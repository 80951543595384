frappe.provide('silicon_ioi.doctype');

export class ioiBOMDefinitionFrame
{
	static path_common = 'silicon_ioi.common.common';
	static path_general_settings = 'silicon_ioi.ioi_configuration.doctype.ioi_general_settings.ioi_general_settings';
	static path_bom_definition = 'silicon_ioi.ioi_configuration.doctype.ioi_bom_definition.ioi_bom_definition';
	static path_item = 'silicon_ioi.ioi_items.doctype.ioi_item.ioi_item';
	static path_item_unit = 'silicon_ioi.ioi_configuration.doctype.ioi_item_unit.ioi_item_unit';


    static html_field = '';
	static item_id = '';
	static release_id = '';
	static show_data = false;
	static can_read = false;
	static can_modify = false;
	static columns = [];
	static data = [];

	static ioiBOMDefinitionTable = null;


	static load_bom_definition_frame(html_field, item_id, release_id, show_data = false, check_status = false, status_value = null)
	{
        silicon_ioi.doctype.ioiBOMDefinitionFrame.html_field = html_field;
		silicon_ioi.doctype.ioiBOMDefinitionFrame.item_id = item_id;
		silicon_ioi.doctype.ioiBOMDefinitionFrame.release_id = release_id;
		silicon_ioi.doctype.ioiBOMDefinitionFrame.show_data = show_data;

		silicon_ioi.doctype.ioiBOMDefinitionFrame.can_read = false;
		silicon_ioi.doctype.ioiBOMDefinitionFrame.can_modify = false;

		let method = silicon_ioi.doctype.ioiBOMDefinitionFrame.path_bom_definition + '.ioi_bom_definition_frame_get_permissions';

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{

							if (r.message.read == 1) {
								silicon_ioi.doctype.ioiBOMDefinitionFrame.can_read = true;
							}

							if (r.message.write == 1) {
								silicon_ioi.doctype.ioiBOMDefinitionFrame.can_modify = true;
							}

							if (silicon_ioi.doctype.ioiBOMDefinitionFrame.can_modify) {
								silicon_ioi.doctype.ioiBOMDefinitionFrame.can_read = true;
							}
						}
		});

		if (document.getElementById('bom_definition_action')) {
			document.getElementById('bom_definition_action').remove();
		}


		if (document.getElementById('bom_definition_action_button_new')) {
			document.getElementById('bom_definition_action_button_new').remove();
		}

		if (document.getElementById('bom_definition_action_button_duplicate')) {
			document.getElementById('bom_definition_action_button_duplicate').remove();
		}


		if (document.getElementById('bom_definition_action_button_delete')) {
			document.getElementById('bom_definition_action_button_delete').remove();
		}

		if (document.getElementById('bom_definition_action_button_save')) {
			document.getElementById('bom_definition_action_button_save').remove();
		}

		if (document.getElementById('bom_definition_action_button_cancel')) {
			document.getElementById('bom_definition_action_button_cancel').remove();
		}

		if (document.getElementById('bom_definition_action_change_position')) {
			document.getElementById('bom_definition_action_change_position').remove();
		}

		if (document.getElementById('bom_definition_action_jump_to_bom_release')) {
			document.getElementById('bom_definition_action_jump_to_bom_release').remove();
		}

		if (document.getElementById('bom_definition_action_component_where_used')) {
			document.getElementById('bom_definition_action_component_where_used').remove();
		}


		if (document.getElementById('bom_definition_hidden_bom_release_value')) {
			document.getElementById('bom_definition_hidden_bom_release_value').remove();
		}


		if (document.getElementById('bom_definition_table_container')) {
			document.getElementById('bom_definition_table_container').remove();
		}

		let bom_release_value = silicon_ioi.doctype.ioiBOMDefinitionFrame.item_id + ' • ' + silicon_ioi.doctype.ioiBOMDefinitionFrame.release_id;

		let html = '';


		html += '<div id="bom_definition_item_frame" style="overflow: auto; overflow-x: auto; overflow-y: hidden; height:55px; border: 1px solid #E6E9EC;">';

		html += '	<div style="position: relative; top: 2px; left: 2px; width:220px;">';
		html += '		<label id="bom_definition_item_frame_bom_release_label" style="position: absolute; top: 0px; left: 2px;z-index:5;"><b>' + __("BOM Release") + '</b></label>';
		html += '	</div>';

		html += '	<div style="position: relative; top: 27px; left: 2px; width:500px;">';
		html += '		<label id="bom_definition_item_frame_bom_release_description" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + bom_release_value + '</label>';
		html += '	</div>';

		let left_position = 500;
		let top_position = 15

		if (cur_frm.doctype.toUpperCase() == 'IOI ITEM MANUFACTURING DEFINITION') {

			html += '	<div style="position: relative; top: 15px; left: ' + left_position.toString() + 'px; width:250px;">';
			html += '		<input id="bom_definition_hidden_bom_release_value" type="hidden" value="' + bom_release_value +  '">';
			html +='		<button id="bom_definition_action_jump_to_bom_release" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="">' + __("To BOM release") + '</button>';
			html += '	</div>';

			left_position += 160;
			top_position = top_position * -1;
		}


		html += '	<div style="position: relative; top: ' + top_position.toString() + 'px; left: ' + left_position.toString() + 'px; width:300px;">';
		html += '		<input id="bom_definition_hidden_bom_release_value" type="hidden" value="' + bom_release_value +  '">';
		html +='		<button id="bom_definition_action_component_where_used" class="btn btn-default ellipsis" style="height: 30px; width: 300px;" onclick="">' + __("Selected Component where used in BOM") + '</button>';
		html += '	</div>';


		html += '</div>';

		if (check_status) {

			if (status_value != 0) {

				silicon_ioi.doctype.ioiBOMDefinitionFrame.can_read = true;
				silicon_ioi.doctype.ioiBOMDefinitionFrame.can_modify = false;
			}
		}

		if ((silicon_ioi.doctype.ioiBOMDefinitionFrame.can_modify) && (silicon_ioi.doctype.ioiBOMDefinitionFrame.show_data)) {

			html += '<div style="overflow: auto; overflow-x: auto; overflow-y: hidden; height:8px;">';
			html += '</div>';


			html += '<div id="bom_definition_action" style="overflow: auto; overflow-x: auto; overflow-y: hidden; height:35px;">';

			// New
			html += '	<div style="position: relative; top: 2px; left: 0px; width:150px;">';
			html += '		<div style="position: absolute; top:0px; left: 2px; height: 35px">';
			html +='			<button id="bom_definition_action_button_new" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="">' + __("New") + '</button>';
			html += '		</div>';
			html += '	</div>';

			// Duplicate
			html += '	<div style="position: relative; top: 2px; left: 160px; width:150px;">';
			html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
			html +='			<button id="bom_definition_action_button_duplicate" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="">' + __("Duplicate") + '</button>';
			html += '		</div>';
			html += '	</div>';


			// Delete
			html += '	<div style="position: relative; top: 2px; left: 320px; width:150px;">';
			html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
			html +='			<button id="bom_definition_action_button_delete" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="">' + __("Delete") + '</button>';
			html += '		</div>';
			html += '	</div>';

			// Save
			html += '	<div style="position: relative; top: 2px; left: 480px; width:150px;">';
			html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
			html +='			<button id="bom_definition_action_button_save" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="" disabled>' + __("Save") + '</button>';
			html += '		</div>';
			html += '	</div>';

			// Cancel
			html += '	<div style="position: relative; top: 2px; left: 640px; width:150px;">';
			html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
			html +='			<button id="bom_definition_action_button_cancel" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="" disabled>' + __("Cancel") + '</button>';
			html += '		</div>';
			html += '	</div>';

			// Change position
			html += '	<div style="position: relative; top: 2px; left: 800px; width:150px;">';
			html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
			html +='			<button id="bom_definition_action_change_position" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="" >' + __("Change position") + '</button>';
			html += '		</div>';
			html += '	</div>';

			html += '</div>'

		}

		html += '<div id="bom_definition_table_container" class="table table-bordered" data-custom-grid="true" style="overflow: auto; overflow-x: auto; overflow-y: hidden; height:600px;border: 1px solid #E6E9EC;">';
		html += '</div>';

		cur_frm.fields_dict[html_field].$wrapper.empty();
		cur_frm.fields_dict[html_field].$wrapper.append(html);


		silicon_ioi.doctype.ioiBOMDefinitionFrame.sleep_static(200).then(() => {

			if (document.getElementById('bom_definition_action_jump_to_bom_release')) {

				let fct_click = function() {

					let url = '/app/ioi-bom-release/' + document.getElementById('bom_definition_hidden_bom_release_value').value;
					window.open(url);
				}

				document.getElementById('bom_definition_action_jump_to_bom_release').onclick = fct_click
			}

			if (document.getElementById('bom_definition_action_component_where_used')) {

				let fct_click = function() {

					let rows = silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.ioiTable.getRows();

					if (rows.length == 0) {
						return false;
					}

					let current_idx = silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.selected_row_index;

					let row = silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.ioiTable.getRow(current_idx);

					if ((row.getData()['component_id'] == null) || (row.getData()['component_id'] == '')) {
						return false;
					}

					document.getElementById('item_where_used_in_bom_frame_item_id').value = row.getData()['component_id'];
					silicon_ioi.doctype.ioiItemWhereUsedInBOMFrame.where_used();
					cur_frm.layout.tabs[2].set_active()
				}

				document.getElementById('bom_definition_action_component_where_used').onclick = fct_click
			}





			if (document.getElementById('bom_definition_action_button_new')) {

				let fct_click = function() {
					silicon_ioi.doctype.ioiBOMDefinitionFrame.new();
				}

				document.getElementById('bom_definition_action_button_new').onclick = fct_click
			}

			if (document.getElementById('bom_definition_action_button_duplicate')) {

				let fct_click = function() {
					silicon_ioi.doctype.ioiBOMDefinitionFrame.duplicate();
				}

				document.getElementById('bom_definition_action_button_duplicate').onclick = fct_click
			}


			if (document.getElementById('bom_definition_action_button_delete')) {

				let fct_click = function() {
					silicon_ioi.doctype.ioiBOMDefinitionFrame.delete();
				}

				document.getElementById('bom_definition_action_button_delete').onclick = fct_click
			}

			if (document.getElementById('bom_definition_action_button_save')) {

				let fct_click = function() {
					silicon_ioi.doctype.ioiBOMDefinitionFrame.save();
				}

				document.getElementById('bom_definition_action_button_save').onclick = fct_click
			}

			if (document.getElementById('bom_definition_action_button_cancel')) {

				let fct_click = function() {
					silicon_ioi.doctype.ioiBOMDefinitionFrame.cancel();
				}

				document.getElementById('bom_definition_action_button_cancel').onclick = fct_click
			}

			if (document.getElementById('bom_definition_action_change_position')) {

				let fct_click = function() {
					silicon_ioi.doctype.ioiBOMDefinitionFrame.change_position();
				}

				document.getElementById('bom_definition_action_change_position').onclick = fct_click
			}




			// New

			let max_height = 600;
			let row_height = null;
			let layout = null;
			let selectable = 1;
			let show_profiles = true;
			let movable_columns = true;
			let resizable_columns = true;
			let auto_redraw = true;

			let table_parameters = {
				"maxHeight": max_height,
				"rowHeight": row_height,
				//"layout": layout,
				"selectable": selectable,
				"showProfiles": show_profiles,
				"movableColumns": movable_columns,
				"resizableColumns": resizable_columns,
				"autoRedraw": auto_redraw
			};

			let doctype = 'ioi BOM Definition';
			let doctype_reference = 'ioi BOM Definition'

			let display_fields = [];
			let display_custom_fields = true;
			let primary_fields_not_name = ['item_id', 'bom_release_id', 'position_id'];
			let exclude_fields = ['is_warning', 'warninfo'];

			let change_title = [];


			let visible_hidden_columns = ['enabled'];
			let hide_visible_columns = ['item_id', 'bom_release_id', 'position_id_int', 'bom_release_name', 'mode_description', 'item_kind_description'];


			let readonly_fields = ['position_id'];

			let display_buttons_when_readonly = [];

			let columns_width = [];



			let frozen_columns = ['position_id'];
			let frozen_columns_editable = [false];

			let select_values = [];
			select_values[0] = ['mode', '0', __('Purchased')];
			select_values[1] = ['mode', '1', __('Manufactured')];
			select_values[2] = ['mode', '2', __('Virtual level')];

			select_values[3] = ['item_kind', '0', __('Normal')];
			select_values[4] = ['item_kind', '1', __('Consumable')];
			select_values[5] = ['item_kind', '2', __('Packing')];
			select_values[6] = ['item_kind', '3', __('Subproduct')];
			select_values[7] = ['item_kind', '4', __('Waste')];


			select_values[8] = ['stored_qty_mode', '0', __('Good')];
			select_values[9] = ['stored_qty_mode', '1', __('To check')];
			select_values[10] = ['stored_qty_mode', '2', __('Bad only')];




			let default_values = []
			default_values[0] = ['mode', '0'];
			default_values[1] = ['item_kind', '0'];
			default_values[2] = ['enabled', '1'];
			default_values[3] = ['stored_qty_mode', '0'];

			let format_numbers = []
			// format_numbers[0] = ['unit_qty', 2, false, true];

			let underline_frozen = true;


			let replace_fields = [];
			replace_fields[0] = ['item_kind', 'item_kind_description'];

			let jump_fields = ['component_id', 'unit_id', 'cons_unit_id'];

			let show_data = silicon_ioi.doctype.ioiBOMDefinitionFrame.show_data;
			let can_read = silicon_ioi.doctype.ioiBOMDefinitionFrame.can_read;
			let can_modify = silicon_ioi.doctype.ioiBOMDefinitionFrame.can_modify;


			let allow_change_status = false;
			let allow_change_userstatus = false

			let language_columns = [];
			let language_memo_columns = [];

			let show_attach_image_path = [];


			let images_properties = []


			let data = [];

			if (show_data) {

				let method = silicon_ioi.doctype.ioiBOMDefinitionFrame.path_bom_definition + '.ioi_bom_definition_get_data';

				frappe.call({  	method: method,
								args: {	"item_id": silicon_ioi.doctype.ioiBOMDefinitionFrame.item_id,
										"release_id": silicon_ioi.doctype.ioiBOMDefinitionFrame.release_id
								},
								async: false,
								callback:function(r)	{
									data = r.message;
								}
				});
			}

			let Format_Cell = function(col, cell, input) {

				if (silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.can_modify) {

					if ((cell.getField().toUpperCase() == 'DESCRIPTION') || (cell.getField().toUpperCase() == 'DESCRIPTION_LG1') || (cell.getField().toUpperCase() == 'DESCRIPTION_LG2') ||
						(cell.getField().toUpperCase() == 'DESCRIPTION_LG3') || (cell.getField().toUpperCase() == 'DESCRIPTION_LG4')) {

						if ((cell.getRow().getData()['component_id'] != null) && (cell.getRow().getData()['component_id'] != '')) {

							if (input != null) {
								input.readOnly = true;
							}

						}else{

							if (input != null) {
								input.readOnly = false;
							}
						}

					}else if (cell.getField().toUpperCase() == 'SUB_RATIO_VALUE') {

						if (cell.getRow().getData()['item_kind'] != 3) {

							if (input != null) {
								input.readOnly = true;
							}

						}else{
							if (input != null) {
								input.readOnly = false;
							}

						}

					}
				}
			}


			let ItemWindowParams = function(bt_fieldname, fieldname, row) {

				let fields = 'a.name, a.description, a.unit_id, b.q_stock, b.q_reserved';
				let fields_len = '300, 500, 125, 150, 150';
				let fields_desc = 'Identification, Description, Unit, Stock qty, Reserved qty';

				let where = 'ioistatus = 2';
				let order_by = 'a.name asc';

				let parameter_with_stock = false;
				let site_id = '';

				return [fields, fields_len, fields_desc, where, order_by, parameter_with_stock, site_id];


			};


			let CustomerWindowParams = function(bt_fieldname, fieldname, row) {

				let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email';
				let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400';
				let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email';
				let where = 'ioistatus = 2';
				let order_by = 'name asc';

				return [fields, fields_len, fields_desc, where, order_by];

			};

			let SupplierWindowParams = function(bt_fieldname, fieldname, row) {

				let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email';
				let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400';
				let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email';
				let where = 'ioistatus = 2';
				let order_by = 'name asc';

				return [fields, fields_len, fields_desc, where, order_by];

			};

			let ManufacturerCatalogWindowParams = function(bt_fieldname, fieldname, row) {

				let fields = 'a.name, a.item_id, a.description, a.unit_id, a.item_id_live_creation, b.supplier_id, a.barcode_reference, ';
				let fields_len = '300, 200, 350, 100, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200';
				let fields_desc = 'Identification, Item, Description, Unit, Item id live creation, Supplier, Barcode ref, ';
				let where = "a.enabled = 1 ";
				let order_by = 'a.name asc';

				let document_date = null;
				let currency_id = null;

				return [fields, fields_len, fields_desc, where, order_by, document_date, currency_id];

			};


			let DoctypeWindowParams = function(bt_fieldname, fieldname, row, cell) {

				let title = __("Select");
				let form_width_pixel = '710px';
				let fields = 'name, description';
				let fields_len = '250, 380';
				let fields_desc = __('Identification') + ',' + __('Description');
				let where = '';
				let order_by = 'name asc';



				if (fieldname.toUpperCase() == 'CONS_UNIT_ID') {

					title = __("Select a consumption unit");

					if ((!row.getData().ref_unit_id) || ((row.getData().ref_unit_id) && (row.getData().ref_unit_id.trim() == ''))) {
						where = "enabled = 1 ";
					}else{

						let method = silicon_ioi.doctype.ioiBOMDefinitionFrame.path_item_unit + '.ioi_item_unit_get_enabled_same_family_for_ref_unit';

						frappe.call({	method: method,
										args: {"ref_unit_id": row.getData().ref_unit_id},
										async: false,
										callback: function(r){

											where = "enabled = 1 and name in (";

											if (r.message)
											{
												for (var z = 0; z < r.message.length; z++) {

													where += "'" + r.message[z][0].replaceAll("'", "''") + "'";

													if (z < r.message.length-1) {
														where += ',';
													}

												}
											}

											where += ")";
										}
						});

					}

				}

				if (fieldname.toUpperCase() == 'STOCK_UNIT_ID') {

					title = __("Select a stock unit");

					if ((!row.getData().ref_unit_id) || ((row.getData().ref_unit_id) && (row.getData().ref_unit_id.trim() == ''))) {
						where = "enabled = 1 ";
					}else{

						let method = silicon_ioi.doctype.ioiBOMDefinitionFrame.path_item_unit + '.ioi_item_unit_get_enabled_same_family_for_ref_unit';

						frappe.call({	method: method,
										args: {"ref_unit_id": row.getData().ref_unit_id},
										async: false,
										callback: function(r){

											where = "enabled = 1 and name in (";

											if (r.message)
											{
												for (var z = 0; z < r.message.length; z++) {

													where += "'" + r.message[z][0].replaceAll("'", "''") + "'";

													if (z < r.message.length-1) {
														where += ',';
													}

												}
											}

											where += ")";
										}
						});

					}

				}


				return [title, form_width_pixel, fields, fields_len, fields_desc, where, order_by];
			};


			let Cell_Callback = function(col, row, input, cell) {

				if (col.fieldname.toUpperCase() == 'COMPONENT_ID')
				{
					if (input != null) {


						if (input.value.trim() != '') {

							let method = silicon_ioi.doctype.ioiBOMDefinitionFrame.path_item + '.ioi_item_get_data';

							frappe.call({  	method: method,
											args: {"item_id": input.value.toUpperCase()},
											async: false,
											callback:function(r) {

												row.update({"description": r.message.description});


												row.update({"ref_unit_id": r.message.unit_id});
												row.update({"cons_unit_id": r.message.unit_id});
												row.update({"stock_unit_id": r.message.unit_id});


												row.update({"cons_unit_conv_coef": r.message.conv_coefficient});
												row.update({"stock_unit_conv_coef": r.message.conv_coefficient});

												silicon_ioi.doctype.ioiBOMDefinitionFrame.convert_value(row);



												if ((r.message.scheduling_mode == 0) || (r.message.scheduling_mode == 1)) {
													row.update({"mode": 0});
												}else{
													row.update({"mode": 1});
												}

											}
							});
						}else{
							row.update({"description": ""});
							row.update({"mode": 0});

							row.update({"ref_unit_id": ""});
							row.update({"cons_unit_id": ""});
							row.update({"stock_unit_id": ""});

							row.update({"cons_unit_conv_coef": 1});
							row.update({"stock_unit_conv_coef": 1});


						}
					}
				}

				if (col.fieldname.toUpperCase() == 'ROUTING_POSITION_DATA') {

					if (input != null) {
						if (input.value != null) {
							if (input.value.trim() != '') {

								if (isNaN(input.value)) {
									input.value = '';
									row.update({"routing_position_int": 0});
									frappe.msgprint({title: __("Message"), message: __('Routing position is not a valid formatted number'), indicator: "red"});


								}else{

									if (input.value.length > 6) {
										input.value = '';
										row.update({"routing_position_int": 0});
										frappe.msgprint({title: __("Message"), message: __('Routing position has to have 6 digits'), indicator: "red"});
									}else{

										if (input.value.length == 6) {
											row.update({"routing_position_int": parseInt(input.value)});
										}else{

											let s = '';

											for (var i = 0; i < 6 - input.value.length; i++) {
												s += '0';
											}

											s += input.value;

											input.value = s;

											row.update({"routing_position_int": parseInt(input.value)});
										}
									}
								}

							}
						}

					}
				}

				if (col.fieldname.toUpperCase() == 'CONS_UNIT_ID') {

					if (input != null) {

						if (input.value.trim() != '') {

							if ((row.getData().ref_unit_id) && (row.getData().ref_unit_id.trim() != '')) {

								let method = silicon_ioi.doctype.ioiBOMDefinitionFrame.path_item_unit + '.ioi_item_unit_get_enabled_same_family_for_ref_unit';

								frappe.call({	method: method,
												args: {"ref_unit_id": row.getData().ref_unit_id},
												async: false,
												callback: function(r){

													let unit_found = false

													for (var z = 0; z < r.message.length; z++) {

														if (r.message[z][0].toUpperCase() == row.getData().unit_id.toUpperCase()) {
															unit_found = true;
															break;
														}
													}

													if (!unit_found) {

														input.value = '';
														frappe.msgprint({title: __("Message"), message: __("This unit does not belong to the unit family") + ' ( ' + row.getData().ref_unit_id + ' ) ' , indicator: "red"});

													}

												}
								});

								row.update({"cons_unit_conv_coef": 1});

								if ((cell.getValue() != null) && (cell.getValue().trim() != '')) {

									let method = silicon_ioi.doctype.ioiBOMDefinitionFrame.path_item_unit + '.ioi_item_unit_get_data';

									frappe.call({  	method: method,
													args: {"unit_id": cell.getValue().toUpperCase()},
													async: false,
													callback:function(r)	{

														let coefficient = 1;

														if (r.message.length > 0) {

															if ((r.message[0].coefficient) && (r.message[0].coefficient != 0)) {

																coefficient = parseFloat(r.message[0].coefficient);
															}
														}

														row.update({"cons_unit_conv_coef": coefficient});


													}
									});

								}

								silicon_ioi.doctype.ioiBOMDefinitionFrame.convert_value(row);

							}

						}
					}
				}


				if (col.fieldname.toUpperCase() == 'STOCK_UNIT_ID') {

					if (input != null) {

						if (input.value.trim() != '') {

							if ((row.getData().ref_unit_id) && (row.getData().ref_unit_id.trim() != '')) {

								let method = silicon_ioi.doctype.ioiBOMDefinitionFrame.path_item_unit + '.ioi_item_unit_get_enabled_same_family_for_ref_unit';

								frappe.call({	method: method,
												args: {"ref_unit_id": row.getData().ref_unit_id},
												async: false,
												callback: function(r){

													let unit_found = false

													for (var z = 0; z < r.message.length; z++) {

														if (r.message[z][0].toUpperCase() == row.getData().unit_id.toUpperCase()) {
															unit_found = true;
															break;
														}
													}

													if (!unit_found) {

														input.value = '';
														frappe.msgprint({title: __("Message"), message: __("This unit does not belong to the unit family") + ' ( ' + row.getData().ref_unit_id + ' ) ' , indicator: "red"});

													}

												}
								});

								row.update({"stock_unit_conv_coef": 1});

								if ((cell.getValue() != null) && (cell.getValue().trim() != '')) {

									let method = silicon_ioi.doctype.ioiBOMDefinitionFrame.path_item_unit + '.ioi_item_unit_get_data';

									frappe.call({  	method: method,
													args: {"unit_id": cell.getValue().toUpperCase()},
													async: false,
													callback:function(r)	{

														let coefficient = 1;

														if (r.message.length > 0) {

															if ((r.message[0].coefficient) && (r.message[0].coefficient != 0)) {

																coefficient = parseFloat(r.message[0].coefficient);
															}
														}

														row.update({"stock_unit_conv_coef": coefficient});


													}
									});

								}


							}

						}

						silicon_ioi.doctype.ioiBOMDefinitionFrame.convert_value(row);
					}
				}

				if (col.fieldname.toUpperCase() == 'CONS_UNIT_QTY') {

					if (input != null) {
						silicon_ioi.doctype.ioiBOMDefinitionFrame.convert_value(row);
					}
				}

				if (col.fieldname.toUpperCase() == 'CONS_FIX_QTY') {

					if (input != null) {
						silicon_ioi.doctype.ioiBOMDefinitionFrame.convert_value(row);
					}
				}

				if (col.fieldname.toUpperCase() == 'ITEM_KIND') {

					if (row.getData().item_kind != 3) {
						row.update({"sub_ratio_value": 0});
					}
				}

			}


			let CellButton_Callback = function(fieldname, return_value, row) {

				if (fieldname.toUpperCase() == 'COMPONENT_ID') {

					row.update({"component_id": return_value});


					let method = silicon_ioi.doctype.ioiBOMDefinitionFrame.path_item + '.ioi_item_get_data';

					frappe.call({  	method: method,
									args: {"item_id": return_value},
									async: false,
									callback:function(r) {

										row.update({"description": r.message.description});

										row.update({"ref_unit_id": r.message.unit_id});
										row.update({"cons_unit_id": r.message.unit_id});
										row.update({"stock_unit_id": r.message.unit_id});

										row.update({"cons_unit_conv_coef": r.message.conv_coefficient});
										row.update({"stock_unit_conv_coef": r.message.conv_coefficient});



										if ((r.message.scheduling_mode == 0) || (r.message.scheduling_mode == 1)) {
											row.update({"mode": 0});
										}else{
											row.update({"mode": 1});
										}

									}
					});

					silicon_ioi.doctype.ioiBOMDefinitionFrame.convert_value(row);
				}

				if (fieldname.toUpperCase() == 'CONS_UNIT_ID') {

					row.update({"cons_unit_id": return_value});

					row.update({"cons_unit_conv_coef": 1});


					let method = silicon_ioi.doctype.ioiBOMDefinitionFrame.path_item_unit + '.ioi_item_unit_get_data';

					frappe.call({  	method: method,
									args: {"unit_id": return_value},
									async: false,
									callback:function(r)	{

										let coefficient = 1;

										if (r.message.length > 0) {

											if ((r.message[0].coefficient) && (r.message[0].coefficient != 0)) {

												coefficient = parseFloat(r.message[0].coefficient);
											}
										}

										row.update({"cons_unit_conv_coef": coefficient});


									}
					});

					silicon_ioi.doctype.ioiBOMDefinitionFrame.convert_value(row);
				}

				if (fieldname.toUpperCase() == 'STOCK_UNIT_ID') {

					row.update({"stock_unit_id": return_value});

					row.update({"stock_unit_conv_coef": 1});


					let method = silicon_ioi.doctype.ioiBOMDefinitionFrame.path_item_unit + '.ioi_item_unit_get_data';

					frappe.call({  	method: method,
									args: {"unit_id": return_value},
									async: false,
									callback:function(r)	{

										let coefficient = 1;

										if (r.message.length > 0) {

											if ((r.message[0].coefficient) && (r.message[0].coefficient != 0)) {

												coefficient = parseFloat(r.message[0].coefficient);
											}
										}

										row.update({"stock_unit_conv_coef": coefficient});


									}
					});

					silicon_ioi.doctype.ioiBOMDefinitionFrame.convert_value(row);
				}

			}

			let Memo_Callback = function(fieldname, output, row) {

			};

			let Duration_Callback = function(fieldname, ret_value, row) {

			};

			let MultiSelect_Callback = function(fieldname, ret_value, row) {

			};


			let Button_Callback = function(bt_fieldname, cell, row, button) {

			};

			let Cell_click = function(cell) {

				if (cell.getColumn().getField().toUpperCase() == 'POSITION_ID') {
					let row = cell.getRow()

					let url = '/app/ioi-bom-definition/' + row.getData()["bom_release_name"] + ' • ' + row.getData()["position_id"];

					window.open(url);
					return false;
				}
			};

			let RecordChangeState = function(record_state) {

				if (record_state.toUpperCase() == 'MODIFIED') {

					if (document.getElementById('bom_definition_action_button_new')) {
						document.getElementById('bom_definition_action_button_new').disabled = false;
					}

					if (document.getElementById('bom_definition_action_button_duplicate')) {
						document.getElementById('bom_definition_action_button_duplicate').disabled = false;
					}

					if (document.getElementById('bom_definition_action_button_delete')) {
						document.getElementById('bom_definition_action_button_delete').disabled = false;
					}

					if (document.getElementById('bom_definition_action_button_save')) {
						document.getElementById('bom_definition_action_button_save').disabled = false;
					}

					if (document.getElementById('bom_definition_action_button_cancel')) {
						document.getElementById('bom_definition_action_button_cancel').disabled = false;
					}

					if (document.getElementById('bom_definition_action_change_position')) {
						document.getElementById('bom_definition_action_change_position').disabled = true;
					}



				}else if (record_state.toUpperCase() == 'INSERT') {

					if (document.getElementById('bom_definition_action_button_new')) {
						document.getElementById('bom_definition_action_button_new').disabled = false;
					}

					if (document.getElementById('bom_definition_action_button_duplicate')) {
						document.getElementById('bom_definition_action_button_duplicate').disabled = false;
					}

					if (document.getElementById('bom_definition_action_button_delete')) {
						document.getElementById('bom_definition_action_button_delete').disabled = false;
					}

					if (document.getElementById('bom_definition_action_button_save')) {
						document.getElementById('bom_definition_action_button_save').disabled = false;
					}

					if (document.getElementById('bom_definition_action_button_cancel')) {
						document.getElementById('bom_definition_action_button_cancel').disabled = false;
					}

					if (document.getElementById('bom_definition_action_change_position')) {
						document.getElementById('bom_definition_action_change_position').disabled = true;
					}

				}else if (record_state.toUpperCase() == '') {

					if (document.getElementById('bom_definition_action_button_new')) {
						document.getElementById('bom_definition_action_button_new').disabled = false;
					}

					if (document.getElementById('bom_definition_action_button_duplicate')) {
						document.getElementById('bom_definition_action_button_duplicate').disabled = false;
					}

					if (document.getElementById('bom_definition_action_button_delete')) {
						document.getElementById('bom_definition_action_button_delete').disabled = false;
					}

					if (document.getElementById('bom_definition_action_button_save')) {
						document.getElementById('bom_definition_action_button_save').disabled = true;
					}

					if (document.getElementById('bom_definition_action_button_cancel')) {
						document.getElementById('bom_definition_action_button_cancel').disabled = true;
					}

					if (document.getElementById('bom_definition_action_change_position')) {
						document.getElementById('bom_definition_action_change_position').disabled = false;
					}

				}


			}

			let extended_parameters = {
				"doctype": doctype,
				"doctype_reference": doctype_reference,
				"display_fields": display_fields,
				"display_custom_fields": display_custom_fields,
				"primary_fields_not_name": primary_fields_not_name,
				"exclude_fields": exclude_fields,
				"change_title": change_title,
				"visible_hidden_columns": visible_hidden_columns,
				"hide_visible_columns" : hide_visible_columns,
				"readonly_fields" : readonly_fields,
				"display_buttons_when_readonly" : display_buttons_when_readonly,
				"columns_width": columns_width,
				"frozen_columns": frozen_columns,
				"frozen_columns_editable": frozen_columns_editable,
				"select_values": select_values,
				"default_values": default_values,
				"format_numbers": format_numbers,
				"underline_frozen": underline_frozen,
				"replace_fields": replace_fields,
				"jump_fields": jump_fields,
				"show_data": show_data,
				"can_read": can_read,
				"can_modify": can_modify,
				"allow_change_status": allow_change_status,
				"allow_change_userstatus": allow_change_userstatus,
				"language_columns": language_columns,
				"language_memo_columns": language_memo_columns,
				"show_attach_image_path": show_attach_image_path,
				"images_properties": images_properties
			}


			let methods = {
				format_cell: Format_Cell,
				item_window_params: ItemWindowParams,
				customer_window_params: CustomerWindowParams,
				supplier_window_params: SupplierWindowParams,
				manufacturer_catalog_window_params: ManufacturerCatalogWindowParams,
				doctype_window_params: DoctypeWindowParams,
				cell_callback: Cell_Callback,
				cell_button_callback: CellButton_Callback,
				memo_callback: Memo_Callback,
				duration_callback: Duration_Callback,
				table_multiselect_callback: MultiSelect_Callback,
				button_callback: Button_Callback,
				cell_click: Cell_click,
				record_change_state : RecordChangeState
			}


			silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable = new silicon_ioi.ioiDocTypeGrid('#bom_definition_table_container', table_parameters, extended_parameters, methods, data);


        });


	}

    static load_bom_definition_grid(html_field, item_id, release_id, show_data = false, check_status = false, status_value = null)
    {
        silicon_ioi.doctype.ioiBOMDefinitionFrame.load_bom_definition_frame(html_field, item_id, release_id, show_data, check_status, status_value);
    }

	static convert_value(row)
	{
		row.update({"stock_unit_qty": row.getData().cons_unit_qty});
		row.update({"stock_fix_qty": row.getData().cons_fix_qty});

		if (((row.getData().cons_unit_id) && (row.getData().cons_unit_id.trim() != '')) &&
			((row.getData().stock_unit_id) && (row.getData().stock_unit_id.trim() != ''))) {

			let cons_unit_conv_coef = 1;

			if ((row.getData().cons_unit_conv_coef) && (row.getData().cons_unit_conv_coef != 0)) {
				cons_unit_conv_coef = parseFloat(row.getData().cons_unit_conv_coef);
			}

			let stock_unit_conv_coef = 1;

			if ((row.getData().stock_unit_conv_coef) && (row.getData().stock_unit_conv_coef != 0)) {
				stock_unit_conv_coef = parseFloat(row.getData().stock_unit_conv_coef);
			}

			let stock_unit_qty = row.getData().cons_unit_qty * cons_unit_conv_coef / stock_unit_conv_coef;
			let stock_fix_qty = row.getData().cons_fix_qty * cons_unit_conv_coef / stock_unit_conv_coef;

			row.update({"stock_unit_qty": stock_unit_qty});
			row.update({"stock_fix_qty": stock_fix_qty});

		}
	}

	static new()
	{

		let position_id_int = 0;

		let rows = silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.ioiTable.getRows();

		if (rows) {

			for (var i = 0; i < rows.length; i++) {

				if (rows[i].getData().position_id_int > position_id_int) {
					position_id_int = rows[i].getData().position_id_int
				}
			}
		}

		position_id_int += 10;

		var insertDialog = new frappe.ui.Dialog({
			title: __("New"),

			fields: [{	label: __("Position"),
						fieldname: 'html_bom_definition_dialog_position',
						fieldtype: 'Int'
					}],
			primary_action_label: __("Ok"),
			primary_action: function(){

				let value = cur_dialog.fields_dict['html_bom_definition_dialog_position'].value

				if ((value == null) || (value == '')) {
					frappe.msgprint({title: __("Message"), message: __("Position is mandatory"), indicator: "red"});
					return false;
				}

				if (parseInt(value) <= 0) {
					frappe.msgprint({title: __("Message"), message: __("Position has to be greater than zero"), indicator: "red"});
					return false;
				}

				let rows = silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.ioiTable.getRows();

				if (rows) {

					for (var i = 0; i < rows.length; i++) {

						if (rows[i].getData().position_id_int == value) {
							frappe.msgprint({title: __("Message"), message: __("This position already exists in the grid"), indicator: "red"});
							return false;
						}
					}
				}

				let nb = 6 - value.toString().length;

				let formatted_position = '';

				for (var i = 1; i <= nb; i++) {
					formatted_position += '0';
				}

				formatted_position += value.toString();

				let s = '{';

				s += '"item_id":"' + silicon_ioi.doctype.ioiBOMDefinitionFrame.item_id + '", ';
				s += '"bom_release_id":"' + silicon_ioi.doctype.ioiBOMDefinitionFrame.release_id + '", ';
				s += '"position_id":"' + formatted_position + '", "position_id_int": ' + value.toString() + ', ';
				s += '"bom_release_name":"' + silicon_ioi.doctype.ioiBOMDefinitionFrame.item_id + ' • ' + silicon_ioi.doctype.ioiBOMDefinitionFrame.release_id + '", ';
				s += '"name":"' + silicon_ioi.doctype.ioiBOMDefinitionFrame.item_id + ' • ' + silicon_ioi.doctype.ioiBOMDefinitionFrame.release_id + ' • ' + formatted_position + '", ';
				s += '"item_kind": 0, ';
				s += '"stock_unit_qty": 1, ';
				s += '"cons_unit_qty": 1, ';
				s += '"stock_unit_conv_coef": 1, ';
				s += '"cons_unit_conv_coef": 1 ';


				s += '}';

				silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.new_row(s, false);

				insertDialog.hide();
			},
			secondary_action_label: __("Cancel"),
			secondary_action: function(){
				insertDialog.hide();
			}

		});


		insertDialog.$wrapper.find('.modal-dialog').css("max-width", "300px").css("width", "300x");

		insertDialog.show();

		silicon_ioi.doctype.ioiBOMDefinitionFrame.sleep_static(200).then(() => {

			insertDialog.set_value('html_bom_definition_dialog_position', position_id_int);

		});

	}

	static duplicate()
	{
		if (!document.getElementById('bom_definition_action_button_save').disabled) {
			frappe.msgprint({title: __("Message"), message: __("Save the changes before duplicating"), indicator: "red"});
			return false;
		}

		let rows = silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.ioiTable.getRows();

		if (rows.length == 0) {
			return false;
		}

		let top = 10;

		let msg = '';
		let s = __("Duplicate this BOM definition ?");

		msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';

		msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';


		frappe.confirm(	msg,
			() => 	{
						silicon_ioi.doctype.ioiBOMDefinitionFrame.sleep_static(200).then(() => {

							let position_id_int = 0;

							let rows = silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.ioiTable.getRows();

							if (rows) {

								for (var i = 0; i < rows.length; i++) {

									if (rows[i].getData().position_id_int > position_id_int) {
										position_id_int = rows[i].getData().position_id_int
									}
								}
							}

							position_id_int += 10;

							var insertDialog = new frappe.ui.Dialog({
								title: __("Duplicate"),

								fields: [{	label: __("Position"),
											fieldname: 'html_bom_definition_dialog_position',
											fieldtype: 'Int'
										}],
								primary_action_label: __("Ok"),
								primary_action: function(){

									let value = cur_dialog.fields_dict['html_bom_definition_dialog_position'].value

									if ((value == null) || (value == '')) {
										frappe.msgprint({title: __("Message"), message: __("Position is mandatory"), indicator: "red"});
										return false;
									}

									if (parseInt(value) <= 0) {
										frappe.msgprint({title: __("Message"), message: __("Position has to be greater than zero"), indicator: "red"});
										return false;
									}

									let rows = silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.ioiTable.getRows();

									if (rows) {

										for (var i = 0; i < rows.length; i++) {

											if (rows[i].getData().position_id_int == value) {
												frappe.msgprint({title: __("Message"), message: __("This position already exists in the grid"), indicator: "red"});
												return false;
											}
										}
									}

									let nb = 6 - value.toString().length;

									let formatted_position = '';

									for (var i = 1; i <= nb; i++) {
										formatted_position += '0';
									}

									formatted_position += value.toString();

									let current_idx = silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.selected_row_index;

									let row = silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.ioiTable.getRow(current_idx);

									if (row.getData().ioistatus) {
										if (row.getData().ioistatus != 0) {
											frappe.msgprint({title: __("Message"), message: __("Status has to be in preparation"), indicator: "red"});
											return false;
										}
									}


									let current_row = silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.ioiTable.getRow(current_idx);

									silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.ioiTable.addData([current_row.getData()], false).then(function(rows){

										let r = rows[0];

										r.update({"item_id": silicon_ioi.doctype.ioiBOMDefinitionFrame.item_id});
										r.update({"bom_release_id": silicon_ioi.doctype.ioiBOMDefinitionFrame.release_id});
										r.update({"position_id": formatted_position});
										r.update({"position_id_int": value.toString()});
										r.update({"bom_release_name": silicon_ioi.doctype.ioiBOMDefinitionFrame.item_id + ' • ' + silicon_ioi.doctype.ioiBOMDefinitionFrame.release_id});
										r.update({"name": silicon_ioi.doctype.ioiBOMDefinitionFrame.item_id + ' • ' + silicon_ioi.doctype.ioiBOMDefinitionFrame.release_id + ' • ' + formatted_position});
										r.update({"position_id": formatted_position});

										r.update({"tabulator_record_modified":"Y"});

										if (silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.RecordChangeState) {
											silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.RecordChangeState('INSERT');
										}

										silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.record_state = 'INSERT';

										silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.ioiTable.deselectRow();
										r.select();

										let nb = 0;

										let all_datas = silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.ioiTable.getData();

										for (var i = 0; i < all_datas.length; i++) {
											if (all_datas[i].tabulator_row_index > nb) {
												nb = all_datas[i].tabulator_row_index;
											}
										}

										nb++;

										r.update({"tabulator_row_index": nb});
										silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.selected_row_index = nb;

										silicon_ioi.doctype.ioiBOMDefinitionFrame.save();


									});


									insertDialog.hide();
								},
								secondary_action_label: __("Cancel"),
								secondary_action: function(){
									insertDialog.hide();
								}

							});


							insertDialog.$wrapper.find('.modal-dialog').css("max-width", "300px").css("width", "300x");

							insertDialog.show();

							silicon_ioi.doctype.ioiBOMDefinitionFrame.sleep_static(200).then(() => {

								insertDialog.set_value('html_bom_definition_dialog_position', position_id_int);

							});



						});
					},
			() => 	{
					}
		);


	}


	static delete()
	{
		let rows = silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.ioiTable.getRows();

		if (rows.length == 0) {
			return false;
		}

		let top = 10;

		let msg = '';
		let s = __("Delete this BOM definition ?");

		msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';

		msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';


		frappe.confirm(	msg,
			() => 	{
						silicon_ioi.doctype.ioiBOMDefinitionFrame.sleep_static(200).then(() => {
							silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.delete_row();
						});
					},
			() => 	{
					}
		);
	}

	static save()
	{
		let updated_data = [];

		let rows = silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.ioiTable.getRows();

		if (rows) {

			for (var i = 0; i < rows.length; i++) {

				if (rows[i].getData()['tabulator_record_modified'] == 'Y') {

					updated_data[updated_data.length] = rows[i].getData();
				}

			}
		}

		let deleted_data = [];

		deleted_data = silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.deleted_rows;

		let images_uploaded_path = [];
		images_uploaded_path = silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.images_uploaded_path;

		let files_uploaded_path = [];
		files_uploaded_path = silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.files_uploaded_path;


		let allow_change_status = 0;

		if (silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.allow_change_status) {
			allow_change_status = 1;
		}


		let allow_change_user_status = 0;

		if (silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.allow_change_userstatus) {
			allow_change_user_status = 1;
		}

		if (updated_data) {
			for (var i = 0; i < updated_data.length; i++) {

				if ((updated_data[i].component_id) && (updated_data[i].component_id.trim() != '')) {
					if (updated_data[i].component_id.toUpperCase() == updated_data[i].item_id.toUpperCase()) {
						frappe.msgprint({title: __("Message"), message: __('Position') + ' ' + updated_data[i].position_id + ' : ' + __("The component and item have to be different"), indicator: "red"});
						return false;
					}
				}

			}
		}


		let method = silicon_ioi.doctype.ioiBOMDefinitionFrame.path_bom_definition + '.ioi_bom_definition_update_data';

		frappe.call({  	method: method,
						args: {	"data": updated_data,
								"deleted_data": deleted_data,
								"doctype_reference": silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.doctype_reference,
								"current_doctype": silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.current_doctype,
								"current_docname": silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.current_name,
								"images_uploaded_path": images_uploaded_path,
								"files_uploaded_path": files_uploaded_path,
								"allow_change_status": allow_change_status,
								"allow_change_user_status": allow_change_user_status},
						async: false,
						callback:function(r)	{

							if (r.message.error != 0) {

								let msg = '';

								for (var i = 0; i < r.message.error_msg.length; i++) {

									msg += r.message.error_msg[i].action + ' ( ' + r.message.error_msg[i].name + ' ) : ' + r.message.error_msg[i].error_msg;

									if (i < r.message.error_msg.length-1) {
											msg += '<BR>';
									}

								}
								frappe.msgprint({title: __("Message"), message: msg, indicator: "red"});
							}

							silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.data = [];

							let method = silicon_ioi.doctype.ioiBOMDefinitionFrame.path_bom_definition + '.ioi_bom_definition_get_data';

							frappe.call({  	method: method,
											args: {	"item_id": silicon_ioi.doctype.ioiBOMDefinitionFrame.item_id,
													"release_id": silicon_ioi.doctype.ioiBOMDefinitionFrame.release_id
											},
											async: false,
											callback:function(r)	{
												silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.data = r.message;
												silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.set_data();
											}
							});


						}
		});
	}

	static cancel()
	{
		silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.data = [];

		let method = silicon_ioi.doctype.ioiBOMDefinitionFrame.path_bom_definition + '.ioi_bom_definition_get_data';

		frappe.call({  	method: method,
						args: {	"item_id": silicon_ioi.doctype.ioiBOMDefinitionFrame.item_id,
								"release_id": silicon_ioi.doctype.ioiBOMDefinitionFrame.release_id
						},
						async: false,
						callback:function(r)	{
							silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.data = r.message;
							silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.set_data();
						}
		});

	}

	static change_position()
	{
		let rows = silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.ioiTable.getRows();

		if (rows.length == 0) {
			return false;
		}
		let current_idx = silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.selected_row_index;


		let row = silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.ioiTable.getRow(current_idx);

		let current_position_id = row.getData().position_id;


		var UpdateDialog = new frappe.ui.Dialog({
			title: __("Change position"),

			fields: [{	label: __("Current position"),
						fieldname: 'html_bom_definition_dialog_change_position_current',
						fieldtype: 'Data',
						read_only: 1
					},
					{	label: __("New position"),
						fieldname: 'html_bom_definition_dialog_change_position_new',
						fieldtype: 'Data'
					}

				],
			primary_action_label: __("Ok"),
			primary_action: function(){

				let current_value = cur_dialog.fields_dict['html_bom_definition_dialog_change_position_current'].value;

				let value = cur_dialog.fields_dict['html_bom_definition_dialog_change_position_new'].value;

				if ((value == null) || (value == '')) {
					frappe.msgprint({title: __("Message"), message: __("New position is mandatory"), indicator: "red"});
					return false;
				}

				let authorized_char = "0123456789"

				for (var i = 0; i < value.length; i++) {

					if (authorized_char.indexOf(value[i]) == -1) {
						frappe.msgprint({title: __("Message"), message: __("New position has an invalid character") + ' ' + value[i], indicator: "red"});
						return false;
					}

				}

				if (value.length != 6) {
					frappe.msgprint({title: __("Message"), message: __("New position has to have 6 positions length"), indicator: "red"});
					return false;
				}



				let rows = silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.ioiTable.getRows();

				if (rows) {

					for (var i = 0; i < rows.length; i++) {

						if (rows[i].getData().position_id == value) {
							frappe.msgprint({title: __("Message"), message: __("This position already exists in the grid"), indicator: "red"});
							return false;
						}
					}
				}

				let method = silicon_ioi.doctype.ioiBOMDefinitionFrame.path_bom_definition + '.ioi_bom_definition_rename_position';

				frappe.call({  	method: method,
								args: {	"bom_release_name": silicon_ioi.doctype.ioiBOMDefinitionFrame.item_id + ' • ' + silicon_ioi.doctype.ioiBOMDefinitionFrame.release_id,
										"current_position": current_value,
										"new_position": value
								},
								async: false,
								callback:function(r)	{

									UpdateDialog.hide();

									silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.data = [];

									let method = silicon_ioi.doctype.ioiBOMDefinitionFrame.path_bom_definition + '.ioi_bom_definition_get_data';

									frappe.call({  	method: method,
													args: {	"item_id": silicon_ioi.doctype.ioiBOMDefinitionFrame.item_id,
															"release_id": silicon_ioi.doctype.ioiBOMDefinitionFrame.release_id
													},
													async: false,
													callback:function(r)	{
														silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.data = r.message;
														silicon_ioi.doctype.ioiBOMDefinitionFrame.ioiBOMDefinitionTable.set_data();
													}
									});

								}
				});

			},
			secondary_action_label: __("Cancel"),
			secondary_action: function(){
				UpdateDialog.hide();
			}

		});


		UpdateDialog.$wrapper.find('.modal-dialog').css("max-width", "300px").css("width", "300x");

		UpdateDialog.show();

		silicon_ioi.doctype.ioiBOMDefinitionFrame.sleep_static(200).then(() => {

			UpdateDialog.set_value('html_bom_definition_dialog_change_position_current', current_position_id);

		});
	}

	static sleep_static(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}

}

silicon_ioi.doctype.ioiBOMDefinitionFrame = ioiBOMDefinitionFrame;
