let images = [];
let images_flag = [];

$(document).ready(() => {


	let me = this;
	let method = 'silicon_ioi.common.common.get_images';

	frappe.call({  	method: method,
					args: { },
					async: false,
					callback:function(r)	{
						images = [];
						images_flag = [];

						for (var i = 0; i < r.message.length; i++)
						{
							images[i] = r.message[i].toString().toUpperCase();
							images_flag[i] = 0;
						}

					}
				});




	display_scan_area();
    fill_workspace();
});

window.onresize = function() {

	if (window.innerWidth <= 500) {

		if (document.getElementById('sb_main_scan_input')) {
			document.getElementById('sb_main_scan_input').style.top = "8px";
		}

		if (document.getElementById('sb_main_bt_cam_scan')) {
			document.getElementById('sb_main_bt_cam_scan').style.top = "8px";
		}

		if (document.getElementById('sb_main_scan_check')) {
			document.getElementById('sb_main_scan_check').style.top = "14px";
		}

		if (document.getElementById('sb_main_scan_msg')) {
			document.getElementById('sb_main_scan_msg').style.top = "46px";
		}

	}else {

		if (document.getElementById('sb_main_scan_input')) {
			document.getElementById('sb_main_scan_input').style.top = "0px";
		}

		if (document.getElementById('sb_main_bt_cam_scan')) {
			document.getElementById('sb_main_bt_cam_scan').style.top = "0px";
		}

		if (document.getElementById('sb_main_scan_check')) {
			document.getElementById('sb_main_scan_check').style.top = "8px";
		}

		if (document.getElementById('sb_main_scan_msg')) {
			document.getElementById('sb_main_scan_msg').style.top = "40px";
		}
	}

	if (window.innerWidth >= 482) {

		if (document.getElementById('sb_main_scan_input')) {

			let scanfield_width = 275;

			document.getElementById('sb_main_scan_input').style.width = scanfield_width + 'px';
			document.getElementById('sb_main_scan_input').style.left = '5px';
		}
	}else
	{
		if (document.getElementById('sb_main_scan_input')) {

			let scanfield_width = 275;

			//document.getElementById('sb_main_scan_input').style.width = document.getElementsByClassName('awesomplete')[0].clientWidth + 'px';
			document.getElementById('sb_main_scan_input').style.width = scanfield_width + 'px';
			document.getElementById('sb_main_scan_input').style.left = '13px';
		}
	}

};

function display_scan_area()
{

	if (document.getElementById('sb_main_scan_section'))
	{	document.getElementById('sb_main_scan_section').remove();
	}

	if (document.getElementById('sb_main_scan_input'))
	{	document.getElementById('sb_main_scan_input').remove();
	}

	if (document.getElementById('sb_main_scan_check'))
	{	document.getElementById('sb_main_scan_check').remove();
	}

	if (document.getElementById('sb_main_scan_msg'))
	{	document.getElementById('sb_main_scan_msg').remove();
	}

	document.getElementsByClassName('container')[0].children[0].style.minWidth = '24px';

	let is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;
	let me = this;

	let div = document.createElement("DIV");
	div.setAttribute("id", "sb_main_scan_section");
	div.setAttribute("style", "position:relative; top: 0px; left: 0px; width: 320px; height:36px;");
	div.setAttribute("hidden", "true");


	let element = document.createElement("INPUT");
	element.setAttribute("type", "text");
	element.setAttribute("class", "form-control");
	element.setAttribute("autocomplete", "off");
	element.setAttribute("id", "sb_main_scan_input");
	element.setAttribute("placeholder", __("Scan here ... [ $-? for help ]"));
	element.setAttribute("value", "");

	let scanfield_width = 275;


	if (is_dark_mode == 0) {
		element.setAttribute("style", "position:absolute; top: 0px; left: 5px; width: " + scanfield_width + "px; height:36px; background: url(/assets/silicon_ioi/images/buttons/barcode_scan.png) no-repeat scroll 7px 6px; padding-left:40px;background-size: 24px 24px; background-color: #F4F5F6; text-transform:uppercase;");
	}else {
		element.setAttribute("style", "position:absolute; top: 0px; left: 5px; width: " + scanfield_width + "px; height:36px; background: url(/assets/silicon_ioi/images/buttons/barcode_scan.png) no-repeat scroll 7px 6px; padding-left:40px;background-size: 24px 24px; text-transform:uppercase;");
	}
	if(silicon_ioi.ioiScanning){
		element.onkeydown = silicon_ioi.ioiScanning.do_scan;
	}

	if (is_dark_mode == 0) {
		element.onfocus = function () { this.style.backgroundColor = "#FFFFFF"; };
		element.onblur = function () { this.style.backgroundColor = "#F4F5F6"; };
	}

	div.appendChild(element);

	let cam_scan = document.createElement('IMG');
	cam_scan.setAttribute("id", "sb_main_bt_cam_scan");
	cam_scan.setAttribute("style", "position:absolute; top: 0px; left:280px; width: 30px; height:36px; object-fit:fill;");
	cam_scan.src = '/assets/silicon_ioi/images/buttons/mobile_scan.svg';
	cam_scan.onclick = function() {
		silicon_ioi.ioiScanning.camscan();
	}

	div.appendChild(cam_scan)


	let checkbox = document.createElement("INPUT");
	checkbox.setAttribute("type", "checkbox");
	checkbox.setAttribute("class", "form-control")
	checkbox.setAttribute("id", "sb_main_scan_check");
	checkbox.setAttribute("title", "Focus in scan area");
	checkbox.setAttribute("style", "position:relative; top: 8px; left: 310px; width: 20px; height:20px; display: block;");
	checkbox.checked = false;
	checkbox.onclick = function () {

		if (document.getElementById('sb_main_scan_input')) {

			if (this.checked) {
				document.getElementById('sb_main_scan_input').focus();
				silicon_ioi.ioiScanning.setfocus();
			}
		}
	};

	div.appendChild(checkbox);


	let label = document.createElement("DIV");
	label.setAttribute("id", "sb_main_scan_msg");
	label.setAttribute("style", "position:absolute; top: 40px; left: 5px; width:290px; height: 40px;");
	label.innerHTML = "";

	div.appendChild(label);


	document.getElementsByClassName('container')[0].getElementsByClassName('collapse navbar-collapse justify-content-end')[0].children[0].appendChild(div);

	if (window.innerWidth <= 500) {

		if (document.getElementById('sb_main_scan_input')) {
			document.getElementById('sb_main_scan_input').style.top = "8px";
		}

		if (document.getElementById('sb_main_bt_cam_scan')) {
			document.getElementById('sb_main_bt_cam_scan').style.top = "8px";
		}

		if (document.getElementById('sb_main_scan_check')) {
			document.getElementById('sb_main_scan_check').style.top = "14px";
		}

		if (document.getElementById('sb_main_scan_msg')) {
			document.getElementById('sb_main_scan_msg').style.top = "46px";
		}
	}else {

		if (document.getElementById('sb_main_scan_input')) {
			document.getElementById('sb_main_scan_input').style.top = "0px";
		}

		if (document.getElementById('sb_main_bt_cam_scan')) {
			document.getElementById('sb_main_bt_cam_scan').style.top = "0px";
		}


		if (document.getElementById('sb_main_scan_check')) {
			document.getElementById('sb_main_scan_check').style.top = "8px";
		}

		if (document.getElementById('sb_main_scan_msg')) {
			document.getElementById('sb_main_scan_msg').style.top = "40px";
		}
	}

	if (window.innerWidth >= 482) {

		if (document.getElementById('sb_main_scan_input')) {
			document.getElementById('sb_main_scan_input').style.width = scanfield_width + 'px';
			document.getElementById('sb_main_scan_input').style.left = '5px';
		}
	}else
	{
		if (document.getElementById('sb_main_scan_input')) {

			//document.getElementById('sb_main_scan_input').style.width = document.getElementsByClassName('awesomplete')[0].clientWidth + 'px';
			document.getElementById('sb_main_scan_input').style.width = scanfield_width + 'px';
			document.getElementById('sb_main_scan_input').style.left = '13px';
		}
	}


}

function fill_workspace(dm=null)
{
	if (dm == null)
	{
		dm = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;
	}

	if (frappe.route_history.length > 0)
	{
		if ((frappe.route_history[frappe.route_history.length - 1][0].toUpperCase() != 'FORM') && (frappe.route_history[frappe.route_history.length - 1][0].toUpperCase() != 'LIST'))
		{
			if (document.getElementById('sb_main_scan_section')) {

				if (document.getElementById('sb_main_scan_check')) {
					document.getElementById('sb_main_scan_check').checked = false;
				}

				document.getElementById('sb_main_scan_section').hidden = true;
			}

		}
	}

    if (cur_page)
    {
		if (cur_page.page)
		{
			if (cur_page.page.page)
			{
				if (cur_page.page.page.title)
				{
					if ((cur_page.page.page.title.toUpperCase() != 'BUILD') && (cur_page.page.page.title.toUpperCase() != 'CUSTOMIZATION') && (cur_page.page.page.title.toUpperCase() != 'TOOLS') &&
						(cur_page.page.page.title.toUpperCase() != 'USERS') && (cur_page.page.page.title.toUpperCase() != 'WEBSITE') && (cur_page.page.page.title.toUpperCase() != 'SETTINGS'))
					{
						if (document.getElementsByClassName('ce-block col-xs-12'))
						{
							let element = document.getElementsByClassName('ce-block col-xs-12');
							if (element[0])
							{
								let cpt = 0;

								if (element[0].className == 'ce-block col-xs-12')
								{
									element[0].setAttribute('data-custom-header', "true")
									if (element[0].style)
									{
										element[0].style.borderRadius = '6px';
										element[0].style.color = '#1f272e';
										element[0].style.padding = '2px';
									}
									cpt = 1;
								}

								for (var i = cpt; i <= element.length-1; i++)
								{
									if (element[i].children[0])
									{
										if (element[i].children[0].children[0])
										{
											if (element[i].children[0].children[0].children[0])
											{
												if (element[i].children[0].children[0].children[0].children[0])
												{
													element[i].setAttribute('data-custom-widget-head', "true")
													element[i].children[0].children[0].children[0].children[0].style.borderRadius = '6px';
													element[i].children[0].children[0].children[0].children[0].style.color = '#1f252e';
													element[i].children[0].children[0].children[0].children[0].style.padding = '2px';

													if (element[i].children[0].children[0].children[0].children[0].children[0])
													{
														if (element[i].children[0].children[0].children[0].children[0].children[0].children[0])
														{
															if (element[i].children[0].children[0].children[0].children[0].children[0].children[0].children[0])
															{
																if (element[i].children[0].children[0].children[0].children[0].children[0].children[0].children[0].outerHTML != '<p>&nbsp;</p>')
																{
																	/*
																	// Temporary fix for missing titles
																	if (element[i].children[0].children[0].hasAttribute('card_name')) {
																		element[i].children[0].children[0].children[0].children[0].children[0].children[0].children[0].outerHTML = '<p>&nbsp;</p>';
																	}
																		*/

																	element[i].children[0].children[0].children[0].children[0].children[0].children[0].children[0].style.height='20px'
																}
															}
														}
													}

													if (element[i].children[0].children[0].children[0].children[1]) {
														for (m=0; m < element[i].children[0].children[0].children[0].children[1].childElementCount; m++) {
															let htmlelement=element[i].children[0].children[0].children[0].children[1].childNodes[m]
															let url = htmlelement.href;

															if(!url){
																continue
															}
															url = url.substring(url.lastIndexOf('/')+1, url.length);
															url = url.replaceAll('-', '_');
															url = url.replaceAll('%20', '_');
															url = url.toLowerCase();
															let url1 = url + '.svg';
															let url2 = url + '.png';

															if (images.indexOf(url1.toUpperCase()) != -1) {
																images_flag[images.indexOf(url1.toUpperCase())] = 1;
																url1 = '/assets/silicon_ioi/images/modules/' + url1;
																let html1 = '<img src="' + url1 + '" loading="lazy" width="56px" height="56px" style="horizontal-align:left;"></img>&nbsp;';
																htmlelement.children[0].className = '';
																if (! htmlelement.children[0].innerHTML.trim().includes("img")) {
																	let html2 = htmlelement.children[0].children[0].innerHTML;
																	html2 = '<font style="font-size:14px" >' + html2 + '</font>';
																	//htmlelement.children[0].insertAdjacentHTML('afterbegin',html1)
																	htmlelement.children[0].style.display="flex";
																	htmlelement.children[0].style.alignItems="center";
																	htmlelement.children[0].innerHTML = html1+'<br>&nbsp;&nbsp;' + html2;
																}
															} else if (images.indexOf(url2.toUpperCase()) != -1) {
																images_flag[images.indexOf(url2.toUpperCase())] = 1;
																url2 = '/assets/silicon_ioi/images/modules/' + url2;
																let html1 = '<img src="' + url2 + '" loading="lazy" width="56px" height="56px" style="horizontal-align:left;"></img>&nbsp;';
																htmlelement.children[0].className = '';
																if (! htmlelement.children[0].innerHTML.trim().includes("img")) {
																	let html2 = htmlelement.children[0].children[0].innerHTML;
																	html2 = '<font style="font-size:14px" >' + html2 + '</font>';
																	//htmlelement.children[0].insertAdjacentHTML('afterbegin',html1)
																	htmlelement.children[0].style.display="flex";
																	htmlelement.children[0].style.alignItems="center";
																	htmlelement.children[0].innerHTML = html1+'<br>&nbsp;&nbsp;' + html2;
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	
	let do_refresh = false;

	for (var i = 0; i < images_flag.length; i++)
	{
		if (images_flag[i] == 0)
		{
			do_refresh = true;
			break;
		}
	}

	if (do_refresh)
	{
		let fct_fill = function () { fill_workspace(dm);};
		window.setTimeout(fct_fill, 100);
	}

}
