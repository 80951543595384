frappe.provide('silicon_ioi.scheduling');

const WARNING_REFRESH = 'Refresh document';
const WARNING_EMPTY_ITEMS = 'Change status to handle (-2), to forecasted (-1), to standby (1) for computing the Gantt';
const HARD_FIX = {color: 'red', label: 'Hard fix'};
const ADJUST_FIX = {color: 'orange', label: 'Adjust fix'};
const BASIC_ITEM_COLOR = '#8dd2ff'
const path_scheduling_production_routing = 'silicon_ioi.ioi_manufacturing.ioi_scheduling.ioi_scheduling_production_routing';
const path_scheduling_dossier_routing = 'silicon_ioi.ioi_manufacturing.ioi_scheduling.ioi_scheduling_dossier_routing';
const path_scheduling_production = 'silicon_ioi.ioi_manufacturing.ioi_scheduling.ioi_scheduling_production';
const path_scheduling_dossier = 'silicon_ioi.ioi_manufacturing.ioi_scheduling.ioi_scheduling_dossier';
const path_scheduling_workcenter = 'silicon_ioi.ioi_manufacturing.ioi_scheduling.ioi_scheduling_workcenter';
const path_staff = "silicon_ioi.ioi_enterprise.doctype.ioi_staff.ioi_staff"
//Use only for start and finish because date utc format date
const format_date = {
	timeZone: 'UTC'
};




export class ioiGantt {


	items = [];
	settings = null;
	ganttContainer = null;
	non_working_day = [];
	staff_id_list = [];
	name = '';
	ioiLoadChart = null;
	ioiGanttControl = null;
	//Scaling load chart
	isWaitingToRefreshGanttChartViewSplitterPosition = null;
	isWaitingToRefreshLoadChartViewSplitterPosition = null;
	isWaitingToRefreshLoadChartViewDisplayedTime = null;
	isWaitingToRefreshGanttChartViewDisplayedTime = null;
	last_splitter_move = null;
	fixed_mode = 1;
	remove_fixed_date = 0;
	display_columns = [];



	constructor(frm, html_field, mode, name = '')
	{
		this.frm = frm;
		this.name = ((mode == 0 || mode == 1) ? name : '');
		this.ganttContainer = document.createElement('div');
		this.ganttContainer.id = `${html_field}_ganttChartView`;
		this.mode = cint(mode) //mode = 0: production / mode = 1: dossier / mode = 2: workcenter

		// this.ganttContainer.style.height = '500px';
		// this.ganttContainer.style.overflowY = 'auto';
		// this.ganttContainer.style.display = 'block';

		this.frm.fields_dict[html_field].$wrapper.append(this.ganttContainer);

		this.load_settings();
	}


	load_settings()
	{
		let me = this

		me.settings = { currentTime: new Date(), displayedTime : new Date()};
		me.settings.workingWeekStart  = 1; // Monday
		me.settings.workingWeekFinish = 0; // Sunday
		me.settings.visibleWeekStart  = 1; // Monday
		me.settings.visibleWeekFinish = 0; // Sunday
		me.settings.visibleDayStart   = 0; // 00:00
		me.settings.visibleDayFinish  = 24 * 60 * 60 * 1000; // 24:00
		me.settings.theme = 'ModernBordered';
		me.settings.weekStartDay = 1; // Monday
		me.settings.areStandardTaskLabelsVisible = true;
		me.settings.areSummaryTaskLabelsVisible = true;
		me.settings.isDependencyToolTipVisible = false;
		// me.settings.extendTimelineToEntireWeeks = false; //Requires a recent build of the software, it's a new feature
		//Manage Read only bar chart
		me.settings.isTaskEffortReadOnly = true;
		me.settings.isTaskEffortPreservedWhenStartChangesInGrid = true;
		me.settings.isTaskCompletionReadOnly = !me.is_active_completion;
		me.settings.isDraggingTaskStartEndsEnabled = false;
		me.settings.areTaskPredecessorsReadOnly = true;
		me.settings.allowCreatingStartDependencies = false;
		me.settings.allowCreatingToFinishDependencies = false;
		// me.settings.isGridReadOnly = false
		// me.settings.isReadOnly = false
		me.settings.itemHeight = 40;
		me.settings.barHeight = 24;
		me.settings.barMargin = 8;
		// me.settings.dateTimeFormatter = function (dateTime) { return me.format_datetime_to_string(dateTime)};

		me.settings.isMouseWheelZoomEnabled = false;
		me.settings.isMouseWheelZoomEnabledMinHourWidth = 1;
		me.settings.isMouseWheelZoomEnabledMaxHourWidth = 200;
		//Percent completed based
		me.settings.percentBasedCompletionBar = true;
		// me.settings.gridLines = '#eaeaea';
		// me.settings.standardBarStyle = 'fill: #428bca; fill-opacity: 0.8; stroke: #3b87d9; stroke-width: 0.65px;';
		// let originalStandardTaskTemplate = DlhSoft.Controls.GanttChartView.getDefaultStandardTaskTemplate(me.items, me.ganttChartView, me.settings);
		me.settings.itemTemplate = function (item) {
			let toolTip = document.createElementNS('http://www.w3.org/2000/svg', 'title');
			let toolTipContent = `• ${item.main_operator_id ? item.main_operator_id + ' - ' : ''}${item.description}`;
			toolTipContent += '\n' + 'Start: ' + item.start.toLocaleString('fr-FR', format_date);
			toolTipContent += '\n' + 'Finish: ' + item.finish.toLocaleString('fr-FR', format_date);
			if(item.remaining_hours){
				toolTipContent += '\n' + `Remaining hours: ${flt(item.remaining_hours, 2)} h`;
			}
			if(item.registered_hours){
				toolTipContent += '\n' + `Registered hours: ${flt(item.registered_hours, 2)} h`;
			}
			if(item.percent_completed){
				toolTipContent += '\n' + `Completed: ${flt(item.percent_completed)} %`;
			}
			if(item.resource_distrib_factor){
				toolTipContent += '\n' + `Resource distrib factor: ${flt(item.resource_distrib_factor)}`;
			}
			toolTip.appendChild(document.createTextNode(toolTipContent));
			return toolTip;
		}

		me.settings.extraTaskTemplate = function(item) {
			let ganttChartView = item.ganttChartView;
			let settings = ganttChartView.settings;
			let document = ganttChartView.ownerDocument;
			let svgns = 'http://www.w3.org/2000/svg';
			// let svgGroup = originalStandardTaskTemplate(item);
			let svgGroup = document.createElementNS(svgns, 'g');
			
			let item_left = ganttChartView.getChartPosition(item.start, settings);
			let item_right = ganttChartView.getChartPosition(item.finish, settings);
			let svg_elem = null

			if (item.objects && item_left && item_right) {
				for (const [index, obj] of item.objects.entries()) {

					if (!obj){
						continue
					}

					//Correction min zoom
					if (obj.position_pin == 'end'){
						if ((item_right - item_left) < 12){
							item_right = item_left + 12
						}
					}
					let x = (obj.position_pin == 'start' ? item_left : obj.position_pin == 'end' ? item_right - 1 : 0);
					let y = (obj.position_level == 'top' ? settings.barMargin : obj.position_level == 'bottom' ? settings.barMargin + settings.barHeight : obj.position_level == 'central'? settings.barMargin + settings.barHeight/2: 0);
					let h = settings.barHeight

					if (obj.object == 'cross'){
						svg_elem = document.createElementNS(svgns, 'g');
						let svg_line_1 = document.createElementNS(svgns, 'line');
						let svg_line_2 = document.createElementNS(svgns, 'line');
						svg_line_1.setAttribute('x1', x - h/5);
						svg_line_1.setAttribute('y1', y + h/5);
						svg_line_1.setAttribute('x2', x + h/5);
						svg_line_1.setAttribute('y2', y - h/5);
						svg_line_2.setAttribute('x1', x - h/5);
						svg_line_2.setAttribute('y1', y - h/5);
						svg_line_2.setAttribute('x2', x + h/5);
						svg_line_2.setAttribute('y2', y + h/5);
						svg_elem.setAttribute('stroke', obj.color);
						svg_elem.setAttribute('stroke-width', '2px');

						let svg_hitbox = document.createElementNS(svgns, 'rect');
						svg_hitbox.setAttribute('x', x - h/5 - 5/2); 
						svg_hitbox.setAttribute('y', y - h/5 - 5/2); 
						svg_hitbox.setAttribute('width', (h/5) * 2 + 5); 
						svg_hitbox.setAttribute('height', (h/5) * 2 + 5); 
						svg_hitbox.setAttribute('fill', 'transparent');
						svg_hitbox.setAttribute('stroke', 'transparent'); 

						svg_elem.appendChild(svg_line_1)
						svg_elem.appendChild(svg_line_2)
						svg_elem.appendChild(svg_hitbox)

						//Change mode date on click
						svg_hitbox.addEventListener('click', function(event) {
							if (!me.settings.isReadOnly){
								svg_elem = this.parentNode;
								if (event.ctrlKey){
									if ((obj.position_pin == 'start') && (item.start_mode == 1)){
										svg_elem.setAttribute('stroke', HARD_FIX.color);
						
										let titleElement = svg_elem.querySelector('title');
										if (titleElement) {titleElement.textContent = HARD_FIX.label;}
	
										item.objects[index].info = HARD_FIX.label
										item.objects[index].color = HARD_FIX.color
										item.start_mode = 2
									}
									else if ((obj.position_pin == 'end') && (item.end_mode == 1)){
										svg_elem.setAttribute('stroke', HARD_FIX.color);
						
										let titleElement = svg_elem.querySelector('title');
										if (titleElement) {titleElement.textContent = HARD_FIX.label;}
	
										item.objects[index].info = HARD_FIX.label
										item.objects[index].color = HARD_FIX.color
										item.end_mode = 2
									}
									else if ((obj.position_pin == 'start') && (item.start_mode == 2)){
										svg_elem.setAttribute('stroke', ADJUST_FIX.color);
						
										let titleElement = svg_elem.querySelector('title');
										if (titleElement) {titleElement.textContent = ADJUST_FIX.label;}
	
										item.objects[index].info = ADJUST_FIX.label
										item.objects[index].color = ADJUST_FIX.color
										item.start_mode = 1
									}
									else if ((obj.position_pin == 'end') && (item.end_mode == 2)){
										svg_elem.setAttribute('stroke', ADJUST_FIX.color);
						
										let titleElement = svg_elem.querySelector('title');
										if (titleElement) {titleElement.textContent = ADJUST_FIX.label;}
	
										item.objects[index].info = ADJUST_FIX.label
										item.objects[index].color = ADJUST_FIX.color
										item.end_mode = 1
									}
								}else{
									if (obj.position_pin == 'start'){
										if (item.start_mode == 0){
											svg_elem.setAttribute('stroke', ADJUST_FIX.color);
						
											let titleElement = svg_elem.querySelector('title');
											if (titleElement) {titleElement.textContent = ADJUST_FIX.label;}
	
											item.objects[index].info = ADJUST_FIX.label
											item.objects[index].color = ADJUST_FIX.color
											item.start_mode = 1
										}else{
											svg_elem.setAttribute('stroke', 'transparent');
											item.objects[index].info = ''
											item.objects[index].color = 'transparent'
											item.start_mode = 0
										}
										
									}
									if (obj.position_pin == 'end'){
										if (item.end_mode == 0){
											svg_elem.setAttribute('stroke', ADJUST_FIX.color);
						
											let titleElement = svg_elem.querySelector('title');
											if (titleElement) {titleElement.textContent = ADJUST_FIX.label;}
	
											item.objects[index].info = ADJUST_FIX.label
											item.objects[index].color = ADJUST_FIX.color
											item.end_mode = 1
										}else{
											svg_elem.setAttribute('stroke', 'transparent');
											item.objects[index].info = ''
											item.objects[index].color = 'transparent'
											item.end_mode = 0
										}
									}
								}
								me.compute_scheduled_date(
									{opening_date: me.get_opening_date_from_item(item)},
									item
								);
							}
						});
					}
					if (obj.object == 'left-triangle'){
						svg_elem = document.createElementNS(svgns, 'polygon');
						svg_elem.setAttribute('points', (x - h/3) + ',' + y + ' ' + x  + ',' + (y + h/5) + ' ' + x + ',' + (y - h/5))
						svg_elem.setAttribute('stroke', 'black');
						svg_elem.setAttribute('stroke-width', '0.5px');
						svg_elem.setAttribute('fill', obj.color);
					}
					if (obj.object == 'right-triangle'){
						svg_elem = document.createElementNS(svgns, 'polygon');
						svg_elem.setAttribute('points', (x + h/3) + ',' + y + ' ' + x  + ',' + (y + h/5) + ' ' + x + ',' + (y - h/5))
						svg_elem.setAttribute('stroke', 'black');
						svg_elem.setAttribute('stroke-width', '0.5px');
						svg_elem.setAttribute('fill', obj.color);
					}
					if (svg_elem){
						if (obj.info) {
							let tool_tip = document.createElementNS(svgns, 'title');
							tool_tip.appendChild(document.createTextNode(obj.info));
							svg_elem.appendChild(tool_tip);
						}
						let index = svgGroup.childNodes.length - 1; // Dependency creation thumb.
						svgGroup.insertBefore(svg_elem, svgGroup.childNodes[index]);
					}
				}
			return svgGroup;
			}
		}

		//Scheduling after drag and drop
		me.settings.itemPropertyChangeHandler = function (item, propertyName, isDirect, isFinal) {
			//Drag and drop element
			if (isDirect && isFinal && ((!item.hasChildren && (propertyName == 'start' || propertyName == 'finish')))){
				//Fix the date for the item changed
				let idx = item.index
				if (cint(item.pivot) == 0){
					me.items[idx].end_mode = (me.fixed_mode);
					me.items[idx].start_mode = 0
					me.items[idx].finish = item.finish
				}else{
					me.items[idx].start_mode = (me.fixed_mode);
					me.items[idx].end_mode = 0
					me.items[idx].start = item.start
				}
				//Scheduling method
				me.compute_scheduled_date(
					{"opening_date": (cint(item.pivot) == 0 ? item.finish : item.start)},
					item
				);
			}
			if (isDirect && isFinal && ((!item.hasChildren && (propertyName == 'completedFinish')))){
				//Change scheduling_update mode in percent completed
				let ganttChartView = item.ganttChartView;
				let new_percent_completed = flt(ganttChartView.getItemCompletion(item) * 100, 2);
				let idx = item.index
				me.items[idx].scheduling_update = (flt(new_percent_completed) != 0 ? 2 : 0);
				me.items[idx].percent_completed = flt(new_percent_completed)
				me.compute_scheduled_date();
			}
		}

		//Use to manage display glitch
		me.settings.itemExpansionChangeHandler = function (item, isExpanded) {
			setTimeout(function() {
				me.refresh_gantt();
			})

		};

		me.settings.itemSelectionChangeHandler = function (item, isSelected, isDirect) {
			if (isDirect && me.ioiLoadChart && item.parent){
				if ((me.ioiLoadChart.items.length >= item.index) && (!me.ioiLoadChart.items[item.index - 1].isSelected)){
					setTimeout(function () {
						me.ioiLoadChart.loadChartView.selectItem(me.ioiLoadChart.items[item.index - 1]);
						me.ioiLoadChart.loadChartView.scrollToItem(me.ioiLoadChart.items[item.index - 1]);
					});
				}
			}
			if (isDirect && me.ioiGanttControl.gantt_custom_bar_color){
				me.ioiGanttControl.gantt_custom_bar_color.value = (item.gantt_color? item.gantt_color : BASIC_ITEM_COLOR)
			}
		}

	}


	set_item_context_menu(ioiGanttControl, options){
		let me = this;
		//Context menu item
		me.settings.itemContextMenuHandler = function(e, item){
			//Add context menu 
			ioiGanttControl.itemContextMenuHandler(e, options);
			let scheduleItemMousedown = function() {
				//Manage Double click... 
				if(ioiGanttControl.close_context_menu){
					var schedule_routing_dialog = new frappe.ui.Dialog({
						'title': __("Schedule rounting"),
						'fields': [
							{'fieldname': 'html_canevas', 'fieldtype': 'HTML'}
						],
						primary_action_label: __('Apply'),
						primary_action: function(){
							//Reload Gantt
							if(ioiGanttControl.view_type == 2){
								let res = me.get_items_by_line_machine(
									cint(ioiGanttControl.gantt_select_source_items.value),
									me.name
								)
								me.items = []
								for (var i = 0; i < res.length; i++) {
									//Parent
									me.items = me.items.concat({
										'assignmentsContent' : '',
										'line_machine_id': res[i].data.name,
										'description': res[i].data.name,
										'indentation': 0
									})
									//Detail
									me.items = me.items.concat(me.set_items(null, res[i].routing_detail))
								}
							}else{
								let res = me.get_items_by_workcenter(
									cint(me.mode),
									me.name
								)
								//Set items
								me.items = me.set_items(null, res)
							}

							//Refresh load chart
							if(me.ioiLoadChart){
								me.ioiLoadChart.set_items(me.name);
								me.ioiLoadChart.initialize();
								me.ioiLoadChart.initilialize_settings_gantt(me.ioiLoadChart.settings);
							}

							me.initialize();
							me.refresh_gantt();

							schedule_routing_dialog.hide();
							ioiGanttControl.close_context_menu = true;
						}
					});
					//Create new Gantt
					let ioi_gantt = new silicon_ioi.scheduling.ioiGantt(schedule_routing_dialog, 'html_canevas', me.mode, item.parent_name);
					//Get routings list
					let res = ioi_gantt.get_items_from_routings();
					//Set items on Gantt
					ioi_gantt.items = ioi_gantt.set_items(
						res.data,
						res.routing_detail
					);
					ioi_gantt.initialize_columns();
					ioi_gantt.initialize();
					ioi_gantt.change_timeline(1);
					//Scales by default
					ioi_gantt.ioiGanttControl = new silicon_ioi.scheduling.ioiGanttControl(0);
					ioi_gantt.ioiGanttControl.initialize_scales(ioi_gantt.settings);
					ioi_gantt.settings.isTaskToolTipVisible = false;

					ioi_gantt.refresh_gantt();

					schedule_routing_dialog.$wrapper.find('.modal-dialog').css("max-width", "1080px").css("width", "1080px");
					schedule_routing_dialog.$wrapper.find('.modal-dialog').css("max-height", "450px").css("height", "450px");
					schedule_routing_dialog.show();
					ioiGanttControl.close_context_menu = false;
					e.stopPropagation();
				}
				ioiGanttControl.scheduleItemContextMenu.removeEventListener("mousedown", scheduleItemMousedown);
			}

			//Add event listener
			//Schedule item
			if(ioiGanttControl.scheduleItemContextMenu){
				ioiGanttControl.scheduleItemContextMenu.addEventListener("mousedown",scheduleItemMousedown);
			}

			//Change color
			if(ioiGanttControl.changeColorContextMenu){
				ioiGanttControl.changeColorContextMenu.set_value((item.gantt_color) ? item.gantt_color : null);
				ioiGanttControl.changeColorContextMenu.df.onchange = () => {
					item.gantt_color = ioiGanttControl.changeColorContextMenu.value;
					me.set_item_gantt_color(item, (item.gantt_color? item.gantt_color : BASIC_ITEM_COLOR));
					me.update_gantt_color(item.name, item.gantt_color);
					me.refresh_gantt();
				}
			}
		}
	}


	get_data_routing(item){
		let routing = {}
		if (item){
			routing.idx = item.idx;
			routing.index = item.index;
			routing.name = item.name;
			routing.end_mode = cint(item.end_mode);
			routing.start_mode = cint(item.start_mode);
			routing.start_date = item.start;
			routing.end_date = item.finish;
			routing.pivot = cint(item.pivot);
			routing.scheduling_update = cint(item.scheduling_update);
			routing.percent_completed = flt(item.percent_completed);
			routing.resource_distrib_factor = flt(item.resource_distrib_factor)
			routing.main_operator_id = item.main_operator_id
		}
		return routing
	}


	get_data_routing_list(){
		let routing_list = []
		if (this.items){
			let length = 1 + (this.items.length - 1) / 2
			for (var i = 1; i < length; i++) {
				routing_list.push(this.get_data_routing(this.items[i]))
			}
		}
		return routing_list
	}


	initialize_columns()
	{
		let me = this;
		let columns = []
		//Jump
		if(me.display_columns.includes('jump')){
			columns.push({header: '', allowUserToResize: false, width: 60, cellTemplate: function (item) {
					if(!item.parent_name){
						return item.ganttChartView.ownerDocument.createTextNode(''); 
					}
					var button = document.createElement('div');
					button.innerHTML = `
						<button title="${__("Jump")}" data-label="Jump doc" class="btn btn-default btn-xs ellipsis" style="margin-left: 10px;">
							<i class="fa fa-search"></i>
						</button>`;
						button.onclick = () => {
							let url = '';
							let id_nav_gantt = '';
							if(me.mode == 0){
								url = '/app/ioi-production/' + cstr(item.parent_name);
								id_nav_gantt = 'ioi-production-production_tab_gantt-tab';
							}else if(me.mode == 1){
								url = '/app/ioi-dossier/' + cstr(item.parent_name);
								id_nav_gantt = 'ioi-dossier-dossier_tab_gantt-tab';
							}
							let newWindow = window.open(url, '_blank');
							if(newWindow){
								setTimeout(function() {
									const ganttTab = newWindow.document.getElementById(id_nav_gantt);
									if(ganttTab){
										ganttTab.click();
									}
								}, 1000);
							}
						}
					return button;
				}
			});
		}
		//Line machine
		if(me.display_columns.includes('line_machine_id')){
			columns.push({header: __('Defined line'),width: 250, isReadOnly: true, isTreeView: true, cellTemplate: function (item) {
				return item.ganttChartView.ownerDocument.createTextNode(item.line_machine_id ? item.line_machine_id : ''); } });
		}
		//Parent name
		if(me.display_columns.includes('name')){
			columns.push({header: __('Name'),width: 200, isReadOnly: true, isTreeView: (me.display_columns.includes('line_machine_id') ? false : true), cellTemplate: function (item) {
				return item.ganttChartView.ownerDocument.createTextNode(item.parent_name ? item.parent_name : ''); } });
		}
		//Task
		columns.push({ header: __('Step'), width: 200, isReadOnly: true, isTreeView: (me.display_columns.includes('line_machine_id') ? false : true), cellTemplate: function (item) {
			return item.ganttChartView.ownerDocument.createTextNode(item.content ? item.content : ''); } });
		//Description
		columns.push({ header: __('Description'), width: 200, isReadOnly: true, cellTemplate: function (item) { 
			return item.ganttChartView.ownerDocument.createTextNode(item.description ? item.description : ''); } });
		//Start date
		columns.push(
			{
				header: __('Start date'),
				width: 150,
				cellTemplate: function (item) {
					return DlhSoft.Controls.GanttChartView.dateTimePickerInputColumnTemplateBase(
						document,
						150,
						function () {
							return item.start;
						}, //Getter
						function (value) {//Setter
							let idx = item.index
							if (value != me.items[idx].start){
								//Fix the date for the item changed
								if (cint(item.pivot) == 1){
									me.items[idx].start_mode = (me.fixed_mode);
									me.items[idx].end_mode = 0
									me.items[idx].start = value
									me.compute_scheduled_date(
										{"opening_date": value},
										item
									);
								}
							}
						},
						//isEnabledGetter
						function () {
							return ((cint(item.pivot) == 1 && (!me.settings.isReadOnly) && (cint(item.indentation) > 0)) ? true : false)
						},
						//isVisibleGetter
						function(){return true},
						//isBoldGetter
						function(){
							return (cint(item.indentation) > 0 ? false : true)
						},
						undefined, undefined, undefined, undefined,
						function(date) { return me.format_datetime_to_string_grid(date); }, // date formatter
						function(stringValue) { return me.format_string_to_datetime_grid(stringValue); } // date parser
					);
				}
			}
		);
		//End date
		columns.push(
			{
				header: __('End date'),
				width: 150,
				cellTemplate: function (item) {
					return DlhSoft.Controls.GanttChartView.dateTimePickerInputColumnTemplateBase(
						document,
						150,
						//Getter
						function () {
							return item.finish;
						},
						//Setter
						function (value) {
							let idx = item.index
							if (value != me.items[idx].finish){
								//Fix the date for the item changed
								if (cint(item.pivot) == 0){
									me.items[idx].end_mode = (me.fixed_mode);
									me.items[idx].start_mode = 0
									me.items[idx].finish = value
									me.compute_scheduled_date(
										{"opening_date": value},
										item
									);
								}
							}
						},
						//isEnabledGetter
						function () {
							return ((cint(item.pivot) == 0 && (!me.settings.isReadOnly) && (cint(item.indentation) > 0)) ? true : false)
						},
						//isVisibleGetter
						function(){return true},
						//isBoldGetter
						function(){
							return (cint(item.indentation) > 0 ? false : true)
						},
						undefined, undefined, undefined, undefined,
						function(date) { return me.format_datetime_to_string_grid(date); }, // date formatter
						function(stringValue) { return me.format_string_to_datetime_grid(stringValue); } // date parser
					);
				}
			}
		);
		//Remaining hours
		columns.push({ header: __('Remaining hours'), width: 150, isReadOnly: true, cellTemplate: function (item) { 
			return item.ganttChartView.ownerDocument.createTextNode(item.remaining_hours ? item.remaining_hours : ''); } });
		//Registered hours
		columns.push({ header: __('Registered hours'), width: 150, isReadOnly: true, cellTemplate: function (item) { 
			return item.ganttChartView.ownerDocument.createTextNode((item.registered_hours ? item.registered_hours : '')); } });
		//Operator
		if (!me.settings.isReadOnly){me.staff_id_list = me.get_staff_id();}
		if(me.display_columns.includes('main_operator_id')){
			columns.push(
				{
					header: __('Operator'),
					width: 150,
					cellTemplate: function (item) {
						if (item.isReadOnly){
							return item.ganttChartView.ownerDocument.createTextNode((''));
						}
						return DlhSoft.Controls.GanttChartView.optionSelectColumnTemplateBase(
							document,
							130,
							function (){
								return me.staff_id_list
							},
							function (){
								return item.main_operator_id;
							}, //Getter
							function (value){//Setter
								let res = cstr(value, '')
								let idx = item.index;
								if (res != me.items[idx].main_operator_id){
									me.items[idx].main_operator_id = res;
									me.compute_scheduled_date(
										{opening_date: me.get_opening_date_from_item(item)},
										item
									);
								}
							},
							function (){
								return !(item.isReadOnly || me.settings.isReadOnly);
							}
						);

					}
				}
			);
		};
		//Workcenter
		columns.push({ header: __('Workcenter'), width: 150, isReadOnly: true, cellTemplate: function (item) { 
			return item.ganttChartView.ownerDocument.createTextNode((item.workcenter ? item.workcenter : '')); } });
		//Ressource distrib factor
		columns.push(
			{
				header: __('Resource distrib factor'),
				width: 150,
				cellTemplate: function (item) {
					return DlhSoft.Controls.GanttChartView.textInputColumnTemplateBase(
						document,
						64,
						function (){
							return item.resource_distrib_factor;
						}, //Getter
						function (value){//Setter
							let res = flt(value, 1)
							let idx = item.index;
							if (res != me.items[idx].resource_distrib_factor){
								if (res > 0){
									me.items[idx].resource_distrib_factor = res;
									me.compute_scheduled_date(
										{opening_date: me.get_opening_date_from_item(item)},
										item
									);
								}else{
									me.items[idx].resource_distrib_factor = 1;
									me.refresh_gantt();
								}
							}
						},
						function (){
							return !(item.isReadOnly || me.settings.isReadOnly);
						}
					);
				}
			}
		);
		//Arrival date
		columns.push({ header: __('Arrival date'), width: 150, isReadOnly: true, cellTemplate: function (item) { 
			return item.ganttChartView.ownerDocument.createTextNode((item.arrival ? item.arrival.toLocaleString('fr-FR') : '')); } });
		//End limit date
		columns.push({ header: __('End limit date'), width: 150, isReadOnly: true, cellTemplate: function (item) { 
			return item.ganttChartView.ownerDocument.createTextNode((item.end_limit ? item.end_limit.toLocaleString('fr-FR') : '')); } });
		//Relay date
		columns.push({ header: __('Relay date'), width: 150, isReadOnly: true, cellTemplate: function (item) { 
			return item.ganttChartView.ownerDocument.createTextNode((item.relay ? item.relay.toLocaleString('fr-FR') : '')); } });

		me.settings.columns = columns
	}


	format_datetime_to_string(datetime){
		const day = String(datetime.getDate()).padStart(2, '0');
		const month = String(datetime.getMonth() + 1).padStart(2, '0');
		const year = datetime.getFullYear();
		const hours = String(datetime.getHours()).padStart(2, '0');
		const minutes = String(datetime.getMinutes()).padStart(2, '0');
		return `${year}-${month}-${day} ${hours}:${minutes}`;
	}

	format_datetime_to_string_grid(datetime){
		const day = String(datetime.getDate()).padStart(2, '0');
		const month = String(datetime.getMonth() + 1).padStart(2, '0');
		const year = datetime.getFullYear();
		const hours = String(datetime.getHours()).padStart(2, '0');
		const minutes = String(datetime.getMinutes()).padStart(2, '0');
		return `${day}/${month}/${year} ${hours}:${minutes}`;
	}

	format_string_to_datetime_grid(dateString){
		const regex = /^(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2})$/;
		const match = dateString.match(regex);
		const [, day, month, year, hours, minutes] = match.map(Number);
		return new Date(year, month - 1, day, hours, minutes);
	}


	initialize_settings_non_working(){
		let me = this;
		if(me.ioiGanttControl){
			if (!me.non_working_day.length){
				me.get_spec_day_canevas();
				me.settings.isIndividualItemNonworkingTimeHighlighted = true;
				// me.settings.areTaskInterruptionsHighlighted = true;
				me.initialize_schedule_spec_day_canevas()
			}else{
				me.non_working_day = [];
				me.settings.isIndividualItemNonworkingTimeHighlighted = false;
				me.clear_schedule_spec_day_canevas()
			}
		}
	}


	initialize_scales_zoom_level(zoom_level, min, max){
		//Zoom level
		this.settings.updateScale = 15 * 60 * 1000; //15 min
		if (flt(zoom_level) > 0)
			if (min){
				this.settings.isMouseWheelZoomEnabledMinHourWidth = cint(min);
			}
			if (max){
				this.settings.isMouseWheelZoomEnabledMaxHourWidth = cint(max);
			}
			this.settings.hourWidth = flt(zoom_level);
	}


	change_timeline(time_amount_day)
	{
		//By default
		silicon_ioi.scheduling.ioiGanttControl.change_timeline(this.settings, time_amount_day);
		if(this.items.length){
			let item = this.ganttChartView.getSelectedItem();
			if(item){
				//Selected item
				if((!isNaN(item.start))){
					silicon_ioi.scheduling.ioiGanttControl.change_timeline(this.settings, time_amount_day, item);
				}
			}else{
				//No selected item
				if(!isNaN(this.items[0].start)){
					silicon_ioi.scheduling.ioiGanttControl.change_timeline(this.settings, time_amount_day, this.items[0]);
				}
			}
		}
	}


	change_completion_mode(is_active){
		this.settings.isTaskCompletionReadOnly = !is_active;
	}


	initialize_schedule_spec_day_canevas(){
		let non_working_day_date = [];
		if (this.non_working_day && this.non_working_day.length){
			for (var i = 0; i < this.non_working_day.length; i++){
				non_working_day_date = [];
				//Convert string in datetime
				for (var j = 0; j < this.non_working_day[i].length; j++) {
					non_working_day_date.push((new Date(this.non_working_day[i][j])));
				}
				this.items[i + 1].schedule = {specialNonworkingDays: non_working_day_date};
			}
		}
	}

	clear_schedule_spec_day_canevas(){
		for (var i = 1; i < this.items.length; i++) {
			delete this.items[i].schedule;
		}
	}


	refresh_gantt(){
		if (this.ganttChartView) {
			this.ganttChartView.refresh()
		}
	}


	scroll_to_first_step(){
		let me = this
		if (me.items.length > 1){
			if (!isNaN(me.items[0].start)){
				setTimeout(function () {
					let start_date = new Date(me.items[0].start);
					start_date.setHours(0);
					start_date.setMinutes(0);
					start_date.setSeconds(0);
					start_date.setMilliseconds(0);
					me.ganttChartView.selectItem(me.items[0]);
					me.ganttChartView.scrollToItem(me.items[0]);
					// me.settings.displayedTime = start_date;
					me.ganttChartView.scrollToDateTime(start_date);
				})
			}
		}
	}


	scroll_to_selected_item(){
		let me = this;
		if (me.items && me.items.length > 1){
			let item = me.ganttChartView.getSelectedItem()
			if (!isNaN(item.start)){
				setTimeout(function () {
					let start_date = new Date(item.start);
					start_date.setHours(0);
					start_date.setMinutes(0);
					start_date.setSeconds(0);
					start_date.setMilliseconds(0);
					// this.ganttChartView.selectItem(item);
					// this.ganttChartView.scrollToItem(item);
					// me.settings.displayedTime = start_date;
					me.ganttChartView.scrollToDateTime(start_date);
				})
			}
		}
	}


	initialize()
	{
		if(this.ioiGanttControl){
			this.ioiGanttControl.initialize_scales(this.settings);
			//Item empty and view type: Gantt (dossier and production)
			if (this.items.length == 0 && [0].includes(this.ioiGanttControl.view_type)){
				this.ioiGanttControl.display_scheduling_warning_message(1, WARNING_EMPTY_ITEMS);
			}
		}

		this.ganttChartView = ioi.DlhSoft.Controls.GanttChartView.initialize(
			this.ganttContainer,
			this.items,
			this.settings
		);

	}


	set_items(doc, routing_detail, scope = {})
	{
		let me = this;
		let items = [];
		if (routing_detail && routing_detail.length != 0) {
			let idx = (scope.idx != null ? scope.idx : 0);
			let pivot = (scope.pivot != null ? scope.pivot : -1);
			let item = {};
			//Parent item
			if(doc){
				if (idx != 0){
					item = me.items[0];
				}

				//Data header
				item.assignmentsContent = ''
				item.content = cstr(doc.name)
				item.description = cstr(doc.name)
				item.start = (doc.start_scheduled ? new Date(doc.start_scheduled) : '')
				item.finish = (doc.end_scheduled ? new Date(doc.end_scheduled) : '')
				item.isReadOnly = true

				//Data header part
				const parts = routing_detail.map(row => ({
					content: row.step_id,
					start: row.start_scheduled ? new Date(row.start_scheduled) : '',
					finish: row.end_scheduled ? new Date(row.end_scheduled) : '',
					description: row.description,
					isReadOnly: true,
					standardBarStyle: 'fill: Gray; fill-opacity: 0.5; stroke: black; stroke-width: 0.65px;',
				}));

				if (idx == 0){
					item.parts = parts
					items = [item];
				}else{
					//Add new parts to items
					if(pivot == 0){
						item.parts.splice(0, idx);
						me.items.splice(1 + (me.items.length - 1) / 2, idx);
						item.parts.unshift(...parts)
					}else if(pivot == 1){
						item.parts.splice(idx - 1);
						me.items.splice(idx + (me.items.length - 1) / 2);
						item.parts.push(...parts)
					}
				}
			}
			//Child item
			for (var i = 0; i < routing_detail.length; i++) {
				let row = routing_detail[i];
				item = (idx == 0 ? {} : (pivot == 0 ? me.items[1 + i] : me.items[idx + i]))
				//Data item
				item.name = (row.name ? row.name : '');
				item.parent_name = (row.parent_name ? row.parent_name : '');
				item.assignmentsContent = me.get_assignements_content(row)
				item.idx = row.idx
				item.content = row.step_id
				item.indentation = cint(row.indentation)
				item.start = (row.start_scheduled ? new Date(row.start_scheduled) : '')
				item.finish = (row.end_scheduled ? new Date(row.end_scheduled) : '')
				item.arrival = (row.arrival_scheduled ? new Date(row.arrival_scheduled) : '')
				item.relay = (row.relay_scheduled ? new Date(row.relay_scheduled) : '')
				item.end_limit = (row.end_limit_scheduled ? new Date(row.end_limit_scheduled) : '')
				item.description = row.description
				item.main_operator_id = row.main_operator_id
				item.workcenter = row.workcenter_id
				item.line_machine_id = row.line_machine_id
				item.remaining_hours = (row.workcenter_mode == 0 ? flt(row.remaining_op_hours, 2) : flt(row.remaining_wc_hours, 2))
				item.registered_hours = (row.workcenter_mode == 0 ? flt(row.registered_worker_hours, 2) : flt(row.registered_workcenter_hours, 2))
				item.pivot = row.header_scheduling_pivot
				item.header_pivot = row.header_scheduling_pivot
				item.start_mode = row.start_mode
				item.end_mode = row.end_mode
				item.scheduling_update = row.scheduling_update
				item.completedFinish = row.completed
				item.percent_completed = flt(row.completed * 100, 2)
				item.resource_distrib_factor = flt(row.ressource_distrib_factor, 1)
				item.isRelativeToTimezone = true
				item.predecessors = me.get_predecessors_items(
					(idx == 0 ? items : me.items), 
					row.predecessors ? cint(row.predecessors) : cint(row.idx - 1), 
					'FS'
				)
				//Color
				item.gantt_color = row.gantt_color;
				me.set_item_gantt_color(item, row.gantt_color);
				//Pivot
				item.objects = [
					{
						position_pin: (cint(row.header_scheduling_pivot) == 0 ? 'end' : 'start'),
						position_level: 'bottom', 
						object: (cint(row.header_scheduling_pivot) == 0 ? 'left-triangle' : 'right-triangle'), 
						color: 'green', 
						info: (cint(row.header_scheduling_pivot) == 0 ? __('At the lastest') : __('At the soonest'))
					}
				];
				//Fixed date: start mode
				if ([0,1,2].includes(cint(row.start_mode))){
					item.objects.push(
						{
							position_pin: 'start', 
							position_level: 'top', 
							object: 'cross', 
							color: (cint(row.start_mode) == 1 ? 'orange' : cint(row.start_mode) == 2 ? 'red': 'transparent'), 
							info: (cint(row.start_mode) == 1 ? 'Adjust fix' : cint(row.start_mode) == 2 ? 'Hard fix': '')
						}
					)
				}
				//Fixed date: end mode
				if ([0,1,2].includes(cint(row.end_mode))){
					item.objects.push(
						{
							position_pin: 'end', 
							position_level: 'top', 
							object: 'cross', 
							color: (cint(row.end_mode) == 1 ? 'orange' : cint(row.end_mode) == 2 ? 'red': 'transparent'), 
							info: (cint(row.end_mode) == 1 ? 'Adjust fix' : cint(row.end_mode) == 2 ? 'Hard fix': '')
						}
					)
				}
				if (idx == 0){
					items.push(item);
				}
			}
		}
		return items;
	}


	get_predecessors_items(items, idx, dependency_type){
		if (idx == -1){
			return []
		} 
		for (var i = 0; i < items.length; i++) {
			if (items[i].idx == idx){
				return [{item: items[i], dependencyType: dependency_type}]
			}
		}
		return []
	}


	set_item_gantt_color(item, color){
		if(color){
			item.standardBarStyle = `
				fill: ${color};
				fill-opacity: 0.8;
				stroke: ${silicon_ioi.scheduling.ioiGanttControl.darken_color(color, 30)};
				stroke-width: 0.65px;
			`
			item.standardCompletedBarStyle = `
				fill: ${silicon_ioi.scheduling.ioiGanttControl.darken_color(color, 30)};
				stroke: ${silicon_ioi.scheduling.ioiGanttControl.darken_color(color, 30)}
			`
		}
	}


	update_cur_frm(data){
		let me = this
		if (data){
			//Header
			me.frm.set_value(data.data);
			//Routing
			for (var i = 0; i < data.routing_detail.length; i++) {
				for (let f in data.routing_detail[i]) {
					let v = data.routing_detail[i][f];
					frappe.model.set_value(me.doctype_routing, data.routing_detail[i].name, f, v);
				}
			}
		}
		me.frm.enable_save();
	}


	get_assignements_content(row){
		let label_displayed = ''
		label_displayed = (row.main_operator_id ? row.main_operator_id + ' - ' : '') + row.description
		if (flt(row.ressource_distrib_factor) != 1){
			label_displayed += ' '
			label_displayed += `[x${row.ressource_distrib_factor}]`
		}
		return label_displayed
	}


	compute_scheduled_date(args = {}, item = null){
		let me = this;
		let res = {}
		let method = ''
		if (me.mode == 0){
			method = path_scheduling_production + '.ioi_production_compute_scheduled_date';
		}else if(me.mode == 1){
			method = path_scheduling_dossier + '.ioi_dossier_compute_scheduled_date';
		}

		//Add name if do not exist
		if (!('name' in args)){
			args['name'] = me.name
		}
		//Add routing_list if do not exist
		if (!('routing_list' in args)){
			args['routing_list'] = me.get_data_routing_list()
		}
		//Format opening date
		if ('opening_date' in args){
			let opening_date = args['opening_date']
			if(opening_date instanceof Date){
				opening_date = me.format_datetime_to_string(opening_date) //format string: "%Y-%m-%d %H:%M:%S.%f"
			}
			args['opening_date'] = opening_date
		}
		//Add idx (compute from one item)
		if(item){
			item = me.get_data_routing(item)
		}

		if (item && (!('idx' in args))){
			args['idx'] = item.idx
		}

		frappe.call({
			method: method,
			args: args,
			async: false,
			callback:function(r){
				res =  r.message;
				me.set_items(
					res.data,
					res.routing_detail,
					(item ? {'idx': item.index, 'pivot': item.pivot} : {'idx': 1, 'pivot': 1})
				)
				if(me.ioiGanttControl){
					if(me.ioiGanttControl.gantt_timeline_input){
						me.change_timeline(cint(me.ioiGanttControl.gantt_timeline_input.value));
					}else{
						if(me.items.length){
							silicon_ioi.scheduling.ioiGanttControl.change_timeline(me.settings, 1, me.items[0]);
						}
					}
				}
				me.scroll_to_selected_item();
				if(me.ioiLoadChart){
					me.ioiLoadChart.set_items(res.data.name);
					me.ioiLoadChart.initialize();
					me.initilialize_settings_load_chart(me.settings);
				}
				me.refresh_gantt();
			}
		});
		// me.update_cur_frm(res)
	}


	remove_all_soft_fixed_date(){
		for (var i = 1; i < this.items.length; i++) {
			if (cint(this.items[i].start_mode) != 2){
				this.items[i].start_mode = 0
			}
			if (cint(this.items[i].end_mode) != 2){
				this.items[i].end_mode = 0
			}
		}
	}

	get_opening_date_from_item(item){
		//At the latest
		if(item.pivot == 0){
			let idx = item.index + 1
			if(this.items.length - 1 > idx){
				return this.items[idx].arrival
			}else{
				return null
			}
		//At the soonest
		}else if(item.pivot == 1){
			let idx = item.index - 1
			if(idx > 0){
				return this.items[idx].relay
			}else{
				return null
			}
		}
	}


	//Control
	make_gantt_control(
		html_timeline_field = '',
		html_scheduling_field = '',
		html_settings = '',
		options_context_menu = [],
		view_type = 0
	)
	{

		this.ioiGanttControl = new silicon_ioi.scheduling.ioiGanttControl(view_type, false);

		if(html_scheduling_field){
			this.get_make_gantt_scheduling_html(html_scheduling_field);
		}
		if (html_timeline_field){
			this.get_gantt_timeline(this.ioiGanttControl, html_timeline_field);
			this.get_selected_routing_control(this.ioiGanttControl);
		}
		// if (html_settings){
		// 	this.get_make_gantt_settings_html(html_settings);
		// }
		// if(options_context_menu && options_context_menu.length){
		// 	this.get_gantt_context_menu_html(this.ioiGanttControl, options_context_menu)
		// }
	}


	get_gantt_context_menu_html(ioiGanttControl, options = []){
		let me = this;

		if(options.length){
			ioiGanttControl.make_gantt_context_menu_html();
			me.set_item_context_menu(ioiGanttControl, options);
		}
	}


	get_make_gantt_settings_html(html_field){
		let me = this;
		me.frm.fields_dict[html_field].$wrapper.empty();
		me.frm.fields_dict[html_field].$wrapper.append(`
			<div class="d-flex flex-column">
				<div id="html_col_1" class="d-flex flex-row mr-2"></div>
				<div id="html_col_2" class="d-flex flex-row mr-2"></div>
			</div>
		`);
		const gantt_settings_allow_manual_completion = frappe.ui.form.make_control({
			parent: me.frm.fields_dict[html_field].$wrapper.find("#html_col_1"),
			df: {
				label: __('Allow manual completion'),
				fieldname: 'gantt_settings_allow_manual_completion',
				fieldtype: 'Check',
				onchange: () => {
					if (cint(gantt_settings_allow_manual_completion.value) == cint(me.settings.isTaskCompletionReadOnly)){
						me.change_completion_mode(gantt_settings_allow_manual_completion.value)
						me.refresh_gantt();
					}
				}
			},
			render_input: true
		})
		//Default value
		gantt_settings_allow_manual_completion.value = 0;


		const gantt_settings_drag_for_hard_fix = frappe.ui.form.make_control({
			parent: me.frm.fields_dict[html_field].$wrapper.find("#html_col_2"),
			df: {
				label: __('Drag for hard fix'),
				fieldname: 'gantt_settings_drag_for_hard_fix',
				fieldtype: 'Check',
				onchange: () => {
					me.fixed_mode = (gantt_settings_drag_for_hard_fix.value ? 2 : 1)
				}
			},
			render_input: true
		})
		//Default value
		gantt_settings_drag_for_hard_fix.value = 0;
	}


	get_make_gantt_scheduling_html(html_field)
	{
		let me = this;
		me.frm.fields_dict[html_field].$wrapper.empty();
		me.frm.fields_dict[html_field].$wrapper.append(`
			<div class="d-flex flex-column">
				<div class="d-flex flex-row flex-wrap align-items-start justify-content-start">
					<div class="d-flex flex-row flex-wrap align-items-center mr-4">
						<div id="html_col_1" class="d-flex flex-row mr-2"></div>
						<div id="html_col_2" class="d-flex flex-row mr-2"></div>
					</div>
					<div class="d-flex flex-row flex-wrap align-items-center mr-4">
						<div id="html_col_3" class="d-flex flex-row mr-2"></div>
						<div id="html_col_4" class="d-flex flex-row mr-2"></div>
					</div>
					<div id="html_col_5" class="d-flex flex-column mr-4">
						<label id="gantt_priority_value" for="gantt_priority_level" class="form-label d-block">Priority</label>
						<input type="range" class="form-range d-block" min="0" max="9" step="1" id="gantt_priority_level">
					</div>
				</div>
				<div class="d-flex flex-row flex-wrap align-items-start justify-content-start">
					<div id="html_col_6" class="d-flex flex-row mr-4"></div>
				</div>
			</div>
		`);



		//Priority level on change
		if (document.getElementById('gantt_priority_level')){
			const gantt_priority_level = document.getElementById('gantt_priority_level');
			gantt_priority_level.value = 0;
			gantt_priority_value.textContent = `Priority: ${gantt_priority_level.value}`;
			gantt_priority_level.onchange = () => {
				gantt_priority_value.textContent = `Priority: ${gantt_priority_level.value}`;
				if(me.remove_fixed_date){
					me.remove_all_soft_fixed_date()
				}
				me.compute_scheduled_date({
					"priority": cint(gantt_priority_level.value),
				});
			};
		}


		const gantt_date_scheduling_at_soonest = frappe.ui.form.make_control({
			parent: me.frm.fields_dict[html_field].$wrapper.find("#html_col_1"),
			df: {
				label: __('Schedule at the soonest'),
				fieldname: 'gantt_date_scheduling_at_soonest',
				fieldtype: 'Datetime'
			},
			render_input: true
		})
		//Default value
		gantt_date_scheduling_at_soonest.set_value(this.frm.doc.start_after);


		const gantt_btn_scheduling_at_soonest = frappe.ui.form.make_control({
			parent: me.frm.fields_dict[html_field].$wrapper.find("#html_col_2"),
			df: {
				label: __('<i class="fa fa-caret-square-o-right"></i>'),
				fieldname: 'gantt_btn_scheduling_at_soonest',
				fieldtype: 'Button',
				click: () => {
					if(me.remove_fixed_date){
						me.remove_all_soft_fixed_date()
					}
					me.compute_scheduled_date({
						"pivot": 1, 
						"opening_date": gantt_date_scheduling_at_soonest.value
					});
				}
			},
			render_input: true
		})

		const gantt_date_scheduling_at_latest = frappe.ui.form.make_control({
			parent: me.frm.fields_dict[html_field].$wrapper.find("#html_col_3"),
			df: {
				label: __('Schedule at the latest'),
				fieldname: 'gantt_date_scheduling_at_latest',
				fieldtype: 'Datetime'
			},
			render_input: true
		})
		//Default value
		if(me.mode == 0){
			gantt_date_scheduling_at_latest.set_value(this.frm.doc.asked_delivery_date);
		}else if (me.mode == 1){
			gantt_date_scheduling_at_latest.set_value(this.frm.doc.asked_delivery_on);
		}


		const gantt_btn_scheduling_at_latest = frappe.ui.form.make_control({
			parent: me.frm.fields_dict[html_field].$wrapper.find("#html_col_4"),
			df: {
				label: __('<i class="fa fa-caret-square-o-left"></i>'),
				fieldname: 'gantt_btn_scheduling_at_latest',
				fieldtype: 'Button',
				click: () => {
					if(me.remove_fixed_date){
						me.remove_all_soft_fixed_date()
					}
					me.compute_scheduled_date({
						"pivot": 0, 
						"opening_date": gantt_date_scheduling_at_latest.value,
					});
				}
			},
			render_input: true
		})

		const gantt_check_remove_all_fixed_date = frappe.ui.form.make_control({
			parent: me.frm.fields_dict[html_field].$wrapper.find("#html_col_6"),
			df: {
				label: __('Remove manual adjust'),
				fieldname: 'gantt_check_remove_all_fixed_date',
				fieldtype: 'Check',
				onchange: () => {
					me.remove_fixed_date = (gantt_check_remove_all_fixed_date.value ? 1 : 0);
				}
			},
			render_input: true
		})

	}


	get_gantt_timeline(ioiGanttControl, html_field){
		let me = this;
		// me.ioiGanttControl = new silicon_ioi.scheduling.ioiGanttControl(view_type);
		ioiGanttControl.timeline_control = true;

		me.frm.fields_dict[html_field].$wrapper.empty();
		ioiGanttControl.make_timeline_html(me.frm.fields_dict[html_field], html_field);
		me.frm.fields_dict[html_field].$wrapper.append(me.ganttContainer);

		//Change Timeline
		if (ioiGanttControl.gantt_timeline_btn){
			ioiGanttControl.gantt_timeline_btn.onclick = () => {
				me.change_timeline(cint(ioiGanttControl.gantt_timeline_input.value))
				me.initilialize_settings_load_chart(me.settings)
				me.refresh_gantt();
			};
		}

		//Decrease Timeline
		if (ioiGanttControl.gantt_decrease_timeline_btn){
			ioiGanttControl.gantt_decrease_timeline_btn.onclick = () => {
				ioiGanttControl.decrease_timeline(
					me.ganttChartView,
					cint(ioiGanttControl.gantt_timeline_input.value)
				)
				me.initilialize_settings_load_chart(me.settings)
				me.refresh_gantt();
			};
		}

		//Increase Timeline
		if (ioiGanttControl.gantt_increase_timeline_btn){
			ioiGanttControl.gantt_increase_timeline_btn.onclick = () => {
				ioiGanttControl.increase_timeline(
					me.ganttChartView,
					cint(ioiGanttControl.gantt_timeline_input.value)
				)
				me.initilialize_settings_load_chart(me.settings)
				me.refresh_gantt();
			};
		}

		//Show non-working day
		if (ioiGanttControl.gantt_show_non_working_day){
			ioiGanttControl.gantt_show_non_working_day.onclick = () => {
				ioiGanttControl.initialize_scales(me.settings);
				me.initialize_settings_non_working()
				me.refresh_gantt();
			}
		}

		//Allow manual completion
		if (ioiGanttControl.gantt_allow_manual_completion_selected_routing){
			ioiGanttControl.gantt_allow_manual_completion_selected_routing.addEventListener('click', function () {
				let isActive = cint(ioiGanttControl.gantt_allow_manual_completion_selected_routing.classList.contains('active'))
				if (isActive == cint(me.settings.isTaskCompletionReadOnly)){
					me.change_completion_mode(isActive)
					me.refresh_gantt();
				}
			});
		}

		//Drag for hard fix
		if (ioiGanttControl.gantt_drag_for_hard_fix_selected_routing){
			ioiGanttControl.gantt_drag_for_hard_fix_selected_routing.addEventListener('click', function () {
				let isActive = cint(ioiGanttControl.gantt_drag_for_hard_fix_selected_routing.classList.contains('active'))
				me.fixed_mode = (isActive ? 2 : 1)
			});
		}

		//Show load chart
		if (ioiGanttControl.gantt_show_load_chart){
			ioiGanttControl.gantt_show_load_chart.onclick = () => {
				if (!me.ioiLoadChart){
					me.ioiLoadChart = new silicon_ioi.scheduling.ioiLoadChart(me.frm, html_field, me.mode);
					me.ioiLoadChart.set_items(me.frm.doc.name);
					me.ioiLoadChart.settings.scales = me.settings.scales;
					me.ioiLoadChart.initialize_columns();
					me.ioiLoadChart.initialize();
					me.last_splitter_move = 'GanttChart';
					me.initilialize_settings_load_chart(me.settings);
					me.sync_timeline_load_chart();
					//Adjust zoom level when scroll into chart
					// me.ioiLoadChart.settings.hourWidthChangeHandler = function (hourWidth) {
					// 	
					// 	me.gantt_zoom_level.value = cint(hourWidth)
					// 	me.initialize_scales_zoom_level(
					// 		me.gantt_zoom_level.value,
					// 		me.gantt_zoom_level.getAttribute('min'),
					// 		me.gantt_zoom_level.getAttribute('max')
					// 	);
					// 	if (me.ioiLoadChart){
					// 		me.ioiLoadChart.initialize_scales_zoom_level(
					// 			me.settings.hourWidth,
					// 			me.settings.isMouseWheelZoomEnabledMinHourWidth,
					// 			me.settings.isMouseWheelZoomEnabledMaxHourWidth
					// 		)
					// 		me.ioiLoadChart.refresh_load_chart();
					// 	}
					// 	me.refresh_gantt();
					// }
					//Same Display time


					// me.settings.displayedTimeChangeHandler = me.ioiLoadChart.settings.displayedTimeChangeHandler = function (displayedTime) {
					// 	if (displayedTime != me.ioiLoadChart.settings.displayedTime){
					// 		me.ioiLoadChart.loadChartView.scrollToDateTime(displayedTime);
					// 	}
					// 	if (displayedTime != me.settings.displayedTime){
					// 		me.ganttChartView.scrollToDateTime(displayedTime);
					// 	}
					// };

					//Display zoom load chart
					if (ioiGanttControl.group_zoom_load_chart){
						ioiGanttControl.group_zoom_load_chart.style.display = 'block';
						//By default
						ioiGanttControl.loadchart_zoom_level.value = 10;
					}

				}else{
					if (me.ioiLoadChart.loadChartContainer){
						me.ioiLoadChart.loadChartContainer.remove();
						if (ioiGanttControl.group_zoom_load_chart){
							ioiGanttControl.group_zoom_load_chart.style.display = 'none';
						}
					}
					me.ioiLoadChart = null;
					me.settings.displayedTimeChangeHandler = function (displayedTime) {}
					me.settings.itemSelectionChangeHandler = function (item, isSelected, isDirect) {}
					me.settings.splitterPositionChangeHandler = function (gridWidth, chartWidth) {}

				}
			}
		}

		//Change Major scale
		if (ioiGanttControl.gantt_major_scale_type_select){
			ioiGanttControl.gantt_major_scale_type_select.onchange = () => {
				ioiGanttControl.update_from_selected_major_scale_type();
				ioiGanttControl.initialize_scales(me.settings);
				me.initilialize_settings_load_chart(me.settings);
				me.refresh_gantt();
			}
		}

		//Change Major format
		if (ioiGanttControl.gantt_major_scale_format_select){
			ioiGanttControl.gantt_major_scale_format_select.onchange = () => {
				ioiGanttControl.initialize_scales(me.settings);
				me.initilialize_settings_load_chart(me.settings);
				me.refresh_gantt();
			}
		}

		//Change Minor scale
		if (ioiGanttControl.gantt_minor_scale_type_select){
			ioiGanttControl.gantt_minor_scale_type_select.onchange = () => {
				ioiGanttControl.update_from_selected_minor_scale_type();
				ioiGanttControl.initialize_scales(me.settings);
				me.initilialize_settings_load_chart(me.settings);
				me.refresh_gantt();
			}
		}

		//Change Minor format
		if (ioiGanttControl.gantt_minor_scale_format_select){
			ioiGanttControl.gantt_minor_scale_format_select.onchange = () => {
				ioiGanttControl.initialize_scales(me.settings);
				me.initilialize_settings_load_chart(me.settings);
				me.refresh_gantt();
			}
		}

		if (ioiGanttControl.gantt_zoom_level){
			//Change zoom level
			ioiGanttControl.gantt_zoom_level.oninput = () => {
				ioiGanttControl.initialize_scales_zoom_level(
					me.settings,
					ioiGanttControl.gantt_zoom_level.value,
					ioiGanttControl.gantt_zoom_level.getAttribute('min'),
					ioiGanttControl.gantt_zoom_level.getAttribute('max')
				);
				me.initilialize_settings_load_chart(me.settings)
				me.refresh_gantt();
			}
		}

		if (ioiGanttControl.loadchart_zoom_level){
			ioiGanttControl.loadchart_zoom_level.oninput = () => {
				if (me.ioiLoadChart){
					me.ioiLoadChart.initialize();
					me.ioiLoadChart.change_item_height(ioiGanttControl.loadchart_zoom_level.value);
					me.ioiLoadChart.refresh_load_chart();
				}
			}
		}

		// if (me.ioiGanttControl.gantt_select_source_items){
		// 	//Gantt(workcenter and line machine)
		// 	if(view_type == 2){
		// 		//Remove first option
		// 		me.ioiGanttControl.gantt_select_source_items.remove(0)
		// 		me.ioiGanttControl.gantt_select_source_items.onchange = () => {
		// 			if(me.ioiGanttControl.gantt_select_source_items.value != -1){
		// 				me.mode = cint(me.ioiGanttControl.gantt_select_source_items.value)

		// 				let res = me.get_items_by_line_machine(
		// 					cint(me.ioiGanttControl.gantt_select_source_items.value),
		// 					me.name
		// 				)
		// 				me.items = []
		// 				for (var i = 0; i < res.length; i++) {
		// 					//Parent
		// 					me.items = me.items.concat({
		// 						'assignmentsContent' : '',
		// 						'line_machine_id': res[i].data.name,
		// 						'description': res[i].data.name,
		// 						'indentation': 0,
		// 						'parts': me.get_item_parts(res[i].routing_detail)
		// 					})
		// 					//Detail
		// 					me.items = me.items.concat(me.set_items(null, res[i].routing_detail))
		// 				}
		// 				//Init Gantt
		// 				me.display_columns = ['jump','line_machine_id', 'name'];
		// 				me.set_item_context_menu();
		// 				me.initialize_columns();
		// 				me.initialize();
		// 				me.change_timeline(1);
		// 				me.scroll_to_first_step();
		// 				me.settings.isReadOnly = true;
		// 				me.refresh_gantt();
		// 			}
		// 		}
		// 	}
		// }


	}

	get_selected_routing_control(ioiGanttControl){
		let me = this;

		if (!(ioiGanttControl && me.ioiGanttControl)) //2 ioiGanttControl case of Loadchart 
			return

		//Change Color
		if (ioiGanttControl.gantt_custom_bar_color){
			me.ioiGanttControl.gantt_custom_bar_color = ioiGanttControl.gantt_custom_bar_color
			me.ioiGanttControl.gantt_custom_bar_color.oninput = () => {
				let item = me.ganttChartView.getSelectedItem()
				if(item && item.name){
					item.gantt_color = me.ioiGanttControl.gantt_custom_bar_color.value;
					me.set_item_gantt_color(item, (item.gantt_color? item.gantt_color : BASIC_ITEM_COLOR));
					me.ganttChartView.refreshItem(item)
				}
			}
			me.ioiGanttControl.gantt_custom_bar_color.onchange = () => {
				let item = me.ganttChartView.getSelectedItem();
				if(item && item.name){
					item.gantt_color = me.ioiGanttControl.gantt_custom_bar_color.value;
					me.set_item_gantt_color(item, (item.gantt_color? item.gantt_color : BASIC_ITEM_COLOR));
					me.update_gantt_color(item.name, item.gantt_color);
					me.refresh_gantt();
				}
			}
		}

		//Schedule Gantt
		if (ioiGanttControl.gantt_schedule_selected_routing){
			me.ioiGanttControl.gantt_schedule_selected_routing = ioiGanttControl.gantt_schedule_selected_routing
			me.ioiGanttControl.gantt_schedule_selected_routing.onclick = () => {
				//Get item selected
				let item = me.ganttChartView.getSelectedItem();
				if(item && item.name){
					me.schedule_item(item);
				}
			}
		}
	}


	schedule_item(item){
		let me = this;
		var schedule_routing_dialog = new frappe.ui.Dialog({
			'title': __("Schedule rounting"),
			'fields': [
				{'fieldname': 'html_canevas', 'fieldtype': 'HTML'}
			],
			primary_action_label: __('Apply'),
			primary_action: function(){
				//Reload Gantt
				let res = me.get_items_by_workcenter(
					cint(me.mode),
					me.name
				)
				//Set items
				me.items = me.set_items(null, res)

				//Refresh load chart
				if(me.ioiLoadChart){
					me.ioiLoadChart.set_items(me.name);
					me.ioiLoadChart.initialize();
					me.ioiLoadChart.initilialize_settings_gantt(me.ioiLoadChart.settings);
				}

				me.initialize();
				me.refresh_gantt();

				schedule_routing_dialog.hide();
			}
		});

		//Create new Gantt
		let ioi_gantt = new silicon_ioi.scheduling.ioiGantt(schedule_routing_dialog, 'html_canevas', me.mode, item.parent_name);
		//Get routings list
		let res = ioi_gantt.get_items_from_routings();
		//Set items on Gantt
		ioi_gantt.items = ioi_gantt.set_items(
			res.data,
			res.routing_detail
		);
		ioi_gantt.initialize_columns();
		ioi_gantt.initialize();
		ioi_gantt.change_timeline(1);
		//Scales by default
		ioi_gantt.ioiGanttControl = new silicon_ioi.scheduling.ioiGanttControl(0);
		ioi_gantt.ioiGanttControl.initialize_scales(ioi_gantt.settings);
		ioi_gantt.settings.isTaskToolTipVisible = false;

		ioi_gantt.refresh_gantt();

		schedule_routing_dialog.$wrapper.find('.modal-dialog').css("max-width", "1080px").css("width", "1080px");
		schedule_routing_dialog.$wrapper.find('.modal-dialog').css("max-height", "450px").css("height", "450px");
		schedule_routing_dialog.show();
	}


	initilialize_settings_load_chart(settings){
		if (this.ioiLoadChart){
			// this.ganttChartView.copyCommonSettings(this.ioiLoadChart.settings);
			this.ioiLoadChart.settings.hourWidth = settings.hourWidth;
			this.ioiLoadChart.settings.isMouseWheelZoomEnabledMinHourWidth = settings.isMouseWheelZoomEnabledMinHourWidth,
			this.ioiLoadChart.settings.isMouseWheelZoomEnabledMaxHourWidth = settings.isMouseWheelZoomEnabledMaxHourWidth
			this.ioiLoadChart.settings.displayedTime = settings.displayedTime;
			this.ioiLoadChart.settings.timelineStart = settings.timelineStart;
			this.ioiLoadChart.settings.timelineFinish = settings.timelineFinish;
			this.update_splitter(this.last_splitter_move)
			this.ioiLoadChart.initialize_scales(this.settings.scales);
			if (this.ioiLoadChart.loadchart_zoom_level){
				this.ioiLoadChart.change_item_height(flt(this.ioiLoadChart.loadchart_zoom_level.value))
			}else{
				this.ioiLoadChart.change_item_height(this.ioiLoadChart.default_zoom)
			}
			this.ioiLoadChart.refresh_load_chart();
		}
	}


	update_splitter(source_control){
		if(this.ioiLoadChart){
			if (source_control == 'GanttChart'){
				this.ioiLoadChart.settings.gridWidth = this.settings.gridWidth;
				this.ioiLoadChart.settings.chartWidth = this.settings.chartWidth;
				this.ioiLoadChart.settings.displayedTime = this.settings.displayedTime;
			}
			if (source_control == 'LoadChart'){
				this.settings.gridWidth = this.ioiLoadChart.settings.gridWidth;
				this.settings.chartWidth = this.ioiLoadChart.settings.chartWidth;
				this.settings.displayedTime = this.ioiLoadChart.settings.displayedTime;
			}
		}
	}


	sync_timeline_load_chart(){
		let me = this;
		if(me.ioiLoadChart){
			me.settings.displayedTimeChangeHandler = function (displayedTime) {
		
				if (!me.isWaitingToRefreshLoadChartViewDisplayedTime){
					me.isWaitingToRefreshLoadChartViewDisplayedTime = true
					setTimeout(function () {
						if (displayedTime != me.ioiLoadChart.settings.displayedTime){
							me.ioiLoadChart.loadChartView.scrollToDateTime(displayedTime);
						}
						me.isWaitingToRefreshLoadChartViewDisplayedTime = false;
					})
				}
			};
	
			me.ioiLoadChart.settings.displayedTimeChangeHandler = function (displayedTime) {
				if (!me.isWaitingToRefreshGanttChartViewDisplayedTime){
					me.isWaitingToRefreshGanttChartViewDisplayedTime = true
					setTimeout(function () {
						if (displayedTime != me.settings.displayedTime){
							me.ganttChartView.scrollToDateTime(displayedTime);
						}
						me.isWaitingToRefreshGanttChartViewDisplayedTime = false;
					})
				}
			};
	
			me.ioiLoadChart.settings.itemSelectionChangeHandler = function (item, isSelected, isDirect) {
				if (isDirect){
					if ((me.items.length >= item.loadChartIndex + 2) && (!me.items[item.loadChartIndex + 1].isSelected)){
						setTimeout(function () {
							me.ganttChartView.selectItem(me.items[item.loadChartIndex + 1]);
							me.ganttChartView.scrollToItem(me.items[item.loadChartIndex + 1]);
						});
					}
				}
			}
	
			//Same splitter position
			me.settings.splitterPositionChangeHandler = function (gridWidth, chartWidth) {
				if (!me.isWaitingToRefreshLoadChartViewSplitterPosition) {
					me.isWaitingToRefreshLoadChartViewSplitterPosition = true;
					setTimeout(function () {
						me.ioiLoadChart.loadChartView.setSplitterPosition(gridWidth, chartWidth);
						me.isWaitingToRefreshLoadChartViewSplitterPosition = false;
						me.last_splitter_move = 'GanttChart'
					});
				}
			};
			me.ioiLoadChart.settings.splitterPositionChangeHandler = function (gridWidth, chartWidth) {
				if (!me.isWaitingToRefreshGanttChartViewSplitterPosition) {
					me.isWaitingToRefreshGanttChartViewSplitterPosition = true;
					setTimeout(function () {
						me.ganttChartView.setSplitterPosition(gridWidth, chartWidth);
						me.isWaitingToRefreshGanttChartViewSplitterPosition = false;
						me.last_splitter_move = 'LoadChart'
					});
				}
			};
		}
	}

	//Frappe call

	get_items_from_routings(){
		let method = ''
		let res = []
		switch(cint(this.mode)){
			case 0:
				method = path_scheduling_production + '.ioi_scheduling_production_get_gantt_items';
				break;
			case 1:
				method = path_scheduling_dossier + '.ioi_scheduling_dossier_get_gantt_items';
				break;
		}

		frappe.call({
			method: method,
			args: {'name': this.name},
			async: false,
			callback:function(r){
				res = r.message;
			}
		});
		return res
	}


	get_items_by_workcenter(source, workcenter_id){
		let method = ''
		let res = []
		switch(cint(source)){
			case -1:
				return []
			case 0:
				method = path_scheduling_workcenter + '.ioi_scheduling_production_get_gantt_items_by_workcenter';
				break;
			case 1:
				method = path_scheduling_workcenter + '.ioi_scheduling_dossier_get_gantt_items_by_workcenter';
				break;
		}

		frappe.call({
			method: method,
			args: {'workcenter_id' : workcenter_id},
			async: false,
			callback:function(r){
				res = r.message;
			}
		});
		return res
	}


	get_items_by_line_machine(source, workcenter_id){
		let method = ''
		let res = []
		switch(cint(source)){
			case -1:
				return []
			case 0:
				method = path_scheduling_workcenter + '.ioi_scheduling_production_get_gantt_items_by_line_machine';
				break;
			case 1:
				method = path_scheduling_workcenter + '.ioi_scheduling_dossier_get_gantt_items_by_line_machine';
				break;
		}

		frappe.call({
			method: method,
			args: {workcenter_id : workcenter_id},
			async: false,
			callback:function(r){
				res = r.message;
			}
		});
		return res
	}


	get_spec_day_canevas()
	{
		let me = this
		let method = ''
		if (me.mode == 0){
			method = path_scheduling_production + '.ioi_scheduling_production_get_spec_day_by_period';
		}else if(me.mode == 1){
			method = path_scheduling_dossier + '.ioi_scheduling_dossier_get_spec_day_by_period';
		}
		let res = null
		frappe.call({
			method: method,
			args: {
				"name": me.name
				// "opening_date": null,
				// "closing_date": null
			},
			async: false,
			callback:function(r){
				res =  r.message;
			}
		});
		me.non_working_day = res
		return res
	}

	get_staff_id(){
		let method = path_staff + '.ioi_staff_get_enabled'
		let res = []

		frappe.call({
			method: method,
			args: {},
			async: false,
			callback:function(r){
				res = r.message.map(el => el[0]);
			}
		});
		return res
	}


	update_gantt_color(name, color){
		let me = this
		let method = ''
		if (me.mode == 0){
			method = path_scheduling_production_routing + '.ioi_scheduling_production_set_gantt_color';
		}else if(me.mode == 1){
			method = path_scheduling_dossier_routing + '.ioi_scheduling_dossier_set_gantt_color';
		}
		frappe.call({
			method: method,
			args: {
				"name": name,
				"color": color
			},
			async: false
		});
	}


}



silicon_ioi.scheduling.ioiGantt = ioiGantt;