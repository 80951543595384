frappe.ui.form.on("Data Import", {
	before_load: function (frm) {

		if (document.getElementById('warning_ioi_data_import')) document.getElementById('warning_ioi_data_import').remove()

		frm.page.body[0].insertAdjacentHTML('beforebegin', `
			<div id="warning_ioi_data_import" class="mx-4 mb-4 text-danger font-weight-bold">${__("For security reasons, processing large volumes in a single transaction may cause a 'Gateway Timeout' error. Please proceed with smaller batches to avoid disruptions.")}</div>
		`)
	},
});

frappe.ui.form.on("Data Export", {
	before_load: function (frm) {

		if (document.getElementById('warning_ioi_data_export')) document.getElementById('warning_ioi_data_export').remove()

		frm.page.body[0].insertAdjacentHTML('beforebegin', `
			<div id="warning_ioi_data_export" class="mx-4 mb-4 text-danger font-weight-bold">${__("To import the manufacturer catalog, please use the dedicated function available in the 'ioi Manufacturer' module.")}</div>
		`)
	},
});