frappe.provide('silicon-ioi');

class ALayout extends frappe.ui.form.Layout {

	focus_on_next_field(start_idx, fields) {
		// loop to find next eligible fields
		for (let i = start_idx + 1, len = fields.length; i < len; i++) {
			let field = fields[i];
			if (this.is_visible(field)) {
				if (field.df.fieldtype === "Table") {
					// open table grid
					if (!(field.grid.grid_rows && field.grid.grid_rows.length)) {
						// empty grid, add a new row

						// VVR:
						field.grid.add_new_row(null, null, true); // field.grid.add_new_row();

					}
					// show grid row (if exists)

					// VVR:
					setTimeout(function () {
						field.grid.grid_rows[0].toggle_editable_row();
						field.grid.set_focus_on_row(0);
					}, 100); // field.grid.grid_rows[0].show_form();

					return true;
				} else if (!in_list(frappe.model.no_value_type, field.df.fieldtype)) {
					this.set_focus(field);
					return true;
				}
			}
		}
	}

	setup_events() {
		let tabs_list = $(".form-tabs-list");
		let tabs_content = this.tabs_content[0];
		if (!tabs_list.length) return;

		$(window).scroll(
			frappe.utils.throttle(() => {
				tabs_list.removeClass("form-tabs-sticky-up");
				tabs_list.addClass("form-tabs-sticky-down");
			}, 500)
		);

		this.tab_link_container.off("click").on("click", ".nav-link", (e) => {
			e.preventDefault();
			e.stopImmediatePropagation();
			$(e.currentTarget).tab("show");
			if (tabs_content.getBoundingClientRect().top < 100) {
				tabs_content.scrollIntoView();
				setTimeout(() => {
					$(".page-head").css("top", "-15px");
					$(".form-tabs-list").removeClass("form-tabs-sticky-down");
					$(".form-tabs-list").addClass("form-tabs-sticky-up");
				}, 3);
			}
		});
	}

};

// overriding frappe class
frappe.ui.form.Layout = ALayout;