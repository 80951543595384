frappe.provide("silicon_ioi");

let email_sandboxing_enabled = 0;
let next_refresh_state = 1000;
let red_dot_html_template = `

	<div id="email_sb_enabled" style="margin: auto; padding-left: 10px" data-toggle="tooltip" data-html="true" data-animation="true" data-placement="bottom" title="${__('Sandbox mode enabled')}:\n%emails%"><i class="fa fa-circle text-danger Blink"></i>&nbsp</div>

`
let red_dot_html = '';


async function get_sandbox_enabled(){
	await frappe.db.get_single_value("ioi General Settings","email_sandboxing_enabled").then((r) => {email_sandboxing_enabled = r})
	await frappe.db.get_single_value("ioi General Settings","email_sandboxing_send_to").then((r) => {
		let emails = ' • '+(r || '').replaceAll(',',';').replaceAll(';','\n • ')
		red_dot_html = red_dot_html_template.replace('%emails%', emails)

	})

}

async function email_sanboxing_render(){
	if ($("#email_sb_enabled")){
		$("#email_sb_enabled").remove()
	}
	if (email_sandboxing_enabled == 0){
		next_refresh_state = 1000;
	}else{
		next_refresh_state = 1000;

		$("header ul.navbar-nav")[1].insertAdjacentHTML('beforeend', red_dot_html);
	}
}


$(document).ready(async function () {
	await get_sandbox_enabled();

	email_sanboxing_render()
	if (!silicon_ioi.email_sanboxing_render_state){
		silicon_ioi.email_sanboxing_render_state = email_sanboxing_render;
		frappe.realtime.on('email_sandboxing_state_change', (message) => {
			debugger;
			email_sandboxing_enabled = message.enabled;
			let emails = ' • '+(message.send_to || '').replaceAll(',',';').replaceAll(';','\n • ');
			red_dot_html = red_dot_html_template.replace('%emails%', emails)
			email_sanboxing_render()
		});
	}

});
