frappe.provide('silicon_ioi.doctype');

export class ioiItemWhereUsedInBOMFrame
{
	static path_bom_definition = 'silicon_ioi.ioi_configuration.doctype.ioi_bom_definition.ioi_bom_definition';

	static tp = ''
	static key_value = ''
	static ItemWhereUsedInBOMTable = null;

	static load_item_where_used_in_bom_frame(html_field, item_id)
	{

		if (document.getElementById('item_where_used_in_bom_frame')) {
			document.getElementById('item_where_used_in_bom_frame').remove();
		}


		if (document.getElementById('item_where_used_in_bom_frame_item_label')) {
			document.getElementById('item_where_used_in_bom_frame_item_label').remove();
		}

		if (document.getElementById('item_where_used_in_bom_frame_item_id')) {
			document.getElementById('item_where_used_in_bom_frame_item_id').remove();
		}


		if (document.getElementById('item_where_used_in_bom_frame_item_button')) {
			document.getElementById('item_where_used_in_bom_frame_item_button').remove();
		}

		if (document.getElementById('item_where_used_in_bom_frame_site_label')) {
			document.getElementById('item_where_used_in_bom_frame_site_label').remove();
		}

		if (document.getElementById('item_where_used_in_bom_frame_site_id')) {
			document.getElementById('item_where_used_in_bom_frame_site_id').remove();
		}


		if (document.getElementById('item_where_used_in_bom_frame_status_label')) {
			document.getElementById('item_where_used_in_bom_frame_status_label').remove();
		}

		if (document.getElementById('item_where_used_in_bom_frame_status_id')) {
			document.getElementById('item_where_used_in_bom_frame_status_id').remove();
		}

		if (document.getElementById('item_where_used_in_bom_frame_component_stock_label')) {
			document.getElementById('item_where_used_in_bom_frame_component_stock_label').remove();
		}

		if (document.getElementById('item_where_used_in_bom_frame_component_stock')) {
			document.getElementById('item_where_used_in_bom_frame_componenet_stock').remove();
		}

		if (document.getElementById('item_where_used_in_bom_frame_item_stock_label')) {
			document.getElementById('item_where_used_in_bom_frame_item_stock_label').remove();
		}

		if (document.getElementById('item_where_used_in_bom_frame_item_stock')) {
			document.getElementById('item_where_used_in_bom_frame_item_stock').remove();
		}

		if (document.getElementById('item_where_used_in_bom_frame_button_search')) {
			document.getElementById('item_where_used_in_bom_frame_button_search').remove();
		}

		if (document.getElementById('item_where_used_in_bom_frame_button_export')) {
			document.getElementById('item_where_used_in_bom_frame_button_export').remove();
		}


		if (document.getElementById('item_where_used_in_bom_table_container')) {
			document.getElementById('item_where_used_in_bom_table_container').remove();
		}


		let html = '';

		html += '<div style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true"><b>' + __("Parameters")  + '</b></div>';


		html += '<div id="item_where_used_in_bom_frame" style="overflow: auto; overflow-x: auto; overflow-y: hidden; height:100px; border: 0px solid #E6E9EC;">';

		// Item
		html += '	<div style="position: relative; top: 0px; left: 2px; width:240px;">';
		html += '		<label id="item_where_used_in_bom_frame_item_label" style="position: absolute; top: 2px; left: 2px;z-index:5;">' + __("Item") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 240px; height: 25px;"> ';
		html += '			<input id="item_where_used_in_bom_frame_item_id" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value="' + item_id + '">' ;
		html += '		</div>';
		html += '		<div  style="position: absolute; top: 25px; left: 250px; width: 30px; height: 25px;"> ';
		html +='			<button id="item_where_used_in_bom_frame_item_button" style="position:absolute; height: 30px; width: 30px; z-index:10;border:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Site
		html += '	<div style="position: relative; top: 0px; left: 292px; width:170px;">';
		html += '		<label id="item_where_used_in_bom_frame_site_label" style="position: absolute; top: 2px; left: 2px;">' + __("Site (for stock values)") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '			<select id="item_where_used_in_bom_frame_site_id" class="input-with-feedback form-control bold"> ';

		let method = silicon_ioi.doctype.ioiItemWhereUsedInBOMFrame.path_bom_definition + '.ioi_bom_definition_get_sites'
		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{
													for (var i = 0; i < r.message.length; i++)
													{
														html += '			<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
													}
												}
		});

		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';


		// Release status
		html += '	<div style="position: relative; top: 0px; left: 472px; width:170px;">';
		html += '		<label id="item_where_used_in_bom_frame_status_label" style="position: absolute; top: 2px; left: 2px;">' + __("BOM Release status") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '			<select id="item_where_used_in_bom_frame_status_id" class="input-with-feedback form-control bold"> ';
		html += '				<option value="-99" selected>' + __("All") + '</option> ';

		method = silicon_ioi.doctype.ioiItemWhereUsedInBOMFrame.path_bom_definition + '.ioi_bom_definition_get_releases_status'
		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{
													for (var i = 0; i < r.message.length; i++)
													{
														html += '			<option value="' + r.message[i].ioistatus + '">' + r.message[i].ioistatus_description_id + '</option> ';
													}
												}
		});

		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';


		// Component Stock
		html += '	<div style="position: relative; top: 0px; left: 652px; width:170px;">';
		html += '		<label id="item_where_used_in_bom_frame_component_stock_label" style="position: absolute; top: 2px; left: 2px;">' + __("Component Stock") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '			<select id="item_where_used_in_bom_frame_component_stock" class="input-with-feedback form-control bold"> ';
		html += '				<option value="-1" selected>' + __("All") + '</option> ';
		html += '				<option value="0">' + __("W/o enough stock") + '</option> ';
		html += '				<option value="1">' + __("With stock") + '</option> ';
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		// Item Stock
		html += '	<div style="position: relative; top: 0px; left: 832px; width:160px;">';
		html += '		<label id="item_where_used_in_bom_frame_item_stock_label" style="position: absolute; top: 2px; left: 2px;">' + __("Item Stock") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 160px; height: 30px;"> ';
		html += '			<select id="item_where_used_in_bom_frame_item_stock" class="input-with-feedback form-control bold"> ';
		html += '				<option value="-1" selected>' + __("All") + '</option> ';
		html += '				<option value="0">' + __("W/o enough stock") + '</option> ';
		html += '				<option value="1">' + __("With stock") + '</option> ';
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		// Search
		html += '	<div style="position: relative; top: 65px; left: 2px; width:115px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 35px">';
		html +='			<button id="item_where_used_in_bom_frame_button_search" class="btn btn-default ellipsis" style="height: 30px; width: 115px;" onclick="">' + __("Refresh") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Export
		html += '	<div style="position: relative; top: 65px; left: 127px; width:115px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 35px">';
		html +='			<button id="item_where_used_in_bom_frame_button_export" class="btn btn-default ellipsis" style="height: 30px; width: 115px;" onclick="">' + __("Export") + '</button>';
		html += '		</div>';
		html += '	</div>';

		html += '</div>';

		html += '<div style="overflow: auto; overflow-x: auto; overflow-y: hidden; height:8px;">';
		html += '</div>';

		html += '<div style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true"><b>' + __("Where used result")  + '</b></div>';



		html += '<div id="item_where_used_in_bom_table_container" class="table table-bordered" data-custom-grid="true" style="overflow: auto; overflow-x: auto; overflow-y: hidden; height:600px;border: 1px solid #E6E9EC;">';
		html += '</div>';

		cur_frm.fields_dict[html_field].$wrapper.empty();
		cur_frm.fields_dict[html_field].$wrapper.append(html);


		silicon_ioi.doctype.ioiItemWhereUsedInBOMFrame.sleep_static(200).then(() => {

			let fct_keydown = function(event) {

				if (event.keyCode == 13) {
					silicon_ioi.doctype.ioiItemWhereUsedInBOMFrame.where_used();
					return false;
				}
			}

			document.getElementById('item_where_used_in_bom_frame_item_id').onkeydown = fct_keydown;

			let fct_select_item = function () {

				let fields = 'a.name, a.description, a.unit_id, b.q_stock, b.q_reserved, a.manufacturer_ref';
				let fields_len = '300, 500, 125, 150, 150, 300';
				let fields_desc = 'Identification, Description, Unit, Stock qty, Reserved qty, Manufacturer Catalog';
				let where = 'ioistatus = 2';
				let order_by = 'a.name asc';
				let parameter_with_stock = false;
				let site_id = '';

				let fct_callback = function (return_value) {

					document.getElementById("item_where_used_in_bom_frame_item_id").value = return_value;
					document.getElementById('item_where_used_in_bom_frame_item_id').focus();
				};

				silicon_ioi.ioiCommon.select_item(fields, fields_len, fields_desc, where, order_by, parameter_with_stock, site_id, fct_callback);

			}

			document.getElementById('item_where_used_in_bom_frame_item_button').onclick = fct_select_item;
			document.getElementById('item_where_used_in_bom_frame_item_button').onkeyup = fct_keydown;


			let fct_change = function(event) {

				silicon_ioi.doctype.ioiItemWhereUsedInBOMFrame.where_used();
			}

			document.getElementById('item_where_used_in_bom_frame_site_id').onchange = fct_change;
			document.getElementById('item_where_used_in_bom_frame_status_id').onchange = fct_change;
			document.getElementById('item_where_used_in_bom_frame_component_stock').onchange = fct_change;
			document.getElementById('item_where_used_in_bom_frame_item_stock').onchange = fct_change;

			let fct_search = function() {
				silicon_ioi.doctype.ioiItemWhereUsedInBOMFrame.where_used();
			}

			document.getElementById('item_where_used_in_bom_frame_button_search').onclick = fct_search;


			let fct_export = function() {
				silicon_ioi.doctype.ioiItemWhereUsedInBOMFrame.ItemWhereUsedInBOMTable.download("xlsx", "data.xlsx", {});
			}

			document.getElementById('item_where_used_in_bom_frame_button_export').onclick = fct_export;

			silicon_ioi.doctype.ioiItemWhereUsedInBOMFrame.where_used();

        });

	}

	static where_used()
	{
		let data = [];

		if ((document.getElementById('item_where_used_in_bom_frame_item_id').value) && (document.getElementById('item_where_used_in_bom_frame_item_id').value.trim() != '')) {

			let method = silicon_ioi.doctype.ioiItemWhereUsedInBOMFrame.path_bom_definition + '.ioi_bom_definition_where_used';

			frappe.call({  	method: method,
							args: { "site_id": document.getElementById('item_where_used_in_bom_frame_site_id').value,
									"item_id": document.getElementById('item_where_used_in_bom_frame_item_id').value,
									"status_id": document.getElementById('item_where_used_in_bom_frame_status_id').value,
									"component_stock": document.getElementById('item_where_used_in_bom_frame_component_stock').value,
									"item_stock": document.getElementById('item_where_used_in_bom_frame_item_stock').value

							},
							async: false,
							callback:function(r)	{
								data = r.message;
							}
			});
		}

		let create_icon = (cell) => {
			let icon = `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
								<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
							</svg>`

			return cell.getElement().innerHTML = icon
		}


		let format_cell_item_component = (cell, formatterParams) => {

			if ((cell.getValue() != null) && (cell.getValue() != '')) {
				if (cell.getValue() == document.getElementById('item_where_used_in_bom_frame_item_id').value.toUpperCase()) {
					return '<b><font color="green">' + cell.getValue() + '</font></b>'

				}else{
					return cell.getValue()
				}
			}
		};

		let format_cell_number = (cell, formatterParams) => {

			if ((cell.getColumn().getField() == 'component_stock_qty') || (cell.getColumn().getField() == 'stock_unit_id')) {

				let qty_in_stock = 0;

				if ((cell.getRow().getData()['component_stock_qty']) && (cell.getRow().getData()['component_stock_qty'] != 0)) {
					qty_in_stock = parseFloat(cell.getRow().getData()['component_stock_qty'])
				}

				let qty_in_stock_unit = 0;

				if ((cell.getRow().getData()['stock_unit_qty']) && (cell.getRow().getData()['stock_unit_qty'] != 0)) {
					qty_in_stock_unit = parseFloat(cell.getRow().getData()['stock_unit_qty'])
				}


				if (parseFloat(qty_in_stock) < parseFloat(qty_in_stock_unit)) {
					return '<b><font color="red">' + cell.getValue() + '</font></b>';
				}else{
					return cell.getValue()
				}

			}else{
				return cell.getValue()
			}

		};



		let cell_onclick = (e, cell) => {

			if (cell.getColumn().getField() == "bom_release_name") {
				let url = `/app/ioi-bom-release/${cell.getRow().getData().bom_release_name}`;
				window.open(url, '_blank');
			}

		};

		silicon_ioi.doctype.ioiItemWhereUsedInBOMFrame.ItemWhereUsedInBOMTable = new ioi.Tabulator('#item_where_used_in_bom_table_container', {
			data: data,
			maxHeight: 600,
			rowHeight: null,
			selectableRows: 1,
			showProfiles: false,
			movableColumns: true,
			resizableColumns: true,
			autoRedraw: true,
			columns: [
				{title: __('BOM Release'), field: 'bom_release_name', width: 250, headerSort: true, cellClick: cell_onclick},
				{title: __('Position'), field: 'position_id', width: 100, headerSort: true},
				{title: __('Release Status'), field: 'ioistatus_description', width: 120, headerSort: true},
				{title: __('Item'), field: 'item_id', width: 200, headerSort: true, formatter: format_cell_item_component},
				{title: __('Component'), field: 'component_id', width: 200, headerSort: true, formatter: format_cell_item_component},

				{title: __('Qty'), field: 'cons_unit_qty', width: 100, hozAlign: "right", headerSort: true, formatter: format_cell_number},
				{title: __('Unit'), field: 'cons_unit_id', width: 80, headerSort: true},

				{title: __('Comp. Stock Qty'), field: 'component_stock_qty', width: 140, hozAlign: "right", headerSort: true, formatter: format_cell_number},
				{title: __('Unit'), field: 'stock_unit_id', width: 80, headerSort: true,  formatter: format_cell_number},

				{title: __('Item Stock Qty'), field: 'item_stock_qty', width: 140, hozAlign: "right", headerSort: true, formatter: format_cell_number},
				{title: __('Unit'), field: 'item_unit_id', width: 80, headerSort: true},

			]
		})
	}



	static sleep_static(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}

}

silicon_ioi.doctype.ioiItemWhereUsedInBOMFrame = ioiItemWhereUsedInBOMFrame;
