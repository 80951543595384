frappe.provide('silicon_ioi.doctype');

export class ioiGoodPurchWhsGlobalFrame {
	static this
	static dossier_id
	static description
	static selected_option

	static build_panel(tab) {
		let panel = '';

		panel += '<div class="d-flex flex-column">';

		panel += '	<div class="d-flex w-100 flex-row flex-wrap mb-2">';

		// Dossier Search
		panel += `	<div class="mr-2" style="width:280px;">
						<label id="${tab}_search_label" style="">${__("Search")}</label>
						<div class="control-input" style="width: 280px;">
							<input id="${tab}_search" type="text" class="input-with-feedback form-control bold">
						</div>
					</div>`

		// Up to date
		panel += `	<div class="mr-2" style="width:130px;">
						<label id="${tab}_up_to_date_label" style="">${__("Up to date")}</label>
						<div class="control-input"> 
							<input id="${tab}_up_to_date" type="date" class="input-with-feedback form-control bold">
						</div>
					</div>`

		// Topn
		panel += `	<div class="mr-3" style="width:90px;">
						<label id="${tab}_topn_label" style="">${__("No records")}</label>
						<div class="control-input"> 
							<input id="${tab}_topn" type="number" class="input-with-feedback form-control bold" min="1" max="1000" value="100">
						</div>
					</div>`

		panel += '	</div>'

		panel += `	<div class="d-flex w-100 flex-row mb-2 flex-wrap" style="height: 28px;">
						<button id="${tab}_bt_search" title="${__("Search")}" data-label="Search" class="btn btn-xs btn-default ellipsis mr-2" style="width: 116px;" onclick="">${__("Search")}</button>
						<button id="${tab}_bt_clear" title="${__("...")}" data-label="Search" class="btn btn-xs btn-default ellipsis mr-2" style="width: 50px;" onclick="">${__("...")}</button>
						<button id="${tab}_bt_export" title="${__("Export")}" data-label="Export" class="btn btn-xs btn-default ellipsis mr-2" style="width: 100px;" onclick="">${__("Export")}</button>
						<div class="form-check ml-2">
							<input class="form-check-input" type="checkbox" value="" id="${tab}_checkbox_discard">
							<label class="form-check-label" for="${tab}_checkbox_discard">
								${__("Discard 'Template/Preparation/Lost' documents")}
							</label>
						</div>
					</div>`

		panel += `	<input id="${tab}_orderby" type="hidden" value="name">`;
		panel += `	<input id="${tab}_order" type="hidden" value="asc">`;
		panel += '</div>';

		return panel
	}

	static show_dossier_label(tab_name) {
		if (document.getElementById(`${tab_name}_desc_label`)) {
			document.getElementById(`${tab_name}_desc_label`).remove();
		}

		if (document.getElementById(`${tab_name}_dossier_label`)) {
			document.getElementById(`${tab_name}_dossier_label`).remove();
		}

		let html = '';

		// Item description
		html += '<div class="d-flex flex-column">';
		html += `	<label id="${tab_name}_desc_label">${__("Dossier")}</label>`
		html += `	<label id="${tab_name}_dossier_label">${silicon_ioi.doctype.ioiGoodPurchWhsGlobalFrame.dossier_id} - ${silicon_ioi.doctype.ioiGoodPurchWhsGlobalFrame.description}</label>`
		html += '</div>';

		return html
	}

	static build_global() {
		let global_tabs = []

		if (silicon_ioi.doctype.ioiGoodPurchWhsGlobalFrame.selected_option) {
			global_tabs = [
				silicon_ioi.doctype.ioiGoodPurchWhsGlobalFrame.selected_option
			]
		} else {
			global_tabs = [
				{ tab_name: 'global_purchases_price_req', dt: 'ioi Purchases Price Request' },
				{ tab_name: 'global_purchases_orders', dt: 'ioi Purchases Order' },
				{ tab_name: 'global_purchases_deliveries', dt: 'ioi Purchases Receipt' },
				{ tab_name: 'global_purchases_stock_output', dt: 'ioi Stock Output' },
				{ tab_name: 'global_purchases_invoices', dt: 'ioi Purchases Invoice' }
			]
		}

		global_tabs.map(tab => {
			const container = `
				<div class="w-100">
					<div class="d-flex flex-row flex-wrap">
						${silicon_ioi.doctype.ioiGoodPurchWhsGlobalFrame.build_panel(tab.tab_name)}
						${silicon_ioi.doctype.ioiGoodPurchWhsGlobalFrame.show_dossier_label(tab.tab_name)}
					</div>
						<p class="ml-2 mt-2 d-flex align-items-bottom">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle mr-2" viewBox="0 0 16 16">
								<path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z"/>
								<path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
							</svg>
							${__('Details line can be linked to other dossier')}
						</p>
					<div id="${tab.tab_name}_grid" class="table table-bordered" data-custom-grid="true"></div>
				</div>`

			if (silicon_ioi.doctype.ioiGoodPurchWhsGlobalFrame.selected_option) {
				const good_purch_whs_global_content = $('#html_good_purch_whs_global_content')[0]
				if (good_purch_whs_global_content) good_purch_whs_global_content.remove()

				silicon_ioi.doctype.ioiGoodPurchWhsGlobalFrame.this.frm.fields_dict[`html_good_purch_whs_global`].$wrapper.append(container);
			} else {
				silicon_ioi.doctype.ioiGoodPurchWhsGlobalFrame.this.frm.fields_dict[`html_${tab.tab_name}`].$wrapper.empty();
				silicon_ioi.doctype.ioiGoodPurchWhsGlobalFrame.this.frm.fields_dict[`html_${tab.tab_name}`].$wrapper.append(container);
			}

			silicon_ioi.doctype.ioiGoodPurchWhsGlobalFrame.build_global_grid(tab.tab_name, tab.dt)
		})
	}

	static build_global_grid(tab, dt) {

		let format_link = (cell, formatterParams) => {
			if (cell.getValue() != null && cell.getValue() != "") {
				let html = `<span style="cursor: pointer; text-decoration: underline;">${cell.getValue()}</span>`
				return html
			}
		}

		let link_onclick = (e, cell) => {
			if (cell.getValue() != null && cell.getValue() != "") {
				let url = ""

				if (cell.getColumn().getField() == "name") {
					let name = cell.getData().name
					url = `/app/${dt.toLowerCase().replace(/\s+/g, '-')}/${name}`
				}

				window.open(url, "_blank")
			}
		}

		let columns = [
			{ title: __("ID"), field: "name", minWidth: 100, formatter: format_link, cellClick: link_onclick },
			{ title: __("Status"), field: "ioistatus_description", minWidth: 100 },
			{ title: __("User status"), field: "ioiuserstatus_description", minWidth: 100 },
			{ title: __("Document date"), field: "document_date", minWidth: 100 },
			{ title: __("Period"), field: "period_id", minWidth: 100 },
			{ title: __("Total HTVA"), field: "total_htva", minWidth: 100, bottomCalc:"sum" },
			{ title: __("Responsible author"), field: "responsible_id", minWidth: 100 },
			{ title: __("Description"), field: "description", minWidth: 100 },
			{ title: __("Remark"), field: "remark", minWidth: 100 },
		]

		if (dt.toLowerCase().includes('stock output')) {
			columns = columns.filter(col => !['total_htva', 'responsible_id', 'description', 'remark'].includes(col.field));
			columns.splice((columns.length), 0, { title: __("Warehouse"), field: "warehouse_id", minWidth: 100 })
		}

		if (dt.toLowerCase().includes('purchases')) {
			columns.splice(3, 0, { title: __("Supplier name"), field: "order_supplier_name", minWidth: 100 })
			columns.splice(3, 0, { title: __("Supplier ID"), field: "order_supplier_id", minWidth: 100 })
		}

		let table = new ioi.Tabulator(`#${tab}_grid`, {
			maxHeight: 800,
			data: [],
			layout: "fitColumns",
			movableColumns: true,
			resizableColumns: true,
			showProfiles: true,
			autoRedraw: true,
			initialSort: [
				{ column: "name", dir: "asc" },
			],
			rowFormatter:function(row){	
				let data = row.getData();

				if (data.ioistatus_bgcolor != null && data.ioistatus_bgcolor != "") {
					row.getCell("ioistatus_description").getElement().style.backgroundColor = data.ioistatus_bgcolor;
					row.getCell("ioistatus_description").getElement().style.color = data.ioistatus_fontcolor;
				}

				if (data.ioiuserstatus_bgcolor != null && data.ioiuserstatus_bgcolor != "") {
					row.getCell("ioiuserstatus_description").getElement().style.backgroundColor = data.ioiuserstatus_bgcolor;
					row.getCell("ioiuserstatus_description").getElement().style.color = data.ioiuserstatus_fontcolor;
				}
			},
			columns: columns
		});

		table.on("tableBuilt", () => {
			table.footerManager.element.setAttribute('data-custom-grid-bottom', 'true')
		});

		table.on("cellClick", function (e, cell) {
			if (cell.getRow().getData().name != null) {
				if (cell.getColumn().getField() == "icon") {
					let url = `/app/${dt.toLowerCase().replace(/\s+/g, '-')}/${cell.getRow().getData().name}`
					window.open(url, '_blank')
				}
			}
		});

		table.on("headerClick", function (e, column) {
			let ordered_field = document.getElementById(`${tab}_orderby`)
			ordered_field.value = column.getField()

			let ordered_dir = document.getElementById(`${tab}_order`)
			ordered_dir.value = table.getSorters()[0].dir

			silicon_ioi.doctype.ioiGoodPurchWhsGlobalFrame.col_click(tab)
		});

		silicon_ioi.doctype.ioiGoodPurchWhsGlobalFrame.global_grid_search(table, dt, tab)

		document.getElementById(`${tab}_bt_search`).onclick = () => silicon_ioi.doctype.ioiGoodPurchWhsGlobalFrame.global_grid_search(table, dt, tab)
		document.getElementById(`${tab}_bt_export`).onclick = () => silicon_ioi.doctype.ioiGoodPurchWhsGlobalFrame.export_data(table, tab)
		document.getElementById(`${tab}_bt_clear`).onclick = () => {
			document.getElementById(`${tab}_search`).value = ''
			document.getElementById(`${tab}_topn`).value = 100
			table.clearData()
		}
		document.getElementById(`${tab}_up_to_date`).onchange = () => silicon_ioi.doctype.ioiGoodPurchWhsGlobalFrame.global_grid_search(table, dt, tab)
		document.getElementById(`${tab}_checkbox_discard`).onclick = () => silicon_ioi.doctype.ioiGoodPurchWhsGlobalFrame.global_grid_search(table, dt, tab)

		document.getElementById(`${tab}_search`).addEventListener("keypress", function(event) {
			if (event.key === "Enter") {
				event.preventDefault();
				silicon_ioi.doctype.ioiGoodPurchWhsGlobalFrame.global_grid_search(table, dt, tab)
			}
		});

		document.getElementById(`${tab}_topn`).addEventListener("keypress", function(event) {
			if (event.key === "Enter") {
				event.preventDefault();
				silicon_ioi.doctype.ioiGoodPurchWhsGlobalFrame.global_grid_search(table, dt, tab)
			}
		});
	}

	static col_click(tab) {
		if (document.getElementById(`${tab}_orderby`).value == 'name') {
			document.getElementById(`${tab}_orderby`).value = 'a.name';
		}

		document.getElementById(`${tab}_bt_search`).click();
	}

	static global_grid_search(table, dt, tab) {
		let method = 'silicon_ioi.ioi_trakker.doctype.ioi_dossier_history.ioi_dossier_history.ioi_dossier_global_get_list';

		frappe.call({
			method: method,
			args: {
				doctype: dt,
				dossier_id: silicon_ioi.doctype.ioiGoodPurchWhsGlobalFrame.dossier_id,
				search: document.getElementById(`${tab}_search`).value,
				topn: document.getElementById(`${tab}_topn`).value,
				orderby: document.getElementById(`${tab}_orderby`).value,
				order: document.getElementById(`${tab}_order`).value,
				discard: document.getElementById(`${tab}_checkbox_discard`).checked,
				uptodate: document.getElementById(`${tab}_up_to_date`).value,
			},
			async: false,
			callback: function (r) {
				if (r.message.length > 0) {
					if (table.initialized) {
						table.setData(r.message)
					} else {
						table.on('tableBuilt', () => table.setData(r.message))
					}
				} else {
					if (table && table.initialized) table.clearData()
				}
			}
		})
	}

	static export_data(table, default_filename) {
		let export_tabulator = () => {
			let filename = document.getElementById('export_filename').value;
			let filetype = document.getElementById('export_file_type').value;

			if (filename.trim() == '') {
				filename = default_filename;
			}

			if (filetype.toUpperCase() == 'XLSX') {
				table.download("xlsx", `${filename}.xlsx`);
			} else {
				table.download("csv", `${filename}.csv`, { delimiter: ";" })
			}
		}

		silicon_ioi.ioiCommon.export_form(export_tabulator, true);
	}
}

silicon_ioi.doctype.ioiGoodPurchWhsGlobalFrame = ioiGoodPurchWhsGlobalFrame;