frappe.provide('silicon_ioi.doctype');
export class ioiItemPriceFrame
{

	static path_user = 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user';
	static path_common = 'silicon_ioi.common.common';
	static path_general_settings = 'silicon_ioi.ioi_configuration.doctype.ioi_general_settings.ioi_general_settings';
	static path_sales_price = 'silicon_ioi.ioi_items.doctype.ioi_sales_price.ioi_sales_price';
	static path_purchases_price = 'silicon_ioi.ioi_items.doctype.ioi_purchases_price.ioi_purchases_price';
	static path_sales_settings = 'silicon_ioi.ioi_configuration.doctype.ioi_sales_settings.ioi_sales_settings'
	static path_purchases_settings = 'silicon_ioi.ioi_configuration.doctype.ioi_purchases_settings.ioi_purchases_settings'

	static selected_site = ''
	static is_dark_mode = 0;
	static bgcolor = 0

	static system_currency = '';
	static sales_catalog_id = '';

	static sales_condition_id = '';
	static manual_sales_condition_id = '';
	static purchases_condition_id = '';
	static manual_purchases_condition_id = '';
	static today = '';
	static sales_price_grid_width = 0;
	static purchases_price_grid_width = 0;
	static item_id = '';
	static unit_id = '';
	static can_read_sales = false;
	static can_modify_sales = false;
	static can_read_purchases = false;
	static can_modify_purchases = false;



	static load_general_config()
	{
		silicon_ioi.doctype.ioiItemPriceFrame.selected_site = ''

		let method = silicon_ioi.doctype.ioiItemPriceFrame.path_user + '.ioi_user_get_site'

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{
							silicon_ioi.doctype.ioiItemPriceFrame.selected_site = r.message
						}
		});

		silicon_ioi.doctype.ioiItemPriceFrame.is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;
		silicon_ioi.doctype.ioiItemPriceFrame.bgcolor = '';

		silicon_ioi.doctype.ioiItemPriceFrame.system_currency = '';

		method = silicon_ioi.doctype.ioiItemPriceFrame.path_general_settings + '.ioi_general_settings_get_data';

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{
							silicon_ioi.doctype.ioiItemPriceFrame.system_currency = r.message.currency;
						}
		});
	}

	static load_sales_config()
	{
		silicon_ioi.doctype.ioiItemPriceFrame.load_general_config();

		silicon_ioi.doctype.ioiItemPriceFrame.sales_catalog_id = '';
		silicon_ioi.doctype.ioiItemPriceFrame.sales_condition_id = '';
		silicon_ioi.doctype.ioiItemPriceFrame.manual_sales_condition_id = '';

		let method = silicon_ioi.doctype.ioiItemPriceFrame.path_sales_settings + '.ioi_sales_settings_get_data';

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{

							silicon_ioi.doctype.ioiItemPriceFrame.sales_catalog_id = r.message.customer_sales_catalog_id
							silicon_ioi.doctype.ioiItemPriceFrame.sales_condition_id = r.message.customer_sales_condition_id;
							silicon_ioi.doctype.ioiItemPriceFrame.manual_sales_condition_id = r.message.customer_manual_sales_condition_id;
						}
		});


		silicon_ioi.doctype.ioiItemPriceFrame.today = '';

		method = silicon_ioi.doctype.ioiItemPriceFrame.path_sales_price + '.ioi_sales_price_get_date';

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{
							silicon_ioi.doctype.ioiItemPriceFrame.today = r.message;
						}
		});


		silicon_ioi.doctype.ioiItemPriceFrame.can_read_sales = false;
		silicon_ioi.doctype.ioiItemPriceFrame.can_modify_sales = false;

		method = silicon_ioi.doctype.ioiItemPriceFrame.path_sales_price + '.ioi_sales_price_frame_get_perimissions';

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{

							if (r.message.read == 1) {
								silicon_ioi.doctype.ioiItemPriceFrame.can_read_sales = true;
							}

							if (r.message.write == 1) {
								silicon_ioi.doctype.ioiItemPriceFrame.can_modify_sales = true;
							}

							if (silicon_ioi.doctype.ioiItemPriceFrame.can_modify_sales) {
								silicon_ioi.doctype.ioiItemPriceFrame.can_read_sales = true;
							}
						}
		});

	}


	static load_purchases_config()
	{
		silicon_ioi.doctype.ioiItemPriceFrame.load_general_config();

		silicon_ioi.doctype.ioiItemPriceFrame.purchases_condition_id = '';
		silicon_ioi.doctype.ioiItemPriceFrame.manual_purchases_condition_id = '';

		let method = silicon_ioi.doctype.ioiItemPriceFrame.path_purchases_settings + '.ioi_purchases_settings_get_data';

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{
							silicon_ioi.doctype.ioiItemPriceFrame.purchases_condition_id = r.message.supplier_purchases_condition_id;
							silicon_ioi.doctype.ioiItemPriceFrame.manual_purchases_condition_id = r.message.supplier_manual_purchases_condition_id;
						}
		});


		silicon_ioi.doctype.ioiItemPriceFrame.today = '';

		method = silicon_ioi.doctype.ioiItemPriceFrame.path_purchases_price + '.ioi_purchases_price_get_date';

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{
							silicon_ioi.doctype.ioiItemPriceFrame.today = r.message;
						}
		});


		silicon_ioi.doctype.ioiItemPriceFrame.can_read_purchases = false;
		silicon_ioi.doctype.ioiItemPriceFrame.can_modify_purchases = false;

		method = silicon_ioi.doctype.ioiItemPriceFrame.path_purchases_price + '.ioi_purchases_price_frame_get_perimissions';

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{

							if (r.message.read == 1) {
								silicon_ioi.doctype.ioiItemPriceFrame.can_read_purchases = true;
							}

							if (r.message.write == 1) {
								silicon_ioi.doctype.ioiItemPriceFrame.can_modify_purchases = true;
							}

							if (silicon_ioi.doctype.ioiItemPriceFrame.can_modify_purchases) {
								silicon_ioi.doctype.ioiItemPriceFrame.can_read_purchases = true;
							}
						}
		});

	}

	// *************************************************************************************************************************************************
	// Sales Price
	// *************************************************************************************************************************************************

    static load_item_sales_price_frame(html_field, item_id, unit_id)
    {
		silicon_ioi.doctype.ioiItemPriceFrame.item_id = item_id;
		silicon_ioi.doctype.ioiItemPriceFrame.unit_id = unit_id;

		silicon_ioi.doctype.ioiItemPriceFrame.load_sales_config();

		let id_list = ['item_sales_price_frame_content','item_sales_price_frame_action','item_sales_price_frame_button_new','item_sales_price_frame_button_delete','item_sales_price_frame_button_save','item_sales_price_frame_button_cancel','item_sales_price_frame_button_duplicate',
		'item_price_frame_sales_price_selected_name','item_price_frame_sales_price_selected_id','item_price_frame_sales_price_nb_rows','item_price_frame_sales_price_grid_header','item_price_frame_sales_price_grid_detail'];

		for (let nbr = 0 ; nbr < id_list.length; nbr ++)
		{
			let id_el= document.getElementById(id_list[nbr]);
			if (id_el)
			{
				id_el.remove();
			}
		}


		while (document.getElementById('item_price_frame_sales_price_grid_header')) {
			document.getElementById('item_price_frame_sales_price_grid_header').remove();
		}

		while (document.getElementById('item_price_frame_sales_price_grid_detail')) {
			document.getElementById('item_price_frame_sales_price_grid_detail').remove();
		}



		let html = '';

		if (silicon_ioi.doctype.ioiItemPriceFrame.can_modify_sales) {

			html += `<div id="item_sales_price_frame_action" style="overflow: auto; overflow-x: auto; height:50px;">`

			//New
			html += `<div style="position: relative; top: 2px; left: 0px; width:150px;">
					<div style="position: absolute; top:0px; left: 2px; height: 35px">
						<button id="item_sales_price_frame_button_new" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="">${__("New")}</button>
					</div>
				</div>`

			//Duplicate
			html += `<div style="position: relative; top: 2px; left: 160px; width:150px;">
					<div style="position: absolute; top:0px; left: 2px; height: 30px">
						<button id="item_sales_price_frame_button_duplicate" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="">${__("Duplicate")}</button>
					</div>
				</div>`


			//Delete
			html += `<div style="position: relative; top: 2px; left: 320px; width:150px;">
					<div style="position: absolute; top:0px; left: 2px; height: 30px">
						<button id="item_sales_price_frame_button_delete" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="">${__("Delete")}</button>
					</div>
				</div>`

			//Save
			html += `<div style="position: relative; top: 2px; left: 480px; width:150px;">
					<div style="position: absolute; top:0px; left: 2px; height: 30px">
						<button id="item_sales_price_frame_button_save" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="">${__("Save")}</button>
					</div>
				</div>`

			//Cancel
			html += `<div style="position: relative; top: 2px; left: 640px; width:150px;">
					<div style="position: absolute; top:0px; left: 2px; height: 30px">
						<button id="item_sales_price_frame_button_cancel" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="">${__("Cancel")}</button>
					</div>
				</div>


			</div>`

		}


		html += `<div id="item_sales_price_frame_content" style="overflow: auto; overflow-x: auto; height:400px;">
		${silicon_ioi.doctype.ioiItemPriceFrame.sales_price_grid_header()}
		</div>

		<input id="item_price_frame_sales_price_selected_name" type="hidden" value="">
		<input id="item_price_frame_sales_price_selected_id" type="hidden" value="">
		<input id="item_price_frame_sales_price_nb_rows" type="hidden" value="">`;



		cur_frm.fields_dict[html_field].$wrapper.empty();
		cur_frm.fields_dict[html_field].$wrapper.append(html);

		silicon_ioi.doctype.ioiItemPriceFrame.sleep_static(200).then(() => {

			let button_new_el = document.getElementById('item_sales_price_frame_button_new');
			let button_delete_el = document.getElementById('item_sales_price_frame_button_delete');
			let button_save_el = document.getElementById('item_sales_price_frame_button_save');
			let button_cancel_el = document.getElementById('item_sales_price_frame_button_cancel');
			let button_duplicate_el = document.getElementById('item_sales_price_frame_button_duplicate');

			if (button_new_el) {

				let fct_new = function() {
					silicon_ioi.doctype.ioiItemPriceFrame.sales_price_new();
				}

				button_new_el.onclick = fct_new;
			}

			if (button_delete_el) {

				let fct_delete = function() {
					silicon_ioi.doctype.ioiItemPriceFrame.sales_price_delete();
				}

				button_delete_el.onclick = fct_delete;
			}

			if (button_save_el) {

				let fct_save = function() {
					silicon_ioi.doctype.ioiItemPriceFrame.sales_price_save();
				}

				button_save_el.onclick = fct_save;
			}

			if (button_cancel_el) {

				let fct_cancel = function() {
					silicon_ioi.doctype.ioiItemPriceFrame.sales_price_cancel();
				}

				button_cancel_el.onclick = fct_cancel;
			}

			if (button_duplicate_el) {

				let fct_duplicate = function() {
					silicon_ioi.doctype.ioiItemPriceFrame.sales_price_duplicate();
				}

				button_duplicate_el.onclick = fct_duplicate;
			}


			silicon_ioi.doctype.ioiItemPriceFrame.sales_price_get_result();

		});
	}


	static sales_price_grid_header()
	{
		if (document.getElementById('item_price_frame_sales_price_grid_header')) {
			document.getElementById('item_price_frame_sales_price_grid_header').remove();
		}

		let html = '';

		let bgcolor = silicon_ioi.doctype.ioiItemPriceFrame.bgcolor;

		silicon_ioi.doctype.ioiItemPriceFrame.sales_price_grid_width = 2910;

		html += `<table id="item_price_frame_sales_price_grid_header" width=${silicon_ioi.doctype.ioiItemPriceFrame.sales_price_grid_width.toString()}px border=1 style="border: 1px solid #E8EAEB" data-custom-grid="true">
		<tr style="height:30px">
		<td width=30px style="vertical-align: middle;">&nbsp;</td>
		<td width=100px style="vertical-align: middle;"><b>&nbsp;${__("Type")}</b></td>
		<td width=200px style="vertical-align: middle;"><b>&nbsp;${__("Catalog / Cust.")}</b></td>
		<td width=170px style="vertical-align: middle;"><b>&nbsp;${__("Condition")}</b></td>
		<td width=120px style="vertical-align: middle;"><b>&nbsp;${__("Unit")}</b></td>
		<td width=100px style="vertical-align: middle;"><b>&nbsp;${__("Curr.")}</b></td>
		<td width=80px style="vertical-align: middle;"><b>&nbsp;${__("Enabled")}</b></td>
		<td width=80px style="vertical-align: middle;"><b>&nbsp;${__("Published")}</b></td>
		<td width=120px style="vertical-align: middle;"><b>&nbsp;${__("Web Published")}</b></td>
		<td width=120px style="vertical-align: middle;"><b>&nbsp;${__("Unit price")}</b></td>
		<td width=120px style="vertical-align: middle;"><b>&nbsp;${__("Fixed price")}</b></td>
		<td width=120px style="vertical-align: middle;"><b>&nbsp;${__("Base disc. (%)")}</b></td>
		<td width=120px style="vertical-align: middle;"><b>&nbsp;${__("Extra disc. (%)")}</b></td>
		<td width=150px style="vertical-align: middle;"><b>&nbsp;${__("Discount in value")}</b></td>
		<td width=150px style="vertical-align: middle;"><b>&nbsp;${__("Start date")}</b></td>
		<td width=150px style="vertical-align: middle;"><b>&nbsp;${__("End date")}</b></td>
		<td width=150px style="vertical-align: middle;"><b>&nbsp;${__("Min order qty")}</b></td>
		<td width=150px style="vertical-align: middle;"><b>&nbsp;${__("Multiple order qty")}</b></td>
		<td width=80px style="vertical-align: middle;"><b>&nbsp;${__("VAT incl.")}</b></td>
		<td width=100px style="vertical-align: middle;"><b>&nbsp;${__("VAT %")}</b></td>
		<td width=300px style="vertical-align: middle;"><b>&nbsp;${__("Contract / Price request")}</b></td>
		<td width=200px style="vertical-align: middle;"><b>&nbsp;${__("External ref")}</b></td>

		</tr>
		</table>`

		return html;
	}

	static sales_price_grid_empty_row()
	{
		let base_default = document.getElementById('item_price_frame_sales_price_grid_detail');
		if (base_default) {

			for (var i = 0; i < base_default.rows.length; i++) {

				let id_list = ['item_price_frame_sales_price_grid_detail_name_','item_price_frame_sales_price_grid_detail_id_',
				'item_price_frame_sales_price_grid_detail_name_to_duplicate_','item_price_frame_sales_price_grid_detail_record_modified_',
				'item_price_frame_sales_price_grid_detail_new_record_','item_price_frame_sales_price_grid_detail_has_price_qty_',
				'item_price_frame_sales_price_grid_detail_document_type_','item_price_frame_sales_price_grid_detail_select_type_',
				'item_price_frame_sales_price_grid_detail_type_id_','item_price_frame_sales_price_grid_detail_checked_id_',
				'item_price_frame_sales_price_grid_detail_label_type_id_','item_price_frame_sales_price_grid_detail_label_catalog_customer_id_',
				'item_price_frame_sales_price_grid_detail_label_sales_condition_id_','item_price_frame_sales_price_grid_detail_label_unit_id_',
				'item_price_frame_sales_price_grid_detail_label_currency_id_','item_price_frame_sales_price_grid_detail_input_type_id_',
				'item_price_frame_sales_price_grid_detail_input_catalog_customer_id_','item_price_frame_sales_price_grid_detail_input_catalog_customer_button_',
				'item_price_frame_sales_price_grid_detail_input_sales_condition_id_','item_price_frame_sales_price_grid_detail_input_sales_condition_button_',
				'item_price_frame_sales_price_grid_detail_input_unit_id_','item_price_frame_sales_price_grid_detail_input_unit_button_',
				'item_price_frame_sales_price_grid_detail_input_currency_id_','item_price_frame_sales_price_grid_detail_input_currency_button_',
				'item_price_frame_sales_price_grid_detail_enabled_','item_price_frame_sales_price_grid_detail_price_published_',
				'item_price_frame_sales_price_grid_detail_web_price_published_','item_price_frame_sales_price_grid_detail_label_unit_price_',
				'item_price_frame_sales_price_grid_detail_label_fixed_price_','item_price_frame_sales_price_grid_detail_label_base_discount_',
				'item_price_frame_sales_price_grid_detail_label_extra_discount_','item_price_frame_sales_price_grid_detail_label_discount_in_value_',
				'item_price_frame_sales_price_grid_detail_label_start_date_','item_price_frame_sales_price_grid_detail_label_end_date_','item_price_frame_sales_price_grid_detail_label_min_order_qty_',
				'item_price_frame_sales_price_grid_detail_label_multiple_order_qty_','item_price_frame_sales_price_grid_detail_label_vat_included_in_price_','item_price_frame_sales_price_grid_detail_label_specific_vat_rate_',
				'item_price_frame_sales_price_grid_detail_label_document_master_link_','item_price_frame_sales_price_grid_detail_label_external_reference_',
				'item_price_frame_sales_price_grid_detail_input_unit_price_','item_price_frame_sales_price_grid_detail_input_fixed_price_',
				'item_price_frame_sales_price_grid_detail_input_base_discount_','item_price_frame_sales_price_grid_detail_input_extra_discount_',
				'item_price_frame_sales_price_grid_detail_input_discount_in_value_','item_price_frame_sales_price_grid_detail_input_start_date_',
				'item_price_frame_sales_price_grid_detail_input_end_date_','item_price_frame_sales_price_grid_detail_input_min_order_qty_',
				'item_price_frame_sales_price_grid_detail_input_multiple_order_qty_','item_price_frame_sales_price_grid_detail_input_vat_included_in_price_',
				'item_price_frame_sales_price_grid_detail_input_specific_vat_rate_','item_price_frame_sales_price_grid_detail_input_external_reference_'];

				for (let nbre = 0 ;nbre < id_list.length; nbre ++)
				{
					let element_id = document.getElementById(id_list[nbre]+ i.toString());
					if (element_id)
					{
						element_id.remove();
					}
				}


				base_default.deleteRow(i);

			}

			base_default.remove();
		}


		let html = '';

		html += `<table id="item_price_frame_sales_price_grid_detail" width=${silicon_ioi.doctype.ioiItemPriceFrame.sales_price_grid_width.toString()}px border=1 style="border: 1px solid #E8EAEB">
		<tr style="height:30px">
		<td width=30px  style="vertical-align: middle;">&nbsp;</td>
		<td width=100px style="vertical-align: middle;">&nbsp;</td>
		<td width=200px style="vertical-align: middle;">&nbsp;</td>
		<td width=170px style="vertical-align: middle;">&nbsp;</td>
		<td width=120px style="vertical-align: middle;">&nbsp;</td>
		<td width=100px style="vertical-align: middle;">&nbsp;</td>
		<td width=80px  style="vertical-align: middle;">&nbsp;</td>
		<td width=80px  style="vertical-align: middle;">&nbsp;</td>
		<td width=120px style="vertical-align: middle;">&nbsp;</td>
		<td width=120px style="vertical-align: middle;">&nbsp;</td>
		<td width=120px style="vertical-align: middle;">&nbsp;</td>
		<td width=120px style="vertical-align: middle;">&nbsp;</td>
		<td width=120px style="vertical-align: middle;">&nbsp;</td>
		<td width=150px style="vertical-align: middle;">&nbsp;</td>
		<td width=150px style="vertical-align: middle;">&nbsp;</td>
		<td width=150px style="vertical-align: middle;">&nbsp;</td>
		<td width=150px style="vertical-align: middle;">&nbsp;</td>
		<td width=150px style="vertical-align: middle;">&nbsp;</td>
		<td width=80px  style="vertical-align: middle;">&nbsp;</td>
		<td width=100px style="vertical-align: middle;">&nbsp;</td>
		<td width=300px style="vertical-align: middle;">&nbsp;</td>
		<td width=200px style="vertical-align: middle;">&nbsp;</td>
		</tr>
		</table>`;

		return html;
	}



	static sales_price_get_result()
	{
		if (silicon_ioi.doctype.ioiItemPriceFrame.item_id.trim() == '') {

			let html = silicon_ioi.doctype.ioiItemPriceFrame.sales_price_grid_empty_row();
			document.getElementById('item_price_frame_sales_price_grid_header').insertAdjacentHTML('afterend', html);

			document.getElementById('item_price_frame_sales_price_nb_rows').value = 1;


		}else{

			frappe.call({ 	method: silicon_ioi.doctype.ioiItemPriceFrame.path_sales_price + ".ioi_sales_price_get_prices_for_an_item",
							args: {"item_id" : silicon_ioi.doctype.ioiItemPriceFrame.item_id},
							async : true,
							callback:function(r){

								let base_id = document.getElementById('item_price_frame_sales_price_grid_detail');
								if (base_id) {

									for (var i = 0; i < base_id.rows.length; i++) {

										let id_list = ['item_price_frame_sales_price_grid_detail_name_','item_price_frame_sales_price_grid_detail_name_to_duplicate_',
										'item_price_frame_sales_price_grid_detail_id_','item_price_frame_sales_price_grid_detail_document_type_',
										'item_price_frame_sales_price_grid_detail_record_modified_','item_price_frame_sales_price_grid_detail_new_record_',
										'item_price_frame_sales_price_grid_detail_select_type_','item_price_frame_sales_price_grid_detail_type_id_',
										'item_price_frame_sales_price_grid_detail_checked_id_','item_price_frame_sales_price_grid_detail_label_type_id_',
										'item_price_frame_sales_price_grid_detail_has_price_qty_','item_price_frame_sales_price_grid_detail_label_catalog_customer_id_',
										'item_price_frame_sales_price_grid_detail_label_sales_condition_id_','item_price_frame_sales_price_grid_detail_label_unit_id_',
										'item_price_frame_sales_price_grid_detail_label_currency_id_','item_price_frame_sales_price_grid_detail_input_type_id_',
										'item_price_frame_sales_price_grid_detail_input_catalog_customer_id_','item_price_frame_sales_price_grid_detail_input_catalog_customer_button_',
										'item_price_frame_sales_price_grid_detail_input_vat_included_in_price_','item_price_frame_sales_price_grid_detail_input_specific_vat_rate_',
										'item_price_frame_sales_price_grid_detail_input_sales_condition_id_','item_price_frame_sales_price_grid_detail_input_sales_condition_button_',
										'item_price_frame_sales_price_grid_detail_input_unit_id_','item_price_frame_sales_price_grid_detail_input_unit_button_',
										'item_price_frame_sales_price_grid_detail_input_currency_id_','item_price_frame_sales_price_grid_detail_input_currency_button_',
										'item_price_frame_sales_price_grid_detail_enabled_','item_price_frame_sales_price_grid_detail_price_published_',
										'item_price_frame_sales_price_grid_detail_web_price_published_','item_price_frame_sales_price_grid_detail_label_unit_price_',
										'item_price_frame_sales_price_grid_detail_label_fixed_price_','item_price_frame_sales_price_grid_detail_label_base_discount_',
										'item_price_frame_sales_price_grid_detail_label_extra_discount_','item_price_frame_sales_price_grid_detail_label_discount_in_value_',
										'item_price_frame_sales_price_grid_detail_label_start_date_','item_price_frame_sales_price_grid_detail_label_end_date_',
										'item_price_frame_sales_price_grid_detail_label_min_order_qty_','item_price_frame_sales_price_grid_detail_label_multiple_order_qty_',
										'item_price_frame_sales_price_grid_detail_label_vat_included_in_price_','item_price_frame_sales_price_grid_detail_label_specific_vat_rate_',
										'item_price_frame_sales_price_grid_detail_label_document_master_link_','item_price_frame_sales_price_grid_detail_label_external_reference_',
										'item_price_frame_sales_price_grid_detail_input_unit_price_','item_price_frame_sales_price_grid_detail_input_fixed_price_',
										'item_price_frame_sales_price_grid_detail_input_base_discount_','item_price_frame_sales_price_grid_detail_input_extra_discount_',
										'item_price_frame_sales_price_grid_detail_input_discount_in_value_','item_price_frame_sales_price_grid_detail_input_start_date_',
										'item_price_frame_sales_price_grid_detail_input_end_date_','item_price_frame_sales_price_grid_detail_input_min_order_qty_',
										'item_price_frame_sales_price_grid_detail_input_multiple_order_qty_','item_price_frame_sales_price_grid_detail_input_external_reference_'];

										for (let nbre = 0 ; nbre < id_list.length; nbre ++)
										{
											let compled_el = document.getElementById(id_list[nbre]+ i.toString());
											if (compled_el)
											{
												compled_el.remove();
											}
										}



										base_id.deleteRow(i);


									}
								}

								while (document.getElementById('item_price_frame_sales_price_grid_detail')) {
									document.getElementById('item_price_frame_sales_price_grid_detail').remove();
								}

								let at_least_one_row = false;
								let can_modify = silicon_ioi.doctype.ioiItemPriceFrame.can_modify_sales;


								let html = '';

								if (r.message.data.length > 0)
								{

									let cpt = 0;

									html = `<table id="item_price_frame_sales_price_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=${silicon_ioi.doctype.ioiItemPriceFrame.sales_price_grid_width}px>`;

									for (var i = 0; i < r.message.data.length; i++)
									{
										at_least_one_row = true;

										let cell_bgcolor = '';

										if (silicon_ioi.doctype.ioiItemPriceFrame.is_dark_mode == 0) {
											cell_bgcolor = '#FFFFFF';
										}else{
											cell_bgcolor = '#1C2126';
										}

										html += `<input id="item_price_frame_sales_price_grid_detail_name_${i.toString()}" type="hidden" value="${r.message.data[i].name}">
										<input id="item_price_frame_sales_price_grid_detail_id_${i.toString()}" type="hidden" value="${i.toString()}">
										<input id="item_price_frame_sales_price_grid_detail_record_modified_${i.toString()}" type="hidden" value="N">
										<input id="item_price_frame_sales_price_grid_detail_new_record_${i.toString()}" type="hidden" value="N">
										<input id="item_price_frame_sales_price_grid_detail_name_to_duplicate_${i.toString()}" type="hidden" value="">
										<input id="item_price_frame_sales_price_grid_detail_has_price_qty_${i.toString()}" type="hidden" value="${r.message.data[i].has_price_qty}">

										<input id="item_price_frame_sales_price_grid_detail_select_type_${i.toString()}" type="hidden" value="${r.message.data[i].select_type}">
										<input id="item_price_frame_sales_price_grid_detail_type_id_${i.toString()}" type="hidden" value="${r.message.data[i].type_id}">
										<input id="item_price_frame_sales_price_grid_detail_document_type_${i.toString()}" type="hidden" value="${r.message.data[i].document_type}">


										<tr id="item_price_frame_sales_price_grid_detail_row_${i.toString()}"  style="height:30px">

										<td width=30px style="vertical-align: middle;" align="center">

										<input type="checkbox" id="item_price_frame_sales_price_grid_detail_checked_id_${i.toString()}" style="postion:absolute; top: 2px; left: 2px;"
										onkeydown="   if (event.keyCode == 13) { return false; } "
										onkeyup="   if (event.keyCode == 13) { return false; } "
										>


										</td>`;


										let url_sales_price = '/app/ioi-sales-price/' + r.message.data[i].name;

										if (r.message.data[i].type_id.toUpperCase() == 'IOI CUSTOMER')
										{	html += `<td width=100px style="background-color:${cell_bgcolor}; vertical-align: middle;">&nbsp;
											<label id="item_price_frame_sales_price_grid_detail_label_type_id_${i.toString()}" style="position:relative; top:4px; color: #000000;" onclick="window.open(\'${url_sales_price}'\);"><u>${__("Customer")}</u></label>
											</td>`;
										}else
										{	html += `<td width=100px style="background-color:${cell_bgcolor}; vertical-align: middle;">&nbsp;
											<label id="item_price_frame_sales_price_grid_detail_label_type_id_${i.toString()}" style="position:relative; top:4px; color: #000000;" onclick="window.open(\'${url_sales_price}'\);"><u>${__("Catalog")}</u></label>
											</td>`;
										}


										if (r.message.data[i].catalog_customer_id)
										{	html += `<td width=200px style="background-color:${cell_bgcolor}; vertical-align: middle;">&nbsp;
											<label id="item_price_frame_sales_price_grid_detail_label_catalog_customer_id_${i.toString()}" style="position:relative; top:4px; color: #000000;" onclick="window.open(\'${url_sales_price}'\);"><u>${r.message.data[i].catalog_customer_id}</u></label>
											</td>`;
										}else
										{	html += `<td width=200px style="background-color:${cell_bgcolor}; vertical-align: middle;">&nbsp;
											<label id="item_price_frame_sales_price_grid_detail_label_catalog_customer_id_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>
											</td>`;
										}

										if (r.message.data[i].sales_condition_id)
										{	html += `<td width=170px style="background-color:${cell_bgcolor}; vertical-align: middle;">&nbsp;
											<label id="item_price_frame_sales_price_grid_detail_label_sales_condition_id_${i.toString()}" style="position:relative; top:4px; color: #000000;" onclick="window.open(\'${url_sales_price}'\);"><u>${r.message.data[i].sales_condition_id}</u></label>
											</td>`;
										}else
										{	html += `<td width=170px style="background-color:${cell_bgcolor}; vertical-align: middle;">&nbsp;
											<label id="item_price_frame_sales_price_grid_detail_label_sales_condition_id_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>
											</td>`;
										}

										if (r.message.data[i].unit_id)
										{	html += `<td width=120px style="background-color:${cell_bgcolor}; vertical-align: middle;">&nbsp;
											<label id="item_price_frame_sales_price_grid_detail_label_unit_id_${i.toString()}" style="position:relative; top:4px; color: #000000;" onclick="window.open(\'${url_sales_price}'\);"><u>${r.message.data[i].unit_id}</u></label>
											</td>`;
										}else
										{	html += `<td width=120px style="background-color:${cell_bgcolor}; vertical-align: middle;">&nbsp;
											<label id="item_price_frame_sales_price_grid_detail_label_unit_id_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>
											</td>`;
										}

										if (r.message.data[i].currency_id)
										{	html += `<td width=100px style="background-color:${cell_bgcolor}; vertical-align: middle;">&nbsp;
											<label id="item_price_frame_sales_price_grid_detail_label_currency_id_${i.toString()}" style="position:relative; top:4px; color: #000000;" onclick="window.open(\'${url_sales_price}'\);"><u>${r.message.data[i].currency_id}</u></label>
											</td>`;
										}else
										{	html += `<td width=100px style="background-color:${cell_bgcolor}; vertical-align: middle;">&nbsp;
											<label id="item_price_frame_sales_price_grid_detail_label_currency_id_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>
											</td>`;
										}

										if (r.message.data[i].enabled)
										{
											html += `<td width=80px align="center" style="background-color:${cell_bgcolor}; vertical-align: middle;">
											<input type="checkbox" id="item_price_frame_sales_price_grid_detail_enabled_${i.toString()}" style="postion:absolute; top: 2px; left: 2px;" `;

											if (r.message.data[i].enabled == 1)	{
												html += "checked"
											}

											if (!can_modify) {
												html += ' disabled ';
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += ' disabled ';
													}
												}
											}

											html += `>
											</td>`;

										}else
										{
											html += `<td width=80px align="center" style="background-color:${cell_bgcolor}; vertical-align: middle;">
											<input type="checkbox" id="item_price_frame_sales_price_grid_detail_enabled_${i.toString()}" style="postion:absolute; top: 2px; left: 2px;" `;

											if (!can_modify) {
												html += ' disabled ';
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += ' disabled ';
													}
												}
											}

											html += `>
											</td>`;
										}


										if (r.message.data[i].price_published)
										{
											html += `<td width=80px align="center" style="background-color:${cell_bgcolor}; vertical-align: middle;">
											<input type="checkbox" id="item_price_frame_sales_price_grid_detail_price_published_${i.toString()}" style="postion:absolute; top: 2px; left: 2px;" `;

											if (r.message.data[i].price_published == 1)	{
												html += "checked"
											}

											if (!can_modify) {
												html += ' disabled ';
											}else{

												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += ' disabled ';
													}
												}
											}

											html += `>
											</td>`;

										}else
										{
											html += `<td width=80px align="center" style="background-color:${cell_bgcolor}; vertical-align: middle;">
											<input type="checkbox" id="item_price_frame_sales_price_grid_detail_price_published_${i.toString()}" style="postion:absolute; top: 2px; left: 2px;" `;

											if (!can_modify) {
												html += ' disabled ';
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += ' disabled ';
													}
												}
											}

											html += `>
											</td>`;
										}


										if (r.message.data[i].web_price_published)
										{
											html += `<td width=120px align="center" style="background-color:${cell_bgcolor}; vertical-align: middle;">
											<input type="checkbox" id="item_price_frame_sales_price_grid_detail_web_price_published_${i.toString()}" style="postion:absolute; top: 2px; left: 2px;" `;

											if (r.message.data[i].web_price_published == 1)	{
												html += "checked"
											}

											if (!can_modify) {
												html += ' disabled ';
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += ' disabled ';
													}
												}

											}

											html += `>
											</td>`;

										}else
										{
											html += `<td width=120px align="center" style="background-color:${cell_bgcolor}; vertical-align: middle;">
											<input type="checkbox" id="item_price_frame_sales_price_grid_detail_web_price_published_${i.toString()}" style="postion:absolute; top: 2px; left: 2px;" `;

											if (!can_modify) {
												html += ' disabled';
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += ' disabled ';
													}
												}
											}

											html += `>
											</td>`;

										}


										html += `<input id="item_price_frame_sales_price_grid_detail_document_type_${i.toString()}" type="hidden" value="${r.message.data[i].document_type}">`;


										if (r.message.data[i].unit_price)
										{
											if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
											{
												if (r.message.data[i].document_type == 'CONTRACT') {

													html += `<td id="item_price_frame_sales_price_grid_detail_cell_unit_price_${i.toString()}" width=120px align="right" style="background-color:#79FF79; vertical-align: middle;">
													<label id="item_price_frame_sales_price_grid_detail_label_unit_price_${i.toString()}" style="position:relative; top:4px; color: #000000;">`;

													if (silicon_ioi.doctype.ioiItemPriceFrame.can_read_sales) {
														html += r.message.data[i].unit_price.toString();
													}
													html += `&nbsp;</label></td>`;


												}else{
													if (!can_modify) {
														html += `<td id="item_price_frame_sales_price_grid_detail_cell_unit_price_${i.toString()}" width=120px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">
														<label id="item_price_frame_sales_price_grid_detail_label_unit_price_${i.toString()}" style="position:relative; top:4px; color: #000000;">`;


														if (silicon_ioi.doctype.ioiItemPriceFrame.can_read_sales) {
															html += r.message.data[i].unit_price.toString();
														}

														html += `&nbsp;</label></td>`;

													}else{

														html += `<td id="item_price_frame_sales_price_grid_detail_cell_unit_price_${i.toString()}" width=120px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">
														<input id="item_price_frame_sales_price_grid_detail_input_unit_price_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="${r.message.data[i].unit_price}">
														</td>`;
													}
												}
											}else {
												html += `<td id="item_price_frame_sales_price_grid_detail_cell_unit_price_${i.toString()}" width=120px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">`;

												if (!can_modify) {
													html += `<label id="item_price_frame_sales_price_grid_detail_label_unit_price_${i.toString()}" style="position:relative; top:4px; color: #000000;">`;

													if (silicon_ioi.doctype.ioiItemPriceFrame.can_read_sales) {
														html += r.message.data[i].unit_price.toString();
													}

													html += `&nbsp;</label>`;
												}else{
													html += `<input id="item_price_frame_sales_price_grid_detail_input_unit_price_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="${r.message.data[i].unit_price}">`;
												}
												html += `</td>`;
											}
										}else
										{
											html += `<td id="item_price_frame_sales_price_grid_detail_cell_unit_price_${i.toString()}" width=120px style="background-color:${cell_bgcolor}; vertical-align: middle;">`;
											if (!can_modify) {
												html += `<label id="item_price_frame_sales_price_grid_detail_label_unit_price_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
											}else{
												html += `<input id="item_price_frame_sales_price_grid_detail_input_unit_price_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="">`;
											}
											html += `</td>`;
										}


										html += `<td width=120px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">`;

										if (r.message.data[i].fixed_price)
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_sales_price_grid_detail_label_fixed_price_${i.toString()}" style="position:relative; top:4px; color: #000000;">`;

												if (silicon_ioi.doctype.ioiItemPriceFrame.can_read_sales) {
													html += r.message.data[i].fixed_price.toString();
												}

												html += `&nbsp;</label>`;
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {

														html += `<label id="item_price_frame_sales_price_grid_detail_label_fixed_price_${i.toString()}" style="position:relative; top:4px; color: #000000;">`;

														if (silicon_ioi.doctype.ioiItemPriceFrame.can_read_sales) {
															html += r.message.data[i].fixed_price.toString();
														}

														html += `&nbsp;</label>`;


													}else{
														html += `<input id="item_price_frame_sales_price_grid_detail_input_fixed_price_${i.toString()}" type="number" step="any" style="width:118px;height:30px;text-align:right;" value="${r.message.data[i].fixed_price}">`;
													}
												}else{
													html += `<input id="item_price_frame_sales_price_grid_detail_input_fixed_price_${i.toString()}" type="number" step="any" style="width:118px;height:30px;text-align:right;" value="${r.message.data[i].fixed_price}">`;
												}

											}
										}else
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_sales_price_grid_detail_label_fixed_price_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
											}else{

												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_sales_price_grid_detail_label_fixed_price_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
													}else{
														html += `<input id="item_price_frame_sales_price_grid_detail_input_fixed_price_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="">`;
													}
												}else{
													html += `<input id="item_price_frame_sales_price_grid_detail_input_fixed_price_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="">`;
												}

											}
										}

										html += `</td>`;

										html += `<td id="item_price_frame_sales_price_grid_detail_cell_base_discount_${i.toString()}" width=120px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">`;

										if (r.message.data[i].base_discount)
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_sales_price_grid_detail_label_base_discount_${i.toString()}" style="position:relative; top:4px; color: #000000;">`;

												if (silicon_ioi.doctype.ioiItemPriceFrame.can_read_sales) {
													html += r.message.data[i].base_discount.toFixed(2).toString() + '&nbsp;%';
												}

												html += '&nbsp;</label>';
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {

														html += `<label id="item_price_frame_sales_price_grid_detail_label_base_discount_${i.toString()}" style="position:relative; top:4px; color: #000000;">`;

														if (silicon_ioi.doctype.ioiItemPriceFrame.can_read_sales) {
															html += r.message.data[i].base_discount.toFixed(2).toString() + '&nbsp;%';
														}

														html += `&nbsp;</label>`;
													}else{
														html += `<input id="item_price_frame_sales_price_grid_detail_input_base_discount_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="${r.message.data[i].base_discount}">`;

													}
												}else{
													html += `<input id="item_price_frame_sales_price_grid_detail_input_base_discount_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="${r.message.data[i].base_discount}">`;
												}
											}
										}else
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_sales_price_grid_detail_label_base_discount_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
											}else{

												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_sales_price_grid_detail_label_base_discount_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
													}else{
														html += `<input id="item_price_frame_sales_price_grid_detail_input_base_discount_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="">`;
													}
												}else{
													html += `<input id="item_price_frame_sales_price_grid_detail_input_base_discount_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="">`;
												}
											}
										}

										html += `</td>`;

										html += `<td id="item_price_frame_sales_price_grid_detail_cell_extra_discount_${i.toString()}" width=120px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">`;

										if (r.message.data[i].extra_discount)
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_sales_price_grid_detail_label_extra_discount_${i.toString()}" style="position:relative; top:4px; color: #000000;">`;

												if (silicon_ioi.doctype.ioiItemPriceFrame.can_read_sales) {
													html += r.message.data[i].extra_discount.toFixed(2).toString() + '&nbsp;%';
												}

												html += '&nbsp;</label>';
											}else{

												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {

														html += `<label id="item_price_frame_sales_price_grid_detail_label_extra_discount_${i.toString()}" style="position:relative; top:4px; color: #000000;">`;

														if (silicon_ioi.doctype.ioiItemPriceFrame.can_read_sales) {
															html += r.message.data[i].extra_discount.toFixed(2).toString() + '&nbsp;%';
														}

														html += `&nbsp;</label>`;


													}else{
														html += `<input id="item_price_frame_sales_price_grid_detail_input_extra_discount_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="${r.message.data[i].extra_discount}">`;
													}
												}else{
													html += `<input id="item_price_frame_sales_price_grid_detail_input_extra_discount_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="${r.message.data[i].extra_discount}">`;
												}


											}
										}else
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_sales_price_grid_detail_label_extra_discount_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
											}else{

												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_sales_price_grid_detail_label_extra_discount_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
													}else{
														html += `<input id="item_price_frame_sales_price_grid_detail_input_extra_discount_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="">`;
													}
												}else{
													html += `<input id="item_price_frame_sales_price_grid_detail_input_extra_discount_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="">`;
												}

											}
										}

										html += `</td>`;


										html += `<td width=150px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">`;

										if (r.message.data[i].discount_in_value)
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_sales_price_grid_detail_label_discount_in_value_${i.toString()}" style="position:relative; top:4px; color: #000000;">`;

												if (silicon_ioi.doctype.ioiItemPriceFrame.can_read_sales) {
													html += r.message.data[i].discount_in_value.toString();
												}

												html += `&nbsp;</label>`;
											}else{

												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {

														html += `<label id="item_price_frame_sales_price_grid_detail_label_discount_in_value_${i.toString()}" style="position:relative; top:4px; color: #000000;">`;

														if (silicon_ioi.doctype.ioiItemPriceFrame.can_read_sales) {
															html += r.message.data[i].discount_in_value.toString();
														}

														html += `&nbsp;</label>`;


													}else{
														html += `<input id="item_price_frame_sales_price_grid_detail_input_discount_in_value_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="${r.message.data[i].discount_in_value}">`;
													}
												}else{
													html += `<input id="item_price_frame_sales_price_grid_detail_input_discount_in_value_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="${r.message.data[i].discount_in_value}">`;
												}


											}
										}else
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_sales_price_grid_detail_label_discount_in_value_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
											}else{

												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {

														html += `<label id="item_price_frame_sales_price_grid_detail_label_discount_in_value_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;

													}else{
														html += `<input id="item_price_frame_sales_price_grid_detail_input_discount_in_value_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="">`;
													}
												}else{
													html += `<input id="item_price_frame_sales_price_grid_detail_input_discount_in_value_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="">`;
												}


											}
										}

										html += `</td>`;


										html += `<td width=150px style="background-color:${cell_bgcolor}; vertical-align: middle;">`;

										if (r.message.data[i].start_date)
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_sales_price_grid_detail_label_start_date_${i.toString()}" style="position:relative; top:4px; color: #000000;">&nbsp;${r.message.data[i].start_date}</label>`;
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_sales_price_grid_detail_label_start_date_${i.toString()}" style="position:relative; top:4px; color: #000000;">&nbsp;${r.message.data[i].start_date}</label>`;
													}else{
														html += `<input id="item_price_frame_sales_price_grid_detail_input_start_date_${i.toString()}" type="date" style="width:146px;height:30px;" value="${r.message.data[i].start_date}">`;
													}
												}else{
													html += `<input id="item_price_frame_sales_price_grid_detail_input_start_date_${i.toString()}" type="date" style="width:146px;height:30px;" value="${r.message.data[i].start_date}">`;
												}
											}
										}else
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_sales_price_grid_detail_label_start_date_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
											}else{

												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_sales_price_grid_detail_label_start_date_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
													}else{
														html += `<input id="item_price_frame_sales_price_grid_detail_input_start_date_${i.toString()}" type="date" style="width:146px;height:30px;" value="">`;
													}

												}else{
													html += `<input id="item_price_frame_sales_price_grid_detail_input_start_date_${i.toString()}" type="date" style="width:146px;height:30px;" value="">`;
												}
											}
										}
										html += `</td>`;

										html += `<td width=150px style="background-color:${cell_bgcolor}; vertical-align: middle;">`;
										if (r.message.data[i].end_date)
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_sales_price_grid_detail_label_end_date_${i.toString()}" style="position:relative; top:4px; color: #000000;">&nbsp;${r.message.data[i].end_date}</label>`;
											}else{

												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_sales_price_grid_detail_label_end_date_${i.toString()}" style="position:relative; top:4px; color: #000000;">&nbsp;${r.message.data[i].end_date}</label>`;
													}else{
														html += `<input id="item_price_frame_sales_price_grid_detail_input_end_date_${i.toString()}" type="date" style="width:146px;height:30px;" value="${r.message.data[i].end_date}">`;
													}
												}else{
													html += `<input id="item_price_frame_sales_price_grid_detail_input_end_date_${i.toString()}" type="date" style="width:146px;height:30px;" value="${r.message.data[i].end_date}">`;
												}
											}

										}else
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_sales_price_grid_detail_label_end_date_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_sales_price_grid_detail_label_end_date_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
													}else{
														html += `<input id="item_price_frame_sales_price_grid_detail_input_end_date_${i.toString()}" type="date" style="width:146px;height:30px;" value="">`;
													}
												}else{
													html += `<input id="item_price_frame_sales_price_grid_detail_input_end_date_${i.toString()}" type="date" style="width:146px;height:30px;" value="">`;
												}
											}
										}

										html += `</td>


										<td width=150px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">`;

										if (r.message.data[i].min_order_qty)
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_sales_price_grid_detail_label_min_order_qty_${i.toString()}" style="position:relative; top:4px; color: #000000;">${r.message.data[i].min_order_qty}&nbsp;</label>`;
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_sales_price_grid_detail_label_min_order_qty_${i.toString()}" style="position:relative; top:4px; color: #000000;">${r.message.data[i].min_order_qty}&nbsp;</label>`;
													}else{
														html += `<input id="item_price_frame_sales_price_grid_detail_input_min_order_qty_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="${r.message.data[i].min_order_qty}">`;
													}
												}else{
													html += `<input id="item_price_frame_sales_price_grid_detail_input_min_order_qty_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="${r.message.data[i].min_order_qty}">`;
												}
											}
										}else
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_sales_price_grid_detail_label_min_order_qty_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_sales_price_grid_detail_label_min_order_qty_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
													}else{
														html += `<input id="item_price_frame_sales_price_grid_detail_input_min_order_qty_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="">`;
													}
												}else{
													html += `<input id="item_price_frame_sales_price_grid_detail_input_min_order_qty_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="">`;
												}
											}
										}

										html += `</td>

										<td width=150px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">`;

										if (r.message.data[i].multiple_order_qty)
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_sales_price_grid_detail_label_multiple_order_qty_${i.toString()}" style="position:relative; top:4px; color: #000000;">${r.message.data[i].multiple_order_qty}&nbsp;</label>`;
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_sales_price_grid_detail_label_multiple_order_qty_${i.toString()}" style="position:relative; top:4px; color: #000000;">${r.message.data[i].multiple_order_qty}&nbsp;</label>`;
													}else{
														html += `<input id="item_price_frame_sales_price_grid_detail_input_multiple_order_qty_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="${r.message.data[i].multiple_order_qty}">`;
													}
												}else{
													html += `<input id="item_price_frame_sales_price_grid_detail_input_multiple_order_qty_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="${r.message.data[i].multiple_order_qty}">`;
												}
											}
										}else
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_sales_price_grid_detail_label_multiple_order_qty_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_sales_price_grid_detail_label_multiple_order_qty_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
													}else{
														html += `<input id="item_price_frame_sales_price_grid_detail_input_multiple_order_qty_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="">`;
													}
												}else{
													html += `<input id="item_price_frame_sales_price_grid_detail_input_multiple_order_qty_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="">`;
												}
											}
										}

										html += `</td>`;

										if (r.message.data[i].vat_included_in_price)
										{
											html += `<td width=80px align="center" style="background-color:${cell_bgcolor}; vertical-align: middle;">
											<input type="checkbox" id="item_price_frame_sales_price_grid_detail_input_vat_included_in_price_${i.toString()}" style="postion:absolute; top: 2px; left: 2px;" `;

											if (r.message.data[i].vat_included_in_price == 1)	{
												html += "checked"
											}

											if (!can_modify) {
												html += ' disabled ';
											}else{

												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += ' disabled ';

													}
												}
											}

											html += `>
											</td>`;

										}else
										{
											html += `<td width=80px align="center" style="background-color:${cell_bgcolor}; vertical-align: middle;">
											<input type="checkbox" id="item_price_frame_sales_price_grid_detail_input_vat_included_in_price_${i.toString()}" style="postion:absolute; top: 2px; left: 2px;" `;

											if (!can_modify) {
												html += ' disabled ';
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += ' disabled ';

													}
												}

											}

											html += `>
											</td>`;
										}

										html += `<td id="item_price_frame_sales_price_grid_detail_cell_extra_discount_${i.toString()}" width=100px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">`;

										if (r.message.data[i].specific_vat_rate)
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_sales_price_grid_detail_label_specific_vat_rate_${i.toString()}" style="position:relative; top:4px; color: #000000;">`;

												if (silicon_ioi.doctype.ioiItemPriceFrame.can_read_sales) {
													html += r.message.data[i].specific_vat_rate.toFixed(2).toString() + '&nbsp;%';
												}

												html += `&nbsp;</label>`;
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {

														html += `<label id="item_price_frame_sales_price_grid_detail_label_specific_vat_rate_${i.toString()}" style="position:relative; top:4px; color: #000000;">`;

														if (silicon_ioi.doctype.ioiItemPriceFrame.can_read_sales) {
															html += r.message.data[i].specific_vat_rate.toFixed(2).toString() + '&nbsp;%';
														}

														html += `&nbsp;</label>`;


													}else{
														html += `<input id="item_price_frame_sales_price_grid_detail_input_specific_vat_rate_${i.toString()}" type="number" step="any" style="width:96px;height:30px;text-align:right;" value="${r.message.data[i].specific_vat_rate}">`;
													}
												}else{
													html += `<input id="item_price_frame_sales_price_grid_detail_input_specific_vat_rate_${i.toString()}" type="number" step="any" style="width:96px;height:30px;text-align:right;" value="${r.message.data[i].specific_vat_rate}">`;
												}
											}
										}else
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_sales_price_grid_detail_label_specific_vat_rate_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
											}else{

												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_sales_price_grid_detail_label_specific_vat_rate_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
													}else{
														html += `<input id="item_price_frame_sales_price_grid_detail_input_specific_vat_rate_${i.toString()}" type="number" step="any" style="width:96px;height:30px;text-align:right;" value="">`;
													}
												}else{
													html += `<input id="item_price_frame_sales_price_grid_detail_input_specific_vat_rate_${i.toString()}" type="number" step="any" style="width:96px;height:30px;text-align:right;" value="">`;
												}

											}
										}

										html += `</td>



										<td width=300px style="background-color:${cell_bgcolor}; vertical-align: middle;">&nbsp;`;

										if (r.message.data[i].document_master_link) {
											let url_master_link = '/app/ioi-sales-quote/' + r.message.data[i].document_master_link;
											html += `<label id="item_price_frame_sales_price_grid_detail_label_document_master_link_${i.toString()}" style="position:relative; top:4px; color: #000000;" onclick="window.open(\'${url_master_link}'\);"><u>${r.message.data[i].document_master_link}</u></label>`;
										}else {
											html += `<label id="item_price_frame_sales_price_grid_detail_label_document_master_link_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
										}
										html += `</td>

										<td width=200px style="background-color:${cell_bgcolor}; vertical-align: middle;">`;

										if (r.message.data[i].external_reference)
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_sales_price_grid_detail_label_external_reference_${i.toString()}" style="position:relative; top:4px; color: #000000;">&nbsp;${r.message.data[i].external_reference}&nbsp;</label>`;
											}else{

												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_sales_price_grid_detail_label_external_reference_${i.toString()}" style="position:relative; top:4px; color: #000000;">&nbsp;${r.message.data[i].external_reference}&nbsp;</label>`;
													}else{
														html += `<input id="item_price_frame_sales_price_grid_detail_input_external_reference_${i.toString()}" type="text" style="width:196px;height:30px;" value="${r.message.data[i].external_reference}">`;
													}
												}else{
													html += `<input id="item_price_frame_sales_price_grid_detail_input_external_reference_${i.toString()}" type="text" style="width:196px;height:30px;" value="${r.message.data[i].external_reference}">`;
												}
											}
										}else
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_sales_price_grid_detail_label_external_reference_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
											}else{

												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_sales_price_grid_detail_label_external_reference_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
													}else{
														html += `<input id="item_price_frame_sales_price_grid_detail_input_external_reference_${i.toString()}" type="text" style="width:196px;height:30px;" value="">`;
													}
												}else{
													html += `<input id="item_price_frame_sales_price_grid_detail_input_external_reference_${i.toString()}" type="text" style="width:196px;height:30px;" value="">`;
												}
											}
										}

										html += `</td>


										</tr>`

										cpt++;
									}

									html += `</table>`;

									document.getElementById('item_price_frame_sales_price_nb_rows').value = cpt;

								}else
								{
									html += silicon_ioi.doctype.ioiItemPriceFrame.sales_price_grid_empty_row();
									document.getElementById('item_price_frame_sales_price_nb_rows').value = 0;
								}


								html += `</div>`;





								document.getElementById('item_price_frame_sales_price_grid_header').insertAdjacentHTML('afterend', html);

								silicon_ioi.doctype.ioiItemPriceFrame.sleep_static(400).then(() => {

									if (at_least_one_row) {

										let fct_row_click = function () { silicon_ioi.doctype.ioiItemPriceFrame.sales_price_row_click(this.id); };
										let fct_edit_value = function() { silicon_ioi.doctype.ioiItemPriceFrame.sales_price_edit(this.id); };

										let fct_keydown = function(event) {

											if (event.keyCode == 13)
											{	return false;
											}

										};



										let fct_keyup = function(event) {

											if (event.keyCode == 13)
											{	return false;
											}
										};


										for (var i = 0; i < document.getElementById('item_price_frame_sales_price_grid_detail').rows.length; i++)	{

											document.getElementById('item_price_frame_sales_price_grid_detail').rows[i].onclick = fct_row_click;

											if (silicon_ioi.doctype.ioiItemPriceFrame.can_modify_sales) {
												silicon_ioi.doctype.ioiItemPriceFrame.assign_row_events(i, fct_edit_value, fct_keydown, fct_keyup);
											}
										}



										for (var j = 0; j < document.getElementById('item_price_frame_sales_price_grid_detail').rows[0].cells.length; j++) {

											if (silicon_ioi.doctype.ioiItemPriceFrame.is_dark_mode == 0) {
												document.getElementById('item_price_frame_sales_price_grid_detail').rows[0].cells[j].style.backgroundColor = '#b1fcd9';
											}else{
												document.getElementById('item_price_frame_sales_price_grid_detail').rows[0].cells[j].style.backgroundColor = 'green';
											}
										}

										silicon_ioi.doctype.ioiItemPriceFrame.sales_price_row_click(document.getElementById('item_price_frame_sales_price_grid_detail').rows[0].id);

										document.getElementById('item_sales_price_frame_content').scrollTop = 0;
									}
								});
							}
			});
		}
	}

	static assign_row_events(i, fct_edit_value, fct_keydown, fct_keyup)
	{

		let id_list = ['item_price_frame_sales_price_grid_detail_enabled_','item_price_frame_sales_price_grid_detail_price_published_',
		'item_price_frame_sales_price_grid_detail_web_price_published_','item_price_frame_sales_price_grid_detail_input_unit_price_',
		'item_price_frame_sales_price_grid_detail_input_fixed_price_','item_price_frame_sales_price_grid_detail_input_base_discount_',
		'item_price_frame_sales_price_grid_detail_input_extra_discount_','item_price_frame_sales_price_grid_detail_input_discount_in_value_',
		'item_price_frame_sales_price_grid_detail_input_start_date_','item_price_frame_sales_price_grid_detail_input_end_date_','item_price_frame_sales_price_grid_detail_input_min_order_qty_',
		'item_price_frame_sales_price_grid_detail_input_multiple_order_qty_','item_price_frame_sales_price_grid_detail_input_vat_included_in_price_',
		'item_price_frame_sales_price_grid_detail_input_specific_vat_rate_','item_price_frame_sales_price_grid_detail_input_external_reference_'];

		for (let nbre = 0 ; nbre < id_list.length;nbre ++)
		{
			let compled_el = document.getElementById(id_list[nbre]+ i.toString());
			if (compled_el)
			{
				compled_el.onclick = fct_edit_value;
				compled_el.onkeydown = fct_keydown;
				compled_el.onkeyup = fct_keyup;
			}
		}
	}


	static sales_price_row_click(row_id)
	{
		let s = row_id;
		let base = document.getElementById('item_price_frame_sales_price_grid_detail');

		s = s.substring(45, s.length);


		for (var i = 0; i < base.rows.length; i++)
		{
			let checked_id = document.getElementById('item_price_frame_sales_price_grid_detail_checked_id_'+ i.toString());
			if (checked_id){
				checked_id.checked= false;
			}

			let enabled = 0

			if (document.getElementById('item_price_frame_sales_price_grid_detail_enabled_' + i.toString()).checked) {
				enabled = 1
			}


			let start_dt = '';
			let input_start_date = document.getElementById('item_price_frame_sales_price_grid_detail_input_start_date_'+ i.toString());
			let label_start_date = document.getElementById('item_price_frame_sales_price_grid_detail_label_start_date_'+ i.toString());

			if (input_start_date) {

				if (input_start_date.value != '') {
					start_dt = input_start_date.value;
				}

			}else if (label_start_date) {
				if (label_start_date.innerText.trim() != '') {
					start_dt = label_start_date.innerText.trim();
				}
			}


			let end_dt = '';
			let input_end_date = document.getElementById('item_price_frame_sales_price_grid_detail_input_end_date_'+ i.toString());
			let label_end_date = document.getElementById('item_price_frame_sales_price_grid_detail_label_end_date_'+ i.toString());

			if (input_end_date) {

				if (input_end_date.value != '') {
					end_dt = input_end_date.value;
				}

			}else if (label_end_date) {
				if (label_end_date.innerText.trim() != '') {
					end_dt = label_end_date.innerText.trim();
				}
			}



			for (var j = 0; j < base.rows[i].cells.length; j++)
			{
				if ((enabled == 0) || ((start_dt != '') && (start_dt > silicon_ioi.doctype.ioiItemPriceFrame.today)) || ((end_dt != '') && (end_dt < silicon_ioi.doctype.ioiItemPriceFrame.today))) {
					base.rows[i].cells[j].style.backgroundColor = '#CACBCC';
					base.rows[i].cells[j].style.color = '#000000';
				}else{

					let document_type = document.getElementById('item_price_frame_sales_price_grid_detail_document_type_' + i.toString());

					if (document.getElementById('item_price_frame_sales_price_grid_detail_has_price_qty_' + i.toString()).value == 'Y') {

						if ((base.rows[i].cells[j].id == 'item_price_frame_sales_price_grid_detail_cell_unit_price_' + i.toString()) ||
							(base.rows[i].cells[j].id == 'item_price_frame_sales_price_grid_detail_cell_base_discount_' + i.toString()) ||
							(base.rows[i].cells[j].id == 'item_price_frame_sales_price_grid_detail_cell_extra_discount_' + i.toString())) {

							if (base.rows[i].cells[j].id == 'item_price_frame_sales_price_grid_detail_cell_unit_price_' + i.toString()) {

								if (document_type.value != 'CONTRACT') {

									base.rows[i].cells[j].style.backgroundColor = '#f7fca9';
									base.rows[i].cells[j].style.color = '#000000';

									let input_unit_price = document.getElementById('item_price_frame_sales_price_grid_detail_input_unit_price'+i.toString());
									let label_unit_price = document.getElementById('item_price_frame_sales_price_grid_detail_label_unit_price'+i.toString());

									if (input_unit_price) {

										input_unit_price.style.backgroundColor = '#f7fca9';
										input_unit_price.style.color = '#000000';

									}else if (label_unit_price) {

										label_unit_price.style.color = '#000000';
									}
								}
							}else{
								base.rows[i].cells[j].style.backgroundColor = '#f7fca9';
								base.rows[i].cells[j].style.color = '#000000';
							}
							let input_base_discount = document.getElementById('item_price_frame_sales_price_grid_detail_input_base_discount_'+ i.toString());
							let label_base_discount = document.getElementById('item_price_frame_sales_price_grid_detail_label_base_discount_'+ i.toString());

							if (input_base_discount) {

								input_base_discount.style.backgroundColor = '#f7fca9';
								input_base_discount.style.color = '#000000';

							}else if (label_base_discount) {

								label_base_discount.style.color = '#000000';
							}
							let input_extra_discount = document.getElementById('item_price_frame_sales_price_grid_detail_input_extra_discount_'+ i.toString());
							let label_extra_discount = document.getElementById('item_price_frame_sales_price_grid_detail_label_extra_discount_'+ i.toString());

							if (input_extra_discount) {

								input_extra_discount.style.backgroundColor = '#f7fca9';
								input_extra_discount.style.color = '#000000';

							}else if (label_extra_discount) {

								label_extra_discount.style.color = '#000000';
							}


						}else{

							if (silicon_ioi.doctype.ioiItemPriceFrame.is_dark_mode == 0) {

								if (document_type.value == 'CONTRACT') {
									base.rows[i].cells[j].style.backgroundColor = '#79FF79';
								}else{
									base.rows[i].cells[j].style.backgroundColor = '#FFFFFF';
								}
							}else{
								if (document_type.value == 'CONTRACT') {
									base.rows[i].cells[j].style.backgroundColor = '#79FF79';
								}else{
									base.rows[i].cells[j].style.backgroundColor = '#1C2126';
								}
							}
						}


					}else{

						if (silicon_ioi.doctype.ioiItemPriceFrame.is_dark_mode == 0) {
							if (document_type.value == 'CONTRACT') {
								base.rows[i].cells[j].style.backgroundColor = '#79FF79';
							}else{
								base.rows[i].cells[j].style.backgroundColor = '#FFFFFF';
							}
						}else{
							if (document_type.value == 'CONTRACT') {
								base.rows[i].cells[j].style.backgroundColor = '#79FF79';
							}else{
								base.rows[i].cells[j].style.backgroundColor = '#1C2126';
							}
						}
					}
				}
			}
		}

		document.getElementById('item_price_frame_sales_price_selected_name').value = document.getElementById('item_price_frame_sales_price_grid_detail_name_' + s).value;
		document.getElementById('item_price_frame_sales_price_selected_id').value = document.getElementById('item_price_frame_sales_price_grid_detail_id_' + s).value;
		let row_el = document.getElementById('item_price_frame_sales_price_grid_detail_row_'+s);

		for (var i = 0; i < row_el.cells.length; i++)
		{
			if (silicon_ioi.doctype.ioiItemPriceFrame.is_dark_mode == 0) {
				row_el.cells[i].style.backgroundColor = '#b1fcd9';
			}else{
				row_el.cells[i].style.backgroundColor = 'green';
			}
		}

		document.getElementById('item_price_frame_sales_price_grid_detail_checked_id_' + s).checked = true;


	}


	static sales_price_edit(id)
	{
		let s = id;

		while (s.indexOf('_') != -1) {
			s = s.substring(s.indexOf('_')+1, s.length);
		}

		s = s.trim();
		let record_modified = document.getElementById('item_price_frame_sales_price_grid_detail_record_modified_' + s);

		if (record_modified) {
			record_modified.value = 'Y';
		}

	}



	static sales_price_new()
	{
		document.getElementById('item_sales_price_frame_button_new').disabled = true;
		document.getElementById('item_sales_price_frame_button_duplicate').disabled = true;
		document.getElementById('item_sales_price_frame_button_delete').disabled = true;
		let base = document.getElementById('item_price_frame_sales_price_grid_detail');

		let i = 0;

		if (base) {

			if (document.getElementById('item_price_frame_sales_price_grid_detail_checked_id_0')) {

				let max_id = -1;

				for (var k = 0; k < base.rows.length; k++)
				{
					let id_el = document.getElementById('item_price_frame_sales_price_grid_detail_id_' + k.toString());
					if (parseInt(id_el.value) > max_id) {
						max_id = parseInt(id_el.value)
					}
				}

				i = max_id + 1;
			}
		}


		let cell_bgcolor = '';

		if (silicon_ioi.doctype.ioiItemPriceFrame.is_dark_mode == 0) {
			cell_bgcolor = '#FFFFFF';
		}else{
			cell_bgcolor = '#1C2126';
		}

		let html = '';

		html += `<input id="item_price_frame_sales_price_grid_detail_name_${i.toString()}" type="hidden" value="">
		<input id="item_price_frame_sales_price_grid_detail_id_${i.toString()}" type="hidden" value="${i.toString()}">
		<input id="item_price_frame_sales_price_grid_detail_has_price_qty_${i.toString()}" type="hidden" value="N">

		<input id="item_price_frame_sales_price_grid_detail_record_modified_${i.toString()}" type="hidden" value="N">
		<input id="item_price_frame_sales_price_grid_detail_new_record_${i.toString()}" type="hidden" value="Y">
		<input id="item_price_frame_sales_price_grid_detail_name_to_duplicate_${i.toString()}" type="hidden" value="">

		<input id="item_price_frame_sales_price_grid_detail_select_type_${i.toString()}" type="hidden" value="">
		<input id="item_price_frame_sales_price_grid_detail_type_id_${i.toString()}" type="hidden" value="">


		<tr id="item_price_frame_sales_price_grid_detail_row_${i.toString()}"  style="height:30px">

		<td width=30px style="vertical-align: middle;" align="center">

		<input type="checkbox" id="item_price_frame_sales_price_grid_detail_checked_id_${i.toString()}" style="postion:absolute; top: 2px; left: 2px;"
		       onkeydown="   if (event.keyCode == 13) { return false; } "
		       onkeyup="   if (event.keyCode == 13) { return false; } ">
		</td>`;



		// Type
		html += `<td width=100px style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<select id="item_price_frame_sales_price_grid_detail_input_type_id_${i.toString()}" style="width:96px;height:30px;">
					<option value="ioi Customer" selected>${__("Customer")}</option>
					<option value="ioi Sales Catalog">${__("Catalog")}</option>
		</select>
		<label id="item_price_frame_sales_price_grid_detail_label_type_id_${i.toString()}" style="position:relative; top:4px;display:none;"></label>
		</td>`;


		// Catalog / Customer
		html += `<td width=200px style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_sales_price_grid_detail_input_catalog_customer_id_${i.toString()}" type="text" style="width:160px;height:30px;text-transform: uppercase;" value="">
		<button id="item_price_frame_sales_price_grid_detail_input_catalog_customer_button_${i.toString()}" style="position:relative; left:6px; top:0px; height: 30px; width: 30px;" tabindex="-1" onclick="">...</button>
		<label id="item_price_frame_sales_price_grid_detail_label_catalog_customer_id_${i.toString()}" style="position:relative; top:4px;display:none;"></label>
		</td>`;

		//Sales condition
		html += `<td width=170px style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_sales_price_grid_detail_input_sales_condition_id_${i.toString()}" type="text" style="width:130px;height:30px;text-transform: uppercase;" value="' + silicon_ioi.doctype.ioiItemPriceFrame.sales_condition_id + '">
		<button id="item_price_frame_sales_price_grid_detail_input_sales_condition_button_${i.toString()}" style="position:relative; left:6px; top:0px; height: 30px; width: 30px;" tabindex="-1" onclick="">...</button>
		<label id="item_price_frame_sales_price_grid_detail_label_sales_condition_id_${i.toString()}" style="position:relative; top:4px;display:none;"></label>
		</td>`;

		// Unit
		html += `<td width=120px style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_sales_price_grid_detail_input_unit_id_${i.toString()}" type="text" style="width:80px;height:30px;text-transform: uppercase;" value="">
		<button id="item_price_frame_sales_price_grid_detail_input_unit_button_${i.toString()}" style="position:relative; left:6px; top:0px; height: 30px; width: 30px;" tabindex="-1" onclick="">...</button>
		<label id="item_price_frame_sales_price_grid_detail_label_unit_id_${i.toString()}" style="position:relative; top:4px;display:none;"></label>
		</td>`;

		// Currency
		html += `<td width=100px style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_sales_price_grid_detail_input_currency_id_${i.toString()}" type="text" style="width:60px;height:30px;text-transform: uppercase;" value="">
		<button id="item_price_frame_sales_price_grid_detail_input_currency_button_${i.toString()}" style="position:relative; left:6px; top:0px; height: 30px; width: 30px;" tabindex="-1" onclick="">...</button>
		<label id="item_price_frame_sales_price_grid_detail_label_currency_id_${i.toString()}" style="position:relative; top:4px;display:none;"></label>
		</td>`;

		// Enabled
		html += `<td width=80px align="center" style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input type="checkbox" id="item_price_frame_sales_price_grid_detail_enabled_${i.toString()}" style="postion:absolute; top: 2px; left: 2px;" checked>
		</td>`;

		// Price published
		html += `<td width=80px align="center" style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input type="checkbox" id="item_price_frame_sales_price_grid_detail_price_published_${i.toString()}" style="postion:absolute; top: 2px; left: 2px;">
		</td>`;

		// Web price published
		html += `<td width=120px align="center" style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input type="checkbox" id="item_price_frame_sales_price_grid_detail_web_price_published_${i.toString()}" style="postion:absolute; top: 2px; left: 2px;">
		</td>`;

		// Document type
		html += `<input id="item_price_frame_sales_price_grid_detail_document_type_${i.toString()}" type="hidden" value="">`;


		// Unit price
		html += `<td width=120px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_sales_price_grid_detail_input_unit_price_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="">
		</td>`;

		// Fixed price
		html += `<td width=120px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_sales_price_grid_detail_input_fixed_price_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="">
		</td>`;

		// Base discount
		html += `<td width=120px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_sales_price_grid_detail_input_base_discount_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="">
		</td>`;


		// Extra discount
		html += `<td width=120px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_sales_price_grid_detail_input_extra_discount_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="">
		</td>`;

		// Discount in value
		html += `<td width=150px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_sales_price_grid_detail_input_discount_in_value_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="">
		</td>`;

		// Start date
		let dt = silicon_ioi.doctype.ioiItemPriceFrame.today;


		html += `<td width=150px style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_sales_price_grid_detail_input_start_date_${i.toString()}" type="date" style="width:146px;height:30px;" value="' + dt + '">
		</td>`;

		// End date
		html += `<td width=150px style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_sales_price_grid_detail_input_end_date_${i.toString()}" type="date" style="width:146px;height:30px;" value="">
		</td>`;


		// Min order qty
		html += `<td width=150px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_sales_price_grid_detail_input_min_order_qty_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="">
		</td>`;

		// Multiple order qty
		html += `<td width=150px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_sales_price_grid_detail_input_multiple_order_qty_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="">
		</td>`;

		// VAT incl.
		html += `<td width=80px align="center" style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input type="checkbox" id="item_price_frame_sales_price_grid_detail_input_vat_included_in_price_${i.toString()}" style="postion:absolute; top: 2px; left: 2px;">
		</td>`;

		// VAT rate
		html += `<td width=100px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_sales_price_grid_detail_input_specific_vat_rate_${i.toString()}" type="number" step="any" style="width:96px;height:30px;text-align:right;" value="">
		</td>`



		// Contract link
		html += `<td width=300px style="background-color:${cell_bgcolor}; vertical-align: middle;">&nbsp;
		<label id="item_price_frame_sales_price_grid_detail_label_document_master_link_${i.toString()}" style="position:relative; top:4px"></label>
		</td>`;

		// External reference
		html += `<td width=200px style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_sales_price_grid_detail_input_external_reference_${i.toString()}" type="text" style="width:196px;height:30px;" value="">
		</td>

		</tr>`;

		if (i == 0) {

			if (base) {
				base.remove();
			}

			html = `<table id="item_price_frame_sales_price_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=${silicon_ioi.doctype.ioiItemPriceFrame.sales_price_grid_width }px>${html}
			</table>`;

			document.getElementById('item_price_frame_sales_price_grid_header').insertAdjacentHTML('afterend', html);
		}else{
			let table = base;
			table.insertAdjacentHTML('beforeend', html);
		}



		document.getElementById('item_price_frame_sales_price_nb_rows').value = parseInt(document.getElementById('item_price_frame_sales_price_nb_rows').value) + 1;

		silicon_ioi.doctype.ioiItemPriceFrame.sleep_static(200).then(() => {

			let type_id = document.getElementById('item_price_frame_sales_price_grid_detail_input_type_id_' + i.toString());
			let custumer_id = document.getElementById('item_price_frame_sales_price_grid_detail_input_catalog_customer_id_' + i.toString());
			let condition_id = document.getElementById('item_price_frame_sales_price_grid_detail_input_sales_condition_id_' + i.toString());
			let unit_id = document.getElementById('item_price_frame_sales_price_grid_detail_input_unit_id_' + i.toString());
			let currency_id = document.getElementById('item_price_frame_sales_price_grid_detail_input_currency_id_' + i.toString());



			type_id.value = 'ioi Sales Catalog';
			custumer_id.value = silicon_ioi.doctype.ioiItemPriceFrame.sales_catalog_id;
			condition_id.value = silicon_ioi.doctype.ioiItemPriceFrame.sales_condition_id;
			unit_id.value = silicon_ioi.doctype.ioiItemPriceFrame.unit_id;
			currency_id.value = silicon_ioi.doctype.ioiItemPriceFrame.system_currency;



			let fct_row_click = function () { silicon_ioi.doctype.ioiItemPriceFrame.sales_price_row_click(this.id); };
			let fct_edit_value = function() { silicon_ioi.doctype.ioiItemPriceFrame.sales_price_edit(this.id); };

			let fct_keydown = function(event) {

				if (event.keyCode == 13)
				{	return false;
				}

			};

			let fct_keyup = function(event) {

				if (event.keyCode == 13)
				{	return false;
				}
			};


			base.rows[i].onclick = fct_row_click;

			// Type
			let fct_type_change = function () {

				let s = this.id;

				while (s.indexOf('_') != -1) {
					s = s.substring(s.indexOf('_')+1, s.length);
				}

				custumer_id.value = '';
				silicon_ioi.doctype.ioiItemPriceFrame.sales_price_edit(customer_id.id);
			}

			type_id.onchange = fct_type_change;
			type_id.onkeydown = fct_keydown;
			type_id.onkeyup = fct_keyup;



			// Customer/Catalog
			let fct_catalog_customer_button = function() {

				let s = this.id;

				while (s.indexOf('_') != -1) {
					s = s.substring(s.indexOf('_')+1, s.length);
				}

				if (document.getElementById('item_price_frame_sales_price_grid_detail_input_type_id_'+s).value.toUpperCase() == 'IOI CUSTOMER') {

					let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email, alternate_id';
					let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400, 250';
					let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email, Alternate id';
					let where = 'ioistatus = 2';
					let order_by = 'name asc';

					let fct_callback = function (return_value) {

						let customer_id = document.getElementById('item_price_frame_sales_price_grid_detail_input_catalog_customer_id_' + s);

						customer_id.value = return_value;
						customer_id.focus();
						silicon_ioi.doctype.ioiItemPriceFrame.sales_price_edit(customer_id.id);

					}

					silicon_ioi.ioiCommon.select_customer(fields, fields_len, fields_desc, where, order_by, fct_callback);
				}else{

					let title = __("Select a sales catalog");
					let form_width_pixel = '710px';
					let table = "ioi Sales Catalog";
					let fields = 'name, description';
					let fields_len = '250, 380';
					let fields_desc = 'Identification, Description';
					let where = 'enabled = 1';
					let order_by = 'name asc';

					let fct_callback = function (return_value) {

						let customer_id = document.getElementById('item_price_frame_sales_price_grid_detail_input_catalog_customer_id_' + s);

						customer_id.value = return_value;
						customer_id.focus();
						silicon_ioi.doctype.ioiItemPriceFrame.sales_price_edit(customer_id.id);
					}

					silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);
				}
			}


			document.getElementById('item_price_frame_sales_price_grid_detail_input_catalog_customer_button_' + i.toString()).onclick = fct_catalog_customer_button;
			custumer_id.onkeydown = fct_keydown;
			custumer_id.onkeyup = fct_keyup;


			// Sales Condition
			let fct_sales_condition_button = function() {

				let s = this.id;

				while (s.indexOf('_') != -1) {
					s = s.substring(s.indexOf('_')+1, s.length);
				}

				let title = __("Select a sales condition");
				let form_width_pixel = '710px';
				let table = "ioi Sales Condition";
				let fields = 'name, description';
				let fields_len = '250, 380';
				let fields_desc = 'Identification, Description';
				let where = "enabled = 1 and name <> '" + silicon_ioi.doctype.ioiItemPriceFrame.manual_sales_condition_id.replaceAll("'", "''") + "' ";
				let order_by = 'name asc';

				let fct_callback = function (return_value) {

					document.getElementById('item_price_frame_sales_price_grid_detail_input_sales_condition_id_' + s).value = return_value;
					document.getElementById('item_price_frame_sales_price_grid_detail_input_sales_condition_id_' + s).focus();
					silicon_ioi.doctype.ioiItemPriceFrame.sales_price_edit(document.getElementById('item_price_frame_sales_price_grid_detail_input_sales_condition_id_' + s).id);
				}

				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);
			}

			document.getElementById('item_price_frame_sales_price_grid_detail_input_sales_condition_button_' + i.toString()).onclick = fct_sales_condition_button;
			condition_id.onkeydown = fct_keydown;
			condition_id.onkeyup = fct_keyup;

			// Unit
			let fct_unit_button = function() {

				let s = this.id;

				while (s.indexOf('_') != -1) {
					s = s.substring(s.indexOf('_')+1, s.length);
				}

				let title = __("Select an Unit");
				let form_width_pixel = '710px';
				let table = "ioi Item Unit";
				let fields = 'name, description';
				let fields_len = '250, 380';
				let fields_desc = 'Identification, Description';
				let where = 'enabled = 1';
				let order_by = 'name asc';

				let fct_callback = function (return_value) {


					document.getElementById('item_price_frame_sales_price_grid_detail_input_unit_id_' + s).value = return_value;
					document.getElementById('item_price_frame_sales_price_grid_detail_input_unit_id_' + s).focus();
					silicon_ioi.doctype.ioiItemPriceFrame.sales_price_edit(document.getElementById('item_price_frame_sales_price_grid_detail_input_unit_id_' + s).id);
				}

				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

			}

			document.getElementById('item_price_frame_sales_price_grid_detail_input_unit_button_' + i.toString()).onclick = fct_unit_button;
			unit_id.onkeydown = fct_keydown;
			unit_id.onkeyup = fct_keyup;


			// Currency
			let fct_currency_button = function() {

				let s = this.id;

				while (s.indexOf('_') != -1) {
					s = s.substring(s.indexOf('_')+1, s.length);
				}

				let title = __("Select a currency");
				let form_width_pixel = '710px';
				let table = "ioi Currency";
				let fields = 'name, description';
				let fields_len = '250, 380';
				let fields_desc = 'Identification, Description';
				let where = '';
				let order_by = 'name asc';

				let fct_callback = function (return_value) {

					let input_currency_id = document.getElementById('item_price_frame_sales_price_grid_detail_input_currency_id_' + s);
					input_currency_id.value = return_value;
					input_currency_id.focus();
					silicon_ioi.doctype.ioiItemPriceFrame.sales_price_edit(input_currency_id.id);
				}

				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);
			}

			document.getElementById('item_price_frame_sales_price_grid_detail_input_currency_button_' + i.toString()).onclick = fct_currency_button;
			currency_id.onkeydown = fct_keydown;
			currency_id.onkeyup = fct_keyup;



			silicon_ioi.doctype.ioiItemPriceFrame.assign_row_events(i, fct_edit_value, fct_keydown, fct_keyup);

			for (var j = 0; j < base.rows[0].cells.length; j++) {

				if (silicon_ioi.doctype.ioiItemPriceFrame.is_dark_mode == 0) {
					base.rows[i].cells[j].style.backgroundColor = '#b1fcd9';
				}else{
					base.rows[i].cells[j].style.backgroundColor = 'green';
				}
			}

			silicon_ioi.doctype.ioiItemPriceFrame.sales_price_row_click(document.getElementById('item_price_frame_sales_price_grid_detail').rows[i].id);
			document.getElementById('item_sales_price_frame_content').scrollTop = document.getElementById('item_sales_price_frame_content').scrollHeight;
		});
	}

	static sales_price_delete()
	{
		if (!silicon_ioi.doctype.ioiItemPriceFrame.sales_price_grid_line_selected()) {
			frappe.msgprint({title: __("Message"), message: __('No sales price line selected'), indicator: "red"});
			return 0;
		}

		for (var i = 0; i < parseInt(document.getElementById('item_price_frame_sales_price_nb_rows').value); i++) {
			let checked_id = document.getElementById('item_price_frame_sales_price_grid_detail_checked_id_' + i.toString());

			if (checked_id)	{

				if (checked_id.checked) {

					if (document.getElementById('item_price_frame_sales_price_grid_detail_document_type_' + i.toString()).value == 'CONTRACT') {

						frappe.msgprint({title: __("Message"), message: __('This sales price linked to a contract can not be deleted'), indicator: "red"});
						return 0;

					}
				}
			}
		}

		let top = 10;

		let s = '<font color="blue">' + __('Are you sure to delete this sales price ?') + '</font>';
		let msg = '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';

		top += 20;
		msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';


		frappe.confirm(	msg,
						() => 	{
									document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;
									silicon_ioi.doctype.ioiItemPriceFrame.sales_price_do_delete();
								},
						() => 	{
								}
		);
	}

	static sales_price_do_delete()
	{
		let name_el = document.getElementById('item_price_frame_sales_price_selected_name');
		if (name_el.value != '') {

			let method = silicon_ioi.doctype.ioiItemPriceFrame.path_sales_price + '.ioi_sales_prices_delete';

			frappe.call({  	method: method,
							args: { "name": name_el.value
							},
							async: false,
							callback:function(r)	{
								silicon_ioi.doctype.ioiItemPriceFrame.sales_price_get_result();
							}
			});
		}

	}

	static sales_price_save()
	{
		if (!silicon_ioi.doctype.ioiItemPriceFrame.sales_price_grid_line_selected()) {
			frappe.msgprint({title: __("Message"), message: __('No sales price line selected'), indicator: "red"});
			return 0;
		}

		if (document.getElementById('item_price_frame_sales_price_grid_detail')) {

			let selected_names = '';
			let name_to_duplicate = '';

			let selected_types_id = '';
			let selected_catalogs_customers = '';
			let selected_sales_conditions = '';
			let selected_units = '';
			let selected_currencies = '';

			let selected_enabled = '';
			let selected_prices_published = '';
			let selected_web_prices_published = '';

			let selected_unit_prices = '';
			let selected_fixed_prices = '';
			let selected_base_discounts = '';
			let selected_extra_discounts = '';
			let selected_discounts_in_value = '';
			let selected_start_dates = '';
			let selected_end_dates = '';
			let selected_min_order_qties = '';
			let selected_multiple_order_qties = '';

			let selected_vat_included_in_price = '';
			let selected_specific_vat_rate = '';

			let selected_external_references = '';

			for (var i = 0; i < parseInt(document.getElementById('item_price_frame_sales_price_nb_rows').value); i++) {

				let new_record = document.getElementById('item_price_frame_sales_price_grid_detail_new_record_' + i.toString());
				let record_modified = document.getElementById('item_price_frame_sales_price_grid_detail_record_modified_' + i.toString());
				if (new_record) {

					let save_value = false;


					if (new_record.value == 'N') {

						// Update

						if (record_modified) {

							if (record_modified.value == 'Y') {

								selected_names += document.getElementById('item_price_frame_sales_price_grid_detail_name_' + i.toString()).value + '#sbsepa#';

								save_value = true;
							}
						}
					}else{

						// Insert (only one insert at a time)

						let input_type_id = document.getElementById('item_price_frame_sales_price_grid_detail_input_type_id_' + i.toString());
						let catalog_customer_id = document.getElementById('item_price_frame_sales_price_grid_detail_input_catalog_customer_id_' + i.toString());
						let sales_condition_id = document.getElementById('item_price_frame_sales_price_grid_detail_input_sales_condition_id_' + i.toString());
						let input_unit_id = document.getElementById('item_price_frame_sales_price_grid_detail_input_unit_id_' + i.toString());
						let input_currency_id = document.getElementById('item_price_frame_sales_price_grid_detail_input_currency_id_' + i.toString());

						if (input_type_id.value.trim() == '') {
							frappe.msgprint({title: __("Message"), message: __('Line') + ' ' + (i+1).toString() + ' : ' + __('Type is mandatory'), indicator: "red"});
							return 0;
						}

						if (catalog_customer_id.value.trim() == '') {

							if (input_type_id.value.toUpperCase() == 'IOI CUSTOMER') {
								frappe.msgprint({title: __("Message"), message: __('Line') + ' ' + (i+1).toString() + ' : ' + __('Customer is mandatory'), indicator: "red"});
							}else{
								frappe.msgprint({title: __("Message"), message: __('Line') + ' ' + (i+1).toString() + ' : ' + __('Sales catalog is mandatory'), indicator: "red"});
							}
							return 0;
						}

						if (sales_condition_id.value.trim() == '') {
							frappe.msgprint({title: __("Message"), message: __('Line') + ' ' + (i+1).toString() + ' : ' + __('Sales condition is mandatory'), indicator: "red"});
							return 0;
						}

						if (sales_condition_id.value.toUpperCase() == silicon_ioi.doctype.ioiItemPriceFrame.manual_sales_condition_id) {
							frappe.msgprint({title: __("Message"), message: __('Line') + ' ' + (i+1).toString() + ' : ' + __('Sales condition can not be') + ' ' + silicon_ioi.doctype.ioiItemPriceFrame.manual_sales_condition_id, indicator: "red"});
							return 0;
						}


						if (input_unit_id.value.trim() == '') {
							frappe.msgprint({title: __("Message"), message: __('Line') + ' ' + (i+1).toString() + ' : ' + __('Unit is mandatory'), indicator: "red"});
							return 0;
						}

						if (input_currency_id.value.trim() == '') {
							frappe.msgprint({title: __("Message"), message: __('Line') + ' ' + (i+1).toString() + ' : ' + __('Currency is mandatory'), indicator: "red"});
							return 0;
						}

						selected_types_id += input_type_id.value.toUpperCase() + '#sbsepa#';;
						selected_catalogs_customers += catalog_customer_id.value.toUpperCase() + '#sbsepa#';;
						selected_sales_conditions += sales_condition_id.value.toUpperCase() + '#sbsepa#';;
						selected_units += input_unit_id.value.toUpperCase() + '#sbsepa#';;
						selected_currencies += input_currency_id.value.toUpperCase() + '#sbsepa#';;

						name_to_duplicate = document.getElementById('item_price_frame_sales_price_grid_detail_name_to_duplicate_' + i.toString()).value;

						save_value = true;

					}

					if (save_value) {

						if (document.getElementById('item_price_frame_sales_price_grid_detail_enabled_' + i.toString()).checked) {
							selected_enabled += '1' + '#sbsepa#';
						}else{
							selected_enabled += '0' + '#sbsepa#';
						}

						if (document.getElementById('item_price_frame_sales_price_grid_detail_price_published_' + i.toString()).checked) {
							selected_prices_published += '1' + '#sbsepa#';
						}else{
							selected_prices_published += '0' + '#sbsepa#';
						}

						if (document.getElementById('item_price_frame_sales_price_grid_detail_web_price_published_' + i.toString()).checked) {
							selected_web_prices_published += '1' + '#sbsepa#';
						}else{
							selected_web_prices_published += '0' + '#sbsepa#';
						}


						selected_unit_prices += document.getElementById('item_price_frame_sales_price_grid_detail_input_unit_price_' + i.toString()).value + '#sbsepa#';
						selected_fixed_prices += document.getElementById('item_price_frame_sales_price_grid_detail_input_fixed_price_' + i.toString()).value + '#sbsepa#';
						selected_base_discounts += document.getElementById('item_price_frame_sales_price_grid_detail_input_base_discount_' + i.toString()).value + '#sbsepa#';
						selected_extra_discounts += document.getElementById('item_price_frame_sales_price_grid_detail_input_extra_discount_' + i.toString()).value + '#sbsepa#';
						selected_discounts_in_value += document.getElementById('item_price_frame_sales_price_grid_detail_input_discount_in_value_' + i.toString()).value + '#sbsepa#';
						selected_start_dates += document.getElementById('item_price_frame_sales_price_grid_detail_input_start_date_' + i.toString()).value + '#sbsepa#';
						selected_end_dates += document.getElementById('item_price_frame_sales_price_grid_detail_input_end_date_' + i.toString()).value + '#sbsepa#';
						selected_min_order_qties += document.getElementById('item_price_frame_sales_price_grid_detail_input_min_order_qty_' + i.toString()).value + '#sbsepa#';
						selected_multiple_order_qties += document.getElementById('item_price_frame_sales_price_grid_detail_input_multiple_order_qty_' + i.toString()).value + '#sbsepa#';


						if (document.getElementById('item_price_frame_sales_price_grid_detail_input_vat_included_in_price_' + i.toString()).checked) {
							selected_vat_included_in_price += '1' + '#sbsepa#';
						}else{
							selected_vat_included_in_price += '0' + '#sbsepa#';
						}

						selected_specific_vat_rate += document.getElementById('item_price_frame_sales_price_grid_detail_input_specific_vat_rate_' + i.toString()).value + '#sbsepa#';
						selected_external_references += document.getElementById('item_price_frame_sales_price_grid_detail_input_external_reference_' + i.toString()).value + '#sbsepa#';
					}
				}

			}

			if ((selected_names.trim() != '') || (selected_types_id.trim() != '')) {

				if (selected_types_id.trim() != '') {

					let method = silicon_ioi.doctype.ioiItemPriceFrame.path_sales_price + '.ioi_sales_prices_check_pk_values';

					frappe.call({  	method: method,
									args: { "item_id": silicon_ioi.doctype.ioiItemPriceFrame.item_id,
											"selected_types_id": selected_types_id,
											"selected_catalogs_customers": selected_catalogs_customers,
											"selected_sales_conditions": selected_sales_conditions,
											"selected_units": selected_units,
											"selected_currencies": selected_currencies
									},
									async: false,
									callback:function(r)	{

										if (r.message.error == 1) {
											frappe.msgprint({title: __("Message"), message: r.message.error_msg, indicator: "red"});
											return 0;

										}else{
											silicon_ioi.doctype.ioiItemPriceFrame.sales_price_do_save(	selected_names, selected_types_id, selected_catalogs_customers, selected_sales_conditions, selected_units, selected_currencies, selected_enabled,
																										selected_prices_published, selected_web_prices_published, selected_unit_prices, selected_fixed_prices, selected_base_discounts,
																										selected_extra_discounts, selected_discounts_in_value, selected_start_dates, selected_end_dates, selected_min_order_qties, selected_multiple_order_qties,
																										selected_vat_included_in_price, selected_specific_vat_rate,	selected_external_references, name_to_duplicate);
										}
									}
					});

				}else{
					silicon_ioi.doctype.ioiItemPriceFrame.sales_price_do_save(	selected_names, selected_types_id, selected_catalogs_customers, selected_sales_conditions, selected_units, selected_currencies, selected_enabled,
																				selected_prices_published, selected_web_prices_published, selected_unit_prices, selected_fixed_prices, selected_base_discounts,
																				selected_extra_discounts, selected_discounts_in_value, selected_start_dates, selected_end_dates, selected_min_order_qties, selected_multiple_order_qties,
																				selected_vat_included_in_price, selected_specific_vat_rate,	selected_external_references, name_to_duplicate);
				}


			}
		}
	}

	static sales_price_do_save(	selected_names, selected_types_id, selected_catalogs_customers, selected_sales_conditions, selected_units, selected_currencies, selected_enabled,
								selected_prices_published, selected_web_prices_published, selected_unit_prices, selected_fixed_prices, selected_base_discounts,
								selected_extra_discounts, selected_discounts_in_value, selected_start_dates, selected_end_dates, selected_min_order_qties, selected_multiple_order_qties,
								selected_vat_included_in_price, selected_specific_vat_rate,	selected_external_references, name_to_duplicate)
	{
		let method = silicon_ioi.doctype.ioiItemPriceFrame.path_sales_price + '.ioi_sales_prices_multiple_inserts_updates_for_an_item';

		frappe.call({  	method: method,
						args: { "item_id": silicon_ioi.doctype.ioiItemPriceFrame.item_id,
								"selected_names": selected_names,
								"selected_types_id": selected_types_id,
								"selected_catalogs_customers": selected_catalogs_customers,
								"selected_sales_conditions": selected_sales_conditions,
								"selected_units": selected_units,
								"selected_currencies": selected_currencies,
								"selected_enabled": selected_enabled,
								"selected_prices_published": selected_prices_published,
								"selected_web_prices_published":selected_web_prices_published,
								"selected_unit_prices": selected_unit_prices,
								"selected_fixed_prices": selected_fixed_prices,
								"selected_base_discounts": selected_base_discounts,
								"selected_extra_discounts": selected_extra_discounts,
								"selected_discounts_in_value": selected_discounts_in_value,
								"selected_start_dates": selected_start_dates,
								"selected_end_dates": selected_end_dates,
								"selected_min_order_qties": selected_min_order_qties,
								"selected_multiple_order_qties": selected_multiple_order_qties,
								"selected_vat_included_in_price": selected_vat_included_in_price,
								"selected_specific_vat_rate": selected_specific_vat_rate,
								"selected_external_references": selected_external_references,
								"name_to_duplicate" : name_to_duplicate


						},
						async: false,
						callback:function(r)	{

							document.getElementById('item_sales_price_frame_button_new').disabled = false;
							document.getElementById('item_sales_price_frame_button_duplicate').disabled = false;
							document.getElementById('item_sales_price_frame_button_delete').disabled = false;

							let current_name = ''
							let nb_row = document.getElementById('item_price_frame_sales_price_nb_rows');

							if (r.message.new_name != '') {
								current_name = r.message.new_name
							}else{

								for (var i = 0; i < parseInt(nb_row.value); i++) {
									let checked_id = document.getElementById('item_price_frame_sales_price_grid_detail_checked_id_' + i.toString());
									let detail_name = document.getElementById('item_price_frame_sales_price_grid_detail_name_' + i.toString());

									if (checked_id)	{

										if (checked_id.checked) {
											current_name = detail_name.value;
											break;
										}
									}
								}
							}

							silicon_ioi.doctype.ioiItemPriceFrame.sales_price_get_result();

							silicon_ioi.doctype.ioiItemPriceFrame.sleep_static(500).then(() => {

								let id = '';
								let detail = document.getElementById('item_price_frame_sales_price_grid_detail');

								for (var i = 0; i < parseInt(nb_row.value); i++) {
									let detail_name = document.getElementById('item_price_frame_sales_price_grid_detail_name_' + i.toString());

									if (detail_name.value == current_name) {
										id = detail.rows[i].id
										break;
									}
								}

								if (id != '') {
									silicon_ioi.doctype.ioiItemPriceFrame.sales_price_row_click(id);
								}

							});
						}
		});

	}

	static sales_price_cancel()
	{
		document.getElementById('item_sales_price_frame_button_new').disabled = false;
		document.getElementById('item_sales_price_frame_button_duplicate').disabled = false;
		document.getElementById('item_sales_price_frame_button_delete').disabled = false;
		silicon_ioi.doctype.ioiItemPriceFrame.sales_price_get_result();
	}

	static sales_price_duplicate()
	{
		if (!silicon_ioi.doctype.ioiItemPriceFrame.sales_price_grid_line_selected()) {
			frappe.msgprint({title: __("Message"), message: __('No sales price line selected'), indicator: "red"});
			return 0;
		}

		let top = 10;

		let s = '<font color="blue">' + __('Are you sure to duplicate this sales price ?') + '</font>';
		let msg = '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';

		top += 20;
		msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';


		frappe.confirm(	msg,
						() => 	{
									document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;
									silicon_ioi.doctype.ioiItemPriceFrame.sales_price_do_duplicate();
								},
						() => 	{
								}
		);


	}

	static sales_price_do_duplicate()
	{
		let idx = -1;
		let name = '';
		let id = '';
		let select_type = '';
		let type_id = '';
		let base = 'item_price_frame_sales_price_grid_detail' ;

		for (var i = 0; i < document.getElementById(base).rows.length; i++) {

			let checked_id = document.getElementById(base+'_checked_id_' + i.toString());
			if (checked_id)	{

				if (checked_id.checked) {

					idx = i;
					break;
				}
			}
		}

		if (idx != -1) {

			let input_unit_price_el = document.getElementById('item_price_frame_sales_price_grid_detail_input_unit_price_' + idx.toString());
			let input_fixed_price_el = document.getElementById('item_price_frame_sales_price_grid_detail_input_fixed_price_' + idx.toString());
			let input_base_discount_el = document.getElementById('item_price_frame_sales_price_grid_detail_input_base_discount_' + idx.toString());
			let input_extra_discount_el = document.getElementById('item_price_frame_sales_price_grid_detail_input_extra_discount_' + idx.toString());
			let input_discount_in_value_el = document.getElementById('item_price_frame_sales_price_grid_detail_input_discount_in_value_' + idx.toString());
			let input_start_date_el = document.getElementById('item_price_frame_sales_price_grid_detail_input_start_date_' + idx.toString());
			let input_end_date_el = document.getElementById('item_price_frame_sales_price_grid_detail_input_end_date_' + idx.toString());
			let input_min_order_qty_el = document.getElementById('item_price_frame_sales_price_grid_detail_input_min_order_qty_' + idx.toString());
			let input_multiple_order_qty_el = document.getElementById('item_price_frame_sales_price_grid_detail_input_multiple_order_qty_' + idx.toString());

			let label_unit_price_el = document.getElementById('item_price_frame_sales_price_grid_detail_label_unit_price_' + idx.toString());
			let label_fixed_price_el = document.getElementById('item_price_frame_sales_price_grid_detail_label_fixed_price_' + idx.toString());
			let label_base_discount_el = document.getElementById('item_price_frame_sales_price_grid_detail_label_base_discount_' + idx.toString());
			let label_extra_discount_el = document.getElementById('item_price_frame_sales_price_grid_detail_label_extra_discount_' + idx.toString());
			let label_discount_in_value_el = document.getElementById('item_price_frame_sales_price_grid_detail_label_discount_in_value_' + idx.toString());
			let label_start_date_el = document.getElementById('item_price_frame_sales_price_grid_detail_label_start_date_' + idx.toString());
			let label_end_date_el = document.getElementById('item_price_frame_sales_price_grid_detail_label_end_date_' + idx.toString());
			let label_min_order_qty_el = document.getElementById('item_price_frame_sales_price_grid_detail_label_min_order_qty_' + idx.toString());
			let label_multiple_order_qty_el = document.getElementById('item_price_frame_sales_price_grid_detail_label_multiple_order_qty_' + idx.toString());

			let input_vat_included_in_price_el = document.getElementById('item_price_frame_sales_price_grid_detail_input_vat_included_in_price_' + idx.toString());

			let input_specific_vat_rate_el = document.getElementById('item_price_frame_sales_price_grid_detail_input_specific_vat_rate_' + idx.toString());
			let label_specific_vat_rate_el = document.getElementById('item_price_frame_sales_price_grid_detail_label_specific_vat_rate_' + idx.toString());

			let input_external_reference = document.getElementById('item_price_frame_sales_price_grid_detail_input_external_reference_' + idx.toString());
			let label_external_reference = document.getElementById('item_price_frame_sales_price_grid_detail_label_external_reference_' + idx.toString());

			let name_to_duplicate = document.getElementById('item_price_frame_sales_price_grid_detail_name_' + idx.toString()).value;
			let select_type = document.getElementById('item_price_frame_sales_price_grid_detail_select_type_' + idx.toString()).value;
			let type_id = document.getElementById('item_price_frame_sales_price_grid_detail_type_id_' + idx.toString()).value;
			let type_id_cell = document.getElementById('item_price_frame_sales_price_grid_detail_label_type_id_' + idx.toString()).innerText;
			let cat_cust = document.getElementById('item_price_frame_sales_price_grid_detail_label_catalog_customer_id_' + idx.toString()).innerText;
			let sales_condition_id = document.getElementById('item_price_frame_sales_price_grid_detail_label_sales_condition_id_' + idx.toString()).innerText;
			let unit_id = document.getElementById('item_price_frame_sales_price_grid_detail_label_unit_id_' + idx.toString()).innerText;
			let currency_id = document.getElementById('item_price_frame_sales_price_grid_detail_label_currency_id_' + idx.toString()).innerText;

			type_id_cell = type_id_cell.trim();
			cat_cust = cat_cust.trim();
			sales_condition_id = sales_condition_id.trim();
			unit_id = unit_id.trim();
			currency_id = currency_id.trim();


			let enabled = 0;
			if (document.getElementById('item_price_frame_sales_price_grid_detail_enabled_' + idx.toString()).checked) {
				enabled = 1;
			}

			let price_published = 0;
			if (document.getElementById('item_price_frame_sales_price_grid_detail_price_published_' + idx.toString()).checked) {
				price_published = 1;
			}

			let web_price_published = 0;
			if (document.getElementById('item_price_frame_sales_price_grid_detail_web_price_published_' + idx.toString()).checked) {
				web_price_published = 1;
			}

			let unit_price = 0;

			if (input_unit_price_el) {
				unit_price = input_unit_price_el.value;
			}else{

				if (label_unit_price_el) {
					unit_price = label_unit_price_el.innerText;
				}
			}

			unit_price = unit_price.toString().replaceAll(' ', '');

			let fixed_price = 0;

			if (input_fixed_price_el) {
				fixed_price = input_fixed_price_el.value;
			}else{

				if (label_fixed_price_el) {
					fixed_price = label_fixed_price_el.innerText;
				}
			}

			fixed_price = fixed_price.toString().replaceAll(' ', '');


			let base_discount = 0;

			if (input_base_discount_el) {
				base_discount = input_base_discount_el.value;
			}else{

				if (label_base_discount_el) {
					base_discount = label_base_discount_el.innerText;
				}
			}

			base_discount = base_discount.toString().replaceAll(' ', '');
			base_discount = base_discount.toString().replaceAll('%', '');


			let extra_discount = 0;

			if (input_extra_discount_el) {
				extra_discount = input_extra_discount_el.value;
			}else{

				if (label_extra_discount_el) {
					extra_discount = label_extra_discount_el.innerText;
				}
			}

			extra_discount = extra_discount.toString().replaceAll(' ', '');
			extra_discount = extra_discount.toString().replaceAll('%', '');


			let discount_in_value = 0;

			if (input_discount_in_value_el) {
				discount_in_value = input_discount_in_value_el.value;
			}else{

				if (label_discount_in_value_el) {
					discount_in_value = label_discount_in_value_el.innerText;
				}
			}

			discount_in_value = discount_in_value.toString().replaceAll(' ', '');


			let start_date = '';

			if (input_start_date_el) {
				start_date = input_start_date_el.value;
			}else{

				if (label_start_date_el) {
					start_date = label_start_date_el.innerText;
				}
			}


			let end_date = '';

			if (input_end_date_el) {
				end_date = input_end_date_el.value;
			}else{

				if (label_end_date_el) {
					end_date = label_end_date_el.innerText;
				}
			}


			let min_order_qty = 0;

			if (input_min_order_qty_el) {
				min_order_qty = input_min_order_qty_el.value;
			}else{

				if (label_min_order_qty_el) {
					min_order_qty = label_min_order_qty_el.innerText;
				}
			}

			min_order_qty = min_order_qty.toString().replaceAll(' ', '');


			let multiple_order_qty = 0;

			if (input_multiple_order_qty_el) {
				multiple_order_qty = input_multiple_order_qty_el.value;
			}else{

				if (label_multiple_order_qty_el) {
					multiple_order_qty = label_multiple_order_qty_el.innerText;
				}
			}

			multiple_order_qty = multiple_order_qty.toString().replaceAll(' ', '');


			let vat_included_in_price = 0;

			if (input_vat_included_in_price_el.checked) {
				vat_included_in_price = 1;
			}

			let specific_vat_rate = 0;

			if (input_specific_vat_rate_el) {
				specific_vat_rate = input_specific_vat_rate_el.value;
			}else{

				if (label_specific_vat_rate_el) {
					specific_vat_rate = label_specific_vat_rate_el.innerText;
				}
			}

			specific_vat_rate = specific_vat_rate.toString().replaceAll(' ', '');
			specific_vat_rate = specific_vat_rate.toString().replaceAll('%', '');





			let external_reference = '';

			if (input_external_reference) {
				external_reference = input_external_reference.value;
			}else{

				if (label_external_reference) {
					external_reference = label_external_reference.innerText;
				}
			}

			silicon_ioi.doctype.ioiItemPriceFrame.sales_price_new();

			silicon_ioi.doctype.ioiItemPriceFrame.sleep_static(600).then(() => {

				idx = document.getElementById('item_price_frame_sales_price_grid_detail').rows.length - 1;


				document.getElementById('item_price_frame_sales_price_grid_detail_name_to_duplicate_' + idx.toString()).value = name_to_duplicate;
				document.getElementById('item_price_frame_sales_price_grid_detail_input_type_id_' + idx.toString()).value = type_id;
				document.getElementById('item_price_frame_sales_price_grid_detail_select_type_' + idx.toString()).value = select_type;
				document.getElementById('item_price_frame_sales_price_grid_detail_type_id_' + idx.toString()).value = type_id;
				document.getElementById('item_price_frame_sales_price_grid_detail_input_catalog_customer_id_' + idx.toString()).value = cat_cust;
				document.getElementById('item_price_frame_sales_price_grid_detail_input_sales_condition_id_' + idx.toString()).value = sales_condition_id;
				document.getElementById('item_price_frame_sales_price_grid_detail_input_unit_id_' + idx.toString()).value = unit_id;
				document.getElementById('item_price_frame_sales_price_grid_detail_input_currency_id_' + idx.toString()).value = currency_id;

				let detail_enabled_el = document.getElementById('item_price_frame_sales_price_grid_detail_enabled_' + idx.toString());
				let detail_price_published_el = document.getElementById('item_price_frame_sales_price_grid_detail_price_published_' + idx.toString());
				let web_price_published_el = document.getElementById('item_price_frame_sales_price_grid_detail_web_price_published_' + idx.toString());

				detail_enabled_el.checked = false;

				if (enabled == 1) {
					detail_enabled_el.checked = true;
				}

				detail_price_published_el.checked = false;

				if (price_published == 1) {
					detail_price_published_el.checked = true;
				}

				web_price_published_el.checked = false;

				if (web_price_published == 1) {
					web_price_published_el.checked = true;
				}

				input_unit_price_el.value = unit_price;
				input_fixed_price_el.value = fixed_price;
				input_base_discount_el.value = base_discount;
				input_extra_discount_el.value = extra_discount;
				input_discount_in_value_el.value = discount_in_value;
				input_start_date_el.value = start_date;
				input_end_date_el.value = end_date;
				input_min_order_qty_el.value = min_order_qty;
				input_multiple_order_qty_el.value = multiple_order_qty;


				input_vat_included_in_price_el.checked = false;

				if (vat_included_in_price == 1) {
					input_vat_included_in_price_el.checked = true;
				}

				input_specific_vat_rate_el.value = specific_vat_rate;

				input_external_reference.value = external_reference;



			});
		}
	}

	static sales_price_grid_line_selected()
	{
		let ret_val = true;
		let base = document.getElementById('item_price_frame_sales_price_grid_detail');

		if (!base) {
			ret_val = false;
		}else {
			if (base.rows.length == 0) {
				ret_val = false;
			}else {

				if (base.rows.length == 1) {

					if (!document.getElementById('item_price_frame_sales_price_grid_detail_checked_id_0')) {
						ret_val = false;
					}

				}else{

					let at_least_one_checked = false;

					for (var i = 0; i < parseInt(document.getElementById('item_price_frame_sales_price_nb_rows').value); i++) {
						let checked_id_el = document.getElementById('item_price_frame_sales_price_grid_detail_checked_id_' + i.toString());
						if (checked_id_el)	{

							if (checked_id_el.checked) {
								at_least_one_checked = true;
								break;
							}
						}
					}

					if (!at_least_one_checked) {
						ret_val = false;
					}
				}
			}
		}

		return ret_val;
	}


	// *************************************************************************************************************************************************
	// Purchases Price
	// *************************************************************************************************************************************************

    static load_item_purchases_price_frame(html_field, item_id, unit_id)
    {
		silicon_ioi.doctype.ioiItemPriceFrame.item_id = item_id;

		silicon_ioi.doctype.ioiItemPriceFrame.load_purchases_config();

		let id_list = ["content","action","button_new","button_delete","button_save","button_cancel","button_duplicate"];

		for (let i = 0 ; i < id_list.length; i++){
			let idelement=document.getElementById("item_purchases_price_frame_"+id_list[i]);
			if (idelement){
				idelement.remove();
			}
		}

		id_list = ["selected_name","selected_id","nb_rows"];

		for (let i = 0 ; i < id_list.length; i++){
			let idelement=document.getElementById("item_price_frame_purchases_price_"+id_list[i])
			if (idelement){
				idelement.remove();
			}
		}


		while (document.getElementById('item_price_frame_purchases_price_grid_header')) {
			document.getElementById('item_price_frame_purchases_price_grid_header').remove();
		}

		while (document.getElementById('item_price_frame_purchases_price_grid_detail')) {
			document.getElementById('item_price_frame_purchases_price_grid_detail').remove();
		}



		let html = '';

		if (silicon_ioi.doctype.ioiItemPriceFrame.can_modify_purchases) {

			html += `<div id="item_purchases_price_frame_action" style="overflow: auto; overflow-x: auto; height:50px;">`;

			// New
			html +=		`<div style="position: relative; top: 2px; left: 0px; width:150px;">
					<div style="position: absolute; top:0px; left: 2px; height: 35px">
						<button id="item_purchases_price_frame_button_new" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="">${__("New")}</button>
					</div>
				</div>`

			// Duplicate
			html += 	`<div style="position: relative; top: 2px; left: 160px; width:150px;">
					<div style="position: absolute; top:0px; left: 2px; height: 30px">
						<button id="item_purchases_price_frame_button_duplicate" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="">${__("Duplicate")}</button>
					</div>
				</div>`


			// Delete
			html += 	`<div style="position: relative; top: 2px; left: 320px; width:150px;">
					<div style="position: absolute; top:0px; left: 2px; height: 30px">
						<button id="item_purchases_price_frame_button_delete" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="">${__("Delete")}</button>
					</div>
				</div>`

			// Save
			html += 	`<div style="position: relative; top: 2px; left: 480px; width:150px;">
					<div style="position: absolute; top:0px; left: 2px; height: 30px">
						<button id="item_purchases_price_frame_button_save" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="">${__("Save")}</button>
					</div>
				</div>`

			// Cancel
			html += 	`<div style="position: relative; top: 2px; left: 640px; width:150px;">
					<div style="position: absolute; top:0px; left: 2px; height: 30px">
						<button id="item_purchases_price_frame_button_cancel" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="">${__("Cancel")}</button>
					</div>
				</div>


			</div>`;
		}


		html += `<div id="item_purchases_price_frame_content" style="overflow: auto; overflow-x: auto; height:400px;">
		${silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_grid_header()}
		</div>



		<input id="item_price_frame_purchases_price_selected_name" type="hidden" value="">
		<input id="item_price_frame_purchases_price_selected_id" type="hidden" value="">
		<input id="item_price_frame_purchases_price_nb_rows" type="hidden" value="">


		<input id="item_price_frame_purchases_price_item_supplier" type="hidden" value="">
		<input id="item_price_frame_purchases_price_item_supplier_color" type="hidden" value="#d0e7fb">
		<input id="item_price_frame_purchases_price_site_supplier" type="hidden" value="">
		<input id="item_price_frame_purchases_price_site_supplier_color" type="hidden" value="#fcf5bb">
		<input id="item_price_frame_purchases_price_item_site_supplier" type="hidden" value="">
		<input id="item_price_frame_purchases_price_item_site_supplier_color" type="hidden" value="#f7ceb0">`;

		if (cur_frm) {

			if (cur_frm.doctype.toUpperCase() == 'IOI ITEM') {

				html += `<div style="overflow: auto; overflow-x: auto; height:8px;">
				</div>

				<div id="" style="overflow: auto; overflow-x: auto; overflow-y: hidden; height:30px; border: 0px solid #E6E9EC;">

					<div style="position: relative; top: 0px; left:0px; width:200px; height:30px; border: 1px solid #E6E9EC; background:#d0e7fb">
						<div style="position: absolute; top:4px; height: 30px; width:200px;text-align:center" align="center">
							<label style=""><b>${__("Item supplier")}</b></label>
						</div>
					</div>

					<div style="position: relative; top: -30px; left:210px; width:200px; height:30px; border: 1px solid #E6E9EC; background:#fcf5bb">
						<div style="position: absolute; top:4px; height: 30px; width:200px;text-align:center" align="center">
							<label style=""><b>${__("Site supplier")}</b></label>
						</div>
					</div>

					<div style="position: relative; top: -60px; left:420px; width:200px; height:30px; border: 1px solid #E6E9EC; background:#f7ceb0">
						<div style="position: absolute; top:4px; height: 30px; width:200px;text-align:center" align="center">
				 			<label style=""><b>${__("Item & Site supplier")}</b></label>
						</div>
					</div>

				</div>`;

			}
		}


		cur_frm.fields_dict[html_field].$wrapper.empty();
		cur_frm.fields_dict[html_field].$wrapper.append(html);

		silicon_ioi.doctype.ioiItemPriceFrame.sleep_static(200).then(() => {

			let fct_el=document.getElementById('item_purchases_price_frame_button_new');
			if (fct_el) {

				let fct_new = function() {
					silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_new();
				}

				fct_el.onclick = fct_new;
			}

			fct_el=document.getElementById('item_purchases_price_frame_button_delete');
			if (fct_el) {

				let fct_delete = function() {
					silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_delete();
				}

				fct_el.onclick = fct_delete;
			}

			fct_el=document.getElementById('item_purchases_price_frame_button_save');
			if (fct_el) {

				let fct_save = function() {
					silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_save();
				}

				fct_el.onclick = fct_save;
			}

			fct_el=document.getElementById('item_purchases_price_frame_button_cancel');
			if (fct_el) {

				let fct_cancel = function() {
					silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_cancel();
				}

				fct_el.onclick = fct_cancel;
			}

			fct_el=document.getElementById('item_purchases_price_frame_button_duplicate');
			if (fct_el) {

				let fct_duplicate = function() {
					silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_duplicate();
				}

				fct_el.onclick = fct_duplicate;
			}


			if (cur_frm) {

				if (cur_frm.doctype.toUpperCase() == 'IOI ITEM') {

					frappe.call({
						method: silicon_ioi.doctype.ioiItemPriceFrame.path_purchases_price + ".ioi_purchases_price_get_suppliers_for_an_item",
						args: {"item_id" : silicon_ioi.doctype.ioiItemPriceFrame.item_id},
						async : true,
						callback:function(r){

							document.getElementById('item_price_frame_purchases_price_item_supplier').value = r.message.item_supplier_id;

							document.getElementById('item_price_frame_purchases_price_site_supplier').value = r.message.site_supplier_id;

							if (r.message.item_supplier_id == r.message.site_supplier_id) {
								document.getElementById('item_price_frame_purchases_price_item_site_supplier').value = r.message.item_supplier_id;
							}
						}
					});
				}
			}


			silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_get_result();

		});
	}


	static purchases_price_grid_header()
	{
		let grid_header_el = document.getElementById('item_price_frame_purchases_price_grid_header');
		if (grid_header_el) {
			grid_header_el.remove();
		}

		let html = '';

		let bgcolor = silicon_ioi.doctype.ioiItemPriceFrame.bgcolor;

		silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_grid_width = 2760;

		html += `<table id="item_price_frame_purchases_price_grid_header" width=${silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_grid_width.toString()}px border=1 style="border: 1px solid #E8EAEB" data-custom-grid="true">
		<tr style="height:30px">
		<td width=30px style="vertical-align: middle;">&nbsp;</td>
		<td width=200px style="vertical-align: middle;"><b>&nbsp;${__("Supplier")}</b></td>
		<td width=170px style="vertical-align: middle;"><b>&nbsp;${__("Condition")}</b></td>
		<td width=120px style="vertical-align: middle;"><b>&nbsp;${__("Unit")}</b></td>
		<td width=100px style="vertical-align: middle;"><b>&nbsp;${__("Curr.")}</b></td>
		<td width=100px style="vertical-align: middle;"><b>&nbsp;${__("Site")}</b></td>
		<td width=80px style="vertical-align: middle;"><b>&nbsp;${__("Enabled")}</b></td>
		<td width=80px style="vertical-align: middle;"><b>&nbsp;${__("Prefered")}</b></td>
		<td width=120px style="vertical-align: middle;"><b>&nbsp;${__("Unit price")}</b></td>
		<td width=120px style="vertical-align: middle;"><b>&nbsp;${__("Fixed price")}</b></td>
		<td width=120px style="vertical-align: middle;"><b>&nbsp;${__("Base disc. (%)")}</b></td>
		<td width=120px style="vertical-align: middle;"><b>&nbsp;${__("Extra disc. (%)")}</b></td>
		<td width=150px style="vertical-align: middle;"><b>&nbsp;${__("Discount in value")}</b></td>
		<td width=150px style="vertical-align: middle;"><b>&nbsp;${__("Start date")}</b></td>
		<td width=150px style="vertical-align: middle;"><b>&nbsp;${__("End date")}</b></td>
		<td width=150px style="vertical-align: middle;"><b>&nbsp;${__("Min order qty")}</b></td>
		<td width=150px style="vertical-align: middle;"><b>&nbsp;${__("Multiple order qty")}</b></td>
		<td width=150px style="vertical-align: middle;"><b>&nbsp;${__("Delivery delay")}</b></td>
		<td width=300px style="vertical-align: middle;"><b>&nbsp;${__("Contract / Price request")}</b></td>
		<td width=200px style="vertical-align: middle;"><b>&nbsp;${__("External ref")}</b></td>

		</tr>
		</table>`;

		return html;
	}

	static purchases_price_grid_empty_row()
	{
		let base_default = document.getElementById("item_price_frame_purchases_price_grid_detail");
		if (base_default) {

			for (var i = 0; i < base_default.rows.length; i++) {


				let id_list = ["item_price_frame_purchases_price_grid_detail_name_","item_price_frame_purchases_price_grid_detail_name_to_duplicate_","item_price_frame_purchases_price_grid_detail_id_","item_price_frame_purchases_price_grid_detail_record_modified_","item_price_frame_purchases_price_grid_detail_new_record_","item_price_frame_purchases_price_grid_detail_checked_id_","item_price_frame_purchases_price_grid_detail_has_price_qty_","item_price_frame_purchases_price_grid_detail_document_type_","item_price_frame_purchases_price_grid_detail_label_supplier_id_","item_price_frame_purchases_price_grid_detail_label_purchases_condition_id_",
				"item_price_frame_purchases_price_grid_detail_label_unit_id_","item_price_frame_purchases_price_grid_detail_label_currency_id_","item_price_frame_purchases_price_grid_detail_label_site_id_","item_price_frame_purchases_price_grid_detail_input_supplier_id_","item_price_frame_purchases_price_grid_detail_input_supplier_button_","item_price_frame_purchases_price_grid_detail_input_purchases_condition_id_","item_price_frame_purchases_price_grid_detail_input_purchases_condition_button_","item_price_frame_purchases_price_grid_detail_input_unit_id_","item_price_frame_purchases_price_grid_detail_input_unit_button_","item_price_frame_purchases_price_grid_detail_input_currency_id_","item_price_frame_purchases_price_grid_detail_input_currency_button_",
				"item_price_frame_purchases_price_grid_detail_input_site_id_","item_price_frame_purchases_price_grid_detail_enabled_","item_price_frame_purchases_price_grid_detail_prefered_","item_price_frame_purchases_price_grid_detail_label_unit_price_","item_price_frame_purchases_price_grid_detail_label_fixed_price_","item_price_frame_purchases_price_grid_detail_label_base_discount_","item_price_frame_purchases_price_grid_detail_label_extra_discount_","item_price_frame_purchases_price_grid_detail_label_discount_in_value_","item_price_frame_purchases_price_grid_detail_label_start_date_","item_price_frame_purchases_price_grid_detail_label_end_date_",
				"item_price_frame_purchases_price_grid_detail_label_min_order_qty_","item_price_frame_purchases_price_grid_detail_label_multiple_order_qty_","item_price_frame_purchases_price_grid_detail_label_delivery_delay_","item_price_frame_purchases_price_grid_detail_label_document_master_link_","item_price_frame_purchases_price_grid_detail_label_external_reference_","item_price_frame_purchases_price_grid_detail_input_unit_price_","item_price_frame_purchases_price_grid_detail_input_fixed_price_","item_price_frame_purchases_price_grid_detail_input_base_discount_","item_price_frame_purchases_price_grid_detail_input_extra_discount_",
				"item_price_frame_purchases_price_grid_detail_input_discount_in_value_","item_price_frame_purchases_price_grid_detail_input_start_date_","item_price_frame_purchases_price_grid_detail_input_end_date_","item_price_frame_purchases_price_grid_detail_input_min_order_qty_","item_price_frame_purchases_price_grid_detail_input_multiple_order_qty_","item_price_frame_purchases_price_grid_detail_input_delivery_delay_","item_price_frame_purchases_price_grid_detail_input_external_reference_"]

				for (let nbre = 0 ; nbre < id_list.length; nbre ++)
				{
					let new_id = document.getElementById(id_list[nbre]+ i.toString())
					if (new_id)
					{
						new_id.remove();
					}
				}


				base_default.deleteRow(i);

			}

			base_default.remove();
		}


		let html = '';

		html += `<table id="item_price_frame_purchases_price_grid_detail" width=${silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_grid_width.toString()}px border=1 style="border: 1px solid #E8EAEB">
		<tr style="height:30px">
		<td width=30px  style="vertical-align: middle;">&nbsp;</td>
		<td width=200px style="vertical-align: middle;">&nbsp;</td>
		<td width=170px style="vertical-align: middle;">&nbsp;</td>
		<td width=120px style="vertical-align: middle;">&nbsp;</td>
		<td width=100px style="vertical-align: middle;">&nbsp;</td>
		<td width=100px style="vertical-align: middle;">&nbsp;</td>
		<td width=80px style="vertical-align: middle;">&nbsp;</td>
		<td width=80px style="vertical-align: middle;">&nbsp;</td>

		<td width=120px style="vertical-align: middle;">&nbsp;</td>
		<td width=120px style="vertical-align: middle;">&nbsp;</td>
		<td width=120px style="vertical-align: middle;">&nbsp;</td>
		<td width=120px style="vertical-align: middle;">&nbsp;</td>

		<td width=150px style="vertical-align: middle;">&nbsp;</td>
		<td width=150px style="vertical-align: middle;">&nbsp;</td>
		<td width=150px style="vertical-align: middle;">&nbsp;</td>
		<td width=150px style="vertical-align: middle;">&nbsp;</td>
		<td width=150px style="vertical-align: middle;">&nbsp;</td>
		<td width=150px style="vertical-align: middle;">&nbsp;</td>
		<td width=300px style="vertical-align: middle;">&nbsp;</td>
		<td width=200px style="vertical-align: middle;">&nbsp;</td>
		</tr>
		</table>`;

		return html;
	}

	static purchases_price_get_result()
	{
		let base_item_is_el = document.getElementById('item_price_frame_purchases_price_grid_detail');
		let price_nb_rows_el = document.getElementById('item_price_frame_purchases_price_nb_rows');
		let price_grid_header = document.getElementById('item_price_frame_purchases_price_grid_header');
		if (silicon_ioi.doctype.ioiItemPriceFrame.item_id.trim() == '') {

			let html = silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_grid_empty_row();
			price_grid_header.insertAdjacentHTML('afterend', html);

			price_nb_rows_el.value = 1;


		}else{

			frappe.call({ 	method: silicon_ioi.doctype.ioiItemPriceFrame.path_purchases_price + ".ioi_purchases_price_get_prices_for_an_item",
							args: {"item_id" : silicon_ioi.doctype.ioiItemPriceFrame.item_id},
							async : true,
							callback:function(r){

								if (base_item_is_el) {

									for (var i = 0; i < base_item_is_el.rows.length; i++) {


										let id_list = ["item_price_frame_purchases_price_grid_detail_name_","item_price_frame_purchases_price_grid_detail_name_to_duplicate_",
										"item_price_frame_purchases_price_grid_detail_id_","item_price_frame_purchases_price_grid_detail_record_modified_",
										"item_price_frame_purchases_price_grid_detail_new_record_","item_price_frame_purchases_price_grid_detail_checked_id_",
										"item_price_frame_purchases_price_grid_detail_has_price_qty_","item_price_frame_purchases_price_grid_detail_document_type_",
										"item_price_frame_purchases_price_grid_detail_label_supplier_id_","item_price_frame_purchases_price_grid_detail_label_purchases_condition_id_",
										"item_price_frame_purchases_price_grid_detail_label_unit_id_","item_price_frame_purchases_price_grid_detail_label_currency_id_",
										"item_price_frame_purchases_price_grid_detail_label_site_id_","item_price_frame_purchases_price_grid_detail_input_supplier_id_",
										"item_price_frame_purchases_price_grid_detail_input_supplier_button_","item_price_frame_purchases_price_grid_detail_input_purchases_condition_id_",
										"item_price_frame_purchases_price_grid_detail_input_purchases_condition_button_","item_price_frame_purchases_price_grid_detail_input_unit_id_",
										"item_price_frame_purchases_price_grid_detail_input_unit_button_","item_price_frame_purchases_price_grid_detail_input_currency_id_",
										"item_price_frame_purchases_price_grid_detail_input_currency_button_","item_price_frame_purchases_price_grid_detail_input_site_id_",
										"item_price_frame_purchases_price_grid_detail_enabled_","item_price_frame_purchases_price_grid_detail_prefered_",
										"item_price_frame_purchases_price_grid_detail_label_unit_price_",
										"item_price_frame_purchases_price_grid_detail_label_fixed_price_","item_price_frame_purchases_price_grid_detail_label_base_discount_",
										"item_price_frame_purchases_price_grid_detail_label_extra_discount_","item_price_frame_purchases_price_grid_detail_label_discount_in_value_",
										"item_price_frame_purchases_price_grid_detail_label_start_date_","item_price_frame_purchases_price_grid_detail_label_end_date_",
										"item_price_frame_purchases_price_grid_detail_label_min_order_qty_","item_price_frame_purchases_price_grid_detail_label_multiple_order_qty_",
										"item_price_frame_purchases_price_grid_detail_label_delivery_delay_","item_price_frame_purchases_price_grid_detail_label_document_master_link_",
										"item_price_frame_purchases_price_grid_detail_label_external_reference_","item_price_frame_purchases_price_grid_detail_input_unit_price_",
										"item_price_frame_purchases_price_grid_detail_input_fixed_price_","item_price_frame_purchases_price_grid_detail_input_base_discount_",
										"item_price_frame_purchases_price_grid_detail_input_extra_discount_","item_price_frame_purchases_price_grid_detail_input_discount_in_value_",
										"item_price_frame_purchases_price_grid_detail_input_start_date_","item_price_frame_purchases_price_grid_detail_input_end_date_",
										"item_price_frame_purchases_price_grid_detail_input_min_order_qty_","item_price_frame_purchases_price_grid_detail_input_multiple_order_qty_",
										"item_price_frame_purchases_price_grid_detail_input_delivery_delay_","item_price_frame_purchases_price_grid_detail_input_external_reference_"]

										for (let nbre = 0 ; nbre < id_list.length; nbre ++)
										{
											let new_id = document.getElementById(id_list[nbre]+ i.toString())
											if (new_id)
											{
												new_id.remove();
											}
										}

										base_item_is_el.deleteRow(i);


									}
								}

								while (document.getElementById('item_price_frame_purchases_price_grid_detail')) {
									document.getElementById('item_price_frame_purchases_price_grid_detail').remove();
								}

								let at_least_one_row = false;
								let can_modify = silicon_ioi.doctype.ioiItemPriceFrame.can_modify_purchases;


								let html = '';

								if (r.message.data.length > 0)
								{

									let cpt = 0;

									html = `<table id="item_price_frame_purchases_price_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=${silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_grid_width}px>`;

									for (var i = 0; i < r.message.data.length; i++)
									{
										at_least_one_row = true;

										let cell_bgcolor = '';

										if (silicon_ioi.doctype.ioiItemPriceFrame.is_dark_mode == 0) {
											cell_bgcolor = '#FFFFFF';
										}else{
											cell_bgcolor = '#1C2126';
										}

										html += `<input id="item_price_frame_purchases_price_grid_detail_name_${i.toString()}" type="hidden" value="${r.message.data[i].name}">
										<input id="item_price_frame_purchases_price_grid_detail_id_${i.toString()}" type="hidden" value="${i.toString()}">
										<input id="item_price_frame_purchases_price_grid_detail_record_modified_${i.toString()}" type="hidden" value="N">
										<input id="item_price_frame_purchases_price_grid_detail_new_record_${i.toString()}" type="hidden" value="N">
										<input id="item_price_frame_purchases_price_grid_detail_name_to_duplicate_${i.toString()}" type="hidden" value="">
										<input id="item_price_frame_purchases_price_grid_detail_has_price_qty_${i.toString()}" type="hidden" value="${r.message.data[i].has_price_qty}">

										<input id="item_price_frame_purchases_price_grid_detail_document_type_${i.toString()}" type="hidden" value="${r.message.data[i].document_type}">



										<tr id="item_price_frame_purchases_price_grid_detail_row_${i.toString()}"  style="height:30px">

										<td width=30px style="vertical-align: middle;" align="center">

										<input type="checkbox" id="item_price_frame_purchases_price_grid_detail_checked_id_${i.toString()}" style="postion:absolute; top: 2px; left: 2px;"
										onkeydown="   if (event.keyCode == 13) { return false; } "
										onkeyup="   if (event.keyCode == 13) { return false; } "
										>


										</td>`


										let url_purchases_price = '/app/ioi-purchases-price/' + r.message.data[i].name;

										if (r.message.data[i].supplier_id)
										{	html += `<td width=200px style="background-color:${cell_bgcolor}; vertical-align: middle;">&nbsp;
											<label id="item_price_frame_purchases_price_grid_detail_label_supplier_id_${i.toString()}" style="position:relative; top:4px; color: #000000;" onclick="window.open(\'${url_purchases_price}'\);"><u>${r.message.data[i].supplier_id}</u></label>
											</td>`;
										}else
										{	html += `<td width=200px style="background-color:${cell_bgcolor}; vertical-align: middle;">&nbsp;
											<label id="item_price_frame_purchases_price_grid_detail_label_supplier_id_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>
											</td>`;
										}

										if (r.message.data[i].purchases_condition_id)
										{	html += `<td width=170px style="background-color:${cell_bgcolor}; vertical-align: middle;">&nbsp;
											<label id="item_price_frame_purchases_price_grid_detail_label_purchases_condition_id_${i.toString()}" style="position:relative; top:4px; color: #000000;" onclick="window.open(\'${url_purchases_price}'\);"><u>${r.message.data[i].purchases_condition_id}</u></label>
											</td>`;
										}else
										{	html += `<td width=170px style="background-color:${cell_bgcolor}; vertical-align: middle;">&nbsp;
											<label id="item_price_frame_purchases_price_grid_detail_label_purchases_condition_id_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>
											</td>`;
										}

										if (r.message.data[i].unit_id)
										{	html += `<td width=120px style="background-color:${cell_bgcolor}; vertical-align: middle;">&nbsp;
											<label id="item_price_frame_purchases_price_grid_detail_label_unit_id_${i.toString()}" style="position:relative; top:4px; color: #000000;" onclick="window.open(\'${url_purchases_price}'\);"><u>${r.message.data[i].unit_id}</u></label>
											</td>`;
										}else
										{	html += `<td width=120px style="background-color:${cell_bgcolor}; vertical-align: middle;">&nbsp;
											<label id="item_price_frame_purchases_price_grid_detail_label_unit_id_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>
											</td>`;
										}

										if (r.message.data[i].currency_id)
										{	html += `<td width=100px style="background-color:${cell_bgcolor}; vertical-align: middle;">&nbsp;
											<label id="item_price_frame_purchases_price_grid_detail_label_currency_id_${i.toString()}" style="position:relative; top:4px; color: #000000;" onclick="window.open(\'${url_purchases_price}'\);"><u>${r.message.data[i].currency_id}</u></label>
											</td>`;
										}else
										{	html += `<td width=100px style="background-color:${cell_bgcolor}; vertical-align: middle;">&nbsp;
											<label id="item_price_frame_purchases_price_grid_detail_label_currency_id_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>
											</td>`;
										}

										if (r.message.data[i].site_id)
										{	html += `<td width=100px style="background-color:${cell_bgcolor}; vertical-align: middle;">&nbsp;
											<label id="item_price_frame_purchases_price_grid_detail_label_site_id_${i.toString()}" style="position:relative; top:4px; color: #000000;" onclick="window.open(\'${url_purchases_price}'\);"><u>${r.message.data[i].site_id}</u></label>
											</td>`;
										}else
										{	html += `<td width=100px style="background-color:${cell_bgcolor}; vertical-align: middle;">&nbsp;
											<label id="item_price_frame_purchases_price_grid_detail_label_site_id_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>
											</td>`;
										}


										if (r.message.data[i].enabled)
										{
											html += `<td width=80px align="center" style="background-color:${cell_bgcolor}; vertical-align: middle;">
											<input type="checkbox" id="item_price_frame_purchases_price_grid_detail_enabled_${i.toString()}" style="postion:absolute; top: 2px; left: 2px;"`;

											if (r.message.data[i].enabled == 1)	{
												html += "checked"
											}

											if (!can_modify) {
												html += ' disabled ';
											}else{

												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += ' disabled ';
													}
												}
											}

											html += `>
											</td>`;

										}else
										{
											html += `<td width=80px align="center" style="background-color:${cell_bgcolor}; vertical-align: middle;">
											<input type="checkbox" id="item_price_frame_purchases_price_grid_detail_enabled_${i.toString()}" style="postion:absolute; top: 2px; left: 2px;"`;

											if (!can_modify) {
												html += ' disabled ';
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += ' disabled ';
													}
												}
											}

											html += `>
											</td>`;
										}


										if (r.message.data[i].prefered)
										{
											html += `<td width=80px align="center" style="background-color:${cell_bgcolor}; vertical-align: middle;">
											<input type="checkbox" id="item_price_frame_purchases_price_grid_detail_prefered_${i.toString()}" style="postion:absolute; top: 2px; left: 2px;" `;

											if (r.message.data[i].prefered == 1) {
												html += "checked"
											}

											if (!can_modify) {
												html += ' disabled ';
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += ' disabled ';
													}
												}
											}

											html += `>
											</td>`;

										}else
										{
											html += `<td width=80px align="center" style="background-color:${cell_bgcolor}; vertical-align: middle;">
											<input type="checkbox" id="item_price_frame_purchases_price_grid_detail_prefered_${i.toString()}" style="postion:absolute; top: 2px; left: 2px;"`;

											if (!can_modify) {
												html += ' disabled ';
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += ' disabled ';
													}
												}
											}

											html += `>
											</td>`;
										}



										html += `<input id="item_price_frame_purchases_price_grid_detail_document_type_${i.toString()}" type="hidden" value="${r.message.data[i].document_type}">`;


										if (r.message.data[i].unit_price)
										{
											if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
											{
												if (r.message.data[i].document_type == 'CONTRACT') {

													html += `<td id="item_price_frame_purchases_price_grid_detail_cell_unit_price_${i.toString()}" width=120px align="right" style="background-color:#79FF79; vertical-align: middle;">';
													<label id="item_price_frame_purchases_price_grid_detail_label_unit_price_${i.toString()}" style="position:relative; top:4px; color: #000000;">`;

													if (silicon_ioi.doctype.ioiItemPriceFrame.can_read_purchases) {
														html += r.message.data[i].unit_price.toString();
													}
													html += `&nbsp;</label>
													</td>`;

												}else{
													if (!can_modify) {
														html += `<td id="item_price_frame_purchases_price_grid_detail_cell_unit_price_${i.toString()}" width=120px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">
														<label id="item_price_frame_purchases_price_grid_detail_label_unit_price_${i.toString()}" style="position:relative; top:4px; color: #000000;">`;

														if (silicon_ioi.doctype.ioiItemPriceFrame.can_read_purchases) {
															html += r.message.data[i].unit_price.toString();
														}

														html += `&nbsp;</label>
														</td>`;
													}else{

														html += `<td id="item_price_frame_purchases_price_grid_detail_cell_unit_price_${i.toString()}" width=120px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">
														<input id="item_price_frame_purchases_price_grid_detail_input_unit_price_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="${r.message.data[i].unit_price}">
														</td>`;
													}
												}
											}else {
												html += `<td id="item_price_frame_purchases_price_grid_detail_cell_unit_price_${i.toString()}" width=120px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">`;

												if (!can_modify) {
													html += `<label id="item_price_frame_purchases_price_grid_detail_label_unit_price_${i.toString()}" style="position:relative; top:4px; color: #000000;">`;

													if (silicon_ioi.doctype.ioiItemPriceFrame.can_read_purchases) {
														html += r.message.data[i].unit_price.toString();
													}

													html += `&nbsp;</label>`;
												}else{
													html += `<input id="item_price_frame_purchases_price_grid_detail_input_unit_price_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="${r.message.data[i].unit_price}">`;
												}
												html += `</td>`;
											}
										}else
										{
											html += `<td id="item_price_frame_purchases_price_grid_detail_cell_unit_price_${i.toString()}" width=120px style="background-color:${cell_bgcolor}; vertical-align: middle;">`;
											if (!can_modify) {
												html += `<label id="item_price_frame_purchases_price_grid_detail_label_unit_price_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_purchases_price_grid_detail_label_unit_price_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;

													}else{
														html += `<input id="item_price_frame_purchases_price_grid_detail_input_unit_price_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="">`;
													}
												}else{
													html += `<input id="item_price_frame_purchases_price_grid_detail_input_unit_price_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="">`;
												}
											}
											html += `</td>`;
										}


										html += `<td width=120px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">`;

										if (r.message.data[i].fixed_price)
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_purchases_price_grid_detail_label_fixed_price_${i.toString()}" style="position:relative; top:4px; color: #000000;">`;

												if (silicon_ioi.doctype.ioiItemPriceFrame.can_read_purchases) {
													html += r.message.data[i].fixed_price.toString();
												}

												html += `&nbsp;</label>`;
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_purchases_price_grid_detail_label_fixed_price_${i.toString()}" style="position:relative; top:4px; color: #000000;">`;

														if (silicon_ioi.doctype.ioiItemPriceFrame.can_read_purchases) {
															html += r.message.data[i].fixed_price.toString();
														}

														html += '&nbsp;</label>';
													}else{
														html += `<input id="item_price_frame_purchases_price_grid_detail_input_fixed_price_${i.toString()}" type="number" step="any" style="width:118px;height:30px;text-align:right;" value="${r.message.data[i].fixed_price}">`;
													}
												}else{
													html += `<input id="item_price_frame_purchases_price_grid_detail_input_fixed_price_${i.toString()}" type="number" step="any" style="width:118px;height:30px;text-align:right;" value="${r.message.data[i].fixed_price}">`;
												}

											}
										}else
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_purchases_price_grid_detail_label_fixed_price_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
											}else{

												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_purchases_price_grid_detail_label_fixed_price_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
													}else{
														html += `<input id="item_price_frame_purchases_price_grid_detail_input_fixed_price_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="">`;
													}
												}else{
													html += `<input id="item_price_frame_purchases_price_grid_detail_input_fixed_price_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="">`;
												}
											}
										}

										html += `</td>

										<td id="item_price_frame_purchases_price_grid_detail_cell_base_discount_${i.toString()}" width=120px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">`;

										if (r.message.data[i].base_discount)
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_purchases_price_grid_detail_label_base_discount_${i.toString()}" style="position:relative; top:4px; color: #000000;">`;

												if (silicon_ioi.doctype.ioiItemPriceFrame.can_read_purchases) {
													html += r.message.data[i].base_discount.toFixed(2).toString() + '&nbsp;%';
												}

												html += `&nbsp;</label>`;
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_purchases_price_grid_detail_label_base_discount_${i.toString()}" style="position:relative; top:4px; color: #000000;">`;

														if (silicon_ioi.doctype.ioiItemPriceFrame.can_read_purchases) {
															html += r.message.data[i].base_discount.toFixed(2).toString() + '&nbsp;%';
														}

														html += `&nbsp;</label>`;

													}else{
														html += `<input id="item_price_frame_purchases_price_grid_detail_input_base_discount_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="${r.message.data[i].base_discount}">`;
													}
												}else{
													html += `<input id="item_price_frame_purchases_price_grid_detail_input_base_discount_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="${r.message.data[i].base_discount}">`;
												}
											}
										}else
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_purchases_price_grid_detail_label_base_discount_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
											}else{

												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_purchases_price_grid_detail_label_base_discount_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
													}else{
														html += `<input id="item_price_frame_purchases_price_grid_detail_input_base_discount_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="">`;
													}
												}else{
													html += `<input id="item_price_frame_purchases_price_grid_detail_input_base_discount_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="">`;
												}
											}
										}

										html += `</td>

										<td id="item_price_frame_purchases_price_grid_detail_cell_extra_discount_${i.toString()}" width=120px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">`;

										if (r.message.data[i].extra_discount)
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_purchases_price_grid_detail_label_extra_discount_${i.toString()}" style="position:relative; top:4px; color: #000000;">`;

												if (silicon_ioi.doctype.ioiItemPriceFrame.can_read_purchases) {
													html += r.message.data[i].extra_discount.toFixed(2).toString() + '&nbsp;%';
												}

												html += `&nbsp;</label>`;
											}else{

												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_purchases_price_grid_detail_label_extra_discount_${i.toString()}" style="position:relative; top:4px; color: #000000;">`;

														if (silicon_ioi.doctype.ioiItemPriceFrame.can_read_purchases) {
															html += r.message.data[i].extra_discount.toFixed(2).toString() + '&nbsp;%';
														}

														html += `&nbsp;</label>`;
													}else{
														html += `<input id="item_price_frame_purchases_price_grid_detail_input_extra_discount_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="${r.message.data[i].extra_discount}">`;
													}
												}else{
													html += `<input id="item_price_frame_purchases_price_grid_detail_input_extra_discount_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="${r.message.data[i].extra_discount}">`;
												}
											}
										}else
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_purchases_price_grid_detail_label_extra_discount_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_purchases_price_grid_detail_label_extra_discount_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
													}else{
														html += `<input id="item_price_frame_purchases_price_grid_detail_input_extra_discount_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="">`;
													}
												}else{
													html += `<input id="item_price_frame_purchases_price_grid_detail_input_extra_discount_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="">`;
												}
											}
										}

										html += `</td>


										<td width=150px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">`;

										if (r.message.data[i].discount_in_value)
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_purchases_price_grid_detail_label_discount_in_value_${i.toString()}" style="position:relative; top:4px; color: #000000;">`;

												if (silicon_ioi.doctype.ioiItemPriceFrame.can_read_purchases) {
													html += r.message.data[i].discount_in_value.toString();
												}

												html += '&nbsp;</label>';
											}else{

												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {

														html += `<label id="item_price_frame_purchases_price_grid_detail_label_discount_in_value_${i.toString()}" style="position:relative; top:4px; color: #000000;">`;

														if (silicon_ioi.doctype.ioiItemPriceFrame.can_read_purchases) {
															html += r.message.data[i].discount_in_value.toString();
														}

														html += '&nbsp;</label>';

													}else{
														html += `<input id="item_price_frame_purchases_price_grid_detail_input_discount_in_value_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="${r.message.data[i].discount_in_value}">`;
													}
												}else{
													html += `<input id="item_price_frame_purchases_price_grid_detail_input_discount_in_value_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="${r.message.data[i].discount_in_value}">`;
												}
											}
										}else
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_purchases_price_grid_detail_label_discount_in_value_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
											}else{

												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_purchases_price_grid_detail_label_discount_in_value_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
													}else{
														html += `<input id="item_price_frame_purchases_price_grid_detail_input_discount_in_value_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="">`;
													}
												}else{
													html += `<input id="item_price_frame_purchases_price_grid_detail_input_discount_in_value_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="">`;
												}
											}
										}

										html += `</td>


										<td width=150px style="background-color:${cell_bgcolor}; vertical-align: middle;">`;

										if (r.message.data[i].start_date)
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_purchases_price_grid_detail_label_start_date_${i.toString()}" style="position:relative; top:4px; color: #000000;">&nbsp;${r.message.data[i].start_date}</label>`;
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_purchases_price_grid_detail_label_start_date_${i.toString()}" style="position:relative; top:4px; color: #000000;">&nbsp;${r.message.data[i].start_date}</label>`;
													}else{
														html += `<input id="item_price_frame_purchases_price_grid_detail_input_start_date_${i.toString()}" type="date" style="width:146px;height:30px;" value="${r.message.data[i].start_date}">`;
													}
												}else{
													html += `<input id="item_price_frame_purchases_price_grid_detail_input_start_date_${i.toString()}" type="date" style="width:146px;height:30px;" value="${r.message.data[i].start_date}">`;
												}
											}
										}else
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_purchases_price_grid_detail_label_start_date_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_purchases_price_grid_detail_label_start_date_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
													}else{
														html += `<input id="item_price_frame_purchases_price_grid_detail_input_start_date_${i.toString()}" type="date" style="width:146px;height:30px;" value="">`;
													}
												}else{
													html += `<input id="item_price_frame_purchases_price_grid_detail_input_start_date_${i.toString()}" type="date" style="width:146px;height:30px;" value="">`;
												}
											}
										}
										html += `</td>

										<td width=150px style="background-color:${cell_bgcolor}; vertical-align: middle;">`;
										if (r.message.data[i].end_date)
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_purchases_price_grid_detail_label_end_date_${i.toString()}" style="position:relative; top:4px; color: #000000;">&nbsp;${r.message.data[i].end_date}</label>`;
											}else{

												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_purchases_price_grid_detail_label_end_date_${i.toString()}" style="position:relative; top:4px; color: #000000;">&nbsp;${r.message.data[i].end_date}</label>`;
													}else{
														html += `<input id="item_price_frame_purchases_price_grid_detail_input_end_date_${i.toString()}" type="date" style="width:146px;height:30px;" value="${r.message.data[i].end_date}">`;
													}
												}else{
													html += `<input id="item_price_frame_purchases_price_grid_detail_input_end_date_${i.toString()}" type="date" style="width:146px;height:30px;" value="${r.message.data[i].end_date}">`;
												}
											}

										}else
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_purchases_price_grid_detail_label_end_date_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_purchases_price_grid_detail_label_end_date_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;

													}else{
														html += `<input id="item_price_frame_purchases_price_grid_detail_input_end_date_${i.toString()}" type="date" style="width:146px;height:30px;" value="">`;
													}
												}else{
													html += `<input id="item_price_frame_purchases_price_grid_detail_input_end_date_${i.toString()}" type="date" style="width:146px;height:30px;" value="">`;
												}
											}
										}

										html += `</td>


										<td width=150px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">`;

										if (r.message.data[i].min_order_qty)
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_purchases_price_grid_detail_label_min_order_qty_${i.toString()}" style="position:relative; top:4px; color: #000000;">${r.message.data[i].min_order_qty}&nbsp;</label>`;
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_purchases_price_grid_detail_label_min_order_qty_${i.toString()}" style="position:relative; top:4px; color: #000000;">${r.message.data[i].min_order_qty}&nbsp;</label>`;

													}else{
														html += `<input id="item_price_frame_purchases_price_grid_detail_input_min_order_qty_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="${r.message.data[i].min_order_qty}">`;
													}
												}else{
													html += `<input id="item_price_frame_purchases_price_grid_detail_input_min_order_qty_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="${r.message.data[i].min_order_qty}">`;
												}
											}
										}else
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_purchases_price_grid_detail_label_min_order_qty_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_purchases_price_grid_detail_label_min_order_qty_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
													}else{
														html += `<input id="item_price_frame_purchases_price_grid_detail_input_min_order_qty_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="">`;
													}
												}else{
													html += `<input id="item_price_frame_purchases_price_grid_detail_input_min_order_qty_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="">`;
												}
											}
										}

										html += `</td>

										<td width=150px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">`;

										if (r.message.data[i].multiple_order_qty)
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_purchases_price_grid_detail_label_multiple_order_qty_${i.toString()}" style="position:relative; top:4px; color: #000000;">${r.message.data[i].multiple_order_qty}&nbsp;</label>`;
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_purchases_price_grid_detail_label_multiple_order_qty_${i.toString()}" style="position:relative; top:4px; color: #000000;">${r.message.data[i].multiple_order_qty}&nbsp;</label>`;
													}else{
														html += `<input id="item_price_frame_purchases_price_grid_detail_input_multiple_order_qty_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="${r.message.data[i].multiple_order_qty}">`;
													}
												}else{
													html += `<input id="item_price_frame_purchases_price_grid_detail_input_multiple_order_qty_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="${r.message.data[i].multiple_order_qty}">`;
												}
											}
										}else
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_purchases_price_grid_detail_label_multiple_order_qty_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_purchases_price_grid_detail_label_multiple_order_qty_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
													}else{
														html += `<input id="item_price_frame_purchases_price_grid_detail_input_multiple_order_qty_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="">`;
													}
												}else{
													html += `<input id="item_price_frame_purchases_price_grid_detail_input_multiple_order_qty_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="">`;
												}
											}
										}

										html += `</td>

										<td width=150px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">`;

										if (r.message.data[i].delivery_delay)
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_purchases_price_grid_detail_label_delivery_delay_${i.toString()}" style="position:relative; top:4px; color: #000000;">${r.message.data[i].delivery_delay}&nbsp;</label>`;
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_purchases_price_grid_detail_label_delivery_delay_${i.toString()}" style="position:relative; top:4px; color: #000000;">${r.message.data[i].delivery_delay}&nbsp;</label>`;
													}else{
														html += `<input id="item_price_frame_purchases_price_grid_detail_input_delivery_delay_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="${r.message.data[i].delivery_delay}">`;
													}
												}else{
													html += `<input id="item_price_frame_purchases_price_grid_detail_input_delivery_delay_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="${r.message.data[i].delivery_delay}">`;
												}
											}
										}else
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_purchases_price_grid_detail_label_delivery_delay_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_purchases_price_grid_detail_label_delivery_delay_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
													}else{
														html += `<input id="item_price_frame_purchases_price_grid_detail_input_delivery_delay_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="">`;
													}
												}else{
													html += `<input id="item_price_frame_purchases_price_grid_detail_input_delivery_delay_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="">`;
												}
											}
										}

										html += `</td>


										<td width=300px style="background-color:${cell_bgcolor}; vertical-align: middle;">&nbsp;`;

										if (r.message.data[i].document_master_link) {
											let url_master_link = '/app/ioi-purchases-price-request/' + r.message.data[i].document_master_link;
											html += `<label id="item_price_frame_purchases_price_grid_detail_label_document_master_link_${i.toString()}" style="position:relative; top:4px; color: #000000;" onclick="window.open(\'${url_master_link}'\);"><u>${r.message.data[i].document_master_link}</u></label>`;
										}else {
											html += `<label id="item_price_frame_purchases_price_grid_detail_label_document_master_link_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
										}
										html += `</td>

										<td width=200px style="background-color:${cell_bgcolor}; vertical-align: middle;">`;

										if (r.message.data[i].external_reference)
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_purchases_price_grid_detail_label_external_reference_${i.toString()}" style="position:relative; top:4px; color: #000000;">&nbsp;${r.message.data[i].external_reference}&nbsp;</label>`;
											}else{

												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_purchases_price_grid_detail_label_external_reference_${i.toString()}" style="position:relative; top:4px; color: #000000;">&nbsp;${r.message.data[i].external_reference}&nbsp;</label>`;
													}else{
														html += `<input id="item_price_frame_purchases_price_grid_detail_input_external_reference_${i.toString()}" type="text" style="width:196px;height:30px;" value="${r.message.data[i].external_reference}">`;
													}
												}else{
													html += `<input id="item_price_frame_purchases_price_grid_detail_input_external_reference_${i.toString()}" type="text" style="width:196px;height:30px;" value="${r.message.data[i].external_reference}">`;
												}
											}
										}else
										{
											if (!can_modify) {
												html += `<label id="item_price_frame_purchases_price_grid_detail_label_external_reference_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
											}else{
												if ((r.message.data[i].document_master_link) && (r.message.data[i].document_master_link.trim() != ''))
												{
													if (r.message.data[i].document_type == 'CONTRACT') {
														html += `<label id="item_price_frame_purchases_price_grid_detail_label_external_reference_${i.toString()}" style="position:relative; top:4px; color: #000000;"></label>`;
													}else{
														html += `<input id="item_price_frame_purchases_price_grid_detail_input_external_reference_${i.toString()}" type="text" style="width:196px;height:30px;" value="">`;
													}
												}else{
													html += `<input id="item_price_frame_purchases_price_grid_detail_input_external_reference_${i.toString()}" type="text" style="width:196px;height:30px;" value="">`;
												}
											}
										}

										html += `</td>


										</tr>`

										cpt++;
									}

									html += `</table>`;

									price_nb_rows_el.value = cpt;

								}else
								{
									html += silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_grid_empty_row();
									price_nb_rows_el.value = 0;
								}


								html += `</div>`;





								price_grid_header.insertAdjacentHTML('afterend', html);

								silicon_ioi.doctype.ioiItemPriceFrame.sleep_static(400).then(() => {

									if (at_least_one_row) {

										let fct_row_click = function () { silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_row_click(this.id); };
										let fct_edit_value = function() { silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_edit(this.id); };

										let fct_keydown = function(event) {

											if (event.keyCode == 13)
											{	return false;
											}

										};



										let fct_keyup = function(event) {

											if (event.keyCode == 13)
											{	return false;
											}
										};

										let base_item_is_el = document.getElementById('item_price_frame_purchases_price_grid_detail');

										for (var i = 0; i < base_item_is_el.rows.length; i++)	{

											base_item_is_el.rows[i].onclick = fct_row_click;

											if (silicon_ioi.doctype.ioiItemPriceFrame.can_modify_purchases) {
												silicon_ioi.doctype.ioiItemPriceFrame.assign_purchases_row_events(i, fct_edit_value, fct_keydown, fct_keyup);
											}
										}



										for (var j = 0; j < base_item_is_el.rows[0].cells.length; j++) {

											if (silicon_ioi.doctype.ioiItemPriceFrame.is_dark_mode == 0) {
												base_item_is_el.rows[0].cells[j].style.backgroundColor = '#b1fcd9';
											}else{
												base_item_is_el.rows[0].cells[j].style.backgroundColor = 'green';
											}
										}

										silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_row_click(base_item_is_el.rows[0].id);

										document.getElementById('item_purchases_price_frame_content').scrollTop = 0;
									}
								});
							}
			});
		}
	}


	static assign_purchases_row_events(i, fct_edit_value, fct_keydown, fct_keyup)
	{

		let list_id = ['item_price_frame_purchases_price_grid_detail_enabled_','item_price_frame_purchases_price_grid_detail_prefered_',
		'item_price_frame_purchases_price_grid_detail_input_unit_price_','item_price_frame_purchases_price_grid_detail_input_fixed_price_',
		'item_price_frame_purchases_price_grid_detail_input_base_discount_','item_price_frame_purchases_price_grid_detail_input_extra_discount_',
		'item_price_frame_purchases_price_grid_detail_input_discount_in_value_','item_price_frame_purchases_price_grid_detail_input_start_date_',
		'item_price_frame_purchases_price_grid_detail_input_end_date_','item_price_frame_purchases_price_grid_detail_input_min_order_qty_',
		'item_price_frame_purchases_price_grid_detail_input_multiple_order_qty_','item_price_frame_purchases_price_grid_detail_input_delivery_delay_',
		'item_price_frame_purchases_price_grid_detail_input_external_reference_'];

		for (let nbre_id = 0 ; nbre_id < list_id.length; nbre_id ++)
		{

			let compled_el = document.getElementById(list_id[nbre_id] + i.toString());
			if (compled_el)
			{
				compled_el.onclick = fct_edit_value;
				compled_el.onkeydown = fct_keydown;
				compled_el.onkeyup = fct_keyup;
			}

		}

	}


	static purchases_price_row_click(row_id)
	{
		let s = row_id;

		s = s.substring(49, s.length);


		let base_el = document.getElementById('item_price_frame_purchases_price_grid_detail');


		for (var i = 0; i < base_el.rows.length; i++)
		{
			let checked_id = document.getElementById('item_price_frame_purchases_price_grid_detail_checked_id_'+i.toString());
			if (checked_id)
			{
				checked_id.checked = false;
			}

			let enabled = 0

			if (document.getElementById('item_price_frame_purchases_price_grid_detail_enabled_' + i.toString()).checked) {
				enabled = 1
			}


			let start_dt = '';

			let input_start_date_el = document.getElementById('item_price_frame_purchases_price_grid_detail_input_start_date_' + i.toString());
			let label_start_date_el = document.getElementById('item_price_frame_purchases_price_grid_detail_label_start_date_' + i.toString());
			if (input_start_date_el)
			{
				if (input_start_date_el.value != '')
				{
					start_dt = input_start_date_el.value;
				}
			}else if (label_start_date_el)
			{
				if (label_start_date_el.innerText.trim() != '')
				{
					start_dt = label_start_date_el.innerText.trim();
				}
			}



			let end_dt = '';

			let input_end_date_el = document.getElementById('item_price_frame_purchases_price_grid_detail_input_end_date_' + i.toString());
			let label_end_date_el = document.getElementById('item_price_frame_purchases_price_grid_detail_label_end_date_' + i.toString());
			if (input_end_date_el)
			{
				if (input_end_date_el.value != '')
				{
					end_dt = input_end_date_el.value;
				}
			}else if (label_end_date_el)
			{
				if (label_end_date_el.innerText.trim() != '')
				{
					end_dt = label_end_date_el.innerText.trim();
				}
			}




			for (var j = 0; j < base_el.rows[i].cells.length; j++)
			{

				if ((enabled == 0) || ((start_dt != '') && (start_dt > silicon_ioi.doctype.ioiItemPriceFrame.today)) || ((end_dt != '') && (end_dt < silicon_ioi.doctype.ioiItemPriceFrame.today))) {
					base_el.rows[i].cells[j].style.backgroundColor = '#CACBCC';
					base_el.rows[i].cells[j].style.color = '#000000';
				}else{
					let has_price_qty_el = document.getElementById('item_price_frame_purchases_price_grid_detail_has_price_qty_'+ i.toString()) ;
					let document_type_el = document.getElementById('item_price_frame_purchases_price_grid_detail_document_type_' + i.toString());

					if (has_price_qty_el.value == 'Y') {
						if ((base_el.rows[i].cells[j].id == 'item_price_frame_purchases_price_grid_detail_cell_unit_price_' + i.toString()) ||
							(base_el.rows[i].cells[j].id == 'item_price_frame_purchases_price_grid_detail_cell_base_discount_' + i.toString()) ||
							(base_el.rows[i].cells[j].id == 'item_price_frame_purchases_price_grid_detail_cell_extra_discount_' + i.toString())) {

							if (base_el.rows[i].cells[j].id == 'item_price_frame_purchases_price_grid_detail_cell_unit_price_' + i.toString()) {

								if (document.getElementById('item_price_frame_purchases_price_grid_detail_document_type_' + i.toString()).value != 'CONTRACT') {

									base_el.rows[i].cells[j].style.backgroundColor = '#f7fca9';
									base_el.rows[i].cells[j].style.color = '#000000';

									let input_unit_price_el = document.getElementById('item_price_frame_purchases_price_grid_detail_input_unit_price_'+ i.toString());
									let label_unit_price_el = document.getElementById('item_price_frame_purchases_price_grid_detail_label_unit_price_'+ i.toString());

									if (input_unit_price_el)
									{
										input_unit_price_el.style.backgroundColor = '#f7fca9';
										input_unit_price_el.style.color = '#000000';
									}else if (label_unit_price_el)
									{
										label_unit_price_el.style.color = '#000000';
									}
								}
							}else{
								base_el.rows[i].cells[j].style.backgroundColor = '#f7fca9';
								base_el.rows[i].cells[j].style.color = '#000000';

							}

							let input_base_discount_el = document.getElementById('item_price_frame_purchases_price_grid_detail_input_base_discount_' + i.toString());
							let label_base_discount_el = document.getElementById('item_price_frame_purchases_price_grid_detail_label_base_discount_' + i.toString());

							if (input_base_discount_el)
							{
								input_base_discount_el.style.backgroundColor = '#f7fca9';
								input_base_discount_el.style.color = '#000000';
							}else if (label_base_discount_el)
							{
								label_base_discount_el.style.color = '#000000';
							}



							let input_extra_discount_el = document.getElementById('item_price_frame_purchases_price_grid_detail_input_extra_discount_' + i.toString());
							let label_extra_discount_el = document.getElementById('item_price_frame_purchases_price_grid_detail_label_extra_discount_' + i.toString());

							if (input_extra_discount_el)
							{
								input_extra_discount_el.style.backgroundColor = '#f7fca9';
								input_extra_discount_el.style.color = '#000000';
							}
							else if (label_extra_discount_el)
							{
								label_extra_discount_el.style.color = '#000000';
							}


						}else{


							if (silicon_ioi.doctype.ioiItemPriceFrame.is_dark_mode == 0)
							{
								if (document_type_el.value == 'CONTRACT')
								{
									base_el.rows[i].cells[j].style.backgroundColor = '#79FF79';
								}else {
									base_el.rows[i].cells[j].style.backgroundColor = '#FFFFFF';
								}
							}else{
								if (document_type_el.value == 'CONTRACT')
								{
									base_el.rows[i].cells[j].style.backgroundColor = '#79FF79';
								}else{
									base_el.rows[i].cells[j].style.backgroundColor = '#1C2126';
								}
							}
						}


					}else{

						if (silicon_ioi.doctype.ioiItemPriceFrame.is_dark_mode == 0) {
							if (document_type_el.value == 'CONTRACT') {
								base_el.rows[i].cells[j].style.backgroundColor = '#79FF79';
							}else{
								base_el.rows[i].cells[j].style.backgroundColor = '#FFFFFF';
							}
						}else{
							if (document_type_el.value == 'CONTRACT') {
								base_el.rows[i].cells[j].style.backgroundColor = '#79FF79';
							}else{
								base_el.rows[i].cells[j].style.backgroundColor = '#1C2126';
							}
						}
					}
				}

			}
			let label_supplier_id_el = document.getElementById('item_price_frame_purchases_price_grid_detail_label_supplier_id_' + i.toString());
			let item_supplier_el = document.getElementById('item_price_frame_purchases_price_item_supplier');
			let site_supplier_el = document.getElementById('item_price_frame_purchases_price_site_supplier');
			let item_site_supplier_el = document.getElementById('item_price_frame_purchases_price_item_site_supplier');

			if (label_supplier_id_el) {

				if (label_supplier_id_el.innerText.trim() != '') {

					let current_supplier = label_supplier_id_el.innerText.trim();

					if (item_supplier_el.value != '') {

						if (current_supplier == item_supplier_el.value) {
							base_el.rows[i].cells[1].style.backgroundColor = document.getElementById('item_price_frame_purchases_price_item_supplier_color').value;
						}
					}

					if (site_supplier_el.value != '') {

						if (current_supplier == site_supplier_el.value) {
							base_el.rows[i].cells[1].style.backgroundColor = document.getElementById('item_price_frame_purchases_price_site_supplier_color').value;
						}

					}

					if (item_site_supplier_el.value != '') {

						if (current_supplier == item_site_supplier_el.value) {
							base_el.rows[i].cells[1].style.backgroundColor = document.getElementById('item_price_frame_purchases_price_item_site_supplier_color').value;
						}

					}

				}

			}
		}

		document.getElementById('item_price_frame_purchases_price_selected_name').value = document.getElementById('item_price_frame_purchases_price_grid_detail_name_' + s).value;
		document.getElementById('item_price_frame_purchases_price_selected_id').value = document.getElementById('item_price_frame_purchases_price_grid_detail_id_' + s).value;


		let row_el = document.getElementById('item_price_frame_purchases_price_grid_detail_row_'+s)


		for (var i = 0; i < row_el.cells.length; i++)
		{
			if (silicon_ioi.doctype.ioiItemPriceFrame.is_dark_mode == 0) {
				row_el.cells[i].style.backgroundColor = '#b1fcd9';
			}else{
				row_el.cells[i].style.backgroundColor = 'green';
			}
		}

		document.getElementById('item_price_frame_purchases_price_grid_detail_checked_id_' + s).checked = true;


	}


	static purchases_price_edit(id)
	{
		let s = id;

		while (s.indexOf('_') != -1) {
			s = s.substring(s.indexOf('_')+1, s.length);
		}

		s = s.trim();

		let record_modified = document.getElementById('item_price_frame_purchases_price_grid_detail_record_modified_' + s);
		if (record_modified) {
			record_modified.value = 'Y';}

	}


	static purchases_price_new()
	{
		document.getElementById('item_purchases_price_frame_button_new').disabled = true;
		document.getElementById('item_purchases_price_frame_button_duplicate').disabled = true;
		document.getElementById('item_purchases_price_frame_button_delete').disabled = true;

		let i = 0;

		let base = document.getElementById('item_price_frame_purchases_price_grid_detail');

		if (base) {

			if (document.getElementById('item_price_frame_purchases_price_grid_detail_checked_id_0')) {

				let max_id = -1;

				for (var k = 0; k < base.rows.length; k++)
				{
					let id_el = document.getElementById('item_price_frame_purchases_price_grid_detail_id_' + k.toString());
					if (parseInt(id_el.value) > max_id) {
						max_id = parseInt(id_el.value)
					}
				}

				i = max_id + 1;
			}
		}



		let cell_bgcolor = '';

		if (silicon_ioi.doctype.ioiItemPriceFrame.is_dark_mode == 0) {
			cell_bgcolor = '#FFFFFF';
		}else{
			cell_bgcolor = '#1C2126';
		}

		let html = '';

		html += `<input id="item_price_frame_purchases_price_grid_detail_name_${i.toString()}" type="hidden" value="">
		<input id="item_price_frame_purchases_price_grid_detail_id_${i.toString()}" type="hidden" value="${i.toString()}">
		<input id="item_price_frame_purchases_price_grid_detail_has_price_qty_${i.toString()}" type="hidden" value="N">

		<input id="item_price_frame_purchases_price_grid_detail_record_modified_${i.toString()}" type="hidden" value="N">
		<input id="item_price_frame_purchases_price_grid_detail_new_record_${i.toString()}" type="hidden" value="Y">
		<input id="item_price_frame_purchases_price_grid_detail_name_to_duplicate_${i.toString()}" type="hidden" value="">



		<tr id="item_price_frame_purchases_price_grid_detail_row_${i.toString()}"  style="height:30px">

		<td width=30px style="vertical-align: middle;" align="center">

		<input type="checkbox" id="item_price_frame_purchases_price_grid_detail_checked_id_${i.toString()}" style="postion:absolute; top: 2px; left: 2px;"
		        onkeydown="   if (event.keyCode == 13) { return false; } "
				onkeyup="   if (event.keyCode == 13) { return false; } "
		>

		</td>`;

		// Supplier
		html += `<td width=200px style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_purchases_price_grid_detail_input_supplier_id_${i.toString()}" type="text" style="width:160px;height:30px;text-transform: uppercase;" value="">
		<button id="item_price_frame_purchases_price_grid_detail_input_supplier_button_${i.toString()}" style="position:relative; left:6px; top:0px; height: 30px; width: 30px;" tabindex="-1" onclick="">...</button>
		<label id="item_price_frame_purchases_price_grid_detail_label_supplier_id_${i.toString()}" style="position:relative; top:4px;display:none;"></label>
		</td>`;

		// Purchases condition
		html += `<td width=170px style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_purchases_price_grid_detail_input_purchases_condition_id_${i.toString()}" type="text" style="width:130px;height:30px;text-transform: uppercase;" value="${silicon_ioi.doctype.ioiItemPriceFrame.purchases_condition_id}">
		<button id="item_price_frame_purchases_price_grid_detail_input_purchases_condition_button_${i.toString()}" style="position:relative; left:6px; top:0px; height: 30px; width: 30px;" tabindex="-1" onclick="">...</button>
		<label id="item_price_frame_purchases_price_grid_detail_label_purchases_condition_id_${i.toString()}" style="position:relative; top:4px;display:none;"></label>
		</td>`;

		// Unit
		html += `<td width=120px style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_purchases_price_grid_detail_input_unit_id_${i.toString()}" type="text" style="width:80px;height:30px;text-transform: uppercase;" value="">
		<button id="item_price_frame_purchases_price_grid_detail_input_unit_button_${i.toString()}" style="position:relative; left:6px; top:0px; height: 30px; width: 30px;" tabindex="-1" onclick="">...</button>
		<label id="item_price_frame_purchases_price_grid_detail_label_unit_id_${i.toString()}" style="position:relative; top:4px;display:none;"></label>
		</td>`;

		// Currency
		html += `<td width=100px style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_purchases_price_grid_detail_input_currency_id_${i.toString()}" type="text" style="width:60px;height:30px;text-transform: uppercase;" value="">
		<button id="item_price_frame_purchases_price_grid_detail_input_currency_button_${i.toString()}" style="position:relative; left:6px; top:0px; height: 30px; width: 30px;" tabindex="-1" onclick="">...</button>
		<label id="item_price_frame_purchases_price_grid_detail_label_currency_id_${i.toString()}" style="position:relative; top:4px;display:none;"></label>
		</td>`;


		// Site
		html += `<td width=100px style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_purchases_price_grid_detail_input_site_id_${i.toString()}" type="text" style="width:60px;height:30px;text-transform: uppercase;" value="">
		<button id="item_price_frame_purchases_price_grid_detail_input_site_button_${i.toString()}" style="position:relative; left:6px; top:0px; height: 30px; width: 30px;" tabindex="-1" onclick="">...</button>
		<label id="item_price_frame_purchases_price_grid_detail_label_site_id_${i.toString()}" style="position:relative; top:4px;display:none;"></label>
		</td>`;


		// Enabled
		html += `<td width=80px align="center" style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input type="checkbox" id="item_price_frame_purchases_price_grid_detail_enabled_${i.toString()}" style="postion:absolute; top: 2px; left: 2px;" checked>
		</td>`;

		// Prefered
		html += `<td width=80px align="center" style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input type="checkbox" id="item_price_frame_purchases_price_grid_detail_prefered_${i.toString()}" style="postion:absolute; top: 2px; left: 2px;">
		</td>`;

		// Document type
		html += `<input id="item_price_frame_purchases_price_grid_detail_document_type_${i.toString()}" type="hidden" value="">`;


		// Unit price
		html += `<td width=120px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_purchases_price_grid_detail_input_unit_price_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="">
		</td>`;

		// Fixed price
		html += `<td width=120px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_purchases_price_grid_detail_input_fixed_price_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="">
		</td>`;

		// Base discount
		html += `<td width=120px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_purchases_price_grid_detail_input_base_discount_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="">
		</td>`;


		// Extra discount
		html += `<td width=120px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_purchases_price_grid_detail_input_extra_discount_${i.toString()}" type="number" step="any" style="width:116px;height:30px;text-align:right;" value="">
		</td>`;

		// Discount in value
		html += `<td width=150px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_purchases_price_grid_detail_input_discount_in_value_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="">
		</td>`;

		// Start date
		let dt = silicon_ioi.doctype.ioiItemPriceFrame.today;


		html += `<td width=150px style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_purchases_price_grid_detail_input_start_date_${i.toString()}" type="date" style="width:146px;height:30px;" value="' + dt + '">
		</td>`;

		// End date
		html += `<td width=150px style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_purchases_price_grid_detail_input_end_date_${i.toString()}" type="date" style="width:146px;height:30px;" value="">
		</td>`;


		// Min order qty
		html += `<td width=150px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_purchases_price_grid_detail_input_min_order_qty_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="">
		</td>`;

		// Multiple order qty
		html += `<td width=150px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_purchases_price_grid_detail_input_multiple_order_qty_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="">
		</td>`;

		// Delivery delay
		html += `<td width=150px align="right" style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_purchases_price_grid_detail_input_delivery_delay_${i.toString()}" type="number" step="any" style="width:146px;height:30px;text-align:right;" value="">
		</td>`;


		// Contract link
		html += `<td width=300px style="background-color:${cell_bgcolor}; vertical-align: middle;">&nbsp;
		<label id="item_price_frame_purchases_price_grid_detail_label_document_master_link_${i.toString()}" style="position:relative; top:4px"></label>
		</td>`;

		// External reference
		html += `<td width=200px style="background-color:${cell_bgcolor}; vertical-align: middle;">
		<input id="item_price_frame_purchases_price_grid_detail_input_external_reference_${i.toString()}" type="text" style="width:196px;height:30px;" value="">
		</td>

		</tr>`;
		let grid_detail = document.getElementById('item_price_frame_purchases_price_grid_detail');

		if (i == 0) {

			if (grid_detail) {
				grid_detail.remove();
			}

			html = `<table id="item_price_frame_purchases_price_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=${silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_grid_width}px>${html}
			</table>`;

			document.getElementById('item_price_frame_purchases_price_grid_header').insertAdjacentHTML('afterend', html);
		}else{
			let table = grid_detail;
			table.insertAdjacentHTML('beforeend', html);
		}


		let nb_row = document.getElementById('item_price_frame_purchases_price_nb_rows');

		nb_row.value = parseInt(nb_row.value) + 1;

		silicon_ioi.doctype.ioiItemPriceFrame.sleep_static(200).then(() => {

			document.getElementById('item_price_frame_purchases_price_grid_detail_input_purchases_condition_id_' + i.toString()).value = silicon_ioi.doctype.ioiItemPriceFrame.purchases_condition_id;
			document.getElementById('item_price_frame_purchases_price_grid_detail_input_unit_id_' + i.toString()).value = silicon_ioi.doctype.ioiItemPriceFrame.unit_id;
			document.getElementById('item_price_frame_purchases_price_grid_detail_input_currency_id_' + i.toString()).value = silicon_ioi.doctype.ioiItemPriceFrame.system_currency;


			let fct_row_click = function () { silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_row_click(this.id); };
			let fct_edit_value = function() { silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_edit(this.id); };

			let fct_keydown = function(event) {

				if (event.keyCode == 13)
				{	return false;
				}

			};

			let fct_keyup = function(event) {

				if (event.keyCode == 13)
				{	return false;
				}
			};


			grid_detail.rows[i].onclick = fct_row_click;



			// Supplier
			let fct_supplier_button = function() {

				let s = this.id;

				while (s.indexOf('_') != -1) {
					s = s.substring(s.indexOf('_')+1, s.length);
				}


				let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email, alternate_id';
				let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400, 250';
				let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email, Alternate id';
				let where = 'ioistatus = 2';
				let order_by = 'name asc';
				let input_supplier_id = document.getElementById('item_price_frame_purchases_price_grid_detail_input_supplier_id_' + s);

				let fct_callback = function (return_value) {

					input_supplier_id.value = return_value;
					input_supplier_id.focus();
					silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_edit(input_supplier_id.id);

				}

				silicon_ioi.ioiCommon.select_supplier(fields, fields_len, fields_desc, where, order_by, fct_callback);
			}

			let input_supplier_el = document.getElementById('item_price_frame_purchases_price_grid_detail_input_supplier_id_' + i.toString())
			document.getElementById('item_price_frame_purchases_price_grid_detail_input_supplier_button_' + i.toString()).onclick = fct_supplier_button;
			input_supplier_el.onkeydown = fct_keydown;
			input_supplier_el.onkeyup = fct_keyup;


			// Purchases Condition
			let fct_purchases_condition_button = function() {

				let s = this.id;

				while (s.indexOf('_') != -1) {
					s = s.substring(s.indexOf('_')+1, s.length);
				}

				let title = __("Select a purchases condition");
				let form_width_pixel = '710px';
				let table = "ioi Purchases Condition";
				let fields = 'name, description';
				let fields_len = '250, 380';
				let fields_desc = 'Identification, Description';
				let where = "enabled = 1 and name <> '" + silicon_ioi.doctype.ioiItemPriceFrame.manual_purchases_condition_id.replaceAll("'", "''") + "' ";
				let order_by = 'name asc';

				let purchases_condition_id = document.getElementById('item_price_frame_purchases_price_grid_detail_input_purchases_condition_id_' + s);
				let fct_callback = function (return_value) {

					purchases_condition_id.value = return_value;
					purchases_condition_id.focus();
					silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_edit(document.getElementById('item_price_frame_purchases_price_grid_detail_input_purchases_condition_id_' + s).id);
				}

				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);
			}
			let purchases_condition_el = document.getElementById('item_price_frame_purchases_price_grid_detail_input_purchases_condition_id_' + i.toString());

			document.getElementById('item_price_frame_purchases_price_grid_detail_input_purchases_condition_button_' + i.toString()).onclick = fct_purchases_condition_button;
			purchases_condition_el.onkeydown = fct_keydown;
			purchases_condition_el.onkeyup = fct_keyup;

			// Unit
			let fct_unit_button = function() {

				let s = this.id;

				while (s.indexOf('_') != -1) {
					s = s.substring(s.indexOf('_')+1, s.length);
				}

				let title = __("Select an Unit");
				let form_width_pixel = '710px';
				let table = "ioi Item Unit";
				let fields = 'name, description';
				let fields_len = '250, 380';
				let fields_desc = 'Identification, Description';
				let where = 'enabled = 1';
				let order_by = 'name asc';

				let input_unit_id = document.getElementById('item_price_frame_purchases_price_grid_detail_input_unit_id_' + s);
				let fct_callback = function (return_value) {


					input_unit_id.value = return_value;
					input_unit_id.focus();
					silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_edit(input_unit_id.id);
				}

				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

			}
			let input_unit_id_el = document.getElementById('item_price_frame_purchases_price_grid_detail_input_unit_id_' + i.toString());

			document.getElementById('item_price_frame_purchases_price_grid_detail_input_unit_button_' + i.toString()).onclick = fct_unit_button;
			input_unit_id_el.onkeydown = fct_keydown;
			input_unit_id_el.onkeyup = fct_keyup;


			// Currency
			let fct_currency_button = function() {

				let s = this.id;

				while (s.indexOf('_') != -1) {
					s = s.substring(s.indexOf('_')+1, s.length);
				}

				let title = __("Select a currency");
				let form_width_pixel = '710px';
				let table = "ioi Currency";
				let fields = 'name, description';
				let fields_len = '250, 380';
				let fields_desc = 'Identification, Description';
				let where = '';
				let order_by = 'name asc';

				let input_currency_id = document.getElementById('item_price_frame_purchases_price_grid_detail_input_currency_id_' + s);
				let fct_callback = function (return_value) {

					input_currency_id.value = return_value;
					input_currency_id.focus();
					silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_edit(input_currency_id.id);
				}

				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);
			}
			let input_currency_id_el = document.getElementById('item_price_frame_purchases_price_grid_detail_input_currency_id_' + i.toString());

			document.getElementById('item_price_frame_purchases_price_grid_detail_input_currency_button_' + i.toString()).onclick = fct_currency_button;
			input_currency_id_el.onkeydown = fct_keydown;
			input_currency_id_el.onkeyup = fct_keyup;



			// Site
			let fct_site_button = function() {

				let s = this.id;

				while (s.indexOf('_') != -1) {
					s = s.substring(s.indexOf('_')+1, s.length);
				}

				let title = __("Select a site");
				let form_width_pixel = '710px';
				let table = "ioi Site";
				let fields = 'name, description';
				let fields_len = '250, 380';
				let fields_desc = 'Identification, Description';
				let where = '';
				let order_by = 'name asc';

				let input_site_id =document.getElementById('item_price_frame_purchases_price_grid_detail_input_site_id_' + s);
				let fct_callback = function (return_value) {

					input_site_id.value = return_value;
					input_site_id.focus();
					silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_edit(input_site_id.id);
				}

				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);
			}

			let input_site_id_el =document.getElementById('item_price_frame_purchases_price_grid_detail_input_site_id_' + i.toString());
			document.getElementById('item_price_frame_purchases_price_grid_detail_input_site_button_' + i.toString()).onclick = fct_site_button;
			input_site_id_el.onkeydown = fct_keydown;
			input_site_id_el.onkeyup = fct_keyup;


			silicon_ioi.doctype.ioiItemPriceFrame.assign_purchases_row_events(i, fct_edit_value, fct_keydown, fct_keyup);

			for (var j = 0; j < grid_detail.rows[0].cells.length; j++) {

				if (silicon_ioi.doctype.ioiItemPriceFrame.is_dark_mode == 0) {
					grid_detail.rows[i].cells[j].style.backgroundColor = '#b1fcd9';
				}else{
					grid_detail.rows[i].cells[j].style.backgroundColor = 'green';
				}
			}

			silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_row_click(document.getElementById('item_price_frame_purchases_price_grid_detail').rows[i].id);
			document.getElementById('item_purchases_price_frame_content').scrollTop = document.getElementById('item_purchases_price_frame_content').scrollHeight;
		});
	}

	static purchases_price_delete()
	{
		if (!silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_grid_line_selected()) {
			frappe.msgprint({title: __("Message"), message: __('No purchases price line selected'), indicator: "red"});
			return 0;
		}

		for (var i = 0; i < parseInt(document.getElementById('item_price_frame_purchases_price_nb_rows').value); i++) {

			if (document.getElementById('item_price_frame_purchases_price_grid_detail_checked_id_' + i.toString()))	{

				if (document.getElementById('item_price_frame_purchases_price_grid_detail_checked_id_' + i.toString()).checked) {

					if (document.getElementById('item_price_frame_purchases_price_grid_detail_document_type_' + i.toString()).value == 'CONTRACT') {

						frappe.msgprint({title: __("Message"), message: __('This purchases price linked to a contract can not be deleted'), indicator: "red"});
						return 0;

					}
				}
			}
		}

		let top = 10;

		let s = '<font color="blue">' + __('Are you sure to delete this purchases price ?') + '</font>';
		let msg = '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';

		top += 20;
		msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';


		frappe.confirm(	msg,
						() => 	{
									document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;
									silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_do_delete();
								},
						() => 	{
								}
		);
	}

	static purchases_price_do_delete()
	{
		if (document.getElementById('item_price_frame_purchases_price_selected_name').value != '') {

			let method = silicon_ioi.doctype.ioiItemPriceFrame.path_purchases_price + '.ioi_purchases_prices_delete';

			frappe.call({  	method: method,
							args: { "name": document.getElementById('item_price_frame_purchases_price_selected_name').value
							},
							async: false,
							callback:function(r)	{
								silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_get_result();
							}
			});
		}

	}


	static purchases_price_save()
	{
		if (!silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_grid_line_selected()) {
			frappe.msgprint({title: __("Message"), message: __('No purchases price line selected'), indicator: "red"});
			return 0;
		}

		if (document.getElementById('item_price_frame_purchases_price_grid_detail')) {

			let selected_names = '';
			let name_to_duplicate = '';


			let selected_suppliers = '';
			let selected_purchases_conditions = '';
			let selected_units = '';
			let selected_currencies = '';
			let selected_sites = '';

			let selected_enabled = '';
			let selected_prefered = '';

			let selected_unit_prices = '';
			let selected_fixed_prices = '';
			let selected_base_discounts = '';
			let selected_extra_discounts = '';
			let selected_discounts_in_value = '';
			let selected_start_dates = '';
			let selected_end_dates = '';
			let selected_min_order_qties = '';
			let selected_multiple_order_qties = '';
			let selected_delivery_delays = '';
			let selected_external_references = '';

			for (var i = 0; i < parseInt(document.getElementById('item_price_frame_purchases_price_nb_rows').value); i++) {
				let new_record_el = document.getElementById('item_price_frame_purchases_price_grid_detail_new_record_' + i.toString());
				if (new_record_el) {

					let save_value = false;


					if (new_record_el.value == 'N') {

						// Update
						let record_modified = document.getElementById('item_price_frame_purchases_price_grid_detail_record_modified_' + i.toString());

						if (record_modified) {

							if (record_modified.value == 'Y') {

								selected_names += document.getElementById('item_price_frame_purchases_price_grid_detail_name_' + i.toString()).value + '#sbsepa#';

								save_value = true;
							}
						}
					}else{

						// Insert (only one insert at a time)

						let input_supplier_id = document.getElementById('item_price_frame_purchases_price_grid_detail_input_supplier_id_' + i.toString());

						if (input_supplier_id.value.trim() == '') {

							frappe.msgprint({title: __("Message"), message: __('Line') + ' ' + (i+1).toString() + ' : ' + __('Supplier is mandatory'), indicator: "red"});
							return 0;
						}

						let purchases_condition_id = document.getElementById('item_price_frame_purchases_price_grid_detail_input_purchases_condition_id_' + i.toString());

						if (purchases_condition_id.value.trim() == '') {
							frappe.msgprint({title: __("Message"), message: __('Line') + ' ' + (i+1).toString() + ' : ' + __('Purchases condition is mandatory'), indicator: "red"});
							return 0;
						}

						if (purchases_condition_id.value.toUpperCase() == silicon_ioi.doctype.ioiItemPriceFrame.manual_purchases_condition_id) {
							frappe.msgprint({title: __("Message"), message: __('Line') + ' ' + (i+1).toString() + ' : ' + __('Purchases condition can not be') + ' ' + silicon_ioi.doctype.ioiItemPriceFrame.manual_purchases_condition_id, indicator: "red"});
							return 0;
						}


						let input_unit_id = document.getElementById('item_price_frame_purchases_price_grid_detail_input_unit_id_' + i.toString());
						if (input_unit_id.value.trim() == '') {
							frappe.msgprint({title: __("Message"), message: __('Line') + ' ' + (i+1).toString() + ' : ' + __('Unit is mandatory'), indicator: "red"});
							return 0;
						}

						let input_currency_id = document.getElementById('item_price_frame_purchases_price_grid_detail_input_currency_id_' + i.toString());

						if (input_currency_id.value.trim() == '') {
							frappe.msgprint({title: __("Message"), message: __('Line') + ' ' + (i+1).toString() + ' : ' + __('Currency is mandatory'), indicator: "red"});
							return 0;
						}


						selected_suppliers += input_supplier_id.value.toUpperCase() + '#sbsepa#';;
						selected_purchases_conditions += purchases_condition_id.value.toUpperCase() + '#sbsepa#';
						selected_units += input_unit_id.value.toUpperCase() + '#sbsepa#';
						selected_currencies += input_currency_id.value.toUpperCase() + '#sbsepa#';
						selected_sites += document.getElementById('item_price_frame_purchases_price_grid_detail_input_site_id_' + i.toString()).value.toUpperCase() + '#sbsepa#';

						name_to_duplicate = document.getElementById('item_price_frame_purchases_price_grid_detail_name_to_duplicate_' + i.toString()).value;

						save_value = true;

					}

					if (save_value) {

						if (document.getElementById('item_price_frame_purchases_price_grid_detail_enabled_' + i.toString()).checked) {
							selected_enabled += '1' + '#sbsepa#';
						}else{
							selected_enabled += '0' + '#sbsepa#';
						}

						if (document.getElementById('item_price_frame_purchases_price_grid_detail_prefered_' + i.toString()).checked) {
							selected_prefered += '1' + '#sbsepa#';
						}else{
							selected_prefered += '0' + '#sbsepa#';
						}


						selected_unit_prices += document.getElementById('item_price_frame_purchases_price_grid_detail_input_unit_price_' + i.toString()).value + '#sbsepa#';
						selected_fixed_prices += document.getElementById('item_price_frame_purchases_price_grid_detail_input_fixed_price_' + i.toString()).value + '#sbsepa#';
						selected_base_discounts += document.getElementById('item_price_frame_purchases_price_grid_detail_input_base_discount_' + i.toString()).value + '#sbsepa#';
						selected_extra_discounts += document.getElementById('item_price_frame_purchases_price_grid_detail_input_extra_discount_' + i.toString()).value + '#sbsepa#';
						selected_discounts_in_value += document.getElementById('item_price_frame_purchases_price_grid_detail_input_discount_in_value_' + i.toString()).value + '#sbsepa#';
						selected_start_dates += document.getElementById('item_price_frame_purchases_price_grid_detail_input_start_date_' + i.toString()).value + '#sbsepa#';
						selected_end_dates += document.getElementById('item_price_frame_purchases_price_grid_detail_input_end_date_' + i.toString()).value + '#sbsepa#';
						selected_min_order_qties += document.getElementById('item_price_frame_purchases_price_grid_detail_input_min_order_qty_' + i.toString()).value + '#sbsepa#';
						selected_multiple_order_qties += document.getElementById('item_price_frame_purchases_price_grid_detail_input_multiple_order_qty_' + i.toString()).value + '#sbsepa#';
						selected_delivery_delays += document.getElementById('item_price_frame_purchases_price_grid_detail_input_delivery_delay_' + i.toString()).value + '#sbsepa#';
						selected_external_references += document.getElementById('item_price_frame_purchases_price_grid_detail_input_external_reference_' + i.toString()).value + '#sbsepa#';
					}
				}

			}

			if ((selected_names.trim() != '') || (selected_suppliers.trim() != '')) {

				if (selected_suppliers.trim() != '') {

					let method = silicon_ioi.doctype.ioiItemPriceFrame.path_purchases_price + '.ioi_purchases_prices_check_pk_values';

					frappe.call({  	method: method,
									args: { "item_id": silicon_ioi.doctype.ioiItemPriceFrame.item_id,

											"selected_suppliers": selected_suppliers,
											"selected_purchases_conditions": selected_purchases_conditions,
											"selected_units": selected_units,
											"selected_currencies": selected_currencies,
											"selected_sites": selected_sites,
									},
									async: false,
									callback:function(r)	{

										if (r.message.error == 1) {
											frappe.msgprint({title: __("Message"), message: r.message.error_msg, indicator: "red"});
											return 0;

										}else{
											silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_do_save(	selected_names, selected_suppliers, selected_purchases_conditions, selected_units, selected_currencies, selected_sites, selected_enabled,
																											selected_prefered, selected_unit_prices, selected_fixed_prices, selected_base_discounts, selected_extra_discounts, selected_discounts_in_value,
																											selected_start_dates, selected_end_dates, selected_min_order_qties, selected_multiple_order_qties, selected_delivery_delays, selected_external_references, name_to_duplicate);
										}
									}
					});

				}else{
					silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_do_save(	selected_names, selected_suppliers, selected_purchases_conditions, selected_units, selected_currencies, selected_sites, selected_enabled,
																					selected_prefered, selected_unit_prices, selected_fixed_prices, selected_base_discounts, selected_extra_discounts, selected_discounts_in_value,
																					selected_start_dates, selected_end_dates, selected_min_order_qties, selected_multiple_order_qties, selected_delivery_delays, selected_external_references, name_to_duplicate);
				}


			}
		}
	}

	static purchases_price_do_save(	selected_names, selected_suppliers, selected_purchases_conditions, selected_units, selected_currencies, selected_sites, selected_enabled,
									selected_prefered, selected_unit_prices, selected_fixed_prices, selected_base_discounts, selected_extra_discounts, selected_discounts_in_value,
									selected_start_dates, selected_end_dates, selected_min_order_qties, selected_multiple_order_qties, selected_delivery_delays, selected_external_references, name_to_duplicate)
	{
		let method = silicon_ioi.doctype.ioiItemPriceFrame.path_purchases_price + '.ioi_purchases_prices_multiple_inserts_updates_for_an_item';

		frappe.call({  	method: method,
						args: { "item_id": silicon_ioi.doctype.ioiItemPriceFrame.item_id,
								"selected_names": selected_names,
								"selected_suppliers": selected_suppliers,
								"selected_purchases_conditions": selected_purchases_conditions,
								"selected_units": selected_units,
								"selected_currencies": selected_currencies,
								"selected_sites": selected_sites,
								"selected_enabled": selected_enabled,
								"selected_prefered": selected_prefered,
								"selected_unit_prices": selected_unit_prices,
								"selected_fixed_prices": selected_fixed_prices,
								"selected_base_discounts": selected_base_discounts,
								"selected_extra_discounts": selected_extra_discounts,
								"selected_discounts_in_value": selected_discounts_in_value,
								"selected_start_dates": selected_start_dates,
								"selected_end_dates": selected_end_dates,
								"selected_min_order_qties": selected_min_order_qties,
								"selected_multiple_order_qties": selected_multiple_order_qties,
								"selected_delivery_delays": selected_delivery_delays,
								"selected_external_references": selected_external_references,
								"name_to_duplicate" : name_to_duplicate


						},
						async: false,
						callback:function(r)	{

							document.getElementById('item_purchases_price_frame_button_new').disabled = false;
							document.getElementById('item_purchases_price_frame_button_duplicate').disabled = false;
							document.getElementById('item_purchases_price_frame_button_delete').disabled = false;

							let current_name = ''
							let nb_rows_el = document.getElementById('item_price_frame_purchases_price_nb_rows');

							if (r.message.new_name != '') {
								current_name = r.message.new_name
							}else{

								for (var i = 0; i < parseInt(nb_rows_el.value); i++) {
									let name_el = document.getElementById('item_price_frame_purchases_price_grid_detail_name_' + i.toString());
									let checked_id_el = document.getElementById('item_price_frame_purchases_price_grid_detail_checked_id_' + i.toString());

									if (checked_id_el)	{

										if (checked_id_el.checked) {
											current_name = name_el.value;
											break;
										}
									}
								}
							}

							silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_get_result();

							silicon_ioi.doctype.ioiItemPriceFrame.sleep_static(500).then(() => {

								let id = '';

								for (var i = 0; i < parseInt(nb_rows_el.value); i++) {
									let name_el = document.getElementById('item_price_frame_purchases_price_grid_detail_name_' + i.toString());

									if (name_el.value == current_name) {
										id = document.getElementById('item_price_frame_purchases_price_grid_detail').rows[i].id
										break;
									}
								}

								if (id != '') {
									silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_row_click(id);
								}

							});
						}
		});

	}

	static purchases_price_cancel()
	{
		document.getElementById('item_purchases_price_frame_button_new').disabled = false;
		document.getElementById('item_purchases_price_frame_button_duplicate').disabled = false;
		document.getElementById('item_purchases_price_frame_button_delete').disabled = false;
		silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_get_result();
	}

	static purchases_price_duplicate()
	{
		if (!silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_grid_line_selected()) {
			frappe.msgprint({title: __("Message"), message: __('No purchases price line selected'), indicator: "red"});
			return 0;
		}

		let top = 10;

		let s = '<font color="blue">' + __('Are you sure to duplicate this purchases price ?') + '</font>';
		let msg = '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';

		top += 20;
		msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';


		frappe.confirm(	msg,
						() => 	{
									document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;
									silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_do_duplicate();
								},
						() => 	{
								}
		);


	}

	static purchases_price_do_duplicate()
	{
		let idx = -1;

		for (var i = 0; i < document.getElementById('item_price_frame_purchases_price_grid_detail').rows.length; i++) {
			let checked_id_el = document.getElementById('item_price_frame_purchases_price_grid_detail_checked_id_' + i.toString());

			if (checked_id_el)	{

				if (checked_id_el.checked) {

					idx = i;
					break;
				}
			}
		}

		if (idx != -1) {

			let name_to_duplicate = document.getElementById('item_price_frame_purchases_price_grid_detail_name_' + idx.toString()).value;
			let supplier_id = document.getElementById('item_price_frame_purchases_price_grid_detail_label_supplier_id_' + idx.toString()).innerText;
			let purchases_condition_id = document.getElementById('item_price_frame_purchases_price_grid_detail_label_purchases_condition_id_' + idx.toString()).innerText;
			let unit_id = document.getElementById('item_price_frame_purchases_price_grid_detail_label_unit_id_' + idx.toString()).innerText;
			let currency_id = document.getElementById('item_price_frame_purchases_price_grid_detail_label_currency_id_' + idx.toString()).innerText;
			let site_id = document.getElementById('item_price_frame_purchases_price_grid_detail_label_site_id_' + idx.toString()).innerText;

			let enabled_el = document.getElementById('item_price_frame_purchases_price_grid_detail_enabled_' + idx.toString());
			let prefered_el = document.getElementById('item_price_frame_purchases_price_grid_detail_prefered_' + idx.toString());

			let input_unit_price_el = document.getElementById('item_price_frame_purchases_price_grid_detail_input_unit_price_' + idx.toString());
			let label_unit_price_el = document.getElementById('item_price_frame_purchases_price_grid_detail_label_unit_price_' + idx.toString());

			let input_fixed_price_el = document.getElementById('item_price_frame_purchases_price_grid_detail_input_fixed_price_' + idx.toString());
			let label_fixed_price_el = document.getElementById('item_price_frame_purchases_price_grid_detail_label_fixed_price_' + idx.toString());

			let input_base_discount_el = document.getElementById('item_price_frame_purchases_price_grid_detail_input_base_discount_' + idx.toString());
			let label_base_discount_el = document.getElementById('item_price_frame_purchases_price_grid_detail_label_base_discount_' + idx.toString());

			let input_extra_discount_el = document.getElementById('item_price_frame_purchases_price_grid_detail_input_extra_discount_' + idx.toString());
			let label_extra_discount_el = document.getElementById('item_price_frame_purchases_price_grid_detail_label_extra_discount_' + idx.toString());

			let input_discount_in_value_el = document.getElementById('item_price_frame_purchases_price_grid_detail_input_discount_in_value_' + idx.toString());
			let label_discount_in_value_el = document.getElementById('item_price_frame_purchases_price_grid_detail_label_discount_in_value_' + idx.toString());

			let input_start_date_el = document.getElementById('item_price_frame_purchases_price_grid_detail_input_start_date_' + idx.toString());
			let label_start_date_el = document.getElementById('item_price_frame_purchases_price_grid_detail_label_start_date_' + idx.toString());

			let input_end_date_el = document.getElementById('item_price_frame_purchases_price_grid_detail_input_end_date_' + idx.toString());
			let label_end_date_el = document.getElementById('item_price_frame_purchases_price_grid_detail_label_end_date_' + idx.toString());

			let input_min_order_qty_el = document.getElementById('item_price_frame_purchases_price_grid_detail_input_min_order_qty_' + idx.toString());
			let label_min_order_qty_el = document.getElementById('item_price_frame_purchases_price_grid_detail_label_min_order_qty_' + idx.toString());

			let input_multiple_order_qty_el = document.getElementById('item_price_frame_purchases_price_grid_detail_input_multiple_order_qty_' + idx.toString());
			let label_multiple_order_qty_el = document.getElementById('item_price_frame_purchases_price_grid_detail_label_multiple_order_qty_' + idx.toString());

			let input_delivery_delay_el = document.getElementById('item_price_frame_purchases_price_grid_detail_input_delivery_delay_' + idx.toString());
			let label_delivery_delay_el = document.getElementById('item_price_frame_purchases_price_grid_detail_label_delivery_delay_' + idx.toString());

			let input_external_reference_el = document.getElementById('item_price_frame_purchases_price_grid_detail_input_external_reference_' + idx.toString());
			let label_external_reference_el = document.getElementById('item_price_frame_purchases_price_grid_detail_label_external_reference_' + idx.toString());

			supplier_id = supplier_id.trim();
			purchases_condition_id = purchases_condition_id.trim();
			unit_id = unit_id.trim();
			currency_id = currency_id.trim();
			site_id = site_id.trim();


			let enabled = 0;
			if (enabled_el.checked) {
				enabled = 1;
			}

			let prefered = 0;
			if (prefered_el.checked) {
				prefered = 1;
			}


			let unit_price = 0;

			if (input_unit_price_el) {
				unit_price = input_unit_price_el.value;
			}else{

				if (label_unit_price_el) {
					unit_price = label_unit_price_el.innerText;
				}
			}

			unit_price = unit_price.toString().replaceAll(' ', '');

			let fixed_price = 0;

			if (input_fixed_price_el) {
				fixed_price = input_fixed_price_el.value;
			}else{

				if (label_fixed_price_el) {
					fixed_price = label_fixed_price_el.innerText;
				}
			}

			fixed_price = fixed_price.toString().replaceAll(' ', '');


			let base_discount = 0;

			if (input_base_discount_el) {
				base_discount = input_base_discount_el.value;
			}else{

				if (label_base_discount_el) {
					base_discount = label_base_discount_el.innerText;
				}
			}

			base_discount = base_discount.toString().replaceAll(' ', '');
			base_discount = base_discount.toString().replaceAll('%', '');


			let extra_discount = 0;

			if (input_extra_discount_el) {
				extra_discount = input_extra_discount_el.value;
			}else{

				if (label_extra_discount_el) {
					extra_discount = label_extra_discount_el.innerText;
				}
			}

			extra_discount = extra_discount.toString().replaceAll(' ', '');
			extra_discount = extra_discount.toString().replaceAll('%', '');


			let discount_in_value = 0;

			if (input_discount_in_value_el) {
				discount_in_value = input_discount_in_value_el.value;
			}else{

				if (label_discount_in_value_el) {
					discount_in_value = label_discount_in_value_el.innerText;
				}
			}

			discount_in_value = discount_in_value.toString().replaceAll(' ', '');


			let start_date = '';

			if (input_start_date_el) {
				start_date = input_start_date_el.value;
			}else{

				if (label_start_date_el) {
					start_date = label_start_date_el.innerText;
				}
			}


			let end_date = '';

			if (input_end_date_el) {
				end_date = input_end_date_el.value;
			}else{

				if (label_end_date_el) {
					end_date = label_end_date_el.innerText;
				}
			}


			let min_order_qty = 0;

			if (input_min_order_qty_el) {
				min_order_qty = input_min_order_qty_el.value;
			}else{

				if (label_min_order_qty_el) {
					min_order_qty = label_min_order_qty_el.innerText;
				}
			}

			min_order_qty = min_order_qty.toString().replaceAll(' ', '');


			let multiple_order_qty = 0;

			if (input_multiple_order_qty_el) {
				multiple_order_qty = input_multiple_order_qty_el.value;
			}else{

				if (label_multiple_order_qty_el) {
					multiple_order_qty = label_multiple_order_qty_el.innerText;
				}
			}


			multiple_order_qty = multiple_order_qty.toString().replaceAll(' ', '');


			let delivery_delay = 0;

			if (input_delivery_delay_el) {
				delivery_delay = input_delivery_delay_el.value;
			}else{

				if (label_delivery_delay_el) {
					delivery_delay = label_delivery_delay_el.innerText;
				}
			}


			delivery_delay = delivery_delay.toString().replaceAll(' ', '');


			let external_reference = '';

			if (input_external_reference_el) {
				external_reference = input_external_reference_el.value;
			}else{

				if (label_external_reference_el) {
					external_reference = label_external_reference_el.innerText;
				}
			}

			silicon_ioi.doctype.ioiItemPriceFrame.purchases_price_new();

			silicon_ioi.doctype.ioiItemPriceFrame.sleep_static(600).then(() => {

				idx = document.getElementById('item_price_frame_purchases_price_grid_detail').rows.length - 1;


				document.getElementById('item_price_frame_purchases_price_grid_detail_name_to_duplicate_' + idx.toString()).value = name_to_duplicate;
				document.getElementById('item_price_frame_purchases_price_grid_detail_input_supplier_id_' + idx.toString()).value = supplier_id;
				document.getElementById('item_price_frame_purchases_price_grid_detail_input_purchases_condition_id_' + idx.toString()).value = purchases_condition_id;
				document.getElementById('item_price_frame_purchases_price_grid_detail_input_unit_id_' + idx.toString()).value = unit_id;
				document.getElementById('item_price_frame_purchases_price_grid_detail_input_currency_id_' + idx.toString()).value = currency_id;
				document.getElementById('item_price_frame_purchases_price_grid_detail_input_site_id_' + idx.toString()).value = site_id;

				enabled_el.checked = false;

				if (enabled == 1) {
					enabled_el.checked = true;
				}

				prefered_el.checked = false;

				if (prefered == 1) {
					prefered_el.checked = true;
				}


				input_unit_price_el.value = unit_price;
				input_fixed_price_el.value = fixed_price;
				input_base_discount_el.value = base_discount;
				input_extra_discount_el.value = extra_discount;
				input_discount_in_value_el.value = discount_in_value;
				input_start_date_el.value = start_date;
				input_end_date_el.value = end_date;
				input_min_order_qty_el.value = min_order_qty;
				input_multiple_order_qty_el.value = multiple_order_qty;
				input_delivery_delay_el.value = delivery_delay;
				input_external_reference_el.value = external_reference;

			});
		}
	}


	static purchases_price_grid_line_selected()
	{
		let base_el = document.getElementById('item_price_frame_purchases_price_grid_detail');
		let ret_val = true;

		if (!base_el) {
			ret_val = false;
		}else {
			if (base_el.rows.length == 0) {
				ret_val = false;
			}else {

				if (base_el.rows.length == 1) {

					if (!document.getElementById('item_price_frame_purchases_price_grid_detail_checked_id_0')) {
						ret_val = false;
					}

				}else{

					let at_least_one_checked = false;

					for (var i = 0; i < parseInt(document.getElementById('item_price_frame_purchases_price_nb_rows').value); i++) {
						let checked_id_el = document.getElementById('item_price_frame_purchases_price_grid_detail_checked_id_' + i.toString());

						if (checked_id_el)	{

							if (checked_id_el.checked) {
								at_least_one_checked = true;
								break;
							}
						}
					}

					if (!at_least_one_checked) {
						ret_val = false;
					}
				}
			}
		}

		return ret_val;
	}





	static sleep_static(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}

}

silicon_ioi.doctype.ioiItemPriceFrame = ioiItemPriceFrame;
