frappe.provide('silicon_ioi.payments');

export class ioiCCV {
	static convert_ccv_status(status) {

		let status_str = "";
		let status_code = "";
		switch (status.toUpperCase()) {
			case 'SUCCESS':
				status_str = __("Accepted");
				status_code = "SUCCEEDED";
				break;
			case 'FAILED':
				status_str = __("Authorization failed");
				status_code = "FAILED";
				break;
			case 'CANCELLED':
				status_str = __("Cancelled");
				status_code = "CANCELLED";
				break;
			default:
				status_str = __("Unknown status");
				status_code = status;
		}
		return { "status_str": status_str, "status_code": status_code }
	}

	static async do_initiate_ccv_payment(amount, payment_debtor_description, payment_bank_reference, doctype, document_name, total_doc_amount, cash_desk_name, author_id) {
		return new Promise((resolve, reject) => {
			let payment_doc = null;
			let interval_id = null;


			frappe.call({
				method: "silicon_ioi.utils.lib.ccv.create_payment",
				args: {
					amount: amount,
					reference: payment_bank_reference,
					description: payment_debtor_description,
					source_doctype: doctype,
					source_document_name: document_name,
					total_doc_amount: total_doc_amount,
					cash_desk_name: cash_desk_name,
					cash_desk_author_id: author_id
				},
				async: false,
				callback: function (r) {
					payment_doc = r.message;

					if (payment_doc.success) {
						let transaction_id = payment_doc.transaction_doc.payment_id;
						let already_show_actions = false;
						frappe.realtime.on(`on_ccv_payment_terminal_status_${transaction_id}`, (data) => {
							if (data?.message){
								if ($("#ccv_terminal_status").length > 0){
									$("#ccv_terminal_status")[0].innerText = data.message;
								}
							}else if (data.actions) {
								if(!already_show_actions){
									already_show_actions = true;
									if (data.actions.includes("askCustomerIdentification")){
										frappe.msgprint(__("Request customer identification"));
									}

									if (data.actions.includes("askMerchantSignature")){
										frappe.msgprint(__("Request merchant signature"));
									}

									if (data.actions.includes("askCustomerSignature")){
										frappe.msgprint(__("Request customer signature"));
									}
								}
							}
						});

						let html = `
						<style>
							.ioiloading {
								position: relative;
								font-size: 15px;
								font-weight: 500;
								left: 0;
								right: 0;
								top: 50%;
								width: 100px;
								color: #000;
								margin: auto;
								-webkit-transform: translateY(-50%);
								-moz-transform: translateY(-50%);
								-o-transform: translateY(-50%);
								transform: translateY(-50%);
							}
							.ioiloading span {
								position: absolute;
								height: 10px;
								width: 84px;
								top: 10px;
								overflow: hidden;
							}
							.ioiloading span > i {
								position: absolute;
								height: 4px;
								width: 4px;
								border-radius: 50%;
								-webkit-animation: wait 4s infinite;
								-moz-animation: wait 4s infinite;
								-o-animation: wait 4s infinite;
								animation: wait 4s infinite;
							}
							.ioiloading span > i:nth-of-type(1) {
								left: -28px;
								background: #000000;
							}
							.ioiloading span > i:nth-of-type(2) {
								left: -21px;
								-webkit-animation-delay: 0.8s;
								animation-delay: 0.8s;
								background: #7C728C;
							}

							@-webkit-keyframes wait {
								0%   { left: -7px  }
								30%  { left: 52px  }
								60%  { left: 22px  }
								100% { left: 100px }
							}
							@-moz-keyframes wait {
								0%   { left: -7px  }
								30%  { left: 52px  }
								60%  { left: 22px  }
								100% { left: 100px }
							}
							@-o-keyframes wait {
								0%   { left: -7px  }
								30%  { left: 52px  }
								60%  { left: 22px  }
								100% { left: 100px }
							}
							@keyframes wait {
								0%   { left: -7px  }
								30%  { left: 52px  }
								60%  { left: 22px  }
								100% { left: 100px }
							}
						</style>

						<div style="height: 420px">
							<img style="display: block;
										-webkit-user-select: none;
										margin: auto;
										max-width: 500px;
										background-color: var(--grey-700);
										transition: background-color 300ms;"
								src="https://www.ccv.eu/wp-content/uploads/sites/7/2022/09/CCV-Logo-2022.png">
							<div style="margin-top: 50px; width: auto">
								<div style="left:50%">
									<h1 style="color: rgb(237, 81, 76);text-align: center;">${(payment_doc.amount).toFixed(2)} €</h1>
									<p id="ccv_status_id" style="text-align: center;font-weight: 700; font-size: x-large">${__("Please wait")}</p>
									<div class="ioiloading" >
										<span><i></i><i></i></span>
									</div>
									<div style="margin-top: 50px;">
										<p id="ccv_terminal_status" style="text-align: center;"></p>
									</div>
								</div>
							</div>
						</div>

						`
						let dialog = new frappe.ui.Dialog({
							title: __("Electronic payment"),
							static: true,
							size: "extra-large",
							fields: [
								{ 'fieldname': 'html_ccv', 'fieldtype': 'HTML' }
							],
							primary_action: () => {
								frappe.confirm(__("Do you want to cancel this payment?"), async () => {
									frappe.call({
										method: "silicon_ioi.utils.lib.ccv.cancel_payment",
										args: {
											cash_desk_name: cash_desk_name,
											payment_id: payment_doc.transaction_doc.payment_id
										},
										callback: function (r) {
										}
									});
								});
							},
							primary_action_label: "Cancel",
							secondary_action: (e) => {
								dialog.$wrapper.find(".btn-modal-secondary").prop('disabled', true);

								frappe.call({
									method: "silicon_ioi.utils.lib.ccv.update_payment_status",
									args: {
										reference: payment_doc.transaction_doc.payment_id
									},
									callback: function (r) {
										setTimeout(() => {
											dialog.$wrapper.find(".btn-modal-secondary").prop('disabled', false);
										}, 1000);
									}
								});
							},
							secondary_action_label: "Refresh status"
						});
						if ($("#ccv_status_id").length > 0) {
							$("#ccv_status_id").remove()
						};

						if ($("#ccv_terminal_status").length > 0) {
							$("#ccv_terminal_status").remove()
						}

						dialog.set_value("html_ccv", html);
						dialog.show();

						let interval_counter = 300;
						interval_id = setInterval(
							async function () {

								let platform_status = await frappe.db.get_value("ioi Payment Transaction", payment_doc.transaction_doc.name, "platform_status")

								interval_counter--;

								if (interval_counter <= 0 || platform_status.message.platform_status.toUpperCase() != "PENDING") {

									let status_cnv = ioiCCV.convert_ccv_status(platform_status.message.platform_status);
									let generic_status = await frappe.db.get_value("ioi Payment Transaction", payment_doc.transaction_doc.name, "generic_status")
									$("#ccv_status_id")[0].innerText = status_cnv.status_str;
									setTimeout(() => {
										dialog.hide();
										resolve({ status_code: generic_status.message.generic_status, amount: amount, payment_transaction_name: payment_doc.transaction_doc.name });
									}, 2000);
									clearInterval(interval_id);

								} else {
									if (interval_counter == 10) {
										frappe.call({
											method: "silicon_ioi.utils.lib.ccv.cancel_payment",
											args: {
												cash_desk_name: cash_desk_name,
												payment_id: payment_doc.transaction_doc.payment_id
											}
										})
									}
								}
							}, 2000);
					} else {
						frappe.throw(`${__("Unable to make an electronic payment")}.</br>
						${__("Please check if your payment terminal is available")}.</br></br>
						${__("Please try again in a few seconds")}!`);
					}
				},
			});
		});
	}

}

silicon_ioi.payments.ioiCCV = ioiCCV;
