const ioi_pd = require("./ioi_print_dialog.js")


class ioiForm extends frappe.ui.form.Form {
	reportList = [];
	sleep(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	PrintStandardReport() {
		let w = frappe.set_route(`/app/print/${this.doc.doctype}/${this.doc.name}`);

		if (!w) {
			frappe.msgprint(__('Please enable pop-ups'));
			return;
		}
	}

	btnPrintReportClick(e) {
		var idx = this.id.split('_')[1];
		
		if (cur_frm.reportList[idx][1] == 0) {
			cur_frm.PrintReportBroReport(cur_frm.reportList[idx][0])
		} else {
			cur_frm.PrintStandardReport()
		}
	}

	print_doc() {
		window["std_dialog_func"] = "doctype";
		ioi_pd.ioi_print_dialog();
	}

	scroll_to_field(fieldname, focus = true, highlight = true) {
		let field = this.get_field(fieldname);
		if (!field) return;

		let $el = field.$wrapper;

		// set tab as active
		if (field.tab && !field.tab.is_active()) {
			field.tab.set_active();
		}

		// uncollapse section
		if (field.section?.is_collapsed()) {
			field.section.collapse(false);
		}

		// scroll to input
		frappe.utils.scroll_to($el, true, 15);

		// focus if text field
		if (focus) {
			setTimeout(() => {
				$el.find("input, select, textarea").focus();
			}, 500);
		}

		if (highlight) {
			// highlight control inside field
			let control_element = $el.closest(".frappe-control");
			control_element.addClass("highlight");
			setTimeout(() => {
				control_element.removeClass("highlight");
			}, 2000);
		}

		return true;
	}
}

$(document).ready(() => {
	frappe.ui.form.Form = ioiForm;
})