import "./ioi_todo_popup.html"

export class TodoPopupManager {
	/**
	 * @typedef {import('@fullcalendar/core').TodoApi} TodoApi
	 *
	 * @typedef {TodoApi & { el: HTMLElement }} TodoApiWithElement
	 *
	 * @typedef {object} TodoPopupOptions
	 * @property {string} doctype
	 * @property {string} container
	 * @property {boolean} [fetch]
	 */

	constructor(/** @type {TodoPopupOptions} */ options) {
		const requiredOptions = ["doctype", "container", "resource_todo_dragger"];
		for (const option of requiredOptions) {
			if (!options[option]) {
				console.error(`Calendar View: TodoPopupManager: Option '${option}' is required`);
			}
		}

		this.options = options;
	}

	async setup() {
		await frappe.model.with_doctype(this.options.doctype);
		this.meta = frappe.get_meta(this.options.doctype);
	}

	/** @private */
	getFields() {
		return this.meta.fields
			.filter((df) => {
				if (["color", "allocated_to", "reference_type", "reference_name", "role", "assignment_rule"].includes(df.fieldname)) {
					return false;
				}
				if (df.in_list_view || df.bold) {
					return true;
				}
			})
			.map((df) => ({
				...df,
				reqd: false,
				read_only: true,
				readonly_depends_on: "",
			}));
	}

	addTodoPopup(
		/** @type {TodoApiWithElement} */ el,
	) {
		el.ariaLabel = __("Click to view details");
		el.href = frappe.utils.get_form_link(this.options.doctype, this.options.doc.name);
		// this.showTodoPopup(info);

		el.addEventListener("click", (event) => {
			if (event.metaKey || event.ctrlKey || event.shiftKey || event.altKey || event.button !== 0) {
				return;
			}

			event.preventDefault();
			event.stopPropagation();
			this.toggleTodoPopup(el);
		});

		el.addEventListener("dblclick", () => {
			frappe.router.set_route("Form", this.options.doctype, this.options.doc.name);
			this.hideTodoPopup(el);
		})

		// this.toggleTodoPopup(info); // debug
	}

	removeTodoPopup(
		/** @type {TodoApiWithElement} */ el,
	) {
		this.hideTodoPopup(el);
	}

	/** @private */
	toggleTodoPopup(
		/** @type {TodoApiWithElement} */ el,
	) {
		if (TodoPopupManager.current) {
			if (TodoPopupManager.current !== el) {
				this.hideTodoPopup(TodoPopupManager.current);
			}
			TodoPopupManager.current = null;
		}
		if (el._todoPopup) {
			this.hideTodoPopup(el);
		} else {
			this.showTodoPopup(el);
		}
	}

	/** @private */
	async showTodoPopup(
		/** @type {TodoApiWithElement} */ el,
	) {
		if (!el._todoPopup) {
			await this.buildTodoPopup(el);
			el._todoPopup = true;
		}
		$(el).popover("show");
		TodoPopupManager.current = el;
	}

	/** @private */
	hideTodoPopup(
		/** @type {TodoApiWithElement} */ el,
	) {
		if (el._todoPopup) {
			$(el).popover("hide");
			el._todoPopup = null;
		}
	}

	/** @private */
	async buildTodoPopup(
		/** @type {TodoApiWithElement} */ el,
	) {

		const doc = await frappe.model.with_doc(this.options.doctype, this.options.doc.name);

		const body = document.createElement("div");
		body.innerHTML = frappe.render_template("ioi_todo_popup", {});

		const qs = (selector) => {
			const element = body.querySelector(selector);
			if (!element) {
				console.error(`Calendar View: TodoPopupManager: Element '${selector}' not found`);
			}
			return element;
		}

		const buttons = {
			close: qs("[data-action='close']"),
			delete: qs("[data-action='delete']"),
			edit: qs("[data-action='edit']"),
		};

		const elements = {
			subject: qs("[data-key='subject']"),
			timestamp: qs("[data-key='timestamp']"),
			swatch: qs("[data-key='swatch']"),
			description: qs("[data-key='description']"),
			links: qs("[data-key='links']"),
			fields: qs("[data-key='fields']"),
		};

		buttons.close.title = buttons.close.ariaLabel = __("Close");
		buttons.close.addEventListener("click", () => this.hideTodoPopup(el));

		buttons.delete.title = buttons.delete.ariaLabel = __("Delete");

		const resource_todo_dragger = this.options.resource_todo_dragger;

		buttons.delete.addEventListener("click", () => {
			frappe.confirm(
				__("Are you sure you want to delete this ToDo?"),
				() => {
					this.hideTodoPopup(el);
					frappe.model.delete_doc(this.options.doctype, this.options.doc.name, () => resource_todo_dragger.refresh());
				}
			);
		});

		buttons.edit.title = buttons.edit.ariaLabel = __("Edit");
		buttons.edit.addEventListener("click", (e) => {
			if (e.metaKey || e.ctrlKey || e.shiftKey || e.altKey || e.button !== 0) {
				return;
			}
			this.hideTodoPopup(el);
			frappe.set_route("Form", this.options.doctype, this.options.doc.name);
		});
		buttons.edit.href = frappe.utils.get_form_link(this.options.doctype, this.options.doc.name);

		elements.swatch.style.backgroundColor = doc.color;

		elements.timestamp.textContent = frappe.datetime.obj_to_user(doc.date);

		const MAX_LENGTH = 250;
		let desc = doc.description;
		if (desc?.length > MAX_LENGTH) {
			desc = desc?.slice(0, MAX_LENGTH) + "…";
		}
		elements.subject.innerHTML = desc;

		await this.populateAsync(el, elements);

		$(el).popover({
			content: body,
			container: this.options.container,
			boundary: "viewport",
			html: true,
			placement: "auto",
			trigger: "manual",
			template: '<div class="popover evp-popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
		});

		return true;
	}

	/** @private */
	async populateAsync(el, elements) {
		const doc = await frappe.model.with_doc(this.options.doctype, this.options.doc.name);
		await this.populateFields(elements.fields, doc);
		await this.populateLinks(elements.links, doc);
	}

	/** @private */
	async populateLinks(
		/** @type {HTMLElement} */ element,
		/** @type {object} */ doc,
	) {
		const EXCLUDE_LIST = ["User", "Contact"];
		if (doc.reference_type && doc.reference_name && !EXCLUDE_LIST.includes(doc.reference_type)) {
			element.closest(".evp-row").removeAttribute("hidden");
			element.innerHTML = "";
			const ul = document.createElement("ul");
			ul.classList.add("p-0", "pl-4");
			element.appendChild(ul);

			const li = document.createElement("li");
			ul.appendChild(li);

			const anchor = document.createElement("a");
			li.appendChild(anchor);

			const url = frappe.utils.get_form_link(doc.reference_type, doc.reference_name);
			anchor.href = url;
			anchor.textContent = doc.reference_name;
			anchor.title = __("{0}: {1}", [__(doc.reference_type), doc.reference_name]);
			anchor.classList.add("underline");
		}
	}

	/** @private */
	async populateFields(
		/** @type {HTMLElement} */ element,
		/** @type {object} */ doc,
	) {
		for (const df of this.getFields()) {
			const wrapper = document.createElement("div");
			wrapper.classList.add("evp-row", "evp-row--field")
			element.before(wrapper);
			const control = frappe.ui.form.make_control({
				df: df,
				parent: wrapper,
				render_input: true,
				doc: doc,
			});
		}
	}
}
