frappe.provide('silicon_ioi.doctype');

export class ioiParkFrame {
	static this
	static park_id
	static description
	static path

	static build_panel(tab) {
		let panel = '';

		panel += '<div class="d-flex flex-column">';

		panel += '	<div class="d-flex w-100 flex-row flex-wrap mb-2">';

		// Park Search
		panel += `	<div class="mr-2" style="width:280px;">
						<label id="${tab}_search_label" style="">${__("Search")}</label>
						<div class="control-input" style="width: 280px;">
							<input id="${tab}_search" type="text" class="input-with-feedback form-control bold">
						</div>
					</div>`

		// Park status
		panel += `	<div class="mr-2" style="width:170px;">
						<label id="${tab}_status_label" style="">${__("Status")}</label>
						<div class="control-input" style="width: 170px;">
							<select id="${tab}_status" class="input-with-feedback form-control bold">
								<option value=""></option>`

		frappe.call({
			method: 'silicon_ioi.ioi_configuration.doctype.ioi_module_status.ioi_module_status.ioi_module_status_get_list',
			args: {
				"doctype": "ioi Park"
			},
			async: false,
			callback: function (r) {
				for (var i = 0; i < r.message.length; i++) {
					panel += '		<option value="' + r.message[i][2] + '">' + r.message[i][5] + '</option> ';
				}
			}
		});

		panel += `			</select>
						</div>
					</div>`

		// Topn
		panel += `	<div class="mr-2" style="width:100px;">
						<label id="${tab}_topn_label" style="">${__("No records")}</label>
							<div class="control-input" style="width: 90px;">
							<input id="${tab}_topn" type="number" class="input-with-feedback form-control bold" min="1" max="1000" value="100">
						</div>
					</div>`

		panel += '	</div>'

		panel += `	<div class="d-flex w-100 flex-row mb-2 flex-wrap" style="height: 28px;">
						<button id="${tab}_bt_search" title="${__("Search")}" data-label="Search" class="btn btn-xs btn-default ellipsis mr-2" style="width: 116px;" onclick="">${__("Search")}</button>
						<button id="${tab}_bt_clear" title="${__("...")}" data-label="Search" class="btn btn-xs btn-default ellipsis mr-2" style="width: 50px;" onclick="">${__("...")}</button>
						<button id="${tab}_bt_export" title="${__("Export")}" data-label="Export" class="btn btn-xs btn-default ellipsis mr-2" style="width: 100px;" onclick="">${__("Export")}</button>
					</div>`

		panel += `	<input id="${tab}_orderby" type="hidden" value="name">`;
		panel += `	<input id="${tab}_order" type="hidden" value="asc">`;
		panel += '</div>';

		return panel
	}

	static show_park_label(tab_name) {
		if (document.getElementById(`${tab_name}_desc_label`)) {
			document.getElementById(`${tab_name}_desc_label`).remove();
		}

		if (document.getElementById(`${tab_name}_park_label`)) {
			document.getElementById(`${tab_name}_park_label`).remove();
		}

		let html = '';

		let doctype = silicon_ioi.doctype.ioiParkFrame.this.frm.doctype
		if (doctype === 'ioi Customer History') doctype = 'Customer'

		let park_label = `${silicon_ioi.doctype.ioiParkFrame.park_id}`
		if (!!silicon_ioi.doctype.ioiParkFrame.description) park_label += ` - ${silicon_ioi.doctype.ioiParkFrame.description}`

		html += '<div class="d-flex flex-column">';
		html += `	<label id="${tab_name}_desc_label">${__(doctype)}</label>`
		html += `	<label id="${tab_name}_park_label">${park_label}</label>`
		html += '</div>';

		return html
	}

	static build_park() {
		let park_tabs = [
			{ tab_name: 'park', dt: 'ioi Park' },
		]

		park_tabs.map(tab => {
			const container = `
				<div id="html_park_content" class="w-100">
					<div class="d-flex flex-row flex-wrap">
						${silicon_ioi.doctype.ioiParkFrame.build_panel(tab.tab_name)}
						${silicon_ioi.doctype.ioiParkFrame.show_park_label(tab.tab_name)}
					</div>
					<div id="${tab.tab_name}_grid" class="table table-bordered" data-custom-grid="true"></div>
				</div>`

			const park_content = $('#html_park_content')[0]

			if (park_content) park_content.remove()
			silicon_ioi.doctype.ioiParkFrame.this.frm.fields_dict[`html_${tab.tab_name}`].$wrapper.append(container);

			silicon_ioi.doctype.ioiParkFrame.build_park_grid(tab.tab_name, tab.dt)
		})
	}

	static build_park_grid(tab, dt) {
		let format_link = (cell, formatterParams) => {
			if (cell.getValue() != null && cell.getValue() != "") {
				let html = `<span style="cursor: pointer; text-decoration: underline;">${cell.getValue()}</span>`
				return html
			}
		}

		let link_onclick = (e, cell) => {
			if (cell.getValue() != null && cell.getValue() != "") {
				let url = ""
				const field = cell.getColumn().getField()
				const data = cell.getData()

				if (field == "name") {
					let name = data.name
					url = '/app/ioi-park/' + name
				}

				window.open(url, "_blank")
			}
		}

		let get_row_bg_color = (row) => {
			let data = row.getData();

			if (data.ioistatus_bgcolor != null && data.ioistatus_bgcolor != "") {
				if (data.ioistatus_bgcolor != "#FFFFFF") {
					row.getCell("name").getElement().style.backgroundColor = data.ioistatus_bgcolor;
					row.getCell("name").getElement().style.color = "black";
					row.getCell("ioistatus_description").getElement().style.backgroundColor = data.ioistatus_bgcolor;
					row.getCell("ioistatus_description").getElement().style.color = "black";
				}
			}

			if (data.ioiuserstatus_bgcolor != null && data.ioiuserstatus_bgcolor != "") {
				if (data.ioiuserstatus_bgcolor != "#FFFFFF") {
					row.getCell("ioiuserstatus_description").getElement().style.backgroundColor = data.ioiuserstatus_bgcolor;
					row.getCell("ioiuserstatus_description").getElement().style.color = "black";
				}
			}
		}

		let format_accessor = (value, data, type, params, column) => {
			let format_value = ""

			if (value != null) {
				if (value != 0) {
					format_value = value
				}
			}

			return format_value
		}

		let columns = [
			{ title: __("Park"), field: "name", formatter: format_link, cellClick: link_onclick },
			{ title: __("Status"), field: "ioistatus_description" },
			{ title: __("User status"), field: "ioiuserstatus_description" },
			{ title: __("Serial number"), field: "code_ref" },
			{ title: __("Batch S/N"), field: "batch_sn_id" },
			{ title: __("Item"), field: "item_id" },
			{ title: __("External ref"), field: "external_ref" },
			{ title: __("Description"), field: "description" },
			{ title: __("Purchased on"), field: "purchased_on" },
			{ title: __("Ready on"), field: "ready_on" },
			{ title: __("In service on"), field: "in_service_on" },
			{ title: __("Hold on"), field: "hold_on" },
			{ title: __("Out of service on"), field: "out_of_service_on" },
			{ title: __("Sold on"), field: "sold_on" },
			{ title: __("Manuf. ref."), field: "manufacturer_ref" },
		]

		if (silicon_ioi.doctype.ioiParkFrame.this.frm.doctype.includes('ioi Customer')) {
			columns.splice(6, 0, { title: __("Supplier"), field: "supplier_id" })
		}

		if (silicon_ioi.doctype.ioiParkFrame.this.frm.doctype.includes('ioi Supplier')) {
			columns.splice(6, 0, { title: __("Customer"), field: "customer_id" })
		}

		let table = new ioi.Tabulator(`#${tab}_grid`, {
			maxHeight: 800,
			data: [],
			layout: "fitColumns",
			selectableRows: false,
			movableColumns: true,
			resizableColumns: true,
			showProfiles: true,
			autoRedraw: true,
			initialSort: [
				{ column: "name", dir: "asc" },
			],
			rowFormatter: function (row) {
				get_row_bg_color(row)
			},
			columnDefaults: {
				minWidth: 100,
				accessorDownload: format_accessor,
			},
			columns: columns
		});

		table.on("headerClick", function (e, column) {
			let ordered_field = document.getElementById(`${tab}_orderby`)
			ordered_field.value = column.getField()

			let ordered_dir = document.getElementById(`${tab}_order`)
			ordered_dir.value = table.getSorters()[0].dir

			silicon_ioi.doctype.ioiParkFrame.col_click(tab)
		});

		silicon_ioi.doctype.ioiParkFrame.park_grid_search(table, dt, tab)

		document.getElementById(`${tab}_bt_search`).onclick = () => silicon_ioi.doctype.ioiParkFrame.park_grid_search(table, dt, tab)
		document.getElementById(`${tab}_bt_export`).onclick = () => silicon_ioi.doctype.ioiParkFrame.export_data(table, tab)
		document.getElementById(`${tab}_bt_clear`).onclick = () => {
			document.getElementById(`${tab}_search`).value = ''
			document.getElementById(`${tab}_topn`).value = 100
			table.clearData()
		}
		document.getElementById(`${tab}_status`).onchange = () => silicon_ioi.doctype.ioiParkFrame.park_grid_search(table, dt, tab)

		document.getElementById(`${tab}_search`).addEventListener("keypress", function(event) {
			if (event.key === "Enter") {
				event.preventDefault();
				silicon_ioi.doctype.ioiParkFrame.park_grid_search(table, dt, tab)
			}
		});

		document.getElementById(`${tab}_topn`).addEventListener("keypress", function(event) {
			if (event.key === "Enter") {
				event.preventDefault();
				silicon_ioi.doctype.ioiParkFrame.park_grid_search(table, dt, tab)
			}
		});
	}

	static col_click(tab) {
		if (document.getElementById(`${tab}_orderby`).value == 'name') {
			document.getElementById(`${tab}_orderby`).value = 'a.name';
		}

		document.getElementById(`${tab}_bt_search`).click();
	}

	static park_grid_search(table, dt, tab) {

		frappe.call({
			method: silicon_ioi.doctype.ioiParkFrame.path,
			args: {
				doctype: dt,
				park_id: silicon_ioi.doctype.ioiParkFrame.park_id,
				search: document.getElementById(`${tab}_search`).value,
				topn: document.getElementById(`${tab}_topn`).value,
				orderby: document.getElementById(`${tab}_orderby`).value,
				order: document.getElementById(`${tab}_order`).value,
				ioistatus: document.getElementById(`${tab}_status`).value,
			},
			async: false,
			callback: function (r) {
				if (r.message.length > 0) {
					if (table.initialized) {
						table.setData(r.message)
					} else {
						table.on('tableBuilt', () => table.setData(r.message))
					}
				} else {
					if (table && table.initialized) table.clearData()
				}
			}
		})
	}

	static export_data(table, default_filename)
	{
		let export_tabulator = () => {
			let filename = document.getElementById('export_filename').value;
			let filetype = document.getElementById('export_file_type').value;

			if (filename.trim() == '') {
				filename = default_filename;
			}

			if (filetype.toUpperCase() == 'XLSX') {
				table.download("xlsx", `${filename}.xlsx`);
			} else {
				table.download("csv", `${filename}.csv`, { delimiter: ";" })
			}
		}

 		silicon_ioi.ioiCommon.export_form(export_tabulator, true);
	}
}

silicon_ioi.doctype.ioiParkFrame = ioiParkFrame;