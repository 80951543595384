import { ElementScrollController } from "@fullcalendar/core/internal";

frappe.provide('silicon_ioi');

export class ioiDocTypeGrid
{
	path_common = 'silicon_ioi.common.common';
	path_general_settings = 'silicon_ioi.ioi_configuration.doctype.ioi_general_settings.ioi_general_settings';
	path_module_status = 'silicon_ioi.ioi_configuration.doctype.ioi_module_status.ioi_module_status';
	path_module_constraint = 'silicon_ioi.ioi_configuration.doctype.ioi_module_constraint.ioi_module_constraint';
	path_module_user_status = 'silicon_ioi.ioi_configuration.doctype.ioi_module_user_status.ioi_module_user_status';

	is_dark_mode = 0;
	language_1 = '';
	language_2 = '';
	language_3 = '';
	language_4 = '';


	current_doctype = null;
	div_container = null;
	current_name = null;
	doctype_reference = null;


	table_parameters = {};

	record_state = '';
	ioistatus = [];
	ioiuserstatus = [];
	selected_row_index = -1;
	deleted_rows = [];
	images_uploaded_path = [];
	files_uploaded_path = [];

	doctype = '';
	display_fields = [];
	display_custom_fields = false;
	primary_fields_not_name = [];
	exclude_fields = [];
	change_title = [];
	visible_hidden_columns = [];
	hide_visible_columns = [];
	readonly_fields = [];
	display_buttons_when_readonly = [];
	columns_width = [];
	frozen_columns = [];
	frozen_columns_editable = [];
	default_values = [];
	format_numbers = [];
	replace_fields = [];
	jump_fields = [];

	show_data = true;
	can_read = false;
	can_modify = false;

	allow_change_status = false;
	allow_change_userstatus = false;

	language_columns = [];
	language_memo_columns = [];

	show_attach_image_path = [];
	images_properties = [];

	ioiTable = null;
	columns = [];
	data = [];

	Format_Cell = null;

	ItemWindowParams = null;
	CustomerWindowParams = null;
	SupplierWindowParams = null;
	ManufacturerCatalogWindowParams = null;
	DoctypeWindowParams = null;
	Cell_Callback = null;
	CellButton_Callback = null;
	Memo_Callback = null;
	Duration_Callback = null;
	MultiSelect_Callback = null;
	Button_Callback = null;
	RecordChangeState = null;

    load_config()
	{
		let me = this;

		me.is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

		me.language_1 = '';
		me.language_2 = '';
		me.language_3 = '';
		me.language_4 = '';

		let method = me.path_general_settings + '.ioi_general_settings_get_data';

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{
							me.language_1 = r.message.language_1;
							me.language_2 = r.message.language_2;
							me.language_3 = r.message.language_3;
							me.language_4 = r.message.language_4;
						}
		});

		me.current_doctype = cur_frm.doctype;
		me.current_name =  null;

		if (cur_frm.doc.name) {
			me.current_name = cur_frm.doc.name;
		}

	}

	constructor(div_container, table_parameters = null, extended_parameters = null,	methods = null, data = null)
	{
		let me = this;

		me.load_config();

		me.table_parameters = {};

		if (table_parameters != null) {
			me.table_parameters = table_parameters;
		}

		me.table_parameters["showProfiles"] = "custom";

		me.table_parameters['index'] = 'tabulator_row_index';
		me.selected_row_index = -1;

		me.div_container = div_container

		me.ioiTable = new ioi.Tabulator(div_container, me.table_parameters);

		me.ioiTable.on("rowClick", function(e, row){
			me.selected_row_index = row.getData().tabulator_row_index;
		});

		me.deleted_rows = [];
		me.images_uploaded_path = [];
		me.files_uploaded_path = [];


		if ((!extended_parameters.doctype) || ((extended_parameters.doctype) && (extended_parameters.doctype.trim() == ''))) {

			frappe.msgprint({title: __("Message"), message: __("doctype is mandatory"), indicator: "red"});
			return false;
		}

		me.doctype = extended_parameters.doctype;

		if ((!extended_parameters.doctype_reference) || ((extended_parameters.doctype_reference) && (extended_parameters.doctype_reference.trim() == ''))) {
			frappe.msgprint({title: __("Message"), message: __("doctype_reference is mandatory"), indicator: "red"});
			return false;
		}

		me.doctype_reference = extended_parameters.doctype_reference;

		me.display_fields = [];

		if (extended_parameters.display_fields) {
			me.display_fields = extended_parameters.display_fields;
		}

		me.display_custom_fields = false;

		if (extended_parameters.display_custom_fields) {
			me.display_custom_fields = extended_parameters.display_custom_fields;
		}

		me.primary_fields_not_name = [];

		if (extended_parameters.primary_fields_not_name) {
			me.primary_fields_not_name = extended_parameters.primary_fields_not_name;
		}

		me.exclude_fields = [];

		if (extended_parameters.exclude_fields) {
			me.exclude_fields = extended_parameters.exclude_fields;
		}

		me.change_title = [];

		if (extended_parameters.change_title) {
			me.change_title = extended_parameters.change_title;
		}

		me.visible_hidden_columns = [];

		if (extended_parameters.visible_hidden_columns) {
			me.visible_hidden_columns = extended_parameters.visible_hidden_columns;
		}

		me.hide_visible_columns = [];

		if (extended_parameters.hide_visible_columns) {
			me.hide_visible_columns = extended_parameters.hide_visible_columns;
		}

		me.readonly_fields = [];

		if (extended_parameters.readonly_fields) {
			me.readonly_fields = extended_parameters.readonly_fields;
		}

		me.display_buttons_when_readonly = [];

		if (extended_parameters.display_buttons_when_readonly) {
			me.display_buttons_when_readonly = extended_parameters.display_buttons_when_readonly;
		}

		me.columns_width = [];

		if (extended_parameters.columns_width) {
			me.columns_width = extended_parameters.columns_width;
		}

		me.frozen_columns = [];

		if (extended_parameters.frozen_columns) {
			me.frozen_columns = extended_parameters.frozen_columns;
		}

		me.frozen_columns_editable = false;

		if (extended_parameters.frozen_columns_editable) {
			me.frozen_columns_editable = extended_parameters.frozen_columns_editable;
		}

		me.select_values = [];

		if (extended_parameters.select_values) {
			me.select_values = extended_parameters.select_values;
		}

		me.default_values = [];

		if (extended_parameters.default_values) {
			me.default_values = extended_parameters.default_values;
		}

		me.format_numbers = [];

		if (extended_parameters.format_numbers) {
			me.format_numbers = extended_parameters.format_numbers;
		}

		me.underline_frozen = false;

		if (extended_parameters.underline_frozen) {
			me.underline_frozen = extended_parameters.underline_frozen;
		}

		me.replace_fields = [];

		if (extended_parameters.replace_fields) {
			me.replace_fields = extended_parameters.replace_fields;
		}

		me.jump_fields = [];

		if (extended_parameters.jump_fields) {
			me.jump_fields = extended_parameters.jump_fields;
		}

		me.show_data = false;

		if (extended_parameters.show_data) {
			me.show_data = extended_parameters.show_data;
		}

		me.can_read = false;

		if (extended_parameters.can_read) {
			me.can_read = extended_parameters.can_read;
		}

		me.can_modify = false;

		if (extended_parameters.can_modify) {
			me.can_modify = extended_parameters.can_modify;
		}

		me.allow_change_status = false;

		if (extended_parameters.allow_change_status) {
			me.allow_change_status = extended_parameters.allow_change_status;
		}

		me.allow_change_userstatus = false;

		if (extended_parameters.allow_change_userstatus) {
			me.allow_change_userstatus = extended_parameters.allow_change_userstatus;
		}

		me.language_columns = [];

		if (extended_parameters.language_columns) {
			me.language_columns = extended_parameters.language_columns;
		}

		me.language_memo_columns = [];

		if (extended_parameters.language_memo_columns) {
			me.language_memo_columns = extended_parameters.language_memo_columns;
		}

		me.show_attach_image_path = [];

		if (extended_parameters.show_attach_image_path) {
			me.show_attach_image_path = extended_parameters.show_attach_image_path;
		}

		me.images_properties = [];

		if (extended_parameters.images_properties) {
			me.images_properties = extended_parameters.images_properties;
		}

		me.data = [];

		if (data != null) {
			me.data = data;
		}

		me.Format_Cell = null;

		if (methods.format_cell) {
			me.Format_Cell = methods.format_cell;
		}

		me.ItemWindowParams = null;

		if (methods.item_window_params) {
			me.ItemWindowParams = methods.item_window_params;
		}

		me.CustomerWindowParams = null;

		if (methods.customer_window_params) {
			me.CustomerWindowParams = methods.customer_window_params;
		}

		me.SupplierWindowParams = null;

		if (methods.supplier_window_params) {
			me.SupplierWindowParams = methods.supplier_window_params;
		}

		me.ManufacturerCatalogWindowParams = null;

		if (methods.manufacturer_catalog_window_params) {
			me.ManufacturerCatalogWindowParams = methods.manufacturer_catalog_window_params;
		}

		me.DoctypeWindowParams = null;

		if (methods.doctype_window_params) {
			me.DoctypeWindowParams = methods.doctype_window_params;
		}

		me.Cell_Callback = null;

		if (methods.cell_callback) {
			me.Cell_Callback = methods.cell_callback;
		}

		me.CellButton_Callback = null;

		if (methods.cell_button_callback) {
			me.CellButton_Callback = methods.cell_button_callback;
		}

		me.Memo_Callback = null;

		if (methods.memo_callback) {
			me.Memo_Callback = methods.memo_callback;
		}

		me.Duration_Callback = null;

		if (methods.duration_callback) {
			me.Duration_Callback = methods.duration_callback;
		}

		me.MultiSelect_Callback = null;

		if (methods.table_multiselect_callback) {
			me.MultiSelect_Callback = methods.table_multiselect_callback;
		}

		me.Button_Callback = null;

		if (methods.button_callback) {
			me.Button_Callback = methods.button_callback;
		}

		me.Cell_click = null;

		if (methods.cell_click) {
			me.Cell_click = methods.cell_click;
		}

		me.RecordChangeState = null;

		if (methods.record_change_state) {
			me.RecordChangeState = methods.record_change_state;
		}

		me.sleep(200).then(() => {

			me.set_columns();

			if (me.show_data) {

				if ((me.can_read) || (me.can_modify)) {
					me.set_data();
				}
			}
		});
	}

	set_columns()
	{
		let me = this;

		me.columns = [];

		let primary_fields = "";

		if (me.primary_fields_not_name) {

			if (me.primary_fields_not_name.length > 0) {

				for(var i = 0; i < me.primary_fields_not_name.length; i++) {
					primary_fields += "'" + me.primary_fields_not_name[i] + "'";

					if (i < me.primary_fields_not_name.length-1) {
						primary_fields += ",";
					}
				}
			}
		}

		let not_fields = "";

		if (me.exclude_fields) {

			if (me.exclude_fields.length > 0) {

				for(var i = 0; i < me.exclude_fields.length; i++) {
					not_fields += "'" + me.exclude_fields[i] + "'";

					if (i < me.exclude_fields.length-1) {
						not_fields += ",";
					}
				}
			}
		}

		let profile_to_apply = ''


		let tmp_div_container = me.div_container;
		tmp_div_container = tmp_div_container.replaceAll('#', '');


		if (cur_frm) {

			if (frappe.model.user_settings[cur_frm.doctype]) {
				if (frappe.model.user_settings[cur_frm.doctype][tmp_div_container]) {
					if (frappe.model.user_settings[cur_frm.doctype][tmp_div_container].profile_name) {
						profile_to_apply = frappe.model.user_settings[cur_frm.doctype][tmp_div_container].profile_name;
					}
				}
			}

		}


		let profiles_fields = [];

		if (profile_to_apply.trim() != '') {
			let method = me.path_common + '.ioi_doctype_get_profile_fields';

			frappe.call({  	method: method,
							args: {	"name": profile_to_apply
							},
							async: false,
							callback:function(r)	{
								profiles_fields = r.message;
							}
			});

		}


		let method = me.path_common + '.get_doctype_fields_definition';

		frappe.call({  	method: method,
						args: {	"doctype": me.doctype,
								"primary_fields_not_name": primary_fields,
								"exclude_fields": not_fields},
						async: false,
						callback:function(r)	{
							me.columns = r.message;
						}
		});

		me.ioistatus = [];
		me.ioiuserstatus = [];

		if (profiles_fields.length == 0) {

			if (me.columns) {

				if (me.columns.length > 0) {

					for (var j = 0; j < me.columns.length; j++) {

						if (me.columns[j].fieldname.toUpperCase() == 'IOISTATUS') {

							let method = me.path_module_status + '.ioi_module_status_get_list';

							frappe.call({  	method: method,
											args: {"doctype": me.doctype},
											async: false,
											callback:function(r) {

												for (var i = 0; i < r.message.length; i++) {
													me.ioistatus[me.ioistatus.length] = [me.doctype, r.message[i][2], r.message[i][5] + ' ( ' + r.message[i][2] + ' )', r.message[i][3], r.message[i][4]];
												}
											}
							});

						}else if (me.columns[j].fieldname.toUpperCase() == 'IOIUSERSTATUS') {

							let method = me.path_module_user_status + '.ioi_module_user_status_get_list';

							frappe.call({  	method: method,
											args: {"doctype": me.doctype},
											async: false,
											callback:function(r) {

												for (var i = 0; i < r.message.length; i++) {
													me.ioiuserstatus[me.ioiuserstatus.length] = [me.doctype, r.message[i][2], r.message[i][5] + ' ( ' + r.message[i][2] + ' )', r.message[i][3], r.message[i][4]];
												}
											}
							});
						}
					}


					let old_language = [];
					let old_memo_language = [];

					if (me.display_fields.length > 0) {

						for (var i = 0; i < me.display_fields.length; i++) {

							for (var j = 0; j < me.columns.length; j++) {

								if (me.columns[j].fieldname.toUpperCase() == me.display_fields[i].toUpperCase()) {

									if (!me.can_modify) {
										me.set_row_readonly(me.columns[j], old_language, old_memo_language);
									}else{
										me.set_row_editable(me.columns[j], old_language, old_memo_language);
									}
								}
							}
						}

					}else{

						for (var i = 0; i < me.columns.length; i++) {

							if (!me.can_modify) {
								me.set_row_readonly(me.columns[i], old_language, old_memo_language);
							}else{
								me.set_row_editable(me.columns[i], old_language, old_memo_language);
							}
						}
					}

					let column_title = 'Record Modified';
					let column_field = 'tabulator_record_modified';
					let column_visible = false;
					let column_frozen = false;
					let column_hozAlign = true;
					let column_editor = false;
					let column_formatter = null;
					let column_formatterParams = null;

					me.ioiTable.addColumn(
						{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, formatterParams: column_formatterParams, headerSort: false}
					);
				}
			}

		}else{

			if (me.columns) {

				if (me.columns.length > 0) {

					for (var a = 0; a < profiles_fields.length; a++) {

						for (var j = 0; j < me.columns.length; j++) {

							if (me.columns[i].fieldname.toUpperCase() == profiles_fields[a].field.toUpperCase()) {

								if (me.columns[j].fieldname.toUpperCase() == 'IOISTATUS') {

									let method = me.path_module_status + '.ioi_module_status_get_list';

									frappe.call({  	method: method,
													args: {"doctype": me.doctype},
													async: false,
													callback:function(r) {

														for (var i = 0; i < r.message.length; i++) {
															me.ioistatus[me.ioistatus.length] = [me.doctype, r.message[i][2], r.message[i][5] + ' ( ' + r.message[i][2] + ' )', r.message[i][3], r.message[i][4]];
														}
													}
									});

								}else if (me.columns[j].fieldname.toUpperCase() == 'IOIUSERSTATUS') {

									let method = me.path_module_user_status + '.ioi_module_user_status_get_list';

									frappe.call({  	method: method,
													args: {"doctype": me.doctype},
													async: false,
													callback:function(r) {

														for (var i = 0; i < r.message.length; i++) {
															me.ioiuserstatus[me.ioiuserstatus.length] = [me.doctype, r.message[i][2], r.message[i][5] + ' ( ' + r.message[i][2] + ' )', r.message[i][3], r.message[i][4]];
														}
													}
									});
								}
							}
						}
					}


					let old_language = [];
					let old_memo_language = [];

					if (me.display_fields.length > 0) {

						for (var a = 0; a < profiles_fields.length; a++) {

							for (var i = 0; i < me.display_fields.length; i++) {

								if (me.display_fields[i].toUpperCase() == profiles_fields[a].field.toUpperCase()) {

									for (var j = 0; j < me.columns.length; j++) {

										if (me.columns[j].fieldname.toUpperCase() == me.display_fields[i].toUpperCase()) {

											if (!me.can_modify) {
												me.set_row_readonly(me.columns[j], old_language, old_memo_language);
											}else{
												me.set_row_editable(me.columns[j], old_language, old_memo_language);
											}
										}
									}
								}
							}
						}

					}else{

						for (var a = 0; a < profiles_fields.length; a++) {

							for (var i = 0; i < me.columns.length; i++) {

								if (me.columns[i].fieldname.toUpperCase() == profiles_fields[a].field.toUpperCase()) {

									if (!me.can_modify) {
										me.set_row_readonly(me.columns[i], old_language, old_memo_language);
									}else{
										me.set_row_editable(me.columns[i], old_language, old_memo_language);
									}
								}
							}
						}
					}

					let column_title = 'Record Modified';
					let column_field = 'tabulator_record_modified';
					let column_visible = false;
					let column_frozen = false;
					let column_hozAlign = true;
					let column_editor = false;
					let column_formatter = null;
					let column_formatterParams = null;

					me.ioiTable.addColumn(
						{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, formatterParams: column_formatterParams, headerSort: false}
					);
				}
			}
		}


		let table_columns = me.ioiTable.getColumnLayout();

		for (var a = 0; a < profiles_fields.length; a++) {

			for (var i = 0; i < table_columns.length; i++) {

				if (table_columns[i].field.toUpperCase() == profiles_fields[a].field.toUpperCase()) {

					if (!profiles_fields[a].visible) {
						me.ioiTable.hideColumn(table_columns[i].field);
					}else{

						if (profiles_fields[a].width != null) {
							me.ioiTable.updateColumnDefinition(profiles_fields[a].field, {width:profiles_fields[a].width})
						}
					}
				}
			}
		}
	}

	set_row_readonly(col, old_language, old_memo_language)
	{
		let me = this;

		let display_column = false;

		if (me.display_fields.length == 0) {
			display_column = true;
		}else{
			for (var i = 0; i < me.display_fields.length ; i++) {
				if (col.fieldname.toUpperCase() == me.display_fields[i].toUpperCase()) {
					display_column = true;
					break;
				}
			}
		}

		if (me.display_custom_fields) {
			if (col.custom_field == 1) {
				display_column = true;
			}
		}

		if (display_column) {

			let column_title = __(col.label);

			if (me.change_title.length > 0) {

				for (var i = 0; i < me.change_title.length; i++) {

					if (col.fieldname.toUpperCase() == me.change_title[i][0].toUpperCase()) {
						column_title = __(me.change_title[i][1]);
						break;
					}
				}
			}

			let column_field = col.fieldname;


			// ***********************************************************************************************************************************************************************
			// Column description [LG X]
			// ***********************************************************************************************************************************************************************

			let language_column_found = false;

			if (me.language_columns.length > 0) {

				for (var i = 0; i < me.language_columns.length; i++) {

					if (column_field.toUpperCase() == me.language_columns[i].toUpperCase()) {
						language_column_found = true;
					}
				}
			}

			if (language_column_found) {
				column_title = __(me.replace_language_in_description(old_language));
			}

			// ***********************************************************************************************************************************************************************
			// Column memo [LG X]
			// ***********************************************************************************************************************************************************************

			language_column_found = false;

			if (me.language_memo_columns.length > 0) {

				for (var i = 0; i < me.language_memo_columns.length; i++) {

					if (column_field.toUpperCase() == me.language_memo_columns[i].toUpperCase()) {
						language_column_found = true;
					}
				}
			}

			if (language_column_found) {
				column_title = __(me.replace_language_in_memo(old_memo_language));
			}

			// ***********************************************************************************************************************************************************************
			// Column visible
			// ***********************************************************************************************************************************************************************

			let column_visible = true;

			if (col.hidden == 1) {

				column_visible = false;

				if (me.visible_hidden_columns.length > 0) {

					for (var i = 0; i < me.visible_hidden_columns.length; i++) {

						if (col.fieldname.toUpperCase() == me.visible_hidden_columns[i].toUpperCase()) {
							column_visible = true;
							break;
						}
					}
				}

			}else{

				if (me.hide_visible_columns.length > 0) {

					for (var i = 0; i < me.hide_visible_columns.length; i++) {

						if (col.fieldname.toUpperCase() == me.hide_visible_columns[i].toUpperCase()) {
							column_visible = false;
							break;
						}
					}
				}
			}


			// ***********************************************************************************************************************************************************************
			// Column frozen
			// ***********************************************************************************************************************************************************************

			let column_frozen = false;

			if (me.frozen_columns.length > 0) {

				for (var i = 0; i < me.frozen_columns.length; i++) {

					if (col.fieldname.toUpperCase() == me.frozen_columns[i].toUpperCase()) {
						column_frozen = true;
						break;
					}
				}
			}


			// ***********************************************************************************************************************************************************************
			// Column replace
			// ***********************************************************************************************************************************************************************

			if (me.replace_fields.length > 0) {

				for (var i = 0; i < me.replace_fields.length; i++) {

					if (col.fieldname.toUpperCase() == me.replace_fields[i][0].toUpperCase()) {
						column_field = me.replace_fields[i][1];
						break;
					}
				}
			}

			let column_hozAlign = "left";
			let column_editor = false;
			let column_formatter = null;
			let column_formatterParams = null;


			if (column_visible)  {

				if (col.fieldtype.toUpperCase() == 'SELECT') {

					let field_format = (cell, formatterParams) => {

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}


						if ((col.options == null) || ((col.options != null) && (col.options.trim() == ''))) {

							if (cell.getValue() != null) {

								if (me.select_values.length > 0) {

									for (var i = 0; i < me.select_values.length; i++) {

										if (col.fieldname.toUpperCase() == me.select_values[i][0].toUpperCase()) {

											if (cell.getValue() == me.select_values[i][1].toUpperCase()) {
												return me.select_values[i][2];
											}
										}
									}
								}

								return cell.getValue();
							}else{
								return cell.getValue();
							}

						}else{
							return cell.getValue();
						}
					}

					column_formatter = field_format;
					column_editor = null;

				}
			}

			if (col.fieldname.toUpperCase() == 'IOISTATUS') {

				column_hozAlign = "left";

				let ioistatus_formatter = (cell, formatterParams, onRendered) => {

					let input = null;

					if (me.Format_Cell != null) {
						me.Format_Cell(col, cell, input);
					}

					if ((cell.getValue() != null) && (cell.getValue() != '')) {

						if (me.ioistatus.length > 0) {

							for (var i = 0; i < me.ioistatus.length; i++) {

								if (cell.getValue() == me.ioistatus[i][1]) {
									cell.getElement().style.backgroundColor = me.ioistatus[i][3];
									cell.getElement().style.color = me.ioistatus[i][4];
									return me.ioistatus[i][2];
								}
							}
						}

					}
				}

				column_formatter = ioistatus_formatter;


			}else if (col.fieldname.toUpperCase() == 'IOIUSERSTATUS') {

				column_hozAlign = "left";

				let ioiuserstatus_formatter = (cell, formatterParams, onRendered) => {

					let input = null;

					if (me.Format_Cell != null) {
						me.Format_Cell(col, cell, input);
					}

					if ((cell.getValue() != null) && (cell.getValue() != '')) {

						if (me.ioiuserstatus.length > 0) {

							for (var i = 0; i < me.ioiuserstatus.length; i++) {

								if (cell.getValue() == me.ioiuserstatus[i][1]) {
									cell.getElement().style.backgroundColor = me.ioiuserstatus[i][3];
									cell.getElement().style.color = me.ioiuserstatus[i][4];
									return me.ioiuserstatus[i][2];
								}
							}
						}
					}
				}

				column_formatter = ioiuserstatus_formatter;

			}else if (col.fieldtype.toUpperCase() == 'RATING') {

				column_hozAlign = "right";

				let rating_formatter = (cell, formatterParams, onRendered) => {

					let input = null;

					if (me.Format_Cell != null) {
						me.Format_Cell(col, cell, input);
					}

					if ((cell.getValue() != null) && (cell.getValue() != '')) {

						let exp = Math.pow(10, 2);

						let nb = Math.round(parseFloat(cell.getValue() * 5) * exp) / exp;

						if (nb == parseInt(nb)) {
							return parseFloat(nb).toFixed(0);
						}else{
							return parseFloat(nb).toFixed(1);
						}
					}
				}

				column_formatter = rating_formatter;

			}else if ((col.fieldtype.toUpperCase() == 'INT') || (col.fieldtype.toUpperCase() == 'FLOAT') || (col.fieldtype.toUpperCase() == 'PERCENT') || (col.fieldtype.toUpperCase() == 'CURRENCY')) {

				column_hozAlign = "right";

				let set_empty = (cell, formatterParams, onRendered) => {

					if (me.format_numbers.length == 0) {

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}

						if (cell.getValue() != 0) {
							return cell.getValue()
						}
					}else{
						let field_found = false;

						let nb_decimals = 0;
						let hide_if_zero = false;

						for (var i = 0; i < me.format_numbers.length; i++) {

							if (me.format_numbers[i][0].toUpperCase() == cell.getColumn().getField().toUpperCase()) {
								field_found = true;
								nb_decimals = me.format_numbers[i][1];
								hide_if_zero = me.format_numbers[i][2];
								break;
							}
						}

						if (field_found) {
							let input = null;

							if (me.Format_Cell != null) {
								me.Format_Cell(col, cell, input);
							}

							if (hide_if_zero) {

								if (cell.getValue() != 0) {
									let value = 0

									if (col.fieldtype.toUpperCase() != 'INT') {
										value = cell.getValue().toFixed(nb_decimals)
									}else{
										value = cell.getValue()
									}

									return value
								}
							}else{
								let value = 0;

								if (col.fieldtype.toUpperCase() != 'INT') {
									value = cell.getValue().toFixed(nb_decimals);
								}else{
									value = cell.getValue();
								}

								return value
							}

						}else{
							let input = null;

							if (me.Format_Cell != null) {
								me.Format_Cell(col, cell, input);
							}

							if (cell.getValue() != 0) {
								return cell.getValue()
							}

						}

					}
				}

				column_formatter = set_empty;

			}else if (col.fieldtype.toUpperCase() == 'CHECK') {

				column_hozAlign = "center";

				let field_format = (cell, formatterParams) => {
					let input = null;

					if (me.Format_Cell != null) {
						me.Format_Cell(col, cell, input);
					}

					if (cell.getValue() != null) {

						if (cell.getValue() == 1) {
							return "<i class='fa fa-check'></i>"
						}else {
							return "<i class='fa fa-times'></i>";
						}
					}
				}

				column_formatter = field_format;


			}else if (col.fieldtype.toUpperCase() == 'DURATION') {

				let duration_format = (cell, formatterParams) => {

					let input = null;

					if (me.Format_Cell != null) {
						me.Format_Cell(col, cell, input);
					}

					if ((cell.getValue() != null) && (cell.getValue() != '')) {

						let duration = parseInt(cell.getValue());

						let ret = me.duration_convert(duration);

						let days = ret[0];
						let hours = ret[1];
						let minutes = ret[2];
						let seconds = ret[3];

						let s = '';

						if (col.hide_days == 0) {

							if (days != 0) {
								s += days.toString() + __("d") + ' ';
							}
						}

						if (hours != 0) {
							s += hours.toString() + __("h") + ' ';
						}

						if (minutes != 0) {
							s += minutes.toString() + __("m") + ' ';
						}

						if (col.hide_seconds == 0) {

							if (seconds != 0) {
								s += seconds.toString() + __("s") + ' ';
							}
						}

						return s;
					}
				}

				column_formatter = duration_format;

			}else if (col.fieldtype.toUpperCase() == 'COLOR') {

				let color_format = (cell, formatterParams) => {

					let input = null;

					if (cell.getValue() != null) {
						cell.getElement().style.backgroundColor = cell.getValue();
					}else{
						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}
					}
				}

				column_formatter = color_format;

			}else if ((col.fieldtype.toUpperCase() != 'CHECK') && (col.fieldtype.toUpperCase() != 'SELECT')) {

				let field_format = (cell, formatterParams) => {
					let input = null;

					if (me.Format_Cell != null) {
						me.Format_Cell(col, cell, input);
					}

					if (cell.getValue() != null) {
						return cell.getValue()
					}
				}

				column_formatter = field_format;
			}

			if (((col.fieldtype.toUpperCase() == 'LINK') || (col.fieldtype.toUpperCase() == 'DYNAMIC LINK') || (col.fieldname.toUpperCase() == 'NAME')) && (column_visible)) {

				if ((col.options == null) || ((col.options != null) && (col.options.toUpperCase() != 'DOCTYPE'))) {

					let jump_to_module = false;

					if (me.jump_fields.length > 0) {

						for (var i = 0; i < me.jump_fields.length ; i++) {

							if (col.fieldname.toUpperCase() == me.jump_fields[i].toUpperCase()) {

								jump_to_module = true;
								break;
							}
						}
					}

					if (jump_to_module) {

						let underline_link = (cell, formatterParams) => {

							let input = null;

							if (me.Format_Cell != null) {
								me.Format_Cell(col, cell, input);
							}

							if ((cell.getValue() != null) && (cell.getValue() != '')) {
								return '<span style="text-decoration: underline; cursor: pointer;">' + cell.getValue() + '</span>';
							}
						}

						column_formatter = underline_link;
					}
				}
			}

			if ((col.fieldtype.toUpperCase() == 'CODE') || (col.fieldtype.toUpperCase() == 'HTML EDITOR') || (col.fieldtype.toUpperCase() == 'LONG TEXT') || (col.fieldtype.toUpperCase() == 'MARKDOWN EDITOR') ||
				(col.fieldtype.toUpperCase() == 'SMALL TEXT') || (col.fieldtype.toUpperCase() == 'TEXT') || (col.fieldtype.toUpperCase() == 'TEXT EDITOR')) {
				column_editor = false;
			}

			if (col.fieldtype.toUpperCase() == 'ATTACH IMAGE') {

				let image_display = false;

				if (me.show_attach_image_path.length > 0) {

					for (var i = 0; i < me.show_attach_image_path.length; i++) {

						if (me.show_attach_image_path[i].toUpperCase() == col.fieldname.toUpperCase()) {

							image_display = true;
							break;
						}
					}
				}

				if (!image_display) {
					column_visible = false;
				}
			}else if (col.fieldtype.toUpperCase() == 'ATTACH') {

			}else if (col.fieldtype.toUpperCase() == 'TABLE MULTISELECT') {

				if (column_visible) {

					let cell_format = (cell, formatterParams) => {

						let input = null;

						if (me.Format_Cell != null) {
								me.Format_Cell(col, cell, input);
						}

						let html = '';

						if ((col.options != null) && (col.options != '')) {

							let method = me.path_common + '.ioi_doctype_grid_get_multiselect_values';

							frappe.call({  	method: method,
											args: { "doctype": col.options,
													"fieldname": col.fieldname,
													"parent_doctype": me.doctype,
													"parent_pk": cell.getRow().getData()["name"]},
											async: false,
											callback:function(r)	{

												if (r.message.length > 0) {
													for (var i = 0; i < r.message.length; i++) {
														html += r.message[i].fieldname;

														if (i < r.message.length-1) {
															html += ', ';
														}
													}
												}
											}
							});
						}

						return html;

					}

					column_formatter = cell_format;
				}
			}


			if (col.fieldtype.toUpperCase() == 'BUTTON') {

				let display_button = false;

				for (var i = 0; i < me.display_buttons_when_readonly.length; i++) {

					if (me.display_buttons_when_readonly[i].toUpperCase() == col.fieldname.toUpperCase()) {
						display_button = true;
						break;
					}

				}

				if (display_button) {

					column_title = '';
					column_field = 'button_' + column_field;
					column_visible = true;
					column_frozen = false;
					column_hozAlign = true;

					var buttonEditor = (cell, formatterParams, onRendered) => {

						var button = document.createElement("button");
						button.style.width = "100%";
						button.style.height = "100%";
						button.style.border = 'none';
						button.width = "100%";
						button.height = "100%";
						button.innerText = __(col.label);


						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, button);
						}

						var bt_click = function() {

							let bt_fieldname = cell.getColumn().getField();

							let row = cell.getRow();

							row.update({"tabulator_record_modified":"Y"});

							if (me.RecordChangeState) {
								me.RecordChangeState('MODIFIED');
							}

							if (me.Button_Callback != null) {
								me.Button_Callback(bt_fieldname, cell, row, button);
							}
						}

						button.onclick = bt_click;
						return button;
					};

					column_editor = null;
					column_formatter = buttonEditor;
				}
			}


			let cell_onclick = (e, cell) => {

				if ((cell.getValue() != null) && (cell.getValue() != '')) {

					let action_found = false;

					for (var k = 0; k < me.columns.length; k++) {

						if (me.columns[k].fieldtype.toUpperCase() == 'LINK') {

							if (me.columns[k].options.toUpperCase() != 'DOCTYPE') {

								if (me.columns[k].fieldname == cell.getColumn().getField()) {

									let jump_to_module = false;

									if (me.jump_fields.length > 0) {

										for (var i = 0; i < me.jump_fields.length ; i++) {

											if (cell.getColumn().getField().toUpperCase() == me.jump_fields[i].toUpperCase()) {

												jump_to_module = true;
												break;
											}
										}
									}

									if (jump_to_module) {

										let s = me.columns[k].options.toString().toLowerCase();

										s = s.replaceAll(' ', '-');

										let url = '/app/' + s + '/' + cell.getValue();

										action_found = true;

										window.open(url, "_blank");
									}
								}
							}
						}else if (me.columns[k].fieldtype.toUpperCase() == 'DYNAMIC LINK') {

							if (me.columns[k].fieldname.toUpperCase() == cell.getColumn().getField().toUpperCase()) {

								let field_options = me.columns[k].options;

								field_options = cell.getRow().getData()[field_options];

								if (field_options != null) {

									if (field_options.trim() != '') {

										let value = cell.getValue();

										if (value != null) {

											if (value.trim() != '') {

												let s = field_options.toLowerCase();

												s = s.replaceAll(' ', '-');

												let url = '/app/' + s + '/' + value;

												action_found = true;

												window.open(url, "_blank");

											}
										}
									}
								}
							}
						}else if ((me.columns[k].fieldtype.toUpperCase() == 'ATTACH') || (me.columns[k].fieldtype.toUpperCase() == 'ATTACH IMAGE')) {

							if (me.columns[k].fieldname.toUpperCase() == cell.getColumn().getField().toUpperCase()) {

								let url = cell.getValue();

								action_found = true;

								window.open(url, "_blank");
							}

						}else if (me.columns[k].fieldname.toUpperCase() == 'NAME') {

							if (me.columns[k].fieldname.toUpperCase() == cell.getColumn().getField().toUpperCase()) {

								let dc = me.doctype;
								dc = dc.toLowerCase();
								dc = dc.replaceAll(' ', '-')

								let url = '/app/' + dc + '/' + cell.getValue();

								action_found = true;

								window.open(url, "_blank");

							}
						}else{
							if (me.Cell_click != null) {
								me.Cell_click(cell);
							}

						}
					}

					if (!action_found) {
						return false;
					}

				}else{
					return false;
				}
			}

			if (col.fieldtype.toUpperCase() == 'BUTTON') {

				let display_button = false;

				for (var i = 0; i < me.display_buttons_when_readonly.length; i++) {

					if (me.display_buttons_when_readonly[i].toUpperCase() == col.fieldname.toUpperCase()) {
						display_button = true;
						break;
					}

				}

				if (display_button) {
					me.ioiTable.addColumn(
						{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, cellClick: cell_onclick, headerSort: false}
					);
				}
			}else{

				let column_width = null;

				if (me.columns_width.length > 0) {

					for (var i = 0; i < me.columns_width.length; i++) {

						if (me.columns_width[i][0].toUpperCase() == col.fieldname.toUpperCase()) {

							column_width = me.columns_width[i][1];
							break;
						}
					}
				}


				if (column_frozen) {

					let field_format = (cell, formatterParams) => {

						if (me.underline_frozen) {
							return '<u>' + cell.getValue() + '</u>';
						}else{
							return cell.getValue();
						}
					}

					column_formatter = field_format;
					column_editor = null;
				}




				if (column_width != null) {
					me.ioiTable.addColumn(
						{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, formatterParams: column_formatterParams, cellClick: cell_onclick, headerSort: false, width: column_width}
					);
				}else{
					me.ioiTable.addColumn(
						{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, formatterParams: column_formatterParams, cellClick: cell_onclick, headerSort: false}
					);

				}

				if (col.fieldtype.toUpperCase() == 'ATTACH IMAGE') {

					column_title = column_title;
					column_field = 'image_' + column_field;
					column_visible = true;
					column_frozen = false;
					column_hozAlign = "center";
					column_editor = null;
					cell_onclick = null;

					let image_formater = (cell, formatterParams) => {

						let bt_fieldname = cell.getColumn().getField();
						let fieldname = bt_fieldname.substring(6, bt_fieldname.length);

						let row = cell.getRow();

						let html = '';

						if ((row.getData()[fieldname] != null) && (row.getData()[fieldname] != '')) {

							let width = '100px'
							let height = '100px';

							for (var i = 0; i < me.images_properties.length; i++) {

								if (me.images_properties[i][0].toUpperCase() == fieldname.toUpperCase()) {

									if ((me.images_properties[i][1] == null) || (me.images_properties[i][1] == '') || (me.images_properties[i][1] == '0')) {
										width = '100';
									}else{
										width = me.images_properties[i][1];
									}

									if ((width.toString().indexOf('px') == -1) && (width.toString().indexOf('%') == -1)) {
										width += 'px';
									}


									if ((me.images_properties[i][2] == null) || (me.images_properties[i][2] == '') || (me.images_properties[i][2] == '0')) {
										height = '100';
									}else{
										height = me.images_properties[i][2];
									}

									if ((height.toString().indexOf('px') == -1) && (height.toString().indexOf('%') == -1)) {
										height += 'px';
									}

									column_hozAlign = "center";

									if ((me.images_properties[i][3] != null) && (me.images_properties[i][3] != '')) {
										column_hozAlign = me.images_properties[i][3];
									}

									cell.getElement().setAttribute("align", column_hozAlign);
									cell.getElement().style.textAlign = column_hozAlign;

									break;
								}
							}

							html += '<img src="' + row.getData()[fieldname] + '" width="' + width.toString() + '" height="' + height.toString() + '" onclick="window.open(\'' + row.getData()[fieldname] + '\');">';

						}

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}

						return html;

					}

					column_formatter = image_formater;
					column_formatterParams = null;

					me.ioiTable.addColumn(
						{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, formatterParams: column_formatterParams, cellClick: cell_onclick, headerSort: false}
					);


				}else if ((col.fieldtype.toUpperCase() == 'LONG TEXT') || (col.fieldtype.toUpperCase() == 'SMALL TEXT') || (col.fieldtype.toUpperCase() == 'TEXT')) {

					column_title = '';
					column_field = 'button_' + column_field;
					column_visible = true;
					column_frozen = false;
					column_hozAlign = true;

					var buttonEditor = (cell, formatterParams, onRendered) => {

						var button = document.createElement("button");
						button.style.width = "100%";
						button.style.height = "100%";
						button.style.border = 'none';
						button.width = "100%";
						button.height = "100%";
						button.innerText = '...';


						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}


						var bt_click = function() {

							let bt_fieldname = cell.getColumn().getField();
							let fieldname = bt_fieldname.substring(7, bt_fieldname.length);

							let row = cell.getRow();

							var memoDialog = new frappe.ui.Dialog({
								title: __("Memo"),
								'fields': [
									{'fieldname': 'html_ioidoctypegrid_memo_dialog', 'fieldtype': 'HTML'}

								],
								primary_action_label: __("Close"),
								primary_action: function(){

									memoDialog.hide();
								}

							});

							if (document.getElementById('html_ioidoctypegrid_memo_dialog_memo')) {
								document.getElementById('html_ioidoctypegrid_memo_dialog_memo').remove();
							}

							let html = '';

							html += '<div style="overflow: auto; overflow-x: auto; height:210px;">';

							html += '	<div style="position: relative; top: 0px; left: 0px; width:500px;">';
							html += '		<div class="control-input" style="position: absolute; top: 2px; left: 0px; width: 750px; height: 25px;"> ';
							html += '			<textarea id="html_ioidoctypegrid_memo_dialog_memo" style="position: absolute; top: 0px; left: 2px; width: 750px; height: 200px; resize: none;" class="input-with-feedback form-control bold">';
							html += '			</textarea>';
							html += '		</div>';
							html += '	</div>';

							html += '</div>';

							memoDialog.fields_dict.html_ioidoctypegrid_memo_dialog.$wrapper.html(html);
							memoDialog.$wrapper.find('.modal-dialog').css("max-width", "800px").css("width", "800px");
							memoDialog.$wrapper.find('.modal-dialog').css("max-height", "550px").css("height", "550px");

							memoDialog.show();

							me.sleep(200).then(() => {

								document.getElementById('html_ioidoctypegrid_memo_dialog_memo').value = row.getData()[fieldname];
								document.getElementById('html_ioidoctypegrid_memo_dialog_memo').readOnly = true;

							});
						}

						button.onclick = bt_click;
						return button;
					};

					column_editor = null;
					column_formatter = buttonEditor;

					me.ioiTable.addColumn(
						{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, cellClick: cell_onclick, headerSort: false}
					);

				}else if ((col.fieldtype.toUpperCase() == 'CODE') || (col.fieldtype.toUpperCase() == 'HTML EDITOR') || (col.fieldtype.toUpperCase() == 'MARKDOWN EDITOR') || (col.fieldtype.toUpperCase() == 'TEXT EDITOR')) {

					column_title = '';
					column_field = 'button_' + column_field;
					column_visible = true;
					column_frozen = false;
					column_hozAlign = true;

					var buttonEditor = (cell, formatterParams, onRendered) => {

						var button = document.createElement("button");
						button.style.width = "100%";
						button.style.height = "100%";
						button.style.border = 'none';
						button.width = "100%";
						button.height = "100%";
						button.innerText = '...';

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, button);
						}

						var bt_click = function() {

							let bt_fieldname = cell.getColumn().getField();
							let fieldname = bt_fieldname.substring(7, bt_fieldname.length);

							let row = cell.getRow();

							let field_type = '';

							if (col.fieldtype.toUpperCase() == 'CODE') {

								field_type = 'Code';

							}else if (col.fieldtype.toUpperCase() == 'HTML EDITOR') {

								field_type = 'HTML Editor';

							}else if (col.fieldtype.toUpperCase() == 'MARKDOWN EDITOR') {

								field_type = 'Markdown Editor';

							}else if (col.fieldtype.toUpperCase() == 'TEXT EDITOR') {

								field_type = 'Text Editor';
							}


							var memoDialog = new frappe.ui.Dialog({
								title: __("Memo"),

								fields: [{
											fieldname: 'html_ioidoctypegrid_memo_dialog_memo',
											fieldtype: field_type
										}],
								primary_action_label: __("Close"),
								primary_action: function(){

									memoDialog.hide();
								}
							});


							memoDialog.$wrapper.find('.modal-dialog').css("max-width", "800px").css("width", "800px");
							memoDialog.$wrapper.find('.modal-dialog').css("max-height", "550px").css("height", "550px");

							memoDialog.show();

							me.sleep(200).then(() => {

								memoDialog.set_value('html_ioidoctypegrid_memo_dialog_memo', row.getData()[fieldname]);

								memoDialog.set_df_property('html_ioidoctypegrid_memo_dialog_memo', 'read_only', 1);
							});


						}

						button.onclick = bt_click;
						return button;
					};

					column_editor = null;
					column_formatter = buttonEditor;

					me.ioiTable.addColumn(
						{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, cellClick: cell_onclick, headerSort: false}
					);

				}
			}
		}

	}



	set_row_editable(col, old_language, old_memo_language)
	{
		let me = this;

		let display_column = false;

		if (me.display_fields.length == 0) {

			display_column = true;

		}else{

			for (var i = 0; i < me.display_fields.length ; i++) {

				if (col.fieldname.toUpperCase() == me.display_fields[i].toUpperCase()) {
					display_column = true;
					break;
				}
			}
		}

		if (me.display_custom_fields) {

			if (col.custom_field == 1) {
				display_column = true;
			}
		}

		if (display_column) {

			let column_title = __(col.label);

			if (me.change_title.length > 0) {

				for (var i = 0; i < me.change_title.length; i++) {

					if (col.fieldname.toUpperCase() == me.change_title[i][0].toUpperCase()) {

						column_title = me.change_title[i][1];
						break;
					}
				}
			}


			if (col.reqd == 1) {
				column_title += ' *';
			}

			let column_field = col.fieldname;
			let column_readonly = col.read_only;

			if (col.set_only_once == 1) {

				if (me.record_state != 'INSERT') {
					column_readonly = 1;
				}else{
					column_readonly = 0;
				}
			}

			if (me.readonly_fields.length > 0) {

				for (var i = 0; i < me.readonly_fields.length; i++) {

					if (col.fieldname.toUpperCase() == me.readonly_fields[i].toUpperCase()) {

						column_readonly = 1;
						break;
					}
				}
			}


			// ***********************************************************************************************************************************************************************
			// Column description [LG X]
			// ***********************************************************************************************************************************************************************

			let language_column_found = false;

			if (me.language_columns.length > 0) {

				for (var i = 0; i < me.language_columns.length; i++) {

					if (column_field.toUpperCase() == me.language_columns[i].toUpperCase()) {
						language_column_found = true;
					}

				}
			}

			if (language_column_found) {
				column_title = me.replace_language_in_description(old_language);
			}


			// ***********************************************************************************************************************************************************************
			// Column memo [LG X]
			// ***********************************************************************************************************************************************************************

			language_column_found = false;

			if (me.language_memo_columns.length > 0) {

				for (var i = 0; i < me.language_memo_columns.length; i++) {

					if (column_field.toUpperCase() == me.language_memo_columns[i].toUpperCase()) {
						language_column_found = true;
					}

				}
			}

			if (language_column_found) {
				column_title = me.replace_language_in_memo(old_memo_language);
			}


			// ***********************************************************************************************************************************************************************
			// Column visible
			// ***********************************************************************************************************************************************************************

			let column_visible = true;

			if (col.hidden == 1) {

				column_visible = false;

				if (me.visible_hidden_columns.length > 0) {

					for (var i = 0; i < me.visible_hidden_columns.length; i++) {

						if (col.fieldname.toUpperCase() == me.visible_hidden_columns[i].toUpperCase()) {
							column_visible = true;
							break;
						}
					}
				}

			}else{

				if (me.hide_visible_columns.length > 0) {

					for (var i = 0; i < me.hide_visible_columns.length; i++) {

						if (col.fieldname.toUpperCase() == me.hide_visible_columns[i].toUpperCase()) {
							column_visible = false;
							break;
						}
					}
				}
			}


			// ***********************************************************************************************************************************************************************
			// Column frozen
			// ***********************************************************************************************************************************************************************

			let column_frozen = false;

			if (me.frozen_columns.length > 0) {

				for (var i = 0; i < me.frozen_columns.length; i++) {

					if (col.fieldname.toUpperCase() == me.frozen_columns[i].toUpperCase()) {
						column_frozen = true;
						break;
					}
				}
			}


			// ***********************************************************************************************************************************************************************
			// Column editor
			// ***********************************************************************************************************************************************************************

			let column_editor = false;

			if (me.frozen_columns.length > 0) {

				for (var i = 0; i < me.frozen_columns.length; i++) {

					if (col.fieldname.toUpperCase() == me.frozen_columns[i].toUpperCase()) {

						if (me.frozen_columns_editable.length > 0) {

							if (me.frozen_columns_editable[i]) {
								column_editor = true;
							}
						}

						break;
					}else{
						column_editor = true;
					}
				}
			}else{

				if (column_readonly == 0) {
					column_editor = true;
				}
			}

			// ***********************************************************************************************************************************************************************
			// Column horizontal align
			// ***********************************************************************************************************************************************************************

			let column_hozAlign = "left";

			if ((col.fieldtype.toUpperCase() == 'INT') || (col.fieldtype.toUpperCase() == 'FLOAT') || (col.fieldtype.toUpperCase() == 'PERCENT') || (col.fieldtype.toUpperCase() == 'CURRENCY')) {
				column_hozAlign = "right";
			}


			let column_formatter = null;


			if (col.fieldtype.toUpperCase() == 'CHECK') {
				column_hozAlign = "center";
				column_editor = true;
			}

			if (col.fieldtype.toUpperCase() == 'SIGNATURE') {
				column_visible = false;
			}

			if ((col.fieldtype.toUpperCase() == 'PASSWORD') || (col.fieldtype.toUpperCase() == 'PHONE') || (col.fieldtype.toUpperCase() == 'TABLE MULTISELECT')) {
				column_readonly = true;
			}

			if ((column_readonly == 1) || (col.fieldtype.toUpperCase() == 'READ ONLY') || (col.fieldtype.toUpperCase() == 'ATTACH IMAGE') || (col.fieldtype.toUpperCase() == 'ATTACH') || (col.fieldname.toUpperCase() == 'IOISTATUS') ||
				(col.fieldtype.toUpperCase() == 'SIGNATURE') || (col.fieldtype.toUpperCase() == 'PHONE') || (col.fieldtype.toUpperCase() == 'PASSWORD') || (col.fieldtype.toUpperCase() == 'TABLE MULTISELECT')) {
				column_editor = false;
			}


			let column_formatterParams = null;

			let image_visible = false;

			if (column_visible)  {

				if (col.fieldtype.toUpperCase() == 'ATTACH IMAGE') {

					image_visible = true;

					let image_display = false;

					if (me.show_attach_image_path.length > 0) {

						for (var i = 0; i < me.show_attach_image_path.length; i++) {

							if (me.show_attach_image_path[i].toUpperCase() == col.fieldname.toUpperCase()) {
								image_display = true;
								image_visible = true;
								break;
							}

						}
					}

					if (!image_display) {
						column_visible = false;
					}
				}else if (col.fieldtype.toUpperCase() == 'ATTACH') {

				}else if (col.fieldname.toUpperCase() == 'IOISTATUS') {

					column_hozAlign = "left";

					let ioistatus_formatter = (cell, formatterParams, onRendered) => {

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}


						if ((cell.getValue() != null) && (cell.getValue() != '')) {

							if (me.ioistatus.length > 0) {

								for (var i = 0; i < me.ioistatus.length; i++) {

									if (cell.getValue() == me.ioistatus[i][1]) {
										cell.getElement().style.backgroundColor = me.ioistatus[i][3];
										cell.getElement().style.color = me.ioistatus[i][4];
										return me.ioistatus[i][2];
									}
								}
							}

						}
					}

					column_formatter = ioistatus_formatter;


				}else if (col.fieldname.toUpperCase() == 'IOIUSERSTATUS') {

					column_hozAlign = "left";

					let ioiuserstatus_formatter = (cell, formatterParams, onRendered) => {

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}


						if ((cell.getValue() != null) && (cell.getValue() != '')) {

							if (me.ioiuserstatus.length > 0) {

								for (var i = 0; i < me.ioiuserstatus.length; i++) {

									if (cell.getValue() == me.ioiuserstatus[i][1]) {
										cell.getElement().style.backgroundColor = me.ioiuserstatus[i][3];
										cell.getElement().style.color = me.ioiuserstatus[i][4];
										return me.ioiuserstatus[i][2];
									}
								}
							}

						}
					}

					column_formatter = ioiuserstatus_formatter;


				}else if ((col.fieldtype.toUpperCase() == 'SELECT') || (col.fieldtype.toUpperCase() == 'AUTOCOMPLETE')) {

					let field_format = (cell, formatterParams) => {

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}

						if ((col.options == null) || ((col.options != null) && (col.options.trim() == ''))) {

							if (cell.getValue() != null) {

								if (me.select_values.length > 0) {

									for (var i = 0; i < me.select_values.length; i++) {

										if (col.fieldname.toUpperCase() == me.select_values[i][0].toUpperCase()) {

											if (cell.getValue() == me.select_values[i][1].toUpperCase()) {
												return me.select_values[i][2];
											}
										}

									}
								}

								return cell.getValue();
							}else{
								return cell.getValue();
							}

						}else{
							return cell.getValue();
						}
					}

					column_formatter = field_format;

				}else if (col.fieldtype.toUpperCase() == 'RATING') {

					column_hozAlign = "right";

					let rating_formatter = (cell, formatterParams, onRendered) => {

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}


						if ((cell.getValue() != null) && (cell.getValue() != '')) {

							let exp = Math.pow(10, 2);

							let nb = Math.round(parseFloat(cell.getValue() * 5) * exp) / exp;

							if (nb == parseInt(nb)) {
								return parseFloat(nb).toFixed(0);
							}else{
								return parseFloat(nb).toFixed(1);
							}

						}
					}

					column_formatter = rating_formatter;

				}
			}




			if ((column_visible) && (column_readonly != 1)) {

				if (col.fieldtype.toUpperCase() == 'LINK') {

					var linkEditor = (cell, onRendered, success, cancel, editorParams) => {

						var input = document.createElement("input");
						input.type = 'TEXT';
						input.style.width = "100%";
						input.style.height = "100%";
						input.value = cell.getValue();

						if (col.length != 0) {
							input.maxLength = col.length;
						}

						if (col.options != 'DocType') {
							input.style.textTransform = 'uppercase';
						}

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}

						onRendered(function(){

							input.focus();
							input.style.css = "100%";
						});

						function successFunc(){

							if (!input.readOnly) {

								let clear_dynamic_link_value = false;

								let cell_value = '';

								if (cell.getValue() != null) {
									cell_value = cell.getValue();
								}

								if (input.value != cell_value) {

									clear_dynamic_link_value = true;

									let row = cell.getRow();

									let s = '';

									if ((!input.value) || ((input.value) && (input.value.trim() == ''))) {
										s = '{"' + cell.getColumn().getField() + '":""}';
									}else{

										let ret_value = 0;

										let method = me.path_common + '.check_value_in_link';

										frappe.call({  	method: method,
														args: {"doctype": col.options, "value": input.value},
														async: false,
														callback:function(r)	{
															ret_value = r.message;
														}
										});

										if (ret_value == 0) {
											let msg = __("This value") + ' "' + input.value + '" ' + __("does not exist in") + ' ' + col.options;
											frappe.msgprint({title: __("Message"), message: msg, indicator: "red"});
											input.value = '';
										}

										if (col.options != 'DocType') {
											s = '{"' + cell.getColumn().getField() + '":"' + input.value.toUpperCase() + '"}';
										}else{
											s = '{"' + cell.getColumn().getField() + '":"' + input.value + '"}';
										}
									}

									row.update(JSON.parse(s));

									if (clear_dynamic_link_value) {

										for (var k = 0; k < me.columns.length; k++) {

											if ((me.columns[k].options != null) && (me.columns[k].options != '')) {

												if (me.columns[k].options.toUpperCase() == cell.getColumn().getField().toUpperCase()) {

													s = '{"' + me.columns[k].fieldname + '":""}';
													row.update(JSON.parse(s));
												}
											}

										}
									}


									if (me.Cell_Callback != null) {
										me.Cell_Callback(col, row, input, cell);
									}

									row.update({"tabulator_record_modified": "Y"});

									if (me.RecordChangeState) {
										me.RecordChangeState('MODIFIED');
									}



								}
							}

							if ((!input.value) || ((input.value) && (input.value.trim() == ''))) {
								success("");
							}else{
								if (col.options != 'DocType') {
									success(input.value.toUpperCase());
								}else{
									success(input.value);
								}
							}
						}

						function keyup(event){

							if (!input.readOnly) {

								if (event) {

									if ((event.keyCode != 9) && (event.keyCode != 13)) {

										let row = cell.getRow();

										row.update({"tabulator_record_modified": "Y"});

										if (me.RecordChangeState) {
											me.RecordChangeState('MODIFIED');
										}
									}
								}
							}

						}

						input.addEventListener("keyup", keyup);
						input.addEventListener("blur", successFunc);

						return input;
					};

					column_editor = linkEditor;

					let cell_format = (cell, formatterParams) => {

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}

						if (cell.getValue() != null) {
							return cell.getValue();
						}
					}

					column_formatter = cell_format;

				}else if (col.fieldtype.toUpperCase() == 'DYNAMIC LINK') {

					var dynamiclinkEditor = (cell, onRendered, success, cancel, editorParams) => {

						var input = document.createElement("input");
						input.type = 'TEXT';
						input.style.width = "100%";
						input.style.height = "100%";
						input.value = cell.getValue();

						if (col.length != 0) {
							input.maxLength = col.length;
						}

						if (col.options != 'DocType') {
							input.style.textTransform = 'uppercase';
						}

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}

						onRendered(function(){
							input.focus();
							input.style.css = "100%";
						});

						function successFunc() {

							if (!input.readOnly) {

								let cell_value = '';

								if (cell.getValue() != null) {
									cell_value = cell.getValue();
								}

								if (input.value != cell_value) {

									let row = cell.getRow();


									let s = '';

									if ((!input.value) || ((input.value) && (input.value.trim() == ''))) {
										s = '{"' + cell.getColumn().getField() + '":""}';
									}else{

										let options = col.options;

										let dc_fieldname = options;

										let dc_fielddata = row.getData()[dc_fieldname];

										if ((dc_fielddata == null) || (dc_fielddata == '')) {
											s = '{"' + cell.getColumn().getField() + '":""}';
										}else{

											let ret_value = 0;

											let method = me.path_common + '.check_value_in_link';
											frappe.call({  	method: method,
															args: {"doctype": dc_fielddata, "value": input.value},
															async: false,
															callback:function(r)	{
																ret_value = r.message;
															}
											});

											if (ret_value == 0) {
												let msg = __("This value") + ' "' + input.value + '" ' + __("does not exist in") + ' ' + dc_fielddata;
												frappe.msgprint({title: __("Message"), message: msg, indicator: "red"});
												input.value = '';
											}

											if (col.options != 'DocType') {
												s = '{"' + cell.getColumn().getField() + '":"' + input.value.toUpperCase() + '"}';
											}else{
												s = '{"' + cell.getColumn().getField() + '":"' + input.value + '"}';
											}
										}

									}

									row.update(JSON.parse(s));

									if (me.Cell_Callback != null) {
										me.Cell_Callback(col, row, input, cell);
									}

									row.update({"tabulator_record_modified": "Y"});

									if (me.RecordChangeState) {
										me.RecordChangeState('MODIFIED');
									}

								}
							}

							if ((!input.value) || ((input.value) && (input.value.trim() == ''))) {
								success("");
							}else{
								if (col.options != 'DocType') {
									success(input.value.toUpperCase());
								}else{
									success(input.value);
								}
							}
						}

						function keyup(event){

							if (!input.readOnly) {

								if (event) {

									if ((event.keyCode != 9) && (event.keyCode != 13)) {

										let row = cell.getRow();

										row.update({"tabulator_record_modified": "Y"});

										if (me.RecordChangeState) {
											me.RecordChangeState('MODIFIED');
										}
									}
								}
							}

						}

						input.addEventListener("keyup", keyup);
						input.addEventListener("blur", successFunc);

						return input;
					};

					column_editor = dynamiclinkEditor;

					let cell_format = (cell, formatterParams) => {

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}

						if (cell.getValue() != null) {
							return cell.getValue();
						}

					}

					column_formatter = cell_format;


				}else if (col.fieldtype.toUpperCase() == 'DATA') {

					var dataEditor = (cell, onRendered, success, cancel, editorParams) => {

						var input = document.createElement("input");
						input.type = 'TEXT';
						input.style.width = "100%";
						input.style.height = "100%";
						input.style.boxSizing = "border-box";
						input.value = cell.getValue();

						if (col.length != 0) {
							input.maxLength = col.length;
						}

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}

						onRendered(function(){
							input.focus();
							input.style.css = "100%";
						});


						function successFunc(){

							if (!input.readOnly) {

								let cell_value = '';

								if (cell.getValue() != null) {
									cell_value = cell.getValue();
								}

								if (input.value != cell_value) {

									let row = cell.getRow();

									let s = '';

									if ((!input.value) || ((input.value) && (input.value.trim() == ''))) {
										s = '{"' + cell.getColumn().getField() + '":""}';
									}else{
										s = '{"' + cell.getColumn().getField() + '":"' + input.value + '"}';
									}


									row.update(JSON.parse(s));

									if (me.Cell_Callback != null) {
										me.Cell_Callback(col, row, input, cell);
									}

									row.update({"tabulator_record_modified": "Y"});

									if (me.RecordChangeState) {
										me.RecordChangeState('MODIFIED');
									}
								}
							}

							if ((!input.value) || ((input.value) && (input.value.trim() == ''))) {
								success("");
							}else{
								success(input.value);
							}
						}

						function keyup(event){

							if (!input.readOnly) {

								if (event) {

									if ((event.keyCode != 9) && (event.keyCode != 13)) {

										let row = cell.getRow();

										row.update({"tabulator_record_modified": "Y"});

										if (me.RecordChangeState) {
											me.RecordChangeState('MODIFIED');
										}
									}
								}
							}
						}

						input.addEventListener("keyup", keyup);
						input.addEventListener("blur", successFunc);

						return input;
					};

					column_editor = dataEditor;

					let cell_format = (cell, formatterParams) => {

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}

						if (cell.getValue() != null) {
							return cell.getValue();
						}

					}

					column_formatter = cell_format;


				}else if (col.fieldtype.toUpperCase() == 'CHECK') {

					var checkEditor = (cell, onRendered, success, cancel, editorParams) => {

						var input = document.createElement("input");
						input.type = 'CHECKBOX';
						input.style.boxSizing = "border-box";

						if ((cell.getValue() == null) || (cell.getValue() == '0')) {
							input.checked = false;
						}else{
							input.checked = true;
						}


						if (col.length != 0) {
							input.maxLength = col.length;
						}

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}


						onRendered(function(){
							input.focus();
							input.style.css = "100%";
						});


						function successFunc(){

							let value = 0;

							if (!input.readOnly) {

								if (input.checked) {
									value = 1;
								}


								let row = cell.getRow();

								let s = '';

								if ((!value) || ((value) && (value == 0))) {
									s = '{"' + cell.getColumn().getField() + '": 0}';
								}else{
									s = '{"' + cell.getColumn().getField() + '":' + value + '}';
								}


								row.update(JSON.parse(s));

								if (me.Cell_Callback != null) {
									me.Cell_Callback(col, row, input, cell);
								}

								row.update({"tabulator_record_modified": "Y"});

								if (me.RecordChangeState) {
									me.RecordChangeState('MODIFIED');
								}
							}

							if ((!value) || ((value) && (value == 0))) {
								success(0);
							}else{
								success(value);
							}
						}


						input.addEventListener("click", successFunc);

						return input;
					};

					column_editor = checkEditor;

					let cell_format = (cell, formatterParams) => {

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}

						if (cell.getValue() != null) {

							if (cell.getValue() == 1) {
								return "<i class='fa fa-check'></i>"
							}else if (cell.getValue() == 0) {
								return "<i class='fa fa-times'></i>";
							}
						}else{
							return "<i class='fa fa-times'></i>";
						}

					}

					column_formatter = cell_format;


				}else if ((col.fieldtype.toUpperCase() == 'SELECT') || (col.fieldtype.toUpperCase() == 'AUTOCOMPLETE')) {

					var selectEditor = (cell, onRendered, success, cancel, editorParams) => {

						var input = document.createElement("select");
						input.style.width = "100%";
						input.style.height = "100%";
						input.style.boxSizing = "border-box";


						if ((col.options == null) || ((col.options != null) && (col.options.trim() == ''))) {

							if (me.select_values.length > 0) {

								for (var i = 0; i < me.select_values.length; i++) {

									if (col.fieldname.toUpperCase() == me.select_values[i][0].toUpperCase()) {

										var option = document.createElement("option");
										option.value = me.select_values[i][1];
										option.text = me.select_values[i][2];
										input.appendChild(option);
									}

								}
							}
						}else{
							if ((col.options) && (col.options.trim() != ''))
							{
								let s = col.options;

								if (s.indexOf('\n') == -1) {

									var option = document.createElement("option");
									option.value = s;
									option.text = __(s);
									input.appendChild(option);

								}else{

									while (s.indexOf('\n') != -1) {

										let line = s.substring(0, s.indexOf('\n'));

										var option = document.createElement("option");
										option.value = line;
										option.text = __(line);
										input.appendChild(option);

										s = s.substring(s.indexOf('\n')+1, s.length);
									}

									if (s.trim() != '') {

										var option = document.createElement("option");
										option.value = s;
										option.text = __(s);
										input.appendChild(option);

									}

								}

							}
						}


						input.value = cell.getValue();

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}


						onRendered(function(){
							input.focus();
							input.style.css = "100%";
						});


						function successFunc(){

							if (!input.readOnly) {

								let cell_value = '';

								if (cell.getValue() != null) {
									cell_value = cell.getValue();
								}

								if (input.value != cell_value) {

									let row = cell.getRow();

									let s = '';

									if ((!input.value) || ((input.value) && (input.value.trim() == ''))) {
										s = '{"' + cell.getColumn().getField() + '":""}';
									}else{
										s = '{"' + cell.getColumn().getField() + '":"' + input.value + '"}';
									}

									row.update(JSON.parse(s));

									row.update({"tabulator_record_modified": "Y"});

									if (me.RecordChangeState) {
										me.RecordChangeState('MODIFIED');
									}


									if (me.Cell_Callback != null) {
										me.Cell_Callback(col, row, input, cell);
									}


								}
							}

							if ((!input.value) || ((input.value) && (input.value.trim() == ''))) {
								success('');
							}else{
								success(input.value);
							}
						}

						function change(event){

							if (!input.readOnly) {

								if (event) {

									if ((event.keyCode != 9) && (event.keyCode != 13)) {

										let row = cell.getRow();

										row.update({"tabulator_record_modified": "Y"});

										if (me.RecordChangeState) {
											me.RecordChangeState('MODIFIED');
										}
									}
								}
							}

						}

						input.addEventListener("change", change);
						input.addEventListener("blur", successFunc);

						return input;

					};

					column_editor = selectEditor;


				}else if (col.fieldtype.toUpperCase() == 'RATING') {

					var selectEditor = (cell, onRendered, success, cancel, editorParams) => {

						var input = document.createElement("select");
						input.style.width = "100%";
						input.style.height = "100%";
						input.style.boxSizing = "border-box";
						input.style.textAlign = "right";

						for (var i = 0; i <= 1; i = i + 0.1) {

							var option = document.createElement("option");
							option.value = i;

							let exp = Math.pow(10, 2);

							let nb = Math.round(parseFloat(i * 5) * exp) / exp;


							if (nb == parseInt(nb)) {
								option.text = parseFloat(nb).toFixed(0);
							}else{
								option.text = parseFloat(nb).toFixed(1);
							}

							input.appendChild(option);

						}

						input.value = cell.getValue();

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}


						onRendered(function(){
							input.focus();
							input.style.css = "100%";
						});


						function successFunc(){

							if (!input.readOnly) {

								let cell_value = '';

								if (cell.getValue() != null) {
									cell_value = cell.getValue();
								}

								if (input.value != cell_value) {

									let row = cell.getRow();

									let s = '';

									if ((!input.value) || ((input.value) && (input.value.trim() == ''))) {
										s = '{"' + cell.getColumn().getField() + '": 0}';
									}else{
										s = '{"' + cell.getColumn().getField() + '":' + input.value + '}';
									}

									row.update(JSON.parse(s));

									row.update({"tabulator_record_modified": "Y"});

									if (me.RecordChangeState) {
										me.RecordChangeState('MODIFIED');
									}


									if (me.Cell_Callback != null) {
										me.Cell_Callback(col, row, input, cell);
									}


								}
							}

							if ((!input.value) || ((input.value) && (input.value.trim() == ''))) {
								success('');
							}else{
								success(input.value);
							}

						}

						function change(event){

							if (!input.readOnly) {

								if (event) {

									if ((event.keyCode != 9) && (event.keyCode != 13)) {

										let row = cell.getRow();

										row.update({"tabulator_record_modified": "Y"});

										if (me.RecordChangeState) {
											me.RecordChangeState('MODIFIED');
										}
									}
								}
							}

						}

						input.addEventListener("change", change);
						input.addEventListener("blur", successFunc);

						return input;

					};

					column_editor = selectEditor;


				}else if ((col.fieldtype.toUpperCase() == 'FLOAT') || (col.fieldtype.toUpperCase() == 'PERCENT') || (col.fieldtype.toUpperCase() == 'CURRENCY')) {

					var floatEditor = (cell, onRendered, success, cancel, editorParams) => {

						var input = document.createElement("input");
						input.type = 'NUMBER';
						input.style.width = "100%";
						input.style.height = "100%";
						input.style.boxSizing = "border-box";
						input.step = 'any';
						input.style.textAlign = 'right';

						if (me.format_numbers.length == 0) {
							if (cell.getValue() != 0) {
								input.value = cell.getValue();
							}
						}else{

							let field_found = false;

							let nb_decimals = 0;
							let hide_if_zero = false;
							let even_in_edit = false;

							for (var i = 0; i < me.format_numbers.length; i++) {

								if (me.format_numbers[i][0].toUpperCase() == cell.getColumn().getField().toUpperCase()) {
									field_found = true;
									nb_decimals = me.format_numbers[i][1];
									hide_if_zero = me.format_numbers[i][2];
									even_in_edit = me.format_numbers[i][3];
									break;
								}
							}

							if (field_found) {

								if (hide_if_zero) {

									if (cell.getValue() != 0) {

										let value = 0;

										if (even_in_edit) {
											value = cell.getValue().toFixed(nb_decimals)
										}else {
											value = cell.getValue()
										}

										input.value = value;
									}
								}else{
									let value = 0;

									if (even_in_edit) {
										value = cell.getValue().toFixed(nb_decimals)
									}else{
										value = parseFloat(cell.getValue());
									}
									input.value = value;
								}
							}else{
								if (cell.getValue() != 0) {
									input.value = cell.getValue();
								}

							}
						}

						if (col.length != 0) {
							input.maxLength = col.length;

						}

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}


						onRendered(function(){

							input.focus();
							input.style.css = "100%";
						});




						function successFunc(){

							if (!input.readOnly) {

								let cell_value = '';

								if (cell.getValue() != null) {
									cell_value = cell.getValue();
								}

								if (input.value != cell_value) {

									let row = cell.getRow();

									let s = '';

									if ((!input.value) || ((input.value) && (input.value.trim() == ''))) {
										s = '{"' + cell.getColumn().getField() + '": 0}';
									}else{
										s = '{"' + cell.getColumn().getField() + '":' + parseFloat(input.value).toString() + '}';
									}

									row.update(JSON.parse(s));

									row.update({"tabulator_record_modified": "Y"});

									if (me.RecordChangeState) {
										me.RecordChangeState('MODIFIED');
									}


									if (me.Cell_Callback != null) {
										me.Cell_Callback(col, row, input, cell);
									}
								}
							}

							if ((!input.value) || ((input.value) && (input.value.trim() == ''))) {
								success(0);
							}else{
								success(parseFloat(input.value));
							}

						}

						function change(event){

							if (!input.readOnly) {

								if (event) {

									if ((event.keyCode != 9) && (event.keyCode != 13)) {

										let row = cell.getRow();

										row.update({"tabulator_record_modified": "Y"});

										if (me.RecordChangeState) {
											me.RecordChangeState('MODIFIED');
										}
									}
								}
							}

						}

						input.addEventListener("change", change);
						input.addEventListener("keyup", change);
						input.addEventListener("blur", successFunc);

						return input;

					};

					column_editor = floatEditor;

					let cell_format = (cell, formatterParams) => {

						if (me.format_numbers.length == 0) {

							let input = null;

							if (me.Format_Cell != null) {
								me.Format_Cell(col, cell, input);
							}

							if (cell.getValue() != 0) {
								return cell.getValue()
							}
						}else{
							let field_found = false;

							let nb_decimals = 0;
							let hide_if_zero = false;

							for (var i = 0; i < me.format_numbers.length; i++) {

								if (me.format_numbers[i][0].toUpperCase() == cell.getColumn().getField().toUpperCase()) {
									field_found = true;
									nb_decimals = me.format_numbers[i][1];
									hide_if_zero = me.format_numbers[i][2];
									break;
								}
							}

							if (field_found) {
								let input = null;

								if (me.Format_Cell != null) {
									me.Format_Cell(col, cell, input);
								}

								if (hide_if_zero) {

									if (cell.getValue() != 0) {
										let value = cell.getValue().toFixed(nb_decimals)
										return value
									}
								}else{
									let value = cell.getValue().toFixed(nb_decimals)
									return value
								}

							}else{
								let input = null;

								if (me.Format_Cell != null) {
									me.Format_Cell(col, cell, input);
								}

								if (cell.getValue() != 0) {
									return cell.getValue()
								}
							}
						}
					}

					column_formatter = cell_format;


				}else if (col.fieldtype.toUpperCase() == 'INT') {

					var intEditor = (cell, onRendered, success, cancel, editorParams) => {


						var input = document.createElement("input");
						input.type = 'NUMBER';
						input.style.width = "100%";
						input.style.height = "100%";
						input.style.boxSizing = "border-box";
						input.step = 'any';
						input.style.textAlign = 'right';


						if (me.format_numbers.length == 0) {
							if (cell.getValue() != 0) {
								input.value = cell.getValue();
							}
						}else{

							let field_found = false;

							let nb_decimals = 0;
							let hide_if_zero = false;
							let even_in_edit = false;

							for (var i = 0; i < me.format_numbers.length; i++) {

								if (me.format_numbers[i][0].toUpperCase() == cell.getColumn().getField().toUpperCase()) {
									field_found = true;
									hide_if_zero = me.format_numbers[i][2];
									break;
								}
							}

							if (field_found) {

								if (hide_if_zero) {

									if (cell.getValue() != 0) {
										input.value = cell.getValue();
									}
								}else{
									input.value = cell.getValue();
								}
							}else{
								if (cell.getValue() != 0) {
									input.value = cell.getValue();
								}

							}
						}


						if (col.length != 0) {
							input.maxLength = col.length;
						}

						onRendered(function(){
							input.focus();
							input.style.css = "100%";
						});

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}



						function successFunc() {

							if (!input.readOnly) {

								let cell_value = '';

								if (cell.getValue() != null) {
									cell_value = cell.getValue();
								}

								if (input.value != cell_value) {

									let row = cell.getRow();

									let s = '';

									if ((!input.value) || ((input.value) && (input.value.trim() == ''))) {
										s = '{"' + cell.getColumn().getField() + '": 0}';
									}else{
										s = '{"' + cell.getColumn().getField()+ '":' + parseInt(input.value).toString() + '}';
									}

									row.update(JSON.parse(s));

									row.update({"tabulator_record_modified": "Y"});

									if (me.RecordChangeState) {
										me.RecordChangeState('MODIFIED');
									}


									if (me.Cell_Callback != null) {
										me.Cell_Callback(col, row, input, cell);
									}
								}
							}

							if ((!input.value) || ((input.value) && (input.value.trim() == ''))) {
								success(0);
							}else{
								success(parseInt(input.value));
							}
						}

						function change(event) {

							if (!input.readOnly) {

								if (event) {

									if ((event.keyCode != 9) && (event.keyCode != 13)) {

										let row = cell.getRow();

										row.update({"tabulator_record_modified": "Y"});

										if (me.RecordChangeState) {
											me.RecordChangeState('MODIFIED');
										}
									}
								}
							}

						}

						input.addEventListener("change", change);
						input.addEventListener("keyup", change);
						input.addEventListener("blur", successFunc);

						return input;


					};

					column_editor = intEditor;

					let cell_format = (cell, formatterParams) => {


						if (me.format_numbers.length == 0) {

							let input = null;

							if (me.Format_Cell != null) {
								me.Format_Cell(col, cell, input);
							}

							if (cell.getValue() != 0) {
								return cell.getValue()
							}
						}else{
							let field_found = false;
							let hide_if_zero = false;

							for (var i = 0; i < me.format_numbers.length; i++) {

								if (me.format_numbers[i][0].toUpperCase() == cell.getColumn().getField().toUpperCase()) {
									field_found = true;
									hide_if_zero = me.format_numbers[i][2];
									break;
								}
							}

							if (field_found) {

								let input = null;

								if (me.Format_Cell != null) {
									me.Format_Cell(col, cell, input);
								}

								if (hide_if_zero) {

									if (cell.getValue() != 0) {
										let value = cell.getValue()
										return value
									}
								}else{
									let value = cell.getValue()
									return value
								}

							}else{
								let input = null;

								if (me.Format_Cell != null) {
									me.Format_Cell(col, cell, input);
								}

								if (cell.getValue() != 0) {
									return cell.getValue()
								}

							}

						}


					}

					column_formatter = cell_format;

				}else if (col.fieldtype.toUpperCase() == 'DATETIME') {

					var datetimeEditor = (cell, onRendered, success, cancel, editorParams) => {

						var input = document.createElement("input");
						input.type = 'DATETIME-LOCAL';
						input.style.width = "100%";
						input.style.height = "100%";
						input.style.boxSizing = "border-box";

						if (cell.getValue() != null && cell.getValue() != '') {
							input.value = cell.getValue();
						}

						if (col.length != 0) {
							input.maxLength = col.length;
						}

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}


						onRendered(function(){
							input.focus();
							input.style.css = "100%";
						});


						function successFunc() {

							if (!input.readOnly) {

								let cell_value = '';

								if (cell.getValue() != null) {
									cell_value = cell.getValue();
								}

								if (input.value != cell_value) {

									let row = cell.getRow();

									let s = '';

									if ((!input.value) || ((input.value) && (input.value.trim() == ''))) {
										s = '{"' + cell.getColumn().getField() + '":""}';
									}else{
										s = '{"' + cell.getColumn().getField() + '":"' + input.value + '"}';
									}

									row.update(JSON.parse(s));

									row.update({"tabulator_record_modified": "Y"});

									if (me.RecordChangeState) {
										me.RecordChangeState('MODIFIED');
									}


									if (me.Cell_Callback != null) {
										me.Cell_Callback(col, row, input, cell);
									}
								}
							}

							if ((!input.value) || ((input.value) && (input.value.trim() == ''))) {
								success('');
							}else{
								success(input.value);
							}
						}

						function change(event) {

							if (!input.readOnly) {

								if (event) {

									if ((event.keyCode != 9) && (event.keyCode != 13)) {

										let row = cell.getRow();

										row.update({"tabulator_record_modified": "Y"});

										if (me.RecordChangeState) {
											me.RecordChangeState('MODIFIED');
										}
									}
								}
							}
						}

						input.addEventListener("change", change);
						input.addEventListener("keyup", change);
						input.addEventListener("blur", successFunc);

						return input;

					};

					column_editor = datetimeEditor;

					let cell_format = (cell, formatterParams) => {

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}

						if (cell.getValue() != null) {
							return cell.getValue();
						}

					}

					column_formatter = cell_format;

				}else if (col.fieldtype.toUpperCase() == 'DATE') {

					var dateEditor = (cell, onRendered, success, cancel, editorParams) => {


						var input = document.createElement("input");
						input.type = 'DATE';
						input.style.width = "100%";
						input.style.height = "100%";
						input.style.boxSizing = "border-box";

						if (cell.getValue() != null && cell.getValue() != '') {
							input.value = cell.getValue();
						}

						if (col.length != 0) {
							input.maxLength = col.length;
						}

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}

						onRendered(function(){
							input.focus();
							input.style.css = "100%";
						});


						function successFunc() {

							if (!input.readOnly) {

								let cell_value = '';

								if (cell.getValue() != null) {
									cell_value = cell.getValue();
								}

								if (input.value != cell_value) {

									let row = cell.getRow();

									let s = '';

									if ((!input.value) || ((input.value) && (input.value.trim() == ''))) {
										s = '{"' + cell.getColumn().getField() + '": ""}';
									}else{
										s = '{"' + cell.getColumn().getField() + '":"' + input.value + '"}';
									}

									row.update(JSON.parse(s));

									row.update({"tabulator_record_modified": "Y"});

									if (me.RecordChangeState) {
										me.RecordChangeState('MODIFIED');
									}


									if (me.Cell_Callback != null) {
										me.Cell_Callback(col, row, input, cell);
									}
								}
							}

							if ((!input.value) || ((input.value) && (input.value.trim() == ''))) {
								success('');
							}else{
								success(input.value);
							}
						}

						function change(event) {

							if (!input.readOnly) {

								if (event) {

									if ((event.keyCode != 9) && (event.keyCode != 13)) {

										let row = cell.getRow();

										row.update({"tabulator_record_modified": "Y"});

										if (me.RecordChangeState) {
											me.RecordChangeState('MODIFIED');
										}
									}
								}
							}
						}

						input.addEventListener("change", change);
						input.addEventListener("keyup", change);
						input.addEventListener("blur", successFunc);

						return input;

					};

					column_editor = dateEditor;

					let cell_format = (cell, formatterParams) => {

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}

						if (cell.getValue() != null) {
							return cell.getValue();
						}

					}

					column_formatter = cell_format;

				}else if (col.fieldtype.toUpperCase() == 'TIME') {

					var timeEditor = (cell, onRendered, success, cancel, editorParams) => {

						var input = document.createElement("input");
						input.type = 'TIME';
						input.style.width = "100%";
						input.style.height = "100%";
						input.style.boxSizing = "border-box";

						if (cell.getValue() != null && cell.getValue() != '') {

							let s = cell.getValue();
							s = s.substring(0, s.indexOf(':'));
							s = s.trim();

							if (s.length < 2) {
								s = '0' + cell.getValue();
							}else{
								s = cell.getValue();
							}
							input.value = s;
						}

						if (col.length != 0) {
							input.maxLength = col.length;
						}

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}


						onRendered(function(){
							input.focus();
							input.style.css = "100%";
						});


						function successFunc() {

							if (!input.readOnly) {

								let cell_value = '';

								if (cell.getValue() != null) {
									cell_value = cell.getValue();
								}

								if (input.value != cell_value) {

									let row = cell.getRow();

									let s = '';

									if ((!input.value) || ((input.value) && (input.value.trim() == ''))) {
										s = '{"' + cell.getColumn().getField() + '":""}';
									}else{
										s = '{"' + cell.getColumn().getField() + '":"' + input.value + '"}';
									}

									row.update(JSON.parse(s));

									row.update({"tabulator_record_modified": "Y"});

									if (me.RecordChangeState) {
										me.RecordChangeState('MODIFIED');
									}

									if (me.Cell_Callback != null) {
										me.Cell_Callback(col, row, input, cell);
									}
								}
							}

							if ((!input.value) || ((input.value) && (input.value.trim() == ''))) {
								success('');
							}else{
								success(input.value);
							}

						}

						function change(event){

							if (!input.readOnly) {

								if (event) {

									if ((event.keyCode != 9) && (event.keyCode != 13)) {

										let row = cell.getRow();

										row.update({"tabulator_record_modified": "Y"});

										if (me.RecordChangeState) {
											me.RecordChangeState('MODIFIED');
										}
									}
								}
							}
						}

						input.addEventListener("change", change);
						input.addEventListener("keyup", change);
						input.addEventListener("blur", successFunc);

						return input;

					};

					column_editor = timeEditor;

					let cell_format = (cell, formatterParams) => {

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}

						if (cell.getValue() != null) {
							return cell.getValue();
						}

					}

					column_formatter = cell_format;

				}
			}else{
				if ((column_visible) && (column_readonly == 1)) {

					if (col.fieldtype.toUpperCase() == 'CHECK') {

						let cell_format = (cell, formatterParams) => {

							let input = null;

							if (me.Format_Cell != null) {
								me.Format_Cell(col, cell, input);
							}

							if (cell.getValue() != null) {

								if (cell.getValue() == 1) {
									return "<i class='fa fa-check'></i>"
								}else if (cell.getValue() == 0) {
									return "<i class='fa fa-times'></i>";
								}
							}else{
								return "<i class='fa fa-times'></i>";
							}

						}

						column_formatter = cell_format;

					}else if ((col.fieldtype.toUpperCase() != 'SELECT') && (col.fieldtype.toUpperCase() != 'AUTOCOMPLETE') && (col.fieldtype.toUpperCase() != 'RATING')) {

						let cell_format = (cell, formatterParams) => {

							let input = null;

							if (me.Format_Cell != null) {
								me.Format_Cell(col, cell, input);
							}

							if (cell.getValue() != null) {
								return cell.getValue();
							}

						}

						column_formatter = cell_format;

					}else if (col.fieldtype.toUpperCase() == 'RATING') {

						column_hozAlign = "right";

						let rating_formatter = (cell, formatterParams) => {

							let input = null;

							if (me.Format_Cell != null) {
								me.Format_Cell(col, cell, input);
							}


							if (cell.getValue() != null) {

								let exp = Math.pow(10, 2);

								let nb = Math.round(parseFloat(cell.getValue() * 5) * exp) / exp;

								if (nb == parseInt(nb)) {
									return parseFloat(nb).toFixed(0);
								}else{
									return parseFloat(nb).toFixed(1);
								}

							}
						}

						column_formatter = rating_formatter;


					}

				}
			}


			if (col.fieldtype.toUpperCase() == 'DURATION') {

				if (column_visible) {

					let duration_format = (cell, formatterParams) => {

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}


						if ((cell.getValue() != null) && (cell.getValue() != '')) {

							let duration = parseInt(cell.getValue());

							let ret = me.duration_convert(duration);

							let days = ret[0];
							let hours = ret[1];
							let minutes = ret[2];
							let seconds = ret[3];

							let s = '';

							if (col.hide_days == 0) {

								if (days != 0) {
									s += days.toString() + __("d") + ' ';
								}
							}

							if (hours != 0) {
								s += hours.toString() + __("h") + ' ';
							}

							if (minutes != 0) {
								s += minutes.toString() + __("m") + ' ';
							}

							if (col.hide_seconds == 0) {

								if (seconds != 0) {
									s += seconds.toString() + __("s") + ' ';
								}
							}

							return s;
						}
					}

					column_formatter = duration_format;
				}
			}


			if ((col.fieldtype.toUpperCase() == 'CODE') || (col.fieldtype.toUpperCase() == 'HTML EDITOR') || (col.fieldtype.toUpperCase() == 'LONG TEXT') ||
				(col.fieldtype.toUpperCase() == 'MARKDOWN EDITOR') || (col.fieldtype.toUpperCase() == 'SMALL TEXT') || (col.fieldtype.toUpperCase() == 'TEXT') || (col.fieldtype.toUpperCase() == 'TEXT EDITOR')) {

				if (column_visible) {

					let memo_format = (cell, formatterParams) => {

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}

						if (cell.getValue() != null) {
							return cell.getValue();
						}


					}

					column_editor = false;
					column_formatter = memo_format;
				}
			}

			if (col.fieldtype.toUpperCase() == 'ATTACH IMAGE') {

				if (column_visible) {

					let image_display = false;

					if (me.show_attach_image_path.length > 0) {

						for (var i = 0; i < me.show_attach_image_path.length; i++) {

							if (me.show_attach_image_path[i].toUpperCase() == col.fieldname.toUpperCase()) {

								image_display = true;
								break;

							}

						}
					}

					if (!image_display) {
						column_visible = false;
					}else{

						let attach_image_format = (cell, formatterParams) => {

							let input = null;

							if (me.Format_Cell != null) {
								me.Format_Cell(col, cell, input);
							}

							if (cell.getValue() != null) {
								return cell.getValue();
							}


						}

						column_editor = false;
						column_formatter = attach_image_format;
					}
				}
			}else if (col.fieldtype.toUpperCase() == 'ATTACH') {

				if (column_visible) {

					let attach_path_format = (cell, formatterParams) => {

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}

						if (cell.getValue() != null) {
							return cell.getValue();
						}


					}

					column_editor = false;
					column_formatter = attach_path_format;

				}
			}else if ((col.fieldtype.toUpperCase() == 'COLOR') || (col.fieldtype.toUpperCase() == 'READ ONLY')) {

				if (column_visible) {

					let cell_format = (cell, formatterParams) => {

						let input = null;

						if (col.fieldtype.toUpperCase() == 'COLOR') {
							if (cell.getValue() != null) {
								cell.getElement().style.backgroundColor = cell.getValue();
							}else{
								if (me.Format_Cell != null) {
									me.Format_Cell(col, cell, input);
								}

							}
						}else{

							if (me.Format_Cell != null) {
								me.Format_Cell(col, cell, input);
							}

							if (cell.getValue() != null) {
								return cell.getValue();
							}
						}

					}

					column_formatter = cell_format;
				}
			}else if (col.fieldtype.toUpperCase() == 'TABLE MULTISELECT') {

				if (column_visible) {

					let cell_format = (cell, formatterParams) => {

						let input = null;

						if (me.Format_Cell != null) {
								me.Format_Cell(col, cell, input);
						}

						let html = '';

						let s = cell.getElement().innerText;

						if (s.trim() == '') {

							if ((col.options != null) && (col.options != '')) {

								let method = me.path_common + '.ioi_doctype_grid_get_multiselect_values';

								frappe.call({  	method: method,
												args: { "doctype": col.options,
														"fieldname": col.fieldname,
														"parent_doctype": me.doctype,
														"parent_pk": cell.getRow().getData()["name"]},
												async: false,
												callback:function(r)	{

													if (r.message.length > 0) {
														for (var i = 0; i < r.message.length; i++) {
															html += r.message[i].fieldname;

															if (i < r.message.length-1) {
																html += ', ';
															}
														}
													}
												}
								});
							}
						}else{

							html = s;
						}

						return html;

					}

					column_formatter = cell_format;
				}



			}

			if (col.fieldtype.toUpperCase() == 'BUTTON') {

				if (column_visible) {

					let display_button = false;

					if (col.read_only == 1) {

						for (var i = 0; i < me.display_buttons_when_readonly.length; i++) {

							if (me.display_buttons_when_readonly[i].toUpperCase() == col.fieldname.toUpperCase()) {
								display_button = true;
								break;
							}

						}
					}else{
						display_button = true;
					}

					if (display_button) {

						column_title = '';
						column_field = 'button_' + column_field;
						column_visible = true;
						column_frozen = false;
						column_hozAlign = true;

						var buttonEditor = (cell, formatterParams, onRendered) => {

							var button = document.createElement("button");
							button.style.width = "100%";
							button.style.height = "100%";
							button.style.border = 'none';
							button.width = "100%";
							button.height = "100%";
							button.innerText = __(col.label);


							if (me.Format_Cell != null) {
								me.Format_Cell(col, cell, button);
							}

							var bt_click = function() {

								let bt_fieldname = cell.getColumn().getField();

								let row = cell.getRow();

								row.update({"tabulator_record_modified":"Y"});

								if (me.RecordChangeState) {
									me.RecordChangeState('MODIFIED');
								}


								if (me.Button_Callback != null) {
									me.Button_Callback(bt_fieldname, cell, row, button);
								}

							}

							button.onclick = bt_click;
							return button;
						};

						column_editor = null;
						column_formatter = buttonEditor;
					}
				}
			}



			let cell_onclick = (e, cell) => {

				if (col.fieldtype.toUpperCase() == 'CHECK') {

					if ((column_editor != null) && (column_editor != false)) {


						let row = cell.getRow();

						row.update({"tabulator_record_modified": "Y"});

						if (me.RecordChangeState) {
							me.RecordChangeState('MODIFIED');
						}


					}
				}else if ((col.fieldtype.toUpperCase() == 'ATTACH') || (col.fieldtype.toUpperCase() == 'ATTACH IMAGE')) {

					if ((cell.getValue() != null) && (cell.getValue() != '')) {

						let url = cell.getValue();
						window.open(url, "_blank");
					}
				}else{
					if (me.Cell_click != null) {
						me.Cell_click(cell);
					}
				}
			}

			if (col.fieldtype.toUpperCase() == 'BUTTON') {

				let display_button = false;

				if (col.read_only == 1) {

					for (var i = 0; i < me.display_buttons_when_readonly.length; i++) {

						if (me.display_buttons_when_readonly[i].toUpperCase() == col.fieldname.toUpperCase()) {
							display_button = true;
							break;
						}

					}
				}else{
					display_button = true;
				}

				if (display_button) {
					me.ioiTable.addColumn(
						{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, formatterParams: column_formatterParams, cellClick: cell_onclick, headerSort: false}
					);
				}

			}else{

				let column_width = null;

				if (me.columns_width.length > 0) {

					for (var i = 0; i < me.columns_width.length; i++) {

						if (me.columns_width[i][0].toUpperCase() == col.fieldname.toUpperCase()) {

							column_width = me.columns_width[i][1];
							break;
						}
					}
				}

				if (column_frozen) {

					let field_format = (cell, formatterParams) => {

						if (me.underline_frozen) {
							return '<u>' + cell.getValue() + '</u>';
						}else{
							return cell.getValue();
						}
					}

					column_formatter = field_format;
					column_editor = null;
				}


				if (column_width != null) {
					me.ioiTable.addColumn(
						{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, formatterParams: column_formatterParams, cellClick: cell_onclick, headerSort: false, width: column_width}
					);
				}else{
					me.ioiTable.addColumn(
						{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, formatterParams: column_formatterParams, cellClick: cell_onclick, headerSort: false}
					);

				}
			}


			if (column_visible) {

				if (col.fieldtype.toUpperCase() == 'LINK') {

					let old_column_field = column_field;

					if (column_readonly == 0) {

						column_title = '';
						column_field = 'button_' + column_field;
						column_visible = true;
						column_frozen = false;
						column_hozAlign = true;

						var buttonEditor = (cell, formatterParams, onRendered) => {

							var button = document.createElement("button");
							button.style.width = "100%";
							button.style.height = "100%";
							button.style.border = 'none';
							button.width = "100%";
							button.height = "100%";
							button.innerText = '...';
							button.title = __("Select")


							let input = null;

							if (me.Format_Cell != null) {
								me.Format_Cell(col, cell, input);
							}

							var bt_click = function() {

								let bt_fieldname = cell.getColumn().getField();
								let fieldname = bt_fieldname.substring(7, bt_fieldname.length);

								let row = cell.getRow();

								for (var k = 0; k < me.columns.length; k++) {

									if (me.columns[k].fieldtype.toUpperCase() == 'LINK') {

										if (fieldname == me.columns[k].fieldname) {

											let options = me.columns[k].options;

											if (options.toString().toUpperCase() == 'IOI ITEM') {

												let fields = 'a.name, a.description, a.unit_id, b.q_stock, b.q_reserved, a.manufacturer_ref';
												let fields_len = '300, 500, 125, 150, 150, 300';
												let fields_desc = 'Identification, Description, Unit, Stock qty, Reserved qty, Manufacturer Catalog';
												let where = 'ioistatus = 2';
												let order_by = 'a.name asc';
												let parameter_with_stock = false;
												let site_id = '';

												if (me.ItemWindowParams != null) {

													let ret = me.ItemWindowParams(bt_fieldname, fieldname, row);

													fields = ret[0];
													fields_len = ret[1];
													fields_desc = ret[2];
													where = ret[3];
													order_by = ret[4];
													parameter_with_stock = ret[5];
													site_id = ret[6];
												}

												let fct_callback = function (return_value) {

													if (return_value != row.getData()[fieldname]) {

														let s = '{"' + fieldname + '":"' + return_value + '"}';
														row.update(JSON.parse(s));


														for (var k = 0; k < me.columns.length; k++) {

															if ((me.columns[k].options != null) && (me.columns[k].options != '')) {

																if (me.columns[k].options.toUpperCase() == fieldname.toUpperCase()) {

																	s = '{"' + me.columns[k].fieldname + '":""}';
																	row.update(JSON.parse(s));
																}
															}

														}

														row.update({"tabulator_record_modified":"Y"});

														if (me.RecordChangeState) {
															me.RecordChangeState('MODIFIED');
														}


														if (me.CellButton_Callback != null) {
															me.CellButton_Callback(fieldname, return_value, row);
														}
													}
												};

												silicon_ioi.ioiCommon.select_item(fields, fields_len, fields_desc, where, order_by, parameter_with_stock, site_id, fct_callback);

											}else if (options.toString().toUpperCase() == 'IOI CUSTOMER') {

												let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email, alternate_id';
												let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400, 250';
												let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email, Alternate id';
												let where = 'ioistatus = 2';
												let order_by = 'name asc';

												if (me.CustomerWindowParams != null) {

													let ret = me.CustomerWindowParams(bt_fieldname, fieldname, row);

													fields = ret[0];
													fields_len = ret[1];
													fields_desc = ret[2];
													where = ret[3];
													order_by = ret[4];
												}


												let fct_callback = function (return_value) {

													if (return_value != row.getData()[fieldname]) {

														let s = '{"' + fieldname + '":"' + return_value + '"}';
														row.update(JSON.parse(s));

														for (var k = 0; k < me.columns.length; k++) {

															if ((me.columns[k].options != null) && (me.columns[k].options != '')) {

																if (me.columns[k].options.toUpperCase() == fieldname.toUpperCase()) {

																	s = '{"' + me.columns[k].fieldname + '":""}';
																	row.update(JSON.parse(s));
																}
															}

														}

														row.update({"tabulator_record_modified":"Y"});

														if (me.RecordChangeState) {
															me.RecordChangeState('MODIFIED');
														}

														if (me.CellButton_Callback != null) {
															me.CellButton_Callback(fieldname, return_value, row);
														}

													}
												}

												silicon_ioi.ioiCommon.select_customer(fields, fields_len, fields_desc, where, order_by, fct_callback);

											}else if (options.toString().toUpperCase() == 'IOI SUPPLIER') {

												let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email, alternate_id';
												let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400, 250';
												let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email, Alternate id';
												let where = 'ioistatus = 2';
												let order_by = 'name asc';

												if (me.SupplierWindowParams != null) {

													let ret = me.SupplierWindowParams(bt_fieldname, fieldname, row);

													fields = ret[0];
													fields_len = ret[1];
													fields_desc = ret[2];
													where = ret[3];
													order_by = ret[4];
												}


												let fct_callback = function (return_value) {

													if (return_value != row.getData()[fieldname]) {

														let s = '{"' + fieldname + '":"' + return_value + '"}';
														row.update(JSON.parse(s));

														for (var k = 0; k < me.columns.length; k++) {

															if ((me.columns[k].options != null) && (me.columns[k].options != '')) {

																if (me.columns[k].options.toUpperCase() == fieldname.toUpperCase()) {

																	s = '{"' + me.columns[k].fieldname + '":""}';
																	row.update(JSON.parse(s));
																}
															}

														}

														row.update({"tabulator_record_modified":"Y"});

														if (me.RecordChangeState) {
															me.RecordChangeState('MODIFIED');
														}


														if (me.CellButton_Callback != null) {
															me.CellButton_Callback(fieldname, return_value, row);
														}

													}
												}

												silicon_ioi.ioiCommon.select_supplier(fields, fields_len, fields_desc, where, order_by, fct_callback);

											}else if (options.toString().toUpperCase() == 'IOI MANUFACTURER CATALOG') {

												let fields = 'a.name, a.item_id, a.description, a.unit_id, a.item_id_live_creation, b.supplier_id, a.barcode_reference, ';
												fields += 'a.family_id, a.type_id, a.group_id, a.subgroup_id, a.shop_category_id, a.classification_id, a.category_id, a.budget_id';
												let fields_len = '300, 200, 350, 100, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200';
												let fields_desc = 'Identification, Item, Description, Unit, Item id live creation, Supplier, Barcode ref, ';
												fields_desc += 'Family, Type, Group, Subgroup, Shop Category, Classification, Category, Budget type';
												let where = "a.enabled = 1 ";
												let order_by = 'a.name asc';


												let document_date = null;
												let currency_id = null;

												if (me.ManufacturerCatalogWindowParams != null) {

													let ret = me.ManufacturerCatalogWindowParams(bt_fieldname, fieldname, row);

													fields = ret[0];
													fields_len = ret[1];
													fields_desc = ret[2];
													where = ret[3];
													order_by = ret[4];
													document_date = ret[5];
													currency_id = ret[6];
												}


												let fct_callback = function (manufacture_catalog_id, item_id) {

													if (manufacture_catalog_id != row.getData()[fieldname]) {

														let s = '{"' + fieldname + '":"' + manufacture_catalog_id + '"}';
														row.update(JSON.parse(s));

														for (var k = 0; k < me.columns.length; k++) {

															if ((me.columns[k].options != null) && (me.columns[k].options != '')) {

																if (me.columns[k].options.toUpperCase() == fieldname.toUpperCase()) {

																	s = '{"' + me.columns[k].fieldname + '":""}';
																	row.update(JSON.parse(s));
																}
															}

														}

														row.update({"tabulator_record_modified":"Y"});

														if (me.RecordChangeState) {
															me.RecordChangeState('MODIFIED');
														}

														if (me.CellButton_Callback != null) {
															me.CellButton_Callback(fieldname, manufacture_catalog_id, row);
														}

													}
												};

												silicon_ioi.ioiCommon.select_manufacturer_catalog(fields, fields_len, fields_desc, where, order_by, document_date, fct_callback, false);

											}else{

												let title = __("Select");
												let form_width_pixel = '710px';
												let table = options;
												let fields = 'name, description';
												let fields_len = '250, 380';
												let fields_desc = __('Identification') + ',' + __('Description');
												let where = '';
												let order_by = 'name asc';


												if (me.DoctypeWindowParams != null) {


													let ret = me.DoctypeWindowParams(bt_fieldname, fieldname, row);

													title = ret[0];
													form_width_pixel = ret[1];
													fields = ret[2];
													fields_len = ret[3];
													fields_desc = ret[4];
													where = ret[5];
													order_by = ret[6];
												}

												let show_window = true;
												let msg = ""


												let fct_callback = function (return_value) {

													if (return_value != row.getData()[fieldname]) {

														let s = '{"' + fieldname + '":"' + return_value + '"}';
														row.update(JSON.parse(s));

														for (var k = 0; k < me.columns.length; k++) {

															if ((me.columns[k].options != null) && (me.columns[k].options != '')) {

																if (me.columns[k].options.toUpperCase() == fieldname.toUpperCase()) {

																	s = '{"' + me.columns[k].fieldname + '":""}';
																	row.update(JSON.parse(s));
																}
															}

														}

														row.update({"tabulator_record_modified":"Y"});

														if (me.RecordChangeState) {
															me.RecordChangeState('MODIFIED');
														}

														if (me.CellButton_Callback != null) {
															me.CellButton_Callback(fieldname, return_value, row);
														}
													}

												}

												if (show_window) {
													silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);
												}else{
													if (msg.trim() != '') {
														frappe.msgprint({title: __("Message"), message: msg, indicator: "red"});
													}
												}

											}

										}

									}
								}



							}

							button.onclick = bt_click;
							return button;
						};

						column_editor = null;
						column_formatter = buttonEditor;

						me.ioiTable.addColumn(
							{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, cellClick: cell_onclick, headerSort: false}
						);

						column_field = old_column_field;

					}

					// Jump
					let jump_to_module = false;

					if (me.jump_fields.length > 0) {

						for (var i = 0; i < me.jump_fields.length ; i++) {

							if (column_field.toUpperCase() == me.jump_fields[i].toUpperCase()) {

								jump_to_module = true;
								break;
							}
						}
					}


					if (jump_to_module) {


						column_title = '';
						column_field = 'button_jump_' + column_field;
						column_visible = true;
						column_frozen = false;
						column_hozAlign = true;

						var buttonEditor = (cell, formatterParams, onRendered) => {

							var button = document.createElement("button");
							button.style.width = "100%";
							button.style.height = "100%";
							button.style.border = 'none';
							button.width = "100%";
							button.height = "100%";
							button.innerText = '->';
							button.title = __("Jump to module");


							let input = null;

							if (me.Format_Cell != null) {
								me.Format_Cell(col, cell, input);
							}

							var bt_click = function() {

								for (var k = 0; k < me.columns.length; k++) {

									if (me.columns[k].fieldtype.toUpperCase() == 'LINK') {

										if (me.columns[k].options.toUpperCase() != 'DOCTYPE') {

											if (me.columns[k].fieldname.toUpperCase() == cell.getColumn().getField().substring(12, cell.getColumn().getField().length).toUpperCase()) {

												let value = cell.getRow().getData()[me.columns[k].fieldname];

												if (value != null) {

													if (value.trim() != '') {

														let ret_value = 0;

														let method = me.path_common + '.check_value_in_link';

														frappe.call({  	method: method,
																		args: {"doctype": me.columns[k].options, "value": value},
																		async: false,
																		callback:function(r)	{
																			ret_value = r.message;
																		}
														});

														if (ret_value == 1) {

															let s = me.columns[k].options.toString().toLowerCase();

															s = s.replaceAll(' ', '-');

															let url = '/app/' + s + '/' + value;

															window.open(url, "_blank");
														}
													}
												}
											}
										}
									}
								}



							}

							button.onclick = bt_click;
							return button;
						};

						column_editor = null;
						column_formatter = buttonEditor;

						me.ioiTable.addColumn(
							{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, cellClick: cell_onclick, headerSort: false}
						);
						column_field = old_column_field;
					}



				}else if (col.fieldtype.toUpperCase() == 'DYNAMIC LINK') {

					let old_column_field = column_field;

					if (column_readonly == 0) {

						column_title = '';
						column_field = 'button_' + column_field;
						column_visible = true;
						column_frozen = false;
						column_hozAlign = true;

						var buttonEditor = (cell, formatterParams, onRendered) => {

							var button = document.createElement("button");
							button.style.width = "100%";
							button.style.height = "100%";
							button.style.border = 'none';
							button.width = "100%";
							button.height = "100%";
							button.innerText = '...';
							button.title = "Select"

							let input = null;

							if (me.Format_Cell != null) {
								me.Format_Cell(col, cell, input);
							}


							var bt_click = function() {

								let bt_fieldname = cell.getColumn().getField();
								let fieldname = bt_fieldname.substring(7, bt_fieldname.length);

								let row = cell.getRow();

								for (var k = 0; k < me.columns.length; k++) {

									if (me.columns[k].fieldtype.toUpperCase() == 'DYNAMIC LINK') {

										if (fieldname == me.columns[k].fieldname) {

											let options = me.columns[k].options;

											let dc_fieldname = options;

											let dc_fielddata = row.getData()[dc_fieldname];

											options = row.getData()[options];

											if ((!dc_fieldname) || ((dc_fieldname) && ((dc_fielddata == '') || (dc_fielddata == null)))) {

												let msg = __("Field") + ' ' + dc_fieldname + ' ' + __("has to be filled")
												frappe.msgprint({title: __("Message"), message: msg, indicator: "red"});
												return false;

											}

											if (options.toString().toUpperCase() == 'IOI ITEM') {

												let fields = 'a.name, a.description, a.unit_id, b.q_stock, b.q_reserved, a.manufacturer_ref';
												let fields_len = '300, 500, 125, 150, 150, 300';
												let fields_desc = 'Identification, Description, Unit, Stock qty, Reserved qty, Manufacturer Catalog';
												let where = 'ioistatus = 2';
												let order_by = 'a.name asc';
												let parameter_with_stock = false;
												let site_id = '';

												if (me.ItemWindowParams != null) {

													let ret = me.ItemWindowParams(bt_fieldname, fieldname, row);

													fields = ret[0];
													fields_len = ret[1];
													fields_desc = ret[2];
													where = ret[3];
													order_by = ret[4];
													parameter_with_stock = ret[5];
													site_id = ret[6];
												}


												let fct_callback = function (return_value) {

													if (return_value != row.getData()[fieldname]) {

														let s = '{"' + fieldname + '":"' + return_value + '"}';
														row.update(JSON.parse(s));

														row.update({"tabulator_record_modified":"Y"});

														if (me.RecordChangeState) {
															me.RecordChangeState('MODIFIED');
														}

														if (me.CellButton_Callback != null) {
															me.CellButton_Callback(fieldname, return_value, row);
														}

													}

												};

												silicon_ioi.ioiCommon.select_item(fields, fields_len, fields_desc, where, order_by, parameter_with_stock, site_id, fct_callback);

											}else if (options.toString().toUpperCase() == 'IOI CUSTOMER') {

												let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email, alternate_id';
												let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400, 250';
												let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email, Alternate id';
												let where = 'ioistatus = 2';
												let order_by = 'name asc';

												if (me.CustomerWindowParams != null) {

													let ret = me.CustomerWindowParams(bt_fieldname, fieldname, row);

													fields = ret[0];
													fields_len = ret[1];
													fields_desc = ret[2];
													where = ret[3];
													order_by = ret[4];
												}


												let fct_callback = function (return_value) {

													if (return_value != row.getData()[fieldname]) {

														let s = '{"' + fieldname + '":"' + return_value + '"}';
														row.update(JSON.parse(s));

														row.update({"tabulator_record_modified":"Y"});

														if (me.RecordChangeState) {
															me.RecordChangeState('MODIFIED');
														}

														if (me.CellButton_Callback != null) {
															me.CellButton_Callback(fieldname, return_value, row);
														}

													}
												}

												silicon_ioi.ioiCommon.select_customer(fields, fields_len, fields_desc, where, order_by, fct_callback);

											}else if (options.toString().toUpperCase() == 'IOI SUPPLIER') {

												let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email, alternate_id';
												let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400, 250';
												let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email, Alternate id';
												let where = 'ioistatus = 2';
												let order_by = 'name asc';

												if (me.SupplierWindowParams != null) {

													let ret = me.SupplierWindowParams(bt_fieldname, fieldname, row);

													fields = ret[0];
													fields_len = ret[1];
													fields_desc = ret[2];
													where = ret[3];
													order_by = ret[4];
												}



												let fct_callback = function (return_value) {

													if (return_value != row.getData()[fieldname]) {

														let s = '{"' + fieldname + '":"' + return_value + '"}';
														row.update(JSON.parse(s));

														row.update({"tabulator_record_modified":"Y"});

														if (me.RecordChangeState) {
															me.RecordChangeState('MODIFIED');
														}

														if (me.CellButton_Callback != null) {
															me.CellButton_Callback(fieldname, return_value, row);
														}

													}
												}

												silicon_ioi.ioiCommon.select_supplier(fields, fields_len, fields_desc, where, order_by, fct_callback);

											}else if (options.toString().toUpperCase() == 'IOI MANUFACTURER CATALOG') {

												let fields = 'a.name, a.item_id, a.description, a.unit_id, a.item_id_live_creation, b.supplier_id, a.barcode_reference, ';
												fields += 'a.family_id, a.type_id, a.group_id, a.subgroup_id, a.shop_category_id, a.classification_id, a.category_id, a.budget_id';
												let fields_len = '300, 200, 350, 100, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200';
												let fields_desc = 'Identification, Item, Description, Unit, Item id live creation, Supplier, Barcode ref, ';
												fields_desc += 'Family, Type, Group, Subgroup, Shop Category, Classification, Category, Budget type';
												let where = "a.enabled = 1 ";
												let order_by = 'a.name asc';


												let document_date = null;
												let currency_id = null;

												if (me.ManufacturerCatalogWindowParams != null) {

													let ret = me.ManufacturerCatalogWindowParams(bt_fieldname, fieldname, row);

													fields = ret[0];
													fields_len = ret[1];
													fields_desc = ret[2];
													where = ret[3];
													order_by = ret[4];
													document_date = ret[5];
													currency_id = ret[6];
												}



												let fct_callback = function (manufacture_catalog_id, item_id) {

													if (manufacture_catalog_id != row.getData()[fieldname]) {

														let s = '{"' + fieldname + '":"' + manufacture_catalog_id + '"}';
														row.update(JSON.parse(s));

														row.update({"tabulator_record_modified":"Y"});

														if (me.RecordChangeState) {
															me.RecordChangeState('MODIFIED');
														}

														if (me.CellButton_Callback != null) {
															me.CellButton_Callback(fieldname, manufacture_catalog_id, row);
														}

													}


												};

												silicon_ioi.ioiCommon.select_manufacturer_catalog(fields, fields_len, fields_desc, where, order_by, document_date, fct_callback, false);

											}else{

												let title = __("Select");
												let form_width_pixel = '710px';
												let table = options;
												let fields = 'name, description';
												let fields_len = '250, 380';
												let fields_desc = __('Identification') + ',' + __('Description');
												let where = '';
												let order_by = 'name asc';

												if (me.DoctypeWindowParams != null) {

													let ret = me.DoctypeWindowParams(bt_fieldname, fieldname, row);

													title = ret[0];
													form_width_pixel = re[1];
													fields = ret[2];
													fields_len = ret[3];
													fields_desc = ret[4];
													where = ret[5];
													order_by = ret[6];
												}


												let fct_callback = function (return_value) {

													if (return_value != row.getData()[fieldname]) {

														let s = '{"' + fieldname + '":"' + return_value + '"}';
														row.update(JSON.parse(s));

														row.update({"tabulator_record_modified":"Y"});

														if (me.RecordChangeState) {
															me.RecordChangeState('MODIFIED');
														}

														if (me.CellButton_Callback != null) {
															me.CellButton_Callback(fieldname, manufacture_catalog_id, row);
														}
													}
												}

												silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);
											}
										}
									}
								}
							}

							button.onclick = bt_click;
							return button;
						};

						column_editor = null;
						column_formatter = buttonEditor;

						me.ioiTable.addColumn(
							{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, cellClick: cell_onclick, headerSort: false}
						);

						column_field = old_column_field;

					}

					// Jump
					let jump_to_module = false;

					if (me.jump_fields.length > 0) {

						for (var i = 0; i < me.jump_fields.length ; i++) {

							if (column_field.toUpperCase() == me.jump_fields[i].toUpperCase()) {

								jump_to_module = true;
								break;
							}
						}
					}

					if (jump_to_module) {

						column_title = '';
						column_field = 'button_jump_' + column_field;
						column_visible = true;
						column_frozen = false;
						column_hozAlign = true;

						var buttonEditor = (cell, formatterParams, onRendered) => {

							var button = document.createElement("button");
							button.style.width = "100%";
							button.style.height = "100%";
							button.style.border = 'none';
							button.width = "100%";
							button.height = "100%";
							button.innerText = '->';
							button.title = __("Jump to module");


							let input = null;

							if (me.Format_Cell != null) {
								me.Format_Cell(col, cell, input);
							}

							var bt_click = function() {


								for (var k = 0; k < me.columns.length; k++) {

									if (me.columns[k].fieldtype.toUpperCase() == 'DYNAMIC LINK') {

										if (me.columns[k].fieldname.toUpperCase() == cell.getColumn().getField().substring(12, cell.getColumn().getField().length).toUpperCase()) {

											let field_options = me.columns[k].options;

											field_options = cell.getRow().getData()[field_options];

											if (field_options != null) {

												if (field_options.trim() != '') {

													let value = cell.getRow().getData()[me.columns[k].fieldname];

													if (value != null) {

														if (value.trim() != '') {

															let ret_value = 0;

															let method = me.path_common + '.check_value_in_link';

															frappe.call({  	method: method,
																			args: {"doctype": field_options, "value": value},
																			async: false,
																			callback:function(r)	{
																				ret_value = r.message;
																			}
															});

															if (ret_value == 1) {

																let s = field_options.toLowerCase();

																s = s.replaceAll(' ', '-');

																let url = '/app/' + s + '/' + value;

																window.open(url, "_blank");
															}
														}
													}
												}
											}
										}
									}
								}
							}

							button.onclick = bt_click;
							return button;
						};

						column_editor = null;
						column_formatter = buttonEditor;

						me.ioiTable.addColumn(
							{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, cellClick: cell_onclick, headerSort: false}
						);

						column_field = old_column_field;
					}

				}else if ((col.fieldtype.toUpperCase() == 'LONG TEXT') || (col.fieldtype.toUpperCase() == 'SMALL TEXT') || (col.fieldtype.toUpperCase() == 'TEXT')) {

					column_title = '';
					column_field = 'button_' + column_field;
					column_visible = true;
					column_frozen = false;
					column_hozAlign = true;

					var buttonEditor = (cell, formatterParams, onRendered) => {

						var button = document.createElement("button");
						button.style.width = "100%";
						button.style.height = "100%";
						button.style.border = 'none';
						button.width = "100%";
						button.height = "100%";
						button.innerText = '...';


						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}


						var bt_click = function() {

							let bt_fieldname = cell.getColumn().getField();
							let fieldname = bt_fieldname.substring(7, bt_fieldname.length);

							let row = cell.getRow();

							var memoDialog = new frappe.ui.Dialog({
								title: __("Memo"),
								'fields': [
									{'fieldname': 'html_ioidoctypegrid_memo_dialog', 'fieldtype': 'HTML'}

								],
								primary_action_label: __("Ok"),
								primary_action: function(){

									if (col.read_only != 1) {

										let content = document.getElementById('html_ioidoctypegrid_memo_dialog_memo').value;
										let s = '';
										let output = '';

										if (content.indexOf('\n') == -1) {
											s = '{"' + fieldname + '":"' + document.getElementById('html_ioidoctypegrid_memo_dialog_memo').value + '"}';

											output = document.getElementById('html_ioidoctypegrid_memo_dialog_memo').value;
										}else{

											output = ''

											while (content.indexOf('\n') != -1) {

												output += content.substring(0, content.indexOf('\n')) + '\\n';
												content = content.substring(content.indexOf('\n')+1, content.length);
											}

											if (content.trim() != '') {
												output += content;
											}

											s = '{"' + fieldname + '":"' + output + '"}';
										}


										row.update(JSON.parse(s));

										row.update({"tabulator_record_modified":"Y"});

										if (me.RecordChangeState) {
											me.RecordChangeState('MODIFIED');
										}

										if (me.Memo_Callback != null) {
											me.Memo_Callback(fieldname, output, row);
										}


									}

									memoDialog.hide();
								},
								secondary_action_label: __("Cancel"),
								secondary_action: function(){
									memoDialog.hide();
								}

							});

							if (document.getElementById('html_ioidoctypegrid_memo_dialog_memo')) {
								document.getElementById('html_ioidoctypegrid_memo_dialog_memo').remove();
							}

							let html = '';

							html += '<div style="overflow: auto; overflow-x: auto; height:210px;">';

							html += '	<div style="position: relative; top: 0px; left: 0px; width:500px;">';
							html += '		<div class="control-input" style="position: absolute; top: 2px; left: 0px; width: 750px; height: 25px;"> ';
							html += '			<textarea id="html_ioidoctypegrid_memo_dialog_memo" style="position: absolute; top: 0px; left: 2px; width: 750px; height: 200px; resize: none;" class="input-with-feedback form-control bold">';
							html += '			</textarea>';
							html += '		</div>';
							html += '	</div>';

							html += '</div>';

							memoDialog.fields_dict.html_ioidoctypegrid_memo_dialog.$wrapper.html(html);
							memoDialog.$wrapper.find('.modal-dialog').css("max-width", "800px").css("width", "800px");
							memoDialog.$wrapper.find('.modal-dialog').css("max-height", "550px").css("height", "550px");

							memoDialog.show();

							me.sleep(200).then(() => {

								document.getElementById('html_ioidoctypegrid_memo_dialog_memo').value = row.getData()[fieldname];
								document.getElementById('html_ioidoctypegrid_memo_dialog_memo').readOnly = false;

								if (col.read_only == 1) {
									document.getElementById('html_ioidoctypegrid_memo_dialog_memo').readOnly = true;
								}

							});


						}

						button.onclick = bt_click;
						return button;
					};

					column_editor = null;
					column_formatter = buttonEditor;

					me.ioiTable.addColumn(
						{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, cellClick: cell_onclick, headerSort: false}
					);

				}else if ((col.fieldtype.toUpperCase() == 'CODE') || (col.fieldtype.toUpperCase() == 'HTML EDITOR') || (col.fieldtype.toUpperCase() == 'MARKDOWN EDITOR') || (col.fieldtype.toUpperCase() == 'TEXT EDITOR')) {

					column_title = '';
					column_field = 'button_' + column_field;
					column_visible = true;
					column_frozen = false;
					column_hozAlign = true;

					var buttonEditor = (cell, formatterParams, onRendered) => {

						var button = document.createElement("button");
						button.style.width = "100%";
						button.style.height = "100%";
						button.style.border = 'none';
						button.width = "100%";
						button.height = "100%";
						button.innerText = '...';


						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}


						var bt_click = function() {

							let bt_fieldname = cell.getColumn().getField();
							let fieldname = bt_fieldname.substring(7, bt_fieldname.length);

							let row = cell.getRow();

							let field_type = '';

							if (col.fieldtype.toUpperCase() == 'CODE') {

								field_type = 'Code';

							}else if (col.fieldtype.toUpperCase() == 'HTML EDITOR') {

								field_type = 'HTML Editor';

							}else if (col.fieldtype.toUpperCase() == 'MARKDOWN EDITOR') {

								field_type = 'Markdown Editor';

							}else if (col.fieldtype.toUpperCase() == 'TEXT EDITOR') {

								field_type = 'Text Editor';
							}


							var memoDialog = new frappe.ui.Dialog({
								title: __("Memo"),

								fields: [{
											fieldname: 'html_ioidoctypegrid_memo_dialog_memo',
											fieldtype: field_type
										}],
								primary_action_label: __("Ok"),
								primary_action: function(){

									if (col.read_only != 1) {

										let content = cur_dialog.fields_dict['html_ioidoctypegrid_memo_dialog_memo'].value;

										let s = '';
										let output = '';

										if (col.fieldtype.toUpperCase() != 'TEXT EDITOR') {

											if (content.indexOf('\n') == -1) {
												s = '{"' + fieldname + '":"' + cur_dialog.fields_dict['html_ioidoctypegrid_memo_dialog_memo'].value + '"}';

												output = cur_dialog.fields_dict['html_ioidoctypegrid_memo_dialog_memo'].value;
											}else{

												output = ''

												while (content.indexOf('\n') != -1) {

													output += content.substring(0, content.indexOf('\n')) + '\\n';
													content = content.substring(content.indexOf('\n')+1, content.length);
												}

												if (content.trim() != '') {
													output += content;
												}

												s = '{"' + fieldname + '":"' + output + '"}';
											}
										}else{
											s = '{"' + fieldname + '":"' + cur_dialog.fields_dict['html_ioidoctypegrid_memo_dialog_memo'].value + '"}';
											output = cur_dialog.fields_dict['html_ioidoctypegrid_memo_dialog_memo'].value;
										}


										row.update(JSON.parse(s));

										row.update({"tabulator_record_modified":"Y"});

										if (me.RecordChangeState) {
											me.RecordChangeState('MODIFIED');
										}

										if (me.Memo_Callback != null) {
											me.Memo_Callback(fieldname, output, row);
										}


									}


									memoDialog.hide();
								},
								secondary_action_label: __("Cancel"),
								secondary_action: function(){
									memoDialog.hide();
								}

							});


							memoDialog.$wrapper.find('.modal-dialog').css("max-width", "800px").css("width", "800px");
							memoDialog.$wrapper.find('.modal-dialog').css("max-height", "550px").css("height", "550px");

							memoDialog.show();

							me.sleep(200).then(() => {

								memoDialog.set_value('html_ioidoctypegrid_memo_dialog_memo', row.getData()[fieldname]);

								if (col.read_only == 1) {
									memoDialog.set_df_property('html_ioidoctypegrid_memo_dialog_memo', 'read_only', 1);
								}else{
									memoDialog.set_df_property('html_ioidoctypegrid_memo_dialog_memo', 'read_only', 0);
								}
							});


						}

						button.onclick = bt_click;
						return button;
					};

					column_editor = null;
					column_formatter = buttonEditor;

					me.ioiTable.addColumn(
						{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, cellClick: cell_onclick, headerSort: false}
					);

				}else if (col.fieldtype.toUpperCase() == 'DURATION') {

					column_title = '';
					column_field = 'button_' + column_field;
					column_visible = true;
					column_frozen = false;
					column_hozAlign = true;

					var buttonEditor = (cell, formatterParams, onRendered) => {

						var button = document.createElement("button");
						button.style.width = "100%";
						button.style.height = "100%";
						button.style.border = 'none';
						button.width = "100%";
						button.height = "100%";
						button.innerText = '...';

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}

						var bt_click = function() {

							let bt_fieldname = cell.getColumn().getField();
							let fieldname = bt_fieldname.substring(7, bt_fieldname.length);

							let row = cell.getRow();

							let duration = 0;

							if (row.getData()[fieldname] != null) {
								duration = parseInt(row.getData()[fieldname]);
							}

							let ret = me.duration_convert(duration);

							let days = ret[0];
							let hours = ret[1];
							let minutes = ret[2];
							let seconds = ret[3];

							let bt_ok_caption = __("Ok");

							if (col.read_only == 1) {
								bt_ok_caption = __("Close");
							}

							var durationDialog = new frappe.ui.Dialog({
								title: __("Duration"),
								'fields': [
									{'fieldname': 'html_ioidoctypegrid_duration_dialog', 'fieldtype': 'HTML'}

								],
								primary_action_label: bt_ok_caption,
								primary_action: function(){

									if (col.read_only == 0) {

										let ret_value = 0;

										if (document.getElementById('html_ioidoctypegrid_duration_dialog_days')) {

											if ((document.getElementById('html_ioidoctypegrid_duration_dialog_days').value != null) && (document.getElementById('html_ioidoctypegrid_duration_dialog_days').value != '')) {

												ret_value += (parseInt(document.getElementById('html_ioidoctypegrid_duration_dialog_days').value) * 86400);
											}
										}

										if (document.getElementById('html_ioidoctypegrid_duration_dialog_hours')) {

											if ((document.getElementById('html_ioidoctypegrid_duration_dialog_hours').value != null) && (document.getElementById('html_ioidoctypegrid_duration_dialog_hours').value != '')) {

												ret_value += (parseInt(document.getElementById('html_ioidoctypegrid_duration_dialog_hours').value) * 3600);
											}
										}

										if (document.getElementById('html_ioidoctypegrid_duration_dialog_minutes')) {

											if ((document.getElementById('html_ioidoctypegrid_duration_dialog_minutes').value != null) && (document.getElementById('html_ioidoctypegrid_duration_dialog_minutes').value != '')) {

												ret_value += (parseInt(document.getElementById('html_ioidoctypegrid_duration_dialog_minutes').value) * 60);
											}
										}

										if (document.getElementById('html_ioidoctypegrid_duration_dialog_seconds')) {

											if ((document.getElementById('html_ioidoctypegrid_duration_dialog_seconds').value != null) && (document.getElementById('html_ioidoctypegrid_duration_dialog_seconds').value != '')) {

												ret_value += parseInt(document.getElementById('html_ioidoctypegrid_duration_dialog_seconds').value);
											}
										}

										let s = '{"' + fieldname + '":' + ret_value.toString() + '}';

										row.update(JSON.parse(s));

										row.update({"tabulator_record_modified":"Y"});

										if (me.RecordChangeState) {
											me.RecordChangeState('MODIFIED');
										}

										if (me.Duration_Callback != null) {
											me.Duration_Callback(fieldname, ret_value, row);
										}

									}

									durationDialog.hide();
								},
								secondary_action_label: __("Cancel"),
								secondary_action: function(){
									durationDialog.hide();
								}

							});

							if (document.getElementById('html_ioidoctypegrid_duration_dialog_days_label')) {
								document.getElementById('html_ioidoctypegrid_duration_dialog_days_label').remove();
							}

							if (document.getElementById('html_ioidoctypegrid_duration_dialog_days')) {
								document.getElementById('html_ioidoctypegrid_duration_dialog_days').remove();
							}


							if (document.getElementById('html_ioidoctypegrid_duration_dialog_hours_label')) {
								document.getElementById('html_ioidoctypegrid_duration_dialog_hours_label').remove();
							}

							if (document.getElementById('html_ioidoctypegrid_duration_dialog_hours')) {
								document.getElementById('html_ioidoctypegrid_duration_dialog_hours').remove();
							}


							if (document.getElementById('html_ioidoctypegrid_duration_dialog_minutes_label')) {
								document.getElementById('html_ioidoctypegrid_duration_dialog_minutes_label').remove();
							}

							if (document.getElementById('html_ioidoctypegrid_duration_dialog_minutes')) {
								document.getElementById('html_ioidoctypegrid_duration_dialog_minutes').remove();
							}


							if (document.getElementById('html_ioidoctypegrid_duration_dialog_seconds_label')) {
								document.getElementById('html_ioidoctypegrid_duration_dialog_seconds_label').remove();
							}

							if (document.getElementById('html_ioidoctypegrid_duration_dialog_seconds')) {
								document.getElementById('html_ioidoctypegrid_duration_dialog_seconds').remove();
							}

							let panel_width = 250;


							if (col.hide_days == 1) {
								panel_width -= 70;
							}

							if (col.hide_seconds == 1) {
								panel_width -= 70;
							}


							let html = '';

							html += '<div style="overflow: auto; overflow-x: auto; height:60px;">';

							html += '	<div style="position: relative; top: 0px; left: 0px; width:' + panel_width.toString() + 'px;">';

							let left = 0;

							if (col.hide_days == 0) {
								html += '		<div class="control-input" style="position: absolute; top: 2px; left: 0px; width: 60px; height: 30px;"> ';
								html += '			<label id="html_ioidoctypegrid_duration_dialog_days_label" style="position: absolute; top: 0px; left: 2px;">' + __("Day(s)") + '</label>';
								html += '			<input id="html_ioidoctypegrid_duration_dialog_days" type="number" step="any" min="0" style="position: absolute; top: 25px; left: 2px; width: 60px; height: 30px;" class="input-with-feedback form-control bold">';
								html += '		</div>';

								left = 70;
							}

							html += '		<div class="control-input" style="position: absolute; top: 2px; left:' + left.toString() + 'px; width: 60px; height: 30px;"> ';
							html += '			<label id="html_ioidoctypegrid_duration_dialog_hours_label" style="position: absolute; top: 0px; left: 2px;">' + __("Hour(s)") + '</label>';
							html += '			<input id="html_ioidoctypegrid_duration_dialog_hours" type="number" step="any" min="0" max="23" style="position: absolute; top: 25px; left: 2px; width: 60px; height: 30px;" class="input-with-feedback form-control bold">';
							html += '		</div>';

							left += 70;

							html += '		<div class="control-input" style="position: absolute; top: 2px; left:' + left.toString() + 'px; width: 60px; height: 30px;"> ';
							html += '			<label id="html_ioidoctypegrid_duration_dialog_minutes_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Min(s)") + '</label>';
							html += '			<input id="html_ioidoctypegrid_duration_dialog_minutes" type="number" step="any" min="0" max="59" style="position: absolute; top: 25px; left: 2px; width: 60px; height: 30px;" class="input-with-feedback form-control bold">';
							html += '		</div>';

							left += 70;

							if (col.hide_seconds == 0) {

								html += '		<div class="control-input" style="position: absolute; top: 2px; left:' + left.toString() + 'px; width: 60px; height: 30px;"> ';
								html += '			<label id="html_ioidoctypegrid_duration_dialog_seconds_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Sec(s)") + '</label>';
								html += '			<input id="html_ioidoctypegrid_duration_dialog_seconds" type="number" step="any" min="0" max="59" style="position: absolute; top: 25px; left: 2px; width: 60px; height: 30px;" class="input-with-feedback form-control bold">';
								html += '		</div>';
							}

							html += '	</div>';

							html += '</div>';

							durationDialog.fields_dict.html_ioidoctypegrid_duration_dialog.$wrapper.html(html);


							let form_width = 320;

							if (col.hide_days == 1) {
								form_width -= 70;
							}

							if (col.hide_seconds == 1) {
								form_width -= 70;
							}


							durationDialog.$wrapper.find('.modal-dialog').css("max-width", form_width.toString() + "px").css("width", form_width.toString() + "px");


							durationDialog.show();

							me.sleep(200).then(() => {

								if (col.read_only == 1) {
									document.getElementsByClassName('btn btn-secondary btn-sm btn-modal-secondary')[document.getElementsByClassName('btn btn-secondary btn-sm btn-modal-secondary').length-1].style.display = 'none';
								}

								let set_focus = false;


								if (document.getElementById('html_ioidoctypegrid_duration_dialog_days')) {
									if (days != 0) {
										document.getElementById('html_ioidoctypegrid_duration_dialog_days').value = days;
										document.getElementById('html_ioidoctypegrid_duration_dialog_days').focus();
										set_focus = true;
									}

									if (col.read_only == 1) {
										document.getElementById('html_ioidoctypegrid_duration_dialog_days').disabled = true;
									}

								}

								if (document.getElementById('html_ioidoctypegrid_duration_dialog_hours')) {
									if (hours != 0) {
										document.getElementById('html_ioidoctypegrid_duration_dialog_hours').value = hours;

										if (!set_focus) {
											document.getElementById('html_ioidoctypegrid_duration_dialog_hours').focus();
											set_focus = true;
										}
									}

									if (col.read_only == 1) {
										document.getElementById('html_ioidoctypegrid_duration_dialog_hours').disabled = true;
									}

								}

								if (document.getElementById('html_ioidoctypegrid_duration_dialog_minutes')) {
									if (minutes != 0) {
										document.getElementById('html_ioidoctypegrid_duration_dialog_minutes').value = minutes;

										if (!set_focus) {
											document.getElementById('html_ioidoctypegrid_duration_dialog_minutes').focus();
											set_focus = true;
										}
									}

									if (col.read_only == 1) {
										document.getElementById('html_ioidoctypegrid_duration_dialog_minutes').disabled = true;
									}

								}

								if (document.getElementById('html_ioidoctypegrid_duration_dialog_seconds')) {
									if (seconds != 0) {
										document.getElementById('html_ioidoctypegrid_duration_dialog_seconds').value = seconds;

										if (!set_focus) {
											document.getElementById('html_ioidoctypegrid_duration_dialog_seconds').focus();
											set_focus = true;
										}
									}

									if (col.read_only == 1) {
										document.getElementById('html_ioidoctypegrid_duration_dialog_seconds').disabled = true;
									}
								}
							});
						}

						button.onclick = bt_click;
						return button;

					};

					column_editor = null;
					column_formatter = buttonEditor;

					me.ioiTable.addColumn(
						{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, cellClick: cell_onclick, headerSort: false}
					);


				}else if (col.fieldtype.toUpperCase() == 'COLOR') {

					column_title = '';
					column_field = 'button_' + column_field;
					column_visible = true;
					column_frozen = false;
					column_hozAlign = true;

					var buttonEditor = (cell, formatterParams, onRendered) => {

						var button = document.createElement("button");
						button.style.width = "100%";
						button.style.height = "100%";
						button.style.border = 'none';
						button.width = "100%";
						button.height = "100%";
						button.innerText = '...';

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}

						var bt_click = function() {

							let bt_fieldname = cell.getColumn().getField();
							let fieldname = bt_fieldname.substring(7, bt_fieldname.length);

							let row = cell.getRow();

							let bt_ok_caption = __("Ok");

							if (col.read_only == 1) {
								bt_ok_caption = __("Close");
							}

							var colorDialog = new frappe.ui.Dialog({
								title: __("Color"),
								'fields': [
									{'fieldname': 'html_ioidoctypegrid_color_dialog', 'fieldtype': 'Color'}
								],
								primary_action_label: bt_ok_caption,
								primary_action: function(){

									if (col.read_only == 0) {

										let ret_value = colorDialog.fields_dict['html_ioidoctypegrid_color_dialog'].value.toString();

										let s = '{"' + fieldname + '":"' + ret_value + '"}';

										row.update(JSON.parse(s));

										row.update({"tabulator_record_modified":"Y"});

										if (me.RecordChangeState) {
											me.RecordChangeState('MODIFIED');
										}

									}

									colorDialog.hide();
								},
								secondary_action_label: __("Cancel"),
								secondary_action: function(){
									colorDialog.hide();
								}

							});


							colorDialog.$wrapper.find('.modal-dialog').css("max-width", "320px").css("width", "320px");


							colorDialog.show();

							me.sleep(200).then(() => {

								if (col.read_only == 1) {
									document.getElementsByClassName('btn btn-secondary btn-sm btn-modal-secondary')[document.getElementsByClassName('btn btn-secondary btn-sm btn-modal-secondary').length-1].style.display = 'none';
								}


								if ((row.getData()[fieldname] != null) && (row.getData()[fieldname] != '')) {
									colorDialog.set_value('html_ioidoctypegrid_color_dialog', row.getData()[fieldname])
								}

								if (col.read_only == 1) {
									colorDialog.set_df_property('html_ioidoctypegrid_color_dialog', 'read_only', 1);
								}else{
									colorDialog.set_df_property('html_ioidoctypegrid_color_dialog', 'read_only', 0);
								}

							});
						}

						button.onclick = bt_click;
						return button;

					};

					column_editor = null;
					column_formatter = buttonEditor;

					me.ioiTable.addColumn(
						{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, cellClick: cell_onclick, headerSort: false}
					);

				}else if (col.fieldname.toUpperCase() == 'IOISTATUS') {

					if (me.allow_change_status) {

						column_title = '';
						column_field = 'button_' + column_field;
						column_visible = true;
						column_frozen = false;
						column_hozAlign = true;

						var buttonEditor = (cell, formatterParams, onRendered) => {

							var button = document.createElement("button");
							button.style.width = "100%";
							button.style.height = "100%";
							button.style.border = 'none';
							button.width = "100%";
							button.height = "100%";
							button.innerText = '...';

							let input = null;

							if (me.Format_Cell != null) {
								me.Format_Cell(col, cell, input);
							}

							var bt_click = function() {

								let bt_fieldname = cell.getColumn().getField();
								let fieldname = bt_fieldname.substring(7, bt_fieldname.length);

								let row = cell.getRow();

								let ioistatus_inactive = [];

								let method = me.path_module_constraint + '.ioi_module_document_constraint_get_constraint_for_a_status';

								frappe.call({  	method: method,
												args: {"doctype": me.doctype, "status" : row.getData()["ioistatus"]},
												async: false,
												callback:function(r)	{
																			if (r.message.length != 0) {

																				ioistatus_inactive = [];

																				for (var i = 0; i < r.message.length; i++) {
																					ioistatus_inactive[i] = r.message[i];
																				}
																			}
																		}
								});



								let data_ioistatus = [];

								for (var i = 0; i < me.ioistatus.length; i++) {

									if (row.getData()["ioistatus"] != me.ioistatus[i][1]) {

										if (ioistatus_inactive.length == 0) {
											data_ioistatus[data_ioistatus.length] = [me.ioistatus[i][1], me.ioistatus[i][2], me.ioistatus[i][3], me.ioistatus[i][4]];
										}else{

											let to_insert = true;

											for (var j = 0; j < ioistatus_inactive.length; j++) {

												if (ioistatus_inactive[j] == me.ioistatus[i][1]) {

													to_insert = false;
													break;
												}
											}

											if (to_insert) {
												data_ioistatus[data_ioistatus.length] = [me.ioistatus[i][1], me.ioistatus[i][2], me.ioistatus[i][3], me.ioistatus[i][4]];
											}

										}
									}
								}


								var ioistatusDialog = new frappe.ui.Dialog({
									title: __("To status"),
									'fields': [
										{'fieldname': 'html_ioidoctypegrid_ioistatus_dialog', 'fieldtype': 'HTML'}
									],
									primary_action_label: __("Ok"),
									primary_action: function(){

										if (document.getElementById('html_ioidoctypegrid_ioistatus_dialog_grid_detail')) {

											let value = '';

											for (var i = 0; i < document.getElementById('html_ioidoctypegrid_ioistatus_dialog_grid_detail').rows.length; i++) {

												if (document.getElementById('html_ioidoctypegrid_ioistatus_dialog_grid_detail_checked_id_' + i.toString()).checked) {

													value = document.getElementById('html_ioidoctypegrid_ioistatus_dialog_grid_detail_to_status_id_' + i.toString()).value;
													break;
												}
											}

											if (value != '') {

												let fieldname = 'tabulator_to_ioistatus'
												let ret_value = value;

												let s = '{"' + fieldname + '":"' + ret_value + '"}';

												row.update(JSON.parse(s));

												row.update({"tabulator_record_modified":"Y"});

												if (me.RecordChangeState) {
													me.RecordChangeState('MODIFIED');
												}
											}
										}


										ioistatusDialog.hide();
									},
									secondary_action_label: __("Cancel"),
									secondary_action: function(){
										ioistatusDialog.hide();
									}

								});

								let html = '';

								if (document.getElementById('html_ioidoctypegrid_ioistatus_dialog_content')) {
									document.getElementById('html_ioidoctypegrid_ioistatus_dialog_content').remove();
								}

								if (document.getElementById('html_ioidoctypegrid_ioistatus_dialog_grid')) {
									document.getElementById('html_ioidoctypegrid_ioistatus_dialog_grid').remove();
								}

								if (document.getElementById('html_ioidoctypegrid_ioistatus_dialog_grid_detail')) {

									for (var i = 0; i < document.getElementById('html_ioidoctypegrid_ioistatus_dialog_grid_detail').rows.length; i++) {

										if (document.getElementById('html_ioidoctypegrid_ioistatus_dialog_grid_detail_checked_id_' + i.toString())) {
											document.getElementById('html_ioidoctypegrid_ioistatus_dialog_grid_detail_checked_id_' + i.toString()).remove();
										}

										if (document.getElementById('html_ioidoctypegrid_ioistatus_dialog_grid_detail_to_status_id_' + i.toString())) {
											document.getElementById('html_ioidoctypegrid_ioistatus_dialog_grid_detail_to_status_id_' + i.toString()).remove();
										}

									}

									document.getElementById('html_ioidoctypegrid_ioistatus_dialog_grid_detail').remove();

								}


								// Grid Header
								html += '<div id="html_ioidoctypegrid_ioistatus_dialog_content" style="overflow: auto; overflow-x: auto; height:392px;">';

								html += '	<table id="html_ioidoctypegrid_ioistatus_dialog_grid" border=1 style="border: 1px solid #E8EAEB" width=300px data-custom-grid="true">';
								html += '	<tr style="height:30px">';
								html += '	<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
								html += '	<td id="html_ioidoctypegrid_ioistatus_dialog_col_name"	width=270px style="vertical-align: middle;"><b>&nbsp;' + __("To status") + '</b></td>';
								html += '	</tr>';
								html += '	</table>';

								if (data_ioistatus.length > 0) {

									html += '	<table id="html_ioidoctypegrid_ioistatus_dialog_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=300px>';
									for (var i = 0; i < data_ioistatus.length; i++) {

										html += '	<input type="hidden" id="html_ioidoctypegrid_ioistatus_dialog_grid_detail_to_status_id_' + i.toString() +'" value="' + data_ioistatus[i][0] + '"> ';

										html += '	<tr bgcolor="' + data_ioistatus[i][2] + '" style="height:30px">';
										html += '	<td width=30px align="center" style="vertical-align: middle;">';

										html += '	<input type="checkbox" id="html_ioidoctypegrid_ioistatus_dialog_grid_detail_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;" ';
										html += '       onkeydown="   if (event.keyCode == 13) { return false; } " ';
										html += '       onkeyup="   if (event.keyCode == 13) { return false; } " ';
										html += 	'>';
										html += '	</td>';
										html += '	<td width=270px style="vertical-align: middle;">&nbsp;<font color="' + data_ioistatus[i][3] + '">' + data_ioistatus[i][1] + '</font></td>';
										html += '	</tr>';
									}
									html += '	</table>';
								}


								html += '</div>';

								ioistatusDialog.fields_dict.html_ioidoctypegrid_ioistatus_dialog.$wrapper.html(html);

								ioistatusDialog.$wrapper.find('.modal-dialog').css("max-width", "345px").css("width", "345px");


								ioistatusDialog.show();

								me.sleep(200).then(() => {

									if (document.getElementById('html_ioidoctypegrid_ioistatus_dialog_grid_detail')) {

										let fct_click = function() {

											for (var i = 0; i < document.getElementById('html_ioidoctypegrid_ioistatus_dialog_grid_detail').rows.length; i++) {
												document.getElementById('html_ioidoctypegrid_ioistatus_dialog_grid_detail_checked_id_' + i.toString()).checked = false;
											}

											this.checked = true;
										}

										let is_checked = false;

										for (var i = 0; i < document.getElementById('html_ioidoctypegrid_ioistatus_dialog_grid_detail').rows.length; i++) {

											document.getElementById('html_ioidoctypegrid_ioistatus_dialog_grid_detail_checked_id_' + i.toString()).onclick = fct_click;

											let fieldname = 'tabulator_to_ioistatus'

											if ((row.getData()[fieldname] != null) && (row.getData()[fieldname] != '')) {

												if (row.getData()[fieldname] == document.getElementById('html_ioidoctypegrid_ioistatus_dialog_grid_detail_to_status_id_' + i.toString()).value) {
													document.getElementById('html_ioidoctypegrid_ioistatus_dialog_grid_detail_checked_id_' + i.toString()).checked = true;
													is_checked = true;
													break;
												}

											}
										}

										if (!is_checked) {
											document.getElementById('html_ioidoctypegrid_ioistatus_dialog_grid_detail_checked_id_0').checked = true;
										}



									}


								});
							}

							button.onclick = bt_click;
							return button;

						};

						column_editor = null;
						column_formatter = buttonEditor;

						me.ioiTable.addColumn(
							{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, cellClick: cell_onclick, headerSort: false}
						);


						// Field tabulator_to_ioistatus

						column_title = __("To status");
						column_field = 'tabulator_to_ioistatus';
						column_visible = true;
						column_frozen = false;
						column_hozAlign = true;

						column_editor = null;

						column_hozAlign = "left";

						let ioistatus_formatter = (cell, formatterParams, onRendered) => {

							let input = null;

							if (me.Format_Cell != null) {
								me.Format_Cell(col, cell, input);
							}


							if ((cell.getValue() != null) && (cell.getValue() != '')) {

								if (me.ioistatus.length > 0) {

									for (var i = 0; i < me.ioistatus.length; i++) {

										if (cell.getValue() == me.ioistatus[i][1]) {
											cell.getElement().style.backgroundColor = me.ioistatus[i][3];
											cell.getElement().style.color = me.ioistatus[i][4];
											return me.ioistatus[i][2];
										}
									}
								}

							}
						}

						column_formatter = ioistatus_formatter;

						me.ioiTable.addColumn(
							{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, cellClick: cell_onclick, headerSort: false}
						);



						// Clear ioistatus

						column_title = '';
						column_field = 'button_clear_' + column_field;
						column_visible = true;
						column_frozen = false;
						column_hozAlign = true;

						var buttonEditor = (cell, formatterParams, onRendered) => {

							var button = document.createElement("button");
							button.style.width = "100%";
							button.style.height = "100%";
							button.style.border = 'none';
							button.width = "100%";
							button.height = "100%";
							button.innerText = __("Clear");

							let input = null;

							if (me.Format_Cell != null) {
								me.Format_Cell(col, cell, input);
							}

							var bt_click = function() {

								let fieldname = 'tabulator_to_ioistatus'
								let ret_value = '';

								let row = cell.getRow();

								row.getCell(fieldname).getElement().style.backgroundColor = '';

								if ((row.getData()[fieldname] != null) && (row.getData()[fieldname] != '')) {

									let s = '{"' + fieldname+ '":"' + ret_value + '"}';
									row.update(JSON.parse(s));

									row.update({"tabulator_record_modified":"Y"});

									if (me.RecordChangeState) {
										me.RecordChangeState('MODIFIED');
									}
								}


							}

							button.onclick = bt_click;
							return button;

						};

						column_editor = null;
						column_formatter = buttonEditor;

						me.ioiTable.addColumn(
							{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, cellClick: cell_onclick, headerSort: false}
						);
					}

				}else if (col.fieldname.toUpperCase() == 'IOIUSERSTATUS') {

					if (me.allow_change_userstatus) {

						column_title = '';
						column_field = 'button_' + column_field;
						column_visible = true;
						column_frozen = false;
						column_hozAlign = true;

						var buttonEditor = (cell, formatterParams, onRendered) => {

							var button = document.createElement("button");
							button.style.width = "100%";
							button.style.height = "100%";
							button.style.border = 'none';
							button.width = "100%";
							button.height = "100%";
							button.innerText = '...';

							let input = null;

							if (me.Format_Cell != null) {
								me.Format_Cell(col, cell, input);
							}

							var bt_click = function() {

								let bt_fieldname = cell.getColumn().getField();
								let fieldname = bt_fieldname.substring(7, bt_fieldname.length);

								let row = cell.getRow();

								let data_ioiuserstatus = [];


								for (var i = 0; i < me.ioiuserstatus.length; i++) {

									if (row.getData()["ioiuserstatus"] != me.ioiuserstatus[i][1]) {

										data_ioiuserstatus[data_ioiuserstatus.length] = [me.ioiuserstatus[i][1], me.ioiuserstatus[i][2], me.ioiuserstatus[i][3], me.ioiuserstatus[i][4]];
									}
								}


								var ioiuserstatusDialog = new frappe.ui.Dialog({
									title: __("To user status"),
									'fields': [
										{'fieldname': 'html_ioidoctypegrid_ioiuserstatus_dialog', 'fieldtype': 'HTML'}
									],
									primary_action_label: __("Ok"),
									primary_action: function(){

										if (document.getElementById('html_ioidoctypegrid_ioiuserstatus_dialog_grid_detail')) {

											let value = '';

											for (var i = 0; i < document.getElementById('html_ioidoctypegrid_ioiuserstatus_dialog_grid_detail').rows.length; i++) {

												if (document.getElementById('html_ioidoctypegrid_ioiuserstatus_dialog_grid_detail_checked_id_' + i.toString()).checked) {

													value = document.getElementById('html_ioidoctypegrid_ioiuserstatus_dialog_grid_detail_to_status_id_' + i.toString()).value;
													break;
												}
											}

											if (value != '') {

												let fieldname = 'tabulator_to_ioiuserstatus'
												let ret_value = value;

												let s = '{"' + fieldname + '":"' + ret_value + '"}';

												row.update(JSON.parse(s));

												row.update({"tabulator_record_modified":"Y"});

												if (me.RecordChangeState) {
													me.RecordChangeState('MODIFIED');
												}
											}
										}


										ioiuserstatusDialog.hide();
									},
									secondary_action_label: __("Cancel"),
									secondary_action: function(){
										ioiuserstatusDialog.hide();
									}

								});

								let html = '';

								if (document.getElementById('html_ioidoctypegrid_ioiuserstatus_dialog_content')) {
									document.getElementById('html_ioidoctypegrid_ioiuserstatus_dialog_content').remove();
								}

								if (document.getElementById('html_ioidoctypegrid_ioiuserstatus_dialog_grid')) {
									document.getElementById('html_ioidoctypegrid_ioiuserstatus_dialog_grid').remove();
								}

								if (document.getElementById('html_ioidoctypegrid_ioiuserstatus_dialog_grid_detail')) {

									for (var i = 0; i < document.getElementById('html_ioidoctypegrid_ioiuserstatus_dialog_grid_detail').rows.length; i++) {

										if (document.getElementById('html_ioidoctypegrid_ioiuserstatus_dialog_grid_detail_checked_id_' + i.toString())) {
											document.getElementById('html_ioidoctypegrid_ioiuserstatus_dialog_grid_detail_checked_id_' + i.toString()).remove();
										}

										if (document.getElementById('html_ioidoctypegrid_ioiuserstatus_dialog_grid_detail_to_status_id_' + i.toString())) {
											document.getElementById('html_ioidoctypegrid_ioiuserstatus_dialog_grid_detail_to_status_id_' + i.toString()).remove();
										}

									}

									document.getElementById('html_ioidoctypegrid_ioiuserstatus_dialog_grid_detail').remove();

								}


								// Grid Header
								html += '<div id="html_ioidoctypegrid_ioiuserstatus_dialog_content" style="overflow: auto; overflow-x: auto; height:392px;">';

								html += '	<table id="html_ioidoctypegrid_ioiuserstatus_dialog_grid" border=1 style="border: 1px solid #E8EAEB" width=300px data-custom-grid="true">';
								html += '	<tr style="height:30px">';
								html += '	<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
								html += '	<td id="html_ioidoctypegrid_ioiuserstatus_dialog_col_name"	width=270px style="vertical-align: middle;"><b>&nbsp;' + __("To status") + '</b></td>';
								html += '	</tr>';
								html += '	</table>';

								if (data_ioiuserstatus.length > 0) {

									html += '	<table id="html_ioidoctypegrid_ioiuserstatus_dialog_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=300px>';
									for (var i = 0; i < data_ioiuserstatus.length; i++) {

										html += '	<input type="hidden" id="html_ioidoctypegrid_ioiuserstatus_dialog_grid_detail_to_status_id_' + i.toString() +'" value="' + data_ioiuserstatus[i][0] + '"> ';

										html += '	<tr bgcolor="' + data_ioiuserstatus[i][2] + '" style="height:30px">';
										html += '	<td width=30px align="center" style="vertical-align: middle;">';

										html += '	<input type="checkbox" id="html_ioidoctypegrid_ioiuserstatus_dialog_grid_detail_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;" ';
										html += '       onkeydown="   if (event.keyCode == 13) { return false; } " ';
										html += '       onkeyup="   if (event.keyCode == 13) { return false; } " ';
										html += 	'>';
										html += '	</td>';
										html += '	<td width=270px style="vertical-align: middle;">&nbsp;<font color="' + data_ioiuserstatus[i][3] + '">' + data_ioiuserstatus[i][1] + '</font></td>';
										html += '	</tr>';
									}
									html += '	</table>';
								}


								html += '</div>';

								ioiuserstatusDialog.fields_dict.html_ioidoctypegrid_ioiuserstatus_dialog.$wrapper.html(html);

								ioiuserstatusDialog.$wrapper.find('.modal-dialog').css("max-width", "345px").css("width", "345px");


								ioiuserstatusDialog.show();

								me.sleep(200).then(() => {

									if (document.getElementById('html_ioidoctypegrid_ioiuserstatus_dialog_grid_detail')) {

										let fct_click = function() {

											for (var i = 0; i < document.getElementById('html_ioidoctypegrid_ioiuserstatus_dialog_grid_detail').rows.length; i++) {
												document.getElementById('html_ioidoctypegrid_ioiuserstatus_dialog_grid_detail_checked_id_' + i.toString()).checked = false;
											}

											this.checked = true;
										}

										let is_checked = false;

										for (var i = 0; i < document.getElementById('html_ioidoctypegrid_ioiuserstatus_dialog_grid_detail').rows.length; i++) {

											document.getElementById('html_ioidoctypegrid_ioiuserstatus_dialog_grid_detail_checked_id_' + i.toString()).onclick = fct_click;

											let fieldname = 'tabulator_to_ioiuserstatus'

											if ((row.getData()[fieldname] != null) && (row.getData()[fieldname] != '')) {

												if (row.getData()[fieldname] == document.getElementById('html_ioidoctypegrid_ioiuserstatus_dialog_grid_detail_to_status_id_' + i.toString()).value) {
													document.getElementById('html_ioidoctypegrid_ioiuserstatus_dialog_grid_detail_checked_id_' + i.toString()).checked = true;
													is_checked = true;
													break;
												}

											}
										}

										if (!is_checked) {
											document.getElementById('html_ioidoctypegrid_ioiuserstatus_dialog_grid_detail_checked_id_0').checked = true;
										}



									}


								});
							}

							button.onclick = bt_click;
							return button;

						};

						column_editor = null;
						column_formatter = buttonEditor;

						me.ioiTable.addColumn(
							{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, cellClick: cell_onclick, headerSort: false}
						);


						// Field tabulator_to_ioiuserstatus

						column_title = __("To user status");
						column_field = 'tabulator_to_ioiuserstatus';
						column_visible = true;
						column_frozen = false;
						column_hozAlign = true;

						column_editor = null;

						column_hozAlign = "left";

						let ioiuserstatus_formatter = (cell, formatterParams, onRendered) => {

							let input = null;

							if (me.Format_Cell != null) {
								me.Format_Cell(col, cell, input);
							}


							if ((cell.getValue() != null) && (cell.getValue() != '')) {

								if (me.ioiuserstatus.length > 0) {

									for (var i = 0; i < me.ioiuserstatus.length; i++) {

										if (cell.getValue() == me.ioiuserstatus[i][1]) {
											cell.getElement().style.backgroundColor = me.ioiuserstatus[i][3];
											cell.getElement().style.color = me.ioiuserstatus[i][4];
											return me.ioiuserstatus[i][2];
										}
									}
								}

							}
						}

						column_formatter = ioiuserstatus_formatter;

						me.ioiTable.addColumn(
							{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, cellClick: cell_onclick, headerSort: false}
						);



						// Clear ioiuserstatus

						column_title = '';
						column_field = 'button_clear_' + column_field;
						column_visible = true;
						column_frozen = false;
						column_hozAlign = true;

						var buttonEditor = (cell, formatterParams, onRendered) => {

							var button = document.createElement("button");
							button.style.width = "100%";
							button.style.height = "100%";
							button.style.border = 'none';
							button.width = "100%";
							button.height = "100%";
							button.innerText = __("Clear");

							let input = null;

							if (me.Format_Cell != null) {
								me.Format_Cell(col, cell, input);
							}

							var bt_click = function() {

								let fieldname = 'tabulator_to_ioiuserstatus'
								let ret_value = '';

								let row = cell.getRow();

								row.getCell(fieldname).getElement().style.backgroundColor = '';

								if ((row.getData()[fieldname] != null) && (row.getData()[fieldname] != '')) {

									let s = '{"' + fieldname+ '":"' + ret_value + '"}';
									row.update(JSON.parse(s));

									row.update({"tabulator_record_modified":"Y"});

									if (me.RecordChangeState) {
										me.RecordChangeState('MODIFIED');
									}
								}


							}

							button.onclick = bt_click;
							return button;

						};

						column_editor = null;
						column_formatter = buttonEditor;

						me.ioiTable.addColumn(
							{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, cellClick: cell_onclick, headerSort: false}
						);
					}

				}
			}

			if (col.fieldtype.toUpperCase() == 'ATTACH IMAGE') {

				if (image_visible) {

					column_title = column_title;
					column_field = 'image_' + column_field;
					column_visible = true;
					column_frozen = false;
					column_hozAlign = "center";
					column_editor = null;
					cell_onclick = null;

					let image_formater = (cell, formatterParams) => {

						let bt_fieldname = cell.getColumn().getField();
						let fieldname = bt_fieldname.substring(6, bt_fieldname.length);

						let row = cell.getRow();

						let html = '';

						if ((row.getData()[fieldname] != null) && (row.getData()[fieldname] != '')) {

							let width = '100px'
							let height = '100px';

							for (var i = 0; i < me.images_properties.length; i++) {

								if (me.images_properties[i][0].toUpperCase() == fieldname.toUpperCase()) {
									if ((me.images_properties[i][1] == null) || (me.images_properties[i][1] == '') || (me.images_properties[i][1] == '0')) {
										width = '100';
									}else{
										width = me.images_properties[i][1];
									}

									if ((width.toString().indexOf('px') == -1) && (width.toString().indexOf('%') == -1)) {
										width += 'px';
									}


									if ((me.images_properties[i][2] == null) || (me.images_properties[i][2] == '') || (me.images_properties[i][2] == '0')) {
										height = '100';
									}else{
										height = me.images_properties[i][2];
									}

									if ((height.toString().indexOf('px') == -1) && (height.toString().indexOf('%') == -1)) {
										height += 'px';
									}

									column_hozAlign = "center";

									if ((me.images_properties[i][3] != null) && (me.images_properties[i][3] != '')) {
										column_hozAlign = me.images_properties[i][3];
									}

									cell.getElement().setAttribute("align", column_hozAlign);
									cell.getElement().style.textAlign = column_hozAlign;

									break;
								}
							}

							html += '<img src="' + row.getData()[fieldname] + '" width="' + width.toString() + '" height="' + height.toString() + '" onclick="window.open(\'' + row.getData()[fieldname] + '\');">';

						}

						return html;

					}

					column_formatter = image_formater;
					column_formatterParams = null;

					me.ioiTable.addColumn(
						{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, formatterParams: column_formatterParams, cellClick: cell_onclick, headerSort: false}
					);

					let old_column_field = column_field;

					// Add
					column_title = '';
					column_field = 'button_add_' + column_field;
					column_visible = true;
					column_frozen = false;
					column_hozAlign = true;

					var buttonEditor = (cell, formatterParams, onRendered) => {

						var button = document.createElement("button");
						button.style.width = "100%";
						button.style.height = "100%";
						button.style.border = 'none';
						button.width = "100%";
						button.height = "100%";
						button.innerText = __("...");

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}

						var bt_click = function() {

							let bt_fieldname = cell.getColumn().getField();
							let fieldname = bt_fieldname.substring(17, bt_fieldname.length);


							new frappe.ui.FileUploader({
														folder: "Home/Attachments",
														doctype: me.current_doctype,
														docname: me.current_name,
														force_private: false,
														allow_multiple: false,
														restrictions: {
															allowed_file_types: [".jpg", ".jpeg", ".png", ".tiff"]
														},

														on_success_terminated: (attachments) => {

															let row = cell.getRow();

															me.images_uploaded_path[me.images_uploaded_path.length] = [row.getData()["name"], fieldname, attachments[0].original_name, attachments[0].final_doc_obj.file_url];

															let ret_value = me.images_uploaded_path[me.images_uploaded_path.length-1][3];

															let s = '{"' + fieldname + '":"' + ret_value + '"}';
															row.update(JSON.parse(s));

															row.update({"tabulator_record_modified":"Y"});

															if (me.RecordChangeState) {
																me.RecordChangeState('MODIFIED');
															}


															let width = '100px'
															let height = '100px';

															for (var i = 0; i < me.images_properties.length; i++) {

																if (me.images_properties[i][0].toUpperCase() == fieldname.toUpperCase()) {

																	if ((me.images_properties[i][1] == null) || (me.images_properties[i][1] == '') || (me.images_properties[i][1] == '0')) {
																		width = '100';
																	}else{
																		width = me.images_properties[i][1];
																	}

																	if ((width.toString().indexOf('px') == -1) && (width.toString().indexOf('%') == -1)) {
																		width += 'px';
																	}


																	if ((me.images_properties[i][2] == null) || (me.images_properties[i][2] == '') || (me.images_properties[i][2] == '0')) {
																		height = '100';
																	}else{
																		height = me.images_properties[i][2];
																	}

																	if ((height.toString().indexOf('px') == -1) && (height.toString().indexOf('%') == -1)) {
																		height += 'px';
																	}

																	let column_hozAlign = "center";

																	if ((me.images_properties[i][3] != null) && (me.images_properties[i][3] != '')) {
																		column_hozAlign = me.images_properties[i][3];
																	}

																	cell.getElement().setAttribute("align", column_hozAlign);
																	cell.getElement().style.textAlign = column_hozAlign;

																	break;
																}
															}

															let html = '<img src="' + row.getData()[fieldname] + '" width="' + width.toString() + '" height="' + height.toString() + '" onclick="window.open(\'' + row.getData()[fieldname] + '\');">';


															let cells = cell.getRow().getCells();

															for (var i = 0; i < cells.length; i++) {

																if (cells[i].getColumn().getField().toUpperCase() == ('image_' + fieldname ).toUpperCase()) {

																	cells[i].getElement().innerHTML = html;
																	break;

																}
															}

														},

														make_attachments_public: cur_frm.meta.make_attachments_public

							});
						}


						button.onclick = bt_click;
						return button;

					};

					column_editor = null;
					column_formatter = buttonEditor;

					me.ioiTable.addColumn(
						{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, cellClick: cell_onclick, headerSort: false}
					);

					column_field = old_column_field;


					// Clear
					column_title = '';
					column_field = 'button_clear_' + column_field;
					column_visible = true;
					column_frozen = false;
					column_hozAlign = true;

					var buttonEditor = (cell, formatterParams, onRendered) => {

						var button = document.createElement("button");
						button.style.width = "100%";
						button.style.height = "100%";
						button.style.border = 'none';
						button.width = "100%";
						button.height = "100%";
						button.innerText = __("Clear");

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}

						var bt_click = function() {

							let bt_fieldname = cell.getColumn().getField();
							let fieldname = bt_fieldname.substring(19, bt_fieldname.length);

							let cells = cell.getRow().getCells();

							for (var i = 0; i < cells.length; i++) {

								if (cells[i].getColumn().getField().toUpperCase() == ('image_' + fieldname ).toUpperCase()) {

									cells[i].getElement().innerHTML = '';
									break;

								}
							}


							let ret_value = '';

							let row = cell.getRow();


							let s = '{"' + fieldname + '":"' + ret_value + '"}';
							row.update(JSON.parse(s));

							row.update({"tabulator_record_modified":"Y"});

							if (me.RecordChangeState) {
								me.RecordChangeState('MODIFIED');
							}
						}


						button.onclick = bt_click;
						return button;

					};

					column_editor = null;
					column_formatter = buttonEditor;

					me.ioiTable.addColumn(
						{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, cellClick: cell_onclick, headerSort: false}
					);



				}

			}

			if (col.fieldtype.toUpperCase() == 'ATTACH') {

				if (column_visible) {

					let old_column_field = column_field;

					// Add
					column_title = '';
					column_field = 'button_add_' + column_field;
					column_visible = true;
					column_frozen = false;
					column_hozAlign = true;

					var buttonEditor = (cell, formatterParams, onRendered) => {

						var button = document.createElement("button");
						button.style.width = "100%";
						button.style.height = "100%";
						button.style.border = 'none';
						button.width = "100%";
						button.height = "100%";
						button.innerText = __("...");

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}

						var bt_click = function() {

							let bt_fieldname = cell.getColumn().getField();
							let fieldname = bt_fieldname.substring(11, bt_fieldname.length);


							new frappe.ui.FileUploader({
								folder: "Home/Attachments",
								doctype: me.current_doctype,
								docname: me.current_name,
								force_private: false,
								allow_multiple: false,
								on_success_terminated: (attachments) => {

									let row = cell.getRow();

									me.files_uploaded_path[me.files_uploaded_path.length] = [row.getData()["name"], fieldname, attachments[0].original_name, attachments[0].final_doc_obj.file_url];

									let ret_value = me.files_uploaded_path[me.files_uploaded_path.length-1][3];

									let s = '{"' + fieldname + '":"' + ret_value + '"}';
									row.update(JSON.parse(s));

									row.update({"tabulator_record_modified":"Y"});

									if (me.RecordChangeState) {
										me.RecordChangeState('MODIFIED');
									}



								},

								make_attachments_public: cur_frm.meta.make_attachments_public

							});
						}


						button.onclick = bt_click;
						return button;

					};

					column_editor = null;
					column_formatter = buttonEditor;

					me.ioiTable.addColumn(
						{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, cellClick: cell_onclick, headerSort: false}
					);

					column_field = old_column_field;


					// Clear
					column_title = '';
					column_field = 'button_clear_' + column_field;
					column_visible = true;
					column_frozen = false;
					column_hozAlign = true;

					var buttonEditor = (cell, formatterParams, onRendered) => {

						var button = document.createElement("button");
						button.style.width = "100%";
						button.style.height = "100%";
						button.style.border = 'none';
						button.width = "100%";
						button.height = "100%";
						button.innerText = __("Clear");

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}

						var bt_click = function() {

							let bt_fieldname = cell.getColumn().getField();
							let fieldname = bt_fieldname.substring(13, bt_fieldname.length);

							let ret_value = '';

							let row = cell.getRow();

							let s = '{"' + fieldname + '":"' + ret_value + '"}';
							row.update(JSON.parse(s));

							row.update({"tabulator_record_modified":"Y"});

							if (me.RecordChangeState) {
								me.RecordChangeState('MODIFIED');
							}
						}


						button.onclick = bt_click;
						return button;

					};

					column_editor = null;
					column_formatter = buttonEditor;

					me.ioiTable.addColumn(
						{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, cellClick: cell_onclick, headerSort: false}
					);


				}
			}


			if (col.fieldtype.toUpperCase() == 'SIGNATURE') {

				//if (column_visible) {

					let old_column_field = column_field;

					// Add
					column_title = 'Signature';
					column_field = 'button_' + column_field;
					column_visible = true;
					column_frozen = false;
					column_hozAlign = true;

					var buttonEditor = (cell, formatterParams, onRendered) => {

						var button = document.createElement("button");
						button.style.width = "100%";
						button.style.height = "100%";
						button.style.border = 'none';
						button.width = "100%";
						button.height = "100%";
						button.innerText = __("Sign");

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}

						var bt_click = function() {

							let bt_fieldname = cell.getColumn().getField();
							let fieldname = bt_fieldname.substring(7, bt_fieldname.length);

							let row = cell.getRow();

							let bt_ok_caption = __("Ok");

							if (col.read_only == 1) {
								bt_ok_caption = __("Close");
							}

							var signatureDialog = new frappe.ui.Dialog({
								title: __("Signature"),
								'fields': [
									{'fieldname': 'html_ioidoctypegrid_signature_dialog', 'fieldtype': 'Signature'}
								],
								primary_action_label: bt_ok_caption,
								primary_action: function(){


									// if (col.read_only == 0) {

										let ret_value = signatureDialog.fields_dict['html_ioidoctypegrid_signature_dialog'].value.toString();

										let s = '{"' + fieldname + '":"' + ret_value + '"}';

										row.update(JSON.parse(s));

										row.update({"tabulator_record_modified":"Y"});

										if (me.RecordChangeState) {
											me.RecordChangeState('MODIFIED');
										}

										if (me.Duration_Callback != null) {
											me.Duration_Callback(fieldname, ret_value, row);
										}
									// }

									signatureDialog.hide();
								},
								secondary_action_label: __("Cancel"),
								secondary_action: function(){
									signatureDialog.hide();
								}

							});


							signatureDialog.$wrapper.find('.modal-dialog').css("max-width", "320px").css("width", "320px");


							signatureDialog.show();

							me.sleep(200).then(() => {

								if (col.read_only == 1) {
									document.getElementsByClassName('btn btn-secondary btn-sm btn-modal-secondary')[document.getElementsByClassName('btn btn-secondary btn-sm btn-modal-secondary').length-1].style.display = 'none';
								}



								if ((row.getData()[fieldname] != null) && (row.getData()[fieldname] != '')) {
									signatureDialog.set_value('html_ioidoctypegrid_signature_dialog', row.getData()[fieldname])
								}
							});





/*
									let s = '{"' + fieldname + '":"' + ret_value + '"}';
									row.update(JSON.parse(s));

									row.update({"tabulator_record_modified":"Y"});

									if (me.RecordChangeState) {
										me.RecordChangeState('MODIFIED');
									}
*/

						}


						button.onclick = bt_click;
						return button;

					};

					column_editor = null;
					column_formatter = buttonEditor;

					me.ioiTable.addColumn(
						{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, cellClick: cell_onclick, headerSort: false}
					);

					column_field = old_column_field;


					// Clear
					column_title = '';
					column_field = 'button_clear_' + column_field;
					column_visible = true;
					column_frozen = false;
					column_hozAlign = true;

					var buttonEditor = (cell, formatterParams, onRendered) => {

						var button = document.createElement("button");
						button.style.width = "100%";
						button.style.height = "100%";
						button.style.border = 'none';
						button.width = "100%";
						button.height = "100%";
						button.innerText = __("Clear");

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}

						var bt_click = function() {

							let bt_fieldname = cell.getColumn().getField();
							let fieldname = bt_fieldname.substring(13, bt_fieldname.length);

							let ret_value = '';

							let row = cell.getRow();

							let s = '{"' + fieldname + '":"' + ret_value + '"}';
							row.update(JSON.parse(s));

							row.update({"tabulator_record_modified":"Y"});

							if (me.RecordChangeState) {
								me.RecordChangeState('MODIFIED');
							}
						}


						button.onclick = bt_click;
						return button;

					};

					column_editor = null;
					column_formatter = buttonEditor;

					me.ioiTable.addColumn(
						{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, cellClick: cell_onclick, headerSort: false}
					);
				//}



			}

			if (col.fieldtype.toUpperCase() == 'PASSWORD') {

				if (column_visible) {

					let old_column_field = column_field;

					// Add
					column_title = '';
					column_field = 'button_' + column_field;
					column_visible = true;
					column_frozen = false;
					column_hozAlign = true;

					var buttonEditor = (cell, formatterParams, onRendered) => {

						var button = document.createElement("button");
						button.style.width = "100%";
						button.style.height = "100%";
						button.style.border = 'none';
						button.width = "100%";
						button.height = "100%";
						button.innerText = __("Change");

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}

						var bt_click = function() {

							let bt_fieldname = cell.getColumn().getField();
							let fieldname = bt_fieldname.substring(7, bt_fieldname.length);

							let row = cell.getRow();

							let bt_ok_caption = __("Ok");

							if (col.read_only == 1) {
								bt_ok_caption = __("Close");
							}

							var passwordDialog = new frappe.ui.Dialog({
								title: __("Color"),
								'fields': [
									{'fieldname': 'html_ioidoctypegrid_password_dialog', 'fieldtype': 'Password'}
								],
								primary_action_label: bt_ok_caption,
								primary_action: function(){


									// if (col.read_only == 0) {

										let ret_value = passwordDialog.fields_dict['html_ioidoctypegrid_password_dialog'].value.toString();

										let s = '{"' + fieldname + '":"' + ret_value + '"}';

										row.update(JSON.parse(s));

										row.update({"tabulator_record_modified":"Y"});

										if (me.RecordChangeState) {
											me.RecordChangeState('MODIFIED');
										}

									// }

									passwordDialog.hide();
								},
								secondary_action_label: __("Cancel"),
								secondary_action: function(){
									passwordDialog.hide();
								}

							});


							passwordDialog.$wrapper.find('.modal-dialog').css("max-width", "320px").css("width", "320px");


							passwordDialog.show();

							me.sleep(200).then(() => {

								if (col.read_only == 1) {
									document.getElementsByClassName('btn btn-secondary btn-sm btn-modal-secondary')[document.getElementsByClassName('btn btn-secondary btn-sm btn-modal-secondary').length-1].style.display = 'none';
								}


								if ((row.getData()[fieldname] != null) && (row.getData()[fieldname] != '')) {
									passwordDialog.set_value('html_ioidoctypegrid_password_dialog', row.getData()[fieldname])
								}
/*
								if (col.read_only == 1) {
									passwordDialog.set_df_property('html_ioidoctypegrid_password_dialog', 'read_only', 1);
								}else{
									passwordDialog.set_df_property('html_ioidoctypegrid_password_dialog', 'read_only', 0);
								}
*/
							});





/*
									let s = '{"' + fieldname + '":"' + ret_value + '"}';
									row.update(JSON.parse(s));

									row.update({"tabulator_record_modified":"Y"});

									if (me.RecordChangeState) {
										me.RecordChangeState('MODIFIED');
									}
*/

						}


						button.onclick = bt_click;
						return button;

					};

					column_editor = null;
					column_formatter = buttonEditor;

					me.ioiTable.addColumn(
						{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, cellClick: cell_onclick, headerSort: false}
					);

					column_field = old_column_field;


					// Clear
					column_title = '';
					column_field = 'button_clear_' + column_field;
					column_visible = true;
					column_frozen = false;
					column_hozAlign = true;

					var buttonEditor = (cell, formatterParams, onRendered) => {

						var button = document.createElement("button");
						button.style.width = "100%";
						button.style.height = "100%";
						button.style.border = 'none';
						button.width = "100%";
						button.height = "100%";
						button.innerText = __("Clear");

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}

						var bt_click = function() {

							let bt_fieldname = cell.getColumn().getField();
							let fieldname = bt_fieldname.substring(13, bt_fieldname.length);

							let ret_value = '';

							let row = cell.getRow();

							let s = '{"' + fieldname + '":"' + ret_value + '"}';
							row.update(JSON.parse(s));

							row.update({"tabulator_record_modified":"Y"});

							if (me.RecordChangeState) {
								me.RecordChangeState('MODIFIED');
							}
						}


						button.onclick = bt_click;
						return button;

					};

					column_editor = null;
					column_formatter = buttonEditor;

					me.ioiTable.addColumn(
						{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, cellClick: cell_onclick, headerSort: false}
					);
				}


			}

			if (col.fieldtype.toUpperCase() == 'PHONE') {

				if (column_visible) {

					let old_column_field = column_field;

					column_title = '';
					column_field = 'button_' + column_field;
					column_visible = true;
					column_frozen = false;
					column_hozAlign = true;

					var buttonEditor = (cell, formatterParams, onRendered) => {

						var button = document.createElement("button");
						button.style.width = "100%";
						button.style.height = "100%";
						button.style.border = 'none';
						button.width = "100%";
						button.height = "100%";
						button.innerText = '...';

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}

						var bt_click = function() {


							let bt_fieldname = cell.getColumn().getField();
							let fieldname = bt_fieldname.substring(7, bt_fieldname.length);

							let row = cell.getRow();

							let bt_ok_caption = __("Ok");

							if (col.read_only == 1) {
								bt_ok_caption = __("Close");
							}

							var phoneDialog = new frappe.ui.Dialog({
								title: __("Phone number"),
								'fields': [
									{'fieldname': 'html_ioidoctypegrid_phone_dialog', 'fieldtype': 'Phone'}
								],
								primary_action_label: bt_ok_caption,
								primary_action: function(){


									if (col.read_only == 0) {

										let ret_value = phoneDialog.fields_dict['html_ioidoctypegrid_phone_dialog'].value.toString();

										let s = '{"' + fieldname + '":"' + ret_value + '"}';

										row.update(JSON.parse(s));

										row.update({"tabulator_record_modified":"Y"});

										if (me.RecordChangeState) {
											me.RecordChangeState('MODIFIED');
										}
									}

									phoneDialog.hide();
								},
								secondary_action_label: __("Cancel"),
								secondary_action: function(){
									phoneDialog.hide();
								}

							});


							phoneDialog.$wrapper.find('.modal-dialog').css("max-width", "320px").css("width", "320px");


							phoneDialog.show();

							me.sleep(200).then(() => {

								if (col.read_only == 1) {
									document.getElementsByClassName('btn btn-secondary btn-sm btn-modal-secondary')[document.getElementsByClassName('btn btn-secondary btn-sm btn-modal-secondary').length-1].style.display = 'none';
								}

								if ((row.getData()[fieldname] != null) && (row.getData()[fieldname] != '')) {
									phoneDialog.set_value('html_ioidoctypegrid_phone_dialog', row.getData()[fieldname])
								}

								if (col.read_only == 1) {
									phoneDialog.set_df_property('html_ioidoctypegrid_phone_dialog', 'read_only', 1);
								}else{
									phoneDialog.set_df_property('html_ioidoctypegrid_phone_dialog', 'read_only', 0);
								}
							});
						}


						button.onclick = bt_click;
						return button;

					};

					column_editor = null;
					column_formatter = buttonEditor;

					me.ioiTable.addColumn(
						{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, cellClick: cell_onclick, headerSort: false}
					);

					column_field = old_column_field;
				}
			}

			if (col.fieldtype.toUpperCase() == 'TABLE MULTISELECT') {

				if (column_visible) {

					let old_column_field = column_field;

					// Add
					column_title = '';
					column_field = 'button_' + column_field;
					column_visible = true;
					column_frozen = false;
					column_hozAlign = true;

					var buttonEditor = (cell, formatterParams, onRendered) => {

						var button = document.createElement("button");
						button.style.width = "100%";
						button.style.height = "100%";
						button.style.border = 'none';
						button.width = "100%";
						button.height = "100%";
						button.innerText = '...';

						let input = null;

						if (me.Format_Cell != null) {
							me.Format_Cell(col, cell, input);
						}

						var bt_click = function() {

							let bt_fieldname = cell.getColumn().getField();
							let fieldname = bt_fieldname.substring(7, bt_fieldname.length);

							let row = cell.getRow();

							let bt_ok_caption = __("Ok");

							if (col.read_only == 1) {
								bt_ok_caption = __("Close");
							}

							var multiselectDialog = new frappe.ui.Dialog({
								title: __("Select"),
								'fields': [
									{'fieldname': 'html_ioidoctypegrid_multiselect', 'fieldtype': 'HTML'}
								],
								primary_action_label: bt_ok_caption,
								primary_action: function(){

									if (col.read_only == 0) {

										let s = '';

										if (document.getElementById('html_ioidoctypegrid_multiselect_grid_detail')) {

											for (var i = 0; i < document.getElementById('html_ioidoctypegrid_multiselect_grid_detail').rows.length; i++) {

												if (document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_value_' + i.toString())) {

													s += document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_value_' + i.toString()).value + ', ';
												}
											}
										}

										if (s[s.length-2] == ',') {
											s = s.substring(0, s.length-2);
										}

										let ret_value = s;

										s = '{"' + fieldname + '":"' + ret_value + '"}';

										row.update(JSON.parse(s));

										let cells = cell.getRow().getCells();

										for (var i = 0; i < cells.length; i++) {
											if (cells[i].getColumn().getField() == fieldname) {
												cells[i].getElement().innerText = ret_value;
											}
										}

										row.update({"tabulator_record_modified":"Y"});

										if (me.RecordChangeState) {
											me.RecordChangeState('MODIFIED');
										}

										if (me.MultiSelect_Callback != null) {
											me.MultiSelect_Callback(fieldname, ret_value, row);
										}


									}


									multiselectDialog.hide();
								},
								secondary_action_label: __("Cancel"),
								secondary_action: function(){
									multiselectDialog.hide();
								}

							});

							let html = '';

							if (document.getElementById('html_ioidoctypegrid_multiselect_grid_header')) {
								document.getElementById('html_ioidoctypegrid_multiselect_grid_header').remove();
							}

							if (document.getElementById('html_ioidoctypegrid_multiselect_grid_detail')) {

								for (var i = 0; i < document.getElementById('html_ioidoctypegrid_multiselect_grid_detail').rows.length; i++) {

									if (document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_value_' + i.toString())) {
										document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_value_' + i.toString()).remove();
									}

									if (document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_checked_id_' + i.toString())) {
										document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_checked_id_' + i.toString()).remove();
									}

									if (document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_row_' + i.toString())) {
										document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_row_' + i.toString()).remove();
									}
								}

								document.getElementById('html_ioidoctypegrid_multiselect_grid_detail').remove();
							}

							let cells = cell.getRow().getCells()

							let s = '';

							for (var i = 0; i < cells.length; i++) {
								if (cells[i].getColumn().getField() == fieldname) {
									s = cells[i].getElement().innerText;
									break;
								}
							}

							// Grid Header
							html += '<div id="html_ioidoctypegrid_multiselect_content" style="overflow: auto; overflow-x: auto; height:392px;">';

							html += '	<table id="html_ioidoctypegrid_multiselect_grid_header" border=1 style="border: 1px solid #E8EAEB" width=310px data-custom-grid="true">';
							html += '	<tr style="height:30px">';
							html += '	<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
							html += '	<td id="html_ioidoctypegrid_multiselect_col_name"	width=270px style="vertical-align: middle;"><b>&nbsp;' + __("Values") + '</b></td>';
							html += '	</tr>';
							html += '	</table>';

							if (s.trim() != '') {

								html += '	<table id="html_ioidoctypegrid_multiselect_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=310px>';

								if (s.indexOf(',') == -1) {

									html += '	<input type="hidden" id="html_ioidoctypegrid_multiselect_grid_detail_value_0" value="' + s + '"> ';

									html += '	<tr id = "html_ioidoctypegrid_multiselect_grid_detail_row_0" style="height:30px">';
									html += '	<td width=30px align="center" style="vertical-align: middle;">';

									html += '	<input type="checkbox" id="html_ioidoctypegrid_multiselect_grid_detail_checked_id_0" style="postion:absolute; top: 2px; left: 2px;" ';
									html += '       onkeydown="   if (event.keyCode == 13) { return false; } " ';
									html += '       onkeyup="   if (event.keyCode == 13) { return false; } " ';
									html += 	' checked >';
									html += '	</td>';
									html += '	<td width=270px style="vertical-align: middle;">&nbsp;' + s + '</td>';
									html += '	</tr>';

								}else{
									let i = 0;

									while (s.indexOf(',') != -1) {

										let v = s.substring(0, s.indexOf(','));
										s = s.substring(s.indexOf(',')+1, s.length);

										html += '	<input type="hidden" id="html_ioidoctypegrid_multiselect_grid_detail_value_' + i.toString() + '" value="' + v + '"> ';

										html += '	<tr id = "html_ioidoctypegrid_multiselect_grid_detail_row_' + i.toString() + '" style="height:30px">';
										html += '	<td width=30px align="center" style="vertical-align: middle;">';

										html += '	<input type="checkbox" id="html_ioidoctypegrid_multiselect_grid_detail_checked_id_' + i.toString() + '" style="postion:absolute; top: 2px; left: 2px;" ';
										html += '       onkeydown="   if (event.keyCode == 13) { return false; } " ';
										html += '       onkeyup="   if (event.keyCode == 13) { return false; } " ';

										if (i == 0) {
											html += ' checked ';
										}
										html += 	'>';
										html += '	</td>';
										html += '	<td width=270px style="vertical-align: middle;">&nbsp;' + v + '</td>';
										html += '	</tr>';

										i++;

									}

									if (s.trim() != '') {

										html += '	<input type="hidden" id="html_ioidoctypegrid_multiselect_grid_detail_value_' + i.toString() + '" value="' + s + '"> ';

										html += '	<tr id = "html_ioidoctypegrid_multiselect_grid_detail_row_' + i.toString() + '" style="height:30px">';
										html += '	<td width=30px align="center" style="vertical-align: middle;">';

										html += '	<input type="checkbox" id="html_ioidoctypegrid_multiselect_grid_detail_checked_id_' + i.toString() + '" style="postion:absolute; top: 2px; left: 2px;" ';
										html += '       onkeydown="   if (event.keyCode == 13) { return false; } " ';
										html += '       onkeyup="   if (event.keyCode == 13) { return false; } " ';
										html += 	'>';
										html += '	</td>';
										html += '	<td width=270px style="vertical-align: middle;">&nbsp;' + s + '</td>';
										html += '	</tr>';

									}
								}

								html += '	</table>';
							}


							html += '</div>';

							if (document.getElementById('html_ioidoctypegrid_multiselect_action_add')) {
								document.getElementById('html_ioidoctypegrid_multiselect_action_add').remove();
							}

							if (document.getElementById('html_ioidoctypegrid_multiselect_action_delete')) {
								document.getElementById('html_ioidoctypegrid_multiselect_action_delete').remove();
							}

							if (document.getElementById('html_ioidoctypegrid_multiselect_action_delete_all')) {
								document.getElementById('html_ioidoctypegrid_multiselect_action_delete_all').remove();
							}


							if (col.read_only == 0) {

								html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
								html += '</div>';

								html += '<div id="html_ioidoctypegrid_multiselect_action" style="overflow: auto; overflow-x: auto; overflow-y: hidden; height:35px;">';

								// Add
								html += '	<div style="position: relative; top: 2px; left: 0px; width:50px;">';
								html += '		<div style="position: absolute; top:0px; left: 2px; height: 35px">';
								html +='			<button id="html_ioidoctypegrid_multiselect_action_add" class="btn btn-default ellipsis" style="height: 30px; width: 50px;" onclick="">' + __("+") + '</button>';
								html += '		</div>';
								html += '	</div>';

								// Delete
								html += '	<div style="position: relative; top: 2px; left: 60px; width:50px;">';
								html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
								html +='			<button id="html_ioidoctypegrid_multiselect_action_delete" class="btn btn-default ellipsis" style="height: 30px; width: 50px;" onclick="">' + __("-") + '</button>';
								html += '		</div>';
								html += '	</div>';

								// Delete all
								html += '	<div style="position: relative; top: 2px; left: 120px; width:150px;">';
								html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
								html +='			<button id="html_ioidoctypegrid_multiselect_action_delete_all" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="">' + __("Delete all") + '</button>';
								html += '		</div>';
								html += '	</div>';


								html += '</div>';
							}


							multiselectDialog.fields_dict.html_ioidoctypegrid_multiselect.$wrapper.html(html);

							multiselectDialog.$wrapper.find('.modal-dialog').css("max-width", "360px").css("width", "360px");


							multiselectDialog.show();

							me.sleep(200).then(() => {

								if (col.read_only == 1) {
									document.getElementsByClassName('btn btn-secondary btn-sm btn-modal-secondary')[document.getElementsByClassName('btn btn-secondary btn-sm btn-modal-secondary').length-1].style.display = 'none';
								}

								if (document.getElementById('html_ioidoctypegrid_multiselect_grid_detail')) {

									let fct_click = function() {

										for (var i = 0; i < document.getElementById('html_ioidoctypegrid_multiselect_grid_detail').rows.length; i++) {

											if (document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_checked_id_' + i.toString())) {
												document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_checked_id_' + i.toString()).checked = false;
											}

										}

										this.checked = true;
									}


									for (var i = 0; i < document.getElementById('html_ioidoctypegrid_multiselect_grid_detail').rows.length; i++) {

										if (document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_checked_id_' + i.toString())) {
											document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_checked_id_' + i.toString()).onclick = fct_click;
										}

									}

								}

								if (document.getElementById('html_ioidoctypegrid_multiselect_action_add')) {

									let fct_add = function() {

										let dc = '';

										for (var i = 0; i < me.columns.length; i++) {
											if (me.columns[i].fieldname == fieldname) {

												dc = me.columns[i].options;
												break;

											}
										}

										if (dc != '') {

											let linked_dc = '';

											let method = me.path_common + '.ioi_doctype_grid_get_linked_doctype';

											frappe.call({  	method: method,
															args: {"doctype" : dc},
															async: false,
															callback:function(r)	{
																linked_dc = r.message;
															}
											});

											if ((linked_dc != '') && (linked_dc != null)) {

												let filters = {};

												if (document.getElementById('html_ioidoctypegrid_multiselect_grid_detail')) {

													if (document.getElementById('html_ioidoctypegrid_multiselect_grid_detail').rows.length > 0) {

														let s = '{"name": ["not in", [';

														for (var i = 0; i < document.getElementById('html_ioidoctypegrid_multiselect_grid_detail').rows.length; i++) {

															s += '"' + document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_value_' + i.toString()).value + '"';

															if (i < document.getElementById('html_ioidoctypegrid_multiselect_grid_detail').rows.length-1) {
																s += ','
															}

														}

														s += ']]}';

														filters = JSON.parse(s);
													}
												}

												var linked_dcDialog = new frappe.ui.Dialog({
													title: __("Add"),
													'fields': [
														{'fieldname': 'html_ioidoctypegrid_linked_dc', 'fieldtype': 'Link', 'options': linked_dc, filters: filters}

													],
													primary_action_label: __("Ok"),
													primary_action: function(){

														let value = cur_dialog.fields_dict['html_ioidoctypegrid_linked_dc'].value;

														if ((!value) || ((value) && (value.trim() == ''))) {
															frappe.msgprint({title: __("Message"), message: __("Value is mandatory"), indicator: "red"});
															return false;
														}

														if (document.getElementById('html_ioidoctypegrid_multiselect_grid_detail')) {

															if (document.getElementById('html_ioidoctypegrid_multiselect_grid_detail').rows.length > 0) {

																for (var i = 0; i < document.getElementById('html_ioidoctypegrid_multiselect_grid_detail').rows.length; i++) {

																	if (document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_value_' + i.toString()).value == value) {

																		frappe.msgprint({title: __("Message"), message: __("This value already exists in the grid"), indicator: "red"});
																		return false;
																	}
																}
															}
														}


														if (!document.getElementById('html_ioidoctypegrid_multiselect_grid_detail')) {

															let html = '';

															html += '<table id="html_ioidoctypegrid_multiselect_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=310px>';

															html += '	<input type="hidden" id="html_ioidoctypegrid_multiselect_grid_detail_value_0" value="' + value + '"> ';

															html += '	<tr id = "html_ioidoctypegrid_multiselect_grid_detail_row_0" style="height:30px">';
															html += '	<td width=30px align="center" style="vertical-align: middle;">';

															html += '	<input type="checkbox" id="html_ioidoctypegrid_multiselect_grid_detail_checked_id_0" style="postion:absolute; top: 2px; left: 2px;" ';
															html += '       onkeydown="   if (event.keyCode == 13) { return false; } " ';
															html += '       onkeyup="   if (event.keyCode == 13) { return false; } " ';
															html += 	' checked >';
															html += '	</td>';
															html += '	<td width=270px style="vertical-align: middle;">&nbsp;' + value + '</td>';
															html += '	</tr>';
															html += '</table>';


															document.getElementById('html_ioidoctypegrid_multiselect_content').insertAdjacentHTML('beforeend', html);

														}else{

															let nb = -1;
															let last_row_id = '';

															for (var i = 0; i < document.getElementById('html_ioidoctypegrid_multiselect_grid_detail').rows.length; i++) {

																let row_id = document.getElementById('html_ioidoctypegrid_multiselect_grid_detail').rows[i].id;
																last_row_id = document.getElementById('html_ioidoctypegrid_multiselect_grid_detail').rows[i].id;

																if (document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_checked_id_' + i.toString())) {
																	document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_checked_id_' + i.toString()).checked = false;
																}

																while (row_id.indexOf('_') != -1) {
																	row_id = row_id.substring(row_id.indexOf('_')+1, row_id.length);
																}

																if (parseInt(row_id) > parseInt(nb)) {
																	nb = parseInt(row_id);
																}
															}

															nb++;



															let html = '';

															html += '	<input type="hidden" id="html_ioidoctypegrid_multiselect_grid_detail_value_' + nb.toString() + '" value="' + value + '"> ';

															html += '	<tr id = "html_ioidoctypegrid_multiselect_grid_detail_row_' + nb.toString() + '" style="height:30px">';
															html += '	<td width=30px align="center" style="vertical-align: middle;">';

															html += '	<input type="checkbox" id="html_ioidoctypegrid_multiselect_grid_detail_checked_id_' + nb.toString() + '" style="postion:absolute; top: 2px; left: 2px;" ';
															html += '       onkeydown="   if (event.keyCode == 13) { return false; } " ';
															html += '       onkeyup="   if (event.keyCode == 13) { return false; } " ';
															html += 	' checked >';
															html += '	</td>';
															html += '	<td width=270px style="vertical-align: middle;">&nbsp;' + value + '</td>';
															html += '	</tr>';

															document.getElementById(last_row_id).insertAdjacentHTML('afterend', html);


														}

														me.sleep(300).then(() => {

															if (document.getElementById('html_ioidoctypegrid_multiselect_grid_detail')) {

																let fct_click = function() {

																	for (var i = 0; i < document.getElementById('html_ioidoctypegrid_multiselect_grid_detail').rows.length; i++) {

																		if (document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_checked_id_' + i.toString())) {
																			document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_checked_id_' + i.toString()).checked = false;
																		}

																	}

																	this.checked = true;
																}


																for (var i = 0; i < document.getElementById('html_ioidoctypegrid_multiselect_grid_detail').rows.length; i++) {

																	if (document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_checked_id_' + i.toString())) {
																		document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_checked_id_' + i.toString()).onclick = fct_click;
																	}

																}



															}
														});

														linked_dcDialog.hide();
													},

													secondary_action_label: __("Cancel"),
													secondary_action: function(){

														linked_dcDialog.hide();
													}



												});


												linked_dcDialog.$wrapper.find('.modal-dialog').css("max-width", "300px").css("width", "300px");

												linked_dcDialog.show();

											}


										}
									}

									document.getElementById('html_ioidoctypegrid_multiselect_action_add').onclick = fct_add;

								}

								if (document.getElementById('html_ioidoctypegrid_multiselect_action_delete')) {

									let fct_delete = function() {

										if (!document.getElementById('html_ioidoctypegrid_multiselect_grid_detail')) {
											frappe.msgprint({title: __("Message"), message: __("No data to delete"), indicator: "red"});
											return false;
										}


										let one_selected = false;
										let row_idx = -1;

										for (var i = 0; i < document.getElementById('html_ioidoctypegrid_multiselect_grid_detail').rows.length; i++) {

											if (document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_checked_id_' + i.toString())) {

												if (document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_checked_id_' + i.toString()).checked) {

													one_selected = true;
													row_idx = i;
													break;
												}
											}
										}

										if (!one_selected) {
											frappe.msgprint({title: __("Message"), message: __("No data to delete"), indicator: "red"});
											return false;
										}

										if (row_idx != -1) {

											let i = row_idx;

											if (document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_value_' + i.toString())) {
												document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_value_' + i.toString()).remove();
											}

											if (document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_checked_id_' + i.toString())) {
												document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_checked_id_' + i.toString()).remove();
											}

											if (document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_row_' + i.toString())) {
												document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_row_' + i.toString()).remove();
											}

											if (document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_checked_id_0')) {
												document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_checked_id_0').checked = true;
											}

										}

										multiselectDialog.primary_action();



									}

									document.getElementById('html_ioidoctypegrid_multiselect_action_delete').onclick = fct_delete;
								}


								if (document.getElementById('html_ioidoctypegrid_multiselect_action_delete_all')) {

									let fct_delete = function() {

										if (!document.getElementById('html_ioidoctypegrid_multiselect_grid_detail')) {
											frappe.msgprint({title: __("Message"), message: __("No data to delete"), indicator: "red"});
											return false;
										}

										for (var i = document.getElementById('html_ioidoctypegrid_multiselect_grid_detail').rows.length-1; i >= 0; i--) {

											if (document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_value_' + i.toString())) {
												document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_value_' + i.toString()).remove();
											}

											if (document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_checked_id_' + i.toString())) {
												document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_checked_id_' + i.toString()).remove();
											}

											if (document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_row_' + i.toString())) {
												document.getElementById('html_ioidoctypegrid_multiselect_grid_detail_row_' + i.toString()).remove();
											}
										}

										document.getElementById('html_ioidoctypegrid_multiselect_grid_detail').remove();

										multiselectDialog.primary_action();
									}

									document.getElementById('html_ioidoctypegrid_multiselect_action_delete_all').onclick = fct_delete;
								}


							});
						}


						button.onclick = bt_click;
						return button;

					};

					column_editor = null;
					column_formatter = buttonEditor;

					me.ioiTable.addColumn(
						{title: __(column_title), field: column_field, visible: column_visible, frozen: column_frozen, hozAlign: column_hozAlign, editor: column_editor, formatter: column_formatter, cellClick: cell_onclick, headerSort: false}
					);

					column_field = old_column_field;
				}
			}

		}

	}


	set_data()
	{
		let me = this;

		me.ioiTable.clearData();

		let method = me.path_common + '.ioi_doctype_grid_assign_tabulator_row_index';

		frappe.call({  	method: method,
						args: {"doctype": me.doctype, "data" : me.data},
						async: false,
						callback:function(r)	{
							me.data = r.message;
						}
		});

		me.ioiTable.setData(me.data);

		let rows = me.ioiTable.getRows();

		if (rows) {

			for (var i = 0; i < rows.length; i++) {

				rows[i].update({tabulator_record_modified: ''});
				rows[i].update({tabulator_to_ioistatus: ''});
				rows[i].update({tabulator_to_ioiuserstatus: ''});

				let cells = rows[i].getCells()

				for (var j = 0; j < cells.length; j++) {
					if ((cells[j].getColumn().getField() == 'tabulator_to_ioistatus') || (cells[j].getColumn().getField() == 'tabulator_to_ioiuserstatus')) {
						cells[j].getElement().style.backgroundColor = '';
					}
				}
			}
		}

		if (me.RecordChangeState) {
			me.RecordChangeState('');
		}

		me.record_state = '';

		me.deleted_rows = [];
		me.images_uploaded_path = [];
		me.files_uploaded_path = [];

		if (me.data.length > 0) {

			let row = me.ioiTable.getRow(1);
			row.select();
			me.selected_row_index = 1;
		}


	}

	replace_language_in_description(old_language)
	{
		let me = this;

		let	column_title = '';

		let language_found = false;

		for (var k = 0; k < old_language.length; k++) {
			if (old_language[k] == me.language_1) {
				language_found = true;
			}
		}

		if (!language_found) {

			if (me.language_1) {
				if (me.language_1.trim() != '') {
					column_title = __('Description') + ' ' + me.language_1.toUpperCase();
					old_language[old_language.length] = me.language_1;
				}
			}
		}

		language_found = false;

		for (var k = 0; k < old_language.length; k++) {
			if (old_language[k] == me.language_2) {
				language_found = true;
			}
		}

		if (!language_found) {
			if (me.language_2) {
				if (me.language_2.trim() != '') {
					if (column_title.trim() == '') {
						column_title = __('Description') + ' ' + me.language_2.toUpperCase();
						old_language[old_language.length] = me.language_2;
					}
				}
			}
		}

		language_found = false;

		for (var k = 0; k < old_language.length; k++) {
			if (old_language[k] == me.language_3) {
				language_found = true;
			}
		}

		if (!language_found) {

			if (me.language_3) {
				if (me.language_3.trim() != '') {
					if (column_title.trim() == '') {
						column_title = __('Description') + ' ' + me.language_3.toUpperCase();
						old_language[old_language.length] = me.language_3;
					}
				}
			}
		}

		language_found = false;

		for (var k = 0; k < old_language.length; k++) {
			if (old_language[k] == me.language_4) {
				language_found = true;
			}
		}

		if (!language_found) {

			if (me.language_4) {
				if(me.language_4.trim() != '') {
					if (column_title.trim() == '') {
						column_title = __('Description') + ' ' + me.language_4.toUpperCase();
						old_language[old_language.length] = me.language_4;
					}
				}
			}
		}

		if (column_title.trim() == '') {
			column_title = __('Description (not specified)');
		}

		return column_title;
	}

	replace_language_in_memo(old_memo_language)
	{
		let me = this;

		let column_title = '';

		let language_found = false;

		for (var k = 0; k < old_memo_language.length; k++) {
			if (old_memo_language[k] == me.language_1) {
				language_found = true;
			}
		}

		if (!language_found) {

			if (me.language_1) {
				if (me.language_1.trim() != '') {
					column_title = __('Memo') + ' ' + me.language_1.toUpperCase();
					old_memo_language[old_memo_language.length] = me.language_1;
				}
			}
		}

		language_found = false;

		for (var k = 0; k < old_memo_language.length; k++) {
			if (old_memo_language[k] == me.language_2) {
				language_found = true;
			}
		}

		if (!language_found) {
			if (me.language_2) {
				if (me.language_2.trim() != '') {
					if (column_title.trim() == '') {
						column_title = __('Memo') + ' ' + me.language_2.toUpperCase();
						old_memo_language[old_memo_language.length] = me.language_2;
					}
				}
			}
		}

		language_found = false;

		for (var k = 0; k < old_memo_language.length; k++) {
			if (old_memo_language[k] == me.language_3) {
				language_found = true;
			}
		}

		if (!language_found) {

			if (me.language_3) {
				if (me.language_3.trim() != '') {
					if (column_title.trim() == '') {
						column_title = __('Memo') + ' ' + me.language_3.toUpperCase();
						old_memo_language[old_memo_language.length] = me.language_3;
					}
				}
			}
		}

		language_found = false;

		for (var k = 0; k < old_memo_language.length; k++) {
			if (old_memo_language[k] == me.language_4) {
				language_found = true;
			}
		}

		if (!language_found) {

			if (me.language_4) {
				if (me.language_4.trim() != '') {
					if (column_title.trim() == '') {
						column_title = __('Memo') + ' ' + me.language_4.toUpperCase();
						old_memo_language[old_memo_language.length] = me.language_4;
					}
				}
			}
		}

		if (column_title.trim() == '') {
			column_title = __('Memo (not specified)');
		}

		return column_title;
	}

	duration_convert(duration)
	{
		let days = 0;
		let hours = 0;
		let minutes = 0;
		let seconds = 0;

		let remainder = 0;

		if (parseInt(duration) >= 86400) {

			days = parseInt(parseInt(duration) / 86400);

			remainder = parseInt(parseInt(duration) % 86400);

			if (parseInt(remainder) != 0) {

				if (parseInt(remainder) >= 3600) {

					hours = parseInt(parseInt(remainder) / 3600);

					remainder = parseInt(parseInt(remainder) % 3600);

					if (parseInt(remainder) != 0) {

						minutes = parseInt(parseInt(remainder) / 60);

						remainder = parseInt(parseInt(remainder) % 60);

						if (parseInt(remainder) != 0) {
							seconds = remainder;
						}
					}

				}else{
					minutes = parseInt(parseInt(remainder) / 60);

					remainder = parseInt(parseInt(remainder) % 60);

					if (parseInt(remainder) != 0) {
						seconds = remainder;
					}
				}
			}
		}else if (parseInt(duration) >= 3600) {

			hours = parseInt(parseInt(duration) / 3600);

			remainder = parseInt(parseInt(duration) % 3600);

			if (parseInt(remainder) != 0) {

				minutes = parseInt(parseInt(remainder) / 60);

				remainder = parseInt(parseInt(remainder) % 60);

				if (parseInt(remainder) != 0) {
					seconds = remainder;
				}
			}

		}else{
			minutes = parseInt(parseInt(duration) / 60);

			remainder = parseInt(parseInt(duration) % 60);

			if (parseInt(remainder) != 0) {
				seconds = remainder;
			}
		}

		return [days, hours, minutes, seconds];
	}

	new_row(values, at_top)
	{
		let me = this;

		if (at_top == null) {
			at_top = false;
		}

		let s = '{';

		if (me.display_fields.length > 0) {

			for (var j = 0; j < me.display_fields.length; j++) {

				for (var i = 0; i < me.columns.length; i++) {

					if (me.columns[i].fieldname.toUpperCase() == me.display_fields[j].toUpperCase()) {

						if ((me.columns[i].fieldtype.toUpperCase() != 'BUTTON') && (me.columns[i].fieldtype.toUpperCase() != 'TABLE MULTISELECT') && (me.columns[i].fieldtype.toUpperCase() != 'TABLE')) {

							s += '"' + me.columns[i].fieldname + '": ';

							if ((me.columns[i].fieldtype.toUpperCase() == 'CURRENCY') 	|| (me.columns[i].fieldtype.toUpperCase() == 'DURATION') ||
								(me.columns[i].fieldtype.toUpperCase() == 'FLOAT')		|| (me.columns[i].fieldtype.toUpperCase() == 'INT') 	 ||
								(me.columns[i].fieldtype.toUpperCase() == 'PERCENT')	|| (me.columns[i].fieldtype.toUpperCase() == 'RATING')	 ||
								(me.columns[i].fieldtype.toUpperCase() == 'CHECK')) {

								if (me.columns[i].default != null) {
									s += me.columns[i].default.toString() + ',';
								}else{
									s += 'null,';
								}

							}else if (  (me.columns[i].fieldtype.toUpperCase() == 'ATTACH') 		 || (me.columns[i].fieldtype.toUpperCase() == 'ATTACH IMAGE')	||
										(me.columns[i].fieldtype.toUpperCase() == 'AUTOCOMPLETE') 	 || (me.columns[i].fieldtype.toUpperCase() == 'COLOR')			||
										(me.columns[i].fieldtype.toUpperCase() == 'DATE') 			 || (me.columns[i].fieldtype.toUpperCase() == 'DATETIME') 		||
										(me.columns[i].fieldtype.toUpperCase() == 'DATA') 			 || (me.columns[i].fieldtype.toUpperCase() == 'LINK') 			||
										(me.columns[i].fieldtype.toUpperCase() == 'DYNAMIC LINK') 	 || (me.columns[i].fieldtype.toUpperCase() == 'CODE') 			||
										(me.columns[i].fieldtype.toUpperCase() == 'HTML EDITOR') 	 || (me.columns[i].fieldtype.toUpperCase() == 'LONG TEXT') 		||
										(me.columns[i].fieldtype.toUpperCase() == 'MARKDOWN EDITOR') || (me.columns[i].fieldtype.toUpperCase() == 'READ ONLY') 		||
										(me.columns[i].fieldtype.toUpperCase() == 'SELECT') 		 || (me.columns[i].fieldtype.toUpperCase() == 'SMALL TEXT') 	||
										(me.columns[i].fieldtype.toUpperCase() == 'TEXT') 			 ||	(me.columns[i].fieldtype.toUpperCase() == 'TEXT EDITOR') 	||
										(me.columns[i].fieldtype.toUpperCase() == 'TIME')) {

								if (me.columns[i].default != null) {
									s += '"' + me.columns[i].default.toString() + '",';
								}else{
									s += 'null,';
								}
							}
						}
					}
				}
			}
		}else{

			for (var i = 0; i < me.columns.length; i++) {

				if ((me.columns[i].fieldtype.toUpperCase() != 'BUTTON') && (me.columns[i].fieldtype.toUpperCase() != 'TABLE MULTISELECT') && (me.columns[i].fieldtype.toUpperCase() != 'TABLE')) {

					s += '"' + me.columns[i].fieldname + '": ';

					if ((me.columns[i].fieldtype.toUpperCase() == 'CURRENCY') 	|| (me.columns[i].fieldtype.toUpperCase() == 'DURATION') ||
						(me.columns[i].fieldtype.toUpperCase() == 'FLOAT')		|| (me.columns[i].fieldtype.toUpperCase() == 'INT') 	 ||
						(me.columns[i].fieldtype.toUpperCase() == 'PERCENT')	|| (me.columns[i].fieldtype.toUpperCase() == 'RATING')	 ||
						(me.columns[i].fieldtype.toUpperCase() == 'CHECK')) {

						if (me.columns[i].default != null) {
							s += me.columns[i].default.toString() + ',';
						}else{
							s += 'null,';
						}

					}else if (  (me.columns[i].fieldtype.toUpperCase() == 'ATTACH') 		 || (me.columns[i].fieldtype.toUpperCase() == 'ATTACH IMAGE')	||
								(me.columns[i].fieldtype.toUpperCase() == 'AUTOCOMPLETE') 	 || (me.columns[i].fieldtype.toUpperCase() == 'COLOR')			||
								(me.columns[i].fieldtype.toUpperCase() == 'DATE') 			 || (me.columns[i].fieldtype.toUpperCase() == 'DATETIME') 		||
								(me.columns[i].fieldtype.toUpperCase() == 'DATA') 			 || (me.columns[i].fieldtype.toUpperCase() == 'LINK') 			||
								(me.columns[i].fieldtype.toUpperCase() == 'DYNAMIC LINK') 	 || (me.columns[i].fieldtype.toUpperCase() == 'CODE') 			||
								(me.columns[i].fieldtype.toUpperCase() == 'HTML EDITOR') 	 || (me.columns[i].fieldtype.toUpperCase() == 'LONG TEXT') 		||
								(me.columns[i].fieldtype.toUpperCase() == 'MARKDOWN EDITOR') || (me.columns[i].fieldtype.toUpperCase() == 'READ ONLY') 		||
								(me.columns[i].fieldtype.toUpperCase() == 'SELECT') 		 || (me.columns[i].fieldtype.toUpperCase() == 'SMALL TEXT') 	||
								(me.columns[i].fieldtype.toUpperCase() == 'TEXT') 			 ||	(me.columns[i].fieldtype.toUpperCase() == 'TEXT EDITOR') 	||
								(me.columns[i].fieldtype.toUpperCase() == 'TIME')) {

						if (me.columns[i].default != null) {
							s += '"' + me.columns[i].default.toString() + '",';
						}else{
							s += 'null,';
						}
					}
				}
			}
		}

		if (s[s.length-1] == ',') {
			s = s.substring(0, s.length-1);
		}

		s += '}';


		me.ioiTable.addRow([JSON.parse(s)], at_top).then(function(row){

			if (me.default_values.length > 0) {

				for (var i = 0; i < me.default_values.length; i++) {

					let key_found = false;
					let key_quote = false;

					let key = '';
					let value = null;

					for (var k = 0; k < me.columns.length; k++) {

						if (me.default_values[i][0].toUpperCase() == me.columns[k].fieldname.toUpperCase()) {

							if (me.columns[k].fieldtype.toUpperCase() != 'BUTTON') {

								if ((me.columns[k].fieldtype.toUpperCase() == 'CURRENCY') 	|| (me.columns[k].fieldtype.toUpperCase() == 'DURATION') ||
									(me.columns[k].fieldtype.toUpperCase() == 'FLOAT')		|| (me.columns[k].fieldtype.toUpperCase() == 'INT') 	 ||
									(me.columns[k].fieldtype.toUpperCase() == 'PERCENT')	|| (me.columns[k].fieldtype.toUpperCase() == 'RATING')	 ||
									(me.columns[k].fieldtype.toUpperCase() == 'CHECK')) {

									key_quote = false;
								}else{
									key_quote = true;
								}

								key = me.columns[k].fieldname;
								value = me.default_values[i][1];

								key_found = true;
								break;
							}
						}

					}

					if (key_found) {

						let s = '{"' + key + '":';

						if (key_quote) {
							if (value != null) {
								s += '"';
							}
						}

						if (value != null) {
							s += value;
						}else{
							s += 'null';
						}

						if (key_quote) {
							if (value != null) {
								s += '"';
							}
						}

						s += '}';

						row.update(JSON.parse(s));
					}


				}

			}

			if ((values != null) && (values != '{}')) {

				let nb = 0;

				let all_datas = me.ioiTable.getData();

				for (var i = 0; i < all_datas.length; i++) {
					if (all_datas[i].tabulator_row_index > nb) {
						nb = all_datas[i].tabulator_row_index;
					}
				}

				nb++;

				row.update({"tabulator_row_index": nb});

				let js = JSON.parse(values);

				for (var key in js) {

					let key_found = false;
					let key_quote = false;

					for (var k = 0; k < me.columns.length; k++) {

						if (key.toUpperCase() == me.columns[k].fieldname.toUpperCase()) {

							if (me.columns[k].fieldtype.toUpperCase() != 'BUTTON') {

								if ((me.columns[k].fieldtype.toUpperCase() == 'CURRENCY') 	|| (me.columns[k].fieldtype.toUpperCase() == 'DURATION') ||
									(me.columns[k].fieldtype.toUpperCase() == 'FLOAT')		|| (me.columns[k].fieldtype.toUpperCase() == 'INT') 	 ||
									(me.columns[k].fieldtype.toUpperCase() == 'PERCENT')	|| (me.columns[k].fieldtype.toUpperCase() == 'RATING')	 ||
									(me.columns[k].fieldtype.toUpperCase() == 'CHECK')) {

									key_quote = false;
								}else{
									key_quote = true;
								}

								key_found = true;
								break;
							}
						}

					}

					if (key_found) {

						let s = '{"' + key + '":';

						if (key_quote) {
							if (js[key] != null) {
								s += '"';
							}
						}

						if (js[key] != null) {
							s += js[key];
						}else{
							s += 'null';
						}

						if (key_quote) {
							if (js[key] != null) {
								s += '"';
							}
						}

						s += '}';

						row.update(JSON.parse(s));
					}
				}

				row.update({"tabulator_record_modified":"Y"});

				if (me.RecordChangeState) {
					me.RecordChangeState('INSERT');
				}

				me.record_state = 'INSERT';

				me.ioiTable.deselectRow();
				row.select();
				me.selected_row_index = nb;
			}

		}).catch(function(error){

		});

	}



	delete_row()
	{
		let me = this;

		if (me.selected_row_index != -1) {

			let row = me.ioiTable.getRow(me.selected_row_index);

			if (row.getData().ioistatus) {
				if (row.getData().ioistatus != 0) {
					return false;
				}
			}


			me.deleted_rows[me.deleted_rows.length] = row.getData();

			if (me.images_uploaded_path.length > 0) {

				for (var i = me.images_uploaded_path.length-1; i >= 0; i--) {

					if (me.images_uploaded_path[i][0] == row.getData().name) {

						me.images_uploaded_path.splice(i, 1);
					}

				}

			}

			if (me.files_uploaded_path.length > 0) {

				for (var i = me.files_uploaded_path.length-1; i >= 0; i--) {

					if (me.files_uploaded_path[i][0] == row.getData().name) {

						me.files_uploaded_path.splice(i, 1);
					}

				}
			}


			me.ioiTable.deleteRow(me.selected_row_index);
			me.selected_row_index = -1;

			if (me.RecordChangeState) {
				me.RecordChangeState('MODIFIED');
			}

			me.record_state = 'MODIFIED';
		}
	}

	sleep(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}
}

silicon_ioi.ioiDocTypeGrid = ioiDocTypeGrid;
