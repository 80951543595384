frappe.provide('silicon_ioi.doctype');

export class ioiGoodPurchWhsDetailFrame {
	static this
	static dossier_id
	static description
	static selected_option

	static build_panel(tab) {
		let panel = '';

		panel += '<div class="d-flex flex-column">';

		panel += '	<div class="d-flex w-100 flex-row flex-wrap mb-2">';

		// Dossier Search
		panel += `	<div class="mr-2" style="width:280px;">
						<label id="${tab}_search_label" style="">${__("Search")}</label>
						<div class="control-input" style="width: 280px;">
							<input id="${tab}_search" type="text" class="input-with-feedback form-control bold">
						</div>
					</div>`

		// Up to date
		panel += `	<div class="mr-2" style="width:130px;">
						<label id="${tab}_up_to_date_label" style="">${__("Up to date")}</label>
						<div class="control-input"> 
							<input id="${tab}_up_to_date" type="date" class="input-with-feedback form-control bold">
						</div>
					</div>`

		// Topn
		panel += `	<div class="mr-3" style="width:90px;">
						<label id="${tab}_topn_label" style="">${__("No records")}</label>
						<div class="control-input"> 
							<input id="${tab}_topn" type="number" class="input-with-feedback form-control bold" min="1" max="1000" value="100">
						</div>
					</div>`

		panel += '	</div>'

		panel += `	<div class="d-flex w-100 flex-row mb-2 flex-wrap" style="height: 28px;">
						<button id="${tab}_bt_search" title="${__("Search")}" data-label="Search" class="btn btn-xs btn-default ellipsis mr-2" style="width: 116px;" onclick="">${__("Search")}</button>
						<button id="${tab}_bt_clear" title="${__("...")}" data-label="Search" class="btn btn-xs btn-default ellipsis mr-2" style="width: 50px;" onclick="">${__("...")}</button>
						<button id="${tab}_bt_export" title="${__("Export")}" data-label="Export" class="btn btn-xs btn-default ellipsis mr-2" style="width: 100px;" onclick="">${__("Export")}</button>
						<div class="form-check ml-2">
							<input class="form-check-input" type="checkbox" value="" id="${tab}_checkbox_discard">
							<label class="form-check-label" for="${tab}_checkbox_discard">
								${__("Discard 'Template/Preparation/Lost' documents")}
							</label>
						</div>
					</div>`

		panel += `	<input id="${tab}_orderby" type="hidden" value="name">`;
		panel += `	<input id="${tab}_order" type="hidden" value="asc">`;
		panel += '</div>';

		return panel
	}

	static show_dossier_label(tab_name) {
		if (document.getElementById(`${tab_name}_desc_label`)) {
			document.getElementById(`${tab_name}_desc_label`).remove();
		}

		if (document.getElementById(`${tab_name}_dossier_label`)) {
			document.getElementById(`${tab_name}_dossier_label`).remove();
		}

		let html = '';

		// Item description
		html += '<div class="d-flex flex-column">';
		html += `	<label id="${tab_name}_desc_label">${__("Dossier")}</label>`
		html += `	<label id="${tab_name}_dossier_label">${silicon_ioi.doctype.ioiGoodPurchWhsDetailFrame.dossier_id} - ${silicon_ioi.doctype.ioiGoodPurchWhsDetailFrame.description}</label>`
		html += '</div>';

		return html
	}

	static build_detail() {
		let detail_tabs = []

		if (silicon_ioi.doctype.ioiGoodPurchWhsDetailFrame.selected_option) {
			detail_tabs = [
				silicon_ioi.doctype.ioiGoodPurchWhsDetailFrame.selected_option
			]
		} else {
			detail_tabs = [
				{ tab_name: 'detail_purchases_price_req', dt: 'ioi Purchases Price Request' },
				{ tab_name: 'detail_purchases_orders', dt: 'ioi Purchases Order' },
				{ tab_name: 'detail_purchases_deliveries', dt: 'ioi Purchases Receipt' },
				{ tab_name: 'detail_purchases_stock_output', dt: 'ioi Stock Output' },
				{ tab_name: 'detail_purchases_invoices', dt: 'ioi Purchases Invoice' },
			]
		}

		detail_tabs.forEach(tab => {
			const container = `
				<div id="html_good_purch_whs_detail_content" class="w-100">
					<div class="d-flex flex-row flex-wrap">
						${silicon_ioi.doctype.ioiGoodPurchWhsDetailFrame.build_panel(tab.tab_name)}
						${silicon_ioi.doctype.ioiGoodPurchWhsDetailFrame.show_dossier_label(tab.tab_name)}
					</div>
					<div id="${tab.tab_name}_grid" class="table table-bordered" data-custom-grid="true"></div>
				</div>`

			if (silicon_ioi.doctype.ioiGoodPurchWhsDetailFrame.selected_option) {
				const good_purch_whs_detail_content = $('#html_good_purch_whs_detail_content')[0]
				if (good_purch_whs_detail_content) good_purch_whs_detail_content.remove()

				silicon_ioi.doctype.ioiGoodPurchWhsDetailFrame.this.frm.fields_dict[`html_good_purch_whs_detail`].$wrapper.append(container);
			} else {
				silicon_ioi.doctype.ioiGoodPurchWhsDetailFrame.this.frm.fields_dict[`html_${tab.tab_name}`].$wrapper.empty();
				silicon_ioi.doctype.ioiGoodPurchWhsDetailFrame.this.frm.fields_dict[`html_${tab.tab_name}`].$wrapper.append(container);
			}

			silicon_ioi.doctype.ioiGoodPurchWhsDetailFrame.build_detail_grid(tab.tab_name, tab.dt)
		})
	}

	static async build_detail_grid(tab, dt) {

		let format_link = (cell, formatterParams) => {
			if (cell.getValue() != null && cell.getValue() != "") {
				let html = `<span style="cursor: pointer; text-decoration: underline;">${cell.getData().parent} | ${cell.getData().idx}</span>`
				return html
			}
		}

		let link_onclick = (e, cell) => {
			if (cell.getValue() != null && cell.getValue() != "") {
				let url = ""

				if (cell.getColumn().getField() == "name") {
					let parent = cell.getData().parent
					let name = cell.getData().name
					url = `/app/${dt.toLowerCase().replace(/\s+/g, '-')}/${parent}?id=${name}`
				}

				window.open(url, "_blank")
			}
		}

		let format_checkbox = (cell) => {
			let value = cell.getValue()

			if (value != null) {
				if (value == 0) {
					return `<div class="w-100 h-100 d-flex justify-content-center align-items-center"><input type="checkbox" value="" style="pointer-events: none;"></div>`
				} else {
					return `<div class="w-100 h-100 d-flex justify-content-center align-items-center"><input type="checkbox" value="" checked style="pointer-events: none;"></div>`
				}
			}
		}

		let budget_type_editor = () => false

		if (frappe.user_roles.includes('ioi Dossier Manager')) {
			const item_budget_list = await frappe.call('silicon_ioi.ioi_trakker.doctype.ioi_dossier_history.ioi_dossier_history.get_item_budget_list').then(r => r.message)

			budget_type_editor = (cell, onRendered, success, cancel, editorParams) => {
				let editor = document.createElement("select");
	
				item_budget_list.map(item => {
					let newOption = new Option(item, item);
					editor.add(newOption, undefined);
				})
	
				editor.style.width = "100%";		
				editor.value = cell.getValue()
	
				onRendered(function(){
					editor.focus();
					editor.style.css = "100%";
				});
	
				let method = 'silicon_ioi.ioi_trakker.doctype.ioi_dossier_history.ioi_dossier_history.update_item_budget';
	
				function successFunc() {
					if (editor.value !== cell.getValue()) {
						frappe.call(method, { doctype: dt, parent: cell.getData().parent, name: cell.getData().name, value: editor.value }).then(r => {
							success(r.message)
						})
					}
				}
	
				editor.addEventListener("change", successFunc);
				editor.addEventListener("blur", () => success(editor.value));
	
				return editor;
			}
		}

		let columns = [
			{ title: __("ID"), field: "name", minWidth: 100, formatter: format_link, cellClick: link_onclick },
			{ title: __("Status"), field: "ioistatus_description", minWidth: 100 },
			{ title: __("Item"), field: "item_id", minWidth: 100 },
			{ title: __("Manufacturer Catalog"), field: "manufacturer_ref", minWidth: 100 },
			{ title: __("Description"), field: "item_description", minWidth: 100 },
			{ title: __("Unit Price"), field: "unit_price", minWidth: 100 },
			{ title: __("Item budget type"), field: "item_budget_id", minWidth: 100, editor: budget_type_editor },
			{ title: __("Document date"), field: "document_date", minWidth: 100 },
			{ title: __("Period"), field: "period_id", minWidth: 100 },
			{ title: __("Sys value line"), field: "value_line_sys_currency", minWidth: 100, bottomCalc:"sum" },
		]

		if (dt.toLowerCase().includes('stock output')) {
			columns = columns.filter(col => !['manufacturer_ref', 'unit_price', 'item_budget_id', 'document_date'].includes(col.field));
			columns.splice(4, 0, { title: __("Unit"), field: "unit_id", minWidth: 100 })
			columns.splice(4, 0, { title: __("Reserved qty"), field: "reserved_qty", minWidth: 100 })
			columns.splice(4, 0, { title: __("Delivered qty"), field: "delivered_qty", minWidth: 100 })
			columns.splice(4, 0, { title: __("Warehouse"), field: "warehouse_id", minWidth: 100 })
		}

		if (dt.toLowerCase().includes('price request')) {
			columns.splice(5, 0, { title: __("Quoted qty"), field: "quoted_qty", minWidth: 100 })
			columns.splice(5, 0, { title: __("Ordered qty"), field: "ordered_qty", minWidth: 100 })
		}


		if (dt.toLowerCase().includes('purchases invoice')) {
			columns.splice(5, 0, { title: __("Invoiced qty"), field: "invoiced_qty", minWidth: 100 })
			columns.splice(5, 0, { title: __("Delivered qty"), field: "delivered_qty", minWidth: 100 })
		}

		if (dt.toLowerCase().includes('purchases order') || dt.toLowerCase().includes('purchases receipt')) {
			columns.splice(5, 0, { title: __("Invoiced qty"), field: "invoiced_qty", minWidth: 100 })
			columns.splice(5, 0, { title: __("Delivered qty"), field: "delivered_qty", minWidth: 100 })
			columns.splice(5, 0, { title: __("Ordered qty"), field: "ordered_qty", minWidth: 100 })
		}

		if (dt.toLowerCase().includes('purchases receipt') || dt.toLowerCase().includes('purchases invoice')) {
			columns.splice(1, 0, { title: __("Is direct trakker"), field: "trakker_direct", minWidth: 100, formatter: format_checkbox })
		}

		if (dt.toLowerCase().includes('purchases')) {
			columns = columns.filter((col) => col.field !== 'item_budget_id')
		}

		let table = new ioi.Tabulator(`#${tab}_grid`, {
			maxHeight: 800,
			data: [],
			layout: "fitColumns",
			movableColumns: true,
			resizableColumns: true,
			showProfiles: true,
			autoRedraw: true,
			initialSort: [
				{ column: "name", dir: "asc" },
			],
			rowFormatter:function(row){	
				let data = row.getData();

				if (data.ioistatus_bgcolor != null && data.ioistatus_bgcolor != "") {
					row.getCell("ioistatus_description").getElement().style.backgroundColor = data.ioistatus_bgcolor;
					row.getCell("ioistatus_description").getElement().style.color = data.ioistatus_fontcolor;
				}

				if (data.ioiuserstatus_bgcolor != null && data.ioiuserstatus_bgcolor != "") {
					row.getCell("ioiuserstatus_description").getElement().style.backgroundColor = data.ioiuserstatus_bgcolor;
					row.getCell("ioiuserstatus_description").getElement().style.color = data.ioiuserstatus_fontcolor;
				}
			},
			columns: columns,
		});

		table.on("tableBuilt", () => {
			table.footerManager.element.setAttribute('data-custom-grid-bottom', 'true')
		});

		table.on("headerClick", function (e, column) {
			let ordered_field = document.getElementById(`${tab}_orderby`)
			ordered_field.value = column.getField()

			let ordered_dir = document.getElementById(`${tab}_order`)
			ordered_dir.value = table.getSorters()[0].dir

			silicon_ioi.doctype.ioiGoodPurchWhsDetailFrame.col_click(tab)
		});

		silicon_ioi.doctype.ioiGoodPurchWhsDetailFrame.detail_grid_search(table, dt, tab)

		document.getElementById(`${tab}_bt_search`).onclick = () => silicon_ioi.doctype.ioiGoodPurchWhsDetailFrame.detail_grid_search(table, dt, tab)
		document.getElementById(`${tab}_bt_export`).onclick = () => silicon_ioi.doctype.ioiGoodPurchWhsDetailFrame.export_data(table, tab)
		document.getElementById(`${tab}_bt_clear`).onclick = () => {
			document.getElementById(`${tab}_search`).value = ''
			document.getElementById(`${tab}_topn`).value = 100
			table.clearData()
		}
		document.getElementById(`${tab}_up_to_date`).onchange = () => silicon_ioi.doctype.ioiGoodPurchWhsDetailFrame.detail_grid_search(table, dt, tab)
		document.getElementById(`${tab}_checkbox_discard`).onclick = () => silicon_ioi.doctype.ioiGoodPurchWhsDetailFrame.detail_grid_search(table, dt, tab)

		document.getElementById(`${tab}_search`).addEventListener("keypress", function(event) {
			if (event.key === "Enter") {
				event.preventDefault();
				silicon_ioi.doctype.ioiGoodPurchWhsDetailFrame.detail_grid_search(table, dt, tab)
			}
		});

		document.getElementById(`${tab}_topn`).addEventListener("keypress", function(event) {
			if (event.key === "Enter") {
				event.preventDefault();
				silicon_ioi.doctype.ioiGoodPurchWhsDetailFrame.detail_grid_search(table, dt, tab)
			}
		});
	}

	static col_click(tab) {
		if (document.getElementById(`${tab}_orderby`).value == 'name') {
			document.getElementById(`${tab}_orderby`).value = 'a.name';
		}

		document.getElementById(`${tab}_bt_search`).click();
	}

	static detail_grid_search(table, dt, tab) {
		let method = 'silicon_ioi.ioi_trakker.doctype.ioi_dossier_history.ioi_dossier_history.ioi_dossier_detail_get_list';

		frappe.call({
			method: method,
			args: {
				doctype: dt,
				dossier_id: silicon_ioi.doctype.ioiGoodPurchWhsDetailFrame.dossier_id,
				search: document.getElementById(`${tab}_search`).value,
				topn: document.getElementById(`${tab}_topn`).value,
				orderby: document.getElementById(`${tab}_orderby`).value,
				order: document.getElementById(`${tab}_order`).value,
				discard: document.getElementById(`${tab}_checkbox_discard`).checked,
				uptodate: document.getElementById(`${tab}_up_to_date`).value,
			},
			async: false,
			callback: function (r) {
				if (r.message.length > 0) {
					if (table.initialized) {
						table.setData(r.message)
					} else {
						table.on('tableBuilt', () => table.setData(r.message))
					}
				} else {
					if (table && table.initialized) table.clearData()
				}
			}
		})
	}

	static export_data(table, default_filename) {
		let export_tabulator = () => {
			let filename = document.getElementById('export_filename').value;
			let filetype = document.getElementById('export_file_type').value;

			if (filename.trim() == '') {
				filename = default_filename;
			}

			if (filetype.toUpperCase() == 'XLSX') {
				table.download("xlsx", `${filename}.xlsx`);
			} else {
				table.download("csv", `${filename}.csv`, { delimiter: ";" })
			}
		}

		silicon_ioi.ioiCommon.export_form(export_tabulator, true);
	}
}

silicon_ioi.doctype.ioiGoodPurchWhsDetailFrame = ioiGoodPurchWhsDetailFrame;