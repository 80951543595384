frappe.provide('silicon_ioi.doctype');


export class ioiEPosConnect
{

	// static _instance;
	static list_epos_device_connected = []


	// constructor() {
	// 	if (ioiEPosConnect._instance) {
	// 		return ioiEPosConnect._instance;
	// 	}
	// 	ioiEPosConnect._instance = this;
	// 	ioiEPosConnect.list_epos_device_connected = []
	// }


	// static getInstance() {
	// 	if (!ioiEPosConnect._instance) {
	// 		ioiEPosConnect._instance = new ioiEPosConnect();
	// 	}
	// 	return ioiEPosConnect._instance;
	// }

	static is_connected(type, ip, port){
		if (!ioiEPosConnect.is_listed(type, ip, port)){
			return false
		}

		let epos_device = ioiEPosConnect.get_epos_device(type, ip, port)

		if (!epos_device){
			return false
		}

		if (!ioiEPosConnect.is_device_connected(epos_device)){
			ioiEPosConnect.delete_from_list(ip, port)
			return false
		}
		return true
	}



	static is_listed(type, ip, port){
		if (ioiEPosConnect.list_epos_device_connected){
			let device_con_data = {}
			for (var i = 0; i < ioiEPosConnect.list_epos_device_connected.length; i++) {
				device_con_data = ioiEPosConnect.list_epos_device_connected[i];
				if (
					(device_con_data.type == type) &&
					(device_con_data.ip == ip) &&
					(device_con_data.port == port)
				){
					return true
				}
			}
			return false
		}
	}


	static is_device_connected(epos_device){
		if(epos_device.ePosDev.isConnected()){
			return true
		}else{
			return false
		}
	}


	static disconnected_all_device(ip, port){
		let device_con_data = {}
		for (var i = 0; i < ioiEPosConnect.list_epos_device_connected.length; i++) {
			device_con_data = ioiEPosConnect.list_epos_device_connected[i];
			if (
				(device_con_data.ip == ip) &&
				(device_con_data.port == port)
			){
				ioiEPosConnect.disconnected_device(device_con_data.epos_device)
			}
		}
		ioiEPosConnect.delete_from_list(ip, port)
	}


	static disconnected_device(epos_device){
		//Discards the Printer object
		epos_device.ePosDev.deleteDevice(epos_device, callback_deleteDevice);
		function callback_deleteDevice(errorCode){
		//Terminates connection with device
		epos_device.ePosDev.disconnect();
		}
	}


	static add_connection(type, ip, port, epos_device){
		// type: printer / display
		if (!this.is_listed(type, ip, port)){
			ioiEPosConnect.list_epos_device_connected.push(
				{
					"type": type,
					"ip": ip,
					"port": port,
					"epos_device": epos_device
				}
			)
		}
	}


	static delete_from_list(ip, port){
		ioiEPosConnect.list_epos_device_connected = ioiEPosConnect.list_epos_device_connected.filter(
			item => (item.ip !== ip && item.port !== port)
		)
	}


	static get_epos_device(type, ip, port){
		let device_con_data = {}
		for (var i = 0; i < ioiEPosConnect.list_epos_device_connected.length; i++) {
			device_con_data = ioiEPosConnect.list_epos_device_connected[i];
			if (
				(device_con_data.type == type) &&
				(device_con_data.ip == ip) &&
				(device_con_data.port == port)
			){
				return device_con_data.epos_device
			}
		}
		return null
	}


}

silicon_ioi.doctype.ioiEPosConnect = ioiEPosConnect;
