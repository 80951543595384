<template>
	<div class="file-browser">
		<div class="file-browser-list">
			<div class="file-filter">
				<input

				type="search"
				class="form-control input-xs"
					v-model="search_text"
					@input="search_by_name"
				/>
			</div>
			<TreeNode
				class="tree with-skeleton"
				:node="node"
				:selected_node="selected_node"
				@node-click="toggle_node"
				@node-dblclick="on_dbl_click"
			/>
		</div>
	</div>
</template>

<script>
import TreeNode from "./QueryProfileNode.vue";

export default {
	name: "QueryProfile",
	components: {
		TreeNode
	},
	props: {
		doctype: {
			type: String,
			default: null
		},
		docname: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			node: {
				label: this.doctype ? __(this.doctype) : '',
				value: this.doctype,
				children: [],
				children_loading: false,
				is_leaf: false,
				fetching: false,
				fetched: false,
				open: false,
				filtered: true,
				parameter_exists: 1
			},
			selected_node: {},
			search_text: "",
			parameters: frappe.get_meta(this.doctype) && frappe.get_meta(this.doctype).fields && frappe.get_meta(this.doctype).fields.map(f => f.frappe_name || f.name),
			fieldnames: {}
		};
	},
	mounted() {
		this.toggle_node(this.node);
	},
	methods: {
		toggle_node(node) {
			if (!node.fetched && !node.is_leaf) {
				node.fetching = true;
				node.children_loading = false;
				this.get_fields(node.value, 0).then(fields => {
					node.open = true;
					node.children = fields;
					node.fetched = true;
					node.fetching = false;
				});
				this.select_node(node);
			} else {
				node.open = !node.open;
				this.select_node(node);
			}
		},
		select_node(node) {
			this.selected_node = node;
			cur_list.current_select = node
			cur_list.fieldnames = this.fieldnames
		},
		on_dbl_click(node) {
			cur_list.add_field(cur_list.query_profile_settings.fields_dict)
		},
		get_fields(docfield) {
			return frappe
				.call("silicon_ioi.utils.lib.system.get_document_structure", {
					doctype: this.doctype,
					docfield: docfield,
				})
				.then(r => {
					let fields = r.message;

					fields.sort((a, b) => a.label.localeCompare(b.label))
					fields.map(f => this.fieldnames[f.name] = { name: f.fieldname, parent: (docfield !== this.doctype) ? docfield : null })
					fields = fields.map(field => this.make_file_node(field));

					return fields;
				});
		},
		search_by_name: frappe.utils.debounce(function() {
			if (this.search_text === "") {
				this.node = this.folder_node;
				return;
			}
			if (this.search_text.length < 1) return;
			frappe
				.call("silicon_ioi.utils.lib.system.get_fields_by_search_text", {
					doctype: this.doctype,
					text: this.search_text
				})
				.then(r => {
					let fields = r.message || [];

					fields.sort((a, b) => {
						if (!a.label && !b.label) {
							return a.fieldname.localeCompare(b.fieldname);
						} else if (!a.label) {
							return 1;
						} else if (!b.label) {
							return -1;
						} else {
							return a.label.localeCompare(b.label);
						}
					});
	
					fields = fields.map(field => this.make_file_node(field));

					if (!this.folder_node) {
						this.folder_node = this.node;
					}
					this.node = {
						label: __("Search Results"),
						value: "",
						children: fields,
						by_search: true,
						open: true,
						filtered: true,
						parameter_exists: true
					};
				});
		}, 300),
		make_file_node(field) {
			let label_name = field.label ? field.label.includes("_") ? '' : field.label : ''
			let label = frappe.utils.file_name_ellipsis(label_name !== '' ? `${field.label} (${field.fieldname})` : field.fieldname, 40);

			return {
				label: label,
				value: field.name,
				is_leaf: field.is_leaf,
				fetched: field.is_leaf, // fetched if node is leaf
				children: [],
				children_loading: false,
				open: false,
				fetching: false,
				filtered: true,
				parameter_exists: this.parameters.includes(field.name),
				field: field
			};
		},
	}
};
</script>

<style>
.file-browser-list {
	overflow: hidden!important;
	margin-top: 0!important;
	height: 100%!important;
    margin-bottom: 10px!important;
}

.file-filter {
	padding: 3px!important;
}

.tree {
	padding-left: 0!important;
	padding-right: 0!important;
	max-height: 500px!important;
	margin-top: 25px!important;
}
</style>
