let contacts = [];
const _internal = __("Internal")
const _external = __("External")
export async function ioi_print_dialog(report_center = false) {

	let view_type = '';
	let doc_to_sign_enable = 0;

	ok_sign_enable = await (frappe.db.get_single_value("ioi Doc To Sign Settings","ok_sign_enable"));
	ok_sign_account_status = await (frappe.db.get_single_value("ioi Doc To Sign Settings","ok_sign_account_status"));

	doc_to_sign_enable = (ok_sign_enable == 1) && (ok_sign_account_status == "OK") ? 1 : 0

	if (report_center){
		view_type = 'List'
	}else{
		switch (frappe.router.current_route[0]) {
			case 'List':
				view_type = frappe.router.current_route[2];
				break;
			case 'Form':
				view_type = 'DocType'
				break;
			default:
				frappe.msgprint(__('View not supported'));
		}
	}


	function reload_last_report(){
		frappe.call({
			method: "silicon_ioi.ioi_system.doctype.ioi_report.ioi_report.get_last_printed_report",
			args: {
				view: frappe.get_route()[0],
				doctype: frappe.get_route()[1]
			},
			callback: (r) => {
				if (r.message){
					let family = r.message[1];
					let type = r.message[2];
					let group = r.message[3];
					let lng = r.message[4];
					let report_name = r.message[0];

					if (cur_dialog.fields_dict['family'].df.options.includes(family)){
						cur_dialog.set_value('family', family).then(() => {
							if (type && cur_dialog.fields_dict['type'].df.options.includes(type)){
								cur_dialog.set_value('type', type).then(() => {
									if (group && cur_dialog.fields_dict['group'].df.options.includes(group)){
										cur_dialog.set_value('group', group)
									}
								})
							}
						})
					}

					// if (lng && cur_dialog.fields_dict['language'].df.options.find((i) => i.value == 'es')){
					// 	cur_dialog.set_value('language', lng)
					// }

				}
			}
		});
	}

	function refresh_source_dt_html(dt, show_oksign){
		let html_field = cur_dialog.get_field('source_dt_html');

		html_field.$wrapper.empty();
		let html = `

		<div class="">
			 <style>
				@keyframes zoom {
				0%, 100% {
					transform: scale(1);
				}
				50% {
					transform: scale(1.2);
				}
				}
			</style>
			<div class="text-center">
				<div style="display: flex; align-items: flex-start; justify-content: center;">
					<img src="/assets/silicon_ioi/images/modules/${frappe.scrub(dt)}.svg"  alt="" class="img-fluid" style="width: 50px;position: relative;">

					<img id="img-oksign"
					     style="width: 50px;position: absolute;left: 50%;translateX(-5%) ; animation: zoom 2s infinite; will-change: transform;"
						 src="/assets/silicon_ioi/images/oksign.png" alt="OK!Sign" ${show_oksign ? '': 'hidden'}>
				</div>
				<h2><span class="label label-info">${__(dt)}</span></h2>
			</div>

		</div>
		`;
		html_field.$wrapper.append(html);
	}

	function refresh_optional_controls(by_report_name = false) {

		let family = cur_dialog.get_field('family').value;
		let type = cur_dialog.get_field('type').df.hidden ? '' : cur_dialog.get_field('type').value;
		let group = cur_dialog.get_field('group').df.hidden || cur_dialog.get_field('type').df.hidden ? "" : cur_dialog.get_field('group').value;
		let rpt_list = report_name_list(family, type, group);

		if (!by_report_name){
			cur_dialog.set_df_property("report_name","hidden",rpt_list.length > 1 ? 0 : 1);
			cur_dialog.set_df_property("report_name","options",rpt_list.map((rpt) => rpt.name));
			if (rpt_list.length > 1){
				cur_dialog.fields_dict.report_name.set_value(rpt_list[0].name);
			}
		}

		let selected_report = get_selected_report(family, type, group);

		if (selected_report && selected_report.bulk_printing == 1 && view_type != 'DocType' && selected_report.file_format == 'PDF') {
			$('#merge_checkbox_group').removeClass("hidden")
		} else {
			$('#merge_checkbox_group').addClass("hidden")
		}

		if (selected_report && ((selected_report.can_export_to_excel == 1 && selected_report.file_format == 'PDF') || (selected_report.file_format == 'XLSX'))) {
			cur_dialog.get_secondary_btn().show();
		} else {
			cur_dialog.get_secondary_btn().hide();
		}

		if (selected_report && selected_report.file_format == 'XLSX') {
			cur_dialog.get_primary_btn().hide()
		} else {
			cur_dialog.get_primary_btn().show()
		}

		if ($('#report-cfg-btn')) {
			$('#report-cfg-btn').remove()
		}
		if (selected_report) {
			let show_oksign = doc_to_sign_enable == 1 && selected_report.enable_signature_pdf == 1;
			refresh_source_dt_html(selected_report.doc_type, show_oksign);


			cur_dialog.header.find('.modal-actions')[0].children[0].insertAdjacentHTML('beforeBegin', '<button id="report-cfg-btn" class="btn btn-modal-minimize btn-link"><svg class="icon  icon-sm" style=""><use class="" href="#icon-setting-gear"></use></svg></button>')

			$("#report-cfg-btn").on('click', () => {
				window.open('/app/ioi-report/'+selected_report.name,'_blank');
			})
		}

	}

	function isViewList() {
		return frappe.router.current_route[0] == 'List';
	}

	function isViewDocType() {
		return frappe.router.current_route[0] == 'Form';
	}

	function report_name_list(family, type, group){
		let result = [];

		reportList.forEach((report) => {
			if (((report.family || '') == (family || '')) &&
				((report.type || '') == (type || '')) &&
				((report.group || '') == (group || ''))) {
					result.push(report);
			}
		});

		return result;
	}

	function get_selected_report(family, type, group) {
		let selected_report = null;
		let force_report_name = ""
		if (cur_dialog.fields_dict.report_name.df.hidden == 0){
			if (cur_dialog.fields_dict.report_name.value){
				force_report_name = cur_dialog.fields_dict.report_name.value;
			}
		}
		reportList.forEach((report) => {
			if (!selected_report){
				if ((((report.family || '') == (family || '')) &&
					((report.type || '') == (type || '')) &&
					((report.group || '') == (group || '')) &&
					(force_report_name == "")) || (force_report_name == report.name ) ){
					selected_report = report;
				}
			}
		});
		return selected_report;
	}
	function refresh_type_report(current_family) {
		let typeList = []
		reportList.forEach((report) => {

			if ((report.family == current_family) && (report.type) && (typeList.indexOf(report.type) == -1)) {
				typeList.push(report.type);
			}
		});
		return typeList;
	}

	function refresh_group_report(current_family, current_type) {
		let groupList = []
		reportList.forEach((report) => {
			if ((report.family == current_family) && (report.type == current_type) && (report.group) && (groupList.indexOf(report.group) == -1)) {
				groupList.push(report.group);
			}
		});

		return groupList;
	}

	function get_report_family_list() {
		reportList = []
		let familyList = [];

		frappe.call({
			method: "silicon_ioi.ioi_system.doctype.ioi_report.ioi_report.get_report_list",
			args: {
				doc_type: view_type == 'List' || view_type == 'Report' ? cur_list?.doctype || "" : cur_frm.doctype,
				type_view: view_type,
				checked_items: view_type == 'List' || view_type == 'Report' ? (cur_list?.get_checked_items().length > 0 ? 1 : 0) : 0,
				report_center: report_center
			},
			async: false,
			callback(r) {

				familyList = []
				reportList = r.message;
				if (r.message) {
					r.message.forEach((report) => {
						if (familyList.indexOf(report.family) == -1) {
							familyList.push(report.family);
						}
					});
				}
			},
		});
		return familyList;
	}

	async function get_contacts_email() {
		return new Promise((resolve, reject) => {
			if (cur_frm) {
				let customer_id;
				let supplier_id;

				switch (cur_frm.doc.doctype) {
					case "ioi Sales Quote":
					case "ioi Sales Order":
						customer_id = cur_frm.doc.order_customer_id;
						break;
					case "ioi Sales Delivery":
						customer_id = cur_frm.doc.delivery_customer_id;
						break;
					case "ioi Sales Invoice":
						customer_id = cur_frm.doc.invoice_customer_id;
						break;
					case "ioi Customer":
						customer_id = cur_frm.doc.identification;
						break;
					case "ioi Purchases Price Request":
						supplier_id = cur_frm.doc.delivery_supplier_id;
						break;
					case "ioi Purchases Order":
						supplier_id = cur_frm.doc.order_supplier_id;
						break;
					case "ioi Purchases Receipt":
						supplier_id = cur_frm.doc.delivery_supplier_id;
						break;
					case "ioi Purchases Invoice":
						supplier_id = cur_frm.doc.invoice_supplier_id;
						break;
					case "ioi Supplier":
						supplier_id = cur_frm.doc.identification;
						break;
				}

				if (customer_id) {
					resolve(frappe.db.get_list("Contact", { "fields": ['email_id'], "pluck": "email_id", "filters": { "customer": customer_id, "email_id": ["!=", ""] } }));
				} else if (supplier_id) {
					resolve(frappe.db.get_list("Contact", { "fields": ['email_id'], "pluck": "email_id", "filters": { "supplier": supplier_id, "email_id": ["!=", ""] } }));
				} else {
					resolve([])
				}
			}
		});
	}

	function email_input_on_blur(event_input) {

		let data = event_input.target.value;
		data = data.replaceAll(' ', '').replaceAll(',', ';').replaceAll(';', '; ');

		event_input.target.value = data;

	}

	function add_contact_to_input(c_click_event) {
		let me = cur_dialog;
		if (c_click_event.target.classList.contains('other-contacts')) {
			contact_dlg = new frappe.ui.Dialog({
				title: __("Contact search"),
				size: "large",
				fields: [
					{
						fieldname: 'contact',
						fieldtype: 'Link',
						label: __('Search'),
						options: "Contact",
						get_query: function () {
							return {
								query: "silicon_ioi.ioi_sales.doctype.ioi_customer.ioi_customer.ioi_contact_email_get_all",

							};
						},
						onchange: () => {
							if (contact_dlg.fields_dict['contact'].value) {
								frappe.db.get_value("Contact", { "name": contact_dlg.fields_dict['contact'].value }, "email_id").then((v) => {
									contact_dlg.fields_dict['email'].set_value(v.message.email_id);
								});
							} else {
								contact_dlg.fields_dict['email'].set_value('');
							}
						}
					},
					{
						fieldname: 'email',
						fieldtype: 'Data',
						label: __('Email'),
						read_only: 1,
						reqd: 1
					}
				],
				primary_action_label: __("Select"),
				primary_action: (values) => {

					let email = values.email;
					let input_fieldname = $(c_click_event.target.parentElement.parentElement.parentElement).find('input')[0].attributes['data-fieldname'].value;

					let field = me.get_field(input_fieldname);

					if (field) {
						let current_value = field.get_value();
						field.set_value(current_value + (current_value ? ';' : '') + email);
						setTimeout(() => {
							field.$input.trigger('blur');
						}, 50);

						contact_dlg.hide();
					}


				},
				secondary_action_label: __("Cancel"),
				secondary_action: () => {
					contact_dlg.hide();
				}
			});
			contact_dlg.show()
		} else {
			let email = c_click_event.target.text;
			let input_fieldname = $(c_click_event.target.parentElement.parentElement.parentElement).find('input')[0].attributes['data-fieldname'].value;

			let field = cur_dialog.get_field(input_fieldname);

			if (field) {
				let current_value = field.get_value();
				field.set_value(current_value + (current_value ? ';' : '') + email);
				setTimeout(() => {
					field.$input.trigger('blur');
				}, 50);
			}
		}
	}

	function get_report_email_converted(report, answers, language) {
		return new Promise((resolve, reject) => {
			let parameters;
			frappe.call({
				method: "silicon_ioi.ioi_system.doctype.ioi_report.ioi_report.get_converted_email_parameters",
				args: {
					report_name: report.name,
					answers: answers,
					doctype: report.doc_type,
					doc_name: (isViewDocType() ? cur_frm.doc.name : ''),
					language : language
				},
				async: false,
				callback: (r) => {
					parameters = r.message;
				}
			});
			return resolve(parameters);
		});
	}

	function get_file(report, language, answers) {
		report.destination = 'DOW';

		let params = new URLSearchParams({
			report: report.name,
			type_view: report.type_view,
			doctype: report.doc_type,
			doc_name: report.doc_name,
			language: language,
			question_answers: JSON.stringify(answers),
			output_format: report.file_format,
			silent_mode: false,
			route: frappe.get_route_str()
		});
		let url = `/api/method/silicon_ioi.ioi_system.doctype.ioi_report.ioi_report.print_report?${params}`;

		frappe.call({
			type: "GET",
			args: {
			},
			async: false,
			url: url,
		}).then((r) => {
			if (r.message.success) {
				files = r.message.files;
				files.forEach((f) => {
					window.open(`/api/method/silicon_ioi.utils.lib.ioiFile.download_file?name=${f}`);
				});
			}
		});

	}

	function send_email_after_review(report_name, type_view, doctype, doc_name, lang, question_answers, selected_items, selected_details, output_format = "pdf", custom_email_params = {}) {
		// let params = new URLSearchParams({
		// 	report: report_name,
		// 	type_view: type_view,
		// 	doctype: doctype,
		// 	doc_name: doc_name,
		// 	language: lang,
		// 	question_answers: JSON.stringify(question_answers),
		// 	selected_items: selected_items,
		// 	selected_details: selected_details,
		// 	output_format: output_format,
		// 	silent_mode: true,
		// 	custom_email_params: JSON.stringify(custom_email_params),
		// 	route: frappe.get_route_str()
		// });

		let url = `/api/method/silicon_ioi.ioi_system.doctype.ioi_report.ioi_report.print_report`;

		frappe.call({
			type: "POST",
			args: {
				report: report_name,
				type_view: type_view,
				doctype: doctype,
				doc_name: doc_name,
				language: lang,
				question_answers: JSON.stringify(question_answers),
				selected_items: selected_items,
				selected_details: selected_details,
				output_format: output_format,
				silent_mode: true,
				custom_email_params: JSON.stringify(custom_email_params),
				route: frappe.get_route_str()
			},
			async: true,
			url: url,
			callback: (r) => {
				if (r.message.success) {
					frappe.show_alert({ message: __('Email sent'), indicator: 'green' }, 5);
				} else {
					frappe.show_alert({ message: __('Failed to send email'), indicator: 'red' }, 5);
				}
			}
		});

	}

	async function edit_email_before_send(report, language, answers) {

		return new Promise(async (resolve, reject) => {

			const editEmailDlg = new frappe.ui.Dialog({
				title: __("Email review"),
				size: "extra-large",
				static: true,
				fields: [
					{
						fieldname: 'send_to',
						fieldtype: 'Data',
						label: __('To'),
					},
					{
						fieldname: 'send_cc',
						fieldtype: 'Data',
						label: __('Cc')
					},
					{
						fieldname: 'send_bcc',
						fieldtype: 'Data',
						label: __('Bcc')
					},
					{
						fieldname: 'email_subject',
						fieldtype: 'Data',
						label: __('Subject')
					},
					{
						label: __('Body'),
						fieldname: 'email_body',
						fieldtype: 'Text Editor'
					},
				],
				primary_action: (values) => {

					let email_params = {
						recipients: values.send_to,
						recipients_cc: values.send_cc,
						recipients_bcc: values.send_bcc,
						subject: values.email_subject,
						message: values.email_body
					};
					send_email_after_review(
						report["name"],
						report["type_view"],
						report["doc_type"],
						(isViewDocType() ? cur_frm.doc.name : ''),
						language,
						answers,
						JSON.stringify([]),
						JSON.stringify([]),
						report["file_format"],
						email_params
					);
					editEmailDlg.hide();
					resolve();
				},
				primary_action_label: __("Send"),
				secondary_action: () => {
					editEmailDlg.hide();
					resolve();
				},
				secondary_action_label: __("Cancel"),
				on_page_show: async () => {
					//Add Get file button
					let icon_type = report.file_format.toLowerCase() == "pdf" ? "pdf" : "excel";
					editEmailDlg.add_custom_action(`<i class="fa fa-file-${icon_type}-o fa-3x"></i>`, () => {
						get_file(report, language, answers);
					});



					// onblur email inputs
					let field;
					field = editEmailDlg.get_field("send_to");
					if (field) {
						field.$input.on('blur', email_input_on_blur)
					}

					field = editEmailDlg.get_field("send_cc");
					if (field) {
						field.$input.on('blur', email_input_on_blur)
					}

					field = editEmailDlg.get_field("send_bcc");
					if (field) {
						field.$input.on('blur', email_input_on_blur)
					}

					//Get Email Report Data
					get_report_email_converted(report, answers, language).then((parameters) => {
						function escapeString(txt){
							return cstr(txt).replaceAll(/&/g, "&amp;")
							.replaceAll(/</g, "&lt;")
							.replaceAll(/>/g, "&gt;")
							.replaceAll(/"/g, "&quot;")
							.replaceAll(/'/g, "&#039;")
							.replaceAll(/%/g, "&#37;")
							.replaceAll(/\[/g, "&#91;")
							.replaceAll(/\]/g, "&#93;")
							.replaceAll(/{/g, "&#123;")
							.replaceAll(/}/g, "&#125;")
							.replaceAll(/\n/g, "&#10;")
							.replaceAll(/\t/g, "&#9;");
						}

						let field;
						field = editEmailDlg.get_field("send_to");
						if (field) {
							field.set_value(parameters['to'])
						}

						field = editEmailDlg.get_field("send_cc");
						if (field) {
							field.set_value(parameters['cc'])
						}

						field = editEmailDlg.get_field("send_bcc");
						if (field) {
							field.set_value(parameters['bcc'])
						}

						field = editEmailDlg.get_field("email_subject");
						if (field) {
							field.set_value(parameters['subject'])
						}

						field = editEmailDlg.get_field("email_body");
						if (field) {
							field.set_value(parameters['content'])
						}
						$("#subject-preview-error").remove()
						$("#body-preview-error").remove()
						if (!parameters.template_subject_output?.success){
							let el = editEmailDlg.$wrapper.find("[data-fieldname=email_subject] .clearfix");
							if (el.length > 0){
								let error_msg = escapeString(parameters.template_subject_output.error)
								el[0].insertAdjacentHTML('beforeEnd',`<span id="subject-preview-error" class="indicator-pill no-indicator-dot whitespace-nowrap red" title="${error_msg}" style="cursor: pointer"><span>error</span></span>`);
							}
						}
						if (!parameters.template_message_output?.success){
							let el = editEmailDlg.$wrapper.find("[data-fieldname=email_body] .clearfix");
							if (el.length > 0){
								let error_msg = escapeString(parameters.template_message_output.error)
								el[0].insertAdjacentHTML('beforeEnd',`<span id="body-preview-error" class="indicator-pill no-indicator-dot whitespace-nowrap red" title="${error_msg}" style="cursor: pointer"><span>error</span></span>`);
							}
						}
					})
					// Add menus email
					get_contacts_email().then((emails) => {

						const contacts_email_list = emails;
						let fieldnames = [{ fn: 'send_to', label: __('...') }, { fn: 'send_cc', label: __('...') },
						{ fn: 'send_bcc', label: __('...') }];

						fieldnames.forEach((fld_grp) => {
							let field = editEmailDlg.get_field(fld_grp.fn);
							if (field) {
								let btn_grp = `
									<div class="input-group-pretend">
										<div class="input-group-text" style="cursor: pointer;border-top-right-radius: 0; border-bottom-right-radius: 0"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">${fld_grp.label}</div>
										<div class="dropdown-menu">
										%EMAIL_LIST%
										<div role="separator" class="dropdown-divider"></div>
										<a class="dropdown-item other-contacts">${__('Other contacts')}</a>
										</div>
									</div>`;
								let html_email_list = "";

								contacts_email_list.forEach((e) => {
									html_email_list += `<a class="dropdown-item">${e}</a>`;
								});

								btn_grp = btn_grp.replace("%EMAIL_LIST%", html_email_list);

								field.$input[0].insertAdjacentHTML('beforeBegin', btn_grp);
								$(field.$input[0].parentElement).addClass('input-group');

								$(field.$input[0].parentElement).find('.dropdown-item').on('click', add_contact_to_input);

							}
						});
					});
				}
			});
		});

	}

	function sleep_ms(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	function printReportBroReport(report, language) {


		function print_merged_reports(report_name, type_view, doctype, doc_name, lang, question_answers, selected_items, selected_details, silent_mode = false) {
			let params = new URLSearchParams({
				report: report_name,
				type_view: type_view,
				doctype: doctype,
				doc_name: doc_name,
				language: lang,
				question_answers: JSON.stringify(question_answers),
				selected_items: JSON.stringify(selected_items)
			});
			let url = `/api/method/silicon_ioi.ioi_system.doctype.ioi_report.ioi_report.print_merged_reports?${params}`;
			if (!silent_mode) {
				let w = window.open(url);
				if (!w) {
					frappe.msgprint(__('Please enable pop-ups'));
					return;
				}
			} else {
				fetch(url).then((result) => {
					console.log(result);
					if (result.status == 200) {
						frappe.show_alert({ message: __('Email sent'), indicator: 'green' }, 5);
					} else {
						frappe.show_alert({ message: __('Failed to send email'), indicator: 'red' }, 5);
					}
				})
			}
		}

		function preset_contact(dlg, select_field){
			if (cstr(dlg.fields_dict[select_field].get_value()) == ""){
				let elems = JSON.parse(dlg.fields_dict[select_field].last_options);
				if (elems.length > 0 ){
					dlg.fields_dict[select_field].set_value(elems[0].value)
				}
			}
		}

		function refresh_contact_select(dlg, is_internal_filter, idx, max_idx){
			let contacts_name_selected = [];

			for (var i = 1; i <= max_idx; i++){
				if (i != idx){
					let value = dlg.fields_dict[`signer_contact_${i}`].get_value();
					if (cstr(value)){
						contacts_name_selected.push(value)
					}
				}
			}

			let result = []
			if (is_internal_filter){
				result = contacts.filter((contact) => {
							if(contact.internal && !contacts_name_selected.includes(contact.name)){
								return contact
							}
						});
				result = result.map((contact) => { return {label: contact.email_id, value: contact.name}});
			}else{
				result = contacts.filter((contact) => {
					if(contact.external && !contacts_name_selected.includes(contact.name)){
						return contact
					}
				});
				result = result.map((contact) => { return {label: contact.email_id, value: contact.name}})
			}
			result.unshift({label:"", value:""})
			dlg.set_df_property(`signer_contact_${idx}`,'options', result)

		}
		function sign_dlg_refresh_fields_html(dlg, params){
			let internal_signer_count = 0;
			let external_signer_count = 0;
			let contacts = [];
			for (var i = 1; i <= params.max_total_signers; i++){
				if (dlg.fields_dict[`signer_contact_${i}`].get_value()){
					contacts.push(dlg.fields_dict[`signer_contact_${i}`].get_value());
					if (dlg.fields_dict[`signer_type_${i}`].get_value() == _internal){
						internal_signer_count++;
					}else{
						external_signer_count++;
					}
				}
			}

			let header_html_content = `
					<p>
					<b><u>${__('Minimum signers required')}:<br></u></b>
					${__('Internal')}: <span style="color: ${internal_signer_count >= params.internal_min_signer_count ? 'green' : 'black' }">${params.internal_min_signer_count} signer(s)</span><br>
					${__('External')}: <span style="color: ${external_signer_count >= params.external_min_signer_count ? 'green' : 'black' }">${params.external_min_signer_count} signer(s)</span><br>
					</p>
					`
			dlg.set_df_property("header_html","options", header_html_content);
			$("#add-signer-link").remove();
			let add_signer_html_content = `<a id="add-signer-link" href="#">+ ${__('Add signer')}</a>`;
			dlg.set_df_property("add_signer_html","options",add_signer_html_content);
			$("#add-signer-link").on("click",() => {
				if(dlg.fields_dict["sb1"].df.hidden == 1){
					dlg.set_df_property("sb1","hidden",0)
				}else{
					if(dlg.fields_dict["sb2"].df.hidden == 1){
						dlg.set_df_property("sb2","hidden",0)
					}else{
						if(dlg.fields_dict["sb3"].df.hidden == 1){
							dlg.set_df_property("sb3","hidden",0)
						}else{
							if(dlg.fields_dict["sb4"].df.hidden == 1){
								dlg.set_df_property("sb4","hidden",0)
							}else{
								frappe.show_alert({
									message: __("Max 4 signers supported"),
									indicator: "blue",
								})
							}
						}
					}
				}
				return false;
			})


			return {success: (internal_signer_count >= params.internal_min_signer_count) && (external_signer_count >= params.external_min_signer_count), contacts: contacts}
		}

		function prepare_doc_to_sign(params){
			const contact_template = `
			<i class="fa fa-user"></i><b>	%LN</b> %FN<br>
			<i class="fa fa-phone"></i>	%PH
			`;
			let signers_fields = [];

			frappe.call({
				method:"silicon_ioi.ioi_core.doctype.ioi_doc_to_sign.ioi_doc_to_sign.get_contacts_linked_document",
				args: {
					doc_type: params.source_dt,
					doc_name: params.source_dn,
				},
				async: false,
				callback:(r) => {
					contacts = r.message
				}
			})
			let internal_count = 0;
			let external_count = 0;


			for(var i = 0; i < params.max_total_signers; i++){
				let default_type = _external;
				if (params.internal_min_signer_count > 0 && internal_count < params.internal_min_signer_count){
					internal_count++;
					default_type = _internal;
				}


				signers_fields.push(
					{
						fieldname: `sb${[i+1]}`,
						fieldtype: "Section Break",
						label:__("Signer No {0}",[i+1]),
						hidden: i + 1 <= params.min_total_signers ? 0 : 1

					},
					{
						fieldname: `signer_type_${[i+1]}`,
						fieldtype: "Select",
						label:__("Type"),
						options: [_internal, _external],
						default: default_type,
						onchange: (v) => {
							const idx = v.currentTarget.getAttribute("data-fieldname").slice(-1);
							doc_to_sign_dlg.fields_dict[`signer_contact_info_${idx}`].$wrapper.empty()
							refresh_contact_select( doc_to_sign_dlg,v.currentTarget.value == _internal, idx, params.max_total_signers);
							sign_dlg_refresh_fields_html( doc_to_sign_dlg, params)
						}
					},
					{
						fieldname: `cb${[i+1]}`,
						fieldtype: "Column Break",
					},
					{
						fieldname: `signer_contact_${[i+1]}`,
						fieldtype: "Select",
						label: __("Email"),
						onchange: (v) => {
							if (v) {
								const idx = v.currentTarget.getAttribute("data-fieldname").slice(-1);
								let contact_info_html =  doc_to_sign_dlg.fields_dict[`signer_contact_info_${idx}`];
								contact_info_html.$wrapper.empty();

								let contact_name = doc_to_sign_dlg.fields_dict[`signer_contact_${idx}`].get_value();
								if (cstr(contact_name)){
									let contact_info = contacts.find((item) => item.name == contact_name)



									let html_content = contact_template.replace("%FN", cstr(contact_info.first_name))
																	   .replace("%LN", cstr(contact_info.last_name))
																	   .replace("%PH", cstr(contact_info.mobile_no) != "" ? cstr(contact_info.mobile_no) : __("N/A"))
									contact_info_html.$wrapper.html(html_content)
								}
								for (var i = 1; i <= params.max_total_signers; i++){
									if (i != idx){
										refresh_contact_select(doc_to_sign_dlg, doc_to_sign_dlg.fields_dict[`signer_type_${i}`].get_value() == _internal, i, params.max_total_signers)
									}
								}
								sign_dlg_refresh_fields_html(doc_to_sign_dlg, params)
							}
						}
					},
					{
						fieldname: `signer_contact_info_${[i+1]}`,
						fieldtype: "HTML",
					},
				)
			}

			let doc_to_sign_dlg = new frappe.ui.Dialog({
				title: __("Please select signers"),
				size: "large",
				strict: true,
				fields: [{
					fieldname: `sb_header`,
					fieldtype: "Section Break",
				},
				{
					fieldname: `header_html`,
					fieldtype: "HTML",
				},
				...signers_fields,
				{
					fieldname:'sb_footer',
					fieldtype: "Section Break",
				},
				{
					fieldname: `add_signer_html`,
					fieldtype: "HTML",
				}],
				primary_action_label: __("Start signing process"),
				primary_action: (data) => {
					output = sign_dlg_refresh_fields_html(doc_to_sign_dlg, params);

					if (output.success){
						frappe.call({
							method:"silicon_ioi.ioi_core.doctype.ioi_doc_to_sign.ioi_doc_to_sign.create_document",
							args: {
								doc_file_name: params.file_name,
								source_dt: params.source_dt,
								source_dn: params.source_dn,
								contacts: output.contacts,
								signer_marker: params.signer_marker,
								email_attachment_file: params.email_attachment_file
							},
							async: false,
							callback:(r) => {
								if (r.message.success){
									doc_to_sign_dlg.hide();
								}else{
									frappe.throw(r.message.description)
								}
							}
						})

					}else{
						frappe.msgprint(__("Missing signer(s) required"))
					}

				},
				on_page_show: () => {
					for(var i = 0; i < params.max_total_signers; i++){
						doc_to_sign_dlg.fields_dict[`signer_type_${i+1}`].input.dispatchEvent(new Event("change"))
						preset_contact(doc_to_sign_dlg, `signer_contact_${i+1}`)
					}
					sign_dlg_refresh_fields_html(doc_to_sign_dlg, params);
				}
			});
			doc_to_sign_dlg.show();
		}

		function cancel_doc_to_sign(id){
			let delete_success = false;
			frappe.call({
				method: "silicon_ioi.ioi_core.doctype.ioi_doc_to_sign.ioi_doc_to_sign.delete_doc",
				async: false,
				args: {
					name: id
				},
				callback: (r) => {
					delete_success = r.message.success;
					if (r.message.success == false){
						frappe.show_alert({
							message: __("Cancelling failed")+`: ${r.message.description}`,
							indicator: "red",
						})
					}
				}
			});
			return delete_success;
		}

		function print(report_name, type_view, doctype, doc_name, lang, question_answers, selected_items, selected_details, silent_mode = false, output_format = "pdf") {
			let params = new URLSearchParams({
				report: report_name,
				type_view: type_view,
				doctype: doctype,
				doc_name: doc_name,
				language: lang,
				question_answers: JSON.stringify(question_answers),
				selected_items: selected_items,
				selected_details: selected_details,
				output_format: output_format,
				silent_mode: silent_mode,
				route: frappe.get_route_str()
			});

			let url = `/api/method/silicon_ioi.ioi_system.doctype.ioi_report.ioi_report.print_report?${params}`;
			if (!silent_mode) {
				if (!true) {
					let w = window.open(url,'_blank');
					if (!w) {
						frappe.msgprint(__('Please enable pop-ups'));
						return;
					}
				} else {
					frappe.call({
						type: "GET",
						args: {},
						async: false,
						url: url,
					}).then((r) => {

						if (r.message?.success) {
							if (r.message.doc_to_sign_info){
								// frappe.confirm(__("The printed document must be signed. Do you want to complete the process?"), () => {
								// 	window.open("/app/ioi-doc-to-sign/"+r.message.doc_to_sign_info.name)
								// });
								if (!frappe.user_roles.includes("ioi Sign Manager") && !frappe.user_roles.includes("ioi Sign Master Manager")){
									frappe.throw(__("You do not have enough permissions for create signing process. <b>\"ioi Sign Manager\"</b> or <b>\"ioi Sign Master Manager\"</b> role required."))
								}

								frappe.db.get_list("ioi Doc To Sign",{
									filters:{
										source_document_type: r.message.doc_to_sign_info.source_dt,
										source_document_name: r.message.doc_to_sign_info.source_dn
									},
									fields:["name", "sign_status", "creation"]
								}).then((v) => {
									$("#container-more-info").remove();
									let more_info = `
									<div id="container-more-info">
										<div>
											<a  onclick="$('#doc-to-sign-more-info').show()" style="color:cornflowerblue;">
											${__("More info")}
											</a>
											<div id="doc-to-sign-more-info" style="display: none;">
												<br>
												%INFO%
											</div>
										</div>
									</div>
									`;
									if (v.length > 0){
										let doc_sign_status;

										let info_content = ''
										for (var i = 0; i < v.length; i++){
											frappe.call({
												method: "silicon_ioi.ioi_core.doctype.ioi_doc_to_sign.ioi_doc_to_sign.get_status_name",
												args:{
													value:  v[i].sign_status
												},
												async: false,
												callback: (res_status) => {
													doc_sign_status = res_status.message
												}
											})
											$(`#cancel_doc_grp_${v[i].name}`).remove();

											let display_cancel = doc_sign_status == __("Signed") ? "display: none" : "";

											info_content = info_content+`<div id="cancel_doc_grp_${v[i].name}">
																			<a href="/app/ioi-doc-to-sign/${v[i].name}" target="_blank">${frappe.datetime.get_datetime_as_string(v[i].creation)} (${doc_sign_status})</a>
																		 	<a id="cancel_doc_${v[i].name}"style="color: #ff6347; ${display_cancel}" href="javascript:" >${__("Cancel")}</a>
																		 	<br>
																		</div>`
										}
										more_info = more_info.replace("%INFO%",info_content);

										frappe.confirm(__("The current document is in the process of being signed. Continue ?")+"<br><br>"+more_info, () => {
											prepare_doc_to_sign(r.message.doc_to_sign_info);
										});
										setTimeout(() => {
											for (var i = 0; i < v.length; i++){
												let name_ = v[i].name
													$(`#cancel_doc_${v[i].name}`).on("click",() => {
														if (cancel_doc_to_sign(`${name_}`)){
															$(`#cancel_doc_grp_${name_}`).remove();
														}
													})


											}
										},500);

									}else{
										prepare_doc_to_sign(r.message.doc_to_sign_info);
									}
								})

							}
							files = r.message.files;
							files.forEach((f) => {
								window.open(`/api/method/silicon_ioi.utils.lib.ioiFile.download_file?name=${f}`,'_blank');
							});
						}else{
							if (r.errors){
								frappe.throw(__("An error occurred during printing")+":<br><br>"+JSON.stringify(r.errors))
							}
						}
					});
				}
			} else {
				fetch(url).then((result) => {
					console.log(result);
					if (result.status == 200) {
						frappe.show_alert({ message: __('Email sent'), indicator: 'green' }, 5);
					} else {
						frappe.show_alert({ message: __('Failed to send email'), indicator: 'red' }, 5);
					}
				})

				if (r.message.doc_to_sign_info){
					if (!frappe.user_roles.includes("ioi Sign Manager") && !frappe.user_roles.includes("ioi Sign Master Manager")){
						frappe.throw(__("You do not have enough permissions for create signing process. <b>\"ioi Sign Manager\"</b> or <b>\"ioi Sign Master Manager\"</b> role required."))
					}
					prepare_doc_to_sign(r.message.doc_to_sign_info)
				}
			}
		}

		silicon_ioi.ioiReportMethods.showReportQuestions(report).then(async (answers) => {

			if (answers.length == 0 || answers.slice(-1)[0]['action'] != 'ABORT') {
				if (report["bulk_printing"] == 1 && report["type_view"].toUpperCase() == "DOCTYPE" && isViewList()) {

					let selected_items = cur_list.get_checked_items();
					let merged = $('#merge_checkbox')[0].checked;
					if (!merged) {
						selected_items.forEach((doc) => {
							sleep_ms(100).then(() => {
								print(report["name"], "DOCTYPE",
									report["doc_type"],
									doc["name"],
									language,
									answers,
									JSON.stringify([]),
									JSON.stringify({}),
									report["destination"] == 'EML',
									report["file_format"]
								);
							});

						});
					} else {
						print_merged_reports(report["name"], "DOCTYPE",
							report["doc_type"],
							"",
							language,
							answers,
							selected_items.map((item) => item["name"]),
							JSON.stringify({}),
							report["destination"] == 'EML'
						);
					}
				} else {
					let selected_items = isViewList() ? (cur_list.get_checked_items() || []) : [];
					selected_items = selected_items.map(elem => elem.name);
					let selected_details = {};
					if (isViewDocType()) {

						cur_frm.fields.forEach((f) => {
							if (f.grid) {
								selected_details[f.df.fieldname] = f.grid.get_selected();
							}
						});
					}

					if (report["destination"] == 'EML' && report["review_email_before_sending"]) {
						report.doctype = report.doc_type;
						report.doc_name = (isViewDocType() ? cur_frm.doc.name : '');
						await edit_email_before_send(report, language, answers);
					} else {
						print(report["name"], report["type_view"],
							report.doc_type,
							(isViewDocType() ? cur_frm.doc.name : ''),
							language,
							answers,
							JSON.stringify(selected_items),
							JSON.stringify(selected_details),
							report["destination"] == 'EML',
							report["file_format"]
						);
					}
				}
			}
		});
	}

	function default_print_doc_from_doctype() {
		frappe.route_options = {
			frm: this,
		};
		frappe.set_route("print", cur_frm.doctype, cur_frm.doc.name);
	}

	if ($('#merge_checkbox_group')) {
		$('#merge_checkbox_group').remove()
	}

	if ($('#merge_checkbox')) {
		$('#merge_checkbox').remove()
	}

	const dialog = new frappe.ui.Dialog({
		title: report_center ? __("Report Center") : __("Generate document"),
		fields: [
			{
				fieldtype: "HTML",
				fieldname: "source_dt_html"
			},
			{
				fieldtype: "Select",
				label: __("Family"),
				fieldname: "family",
				options: get_report_family_list(),
				onchange: function (e) {
					let typeList = refresh_type_report(this.value);
					cur_dialog.get_field("group").df.hidden = true;
					cur_dialog.get_field("group").refresh();

					cur_dialog.get_field("type").df.options = typeList;
					//cur_dialog.get_field("sub_group").set_options();

					cur_dialog.get_field("type").df.hidden = (typeList.length == 0);
					cur_dialog.get_field("type").refresh();

					if (typeList.length > 0) {
						cur_dialog.get_field("type").set_value(cur_dialog.get_field("type").df.options[0]);
					}
					refresh_optional_controls();
				}
			},
			{
				fieldtype: "Select",
				label: __("Type"),
				fieldname: "type",
				hidden: true,
				onchange: function (e) {
					let groupList = refresh_group_report(cur_dialog.get_field("family").value, this.value);
					cur_dialog.get_field("group").df.options = groupList;


					cur_dialog.get_field("group").df.hidden = (groupList.length == 0);
					cur_dialog.get_field("group").refresh();

					if (groupList.length > 0) {
						cur_dialog.get_field("group").set_value(cur_dialog.get_field("group").df.options[0]);
					}
					refresh_optional_controls();
				},
			},
			{
				fieldtype: "Select",
				label: __("Group"),
				fieldname: "group",
				hidden: true,
				onchange: function (e) {
					refresh_optional_controls();
				}
			},
			{
				fieldtype: "Select",
				label: __("Report"),
				fieldname: "report_name",
				hidden: true,
				onchange: function (e) {
					refresh_optional_controls(true);
				}
			},
			{
				fieldtype: "Select",
				label: __("Language"),
				fieldname: "language",
				options: [{
					label: __("Select Language"),
					value: __("Select Language"),
					disabled: true
				},
				{
					label: 'Deutsch',
					value: 'de'
				},
				{
					label: 'English',
					value: 'en'
				},
				{
					label: 'Español',
					value: 'es'
				},
				{
					label: 'Français',
					value: 'fr'
				},
				{
					label: 'Nederlands',
					value: 'nl'
				},

				],
				default: __("Select Language"),
			},

		],
		on_page_show: () => {
			cur_dialog.get_field("family").set_value(cur_dialog.get_field("family").df.options[0]);
			cur_dialog.get_field("language").set_value(isViewDocType() ? cur_frm.doc.language || cur_frm.doc.custom_language || frappe.boot.lang : frappe.boot.lang);
			refresh_optional_controls();
			reload_last_report();
		},

	});
	let merge_checkbox = `
	<div class="form-check hidden" id="merge_checkbox_group">
		<input class="form-check-input" type="checkbox" value="" id="merge_checkbox" checked="">
		<label class="form-check-label" for="merge_checkbox">
		${__('Merge reports')}
		</label>
	</div>
	`;

	dialog.footer[0].insertAdjacentHTML("afterbegin", merge_checkbox);

	dialog.set_primary_action(__("Generate"), (args) => {

		let family = cur_dialog.get_field('family').value;
		let type = cur_dialog.get_field('type').df.hidden ? '' : cur_dialog.get_field('type').value;
		let group = cur_dialog.get_field('group').df.hidden || cur_dialog.get_field('type').df.hidden ? "" : cur_dialog.get_field('group').value;
		let selected_report = get_selected_report(family, type, group);

		if (selected_report && cur_dialog.get_field("language").value != __("Select Language")) {
			printReportBroReport(selected_report, cur_dialog.get_field("language").value);
			cur_dialog.hide();
		} else {
			frappe.msgprint(__("Please select a report option(s)"));
		}

	});

	dialog.set_secondary_action_label(__("Export to xlsx"));
	dialog.set_secondary_action(() => {
		let family = cur_dialog.get_field('family').value;
		let type = cur_dialog.get_field('type').df.hidden ? '' : cur_dialog.get_field('type').value;
		let group = cur_dialog.get_field('group').df.hidden || cur_dialog.get_field('type').df.hidden ? "" : cur_dialog.get_field('group').value;
		let selected_report = get_selected_report(family, type, group);

		if (selected_report && cur_dialog.get_field("language").value != __("Select Language")) {
			selected_report.file_format = 'XLSX';

			printReportBroReport(selected_report, cur_dialog.get_field("language").value);
			cur_dialog.hide();
		} else {
			frappe.msgprint(__("Please select a report option(s)"));
		}
	});
	dialog.get_secondary_btn().hide();
	dialog.get_secondary_btn().removeClass("btn-secondary");
	dialog.get_secondary_btn().addClass("btn-info")

	// frappe.db.get_list("Print Format", {
	// 	filters: {
	// 		doc_type: (isViewDocType() && cur_frm?.doctype) || cur_list?.doctype,
	// 		disabled: 0
	// 	}
	// })


	//// Not necessary at the moment
	//
	// .then(stdReports => {
	// 	if ((stdReports && stdReports.length > 0) || (isViewDocType() && cur_frm.doc)) {


	// 		dialog.set_secondary_action_label(__("Default dialog"));
	// 		dialog.set_secondary_action(() => {

	// 			cur_dialog.hide();
	// 			if (window["std_dialog_func"] === "doctype") {
	// 				frappe.route_options = {
	// 					frm: cur_frm,
	// 				};
	// 				frappe.set_route("print", cur_frm.doctype, cur_frm.doc.name);
	// 			} else {
	// 				window["std_dialog_func"]();
	// 			}
	// 		});
	// 	}
	// });

	dialog.show();
}
