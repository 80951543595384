import Grid from 'frappe/public/js/frappe/form/grid';
import ioiGridRow from './ioi_grid_row';

export default class ioiGrid extends Grid {
	make_head() {
		if (this.prevent_build) return;

		// labels
		if (this.header_row) {
			$(this.parent).find(".grid-heading-row .grid-row").remove();
		}
		this.header_row = new ioiGridRow({
			parent: $(this.parent).find(".grid-heading-row"),
			parent_df: this.df,
			docfields: this.docfields,
			frm: this.frm,
			grid: this,
			configure_columns: true
		});

		this.header_search = new ioiGridRow({
			parent: $(this.parent).find(".grid-heading-row"),
			parent_df: this.df,
			docfields: this.docfields,
			frm: this.frm,
			grid: this,
			show_search: true
		});

		this.header_search.row.addClass("filter-row");
		if (this.header_search.show_search || this.header_search.show_search_row()) {
			$(this.parent).find(".grid-heading-row").addClass("with-filter");
		} else {
			$(this.parent).find(".grid-heading-row").removeClass("with-filter");
		}

		this.filter_applied && this.update_search_columns();
	}

	refresh() {
		this.profiles_loaded = false

		super.refresh()

		if (this.frm) {
			this.selected_profile = frappe.model.user_settings[this.frm.doctype]["GridViewProfile"];
			this.add_profile_selector().then(() => {
				if (!this.profile_list[this.selected_profile]) {
					this.selected_profile = 'DEFAULT';
				}
			});

			const parent = $(this.parent)

			this.check_row_dbl_data(parent)
			this.check_text_on_one_line(parent)
		}
	}

	check_row_dbl_data(parent) {
		const heading_row = parent.find('.grid-heading-row')
		const grid_body = parent.find('.grid-body')
		
		if (this.expand_columns_width == 1) {
			heading_row.addClass('row-dbl-title')
			grid_body.addClass('row-dbl-data')
		} else {
			heading_row.removeClass('row-dbl-title')
			grid_body.removeClass('row-dbl-data')
		}
	}

	check_text_on_one_line(parent) {
		let is_text_on_one_line = 0

		if (this.profiles_loaded == false) {
			if (
				this.frm &&
				frappe.model.user_settings[this.frm.doctype] && 
				frappe.model.user_settings[this.frm.doctype].GridView && 
				frappe.model.user_settings[this.frm.doctype].GridView[this.doctype]
				) {
					is_text_on_one_line = frappe.model.user_settings[this.frm.doctype].GridView[this.doctype][0].keep_text_on_one_line
			}
		} else {
			is_text_on_one_line = this.keep_text_on_one_line
		}

		const static_area_rows = parent.find(".rows .static-area")
		const static_area_header = parent.find(".grid-heading-row .static-area")

		if (is_text_on_one_line != 0 && is_text_on_one_line != 1) is_text_on_one_line = 0

		if (!is_text_on_one_line) {

			static_area_rows.removeClass('ellipsis')
			static_area_header.removeClass('ellipsis')
			
			// static_area_rows.css({"display": "-webkit-box", "-webkit-box-orient": "vertical", "-webkit-line-clamp": "2", "overflow": "hidden"})

			static_area_rows.each((i, el) => {
				$(el).closest('.grid-row').find('.col').css('height', 'inherit')
			})

			$(static_area_header[0]).closest('.grid-heading-row').css('height', 'inherit')

			static_area_header.each((i, el) => {
				$(el).closest('.grid-row').find('.col').css('height', 'inherit')
			})
		} else {
			if (!static_area_rows.hasClass('ellipsis')) static_area_rows.addClass('ellipsis')
			if (!static_area_header.hasClass('ellipsis')) static_area_header.addClass('ellipsis')
			
			// static_area_rows.css({"display": "", "-webkit-box-orient": "", "-webkit-line-clamp": "", "overflow": ""})

			static_area_rows.each((i, el) => {
				$(el).closest('.grid-row').find('.col').css('height', '38px')
			})

			$(static_area_header[0]).closest('.grid-heading-row').css('height', '32px')

			static_area_header.each((i, el) => {
				$(el).closest('.grid-row').find('.col').css('height', '32px')
			})
		}
	}

	render_result_rows($rows, append_row) {
		super.render_result_rows($rows, append_row);

		// pagination event (page has changed)
		if (append_row) {
			this.wrapper.trigger('render_result_rows_append_row');
		} else {
			this.wrapper.trigger('render_result_rows');
		}
	}

	async add_profile_selector() {
		await this.get_profiles().then(() => {
			return this.add_profile_selector_btn();
		})
	}

	get_profiles() {
		let me = this;
		return frappe.db.get_list("ioi GridRow Profile", {
			filters: [
				["ioi GridRow Profile", "doc_type", "=", this.frm.doctype],
				["ioi GridRow Profile", "field_name", "=", this.df.fieldname],
				["ioi GridRow Profile Roles", "role", "in", ["", ...frappe.user_roles]]
			],
			or_filters: [
				["ioi GridRow Profile", "user_name", "=", frappe.session.user],
				["ioi GridRow Profile", "is_public_access", "=", 1],
				["ioi GridRow Profile", "is_standard_access", "=", 1]
			],
			fields: ["name", "profile_name", "user_name", "content", 'expand_columns_width', 'keep_text_on_one_line', 'is_public_access', 'is_standard_access'],
			distinct: 1
		}).then(r => {
			this.profile_list = r.reduce(
				(obj, value) => ({
					...obj, [value.name]: value
				}), {});

			Object.keys(this.profile_list).forEach((key) => {
				me.profile_list[key].content = JSON.parse(me.profile_list[key].content)
			});

			this.profiles_loaded = true
		})
	}

	setup_visible_columns() {
		if (this.visible_columns && this.visible_columns.length > 0) return;

		let expand = 0

		if (this.profiles_loaded == false) {
			if (
				this.frm &&
				frappe.model.user_settings[this.frm.doctype] && 
				frappe.model.user_settings[this.frm.doctype].GridView && 
				frappe.model.user_settings[this.frm.doctype].GridView[this.doctype]
				) {
					let cols = 0
					frappe.model.user_settings[this.frm.doctype].GridView[this.doctype].map(el => cols += el.columns)

					if (cols > 10) expand = 1
			}
		} else {
			expand = this.expand_columns_width
		}

		this.user_defined_columns = [];
		this.setup_user_defined_columns();

		var total_colsize = 1,
			fields =
				this.user_defined_columns && this.user_defined_columns.length > 0
					? this.user_defined_columns
					: this.editable_fields || this.docfields;

		this.visible_columns = [];

		for (var ci in fields) {
			var _df = fields[ci];

			// get docfield if from fieldname
			df =
				this.user_defined_columns && this.user_defined_columns.length > 0
					? _df
					: this.fields_map[_df.fieldname];

			if (
				df &&
				!df.hidden &&
				(this.editable_fields || df.in_list_view) &&
				((this.frm && this.frm.get_perm(df.permlevel, "read")) || !this.frm) &&
				!frappe.model.layout_fields.includes(df.fieldtype)
			) {
				if (df.columns) {
					df.colsize = df.columns;
				} else {
					this.update_default_colsize(df);
				}

				// attach formatter on refresh
				if (
					df.fieldtype == "Link" &&
					!df.formatter &&
					df.parent &&
					frappe.meta.docfield_map[df.parent]
				) {
					const docfield = frappe.meta.docfield_map[df.parent][df.fieldname];
					if (docfield && docfield.formatter) {
						df.formatter = docfield.formatter;
					}
				}

				total_colsize += df.colsize;
				if (total_colsize > (expand == 1 ? 23 : 11)) {
					return false;
				};
				this.visible_columns.push([df, df.colsize]);
			}
		}

		// redistribute if total-col size is less than 24 or 12
		var passes = 0;
		while (total_colsize < (expand == 1 ? 23 : 11) && passes < (expand == 1 ? 24 : 12)) {
			for (var i in this.visible_columns) {
				var df = this.visible_columns[i][0];
				var colsize = this.visible_columns[i][1];

				if (colsize > 1 && colsize < (expand == 1 ? 24 : 12) && frappe.model.is_non_std_field(df.fieldname)) {
					if (
						passes < 3 &&
						["Int", "Currency", "Float", "Check", "Percent"].indexOf(df.fieldtype) !== -1
					) {
						// don't increase col size of these fields in first 3 passes
						continue;
					}

					this.visible_columns[i][1] += 1;
					total_colsize += this.visible_columns[i][1];
				}

				if (total_colsize > (expand == 1 ? 22 : 10)) break;
			}
			passes++;
		}
	}

	add_profile_selector_btn() {
		this.profile_selector && this.profile_selector.empty()
		this.profile_selector = null;

		const me = this;
		const id_default_profile_grid = Date.now() + '_default_profile';

		const filtered_profiles = this.profile_list;//this.profiles_names;//.filter(p => p !== this.selected_profile);

		if (!this.profile_selector && Object.keys(filtered_profiles).length) { //
			// const dropdown_menu_items = filtered_profiles.map(p => {
			// 	if (p == me.selected_profile) {
			// 		return `<button class="dropdown-item" type="button" data-value=${encodeURIComponent(p)}><b>${this.profile_list[p].profile_name}</b></button>`
			// 	} else {
			// 		return `<button class="dropdown-item" type="button" data-value=${encodeURIComponent(p)}>${this.profile_list[p].profile_name}</button>`
			// 	}
			// }).join("") +
			let dropdown_menu_items = '';

			Object.keys(this.profile_list).forEach((key) => {
				//const is_public = me.profile_list[key].is_public_access == '1' ? ` (${__('Public')})` : '';
				const is_public = me.profile_list[key].is_public_access == '1' && me.profile_list[key].user_name != frappe.session.user ? `
						<svg class="icon icon-sm" style="">
							<use class="" href="#icon-share-people"></use>
						</svg>`: '';

				if (key == me.selected_profile) {
					dropdown_menu_items += `<button class="dropdown-item" type="button" data-value=${encodeURIComponent(key)}><b>${me.profile_list[key].profile_name}${is_public}</b></button>`
				} else {
					dropdown_menu_items += `<button class="dropdown-item" type="button" data-value=${encodeURIComponent(key)}>${me.profile_list[key].profile_name}${is_public}</button>`
				}
			});

			dropdown_menu_items +=
				`
				<div class="dropdown-divider"></div>
				<button id="${id_default_profile_grid}" class="dropdown-item" type="button" data-value="DEFAULT">
					${me.selected_profile == 'DEFAULT' ? '<b>' + __("Default") + '</b>' : __('Default')}
				</button>`
			//cur_frm.fields_dict.bank_detail.grid.reset_grid()
			let grid_drop_down_id = 'grid-drop-down' + '-' + this.df.fieldname;

			if ($('#' + grid_drop_down_id).length > 0) {
				($('#' + grid_drop_down_id)).remove();
			}

			this.profile_selector = $(`
				<div class="dropdown">
					<div id="${grid_drop_down_id}" class="btn-xs dropdown-toggle" data-toggle="dropdown" aria-expanded="true"></div>
					<div class="dropdown-menu dropdown-menu-right">
						${dropdown_menu_items}
					</div>
				</div>
			`);




			this.profile_selector.insertAfter(me.header_row.configure_columns_button.find('a')[0]);

			me.header_row.configure_columns_button.unbind();
			me.header_row.configure_columns_button.find('a').off("click");
			me.header_row.configure_columns_button.find('a').on("click", function () {
				me.header_row.configure_dialog_for_columns_selector();
			});

			if (me.df.read_only == '1') {
				//this.profile_selector.find('#'+grid_drop_down_id).off('click');
				this.profile_selector.find('#' + grid_drop_down_id).on("click", function (e) {
					e.preventDefault();
					e.stopPropagation();
					$('#' + grid_drop_down_id).dropdown();
				});
				this.profile_selector.find('#' + grid_drop_down_id).click();
			}

			$(document.getElementById(id_default_profile_grid)).on('click', function () {
				me.selected_profile = 'DEFAULT';
				frappe.model.user_settings.save(cur_frm.doctype, 'GridView', null).then((r) => {
					frappe.model.user_settings[cur_frm.doctype] = r.message || r;
					frappe.model.user_settings[cur_frm.doctype].GridViewProfile = "DEFAULT";
					me.reset_grid();

					frappe.model.user_settings.save(cur_frm.doctype, 'GridViewProfile', me.selected_profile)
						.then(() => {
							me.header_row.save_user_settings_for_gridview()
						})
				});
			});

			this.profile_selector.find('.dropdown-item').on('click', function () {
				me.selected_profile = decodeURIComponent(this.attributes.getNamedItem("data-value").value);
				if (me.selected_profile != 'DEFAULT') {
					me.header_row.selected_columns_for_grid = me.profile_list[me.selected_profile].content.map(el => ({
						...el,
						expand_columns_width : me.profile_list[me.selected_profile].expand_columns_width,
						keep_text_on_one_line: me.profile_list[me.selected_profile].keep_text_on_one_line
					}));
				} else {
					me.header_row.selected_columns_for_grid = null;
				}

				me.header_row.columns = {};
				me.header_row.update_user_settings_for_grid();
				me.refresh_profile_selector_btn()
			});
		}
	}

	refresh_profile_selector_btn() {
		this.profile_selector && this.profile_selector.empty()
		this.profile_selector = null;
		this.add_profile_selector_btn()
	}

	// VVR:
	set_focus_on_row(idx) {
		if (!idx && idx !== 0) {
			idx = this.grid_rows.length - 1;
		}

		setTimeout(() => {
			this.grid_rows[idx].row
				.find('input[type="Text"],textarea,select')
				.filter(":visible:first")

				// VVR:
				.focus().select(); //.focus();
		}, 100);
	}
}
