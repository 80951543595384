frappe.provide('silicon_ioi.doctype');

export class ioiEPosDisplayDevice
{
	ip_address = '';
	port = 0;
	ePosDev = null;
	display = null;
	callback = null


	constructor(ip_address, port, callback = null)
	{
		this.ip_address = ip_address;
		this.port = port;
		this.callback = callback;

		// this.ePosDev = new ioi.epson.ePOSDevice();

		// Check if the connection is already established
		if (silicon_ioi.doctype.ioiEPosConnect.is_connected('display', this.ip_address, this.port)){
			this.display = silicon_ioi.doctype.ioiEPosConnect.get_epos_device('display', this.ip_address, this.port)
			this.callback(this)
		}else{
			this.ePosDev = new ioi.epson.ePOSDevice();
			// this.ePosDev.disconnect();
			this.connect();
		}

		// this.connect();
	}



	connect()
	{
		let me = this;

		let cbCreateDevice_display = function(devobj, retcode) {

			if (retcode == 'OK') {
				me.display = devobj;
				silicon_ioi.doctype.ioiEPosConnect.add_connection('display', me.ip_address, me.port, me.display)

				if (me.callback) {
					me.callback(me);
				}

			}else{
				console.log(retcode)
				me.ePosDev.disconnect();
				me.printer = null;
				me.display = null;
				me.ePosDev = null;

				// No message for display
				// frappe.msgprint({ title: __("Message"), message: retcode, indicator: "red" });
			}
		}

		let cbConnect = function (data) {

			if((data == 'OK') || (data == 'SSL_CONNECT_OK')) {
				me.ePosDev.createDevice('local_display', me.ePosDev.DEVICE_TYPE_DISPLAY, {'crypto' : false, 'buffer' : false}, cbCreateDevice_display);
			} else {
				// No message for display
				// frappe.msgprint({ title: __("Message"), message: __("Display connection error : check ip address, port, ..."), indicator: "red" });
			}
		};

		me.ePosDev.connect(me.ip_address, me.port, cbConnect);


	}
}

silicon_ioi.doctype.ioiEPosDisplayDevice = ioiEPosDisplayDevice;
