frappe.provide('silicon-ioi');

class ADynamicLink extends frappe.ui.form.ControlDynamicLink {

	// VVR:
	// the whole method is copied from "frappe.ui.form.ControlLink" and reintroduced here (without any changes)
	// because "frappe.ui.form.ControlDynamicLink" doesn't inherit from the changes made to "frappe.ui.form.ControlLink" in "ioi_link.js" !

	make_input() {
		var me = this;
		$(`<div class="link-field ui-front" style="position: relative;">
			<input type="text" class="input-with-feedback form-control">
			<span class="link-btn">
				<a class="btn-open no-decoration" title="${__("Open Link")}">
					${frappe.utils.icon("arrow-right", "xs")}
				</a>
			</span>
		</div>`).prependTo(this.input_area);
		this.$input_area = $(this.input_area);
		this.$input = this.$input_area.find("input");
		this.$link = this.$input_area.find(".link-btn");
		this.$link_open = this.$link.find(".btn-open");
		this.set_input_attributes();
		this.$input.on("focus", function () {
			setTimeout(function () {
				if (me.$input.val() && me.get_options()) {
					let doctype = me.get_options();
					let name = me.get_input_value();
					me.$link.toggle(true);
					me.$link_open.attr("href", frappe.utils.get_form_link(doctype, name));


					// VVR:
					me.$link_open.attr('tabindex', '-1');


				}

				if (!me.$input.val()) {
					me.$input.val("").trigger("input");

					// hide link arrow to doctype if none is set
					me.$link.toggle(false);
				}
			}, 500);
		});
		this.$input.on("blur", function () {
			// if this disappears immediately, the user's click
			// does not register, hence timeout
			setTimeout(function () {
				me.$link.toggle(false);
			}, 500);
		});
		this.$input.attr("data-target", this.df.options);
		this.input = this.$input.get(0);
		this.has_input = true;
		this.translate_values = true;
		this.setup_buttons();
		this.setup_awesomeplete();
		this.bind_change_event();
	}

	get_status(explain) {
		let r = super.get_status(explain);

		// VVR: revert null & read-only field as visible
		if (r === "None" && !cint(this.df.hidden) && !cint(this.df.hidden_due_to_dependency)) {
			r = "Read";
		}

		return r;
	}

};

// overriding frappe class
frappe.ui.form.ControlDynamicLink = ADynamicLink;
