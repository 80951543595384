frappe.provide("metabase")

metabase.dashboard = class ioiMetabaseDashboard {
	constructor(page, wrapper, doctype) {

		this.currentDashboard = false;
		this.page = page;
		this.wrapper = wrapper;
		this.doctype = doctype;
		this.pageMain = $(page.main);
		this.pageTitle = $(this.wrapper).find('.title-text');
		this.filters = [
			["ioi Metabase Dashboard", "is_active", "=", 1],
		]

		if (this.doctype) {
			this.filters.push(
				["ioi Metabase Linked DocTypes", "doc_type", "=", this.doctype]
			)
		}

		frappe.db.get_list("ioi Metabase Dashboard", { filters: this.filters }).then(r => {
			this.default_dashboard = r.length >= 1 ? r[0].name : null;
			this.createButtons();
			let routes = frappe.get_route();
			if (routes.length == 2) {
				this.select_dashboard(routes[1])
			} else {
				if (routes[0] == 'List' && routes.length == 3 && routes[2] == "Metabase") {
					$('[href="#icon-printer"]').parent().parent().hide();
					this.createSelectDashboards(r.map((x) => x.name));
				}
				// if (this.default_dashboard) {
				// 	this.select_dashboard(r[0].name)
				// }
			}
		})
	}

	showIframe() {
		this.getSettings().then(
			(r) => {
				// set variable
				this.settings = r.message;
				this.resizer = this.settings.resizer;
				this.iframeUrl = this.settings.iframeUrl;
				this.name = this.settings.name;

				if (this.iframeUrl && this.resizer) {
					// prepare html
					const iFrameHtml = `
						<script id="resizer" src="${this.resizer}"></script>
						<iframe
							src="${this.iframeUrl}"
							frameborder="0"
							width=100%
							onload="iFrameResize({}, this)"
							allowtransparency
						></iframe>
					`;

					// append html to page
					this.iFrame = $(iFrameHtml).appendTo(this.pageMain);
				}
			}
		);
	}

	getSettings() {
		return frappe.call({
			'method': 'silicon_ioi.ioi_core.doctype.ioi_metabase_dashboard.get_dashboard_url',
			'args': {
				'dashboard': this.dashboardName,
			},
		});
	}

	select_dashboard(name) {
		const dashboardName = name;
		if (dashboardName) {
			this.dashboardName = dashboardName;
			if (this.currentDashboard != this.dashboardName) {
				// clear page html
				this.pageMain.empty();

				this.showIframe();
				this.changeTitle();

				// set current dashboard
				this.currentDashboard = this.dashboardName;
			}
			// clear input
			// this.selectionField.set_input('');
		}
	}

	createSelectDashboards(dashboards) {
		let me = this;
		$("#select_dashboards_group").remove();
		let select_dashboards = `
			<div id="select_dashboards_group" class="flex" style="margin-bottom:5px">
				<p style="position: relative; top:5px"><b>${__('Dashboards')} :</b></p>
				<select id='select_dashboards' style="margin-left:5px; width:350px" type="text" autocomplete="off" class="input-with-feedback form-control ellipsis" maxlength="140">
					%OPTIONS%
				</select>
			</div>
		`
		let options = '';
		for (var i = 0; i < dashboards.length; i++) {
			options = options + `<option>${dashboards[i]}</option>`
		}
		select_dashboards = select_dashboards.replace('%OPTIONS%', options);
		$('.page-content').last().children()[0].insertAdjacentHTML('beforebegin', select_dashboards);

		document.getElementById('select_dashboards').addEventListener('change', function(){
		 	me.select_dashboard(document.getElementById('select_dashboards').value)
		});

		if (dashboards.length > 0) {
			document.getElementById('select_dashboards').value = dashboards[0];
			this.select_dashboard(document.getElementById('select_dashboards').value)
		}

		// $("[data-fieldname=select_dashboards_label]").remove();
		// this.select_dashboards_label = frappe.ui.form.make_control({
		// 	parent: $('.page-content'),
		// 	df: {
		// 		fieldname: 'select_dashboards_label',
		// 		fieldtype: 'Heading',
		// 		label: __('Dashboards') + ' :'
		// 	},
		// 	render_input: true,
		// });
		// this.select_dashboards_label.$wrapper.css('padding-top', '15px');

		// $("[data-fieldname=select_dashboards]").remove();
		// this.select_dashboards = frappe.ui.form.make_control({
		// 	parent: $('.page-content'),
		// 	df: {
		// 		fieldname: 'select_dashboards',
		// 		fieldtype: 'Select',
		// 		options: dashboards,
		// 		onchange: () => {
		// 			this.select_dashboard(this.select_dashboards.get_value())
		// 		}
		// 	},
		// 	render_input: true,
		// });
		// this.select_dashboards.$wrapper.css("width", "300px");
		// this.select_dashboards.$wrapper.css("margin-left", "5px");

		// if (dashboards.length > 0) {
		// 	this.select_dashboards.set_value(dashboards[0])
		// }
	}

	createButtons() {
		$("[data-fieldname=dashboards_list]").remove();
		$("[data-fieldname=dashboard_settings]").remove()

		this.btn_dashboard_list = frappe.ui.form.make_control({
			parent: this.page.custom_actions,
			df: {
				fieldname: 'dashboards_list',
				fieldtype: 'Button',
				label: __("Go to dashboard list"),
				click: () => {
					window.open('/app/ioi-metabase-dashboard', '_self');
				}
			},
			render_input: true,
		});

		$(this.btn_dashboard_list)
			.find(".frappe-control")
			.map((index, button) => {
				$(button).css({ "margin-top": "1em" });
			});

		this.btn_dashboard_settings = frappe.ui.form.make_control({
			parent: this.page.custom_actions,
			df: {
				fieldname: 'dashboard_settings',
				fieldtype: 'Button',
				label: __("Settings"),
				click: () => {
					window.open('/app/ioi-metabase-dashboard/' + this.dashboardName, '_self');
				}
			},
			render_input: true,
		});

		$("[data-fieldname=dashboards_list]").find('div').removeClass('form-group')
		$("[data-fieldname=dashboards_list]").find('p').remove()

		$("[data-fieldname=dashboard_settings]").find('div').removeClass('form-group')
		$("[data-fieldname=dashboard_settings]").find('p').remove()

		this.page.custom_actions.removeClass("hide");
	}

	changeTitle() {
		this.pageTitle.text(`${this.dashboardName} Dashboard`);
	}
}
