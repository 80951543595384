
	frappe.templates['ioi_todo_popup'] = `<article class="evp-root">
	<div class="evp-toolbar">
		<a data-action="edit" role="button" type="button" class="btn-reset evp-btn">
			{{ frappe.utils.icon("edit", "md") }}
		</a>
		<button data-action="delete" type="button" class="btn-reset evp-btn">
			{{ frappe.utils.icon("delete", "md") }}
		</button>
		<div></div>
		<button data-action="close" type="button" class="btn-reset evp-btn">
			{{ frappe.utils.icon("close", "md") }}
		</button>
	</div>
	<div class="evp-scroller">
		<div class="evp-row">
			<div>
				<div class="evp-swatch" data-key="swatch"></div>
			</div>
			<div>
				<div data-key="subject" role="heading" aria-level="1"></div>
				<div data-key="timestamp"></div>
			</div>
		</div>
		<div style="height: 1em;"></div>
		<div class="evp-row" hidden>
			<div>
				{{ frappe.utils.icon("list-alt", "md") }}
			</div>
			<div>
				<div data-key="description"></div>
			</div>
		</div>
		<div class="evp-row" hidden>
			<div>
				{{ frappe.utils.icon("attachment", "md") }}
			</div>
			<div>
				<div data-key="links"></div>
			</div>
		</div>
		<template hidden data-key="fields"></template>
	</div>
</article>
`;
