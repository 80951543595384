

frappe.provide('silicon_ioi');

import {Html5QrcodeScanner} from "html5-qrcode";

export class ioiScanning {

	static command = [];
	static command_list_form = [];
	static command_form = [];
	static command_form_detail = [];
	static open_window = [];
	static command_value = [];
	static prefix_detection = [];


	static container = "";
	static dc = '';

	static identification = '';
	static module_initials = '';

	static field_detail1 = '';
	static field_detail2 = '';
	static field_detail3 = '';
	static field_detail4 = '';
	static field_detail5 = '';
	static field_detail6 = '';

	static camscan()
	{
		if (document.getElementsByClassName('frappe-control')) {

			for (var i = document.getElementsByClassName('frappe-control').length-1; i >= 0; i--)
			{
				if (document.getElementsByClassName('frappe-control')[i].attributes) {

					if (document.getElementsByClassName('frappe-control')[i].attributes.getNamedItem('data-fieldname')) {

						if (document.getElementsByClassName('frappe-control')[i].attributes.getNamedItem('data-fieldname').value == 'html_cam_scan') {
							document.getElementsByClassName('frappe-control')[i].remove();
						}
					}
				}
			}
		}


		var cam_window = new frappe.ui.Dialog({
			'title': __("Scan ..."),
			'fields': [
				{'fieldname': 'html_cam_scan', 'fieldtype': 'HTML'}

			],
			primary_action_label: __('Cancel'),

			primary_action: function(){


				cam_window.hide();

				document.getElementsByClassName('frappe-control')[document.getElementsByClassName('frappe-control').length-1].remove();
			}
		});

		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:400px; width: 100%;">';

		html += '	<div id="sb_main_cam_reader" width="600px" height="400px"></div>';

		html += '</div>'

		cam_window.fields_dict.html_cam_scan.$wrapper.html(html);


		if (window.innerWidth <= 500) {
			cam_window.$wrapper.find('.modal-dialog').css("max-width", "100%").css("width", "100%");
			cam_window.$wrapper.find('.modal-dialog').css("max-height", "100%").css("height", "100%");
		}else
		{
			cam_window.$wrapper.find('.modal-dialog').css("max-width", "50%").css("width", "50%");
			cam_window.$wrapper.find('.modal-dialog').css("max-height", "100%").css("height", "100%");
		}

		cam_window.show();


		silicon_ioi.ioiScanning.sleep_static(1000).then(() => {

			var html5QrcodeScanner = new silicon_ioi.ioiScanning.Html5QrcodeScanner("sb_main_cam_reader", { fps: 10, qrbox: 250 });

			function onScanSuccess(decodedText, decodedResult) {

				document.getElementById('sb_main_scan_input').value = decodedText;

				silicon_ioi.ioiScanning.decode_scan(document.getElementById('sb_main_scan_input'));
				document.getElementById('sb_main_scan_input').value = '';
				silicon_ioi.ioiScanning.setfocus();

				cam_window.hide();
			}

			html5QrcodeScanner.render(onScanSuccess);


			if (document.getElementById('html5-qrcode-anchor-scan-type-change')) {
				document.getElementById('html5-qrcode-anchor-scan-type-change').hidden = true;
			}
		});

	}


	static do_scan(event) {

		if (event.keyCode == 13) {
			if ((this.value) && (this.value.trim() != '')) {
				silicon_ioi.ioiScanning.decode_scan(this);
				silicon_ioi.ioiScanning.setfocus();

				this.value = '';
				this.focus();
			}
		}
	}

	static decode_scan(obj) {

		if (document.getElementById('sb_main_scan_check')) {
			if (frappe.is_mobile()){
				document.getElementById('sb_main_scan_check').checked = false;
			}else{
				document.getElementById('sb_main_scan_check').checked = true;
			}

		}

		if (document.getElementById('sb_main_scan_msg')) {
			document.getElementById('sb_main_scan_msg').innerHTML = '';
		}


		let v = obj.value;

		if ((v) && (v.trim() == '')) {
			return;
		}


		if (v.length < 2) {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Scanned value is to short") + '</font>';
			return;
		}

		v = v.toUpperCase();

		let char_allowed = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 -$%./+*?';


		for (var i = 0; i < v.length; i++)
		{
			if (char_allowed.indexOf(v[i]) == -1)
			{
				document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Invalid char in scanned value (code 39)") + '</font>';
				return
			}
		}


		silicon_ioi.ioiScanning.check_hidden_detail_row_id('sb_main_scan_detail_row_idx');
		silicon_ioi.ioiScanning.check_hidden_detail_row_id('sb_main_scan_detail2_row_idx');
		silicon_ioi.ioiScanning.check_hidden_detail_row_id('sb_main_scan_detail3_row_idx');
		silicon_ioi.ioiScanning.check_hidden_detail_row_id('sb_main_scan_detail4_row_idx');
		silicon_ioi.ioiScanning.check_hidden_detail_row_id('sb_main_scan_detail5_row_idx');
		silicon_ioi.ioiScanning.check_hidden_detail_row_id('sb_main_scan_detail6_row_idx');


		silicon_ioi.ioiScanning.load_command_help();
		silicon_ioi.ioiScanning.load_params_by_doctype();

		if (v.substring(0, 2) == '$-')
		{
			v = v.substring(1, v.length);

			if (v.trim().toUpperCase().substring(0, 2) == '-?')
			{
				// ************************************************************
				// Help
				// ************************************************************

				silicon_ioi.ioiScanning.show_help();

			}else if (v.trim().toUpperCase().substring(0, 4) == '-GO-')
			{
				// ************************************************************
				// Go to list or form
				// ************************************************************

				silicon_ioi.ioiScanning.go_to_list_form(v);

			} else if (v.trim().toUpperCase() == '-NEW') {

				// ************************************************************
				// New document
				// ************************************************************
				obj.value = '';
				obj.focus();

				silicon_ioi.ioiScanning.new_doc();

			} else if (v.trim().toUpperCase() == '-SAVE') {

				// ************************************************************
				// Save document
				// ************************************************************

				obj.value = '';
				obj.focus();

				silicon_ioi.ioiScanning.save_doc();


			} else if (v.trim().toUpperCase() == '-CANCEL') {

				// ************************************************************
				// Cancel document
				// ************************************************************

				obj.value = '';
				obj.focus();

				silicon_ioi.ioiScanning.cancel_doc();


			} else if (v.trim().toUpperCase() == '-DELETE') {

				// ************************************************************
				// Delete document
				// ************************************************************

				obj.value = '';
				obj.focus();

				silicon_ioi.ioiScanning.delete_doc();

			} else if (v.trim().toUpperCase() == '-DUPLICATE') {

				// ************************************************************
				// Duplicate document
				// ************************************************************

				obj.value = '';
				obj.focus();

				silicon_ioi.ioiScanning.duplicate_doc();
			}
			else if (v.trim().toUpperCase().substring(0, 6) == '-NEWLN')
			{
				// ************************************************************
				// new line in detail
				// ************************************************************

				silicon_ioi.ioiScanning.new_line(v);

			} else if (v.trim().toUpperCase().substring(0, 6) == '-DELLN')
			{
				// ************************************************************
				// Delete line in detail
				// ************************************************************

				silicon_ioi.ioiScanning.delete_line(v);

			} else if (v.trim().toUpperCase().substring(0, 6) == '-SETLN')
			{
				// ************************************************************
				// Set focus on line in detail
				// ************************************************************

				silicon_ioi.ioiScanning.set_line(v);

			} else if (v.trim().toUpperCase().substring(0, 7) == '-MOVELN')
			{
				// ************************************************************
				// Move line in detail
				// ************************************************************

				silicon_ioi.ioiScanning.move_line(v);

			} else if (v.trim().toUpperCase().substring(0, 6) == '-DUPLN')
			{
				// ************************************************************
				// Duplicate line in detail
				// ************************************************************

				silicon_ioi.ioiScanning.duplicate_line(v);

			}else if (v.trim().toUpperCase().substring(0, 4) == '-STA') {

				// ************************************************************
				// Change status
				// ************************************************************

				obj.value = '';
				obj.focus();

				silicon_ioi.ioiScanning.change_ioistatus(v);

			} else if (v.trim().toUpperCase().substring(0, 4) == '-UST') {

				// ************************************************************
				// Change user status
				// ************************************************************

				obj.value = '';
				obj.focus();

				silicon_ioi.ioiScanning.change_user_status(v);

			} else if (v.trim().toUpperCase().substring(0, 4) == '-MFD') {

				// ************************************************************
				// set value to master field
				// ************************************************************

				silicon_ioi.ioiScanning.set_value_in_field(v);


			} else if (v.trim().toUpperCase().substring(0, 4) == '-DFD') {

				// ************************************************************
				// set value to field in detail
				// ************************************************************

				silicon_ioi.ioiScanning.set_value_in_field_detail(v);

			} else if (v.trim().toUpperCase().substring(0, 12) == '-VOUCHEROUT-') {
				
				let detail = '';
				let tiret_idx = 3;
				let idx = 0;

		
				if (silicon_ioi.ioiScanning.container.toUpperCase() != 'FORM') {
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Function not available here") + '</font>';
					return;
				}

				if (cur_frm.doctype != 'ioi Sales Delivery') {
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Function not available here") + '</font>';
					return;					
				}

		
				if (cur_frm.fields_dict['ioistatus']) {
					if (cur_frm.doc.ioistatus != 0) {
						document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document is not editable") + '</font>';
						return;
					}else{
						if (cur_frm.doctype == 'ioi Sales Delivery') {
		
							if (cur_frm.doc.locked_by_credit_control == 1) {
								can_duplicate = false;
								document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document has to be signed (credit control)") + '</font>';
								return;
							}
						}
					}
				}
		
				if (cur_frm.fields_dict['docstatus']) {
					if (cur_frm.doc.docstatus == 1) {
						document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document is not editable") + '</font>';
						return;
					}
				}

				detail = silicon_ioi.ioiScanning.field_detail1;

	
				if (detail.trim() == '') {
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Fill detail in ioi Scan Settings") + '</font>';
					return;
				}


				let s = v.trim().toUpperCase().substring(12, v.length).trim();


				if (s.trim() == '') {
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("VOUCHEROUT : missing item and code ref") + '</font>';
					return;					
				}


				let item_id = '';
				let code_ref = '';

				if (s.indexOf('-') == -1) {
					item_id = s;
					s = ''
				}else{
					item_id = s.substring(0, s.indexOf('-'))
					s = s.substring(s.indexOf('-')+1, s.length)
				}

				if (s.trim() != '') {
					code_ref = s;
				}

				if ((!cur_frm.doc.currency_id) || ((cur_frm.doc.currency_id) && (cur_frm.doc.currency_id.trim() == ''))) {
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("'VOUCHEROUT : document currency is missing") + '</font>';
					return;								
				}

				let method = 'silicon_ioi.ioi_sales.doctype.ioi_sales_pos.ioi_sales_pos.ioi_sales_pos_scan_voucher'

				let data = {}

				frappe.call({  	
					method: method,
					args: {
						"sens": 'OUT',
						"element" : s,
						"currency_id": cur_frm.doc.currency_id,
						"currency_inv_rate": cur_frm.doc.currency_rate_inv,
						"item_id": item_id,
						"code_ref": code_ref
					},
					async: false,
					callback:function(r)	{
						data = r.message;
					}
				});

				if (data.error != 0) {
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + data.error_msg + '</font>';
					return;		

				}

				silicon_ioi.ioiScanning.new_line('-NEWLN');


				silicon_ioi.ioiScanning.sleep_static(500).then(() => {


					if (cur_frm.get_field(detail).grid) {
						if (cur_frm.get_field(detail).grid.grid_rows)
						{
							for (var i = 0; i < cur_frm.get_field(detail).grid.grid_rows.length; i++) {
			
								if (cur_frm.get_field(detail).grid.grid_rows[i].row_check[0].children[0].checked)
								{
									idx = i + 1;
			
									if (detail == silicon_ioi.ioiScanning.field_detail1) {
										document.getElementById('sb_main_scan_detail_row_idx').value = idx.toString();
									} else if (detail == silicon_ioi.ioiScanning.field_detail2) {
										document.getElementById('sb_main_scan_detail2_row_idx').value = idx.toString();
									} else if (detail == silicon_ioi.ioiScanning.field_detail3) {
										document.getElementById('sb_main_scan_detail3_row_idx').value = idx.toString();
									} else if (detail == silicon_ioi.ioiScanning.field_detail4) {
										document.getElementById('sb_main_scan_detail4_row_idx').value = idx.toString();
									} else if (detail == silicon_ioi.ioiScanning.field_detail5) {
										document.getElementById('sb_main_scan_detail5_row_idx').value = idx.toString();
									} else if (detail == silicon_ioi.ioiScanning.field_detail6) {
										document.getElementById('sb_main_scan_detail6_row_idx').value = idx.toString();
									}
			
									break;
								}
			
							}
						}
					}

					if (!cur_frm.is_dirty()) {
						cur_frm.dirty();
					}

					cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].doc['item_id'] = data.item_id;
					cur_frm.refresh_field(detail);
			
					silicon_ioi.ioiScanning.sleep_static(200).then(() => {
						let script_manager = cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].frm.script_manager;
			
						script_manager.trigger('item_id', cur_frm.fields_dict[detail].grid.doctype,	cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].doc.name);

						silicon_ioi.ioiScanning.sleep_static(200).then(() => {

							cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].doc['delivered_qty'] = 1;
							cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].doc['batch_sn_code_ref'] = data.code_ref;
							cur_frm.refresh_field(detail);	
						
							script_manager.trigger('unit_price', cur_frm.fields_dict[detail].grid.doctype,	cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].doc.name);
					
						});
					});					
				});				

			} else if (v.trim().toUpperCase().substring(0, 11) == '-VOUCHERIN-') {
				
				let detail = '';
				let tiret_idx = 3;
				let idx = 0;

		
				if (silicon_ioi.ioiScanning.container.toUpperCase() != 'FORM') {
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Function not available here") + '</font>';
					return;
				}

				if (cur_frm.doctype != 'ioi Sales Delivery') {
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Function not available here") + '</font>';
					return;					
				}

		
				if (cur_frm.fields_dict['ioistatus']) {
					if (cur_frm.doc.ioistatus != 0) {
						document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document is not editable") + '</font>';
						return;
					}else{
						if (cur_frm.doctype == 'ioi Sales Delivery') {
		
							if (cur_frm.doc.locked_by_credit_control == 1) {
								can_duplicate = false;
								document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document has to be signed (credit control)") + '</font>';
								return;
							}
						}
					}
				}
		
				if (cur_frm.fields_dict['docstatus']) {
					if (cur_frm.doc.docstatus == 1) {
						document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document is not editable") + '</font>';
						return;
					}
				}

				detail = silicon_ioi.ioiScanning.field_detail1;

	
				if (detail.trim() == '') {
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Fill detail in ioi Scan Settings") + '</font>';
					return;
				}


				let s = v.trim().toUpperCase().substring(11, v.length).trim();


				if (s.trim() == '') {
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("VOUCHERIN : missing code ref or SN") + '</font>';
					return;					
				}

				if ((!cur_frm.doc.currency_id) || ((cur_frm.doc.currency_id) && (cur_frm.doc.currency_id.trim() == ''))) {
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("VOUCHERIN : document currency is missing") + '</font>';
					return;								
				}


				let method = 'silicon_ioi.ioi_sales.doctype.ioi_sales_pos.ioi_sales_pos.ioi_sales_pos_scan_voucher'

				let data = {}

				frappe.call({  	
					method: method,
					args: {
						"sens": 'IN',
						"element" : s,
						"currency_id": cur_frm.doc.currency_id,
						"currency_inv_rate": cur_frm.doc.currency_rate_inv
					},
					async: false,
					callback:function(r)	{
						data = r.message;
					}
				});

				if (data.error != 0) {
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + data.error_msg + '</font>';
					return;							
				}

				silicon_ioi.ioiScanning.new_line('-NEWLN');


				silicon_ioi.ioiScanning.sleep_static(500).then(() => {


					if (cur_frm.get_field(detail).grid) {
						if (cur_frm.get_field(detail).grid.grid_rows)
						{
							for (var i = 0; i < cur_frm.get_field(detail).grid.grid_rows.length; i++) {
			
								if (cur_frm.get_field(detail).grid.grid_rows[i].row_check[0].children[0].checked)
								{
									idx = i + 1;
			
									if (detail == silicon_ioi.ioiScanning.field_detail1) {
										document.getElementById('sb_main_scan_detail_row_idx').value = idx.toString();
									} else if (detail == silicon_ioi.ioiScanning.field_detail2) {
										document.getElementById('sb_main_scan_detail2_row_idx').value = idx.toString();
									} else if (detail == silicon_ioi.ioiScanning.field_detail3) {
										document.getElementById('sb_main_scan_detail3_row_idx').value = idx.toString();
									} else if (detail == silicon_ioi.ioiScanning.field_detail4) {
										document.getElementById('sb_main_scan_detail4_row_idx').value = idx.toString();
									} else if (detail == silicon_ioi.ioiScanning.field_detail5) {
										document.getElementById('sb_main_scan_detail5_row_idx').value = idx.toString();
									} else if (detail == silicon_ioi.ioiScanning.field_detail6) {
										document.getElementById('sb_main_scan_detail6_row_idx').value = idx.toString();
									}
			
									break;
								}
			
							}
						}
					}

					if (!cur_frm.is_dirty()) {
						cur_frm.dirty();
					}

					cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].doc['item_id'] = data.item_id;
					cur_frm.refresh_field(detail);
			
					silicon_ioi.ioiScanning.sleep_static(200).then(() => {
						let script_manager = cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].frm.script_manager;
			
						script_manager.trigger('item_id', cur_frm.fields_dict[detail].grid.doctype,	cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].doc.name);

						silicon_ioi.ioiScanning.sleep_static(200).then(() => {

							cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].doc['delivered_qty'] = -1;
							cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].doc['warehouse_id'] = data.warehouse_id;
							cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].doc['warehouse_location_id'] = data.warehouse_location_id;
							cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].doc['batch_sn_id'] = data.batch_sn_id;
							cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].doc['batch_sn_code_ref'] = data.code_ref;
							cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].doc['batch_sn_origin'] = data.origin;
							cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].doc['unit_price'] = data.voucher_value_htva;
							cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].doc['unit_price_tvac'] = data.voucher_value_tvac;		
							cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].doc['vat_rate'] = data.voucher_vat_rate;	
							cur_frm.refresh_field(detail);	
							
							script_manager.trigger('unit_price', cur_frm.fields_dict[detail].grid.doctype,	cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].doc.name);
					
						});
					});					
				});				

			} else if (v.trim().toUpperCase().substring(0, 2) == '-W') {
				silicon_ioi.ioiScanning.warehouse_window('W');
			} else if (v.trim().toUpperCase().substring(0, 3) == '-FW') {
				silicon_ioi.ioiScanning.warehouse_window('FW');

			} else if (v.trim().toUpperCase().substring(0, 3) == '-TW') {
				silicon_ioi.ioiScanning.warehouse_window('TW');

			} else if (v.trim().toUpperCase().substring(0, 2) == '-L') {
				silicon_ioi.ioiScanning.warehouse_location_window('L');

			} else if (v.trim().toUpperCase().substring(0, 3) == '-FL') {
				silicon_ioi.ioiScanning.warehouse_location_window('FL');

			} else if (v.trim().toUpperCase().substring(0, 3) == '-TL') {
				silicon_ioi.ioiScanning.warehouse_location_window('TL');
			} else if (v.trim().toUpperCase().substring(0, 3) == '-B') {
				silicon_ioi.ioiScanning.batch_window();
			}

		} else {

			if (v[0] == '$')
			{
				// ************************************************************
				// Préfix
				// ************************************************************

				v = v.substring(1, v.length);

				let prefix = v.substring(0, 2);
				let data = v.substring(2, v.length);
				data = data.replaceAll('/', ' • ');

				if ((prefix.trim() != '') && (data.trim() != ''))
				{

					if (silicon_ioi.ioiScanning.container.toUpperCase() != 'FORM') {
						document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Function not available here") + '</font>';
						return;
					}

					if (cur_frm.fields_dict['ioistatus']) {
						if (cur_frm.doc.ioistatus != 0) {
							document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document is not editable") + '</font>';
							return;
						}else{
							if (cur_frm.doctype == 'ioi Sales Delivery') {

								if (cur_frm.doc.locked_by_credit_control == 1) {
									can_duplicate = false;
									document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document has to be signed (credit control)") + '</font>';
									return;
								}
							}
						}
					}

					if (cur_frm.fields_dict['docstatus']) {
						if (cur_frm.doc.docstatus == 1) {
							document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document is not editable") + '</font>';
							return;
						}
					}

					let amethod = 'silicon_ioi.ioi_configuration.doctype.ioi_scan_settings.ioi_scan_settings.ioi_scan_settings_check_if_prefix';

					let is_prefix = false;
					let dest_detail = '';
					let dest_field = '';

					frappe.call({
						method: amethod,
						args: { "doctype": silicon_ioi.ioiScanning.dc, "prefix": prefix, "value": data },
						async: false,
						callback: function (r) {

							if (r.message) {

								if (r.message.is_prefix == 1)
								{
									is_prefix = true;
									dest_detail = r.message.detail;
									dest_field = r.message.field;
								}
							}
						}
					});


					if ((is_prefix) && (dest_field.trim() != ''))
					{
						if (dest_detail.trim() == '')
						{
							if (!cur_frm.is_dirty()) {
								cur_frm.dirty();
							}

							dest_field = dest_field.substring(dest_field.indexOf('.')+1, dest_field.length);

							cur_frm.set_value(dest_field, data);
							cur_frm.refresh_field(dest_field);


						}else
						{
							// detail

							let detail = dest_detail;
							let idx = 0;


							if (cur_frm.get_field(detail).grid) {
								if (cur_frm.get_field(detail).grid.grid_rows)
								{
									for (var i = 0; i < cur_frm.get_field(detail).grid.grid_rows.length; i++) {

										if (cur_frm.get_field(detail).grid.grid_rows[i].row_check[0].children[0].checked)
										{
											idx = i + 1;

											if (detail == silicon_ioi.ioiScanning.field_detail1) {
												document.getElementById('sb_main_scan_detail_row_idx').value = idx.toString();
											} else if (detail == silicon_ioi.ioiScanning.field_detail2) {
												document.getElementById('sb_main_scan_detail2_row_idx').value = idx.toString();
											} else if (detail == silicon_ioi.ioiScanning.field_detail3) {
												document.getElementById('sb_main_scan_detail3_row_idx').value = idx.toString();
											} else if (detail == silicon_ioi.ioiScanning.field_detail4) {
												document.getElementById('sb_main_scan_detail4_row_idx').value = idx.toString();
											} else if (detail == silicon_ioi.ioiScanning.field_detail5) {
												document.getElementById('sb_main_scan_detail5_row_idx').value = idx.toString();
											} else if (detail == silicon_ioi.ioiScanning.field_detail6) {
												document.getElementById('sb_main_scan_detail6_row_idx').value = idx.toString();
											}

											break;
										}

									}
								}
							}

							if (idx == 0)
							{
								document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Select a detail line before") + '</font>';
								return;

							}


							if (!cur_frm.is_dirty()) {
								cur_frm.dirty();
							}

							dest_field = dest_field.substring(dest_field.indexOf('.')+1, dest_field.length);

							cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].doc[dest_field] = data;

							let script_manager = cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].frm.script_manager;

							script_manager.trigger(dest_field, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].doc.name);

							cur_frm.refresh_field(detail);

						}

					}
				}else
				{
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Invalid scanned value") + '</font>';
					return;
				}

			}else
			{
				// ************************************************************
				// Id or barcode ref ?
				// ************************************************************

				if (silicon_ioi.ioiScanning.container.toUpperCase() != 'FORM') {
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Function not available here") + '</font>';
					return;
				}

				if (cur_frm.fields_dict['ioistatus']) {
					if (cur_frm.doc.ioistatus != 0) {
						document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document is not editable") + '</font>';
						return;
					}else{
						if (cur_frm.doctype == 'ioi Sales Delivery') {

							if (cur_frm.doc.locked_by_credit_control == 1) {
								can_duplicate = false;
								document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document has to be signed (credit control)") + '</font>';
								return;
							}
						}
					}
				}

				if (cur_frm.fields_dict['docstatus']) {
					if (cur_frm.doc.docstatus == 1) {
						document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document is not editable") + '</font>';
						return;
					}
				}

				let amethod = 'silicon_ioi.ioi_configuration.doctype.ioi_scan_settings.ioi_scan_settings.ioi_scan_settings_find_element';

				let element_found = false;

				let tp = '';



				if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE')) {

					tp = 'SALES';

				} else if ((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE')) {

					tp = 'PURCHASES';
				}

				let	error = 0;
				let	error_msg = '';
				let what = '';
				let pk = '';
				let duplicate = '';
				let	destination_detail = '';
				let	destination_field = '';
				let	qt_scan = 1;
				let	scan_mode = 0;

				let price_select_type = '';
				let price_catalog_customer_supplier_id = '';
				let price_item_id = '';
				let price_condition_id = '';
				let price_unit_id = '';
				let price_currency_id = '';

				let batch_sn_id = '';
				let batch_sn_item_id = '';
				let batch_sn_code_ref = '';
				let batch_sn_origin = '';
				let qcref = '';


				frappe.call({
					method: amethod,
					args: { "doctype": cur_frm.doctype, "element": v, "tp" : tp},
					async: false,
					callback: function (r) {



						if (r.message) {

							element_found = true;
							error = r.message.error;
							error_msg = r.message.error_msg;
							what = r.message.what;
							pk = r.message.pk;
							duplicate = r.message.duplicate;
							destination_detail = r.message.destination_detail;
							destination_field = r.message.destination_field;
							qt_scan = r.message.qt_scan;
							scan_mode = r.message.scan_mode;

							price_select_type = r.message.price_select_type;
							price_catalog_customer_supplier_id = r.message.price_catalog_customer_supplier_id;
							price_item_id = r.message.price_item_id;
							price_condition_id = r.message.price_condition_id;
							price_unit_id = r.message.price_unit_id;
							price_currency_id = r.message.price_currency_id;

							batch_sn_id = r.message.batch_sn_id;
							batch_sn_item_id = r.message.batch_sn_item_id;
							batch_sn_code_ref = r.message.batch_sn_code_ref;
							batch_sn_origin = r.message.batch_sn_origin;
							qcref = r.message.qcref;



						}
					}
				});



				if (element_found)
				{
					if (error == 1)
					{
						document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __(error_msg) + '</font>';
						return;
					}

					if (duplicate == "Y")
					{
						document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Many records found !") + '</font>';
						return;
					}

					if (destination_field.trim() == '')
					{
						document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("No dest. field defined in Scan Settings") + '</font>';
						return;

					}

					let detail = destination_detail;


					if ((what == 'BARCODE_REFERENCE') || (what == 'BARCODE_BOX_REFERENCE') || (what == 'BARCODE_PALLET_REFERENCE') || (what == 'ITEM') || (what == 'SALES_PRICE') || (what == 'PURCHASES_PRICE'))
					{
						if (what == 'SALES_PRICE')
						{
							if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE'))
							{
								if (price_select_type.toUpperCase() == 'CATALOG')
								{
									if (price_catalog_customer_supplier_id != cur_frm.doc.sales_catalog_id)
									{
										document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Sales price : no corresponding catalog !") + '</font>';
										return;

									}
								}else
								{
									if (price_catalog_customer_supplier_id != cur_frm.doc.order_customer_id)
									{
										document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Sales price : no corresponding customer !") + '</font>';
										return;

									}
								}
							}else
							{
								document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Sales price are not applicable here !") + '</font>';
								return;
							}

						}else if (what == 'PURCHASES_PRICE')
						{
							if ((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE'))
							{
								if (price_catalog_customer_supplier_id != cur_frm.doc.order_supplier_id)
								{
									document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Purchases price : no corresponding supplier !") + '</font>';
									return;
								}

							}else
							{
								document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Purchases price are not applicable here !") + '</font>';
								return;
							}
						}



						if (detail.trim() != '')
						{
							silicon_ioi.ioiScanning.scan_item_or_price( what, detail, pk, duplicate, destination_detail, destination_field, qt_scan, scan_mode,
																		price_select_type, price_catalog_customer_supplier_id, price_item_id, price_condition_id,
																		price_unit_id, price_currency_id);


						}else
						{
							if (!cur_frm.is_dirty()) {
								cur_frm.dirty();
							}

							cur_frm.set_value(destination_field, pk);
							cur_frm.refresh_field(destination_field);

						}


					}else if ((what == 'BATCH') || (what == 'SN'))
					{
						if ((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY'))
						{

							if (detail.trim() == '') {
								document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Fill detail in ioi Scan Settings") + '</font>';
								return;
							}

							if (what == 'BATCH')
							{
								silicon_ioi.ioiScanning.scan_batch(what, detail, pk, batch_sn_item_id, batch_sn_id, scan_mode, v);
							}else
							{
								silicon_ioi.ioiScanning.scan_sn(what, detail, pk, batch_sn_item_id, batch_sn_id, scan_mode);
							}
						}else
						{
							document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Batch/SN scan is not allowed here") + '</font>';
							return;
						}
					}else{
						if (detail.trim() != '')
						{
							silicon_ioi.ioiScanning.scan_manufacturer_catalog( 	what, detail, pk, duplicate, destination_detail, destination_field, qt_scan, scan_mode,
																				price_select_type, price_catalog_customer_supplier_id, price_item_id, price_condition_id,
																				price_unit_id, price_currency_id);


						}else
						{
							if (!cur_frm.is_dirty()) {
								cur_frm.dirty();
							}

							cur_frm.set_value(destination_field, pk);
							cur_frm.refresh_field(destination_field);

						}
					}
				}else
				{
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Invalid scanned value") + '</font>';
					return;
				}
			}
		}


		silicon_ioi.ioiScanning.setfocus();

	}

	// ***************************************************************************************************************************************
	// Load params (doctype)
	// ***************************************************************************************************************************************

	static load_params_by_doctype()
	{
		silicon_ioi.ioiScanning.container = frappe.route_history[frappe.route_history.length - 1][0];
		silicon_ioi.ioiScanning.dc = frappe.route_history[frappe.route_history.length - 1][1];


		silicon_ioi.ioiScanning.identification = '';
		silicon_ioi.ioiScanning.module_initials = '';

		silicon_ioi.ioiScanning.field_detail1 = '';
		silicon_ioi.ioiScanning.field_detail2 = '';
		silicon_ioi.ioiScanning.field_detail3 = '';
		silicon_ioi.ioiScanning.field_detail4 = '';
		silicon_ioi.ioiScanning.field_detail5 = '';
		silicon_ioi.ioiScanning.field_detail6 = '';


		if (frappe.route_history[frappe.route_history.length - 1][1]) {

			let amethod = 'silicon_ioi.ioi_configuration.doctype.ioi_scan_settings.ioi_scan_settings.ioi_scan_settings_get_scan_data_by_doctype';

			frappe.call({
				method: amethod,
				args: { "doctype": silicon_ioi.ioiScanning.dc },
				async: false,
				callback: function (r) {

					if (r.message) {

						if (r.message[0]) {

							silicon_ioi.ioiScanning.identification = r.message[0].identification;
							silicon_ioi.ioiScanning.module_initials = r.message[0].module_initials;

							silicon_ioi.ioiScanning.field_detail1 = r.message[0].scanning_detail1;
							silicon_ioi.ioiScanning.field_detail2 = r.message[0].scanning_detail2;
							silicon_ioi.ioiScanning.field_detail3 = r.message[0].scanning_detail3;
							silicon_ioi.ioiScanning.field_detail4 = r.message[0].scanning_detail4;
							silicon_ioi.ioiScanning.field_detail5 = r.message[0].scanning_detail5;
							silicon_ioi.ioiScanning.field_detail6 = r.message[0].scanning_detail6;
						}
					}
				}
			});
		}
	}


	// ***************************************************************************************************************************************
	// Load help commands
	// ***************************************************************************************************************************************

	static load_command_help()
	{
		silicon_ioi.ioiScanning.command = [];
		silicon_ioi.ioiScanning.command[0] = [__("Global functions"), ''];
		silicon_ioi.ioiScanning.command[1] = ['$-GO-XXX', 			__("Go to the module list (XXX = module's initials)")];
		silicon_ioi.ioiScanning.command[2] = ['',                   __("<u>Example</u> : <b>$-GO-SO</b> (Go to Sales Order list)")];
		silicon_ioi.ioiScanning.command[3] = ['$-GO-XXX-YYYY', 		__("Go to the module form (XXX = module's initials; YYYY = name)")];
		silicon_ioi.ioiScanning.command[4] = ['',                   __("<u>Example</u> : <b>$-GO-SO-QTSA/202200007</b>")];
		silicon_ioi.ioiScanning.command[5] = ['',                   __("Go to Sales Order QTSA • 202200007")];

		silicon_ioi.ioiScanning.command_list_form = [];
		silicon_ioi.ioiScanning.command_list_form[0] = [__("List / Form functions"), ''];
		silicon_ioi.ioiScanning.command_list_form[1] = ['$-NEW',	__("Create a new document")];

		silicon_ioi.ioiScanning.command_form = [];
		silicon_ioi.ioiScanning.command_form[0] = [__("Form functions"), ''];
		silicon_ioi.ioiScanning.command_form[1] = ['$-SAVE', 		__("Save the current document")];
		silicon_ioi.ioiScanning.command_form[2] = ['$-CANCEL', 		__("Cancel the current edited document")];
		silicon_ioi.ioiScanning.command_form[3] = ['$-DELETE', 		__("Delete the current document")];
		silicon_ioi.ioiScanning.command_form[4] = ['$-DUPLICATE', 	__("Duplicate the current document")];
		silicon_ioi.ioiScanning.command_form[5] = ['$-STA-XX',		__("Change document status (XX = destination status)")];
		silicon_ioi.ioiScanning.command_form[6] = ['',              __("<u>Example</u> : <b>$-STA-1</b>")];
		silicon_ioi.ioiScanning.command_form[7] = ['$-UST-XX',		__("Change user status (XX = destination user status)")];
		silicon_ioi.ioiScanning.command_form[8] = ['',              __("<u>Example</u> : <b>$-UST-3</b>")];

		silicon_ioi.ioiScanning.command_form_detail = [];
		silicon_ioi.ioiScanning.command_form_detail[0] = [__("Form detail functions"), ''];
		silicon_ioi.ioiScanning.command_form_detail[1] = ['$-NEWLN', 					__("Add a new row in the detail 1")];
		silicon_ioi.ioiScanning.command_form_detail[2] = ['', 							__("<b>$-NEWLN2</b>, ... , <b>$-NEWLN6</b> : add new row in the detail 2, ... , 6")];
		silicon_ioi.ioiScanning.command_form_detail[3] = ['$-DELLN-XXX', 				__("Remove the row number XXX in the detail 1")];
		silicon_ioi.ioiScanning.command_form_detail[4] = ['', 							__("<b>$-DELLN2-XXX</b>, ... , <b>$-DELLN6-XXX</b> : remove the row XXX in the detail 2, ... , 6")];
		silicon_ioi.ioiScanning.command_form_detail[5] = ['', 							__("<u>Exemple</u> : <b>$-DELLN-17</b>")];
		silicon_ioi.ioiScanning.command_form_detail[6] = ['$-SETLN-XXX', 				__("Select the row number XXX in the detail 1")];
		silicon_ioi.ioiScanning.command_form_detail[7] = ['', 							__("<b>$-SETLN2-XXX</b>, ... , <b>$-SETLN6-XXX</b> : select the row XXX in the detail 2, ... , 6")];
		silicon_ioi.ioiScanning.command_form_detail[8] = ['', 							__("<u>Example</u> : <b>$-SETLN-8</b>")];
		silicon_ioi.ioiScanning.command_form_detail[9] = ['$-MOVELN-XXX-YYY', 			__("Move row XXX to YYY in detail 1")];
		silicon_ioi.ioiScanning.command_form_detail[10] = ['', 							__("<b>$-MOVELN2-XXX-YYY</b>, ... , <b>$-MOVELN6-XXX-YYY</b> : move row XXX to YYYY in detail 2, ... , 6")];
		silicon_ioi.ioiScanning.command_form_detail[11] = ['', 							__("<u>Example</u> : <b>$-MOVELN-27-14</b>")];
		silicon_ioi.ioiScanning.command_form_detail[12] = ['$-DUPLN-XXX', 				__("Duplicate the row number XXX in the detail 1")];
		silicon_ioi.ioiScanning.command_form_detail[13] = ['', 							__("<b>$-DUPLN2-XXX</b>, ... , <b>$-DUPLN6-XXX</b> : duplicate the row XXX in the detail 2, ... , 6")];
		silicon_ioi.ioiScanning.command_form_detail[14] = ['', 							__("<u>Example</u> : <b>$-DUPLN-23</b>")];


		silicon_ioi.ioiScanning.open_window = [];
		silicon_ioi.ioiScanning.open_window[0] = [__("Open window"), ''];
		silicon_ioi.ioiScanning.open_window[1] = ['$-W or $-FW', __("Open the warehouse window")];
		silicon_ioi.ioiScanning.open_window[2] = ['$-TW',        __("Open the warehouse window (to)")];
		silicon_ioi.ioiScanning.open_window[3] = ['$-L or $-FL', __("Open the location window")];
		silicon_ioi.ioiScanning.open_window[4] = ['$-TL', 	   __("Open the location window (to)")];
		silicon_ioi.ioiScanning.open_window[5] = ['$-B', 		   __("Open the batch/sn window")];


		silicon_ioi.ioiScanning.command_value = [];
		silicon_ioi.ioiScanning.command_value[0] = [__("Assigning value to field"), ''];
		silicon_ioi.ioiScanning.command_value[1] = ['$-MFD-XXX-YYY', __("Set value (YYY) to field (XXX) in master")];
		silicon_ioi.ioiScanning.command_value[2] = ['',              __("<u>Example</u> : <b>$-MFD-OCUST-ABC123</b>")];
		silicon_ioi.ioiScanning.command_value[3] = ['',              __("Set ABC123 in linked field to OCUST")];
		silicon_ioi.ioiScanning.command_value[4] = ['$-DFD-XXX-YYY', __("Set value (YYY) to field (XXX) in detail")];
		silicon_ioi.ioiScanning.command_value[5] = ['', 			 __("<b>$-DFD2-XXX-YYY</b>, ... , <b>$-DFD6-XXX-YYY</b>")];
		silicon_ioi.ioiScanning.command_value[6] = ['',     		 __("Set value (YYY) to field (XXX) in detail 2, ... , 6")];
		silicon_ioi.ioiScanning.command_value[7] = ['',              __("<u>Example</u> : <b>$-DFD-QT-12.50</b>")];
		silicon_ioi.ioiScanning.command_value[8] = ['',              __("Set 12.50 in linked field to QT")];
		silicon_ioi.ioiScanning.command_value[9] = ['',               __("Go to <b>ioi Scan Settings</b> to link initials to fields")];


		silicon_ioi.ioiScanning.prefix_detection = [];
		silicon_ioi.ioiScanning.prefix_detection[0] = [__("Prefixes detection"), ''];
		silicon_ioi.ioiScanning.prefix_detection[1] = ['$YYXXXXXXXXX', 	__("Check, retrieve value of prefix")];
		silicon_ioi.ioiScanning.prefix_detection[2] = ['', 				__("YY = prefix, XXXXXXXXX = value")];
		silicon_ioi.ioiScanning.prefix_detection[3] = ['', 				__("Composite keys have to be separated by /")];
		silicon_ioi.ioiScanning.prefix_detection[4] = ['', 				__("<u>Example</u> : <b>$CU123456</b>")];
		silicon_ioi.ioiScanning.prefix_detection[5] = ['',              __("Go to <b>ioi Scan Settings</b> to define prefixes")];

	}


	// ***************************************************************************************************************************************
	// Function $-? : Help
	// ***************************************************************************************************************************************

	static show_help()
	{
		var d = new frappe.ui.Dialog({
			'title': __("Scanning functions help"),
			'fields': [
				{'fieldname': 'html_scanning_help', 'fieldtype': 'HTML'}

			],
			primary_action_label: __('Ok'),
			primary_action: function(){
				d.hide();

				silicon_ioi.ioiScanning.sleep_static(1000).then(() => {

					document.getElementById('sb_main_scan_input').focus();
				});


			}

		});

		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:600px; width: 100%;">';

		html += '<table border=0 style="border: 0px solid #E8EAEB" width=800px>';

		for (var i = 0; i < silicon_ioi.ioiScanning.command.length; i++)
		{
			if (i == 0)	{

				html += '<tr style="height:30px;">';
				html += '<td colspan=2 style="vertical-align: middle;">&nbsp;<div style="background-color:#D0E7FB; border-radius: 6px; padding: 4px;"><b><font color="black">' + silicon_ioi.ioiScanning.command[i][0] + '</font></b></div></td>';
				html += '</tr>';

			}else {

				html += '<tr style="height:30px">';
				html += '<td width=200px style="vertical-align: middle;">&nbsp;<b>' + silicon_ioi.ioiScanning.command[i][0] + '</b></td>';
				html += '<td width=600px style="vertical-align: middle;">&nbsp;' + silicon_ioi.ioiScanning.command[i][1] + '</td>';
				html += '</tr>';

			}
		}

		for (var i = 0; i < silicon_ioi.ioiScanning.command_list_form.length; i++)
		{
			if (i == 0)	{

				html += '<tr style="height:30px;">';
				html += '<td colspan=2 style="vertical-align: middle;">&nbsp;<div style="background-color:#D0E7FB; border-radius: 6px; padding: 4px;"><b><font color="black">' + silicon_ioi.ioiScanning.command_list_form[i][0] + '</font></b></div></td>';
				html += '</tr>';

			}else {

				html += '<tr style="height:30px">';
				html += '<td width=200px style="vertical-align: middle;">&nbsp;<b>' + silicon_ioi.ioiScanning.command_list_form[i][0] + '</b></td>';
				html += '<td width=600px style="vertical-align: middle;">&nbsp;' + silicon_ioi.ioiScanning.command_list_form[i][1] + '</td>';
				html += '</tr>';

			}
		}

		for (var i = 0; i < silicon_ioi.ioiScanning.command_form.length; i++)
		{
			if (i == 0)	{

				html += '<tr style="height:30px;">';
				html += '<td colspan=2 style="vertical-align: middle;">&nbsp;<div style="background-color:#D0E7FB; border-radius: 6px; padding: 4px;"><b><font color="black">' + silicon_ioi.ioiScanning.command_form[i][0] + '</font></b></div></td>';
				html += '</tr>';

			}else {

				html += '<tr style="height:30px">';
				html += '<td width=200px style="vertical-align: middle;">&nbsp;<b>' + silicon_ioi.ioiScanning.command_form[i][0] + '</b></td>';
				html += '<td width=600px style="vertical-align: middle;">&nbsp;' + silicon_ioi.ioiScanning.command_form[i][1] + '</td>';
				html += '</tr>';

			}
		}

		for (var i = 0; i < silicon_ioi.ioiScanning.command_form_detail.length; i++)
		{
			if (i == 0)	{

				html += '<tr style="height:30px;">';
				html += '<td colspan=2 style="vertical-align: middle;">&nbsp;<div style="background-color:#D0E7FB; border-radius: 6px; padding: 4px;"><b><font color="black">' + silicon_ioi.ioiScanning.command_form_detail[i][0] + '</font></b></div></td>';
				html += '</tr>';

			}else {

				html += '<tr style="height:30px">';
				html += '<td width=200px style="vertical-align: middle;">&nbsp;<b>' + silicon_ioi.ioiScanning.command_form_detail[i][0] + '</b></td>';
				html += '<td width=600px style="vertical-align: middle;">&nbsp;' + silicon_ioi.ioiScanning.command_form_detail[i][1] + '</td>';
				html += '</tr>';

			}

		}

		for (var i = 0; i < silicon_ioi.ioiScanning.open_window.length; i++)
		{
			if (i == 0)	{

				html += '<tr style="height:30px;">';
				html += '<td colspan=2 style="vertical-align: middle;">&nbsp;<div style="background-color:#D0E7FB; border-radius: 6px; padding: 4px;"><b><font color="black">' + silicon_ioi.ioiScanning.open_window[i][0] + '</font></b></div></td>';
				html += '</tr>';

			}else {

				html += '<tr style="height:30px">';
				html += '<td width=200px style="vertical-align: middle;">&nbsp;<b>' + silicon_ioi.ioiScanning.open_window[i][0] + '</b></td>';
				html += '<td width=600px style="vertical-align: middle;">&nbsp;' + silicon_ioi.ioiScanning.open_window[i][1] + '</td>';
				html += '</tr>';

			}

		}


		for (var i = 0; i < silicon_ioi.ioiScanning.command_value.length; i++)
		{
			if (i == 0)	{

				html += '<tr style="height:30px;">';
				html += '<td colspan=2 style="vertical-align: middle;">&nbsp;<div style="background-color:#D0E7FB; border-radius: 6px; padding: 4px;"><b><font color="black">' + silicon_ioi.ioiScanning.command_value[i][0] + '</font></b></div></td>';
				html += '</tr>';

			}else {

				html += '<tr style="height:30px">';
				html += '<td width=200px style="vertical-align: middle;">&nbsp;<b>' + silicon_ioi.ioiScanning.command_value[i][0] + '</b></td>';
				html += '<td width=600px style="vertical-align: middle;">&nbsp;' + silicon_ioi.ioiScanning.command_value[i][1] + '</td>';
				html += '</tr>';

			}

		}


		for (var i = 0; i < silicon_ioi.ioiScanning.prefix_detection.length; i++)
		{
			if (i == 0)	{

				html += '<tr style="height:30px;">';
				html += '<td colspan=2 style="vertical-align: middle;">&nbsp;<div style="background-color:#D0E7FB; border-radius: 6px; padding: 4px;"><b><font color="black">' + silicon_ioi.ioiScanning.prefix_detection[i][0] + '</font></b></div></td>';
				html += '</tr>';

			}else {

				html += '<tr style="height:30px">';
				html += '<td width=200px style="vertical-align: middle;">&nbsp;<b>' + silicon_ioi.ioiScanning.prefix_detection[i][0] + '</b></td>';
				html += '<td width=600px style="vertical-align: middle;">&nbsp;' + silicon_ioi.ioiScanning.prefix_detection[i][1] + '</td>';
				html += '</tr>';

			}

		}



		html += '</table>';
		html += '</div>';

		d.fields_dict.html_scanning_help.$wrapper.html(html);

		if (window.innerWidth <= 500) {
			d.$wrapper.find('.modal-dialog').css("max-width", '100%').css("width", '100%');
		}else {
			d.$wrapper.find('.modal-dialog').css("max-width", '850px').css("width", '850px');
		}
		d.show();
	}

	// ***************************************************************************************************************************************
	// Function $-GO : Go to list or form
	// ***************************************************************************************************************************************

	static go_to_list_form(v)
	{
		if (silicon_ioi.ioiScanning.container.toUpperCase() == 'FORM') {
			if (cur_frm.is_dirty()) {
				document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Save document before leave it") + '</font>';
				return;
			}
		}

		v = v.substring(4, v.length).trim();

		if (v.trim() == '')
		{
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Initials module is missing") + '</font>';
			return;
		}

		let initials = '';

		if (v.indexOf('-') == -1)
		{
			initials = v.trim();

		}else
		{	let idx = v.indexOf('-');
			initials = v.substring(0, idx);
			v = v.substring(idx+1, v.length);
		}


		let amethod = 'silicon_ioi.ioi_configuration.doctype.ioi_scan_settings.ioi_scan_settings.ioi_scan_settings_get_scan_data_by_initials';

		frappe.call({
			method: amethod,
			args: { "initials": initials },
			async: false,
			callback: function (r) {

				if (!r.message) {
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Fill module initials in ioi Scan Settings") + '</font>';
					return;
				}else
				{
					silicon_ioi.ioiScanning.identification = '';

					if (!r.message[0])
					{
						document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Fill module initials in ioi Scan Settings") + '</font>';
						return;
					}

					if ((!r.message[0].identification) || (r.message[0].identification.trim() == ''))
					{
						document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Fill module initials in ioi Scan Settings") + '</font>';
						return;
					}

					silicon_ioi.ioiScanning.identification = r.message[0].identification;
					silicon_ioi.ioiScanning.module_initials = r.message[0].module_initials;

					silicon_ioi.ioiScanning.field_detail1 = r.message[0].scanning_detail1;
					silicon_ioi.ioiScanning.field_detail2 = r.message[0].scanning_detail2;
					silicon_ioi.ioiScanning.field_detail3 = r.message[0].scanning_detail3;
					silicon_ioi.ioiScanning.field_detail4 = r.message[0].scanning_detail4;
					silicon_ioi.ioiScanning.field_detail5 = r.message[0].scanning_detail5;
					silicon_ioi.ioiScanning.field_detail6 = r.message[0].scanning_detail6;
				}
			}
		});


		if (v.indexOf('-') == -1)
		{	if (silicon_ioi.ioiScanning.identification.trim() != '')
			{
				window.location = '/app/' + silicon_ioi.ioiScanning.identification.toLocaleLowerCase().replaceAll(' ', '-') + '/';
			}
		}else
		{
			let pk = v.trim();
			pk = pk.replaceAll('/', ' • ');


			let ameth = 'silicon_ioi.ioi_configuration.doctype.ioi_scan_settings.ioi_scan_settings.ioi_scan_settings_check_doctype_name';

			frappe.call({
				method: ameth,
				args: { "doctype": silicon_ioi.ioiScanning.identification, "name": pk },
				async: false,
				callback: function (r) {

					if (r.message.error == 1)
					{
						document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + r.message.error_msg + '</font>';
						return;
					}else
					{
						window.location = '/app/' + silicon_ioi.ioiScanning.identification.toLocaleLowerCase().replaceAll(' ', '-') + '/' + r.message.name;
					}
				}
			});
		}
	}

	// ***************************************************************************************************************************************
	// Function $-NEW : Create a new document
	// ***************************************************************************************************************************************

	static new_doc()
	{

		if ((silicon_ioi.ioiScanning.container.toUpperCase() != 'FORM') && (silicon_ioi.ioiScanning.container.toUpperCase() != 'LIST')) {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Function not available here") + '</font>';
			return;
		}

		if (frappe.route_history[frappe.route_history.length - 1][2]) {
			if (silicon_ioi.ioiScanning.dc == frappe.route_history[frappe.route_history.length - 1][2]) {
				document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Can not create new document here") + '</font>';
				return;
			}
		}

		if (silicon_ioi.ioiScanning.container.toUpperCase() == 'FORM') {
			if (cur_frm.is_dirty()) {
				document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Save document before create a new one") + '</font>';
				return;
			}
		}

		document.getElementById('sb_main_scan_detail_row_idx').value = '';
		document.getElementById('sb_main_scan_detail2_row_idx').value = '';
		document.getElementById('sb_main_scan_detail3_row_idx').value = '';
		document.getElementById('sb_main_scan_detail4_row_idx').value = '';
		document.getElementById('sb_main_scan_detail5_row_idx').value = '';
		document.getElementById('sb_main_scan_detail6_row_idx').value = '';


		frappe.new_doc(silicon_ioi.ioiScanning.dc, {}, doc => { setTimeout(silicon_ioi.ioiScanning.setfocus, 3000); });
	}

	// ***************************************************************************************************************************************
	// Function $-SAVE: Save document
	// ***************************************************************************************************************************************

	static save_doc()
	{
		if (silicon_ioi.ioiScanning.container.toUpperCase() != 'FORM') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Function not available here") + '</font>';
			return;
		}

		if (cur_frm.is_dirty()) {

			let fct_call_back = function () { silicon_ioi.ioiScanning.setfocus(); };
			cur_frm.save('Save', fct_call_back);
		}
	}

	// ***************************************************************************************************************************************
	// Function $-CANCEL: Cancel document
	// ***************************************************************************************************************************************

	static cancel_doc()
	{
		if (silicon_ioi.ioiScanning.container.toUpperCase() != 'FORM') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Function not available here") + '</font>';
			return;
		}

		if (cur_frm.is_dirty()) {

			let url = '/app/' + silicon_ioi.ioiScanning.dc.toLowerCase().replaceAll(' ', '-') + '/';

			window.location = url;

			silicon_ioi.ioiScanning.sleep_static(1000).then(() => {

				silicon_ioi.ioiScanning.setfocus();
			});
		}
	}

	// ***************************************************************************************************************************************
	// Function $-DELETE: Delete document
	// ***************************************************************************************************************************************

	static delete_doc()
	{
		if (silicon_ioi.ioiScanning.container.toUpperCase() != 'FORM') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Function not available here") + '</font>';
			return;
		}

		if (cur_frm.is_dirty()) {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Save document before delete it") + '</font>';
			return;
		}

		if (frappe.route_history[frappe.route_history.length - 1][2]) {
			if (silicon_ioi.ioiScanning.dc == frappe.route_history[frappe.route_history.length - 1][2]) {
				document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Can not delete new document") + '</font>';
				return;
			}
		}

		document.getElementById('sb_main_scan_detail_row_idx').value = '';
		document.getElementById('sb_main_scan_detail2_row_idx').value = '';
		document.getElementById('sb_main_scan_detail3_row_idx').value = '';
		document.getElementById('sb_main_scan_detail4_row_idx').value = '';
		document.getElementById('sb_main_scan_detail5_row_idx').value = '';
		document.getElementById('sb_main_scan_detail6_row_idx').value = '';


		let name = frappe.route_history[frappe.route_history.length - 1][2];

		frappe.db.delete_doc(silicon_ioi.ioiScanning.dc, name);

		let sdc = silicon_ioi.ioiScanning.dc.toLowerCase();
		sdc = sdc.replaceAll(' ', '-')

		window.location = '/app/' + sdc + '/';
	}


	// ***************************************************************************************************************************************
	// Function $-DUPLICATE: Duplicate document
	// ***************************************************************************************************************************************

	static duplicate_doc()
	{
		if (silicon_ioi.ioiScanning.container.toUpperCase() != 'FORM') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Function not available here") + '</font>';
			return;
		}

		if (cur_frm.is_dirty()) {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Save document before delete it") + '</font>';
			return;
		}

		if (frappe.route_history[frappe.route_history.length - 1][2]) {
			if (silicon_ioi.ioiScanning.dc == frappe.route_history[frappe.route_history.length - 1][2]) {
				document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Can not duplicate new document") + '</font>';
				return;
			}
		}

		document.getElementById('sb_main_scan_detail_row_idx').value = '';
		document.getElementById('sb_main_scan_detail2_row_idx').value = '';
		document.getElementById('sb_main_scan_detail3_row_idx').value = '';
		document.getElementById('sb_main_scan_detail4_row_idx').value = '';
		document.getElementById('sb_main_scan_detail5_row_idx').value = '';
		document.getElementById('sb_main_scan_detail6_row_idx').value = '';


		cur_frm.copy_doc();
	}


	// ***************************************************************************************************************************************
	// Function $-NEWLN: New row in detail
	// ***************************************************************************************************************************************

	static new_line(v)
	{
		let detail = '';

		if (v.trim().toUpperCase() == '-NEWLN') {
			detail = silicon_ioi.ioiScanning.field_detail1;
		} else if (v.trim().toUpperCase() == '-NEWLN2') {
			detail = silicon_ioi.ioiScanning.field_detail2;
		} else if (v.trim().toUpperCase() == '-NEWLN3') {
			detail = silicon_ioi.ioiScanning.field_detail3;
		} else if (v.trim().toUpperCase() == '-NEWLN4') {
			detail = silicon_ioi.ioiScanning.field_detail4;
		} else if (v.trim().toUpperCase() == '-NEWLN5') {
			detail = silicon_ioi.ioiScanning.field_detail5;
		} else if (v.trim().toUpperCase() == '-NEWLN6') {
			detail = silicon_ioi.ioiScanning.field_detail6;
		}

		if (silicon_ioi.ioiScanning.container.toUpperCase() != 'FORM') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Function not available here") + '</font>';
			return;
		}

		if (detail.trim() == '') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Fill detail field in ioi Scan Settings") + '</font>';
			return;
		}


		if (cur_frm.fields_dict[detail]) {
			let can_add = true;

			if (cur_frm.fields_dict['ioistatus']) {
				if (cur_frm.doc.ioistatus != 0) {
					can_add = false;
				}else{

					if (cur_frm.doctype == 'ioi Sales Delivery') {

						if (cur_frm.doc.locked_by_credit_control == 1) {
							can_add = false;
							document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document has to be signed (credit control)") + '</font>';
							return;
						}
					}
				}
			}

			if ((can_add) && (cur_frm.fields_dict[detail].grid.display_status.toUpperCase() != 'WRITE'))
			{
				can_add = false;
			}

			if (can_add) {

				cur_frm.fields_dict[detail].grid.grid_pagination.go_to_page(cur_frm.fields_dict[detail].grid.grid_pagination.total_pages);

				let d = cur_frm.fields_dict[detail].grid.add_new_row();

				if (d) {
					if (d.idx) {
						if (detail == silicon_ioi.ioiScanning.field_detail1) {
							document.getElementById('sb_main_scan_detail_row_idx').value = d.idx.toString();
						} else if (detail == silicon_ioi.ioiScanning.field_detail2) {
							document.getElementById('sb_main_scan_detail2_row_idx').value = d.idx.toString();
						} else if (detail == silicon_ioi.ioiScanning.field_detail3) {
							document.getElementById('sb_main_scan_detail3_row_idx').value = d.idx.toString();
						} else if (detail == silicon_ioi.ioiScanning.field_detail4) {
							document.getElementById('sb_main_scan_detail4_row_idx').value = d.idx.toString();
						} else if (detail == silicon_ioi.ioiScanning.field_detail5) {
							document.getElementById('sb_main_scan_detail5_row_idx').value = d.idx.toString();
						} else if (detail == silicon_ioi.ioiScanning.field_detail6) {
							document.getElementById('sb_main_scan_detail6_row_idx').value = d.idx.toString();
						}


						for (var i = 0; i < cur_frm.fields_dict[detail].grid.grid_rows.length; i++) {

								cur_frm.get_field(detail).grid.grid_rows[i].row_check[0].children[0].checked = false;
						}

						silicon_ioi.ioiScanning.sleep_static(200).then(() => {
							cur_frm.fields_dict[detail].grid.grid_rows[d.idx-1].row_check[0].children[0].checked = true;
						});
					}
				}
			}
		}
	}


	// ***************************************************************************************************************************************
	// Function $-DELLN: Delete row in detail
	// ***************************************************************************************************************************************

	static delete_line(v)
	{
		let detail = '';
		let tiret_idx = 6;

		if (v.trim().toUpperCase().substring(0, 7) == '-DELLN6') {
			detail = silicon_ioi.ioiScanning.field_detail6;
			tiret_idx = 7;
		} else if (v.trim().toUpperCase().substring(0, 7) == '-DELLN5') {
			detail = silicon_ioi.ioiScanning.field_detail5;
			tiret_idx = 7;
		} else if (v.trim().toUpperCase().substring(0, 7) == '-DELLN4') {
			detail = silicon_ioi.ioiScanning.field_detail4;
			tiret_idx = 7;
		} else if (v.trim().toUpperCase().substring(0, 7) == '-DELLN3') {
			detail = silicon_ioi.ioiScanning.field_detail3;
			tiret_idx = 7;
		} else if (v.trim().toUpperCase().substring(0, 7) == '-DELLN2') {
			detail = silicon_ioi.ioiScanning.field_detail2;
			tiret_idx = 7;
		} else if (v.trim().toUpperCase().substring(0, 6) == '-DELLN') {
			detail = silicon_ioi.ioiScanning.field_detail1;
			tiret_idx = 6;
		}

		if (silicon_ioi.ioiScanning.container.toUpperCase() != 'FORM') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Function not available here") + '</font>';
			return;
		}

		if (v[tiret_idx] != '-') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Line number is missing") + '</font>';
			return;
		}

		if (detail.trim() == '') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Fill detail field in ioi Scan Settings") + '</font>';
			return;
		}

		if (cur_frm.fields_dict[detail]) {

			let can_delete = true;

			if (cur_frm.fields_dict['ioistatus']) {
				if (cur_frm.doc.ioistatus != 0) {
					can_delete = false;
				}else{
					if (cur_frm.doctype == 'ioi Sales Delivery') {

						if (cur_frm.doc.locked_by_credit_control == 1) {
							can_add = false;
							document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document has to be signed (credit control)") + '</font>';
							return;
						}
					}
				}
			}

			if ((can_delete) && (cur_frm.fields_dict[detail].grid.display_status.toUpperCase() != 'WRITE'))
			{
				can_delete = false;
			}


			if (can_delete) {

				let idx = v.trim().toUpperCase().substring(tiret_idx + 1, v.length).trim();

				if (idx.trim() != '') {

					let n = idx / cur_frm.fields_dict[detail].grid.grid_pagination.page_length;

					let n_round = Math.round(n);

					if (n <= n_round) {
						n = n_round
					} else {
						n = n_round+1;
					}

					cur_frm.fields_dict[detail].grid.grid_pagination.go_to_page(n);


					if (cur_frm.get_field(detail).grid.grid_rows[idx - 1]) {

						if (cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].doc.ioistatus) {
							if (cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].doc.ioistatus != 0) {
								can_delete = false;
							}
						}

						if (can_delete) {
							cur_frm.get_field(detail).grid.grid_rows[idx - 1].remove();

							if (detail == silicon_ioi.ioiScanning.field_detail1) {
								document.getElementById('sb_main_scan_detail_row_idx').value = '';
							} else if (detail == silicon_ioi.ioiScanning.field_detail2) {
								document.getElementById('sb_main_scan_detail2_row_idx').value = '';
							} else if (detail == silicon_ioi.ioiScanning.field_detail3) {
								document.getElementById('sb_main_scan_detail3_row_idx').value = '';
							} else if (detail == silicon_ioi.ioiScanning.field_detail4) {
								document.getElementById('sb_main_scan_detail4_row_idx').value = '';
							} else if (detail == silicon_ioi.ioiScanning.field_detail5) {
								document.getElementById('sb_main_scan_detail5_row_idx').value = '';
							} else if (detail == silicon_ioi.ioiScanning.field_detail6) {
								document.getElementById('sb_main_scan_detail6_row_idx').value = '';
							}

						}
					}else
					{	document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Row numbrer not found") + '</font>';
						return;
					}

				}
			}
		}
	}

	// ***************************************************************************************************************************************
	// Function $-SETLN: select a row in detail
	// ***************************************************************************************************************************************

	static set_line(v)
	{
		let detail = '';
		let tiret_idx = 6;

		if (v.trim().toUpperCase().substring(0, 7) == '-SETLN6') {
			detail = silicon_ioi.ioiScanning.field_detail6;
			tiret_idx = 7;
		} else if (v.trim().toUpperCase().substring(0, 7) == '-SETLN5') {
			detail = silicon_ioi.ioiScanning.field_detail5;
			tiret_idx = 7;
		} else if (v.trim().toUpperCase().substring(0, 7) == '-SETLN4') {
			detail = silicon_ioi.ioiScanning.field_detail4;
			tiret_idx = 7;
		} else if (v.trim().toUpperCase().substring(0, 7) == '-SETLN3') {
			detail = silicon_ioi.ioiScanning.field_detail3;
			tiret_idx = 7;
		} else if (v.trim().toUpperCase().substring(0, 7) == '-SETLN2') {
			detail = silicon_ioi.ioiScanning.field_detail2;
			tiret_idx = 7;
		} else if (v.trim().toUpperCase().substring(0, 6) == '-SETLN') {
			detail = silicon_ioi.ioiScanning.field_detail1;
			tiret_idx = 6;
		}

		if (silicon_ioi.ioiScanning.container.toUpperCase() != 'FORM') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Function not available here") + '</font>';
			return;
		}

		if (v[tiret_idx] != '-') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Line number is missing") + '</font>';
			return;
		}

		if (detail.trim() == '') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Fill detail field in ioi Scan Settings") + '</font>';
			return;
		}

		if (cur_frm.fields_dict[detail]) {

			let can_focus = true;

			if ((can_focus) && (cur_frm.fields_dict[detail].grid.display_status.toUpperCase() != 'WRITE'))
			{
				can_focus = false;
			}


			if (can_focus) {

				let idx = v.trim().toUpperCase().substring(tiret_idx + 1, v.length).trim();

				if (idx.trim() != '') {

					let n = idx / cur_frm.fields_dict[detail].grid.grid_pagination.page_length;

					let n_round = Math.round(n);

					if (n <= n_round) {
						n = n_round
					} else {
						n = n_round+1;
					}

					cur_frm.fields_dict[detail].grid.grid_pagination.go_to_page(n);



					if (cur_frm.get_field(detail).grid.grid_rows[idx - 1]) {


						if (cur_frm.get_field(detail).grid) {
							if (cur_frm.get_field(detail).grid.grid_rows)
							{
								for (var i = 0; i < cur_frm.get_field(detail).grid.grid_rows.length; i++) {

									cur_frm.get_field(detail).grid.grid_rows[i].row_check[0].children[0].checked = false;
								}
							}

							cur_frm.get_field(detail).grid.grid_rows[idx-1].row_check[0].children[0].checked = true;
						}


						if (detail == silicon_ioi.ioiScanning.field_detail1) {
							document.getElementById('sb_main_scan_detail_row_idx').value = idx.toString();
						} else if (detail == silicon_ioi.ioiScanning.field_detail2) {
							document.getElementById('sb_main_scan_detail2_row_idx').value = idx.toString();
						} else if (detail == silicon_ioi.ioiScanning.field_detail3) {
							document.getElementById('sb_main_scan_detail3_row_idx').value = idx.toString();
						} else if (detail == silicon_ioi.ioiScanning.field_detail4) {
							document.getElementById('sb_main_scan_detail4_row_idx').value = idx.toString();
						} else if (detail == silicon_ioi.ioiScanning.field_detail5) {
							document.getElementById('sb_main_scan_detail5_row_idx').value = idx.toString();
						} else if (detail == silicon_ioi.ioiScanning.field_detail6) {
							document.getElementById('sb_main_scan_detail6_row_idx').value = idx.toString();
						}

					}else
					{
						document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Row numbrer not found") + '</font>';
						return;
					}
				}
			}
		}
	}

	// ***************************************************************************************************************************************
	// Function $-MOVELN : move row in detail
	// ***************************************************************************************************************************************

	static move_line(v)
	{
		let detail = '';
		let tiret_idx = 6;

		if (v.trim().toUpperCase().substring(0, 8) == '-MOVELN6') {
			detail = silicon_ioi.ioiScanning.field_detail6;
			tiret_idx = 8;
		} else if (v.trim().toUpperCase().substring(0, 8) == '-MOVELN5') {
			detail = silicon_ioi.ioiScanning.field_detail5;
			tiret_idx = 8;
		} else if (v.trim().toUpperCase().substring(0, 8) == '-MOVELN4') {
			detail = silicon_ioi.ioiScanning.field_detail4;
			tiret_idx = 8;
		} else if (v.trim().toUpperCase().substring(0, 8) == '-MOVELN3') {
			detail = silicon_ioi.ioiScanning.field_detail3;
			tiret_idx = 8;
		} else if (v.trim().toUpperCase().substring(0, 8) == '-MOVELN2') {
			detail = silicon_ioi.ioiScanning.field_detail2;
			tiret_idx = 8;
		} else if (v.trim().toUpperCase().substring(0, 7) == '-MOVELN') {
			detail = silicon_ioi.ioiScanning.field_detail1;
			tiret_idx = 7;
		}

		if (silicon_ioi.ioiScanning.container.toUpperCase() != 'FORM') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Function not available here") + '</font>';
			return;
		}

		if (v[tiret_idx] != '-') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Line number is missing") + '</font>';
			return;
		}

		if (detail.trim() == '') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Fill detail field in ioi Scan Settings") + '</font>';
			return;
		}

		if (cur_frm.fields_dict[detail]) {

			let can_move = true;

			if (cur_frm.fields_dict['ioistatus']) {
				if (cur_frm.doc.ioistatus != 0) {
					can_move = false;
				}
			}

			if ((can_move) && (cur_frm.fields_dict[detail].grid.display_status.toUpperCase() != 'WRITE'))
			{
				can_move = false;
			}



			if (can_move) {

				let s = v.trim().toUpperCase().substring(tiret_idx + 1, v.length).trim();

				let idx_from = s.substring(0, s.indexOf('-')).trim();

				if (idx_from.trim() == '')
				{
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Row number is missing") + '</font>';
					return;

				}

				s = s.substring(s.indexOf('-')+1, v.length);

				let idx_to = s.trim();

				if (idx_to.trim() == '')
				{
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Row number is missing") + '</font>';
					return;

				}


				let n = parseInt(idx_from);

				if (isNaN(n)) {
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Row number (source) is invalid") + '</font>';
					return;
				}


				n = parseInt(idx_to);

				if (isNaN(n)) {
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Row number (destination) is invalid") + '</font>';
					return;
				}


				if ((idx_from.trim() != '') && (idx_to.trim() != '')) {

					if ((cur_frm.fields_dict[detail].grid.grid_rows[idx_from-1]) && (cur_frm.fields_dict[detail].grid.grid_rows[idx_to-1]))
					{

						let data = cur_frm.fields_dict[detail].grid.get_data();

						data.move(idx_from-1, idx_to-1);


						let script_manager = cur_frm.fields_dict[detail].grid.grid_rows[idx_from - 1].frm.script_manager;

						script_manager.trigger(detail+'_move', cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[idx_from - 1].doc.name);


						for (let i = 0; i < data.length; i++) {
							data[i].idx = i + 1;
						}

						cur_frm.fields_dict[detail].grid.refresh();



						let n = idx_to / cur_frm.fields_dict[detail].grid.grid_pagination.page_length;

						let n_round = Math.round(n);

						if (n <= n_round) {
							n = n_round
						} else {
							n = n_round+1;
						}

						cur_frm.fields_dict[detail].grid.grid_pagination.go_to_page(n);

						if (cur_frm.get_field(detail).grid.grid_rows[idx_to - 1]) {


							if (cur_frm.get_field(detail).grid) {
								if (cur_frm.get_field(detail).grid.grid_rows)
								{
									for (var i = 0; i < cur_frm.get_field(detail).grid.grid_rows.length; i++) {

										cur_frm.get_field(detail).grid.grid_rows[i].row_check[0].children[0].checked = false;
									}
								}

								cur_frm.get_field(detail).grid.grid_rows[idx_to-1].row_check[0].children[0].checked = true;
							}


							if (detail == silicon_ioi.ioiScanning.field_detail1) {
								document.getElementById('sb_main_scan_detail_row_idx').value = idx_to.toString();
							} else if (detail == silicon_ioi.ioiScanning.field_detail2) {
								document.getElementById('sb_main_scan_detail2_row_idx').value = idx_to.toString();
							} else if (detail == silicon_ioi.ioiScanning.field_detail3) {
								document.getElementById('sb_main_scan_detail3_row_idx').value = idx_to.toString();
							} else if (detail == silicon_ioi.ioiScanning.field_detail4) {
								document.getElementById('sb_main_scan_detail4_row_idx').value = idx_to.toString();
							} else if (detail == silicon_ioi.ioiScanning.field_detail5) {
								document.getElementById('sb_main_scan_detail5_row_idx').value = idx_to.toString();
							} else if (detail == silicon_ioi.ioiScanning.field_detail6) {
								document.getElementById('sb_main_scan_detail6_row_idx').value = idx_to.toString();
							}

						}else
						{
							document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Row numbrer not found") + '</font>';
							return;
						}
					}else {

						document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Row numbrer not found") + '</font>';
						return;
					}
				}
			}
		}
	}


	// ***************************************************************************************************************************************
	// Function $-DUPLN : duplicate row in detail
	// ***************************************************************************************************************************************

	static duplicate_line(v)
	{
		let detail = '';
		let tiret_idx = 6;

		if (v.trim().toUpperCase().substring(0, 7) == '-DUPLN6') {
			detail = silicon_ioi.ioiScanning.field_detail6;
			tiret_idx = 7;
		} else if (v.trim().toUpperCase().substring(0, 7) == '-DUPLN5') {
			detail = silicon_ioi.ioiScanning.field_detail5;
			tiret_idx = 7;
		} else if (v.trim().toUpperCase().substring(0, 7) == '-DUPLN4') {
			detail = silicon_ioi.ioiScanning.field_detail4;
			tiret_idx = 7;
		} else if (v.trim().toUpperCase().substring(0, 7) == '-DUPLN3') {
			detail = silicon_ioi.ioiScanning.field_detail3;
			tiret_idx = 7;
		} else if (v.trim().toUpperCase().substring(0, 7) == '-DUPLN2') {
			detail = silicon_ioi.ioiScanning.field_detail2;
			tiret_idx = 7;
		} else if (v.trim().toUpperCase().substring(0, 6) == '-DUPLN') {
			detail = silicon_ioi.ioiScanning.field_detail1;
			tiret_idx = 6;
		}

		if (silicon_ioi.ioiScanning.container.toUpperCase() != 'FORM') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Function not available here") + '</font>';
			return;
		}

		if (v[tiret_idx] != '-') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Line number is missing") + '</font>';
			return;
		}

		if (detail.trim() == '') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Fill detail field in ioi Scan Settings") + '</font>';
			return;
		}

		if (cur_frm.fields_dict[detail]) {

			let can_duplicate= true;

			if (cur_frm.fields_dict['ioistatus']) {
				if (cur_frm.doc.ioistatus != 0) {
					can_duplicate = false;
				}else{
					if (cur_frm.doctype == 'ioi Sales Delivery') {

						if (cur_frm.doc.locked_by_credit_control == 1) {
							can_duplicate = false;
							document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document has to be signed (credit control)") + '</font>';
							return;
						}
					}
				}
			}

			if ((can_duplicate) && (cur_frm.fields_dict[detail].grid.display_status.toUpperCase() != 'WRITE'))
			{
				can_duplicate = false;
			}



			if (can_duplicate) {

				let s = v.trim().toUpperCase().substring(tiret_idx + 1, v.length).trim();

				let idx = s.trim();

				if (idx.trim() == '')
				{
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Row number is missing") + '</font>';
					return;

				}

				let n = parseInt(idx);

				if (isNaN(n)) {
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Row number is invalid") + '</font>';
					return;
				}

				if (idx.trim() != '') {

					if (cur_frm.fields_dict[detail].grid.grid_rows[idx-1])
					{

						let new_item = cur_frm.fields_dict[detail].grid.add_new_row();

						let item = locals[cur_frm.fields_dict[detail].grid.doctype][cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].doc.name];

						silicon_ioi.ioiScanning.sleep_static(200).then(() => {


							cur_frm.fields_dict[detail].grid.duplicate_row(new_item, item);

							cur_frm.fields_dict[detail].grid.refresh();

							cur_frm.fields_dict[detail].grid.grid_pagination.go_to_page(cur_frm.fields_dict[detail].grid.grid_pagination.total_pages);



							if (cur_frm.get_field(detail).grid.grid_rows[new_item.idx - 1]) {


								if (cur_frm.get_field(detail).grid) {
									if (cur_frm.get_field(detail).grid.grid_rows)
									{
										for (var i = 0; i < cur_frm.get_field(detail).grid.grid_rows.length; i++) {

											cur_frm.get_field(detail).grid.grid_rows[i].row_check[0].children[0].checked = false;
										}
									}

									silicon_ioi.ioiScanning.sleep_static(200).then(() => {
										cur_frm.fields_dict[detail].grid.grid_rows[new_item.idx-1].row_check[0].children[0].checked = true;
									});
								}


								if (detail == silicon_ioi.ioiScanning.field_detail1) {
									document.getElementById('sb_main_scan_detail_row_idx').value = new_item.idx.toString();
								} else if (detail == silicon_ioi.ioiScanning.field_detail2) {
									document.getElementById('sb_main_scan_detail2_row_idx').value = new_item.idx.toString();
								} else if (detail == silicon_ioi.ioiScanning.field_detail3) {
									document.getElementById('sb_main_scan_detail3_row_idx').value = new_item.idx.toString();
								} else if (detail == silicon_ioi.ioiScanning.field_detail4) {
									document.getElementById('sb_main_scan_detail4_row_idx').value = new_item.idx.toString();
								} else if (detail == silicon_ioi.ioiScanning.field_detail5) {
									document.getElementById('sb_main_scan_detail5_row_idx').value = new_item.idx.toString();
								} else if (detail == silicon_ioi.ioiScanning.field_detail6) {
									document.getElementById('sb_main_scan_detail6_row_idx').value = new_item.idx.toString();
								}

							}
						});

					}else {

						document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Row numbrer not found") + '</font>';
						return;
					}
				}
			}
		}
	}

	// ***************************************************************************************************************************************
	// Function $-STA : Change document status (ioistatus)
	// ***************************************************************************************************************************************

	static change_ioistatus(v)
	{
		if (silicon_ioi.ioiScanning.container.toUpperCase() != 'FORM') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Function not available here") + '</font>';
			return;
		}

		if (cur_frm.is_dirty()) {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Save document before") + '</font>';
			return;
		}

		if (!cur_frm.fields_dict['ioistatus']) {
			return;
		}


		if (v[4] != '-') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Status number is missing") + '</font>';
			return;
		}


		v = v.substring(5, v.length).trim();

		if (v == '') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Status number is missing") + '</font>';
			return;
		}


		if (v != cur_frm.doc.ioistatus) {

			if (document.getElementById('buttons_disabled').value == 0) {
				document.getElementById('buttons_disabled').value = 1;
				document.getElementById("splash_screen").style.height = screen.height.toString() + 'px';
				document.getElementById("splash_screen").style.verticalAlign = 'middle';
				document.getElementById('splash_screen').style.display = 'block';
				silicon_ioi.doctype.ioiDocumentStatus.refresh_msg(1);


				if (cur_frm.is_dirty()) {
					document.getElementById('splash_screen').style.visibility = 'hidden';
					document.getElementById('buttons_disabled').value = 0;
					frappe.msgprint({ title: __("Message"), message: __("Please, save your modifications before"), indicator: "red" });
					raise;
				}

				if (cur_frm.doctype.toUpperCase() == 'IOI STAFF') {
					silicon_ioi.doctype.ioiDocumentStatus.ioi_staff_change_status(v, cur_frm);
				}

				let path_document_change_status = 'silicon_ioi.common.document_change_status';
				let method = path_document_change_status + '.change_document_status';

				let can_change = false;

				frappe.call({
					method: method,
					args: { "doctype": cur_frm.doctype, "name": cur_frm.doc.name, "from_status": cur_frm.doc.ioistatus, "to_status": v, "silent_mode": true },
					async: false,
					callback: function (r) {

						if (r.message.error != '0') {
							document.getElementById('buttons_disabled').value = 0;

							if ((cur_frm.doctype.toUpperCase() != 'IOI SALES QUOTE') && (cur_frm.doctype.toUpperCase() != 'IOI SALES ORDER') &&
								(cur_frm.doctype.toUpperCase() != 'IOI SALES DELIVERY') && (cur_frm.doctype.toUpperCase() != 'IOI SALES INVOICE') &&
								(cur_frm.doctype.toUpperCase() != 'IOI PURCHASES PRICE REQUEST') && (cur_frm.doctype.toUpperCase() != 'IOI PURCHASES ORDER') &&
								(cur_frm.doctype.toUpperCase() != 'IOI PURCHASES RECEIPT') && (cur_frm.doctype.toUpperCase() != 'IOI PURCHASES INVOICE') &&
								(cur_frm.doctype.toUpperCase() != 'IOI CUSTOMER') && (cur_frm.doctype.toUpperCase() != 'IOI SUPPLIER') && (cur_frm.doctype.toUpperCase() != 'IOI STAFF') &&
								(cur_frm.doctype.toUpperCase() != 'IOI ITEM') && (cur_frm.doctype.toUpperCase() != 'IOI SALES FTG DISCOUNT') && (cur_frm.doctype.toUpperCase() != 'IOI DOSSIER') &&
								(cur_frm.doctype.toUpperCase() != 'IOI PURCHASES FTG DISCOUNT') && (cur_frm.doctype.toUpperCase() != 'IOI STOCK TRANSFER') && (cur_frm.doctype.toUpperCase() != 'IOI STOCK OUTPUT') &&
								(cur_frm.doctype.toUpperCase() != 'IOI STOCK ENTRY')) {
								if (r.message.error == '2') {
									frappe.msgprint({ title: __("Message"), message: r.message.error_message, indicator: "red" });
								} else {
									frappe.msgprint({ title: __("Message"), message: r.message.error_message, indicator: "orange" });
								}


								silicon_ioi.doctype.ioiDocumentStatus.sleep_static(1000).then(() => {

									document.getElementById('splash_screen').style.visibility = 'hidden';
									// Need to force a reload if something has changed in the record by the "change status" process
									window.location.reload();
								});
							} else {
								can_change = true;
							}
						} else {
							can_change = true;
						}
					}
				});


				if (can_change) {
					window.location.reload();
					silicon_ioi.ioiScanning.setfocus();
				} else {
					silicon_ioi.ioiScanning.setfocus();
				}
			}
		}
	}

	// ***************************************************************************************************************************************
	// Function $-UST : Change user status (ioiuserstatus)
	// ***************************************************************************************************************************************

	static change_user_status(v)
	{
		if (silicon_ioi.ioiScanning.container.toUpperCase() != 'FORM') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Function  not available here") + '</font>';
			return;
		}

		if (cur_frm.is_dirty()) {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Save document before") + '</font>';
		}

		if (!cur_frm.fields_dict['ioiuserstatus']) {
			return;
		}


		if (v[4] != '-') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Status number is missing") + '</font>';
			return;
		}

		v = v.substring(5, v.length).trim();

		if (v == '') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Status number is missing") + '</font>';
			return;
		}

		let cur_user_status = 0;

		if (cur_frm.doc.ioiuserstatus) {
			cur_user_status = cur_frm.doc.ioiuserstatus;
		}


		if (v != cur_user_status) {

			let path_module_user_status = 'silicon_ioi.ioi_configuration.doctype.ioi_scan_settings_user_status.ioi_scan_settings_user_status';
			let method = path_module_user_status + '.ioi_scan_settings_user_status_change_status';

			let can_change = false;

			frappe.call({
				method: method,
				args: { "doctype": cur_frm.doctype, "name": cur_frm.doc.name, "from_status": cur_user_status, "to_status": v, "silent_mode": true },
				async: false,
				callback: function (r) {
					can_change = true;
				}
			});

			if (can_change) {
				window.location.reload();
				silicon_ioi.ioiScanning.setfocus();
			} else {
				silicon_ioi.ioiScanning.setfocus();
			}
		}
	}


	// ***************************************************************************************************************************************
	// Function $-MFD : Set value to field (master)
	// ***************************************************************************************************************************************

	static set_value_in_field(v)
	{
		v = v.substring(4, v.length);

		if (silicon_ioi.ioiScanning.container.toUpperCase() != 'FORM') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Function not available here") + '</font>';
			return;
		}

		if (cur_frm.fields_dict['ioistatus']) {
			if (cur_frm.doc.ioistatus != 0) {
				document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document is not editable") + '</font>';
				return;
			}else{

				if (cur_frm.doctype == 'ioi Sales Delivery') {

					if (cur_frm.doc.locked_by_credit_control == 1) {
						document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document has to be signed (credit control)") + '</font>';
						return;
					}
				}
			}
		}

		if (cur_frm.fields_dict['docstatus']) {
			if (cur_frm.doc.docstatus == 1) {
				document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document is not editable") + '</font>';
				return;
			}
		}


		if (v.indexOf('-') == -1) {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Initials field name is missing") + '</font>';
			return;
		}

		v = v.substring(v.indexOf('-')+1, v.length);

		if (v.trim() == '') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Initials field name is missing") + '</font>';
			return;
		}

		if (v.indexOf('-') == -1) {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Value is missing") + '</font>';
			return;
		}


		let fn = v.substring(0, v.indexOf('-'));
		v = v.substring(v.indexOf('-')+1, v.length);

		v = v.replaceAll('-', ' • ');

		let method = 'silicon_ioi.ioi_configuration.doctype.ioi_scan_settings.ioi_scan_settings.ioi_scan_settings_get_field';

		let fieldname = '';
		let fieldtype = '';

		frappe.call({
			method: method,
			args: { "doctype": cur_frm.doctype, "initials": fn },
			async: false,
			callback: function (r) {

				if (r.message.length != 0)
				{
					fieldname = r.message[0].fieldname;
					fieldtype = r.message[0].fieldtype;
				}

			}
		});

		if (fieldname.trim() == '')
		{
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Initials not found in ioi Scan Settings") + '</font>';
			return;
		}

		if (cur_frm.fields_dict[fieldname].disp_status.toUpperCase() != 'WRITE')
		{
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Field can not be modified") + '</font>';
			return;
		}


		if ((fieldtype == 'CURRENCY') || (fieldtype == 'FLOAT') || (fieldtype == 'PERCENT'))
		{
			v = v.trim();

			let q = parseFloat(v);

			if (isNaN(q)) {
				document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Invalid number") + '</font>';
				return;
			}


		}else if (fieldtype == 'INT')
		{
			v = v.trim();

			let q = parseInt(v);

			if (isNaN(q)) {
				document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Invalid integer") + '</font>';
				return;
			}

		}else if (fieldtype == 'DATE')
		{

		}else if (fieldtype == 'DATETIME')
		{

		}else if (fieldtype == 'TIME')
		{

		}else if (fieldtype == 'DURATION')
		{

		}else if (fieldtype == 'CHECK')
		{
			v = v.trim();

			if ((v != "0") && (v != "1")) {
				document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Value has to be 0 or 1") + '</font>';
				return;
			}

		}else {

		}

		if (!cur_frm.is_dirty()) {
			cur_frm.dirty();
		}

		cur_frm.set_value(fieldname, v);
		cur_frm.refresh_field(fieldname);
	}

	// ***************************************************************************************************************************************
	// Function $-DFD : Set value to field (detail)
	// ***************************************************************************************************************************************

	static set_value_in_field_detail(v)
	{
		let detail = '';
		let tiret_idx = 3;
		let idx = 0;


		if (silicon_ioi.ioiScanning.container.toUpperCase() != 'FORM') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Function not available here") + '</font>';
			return;
		}

		if (cur_frm.fields_dict['ioistatus']) {
			if (cur_frm.doc.ioistatus != 0) {
				document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document is not editable") + '</font>';
				return;
			}else{
				if (cur_frm.doctype == 'ioi Sales Delivery') {

					if (cur_frm.doc.locked_by_credit_control == 1) {
						can_duplicate = false;
						document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document has to be signed (credit control)") + '</font>';
						return;
					}
				}
			}
		}

		if (cur_frm.fields_dict['docstatus']) {
			if (cur_frm.doc.docstatus == 1) {
				document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document is not editable") + '</font>';
				return;
			}
		}


		if (v.trim().toUpperCase().substring(0, 5) == '-DFD6') {

			detail = silicon_ioi.ioiScanning.field_detail6;
			tiret_idx = 5;

		} else if (v.trim().toUpperCase().substring(0, 5) == '-DFD5') {

			detail = silicon_ioi.ioiScanning.field_detail5;
			tiret_idx = 5;

		} else if (v.trim().toUpperCase().substring(0, 5) == '-DFD4') {

			detail = silicon_ioi.ioiScanning.field_detail4;
			tiret_idx = 5;

		} else if (v.trim().toUpperCase().substring(0, 5) == '-DFD3') {

			detail = silicon_ioi.ioiScanning.field_detail3;
			tiret_idx = 5;

		} else if (v.trim().toUpperCase().substring(0, 5) == '-DFD2') {

			detail = silicon_ioi.ioiScanning.field_detail2;
			tiret_idx = 5;

		} else if (v.trim().toUpperCase().substring(0, 4) == '-DFD') {

			detail = silicon_ioi.ioiScanning.field_detail1;
			tiret_idx = 4;

		}



		if (detail.trim() == '') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Fill detail in ioi Scan Settings") + '</font>';
			return;
		}

		if (cur_frm.get_field(detail).grid) {
			if (cur_frm.get_field(detail).grid.grid_rows)
			{
				for (var i = 0; i < cur_frm.get_field(detail).grid.grid_rows.length; i++) {

					if (cur_frm.get_field(detail).grid.grid_rows[i].row_check[0].children[0].checked)
					{
						idx = i + 1;

						if (detail == silicon_ioi.ioiScanning.field_detail1) {
							document.getElementById('sb_main_scan_detail_row_idx').value = idx.toString();
						} else if (detail == silicon_ioi.ioiScanning.field_detail2) {
							document.getElementById('sb_main_scan_detail2_row_idx').value = idx.toString();
						} else if (detail == silicon_ioi.ioiScanning.field_detail3) {
							document.getElementById('sb_main_scan_detail3_row_idx').value = idx.toString();
						} else if (detail == silicon_ioi.ioiScanning.field_detail4) {
							document.getElementById('sb_main_scan_detail4_row_idx').value = idx.toString();
						} else if (detail == silicon_ioi.ioiScanning.field_detail5) {
							document.getElementById('sb_main_scan_detail5_row_idx').value = idx.toString();
						} else if (detail == silicon_ioi.ioiScanning.field_detail6) {
							document.getElementById('sb_main_scan_detail6_row_idx').value = idx.toString();
						}

						break;
					}

				}
			}
		}

		if (idx == 0)
		{
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Select a detail line before") + '</font>';
			return;

		}

		if (v[tiret_idx] != '-') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Initials field name is missing") + '</font>';
			return;
		}


		if (v.indexOf('-') == -1) {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Initials field name is missing") + '</font>';
			return;
		}

		v = v.substring(v.indexOf('-')+1, v.length);

		if (v.trim() == '') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Initials field name is missing") + '</font>';
			return;
		}

		if (v.indexOf('-') == -1) {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Value is missing") + '</font>';
			return;
		}


		v = v.substring(v.indexOf('-')+1, v.length);
		let fn = v.substring(0, v.indexOf('-'));

		v = v.substring(v.indexOf('-')+1, v.length);

		v = v.replaceAll('/', ' • ');

		let method = 'silicon_ioi.ioi_configuration.doctype.ioi_scan_settings.ioi_scan_settings.ioi_scan_settings_scan_field_detail';

		let fieldname = '';
		let fieldtype = '';

		frappe.call({
			method: method,
			args: { "doctype": cur_frm.doctype, "detail": detail, "initials": fn },
			async: false,
			callback: function (r) {

				if (r.message.length != 0)
				{
					fieldname = r.message[0].fieldname;
					fieldtype = r.message[0].fieldtype;
				}

			}
		});



		if (fieldname.trim() == '')
		{
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Initials not found in ioi Scan Settings") + '</font>';
			return;
		}


		if ((fieldtype == 'CURRENCY') || (fieldtype == 'FLOAT') || (fieldtype == 'PERCENT'))
		{
			v = v.trim();

			let q = parseFloat(v);

			if (isNaN(q)) {
				document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Invalid number") + '</font>';
				return;
			}


		}else if (fieldtype == 'INT')
		{
			v = v.trim();

			let q = parseInt(v);

			if (isNaN(q)) {
				document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Invalid integer") + '</font>';
				return;
			}

		}else if (fieldtype == 'DATE')
		{

		}else if (fieldtype == 'DATETIME')
		{

		}else if (fieldtype == 'TIME')
		{

		}else if (fieldtype == 'DURATION')
		{

		}else if (fieldtype == 'CHECK')
		{
			v = v.trim();

			if ((v != "0") && (v != "1")) {
				document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Value has to be 0 or 1") + '</font>';
				return;
			}

		}else {

		}

		if (!cur_frm.is_dirty()) {
			cur_frm.dirty();
		}

		cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].doc[fieldname] = v;
		cur_frm.refresh_field(detail);

		silicon_ioi.ioiScanning.sleep_static(200).then(() => {
			let script_manager = cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].frm.script_manager;

			script_manager.trigger(fieldname, cur_frm.fields_dict[detail].grid.doctype,	cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].doc.name);
		});

		cur_frm.refresh_field(detail);

	}



	// ***************************************************************************************************************************************
	// Set focus
	// ***************************************************************************************************************************************
	static setfocus() {

		if (document.getElementById('sb_main_scan_input')) {

			if (document.getElementById('sb_main_scan_check')) {

				if (document.getElementById('sb_main_scan_check').checked) {

					if (document.activeElement != document.getElementById('sb_main_scan_input')) {

						document.getElementById('sb_main_scan_input').focus();
					}
				}

				silicon_ioi.ioiScanning.sleep_static(200).then(() => {

					silicon_ioi.ioiScanning.setfocus();
				});

			}
		}
	}

	// ***************************************************************************************************************************************
	// Scan Item or price
	// ***************************************************************************************************************************************
	static scan_item_or_price(what, detail, pk, duplicate, destination_detail, destination_field, qt_scan, scan_mode,
							  price_select_type, price_catalog_customer_supplier_id, price_item_id, price_condition_id,
							  price_unit_id, price_currency_id)
	{


		let idx = 0;

		let qty_field = '';

		if (cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') {
			qty_field = 'quoted_qty';
		}else if (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
			qty_field = 'ordered_qty';
		}else if (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
			qty_field = 'delivered_qty';
		}else if (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
			qty_field = 'invoiced_qty';
		}else if (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') {
			qty_field = 'quoted_qty';
		}else if (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') {
			qty_field = 'ordered_qty';
		}else if (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') {
			qty_field = 'delivered_qty';
		}else if (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE') {
			qty_field = 'invoiced_qty';
		}else if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER') {
			qty_field = 'transferred_qty';
		}else if (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') {
			qty_field = 'delivered_qty';
		}else if (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY') {
			qty_field = 'delivered_qty';
		}


		if (!cur_frm.is_dirty()) {
			cur_frm.dirty();
		}

		cur_frm.fields_dict[detail].grid.grid_pagination.go_to_page(cur_frm.fields_dict[detail].grid.grid_pagination.total_pages);

		let d = null;

		let create_new = false;

		if (cur_frm.fields_dict[detail].grid.grid_rows.length == 0)
		{
			create_new = true;

		}else
		{
			if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') 			 || (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') 	 || (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') 	|| (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE') ||
				(cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT')  || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE') ||
				(cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')			 || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT')	 || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY'))
			{
				if (scan_mode == 1)
				{
					create_new = true;
				}else
				{
					if (cur_frm.fields_dict[detail].grid.grid_rows[cur_frm.fields_dict[detail].grid.grid_rows.length-1].doc[destination_field] != pk)
					{
						create_new = true;
					}else
					{

						if ((what == 'SALES_PRICE') || (what == 'PURCHASES_PRICE'))
						{
							if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE'))
							{
								if (price_condition_id != cur_frm.fields_dict[detail].grid.grid_rows[cur_frm.fields_dict[detail].grid.grid_rows.length-1].doc['sales_condition_id'])
								{
									create_new = true;

								}else if (price_unit_id != cur_frm.fields_dict[detail].grid.grid_rows[cur_frm.fields_dict[detail].grid.grid_rows.length-1].doc['unit_id'])
								{
									create_new = true;
								}

							}else if ((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE'))
							{
								if (price_condition_id != cur_frm.fields_dict[detail].grid.grid_rows[cur_frm.fields_dict[detail].grid.grid_rows.length-1].doc['purchases_condition_id'])
								{
									create_new = true;

								}else if (price_unit_id != cur_frm.fields_dict[detail].grid.grid_rows[cur_frm.fields_dict[detail].grid.grid_rows.length-1].doc['unit_id'])
								{
									create_new = true;
								}
							}

						}
					}
				}
			}
		}


		if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') 			 || (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') 	 || (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')    || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE') ||
			(cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE') ||
			(cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')			 || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT')	 || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY'))
		{

			if ((price_currency_id) && (price_currency_id.trim() != ''))
			{
				if (price_currency_id != cur_frm.doc.currency_id)
				{
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Price currency is not applicable here !") + '</font>';
					return;
				}
			}
		}

		if ((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY'))
		{
			create_new = false;

			if ((!cur_frm.doc[detail]) || ((cur_frm.doc[detail]) && (cur_frm.doc[detail].length == 0)))
			{
				if (cur_frm.doc.qty_to_prepare_mode == 1)
				{
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Can not add new line (Qty to prepare)") + '</font>';
					return;
				}else
				{
					create_new = false;
				}
			}

			if (!create_new)
			{
				create_new = true;
				let can_update_detail = false;
				let current_idx = -1;

				for(var j = 0; j < cur_frm.doc[detail].length; j++)
				{
					if ((cur_frm.doc[detail][j].item_id) && (cur_frm.doc[detail][j].item_id.trim() != ''))
					{
						if (cur_frm.doc[detail][j].item_id == pk)
						{

							if (cur_frm.doc.qty_to_prepare_mode == 1)
							{
								if (parseFloat(cur_frm.doc[detail][j].qty_to_prepare) != 0)
								{
									if (parseFloat(cur_frm.doc[detail][j].qty_to_prepare) > 0)
									{
										if ((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY'))
										{
											if (parseFloat(cur_frm.doc[detail][j].delivered_qty) < parseFloat(cur_frm.doc[detail][j].qty_to_prepare))
											{
												current_idx = cur_frm.doc[detail][j].idx;
												create_new = false;
												can_update_detail = true;
												break;
											}
										}else
										{
											if (parseFloat(cur_frm.doc[detail][j].transferred_qty) < parseFloat(cur_frm.doc[detail][j].qty_to_prepare))
											{
												current_idx = cur_frm.doc[detail][j].idx;
												create_new = false;
												can_update_detail = true;
												break;
											}

										}
									}else
									{
										if ((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY'))
										{
											if (parseFloat(cur_frm.doc[detail][j].delivered_qty) > parseFloat(cur_frm.doc[detail][j].qty_to_prepare))
											{
												current_idx = cur_frm.doc[detail][j].idx;
												create_new = false;
												can_update_detail = true;
												break;
											}
										}else
										{
											if (parseFloat(cur_frm.doc[detail][j].transferred_qty) > parseFloat(cur_frm.doc[detail][j].qty_to_prepare))
											{
												current_idx = cur_frm.doc[detail][j].idx;
												create_new = false;
												can_update_detail = true;
												break;
											}

										}
									}
								}
							}else
							{
								current_idx = cur_frm.doc[detail][j].idx;
								create_new = false;
								break;
							}
						}
					}
				}

				if (cur_frm.doc.qty_to_prepare_mode != 1)
				{
					can_update_detail = true;
				}else
				{
					if (!can_update_detail)
					{
						document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Can not add new line (Qty to prepare)") + '</font>';
						return;
					}
				}

				if (can_update_detail)
				{
					let qtyfield = 'delivered_qty';


					if (create_new)
					{

						if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
						{
							if ((!cur_frm.doc.from_warehouse_id) || ((cur_frm.doc.from_warehouse_id) && (cur_frm.doc.from_warehouse_id.trim() == '')))
							{
								document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Fill the warehouse, please") + '</font>';
								return;
							}
						}else{
							if ((!cur_frm.doc.warehouse_id) || ((cur_frm.doc.warehouse_id) && (cur_frm.doc.warehouse_id.trim() == '')))
							{
								document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Fill the warehouse, please") + '</font>';
								return;
							}
						}


						let d = cur_frm.fields_dict[detail].grid.add_new_row();

						if (d.idx) {
							if (detail == silicon_ioi.ioiScanning.field_detail1) {
								document.getElementById('sb_main_scan_detail_row_idx').value = d.idx.toString();
							} else if (detail == silicon_ioi.ioiScanning.field_detail2) {
								document.getElementById('sb_main_scan_detail2_row_idx').value = d.idx.toString();
							} else if (detail == silicon_ioi.ioiScanning.field_detail3) {
								document.getElementById('sb_main_scan_detail3_row_idx').value = d.idx.toString();
							} else if (detail == silicon_ioi.ioiScanning.field_detail4) {
								document.getElementById('sb_main_scan_detail4_row_idx').value = d.idx.toString();
							} else if (detail == silicon_ioi.ioiScanning.field_detail5) {
								document.getElementById('sb_main_scan_detail5_row_idx').value = d.idx.toString();
							} else if (detail == silicon_ioi.ioiScanning.field_detail6) {
								document.getElementById('sb_main_scan_detail6_row_idx').value = d.idx.toString();
							}


							for (var k = 0; k < cur_frm.fields_dict[detail].grid.grid_rows.length; k++) {

								cur_frm.get_field(detail).grid.grid_rows[k].row_check[0].children[0].checked = false;
							}


							let current_item = pk;
							let qty = qt_scan;


							silicon_ioi.ioiScanning.sleep_static(200).then(() => {

								let script_manager = cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].frm.script_manager;

								// Item
								cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["item_id"] = current_item;
								script_manager.trigger("item_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
								cur_frm.refresh_field(detail);


								// Qty
								cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc[qtyfield] = qty;
								script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
								cur_frm.refresh_field(detail);

								silicon_ioi.ioiScanning.sleep_static(500).then(() => {
									cur_frm.fields_dict[detail].grid.grid_rows[d.idx-1].row_check[0].children[0].checked = true;
								});

							});

						}


					}else
					{

						if (detail == silicon_ioi.ioiScanning.field_detail1) {
							document.getElementById('sb_main_scan_detail_row_idx').value = current_idx.toString();
						} else if (detail == silicon_ioi.ioiScanning.field_detail2) {
							document.getElementById('sb_main_scan_detail2_row_idx').value = current_idx.toString();
						} else if (detail == silicon_ioi.ioiScanning.field_detail3) {
							document.getElementById('sb_main_scan_detail3_row_idx').value = current_idx.toString();
						} else if (detail == silicon_ioi.ioiScanning.field_detail4) {
							document.getElementById('sb_main_scan_detail4_row_idx').value = current_idx.toString();
						} else if (detail == silicon_ioi.ioiScanning.field_detail5) {
							document.getElementById('sb_main_scan_detail5_row_idx').value = current_idx.toString();
						} else if (detail == silicon_ioi.ioiScanning.field_detail6) {
							document.getElementById('sb_main_scan_detail6_row_idx').value = current_idx.toString();
						}


						for (var k = 0; k < cur_frm.fields_dict[detail].grid.grid_rows.length; k++) {

							cur_frm.get_field(detail).grid.grid_rows[k].row_check[0].children[0].checked = false;
						}


						let script_manager = cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].frm.script_manager;


						let qty = qt_scan;

						create_new = false;

						let qt_diff = 0;

						if (cur_frm.doc.qty_to_prepare_mode == 1)
						{
							if (parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare) != 0)
							{
								let doc_qty = 0;

								if ((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY'))
								{
									doc_qty = cur_frm.doc[detail][current_idx-1].delivered_qty;
								}else
								{
									doc_qty = cur_frm.doc[detail][current_idx-1].transferred_qty;
								}

								if (parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare) > 0)
								{
									if (parseFloat(doc_qty) < parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare))
									{
										qt_diff = parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare) - parseFloat(doc_qty);

										if (parseFloat(qty) <= parseFloat(qt_diff))
										{
											// Qty
											cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield] = parseFloat(cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield]) + parseFloat(qty);
											script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
											cur_frm.refresh_field(detail);

											silicon_ioi.ioiScanning.sleep_static(500).then(() => {
												cur_frm.fields_dict[detail].grid.grid_rows[current_idx-1].row_check[0].children[0].checked = true;
											});


											qty = 0;


										}else
										{

											// Qty
											cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield] = parseFloat(cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield]) + parseFloat(qt_diff);
											script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
											cur_frm.refresh_field(detail);

											qty = qty - qt_diff;

										}
									}
								}else
								{
									if (parseFloat(doc_qty) > parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare))
									{

										qt_diff = parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare) - parseFloat(doc_qty);

										if (parseFloat(qty) >= parseFloat(qt_diff))
										{
											// Qty
											cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield] = parseFloat(cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield]) + parseFloat(qty);
											script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
											cur_frm.refresh_field(detail);

											silicon_ioi.ioiScanning.sleep_static(500).then(() => {
												cur_frm.fields_dict[detail].grid.grid_rows[current_idx-1].row_check[0].children[0].checked = true;
											});

											qty = 0;

										}else
										{
											// Qty
											cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield] = parseFloat(cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield]) + parseFloat(qt_diff);
											script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
											cur_frm.refresh_field(detail);

											qty = qty - qt_diff;
										}
									}
								}
							}
						}else
						{

							// Qty
							cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield] = parseFloat(cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield]) + parseFloat(qty);
							script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
							cur_frm.refresh_field(detail);

							silicon_ioi.ioiScanning.sleep_static(500).then(() => {
								cur_frm.fields_dict[detail].grid.grid_rows[current_idx-1].row_check[0].children[0].checked = true;
							});

							qty = 0;


						}



						if (parseFloat(qty) != 0)
						{

							for (var k = 0; k < cur_frm.fields_dict[detail].grid.grid_rows.length; k++) {

								cur_frm.get_field(detail).grid.grid_rows[k].row_check[0].children[0].checked = false;
							}


							while (parseFloat(qty) != 0)
							{
								current_idx = -1;
								let last_idx_found = -1;

								for(var j = 0; j < cur_frm.doc[detail].length; j++)
								{
									if ((cur_frm.doc[detail][j].item_id) && (cur_frm.doc[detail][j].item_id.trim() != ''))
									{
										if (cur_frm.doc[detail][j].item_id == pk)
										{
											last_idx_found = cur_frm.doc[detail][j].idx;;

											if (parseFloat(cur_frm.doc[detail][j].qty_to_prepare) != 0)
											{
												if (parseFloat(cur_frm.doc[detail][j].qty_to_prepare) > 0)
												{

													if ((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY'))
													{
														if (parseFloat(cur_frm.doc[detail][j].delivered_qty) < parseFloat(cur_frm.doc[detail][j].qty_to_prepare))
														{
															current_idx = cur_frm.doc[detail][j].idx;
															break;
														}
													}else{
														if (parseFloat(cur_frm.doc[detail][j].transferred_qty) < parseFloat(cur_frm.doc[detail][j].qty_to_prepare))
														{
															current_idx = cur_frm.doc[detail][j].idx;
															break;
														}

													}
												}else
												{
													if ((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY'))
													{
														if (parseFloat(cur_frm.doc[detail][j].delivered_qty) > parseFloat(cur_frm.doc[detail][j].qty_to_prepare))
														{
															current_idx = cur_frm.doc[detail][j].idx;
															break;
														}
													}else
													{
														if (parseFloat(cur_frm.doc[detail][j].transferred_qty) > parseFloat(cur_frm.doc[detail][j].qty_to_prepare))
														{
															current_idx = cur_frm.doc[detail][j].idx;
															break;
														}
													}

												}
											}
										}
									}
								}


								if (current_idx == -1)
								{
									qty = 0;

									silicon_ioi.ioiScanning.sleep_static(500).then(() => {
										cur_frm.fields_dict[detail].grid.grid_rows[last_idx_found-1].row_check[0].children[0].checked = true;
									});


								}else
								{
									script_manager = cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].frm.script_manager;

									let doc_qty = 0;

									if ((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY'))
									{
										doc_qty = cur_frm.doc[detail][current_idx-1].delivered_qty;
									}else
									{
										doc_qty = cur_frm.doc[detail][current_idx-1].transferred_qty;
									}


									if (parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare) > 0)
									{
										if (parseFloat(doc_qty) < parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare))
										{
											qt_diff = parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare) - parseFloat(doc_qty);

											if (parseFloat(qty) <= parseFloat(qt_diff))
											{
												// Qty
												cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield] = parseFloat(cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield]) + parseFloat(qty);
												script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
												cur_frm.refresh_field(detail);

												silicon_ioi.ioiScanning.sleep_static(500).then(() => {
													cur_frm.fields_dict[detail].grid.grid_rows[current_idx-1].row_check[0].children[0].checked = true;
												});


												qty = 0;


											}else
											{

												// Qty
												cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield] = parseFloat(cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield]) + parseFloat(qt_diff);
												script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
												cur_frm.refresh_field(detail);

												qty = qty - qt_diff;

											}
										}
									}else
									{
										if (parseFloat(doc_qty) > parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare))
										{

											qt_diff = parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare) - parseFloat(doc_qty);

											if (parseFloat(qty) >= parseFloat(qt_diff))
											{
												// Qty
												cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield] = parseFloat(cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield]) + parseFloat(qty);
												script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
												cur_frm.refresh_field(detail);

												silicon_ioi.ioiScanning.sleep_static(500).then(() => {
													cur_frm.fields_dict[detail].grid.grid_rows[current_idx-1].row_check[0].children[0].checked = true;
												});

												qty = 0;

											}else
											{
												// Qty
												cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield] = parseFloat(cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield]) + parseFloat(qt_diff);
												script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
												cur_frm.refresh_field(detail);

												qty = qty - qt_diff;
											}
										}
									}


								}
							}
						}
					}
				}
			}

		}else
		{

			if (create_new)
			{
				d = cur_frm.fields_dict[detail].grid.add_new_row();
			}


			if (d) {
				if (d.idx) {
					if (detail == silicon_ioi.ioiScanning.field_detail1) {
						document.getElementById('sb_main_scan_detail_row_idx').value = d.idx.toString();
					} else if (detail == silicon_ioi.ioiScanning.field_detail2) {
						document.getElementById('sb_main_scan_detail2_row_idx').value = d.idx.toString();
					} else if (detail == silicon_ioi.ioiScanning.field_detail3) {
						document.getElementById('sb_main_scan_detail3_row_idx').value = d.idx.toString();
					} else if (detail == silicon_ioi.ioiScanning.field_detail4) {
						document.getElementById('sb_main_scan_detail4_row_idx').value = d.idx.toString();
					} else if (detail == silicon_ioi.ioiScanning.field_detail5) {
						document.getElementById('sb_main_scan_detail5_row_idx').value = d.idx.toString();
					} else if (detail == silicon_ioi.ioiScanning.field_detail6) {
						document.getElementById('sb_main_scan_detail6_row_idx').value = d.idx.toString();
					}


					for (var i = 0; i < cur_frm.fields_dict[detail].grid.grid_rows.length; i++) {

							cur_frm.get_field(detail).grid.grid_rows[i].row_check[0].children[0].checked = false;
					}


					silicon_ioi.ioiScanning.sleep_static(200).then(() => {

						let script_manager = cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].frm.script_manager;

						cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc[destination_field] = pk;

						script_manager.trigger(destination_field, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);

						cur_frm.refresh_field(detail);

						if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') 			 || (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') 	 || (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')    || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE') ||
							(cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE') ||
							(cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER') 		 || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT')	 || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY'))
						{

							if (cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc[qty_field] == 0)
							{
								cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc[qty_field] = qt_scan;
							}else
							{
								cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc[qty_field] = cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc[qty_field] + qt_scan;
							}

							script_manager.trigger(qty_field, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);


							cur_frm.refresh_field(detail);

							silicon_ioi.ioiScanning.sleep_static(500).then(() => {
								cur_frm.fields_dict[detail].grid.grid_rows[d.idx-1].row_check[0].children[0].checked = true;
							});

						}
					});
				}
			}else
			{
				if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') 			 || (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') 	 || (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')    || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE') ||
					(cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE') ||
					(cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')			 || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT')	 || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY'))
				{

					for (var i = 0; i < cur_frm.fields_dict[detail].grid.grid_rows.length; i++) {

						cur_frm.get_field(detail).grid.grid_rows[i].row_check[0].children[0].checked = false;
					}


					silicon_ioi.ioiScanning.sleep_static(200).then(() => {

						let idx = cur_frm.fields_dict[detail].grid.grid_rows.length;

						let script_manager = cur_frm.fields_dict[detail].grid.grid_rows[idx-1].frm.script_manager;

						cur_frm.fields_dict[detail].grid.grid_rows[idx-1].doc[qty_field] = parseFloat(cur_frm.fields_dict[detail].grid.grid_rows[idx-1].doc[qty_field]) + qt_scan;

						script_manager.trigger(qty_field, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].doc.name);


						cur_frm.refresh_field(detail);

						silicon_ioi.ioiScanning.sleep_static(500).then(() => {
							cur_frm.fields_dict[detail].grid.grid_rows[idx-1].row_check[0].children[0].checked = true;
						});
					});
				}
			}
		}
	}



	// ***************************************************************************************************************************************
	// Scan Manufacturer Catalog
	// ***************************************************************************************************************************************
	static scan_manufacturer_catalog(what, detail, pk, duplicate, destination_detail, destination_field, qt_scan, scan_mode,
							  		 price_select_type, price_catalog_customer_supplier_id, price_item_id, price_condition_id,
							  		 price_unit_id, price_currency_id)
	{
		let idx = 0;

		let qty_field = '';

		if (cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') {
			qty_field = 'quoted_qty';
		}else if (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
			qty_field = 'ordered_qty';
		}else if (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
			qty_field = 'delivered_qty';
		}else if (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
			qty_field = 'invoiced_qty';
		}else if (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') {
			qty_field = 'quoted_qty';
		}else if (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') {
			qty_field = 'ordered_qty';
		}else if (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') {
			qty_field = 'delivered_qty';
		}else if (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE') {
			qty_field = 'invoiced_qty';
		}else if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER') {
			qty_field = 'transferred_qty';
		}else if (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') {
			qty_field = 'delivered_qty';
		}else if (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY') {
			qty_field = 'delivered_qty';
		}


		if (!cur_frm.is_dirty()) {
			cur_frm.dirty();
		}

		cur_frm.fields_dict[detail].grid.grid_pagination.go_to_page(cur_frm.fields_dict[detail].grid.grid_pagination.total_pages);

		let d = null;

		let create_new = false;

		if (cur_frm.fields_dict[detail].grid.grid_rows.length == 0)
		{
			create_new = true;

		}else
		{
			if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') 			 || (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') 	 || (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') 	|| (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE') ||
				(cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT')  || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE') ||
				(cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')			 || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT')	 || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY'))
			{
				if (scan_mode == 1)
				{
					create_new = true;
				}else
				{
					if (cur_frm.fields_dict[detail].grid.grid_rows[cur_frm.fields_dict[detail].grid.grid_rows.length-1].doc[destination_field] != pk)
					{
						create_new = true;
					}else
					{

						if ((what == 'SALES_PRICE') || (what == 'PURCHASES_PRICE'))
						{
							if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE'))
							{
								if (price_condition_id != cur_frm.fields_dict[detail].grid.grid_rows[cur_frm.fields_dict[detail].grid.grid_rows.length-1].doc['sales_condition_id'])
								{
									create_new = true;

								}else if (price_unit_id != cur_frm.fields_dict[detail].grid.grid_rows[cur_frm.fields_dict[detail].grid.grid_rows.length-1].doc['unit_id'])
								{
									create_new = true;
								}

							}else if ((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE'))
							{
								if (price_condition_id != cur_frm.fields_dict[detail].grid.grid_rows[cur_frm.fields_dict[detail].grid.grid_rows.length-1].doc['purchases_condition_id'])
								{
									create_new = true;

								}else if (price_unit_id != cur_frm.fields_dict[detail].grid.grid_rows[cur_frm.fields_dict[detail].grid.grid_rows.length-1].doc['unit_id'])
								{
									create_new = true;
								}
							}

						}
					}
				}
			}
		}


		if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') 			 || (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') 	 || (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')    || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE') ||
			(cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE') ||
			(cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')			 || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT')	 || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY'))
		{

			if ((price_currency_id) && (price_currency_id.trim() != ''))
			{
				if (price_currency_id != cur_frm.doc.currency_id)
				{
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Price currency is not applicable here !") + '</font>';
					return;
				}
			}
		}

		if ((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY'))
		{
			create_new = false;

			if ((!cur_frm.doc[detail]) || ((cur_frm.doc[detail]) && (cur_frm.doc[detail].length == 0)))
			{
				if (cur_frm.doc.qty_to_prepare_mode == 1)
				{
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Can not add new line (Qty to prepare)") + '</font>';
					return;
				}else
				{
					create_new = false;
				}
			}

			if (!create_new)
			{
				create_new = true;
				let can_update_detail = false;
				let current_idx = -1;

				for(var j = 0; j < cur_frm.doc[detail].length; j++)
				{
					if ((cur_frm.doc[detail][j].manufacturer_ref) && (cur_frm.doc[detail][j].manufacturer_ref.trim() != ''))
					{
						if (cur_frm.doc[detail][j].manufacturer_ref == pk)
						{

							if (cur_frm.doc.qty_to_prepare_mode == 1)
							{
								if (parseFloat(cur_frm.doc[detail][j].qty_to_prepare) != 0)
								{
									if (parseFloat(cur_frm.doc[detail][j].qty_to_prepare) > 0)
									{
										if ((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY'))
										{
											if (parseFloat(cur_frm.doc[detail][j].delivered_qty) < parseFloat(cur_frm.doc[detail][j].qty_to_prepare))
											{
												current_idx = cur_frm.doc[detail][j].idx;
												create_new = false;
												can_update_detail = true;
												break;
											}
										}else
										{
											if (parseFloat(cur_frm.doc[detail][j].transferred_qty) < parseFloat(cur_frm.doc[detail][j].qty_to_prepare))
											{
												current_idx = cur_frm.doc[detail][j].idx;
												create_new = false;
												can_update_detail = true;
												break;
											}

										}
									}else
									{
										if ((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY'))
										{
											if (parseFloat(cur_frm.doc[detail][j].delivered_qty) > parseFloat(cur_frm.doc[detail][j].qty_to_prepare))
											{
												current_idx = cur_frm.doc[detail][j].idx;
												create_new = false;
												can_update_detail = true;
												break;
											}
										}else
										{
											if (parseFloat(cur_frm.doc[detail][j].transferred_qty) > parseFloat(cur_frm.doc[detail][j].qty_to_prepare))
											{
												current_idx = cur_frm.doc[detail][j].idx;
												create_new = false;
												can_update_detail = true;
												break;
											}

										}
									}
								}
							}else
							{
								current_idx = cur_frm.doc[detail][j].idx;
								create_new = false;
								break;
							}
						}
					}
				}

				if (cur_frm.doc.qty_to_prepare_mode != 1)
				{
					can_update_detail = true;
				}else
				{
					if (!can_update_detail)
					{
						document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Can not add new line (Qty to prepare)") + '</font>';
						return;
					}
				}

				if (can_update_detail)
				{
					let qtyfield = 'delivered_qty';


					if (create_new)
					{

						if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
						{
							if ((!cur_frm.doc.from_warehouse_id) || ((cur_frm.doc.from_warehouse_id) && (cur_frm.doc.from_warehouse_id.trim() == '')))
							{
								document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Fill the warehouse, please") + '</font>';
								return;
							}
						}else{
							if ((!cur_frm.doc.warehouse_id) || ((cur_frm.doc.warehouse_id) && (cur_frm.doc.warehouse_id.trim() == '')))
							{
								document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Fill the warehouse, please") + '</font>';
								return;
							}
						}


						let d = cur_frm.fields_dict[detail].grid.add_new_row();

						if (d.idx) {
							if (detail == silicon_ioi.ioiScanning.field_detail1) {
								document.getElementById('sb_main_scan_detail_row_idx').value = d.idx.toString();
							} else if (detail == silicon_ioi.ioiScanning.field_detail2) {
								document.getElementById('sb_main_scan_detail2_row_idx').value = d.idx.toString();
							} else if (detail == silicon_ioi.ioiScanning.field_detail3) {
								document.getElementById('sb_main_scan_detail3_row_idx').value = d.idx.toString();
							} else if (detail == silicon_ioi.ioiScanning.field_detail4) {
								document.getElementById('sb_main_scan_detail4_row_idx').value = d.idx.toString();
							} else if (detail == silicon_ioi.ioiScanning.field_detail5) {
								document.getElementById('sb_main_scan_detail5_row_idx').value = d.idx.toString();
							} else if (detail == silicon_ioi.ioiScanning.field_detail6) {
								document.getElementById('sb_main_scan_detail6_row_idx').value = d.idx.toString();
							}


							for (var k = 0; k < cur_frm.fields_dict[detail].grid.grid_rows.length; k++) {

								cur_frm.get_field(detail).grid.grid_rows[k].row_check[0].children[0].checked = false;
							}


							let current_item = pk;
							let qty = qt_scan;


							silicon_ioi.ioiScanning.sleep_static(200).then(() => {

								let script_manager = cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].frm.script_manager;

								// Item
								cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["manufacturer_ref"] = current_item;
								script_manager.trigger("manufacturer_ref", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
								cur_frm.refresh_field(detail);


								// Qty
								cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc[qtyfield] = qty;
								script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
								cur_frm.refresh_field(detail);

								silicon_ioi.ioiScanning.sleep_static(500).then(() => {
									cur_frm.fields_dict[detail].grid.grid_rows[d.idx-1].row_check[0].children[0].checked = true;
								});

							});

						}


					}else
					{

						if (detail == silicon_ioi.ioiScanning.field_detail1) {
							document.getElementById('sb_main_scan_detail_row_idx').value = current_idx.toString();
						} else if (detail == silicon_ioi.ioiScanning.field_detail2) {
							document.getElementById('sb_main_scan_detail2_row_idx').value = current_idx.toString();
						} else if (detail == silicon_ioi.ioiScanning.field_detail3) {
							document.getElementById('sb_main_scan_detail3_row_idx').value = current_idx.toString();
						} else if (detail == silicon_ioi.ioiScanning.field_detail4) {
							document.getElementById('sb_main_scan_detail4_row_idx').value = current_idx.toString();
						} else if (detail == silicon_ioi.ioiScanning.field_detail5) {
							document.getElementById('sb_main_scan_detail5_row_idx').value = current_idx.toString();
						} else if (detail == silicon_ioi.ioiScanning.field_detail6) {
							document.getElementById('sb_main_scan_detail6_row_idx').value = current_idx.toString();
						}


						for (var k = 0; k < cur_frm.fields_dict[detail].grid.grid_rows.length; k++) {

							cur_frm.get_field(detail).grid.grid_rows[k].row_check[0].children[0].checked = false;
						}


						let script_manager = cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].frm.script_manager;


						let qty = qt_scan;

						create_new = false;

						let qt_diff = 0;

						if (cur_frm.doc.qty_to_prepare_mode == 1)
						{
							if (parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare) != 0)
							{
								let doc_qty = 0;

								if ((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY'))
								{
									doc_qty = cur_frm.doc[detail][current_idx-1].delivered_qty;
								}else
								{
									doc_qty = cur_frm.doc[detail][current_idx-1].transferred_qty;
								}

								if (parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare) > 0)
								{
									if (parseFloat(doc_qty) < parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare))
									{
										qt_diff = parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare) - parseFloat(doc_qty);

										if (parseFloat(qty) <= parseFloat(qt_diff))
										{
											// Qty
											cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield] = parseFloat(cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield]) + parseFloat(qty);
											script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
											cur_frm.refresh_field(detail);

											silicon_ioi.ioiScanning.sleep_static(500).then(() => {
												cur_frm.fields_dict[detail].grid.grid_rows[current_idx-1].row_check[0].children[0].checked = true;
											});


											qty = 0;


										}else
										{

											// Qty
											cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield] = parseFloat(cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield]) + parseFloat(qt_diff);
											script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
											cur_frm.refresh_field(detail);

											qty = qty - qt_diff;

										}
									}
								}else
								{
									if (parseFloat(doc_qty) > parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare))
									{

										qt_diff = parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare) - parseFloat(doc_qty);

										if (parseFloat(qty) >= parseFloat(qt_diff))
										{
											// Qty
											cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield] = parseFloat(cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield]) + parseFloat(qty);
											script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
											cur_frm.refresh_field(detail);

											silicon_ioi.ioiScanning.sleep_static(500).then(() => {
												cur_frm.fields_dict[detail].grid.grid_rows[current_idx-1].row_check[0].children[0].checked = true;
											});

											qty = 0;

										}else
										{
											// Qty
											cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield] = parseFloat(cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield]) + parseFloat(qt_diff);
											script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
											cur_frm.refresh_field(detail);

											qty = qty - qt_diff;
										}
									}
								}
							}
						}else
						{

							// Qty
							cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield] = parseFloat(cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield]) + parseFloat(qty);
							script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
							cur_frm.refresh_field(detail);

							silicon_ioi.ioiScanning.sleep_static(500).then(() => {
								cur_frm.fields_dict[detail].grid.grid_rows[current_idx-1].row_check[0].children[0].checked = true;
							});

							qty = 0;


						}



						if (parseFloat(qty) != 0)
						{

							for (var k = 0; k < cur_frm.fields_dict[detail].grid.grid_rows.length; k++) {

								cur_frm.get_field(detail).grid.grid_rows[k].row_check[0].children[0].checked = false;
							}


							while (parseFloat(qty) != 0)
							{
								current_idx = -1;
								let last_idx_found = -1;

								for(var j = 0; j < cur_frm.doc[detail].length; j++)
								{
									if ((cur_frm.doc[detail][j].item_id) && (cur_frm.doc[detail][j].manufacturer_ref.trim() != ''))
									{
										if (cur_frm.doc[detail][j].manufacturer_ref == pk)
										{
											last_idx_found = cur_frm.doc[detail][j].idx;;

											if (parseFloat(cur_frm.doc[detail][j].qty_to_prepare) != 0)
											{
												if (parseFloat(cur_frm.doc[detail][j].qty_to_prepare) > 0)
												{

													if ((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY'))
													{
														if (parseFloat(cur_frm.doc[detail][j].delivered_qty) < parseFloat(cur_frm.doc[detail][j].qty_to_prepare))
														{
															current_idx = cur_frm.doc[detail][j].idx;
															break;
														}
													}else{
														if (parseFloat(cur_frm.doc[detail][j].transferred_qty) < parseFloat(cur_frm.doc[detail][j].qty_to_prepare))
														{
															current_idx = cur_frm.doc[detail][j].idx;
															break;
														}

													}
												}else
												{
													if ((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY'))
													{
														if (parseFloat(cur_frm.doc[detail][j].delivered_qty) > parseFloat(cur_frm.doc[detail][j].qty_to_prepare))
														{
															current_idx = cur_frm.doc[detail][j].idx;
															break;
														}
													}else
													{
														if (parseFloat(cur_frm.doc[detail][j].transferred_qty) > parseFloat(cur_frm.doc[detail][j].qty_to_prepare))
														{
															current_idx = cur_frm.doc[detail][j].idx;
															break;
														}
													}

												}
											}
										}
									}
								}


								if (current_idx == -1)
								{
									qty = 0;

									silicon_ioi.ioiScanning.sleep_static(500).then(() => {
										cur_frm.fields_dict[detail].grid.grid_rows[last_idx_found-1].row_check[0].children[0].checked = true;
									});


								}else
								{
									script_manager = cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].frm.script_manager;

									let doc_qty = 0;

									if ((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY'))
									{
										doc_qty = cur_frm.doc[detail][current_idx-1].delivered_qty;
									}else
									{
										doc_qty = cur_frm.doc[detail][current_idx-1].transferred_qty;
									}


									if (parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare) > 0)
									{
										if (parseFloat(doc_qty) < parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare))
										{
											qt_diff = parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare) - parseFloat(doc_qty);

											if (parseFloat(qty) <= parseFloat(qt_diff))
											{
												// Qty
												cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield] = parseFloat(cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield]) + parseFloat(qty);
												script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
												cur_frm.refresh_field(detail);

												silicon_ioi.ioiScanning.sleep_static(500).then(() => {
													cur_frm.fields_dict[detail].grid.grid_rows[current_idx-1].row_check[0].children[0].checked = true;
												});


												qty = 0;


											}else
											{

												// Qty
												cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield] = parseFloat(cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield]) + parseFloat(qt_diff);
												script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
												cur_frm.refresh_field(detail);

												qty = qty - qt_diff;

											}
										}
									}else
									{
										if (parseFloat(doc_qty) > parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare))
										{

											qt_diff = parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare) - parseFloat(doc_qty);

											if (parseFloat(qty) >= parseFloat(qt_diff))
											{
												// Qty
												cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield] = parseFloat(cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield]) + parseFloat(qty);
												script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
												cur_frm.refresh_field(detail);

												silicon_ioi.ioiScanning.sleep_static(500).then(() => {
													cur_frm.fields_dict[detail].grid.grid_rows[current_idx-1].row_check[0].children[0].checked = true;
												});

												qty = 0;

											}else
											{
												// Qty
												cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield] = parseFloat(cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield]) + parseFloat(qt_diff);
												script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
												cur_frm.refresh_field(detail);

												qty = qty - qt_diff;
											}
										}
									}


								}
							}
						}
					}
				}
			}

		}else
		{

			if (create_new)
			{
				d = cur_frm.fields_dict[detail].grid.add_new_row();
			}


			if (d) {
				if (d.idx) {
					if (detail == silicon_ioi.ioiScanning.field_detail1) {
						document.getElementById('sb_main_scan_detail_row_idx').value = d.idx.toString();
					} else if (detail == silicon_ioi.ioiScanning.field_detail2) {
						document.getElementById('sb_main_scan_detail2_row_idx').value = d.idx.toString();
					} else if (detail == silicon_ioi.ioiScanning.field_detail3) {
						document.getElementById('sb_main_scan_detail3_row_idx').value = d.idx.toString();
					} else if (detail == silicon_ioi.ioiScanning.field_detail4) {
						document.getElementById('sb_main_scan_detail4_row_idx').value = d.idx.toString();
					} else if (detail == silicon_ioi.ioiScanning.field_detail5) {
						document.getElementById('sb_main_scan_detail5_row_idx').value = d.idx.toString();
					} else if (detail == silicon_ioi.ioiScanning.field_detail6) {
						document.getElementById('sb_main_scan_detail6_row_idx').value = d.idx.toString();
					}


					for (var i = 0; i < cur_frm.fields_dict[detail].grid.grid_rows.length; i++) {

							cur_frm.get_field(detail).grid.grid_rows[i].row_check[0].children[0].checked = false;
					}


					silicon_ioi.ioiScanning.sleep_static(200).then(() => {

						let script_manager = cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].frm.script_manager;

						cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc[destination_field] = pk;

						script_manager.trigger(destination_field, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);

						cur_frm.refresh_field(detail);

						if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') 			 || (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') 	 || (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')    || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE') ||
							(cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE') ||
							(cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')			 || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT')	 || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY'))
						{

							if (cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc[qty_field] == 0)
							{
								cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc[qty_field] = qt_scan;
							}else
							{
								cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc[qty_field] = cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc[qty_field] + qt_scan;
							}

							script_manager.trigger(qty_field, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);


							cur_frm.refresh_field(detail);

							silicon_ioi.ioiScanning.sleep_static(500).then(() => {
								cur_frm.fields_dict[detail].grid.grid_rows[d.idx-1].row_check[0].children[0].checked = true;
							});

						}
					});
				}
			}else
			{
				if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') 			 || (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') 	 || (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')    || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE') ||
					(cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE') ||
					(cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')			 || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT')	 || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY'))
				{

					for (var i = 0; i < cur_frm.fields_dict[detail].grid.grid_rows.length; i++) {

						cur_frm.get_field(detail).grid.grid_rows[i].row_check[0].children[0].checked = false;
					}


					silicon_ioi.ioiScanning.sleep_static(200).then(() => {

						let idx = cur_frm.fields_dict[detail].grid.grid_rows.length;

						let script_manager = cur_frm.fields_dict[detail].grid.grid_rows[idx-1].frm.script_manager;

						cur_frm.fields_dict[detail].grid.grid_rows[idx-1].doc[qty_field] = parseFloat(cur_frm.fields_dict[detail].grid.grid_rows[idx-1].doc[qty_field]) + qt_scan;

						script_manager.trigger(qty_field, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].doc.name);


						cur_frm.refresh_field(detail);

						silicon_ioi.ioiScanning.sleep_static(500).then(() => {
							cur_frm.fields_dict[detail].grid.grid_rows[idx-1].row_check[0].children[0].checked = true;
						});
					});
				}
			}
		}
	}


	// ***************************************************************************************************************************************
	// Scan batch
	// ***************************************************************************************************************************************
	static scan_batch(what, detail, pk, batch_sn_item_id, batch_sn_id, scan_mode, code_ref)
	{

		// Check site
		let found_in_current_site = 0;

		let pathbatchlocation = 'silicon_ioi.ioi_wms.doctype.ioi_batch_sn.ioi_batch_sn';
		let m = pathbatchlocation + '.ioi_batch_sn_in_current_site';

		frappe.call({
			method: m,
			args: { "item_id": batch_sn_item_id, "batch_sn_id": batch_sn_id},
			async: false,
			callback: function (r) {

				found_in_current_site = r.message.found;
			}
		});

		if (found_in_current_site == 0)
		{
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("This batch/sn can not be used in this site.") + '</font>';
			return;

		}

		if ((!cur_frm.doc[detail]) || ((cur_frm.doc[detail]) && (cur_frm.doc[detail].length == 0)))
		{
			if (cur_frm.doc.qty_to_prepare_mode == 1)
			{
				document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Can not add new line (Qty to prepare)") + '</font>';
				return;
			}

			if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
			{
				if ((!cur_frm.doc.from_warehouse_id) || ((cur_frm.doc.from_warehouse_id) && (cur_frm.doc.from_warehouse_id.trim() == '')))
				{
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Fill the warehouse, please") + '</font>';
					return;
				}

			}else
			{

				if ((!cur_frm.doc.warehouse_id) || ((cur_frm.doc.warehouse_id) && (cur_frm.doc.warehouse_id.trim() == '')))
				{
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Fill the warehouse, please") + '</font>';
					return;
				}
			}

		}



		let batchsn = [];

		m = pathbatchlocation + '.ioi_batch_sn_get_data';




		frappe.call({
			method: m,
			args: {"item_id": batch_sn_item_id, "batch_sn_id": code_ref, "is_batch": 'Y'},
			async: false,
			callback: function (r) {

				batchsn = [];

				for (var i = 0; i < r.message.length; i++)
				{
					batchsn[i] = [r.message[i].item_id, r.message[i].warehouse_id, r.message[i].location_id, r.message[i].batch_sn_id, r.message[i].code_ref, r.message[i].code_origin, r.message[i].qty, 0, r.message[i].qty, r.message[i].qcref];

				}
			}
		});


		if (batchsn.length == 0)
		{
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("This batch/sn has no stock.") + '</font>';
			return;

		}

		let row = [];

		let current_warehouse = '';
		let current_location = '';


		if ((!cur_frm.doc[detail]) || ((cur_frm.doc[detail]) && (cur_frm.doc[detail].length == 0)))
		{
			if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
			{
				if ((!cur_frm.doc.from_warehouse_id) || ((cur_frm.doc.from_warehouse_id) && (cur_frm.doc.from_warehouse_id.trim() == '')))
				{
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Fill the warehouse, please") + '</font>';
					return;
				}
			}else
			{
				if ((!cur_frm.doc.warehouse_id) || ((cur_frm.doc.warehouse_id) && (cur_frm.doc.warehouse_id.trim() == '')))
				{
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Fill the warehouse, please") + '</font>';
					return;
				}

			}

		}

		let i = 0;
		let one_found = false;

		if (cur_frm.doc[detail]) {

			for (i = 0; i < cur_frm.doc[detail].length; i++)
			{
				if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
				{
					if (((!cur_frm.doc[detail][i].from_warehouse_id) || ((cur_frm.doc[detail][i].from_warehouse_id) && (cur_frm.doc[detail][i].from_warehouse_id.trim() == ''))) &&
						((!cur_frm.doc.from_warehouse_id) || ((cur_frm.doc.from_warehouse_id) && (cur_frm.doc.from_warehouse_id.trim() == ''))))
					{
						document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Fill the warehouse, please") + '</font>';
						return;
					}

					if ((cur_frm.doc[detail][i].from_warehouse_id) && (cur_frm.doc[detail][i].from_warehouse_id.trim() != ''))
					{
						current_warehouse = cur_frm.doc[detail][i].from_warehouse_id;
					}else
					{
						current_warehouse = cur_frm.doc.from_warehouse_id;
					}

					current_location = '';

					if ((cur_frm.doc[detail][i].from_warehouse_location_id) && (cur_frm.doc[detail][i].from_warehouse_location_id.trim() != ''))
					{
						current_location = cur_frm.doc[detail][i].from_warehouse_location_id;
					}


				}else
				{

					if (((!cur_frm.doc[detail][i].warehouse_id) || ((cur_frm.doc[detail][i].warehouse_id) && (cur_frm.doc[detail][i].warehouse_id.trim() == ''))) &&
						((!cur_frm.doc.warehouse_id) || ((cur_frm.doc.warehouse_id) && (cur_frm.doc.warehouse_id.trim() == ''))))
					{
						document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Fill the warehouse, please") + '</font>';
						return;
					}


					if ((cur_frm.doc[detail][i].warehouse_id) && (cur_frm.doc[detail][i].warehouse_id.trim() != ''))
					{
						current_warehouse = cur_frm.doc[detail][i].warehouse_id;
					}else
					{
						current_warehouse = cur_frm.doc.warehouse_id;
					}

					current_location = '';

					if ((cur_frm.doc[detail][i].warehouse_location_id) && (cur_frm.doc[detail][i].warehouse_location_id.trim() != ''))
					{
						current_location = cur_frm.doc[detail][i].warehouse_location_id;
					}
				}

				let qtyfield = 'delivered_qty';

				if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER') {
					qtyfield = 'transferred_qty';
				}



				for (var j = 0; j < batchsn.length; j++)
				{

					if (batchsn[j][8] > 0)
					{
						if (cur_frm.doc[detail][i].item_id == batchsn[j][0])
						{


							if (cur_frm.doc.qty_to_prepare_mode == 1)
							{

								let delivered_qty = 0;

								if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
								{
									if ((cur_frm.doc[detail][i].transferred_qty) && (cur_frm.doc[detail][i].transferred_qty != 0))
									{
										delivered_qty = cur_frm.doc[detail][i].transferred_qty;
									}
								}else
								{

									if ((cur_frm.doc[detail][i].delivered_qty) && (cur_frm.doc[detail][i].delivered_qty != 0))
									{
										delivered_qty = cur_frm.doc[detail][i].delivered_qty;
									}
								}

								let qty_to_prepare = 0;

								if ((cur_frm.doc[detail][i].qty_to_prepare) && (cur_frm.doc[detail][i].qty_to_prepare != 0))
								{
									qty_to_prepare = cur_frm.doc[detail][i].qty_to_prepare;
								}


								if (parseFloat(qty_to_prepare) != 0)
								{

									if (parseFloat(delivered_qty) < parseFloat(qty_to_prepare))
									{

										if (current_warehouse == batchsn[j][1])
										{
											if (current_location != '')
											{
												if (current_location == batchsn[j][2])
												{
													silicon_ioi.ioiScanning.update_and_insert_detail_line_batch(detail, i, batchsn, j, qty_to_prepare, delivered_qty, false, qtyfield, 1);
													one_found = true;
												}
											}else
											{
												silicon_ioi.ioiScanning.update_and_insert_detail_line_batch(detail, i, batchsn, j, qty_to_prepare, delivered_qty, true, qtyfield, 1);
												one_found = true;
											}
										}

									}else
									{

										if (parseFloat(delivered_qty) > parseFloat(qty_to_prepare))
										{

											if (current_warehouse == batchsn[j][1])
											{
												if (current_location != '')
												{
													if (current_location == batchsn[j][2])
													{
														silicon_ioi.ioiScanning.update_and_insert_detail_line_batch(detail, i, batchsn, j, Math.abs(qty_to_prepare), Math.abs(delivered_qty), false, qtyfield, 1);
														one_found = true;
													}
												}else
												{
													silicon_ioi.ioiScanning.update_and_insert_detail_line_batch(detail, i, batchsn, j, Math.abs(qty_to_prepare), Math.abs(delivered_qty), true, qtyfield, 1);
													one_found = true;
												}
											}
										}
									}
								}
							}else
							{
								// qty_to_prepare_mode = 0

								let delivered_qty = 0;

								if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
								{
									if ((cur_frm.doc[detail][i].transferred_qty) && (cur_frm.doc[detail][i].transferred_qty != 0))
									{
										delivered_qty = cur_frm.doc[detail][i].transferred_qty;
									}
								}else
								{
									if ((cur_frm.doc[detail][i].delivered_qty) && (cur_frm.doc[detail][i].delivered_qty != 0))
									{
										delivered_qty = cur_frm.doc[detail][i].delivered_qty;
									}
								}



								let ordered_qty = 0;

								if (cur_frm.doctype.toUpperCase() != 'IOI STOCK TRANSFER')
								{
									if ((cur_frm.doc[detail][i].ordered_qty) && (cur_frm.doc[detail][i].ordered_qty != 0))
									{
										ordered_qty = cur_frm.doc[detail][i].ordered_qty;
									}
								}


								if (parseFloat(ordered_qty) != 0)
								{

									if (parseFloat(delivered_qty) < parseFloat(ordered_qty))
									{

										if (current_warehouse == batchsn[j][1])
										{
											if (current_location != '')
											{
												if (current_location == batchsn[j][2])
												{
													silicon_ioi.ioiScanning.update_and_insert_detail_line_batch(detail, i, batchsn, j, ordered_qty, delivered_qty, false, qtyfield, 0);
													one_found = true;
												}
											}else
											{
												silicon_ioi.ioiScanning.update_and_insert_detail_line_batch(detail, i, batchsn, j, ordered_qty, delivered_qty, true, qtyfield, 0);
												one_found = true;
											}
										}

									}else
									{

										if (parseFloat(delivered_qty) > parseFloat(ordered_qty))
										{

											if (current_warehouse == batchsn[j][1])
											{
												if (current_location != '')
												{
													if (current_location == batchsn[j][2])
													{
														silicon_ioi.ioiScanning.update_and_insert_detail_line_batch(detail, i, batchsn, j, Math.abs(ordered_qty), Math.abs(delivered_qty), false, qtyfield, 0);
														one_found = true;
													}
												}else
												{
													silicon_ioi.ioiScanning.update_and_insert_detail_line_batch(detail, i, batchsn, j, Math.abs(ordered_qty), Math.abs(delivered_qty), true, qtyfield, 0);
													one_found = true;
												}
											}
										}
									}
								}else
								{
									// mode normal

									one_found = false;

/*									if (current_warehouse == batchsn[j][1])
									{
										if (current_location != '')
										{
											if (current_location == batchsn[j][2])
											{	if (delivered_qty == 0)
												{
													silicon_ioi.ioiScanning.update_and_insert_detail_line_batch(detail, i, batchsn, j, ordered_qty, delivered_qty, false, qtyfield, 2);
													one_found = true;
												}else
												{
													one_found = false;
												}

											}
										}else
										{	if (delivered_qty == 0) {
												silicon_ioi.ioiScanning.update_and_insert_detail_line_batch(detail, i, batchsn, j, ordered_qty, delivered_qty, true, qtyfield, 2);
												one_found = true;
											}else{
												one_found = false;
											}
										}

										if (!one_found) {
											document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("This batch is aleady in a detail line") + '</font>';
											return;
										}


										one_found = true;
									}
*/
								}
							}
						}
					}
				}
			}
		}

		if (!one_found)
		{
			if (cur_frm.doc.qty_to_prepare_mode == 1)
			{
				document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Can not add new line (Qty to prepare)") + '</font>';
				return;
			}else{

				let can_create = true;

				if ((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT')) {

					if (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {

						if (cur_frm.doc.qty_to_prepare_mode == 0)
						{
							if (cur_frm.doc[detail]) {
								for (i = 0; i < cur_frm.doc[detail].length; i++) {

									if ((cur_frm.doc[detail][i].order_master_link) && (cur_frm.doc[detail][i].order_master_link != '')) {
										can_create = false;
										break;
									}
								}
							}
						}
					}else{
						for (i = 0; i < cur_frm.doc[detail].length; i++) {

							if (cur_frm.doc[detail]) {
								if ((cur_frm.doc[detail][i].order_master_link) && (cur_frm.doc[detail][i].order_master_link != '')) {
									can_create = false;
									break;
								}
							}
						}
					}
				}

				if (!can_create) {
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Can not add new line (Linked to order)") + '</font>';
					return;

				}

				let qtyfield = 'delivered_qty';

				if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER') {
					qtyfield = 'transferred_qty';
				}


				if (can_create) {

					for (var j = 0; j < batchsn.length; j++)
					{

						// Add new line
						let d = cur_frm.fields_dict[detail].grid.add_new_row();

						if (d.idx) {
							if (detail == silicon_ioi.ioiScanning.field_detail1) {
								document.getElementById('sb_main_scan_detail_row_idx').value = d.idx.toString();
							} else if (detail == silicon_ioi.ioiScanning.field_detail2) {
								document.getElementById('sb_main_scan_detail2_row_idx').value = d.idx.toString();
							} else if (detail == silicon_ioi.ioiScanning.field_detail3) {
								document.getElementById('sb_main_scan_detail3_row_idx').value = d.idx.toString();
							} else if (detail == silicon_ioi.ioiScanning.field_detail4) {
								document.getElementById('sb_main_scan_detail4_row_idx').value = d.idx.toString();
							} else if (detail == silicon_ioi.ioiScanning.field_detail5) {
								document.getElementById('sb_main_scan_detail5_row_idx').value = d.idx.toString();
							} else if (detail == silicon_ioi.ioiScanning.field_detail6) {
								document.getElementById('sb_main_scan_detail6_row_idx').value = d.idx.toString();
							}


							for (var k = 0; k < cur_frm.fields_dict[detail].grid.grid_rows.length; k++) {

								cur_frm.get_field(detail).grid.grid_rows[k].row_check[0].children[0].checked = false;
							}

							let qty = batchsn[j][8];
							let bitem = batchsn[j][0];
							let bwarehouse = batchsn[j][1];
							let blocation = batchsn[j][2];
							let bbatch =  batchsn[j][3];

							let fieldwhs = 'warehouse_id';
							let fieldloc = 'warehouse_location_id';

							if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER') {
								fieldwhs = 'from_warehouse_id';
								fieldloc = 'from_warehouse_location_id';
							}


							silicon_ioi.ioiScanning.sleep_static(200).then(() => {

								let script_manager = cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].frm.script_manager;

								// Item
								cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["item_id"] = bitem;
								script_manager.trigger("item_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
								cur_frm.refresh_field(detail);


								// Qty
								cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc[qtyfield] = qty;
								script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
								cur_frm.refresh_field(detail);

								cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc[fieldwhs] = bwarehouse;
								cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc[fieldloc] = blocation;
								cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc['batch_sn_id'] = bbatch;
								cur_frm.refresh_field(detail);


								silicon_ioi.ioiScanning.sleep_static(500).then(() => {
									cur_frm.fields_dict[detail].grid.grid_rows[d.idx-1].row_check[0].children[0].checked = true;
								});

							});
						}

					}



				}
			}
		}
	}

	// ***************************************************************************************************************************************
	// Scan SN
	// ***************************************************************************************************************************************
	static scan_sn(what, detail, pk, batch_sn_item_id, batch_sn_id, scan_mode)
	{
		// Check site
		let found_in_current_site = 0;

		let pathbatchlocation = 'silicon_ioi.ioi_wms.doctype.ioi_batch_sn.ioi_batch_sn';
		let m = pathbatchlocation + '.ioi_batch_sn_in_current_site';

		frappe.call({
			method: m,
			args: { "item_id": batch_sn_item_id, "batch_sn_id": batch_sn_id},
			async: false,
			callback: function (r) {

				found_in_current_site = r.message.found;
			}
		});

		if (found_in_current_site == 0)
		{
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("This batch/sn can not be used in this site.") + '</font>';
			return;

		}

		if ((!cur_frm.doc[detail]) || ((cur_frm.doc[detail]) && (cur_frm.doc[detail].length == 0)))
		{
			if (cur_frm.doc.qty_to_prepare_mode == 1)
			{
				document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Can not add new line (Qty to prepare)") + '</font>';
				return;
			}

			if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
			{
				if ((!cur_frm.doc.from_warehouse_id) || ((cur_frm.doc.from_warehouse_id) && (cur_frm.doc.from_warehouse_id.trim() == '')))
				{
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Fill the warehouse, please") + '</font>';
					return;
				}

			}else
			{

				if ((!cur_frm.doc.warehouse_id) || ((cur_frm.doc.warehouse_id) && (cur_frm.doc.warehouse_id.trim() == '')))
				{
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Fill the warehouse, please") + '</font>';
					return;
				}
			}

		}



		let batchsn = [];

		m = pathbatchlocation + '.ioi_batch_sn_get_data';


		frappe.call({
			method: m,
			args: {"item_id": batch_sn_item_id, "batch_sn_id": batch_sn_id},
			async: false,
			callback: function (r) {

				batchsn = [];

				for (var i = 0; i < r.message.length; i++)
				{
					batchsn[i] = [r.message[i].item_id, r.message[i].warehouse_id, r.message[i].location_id, r.message[i].code_ref, r.message[i].code_origin, r.message[i].qty, r.message[i].qcref];

				}
			}
		});


		if (batchsn.length == 0)
		{
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("This batch/sn has no stock.") + '</font>';
			return;

		}

		let create_new = true;
		let current_warehouse = '';
		let current_location = '';
		let current_idx = -1;
		let can_update_detail = false;


		if ((!cur_frm.doc[detail]) || ((cur_frm.doc[detail]) && (cur_frm.doc[detail].length == 0)))
		{
			create_new = true;

			if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
			{
				if ((!cur_frm.doc.from_warehouse_id) || ((cur_frm.doc.from_warehouse_id) && (cur_frm.doc.from_warehouse_id.trim() == '')))
				{
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Fill the warehouse, please") + '</font>';
					return;
				}

			}else
			{
				if ((!cur_frm.doc.warehouse_id) || ((cur_frm.doc.warehouse_id) && (cur_frm.doc.warehouse_id.trim() == '')))
				{
					document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Fill the warehouse, please") + '</font>';
					return;
				}
			}

		}else
		{

			for(var j = 0; j < cur_frm.doc[detail].length; j++)
			{
				if ((cur_frm.doc[detail][j].item_id) && (cur_frm.doc[detail][j].item_id.trim() != ''))
				{
					if (cur_frm.doc[detail][j].item_id == batchsn[0][0])
					{
						if ((!cur_frm.doc[detail][j].batch_sn_id) || ((cur_frm.doc[detail][j].batch_sn_id) && (cur_frm.doc[detail][j].batch_sn_id == '')))
						{

							if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
							{
								if ((cur_frm.doc[detail][j].from_warehouse_id) && (cur_frm.doc[detail][j].from_warehouse_id != ''))
								{
									current_warehouse = cur_frm.doc[detail][j].from_warehouse_id;

									if ((cur_frm.doc[detail][j].from_warehouse_location_id) && (cur_frm.doc[detail][j].from_warehouse_location_id != ''))
									{
										current_location = cur_frm.doc[detail][j].from_warehouse_location_id;
									}

								}else
								{
									document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Fill the warehouse, please") + '</font>';
									return;
								}
							}else
							{
								if ((cur_frm.doc[detail][j].warehouse_id) && (cur_frm.doc[detail][j].warehouse_id != ''))
								{
									current_warehouse = cur_frm.doc[detail][j].warehouse_id;

									if ((cur_frm.doc[detail][j].warehouse_location_id) && (cur_frm.doc[detail][j].warehouse_location_id != ''))
									{
										current_location = cur_frm.doc[detail][j].warehouse_location_id;
									}

								}else
								{
									document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Fill the warehouse, please") + '</font>';
									return;
								}


							}

							let doc_qty = 0

							if (cur_frm.doctype.toUpperCase() != 'IOI STOCK TRANSFER')
							{
								doc_qty = cur_frm.doc[detail][j].delivered_qty;
							}else
							{
								doc_qty = cur_frm.doc[detail][j].transferred_qty;
							}



							if (cur_frm.doc.qty_to_prepare_mode == 1)
							{
								if (parseFloat(cur_frm.doc[detail][j].qty_to_prepare) != 0)
								{
									if (parseFloat(cur_frm.doc[detail][j].qty_to_prepare) > 0)
									{
										if (parseFloat(doc_qty) < parseFloat(cur_frm.doc[detail][j].qty_to_prepare))
										{
											current_idx = cur_frm.doc[detail][j].idx;
											create_new = false;
											can_update_detail = true;
											break;
										}
									}else
									{
										if (parseFloat(doc_qty) > parseFloat(cur_frm.doc[detail][j].qty_to_prepare))
										{
											current_idx = cur_frm.doc[detail][j].idx;
											create_new = false;
											can_update_detail = true;
											break;
										}
									}
								}
							}else
							{
								current_idx = cur_frm.doc[detail][j].idx;
								create_new = false;
								break;
							}
						}
					}
				}
			}
		}

		if (!create_new)
		{
			if (current_warehouse != '')
			{
				if (current_warehouse != batchsn[0][1])
				{
					create_new = true;
					can_update_detail = false;
				}else
				{
					if (current_location != '')
					{
						if (current_location != batchsn[0][2])
						{
							create_new = true;
							can_update_detail = false;
						}
					}
				}
			}
		}



		if (cur_frm.doc.qty_to_prepare_mode != 1)
		{
			can_update_detail = true;
		}else
		{
			if (!can_update_detail)
			{
				document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Can not add new line (Qty to prepare)") + '</font>';
				return;
			}
		}


		if (can_update_detail)
		{

			let qtyfield = 'delivered_qty';

			if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
			{
				qtyfield = 'transferred_qty';
			}

			if (create_new)
			{
				let d = cur_frm.fields_dict[detail].grid.add_new_row();

				if (d.idx) {
					if (detail == silicon_ioi.ioiScanning.field_detail1) {
						document.getElementById('sb_main_scan_detail_row_idx').value = d.idx.toString();
					} else if (detail == silicon_ioi.ioiScanning.field_detail2) {
						document.getElementById('sb_main_scan_detail2_row_idx').value = d.idx.toString();
					} else if (detail == silicon_ioi.ioiScanning.field_detail3) {
						document.getElementById('sb_main_scan_detail3_row_idx').value = d.idx.toString();
					} else if (detail == silicon_ioi.ioiScanning.field_detail4) {
						document.getElementById('sb_main_scan_detail4_row_idx').value = d.idx.toString();
					} else if (detail == silicon_ioi.ioiScanning.field_detail5) {
						document.getElementById('sb_main_scan_detail5_row_idx').value = d.idx.toString();
					} else if (detail == silicon_ioi.ioiScanning.field_detail6) {
						document.getElementById('sb_main_scan_detail6_row_idx').value = d.idx.toString();
					}


					for (var k = 0; k < cur_frm.fields_dict[detail].grid.grid_rows.length; k++) {

						cur_frm.get_field(detail).grid.grid_rows[k].row_check[0].children[0].checked = false;
					}


					let current_item = batchsn[0][0];
					let current_warehouse = batchsn[0][1];
					let current_location = batchsn[0][2];
					let current_code_ref = batchsn[0][3];
					let current_origin = batchsn[0][4];
					let current_qcref = batchsn[0][6];


					let qty = batchsn[0][5];


					silicon_ioi.ioiScanning.sleep_static(200).then(() => {

						let script_manager = cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].frm.script_manager;

						// Item
						cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["item_id"] = current_item;
						script_manager.trigger("item_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
						cur_frm.refresh_field(detail);

						if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
						{
							// Warehouse
							cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["from_warehouse_id"] = current_warehouse;
							script_manager.trigger("from_warehouse_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
							cur_frm.refresh_field(detail);

							silicon_ioi.ioiScanning.sleep_static(200).then(() => {
								// Location
								cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["from_warehouse_location_id"] = current_location;
								script_manager.trigger("from_warehouse_location_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
								cur_frm.refresh_field(detail);

							});
						}else
						{
							// Warehouse
							cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["warehouse_id"] = current_warehouse;
							script_manager.trigger("warehouse_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
							cur_frm.refresh_field(detail);


							silicon_ioi.ioiScanning.sleep_static(200).then(() => {
								// Location
								cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["warehouse_location_id"] = current_location;
								script_manager.trigger("warehouse_location_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
								cur_frm.refresh_field(detail);

							});
						}


						// Batch/SN Id
						cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["batch_sn_id"] = batch_sn_id;
						script_manager.trigger("batch_sn_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
						cur_frm.refresh_field(detail);


						// Batch/SN Code ref
						cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["batch_sn_code_ref"] = current_code_ref;
						script_manager.trigger("batch_sn_code_ref", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
						cur_frm.refresh_field(detail);

						// Batch/SN Code origin
						cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["batch_sn_origin"] = current_origin;
						script_manager.trigger("batch_sn_origin", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
						cur_frm.refresh_field(detail);

						if (cur_frm.doctype.toUpperCase() != 'IOI STOCK TRANSFER')
						{
							// QCSupplierRef
							cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["qcsupplier_ref"] = current_qcref;
							script_manager.trigger("qcsupplier_ref", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
							cur_frm.refresh_field(detail);
						}


						// Qty
						cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc[qtyfield] = parseFloat(qty);
						script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
						cur_frm.refresh_field(detail);

						silicon_ioi.ioiScanning.sleep_static(500).then(() => {
							cur_frm.fields_dict[detail].grid.grid_rows[d.idx-1].row_check[0].children[0].checked = true;
						});

					});
				}
			}else
			{
				if (detail == silicon_ioi.ioiScanning.field_detail1) {
					document.getElementById('sb_main_scan_detail_row_idx').value = current_idx.toString();
				} else if (detail == silicon_ioi.ioiScanning.field_detail2) {
					document.getElementById('sb_main_scan_detail2_row_idx').value = current_idx.toString();
				} else if (detail == silicon_ioi.ioiScanning.field_detail3) {
					document.getElementById('sb_main_scan_detail3_row_idx').value = current_idx.toString();
				} else if (detail == silicon_ioi.ioiScanning.field_detail4) {
					document.getElementById('sb_main_scan_detail4_row_idx').value = current_idx.toString();
				} else if (detail == silicon_ioi.ioiScanning.field_detail5) {
					document.getElementById('sb_main_scan_detail5_row_idx').value = current_idx.toString();
				} else if (detail == silicon_ioi.ioiScanning.field_detail6) {
					document.getElementById('sb_main_scan_detail6_row_idx').value = current_idx.toString();
				}


				for (var k = 0; k < cur_frm.fields_dict[detail].grid.grid_rows.length; k++) {

					cur_frm.get_field(detail).grid.grid_rows[k].row_check[0].children[0].checked = false;
				}



				let qty = batchsn[0][5];
				let current_code_ref = batchsn[0][3];
				let current_origin = batchsn[0][4];
				let current_qcref = batchsn[0][6];
				let current_warehouse = batchsn[0][1];
				let current_location = batchsn[0][2];


				silicon_ioi.ioiScanning.sleep_static(200).then(() => {

					let script_manager = cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].frm.script_manager;

					if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
					{
						// Warehouse
						cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["from_warehouse_id"] = current_warehouse;
						script_manager.trigger("from_warehouse_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
						cur_frm.refresh_field(detail);


						silicon_ioi.ioiScanning.sleep_static(200).then(() => {
							// Location
							cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["from_warehouse_location_id"] = current_location;
							script_manager.trigger("from_warehouse_location_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
							cur_frm.refresh_field(detail);

						});

					}else
					{
						// Warehouse
						cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["warehouse_id"] = current_warehouse;
						script_manager.trigger("warehouse_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
						cur_frm.refresh_field(detail);


						silicon_ioi.ioiScanning.sleep_static(200).then(() => {
							// Location
							cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["warehouse_location_id"] = current_location;
							script_manager.trigger("warehouse_location_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
							cur_frm.refresh_field(detail);

						});
					}


					// Batch/SN Id
					cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["batch_sn_id"] = batch_sn_id;
					script_manager.trigger("batch_sn_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
					cur_frm.refresh_field(detail);


					// Batch/SN Code ref
					cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["batch_sn_code_ref"] = current_code_ref;
					script_manager.trigger("batch_sn_code_ref", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
					cur_frm.refresh_field(detail);

					// Batch/SN Code origin
					cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["batch_sn_origin"] = current_origin;
					script_manager.trigger("batch_sn_origin", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
					cur_frm.refresh_field(detail);

					if (cur_frm.doctype.toUpperCase() != 'IOI STOCK TRANSFER')
					{
						// QCSupplierRef
						cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["qcsupplier_ref"] = current_qcref;
						script_manager.trigger("qcsupplier_ref", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
						cur_frm.refresh_field(detail);
					}



					let qt_diff = 0;
					let update_ordered_qty = true;


					let doc_qty = 0

					if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
					{
						doc_qty = cur_frm.doc[detail][current_idx-1].transferred_qty;
					}else
					{
						doc_qty = cur_frm.doc[detail][current_idx-1].delivered_qty;
					}



					if (cur_frm.doc.qty_to_prepare_mode == 1)
					{
						if (parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare) != 0)
						{
							if (parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare) > 0)
							{
								if (parseFloat(doc_qty) < parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare))
								{
									qt_diff = parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare) - parseFloat(doc_qty);

									if (parseFloat(qty) <= parseFloat(qt_diff))
									{
										// Qty
										cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield] = parseFloat(cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield]) + parseFloat(qty);
										script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
										cur_frm.refresh_field(detail);

										qt_diff = qt_diff - qty;

										if (parseFloat(qt_diff) != 0)
										{
											cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc['qty_to_prepare'] = parseFloat(qty);
											script_manager.trigger("qty_to_prepare", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
											cur_frm.refresh_field(detail);

										}

									}
								}
							}else
							{
								if (parseFloat(doc_qty) > parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare))
								{

									qt_diff = parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare) - parseFloat(doc_qty);

									if (parseFloat(qty) >= parseFloat(qt_diff))
									{
										// Qty
										cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield] = parseFloat(cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield]) + parseFloat(qty);
										script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
										cur_frm.refresh_field(detail);

										qt_diff = qt_diff - qty;

										if (parseFloat(qt_diff) != 0)
										{
											cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc['qty_to_prepare'] = parseFloat(qty);
											script_manager.trigger("qty_to_prepare", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
											cur_frm.refresh_field(detail);

										}


									}

								}

							}
						}
					}else
					{

						if (cur_frm.doctype.toUpperCase() != 'IOI STOCK TRANSFER')
						{

							if (parseFloat(cur_frm.doc[detail][current_idx-1].ordered_qty) != 0)
							{
								if (parseFloat(cur_frm.doc[detail][current_idx-1].ordered_qty) > 0)
								{
									if (parseFloat(cur_frm.doc[detail][current_idx-1].delivered_qty) < parseFloat(cur_frm.doc[detail][current_idx-1].ordered_qty))
									{
										qt_diff = parseFloat(cur_frm.doc[detail][current_idx-1].ordered_qty) - parseFloat(cur_frm.doc[detail][current_idx-1].delivered_qty);

										if (parseFloat(qty) <= parseFloat(qt_diff))
										{
											// Qty
											cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield] = parseFloat(cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield]) + parseFloat(qty);
											script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
											cur_frm.refresh_field(detail);

											qt_diff = qt_diff - qty;

											if (parseFloat(qt_diff) != 0)
											{
												cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc['ordered_qty'] = parseFloat(qty);
												script_manager.trigger("ordered_qty", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
												cur_frm.refresh_field(detail);

											}
										}
									}
								}else
								{
									if (parseFloat(cur_frm.doc[detail][current_idx-1].delivered_qty) > parseFloat(cur_frm.doc[detail][current_idx-1].ordered_qty))
									{

										qt_diff = parseFloat(cur_frm.doc[detail][current_idx-1].qty_to_prepare) - parseFloat(cur_frm.doc[detail][current_idx-1].delivered_qty);

										if (parseFloat(qty) >= parseFloat(qt_diff))
										{
											// Qty
											cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield] = parseFloat(cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield]) + parseFloat(qty);
											script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
											cur_frm.refresh_field(detail);

											qt_diff = qt_diff - qty;

											if (parseFloat(qt_diff) != 0)
											{
												cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc['ordered_qty'] = parseFloat(qty);
												script_manager.trigger("ordered_qty", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
												cur_frm.refresh_field(detail);

											}

										}

									}

								}
							}else
							{
								qt_diff = qty;
								update_ordered_qty = false;
							}
						}

					}

					if (cur_frm.doctype.toUpperCase() != 'IOI STOCK TRANSFER')
					{
						if (qt_diff != 0)
						{

							let order_master_link = cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc['order_master_link'];
							let order_detail_link = cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc['order_detail_link'];


							let d = cur_frm.fields_dict[detail].grid.add_new_row();
							let item = locals[cur_frm.fields_dict[detail].grid.doctype][cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name];

							silicon_ioi.ioiScanning.sleep_static(200).then(() => {


								cur_frm.fields_dict[detail].grid.duplicate_row(d, item);
								cur_frm.fields_dict[detail].grid.refresh();



								if (detail == silicon_ioi.ioiScanning.field_detail1) {
									document.getElementById('sb_main_scan_detail_row_idx').value = d.idx.toString();
								} else if (detail == silicon_ioi.ioiScanning.field_detail2) {
									document.getElementById('sb_main_scan_detail2_row_idx').value = d.idx.toString();
								} else if (detail == silicon_ioi.ioiScanning.field_detail3) {
									document.getElementById('sb_main_scan_detail3_row_idx').value = d.idx.toString();
								} else if (detail == silicon_ioi.ioiScanning.field_detail4) {
									document.getElementById('sb_main_scan_detail4_row_idx').value = d.idx.toString();
								} else if (detail == silicon_ioi.ioiScanning.field_detail5) {
									document.getElementById('sb_main_scan_detail5_row_idx').value = d.idx.toString();
								} else if (detail == silicon_ioi.ioiScanning.field_detail6) {
									document.getElementById('sb_main_scan_detail6_row_idx').value = d.idx.toString();
								}


								for (var k = 0; k < cur_frm.fields_dict[detail].grid.grid_rows.length; k++) {

									cur_frm.get_field(detail).grid.grid_rows[k].row_check[0].children[0].checked = false;
								}


								let current_item = batchsn[0][0];
								let current_warehouse = batchsn[0][1];
								let current_location = batchsn[0][2];
								let current_code_ref = batchsn[0][3];
								let current_origin = batchsn[0][4];
								let current_qcref = batchsn[0][6];
								let qty = batchsn[0][5];

								if (parseFloat(qty) == 0)
								{
									qty = 1;
								}

								cur_frm.refresh_field(detail);


								silicon_ioi.ioiScanning.sleep_static(200).then(() => {

									let script_manager = cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].frm.script_manager;

									if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
									{
										// Warehouse
										cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["from_warehouse_id"] = '';
										script_manager.trigger("from_warehouse_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
										cur_frm.refresh_field(detail);


										// Location
										cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["from_warehouse_location_id"] = '';
										script_manager.trigger("from_warehouse_location_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
										cur_frm.refresh_field(detail);

									}else
									{
										// Warehouse
										cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["warehouse_id"] = '';
										script_manager.trigger("warehouse_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
										cur_frm.refresh_field(detail);


										// Location
										cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["warehouse_location_id"] = '';
										script_manager.trigger("warehouse_location_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
										cur_frm.refresh_field(detail);
									}

									// Batch/SN Id
									cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["batch_sn_id"] = '';
									script_manager.trigger("batch_sn_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
									cur_frm.refresh_field(detail);


									// Batch/SN Code ref
									cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["batch_sn_code_ref"] = '';
									script_manager.trigger("batch_sn_code_ref", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
									cur_frm.refresh_field(detail);

									// Batch/SN Code origin
									cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["batch_sn_origin"] = '';
									script_manager.trigger("batch_sn_origin", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
									cur_frm.refresh_field(detail);

									if (cur_frm.doctype.toUpperCase() != 'IOI STOCK TRANSFER')
									{
										// QCSupplierRef
										cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["qcsupplier_ref"] = '';
										script_manager.trigger("qcsupplier_ref", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
										cur_frm.refresh_field(detail);
									}



									if (cur_frm.doc.qty_to_prepare_mode == 1)
									{
										cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc['order_master_link'] = order_master_link;
										cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc['order_detail_link'] = order_detail_link;

										// Qty
										cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc[qtyfield] =  0;
										script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
										cur_frm.refresh_field(detail);

										// Qty to prepare
										cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc['qty_to_prepare'] = qt_diff;
										script_manager.trigger('qty_to_prepare', cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
										cur_frm.refresh_field(detail);
									}else
									{
										if (update_ordered_qty)
										{
											// Qty
											cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc[qtyfield] = 0;
											script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
											cur_frm.refresh_field(detail);

											// Ordered qty
											cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc['ordered_qty'] = qt_diff;
											script_manager.trigger('ordered_qty', cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
											cur_frm.refresh_field(detail);

											cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc['order_master_link'] = order_master_link;
											cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc['order_detail_link'] = order_detail_link;
											cur_frm.refresh_field(detail);

										}else
										{

											if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
											{
												// Warehouse
												cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["from_warehouse_id"] = current_warehouse;
												script_manager.trigger("from_warehouse_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
												cur_frm.refresh_field(detail);


												// Location
												cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["from_warehouse_location_id"] = current_location;
												script_manager.trigger("from_warehouse_location_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
												cur_frm.refresh_field(detail);

											}else
											{
												// Warehouse
												cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["warehouse_id"] = current_warehouse;
												script_manager.trigger("warehouse_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
												cur_frm.refresh_field(detail);


												// Location
												cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["warehouse_location_id"] = current_location;
												script_manager.trigger("warehouse_location_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
												cur_frm.refresh_field(detail);
											}

											// Batch/SN Id
											cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["batch_sn_id"] = batch_sn_id;
											script_manager.trigger("batch_sn_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
											cur_frm.refresh_field(detail);


											// Batch/SN Code ref
											cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["batch_sn_code_ref"] = current_code_ref;
											script_manager.trigger("batch_sn_code_ref", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
											cur_frm.refresh_field(detail);

											// Batch/SN Code origin
											cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["batch_sn_origin"] = current_origin;
											script_manager.trigger("batch_sn_origin", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
											cur_frm.refresh_field(detail);

											if (cur_frm.doctype.toUpperCase() != 'IOI STOCK TRANSFER')
											{
												// QCSupplierRef
												cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["qcsupplier_ref"] = current_qcref;
												script_manager.trigger("qcsupplier_ref", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
												cur_frm.refresh_field(detail);
											}

											// Qty
											cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc[qtyfield] = 1;
											script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
											cur_frm.refresh_field(detail);

										}

									}

									silicon_ioi.ioiScanning.sleep_static(500).then(() => {
										cur_frm.fields_dict[detail].grid.grid_rows[d.idx-1].row_check[0].children[0].checked = true;
									});

								});
							});
						}
					}
				});

			}
		}


		cur_frm.refresh_field(detail);


	}


	static update_and_insert_detail_line_batch(detail, i, batchsn, j, qty_to_prepare, delivered_qty, update_loc, qtyfield, qty_to_prepare_mode = 1)
	{

		let current_idx = cur_frm.doc[detail][i].idx;
		let script_manager = cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].frm.script_manager;

		if (parseFloat(batchsn[j][8]) <= (parseFloat(qty_to_prepare) - parseFloat(delivered_qty)))
		{

			if (parseFloat(batchsn[j][8]) == (parseFloat(qty_to_prepare) - parseFloat(delivered_qty)))
			{
				if ((!cur_frm.doc[detail][i].batch_sn_id) || ((cur_frm.doc[detail][i].batch_sn_id) && (cur_frm.doc[detail][i].batch_sn_id == '')))
				{

					if (!update_loc)
					{
						silicon_ioi.ioiScanning.update_batch_line(detail, i, 'N', '', '', batchsn[j][3], batchsn[j][4], batchsn[j][5], parseFloat(batchsn[j][8]), qtyfield, parseFloat(batchsn[j][8]), qty_to_prepare_mode, batchsn[j][9]);

						batchsn[j][7] = parseFloat(batchsn[j][7]) + parseFloat(batchsn[j][8]);
						batchsn[j][8] = parseFloat(batchsn[j][8]) - parseFloat(batchsn[j][8]);

					}else
					{
						if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
						{
							cur_frm.doc[detail][i].from_warehouse_id = batchsn[j][1];
							cur_frm.doc[detail][i].from_warehouse_location_id = batchsn[j][2];

							// Warehouse
							cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["from_warehouse_id"] = batchsn[j][1];
							script_manager.trigger("from_warehouse_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx- 1].doc.name);
							cur_frm.refresh_field(detail);

						}else
						{
							cur_frm.doc[detail][i].warehouse_id = batchsn[j][1];
							cur_frm.doc[detail][i].warehouse_location_id = batchsn[j][2];

							// Warehouse
							cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["warehouse_id"] = batchsn[j][1];
							script_manager.trigger("warehouse_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx- 1].doc.name);
							cur_frm.refresh_field(detail);
						}

						silicon_ioi.ioiScanning.update_batch_line_next(detail, i, 'Y', batchsn[j][1], batchsn[j][2], batchsn[j][3], batchsn[j][4], batchsn[j][5], parseFloat(batchsn[j][8]), qtyfield, parseFloat(batchsn[j][8]), qty_to_prepare_mode, batchsn[j][9]);

						batchsn[j][7] = parseFloat(batchsn[j][7]) + parseFloat(batchsn[j][8]);
						batchsn[j][8] = parseFloat(batchsn[j][8]) - parseFloat(batchsn[j][8]);

						let a = j;

						if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
						{
							silicon_ioi.ioiScanning.sleep_static(200).then(() => {

								// Location
								cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["from_warehouse_location_id"] = batchsn[a][2];
								script_manager.trigger("from_warehouse_location_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx- 1].doc.name);
								cur_frm.refresh_field(detail);

							});

						}else
						{
							silicon_ioi.ioiScanning.sleep_static(200).then(() => {

								// Location
								cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["warehouse_location_id"] = batchsn[a][2];
								script_manager.trigger("warehouse_location_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx- 1].doc.name);
								cur_frm.refresh_field(detail);

							});
						}


					}



				}else
				{
					if (cur_frm.doc[detail][i].batch_sn_id == batchsn[j][3])
					{

						if (!update_loc)
						{
							silicon_ioi.ioiScanning.update_batch_line(detail, i, 'N', '', '', batchsn[j][3], batchsn[j][4], batchsn[j][5], parseFloat(batchsn[j][8]), qtyfield, parseFloat(batchsn[j][8]), qty_to_prepare_mode, batchsn[j][9]);

							batchsn[j][7] = parseFloat(batchsn[j][7]) + parseFloat(batchsn[j][8]);
							batchsn[j][8] = parseFloat(batchsn[j][8]) - parseFloat(batchsn[j][8]);

						}else
						{

							if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
							{
								cur_frm.doc[detail][i].from_warehouse_id = batchsn[j][1];
								cur_frm.doc[detail][i].from_warehouse_location_id = batchsn[j][2];

								// Warehouse
								cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["from_warehouse_id"] = batchsn[j][1];
								script_manager.trigger("from_warehouse_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx- 1].doc.name);
								cur_frm.refresh_field(detail);

							}else
							{

								cur_frm.doc[detail][i].warehouse_id = batchsn[j][1];
								cur_frm.doc[detail][i].warehouse_location_id = batchsn[j][2];

								// Warehouse
								cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["warehouse_id"] = batchsn[j][1];
								script_manager.trigger("warehouse_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx- 1].doc.name);
								cur_frm.refresh_field(detail);
							}

							silicon_ioi.ioiScanning.update_batch_line_next(detail, i, 'Y', batchsn[j][1], batchsn[j][2], batchsn[j][3], batchsn[j][4], batchsn[j][5], parseFloat(batchsn[j][8]), qtyfield, parseFloat(batchsn[j][8]), qty_to_prepare_mode, batchsn[j][9]);

							batchsn[j][7] = parseFloat(batchsn[j][7]) + parseFloat(batchsn[j][8]);
							batchsn[j][8] = parseFloat(batchsn[j][8]) - parseFloat(batchsn[j][8]);


							let a = j;

							if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
							{
								silicon_ioi.ioiScanning.sleep_static(200).then(() => {

									// Location
									cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["from_warehouse_location_id"] = batchsn[a][2];
									script_manager.trigger("from_warehouse_location_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx- 1].doc.name);
									cur_frm.refresh_field(detail);

								});

							}else{
								silicon_ioi.ioiScanning.sleep_static(200).then(() => {

									// Location
									cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["warehouse_location_id"] = batchsn[a][2];
									script_manager.trigger("warehouse_location_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx- 1].doc.name);
									cur_frm.refresh_field(detail);

								});
							}

						}

					}

				}


			}else
			{

				if ((!cur_frm.doc[detail][i].batch_sn_id) || ((cur_frm.doc[detail][i].batch_sn_id) && (cur_frm.doc[detail][i].batch_sn_id == '')))
				{

					let a = -1;

					let new_qt = (parseFloat(qty_to_prepare) - parseFloat(delivered_qty)) - parseFloat(batchsn[j][8]);


					if (!update_loc)
					{
						silicon_ioi.ioiScanning.update_batch_line(detail, i, 'N', '', '', batchsn[j][3], batchsn[j][4], batchsn[j][5], parseFloat(batchsn[j][8]), qtyfield, parseFloat(batchsn[j][8]), qty_to_prepare_mode, batchsn[j][9]);

						batchsn[j][7] = parseFloat(batchsn[j][7]) + parseFloat(batchsn[j][8]);
						batchsn[j][8] = parseFloat(batchsn[j][8]) - parseFloat(batchsn[j][8]);

					}else
					{
						if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
						{
							cur_frm.doc[detail][i].from_warehouse_id = batchsn[j][1];
							cur_frm.doc[detail][i].from_warehouse_location_id = batchsn[j][2];

							// Warehouse
							cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["from_warehouse_id"] = batchsn[j][1];
							script_manager.trigger("from_warehouse_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx- 1].doc.name);
							cur_frm.refresh_field(detail);

						}else{

							cur_frm.doc[detail][i].warehouse_id = batchsn[j][1];
							cur_frm.doc[detail][i].warehouse_location_id = batchsn[j][2];

							// Warehouse
							cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["warehouse_id"] = batchsn[j][1];
							script_manager.trigger("warehouse_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx- 1].doc.name);
							cur_frm.refresh_field(detail);
						}


						silicon_ioi.ioiScanning.update_batch_line_next(detail, i, 'Y', batchsn[j][1], batchsn[j][2], batchsn[j][3], batchsn[j][4], batchsn[j][5], parseFloat(batchsn[j][8]), qtyfield, parseFloat(batchsn[j][8]), qty_to_prepare_mode, batchsn[j][9]);

						batchsn[j][7] = parseFloat(batchsn[j][7]) + parseFloat(batchsn[j][8]);
						batchsn[j][8] = parseFloat(batchsn[j][8]) - parseFloat(batchsn[j][8]);

						a = j;



					}




					if (parseFloat(batchsn[j][8]) == 0)
					{

						if (parseFloat(new_qt) != 0)
						{
							let z;

							for (z = 0; z < batchsn.length; z++)
							{
								if ((batchsn[z][1] == batchsn[j][1]) && (batchsn[z][2] == batchsn[j][2]) && (batchsn[z][3] == batchsn[j][3]))
								{
									if (parseFloat(batchsn[z][8]) != 0)
									{

										if (parseFloat(batchsn[z][8]) <= parseFloat(new_qt))
										{
											if (parseFloat(batchsn[z][8]) == parseFloat(new_qt))
											{
												silicon_ioi.ioiScanning.duplicate_batch_line(detail, i, batchsn[z][3], batchsn[z][4], batchsn[z][5], parseFloat(new_qt), qtyfield, parseFloat(batchsn[z][8]), false, batchsn[z][1], batchsn[z][2], qty_to_prepare_mode, batchsn[z][9]);


												batchsn[z][7] = parseFloat(batchsn[z][7]) + parseFloat(batchsn[z][8]);
												batchsn[z][8] = parseFloat(batchsn[z][8]) - parseFloat(batchsn[z][8]);

												new_qt = 0;
											}else
											{

												silicon_ioi.ioiScanning.duplicate_batch_line(detail, i, batchsn[z][3], batchsn[z][4], batchsn[z][5], parseFloat(new_qt), qtyfield, parseFloat(batchsn[z][8]), false, batchsn[z][1], batchsn[z][2], qty_to_prepare_mode, batchsn[z][9]);



												batchsn[z][7] = parseFloat(batchsn[z][7]) + parseFloat(batchsn[z][8]);
												batchsn[z][8] = parseFloat(batchsn[z][8]) - parseFloat(batchsn[z][8]);

												new_qt = parseFloat(new_qt) - parseFloat(batchsn[z][8]);


											}

										}else
										{
											silicon_ioi.ioiScanning.duplicate_batch_line(detail, i, batchsn[z][3], batchsn[z][4], batchsn[z][5], parseFloat(new_qt), qtyfield,  parseFloat(new_qt), false, batchsn[z][1], batchsn[z][2], qty_to_prepare_mode, batchsn[z][9]);

											batchsn[z][7] = parseFloat(batchsn[z][7]) + parseFloat(new_qt);
											batchsn[z][8] = parseFloat(batchsn[z][8]) - parseFloat(new_qt);

											new_qt = 0;

										}

									}
								}
							}

							if (parseFloat(new_qt) != 0)
							{
								silicon_ioi.ioiScanning.duplicate_batch_line(detail, i, '', '', '', parseFloat(new_qt), qtyfield, 0, true, batchsn[j][1], batchsn[j][2], qty_to_prepare_mode, batchsn[j][9]);

								batchsn[j][7] = parseFloat(batchsn[j][7]) + parseFloat(new_qt);
								batchsn[j][8] = parseFloat(batchsn[j][8]) - parseFloat(new_qt);

								new_qt = 0;
							}

						}


					}else
					{

						if (parseFloat(batchsn[j][8]) <= parseFloat(new_qt))
						{

							if (parseFloat(batchsn[j][8]) == parseFloat(new_qt))
							{
								silicon_ioi.ioiScanning.duplicate_batch_line(detail, i, batchsn[j][3], batchsn[j][4], batchsn[j][5], parseFloat(new_qt), qtyfield, parseFloat(batchsn[j][8]), false, batchsn[j][1], batchsn[j][2], qty_to_prepare_mode, batchsn[j][9]);

								batchsn[j][7] = parseFloat(batchsn[j][7]) + parseFloat(batchsn[j][8]);
								batchsn[j][8] = parseFloat(batchsn[j][8]) - parseFloat(batchsn[j][8]);
							}else
							{
								silicon_ioi.ioiScanning.duplicate_batch_line(detail, i, batchsn[j][3], batchsn[j][4], batchsn[j][5], parseFloat(new_qt), qtyfield, parseFloat(batchsn[j][8]), false, batchsn[j][1], batchsn[j][2], qty_to_prepare_mode, batchsn[j][9]);


								new_qt = parseFloat(new_qt) - parseFloat(batchsn[j][8]);

								batchsn[j][7] = parseFloat(batchsn[j][7]) + parseFloat(batchsn[j][8]);
								batchsn[j][8] = parseFloat(batchsn[j][8]) - parseFloat(batchsn[j][8]);


								if (parseFloat(new_qt) != 0)
								{

									for (var z = 0; z < batchsn.length; z++)
									{

										if ((batchsn[z][1] == batchsn[j][1]) && (batchsn[z][2] == batchsn[j][2]) && (batchsn[z][3] == batchsn[j][3]))
										{
											if (parseFloat(batchsn[z][8]) != 0)
											{
												if (parseFloat(batchsn[z][8]) <= parseFloat(new_qt))
												{
													if (parseFloat(batchsn[z][8]) == parseFloat(new_qt))
													{
														silicon_ioi.ioiScanning.duplicate_batch_line(detail, i, batchsn[z][3], batchsn[z][4], batchsn[z][5], parseFloat(new_qt), qtyfield, parseFloat(batchsn[z][8]), false, batchsn[z][1], batchsn[z][2], qty_to_prepare_mode, batchsn[z][9]);

														batchsn[z][7] = parseFloat(batchsn[z][7]) + parseFloat(batchsn[z][8]);
														batchsn[z][8] = parseFloat(batchsn[z][8]) - parseFloat(batchsn[z][8]);

														new_qt = 0;
													}else
													{
														silicon_ioi.ioiScanning.duplicate_batch_line(detail, i, batchsn[z][3], batchsn[z][4], batchsn[z][5], parseFloat(new_qt), qtyfield, parseFloat(batchsn[z][8]), false, batchsn[z][1], batchsn[z][2], qty_to_prepare_mode, batchsn[z][9]);

														new_qt = parseFloat(new_qt) - parseFloat(batchsn[z][8]);

														batchsn[z][7] = parseFloat(batchsn[z][7]) + parseFloat(batchsn[z][8]);
														batchsn[z][8] = parseFloat(batchsn[z][8]) - parseFloat(batchsn[z][8]);


													}

												}else
												{
													silicon_ioi.ioiScanning.duplicate_batch_line(detail, i, batchsn[z][3], batchsn[z][4], batchsn[z][5], parseFloat(new_qt), qtyfield,  parseFloat(new_qt), false, batchsn[z][1], batchsn[z][2], qty_to_prepare_mode, batchsn[z][9]);

													batchsn[z][7] = parseFloat(batchsn[z][7]) + parseFloat(new_qt);
													batchsn[z][8] = parseFloat(batchsn[z][8]) - parseFloat(new_qt);

													new_qt = 0;


												}

											}
										}
									}


									if (parseFloat(new_qt) != 0)
									{
										silicon_ioi.ioiScanning.duplicate_batch_line(detail, i, '', '', '', parseFloat(new_qt), qtyfield, 0, true, batchsn[j][1], batchsn[j][2], qty_to_prepare_mode, batchsn[j][9]);

										batchsn[j][7] = parseFloat(batchsn[j][7]) + parseFloat(new_qt);
										batchsn[j][8] = parseFloat(batchsn[j][8]) - parseFloat(new_qt);

									}

								}

							}

						}else
						{
							silicon_ioi.ioiScanning.duplicate_batch_line(detail, i, batchsn[j][3], batchsn[j][4], batchsn[j][5], parseFloat(new_qt), qtyfield, parseFloat(new_qt), true, batchsn[j][1], batchsn[j][2], qty_to_prepare_mode, batchsn[j][9]);

							batchsn[j][7] = parseFloat(batchsn[j][7]) + parseFloat(new_qt);
							batchsn[j][8] = parseFloat(batchsn[j][8]) - parseFloat(new_qt);


						}

					}


					if (update_loc)
					{
						if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
						{
							silicon_ioi.ioiScanning.sleep_static(200).then(() => {

								// Location
								cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["from_warehouse_location_id"] = batchsn[a][2];
								script_manager.trigger("from_warehouse_location_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx- 1].doc.name);
								cur_frm.refresh_field(detail);

							});

						}else{
							silicon_ioi.ioiScanning.sleep_static(200).then(() => {

								// Location
								cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["warehouse_location_id"] = batchsn[a][2];
								script_manager.trigger("warehouse_location_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx- 1].doc.name);
								cur_frm.refresh_field(detail);

							});
						}

					}



				}else
				{
					if (cur_frm.doc[detail][i].batch_sn_id == batchsn[j][3])
					{


						let a = -1;


						if (!update_loc)
						{
							silicon_ioi.ioiScanning.update_batch_line(detail, i, 'N', '', '', batchsn[j][3], batchsn[j][4], batchsn[j][5], parseFloat(batchsn[j][8]), qtyfield, parseFloat(batchsn[j][8]), qty_to_prepare_mode, batchsn[j][9]);

							batchsn[j][7] = parseFloat(batchsn[j][7]) + parseFloat(batchsn[j][8]);
							batchsn[j][8] = parseFloat(batchsn[j][8]) - parseFloat(batchsn[j][8]);

						}else
						{
							if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
							{
								cur_frm.doc[detail][i].from_warehouse_id = batchsn[j][1];
								cur_frm.doc[detail][i].from_warehouse_location_id = batchsn[j][2];

								// Warehouse
								cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["from_warehouse_id"] = batchsn[j][1];
								script_manager.trigger("from_warehouse_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx- 1].doc.name);
								cur_frm.refresh_field(detail);
							}else{

								cur_frm.doc[detail][i].warehouse_id = batchsn[j][1];
								cur_frm.doc[detail][i].warehouse_location_id = batchsn[j][2];

								// Warehouse
								cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["warehouse_id"] = batchsn[j][1];
								script_manager.trigger("warehouse_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx- 1].doc.name);
								cur_frm.refresh_field(detail);
							}
							silicon_ioi.ioiScanning.update_batch_line_next(detail, i, 'Y', batchsn[j][1], batchsn[j][2], batchsn[j][3], batchsn[j][4], batchsn[j][5], parseFloat(batchsn[j][8]), qtyfield, parseFloat(batchsn[j][8]), qty_to_prepare_mode, batchsn[j][9]);

							batchsn[j][7] = parseFloat(batchsn[j][7]) + parseFloat(batchsn[j][8]);
							batchsn[j][8] = parseFloat(batchsn[j][8]) - parseFloat(batchsn[j][8]);

							a = j;

						}

						let new_qt = (parseFloat(qty_to_prepare) - parseFloat(delivered_qty)) - parseFloat(batchsn[j][8]);

						if (parseFloat(batchsn[j][8]) <= parseFloat(new_qt))
						{

							if (parseFloat(batchsn[j][8]) == parseFloat(new_qt))
							{
								silicon_ioi.ioiScanning.duplicate_batch_line(detail, i, batchsn[j][3], batchsn[j][4], batchsn[j][5], parseFloat(new_qt), qtyfield, parseFloat(batchsn[j][8]), false, batchsn[j][1], batchsn[j][2], qty_to_prepare_mode, batchsn[j][9]);

								batchsn[j][7] = parseFloat(batchsn[j][7]) + parseFloat(batchsn[j][8]);
								batchsn[j][8] = parseFloat(batchsn[j][8]) - parseFloat(batchsn[j][8]);
							}else
							{
								silicon_ioi.ioiScanning.duplicate_batch_line(detail, i, batchsn[j][3], batchsn[j][4], batchsn[j][5], parseFloat(new_qt), qtyfield, parseFloat(batchsn[j][8]), false, batchsn[j][1], batchsn[j][2], qty_to_prepare_mode, batchsn[j][9]);

								new_qt = parseFloat(new_qt) - parseFloat(batchsn[j][8]);

								batchsn[j][7] = parseFloat(batchsn[j][7]) + parseFloat(batchsn[j][8]);
								batchsn[j][8] = parseFloat(batchsn[j][8]) - parseFloat(batchsn[j][8]);


								if (parseFloat(new_qt) != 0)
								{
									for (var z = 0; z < batchsn.length; z++)
									{
										if ((batchsn[z][1] == batchsn[j][1]) && (batchsn[z][2] == batchsn[j][2]) && (batchsn[z][3] == batchsn[j][3]))
										{
											if (parseFloat(batchsn[z][8]) != 0)
											{
												if (parseFloat(batchsn[z][8]) <= parseFloat(new_qt))
												{
													if (parseFloat(batchsn[z][8]) == parseFloat(new_qt))
													{
														silicon_ioi.ioiScanning.duplicate_batch_line(detail, i, batchsn[z][3], batchsn[z][4], batchsn[z][5], parseFloat(new_qt), qtyfield, parseFloat(batchsn[z][8]), false, batchsn[z][1], batchsn[z][2], qty_to_prepare_mode, batchsn[z][9]);

														batchsn[z][7] = parseFloat(batchsn[z][7]) + parseFloat(batchsn[z][8]);
														batchsn[z][8] = parseFloat(batchsn[z][8]) - parseFloat(batchsn[z][8]);

														new_qt = 0;
													}else
													{
														silicon_ioi.ioiScanning.duplicate_batch_line(detail, i, batchsn[z][3], batchsn[z][4], batchsn[z][5], parseFloat(new_qt), qtyfield, parseFloat(batchsn[z][8]), false, batchsn[z][1], batchsn[z][2], qty_to_prepare_mode, batchsn[z][9]);

														new_qt = parseFloat(new_qt) - parseFloat(batchsn[z][8]);

														batchsn[z][7] = parseFloat(batchsn[z][7]) + parseFloat(batchsn[z][8]);
														batchsn[z][8] = parseFloat(batchsn[z][8]) - parseFloat(batchsn[z][8]);


													}

												}else
												{
													silicon_ioi.ioiScanning.duplicate_batch_line(detail, i, batchsn[z][3], batchsn[z][4], batchsn[z][5], parseFloat(new_qt), qtyfield,  parseFloat(new_qt), false, batchsn[z][1], batchsn[z][2], qty_to_prepare_mode, batchsn[z][9]);

													batchsn[z][7] = parseFloat(batchsn[z][7]) + parseFloat(new_qt);
													batchsn[z][8] = parseFloat(batchsn[z][8]) - parseFloat(new_qt);

													new_qt = 0;
												}

											}
										}
									}


									if (parseFloat(new_qt) != 0)
									{
										silicon_ioi.ioiScanning.duplicate_batch_line(detail, i, '', '', '', parseFloat(new_qt), qtyfield, 0, true, batchsn[j][1], batchsn[j][2], qty_to_prepare_mode, batchsn[j][9]);

										batchsn[j][7] = parseFloat(batchsn[j][7]) + parseFloat(new_qt);
										batchsn[j][8] = parseFloat(batchsn[j][8]) - parseFloat(new_qt);

									}

								}

							}

						}else
						{
							silicon_ioi.ioiScanning.duplicate_batch_line(detail, i, batchsn[j][3], batchsn[j][4], batchsn[j][5], parseFloat(new_qt), qtyfield,  parseFloat(new_qt), true, batchsn[j][1], batchsn[j][2], qty_to_prepare_mode, batchsn[j][9]);

							batchsn[j][7] = parseFloat(batchsn[j][7]) + parseFloat(new_qt);
							batchsn[j][8] = parseFloat(batchsn[j][8]) - parseFloat(new_qt);


						}



						if (update_loc)
						{
							if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
							{

								silicon_ioi.ioiScanning.sleep_static(200).then(() => {

									// Location
									cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["from_warehouse_location_id"] = batchsn[a][2];
									script_manager.trigger("from_warehouse_location_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx- 1].doc.name);
									cur_frm.refresh_field(detail);

								});

							}else{

								silicon_ioi.ioiScanning.sleep_static(200).then(() => {

									// Location
									cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["warehouse_location_id"] = batchsn[a][2];
									script_manager.trigger("warehouse_location_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx- 1].doc.name);
									cur_frm.refresh_field(detail);

								});
							}
						}

					}
				}
			}

		}else
		{
			// batch qt >
			let new_qt = parseFloat(qty_to_prepare) - parseFloat(delivered_qty);

			let a = -1;

			if (!update_loc)
			{
				silicon_ioi.ioiScanning.update_batch_line(detail, i, 'N', '', '', batchsn[j][3], batchsn[j][4], batchsn[j][5], parseFloat(new_qt), qtyfield, parseFloat(new_qt), qty_to_prepare_mode, batchsn[j][9]);

				batchsn[j][7] = parseFloat(batchsn[j][7]) + parseFloat(new_qt);
				batchsn[j][8] = parseFloat(batchsn[j][8]) - parseFloat(new_qt);

				new_qt = batchsn[j][8];

			}else
			{
				if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
				{
					cur_frm.doc[detail][i].from_warehouse_id = batchsn[j][1];
					cur_frm.doc[detail][i].from_warehouse_location_id = batchsn[j][2];

					// Warehouse
					cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["from_warehouse_id"] = batchsn[j][1];
					script_manager.trigger("from_warehouse_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx- 1].doc.name);
					cur_frm.refresh_field(detail);
				}else{

					cur_frm.doc[detail][i].warehouse_id = batchsn[j][1];
					cur_frm.doc[detail][i].warehouse_location_id = batchsn[j][2];

					// Warehouse
					cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["warehouse_id"] = batchsn[j][1];
					script_manager.trigger("warehouse_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx- 1].doc.name);
					cur_frm.refresh_field(detail);
				}

				silicon_ioi.ioiScanning.update_batch_line_next(detail, i, 'Y', batchsn[j][1], batchsn[j][2], batchsn[j][3], batchsn[j][4], batchsn[j][5], parseFloat(new_qt), qtyfield, parseFloat(new_qt), qty_to_prepare_mode, batchsn[j][9]);

				batchsn[j][7] = parseFloat(batchsn[j][7]) + parseFloat(new_qt);
				batchsn[j][8] = parseFloat(batchsn[j][8]) - parseFloat(new_qt);

				new_qt = batchsn[j][8];

				a = j;

			}

			new_qt = 0;

			if (parseFloat(new_qt) != 0)
			{
				for (var z = 0; z < batchsn.length; z++)
				{
					if ((batchsn[z][1] == batchsn[j][1]) && (batchsn[z][2] == batchsn[j][2]) && (batchsn[z][3] == batchsn[j][3]))
					{
						if (parseFloat(batchsn[z][8]) != 0)
						{
							if (parseFloat(batchsn[z][8]) <= parseFloat(new_qt))
							{
								if (parseFloat(batchsn[z][8]) == parseFloat(new_qt))
								{
									//silicon_ioi.ioiScanning.duplicate_batch_line(detail, i, batchsn[z][3], batchsn[z][4], batchsn[z][5], parseFloat(new_qt), qtyfield, parseFloat(batchsn[z][8]), false, batchsn[z][1], batchsn[z][2], qty_to_prepare_mode);
									silicon_ioi.ioiScanning.duplicate_batch_line(detail, i, batchsn[z][3], batchsn[z][4], batchsn[z][5], parseFloat(new_qt), qtyfield, 0, false, batchsn[z][1], batchsn[z][2], qty_to_prepare_mode, batchsn[z][9]);

									batchsn[z][7] = parseFloat(batchsn[z][7]) + parseFloat(batchsn[z][8]);
									batchsn[z][8] = parseFloat(batchsn[z][8]) - parseFloat(batchsn[z][8]);

									new_qt = 0;
								}else
								{
									// silicon_ioi.ioiScanning.duplicate_batch_line(detail, i, batchsn[z][3], batchsn[z][4], batchsn[z][5], parseFloat(new_qt), qtyfield, parseFloat(batchsn[z][8]), false, batchsn[z][1], batchsn[z][2], qty_to_prepare_mode);
									silicon_ioi.ioiScanning.duplicate_batch_line(detail, i, batchsn[z][3], batchsn[z][4], batchsn[z][5], parseFloat(new_qt), qtyfield, 0, false, batchsn[z][1], batchsn[z][2], qty_to_prepare_mode, batchsn[z][9]);

									new_qt = parseFloat(new_qt) - parseFloat(batchsn[z][8]);

									batchsn[z][7] = parseFloat(batchsn[z][7]) + parseFloat(batchsn[z][8]);
									batchsn[z][8] = parseFloat(batchsn[z][8]) - parseFloat(batchsn[z][8]);


								}

							}else
							{
								// silicon_ioi.ioiScanning.duplicate_batch_line(detail, i, batchsn[z][3], batchsn[z][4], batchsn[z][5], parseFloat(new_qt), qtyfield, parseFloat(new_qt), false, batchsn[z][1], batchsn[z][2], qty_to_prepare_mode);
								silicon_ioi.ioiScanning.duplicate_batch_line(detail, i, batchsn[z][3], batchsn[z][4], batchsn[z][5], parseFloat(new_qt), qtyfield, 0, false, batchsn[z][1], batchsn[z][2], qty_to_prepare_mode, batchsn[z][9]);

								batchsn[z][7] = parseFloat(batchsn[z][7]) + parseFloat(new_qt);
								batchsn[z][8] = parseFloat(batchsn[z][8]) - parseFloat(new_qt);

								new_qt = 0;
							}

						}
					}
				}


				if (parseFloat(new_qt) != 0)
				{
					silicon_ioi.ioiScanning.duplicate_batch_line(detail, i, '', '', '', parseFloat(new_qt), qtyfield, 0, true, batchsn[j][1], batchsn[j][2], qty_to_prepare_mode, batchsn[j][9]);

					batchsn[j][7] = parseFloat(batchsn[j][7]) + parseFloat(new_qt);
					batchsn[j][8] = parseFloat(batchsn[j][8]) - parseFloat(new_qt);

				}

			}

			if (update_loc)
			{
				if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
				{
					silicon_ioi.ioiScanning.sleep_static(200).then(() => {

						// Location
						cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["from_warehouse_location_id"] = batchsn[a][2];
						script_manager.trigger("from_warehouse_location_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx- 1].doc.name);
						cur_frm.refresh_field(detail);

					});
				}else
				{
					silicon_ioi.ioiScanning.sleep_static(200).then(() => {

						// Location
						cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["warehouse_location_id"] = batchsn[a][2];
						script_manager.trigger("warehouse_location_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx- 1].doc.name);
						cur_frm.refresh_field(detail);

					});

				}


			}


			if (qty_to_prepare_mode == 0)
			{
				alert("mode normal")
			}
		}


	}



	static update_batch_line(detail, detail_index, update_loc, whs, loc, batchsn_id, batch_sn_code_ref, batch_sn_origin, qty_to_prepare, qtyfield, qty, qty_to_prepare_mode, qcref)
	{

		silicon_ioi.ioiScanning.update_batch_line_next(detail, detail_index, update_loc, whs, loc, batchsn_id, batch_sn_code_ref, batch_sn_origin, qty_to_prepare, qtyfield, qty, qty_to_prepare_mode, qcref);

	}

	static update_batch_line_next(detail, detail_index, update_loc, whs, loc, batchsn_id, batch_sn_code_ref, batch_sn_origin, qty_to_prepare, qtyfield, qty, qty_to_prepare_mode, qcref)
	{

		let current_idx = cur_frm.doc[detail][detail_index].idx;

		let script_manager = cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].frm.script_manager;

		cur_frm.doc[detail][detail_index].batch_sn_id = batchsn_id;
		cur_frm.doc[detail][detail_index].batch_sn_code_ref = batch_sn_code_ref;
		cur_frm.doc[detail][detail_index].batch_sn_origin = batch_sn_origin;

		if (cur_frm.doctype.toUpperCase() != 'IOI STOCK TRANSFER')
		{
			cur_frm.doc[detail][detail_index].qcsupplier_ref = qcref;
		}

		if (qty_to_prepare_mode == 1) {
			cur_frm.doc[detail][detail_index].qty_to_prepare = parseFloat(qty_to_prepare);
		}else if (qty_to_prepare_mode == 0) {

			if (cur_frm.doctype.toUpperCase() != 'IOI STOCK TRANSFER')
			{
				cur_frm.doc[detail][detail_index].ordered_qty = parseFloat(qty_to_prepare);
			}
		}

		if (cur_frm.doctype.toUpperCase() != 'IOI STOCK TRANSFER')
		{
			cur_frm.doc[detail][detail_index].delivered_qty = parseFloat(qty);
		}else
		{
			cur_frm.doc[detail][detail_index].transferred_qty = parseFloat(qty);
		}


		// Batch/SN Id
		cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["batch_sn_id"] = batchsn_id;
		script_manager.trigger("batch_sn_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx- 1].doc.name);
		cur_frm.refresh_field(detail);


		// Batch/SN Code ref
		cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["batch_sn_code_ref"] = batch_sn_code_ref;
		script_manager.trigger("batch_sn_code_ref", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
		cur_frm.refresh_field(detail);

		// Batch/SN Code origin
		cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["batch_sn_origin"] = batch_sn_origin;
		script_manager.trigger("batch_sn_origin", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
		cur_frm.refresh_field(detail);

		if (cur_frm.doctype.toUpperCase() != 'IOI STOCK TRANSFER')
		{
			// QCSupplierRef
			cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc["qcsupplier_ref"] = qcref;
			script_manager.trigger("qcsupplier_ref", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
			cur_frm.refresh_field(detail);
		}



		if (qty_to_prepare_mode == 1)
		{
			// Qty to Prepare
			cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc['qty_to_prepare'] = parseFloat(qty_to_prepare);
			script_manager.trigger('qty_to_prepare', cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
			cur_frm.refresh_field(detail);

		}else if (qty_to_prepare_mode == 0)
		{
			if (cur_frm.doctype.toUpperCase() != 'IOI STOCK TRANSFER')
			{
				// Ordered Qty
				cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc['ordered_qty'] = parseFloat(qty_to_prepare);
				script_manager.trigger('ordered_qty', cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
				cur_frm.refresh_field(detail);

			}
		}


		// Qty
		cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc[qtyfield] = parseFloat(qty);
		script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name);
		cur_frm.refresh_field(detail);

	}


	static duplicate_batch_line(detail, detail_index, batchsn_id, batch_sn_code_ref, batch_sn_origin, qty_to_prepare, qtyfield, qty, clear_whs_batch = true, whs = '', loc = '', qty_to_prepare_mode = 1, qcref = '')
	{

		let current_idx = cur_frm.doc[detail][detail_index].idx;
		let script_manager = cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].frm.script_manager;


		let d = cur_frm.fields_dict[detail].grid.add_new_row();

		let item = locals[cur_frm.fields_dict[detail].grid.doctype][cur_frm.fields_dict[detail].grid.grid_rows[current_idx - 1].doc.name];


		silicon_ioi.ioiScanning.sleep_static(200).then(() => {


			cur_frm.fields_dict[detail].grid.duplicate_row(d, item);
			cur_frm.fields_dict[detail].grid.refresh();



			if (detail == silicon_ioi.ioiScanning.field_detail1) {
				document.getElementById('sb_main_scan_detail_row_idx').value = d.idx.toString();
			} else if (detail == silicon_ioi.ioiScanning.field_detail2) {
				document.getElementById('sb_main_scan_detail2_row_idx').value = d.idx.toString();
			} else if (detail == silicon_ioi.ioiScanning.field_detail3) {
				document.getElementById('sb_main_scan_detail3_row_idx').value = d.idx.toString();
			} else if (detail == silicon_ioi.ioiScanning.field_detail4) {
				document.getElementById('sb_main_scan_detail4_row_idx').value = d.idx.toString();
			} else if (detail == silicon_ioi.ioiScanning.field_detail5) {
				document.getElementById('sb_main_scan_detail5_row_idx').value = d.idx.toString();
			} else if (detail == silicon_ioi.ioiScanning.field_detail6) {
				document.getElementById('sb_main_scan_detail6_row_idx').value = d.idx.toString();
			}

			let cur_whs = '';
			let cur_loc = '';
			let cur_batch = '';
			let cur_coderef = '';
			let cur_origin = '';
			let cur_qcref = '';

			if (!clear_whs_batch)
			{

				cur_whs = whs;
				cur_loc = loc;
				cur_batch = batchsn_id;
				cur_coderef = batch_sn_code_ref;
				cur_origin = batch_sn_origin;
				cur_qcref = qcref;

			}

			if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
			{
				// Warehouse
				cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["from_warehouse_id"] = cur_whs;
				script_manager.trigger("from_warehouse_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
				cur_frm.refresh_field(detail);

			}else
			{
				// Warehouse
				cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["warehouse_id"] = cur_whs;
				script_manager.trigger("warehouse_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
				cur_frm.refresh_field(detail);
			}

			// Batch/SN Id
			cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["batch_sn_id"] = cur_batch;
			script_manager.trigger("batch_sn_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
			cur_frm.refresh_field(detail);


			// Batch/SN Code ref
			cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["batch_sn_code_ref"] = cur_coderef;
			script_manager.trigger("batch_sn_code_ref", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
			cur_frm.refresh_field(detail);

			// Batch/SN Code origin
			cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["batch_sn_origin"] = cur_origin;
			script_manager.trigger("batch_sn_origin", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
			cur_frm.refresh_field(detail);

			if (cur_frm.doctype.toUpperCase() != 'IOI STOCK TRANSFER')
			{
				// QCSupplierRef
				cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc["qcsupplier_ref"] = cur_qcref;
				script_manager.trigger("qcsupplier_ref", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
				cur_frm.refresh_field(detail);
			}


			if (qty_to_prepare_mode == 1)
			{
				// Qty to prepare
				cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc['qty_to_prepare'] = parseFloat(qty_to_prepare);
				script_manager.trigger('qty_to_prepare', cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
				cur_frm.refresh_field(detail);
			}else if (qty_to_prepare_mode == 0)
			{
				if (cur_frm.doctype.toUpperCase() != 'IOI STOCK TRANSFER')
				{
					// Ordered Qty
					cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc['ordered_qty'] = parseFloat(qty_to_prepare);
					script_manager.trigger('ordered_qty', cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
					cur_frm.refresh_field(detail);
				}
			}


			// Qty
			cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc[qtyfield] = parseFloat(qty);
			script_manager.trigger(qtyfield, cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[d.idx - 1].doc.name);
			cur_frm.refresh_field(detail);

			if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
			{
				cur_frm.doc[detail][d.idx - 1].from_warehouse_id = cur_whs;
				cur_frm.doc[detail][d.idx - 1].from_warehouse_location_id = cur_loc;

			}else{
				cur_frm.doc[detail][d.idx - 1].warehouse_id = cur_whs;
				cur_frm.doc[detail][d.idx - 1].warehouse_location_id = cur_loc;

			}


			cur_frm.doc[detail][d.idx - 1].batch_sn_id = cur_batch;
			cur_frm.doc[detail][d.idx - 1].batch_sn_code_ref = cur_coderef;
			cur_frm.doc[detail][d.idx - 1].batch_sn_origin = cur_origin;

			if (cur_frm.doctype.toUpperCase() != 'IOI STOCK TRANSFER')
			{
				cur_frm.doc[detail][d.idx - 1].qcsupplier_ref = cur_qcref;
			}

			if (qty_to_prepare_mode == 1) {
				cur_frm.doc[detail][d.idx - 1].qty_to_prepare = parseFloat(qty_to_prepare);
			}else if (qty_to_prepare_mode == 0) {

				if (cur_frm.doctype.toUpperCase() != 'IOI STOCK TRANSFER')
				{
					cur_frm.doc[detail][d.idx - 1].ordered_qty = parseFloat(qty_to_prepare);
				}
			}

			if (cur_frm.doctype.toUpperCase() != 'IOI STOCK TRANSFER')
			{
				cur_frm.doc[detail][d.idx - 1].delivered_qty = parseFloat(qty);
			}else
			{
				cur_frm.doc[detail][d.idx - 1].transferred_qty = parseFloat(qty);
			}


			let idx = d.idx - 1;

			if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')
			{
				silicon_ioi.ioiScanning.sleep_static(200).then(() => {

					// Location
					cur_frm.fields_dict[detail].grid.grid_rows[idx].doc["from_warehouse_location_id"] = cur_loc;
					script_manager.trigger("from_warehouse_location_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[idx].doc.name);
					cur_frm.refresh_field(detail);

				});
			}else{
				silicon_ioi.ioiScanning.sleep_static(200).then(() => {

					// Location
					cur_frm.fields_dict[detail].grid.grid_rows[idx].doc["warehouse_location_id"] = cur_loc;
					script_manager.trigger("warehouse_location_id", cur_frm.fields_dict[detail].grid.doctype, cur_frm.fields_dict[detail].grid.grid_rows[idx].doc.name);
					cur_frm.refresh_field(detail);

				});
			}

		});

	}

	// ***************************************************************************************************************************************
	// Warehouse window
	// ***************************************************************************************************************************************

	static warehouse_window(tp)
	{
		let detail = 'line_detail';
		let idx = 0;


		if (silicon_ioi.ioiScanning.container.toUpperCase() != 'FORM') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Function not available here") + '</font>';
			return;
		}

		let can_go = false;

		if ((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST')	|| (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') 	||
			(cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE') 			|| (cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE')		|| (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER')			||
			(cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')				|| (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE')	|| (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY')			||
			(cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT')				|| (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')) {
			can_go = true;
		}

		if (!can_go) {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Function not available here") + '</font>';
			return;
		}


		if (cur_frm.fields_dict['ioistatus']) {
			if (cur_frm.doc.ioistatus != 0) {
				document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document is not editable") + '</font>';
				return;
			}else{
				if (cur_frm.doctype == 'ioi Sales Delivery') {

					if (cur_frm.doc.locked_by_credit_control == 1) {
						can_duplicate = false;
						document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document has to be signed (credit control)") + '</font>';
						return;
					}
				}
			}
		}

		if (cur_frm.fields_dict['docstatus']) {
			if (cur_frm.doc.docstatus == 1) {
				document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document is not editable") + '</font>';
				return;
			}
		}

		if (cur_frm.get_field(detail).grid) {
			if (cur_frm.get_field(detail).grid.grid_rows)
			{
				for (var i = 0; i < cur_frm.get_field(detail).grid.grid_rows.length; i++) {

					if (cur_frm.get_field(detail).grid.grid_rows[i].row_check[0].children[0].checked)
					{
						idx = i + 1;

						document.getElementById('sb_main_scan_detail_row_idx').value = idx.toString();

						break;
					}

				}
			}
		}

		if (idx == 0)
		{
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Select a detail line before") + '</font>';
			return;
		}


		let fieldname = '';


		if ((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST')	|| (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') 	||
			(cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE') 			|| (cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE')		|| (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER')			||
			(cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')				|| (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE')	|| (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY')			||
			(cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT')) {

			fieldname = 'warehouse_id';

		}else if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER') {

			if ((tp.toUpperCase() == 'W') || (tp.toUpperCase() == 'FW')) {
				fieldname = 'from_warehouse_id';
			}else{
				fieldname = 'to_warehouse_id';
			}

		}

		let title = __("Select a warehouse");
		let form_width_pixel = '580px';
		let table = "ioi Warehouse";
		let fields = 'name, description';
		let fields_len = '200, 300';
		let fields_desc = __('Identification') + ',' + __('Description');
		let where = "enabled = 1 and site_id = '" + cur_frm.doc.site_id.replaceAll("'", "''") + "' ";
		let order_by = 'name asc';

		let fct_callback = function (return_value) {

			cur_frm.fields_dict[detail].grid.grid_rows[idx - 1].doc[fieldname] = return_value;

			let script_manager = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].frm.script_manager;
			script_manager.trigger(fieldname, cur_frm.fields_dict['line_detail'].grid.doctype, cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name);
			cur_frm.refresh_field('line_detail');
		}

		silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);


	}

	// ***************************************************************************************************************************************
	// Warehouse location window
	// ***************************************************************************************************************************************

	static warehouse_location_window(tp)
	{
		let detail = 'line_detail';
		let idx = 0;


		if (silicon_ioi.ioiScanning.container.toUpperCase() != 'FORM') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Function not available here") + '</font>';
			return;
		}

		let can_go = false;

		if ((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') 	|| (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')	|| (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY')	||
			(cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT')		|| (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')) {
			can_go = true;
		}

		if (!can_go) {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Function not available here") + '</font>';
			return;
		}


		if (cur_frm.fields_dict['ioistatus']) {
			if (cur_frm.doc.ioistatus != 0) {
				document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document is not editable") + '</font>';
				return;
			}else{
				if (cur_frm.doctype == 'ioi Sales Delivery') {

					if (cur_frm.doc.locked_by_credit_control == 1) {
						can_duplicate = false;
						document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document has to be signed (credit control)") + '</font>';
						return;
					}
				}
			}
		}

		if (cur_frm.fields_dict['docstatus']) {
			if (cur_frm.doc.docstatus == 1) {
				document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document is not editable") + '</font>';
				return;
			}
		}

		if (cur_frm.get_field(detail).grid) {
			if (cur_frm.get_field(detail).grid.grid_rows)
			{
				for (var i = 0; i < cur_frm.get_field(detail).grid.grid_rows.length; i++) {

					if (cur_frm.get_field(detail).grid.grid_rows[i].row_check[0].children[0].checked)
					{
						idx = i + 1;

						document.getElementById('sb_main_scan_detail_row_idx').value = idx.toString();

						break;
					}

				}
			}
		}

		if (idx == 0)
		{
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Select a detail line before") + '</font>';
			return;
		}


		let grid_buttons = document.getElementsByClassName('grid-field')[0].getElementsByClassName('small form-clickable-section grid-footer')[0].getElementsByClassName('grid-buttons')[0];

		if ((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') 	|| (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')	|| (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY')	||
			(cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT')) {

			grid_buttons.children[0].click();

		}else if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER') {

			if ((tp.toUpperCase() == 'L') || (tp.toUpperCase() == 'FL')) {
				grid_buttons.children[0].click();
			}else{
				grid_buttons.children[2].click();
			}

		}

	}


	// ***************************************************************************************************************************************
	// Batch/SN window
	// ***************************************************************************************************************************************

	static batch_window()
	{
		let detail = 'line_detail';
		let idx = 0;


		if (silicon_ioi.ioiScanning.container.toUpperCase() != 'FORM') {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Function not available here") + '</font>';
			return;
		}

		let can_go = false;

		if ((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') 	|| (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')	|| (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY')	||
			(cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT')		|| (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER')) {
			can_go = true;
		}

		if (!can_go) {
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Function not available here") + '</font>';
			return;
		}


		if (cur_frm.fields_dict['ioistatus']) {
			if (cur_frm.doc.ioistatus != 0) {
				document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document is not editable") + '</font>';
				return;
			}else{
				if (cur_frm.doctype == 'ioi Sales Delivery') {

					if (cur_frm.doc.locked_by_credit_control == 1) {
						can_duplicate = false;
						document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document has to be signed (credit control)") + '</font>';
						return;
					}
				}
			}
		}

		if (cur_frm.fields_dict['docstatus']) {
			if (cur_frm.doc.docstatus == 1) {
				document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Document is not editable") + '</font>';
				return;
			}
		}

		if (cur_frm.get_field(detail).grid) {
			if (cur_frm.get_field(detail).grid.grid_rows)
			{
				for (var i = 0; i < cur_frm.get_field(detail).grid.grid_rows.length; i++) {

					if (cur_frm.get_field(detail).grid.grid_rows[i].row_check[0].children[0].checked)
					{
						idx = i + 1;

						document.getElementById('sb_main_scan_detail_row_idx').value = idx.toString();

						break;
					}

				}
			}
		}

		if (idx == 0)
		{
			document.getElementById('sb_main_scan_msg').innerHTML = '<font color=red>' + __("Select a detail line before") + '</font>';
			return;
		}


		let grid_buttons = document.getElementsByClassName('grid-field')[0].getElementsByClassName('small form-clickable-section grid-footer')[0].getElementsByClassName('grid-buttons')[0];

		if ((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') 	|| (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')	|| (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY')	||
			(cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT')) {

			grid_buttons.children[2].click();

		}else if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER') {

			grid_buttons.children[4].click();

		}

	}


	// ***************************************************************************************************************************************
	// Check if hidden field exists else create
	// ***************************************************************************************************************************************
	static check_hidden_detail_row_id(hidden_detail_row_idx) {

		if (!document.getElementById(hidden_detail_row_idx)) {

			let element = document.createElement("INPUT");
			element.setAttribute("type", "hidden");
			element.setAttribute("id", hidden_detail_row_idx);
			element.setAttribute("value", "");

			document.getElementsByClassName('container')[0].getElementsByClassName('collapse navbar-collapse justify-content-end')[0].children[0].appendChild(element);
		}
	}

	// ***************************************************************************************************************************************
	// Sleep
	// ***************************************************************************************************************************************
	static sleep_static(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}

}

silicon_ioi.ioiScanning = ioiScanning;

silicon_ioi.ioiScanning.Html5QrcodeScanner = Html5QrcodeScanner;
