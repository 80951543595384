frappe.provide('silicon-ioi');

class ADate extends frappe.ui.form.ControlDate {

	make_input() {
		super.make_input();
		this.make_picker();

		// VVR:
		this.$input
			.on("focus", function () {
				setTimeout(function () {
					if (!document.activeElement) return;
					try {document.activeElement.select();} catch (err) {}
				}, 100);
				return false;
			});

	}

	get_status(explain) {
		let r = super.get_status(explain);

		// VVR: revert null & read-only field as visible
		if (r === "None" && !cint(this.df.hidden) && !cint(this.df.hidden_due_to_dependency)) {
			r = "Read";
		}

		return r;
	}

};

// overriding frappe class
frappe.ui.form.ControlDate = ADate;
