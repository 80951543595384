// *******************************************************************************************************************************************
// Parent Class of all Pivot Document Doctypes : for Dossiers, Productions, ...
// *******************************************************************************************************************************************

frappe.provide('silicon_ioi.doctype');

export class ioiPivotDocumentDocType extends silicon_ioi.doctype.ioiDocType
{
	constructor(frm) 
	{
		super(frm);
	}
}

silicon_ioi.doctype.ioiPivotDocumentDocType = ioiPivotDocumentDocType;