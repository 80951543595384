frappe.provide("frappe.views");

export default class ioiMapView extends frappe.views.MapView {

	// setup_view() {
	// 	debugger;
	// 	super.setup_view();
	// 	this.settings.get_coords_method = 'test';
	// }

	get_coords() {
		let get_coords_method =
			(this.settings && this.settings.get_coords_method) || "frappe.geo.utils.get_coords";

		if (
			cur_list.meta.fields.find(
				(i) => i.fieldname === "location" && i.fieldtype === "Geolocation"
			)
		) {
			this.type = "location_field";
		} else if (
			cur_list.meta.fields.find((i) => i.fieldname === "latitude") &&
			cur_list.meta.fields.find((i) => i.fieldname === "longitude")
		) {
			this.type = "coordinates";
		}
		return frappe
			.call({
				method: get_coords_method,
				args: {
					doctype: this.doctype,
					filters: cur_list.filter_area.get(),
					type: this.type,
				},
			})
			.then((r) => {
				this.coords = r.message;

				if (this.coords.features){
					for (var idx = 0; idx < this.coords.features.length; idx++)
					{
						this.coords.features[idx].geometry.coordinates.reverse();
					}
				}				
			});
	}
}

frappe.views.MapView = ioiMapView;