frappe.provide('silicon_ioi.doctype');

export class ioiJITEngineLinkFrame
{
	static show_scheduled_operations(html_fieldname, doctype = null)
	{
		cur_frm.fields_dict[html_fieldname].$wrapper.empty();

		if (!cur_frm.is_new()) {

			let dc = cur_frm.doctype;

			if ((doctype != null) && (doctype.trim() != '')) {
				dc = doctype;
			}

			let method = 'silicon_ioi.ioi_jit.doctype.ioi_jit_engine_link.ioi_jit_engine_link.ioi_jit_engine_link_get_my_links';

			let data = [];

			frappe.call({  	method: method,
							args: {'doctype': dc, 'name': cur_frm.doc.name},
							async: false,
							callback:function(r)	{
								data = r.message;
							}
			});

			let html = '';

			let table_width = 1060;
			let col1_width = 230;
			let col2_width = 300;
			let col3_width = 230;
			let col4_width = 300;

			html += '<div style="overflow: auto; overflow-x: auto; height:800px; width: 100%;">';

			html += '<table border=0 style="border: 0px solid #E8EAEB" width=' + table_width.toString() + 'px >';
			html += '<tr style="height:15px">';
			html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;"><b><u>' + __("Origin") + '</u></b></td>';
			html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;"><b><u>' + __("Destination") + '</u></b></td>';
			html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
			html += '</tr>';

			html += '</table>';

			if (data.length == 0) {

				html += '<table border=0 style="border: 0px solid #E8EAEB" width=' + table_width.toString() + 'px>';
				html += '<tr style="height:30px">';
				html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
				html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
				html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
				html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
				html += '</tr>';
				html += '</table>';
			}else{
				html += '<table border=0 style="border: 0px solid #E8EAEB" width=' + table_width.toString() + 'px>';

				for (var i = 0; i < data.length; i++) {

					// *******************************************************************************************************************************************************************************************************
					// Document
					// *******************************************************************************************************************************************************************************************************
					html += '<tr style="height:30px">';
					html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;"><div style="position:relative; top: 10px; left: 0px; width: 15px; height: 15px; border-radius: 50%; background:' + data[i].origin_ioistatus_bgcolor + ';"></div>';
					html += '<b><label style="position:relative; left:25px; top:-7px;height:15px;" ';


					if (data[i].origin_doctype != cur_frm.doctype) {

						let url = '';

						if (data[i].origin_doctype == 'ioi Sales Order') {
							url = '/app/ioi-sales-order/';
						}else if (data[i].origin_doctype == 'ioi Purchases Order') {
							url = '/app/ioi-purchases-order/';
						}else if ((data[i].origin_doctype == 'ioi Production') || (data[i].origin_doctype == 'ioi Production BOM')) {
							url = '/app/ioi-production/';
						}else if ((data[i].origin_doctype == 'ioi Dossier') || (data[i].origin_doctype == 'ioi Dossier BOM')) {
							url = '/app/ioi-dossier/';
						}else{

							html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';"';

							let dc = 'ioi Purchases Proposals'
							let name = data[i].origin_master_name;

							html += 'onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.jump_to_single_module(\'' + dc + '\', \'' + name + '\');"';
						}

						if ((data[i].origin_doctype == 'ioi Sales Order') || (data[i].origin_doctype == 'ioi Purchases Order') || (data[i].origin_doctype == 'ioi Production') || (data[i].origin_doctype == 'ioi Production BOM') ||
							(data[i].origin_doctype == 'ioi Dossier') 	  || (data[i].origin_doctype == 'ioi Dossier BOM')) {

							if (url != '') {

								html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';"';

								url += data[i].origin_master_name;
								html += 'onclick="window.open(\'' + url + '\');"';
							}
						}
					}


					html += '>' + data[i].origin_pk_formatted + '</label></b></td>';

					if (data[i].origin_ioistatus != null) {
						html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">';
						html += '<div style="position:relative; top: -2px; left: 0px; height: 30px; width:95%; border-radius: 6%; padding: 4px; color:#000000; background-color:' + data[i].origin_ioistatus_bgcolor + ';"><b>'
						html += data[i].origin_doctype_formatted + ' : ' + data[i].origin_ioistatus_description + ' ( ' + data[i].origin_ioistatus + ' )';
						html += '</b></div></td>';
					}else{
						html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
					}

					html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;"><div style="position:relative; top: 10px; left: 0px; width: 15px; height: 15px; border-radius: 50%; background:' + data[i].destination_ioistatus_bgcolor + ';"></div>';
					html += '<b><label style="position:relative; left:25px; top:-7px;height:15px;" ';


					if (data[i].destination_doctype != cur_frm.doctype) {

						let url = '';

						if (data[i].destination_doctype == 'ioi Sales Order') {
							url = '/app/ioi-sales-order/';
						}else if (data[i].destination_doctype == 'ioi Purchases Order') {
							url = '/app/ioi-purchases-order/';
						}else if ((data[i].destination_doctype == 'ioi Production') || (data[i].destination_doctype == 'ioi Production BOM')) {
							url = '/app/ioi-production/';
						}else if ((data[i].destination_doctype == 'ioi Dossier') || (data[i].destination_doctype == 'ioi Dossier BOM')) {
							url = '/app/ioi-dossier/';
						}else{

							html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';"';

							let dc = 'ioi Purchases Proposals'
							let name = data[i].destination_master_name;

							html += 'onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.jump_to_single_module(\'' + dc + '\', \'' + name + '\');"';

						}

						if ((data[i].destination_doctype == 'ioi Sales Order') || (data[i].destination_doctype == 'ioi Purchases Order') || (data[i].destination_doctype == 'ioi Production') || (data[i].destination_doctype == 'ioi Production BOM') ||
							(data[i].destination_doctype == 'ioi Dossier') || (data[i].destination_doctype == 'ioi Dossier BOM')) {

							if (url != '') {
								html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';"';

								url += data[i].destination_master_name;
								html += 'onclick="window.open(\'' + url + '\');"';
							}
						}
					}


					html += '>' + data[i].destination_pk_formatted + '</label></b></td>';

					if (data[i].destination_ioistatus != null) {
						html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">'
						html += '<div style="position:relative; top: -2px; left: 0px; height: 30px; width:95%; border-radius: 6%; padding: 4px; color:#000000; background-color:' + data[i].destination_ioistatus_bgcolor + ';"><b>'
						html += data[i].destination_doctype_formatted + ' : ' + data[i].destination_ioistatus_description + ' ( ' + data[i].destination_ioistatus + ' )';
						html += '</b></div></td>';
					}else{
						html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
					}

					html += '</tr>';

					// *******************************************************************************************************************************************************************************************************
					// Customer / Supplier
					// *******************************************************************************************************************************************************************************************************

					html += '<tr style="height:30px">';
					html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>';

					if (data[i].origin_doctype == 'ioi Sales Order') {
						html += '•&nbsp;' + __('Customer');
					}else if ((data[i].origin_doctype == 'ioi Purchases Order') || (data[i].origin_doctype == 'ioi Purchases Proposal Internal')) {
						html += '•&nbsp;' + __('Supplier');
					}else  {
						html += '&nbsp;';
					}

					html +=  '</b></td>';

					if (data[i].origin_customer_supplier_id != null) {
						html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">';
						html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
						let url = '';

						if (data[i].origin_doctype == 'ioi Sales Order') {
							url = '/app/ioi-customer/';
						}else if ((data[i].origin_doctype == 'ioi Purchases Order') || (data[i].origin_doctype == 'ioi Purchases Proposal Internal')) {
							url = '/app/ioi-supplier/';
						}

						if (url != '') {


							html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';"';

							url += data[i].origin_customer_supplier_id;
							html += 'onclick="window.open(\'' + url + '\');"';
						}

						html += '>' + data[i].origin_customer_supplier_id + '</label></td>';
					}else{
						html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
					}


					html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>';

					if (data[i].destination_doctype == 'ioi Sales Order') {
						html += '•&nbsp;' + __('Customer')
					}else if ((data[i].destination_doctype == 'ioi Purchases Order') || (data[i].destination_doctype == 'ioi Purchases Proposal Internal')) {
						html += '•&nbsp;' + __('Supplier')
					}else  {
						html += '';
					}


					html += '</b></td>';

					if (data[i].destination_customer_supplier_id != null) {
						html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">';
						html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
						let url = '';

						if (data[i].origin_doctype == 'ioi Sales Order') {
							url = '/app/ioi-customer/';
						}else if ((data[i].destination_doctype == 'ioi Purchases Order') || (data[i].destination_doctype == 'ioi Purchases Proposal Internal')) {
							url = '/app/ioi-supplier/';
						}


						if (url != '') {

							html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';"';

							url += data[i].destination_customer_supplier_id;
							html += 'onclick="window.open(\'' + url + '\');"';
						}

						html += '>' + data[i].destination_customer_supplier_id + '</label></td>';

					}else{
						html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
					}
					html += '</tr>';

					// *******************************************************************************************************************************************************************************************************
					// Item
					// *******************************************************************************************************************************************************************************************************

					html += '<tr style="height:30px">';
					html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>•&nbsp;' + __('Item') + '</b></td>';

					if (data[i].origin_item_id != null) {

						html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">';
						html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
						html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';"';

						let url = '/app/ioi-item/' + data[i].origin_item_id;
						html += 'onclick="window.open(\'' + url + '\');"';

						html += '>' + data[i].origin_item_id + '</label></td>';

					}else{
						html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
					}

					html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>•&nbsp;' +__('Item') + '</b></td>';

					if (data[i].destination_item_id != null) {

						html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">';
						html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
						html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';"';

						let url = '/app/ioi-item/' + data[i].destination_item_id;
						html += 'onclick="window.open(\'' + url + '\');"';

						html += '>' + data[i].destination_item_id + '</label></td>';
					}else{
						html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
					}

					html += '</tr>';

					// *******************************************************************************************************************************************************************************************************
					// Item description
					// *******************************************************************************************************************************************************************************************************

					html += '<tr style="height:30px">';
					html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>•&nbsp;' + __('Item description') + '</b></td>';

					if (data[i].origin_item_description != null) {
						html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">' + data[i].origin_item_description + '</td>';
					}else{
						html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
					}

					html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>•&nbsp;' +__('Item description') + '</b></td>';

					if (data[i].destination_item_description != null) {
						html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">' + data[i].destination_item_description + '</td>';
					}else{
						html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
					}

					html += '</tr>';


					// *******************************************************************************************************************************************************************************************************
					// Maunfacturer Catalog
					// *******************************************************************************************************************************************************************************************************

					html += '<tr style="height:30px">';
					html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>';
					if (data[i].origin_doctype == 'ioi Sales Order') {
						html += '•&nbsp;' + __('Manufacturer catalog');
					}else if ((data[i].origin_doctype == 'ioi Purchases Order') || (data[i].origin_doctype == 'ioi Purchases Proposal Internal')) {
						html += '•&nbsp;' + __('Manufacturer catalog');
					}else  {
						html += '&nbsp;';
					}

					html += '</b></td>';

					if (data[i].origin_manufacturer_ref != null) {

						html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">';
						html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
						html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';"';

						let url = '/app/ioi-manufacturer-catalog/' + data[i].origin_manufacturer_ref;
						html += 'onclick="window.open(\'' + url + '\');"';

						html += '>' + data[i].origin_manufacturer_ref + '</label></td>';

					}else{
						html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
					}

					html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>';
					if (data[i].destination_doctype == 'ioi Sales Order') {
						html += '•&nbsp;' + __('Manufacturer catalog');
					}else if ((data[i].destination_doctype == 'ioi Purchases Order') || (data[i].destination_doctype == 'ioi Purchases Proposal Internal')) {
						html += '•&nbsp;' + __('Manufacturer catalog');
					}else  {
						html += '&nbsp;';
					}

					html += '</b></td>';


					if (data[i].destination_manufacturer_ref != null) {

						html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">';
						html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
						html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';"';

						let url = '/app/ioi-manufacturer-catalog/' + data[i].destination_manufacturer_ref;
						html += 'onclick="window.open(\'' + url + '\');"';

						html += '>' + data[i].destination_manufacturer_ref + '</label></td>';
					}else{
						html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
					}

					html += '</tr>';


					// *******************************************************************************************************************************************************************************************************
					// Scheduled Qty
					// *******************************************************************************************************************************************************************************************************

					html += '<input id="origin_master_name_' + i.toString() + '" type="hidden" value="' + data[i].origin_master_name + '">';
					html += '<input id="origin_detail_name_' + i.toString() + '" type="hidden" value="' + data[i].origin_detail_name + '">';
					html += '<input id="destination_master_name_' + i.toString() + '" type="hidden" value="' + data[i].destination_master_name + '">';
					html += '<input id="destination_detail_name_' + i.toString() + '" type="hidden" value="' + data[i].destination_detail_name + '">';

					html += '<input id="origin_qty_' + i.toString() + '" type="hidden" value="' + data[i].origin_qty + '">';
					html += '<input id="origin_unit_' + i.toString() + '" type="hidden" value="' + data[i].origin_unit + '">';

					html += '<input id="destination_qty_' + i.toString() + '" type="hidden" value="' + data[i].destination_qty + '">';
					html += '<input id="destination_unit_' + i.toString() + '" type="hidden" value="' + data[i].destination_unit + '">';

					html += '<input id="origin_ordered_qty_' + i.toString() + '" type="hidden" value="' + data[i].origin_ordered_qty + '">';
					html += '<input id="destination_ordered_qty_' + i.toString() + '" type="hidden" value="' + data[i].destination_ordered_qty + '">';



					html += '<tr style="height:30px">';
					html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>•&nbsp;' + __('Scheduled qty') + '</b></td>';

					if ((data[i].origin_qty != null) && (data[i].origin_qty != 0)) {
						if (data[i].origin_unit != null) {
							html += '<td id="col_origin_qty_' + i.toString() + '" width=' + col2_width.toString() + 'px style="vertical-align: middle;">' + data[i].origin_qty + '&nbsp;' + data[i].origin_unit;
						}else{
							html += '<td id="col_origin_qty_' + i.toString() + '" width=' + col2_width.toString() + 'px style="vertical-align: middle;">' + data[i].origin_qty;
						}
					}else{
						html += '<td id="col_origin_qty_' + i.toString() + '" width=' + col2_width.toString() + 'px style="vertical-align: middle;">';
					}

					html += '&nbsp;&nbsp;<input id="origin_button_' + i.toString() + '" type="button" value = "..." onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.origin_button(this.id);"> ';

					html +=  '</td>';

					html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>•&nbsp;' +__('Scheduled qty') + '</b></td>';

					if ((data[i].destination_qty != null) && (data[i].destination_qty != 0)) {
						if (data[i].destination_unit != null) {
							html += '<td id="col_destination_qty_' + i.toString() + '" width=' + col4_width.toString() + 'px style="vertical-align: middle;">' + data[i].destination_qty + '&nbsp;' + data[i].destination_unit;
						}else{
							html += '<td id="col_destination_qty_' + i.toString() + '" width=' + col4_width.toString() + 'px style="vertical-align: middle;">' + data[i].destination_qty;
						}
					}else{
						html += '<td id="col_destination_qty_' + i.toString() + '" width=' + col4_width.toString() + 'px style="vertical-align: middle;">';
					}

					html += '&nbsp;&nbsp;<input id="destination_button_' + i.toString() + '" type="button" value = "..." onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.destination_button(this.id);"> ';


					html +=  '</td>';


					html += '</tr>';

					// *******************************************************************************************************************************************************************************************************
					// Ordered Qty
					// *******************************************************************************************************************************************************************************************************

					html += '<tr style="height:30px">';
					html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>•&nbsp;' + __('Ordered qty') + '</b></td>';

					if ((data[i].origin_ordered_qty != null) && (data[i].origin_ordered_qty != 0)) {
						if (data[i].origin_unit != null) {
							html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">' + data[i].origin_ordered_qty + '&nbsp;' + data[i].origin_unit + '</td>';
						}else{
							html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">' + data[i].origin_ordered_qty + '</td>';
						}
					}else{
						html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
					}

					html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>•&nbsp;' +__('Ordered qty') + '</b></td>';

					if ((data[i].destination_ordered_qty != null) && (data[i].destination_ordered_qty != 0)) {
						if (data[i].destination_unit != null) {
							html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">' + data[i].destination_ordered_qty + '&nbsp;' + data[i].destination_unit + '</td>';
						}else{
							html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">' + data[i].destination_ordered_qty + '</td>';
						}
					}else{
						html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
					}

					html += '</tr>';

					// *******************************************************************************************************************************************************************************************************
					// Delivered Qty
					// *******************************************************************************************************************************************************************************************************

					html += '<tr style="height:30px">';
					html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>';

					if (data[i].origin_doctype == 'ioi Sales Order') {
						html += '•&nbsp;' + __('Delivered qty');
					}else if ((data[i].origin_doctype == 'ioi Purchases Order') || (data[i].origin_doctype == 'ioi Purchases Proposal Internal')) {
						html += '•&nbsp;' + __('Delivered qty');
					}else if (data[i].origin_doctype == 'ioi Production BOM') {
						html += '•&nbsp;' + __('Delivered qty');
					}else if (data[i].origin_doctype == 'ioi Production') {
						html += '•&nbsp;' + __('Produced qty');
					}else if (data[i].origin_doctype == 'ioi Dossier BOM') {
						html += '•&nbsp;' + __('Delivered qty');
					}else if (data[i].origin_doctype == 'ioi Dossier') {
						html += '•&nbsp;' + __('Produced qty');
					}else  {
						html += '&nbsp;';
					}

					html += '</b></td>';

					if ((data[i].origin_doctype != 'ioi Production') && (data[i].origin_doctype != 'ioi Dossier')) {

						if ((data[i].origin_delivered_qty != null) && (data[i].origin_delivered_qty != 0)) {
							if (data[i].origin_unit != null) {
								html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">';
								html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
								html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';" ';

								let dc = data[i].origin_doctype;
								let name = data[i].origin_master_name;
								let detail_name = data[i].origin_detail_name;
								let tp = 'DELIVERED_QTY';

								html += 'onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.show_detail(\'' + dc + '\', \'' + name + '\',\'' + detail_name + '\', \'' + tp + '\'); " ';

								html += '>' + data[i].origin_delivered_qty + '&nbsp;' + data[i].origin_unit + '</label></td>';
							}else{
								html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">';
								html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
								html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';" ';

								let dc = data[i].origin_doctype;
								let name = data[i].origin_master_name;
								let detail_name = data[i].origin_detail_name;
								let tp = 'DELIVERED_QTY';

								html += 'onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.show_detail(\'' + dc + '\', \'' + name + '\',\'' + detail_name + '\', \'' + tp + '\'); " ';

								html += '>' + data[i].origin_delivered_qty + '</label></td>';
							}
						}else{
							html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						}
					}else{

						if ((data[i].origin_produced_good_qty != null) && (data[i].origin_produced_good_qty != 0)) {
							if (data[i].origin_unit != null) {
								html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">' + data[i].origin_produced_good_qty + '&nbsp;' + data[i].origin_unit + '</td>';
							}else{
								html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">' + data[i].origin_produced_good_qty + '</td>';
							}
						}else{
							html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						}
					}

					html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>';

					if (data[i].destination_doctype == 'ioi Sales Order') {
						html += '•&nbsp;' + __('Delivered qty');
					}else if ((data[i].destination_doctype == 'ioi Purchases Order') || (data[i].destination_doctype == 'ioi Purchases Proposal Internal')) {
						html += '•&nbsp;' + __('Delivered qty');
					}else if (data[i].destination_doctype == 'ioi Production BOM') {
						html += '•&nbsp;' + __('Delivered qty');
					}else if (data[i].destination_doctype == 'ioi Production') {
						html += '•&nbsp;' + __('Produced qty');
					}else if (data[i].destination_doctype == 'ioi Dossier BOM') {
						html += '•&nbsp;' + __('Delivered qty');
					}else if (data[i].destination_doctype == 'ioi Dossier') {
						html += '•&nbsp;' + __('Produced qty');
					}else  {
						html += '&nbsp;';
					}

					html += '</b></td>';

					if ((data[i].origin_doctype != 'ioi Production') && (data[i].origin_doctype != 'ioi Dossier')) {

						if ((data[i].destination_delivered_qty != null) && (data[i].destination_delivered_qty != 0)) {
							if (data[i].destination_unit != null) {
								html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">';
								html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
								html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';" ';

								let dc = data[i].destination_doctype;
								let name = data[i].destination_master_name;
								let detail_name = data[i].destination_detail_name;
								let tp = 'DELIVERED_QTY';

								html += 'onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.show_detail(\'' + dc + '\', \'' + name + '\',\'' + detail_name + '\', \'' + tp + '\'); " ';

								html += '>' + data[i].destination_delivered_qty + '&nbsp;' + data[i].destination_unit + '</label></td>';
							}else{
								html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">';
								html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
								html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';" ';

								let dc = data[i].destination_doctype;
								let name = data[i].destination_master_name;
								let detail_name = data[i].destination_detail_name;
								let tp = 'DELIVERED_QTY';

								html += 'onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.show_detail(\'' + dc + '\', \'' + name + '\',\'' + detail_name + '\', \'' + tp + '\'); " ';

								html += '>' + data[i].destination_delivered_qty + '</label></td>';
							}
						}else{
							html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						}

					}else{

						if ((data[i].destination_produced_good_qty != null) && (data[i].destination_produced_good_qty != 0)) {
							if (data[i].destination_unit != null) {
								html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">' + data[i].destination_produced_good_qty + '&nbsp;' + data[i].origin_unit + '</td>';
							}else{
								html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">' + data[i].destination_produced_good_qty + '</td>';
							}
						}else{
							html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						}


					}

					// *******************************************************************************************************************************************************************************************************
					// Reserved Qty
					// *******************************************************************************************************************************************************************************************************

					html += '<tr style="height:30px">';
					html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>';


					if (data[i].origin_doctype == 'ioi Sales Order') {
						html += '•&nbsp;' + __('Reserved qty');
					}else if ((data[i].origin_doctype == 'ioi Purchases Order') || (data[i].origin_doctype == 'ioi Purchases Proposal Internal')) {
						html += '•&nbsp;' + __('Reserved qty');
					}else if (data[i].origin_doctype == 'ioi Production') {
						html += '•&nbsp;' + __('Prod. tockeck qty');
					}else if (data[i].origin_doctype == 'ioi Dossier') {
						html += '•&nbsp;' + __('Prod. tockeck qty');
					}else {
						html += '';
					}

					html += '</b></td>';

					if ((data[i].origin_doctype != 'ioi Production') && (data[i].origin_doctype != 'ioi Dossier')) {
						if ((data[i].origin_reserved_qty != null) && (data[i].origin_reserved_qty != 0)) {
							if (data[i].origin_unit != null) {
								html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">';
								html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
								html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';" ';

								let dc = data[i].origin_doctype;
								let name = data[i].origin_master_name;
								let detail_name = data[i].origin_detail_name;
								let tp = 'RESERVED_QTY';

								html += 'onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.show_detail(\'' + dc + '\', \'' + name + '\',\'' + detail_name + '\', \'' + tp + '\'); " ';

								html += '>' + data[i].origin_reserved_qty + '&nbsp;' + data[i].origin_unit + '</label></td>';
							}else{
								html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">';
								html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
								html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';" ';

								let dc = data[i].origin_doctype;
								let name = data[i].origin_master_name;
								let detail_name = data[i].origin_detail_name;
								let tp = 'RESERVED_QTY';

								html += 'onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.show_detail(\'' + dc + '\', \'' + name + '\',\'' + detail_name + '\', \'' + tp + '\'); " ';

								html += '>' + data[i].origin_reserved_qty + '</label></td>';
							}
						}else{
							html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						}
					}else{
						if ((data[i].origin_produced_tocheck_qty != null) && (data[i].origin_produced_tocheck_qty != 0)) {
							if (data[i].origin_unit != null) {
								html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">' + data[i].origin_produced_tocheck_qty + '&nbsp;' + data[i].origin_unit + '</td>';
							}else{
								html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">' + data[i].origin_produced_tocheck_qty + '</td>';
							}
						}else{
							html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						}
					}

					html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>';

					if (data[i].destination_doctype == 'ioi Sales Order') {
						html += '•&nbsp;' + __('Reserved qty');
					}else if ((data[i].destination_doctype == 'ioi Purchases Order') || (data[i].destination_doctype == 'ioi Purchases Proposal Internal')) {
						html += '•&nbsp;' + __('Reserved qty');
					}else if (data[i].destination_doctype == 'ioi Production') {
						html += '•&nbsp;' + __('Prod. tockeck qty');
					}else if (data[i].destination_doctype == 'ioi Dossier') {
						html += '•&nbsp;' + __('Prod. tockeck qty');
					}else {
						html += '';
					}

					html += '</b></td>';

					if ((data[i].destination_doctype != 'ioi Production') && (data[i].destination_doctype != 'ioi Dossier')) {

						if ((data[i].destination_reserved_qty != null) && (data[i].destination_reserved_qty != 0)) {
							if (data[i].destination_unit != null) {
								html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">';
								html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
								html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';" ';

								let dc = data[i].destination_doctype;
								let name = data[i].destination_master_name;
								let detail_name = data[i].destination_detail_name;
								let tp = 'RESERVED_QTY';

								html += 'onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.show_detail(\'' + dc + '\', \'' + name + '\',\'' + detail_name + '\', \'' + tp + '\'); " ';

								html += '>' + data[i].destination_reserved_qty + '&nbsp;' + data[i].destination_unit + '</label></td>';
							}else{
								html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">';
								html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
								html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';" ';

								let dc = data[i].destination_doctype;
								let name = data[i].destination_master_name;
								let detail_name = data[i].destination_detail_name;
								let tp = 'RESERVED_QTY';

								html += 'onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.show_detail(\'' + dc + '\', \'' + name + '\',\'' + detail_name + '\', \'' + tp + '\'); " ';

								html += '>' + data[i].destination_reserved_qty + '</label></td>';
							}
						}else{
							html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						}
					}else{
						if ((data[i].destination_produced_tocheck_qty != null) && (data[i].destination_produced_tocheck_qty != 0)) {
							if (data[i].origin_unit != null) {
								html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">' + data[i].destination_produced_tocheck_qty + '&nbsp;' + data[i].origin_unit + '</td>';
							}else{
								html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">' + data[i].destination_produced_tocheck_qty + '</td>';
							}
						}else{
							html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						}

					}

					html += '</tr>';

					// *******************************************************************************************************************************************************************************************************
					// Invoiced Qty
					// *******************************************************************************************************************************************************************************************************

					html += '<tr style="height:30px">';
					html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>';

					if (data[i].origin_doctype == 'ioi Sales Order') {
						html += '•&nbsp;' + __('Invoiced qty');
					}else if ((data[i].origin_doctype == 'ioi Purchases Order') || (data[i].origin_doctype == 'ioi Purchases Proposal Internal')) {
						html += '•&nbsp;' + __('Invoiced qty');
					}else if (data[i].origin_doctype == 'ioi Production') {
						html += '•&nbsp;' + __('Produced bad qty');
					}else if (data[i].origin_doctype == 'ioi Dossier') {
						html += '•&nbsp;' + __('Produced bad qty');
					}else  {
						html += '';
					}

					html += '</b></td>';

					if ((data[i].origin_doctype != 'ioi Production') && (data[i].origin_doctype != 'ioi Dossier')) {
						if ((data[i].origin_invoiced_qty != null) && (data[i].origin_invoiced_qty != 0)) {
							if (data[i].origin_unit != null) {
								html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">';
								html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
								html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';" ';

								let dc = data[i].origin_doctype;
								let name = data[i].origin_master_name;
								let detail_name = data[i].origin_detail_name;
								let tp = 'INVOICED_QTY';

								html += 'onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.show_detail(\'' + dc + '\', \'' + name + '\',\'' + detail_name + '\', \'' + tp + '\'); " ';

								html += '>' + data[i].origin_invoiced_qty + '&nbsp;' + data[i].origin_unit + '</label></td>';
							}else{
								html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">';
								html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
								html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';" ';

								let dc = data[i].origin_doctype;
								let name = data[i].origin_master_name;
								let detail_name = data[i].origin_detail_name;
								let tp = 'INVOICED_QTY';

								html += 'onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.show_detail(\'' + dc + '\', \'' + name + '\',\'' + detail_name + '\', \'' + tp + '\'); " ';

								html += '>' + data[i].origin_invoiced_qty + '</label></td>';
							}
						}else{
							html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						}
					}else{
						if ((data[i].origin_produced_bad_qty != null) && (data[i].origin_produced_bad_qty != 0)) {
							if (data[i].origin_unit != null) {
								html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">' + data[i].origin_produced_bad_qty + '&nbsp;' + data[i].origin_unit + '</td>';
							}else{
								html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">' + data[i].origin_produced_bad_qty + '</td>';
							}
						}else{
							html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						}

					}

					html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>';

					if (data[i].destination_doctype == 'ioi Sales Order') {
						html += '•&nbsp;' + __('Invoiced qty');
					}else if ((data[i].destination_doctype == 'ioi Purchases Order') || (data[i].destination_doctype == 'ioi Purchases Proposal Internal')) {
						html += '•&nbsp;' + __('Invoiced qty');
					}else if (data[i].destination_doctype == 'ioi Production') {
						html += '•&nbsp;' + __('Produced bad qty');
					}else if (data[i].destination_doctype == 'ioi Dossier') {
						html += '•&nbsp;' + __('Produced bad qty');
					}else {
						html += '';
					}

					html += '</b></td>';

					if ((data[i].destination_doctype != 'ioi Production') && (data[i].destination_doctype != 'ioi Dossier')) {
						if ((data[i].destination_invoiced_qty != null) && (data[i].destination_invoiced_qty != 0)) {
							if (data[i].destination_unit != null) {
								html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">';
								html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
								html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';" ';

								let dc = data[i].destination_doctype;
								let name = data[i].destination_master_name;
								let detail_name = data[i].destination_detail_name;
								let tp = 'INVOICED_QTY';

								html += 'onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.show_detail(\'' + dc + '\', \'' + name + '\',\'' + detail_name + '\', \'' + tp + '\'); " ';

								html += '>' + data[i].destination_invoiced_qty + '&nbsp;' + data[i].destination_unit + '</label></td>';
							}else{
								html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">';
								html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
								html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';" ';

								let dc = data[i].destination_doctype;
								let name = data[i].destination_master_name;
								let detail_name = data[i].destination_detail_name;
								let tp = 'INVOICED_QTY';

								html += 'onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.show_detail(\'' + dc + '\', \'' + name + '\',\'' + detail_name + '\', \'' + tp + '\'); " ';

								html += '>' + data[i].destination_invoiced_qty + '</label></td>';
							}
						}else{
							html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						}
					}else{
						if ((data[i].destination_produced_bad_qty != null) && (data[i].destination_produced_bad_qty != 0)) {
							if (data[i].origin_unit != null) {
								html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">' + data[i].destination_produced_bad_qty + '&nbsp;' + data[i].origin_unit + '</td>';
							}else{
								html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">' + data[i].destination_produced_bad_qty + '</td>';
							}
						}else{
							html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						}

					}

					html += '</tr>';

					html += '<tr style="height:30px">';

					if (i < data.length-1) {
						html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;"><b><u>' + __("Origin") + '</u></b></td>';
						html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;"><b><u>' + __("Destination") + '</u></b></td>';
						html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
					}else{

						html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
					}

					html += '</tr>';

				}

				html += '</table>';

			}

			html += '</div>';

			html += '<div style="overflow: auto; overflow-x: auto; height:30px; width: 100%;">';
			html += '	<div style="position: relative; top: 0px; left: 2px; width:428px;">';
			html += '		<label style="position: absolute; top: 8px; left: 0px;color:blue;">' + __("Click on delivered qty, reserved qty or invoiced qty to see the detail") + '</label>';
			html += '	</div>';
			html += '</div>';



			cur_frm.fields_dict[html_fieldname].$wrapper.append(html);
		}
	}


	static get_html_detail_scheduled_operations(parent, name)
	{

		let html = '';

		let dc = cur_frm.doctype;

		let method = 'silicon_ioi.ioi_jit.doctype.ioi_jit_engine_link.ioi_jit_engine_link.ioi_jit_engine_link_get_my_links_detail_line';

		let data = [];

		frappe.call({  	method: method,
			args: {"doctype": dc, "name": parent, "detail_name": name},
			async: false,
			callback:function(r)	{
				data = r.message
			}
		});

		if (data.length == 0) {
			return html;
		}


		let table_width = 1060;
		let col1_width = 230;
		let col2_width = 300;
		let col3_width = 230;
		let col4_width = 300;

		html += '<div style="overflow: auto; overflow-x: hidden; overflow-y: auto; height:160px; width: 100%;">';

		html += '<table border=0 style="border: 0px solid #E8EAEB" width=' + table_width.toString() + 'px >';
		html += '<tr style="height:15px">';
		html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;"><b><u>' + __("Origin") + '</u></b></td>';
		html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;"><b><u>' + __("Destination") + '</u></b></td>';
		html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
		html += '</tr>';

		html += '</table>';

		if (data.length == 0) {

			html += '<table border=0 style="border: 0px solid #E8EAEB" width=' + table_width.toString() + 'px>';
			html += '<tr style="height:30px">';
			html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
			html += '</tr>';
			html += '</table>';
		}else{
			html += '<table border=0 style="border: 0px solid #E8EAEB" width=' + table_width.toString() + 'px>';

			for (var i = 0; i < data.length; i++) {

				// *******************************************************************************************************************************************************************************************************
				// Document
				// *******************************************************************************************************************************************************************************************************
				html += '<tr style="height:30px">';
				html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;"><div style="position:relative; top: 10px; left: 0px; width: 15px; height: 15px; border-radius: 50%; background:' + data[i].origin_ioistatus_bgcolor + ';"></div>';
				html += '<b><label style="position:relative; left:25px; top:-7px;height:15px;" ';


				if (data[i].origin_doctype != cur_frm.doctype) {

					let url = '';

					if (data[i].origin_doctype == 'ioi Sales Order') {
						url = '/app/ioi-sales-order/';
					}else if (data[i].origin_doctype == 'ioi Purchases Order') {
						url = '/app/ioi-purchases-order/';
					}else if ((data[i].origin_doctype == 'ioi Production') || (data[i].origin_doctype == 'ioi Production BOM')) {
						url = '/app/ioi-production/';
					}else if ((data[i].origin_doctype == 'ioi Dossier') || (data[i].origin_doctype == 'ioi Dossier BOM')) {
						url = '/app/ioi-dossier/';
					}else{

						html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';"';

						let dc = 'ioi Purchases Proposals'
						let name = data[i].origin_master_name;

						html += 'onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.jump_to_single_module(\'' + dc + '\', \'' + name + '\');"';
					}

					if ((data[i].origin_doctype == 'ioi Sales Order') || (data[i].origin_doctype == 'ioi Purchases Order') || (data[i].origin_doctype == 'ioi Production') || (data[i].origin_doctype == 'ioi Production BOM') ||
						(data[i].origin_doctype == 'ioi Dossier') 	  || (data[i].origin_doctype == 'ioi Dossier BOM')) {

						if (url != '') {

							html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';"';

							url += data[i].origin_master_name;
							html += 'onclick="window.open(\'' + url + '\');"';
						}
					}
				}


				html += '>' + data[i].origin_pk_formatted + '</label></b></td>';

				if (data[i].origin_ioistatus != null) {
					html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">';
					html += '<div style="position:relative; top: -2px; left: 0px; height: 30px; width:95%; border-radius: 6%; padding: 4px; color:#000000; background-color:' + data[i].origin_ioistatus_bgcolor + ';"><b>'
					html += data[i].origin_doctype_formatted + ' : ' + data[i].origin_ioistatus_description + ' ( ' + data[i].origin_ioistatus + ' )';
					html += '</b></div></td>';
				}else{
					html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
				}

				html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;"><div style="position:relative; top: 10px; left: 0px; width: 15px; height: 15px; border-radius: 50%; background:' + data[i].destination_ioistatus_bgcolor + ';"></div>';
				html += '<b><label style="position:relative; left:25px; top:-7px;height:15px;" ';


				if (data[i].destination_doctype != cur_frm.doctype) {

					let url = '';

					if (data[i].destination_doctype == 'ioi Sales Order') {
						url = '/app/ioi-sales-order/';
					}else if (data[i].destination_doctype == 'ioi Purchases Order') {
						url = '/app/ioi-purchases-order/';
					}else if ((data[i].destination_doctype == 'ioi Production') || (data[i].destination_doctype == 'ioi Production BOM')) {
						url = '/app/ioi-production/';
					}else if ((data[i].destination_doctype == 'ioi Dossier') || (data[i].destination_doctype == 'ioi Dossier BOM')) {
						url = '/app/ioi-dossier/';
					}else{

						html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';"';

						let dc = 'ioi Purchases Proposals'
						let name = data[i].destination_master_name;

						html += 'onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.jump_to_single_module(\'' + dc + '\', \'' + name + '\');"';

					}

					if ((data[i].destination_doctype == 'ioi Sales Order') || (data[i].destination_doctype == 'ioi Purchases Order') || (data[i].destination_doctype == 'ioi Production') || (data[i].destination_doctype == 'ioi Production BOM') ||
						(data[i].destination_doctype == 'ioi Dossier') || (data[i].destination_doctype == 'ioi Dossier BOM')) {

						if (url != '') {
							html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';"';

							url += data[i].destination_master_name;
							html += 'onclick="window.open(\'' + url + '\');"';
						}
					}
				}


				html += '>' + data[i].destination_pk_formatted + '</label></b></td>';

				if (data[i].destination_ioistatus != null) {
					html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">'
					html += '<div style="position:relative; top: -2px; left: 0px; height: 30px; width:95%; border-radius: 6%; padding: 4px; color:#000000; background-color:' + data[i].destination_ioistatus_bgcolor + ';"><b>'
					html += data[i].destination_doctype_formatted + ' : ' + data[i].destination_ioistatus_description + ' ( ' + data[i].destination_ioistatus + ' )';
					html += '</b></div></td>';
				}else{
					html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
				}

				html += '</tr>';

				// *******************************************************************************************************************************************************************************************************
				// Customer / Supplier
				// *******************************************************************************************************************************************************************************************************

				html += '<tr style="height:20px">';
				html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>';

				if (data[i].origin_doctype == 'ioi Sales Order') {
					html += '•&nbsp;' + __('Customer');
				}else if ((data[i].origin_doctype == 'ioi Purchases Order') || (data[i].origin_doctype == 'ioi Purchases Proposal Internal')) {
					html += '•&nbsp;' + __('Supplier');
				}else  {
					html += '&nbsp;';
				}

				html +=  '</b></td>';

				if (data[i].origin_customer_supplier_id != null) {
					html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">';
					html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
					let url = '';

					if (data[i].origin_doctype == 'ioi Sales Order') {
						url = '/app/ioi-customer/';
					}else if ((data[i].origin_doctype == 'ioi Purchases Order') || (data[i].origin_doctype == 'ioi Purchases Proposal Internal')) {
						url = '/app/ioi-supplier/';
					}

					if (url != '') {


						html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';"';

						url += data[i].origin_customer_supplier_id;
						html += 'onclick="window.open(\'' + url + '\');"';
					}

					html += '>' + data[i].origin_customer_supplier_id + '</label></td>';
				}else{
					html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
				}


				html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>';

				if (data[i].destination_doctype == 'ioi Sales Order') {
					html += '•&nbsp;' + __('Customer')
				}else if ((data[i].destination_doctype == 'ioi Purchases Order') || (data[i].destination_doctype == 'ioi Purchases Proposal Internal')) {
					html += '•&nbsp;' + __('Supplier')
				}else  {
					html += '';
				}


				html += '</b></td>';

				if (data[i].destination_customer_supplier_id != null) {
					html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">';
					html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
					let url = '';

					if (data[i].origin_doctype == 'ioi Sales Order') {
						url = '/app/ioi-customer/';
					}else if ((data[i].destination_doctype == 'ioi Purchases Order') || (data[i].destination_doctype == 'ioi Purchases Proposal Internal')) {
						url = '/app/ioi-supplier/';
					}


					if (url != '') {

						html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';"';

						url += data[i].destination_customer_supplier_id;
						html += 'onclick="window.open(\'' + url + '\');"';
					}

					html += '>' + data[i].destination_customer_supplier_id + '</label></td>';

				}else{
					html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
				}
				html += '</tr>';

				// *******************************************************************************************************************************************************************************************************
				// Item
				// *******************************************************************************************************************************************************************************************************
				if ((data[i].origin_item_id != null) || (data[i].origin_manufacturer_ref != null) || (data[i].destination_item_id != null) || (data[i].destination_manufacturer_ref != null)) {

					html += '<tr style="height:20px">';


					if (data[i].origin_item_id != null) {

						html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>•&nbsp;' + __('Item') + '</b></td>';

						html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">';
						html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
						html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';"';

						let url = '/app/ioi-item/' + data[i].origin_item_id;
						html += 'onclick="window.open(\'' + url + '\');"';

						html += '>' + data[i].origin_item_id + '</label></td>';

					}else{

						if (data[i].origin_manufacturer_ref != null) {

							html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>';
							if (data[i].origin_doctype == 'ioi Sales Order') {
								html += '•&nbsp;' + __('Manufacturer catalog');
							}else if ((data[i].origin_doctype == 'ioi Purchases Order') || (data[i].origin_doctype == 'ioi Purchases Proposal Internal')) {
								html += '•&nbsp;' + __('Manufacturer catalog');
							}else  {
								html += '&nbsp;';
							}

							html += '</b></td>';

							html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">';
							html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
							html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';"';

							let url = '/app/ioi-manufacturer-catalog/' + data[i].origin_manufacturer_ref;
							html += 'onclick="window.open(\'' + url + '\');"';

							html += '>' + data[i].origin_manufacturer_ref + '</label></td>';



						}else{
							html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>•&nbsp;' + __('Item') + '</b></td>';
							html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						}
					}

					if (data[i].destination_item_id != null) {
						html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>•&nbsp;' +__('Item') + '</b></td>';
						html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">';
						html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
						html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';"';

						let url = '/app/ioi-item/' + data[i].destination_item_id;
						html += 'onclick="window.open(\'' + url + '\');"';

						html += '>' + data[i].destination_item_id + '</label></td>';
					}else{

						if (data[i].destination_manufacturer_ref != null) {

							html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>';
							if (data[i].destination_doctype == 'ioi Sales Order') {
								html += '•&nbsp;' + __('Manufacturer catalog');
							}else if ((data[i].destination_doctype == 'ioi Purchases Order') || (data[i].destination_doctype == 'ioi Purchases Proposal Internal')) {
								html += '•&nbsp;' + __('Manufacturer catalog');
							}else  {
								html += '&nbsp;';
							}

							html += '</b></td>';

							html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">';
							html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
							html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';"';

							let url = '/app/ioi-manufacturer-catalog/' + data[i].destination_manufacturer_ref;
							html += 'onclick="window.open(\'' + url + '\');"';

							html += '>' + data[i].destination_manufacturer_ref + '</label></td>';
						}else{
							html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>•&nbsp;' +__('Item') + '</b></td>';
							html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						}
					}

					html += '</tr>';
				}

				// *******************************************************************************************************************************************************************************************************
				// Item description
				// *******************************************************************************************************************************************************************************************************

				html += '<tr style="height:20px">';
				html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>•&nbsp;' + __('Item description') + '</b></td>';

				if (data[i].origin_item_description != null) {
					html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">' + data[i].origin_item_description.substring(0,40) + '</td>';
				}else{
					html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
				}

				html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>•&nbsp;' +__('Item description') + '</b></td>';

				if (data[i].destination_item_description != null) {
					html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">' + data[i].destination_item_description.substring(0, 40) + '</td>';
				}else{
					html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
				}

				html += '</tr>';


				// *******************************************************************************************************************************************************************************************************
				// Scheduled Qty
				// *******************************************************************************************************************************************************************************************************

				html += '<input id="origin_master_name_' + i.toString() + '" type="hidden" value="' + data[i].origin_master_name + '">';
				html += '<input id="origin_detail_name_' + i.toString() + '" type="hidden" value="' + data[i].origin_detail_name + '">';
				html += '<input id="destination_master_name_' + i.toString() + '" type="hidden" value="' + data[i].destination_master_name + '">';
				html += '<input id="destination_detail_name_' + i.toString() + '" type="hidden" value="' + data[i].destination_detail_name + '">';

				html += '<input id="origin_qty_' + i.toString() + '" type="hidden" value="' + data[i].origin_qty + '">';
				html += '<input id="origin_unit_' + i.toString() + '" type="hidden" value="' + data[i].origin_unit + '">';

				html += '<input id="destination_qty_' + i.toString() + '" type="hidden" value="' + data[i].destination_qty + '">';
				html += '<input id="destination_unit_' + i.toString() + '" type="hidden" value="' + data[i].destination_unit + '">';

				html += '<input id="origin_ordered_qty_' + i.toString() + '" type="hidden" value="' + data[i].origin_ordered_qty + '">';
				html += '<input id="destination_ordered_qty_' + i.toString() + '" type="hidden" value="' + data[i].destination_ordered_qty + '">';



				html += '<tr style="height:20px">';
				html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>•&nbsp;' + __('Scheduled qty') + '</b></td>';

				if ((data[i].origin_qty != null) && (data[i].origin_qty != 0)) {
					if (data[i].origin_unit != null) {
						html += '<td id="col_origin_qty_' + i.toString() + '" width=' + col2_width.toString() + 'px style="vertical-align: middle;">' + data[i].origin_qty + '&nbsp;' + data[i].origin_unit;
					}else{
						html += '<td id="col_origin_qty_' + i.toString() + '" width=' + col2_width.toString() + 'px style="vertical-align: middle;">' + data[i].origin_qty;
					}
				}else{
					html += '<td id="col_origin_qty_' + i.toString() + '" width=' + col2_width.toString() + 'px style="vertical-align: middle;">';
				}

				html += '&nbsp;';

				html +=  '</td>';

				html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>•&nbsp;' +__('Scheduled qty') + '</b></td>';

				if ((data[i].destination_qty != null) && (data[i].destination_qty != 0)) {
					if (data[i].destination_unit != null) {
						html += '<td id="col_destination_qty_' + i.toString() + '" width=' + col4_width.toString() + 'px style="vertical-align: middle;">' + data[i].destination_qty + '&nbsp;' + data[i].destination_unit;
					}else{
						html += '<td id="col_destination_qty_' + i.toString() + '" width=' + col4_width.toString() + 'px style="vertical-align: middle;">' + data[i].destination_qty;
					}
				}else{
					html += '<td id="col_destination_qty_' + i.toString() + '" width=' + col4_width.toString() + 'px style="vertical-align: middle;">';
				}

				html += '&nbsp;';


				html +=  '</td>';


				html += '</tr>';

				// *******************************************************************************************************************************************************************************************************
				// Ordered Qty
				// *******************************************************************************************************************************************************************************************************
				if (((data[i].origin_ordered_qty != null) && (data[i].origin_ordered_qty != 0)) || ((data[i].destination_ordered_qty != null) && (data[i].destination_ordered_qty != 0))){

					html += '<tr style="height:20px">';
					html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>•&nbsp;' + __('Ordered qty') + '</b></td>';

					if ((data[i].origin_ordered_qty != null) && (data[i].origin_ordered_qty != 0)) {
						if (data[i].origin_unit != null) {
							html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">' + data[i].origin_ordered_qty + '&nbsp;' + data[i].origin_unit + '</td>';
						}else{
							html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">' + data[i].origin_ordered_qty + '</td>';
						}
					}else{
						html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
					}

					html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>•&nbsp;' +__('Ordered qty') + '</b></td>';

					if ((data[i].destination_ordered_qty != null) && (data[i].destination_ordered_qty != 0)) {
						if (data[i].destination_unit != null) {
							html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">' + data[i].destination_ordered_qty + '&nbsp;' + data[i].destination_unit + '</td>';
						}else{
							html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">' + data[i].destination_ordered_qty + '</td>';
						}
					}else{
						html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
					}

					html += '</tr>';
				}

				// *******************************************************************************************************************************************************************************************************
				// Delivered Qty
				// *******************************************************************************************************************************************************************************************************

				if (((data[i].origin_delivered_qty != null) && (data[i].origin_delivered_qty != 0)) || ((data[i].origin_produced_good_qty != null) && (data[i].origin_produced_good_qty != 0)) ||
					((data[i].destination_delivered_qty != null) && (data[i].destination_delivered_qty != 0)) || ((data[i].destination_produced_good_qty != null) && (data[i].destination_produced_good_qty != 0))) {

					html += '<tr style="height:20px">';
					html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>';

					if (data[i].origin_doctype == 'ioi Sales Order') {
						html += '•&nbsp;' + __('Delivered qty');
					}else if ((data[i].origin_doctype == 'ioi Purchases Order') || (data[i].origin_doctype == 'ioi Purchases Proposal Internal')) {
						html += '•&nbsp;' + __('Delivered qty');
					}else if (data[i].origin_doctype == 'ioi Production BOM') {
						html += '•&nbsp;' + __('Delivered qty');
					}else if (data[i].origin_doctype == 'ioi Production') {
						html += '•&nbsp;' + __('Produced qty');
					}else if (data[i].origin_doctype == 'ioi Dossier BOM') {
						html += '•&nbsp;' + __('Delivered qty');
					}else if (data[i].origin_doctype == 'ioi Dossier') {
						html += '•&nbsp;' + __('Produced qty');
					}else  {
						html += '&nbsp;';
					}

					html += '</b></td>';

					if ((data[i].origin_doctype != 'ioi Production') && (data[i].origin_doctype != 'ioi Dossier')) {

						if ((data[i].origin_delivered_qty != null) && (data[i].origin_delivered_qty != 0)) {
							if (data[i].origin_unit != null) {
								html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">';
								html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
								html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';" ';

								let dc = data[i].origin_doctype;
								let name = data[i].origin_master_name;
								let detail_name = data[i].origin_detail_name;
								let tp = 'DELIVERED_QTY';

								html += 'onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.show_detail(\'' + dc + '\', \'' + name + '\',\'' + detail_name + '\', \'' + tp + '\'); " ';

								html += '>' + data[i].origin_delivered_qty + '&nbsp;' + data[i].origin_unit + '</label></td>';
							}else{
								html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">';
								html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
								html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';" ';

								let dc = data[i].origin_doctype;
								let name = data[i].origin_master_name;
								let detail_name = data[i].origin_detail_name;
								let tp = 'DELIVERED_QTY';

								html += 'onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.show_detail(\'' + dc + '\', \'' + name + '\',\'' + detail_name + '\', \'' + tp + '\'); " ';

								html += '>' + data[i].origin_delivered_qty + '</label></td>';
							}
						}else{
							html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						}
					}else{

						if ((data[i].origin_produced_good_qty != null) && (data[i].origin_produced_good_qty != 0)) {
							if (data[i].origin_unit != null) {
								html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">' + data[i].origin_produced_good_qty + '&nbsp;' + data[i].origin_unit + '</td>';
							}else{
								html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">' + data[i].origin_produced_good_qty + '</td>';
							}
						}else{
							html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						}
					}

					html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>';

					if (data[i].destination_doctype == 'ioi Sales Order') {
						html += '•&nbsp;' + __('Delivered qty');
					}else if ((data[i].destination_doctype == 'ioi Purchases Order') || (data[i].destination_doctype == 'ioi Purchases Proposal Internal')) {
						html += '•&nbsp;' + __('Delivered qty');
					}else if (data[i].destination_doctype == 'ioi Production BOM') {
						html += '•&nbsp;' + __('Delivered qty');
					}else if (data[i].destination_doctype == 'ioi Production') {
						html += '•&nbsp;' + __('Produced qty');
					}else if (data[i].destination_doctype == 'ioi Dossier BOM') {
						html += '•&nbsp;' + __('Delivered qty');
					}else if (data[i].destination_doctype == 'ioi Dossier') {
						html += '•&nbsp;' + __('Produced qty');
					}else  {
						html += '&nbsp;';
					}

					html += '</b></td>';

					if ((data[i].origin_doctype != 'ioi Production') && (data[i].origin_doctype != 'ioi Dossier')) {

						if ((data[i].destination_delivered_qty != null) && (data[i].destination_delivered_qty != 0)) {
							if (data[i].destination_unit != null) {
								html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">';
								html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
								html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';" ';

								let dc = data[i].destination_doctype;
								let name = data[i].destination_master_name;
								let detail_name = data[i].destination_detail_name;
								let tp = 'DELIVERED_QTY';

								html += 'onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.show_detail(\'' + dc + '\', \'' + name + '\',\'' + detail_name + '\', \'' + tp + '\'); " ';

								html += '>' + data[i].destination_delivered_qty + '&nbsp;' + data[i].destination_unit + '</label></td>';
							}else{
								html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">';
								html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
								html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';" ';

								let dc = data[i].destination_doctype;
								let name = data[i].destination_master_name;
								let detail_name = data[i].destination_detail_name;
								let tp = 'DELIVERED_QTY';

								html += 'onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.show_detail(\'' + dc + '\', \'' + name + '\',\'' + detail_name + '\', \'' + tp + '\'); " ';

								html += '>' + data[i].destination_delivered_qty + '</label></td>';
							}
						}else{
							html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						}

					}else{

						if ((data[i].destination_produced_good_qty != null) && (data[i].destination_produced_good_qty != 0)) {
							if (data[i].destination_unit != null) {
								html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">' + data[i].destination_produced_good_qty + '&nbsp;' + data[i].origin_unit + '</td>';
							}else{
								html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">' + data[i].destination_produced_good_qty + '</td>';
							}
						}else{
							html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						}


					}
				}

				// *******************************************************************************************************************************************************************************************************
				// Reserved Qty
				// *******************************************************************************************************************************************************************************************************

				if (((data[i].origin_reserved_qty != null) && (data[i].origin_reserved_qty != 0)) || ((data[i].origin_produced_tocheck_qty != null) && (data[i].origin_produced_tocheck_qty != 0)) ||
					((data[i].destination_reserved_qty != null) && (data[i].destination_reserved_qty != 0)) || ((data[i].destination_produced_tocheck_qty != null) && (data[i].destination_produced_tocheck_qty != 0))) {

					html += '<tr style="height:20px">';
					html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>';


					if (data[i].origin_doctype == 'ioi Sales Order') {
						html += '•&nbsp;' + __('Reserved qty');
					}else if ((data[i].origin_doctype == 'ioi Purchases Order') || (data[i].origin_doctype == 'ioi Purchases Proposal Internal')) {
						html += '•&nbsp;' + __('Reserved qty');
					}else if (data[i].origin_doctype == 'ioi Production') {
						html += '•&nbsp;' + __('Prod. tockeck qty');
					}else if (data[i].origin_doctype == 'ioi Dossier') {
						html += '•&nbsp;' + __('Prod. tockeck qty');
					}else {
						html += '';
					}

					html += '</b></td>';

					if ((data[i].origin_doctype != 'ioi Production') && (data[i].origin_doctype != 'ioi Dossier')) {
						if ((data[i].origin_reserved_qty != null) && (data[i].origin_reserved_qty != 0)) {
							if (data[i].origin_unit != null) {
								html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">';
								html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
								html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';" ';

								let dc = data[i].origin_doctype;
								let name = data[i].origin_master_name;
								let detail_name = data[i].origin_detail_name;
								let tp = 'RESERVED_QTY';

								html += 'onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.show_detail(\'' + dc + '\', \'' + name + '\',\'' + detail_name + '\', \'' + tp + '\'); " ';

								html += '>' + data[i].origin_reserved_qty + '&nbsp;' + data[i].origin_unit + '</label></td>';
							}else{
								html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">';
								html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
								html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';" ';

								let dc = data[i].origin_doctype;
								let name = data[i].origin_master_name;
								let detail_name = data[i].origin_detail_name;
								let tp = 'RESERVED_QTY';

								html += 'onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.show_detail(\'' + dc + '\', \'' + name + '\',\'' + detail_name + '\', \'' + tp + '\'); " ';

								html += '>' + data[i].origin_reserved_qty + '</label></td>';
							}
						}else{
							html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						}
					}else{
						if ((data[i].origin_produced_tocheck_qty != null) && (data[i].origin_produced_tocheck_qty != 0)) {
							if (data[i].origin_unit != null) {
								html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">' + data[i].origin_produced_tocheck_qty + '&nbsp;' + data[i].origin_unit + '</td>';
							}else{
								html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">' + data[i].origin_produced_tocheck_qty + '</td>';
							}
						}else{
							html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						}
					}

					html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>';

					if (data[i].destination_doctype == 'ioi Sales Order') {
						html += '•&nbsp;' + __('Reserved qty');
					}else if ((data[i].destination_doctype == 'ioi Purchases Order') || (data[i].destination_doctype == 'ioi Purchases Proposal Internal')) {
						html += '•&nbsp;' + __('Reserved qty');
					}else if (data[i].destination_doctype == 'ioi Production') {
						html += '•&nbsp;' + __('Prod. tockeck qty');
					}else if (data[i].destination_doctype == 'ioi Dossier') {
						html += '•&nbsp;' + __('Prod. tockeck qty');
					}else {
						html += '';
					}

					html += '</b></td>';

					if ((data[i].destination_doctype != 'ioi Production') && (data[i].destination_doctype != 'ioi Dossier')) {

						if ((data[i].destination_reserved_qty != null) && (data[i].destination_reserved_qty != 0)) {
							if (data[i].destination_unit != null) {
								html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">';
								html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
								html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';" ';

								let dc = data[i].destination_doctype;
								let name = data[i].destination_master_name;
								let detail_name = data[i].destination_detail_name;
								let tp = 'RESERVED_QTY';

								html += 'onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.show_detail(\'' + dc + '\', \'' + name + '\',\'' + detail_name + '\', \'' + tp + '\'); " ';

								html += '>' + data[i].destination_reserved_qty + '&nbsp;' + data[i].destination_unit + '</label></td>';
							}else{
								html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">';
								html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
								html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';" ';

								let dc = data[i].destination_doctype;
								let name = data[i].destination_master_name;
								let detail_name = data[i].destination_detail_name;
								let tp = 'RESERVED_QTY';

								html += 'onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.show_detail(\'' + dc + '\', \'' + name + '\',\'' + detail_name + '\', \'' + tp + '\'); " ';

								html += '>' + data[i].destination_reserved_qty + '</label></td>';
							}
						}else{
							html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						}
					}else{
						if ((data[i].destination_produced_tocheck_qty != null) && (data[i].destination_produced_tocheck_qty != 0)) {
							if (data[i].origin_unit != null) {
								html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">' + data[i].destination_produced_tocheck_qty + '&nbsp;' + data[i].origin_unit + '</td>';
							}else{
								html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">' + data[i].destination_produced_tocheck_qty + '</td>';
							}
						}else{
							html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						}

					}

					html += '</tr>';
				}

				// *******************************************************************************************************************************************************************************************************
				// Invoiced Qty
				// *******************************************************************************************************************************************************************************************************

				if (((data[i].origin_invoiced_qty != null) && (data[i].origin_invoiced_qty != 0)) || ((data[i].origin_produced_bad_qty != null) && (data[i].origin_produced_bad_qty != 0)) ||
					((data[i].destination_invoiced_qty != null) && (data[i].destination_invoiced_qty != 0)) || ((data[i].destination_produced_bad_qty != null) && (data[i].destination_produced_bad_qty != 0))) {

					html += '<tr style="height:20px">';
					html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>';

					if (data[i].origin_doctype == 'ioi Sales Order') {
						html += '•&nbsp;' + __('Invoiced qty');
					}else if ((data[i].origin_doctype == 'ioi Purchases Order') || (data[i].origin_doctype == 'ioi Purchases Proposal Internal')) {
						html += '•&nbsp;' + __('Invoiced qty');
					}else if (data[i].origin_doctype == 'ioi Production') {
						html += '•&nbsp;' + __('Produced bad qty');
					}else if (data[i].origin_doctype == 'ioi Dossier') {
						html += '•&nbsp;' + __('Produced bad qty');
					}else  {
						html += '';
					}

					html += '</b></td>';

					if ((data[i].origin_doctype != 'ioi Production') && (data[i].origin_doctype != 'ioi Dossier')) {
						if ((data[i].origin_invoiced_qty != null) && (data[i].origin_invoiced_qty != 0)) {
							if (data[i].origin_unit != null) {
								html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">';
								html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
								html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';" ';

								let dc = data[i].origin_doctype;
								let name = data[i].origin_master_name;
								let detail_name = data[i].origin_detail_name;
								let tp = 'INVOICED_QTY';

								html += 'onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.show_detail(\'' + dc + '\', \'' + name + '\',\'' + detail_name + '\', \'' + tp + '\'); " ';

								html += '>' + data[i].origin_invoiced_qty + '&nbsp;' + data[i].origin_unit + '</label></td>';
							}else{
								html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">';
								html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
								html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';" ';

								let dc = data[i].origin_doctype;
								let name = data[i].origin_master_name;
								let detail_name = data[i].origin_detail_name;
								let tp = 'INVOICED_QTY';

								html += 'onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.show_detail(\'' + dc + '\', \'' + name + '\',\'' + detail_name + '\', \'' + tp + '\'); " ';

								html += '>' + data[i].origin_invoiced_qty + '</label></td>';
							}
						}else{
							html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						}
					}else{
						if ((data[i].origin_produced_bad_qty != null) && (data[i].origin_produced_bad_qty != 0)) {
							if (data[i].origin_unit != null) {
								html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">' + data[i].origin_produced_bad_qty + '&nbsp;' + data[i].origin_unit + '</td>';
							}else{
								html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">' + data[i].origin_produced_bad_qty + '</td>';
							}
						}else{
							html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						}

					}

					html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>';

					if (data[i].destination_doctype == 'ioi Sales Order') {
						html += '•&nbsp;' + __('Invoiced qty');
					}else if ((data[i].destination_doctype == 'ioi Purchases Order') || (data[i].destination_doctype == 'ioi Purchases Proposal Internal')) {
						html += '•&nbsp;' + __('Invoiced qty');
					}else if (data[i].destination_doctype == 'ioi Production') {
						html += '•&nbsp;' + __('Produced bad qty');
					}else if (data[i].destination_doctype == 'ioi Dossier') {
						html += '•&nbsp;' + __('Produced bad qty');
					}else {
						html += '';
					}

					html += '</b></td>';

					if ((data[i].destination_doctype != 'ioi Production') && (data[i].destination_doctype != 'ioi Dossier')) {
						if ((data[i].destination_invoiced_qty != null) && (data[i].destination_invoiced_qty != 0)) {
							if (data[i].destination_unit != null) {
								html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">';
								html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
								html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';" ';

								let dc = data[i].destination_doctype;
								let name = data[i].destination_master_name;
								let detail_name = data[i].destination_detail_name;
								let tp = 'INVOICED_QTY';

								html += 'onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.show_detail(\'' + dc + '\', \'' + name + '\',\'' + detail_name + '\', \'' + tp + '\'); " ';

								html += '>' + data[i].destination_invoiced_qty + '&nbsp;' + data[i].destination_unit + '</label></td>';
							}else{
								html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">';
								html += '<label style="position:relative; left:0px; top:2px;height:15px;" ';
								html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';" ';

								let dc = data[i].destination_doctype;
								let name = data[i].destination_master_name;
								let detail_name = data[i].destination_detail_name;
								let tp = 'INVOICED_QTY';

								html += 'onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.show_detail(\'' + dc + '\', \'' + name + '\',\'' + detail_name + '\', \'' + tp + '\'); " ';

								html += '>' + data[i].destination_invoiced_qty + '</label></td>';
							}
						}else{
							html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						}
					}else{
						if ((data[i].destination_produced_bad_qty != null) && (data[i].destination_produced_bad_qty != 0)) {
							if (data[i].origin_unit != null) {
								html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">' + data[i].destination_produced_bad_qty + '&nbsp;' + data[i].origin_unit + '</td>';
							}else{
								html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">' + data[i].destination_produced_bad_qty + '</td>';
							}
						}else{
							html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						}

					}

					html += '</tr>';

					html += '<tr style="height:30px">';

					if (i < data.length-1) {
						html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;"><b><u>' + __("Origin") + '</u></b></td>';
						html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;"><b><u>' + __("Destination") + '</u></b></td>';
						html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
					}else{

						html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
						html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
					}

					html += '</tr>';
				}

			}

			html += '</table>';

		}

		html += '</div>';

		return html;
	}

	static show_detail(doctype, name, detail_name, tp)
	{
		let method = 'silicon_ioi.ioi_jit.doctype.ioi_jit_engine_link.ioi_jit_engine_link.ioi_jit_engine_link_get_detail_of_qty';

		let data = [];

		frappe.call({  	method: method,
						args: {"doctype": doctype, "name": name, "detail_name": detail_name, "tp": tp},
						async: false,
						callback:function(r)	{
							data = r.message;
						}
		});

		if (data.length > 0) {

			let title = '';

			if (doctype.toUpperCase() == 'IOI SALES ORDER') {

				if (tp.toUpperCase() == 'RESERVED_QTY') {
					title = __('Sales Delivery (reservation)');
				}else if (tp.toUpperCase() == 'DELIVERED_QTY') {
					title = __('Sales Delivery');
				}else if (tp.toUpperCase() == 'INVOICED_QTY') {
					title = __('Sales Invoice');
				}
			}else{
				if (doctype.toUpperCase() == 'IOI PRODUCTION BOM') {
					title = __('Production BOM');
				}else if (doctype.toUpperCase() == 'IOI DOSSIER BOM') {
					title = __('Dossier BOM');
				}else if (tp.toUpperCase() == 'DELIVERED_QTY') {
					title = __('Purchases Receipt');
				}else if (tp.toUpperCase() == 'INVOICED_QTY') {
					title = __('Purchases Invoice');
				}
			}

			var jit_sd = new frappe.ui.Dialog({
				'title': title,
				'fields': [
					{'fieldname': 'html_jit_engine_link_detail_qty', 'fieldtype': 'HTML'}
				],
				primary_action_label: __('Close'),
				primary_action: function(){

					jit_sd.hide();

				},

			});

			let html = '';

			html += '<div style="overflow: auto; overflow-x: auto; height:400px; width: 100%;">';

			let table_width = 1530;

			if ((doctype.toUpperCase() == 'IOI PRODUCTION BOM') || (doctype.toUpperCase() == 'IOI DOSSIER BOM')) {
				table_width -= 210;
			}

			let col1_width = 210;
			let col2_width = 210;

			let col3_width = 150;
			let col4_width = 230;
			let col5_width = 300;
			let col6_width = 120;
			let col7_width = 80;
			let col8_width = 230;

			html += '<div style="overflow: auto; overflow-x: auto; height:400px; width: 100%;">';

			html += '<table border=1 style="border: 1px solid #E8EAEB" width=' + table_width.toString() + 'px data-custom-grid="true">';
			html += '<tr style="height:30px">';
			html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;">&nbsp;<b>' + __("Document") + '</b></td>';

			if (doctype.toUpperCase() == 'IOI SALES ORDER') {
				html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;<b>' + __("Customer") + '</b></td>';
			}else if ((doctype.toUpperCase() != 'IOI PRODUCTION BOM') && (doctype.toUpperCase() != 'IOI DOSSIER BOM')) {
				html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;<b>' + __("Supplier") + '</b></td>';
			}

			html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;">&nbsp;<b>' + __("Document date") + '</b></td>';
			html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;<b>' + __("Item") + '</b></td>';
			html += '<td width=' + col5_width.toString() + 'px style="vertical-align: middle;">&nbsp;<b>' + __("Item description") + '</b></td>';


			if (tp.toUpperCase() == 'RESERVED_QTY') {
				html += '<td width=' + col6_width.toString() + 'px style="vertical-align: middle;">&nbsp;<b>' + __("Reserved qty") + '</b></td>';
			}else if (tp.toUpperCase() == 'DELIVERED_QTY') {
				html += '<td width=' + col6_width.toString() + 'px style="vertical-align: middle;">&nbsp;<b>' + __("Delivered qty") + '</b></td>';
			}else if (tp.toUpperCase() == 'INVOICED_QTY') {
				html += '<td width=' + col6_width.toString() + 'px style="vertical-align: middle;">&nbsp;<b>' + __("Invoiced qty") + '</b></td>';
			}

			html += '<td width=' + col7_width.toString() + 'px style="vertical-align: middle;">&nbsp;<b>' + __("Unit") + '</b></td>';
			html += '<td width=' + col8_width.toString() + 'px style="vertical-align: middle;">&nbsp;<b>' + __("Status") + '</b></td>';

			html += '</tr>';
			html += '</table>';

			html += '<table border=1 style="border: 1px solid #E8EAEB" width=' + table_width.toString() + 'px>';

			for (var i = 0; i < data.length; i++) {

				html += '<tr style="height:30px">';

				if (data[i].ioistatus != null) {
					html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle; color: #000000;" bgcolor="' + data[i].ioistatus_bgcolor + '">';
					html += '<label style="position:relative; left:5px; top:2px;height:15px;" ';

					let url = '';

					if (doctype.toUpperCase() == 'IOI SALES ORDER') {

						if (tp.toUpperCase() == 'RESERVED_QTY') {
							url = '/app/ioi-sales-delivery/';
						}else if (tp.toUpperCase() == 'DELIVERED_QTY') {
							url = '/app/ioi-sales-delivery/';
						}else if (tp.toUpperCase() == 'INVOICED_QTY') {
							url = '/app/ioi-sales-invoice/';
						}
					}else{
						if (tp.toUpperCase() == 'DELIVERED_QTY') {
							if (doctype.toUpperCase() == 'IOI PRODUCTION BOM') {
								url = '/app/ioi-production/';
							}else if (doctype.toUpperCase() == 'IOI DOSSIER BOM') {
								url = '/app/ioi-dossier/';
							}else{
								url = '/app/ioi-purchases-receipt/';
							}
						}else if (tp.toUpperCase() == 'INVOICED_QTY') {
							url = '/app/ioi-purchases-invoice/';
						}
					}

					url += data[i].parent;

					html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';"';
					html += 'onclick="window.open(\'' + url + '\');"';

					html += '>' + data[i].pk_formatted + '</label></td>';
				}else{
					html += '<td width=' + col1_width.toString() + 'px style="vertical-align: middle;">';
					html += '<label style="position:relative; left:5px; top:2px;height:15px;" ';

					let url = '';

					if (doctype.toUpperCase() == 'IOI SALES ORDER') {

						if (tp.toUpperCase() == 'RESERVED_QTY') {
							url = '/app/ioi-sales-delivery/';
						}else if (tp.toUpperCase() == 'DELIVERED_QTY') {
							url = '/app/ioi-sales-delivery/';
						}else if (tp.toUpperCase() == 'INVOICED_QTY') {
							url = '/app/ioi-sales-invoice/';
						}
					}else{
						if (tp.toUpperCase() == 'DELIVERED_QTY') {
							if (doctype.toUpperCase() == 'IOI PRODUCTION BOM') {
								url = '/app/ioi-production/';
							}else if (doctype.toUpperCase() == 'IOI DOSSIER BOM') {
								url = '/app/ioi-dossier/';
							}else{
								url = '/app/ioi-purchases-receipt/';
							}
						}else if (tp.toUpperCase() == 'INVOICED_QTY') {
							url = '/app/ioi-purchases-invoice/';
						}
					}

					url += data[i].parent;

					html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';"';
					html += 'onclick="window.open(\'' + url + '\');"';

					html += '>' + data[i].pk_formatted + '</label></td>';

				}

				if ((doctype.toUpperCase() != 'IOI PRODUCTION BOM') && (doctype.toUpperCase() != 'IOI DOSSIER BOM')) {

					if (data[i].customer_supplier_id != null) {
						html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">';
						html += '<label style="position:relative; left:5px; top:2px;height:15px;" ';

						let url = '';

						if (doctype.toUpperCase() == 'IOI SALES ORDER') {
							url = '/app/ioi-customer/';
						}else {
							url = '/app/ioi-supplier/';
						}

						url += data[i].customer_supplier_id;

						html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';"';

						html += 'onclick="window.open(\'' + url + '\');"';

						html += '>' + data[i].customer_supplier_id + '</label></td>';
					}else{
						html += '<td width=' + col2_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
					}
				}

				if (data[i].document_date != null) {
					html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;">&nbsp;' + data[i].document_date + '</td>';
				}else{
					html += '<td width=' + col3_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
				}

				if (data[i].item_id != null) {
					html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">';

					html += '<label style="position:relative; left:5px; top:2px;height:15px;" ';
					html += 'onmouseover="this.style.cursor=\'pointer\';this.style.textDecoration = \'underline\';" onmouseleave="this.style.cursor=\'none\';this.style.textDecoration = \'none\';"';

					let url = '/app/ioi-item/' + data[i].item_id;
					html += 'onclick="window.open(\'' + url + '\');"';

					html += '>' + data[i].item_id + '</label></td>';

				}else{
					html += '<td width=' + col4_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
				}

				if (data[i].item_description != null) {
					html += '<td width=' + col5_width.toString() + 'px style="vertical-align: middle;">&nbsp;' + data[i].item_description + '</td>';
				}else{
					html += '<td width=' + col5_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
				}

				if ((data[i].qt != null) && (data[i].qt != 0)) {
					html += '<td width=' + col6_width.toString() + 'px style="vertical-align: middle;" align="right">' + data[i].qt + '&nbsp;</td>';
				}else{
					html += '<td width=' + col6_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
				}

				if (data[i].unit_id != null) {
					html += '<td width=' + col7_width.toString() + 'px style="vertical-align: middle;">&nbsp;' + data[i].unit_id + '</td>';
				}else{
					html += '<td width=' + col7_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
				}

				if (data[i].ioistatus != null) {
					html += '<td width=' + col8_width.toString() + 'px style="vertical-align: middle; color:#000000;" bgcolor="' + data[i].ioistatus_bgcolor + '">&nbsp;' + data[i].ioistatus_description + ' ( ' + data[i].ioistatus + ' )' + '</td>';
				}else{
					html += '<td width=' + col8_width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
				}

				html += '</tr>';


			}

			html += '</table>';


			html += '</div>';


			jit_sd.fields_dict.html_jit_engine_link_detail_qty.$wrapper.html(html);

			if ((doctype.toUpperCase() != 'IOI PRODUCTION BOM') && (doctype.toUpperCase() != 'IOI DOSSIER BOM')) {
				jit_sd.$wrapper.find('.modal-dialog').css("max-width", "1580px").css("width", "1580px");
			}else{
				jit_sd.$wrapper.find('.modal-dialog').css("max-width", "1370px").css("width", "1370px");
			}
			jit_sd.show();

		}
	}

	static jump_to_single_module(doctype,  name)
	{
		let method = 'silicon_ioi.ioi_system.doctype.ioi_jump_to_single_doctype.ioi_jump_to_single_doctype.ioi_jump_to_single_doctype_add_entry';

		frappe.call({  	method: method,
						args: {'doctype': doctype, 'name': name},
						async: false,
						callback:function(r)	{

							let dc = doctype.toLowerCase();
							dc = dc.replaceAll(' ', '-');

							let url = '/app/' + dc + '/';
							window.open(url);
						}
		});
	}

	static origin_button(id)
	{
		silicon_ioi.doctype.ioiJITEngineLinkFrame.do_change_qty_window(id, 'ORIGIN');
	}

	static destination_button(id)
	{
		silicon_ioi.doctype.ioiJITEngineLinkFrame.do_change_qty_window(id, 'DESTINATION');
	}

	static do_change_qty_window(id, tp)
	{
		let s = id;

		while (s.indexOf('_') != -1) {
			s = s.substring(s.indexOf('_')+1, s.length);
		}

		s = s.trim();

		let origin_master_name = document.getElementById('origin_master_name_' + s).value;
		let origin_detail_name = document.getElementById('origin_detail_name_' + s).value;

		let destination_master_name = document.getElementById('destination_master_name_' + s).value;
		let destination_detail_name = document.getElementById('destination_detail_name_' + s).value;


		let origin_qty = document.getElementById('origin_qty_' + s).value;
		let origin_unit = document.getElementById('origin_unit_' + s).value;


		let destination_qty = document.getElementById('destination_qty_' + s).value;
		let destination_unit = document.getElementById('destination_unit_' + s).value;

		let origin_ordered_qty = document.getElementById('origin_ordered_qty_' + s).value;
		let destination_ordered_qty = document.getElementById('destination_ordered_qty_' + s).value;


		let title = '';

		if (tp.toUpperCase() == 'ORIGIN') {
			title = __("Modify origin scheduled quantity")
		}else{
			title = __("Modify destination scheduled quantity")
		}

		var z = new frappe.ui.Dialog({
			'title': title,
			'fields': [
				{'fieldname': 'html_jit_engine_link_qties', 'fieldtype': 'HTML'}
			],
			primary_action_label: __('Ok'),
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				let button_ok = document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1];

				button_ok.disabled = true;

				let new_origin_qty = 0;
				let new_destination_qty = 0

				if (tp.toUpperCase() == 'ORIGIN') {

					if (document.getElementById('html_jit_engine_link_qties_origin_qty').value) {
						if (parseFloat(document.getElementById('html_jit_engine_link_qties_origin_qty').value) < 0) {
							button_ok.disabled = false;
							frappe.msgprint({ title: __("Message"), message: __("Origin qty has to be greater than or equal to zero"), indicator: "red" });
							raise;
						}

						if (parseFloat(document.getElementById('html_jit_engine_link_qties_origin_qty').value) > parseFloat(origin_ordered_qty)) {
							button_ok.disabled = false;
							frappe.msgprint({ title: __("Message"), message: __("Origin qty has to be lower than or equal to the ordered qty"), indicator: "red" });
							raise;
						}
					}

				}else{
					if (document.getElementById('html_jit_engine_link_qties_destination_qty').value) {
						if (parseFloat(document.getElementById('html_jit_engine_link_qties_destination_qty').value) < 0) {
							button_ok.disabled = false;
							frappe.msgprint({ title: __("Message"), message: __("Destination qty has to be greater than or equal to zero"), indicator: "red" });
							raise;
						}

						if (parseFloat(document.getElementById('html_jit_engine_link_qties_destination_qty').value) > parseFloat(destination_ordered_qty)) {
							button_ok.disabled = false;
							frappe.msgprint({ title: __("Message"), message: __("Destination qty has to be lower than or equal to the ordered qty"), indicator: "red" });
							raise;
						}
					}

				}

				new_origin_qty = parseFloat(document.getElementById('html_jit_engine_link_qties_origin_qty').value);
				new_destination_qty = parseFloat(document.getElementById('html_jit_engine_link_qties_destination_qty').value);

				if ((parseFloat(new_origin_qty) != parseFloat(origin_qty)) || (parseFloat(new_destination_qty) != parseFloat(destination_qty))) {

					if (tp.toUpperCase() == 'ORIGIN') {
						if (parseFloat(new_origin_qty) != 0) {
							silicon_ioi.doctype.ioiJITEngineLinkFrame.do_update_delete_link(tp, origin_master_name, origin_detail_name, destination_master_name, destination_detail_name, new_origin_qty, new_destination_qty, z, s);
						}else{
							button_ok.disabled = false;

							let msg = __('This scheduled operation link will be deleted. Do you want to continue ?');

							frappe.confirm(	msg,
											() => 	{
														silicon_ioi.doctype.ioiJITEngineLinkFrame.sleep_static(200).then(() => {

															silicon_ioi.doctype.ioiJITEngineLinkFrame.do_update_delete_link(tp, origin_master_name, origin_detail_name, destination_master_name, destination_detail_name, new_origin_qty, new_destination_qty, z, s);
														});
													},
											() => 	{
													}
							);
						}
					}else{
						if (parseFloat(new_destination_qty) != 0) {
							silicon_ioi.doctype.ioiJITEngineLinkFrame.do_update_delete_link(tp, origin_master_name, origin_detail_name, destination_master_name, destination_detail_name, new_origin_qty, new_destination_qty, z, s);
						}else{
							button_ok.disabled = false;

							let msg = __('This scheduled operation link will be deleted. Do you want to continue ?');

							frappe.confirm(	msg,
											() => 	{
														silicon_ioi.doctype.ioiJITEngineLinkFrame.sleep_static(200).then(() => {

															silicon_ioi.doctype.ioiJITEngineLinkFrame.do_update_delete_link(tp, origin_master_name, origin_detail_name, destination_master_name, destination_detail_name, new_origin_qty, new_destination_qty, z, s);
														});
													},
											() => 	{
													}
							);
						}

					}

				}else{
					z.hide();
				}

			},
			secondary_action: function(){

				z.hide();
			}

		});

		if (document.getElementById('html_jit_engine_link_qties_origin_qty_label')) {
			document.getElementById('html_jit_engine_link_qties_origin_qty_label').remove();
		}

		if (document.getElementById('html_jit_engine_link_qties_origin_qty')) {
			document.getElementById('html_jit_engine_link_qties_origin_qty').remove();
		}

		if (document.getElementById('html_jit_engine_link_qties_origin_unit')) {
			document.getElementById('html_jit_engine_link_qties_origin_unit').remove();
		}

		if (document.getElementById('html_jit_engine_link_qties_destination_qty_label')) {
			document.getElementById('html_jit_engine_link_qties_destination_qty_label').remove();
		}

		if (document.getElementById('html_jit_engine_link_qties_destination_qty')) {
			document.getElementById('html_jit_engine_link_qties_destination_qty').remove();
		}

		if (document.getElementById('html_jit_engine_link_qties_destination_unit')) {
			document.getElementById('html_jit_engine_link_qties_destination_unit').remove();
		}



		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:75px; width: 100%;">';


		// Origin Qty
		html += '	<div style="position: relative; top: 0px; left: 2px; width:100px;">';
		html += '		<label id="html_jit_engine_link_qties_origin_qty_label" style="position: absolute; top: 0px; left: 0px;z-index:5;">' + __("Origin qty") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 100px; height: 25px;"> ';
		html += '			<input id="html_jit_engine_link_qties_origin_qty" type="number" step="any" class="input-with-feedback form-control bold" value="' + origin_qty + '" ';
		if (tp.toUpperCase() == 'DESTINATION') {
			html += 'disabled';
		}
		html += '>';
		html += '		</div>';
		html += '	</div>';

		// Origin Unit
		html += '	<div style="position: relative; top: 0px; left: 112px; width:100px;">';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 100px; height: 25px;"> ';
		html += '			<input id="html_jit_engine_link_qties_origin_unit" type="text" class="input-with-feedback form-control bold" value="' + origin_unit + '" disabled>';
		html += '		</div>';
		html += '	</div>';

		// Destination Qty
		html += '	<div style="position: relative; top: 0px; left: 222px; width:100px;">';
		html += '		<label id="html_jit_engine_link_qties_destination_qty_label" style="position: absolute; top: 0px; left: 0px;z-index:5;">' + __("Destination qty") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 100px; height: 25px;"> ';
		html += '			<input id="html_jit_engine_link_qties_destination_qty" type="number" step="any" class="input-with-feedback form-control bold" value="' + destination_qty + '" ';
		if (tp.toUpperCase() == 'ORIGIN') {
			html += 'disabled';
		}
		html += '>';

		html += '		</div>';
		html += '	</div>';

		// Destination Unit
		html += '	<div style="position: relative; top: 0px; left: 332px; width:100px;">';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 100px; height: 25px;"> ';
		html += '			<input id="html_jit_engine_link_qties_destination_unit" type="text" class="input-with-feedback form-control bold" value="' + destination_unit + '" disabled>';
		html += '		</div>';
		html += '	</div>';


		html += '</div>';

		z.fields_dict.html_jit_engine_link_qties.$wrapper.html(html);
		z.$wrapper.find('.modal-dialog').css("max-width", "475px").css("width", "475px");
		z.$wrapper.find('.modal-dialog').css("max-height", "450px").css("height", "450px");
		z.show();

		silicon_ioi.doctype.ioiJITEngineLinkFrame.sleep_static(500).then(() => {

			if (tp.toUpperCase() == 'ORIGIN') {
				document.getElementById('html_jit_engine_link_qties_origin_qty').focus();
			}else{
				document.getElementById('html_jit_engine_link_qties_destination_qty').focus();
			}

		});
	}

	static do_update_delete_link(tp, origin_master_name, origin_detail_name, destination_master_name, destination_detail_name, new_origin_qty, new_destination_qty, z, id)
	{
		let method = 'silicon_ioi.ioi_jit.doctype.ioi_jit_engine_link.ioi_jit_engine_link.ioi_jit_engine_link_update_delete_link';

		frappe.call({  	method: method,
						args: {	"tp": tp,
								"origin_master_name": origin_master_name,
								"origin_detail_name": origin_detail_name,
								"destination_master_name": destination_master_name,
								"destination_detail_name": destination_detail_name,
								"new_origin_qty": new_origin_qty,
								"new_destination_qty": new_destination_qty

						},
						async: false,
						callback:function(r)	{

							if (tp.toUpperCase() == 'ORIGIN') {

								if (parseFloat(new_origin_qty) != 0) {

									if ((r.message.origin_qty == null) || (r.message.origin_qty == 0)) {
										document.getElementById('col_origin_qty_' + id).innerHTML = '';
									}else{
										if ((r.message.origin_unit != null) && (r.message.origin_unit != '')) {
											document.getElementById('col_origin_qty_' + id).innerHTML = r.message.origin_qty + '&nbsp;' + r.message.origin_unit;
										}else{
											document.getElementById('col_origin_qty_' + id).innerHTML = r.message.origin_qty;
										}
									}

									document.getElementById('col_origin_qty_' + id).innerHTML += '&nbsp;&nbsp;<input id="origin_button_' + id.toString() + '" type="button" value = "..." onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.origin_button(this.id);">';

									if ((r.message.destination_qty == null) || (r.message.destination_qty == 0)) {
										document.getElementById('col_destination_qty_' + id).innerHTML = '';
									}else{
										if ((r.message.destination_unit != null) && (r.message.destination_unit != '')) {
											document.getElementById('col_destination_qty_' + id).innerHTML = r.message.destination_qty + '&nbsp;' + r.message.destination_unit;
										}else{
											document.getElementById('col_destination_qty_' + id).innerHTML = r.message.destination_qty;
										}
									}

									document.getElementById('col_destination_qty_' + id).innerHTML += '&nbsp;&nbsp;<input id="destination_button_' + id.toString() + '" type="button" value = "..." onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.destination_button(this.id);">';

									document.getElementById('origin_qty_' + id.toString()).value = r.message.origin_qty;
									document.getElementById('destination_qty_' + id.toString()).value = r.message.destination_qty;


									z.hide();


								}else{
									window.location.reload();
								}

							}else{

								if (parseFloat(new_destination_qty) != 0) {

									if ((r.message.origin_qty == null) || (r.message.origin_qty == 0)) {
										document.getElementById('col_origin_qty_' + id).innerHTML = '';
									}else{
										if ((r.message.origin_unit != null) && (r.message.origin_unit != '')) {
											document.getElementById('col_origin_qty_' + id).innerHTML = r.message.origin_qty + '&nbsp;' + r.message.origin_unit;
										}else{
											document.getElementById('col_origin_qty_' + id).innerHTML = r.message.origin_qty;
										}
									}

									document.getElementById('col_origin_qty_' + id).innerHTML += '&nbsp;&nbsp;<input id="origin_button_' + id.toString() + '" type="button" value = "..." onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.origin_button(this.id);">';

									if ((r.message.destination_qty == null) || (r.message.destination_qty == 0)) {
										document.getElementById('col_destination_qty_' + id).innerHTML = '';
									}else{
										if ((r.message.destination_unit != null) && (r.message.destination_unit != '')) {
											document.getElementById('col_destination_qty_' + id).innerHTML = r.message.destination_qty + '&nbsp;' + r.message.destination_unit;
										}else{
											document.getElementById('col_destination_qty_' + id).innerHTML = r.message.destination_qty;
										}
									}

									document.getElementById('col_destination_qty_' + id).innerHTML += '&nbsp;&nbsp;<input id="destination_button_' + id.toString() + '" type="button" value = "..." onclick="silicon_ioi.doctype.ioiJITEngineLinkFrame.destination_button(this.id);">';

									document.getElementById('origin_qty_' + id.toString()).value = r.message.origin_qty;
									document.getElementById('destination_qty_' + id.toString()).value = r.message.destination_qty;


									z.hide();


								}else{
									window.location.reload();
								}
							}
						}
		});

	}

	static sleep_static(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}

}

silicon_ioi.doctype.ioiJITEngineLinkFrame = ioiJITEngineLinkFrame;
