export default class ioiPage extends frappe.ui.Page {
	make() {
		super.make()
		let me = this

		if (window.webkit != undefined || window.UniAndroid != undefined) {
			$(document).ready(function() {
				setTimeout(() => me.render_nav_btns_mobile_app(), 200)
			});
		}

		this.render_nav_if_running_wrong_url();
		this.render_nav_if_ponto_connect_issue();
	}

	render_nav_if_running_wrong_url(){
		if (!frappe.boot.running_on_prod_url){
			$("#wrong-site-badge").remove();
			$(".main-section .sticky-top .navbar.navbar-expand").css({"background-color":"#ffcfcf"});
			$(".main-section .sticky-top .navbar.navbar-expand .input-group.search-bar.text-muted")[0].insertAdjacentHTML('beforeBegin', `
				<span 	id="wrong-site-badge"
						class="badge align-middle"
						style="padding: 8px; background-color: #ffffff99"
						data-toggle="tooltip"
						title='${__("Some features are disabled (Ponto, Email...)")}'>${__("Running on wrong url")}</span>
			`);
		}
	}

	render_nav_if_ponto_connect_issue(){

		if (frappe.user_roles.includes('ioi Accounting Manager') && frappe.boot.ponto_connect?.filter((cmp) => cmp.max_retry_cnx == true).length > 0){
			$("#ponto-connect-issue-badge").remove();

			$(".main-section .sticky-top .navbar.navbar-expand").css({"background-color":"#ffcfcf"});
			$(".main-section .sticky-top .navbar.navbar-expand .input-group.search-bar.text-muted")[0].insertAdjacentHTML('beforeBegin', `
				<span 	id="ponto-connect-issue-badge"
						class="badge align-middle"
						style="padding: 8px; background-color: #ffffff99"
						data-toggle="tooltip"
						title='${__("Ponto Connect service is experiencing connection problems. Please reconnect to Ponto Connect")}'>${__("Ponto Connect: Connection issue")}</span>
			`);
		}
	}


	setup_scroll_handler() {
		// Overrides the function that makes the page-head disappear on scroll
		$(window).scroll(
			frappe.utils.throttle(() => {
				$(".page-head").toggleClass("drop-shadow", !!document.documentElement.scrollTop);
				$(".page-head").css("top", "var(--navbar-height)");
			}, 500)
		);
	}

	refresh_customize_bg_color_menu(){
		let customize_items = this.menu.children().find(`[data-label=${__('Customize')}]`);
		customize_items.each((idx) => {
			if(!customize_items[idx].getAttribute("ioi-doctype")){
				customize_items[idx].setAttribute("ioi-doctype", frappe.get_route()[1]);
			}
			let dt = customize_items[idx].getAttribute("ioi-doctype");
			frappe.call({
				method: "silicon_ioi.utils.lib.system.is_dt_customized",
				args: {
					doctype: dt
				},
				callback: (r) => {
					if (r.message.success) {
						$(`[ioi-doctype='${dt}']`).parent().parent().css('background','var(--ioi-heading-workspace-bg)')
					}else{
						$(`[ioi-doctype='${dt}']`).parent().parent().css('background',null)
					}
				}
			})


		})
	}

	setup_page()
	{

		super.setup_page();
		let me = this;
		setTimeout(() => {
			me.refresh_customize_bg_color_menu()
		}, 500)
	}

	render_nav_btns_mobile_app() {

		const id = `${this.title.toLowerCase().replaceAll(' ', '_')}_mobile_app_navigation_container`

		if (this.wrapper.find(`#${id}`)) {
			this.wrapper.find(`#${id}`).remove();
		}
	
		this.wrapper.append(`
			<div id=${id} class="w-100 d-flex justify-content-between" style="	position: fixed; top: 0; background-color: #f0f0f0; height: 6%; transition: all 0.4s; opacity: 1; z-index: 10000; opacity: 0.7">
				<div id="mobile_app_navigation_back" class="w-50 border d-flex justify-content-center align-items-center">
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-left font-weight-bold" viewBox="0 0 16 16">
						<path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
					</svg>
				</div>
				<div id="mobile_app_navigation_forward" class="w-50 border d-flex justify-content-center align-items-center">
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-right font-weight-bold" viewBox="0 0 16 16">
						<path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
					</svg>
				</div>
			</div>`
		);
	
		let navElement = this.wrapper.find(`#${id}`);
		let timeoutId;

		if (timeoutId) clearTimeout(timeoutId);
		timeoutId = setTimeout(() => navElement.css("top", "-6%"), 2500)
	
		let startY = 0;
	
		window.addEventListener("touchstart", (e) => {
			startY = e.touches[0].clientY;
		});
	
		window.addEventListener("touchmove", (e) => {
			const currentY = e.touches[0].clientY;
	
			if (currentY < startY) {

				if (timeoutId) clearTimeout(timeoutId);
				navElement.css("top", "-6%");

			} else if (currentY > startY) {

				navElement.css("top", "0");
				if (timeoutId) clearTimeout(timeoutId);
				timeoutId = setTimeout(() => navElement.css("top", "-6%"), 2500);
			}
	
			startY = currentY;
		});
	
		let backButton = this.wrapper.find(`#${id} #mobile_app_navigation_back`)
		let forwardButton = this.wrapper.find(`#${id} #mobile_app_navigation_forward`)
	
		backButton.on('click', () => window.history.back());
		forwardButton.on('click', () => window.history.forward());
	}
}

frappe.ui.Page = ioiPage;
frappe.request.report_error = function (xhr, request_opts) {
	var data = JSON.parse(xhr.responseText);
	var exc;
	if (data.exc) {
		try {
			exc = (JSON.parse(data.exc) || []).join("\n");
		} catch (e) {
			exc = data.exc;
		}
		delete data.exc;
	} else {
		exc = "";
	}

	const copy_markdown_to_clipboard = () => {
		const code_block = (snippet) => "```\n" + snippet + "\n```";

		let request_data = Object.assign({}, request_opts);
		request_data.request_id = xhr.getResponseHeader("X-Frappe-Request-Id");
		const traceback_info = [
			"### App Versions",
			code_block(JSON.stringify(frappe.boot.versions, null, "\t")),
			"### Route",
			code_block(frappe.get_route_str()),
			"### Traceback",
			code_block(exc),
			"### Request Data",
			code_block(JSON.stringify(request_data, null, "\t")),
			"### Response Data",
			code_block(JSON.stringify(data, null, "\t")),
		].join("\n");
		frappe.utils.copy_to_clipboard(traceback_info);
	};

	var show_communication = async function () {
		ioi_current_site = (await frappe.db.get_value("ioi User", frappe.session.user, "site_id"))?.message?.site_id;
		var error_report_message = [
			"<h5>Please type some additional information that could help us reproduce this issue:</h5>",
			'<div style="min-height: 100px; border: 1px solid #bbb; \
				border-radius: 5px; padding: 15px; margin-bottom: 15px;"></div>',
			"<hr>",
			"<h5>App Versions </h5>",
			"<pre>" + JSON.stringify(frappe.boot.versions, null, "\t") + "</pre>",
			"<h5>User</h5>",
			"<pre>",
			"Name: "+frappe.session.user_fullname,
			"Email: "+frappe.session.user_email,
			"ioi Site: "+ioi_current_site,
			"</pre>",
			"<hr>",
			"<h5>Route</h5>",
			"<pre>" + window.location.href + "</pre>",
			"<hr>",
			"<h5>When</h5>",
			"<pre>" + frappe.datetime.now_datetime() + "</pre>",
			"<hr>",
			"<h5>Error Report</h5>",
			"<pre>" + exc + "</pre>",
			"<hr>",
			"<h5>Request Data</h5>",
			"<pre>" + JSON.stringify(request_opts, null, "\t") + "</pre>",
			"<hr>",
			"<h5>Response JSON</h5>",
			"<pre>" + JSON.stringify(data, null, "\t") + "</pre>",
		].join("\n");

		var communication_composer = new frappe.views.CommunicationComposer({
			subject: "Error Report [" + frappe.datetime.nowdate() + "]",
			recipients: error_report_email,
			message: error_report_message,
			doc: {
				doctype: "User",
				name: frappe.session.user,
			},
		});
		communication_composer.dialog.$wrapper.css(
			"z-index",
			cint(frappe.error_dialog.$wrapper.css("z-index")) + 1
		);
	};

	if (exc) {
		var error_report_email = frappe.boot.error_report_email;

		request_opts = frappe.request.cleanup_request_opts(request_opts);

		// window.msg_dialog = frappe.msgprint({message:error_message, indicator:'red', big: true});

		if (!frappe.error_dialog) {
			frappe.error_dialog = new frappe.ui.Dialog({
				title: __("Server Error"),
			});

			if (error_report_email) {
				frappe.error_dialog.set_primary_action(__("Report"), () => {
					show_communication();
					frappe.error_dialog.hide();
				});
			} else {
				frappe.error_dialog.set_primary_action(__("Copy error to clipboard"), () => {
					copy_markdown_to_clipboard();
					frappe.error_dialog.hide();
				});
			}
			frappe.error_dialog.wrapper.classList.add("msgprint-dialog");
		}

		let parts = strip(exc).split("\n");

		let dialog_html = parts[parts.length - 1];

		if (data._exc_source) {
			dialog_html += "<br>";
			dialog_html += `Possible source of error: ${data._exc_source.bold()} `;
		}

		frappe.error_dialog.$body.html(dialog_html);
		frappe.error_dialog.show();
	}
};
