
frappe.get_route = () => {
	let route = frappe.router.current_route.slice();
	let boot_settings = undefined
	
	if (route[0].toLowerCase() === "list" && !location.pathname.includes("view")) {
		boot_settings = frappe.boot.user_saved_listview.find(el => el.for_doctype.toLowerCase() === route[1].toLowerCase().replace('-', ' '))
	}

	if (boot_settings) {
		route[route.length - 1] = boot_settings.list_view;
		frappe.router.current_route = route
	}

	return frappe.router.current_route
};

class ioiListViewSelect extends frappe.views.ListViewSelect {
	setup_views() {
		super.setup_views();

		if (this.current_view !== "Metabase") {
			this.add_view_to_menu("Metabase", () => {this.set_route("metabase")});
		}

		if (this.current_view !== "Expert") {
			this.add_view_to_menu("ioi List Expert", () => {this.set_route("expert")});
		}

		if (this.current_view !== "Pivot") {
			this.add_view_to_menu("ioi Pivot Expert", () => {this.set_route("pivot")});
		}

		if (this.current_view !== "Resources" && frappe.boot.resource_calendars.includes(this.doctype)) {
			this.add_view_to_menu("Resources", () => {this.set_route("resources")});
			$('[data-view="Resources"]').find("use")[0].setAttribute("href", "#icon-table");
		}

		if (this.current_view == "Resources") {
			this.get_resource_managers().then((managers) => {
				this.setup_dropdown_in_sidebar(__("Select a Canva"), managers);
			});
		}

		this.set_list_view_saving()
	}

	set_list_view_saving() {
		const click = () => {
			let view = this.current_view

			if (view === "ioi List Expert") { 
				view = "Expert" 
			} else if (view === "ioi Pivot Expert") { 
				view = "Pivot"
			}
	
			frappe.call('silicon_ioi.utils.lib.system.save_list_view_for_user', { doctype: this.doctype, view: view }).then(() => {
				setTimeout(() => location.reload(), 100)
			}
		)}

		let $el = this.page.add_custom_menu_item(
			this.parent,
			__('Set as Default'),
			click,
			true,
			null,
			null
		);

		$el[0].classList.add("mb-1", "mt-2", "p-0")

		$el[0].innerHTML = (`
			<button type="button" id="save_view_btn" class="btn btn-secondary btn-xs m-0" style="width: 96%; margin-left: 2%!important;">
				${__('Set as Default')}
			</button>
		`)
	}

	get_resource_managers() {
		const doctype = this.doctype;
		let managers = [];

		return frappe.db
			.get_list("ioi Event Resource Manager", {
				filters: {
					accessible_on_doctype: doctype,
				},
			})
			.then((result) => {
				if (!(result && Array.isArray(result) && result.length)) return;

				result.map((manager) => {
					managers.push({
						name: manager.name,
						route: `/app/${this.slug()}/view/resources/${manager.name}`,
					});
				});

				return managers;
			});
	}

	set_current_view() {
		super.set_current_view();
		const route = frappe.get_route();
		const view_name = frappe.utils.to_title_case(route[2] || "");
		if (route.length > 2 && view_name == "Metabase") {
			this.current_view = view_name;
		} else if (route.length > 2 && view_name == "Expert") {
			this.current_view = view_name; 
		} else if (route.length > 2 && view_name == "Pivot") {
			this.current_view = view_name; 
		} else if (route.length > 2 && view_name == "Resources") {
			this.current_view = view_name;
			const button = $('.custom-btn-group .btn-default').find("use")
			if (button.length) {
				button[0].setAttribute("href", "#icon-table");
			}
		}
	}
}

// Object.assign(frappe.views.BaseList.icon_map, {
// 	Metabase: "ioimetabase-dashboard-list"
// })

document.addEventListener("DOMContentLoaded", function() {
	frappe.views.ListViewSelect = ioiListViewSelect;

	frappe.router.list_views.push("resources");
	frappe.router.list_views.push("metabase");
	frappe.router.list_views.push("expert");
	frappe.router.list_views.push("pivot");

	Object.assign(frappe.router.list_views_route, {
		resources: "Resources",
		metabase: "Metabase",
		expert: "Expert",
		pivot: "Pivot",
	})
})
