frappe.provide("frappe.views");
frappe.provide("silicon_ioi.kanban")

const ioi_pd = require("./ioi_print_dialog.js");

const _get_original_actions_menu_items = frappe.views.ListView.prototype.get_actions_menu_items;

let reportList = [];


frappe.views.ListView.prototype.get_actions_menu_items = function () {
	const actions_menu_items = _get_original_actions_menu_items.call(this)
	actions_menu_items.forEach(f => {

		if (f.label == __("Print")) {
			window["std_dialog_func"] = f.action;
			f.action = ioi_pd.ioi_print_dialog;
		}else{
			if (cur_list.doctype == 'ioi Report' && f.label == __("Export")){
				f.action = silicon_ioi.ioiReportMethods.ioi_report_export;
			}
		}
	})

	return actions_menu_items
}


export default class ioiListView extends frappe.views.ListView {

	setup_page_head() {
		super.setup_page_head();
		this.add_print_btn();
	}

	after_render() {
		super.after_render();

		// Kanban settings can be added to *_list.js file
		if (!this.settings || !this.settings.kanban){
			this.views_menu.find('[data-view="Kanban"]').hide();
		}

		this.handle_dropdown()
	}

	add_print_btn(){

		if (!this.print_btn_added){
			this.page.add_button("", () => {
				ioi_pd.ioi_print_dialog();
			}, { icon: "printer", size: "sm" });
			this.print_btn_added = true;
		}

	}

	get_list_row_html_skeleton(left = "", right = "", fields = [], doc) {
			return `
				<div class="list-row-container" tabindex="1">
					<div class="level list-row">
						<div class="level-left ellipsis">
							${left}
						</div>
						<div class="level-right text-muted ellipsis">
							${right}
						</div>
					</div>
					${fields.map(field => {
						return `<div class="level list-row details-row d-none justify-content-between"><b>${field.label}</b><span>${doc[field.fieldname]}</span></div>`
					}).join('')}
					<div class="details-row-button">+</div>
				</div>
			`;
	}

	handle_dropdown() {
		let row_containers = document.getElementsByClassName('list-row-container')

		Array.from(row_containers).map(row => {
			(row.lastElementChild).onclick = (e) => {

				// Close all rows
				Array.from(row_containers).map(item => {
					if (item.lastElementChild != e.target) {
						Array.from(item.children).map(child => {
							if (Array.from(child.classList).includes('d-flex') && Array.from(child.classList).includes('details-row')) {
								child.classList.remove('d-flex')
								child.parentElement.lastElementChild.innerHTML = "+"
							}
						})
					}
				})

				// Open/Close one target row
				Array.from(e.target.offsetParent.children).map(details_row => {
					if (Array.from(details_row.classList).includes('details-row')) {
						if (Array.from(details_row.classList).includes('d-flex')) {
							details_row.classList.remove('d-flex')
							e.target.innerHTML = "+"
						} else if (!Array.from(details_row.classList).includes('d-flex')) {
							details_row.classList.add('d-flex')
							e.target.innerHTML = "-"

							addEventListener('resize', () => {
								remove_display()
								removeEventListener("resize", remove_display());
							})
						}

						function remove_display() {
							details_row.classList.remove('d-flex')
							e.target.innerHTML = "+"
						}
					}
				})
			}
		})
	}

	get_list_row_html(doc) {
		let fields = []

		cur_list.columns.map(item => {
			if (item.type == "Field") {
				fields.push({fieldname : item.df.fieldname, label: item.df.label})
			}
		})

		return this.get_list_row_html_skeleton(
			this.get_left_html(doc),
			this.get_right_html(doc),
			fields,
			doc
		);
	}
}

frappe.views.ListView = ioiListView;
