frappe.provide('silicon_ioi.doctype');

export class ioiStockInventoryGeneration
{
	static path_stock_inventory = 'silicon_ioi.ioi_wms.doctype.ioi_stock_inventory.ioi_stock_inventory';

	static is_dark_mode = 0;
	static bgcolor = 0;

	static current_site	= ''

	static ioiTable = null;
	static columns = [];
	static data = [];

	static from_where = '';
	static whs_id = '';
	static loc_id = '';
	static inventory_id = '';
	static inventory_mode = -1;
	static item_id = '';
	static batch_sn_id = '';
	static journal_id = ''
	static is_loading = true;


	static stock_inventory_generation(from_where = '', whs_id = '', loc_id = '', inventory_id = '', inventory_mode = -1, item_id = '', batch_sn_id = '', inventory_mode_readonly = false, whs_readonly = false, journal_id = '')
	{
		if ((!from_where) || ((from_where) && (from_where.trim() == ''))) {
			frappe.msgprint({title: __("Message"), message: __("from_where parameter must be filled with 'INV', 'WHS' or 'LOC'"), indicator: "red"});
			return false;
		}

		if ((from_where.toUpperCase() != 'INV') && (from_where.toUpperCase() != 'WHS') && (from_where.toUpperCase() != 'LOC')) {
			frappe.msgprint({title: __("Message"), message: __("from_where parameter must be filled with 'INV', 'WHS' or 'LOC'"), indicator: "red"});
			return false;
		}



		if (from_where.toUpperCase() == 'WHS') {

			if ((!whs_id) || ((whs_id) && (whs_id.trim() == ''))) {
				frappe.msgprint({title: __("Message"), message: __("whs_id parameter must be filled"), indicator: "red"});
				return false;
			}

		}else if (from_where.toUpperCase() == 'LOC') {

			if ((!whs_id) || ((whs_id) && (whs_id.trim() == ''))) {
				frappe.msgprint({title: __("Message"), message: __("whs_id parameter must be filled"), indicator: "red"});
				return false;
			}

			if ((!loc_id) || ((loc_id) && (loc_id.trim() == ''))) {
				frappe.msgprint({title: __("Message"), message: __("loc_id parameter must be filled"), indicator: "red"});
				return false;
			}
		}

		silicon_ioi.doctype.ioiStockInventoryGeneration.from_where = from_where;

		silicon_ioi.doctype.ioiStockInventoryGeneration.whs_id = ''

		if ((whs_id) && (whs_id.trim() != '')) {
			silicon_ioi.doctype.ioiStockInventoryGeneration.whs_id = whs_id;
		}

		silicon_ioi.doctype.ioiStockInventoryGeneration.loc_id = '';

		if ((loc_id) && (loc_id.trim() != '')) {
			silicon_ioi.doctype.ioiStockInventoryGeneration.loc_id = loc_id;
		}

		silicon_ioi.doctype.ioiStockInventoryGeneration.inventory_id = '';

		if ((inventory_id) && (inventory_id.trim() != '')) {
			silicon_ioi.doctype.ioiStockInventoryGeneration.inventory_id = inventory_id;
		}

		silicon_ioi.doctype.ioiStockInventoryGeneration.inventory_mode = -1;

		if ((inventory_mode) && (inventory_mode != '')) {
			silicon_ioi.doctype.ioiStockInventoryGeneration.inventory_mode = inventory_mode;
		}

		silicon_ioi.doctype.ioiStockInventoryGeneration.item_id = '';

		if ((item_id) && (item_id.trim() != '')) {
			silicon_ioi.doctype.ioiStockInventoryGeneration.item_id = item_id;
		}

		silicon_ioi.doctype.ioiStockInventoryGeneration.batch_sn_id = '';


		if ((batch_sn_id) && (batch_sn_id.trim() != '')) {
			silicon_ioi.doctype.ioiStockInventoryGeneration.batch_sn_id = batch_sn_id;
		}

		silicon_ioi.doctype.ioiStockInventoryGeneration.journal_id = '';

		if ((journal_id) && (journal_id.trim() != '')) {
			silicon_ioi.doctype.ioiStockInventoryGeneration.journal_id = journal_id;
		}



		silicon_ioi.doctype.ioiStockInventoryGeneration.is_loading = true;

		let method = silicon_ioi.doctype.ioiStockInventoryGeneration.path_stock_inventory + '.ioi_stock_inventory_generation_get_current_site';

		frappe.call({
			method: method,
			args: {
			},
			async: false,
			callback: function(r)	{
				silicon_ioi.doctype.ioiStockInventoryGeneration.current_site = r.message;

			}
		});

        let title = '';

		if (silicon_ioi.doctype.ioiStockInventoryGeneration.inventory_id == '') {
			title = __("Generate Stock Inventory")
		}else{
			title = __("Import from Stock")
		}

        var generateStockInventoryForm =  new frappe.ui.Dialog({
            'title': title,
            'static': true,
            'fields': [
                {'fieldname': 'html_stock_inventory_generation', 'fieldtype': 'HTML'}
            ],
            primary_action_label: __('Generate'),
            secondary_action_label: __('Cancel'),
            primary_action: function() {

				cur_dialog.disable_primary_action();

				if (silicon_ioi.doctype.ioiStockInventoryGeneration.from_where.toUpperCase() == 'LOC') {
					if ((document.getElementById('html_stock_inventory_generation_inventory_mode').value == 0) ||
						(document.getElementById('html_stock_inventory_generation_inventory_mode').value == 2) ||
						(document.getElementById('html_stock_inventory_generation_inventory_mode').value == 4)) {

						frappe.msgprint({title: __("Message"), message: __("Inventory mode must be with location"), indicator: "red"});
						cur_dialog.enable_primary_action();
						return false;
					}
				}

				if (!silicon_ioi.doctype.ioiStockInventoryGeneration.ioiTable) {
					frappe.msgprint({title: __("Message"), message: __("No selected items"), indicator: "red"});
					cur_dialog.enable_primary_action();
					return false;
				}

				let data = silicon_ioi.doctype.ioiStockInventoryGeneration.ioiTable.getData();

				if (!data) {
					frappe.msgprint({title: __("Message"), message: __("No selected items"), indicator: "red"});
					cur_dialog.enable_primary_action();
					return false;
				}

				if (data.length == 0) {
					frappe.msgprint({title: __("Message"), message: __("No selected items"), indicator: "red"});
					cur_dialog.enable_primary_action();
					return false;
				}

				let at_least_one = false;

				for (var i = 0; i < data.length; i++) {

					if (data[i]["checked"] == 1) {
						at_least_one = true;
						break;
					}
				}

				if (!at_least_one) {
					frappe.msgprint({title: __("Message"), message: __("No selected items"), indicator: "red"});
					cur_dialog.enable_primary_action();
					return false;
				}

				let items = [];

				for (var i = 0; i < data.length; i++) {

					if (data[i]["checked"] == 1) {

						let whs_id = '';
						let loc_id = '';
						let item_id = '';
						let batch_sn_id = '';
						let stored_qty_mode = 0;
						let qty = 0

						if ((data[i]["warehouse_id"]) && (data[i]["warehouse_id"].trim() != '')) {
							whs_id = data[i]["warehouse_id"];
						}

						if ((data[i]["location_id"]) && (data[i]["location_id"].trim() != '')) {
							loc_id = data[i]["location_id"];
						}

						if ((data[i]["item_id"]) && (data[i]["item_id"].trim() != '')) {
							item_id = data[i]["item_id"];
						}

						if ((data[i]["batch_sn_id"]) && (data[i]["batch_sn_id"].trim() != '')) {
							batch_sn_id = data[i]["batch_sn_id"];
						}

						if ((data[i]["stored_qty_mode"]) && (data[i]["stored_qty_mode"] != '')) {
							stored_qty_mode = data[i]["stored_qty_mode"];
						}

						if ((data[i]["qty"]) && (data[i]["qty"] != '')) {
							qty = data[i]["qty"];
						}

						items[items.length] = {"warehouse_id": whs_id, "location_id": loc_id, "item_id": item_id, "batch_sn_id": batch_sn_id, "stored_qty_mode": stored_qty_mode, "qty": qty}

					}
				}

				let method = silicon_ioi.doctype.ioiStockInventoryGeneration.path_stock_inventory + '.ioi_stock_inventory_generation_generate_inventory';

				frappe.call({
					method: method,
					args: {
						"from_where": silicon_ioi.doctype.ioiStockInventoryGeneration.from_where.toUpperCase(),
						"generation_tp": document.getElementById('html_stock_inventory_generation_generate_tp').value,
						"journal_id": document.getElementById('html_stock_inventory_generation_journal_id').value,
						"inventory_id": document.getElementById('html_stock_inventory_generation_inventory_id').value,
						"inventory_mode": document.getElementById('html_stock_inventory_generation_inventory_mode').value,
						"items": items
					},
					async: false,
					callback: function(r)	{

						if (r.message.error == 1) {
							frappe.msgprint({title: __("Message"), message: r.message.error_msg, indicator: "red"});
							cur_dialog.enable_primary_action();
							return false;
						}else{

							if (document.getElementById('html_stock_inventory_generation_open_inventory').checked) {

								let url = '/app/ioi-stock-inventory/' + r.message.inventory_id;
								window.open(url);

							}
							generateStockInventoryForm.hide();
							window.location.reload();
						}
					}
				});

            },
            secondary_action: function() {

                generateStockInventoryForm.hide();
            }

        });

        silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_inventory_mode_label');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_inventory_mode');

        silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_warehouse_label');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_warehouse_id');

		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_warehouse_location_label');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_warehouse_location_id');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_warehouse_location_button');

		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_item_label');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_item_id');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_item_button');

		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_batch_sn_label');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_batch_sn_id');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_batch_sn_button');

		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_with_qty_label');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_with_qty');

		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_batch_sn_locked_label');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_batch_sn_locked');

		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_stored_qty_mode_label');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_stored_qty_mode');

		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_no_inventory');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_no_inventory_label');

		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_inventory_days');

		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_not_prep_tohandle_label');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_not_prep_tohandle');


		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_no_record_label');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_no_record');

		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_search_term_label');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_search_term');

		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_search_in_label');

		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_in_description');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_in_description_label');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_in_budget');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_in_budget_label');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_in_category');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_in_category_label');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_in_classification');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_in_classification_label');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_in_family');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_in_family_label');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_in_type');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_in_type_label');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_in_group');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_in_group_label');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_in_subgroup');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_in_subgroup_label');



		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_refresh');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_table_container');

		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_button_all');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_button_none');

		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_generate_tp_label');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_generate_tp');

		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_journal_label');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_journal_id');

		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_inventory_label');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_inventory_id');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_inventory_button');

		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_open_inventory_label');
		silicon_ioi.doctype.ioiStockInventoryGeneration.remove_component('html_stock_inventory_generation_open_inventory');



		let html = '';

        html += '<div style="overflow: auto; overflow-x: auto; height:235px; width: 100%;">';

		// Inventory mode
		html += '	<div style="position: relative; top: 0px; left: 2px; width:350px;">';
		html += '		<label id="html_stock_inventory_generation_inventory_mode_label" style="position: absolute; top: 0px; left: 2px;">' + __("Inventory mode") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 350px; height: 30px;"> ';
		html += '		<select id="html_stock_inventory_generation_inventory_mode" class="input-with-feedback form-control bold" style="width:350px"> ';
		html += '			<option value="0" selected>' + __("Regular item, Warehouse only") + '</option> ';
		html += '			<option value="1">' + __("Regular item, Warehouse and location only") + '</option> ';
		html += '			<option value="2">' + __("Batch item, Warehouse only") + '</option> ';
		html += '			<option value="3">' + __("Batch item, Warehouse and location only") + '</option> ';
		html += '			<option value="4">' + __("SN item, Warehouse only") + '</option> ';
		html += '			<option value="5">' + __("SN item, Warehouse and location only") + '</option> ';
		html += '		</select> ';
		html += '		</div>';
		html += '	</div>';

		// Warehouse
		html += '	<div style="position: relative; top: 0px; left: 362px; width:260px;">';
		html += '		<label id="html_stock_inventory_generation_warehouse_label" style="position: absolute; top: 0px; left: 2px;">' + __("Warehouse") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 260px; height: 30px;"> ';
		html += '		<select id="html_stock_inventory_generation_warehouse_id" class="input-with-feedback form-control bold" style="width:260px"> ';


		method = silicon_ioi.doctype.ioiStockInventoryGeneration.path_stock_inventory + '.ioi_stock_inventory_generation_get_warehouses';

		frappe.call({
			method: method,
			args: {
				"inventory_mode": 0
			},
			async: false,
			callback: function(r)	{

				if (r.message.length > 0) {

					for (var i = 0; i < r.message.length; i++) {

						if (i == 0) {
							html += '			<option value="' + r.message[i].name + '" selected>' + r.message[i].name + '</option> ';
						}else{
							html += '			<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
						}
					}
				}
			}
		});

		html += '		</select> ';
		html += '		</div>';
		html += '	</div>';


		// Locations
		html += '	<div style="position: relative; top: 0px; left: 632px; width:270px;">';
		html += '		<label id="html_stock_inventory_generation_warehouse_location_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Location") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 230px; height: 25px;"> ';
		html += '			<input id="html_stock_inventory_generation_warehouse_location_id" type="text" class="input-with-feedback form-control bold" value="" style="text-transform: uppercase;" disabled>';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 240px; width: 30px; height: 25px;"> ';
		html +='			<button id="html_stock_inventory_generation_warehouse_location_button" style="position:absolute; height: 28px; width: 30px; z-index:10;border: none; outline:none;" disabled onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';


		// Stored qty mode
		html += '	<div style="position: relative; top: 65px; left: 2px; width:180px;">';
		html += '		<label id="html_stock_inventory_generation_stored_qty_mode_label" style="position: absolute; top: 0px; left: 2px;">' + __("Stored qty mode") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 180px; height: 30px;"> ';
		html += '		<select id="html_stock_inventory_generation_stored_qty_mode" class="input-with-feedback form-control bold" style="width:180px"> ';
		html += '			<option value="-1" selected>' + __("All") + '</option> ';
		html += '			<option value="0">' + __("Good") + '</option> ';
		html += '			<option value="1">' + __("To check") + '</option> ';
		html += '			<option value="2">' + __("Bad") + '</option> ';
		html += '		</select> ';
		html += '		</div>';
		html += '	</div>';

		// With qty
		html += '	<div style="position: relative; top: 75px; left: 192px; width:250px;">';
		html += '		<input type="checkbox" id="html_stock_inventory_generation_with_qty" style="position: absolute; top: 8px; left: 2px;" checked>';
		html += '		<label id="html_stock_inventory_generation_with_qty_label" style="position: absolute; top: 6px; left: 30px;">' + __("With qty") + '</label>';
		html += '	</div>';

		// Only Batch locked qty
		html += '	<div style="position: relative; top: 95px; left: 192px; width:250px;">';
		html += '		<input type="checkbox" id="html_stock_inventory_generation_batch_sn_locked" style="position: absolute; top: 8px; left: 2px; display:none;">';
		html += '		<label id="html_stock_inventory_generation_batch_sn_locked_label" style="position: absolute; top: 6px; left: 30px; display:none;">' + __("Locked qty") + '</label>';
		html += '	</div>';

		// Item
		html += '	<div style="position: relative; top: 65px; left: 362px; width:260px;">';
		html += '		<label id="html_stock_inventory_generation_item_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Item") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 220px; height: 25px;"> ';
		html += '			<input id="html_stock_inventory_generation_item_id" type="text" class="input-with-feedback form-control bold" value="" style="text-transform: uppercase;">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 230px; width: 30px; height: 25px;"> ';
		html +='			<button id="html_stock_inventory_generation_item_button" style="position:absolute; height: 28px; width: 30px; z-index:10;border: none; outline:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Batch SN
		html += '	<div style="position: relative; top: 65px; left: 632px; width:270px;">';
		html += '		<label id="html_stock_inventory_generation_batch_sn_label" style="position: absolute; top: 0px; left: 2px;z-index:5; ">' + __("Batch/SN") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 230px; height: 25px;"> ';
		html += '			<input id="html_stock_inventory_generation_batch_sn_id" type="text" class="input-with-feedback form-control bold" disabled value="" style="text-transform: uppercase;">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 240px; width: 30px; height: 25px;"> ';
		html +='			<button id="html_stock_inventory_generation_batch_sn_button" style="position:absolute; height: 28px; width: 30px; z-index:10;border: none; outline:none;" disabled onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';



		// No inventory since
		html += '	<div style="position: relative; top: 124px; left: 2px; width:250px;">';
		html += '		<input type="checkbox" id="html_stock_inventory_generation_no_inventory" style="position: absolute; top: 8px; left: 2px;" checked>';
		html += '		<label id="html_stock_inventory_generation_no_inventory_label" style="position: absolute; top: 6px; left: 30px;">' + __("No inventory since") + '</label>';
		html += '	</div>';

		// Inventory days
		html += '	<div style="position: relative; top: 130px; left: 2px; width:180px;">';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 180px; height: 25px;"> ';
		html += '			<input id="html_stock_inventory_generation_inventory_days" type="number" step="any" class="input-with-feedback form-control bold">';
		html += '		</div>';
		html += '	</div>';

		// Not in Prepation / Tohandle
		html += '	<div style="position: relative; top: 185px; left: 2px; width:250px;">';
		html += '		<input type="checkbox" id="html_stock_inventory_generation_not_prep_tohandle" style="position: absolute; top: 8px; left: 2px;" checked>';
		html += '		<label id="html_stock_inventory_generation_not_prep_tohandle_label" style="position: absolute; top: 6px; left: 30px;">' + __("Not in Preparation / To handle") + '</label>';
		html += '	</div>';


		// No records
		html += '	<div style="position: relative; top: 130px; left: 192px; width:160px;">';
		html += '		<label id="html_stock_inventory_generation_no_record_label" style="position: absolute; top: 0px; left: 2px;z-index:5; ">' + __("No record") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 160px; height: 25px;"> ';
		html += '			<input id="html_stock_inventory_generation_no_record" type="number" step="any" class="input-with-feedback form-control bold" value="500">';
		html += '		</div>';
		html += '	</div>';



		// Search term
		html += '	<div style="position: relative; top: 130px; left: 362px; width:270px;">';
		html += '		<label id="html_stock_inventory_generation_search_term_label" style="position: absolute; top: 0px; left: 2px;z-index:5; ">' + __("Search") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 260px; height: 25px;"> ';
		html += '			<input id="html_stock_inventory_generation_search_term" type="text" class="input-with-feedback form-control bold" value="" style="text-transform: uppercase;">';
		html += '		</div>';
		html += '	</div>';

		// Search in
		html += '	<div style="position: relative; top: 130px; left: 632px; width:270px;">';
		html += '		<label id="html_stock_inventory_generation_search_in_label" style="position: absolute; top: 0px; left: 2px;z-index:5; ">' + __("Search in") + '</label>';
		html += '	</div>';



		// In description
		html += '	<div style="position: relative; top: 145px; left: 632px; width:250px;">';
		html += '		<input type="checkbox" id="html_stock_inventory_generation_in_description" style="position: absolute; top: 8px; left: 2px;" checked>';
		html += '		<label id="html_stock_inventory_generation_in_description_label" style="position: absolute; top: 6px; left: 30px;">' + __("Description") + '</label>';
		html += '	</div>';

		// In budget
		html += '	<div style="position: relative; top: 145px; left: 772px; width:250px;">';
		html += '		<input type="checkbox" id="html_stock_inventory_generation_in_budget" style="position: absolute; top: 8px; left: 2px;" >';
		html += '		<label id="html_stock_inventory_generation_in_budget_label" style="position: absolute; top: 6px; left: 30px;">' + __("Budget") + '</label>';
		html += '	</div>';

		// In category
		html += '	<div style="position: relative; top: 165px; left: 632px; width:250px;">';
		html += '		<input type="checkbox" id="html_stock_inventory_generation_in_category" style="position: absolute; top: 8px; left: 2px;" >';
		html += '		<label id="html_stock_inventory_generation_in_category_label" style="position: absolute; top: 6px; left: 30px;">' + __("Category") + '</label>';
		html += '	</div>';

		// In classification
		html += '	<div style="position: relative; top: 165px; left: 772px; width:250px;">';
		html += '		<input type="checkbox" id="html_stock_inventory_generation_in_classification" style="position: absolute; top: 8px; left: 2px;" >';
		html += '		<label id="html_stock_inventory_generation_in_classification_label" style="position: absolute; top: 6px; left: 30px;">' + __("Classif.") + '</label>';
		html += '	</div>';


		// In family
		html += '	<div style="position: relative; top: 185px; left: 632px; width:250px;">';
		html += '		<input type="checkbox" id="html_stock_inventory_generation_in_family" style="position: absolute; top: 8px; left: 2px;" >';
		html += '		<label id="html_stock_inventory_generation_in_family_label" style="position: absolute; top: 6px; left: 30px;">' + __("Family") + '</label>';
		html += '	</div>';

		// In type
		html += '	<div style="position: relative; top: 185px; left: 772px; width:250px;">';
		html += '		<input type="checkbox" id="html_stock_inventory_generation_in_type" style="position: absolute; top: 8px; left: 2px;" >';
		html += '		<label id="html_stock_inventory_generation_in_type_label" style="position: absolute; top: 6px; left: 30px;">' + __("Type") + '</label>';
		html += '	</div>';

		// In group
		html += '	<div style="position: relative; top: 205px; left: 632px; width:250px;">';
		html += '		<input type="checkbox" id="html_stock_inventory_generation_in_group" style="position: absolute; top: 8px; left: 2px;" >';
		html += '		<label id="html_stock_inventory_generation_in_group_label" style="position: absolute; top: 6px; left: 30px;">' + __("Group") + '</label>';
		html += '	</div>';

		// In subgroup
		html += '	<div style="position: relative; top: 205px; left: 772px; width:250px;">';
		html += '		<input type="checkbox" id="html_stock_inventory_generation_in_subgroup" style="position: absolute; top: 8px; left: 2px;" >';
		html += '		<label id="html_stock_inventory_generation_in_subgroup_label" style="position: absolute; top: 6px; left: 30px;">' + __("Subgroup") + '</label>';
		html += '	</div>';

		// Refresh
		html += '	<div style="position: relative; top: 165px; left: 362px; width:262px;">';
		html += '		<div  style="position: absolute; top: 25px; left: 0px; width: 262px; height: 40px;"> ';
		html +='			<button id="html_stock_inventory_generation_refresh" class="btn btn-default ellipsis" style="position:absolute; height: 40px; width: 262px; z-index:10;" onclick="">' + __("Refresh") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Action
		html += '	<div style="position: relative; top: 0px; left: 922px; width:260px;">';
		html += '		<label id="html_stock_inventory_generation_generate_tp_label" style="position: absolute; top: 0px; left: 2px;">' + __("Generation") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 260px; height: 30px;"> ';
		html += '		<select id="html_stock_inventory_generation_generate_tp" class="input-with-feedback form-control bold" style="width:260px"> ';
		html += '			<option value="0" selected>' + __("New stock inventory") + '</option> ';
		html += '			<option value="1">' + __("Existing stock inventory") + '</option> ';
		html += '		</select> ';
		html += '		</div>';
		html += '	</div>';

		// Journal
		html += '	<div style="position: relative; top: 65px; left: 922px; width:260px;">';
		html += '		<label id="html_stock_inventory_generation_journal_label" style="position: absolute; top: 0px; left: 2px;">' + __("Journal") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 260px; height: 30px;"> ';
		html += '		<select id="html_stock_inventory_generation_journal_id" class="input-with-feedback form-control bold" style="width:260px"> ';


		method = silicon_ioi.doctype.ioiStockInventoryGeneration.path_stock_inventory + '.ioi_stock_inventory_get_journal';

		frappe.call({
			method: method,
			args: {
			},
			async: false,
			callback: function(r)	{

				if (r.message.length > 0) {

					for (var i = 0; i < r.message.length; i++) {

						if (i == 0) {

							html += '			<option value="' + r.message[i].name + '" selected>' + r.message[i].name + '</option> ';
						}else{
							html += '			<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
						}
					}
				}
			}
		});

		html += '		</select> ';
		html += '		</div>';
		html += '	</div>';

		// Existing inventory
		html += '	<div style="position: relative; top: 65px; left: 922px; width:260px;">';
		html += '		<label id="html_stock_inventory_generation_inventory_label" style="position: absolute; top: 0px; left: 2px; display: none;">' + __("Stock Inventory") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 220px; height: 25px;"> ';
		html += '			<input id="html_stock_inventory_generation_inventory_id" type="text" class="input-with-feedback form-control bold" value="" style="text-transform: uppercase; display: none;">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 230px; width: 30px; height: 25px;"> ';
		html +='			<button id="html_stock_inventory_generation_inventory_button" style="position:absolute; height: 28px; width: 30px; border: none; outline:none; display: none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';


		// Open the stock inventory
		html += '	<div style="position: relative; top: 130px; left: 922px; width:250px;">';
		html += '		<input type="checkbox" id="html_stock_inventory_generation_open_inventory" style="position: absolute; top: 8px; left: 2px;"';

		if (silicon_ioi.doctype.ioiStockInventoryGeneration.inventory_id == '') {
			html += ' checked ';
		}
		html += '>';

		html += '		<label id="html_stock_inventory_generation_open_inventory_label" style="position: absolute; top: 6px; left: 30px;">' + __("Jump to the stock inventory") + '</label>';
		html += '	</div>';


		// Vertical line
		html += '	<div style="position: relative; top: 0px; left: 912px; width:1px; height:230px;background:#ededed">';
		html += '	</div>';



        html += '</div>';

		html += '<div id="html_stock_inventory_generation_table_container" class="table table-bordered" data-custom-grid="true" style="overflow: auto; overflow-x: auto; overflow-y: hidden; height:380px;border: 1px solid #E6E9EC;">';
		html += '</div>';

		html += '<div style="overflow: auto; overflow-x: auto; height:40px; width: 100%;">';


		// Select all
		html += '	<div style="position: relative; top: 2px; left: 2px; width:110px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="html_stock_inventory_generation_button_all" class="btn btn-default ellipsis" style="height: 30px; width: 110px;" onclick="">' + __("All") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Select None
		html += '	<div style="position: relative; top: 2px; left: 122px; width:50px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px;">';
		html +='			<button id="html_stock_inventory_generation_button_none" class="btn btn-default ellipsis" style="height: 30px; width: 110px;" onclick="">' + __("None") + '</button>';
		html += '		</div>';
		html += '	</div>';




		html += '</div>';



        generateStockInventoryForm.fields_dict.html_stock_inventory_generation.$wrapper.html(html);
        generateStockInventoryForm.$wrapper.find('.modal-dialog').css("max-width", "1250px").css("width", "1250px");
        generateStockInventoryForm.show();

        silicon_ioi.doctype.ioiStockInventoryGeneration.sleep_static(250).then(() => {

			document.getElementById('html_stock_inventory_generation_inventory_mode').disabled = false;

			if (inventory_mode_readonly) {
				document.getElementById('html_stock_inventory_generation_inventory_mode').disabled = true;
			}

			document.getElementById('html_stock_inventory_generation_warehouse_id').disabled = false;

			if (whs_readonly) {
				document.getElementById('html_stock_inventory_generation_warehouse_id').disabled = true;
			}

			if ((document.getElementById('html_stock_inventory_generation_inventory_mode').value == 0) ||
				(document.getElementById('html_stock_inventory_generation_inventory_mode').value == 2) ||
				(document.getElementById('html_stock_inventory_generation_inventory_mode').value == 4)) {

				document.getElementById('html_stock_inventory_generation_warehouse_location_id').disabled = true;
				document.getElementById('html_stock_inventory_generation_warehouse_location_button').disabled = true;

			}else{
				document.getElementById('html_stock_inventory_generation_warehouse_location_id').disabled = false;
				document.getElementById('html_stock_inventory_generation_warehouse_location_button').disabled = false;
			}

			if ((document.getElementById('html_stock_inventory_generation_inventory_mode').value == 0) ||
				(document.getElementById('html_stock_inventory_generation_inventory_mode').value == 1)) {

				document.getElementById('html_stock_inventory_generation_batch_sn_id').disabled = true;
				document.getElementById('html_stock_inventory_generation_batch_sn_button').disabled = true;

				document.getElementById('html_stock_inventory_generation_batch_sn_locked_label').style.display = 'none';
				document.getElementById('html_stock_inventory_generation_batch_sn_locked').style.display = 'none';
				document.getElementById('html_stock_inventory_generation_batch_sn_locked').checked = false;

			}else{

				document.getElementById('html_stock_inventory_generation_batch_sn_id').disabled = false;
				document.getElementById('html_stock_inventory_generation_batch_sn_button').disabled = false;

				document.getElementById('html_stock_inventory_generation_batch_sn_locked_label').style.display = 'block';
				document.getElementById('html_stock_inventory_generation_batch_sn_locked').style.display = 'block';

			}



			let fct_change_inventory_mode = function() {



				if ((document.getElementById('html_stock_inventory_generation_inventory_mode').value == 0) || (document.getElementById('html_stock_inventory_generation_inventory_mode').value == 2) || (document.getElementById('html_stock_inventory_generation_inventory_mode').value == 4)) {


					document.getElementById('html_stock_inventory_generation_warehouse_location_id').disabled = true;
					document.getElementById('html_stock_inventory_generation_warehouse_location_button').disabled = true;
					document.getElementById('html_stock_inventory_generation_warehouse_location_id').value = '';

				}else{
					document.getElementById('html_stock_inventory_generation_warehouse_location_id').disabled = false;
					document.getElementById('html_stock_inventory_generation_warehouse_location_button').disabled = false;
				}


				if ((document.getElementById('html_stock_inventory_generation_inventory_mode').value == 0) || (document.getElementById('html_stock_inventory_generation_inventory_mode').value == 1)) {

					document.getElementById('html_stock_inventory_generation_batch_sn_id').disabled = true;
					document.getElementById('html_stock_inventory_generation_batch_sn_button').disabled = true;
					document.getElementById('html_stock_inventory_generation_batch_sn_id').value = '';

					document.getElementById('html_stock_inventory_generation_batch_sn_locked_label').style.display = 'none';
					document.getElementById('html_stock_inventory_generation_batch_sn_locked').style.display = 'none';
					document.getElementById('html_stock_inventory_generation_batch_sn_locked').checked = false;

				}else{

					document.getElementById('html_stock_inventory_generation_batch_sn_id').disabled = false;
					document.getElementById('html_stock_inventory_generation_batch_sn_button').disabled = false;

					document.getElementById('html_stock_inventory_generation_batch_sn_locked_label').style.display = 'block';
					document.getElementById('html_stock_inventory_generation_batch_sn_locked').style.display = 'block';
				}

				let old_value = ''

				if (document.getElementById('html_stock_inventory_generation_warehouse_id').options.length > 0) {
					old_value = document.getElementById('html_stock_inventory_generation_warehouse_id').value;
				}

				if (!whs_readonly) {

					for (var i = document.getElementById('html_stock_inventory_generation_warehouse_id').options.length-1; i >= 0; i--) {
						document.getElementById('html_stock_inventory_generation_warehouse_id').remove(i)
					}



					let method = silicon_ioi.doctype.ioiStockInventoryGeneration.path_stock_inventory + '.ioi_stock_inventory_generation_get_warehouses';

					frappe.call({
						method: method,
						args: {
							"inventory_mode": document.getElementById('html_stock_inventory_generation_inventory_mode').value
						},
						async: false,
						callback: function(r)	{

							if (r.message.length > 0) {

								for (var i = 0; i < r.message.length; i++) {

									let option = document.createElement("option");
									option.value = r.message[i].name
									option.text = r.message[i].name

									if (old_value != '') {
										if (old_value == r.message[i].name) {
											option.selected = true;
										}
									}else{
										if (i == 0) {
											option.selected = true;
										}
									}

									document.getElementById('html_stock_inventory_generation_warehouse_id').add(option);
								}
							}
						}
					});
				}

				if (!silicon_ioi.doctype.ioiStockInventoryGeneration.is_loading) {
					silicon_ioi.doctype.ioiStockInventoryGeneration.stock_inventory_generation_get_data();
				}
			}

			document.getElementById('html_stock_inventory_generation_inventory_mode').onchange = fct_change_inventory_mode;

			let fct_stored_qty_mode = function() {

				if (!silicon_ioi.doctype.ioiStockInventoryGeneration.is_loading) {
					silicon_ioi.doctype.ioiStockInventoryGeneration.stock_inventory_generation_get_data();
				}
			}

			document.getElementById('html_stock_inventory_generation_stored_qty_mode').onchange = fct_stored_qty_mode;


			let fct_locked_checked = function() {

				if (!silicon_ioi.doctype.ioiStockInventoryGeneration.is_loading) {
					silicon_ioi.doctype.ioiStockInventoryGeneration.stock_inventory_generation_get_data();
				}
			}

			document.getElementById('html_stock_inventory_generation_batch_sn_locked').onclick = fct_locked_checked;


			let fct_withqty_checked = function() {

				if (!silicon_ioi.doctype.ioiStockInventoryGeneration.is_loading) {
					silicon_ioi.doctype.ioiStockInventoryGeneration.stock_inventory_generation_get_data();
				}
			}

			document.getElementById('html_stock_inventory_generation_with_qty').onclick = fct_withqty_checked;




			let fct_change_warehouse = function() {
				document.getElementById('html_stock_inventory_generation_warehouse_location_id').value = '';

				if (!silicon_ioi.doctype.ioiStockInventoryGeneration.is_loading) {
					silicon_ioi.doctype.ioiStockInventoryGeneration.stock_inventory_generation_get_data();
				}
			}

			document.getElementById('html_stock_inventory_generation_warehouse_id').onchange = fct_change_warehouse;

			let fct_change_location = function() {

				if ((!document.getElementById('html_stock_inventory_generation_warehouse_id').value) ||
					((document.getElementById('html_stock_inventory_generation_warehouse_id').value) && (document.getElementById('html_stock_inventory_generation_warehouse_id').value.trim() == ''))) {

					frappe.msgprint({title: __("Message"), message: __("Select the warehouse before"), indicator: "red"});
					return false;
				}

				let title = __("Select a location");
				let form_width_pixel = '710px';
				let table = "ioi Warehouse Location";
				let fields = 'name, description';
				let fields_len = '250, 380';
				let fields_desc = __('Identification') + ', ' + __('Description');
				let where = "site_id = '" + silicon_ioi.doctype.ioiStockInventoryGeneration.current_site.replaceAll("'", "''")  + "' ";
				where += "and warehouse_id = '" + document.getElementById('html_stock_inventory_generation_warehouse_id').value.replaceAll("'", "''") + "' "
				where += "and enabled = 1 "

				where += "and exists ("
				where += "				select b.name "
				where += "				from `tabioi Warehouse Stock Detail` b "
				where += "				left join `tabioi Batch SN` c on c.name = b.batch_sn_id "
				where += "				where b.site_id = `tabioi Warehouse Location`.site_id and b.warehouse_id = `tabioi Warehouse Location`.warehouse_id "
				where += "				and coalesce(b.location_id, '') = `tabioi Warehouse Location`.name "

				if ((document.getElementById('html_stock_inventory_generation_inventory_mode').value == 0) || (document.getElementById('html_stock_inventory_generation_inventory_mode').value == 1)) {
					where += "			and coalesce(b.batch_sn_id, '') = '' "
				}else if ((document.getElementById('html_stock_inventory_generation_inventory_mode').value == 2) || (document.getElementById('html_stock_inventory_generation_inventory_mode').value == 3)) {
					where += "			and c.name is not null and coalesce(c.mode, 0) = 0 "
				}else if ((document.getElementById('html_stock_inventory_generation_inventory_mode').value == 4) || (document.getElementById('html_stock_inventory_generation_inventory_mode').value == 5)) {
					where += "			and c.name is not null and coalesce(c.mode, 0) = 1 "
				}


				if ((document.getElementById('html_stock_inventory_generation_item_id').value) &&
					(document.getElementById('html_stock_inventory_generation_item_id').value.trim() != '')) {

					where += "			and upper(coalesce(b.item_id, '')) = '" + document.getElementById('html_stock_inventory_generation_item_id').value.toUpperCase().replaceAll("'", "''") + "' "
				}

				if ((document.getElementById('html_stock_inventory_generation_batch_sn_id').value) &&
					(document.getElementById('html_stock_inventory_generation_batch_sn_id').value.trim() != '')) {

					where += "			and upper(coalesce(b.batch_sn_id, '')) = '" + document.getElementById('html_stock_inventory_generation_batch_sn_id').value.toUpperCase().replaceAll("'", "''") + "' "
				}

				where += "				limit 1 ) "




				let order_by = 'name asc';

				let fct_callback = function (return_value) {

					document.getElementById("html_stock_inventory_generation_warehouse_location_id").value = return_value;
					document.getElementById('html_stock_inventory_generation_warehouse_location_id').focus();
					silicon_ioi.doctype.ioiStockInventoryGeneration.stock_inventory_generation_get_data();
				}

				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

			}

			let fct_key_up = function(event) {

				if (event.keyCode == 13) {

					if (!silicon_ioi.doctype.ioiStockInventoryGeneration.is_loading) {
						silicon_ioi.doctype.ioiStockInventoryGeneration.stock_inventory_generation_get_data();
					}

					return false;
				}
			}


			document.getElementById('html_stock_inventory_generation_warehouse_location_button').onclick = fct_change_location;
			document.getElementById('html_stock_inventory_generation_warehouse_location_id').onkeydown = fct_key_up;


			let fct_change_item = function() {

				let title = __("Select an item");
				let form_width_pixel = '790px';
				let table = "ioi Item";
				let fields = 'name, description, unit_id';
				let fields_len = '250, 380, 80';
				let fields_desc = __('Identification') + ', ' + __('Description') + ', ' + __('Unit');
				let where = "coalesce(ioistatus, 0) = 2 "

				if ((document.getElementById('html_stock_inventory_generation_inventory_mode').value == 0) || (document.getElementById('html_stock_inventory_generation_inventory_mode').value == 1)) {
					where += "			and coalesce(mode, 0) in (0, 1) "
				}else if ((document.getElementById('html_stock_inventory_generation_inventory_mode').value == 2) || (document.getElementById('html_stock_inventory_generation_inventory_mode').value == 3)) {
					where += "			and coalesce(mode, 0) = 3 "
				}else if ((document.getElementById('html_stock_inventory_generation_inventory_mode').value == 4) || (document.getElementById('html_stock_inventory_generation_inventory_mode').value == 5)) {
					where += "			and coalesce(mode, 0) = 4 "
				}

				where += "and exists ("
				where += "				select b.name "
				where += "				from `tabioi Warehouse Stock Detail` b "
				where += "				left join `tabioi Batch SN` c on c.name = b.batch_sn_id "
				where += "				where b.site_id = '" + silicon_ioi.doctype.ioiStockInventoryGeneration.current_site.replaceAll("'", "''") + "' "

				if ((!document.getElementById('html_stock_inventory_generation_warehouse_id').value) ||
					((document.getElementById('html_stock_inventory_generation_warehouse_id').value) && (document.getElementById('html_stock_inventory_generation_warehouse_id').value.trim() == ''))) {
					where += "				and b.warehouse_id = '" + document.getElementById('html_stock_inventory_generation_warehouse_id').value.replaceAll("'", "''") + "' "
				}

				if ((document.getElementById('html_stock_inventory_generation_warehouse_location_id').value) &&
					(document.getElementById('html_stock_inventory_generation_warehouse_location_id').value.trim() != '')) {

					where += "			and upper(coalesce(b.location_id, '')) = '" + document.getElementById('html_stock_inventory_generation_warehouse_location_id').value.toUpperCase().replaceAll("'", "''") + "' "
				}

				where += "				and b.item_id = `tabioi Item`.name "

				if ((document.getElementById('html_stock_inventory_generation_inventory_mode').value == 0) || (document.getElementById('html_stock_inventory_generation_inventory_mode').value == 1)) {
					where += "			and coalesce(b.batch_sn_id, '') = '' "
				}else if ((document.getElementById('html_stock_inventory_generation_inventory_mode').value == 2) || (document.getElementById('html_stock_inventory_generation_inventory_mode').value == 3)) {
					where += "			and c.name is not null and coalesce(c.mode, 0) = 0 "
				}else if ((document.getElementById('html_stock_inventory_generation_inventory_mode').value == 4) || (document.getElementById('html_stock_inventory_generation_inventory_mode').value == 5)) {
					where += "			and c.name is not null and coalesce(c.mode, 0) = 1 "
				}
				where += "				limit 1 ) "


				let order_by = 'name asc';

				let fct_callback = function (return_value) {

					document.getElementById("html_stock_inventory_generation_item_id").value = return_value;
					document.getElementById('html_stock_inventory_generation_item_id').focus();


					let old_value = ''

					if (document.getElementById('html_stock_inventory_generation_warehouse_id').options.length > 0) {
						old_value = document.getElementById('html_stock_inventory_generation_warehouse_id').value;
					}

					if (!whs_readonly) {

						for (var i = document.getElementById('html_stock_inventory_generation_warehouse_id').options.length-1; i >= 0; i--) {
							document.getElementById('html_stock_inventory_generation_warehouse_id').remove(i)
						}


						let method = silicon_ioi.doctype.ioiStockInventoryGeneration.path_stock_inventory + '.ioi_stock_inventory_generation_get_warehouses';

						frappe.call({
							method: method,
							args: {
								"inventory_mode": document.getElementById('html_stock_inventory_generation_inventory_mode').value,
								"item_id": document.getElementById("html_stock_inventory_generation_item_id").value
							},
							async: false,
							callback: function(r)	{

								if (r.message.length > 0) {

									for (var i = 0; i < r.message.length; i++) {

										let option = document.createElement("option");
										option.value = r.message[i].name
										option.text = r.message[i].name

										if (old_value != '') {
											if (old_value == r.message[i].name) {
												option.selected = true;
											}
										}else{
											if (i == 0) {
												option.selected = true;
											}
										}

										document.getElementById('html_stock_inventory_generation_warehouse_id').add(option);
									}
								}

								silicon_ioi.doctype.ioiStockInventoryGeneration.stock_inventory_generation_get_data();
							}
						});
					}



				}

				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

			}


			document.getElementById('html_stock_inventory_generation_item_button').onclick = fct_change_item;
			document.getElementById('html_stock_inventory_generation_item_id').onkeydown = fct_key_up;


			let fct_change_batchsn = function() {

				let title = __("Select a batch/sn");
				let form_width_pixel = '1080px';
				let table = "ioi Batch SN";
				let fields = 'name, code_ref, origin, item_id';
				let fields_len = '250, 250, 250, 250';
				let fields_desc = __('Identification') + ', ' + __('Code ref') + ', ' + __('Origin') + ', ' + __('Item');
				let where = "coalesce(enabled, 0) = 1 "


				if ((document.getElementById('html_stock_inventory_generation_inventory_mode').value == 2) || (document.getElementById('html_stock_inventory_generation_inventory_mode').value == 3)) {
					where += "			and coalesce(mode, 0) = 0 "
				}else if ((document.getElementById('html_stock_inventory_generation_inventory_mode').value == 4) || (document.getElementById('html_stock_inventory_generation_inventory_mode').value == 5)) {
					where += "			and coalesce(mode, 0) = 1 "
				}

				where += "and exists ("
				where += "				select b.name "
				where += "				from `tabioi Warehouse Stock Detail` b "
				where += "				left join `tabioi Batch SN` c on c.name = b.batch_sn_id "
				where += "				where b.site_id = '" + silicon_ioi.doctype.ioiStockInventoryGeneration.current_site.replaceAll("'", "''") + "' "

				if ((!document.getElementById('html_stock_inventory_generation_warehouse_id').value) ||
					((document.getElementById('html_stock_inventory_generation_warehouse_id').value) && (document.getElementById('html_stock_inventory_generation_warehouse_id').value.trim() == ''))) {
					where += "				and b.warehouse_id = '" + document.getElementById('html_stock_inventory_generation_warehouse_id').value.replaceAll("'", "''") + "' "
				}

				if ((document.getElementById('html_stock_inventory_generation_warehouse_location_id').value) &&
					(document.getElementById('html_stock_inventory_generation_warehouse_location_id').value.trim() != '')) {

					where += "			and upper(coalesce(b.location_id, '')) = '" + document.getElementById('html_stock_inventory_generation_warehouse_location_id').value.toUpperCase().replaceAll("'", "''") + "' "
				}

				if ((document.getElementById('html_stock_inventory_generation_item_id').value) &&
					(document.getElementById('html_stock_inventory_generation_item_id').value.trim() != '')) {


					where += "				and upper(coalesce(b.item_id, '')) = '" + document.getElementById('html_stock_inventory_generation_item_id').value.toUpperCase().replaceAll("'", "''") + "' "
				}

				where += "					and b.batch_sn_id = `tabioi Batch SN`.name "

				where += "				limit 1 ) "


				let order_by = 'name asc';

				let fct_callback = function (return_value) {

					document.getElementById("html_stock_inventory_generation_batch_sn_id").value = return_value;
					document.getElementById('html_stock_inventory_generation_batch_sn_id').focus();


					let old_value = ''

					if (document.getElementById('html_stock_inventory_generation_warehouse_id').options.length > 0) {
						old_value = document.getElementById('html_stock_inventory_generation_warehouse_id').value;
					}

					if (!whs_readonly) {

						for (var i = document.getElementById('html_stock_inventory_generation_warehouse_id').options.length-1; i >= 0; i--) {
							document.getElementById('html_stock_inventory_generation_warehouse_id').remove(i)
						}


						let method = silicon_ioi.doctype.ioiStockInventoryGeneration.path_stock_inventory + '.ioi_stock_inventory_generation_get_warehouses';

						frappe.call({
							method: method,
							args: {
								"inventory_mode": document.getElementById('html_stock_inventory_generation_inventory_mode').value,
								"item_id": document.getElementById("html_stock_inventory_generation_item_id").value,
								"batch_sn_id": document.getElementById("html_stock_inventory_generation_batch_sn_id").value
							},
							async: false,
							callback: function(r)	{

								if (r.message.length > 0) {

									for (var i = 0; i < r.message.length; i++) {

										let option = document.createElement("option");
										option.value = r.message[i].name
										option.text = r.message[i].name

										if (old_value != '') {
											if (old_value == r.message[i].name) {
												option.selected = true;
											}
										}else{
											if (i == 0) {
												option.selected = true;
											}
										}

										document.getElementById('html_stock_inventory_generation_warehouse_id').add(option);
									}
								}

								silicon_ioi.doctype.ioiStockInventoryGeneration.stock_inventory_generation_get_data();
							}
						});
					}

				}

				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

			}


			document.getElementById('html_stock_inventory_generation_batch_sn_button').onclick = fct_change_batchsn;
			document.getElementById('html_stock_inventory_generation_batch_sn_id').onkeydown = fct_key_up;

			let fct_refresh_data = function() {
				silicon_ioi.doctype.ioiStockInventoryGeneration.stock_inventory_generation_get_data();
			}


			document.getElementById('html_stock_inventory_generation_inventory_days').onkeyup = fct_key_up;

			document.getElementById('html_stock_inventory_generation_no_inventory').onclick = fct_refresh_data;

			document.getElementById('html_stock_inventory_generation_refresh').onclick = fct_refresh_data;


			document.getElementById('html_stock_inventory_generation_search_term').onkeyup = fct_key_up;

			let fct_choice_click = function() {

				if ((document.getElementById('html_stock_inventory_generation_search_term').value) && (document.getElementById('html_stock_inventory_generation_search_term').value.trim() != '')) {
					if (!silicon_ioi.doctype.ioiStockInventoryGeneration.is_loading) {
						silicon_ioi.doctype.ioiStockInventoryGeneration.stock_inventory_generation_get_data();
					}
				}
			}


			document.getElementById('html_stock_inventory_generation_in_description').onclick = fct_choice_click;
			document.getElementById('html_stock_inventory_generation_in_budget').onclick = fct_choice_click;
			document.getElementById('html_stock_inventory_generation_in_category').onclick = fct_choice_click;
			document.getElementById('html_stock_inventory_generation_in_classification').onclick = fct_choice_click;
			document.getElementById('html_stock_inventory_generation_in_family').onclick = fct_choice_click;
			document.getElementById('html_stock_inventory_generation_in_type').onclick = fct_choice_click;
			document.getElementById('html_stock_inventory_generation_in_group').onclick = fct_choice_click;
			document.getElementById('html_stock_inventory_generation_in_subgroup').onclick = fct_choice_click;


			let fct_click_all = function() {

				if (silicon_ioi.doctype.ioiStockInventoryGeneration.ioiTable) {

					let data = silicon_ioi.doctype.ioiStockInventoryGeneration.ioiTable.getData();

					if (data) {

						if (data.length > 0) {

							for (var i = 0; i < data.length; i++) {
								data[i]["checked"] = 1;
							}

							silicon_ioi.doctype.ioiStockInventoryGeneration.ioiTable.setData(data);
						}
					}
				}
			}

			document.getElementById('html_stock_inventory_generation_button_all').onclick = fct_click_all;



			let fct_click_none = function() {

				if (silicon_ioi.doctype.ioiStockInventoryGeneration.ioiTable) {

					let data = silicon_ioi.doctype.ioiStockInventoryGeneration.ioiTable.getData();

					if (data) {

						if (data.length > 0) {

							for (var i = 0; i < data.length; i++) {
								data[i]["checked"] = 0;
							}

							silicon_ioi.doctype.ioiStockInventoryGeneration.ioiTable.setData(data);
						}
					}
				}

			}

			document.getElementById('html_stock_inventory_generation_button_none').onclick = fct_click_none;



			if (document.getElementById('html_stock_inventory_generation_generate_tp').value == 0)	{

				document.getElementById('html_stock_inventory_generation_inventory_label').style.display = 'none';
				document.getElementById('html_stock_inventory_generation_inventory_id').style.display = 'none';
				document.getElementById('html_stock_inventory_generation_inventory_button').style.display = 'none';

				document.getElementById('html_stock_inventory_generation_journal_label').style.display = 'block';
				document.getElementById('html_stock_inventory_generation_journal_id').style.display = 'block';

				document.getElementById('html_stock_inventory_generation_inventory_id').style.zIndex = 0;
				document.getElementById('html_stock_inventory_generation_inventory_button').style.zIndex = 0;
				document.getElementById('html_stock_inventory_generation_journal_id').style.zIndex = 10;

			}else {

				document.getElementById('html_stock_inventory_generation_journal_label').style.display = 'none';
				document.getElementById('html_stock_inventory_generation_journal_id').style.display = 'none';

				document.getElementById('html_stock_inventory_generation_inventory_label').style.display = 'block';
				document.getElementById('html_stock_inventory_generation_inventory_id').style.display = 'block';
				document.getElementById('html_stock_inventory_generation_inventory_button').style.display = 'block';

				document.getElementById('html_stock_inventory_generation_journal_id').style.zIndex = 0;
				document.getElementById('html_stock_inventory_generation_inventory_id').style.zIndex = 10;
				document.getElementById('html_stock_inventory_generation_inventory_button').style.zIndex = 10;

			}


			let fct_change_action = function() {

				if (document.getElementById('html_stock_inventory_generation_generate_tp').value == 0) {

					document.getElementById('html_stock_inventory_generation_inventory_label').style.display = 'none';
					document.getElementById('html_stock_inventory_generation_inventory_id').style.display = 'none';
					document.getElementById('html_stock_inventory_generation_inventory_button').style.display = 'none';


					document.getElementById('html_stock_inventory_generation_journal_label').style.display = 'block';
					document.getElementById('html_stock_inventory_generation_journal_id').style.display = 'block';

					document.getElementById('html_stock_inventory_generation_inventory_id').style.zIndex = 0;
					document.getElementById('html_stock_inventory_generation_inventory_button').style.zIndex = 0;
					document.getElementById('html_stock_inventory_generation_journal_id').style.zIndex = 10;

					if ((document.getElementById('html_stock_inventory_generation_journal_id').value) && (document.getElementById('html_stock_inventory_generation_journal_id').value != '')) {

						let method = silicon_ioi.doctype.ioiStockInventoryGeneration.path_stock_inventory + '.ioi_stock_inventory_get_inventory_days';

						frappe.call({
							method: method,
							args: {
								"journal_id": document.getElementById('html_stock_inventory_generation_journal_id').value
							},
							async: false,
							callback: function(r)	{
								document.getElementById('html_stock_inventory_generation_inventory_days').value = r.message;
							}
						});
					}

				}else {

					document.getElementById('html_stock_inventory_generation_journal_label').style.display = 'none';
					document.getElementById('html_stock_inventory_generation_journal_id').style.display = 'none';

					document.getElementById('html_stock_inventory_generation_inventory_label').style.display = 'block';
					document.getElementById('html_stock_inventory_generation_inventory_id').style.display = 'block';
					document.getElementById('html_stock_inventory_generation_inventory_button').style.display = 'block';

					document.getElementById('html_stock_inventory_generation_journal_id').style.zIndex = 0;
					document.getElementById('html_stock_inventory_generation_inventory_id').style.zIndex = 10;
					document.getElementById('html_stock_inventory_generation_inventory_button').style.zIndex = 10;

				}



			}

			document.getElementById('html_stock_inventory_generation_generate_tp').onchange = fct_change_action;


			let fct_select_inventory = function() {

				let title = __("Select a Stock Inventory");
				let form_width_pixel = '910px';
				let table = "ioi Stock Inventory";
				let fields = 'name, journal_id, document_date';
				let fields_len = '250, 200, 380';
				let fields_desc = __('Identification') + ', ' + __("Journal") + ', ' + __('Document date');
				let where = "coalesce(ioistatus, 0) = 0 and site_id = '" + silicon_ioi.doctype.ioiStockInventoryGeneration.current_site.replaceAll("'", "''")  + "' ";
				where += "and coalesce(inventory_mode, 0) = " + document.getElementById('html_stock_inventory_generation_inventory_mode').value + " "
				where += "and coalesce(warehouse_id, '') = '" + document.getElementById('html_stock_inventory_generation_warehouse_id').value.replaceAll("'", "''") + "' "

				let order_by = 'document_date desc';

				let fct_callback = function (return_value) {

					document.getElementById("html_stock_inventory_generation_inventory_id").value = return_value;
					document.getElementById('html_stock_inventory_generation_inventory_id').focus();
				}

				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

			}



			document.getElementById('html_stock_inventory_generation_inventory_button').onclick = fct_select_inventory;
			document.getElementById('html_stock_inventory_generation_inventory_id').onkeydown = fct_key_up;


			if (silicon_ioi.doctype.ioiStockInventoryGeneration.inventory_mode != -1) {
				document.getElementById('html_stock_inventory_generation_inventory_mode').value = parseInt(silicon_ioi.doctype.ioiStockInventoryGeneration.inventory_mode);
				fct_change_inventory_mode(document.getElementById('html_stock_inventory_generation_inventory_mode'));
			}

			if (silicon_ioi.doctype.ioiStockInventoryGeneration.inventory_id != '') {

				document.getElementById('html_stock_inventory_generation_generate_tp').value = 1;
				fct_change_action(document.getElementById('html_stock_inventory_generation_generate_tp'));

				document.getElementById('html_stock_inventory_generation_inventory_id').value = silicon_ioi.doctype.ioiStockInventoryGeneration.inventory_id;
			}


			if (silicon_ioi.doctype.ioiStockInventoryGeneration.whs_id != '') {

				document.getElementById('html_stock_inventory_generation_warehouse_id').value = silicon_ioi.doctype.ioiStockInventoryGeneration.whs_id;
				fct_change_warehouse(document.getElementById('html_stock_inventory_generation_warehouse_id'));

			}


			if (silicon_ioi.doctype.ioiStockInventoryGeneration.loc_id != '') {
				document.getElementById('html_stock_inventory_generation_warehouse_location_id').value = silicon_ioi.doctype.ioiStockInventoryGeneration.loc_id;
			}

			if (silicon_ioi.doctype.ioiStockInventoryGeneration.item_id != '') {
				document.getElementById('html_stock_inventory_generation_item_id').value = silicon_ioi.doctype.ioiStockInventoryGeneration.item_id;
			}

			if (silicon_ioi.doctype.ioiStockInventoryGeneration.batch_sn_id != '') {
				document.getElementById('html_stock_inventory_generation_batch_sn_id').value = silicon_ioi.doctype.ioiStockInventoryGeneration.batch_sn_id;
			}

			silicon_ioi.doctype.ioiStockInventoryGeneration.is_loading = false;

			let fct_journal_change = function() {
				silicon_ioi.doctype.ioiStockInventoryGeneration.journal_id = document.getElementById('html_stock_inventory_generation_journal_id').value;


				let method = silicon_ioi.doctype.ioiStockInventoryGeneration.path_stock_inventory + '.ioi_stock_inventory_get_inventory_days';

				frappe.call({
					method: method,
					args: {
						"journal_id": silicon_ioi.doctype.ioiStockInventoryGeneration.journal_id
					},
					async: false,
					callback: function(r)	{
						document.getElementById('html_stock_inventory_generation_inventory_days').value = r.message;
					}
				});

			}

			document.getElementById('html_stock_inventory_generation_journal_id').onchange = fct_journal_change;


			if (silicon_ioi.doctype.ioiStockInventoryGeneration.journal_id != '')  {

				let method = silicon_ioi.doctype.ioiStockInventoryGeneration.path_stock_inventory + '.ioi_stock_inventory_get_inventory_days';

				frappe.call({
					method: method,
					args: {
						"journal_id": silicon_ioi.doctype.ioiStockInventoryGeneration.journal_id
					},
					async: false,
					callback: function(r)	{
						document.getElementById('html_stock_inventory_generation_inventory_days').value = r.message;
					}
				});
			}else{

				if ((document.getElementById('html_stock_inventory_generation_journal_id').value) && (document.getElementById('html_stock_inventory_generation_journal_id').value != '')) {

					let method = silicon_ioi.doctype.ioiStockInventoryGeneration.path_stock_inventory + '.ioi_stock_inventory_get_inventory_days';

					frappe.call({
						method: method,
						args: {
							"journal_id": document.getElementById('html_stock_inventory_generation_journal_id').value
						},
						async: false,
						callback: function(r)	{
							document.getElementById('html_stock_inventory_generation_inventory_days').value = r.message;
						}
					});
				}
			}

			let fct_click_not_prep = function() {
				silicon_ioi.doctype.ioiStockInventoryGeneration.stock_inventory_generation_get_data();
			}

			document.getElementById('html_stock_inventory_generation_not_prep_tohandle').onclick = fct_click_not_prep;

			document.getElementById('html_stock_inventory_generation_no_record').onkeyup = fct_key_up;


			if (document.getElementById('html_stock_inventory_generation_item_id').value != '') {
				silicon_ioi.doctype.ioiStockInventoryGeneration.stock_inventory_generation_get_data();
			}
		});

	}

	static stock_inventory_generation_get_data() {

		silicon_ioi.doctype.ioiStockInventoryGeneration.data = [];

		let cb_locked = 0

		if (document.getElementById('html_stock_inventory_generation_batch_sn_locked').checked) {
			cb_locked = 1;
		}

		let cb_with_qty = 0;

		if (document.getElementById('html_stock_inventory_generation_with_qty').checked) {
			cb_with_qty = 1;
		}

		let inventory_days = 0;

		if (document.getElementById('html_stock_inventory_generation_no_inventory').checked) {

			if ((document.getElementById('html_stock_inventory_generation_inventory_days').value) && (document.getElementById('html_stock_inventory_generation_inventory_days').value != '')) {
				inventory_days = parseInt(document.getElementById('html_stock_inventory_generation_inventory_days').value)
			}
		}

		if (parseInt(inventory_days) < 0) {
			frappe.msgprint({title: __("Message"), message: __("Inventory days has to be greater than or equal to zero"), indicator: "red"});
			return false;
		}

		let not_in_prep_tohandle = 0

		if (document.getElementById('html_stock_inventory_generation_not_prep_tohandle').checked) {
			not_in_prep_tohandle = 1;
		}

		let topn = 0;

		if ((document.getElementById('html_stock_inventory_generation_no_record').value) && (document.getElementById('html_stock_inventory_generation_no_record').value != '')) {
			topn = parseInt(document.getElementById('html_stock_inventory_generation_no_record').value)
		}

		if (parseInt(topn) < 0) {
			topn = 0;
		}

		let item_description = 0;

		if (document.getElementById('html_stock_inventory_generation_in_description').checked) {
			item_description = 1;
		}

		let budget_id = 0;

		if (document.getElementById('html_stock_inventory_generation_in_budget').checked) {
			budget_id = 1;
		}

		let category_id = 0;

		if (document.getElementById('html_stock_inventory_generation_in_category').checked) {
			category_id = 1;
		}


		let classification_id = 0;

		if (document.getElementById('html_stock_inventory_generation_in_classification').checked) {
			classification_id = 1;
		}

		let family_id = 0;

		if (document.getElementById('html_stock_inventory_generation_in_family').checked) {
			family_id = 1;
		}

		let type_id = 0;

		if (document.getElementById('html_stock_inventory_generation_in_type').checked) {
			type_id = 1;
		}

		let group_id = 0;

		if (document.getElementById('html_stock_inventory_generation_in_group').checked) {
			group_id = 1;
		}

		let sub_group_id = 0;

		if (document.getElementById('html_stock_inventory_generation_in_subgroup').checked) {
			sub_group_id = 1;
		}

		let method = silicon_ioi.doctype.ioiStockInventoryGeneration.path_stock_inventory + '.ioi_stock_inventory_generation_get_data';

		frappe.call({
			method: method,
			args: {
				"inventory_mode": document.getElementById('html_stock_inventory_generation_inventory_mode').value,
				"stored_qty_mode": document.getElementById('html_stock_inventory_generation_stored_qty_mode').value ,
				"batch_sn_locked": cb_locked,
				"warehouse_id": document.getElementById("html_stock_inventory_generation_warehouse_id").value.toUpperCase(),
				"warehouse_location_id": document.getElementById("html_stock_inventory_generation_warehouse_location_id").value.toUpperCase(),
				"item_id": document.getElementById("html_stock_inventory_generation_item_id").value.toUpperCase(),
				"batch_sn_id": document.getElementById("html_stock_inventory_generation_batch_sn_id").value.toUpperCase(),
				"with_qty": cb_with_qty,
				"inventory_days": inventory_days,
				"not_in_prep_tohandle": not_in_prep_tohandle,
				"topn": topn,
				"search_term": document.getElementById('html_stock_inventory_generation_search_term').value.toUpperCase(),
				"item_description": item_description,
				"budget_id": budget_id,
				"category_id": category_id,
				"classification_id": classification_id,
				"family_id": family_id,
				"type_id": type_id,
				"group_id": group_id,
				"sub_group_id": sub_group_id
			},
			async: false,
			callback: function(r)	{
				silicon_ioi.doctype.ioiStockInventoryGeneration.data = r.message;
			}
		});


		let columns = [];

		columns[columns.length] = { title: "", field: 'checked', width: 80, hozAlign:"center", editor: true, formatter:"tickCross", headerSort:false, frozen:true};

		if ((document.getElementById('html_stock_inventory_generation_inventory_mode').value == 0) ||
			(document.getElementById('html_stock_inventory_generation_inventory_mode').value == 2) ||
			(document.getElementById('html_stock_inventory_generation_inventory_mode').value == 4)) {
			columns[columns.length] = { title: __("Warehouse"), field: "warehouse_id", minWidth: 100, visible: true, headerSort:false };
			columns[columns.length] = { title: __("Location"), field: "location_id", minWidth: 100, visible: false, headerSort:false };
		}else{
			columns[columns.length] = { title: __("Warehouse"), field: "warehouse_id", minWidth: 100, visible: false, headerSort:false };
			columns[columns.length] = { title: __("Location"), field: "location_id", minWidth: 100, visible: true, headerSort:false };
		}

		columns[columns.length] = { title: __("Item"), field: "item_id", minWidth: 100, visible: true, headerSort:false };
		columns[columns.length] = { title: __("Batch SN"), field: "batch_sn_id", minWidth: 100, visible: true, headerSort:false };
		columns[columns.length] = { title: __("Stored qty mode"), field: "stored_qty_mode_description", minWidth: 100, visible: true, headerSort:false };
		columns[columns.length] = { title: __("Stored qty mode id"), field: "stored_qty_mode", minWidth: 100, visible: false, headerSort:false };


		if (cb_locked == 0) {
			columns[columns.length] = { title: __("Qty"), field: "qty", minWidth: 10, width:100, visible: true, hozAlign:"right", headerSort:false};
		}else{
			columns[columns.length] = { title: __("Locked Qty"), field: "qty", minWidth: 10, width:100, visible: true, hozAlign:"right", headerSort:false};
		}
		columns[columns.length] = { title: __("Unit"), field: "unit_id", minWidth: 100, visible: true, headerSort:false };

		columns[columns.length] = { title: __("Item description"), field: "item_description", minWidth: 100, visible: true, headerSort:false };

		columns[columns.length] = { title: __("Item Budget"), field: "budget_id", minWidth: 100, visible: true, headerSort:false };
		columns[columns.length] = { title: __("Item Budget description"), field: "budget_description", minWidth: 100, visible: true, headerSort:false };

		columns[columns.length] = { title: __("Category"), field: "category_id", minWidth: 100, visible: true, headerSort:false };
		columns[columns.length] = { title: __("Category description"), field: "category_description", minWidth: 100, visible: true, headerSort:false };

		columns[columns.length] = { title: __("Classification"), field: "classification_id", minWidth: 100, visible: true, headerSort:false };
		columns[columns.length] = { title: __("Classification description"), field: "classification_description", minWidth: 100, visible: true, headerSort:false };

		columns[columns.length] = { title: __("Family"), field: "family_id", minWidth: 100, visible: true, headerSort:false };
		columns[columns.length] = { title: __("Family description"), field: "family_description", minWidth: 100, visible: true, headerSort:false };

		columns[columns.length] = { title: __("Type"), field: "type_id", minWidth: 100, visible: true, headerSort:false };
		columns[columns.length] = { title: __("Type description"), field: "type_description", minWidth: 100, visible: true, headerSort:false };


		columns[columns.length] = { title: __("Group"), field: "group_id", minWidth: 100, visible: true, headerSort:false };
		columns[columns.length] = { title: __("Group description"), field: "group_description", minWidth: 100, visible: true, headerSort:false };

		columns[columns.length] = { title: __("Subgroup"), field: "subgroup_id", minWidth: 100, visible: true, headerSort:false };
		columns[columns.length] = { title: __("Subgroup description"), field: "subgroup_description", minWidth: 100, visible: true, headerSort:false };



		silicon_ioi.doctype.ioiStockInventoryGeneration.ioiTable = new ioi.Tabulator('#html_stock_inventory_generation_table_container', {
			columns: columns,
			data:silicon_ioi.doctype.ioiStockInventoryGeneration.data,
			maxHeight: 400,
			rowHeight: null,
			selectableRows: 1,
			showProfiles: false,
			movableColumns: true,
			resizableColumns: true,
			autoRedraw: true,
		});

	}

	static remove_component(fieldname)
	{
		if (document.getElementById(fieldname)) {
			document.getElementById(fieldname).remove();
		}
	}

	static sleep_static(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}

}

silicon_ioi.doctype.ioiStockInventoryGeneration = ioiStockInventoryGeneration;
