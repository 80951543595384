frappe.provide('silicon-ioi');

class ALink extends frappe.ui.form.ControlLink {

	// VVR:
	// warning: changes made here must also be ported to "frappe.ui.form.ControlDynamicLink" in "ioi_dynamic_link.js"

	make_input() {
		var me = this;
		$(`<div class="link-field ui-front" style="position: relative;">
			<input type="text" class="input-with-feedback form-control">
			<span class="link-btn">
				<a class="btn-open no-decoration" title="${__("Open Link")}">
					${frappe.utils.icon("arrow-right", "xs")}
				</a>
			</span>
		</div>`).prependTo(this.input_area);
		this.$input_area = $(this.input_area);
		this.$input = this.$input_area.find("input");
		this.$link = this.$input_area.find(".link-btn");
		this.$link_open = this.$link.find(".btn-open");
		this.set_input_attributes();
		this.$input.on("focus", function () {
			setTimeout(function () {
				if (me.$input.val() && me.get_options()) {
					let doctype = me.get_options();
					let name = me.get_input_value();

					// VVR:
					if (!['ioi Account Transaction', 'ioi Customer Transaction', 'ioi Supplier Transaction'].includes(doctype)) {

						// Frappe
						me.$link.toggle(true);
						me.$link_open.attr("href", frappe.utils.get_form_link(doctype, name));

						// VVR:
						me.$link_open.attr('tabindex', '-1');
					}

				}

				if (!me.$input.val()) {
					me.$input.val("").trigger("input");

					// hide link arrow to doctype if none is set
					me.$link.toggle(false);
				}
			}, 500);
		});
		this.$input.on("blur", function () {
			// if this disappears immediately, the user's click
			// does not register, hence timeout
			setTimeout(function () {
				me.$link.toggle(false);
			}, 500);
		});
		this.$input.attr("data-target", this.df.options);
		this.input = this.$input.get(0);
		this.has_input = true;
		this.translate_values = true;
		this.setup_buttons();
		this.setup_awesomeplete();
		this.bind_change_event();

		if (this.df.max_items){
			this.awesomplete.maxItems = this.df.max_items;
		}
	}

	setup_awesomeplete() {
		let me = this;

		this.$input.cache = {};

		this.awesomplete = new Awesomplete(me.input, {
			minChars: 0,
			maxItems: 99,
			autoFirst: true,
			list: [],
			replace: function (item) {
				// Override Awesomeplete replace function as it is used to set the input value
				// https://github.com/LeaVerou/awesomplete/issues/17104#issuecomment-359185403
				this.input.value = me.get_translated(item.label || item.value);
			},
			data: function (item) {
				return {
					label: me.get_translated(item.label || item.value),
					value: item.value,
				};
			},
			filter: function () {
				return true;
			},
			item: function (item) {
				let d = this.get_item(item.value);
				if (!d.label) {
					d.label = d.value;
				}

				let _label = me.get_translated(d.label);
				let html = d.html || "<strong>" + _label + "</strong>";
				if (
					d.description &&
					// for title links, we want to inlude the value in the description
					// because it will not visible otherwise
					(me.is_title_link() || d.value !== d.description)
				) {
					html += '<br><span class="small">' + __(d.description) + "</span>";
				}
				return $("<li></li>")
					.data("item.autocomplete", d)
					.prop("aria-selected", "false")
					.html(`<a><p title="${_label}">${html}</p></a>`)
					.get(0);
			},
			sort: function () {
				return 0;
			},
		});

		this.custom_awesomplete_filter && this.custom_awesomplete_filter(this.awesomplete);

		this.$input.on(
			"input",
			frappe.utils.debounce(function (e) {
				var doctype = me.get_options();
				if (!doctype) return;
				if (!me.$input.cache[doctype]) {
					me.$input.cache[doctype] = {};
				}

				var term = e.target.value;

				if (me.$input.cache[doctype][term] != null) {
					// immediately show from cache
					me.awesomplete.list = me.$input.cache[doctype][term];
				}
				var args = {
					txt: term,
					doctype: doctype,
					ignore_user_permissions: me.df.ignore_user_permissions,
					reference_doctype: me.get_reference_doctype() || "",
				};

				me.set_custom_query(args);

				frappe.call({
					type: "POST",
					method: "frappe.desk.search.search_link",
					no_spinner: true,
					args: args,
					callback: function (r) {
						if (!window.Cypress && !me.$input.is(":focus")) {
							return;
						}
						r.message = me.merge_duplicates(r.message);

						// show filter description in awesomplete
						if (args.filters) {
							let filter_string = me.get_filter_description(args.filters);
							if (filter_string) {
								r.message.push({
									html: `<span class="text-muted" style="line-height: 1.5">${filter_string}</span>`,
									value: "",
									action: () => {},
								});
							}
						}

						if (!me.df.only_select) {
							if (frappe.model.can_create(doctype)) {
								// new item
								r.message.push({
									html:
										"<span class='text-primary link-option'>" +
										"<i class='fa fa-plus' style='margin-right: 5px;'></i> " +
										__("Create a new {0}", [__(me.get_options())]) +
										"</span>",
									label: __("Create a new {0}", [__(me.get_options())]),
									value: "create_new__link_option",
									action: me.new_doc,
								});
							}

							//custom link actions
							let custom__link_options =
								frappe.ui.form.ControlLink.link_options &&
								frappe.ui.form.ControlLink.link_options(me);

							if (custom__link_options) {
								r.message = r.message.concat(custom__link_options);
							}

							// advanced search
							if (locals && locals["DocType"]) {
								// not applicable in web forms

								if (me.get_options() == 'ioi Item') {
									r.message.unshift({
										html:
											"<span class='text-primary link-option'>" +
											"<i class='fa fa-search' style='margin-right: 5px;'></i> " +
											__("E-shop Search") +
											"</span>",
										label: __("E-shop Search"),
										value: "eshop_search__link_option",
										action: me.open_eshop_search,
									});

									const dt_bom_search_enabled = ["ioi Sales Quote", "ioi Purchases Price Request", "ioi Sales Order", "ioi Purchases Order", "ioi Sales Invoice", "ioi Purchases Invoice", "ioi Sales Delivery", "ioi Purchases Receipt", "ioi Stock Entry", "ioi Stock Output", "ioi Stock Transfer"]
									const filter = (element) => me.frm.doctype == element;

									if (dt_bom_search_enabled.some(filter)) {
										r.message.unshift({
											html:
												"<span class='text-primary link-option'>" +
												"<i class='fa fa-search' style='margin-right: 5px;'></i> " +
												__("BOM/Kit Search") +
												"</span>",
											label: __("BOM/Kit Search"),
											value: "bom_kit_search__link_option",
											action: me.open_bom_search,
										});
									}
								}

								r.message.unshift({
									html:
										"<span class='text-primary link-option'>" +
										"<i class='fa fa-search' style='margin-right: 5px;'></i> " +
										__("Advanced Search") +
										"</span>",
									label: __("Advanced Search"),
									value: "advanced_search__link_option",
									action: me.open_advanced_search,
								});
							}
						}
						me.$input.cache[doctype][term] = r.message;
						me.awesomplete.list = me.$input.cache[doctype][term];
						me.toggle_href(doctype);
					},
				});
			}, 500)
		);

		this.$input.on("blur", function () {
			if (me.selected) {
				me.selected = false;
				return;
			}
			let value = me.get_input_value();
			let label = me.get_label_value();
			let last_value = me.last_value || "";
			let last_label = me.label || "";

			if (value !== last_value) {
				me.parse_validate_and_set_in_model(value, null, label);
			}
		});

		this.$input.on("awesomplete-open", () => {
			this.autocomplete_open = true;

			if (!me.get_label_value()) {
				// hide link arrow to doctype if none is set
				me.$link.toggle(false);
			}
		});

		this.$input.on("awesomplete-close", (e) => {
			this.autocomplete_open = false;

			if (!me.get_label_value()) {
				// hide link arrow to doctype if none is set
				me.$link.toggle(false);
			}
		});

		this.$input.on("awesomplete-select", function (e) {
			var o = e.originalEvent;
			var item = me.awesomplete.get_item(o.text.value);

			me.autocomplete_open = false;

			// prevent selection on tab
			let TABKEY = 9;
			if (e.keyCode === TABKEY) {
				e.preventDefault();
				me.awesomplete.close();
				return false;
			}

			if (item.action) {
				item.value = "";
				item.label = "";
				item.action.apply(me);
			}

			// if remember_last_selected is checked in the doctype against the field,
			// then add this value
			// to defaults so you do not need to set it again
			// unless it is changed.
			if (me.df.remember_last_selected_value) {
				frappe.boot.user.last_selected_values[me.df.options] = item.value;
			}

			me.parse_validate_and_set_in_model(item.value, null, item.label);
		});

		this.$input.on("awesomplete-selectcomplete", function (e) {
			let o = e.originalEvent;
			if (o.text.value.indexOf("__link_option") !== -1) {
				me.$input.val("");
			}
		});
	}

	open_advanced_search() {
		let me = this



		if (this.get_options() == 'ioi Item') {

			if (this.doctype.toUpperCase() == 'IOI DOSSIER ROUTING') {

				let fields = 'a.name, a.description, a.unit_id, b.q_stock, b.q_reserved, a.manufacturer_ref';
				let fields_len = '300, 500, 125, 150, 150, 300';
				let fields_desc = 'Identification, Description, Unit, Stock qty, Reserved qty, Manufacturer Catalog';
				let where = 'ioistatus = 2 and coalesce(a.mode, 0) = 2 and exists(select z.name from `tabioi Item Unit` z where z.name = a.unit_id and coalesce(z.coefficient, 0) = 3600) ';
				let order_by = 'a.name asc';
				let parameter_with_stock = true;
				let site_id = this.frm.doc.site_id;

				let fct_callback = function (return_value) {
					let frm = me.frm
					let idx = me.doc.idx - 1

					let script_manager = frm.fields_dict['routing_detail'].grid.grid_rows[idx].frm.script_manager;
					frm.fields_dict['routing_detail'].grid.grid_rows[idx].doc["item_id"] = return_value;
					script_manager.trigger("item_id", frm.fields_dict['routing_detail'].grid.doctype, frm.fields_dict['routing_detail'].grid.grid_rows[idx].doc.name);

					frm.refresh_field('routing_detail');


				};

				silicon_ioi.ioiCommon.select_item(fields, fields_len, fields_desc, where, order_by, parameter_with_stock, site_id, fct_callback);

			}else{

				let fields = 'a.name, a.description, a.unit_id, b.q_stock, b.q_reserved, a.manufacturer_ref';
				let fields_len = '300, 500, 125, 150, 150, 300';
				let fields_desc = 'Identification, Description, Unit, Stock qty, Reserved qty, Manufacturer Catalog';
				let where = 'ioistatus = 2';
				let order_by = 'a.name asc';
				let parameter_with_stock = true;
				let site_id = this.frm.doc.site_id;

				let fct_callback = function (return_value) {
					let frm = me.frm
					let idx = me.doc.idx - 1

					if ((frm.doctype.toUpperCase() == 'IOI PRODUCTION') || (frm.doctype.toUpperCase() == 'IOI DOSSIER')) {
						let script_manager = frm.fields_dict['bom_detail'].grid.grid_rows[idx].frm.script_manager;
						frm.fields_dict['bom_detail'].grid.grid_rows[idx].doc["component_id"] = return_value;
						script_manager.trigger("component_id", frm.fields_dict['bom_detail'].grid.doctype, frm.fields_dict['bom_detail'].grid.grid_rows[idx].doc.name);

						frm.refresh_field('bom_detail');

					}else if (frm.doctype.toUpperCase() == 'IOI PARK') {

						frm.doc.item_id = return_value;
						frm.refresh_field('item_id');

					}else{
						let script_manager = frm.fields_dict['line_detail'].grid.grid_rows[idx].frm.script_manager;
						frm.fields_dict['line_detail'].grid.grid_rows[idx].doc["item_id"] = return_value;
						script_manager.trigger("item_id", frm.fields_dict['line_detail'].grid.doctype, frm.fields_dict['line_detail'].grid.grid_rows[idx].doc.name);

						frm.refresh_field('line_detail');
					}
				};

				silicon_ioi.ioiCommon.select_item(fields, fields_len, fields_desc, where, order_by, parameter_with_stock, site_id, fct_callback);
			}

		} else if (this.get_options() == 'ioi Manufacturer Catalog') {

			let fields = 'a.name, a.item_id, a.description, a.unit_id, a.item_id_live_creation, b.supplier_id, a.barcode_reference, ';
			fields += 'a.family_id, a.type_id, a.group_id, a.subgroup_id, a.shop_category_id, a.classification_id, a.category_id, a.budget_id';
			let fields_len = '300, 200, 350, 100, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200';
			let fields_desc = 'Identification, Item, Description, Unit, Item id live creation, Supplier, Barcode ref, ';
			fields_desc += 'Family, Type, Group, Subgroup, Shop Category, Classification, Category, Budget type';
			let where = 'a.enabled = 1';
			let order_by = 'a.name asc';

			let supplier = '';

			if ((this.frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') || (this.frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER')) {
				supplier = this.frm.doc.order_supplier_id;
			}else if (this.frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') {
				supplier = this.frm.doc.delivery_supplier_id;
			}else if (this.frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE') {
				supplier = this.frm.doc.invoice_supplier_id;
			}else if (this.frm.doctype.toUpperCase() == 'IOI DOSSIER') {

				let cdt = this.doctype;
				let cdn = this.docname;

				let line = locals[cdt][cdn];

				if (line) {
					supplier = cur_frm.fields_dict['bom_detail'].grid.grid_rows[line.idx - 1].doc["supplier_id"]
				}

			}

			let document_date = me.frm.doc.document_date;

			let fct_callback = function (manufacture_catalog_id, item_id) {

				let frm = me.frm
				let idx = me.doc.idx - 1

				let detail_fieldname = 'line_detail';
				let item_fieldname = 'item_id';

				if (frm.doctype.toUpperCase() == 'IOI PARK') {

					frm.doc.manufacturer_ref = manufacture_catalog_id;
					frm.refresh_field('manufacturer_ref');

				}else{

					if (frm.doctype.toUpperCase() == 'IOI DOSSIER') {
						detail_fieldname = 'bom_detail';
						item_fieldname = 'component_id';
					}

					let script_manager = frm.fields_dict[detail_fieldname].grid.grid_rows[idx].frm.script_manager;

					frm.fields_dict[detail_fieldname].grid.grid_rows[idx].doc["manufacturer_ref"] = manufacture_catalog_id;


					if ((item_id != '') && (item_id != null)){
						frm.fields_dict[detail_fieldname].grid.grid_rows[idx].doc[item_fieldname] = item_id;

					}else{
						frm.fields_dict[detail_fieldname].grid.grid_rows[idx].doc[item_fieldname] = '';
						frm.doc.item_id = '';
					}

					if ((item_id != '') && (item_id != null)){
						script_manager.trigger(item_fieldname, frm.fields_dict[detail_fieldname].grid.doctype, frm.fields_dict[detail_fieldname].grid.grid_rows[idx].doc.name);

					}else{
						script_manager.trigger("manufacturer_ref", frm.fields_dict[detail_fieldname].grid.doctype, frm.fields_dict[detail_fieldname].grid.grid_rows[idx].doc.name);
					}

					frm.refresh_field(detail_fieldname);
				}
			};

			silicon_ioi.ioiCommon.select_manufacturer_catalog(fields, fields_len, fields_desc, where, order_by, document_date, fct_callback, true, supplier);


		}else if (this.get_options() == 'ioi Park Properties Definition') {

			let title = __("Select a park property");
			let form_width_pixel = '1200px';
			let table = "ioi Park Properties Definition";
			let fields = 'name, description, family_id, value_unit, unit_id';
			let fields_len = '200, 300, 300, 150, 150';
			let fields_desc = __('Identification') + ',' + __('Description') + ',' + __('Family') + ',' + __('Value unit') + ',' + __('Unit');
			let where = 'enabled = 1 ';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {

				let cdt = me.frm.selected_doc.doctype;
				let cdn = me.frm.selected_doc.name;

				let item = locals[cdt][cdn];

				let detail_fieldname = 'properties_detail';

				let script_manager = cur_frm.fields_dict[detail_fieldname].grid.grid_rows[item.idx - 1].frm.script_manager;

				cur_frm.fields_dict[detail_fieldname].grid.grid_rows[item.idx - 1].doc["park_property_id"] = return_value;


				script_manager.trigger('park_property_id', cur_frm.fields_dict[detail_fieldname].grid.doctype, cur_frm.fields_dict[detail_fieldname].grid.grid_rows[item.idx - 1].doc.name);

				cur_frm.refresh_field(detail_fieldname);

			}


			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

		}else if (this.get_options() == 'ioi Customer') {

			if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE')  || (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') ||
				(cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE')) {

				let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email, alternate_id';
				let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400, 250';
				let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email, Alternate id';
				let where = 'ioistatus = 2';
				let order_by = 'name asc';

				let fct_callback = function (return_value) {

					let cdt = me.frm.selected_doc.doctype;
					let cdn = me.frm.selected_doc.name;

					let line = locals[cdt][cdn];
					line.customer_id = return_value;

					let script_manager = cur_frm.fields_dict['address_detail'].grid.grid_rows[line.idx - 1].frm.script_manager;

					cur_frm.fields_dict['address_detail'].grid.grid_rows[line.idx - 1].doc["customer_id"] = return_value;


					script_manager.trigger('customer_id', cur_frm.fields_dict['address_detail'].grid.doctype, cur_frm.fields_dict['address_detail'].grid.grid_rows[line.idx - 1].doc.name);


					me.frm.refresh_field('address_detail');
				}

				silicon_ioi.ioiCommon.select_customer(fields, fields_len, fields_desc, where, order_by, fct_callback);
			}
		}else if (this.get_options() == 'ioi Supplier') {

			if ((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST')  || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') ||
				(cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE')) {

				let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email, alternate_id';
				let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400, 250';
				let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email, Alternate id';
				let where = 'ioistatus = 2';
				let order_by = 'name asc';

				let fct_callback = function (return_value) {

					let cdt = me.frm.selected_doc.doctype;
					let cdn = me.frm.selected_doc.name;

					let line = locals[cdt][cdn];
					line.supplier_id = return_value;

					let script_manager = cur_frm.fields_dict['address_detail'].grid.grid_rows[line.idx - 1].frm.script_manager;

					cur_frm.fields_dict['address_detail'].grid.grid_rows[line.idx - 1].doc["supplier_id"] = return_value;


					script_manager.trigger('supplier_id', cur_frm.fields_dict['address_detail'].grid.doctype, cur_frm.fields_dict['address_detail'].grid.grid_rows[line.idx - 1].doc.name);


					me.frm.refresh_field('address_detail');
				}

				silicon_ioi.ioiCommon.select_supplier(fields, fields_len, fields_desc, where, order_by, fct_callback);
			}
		}else if (this.get_options() == 'ioi Purchases Receipt') {

			if (cur_frm.doctype.toUpperCase() == 'IOI STOCK QUALIFICATION') {

				let title = __("Select a purchase receipt");
				let form_width_pixel = '1100px';
				let table = "ioi Purchases Receipt";
				let fields = 'name, document_date, delivery_supplier_id, external_ref, total_htva, currency_id';
				let fields_len = '250, 120, 200, 250, 120, 80';
				let fields_desc = __('Identification') + ',' + __('Doc. date') + ',' + __('Supplier') + ',' + __('External ref.') + ',' + __('Total wo VAt') + ',' + __('Currency')
				let where = 'ioistatus > 0 ';
				let order_by = 'name asc';

				let fct_callback = function (return_value) {

					let cdt = me.frm.selected_doc.doctype;
					let cdn = me.frm.selected_doc.name;

					let line = locals[cdt][cdn];
					line.purchases_receipt_id = return_value;

					let script_manager = me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].frm.script_manager;
					script_manager.trigger("purchases_receipt_id", me.frm.fields_dict['line_detail'].grid.doctype, me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].doc.name);
					me.frm.refresh_field('line_detail');
					me.format_detail_form(me.frm, cdt, cdn);

				}

				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)
			}

		}else if (this.get_options() == 'ioi Stock Entry') {

			if (cur_frm.doctype.toUpperCase() == 'IOI STOCK QUALIFICATION') {

				let title = __("Select a stock entry");
				let form_width_pixel = '1200px';
				let table = "ioi Stock Entry";
				let fields = 'name, document_date, external_ref, production_id, dossier_id';
				let fields_len = '250, 120, 250, 250, 250';
				let fields_desc = __('Identification') + ',' + __('Doc. date') + ',' + __('External ref.') + ',' + __('Production') + ',' + __('Dossier');
				let where = 'ioistatus > 0 ';
				let order_by = 'name asc';

				let fct_callback = function (return_value) {

					let cdt = me.frm.selected_doc.doctype;
					let cdn = me.frm.selected_doc.name;

					let line = locals[cdt][cdn];
					line.stock_entry_id = return_value;

					let script_manager = me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].frm.script_manager;
					script_manager.trigger("stock_entry_id", me.frm.fields_dict['line_detail'].grid.doctype, me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].doc.name);
					me.frm.refresh_field('line_detail');
					me.format_detail_form(me.frm, cdt, cdn);

				}

				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)
			}

		}else {
			super.open_advanced_search()
		}
	}

	open_bom_search() {
		if (this.get_options() == 'ioi Item') {
			let me = this

			let d = new frappe.ui.Dialog({
				'title': __("Select a BOM"),
				'fields': [
					{ 'fieldname': 'html_select_bom', 'fieldtype': 'HTML' }

				],
				primary_action_label: 'Ok',
				secondary_action_label: __('Cancel'),
				primary_action: function () {

					const table = ioi.Tabulator.findTable("#ioi_select_bom_grid")[0]
					const data = table.getSelectedData();

					const populate_rows = (header_item) => {
						frappe.call('silicon_ioi.ioi_configuration.doctype.ioi_bom_release.ioi_bom_release.ioi_bom_select_get_definition_list', {
							item: data[0].item_id,
							bom_release: data[0].bom_release_id
						}).then(r => {

							if (header_item) r.message.unshift({ "component_id": header_item })

							let multiplier = parseInt(document.getElementById('select_bom_multiplier').value)
							if (multiplier < 1) multiplier = 1

							let frm = me.frm
							let idx = me.doc.idx - 1

							if (me.grid && me.grid.grid_rows.length > 1) {

								if (idx !== me.grid.grid_rows.length - 1) document.querySelector(`[data-page-route="${frm.doctype}"] [data-fieldname="line_detail"] .grid-add-row`).click()
								idx = me.grid.grid_rows.length - 1
							}
							else if (me.frm.cur_grid && me.frm.cur_grid.grid.grid_rows.length > 1) {

								if (idx !== me.frm.cur_grid.grid.grid_rows.length - 1) document.querySelector(`[data-page-route="${frm.doctype}"] [data-fieldname="line_detail"] .grid-add-row`).click()
								idx = me.frm.cur_grid.grid.grid_rows.length - 1
							}

							let script_manager = frm.fields_dict['line_detail'].grid.grid_rows[idx].frm.script_manager;

							r.message.forEach(def => {

								if (r.message[0].component_id !== def.component_id) document.querySelector(`[data-page-route="${frm.doctype}"] [data-fieldname="line_detail"] .grid-add-row`).click()

								setTimeout(() => {
									let quantity = 0

									frm.fields_dict['line_detail'].grid.grid_rows[idx].doc["item_id"] = def.component_id;

									if (def.component_id && header_item && def.component_id === header_item) quantity = multiplier

									if (def.component_id && def.stock_unit_qty != undefined && def.stock_fix_qty != undefined) quantity = multiplier * def.stock_unit_qty + def.stock_fix_qty

									let gridrow_doc = frm.fields_dict['line_detail'].grid.grid_rows[idx].doc
									const doctype = frm.doctype;

									switch (doctype) {
										case 'ioi Sales Quote':
										case 'ioi Purchases Price Request':
											gridrow_doc['quoted_qty'] = quantity;
											break;
										case 'ioi Sales Order':
										case 'ioi Purchases Order':
											gridrow_doc['ordered_qty'] = quantity;
											break;
										case 'ioi Sales Invoice':
										case 'ioi Purchases Invoice':
											gridrow_doc['invoiced_qty'] = quantity;
										case 'ioi Sales Delivery':
										case 'ioi Purchases Receipt':
										case 'ioi Stock Entry':
										case 'ioi Stock Output':
											gridrow_doc['delivered_qty'] = quantity;
											break;
										case 'ioi Stock Transfer':
											gridrow_doc['transferred_qty'] = quantity;
											break;
									}

									if (doctype.includes('ioi Sales') && def.component_id !== header_item) {
										if (data[0].sales_conditions && data[0].sales_conditions !== "") {
											gridrow_doc['sales_condition_id'] = data[0].sales_conditions
										}
									} else if (doctype.includes('ioi Purchases') && def.component_id !== header_item) {
										if (data[0].purchases_conditions && data[0].purchases_conditions !== "") {
											gridrow_doc['purchases_condition_id'] = data[0].purchases_conditions
										}
									}

									script_manager.trigger("item_id", frm.fields_dict['line_detail'].grid.doctype, frm.fields_dict['line_detail'].grid.grid_rows[idx].doc.name);
									idx++

								}, 200)
							})

							frm.refresh_field('line_detail')

							d.hide()
						})
					}

					if (document.getElementById('select_bom_keep_call_item').checked) {
						populate_rows(data[0].item_id)
					} else {
						populate_rows()
					}
				},
				secondary_action: function () {
					d.hide();
				},
				on_page_show: function () {

					let columns = [
						{ title: __('Item'), field: 'item_id' },
						{ title: __('BOM release'), width: 100, field: 'bom_release_id' },
						{ title: __('Description'), field: 'description' },
					]

					let select_bom_table = new ioi.Tabulator("#ioi_select_bom_grid", {
						maxHeight: 500,
						rowHeight: 30,
						data: [],
						layout: "fitColumns",
						selectableRows: 1,
						columnDefaults: {},
						columns: columns
					});

					select_bom_table.on("rowSelected", (row) => {
						const data = row.getData()

						document.getElementById('select_bom_keep_call_item').checked = data.keep_call_item == 1 ? true : false
						document.getElementById('select_bom_multiplier').value = data.multiplier
					});

					select_bom_table.on("headerClick", (e, column) => {
						const sorters = select_bom_table.getSorters()
						silicon_ioi.ioiCommon.select_bom_search(select_bom_table, sorters)
					});

					silicon_ioi.ioiCommon.select_bom_search(select_bom_table)

					document.getElementById('select_bom_filter_button').onclick = () => silicon_ioi.ioiCommon.select_bom_search(select_bom_table)
					document.getElementById('select_bom_search').onkeyup = (e) => fct_keyup(e)
					document.getElementById('select_bom_topn').onkeyup = (e) => fct_keyup(e)

					const fct_keyup = (e) => {
						if (e.key == "Enter") {
							e.preventDefault();
							silicon_ioi.ioiCommon.select_bom_search(select_bom_table)
						}
					}
				}
			})

			if (document.getElementById('ioi_select_bom_container')) document.getElementById('ioi_select_bom_container').remove();

			const grid = `<div id="ioi_select_bom_grid" class="table table-bordered my-3" data-custom-grid="true"></div>`

			const search_input = `
						<div class="mr-2" style="width: 200px;">
							<label style="font-size: var(--text-md);">${__('Search')}</label>
							<div class="control-input" >
								<input id="select_bom_search" type="text" class="input-with-feedback form-control bold">
							</div>
						</div>`

			const topn_button = `
						<div class="mr-2" style="width:100px;">
							<label id="select_bom_topn_label" style="font-size: var(--text-md);">${__("No records")}</label>
							<div class="control-input" style="">
								<input id="select_bom_topn" type="number" class="input-with-feedback form-control bold" min="1" max="1000" value="100">
							</div>
						</div>`

			const filter_button = `
						<div class="align-self-end mb-1">
							<button id="select_bom_filter_button" type="button" class="btn btn-secondary btn-xs">${__('Filter')}</button>
						</div>`

			const keep_call_item = `
					<div class="form-check form-check-inline ml-2" style="margin-top: 28px;">
						<input class="form-check-input" type="checkbox" id="select_bom_keep_call_item">
						<label class="form-check-label" style="font-size: var(--text-md);" for="select_bom_keep_call_item">${__('Keep call item')}</label>
					</div>`

			const multiplier = `
					<div class="mr-2" style="width:75px;">
						<label id="select_bom_multiplier_label" style="font-size: var(--text-md);">${__("Multiplier")}</label>
						<div class="control-input" style="">
							<input id="select_bom_multiplier" type="number" class="input-with-feedback form-control bold" min="1" max="1000" value="">
						</div>
					</div>`

			const control_panel = `
						<div id="ioi_select_bom_container" class="d-flex flex-column w-100 position-relative" style="max-height: 100%;">
							<div class="d-flex flex-row flex-wrap align-items-start justify-content-start">
								${search_input}
								${topn_button}
								${filter_button}
							</div>
							${grid}
							<div class="d-flex flex-row flex-wrap align-items-center justify-content-start">
								${multiplier}
								${keep_call_item}
							</div>
						</div>`

			d.fields_dict.html_select_bom.$wrapper.html(control_panel);

			d.show()
		}
	}

	open_eshop_search() {
		if (this.get_options() == 'ioi Item') {
			let me = this;

			let width = '60%'
			let catalog_id = silicon_ioi.doctype.ioiSalesPOS.doc.sales_catalog_id;
			let customer_id = silicon_ioi.doctype.ioiSalesPOS.doc.order_customer_id;
			let division_id = silicon_ioi.doctype.ioiSalesPOS.doc.division_id;
			let condition_id = silicon_ioi.doctype.ioiSalesPOS.doc.sales_condition_id;
			let warehouse_id = silicon_ioi.doctype.ioiSalesPOS.doc.warehouse_id;

			let fct_callback = function (return_value) {
				let frm = me.frm
				let idx = me.doc.idx - 1

				let script_manager = frm.fields_dict['line_detail'].grid.grid_rows[idx].frm.script_manager;
				frm.fields_dict['line_detail'].grid.grid_rows[idx].doc["item_id"] = return_value;
				script_manager.trigger("item_id", frm.fields_dict['line_detail'].grid.doctype, frm.fields_dict['line_detail'].grid.grid_rows[idx].doc.name);

				frm.refresh_field('line_detail');
			}

			silicon_ioi.ioiEShop.select_item_from_shop(width, division_id, catalog_id, condition_id, customer_id, warehouse_id, fct_callback)
		}
	}

	get_status(explain) {
		let r = super.get_status(explain);

		// VVR: revert null & read-only field as visible
		if (r === "None" && !cint(this.df.hidden) && !cint(this.df.hidden_due_to_dependency)) {
			r = "Read";
		}

		return r;
	}

};

// overriding frappe class
frappe.ui.form.ControlLink = ALink;
