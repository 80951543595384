frappe.provide('silicon_ioi.doctype');

export class ioiLocationFrame
{
	static path_common = 'silicon_ioi.common.common';
	static path_warehouse_stock_detail = 'silicon_ioi.ioi_wms.doctype.ioi_warehouse_stock_detail.ioi_warehouse_stock_detail';
	static path_item = 'silicon_ioi.ioi_items.doctype.ioi_item.ioi_item';
	static is_dark_mode = 0;
	static bgcolor = 0;
	static site_id = '';
	static warehouse_id = '';
	static item_id = '';
	static item_mode = -1;
	static table_filters
	static is_builded_table_filters = false;
	static table_details

	static load_config()
	{
		silicon_ioi.doctype.ioiLocationFrame.is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;
	}


	static load_location_frame(html_field, is_dark_mode = null, bgcolor = null)
	{
		silicon_ioi.doctype.ioiLocationFrame.item_mode = -1;

		if ((is_dark_mode == null) || (bgcolor == null)) {
			silicon_ioi.doctype.ioiLocationFrame.load_config();
		}else{
			silicon_ioi.doctype.ioiLocationFrame.is_dark_mode = is_dark_mode;
			silicon_ioi.doctype.ioiLocationFrame.bgcolor = bgcolor;
		}


		if (document.getElementById('location_frame_filters_panel_filters')) {
			document.getElementById('location_frame_filters_panel_filters').remove();
		}

		if (document.getElementById('location_frame_filters_panel_filters_title')) {
			document.getElementById('location_frame_filters_panel_filters_title').remove();
		}

	


		if (document.getElementById('location_frame_filters_enabled_label')) {
			document.getElementById('location_frame_filters_enabled_label').remove();
		}

		if (document.getElementById('location_frame_filters_enabled')) {
			document.getElementById('location_frame_filters_enabled').remove();
		}

		if (document.getElementById('location_frame_filters_block_label')) {
			document.getElementById('location_frame_filters_block_label').remove();
		}
		
		if (document.getElementById('location_frame_filters_block')) {
			document.getElementById('location_frame_filters_block').remove();
		}
		
		if (document.getElementById('location_frame_filters_row_label')) {
			document.getElementById('location_frame_filters_row_label').remove();
		}
		
		if (document.getElementById('location_frame_filters_row')) {
			document.getElementById('location_frame_filters_row').remove();
		}
		
		
		if (document.getElementById('location_frame_filters_col_label')) {
			document.getElementById('location_frame_filters_col_label').remove();
		}
		
		if (document.getElementById('location_frame_filters_col')) {
			document.getElementById('location_frame_filters_col').remove();
		}


		if (document.getElementById('location_frame_filters_level_label')) {
			document.getElementById('location_frame_filters_level_label').remove();
		}
		
		if (document.getElementById('location_frame_filters_level')) {
			document.getElementById('location_frame_filters_level').remove();
		}


		if (document.getElementById('location_frame_filters_topn_label')) {
			document.getElementById('location_frame_filters_topn_label').remove();
		}

		if (document.getElementById('location_frame_filters_topn')) {
			document.getElementById('location_frame_filters_topn').remove();
		}

		if (document.getElementById('location_frame_filters_button_search')) {
			document.getElementById('location_frame_filters_button_search').remove();
		}

		if (document.getElementById('location_frame_filters_button_clear')) {
			document.getElementById('location_frame_filters_button_clear').remove();
		}


		if (document.getElementById('location_frame_filters_view_empty_locations')) {
			document.getElementById('location_frame_filters_view_empty_locations').remove();
		}

		if (document.getElementById('location_frame_filters_view_empty_locations_label')) {
			document.getElementById('location_frame_filters_view_empty_locations_label').remove();
		}



		if (document.getElementById('location_frame_custom_sort')) {
			document.getElementById('location_frame_custom_sort').remove();
		}

		if (document.getElementById('location_frame_orderby_field')) {
			document.getElementById('location_frame_orderby_field').remove();
		}

		if (document.getElementById('location_frame_orderby_dir')) {
			document.getElementById('location_frame_orderby_dir').remove();
		}


		if (document.getElementById('location_frame_filters')) {
			document.getElementById('location_frame_filters').remove();
		}


		if (document.getElementById('location_frame_result')) {
			document.getElementById('location_frame_result').remove();
		}


		
		if (document.getElementById('location_frame_subdetail_filters_locked_label')) {
			document.getElementById('location_frame_subdetail_filters_locked_label').remove();
		}

		if (document.getElementById('location_frame_subdetail_filters_locked')) {
			document.getElementById('location_frame_subdetail_filters_locked').remove();
		}


		if (document.getElementById('location_frame_subdetail_filters_archived_label')) {
			document.getElementById('location_frame_subdetail_filters_archived_label').remove();
		}

		if (document.getElementById('location_frame_subdetail_filters_archived')) {
			document.getElementById('location_frame_subdetail_filters_archived').remove();
		}



		if (document.getElementById('location_frame_subdetail_filters_search_label')) {
			document.getElementById('location_frame_subdetail_filters_search_label').remove();
		}

		if (document.getElementById('location_frame_subdetail_filters_search')) {
			document.getElementById('location_frame_subdetail_filters_search').remove();
		}

		if (document.getElementById('location_frame_subdetail_filters_view_empty_batches_label')) {
			document.getElementById('location_frame_subdetail_filters_view_empty_batches_label').remove();
		}

		if (document.getElementById('location_frame_subdetail_filters_view_empty_batches')) {
			document.getElementById('location_frame_subdetail_filters_view_empty_batches').remove();
		}


		


		if (document.getElementById('location_frame_subdetail_filters_button_search')) {
			document.getElementById('location_frame_subdetail_filters_button_search').remove();
		}

		if (document.getElementById('location_frame_subdetail_filters_button_clear')) {
			document.getElementById('location_frame_subdetail_filters_button_clear').remove();
		}
		
		
		if (document.getElementById('location_frame_subdetail_orderby_field')) {
			document.getElementById('location_frame_subdetail_orderby_field').remove();
		}

		if (document.getElementById('location_frame_subdetail_orderby_dir')) {
			document.getElementById('location_frame_subdetail_orderby_dir').remove();
		}

		if (document.getElementById('location_frame_subdetail_detail_panel')) {
			document.getElementById('location_frame_subdetail_detail_panel').remove();
		}

		if (document.getElementById('location_frame_subdetail_detail_panel_title')) {
			document.getElementById('location_frame_subdetail_detail_panel_title').remove();
		}



		if (document.getElementById('location_frame_subdetail_filters')) {
			document.getElementById('location_frame_subdetail_filters').remove();
		}


		if (document.getElementById('location_frame_subdetail_result')) {
			document.getElementById('location_frame_subdetail_result').remove();
		}


	
		let html = '';

		html += '<div id="location_frame_filters_panel_filters" style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '	<label id="location_frame_filters_panel_filters_title" style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Filters") + '</b></label>';			
		html += '</div>'

		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'

		html += '<div id="location_frame_filters" style="overflow-x: auto;height:110px;">';

		// All / Enabled / Disabled
		html += '	<div style="position: relative; top: 0px; left: 0px; width:170px;">';		
		html += '		<label id="location_frame_filters_enabled_label" style="position: absolute; top: 0px; left: 2px;">' + __("Enabled / Disabled") + '</label>';
		
		html += '		<select id="location_frame_filters_enabled" class="input-with-feedback form-control bold" style="position: absolute; top: 25px; left: 2px;"> ';
		html += '			<option value="-1">' + __("All") + '</option> ';
		html += '			<option value="1">' + __("Enabled") + '</option> ';
		html += '			<option value="0">' + __("Disabled") + '</option> ';
		html += '		</select> ';
		html += '	</div>';

		// Block
		html += '	<div style="position: relative; top: 0px; left: 180px; width:100px;">';		
		html += '		<label id="location_frame_filters_block_label" style="position: absolute; top: 0px; left: 2px;">' + __("Block") + '</label>';		
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 100px; height: 25px;"> ';
		html += '			<input id="location_frame_filters_block" type="text" maxlength="6" class="input-with-feedback form-control bold" style="text-transform:uppercase" value="">';
		html += '		</div>';
		html += '	</div>';

		// Row
		html += '	<div style="position: relative; top: 0px; left: 290px; width:100px;">';		
		html += '		<label id="location_frame_filters_row_label" style="position: absolute; top: 0px; left: 2px;">' + __("Row") + '</label>';		
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 100px; height: 25px;"> ';
		html += '			<input id="location_frame_filters_row" type="text" maxlength="6" class="input-with-feedback form-control bold" style="text-transform:uppercase" value="">';
		html += '		</div>';
		html += '	</div>';

		// Col
		html += '	<div style="position: relative; top: 0px; left: 400px; width:100px;">';		
		html += '		<label id="location_frame_filters_col_label" style="position: absolute; top: 0px; left: 2px;">' + __("Col") + '</label>';		
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 100px; height: 25px;"> ';
		html += '			<input id="location_frame_filters_col" type="text" maxlength="6" class="input-with-feedback form-control bold" style="text-transform:uppercase" value="">';
		html += '		</div>';
		html += '	</div>';

		// Level
		html += '	<div style="position: relative; top: 0px; left: 510px; width:100px;">';		
		html += '		<label id="location_frame_filters_level_label" style="position: absolute; top: 0px; left: 2px;">' + __("Level") + '</label>';		
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 100px; height: 25px;"> ';
		html += '			<input id="location_frame_filters_level" type="text" maxlength="6" class="input-with-feedback form-control bold" style="text-transform:uppercase" value="">';
		html += '		</div>';
		html += '	</div>';

		// Top N
		html += '	<div style="position: relative; top: 0px; left: 620px; width:100px;">';		
		html += '		<label id="location_frame_filters_topn_label" style="position: absolute; top: 0px; left: 2px;">' + __("No of records") + '</label>';		
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 100px; height: 25px;"> ';
		html += '			<input id="location_frame_filters_topn" type="number" step="any" class="input-with-feedback form-control bold" value="1000" min="0">';		
		html += '		</div>';
		html += '	</div>';

		// Search
		html += '	<div style="position: relative; top: 70px; left: 0px; width:110px;">';		
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="location_frame_filters_button_search" class="btn btn-default ellipsis" style="height: 30px; width: 110px;" onclick="">' + __("Search") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Clear
		html += '	<div style="position: relative; top: 70px; left: 120px; width:50px;">';		
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px;">';
		html +='			<button id="location_frame_filters_button_clear" title="' + __("Clear") +'" data-label="Search" class="btn btn-default ellipsis" style="height: 30px; width: 50px;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// View empty locations
		html += '	<div style="position: relative; top: 70px; left: 180px; width:400px;">';
		html += '		<input type="checkbox" id="location_frame_filters_view_empty_locations" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="location_frame_filters_view_empty_locations_label" style="position: absolute; top: 6px; left: 30px;">' + __("View empty locations") + '</label>';
		html += '	</div>';

		html += '	<input id="location_frame_custom_sort" 	 type="hidden" value="a.block_id, a.row_id, a.col_id, a.level_id">';
		html += '	<input id="location_frame_orderby_field" type="hidden" value="">';
		html += '	<input id="location_frame_orderby_dir"   type="hidden" value="asc">';

		html += '</div>';	
		
		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>';

		html += '<div id="location_frame_result" style="height:200px;">';

		html += '<div id="location_frame_grid" class="table table-bordered" data-custom-grid="true"></div>'

		html += '</div>';

		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>';

		// Sub Detail

		html += '<div id="location_frame_subdetail_detail_panel" style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '	<label id="location_frame_subdetail_detail_panel_title" style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Detail") + '</b></label>';			
		html += '</div>'

		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>';

		html += '<div id="location_frame_subdetail_filters" style="overflow-x: auto;height:110px;">';

		// All / Locked/ Unlocked
		html += '	<div style="position: relative; top: 0px; left: 0px; width:170px;">';		
		html += '		<label id="location_frame_subdetail_filters_locked_label" style="position: absolute; top: 0px; left: 2px;">' + __("Locked / Unlocked") + '</label>';
		html += '		<select id="location_frame_subdetail_filters_locked" class="input-with-feedback form-control bold" style="position: absolute; top: 25px; left: 2px;"> ';
		html += '			<option value="-1">' + __("All") + '</option> ';
		html += '			<option value="1">' + __("Locked") + '</option> ';
		html += '			<option value="0">' + __("Unlocked") + '</option> ';
		html += '		</select> ';
		html += '	</div>';

		// All / Archived / Unarchived
		html += '	<div style="position: relative; top: 0px; left: 180px; width:170px;">';		
		html += '		<label id="location_frame_subdetail_filters_archived_label" style="position: absolute; top: 0px; left: 2px;">' + __("Archived / Unarchived") + '</label>';
		html += '		<select id="location_frame_subdetail_filters_archived" class="input-with-feedback form-control bold" style="position: absolute; top: 25px; left: 2px;"> ';
		html += '			<option value="-1">' + __("All") + '</option> ';
		html += '			<option value="1">' + __("Archived") + '</option> ';
		html += '			<option value="0">' + __("Unarchived") + '</option> ';
		html += '		</select> ';
		html += '	</div>';

		// Search term
		html += '	<div style="position: relative; top: 0px; left: 360px; width:400px;">';		
		html += '		<label id="location_frame_subdetail_filters_search_label" style="position: absolute; top: 0px; left: 2px;">' + __("Search") + '</label>';		
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 400px; height: 25px;"> ';
		html += '			<input id="location_frame_subdetail_filters_search" type="text" class="input-with-feedback form-control bold" value="">';
		html += '		</div>';
		html += '	</div>';

		// Search
		html += '	<div style="position: relative; top: 70px; left: 0px; width:110px;">';		
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="location_frame_subdetail_filters_button_search" class="btn btn-default ellipsis" style="height: 30px; width: 110px;" onclick="">' + __("Search") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Clear
		html += '	<div style="position: relative; top: 70px; left: 120px; width:50px;">';		
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px;">';
		html +='			<button id="location_frame_subdetail_filters_button_clear" title="' + __("Clear") +'" data-label="Search" class="btn btn-default ellipsis" style="height: 30px; width: 50px;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';


		// Vie empty batches
		html += '	<div style="position: relative; top: 70px; left: 180px; width:400px;">';
		html += '		<input type="checkbox" id="location_frame_subdetail_filters_view_empty_batches" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="location_frame_subdetail_filters_view_empty_batches_label" style="position: absolute; top: 6px; left: 30px;">' + __("View empty batches/sn") + '</label>';
		html += '	</div>';


		html += '	<input id="location_frame_subdetail_orderby_field" type="hidden" value="name">';
		html += '	<input id="location_frame_subdetail_orderby_dir"   type="hidden" value="asc">';

		html += '</div>';

		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>';

		html += '<div id="location_frame_subdetail_result" style="height:200px;">';

		html += '<div id="location_frame_subdetail_grid" class="table table-bordered" data-custom-grid="true"></div>'

		html += '</div>';

		document.getElementById(html_field).innerHTML = html;

		silicon_ioi.doctype.ioiLocationFrame.location_frame_grid([])
		silicon_ioi.doctype.ioiLocationFrame.location_frame_subdetail_grid([])

		silicon_ioi.doctype.ioiLocationFrame.sleep_static(200).then(() => {


			let fct_keydown = function(event) { 

				if (event.keyCode == 13) 
				{	return false;
				}
			
			};

			let fct_keyup = function(event) { 

				if (event.keyCode == 13) 
				{	return false;
				}
			
			};


			let fct_keydown_refresh = function(event) { 

				if (event.keyCode == 13) 
				{	

					let site_id = silicon_ioi.doctype.ioiLocationFrame.site_id;
					let warehouse_id = silicon_ioi.doctype.ioiLocationFrame.warehouse_id;
					let item_id = silicon_ioi.doctype.ioiLocationFrame.item_id;
			
					silicon_ioi.doctype.ioiLocationFrame.load_location_frame_get_result(site_id, warehouse_id, item_id);  
	
				}
			
			};

			let fct_change = function() { 

				let site_id = silicon_ioi.doctype.ioiLocationFrame.site_id;
				let warehouse_id = silicon_ioi.doctype.ioiLocationFrame.warehouse_id;
				let item_id = silicon_ioi.doctype.ioiLocationFrame.item_id;
		
				silicon_ioi.doctype.ioiLocationFrame.load_location_frame_get_result(site_id, warehouse_id, item_id);  
				
			};



			// Enabled / Disabled
			document.getElementById('location_frame_filters_enabled').onchange = fct_change;
			document.getElementById('location_frame_filters_enabled').onkeydown = fct_keydown;
			document.getElementById('location_frame_filters_enabled').onkeydown = fct_keyup;


			// Block
			document.getElementById('location_frame_filters_block').onkeydown = fct_keydown_refresh;
			document.getElementById('location_frame_filters_block').onkeyup = fct_keyup;

			// Row
			document.getElementById('location_frame_filters_row').onkeydown = fct_keydown_refresh;
			document.getElementById('location_frame_filters_row').onkeyup = fct_keyup;

			// Col
			document.getElementById('location_frame_filters_col').onkeydown = fct_keydown_refresh;
			document.getElementById('location_frame_filters_col').onkeyup = fct_keyup;

			// Level
			document.getElementById('location_frame_filters_level').onkeydown = fct_keydown_refresh;
			document.getElementById('location_frame_filters_level').onkeyup = fct_keyup;

			// Topn
			document.getElementById('location_frame_filters_topn').onkeydown = fct_keydown_refresh;
			document.getElementById('location_frame_filters_topn').onkeyup = fct_keyup;
			
	
			document.getElementById('location_frame_filters_view_empty_locations').onclick = fct_change;
			document.getElementById('location_frame_filters_view_empty_locations').onkeydown = fct_keydown;
			document.getElementById('location_frame_filters_view_empty_locations').onkeyup = fct_keyup;


			let fct_button_search = function () { 
				let table_content = document.querySelector('#item_stock_frame_warehouse_stock_grid .tabulator-table').children
				
				if (table_content.length) {
					let site_id = silicon_ioi.doctype.ioiLocationFrame.site_id;
					let warehouse_id = silicon_ioi.doctype.ioiLocationFrame.warehouse_id;
					let item_id = silicon_ioi.doctype.ioiLocationFrame.item_id;
			
					silicon_ioi.doctype.ioiLocationFrame.load_location_frame_get_result(site_id, warehouse_id, item_id);  
				}
			};
			
			let fct_button_clear = function () { silicon_ioi.doctype.ioiLocationFrame.load_location_frame_do_clear();	 };
	
			document.getElementById('location_frame_filters_button_search').onclick = fct_button_search;
			document.getElementById('location_frame_filters_button_clear').onclick = fct_button_clear;

			let fct_keydown_sub_detail_refresh = function(event) { 

				if (event.keyCode == 13) 
				{	
					silicon_ioi.doctype.ioiLocationFrame.load_location_frame_get_subdetail_result(); 
					return false; 
				}
			
			};

			let fct_change_sub_detail = function() { 

				silicon_ioi.doctype.ioiLocationFrame.load_location_frame_get_subdetail_result();  
			};

			let fct_click_sub_detail = function() { 

				silicon_ioi.doctype.ioiLocationFrame.load_location_frame_get_subdetail_result(); 
				
			};


			// Locked / Unlocked
			document.getElementById('location_frame_subdetail_filters_locked').onchange = fct_change_sub_detail;
			document.getElementById('location_frame_subdetail_filters_locked').onkeydown = fct_keydown;
			document.getElementById('location_frame_subdetail_filters_locked').onkeydown = fct_keyup;

			// Archived / Unarchived
			document.getElementById('location_frame_subdetail_filters_archived').onchange = fct_change_sub_detail;
			document.getElementById('location_frame_subdetail_filters_archived').onkeydown = fct_keydown;
			document.getElementById('location_frame_subdetail_filters_archived').onkeydown = fct_keyup;
			
			// search
			document.getElementById('location_frame_subdetail_filters_search').onkeydown = fct_keydown_sub_detail_refresh;
			document.getElementById('location_frame_subdetail_filters_search').onkeyup = fct_keyup;


			document.getElementById('location_frame_subdetail_filters_view_empty_batches').onclick = fct_click_sub_detail;
			document.getElementById('location_frame_subdetail_filters_view_empty_batches').onkeydown = fct_keydown;
			document.getElementById('location_frame_subdetail_filters_view_empty_batches').onkeyup = fct_keyup;

			

			let fct_button_subdetail_search = function () { silicon_ioi.doctype.ioiLocationFrame.load_location_frame_get_subdetail_result(); };  
			let fct_button_subdetail_clear = function () { silicon_ioi.doctype.ioiLocationFrame.load_location_frame_do_subdetail_clear();	 };
	
			document.getElementById('location_frame_subdetail_filters_button_search').onclick = fct_button_subdetail_search;
			document.getElementById('location_frame_subdetail_filters_button_clear').onclick = fct_button_subdetail_clear;

		});


	}

	static location_frame_grid(data)
	{

		let let_empty_if_zero = (cell, formatterParams, onRendered) => {
			if (cell.getValue() != 0) {
				return cell.getValue()
			}
		}

		silicon_ioi.doctype.ioiLocationFrame.table_filters = new ioi.Tabulator("#location_frame_grid", {
			maxHeight: 200,
			data: data,
			layout: "fitColumns",
			selectableRows: 1,
			showProfiles: true,
			movableColumns: true,
			resizableColumns: true,
			autoRedraw: true,
			columns: [
				{ title: __("Block"), field: "block_id", minWidth: 100 },
				{ title: __("Row"), field: "row_id", minWidth: 100 },
				{ title: __("Col"), field: "col_id", minWidth: 100 },
				{ title: __("Level"), field: "level_id", minWidth: 100 },
				{ title: __("Unit"), field: "unit_id", minWidth: 100 },
				{ title: __("Total stock qty"), field: "tot_q_stock", minWidth: 150, hozAlign: 'right', formatter: let_empty_if_zero },
				{ title: __("Stock qty"), field: "q_stock", minWidth: 150, hozAlign: 'right', formatter: let_empty_if_zero },
				{ title: __("Reserved qty"), field: "q_reserved", minWidth: 150, hozAlign: 'right', formatter: let_empty_if_zero },
				{ title: __("To check qty"), field: "q_tocheck", minWidth: 150, hozAlign: 'right', formatter: let_empty_if_zero },
				{ title: __("Bad qty"), field: "q_bad", minWidth: 150, hozAlign: 'right', formatter: let_empty_if_zero },
				{ title: __("Locked qty"), field: "q_locked", minWidth: 150, hozAlign: 'right', formatter: let_empty_if_zero },
				{ title: __("Lck. tocheck qty"), field: "q_lockedtocheck", minWidth: 150, hozAlign: 'right', formatter: let_empty_if_zero },
				{ title: __("Lck. bad qty"), field: "q_lockedbad", minWidth: 150, hozAlign: 'right', formatter: let_empty_if_zero },
				{ title: __("Packed qty"), field: "q_packed", minWidth: 150, hozAlign: 'right', formatter: let_empty_if_zero },
			],
		});

		silicon_ioi.doctype.ioiLocationFrame.table_filters.on("headerClick", function (e, column) {
			document.getElementById('location_frame_custom_sort').value = '';

			let ordered_field = document.getElementById('location_frame_orderby_field')
			ordered_field.value = column.getField()

			let ordered_dir = document.getElementById('location_frame_orderby_dir')
			ordered_dir.value = silicon_ioi.doctype.ioiLocationFrame.table_filters.getSorters()[0].dir

			document.getElementById('location_frame_filters_button_search').click();
		});

		silicon_ioi.doctype.ioiLocationFrame.table_filters.on('dataProcessed', () => {
			if (silicon_ioi.doctype.ioiLocationFrame.table_filters.getRows().length) {
				silicon_ioi.doctype.ioiLocationFrame.table_filters.getRows()[0].select()
			}
		})

		silicon_ioi.doctype.ioiLocationFrame.table_filters.on("rowSelected", (row) => {
			silicon_ioi.doctype.ioiLocationFrame.location_frame_row_click(row)
		});
	}

	static load_location_frame_do_clear()
	{

		document.getElementById('location_frame_filters_enabled').selectedIndex = 0;

		document.getElementById('location_frame_filters_block').value = '';
		document.getElementById('location_frame_filters_row').value = '';
		document.getElementById('location_frame_filters_col').value = '';
		document.getElementById('location_frame_filters_level').value = '';

		document.getElementById('location_frame_filters_topn').value = '1000';	
		
		document.getElementById('location_frame_filters_view_empty_locations').checked = false;

		if (document.getElementById('location_frame_selected_block_id')) {
			document.getElementById('location_frame_selected_block_id').remove();
		}

		if (document.getElementById('location_frame_selected_row_id')) {
			document.getElementById('location_frame_selected_row_id').remove();
		}

		if (document.getElementById('location_frame_selected_col_id')) {
			document.getElementById('location_frame_selected_col_id').remove();
		}

		if (document.getElementById('location_frame_selected_level_id')) {
			document.getElementById('location_frame_selected_level_id').remove();
		}

		if (document.getElementById('location_frame_selected_unit_id')) {
			document.getElementById('location_frame_selected_unit_id').remove();
		}	

		silicon_ioi.doctype.ioiLocationFrame.table_filters.clearSort()
		silicon_ioi.doctype.ioiLocationFrame.table_filters.clearData();

		silicon_ioi.doctype.ioiLocationFrame.load_location_frame_do_subdetail_clear()
	}

	static load_location_frame_get_result(site_id, warehouse_id, item_id = '')	
	{		

		silicon_ioi.doctype.ioiLocationFrame.site_id = site_id;
		silicon_ioi.doctype.ioiLocationFrame.warehouse_id = warehouse_id;
		silicon_ioi.doctype.ioiLocationFrame.item_id = item_id;
	
		if (document.getElementById('location_frame_subdetail_detail_panel_title')) {	
			document.getElementById('location_frame_subdetail_detail_panel_title').innerHTML = '<b>' + ("Detail") + '</b>';
		}
		
		if (item_id != '') {

			if (silicon_ioi.doctype.ioiLocationFrame.item_mode == -1) {

				let method = silicon_ioi.doctype.ioiLocationFrame.path_item + '.ioi_item_get_item';
			
				frappe.call({  	method: method, 
								args: {"item_id": item_id },
								async: false,
								callback:function(r)	{ 

									silicon_ioi.doctype.ioiLocationFrame.item_mode = r.message.mode;

									if ((silicon_ioi.doctype.ioiLocationFrame.item_mode != 3) && (silicon_ioi.doctype.ioiLocationFrame.item_mode != 4)) {


										if (document.getElementById('location_frame_subdetail_detail_panel')) {
											document.getElementById('location_frame_subdetail_detail_panel').style.display = 'none';
										}

										if (document.getElementById('location_frame_subdetail_filters')) {
											document.getElementById('location_frame_subdetail_filters').style.display = 'none';
										}


										if (document.getElementById('location_frame_subdetail_result')) {
											document.getElementById('location_frame_subdetail_result').style.display = 'none';
										}

										if (document.getElementById('item_stock_frame_batches_tab')) {
											document.getElementById('item_stock_frame_batches_tab').style.display = 'none';
										}

										if (document.getElementById('item_stock_frame_batches_content')) {
											document.getElementById('item_stock_frame_batches_content').style.display = 'none';
										}



									}else{


										if (document.getElementById('location_frame_subdetail_detail_panel')) {
											document.getElementById('location_frame_subdetail_detail_panel').style.display = 'block';
										}

										if (document.getElementById('location_frame_subdetail_filters')) {
											document.getElementById('location_frame_subdetail_filters').style.display = 'block';
										}


										if (document.getElementById('location_frame_subdetail_result')) {
											document.getElementById('location_frame_subdetail_result').style.display = 'block';
										}

										if (document.getElementById('item_stock_frame_batches_tab')) {
											document.getElementById('item_stock_frame_batches_tab').style.display = 'block';
										}


									}

								}
				});
			}
		}else{
			if (document.getElementById('location_frame_subdetail_detail_panel')) {
				document.getElementById('location_frame_subdetail_detail_panel').style.display = 'none';
			}

			if (document.getElementById('location_frame_subdetail_filters')) {
				document.getElementById('location_frame_subdetail_filters').style.display = 'none';
			}


			if (document.getElementById('location_frame_subdetail_result')) {
				document.getElementById('location_frame_subdetail_result').style.display = 'none';
			}

			if (document.getElementById('item_stock_frame_batches_tab')) {
				document.getElementById('item_stock_frame_batches_tab').style.display = 'none';
			}

			if (document.getElementById('item_stock_frame_batches_content')) {
				document.getElementById('item_stock_frame_batches_content').style.display = 'none';
			}

		}


		if (document.getElementById('location_frame_selected_block_id')) {
			document.getElementById('location_frame_selected_block_id').remove();
		}

		if (document.getElementById('location_frame_selected_row_id')) {
			document.getElementById('location_frame_selected_row_id').remove();
		}

		if (document.getElementById('location_frame_selected_col_id')) {
			document.getElementById('location_frame_selected_col_id').remove();
		}

		if (document.getElementById('location_frame_selected_level_id')) {
			document.getElementById('location_frame_selected_level_id').remove();
		}

		if (document.getElementById('location_frame_selected_unit_id')) {
			document.getElementById('location_frame_selected_unit_id').remove();
		}

		if ((site_id != '') && (warehouse_id != '')) {

			let cb_empty_locations = 0

			if (document.getElementById('location_frame_filters_view_empty_locations').checked) {
				cb_empty_locations = 1;
			}

			frappe.call({  	method: silicon_ioi.doctype.ioiLocationFrame.path_warehouse_stock_detail + ".ioi_warehouse_stock_detail_get_frame_list",
							args: {	"site_id": site_id,
									"warehouse_id": warehouse_id,
									"item_id": item_id,
									"enabled": document.getElementById('location_frame_filters_enabled').value,
									"block": document.getElementById('location_frame_filters_block').value,
									"row": document.getElementById('location_frame_filters_row').value,
									"col": document.getElementById('location_frame_filters_col').value,
									"level": document.getElementById('location_frame_filters_level').value,
									"view_empty_location": cb_empty_locations,
									"custom_sort": document.getElementById('location_frame_custom_sort').value,
									"orderby_field": document.getElementById('location_frame_orderby_field').value, 
									"orderby_dir": document.getElementById('location_frame_orderby_dir').value,
									"topn": document.getElementById('location_frame_filters_topn').value
							},
							async : false,
							callback:function(r) { 
								if (r.message.length > 0) {
									let html = '';

									html += '<input id="location_frame_selected_block_id" type="hidden" value = "">';
									html += '<input id="location_frame_selected_row_id" type="hidden" value = "">';
									html += '<input id="location_frame_selected_col_id" type="hidden" value = "">';
									html += '<input id="location_frame_selected_level_id" type="hidden" value = "">';
									html += '<input id="location_frame_selected_unit_id" type="hidden" value = "">';

									document.getElementById('location_frame_grid').insertAdjacentHTML('afterend', html);

									if (silicon_ioi.doctype.ioiLocationFrame.table_filters.initialized) {
										silicon_ioi.doctype.ioiLocationFrame.table_filters.replaceData(r.message);
									} else {
										silicon_ioi.doctype.ioiLocationFrame.table_filters.on('tableBuilt', () => {
											silicon_ioi.doctype.ioiLocationFrame.table_filters.replaceData(r.message);
										})
									}

									if (silicon_ioi.doctype.ioiLocationFrame.table_filters.getRows().length) {
										silicon_ioi.doctype.ioiLocationFrame.table_filters.getRows()[0].select()
									}

								} else {
									if (silicon_ioi.doctype.ioiLocationFrame.table_filters.initialized) {
										silicon_ioi.doctype.ioiLocationFrame.table_filters.clearData();
									}

									if (silicon_ioi.doctype.ioiLocationFrame.table_details.initialized) {
										silicon_ioi.doctype.ioiLocationFrame.table_details.clearData();
									}
								}
							}
						});
		}
	}

	static location_frame_row_click(row)
	{
		let rowData = row.getData()

		document.getElementById('location_frame_selected_block_id').value = rowData.block_id
		document.getElementById('location_frame_selected_row_id').value = rowData.row_id
		document.getElementById('location_frame_selected_col_id').value = rowData.col_id
		document.getElementById('location_frame_selected_level_id').value = rowData.level_id
		document.getElementById('location_frame_selected_unit_id').value = rowData.unit_id

		let sloc = document.getElementById('location_frame_selected_block_id').value;
		sloc += document.getElementById('location_frame_selected_row_id').value;
		sloc += document.getElementById('location_frame_selected_col_id').value;
		sloc += document.getElementById('location_frame_selected_level_id').value;


		if (document.getElementById('location_frame_subdetail_detail_panel_title')) {
			document.getElementById('location_frame_subdetail_detail_panel_title').innerHTML = '<b>' + ("Detail, for location") + ' ' + sloc + '</b>';
		}


		silicon_ioi.doctype.ioiLocationFrame.load_location_frame_get_subdetail_result();
	}


	static location_frame_subdetail_grid()
	{

		let let_empty_if_zero = (cell, formatterParams, onRendered) => {
			if (cell.getValue() != 0) {
				return cell.getValue()
			}
		}

		let convert_number_to_checkbox = (cell, formatterParams, onRendered) => {
			return `<div class="d-flex justify-content-center align-items-center" style="height:100%;"><input type="checkbox" ${cell.getValue() == 1 ? "checked" : ""} disabled></div>`;
		}

		silicon_ioi.doctype.ioiLocationFrame.table_details = new ioi.Tabulator("#location_frame_subdetail_grid", {
			maxHeight: 200,
			data: [],
			layout: "fitColumns",
			selectableRows: 1,
			showProfiles: true,
			movableColumns: true,
			resizableColumns: true,
			autoRedraw: true,
			columns: [
				{ title: __("Batch/SN"), field: "name", minWidth:250, formatter: 'link', formatterParams: { labelField: 'name', urlPrefix: '/app/ioi-batch-sn/', urlField: 'name', target: '_blank'}},
				{ title: __("Locked"), field: "batch_sn_locked", minWidth:100, formatter: convert_number_to_checkbox },
				{ title: __("Archived"), field: "batch_sn_archived", minWidth:100, formatter: convert_number_to_checkbox },
				{ title: __("Unit"), field: "unit_id", minWidth:100 },
				{ title: __("Stock qty"), field: "q_stock", minWidth:120, hozAlign: 'right', formatter: let_empty_if_zero },
				{ title: __("Reserved qty"), field: "q_reserved", minWidth:150, hozAlign: 'right', formatter: let_empty_if_zero },
				{ title: __("Batch Cost"), field: "batch_cost", minWidth: 100, hozAlign: 'right', formatter: let_empty_if_zero },
				{ title: __("To check qty"), field: "q_tocheck", minWidth:120, hozAlign: 'right', formatter: let_empty_if_zero },
				{ title: __("Bad qty"), field: "q_bad", minWidth:120, hozAlign: 'right', formatter: let_empty_if_zero },
				{ title: __("Code ref"), field: "code_ref", minWidth:250 },
				{ title: __("QC ref"), field: "qcref", minWidth:250 },
				{ title: __("Origin"), field: "origin", minWidth:250 },
			],
		});

		silicon_ioi.doctype.ioiLocationFrame.table_details.on("headerClick", function(e, column){
			let ordered_field = document.getElementById('location_frame_subdetail_orderby_field')
			ordered_field.value = column.getField()

			let ordered_dir = document.getElementById('location_frame_subdetail_orderby_dir')
			ordered_dir.value = silicon_ioi.doctype.ioiLocationFrame.table_details.getSorters()[0].dir

			document.getElementById('location_frame_subdetail_filters_button_search').click();
		});

		silicon_ioi.doctype.ioiLocationFrame.table_details.on("dataProcessed", (data) => {
			if (silicon_ioi.doctype.ioiLocationFrame.table_details.getRows().length) {
				silicon_ioi.doctype.ioiLocationFrame.location_frame_subdetail_row_click(silicon_ioi.doctype.ioiLocationFrame.table_details.getRows()[0])
				silicon_ioi.doctype.ioiLocationFrame.table_details.getRows()[0].select()
			}
		});

		silicon_ioi.doctype.ioiLocationFrame.table_details.on("rowSelected", (row) => {
			silicon_ioi.doctype.ioiLocationFrame.location_frame_subdetail_row_click(row)
		});
	}

	static load_location_frame_do_subdetail_clear()
	{

		document.getElementById('location_frame_subdetail_filters_locked').selectedIndex = 0;
		document.getElementById('location_frame_subdetail_filters_archived').selectedIndex = 0;
		document.getElementById('location_frame_subdetail_filters_search').value = '';

		document.getElementById('location_frame_subdetail_filters_view_empty_batches').checked = false;

		silicon_ioi.doctype.ioiLocationFrame.table_details.clearSort()
		silicon_ioi.doctype.ioiLocationFrame.table_details.clearData()
	}
	
	static load_location_frame_get_subdetail_result()
	{
		if ((silicon_ioi.doctype.ioiLocationFrame.site_id != '') && (silicon_ioi.doctype.ioiLocationFrame.warehouse_id != '') && (silicon_ioi.doctype.ioiLocationFrame.item_id != '')) {

			let block = '';
			
			if (document.getElementById('location_frame_selected_block_id')) {
				block = document.getElementById('location_frame_selected_block_id').value;
			}

			let row = '';

			if (document.getElementById('location_frame_selected_row_id')) {
				row = document.getElementById('location_frame_selected_row_id').value;
			}
						
			let col = '';

			if (document.getElementById('location_frame_selected_col_id')) {
				col = document.getElementById('location_frame_selected_col_id').value;
			}

			let level = '';

			if (document.getElementById('location_frame_selected_level_id')) {
				level = document.getElementById('location_frame_selected_level_id').value;
			}

			let unit_id = '';

			if (document.getElementById('location_frame_selected_unit_id')) {
				unit_id = document.getElementById('location_frame_selected_unit_id').value;
			}

			let cb_empty_batches = 0;

			if (document.getElementById('location_frame_subdetail_filters_view_empty_batches').checked) {
				cb_empty_batches = 1;
			}

			frappe.call({  	method: silicon_ioi.doctype.ioiLocationFrame.path_warehouse_stock_detail + ".ioi_warehouse_stock_detail_get_location_detail",
							args: {	"site_id": silicon_ioi.doctype.ioiLocationFrame.site_id,
									"warehouse_id": silicon_ioi.doctype.ioiLocationFrame.warehouse_id,
									"item_id": silicon_ioi.doctype.ioiLocationFrame.item_id,
									"block": block,
									"row": row, 
									"col": col,
									"level": level,
									"unit_id": unit_id, 
									"locked": document.getElementById('location_frame_subdetail_filters_locked').value, 
									"archived": document.getElementById('location_frame_subdetail_filters_archived').value, 
									"search": document.getElementById('location_frame_subdetail_filters_search').value, 
									"view_empty_batches": cb_empty_batches,
									"orderby_field": document.getElementById('location_frame_subdetail_orderby_field').value, 
									"orderby_dir": document.getElementById('location_frame_subdetail_orderby_dir').value
							},
							async : false,
							callback:function(r) {  
								if (r.message.length > 0) {
									if (silicon_ioi.doctype.ioiLocationFrame.table_details.initialized) {
										silicon_ioi.doctype.ioiLocationFrame.table_details.replaceData(r.message)
									} else {
										silicon_ioi.doctype.ioiLocationFrame.table_details.on('tableBuilt', () => {
											silicon_ioi.doctype.ioiLocationFrame.table_details.replaceData(r.message)
										})
									}
								} else {
									if (silicon_ioi.doctype.ioiLocationFrame.table_details && silicon_ioi.doctype.ioiLocationFrame.table_details.initialized) {
										silicon_ioi.doctype.ioiLocationFrame.table_details.clearData();
									}
								}
							}
			});
		}
	}

	static location_frame_subdetail_row_click(row)
	{
		let rowData = row.getData()

		if (rowData.batch_sn_locked == 1) {
			row.getCells().map(cell => cell.getElement().style.backgroundColor = '#D1CDCD');
			row.getCells().map(cell => cell.getElement().style.color = '#000000');
		}				
	}


	static sleep_static(ms) 
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}

}

silicon_ioi.doctype.ioiLocationFrame = ioiLocationFrame;