frappe.provide("silicon_ioi")

const CURRENT_STAFF_ID = "current_staff_id";

export class ioiAndroidScanning{
	static SCAN_TYPE = {
		division: "DIVISION",
		site: "SITE",
		journal: "JOURNAL",
		warehouse: "WAREHOUSE",
		warehouse_location: "WAREHOUSE_LOCATION",
		staff: "STAFF",
		step: "STEP",
		workcenter: "WORKCENTER",
		func: "FUNCTION",
		search_strategy: "SEARCH_STRATEGY",
	}

	static general_settings = {}

	static async init() {
		const DT = "ioi General Scan Settings";
		this.general_settings = {
			division_pref : await frappe.db.get_single_value(DT,"division_prefix"),
			site_pref : await frappe.db.get_single_value(DT,"site_prefix"),
			journal_pref: await frappe.db.get_single_value(DT,"journal_prefix"),
			warehouse_pref : await frappe.db.get_single_value(DT,"warehouse_prefix"),
			warehouse_location_pref : await frappe.db.get_single_value(DT,"warehouse_location_prefix"),
			staff_pref : await frappe.db.get_single_value(DT,"staff_prefix"),
			step_pref : await frappe.db.get_single_value(DT,"step_production_dossier_prefix"),
			workcenter_pref : await frappe.db.get_single_value(DT,"workcenter_prefix"),
			function_pref : await frappe.db.get_single_value(DT,"function_prefix"),
		}
	}

	static get_current_staff_id(){
		let current_staff_id = frappe.boot.default_user_staff_id;

		if (localStorage && localStorage.getItem(CURRENT_STAFF_ID)){
			current_staff_id = localStorage.getItem(CURRENT_STAFF_ID)
		}

		return current_staff_id;
	}

	static barcode_entry_dialog(callback_on_scan, callback_on_close){
		const BARCODE_ENTRY_DLG = "barcode_entry_dlg";
		if (cur_dialog?.name == BARCODE_ENTRY_DLG){
			return
		}

		let barcode_entry_dlg = new frappe.ui.Dialog({
			title: __("Barcode entry"),
			static: true,
			fields: [
				{
					label: __('Barcode'),
					fieldname: 'barcode',
					fieldtype: 'Data',
				},
				{
					fieldname: "btn_group_html",
					fieldtype: "HTML"
				}
			],
			primary_action_label: __("Validate"),
			primary_action: (values) => {
				if (values.barcode){
					barcode_entry_dlg.hide();
					if (callback_on_close){
						callback_on_close();
					}

					if (callback_on_scan){
						callback_on_scan(values.barcode.toUpperCase());
					}
				}else{
					this.msg_failed(__("Empty barcode"))
				}
			},
			secondary_action_label: __("Cancel"),
			secondary_action: () => {
				barcode_entry_dlg.hide();
				if (callback_on_close){
					callback_on_close();
				}
			},
			on_page_show: () => {
				barcode_entry_dlg.fields_dict.barcode.$input.css("text-transform","uppercase");
				let btn_group_html = `
				<div id="btn-group-keys" class="btn-group" role="group" aria-label="Basic example">
					<button type="button" class="btn btn-secondary">$</button>
					<button type="button" class="btn btn-secondary">/</button>
					<button type="button" class="btn btn-secondary">-</button>
				</div>
				`;

				if ($("#btn-group-keys").length > 0){
					$("#btn-group-keys").remove();
				}

				barcode_entry_dlg.fields_dict.btn_group_html.$wrapper.append(btn_group_html);

				for (var btn of $("#btn-group-keys").children()){
					$(btn).on('click', (e) => {
						e.currentTarget.blur();
						this.barcode_entry_btn_click($(e.currentTarget).text(), barcode_entry_dlg);

					});
				}

			}
		});
		barcode_entry_dlg.name = BARCODE_ENTRY_DLG
	}
	static barcode_entry_btn_click(val, dlg){
		if (cstr(val)){
			let new_barcode = dlg.fields_dict.barcode.$input.val() + cstr(val);
			dlg.fields_dict.barcode.$input.val(new_barcode);
			dlg.fields_dict.barcode.$input.focus();
		}
	}
	static msg_success(msg){
		frappe.show_alert({
			message: __(msg),
			indicator: "green",
		});
	}

	static msg_failed(msg){
		frappe.show_alert({
			message: __(msg),
			indicator: "red",
		});
	}

	static async set_current_staff_id(staff_id){
		if (localStorage && staff_id){
			localStorage.setItem(CURRENT_STAFF_ID, staff_id);
			return true;
		}
		return false;
	}

	static get_scan_data_info(scan_data){
		const prefixes = [
			{ pref: this.general_settings.division_pref, type: this.SCAN_TYPE.division },
			{ pref: this.general_settings.site_pref, type: this.SCAN_TYPE.site },
			{ pref: this.general_settings.function_pref, type: this.SCAN_TYPE.func },
			{ pref: this.general_settings.journal_pref, type: this.SCAN_TYPE.journal },
			{ pref: this.general_settings.staff_pref, type: this.SCAN_TYPE.staff },
			{ pref: this.general_settings.step_pref, type: this.SCAN_TYPE.step },
			{ pref: this.general_settings.warehouse_pref, type: this.SCAN_TYPE.warehouse },
			{ pref: this.general_settings.warehouse_location_pref, type: this.SCAN_TYPE.warehouse_location},
			{ pref: this.general_settings.workcenter_pref, type: this.SCAN_TYPE.workcenter }
		];

		let output = { scan_accepted: false, prefix: "", data: "", scan_type: "" };

		for (const { pref, type } of prefixes) {
			if (scan_data.startsWith(pref)) {

				frappe.call({
					method: "silicon_ioi.ioi_configuration.doctype.ioi_general_scan_settings.ioi_general_scan_settings.decode_and_validate_std_scan_data",
					args: {
						scan_type: type,
						data: scan_data.substring(pref.length).replaceAll("/"," • ")
					},
					async: false,
					callback:function(r){
							if (r.message.success){
								output = { scan_accepted: true, prefix: pref, data: r.message.name, scan_type: type };
							}else{
								ioiAndroidScanning.msg_failed(r.message.description)
							}
					}
				});

				if (output.scan_accepted){
					break;
				}
			}
		}

		if (!output.scan_accepted){

			frappe.call({
				method: "silicon_ioi.ioi_configuration.doctype.ioi_general_scan_settings.ioi_general_scan_settings.decode_scan_with_search_strategy",
				args: {"scan_data" : scan_data},
				async: false,
				callback:function(r){
						if (r.message.success){
							output = {
								scan_accepted: true,
								doctype: r.message.doctype,
								doc_name: r.message.doc_name,
								data: r.message.data,
								scan_type: ioiAndroidScanning.SCAN_TYPE.search_strategy,
								extra: r.message?.extra };
						}
				}
			});
		}

		return output;
	}
}

silicon_ioi.ioiAndroidScanning = ioiAndroidScanning;
if (frappe.is_mobile()){
	silicon_ioi.ioiAndroidScanning.init();
}
