frappe.provide('silicon_ioi.doctype');

export class ioiEPosDevice
{
	ip_address = '';
	port = 0;
	paper_width = 0;
	action_on_create = '';
	print_from_context = false;
	ctx = null;
	ctx_height = 0;

	ePosDev = null;
	printer = null;


	constructor(ip_address, port, paper_width, action_on_create, print_from_context, ctx, ctx_height)
	{
		this.ip_address = ip_address;
		this.port = port;
		this.paper_width = paper_width;
		this.action_on_create = action_on_create;
		this.print_from_context = print_from_context;
		this.ctx = ctx;
		this.ctx_height = ctx_height;

		// Check if the connection is already established
		if (silicon_ioi.doctype.ioiEPosConnect.is_connected('printer', this.ip_address, this.port)){
			this.printer = silicon_ioi.doctype.ioiEPosConnect.get_epos_device('printer', this.ip_address, this.port)
			this.execute_added_code();
		}else{
			this.ePosDev = new ioi.epson.ePOSDevice();
			this.connect();
		}


		// this.ePosDev = new ioi.epson.ePOSDevice();
		// this.connect();
	}


	connect()
	{
		let me = this;
		

		let cbCreateDevice_printer = function(devobj, retcode) {

			if (retcode == 'OK') {
				me.printer = devobj;
				me.execute_added_code();
				silicon_ioi.doctype.ioiEPosConnect.add_connection('printer', me.ip_address, me.port, me.printer)
			}else{
				frappe.msgprint({ title: __("Message"), message: retcode, indicator: "red" });
			}
		}

		let cbConnect = function (data) {

			if((data == 'OK') || (data == 'SSL_CONNECT_OK')) {
				me.ePosDev.createDevice('local_printer', me.ePosDev.DEVICE_TYPE_PRINTER, {'crypto' : false, 'buffer' : false}, cbCreateDevice_printer);
			} else {
				frappe.msgprint({ title: __("Message"), message: __("Printer connection error : check ip address, port, ..."), indicator: "red" });
			}
		};

		me.ePosDev.connect(me.ip_address, me.port, cbConnect);

	}

	execute_added_code(){
		if (this.action_on_create == 'PRINT') {

			if (this.print_from_context) {
				this.do_print_from_context();
			}

		}else if (this.action_on_create == 'DRAWER_PULSE') {
			this.printer.addPulse();
			this.printer.send();
			// me.ePosDev.disconnect();

		}
	}


	do_print_from_context()
	{
		//Old method
		//Improve printing speed
		// this.printer.addTextAlign(this.printer.ALIGN_LEFT);
		//Print image canevas
		// this.printer.addImage(this.ctx, 0, 0, this.paper_width, this.ctx_height, this.printer.COLOR_1, this.printer.MODE_MONO);
		//Cut paper
		// this.printer.addCut(this.printer.CUT_FEED);
		// this.printer.send();

		//New method
		//Improve tone (seuil demi-teinte)
		this.printer.halftone = this.printer.HALFTONE_ERROR_DIFFUSION
		//Improve clearness, stroke more lisable (by default = 1.0)
		this.printer.brightness = 0.5
		this.printer.print(this.ctx, true, this.printer.MODE_MONO, 30000);

		let fct_fire_success = function() {

		};

		this.printer.fireReceiveEvent = fct_fire_success;


		let fct_fire_error = function(status, responseText) {

		};

		this.printer.fireErrorEvent = fct_fire_error;

		// this.ePosDev.disconnect();
		// this.printer = null;
		// this.ePosDev = null;
	}

}

silicon_ioi.doctype.ioiEPosDevice = ioiEPosDevice;
