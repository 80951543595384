import Quill from "quill";

frappe.provide("ioi.reporting");

import "./about_page.js";
import "./filter.js";
import "./workspace.js";
import "./ioi_layout.js";
import "./ioi_barcode.js";
import "./ioi_check.js";
import "./ioi_code.js";
import "./ioi_color.js";
import "./ioi_currency.js";
import "./ioi_data.js";
import "./ioi_date.js";
import "./ioi_datetime.js";
import "./ioi_duration.js";
import "./ioi_dynamic_link.js";
import "./ioi_float.js";
import "./ioi_html_editor.js";
import "./ioi_int.js";
import "./ioi_json.js";
import "./ioi_link.js";
import "./ioi_markdown_editor.js";
import "./ioi_password.js";
import "./ioi_phone.js";
import "./ioi_rating.js";
import "./ioi_select.js";
import "./ioi_signature.js";
import "./ioi_table.js";
import "./ioi_table_multiselect.js";
import "./ioi_text.js";
import "./ioi_text_editor.js";
import "./ioi_time.js";
import "./classes/ioi_common.js";
import "./classes/ioi_acc_utils.js";
import "./classes/ioi_document_status.js";
import "./classes/ioi_doctype.js";
import "./classes/ioi_table_doctype.js";
import "./classes/ioi_secondarytable_doctype.js";
import "./classes/ioi_roottable_doctype.js";
import "./classes/ioi_signaletic_doctype.js";
import "./classes/ioi_document_doctype.js";
import "./classes/ioi_pivotdocument_doctype.js";
import "./classes/ioi_salesdocument_doctype.js";
import "./classes/ioi_purchasesdocument_doctype.js";
import "./classes/ioi_stockdocument_doctype.js";
import "./classes/ioi_qualifdocument_doctype.js";
import "./classes/ioi_productiondocument_doctype.js";
import "./classes/ioi_stockentrydocument_doctype.js";
import "./classes/ioi_stockoutputdocument_doctype.js";
import "./classes/ioi_stockinventorydocument_doctype.js";
import "./classes/ioi_price_updating_tool.js";
import "./classes/ioi_sales_to_purchases.js";
import "./classes/ioi_sales_pos.js";
import "./classes/ioi_cash_desk.js";
import "./classes/ioi_eshop.js";
import "./classes/ioi_call.js";
import "./classes/ioi_list.js";
import "./classes/ioi_doctype_grid.js";
import "./classes/ioi_receipt_preview.js";
import "./classes/ioi_report_methods.js";
import "./classes/ioi_payconiq.js";
import "./classes/ioi_ccv.js";
import "./classes/ioi_labelling.js";
import "./scanning/scanning.js";
import "./scanning/android_scanning.js";
import "./frames/ioi_item_stock_frame.js"
import "./frames/ioi_item_price_frame.js"
import "./frames/ioi_location_frame.js"
import "./frames/ioi_batchsn_frame.js"
import "./frames/ioi_time_vector_frame.js"
import "./frames/ioi_time_vector_frame_sub.js"
import "./frames/ioi_bom_definition_frame.js"
import "./frames/ioi_bom_explore_frame.js"
import "./frames/ioi_item_where_used_in_bom_frame.js"
import "./frames/ioi_time_vector_summary.js"
import "./frames/ioi_routing_definition_frame.js"
import "./frames/ioi_manufacturing_definition_frame.js"
import "./frames/ioi_jit_engine_link_frame.js"
import "./frames/ioi_jit_engine_frame.js"
import "./frames/ioi_global_explorer.js"
import "./frames/ioi_stock_inventory_generation_frame.js"
import "./epson/ioi_epos_device.js"
import "./epson/ioi_epos_display.js"
import "./epson/ioi_epos_connect.js"
import "./ioi_form.js";
import "./ioi_print_dialog.js";
import "./ioi_list_view.js";
import "./ioi_map_view.js";
import "./ioi_report_view.js";
import "./ioi_table.js";
import "./document_structure";
import "./code.js";
import "./ioi_attachments.js";
import "./views/ioi_calendar.js";
import "./views/ioi_kanban.js";
import "./views/ioi_kanban_column.html";
import "./ioi_email_sandboxing_render.js";
import "./views/ioi_resource_dropdown.html";
import "./views/ioi_profile_dropdown.html";
import "./views/ioi_resource_view.js";
import "./ioi_metabase_dashboard";
import "./ioi_metabase_workspace";
import "./ioi_metabase_view";
import "./views/ioi_list_view_select.js";
import "./ioi_theme_switcher.js";
import "./ioi_tabulator.js"
import "./ioi_expert_view.js";
import "./ioi_actions.js"
import "./ioi_page.js"
import "./ioi_model.js"
import "./ioi_bulk_update.js"
import "./gantt/dlhsoft_lib.js"
import "./gantt/ioi_gantt.js"
import "./gantt/ioi_load_chart.js"
import "./gantt/ioi_gantt_control.js"
import "./gantt/ioi_schedule_chart.js"
import "./ioi_breadcrumbs.js"
import "./ioi_navbar.js"
import "./ioi_scanning.js"
import "./frames/ioi_dossier_frames/ioi_clocking_frame.js"
import "./frames/ioi_dossier_frames/ioi_good_purch_whs_detail_frame.js"
import "./frames/ioi_dossier_frames/ioi_good_purch_whs_global_frame.js"
import "./frames/ioi_dossier_frames/ioi_good_sales_global_frame.js"
import "./frames/ioi_dossier_frames/ioi_good_sales_detail_frame.js"
import "./frames/ioi_dossier_frames/ioi_wms_dedicated_location.js"
import "./ioi_pivot_expert_view.js"
import "./ioi_data_import_export.js"
import "./frames/ioi_park_frame.js"

function load_epson_lib(){
	return new Promise((resolve) => {
		var asset_dir = "assets/silicon_ioi/js/epson/";
		frappe.require(
			[
				asset_dir + "epos-2.24.0.js"
			],
			resolve
		);
	});
}

ioi.reporting.quill = Quill;

load_epson_lib().then(()=>{
	ioi.epson = epson;
})
