frappe.provide("frappe.views");
const ioi_pd = require("./ioi_print_dialog.js") 

export default class ioiReportView extends frappe.views.ReportView {
	setup_page_head() {
		super.setup_page_head();
		this.add_print_btn();
	}

	add_print_btn(){
		this.page.set_secondary_action("", () => {
			ioi_pd.ioi_print_dialog();
		}, { icon: "printer", size: "sm" });
	}
}

frappe.views.ReportView = ioiReportView;