frappe.provide('frappe.ui.misc');

$(document).ready(() => {


frappe.ui.misc.about = function() {
	if(!frappe.ui.misc.about_dialog) {
		var d = new frappe.ui.Dialog({title: 'silicon ioi'});

		$(d.body).html(repl("<div>\
		<img class=\"about-logo\" src=\"/assets/silicon_ioi/images/logo_800x800.png\">\
		<p></p>\
		<hr>\
		<div id='about-app-versions'>Loading versions...</div>\
		<hr>\
		<p class='text-muted'>&copy; Silicon Brain NV/SA</p> \
		</div>", frappe.app));

		frappe.ui.misc.about_dialog = d;

		frappe.ui.misc.about_dialog.on_page_show = function() {
			if(!frappe.versions) {
				frappe.call({
					method: "frappe.utils.change_log.get_versions",
					callback: function(r) {
						show_versions(r.message);
					}
				})
			} else {
				show_versions(frappe.versions);
			}
		};

		var show_versions = function(versions) {
			let reportBro_backend;
			let reportBro_frontend;
			frappe.call({
				method: "silicon_ioi.ioi_system.doctype.ioi_report.ioi_report.get_reportbro_versions",
				args: {},
				async: false,
				callback: function(r) {
					reportBro_backend = r.message.backend;
					reportBro_frontend = r.message.frontend;
				}
			});

			versions["reporting-core"] = {
				description: "ReportBro backend",
				title: "Reporting Core",
				version: `${reportBro_backend}`
			};
			versions["reporting-designer"] = {
				description: "ReportBro frontend",
				title: "Reporting Designer",
				version: `${reportBro_frontend}`
			};

			let tabulator = require('silicon_ioi/public/node_modules/tabulator-tables/package.json')
			versions["tabulator"] = {
				description: "Tabulator grid",
				title: "Tabulator",
				version: `${tabulator.version}`
			};

			let gantt_chart = require('silicon_ioi/public/node_modules/@dlhsoft/ganttcharthyperlibrary/package.json')
			versions["gantt_chart"] = {
				description: "Gantt Chart Hyper",
				title: "Gantt Chart",
				version: `${gantt_chart.version}`
			};

			for (const [key, value] of Object.entries(versions)) {
				if (value?.version == '0.0'){

					frappe.call({
						method: `silicon_ioi.utils.lib.system.get_last_commit_datetime`,
						args: {
							app_name: key
						},
						async: false,
						silent: true,
						callback: function(r) {
							versions[key].version = r.message;
						},
						error: function(r){
							console.log(`Get version failed for ${key} app`)
						}
					});
				}
			}
			versions.silicon_ioi.title = "Silicon ioi";

			var $wrap = $("#about-app-versions").empty();
			$.each(Object.keys(versions).sort(), function(i, key) {
				var v = versions[key];
				if(v.branch) {
					var text = $.format('<p><b>{0}:</b> {1} ({2})<br></p>',
						[v.title, v.branch_version || v.version, v.branch])
				} else {
					var text = $.format('<p><b>{0}:</b> {1}<br></p>',
						[v.title, v.version])
				}
				$(text).appendTo($wrap);
			});

			frappe.versions = versions;
		}

	}

	frappe.ui.misc.about_dialog.show();

}
});
