frappe.provide('silicon_ioi.doctype');

export class ioiClockingFrame {
	static this
	static clocking_id
	static description
	static path

	static build_panel(tab) {
		let panel = '';

		panel += '<div class="d-flex flex-column">';

		panel += '	<div class="d-flex w-100 flex-row flex-wrap mb-2">';

		// Clocking Search
		panel += `	<div class="mr-2" style="width:280px;">
						<label id="${tab}_search_label" style="">${__("Search")}</label>
						<div class="control-input" style="width: 280px;">
							<input id="${tab}_search" type="text" class="input-with-feedback form-control bold">
						</div>
					</div>`

		// Up to date
		panel += `	<div class="mr-2" style="width:130px;">
						<label id="${tab}_up_to_date_label" style="">${__("Up to date")}</label>
						<div class="control-input"> 
							<input id="${tab}_up_to_date" type="date" class="input-with-feedback form-control bold">
						</div>
					</div>`

		// Topn
		panel += `	<div class="mr-3" style="width:90px;">
						<label id="${tab}_topn_label" style="">${__("No records")}</label>
						<div class="control-input"> 
							<input id="${tab}_topn" type="number" class="input-with-feedback form-control bold" min="1" max="1000" value="100">
						</div>
					</div>`

		panel += '	</div>'

		panel += `	<div class="d-flex w-100 flex-row mb-2 flex-wrap" style="height: 28px;">
						<button id="${tab}_bt_search" title="${__("Search")}" data-label="Search" class="btn btn-xs btn-default ellipsis mr-2" style="width: 116px;" onclick="">${__("Search")}</button>
						<button id="${tab}_bt_clear" title="${__("...")}" data-label="Search" class="btn btn-xs btn-default ellipsis mr-2" style="width: 50px;" onclick="">${__("...")}</button>
						<button id="${tab}_bt_export" title="${__("Export")}" data-label="Export" class="btn btn-xs btn-default ellipsis mr-2" style="width: 100px;" onclick="">${__("Export")}</button>
					</div>`

		panel += `	<input id="${tab}_orderby" type="hidden" value="name">`;
		panel += `	<input id="${tab}_order" type="hidden" value="asc">`;
		panel += '</div>';

		return panel
	}

	static show_clocking_label(tab_name) {
		if (document.getElementById(`${tab_name}_desc_label`)) {
			document.getElementById(`${tab_name}_desc_label`).remove();
		}

		if (document.getElementById(`${tab_name}_clocking_label`)) {
			document.getElementById(`${tab_name}_clocking_label`).remove();
		}

		let html = '';

		let doctype = silicon_ioi.doctype.ioiClockingFrame.this.frm.doctype
		if (doctype === 'ioi Dossier History') doctype = 'ioi Dossier'

		let clocking_label = `${silicon_ioi.doctype.ioiClockingFrame.clocking_id}`
		if (!!silicon_ioi.doctype.ioiClockingFrame.description) clocking_label += ` - ${silicon_ioi.doctype.ioiClockingFrame.description}`

		html += '<div class="d-flex flex-column">';
		html += `	<label id="${tab_name}_desc_label">${__(doctype)}</label>`
		html += `	<label id="${tab_name}_clocking_label">${clocking_label}</label>`
		html += '</div>';

		return html
	}

	static build_clocking() {
		let clocking_tabs = [
			{ tab_name: 'clocking', dt: 'ioi Clocking Reg' },
		]

		clocking_tabs.map(tab => {
			const container = `
				<div id="html_clocking_content" class="w-100">
					<div class="d-flex flex-row flex-wrap">
						${silicon_ioi.doctype.ioiClockingFrame.build_panel(tab.tab_name)}
						${silicon_ioi.doctype.ioiClockingFrame.show_clocking_label(tab.tab_name)}
					</div>
					<div id="${tab.tab_name}_grid" class="table table-bordered" data-custom-grid="true"></div>
				</div>`

			const clocking_content = $('#html_clocking_content')[0]

			if (clocking_content) clocking_content.remove()
			silicon_ioi.doctype.ioiClockingFrame.this.frm.fields_dict[`html_${tab.tab_name}`].$wrapper.append(container);

			silicon_ioi.doctype.ioiClockingFrame.build_clocking_grid(tab.tab_name, tab.dt)
		})
	}

	static build_clocking_grid(tab, dt) {
		let format_link = (cell, formatterParams) => {
			if (cell.getValue() != null && cell.getValue() != "") {
				let html = `<span style="cursor: pointer; text-decoration: underline;">${cell.getValue()}</span>`
				return html
			}
		}

		let link_onclick = (e, cell) => {
			if (cell.getValue() != null && cell.getValue() != "") {
				let url = ""

				if (cell.getColumn().getField() == "sales_invoice") {
					let name = cell.getData().sales_invoice
					url = `/app/ioi-sales-invoice/${name}`
				}

				window.open(url, "_blank")
			}
		}

		let format_date = (cell) => {
			if (cell.getValue() != null) {
				let format_date = cell.getValue().substring(0, 16)
				return format_date
			}
		}

		let columns = [
			{ title: __("Date"), field: "reg_date", formatter: format_date },
			{ title: __("Operator"), field: "operator_id" },
			{ title: __("Work duration"), field: "work_duration", bottomCalc: "sum" },
			{ title: __("Workcenter"), field: "workcenter_id" },
			{ title: __("Initials"), field: "initials" },
			{ title: __("First name"), field: "first_name" },
			{ title: __("Last name"), field: "last_name" },
			{ title: __("Start time"), field: "start_time", formatter: format_date },
			{ title: __("End time"), field: "end_time", formatter: format_date },
			{ title: __("Cumultative pause"), field: "pause_duration", bottomCalc: "sum" },
			{ title: __("Work center duration"), field: "workcenter_work_duration", bottomCalc: "sum" },
			{ title: __("Sale invoice"), field: "sales_invoice", formatter: format_link, cellClick: link_onclick },
			{ title: __("Invoice period"), field: "periode_inv" },
			{ title: __("Not invoicable"), field: "not_invoicable_duration" },
			{ title: __("Invoicable Duration"), field: "invoicable_duration" },
			{ title: __("Operator cost corrected"), field: "operator_cost_corrected" },
			{ title: __("Workcenter cost corrected"), field: "work_cost_corrected" },
			{ title: __("Operator cost factor"), field: "operator_cost_factor" },
			{ title: __("Operator cost"), field: "operator_cost" },
			{ title: __("Operator cost add value"), field: "operator_cost_add_value" },
			{ title: __("Workcenter cost factor"), field: "work_cost_factor" },
			{ title: __("Workcenter cost"), field: "work_cost" },
			{ title: __("Workcenter cost add value"), field: "work_cost_add_value" },
		]

		if (silicon_ioi.doctype.ioiClockingFrame.this.frm.doctype.includes('ioi Dossier')) {
			columns.splice(4, 0, { title: __("Step"), field: "step_id" })
		}

		if (silicon_ioi.doctype.ioiClockingFrame.this.frm.doctype.includes('ioi Sales Order')) {
			columns.splice(4, 0, { title: __("Detail line number"), field: "idx" })
		}

		let table = new ioi.Tabulator(`#${tab}_grid`, {
			maxHeight: 800,
			data: [],
			layout: "fitColumns",
			movableColumns: true,
			resizableColumns: true,
			showProfiles: true,
			autoRedraw: true,
			initialSort: [
				{ column: "reg_date", dir: "asc" },
			],
			columns: columns,
			columnDefaults: {
				minWidth: 100
			}
		});

		table.on("tableBuilt", () => {
			table.footerManager.element.setAttribute('data-custom-grid-bottom', 'true')
		});

		table.on("headerClick", function (e, column) {
			let ordered_field = document.getElementById(`${tab}_orderby`)
			ordered_field.value = column.getField()

			let ordered_dir = document.getElementById(`${tab}_order`)
			ordered_dir.value = table.getSorters()[0].dir

			silicon_ioi.doctype.ioiClockingFrame.col_click(tab)
		});

		silicon_ioi.doctype.ioiClockingFrame.clocking_grid_search(table, dt, tab)

		document.getElementById(`${tab}_bt_search`).onclick = () => silicon_ioi.doctype.ioiClockingFrame.clocking_grid_search(table, dt, tab)
		document.getElementById(`${tab}_bt_export`).onclick = () => silicon_ioi.doctype.ioiClockingFrame.export_data(table, tab)
		document.getElementById(`${tab}_bt_clear`).onclick = () => {
			document.getElementById(`${tab}_search`).value = ''
			document.getElementById(`${tab}_topn`).value = 100
			table.clearData()
		}
		document.getElementById(`${tab}_up_to_date`).onchange = () => silicon_ioi.doctype.ioiClockingFrame.clocking_grid_search(table, dt, tab)

		document.getElementById('clocking_search').addEventListener("keypress", function(event) {
			if (event.key === "Enter") {
				event.preventDefault();
				silicon_ioi.doctype.ioiClockingFrame.clocking_grid_search(table, dt, tab)
			}
		});

		document.getElementById('clocking_topn').addEventListener("keypress", function(event) {
			if (event.key === "Enter") {
				event.preventDefault();
				silicon_ioi.doctype.ioiClockingFrame.clocking_grid_search(table, dt, tab)
			}
		});
	}

	static col_click(tab) {
		if (document.getElementById(`${tab}_orderby`).value == 'name') {
			document.getElementById(`${tab}_orderby`).value = 'a.name';
		}

		document.getElementById(`${tab}_bt_search`).click();
	}

	static clocking_grid_search(table, dt, tab) {

		frappe.call({
			method: silicon_ioi.doctype.ioiClockingFrame.path,
			args: {
				doctype: dt,
				clocking_id: silicon_ioi.doctype.ioiClockingFrame.clocking_id,
				search: document.getElementById(`${tab}_search`).value,
				topn: document.getElementById(`${tab}_topn`).value,
				orderby: document.getElementById(`${tab}_orderby`).value,
				order: document.getElementById(`${tab}_order`).value,
				uptodate: document.getElementById(`${tab}_up_to_date`).value,
			},
			async: false,
			callback: function (r) {
				if (r.message.length > 0) {
					if (table.initialized) {
						table.setData(r.message)
					} else {
						table.on('tableBuilt', () => table.setData(r.message))
					}
				} else {
					if (table && table.initialized) table.clearData()
				}
			}
		})
	}

	static export_data(table, default_filename)
	{
		let export_tabulator = () => {
			let filename = document.getElementById('export_filename').value;
			let filetype = document.getElementById('export_file_type').value;

			if (filename.trim() == '') {
				filename = default_filename;
			}

			if (filetype.toUpperCase() == 'XLSX') {
				table.download("xlsx", `${filename}.xlsx`);
			} else {
				table.download("csv", `${filename}.csv`, { delimiter: ";" })
			}
		}

 		silicon_ioi.ioiCommon.export_form(export_tabulator, true);
	}
}

silicon_ioi.doctype.ioiClockingFrame = ioiClockingFrame;