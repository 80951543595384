function load_dlhsoft_lib(){
	return new Promise((resolve) => {
		var asset_dir = "assets/silicon_ioi/node_modules/@dlhsoft/ganttcharthyperlibrary/";
		frappe.require(
			[
				asset_dir + "DlhSoft.ProjectData.GanttChart.HTML.Controls.js",
				asset_dir + "DlhSoft.Data.HTML.Controls.js",
				asset_dir + "DlhSoft.ProjectData.GanttChart.HTML.Controls.Extras.js",
			],
			resolve
		);
	});
}

load_dlhsoft_lib().then(() => {
	DlhSoft.Licensing.setLicense("DlhSoft.Controls.Calendar", "Silicon Brain NV/SA:FC88B660:93F87488:1766613600000:1DB026E2");
	DlhSoft.Licensing.setLicense("DlhSoft.Controls.DatePicker", "Silicon Brain NV/SA:FC88B660:93F87488:1766613600000:72AABC80");
	DlhSoft.Licensing.setLicense("DlhSoft.Controls.MultiSelectorComboBox", "Silicon Brain NV/SA:FC88B660:93F87488:1766613600000:107DFCF6F");
	DlhSoft.Licensing.setLicense("DlhSoft.Controls.TimePicker", "Silicon Brain NV/SA:FC88B660:93F87488:1766613600000:17DC2F3A1");

	DlhSoft.Licensing.setLicense("DlhSoft.Controls.GanttChartView", "Silicon Brain NV/SA:FC88B660:93F87488:1766613600000:3D54E53");
	DlhSoft.Licensing.setLicense("DlhSoft.Controls.LoadChartView", "Silicon Brain NV/SA:FC88B660:93F87488:1766613600000:7CE56039");
	DlhSoft.Licensing.setLicense("DlhSoft.Controls.ScheduleChartView", "Silicon Brain NV/SA:FC88B660:93F87488:1766613600000:43BE9BA8");

	DlhSoft.Licensing.setLicense("DlhSoft.Controls.Pert.NetworkDiagramView", "Silicon Brain NV/SA:FC88B660:93F87488:1766613600000:1DB84B5D");
	DlhSoft.Licensing.setLicense("DlhSoft.Controls.Pert.PertChartView", "Silicon Brain NV/SA:FC88B660:93F87488:1766613600000:7D874A39");

	ioi.DlhSoft = DlhSoft;
})
