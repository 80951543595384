frappe.provide('silicon_ioi');

export class ioiPriceUpdatingTool {

	static bgcolor = '';

	static current_family = '';
	static current_type = '';
	static current_group = '';
	static current_subgroup = '';

	static item_family = [];

	static is_dark_mode = 0;

    // ***************************************************************************************************************************************
	// 
	// Purchases Price : Price updating tool
    //
	// ***************************************************************************************************************************************

	// ***************************************************************************************************************************************
	// Load purchases prices tool
	// ***************************************************************************************************************************************
	static load_update_purchases_prices(html_field)
	{
		silicon_ioi.doctype.ioiPriceUpdatingTool.is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

		silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_purchases_prices(html_field);
	}



	// ***************************************************************************************************************************************
	// Do Update purchases prices
	// ***************************************************************************************************************************************

	static do_update_purchases_prices(html_field)	
	{
		if (document.getElementById("update_purchases_supplier_label"))
		{	document.getElementById("update_purchases_supplier_label").remove();
		}

		if (document.getElementById("update_purchases_supplier_id"))
		{	document.getElementById("update_purchases_supplier_id").remove();
		}

		if (document.getElementById("update_purchases_select_supplier_button"))
		{	document.getElementById("update_purchases_select_supplier_button").remove();
		}


		if (document.getElementById("update_purchases_purchases_condition_label"))
		{	document.getElementById("update_purchases_purchases_condition_label").remove();
		}

		if (document.getElementById("update_purchases_purchases_condition_id"))
		{	document.getElementById("update_purchases_purchases_condition_id").remove();
		}

		if (document.getElementById("update_purchases_item_label"))
		{	document.getElementById("update_purchases_item_label").remove();
		}

		if (document.getElementById("update_purchases_item_id"))
		{	document.getElementById("update_purchases_item_id").remove();
		}

		if (document.getElementById("update_purchases_select_item_button"))
		{	document.getElementById("update_purchases_select_item_button").remove();
		}


		if (document.getElementById("update_purchases_unit_label"))
		{	document.getElementById("update_purchases_unit_label").remove();
		}

		if (document.getElementById("update_purchases_unit_id"))
		{	document.getElementById("update_purchases_unit_id").remove();
		}


		if (document.getElementById("update_purchases_currency_label"))
		{	document.getElementById("update_purchases_currency_label").remove();
		}

		if (document.getElementById("update_purchases_currency_id"))
		{	document.getElementById("update_purchases_currency_id").remove();
		}

		if (document.getElementById("update_purchases_select_currency_button"))
		{	document.getElementById("update_purchases_select_currency_button").remove();
		}




		if (document.getElementById("update_purchases_site_label"))
		{	document.getElementById("update_purchases_site_label").remove();
		}

		if (document.getElementById("update_purchases_site_id"))
		{	document.getElementById("update_purchases_site_id").remove();
		}

		if (document.getElementById("update_purchases_enabled_label"))
		{	document.getElementById("update_purchases_enabled_label").remove();
		}

		if (document.getElementById("update_purchases_enabled"))
		{	document.getElementById("update_purchases_enabled").remove();
		}


		if (document.getElementById("update_purchases_family_label"))
		{	document.getElementById("update_purchases_family_label").remove();
		}

		if (document.getElementById("update_purchases_family_id"))
		{	document.getElementById("update_purchases_family_id").remove();
		}
		
		if (document.getElementById("update_purchases_type_label"))
		{	document.getElementById("update_purchases_type_label").remove();
		}

		if (document.getElementById("update_purchases_type_id"))
		{	document.getElementById("update_purchases_type_id").remove();
		}
		
		if (document.getElementById("update_purchases_group_label"))
		{	document.getElementById("update_purchases_group_label").remove();
		}

		if (document.getElementById("update_purchases_group_id"))
		{	document.getElementById("update_purchases_group_id").remove();
		}
		
		if (document.getElementById("update_purchases_subgroup_label"))
		{	document.getElementById("update_purchases_subgroup_label").remove();
		}

		if (document.getElementById("update_purchases_subgroup_id"))
		{	document.getElementById("update_purchases_subgroup_id").remove();
		}
		
		if (document.getElementById("update_purchases_classification_label"))
		{	document.getElementById("update_purchases_classification_label").remove();
		}

		if (document.getElementById("update_purchases_classification_id"))
		{	document.getElementById("update_purchases_classification_id").remove();
		}
		
		
		if (document.getElementById("update_purchases_shop_category_label"))
		{	document.getElementById("update_purchases_shop_category_label").remove();
		}

		if (document.getElementById("update_purchases_shop_category_id"))
		{	document.getElementById("update_purchases_shop_category_id").remove();
		}

		if (document.getElementById("update_purchases_grid_banner"))
		{	document.getElementById("update_purchases_grid_banner").remove();
		}

		if (document.getElementById("update_purchases_grid"))
		{	document.getElementById("update_purchases_grid").remove();
		}

		if (document.getElementById("update_purchases_check_all_none")) {
			document.getElementById("update_purchases_check_all_none").remove();
		}

		if (document.getElementById("update_purchases_detail")) {
			
			for (var i = 0; i < document.getElementById("update_purchases_detail").rows.length; i++)
			{
				if (document.getElementById("update_purchases_checked_id_" + i.toString()))	{
					document.getElementById("update_purchases_checked_id_" + i.toString()).remove();
				}
			}

			document.getElementById("update_purchases_detail").remove();
		}


		if (document.getElementById("update_purchases_content")) {
			document.getElementById("update_purchases_content").remove();
		}

		
		if (document.getElementById("update_purchases_search"))
		{	document.getElementById("update_purchases_search").remove();
		}

		if (document.getElementById("update_purchases_clear"))
		{	document.getElementById("update_purchases_clear").remove();
		}

		if (document.getElementById("update_purchases_orderby"))
		{	document.getElementById("update_purchases_orderby").remove();
		}

		if (document.getElementById("update_purchases_order"))
		{	document.getElementById("update_purchases_order").remove();
		}


		if (document.getElementById("update_purchases_cb_increase"))
		{	document.getElementById("update_purchases_cb_increase").remove();
		}


		if (document.getElementById("update_purchases_increase_unit_price_label"))
		{	document.getElementById("update_purchases_increase_unit_price_label").remove();
		}

		if (document.getElementById("update_purchases_increase_unit_price"))
		{	document.getElementById("update_purchases_increase_unit_price").remove();
		}

		if (document.getElementById("update_purchases_increase_unit_price_percent"))
		{	document.getElementById("update_purchases_increase_unit_price_percent").remove();
		}


		if (document.getElementById("update_purchases_cb_fixed_price"))
		{	document.getElementById("update_purchases_cb_fixed_price").remove();
		}


		if (document.getElementById("update_purchases_update_fixed_price_label"))
		{	document.getElementById("update_purchases_update_fixed_price_label").remove();
		}

		if (document.getElementById("update_purchases_update_fixed_price"))
		{	document.getElementById("update_purchases_update_fixed_price").remove();
		}


		if (document.getElementById("update_purchases_cb_base_discount"))
		{	document.getElementById("update_purchases_cb_base_discount").remove();
		}

		if (document.getElementById("update_purchases_update_base_discount_label"))
		{	document.getElementById("update_purchases_update_base_discount_label").remove();
		}

		if (document.getElementById("update_purchases_update_base_discount"))
		{	document.getElementById("update_purchases_update_base_discount").remove();
		}

		if (document.getElementById("update_purchases_update_base_discount_percent"))
		{	document.getElementById("update_purchases_update_base_discount_percent").remove();
		}

		if (document.getElementById("update_purchases_cb_extra_discount"))
		{	document.getElementById("update_purchases_cb_extra_discount").remove();
		}

		if (document.getElementById("update_purchases_update_extra_discount_label"))
		{	document.getElementById("update_purchases_update_extra_discount_label").remove();
		}

		if (document.getElementById("update_purchases_update_extra_discount"))
		{	document.getElementById("update_purchases_update_extra_discount").remove();
		}

		if (document.getElementById("update_purchases_update_extra_discount_percent"))
		{	document.getElementById("update_purchases_update_extra_discount_percent").remove();
		}


		if (document.getElementById("update_purchases_update_rounding_kind_label"))
		{	document.getElementById("update_purchases_update_rounding_kind_label").remove();
		}

		if (document.getElementById("update_purchases_update_rounding_kind"))
		{	document.getElementById("update_purchases_update_rounding_kind").remove();
		}

		if (document.getElementById("update_purchases_update_rounding_method_label"))
		{	document.getElementById("update_purchases_update_rounding_method_label").remove();
		}

		if (document.getElementById("update_purchases_update_rounding_method"))
		{	document.getElementById("update_purchases_update_rounding_method").remove();
		}


		if (document.getElementById("update_purchases_update_rounding_value_label"))
		{	document.getElementById("update_purchases_update_rounding_value_label").remove();
		}

		if (document.getElementById("update_purchases_update_rounding_value"))
		{	document.getElementById("update_purchases_update_rounding_value").remove();
		}

		if (document.getElementById("update_purchases_bt_clear_values"))
		{	document.getElementById("update_purchases_bt_clear_values").remove();
		}


		if (document.getElementById("update_purchases_cb_convert"))
		{	document.getElementById("update_purchases_cb_convert").remove();
		}

		if (document.getElementById("update_purchases_convert_label"))
		{	document.getElementById("update_purchases_convert_label").remove();
		}

		if (document.getElementById("update_purchases_old_currency_label"))
		{	document.getElementById("update_purchases_old_currency_label").remove();
		}

		if (document.getElementById("update_purchases_old_currency"))
		{	document.getElementById("update_purchases_old_currency").remove();
		}

		if (document.getElementById("update_purchases_old_currency_select_currency_button"))
		{	document.getElementById("update_purchases_old_currency_select_currency_button").remove();
		}


		if (document.getElementById("update_purchases_new_currency_label"))
		{	document.getElementById("update_purchases_new_currency_label").remove();
		}

		if (document.getElementById("update_purchases_new_currency"))
		{	document.getElementById("update_purchases_new_currency").remove();
		}

		if (document.getElementById("update_purchases_new_currency_select_currency_button"))
		{	document.getElementById("update_purchases_new_currency_select_currency_button").remove();
		}


		if (document.getElementById("update_purchases_currency_comment1"))
		{	document.getElementById("update_purchases_currency_comment1").remove();
		}

		if (document.getElementById("update_purchases_currency_comment2"))
		{	document.getElementById("update_purchases_currency_comment2").remove();
		}

		if (document.getElementById("update_purchases_bt_update_price"))
		{	document.getElementById("update_purchases_bt_update_price").remove();
		}

		if (document.getElementById("update_purchases_set_enabled"))
		{	document.getElementById("update_purchases_set_enabled").remove();
		}

		if (document.getElementById("update_purchases_set_disabled"))
		{	document.getElementById("update_purchases_set_disabled").remove();
		}

		if (document.getElementById("update_purchases_delete_disabled"))
		{	document.getElementById("update_purchases_delete_disabled").remove();
		}


		if (document.getElementById("update_purchases_warning1"))
		{	document.getElementById("update_purchases_warning1").remove();
		}


		let html = '';

		html += '<div style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '	<label style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Filters") + '</b></label>';			
		html += '</div>'

		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'

		html += '<div style="overflow: auto; overflow-x: auto; height:174px;">';		


		// Supplier
		html += '	<div style="position: relative; top: 0px; left: 0px; width:180px;">';
		html += '		<label id="update_purchases_supplier_label" style="position: absolute; top: 0px; left: 2px;">' + __("Supplier") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 130px; height: 25px;"> ';
		html += '			<input id="update_purchases_supplier_id" type="text" class="input-with-feedback form-control bold">';
		html += '		</div>';
		html += '		<div  style="position: absolute; top: 25px; left: 142px; width: 130px; height: 25px;"> ';		
		html +='			<button id="update_purchases_select_supplier_button" style="height: 30px; width: 30px;border: none; outline:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Purchases Condition
		html += '<div style="position: relative; top: 0px; left: 180px; width:170px;">';
		html += '	<label id="update_purchases_purchases_condition_label" style="position: absolute; top: 0px; left: 2px;">' + __("Purchases condition") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '		<select id="update_purchases_purchases_condition_id" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';

		let method = 'silicon_ioi.ioi_items.doctype.ioi_purchases_price.ioi_purchases_price.ioi_purchases_prices_get_purchases_condition'
		frappe.call({  	method: method, 
						args: {},
						async: false,
						callback:function(r)	{ 
													for (var i = 0; i < r.message.length; i++)
													{
														html += '		<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
													}
												}
		});
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';


		// Item
		html += '	<div style="position: relative; top: 0px; left: 360px; width:350px;">';
		html += '		<label id="update_purchases_item_label" style="position: absolute; top: 0px; left: 2px;">' + __("Item") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 310px; height: 25px;"> ';
		html += '			<input id="update_purchases_item_id" type="text" class="input-with-feedback form-control bold">';
		html += '		</div>';
		html += '		<div  style="position: absolute; top: 25px; left: 322px; width: 130px; height: 25px;"> ';		
		html +='			<button id="update_purchases_select_item_button" style="height: 30px; width: 30px;border: none; outline:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Unit
		html += '<div style="position: relative; top: 0px; left: 720px; width:170px;">';
		html += '	<label id="update_purchases_unit_label" style="position: absolute; top: 0px; left: 2px;">' + __("Unit") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '		<select id="update_purchases_unit_id" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';

		method = 'silicon_ioi.ioi_items.doctype.ioi_purchases_price.ioi_purchases_price.ioi_purchases_prices_get_unit'
		frappe.call({  	method: method, 
						args: {},
						async: false,
						callback:function(r)	{ 
													for (var i = 0; i < r.message.length; i++)
													{
														html += '		<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
													}
												}
		});
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';


		// Currency
		html += '	<div style="position: relative; top: 0px; left: 900px; width:170px;">';
		html += '		<label id="update_purchases_currency_label" style="position: absolute; top: 0px; left: 2px;">' + __("Currency") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 130px; height: 25px;"> ';
		html += '			<input id="update_purchases_currency_id" type="text" class="input-with-feedback form-control bold">';
		html += '		</div>';
		html += '		<div  style="position: absolute; top: 25px; left: 142px; width: 130px; height: 25px;"> ';		
		html +='			<button id="update_purchases_select_currency_button" style="height: 30px; width: 30px;border: none; outline:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';


		// Site
		html += '<div style="position: relative; top: 0px; left: 1080px; width:120px;">';
		html += '	<label id="update_purchases_site_label" style="position: absolute; top: 0px; left: 2px;">' + __("Site") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 120px; height: 30px;"> ';
		html += '		<select id="update_purchases_site_id" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';

		method = 'silicon_ioi.ioi_items.doctype.ioi_purchases_price.ioi_purchases_price.ioi_purchases_prices_get_site'
		frappe.call({  	method: method, 
						args: {},
						async: false,
						callback:function(r)	{ 
													for (var i = 0; i < r.message.length; i++)
													{
														html += '		<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
													}
												}
		});
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';



		// Enabled
		html += '<div style="position: relative; top: 64px; left: 1080px; width:120px;">';
		html += '	<label id="update_purchases_enabled_label" style="position: absolute; top: 0px; left: 2px;">' + __("Status") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 120px; height: 30px;"> ';
		html += '		<select id="update_purchases_enabled" class="input-with-feedback form-control bold"> ';
		html += '			<option value="">All</option> ';
		html += '			<option value="0">' + __("Disabled") + '</option> ';
		html += '			<option value="1">' + __("Enabled") + '</option> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';

		// Item Family
		html += '<div style="position: relative; top: 64px; left: 0px; width:170px;">';
		html += '	<label id="update_purchases_family_label" style="position: absolute; top: 0px; left: 2px;">' + __("Family") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '		<select id="update_purchases_family_id" class="input-with-feedback form-control bold"> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';

		// Item Type
		html += '<div style="position: relative; top: 64px; left: 180px; width:170px;">';
		html += '	<label id="update_purchases_type_label" style="position: absolute; top: 0px; left: 2px;">' + __("Type") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '		<select id="update_purchases_type_id" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';

		// Item Group
		html += '<div style="position: relative; top: 64px; left: 360px; width:170px;">';
		html += '	<label id="update_purchases_group_label" style="position: absolute; top: 0px; left: 2px;">' + __("Group") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '		<select id="update_purchases_group_id" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';

		// Item Sub Group
		html += '<div style="position: relative; top: 64px; left: 540px; width:170px;">';
		html += '	<label id="update_purchases_subgroup_label" style="position: absolute; top: 0px; left: 2px;">' + __("Sub group") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '		<select id="update_purchases_subgroup_id" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';

		// Item Classification
		html += '<div style="position: relative; top: 64px; left: 720px; width:170px;">';
		html += '	<label id="update_purchases_classification_label" style="position: absolute; top: 0px; left: 2px;">' + __("Classification") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '		<select id="update_purchases_classification_id" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';

		method = 'silicon_ioi.ioi_items.doctype.ioi_purchases_price.ioi_purchases_price.ioi_purchases_prices_get_classification'
		frappe.call({  	method: method, 
						args: {},
						async: false,
						callback:function(r)	{ 
													for (var i = 0; i < r.message.length; i++)
													{
														html += '		<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
													}
												}
		});

		html += '		</select> ';
		html += '	</div>';
		html += '</div>';


		// Item Shop Category
		html += '<div style="position: relative; top: 64px; left: 900px; width:170px;">';
		html += '	<label id="update_purchases_shop_category_label" style="position: absolute; top: 0px; left: 2px;">' + __("Shop Category") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '		<select id="update_purchases_shop_category_id" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';

		method = 'silicon_ioi.ioi_items.doctype.ioi_purchases_price.ioi_purchases_price.ioi_purchases_prices_get_shop_category'
		frappe.call({  	method: method, 
						args: {},
						async: false,
						callback:function(r)	{ 
													for (var i = 0; i < r.message.length; i++)
													{
														html += '		<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
													}
												}
		});


		html += '		</select> ';
		html += '	</div>';
		html += '</div>';


		// Search
		html += '<div style="position: relative; top: 132px; left: 0px; width:110px;">';		
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='		<button id="update_purchases_search" class="btn btn-default ellipsis" style="height: 30px; width: 110px;" onclick="">' + __("Search") + '</button>';
		html += '	</div>';
		html += '</div>';

		// Clear
		html += '<div style="position: relative; top: 132px; left: 120px; width:50px;">';		
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 30px;">';
		html +='		<button id="update_purchases_clear" title="' + __("Clear") +'" data-label="Search" class="btn btn-default ellipsis" style="height: 30px; width: 50px;" onclick="">' + __("...") + '</button>';
		html += '	</div>';
		html += '</div>';

		html += '<div style="position: relative; top: 132px; left: 180px; height:30px; width:600px; ">';
		html += '	<label id="update_purchases_grid_banner" style="position: relative; top: 6px; left: 6px;"></label>';			
		html += '</div>';


		html += '<input id="update_purchases_orderby" type="hidden" value="name">';
		html += '<input id="update_purchases_order" type="hidden" value="asc">';


		html += '</div>';
		
		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>';



		// Grid Header
		html += '<div id="update_purchases_content" style="overflow: auto; overflow-x: auto; height:392px;">';		

		html += '<table id="update_purchases_grid" border=1 style="border: 1px solid #E8EAEB" width=1400px data-custom-grid="true">';

		html += '<tr style="height:30px;">';

		html += '<td width=30px align="center" style="vertical-align: middle;">';
		html += '<input type="checkbox" id="update_purchases_check_all_none" style="postion:absolute; top: 2px; left: 2px;" checked ';
		html += '       onclick="   let nb_selected_rows = 0; ';
		html += '					for (var i = 0; i < document.getElementById(\'update_purchases_detail\').rows.length; i++) ';
		html += '					{	';
		html += '						if (document.getElementById(\'update_purchases_checked_id_\' + i.toString())) { '
		html += '							document.getElementById(\'update_purchases_checked_id_\' + i.toString()).checked = this.checked; ';
		html += '						} ';

		html += '						if (document.getElementById(\'update_purchases_checked_id_\' + i.toString())) { '
		html += '							if (document.getElementById(\'update_purchases_checked_id_\' + i.toString()).checked) { '
		html += '								nb_selected_rows++; ';
		html += '							} ';		
		html += '						} ';		
		html += '					} ';

		html += '					if (document.getElementById(\'update_purchases_grid_banner\')) ';
		html += '					{ ';
		html += '						if (nb_selected_rows <= 1) { ';
		html += '							document.getElementById(\'update_purchases_grid_banner\').innerHTML = \'<b>\' + nb_selected_rows.toString() + \'</b> \' + __(\'selected purchases price (prices updated by contracts have been excluded)\'); ';
		html += '						}else { ';
		html += '							document.getElementById(\'update_purchases_grid_banner\').innerHTML = \'<b>\' + nb_selected_rows.toString() + \'</b> \' + __(\'selected purchases prices (prices updated by contracts have been excluded)\'); ';
		html += '						} ';
		html += '					} ';
		html += '                " ';
		html += '>'; 
		html += '</td>';

		html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';		
		html += '<td id="update_purchases_col_supplier_id" width=200px style="vertical-align: middle;"><b>&nbsp;' + __("Supplier") +                  '<label id="update_purchases_col_label_supplier_id" style="width:30px; height:8px" align="right">&darr;</label></b></td>';		
		html += '<td id="update_purchases_col_purchases_condition_id" width=150px style="vertical-align: middle;"><b>&nbsp;' + __("Pur. condition") + '<label id="update_purchases_col_label_purchases_condition_id" style="width:30px; height:8px" align="right"></label></b></td>';		
		html += '<td id="update_purchases_col_item_id" width=250px style="vertical-align: middle;"><b>&nbsp;' + __("Item") +                          '<label id="update_purchases_col_label_item_id" style="width:30px; height:8px" align="right"></label></b></td>';		
		html += '<td id="update_purchases_col_unit_id" width=80px style="vertical-align: middle;"><b>&nbsp;' + __("Unit") +                           '<label id="update_purchases_col_label_unit_id" style="width:30px; height:8px" align="right"></label></b></td>';				
		html += '<td id="update_purchases_col_currency_id" width=80px style="vertical-align: middle;"><b>&nbsp;' + __("Curr.") +                      '<label id="update_purchases_col_label_currency_id" style="width:30px; height:8px" align="right"></label></b></td>';				
		html += '<td id="update_purchases_col_site_id" width=80px style="vertical-align: middle;"><b>&nbsp;' + __("Site") +                           '<label id="update_purchases_col_label_site_id" style="width:30px; height:8px" align="right"></label></b></td>';				
		html += '<td id="update_purchases_col_unit_price" width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Unit price") +                 '<label id="update_purchases_col_label_unit_price" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id="update_purchases_col_fixed_price" width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Fix. price") +                '<label id="update_purchases_col_label_fixed_price" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id="update_purchases_col_base_discount" width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Base disc.") +              '<label id="update_purchases_col_label_base_discount" style="width:20px; height:8px" align="right"></label></b></td>';
		html += '<td id="update_purchases_col_extra_discount" width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Extra disc.") +            '<label id="update_purchases_col_label_extra_discount" style="width:20px; height:8px" align="right"></label></b></td>';		
		html += '<td id="update_purchases_col_enabled" width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Status") +        				 '<label id="update_purchases_col_label_enabled" style="width:30px; height:8px" align="right"></label></b></td>';				
		html += '</tr>';
		html += '</table>';		


		// Result
		html += '<table id="update_purchases_detail" border=1 style="border: 1px solid #E8EAEB" width=1400px>';
		html += '<tr style="height:30px">';
		html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '</tr>';
		html += '</table>';		

		html += '</div>'

		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'

		html += '<div style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '	<label style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Price calculation rule") + '</b></label>';			
		html += '</div>'


		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'

		html += '<div style="overflow: auto; overflow-x: auto; height:200px;">';	// 140
		
		// Increase Unit Price
		html += '	<div style="position: relative; top: 0px; left: 0px; width:400px;">';
		html += '       <input type="checkbox" id="update_purchases_cb_increase" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="update_purchases_increase_unit_price_label" style="position: absolute; top: 6px; left: 30px;">' + __("Increasing unit price of") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 200px; width: 100px; height: 25px;"> ';
		html += '			<input id="update_purchases_increase_unit_price" type="number" class="input-with-feedback form-control bold">';
		html += '			<label id="update_purchases_increase_unit_price_percent" style="position: absolute; top: 6px; left: 110px;">%</label>';		
		html += '		</div>';
		html += '	</div>';
		
		// Update Fixed Price
		html += '	<div style="position: relative; top: 40px; left: 0px; width:400px;">';
		html += '       <input type="checkbox" id="update_purchases_cb_fixed_price" style="position: absolute; top: 8px; left: 2px;">';		
		html += '		<label id="update_purchases_update_fixed_price_label" style="position: absolute; top: 6px; left: 30px;">' + __("Update fixed price") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 200px; width: 100px; height: 25px;"> ';
		html += '			<input id="update_purchases_update_fixed_price" type="number" class="input-with-feedback form-control bold">';
		html += '		</div>';
		html += '	</div>';

		// Update Base discount
		html += '	<div style="position: relative; top: 0px; left: 350px; width:400px;">';
		html += '       <input type="checkbox" id="update_purchases_cb_base_discount" style="position: absolute; top: 8px; left: 2px;">';		
		html += '		<label id="update_purchases_update_base_discount_label" style="position: absolute; top: 6px; left: 30px;">' + __("Update base discount") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 200px; width: 100px; height: 25px;"> ';
		html += '			<input id="update_purchases_update_base_discount" type="number" class="input-with-feedback form-control bold">';
		html += '			<label id="update_purchases_update_base_discount_percent" style="position: absolute; top: 6px; left: 110px;">%</label>';		
		html += '		</div>';
		html += '	</div>';

		// Update Extra discount
		html += '	<div style="position: relative; top: 40px; left: 350px; width:400px;">';
		html += '       <input type="checkbox" id="update_purchases_cb_extra_discount" style="position: absolute; top: 8px; left: 2px;">';		
		html += '		<label id="update_purchases_update_extra_discount_label" style="position: absolute; top: 6px; left: 30px;">' + __("Update extra discount") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 200px; width: 100px; height: 25px;"> ';
		html += '			<input id="update_purchases_update_extra_discount" type="number" class="input-with-feedback form-control bold">';
		html += '			<label id="update_purchases_update_extra_discount_percent" style="position: absolute; top: 6px; left: 110px;">%</label>';		
		html += '		</div>';
		html += '	</div>';


		// Rounding kind
		html += '	<div style="position: relative; top: 80px; left: 0px; width:400px;">';
		html += '		<label id="update_purchases_update_rounding_kind_label" style="position: absolute; top: 6px; left: 2px;">' + __("Rounding") + '</label>';

		html += '		<div class="control-input" style="position: absolute; top: 30px; left: 2px; width: 190px; height: 25px;"> ';
		html += '			<select id="update_purchases_update_rounding_kind" class="input-with-feedback form-control bold"> ';
		html += '				<option value="0" selected>' + __('Decimals') + '</option> ';		
		html += '				<option value="1">' + __('Multiple') + '</option> ';
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';


		// Rounding Method
		html += '	<div style="position: relative; top: 80px; left: 200px; width:400px;">';
		html += '		<label id="update_purchases_update_rounding_method_label" style="position: absolute; top: 6px; left: 2px;">' + __("Method") + '</label>';		
		html += '		<div class="control-input" style="position: absolute; top: 30px; left: 2px; width: 140px; height: 25px;"> ';
		html += '			<select id="update_purchases_update_rounding_method" class="input-with-feedback form-control bold"> ';
		html += '				<option value="0" selected>' + __('Nearest') + '</option> ';		
		html += '				<option value="1">' + __('Above') + '</option> ';
		html += '				<option value="2">' + __('Under') + '</option> ';
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		// Rounding value
		html += '	<div style="position: relative; top: 80px; left: 350px; width:400px;">';
		html += '		<label id="update_purchases_update_rounding_value_label" style="position: absolute; top: 6px; left: 2px;">' + __("Decimals") + '</label>';				
		html += '		<div class="control-input" style="position: absolute; top: 30px; left: 2px; width: 100px; height: 25px;"> ';
		html += '			<input id="update_purchases_update_rounding_value" type="number" class="input-with-feedback form-control bold" value="2">';
		html += '		</div>';
		html += '	</div>';





		// Button Clear values
		html += '	<div style="position: relative; top: 150px; left: 0px; width:100px;">';		
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 2px; width: 100px; height: 25px;"> ';
		html += '			<input id="update_purchases_bt_clear_values" type="button" class="btn btn-default ellipsis" value="' + __("Clear")  + '" style="width:100px">';
		html += '		</div>';
		html += '	</div>';		

		html += '	<div style="position: relative; top: 160px; left: 200px; width:600px;">';
		html += '		<label id="update_purchases_warning1" style="position: absolute; top: 0px; left: 2px;"><font color="red">' + __("Caution, risky and irreversible operation ! Make a copy before, please (ioi Purchases Price) ") + '</font></label>';
		html += '	</div>';




		// Convert in the currency
		html += '	<div style="position: relative; top: 0px; left: 700px; width:400px;">';
		html += '       <input type="checkbox" id="update_purchases_cb_convert" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="update_purchases_convert_label" style="position: absolute; top: 6px; left: 30px;">' + __("Convert in the currency") + '</label>';
		html += '	</div>';


		// Old currency
		html += '	<div style="position: relative; top: 40px; left: 700px; width:400px;">';
		html += '		<label id="update_purchases_old_currency_label" style="position: absolute; top: 6px; left: 30px;">' + __("Old currency") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 150px; width: 70px; height: 25px;"> ';
		html += '			<input id="update_purchases_old_currency" type="text" class="input-with-feedback form-control bold">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 4px; left: 230px; width: 130px; height: 25px;"> ';		
		html +='			<button id="update_purchases_old_currency_select_currency_button" style="height: 30px; width: 30px;border: none; outline:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';

		html += '	</div>';


		// New currency
		html += '	<div style="position: relative; top: 40px; left: 950px; width:200px;">';
		html += '		<label id="update_purchases_new_currency_label" style="position: absolute; top: 6px; left: 30px;">' + __("New currency") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 150px; width: 70px; height: 25px;"> ';
		html += '			<input id="update_purchases_new_currency" type="text" class="input-with-feedback form-control bold" ';
		html += '				   onkeyup="';

		html += '						var start = this.selectionStart; ';
		html += '						var end = this.selectionEnd; ';
		html += '						this.value = this.value.toUpperCase(); ';
		html += '						this.setSelectionRange(start, end); ';

		html += '						if (event.keyCode == 13) { return false; }';
		
		html += '			">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 4px; left: 230px; width: 30px; height: 25px;"> ';		
		html +='			<button id="update_purchases_new_currency_select_currency_button" style="height: 30px; width: 30px;border: none; outline:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';

		html += '	</div>';


		// Currency comments
		html += '	<div style="position: relative; top: 80px; left: 700px; width:400px;">';
		html += '		<label id="update_purchases_currency_comment1" style="position: absolute; top: 6px; left: 30px;"><font color="blue">' + __("Consider prices in the old currency") + '</font></label>';
		html += '		<label id="update_purchases_currency_comment2" style="position: absolute; top: 30px; left: 30px;"><font color="blue">' + __("Do not erase an existing price in the new currency") + '</font></label>';
		html += '	</div>';

		html += '</div>'

		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'


		html += '<div style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '	<label style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Operations") + '</b></label>';			
		html += '</div>'


		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'

		html += '<div style="overflow: auto; overflow-x: auto; height:55px;">';	

		html += '	<div style="position: relative; top: 0px; left: 2px; width:500px;">';

		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 0px; width: 200px; height: 25px;"> ';
		html += '			<input id="update_purchases_bt_update_price" type="button" class="btn btn-default ellipsis" value="' + __("Update selected prices")  + '" style="width:200px">';
		html += '		</div>';

		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 210px; width: 150px; height: 25px;"> ';
		html += '			<input id="update_purchases_set_enabled" type="button" class="btn btn-default ellipsis" value="' + __("Set enabled")  + '" style="width:150px">';
		html += '		</div>';

		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 370px; width: 150px; height: 25px;"> ';
		html += '			<input id="update_purchases_set_disabled" type="button" class="btn btn-default ellipsis" value="' + __("Set disabled")  + '" style="width:150px">';
		html += '		</div>';

		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 530px; width: 200px; height: 25px;"> ';
		html += '			<input id="update_purchases_delete_disabled" type="button" class="btn btn-default ellipsis" value="' + __("Delete selected disabled")  + '" style="width:200px">';
		html += '		</div>';

		html += '	</div>';

		html += '</div>'

		cur_frm.fields_dict[html_field].$wrapper.empty();
		cur_frm.fields_dict[html_field].$wrapper.append(html);

		silicon_ioi.doctype.ioiPriceUpdatingTool.sleep_static(250).then(() => {
			
			let fct_keydown = function(event) { 
				
				silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_purchases_search_key_down(event);

							
				if (event.keyCode == 13) 
				{	return false;
				}
			
			};

			document.getElementById("update_purchases_supplier_id").onkeydown = fct_keydown;

			

			let fct_bt_supplier_click = function () {

				let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email, alternate_id';
				let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400, 250';
				let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email, Alternate id';
				let where = 'ioistatus = 2';
				let order_by = 'name asc';
			
				let fct_callback = function (return_value) {

					document.getElementById('update_purchases_supplier_id').value = return_value;					
					document.getElementById('update_purchases_supplier_id').focus();					
				}

				silicon_ioi.ioiCommon.select_supplier(fields, fields_len, fields_desc, where, order_by, fct_callback);

			}


			document.getElementById('update_purchases_select_supplier_button').onclick = fct_bt_supplier_click;



			document.getElementById("update_purchases_item_id").onkeydown = fct_keydown;


			let fct_bt_item_click = function () {

			
				let fields = 'a.name, a.description, a.unit_id, b.q_stock, b.q_reserved, a.manufacturer_ref';
				let fields_len = '300, 500, 125, 150, 150, 300';
				let fields_desc = 'Identification, Description, Unit, Stock qty, Reserved qty, Manufacturer Catalog';
				let where = 'ioistatus = 2';
				let order_by = 'a.name asc';
				let parameter_with_stock = false;
				let site_id = '';
		
				let fct_callback = function (return_value) {

					document.getElementById('update_purchases_item_id').value = return_value;					
					document.getElementById('update_purchases_item_id').focus();					
				}
		
				silicon_ioi.ioiCommon.select_item(fields, fields_len, fields_desc, where, order_by, parameter_with_stock, site_id, fct_callback);
			}


			document.getElementById('update_purchases_select_item_button').onclick = fct_bt_item_click;			




			document.getElementById("update_purchases_currency_id").onkeydown = fct_keydown;

			
			let fct_bt_currency_click = function () {

					let title = __("Select a currency");
					let form_width_pixel = '580px';
					let table = "ioi Currency";
					let fields = 'name, description';
					let fields_len = '200, 300';
					let fields_desc = 'Identification, Description';
					let where = 'enabled = 1';
					let order_by = 'name asc';
					
					let fct_callback = function (return_value) {
		
						document.getElementById("update_purchases_currency_id").value = return_value;
						document.getElementById('update_purchases_currency_id').focus();
					}
		
					silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

			}

			document.getElementById('update_purchases_select_currency_button').onclick = fct_bt_currency_click;			
			


			let fct_change = function() { silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_purchases_search_on_change(); };

			document.getElementById("update_purchases_purchases_condition_id").onchange = fct_change;
			document.getElementById("update_purchases_unit_id").onchange = fct_change;
			document.getElementById("update_purchases_site_id").onchange = fct_change;

			document.getElementById("update_purchases_enabled").onchange = fct_change;

			silicon_ioi.doctype.ioiPriceUpdatingTool.update_purchases_fill_item_family();

			let fct_change_family = function() { 
				
				silicon_ioi.doctype.ioiPriceUpdatingTool.update_purchases_fill_item_type('CHANGE');
				silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_purchases_search_on_change(); 
			};

			document.getElementById("update_purchases_family_id").onchange = fct_change_family;

			let fct_change_type = function() { 
				
				silicon_ioi.doctype.ioiPriceUpdatingTool.update_purchases_fill_item_group('CHANGE');
				silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_purchases_search_on_change(); 
			};

			document.getElementById("update_purchases_type_id").onchange = fct_change_type;


			let fct_change_group = function() { 
				
				silicon_ioi.doctype.ioiPriceUpdatingTool.update_purchases_fill_item_subgroup('CHANGE')
				silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_purchases_search_on_change(); 
			};

			document.getElementById("update_purchases_group_id").onchange = fct_change_group;


			let fct_change_subgroup = function() { 
				
				silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_purchases_search_on_change(); 
			};

			document.getElementById("update_purchases_subgroup_id").onchange = fct_change_subgroup;



			document.getElementById("update_purchases_classification_id").onchange = fct_change;
			document.getElementById("update_purchases_shop_category_id").onchange = fct_change;


			let fct_click = function () { silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_purchases_refresh(); };
			let fct_clear = function () { silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_purchases_clear(); };
	
	
			document.getElementById('update_purchases_search').onclick = fct_click;
			document.getElementById('update_purchases_clear').onclick = fct_clear;
	
	
			let fct_col_over = function ()  { silicon_ioi.doctype.ioiPriceUpdatingTool.col_mouse_over(this); };
			let fct_col_leave = function () { silicon_ioi.doctype.ioiPriceUpdatingTool.col_mouse_leave(this) }; 
			let fct_col_click = function () { 
			
				let selected_lines = [];

				for (var i = 0; i < document.getElementById('update_purchases_detail').rows.length; i++) {	

					if (document.getElementById("update_purchases_checked_id_" + i.toString()))	{					
						
						if (document.getElementById('update_purchases_checked_id_' + i.toString()).checked)	{

							selected_lines[selected_lines.length] = document.getElementById('update_purchases_detail_name_' + i.toString()).value;
						}
					}
				}

				silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_purchases_col_click(this); 

				silicon_ioi.doctype.ioiPriceUpdatingTool.sleep_static(100).then(() => {

					for (var i = 0; i < document.getElementById('update_purchases_detail').rows.length; i++) {	

						if (document.getElementById("update_purchases_checked_id_" + i.toString()))	{						

							document.getElementById('update_purchases_checked_id_' + i.toString()).checked = false;

							if (selected_lines.indexOf(document.getElementById('update_purchases_detail_name_' + i.toString()).value) != -1)
							{	
								document.getElementById('update_purchases_checked_id_' + i.toString()).checked = true;
							}
						}
					}

					

					if (document.getElementById("update_purchases_grid_banner"))
					{	
						if (selected_lines.length <= 1) {
							document.getElementById("update_purchases_grid_banner").innerHTML = '<b>' + selected_lines.length.toString() + '</b> ' + __("selected purchases price (prices updated by contracts have been excluded)")
						}else {
							document.getElementById("update_purchases_grid_banner").innerHTML = '<b>' + selected_lines.length.toString() + '</b> ' + __("selected purchases prices (prices updated by contracts have been excluded)")
						}
					}
			

				});
			};			
	
		
			document.getElementById('update_purchases_col_supplier_id').onclick = fct_col_click; 
			document.getElementById('update_purchases_col_purchases_condition_id').onclick = fct_col_click;
			document.getElementById('update_purchases_col_item_id').onclick = fct_col_click;
			document.getElementById('update_purchases_col_unit_id').onclick = fct_col_click;
			document.getElementById('update_purchases_col_currency_id').onclick = fct_col_click;
			document.getElementById('update_purchases_col_site_id').onclick = fct_col_click;
			document.getElementById('update_purchases_col_unit_price').onclick = fct_col_click;
			document.getElementById('update_purchases_col_fixed_price').onclick = fct_col_click;
			document.getElementById('update_purchases_col_base_discount').onclick = fct_col_click;
			document.getElementById('update_purchases_col_extra_discount').onclick = fct_col_click;
			document.getElementById('update_purchases_col_enabled').onclick = fct_col_click;
	
			document.getElementById('update_purchases_col_supplier_id').onmouseover = fct_col_over;
			document.getElementById('update_purchases_col_purchases_condition_id').onmouseover = fct_col_over;
			document.getElementById('update_purchases_col_item_id').onmouseover = fct_col_over;
			document.getElementById('update_purchases_col_unit_id').onmouseover = fct_col_over;
			document.getElementById('update_purchases_col_currency_id').onmouseover = fct_col_over;
			document.getElementById('update_purchases_col_site_id').onmouseover = fct_col_over;
			document.getElementById('update_purchases_col_unit_price').onmouseover = fct_col_over;
			document.getElementById('update_purchases_col_fixed_price').onmouseover = fct_col_over;
			document.getElementById('update_purchases_col_base_discount').onmouseover = fct_col_over;
			document.getElementById('update_purchases_col_extra_discount').onmouseover = fct_col_over;
			document.getElementById('update_purchases_col_enabled').onmouseover = fct_col_over;
	
			document.getElementById('update_purchases_col_supplier_id').onmouseleave = fct_col_leave;
			document.getElementById('update_purchases_col_purchases_condition_id').onmouseleave = fct_col_leave;
			document.getElementById('update_purchases_col_item_id').onmouseleave = fct_col_leave;
			document.getElementById('update_purchases_col_unit_id').onmouseleave = fct_col_leave;
			document.getElementById('update_purchases_col_currency_id').onmouseleave = fct_col_leave;
			document.getElementById('update_purchases_col_site_id').onmouseleave = fct_col_leave;
			document.getElementById('update_purchases_col_unit_price').onmouseleave = fct_col_leave;
			document.getElementById('update_purchases_col_fixed_price').onmouseleave = fct_col_leave;
			document.getElementById('update_purchases_col_base_discount').onmouseleave = fct_col_leave;
			document.getElementById('update_purchases_col_extra_discount').onmouseleave = fct_col_leave;
			document.getElementById('update_purchases_col_enabled').onmouseleave = fct_col_leave;


			let fct_return_false = function(event) {
			
				if (event.keyCode == 13) 
				{	return false;
				}
		
			}


			document.getElementById('update_purchases_cb_increase').onkeydown = fct_return_false;			

			let fct_check_cb_unit_price = function(event) {
				document.getElementById('update_purchases_cb_increase').checked = true;
				
				if (event.keyCode == 13) 
				{	return false;
				}
		
			}

			document.getElementById("update_purchases_increase_unit_price").onkeydown = fct_check_cb_unit_price;


			document.getElementById('update_purchases_cb_fixed_price').onkeydown = fct_return_false;			

			let fct_check_cb_fixed_price = function(event) {
				document.getElementById('update_purchases_cb_fixed_price').checked = true;

				if (event.keyCode == 13) 
				{	return false;
				}

			}


			document.getElementById("update_purchases_update_fixed_price").onkeydown = fct_check_cb_fixed_price;
			
			document.getElementById('update_purchases_cb_base_discount').onkeydown = fct_return_false;						

			let fct_check_cb_base_discount = function(event) {
				document.getElementById('update_purchases_cb_base_discount').checked = true;

				if (event.keyCode == 13) 
				{	return false;
				}

			}

			document.getElementById("update_purchases_update_base_discount").onkeydown = fct_check_cb_base_discount;

			document.getElementById('update_purchases_cb_extra_discount').onkeydown = fct_return_false;									

			let fct_check_cb_extra_discount = function(event) {
				document.getElementById('update_purchases_cb_extra_discount').checked = true;

				if (event.keyCode == 13) 
				{	return false;
				}

			}

			document.getElementById("update_purchases_update_extra_discount").onkeydown = fct_check_cb_extra_discount;

					
			document.getElementById('update_purchases_update_rounding_kind').onkeydown = fct_return_false;

			let fct_rounding_kind_change = function() {

				if (document.getElementById('update_purchases_update_rounding_kind').value == 0) {

					document.getElementById('update_purchases_update_rounding_value_label').innerText = __('Decimals');
					document.getElementById('update_purchases_update_rounding_value').value = '2';
					
				}else{
					document.getElementById('update_purchases_update_rounding_value_label').innerText = __('Multiple');
					document.getElementById('update_purchases_update_rounding_value').value = '0.05';
				}

			};

			document.getElementById('update_purchases_update_rounding_kind').onchange = fct_rounding_kind_change;

			document.getElementById('update_purchases_update_rounding_method').onkeydown = fct_return_false;	
			document.getElementById('update_purchases_update_rounding_value').onkeydown = fct_return_false;	




			

			document.getElementById('update_purchases_cb_convert').onkeydown = fct_return_false;									

			let fct_check_cb_convert = function(event) {
				document.getElementById('update_purchases_cb_convert').checked = true;
				

				if (event.keyCode == 13) 
				{	
					document.getElementById('update_purchases_currency_id').value = document.getElementById("update_purchases_old_currency").value;
					silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_purchases_refresh();
					return false;
				}

			}

			document.getElementById("update_purchases_old_currency").onkeydown = fct_check_cb_convert;


			let fct_bt_old_currency_click = function () {

				let title = __("Select a currency");
				let form_width_pixel = '580px';
				let table = "ioi Currency";
				let fields = 'name, description';
				let fields_len = '200, 300';
				let fields_desc = 'Identification, Description';
				let where = 'enabled = 1';
				let order_by = 'name asc';
				
				let fct_callback = function (return_value) {
					document.getElementById('update_purchases_cb_convert').checked = true;
					document.getElementById("update_purchases_old_currency").value = return_value;
					document.getElementById('update_purchases_old_currency').focus();

					document.getElementById('update_purchases_currency_id').value = return_value;
					silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_purchases_refresh();
	
				}
	
				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

			}

			document.getElementById('update_purchases_old_currency_select_currency_button').onclick = fct_bt_old_currency_click;			


			let fct_keyup = function(event) { 
			
				var start = this.selectionStart;
				var end = this.selectionEnd;
				this.value = this.value.toUpperCase(); 
				this.setSelectionRange(start, end);
				document.getElementById('update_purchases_cb_convert').checked = true;
						
			};			
		
			document.getElementById("update_purchases_old_currency").onkeyup = fct_keyup;
			document.getElementById("update_purchases_old_currency").onkeydown = fct_check_cb_convert;

			let fct_check_new_cb_convert = function(event) {
				document.getElementById('update_purchases_cb_convert').checked = true;
				

				if (event.keyCode == 13) 
				{	
					return false;
				}

			}



			document.getElementById("update_purchases_new_currency").onkeydown = fct_check_new_cb_convert;

			let fct_bt_new_currency_click = function () {

				let title = __("Select a currency");
				let form_width_pixel = '580px';
				let table = "ioi Currency";
				let fields = 'name, description';
				let fields_len = '200, 300';
				let fields_desc = 'Identification, Description';
				let where = 'enabled = 1';
				let order_by = 'name asc';
				
				let fct_callback = function (return_value) {
					document.getElementById('update_purchases_cb_convert').checked = true;
					document.getElementById("update_purchases_new_currency").value = return_value;
					document.getElementById('update_purchases_new_currency').focus();

					document.getElementById('update_purchases_currency_id').value = this.value;
					silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_purchases_refresh();

				}
	
				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

			}

			document.getElementById('update_purchases_new_currency_select_currency_button').onclick = fct_bt_new_currency_click;			




			let fct_clear_values = function () { silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_purchases_clear_values(); };			

			document.getElementById('update_purchases_bt_clear_values').onclick = fct_clear_values;


			let fct_update = function () { silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_purchases_update_price(); };			
			let fct_set_enabled = function () { silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_purchases_set_enabled(); };
			let fct_set_disabled = function () { silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_purchases_set_disabled(); };
			let fct_delete_selected_disabled = function () { silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_purchases_delete_selected_disabled(); };
	
			document.getElementById('update_purchases_bt_update_price').onclick = fct_update;
			document.getElementById('update_purchases_set_enabled').onclick = fct_set_enabled;
			document.getElementById('update_purchases_set_disabled').onclick = fct_set_disabled;
			document.getElementById('update_purchases_delete_disabled').onclick = fct_delete_selected_disabled;			

			

		});
	}



	static do_update_purchases_clear()
	{
		
		document.getElementById('update_purchases_supplier_id').value = '';
		document.getElementById("update_purchases_purchases_condition_id").selectedIndex = 0;
		document.getElementById('update_purchases_item_id').value = '';
		document.getElementById("update_purchases_unit_id").selectedIndex = 0;
		document.getElementById('update_purchases_currency_id').value = '';
		document.getElementById("update_purchases_site_id").selectedIndex = 0;

		document.getElementById("update_purchases_enabled").selectedIndex = 0;

		document.getElementById("update_purchases_family_id").selectedIndex = 0;

		for (var i = document.getElementById('update_purchases_type_id').options.length-1; i >= 0; i--) {
			document.getElementById('update_purchases_type_id').options[i].remove();	
		}

		for (var i = document.getElementById('update_purchases_group_id').options.length-1; i >= 0; i--) {
			document.getElementById('update_purchases_group_id').options[i].remove();	
		}
		
		for (var i = document.getElementById('update_purchases_subgroup_id').options.length-1; i >= 0; i--) {
			document.getElementById('update_purchases_subgroup_id').options[i].remove();	
		}

		silicon_ioi.doctype.ioiPriceUpdatingTool.item_type = [];
		silicon_ioi.doctype.ioiPriceUpdatingTool.item_type[0] = ['', ''];
		document.getElementById('update_purchases_type_id').options[document.getElementById('update_purchases_type_id').options.length] = new Option('', '');												


		silicon_ioi.doctype.ioiPriceUpdatingTool.item_group = [];
		silicon_ioi.doctype.ioiPriceUpdatingTool.item_group[0] = ['', ''];
		document.getElementById('update_purchases_group_id').options[document.getElementById('update_purchases_group_id').options.length] = new Option('', '');												

		silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup = [];
		silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[0] = ['', ''];			
		document.getElementById('update_purchases_subgroup_id').options[document.getElementById('update_purchases_subgroup_id').options.length] = new Option('', '');												

		document.getElementById("update_purchases_type_id").selectedIndex = 0;				
		document.getElementById("update_purchases_group_id").selectedIndex = 0;
		document.getElementById("update_purchases_subgroup_id").selectedIndex = 0;				
		document.getElementById("update_purchases_classification_id").selectedIndex = 0;
		document.getElementById("update_purchases_shop_category_id").selectedIndex = 0;				
	

		
		if (document.getElementById('update_purchases_detail'))	{
			document.getElementById('update_purchases_detail').remove();
		}
		
		let html = '';

		html += '<table id="update_purchases_detail" border=1 style="border: 1px solid #E8EAEB" width=1400px>';
		html += '<tr style="height:30px">';
		html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '</tr>';
		html += '</table>';	
		

		document.getElementById('update_purchases_content').insertAdjacentHTML('beforeend', html);	

		let nb_selected_rows = 0
		document.getElementById("update_purchases_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected purchases price (prices updated by contracts have been excluded)")

	}

	static do_update_purchases_refresh()
	{
		
		let html = '';

		let me = this;

		let method = 'silicon_ioi.ioi_items.doctype.ioi_purchases_price.ioi_purchases_price.ioi_purchases_prices_get_list_for_update_prices'		

					
		frappe.call({  	method: method, 
						args: {	"supplier_id": document.getElementById('update_purchases_supplier_id').value,
								"purchases_condition_id": document.getElementById("update_purchases_purchases_condition_id").value,
								"item_id": document.getElementById("update_purchases_item_id").value,
								"unit_id": document.getElementById("update_purchases_unit_id").value,
								"currency_id": document.getElementById("update_purchases_currency_id").value,
								"site_id": document.getElementById("update_purchases_site_id").value, 
								"enabled": document.getElementById("update_purchases_enabled").value,
								"family_id": document.getElementById("update_purchases_family_id").value,
								"type_id": document.getElementById("update_purchases_type_id").value, 
								"group_id": document.getElementById("update_purchases_group_id").value, 
								"subgroup_id": document.getElementById("update_purchases_subgroup_id").value,
								"classification_id": document.getElementById("update_purchases_classification_id").value,
								"shop_category_id": document.getElementById("update_purchases_shop_category_id").value,
								"order_field": document.getElementById('update_purchases_orderby').value,
								"order_dir": document.getElementById('update_purchases_order').value
								},
						async: false,
						callback:function(r)	{ 
													document.getElementById('update_purchases_check_all_none').checked = true;

													if (document.getElementById('update_purchases_detail'))
													{
														for (var i = 0; i < document.getElementById('update_purchases_detail').rows.length; i++)
														{
															if (document.getElementById('update_purchases_detail_name_' + i.toString()))
															{
																document.getElementById('update_purchases_detail_name_' + i.toString()).remove();
															}

															if (document.getElementById('update_purchases_checked_id_' + i.toString()))
															{
																document.getElementById('update_purchases_checked_id_' + i.toString()).remove();
															}

															if (document.getElementById('update_purchases_button_id_' + i.toString()))
															{
																document.getElementById('update_purchases_button_id_' + i.toString()).remove();
															}


															if (document.getElementById('update_purchases_detail_enabled_' + i.toString()))
															{
																document.getElementById('update_purchases_detail_enabled_' + i.toString()).remove();
															}

														}

													
														document.getElementById('update_purchases_detail').remove();
													}
						
													if (r.message.length > 0)
													{	
														let cpt = 0;
														html += '<table id="update_purchases_detail" border=1 style="border: 1px solid #E8EAEB" width=1400px>';
														for (var i = 0; i < r.message.length; i++)
														{

															html += '<input type="hidden" id="update_purchases_detail_name_' + i.toString() + '" value="' + r.message[i].name + '">';															

															
															if (r.message[i].enabled == 1) {															
																html += '<tr style="height:30px">';
															}else{
																html += '<tr bgcolor="#E7E3E3" style="height:30px">';
															}


															html += '<td width=30px align="center" style="vertical-align: middle;">';
															html += '<input type="checkbox" id="update_purchases_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;" checked>'; 
															html += '<input type="hidden" id="update_purchases_detail_enabled_' + i.toString() + '" value="' + r.message[i].enabled + '">'															
															html += '</td>';


															let s = '/app/ioi-purchases-price/' + r.message[i].name;

															html += '<td width=30px align="center" style="vertical-align: middle;">';
															html += '<input type="button" id="update_purchases_button_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;border: none; outline:none;" value="..." onclick="window.open(\'' + s + '\');" title="' + __("Go to Purchases Price") + '">'; 
															html += '</td>';
													
															s = '/app/ioi-supplier/' + r.message[i].supplier_id;

															html += '<td width=200px style="vertical-align: middle;" onclick="window.open(\'' + s + '\');" onmouseover="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';">&nbsp;<u>' + r.message[i].supplier_id + '</u></td>';

															html += '<td width=150px style="vertical-align: middle;">&nbsp;' + r.message[i].purchases_condition_id + '</td>';

															s = '/app/ioi-item/' + r.message[i].item_id;

															html += '<td width=250px style="vertical-align: middle;" onclick="window.open(\'' + s + '\');" onmouseover="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';">&nbsp;<u>' + r.message[i].item_id + '</u></td>';

															html += '<td width=80px style="vertical-align: middle;">&nbsp;' + r.message[i].unit_id + '</td>';

															html += '<td width=80px style="vertical-align: middle;">&nbsp;' + r.message[i].currency_id + '</td>';

															if (r.message[i].site_id != null) {
																html += '<td width=80px style="vertical-align: middle;">&nbsp;' + r.message[i].site_id + '</td>';
															}else{
																html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
															}

															html += '<td width=100px style="vertical-align: middle;" align="right">' + r.message[i].unit_price + '&nbsp;</td>';

															html += '<td width=100px style="vertical-align: middle;" align="right">' + r.message[i].fixed_price + '&nbsp;</td>';

															html += '<td width=100px style="vertical-align: middle;" align="right">' + r.message[i].base_discount + '&nbsp;%&nbsp;</td>';

															html += '<td width=100px style="vertical-align: middle;" align="right">' + r.message[i].extra_discount + '&nbsp;%&nbsp;</td>';

															if (r.message[i].enabled == 1) {
																html += '<td width=100px style="vertical-align: middle;">&nbsp;' + __("Enabled") + '</td>';
															}else {
																html += '<td width=100px style="vertical-align: middle;">&nbsp;' + __("Disabled") + '</td>';
															}

															html += '</tr>';
														}
														html += '</table>';																	
													}else
													{
														html += '<table id="update_purchases_detail" border=1 style="border: 1px solid #E8EAEB" width=1400px>';
														html += '<tr style="height:30px">';
														html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
														html += '</tr>';
														html += '</table>';		
																							
													}

													document.getElementById('update_purchases_content').insertAdjacentHTML('beforeend', html);

													silicon_ioi.doctype.ioiPriceUpdatingTool.sleep_static(200).then(() => {
														

														let fct_click = function () {

															let nb_selected_rows = 0

															for (var i = 0; i < document.getElementById('update_purchases_detail').rows.length; i++) {	
																					
																if (document.getElementById("update_purchases_checked_id_" + i.toString()))	{																
																	if (document.getElementById('update_purchases_checked_id_' + i.toString()).checked)	{
																		nb_selected_rows++;
																	}
																}
															}

															if (document.getElementById("update_purchases_grid_banner"))
															{	
																if (nb_selected_rows <= 1) {
																	document.getElementById("update_purchases_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected purchases price (prices updated by contracts have been excluded)")
																}else {
																	document.getElementById("update_purchases_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected purchases prices (prices updated by contracts have been excluded)")
																}
															}
														};

														for (var i = 0; i < document.getElementById('update_purchases_detail').rows.length; i++) {

															if (document.getElementById('update_purchases_checked_id_' + i.toString())) {

																document.getElementById('update_purchases_checked_id_' + i.toString()).onclick = fct_click;
															}
															
														}

													});

												}
		});

		let nb_selected_rows = 0

		for (var i = 0; i < document.getElementById('update_purchases_detail').rows.length; i++) {	
			if (document.getElementById("update_purchases_checked_id_" + i.toString()))	{										
				if (document.getElementById('update_purchases_checked_id_' + i.toString()).checked)	{
					nb_selected_rows++;
				}
			}
		}

		if (document.getElementById("update_purchases_grid_banner"))
		{	
			if (nb_selected_rows <= 1) {
				document.getElementById("update_purchases_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected purchases price (prices updated by contracts have been excluded)")
			}else {
				document.getElementById("update_purchases_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected purchases prices (prices updated by contracts have been excluded)")
			}
		}

		
	}


	static do_update_purchases_search_key_down(event)
	{
		if (event.keyCode == 13) 
		{
			silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_purchases_refresh();
		}
		
	}

	static do_update_purchases_search_on_change()
	{
		silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_purchases_refresh();
	}

	static do_update_purchases_col_click(obj)
	{   
		let s = obj.id;

	
		s = s.substring(21, obj.id.length); 

		document.getElementById('update_purchases_col_label_supplier_id').innerHTML = '';
		document.getElementById('update_purchases_col_label_purchases_condition_id').innerHTML = '';
		document.getElementById('update_purchases_col_label_item_id').innerHTML = '';
		document.getElementById('update_purchases_col_label_unit_id').innerHTML = '';
		document.getElementById('update_purchases_col_label_currency_id').innerHTML = '';
		document.getElementById('update_purchases_col_label_site_id').innerHTML = '';
		document.getElementById('update_purchases_col_label_unit_price').innerHTML = '';
		document.getElementById('update_purchases_col_label_fixed_price').innerHTML = '';
		document.getElementById('update_purchases_col_label_base_discount').innerHTML = '';		
		document.getElementById('update_purchases_col_label_extra_discount').innerHTML = '';
		document.getElementById('update_purchases_col_label_enabled').innerHTML = '';

		if (document.getElementById('update_purchases_orderby').value == s)
		{
			if (document.getElementById('update_purchases_order').value == 'desc')
			{	document.getElementById('update_purchases_order').value = 'asc';
			
				document.getElementById('update_purchases_col_label_' + s).innerHTML = '&darr;';
			}else
			{	document.getElementById('update_purchases_order').value = 'desc';	
				document.getElementById('update_purchases_col_label_' + s).innerHTML = '&uarr;';
			}
		}else
		{	document.getElementById('update_purchases_orderby').value = s;
			document.getElementById('update_purchases_order').value = 'desc';
			document.getElementById('update_purchases_col_label_' + s).innerHTML = '&uarr;';
		}

		silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_purchases_refresh();		
	}

	static col_mouse_over(obj)
	{
		obj.style.cursor = 'pointer';
	}

	static col_mouse_leave(obj)
	{
		obj.style.cursor = 'none';
	}

	static sleep_static(ms) 
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	// ***************************************************************************************************************************************
	// Fill Item Family
	// ***************************************************************************************************************************************
	static update_purchases_fill_item_family()
	{	
		let me = this;
		let amethod =  'silicon_ioi.ioi_configuration.doctype.ioi_item_family.ioi_item_family.ioi_item_family_get_families';

		frappe.call({  	method: amethod,
					   	args: {"enabled":1, "current_family": silicon_ioi.doctype.ioiPriceUpdatingTool.current_family},
						async: false,
						callback:function(r){   
                                                silicon_ioi.doctype.ioiPriceUpdatingTool.item_family = [];
												silicon_ioi.doctype.ioiPriceUpdatingTool.item_family[0] = ['', ''];

												document.getElementById('update_purchases_family_id').options[document.getElementById('update_purchases_family_id').options.length] = new Option('', '');												

												for (var i = 0; i < r.message.length; i++)
												{	
													silicon_ioi.doctype.ioiPriceUpdatingTool.item_family[i+1] = [r.message[i][0], r.message[i][1]];

													document.getElementById('update_purchases_family_id').options[document.getElementById('update_purchases_family_id').options.length] = new Option(silicon_ioi.doctype.ioiPriceUpdatingTool.item_family[i+1][1], silicon_ioi.doctype.ioiPriceUpdatingTool.item_family[i+1][0]);													
												}
											}
		});
	}


	// ***************************************************************************************************************************************
	// Fill Item Type
	// ***************************************************************************************************************************************
	static update_purchases_fill_item_type(action='')
	{	
		if ((!document.getElementById('update_purchases_family_id')) || ((document.getElementById('update_purchases_family_id')) && (document.getElementById('update_purchases_family_id').value.trim() == '')))
		{
			if (action != '')
			{
				for (var i = document.getElementById('update_purchases_type_id').options.length-1; i >= 0; i--) {
					document.getElementById('update_purchases_type_id').options[i].remove();	
				}

				for (var i = document.getElementById('update_purchases_group_id').options.length-1; i >= 0; i--) {
					document.getElementById('update_purchases_group_id').options[i].remove();	
				}
				
				for (var i = document.getElementById('update_purchases_subgroup_id').options.length-1; i >= 0; i--) {
					document.getElementById('update_purchases_subgroup_id').options[i].remove();	
				}

			}

			silicon_ioi.doctype.ioiPriceUpdatingTool.item_type = [];
			silicon_ioi.doctype.ioiPriceUpdatingTool.item_type[0] = ['', ''];
			document.getElementById('update_purchases_type_id').options[document.getElementById('update_purchases_type_id').options.length] = new Option('', '');												


			silicon_ioi.doctype.ioiPriceUpdatingTool.item_group = [];
			silicon_ioi.doctype.ioiPriceUpdatingTool.item_group[0] = ['', ''];
			document.getElementById('update_purchases_group_id').options[document.getElementById('update_purchases_group_id').options.length] = new Option('', '');												

			silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup = [];
			silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[0] = ['', ''];			
			document.getElementById('update_purchases_subgroup_id').options[document.getElementById('update_purchases_subgroup_id').options.length] = new Option('', '');												

		}else
		{
			let me = this;
			let amethod =  'silicon_ioi.ioi_configuration.doctype.ioi_item_type.ioi_item_type.ioi_item_type_get_types';

			frappe.call({  	method: amethod,
							args: {"family_id": document.getElementById('update_purchases_family_id').value, "enabled":1, "current_type": silicon_ioi.doctype.ioiPriceUpdatingTool.current_type},
							async: false,
							callback:function(r){  
													if (action != '')
													{
														for (var i = document.getElementById('update_purchases_type_id').options.length-1; i >= 0; i--) {
															document.getElementById('update_purchases_type_id').options[i].remove();	
														}
										
														for (var i = document.getElementById('update_purchases_group_id').options.length-1; i >= 0; i--) {
															document.getElementById('update_purchases_group_id').options[i].remove();	
														}
														
														for (var i = document.getElementById('update_purchases_subgroup_id').options.length-1; i >= 0; i--) {
															document.getElementById('update_purchases_subgroup_id').options[i].remove();	
														}
													}
								
													silicon_ioi.doctype.ioiPriceUpdatingTool.item_type = [];
													silicon_ioi.doctype.ioiPriceUpdatingTool.item_type[0] = ['', ''];
													document.getElementById('update_purchases_type_id').options[document.getElementById('update_purchases_type_id').options.length] = new Option('', '');												
										

													for (var i = 0; i < r.message.length; i++)
													{	
														silicon_ioi.doctype.ioiPriceUpdatingTool.item_type[i+1] = [r.message[i][0], r.message[i][1]];

														document.getElementById('update_purchases_type_id').options[document.getElementById('update_purchases_type_id').options.length] = new Option(silicon_ioi.doctype.ioiPriceUpdatingTool.item_type[i+1][1], silicon_ioi.doctype.ioiPriceUpdatingTool.item_type[i+1][0]);													

													}

													silicon_ioi.doctype.ioiPriceUpdatingTool.item_group = [];
													silicon_ioi.doctype.ioiPriceUpdatingTool.item_group[0] = ['', ''];
													document.getElementById('update_purchases_group_id').options[document.getElementById('update_purchases_group_id').options.length] = new Option('', '');												
										
													silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup = [];
													silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[0] = ['', ''];			
													document.getElementById('update_purchases_subgroup_id').options[document.getElementById('update_purchases_subgroup_id').options.length] = new Option('', '');												
										
									
												}
			});
		}
	}


	// ***************************************************************************************************************************************
	// Fill Item Group
	// ***************************************************************************************************************************************
	static update_purchases_fill_item_group(action='')
	{	
		if ((!document.getElementById('update_purchases_type_id')) || ((document.getElementById('update_purchases_type_id')) && (document.getElementById('update_purchases_type_id').value.trim() == '')))
		{

			if (action != '')
			{

				for (var i = document.getElementById('update_purchases_group_id').options.length-1; i >= 0; i--) {
					document.getElementById('update_purchases_group_id').options[i].remove();	
				}
				
				for (var i = document.getElementById('update_purchases_subgroup_id').options.length-1; i >= 0; i--) {
					document.getElementById('update_purchases_subgroup_id').options[i].remove();	
				}

			}

			silicon_ioi.doctype.ioiPriceUpdatingTool.item_group = [];
			silicon_ioi.doctype.ioiPriceUpdatingTool.item_group[0] = ['', ''];
			document.getElementById('update_purchases_group_id').options[document.getElementById('update_purchases_group_id').options.length] = new Option('', '');												

			silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup = [];
			silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[0] = ['', ''];			
			document.getElementById('update_purchases_subgroup_id').options[document.getElementById('update_purchases_subgroup_id').options.length] = new Option('', '');												

		}else
		{
			let me = this;
			let amethod =  'silicon_ioi.ioi_configuration.doctype.ioi_item_group.ioi_item_group.ioi_item_group_get_groups';

			frappe.call({  	method: amethod,
							args: {"family_id": document.getElementById('update_purchases_family_id').value, "type_id": document.getElementById('update_purchases_type_id').value, "enabled":1, "current_group": silicon_ioi.doctype.ioiPriceUpdatingTool.current_group},
							async: false,
							callback:function(r){   
													if (action != '')
													{
														for (var i = document.getElementById('update_purchases_group_id').options.length-1; i >= 0; i--) {
															document.getElementById('update_purchases_group_id').options[i].remove();	
														}
														
														for (var i = document.getElementById('update_purchases_subgroup_id').options.length-1; i >= 0; i--) {
															document.getElementById('update_purchases_subgroup_id').options[i].remove();	
														}

													}

													silicon_ioi.doctype.ioiPriceUpdatingTool.item_group = [];
													silicon_ioi.doctype.ioiPriceUpdatingTool.item_group[0] = ['', ''];
													document.getElementById('update_purchases_group_id').options[document.getElementById('update_purchases_group_id').options.length] = new Option('', '');												


													for (var i = 0; i < r.message.length; i++)
													{	
														silicon_ioi.doctype.ioiPriceUpdatingTool.item_group[i+1] = [r.message[i][0], r.message[i][1]];

														document.getElementById('update_purchases_group_id').options[document.getElementById('update_purchases_group_id').options.length] = new Option(silicon_ioi.doctype.ioiPriceUpdatingTool.item_group[i+1][1], silicon_ioi.doctype.ioiPriceUpdatingTool.item_group[i+1][0]);													
													}

													silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup = [];
													silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[0] = ['', ''];			
													document.getElementById('update_purchases_subgroup_id').options[document.getElementById('update_purchases_subgroup_id').options.length] = new Option('', '');												

												}
			});
		}
	}


	// ***************************************************************************************************************************************
	// Fill Item Subgroup
	// ***************************************************************************************************************************************
	static update_purchases_fill_item_subgroup(action='')
	{	
		if ((!document.getElementById('update_purchases_group_id')) || ((document.getElementById('update_purchases_group_id')) && (document.getElementById('update_purchases_group_id').value.trim() == '')))
		{
			if (action != '')
			{
				for (var i = document.getElementById('update_purchases_subgroup_id').options.length-1; i >= 0; i--) {
					document.getElementById('update_purchases_subgroup_id').options[i].remove();	
				}
			}

			silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup = [];
			silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[0] = ['', ''];			
			document.getElementById('update_purchases_subgroup_id').options[document.getElementById('update_purchases_subgroup_id').options.length] = new Option('', '');												

		}else
		{
			let me = this;
			let amethod = 'silicon_ioi.ioi_configuration.doctype.ioi_item_subgroup.ioi_item_subgroup.ioi_item_subgroup_get_subgroups';

			frappe.call({  	method: amethod,
							args: {"family_id": document.getElementById('update_purchases_family_id').value, "type_id": document.getElementById('update_purchases_type_id').value, "group_id": document.getElementById('update_purchases_group_id').value, "enabled":1, "current_subgroup": silicon_ioi.doctype.ioiPriceUpdatingTool.current_subgroup},
							async: false,
							callback:function(r){   
													if (action != '')
													{
														for (var i = document.getElementById('update_purchases_subgroup_id').options.length-1; i >= 0; i--) {
															document.getElementById('update_purchases_subgroup_id').options[i].remove();	
														}
													}

													silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup = [];
													silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[0] = ['', ''];
													document.getElementById('update_purchases_subgroup_id').options[document.getElementById('update_purchases_subgroup_id').options.length] = new Option('', '');												



													for (var i = 0; i < r.message.length; i++)
													{	
														silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[i+1] = [r.message[i][0], r.message[i][1]];
														document.getElementById('update_purchases_subgroup_id').options[document.getElementById('update_purchases_subgroup_id').options.length] = new Option(silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[i+1][1], silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[i+1][0]);													
													}
												}
			});
		}
	}

	// ***************************************************************************************************************************************
	// Clear values
	// ***************************************************************************************************************************************

	static do_update_purchases_clear_values()
	{
		document.getElementById("update_purchases_cb_increase").checked = false;
		document.getElementById("update_purchases_increase_unit_price").value = '';

		document.getElementById("update_purchases_cb_fixed_price").checked = false;
		document.getElementById("update_purchases_update_fixed_price").value = '';


		document.getElementById("update_purchases_cb_base_discount").checked = false;
		document.getElementById("update_purchases_update_base_discount").value = '';

		document.getElementById("update_purchases_cb_extra_discount").checked = false;
		document.getElementById("update_purchases_update_extra_discount").value = '';
		
		document.getElementById('update_purchases_update_rounding_kind_label').value = __('Decimals');
		document.getElementById('update_purchases_update_rounding_kind').selectedIndex = 0;
		document.getElementById('update_purchases_update_rounding_method').selectedIndex = 0;
		document.getElementById('update_purchases_update_rounding_value').value = '2';		

		document.getElementById("update_purchases_cb_convert").checked = false;
		document.getElementById("update_purchases_old_currency").value = '';
		document.getElementById("update_purchases_new_currency").value = '';
	}

	// ***************************************************************************************************************************************
	// Set Enabled
	// ***************************************************************************************************************************************
	static do_update_purchases_set_enabled()
	{
		if (!document.getElementById('update_purchases_detail')) {
			frappe.msgprint({title: __("Message"), message: __('No selected purchases prices'), indicator: "red"});
			raise;
		}else {
			if (document.getElementById('update_purchases_detail').rows.length == 0) {
				frappe.msgprint({title: __("Message"), message: __('No selected purchases prices'), indicator: "red"});
				raise;
			}else {

				let at_least_one_checked = false;

				for (var i = 0; i < document.getElementById('update_purchases_detail').rows.length; i++) {
					
					if (!document.getElementById('update_purchases_checked_id_' + i.toString()))
					{
						frappe.msgprint({title: __("Message"), message: __('No selected purchases prices'), indicator: "red"});
						raise;
					}else {
						if (document.getElementById('update_purchases_checked_id_' + i.toString()).checked)
						{
							at_least_one_checked = true;
							break;
						}
					}
				}

				if (!at_least_one_checked) {
					frappe.msgprint({title: __("Message"), message: __('No selected purchases prices'), indicator: "red"});
					raise;
				}
			}
		}

		let nb_selected_rows = 0

		for (var i = 0; i < document.getElementById('update_purchases_detail').rows.length; i++) {	
			if (document.getElementById("update_purchases_checked_id_" + i.toString()))	{										
				if (document.getElementById('update_purchases_checked_id_' + i.toString()).checked)	{
					nb_selected_rows++;
				}
			}
		}

		let msg = '';

		if (nb_selected_rows <= 1) {
			msg = 'Set enabled ' + nb_selected_rows.toString() + ' selected purchases price ?';
		}else {
			msg = 'Set enabled ' + nb_selected_rows.toString() + ' selected purchases prices ?';
		}


		frappe.confirm(	msg,
						() => 	{	
									document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

									let selected_lines = [];
									let purchases_prices_selected_lines = '';

									for (var i = 0; i < document.getElementById('update_purchases_detail').rows.length; i++) {	
										if (document.getElementById("update_purchases_checked_id_" + i.toString()))	{
											if (document.getElementById('update_purchases_checked_id_' + i.toString()).checked)	{

												selected_lines[selected_lines.length] = document.getElementById('update_purchases_detail_name_' + i.toString()).value;
												purchases_prices_selected_lines += document.getElementById('update_purchases_detail_name_' + i.toString()).value + '#sbsepa#'
												
											}
										}
									}

									if (purchases_prices_selected_lines != '')
									{
										let method = 'silicon_ioi.ioi_items.doctype.ioi_purchases_price.ioi_purchases_price.ioi_purchases_prices_set_enabled_selected_purchases_prices';

										frappe.call({  	method: method, 
														args: {"selected_lines":purchases_prices_selected_lines},
														async: false,
														callback:function(r)	{ 
																	}
										});
					
										silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_purchases_refresh();	
										
										silicon_ioi.doctype.ioiPriceUpdatingTool.sleep_static(200).then(() => {

                                            let nb_selected_rows = 0;

											for (var i = 0; i < document.getElementById('update_purchases_detail').rows.length; i++) {	

												if (document.getElementById("update_purchases_checked_id_" + i.toString()))	{
													document.getElementById('update_purchases_checked_id_' + i.toString()).checked = false;

													if (selected_lines.indexOf(document.getElementById('update_purchases_detail_name_' + i.toString()).value) != -1)
													{	
														document.getElementById('update_purchases_checked_id_' + i.toString()).checked = true;
														nb_selected_rows++;
													}
												}
											}

                                   
                                            if (document.getElementById("update_purchases_grid_banner"))
                                            {	
                                                if (nb_selected_rows <= 1) {
                                                    document.getElementById("update_purchases_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected purchases price (prices updated by contracts have been excluded)")
                                                }else {
                                                    document.getElementById("update_purchases_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected purchases prices (prices updated by contracts have been excluded)")
                                                }
                                            }
                                    
										});
										
									}
								}, 
						() => 	{	
								}
		);

	}

	// ***************************************************************************************************************************************
	// Set Disabled
	// ***************************************************************************************************************************************
	static do_update_purchases_set_disabled()
	{
		if (!document.getElementById('update_purchases_detail')) {
			frappe.msgprint({title: __("Message"), message: __('No selected purchases prices'), indicator: "red"});
			raise;
		}else {
			if (document.getElementById('update_purchases_detail').rows.length == 0) {
				frappe.msgprint({title: __("Message"), message: __('No selected purchases prices'), indicator: "red"});
				raise;
			}else {

				let at_least_one_checked = false;

				for (var i = 0; i < document.getElementById('update_purchases_detail').rows.length; i++) {
					
					if (!document.getElementById('update_purchases_checked_id_' + i.toString()))
					{
						frappe.msgprint({title: __("Message"), message: __('No selected purchases prices'), indicator: "red"});
						raise;
					}else {
						if (document.getElementById('update_purchases_checked_id_' + i.toString()).checked)
						{
							at_least_one_checked = true;
							break;
						}
					}
				}

				if (!at_least_one_checked) {
					frappe.msgprint({title: __("Message"), message: __('No selected purchases prices'), indicator: "red"});
					raise;
				}
			}
		}


		let nb_selected_rows = 0

		for (var i = 0; i < document.getElementById('update_purchases_detail').rows.length; i++) {	
			if (document.getElementById("update_purchases_checked_id_" + i.toString()))	{										
				if (document.getElementById('update_purchases_checked_id_' + i.toString()).checked)	{
					nb_selected_rows++;
				}
			}
		}

		let msg = '';

		if (nb_selected_rows <= 1) {
			msg = 'Set disabled ' + nb_selected_rows.toString() + ' selected purchases price ?';
		}else {
			msg = 'Set disabled ' + nb_selected_rows.toString() + ' selected purchases prices ?';
		}

		frappe.confirm(	msg,
						() => 	{	
									document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

									let selected_lines = [];
									let purchases_prices_selected_lines = '';

									for (var i = 0; i < document.getElementById('update_purchases_detail').rows.length; i++) {	
										if (document.getElementById("update_purchases_checked_id_" + i.toString()))	{
											if (document.getElementById('update_purchases_checked_id_' + i.toString()).checked)	{

												selected_lines[selected_lines.length] = document.getElementById('update_purchases_detail_name_' + i.toString()).value;
												purchases_prices_selected_lines += document.getElementById('update_purchases_detail_name_' + i.toString()).value + '#sbsepa#'
												
											}
										}
									}

									if (purchases_prices_selected_lines != '')
									{
										let method = 'silicon_ioi.ioi_items.doctype.ioi_purchases_price.ioi_purchases_price.ioi_purchases_prices_set_disabled_selected_purchases_prices';

										frappe.call({  	method: method, 
														args: {"selected_lines":purchases_prices_selected_lines},
														async: false,
														callback:function(r)	{ 
																	}
										});
					
										silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_purchases_refresh();
										
										
										silicon_ioi.doctype.ioiPriceUpdatingTool.sleep_static(200).then(() => {

                                            let nb_selected_rows = 0;

											for (var i = 0; i < document.getElementById('update_purchases_detail').rows.length; i++) {	
												if (document.getElementById("update_purchases_checked_id_" + i.toString()))	{
													document.getElementById('update_purchases_checked_id_' + i.toString()).checked = false;

													if (selected_lines.indexOf(document.getElementById('update_purchases_detail_name_' + i.toString()).value) != -1)
													{	
														document.getElementById('update_purchases_checked_id_' + i.toString()).checked = true;
														nb_selected_rows++;
													}
												}
											}

                                   
                                            if (document.getElementById("update_purchases_grid_banner"))
                                            {	
                                                if (nb_selected_rows <= 1) {
                                                    document.getElementById("update_purchases_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected purchases price (prices updated by contracts have been excluded)")
                                                }else {
                                                    document.getElementById("update_purchases_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected purchases prices (prices updated by contracts have been excluded)")
                                                }
                                            }

										});

									}

								}, 
						() => 	{	
								}
		);

	}

	// ***************************************************************************************************************************************
	// Delete disabled selected purchases prices
	// ***************************************************************************************************************************************
	static do_update_purchases_delete_selected_disabled()
	{
		if (!document.getElementById('update_purchases_detail')) {
			frappe.msgprint({title: __("Message"), message: __('No selected purchases prices'), indicator: "red"});
			raise;
		}else {
			if (document.getElementById('update_purchases_detail').rows.length == 0) {
				frappe.msgprint({title: __("Message"), message: __('No selected purchases prices'), indicator: "red"});
				raise;
			}else {

				let at_least_one_checked = false;

				for (var i = 0; i < document.getElementById('update_purchases_detail').rows.length; i++) {

					if (!document.getElementById('update_purchases_checked_id_' + i.toString()))
					{
						frappe.msgprint({title: __("Message"), message: __('No selected purchases prices'), indicator: "red"});
						raise;
					}else {
						if (document.getElementById('update_purchases_checked_id_' + i.toString()).checked)
						{
							if (document.getElementById('update_purchases_detail_enabled_' + i.toString()).value != 1)
							{
								at_least_one_checked = true;
								break;
							}
						}
					}
				}

				if (!at_least_one_checked) {
					frappe.msgprint({title: __("Message"), message: __('No selected disabled purchases prices'), indicator: "red"});
					raise;
				}
			}
		}


		let nb_selected_rows = 0

		for (var i = 0; i < document.getElementById('update_purchases_detail').rows.length; i++) {	
										
			if (document.getElementById('update_purchases_checked_id_' + i.toString()).checked)	{
				if (document.getElementById('update_purchases_detail_enabled_' + i.toString()).value != 1) {
					nb_selected_rows++;
				}
			}
		}

		let msg = '';

		if (nb_selected_rows <= 1) {
			msg = 'Delete ' + nb_selected_rows.toString() + ' selected purchases price disabled ?';
		}else {
			msg = 'Delete ' + nb_selected_rows.toString() + ' selected purchases prices disabled ?';
		}

		frappe.confirm(	msg,
						() => 	{	
									document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

									let selected_lines = [];
									let purchases_prices_selected_lines = '';

									for (var i = 0; i < document.getElementById('update_purchases_detail').rows.length; i++) {	
										
										if (document.getElementById('update_purchases_checked_id_' + i.toString()).checked)	{

											if (document.getElementById('update_purchases_detail_enabled_' + i.toString()).value != 1) {											

												selected_lines[selected_lines.length] = document.getElementById('update_purchases_detail_name_' + i.toString()).value;
												purchases_prices_selected_lines += document.getElementById('update_purchases_detail_name_' + i.toString()).value + '#sbsepa#'
											}
											
										}
									}

									if (purchases_prices_selected_lines != '')
									{
										let method = 'silicon_ioi.ioi_items.doctype.ioi_purchases_price.ioi_purchases_price.ioi_purchases_prices_delete_selected_purchases_prices_disabled';

										frappe.call({  	method: method, 
														args: {"selected_lines": purchases_prices_selected_lines},
														async: false,
														callback:function(r)	{ 
																	}
										});
					
										silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_purchases_refresh();

									}

								}, 
						() => 	{	
								}
		);

	}





	// ***************************************************************************************************************************************
	// Update purchases prices
	// ***************************************************************************************************************************************

	static do_update_purchases_update_price()
	{
		if (!document.getElementById('update_purchases_detail')) {
			frappe.msgprint({title: __("Message"), message: __('No selected purchases prices'), indicator: "red"});
			raise;
		}else {
			if (document.getElementById('update_purchases_detail').rows.length == 0) {
				frappe.msgprint({title: __("Message"), message: __('No selected purchases prices'), indicator: "red"});
				raise;
			}else {

				let at_least_one_checked = false;

				for (var i = 0; i < document.getElementById('update_purchases_detail').rows.length; i++) {
					
					if (!document.getElementById('update_purchases_checked_id_' + i.toString()))
					{
						frappe.msgprint({title: __("Message"), message: __('No selected purchases prices'), indicator: "red"});
						raise;
					}else {
						if (document.getElementById('update_purchases_checked_id_' + i.toString()).checked)
						{
							at_least_one_checked = true;
							break;
						}
					}
				}

				if (!at_least_one_checked) {
					frappe.msgprint({title: __("Message"), message: __('No selected purchases prices'), indicator: "red"});
					raise;
				}
			}
		}


		let cb_increase = 0;
		
		if (document.getElementById("update_purchases_cb_increase").checked) {	
			cb_increase = 1;
		}


		let cb_fixed_price = 0;
		
		if (document.getElementById("update_purchases_cb_fixed_price").checked) {	
			cb_fixed_price = 1;
		}

		let cb_base_discount = 0;
		
		if (document.getElementById("update_purchases_cb_base_discount").checked) {	
			cb_base_discount = 1;
		}


		let cb_extra_discount = 0;
		
		if (document.getElementById("update_purchases_cb_extra_discount").checked) {	
			cb_extra_discount = 1;
		}


		let cb_convert = 0;
		
		if (document.getElementById("update_purchases_cb_convert").checked) {	
			cb_convert = 1;
		}


		let increase_unit_price = 0;

		if ((document.getElementById("update_purchases_increase_unit_price")) && (document.getElementById("update_purchases_increase_unit_price").value.trim() != '')) {
			increase_unit_price = parseFloat(document.getElementById("update_purchases_increase_unit_price").value);
		}


		let update_fixed_price = 0;

		if ((document.getElementById("update_purchases_update_fixed_price")) && (document.getElementById("update_purchases_update_fixed_price").value.trim() != '')) {
			update_fixed_price = parseFloat(document.getElementById("update_purchases_update_fixed_price").value);
		}


		let update_base_discount = 0;

		if ((document.getElementById("update_purchases_update_base_discount")) && (document.getElementById("update_purchases_update_base_discount").value.trim() != '')) {
			update_base_discount = parseFloat(document.getElementById("update_purchases_update_base_discount").value);
		}

		if ((update_base_discount < 0) || (update_base_discount > 100)) {

			frappe.msgprint({title: __("Message"), message: __('Base discount has to be between 0 and 100'), indicator: "red"});
			raise;
		}

		let update_extra_discount = 0;

		if ((document.getElementById("update_purchases_update_extra_discount")) && (document.getElementById("update_purchases_update_extra_discount").value.trim() != '')) {
			update_extra_discount = parseFloat(document.getElementById("update_purchases_update_extra_discount").value);
		}

		if ((update_extra_discount < 0) || (update_extra_discount > 100)) {

			frappe.msgprint({title: __("Message"), message: __('Extra discount has to be between 0 and 100'), indicator: "red"});
			raise;
		}

		if (update_base_discount + update_extra_discount > 100) {
			frappe.msgprint({title: __("Message"), message: __('Sum of base and extra discount has to be between 0 and 100'), indicator: "red"});
			raise;

		}



		let rounding_kind = document.getElementById('update_purchases_update_rounding_kind').value;
		let rounding_method = document.getElementById('update_purchases_update_rounding_method').value;

		let rounding_value = 0;
		if ((document.getElementById("update_purchases_update_rounding_value")) && (document.getElementById("update_purchases_update_rounding_value").value.trim() != '')) {
			rounding_value = document.getElementById("update_purchases_update_rounding_value").value;
		}

		if (rounding_value < 0) {
			frappe.msgprint({title: __("Message"), message: __('Rounding value has to be greater than or equal to zero'), indicator: "red"});
			raise;
		}


		let old_currency = '';
		let new_currency = '';

		let go = true;

		if (cb_convert == 1)
		{
			if  ((!document.getElementById("update_purchases_old_currency")) || ((document.getElementById("update_purchases_old_currency")) && (document.getElementById("update_purchases_old_currency").value.trim() == ''))) {

				frappe.msgprint({title: __("Message"), message: __('Old currency is mandatory'), indicator: "red"});
				raise;

			}

			// Check Old Currency
			let meth = 'silicon_ioi.ioi_items.doctype.ioi_purchases_price.ioi_purchases_price.ioi_purchases_prices_check_currency';


			frappe.call({  	method: meth, 
							args: {	"currency_id": document.getElementById("update_purchases_old_currency").value },
							async: false,
							callback:function(r)	{ 
														if ((r.message == null) || (r.message == 0))
														{
															frappe.msgprint({title: __("Message"), message: __('Old currency does not exist'), indicator: "red"});
															go = false;
															raise;
									
														}else {
															old_currency = document.getElementById("update_purchases_old_currency").value;
														}

													}
			});




			if  ((!document.getElementById("update_purchases_new_currency")) || ((document.getElementById("update_purchases_new_currency")) && (document.getElementById("update_purchases_new_currency").value.trim() == ''))) {					

				frappe.msgprint({title: __("Message"), message: __('New currency is mandatory'), indicator: "red"});
				raise;

			}


			// Check New Currency
			frappe.call({  	method: meth, 
							args: {	"currency_id": document.getElementById("update_purchases_new_currency").value },
							async: false,
							callback:function(r)	{ 
														if ((r.message == null) || (r.message == 0))
														{
															frappe.msgprint({title: __("Message"), message: __('New currency does not exist'), indicator: "red"});
															go = false;
															raise;
									
														}else {
															new_currency = document.getElementById("update_purchases_new_currency").value;
														}

													}
				});


			if (old_currency.toUpperCase() == new_currency.toUpperCase()) {

				frappe.msgprint({title: __("Message"), message: __('Old and new currency have to be different'), indicator: "red"});
				go = false;
				raise;

			}
		}else {
			old_currency = document.getElementById("update_purchases_old_currency").value;
			new_currency = document.getElementById("update_purchases_new_currency").value;
		}

		if (go) {

			let nb_selected_rows = 0

			for (var i = 0; i < document.getElementById('update_purchases_detail').rows.length; i++) {	
				if (document.getElementById("update_purchases_checked_id_" + i.toString()))	{											
					if (document.getElementById('update_purchases_checked_id_' + i.toString()).checked)	{
						nb_selected_rows++;
					}
				}
			}
	
			let msg = '';


			let top = 10;

			msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;">' + __("Selected purchases prices : ") + '<b>' +  nb_selected_rows.toString() + '</b></label>';	
			
			top += 25;


			if (cb_convert == 1)
			{
				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;">' + __("Create in the currency and update values (created records only)") + '</label>';

				top += 25;


				let s = '- ' + __("Old Currency");

				s += ' <b>' + old_currency + '</b> to ' + __("new Currency") + ' <b>' + new_currency + '</b>';

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

				top += 25;
					

			}else{
				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;">' + __("Update selected prices") + '</label>';

				top += 25;

			}

			if (cb_increase == 1) {

				let s = '- ' + __("Increasing unit price of");

				s += ' <b>' + increase_unit_price.toString() + '</b> %';

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

				top += 25;
				
			}

			if (cb_fixed_price == 1) {

				let s = '- ' + __("Update fixed price");

				s += ' : <b>' + update_fixed_price.toString() + '</b>';

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

				top += 25;
				
			}

			if (cb_base_discount == 1) {

				let s = '- ' + __("Update base discount");

				s += ' : <b>' + update_base_discount.toString() + '</b> %';

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

				top += 25;
				
			}


			if (cb_extra_discount == 1) {

				let s = '- ' + __("Update extra discount");

				s += ' : <b>' + update_extra_discount.toString() + '</b> %';

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

				top += 25;
				
			}


			let s = '- ' + __("Rounding");

			if (document.getElementById('update_purchases_update_rounding_kind').value == 0) {
				s += ' <b>' + __('Decimals') + '</b>';
			}else if (document.getElementById('update_purchases_update_rounding_kind').value == 1) {
				s += ' <b>' + __('Multiple') + '</b>';
			}

			s += ' ' + rounding_value;

			s += ' - ' + __('Method'); 

			if (document.getElementById('update_purchases_update_rounding_method').value == 0) {
				s += ' <b>' + __('Nearest') + '</b>';
			}else if (document.getElementById('update_purchases_update_rounding_method').value == 1) {
				s += ' <b>' + __('Above') + '</b>';
			}else{
				s += ' <b>' + __('Under') + '</b>';
			}

			msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';
			

			top += 50;


			s = '<font color="blue">Are you sure to launch the update ?</font>';
			msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';					


			top += 40;
			msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';



	
			frappe.confirm(	msg,
							() => 	{	
										document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

										let purchases_prices_selected_lines = '';
										let selected_lines = [];
	
										for (var i = 0; i < document.getElementById('update_purchases_detail').rows.length; i++) {	
											if (document.getElementById("update_purchases_checked_id_" + i.toString()))	{
												if (document.getElementById('update_purchases_checked_id_' + i.toString()).checked)	{
		
													purchases_prices_selected_lines += document.getElementById('update_purchases_detail_name_' + i.toString()).value + '#sbsepa#'

													selected_lines[selected_lines.length] = document.getElementById('update_purchases_detail_name_' + i.toString()).value;
													
												}
											}
										}

										
	
										if (purchases_prices_selected_lines != '')
										{
											let meth = 'silicon_ioi.ioi_items.doctype.ioi_purchases_price.ioi_purchases_price.ioi_purchases_prices_update_purchases_prices';
	
											frappe.call({  	method: meth, 
															args: {	"selected_lines": purchases_prices_selected_lines,

																	"cb_increase": cb_increase, 
																	"increase_unit_price": increase_unit_price, 
																	"cb_fixed_price": cb_fixed_price, 
																	"update_fixed_price": update_fixed_price, 
																	"cb_base_discount": cb_base_discount , 
																	"update_base_discount": update_base_discount,
																	"cb_extra_discount": cb_extra_discount, 
																	"update_extra_discount": update_extra_discount, 

																	"rounding_kind": rounding_kind,
																	"rounding_method": rounding_method,
																	"rounding_value": rounding_value,

																	"cb_convert": cb_convert, 
																	"old_currency": old_currency, 
																	"new_currency": new_currency
													
													
															},
															async: false,
															callback:function(r)	{ 
																							let inserted = r.message.inserted;
																							let updated = r.message.updated;

                                                                                            silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_purchases_refresh();

																							silicon_ioi.doctype.ioiPriceUpdatingTool.sleep_static(200).then(() => {

                                                                                                let nb_selected_rows = 0;

                                                                                                for (var i = 0; i < document.getElementById('update_purchases_detail').rows.length; i++) {	
																									if (document.getElementById("update_purchases_checked_id_" + i.toString()))	{
																										document.getElementById('update_purchases_checked_id_' + i.toString()).checked = false;
														
																										if (selected_lines.indexOf(document.getElementById('update_purchases_detail_name_' + i.toString()).value) != -1)
																										{	
																											document.getElementById('update_purchases_checked_id_' + i.toString()).checked = true;
																											nb_selected_rows++;
																										}
																									}
                                                                                                }
                                                    
                                                                                       
                                                                                                if (document.getElementById("update_purchases_grid_banner"))
                                                                                                {	
                                                                                                    if (nb_selected_rows <= 1) {
                                                                                                        document.getElementById("update_purchases_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected purchases price (prices updated by contracts have been excluded)")
                                                                                                    }else {
                                                                                                        document.getElementById("update_purchases_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected purchases prices (prices updated by contracts have been excluded)")
                                                                                                    }
                                                                                                }

																								let top = 0;

																								let msg = '<label style="position: absolute; top: ' + top.toString() + 'px; left: 12px;"> ' + __('Prices updated with success') + '</label>';
																								top += 25;

																								if (inserted != 0) {
                                                    												msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 12px;"> ' + __('Inserted record(s)') + ' : ' + inserted.toString() + '</label>';
																									top += 25;
																								}

																								if (updated != 0) {
																									msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 12px;"> ' + __('Updated record(s)') + ' : ' + updated.toString() + '</label>';
																									top += 25;
																								}

																								msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';

																								frappe.msgprint({title: __("Message"), message: msg, indicator: "blue"});
																							});

																		}
											});
						
											
										}
	
									}, 
							() => 	{	
									}
			);
		}

	}


    // ***************************************************************************************************************************************
	// 
	// Purchases Price : Generate Sales Prices
    //
	// ***************************************************************************************************************************************


	// ***************************************************************************************************************************************
	// Load generate sales prices tool
	// ***************************************************************************************************************************************
	static load_generate_sales_prices(html_field)
	{
		silicon_ioi.doctype.ioiPriceUpdatingTool.is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

		silicon_ioi.doctype.ioiPriceUpdatingTool.do_generate_sales_prices(html_field);
	}



	// ***************************************************************************************************************************************
	// Do Generate sales prices
	// ***************************************************************************************************************************************

	static do_generate_sales_prices(html_field)	
	{
		if (document.getElementById("generate_sales_supplier_label"))
		{	document.getElementById("generate_sales_supplier_label").remove();
		}

		if (document.getElementById("generate_sales_supplier_id"))
		{	document.getElementById("generate_sales_supplier_id").remove();
		}

		if (document.getElementById("generate_sales_select_supplier_button"))
		{	document.getElementById("generate_sales_select_supplier_button").remove();
		}



		if (document.getElementById("generate_sales_purchases_condition_label"))
		{	document.getElementById("generate_sales_purchases_condition_label").remove();
		}

		if (document.getElementById("generate_sales_purchases_condition_id"))
		{	document.getElementById("generate_sales_purchases_condition_id").remove();
		}

		if (document.getElementById("generate_sales_item_label"))
		{	document.getElementById("generate_sales_item_label").remove();
		}

		if (document.getElementById("generate_sales_item_id"))
		{	document.getElementById("generate_sales_item_id").remove();
		}

		if (document.getElementById("generate_sales_select_item_button"))
		{	document.getElementById("generate_sales_select_item_button").remove();
		}



		if (document.getElementById("generate_sales_unit_label"))
		{	document.getElementById("generate_sales_unit_label").remove();
		}

		if (document.getElementById("generate_sales_unit_id"))
		{	document.getElementById("generate_sales_unit_id").remove();
		}


		if (document.getElementById("generate_sales_currency_label"))
		{	document.getElementById("generate_sales_currency_label").remove();
		}

		if (document.getElementById("generate_sales_currency_id"))
		{	document.getElementById("generate_sales_currency_id").remove();
		}

		if (document.getElementById("generate_sales_select_currency_button"))
		{	document.getElementById("generate_sales_select_currency_button").remove();
		}


		if (document.getElementById("generate_sales_site_label"))
		{	document.getElementById("generate_sales_site_label").remove();
		}

		if (document.getElementById("generate_sales_site_id"))
		{	document.getElementById("generate_sales_site_id").remove();
		}

		if (document.getElementById("generate_sales_enabled_label"))
		{	document.getElementById("generate_sales_enabled_label").remove();
		}

		if (document.getElementById("generate_sales_enabled"))
		{	document.getElementById("generate_sales_enabled").remove();
		}


		if (document.getElementById("generate_sales_family_label"))
		{	document.getElementById("generate_sales_family_label").remove();
		}

		if (document.getElementById("generate_sales_family_id"))
		{	document.getElementById("generate_sales_family_id").remove();
		}
		
		if (document.getElementById("generate_sales_type_label"))
		{	document.getElementById("generate_sales_type_label").remove();
		}

		if (document.getElementById("generate_sales_type_id"))
		{	document.getElementById("generate_sales_type_id").remove();
		}
		
		if (document.getElementById("generate_sales_group_label"))
		{	document.getElementById("generate_sales_group_label").remove();
		}

		if (document.getElementById("generate_sales_group_id"))
		{	document.getElementById("generate_sales_group_id").remove();
		}
		
		if (document.getElementById("generate_sales_subgroup_label"))
		{	document.getElementById("generate_sales_subgroup_label").remove();
		}

		if (document.getElementById("generate_sales_subgroup_id"))
		{	document.getElementById("generate_sales_subgroup_id").remove();
		}
		
		if (document.getElementById("generate_sales_classification_label"))
		{	document.getElementById("generate_sales_classification_label").remove();
		}

		if (document.getElementById("generate_sales_classification_id"))
		{	document.getElementById("generate_sales_classification_id").remove();
		}
		
		
		if (document.getElementById("generate_sales_shop_category_label"))
		{	document.getElementById("generate_sales_shop_category_label").remove();
		}

		if (document.getElementById("generate_sales_shop_category_id"))
		{	document.getElementById("generate_sales_shop_category_id").remove();
		}

		if (document.getElementById("generate_sales_grid_banner"))
		{	document.getElementById("generate_sales_grid_banner").remove();
		}

		if (document.getElementById("generate_sales_grid"))
		{	document.getElementById("generate_sales_grid").remove();
		}

		if (document.getElementById("generate_sales_check_all_none")) {
			document.getElementById("generate_sales_check_all_none").remove();
		}

		if (document.getElementById("generate_sales_detail")) {
			
			for (var i = 0; i < document.getElementById("generate_sales_detail").rows.length; i++)
			{
				if (document.getElementById("generate_sales_checked_id_" + i.toString()))	{
					document.getElementById("generate_sales_checked_id_" + i.toString()).remove();
				}
			}

			document.getElementById("generate_sales_detail").remove();
		}


		if (document.getElementById("generate_sales_content")) {
			document.getElementById("generate_sales_content").remove();
		}

		
		if (document.getElementById("generate_sales_search"))
		{	document.getElementById("generate_sales_search").remove();
		}

		if (document.getElementById("generate_sales_clear"))
		{	document.getElementById("generate_sales_clear").remove();
		}

		if (document.getElementById("generate_sales_orderby"))
		{	document.getElementById("generate_sales_orderby").remove();
		}

		if (document.getElementById("generate_sales_order"))
		{	document.getElementById("generate_sales_order").remove();
		}

		if (document.getElementById("generate_sales_cb_do_not_take_base_discount"))
		{	document.getElementById("generate_sales_cb_do_not_take_base_discount").remove();
		}

		if (document.getElementById("generate_sales_do_not_take_base_discount_label"))
		{	document.getElementById("generate_sales_do_not_take_base_discount_label").remove();
		}


		if (document.getElementById("generate_sales_cb_do_not_take_extra_discount"))
		{	document.getElementById("generate_sales_cb_do_not_take_extra_discount").remove();
		}

		if (document.getElementById("generate_sales_do_not_take_extra_discount_label"))
		{	document.getElementById("generate_sales_do_not_take_extra_discount_label").remove();
		}

		if (document.getElementById("generate_sales_margin_kind_label"))
		{	document.getElementById("generate_sales_margin_kind_label").remove();
		}

		if (document.getElementById("generate_sales_margin_kind"))
		{	document.getElementById("generate_sales_margin_kind").remove();
		}

		if (document.getElementById("generate_sales_margin_percentage_label"))
		{	document.getElementById("generate_sales_margin_percentage_label").remove();
		}

		if (document.getElementById("generate_sales_margin_percentage"))
		{	document.getElementById("generate_sales_margin_percentage").remove();
		}

		if (document.getElementById("generate_sales_margin_percentage_percent"))
		{	document.getElementById("generate_sales_margin_percentage_percent").remove();
		}

		if (document.getElementById("generate_sales_cb_min_margin"))
		{	document.getElementById("generate_sales_cb_min_margin").remove();
		}

		if (document.getElementById("generate_sales_min_margin_label"))
		{	document.getElementById("generate_sales_min_margin_label").remove();
		}

		if (document.getElementById("generate_sales_min_margin"))
		{	document.getElementById("generate_sales_min_margin").remove();
		}

		if (document.getElementById("generate_sales_min_margin_percent"))
		{	document.getElementById("generate_sales_min_margin_percent").remove();
		}

		if (document.getElementById("generate_sales_purchases_price_inf_label"))
		{	document.getElementById("generate_sales_purchases_price_inf_label").remove();
		}

		if (document.getElementById("generate_sales_purchases_price_inf"))
		{	document.getElementById("generate_sales_purchases_price_inf").remove();
		}


		if (document.getElementById("generate_sales_cb_max_margin"))
		{	document.getElementById("generate_sales_cb_max_margin").remove();
		}

		if (document.getElementById("generate_sales_max_margin_label"))
		{	document.getElementById("generate_sales_max_margin_label").remove();
		}

		if (document.getElementById("generate_sales_max_margin"))
		{	document.getElementById("generate_sales_max_margin").remove();
		}

		if (document.getElementById("generate_sales_max_margin_percent"))
		{	document.getElementById("generate_sales_max_margin_percent").remove();
		}

		if (document.getElementById("generate_sales_purchases_price_sup_label"))
		{	document.getElementById("generate_sales_purchases_price_sup_label").remove();
		}

		if (document.getElementById("generate_sales_purchases_price_sup"))
		{	document.getElementById("generate_sales_purchases_price_sup").remove();
		}

		if (document.getElementById("generate_sales_cb_minimum_value"))
		{	document.getElementById("generate_sales_cb_minimum_value").remove();
		}

		if (document.getElementById("generate_sales_minimum_value_label"))
		{	document.getElementById("generate_sales_minimum_value_label").remove();
		}

		if (document.getElementById("generate_sales_minimum_value"))
		{	document.getElementById("generate_sales_minimum_value").remove();
		}


		if (document.getElementById("generate_sales_cb_gross_price_with_base_discount"))
		{	document.getElementById("generate_sales_cb_gross_price_with_base_discount").remove();
		}

		if (document.getElementById("generate_sales_gross_price_with_base_discount_label"))
		{	document.getElementById("generate_sales_gross_price_with_base_discount_label").remove();
		}

		if (document.getElementById("generate_sales_gross_price_with_base_discount"))
		{	document.getElementById("generate_sales_gross_price_with_base_discount").remove();
		}

		if (document.getElementById("generate_sales_gross_price_with_base_discount_percent"))
		{	document.getElementById("generate_sales_gross_price_with_base_discount_percent").remove();
		}


		if (document.getElementById("generate_sales_cb_specific_vat_rate"))
		{	document.getElementById("generate_sales_cb_specific_vat_rate").remove();
		}

		if (document.getElementById("generate_sales_specific_vat_rate_label"))
		{	document.getElementById("generate_sales_specific_vat_rate_label").remove();
		}

		if (document.getElementById("generate_sales_specific_vat_rate"))
		{	document.getElementById("generate_sales_specific_vat_rate").remove();
		}

		if (document.getElementById("generate_sales_specific_vat_rate_percent"))
		{	document.getElementById("generate_sales_specific_vat_rate_percent").remove();
		}

		if (document.getElementById("generate_sales_cb_calculate_included_vat_price"))
		{	document.getElementById("generate_sales_cb_calculate_included_vat_price").remove();
		}

		if (document.getElementById("generate_sales_calculate_included_vat_price_label"))
		{	document.getElementById("generate_sales_calculate_included_vat_price_label").remove();
		}


		if (document.getElementById("generate_sales_cb_inactivate_vat"))
		{	document.getElementById("generate_sales_cb_inactivate_vat").remove();
		}

		if (document.getElementById("generate_sales_inactivate_vat_label"))
		{	document.getElementById("generate_sales_inactivate_vat_label").remove();
		}

		if (document.getElementById("generate_sales_update_rounding_kind_label"))
		{	document.getElementById("generate_sales_update_rounding_kind_label").remove();
		}

		if (document.getElementById("generate_sales_update_rounding_kind"))
		{	document.getElementById("generate_sales_update_rounding_kind").remove();
		}

		if (document.getElementById("generate_sales_update_rounding_method_label"))
		{	document.getElementById("generate_sales_update_rounding_method_label").remove();
		}

		if (document.getElementById("generate_sales_update_rounding_method"))
		{	document.getElementById("generate_sales_update_rounding_method").remove();
		}


		if (document.getElementById("generate_sales_update_rounding_value_label"))
		{	document.getElementById("generate_sales_update_rounding_value_label").remove();
		}

		if (document.getElementById("generate_sales_update_rounding_value"))
		{	document.getElementById("generate_sales_update_rounding_value").remove();
		}



		if (document.getElementById("generate_sales_bt_clear_values"))
		{	document.getElementById("generate_sales_bt_clear_values").remove();
		}

		
		if (document.getElementById("generate_sales_new_catalog_label"))
		{	document.getElementById("generate_sales_new_catalog_label").remove();
		}

		if (document.getElementById("generate_sales_new_catalog_type"))
		{	document.getElementById("generate_sales_new_catalog_type").remove();
		}


		if (document.getElementById("generate_sales_new_catalog_catalog"))
		{	document.getElementById("generate_sales_new_catalog_catalog").remove();
		}

		if (document.getElementById("generate_sales_new_catalog_customer"))
		{	document.getElementById("generate_sales_new_catalog_customer").remove();
		}


		if (document.getElementById("generate_sales_new_catalog_bt_customer"))
		{	document.getElementById("generate_sales_new_catalog_bt_customer").remove();
		}

		if (document.getElementById("generate_sales_cb_take_customer_link"))
		{	document.getElementById("generate_sales_cb_take_customer_link").remove();
		}

		if (document.getElementById("generate_sales_take_customer_link_label"))
		{	document.getElementById("generate_sales_take_customer_link_label").remove();
		}

		

		if (document.getElementById("generate_sales_new_sales_condition_label"))
		{	document.getElementById("generate_sales_new_sales_condition_label").remove();
		}

		if (document.getElementById("generate_sales_new_sales_condition"))
		{	document.getElementById("generate_sales_new_sales_condition").remove();
		}


		if (document.getElementById("generate_sales_new_currency_label"))
		{	document.getElementById("generate_sales_new_currency_label").remove();
		}

		if (document.getElementById("generate_sales_new_currency"))
		{	document.getElementById("generate_sales_new_currency").remove();
		}

		if (document.getElementById("generate_sales_new_currency_select_currency_button"))
		{	document.getElementById("generate_sales_new_currency_select_currency_button").remove();
		}


		if (document.getElementById("generate_sales_cb_only_if_sales_price_exists"))
		{	document.getElementById("generate_sales_cb_only_if_sales_price_exists").remove();
		}

		if (document.getElementById("generate_sales_only_if_sales_price_exists_label"))
		{	document.getElementById("generate_sales_only_if_sales_price_exists_label").remove();
		}




		if (document.getElementById("generate_sales_bt_update_price"))
		{	document.getElementById("generate_sales_bt_update_price").remove();
		}



		if (document.getElementById("generate_sales_warning1"))
		{	document.getElementById("generate_sales_warning1").remove();
		}







		let html = '';

		html += '<div style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '	<label style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Filters") + '</b></label>';			
		html += '</div>'

		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'

		html += '<div style="overflow: auto; overflow-x: auto; height:174px;">';		


		// Supplier
		html += '	<div style="position: relative; top: 0px; left: 0px; width:180px;">';
		html += '		<label id="generate_sales_supplier_label" style="position: absolute; top: 0px; left: 2px;">' + __("Supplier") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 130px; height: 25px;"> ';
		html += '			<input id="generate_sales_supplier_id" type="text" class="input-with-feedback form-control bold">';
		html += '		</div>';
		html += '		<div  style="position: absolute; top: 25px; left: 142px; width: 130px; height: 25px;"> ';		
		html +='			<button id="generate_sales_select_supplier_button" style="height: 30px; width: 30px;border: none; outline:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';

		html += '	</div>';



		// Purchases Condition
		html += '<div style="position: relative; top: 0px; left: 180px; width:170px;">';
		html += '	<label id="generate_sales_purchases_condition_label" style="position: absolute; top: 0px; left: 2px;">' + __("Purchases condition") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '		<select id="generate_sales_purchases_condition_id" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';

		let method = 'silicon_ioi.ioi_items.doctype.ioi_purchases_price.ioi_purchases_price.ioi_purchases_prices_get_purchases_condition'
		frappe.call({  	method: method, 
						args: {},
						async: false,
						callback:function(r)	{ 
													for (var i = 0; i < r.message.length; i++)
													{
														html += '		<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
													}
												}
		});
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';


		// Item
		html += '	<div style="position: relative; top: 0px; left: 360px; width:350px;">';
		html += '		<label id="generate_sales_item_label" style="position: absolute; top: 0px; left: 2px;">' + __("Item") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 310px; height: 25px;"> ';
		html += '			<input id="generate_sales_item_id" type="text" class="input-with-feedback form-control bold">';
		html += '		</div>';
		html += '		<div  style="position: absolute; top: 25px; left: 322px; width: 130px; height: 25px;"> ';		
		html +='			<button id="generate_sales_select_item_button" style="height: 30px; width: 30px;border: none; outline:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';





		// Unit
		html += '<div style="position: relative; top: 0px; left: 720px; width:170px;">';
		html += '	<label id="generate_sales_unit_label" style="position: absolute; top: 0px; left: 2px;">' + __("Unit") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '		<select id="generate_sales_unit_id" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';

		method = 'silicon_ioi.ioi_items.doctype.ioi_purchases_price.ioi_purchases_price.ioi_purchases_prices_get_unit'
		frappe.call({  	method: method, 
						args: {},
						async: false,
						callback:function(r)	{ 
													for (var i = 0; i < r.message.length; i++)
													{
														html += '		<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
													}
												}
		});
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';


		// Currency
		html += '	<div style="position: relative; top: 0px; left: 900px; width:170px;">';
		html += '		<label id="generate_sales_currency_label" style="position: absolute; top: 0px; left: 2px;">' + __("Currency") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 130px; height: 25px;"> ';
		html += '			<input id="generate_sales_currency_id" type="text" class="input-with-feedback form-control bold">';
		html += '		</div>';
		html += '		<div  style="position: absolute; top: 25px; left: 142px; width: 130px; height: 25px;"> ';		
		html +='			<button id="generate_sales_select_currency_button" style="height: 30px; width: 30px;border: none; outline:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';


		// Site
		html += '<div style="position: relative; top: 0px; left: 1080px; width:120px;">';
		html += '	<label id="generate_sales_site_label" style="position: absolute; top: 0px; left: 2px;">' + __("Site") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 120px; height: 30px;"> ';
		html += '		<select id="generate_sales_site_id" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';

		method = 'silicon_ioi.ioi_items.doctype.ioi_purchases_price.ioi_purchases_price.ioi_purchases_prices_get_site'
		frappe.call({  	method: method, 
						args: {},
						async: false,
						callback:function(r)	{ 
													for (var i = 0; i < r.message.length; i++)
													{
														html += '		<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
													}
												}
		});
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';



		// Enabled
		html += '<div style="position: relative; top: 64px; left: 1080px; width:120px;">';
		html += '	<label id="generate_sales_enabled_label" style="position: absolute; top: 0px; left: 2px;">' + __("Status") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 120px; height: 30px;"> ';
		html += '		<select id="generate_sales_enabled" class="input-with-feedback form-control bold"> ';
		html += '			<option value="">All</option> ';
		html += '			<option value="0">' + __("Disabled") + '</option> ';
		html += '			<option value="1">' + __("Enabled") + '</option> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';

		// Item Family
		html += '<div style="position: relative; top: 64px; left: 0px; width:170px;">';
		html += '	<label id="generate_sales_family_label" style="position: absolute; top: 0px; left: 2px;">' + __("Family") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '		<select id="generate_sales_family_id" class="input-with-feedback form-control bold"> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';

		// Item Type
		html += '<div style="position: relative; top: 64px; left: 180px; width:170px;">';
		html += '	<label id="generate_sales_type_label" style="position: absolute; top: 0px; left: 2px;">' + __("Type") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '		<select id="generate_sales_type_id" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';

		// Item Group
		html += '<div style="position: relative; top: 64px; left: 360px; width:170px;">';
		html += '	<label id="generate_sales_group_label" style="position: absolute; top: 0px; left: 2px;">' + __("Group") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '		<select id="generate_sales_group_id" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';

		// Item Sub Group
		html += '<div style="position: relative; top: 64px; left: 540px; width:170px;">';
		html += '	<label id="generate_sales_subgroup_label" style="position: absolute; top: 0px; left: 2px;">' + __("Sub group") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '		<select id="generate_sales_subgroup_id" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';

		// Item Classification
		html += '<div style="position: relative; top: 64px; left: 720px; width:170px;">';
		html += '	<label id="generate_sales_classification_label" style="position: absolute; top: 0px; left: 2px;">' + __("Classification") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '		<select id="generate_sales_classification_id" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';

		method = 'silicon_ioi.ioi_items.doctype.ioi_purchases_price.ioi_purchases_price.ioi_purchases_prices_get_classification'
		frappe.call({  	method: method, 
						args: {},
						async: false,
						callback:function(r)	{ 
													for (var i = 0; i < r.message.length; i++)
													{
														html += '		<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
													}
												}
		});

		html += '		</select> ';
		html += '	</div>';
		html += '</div>';


		// Item Shop Category
		html += '<div style="position: relative; top: 64px; left: 900px; width:170px;">';
		html += '	<label id="generate_sales_shop_category_label" style="position: absolute; top: 0px; left: 2px;">' + __("Shop Category") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '		<select id="generate_sales_shop_category_id" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';

		method = 'silicon_ioi.ioi_items.doctype.ioi_purchases_price.ioi_purchases_price.ioi_purchases_prices_get_shop_category'
		frappe.call({  	method: method, 
						args: {},
						async: false,
						callback:function(r)	{ 
													for (var i = 0; i < r.message.length; i++)
													{
														html += '		<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
													}
												}
		});


		html += '		</select> ';
		html += '	</div>';
		html += '</div>';


		// Search
		html += '<div style="position: relative; top: 132px; left: 0px; width:110px;">';		
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='		<button id="generate_sales_search" class="btn btn-default ellipsis" style="height: 30px; width: 110px;" onclick="">' + __("Search") + '</button>';
		html += '	</div>';
		html += '</div>';

		// Clear
		html += '<div style="position: relative; top: 132px; left: 120px; width:50px;">';		
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 30px;">';
		html +='		<button id="generate_sales_clear" title="' + __("Clear") +'" data-label="Search" class="btn btn-default ellipsis" style="height: 30px; width: 50px;" onclick="">' + __("...") + '</button>';
		html += '	</div>';
		html += '</div>';

		html += '<div style="position: relative; top: 132px; left: 180px; height:30px; width:600px; ">';
		html += '	<label id="generate_sales_grid_banner" style="position: relative; top: 6px; left: 6px;"></label>';			
		html += '</div>';


		html += '<input id="generate_sales_orderby" type="hidden" value="name">';
		html += '<input id="generate_sales_order" type="hidden" value="asc">';


		html += '</div>';
		
		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>';



		// Grid Header
		html += '<div id="generate_sales_content" style="overflow: auto; overflow-x: auto; height:392px;">';		

		html += '<table id="generate_sales_grid" border=1 style="border: 1px solid #E8EAEB" width=1400px data-custom-grid="true">';

		html += '<tr style="height:30px;">';

		html += '<td width=30px align="center" style="vertical-align: middle;">';
		html += '<input type="checkbox" id="generate_sales_check_all_none" style="postion:absolute; top: 2px; left: 2px;" checked ';
		html += '       onclick="   let nb_selected_rows = 0; ';
		html += '					for (var i = 0; i < document.getElementById(\'generate_sales_detail\').rows.length; i++) ';
		html += '					{	';
		html += '						if (document.getElementById(\'generate_sales_checked_id_\' + i.toString())) { '
		html += '							document.getElementById(\'generate_sales_checked_id_\' + i.toString()).checked = this.checked; ';
		html += '						} ';

		html += '						if (document.getElementById(\'generate_sales_checked_id_\' + i.toString())) { '
		html += '							if (document.getElementById(\'generate_sales_checked_id_\' + i.toString()).checked) { '
		html += '								nb_selected_rows++; ';
		html += '							} ';		
		html += '						} ';		
		html += '					} ';

		html += '					if (document.getElementById(\'generate_sales_grid_banner\')) ';
		html += '					{ ';
		html += '						if (nb_selected_rows <= 1) { ';
		html += '							document.getElementById(\'generate_sales_grid_banner\').innerHTML = \'<b>\' + nb_selected_rows.toString() + \'</b> \' + __(\'selected purchases price\'); ';
		html += '						}else { ';
		html += '							document.getElementById(\'generate_sales_grid_banner\').innerHTML = \'<b>\' + nb_selected_rows.toString() + \'</b> \' + __(\'selected purchases prices\'); ';
		html += '						} ';
		html += '					} ';
		html += '                " ';
		html += '>'; 
		html += '</td>';
		html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
		html += '<td id="generate_sales_col_supplier_id" width=200px style="vertical-align: middle;"><b>&nbsp;' + __("Supplier") +                  '<label id="generate_sales_col_label_supplier_id" style="width:30px; height:8px" align="right">&darr;</label></b></td>';		
		html += '<td id="generate_sales_col_purchases_condition_id" width=150px style="vertical-align: middle;"><b>&nbsp;' + __("Pur. condition") + '<label id="generate_sales_col_label_purchases_condition_id" style="width:30px; height:8px" align="right"></label></b></td>';		
		html += '<td id="generate_sales_col_item_id" width=250px style="vertical-align: middle;"><b>&nbsp;' + __("Item") +                          '<label id="generate_sales_col_label_item_id" style="width:30px; height:8px" align="right"></label></b></td>';		
		html += '<td id="generate_sales_col_unit_id" width=80px style="vertical-align: middle;"><b>&nbsp;' + __("Unit") +                           '<label id="generate_sales_col_label_unit_id" style="width:30px; height:8px" align="right"></label></b></td>';				
		html += '<td id="generate_sales_col_currency_id" width=80px style="vertical-align: middle;"><b>&nbsp;' + __("Curr.") +                      '<label id="generate_sales_col_label_currency_id" style="width:30px; height:8px" align="right"></label></b></td>';				
		html += '<td id="generate_sales_col_site_id" width=80px style="vertical-align: middle;"><b>&nbsp;' + __("Site") +                           '<label id="generate_sales_col_label_site_id" style="width:30px; height:8px" align="right"></label></b></td>';				
		html += '<td id="generate_sales_col_unit_price" width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Unit price") +                 '<label id="generate_sales_col_label_unit_price" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id="generate_sales_col_fixed_price" width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Fix. price") +                '<label id="generate_sales_col_label_fixed_price" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id="generate_sales_col_base_discount" width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Base disc.") +              '<label id="generate_sales_col_label_base_discount" style="width:20px; height:8px" align="right"></label></b></td>';
		html += '<td id="generate_sales_col_extra_discount" width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Extra disc.") +            '<label id="generate_sales_col_label_extra_discount" style="width:20px; height:8px" align="right"></label></b></td>';		
		html += '<td id="generate_sales_col_enabled" width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Status") +        				 '<label id="generate_sales_col_label_enabled" style="width:30px; height:8px" align="right"></label></b></td>';				
		html += '</tr>';
		html += '</table>';		


		// Result
		html += '<table id="generate_sales_detail" border=1 style="border: 1px solid #E8EAEB" width=1400px>';
		html += '<tr style="height:30px">';
		html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '</tr>';
		html += '</table>';		

		html += '</div>'

		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'

		html += '<div style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '	<label style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Price calculation rule") + '</b></label>';			
		html += '</div>'


		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'

		html += '<div style="overflow: auto; overflow-x: auto; height:300px;">';	

		// Do not take account of the discount mentionned in the purchase price
		html += '	<div style="position: relative; top: 0px; left: 0px; width:500px;">';
		html += '       <input type="checkbox" id="generate_sales_cb_do_not_take_base_discount" style="position: absolute; top: 8px; left: 2px;">';		
		html += '		<label id="generate_sales_do_not_take_base_discount_label" style="position: absolute; top: 6px; left: 30px;">' + __("Do not take account of the discount mentionned in the purchase price") + '</label>';
		html += '	</div>';
		

		// Do not take account of the extra discount mentionned in the purchase price
		html += '	<div style="position: relative; top: 0px; left: 600px; width:500px;">';
		html += '       <input type="checkbox" id="generate_sales_cb_do_not_take_extra_discount" style="position: absolute; top: 8px; left: 2px;">';		
		html += '		<label id="generate_sales_do_not_take_extra_discount_label" style="position: absolute; top: 6px; left: 30px;">' + __("Do not take account of the extra discount mentionned in the purchase price") + '</label>';
		html += '	</div>';


		// Margin kind
		html += '	<div style="position: relative; top: 30px; left: 0px; width:400px;">';
		html += '		<label id="generate_sales_margin_kind_label" style="position: absolute; top: 4px; left: 2px;">' + __("Margin") + '</label>';		
		html += '		<div class="control-input" style="position: absolute; top: 30px; left: 2px; width: 190px; height: 25px;"> ';
		html += '			<select id="generate_sales_margin_kind" class="input-with-feedback form-control bold"> ';
		html += '				<option value="0" selected>' + __('Purchases price based') + '</option> ';		
		html += '				<option value="1">' + __('Sales price based') + '</option> ';
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		// Margin Percentage
		html += '	<div style="position: relative; top: 30px; left: 200px; width:400px;">';
		html += '		<label id="generate_sales_margin_percentage_label" style="position: absolute; top: 4px; left: 2px;">' + __("Percentage") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 30px; left: 2px; width: 100px; height: 25px;"> ';
		html += '			<input id="generate_sales_margin_percentage" type="number" class="input-with-feedback form-control bold">';
		html += '			<label id="generate_sales_margin_percentage_percent" style="position: absolute; top: 6px; left: 110px;">%</label>';		
		html += '		</div>';
		html += '	</div>';

		// Min margin
		html += '	<div style="position: relative; top: 60px; left: 350px; width:400px;">';
		html += '       <input type="checkbox" id="generate_sales_cb_min_margin" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="generate_sales_min_margin_label" style="position: absolute; top: 6px; left: 30px;">' + __("Min %") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 102px; width: 100px; height: 25px;"> ';
		html += '			<input id="generate_sales_min_margin" type="number" class="input-with-feedback form-control bold">';
		html += '			<label id="generate_sales_min_margin_percent" style="position: absolute; top: 6px; left: 110px;">%</label>';		
		html += '		</div>';
		html += '	</div>';


		// If purchase price inf. than
		html += '	<div style="position: relative; top: 60px; left: 600px; width:400px;">';
		html += '		<label id="generate_sales_purchases_price_inf_label" style="position: absolute; top: 8px; left: 2px;">' + __("If purchase price inf. than") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 200px; width: 100px; height: 25px;"> ';
		html += '			<input id="generate_sales_purchases_price_inf" type="number" class="input-with-feedback form-control bold">';

		html += '		</div>';
		html += '	</div>';


		// Max margin
		html += '	<div style="position: relative; top: 100px; left: 350px; width:400px;">';
		html += '       <input type="checkbox" id="generate_sales_cb_max_margin" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="generate_sales_max_margin_label" style="position: absolute; top: 6px; left: 30px;">' + __("Max %") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 102px; width: 100px; height: 25px;"> ';
		html += '			<input id="generate_sales_max_margin" type="number" class="input-with-feedback form-control bold">';
		html += '			<label id="generate_sales_max_margin_percent" style="position: absolute; top: 6px; left: 110px;">%</label>';		
		html += '		</div>';
		html += '	</div>';

		// If purchase price sup than
		html += '	<div style="position: relative; top: 100px; left: 600px; width:400px;">';
		html += '		<label id="generate_sales_purchases_price_sup_label" style="position: absolute; top: 8px; left: 2px;">' + __("If purchase price sup. than") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 200px; width: 100px; height: 25px;"> ';
		html += '			<input id="generate_sales_purchases_price_sup" type="number" class="input-with-feedback form-control bold">';

		html += '		</div>';
		html += '	</div>';


		// Minimum value
		html += '	<div style="position: relative; top: 140px; left: 0px; width:400px;">';
		html += '       <input type="checkbox" id="generate_sales_cb_minimum_value" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="generate_sales_minimum_value_label" style="position: absolute; top: 6px; left: 30px;">' + __("Minimum value") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 202px; width: 100px; height: 25px;"> ';
		html += '			<input id="generate_sales_minimum_value" type="number" class="input-with-feedback form-control bold">';
		html += '		</div>';
		html += '	</div>';


		// Gross price with base discount
		html += '	<div style="position: relative; top: 180px; left: 0px; width:400px;">';
		html += '       <input type="checkbox" id="generate_sales_cb_gross_price_with_base_discount" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="generate_sales_gross_price_with_base_discount_label" style="position: absolute; top: 6px; left: 30px;">' + __("Gross price with discount") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 202px; width: 100px; height: 25px;"> ';
		html += '			<input id="generate_sales_gross_price_with_base_discount" type="number" class="input-with-feedback form-control bold">';
		html += '			<label id="generate_sales_gross_price_with_base_discount_percent" style="position: absolute; top: 6px; left: 110px;">%</label>';		
		html += '		</div>';
		html += '	</div>';




		// Specific VAT rate
		html += '	<div style="position: relative; top: 140px; left: 350px; width:400px;">';
		html += '       <input type="checkbox" id="generate_sales_cb_specific_vat_rate" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="generate_sales_specific_vat_rate_label" style="position: absolute; top: 6px; left: 30px;">' + __("Specific VAT rate") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 150px; width: 52px; height: 25px;"> ';
		html += '			<input id="generate_sales_specific_vat_rate" type="number" class="input-with-feedback form-control bold">';
		html += '			<label id="generate_sales_specific_vat_rate_percent" style="position: absolute; top: 6px; left: 62px;">%</label>';		
		html += '		</div>';
		html += '	</div>';

		// Calculate included vat price
		html += '	<div style="position: relative; top: 140px; left: 600px; width:400px;">';
		html += '       <input type="checkbox" id="generate_sales_cb_calculate_included_vat_price" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="generate_sales_calculate_included_vat_price_label" style="position: absolute; top: 6px; left: 30px;">' + __("Calculate the incl. VAT price") + '</label>';
		html += '	</div>';

		// Inactivate the VAT
		html += '	<div style="position: relative; top: 170px; left: 600px; width:400px;">';
		html += '       <input type="checkbox" id="generate_sales_cb_inactivate_vat" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="generate_sales_inactivate_vat_label" style="position: absolute; top: 6px; left: 30px;">' + __("Inactivate the VAT") + '</label>';
		html += '	</div>';




		// Rounding kind
		html += '	<div style="position: relative; top: 220px; left: 0px; width:400px;">';
		html += '		<label id="generate_sales_update_rounding_kind_label" style="position: absolute; top: 6px; left: 2px;">' + __("Rounding") + '</label>';

		html += '		<div class="control-input" style="position: absolute; top: 30px; left: 2px; width: 190px; height: 25px;"> ';
		html += '			<select id="generate_sales_update_rounding_kind" class="input-with-feedback form-control bold"> ';
		html += '				<option value="0" selected>' + __('Decimals') + '</option> ';		
		html += '				<option value="1">' + __('Multiple') + '</option> ';
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';


		// Rounding Method
		html += '	<div style="position: relative; top: 220px; left: 200px; width:400px;">';
		html += '		<label id="generate_sales_update_rounding_method_label" style="position: absolute; top: 6px; left: 2px;">' + __("Method") + '</label>';		
		html += '		<div class="control-input" style="position: absolute; top: 30px; left: 2px; width: 140px; height: 25px;"> ';
		html += '			<select id="generate_sales_update_rounding_method" class="input-with-feedback form-control bold"> ';
		html += '				<option value="0" selected>' + __('Nearest') + '</option> ';		
		html += '				<option value="1">' + __('Above') + '</option> ';
		html += '				<option value="2">' + __('Under') + '</option> ';
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		// Rounding value
		html += '	<div style="position: relative; top: 220px; left: 350px; width:400px;">';
		html += '		<label id="generate_sales_update_rounding_value_label" style="position: absolute; top: 6px; left: 2px;">' + __("Decimals") + '</label>';				
		html += '		<div class="control-input" style="position: absolute; top: 30px; left: 2px; width: 100px; height: 25px;"> ';
		html += '			<input id="generate_sales_update_rounding_value" type="number" class="input-with-feedback form-control bold" value="2">';
		html += '		</div>';
		html += '	</div>';


		html += '	<div style="position: relative; top: 210px; left: 600px; width:550px;">';
		html += '		<label id="generate_sales_warning1" style="position: absolute; top: 0px; left: 2px;"><font color="red">' + __("Caution, risky and irreversible operation ! Make a copy before, please (ioi Sales Price) ") + '</font></label>';
		html += '	</div>';


		// Button Clear values
		html += '	<div style="position: relative; top: 240px; left: 600px; width:100px;">';		
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 2px; width: 100px; height: 25px;"> ';
		html += '			<input id="generate_sales_bt_clear_values" type="button" class="btn btn-default ellipsis" value="' + __("Clear")  + '" style="width:100px">';
		html += '		</div>';
		html += '	</div>';		


		html += '</div>';

		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>';

		html += '<div style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '	<label style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Destination") + '</b></label>';			
		html += '</div>';


		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'


		html += '<div style="overflow: auto; overflow-x: auto; height:65px;">';	

		// New catalog
		html += '	<div style="position: relative; top: 2px; left: 0px; width:400px;">';
		html += '		<label id="generate_sales_new_catalog_label" style="position: absolute; top: 6px; left: 2px;">' + __("Catalog") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 100px; width: 120px; height: 25px;"> ';
		html += '			<select id="generate_sales_new_catalog_type" class="input-with-feedback form-control bold"> ';
		html += '				<option value=""></option> ';		
		html += '				<option value="Catalog">' + __("Catalog") + '</option> ';
	    html += '				<option value="Customer">' + __("Customer") + '</option> ';
		html += '			</select> ';
		html += '		</div>';

		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 230px; width: 170px; height: 25px;"> ';
		html += '			<select id="generate_sales_new_catalog_catalog" class="input-with-feedback form-control bold" hidden > ';
		html += '				<option value=""></option> ';	

		method = 'silicon_ioi.ioi_items.doctype.ioi_sales_price.ioi_sales_price.ioi_sales_prices_get_sales_catalog'
		frappe.call({  	method: method, 
						args: {},
						async: false,
						callback:function(r)	{ 
													for (var i = 0; i < r.message.length; i++)
													{
														html += '		<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
													}
												}
		});

		html += '			</select> ';

		html += '		</div>';
	
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 230px; width: 170px; height: 25px;"> ';
		html += '			<input id="generate_sales_new_catalog_customer" type="text" class="input-with-feedback form-control bold" value="" hidden>';
		html += '		</div>';

		html += '		<div class="control-input" style="position: absolute; top: 32px; left: 230px; width: 500px; height: 25px;"> ';
		html += '       	<input type="checkbox" id="generate_sales_cb_take_customer_link" style="position: absolute; top: 8px; left: 2px;" hidden>';		
		html += '			<label id="generate_sales_take_customer_link_label" style="position: absolute; top: 6px; left: 30px;" hidden>' + __("Use the customer link filled in the suplier") + '</label>';
		html += '		</div>';
		
		
		html += '		<div style="position: absolute; top:2px; left: 410px; height: 30px">';
		html +='			<button id="generate_sales_new_catalog_bt_customer" class="btn btn-default ellipsis" style="height: 30px; width: 50px;border: none; outline:none;" hidden onclick="">' + __("...") + '</button>';
		html += '		</div>';

		html += '	</div>';


		// New sales condition
		html += '	<div style="position: relative; top: 2px; left: 490px; width:400px;">';
		html += '		<label id="generate_sales_new_sales_condition_label" style="position: absolute; top: 6px; left: 2px;">' + __("Condition") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 100px; width: 170px; height: 25px;"> ';
		html += '			<select id="generate_sales_new_sales_condition" class="input-with-feedback form-control bold"> ';
		html += '				<option value=""></option> ';		

		method = 'silicon_ioi.ioi_items.doctype.ioi_sales_price.ioi_sales_price.ioi_sales_prices_get_sales_condition'
		frappe.call({  	method: method, 
						args: {},
						async: false,
						callback:function(r)	{ 
													for (var i = 0; i < r.message.length; i++)
													{
														html += '		<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
													}
												}
		});

		html += '			</select> ';

		html += '		</div>';

		html += '	</div>';


	
		// Currency
		html += '	<div style="position: relative; top: 2px; left: 800px; width:400px;">';
		html += '		<label id="generate_sales_new_currency_label" style="position: absolute; top: 6px; left: 2px;">' + __("Currency") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 100px; width: 100px; height: 25px;"> ';
		html += '			<input id="generate_sales_new_currency" type="text" class="input-with-feedback form-control bold" ';
		html += '				   onkeyup="';

		html += '						var start = this.selectionStart; ';
		html += '						var end = this.selectionEnd; ';
		html += '						this.value = this.value.toUpperCase(); ';
		html += '						this.setSelectionRange(start, end); ';
		
		html += '			">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 4px; left: 210px; width: 130px; height: 25px;"> ';		
		html +='			<button id="generate_sales_new_currency_select_currency_button" style="height: 30px; width: 30px;border: none; outline:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';

		html += '	</div>';

		html += '</div>';




		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>';



		html += '<div style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '	<label style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Operation") + '</b></label>';			
		html += '</div>';


		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>';

		html += '<div style="overflow: auto; overflow-x: auto; height:55px;">';	

		html += '	<div style="position: relative; top: 0px; left: 2px; width:500px;">';

		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 0px; width: 200px; height: 25px;"> ';
		html += '			<input id="generate_sales_bt_update_price" type="button" class="btn btn-default ellipsis" value="' + __("Generation")  + '" style="width:200px">';
		html += '		</div>';

		html += '		<div style="position: relative; top: 2px; left: 210px; width:400px;">';
		html += '			<input type="checkbox" id="generate_sales_cb_only_if_sales_price_exists" style="position: absolute; top: 8px; left: 2px;">';		
		html += '			<label id="generate_sales_only_if_sales_price_exists_label" style="position: absolute; top: 6px; left: 30px;">' + __("Only if sales price already exists") + '</label>';
		html += '		</div>';


		html += '	</div>';

		html += '</div>'

		cur_frm.fields_dict[html_field].$wrapper.empty();
		cur_frm.fields_dict[html_field].$wrapper.append(html);

		silicon_ioi.doctype.ioiPriceUpdatingTool.sleep_static(250).then(() => {

			let fct_keydown = function(event) { 
				
				silicon_ioi.doctype.ioiPriceUpdatingTool.do_generate_sales_search_key_down(event);

							
				if (event.keyCode == 13) 
				{	return false;
				}
			
			};

			document.getElementById("generate_sales_supplier_id").onkeydown = fct_keydown;


			let fct_bt_supplier_click = function () {

				let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email, alternate_id';
				let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400, 250';
				let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email, Alternate id';
				let where = 'ioistatus = 2';
				let order_by = 'name asc';
			
				let fct_callback = function (return_value) {

					document.getElementById('generate_sales_supplier_id').value = return_value;					
					document.getElementById('generate_sales_supplier_id').focus();					
				}

				silicon_ioi.ioiCommon.select_supplier(fields, fields_len, fields_desc, where, order_by, fct_callback);

			}


			document.getElementById('generate_sales_select_supplier_button').onclick = fct_bt_supplier_click;


			document.getElementById("generate_sales_item_id").onkeydown = fct_keydown;

			let fct_bt_item_click = function () {

			
				let fields = 'a.name, a.description, a.unit_id, b.q_stock, b.q_reserved, a.manufacturer_ref';
				let fields_len = '300, 500, 125, 150, 150, 300';
				let fields_desc = 'Identification, Description, Unit, Stock qty, Reserved qty, Manufacturer Catalog';
			let where = 'ioistatus = 2';
				let order_by = 'a.name asc';
				let parameter_with_stock = false;
				let site_id = '';
		
				let fct_callback = function (return_value) {

					document.getElementById('generate_sales_item_id').value = return_value;					
					document.getElementById('generate_sales_item_id').focus();					
				}
		
				silicon_ioi.ioiCommon.select_item(fields, fields_len, fields_desc, where, order_by, parameter_with_stock, site_id, fct_callback);
			}


			document.getElementById('generate_sales_select_item_button').onclick = fct_bt_item_click;			




			document.getElementById("generate_sales_currency_id").onkeydown = fct_keydown;


			let fct_bt_currency_click = function () {

				let title = __("Select a currency");
				let form_width_pixel = '580px';
				let table = "ioi Currency";
				let fields = 'name, description';
				let fields_len = '200, 300';
				let fields_desc = 'Identification, Description';
				let where = 'enabled = 1';
				let order_by = 'name asc';
				
				let fct_callback = function (return_value) {
	
					document.getElementById("generate_sales_currency_id").value = return_value;
					document.getElementById('generate_sales_currency_id').focus();
				}
	
				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

			}

			document.getElementById('generate_sales_select_currency_button').onclick = fct_bt_currency_click;			





			let fct_change = function() { silicon_ioi.doctype.ioiPriceUpdatingTool.do_generate_sales_search_on_change(); };

			document.getElementById("generate_sales_purchases_condition_id").onchange = fct_change;
			document.getElementById("generate_sales_unit_id").onchange = fct_change;
			document.getElementById("generate_sales_site_id").onchange = fct_change;

			document.getElementById("generate_sales_enabled").onchange = fct_change;

			silicon_ioi.doctype.ioiPriceUpdatingTool.generate_sales_fill_item_family();

			let fct_change_family = function() { 
				
				silicon_ioi.doctype.ioiPriceUpdatingTool.generate_sales_fill_item_type('CHANGE');
				silicon_ioi.doctype.ioiPriceUpdatingTool.do_generate_sales_search_on_change(); 
			};

			document.getElementById("generate_sales_family_id").onchange = fct_change_family;

			let fct_change_type = function() { 
				
				silicon_ioi.doctype.ioiPriceUpdatingTool.generate_sales_fill_item_group('CHANGE');
				silicon_ioi.doctype.ioiPriceUpdatingTool.do_generate_sales_search_on_change(); 
			};

			document.getElementById("generate_sales_type_id").onchange = fct_change_type;


			let fct_change_group = function() { 
				
				silicon_ioi.doctype.ioiPriceUpdatingTool.generate_sales_fill_item_subgroup('CHANGE')
				silicon_ioi.doctype.ioiPriceUpdatingTool.do_generate_sales_search_on_change(); 
			};

			document.getElementById("generate_sales_group_id").onchange = fct_change_group;


			let fct_change_subgroup = function() { 
				
				silicon_ioi.doctype.ioiPriceUpdatingTool.do_generate_sales_search_on_change(); 
			};

			document.getElementById("generate_sales_subgroup_id").onchange = fct_change_subgroup;



			document.getElementById("generate_sales_classification_id").onchange = fct_change;
			document.getElementById("generate_sales_shop_category_id").onchange = fct_change;


			let fct_click = function () { silicon_ioi.doctype.ioiPriceUpdatingTool.do_generate_sales_refresh(); };
			let fct_clear = function () { silicon_ioi.doctype.ioiPriceUpdatingTool.do_generate_sales_clear(); };
	
	
			document.getElementById('generate_sales_search').onclick = fct_click;
			document.getElementById('generate_sales_clear').onclick = fct_clear;
	
	
			let fct_col_over = function ()  { silicon_ioi.doctype.ioiPriceUpdatingTool.col_mouse_over(this); };
			let fct_col_leave = function () { silicon_ioi.doctype.ioiPriceUpdatingTool.col_mouse_leave(this) }; 
			let fct_col_click = function () { 
			
				let selected_lines = [];

				for (var i = 0; i < document.getElementById('generate_sales_detail').rows.length; i++) {	

					if (document.getElementById("generate_sales_checked_id_" + i.toString()))	{					
						
						if (document.getElementById('generate_sales_checked_id_' + i.toString()).checked)	{

							selected_lines[selected_lines.length] = document.getElementById('generate_sales_detail_name_' + i.toString()).value;
						}
					}
				}

				silicon_ioi.doctype.ioiPriceUpdatingTool.do_generate_sales_col_click(this); 

				silicon_ioi.doctype.ioiPriceUpdatingTool.sleep_static(100).then(() => {

					for (var i = 0; i < document.getElementById('generate_sales_detail').rows.length; i++) {	

						if (document.getElementById("generate_sales_checked_id_" + i.toString()))	{						

							document.getElementById('generate_sales_checked_id_' + i.toString()).checked = false;

							if (selected_lines.indexOf(document.getElementById('generate_sales_detail_name_' + i.toString()).value) != -1)
							{	
								document.getElementById('generate_sales_checked_id_' + i.toString()).checked = true;
							}
						}
					}

					

					if (document.getElementById("generate_sales_grid_banner"))
					{	
						if (selected_lines.length <= 1) {
							document.getElementById("generate_sales_grid_banner").innerHTML = '<b>' + selected_lines.length.toString() + '</b> ' + __("selected purchases price")
						}else {
							document.getElementById("generate_sales_grid_banner").innerHTML = '<b>' + selected_lines.length.toString() + '</b> ' + __("selected purchases prices")
						}
					}
			

				});
			};			
	
	
		
			document.getElementById('generate_sales_col_supplier_id').onclick = fct_col_click; 
			document.getElementById('generate_sales_col_purchases_condition_id').onclick = fct_col_click;
			document.getElementById('generate_sales_col_item_id').onclick = fct_col_click;
			document.getElementById('generate_sales_col_unit_id').onclick = fct_col_click;
			document.getElementById('generate_sales_col_currency_id').onclick = fct_col_click;
			document.getElementById('generate_sales_col_site_id').onclick = fct_col_click;
			document.getElementById('generate_sales_col_unit_price').onclick = fct_col_click;
			document.getElementById('generate_sales_col_fixed_price').onclick = fct_col_click;
			document.getElementById('generate_sales_col_base_discount').onclick = fct_col_click;
			document.getElementById('generate_sales_col_extra_discount').onclick = fct_col_click;
			document.getElementById('generate_sales_col_enabled').onclick = fct_col_click;
	
			document.getElementById('generate_sales_col_supplier_id').onmouseover = fct_col_over;
			document.getElementById('generate_sales_col_purchases_condition_id').onmouseover = fct_col_over;
			document.getElementById('generate_sales_col_item_id').onmouseover = fct_col_over;
			document.getElementById('generate_sales_col_unit_id').onmouseover = fct_col_over;
			document.getElementById('generate_sales_col_currency_id').onmouseover = fct_col_over;
			document.getElementById('generate_sales_col_site_id').onmouseover = fct_col_over;
			document.getElementById('generate_sales_col_unit_price').onmouseover = fct_col_over;
			document.getElementById('generate_sales_col_fixed_price').onmouseover = fct_col_over;
			document.getElementById('generate_sales_col_base_discount').onmouseover = fct_col_over;
			document.getElementById('generate_sales_col_extra_discount').onmouseover = fct_col_over;
			document.getElementById('generate_sales_col_enabled').onmouseover = fct_col_over;
	
			document.getElementById('generate_sales_col_supplier_id').onmouseleave = fct_col_leave;
			document.getElementById('generate_sales_col_purchases_condition_id').onmouseleave = fct_col_leave;
			document.getElementById('generate_sales_col_item_id').onmouseleave = fct_col_leave;
			document.getElementById('generate_sales_col_unit_id').onmouseleave = fct_col_leave;
			document.getElementById('generate_sales_col_currency_id').onmouseleave = fct_col_leave;
			document.getElementById('generate_sales_col_site_id').onmouseleave = fct_col_leave;
			document.getElementById('generate_sales_col_unit_price').onmouseleave = fct_col_leave;
			document.getElementById('generate_sales_col_fixed_price').onmouseleave = fct_col_leave;
			document.getElementById('generate_sales_col_base_discount').onmouseleave = fct_col_leave;
			document.getElementById('generate_sales_col_extra_discount').onmouseleave = fct_col_leave;
			document.getElementById('generate_sales_col_enabled').onmouseleave = fct_col_leave;


			let fct_return_false = function(event) {
			
				if (event.keyCode == 13) 
				{	return false;
				}
		
			}

			document.getElementById("generate_sales_margin_kind").onkeydown = fct_return_false;

			document.getElementById("generate_sales_margin_percentage").onkeydown = fct_return_false;

			document.getElementById('generate_sales_cb_min_margin').onkeydown = fct_return_false;			

			let fct_check_cb_min_margin = function(event) {
				document.getElementById('generate_sales_cb_min_margin').checked = true;

				if (event.keyCode == 13) 
				{	return false;
				}

			}

			document.getElementById("generate_sales_min_margin").onkeydown = fct_check_cb_min_margin;

			
			document.getElementById("generate_sales_purchases_price_inf").onkeydown = fct_check_cb_min_margin;			
			

			document.getElementById('generate_sales_cb_max_margin').onkeydown = fct_return_false;

			let fct_check_cb_max_margin = function(event) {
				document.getElementById('generate_sales_cb_max_margin').checked = true;

				if (event.keyCode == 13) 
				{	return false;
				}

			}

			document.getElementById("generate_sales_max_margin").onkeydown = fct_check_cb_max_margin;

			document.getElementById("generate_sales_purchases_price_sup").onkeydown = fct_check_cb_max_margin;

	
			document.getElementById('generate_sales_cb_minimum_value').onkeydown = fct_return_false;

			let fct_check_cb_minimum_value = function(event) {
				document.getElementById('generate_sales_cb_minimum_value').checked = true;

				if (event.keyCode == 13) 
				{	return false;
				}

			}

			document.getElementById("generate_sales_minimum_value").onkeydown = fct_check_cb_minimum_value;


			document.getElementById('generate_sales_cb_gross_price_with_base_discount').onkeydown = fct_return_false;

			let fct_check_cb_gross_price_with_base_discount = function(event) {
				document.getElementById('generate_sales_cb_gross_price_with_base_discount').checked = true;

				if (event.keyCode == 13) 
				{	return false;
				}

			}

			document.getElementById("generate_sales_gross_price_with_base_discount").onkeydown = fct_check_cb_gross_price_with_base_discount;

	


			document.getElementById('generate_sales_cb_specific_vat_rate').onkeydown = fct_return_false;

			let fct_check_cb_specific_vat_rate = function(event) {
				document.getElementById('generate_sales_cb_specific_vat_rate').checked = true;

				if (event.keyCode == 13) 
				{	return false;
				}

			}

			document.getElementById("generate_sales_specific_vat_rate").onkeydown = fct_check_cb_specific_vat_rate;		
			document.getElementById('generate_sales_cb_calculate_included_vat_price').onkeydown = fct_return_false;			
			document.getElementById('generate_sales_cb_inactivate_vat').onkeydown = fct_return_false;


			document.getElementById('generate_sales_update_rounding_kind').onkeydown = fct_return_false;

			let fct_rounding_kind_change = function() {

				if (document.getElementById('generate_sales_update_rounding_kind').value == 0) {

					document.getElementById('generate_sales_update_rounding_value_label').innerText = __('Decimals');
					document.getElementById('generate_sales_update_rounding_value').value = '2';
					
				}else{
					document.getElementById('generate_sales_update_rounding_value_label').innerText = __('Multiple');
					document.getElementById('generate_sales_update_rounding_value').value = '0.05';
				}

			};

			document.getElementById('generate_sales_update_rounding_kind').onchange = fct_rounding_kind_change;

			document.getElementById('generate_sales_update_rounding_method').onkeydown = fct_return_false;	
			document.getElementById('generate_sales_update_rounding_value').onkeydown = fct_return_false;	
			


			document.getElementById('generate_sales_cb_do_not_take_base_discount').onkeydown = fct_return_false;
			document.getElementById('generate_sales_cb_do_not_take_extra_discount').onkeydown = fct_return_false;



			let fct_clear_values = function () { silicon_ioi.doctype.ioiPriceUpdatingTool.do_generate_sales_clear_values(); };			

			document.getElementById('generate_sales_bt_clear_values').onclick = fct_clear_values;



			let fct_catalog_type = function(event) {

				if (document.getElementById('generate_sales_new_catalog_type').value == '') {

					document.getElementById('generate_sales_new_catalog_catalog').hidden = true;
					document.getElementById('generate_sales_new_catalog_customer').hidden = true;
					document.getElementById('generate_sales_new_catalog_bt_customer').hidden = true;
					document.getElementById('generate_sales_cb_take_customer_link').hidden = true;
					document.getElementById('generate_sales_take_customer_link_label').hidden = true;

					document.getElementById('generate_sales_new_catalog_catalog').selectedIndex = 0;
					document.getElementById('generate_sales_new_catalog_customer').value = '';
					document.getElementById('generate_sales_cb_take_customer_link').checked = false;


				}else {

					if (document.getElementById('generate_sales_new_catalog_type').value == 'Catalog') {

						document.getElementById('generate_sales_new_catalog_catalog').hidden = false;
						document.getElementById('generate_sales_new_catalog_customer').hidden = true;
						document.getElementById('generate_sales_new_catalog_bt_customer').hidden = true;
						document.getElementById('generate_sales_cb_take_customer_link').hidden = true;
						document.getElementById('generate_sales_take_customer_link_label').hidden = true;
	

						document.getElementById('generate_sales_new_catalog_customer').value = '';
						document.getElementById('generate_sales_cb_take_customer_link').checked = false;						
						document.getElementById("generate_sales_new_catalog_catalog").style.zIndex = "10";
						document.getElementById("generate_sales_new_catalog_customer").style.zIndex = "5";

					}else {

						document.getElementById('generate_sales_new_catalog_catalog').hidden = true;
						document.getElementById('generate_sales_new_catalog_customer').hidden = false;
						document.getElementById('generate_sales_new_catalog_bt_customer').hidden = false;
						document.getElementById('generate_sales_cb_take_customer_link').hidden = false;
						document.getElementById('generate_sales_take_customer_link_label').hidden = false;


						document.getElementById('generate_sales_new_catalog_catalog').selectedIndex = 0;
						document.getElementById('generate_sales_cb_take_customer_link').checked = false;

						document.getElementById("generate_sales_new_catalog_customer").style.zIndex = "10";
						document.getElementById("generate_sales_new_catalog_catalog").style.zIndex = "5";
					}
				}

				if (event.keyCode == 13) 
				{	return false;
				}

			}

			document.getElementById('generate_sales_new_catalog_type').onchange = fct_catalog_type;

			document.getElementById('generate_sales_new_catalog_type').onkeydown = fct_return_false;			


			let fct_bt_customer_click = function () {

				let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email, alternate_id';
				let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400, 250';
				let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email, Alternate id';
				let where = 'ioistatus = 2';
				let order_by = 'name asc';
			
				let fct_callback = function (return_value) {

					document.getElementById('generate_sales_new_catalog_customer').value = return_value;					
				}

				silicon_ioi.ioiCommon.select_customer(fields, fields_len, fields_desc, where, order_by, fct_callback);

			}

			document.getElementById('generate_sales_new_catalog_customer').onkeydown = fct_return_false;	
			
			document.getElementById('generate_sales_cb_take_customer_link').onkeydown = fct_return_false;

			document.getElementById('generate_sales_new_catalog_bt_customer').onclick = fct_bt_customer_click;




			let fct_check_cb_sales_condition = function(event) {

				if (event.keyCode == 13) 
				{	return false;
				}

			}


			document.getElementById("generate_sales_new_sales_condition").onchange = fct_check_cb_sales_condition;


			let fct_check_cb_convert = function(event) {

				if (event.keyCode == 13) 
				{	return false;
				}

			}

			document.getElementById("generate_sales_new_currency").onkeydown = fct_check_cb_convert;



			let fct_bt_new_currency_click = function () {

				let title = __("Select a currency");
				let form_width_pixel = '580px';
				let table = "ioi Currency";
				let fields = 'name, description';
				let fields_len = '200, 300';
				let fields_desc = 'Identification, Description';
				let where = 'enabled = 1';
				let order_by = 'name asc';
				
				let fct_callback = function (return_value) {
	
					document.getElementById("generate_sales_new_currency").value = return_value;
					document.getElementById('generate_sales_new_currency').focus();
				}
	
				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

			}

			document.getElementById('generate_sales_new_currency_select_currency_button').onclick = fct_bt_new_currency_click;			
			
			


			document.getElementById('generate_sales_cb_only_if_sales_price_exists').onkeydown = fct_return_false;



			let fct_update = function () { silicon_ioi.doctype.ioiPriceUpdatingTool.do_generate_sales_update_price(); };			
	
			document.getElementById('generate_sales_bt_update_price').onclick = fct_update;

			
		});
	}



	static do_generate_sales_clear()
	{
		
		document.getElementById('generate_sales_supplier_id').value = '';
		document.getElementById("generate_sales_purchases_condition_id").selectedIndex = 0;
		document.getElementById('generate_sales_item_id').value = '';
		document.getElementById("generate_sales_unit_id").selectedIndex = 0;
		document.getElementById('generate_sales_currency_id').value = '';
		document.getElementById("generate_sales_site_id").selectedIndex = 0;

		document.getElementById("generate_sales_enabled").selectedIndex = 0;

		document.getElementById("generate_sales_family_id").selectedIndex = 0;

		for (var i = document.getElementById('generate_sales_type_id').options.length-1; i >= 0; i--) {
			document.getElementById('generate_sales_type_id').options[i].remove();	
		}

		for (var i = document.getElementById('generate_sales_group_id').options.length-1; i >= 0; i--) {
			document.getElementById('generate_sales_group_id').options[i].remove();	
		}
		
		for (var i = document.getElementById('generate_sales_subgroup_id').options.length-1; i >= 0; i--) {
			document.getElementById('generate_sales_subgroup_id').options[i].remove();	
		}

		silicon_ioi.doctype.ioiPriceUpdatingTool.item_type = [];
		silicon_ioi.doctype.ioiPriceUpdatingTool.item_type[0] = ['', ''];
		document.getElementById('generate_sales_type_id').options[document.getElementById('generate_sales_type_id').options.length] = new Option('', '');												


		silicon_ioi.doctype.ioiPriceUpdatingTool.item_group = [];
		silicon_ioi.doctype.ioiPriceUpdatingTool.item_group[0] = ['', ''];
		document.getElementById('generate_sales_group_id').options[document.getElementById('generate_sales_group_id').options.length] = new Option('', '');												

		silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup = [];
		silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[0] = ['', ''];			
		document.getElementById('generate_sales_subgroup_id').options[document.getElementById('generate_sales_subgroup_id').options.length] = new Option('', '');												

		document.getElementById("generate_sales_type_id").selectedIndex = 0;				
		document.getElementById("generate_sales_group_id").selectedIndex = 0;
		document.getElementById("generate_sales_subgroup_id").selectedIndex = 0;				
		document.getElementById("generate_sales_classification_id").selectedIndex = 0;
		document.getElementById("generate_sales_shop_category_id").selectedIndex = 0;				
	

		
		if (document.getElementById('generate_sales_detail'))	{
			document.getElementById('generate_sales_detail').remove();
		}
		
		let html = '';

		html += '<table id="generate_sales_detail" border=1 style="border: 1px solid #E8EAEB" width=1400px>';
		html += '<tr style="height:30px">';
		html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '</tr>';
		html += '</table>';	
		

		document.getElementById('generate_sales_content').insertAdjacentHTML('beforeend', html);	

		let nb_selected_rows = 0
		document.getElementById("generate_sales_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected purchases price")

	}

	static do_generate_sales_refresh()
	{
		
		let html = '';

		let me = this;

		let method = 'silicon_ioi.ioi_items.doctype.ioi_purchases_price.ioi_purchases_price.ioi_purchases_prices_get_list_for_generate_sales_prices'		

					
		frappe.call({  	method: method, 
						args: {	"supplier_id": document.getElementById('generate_sales_supplier_id').value,
								"purchases_condition_id": document.getElementById("generate_sales_purchases_condition_id").value,
								"item_id": document.getElementById("generate_sales_item_id").value,
								"unit_id": document.getElementById("generate_sales_unit_id").value,
								"currency_id": document.getElementById("generate_sales_currency_id").value,
								"site_id": document.getElementById("generate_sales_site_id").value, 
								"enabled": document.getElementById("generate_sales_enabled").value,
								"family_id": document.getElementById("generate_sales_family_id").value,
								"type_id": document.getElementById("generate_sales_type_id").value, 
								"group_id": document.getElementById("generate_sales_group_id").value, 
								"subgroup_id": document.getElementById("generate_sales_subgroup_id").value,
								"classification_id": document.getElementById("generate_sales_classification_id").value,
								"shop_category_id": document.getElementById("generate_sales_shop_category_id").value,
								"order_field": document.getElementById('generate_sales_orderby').value,
								"order_dir": document.getElementById('generate_sales_order').value
								},
						async: false,
						callback:function(r)	{ 
													document.getElementById('generate_sales_check_all_none').checked = true;

													if (document.getElementById('generate_sales_detail'))
													{
														for (var i = 0; i < document.getElementById('generate_sales_detail').rows.length; i++)
														{

															if (document.getElementById('generate_sales_detail_name_' + i.toString()))
															{
																document.getElementById('generate_sales_detail_name_' + i.toString()).remove();
															}

															if (document.getElementById('generate_sales_detail_supplier_' + i.toString()))
															{
																document.getElementById('generate_sales_detail_supplier_' + i.toString()).remove();
															}


															if (document.getElementById('generate_sales_checked_id_' + i.toString()))
															{
																document.getElementById('generate_sales_checked_id_' + i.toString()).remove();
															}

															if (document.getElementById('generate_sales_button_id_' + i.toString()))
															{
																document.getElementById('generate_sales_button_id_' + i.toString()).remove();
															}


															if (document.getElementById('generate_sales_detail_enabled_' + i.toString()))
															{
																document.getElementById('generate_sales_detail_enabled_' + i.toString()).remove();
															}

														}

													
														document.getElementById('generate_sales_detail').remove();
													}
						
													if (r.message.length > 0)
													{	
														let cpt = 0;
														html += '<table id="generate_sales_detail" border=1 style="border: 1px solid #E8EAEB" width=1370px>';
														for (var i = 0; i < r.message.length; i++)
														{

															html += '<input type="hidden" id="generate_sales_detail_name_' + i.toString() + '" value="' + r.message[i].name + '">';
															html += '<input type="hidden" id="generate_sales_detail_supplier_' + i.toString() + '" value="' + r.message[i].supplier_id + '">';
															
															if (r.message[i].enabled == 1) {															
																html += '<tr style="height:30px">';
															}else{
																html += '<tr bgcolor="#E7E3E3" style="height:30px">';
															}


															html += '<td width=30px align="center" style="vertical-align: middle;">';
															html += '<input type="checkbox" id="generate_sales_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;" checked>'; 
															html += '<input type="hidden" id="generate_sales_detail_enabled_' + i.toString() + '" value="' + r.message[i].enabled + '">'															
															html += '</td>';

															let s = '/app/ioi-purchases-price/' + r.message[i].name;

															html += '<td width=30px align="center" style="vertical-align: middle;">';
															html += '<input type="button" id="generate_sales_button_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;" value="..." onclick="window.open(\'' + s + '\');" title="' + __("Go to Purchases Price") + '">'; 
															html += '</td>';
													

															s = '/app/ioi-supplier/' + r.message[i].supplier_id;

															html += '<td width=200px style="vertical-align: middle;" onclick="window.open(\'' + s + '\');" onmouseover="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';">&nbsp;<u>' + r.message[i].supplier_id + '</u></td>';


															html += '<td width=150px style="vertical-align: middle;">&nbsp;' + r.message[i].purchases_condition_id + '</td>';

															s = '/app/ioi-item/' + r.message[i].item_id;

															html += '<td width=250px style="vertical-align: middle;" onclick="window.open(\'' + s + '\');" onmouseover="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';">&nbsp;<u>' + r.message[i].item_id + '</u></td>';


															html += '<td width=80px style="vertical-align: middle;">&nbsp;' + r.message[i].unit_id + '</td>';

															html += '<td width=80px style="vertical-align: middle;">&nbsp;' + r.message[i].currency_id + '</td>';

															if (r.message[i].site_id != null) {
																html += '<td width=80px style="vertical-align: middle;">&nbsp;' + r.message[i].site_id + '</td>';
															}else{
																html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
															}

															html += '<td width=100px style="vertical-align: middle;" align="right">' + r.message[i].unit_price + '&nbsp;</td>';

															html += '<td width=100px style="vertical-align: middle;" align="right">' + r.message[i].fixed_price + '&nbsp;</td>';

															html += '<td width=100px style="vertical-align: middle;" align="right">' + r.message[i].base_discount + '&nbsp;%&nbsp;</td>';

															html += '<td width=100px style="vertical-align: middle;" align="right">' + r.message[i].extra_discount + '&nbsp;%&nbsp;</td>';

															if (r.message[i].enabled == 1) {
																html += '<td width=100px style="vertical-align: middle;">&nbsp;' + __("Enabled") + '</td>';
															}else {
																html += '<td width=100px style="vertical-align: middle;">&nbsp;' + __("Disabled") + '</td>';
															}

															html += '</tr>';
														}
														html += '</table>';																	
													}else
													{
														html += '<table id="generate_sales_detail" border=1 style="border: 1px solid #E8EAEB" width=1400px>';
														html += '<tr style="height:30px">';
														html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
														html += '</tr>';
														html += '</table>';		
																							
													}

													document.getElementById('generate_sales_content').insertAdjacentHTML('beforeend', html);

													silicon_ioi.doctype.ioiPriceUpdatingTool.sleep_static(200).then(() => {
														

														let fct_click = function () {

															let nb_selected_rows = 0

															for (var i = 0; i < document.getElementById('generate_sales_detail').rows.length; i++) {	
																					
																if (document.getElementById("generate_sales_checked_id_" + i.toString()))	{																
																	if (document.getElementById('generate_sales_checked_id_' + i.toString()).checked)	{
																		nb_selected_rows++;
																	}
																}
															}

															if (document.getElementById("generate_sales_grid_banner"))
															{	
																if (nb_selected_rows <= 1) {
																	document.getElementById("generate_sales_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected purchases price")
																}else {
																	document.getElementById("generate_sales_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected purchases prices")
																}
															}
														};

														for (var i = 0; i < document.getElementById('generate_sales_detail').rows.length; i++) {

															if (document.getElementById('generate_sales_checked_id_' + i.toString())) {

																document.getElementById('generate_sales_checked_id_' + i.toString()).onclick = fct_click;
															}
															
														}

													});

												}
		});

		let nb_selected_rows = 0

		for (var i = 0; i < document.getElementById('generate_sales_detail').rows.length; i++) {	
			if (document.getElementById("generate_sales_checked_id_" + i.toString()))	{										
				if (document.getElementById('generate_sales_checked_id_' + i.toString()).checked)	{
					nb_selected_rows++;
				}
			}
		}

		if (document.getElementById("generate_sales_grid_banner"))
		{	
			if (nb_selected_rows <= 1) {
				document.getElementById("generate_sales_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected purchases price")
			}else {
				document.getElementById("generate_sales_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected purchases prices")
			}
		}

		
	}


	static do_generate_sales_search_key_down(event)
	{
		if (event.keyCode == 13) 
		{
			silicon_ioi.doctype.ioiPriceUpdatingTool.do_generate_sales_refresh();
		}
		
	}

	static do_generate_sales_search_on_change()
	{
		silicon_ioi.doctype.ioiPriceUpdatingTool.do_generate_sales_refresh();
	}

	static do_generate_sales_col_click(obj)
	{   
		let s = obj.id;
	
		s = s.substring(19, obj.id.length); 
								 
		document.getElementById('generate_sales_col_label_supplier_id').innerHTML = '';
		document.getElementById('generate_sales_col_label_purchases_condition_id').innerHTML = '';
		document.getElementById('generate_sales_col_label_item_id').innerHTML = '';
		document.getElementById('generate_sales_col_label_unit_id').innerHTML = '';
		document.getElementById('generate_sales_col_label_currency_id').innerHTML = '';
		document.getElementById('generate_sales_col_label_site_id').innerHTML = '';
		document.getElementById('generate_sales_col_label_unit_price').innerHTML = '';
		document.getElementById('generate_sales_col_label_fixed_price').innerHTML = '';
		document.getElementById('generate_sales_col_label_base_discount').innerHTML = '';		
		document.getElementById('generate_sales_col_label_extra_discount').innerHTML = '';
		document.getElementById('generate_sales_col_label_enabled').innerHTML = '';

		if (document.getElementById('generate_sales_orderby').value == s)
		{
			if (document.getElementById('generate_sales_order').value == 'desc')
			{	document.getElementById('generate_sales_order').value = 'asc';
			
				document.getElementById('generate_sales_col_label_' + s).innerHTML = '&darr;';
			}else
			{	document.getElementById('generate_sales_order').value = 'desc';	
				document.getElementById('generate_sales_col_label_' + s).innerHTML = '&uarr;';
			}
		}else
		{	document.getElementById('generate_sales_orderby').value = s;
			document.getElementById('generate_sales_order').value = 'desc';
			document.getElementById('generate_sales_col_label_' + s).innerHTML = '&uarr;';
		}

		silicon_ioi.doctype.ioiPriceUpdatingTool.do_generate_sales_refresh();		
	}


	// ***************************************************************************************************************************************
	// Fill Item Family
	// ***************************************************************************************************************************************
	static generate_sales_fill_item_family()
	{	
		let me = this;
		let amethod =  'silicon_ioi.ioi_configuration.doctype.ioi_item_family.ioi_item_family.ioi_item_family_get_families';

		frappe.call({  	method: amethod,
					   	args: {"enabled":1, "current_family": silicon_ioi.doctype.ioiPriceUpdatingTool.current_family},
						async: false,
						callback:function(r){   
                                                silicon_ioi.doctype.ioiPriceUpdatingTool.item_family = [];
												silicon_ioi.doctype.ioiPriceUpdatingTool.item_family[0] = ['', ''];

												document.getElementById('generate_sales_family_id').options[document.getElementById('generate_sales_family_id').options.length] = new Option('', '');												

												for (var i = 0; i < r.message.length; i++)
												{	
													silicon_ioi.doctype.ioiPriceUpdatingTool.item_family[i+1] = [r.message[i][0], r.message[i][1]];

													document.getElementById('generate_sales_family_id').options[document.getElementById('generate_sales_family_id').options.length] = new Option(silicon_ioi.doctype.ioiPriceUpdatingTool.item_family[i+1][1], silicon_ioi.doctype.ioiPriceUpdatingTool.item_family[i+1][0]);													
												}
											}
		});
	}


	// ***************************************************************************************************************************************
	// Fill Item Type
	// ***************************************************************************************************************************************
	static generate_sales_fill_item_type(action='')
	{	
		if ((!document.getElementById('generate_sales_family_id')) || ((document.getElementById('generate_sales_family_id')) && (document.getElementById('generate_sales_family_id').value.trim() == '')))
		{
			if (action != '')
			{
				for (var i = document.getElementById('generate_sales_type_id').options.length-1; i >= 0; i--) {
					document.getElementById('generate_sales_type_id').options[i].remove();	
				}

				for (var i = document.getElementById('generate_sales_group_id').options.length-1; i >= 0; i--) {
					document.getElementById('generate_sales_group_id').options[i].remove();	
				}
				
				for (var i = document.getElementById('generate_sales_subgroup_id').options.length-1; i >= 0; i--) {
					document.getElementById('generate_sales_subgroup_id').options[i].remove();	
				}

			}

			silicon_ioi.doctype.ioiPriceUpdatingTool.item_type = [];
			silicon_ioi.doctype.ioiPriceUpdatingTool.item_type[0] = ['', ''];
			document.getElementById('generate_sales_type_id').options[document.getElementById('generate_sales_type_id').options.length] = new Option('', '');												


			silicon_ioi.doctype.ioiPriceUpdatingTool.item_group = [];
			silicon_ioi.doctype.ioiPriceUpdatingTool.item_group[0] = ['', ''];
			document.getElementById('generate_sales_group_id').options[document.getElementById('generate_sales_group_id').options.length] = new Option('', '');												

			silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup = [];
			silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[0] = ['', ''];			
			document.getElementById('generate_sales_subgroup_id').options[document.getElementById('generate_sales_subgroup_id').options.length] = new Option('', '');												

		}else
		{
			let me = this;
			let amethod =  'silicon_ioi.ioi_configuration.doctype.ioi_item_type.ioi_item_type.ioi_item_type_get_types';

			frappe.call({  	method: amethod,
							args: {"family_id": document.getElementById('generate_sales_family_id').value, "enabled":1, "current_type": silicon_ioi.doctype.ioiPriceUpdatingTool.current_type},
							async: false,
							callback:function(r){  
													if (action != '')
													{
														for (var i = document.getElementById('generate_sales_type_id').options.length-1; i >= 0; i--) {
															document.getElementById('generate_sales_type_id').options[i].remove();	
														}
										
														for (var i = document.getElementById('generate_sales_group_id').options.length-1; i >= 0; i--) {
															document.getElementById('generate_sales_group_id').options[i].remove();	
														}
														
														for (var i = document.getElementById('generate_sales_subgroup_id').options.length-1; i >= 0; i--) {
															document.getElementById('generate_sales_subgroup_id').options[i].remove();	
														}
													}
								
													silicon_ioi.doctype.ioiPriceUpdatingTool.item_type = [];
													silicon_ioi.doctype.ioiPriceUpdatingTool.item_type[0] = ['', ''];
													document.getElementById('generate_sales_type_id').options[document.getElementById('generate_sales_type_id').options.length] = new Option('', '');												
										

													for (var i = 0; i < r.message.length; i++)
													{	
														silicon_ioi.doctype.ioiPriceUpdatingTool.item_type[i+1] = [r.message[i][0], r.message[i][1]];

														document.getElementById('generate_sales_type_id').options[document.getElementById('generate_sales_type_id').options.length] = new Option(silicon_ioi.doctype.ioiPriceUpdatingTool.item_type[i+1][1], silicon_ioi.doctype.ioiPriceUpdatingTool.item_type[i+1][0]);													

													}

													silicon_ioi.doctype.ioiPriceUpdatingTool.item_group = [];
													silicon_ioi.doctype.ioiPriceUpdatingTool.item_group[0] = ['', ''];
													document.getElementById('generate_sales_group_id').options[document.getElementById('generate_sales_group_id').options.length] = new Option('', '');												
										
													silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup = [];
													silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[0] = ['', ''];			
													document.getElementById('generate_sales_subgroup_id').options[document.getElementById('generate_sales_subgroup_id').options.length] = new Option('', '');												
										
									
												}
			});
		}
	}


	// ***************************************************************************************************************************************
	// Fill Item Group
	// ***************************************************************************************************************************************
	static generate_sales_fill_item_group(action='')
	{	
		if ((!document.getElementById('generate_sales_type_id')) || ((document.getElementById('generate_sales_type_id')) && (document.getElementById('generate_sales_type_id').value.trim() == '')))
		{

			if (action != '')
			{

				for (var i = document.getElementById('generate_sales_group_id').options.length-1; i >= 0; i--) {
					document.getElementById('generate_sales_group_id').options[i].remove();	
				}
				
				for (var i = document.getElementById('generate_sales_subgroup_id').options.length-1; i >= 0; i--) {
					document.getElementById('generate_sales_subgroup_id').options[i].remove();	
				}

			}

			silicon_ioi.doctype.ioiPriceUpdatingTool.item_group = [];
			silicon_ioi.doctype.ioiPriceUpdatingTool.item_group[0] = ['', ''];
			document.getElementById('generate_sales_group_id').options[document.getElementById('generate_sales_group_id').options.length] = new Option('', '');												

			silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup = [];
			silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[0] = ['', ''];			
			document.getElementById('generate_sales_subgroup_id').options[document.getElementById('generate_sales_subgroup_id').options.length] = new Option('', '');												

		}else
		{
			let me = this;
			let amethod =  'silicon_ioi.ioi_configuration.doctype.ioi_item_group.ioi_item_group.ioi_item_group_get_groups';

			frappe.call({  	method: amethod,
							args: {"family_id": document.getElementById('generate_sales_family_id').value, "type_id": document.getElementById('generate_sales_type_id').value, "enabled":1, "current_group": silicon_ioi.doctype.ioiPriceUpdatingTool.current_group},
							async: false,
							callback:function(r){   
													if (action != '')
													{
														for (var i = document.getElementById('generate_sales_group_id').options.length-1; i >= 0; i--) {
															document.getElementById('generate_sales_group_id').options[i].remove();	
														}
														
														for (var i = document.getElementById('generate_sales_subgroup_id').options.length-1; i >= 0; i--) {
															document.getElementById('generate_sales_subgroup_id').options[i].remove();	
														}

													}

													silicon_ioi.doctype.ioiPriceUpdatingTool.item_group = [];
													silicon_ioi.doctype.ioiPriceUpdatingTool.item_group[0] = ['', ''];
													document.getElementById('generate_sales_group_id').options[document.getElementById('generate_sales_group_id').options.length] = new Option('', '');												


													for (var i = 0; i < r.message.length; i++)
													{	
														silicon_ioi.doctype.ioiPriceUpdatingTool.item_group[i+1] = [r.message[i][0], r.message[i][1]];

														document.getElementById('generate_sales_group_id').options[document.getElementById('generate_sales_group_id').options.length] = new Option(silicon_ioi.doctype.ioiPriceUpdatingTool.item_group[i+1][1], silicon_ioi.doctype.ioiPriceUpdatingTool.item_group[i+1][0]);													
													}

													silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup = [];
													silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[0] = ['', ''];			
													document.getElementById('generate_sales_subgroup_id').options[document.getElementById('generate_sales_subgroup_id').options.length] = new Option('', '');												

												}
			});
		}
	}


	// ***************************************************************************************************************************************
	// Fill Item Subgroup
	// ***************************************************************************************************************************************
	static generate_sales_fill_item_subgroup(action='')
	{	
		if ((!document.getElementById('generate_sales_group_id')) || ((document.getElementById('generate_sales_group_id')) && (document.getElementById('generate_sales_group_id').value.trim() == '')))
		{
			if (action != '')
			{
				for (var i = document.getElementById('generate_sales_subgroup_id').options.length-1; i >= 0; i--) {
					document.getElementById('generate_sales_subgroup_id').options[i].remove();	
				}
			}

			silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup = [];
			silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[0] = ['', ''];			
			document.getElementById('generate_sales_subgroup_id').options[document.getElementById('generate_sales_subgroup_id').options.length] = new Option('', '');												

		}else
		{
			let me = this;
			let amethod = 'silicon_ioi.ioi_configuration.doctype.ioi_item_subgroup.ioi_item_subgroup.ioi_item_subgroup_get_subgroups';

			frappe.call({  	method: amethod,
							args: {"family_id": document.getElementById('generate_sales_family_id').value, "type_id": document.getElementById('generate_sales_type_id').value, "group_id": document.getElementById('generate_sales_group_id').value, "enabled":1, "current_subgroup": silicon_ioi.doctype.ioiPriceUpdatingTool.current_subgroup},
							async: false,
							callback:function(r){   
													if (action != '')
													{
														for (var i = document.getElementById('generate_sales_subgroup_id').options.length-1; i >= 0; i--) {
															document.getElementById('generate_sales_subgroup_id').options[i].remove();	
														}
													}

													silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup = [];
													silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[0] = ['', ''];
													document.getElementById('generate_sales_subgroup_id').options[document.getElementById('generate_sales_subgroup_id').options.length] = new Option('', '');												



													for (var i = 0; i < r.message.length; i++)
													{	
														silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[i+1] = [r.message[i][0], r.message[i][1]];
														document.getElementById('generate_sales_subgroup_id').options[document.getElementById('generate_sales_subgroup_id').options.length] = new Option(silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[i+1][1], silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[i+1][0]);													
													}
												}
			});
		}
	}

	static do_generate_sales_clear_values()
	{
		document.getElementById('generate_sales_cb_do_not_take_base_discount').checked = false;
		document.getElementById('generate_sales_cb_do_not_take_extra_discount').checked = false;


		document.getElementById('generate_sales_margin_kind').selectedIndex = 0;
		document.getElementById('generate_sales_margin_percentage').value = '';

		document.getElementById('generate_sales_cb_min_margin').checked = false;
		document.getElementById('generate_sales_min_margin').value = '';
		document.getElementById('generate_sales_purchases_price_inf').value = '';
		
		document.getElementById('generate_sales_cb_max_margin').checked = false;
		document.getElementById('generate_sales_max_margin').value = '';
		document.getElementById('generate_sales_purchases_price_sup').value = '';

		document.getElementById('generate_sales_cb_minimum_value').checked = false;
		document.getElementById('generate_sales_minimum_value').value = '';

		document.getElementById('generate_sales_cb_gross_price_with_base_discount').checked = false;
		document.getElementById('generate_sales_gross_price_with_base_discount').value = '';



		document.getElementById('generate_sales_cb_specific_vat_rate').checked = false;
		document.getElementById('generate_sales_specific_vat_rate').value = '';
		document.getElementById('generate_sales_cb_calculate_included_vat_price').checked = false;
		document.getElementById('generate_sales_cb_inactivate_vat').checked = false;


		document.getElementById('generate_sales_update_rounding_kind_label').value = __('Decimals');
		document.getElementById('generate_sales_update_rounding_kind').selectedIndex = 0;
		document.getElementById('generate_sales_update_rounding_method').selectedIndex = 0;
		document.getElementById('generate_sales_update_rounding_value').value = '2';



		document.getElementById('generate_sales_new_catalog_type').selectedIndex = 0;
		document.getElementById('generate_sales_new_catalog_catalog').selectedIndex = 0;


		document.getElementById('generate_sales_new_catalog_customer').value = '';
		document.getElementById('generate_sales_cb_take_customer_link').checked = false;


		document.getElementById('generate_sales_new_catalog_catalog').hidden = true;
		document.getElementById('generate_sales_new_catalog_customer').hidden = true;
		document.getElementById('generate_sales_new_catalog_bt_customer').hidden = true;
		document.getElementById('generate_sales_cb_take_customer_link').hidden = true;
		document.getElementById('generate_sales_take_customer_link_label').hidden = true;


		document.getElementById('generate_sales_new_sales_condition').selectedIndex = 0;	
		document.getElementById('generate_sales_new_currency').value = '';	

	}


	static do_generate_sales_update_price()
	{
		if (!document.getElementById('generate_sales_detail')) {
			frappe.msgprint({title: __("Message"), message: __('No selected purchases prices'), indicator: "red"});
			raise;
		}else {
			if (document.getElementById('generate_sales_detail').rows.length == 0) {
				frappe.msgprint({title: __("Message"), message: __('No selected purchases prices'), indicator: "red"});
				raise;
			}else {

				let at_least_one_checked = false;

				for (var i = 0; i < document.getElementById('generate_sales_detail').rows.length; i++) {
					
					if (!document.getElementById('generate_sales_checked_id_' + i.toString()))
					{
						frappe.msgprint({title: __("Message"), message: __('No selected purchases prices'), indicator: "red"});
						raise;
					}else {
						if (document.getElementById('generate_sales_checked_id_' + i.toString()).checked)
						{
							at_least_one_checked = true;
							break;
						}
					}
				}

				if (!at_least_one_checked) {
					frappe.msgprint({title: __("Message"), message: __('No selected purchases prices'), indicator: "red"});
					raise;
				}
			}
		}

		let cb_do_not_take_base_discount = 0;

		if (document.getElementById("generate_sales_cb_do_not_take_base_discount").checked) {	
			cb_do_not_take_base_discount = 1;
		}

		let cb_do_not_take_extra_discount = 0;

		if (document.getElementById("generate_sales_cb_do_not_take_extra_discount").checked) {	
			cb_do_not_take_extra_discount = 1;
		}

		let margin_kind = 0;

		if ((document.getElementById("generate_sales_margin_kind")) && (document.getElementById("generate_sales_margin_kind").value.trim() != '')) {
			margin_kind = document.getElementById("generate_sales_margin_kind").value;
		}


		let margin_percentage = 0;

		if ((document.getElementById("generate_sales_margin_percentage")) && (document.getElementById("generate_sales_margin_percentage").value.trim() != '')) {
			margin_percentage = parseFloat(document.getElementById("generate_sales_margin_percentage").value);
		}

		if ((margin_percentage < 0) || (margin_percentage > 100)) {

			frappe.msgprint({title: __("Message"), message: __('Margin percentage has to be between 0 and 100'), indicator: "red"});
			raise;
		}


		let cb_min_margin = 0;
		
		if (document.getElementById("generate_sales_cb_min_margin").checked) {	
			cb_min_margin = 1;
		}


		let min_margin = 0;

		if ((document.getElementById("generate_sales_min_margin")) && (document.getElementById("generate_sales_min_margin").value.trim() != '')) {
			min_margin = parseFloat(document.getElementById("generate_sales_min_margin").value);
		}

		if ((min_margin < 0) || (min_margin > 100)) {

			frappe.msgprint({title: __("Message"), message: __('Min margin has to be between 0 and 100'), indicator: "red"});
			raise;
			
		}


		let cb_max_margin = 0;
		
		if (document.getElementById("generate_sales_cb_max_margin").checked) {	
			cb_max_margin = 1;
		}

		let max_margin = 0;

		if ((document.getElementById("generate_sales_max_margin")) && (document.getElementById("generate_sales_max_margin").value.trim() != '')) {
			max_margin = parseFloat(document.getElementById("generate_sales_max_margin").value);
		}

		if ((max_margin < 0) || (max_margin > 100)) {

			frappe.msgprint({title: __("Message"), message: __('Max margin has to be between 0 and 100'), indicator: "red"});
			raise;
			
		}

		let cb_minimum_value = 0;
		
		if (document.getElementById("generate_sales_cb_minimum_value").checked) {	
			cb_minimum_value = 1;
		}


		let cb_gross_price_with_base_discount = 0;

		if (document.getElementById("generate_sales_cb_gross_price_with_base_discount").checked) {	
			cb_gross_price_with_base_discount = 1;
		}		


		let gross_price_with_base_discount = 0;

		if ((document.getElementById("generate_sales_gross_price_with_base_discount")) && (document.getElementById("generate_sales_gross_price_with_base_discount").value.trim() != '')) {
			gross_price_with_base_discount = parseFloat(document.getElementById("generate_sales_gross_price_with_base_discount").value);
		}

		if ((gross_price_with_base_discount < 0) || (gross_price_with_base_discount > 100)) {

			frappe.msgprint({title: __("Message"), message: __('Gross price with base discount has to be between 0 and 100'), indicator: "red"});
			raise;
			
		}


		


		let cb_specific_vat_rate = 0;

		if (document.getElementById("generate_sales_cb_specific_vat_rate").checked) {	
			cb_specific_vat_rate = 1;
		}

		let cb_calculate_included_vat_price = 0;

		if (document.getElementById("generate_sales_cb_calculate_included_vat_price").checked) {	
			cb_calculate_included_vat_price = 1;
		}

		let cb_inactivate_vat = 0;

		if (document.getElementById("generate_sales_cb_inactivate_vat").checked) {	
			cb_inactivate_vat = 1;
		}




		let rounding_kind = document.getElementById('generate_sales_update_rounding_kind').value;
		let rounding_method = document.getElementById('generate_sales_update_rounding_method').value;

		let rounding_value = 0;
		if ((document.getElementById("generate_sales_update_rounding_value")) && (document.getElementById("generate_sales_update_rounding_value").value.trim() != '')) {
			rounding_value = document.getElementById("generate_sales_update_rounding_value").value;
		}







		let go = true;



		if (document.getElementById('generate_sales_new_catalog_type').value != '') {

			if (document.getElementById('generate_sales_new_catalog_type').value == 'Catalog') {

				if (document.getElementById('generate_sales_new_catalog_catalog').value.trim() == '') {

					frappe.msgprint({title: __("Message"), message: __('Catalog is mandatory'), indicator: "red"});
					raise;
				}


			}else{

				if (document.getElementById('generate_sales_new_catalog_customer').value.trim() == '') {

					if (!document.getElementById('generate_sales_cb_take_customer_link').checked) {
						frappe.msgprint({title: __("Message"), message: __('Customer is mandatory'), indicator: "red"});
						raise;
					}
				}else{

					let meth = 'silicon_ioi.ioi_items.doctype.ioi_sales_price.ioi_sales_price.ioi_sales_prices_is_customer_exist';
					frappe.call({  	method: meth, 
									args: {	"customer_id": document.getElementById("generate_sales_new_catalog_customer").value },
									async: false,
									callback:function(r)	{ 
																if ((r.message == null) || (r.message == 0))
																{
																	go = false;
																}
															}
						});
				}
			}
		}else {
			frappe.msgprint({title: __("Message"), message: __('Cat type is mandatory'), indicator: "red"});
			raise;
		}
		


		if (!go) {
			frappe.msgprint({title: __("Message"), message: __('Customer does not exist'), indicator: "red"});
			raise;			
		}

		let cb_take_customer_link = 0;

		if (document.getElementById('generate_sales_cb_take_customer_link').checked) {

			cb_take_customer_link = 1;

			// Check if all suppliers have each one an existing customer link

			let generate_prices_supplier_id = [];
			let generate_prices_supplier_lines = '';

			for (var i = 0; i < document.getElementById('generate_sales_detail').rows.length; i++) {	

				if (document.getElementById("generate_sales_checked_id_" + i.toString()))	{	

					if (document.getElementById('generate_sales_checked_id_' + i.toString()).checked) {

						if (document.getElementById('generate_sales_detail_supplier_' + i.toString())) {

							if (generate_prices_supplier_id.indexOf(document.getElementById('generate_sales_detail_supplier_' + i.toString()).value) == -1) {
								
								generate_prices_supplier_id[generate_prices_supplier_id.length] = document.getElementById('generate_sales_detail_supplier_' + i.toString()).value;
								generate_prices_supplier_lines += document.getElementById('generate_sales_detail_supplier_' + i.toString()).value + '#sbsepa#'
							}
						}
					}
				}
			}

			if (generate_prices_supplier_id.length == 0) {
				frappe.msgprint({title: __("Message"), message: __('No supplier in thispurchases prices'), indicator: "red"});
				raise;
			}

			let meth = 'silicon_ioi.ioi_items.doctype.ioi_purchases_price.ioi_purchases_price.ioi_purchases_prices_get_suppliers_without_customer_link';
			frappe.call({  	method: meth, 
							args: {	"selected_suppliers": generate_prices_supplier_lines },
							async: false,
							callback:function(r)	{ 
														if (r.message.error == 1) {
															frappe.msgprint({title: __("Message"), message: r.message.error_msg, indicator: "red"});
															go = false;
															raise;
														}
													}
			});

			if (!go) {
				raise;
			}
		}



		if (document.getElementById('generate_sales_new_sales_condition').value.trim() == '') {

			frappe.msgprint({title: __("Message"), message: __('Sales condition is mandatory'), indicator: "red"});
			raise;
		}

		let new_currency = '';

		if  ((!document.getElementById("generate_sales_new_currency")) || ((document.getElementById("generate_sales_new_currency")) && (document.getElementById("generate_sales_new_currency").value.trim() == ''))) {					

			frappe.msgprint({title: __("Message"), message: __('Currency is mandatory'), indicator: "red"});
			raise;

		}
		

		// Check New Currency
		let meth = 'silicon_ioi.ioi_items.doctype.ioi_sales_price.ioi_sales_price.ioi_sales_prices_check_currency';
		frappe.call({  	method: meth, 
						args: {	"currency_id": document.getElementById("generate_sales_new_currency").value },
						async: false,
						callback:function(r)	{ 
													if ((r.message == null) || (r.message == 0))
													{
														frappe.msgprint({title: __("Message"), message: __('Currency does not exist'), indicator: "red"});
														go = false;
														raise;
								
													}else {
														new_currency = document.getElementById("generate_sales_new_currency").value;
													}

												}
		});

		if (!go) {
			raise;
		}




		let cb_only_if_sales_price_exists = 0;

		if (document.getElementById('generate_sales_cb_only_if_sales_price_exists').checked) {
			cb_only_if_sales_price_exists = 1;
		}
		


		if (go) {

			let nb_selected_rows = 0

			for (var i = 0; i < document.getElementById('generate_sales_detail').rows.length; i++) {	
				if (document.getElementById("generate_sales_checked_id_" + i.toString()))	{											
					if (document.getElementById('generate_sales_checked_id_' + i.toString()).checked)	{
						nb_selected_rows++;
					}
				}
			}
	
			let msg = '';


			let top = 10;

			msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;">' + __("Selected purchases prices : ") + '<b>' +  nb_selected_rows.toString() + '</b></label>';	
			
			top += 25;

			msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;">' + __("Destination") + '</label>';	

			top += 25;

			if (document.getElementById('generate_sales_new_catalog_type').value == 'Catalog') {

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;">- ' + __("Catalog : ") + '<b>' + document.getElementById('generate_sales_new_catalog_catalog').value + '</b></label>';	

				top += 25;				
			}else {

				if (!document.getElementById('generate_sales_cb_take_customer_link').checked) {
					
					msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;">- ' + __("Customer : ") + '<b>' + document.getElementById("generate_sales_new_catalog_customer").value + '</b></label>';	

					top += 25;				
	
				}else {

					msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;">- ' + __("Customer : ") + '<b>' + __("use the customer link filled in the suplier") + '</b></label>';	

					top += 25;				

				}				
			}

			msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;">- ' + __("Sales condition : ") + '<b>' + document.getElementById("generate_sales_new_sales_condition").value + '</b></label>';	

			top += 25;

			msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;">- ' + __("Currency : ") + '<b>' + document.getElementById("generate_sales_new_currency").value + '</b></label>';	

			top += 25;


			msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;">' + __("Operations") + '</label>';	

			top += 25;

			if (cb_do_not_take_base_discount == 1) {

				let s = '- ' + __("Do not take account of the discount mentionned in the purchase price");

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

				top += 25;

			}

	
			if (cb_do_not_take_extra_discount == 1) {

				let s = '- ' + __("Do not take account of the extra discount mentionned in the purchase price");

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

				top += 25;

			} 

			if (margin_percentage != 0) {

				let s = '- ' + __("Margin");

				if (margin_kind == 0) {
					s += ' ' + __('Purchases price based');
				}else {
					s += ' ' + __('Sales price based');
				}

				s += ' : <b>' + margin_percentage + '</b> %';

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

				top += 25;
			}

			if (cb_min_margin == 1)
			{
				let s = '- ' + __("Min %");

				s += ' : <b>' + min_margin + '</b> %';

				if ((document.getElementById('generate_sales_purchases_price_inf')) && (document.getElementById('generate_sales_purchases_price_inf').value != '')) {
					s += ' ' + __('if purchases price is inf. than') + ' <b>' + document.getElementById('generate_sales_purchases_price_inf').value + '</b>';
				}else{
					s += ' ' + __('if purchases price is inf. than') + ' <b>0</b>';
				}

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

				top += 25;
			}

	
			if (cb_max_margin == 1)
			{
				let s = '- ' + __("Max %");

				s += ' : <b>' + max_margin + '</b> %';

				if ((document.getElementById('generate_sales_purchases_price_sup')) && (document.getElementById('generate_sales_purchases_price_sup').value != '')) {
					s += ' ' + __('if purchases price is sup. than') + ' <b>' + document.getElementById('generate_sales_purchases_price_sup').value + '</b>';
				}else{
					s += ' ' + __('if purchases price is sup. than') + ' <b>0</b>';
				}

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

				top += 25;
			}

			if (cb_minimum_value == 1)
			{
				let s = '- ' + __("Minimum value");

				if ((document.getElementById('generate_sales_minimum_value')) && (document.getElementById('generate_sales_minimum_value').value != '')) {				
					s += ' : <b>' + document.getElementById('generate_sales_minimum_value').value + '</b>';
				}else{
					s += ' : <b>0</b>';
				}


				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

				top += 25;
			}

			if (cb_gross_price_with_base_discount == 1)
			{
				let s = '- ' + __("Gross price with discount");

				if ((document.getElementById('generate_sales_gross_price_with_base_discount')) && (document.getElementById('generate_sales_gross_price_with_base_discount').value != '')) {				
					s += ' : <b>' + document.getElementById('generate_sales_gross_price_with_base_discount').value + '</b> %';
				}else{
					s += ' : <b>0</b> %';
				}


				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

				top += 25;
			}



			if (cb_specific_vat_rate == 1) {

				let s = '- ' + __("Update specific VAT rate");

				if (document.getElementById('generate_sales_specific_vat_rate').value.trim() != '') {
					s += ' : <b>' + document.getElementById('generate_sales_specific_vat_rate').value.toString() + '</b> %';
				}else {
					s += ' : <b>0</b> %';
				}

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

				top += 25;
			}

			if (cb_inactivate_vat == 1)	{

				let s = '- ' + __("Inactivate the VAT");

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

				top += 25;

			}else{
				if (cb_calculate_included_vat_price == 1)
				{
					let s = '- ' + __("Calculate the incl. VAT price");

					msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					
	
					top += 25;
	
				}

			}


			let s = '- ' + __("Rounding");

			if (document.getElementById('generate_sales_update_rounding_kind').value == 0) {
				s += ' <b>' + __('Decimals') + '</b>';
			}else if (document.getElementById('generate_sales_update_rounding_kind').value == 1) {
				s += ' <b>' + __('Multiple') + '</b>';
			}

			s += ' ' + rounding_value;

			s += ' - ' + __('Method'); 

			if (document.getElementById('generate_sales_update_rounding_method').value == 0) {
				s += ' <b>' + __('Nearest') + '</b>';
			}else if (document.getElementById('generate_sales_update_rounding_method').value == 1) {
				s += ' <b>' + __('Above') + '</b>';
			}else{
				s += ' <b>' + __('Under') + '</b>';
			}

			msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';
			
	
			top += 50;


			s = '<font color="blue">Are you sure to launch the Sales prices generation ?</font>';
			msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';					


			top += 40;
			msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';


	
			frappe.confirm(	msg,
							() => 	{	
										document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

										let generate_prices_selected_lines = '';
										let selected_lines = [];
	
										for (var i = 0; i < document.getElementById('generate_sales_detail').rows.length; i++) {	
											if (document.getElementById("generate_sales_checked_id_" + i.toString()))	{
												if (document.getElementById('generate_sales_checked_id_' + i.toString()).checked)	{
		
													generate_prices_selected_lines += document.getElementById('generate_sales_detail_name_' + i.toString()).value + '#sbsepa#'

													selected_lines[selected_lines.length] = document.getElementById('generate_sales_detail_name_' + i.toString()).value;
													
												}
											}
										}

										
	
										if (generate_prices_selected_lines != '')
										{
											let meth = 'silicon_ioi.ioi_items.doctype.ioi_purchases_price.ioi_purchases_price.ioi_purchases_prices_generate_sales_prices';
	
											frappe.call({  	method: meth, 
															args: {	"selected_lines": generate_prices_selected_lines,
																	"cb_only_if_sales_price_exists": cb_only_if_sales_price_exists,

																	"catalog_type": document.getElementById('generate_sales_new_catalog_type').value,
																	"catalog_catalog": document.getElementById('generate_sales_new_catalog_catalog').value,
																	"catalog_customer": document.getElementById('generate_sales_new_catalog_customer').value,
																	"cb_take_customer_link": cb_take_customer_link,
																	"sales_condition": document.getElementById('generate_sales_new_sales_condition').value,
																	"new_currency": new_currency,


																	"cb_do_not_take_base_discount": cb_do_not_take_base_discount,
																	"cb_do_not_take_extra_discount": cb_do_not_take_extra_discount,

																	"margin_kind": margin_kind,
																	"margin_percentage": margin_percentage,

																	"cb_min_margin": cb_min_margin,
																	"min_margin": min_margin,
																	"purchases_price_inf": document.getElementById('generate_sales_purchases_price_inf').value,
														
																	"cb_max_margin": cb_max_margin,
																	"max_margin": max_margin,
																	"purchases_price_sup": document.getElementById('generate_sales_purchases_price_sup').value,
															
																	"cb_minimum_value": cb_minimum_value,
																	"minimum_value": document.getElementById('generate_sales_minimum_value').value,

																	"cb_gross_price_with_base_discount": cb_gross_price_with_base_discount,
																	"gross_price_with_base_discount": gross_price_with_base_discount,
															

																	"cb_specific_vat_rate": cb_specific_vat_rate,
																	"update_specific_vat_rate": document.getElementById("generate_sales_specific_vat_rate").value,
																	"cb_calculate_included_vat_price": cb_calculate_included_vat_price,
																	"cb_inactivate_vat": cb_inactivate_vat,
															

																	"rounding_kind": rounding_kind,
																	"rounding_method": rounding_method,
																	"rounding_value": rounding_value,
															
															
															},
															async: false,
															callback:function(r)	{ 

																							let inserted = r.message.inserted;
																							let updated = r.message.updated;

                                                                                            silicon_ioi.doctype.ioiPriceUpdatingTool.do_generate_sales_refresh();

																							silicon_ioi.doctype.ioiPriceUpdatingTool.sleep_static(200).then(() => {

                                                                                                let nb_selected_rows = 0;

                                                                                                for (var i = 0; i < document.getElementById('generate_sales_detail').rows.length; i++) {	
																									if (document.getElementById("generate_sales_checked_id_" + i.toString()))	{
																										document.getElementById('generate_sales_checked_id_' + i.toString()).checked = false;
														
																										if (selected_lines.indexOf(document.getElementById('generate_sales_detail_name_' + i.toString()).value) != -1)
																										{	
																											document.getElementById('generate_sales_checked_id_' + i.toString()).checked = true;
																											nb_selected_rows++;
																										}
																									}
                                                                                                }
                                                    
                                                                                       
                                                                                                if (document.getElementById("generate_sales_grid_banner"))
                                                                                                {	
                                                                                                    if (nb_selected_rows <= 1) {
                                                                                                        document.getElementById("generate_sales_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected purchases price")
                                                                                                    }else {
                                                                                                        document.getElementById("generate_sales_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected purchases prices")
                                                                                                    }
                                                                                                }

																								let top = 0;

																								let msg = '<label style="position: absolute; top: ' + top.toString() + 'px; left: 12px;"> ' + __('Generation terminated with success') + '</label>';
																								top += 25;

																								if (inserted != 0) {
                                                    												msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 12px;"> ' + __('Inserted record(s)') + ' : ' + inserted.toString() + '</label>';
																									top += 25;
																								}

																								if (updated != 0) {
																									msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 12px;"> ' + __('Updated record(s)') + ' : ' + updated.toString() + '</label>';
																									top += 25;
																								}

																								msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';

																								frappe.msgprint({title: __("Message"), message: msg, indicator: "blue"});

                                                    


																							});

																		}
											});
						
											
										}
	
									}, 
							() => 	{	
									}
			);
		}

	}






    // ***************************************************************************************************************************************
	// 
	// Sales Price : Price updating tool
    //
	// ***************************************************************************************************************************************


	// ***************************************************************************************************************************************
	// Load sales prices tool
	// ***************************************************************************************************************************************
	static load_update_sales_prices(html_field)
	{
		silicon_ioi.doctype.ioiPriceUpdatingTool.is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

		silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_sales_prices(html_field);
	}



	// ***************************************************************************************************************************************
	// Update sales prices
	// ***************************************************************************************************************************************
	static update_sales_prices(html_field)
	{
		silicon_ioi.doctype.ioiPriceUpdatingTool.is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

        if (cur_frm) {
            if (cur_frm.is_dirty())
            {	
                let fct_callback = function () { silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_sales_prices(); };
                cur_frm.save('Save', fct_callback);
            }else
            {
                silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_sales_prices();
            }
        }else {
            silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_sales_prices();
        }
	}	

	static do_update_sales_prices(html_field)	
	{
		if (document.getElementById("update_sales_doc_type_label"))
		{	document.getElementById("update_sales_doc_type_label").remove();
		}

		if (document.getElementById("update_sales_doc_type_id"))
		{	document.getElementById("update_sales_doc_type_id").remove();
		}

	
		if (document.getElementById("update_sales_catalog_customer_label"))
		{	document.getElementById("update_sales_catalog_customer_label").remove();
		}

		if (document.getElementById("update_sales_catalog_customer_id"))
		{	document.getElementById("update_sales_catalog_customer_id").remove();
		}

		if (document.getElementById("update_sales_select_customer_catalog_button"))
		{	document.getElementById("update_sales_select_customer_catalog_button").remove();
		}

		if (document.getElementById("update_sales_sales_condition_label"))
		{	document.getElementById("update_sales_sales_condition_label").remove();
		}

		if (document.getElementById("update_sales_sales_condition_id"))
		{	document.getElementById("update_sales_sales_condition_id").remove();
		}

		if (document.getElementById("update_sales_item_label"))
		{	document.getElementById("update_sales_item_label").remove();
		}

		if (document.getElementById("update_sales_item_id"))
		{	document.getElementById("update_sales_item_id").remove();
		}

		if (document.getElementById("update_sales_select_item_button"))
		{	document.getElementById("update_sales_select_item_button").remove();
		}


		if (document.getElementById("update_sales_unit_label"))
		{	document.getElementById("update_sales_unit_label").remove();
		}

		if (document.getElementById("update_sales_unit_id"))
		{	document.getElementById("update_sales_unit_id").remove();
		}


		if (document.getElementById("update_sales_currency_label"))
		{	document.getElementById("update_sales_currency_label").remove();
		}

		if (document.getElementById("update_sales_currency_id"))
		{	document.getElementById("update_sales_currency_id").remove();
		}

		if (document.getElementById("update_sales_select_currency_button"))
		{	document.getElementById("update_sales_select_currency_button").remove();
		}		


		if (document.getElementById("update_sales_enabled_label"))
		{	document.getElementById("update_sales_enabled_label").remove();
		}

		if (document.getElementById("update_sales_enabled"))
		{	document.getElementById("update_sales_enabled").remove();
		}


		if (document.getElementById("update_sales_family_label"))
		{	document.getElementById("update_sales_family_label").remove();
		}

		if (document.getElementById("update_sales_family_id"))
		{	document.getElementById("update_sales_family_id").remove();
		}
		
		if (document.getElementById("update_sales_type_label"))
		{	document.getElementById("update_sales_type_label").remove();
		}

		if (document.getElementById("update_sales_type_id"))
		{	document.getElementById("update_sales_type_id").remove();
		}
		
		if (document.getElementById("update_sales_group_label"))
		{	document.getElementById("update_sales_group_label").remove();
		}

		if (document.getElementById("update_sales_group_id"))
		{	document.getElementById("update_sales_group_id").remove();
		}
		
		if (document.getElementById("update_sales_subgroup_label"))
		{	document.getElementById("update_sales_subgroup_label").remove();
		}

		if (document.getElementById("update_sales_subgroup_id"))
		{	document.getElementById("update_sales_subgroup_id").remove();
		}
		
		if (document.getElementById("update_sales_classification_label"))
		{	document.getElementById("update_sales_classification_label").remove();
		}

		if (document.getElementById("update_sales_classification_id"))
		{	document.getElementById("update_sales_classification_id").remove();
		}
		
		
		if (document.getElementById("update_sales_shop_category_label"))
		{	document.getElementById("update_sales_shop_category_label").remove();
		}

		if (document.getElementById("update_sales_shop_category_id"))
		{	document.getElementById("update_sales_shop_category_id").remove();
		}

		if (document.getElementById("update_sales_withme_label"))
		{	document.getElementById("update_sales_withme_label").remove();
		}

		if (document.getElementById("update_sales_withme_id"))
		{	document.getElementById("update_sales_withme_id").remove();
		}



		if (document.getElementById("update_sales_grid_banner"))
		{	document.getElementById("update_sales_grid_banner").remove();
		}

		if (document.getElementById("update_sales_grid"))
		{	document.getElementById("update_sales_grid").remove();
		}

		if (document.getElementById("update_sales_check_all_none")) {
			document.getElementById("update_sales_check_all_none").remove();
		}

		if (document.getElementById("update_sales_detail")) {
			
			for (var i = 0; i < document.getElementById("update_sales_detail").rows.length; i++)
			{
				if (document.getElementById("update_sales_checked_id_" + i.toString()))	{
					document.getElementById("update_sales_checked_id_" + i.toString()).remove();
				}
			}

			document.getElementById("update_sales_detail").remove();
		}


		if (document.getElementById("update_sales_content")) {
			document.getElementById("update_sales_content").remove();
		}

		
		if (document.getElementById("update_sales_search"))
		{	document.getElementById("update_sales_search").remove();
		}

		if (document.getElementById("update_sales_clear"))
		{	document.getElementById("update_sales_clear").remove();
		}

		if (document.getElementById("update_sales_orderby"))
		{	document.getElementById("update_sales_orderby").remove();
		}

		if (document.getElementById("update_sales_order"))
		{	document.getElementById("update_sales_order").remove();
		}


		if (document.getElementById("update_sales_cb_start_date"))
		{	document.getElementById("update_sales_cb_start_date").remove();
		}

		if (document.getElementById("update_sales_start_date_label"))
		{	document.getElementById("update_sales_start_date_label").remove();
		}

		if (document.getElementById("update_sales_start_date"))
		{	document.getElementById("update_sales_start_date").remove();
		}



		if (document.getElementById("update_sales_cb_end_date"))
		{	document.getElementById("update_sales_cb_end_date").remove();
		}

		if (document.getElementById("update_sales_end_date_label"))
		{	document.getElementById("update_sales_end_date_label").remove();
		}

		if (document.getElementById("update_sales_end_date"))
		{	document.getElementById("update_sales_end_date").remove();
		}

		if (document.getElementById("update_sales_cb_clear_end_date"))
		{	document.getElementById("update_sales_cb_clear_end_date").remove();
		}

		if (document.getElementById("update_sales_clear_end_date_label"))
		{	document.getElementById("update_sales_clear_end_date_label").remove();
		}



		if (document.getElementById("update_sales_cb_published_price"))
		{	document.getElementById("update_sales_cb_published_price").remove();
		}

		if (document.getElementById("update_sales_published_price_label"))
		{	document.getElementById("update_sales_published_price_label").remove();
		}

		if (document.getElementById("update_sales_published_price"))
		{	document.getElementById("update_sales_published_price").remove();
		}

		
		if (document.getElementById("update_sales_cb_web_published"))
		{	document.getElementById("update_sales_cb_web_published").remove();
		}

		if (document.getElementById("update_sales_web_published_label"))
		{	document.getElementById("update_sales_web_published_label").remove();
		}

		if (document.getElementById("update_sales_web_published"))
		{	document.getElementById("update_sales_web_published").remove();
		}
        
		if (document.getElementById("update_sales_cb_with_me"))
		{	document.getElementById("update_sales_cb_with_me").remove();
		}

		if (document.getElementById("update_sales_with_me_label"))
		{	document.getElementById("update_sales_with_me_label").remove();
		}

		if (document.getElementById("update_sales_with_me_value"))
		{	document.getElementById("update_sales_with_me_value").remove();
		}

		if (document.getElementById("update_sales_cb_clear_with_me"))
		{	document.getElementById("update_sales_cb_clear_with_me").remove();
		}

		if (document.getElementById("update_sales_clear_with_me_label"))
		{	document.getElementById("update_sales_clear_with_me_label").remove();
		}

		


		if (document.getElementById("update_sales_cb_increase"))
		{	document.getElementById("update_sales_cb_increase").remove();
		}


		if (document.getElementById("update_sales_increase_unit_price_label"))
		{	document.getElementById("update_sales_increase_unit_price_label").remove();
		}

		if (document.getElementById("update_sales_increase_unit_price"))
		{	document.getElementById("update_sales_increase_unit_price").remove();
		}

		if (document.getElementById("update_sales_increase_unit_price_percent"))
		{	document.getElementById("update_sales_increase_unit_price_percent").remove();
		}


		if (document.getElementById("update_sales_cb_fixed_price"))
		{	document.getElementById("update_sales_cb_fixed_price").remove();
		}


		if (document.getElementById("update_sales_update_fixed_price_label"))
		{	document.getElementById("update_sales_update_fixed_price_label").remove();
		}

		if (document.getElementById("update_sales_update_fixed_price"))
		{	document.getElementById("update_sales_update_fixed_price").remove();
		}


		if (document.getElementById("update_sales_cb_base_discount"))
		{	document.getElementById("update_sales_cb_base_discount").remove();
		}

		if (document.getElementById("update_sales_update_base_discount_label"))
		{	document.getElementById("update_sales_update_base_discount_label").remove();
		}

		if (document.getElementById("update_sales_update_base_discount"))
		{	document.getElementById("update_sales_update_base_discount").remove();
		}

		if (document.getElementById("update_sales_update_base_discount_percent"))
		{	document.getElementById("update_sales_update_base_discount_percent").remove();
		}

		if (document.getElementById("update_sales_cb_extra_discount"))
		{	document.getElementById("update_sales_cb_extra_discount").remove();
		}

		if (document.getElementById("update_sales_update_extra_discount_label"))
		{	document.getElementById("update_sales_update_extra_discount_label").remove();
		}

		if (document.getElementById("update_sales_update_extra_discount"))
		{	document.getElementById("update_sales_update_extra_discount").remove();
		}

		if (document.getElementById("update_sales_update_extra_discount_percent"))
		{	document.getElementById("update_sales_update_extra_discount_percent").remove();
		}


		if (document.getElementById("update_sales_cb_increase_recommended_unit_price"))
		{	document.getElementById("update_sales_cb_increase_recommended_unit_price").remove();
		}

		if (document.getElementById("update_sales_increase_recommended_unit_price_label"))
		{	document.getElementById("update_sales_increase_recommended_unit_price_label").remove();
		}


		if (document.getElementById("update_sales_increase_recommended_unit_price"))
		{	document.getElementById("update_sales_increase_recommended_unit_price").remove();
		}

		if (document.getElementById("update_sales_increase_recommended_unit_price_percent"))
		{	document.getElementById("update_sales_increase_recommended_unit_price_percent").remove();
		}


		if (document.getElementById("update_sales_cb_recommended_base_discount"))
		{	document.getElementById("update_sales_cb_recommended_base_discount").remove();
		}

		if (document.getElementById("update_sales_update_recommended_base_discount_label"))
		{	document.getElementById("update_sales_update_recommended_base_discount_label").remove();
		}

		if (document.getElementById("update_sales_update_recommended_base_discount"))
		{	document.getElementById("update_sales_update_recommended_base_discount").remove();
		}

		if (document.getElementById("update_sales_update_recommended_base_discount_percent"))
		{	document.getElementById("update_sales_update_recommended_base_discount_percent").remove();
		}


		if (document.getElementById("update_sales_cb_recommended_margin"))
		{	document.getElementById("update_sales_cb_recommended_margin").remove();
		}

		if (document.getElementById("update_sales_update_recommended_margin_label"))
		{	document.getElementById("update_sales_update_recommended_margin_label").remove();
		}

		if (document.getElementById("update_sales_update_recommended_margin"))
		{	document.getElementById("update_sales_update_recommended_margin").remove();
		}

		if (document.getElementById("update_sales_update_recommended_margin_percent"))
		{	document.getElementById("update_sales_update_recommended_margin_percent").remove();
		}


		if (document.getElementById("update_sales_cb_margin_mode"))
		{	document.getElementById("update_sales_cb_margin_mode").remove();
		}

		if (document.getElementById("update_sales_update_margin_mode_label"))
		{	document.getElementById("update_sales_update_margin_mode_label").remove();
		}

		if (document.getElementById("update_sales_update_margin_mode"))
		{	document.getElementById("update_sales_update_margin_mode").remove();
		}


		if (document.getElementById("update_sales_cb_specific_vat_rate"))
		{	document.getElementById("update_sales_cb_specific_vat_rate").remove();
		}


		if (document.getElementById("update_sales_update_specific_vat_rate_label"))
		{	document.getElementById("update_sales_update_specific_vat_rate_label").remove();
		}

		if (document.getElementById("update_sales_update_specific_vat_rate"))
		{	document.getElementById("update_sales_update_specific_vat_rate").remove();
		}

		if (document.getElementById("update_sales_update_specific_vat_rate_percent"))
		{	document.getElementById("update_sales_update_specific_vat_rate_percent").remove();
		}


		if (document.getElementById("update_sales_update_rounding_kind_label"))
		{	document.getElementById("update_sales_update_rounding_kind_label").remove();
		}

		if (document.getElementById("update_sales_update_rounding_kind"))
		{	document.getElementById("update_sales_update_rounding_kind").remove();
		}

		if (document.getElementById("update_sales_update_rounding_method_label"))
		{	document.getElementById("update_sales_update_rounding_method_label").remove();
		}

		if (document.getElementById("update_sales_update_rounding_method"))
		{	document.getElementById("update_sales_update_rounding_method").remove();
		}


		if (document.getElementById("update_sales_update_rounding_value_label"))
		{	document.getElementById("update_sales_update_rounding_value_label").remove();
		}

		if (document.getElementById("update_sales_update_rounding_value"))
		{	document.getElementById("update_sales_update_rounding_value").remove();
		}


		if (document.getElementById("update_sales_bt_clear_values"))
		{	document.getElementById("update_sales_bt_clear_values").remove();
		}		


		if (document.getElementById("update_sales_create_new_cat_cond_label"))
		{	document.getElementById("update_sales_create_new_cat_cond_label").remove();
		}

		if (document.getElementById("update_sales_cb_new_catalog"))
		{	document.getElementById("update_sales_cb_new_catalog").remove();
		}

		if (document.getElementById("update_sales_new_catalog_label"))
		{	document.getElementById("update_sales_new_catalog_label").remove();
		}

		if (document.getElementById("update_sales_new_catalog_type"))
		{	document.getElementById("update_sales_new_catalog_type").remove();
		}


		if (document.getElementById("update_sales_new_catalog_catalog"))
		{	document.getElementById("update_sales_new_catalog_catalog").remove();
		}

		if (document.getElementById("update_sales_new_catalog_customer"))
		{	document.getElementById("update_sales_new_catalog_customer").remove();
		}


		if (document.getElementById("update_sales_new_catalog_bt_customer"))
		{	document.getElementById("update_sales_new_catalog_bt_customer").remove();
		}

		
		if (document.getElementById("update_sales_cb_new_sales_condition"))
		{	document.getElementById("update_sales_cb_new_sales_condition").remove();
		}

		if (document.getElementById("update_sales_new_sales_condition_label"))
		{	document.getElementById("update_sales_new_sales_condition_label").remove();
		}

		if (document.getElementById("update_sales_new_sales_condition"))
		{	document.getElementById("update_sales_new_sales_condition").remove();
		}



		if (document.getElementById("update_sales_cb_convert"))
		{	document.getElementById("update_sales_cb_convert").remove();
		}

		if (document.getElementById("update_sales_new_currency_label"))
		{	document.getElementById("update_sales_new_currency_label").remove();
		}


		if (document.getElementById("update_sales_new_currency"))
		{	document.getElementById("update_sales_new_currency").remove();
		}

		if (document.getElementById("update_sales_new_currency_select_currency_button"))
		{	document.getElementById("update_sales_new_currency_select_currency_button").remove();
		}




		if (document.getElementById("update_sales_bt_update_price"))
		{	document.getElementById("update_sales_bt_update_price").remove();
		}

		if (document.getElementById("update_sales_set_enabled"))
		{	document.getElementById("update_sales_set_enabled").remove();
		}

		if (document.getElementById("update_sales_set_disabled"))
		{	document.getElementById("update_sales_set_disabled").remove();
		}

		if (document.getElementById("update_sales_delete_disabled"))
		{	document.getElementById("update_sales_delete_disabled").remove();
		}


		if (document.getElementById("update_sales_warning1"))
		{	document.getElementById("update_sales_warning1").remove();
		}


		let html = '';

		html += '<div style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '	<label style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Filters") + '</b></label>';			
		html += '</div>'


		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'

		html += '<div style="overflow: auto; overflow-x: auto; height:174px;">';		


		// Cat Type
		html += '<div style="position: relative; top: 0px; left: 0px; width:110px;">';
		html += '	<label id="update_sales_doc_type_label" style="position: absolute; top: 0px; left: 2px;">' + __("Cat. type") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 110px; height: 30px;"> ';
		html += '		<select id="update_sales_doc_type_id" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';		
		html += '			<option value="Catalog">' + __("Catalog") + '</option> ';
        html += '			<option value="Customer">' + __("Customer") + '</option> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';


		// Catalog Customer Id
		html += '	<div style="position: relative; top: 0px; left: 120px; width:230px;">';
		html += '		<label id="update_sales_catalog_customer_label" style="position: absolute; top: 0px; left: 2px;">' + __("Catalog / Customer") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 190px; height: 25px;"> ';
		html += '			<input id="update_sales_catalog_customer_id" type="text" class="input-with-feedback form-control bold">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 200px; width: 30px; height: 25px;"> ';		
		html +='			<button id="update_sales_select_customer_catalog_button" style="height: 30px; width: 30px;border: none; outline:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';

		html += '	</div>';


		// Sales Condition
		html += '<div style="position: relative; top: 0px; left: 360px; width:170px;">';
		html += '	<label id="update_sales_sales_condition_label" style="position: absolute; top: 0px; left: 2px;">' + __("Sales condition") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '		<select id="update_sales_sales_condition_id" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';

		let method = 'silicon_ioi.ioi_items.doctype.ioi_sales_price.ioi_sales_price.ioi_sales_prices_get_sales_condition'
		frappe.call({  	method: method, 
						args: {},
						async: false,
						callback:function(r)	{ 
													for (var i = 0; i < r.message.length; i++)
													{
														html += '		<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
													}
												}
		});
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';


		// Item
		html += '	<div style="position: relative; top: 0px; left: 540px; width:170px;">';
		html += '		<label id="update_sales_item_label" style="position: absolute; top: 0px; left: 2px;">' + __("Item") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 200px; height: 25px;"> ';
		html += '			<input id="update_sales_item_id" type="text" class="input-with-feedback form-control bold">';
		html += '		</div>';

		html += '		<div style="position: absolute; top: 25px; left: 212px; width: 30px; height: 25px;"> ';		
		html +='			<button id="update_sales_select_item_button" style="height: 30px; width: 30px;border: none; outline:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';

		html += '	</div>';

		// Unit
		html += '<div style="position: relative; top: 0px; left: 790px; width:100px;">';
		html += '	<label id="update_sales_unit_label" style="position: absolute; top: 0px; left: 2px;">' + __("Unit") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 100px; height: 30px;"> ';
		html += '		<select id="update_sales_unit_id" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';

		method = 'silicon_ioi.ioi_items.doctype.ioi_sales_price.ioi_sales_price.ioi_sales_prices_get_unit'
		frappe.call({  	method: method, 
						args: {},
						async: false,
						callback:function(r)	{ 
													for (var i = 0; i < r.message.length; i++)
													{
														html += '		<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
													}
												}
		});
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';


		// Currency
		html += '	<div style="position: relative; top: 0px; left: 900px; width:170px;">';
		html += '		<label id="update_sales_currency_label" style="position: absolute; top: 0px; left: 2px;">' + __("Currency") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 130px; height: 25px;"> ';
		html += '			<input id="update_sales_currency_id" type="text" class="input-with-feedback form-control bold">';
		html += '		</div>';
		html += '		<div  style="position: absolute; top: 25px; left: 142px; width: 130px; height: 25px;"> ';		
		html +='			<button id="update_sales_select_currency_button" style="height: 30px; width: 30px;border: none; outline:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';



		// Enabled
		html += '<div style="position: relative; top: 0px; left: 1080px; width:135px;">';
		html += '	<label id="update_sales_enabled_label" style="position: absolute; top: 0px; left: 2px;">' + __("Status") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 135px; height: 30px;"> ';
		html += '		<select id="update_sales_enabled" class="input-with-feedback form-control bold"> ';
		html += '			<option value="">All</option> ';
		html += '			<option value="0">' + __("Disabled") + '</option> ';
		html += '			<option value="1">' + __("Enabled") + '</option> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';

		// Item Family
		html += '<div style="position: relative; top: 64px; left: 0px; width:170px;">';
		html += '	<label id="update_sales_family_label" style="position: absolute; top: 0px; left: 2px;">' + __("Family") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '		<select id="update_sales_family_id" class="input-with-feedback form-control bold"> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';

		// Item Type
		html += '<div style="position: relative; top: 64px; left: 180px; width:170px;">';
		html += '	<label id="update_sales_type_label" style="position: absolute; top: 0px; left: 2px;">' + __("Type") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '		<select id="update_sales_type_id" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';

		// Item Group
		html += '<div style="position: relative; top: 64px; left: 360px; width:170px;">';
		html += '	<label id="update_sales_group_label" style="position: absolute; top: 0px; left: 2px;">' + __("Group") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '		<select id="update_sales_group_id" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';

		// Item Sub Group
		html += '<div style="position: relative; top: 64px; left: 540px; width:170px;">';
		html += '	<label id="update_sales_subgroup_label" style="position: absolute; top: 0px; left: 2px;">' + __("Sub group") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '		<select id="update_sales_subgroup_id" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';

		// Item Classification
		html += '<div style="position: relative; top: 64px; left: 720px; width:170px;">';
		html += '	<label id="update_sales_classification_label" style="position: absolute; top: 0px; left: 2px;">' + __("Classification") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '		<select id="update_sales_classification_id" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';

		method = 'silicon_ioi.ioi_items.doctype.ioi_sales_price.ioi_sales_price.ioi_sales_prices_get_classification'
		frappe.call({  	method: method, 
						args: {},
						async: false,
						callback:function(r)	{ 
													for (var i = 0; i < r.message.length; i++)
													{
														html += '		<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
													}
												}
		});

		html += '		</select> ';
		html += '	</div>';
		html += '</div>';


		// Item Shop Category
		html += '<div style="position: relative; top: 64px; left: 900px; width:170px;">';
		html += '	<label id="update_sales_shop_category_label" style="position: absolute; top: 0px; left: 2px;">' + __("Shop Category") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '		<select id="update_sales_shop_category_id" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';

		method = 'silicon_ioi.ioi_items.doctype.ioi_sales_price.ioi_sales_price.ioi_sales_prices_get_shop_category'
		frappe.call({  	method: method, 
						args: {},
						async: false,
						callback:function(r)	{ 
													for (var i = 0; i < r.message.length; i++)
													{
														html += '		<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
													}
												}
		});


		html += '		</select> ';
		html += '	</div>';
		html += '</div>';



		// With me
		html += '<div style="position: relative; top: 64px; left: 1080px; width:135px;">';
		html += '	<label id="update_sales_withme_label" style="position: absolute; top: 0px; left: 2px;">' + __("With me") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 135px; height: 30px;"> ';
		html += '		<select id="update_sales_withme_id" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';

		method = 'silicon_ioi.ioi_items.doctype.ioi_sales_price.ioi_sales_price.ioi_sales_prices_get_withme'
		frappe.call({  	method: method, 
						args: {},
						async: false,
						callback:function(r)	{ 
													for (var i = 0; i < r.message.length; i++)
													{
														html += '		<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
													}
												}
		});


		html += '		</select> ';
		html += '	</div>';
		html += '</div>';


		
		// Search
		html += '<div style="position: relative; top: 132px; left: 0px; width:110px;">';		
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='		<button id="update_sales_search" class="btn btn-default ellipsis" style="height: 30px; width: 110px;" onclick="">' + __("Search") + '</button>';
		html += '	</div>';
		html += '</div>';

		// Clear
		html += '<div style="position: relative; top: 132px; left: 120px; width:50px;">';		
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 30px;">';
		html +='		<button id="update_sales_clear" title="' + __("Clear") +'" data-label="Search" class="btn btn-default ellipsis" style="height: 30px; width: 50px;" onclick="">' + __("...") + '</button>';
		html += '	</div>';
		html += '</div>';

		html += '<div style="position: relative; top: 132px; left: 180px; height:30px; width:600px;">';
		html += '	<label id="update_sales_grid_banner" style="position: relative; top: 6px; left: 6px;"></label>';			
		html += '</div>'


		html += '</div>';

		html += '<input id="update_sales_orderby" type="hidden" value="name">';
		html += '<input id="update_sales_order" type="hidden" value="asc">';


		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'


		// Grid Header
		html += '<div id="update_sales_content" style="overflow: auto; overflow-x: auto; height:392px;">';		

		html += '<table id="update_sales_grid" border=1 style="border: 1px solid #E8EAEB" width=2090px data-custom-grid="true">';

		html += '<tr style="height:30px">';

		html += '<td width=30px align="center" style="vertical-align: middle;">';
		html += '<input type="checkbox" id="update_sales_check_all_none" style="postion:absolute; top: 2px; left: 2px;" checked ';
		html += '       onclick="   let nb_selected_rows = 0; ';
		html += '					for (var i = 0; i < document.getElementById(\'update_sales_detail\').rows.length; i++) ';
		html += '					{	';
		html += '						if (document.getElementById(\'update_sales_checked_id_\' + i.toString())) { '
		html += '							document.getElementById(\'update_sales_checked_id_\' + i.toString()).checked = this.checked; ';
		html += '						} ';

		html += '						if (document.getElementById(\'update_sales_checked_id_\' + i.toString())) { '		
		html += '							if (document.getElementById(\'update_sales_checked_id_\' + i.toString()).checked) { '
		html += '								nb_selected_rows++; ';
		html += '							} ';		
		html += '						} ';
		html += '					} ';

		html += '					if (document.getElementById(\'update_sales_grid_banner\')) ';
		html += '					{ ';
		html += '						if (nb_selected_rows <= 1) { ';
		html += '							document.getElementById(\'update_sales_grid_banner\').innerHTML = \'<b>\' + nb_selected_rows.toString() + \'</b> \' + __(\'selected sales price (prices updated by contracts have been excluded)\'); ';
		html += '						}else { ';
		html += '							document.getElementById(\'update_sales_grid_banner\').innerHTML = \'<b>\' + nb_selected_rows.toString() + \'</b> \' + __(\'selected sales prices (prices updated by contracts have been excluded)\'); ';
		html += '						} ';
		html += '					} ';
		html += '                " ';
		html += '>'; 
		html += '</td>';
		html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
		html += '<td id="update_sales_col_select_type" width=150px style="vertical-align: middle;"><b>&nbsp;' + __("Cat Type") +                  '<label id="update_sales_col_label_select_type" style="width:30px; height:8px" align="right"></label></b></td>';				
        html += '<td id="update_sales_col_catalog_customer_id" width=200px style="vertical-align: middle;"><b>&nbsp;' + __("Cat. / Cust.") +      '<label id="update_sales_col_label_catalog_customer_id" style="width:30px; height:8px" align="right">&darr;</label></b></td>';		
		html += '<td id="update_sales_col_sales_condition_id" width=150px style="vertical-align: middle;"><b>&nbsp;' + __("Sales cond.") +        '<label id="update_sales_col_label_sales_condition_id" style="width:30px; height:8px" align="right"></label></b></td>';		
		html += '<td id="update_sales_col_item_id" width=250px style="vertical-align: middle;"><b>&nbsp;' + __("Item") +                          '<label id="update_sales_col_label_item_id" style="width:30px; height:8px" align="right"></label></b></td>';		
		html += '<td id="update_sales_col_unit_id" width=80px style="vertical-align: middle;"><b>&nbsp;' + __("Unit") +                           '<label id="update_sales_col_label_unit_id" style="width:30px; height:8px" align="right"></label></b></td>';				
		html += '<td id="update_sales_col_currency_id" width=80px style="vertical-align: middle;"><b>&nbsp;' + __("Curr.") +                      '<label id="update_sales_col_label_currency_id" style="width:30px; height:8px" align="right"></label></b></td>';				
		html += '<td id="update_sales_col_unit_price" width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Unit price") +                 '<label id="update_sales_col_label_unit_price" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id="update_sales_col_fixed_price" width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Fix. price") +                '<label id="update_sales_col_label_fixed_price" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id="update_sales_col_base_discount" width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Base disc.") +              '<label id="update_sales_col_label_base_discount" style="width:20px; height:8px" align="right"></label></b></td>';
		html += '<td id="update_sales_col_extra_discount" width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Extra disc.") +            '<label id="update_sales_col_label_extra_discount" style="width:20px; height:8px" align="right"></label></b></td>';		
		html += '<td id="update_sales_col_enabled" width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Status") +        			 	  '<label id="update_sales_col_label_enabled" style="width:30px; height:8px" align="right"></label></b></td>';				
        html += '<td id="update_sales_col_start_date" width=110px style="vertical-align: middle;"><b>&nbsp;' + __("Start date") +    			   '<label id="update_sales_col_label_start_date" style="width:30px; height:8px" align="right"></label></b></td>';				        
        html += '<td id="update_sales_col_end_date" width=110px style="vertical-align: middle;"><b>&nbsp;' + __("End date") +    			       '<label id="update_sales_col_label_end_date" style="width:30px; height:8px" align="right"></label></b></td>';
        html += '<td id="update_sales_col_price_published" width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Published") +    	       '<label id="update_sales_col_label_price_published" style="width:30px; height:8px" align="right"></label></b></td>';
        html += '<td id="update_sales_col_web_price_published" width=150px style="vertical-align: middle;"><b>&nbsp;' + __("Web Published") +      '<label id="update_sales_col_label_web_price_published" style="width:30px; height:8px" align="right"></label></b></td>';
        html += '<td id="update_sales_col_item_with_me" width=150px style="vertical-align: middle;"><b>&nbsp;' + __("With me") +                   '<label id="update_sales_col_label_item_with_me" style="width:30px; height:8px" align="right"></label></b></td>';
        html += '</tr>';
		html += '</table>';		


		// Result
		html += '<table id="update_sales_detail" border=1 style="border: 1px solid #E8EAEB" width=2090px>';
		html += '<tr style="height:30px">';
		html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';		
		html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
        html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';        
		html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
        html += '<td width=110px style="vertical-align: middle;">&nbsp;</td>';
        html += '<td width=110px style="vertical-align: middle;">&nbsp;</td>';
        html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
        html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
        html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';


		html += '</tr>';
		html += '</table>';		

		html += '</div>'


		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'

		html += '<div style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '	<label style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Fields update") + '</b></label>';			
		html += '</div>'


		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'

		html += '<div style="overflow: auto; overflow-x: auto; height:90px;">';
        
		// Start date
		html += '	<div style="position: relative; top: 0px; left: 0px; width:400px;">';
		html += '       <input type="checkbox" id="update_sales_cb_start_date" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="update_sales_start_date_label" style="position: absolute; top: 6px; left: 30px;">' + __("Start date") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 110px; width: 140px; height: 25px;"> ';
		html += '			<input id="update_sales_start_date" type="date" class="input-with-feedback form-control" placeholder="dd-mm-yyyy">'; 
		html += '		</div>';
		html += '	</div>';
        

		// End date
		html += '	<div style="position: relative; top: 40px; left: 0px; width:400px;">';
		html += '       <input type="checkbox" id="update_sales_cb_end_date" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="update_sales_end_date_label" style="position: absolute; top: 6px; left: 30px;">' + __("End date") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 110px; width: 140px; height: 25px;"> ';
		html += '			<input id="update_sales_end_date" type="date" class="input-with-feedback form-control" placeholder="dd-mm-yyyy">'; 
		html += '			<input type="checkbox" id="update_sales_cb_clear_end_date" style="position: absolute; top: 8px; left: 145px;">';
        html += '		    <label id="update_sales_clear_end_date_label" style="position: absolute; top: 6px; left: 165px;">' + __("Clear") + '</label>';        		
		html += '		</div>';
		html += '	</div>';



		// Published price
		html += '	<div style="position: relative; top: 2px; left: 350px; width:400px;">';
		html += '       <input type="checkbox" id="update_sales_cb_published_price" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="update_sales_published_price_label" style="position: absolute; top: 6px; left: 30px;">' + __("Published price") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 140px; width: 140px; height: 25px;"> ';
		html += '			<input type="checkbox" id="update_sales_published_price" style="position: absolute; top: 6px; left: 0px;">';
		html += '		</div>';
		html += '	</div>';

		// Web Published
		html += '	<div style="position: relative; top: 42px; left: 350px; width:400px;">';
		html += '       <input type="checkbox" id="update_sales_cb_web_published" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="update_sales_web_published_label" style="position: absolute; top: 6px; left: 30px;">' + __("Web published") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 140px; width: 140px; height: 25px;"> ';
		html += '			<input type="checkbox" id="update_sales_web_published" style="position: absolute; top: 6px; left: 0px;">';
		html += '		</div>';
		html += '	</div>';


		// With me
		html += '	<div style="position: relative; top: 0px; left: 548px; width:400px;">';
		html += '       <input type="checkbox" id="update_sales_cb_with_me" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="update_sales_with_me_label" style="position: absolute; top: 6px; left: 30px;">' + __("With me") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 140px; width: 140px; height: 25px;"> ';
		html += '			<select id="update_sales_with_me_value" class="input-with-feedback form-control bold"> ';
		html += '				<option value=""></option> ';

		method = 'silicon_ioi.ioi_items.doctype.ioi_sales_price.ioi_sales_price.ioi_sales_prices_get_withme'
		frappe.call({  	method: method, 
						args: {},
						async: false,
						callback:function(r)	{ 
													for (var i = 0; i < r.message.length; i++)
													{
														html += '		<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
													}
												}
		});


		html += '			</select> ';
		html += '			<input type="checkbox" id="update_sales_cb_clear_with_me" style="position: absolute; top: 8px; left: 150px;">';		
		html += '		    <label id="update_sales_clear_with_me_label" style="position: absolute; top: 6px; left: 170px;">' + __("Clear") + '</label>';        				

		html += '		</div>';
		html += '	</div>';

        html += '</div>';


		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>';


		html += '<div style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '	<label style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Price calculation rule") + '</b></label>';			
		html += '</div>';


		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>';

		html += '<div style="overflow: auto; overflow-x: auto; height:280px;">';	
		
		// Increase Unit Price
		html += '	<div style="position: relative; top: 0px; left: 0px; width:400px;">';
		html += '       <input type="checkbox" id="update_sales_cb_increase" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="update_sales_increase_unit_price_label" style="position: absolute; top: 6px; left: 30px;">' + __("Increasing unit price of") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 200px; width: 100px; height: 25px;"> ';
		html += '			<input id="update_sales_increase_unit_price" type="number" class="input-with-feedback form-control bold">';
		html += '			<label id="update_sales_increase_unit_price_percent" style="position: absolute; top: 6px; left: 110px;">%</label>';		
		html += '		</div>';
		html += '	</div>';
		
		// Update Fixed Price
		html += '	<div style="position: relative; top: 40px; left: 0px; width:400px;">';
		html += '       <input type="checkbox" id="update_sales_cb_fixed_price" style="position: absolute; top: 8px; left: 2px;">';		
		html += '		<label id="update_sales_update_fixed_price_label" style="position: absolute; top: 6px; left: 30px;">' + __("Update fixed price") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 200px; width: 100px; height: 25px;"> ';
		html += '			<input id="update_sales_update_fixed_price" type="number" class="input-with-feedback form-control bold">';
		html += '		</div>';
		html += '	</div>';

		// Update Base discount
		html += '	<div style="position: relative; top: 0px; left: 350px; width:400px;">';
		html += '       <input type="checkbox" id="update_sales_cb_base_discount" style="position: absolute; top: 8px; left: 2px;">';		
		html += '		<label id="update_sales_update_base_discount_label" style="position: absolute; top: 6px; left: 30px;">' + __("Update base discount") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 200px; width: 100px; height: 25px;"> ';
		html += '			<input id="update_sales_update_base_discount" type="number" class="input-with-feedback form-control bold">';
		html += '			<label id="update_sales_update_base_discount_percent" style="position: absolute; top: 6px; left: 110px;">%</label>';		
		html += '		</div>';
		html += '	</div>';

		// Update Extra discount
		html += '	<div style="position: relative; top: 40px; left: 350px; width:400px;">';
		html += '       <input type="checkbox" id="update_sales_cb_extra_discount" style="position: absolute; top: 8px; left: 2px;">';		
		html += '		<label id="update_sales_update_extra_discount_label" style="position: absolute; top: 6px; left: 30px;">' + __("Update extra discount") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 200px; width: 100px; height: 25px;"> ';
		html += '			<input id="update_sales_update_extra_discount" type="number" class="input-with-feedback form-control bold">';
		html += '			<label id="update_sales_update_extra_discount_percent" style="position: absolute; top: 6px; left: 110px;">%</label>';		
		html += '		</div>';
		html += '	</div>';


		// Increase Recommended Unit Price
		html += '	<div style="position: relative; top: 80px; left: 0px; width:400px;">';
		html += '       <input type="checkbox" id="update_sales_cb_increase_recommended_unit_price" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="update_sales_increase_recommended_unit_price_label" style="position: absolute; top: 6px; left: 30px;">' + __("Increasing rec. price of") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 200px; width: 100px; height: 25px;"> ';
		html += '			<input id="update_sales_increase_recommended_unit_price" type="number" class="input-with-feedback form-control bold">';
		html += '			<label id="update_sales_increase_recommended_unit_price_percent" style="position: absolute; top: 6px; left: 110px;">%</label>';		
		html += '		</div>';
		html += '	</div>';


		// Update Recommended Base Discount
		html += '	<div style="position: relative; top: 120px; left: 0px; width:400px;">';
		html += '       <input type="checkbox" id="update_sales_cb_recommended_base_discount" style="position: absolute; top: 8px; left: 2px;">';		
		html += '		<label id="update_sales_update_recommended_base_discount_label" style="position: absolute; top: 6px; left: 30px;">' + __("Update rec. base disc.") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 200px; width: 100px; height: 25px;"> ';
		html += '			<input id="update_sales_update_recommended_base_discount" type="number" class="input-with-feedback form-control bold">';
		html += '			<label id="update_sales_update_recommended_base_discount_percent" style="position: absolute; top: 6px; left: 110px;">%</label>';		
		html += '		</div>';
		html += '	</div>';


		// Update Recommended Margin
		html += '	<div style="position: relative; top: 80px; left: 350px; width:400px;">';
		html += '       <input type="checkbox" id="update_sales_cb_recommended_margin" style="position: absolute; top: 8px; left: 2px;">';		
		html += '		<label id="update_sales_update_recommended_margin_label" style="position: absolute; top: 6px; left: 30px;">' + __("Update rec. margin") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 200px; width: 100px; height: 25px;"> ';
		html += '			<input id="update_sales_update_recommended_margin" type="number" class="input-with-feedback form-control bold">';
		html += '			<label id="update_sales_update_recommended_margin_percent" style="position: absolute; top: 6px; left: 110px;">%</label>';		
		html += '		</div>';
		html += '	</div>';


		// Update Margin mode
		html += '	<div style="position: relative; top: 120px; left: 350px; width:400px;">';
		html += '       <input type="checkbox" id="update_sales_cb_margin_mode" style="position: absolute; top: 8px; left: 2px;">';		
		html += '		<label id="update_sales_update_margin_mode_label" style="position: absolute; top: 6px; left: 30px;">' + __("Update margin mode") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 200px; width: 125px; height: 25px;"> ';
		html += '			<select id="update_sales_update_margin_mode" class="input-with-feedback form-control bold"> ';
		html += '				<option value="0" selected>' + __('Regular') + '</option> ';		
		html += '				<option value="1">' + __('Sales based') + '</option> ';				
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		// Update Specific VAT rate
		html += '	<div style="position: relative; top: 160px; left: 0px; width:400px;">';
		html += '       <input type="checkbox" id="update_sales_cb_specific_vat_rate" style="position: absolute; top: 8px; left: 2px;">';		
		html += '		<label id="update_sales_update_specific_vat_rate_label" style="position: absolute; top: 6px; left: 30px;">' + __("Update VAT rate") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 200px; width: 100px; height: 25px;"> ';
		html += '			<input id="update_sales_update_specific_vat_rate" type="number" class="input-with-feedback form-control bold">';
		html += '			<label id="update_sales_update_specific_vat_rate_percent" style="position: absolute; top: 6px; left: 110px;">%</label>';		
		html += '		</div>';
		html += '	</div>';



		// Rounding kind
		html += '	<div style="position: relative; top: 200px; left: 0px; width:400px;">';
		html += '		<label id="update_sales_update_rounding_kind_label" style="position: absolute; top: 6px; left: 2px;">' + __("Rounding") + '</label>';

		html += '		<div class="control-input" style="position: absolute; top: 30px; left: 2px; width: 190px; height: 25px;"> ';
		html += '			<select id="update_sales_update_rounding_kind" class="input-with-feedback form-control bold"> ';
		html += '				<option value="0" selected>' + __('Decimals') + '</option> ';		
		html += '				<option value="1">' + __('Multiple') + '</option> ';
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';


		// Rounding Method
		html += '	<div style="position: relative; top: 200px; left: 200px; width:400px;">';
		html += '		<label id="update_sales_update_rounding_method_label" style="position: absolute; top: 6px; left: 2px;">' + __("Method") + '</label>';		
		html += '		<div class="control-input" style="position: absolute; top: 30px; left: 2px; width: 140px; height: 25px;"> ';
		html += '			<select id="update_sales_update_rounding_method" class="input-with-feedback form-control bold"> ';
		html += '				<option value="0" selected>' + __('Nearest') + '</option> ';		
		html += '				<option value="1">' + __('Above') + '</option> ';
		html += '				<option value="2">' + __('Under') + '</option> ';
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		// Rounding value
		html += '	<div style="position: relative; top: 200px; left: 350px; width:400px;">';
		html += '		<label id="update_sales_update_rounding_value_label" style="position: absolute; top: 6px; left: 2px;">' + __("Decimals") + '</label>';				
		html += '		<div class="control-input" style="position: absolute; top: 30px; left: 2px; width: 100px; height: 25px;"> ';
		html += '			<input id="update_sales_update_rounding_value" type="number" class="input-with-feedback form-control bold" value="2">';
		html += '		</div>';
		html += '	</div>';



		// Button Clear values
		html += '	<div style="position: relative; top: 225px; left: 550px; width:100px;">';		
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 2px; width: 100px; height: 25px;"> ';
		html += '			<input id="update_sales_bt_clear_values" type="button" class="btn btn-default ellipsis" value="' + __("Clear")  + '" style="width:100px">';
		html += '		</div>';
		html += '	</div>';	

		// Warning
		html += '	<div style="position: relative; top: 200px; left: 550px; width:550px;">';
		html += '		<label id="update_sales_warning1" style="position: absolute; top: 0px; left: 2px;"><font color="red">' + __("Caution, risky and irreversible operation ! Make a copy before, please (ioi Sales Price) ") + '</font></label>';
		html += '	</div>';


		html += '	<div style="position: relative; top: 0px; left: 730px; width:420px;">';
		html += '		<label id="update_sales_create_new_cat_cond_label" style="position: absolute; top: 6px; left: 0px;">' + __("Create/Update selected prices in this catalog / condition / currency") + '</label>';
		html += '	</div>';

		// New catalog
		html += '	<div style="position: relative; top: 40px; left: 730px; width:400px;">';
		html += '       <input type="checkbox" id="update_sales_cb_new_catalog" style="position: absolute; top: 8px; left: 2px;">';		
		html += '		<label id="update_sales_new_catalog_label" style="position: absolute; top: 6px; left: 30px;">' + __("Catalog") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 100px; width: 120px; height: 25px;"> ';
		html += '			<select id="update_sales_new_catalog_type" class="input-with-feedback form-control bold"> ';
		html += '				<option value=""></option> ';		
		html += '				<option value="Catalog">' + __("Catalog") + '</option> ';
        html += '				<option value="Customer">' + __("Customer") + '</option> ';
		html += '			</select> ';
		html += '		</div>';

		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 230px; width: 170px; height: 25px;"> ';
		html += '			<select id="update_sales_new_catalog_catalog" class="input-with-feedback form-control bold" hidden > ';
		html += '				<option value=""></option> ';	

		method = 'silicon_ioi.ioi_items.doctype.ioi_sales_price.ioi_sales_price.ioi_sales_prices_get_sales_catalog'
		frappe.call({  	method: method, 
						args: {},
						async: false,
						callback:function(r)	{ 
													for (var i = 0; i < r.message.length; i++)
													{
														html += '		<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
													}
												}
		});

		html += '			</select> ';

		html += '		</div>';
	
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 230px; width: 170px; height: 25px;"> ';
		html += '			<input id="update_sales_new_catalog_customer" type="text" class="input-with-feedback form-control bold" value="" hidden>';
		html += '		</div>';
		
		
		html += '		<div style="position: absolute; top:2px; left: 410px; height: 30px">';
		html +='			<button id="update_sales_new_catalog_bt_customer" class="btn btn-default ellipsis" style="height: 30px; width: 50px;" hidden onclick="">' + __("...") + '</button>';
		html += '		</div>';

		html += '	</div>';


		// New sales condition
		html += '	<div style="position: relative; top: 80px; left: 730px; width:400px;">';
		html += '       <input type="checkbox" id="update_sales_cb_new_sales_condition" style="position: absolute; top: 8px; left: 2px;">';		
		html += '		<label id="update_sales_new_sales_condition_label" style="position: absolute; top: 6px; left: 30px;">' + __("Condition") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 100px; width: 170px; height: 25px;"> ';
		html += '			<select id="update_sales_new_sales_condition" class="input-with-feedback form-control bold"> ';
		html += '				<option value=""></option> ';		

		method = 'silicon_ioi.ioi_items.doctype.ioi_sales_price.ioi_sales_price.ioi_sales_prices_get_sales_condition'
		frappe.call({  	method: method, 
						args: {},
						async: false,
						callback:function(r)	{ 
													for (var i = 0; i < r.message.length; i++)
													{
														html += '		<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
													}
												}
		});

		html += '			</select> ';

		html += '		</div>';

		html += '	</div>';


	
		// Currency
		html += '	<div style="position: relative; top: 120px; left: 730px; width:400px;">';
		html += '       <input type="checkbox" id="update_sales_cb_convert" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="update_sales_new_currency_label" style="position: absolute; top: 6px; left: 30px;">' + __("Currency") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 100px; width: 100px; height: 25px;"> ';
		html += '			<input id="update_sales_new_currency" type="text" class="input-with-feedback form-control bold" ';
		html += '				   onkeyup="';

		html += '						var start = this.selectionStart; ';
		html += '						var end = this.selectionEnd; ';
		html += '						this.value = this.value.toUpperCase(); ';
		html += '						this.setSelectionRange(start, end); ';
		
		html += '			">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 4px; left: 210px; width: 130px; height: 25px;"> ';		
		html +='			<button id="update_sales_new_currency_select_currency_button" style="height: 30px; width: 30px;border: none; outline:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';

		html += '</div>'


		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'


		html += '<div style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '	<label style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Operations") + '</b></label>';			
		html += '</div>';



		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'

		html += '<div style="overflow: auto; overflow-x: auto; height:55px;">';	

		html += '	<div style="position: relative; top: 0px; left: 2px; width:500px;">';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 0px; width: 200px; height: 25px;"> ';
		html += '			<input id="update_sales_bt_update_price" type="button" class="btn btn-default ellipsis" value="' + __("Update selected prices")  + '" style="width:200px">';
		html += '		</div>';

		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 210px; width: 150px; height: 25px;"> ';
		html += '			<input id="update_sales_set_enabled" type="button" class="btn btn-default ellipsis" value="' + __("Set enabled")  + '" style="width:150px">';
		html += '		</div>';

		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 370px; width: 150px; height: 25px;"> ';
		html += '			<input id="update_sales_set_disabled" type="button" class="btn btn-default ellipsis" value="' + __("Set disabled")  + '" style="width:150px">';
		html += '		</div>';

		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 530px; width: 200px; height: 25px;"> ';
		html += '			<input id="update_sales_delete_disabled" type="button" class="btn btn-default ellipsis" value="' + __("Delete selected disabled")  + '" style="width:200px">';
		html += '		</div>';

		html += '	</div>';

		html += '</div>'

		cur_frm.fields_dict[html_field].$wrapper.empty();
		cur_frm.fields_dict[html_field].$wrapper.append(html);


		silicon_ioi.doctype.ioiPriceUpdatingTool.sleep_static(250).then(() => {
			
			let fct_keydown = function(event) { 
				silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_sales_search_key_down(event); 

				if (event.keyCode == 13) 
				{	return false;
				}

			
			};

            
			document.getElementById("update_sales_catalog_customer_id").onkeydown = fct_keydown;


			let fct_select_catalog_customer = function() {

				if ((document.getElementById("update_sales_doc_type_id").selectedIndex == 0) || (document.getElementById("update_sales_doc_type_id").selectedIndex == 2)) {

					let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email, alternate id';
					let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400, 250';
					let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email, Alternate id';
					let where = 'ioistatus = 2';
					let order_by = 'name asc';
				  
					let fct_callback = function (return_value) {
		
						document.getElementById("update_sales_catalog_customer_id").value = return_value;
						document.getElementById('update_sales_catalog_customer_id').focus();
					}
		
					silicon_ioi.ioiCommon.select_customer(fields, fields_len, fields_desc, where, order_by, fct_callback);
				}else {
					let title = __("Select a catalog");
					let form_width_pixel = '580px';
					let table = "ioi Sales Catalog";
					let fields = 'name, description';
					let fields_len = '200, 300';
					let fields_desc = 'Identification, Description';
					let where = 'enabled = 1';
					let order_by = 'name asc';
				  
					let fct_callback = function (return_value) {
		
						document.getElementById("update_sales_catalog_customer_id").value = return_value;
						document.getElementById('update_sales_catalog_customer_id').focus();
					}
		
					silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);


				}
			}


			document.getElementById("update_sales_select_customer_catalog_button").onclick = fct_select_catalog_customer;

			document.getElementById("update_sales_item_id").onkeydown = fct_keydown;



			let fct_bt_item_click = function () {

			
				let fields = 'a.name, a.description, a.unit_id, b.q_stock, b.q_reserved, a.manufacturer_ref';
				let fields_len = '300, 500, 125, 150, 150, 300';
				let fields_desc = 'Identification, Description, Unit, Stock qty, Reserved qty, Manufacturer Catalog';
				let where = 'ioistatus = 2';
				let order_by = 'a.name asc';
				let parameter_with_stock = false;
				let site_id = '';
		
				let fct_callback = function (return_value) {

					document.getElementById('update_sales_item_id').value = return_value;					
					document.getElementById('update_sales_item_id').focus();					
				}
		
				silicon_ioi.ioiCommon.select_item(fields, fields_len, fields_desc, where, order_by, parameter_with_stock, site_id, fct_callback);
			}


			document.getElementById('update_sales_select_item_button').onclick = fct_bt_item_click;			


			document.getElementById("update_sales_currency_id").onkeydown = fct_keydown;


			let fct_bt_currency_click = function () {

				let title = __("Select a currency");
				let form_width_pixel = '580px';
				let table = "ioi Currency";
				let fields = 'name, description';
				let fields_len = '200, 300';
				let fields_desc = 'Identification, Description';
				let where = 'enabled = 1';
				let order_by = 'name asc';
				
				let fct_callback = function (return_value) {
	
					document.getElementById("update_sales_currency_id").value = return_value;
					document.getElementById('update_sales_currency_id').focus();
				}
	
				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

			}

			document.getElementById('update_sales_select_currency_button').onclick = fct_bt_currency_click;			



			let fct_change = function() { silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_sales_search_on_change(); };

            document.getElementById("update_sales_doc_type_id").onchange = fct_change;
			document.getElementById("update_sales_sales_condition_id").onchange = fct_change;
			document.getElementById("update_sales_unit_id").onchange = fct_change;

			document.getElementById("update_sales_enabled").onchange = fct_change;

			silicon_ioi.doctype.ioiPriceUpdatingTool.update_sales_fill_item_family();

			let fct_change_family = function() { 
				
				silicon_ioi.doctype.ioiPriceUpdatingTool.update_sales_fill_item_type('CHANGE');
				silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_sales_search_on_change(); 
			};

			document.getElementById("update_sales_family_id").onchange = fct_change_family;

			let fct_change_type = function() { 
				
				silicon_ioi.doctype.ioiPriceUpdatingTool.update_sales_fill_item_group('CHANGE');
				silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_sales_search_on_change(); 
			};

			document.getElementById("update_sales_type_id").onchange = fct_change_type;


			let fct_change_group = function() { 
				
				silicon_ioi.doctype.ioiPriceUpdatingTool.update_sales_fill_item_subgroup('CHANGE')
				silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_sales_search_on_change(); 
			};

			document.getElementById("update_sales_group_id").onchange = fct_change_group;


			let fct_change_subgroup = function() { 
				
				silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_sales_search_on_change(); 
			};

			document.getElementById("update_sales_subgroup_id").onchange = fct_change_subgroup;



			document.getElementById("update_sales_classification_id").onchange = fct_change;
			document.getElementById("update_sales_shop_category_id").onchange = fct_change;
            document.getElementById("update_sales_withme_id").onchange = fct_change;


			let fct_click = function () { silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_sales_refresh(); };
			let fct_clear = function () { silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_sales_clear(); };
	
	
			document.getElementById('update_sales_search').onclick = fct_click;
			document.getElementById('update_sales_clear').onclick = fct_clear;
	
	
			let fct_col_over = function ()  { silicon_ioi.doctype.ioiPriceUpdatingTool.col_mouse_over(this); };
			let fct_col_leave = function () { silicon_ioi.doctype.ioiPriceUpdatingTool.col_mouse_leave(this) }; 
			let fct_col_click = function () { 
			
				let selected_lines = [];

				for (var i = 0; i < document.getElementById('update_sales_detail').rows.length; i++) {	
					
					if (document.getElementById('update_sales_checked_id_' + i.toString())) {
						if (document.getElementById('update_sales_checked_id_' + i.toString()).checked)	{

							selected_lines[selected_lines.length] = document.getElementById('update_sales_detail_name_' + i.toString()).value;
						}
					}
				}

				silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_sales_col_click(this); 

				silicon_ioi.doctype.ioiPriceUpdatingTool.sleep_static(100).then(() => {

					for (var i = 0; i < document.getElementById('update_sales_detail').rows.length; i++) {	

						if (document.getElementById('update_sales_checked_id_' + i.toString())) {						
							document.getElementById('update_sales_checked_id_' + i.toString()).checked = false;

							if (selected_lines.indexOf(document.getElementById('update_sales_detail_name_' + i.toString()).value) != -1)
							{	
								document.getElementById('update_sales_checked_id_' + i.toString()).checked = true;
							}
						}
					}

					

					if (document.getElementById("update_sales_grid_banner"))
					{	
						if (selected_lines.length <= 1) {
							document.getElementById("update_sales_grid_banner").innerHTML = '<b>' + selected_lines.length.toString() + '</b> ' + __("selected sales price (prices updated by contracts have been excluded)")
						}else {
							document.getElementById("update_sales_grid_banner").innerHTML = '<b>' + selected_lines.length.toString() + '</b> ' + __("selected sales prices (prices updated by contracts have been excluded)")
						}
					}
			

				});
			};			
	

            
            document.getElementById('update_sales_col_select_type').onclick = fct_col_click; 
			document.getElementById('update_sales_col_catalog_customer_id').onclick = fct_col_click; 
			document.getElementById('update_sales_col_sales_condition_id').onclick = fct_col_click;
			document.getElementById('update_sales_col_item_id').onclick = fct_col_click;
			document.getElementById('update_sales_col_unit_id').onclick = fct_col_click;
			document.getElementById('update_sales_col_currency_id').onclick = fct_col_click;
			document.getElementById('update_sales_col_unit_price').onclick = fct_col_click;
			document.getElementById('update_sales_col_fixed_price').onclick = fct_col_click;
			document.getElementById('update_sales_col_base_discount').onclick = fct_col_click;
			document.getElementById('update_sales_col_extra_discount').onclick = fct_col_click;
			document.getElementById('update_sales_col_enabled').onclick = fct_col_click;
            document.getElementById('update_sales_col_start_date').onclick = fct_col_click;
            document.getElementById('update_sales_col_end_date').onclick = fct_col_click;
            document.getElementById('update_sales_col_price_published').onclick = fct_col_click;
            document.getElementById('update_sales_col_web_price_published').onclick = fct_col_click;
            document.getElementById('update_sales_col_item_with_me').onclick = fct_col_click;


            document.getElementById('update_sales_col_select_type').onmouseover = fct_col_over;
			document.getElementById('update_sales_col_catalog_customer_id').onmouseover = fct_col_over;
			document.getElementById('update_sales_col_sales_condition_id').onmouseover = fct_col_over;
			document.getElementById('update_sales_col_item_id').onmouseover = fct_col_over;
			document.getElementById('update_sales_col_unit_id').onmouseover = fct_col_over;
			document.getElementById('update_sales_col_currency_id').onmouseover = fct_col_over;
			document.getElementById('update_sales_col_unit_price').onmouseover = fct_col_over;
			document.getElementById('update_sales_col_fixed_price').onmouseover = fct_col_over;
			document.getElementById('update_sales_col_base_discount').onmouseover = fct_col_over;
			document.getElementById('update_sales_col_extra_discount').onmouseover = fct_col_over;
			document.getElementById('update_sales_col_enabled').onmouseover = fct_col_over;
            document.getElementById('update_sales_col_start_date').onmouseover = fct_col_over;
            document.getElementById('update_sales_col_end_date').onmouseover = fct_col_over;
            document.getElementById('update_sales_col_price_published').onmouseover = fct_col_over;
            document.getElementById('update_sales_col_web_price_published').onmouseover = fct_col_over;
            document.getElementById('update_sales_col_item_with_me').onmouseover = fct_col_over;


            document.getElementById('update_sales_col_select_type').onmouseleave = fct_col_leave;
			document.getElementById('update_sales_col_catalog_customer_id').onmouseleave = fct_col_leave;
			document.getElementById('update_sales_col_sales_condition_id').onmouseleave = fct_col_leave;
			document.getElementById('update_sales_col_item_id').onmouseleave = fct_col_leave;
			document.getElementById('update_sales_col_unit_id').onmouseleave = fct_col_leave;
			document.getElementById('update_sales_col_currency_id').onmouseleave = fct_col_leave;
			document.getElementById('update_sales_col_unit_price').onmouseleave = fct_col_leave;
			document.getElementById('update_sales_col_fixed_price').onmouseleave = fct_col_leave;
			document.getElementById('update_sales_col_base_discount').onmouseleave = fct_col_leave;
			document.getElementById('update_sales_col_extra_discount').onmouseleave = fct_col_leave;
			document.getElementById('update_sales_col_enabled').onmouseleave = fct_col_leave;
            document.getElementById('update_sales_col_start_date').onmouseleave = fct_col_leave;
            document.getElementById('update_sales_col_end_date').onmouseleave = fct_col_leave;
            document.getElementById('update_sales_col_price_published').onmouseleave = fct_col_leave;
            document.getElementById('update_sales_col_web_price_published').onmouseleave = fct_col_leave;
            document.getElementById('update_sales_col_item_with_me').onmouseleave = fct_col_leave;


			let fct_return_false = function(event) {
			
				if (event.keyCode == 13) 
				{	return false;
				}
		
			}


			document.getElementById('update_sales_cb_start_date').onkeydown = fct_return_false;





			let fct_check_cb_start_date = function(event) {
				document.getElementById('update_sales_cb_start_date').checked = true;

				if (event.keyCode == 13) 
				{	return false;
				}

			}

			document.getElementById("update_sales_start_date").onkeydown = fct_check_cb_start_date;
			document.getElementById("update_sales_start_date").onchange = fct_check_cb_start_date;



			document.getElementById('update_sales_cb_clear_end_date').onkeydown = fct_return_false;


			let fct_check_cb_end_date = function(event) {
				document.getElementById('update_sales_cb_end_date').checked = true;
                document.getElementById('update_sales_cb_clear_end_date').checked = false;

				if (event.keyCode == 13) 
				{	return false;
				}

                
			}

			document.getElementById("update_sales_end_date").onkeydown = fct_check_cb_end_date;
			document.getElementById("update_sales_end_date").onchange = fct_check_cb_end_date;


			document.getElementById('update_sales_cb_end_date').onkeydown = fct_return_false;

			let fct_cb_end = function() {
				document.getElementById('update_sales_cb_clear_end_date').checked = false;
			}


            document.getElementById('update_sales_cb_end_date').onclick = fct_cb_end;            


			let fct_cb_clear_end = function() {
				document.getElementById('update_sales_cb_end_date').checked = false;
			}


            document.getElementById('update_sales_cb_clear_end_date').onclick = fct_cb_clear_end; 
			document.getElementById('update_sales_cb_clear_end_date').onkeydown = fct_return_false;           


			document.getElementById('update_sales_cb_published_price').onkeydown = fct_return_false;

			let fct_cb_published_price = function() {
				document.getElementById('update_sales_cb_published_price').checked = true;
			}

            document.getElementById('update_sales_published_price').onclick = fct_cb_published_price;
			document.getElementById('update_sales_published_price').onkeydown = fct_return_false;              


			document.getElementById('update_sales_cb_web_published').onkeydown = fct_return_false;

			let fct_cb_web_published = function() {
				document.getElementById('update_sales_cb_web_published').checked = true;
			}

            document.getElementById('update_sales_web_published').onclick = fct_cb_web_published;  
			document.getElementById('update_sales_web_published').onkeydown = fct_return_false;  

			document.getElementById('update_sales_cb_clear_with_me').onkeydown = fct_return_false;			

			let fct_cb_withme_click = function() {
				document.getElementById('update_sales_cb_clear_with_me').checked = false;
			}

			document.getElementById('update_sales_cb_with_me').onclick = fct_cb_withme_click;    						

			let fct_cb_withme = function() {
				document.getElementById('update_sales_cb_with_me').checked = true;
				document.getElementById('update_sales_cb_clear_with_me').checked = false;
			}

			document.getElementById('update_sales_with_me_value').onchange = fct_cb_withme;    			


			document.getElementById('update_sales_cb_with_me').onkeydown = fct_return_false;			

			let fct_cb_clear_withme_click = function() {
				document.getElementById('update_sales_cb_with_me').checked = false;
			}

			document.getElementById('update_sales_cb_clear_with_me').onclick = fct_cb_clear_withme_click;    						



			let fct_cb_clear_withme = function() {
				document.getElementById('update_sales_cb_with_me').checked = false;
			}


            document.getElementById('update_sales_cb_clear_with_me').onclick = fct_cb_clear_withme;            

			


			document.getElementById('update_sales_cb_increase').onkeydown = fct_return_false;			


			let fct_check_cb_unit_price = function(event) {
				document.getElementById('update_sales_cb_increase').checked = true;

				if (event.keyCode == 13) {
					return false;
				}
			}

			document.getElementById("update_sales_increase_unit_price").onkeydown = fct_check_cb_unit_price;


			document.getElementById('update_sales_cb_fixed_price').onkeydown = fct_return_false;			

			let fct_check_cb_fixed_price = function(event) {
				document.getElementById('update_sales_cb_fixed_price').checked = true;

				if (event.keyCode == 13) {
					return false;
				}

			}

			document.getElementById("update_sales_update_fixed_price").onkeydown = fct_check_cb_fixed_price;


			document.getElementById('update_sales_cb_base_discount').onkeydown = fct_return_false;			
			

			let fct_check_cb_base_discount = function(event) {
				document.getElementById('update_sales_cb_base_discount').checked = true;

				if (event.keyCode == 13) {
					return false;
				}

			}

			document.getElementById("update_sales_update_base_discount").onkeydown = fct_check_cb_base_discount;

			document.getElementById('update_sales_cb_extra_discount').onkeydown = fct_return_false;						

			let fct_check_cb_extra_discount = function(event) {
				document.getElementById('update_sales_cb_extra_discount').checked = true;

				if (event.keyCode == 13) {
					return false;
				}

			}

			document.getElementById("update_sales_update_extra_discount").onkeydown = fct_check_cb_extra_discount;

			document.getElementById('update_sales_cb_increase_recommended_unit_price').onkeydown = fct_return_false;			

			let fct_check_cb_recommended_unit_price = function(event) {
				document.getElementById('update_sales_cb_increase_recommended_unit_price').checked = true;

				if (event.keyCode == 13) {
					return false;
				}

			}

			document.getElementById("update_sales_increase_recommended_unit_price").onkeydown = fct_check_cb_recommended_unit_price;


			document.getElementById('update_sales_cb_recommended_base_discount').onkeydown = fct_return_false;						

			let fct_check_cb_recommended_base_discount = function(event) {
				document.getElementById('update_sales_cb_recommended_base_discount').checked = true;

				if (event.keyCode == 13) {
					return false;
				}

			}

			document.getElementById("update_sales_update_recommended_base_discount").onkeydown = fct_check_cb_recommended_base_discount;

			document.getElementById('update_sales_cb_recommended_margin').onkeydown = fct_return_false;									

			let fct_check_cb_recommended_margin = function(event) {
				document.getElementById('update_sales_cb_recommended_margin').checked = true;

				if (event.keyCode == 13) {
					return false;
				}

			}

			document.getElementById("update_sales_update_recommended_margin").onkeydown = fct_check_cb_recommended_margin;


			document.getElementById('update_sales_cb_margin_mode').onkeydown = fct_return_false;									

			let fct_check_cb_margin_mode = function(event) {
				document.getElementById('update_sales_cb_margin_mode').checked = true;

				if (event.keyCode == 13) {
					return false;
				}

			}

			document.getElementById("update_sales_update_margin_mode").onchange= fct_check_cb_margin_mode;
			document.getElementById("update_sales_update_margin_mode").onkeydown= fct_check_cb_margin_mode;


			document.getElementById('update_sales_cb_specific_vat_rate').onkeydown = fct_return_false;									

			let fct_check_cb_specific_vat_rate = function(event) {
				document.getElementById('update_sales_cb_specific_vat_rate').checked = true;

				if (event.keyCode == 13) {
					return false;
				}

			}

			document.getElementById("update_sales_update_specific_vat_rate").onkeydown = fct_check_cb_specific_vat_rate;




			document.getElementById('update_sales_update_rounding_kind').onkeydown = fct_return_false;

			let fct_rounding_kind_change = function() {

				if (document.getElementById('update_sales_update_rounding_kind').value == 0) {

					document.getElementById('update_sales_update_rounding_value_label').innerText = __('Decimals');
					document.getElementById('update_sales_update_rounding_value').value = '2';
					
				}else{
					document.getElementById('update_sales_update_rounding_value_label').innerText = __('Multiple');
					document.getElementById('update_sales_update_rounding_value').value = '0.05';
				}

			};

			document.getElementById('update_sales_update_rounding_kind').onchange = fct_rounding_kind_change;

			document.getElementById('update_sales_update_rounding_method').onkeydown = fct_return_false;	
			document.getElementById('update_sales_update_rounding_value').onkeydown = fct_return_false;	




			document.getElementById('update_sales_cb_new_catalog').onkeydown = fct_return_false;

			let fct_catalog_type = function(event) {

				if (document.getElementById('update_sales_new_catalog_type').value == '') {

					document.getElementById('update_sales_cb_new_catalog').checked = false;

					document.getElementById('update_sales_new_catalog_catalog').hidden = true;
					document.getElementById('update_sales_new_catalog_customer').hidden = true;
					document.getElementById('update_sales_new_catalog_bt_customer').hidden = true;

					document.getElementById('update_sales_new_catalog_catalog').selectedIndex = 0;
					document.getElementById('update_sales_new_catalog_customer').value = '';
			
				}else {

					document.getElementById('update_sales_cb_new_catalog').checked = true;

					if (document.getElementById('update_sales_new_catalog_type').value == 'Catalog') {

						document.getElementById('update_sales_new_catalog_catalog').hidden = false;
						document.getElementById('update_sales_new_catalog_customer').hidden = true;
						document.getElementById('update_sales_new_catalog_bt_customer').hidden = true;

						document.getElementById('update_sales_new_catalog_customer').value = '';

						document.getElementById("update_sales_new_catalog_catalog").style.zIndex = "10";
						document.getElementById("update_sales_new_catalog_customer").style.zIndex = "5";

					}else {

						document.getElementById('update_sales_new_catalog_catalog').hidden = true;
						document.getElementById('update_sales_new_catalog_customer').hidden = false;
						document.getElementById('update_sales_new_catalog_bt_customer').hidden = false;

						document.getElementById('update_sales_new_catalog_catalog').selectedIndex = 0;

						document.getElementById("update_sales_new_catalog_customer").style.zIndex = "10";
						document.getElementById("update_sales_new_catalog_catalog").style.zIndex = "5";
					}
				}

				if (event.keyCode == 13) {
					return false;
				}

			}

			document.getElementById('update_sales_new_catalog_type').onchange = fct_catalog_type;
			document.getElementById('update_sales_new_catalog_type').onkeydown = fct_catalog_type;


			let fct_bt_customer_click = function () {

				let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email, alternate_id';
				let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400, 250';
				let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email, Alternate id';
				let where = 'ioistatus = 2';
				let order_by = 'name asc';
			  
				let fct_callback = function (return_value) {
	
					document.getElementById('update_sales_new_catalog_customer').value = return_value;					
				}
	
				silicon_ioi.ioiCommon.select_customer(fields, fields_len, fields_desc, where, order_by, fct_callback);
	
			}

			document.getElementById('update_sales_new_catalog_customer').onkeydown = fct_return_false;

			document.getElementById('update_sales_new_catalog_bt_customer').onclick = fct_bt_customer_click;


			let fct_check_cb_sales_condition = function(event) {
				document.getElementById('update_sales_cb_new_sales_condition').checked = true;

				if (event.keyCode == 13) {
					return false;
				}

			}

			document.getElementById('update_sales_cb_new_sales_condition').onkeydown = fct_return_false;			
			
			document.getElementById("update_sales_new_sales_condition").onchange = fct_check_cb_sales_condition;
			document.getElementById("update_sales_new_sales_condition").onkeydown = fct_check_cb_sales_condition;


			
			document.getElementById("update_sales_cb_convert").onkeydown = fct_return_false;


			let fct_check_cb_convert = function(event) {
				document.getElementById('update_sales_cb_convert').checked = true;

				if (event.keyCode == 13) {
					return false;
				}

			}

			
			document.getElementById("update_sales_new_currency").onkeydown = fct_check_cb_convert;


			let fct_bt_new_currency_click = function () {

				let title = __("Select a currency");
				let form_width_pixel = '580px';
				let table = "ioi Currency";
				let fields = 'name, description';
				let fields_len = '200, 300';
				let fields_desc = 'Identification, Description';
				let where = 'enabled = 1';
				let order_by = 'name asc';
				
				let fct_callback = function (return_value) {
					document.getElementById('update_sales_cb_convert').checked = true;
					document.getElementById("update_sales_new_currency").value = return_value;
					document.getElementById('update_sales_new_currency').focus();
				}
	
				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

			}

			document.getElementById('update_sales_new_currency_select_currency_button').onclick = fct_bt_new_currency_click;			

	
			let fct_clear_values = function () { silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_sales_clear_values(); };			

			document.getElementById('update_sales_bt_clear_values').onclick = fct_clear_values;


			let fct_update = function () { silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_sales_update_price(); };			
			let fct_set_enabled = function () { silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_sales_set_enabled(); };
			let fct_set_disabled = function () { silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_sales_set_disabled(); };
			let fct_delete_selected_disabled = function () { silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_sales_delete_selected_disabled(); };
	
			document.getElementById('update_sales_bt_update_price').onclick = fct_update;
			document.getElementById('update_sales_set_enabled').onclick = fct_set_enabled;
			document.getElementById('update_sales_set_disabled').onclick = fct_set_disabled;

			document.getElementById('update_sales_delete_disabled').onclick = fct_delete_selected_disabled;

		});
	}



	static do_update_sales_clear()
	{
		
        document.getElementById("update_sales_doc_type_id").selectedIndex = 0;
		document.getElementById('update_sales_catalog_customer_id').value = '';
		document.getElementById("update_sales_sales_condition_id").selectedIndex = 0;
		document.getElementById('update_sales_item_id').value = '';
		document.getElementById("update_sales_unit_id").selectedIndex = 0;
		document.getElementById('update_sales_currency_id').value = '';

		document.getElementById("update_sales_enabled").selectedIndex = 0;

		document.getElementById("update_sales_family_id").selectedIndex = 0;

		for (var i = document.getElementById('update_sales_type_id').options.length-1; i >= 0; i--) {
			document.getElementById('update_sales_type_id').options[i].remove();	
		}

		for (var i = document.getElementById('update_sales_group_id').options.length-1; i >= 0; i--) {
			document.getElementById('update_sales_group_id').options[i].remove();	
		}
		
		for (var i = document.getElementById('update_sales_subgroup_id').options.length-1; i >= 0; i--) {
			document.getElementById('update_sales_subgroup_id').options[i].remove();	
		}

		silicon_ioi.doctype.ioiPriceUpdatingTool.item_type = [];
		silicon_ioi.doctype.ioiPriceUpdatingTool.item_type[0] = ['', ''];
		document.getElementById('update_sales_type_id').options[document.getElementById('update_sales_type_id').options.length] = new Option('', '');												


		silicon_ioi.doctype.ioiPriceUpdatingTool.item_group = [];
		silicon_ioi.doctype.ioiPriceUpdatingTool.item_group[0] = ['', ''];
		document.getElementById('update_sales_group_id').options[document.getElementById('update_sales_group_id').options.length] = new Option('', '');												

		silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup = [];
		silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[0] = ['', ''];			
		document.getElementById('update_sales_subgroup_id').options[document.getElementById('update_sales_subgroup_id').options.length] = new Option('', '');												

		document.getElementById("update_sales_type_id").selectedIndex = 0;				
		document.getElementById("update_sales_group_id").selectedIndex = 0;
		document.getElementById("update_sales_subgroup_id").selectedIndex = 0;				
		document.getElementById("update_sales_classification_id").selectedIndex = 0;
		document.getElementById("update_sales_shop_category_id").selectedIndex = 0;				
        document.getElementById("update_sales_withme_id").selectedIndex = 0;
	

	
		if (document.getElementById('update_sales_detail'))	{
			document.getElementById('update_sales_detail').remove();
		}
		
		let html = '';

		html += '<table id="update_sales_detail" border=1 style="border: 1px solid #E8EAEB" width=2090px>';
		html += '<tr style="height:30px">';
		html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
        html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';        
		html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
        html += '<td width=110px style="vertical-align: middle;">&nbsp;</td>';        
        html += '<td width=110px style="vertical-align: middle;">&nbsp;</td>';
        html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
        html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
        html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';

		html += '</tr>';
		html += '</table>';	
		

		document.getElementById('update_sales_content').insertAdjacentHTML('beforeend', html);	

		let nb_selected_rows = 0
		document.getElementById("update_sales_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected sales price (prices updated by contracts have been excluded)")

	}

	static do_update_sales_refresh()
	{
		
		let html = '';

		let method = 'silicon_ioi.ioi_items.doctype.ioi_sales_price.ioi_sales_price.ioi_sales_prices_get_list_for_update_prices'		

		frappe.call({  	method: method, 
						args: {	
                                "doc_type_id": document.getElementById("update_sales_doc_type_id").value, 
                                "cat_cust_id": document.getElementById('update_sales_catalog_customer_id').value,
								"sales_condition_id": document.getElementById("update_sales_sales_condition_id").value,
								"item_id": document.getElementById("update_sales_item_id").value,
								"unit_id": document.getElementById("update_sales_unit_id").value,
								"currency_id": document.getElementById("update_sales_currency_id").value,
								"enabled": document.getElementById("update_sales_enabled").value,
								"family_id": document.getElementById("update_sales_family_id").value,
								"type_id": document.getElementById("update_sales_type_id").value, 
								"group_id": document.getElementById("update_sales_group_id").value, 
								"subgroup_id": document.getElementById("update_sales_subgroup_id").value,
								"classification_id": document.getElementById("update_sales_classification_id").value,
								"shop_category_id": document.getElementById("update_sales_shop_category_id").value,
                                "withme_id": document.getElementById("update_sales_withme_id").value,
								"order_field": document.getElementById('update_sales_orderby').value,
								"order_dir": document.getElementById('update_sales_order').value
								},
						async: false,
						callback:function(r)	{ 
													document.getElementById('update_sales_check_all_none').checked = true;

													if (document.getElementById('update_sales_detail'))
													{
														for (var i = 0; i < document.getElementById('update_sales_detail').rows.length; i++)
														{

															if (document.getElementById('update_sales_detail_name_' + i.toString()))
															{
																document.getElementById('update_sales_detail_name_' + i.toString()).remove();
															}

															if (document.getElementById('update_sales_checked_id_' + i.toString()))
															{
																document.getElementById('update_sales_checked_id_' + i.toString()).remove();
															}

															if (document.getElementById('update_sales_button_id_' + i.toString()))
															{
																document.getElementById('update_sales_button_id_' + i.toString()).remove();
															}


															if (document.getElementById('update_sales_detail_enabled_' + i.toString()))
															{
																document.getElementById('update_sales_detail_enabled_' + i.toString()).remove();
															}															
														}

													
														document.getElementById('update_sales_detail').remove();
													}
						
													if (r.message.length > 0)
													{	
														let cpt = 0;
														html += '<table id="update_sales_detail" border=1 style="border: 1px solid #E8EAEB" width=2090px>';
														for (var i = 0; i < r.message.length; i++)
														{

															html += '<input type="hidden" id="update_sales_detail_name_' + i.toString() + '" value="' + r.message[i].name + '">'															
															html += '<input type="hidden" id="update_sales_detail_enabled_' + i.toString() + '" value="' + r.message[i].enabled + '">'															
															

															if (r.message[i].enabled == 1) {															
																html += '<tr style="height:30px">';
															}else{
																html += '<tr bgcolor="#E7E3E3" style="height:30px">';
															}


															html += '<td width=30px align="center" style="vertical-align: middle;">';
															html += '<input type="checkbox" id="update_sales_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;" checked>'; 
															html += '</td>';

															let s = '';

															s = '/app/ioi-sales-price/' + r.message[i].name;

															html += '<td width=30px align="center" style="vertical-align: middle;">';
															html += '<input type="button" id="update_sales_button_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;border: none; outline:none;" value="..." onclick="window.open(\'' + s + '\');" title="' + __("Go to Sales Price") + '">'; 
															html += '</td>';


                                                            html += '<td width=150px style="vertical-align: middle;">&nbsp;' + r.message[i].doc_type_id + '</td>';

															

															if (r.message[i].dc.toUpperCase() == 'IOI CUSTOMER') {
																
																s = '/app/ioi-customer/' + r.message[i].catalog_customer_id;
																html += '<td width=200px style="vertical-align: middle;" onclick="window.open(\'' + s + '\');" onmouseover="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';">&nbsp;<u>' + r.message[i].catalog_customer_id + '</u></td>';

															}else {

																s = '/app/ioi-sales-catalog/' + r.message[i].catalog_customer_id;
																html += '<td width=200px style="vertical-align: middle;" onclick="window.open(\'' + s + '\');" onmouseover="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';">&nbsp;<u>' + r.message[i].catalog_customer_id + '</u></td>';
															}

															

															html += '<td width=150px style="vertical-align: middle;">&nbsp;' + r.message[i].sales_condition_id + '</td>';

															s = '/app/ioi-item/' + r.message[i].item_id;

															html += '<td width=250px style="vertical-align: middle;" onclick="window.open(\'' + s + '\');" onmouseover="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';">&nbsp;<u>' + r.message[i].item_id + '</u></td>';


															

															html += '<td width=80px style="vertical-align: middle;">&nbsp;' + r.message[i].unit_id + '</td>';

															html += '<td width=80px style="vertical-align: middle;">&nbsp;' + r.message[i].currency_id + '</td>';

															html += '<td width=100px style="vertical-align: middle;" align="right">' + r.message[i].unit_price + '&nbsp;</td>';

															html += '<td width=100px style="vertical-align: middle;" align="right">' + r.message[i].fixed_price + '&nbsp;</td>';

															html += '<td width=100px style="vertical-align: middle;" align="right">' + r.message[i].base_discount + '&nbsp;%&nbsp;</td>';

															html += '<td width=100px style="vertical-align: middle;" align="right">' + r.message[i].extra_discount + '&nbsp;%&nbsp;</td>';

															if (r.message[i].enabled == 1) {
																html += '<td width=100px style="vertical-align: middle;">&nbsp;' + __("Enabled") + '</td>';
															}else {
																html += '<td width=100px style="vertical-align: middle;">&nbsp;' + __("Disabled") + '</td>';
															}


															if ((r.message[i].start_date != null) && (r.message[i].start_date != '')) {
																html += '<td width=110px style="vertical-align: middle;">&nbsp;' + r.message[i].start_date + '</td>';
															}else {
																html += '<td width=110px style="vertical-align: middle;">&nbsp;</td>';
															}

															if ((r.message[i].end_date != null) && (r.message[i].end_date != '')) {
																html += '<td width=110px style="vertical-align: middle;">&nbsp;' + r.message[i].end_date + '</td>';
															}else {
																html += '<td width=110px style="vertical-align: middle;">&nbsp;</td>';
															}

															if ((r.message[i].price_published != null) && (r.message[i].price_published != 0)) {
																html += '<td width=100px style="vertical-align: middle;" align="center">x</td>';
															}else {
																html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
															}

															if ((r.message[i].web_price_published != null) && (r.message[i].web_price_published != 0)) {
																html += '<td width=150px style="vertical-align: middle;" align="center">x</td>';
															}else {
																html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
															}

															if ((r.message[i].item_with_me != null) && (r.message[i].item_with_me != '')) {
																html += '<td width=150px style="vertical-align: middle;">&nbsp;' + r.message[i].item_with_me + '</td>';
															}else {
																html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
															}

															html += '</tr>';
														}
														html += '</table>';																	
													}else
													{
														html += '<table id="update_sales_detail" border=1 style="border: 1px solid #E8EAEB" width=2090px>';
														html += '<tr style="height:30px">';
														html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
                                                        html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
                                                        html += '<td width=110px style="vertical-align: middle;">&nbsp;</td>';
                                                        html += '<td width=110px style="vertical-align: middle;">&nbsp;</td>';
                                                        html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
                                                        html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
                                                        html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';

														html += '</tr>';
														html += '</table>';		
																							
													}

													document.getElementById('update_sales_content').insertAdjacentHTML('beforeend', html);

													silicon_ioi.doctype.ioiPriceUpdatingTool.sleep_static(200).then(() => {
														

														let fct_click = function () {

															let nb_selected_rows = 0

															for (var i = 0; i < document.getElementById('update_sales_detail').rows.length; i++) {	
																if (document.getElementById('update_sales_checked_id_' + i.toString())) {																							
																	if (document.getElementById('update_sales_checked_id_' + i.toString()).checked)	{
																		nb_selected_rows++;
																	}
																}
															}

															if (document.getElementById("update_sales_grid_banner"))
															{	
																if (nb_selected_rows <= 1) {
																	document.getElementById("update_sales_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected sales price (prices updated by contracts have been excluded)")
																}else {
																	document.getElementById("update_sales_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected sales prices (prices updated by contracts have been excluded)")
																}
															}
														};

														for (var i = 0; i < document.getElementById('update_sales_detail').rows.length; i++) {

															if (document.getElementById('update_sales_checked_id_' + i.toString())) {

																document.getElementById('update_sales_checked_id_' + i.toString()).onclick = fct_click;
															}
															
														}

													});

												}
		});

		let nb_selected_rows = 0

		for (var i = 0; i < document.getElementById('update_sales_detail').rows.length; i++) {	
			
			if (document.getElementById('update_sales_checked_id_' + i.toString())) {							
				if (document.getElementById('update_sales_checked_id_' + i.toString()).checked)	{
					nb_selected_rows++;
				}
			}
		}

		if (document.getElementById("update_sales_grid_banner"))
		{	
			if (nb_selected_rows <= 1) {
				document.getElementById("update_sales_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected sales price (prices updated by contracts have been excluded)")
			}else {
				document.getElementById("update_sales_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected sales prices (prices updated by contracts have been excluded)")
			}
		}

		
	}


	static do_update_sales_search_key_down(event)
	{
		if (event.keyCode == 13) 
		{
			silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_sales_refresh();
		}
		
	}

	static do_update_sales_search_on_change()
	{
		silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_sales_refresh();
	}

	static do_update_sales_col_click(obj)
	{   
		let s = obj.id;

	
		s = s.substring(17, obj.id.length); 

        document.getElementById('update_sales_col_label_select_type').innerHTML = '';        
		document.getElementById('update_sales_col_label_catalog_customer_id').innerHTML = '';
		document.getElementById('update_sales_col_label_sales_condition_id').innerHTML = '';
		document.getElementById('update_sales_col_label_item_id').innerHTML = '';
		document.getElementById('update_sales_col_label_unit_id').innerHTML = '';
		document.getElementById('update_sales_col_label_currency_id').innerHTML = '';
		document.getElementById('update_sales_col_label_unit_price').innerHTML = '';
		document.getElementById('update_sales_col_label_fixed_price').innerHTML = '';
		document.getElementById('update_sales_col_label_base_discount').innerHTML = '';		
		document.getElementById('update_sales_col_label_extra_discount').innerHTML = '';
		document.getElementById('update_sales_col_label_enabled').innerHTML = '';


        document.getElementById('update_sales_col_label_start_date').innerHTML = '';
        document.getElementById('update_sales_col_label_end_date').innerHTML = '';
        document.getElementById('update_sales_col_label_price_published').innerHTML = '';
        document.getElementById('update_sales_col_label_web_price_published').innerHTML = '';
        document.getElementById('update_sales_col_label_item_with_me').innerHTML = '';

		if (document.getElementById('update_sales_orderby').value == s)
		{
			if (document.getElementById('update_sales_order').value == 'desc')
			{	document.getElementById('update_sales_order').value = 'asc';
			
				document.getElementById('update_sales_col_label_' + s).innerHTML = '&darr;';
			}else
			{	document.getElementById('update_sales_order').value = 'desc';	
				document.getElementById('update_sales_col_label_' + s).innerHTML = '&uarr;';
			}
		}else
		{	document.getElementById('update_sales_orderby').value = s;
			document.getElementById('update_sales_order').value = 'desc';
			document.getElementById('update_sales_col_label_' + s).innerHTML = '&uarr;';
		}

		silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_sales_refresh();		
	}

	// ***************************************************************************************************************************************
	// Fill Item Family
	// ***************************************************************************************************************************************
	static update_sales_fill_item_family()
	{	
		let me = this;
		let amethod =  'silicon_ioi.ioi_configuration.doctype.ioi_item_family.ioi_item_family.ioi_item_family_get_families';

		frappe.call({  	method: amethod,
					   	args: {"enabled":1, "current_family": silicon_ioi.doctype.ioiPriceUpdatingTool.current_family},
						async: false,
						callback:function(r){   
                                                silicon_ioi.doctype.ioiPriceUpdatingTool.item_family = [];
												silicon_ioi.doctype.ioiPriceUpdatingTool.item_family[0] = ['', ''];

												document.getElementById('update_sales_family_id').options[document.getElementById('update_sales_family_id').options.length] = new Option('', '');												

												for (var i = 0; i < r.message.length; i++)
												{	
													silicon_ioi.doctype.ioiPriceUpdatingTool.item_family[i+1] = [r.message[i][0], r.message[i][1]];

													document.getElementById('update_sales_family_id').options[document.getElementById('update_sales_family_id').options.length] = new Option(silicon_ioi.doctype.ioiPriceUpdatingTool.item_family[i+1][1], silicon_ioi.doctype.ioiPriceUpdatingTool.item_family[i+1][0]);													
												}
											}
		});
	}


	// ***************************************************************************************************************************************
	// Fill Item Type
	// ***************************************************************************************************************************************
	static update_sales_fill_item_type(action='')
	{	
		if ((!document.getElementById('update_sales_family_id')) || ((document.getElementById('update_sales_family_id')) && (document.getElementById('update_sales_family_id').value.trim() == '')))
		{
			if (action != '')
			{
				for (var i = document.getElementById('update_sales_type_id').options.length-1; i >= 0; i--) {
					document.getElementById('update_sales_type_id').options[i].remove();	
				}

				for (var i = document.getElementById('update_sales_group_id').options.length-1; i >= 0; i--) {
					document.getElementById('update_sales_group_id').options[i].remove();	
				}
				
				for (var i = document.getElementById('update_sales_subgroup_id').options.length-1; i >= 0; i--) {
					document.getElementById('update_sales_subgroup_id').options[i].remove();	
				}

			}

			silicon_ioi.doctype.ioiPriceUpdatingTool.item_type = [];
			silicon_ioi.doctype.ioiPriceUpdatingTool.item_type[0] = ['', ''];
			document.getElementById('update_sales_type_id').options[document.getElementById('update_sales_type_id').options.length] = new Option('', '');												


			silicon_ioi.doctype.ioiPriceUpdatingTool.item_group = [];
			silicon_ioi.doctype.ioiPriceUpdatingTool.item_group[0] = ['', ''];
			document.getElementById('update_sales_group_id').options[document.getElementById('update_sales_group_id').options.length] = new Option('', '');												

			silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup = [];
			silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[0] = ['', ''];			
			document.getElementById('update_sales_subgroup_id').options[document.getElementById('update_sales_subgroup_id').options.length] = new Option('', '');												

		}else
		{
			let me = this;
			let amethod =  'silicon_ioi.ioi_configuration.doctype.ioi_item_type.ioi_item_type.ioi_item_type_get_types';

			frappe.call({  	method: amethod,
							args: {"family_id": document.getElementById('update_sales_family_id').value, "enabled":1, "current_type": silicon_ioi.doctype.ioiPriceUpdatingTool.current_type},
							async: false,
							callback:function(r){  
													if (action != '')
													{
														for (var i = document.getElementById('update_sales_type_id').options.length-1; i >= 0; i--) {
															document.getElementById('update_sales_type_id').options[i].remove();	
														}
										
														for (var i = document.getElementById('update_sales_group_id').options.length-1; i >= 0; i--) {
															document.getElementById('update_sales_group_id').options[i].remove();	
														}
														
														for (var i = document.getElementById('update_sales_subgroup_id').options.length-1; i >= 0; i--) {
															document.getElementById('update_sales_subgroup_id').options[i].remove();	
														}
													}
								
													silicon_ioi.doctype.ioiPriceUpdatingTool.item_type = [];
													silicon_ioi.doctype.ioiPriceUpdatingTool.item_type[0] = ['', ''];
													document.getElementById('update_sales_type_id').options[document.getElementById('update_sales_type_id').options.length] = new Option('', '');												
										

													for (var i = 0; i < r.message.length; i++)
													{	
														silicon_ioi.doctype.ioiPriceUpdatingTool.item_type[i+1] = [r.message[i][0], r.message[i][1]];

														document.getElementById('update_sales_type_id').options[document.getElementById('update_sales_type_id').options.length] = new Option(silicon_ioi.doctype.ioiPriceUpdatingTool.item_type[i+1][1], silicon_ioi.doctype.ioiPriceUpdatingTool.item_type[i+1][0]);													

													}

													silicon_ioi.doctype.ioiPriceUpdatingTool.item_group = [];
													silicon_ioi.doctype.ioiPriceUpdatingTool.item_group[0] = ['', ''];
													document.getElementById('update_sales_group_id').options[document.getElementById('update_sales_group_id').options.length] = new Option('', '');												
										
													silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup = [];
													silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[0] = ['', ''];			
													document.getElementById('update_sales_subgroup_id').options[document.getElementById('update_sales_subgroup_id').options.length] = new Option('', '');												
										
									
												}
			});
		}
	}


	// ***************************************************************************************************************************************
	// Fill Item Group
	// ***************************************************************************************************************************************
	static update_sales_fill_item_group(action='')
	{	
		if ((!document.getElementById('update_sales_type_id')) || ((document.getElementById('update_sales_type_id')) && (document.getElementById('update_sales_type_id').value.trim() == '')))
		{

			if (action != '')
			{

				for (var i = document.getElementById('update_sales_group_id').options.length-1; i >= 0; i--) {
					document.getElementById('update_sales_group_id').options[i].remove();	
				}
				
				for (var i = document.getElementById('update_sales_subgroup_id').options.length-1; i >= 0; i--) {
					document.getElementById('update_sales_subgroup_id').options[i].remove();	
				}

			}

			silicon_ioi.doctype.ioiPriceUpdatingTool.item_group = [];
			silicon_ioi.doctype.ioiPriceUpdatingTool.item_group[0] = ['', ''];
			document.getElementById('update_sales_group_id').options[document.getElementById('update_sales_group_id').options.length] = new Option('', '');												

			silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup = [];
			silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[0] = ['', ''];			
			document.getElementById('update_sales_subgroup_id').options[document.getElementById('update_sales_subgroup_id').options.length] = new Option('', '');												

		}else
		{
			let me = this;
			let amethod =  'silicon_ioi.ioi_configuration.doctype.ioi_item_group.ioi_item_group.ioi_item_group_get_groups';

			frappe.call({  	method: amethod,
							args: {"family_id": document.getElementById('update_sales_family_id').value, "type_id": document.getElementById('update_sales_type_id').value, "enabled":1, "current_group": silicon_ioi.doctype.ioiPriceUpdatingTool.current_group},
							async: false,
							callback:function(r){   
													if (action != '')
													{
														for (var i = document.getElementById('update_sales_group_id').options.length-1; i >= 0; i--) {
															document.getElementById('update_sales_group_id').options[i].remove();	
														}
														
														for (var i = document.getElementById('update_sales_subgroup_id').options.length-1; i >= 0; i--) {
															document.getElementById('update_sales_subgroup_id').options[i].remove();	
														}

													}

													silicon_ioi.doctype.ioiPriceUpdatingTool.item_group = [];
													silicon_ioi.doctype.ioiPriceUpdatingTool.item_group[0] = ['', ''];
													document.getElementById('update_sales_group_id').options[document.getElementById('update_sales_group_id').options.length] = new Option('', '');												


													for (var i = 0; i < r.message.length; i++)
													{	
														silicon_ioi.doctype.ioiPriceUpdatingTool.item_group[i+1] = [r.message[i][0], r.message[i][1]];

														document.getElementById('update_sales_group_id').options[document.getElementById('update_sales_group_id').options.length] = new Option(silicon_ioi.doctype.ioiPriceUpdatingTool.item_group[i+1][1], silicon_ioi.doctype.ioiPriceUpdatingTool.item_group[i+1][0]);													
													}

													silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup = [];
													silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[0] = ['', ''];			
													document.getElementById('update_sales_subgroup_id').options[document.getElementById('update_sales_subgroup_id').options.length] = new Option('', '');												

												}
			});
		}
	}


	// ***************************************************************************************************************************************
	// Fill Item Subgroup
	// ***************************************************************************************************************************************
	static update_sales_fill_item_subgroup(action='')
	{	
		if ((!document.getElementById('update_sales_group_id')) || ((document.getElementById('update_sales_group_id')) && (document.getElementById('update_sales_group_id').value.trim() == '')))
		{
			if (action != '')
			{
				for (var i = document.getElementById('update_sales_subgroup_id').options.length-1; i >= 0; i--) {
					document.getElementById('update_sales_subgroup_id').options[i].remove();	
				}
			}

			silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup = [];
			silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[0] = ['', ''];			
			document.getElementById('update_sales_subgroup_id').options[document.getElementById('update_sales_subgroup_id').options.length] = new Option('', '');												

		}else
		{
			let me = this;
			let amethod = 'silicon_ioi.ioi_configuration.doctype.ioi_item_subgroup.ioi_item_subgroup.ioi_item_subgroup_get_subgroups';

			frappe.call({  	method: amethod,
							args: {"family_id": document.getElementById('update_sales_family_id').value, "type_id": document.getElementById('update_sales_type_id').value, "group_id": document.getElementById('update_sales_group_id').value, "enabled":1, "current_subgroup": silicon_ioi.doctype.ioiPriceUpdatingTool.current_subgroup},
							async: false,
							callback:function(r){   
													if (action != '')
													{
														for (var i = document.getElementById('update_sales_subgroup_id').options.length-1; i >= 0; i--) {
															document.getElementById('update_sales_subgroup_id').options[i].remove();	
														}
													}

													silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup = [];
													silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[0] = ['', ''];
													document.getElementById('update_sales_subgroup_id').options[document.getElementById('update_sales_subgroup_id').options.length] = new Option('', '');												



													for (var i = 0; i < r.message.length; i++)
													{	
														silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[i+1] = [r.message[i][0], r.message[i][1]];
														document.getElementById('update_sales_subgroup_id').options[document.getElementById('update_sales_subgroup_id').options.length] = new Option(silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[i+1][1], silicon_ioi.doctype.ioiPriceUpdatingTool.item_subgroup[i+1][0]);													
													}
												}
			});
		}
	}

	// ***************************************************************************************************************************************
	// Clear values
	// ***************************************************************************************************************************************
	static do_update_sales_clear_values()
	{
		document.getElementById("update_sales_cb_start_date").checked = false;
		document.getElementById("update_sales_start_date").value = '';

		document.getElementById("update_sales_cb_end_date").checked = false;
		document.getElementById("update_sales_end_date").value = '';
		document.getElementById("update_sales_cb_clear_end_date").checked = false;

		document.getElementById("update_sales_cb_published_price").checked = false;
		document.getElementById("update_sales_published_price").value = '';

		document.getElementById("update_sales_cb_web_published").checked = false;
		document.getElementById("update_sales_web_published").value = '';

		document.getElementById("update_sales_cb_with_me").checked = false;
		document.getElementById("update_sales_with_me_value").value = '';
		document.getElementById("update_sales_cb_clear_with_me").checked = false;

		document.getElementById("update_sales_cb_increase").checked = false;
		document.getElementById("update_sales_increase_unit_price").value = '';

		document.getElementById("update_sales_cb_fixed_price").checked = false;
		document.getElementById("update_sales_update_fixed_price").value = '';

		document.getElementById("update_sales_cb_base_discount").checked = false;
		document.getElementById("update_sales_update_base_discount").value = '';

		document.getElementById("update_sales_cb_extra_discount").checked = false;
		document.getElementById("update_sales_update_extra_discount").value = '';

		document.getElementById("update_sales_cb_increase_recommended_unit_price").checked = false;
		document.getElementById("update_sales_increase_recommended_unit_price").value = '';

		document.getElementById("update_sales_cb_recommended_base_discount").checked = false;
		document.getElementById("update_sales_update_recommended_base_discount").value = '';

		document.getElementById("update_sales_cb_recommended_margin").checked = false;
		document.getElementById("update_sales_update_recommended_margin").value = '';

		document.getElementById("update_sales_cb_margin_mode").checked = false;
		document.getElementById("update_sales_update_margin_mode").value = '';

		document.getElementById("update_sales_cb_specific_vat_rate").checked = false;
		document.getElementById("update_sales_update_specific_vat_rate").value = '';
	

		document.getElementById('update_sales_update_rounding_kind_label').value = __('Decimals');
		document.getElementById('update_sales_update_rounding_kind').selectedIndex = 0;
		document.getElementById('update_sales_update_rounding_method').selectedIndex = 0;
		document.getElementById('update_sales_update_rounding_value').value = '2';


		document.getElementById("update_sales_cb_new_catalog").checked = false;
		document.getElementById("update_sales_new_catalog_type").selectedIndex = 0;
		document.getElementById("update_sales_new_catalog_catalog").selectedIndex = 0;
		document.getElementById("update_sales_new_catalog_customer").value = '';

		document.getElementById("update_sales_new_catalog_catalog").hidden = true;
		document.getElementById("update_sales_new_catalog_customer").hidden = true;
		document.getElementById("update_sales_new_catalog_bt_customer").hidden = true;

		document.getElementById("update_sales_cb_new_sales_condition").checked = false;
		document.getElementById("update_sales_new_sales_condition").value = '';

		document.getElementById("update_sales_cb_convert").checked = false;
		document.getElementById("update_sales_new_currency").value = '';

	}



	// ***************************************************************************************************************************************
	// Set Enabled
	// ***************************************************************************************************************************************
	static do_update_sales_set_enabled()
	{
		if (!document.getElementById('update_sales_detail')) {
			frappe.msgprint({title: __("Message"), message: __('No selected sales prices'), indicator: "red"});
			raise;
		}else {
			if (document.getElementById('update_sales_detail').rows.length == 0) {
				frappe.msgprint({title: __("Message"), message: __('No selected sales prices'), indicator: "red"});
				raise;
			}else {

				let at_least_one_checked = false;

				for (var i = 0; i < document.getElementById('update_sales_detail').rows.length; i++) {
					
					if (!document.getElementById('update_sales_checked_id_' + i.toString()))
					{
						frappe.msgprint({title: __("Message"), message: __('No selected sales prices'), indicator: "red"});
						raise;
					}else {
						if (document.getElementById('update_sales_checked_id_' + i.toString()).checked)
						{
							at_least_one_checked = true;
							break;
						}
					}
				}

				if (!at_least_one_checked) {
					frappe.msgprint({title: __("Message"), message: __('No selected sales prices'), indicator: "red"});
					raise;
				}
			}
		}

		let nb_selected_rows = 0

		for (var i = 0; i < document.getElementById('update_sales_detail').rows.length; i++) {	
			if (document.getElementById('update_sales_checked_id_' + i.toString())) {							
				if (document.getElementById('update_sales_checked_id_' + i.toString()).checked)	{
					nb_selected_rows++;
				}
			}
		}

		let msg = '';

		if (nb_selected_rows <= 1) {
			msg = 'Set enabled ' + nb_selected_rows.toString() + ' selected sales price ?';
		}else {
			msg = 'Set enabled ' + nb_selected_rows.toString() + ' selected sales prices ?';
		}


		frappe.confirm(	msg,
						() => 	{	
									document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;							

									let selected_lines = [];
									let sales_prices_selected_lines = '';

									for (var i = 0; i < document.getElementById('update_sales_detail').rows.length; i++) {	
										if (document.getElementById('update_sales_checked_id_' + i.toString())) {										
											if (document.getElementById('update_sales_checked_id_' + i.toString()).checked)	{

												selected_lines[selected_lines.length] = document.getElementById('update_sales_detail_name_' + i.toString()).value;
												sales_prices_selected_lines += document.getElementById('update_sales_detail_name_' + i.toString()).value + '#sbsepa#'
												
											}
										}
									}

									if (sales_prices_selected_lines != '')
									{
										let method = 'silicon_ioi.ioi_items.doctype.ioi_sales_price.ioi_sales_price.ioi_sales_prices_set_enabled_selected_sales_prices';

										frappe.call({  	method: method, 
														args: {"selected_lines": sales_prices_selected_lines},
														async: false,
														callback:function(r)	{ 
																	}
										});
					
										silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_sales_refresh();	
										
										silicon_ioi.doctype.ioiPriceUpdatingTool.sleep_static(200).then(() => {

                                            let nb_selected_rows = 0;

											for (var i = 0; i < document.getElementById('update_sales_detail').rows.length; i++) {	

												if (document.getElementById('update_sales_checked_id_' + i.toString())) {												

													document.getElementById('update_sales_checked_id_' + i.toString()).checked = false;

													if (selected_lines.indexOf(document.getElementById('update_sales_detail_name_' + i.toString()).value) != -1)
													{	
														document.getElementById('update_sales_checked_id_' + i.toString()).checked = true;
														nb_selected_rows++;
													}
												}
											}

                                   
                                            if (document.getElementById("update_sales_grid_banner"))
                                            {	
                                                if (nb_selected_rows <= 1) {
                                                    document.getElementById("update_sales_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected sales price (prices updated by contracts have been excluded)")
                                                }else {
                                                    document.getElementById("update_sales_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected sales prices (prices updated by contracts have been excluded)")
                                                }
                                            }
                                    
										});
										
									}
								}, 
						() => 	{	
								}
		);

	}

	// ***************************************************************************************************************************************
	// Set Disabled
	// ***************************************************************************************************************************************
	static do_update_sales_set_disabled()
	{
		if (!document.getElementById('update_sales_detail')) {
			frappe.msgprint({title: __("Message"), message: __('No selected sales prices'), indicator: "red"});
			raise;
		}else {
			if (document.getElementById('update_sales_detail').rows.length == 0) {
				frappe.msgprint({title: __("Message"), message: __('No selected sales prices'), indicator: "red"});
				raise;
			}else {

				let at_least_one_checked = false;

				for (var i = 0; i < document.getElementById('update_sales_detail').rows.length; i++) {
					
					if (!document.getElementById('update_sales_checked_id_' + i.toString()))
					{
						frappe.msgprint({title: __("Message"), message: __('No selected sales prices'), indicator: "red"});
						raise;
					}else {
						if (document.getElementById('update_sales_checked_id_' + i.toString()).checked)
						{
							at_least_one_checked = true;
							break;
						}
					}
				}

				if (!at_least_one_checked) {
					frappe.msgprint({title: __("Message"), message: __('No selected sales prices'), indicator: "red"});
					raise;
				}
			}
		}


		let nb_selected_rows = 0

		for (var i = 0; i < document.getElementById('update_sales_detail').rows.length; i++) {	
			if (document.getElementById('update_sales_checked_id_' + i.toString())) {										
				if (document.getElementById('update_sales_checked_id_' + i.toString()).checked)	{
					nb_selected_rows++;
				}
			}
		}

		let msg = '';

		if (nb_selected_rows <= 1) {
			msg = 'Set disabled ' + nb_selected_rows.toString() + ' selected sales price ?';
		}else {
			msg = 'Set disabled ' + nb_selected_rows.toString() + ' selected sales prices ?';
		}

		frappe.confirm(	msg,
						() => 	{	
									document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

									let selected_lines = [];
									let sales_prices_selected_lines = '';

									for (var i = 0; i < document.getElementById('update_sales_detail').rows.length; i++) {	
										if (document.getElementById('update_sales_checked_id_' + i.toString())) {
											if (document.getElementById('update_sales_checked_id_' + i.toString()).checked)	{

												selected_lines[selected_lines.length] = document.getElementById('update_sales_detail_name_' + i.toString()).value;
												sales_prices_selected_lines += document.getElementById('update_sales_detail_name_' + i.toString()).value + '#sbsepa#'
											}
										}
									}

									if (sales_prices_selected_lines != '')
									{
										let method = 'silicon_ioi.ioi_items.doctype.ioi_sales_price.ioi_sales_price.ioi_sales_prices_set_disabled_selected_sales_prices';

										frappe.call({  	method: method, 
														args: {"selected_lines": sales_prices_selected_lines},
														async: false,
														callback:function(r)	{ 
																	}
										});
					
										silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_sales_refresh();
										
										
										silicon_ioi.doctype.ioiPriceUpdatingTool.sleep_static(200).then(() => {

                                            let nb_selected_rows = 0;

											for (var i = 0; i < document.getElementById('update_sales_detail').rows.length; i++) {	
												if (document.getElementById('update_sales_checked_id_' + i.toString())) {
													document.getElementById('update_sales_checked_id_' + i.toString()).checked = false;

													if (selected_lines.indexOf(document.getElementById('update_sales_detail_name_' + i.toString()).value) != -1)
													{	
														document.getElementById('update_sales_checked_id_' + i.toString()).checked = true;
														nb_selected_rows++;
													}
												}
											}

                                   
                                            if (document.getElementById("update_sales_grid_banner"))
                                            {	
                                                if (nb_selected_rows <= 1) {
                                                    document.getElementById("update_sales_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected sales price (prices updated by contracts have been excluded)")
                                                }else {
                                                    document.getElementById("update_sales_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected sales prices (prices updated by contracts have been excluded)")
                                                }
                                            }

										});

									}

								}, 
						() => 	{	
								}
		);

	}


	// ***************************************************************************************************************************************
	// Delete selected disabled
	// ***************************************************************************************************************************************
	static do_update_sales_delete_selected_disabled()
	{
		if (!document.getElementById('update_sales_detail')) {
			frappe.msgprint({title: __("Message"), message: __('No selected sales prices'), indicator: "red"});
			raise;
		}else {
			if (document.getElementById('update_sales_detail').rows.length == 0) {
				frappe.msgprint({title: __("Message"), message: __('No selected sales prices'), indicator: "red"});
				raise;
			}else {

				let at_least_one_checked = false;

				for (var i = 0; i < document.getElementById('update_sales_detail').rows.length; i++) {

					if (!document.getElementById('update_sales_checked_id_' + i.toString()))
					{
						frappe.msgprint({title: __("Message"), message: __('No selected sales prices'), indicator: "red"});
						raise;
					}else {
						if (document.getElementById('update_sales_checked_id_' + i.toString()).checked)
						{
							if (document.getElementById('update_sales_detail_enabled_' + i.toString()).value != 1)
							{
								at_least_one_checked = true;
								break;
							}
						}
					}
				}

				if (!at_least_one_checked) {
					frappe.msgprint({title: __("Message"), message: __('No selected disabled sales prices'), indicator: "red"});
					raise;
				}
			}
		}


		let nb_selected_rows = 0

		for (var i = 0; i < document.getElementById('update_sales_detail').rows.length; i++) {	
										
			if (document.getElementById('update_sales_checked_id_' + i.toString()).checked)	{
				if (document.getElementById('update_sales_detail_enabled_' + i.toString()).value != 1) {
					nb_selected_rows++;
				}
			}
		}

		let msg = '';

		if (nb_selected_rows <= 1) {
			msg = 'Delete ' + nb_selected_rows.toString() + ' selected sales price disabled ?';
		}else {
			msg = 'Delete ' + nb_selected_rows.toString() + ' selected sales prices disabled ?';
		}

		frappe.confirm(	msg,
						() => 	{	
									document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

									let selected_lines = [];
									let sales_prices_selected_lines = '';

									for (var i = 0; i < document.getElementById('update_sales_detail').rows.length; i++) {	
										
										if (document.getElementById('update_sales_checked_id_' + i.toString()).checked)	{

											if (document.getElementById('update_sales_detail_enabled_' + i.toString()).value != 1) {											

												selected_lines[selected_lines.length] = document.getElementById('update_sales_detail_name_' + i.toString()).value;
												sales_prices_selected_lines += document.getElementById('update_sales_detail_name_' + i.toString()).value + '#sbsepa#'
											}
											
										}
									}

									if (sales_prices_selected_lines != '')
									{
										let method = 'silicon_ioi.ioi_items.doctype.ioi_sales_price.ioi_sales_price.ioi_sales_prices_delete_selected_sales_prices_disabled';

										frappe.call({  	method: method, 
														args: {"selected_lines": sales_prices_selected_lines},
														async: false,
														callback:function(r)	{ 
																	}
										});
					
										silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_sales_refresh();

									}

								}, 
						() => 	{	
								}
		);


	}


	// ***************************************************************************************************************************************
	// Update sales prices
	// ***************************************************************************************************************************************

	static do_update_sales_update_price()
	{
		if (!document.getElementById('update_sales_detail')) {
			frappe.msgprint({title: __("Message"), message: __('No selected sales prices'), indicator: "red"});
			raise;
		}else {
			if (document.getElementById('update_sales_detail').rows.length == 0) {
				frappe.msgprint({title: __("Message"), message: __('No selected sales prices'), indicator: "red"});
				raise;
			}else {

				let at_least_one_checked = false;

				for (var i = 0; i < document.getElementById('update_sales_detail').rows.length; i++) {
					
					if (!document.getElementById('update_sales_checked_id_' + i.toString()))
					{
						frappe.msgprint({title: __("Message"), message: __('No selected sales prices'), indicator: "red"});
						raise;
					}else {
						if (document.getElementById('update_sales_checked_id_' + i.toString()).checked)
						{
							at_least_one_checked = true;
							break;
						}
					}
				}

				if (!at_least_one_checked) {
					frappe.msgprint({title: __("Message"), message: __('No selected sales prices'), indicator: "red"});
					raise;
				}
			}
		}

		let cb_start_date = 0;

		if (document.getElementById("update_sales_cb_start_date").checked) {	
			cb_start_date = 1;
		}

		if (cb_start_date == 1) {

			if ((!document.getElementById('update_sales_start_date')) || ((document.getElementById('update_sales_start_date')) && (document.getElementById('update_sales_start_date').value.trim() == ''))) {
				frappe.msgprint({title: __("Message"), message: __('Start date is mandatory'), indicator: "red"});
				raise;

			}
		}


		let cb_end_date = 0;

		if (document.getElementById("update_sales_cb_end_date").checked) {	
			cb_end_date = 1;
		}

		if (cb_end_date == 1) {

			if ((!document.getElementById('update_sales_end_date')) || ((document.getElementById('update_sales_end_date')) && (document.getElementById('update_sales_end_date').value.trim() == ''))) {
				frappe.msgprint({title: __("Message"), message: __('End date is mandatory'), indicator: "red"});
				raise;
			}
		}

		if (((document.getElementById('update_sales_start_date')) && (document.getElementById('update_sales_start_date').value.trim() != '')) &&
			((document.getElementById('update_sales_end_date')) && (document.getElementById('update_sales_end_date').value.trim() != ''))) {

			if (Date.parse(document.getElementById('update_sales_start_date').value) > Date.parse(document.getElementById('update_sales_end_date').value)) {
				frappe.msgprint({title: __("Message"), message: __('Start date has to be lower than or equal to End date'), indicator: "red"});
				raise;

			}
		
		}

		let cb_clear_end_date = 0;

		if (document.getElementById("update_sales_cb_clear_end_date").checked) {	
			cb_clear_end_date = 1;
		}


		let cb_published_price = 0;

		if (document.getElementById("update_sales_cb_published_price").checked) {	
			cb_published_price = 1;
		}

		let cb_published_price_value = 0;

		if (document.getElementById("update_sales_published_price").checked) {	
			cb_published_price_value = 1;
		}




		let cb_web_published = 0;

		if (document.getElementById("update_sales_cb_web_published").checked) {	
			cb_web_published = 1;
		}

		let cb_web_published_value = 0;

		if (document.getElementById("update_sales_web_published").checked) {	
			cb_web_published_value = 1;
		}


		let cb_withme = 0;

		if (document.getElementById("update_sales_cb_with_me").checked) {	
			cb_withme = 1;
		}

		if (cb_withme == 1) {
		
			if ((!document.getElementById('update_sales_with_me_value')) || ((document.getElementById('update_sales_with_me_value')) && (document.getElementById('update_sales_with_me_value').value.trim() == ''))) {
				frappe.msgprint({title: __("Message"), message: __('With me is mandatory'), indicator: "red"});
				raise;
			}
			
		}

		let cb_clear_withme = 0;

		if (document.getElementById("update_sales_cb_clear_with_me").checked) {	
			cb_clear_withme = 1;
		}

		let cb_increase = 0;
		
		if (document.getElementById("update_sales_cb_increase").checked) {	
			cb_increase = 1;
		}


		let cb_fixed_price = 0;
		
		if (document.getElementById("update_sales_cb_fixed_price").checked) {	
			cb_fixed_price = 1;
		}

		let cb_base_discount = 0;
		
		if (document.getElementById("update_sales_cb_base_discount").checked) {	
			cb_base_discount = 1;
		}


		let cb_extra_discount = 0;
		
		if (document.getElementById("update_sales_cb_extra_discount").checked) {	
			cb_extra_discount = 1;
		}


		let cb_convert = 0;
		
		if (document.getElementById("update_sales_cb_convert").checked) {	
			cb_convert = 1;
		}


		let increase_unit_price = 0;

		if ((document.getElementById("update_sales_increase_unit_price")) && (document.getElementById("update_sales_increase_unit_price").value.trim() != '')) {
			increase_unit_price = parseFloat(document.getElementById("update_sales_increase_unit_price").value);
		}


		let update_fixed_price = 0;

		if ((document.getElementById("update_sales_update_fixed_price")) && (document.getElementById("update_sales_update_fixed_price").value.trim() != '')) {
			update_fixed_price = parseFloat(document.getElementById("update_sales_update_fixed_price").value);
		}


		let update_base_discount = 0;

		if ((document.getElementById("update_sales_update_base_discount")) && (document.getElementById("update_sales_update_base_discount").value.trim() != '')) {
			update_base_discount = parseFloat(document.getElementById("update_sales_update_base_discount").value);
		}

		if ((update_base_discount < 0) || (update_base_discount > 100)) {

			frappe.msgprint({title: __("Message"), message: __('Base discount has to be between 0 and 100'), indicator: "red"});
			raise;
		}

		let update_extra_discount = 0;

		if ((document.getElementById("update_sales_update_extra_discount")) && (document.getElementById("update_sales_update_extra_discount").value.trim() != '')) {
			update_extra_discount = parseFloat(document.getElementById("update_sales_update_extra_discount").value);
		}

		if ((update_extra_discount < 0) || (update_extra_discount > 100)) {

			frappe.msgprint({title: __("Message"), message: __('Extra discount has to be between 0 and 100'), indicator: "red"});
			raise;
		}

		if (update_base_discount + update_extra_discount > 100) {
			frappe.msgprint({title: __("Message"), message: __('Sum of base and extra discount has to be between 0 and 100'), indicator: "red"});
			raise;

		}

		let cb_increase_recommended_unit_price = 0;
		
		if (document.getElementById("update_sales_cb_increase_recommended_unit_price").checked) {	
			cb_increase_recommended_unit_price = 1;
		}


		let increase_recommended_unit_price = 0;

		if ((document.getElementById("update_sales_increase_recommended_unit_price")) && (document.getElementById("update_sales_increase_recommended_unit_price").value.trim() != '')) {
			increase_recommended_unit_price = parseFloat(document.getElementById("update_sales_increase_recommended_unit_price").value);
		}


		let cb_recommended_base_discount = 0;
		
		if (document.getElementById("update_sales_cb_recommended_base_discount").checked) {	
			cb_recommended_base_discount = 1;
		}


		let update_recommended_base_discount = 0;

		if ((document.getElementById("update_sales_update_recommended_base_discount")) && (document.getElementById("update_sales_update_recommended_base_discount").value.trim() != '')) {
			update_recommended_base_discount = parseFloat(document.getElementById("update_sales_update_recommended_base_discount").value);
		}

		if ((update_recommended_base_discount < 0) || (update_recommended_base_discount > 100)) {

			frappe.msgprint({title: __("Message"), message: __('Recommended base discount has to be between 0 and 100'), indicator: "red"});
			raise;
		}


		let cb_recommended_margin = 0;
		
		if (document.getElementById("update_sales_cb_recommended_margin").checked) {	
			cb_recommended_margin = 1;
		}


		let update_recommended_margin = 0;

		if ((document.getElementById("update_sales_update_recommended_margin")) && (document.getElementById("update_sales_update_recommended_margin").value.trim() != '')) {
			update_recommended_margin = parseFloat(document.getElementById("update_sales_update_recommended_margin").value);
		}

		if ((update_recommended_margin < 0) || (update_recommended_margin > 100)) {

			frappe.msgprint({title: __("Message"), message: __('Recommended margin has to be between 0 and 100'), indicator: "red"});
			raise;
		}

		let cb_margin_mode = 0;
		
		if (document.getElementById("update_sales_cb_margin_mode").checked) {	
			cb_margin_mode = 1;
		}

		let cb_specific_vat_rate = 0;

		if (document.getElementById("update_sales_cb_specific_vat_rate").checked) {	
			cb_specific_vat_rate = 1;
		}

		let update_specific_vat_rate = 0;

		if ((document.getElementById("update_sales_update_specific_vat_rate")) && (document.getElementById("update_sales_update_specific_vat_rate").value.trim() != '')) {
			update_specific_vat_rate = parseFloat(document.getElementById("update_sales_update_specific_vat_rate").value);
		}

		if ((update_specific_vat_rate < 0) || (update_specific_vat_rate > 100)) {

			frappe.msgprint({title: __("Message"), message: __('Specific VAT rate has to be between 0 and 100'), indicator: "red"});
			raise;
		}


		let rounding_kind = document.getElementById('update_sales_update_rounding_kind').value;
		let rounding_method = document.getElementById('update_sales_update_rounding_method').value;

		let rounding_value = 0;
		if ((document.getElementById("update_sales_update_rounding_value")) && (document.getElementById("update_sales_update_rounding_value").value.trim() != '')) {
			rounding_value = document.getElementById("update_sales_update_rounding_value").value;
		}

		if (rounding_value < 0) {
			frappe.msgprint({title: __("Message"), message: __('Rounding value has to be greater than or equal to zero'), indicator: "red"});
			raise;
		}



		let go = true;

		let cb_new_catalog = 0;

		if (document.getElementById("update_sales_cb_new_catalog").checked) {	
			cb_new_catalog = 1;
		}

		if (cb_new_catalog == 1) {

			if (document.getElementById('update_sales_new_catalog_type').value != '') {

				if (document.getElementById('update_sales_new_catalog_type').value == 'Catalog') {

					if (document.getElementById('update_sales_new_catalog_catalog').value.trim() == '') {

						frappe.msgprint({title: __("Message"), message: __('Catalog is mandatory'), indicator: "red"});
						raise;
					}


				}else{

					if (document.getElementById('update_sales_new_catalog_customer').value.trim() == '') {

						frappe.msgprint({title: __("Message"), message: __('Customer is mandatory'), indicator: "red"});
						raise;
					}else{

						let meth = 'silicon_ioi.ioi_items.doctype.ioi_sales_price.ioi_sales_price.ioi_sales_prices_is_customer_exist';
						frappe.call({  	method: meth, 
										args: {	"customer_id": document.getElementById("update_sales_new_catalog_customer").value },
										async: false,
										callback:function(r)	{ 
																	if ((r.message == null) || (r.message == 0))
																	{
																		go = false;
																	}
																}
							});
					}
				}
			}else {
				frappe.msgprint({title: __("Message"), message: __('Cat type is mandatory'), indicator: "red"});
				raise;
			}
		}

		if (!go) {
			frappe.msgprint({title: __("Message"), message: __('Customer does not exist'), indicator: "red"});
			raise;			
		}

		let cb_new_sales_condition = 0;

		if (document.getElementById("update_sales_cb_new_sales_condition").checked) {	
			cb_new_sales_condition = 1;
		}

		if (cb_new_sales_condition == 1) {

			if (document.getElementById('update_sales_new_sales_condition').value.trim() == '') {

				frappe.msgprint({title: __("Message"), message: __('Sales condition is mandatory'), indicator: "red"});
				raise;
			}
		}




		let new_currency = '';

		

		if (cb_convert == 1)
		{
			if  ((!document.getElementById("update_sales_new_currency")) || ((document.getElementById("update_sales_new_currency")) && (document.getElementById("update_sales_new_currency").value.trim() == ''))) {					

				frappe.msgprint({title: __("Message"), message: __('New currency is mandatory'), indicator: "red"});
				raise;

			}
			

			// Check New Currency
			let meth = 'silicon_ioi.ioi_items.doctype.ioi_sales_price.ioi_sales_price.ioi_sales_prices_check_currency';
			frappe.call({  	method: meth, 
							args: {	"currency_id": document.getElementById("update_sales_new_currency").value },
							async: false,
							callback:function(r)	{ 
														if ((r.message == null) || (r.message == 0))
														{
															frappe.msgprint({title: __("Message"), message: __('New currency does not exist'), indicator: "red"});
															go = false;
															raise;
									
														}else {
															new_currency = document.getElementById("update_sales_new_currency").value;
														}

													}
				});


		}else {
			
			new_currency = document.getElementById("update_sales_new_currency").value;
		}

		if (go) {

			let nb_selected_rows = 0

			for (var i = 0; i < document.getElementById('update_sales_detail').rows.length; i++) {	
				
				if (document.getElementById('update_sales_checked_id_' + i.toString())) {
					if (document.getElementById('update_sales_checked_id_' + i.toString()).checked)	{
						nb_selected_rows++;
					}
				}
			}
	
			let msg = '';

			let top = 10;

			msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;">' + __("Selected sales prices : ") + '<b>' +  nb_selected_rows.toString() + '</b></label>';	
			
			top += 25;


			if ((cb_new_catalog == 1) || (cb_new_sales_condition == 1) || (cb_convert == 1))
			{
				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;">' + __("Create/Update selected prices in this catalog / condition / currency") + '</label>';

				top += 25;

				if (cb_new_catalog == 1) {

					let s = '- ' + document.getElementById('update_sales_new_catalog_type').value;

					if (document.getElementById('update_sales_new_catalog_type').value == 'Catalog') {
						s += ' : <b>' + document.getElementById('update_sales_new_catalog_catalog').value + '</b>';
					}else {
						s += ' : <b>' + document.getElementById('update_sales_new_catalog_customer').value + '</b>';
					}

					msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

					top += 25;

				}

				if (cb_new_sales_condition == 1) {
					
					let s = '- ' + __("Condition");

					s += ' : <b>' + document.getElementById('update_sales_new_sales_condition').value + '</b>';

					msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

					top += 25;

				}

				if (cb_convert == 1) {

					let s = '- ' + __("Currency");

					s += ' : <b>' + new_currency + '</b>';

					msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

					top += 25;
					
				}

			}else{
				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;">' + __("Update selected prices") + '</label>';

				top += 25;

			}

			if (cb_start_date == 1) {

				let s = '- ' + __("Set start date");

				s += ' : <b>' + document.getElementById('update_sales_start_date').value + '</b>';

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

				top += 25;
				
			}

			if (cb_clear_end_date == 1)
			{
				let s = '- ' + __("Clear end date");

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

				top += 25;

			}else {
				if (cb_end_date == 1)
				{
					let s = '- ' + __("Set end date");

					s += ' : <b>' + document.getElementById('update_sales_end_date').value + '</b>';
	
					msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					
	
					top += 25;
	
				}

			}

			if (cb_published_price == 1)
			{

				let s = '';

				if (cb_published_price_value == 1) {
					s = '- ' + __("Check published price");
				}else{
					s = '- ' + __("Uncheck published price");
				}

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

				top += 25;

			}

	
	
			if (cb_web_published == 1)
			{

				let s = '';

				if (cb_web_published_value == 1) {
					s = '- ' + __("Check published price");
				}else{
					s = '- ' + __("Uncheck web published");
				}

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

				top += 25;

			}


			if (cb_clear_withme == 1)
			{
				let s = '- ' + __("Clear with me");

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

				top += 25;

			}else {
				if (cb_withme == 1)
				{
					let s = '- ' + __("Set with me");

					s += ' : <b>' + document.getElementById('update_sales_with_me_value').value + '</b>';
	
					msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					
	
					top += 25;
	
				}

			}

			if (cb_increase == 1) {

				let s = '- ' + __("Increasing unit price of");

				s += ' <b>' + increase_unit_price.toString() + '</b> %';

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

				top += 25;
				
			}

			if (cb_fixed_price == 1) {

				let s = '- ' + __("Update fixed price");

				s += ' : <b>' + update_fixed_price.toString() + '</b>';

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

				top += 25;
				
			}

			if (cb_base_discount == 1) {

				let s = '- ' + __("Update base discount");

				s += ' : <b>' + update_base_discount.toString() + '</b> %';

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

				top += 25;
				
			}

			if (cb_extra_discount == 1) {

				let s = '- ' + __("Update extra discount");

				s += ' : <b>' + update_extra_discount.toString() + '</b> %';

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

				top += 25;
				
			}

			if (cb_increase_recommended_unit_price == 1) {

				let s = '- ' + __("Increasing reccomended price of");

				s += ' <b>' + increase_recommended_unit_price.toString() + '</b> %';

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

				top += 25;
				
			}

			if (cb_recommended_base_discount == 1) {

				let s = '- ' + __("Update recommended base discount");

				s += ' : <b>' + update_recommended_base_discount.toString() + '</b> %';

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

				top += 25;
				
			}

			if (cb_recommended_margin == 1) {

				let s = '- ' + __("Update recommended margin");

				s += ' : <b>' + update_recommended_margin.toString() + '</b> %';

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

				top += 25;
				
			}


			if (cb_margin_mode == 1) {

				let s = '- ' + __("Update margin mode");

				if (document.getElementById('update_sales_update_margin_mode').value == 0) {
					s += ' : <b>' + __('Regular') + '</b>';
				}else{
					s += ' : <b>' + __('Sales based') + '</b>';
				}

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

				top += 25;
				
			}

			if (cb_specific_vat_rate == 1) {

				let s = '- ' + __("Update VAT rate");

				s += ' : <b>' + update_specific_vat_rate.toString() + '</b> %';

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';					

				top += 25;
				
			}



			let s = '- ' + __("Rounding");

			if (document.getElementById('update_sales_update_rounding_kind').value == 0) {
				s += ' <b>' + __('Decimals') + '</b>';
			}else if (document.getElementById('update_sales_update_rounding_kind').value == 1) {
				s += ' <b>' + __('Multiple') + '</b>';
			}

			s += ' ' + rounding_value;

			s += ' - ' + __('Method'); 

			if (document.getElementById('update_sales_update_rounding_method').value == 0) {
				s += ' <b>' + __('Nearest') + '</b>';
			}else if (document.getElementById('update_sales_update_rounding_method').value == 1) {
				s += ' <b>' + __('Above') + '</b>';
			}else{
				s += ' <b>' + __('Under') + '</b>';
			}

			msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 40px;"> ' + s + '</label>';
			


			top += 50;


			s = '<font color="blue">Are you sure to launch the update ?</font>';
			msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';					


			top += 40;
			msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';
	

			frappe.confirm(	msg,
							() => 	{	
										document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;								
										
										let sales_prices_selected_lines = '';
										let selected_lines = [];
	
										for (var i = 0; i < document.getElementById('update_sales_detail').rows.length; i++) {	
											
											if (document.getElementById('update_sales_checked_id_' + i.toString())) {											
												if (document.getElementById('update_sales_checked_id_' + i.toString()).checked)	{
		
													sales_prices_selected_lines += document.getElementById('update_sales_detail_name_' + i.toString()).value + '#sbsepa#'

													selected_lines[selected_lines.length] = document.getElementById('update_sales_detail_name_' + i.toString()).value;
													
												}
											}
										}

	
										if (sales_prices_selected_lines != '')
										{
											let meth = 'silicon_ioi.ioi_items.doctype.ioi_sales_price.ioi_sales_price.ioi_sales_prices_update_sales_prices';
	
											frappe.call({  	method: meth, 
															args: {	"selected_lines": sales_prices_selected_lines,

																	"cb_start_date": cb_start_date,
																	"start_date": document.getElementById('update_sales_start_date').value,
																	"cb_end_date": cb_end_date,
																	"end_date": document.getElementById('update_sales_end_date').value,
																	"cb_clear_end_date" : cb_clear_end_date,
																	"cb_published_price": cb_published_price,
																	"cb_published_price_value": cb_published_price_value,




																	"cb_web_published": cb_web_published,
																	"cb_web_published_value": cb_web_published_value,
																	"cb_withme": cb_withme,
																	"withme_value": document.getElementById('update_sales_with_me_value').value,
																	"cb_clear_withme": cb_clear_withme,
															
																	"cb_increase": cb_increase, 
																	"increase_unit_price": increase_unit_price, 
																	"cb_fixed_price": cb_fixed_price, 
																	"update_fixed_price": update_fixed_price, 
																	"cb_base_discount": cb_base_discount , 
																	"update_base_discount": update_base_discount,
																	"cb_extra_discount": cb_extra_discount, 
																	"update_extra_discount": update_extra_discount, 

																	"cb_increase_recommended_unit_price": cb_increase_recommended_unit_price,
																	"increase_recommended_unit_price": increase_recommended_unit_price,
																	"cb_recommended_base_discount": cb_recommended_base_discount,
																	"update_recommended_base_discount": update_recommended_base_discount,
																	"cb_recommended_margin": cb_recommended_margin,
																	"update_recommended_margin": update_recommended_margin,
																	"cb_margin_mode": cb_margin_mode,
																	"update_margin_mode": document.getElementById('update_sales_update_margin_mode').value,
															
																	"cb_specific_vat_rate": cb_specific_vat_rate,
																	"update_specific_vat_rate" : update_specific_vat_rate,

																	"rounding_kind": rounding_kind,
																	"rounding_method": rounding_method,
																	"rounding_value": rounding_value,

																	
																	"cb_new_catalog": cb_new_catalog,
																	"catalog_type": document.getElementById('update_sales_new_catalog_type').value,
																	"catalog_catalog": document.getElementById('update_sales_new_catalog_catalog').value,
																	"catalog_customer": document.getElementById('update_sales_new_catalog_customer').value,

																	"cb_new_sales_condition": cb_new_sales_condition,
																	"sales_condition": document.getElementById('update_sales_new_sales_condition').value,

																	"cb_convert": cb_convert, 
																	"new_currency": new_currency
															},
															async: false,
															callback:function(r)	{ 

																							let inserted = r.message.inserted;
																							let updated = r.message.updated;

                                                                                            silicon_ioi.doctype.ioiPriceUpdatingTool.do_update_sales_refresh();

																							silicon_ioi.doctype.ioiPriceUpdatingTool.sleep_static(200).then(() => {

                                                                                                let nb_selected_rows = 0;

                                                                                                for (var i = 0; i < document.getElementById('update_sales_detail').rows.length; i++) {	

																									if (document.getElementById('update_sales_checked_id_' + i.toString())) {																									
                                                    
																										document.getElementById('update_sales_checked_id_' + i.toString()).checked = false;
														
																										if (selected_lines.indexOf(document.getElementById('update_sales_detail_name_' + i.toString()).value) != -1)
																										{	
																											document.getElementById('update_sales_checked_id_' + i.toString()).checked = true;
																											nb_selected_rows++;
																										}
																									}
                                                                                                }
                                                    
                                                                                       
                                                                                                if (document.getElementById("update_sales_grid_banner"))
                                                                                                {	
                                                                                                    if (nb_selected_rows <= 1) {
                                                                                                        document.getElementById("update_sales_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected sales price (prices updated by contracts have been excluded)")
                                                                                                    }else {
                                                                                                        document.getElementById("update_sales_grid_banner").innerHTML = '<b>' + nb_selected_rows.toString() + '</b> ' + __("selected sales prices (prices updated by contracts have been excluded)")
                                                                                                    }
                                                                                                }

																								let top = 0;

																								let msg = '<label style="position: absolute; top: ' + top.toString() + 'px; left: 12px;"> ' + __('Prices updated with success') + '</label>';
																								top += 25;

																								if (inserted != 0) {
                                                    												msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 12px;"> ' + __('Inserted record(s)') + ' : ' + inserted.toString() + '</label>';
																									top += 25;
																								}

																								if (updated != 0) {
																									msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 12px;"> ' + __('Updated record(s)') + ' : ' + updated.toString() + '</label>';
																									top += 25;
																								}

																								msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';

																								frappe.msgprint({title: __("Message"), message: msg, indicator: "blue"});

																								
																							});

																		}
											});
						
											
										}
	
									}, 
							() => 	{	
									}
			);
		}

	}



}

silicon_ioi.doctype.ioiPriceUpdatingTool = ioiPriceUpdatingTool;
