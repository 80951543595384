frappe.provide("ioi");

import {TabulatorFull as Tabulator, Module as TabulatorModule } from 'tabulator-tables';

ioi.Tabulator = Tabulator;


export default class ioiTabulator extends TabulatorModule {
	constructor(table) {
		super(table)
	}

	async create_update_profile() {
		let me = this

		const values = this.grid_settings_dialog.get_values()
		let selectedProfile = {}
		let columns_layout = {}

		Array.from(this.grid_settings_dialog.fields_dict['selected_profile'].input.options).map(option => {
			if (option.value == values.selected_profile) {
				selectedProfile = option
			}
		})
		
		let new_columns_layout = () => {
			return new Promise((resolve) => {
				let table_layout = this.table.getColumnLayout()

				table_layout.map(column => {
					if (values.columns && values.columns.includes(column.title)) {
						column.visible = false
					} else {
						column.visible = true
					}

					if (column.field == "profiles_settings") {
						const index = table_layout.indexOf(column);
						table_layout.splice(index, 1);
					}
				})
				columns_layout = table_layout
				resolve();
			});
		}

		let get_columns_layout = () => {
			return new Promise((resolve) => {
				frappe.db.get_value('ioi GridRow Profile', { name: `${cur_frm.doctype} • ${this.table.element.id} • ${frappe.session.user} • ${selectedProfile.textContent}` }, ['content']).then(r => {
					let table_layout = JSON.parse(r.message.content)

					table_layout.map(column => {
						if (values.columns && values.columns.includes(column.title)) {
							column.visible = false
						} else {
							column.visible = true
						}
	
						if (column.field == "profiles_settings") {
							const index = table_layout.indexOf(column);
							table_layout.splice(index, 1);
						}
					})

					columns_layout = table_layout
					resolve();
				})
			});
		}

		if ((!selectedProfile.value || selectedProfile.value == "NEW_PROFILE") && values.new_profile_name.toLowerCase() == "default") {
			frappe.msgprint({
				title: __('Notification'),
				indicator: 'red',
				message: __('Please choose another profile name.')
			});
		} else {
			let profile_name = frappe.model.user_settings[cur_frm.doctype][this.table.element.id] && frappe.model.user_settings[cur_frm.doctype][this.table.element.id] != "" ? frappe.model.user_settings[cur_frm.doctype][this.table.element.id].profile_name.split('•') : []
			let selected_profile_name = profile_name.length ? profile_name[profile_name.length - 1].trim() : ""

			if (selected_profile_name == selectedProfile.textContent || selectedProfile.value == "NEW_PROFILE") {
				await new_columns_layout() 
			} else {
				await get_columns_layout()
			}

			frappe.call({
				method: "silicon_ioi.ioi_system.doctype.ioi_gridrow_profile.ioi_gridrow_profile.create_update_profile",
				args: {
					profile_name: !selectedProfile.value || selectedProfile.value == "NEW_PROFILE" ? values.new_profile_name : selectedProfile.textContent,
					doctype: cur_frm.doctype,
					field_name: this.table.element.id,
					columns: columns_layout || [],
					is_public: values.is_public,
					roles: values.roles
				},
				callback: function () {
					me.update_user_settings_for_grid(values, columns_layout)
					me.grid_settings_dialog.hide();
					location.reload()
				}
			});
		}
	}

	onProfileChange(selected_profile) {
		this.grid_settings_dialog.new_profile_name_group.hide();

		if (!selected_profile.value || selected_profile.value == "NEW_PROFILE") {
			this.grid_settings_dialog.fields_dict['columns'].value = []
			this.grid_settings_dialog.fields_dict['roles'].value = []
			this.grid_settings_dialog.fields_dict['is_public'].value = 1
			this.grid_settings_dialog.fields_dict['is_standard'].value = 0
			this.grid_settings_dialog.refresh()
			this.grid_settings_dialog.new_profile_name_group.show();
			this.grid_settings_dialog.columns_group.show()
			this.grid_settings_dialog.roles_group.show()
			this.grid_settings_dialog.custom_actions[0].firstChild.classList.add('d-none')
			this.grid_settings_dialog.$wrapper.find('.btn-modal-primary')[0].innerText = __('Create');
		} else if (selected_profile.value == "DEFAULT") {
				this.grid_settings_dialog.fields_dict['is_public'].value = 1
				this.grid_settings_dialog.fields_dict['is_standard'].value = 1
				this.grid_settings_dialog.refresh()
				this.grid_settings_dialog.columns_group.hide()
				this.grid_settings_dialog.roles_group.hide()
				this.grid_settings_dialog.custom_actions[0].firstChild.classList.add('d-none')
				this.grid_settings_dialog.$wrapper.find('.btn-modal-primary')[0].innerText = __('Update');
		} else {
			this.grid_settings_dialog.$wrapper.find('.btn-modal-primary')[0].innerText = __('Update');
			this.grid_settings_dialog.custom_actions[0].firstChild.classList.remove('d-none')
			this.grid_settings_dialog.columns_group.show()
			this.grid_settings_dialog.roles_group.show()

			frappe.db.get_doc('ioi GridRow Profile', `${cur_frm.doctype} • ${this.table.element.id} • ${frappe.session.user} • ${selected_profile.textContent}`).then(doc => {
				this.grid_settings_dialog.fields_dict['columns'].value = JSON.parse(doc.content).map(col => { if (col.visible == false) { return col.title }}).filter(element => element !== undefined);
				this.grid_settings_dialog.fields_dict['roles'].value = (doc.roles).map(role => role.role)
				this.grid_settings_dialog.fields_dict['is_public'].value = doc.is_public_access
				this.grid_settings_dialog.fields_dict['is_standard'].value = doc.is_standard_access
				this.grid_settings_dialog.refresh()
			})
		}
	}

	update_user_settings_for_grid(values, columns_layout) {
		if (values.selected_profile == "DEFAULT") {
			return frappe.model.user_settings.save(cur_frm.doctype, this.table.element.id, "")
		} else {
			let selectedProfile = {}
			let profile_name = ""
			
			Array.from(this.grid_settings_dialog.fields_dict['selected_profile'].input.options).map(option => {
				if (option.value == values.selected_profile) {
					if (option.value == "NEW_PROFILE") {
						selectedProfile = values.new_profile_name
					} else {
						selectedProfile = option.textContent
					}
				}
			})
			
			profile_name = `${cur_frm.doctype} • ${this.table.element.id} • ${frappe.session.user} • ${selectedProfile}`

			let value = {};
			value['profile_name'] = profile_name
			value['columns'] = columns_layout;

			let profiles_list = frappe.model.user_settings[cur_frm.doctype].GridProfiles ? [...frappe.model.user_settings[cur_frm.doctype].GridProfiles, this.table.element.id] : [this.table.element.id]

			return frappe.model.user_settings.save(cur_frm.doctype, 'GridProfiles', [...new Set(profiles_list)]).then(() => {
				return frappe.model.user_settings.save(cur_frm.doctype, this.table.element.id, value)
			})
		}
	}

	remove_current_profile(profile) {
		frappe.db.delete_doc('ioi GridRow Profile', `${cur_frm.doctype} • ${this.table.element.id} • ${frappe.session.user} • ${profile}`).then(() => {
			frappe.model.user_settings.save(cur_frm.doctype, this.table.element.id, "")
			this.grid_settings_dialog.hide()
			location.reload()
		})
	}

	async configure_dialog_for_columns_selector(gridRow_profiles) {
		let me = this

		// Get all select options
		let selectOptions = []

		selectOptions.push({label: __('Default'), value: 'DEFAULT'})

		gridRow_profiles.map((profile, key) => {
			selectOptions.push({
				label: profile.profile_name,
				value: key
			});
		})
		selectOptions.push({ label: __("Create a new profile..."), value: "NEW_PROFILE" });

		// Get all columns titles
		let all_columns = []
		
		this.table.getColumns().map(col => {
			if (col.getDefinition().title && col.getDefinition().field) {
				all_columns.push(col.getDefinition().title)
			}
		})

		this.grid_settings_dialog = new frappe.ui.Dialog({
			title: __("Configure Columns"),
			fields: [
				{
					'fieldtype': 'Select',
					'fieldname': 'selected_profile',
					'label': __('Select a profile'),
					'options': selectOptions,
				},
				{
					'fieldtype': 'Data',
					'fieldname': 'new_profile_name',
					'label': __('New profile name'),
				},
				{
					'fieldtype': 'MultiSelectPills',
					'fieldname': 'columns',
					'label': __('Hide Columns'),
					'options': all_columns,
					'default': ''
				},
				{
					'fieldtype': 'MultiSelectPills',
					'fieldname': 'roles',
					'label': __('Limit to roles'),
					'depends_on': `eval:frappe.user_roles.includes("System Manager")`,
					get_data: function (txt) {
						return frappe.db.get_link_options("Role", txt, { disabled: 0 });
					},
				},
				{
					"fieldname": "section",
					"fieldtype": "Section Break",
					"label": ""
				},
				{
					"default": "0",
					"fieldname": "is_public",
					"fieldtype": "Check",
					"label": "Is public"
				},
				{
					"default": "0",
					"fieldname": "is_standard",
					"fieldtype": "Check",
					"label": "Is standard",
					'read_only': "1"
				},
				{
					"fieldname": "column_break",
					"fieldtype": "Column Break"
				}
			],
			primary_action_label: __('Update'),
			primary_action(values) {
				if (values.selected_profile == "NEW_PROFILE" && !values.new_profile_name) {
					frappe.msgprint({
						title: __('Notification'),
						indicator: 'red',
						message: __('Please add a profile name !')
					});
				} else if (values.selected_profile == "DEFAULT") {
					me.update_user_settings_for_grid(values)
					me.grid_settings_dialog.hide();
					location.reload()
				} else if (values.columns && values.columns.length == all_columns.length) {
					frappe.msgprint({
						title: __('Notification'),
						indicator: 'red',
						message: __('Please keep at least one column !')
					});
				} else {
					me.create_update_profile()
				}
			}
		});

		this.grid_settings_dialog.add_custom_action(__('Remove'), function () {
			Array.from(me.grid_settings_dialog.fields_dict['selected_profile'].input.options).map(option => {
				if (option.value == me.grid_settings_dialog.fields_dict['selected_profile'].input.selectedOptions[0].value) {
					me.remove_current_profile(option.textContent)
				}
			})
		}, 'btn-danger');

		// Get Saved Profile
		let saved_profile_name = frappe.model.user_settings[cur_frm.doctype][this.table.element.id] ? frappe.model.user_settings[cur_frm.doctype][this.table.element.id].profile_name : ''
		let saved_profile = {}

		let get_saved_profile = async () => {
			return new Promise(resolve => {
				frappe.db.exists('ioi GridRow Profile', saved_profile_name).then(exists => {
					if (exists && saved_profile_name != undefined) {
						frappe.db.get_doc('ioi GridRow Profile', saved_profile_name).then(doc => {
							saved_profile.selected_profile = doc.profile_name
							saved_profile.columns = JSON.parse(doc.content).map(col => { if (col.visible == false) { return col.title }}).filter(element => element !== undefined);
							saved_profile.roles = (doc.roles).map(role => role.role)
							saved_profile.is_public = doc.is_public_access
							saved_profile.is_standard = doc.is_standard_access
							resolve()
						})
					} else {
						frappe.model.user_settings.save(cur_frm.doctype, this.table.element.id, "")
						saved_profile = false
						resolve()
					}
				})
			})
		}

		await get_saved_profile()

		this.grid_settings_dialog.fields_dict['selected_profile'].input.addEventListener('change', (e) => {
			let selectedProfile = ""

			Array.from(e.target.options).map(option => {
				if (option.value == e.target.value) {
					selectedProfile = option
				}
			})

			me.onProfileChange(selectedProfile);
		});

		this.grid_settings_dialog.columns_group = ($(this.grid_settings_dialog.fields_dict.columns)[0].$input.parent().parent().parent().parent().parent())
		this.grid_settings_dialog.roles_group = ($(this.grid_settings_dialog.fields_dict.roles)[0].$input.parent().parent().parent().parent().parent())
		this.grid_settings_dialog.new_profile_name_group = $(me.grid_settings_dialog.fields_dict.new_profile_name)[0].$input.parent().parent().parent();
		this.grid_settings_dialog.new_profile_name_group.hide();
		this.grid_settings_dialog.show();

		// Set Saved values
		if (saved_profile) {
			this.grid_settings_dialog.fields_dict['columns'].value = saved_profile.columns
			this.grid_settings_dialog.fields_dict['roles'].value = saved_profile.roles
			this.grid_settings_dialog.fields_dict['is_public'].value = saved_profile.is_public
			this.grid_settings_dialog.fields_dict['is_standard'].value = saved_profile.is_standard
			this.grid_settings_dialog.refresh()

			Array.from(this.grid_settings_dialog.fields_dict['selected_profile'].input.options).map(option => {
				if (option.textContent == saved_profile.selected_profile) {
					this.grid_settings_dialog.fields_dict['selected_profile'].input.value = option.value
				}
			})
		} else {
			this.grid_settings_dialog.columns_group.hide()
			this.grid_settings_dialog.roles_group.hide()
			this.grid_settings_dialog.fields_dict['is_public'].value = 1
			this.grid_settings_dialog.fields_dict['is_standard'].value = 1
			this.grid_settings_dialog.custom_actions[0].firstChild.classList.add('d-none')
			this.grid_settings_dialog.refresh()
			this.grid_settings_dialog.fields_dict['selected_profile'].input.value = 'DEFAULT'
		}
	}

	hide_selected_columns() {
		let saved_profiles = frappe.model.user_settings[cur_frm.doctype].GridProfiles

		if (saved_profiles && saved_profiles.includes(this.table.element.id)) {
			saved_profiles.map(saved_profile => {
				if (saved_profile == this.table.element.id) {
					let grid_profile = frappe.model.user_settings[cur_frm.doctype][saved_profile] ? frappe.model.user_settings[cur_frm.doctype][saved_profile].profile_name : undefined

					if (grid_profile != undefined) {
						frappe.db.exists('ioi GridRow Profile', grid_profile).then(exists => {
							if (exists) {
								frappe.db.get_value('ioi GridRow Profile', { name: grid_profile }, ['content']).then(r => {
									let columnLayout = JSON.parse(r.message.content)
									this.table.setColumnLayout(columnLayout)

									columnLayout.map(col => {
										if (col.visible == false) {
											this.table.hideColumn(col.field)
										}
									})
								})
							} else {
								frappe.model.user_settings.save(cur_frm.doctype, this.table.element.id, "")
							}
						})
					}
				}
			})
		}
	}

	initialize() {	
		if (this.table.options.showProfiles) {
			this.table.on('tableBuilt', () => {
				this.hide_selected_columns()

				let headerMenu = () => {
					frappe.db.get_list('ioi GridRow Profile', { fields: ['user_name', 'profile_name', 'is_public_access', 'is_standard_access'], filters: { doc_type: cur_frm.doctype, field_name: this.table.element.id, user_name: frappe.session.user } }).then(record => {
						this.configure_dialog_for_columns_selector(record)
					})
				}

				this.table.addColumn({ field: 'profiles_settings', width: 50, headerSort: false, resizable: false }).then((column) => {
					column.updateDefinition({
						headerMenuIcon: `<svg class="icon icon-sm" style="filter: opacity(0.5); margin: 0 5.75px 0 4.25px">
													<use class="" href="#icon-setting-gear"></use>
												</svg>`,
						headerMenu: headerMenu
					})
				})

				this.table.on('renderComplete', () => {
					if (!this.table.getColumn("profiles_settings")) {
						this.table.addColumn({ field: 'profiles_settings', width: 50, headerSort: false, resizable: false }).then((column) => {
							column.updateDefinition({
								headerMenuIcon: `<svg class="icon icon-sm" style="filter: opacity(0.5); margin: 0 5.75px 0 4.25px">
															<use class="" href="#icon-setting-gear"></use>
														</svg>`,
								headerMenu: headerMenu
							})
						})
					}
				})
			})
		}
	}
}

ioiTabulator.moduleName = "custom";
Tabulator.registerModule(ioiTabulator);