frappe.provide('silicon_ioi.doctype');

export class ioiJITEngineFrame
{
	static path_jit_engine_execution_log = 'silicon_ioi.ioi_jit.doctype.ioi_jit_engine_execution_log.ioi_jit_engine_execution_log';
	static jit_engine_scheduling_name = null
	static jit_run_id = null
	static division_id = null
	static tp = '';
	static cdt = null;
	static cdn = null;
	static item_id = null
	static view = 'SEARCH';


	static show_logs(jit_run_id, division_id, jit_engine_scheduling_name = null, tp = '', cdt = null, cdn = null, item_id = null)
	{
		silicon_ioi.doctype.ioiJITEngineFrame.jit_run_id = jit_run_id;
		silicon_ioi.doctype.ioiJITEngineFrame.jit_engine_scheduling_name = jit_engine_scheduling_name;
		silicon_ioi.doctype.ioiJITEngineFrame.tp = tp;
		silicon_ioi.doctype.ioiJITEngineFrame.cdt = cdt;
		silicon_ioi.doctype.ioiJITEngineFrame.cdn = cdn;
		silicon_ioi.doctype.ioiJITEngineFrame.item_id = item_id;
		silicon_ioi.doctype.ioiJITEngineFrame.division_id = division_id;



		var p = new frappe.ui.Dialog({
			'title': __("Show log"),
			'fields': [
				{'fieldname': 'html_jit_engine_scheduling_log', 'fieldtype': 'HTML'}

			],
			primary_action_label:  __('Close'),
			primary_action: function(){
				p.hide();
			},

		});

		if (document.getElementById('html_jit_engine_scheduling_log_site_label')) {
			document.getElementById('html_jit_engine_scheduling_log_site_label').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_site')) {
			document.getElementById('html_jit_engine_scheduling_log_site').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_jit_setting_label')) {
			document.getElementById('html_jit_engine_scheduling_log_jit_setting_label').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_jit_setting')) {
			document.getElementById('html_jit_engine_scheduling_log_jit_setting').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_jit_setting_button')) {
			document.getElementById('html_jit_engine_scheduling_log_jit_setting_button').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_status_label')) {
			document.getElementById('html_jit_engine_scheduling_log_status_label').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_status')) {
			document.getElementById('html_jit_engine_scheduling_log_status').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_description_label')) {
			document.getElementById('html_jit_engine_scheduling_log_description_label').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_description')) {
			document.getElementById('html_jit_engine_scheduling_log_description').remove();
		}


		if (document.getElementById('html_jit_engine_scheduling_log_item_label')) {
			document.getElementById('html_jit_engine_scheduling_log_item_label').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_item')) {
			document.getElementById('html_jit_engine_scheduling_log_item').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_item_button')) {
			document.getElementById('html_jit_engine_scheduling_log_item_button').remove();
		}


		if (document.getElementById('html_jit_engine_scheduling_log_po_label')) {
			document.getElementById('html_jit_engine_scheduling_log_po_label').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_po')) {
			document.getElementById('html_jit_engine_scheduling_log_po').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_po_button')) {
			document.getElementById('html_jit_engine_scheduling_log_po_button').remove();
		}


		if (document.getElementById('html_jit_engine_scheduling_log_pp_label')) {
			document.getElementById('html_jit_engine_scheduling_log_pp_label').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_pp')) {
			document.getElementById('html_jit_engine_scheduling_log_pp').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_pp_button')) {
			document.getElementById('html_jit_engine_scheduling_log_pp_button').remove();
		}


		if (document.getElementById('html_jit_engine_scheduling_log_so_label')) {
			document.getElementById('html_jit_engine_scheduling_log_so_label').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_so')) {
			document.getElementById('html_jit_engine_scheduling_log_so').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_so_button')) {
			document.getElementById('html_jit_engine_scheduling_log_so_button').remove();
		}


		if (document.getElementById('html_jit_engine_scheduling_log_prod_label')) {
			document.getElementById('html_jit_engine_scheduling_log_prod_label').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_prod')) {
			document.getElementById('html_jit_engine_scheduling_log_prod').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_prod_button')) {
			document.getElementById('html_jit_engine_scheduling_log_prod_button').remove();
		}


		if (document.getElementById('html_jit_engine_scheduling_log_dossier_label')) {
			document.getElementById('html_jit_engine_scheduling_log_dossier_label').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_dossier')) {
			document.getElementById('html_jit_engine_scheduling_log_dossier').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_dossier_button')) {
			document.getElementById('html_jit_engine_scheduling_log_dossier_button').remove();
		}


		if (document.getElementById('html_jit_engine_scheduling_log_search')) {
			document.getElementById('html_jit_engine_scheduling_log_search').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_to_handle')) {
			document.getElementById('html_jit_engine_scheduling_log_to_handle').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_no_scheduled')) {
			document.getElementById('html_jit_engine_scheduling_log_no_scheduled').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_in_late')) {
			document.getElementById('html_jit_engine_scheduling_log_in_late').remove();
		}


		if (document.getElementById('html_jit_engine_scheduling_log_auto_refresh')) {
			document.getElementById('html_jit_engine_scheduling_log_auto_refresh').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_auto_refresh_label')) {
			document.getElementById('html_jit_engine_scheduling_log_auto_refresh_label').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_auto_refresh_value')) {
			document.getElementById('html_jit_engine_scheduling_log_auto_refresh_value').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_auto_refresh_label2')) {
			document.getElementById('html_jit_engine_scheduling_log_auto_refresh_label2').remove();
		}



		if (document.getElementById('html_jit_engine_scheduling_log_result_title')) {
			document.getElementById('html_jit_engine_scheduling_log_result_title').remove();
		}





		if (document.getElementById('html_jit_engine_scheduling_log_result_content')) {
			document.getElementById('html_jit_engine_scheduling_log_result_content').remove();
		}


		if (document.getElementById('html_jit_engine_scheduling_log_result_content_to_handle')) {
			document.getElementById('html_jit_engine_scheduling_log_result_content_to_handle').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_result_content_no_scheduled')) {
			document.getElementById('html_jit_engine_scheduling_log_result_content_no_scheduled').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_result_content_in_late')) {
			document.getElementById('html_jit_engine_scheduling_log_result_content_in_late').remove();
		}


		if (document.getElementById('html_jit_engine_scheduling_log_result_content_download')) {
			document.getElementById('html_jit_engine_scheduling_log_result_content_download').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_result_exceptions')) {
			document.getElementById('html_jit_engine_scheduling_log_result_exceptions').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_result_unresolved')) {
			document.getElementById('html_jit_engine_scheduling_log_result_unresolved').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_result_intersite_rejected')) {
			document.getElementById('html_jit_engine_scheduling_log_result_intersite_rejected').remove();
		}


		if (document.getElementById('html_jit_engine_scheduling_log_download_xlsx')) {
			document.getElementById('html_jit_engine_scheduling_log_download_xlsx').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_log_exceptions')) {
			document.getElementById('html_jit_engine_scheduling_log_exceptions').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_unresolved_items')) {
			document.getElementById('html_jit_engine_scheduling_unresolved_items').remove();
		}

		if (document.getElementById('html_jit_engine_scheduling_intersite_rejected_items')) {
			document.getElementById('html_jit_engine_scheduling_intersite_rejected_items').remove();
		}





		if (document.getElementById('html_jit_engine_scheduling_log_nb_document')) {
			document.getElementById('html_jit_engine_scheduling_log_nb_document').remove();
		}







		let html = '';

		html += '<div  data-custom-section-head="true" style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '	<label style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Filters") + '</b></label>';
		html += '</div>'

		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'

		html += '<div style="overflow: auto; overflow-x: auto; height:255px; width: 100%;">';

		// Site
		html += '	<div style="position: relative; top: 0px; left: 2px; width:170px;">';
		html += '		<label id="html_jit_engine_scheduling_log_site_label" style="position: absolute; top: 0px; left: 0px;z-index:5;">' + __("Site") + '</label>';

		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '			<select id="html_jit_engine_scheduling_log_site" class="input-with-feedback form-control bold"> ';
		html += '				<option value="-1">' + __('All') + '</option> ';

		let method = silicon_ioi.doctype.ioiJITEngineFrame.path_jit_engine_execution_log + '.ioi_jit_engine_execution_log_get_sites';

		frappe.call({  	method: method,
						args: {"division_id": silicon_ioi.doctype.ioiJITEngineFrame.division_id},
						async: false,
						callback:function(r)	{
													for (var i = 0; i < r.message.length; i++)
													{
														html += '		<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
													}
												}
		});

		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';


		// JIT Setting
		html += '	<div style="position: relative; top: 0px; left: 182px; width:240px;">';
		html += '		<label id="html_jit_engine_scheduling_log_jit_setting_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("JIT Setting") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 240px; height: 25px;"> ';
		html += '			<input id="html_jit_engine_scheduling_log_jit_setting" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value="">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 250px; width: 30px; height: 25px;"> ';
		html +='			<button id="html_jit_engine_scheduling_log_jit_setting_button" style="position:absolute; height: 30px; width: 30px; z-index:10;border:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';


		// Status
		html += '	<div style="position: relative; top: 0px; left: 472px; width:170px;">';
		html += '		<label id="html_jit_engine_scheduling_log_status_label" style="position: absolute; top: 0px; left: 0px;z-index:5;">' + __("Status") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '			<select id="html_jit_engine_scheduling_log_status" class="input-with-feedback form-control bold"> ';
		html += '				<option value="-1">' + __('All') + '</option> ';
		html += '				<option value="0">' + __('Success') + '</option> ';
		html += '				<option value="1">' + __('Warning') + '</option> ';
		html += '				<option value="2">' + __('Error') + '</option> ';
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		// Description
		html += '	<div style="position: relative; top: 0px; left: 652px; width:500px;">';
		html += '		<label id="html_jit_engine_scheduling_log_description_label" style="position: absolute; top: 0px; left: 0px;z-index:5;">' + __("Description") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 500px; height: 25px;"> ';
		html += '			<input id="html_jit_engine_scheduling_log_description" type="text" class="input-with-feedback form-control bold" value="">';
		html += '		</div>';
		html += '	</div>';


		// Item
		html += '	<div style="position: relative; top: 65px; left: 2px; width:240px;">';
		html += '		<label id="html_jit_engine_scheduling_log_item_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Item") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 240px; height: 25px;"> ';
		html += '			<input id="html_jit_engine_scheduling_log_item" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value="">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 250px; width: 30px; height: 25px;"> ';
		html +='			<button id="html_jit_engine_scheduling_log_item_button" style="position:absolute; height: 30px; width: 30px; z-index:10;border:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Purchase Order
		html += '	<div style="position: relative; top: 65px; left: 292px; width:240px;">';
		html += '		<label id="html_jit_engine_scheduling_log_po_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Purchases Order") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 240px; height: 25px;"> ';
		html += '			<input id="html_jit_engine_scheduling_log_po" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value="">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 250px; width: 30px; height: 25px;"> ';
		html +='			<button id="html_jit_engine_scheduling_log_po_button" style="position:absolute; height: 30px; width: 30px; z-index:10;border:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Purchase Proposal
		html += '	<div style="position: relative; top: 65px; left: 582px; width:240px;">';
		html += '		<label id="html_jit_engine_scheduling_log_pp_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Purchases Proposal") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 240px; height: 25px;"> ';
		html += '			<input id="html_jit_engine_scheduling_log_pp" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value="">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 250px; width: 30px; height: 25px;"> ';
		html +='			<button id="html_jit_engine_scheduling_log_pp_button" style="position:absolute; height: 30px; width: 30px; z-index:10;border:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';


		// Sales Order
		html += '	<div style="position: relative; top: 65px; left: 872px; width:240px;">';
		html += '		<label id="html_jit_engine_scheduling_log_so_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Sales Order") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 240px; height: 25px;"> ';
		html += '			<input id="html_jit_engine_scheduling_log_so" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value="">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 250px; width: 30px; height: 25px;"> ';
		html +='			<button id="html_jit_engine_scheduling_log_so_button" style="position:absolute; height: 30px; width: 30px; z-index:10;border:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Search
		html += '	<div style="position: relative; top: 130px; left: 2px; width:100px;">';
		html += '		<div  style="position: absolute; top: 25px; left: 0px; width: 100px; height: 25px;"> ';
		html +='			<button id="html_jit_engine_scheduling_log_search" style="position:absolute; height: 30px; width: 100px; z-index:10;" class="btn btn-default ellipsis" onclick="">' + __("JIT logs") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// To handle
		html += '	<div style="position: relative; top: 130px; left: 112px; width:130px;">';
		html += '		<div  style="position: absolute; top: 25px; left: 0px; width: 130px; height: 25px;"> ';
		html +='			<button id="html_jit_engine_scheduling_log_to_handle" style="position:absolute; height: 30px; width: 130px; z-index:10;display:none;" class="btn btn-default ellipsis" onclick="">' + __("To handle") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Without scheduled dt
		html += '	<div style="position: relative; top: 130px; left: 255px; width:200px;">';
		html += '		<div  style="position: absolute; top: 25px; left: 0px; width: 200px; height: 25px;"> ';
		html +='			<button id="html_jit_engine_scheduling_log_no_scheduled" style="position:absolute; height: 30px; width: 200px; z-index:10;display:none;" class="btn btn-default ellipsis" onclick="">' + __("To handle (immediate)") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Late
		html += '	<div style="position: relative; top: 130px; left: 465px; width:110px;">';
		html += '		<div  style="position: absolute; top: 25px; left: 0px; width: 110px; height: 25px;"> ';
		html +='			<button id="html_jit_engine_scheduling_log_in_late" style="position:absolute; height: 30px; width: 110px; z-index:10;display:none;" class="btn btn-default ellipsis" onclick="">' + __("In late") + '</button>';
		html += '		</div>';
		html += '	</div>';



		// Production
		html += '	<div style="position: relative; top: 130px; left: 582px; width:240px;">';
		html += '		<label id="html_jit_engine_scheduling_log_prod_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Production") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 240px; height: 25px;"> ';
		html += '			<input id="html_jit_engine_scheduling_log_prod" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value="">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 250px; width: 30px; height: 25px;"> ';
		html +='			<button id="html_jit_engine_scheduling_log_prod_button" style="position:absolute; height: 30px; width: 30px; z-index:10;border:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Dossier
		html += '	<div style="position: relative; top: 130px; left: 872px; width:240px;">';
		html += '		<label id="html_jit_engine_scheduling_log_dossier_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Dossier") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 240px; height: 25px;"> ';
		html += '			<input id="html_jit_engine_scheduling_log_dossier" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value="">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 250px; width: 30px; height: 25px;"> ';
		html +='			<button id="html_jit_engine_scheduling_log_dossier_button" style="position:absolute; height: 30px; width: 30px; z-index:10;border:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Auto resfresh
		html += '	<div style="position: relative; top: 195px; left: 2px; width:240px;">';
		html += '		<div class="control-input" style="position: absolute; top: 12px; left: 2px; width: 240px; height: 25px;"> ';
		html += '			<input id="html_jit_engine_scheduling_log_auto_refresh" type="checkbox" class="input-with-feedback form-control bold" checked>';
		html += '		</div>';

		html += '		<label id="html_jit_engine_scheduling_log_auto_refresh_label" style="position: absolute; top: 10px; left: 25px;z-index:5;">' + __("Refresh every") + '</label>';

		html += '		<div class="control-input" style="position: absolute; top: 4px; left: 150px; width: 90px; height: 25px;"> ';
		html += '			<input id="html_jit_engine_scheduling_log_auto_refresh_value" type="number" step="any" class="input-with-feedback form-control bold" style="" value="5">';
		html += '		</div>';

		html += '		<label id="html_jit_engine_scheduling_log_auto_refresh_label2" style="position: absolute; top: 10px; left: 252px;z-index:5;">' + __("secondes") + '</label>';
		html += '	</div>';

		// Exceptions list
		html += '	<div style="position: relative; top: 195px; left: 352px; width:225px;">';
		html += '		<div  style="position: absolute; top: 6px; left: 0px; width: 225px; height: 25px;"> ';
		html +='			<button id="html_jit_engine_scheduling_log_exceptions" style="position:absolute; height: 30px; width: 225px; z-index:10;display:none;" class="btn btn-default ellipsis" onclick="">' + __("Exceptions list") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Unresolved items

		html += '	<div style="position: relative; top: 195px; left: 587px; width:225px;">';
		html += '		<div  style="position: absolute; top: 6px; left: 0px; width: 225px; height: 25px;"> ';
		html +='			<button id="html_jit_engine_scheduling_unresolved_items" style="position:absolute; height: 30px; width: 225px; z-index:10;display:none;" class="btn btn-default ellipsis" onclick="">' + __("Rejected items / documents") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Intersite rejected items

		html += '	<div style="position: relative; top: 195px; left: 824px; width:225px;">';
		html += '		<div  style="position: absolute; top: 6px; left: 0px; width: 225px; height: 25px;"> ';
		html +='			<button id="html_jit_engine_scheduling_intersite_rejected_items" style="position:absolute; height: 30px; width: 225px; z-index:10;display:none;" class="btn btn-default ellipsis" onclick="">' + __("Intersite rejected items") + '</button>';
		html += '		</div>';
		html += '	</div>';








		html += '</div>';

		html += '<div  data-custom-section-head="true" style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '	<label id="html_jit_engine_scheduling_log_result_title" style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("JIT Logs") + '</b></label>';
		html += '</div>'

		html += '<div id="html_jit_engine_scheduling_log_result_content" class="table table-bordered" data-custom-grid="true" style="height:400px; border-radius:6px; padding: 4px;display:block;">';
		html += '</div>';

		html += '<div id="html_jit_engine_scheduling_log_result_content_to_handle" class="table table-bordered" data-custom-grid="true" style="height:400px; border-radius:6px; padding: 4px;display:none">';
		html += '</div>';

		html += '<div id="html_jit_engine_scheduling_log_result_content_no_scheduled" class="table table-bordered" data-custom-grid="true" style="height:400px; border-radius:6px; padding: 4px;display:none">';
		html += '</div>';

		html += '<div id="html_jit_engine_scheduling_log_result_content_in_late" class="table table-bordered" data-custom-grid="true" style="height:400px; border-radius:6px; padding: 4px;display:none">';
		html += '</div>';

		html += '<div id="html_jit_engine_scheduling_log_result_exceptions" class="table table-bordered" data-custom-grid="true" style="height:400px; border-radius:6px; padding: 4px;display:none">';
		html += '</div>';

		html += '<div id="html_jit_engine_scheduling_log_result_unresolved" class="table table-bordered" data-custom-grid="true" style="height:400px; border-radius:6px; padding: 4px;display:none">';
		html += '</div>';

		html += '<div id="html_jit_engine_scheduling_log_result_intersite_rejected" class="table table-bordered" data-custom-grid="true" style="height:400px; border-radius:6px; padding: 4px;display:none">';
		html += '</div>';




		html += '<div id="html_jit_engine_scheduling_log_result_content_download" style="overflow: auto; overflow-x: auto; height:40px; width: 100%; display: none;">';

		// Download
		html += '	<div style="position: relative; top: 0px; left: 2px; width:100px;">';
		html += '		<div  style="position: absolute; top: 2px; left: 0px; width: 100px; height: 25px;"> ';
		html +='			<button id="html_jit_engine_scheduling_log_download_xlsx" style="position:absolute; height: 30px; width: 100px; z-index:10;" class="btn btn-default ellipsis" onclick="">' + __("Download") + '</button>';
		html += '		</div>';
		html += '	</div>';


		html += '	<div style="position: relative; top: 8px; left: 112px; width:500px">';
		html += '		<label id="html_jit_engine_scheduling_log_nb_document" style="position: absolute; top: 0px; left: 2px;z-index:5;"></label>';
		html += '	</div>';

		html += '</div>';


		p.fields_dict.html_jit_engine_scheduling_log.$wrapper.html(html);

		let form_width = 1210;

		p.$wrapper.find('.modal-dialog').css("max-width", form_width.toString() + "px").css("width", form_width.toString() + "px");
		p.show();


		silicon_ioi.doctype.ioiJITEngineFrame.sleep_static(200).then(() => {

			if (silicon_ioi.doctype.ioiJITEngineFrame.tp == 'SETTING') {

				let line = locals[silicon_ioi.doctype.ioiJITEngineFrame.cdt][silicon_ioi.doctype.ioiJITEngineFrame.cdn];
				document.getElementById('html_jit_engine_scheduling_log_jit_setting').value = line.jit_engine_setting_id;
				document.getElementById('html_jit_engine_scheduling_log_jit_setting').disabled = true;
				document.getElementById('html_jit_engine_scheduling_log_jit_setting_button').disabled = true;
			}else if (silicon_ioi.doctype.ioiJITEngineFrame.tp == 'ITEM') {
				document.getElementById('html_jit_engine_scheduling_log_item').value = silicon_ioi.doctype.ioiJITEngineFrame.item_id;
				document.getElementById('html_jit_engine_scheduling_log_item').disabled = true;
				document.getElementById('html_jit_engine_scheduling_log_item_button').disabled = true;
			}



			let fct_change = function() {

				if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'SEARCH') {

					silicon_ioi.doctype.ioiJITEngineFrame.show_logs_refresh();

				}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'TO_HANDLE') {

					silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('TO_HANDLE');

				}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'NO_SCHEDULED') {

					silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('NO_SCHEDULED');

				}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'IN_LATE') {

					silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('IN_LATE');

				}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'EXCEPTIONS') {
					silicon_ioi.doctype.ioiJITEngineFrame.show_exceptions_list()
				}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'UNRESOLVED_ITEMS') {
					silicon_ioi.doctype.ioiJITEngineFrame.unresolved_items();
				}else{
					silicon_ioi.doctype.ioiJITEngineFrame.intersite_rejected_items();
				}


			}

			document.getElementById('html_jit_engine_scheduling_log_site').onchange = fct_change;


			let fct_keydown = function(event) {

				if (event.keyCode == 13) {

					if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'SEARCH') {

						silicon_ioi.doctype.ioiJITEngineFrame.show_logs_refresh();

					}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'TO_HANDLE') {

						silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('TO_HANDLE');

					}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'NO_SCHEDULED') {

						silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('NO_SCHEDULED');

					}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'IN_LATE') {

						silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('IN_LATE');
					}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'EXCEPTIONS') {
						silicon_ioi.doctype.ioiJITEngineFrame.show_exceptions_list()
					}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'UNRESOLVED_ITEMS') {
						silicon_ioi.doctype.ioiJITEngineFrame.unresolved_items();
					}else{
						silicon_ioi.doctype.ioiJITEngineFrame.intersite_rejected_items();

					}




					return false;
				}
			}


			document.getElementById('html_jit_engine_scheduling_log_jit_setting').onkeydown = fct_keydown

			let fct_select_jit_settings = function() {

				let title = __("Select a jit settings");
				let form_width_pixel = '710px';
				let table = "ioi JIT Engine Settings";
				let fields = 'name, description';
				let fields_len = '250, 380';
				let fields_desc = 'Identification, Description';
				let where = "enabled = 1 and division_id = '" + silicon_ioi.doctype.ioiJITEngineFrame.division_id.replaceAll("'", "''") + "' ";
				let order_by = 'name asc';

				let fct_callback = function (return_value) {

					document.getElementById("html_jit_engine_scheduling_log_jit_setting").value = return_value;
					document.getElementById('html_jit_engine_scheduling_log_jit_setting').focus();

					if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'SEARCH') {

						silicon_ioi.doctype.ioiJITEngineFrame.show_logs_refresh();

					}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'TO_HANDLE') {

						silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('TO_HANDLE');

					}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'NO_SCHEDULED') {

						silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('NO_SCHEDULED');

					}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'IN_LATE') {

						silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('IN_LATE');
					}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'EXCEPTIONS') {
						silicon_ioi.doctype.ioiJITEngineFrame.show_exceptions_list()
					}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'UNRESOLVED_ITEMS') {
						silicon_ioi.doctype.ioiJITEngineFrame.unresolved_items();
					}else{
						silicon_ioi.doctype.ioiJITEngineFrame.intersite_rejected_items();
					}


				}

				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

			}

			document.getElementById('html_jit_engine_scheduling_log_jit_setting_button').onclick = fct_select_jit_settings;
			document.getElementById('html_jit_engine_scheduling_log_jit_setting_button').onkeydown = fct_keydown;


			document.getElementById('html_jit_engine_scheduling_log_status').onchange = fct_change;

			document.getElementById('html_jit_engine_scheduling_log_description').onkeydown = fct_keydown

			let fct_search = function() {
				document.getElementById('html_jit_engine_scheduling_log_result_content').style.display = 'block';
				document.getElementById('html_jit_engine_scheduling_log_result_content_to_handle').style.display = 'none';
				document.getElementById('html_jit_engine_scheduling_log_result_content_no_scheduled').style.display = 'none';
				document.getElementById('html_jit_engine_scheduling_log_result_content_in_late').style.display = 'none';
				document.getElementById('html_jit_engine_scheduling_log_result_exceptions').style.display = 'none';
				document.getElementById('html_jit_engine_scheduling_log_result_unresolved').style.display = 'none';
				document.getElementById('html_jit_engine_scheduling_log_result_intersite_rejected').style.display = 'none';




				silicon_ioi.doctype.ioiJITEngineFrame.view = 'SEARCH';

				if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'SEARCH') {

					silicon_ioi.doctype.ioiJITEngineFrame.show_logs_refresh();

				}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'TO_HANDLE') {

					silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('TO_HANDLE');

				}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'NO_SCHEDULED') {

					silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('NO_SCHEDULED');

				}else {

					silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('IN_LATE');
				}
			}

			document.getElementById('html_jit_engine_scheduling_log_search').onclick = fct_search;
			document.getElementById('html_jit_engine_scheduling_log_search').onkeydown = fct_keydown;



			let fct_to_handle = function() {

				document.getElementById('html_jit_engine_scheduling_log_result_content').style.display = 'none';
				document.getElementById('html_jit_engine_scheduling_log_result_content_to_handle').style.display = 'block';
				document.getElementById('html_jit_engine_scheduling_log_result_content_no_scheduled').style.display = 'none';
				document.getElementById('html_jit_engine_scheduling_log_result_content_in_late').style.display = 'none';
				document.getElementById('html_jit_engine_scheduling_log_result_exceptions').style.display = 'none';
				document.getElementById('html_jit_engine_scheduling_log_result_unresolved').style.display = 'none';
				document.getElementById('html_jit_engine_scheduling_log_result_intersite_rejected').style.display = 'none';



				silicon_ioi.doctype.ioiJITEngineFrame.view = 'TO_HANDLE';


				if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'SEARCH') {

					silicon_ioi.doctype.ioiJITEngineFrame.show_logs_refresh();

				}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'TO_HANDLE') {

					silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('TO_HANDLE');

				}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'NO_SCHEDULED') {

					silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('NO_SCHEDULED');

				}else {

					silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('IN_LATE');
				}
			}

			document.getElementById('html_jit_engine_scheduling_log_to_handle').onclick = fct_to_handle;
			document.getElementById('html_jit_engine_scheduling_log_to_handle').onkeydown = fct_keydown;


			let fct_no_scheduled = function() {

				document.getElementById('html_jit_engine_scheduling_log_result_content').style.display = 'none';
				document.getElementById('html_jit_engine_scheduling_log_result_content_to_handle').style.display = 'none';
				document.getElementById('html_jit_engine_scheduling_log_result_content_no_scheduled').style.display = 'block';
				document.getElementById('html_jit_engine_scheduling_log_result_content_in_late').style.display = 'none';
				document.getElementById('html_jit_engine_scheduling_log_result_exceptions').style.display = 'none';
				document.getElementById('html_jit_engine_scheduling_log_result_unresolved').style.display = 'none';
				document.getElementById('html_jit_engine_scheduling_log_result_intersite_rejected').style.display = 'none';



				silicon_ioi.doctype.ioiJITEngineFrame.view = 'NO_SCHEDULED';


				if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'SEARCH') {

					silicon_ioi.doctype.ioiJITEngineFrame.show_logs_refresh();

				}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'TO_HANDLE') {

					silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('TO_HANDLE');

				}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'NO_SCHEDULED') {

					silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('NO_SCHEDULED');

				}else {

					silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('IN_LATE');
				}
			}

			document.getElementById('html_jit_engine_scheduling_log_no_scheduled').onclick = fct_no_scheduled;
			document.getElementById('html_jit_engine_scheduling_log_no_scheduled').onkeydown = fct_keydown;


			let fct_in_late = function() {

				document.getElementById('html_jit_engine_scheduling_log_result_content').style.display = 'none';
				document.getElementById('html_jit_engine_scheduling_log_result_content_to_handle').style.display = 'none';
				document.getElementById('html_jit_engine_scheduling_log_result_content_no_scheduled').style.display = 'none';
				document.getElementById('html_jit_engine_scheduling_log_result_content_in_late').style.display = 'block';
				document.getElementById('html_jit_engine_scheduling_log_result_exceptions').style.display = 'none';
				document.getElementById('html_jit_engine_scheduling_log_result_unresolved').style.display = 'none';
				document.getElementById('html_jit_engine_scheduling_log_result_intersite_rejected').style.display = 'none';



				silicon_ioi.doctype.ioiJITEngineFrame.view = 'IN_LATE';


				if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'SEARCH') {

					silicon_ioi.doctype.ioiJITEngineFrame.show_logs_refresh();

				}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'TO_HANDLE') {

					silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('TO_HANDLE');

				}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'NO_SCHEDULED') {

					silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('NO_SCHEDULED');

				}else {
					silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('IN_LATE');
				}

			}

			document.getElementById('html_jit_engine_scheduling_log_in_late').onclick = fct_in_late;
			document.getElementById('html_jit_engine_scheduling_log_in_late').onkeydown = fct_keydown;



			document.getElementById('html_jit_engine_scheduling_log_item').onkeydown = fct_keydown;

			let fct_select_item = function () {

				let fields = 'a.name, a.description, a.unit_id, b.q_stock, b.q_reserved, a.manufacturer_ref';
				let fields_len = '300, 500, 125, 150, 150, 300';
				let fields_desc = 'Identification, Description, Unit, Stock qty, Reserved qty, Manufacturer Catalog';
				let where = 'ioistatus = 2';
				let order_by = 'a.name asc';
				let parameter_with_stock = false;
				let site_id = '';

				let fct_callback = function (return_value) {

					document.getElementById("html_jit_engine_scheduling_log_item").value = return_value;
					document.getElementById('html_jit_engine_scheduling_log_item').focus();


					if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'SEARCH') {

						silicon_ioi.doctype.ioiJITEngineFrame.show_logs_refresh();

					}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'TO_HANDLE') {

						silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('TO_HANDLE');

					}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'NO_SCHEDULED') {

						silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('NO_SCHEDULED');

					}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'IN_LATE') {

						silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('IN_LATE');
					}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'EXCEPTIONS') {
						silicon_ioi.doctype.ioiJITEngineFrame.show_exceptions_list()
					}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'UNRESOLVED_ITEMS') {
						silicon_ioi.doctype.ioiJITEngineFrame.unresolved_items();
					}else{
						silicon_ioi.doctype.ioiJITEngineFrame.intersite_rejected_items();
					}


				};


				silicon_ioi.ioiCommon.select_item(fields, fields_len, fields_desc, where, order_by, parameter_with_stock, site_id, fct_callback);

			}

			document.getElementById('html_jit_engine_scheduling_log_item_button').onclick = fct_select_item;
			document.getElementById('html_jit_engine_scheduling_log_item_button').onkeydown = fct_keydown;




			document.getElementById('html_jit_engine_scheduling_log_po').onkeydown = fct_keydown;

			let fct_select_po = function() {

				let title = __("Select a purchases order");
				let form_width_pixel = '820px';
				let table = "ioi Purchases Order";
				let fields = 'name, order_supplier_id, document_date, total_htva';
				let fields_len = '250, 250, 120, 120';
				let fields_desc = 'Identification, Supplier, Doc. date, Total w/o VAT';
				let where = "division_id = '" + silicon_ioi.doctype.ioiJITEngineFrame.division_id.replaceAll("'", "''") + "' ";

				let order_by = 'name asc';

				let fct_callback = function (return_value) {

					document.getElementById("html_jit_engine_scheduling_log_po").value = return_value;
					document.getElementById('html_jit_engine_scheduling_log_po').focus();


					if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'SEARCH') {

						silicon_ioi.doctype.ioiJITEngineFrame.show_logs_refresh();

					}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'TO_HANDLE') {

						silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('TO_HANDLE');

					}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'NO_SCHEDULED') {

						silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('NO_SCHEDULED');

					}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'EXCEPTIONS') {
						silicon_ioi.doctype.ioiJITEngineFrame.show_exceptions_list()
					}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'UNRESOLVED_ITEMS') {
						silicon_ioi.doctype.ioiJITEngineFrame.unresolved_items();
					}else{
						silicon_ioi.doctype.ioiJITEngineFrame.intersite_rejected_items();
					}


				}

				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

			}

			document.getElementById('html_jit_engine_scheduling_log_po_button').onclick = fct_select_po;
			document.getElementById('html_jit_engine_scheduling_log_po_button').onkeydown = fct_keydown;




			document.getElementById('html_jit_engine_scheduling_log_pp').onkeydown = fct_keydown;

			let fct_select_pp = function() {

				let title = __("Select a purchases proposal");
				let form_width_pixel = '760px';
				let table = "ioi Purchases Proposal Internal";
				let fields = 'name, site_id, sequence_no, supplier_id, unit_price';
				let fields_len = '100, 100, 120, 250, 120';
				let fields_desc = 'Name, Site, No, Supplier, Unit price';
				let where = "";

				let order_by = 'name asc';

				let fct_callback = function (return_value) {

					let method = silicon_ioi.doctype.ioiJITEngineFrame.path_jit_engine_execution_log + '.ioi_jit_engine_get_pp_data';

					frappe.call({  	method: method,
									args: {"name": return_value},
									async: false,
									callback:function(r)	{

										if (r.message.length > 0) {
											document.getElementById("html_jit_engine_scheduling_log_pp").value = r.message[0].id;
											document.getElementById('html_jit_engine_scheduling_log_pp').focus();
										}
									}
					});


					if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'SEARCH') {

						silicon_ioi.doctype.ioiJITEngineFrame.show_logs_refresh();

					}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'TO_HANDLE') {

						silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('TO_HANDLE');

					}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'NO_SCHEDULED') {

						silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('NO_SCHEDULED');

					}else {

						silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('IN_LATE');
					}

				}

				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

			}

			document.getElementById('html_jit_engine_scheduling_log_pp_button').onclick = fct_select_pp;
			document.getElementById('html_jit_engine_scheduling_log_pp_button').onkeydown = fct_keydown;



			document.getElementById('html_jit_engine_scheduling_log_so').onkeydown = fct_keydown;

			let fct_select_so = function() {

				let title = __("Select a sales order");
				let form_width_pixel = '820px';
				let table = "ioi Sales Order";
				let fields = 'name, order_customer_id, document_date, total_htva';
				let fields_len = '250, 250, 120, 120';
				let fields_desc = 'Identification, Customer, Doc. date, Total w/o VAT';
				let where = "division_id = '" + silicon_ioi.doctype.ioiJITEngineFrame.division_id.replaceAll("'", "''") + "' ";

				let order_by = 'name asc';

				let fct_callback = function (return_value) {

					document.getElementById("html_jit_engine_scheduling_log_so").value = return_value;
					document.getElementById('html_jit_engine_scheduling_log_so').focus();


					if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'SEARCH') {

						silicon_ioi.doctype.ioiJITEngineFrame.show_logs_refresh();

					}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'TO_HANDLE') {

						silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('TO_HANDLE');

					}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'NO_SCHEDULED') {

						silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('NO_SCHEDULED');

					}else {

						silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('IN_LATE');
					}
				}

				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

			}

			document.getElementById('html_jit_engine_scheduling_log_so_button').onclick = fct_select_so;
			document.getElementById('html_jit_engine_scheduling_log_so_button').onkeydown = fct_keydown;




			document.getElementById('html_jit_engine_scheduling_log_prod').onkeydown = fct_keydown;

			let fct_select_prod = function() {

				let title = __("Select a production");
				let form_width_pixel = '1120px';
				let table = "ioi Production";
				let fields = 'name, item_id, item_description, document_date, ordered_qty';
				let fields_len = '250, 250, 300, 120, 120';
				let fields_desc = 'Identification, Item, Description, Doc. date, Ordered qty';
				let where = "division_id = '" + silicon_ioi.doctype.ioiJITEngineFrame.division_id.replaceAll("'", "''") + "' and parent_id is null ";

				let order_by = 'name asc';

				let fct_callback = function (return_value) {

					document.getElementById("html_jit_engine_scheduling_log_prod").value = return_value;
					document.getElementById('html_jit_engine_scheduling_log_prod').focus();


					if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'SEARCH') {

						silicon_ioi.doctype.ioiJITEngineFrame.show_logs_refresh();

					}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'TO_HANDLE') {

						silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('TO_HANDLE');

					}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'NO_SCHEDULED') {

						silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('NO_SCHEDULED');

					}else {

						silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('IN_LATE');
					}
				}

				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

			}

			document.getElementById('html_jit_engine_scheduling_log_prod_button').onclick = fct_select_prod;
			document.getElementById('html_jit_engine_scheduling_log_prod_button').onkeydown = fct_keydown;




			document.getElementById('html_jit_engine_scheduling_log_dossier').onkeydown = fct_keydown;

			let fct_select_dossier = function() {

				let title = __("Select a dossier");
				let form_width_pixel = '950px';
				let table = "ioi Dossier";
				let fields = 'name, description, order_customer_id, document_date';
				let fields_len = '250, 250, 250, 120';
				let fields_desc = 'Identification, Description, Customer, Doc. date';
				let where = "division_id = '" + silicon_ioi.doctype.ioiJITEngineFrame.division_id.replaceAll("'", "''") + "' ";

				let order_by = 'name asc';

				let fct_callback = function (return_value) {

					document.getElementById("html_jit_engine_scheduling_log_dossier").value = return_value;
					document.getElementById('html_jit_engine_scheduling_log_dossier').focus();


					if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'SEARCH') {

						silicon_ioi.doctype.ioiJITEngineFrame.show_logs_refresh();

					}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'TO_HANDLE') {

						silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('TO_HANDLE');


					}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'NO_SCHEDULED') {

						silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('NO_SCHEDULED');

					}else {
						silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('IN_LATE');
					}
				}

				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

			}

			document.getElementById('html_jit_engine_scheduling_log_dossier_button').onclick = fct_select_dossier;
			document.getElementById('html_jit_engine_scheduling_log_dossier_button').onkeydown = fct_keydown;

			let fct_download_xlsx = function() {

				if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'SEARCH') {
					silicon_ioi.doctype.ioiJITEngineFrame.ioiTable.download("xlsx", "data.xlsx", {});
				}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'TO_HANDLE') {
					silicon_ioi.doctype.ioiJITEngineFrame.ioiTableToHandle.download("xlsx", "data.xlsx", {});
				}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'NO_SCHEDULED') {
					silicon_ioi.doctype.ioiJITEngineFrame.ioiTableNoScheduled.download("xlsx", "data.xlsx", {});
				}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'IN_LATE') {
					silicon_ioi.doctype.ioiJITEngineFrame.ioiTableInLate.download("xlsx", "data.xlsx", {});
				}else if(silicon_ioi.doctype.ioiJITEngineFrame.view == 'UNRESOLVED_ITEMS') {
					silicon_ioi.doctype.ioiJITEngineFrame.ioiTableUnresolved.download("xlsx", "data.xlsx", {});
				}else{
					silicon_ioi.doctype.ioiJITEngineFrame.ioiTableIntersiteRejectedItem.download("xlsx", "data.xlsx", {});
				}

			}

			document.getElementById('html_jit_engine_scheduling_log_download_xlsx').onclick = fct_download_xlsx;

			let fct_exceptions_list = function() {
				silicon_ioi.doctype.ioiJITEngineFrame.show_exceptions_list()
			}

			document.getElementById('html_jit_engine_scheduling_log_exceptions').onclick = fct_exceptions_list;

			let fct_unresolved_items = function() {
				silicon_ioi.doctype.ioiJITEngineFrame.unresolved_items()
			}

			document.getElementById('html_jit_engine_scheduling_unresolved_items').onclick = fct_unresolved_items;


			let fct_intersite_rejected_items = function() {
				silicon_ioi.doctype.ioiJITEngineFrame.intersite_rejected_items()
			}

			document.getElementById('html_jit_engine_scheduling_intersite_rejected_items').onclick = fct_intersite_rejected_items;




			let fct_change_refresh_value = function () {

				if (this.value) {
					if (parseFloat(this.value) < 2) {
						this.value = 2;
					}
				}else{
					this.value = 2;
				}
			}


			document.getElementById('html_jit_engine_scheduling_log_auto_refresh_value').onchange = fct_change_refresh_value;



			let format_cell = (cell, formatterParams) => {

				let status = cell.getRow().getData()["status_id"];

				if (status == 2) {
					cell.getElement().style.color = '#000000';
					cell.getElement().style.backgroundColor = '#ffaca3';
					cell.getElement().innerText = cell.getValue();
				}else{

					if (cell.getColumn().getField().toUpperCase() == 'STATUS_DESC') {

						if (status == 0) {
							cell.getElement().style.color = '#000000';
							cell.getElement().style.backgroundColor = '#93faa8';
							cell.getElement().innerText = cell.getValue();
						}else{
							cell.getElement().style.color = '#000000';
							cell.getElement().style.backgroundColor = '#ffe0a3';
							cell.getElement().innerText = cell.getValue();
						}

					}else if (cell.getColumn().getField().toUpperCase() == 'FORMATTED_PO_PR') {

						cell.getElement().style.color = '#000000';

						if (cell.getRow().getData()["bgcolor_po_pr"]) {
							cell.getElement().style.backgroundColor = cell.getRow().getData()["bgcolor_po_pr"];
						}

						cell.getElement().innerText = cell.getValue();

					}else if (cell.getColumn().getField().toUpperCase() == 'FORMATTED_SO_PROD_DOS') {

						cell.getElement().style.color = '#000000';

						if (cell.getRow().getData()["bgcolor_so_prod_dos"]) {
							cell.getElement().style.backgroundColor = cell.getRow().getData()["bgcolor_so_prod_dos"];
						}

						cell.getElement().innerText = cell.getValue();
					}

				}

				if (cell.getColumn().getField().toUpperCase() == 'SO_PROD_DOS_QT') {

					if ((!cell.getValue()) || ((cell.getValue()) && (cell.getValue() == 0))) {
						return '';
					}else{
						return parseFloat(cell.getValue()).toFixed(2);
					}
				}else{

					if (!cell.getValue()) {
						return '';
					}else{
						return cell.getValue();
					}

				}
			}

			let cell_onclick = (e, cell) => {

				if ((cell.getValue()) && (cell.getValue() != '')) {

					if (cell.getRow().getData()["execution_step"] != 'INTERSITE_CHANGE_STATUS') {

						if (cell.getColumn().getField().toUpperCase() == 'FORMATTED_PO_PR') {

							if ((cell.getRow().getData()["doc_po_pr"]) && (cell.getRow().getData()["doc_po_pr"] != '')) {

								if (cell.getRow().getData()["doc_po_pr"] == 'POR') {
									window.open('/app/ioi-purchases-order/' + cell.getRow().getData()["purchases_order_id"]);

								}else if (cell.getRow().getData()["doc_po_pr"] == 'ODP') {
									window.open('/app/ioi-production/' + cell.getRow().getData()["dest_production_id"]);

								}else{
									let dc = 'ioi Purchases Proposals'
									let name = cell.getRow().getData()["purchases_proposal_id"];

									silicon_ioi.doctype.ioiJITEngineLinkFrame.jump_to_single_module(dc, name);
								}
							}
						}else if (cell.getColumn().getField().toUpperCase() == 'FORMATTED_SO_PROD_DOS') {

							if ((cell.getRow().getData()["doc_so_prod_dos"]) && (cell.getRow().getData()["doc_so_prod_dos"] != '')) {

								if (cell.getRow().getData()["doc_so_prod_dos"] == 'SOR') {

									window.open('/app/ioi-sales-order/' + cell.getRow().getData()["sales_order_id"]);

								}else if (cell.getRow().getData()["doc_so_prod_dos"] == 'ODPC') {

									window.open('/app/ioi-production/' + cell.getRow().getData()["production_id"]);

								}else if (cell.getRow().getData()["doc_so_prod_dos"] == 'DOSC') {
									window.open('/app/ioi-dossier/' + cell.getRow().getData()["dossier_id"]);
								}
							}
						}

					}else{

						if (cell.getColumn().getField().toUpperCase() == 'FORMATTED_PO_PR') {

							if ((cell.getRow().getData()["doc_po_pr"]) && (cell.getRow().getData()["doc_po_pr"] != '')) {

								window.open('/app/ioi-sales-order/' + cell.getRow().getData()["purchases_order_id"]);
							}
						}else if (cell.getColumn().getField().toUpperCase() == 'FORMATTED_SO_PROD_DOS') {

							if ((cell.getRow().getData()["doc_so_prod_dos"]) && (cell.getRow().getData()["doc_so_prod_dos"] != '')) {

								window.open('/app/ioi-purchases-order/' + cell.getRow().getData()["sales_order_id"]);
							}
						}
					}
				}
			}




			silicon_ioi.doctype.ioiJITEngineFrame.ioiTable = new ioi.Tabulator('#html_jit_engine_scheduling_log_result_content', {
				maxHeight: 600,
				rowHeight: null,
				selectableRows: 0,
				showProfiles: false,
				movableColumns: false,
				resizableColumns: false,
				autoRedraw: false,

				columns: [
					{title: __('Status'), field: 'status_desc', width: 90, formatter: format_cell, frozen:true},

					{title: __('Execution step'), field: 'execution_step', width: 250, visible: false},


					{title: __('No'), field: 'line_no', width: 80, hozAlign: "right", formatter: format_cell},
					{title: __('Date / Time'), field: 'execution_dt', width: 150, formatter: format_cell},
					{title: __('Site'), field: 'site_id', width: 60, formatter: format_cell},

					{title: __('Item / Manuf. cat'), field: 'item_id', width: 200, formatter: format_cell},
					{title: __('Description'), field: 'description', width: 500, formatter: format_cell},

					{title: __('Purchases Order Id'), field: 'purchases_order_id', width: 250, visible: false},
					{title: __('Purchases Proposal Id'), field: 'purchases_proposal_id', width: 250, visible: false},


					{title: __('SOR / ODPC / DOSC'), field: 'doc_so_prod_dos', width: 140, visible: false},
					{title: __('Origin document'), field: 'formatted_so_prod_dos', width: 250, formatter: format_cell, cellClick: cell_onclick},
					{title: __('SOR / ODPC / DOSC bgcolor'), field: 'bgcolor_so_prod_dos', width: 250, visible: false},
					{title: __('SOR / ODPC / DOSC color'), field: 'ftcolor_so_prod_dos', width: 250, visible: false},


					{title: __('POR / PPR'), field: 'doc_po_pr', width: 100, visible: false},
					{title: __('Created / Updated document'), field: 'formatted_po_pr', width: 250, formatter: format_cell, cellClick: cell_onclick},
					{title: __('PO / PP bgcolor'), field: 'bgcolor_po_pr', width: 250, visible: false},
					{title: __('PO / PP color'), field: 'ftcolor_po_pr', width: 250, visible: false},




					{title: __('Qty'), field: 'so_prod_dos_qt', width: 200, hozAlign: "right", formatter: format_cell},

					{title: __('Sales Order Id'), field: 'sales_order_id', width: 250, visible: false},
					{title: __('Production Id'), field: 'production_id', width: 250, visible: false},
					{title: __('Dossier Id'), field: 'dossier_id', width: 250, visible: false},
					{title: __('JIT Engine Setting'), field: 'jit_engine_setting_id', width: 200, formatter: format_cell}

				]
			});



			let format_cell_bis = (cell, formatterParams) => {

				if (cell.getColumn().getField().toUpperCase() == 'FORMATTED_NAME') {

					cell.getElement().style.color = '#000000'; //cell.getRow().getData()["ftcolor_po_pr"];

					if (cell.getRow().getData()["bgcolor_po_pr"]) {
						cell.getElement().style.backgroundColor = cell.getRow().getData()["bgcolor_po_pr"];
					}

					cell.getElement().innerText = cell.getValue();

				}

				if (!cell.getValue()) {
					return '';
				}else{
					return cell.getValue();
				}

			}


			let cell_onclick_bis = (e, cell) => {

				if ((cell.getValue()) && (cell.getValue() != '')) {

					if (cell.getColumn().getField().toUpperCase() == 'FORMATTED_NAME') {

						if ((cell.getRow().getData()["doctype_id"]) && (cell.getRow().getData()["doctype_id"] != '')) {

							if (cell.getRow().getData()["doctype_id"] == 'POR') {
								window.open('/app/ioi-purchases-order/' + cell.getRow().getData()["doctype_name"]);

							}else if (cell.getRow().getData()["doctype_id"] == 'PPR') {
								let dc = 'ioi Purchases Proposals'
								let name = cell.getRow().getData()["doctype_name"];

								silicon_ioi.doctype.ioiJITEngineLinkFrame.jump_to_single_module(dc, name);
							}else{
								window.open('/app/ioi-production/' + cell.getRow().getData()["doctype_name"]);
							}
						}
					}
				}
			}




			silicon_ioi.doctype.ioiJITEngineFrame.ioiTableToHandle = new ioi.Tabulator('#html_jit_engine_scheduling_log_result_content_to_handle', {
				maxHeight: 600,
				rowHeight: null,
				selectableRows: 0,
				showProfiles: false,
				movableColumns: false,
				resizableColumns: false,
				autoRedraw: false,

				columns: [
					{title: __('Doctype'), field: 'doctype_id', width: 150, visible: false},
					{title: __('Document'), field: 'doctype_name', width: 80, hozAlign: "right", visible: false},
					{title: __('Child Doc'), field: 'child_doctype_name', width: 80, hozAlign: "right", visible: false},
					{title: __('Document'), field: 'formatted_name', width: 250, formatter: format_cell_bis, cellClick: cell_onclick_bis},
					{title: __('Supplier'), field: 'supplier_id', width: 150},
					{title: __('Expected arrival on'), field: 'scheduled_datetime', width: 150},
					{title: __('Item / Manuf. cat'), field: 'item_id', width: 200},
					{title: __('Ordered qty'), field: 'ordered_qty', hozAlign: "right", width: 100},
					{title: __('Unit'), field: 'unit_id', width: 100},
					{title: __('Description'), field: 'item_description', width: 350},
					{title: __('Status bgcolor'), field: 'bgcolor_po_pr', width: 250, visible: false},
					{title: __('Status color'), field: 'ftcolor_po_pr', width: 250, visible: false}

				]

			});



			silicon_ioi.doctype.ioiJITEngineFrame.ioiTableNoScheduled = new ioi.Tabulator('#html_jit_engine_scheduling_log_result_content_no_scheduled', {
				maxHeight: 600,
				rowHeight: null,
				selectableRows: 0,
				showProfiles: false,
				movableColumns: false,
				resizableColumns: false,
				autoRedraw: false,

				columns: [
					{title: __('Doctype'), field: 'doctype_id', width: 150, visible: false},
					{title: __('Document'), field: 'doctype_name', width: 80, hozAlign: "right", visible: false},
					{title: __('Child Doc'), field: 'child_doctype_name', width: 80, hozAlign: "right", visible: false},
					{title: __('Document'), field: 'formatted_name', width: 250, formatter: format_cell_bis, cellClick: cell_onclick_bis},
					{title: __('Supplier'), field: 'supplier_id', width: 150},
					{title: __('Expected arrival on'), field: 'scheduled_datetime', width: 150},
					{title: __('Item / Manuf. cat'), field: 'item_id', width: 200},
					{title: __('Ordered qty'), field: 'ordered_qty', hozAlign: "right", width: 100},
					{title: __('Unit'), field: 'unit_id', width: 100},
					{title: __('Description'), field: 'item_description', width: 350},
					{title: __('Status bgcolor'), field: 'bgcolor_po_pr', width: 250, visible: false},
					{title: __('Status color'), field: 'ftcolor_po_pr', width: 250, visible: false}
				]

			});


			silicon_ioi.doctype.ioiJITEngineFrame.ioiTableInLate = new ioi.Tabulator('#html_jit_engine_scheduling_log_result_content_in_late', {
				maxHeight: 600,
				rowHeight: null,
				selectableRows: 0,
				showProfiles: false,
				movableColumns: false,
				resizableColumns: false,
				autoRedraw: false,

				columns: [
					{title: __('Doctype'), field: 'doctype_id', width: 150, visible: false},
					{title: __('Document'), field: 'doctype_name', width: 80, hozAlign: "right", visible: false},
					{title: __('Child Doc'), field: 'child_doctype_name', width: 80, hozAlign: "right", visible: false},
					{title: __('Document'), field: 'formatted_name', width: 250, formatter: format_cell_bis, cellClick: cell_onclick_bis},
					{title: __('Supplier'), field: 'supplier_id', width: 150},
					{title: __('Expected arrival on'), field: 'scheduled_datetime', width: 150},
					{title: __('Item / Manuf. cat'), field: 'item_id', width: 200},
					{title: __('Ordered qty'), field: 'ordered_qty', hozAlign: "right", width: 100},
					{title: __('Unit'), field: 'unit_id', width: 100},
					{title: __('Description'), field: 'item_description', width: 350},
					{title: __('Status bgcolor'), field: 'bgcolor_po_pr', width: 250, visible: false},
					{title: __('Status color'), field: 'ftcolor_po_pr', width: 250, visible: false}

				]

			});


			let format_cell_ter = (cell, formatterParams) => {

				if (cell.getColumn().getField().toUpperCase() == 'FORMATTED_PK') {

					cell.getElement().style.color = '#000000';

					if (cell.getRow().getData()["bgcolor"]) {
						cell.getElement().style.backgroundColor = cell.getRow().getData()["bgcolor"];
					}

					cell.getElement().innerText = cell.getValue();

				}

				if (!cell.getValue()) {
					return '';
				}else{
					return cell.getValue();
				}

			}

			let cell_onclick_ter = (e, cell) => {

				if ((cell.getValue()) && (cell.getValue() != '')) {

					if (cell.getColumn().getField().toUpperCase() == 'FORMATTED_PK') {

						if ((cell.getRow().getData()["vt_doc_type"]) && (cell.getRow().getData()["vt_doc_type"] != '')) {

							if (cell.getRow().getData()["vt_doc_type"] == 'SOR') {
								window.open('/app/ioi-sales-order/' + cell.getRow().getData()["pk_name"]);

							}else if ((cell.getRow().getData()["vt_doc_type"] == 'ODP') || (cell.getRow().getData()["vt_doc_type"] == 'ODPC')) {
								window.open('/app/ioi-production/' + cell.getRow().getData()["pk_name"]);

							}else if ((cell.getRow().getData()["vt_doc_type"] == 'DOS') || (cell.getRow().getData()["vt_doc_type"] == 'DOSC')) {
								window.open('/app/ioi-dossier/' + cell.getRow().getData()["pk_name"]);

							}else if (cell.getRow().getData()["vt_doc_type"] == 'POR') {
								window.open('/app/ioi-purchases-order/' + cell.getRow().getData()["pk_name"]);

							}else if (cell.getRow().getData()["vt_doc_type"] == 'PPR') {

								let dc = 'ioi Purchases Proposals'
								let name = cell.getRow().getData()["pk_name"];

								silicon_ioi.doctype.ioiJITEngineLinkFrame.jump_to_single_module(dc, name);
							}
						}
					}
				}
			}


			silicon_ioi.doctype.ioiJITEngineFrame.ioiTableUnresolved = new ioi.Tabulator('#html_jit_engine_scheduling_log_result_unresolved', {
				maxHeight: 600,
				rowHeight: null,
				selectableRows: 0,
				showProfiles: false,
				movableColumns: false,
				resizableColumns: false,
				autoRedraw: false,

				columns: [

					{title: __('Site'), field: 'site_id', width: 60},
					{title: __('Item / Manuf. cat'), field: 'item_id', width: 200},
					{title: __('Scheduled'), field: 'doc_scheduled_pivot_datetime', width: 150},
					{title: __('Document'), field: 'formatted_pk', width: 250, formatter: format_cell_ter, cellClick: cell_onclick_ter},
					{title: __('Ordered qty'), field: 'doc_qty', hozAlign: "right", width: 120},
					{title: __('Unit'), field: 'doc_unit_id', width: 100},

					{title: __('Site balance'), field: 'site_balance', hozAlign: "right", width: 100},
					{title: __('Site level alert'), field: 'site_level_alert', hozAlign: "right", width: 100},
					{title: __('Stock Unit'), field: 'unit_id', width: 100},


					{title: __('Status bgcolor'), field: 'bgcolor', width: 250, visible: false},
					{title: __('VT Doc Type'), field: 'vt_doc_type', width: 250, visible: false},
					{title: __('Doctype'), field: 'doctype_id', width: 250, visible: false},
					{title: __('PK name'), field: 'pk_name', width: 250, visible: false},
					{title: __('Child doctype'), field: 'child_doctype_id', width: 250, visible: false},
					{title: __('Child PK name'), field: 'child_pk_name', width: 250, visible: false},


				]

			});


			let format_cell_for = (cell, formatterParams) => {

				if (cell.getColumn().getField().toUpperCase() == 'FORMATTED_PK') {

					cell.getElement().style.color = '#000000';

					if (cell.getRow().getData()["bgcolor"]) {
						cell.getElement().style.backgroundColor = cell.getRow().getData()["bgcolor"];
					}

					cell.getElement().innerText = cell.getValue();

				}

				if (!cell.getValue()) {
					return '';
				}else{
					return cell.getValue();
				}

			}

			let cell_onclick_for = (e, cell) => {

				if ((cell.getValue()) && (cell.getValue() != '')) {

					if (cell.getColumn().getField().toUpperCase() == 'FORMATTED_PK') {

						if ((cell.getRow().getData()["vt_doc_type"]) && (cell.getRow().getData()["vt_doc_type"] != '')) {

							if (cell.getRow().getData()["vt_doc_type"] == 'SOR') {
								window.open('/app/ioi-sales-order/' + cell.getRow().getData()["pk_name"]);

							}else if ((cell.getRow().getData()["vt_doc_type"] == 'ODP') || (cell.getRow().getData()["vt_doc_type"] == 'ODPC')) {
								window.open('/app/ioi-production/' + cell.getRow().getData()["pk_name"]);

							}else if ((cell.getRow().getData()["vt_doc_type"] == 'DOS') || (cell.getRow().getData()["vt_doc_type"] == 'DOSC')) {
								window.open('/app/ioi-dossier/' + cell.getRow().getData()["pk_name"]);

							}else if (cell.getRow().getData()["vt_doc_type"] == 'POR') {
								window.open('/app/ioi-purchases-order/' + cell.getRow().getData()["pk_name"]);

							}else if (cell.getRow().getData()["vt_doc_type"] == 'PPR') {

								let dc = 'ioi Purchases Proposals'
								let name = cell.getRow().getData()["pk_name"];

								silicon_ioi.doctype.ioiJITEngineLinkFrame.jump_to_single_module(dc, name);
							}
						}
					}
				}
			}




			silicon_ioi.doctype.ioiJITEngineFrame.ioiTableIntersiteRejectedItem = new ioi.Tabulator('#html_jit_engine_scheduling_log_result_intersite_rejected', {
				maxHeight: 600,
				rowHeight: null,
				selectableRows: 0,
				showProfiles: false,
				movableColumns: false,
				resizableColumns: false,
				autoRedraw: false,

				columns: [

					{title: __('Site'), field: 'site_id', width: 60},
					{title: __('Item / Manuf. cat'), field: 'item_id', width: 200},
					{title: __('Expected arrival'), field: 'expected_arrival_on', width: 150},
					{title: __('Document'), field: 'formatted_pk', width: 250, formatter: format_cell_for, cellClick: cell_onclick_for},
					{title: __('Supplier'), field: 'supplier_id', width: 200},


					{title: __('Ordered qty'), field: 'qty', hozAlign: "right", width: 120},
					{title: __('Unit'), field: 'unit_id', width: 100},
					{title: __('Item description'), field: 'item_description', hozAlign: "right", width: 300},
					{title: __('Status bgcolor'), field: 'bgcolor', width: 250, visible: false},
					{title: __('VT Doc Type'), field: 'vt_doc_type', width: 250, visible: false},
					{title: __('PK name'), field: 'pk_name', width: 250, visible: false},
					{title: __('Child PK name'), field: 'child_pk_name', width: 250, visible: false}

				]

			});





			silicon_ioi.doctype.ioiJITEngineFrame.sleep_static(200).then(() => {
				silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('TO_HANDLE');
				silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('NO_SCHEDULED');
				silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('IN_LATE');
				silicon_ioi.doctype.ioiJITEngineFrame.show_logs_refresh();

				silicon_ioi.doctype.ioiJITEngineFrame.refresh_result();
			});

		});

	}

	static show_logs_refresh()
	{
		silicon_ioi.doctype.ioiJITEngineFrame.view = 'SEARCH';

		let data = [];

		let method = silicon_ioi.doctype.ioiJITEngineFrame.path_jit_engine_execution_log + '.ioi_jit_engine_execution_log_has_record_to_handle';

		frappe.call({  	method: method,
						args: {	"jit_run_id": silicon_ioi.doctype.ioiJITEngineFrame.jit_run_id
						},
						async: false,
						callback:function(r)	{
							if (r.message == 0) {
								document.getElementById('html_jit_engine_scheduling_log_to_handle').style.display = 'none';
							}else{
								document.getElementById('html_jit_engine_scheduling_log_to_handle').style.display = 'block';
							}

						}
		});

		method = silicon_ioi.doctype.ioiJITEngineFrame.path_jit_engine_execution_log + '.ioi_jit_engine_execution_log_has_no_scheduler_dt';

		frappe.call({  	method: method,
						args: {	"jit_run_id": silicon_ioi.doctype.ioiJITEngineFrame.jit_run_id
						},
						async: false,
						callback:function(r)	{
							if (r.message == 0) {
								document.getElementById('html_jit_engine_scheduling_log_no_scheduled').style.display = 'none';
							}else{
								document.getElementById('html_jit_engine_scheduling_log_no_scheduled').style.display = 'block';
							}

						}
		});

		method = silicon_ioi.doctype.ioiJITEngineFrame.path_jit_engine_execution_log + '.ioi_jit_engine_execution_log_has_record_in_late';

		frappe.call({  	method: method,
						args: {	"jit_run_id": silicon_ioi.doctype.ioiJITEngineFrame.jit_run_id
						},
						async: false,
						callback:function(r)	{
							if (r.message == 0) {
								document.getElementById('html_jit_engine_scheduling_log_in_late').style.display = 'none';
							}else{
								document.getElementById('html_jit_engine_scheduling_log_in_late').style.display = 'block';
							}

						}
		});

		method = silicon_ioi.doctype.ioiJITEngineFrame.path_jit_engine_execution_log + '.ioi_jit_engine_unresolved_items';

		frappe.call({  	method: method,
						args: {	"jit_engine_scheduling_name": silicon_ioi.doctype.ioiJITEngineFrame.jit_engine_scheduling_name,
								"jit_run_id": silicon_ioi.doctype.ioiJITEngineFrame.jit_run_id,

						},
						async: false,
						callback:function(r)	{

							if (r.message.length == 0) {
								document.getElementById('html_jit_engine_scheduling_log_result_exceptions').style.display = 'none';
								document.getElementById('html_jit_engine_scheduling_log_result_unresolved').style.display = 'none';
							}else{
								document.getElementById('html_jit_engine_scheduling_log_result_exceptions').style.display = 'block';
								document.getElementById('html_jit_engine_scheduling_log_result_unresolved').style.display = 'block';
							}
						}
		});


		method = silicon_ioi.doctype.ioiJITEngineFrame.path_jit_engine_execution_log + '.ioi_jit_engine_execution_intesite_rejected';

		frappe.call({  	method: method,
						args: {	"jit_engine_scheduling_name": silicon_ioi.doctype.ioiJITEngineFrame.jit_engine_scheduling_name,
								"jit_run_id": silicon_ioi.doctype.ioiJITEngineFrame.jit_run_id,

						},
						async: false,
						callback:function(r)	{

							if (r.message.length == 0) {
								document.getElementById('html_jit_engine_scheduling_log_result_intersite_rejected').style.display = 'none';
							}else{
								document.getElementById('html_jit_engine_scheduling_log_result_intersite_rejected').style.display = 'block';
							}
						}
		});




		method = silicon_ioi.doctype.ioiJITEngineFrame.path_jit_engine_execution_log + '.ioi_jit_engine_execution_log_show_scheduling_log';

		frappe.call({  	method: method,
						args: {	"jit_engine_scheduling_name": silicon_ioi.doctype.ioiJITEngineFrame.jit_engine_scheduling_name,
								"jit_run_id": silicon_ioi.doctype.ioiJITEngineFrame.jit_run_id,
								"site_id": document.getElementById('html_jit_engine_scheduling_log_site').value,
								"jit_engine_setting_id": document.getElementById('html_jit_engine_scheduling_log_jit_setting').value,
								"status_id": document.getElementById('html_jit_engine_scheduling_log_status').value,
								"description": document.getElementById('html_jit_engine_scheduling_log_description').value,
								"item_id": document.getElementById('html_jit_engine_scheduling_log_item').value,
								"sales_order_id": document.getElementById('html_jit_engine_scheduling_log_so').value,
								"production_id": document.getElementById('html_jit_engine_scheduling_log_prod').value,
								"dossier_id": document.getElementById('html_jit_engine_scheduling_log_dossier').value,
								"purchases_order_id": document.getElementById('html_jit_engine_scheduling_log_po').value,
								"purchases_proposal_id": document.getElementById('html_jit_engine_scheduling_log_pp').value

						},
						async: false,
						callback:function(r)	{
							data = r.message;

						}
		});

		silicon_ioi.doctype.ioiJITEngineFrame.ioiTable.setData(data);

		silicon_ioi.doctype.ioiJITEngineFrame.sleep_static(200).then(() => {

			document.getElementById('html_jit_engine_scheduling_log_jit_setting_label').style.display = 'block';
			document.getElementById('html_jit_engine_scheduling_log_jit_setting').style.display = 'block';
			document.getElementById('html_jit_engine_scheduling_log_jit_setting_button').style.display = 'block';

			document.getElementById('html_jit_engine_scheduling_log_status_label').style.display = 'block';
			document.getElementById('html_jit_engine_scheduling_log_status').style.display = 'block';

			document.getElementById('html_jit_engine_scheduling_log_description_label').style.display = 'block';
			document.getElementById('html_jit_engine_scheduling_log_description').style.display = 'block';

			document.getElementById('html_jit_engine_scheduling_log_po_label').style.display = 'block';
			document.getElementById('html_jit_engine_scheduling_log_po').style.display = 'block';
			document.getElementById('html_jit_engine_scheduling_log_po_button').style.display = 'block';

			document.getElementById('html_jit_engine_scheduling_log_pp_label').style.display = 'block';
			document.getElementById('html_jit_engine_scheduling_log_pp').style.display = 'block';
			document.getElementById('html_jit_engine_scheduling_log_pp_button').style.display = 'block';

			document.getElementById('html_jit_engine_scheduling_log_so_label').style.display = 'block';
			document.getElementById('html_jit_engine_scheduling_log_so').style.display = 'block';
			document.getElementById('html_jit_engine_scheduling_log_so_button').style.display = 'block';

			document.getElementById('html_jit_engine_scheduling_log_prod_label').style.display = 'block';
			document.getElementById('html_jit_engine_scheduling_log_prod').style.display = 'block';
			document.getElementById('html_jit_engine_scheduling_log_prod_button').style.display = 'block';

			document.getElementById('html_jit_engine_scheduling_log_dossier_label').style.display = 'block';
			document.getElementById('html_jit_engine_scheduling_log_dossier').style.display = 'block';
			document.getElementById('html_jit_engine_scheduling_log_dossier_button').style.display = 'block';

			document.getElementById('html_jit_engine_scheduling_log_result_content_download').style.display = 'block';
			document.getElementById('html_jit_engine_scheduling_log_download_xlsx').style.display = 'block';

			document.getElementById('html_jit_engine_scheduling_log_result_title').innerHTML = '<b>' + __("JIT Logs") + '</b>';

			document.getElementById('html_jit_engine_scheduling_log_nb_document').innerHTML = '';


			document.getElementById('html_jit_engine_scheduling_log_result_exceptions').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_result_unresolved').style.display = 'none';

			document.getElementById('html_jit_engine_scheduling_log_exceptions').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_unresolved_items').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_intersite_rejected_items').style.display = 'none';
			//document.getElementById('html_jit_engine_scheduling_log_result_intersite_rejected').style.display = 'none';

			if (data.length > 0) {

				for (var i = 0; i < data.length; i++) {

					if ((data[i].execution_step.toUpperCase() == 'END_PACKAGE') && (data[i].status_id == 1)) {
						document.getElementById('html_jit_engine_scheduling_log_exceptions').style.display = 'block';
						document.getElementById('html_jit_engine_scheduling_unresolved_items').style.display = 'block'
					}

					// if (data[i].execution_step.toUpperCase() == 'END') {
					// 	document.getElementById('html_jit_engine_scheduling_intersite_rejected_items').style.display = 'block';
					// }
				}

			}

		});
	}

	static show_execution_result(what)
	{

		silicon_ioi.doctype.ioiJITEngineFrame.view = what.toUpperCase()

		document.getElementById('html_jit_engine_scheduling_log_jit_setting_label').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_jit_setting').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_jit_setting_button').style.display = 'none';

		document.getElementById('html_jit_engine_scheduling_log_status_label').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_status').style.display = 'none';

		document.getElementById('html_jit_engine_scheduling_log_description_label').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_description').style.display = 'none';

		document.getElementById('html_jit_engine_scheduling_log_po_label').style.display = 'block';
		document.getElementById('html_jit_engine_scheduling_log_po').style.display = 'block';
		document.getElementById('html_jit_engine_scheduling_log_po_button').style.display = 'block';

		document.getElementById('html_jit_engine_scheduling_log_pp_label').style.display = 'block';
		document.getElementById('html_jit_engine_scheduling_log_pp').style.display = 'block';
		document.getElementById('html_jit_engine_scheduling_log_pp_button').style.display = 'block';

		document.getElementById('html_jit_engine_scheduling_log_so_label').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_so').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_so_button').style.display = 'none';

		document.getElementById('html_jit_engine_scheduling_log_prod_label').style.display = 'block';
		document.getElementById('html_jit_engine_scheduling_log_prod').style.display = 'block';
		document.getElementById('html_jit_engine_scheduling_log_prod_button').style.display = 'block';

		document.getElementById('html_jit_engine_scheduling_log_dossier_label').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_dossier').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_dossier_button').style.display = 'none';

		document.getElementById('html_jit_engine_scheduling_log_result_content_download').style.display = 'block';
		document.getElementById('html_jit_engine_scheduling_log_download_xlsx').style.display = 'block';



		let status_id = 0;


		if (what.toUpperCase() == 'TO_HANDLE') {
			status_id = 0;
		}else if (what.toUpperCase() == 'NO_SCHEDULED') {
			status_id = 1;
		}else if (what.toUpperCase() == 'IN_LATE') {
			status_id = 2;
		}


		let data = [];

		let method = silicon_ioi.doctype.ioiJITEngineFrame.path_jit_engine_execution_log + '.ioi_jit_engine_execution_result';

		frappe.call({  	method: method,
						args: {	"jit_run_id": silicon_ioi.doctype.ioiJITEngineFrame.jit_run_id,
								"status_id": status_id,
								"site_id": document.getElementById('html_jit_engine_scheduling_log_site').value,
								"item_id": document.getElementById('html_jit_engine_scheduling_log_item').value,
								"purchases_order_id": document.getElementById('html_jit_engine_scheduling_log_po').value,
								"purchases_proposal_id": document.getElementById('html_jit_engine_scheduling_log_pp').value,
								"production_id": document.getElementById('html_jit_engine_scheduling_log_prod').value
						},
						async: false,
						callback:function(r)	{
							data = r.message;

						}
		});

		if (what.toUpperCase() == 'TO_HANDLE') {
			silicon_ioi.doctype.ioiJITEngineFrame.ioiTableToHandle.setData(data);
		}else if (what.toUpperCase() == 'NO_SCHEDULED') {
			silicon_ioi.doctype.ioiJITEngineFrame.ioiTableNoScheduled.setData(data);
		}else if (what.toUpperCase() == 'IN_LATE') {
			silicon_ioi.doctype.ioiJITEngineFrame.ioiTableInLate.setData(data);
		}


		let nb_lines = 0;
		let nb_po_lines = 0;
		let nb_pp_lines = 0;
		let nb_odp_lines = 0;
		let nb_dos_lines = 0;
		let nb_distinct_document = 0;
		let nb_distinct_po = 0;
		let nb_distinct_pp = 0;
		let nb_distinct_odp = 0;
		let nb_distinct_dos = 0

		if (data.length > 0) {



			let old_doctype_name = ''

			for (var i = 0; i < data.length; i++) {

				if (data[i].doctype_name != old_doctype_name) {

					nb_distinct_document += 1;

					if (data[i].doctype_id == 'POR') {
						nb_distinct_po += 1;
					}else if (data[i].doctype_id == 'PPR') {
						nb_distinct_pp += 1;
					}else if (data[i].doctype_id == 'ODP') {
						nb_distinct_odp += 1;
					}else if (data[i].doctype_id == 'DOS') {
						nb_distinct_dos += 1;
					}
				}

				if (data[i].child_doctype_name) {

					if (data[i].doctype_id == 'POR') {
						nb_po_lines += 1;
					}else if (data[i].doctype_id == 'PPR') {
						nb_pp_lines += 1;
					}else if (data[i].doctype_id == 'ODP') {
						nb_odp_lines += 1;
					}else if (data[i].doctype_id == 'DOS') {
						nb_dos_lines += 1;
					}

					nb_lines += 1;
				}


				old_doctype_name = data[i].doctype_name;

			}
		}

		let msg = '';

		if (nb_distinct_document != 0) {

			msg += '<b>' + nb_distinct_document.toString() + '</b> ';

			if (nb_distinct_document > 1) {
				msg += __("documents");
			}else{
				msg += __("document");
			}

			msg += ' (';

			if (nb_distinct_po != 0) {
				msg +=  ' POR : <b>' + nb_distinct_po.toString() + '</b>';

				if ((nb_distinct_pp != 0) || (nb_distinct_odp != 0) || (nb_distinct_dos != 0)) {
					msg += ' -';
				}
			}

			if (nb_distinct_pp != 0) {
				msg += ' PPR : <b>' + nb_distinct_pp.toString() + '</b>';

				if ((nb_distinct_odp != 0) || (nb_distinct_dos != 0)) {
					msg += ' -';
				}
			}


			if (nb_distinct_odp != 0) {
				msg += ' ODP : <b>' + nb_distinct_odp.toString() + '</b>';

				if (nb_distinct_dos != 0) {
					msg += ' -';
				}
			}

			if (nb_distinct_dos != 0) {
				msg += ' DOS : <b>' + nb_distinct_dos.toString() + '</b>';
			}



			msg += ' ), <b>' + nb_lines.toString() + '</b> ';


			if (nb_lines > 1) {
				msg += __('lines');
			}else{
				msg += __('line');
			}

			msg += ' (';



			if (nb_po_lines != 0) {
				msg +=  ' POR : <b>' + nb_po_lines.toString() + '</b>';

				if ((nb_pp_lines != 0) || (nb_odp_lines != 0) || (nb_dos_lines != 0)) {
					msg += ' -';
				}
			}

			if (nb_pp_lines != 0) {
				msg += ' PPR : <b>' + nb_pp_lines.toString() + '</b>';

				if ((nb_odp_lines != 0) || (nb_dos_lines != 0)) {
					msg += ' -';
				}
			}

			if (nb_odp_lines != 0) {
				msg += ' ODP : <b>' + nb_odp_lines.toString() + '</b>';

				if (nb_dos_lines != 0) {
					msg += ' -';
				}
			}


			if (nb_dos_lines != 0) {
				msg += ' DOS : <b>' + nb_dos_lines.toString() + '</b>';
			}


			msg += ' )';

		}

		document.getElementById('html_jit_engine_scheduling_log_nb_document').innerHTML = msg;



		silicon_ioi.doctype.ioiJITEngineFrame.sleep_static(200).then(() => {

			document.getElementById('html_jit_engine_scheduling_log_jit_setting_label').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_jit_setting').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_jit_setting_button').style.display = 'none';

			document.getElementById('html_jit_engine_scheduling_log_status_label').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_status').style.display = 'none';

			document.getElementById('html_jit_engine_scheduling_log_description_label').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_description').style.display = 'none';

			document.getElementById('html_jit_engine_scheduling_log_po_label').style.display = 'block';
			document.getElementById('html_jit_engine_scheduling_log_po').style.display = 'block';
			document.getElementById('html_jit_engine_scheduling_log_po_button').style.display = 'block';

			document.getElementById('html_jit_engine_scheduling_log_pp_label').style.display = 'block';
			document.getElementById('html_jit_engine_scheduling_log_pp').style.display = 'block';
			document.getElementById('html_jit_engine_scheduling_log_pp_button').style.display = 'block';

			document.getElementById('html_jit_engine_scheduling_log_so_label').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_so').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_so_button').style.display = 'none';

			document.getElementById('html_jit_engine_scheduling_log_prod_label').style.display = 'block';
			document.getElementById('html_jit_engine_scheduling_log_prod').style.display = 'block';
			document.getElementById('html_jit_engine_scheduling_log_prod_button').style.display = 'block';

			document.getElementById('html_jit_engine_scheduling_log_dossier_label').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_dossier').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_dossier_button').style.display = 'none';

			if (what.toUpperCase() == 'TO_HANDLE') {
				document.getElementById('html_jit_engine_scheduling_log_result_title').innerHTML = '<b>' + __("Purchases order and Purchases Proposals to handle") + '</b>';
			}else if (what.toUpperCase() == 'NO_SCHEDULED') {
				document.getElementById('html_jit_engine_scheduling_log_result_title').innerHTML = '<b>' + __("Purchases order and Purchases Proposals without scheduled datetime") + '</b>';
			}else if (what.toUpperCase() == 'IN_LATE') {
				document.getElementById('html_jit_engine_scheduling_log_result_title').innerHTML = '<b>' + __("Purchases order and Purchases Proposals in late") + '</b>';
			}



		});

	}

	static show_exceptions_list()
	{
		silicon_ioi.doctype.ioiJITEngineFrame.view = 'EXCEPTIONS';

		document.getElementById('html_jit_engine_scheduling_log_result_title').innerHTML = '<b>' + __("Exceptions list") + '</b>';

		document.getElementById('html_jit_engine_scheduling_log_result_content').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_result_content_to_handle').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_result_content_no_scheduled').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_result_content_in_late').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_result_exceptions').style.display = 'block';
		document.getElementById('html_jit_engine_scheduling_log_result_unresolved').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_result_intersite_rejected').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_download_xlsx').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_nb_document').innerHTML= '';

		let html = '<div style="overflow-y:auto;width:100%;height:400px;background-color:#F4F5F6;"> ';

		let method = silicon_ioi.doctype.ioiJITEngineFrame.path_jit_engine_execution_log + '.ioi_jit_engine_execution_log_show_exceptions';

		frappe.call({  	method: method,
						args: {	"jit_engine_scheduling_name": silicon_ioi.doctype.ioiJITEngineFrame.jit_engine_scheduling_name,
								"jit_run_id": silicon_ioi.doctype.ioiJITEngineFrame.jit_run_id,
								"site_id": document.getElementById('html_jit_engine_scheduling_log_site').value,
								"item_id": document.getElementById('html_jit_engine_scheduling_log_item').value
						},
						async: false,
						callback:function(r)	{
							html += r.message.content;
						}

		});

		html+= '</div>';

		document.getElementById('html_jit_engine_scheduling_log_result_exceptions').innerHTML = html;
	}

	static unresolved_items()
	{
		silicon_ioi.doctype.ioiJITEngineFrame.view = 'UNRESOLVED_ITEMS';

		document.getElementById('html_jit_engine_scheduling_log_jit_setting_label').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_jit_setting').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_jit_setting_button').style.display = 'none';

		document.getElementById('html_jit_engine_scheduling_log_status_label').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_status').style.display = 'none';

		document.getElementById('html_jit_engine_scheduling_log_description_label').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_description').style.display = 'none';

		document.getElementById('html_jit_engine_scheduling_log_po_label').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_po').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_po_button').style.display = 'none';

		document.getElementById('html_jit_engine_scheduling_log_pp_label').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_pp').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_pp_button').style.display = 'none';

		document.getElementById('html_jit_engine_scheduling_log_so_label').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_so').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_so_button').style.display = 'none';

		document.getElementById('html_jit_engine_scheduling_log_prod_label').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_prod').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_prod_button').style.display = 'none';

		document.getElementById('html_jit_engine_scheduling_log_dossier_label').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_dossier').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_dossier_button').style.display = 'none';

		document.getElementById('html_jit_engine_scheduling_log_result_content').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_result_content_to_handle').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_result_content_no_scheduled').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_result_content_in_late').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_result_exceptions').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_result_unresolved').style.display = 'block';
		document.getElementById('html_jit_engine_scheduling_log_result_intersite_rejected').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_download_xlsx').style.display = 'block';
		document.getElementById('html_jit_engine_scheduling_log_nb_document').style.display = 'none';


		let data = [];

		let method = silicon_ioi.doctype.ioiJITEngineFrame.path_jit_engine_execution_log + '.ioi_jit_engine_unresolved_items';

		frappe.call({  	method: method,
						args: {	"jit_engine_scheduling_name": silicon_ioi.doctype.ioiJITEngineFrame.jit_engine_scheduling_name,
								"jit_run_id": silicon_ioi.doctype.ioiJITEngineFrame.jit_run_id,
								"site_id": document.getElementById('html_jit_engine_scheduling_log_site').value,
								"item_id": document.getElementById('html_jit_engine_scheduling_log_item').value
						},
						async: false,
						callback:function(r)	{
							data = r.message;

						}
		});

		silicon_ioi.doctype.ioiJITEngineFrame.ioiTableUnresolved.setData(data);

		silicon_ioi.doctype.ioiJITEngineFrame.sleep_static(200).then(() => {



			document.getElementById('html_jit_engine_scheduling_log_jit_setting_label').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_jit_setting').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_jit_setting_button').style.display = 'none';

			document.getElementById('html_jit_engine_scheduling_log_status_label').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_status').style.display = 'none';

			document.getElementById('html_jit_engine_scheduling_log_description_label').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_description').style.display = 'none';

			document.getElementById('html_jit_engine_scheduling_log_so_label').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_so').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_so_button').style.display = 'none';

			document.getElementById('html_jit_engine_scheduling_log_prod_label').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_prod').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_prod_button').style.display = 'none';

			document.getElementById('html_jit_engine_scheduling_log_dossier_label').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_dossier').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_dossier_button').style.display = 'none';

			document.getElementById('html_jit_engine_scheduling_log_result_title').innerHTML = '<b>' + __("Rejected items / documents") + '</b>';



		});

	}

	static intersite_rejected_items()
	{
		silicon_ioi.doctype.ioiJITEngineFrame.view = 'INTERSITE_REJECTED_ITEMS';

		document.getElementById('html_jit_engine_scheduling_log_jit_setting_label').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_jit_setting').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_jit_setting_button').style.display = 'none';

		document.getElementById('html_jit_engine_scheduling_log_status_label').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_status').style.display = 'none';

		document.getElementById('html_jit_engine_scheduling_log_description_label').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_description').style.display = 'none';

		document.getElementById('html_jit_engine_scheduling_log_po_label').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_po').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_po_button').style.display = 'none';

		document.getElementById('html_jit_engine_scheduling_log_pp_label').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_pp').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_pp_button').style.display = 'none';

		document.getElementById('html_jit_engine_scheduling_log_so_label').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_so').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_so_button').style.display = 'none';

		document.getElementById('html_jit_engine_scheduling_log_prod_label').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_prod').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_prod_button').style.display = 'none';

		document.getElementById('html_jit_engine_scheduling_log_dossier_label').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_dossier').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_dossier_button').style.display = 'none';

		document.getElementById('html_jit_engine_scheduling_log_result_content').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_result_content_to_handle').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_result_content_no_scheduled').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_result_content_in_late').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_result_exceptions').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_result_unresolved').style.display = 'none';
		document.getElementById('html_jit_engine_scheduling_log_result_intersite_rejected').style.display = 'block';
		document.getElementById('html_jit_engine_scheduling_log_download_xlsx').style.display = 'block';
		document.getElementById('html_jit_engine_scheduling_log_nb_document').style.display = 'none';


		let data = [];

		let method = silicon_ioi.doctype.ioiJITEngineFrame.path_jit_engine_execution_log + '.ioi_jit_engine_execution_intesite_rejected';

		frappe.call({  	method: method,
						args: {	"jit_engine_scheduling_name": silicon_ioi.doctype.ioiJITEngineFrame.jit_engine_scheduling_name,
								"jit_run_id": silicon_ioi.doctype.ioiJITEngineFrame.jit_run_id,
								"site_id": document.getElementById('html_jit_engine_scheduling_log_site').value,
								"item_id": document.getElementById('html_jit_engine_scheduling_log_item').value
						},
						async: false,
						callback:function(r)	{
							data = r.message;

						}
		});

		silicon_ioi.doctype.ioiJITEngineFrame.ioiTableIntersiteRejectedItem.setData(data);

		silicon_ioi.doctype.ioiJITEngineFrame.sleep_static(200).then(() => {



			document.getElementById('html_jit_engine_scheduling_log_jit_setting_label').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_jit_setting').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_jit_setting_button').style.display = 'none';

			document.getElementById('html_jit_engine_scheduling_log_status_label').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_status').style.display = 'none';

			document.getElementById('html_jit_engine_scheduling_log_description_label').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_description').style.display = 'none';

			document.getElementById('html_jit_engine_scheduling_log_so_label').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_so').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_so_button').style.display = 'none';

			document.getElementById('html_jit_engine_scheduling_log_prod_label').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_prod').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_prod_button').style.display = 'none';

			document.getElementById('html_jit_engine_scheduling_log_dossier_label').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_dossier').style.display = 'none';
			document.getElementById('html_jit_engine_scheduling_log_dossier_button').style.display = 'none';

			document.getElementById('html_jit_engine_scheduling_log_result_title').innerHTML = '<b>' + __("Intersite rejected items") + '</b>';



		});
	}

	static refresh_result()
	{
		if (document.getElementById('html_jit_engine_scheduling_log_auto_refresh')) {

			if (document.getElementById('html_jit_engine_scheduling_log_auto_refresh').checked) {

				if (document.getElementById('html_jit_engine_scheduling_log_auto_refresh_value').value) {

					if (parseFloat(document.getElementById('html_jit_engine_scheduling_log_auto_refresh_value').value) >= 2) {

						let ms = parseFloat(document.getElementById('html_jit_engine_scheduling_log_auto_refresh_value').value) * 1000;
						setTimeout(silicon_ioi.doctype.ioiJITEngineFrame.refresh_grids, ms);

					}else{
						setTimeout(silicon_ioi.doctype.ioiJITEngineFrame.refresh_result, 100);
					}
				}else{
					setTimeout(silicon_ioi.doctype.ioiJITEngineFrame.refresh_result, 100);
				}
			}else{
				setTimeout(silicon_ioi.doctype.ioiJITEngineFrame.refresh_result, 100);
			}
		}
	}

	static refresh_grids()
	{
		if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'SEARCH') {

			silicon_ioi.doctype.ioiJITEngineFrame.show_logs_refresh();

		}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'TO_HANDLE') {

			silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('TO_HANDLE');

		}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'NO_SCHEDULED') {

			silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('NO_SCHEDULED');

		}else if (silicon_ioi.doctype.ioiJITEngineFrame.view == 'IN_LATE') {

			silicon_ioi.doctype.ioiJITEngineFrame.show_execution_result('IN_LATE');

		}else{

		}

		setTimeout(silicon_ioi.doctype.ioiJITEngineFrame.refresh_result, 100);

	}


	static sleep_static(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}

}

silicon_ioi.doctype.ioiJITEngineFrame = ioiJITEngineFrame;
