frappe.provide("ioi.actions");

function documentation() {
	let language = frappe.boot.lang

	let url = ""

	if (language == "fr") {
		url = 'https://docs.ioi.online/s/fr'
	} else if(language == "nl") {
		url = 'https://docs.ioi.online/s/nl'
	} else {
		url = 'https://docs.ioi.online/s/en'
	}

	window.open(url, "_blank")
}

ioi.actions.documentation = documentation;

function show_tour(){
	//let tour_name = document.querySelector(".onboarding-step.pending.active").querySelector(".step-title").querySelector(":not(.step-index)").innerHTML;

	var target_doctype="";
	var target_module=["is","set"];

	var frm = window.cur_page.page.frm;
	if(frm){
		//form
		target_doctype=frm.doc.doctype;
	}else{
		//list
		frm = window.cur_page.page.list_view;
		if(frm){
			target_doctype=frm.doctype;
		}else{
			//page
			frm = window.cur_page.page.page;
			target_doctype=["is","set"];
			target_module = frm.title;
		}
	}


if (frm){
	frappe.call({
		method: "silicon_ioi.ioi_configuration.doctype.ioi_general_settings.ioi_general_settings.form_tour_exist",
		args: {
			doctype: target_doctype,
			module: target_module
		},
		callback: function (exi) {
			if (exi && exi.message) {
				let exist= parseInt(exi.message);
				if (exist){
					var d = new frappe.ui.Dialog({
						title: __("Start a From Tour"),
						fields: [
							{
								fieldtype: "Link",
								options: "Form Tour",
								label: __("Select your form tour"),
								fieldname: "form_tour",
								get_query: function () {
									return {
										filters: {
											reference_doctype: target_doctype,
											module: target_module,
										},
									};
								},
							}
						],
					});
					d.set_primary_action(__("Start"), function () {
						var values = d.get_values();
						if (values) {

							d.hide();

							frappe.call({
								method: "silicon_ioi.ioi_configuration.doctype.ioi_general_settings.ioi_general_settings.is_doctype_single",
								args: {
									doctype_name: target_doctype,
									form_tour_name: values['form_tour']},
								callback: function (r) {
									if (r && r.message) {
										let is_single = parseInt(r.message[0]);
										var reference_document = r.message[1];

										let route;
										if (is_single) {
											route = frappe.router.slug(reference_document);
										} else {
											route = `${frappe.router.slug(reference_document)}/new`;
										}

										let current_route = frappe.get_route();

										frappe.route_hooks = {};
										frappe.route_hooks.after_load = (frm) => {
											const on_finish = () => {
												let msg_dialog = frappe.msgprint({
													message: __("Form Tour Finished"),
													title: __("End of the Form Tour"),
													primary_action: {
														action: () => {
															frappe.set_route(current_route)
															msg_dialog.hide();
														},
														label: () => __("Continue"),
													},
												});
											};
											const tour_name = values['form_tour'];
											frm.tour.init({ tour_name, on_finish }).then(() => frm.tour.start());
										};

										frappe.set_route(route);
									}
								},
							});



						}
					});
					d.set_secondary_action_label(__("Close"));
					d.set_secondary_action(() => {d.hide();});

					d.show();

					frappe.route_hooks = {};
				}else{
					frappe.msgprint(__("This page doesn't have any form tour available."));
				}
			}

		}
	});
}else{
	frappe.msgprint(__("This page doesn't have any form tour available."));
}

}

ioi.actions.show_tour = show_tour
