const ioi_pd = require("./ioi_print_dialog.js");

$(document).ready(() => {
	let html_btn_report_center = `
	<li id="report-center-btn" class="nav-item" title="${__('Report Center')}">
		<div id="report_center_btn" style="cursor: pointer">
			<span>
				<svg class="icon icon-md"><use href="#icon-printer"></use></svg>
			</span>
		</div>
    </li>
	`;

	$('header.navbar > .container > .navbar-collapse > ul')[0].insertAdjacentHTML('afterbegin', html_btn_report_center);
	$("#report_center_btn").on("click", () => {
		ioi_pd.ioi_print_dialog(true);
	})
});
