frappe.provide('silicon-ioi');

class ACheck extends frappe.ui.form.ControlCheck {

	get_status(explain) {
		let r = super.get_status(explain);

		// VVR: revert null & read-only field as visible
		if (r === "None" && !cint(this.df.hidden) && !cint(this.df.hidden_due_to_dependency)) {
			r = "Read";
		}

		return r;
	}

};

// overriding frappe class
frappe.ui.form.ControlCheck = ACheck;
