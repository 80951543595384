// *******************************************************************************************************************************************
// Parent Class of all Sales Documents (Quotes, Orders, Deliveries, Invoices)
// *******************************************************************************************************************************************

import { ioiDocumentStatus } from "./ioi_document_status";

frappe.provide('silicon_ioi.doctype');

export class ioiSalesDocumentDocType extends silicon_ioi.doctype.ioiDocType
{
	quote_img_tab_detail = '/assets/silicon_ioi/images/modules/ioi_sales_quote.png';
	quote_img_tab_more = '/assets/silicon_ioi/images/buttons/more.png';
	quote_img_tab_address = '/assets/silicon_ioi/images/buttons/address.png';
	quote_img_tab_memo = '/assets/silicon_ioi/images/buttons/memo.png';
	quote_img_tab_document_flow = '/assets/silicon_ioi/images/buttons/document_flow.png';
	quote_img_tab_log = '/assets/silicon_ioi/images/buttons/log.png';
	quote_img_tab_warning = '/assets/silicon_ioi/images/buttons/log.png';

	order_img_tab_detail = '/assets/silicon_ioi/images/modules/ioi_sales_order.png';
	order_img_tab_more = '/assets/silicon_ioi/images/buttons/more.png';
	order_img_tab_address = '/assets/silicon_ioi/images/buttons/address.png';
	order_img_tab_memo = '/assets/silicon_ioi/images/buttons/memo.png';
	order_img_tab_document_flow = '/assets/silicon_ioi/images/buttons/document_flow.png';
	order_img_tab_log = '/assets/silicon_ioi/images/buttons/log.png';
	order_img_tab_warning = '/assets/silicon_ioi/images/buttons/log.png';

	delivery_img_tab_detail = '/assets/silicon_ioi/images/modules/ioi_sales_delivery.png';
	delivery_img_tab_more = '/assets/silicon_ioi/images/buttons/more.png';
	delivery_img_tab_address = '/assets/silicon_ioi/images/buttons/address.png';
	delivery_img_tab_memo = '/assets/silicon_ioi/images/buttons/memo.png';
	delivery_img_tab_document_flow = '/assets/silicon_ioi/images/buttons/document_flow.png';
	delivery_img_tab_log = '/assets/silicon_ioi/images/buttons/log.png';
	delivery_img_tab_warning = '/assets/silicon_ioi/images/buttons/log.png';

	invoice_img_tab_detail = '/assets/silicon_ioi/images/modules/ioi_sales_invoice.png';
	invoice_img_tab_more = '/assets/silicon_ioi/images/buttons/more.png';
	invoice_img_tab_address = '/assets/silicon_ioi/images/buttons/address.png';
	invoice_img_tab_memo = '/assets/silicon_ioi/images/buttons/memo.png';
	invoice_img_tab_summary = '/assets/silicon_ioi/images/buttons/invoice_summary.png';
	invoice_img_tab_document_flow = '/assets/silicon_ioi/images/buttons/document_flow.png';
	invoice_img_tab_log = '/assets/silicon_ioi/images/buttons/log.png';
	invoice_img_tab_warning = '/assets/silicon_ioi/images/buttons/log.png';


	static img_vt_data_close = '/assets/silicon_ioi/images/buttons/close.png';

	path_general_settings = 'silicon_ioi.ioi_configuration.doctype.ioi_general_settings.ioi_general_settings';
	path_module_status = 'silicon_ioi.ioi_configuration.doctype.ioi_module_status.ioi_module_status';
	path_module_user_status = 'silicon_ioi.ioi_configuration.doctype.ioi_module_user_status.ioi_module_user_status';
	path_module_status_accessible_fields = 'silicon_ioi.ioi_configuration.doctype.ioi_module_status_accessible_fields.ioi_module_status_accessible_fields';
	path_user = 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user';
	path_division = 'silicon_ioi.ioi_enterprise.doctype.ioi_division.ioi_division';
	path_site = 'silicon_ioi.ioi_enterprise.doctype.ioi_site.ioi_site';
	path_document_change_status = 'silicon_ioi.common.document_change_status';
    path_currency = 'silicon_ioi.ioi_configuration.doctype.ioi_currency.ioi_currency';
    path_sales_settings = 'silicon_ioi.ioi_configuration.doctype.ioi_sales_settings.ioi_sales_settings';
    path_sales_journal = 'silicon_ioi.ioi_sales.doctype.ioi_sales_journal.ioi_sales_journal';
	path_purchases_journal = 'silicon_ioi.ioi_purchases.doctype.ioi_purchases_journal.ioi_purchases_journal';
    path_sales_document = 'silicon_ioi.common.sales_document';
	path_sales_price_index = 'silicon_ioi.ioi_items.doctype.ioi_sales_price_index.ioi_sales_price_index'
	path_period = 'silicon_ioi.ioi_enterprise.doctype.ioi_period.ioi_period';
    path_sales_quote = 'silicon_ioi.ioi_sales.doctype.ioi_sales_quote.ioi_sales_quote';
	path_sales_order = 'silicon_ioi.ioi_sales.doctype.ioi_sales_order.ioi_sales_order';
	path_sales_delivery = 'silicon_ioi.ioi_sales.doctype.ioi_sales_delivery.ioi_sales_delivery';
	path_sales_invoice = 'silicon_ioi.ioi_sales.doctype.ioi_sales_invoice.ioi_sales_invoice';
    path_customer = 'silicon_ioi.ioi_sales.doctype.ioi_customer.ioi_customer';
	path_supplier = 'silicon_ioi.ioi_purchases.doctype.ioi_supplier.ioi_supplier';
    path_dossier = 'silicon_ioi.ioi_trakker.doctype.ioi_dossier.ioi_dossier';
    path_sales_agent = 'silicon_ioi.ioi_sales.doctype.ioi_sales_agent.ioi_sales_agent';
	path_park = 'silicon_ioi.ioi_helpdesk.doctype.ioi_park.ioi_park';
	path_opportunity = 'silicon_ioi.ioi_crm.doctype.ioi_opportunity.ioi_opportunity';
    path_sales_catalog = 'silicon_ioi.ioi_configuration.doctype.ioi_sales_catalog.ioi_sales_catalog';
    path_sales_condition = 'silicon_ioi.ioi_configuration.doctype.ioi_sales_condition.ioi_sales_condition';
    path_specific_condition = 'silicon_ioi.ioi_configuration.doctype.ioi_specific_condition.ioi_specific_condition';
	path_delivery_condition = 'silicon_ioi.ioi_configuration.doctype.ioi_delivery_condition.ioi_delivery_condition';
    path_warehouse = 'silicon_ioi.ioi_wms.doctype.ioi_warehouse.ioi_warehouse';
	path_warehouse_location = 'silicon_ioi.ioi_wms.doctype.ioi_warehouse_location.ioi_warehouse_location';
    path_payment_terms = 'silicon_ioi.ioi_configuration.doctype.ioi_payment_terms.ioi_payment_terms';
    path_customer_family_1 = 'silicon_ioi.ioi_configuration.doctype.ioi_customer_family_1.ioi_customer_family_1';
    path_customer_family_2 = 'silicon_ioi.ioi_configuration.doctype.ioi_customer_family_2.ioi_customer_family_2';
    path_general_account = 'silicon_ioi.ioi_accounting.doctype.ioi_general_account.ioi_general_account';
    path_postal_code = 'silicon_ioi.ioi_configuration.doctype.ioi_postal_code.ioi_postal_code';
    path_country = 'silicon_ioi.ioi_configuration.doctype.ioi_country.ioi_country';
	path_workcenter = 'silicon_ioi.ioi_enterprise.doctype.ioi_workcenter.ioi_workcenter';
    path_analytic_1 = 'silicon_ioi.ioi_accounting.doctype.ioi_analytic_1.ioi_analytic_1';
    path_analytic_2 = 'silicon_ioi.ioi_accounting.doctype.ioi_analytic_2.ioi_analytic_2';
    path_analytic_3 = 'silicon_ioi.ioi_accounting.doctype.ioi_analytic_3.ioi_analytic_3';
    path_analytic_4 = 'silicon_ioi.ioi_accounting.doctype.ioi_analytic_4.ioi_analytic_4';
    path_item_unit = 'silicon_ioi.ioi_configuration.doctype.ioi_item_unit.ioi_item_unit';
	path_sales_price = 'silicon_ioi.ioi_items.doctype.ioi_sales_price.ioi_sales_price';
	path_user_change_status_action = 'silicon_ioi.ioi_system.doctype.ioi_user_change_status_action.ioi_user_change_status_action';
	path_vat_matching = 'silicon_ioi.ioi_accounting.doctype.ioi_vat_matching.ioi_vat_matching';
    path_item = 'silicon_ioi.ioi_items.doctype.ioi_item.ioi_item';
	path_item_budget = 'silicon_ioi.ioi_configuration.doctype.ioi_item_budget.ioi_item_budget';
	path_manufacturer_catalog = 'silicon_ioi.ioi_items.doctype.ioi_manufacturer_catalog.ioi_manufacturer_catalog';
	path_cash_desk = 'silicon_ioi.ioi_configuration.doctype.ioi_cash_desk.ioi_cash_desk';
	path_cash_desk_movement = 'silicon_ioi.ioi_configuration.doctype.ioi_cash_desk_movement.ioi_cash_desk_movement';
	path_search_definition = 'silicon_ioi.ioi_configuration.doctype.ioi_module_search_definition.ioi_module_search_definition'
	path_sales_pos = 'silicon_ioi.ioi_sales.doctype.ioi_sales_pos.ioi_sales_pos';


	is_dark_mode = 0;

	static show_margin = 0;

	user_language = '';
	language_1 = '';
	language_2 = '';
	language_3 = '';
	language_4 = '';

	system_currency = '';
	system_currency_rate = 1;
	system_currency_rate_inv = 1;
	system_currency_digit_rounding = 8;

	customer_base_discount_in_header = 0;
	customer_extra_discount_in_header = 0;
	use_triggered_budget_discount = 0;
	customer_manual_sales_condition_id = 'MANUAL';

	journal_division_id = '';
	journal_site_id = '';
	journal_sales_catalog_id = '';
	journal_sales_condition_id = '';
	journal_warehouse_id = '';
	journal_unit_price_decimal_number = 2
	journal_vat_type = '';
	journal_spec_vat_rate = 0
	journal_vat_included_control = 0;
	journal_sepa_qr_code_active = 0
	journal_default_alert_margin_rate = 0;
	journal_margin_mode = 0;
	journal_display_margin = 0;


	journal_quote_to_order_mode = 1
	journal_order_to_delivery_mode = 1
	journal_order_to_delivery_qty_rule = 3
	journal_delivery_to_invoice_mode = 1

	journal_quotes_prefix = '';
	journal_quotes_last_number = 0;
	journal_quotes_nb_digits = 0;
	journal_quotes_sublevel_separator = '.';
	journal_quotes_sublevel_nb_digits = 0;
	journal_quotes_period_break_numbering = 0;
	journal_quotes_period_break_position = 0;
	journal_quotes_max_answer_delay = 0;
	journal_quotes_min_delivery_days = 0;
	journal_quotes_min_margin = 0;
	journal_quotes_default_mono_dossier = 0;
	journal_quotes_account_analytic1_mandatory = 0;
	journal_quotes_accountid_mandatory = 0;
	journal_quotes_account_analytic2_mandatory = 0;
	journal_quotes_fill_thumbnail = 0;
	journal_quotes_account_analytic3_mandatory = 0;
	journal_quotes_fill_memo = 0;
	journal_quotes_account_analytic4_mandatory = 0;
	journal_quotes_disabled = 0;
	journal_quotes_valid_delay_mode = '';
	journal_quotes_valid_delay = 0;
	journal_quotes_route_for_order_journal = '';
	journal_quotes_do_not_apply_index = 0;
	journal_quotes_approval_scheme_id = '';
	journal_quotes_approval_default_delay = 0;
	journal_quotes_approval_required = 0;


	journal_contracts_prefix = '';
	journal_contracts_last_number = 0;
	journal_contracts_nb_digits = 0;
	journal_contracts_sublevel_separator = '.';
	journal_contracts_sublevel_nb_digits = 0;
	journal_contracts_period_break_numbering = 0;
	journal_contracts_period_break_position = 0;
	journal_contracts_disabled = 0;
	journal_contracts_valid_delay_mode = '';
	journal_contracts_valid_delay = 0;
	journal_contracts_default_mono_dossier = 0;
	journal_contracts_account_analytic1_mandatory = 0;
	journal_contracts_accountid_mandatory = 0;
	journal_contracts_account_analytic2_mandatory = 0;
	journal_contracts_fill_thumbnail = 0;
	journal_contracts_account_analytic3_mandatory = 0;
	journal_contracts_fill_memo = 0;
	journal_contracts_account_analytic4_mandatory = 0;
	journal_contracts_do_not_apply_index = 0;
	journal_contracts_approval_scheme_id = '';
	journal_contracts_approval_default_delay = 0;
	journal_contracts_approval_required = 0;


	journal_orders_prefix = '';
	journal_orders_last_number = 0;
	journal_orders_nb_digits = 0;
	journal_orders_sublevel_separator = '.';
	journal_orders_sublevel_nb_digits = 0;
	journal_orders_period_break_numbering = 0;
	journal_orders_period_break_position = 0;
	journal_orders_min_delivery_days = 0;
	journal_orders_min_margin = 0;
	journal_orders_default_mono_dossier = 0;
	journal_orders_min_disposal_item_days = 1
	journal_orders_min_disposal_item_hours = 0
	journal_orders_delivery_start_day_at = '08:30:00'
	journal_orders_reservation_allowed = 0;
	journal_orders_account_analytic1_mandatory = 0;
	journal_orders_accountid_mandatory = 0;
	journal_orders_account_analytic2_mandatory = 0;
	journal_orders_fill_thumbnail = 0;
	journal_orders_account_analytic3_mandatory = 0;
	journal_orders_fill_memo = 0;
	journal_orders_account_analytic4_mandatory = 0;
	journal_orders_disabled = 0;
	journal_orders_valid_delay_mode = '';
	journal_orders_valid_delay = 0;
	journal_orders_route_for_delivery_journal = ''
	journal_orders_do_not_apply_index = 0;
	journal_orders_approval_scheme_id = '';
	journal_orders_approval_default_delay = 0;
	journal_orders_approval_required = 0;
	journal_orders_cashdesk_active = 0;

	journal_orders_pass_through_supplier_id = '';
	journal_orders_pass_through_mode = 0;
	journal_orders_path_through_discard_lines_without_qty = 0;
	journal_orders_pass_through_mirror_virtual_items = 0;
	journal_orders_pass_through_purchases_journal_id = ''



	journal_deliveries_prefix = '';
	journal_deliveries_last_number = 0;
	journal_deliveries_nb_digits = 0;
	journal_deliveries_sublevel_separator = '.';
	journal_deliveries_sublevel_nb_digits = 0;
	journal_deliveries_period_break_numbering = 0;
	journal_deliveries_period_break_position = 0;
	journal_deliveries_reservation_allowed = 0;
	journal_deliveries_enable_price_access = 0;
	journal_deliveries_enable_price_access_role = '';
	journal_deliveries_negative_qty_warehouse = '';
	journal_deliveries_no_price_alert = 0;
	journal_deliveries_account_analytic1_mandatory = 0;
	journal_deliveries_accountid_mandatory = 0;
	journal_deliveries_account_analytic2_mandatory = 0;
	journal_deliveries_fill_thumbnail = 0;
	journal_deliveries_account_analytic3_mandatory = 0;
	journal_deliveries_fill_memo = 0;
	journal_deliveries_account_analytic4_mandatory = 0;
	journal_deliveries_disabled = 0;
	journal_deliveries_route_for_invoice_journal = '';
	journal_deliveries_do_not_apply_index = 0;
	journal_deliveries_qty_to_prepare_mode = 0;
	journal_deliveries_approval_scheme_id = '';
	journal_deliveries_approval_default_delay = 0;
	journal_deliveries_approval_required = 0;

	journal_deliveries_pos_customer_id = '';
	journal_deliveries_cashdesk_active = 0;
	journal_deliveries_lock_payment_terms = 0;





	journal_invoices_prefix = '';
	journal_invoices_last_number = 0;
	journal_invoices_nb_digits = 0;
	journal_invoices_sublevel_separator = '.';
	journal_invoices_sublevel_nb_digits = 0;
	journal_invoices_period_break_numbering = 0;
	journal_invoices_period_break_position = 0;
	journal_invoices_vcs_mode = 0
	journal_invoices_vcs_param = 0
	journal_invoices_allow_proposal = 0;
	journal_invoices_enable_price_access = 0;
	journal_invoices_enable_price_access_role = '';
	journal_invoices_no_price_alert = 0;
	journal_invoices_account_analytic1_mandatory = 0;
	journal_invoices_accountid_mandatory = 0;
	journal_invoices_account_analytic2_mandatory = 0;
	journal_invoices_fill_thumbnail = 0;
	journal_invoices_account_analytic3_mandatory = 0;
	journal_invoices_fill_memo = 0;
	journal_invoices_account_analytic4_mandatory = 0;
	journal_invoices_disabled = 0;
	journal_invoices_route_for_accounting_journal = '';
	journal_invoices_do_not_apply_index = 0;
	journal_invoices_approval_scheme_id = '';
	journal_invoices_default_delay = 0;
	journal_invoices_approval_required = 0;
	journal_invoices_cashdesk_active = 0;



	journal_cnotes_prefix = '';
	journal_cnotes_last_number = 0;
	journal_cnotes_nb_digits = 0;
	journal_cnotes_sublevel_separator = '.';
	journal_cnotes_sublevel_nb_digits = 0;
	journal_cnotes_period_break_numbering = 0;
	journal_cnotes_period_break_position = 0;
	journal_cnotes_vcs_mode = 0
	journal_cnotes_vcs_param = 0
	journal_cnotes_allow_proposal = 0;
	journal_cnotes_enable_price_access = 0;
	journal_cnotes_enable_price_access_role = '';
	journal_cnotes_no_price_alert = 0;
	journal_cnotes_account_analytic1_mandatory = 0;
	journal_cnotes_accountid_mandatory = 0;
	journal_cnotes_account_analytic2_mandatory = 0;
	journal_cnotes_fill_thumbnail = 0;
	journal_cnotes_account_analytic3_mandatory = 0;
	journal_cnotes_fill_memo = 0;
	journal_cnotes_account_analytic4_mandatory = 0;
	journal_cnotes_disabled = 0;
	journal_cnotes_route_for_accounting_journal = '';
	journal_cnotes_do_not_apply_index = 0;
	journal_cnotes_approval_scheme_id = '';
	journal_cnotes_default_delay = 0;
	journal_cnotes_approval_required = 0;
	journal_cnotes_cashdesk_active = 0;

	DocumentStatus = Object;
    ioistatus = [];
	ioiuserstatus = [];

    vat_type = [];
	vat_service = [];
	vat_rate = [];
    document_type = [];
    prefixes = [];
    address_mode = [];
	valid_delay_mode = [];
	source_color = [];


	available_status = [];
	available_scheduled_status = [];

	static warehouse_location = [];
	static batch_id = [];

	static static_customer_manual_sales_condition_id = 'MANUAL';
	static static_use_triggered_budget_discount = 0;
	static static_is_dark_mode = 0;
	static vt_data_up = false;

	default_cash_desk_id = '';

	// ***************************************************************************************************************************************
	// Constructor
	// ***************************************************************************************************************************************
	constructor(frm)
	{
		super(frm);

		let me = this;

		this.is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

		this.DocumentStatus = new ioiDocumentStatus(frm);
		this.DocumentStatus.fill_status();
		this.ioistatus = this.DocumentStatus.ioistatus;

		this.#load_source_color();

		setTimeout(silicon_ioi.doctype.ioiSalesDocumentDocType.set_static_grid_layout(), 500)
	}



	// ***************************************************************************************************************************************
	// Load Source color
	// ***************************************************************************************************************************************
	#load_source_color()
	{
		this.source_color = [];
		this.source_color[0] = ['CATALOG',      '#C2FFCE', __('Catalog')];
		this.source_color[1] = ['CATALOG_QTY',  '#C2FFFB', __('Catalog qty')];
		this.source_color[2] = ['FTG', 		    '#F6FDA5', __('FTG')];
		this.source_color[3] = ['CUSTOMER',     '#C2ECFF', __('Customer')];
		this.source_color[4] = ['CUSTOMER_QTY', '#A8BFCA', __('Customer qty')];

		if (document.getElementById('select_source_id'))
		{	document.getElementById('select_source_id').remove();
		}

		if (document.getElementById('select_source_color'))
		{	document.getElementById('select_source_color').remove();
		}


		let html = '<select id="select_source_id" name="select_source_id" hidden></select>';
		html += '<select id="select_source_color" name="select_source_color" hidden></select>';

		if (this.frm.$wrapper[0].childElementCount < 3)
		{	this.frm.$wrapper[0].children[1].children[1].children[0].insertAdjacentHTML('beforeend', html);
		}else
		{	this.frm.$wrapper[0].children[3].children[1].children[0].insertAdjacentHTML('beforeend', html);
		}

		for (var i = 0; i < this.source_color.length; i++)
		{
			let option1 = document.createElement("option");
			option1.text = this.source_color[i][0];
			document.getElementById("select_source_id").add(option1)

			let option2 = document.createElement("option");
			option2.text = this.source_color[i][1];
			document.getElementById("select_source_color").add(option2)

		}
	}

	// ***************************************************************************************************************************************
	// Can access to the module
	// ***************************************************************************************************************************************
	can_access_to_document(doctype, document_type, prefix_id)
	{
		let can_access = false;

		let me = this;

		let method = this.path_sales_document + '.ioi_sales_document_can_access_to_document';

		frappe.call({  	method: method,
						args: {	"doctype" : doctype,
								"document_type": document_type,
								"prefix_id": prefix_id},
						async: false,
						callback:function(r)	{

							if (r.message == 1) {
								can_access = true;
							}

						}
		});

		return can_access;
	}



	// ***************************************************************************************************************************************
	// Load General Settings
	// ***************************************************************************************************************************************
	load_general_settings()
	{
		let me = this;

		let method = this.path_general_settings + '.ioi_general_settings_get_data';

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{
													me.system_currency = r.message.currency;

													if (me.system_currency.trim() != '')
													{
														method = me.path_currency + '.ioi_currency_get_data';

														frappe.call({  	method: method,
																		args: {"currency_id": me.system_currency},
																		async: false,
																		callback:function(r)	{
                                                                                                    me.language_1 = r.message.language_1;
                                                                                                    me.language_2 = r.message.language_2;
                                                                                                    me.language_3 = r.message.language_3;
                                                                                                    me.language_4 = r.message.language_4;
																									me.system_currency_rate = r.message.sales_rate;
																									me.system_currency_rate_inv = r.message.sales_rate_inv;
																									me.system_currency_digit_rounding = r.message.decimals;
																								}
														});
													}
						}
		});
	}

	// ***************************************************************************************************************************************
	// Load Sales Settings
	// ***************************************************************************************************************************************
	load_sales_settings()
	{
		let me = this;

		let method = this.path_sales_settings + '.ioi_sales_settings_get_data';

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{
							me.customer_base_discount_in_header = r.message.customer_base_discount_in_header;
							me.customer_extra_discount_in_header = r.message.customer_extra_discount_in_header;
							me.customer_manual_sales_condition_id = r.message.customer_manual_sales_condition_id;
							me.use_triggered_budget_discount = r.message.use_triggered_budget_discount;
							silicon_ioi.doctype.ioiSalesDocumentDocType.static_customer_manual_sales_condition_id = r.message.customer_manual_sales_condition_id;
							silicon_ioi.doctype.ioiSalesDocumentDocType.static_use_triggered_budget_discount = r.message.use_triggered_budget_discount;
						}
		});
	}

	// ***************************************************************************************************************************************
	// Load Sales Journal data
	// ***************************************************************************************************************************************
	load_sales_journal_data()
	{
		if ((this.frm.doc.journal_id) && (this.frm.doc.journal_id.trim() != ''))
		{
			let me = this;

			let method = this.path_sales_journal + '.ioi_sales_journal_get_data';

			frappe.call({  	method: method,
							args: { "journal_id": this.frm.doc.journal_id,
									"dynamic_dt": this.frm.doc.document_date},
							async: false,
							callback:function(r)	{

								me.user_language = r.message.user_language;

								// General
								me.journal_division_id = r.message.division_id;
								me.journal_site_id = r.message.site_id;
								me.journal_sales_catalog_id = r.message.sales_catalog_id;
								me.journal_sales_condition_id = r.message.sales_condition_id;
								me.journal_warehouse_id = r.message.warehouse_id;
								me.journal_unit_price_decimal_number = r.message.unit_price_decimal_number;
								me.journal_vat_type = r.message.vat_type;
								me.journal_spec_vat_rate = r.message.spec_vat_rate;
								me.journal_vat_included_control = r.message.vat_included_control;

								me.journal_quote_to_order_mode = r.message.quote_to_order_mode;
								me.journal_order_to_delivery_mode = r.message.order_to_delivery_mode;
								me.journal_order_to_delivery_qty_rule = r.message.order_to_delivery_qty_rule;
								me.journal_delivery_to_invoice_mode = r.message.delivery_to_invoice_mode;
								me.journal_sepa_qr_code_active = r.message.sepa_qr_code_active;
								me.journal_default_alert_margin_rate = r.message.default_alert_margin_rate;
								me.journal_margin_mode = r.message.margin_mode;
								me.journal_display_margin = r.message.display_margin;

								if (me.journal_display_margin == 1) {
									silicon_ioi.doctype.ioiSalesDocumentDocType.show_margin = 1;
								}else{
									silicon_ioi.doctype.ioiSalesDocumentDocType.show_margin = 0;
								}


								// Quotes
								me.journal_quotes_prefix = r.message.quotes_prefix;
								me.journal_quotes_last_number = r.message.quotes_last_number;
								me.journal_quotes_nb_digits = r.message.quotes_nb_digits;
								me.journal_quotes_sublevel_separator = r.message.quotes_sublevel_separator;
								me.journal_quotes_sublevel_nb_digits = r.message.quotes_sublevel_nb_digits;
								me.journal_quotes_period_break_numbering = r.message.quotes_period_break_numbering;
								me.journal_quotes_period_break_position = r.message.quotes_period_break_position;
								me.journal_quotes_max_answer_delay = r.message.quotes_max_answer_delay;
								me.journal_quotes_min_delivery_days = r.message.quotes_min_delivery_days;
								me.journal_quotes_min_margin = r.message.quotes_min_margin;
								me.journal_quotes_default_mono_dossier = r.message.quotes_default_mono_dossier;
								me.journal_quotes_account_analytic1_mandatory = r.message.quotes_account_analytic1_mandatory;
								me.journal_quotes_accountid_mandatory = r.message.quotes_accountid_mandatory;
								me.journal_quotes_account_analytic2_mandatory = r.message.quotes_account_analytic2_mandatory;
								me.journal_quotes_fill_thumbnail = r.message.quotes_fill_thumbnail;
								me.journal_quotes_account_analytic3_mandatory = r.message.quotes_account_analytic3_mandatory;
								me.journal_quotes_fill_memo = r.message.quotes_fill_memo;
								me.journal_quotes_account_analytic4_mandatory = r.message.quotes_account_analytic4_mandatory;
								me.journal_quotes_disabled = r.message.quotes_disabled;
								me.journal_quotes_valid_delay_mode = r.message.quotes_valid_delay_mode;
								me.journal_quotes_valid_delay = r.message.quotes_valid_delay;
								me.journal_quotes_route_for_order_journal = r.message.quotes_route_for_order_journal;
								me.journal_quotes_do_not_apply_index = r.message.quotes_do_not_apply_index;
								me.journal_quotes_approval_scheme_id = r.message.quotes_approval_scheme_id;
								me.journal_quotes_approval_default_delay = r.message.quotes_approval_default_delay;
								me.journal_quotes_approval_required = r.message.quotes_approval_required;



								// Contracts
								me.journal_contracts_prefix = r.message.contracts_prefix;
								me.journal_contracts_last_number = r.message.contracts_last_number;
								me.journal_contracts_nb_digits = r.message.contracts_nb_digits;
								me.journal_contracts_sublevel_separator = r.message.contracts_sublevel_separator;
								me.journal_contracts_sublevel_nb_digits = r.message.contracts_sublevel_nb_digits;
								me.journal_contracts_period_break_numbering	= r.message.contracts_period_break_numbering;
								me.journal_contracts_period_break_position = r.message.contracts_period_break_position;
								me.journal_contracts_disabled = r.message.contracts_disabled;
								me.journal_contracts_valid_delay_mode = r.message.contracts_valid_delay_mode;
								me.journal_contracts_valid_delay = r.message.contracts_valid_delay;
								me.journal_contracts_default_mono_dossier = r.message.contracts_default_mono_dossier;
								me.journal_contracts_account_analytic1_mandatory = r.message.contracts_account_analytic1_mandatory;
								me.journal_contracts_accountid_mandatory = r.message.contracts_accountid_mandatory;
								me.journal_contracts_account_analytic2_mandatory = r.message.contracts_account_analytic2_mandatory;
								me.journal_contracts_fill_thumbnail = r.message.contracts_fill_thumbnail;
								me.journal_contracts_account_analytic3_mandatory = r.message.contracts_account_analytic3_mandatory;
								me.journal_contracts_fill_memo = r.message.contracts_fill_memo;
								me.journal_contracts_account_analytic4_mandatory = r.message.contracts_account_analytic4_mandatory;
								me.journal_contracts_do_not_apply_index = r.message.contracts_do_not_apply_index;
								me.journal_contracts_approval_scheme_id = r.message.contracts_approval_scheme_id;
								me.journal_contracts_approval_default_delay = r.message.contracts_approval_default_delay;
								me.journal_contracts_approval_required = r.message.contracts_approval_required;



								// Orders
								me.journal_orders_prefix = r.message.orders_prefix;
								me.journal_orders_last_number = r.message.orders_last_number;
								me.journal_orders_nb_digits = r.message.orders_nb_digits;
								me.journal_orders_sublevel_separator = r.message.orders_sublevel_separator;
								me.journal_orders_sublevel_nb_digits = r.message.orders_sublevel_nb_digits;
								me.journal_orders_period_break_numbering = r.message.orders_period_break_numbering;
								me.journal_orders_period_break_position = r.message.orders_period_break_position;
								me.journal_orders_min_delivery_days = r.message.orders_min_delivery_days;
								me.journal_orders_min_margin = r.message.orders_min_margin;


								me.journal_orders_default_mono_dossier = r.message.orders_default_mono_dossier;
								me.journal_orders_min_disposal_item_days = r.message.orders_min_disposal_item_days
								me.journal_orders_min_disposal_item_hours = r.message.orders_min_disposal_item_hours
								me.journal_orders_delivery_start_day_at = r.message.orders_delivery_start_day_at
								me.journal_orders_reservation_allowed = r.message.orders_reservation_allowed;
								me.journal_orders_account_analytic1_mandatory = r.message.orders_account_analytic1_mandatory;
								me.journal_orders_accountid_mandatory = r.message.orders_accountid_mandatory;
								me.journal_orders_account_analytic2_mandatory = r.message.orders_account_analytic2_mandatory;
								me.journal_orders_fill_thumbnail = r.message.orders_fill_thumbnail;
								me.journal_orders_account_analytic3_mandatory = r.message.orders_account_analytic3_mandatory;
								me.journal_orders_fill_memo = r.message.orders_fill_memo;
								me.journal_orders_account_analytic4_mandatory = r.message.orders_account_analytic4_mandatory;
								me.journal_orders_disabled = r.message.orders_disabled;
								me.journal_orders_route_for_delivery_journal = r.message.orders_route_for_delivery_journal;
								me.journal_orders_do_not_apply_index = r.message.orders_do_not_apply_index;
								me.journal_orders_approval_scheme_id = r.message.orders_approval_scheme_id;
								me.journal_orders_approval_default_delay = r.message.orders_approval_default_delay;
								me.journal_orders_approval_required = r.message.orders_approval_required;
								me.journal_orders_cashdesk_active = r.message.orders_cashdesk_active;
								me.journal_orders_pass_through_supplier_id =  r.message.orders_pass_through_supplier_id;
								me.journal_orders_pass_through_mode =  r.message.orders_pass_through_mode;
								me.journal_orders_path_through_discard_lines_without_qty =  r.message.orders_path_through_discard_lines_without_qty;
								me.journal_orders_pass_through_mirror_virtual_items =  r.message.orders_pass_through_mirror_virtual_items;
								me.journal_orders_pass_through_purchases_journal_id = r.message.orders_pass_through_purchases_journal_id




								// Deliveries
								me.journal_deliveries_prefix = r.message.deliveries_prefix;
								me.journal_deliveries_last_number = r.message.deliveries_last_number;
								me.journal_deliveries_nb_digits = r.message.deliveries_nb_digits;
								me.journal_deliveries_sublevel_separator = r.message.deliveries_sublevel_separator;
								me.journal_deliveries_sublevel_nb_digits = r.message.deliveries_sublevel_nb_digits;
								me.journal_deliveries_period_break_numbering = r.message.deliveries_period_break_numbering;
								me.journal_deliveries_period_break_position = r.message.deliveries_period_break_position;
								me.journal_deliveries_reservation_allowed = r.message.deliveries_reservation_allowed;
								me.journal_deliveries_enable_price_access = r.message.deliveries_enable_price_access;
								me.journal_deliveries_enable_price_access_role = r.message.deliveries_enable_price_access_role;
								me.journal_deliveries_negative_qty_warehouse = r.message.deliveries_negative_qty_warehouse;
								me.journal_deliveries_no_price_alert = r.message.deliveries_no_price_alert;
								me.journal_deliveries_account_analytic1_mandatory = r.message.deliveries_account_analytic1_mandatory;
								me.journal_deliveries_accountid_mandatory = r.message.deliveries_accountid_mandatory;
								me.journal_deliveries_account_analytic2_mandatory = r.message.deliveries_account_analytic2_mandatory;
								me.journal_deliveries_fill_thumbnail = r.message.deliveries_fill_thumbnail;
								me.journal_deliveries_account_analytic3_mandatory = r.message.deliveries_account_analytic3_mandatory;
								me.journal_deliveries_fill_memo = r.message.deliveries_fill_memo;
								me.journal_deliveries_account_analytic4_mandatory = r.message.deliveries_account_analytic4_mandatory;
								me.journal_deliveries_disabled = r.message.deliveries_disabled;
								me.journal_deliveries_route_for_invoice_journal = r.message.deliveries_route_for_invoice_journal;
								me.journal_deliveries_do_not_apply_index = r.message.deliveries_do_not_apply_index;
								me.journal_deliveries_qty_to_prepare_mode = r.message.deliveries_qty_to_prepare_mode;
								me.journal_deliveries_approval_scheme_id = r.message.deliveries_approval_scheme_id;
								me.journal_deliveries_approval_default_delay = r.message.deliveries_approval_default_delay;
								me.journal_deliveries_approval_required = r.message.deliveries_approval_required;
								me.journal_deliveries_pos_customer_id = r.message.deliveries_pos_customer_id;
								me.journal_deliveries_cashdesk_active = r.message.deliveries_cashdesk_active;
								me.journal_deliveries_lock_payment_terms = r.message.deliveries_lock_payment_terms;





								// Invoices
								me.journal_invoices_prefix = r.message.invoices_prefix;
								me.journal_invoices_last_number = r.message.invoices_last_number;
								me.journal_invoices_nb_digits = r.message.invoices_nb_digits;
								me.journal_invoices_sublevel_separator = r.message.invoices_sublevel_separator;
								me.journal_invoices_sublevel_nb_digits = r.message.invoices_sublevel_nb_digits;
								me.journal_invoices_period_break_numbering = r.message.invoices_period_break_numbering;
								me.journal_invoices_period_break_position = r.message.invoices_period_break_position;
								me.journal_invoices_vcs_mode = r.message.invoices_vcs_mode;
								me.journal_invoices_vcs_param = r.message.invoices_vcs_param;
								me.journal_invoices_allow_proposal = r.message.invoices_allow_proposal;
								me.journal_invoices_enable_price_access = r.message.invoices_enable_price_access;
								me.journal_invoices_enable_price_access_role = r.message.invoices_enable_price_access_role;
								me.journal_invoices_no_price_alert = r.message.invoices_no_price_alert;
								me.journal_invoices_account_analytic1_mandatory = r.message.invoices_account_analytic1_mandatory;
								me.journal_invoices_accountid_mandatory = r.message.invoices_accountid_mandatory;
								me.journal_invoices_account_analytic2_mandatory = r.message.invoices_account_analytic2_mandatory;
								me.journal_invoices_fill_thumbnail = r.message.invoices_fill_thumbnail;
								me.journal_invoices_account_analytic3_mandatory = r.message.invoices_account_analytic3_mandatory;
								me.journal_invoices_fill_memo = r.message.invoices_fill_memo;
								me.journal_invoices_account_analytic4_mandatory = r.message.invoices_account_analytic4_mandatory;
								me.journal_invoices_disabled = r.message.invoices_disabled;
								me.journal_invoices_route_for_accounting_journal = r.message.invoices_route_for_accounting_journal;
								me.journal_invoices_do_not_apply_index = r.message.invoices_do_not_apply_index;
								me.journal_invoices_approval_scheme_id = r.message.invoices_approval_scheme_id;
								me.journal_invoices_approval_default_delay = r.message.invoices_approval_default_delay;
								me.journal_invoices_approval_required = r.message.invoices_approval_required;
								me.journal_invoices_cashdesk_active = r.message.invoices_cashdesk_active;



								// Credit Notes
								me.journal_cnotes_prefix = r.message.cnotes_prefix;
								me.journal_cnotes_last_number = r.message.cnotes_last_number;
								me.journal_cnotes_nb_digits = r.message.cnotes_nb_digits;
								me.journal_cnotes_sublevel_separator = r.message.cnotes_sublevel_separator;
								me.journal_cnotes_sublevel_nb_digits = r.message.cnotes_sublevel_nb_digits;
								me.journal_cnotes_period_break_numbering = r.message.cnotes_period_break_numbering;
								me.journal_cnotes_period_break_position = r.message.cnotes_period_break_position;
								me.journal_cnotes_vcs_mode = r.message.cnotes_vcs_mode;
								me.journal_cnotes_vcs_param = r.message.cnotes_vcs_param;
								me.journal_cnotes_allow_proposal = r.message.cnotes_allow_proposal;
								me.journal_cnotes_enable_price_access = r.message.cnotes_enable_price_access;
								me.journal_cnotes_enable_price_access_role = r.message.cnotes_enable_price_access_role;
								me.journal_cnotes_no_price_alert = r.message.cnotes_no_price_alert;
								me.journal_cnotes_account_analytic1_mandatory = r.message.cnotes_account_analytic1_mandatory;
								me.journal_cnotes_accountid_mandatory = r.message.cnotes_accountid_mandatory;
								me.journal_cnotes_account_analytic2_mandatory = r.message.cnotes_account_analytic2_mandatory;
								me.journal_cnotes_fill_thumbnail = r.message.cnotes_fill_thumbnail;
								me.journal_cnotes_account_analytic3_mandatory = r.message.cnotes_account_analytic3_mandatory;
								me.journal_cnotes_fill_memo = r.message.cnotes_fill_memo;
								me.journal_cnotes_account_analytic4_mandatory = r.message.cnotes_account_analytic4_mandatory;
								me.journal_cnotes_disabled = r.message.cnotes_disabled;
								me.journal_cnotes_route_for_accounting_journal = r.message.cnotes_route_for_accounting_journal;
								me.journal_cnotes_do_not_apply_index = r.message.cnotes_do_not_apply_index;
								me.journal_cnotes_approval_scheme_id = r.message.cnotes_approval_scheme_id;
								me.journal_cnotes_approval_default_delay = r.message.cnotes_approval_default_delay;
								me.journal_cnotes_approval_required = r.message.cnotes_approval_required;
								me.journal_cnotes_cashdesk_active = r.message.cnotes_cashdesk_active;



							}
			});
		}
	}

	// ***************************************************************************************************************************************
	// Load User Status
	// ***************************************************************************************************************************************
	fill_ioiuserstatus()
	{
		this.ioiuserstatus = [];

		let me = this;

		let method = this.path_module_user_status + '.ioi_module_user_status_get_list';

		frappe.call({  	method: method,
						args: {"doctype": this.frm.doctype},
						async: false,
						callback:function(r)	{
													if (r.message.length != 0)
													{
														me.ioiuserstatus = [];

														for (var i = 0; i < r.message.length; i++)
														{	//                    code            description       bgcolor          fontcolor
															me.ioiuserstatus[i] = [r.message[i][2],r.message[i][5], r.message[i][3], r.message[i][4]];

														}
													}

													if (me.ioiuserstatus.length == 0)
													{	me.ioiuserstatus[0] = [0, 'Undefined', '#C3C2C4', '#000000'];
													}else
													{
														let found_zero = false;

														for (var i = 0; i < me.ioiuserstatus.length; i++)
														{
															if (me.ioiuserstatus[i][0] == 0)
															{
																found_zero = true;
																break;
															}
														}

														if (!found_zero)
														{	me.ioiuserstatus[me.ioiuserstatus.length] = [0, 'Undefined', '#C3C2C4', '#000000'];
														}
													}
												}
		});
	}

	// ***************************************************************************************************************************************
	// Refresh intersite transaction
	// ***************************************************************************************************************************************
	refresh_intersite_transaction()
	{
		let html = '';

		this.frm.fields_dict['html_intersite_transaction'].$wrapper.empty();

		if (this.frm.doc.intersite_transaction == 1) {

			let destination_site_id = this.frm.doc.intersite_destination_site_id;


			let bgcolor = '#FFFF94';
			let color = '#000000';
			let description = __('Intersite') + ' ' + this.frm.doc.site_id + ' ' + __("<->") + ' ' + destination_site_id;

			html = '<table width=100%>';
			html += '<tr>';
			html += '<td width=100%>';
			html += '<div style="height:32px; width:100%; background-color:' + bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;" align="center">';
			html += '<label style="position:absolute;left:0px;top:8px;width:100%;font-size:14px;color:' + color +'" align="center"><b>'+ description +'</b></label>';
			html += '</div></td>';

			html += '</tr>';

			html += '</table>';

			this.frm.fields_dict['html_intersite_transaction'].$wrapper.append(html);
			this.frm.fields_dict['intersite_section'].wrapper[0].hidden = false;
		}else{
			if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {

				if (this.frm.doc.created_from == 'ioi Sales POS') {


					let bgcolor = '#FCB99A';
					let color = '#000000';
					let description = __('Point of sale');

					html = '<table width=100%>';
					html += '<tr>';
					html += '<td width=100%>';
					html += '<div style="height:32px; width:100%; background-color:' + bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;" align="center">';
					html += '<label style="position:absolute;left:0px;top:8px;width:100%;font-size:14px;color:' + color +'" align="center"><b>'+ description +'</b></label>';
					html += '</div></td>';

					html += '</tr>';

					html += '</table>';

					this.frm.fields_dict['html_intersite_transaction'].$wrapper.append(html);
					this.frm.fields_dict['intersite_section'].wrapper[0].hidden = false;

				}else{
					this.frm.fields_dict['intersite_section'].wrapper[0].hidden = true;
				}
			}else {
				this.frm.fields_dict['intersite_section'].wrapper[0].hidden = true;
			}
		}

	}


	// ***************************************************************************************************************************************
	// Refresh ioiuserstatus (User Status)
	// ***************************************************************************************************************************************
	refresh_ioiuserstatus()
	{
		let html = '';
		this.frm.fields_dict['html_ioiuserstatus'].$wrapper.empty();


		let bgcolor = '';
		let color = '';
		let description = '';

		let cur_userstatus = 0;

		if (this.frm.doc.ioiuserstatus)
		{	cur_userstatus = this.frm.doc.ioiuserstatus;
		}

		for (var i = 0; i < this.ioiuserstatus.length; i++)
		{

			if (cur_userstatus == this.ioiuserstatus[i][0])
			{
				description = this.ioiuserstatus[i][1];
				bgcolor = this.ioiuserstatus[i][2];
				color = this.ioiuserstatus[i][3];
				break;
			}
		}


		html = '<table width=100%>';
		html += '<tr>';
		html += '<td width=100% height=20px><font style="font-size:12px;">'+ __("User status") +'</font></td>';
		html += '</tr>';

		html += '<tr>';
		html += '<td width=100%>';
		html += '<div style="height:32px; background-color:' + bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
		html += '<b><font style="font-size:14px;color:' + color +'">&nbsp;'+ __(description) +'</b></font>';
		html += '</div></td>';

		html += '</tr>';

		html += '<tr>';
		html += '<td width=100% height=7px>&nbsp;</td>';
		html += '</tr>';

		html += '</table>';

		this.frm.fields_dict['html_ioiuserstatus'].$wrapper.append(html);

	}


	// ***************************************************************************************************************************************
	// Refresh Approval_status
	// ***************************************************************************************************************************************
	refresh_approval_status()
	{
		let html = '';
		this.frm.fields_dict['approval_html_status'].$wrapper.empty();

		let description = '';
		let bgcolor = '#DFDEE0';
		let color = '#000000';

		if ((!this.frm.doc.approval_status) || ((this.frm.doc.approval_status) && (this.frm.doc.approval_status.trim() == ''))) {
			description = __('Not applicable');
			bgcolor = '#DFDEE0';
		}else if ((this.frm.doc.approval_status) && (this.frm.doc.approval_status.trim() == 0)) {
			description = __('Not applicable');
			bgcolor = '#DFDEE0';
		}else if ((this.frm.doc.approval_status) && (this.frm.doc.approval_status.trim() == 1)) {
			description = __('Required');
			bgcolor = '#FEFFAD';
		}else if ((this.frm.doc.approval_status) && (this.frm.doc.approval_status.trim() == 2)) {
			description = __('Partial approved');
			bgcolor = '#FAC67D';
		}else if ((this.frm.doc.approval_status) && (this.frm.doc.approval_status.trim() == 3)) {
			description = __('Approved');
			bgcolor = '#97F779';
		}else if ((this.frm.doc.approval_status) && (this.frm.doc.approval_status.trim() == 4)) {
			description = __('Refused');
			bgcolor = '#FA6557';
		}

		html = '<table width=100%>';
		html += '<tr>';
		html += '<td width=100% height=20px><font style="font-size:12px;">'+ __("Approval status") +'</font></td>';
		html += '</tr>';

		html += '<tr>';
		html += '<td width=100%>';
		html += '<div style="height:32px; background-color:' + bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
		html += '<b><font style="font-size:14px;color:' + color +'">&nbsp;';

		html += description + '</b></font>';
		html += '</div></td>';

		html += '</tr>';

		html += '<tr>';
		html += '<td width=100% height=7px>&nbsp;</td>';
		html += '</tr>';

		html += '</table>';

		this.frm.fields_dict['approval_html_status'].$wrapper.append(html);

	}



	// ***************************************************************************************************************************************
	// Change user Status
	// ***************************************************************************************************************************************
	change_user_status()
	{
		if (this.frm.is_dirty())
		{
			let me = this;
			let fct_callback = function () { me.do_change_user_status(); };
			this.frm.save('Save', fct_callback);
		}else
		{
			this.do_change_user_status();
		}
	}

	do_change_user_status()
	{

		let me = this;

		var z = new frappe.ui.Dialog({
			'title': __("User status"),
			'fields': [
				{'fieldname': 'html_select_user_status', 'fieldtype': 'HTML'}

			],
			primary_action_label: 'Ok',
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

				let go = true;

				if (document.getElementById('user_status_nb_record').value == 0)
				{
					go = false;
				}

				if ((go) && (document.getElementById('user_status_nb_record').value != 0))
				{	go = false
					for (var i = 0; i < document.getElementById('user_status_nb_record').value; i++)
					{

						if (document.getElementById('user_status_checked_id_' + i.toString()))
						{
							if (document.getElementById('user_status_checked_id_' + i.toString()).checked)
							{
								document.getElementById('selected_user_status').value = document.getElementById('user_status_id_' + i.toString()).value;
								go = true;
								break;
							}
						}
					}
				}

				if (go)
				{
					z.hide();

					method = me.path_module_user_status + '.ioi_module_user_status_change_status';

					let can_change = false;

					let cur_user_status = 0;

					if (me.frm.doc.ioiuserstatus)
					{
						cur_user_status = me.frm.doc.ioiuserstatus;
					}

					frappe.call({  	method: method,
									args: {"doctype": me.frm.doctype, "name" : me.frm.doc.name, "from_status" : cur_user_status, "to_status" : document.getElementById('selected_user_status').value, "silent_mode" : true },
									async: false,
										callback:function(r)	{
																		can_change = true;
																}
					});

					if (can_change)
					{
						window.location.reload();
					}
				}else{
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
				}
			},
			secondary_action: function(){
				z.hide();
			}

		});

		if (document.getElementById('user_status_nb_record'))
		{
			for (var i = 0; i < document.getElementById('user_status_nb_record').value; i++)
			{
				if (document.getElementById('user_status_checked_id_' + i.toString()))
				{
					document.getElementById('user_status_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('user_status_id_' + i.toString()))
				{
					document.getElementById('user_status_id_' + i.toString()).remove();
				}

			}

			document.getElementById('user_status_nb_record').remove();
		}

		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:300px; width: 100%;">';

		html += '<table border=1 style="border: 1px solid #E8EAEB" width=100% data-custom-grid="true">';

		html += '<tr style="height:30px">';

		html += '<td width=5% align="center" style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=95% style="vertical-align: middle;">&nbsp;<b>' + __("User status") + '</b></td>';
		html += '</tr>';
		html += '</table>';



		let method = me.path_module_user_status + '.ioi_module_user_status_get_list';


		frappe.call({  	method: method,
						args: {	"doctype": this.frm.doctype},
						async: false,
						callback:function(r)	{
													if (r.message.length > 0)
													{
														html += '<table border=1 style="border: 1px solid #E8EAEB" width=100%>';

														let cur_userstatus = 0;

														if (me.frm.doc.ioiuserstatus)
														{
															cur_userstatus = me.frm.doc.ioiuserstatus;
														}

														let cpt = 0


														for (var i = 0; i < r.message.length; i++)
														{
															if (cur_userstatus != r.message[i][2])
															{
																html += '<tr style="height:30px">';

																html += '<td width=5% align="center" style="vertical-align: middle;">';
																html += '<input type="checkbox" id="user_status_checked_id_' + cpt.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
																html += '       onclick=" ';
																html += '					for (var i = 0; i < document.getElementById(\'user_status_nb_record\').value; i++) ';
																html += '					{   if (document.getElementById(\'user_status_checked_id_\' + i.toString())) ';
																html += '						{ '
																html += '							if (document.getElementById(\'user_status_checked_id_\' + i.toString()).id != this.id) ';
																html += '							{ ';
																html += '								document.getElementById(\'user_status_checked_id_\' + i.toString()).checked = false; ';
																html += '							} ';
																html += '						} ';
																html += '					} ';
																html += '" ';

																html += '>';
																html += '<input type="hidden" id="user_status_id_' + cpt.toString() + '" value="' +  r.message[i][2] + '">';
																html += '</td>';

																html += '<td bgcolor="' + r.message[i][3] + '" width=95% style="vertical-align: middle;">&nbsp;<font color="' + r.message[i][4] + '">' + r.message[i][5] + ' ( ' + r.message[i][2] + ' )' + '</font></td>'

																html += '</tr>';
															}

															cpt++;
														}
														html += '<input type="hidden" id="user_status_nb_record" value="' + cpt.toString() + '">';
														html += '</table>';
													}else
													{
														html += '<input type="hidden" id="user_status_nb_record" value="0">';
													}
												}
		});

		html += '<input type="hidden" id="selected_user_status" value="">';
		html += '</div>';


		z.fields_dict.html_select_user_status.$wrapper.html(html);

		z.show();
	}


	// ***************************************************************************************************************************************
	// Fill Period from document date
	// ***************************************************************************************************************************************
	fill_period()
	{
		if ((!this.frm.doc.document_date) || ((this.frm.doc.document_date) && (this.frm.doc.document_date.toString().trim() == '')))
		{
			this.frm.doc.period_id = '';
			this.frm.refresh_field('period_id');
		}else
		{
			let me = this;
			let method = this.path_sales_document + '.ioi_sales_document_get_period_from_date';

 			this.load_sales_journal_data();
			let division = this.journal_division_id;



			if (division.trim() == '') {
				division = silicon_ioi.ioiAccUtils.get_main_division_id();
			}

			this.frm.doc.division_id = division;

			frappe.call({  	method: method,
							args: {"division_id" : division, "document_date" : this.frm.doc.document_date},
							async: false,
							callback:function(r)	{
															if (r.message.length == 0)
															{
																me.frm.doc.period_id = '';
															}else
															{
																me.frm.doc.period_id = r.message[0].name;
															}
													}
			});

			this.frm.refresh_field('period_id');
		}
	}


	// ***************************************************************************************************************************************
	// Fill VAT Type
	// ***************************************************************************************************************************************
	fill_vat_type()
	{
		this.vat_type = [];
		this.vat_type[0] = ['NAT', __('National')];
		this.vat_type[1] = ['NCO', __('National cocontractant')];
		this.vat_type[2] = ['EEC', __('Europe')];
		this.vat_type[3] = ['INT', __('International')];
		this.vat_type[4] = ['XXX', __('Others')];


		this.frm.set_df_property('vat_type', 'options', [	{ label: this.vat_type[0][1], value: this.vat_type[0][0]},
															{ label: this.vat_type[1][1], value: this.vat_type[1][0]},
															{ label: this.vat_type[2][1], value: this.vat_type[2][0]},
															{ label: this.vat_type[3][1], value: this.vat_type[3][0]},
															{ label: this.vat_type[4][1], value: this.vat_type[4][0]}
														]);

		this.frm.fields_dict['line_detail'].grid.update_docfield_property("vat_type", "options", [	{ label: this.vat_type[0][1], value: this.vat_type[0][0]},
																									{ label: this.vat_type[1][1], value: this.vat_type[1][0]},
																									{ label: this.vat_type[2][1], value: this.vat_type[2][0]},
																									{ label: this.vat_type[3][1], value: this.vat_type[3][0]},
																									{ label: this.vat_type[4][1], value: this.vat_type[4][0]}

																								 ]);
		this.#load_vat_rates();

		this.vat_service = [];
		this.vat_service[0] = ["", __("Empty")];
		this.vat_service[1] = ['G', __('Goods')];
		this.vat_service[2] = ['S', __('Service')];

		this.frm.fields_dict['line_detail'].grid.update_docfield_property("vat_service", "options", [	{ label: this.vat_service[0][1], value: this.vat_service[0][0]},
																										{ label: this.vat_service[1][1], value: this.vat_service[1][0]},
																										{ label: this.vat_service[2][1], value: this.vat_service[2][0]}

																								 	]);



	}

	// ***************************************************************************************************************************************
	// Load all VAT rates
	// ***************************************************************************************************************************************
	#load_vat_rates()
	{
		this.vat_rate = [];

		let me = this;

		frappe.call({  	method: this.path_sales_document + '.ioi_sales_document_get_vat_code_list',
						args: {},
						async: false,
						callback: function(r)	{	for (var i = 0; i < r.message.length; i++)
													{
														me.vat_rate[i] = [r.message[i][0], r.message[i][1], r.message[i][2], r.message[i][3]];
													}
												}
		});
	}

	// ***************************************************************************************************************************************
	// Fill Document type
	// ***************************************************************************************************************************************
	fill_document_type()
	{
		let me = this;

		this.frm.set_df_property('document_type', 'options', []);

		let method = this.path_sales_journal + '.ioi_sales_journal_get_document_types';

		let options = '';

		this.document_type = [];

		frappe.call({  	method: method,
						args: {"doctype": this.frm.doctype},
						async: false,
						callback:function(r)	{

							if (r.message.length > 0) {
								options = '[';

								for (var i = 0; i < r.message.length; i++) {

									options += '{ "label": "' + r.message[i].document_type_desc + '", "value": "' + r.message[i].document_type + '"}';

									me.document_type[me.document_type.length] = [r.message[i].document_type , r.message[i].document_type_desc];


									if (i < r.message.length-1) {
										options += ',';
									}

								}

								options += ']';

							}else{
								options = '[]';
							}

					}
		});


		this.frm.set_df_property('document_type', 'options', JSON.parse(options));
		this.frm.refresh_field('document_type');

	}

	// ***************************************************************************************************************************************
	// Assign query to Link Fields
	// ***************************************************************************************************************************************
    assign_query_to_link_fields()
    {
		this.set_query('period_id', '', this.path_sales_document + '.ioi_sales_document_get_enabled_period', '{"division":"' + this.frm.doc.division_id + '"}');

		let me = this;

		let site_id = '';
		let division_id = '';

		if ((this.frm.doc.site_id) && (this.frm.doc.site_id.trim() != '')) {
			site_id = this.frm.doc.site_id;
			division_id = this.frm.doc.division_id;
		}else{

			let method = this.path_user + '.ioi_user_has_site';

			frappe.call({  	method: method,
							args: {},
							async: false,
							callback:function(r)	{ 	if (r.message.has_site != 0)
														{
															me.current_site = r.message.site_id;
														}
													}
			});


			site_id = this.current_site;

			method = this.path_site + '.ioi_site_get_division';

			frappe.call({  	method: method,
							args: {"name": site_id},
							async: false,
							callback:function(r)	{
								division_id = r.message[0].division_id;
							}
			});
		}

		this.set_query('dossier_id', '', this.path_dossier + '.ioi_dossier_get_enabled_for_sales_purchases', '{"division_id":"' + division_id + '"}');


		//this.set_query('order_customer_id', '', this.path_customer + '.ioi_customer_get_enabled_for_order');
		//this.set_query('delivery_customer_id', '', this.path_customer + '.ioi_customer_get_enabled_for_delivery');
		//this.set_query('invoice_customer_id', '', this.path_customer + '.ioi_customer_get_enabled_for_invoice');


		this.set_query('order_customer_id', '', this.path_search_definition + '.set_search_definition_filters');
		this.set_query('delivery_customer_id', '', this.path_search_definition + '.set_search_definition_filters');
		this.set_query('invoice_customer_id', '', this.path_search_definition + '.set_search_definition_filters');
		this.set_query('item_id', 'line_detail', this.path_search_definition + '.set_search_definition_filters');

		//this.set_query('item_id', 'line_detail', this.path_item + '.ioi_item_get_enabled');


		if ((this.frm.doc.order_customer_id) && (this.frm.doc.order_customer_id.trim() != ''))
		{
			//this.set_query('order_customer_contact_id', '', this.path_customer + '.ioi_contact_get_all', '{"customer" : "' + this.frm.doc.order_customer_id + '"}');
			this.set_query('order_customer_contact_id', '', this.path_search_definition + '.set_search_definition_filters', '{"customer" : "' + this.frm.doc.order_customer_id + '"}');
			this.set_query('end_user_id', '', this.path_customer + '.ioi_customer_end_user_get_all', '{"end_user_id" : "' + this.frm.doc.order_customer_id + '"}');
		}else
		{	this.set_query('order_customer_contact_id', '', this.path_customer + '.ioi_contact_get_none');
			this.set_query('end_user_id', '', this.path_customer + '.ioi_customer_end_user_get_none');
		}

		if ((this.frm.doc.delivery_customer_id) && (this.frm.doc.delivery_customer_id.trim() != ''))
		{
			//this.set_query('delivery_customer_contact_id', '', this.path_customer + '.ioi_contact_get_all', '{"customer" : "' + this.frm.doc.delivery_customer_id + '"}');
			this.set_query('delivery_customer_contact_id', '', this.path_search_definition + '.set_search_definition_filters', '{"customer" : "' + this.frm.doc.delivery_customer_id + '"}');
		}else
		{	this.set_query('delivery_customer_contact_id', '', this.path_customer + '.ioi_contact_get_none');
		}

		if ((this.frm.doc.invoice_customer_id) && (this.frm.doc.invoice_customer_id.trim() != ''))
		{
			//this.set_query('invoice_customer_contact_id', '', this.path_customer + '.ioi_contact_get_all', '{"customer" : "' + this.frm.doc.invoice_customer_id + '"}');
			this.set_query('invoice_customer_contact_id', '', this.path_search_definition + '.set_search_definition_filters', '{"customer" : "' + this.frm.doc.invoice_customer_id + '"}');
		}else
		{	this.set_query('invoice_customer_contact_id', '', this.path_customer + '.ioi_contact_get_none');
		}


		this.set_query('author_id', '', this.path_sales_document + '.ioi_sales_document_get_user');
		this.set_query('responsible_id', '', this.path_sales_document + '.ioi_sales_document_get_user');

		this.set_query('sales_agent_id', '', this.path_sales_agent + '.ioi_sales_agent_get_enabled');
		this.set_query('opportunity_id', '', this.path_opportunity + '.ioi_opportunity_get_enabled');

		if ((this.frm.doc.order_customer_id) && (this.frm.doc.order_customer_id.trim() != '')) {
			this.set_query('park_id', '', this.path_park + '.ioi_park_get_enabled_for_sales', '{"customer_id": "' +  this.frm.doc.order_customer_id + '"}');
		}else{
			this.set_query('park_id', '', this.path_park + '.ioi_park_get_enabled_for_sales', '');
		}

		let fct_search_park = function () { me.search_park(); };
		this.frm.fields_dict['park_id'].open_advanced_search = fct_search_park;



		this.set_query('sales_catalog_id', '', this.path_sales_catalog + '.ioi_sales_catalog_get_enabled');
		this.set_query('sales_condition_id', '', this.path_sales_condition + '.ioi_sales_condition_get_enabled');
		this.set_query('specific_condition_id', '', this.path_specific_condition + '.ioi_specific_condition_get_enabled');
		this.set_query('delivery_condition_id', '', this.path_delivery_condition + '.ioi_delivery_condition_get_enabled', '{"in_sales":1}');

		this.set_query('warehouse_id', '', this.path_warehouse + '.ioi_warehouse_get_enabled', '{"site_id":"' + this.frm.doc.site_id + '"}');

		this.set_query('payment_term_id', '', this.path_payment_terms + '.ioi_payment_terms_get_enabled');
		this.set_query('currency_id', '', this.path_currency + '.ioi_currency_get_enabled');

		this.set_query('customer_family_1_id', '', this.path_customer_family_1 + '.ioi_customer_family_1_get_enabled');
		this.set_query('customer_family_2_id', '', this.path_customer_family_2 + '.ioi_customer_family_2_get_enabled');
		this.set_query('customer_id', 'address_detail', this.path_search_definition + '.set_search_definition_filters');
		this.set_query('detail_postal_code_id', 'address_detail', this.path_postal_code + '.ioi_postal_code_get_enabled');
		this.set_query('detail_country_id', 'address_detail', this.path_country + '.ioi_country_get_enabled');

		this.set_query('sales_condition_id', 'line_detail', this.path_sales_condition + '.ioi_sales_condition_get_enabled');
		this.set_query('account_id', 'line_detail', this.path_sales_document + '.ioi_sales_document_get_account_enabled');

		this.set_query('item_budget_id', 'line_detail', this.path_item_budget + '.ioi_item_budget_get_enabled');


		this.frm.fields_dict['line_detail'].grid.update_docfield_property("alert_margin_mode", "options", [	{ label: __("Cost margin"), 	 value: 0},
																											{ label: __("Sales margin"), value: 1}
																								  ]);


		this.frm.fields_dict['line_detail'].grid.update_docfield_property("margin_alert", "options", [	{ label: __("Ok"), 	 value: 0},
																								  		{ label: __("Margin alert"), value: -1},
																										{ label: __("Margin error"), value: -2}
																						]);

		if ((this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')) {

			this.set_query('workcenter_id', 'line_detail', this.path_workcenter + '.ioi_workcenter_get_enabled');
		}

		if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {

			let supplier_site = ''

			let method = this.path_sales_journal + '.ioi_sales_journal_get_supplier_site';

			frappe.call({  	method: method,
							args: {
							},
							async: false,
							callback:function(r)	{
								supplier_site = r.message;
							}
			});

			this.set_query('pass_through_supplier_id', '', this.path_supplier + '.ioi_supplier_get_enabled', '{"mode": 4, "ioistatus": 2, "name": ["!=", "' + supplier_site + '"]}');

			this.frm.set_df_property('pass_through_mode', 'options', [
				{ label: __('Inactive'), 		value: 0},
				{ label: __('Full mirror'), 	value: 1},
				{ label: __('Flat JIT'),  		value: 2}
			]);

			if ((this.frm.doc.site_id) && (this.frm.doc.site_id.trim() != '')) {
				this.set_query('pass_through_purchases_journal_id', '', this.path_purchases_journal + '.ioi_purchases_journal_get_enabled', '{"site_id": "' + this.frm.doc.site_id + '"}');
			}else{
				this.set_query('pass_through_purchases_journal_id', '', this.path_purchases_journal + '.ioi_purchases_journal_get_enabled', '');
			}

			let fct_supplier_button_click = function() {

				let supplier_site = ''

				let method = me.path_sales_journal + '.ioi_sales_journal_get_supplier_site';

				frappe.call({  	method: method,
								args: {
								},
								async: false,
								callback:function(r)	{
									supplier_site = r.message;
								}
				});


				let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email, alternate_id';
				let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400, 250';
				let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email, Alternate id';
				let where = "ioistatus = 2 and mode = 4";
				if ((supplier_site) && (supplier_site != '')) {
					where += " and name != '" + supplier_site.replaceAll("'", "''") + "' "

				}
				let order_by = 'name asc';

				let fct_callback = function (return_value) {

					me.frm.set_value('pass_through_supplier_id', return_value);

				}

				silicon_ioi.ioiCommon.select_supplier(fields, fields_len, fields_desc, where, order_by, fct_callback);
			}

			this.frm.get_field("pass_through_supplier_id").open_advanced_search = fct_supplier_button_click;

		}

		this.set_query('index_id', 'line_detail', this.path_sales_price_index + '.ioi_sales_price_index_get_enabled');
		this.set_query('analytic1_id', 'line_detail', this.path_analytic_1 + '.ioi_analytic_1_get_enabled');
		this.set_query('analytic2_id', 'line_detail', this.path_analytic_2 + '.ioi_analytic_2_get_enabled');
		this.set_query('analytic3_id', 'line_detail', this.path_analytic_3 + '.ioi_analytic_3_get_enabled');
		this.set_query('analytic4_id', 'line_detail', this.path_analytic_4 + '.ioi_analytic_4_get_enabled');
		this.set_query('unit_id', 'line_detail', this.path_item_unit + '.ioi_item_unit_get_enabled');
		this.set_query('warehouse_id', 'line_detail', this.path_warehouse + '.ioi_warehouse_get_enabled');

		this.set_query('dossier_id', 'line_detail', this.path_dossier + '.ioi_dossier_get_enabled_for_sales_purchases','{"division_id":"' + division_id + '"}');


		this.set_query('ext_unit_id', 'line_detail', this.path_item_unit + '.ioi_item_unit_get_enabled');

		this.frm.set_df_property('financial_discount_mode', 'options', [	{ label: 'Inactive',             value: 0},
																			{ label: 'Proposed',             value: 1},
																			{ label: 'Proposed, VAT reduce', value: 2}
																		]);


		this.frm.set_df_property('document_calc_mode', 'options', [ 	{ label: 'VAT rounding',                 value: 0},
																		{ label: 'Value line rounding',          value: 1},
																		{ label: 'Unit price discount rounding', value: 2},
																		{ label: '1 + 2 mode',                   value: 3}

																	]);



		this.set_query('vat_matching_id', 'line_detail', this.path_vat_matching + '.ioi_vat_matching_get_enabled', '');
		this.set_query('warehouse_id', 'line_detail', this.path_warehouse + '.ioi_warehouse_get_enabled', '{"site_id":"' + this.frm.doc.site_id + '"}');


		if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
		{

			if ((this.frm.doc.site_id) && (this.frm.doc.site_id.trim() != '')) {

				if ((this.frm.doc.currency_id) && (this.frm.doc.currency_id.trim() != '')) {
					this.set_query('cashdesk_id', '', this.path_cash_desk + '.ioi_cash_desk_get', '{"site_id":"' + this.frm.doc.site_id + '", "currency_id":"' + this.frm.doc.currency_id + '"}');
				}else {
					this.set_query('cashdesk_id', '', this.path_cash_desk + '.ioi_cash_desk_get', '{"site_id":"' + this.frm.doc.site_id + '"}');
				}
			}else {
				if ((this.frm.doc.currency_id) && (this.frm.doc.currency_id.trim() != '')) {
					this.set_query('cashdesk_id', '', this.path_cash_desk + '.ioi_cash_desk_get', '{"currency_id":"' + this.frm.doc.currency_id + '"}');
				}else{
					this.set_query('cashdesk_id', '', this.path_cash_desk + '.ioi_cash_desk_get', '');
				}
			}


			this.set_query('carrier_id', '', this.path_supplier + '.ioi_supplier_get_carriers');

			let fct_search_carrier = function () { me.search_carrier(); };
			this.frm.fields_dict['carrier_id'].open_advanced_search = fct_search_carrier;


			if ((this.frm.doc.carrier_id) && (this.frm.doc.carrier_id.trim() != ''))
			{
				this.set_query('carrier_contact_id', '', this.path_supplier + '.ioi_carrier_contact_get_all', '{"supplier" : "' + this.frm.doc.carrier_id + '"}');
			}else
			{	this.set_query('carrier_contact_id', '', this.path_supplier + '.ioi_carrier_contact_get_none');
			}

			this.set_query('warehouse_location_id', 'line_detail', this.path_warehouse_location + '.ioi_warehouse_location_get_all');

			this.frm.fields_dict['line_detail'].grid.update_docfield_property("stored_qty_mode", "options", [	{ label: __("Good"), value: 0},
																												{ label: __("To check"), value: 1},
																												{ label: __("Bad"), value: 2}
			]);

		}


		this.frm.fields_dict['line_detail'].grid.update_docfield_property("invoicing_mode", "options", [
			{ label: __("Default"), value: 0},
			{ label: __("Up to ordered"), value: 1},
			{ label: __("Fix ordered"), value: 2},
			{ label: __("Open order"), value: 3}
		]);


		frappe.meta.get_docfield(this.frm.doctype + ' Detail', 'invoicing_mode').options = [
			{ label: __("Default"), value: 0},
			{ label: __("Up to ordered"), value: 1},
			{ label: __("Fix ordered"), value: 2},
			{ label: __("Open order"), value: 3}
		];

		this.frm.fields_dict['line_detail'].grid.update_docfield_property("is_withme", "options", [	{ label: __("Detail"), 	 value: 0},
																									{ label: __("Document"), value: 1}
																								  ]);

		if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
		{
			this.set_query('vatcode_id', 'line_detail', this.path_sales_document + '.ioi_sales_document_get_vat_enabled', '');
		}

		if ((this.frm.doc.document_date) && (this.frm.doc.document_date != '')) {
			this.set_query('manufacturer_ref', 'line_detail', this.path_manufacturer_catalog + '.ioi_manufacturer_catalog_get_for_sales_purchases', '{"document_date": "' + this.frm.doc.document_date + '"}');
		}else{
			this.set_query('manufacturer_ref', 'line_detail', this.path_manufacturer_catalog + '.ioi_manufacturer_catalog_get_for_sales_purchases', '');
		}

		let fct_search_order_customer = function () { me.search_order_customer(); };
		let fct_search_delivery_customer = function () { me.search_delivery_customer(); };
		let fct_search_invoice_customer = function () { me.search_invoice_customer(); };

		this.frm.fields_dict['order_customer_id'].open_advanced_search = fct_search_order_customer;
		this.frm.fields_dict['delivery_customer_id'].open_advanced_search = fct_search_delivery_customer;
		this.frm.fields_dict['invoice_customer_id'].open_advanced_search = fct_search_invoice_customer;


		let fct_search_order_customer_contact = function () { me.search_order_customer_contact(); };
		let fct_search_delivery_customer_contact = function () { me.search_delivery_customer_contact(); };
		let fct_search_invoice_customer_contact = function () { me.search_invoice_customer_contact(); };

		this.frm.fields_dict['order_customer_contact_id'].open_advanced_search = fct_search_order_customer_contact;
		this.frm.fields_dict['delivery_customer_contact_id'].open_advanced_search = fct_search_delivery_customer_contact;
		this.frm.fields_dict['invoice_customer_contact_id'].open_advanced_search = fct_search_invoice_customer_contact;


		let fct_search_address_customer = function () { me.select_address_customer(me.frm); };
		this.frm.get_field("address_detail").grid.get_field("customer_id").open_advanced_search = fct_search_address_customer;


		// item advanced search is now defined in ioi_link
		if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
		{
			let fct_search_vatcode = function () { me.select_vatcode(me.frm); };
			this.frm.get_field("line_detail").grid.get_field("vatcode_id").open_advanced_search = fct_search_vatcode;
		}


		let fct_search_dossier = function() {

			let title = __("Select a dossier");
			let form_width_pixel = '710px';
			let table = "ioi Dossier";
			let fields = 'name, description';
			let fields_len = '250, 380';
			let fields_desc = 'Identification, Description';
			let where = "ioistatus <> 0 and division_id = '" + division_id.replaceAll("'", "''") + "' "


			let order_by = 'name asc';

			let fct_callback = function (return_value) {
				me.frm.set_value('dossier_id', return_value);
				me.frm.refresh_field('dossier_id');
			}

			me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

		}

		this.frm.fields_dict['dossier_id'].open_advanced_search = fct_search_dossier;

		let fct_search_responsible = function() {

			let title = __("Select a responsible");
			let form_width_pixel = '710px';
			let table = "User";
			let fields = 'name, full_name';
			let fields_len = '250, 380';
			let fields_desc = __('Identification') + ',' + __('Full name');
			let where = '';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {
				me.frm.set_value('responsible_id', return_value);
				me.frm.refresh_field('responsible_id');
			}

			me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

		}

		this.frm.fields_dict['responsible_id'].open_advanced_search = fct_search_responsible;


		let fct_search_sales_agent = function() {

			let title = __("Select a sales agent");
			let form_width_pixel = '750px';
			let table = "ioi Sales Agent";
			let fields = 'name,  firstname, lastname';
			let fields_len = '250, 200, 200';
			let fields_desc = __('Identification') + ',' + __('First name') + ',' + ('Last name');
			let where = '';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {
				me.frm.set_value('sales_agent_id', return_value);
				me.frm.refresh_field('sales_agent_id');
			}

			me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

		}

		this.frm.fields_dict['sales_agent_id'].open_advanced_search = fct_search_sales_agent;


		let fct_search_sales_catalog = function() {

			let title = __("Select a sales catalog");
			let form_width_pixel = '710px';
			let table = "ioi Sales Catalog";
			let fields = 'name, description';
			let fields_len = '250, 380';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = 'enabled = 1';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {
				me.frm.set_value('sales_catalog_id', return_value);
				me.frm.refresh_field('sales_catalog_id');
			}

			me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

		}

		this.frm.fields_dict['sales_catalog_id'].open_advanced_search = fct_search_sales_catalog;


		let fct_search_sales_condition = function() {

			let title = __("Select a sales condition");
			let form_width_pixel = '710px';
			let table = "ioi Sales Condition";
			let fields = 'name, description';
			let fields_len = '250, 380';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = 'enabled = 1';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {
				me.frm.set_value('sales_condition_id', return_value);
				me.frm.refresh_field('sales_condition_id');
			}

			me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

		}

		this.frm.fields_dict['sales_condition_id'].open_advanced_search = fct_search_sales_condition;

		let fct_search_language = function() {

			let title = __("Select a language");
			let form_width_pixel = '710px';
			let table = "Language";
			let fields = 'name, language_name';
			let fields_len = '250, 380';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = 'enabled = 1';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {
				me.frm.set_value('language', return_value);
				me.frm.refresh_field('language');
			}

			me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

		}

		this.frm.fields_dict['language'].open_advanced_search = fct_search_language;


		let fct_search_specific_condition = function() {

			let title = __("Select a specific condition");
			let form_width_pixel = '710px';
			let table = "ioi Specific Condition";
			let fields = 'name, description';
			let fields_len = '250, 380';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = 'enabled = 1';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {
				me.frm.set_value('specific_condition_id', return_value);
				me.frm.refresh_field('specific_condition_id');
			}

			me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

		}

		this.frm.fields_dict['specific_condition_id'].open_advanced_search = fct_search_specific_condition;


		let fct_search_delivery_condition = function() {

			let title = __("Select a delivery condition");
			let form_width_pixel = '710px';
			let table = "ioi Delivery Condition";
			let fields = 'name, description';
			let fields_len = '250, 380';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = 'enabled = 1 and in_sales = 1 ';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {
				me.frm.set_value('delivery_condition_id', return_value);
				me.frm.refresh_field('delivery_condition_id');
			}

			me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

		}

		this.frm.fields_dict['delivery_condition_id'].open_advanced_search = fct_search_delivery_condition;


		let fct_search_warehouse = function() {

			let title = __("Select a warehouse");
			let form_width_pixel = '710px';
			let table = "ioi Warehouse";
			let fields = 'name, description';
			let fields_len = '250, 380';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = "enabled = 1 and site_id = '" + me.frm.doc.site_id.replaceAll("'", "''") + "' ";
			let order_by = 'name asc';

			let fct_callback = function (return_value) {
				me.frm.set_value('warehouse_id', return_value);
				me.frm.refresh_field('warehouse_id');
			}

			me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

		}

		this.frm.fields_dict['warehouse_id'].open_advanced_search = fct_search_warehouse;


		let fct_search_payment_term = function() {

			let title = __("Select a payment term");
			let form_width_pixel = '710px';
			let table = "ioi Payment Terms";
			let fields = 'name, description';
			let fields_len = '250, 380';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = "enabled = 1";
			let order_by = 'name asc';

			let fct_callback = function (return_value) {
				me.frm.set_value('payment_term_id', return_value);
				me.frm.refresh_field('payment_term_id');
			}

			me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

		}

		this.frm.fields_dict['payment_term_id'].open_advanced_search = fct_search_payment_term;


		let fct_search_period = function() {

			let title = __("Select a period");
			let form_width_pixel = '600px';
			let table = "ioi Period";
			let fields = 'name';
			let fields_len = '500';
			let fields_desc = __('Period');
			let where = "division = '" + me.frm.doc.division_id.replaceAll("'", "''") + "' and enabled = 1 ";
			let order_by = 'name asc';

			let fct_callback = function (return_value) {
				me.frm.set_value('period_id', return_value);
				me.frm.refresh_field('period_id');
			}

			me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

		}

		this.frm.fields_dict['period_id'].open_advanced_search = fct_search_period;


		let fct_search_customer_family_1 = function() {

			let title = __("Select a customer family 1");
			let form_width_pixel = '710px';
			let table = "ioi Customer Family 1";
			let fields = 'name, description';
			let fields_len = '250, 380';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = "enabled = 1";
			let order_by = 'name asc';

			let fct_callback = function (return_value) {
				me.frm.set_value('customer_family_1_id', return_value);
				me.frm.refresh_field('customer_family_1_id');
			}

			me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

		}

		this.frm.fields_dict['customer_family_1_id'].open_advanced_search = fct_search_customer_family_1;



		let fct_search_customer_family_2 = function() {

			let title = __("Select a customer family 2");
			let form_width_pixel = '710px';
			let table = "ioi Customer Family 2";
			let fields = 'name, description';
			let fields_len = '250, 380';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = "enabled = 1";
			let order_by = 'name asc';

			let fct_callback = function (return_value) {
				me.frm.set_value('customer_family_2_id', return_value);
				me.frm.refresh_field('customer_family_2_id');
			}

			me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

		}

		this.frm.fields_dict['customer_family_2_id'].open_advanced_search = fct_search_customer_family_2;


		let fct_search_currency = function() {

			let title = __("Select a currency");
			let form_width_pixel = '710px';
			let table = "ioi Currency";
			let fields = 'name, description';
			let fields_len = '250, 380';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = "enabled = 1";
			let order_by = 'name asc';

			let fct_callback = function (return_value) {
				me.frm.set_value('currency_id', return_value);
				me.frm.refresh_field('currency_id');
			}

			me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

		}

		this.frm.fields_dict['currency_id'].open_advanced_search = fct_search_currency;



		let fct_search_detail_postal_code = function () {

			let title = __("Select a postal code");
			let form_width_pixel = '800px';
			let table = "ioi Postal Code";
			let fields = 'name, country_code, code, locality';
			let fields_len = '250, 100, 100, 250';
			let fields_desc = __('Identification') + ',' + __('Country') + ',' + __('Code') + ',' + __('Locality');
			let where = 'enabled = 1';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {

				let cdt = me.frm.selected_doc.doctype;
				let cdn = me.frm.selected_doc.name;

				let line = locals[cdt][cdn];
				line.detail_postal_code_id = return_value;

				let script_manager = me.frm.fields_dict['address_detail'].grid.grid_rows[line.idx - 1].frm.script_manager;
				script_manager.trigger("detail_postal_code_id", me.frm.fields_dict['address_detail'].grid.doctype, me.frm.fields_dict['address_detail'].grid.grid_rows[line.idx - 1].doc.name);
				me.frm.refresh_field('address_detail');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)
		};

		this.frm.get_field("address_detail").grid.get_field("detail_postal_code_id").open_advanced_search = fct_search_detail_postal_code;

		let fct_search_detail_country = function() {

			let title = __("Select a country");
			let form_width_pixel = '600px';
			let table = "ioi Country";
			let fields = 'name, description';
			let fields_len = '200, 300';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = 'enabled = 1';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {
				let cdt = me.frm.selected_doc.doctype;
				let cdn = me.frm.selected_doc.name;

				let line = locals[cdt][cdn];
				line.detail_country_id = return_value;

				let script_manager = me.frm.fields_dict['address_detail'].grid.grid_rows[line.idx - 1].frm.script_manager;
				script_manager.trigger("detail_country_id", me.frm.fields_dict['address_detail'].grid.doctype, me.frm.fields_dict['address_detail'].grid.grid_rows[line.idx - 1].doc.name);
				me.frm.refresh_field('address_detail');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

		}

		this.frm.get_field("address_detail").grid.get_field("detail_country_id").open_advanced_search = fct_search_detail_country;



		let fct_search_detail_account_id = function () {

			let title = __("Select an account id");
			let form_width_pixel = '580px';
			let table = "ioi General Account";
			let fields = 'name, description';
			let fields_len = '200, 300';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = 'enabled = 1 and is_group = 0';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {

				let cdt = me.frm.selected_doc.doctype;
				let cdn = me.frm.selected_doc.name;

				let line = locals[cdt][cdn];
				line.account_id = return_value;

				let script_manager = me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].frm.script_manager;
				script_manager.trigger("account_id", me.frm.fields_dict['line_detail'].grid.doctype, me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].doc.name);
				me.frm.refresh_field('line_detail');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)
		};

		this.frm.get_field("line_detail").grid.get_field("account_id").open_advanced_search = fct_search_detail_account_id;


		let fct_search_detail_analytic1 = function () {

			let title = __("Select an analytic 1");
			let form_width_pixel = '580px';
			let table = "ioi Analytic 1";
			let fields = 'name, description';
			let fields_len = '200, 300';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = 'enabled = 1';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {

				let cdt = me.frm.selected_doc.doctype;
				let cdn = me.frm.selected_doc.name;

				let line = locals[cdt][cdn];
				line.analytic1_id = return_value;

				let script_manager = me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].frm.script_manager;
				script_manager.trigger("analytic1_id", me.frm.fields_dict['line_detail'].grid.doctype, me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].doc.name);
				me.frm.refresh_field('line_detail');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)
		};

		this.frm.get_field("line_detail").grid.get_field("analytic1_id").open_advanced_search = fct_search_detail_analytic1;


		let fct_search_detail_analytic2 = function () {

			let title = __("Select an analytic 2");
			let form_width_pixel = '580px';
			let table = "ioi Analytic 2";
			let fields = 'name, description';
			let fields_len = '200, 300';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = 'enabled = 1';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {

				let cdt = me.frm.selected_doc.doctype;
				let cdn = me.frm.selected_doc.name;

				let line = locals[cdt][cdn];
				line.analytic2_id = return_value;

				let script_manager = me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].frm.script_manager;
				script_manager.trigger("analytic2_id", me.frm.fields_dict['line_detail'].grid.doctype, me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].doc.name);
				me.frm.refresh_field('line_detail');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)
		};

		this.frm.get_field("line_detail").grid.get_field("analytic2_id").open_advanced_search = fct_search_detail_analytic2;



		let fct_search_detail_analytic3 = function () {

			let title = __("Select an analytic 3");
			let form_width_pixel = '580px';
			let table = "ioi Analytic 3";
			let fields = 'name, description';
			let fields_len = '200, 300';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = 'enabled = 1';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {

				let cdt = me.frm.selected_doc.doctype;
				let cdn = me.frm.selected_doc.name;

				let line = locals[cdt][cdn];
				line.analytic3_id = return_value;

				let script_manager = me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].frm.script_manager;
				script_manager.trigger("analytic3_id", me.frm.fields_dict['line_detail'].grid.doctype, me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].doc.name);
				me.frm.refresh_field('line_detail');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)
		};

		this.frm.get_field("line_detail").grid.get_field("analytic3_id").open_advanced_search = fct_search_detail_analytic3;



		let fct_search_detail_analytic4 = function () {

			let title = __("Select an analytic 4");
			let form_width_pixel = '580px';
			let table = "ioi Analytic 4";
			let fields = 'name, description';
			let fields_len = '200, 300';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = 'enabled = 1';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {

				let cdt = me.frm.selected_doc.doctype;
				let cdn = me.frm.selected_doc.name;

				let line = locals[cdt][cdn];
				line.analytic4_id = return_value;

				let script_manager = me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].frm.script_manager;
				script_manager.trigger("analytic4_id", me.frm.fields_dict['line_detail'].grid.doctype, me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].doc.name);
				me.frm.refresh_field('line_detail');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)
		};

		this.frm.get_field("line_detail").grid.get_field("analytic4_id").open_advanced_search = fct_search_detail_analytic4;


		let fct_search_detail_dossier = function () {

			let title = __("Select a dossier");
			let form_width_pixel = '580px';
			let table = "ioi Dossier";
			let fields = 'name, description';
			let fields_len = '200, 300';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = "ioistatus <> 0 and division_id = '" + division_id.replaceAll("'", "''") + "' "

			if ((this.frm.doc.site_id) && (this.frm.doc.site_id.trim() != '')) {
				where += "and site_id = '" + this.frm.doc.site_id.replaceAll("'", "''") + "' "
			}else{
				where += "and site_id = '" + this.current_site.replaceAll("'", "''") + "' "
			}


			let order_by = 'name asc';

			let fct_callback = function (return_value) {

				let cdt = me.frm.selected_doc.doctype;
				let cdn = me.frm.selected_doc.name;

				let line = locals[cdt][cdn];
				line.dossier_id = return_value;

				let script_manager = me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].frm.script_manager;
				script_manager.trigger("dossier_id", me.frm.fields_dict['line_detail'].grid.doctype, me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].doc.name);
				me.frm.refresh_field('line_detail');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)
		};

		this.frm.get_field("line_detail").grid.get_field("dossier_id").open_advanced_search = fct_search_detail_dossier;


		let fct_search_detail_sales_condition = function () {

			let title = __("Select a sales condition");
			let form_width_pixel = '580px';
			let table = "ioi Sales Condition";
			let fields = 'name, description';
			let fields_len = '200, 300';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = 'enabled = 1';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {

				let cdt = me.frm.selected_doc.doctype;
				let cdn = me.frm.selected_doc.name;

				let line = locals[cdt][cdn];
				line.sales_condition_id = return_value;

				let script_manager = me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].frm.script_manager;
				script_manager.trigger("sales_condition_id", me.frm.fields_dict['line_detail'].grid.doctype, me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].doc.name);
				me.frm.refresh_field('line_detail');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)
		};

		this.frm.get_field("line_detail").grid.get_field("sales_condition_id").open_advanced_search = fct_search_detail_sales_condition;


		let fct_search_detail_vat_matching = function () {

			let title = __("Select a VAT matching");
			let form_width_pixel = '580px';
			let table = "ioi VAT Matching";
			let fields = 'name, description';
			let fields_len = '200, 300';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = 'enabled = 1';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {

				let cdt = me.frm.selected_doc.doctype;
				let cdn = me.frm.selected_doc.name;

				let line = locals[cdt][cdn];
				line.vat_matching_id = return_value;

				let script_manager = me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].frm.script_manager;
				script_manager.trigger("vat_matching_id", me.frm.fields_dict['line_detail'].grid.doctype, me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].doc.name);
				me.frm.refresh_field('line_detail');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)
		};

		this.frm.get_field("line_detail").grid.get_field("vat_matching_id").open_advanced_search = fct_search_detail_vat_matching;


		let fct_search_detail_warehouse = function () {

			let title = __("Select a warehouse");
			let form_width_pixel = '580px';
			let table = "ioi Warehouse";
			let fields = 'name, description';
			let fields_len = '200, 300';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = "enabled = 1 and site_id = '" + me.frm.doc.site_id.replaceAll("'", "''") + "' ";
			let order_by = 'name asc';

			let fct_callback = function (return_value) {

				let cdt = me.frm.selected_doc.doctype;
				let cdn = me.frm.selected_doc.name;

				let line = locals[cdt][cdn];
				line.warehouse_id = return_value;

				let script_manager = me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].frm.script_manager;
				script_manager.trigger("warehouse_id", me.frm.fields_dict['line_detail'].grid.doctype, me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].doc.name);
				me.frm.refresh_field('line_detail');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)
		};

		this.frm.get_field("line_detail").grid.get_field("warehouse_id").open_advanced_search = fct_search_detail_warehouse;


		let fct_search_detail_activity_detail = function() {

			let title = __("Select a customer activity");
			let form_width_pixel = '580px';
			let table = "ioi Customer Activity";
			let fields = 'name, description';
			let fields_len = '200, 300';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = "enabled = 1 ";

			if (me.frm.get_field('activity_detail').rows.length > 0) {
				where += " and name not in ( "

				for(var i = 0; i < me.frm.get_field('activity_detail').rows.length; i++) {
					where += "'" + me.frm.get_field('activity_detail').rows[i].activity_id.replaceAll("'", "''") + "',"
				}

				where = where.substring(0, where.length-1);

				where += " ) "
			}

			let order_by = 'name asc';

			let fct_callback = function (return_value) {

				let row = me.frm.add_child('activity_detail', {activity_id: return_value});
				me.frm.refresh_field('activity_detail');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

		}

		this.frm.get_field("activity_detail").open_advanced_search = fct_search_detail_activity_detail;




		let fct_search_detail_sector_detail = function() {

			let title = __("Select a customer sector");
			let form_width_pixel = '580px';
			let table = "ioi Customer Sector";
			let fields = 'name, description';
			let fields_len = '200, 300';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = "enabled = 1 ";

			if (me.frm.get_field('sector_detail').rows.length > 0) {
				where += " and name not in ( "

				for(var i = 0; i < me.frm.get_field('sector_detail').rows.length; i++) {
					where += "'" + me.frm.get_field('sector_detail').rows[i].sector_id.replaceAll("'", "''") + "',"
				}

				where = where.substring(0, where.length-1);

				where += " ) "
			}

			let order_by = 'name asc';

			let fct_callback = function (return_value) {

				let row = me.frm.add_child('sector_detail', {sector_id: return_value});
				me.frm.refresh_field('sector_detail');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

		}

		this.frm.get_field("sector_detail").open_advanced_search = fct_search_detail_sector_detail;

		if ((!this.frm.doc.ioistatus) || (this.frm.doc.ioistatus == 0)) {
			this.frm.fields_dict['bt_order_new_contact'].wrapper.style.display = 'block';
			this.frm.fields_dict['bt_delivery_new_contact'].wrapper.style.display = 'block';
			this.frm.fields_dict['bt_invoice_new_contact'].wrapper.style.display = 'block';

			if(this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
				this.frm.fields_dict['bt_carrier_new_contact'].wrapper.style.display = 'block';
			}

		}else{
			this.frm.fields_dict['bt_order_new_contact'].wrapper.style.display = 'none';
			this.frm.fields_dict['bt_delivery_new_contact'].wrapper.style.display = 'none';
			this.frm.fields_dict['bt_invoice_new_contact'].wrapper.style.display = 'none';

			if(this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
				this.frm.fields_dict['bt_carrier_new_contact'].wrapper.style.display = 'none';
			}
		}
    }

	// ***************************************************************************************************************************************
	// Set query
	// ***************************************************************************************************************************************
	set_query(fieldname, childfieldname, method, filter = '')
	{
		if (childfieldname.trim() == '')
		{
			if (filter.trim() != '')
			{
				this.frm.set_query(fieldname, () => {
					return  {   query: method,
								filters : JSON.parse(filter)
							}
				});
			}else
			{
				this.frm.set_query(fieldname, () => {
					return  {   query: method
							}
				});

			}
		}else
		{
			if (filter.trim() != '')
			{
				this.frm.set_query(fieldname, childfieldname, () => {
					return  {   query: method,
								filters: JSON.parse(filter)
							}
				});
			}else
			{
				this.frm.set_query(fieldname, childfieldname, () => {
					return  {   query: method
							}
				});

			}
		}
	}

	// ***************************************************************************************************************************************
	// Fill Prefixes
	// ***************************************************************************************************************************************
	fill_prefixes()
	{
		let me = this;

		let amethod = '';
		let tp = '';

		if (this.frm.is_new()) {
			if ((this.frm.doc.level_tp != 1) && (this.frm.doc.level_tp != 2)) {

				this.frm.doc.prefix_id = '';
				this.frm.doc.id_prefix = '';
				this.frm.doc.id_number = 0;
				this.frm.doc.identification = '?';
			}
		}

		this.frm.refresh_field('journal_id');
		this.frm.refresh_field('prefix_id');
		this.frm.refresh_field('id_prefix');
		this.frm.refresh_field('id_number');
		this.frm.refresh_field('identification');
		this.load_sales_journal_data();


        if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
        {
            amethod = this.path_sales_journal + '.ioi_sales_journal_get_quotes_contracts_prefixes';

    		if ((!this.frm.doc.document_type) || ((this.frm.doc.document_type) && (this.frm.doc.document_type.trim() == 'QUOTE')))
		    {	tp = 'QUOTE';
		    }else
		    {	tp = 'CONTRACT';
		    }

        }else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
        {
            amethod = this.path_sales_journal + '.ioi_sales_journal_get_orders_prefixes';
		    tp = 'ORDER';
        }else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
        {
            amethod = this.path_sales_journal + '.ioi_sales_journal_get_deliveries_prefixes';
            tp = 'DELIVERY';
        }else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
        {
			if (this.frm.is_new())
			{
				if ((this.frm.doc.document_type == '') || (this.frm.doc.document_type.toUpperCase() == 'INVOICE'))
				{
					this.frm.doc.vcs_mode = this.journal_invoices_vcs_mode;
					this.frm.doc.vcs_param = this.journal_invoices_vcs_param;
				}else
				{
					this.frm.doc.vcs_mode = this.journal_cnotes_vcs_mode;
					this.frm.doc.vcs_param = this.journal_cnotes_vcs_param;
				}

				this.frm.refresh_field('vcs_mode');
				this.frm.refresh_field('vcs_param');
			}

			this.frm.set_df_property('vcs_reference', 'read_only', 0);

			if (this.frm.doc.vcs_mode != 0)
			{	this.frm.set_df_property('vcs_reference', 'read_only', 1);
			}



            amethod = this.path_sales_journal + '.ioi_sales_journal_get_invoices_cnotes_prefixes';

    		if ((!this.frm.doc.document_type) || ((this.frm.doc.document_type) && (this.frm.doc.document_type.trim() == 'INVOICE')))
		    {	tp = 'INVOICE';
		    }else
		    {	tp = 'CNOTE';
		    }

        }

		let prefix_enabled = 0

		if (this.frm.is_new())
		{	prefix_enabled = 1
		}

		frappe.call({  	method: amethod,
					   	args: {"enabled":1, "prefix_enabled" : prefix_enabled, "tp":tp, "current_prefix": this.current_prefix},
						async: false,
						callback:function(r){
												let options = '[';

                                                me.prefixes = [];

												for (var i = 0; i < r.message.length; i++)
												{

													if (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
																   	   // name             prefix           last_number      nb_digits		  sublevel_sep     sublvl_nb_digits division_id      dt.site_id       vat_incl_ctrl    vcs_mode,        vcs_param
														me.prefixes[i] = [r.message[i][0], r.message[i][1], r.message[i][2], r.message[i][3], r.message[i][4], r.message[i][5], r.message[i][6], r.message[i][7], r.message[i][8], r.message[i][9], r.message[i][10]];
													}else{
																   	   // name             prefix           last_number      nb_digits		  sublevel_sep     sublvl_nb_digits division_id      dt.site_id       vat_incl_ctrl
														me.prefixes[i] = [r.message[i][0], r.message[i][1], r.message[i][2], r.message[i][3], r.message[i][4], r.message[i][5], r.message[i][6], r.message[i][7], r.message[i][8]];
													}

													options += '{"label": "' + me.prefixes[i][1] + '", "value": "' + me.prefixes[i][1] + '"}';

													if (i != r.message.length-1)
													{	options += ',';
													}
												}

												options += ']';

												me.frm.set_df_property('prefix_id', 'options', JSON.parse(options));
												me.frm.refresh_field("prefix_id");

                                                if (me.frm.is_new())
                                                {		if (me.prefixes.length > 0)
														{
															let mtd = 'silicon_ioi.ioi_system.doctype.ioi_user_module_params.ioi_user_module_params.ioi_user_module_params_get_prefix';

															let selected_pref = ''

															frappe.call({
																method: mtd,
																args: {"doctype":  me.frm.doctype},
																async: false,
																callback:function(r){
																	selected_pref = r.message;

																	if (!selected_pref) {
																		selected_pref = '';
																	}
																}
															});


															let found = false;

															for (var w = 0; w < me.prefixes.length; w++) {

																if (me.prefixes[w][1].toUpperCase() == selected_pref.toUpperCase()) {
																	found = true;
																	break;
																}
															}

															if (!found) {
																me.frm.set_value('prefix_id', me.prefixes[0][1]);
															}else{
																me.frm.set_value('prefix_id', selected_pref);
															}

														}
                                                }

											}
		});

	}

	// ***************************************************************************************************************************************
	// Refresh Prefixes
	// ***************************************************************************************************************************************
	refresh_prefixes()
	{
		this.fill_prefixes();
	}

	// ***************************************************************************************************************************************
	// Refresh ioistatus (Status)
	// ***************************************************************************************************************************************
	refresh_ioistatus()
	{
		let html = '';
		this.frm.fields_dict['html_ioistatus'].$wrapper.empty();


		let bgcolor = '';
		let color = '';
		let description = '';

		for (var i = 0; i < this.ioistatus.length; i++)
		{
			if (this.frm.doc.ioistatus == this.ioistatus[i][0])
			{
				description = this.ioistatus[i][1];
				bgcolor = this.ioistatus[i][2];
				color = this.ioistatus[i][3];
				break;
			}
		}


		html = '<table width=100%>';
		html += '<tr>';
		html += '<td width=100% height=20px><font style="font-size:12px;">'+ __("Status") +'</font></td>';
		html += '</tr>';

		html += '<tr>';
		html += '<td width=100%>';
		html += '<div style="height:32px; background-color:' + bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
		html += '<b><font style="font-size:14px;color:' + color +'">&nbsp;'+ __(description) +'</b></font>';
		html += '</div></td>';
		html += '</tr>';

		html += '<tr>';
		html += '<td width=100% height=7px>&nbsp;</td>';
		html += '</tr>';

		html += '</table>';

		this.frm.fields_dict['html_ioistatus'].$wrapper.append(html);
	}

	// ***************************************************************************************************************************************
	// Detail : Refresh ioistatus (Status)
	// ***************************************************************************************************************************************
	#refresh_detail_ioistatus(frm, cdt, cdn)
	{
		let item = locals[cdt][cdn];

		let bgcolor = '';
		let color = '';
		let description = '';

		for (var i = 0; i < this.ioistatus.length; i++)
		{
			if (item.ioistatus == this.ioistatus[i][0])
			{
				description = this.ioistatus[i][1];
				bgcolor = this.ioistatus[i][2];
				color = this.ioistatus[i][3];
				break;
			}
		}

		if (this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form)
		{
			let html = '';

			this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['html_detail_ioistatus'].$wrapper.empty();

			html = '<table width=100%>';
			html += '<tr>';
			html += '<td width=100% height=20px><font style="font-size:12px;">'+ __("Status") +'</font></td>';
			html += '</tr>';

			html += '<tr>';
			html += '<td width=100%>';
			html += '<div style="height:32px; background-color:' + bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
			html += '<b><font style="font-size:14px;color:' + color +'">&nbsp;'+ __(description) +'</b></font>';
			html += '</div></td>';
			html += '</tr>';

			html += '<tr>';
			html += '<td width=100% height=7px>&nbsp;</td>';
			html += '</tr>';

			html += '</table>';

			this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['html_detail_ioistatus'].$wrapper.append(html);
		}

		if (this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1])
		{
			this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].wrapper[0].children[0].children[1].style.backgroundColor = bgcolor;
		}
	}

	#refresh_detail_dossier_bom_routing(frm, cdt, cdn)
	{
		let html = '';

		let item = locals[cdt][cdn];

		if (document.getElementById('html_dossier_bom_routing_link')) {
			document.getElementById('html_dossier_bom_routing_link').remove();
		}

		if (document.getElementById('html_dossier_bom_routing_clear')) {
			document.getElementById('html_dossier_bom_routing_clear').remove();
		}

		this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['html_dossier_bom_routing'].$wrapper.empty();

		if ((item.dossier_id) && (item.dossier_id.trim() != '')) {

			if ((item.dossier_bom_id) && (item.dossier_bom_id.trim() != '')) {

				let data = [];

				let method = this.path_sales_document + '.ioi_sales_document_orders_get_bom_data';

				frappe.call({
					method: method,
					args: {"dossier_id": item.dossier_id, "dossier_bom_id" : item.dossier_bom_id},
					async: false,
					callback:function(r){
						data = r.message;
					}
				});


				if (data.length > 0) {

					html = '<table width=100%>';
					html += '<tr>';
					html += '<td width=100% height=20px><font style="font-size:12px;">'+ __("Dossier BOM") +'</font></td>';
					html += '</tr>';

					html += '<tr>';
					html += '<td width=100%>';
					html += '<div id="html_dossier_bom_routing_link" style="height:100%; background-color:' + data[0].ioistatus_color + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
					html += '<b><font style="font-size:14px;color:#000000;">&nbsp;' + data[0].position_id + ' | ' + data[0].item_id + ' | ' + data[0].description +'</b></font>';
					html += '</div></td>';
					html += '</tr>';

					html += '<tr>';
					html += '<td width=100% height=2px>&nbsp;</td>';
					html += '</tr>';

					html += '<tr>';
					html += '<td width=100% height=7px>';
					html +='<button id="html_dossier_bom_routing_clear" title="' + __("Clear") +'" data-label="Search" class="btn btn-default ellipsis" style="height: 28px; width: 110px;" onclick="">' + __("Clear") + '</button>';
					html += '</td>';
					html += '</tr>';

					html += '<tr>';
					html += '<td width=100% height=7px>&nbsp;</td>';
					html += '</tr>';

					html += '</table>';
				}
			}else if ((item.dossier_routing_id) && (item.dossier_routing_id.trim() != '')) {

				let data = [];

				let method = this.path_sales_document + '.ioi_sales_document_orders_get_routing_data';

				frappe.call({
					method: method,
					args: {"dossier_id": item.dossier_id, "dossier_routing_id" : item.dossier_routing_id},
					async: false,
					callback:function(r){
						data = r.message;
					}
				});

				if (data.length > 0) {

					html = '<table width=100%>';
					html += '<tr>';
					html += '<td width=100% height=20px><font style="font-size:12px;">'+ __("Dossier Routing") +'</font></td>';
					html += '</tr>';

					html += '<tr>';
					html += '<td width=100%>';
					html += '<div id="html_dossier_bom_routing_link" style="height:100%; background-color:' + data[0].ioistatus_color + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
					html += '<b><font style="font-size:14px;color:#000000;">&nbsp;' + data[0].step_id + ' | ' + data[0].workcenter_id + ' | ' + data[0].description +'</b></font>';
					html += '</div></td>';
					html += '</tr>';

					html += '<tr>';
					html += '<td width=100% height=2px>&nbsp;</td>';
					html += '</tr>';

					html += '<tr>';
					html += '<td width=100% height=7px>';
					html +='<button id="html_dossier_bom_routing_clear" title="' + __("Clear") +'" data-label="Search" class="btn btn-default ellipsis" style="height: 28px; width: 110px;" onclick="">' + __("Clear") + '</button>';
					html += '</td>';
					html += '</tr>';

					html += '<tr>';
					html += '<td width=100% height=7px>&nbsp;</td>';
					html += '</tr>';

					html += '</table>';
				}
			}
		}

		this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['html_dossier_bom_routing'].$wrapper.append(html);

		let me = this;

		this.#sleep(250).then(() => {


			if ((item.dossier_id) && (item.dossier_id.trim() != '')) {

				if ((item.dossier_bom_id) && (item.dossier_bom_id.trim() != '')) {

					let fct_click = function() {
						let url = '/app/ioi-dossier/' + item.dossier_id;
						window.open(url);
					}

					document.getElementById('html_dossier_bom_routing_link').onclick = fct_click;

					let fct_clear = function() {

						if ((!item.ioistatus) || ((item.ioistatus) && (item.ioistatus == 0))) {

							if (!me.frm.is_dirty()) {
								me.frm.dirty();
							}

							item.dossier_id = '';
							item.dossier_bom_id = '';
							me.frm.refresh_field('line_detail');
							me.format_detail_form(frm, cdt, cdn);

						}

					}

					document.getElementById('html_dossier_bom_routing_clear').onclick = fct_clear;

				}else if ((item.dossier_routing_id) && (item.dossier_routing_id.trim() != '')) {

					let fct_click = function() {
						let url = '/app/ioi-dossier/' + item.dossier_id;
						window.open(url);
					}

					document.getElementById('html_dossier_bom_routing_link').onclick = fct_click;

					let fct_clear = function() {

						if ((!item.ioistatus) || ((item.ioistatus) && (item.ioistatus == 0))) {

							if (!me.frm.is_dirty()) {
								me.frm.dirty();
							}


							item.dossier_id = '';
							item.dossier_routing_id = '';
							me.frm.refresh_field('line_detail');
							me.format_detail_form(frm, cdt, cdn);

						}

					}

					document.getElementById('html_dossier_bom_routing_clear').onclick = fct_clear;

				}
			}
		});
	}

	// ***************************************************************************************************************************************
	// New document
	// ***************************************************************************************************************************************
    new_document()
    {
		this.init_values_when_new_or_duplicate();

        if ((!this.frm.doc.document_type) || ((this.frm.doc.document_type) && (this.frm.doc.document_type.trim() == '')))
        {
			if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
			{	this.frm.set_value('document_type', 'QUOTE');
			}else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
			{	this.frm.set_value('document_type', 'ORDER');
			}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
			{	this.frm.set_value('document_type', 'DELIVERY');
			}else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
			{	this.frm.set_value('document_type', 'INVOICE');
			}
        }


        if ((this.frm.doc.prefix_id) && (this.frm.doc.prefix_id.trim() != ''))
        {	this.frm.set_df_property('document_type', 'read_only', 1);
            this.frm.set_df_property('prefix_id', 'read_only', 1);
        }else
        {	this.frm.set_df_property('document_type', 'read_only', 0);
            this.frm.set_df_property('prefix_id', 'read_only', 0);
        }

        if ((!this.frm.doc.identification) || ((this.frm.doc.identification) && (this.frm.doc.identification.trim() == '')))
        {	this.frm.set_value('identification', '?');
        }


        this.frm.set_df_property('identification', 'read_only', 1);

        this.frm.set_value('ioistatus', 0);
        this.frm.set_value('lib_ioistatus', __('In preparation'));


        this.frm.set_value('sales_catalog_id', this.journal_sales_catalog_id);
        this.frm.set_value('sales_condition_id', this.journal_sales_condition_id);
        this.frm.set_value('warehouse_id', this.journal_warehouse_id);
        this.frm.set_value('currency_id', this.system_currency);
        this.frm.set_value('currency_rate', this.system_currency_rate);
        this.frm.set_value('currency_rate_inv', this.system_currency_rate_inv);
        this.frm.set_value('currency_digit_rounding', this.system_currency_digit_rounding);

		if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
		{
			if (this.frm.doc.document_type.toUpperCase() == 'INVOICE')
			{
				this.frm.set_value('vcs_mode', this.journal_invoices_vcs_mode);
				this.frm.set_value('vcs_param', this.journal_invoices_vcs_param);
			}
			else
			{
				this.frm.set_value('vcs_mode', this.journal_cnotes_vcs_mode);
				this.frm.set_value('vcs_param', this.journal_cnotes_vcs_param);
			}
		}
        this.frm.set_value('vat_type', 'NAT');
        this.frm.set_value('document_calc_mode', 0);

		if ((this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')) {

			if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') {

				if (this.frm.doc.document_type == 'QUOTE') {
					this.frm.set_value('mono_dossier', this.journal_quotes_default_mono_dossier);
				}else{
					this.frm.set_value('mono_dossier', this.journal_contracts_default_mono_dossier);
				}

			}else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
				this.frm.set_value('mono_dossier', this.journal_orders_default_mono_dossier);

			}

		}

		this.frm.set_value('vat_included_control', this.journal_vat_included_control);

    }


	// ***************************************************************************************************************************************
	// Init value when new document or duplicate
	// ***************************************************************************************************************************************

	init_values_when_new_or_duplicate()
	{

		if ((this.frm.doc.level_tp != 1) && (this.frm.doc.level_tp != 2)) {

			let me = this;

			let amethod = this.path_sales_document + '.ioi_sales_document_get_fields_for_initialization'
			frappe.call({  	method: amethod,
							args: {"doctype": this.frm.doctype, "doctype_detail" : this.frm.doctype + ' Detail'},
							async: false,
							callback:function(r){

								if (r.message.length > 0) {

									for (var i = 0; i < r.message.length; i++) {

										if (me.frm.doctype == r.message[i].parent) {
											me.frm.set_value(r.message[i].fieldname, r.message[i].default_value);
											me.frm.refresh_field(r.message[i].fieldname);
										}
									}

									if (me.frm.doc.line_detail)
									{
										for (var j = 0; j < me.frm.doc.line_detail.length; j++) {

											for (var i = 0; i < r.message.length; i++) {

												if (me.frm.doctype + ' Detail' == r.message[i].parent) {

													me.frm.doc.line_detail[j][r.message[i].fieldname] =  r.message[i].default_value;
												}
											}
										}

										me.frm.refresh_field('line_detail');
									}
								}
							}
			});
		}

	}

	update_vat_type_in_line()
	{

		if (this.frm.doc.line_detail) {

			for (var i = 0; i < this.frm.doc.line_detail.length; i++) {

				if (this.frm.doc.line_detail[i].vat_type == this.frm.old_vat_type) {
					this.frm.doc.line_detail[i].vat_type = this.frm.doc.vat_type;

					let script_manager = this.frm.fields_dict['line_detail'].grid.grid_rows[i].frm.script_manager;
					script_manager.trigger("vat_type", this.frm.fields_dict['line_detail'].grid.doctype, this.frm.fields_dict['line_detail'].grid.grid_rows[i].doc.name);

				}
			}

			this.frm.refresh_field('line_detail');
		}
	}


	// ***************************************************************************************************************************************
	// Create subdocument (same level)
	// ***************************************************************************************************************************************
	create_subdocument_same_level()
	{
		if (this.frm.is_dirty())
		{
			let me = this;
			let fct_callback = function () { me.do_create_subdocument_same_level(); };
			this.frm.save('Save', fct_callback);
		}else
		{
			this.do_create_subdocument_same_level();
		}
	}

	do_create_subdocument_same_level()
	{
		let sublevel_separator = '';
		let sublevel_nb_digit = 0;

		for (var i = 0; i < this.prefixes.length; i++)
		{
			if (this.prefixes[i][1] == this.frm.doc.prefix_id)
			{
				sublevel_separator = this.prefixes[i][4];
				sublevel_nb_digit = this.prefixes[i][5];
				break;
			}
		}

		let me = this;

		let new_identification = '';

        let amethod = '';

        if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
        {	amethod = this.path_sales_quote + '.ioi_sales_quote_get_next_subdoc_samelevel';
        }else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
        {	amethod = this.path_sales_order + '.ioi_sales_order_get_next_subdoc_samelevel';
        }else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
        {	amethod = this.path_sales_delivery + '.ioi_sales_delivery_get_next_subdoc_samelevel';
        }else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
        {	amethod = this.path_sales_invoice + '.ioi_sales_invoice_get_next_subdoc_samelevel';
        }

		frappe.call({  	method: amethod,
						args: {"prefix_id": this.frm.doc.prefix_id, "identification" : this.frm.doc.identification, "sublevel_separator" : sublevel_separator, "sublevel_nb_digits": sublevel_nb_digit},
						async: false,
		   				callback: function(r)	{
													new_identification = r.message;
												}
		});

		let current_document_type = me.frm.doc.document_type;
		let current_prefix_id = me.frm.doc.prefix_id;
		let current_journal_id = me.frm.doc.journal_id;
		let current_identification = me.frm.doc.parent_id;

		let new_root = ''

		if ((!me.frm.doc.root_id) || ((me.frm.doc.root_id) && (me.frm.doc.root_id.trim() == '')))
		{
			new_root = me.frm.doc.parent_id;
		}else
		{
			new_root = me.frm.doc.root_id;
		}



		frappe.call({  	method: this.path_sales_document + '.ioi_sales_document_get_document',
						args: {"doctype": this.frm.doctype, "name": me.frm.doc.parent_id},
						async: false,
						callback: function(r){

							let keys = Object.keys(r.message);
							let values = Object.values(r.message);

							frappe.new_doc(me.frm.doctype, {}, doc => {

								doc["document_type"] = current_document_type;
								doc["journal_id"] = current_journal_id;
								doc["prefix_id"] = current_prefix_id;
								doc["id_prefix"] = current_prefix_id;
								doc["identification"] = new_identification;

								doc["parent_id"] = current_identification;
								doc["root_id"] = new_root;
								doc["level_tp"] = 1;

								for (var i = 0; i < keys.length; i++)
								{
									if ((keys[i].toUpperCase() != 'NAME')                  && (keys[i].toUpperCase() != 'OWNER')                    && (keys[i].toUpperCase() != 'CREATION')               &&
										(keys[i].toUpperCase() != 'MODIFIED')              && (keys[i].toUpperCase() != 'MODIFIED_BY')              && (keys[i].toUpperCase() != 'DOCSTATUS')              &&
										(keys[i].toUpperCase() != 'IDX')                   && (keys[i].toUpperCase() != 'DOCUMENT_TYPE')            && (keys[i].toUpperCase() != 'IOISTATUS')              &&
										(keys[i].toUpperCase() != 'LIB_IOISTATUS')         && (keys[i].toUpperCase() != 'DOCTYPE')                  && (keys[i].toUpperCase() != 'JOURNAL_ID')             &&
										(keys[i].toUpperCase() != 'PREFIX_ID')             && (keys[i].toUpperCase() != 'IDENTIFICATION')           && (keys[i].toUpperCase() != 'PARENT_ID')              &&
										(keys[i].toUpperCase() != 'ROOT_ID')               && (keys[i].toUpperCase() != 'LEVEL_TP')                 && (keys[i].toUpperCase() != 'LINE_DETAIL')            &&
										(keys[i].toUpperCase() != 'ADDRESS_DETAIL')        && (keys[i].toUpperCase() != 'LOG_DETAIL')	            && (keys[i].toUpperCase() != 'ID_PREFIX')              &&
										(keys[i].toUpperCase() != 'ID_NUMBER')             && (keys[i].toUpperCase() != 'TOTAL_HTVA')               && (keys[i].toUpperCase() != 'TOTAL_BASE')             &&
										(keys[i].toUpperCase() != 'TOTAL_VAT')             && (keys[i].toUpperCase() != 'TOTAL_TVAC')               && (keys[i].toUpperCase() != 'TOTAL_FIN_DISCOUNT')     && (keys[i].toUpperCase() != 'TOTAL_FAST_FIN_DISCOUNT') &&
										(keys[i].toUpperCase() != 'TOTAL_WEIGHT')          && (keys[i].toUpperCase() != 'TOTAL_VOLUME')             && (keys[i].toUpperCase() != 'TOTAL_HTVA_OPTION_PART') &&
										(keys[i].toUpperCase() != 'TOTAL_WO_VAT_NEG_PART') && (keys[i].toUpperCase() != 'TOTAL_IN_SYSTEM_CURRENCY') && (keys[i].toUpperCase() != 'RATE_VAT1')              &&
										(keys[i].toUpperCase() != 'RATE_VAT2')             && (keys[i].toUpperCase() != 'RATE_VAT3')                && (keys[i].toUpperCase() != 'RATE_VAT4')              &&
										(keys[i].toUpperCase() != 'RATE_VAT5')             && (keys[i].toUpperCase() != 'WO_VAT1')                  && (keys[i].toUpperCase() != 'WO_VAT2')                &&
										(keys[i].toUpperCase() != 'WO_VAT3')               && (keys[i].toUpperCase() != 'WO_VAT4')                  && (keys[i].toUpperCase() != 'WO_VAT5')                &&
										(keys[i].toUpperCase() != 'BASE_VAT1')             && (keys[i].toUpperCase() != 'BASE_VAT2')                && (keys[i].toUpperCase() != 'BASE_VAT3')              &&
										(keys[i].toUpperCase() != 'BASE_VAT4')             && (keys[i].toUpperCase() != 'BASE_VAT5')                && (keys[i].toUpperCase() != 'VAT1')                   &&
										(keys[i].toUpperCase() != 'VAT2')   	           && (keys[i].toUpperCase() != 'VAT3')                     && (keys[i].toUpperCase() != 'VAT4')                   &&
										(keys[i].toUpperCase() != 'VAT5')                  && (keys[i].toUpperCase() != 'INC_VAT1')                 && (keys[i].toUpperCase() != 'INC_VAT2')               &&
										(keys[i].toUpperCase() != 'INC_VAT3')              && (keys[i].toUpperCase() != 'INC_VAT4')                 && (keys[i].toUpperCase() != 'INC_VAT5')               &&
										(keys[i].toUpperCase() != 'VAT_TYPE1')             && (keys[i].toUpperCase() != 'VAT_TYPE2')                && (keys[i].toUpperCase() != 'VAT_TYPE3')              &&
										(keys[i].toUpperCase() != 'VAT_TYPE4')             && (keys[i].toUpperCase() != 'VAT_TYPE5'))
									{
										doc[keys[i]] = values[i];
									}
								}

								if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
									doc["accounted_on"] = '';
									doc["accounted_datetime"] = '';
									doc["accounted_on_ext_site"] = '';
									doc["accounted_on_ext"] = '';
								}



							});
						}
		});

	}


	// ***************************************************************************************************************************************
	// Create subdocument (sub level)
	// ***************************************************************************************************************************************
	create_subdocument_sub_level()
	{
		if (this.frm.is_dirty())
		{
			let me = this;
			let fct_callback = function () { me.do_create_subdocument_sub_level(); };
			this.frm.save('Save', fct_callback);
		}else
		{
			this.do_create_subdocument_sub_level();
		}
	}
	do_create_subdocument_sub_level()
	{
		let sublevel_separator = '';
		let sublevel_nb_digit = 0;

		for (var i = 0; i < this.prefixes.length; i++)
		{
			if (this.prefixes[i][1] == this.frm.doc.prefix_id)
			{
				sublevel_separator = this.prefixes[i][4];
				sublevel_nb_digit = this.prefixes[i][5];
				break;
			}
		}

		let me = this;

		let new_identification = '';

        let amethod = '';

        if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
        {	amethod = this.path_sales_quote + '.ioi_sales_quote_get_next_subdoc_sublevel';
        }else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
        {	amethod = this.path_sales_order + '.ioi_sales_order_get_next_subdoc_sublevel';
        }else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
        {	amethod = this.path_sales_delivery + '.ioi_sales_delivery_get_next_subdoc_sublevel';
        }else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
        {	amethod = this.path_sales_invoice + '.ioi_sales_invoice_get_next_subdoc_sublevel';
        }

		frappe.call({  	method: amethod,
						args: {"prefix_id": this.frm.doc.prefix_id, "identification" : this.frm.doc.identification, "sublevel_separator" : sublevel_separator, "sublevel_nb_digits": sublevel_nb_digit},
						async: false,
		   				callback: function(r)	{
													new_identification = r.message;
												}
		});


		let current_document_type = me.frm.doc.document_type;
		let current_prefix_id = me.frm.doc.prefix_id;
		let current_journal_id = me.frm.doc.journal_id;
		let current_identification = me.frm.doc.name;

		let new_root = ''

		if ((!me.frm.doc.root_id) || ((me.frm.doc.root_id) && (me.frm.doc.root_id.trim() == '')))
		{
			new_root = me.frm.doc.name;
		}else
		{
			new_root = me.frm.doc.root_id;
		}



		frappe.call({  	method: this.path_sales_document + '.ioi_sales_document_get_document',
						args: {"doctype": this.frm.doctype, "name": this.frm.doc.name},
						async: false,
						callback: function(r){

							let keys = Object.keys(r.message);
							let values = Object.values(r.message);

							frappe.new_doc(me.frm.doctype, {}, doc => {

								doc["document_type"] = current_document_type;
								doc["journal_id"] = current_journal_id;
								doc["prefix_id"] = current_prefix_id;
								doc["id_prefix"] = current_prefix_id;
								doc["identification"] = new_identification;



								doc["parent_id"] = current_identification;
								doc["root_id"] = new_root;
								doc["level_tp"] = 2;

								for (var i = 0; i < keys.length; i++)
								{
									if ((keys[i].toUpperCase() != 'NAME')                  && (keys[i].toUpperCase() != 'OWNER')                    && (keys[i].toUpperCase() != 'CREATION')               &&
										(keys[i].toUpperCase() != 'MODIFIED')              && (keys[i].toUpperCase() != 'MODIFIED_BY')              && (keys[i].toUpperCase() != 'DOCSTATUS')              &&
										(keys[i].toUpperCase() != 'IDX')                   && (keys[i].toUpperCase() != 'DOCUMENT_TYPE')            && (keys[i].toUpperCase() != 'IOISTATUS')              &&
										(keys[i].toUpperCase() != 'LIB_IOISTATUS')         && (keys[i].toUpperCase() != 'DOCTYPE')                  && (keys[i].toUpperCase() != 'JOURNAL_ID')             &&
										(keys[i].toUpperCase() != 'PREFIX_ID')             && (keys[i].toUpperCase() != 'IDENTIFICATION')           && (keys[i].toUpperCase() != 'PARENT_ID')              &&
										(keys[i].toUpperCase() != 'ROOT_ID')               && (keys[i].toUpperCase() != 'LEVEL_TP')                 && (keys[i].toUpperCase() != 'LINE_DETAIL')            &&
										(keys[i].toUpperCase() != 'ADDRESS_DETAIL')        && (keys[i].toUpperCase() != 'LOG_DETAIL')	            && (keys[i].toUpperCase() != 'ID_PREFIX')              &&
										(keys[i].toUpperCase() != 'ID_NUMBER')             && (keys[i].toUpperCase() != 'TOTAL_HTVA')               && (keys[i].toUpperCase() != 'TOTAL_BASE')             &&
										(keys[i].toUpperCase() != 'TOTAL_VAT')             && (keys[i].toUpperCase() != 'TOTAL_TVAC')               && (keys[i].toUpperCase() != 'TOTAL_FIN_DISCOUNT')     && (keys[i].toUpperCase() != 'TOTAL_FAST_FIN_DISCOUNT') &&
										(keys[i].toUpperCase() != 'TOTAL_WEIGHT')          && (keys[i].toUpperCase() != 'TOTAL_VOLUME')             && (keys[i].toUpperCase() != 'TOTAL_HTVA_OPTION_PART') &&
										(keys[i].toUpperCase() != 'TOTAL_WO_VAT_NEG_PART') && (keys[i].toUpperCase() != 'TOTAL_IN_SYSTEM_CURRENCY') && (keys[i].toUpperCase() != 'RATE_VAT1')              &&
										(keys[i].toUpperCase() != 'RATE_VAT2')             && (keys[i].toUpperCase() != 'RATE_VAT3')                && (keys[i].toUpperCase() != 'RATE_VAT4')              &&
										(keys[i].toUpperCase() != 'RATE_VAT5')             && (keys[i].toUpperCase() != 'WO_VAT1')                  && (keys[i].toUpperCase() != 'WO_VAT2')                &&
										(keys[i].toUpperCase() != 'WO_VAT3')               && (keys[i].toUpperCase() != 'WO_VAT4')                  && (keys[i].toUpperCase() != 'WO_VAT5')                &&
										(keys[i].toUpperCase() != 'BASE_VAT1')             && (keys[i].toUpperCase() != 'BASE_VAT2')                && (keys[i].toUpperCase() != 'BASE_VAT3')              &&
										(keys[i].toUpperCase() != 'BASE_VAT4')             && (keys[i].toUpperCase() != 'BASE_VAT5')                && (keys[i].toUpperCase() != 'VAT1')                   &&
										(keys[i].toUpperCase() != 'VAT2')   	           && (keys[i].toUpperCase() != 'VAT3')                     && (keys[i].toUpperCase() != 'VAT4')                   &&
										(keys[i].toUpperCase() != 'VAT5')                  && (keys[i].toUpperCase() != 'INC_VAT1')                 && (keys[i].toUpperCase() != 'INC_VAT2')               &&
										(keys[i].toUpperCase() != 'INC_VAT3')              && (keys[i].toUpperCase() != 'INC_VAT4')                 && (keys[i].toUpperCase() != 'INC_VAT5')               &&
										(keys[i].toUpperCase() != 'VAT_TYPE1')             && (keys[i].toUpperCase() != 'VAT_TYPE2')                && (keys[i].toUpperCase() != 'VAT_TYPE3')              &&
										(keys[i].toUpperCase() != 'VAT_TYPE4')             && (keys[i].toUpperCase() != 'VAT_TYPE5'))
									{
										doc[keys[i]] = values[i];
									}
								}

								if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
									doc["accounted_on"] = '';
									doc["accounted_datetime"] = '';
								}



							});
						}
		});
	}

	// ***************************************************************************************************************************************
	// Fill Valid Delay Mode
	// ***************************************************************************************************************************************
	fill_valid_delay_mode()
	{
		this.valid_delay_mode = [];

		if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
		{
			this.valid_delay_mode[0] = ['DATE', __('Date')];
			this.valid_delay_mode[1] = ['DELAY', __('Delay')];

			this.frm.set_df_property('valid_mode', 'options', [	{ label: this.valid_delay_mode[0][1], value: this.valid_delay_mode[0][0]},
																{ label: this.valid_delay_mode[1][1], value: this.valid_delay_mode[1][0]}
															  ]);
		}
	}

	// ***************************************************************************************************************************************
	// Get next number
	// ***************************************************************************************************************************************
	get_next_number()
	{
		let me = this;

		if (this.frm.is_new())
		{
			if (!this.frm.doc.level_tp)
			{	this.frm.doc.level_tp = 0;
			}

			if ((this.frm.doc.level_tp == 0) || (this.frm.doc.level_tp == 3))
			{
				this.frm.set_intro('', '');

				for (var i = 0; i < this.prefixes.length; i++)
				{
					if (this.prefixes[i][1] == this.frm.doc.prefix_id)
					{

						this.frm.set_value('identification', this.prefixes[i][2]+1);

						let id_journal = '';
						let id_prefix = '';
						let id_last_number = 0;
						let id_number_digits = 0;
						let id_formatted = '';

						id_journal = this.prefixes[i][0];

						this.frm.doc.journal_id = id_journal;

						this.frm.refresh_field('journal_id');
						this.frm.refresh_field('prefix_id');
						this.frm.refresh_field('id_prefix');
						this.frm.refresh_field('id_number');
						this.frm.refresh_field('identification');

						let me = this;

						if ((this.frm.doc.document_date) && (this.frm.doc.document_date != '')) {

							let amethod = this.path_sales_journal + '.ioi_sales_journal_get_dynamic_catalog';


							frappe.call({  	method: amethod,
											args: { "journal_id": this.frm.doc.journal_id,
													"dynamic_dt": this.frm.doc.document_date
											},
											async: false,
											callback:function(r){
																	if ((r.message) && (r.message != ''))
																	{
																		if (r.message != me.frm.doc.sales_catalog_id)
																		{
																			if (me.frm.doc.line_detail)
																			{
																				let found = false;

																				for (var i = 0; i < me.frm.doc.line_detail.length; i++)
																				{
																					if ((me.frm.doc.line_detail[i].item_id) && (me.frm.doc.line_detail[i].item_id.trim() != ''))
																					{
																						if ((me.frm.doc.line_detail[i].sales_condition_id) && (me.frm.doc.line_detail[i].sales_condition_id != me.customer_manual_sales_condition_id))
																						{
																							found = true;
																							break;
																						}
																					}
																				}

																				if (found)
																				{
																					me.frm.set_intro('Calatog has been changed, update line prices please (Action menu -> Update line prices)', 'orange');
																				}
																			}
																		}
																	}
																}
							});
						}




						this.load_sales_journal_data();

						id_prefix = this.prefixes[i][1];

						this.frm.doc.division_id = this.prefixes[i][6];
						this.frm.doc.site_id = this.prefixes[i][7];
						this.frm.doc.vat_included_control = this.prefixes[i][8];
						this.frm.refresh_field('division_id');
						this.frm.refresh_field('site_id');
						this.frm.refresh_field('vat_included_control');

						if ((this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')) {

							if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') {

								if (this.frm.doc.document_type == 'QUOTE') {
									this.frm.set_value('mono_dossier', this.journal_quotes_default_mono_dossier);
								}else{
									this.frm.set_value('mono_dossier', this.journal_contracts_default_mono_dossier);
								}

							}else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
								this.frm.set_value('mono_dossier', this.journal_orders_default_mono_dossier);

							}

						}

						if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
							this.frm.set_value('qty_to_prepare_mode', this.journal_deliveries_qty_to_prepare_mode);
						}

						if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
						{
							this.frm.doc.vcs_mode = this.prefixes[i][9];
							this.frm.doc.vcs_param = this.prefixes[i][10];
							this.frm.refresh_field('vcs_mode');
							this.frm.refresh_field('vcs_param');

							this.frm.set_df_property('vcs_reference', 'read_only', 0);
							if (this.frm.doc.vcs_mode != 0)
							{	this.frm.set_df_property('vcs_reference', 'read_only', 1);
							}

						}

						this.set_query('warehouse_id', '', this.path_warehouse + '.ioi_warehouse_get_enabled', '{"site_id":"' + this.frm.doc.site_id + '"}');

						this.fill_period();
						this.set_query('period_id', '', this.path_sales_document + '.ioi_sales_document_get_enabled_period', '{"division":"' + this.frm.doc.division_id + '"}');

						let journal_period_break_numbering = 0;
						let journal_period_break_position = 0;
						let mode_break = false;

						if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
						{
							if (this.frm.doc.document_type.toUpperCase() == 'QUOTE')
							{
								journal_period_break_numbering = this.journal_quotes_period_break_numbering;
								journal_period_break_position = this.journal_quotes_period_break_position;

								this.frm.doc.do_not_apply_index = this.journal_quotes_do_not_apply_index;;
								this.frm.refresh_field('do_not_apply_index');

								if (this.journal_quotes_approval_required == 1) {

									let method = this.path_sales_document + '.ioi_sales_document_compute_approval_before_date';

									frappe.call({  	method: method,
													args: {"document_date" : this.frm.doc.document_date, "delay": this.journal_quotes_approval_default_delay },
													async: false,
													callback:function(r)	{
														me.frm.doc.approval_before_date = r.message;
														me.frm.refresh_field('approval_before_date');
													}
									});
								}

							}else
							{
								journal_period_break_numbering = this.journal_contracts_period_break_numbering;
								journal_period_break_position = this.journal_contracts_period_break_position;

								this.frm.doc.do_not_apply_index = this.journal_contracts_do_not_apply_index;;
								this.frm.refresh_field('do_not_apply_index');

								if (this.journal_contracts_approval_required == 1) {

									let method = this.path_sales_document + '.ioi_sales_document_compute_approval_before_date';

									frappe.call({  	method: method,
													args: {"document_date" : this.frm.doc.document_date, "delay": this.journal_contracts_approval_default_delay },
													async: false,
													callback:function(r)	{
														me.frm.doc.approval_before_date = r.message;
														me.frm.refresh_field('approval_before_date');
													}
									});
								}


							}
						}else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
						{
							journal_period_break_numbering = this.journal_orders_period_break_numbering;
							journal_period_break_position = this.journal_orders_period_break_position;

							this.frm.doc.do_not_apply_index = this.journal_orders_do_not_apply_index;
							this.frm.refresh_field('do_not_apply_index');

							if (this.journal_orders_approval_required == 1) {

								let method = this.path_sales_document + '.ioi_sales_document_compute_approval_before_date';

								frappe.call({  	method: method,
												args: {"document_date" : this.frm.doc.document_date, "delay": this.journal_orders_approval_default_delay },
												async: false,
												callback:function(r)	{
													me.frm.doc.approval_before_date = r.message;
													me.frm.refresh_field('approval_before_date');
												}
								});
							}


							document.getElementById('ioi-sales-order-ioi_sales_order_tab_payment-tab').hidden = true;

							let show_payment_tab = 0;

							if (me.frm.is_new()) {

								if (this.journal_orders_cashdesk_active == 1) {
									show_payment_tab = 1;
								}

							}else{
								show_payment_tab = 1;
							}

							if (show_payment_tab == 1) {

								document.getElementById('ioi-sales-order-ioi_sales_order_tab_payment-tab').hidden = false;
							}

							if ((this.frm.doc.site_id) && (this.frm.doc.site_id.trim() != '')) {

								if ((this.frm.doc.currency_id) && (this.frm.doc.currency_id.trim() != '')) {
									this.set_query('cashdesk_id', '', this.path_cash_desk + '.ioi_cash_desk_get', '{"site_id":"' + this.frm.doc.site_id + '", "currency_id":"' + this.frm.doc.currency_id + '"}');
								}else {
									this.set_query('cashdesk_id', '', this.path_cash_desk + '.ioi_cash_desk_get', '{"site_id":"' + this.frm.doc.site_id + '"}');
								}
							}else {
								if ((this.frm.doc.currency_id) && (this.frm.doc.currency_id.trim() != '')) {
									this.set_query('cashdesk_id', '', this.path_cash_desk + '.ioi_cash_desk_get', '{"currency_id":"' + this.frm.doc.currency_id + '"}');
								}else{
									this.set_query('cashdesk_id', '', this.path_cash_desk + '.ioi_cash_desk_get', '');
								}
							}

							this.frm.doc.pass_through_supplier_id = this.journal_orders_pass_through_supplier_id;
							this.frm.doc.pass_through_mode = this.journal_orders_pass_through_mode;
							this.frm.doc.pass_through_discard_lines_without_qty = this.journal_orders_pass_through_discard_lines_without_qty;
							this.frm.doc.pass_through_mirror_virtual_items = this.journal_orders_pass_through_mirror_virtual_items;
							this.frm.doc.pass_through_purchases_journal_id = this.journal_orders_pass_through_purchases_journal_id;
							this.frm.refresh_field('pass_through_supplier_id');
							this.frm.refresh_field('pass_through_mode');
							this.frm.refresh_field('pass_through_discard_lines_without_qty');
							this.frm.refresh_field('pass_through_mirror_virtual_items');
							this.frm.refresh_field('pass_through_purchases_journal_id');

							if ((this.frm.doc.site_id) && (this.frm.doc.site_id.trim() != '')) {
								this.set_query('pass_through_purchases_journal_id', '', this.path_purchases_journal + '.ioi_purchases_journal_get_enabled', '{"site_id": "' + this.frm.doc.site_id + '"}');
							}else{
								this.set_query('pass_through_purchases_journal_id', '', this.path_purchases_journal + '.ioi_purchases_journal_get_enabled', '');
							}


						}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
						{
							journal_period_break_numbering = this.journal_deliveries_period_break_numbering;
							journal_period_break_position = this.journal_deliveries_period_break_position;

							this.frm.doc.do_not_apply_index = this.journal_deliveries_do_not_apply_index;;
							this.frm.refresh_field('do_not_apply_index');

							if (this.journal_deliveries_approval_required == 1) {

								let method = this.path_sales_document + '.ioi_sales_document_compute_approval_before_date';

								frappe.call({  	method: method,
												args: {"document_date" : this.frm.doc.document_date, "delay": this.journal_deliveries_approval_default_delay },
												async: false,
												callback:function(r)	{
													me.frm.doc.approval_before_date = r.message;
													me.frm.refresh_field('approval_before_date');
												}
								});
							}



							this.frm.set_df_property('payment_term_id', 'read_only', 0);

							if (this.journal_deliveries_cashdesk_active == 1)  {
								if (this.journal_deliveries_lock_payment_terms == 1) {
									this.frm.set_df_property('payment_term_id', 'read_only', 1)
								}
							}

							document.getElementById('ioi-sales-delivery-ioi_sales_delivery_tab_payment-tab').hidden = true;

							let show_payment_tab = 0;

							if (me.frm.is_new()) {

								if (this.journal_deliveries_cashdesk_active == 1) {
									show_payment_tab = 1;
								}

							}else{
								show_payment_tab = 1;
							}

							if ((show_payment_tab == 1) || (this.frm.doc.cashdesk_pay_on_delivery == 1)) {

								document.getElementById('ioi-sales-delivery-ioi_sales_delivery_tab_payment-tab').hidden = false;
							}

							if ((this.frm.doc.site_id) && (this.frm.doc.site_id.trim() != '')) {

								if ((this.frm.doc.currency_id) && (this.frm.doc.currency_id.trim() != '')) {
									this.set_query('cashdesk_id', '', this.path_cash_desk + '.ioi_cash_desk_get', '{"site_id":"' + this.frm.doc.site_id + '", "currency_id":"' + this.frm.doc.currency_id + '"}');
								}else {
									this.set_query('cashdesk_id', '', this.path_cash_desk + '.ioi_cash_desk_get', '{"site_id":"' + this.frm.doc.site_id + '"}');
								}
							}else {
								if ((this.frm.doc.currency_id) && (this.frm.doc.currency_id.trim() != '')) {
									this.set_query('cashdesk_id', '', this.path_cash_desk + '.ioi_cash_desk_get', '{"currency_id":"' + this.frm.doc.currency_id + '"}');
								}else{
									this.set_query('cashdesk_id', '', this.path_cash_desk + '.ioi_cash_desk_get', '');
								}
							}






						}else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
						{
							if (this.frm.doc.document_type.toUpperCase() == 'INVOICE')
							{
								journal_period_break_numbering = this.journal_invoices_period_break_numbering;
								journal_period_break_position = this.journal_invoices_period_break_position;

								this.frm.doc.do_not_apply_index = this.journal_invoices_do_not_apply_index;;
								this.frm.refresh_field('do_not_apply_index');

								if (this.journal_invoices_approval_required == 1) {

									let method = this.path_sales_document + '.ioi_sales_document_compute_approval_before_date';

									frappe.call({  	method: method,
													args: {"document_date" : this.frm.doc.document_date, "delay": this.journal_invoices_approval_default_delay },
													async: false,
													callback:function(r)	{
														me.frm.doc.approval_before_date = r.message;
														me.frm.refresh_field('approval_before_date');
													}
									});
								}

							}else
							{
								journal_period_break_numbering = this.journal_cnotes_period_break_numbering;
								journal_period_break_position = this.journal_cnotes_period_break_position;

								this.frm.doc.do_not_apply_index = this.journal_cnotes_do_not_apply_index;;
								this.frm.refresh_field('do_not_apply_index');

								if (this.journal_cnotes_approval_required == 1) {

									let method = this.path_sales_document + '.ioi_sales_document_compute_approval_before_date';

									frappe.call({  	method: method,
													args: {"document_date" : this.frm.doc.document_date, "delay": this.journal_cnotes_approval_default_delay },
													async: false,
													callback:function(r)	{
														me.frm.doc.approval_before_date = r.message;
														me.frm.refresh_field('approval_before_date');
													}
									});
								}


							}

							document.getElementById('ioi-sales-invoice-ioi_sales_invoice_tab_payment-tab').hidden = true;

							let show_payment_tab = 0;

							if (me.frm.is_new()) {

								if (this.frm.doc.document_type.toUpperCase() == 'INVOICE') {

									if (this.journal_invoices_cashdesk_active == 1) {
										show_payment_tab = 1;
									}
								}else{

									if (this.journal_cnotes_cashdesk_active == 1) {
										show_payment_tab = 1;
									}
								}

							}else{
								show_payment_tab = 1;
							}

							if (show_payment_tab == 1) {

								document.getElementById('ioi-sales-invoice-ioi_sales_invoice_tab_payment-tab').hidden = false;
							}

							if ((this.frm.doc.site_id) && (this.frm.doc.site_id.trim() != '')) {

								if ((this.frm.doc.currency_id) && (this.frm.doc.currency_id.trim() != '')) {
									this.set_query('cashdesk_id', '', this.path_cash_desk + '.ioi_cash_desk_get', '{"site_id":"' + this.frm.doc.site_id + '", "currency_id":"' + this.frm.doc.currency_id + '"}');
								}else {
									this.set_query('cashdesk_id', '', this.path_cash_desk + '.ioi_cash_desk_get', '{"site_id":"' + this.frm.doc.site_id + '"}');
								}
							}else {
								if ((this.frm.doc.currency_id) && (this.frm.doc.currency_id.trim() != '')) {
									this.set_query('cashdesk_id', '', this.path_cash_desk + '.ioi_cash_desk_get', '{"currency_id":"' + this.frm.doc.currency_id + '"}');
								}else{
									this.set_query('cashdesk_id', '', this.path_cash_desk + '.ioi_cash_desk_get', '');
								}
							}

						}



						if (journal_period_break_numbering == 1)
						{
							mode_break = true;
						}

						if (!mode_break)
						{
							id_last_number = this.prefixes[i][2]+1;

							id_number_digits = this.prefixes[i][3];

							if (id_number_digits != 0)
							{
								let n = id_number_digits - id_last_number.toString().trim().length;

								if (n >= 0)
								{
									for (var j = 0; j < n; j++)
									{	id_formatted += '0';
									}
								}
							}

							id_formatted += id_last_number.toString().trim();

							this.frm.doc.level_tp = '0';
						}else
						{
							let amethod = '';
							let tp = ''
							let period_year = ''
							let period_month = ''



							if ((!this.frm.doc.period_id) || ((this.frm.doc.period_id) && (this.frm.doc.period_id.toString().trim() == '')))
							{
								if ((!this.frm.doc.document_date) || ((this.frm.doc.document_date) && (this.frm.doc.document_date.trim() == '')))
								{
									frappe.msgprint({title: __("Message"), message: __('Document date is mandatory'), indicator: "red"});
									raise;
								}
								let dt = new Date(this.frm.doc.document_date)
								period_year = dt.getFullYear();
								period_month = dt.getMonth()+1;

								if (Number(period_month) < 10)
								{	period_month = '0' + period_month
								}

							}else
							{
								let me = this;
								let method = this.path_sales_document + '.ioi_sales_document_get_period';

								frappe.call({  	method: method,
												args: {"period_id" : this.frm.doc.period_id.toString()},
												async: false,
												callback:function(r)	{
																			if (r.message.length == 0)
																			{
																				frappe.msgprint({title: __("Message"), message: __('No Period found'), indicator: "red"});
																				raise;
																			}

																			period_year = Number(r.message[0].year);

																			if (Number(r.message[0].month) < 10)
																			{
																				period_month = '0' + r.message[0].month;
																			}else
																			{
																				period_month = r.message[0].month;
																			}
																		}
								});

							}

							if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
							{
								amethod = this.path_sales_journal + '.ioi_sales_journal_get_quotes_contracts_period_break_last_number';

								if ((!this.frm.doc.document_type) || (this.frm.doc.document_type) && (this.frm.doc.document_type.trim() == ''))
								{	tp = 'QUOTE'
								}else
								{	tp = this.frm.doc.document_type.toUpperCase();
								}

							}else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
							{
								amethod = this.path_sales_journal + '.ioi_sales_journal_get_orders_period_break_last_number';
								tp = this.frm.doc.document_type.toUpperCase();

							}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
							{
								amethod = this.path_sales_journal + '.ioi_sales_journal_get_deliveries_period_break_last_number';
								tp = this.frm.doc.document_type.toUpperCase();

							}else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
							{
								amethod = this.path_sales_journal + '.ioi_sales_journal_get_invoices_cnotes_period_break_last_number';

								if ((!this.frm.doc.document_type) || (this.frm.doc.document_type) && (this.frm.doc.document_type.trim() == ''))
								{	tp = 'INVOICE'
								}else
								{	tp = this.frm.doc.document_type.toUpperCase();
								}
							}


							let me = this;

							frappe.call({  	method: amethod,
											args: {"tp":tp, "current_prefix": this.frm.doc.prefix_id, "period_year" : period_year, "period_month": period_month},
											async: false,
											callback:function(r){
																	id_last_number = r.message[0][0];
																	id_number_digits = me.prefixes[i][3];
																	id_formatted = r.message[0][0];
																}
							});

							this.frm.doc.level_tp = '3';
						}

						this.frm.set_value('journal_id', id_journal);

						this.frm.set_value('id_prefix', id_prefix);
						this.frm.set_value('id_number', id_last_number);
						this.frm.set_value('identification', id_formatted);
						this.frm.set_df_property('identification', 'read_only', 1);


						this.frm.doc.sales_catalog_id = this.journal_sales_catalog_id;
						this.frm.refresh_field('sales_catalog_id');

						this.frm.set_value('sales_condition_id', this.journal_sales_condition_id);
						this.frm.set_value('warehouse_id', this.journal_warehouse_id);

						if ((this.frm.doc.vat_type == 'N') || (this.frm.doc.vat_type == 'NAT'))
						{
							this.frm.set_value('vat_type', this.journal_vat_type);
						}

						if ((!this.frm.doc.spec_vat_rate) || ((this.frm.doc.spec_vat_rate) && (this.frm.doc.spec_vat_rate == 0)))
						{
							this.frm.set_value('spec_vat_rate', this.journal_spec_vat_rate);
						}

						this.frm.refresh_field('vat_type');
						this.frm.refresh_field('spec_vat_rate');


						if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
						{
							if ((this.frm.doc.request_date) && (this.frm.doc.request_date.trim() != ''))
							{
								let dt = new Date(this.frm.doc.request_date);
								dt.setDate(dt.getDate() + this.journal_quotes_max_answer_delay);
								let sdt = dt.getFullYear().toString() + '-' + (dt.getMonth()+1).toString() + '-' + dt.getDate().toString();
								this.frm.set_value('answer_limit_date', sdt);
							}

							this.fill_valid_delay_mode();

							if (this.frm.doc.document_type == 'QUOTE')
							{	this.frm.set_value('valid_mode', this.journal_quotes_valid_delay_mode);
								this.frm.set_value('valid_days', this.journal_quotes_valid_delay);
							}else
							{	this.frm.set_value('valid_mode', this.journal_contracts_valid_delay_mode);
								this.frm.set_value('valid_days', this.journal_contracts_valid_delay);
							}

							if ((this.frm.doc.request_date) && (this.frm.doc.request_date.trim() != ''))
							{
								if (this.frm.doc.valid_mode == 'DATE')
								{
									let dt = new Date(this.frm.doc.request_date);
									let sdt = dt.getFullYear().toString() + '-' + (dt.getMonth()+1).toString() + '-' + dt.getDate().toString();
									this.frm.set_value('valid_date', sdt);
								}else
								{
									let dt = new Date(this.frm.doc.request_date);
									dt.setDate(dt.getDate() + this.frm.doc.valid_days);
									let sdt = dt.getFullYear().toString() + '-' + (dt.getMonth()+1).toString() + '-' + dt.getDate().toString();
									this.frm.set_value('valid_date', sdt);
								}
							}
						}

					}
				}
			}


			if ((this.frm.doc.document_date) && (this.frm.doc.document_date != '')) {
				this.set_query('manufacturer_ref', 'line_detail', this.path_manufacturer_catalog + '.ioi_manufacturer_catalog_get_for_sales_purchases', '{"document_date": "' + this.frm.doc.document_date + '"}');
			}else{
				this.set_query('manufacturer_ref', 'line_detail', this.path_manufacturer_catalog + '.ioi_manufacturer_catalog_get_for_sales_purchases', '');
			}

		}else{
			if ((!this.frm.doc.ioistatus) || ((this.frm.doc.ioistatus) && (this.frm.doc.ioistatus == 0))) {

				if ((this.frm.doc.document_date) && (this.frm.doc.document_date != '')) {
					this.set_query('manufacturer_ref', 'line_detail', this.path_manufacturer_catalog + '.ioi_manufacturer_catalog_get_for_sales_purchases', '{"document_date": "' + this.frm.doc.document_date + '"}');
				}else{
					this.set_query('manufacturer_ref', 'line_detail', this.path_manufacturer_catalog + '.ioi_manufacturer_catalog_get_for_sales_purchases', '');
				}


				if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
				{
					if (this.frm.doc.document_type.toUpperCase() == 'QUOTE')
					{
						if (this.journal_quotes_approval_required == 1) {

							let method = this.path_sales_document + '.ioi_sales_document_compute_approval_before_date';

							frappe.call({  	method: method,
											args: {"document_date" : this.frm.doc.document_date, "delay": this.journal_quotes_approval_default_delay },
											async: false,
											callback:function(r)	{
												me.frm.doc.approval_before_date = r.message;
												me.frm.refresh_field('approval_before_date');
											}
							});
						}

					}else
					{
						if (this.journal_contracts_approval_required == 1) {

							let method = this.path_sales_document + '.ioi_sales_document_compute_approval_before_date';

							frappe.call({  	method: method,
											args: {"document_date" : this.frm.doc.document_date, "delay": this.journal_contracts_approval_default_delay },
											async: false,
											callback:function(r)	{
												me.frm.doc.approval_before_date = r.message;
												me.frm.refresh_field('approval_before_date');
											}
							});
						}


					}
				}else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
				{
					if (this.journal_orders_approval_required == 1) {

						let method = this.path_sales_document + '.ioi_sales_document_compute_approval_before_date';

						frappe.call({  	method: method,
										args: {"document_date" : this.frm.doc.document_date, "delay": this.journal_orders_approval_default_delay },
										async: false,
										callback:function(r)	{
											me.frm.doc.approval_before_date = r.message;
											me.frm.refresh_field('approval_before_date');
										}
						});
					}


				}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
				{
					if (this.journal_deliveries_approval_required == 1) {

						let method = this.path_sales_document + '.ioi_sales_document_compute_approval_before_date';

						frappe.call({  	method: method,
										args: {"document_date" : this.frm.doc.document_date, "delay": this.journal_deliveries_approval_default_delay },
										async: false,
										callback:function(r)	{
											me.frm.doc.approval_before_date = r.message;
											me.frm.refresh_field('approval_before_date');
										}
						});
					}

				}else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
				{
					if (this.frm.doc.document_type.toUpperCase() == 'INVOICE')
					{

						if (this.journal_invoices_approval_required == 1) {

							let method = this.path_sales_document + '.ioi_sales_document_compute_approval_before_date';

							frappe.call({  	method: method,
											args: {"document_date" : this.frm.doc.document_date, "delay": this.journal_invoices_approval_default_delay },
											async: false,
											callback:function(r)	{
												me.frm.doc.approval_before_date = r.message;
												me.frm.refresh_field('approval_before_date');
											}
							});
						}

					}else
					{

						if (this.journal_cnotes_approval_required == 1) {

							let method = this.path_sales_document + '.ioi_sales_document_compute_approval_before_date';

							frappe.call({  	method: method,
											args: {"document_date" : this.frm.doc.document_date, "delay": this.journal_cnotes_approval_default_delay },
											async: false,
											callback:function(r)	{
												me.frm.doc.approval_before_date = r.message;
												me.frm.refresh_field('approval_before_date');
											}
							});
						}

					}
				}

			}
		}
	}

	dynamic_catalog(fieldname)
	{
		this.frm.set_intro('', '');

		let execute = true;

		if (fieldname.toUpperCase() == 'DOCUMENT_DATE')
		{
			if (this.frm.is_new())
			{
				execute = false;
			}
		}

		if (execute)
		{
			this.frm.refresh_field('document_date');

			if ((this.frm.doc.document_date) && (this.frm.doc.document_date != ''))
			{
				let me = this;
				let amethod = this.path_sales_journal + '.ioi_sales_journal_get_dynamic_catalog';

				frappe.call({  	method: amethod,
								args: { "journal_id": this.frm.doc.journal_id,
										"dynamic_dt": this.frm.doc.document_date
								},
								async: false,
								callback:function(r){
														if ((r.message) && (r.message != ''))
														{
															if (r.message != me.frm.doc.sales_catalog_id)
															{
																me.journal_sales_catalog_id = r.message;
																me.frm.doc.sales_catalog_id = r.message;

																me.frm.refresh_field('sales_catalog_id');

																if (me.frm.doc.line_detail)
																{
																	let found = false;

																	for (var i = 0; i < me.frm.doc.line_detail.length; i++)
																	{
																		if ((me.frm.doc.line_detail[i].item_id) && (me.frm.doc.line_detail[i].item_id.trim() != ''))
																		{
																			if ((me.frm.doc.line_detail[i].sales_condition_id) && (me.frm.doc.line_detail[i].sales_condition_id != me.customer_manual_sales_condition_id))
																			{
																				found = true;
																				break;
																			}
																		}
																	}

																	if (found)
																	{
																		me.frm.set_intro('Catalog has been changed, update line prices please (Action menu -> Update line prices)', 'orange');
																	}
																}
															}
														}
													}
				});
			}
		}
	}

	// ***************************************************************************************************************************************
	// Set the label with currency
	// ***************************************************************************************************************************************
	#set_original_caption_with_currency(fieldname)
	{
		if (this.frm.fields_dict[fieldname].disp_status != 'None')
		{
			let caption = '';

			caption = __(this.frm.fields_dict[fieldname]._label);

			if(fieldname.toUpperCase() != 'TOTAL_IN_SYSTEM_CURRENCY')
			{
				if ((this.frm.doc.currency_id) && (this.frm.doc.currency_id.trim() != ''))
				{
					caption += '&nbsp;<b>( ' + this.frm.doc.currency_id + ' )</b>';
				}
			}else
			{
				if ((this.system_currency) && (this.system_currency.trim() != ''))
				{
					caption += '&nbsp;<b>( ' + this.system_currency + ' )</b>';
				}
			}

			this.frm.fields_dict[fieldname].wrapper.children[0].children[0].children[0].innerHTML = caption;

			this.#format_float_value(fieldname);
		}
	}

	// ***************************************************************************************************************************************
	// Refresh label of total section
	// ***************************************************************************************************************************************
	refresh_total_section()
	{
		this.#set_original_caption_with_currency('total_htva');
		this.#set_original_caption_with_currency('total_base');
		this.#set_original_caption_with_currency('total_vat');
		this.#set_original_caption_with_currency('total_tvac');
		this.#set_original_caption_with_currency('total_htva_option_part');
		this.#set_original_caption_with_currency('total_wo_vat_neg_part');
		this.#set_original_caption_with_currency('total_in_system_currency');

		this.#format_float_value('rate_vat1', 2);
		this.#format_float_value('rate_vat2', 2);
		this.#format_float_value('rate_vat3', 2);
		this.#format_float_value('rate_vat4', 2);
		this.#format_float_value('rate_vat5', 2);

		this.#set_original_caption_with_currency('wo_vat1');
		this.#set_original_caption_with_currency('wo_vat2');
		this.#set_original_caption_with_currency('wo_vat3');
		this.#set_original_caption_with_currency('wo_vat4');
		this.#set_original_caption_with_currency('wo_vat5');

		this.#set_original_caption_with_currency('base_vat1');
		this.#set_original_caption_with_currency('base_vat2');
		this.#set_original_caption_with_currency('base_vat3');
		this.#set_original_caption_with_currency('base_vat4');
		this.#set_original_caption_with_currency('base_vat5');

		this.#set_original_caption_with_currency('vat1');
		this.#set_original_caption_with_currency('vat2');
		this.#set_original_caption_with_currency('vat3');
		this.#set_original_caption_with_currency('vat4');
		this.#set_original_caption_with_currency('vat5');

		this.#set_original_caption_with_currency('inc_vat1');
		this.#set_original_caption_with_currency('inc_vat2');
		this.#set_original_caption_with_currency('inc_vat3');
		this.#set_original_caption_with_currency('inc_vat4');
		this.#set_original_caption_with_currency('inc_vat5');

		// Other number fields
		if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
		{
			this.frm.fields_dict['est_success_rate'].wrapper.children[0].children[1].children[1].align = 'right';
		}
		this.frm.fields_dict['base_discount'].wrapper.children[0].children[1].children[1].align = 'right';
		this.frm.fields_dict['extra_discount'].wrapper.children[0].children[1].children[1].align = 'right';

		this.frm.fields_dict['financial_discount'].wrapper.children[0].children[1].children[1].align = 'right';
		this.frm.fields_dict['fast_financial_discount'].wrapper.children[0].children[1].children[1].align = 'right';
		this.frm.fields_dict['currency_rate'].wrapper.children[0].children[1].children[1].align = 'right';
		this.frm.fields_dict['currency_rate_inv'].wrapper.children[0].children[1].children[1].align = 'right';

		if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
		{
			this.frm.fields_dict['valid_days'].wrapper.children[0].children[1].children[1].align = 'right';
		}
		this.frm.fields_dict['financial_discount_pay_day'].wrapper.children[0].children[1].children[1].align = 'right';
		this.frm.fields_dict['fast_financial_discount_pay_day'].wrapper.children[0].children[1].children[1].align = 'right';
		this.frm.fields_dict['currency_digit_rounding'].wrapper.children[0].children[1].children[1].align = 'right';

		this.#refresh_total_amounts();
		this.#refresh_vat_summary();

	}

	// ***************************************************************************************************************************************
	// Format float value
	// ***************************************************************************************************************************************
	#format_float_value(fieldname, nb_decimal=0)
	{
		if (this.frm.fields_dict[fieldname].disp_status != 'None')
		{
			this.frm.fields_dict[fieldname].wrapper.children[0].children[1].children[1].align = 'right';

			let value = this.frm.fields_dict[fieldname].value;

			let n = 0;

			if (nb_decimal == 0)
			{
				if ((this.frm.doc.currency_digit_rounding) && (this.frm.doc.currency_digit_rounding.toString().trim() != ''))
				{
					n = parseInt(this.frm.doc.currency_digit_rounding);
				}
			}else
			{
				n = nb_decimal;
			}

			let number_format = frappe.sys_defaults.number_format;
			let idx_point = -1;
			let idx_coma = -1;
			let str_value = '';

			for(var i = 0; i < number_format.length; i++)
			{
				if (number_format[i] == ',')
				{	idx_coma = i;
				}else if (number_format[i] == '.')
				{	idx_point = i;
				}
			}

			if (value != 0)
			{	str_value = parseFloat(value.toFixed(n)).toString();

				if (idx_coma >= idx_point)
				{	str_value = str_value.replace('.', ',');

					if (str_value.indexOf(',') == -1)
					{
						if (n > 0)
						{
							str_value += ',';

							for (var i = 0; i < n; i++)
							{
								str_value += '0';
							}
						}
					}else
					{
						if (n > 0)
						{
							for (var i = str_value.substring(str_value.indexOf(',')+1, str_value.length).length; i < n; i++)
							{
								str_value += '0';
							}
						}
					}
				}else
				{
					if (str_value.indexOf('.') == -1)
					{
						if (n > 0)
						{
							str_value += '.';

							for (var i = 0; i < n; i++)
							{
								str_value += '0';
							}
						}
					}else
					{
						if (n > 0)
						{
							for (var i = str_value.substring(str_value.indexOf('.')+1, str_value.length).length; i < n; i++)
							{
								str_value += '0';
							}
						}
					}
				}

				this.frm.fields_dict[fieldname].wrapper.children[0].children[1].children[1].innerText = str_value;
			}else
			{	str_value = '0';

				if (n > 0)
				{
					str_value += '.';

					for (var i = 0; i < n; i++)
					{
						str_value += '0';
					}

					if (idx_coma >= idx_point)
					{	str_value = str_value.replace('.', ',');
					}
				}

				this.frm.fields_dict[fieldname].wrapper.children[0].children[1].children[1].innerText = str_value;
			}
		}
	}

	// ***************************************************************************************************************************************
	// Lien Detail :Set the label with currency
	// ***************************************************************************************************************************************
	#detail_set_original_caption_with_currency(item, detail, fieldname, format_float = false)
	{
		if (this.frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form)
		{
			if (this.frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].disp_status)
			{
				if (this.frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].disp_status != 'None')
				{
					let caption = '';

					caption = __(this.frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname]._label);

					if ((fieldname.toUpperCase() != 'TOTAL_IN_SYSTEM_CURRENCY') && (fieldname.toUpperCase() != 'VALUE_LINE_SYS_CURRENCY') &&
						(fieldname.toUpperCase() != 'ITEM_COST') && (fieldname.toUpperCase() != 'ITEM_COST_STD_STAMP') &&
						(fieldname.toUpperCase() != 'MANUAL_COST') && (fieldname.toUpperCase() != 'COST_VALUE') &&
						(fieldname.toUpperCase() != 'PURCH_UNIT_PRICE_FND') && (fieldname.toUpperCase() != 'TOTAL_SUPPLIER_PRICE'))
					{
						if ((this.frm.doc.currency_id) && (this.frm.doc.currency_id.trim() != ''))
						{
							caption += '&nbsp;<b>( ' + this.frm.doc.currency_id + ' )</b>';
						}
					}else
					{
						if ((this.system_currency) && (this.system_currency.trim() != ''))
						{
							caption += '&nbsp;<b>( ' + this.system_currency + ' )</b>';
						}
					}

					this.frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].wrapper.children[0].children[0].children[0].innerHTML = caption;

					if (format_float)
					{
						this.#format_float_value(fieldname);
					}
				}
			}
		}
	}

	// ***************************************************************************************************************************************
	// Refresh vat summary
	// ***************************************************************************************************************************************
	#refresh_total_amounts(frm)
	{
		let html = '';

		let div_height = '138px';

		if ((this.frm.doc.financial_discount_mode) && ((this.frm.doc.financial_discount_mode == 1) || ((this.frm.doc.financial_discount_mode == 2) && ((this.frm.doc.vat_type == 'N') || (this.frm.doc.vat_type == 'NAT')))))
		{
			if ((((this.frm.doc.financial_discount) && (this.frm.doc.financial_discount != 0)) && ((!this.frm.doc.fast_financial_discount) || (this.frm.doc.fast_financial_discount == 0))) ||
				(((!this.frm.doc.financial_discount) || (this.frm.doc.financial_discount == 0)) && ((this.frm.doc.fast_financial_discount) && (this.frm.doc.fast_financial_discount != 0)))) {
				div_height = '210px';
			}else{
				if ((((this.frm.doc.financial_discount) && (this.frm.doc.financial_discount != 0)) && ((this.frm.doc.fast_financial_discount) || (this.frm.doc.fast_financial_discount != 0)))) {
					div_height = '267px';
				}

			}
		}

		html += '<div style="overflow:auto; height:' + div_height + '">';
		html += '<table border=0 style="border: 0px solid #E8EAEB">';

		html += '<tr style="height:32px">';
		html += '<td width=200px style="vertical-align: middle;">' + __('Total w/o VAT') + '</td>';
		html += '<td width=150px style="vertical-align: middle;" align="right">' + parseFloat(this.frm.doc.total_htva).toFixed(this.frm.doc.currency_digit_rounding) + '&nbsp;' + this.frm.doc.currency_id + '&nbsp;</td>';
		html += '</tr>';

		html += '<tr style="height:32px">';
		html += '<td width=200px style="vertical-align: middle;">' + __('Total base') + '</td>';
		html += '<td width=150px style="vertical-align: middle;" align="right">' + parseFloat(this.frm.doc.total_base).toFixed(this.frm.doc.currency_digit_rounding) + '&nbsp;' + this.frm.doc.currency_id + '&nbsp;</td>';
		html += '</tr>';

		html += '<tr style="height:32px">';
		html += '<td width=200px style="vertical-align: middle;">' + __('Total VAT') + '</td>';
		html += '<td width=150px style="vertical-align: middle;" align="right">' + parseFloat(this.frm.doc.total_vat).toFixed(this.frm.doc.currency_digit_rounding) + '&nbsp;' + this.frm.doc.currency_id + '&nbsp;</td>';
		html += '</tr>';

		html += '<tr style="height:32px">';
		html += '<td width=200px style="vertical-align: middle;">' + __('Total incl. VAT') + '</td>';
		html += '<td width=150px style="vertical-align: middle;" align="right"><b>' + parseFloat(this.frm.doc.total_tvac).toFixed(this.frm.doc.currency_digit_rounding) + '&nbsp;' + this.frm.doc.currency_id + '</b>&nbsp;</td>';
		html += '</tr>';

		if ((this.frm.doc.financial_discount_mode) && ((this.frm.doc.financial_discount_mode == 1) || ((this.frm.doc.financial_discount_mode == 2) && ((this.frm.doc.vat_type == 'N') || (this.frm.doc.vat_type == 'NAT')))))
		{
			if (((this.frm.doc.financial_discount) && (this.frm.doc.financial_discount != 0)) || ((this.frm.doc.fast_financial_discount) && (this.frm.doc.fast_financial_discount != 0)))
			{
				html += '<tr style="height:25px">';
				html += '<td colspan="2"><b><u>' + __('Financial discount') + '</u></b></td>';
				html += '</tr>';

				if ((this.frm.doc.financial_discount) && (this.frm.doc.financial_discount != 0)) {
					html += '<tr style="height:25px">';
					html += '<td width=200px style="vertical-align: middle;">' + __('Total incl. VAT') + '</td>';
					html += '<td width=150px style="vertical-align: middle;" align="right"><b>' + parseFloat(this.frm.doc.total_fin_discount).toFixed(this.frm.doc.currency_digit_rounding) + '&nbsp;' + this.frm.doc.currency_id + '</b>&nbsp;</td>';
					html += '</tr>';

					html += '<tr style="height:25px">';
					if ((this.frm.doc.financial_discount_pay_day) && (this.frm.doc.financial_discount_pay_day > 1))
					{	html += '<td colspan=2 style="vertical-align: middle;">' + __('if paid within the next ') + this.frm.doc.financial_discount_pay_day.toString() + '&nbsp;days</td>';
					}else if ((this.frm.doc.financial_discount_pay_day) && (this.frm.doc.financial_discount_pay_day == 1))
					{	html += '<td colspan=2 style="vertical-align: middle;">' + __('if paid with the next ') + this.frm.doc.financial_discount_pay_day.toString() + '&nbsp;day</td>';
					}else
					{	html += '<td colspan=2 style="vertical-align: middle;">' + __('if paid immediately') + '</td>';
					}
					html += '</tr>';
				}

				if ((this.frm.doc.fast_financial_discount) && (this.frm.doc.fast_financial_discount != 0)) {
					html += '<tr style="height:25px">';
					html += '<td width=200px style="vertical-align: middle;">' + __('Total incl. VAT') + '</td>';
					html += '<td width=150px style="vertical-align: middle;" align="right"><b>' + parseFloat(this.frm.doc.total_fast_fin_discount).toFixed(this.frm.doc.currency_digit_rounding) + '&nbsp;' + this.frm.doc.currency_id + '</b>&nbsp;</td>';
					html += '</tr>';

					html += '<tr style="height:25px">';
					if ((this.frm.doc.fast_financial_discount_pay_day) && (this.frm.doc.fast_financial_discount_pay_day > 1))
					{	html += '<td colspan=2 style="vertical-align: middle;">' + __('if paid within the next ') + this.frm.doc.fast_financial_discount_pay_day.toString() + '&nbsp;days</td>';
					}else if ((this.frm.doc.fast_financial_discount_pay_day) && (this.frm.doc.fast_financial_discount_pay_day == 1))
					{	html += '<td colspan=2 style="vertical-align: middle;">' + __('if paid with the next ') + this.frm.doc.fast_financial_discount_pay_day.toString() + '&nbsp;day</td>';
					}else
					{	html += '<td colspan=2 style="vertical-align: middle;">' + __('if paid immediately') + '</td>';
					}
					html += '</tr>';

				}
			}
		}

		html += '</table>';
		html += '</div>';

		this.frm.fields_dict['html_total'].$wrapper.empty();
		this.frm.fields_dict['html_total'].$wrapper.append(html);
	}



	// ***************************************************************************************************************************************
	// Refresh vat summary
	// ***************************************************************************************************************************************
	#refresh_vat_summary(frm)
	{
		let data_recap = [];
		let data_recap_rate = [];
		let data_recap_type = [];
		let data_recap_total_htva = [];
		let data_recap_total_base = [];
		let data_recap_total_tva = [];
		let data_recap_total_tvac = [];

		let vtype = '';
		let vrate = '0';

		if (this.frm.doc.line_detail)
		{
			for (var i = 0; i < this.frm.doc.line_detail.length; i++)
			{
				if ((!this.frm.doc.line_detail[i].vat_type) || ((this.frm.doc.line_detail[i].vat_type) && (this.frm.doc.line_detail[i].vat_type.trim() == '')))
				{	vtype = '';
				}else
				{	vtype = this.frm.doc.line_detail[i].vat_type.toUpperCase().trim();
				}

				if ((!this.frm.doc.line_detail[i].vat_rate) || ((this.frm.doc.line_detail[i].vat_rate) && (this.frm.doc.line_detail[i].vat_rate == 0)))
				{	vrate = '0';
				}else
				{	vrate = this.frm.doc.line_detail[i].vat_rate.toString().trim();
				}

				if ((vtype != '') || (vrate != ''))
				{
					str_vrate = vrate;

					cpt = 1;
					n = 15 - str_vrate.length;

					str_zero = '';

					while (cpt < n)
					{
						str_zero += '0';
						cpt++;
					}

					str_vrate = str_zero + str_vrate;



					if (data_recap.indexOf(vtype + "|" + str_vrate) == -1)
					{
						data_recap.push(vtype + "|" + str_vrate);
						data_recap_rate.push(parseFloat(0));
						data_recap_type.push('');
						data_recap_total_htva.push(parseFloat(0));
						data_recap_total_base.push(parseFloat(0));
						data_recap_total_tva.push(parseFloat(0));
						data_recap_total_tvac.push(parseFloat(0));
					}
				}
			}

			data_recap.sort();

			for(var k = 0; k < data_recap.length; k++)
			{
				let value = data_recap[k];

				let f = value.substring(0, value.indexOf('|'));

				let last = value.substring(value.indexOf('|')+1, value.length);

				let s = last;

				if (s.trim() == '')
				{	s = '0';
				}

				s = parseFloat(s).toString().trim();


				cpt = 1;
				n = 15 - s.length;


				str_zero = '';

				while (cpt < n)
				{
					str_zero += '0';
					cpt++;
				}

				s = str_zero + s;



				value = f + '|' + s;

				data_recap[k] = value;
			}

			for (var i = 0; i < this.frm.doc.line_detail.length; i++)
			{

				if ((!this.frm.doc.line_detail[i].vat_type) || ((this.frm.doc.line_detail[i].vat_type) && (this.frm.doc.line_detail[i].vat_type.trim() == '')))
				{	vtype = '';
				}else
				{	vtype = this.frm.doc.line_detail[i].vat_type.toUpperCase().trim();
				}

				if ((!this.frm.doc.line_detail[i].vat_rate) || ((this.frm.doc.line_detail[i].vat_rate) && (this.frm.doc.line_detail[i].vat_rate == 0)))
				{	vrate = '0';
				}else
				{	vrate = this.frm.doc.line_detail[i].vat_rate.toString().trim();
				}

				if ((vtype != '') || (vrate != ''))
				{

					str_vrate = vrate;

					cpt = 1;
					n = 15 - str_vrate.length;

					str_zero = '';

					while (cpt < n)
					{
						str_zero += '0';
						cpt++;
					}

					str_vrate = str_zero + str_vrate;

					idx = data_recap.indexOf(vtype + "|" + str_vrate);


					data_recap_total_htva[idx] = parseFloat(data_recap_total_htva[idx]) + parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency);

					if ((!this.frm.doc.financial_discount_mode) || ((this.frm.doc.financial_discount_mode) && ((this.frm.doc.financial_discount_mode == 0) || (this.frm.doc.financial_discount_mode == 1))))
					{	data_recap_total_base[idx] = parseFloat(data_recap_total_base[idx]) + parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency);
					}else
					{
						data_recap_total_base[idx] = parseFloat(data_recap_total_base[idx]) + (parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency) - (parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency) * parseFloat(this.frm.doc.financial_discount) / 100 ))
					}


					if ((this.frm.doc.line_detail[i].vat_rate) && ((this.frm.doc.line_detail[i].vat_rate.toString().trim() != '')))
					{	data_recap_rate[idx] = parseFloat(this.frm.doc.line_detail[i].vat_rate);
					}

					data_recap_type[idx] = this.frm.doc.line_detail[i].vat_type;


					if (this.frm.doc.line_detail[i].vat_type)
					{
						let rate = 0;

						if ((this.frm.doc.line_detail[i].vat_rate) && (this.frm.doc.line_detail[i].vat_rate != 0))
						{	rate = this.frm.doc.line_detail[i].vat_rate;
						}

						if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'N') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'NAT') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'X') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'XXX'))
						{	data_recap_total_tvac[idx] = parseFloat(data_recap_total_tvac[idx]) + parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency + (this.frm.doc.line_detail[i].value_line_doc_currency * ((rate / 100.000000) )));
						}else if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'E') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'INT'))
						{	data_recap_total_tvac[idx] = parseFloat(data_recap_total_tvac[idx]) + parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency);
						}else if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'I') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'EEC') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'C') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'S') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'NCO'))
						{	data_recap_total_tvac[idx] = parseFloat(data_recap_total_tvac[idx]) + parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency)
						}

						if ((!this.frm.doc.financial_discount_mode) || ((this.frm.doc.financial_discount_mode) && ((this.frm.doc.financial_discount_mode == 0) || (this.frm.doc.financial_discount_mode == 1))))
						{
							if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'N') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'NAT') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'X') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'XXX'))
							{	data_recap_total_tva[idx] = parseFloat(data_recap_total_tva[idx]) + (this.frm.doc.line_detail[i].value_line_doc_currency * ((rate / 100.000000)));
							}else if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'E') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'INT'))
							{	data_recap_total_tva[idx] = parseFloat(data_recap_total_tva[idx]) + 0;
							}else if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'I') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'EEC') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'C') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'S') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'NCO'))
							{	data_recap_total_tva[idx] = parseFloat(data_recap_total_tva[idx]) + 0;
							}
						}else{
							if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'N') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'NAT') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'X') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'XXX'))
							{	data_recap_total_tva[idx] = parseFloat(data_recap_total_tva[idx]) + (this.frm.doc.line_detail[i].value_line_doc_currency - (this.frm.doc.line_detail[i].value_line_doc_currency * parseFloat(this.frm.doc.financial_discount) / 100.00)) * ((rate / 100.000000));
							}else if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'E') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'INT'))
							{	data_recap_total_tva[idx] = parseFloat(data_recap_total_tva[idx]) + 0;
							}else if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'I') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'EEC') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'C') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'S') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'NCO'))
							{	data_recap_total_tva[idx] = parseFloat(data_recap_total_tva[idx]) + 0;
							}
						}
					}

				}
			}



			let exp = Math.pow(10, this.frm.doc.currency_digit_rounding);

			for(var k = 0; k < data_recap.length; k++)
			{
				data_recap_total_htva[k] = Math.round(parseFloat(data_recap_total_htva[k]) * exp) / exp;
				data_recap_total_base[k] = Math.round(parseFloat(data_recap_total_base[k]) * exp) / exp;
				data_recap_total_tva[k] = Math.round(parseFloat(data_recap_total_tva[k]) * exp) / exp;
				data_recap_total_tvac[k] = Math.round(parseFloat(data_recap_total_tvac[k]) * exp) / exp;

			}


			let total_htva = 0;
			let cur_val = 0;
			let cur_index = 0;

			for(var k = 0; k < data_recap.length; k++)
			{
				total_htva += parseFloat(data_recap_total_htva[k])

				if (parseFloat(data_recap_total_htva[k]) >= parseFloat(cur_val))
				{
					cur_val = parseFloat(data_recap_total_htva[k]);
					cur_index = k;
				}

			}

			total_htva = Math.round(parseFloat(total_htva) * exp) / exp;


			if (parseFloat(total_htva) != parseFloat(this.frm.doc.total_htva))
			{
				if (parseFloat(total_htva) > parseFloat(this.frm.doc.total_htva))
				{	data_recap_total_htva[cur_index] = parseFloat(data_recap_total_htva[cur_index]) - (parseFloat(total_htva) - parseFloat(this.frm.doc.total_htva));
				}else{
					data_recap_total_htva[cur_index] = parseFloat(data_recap_total_htva[cur_index]) + (parseFloat(this.frm.doc.total_htva) - parseFloat(total_htva));
				}
			}


			total_htva = 0;

			for(var k = 0; k < data_recap.length; k++)
			{
				total_htva += parseFloat(data_recap_total_htva[k])
			}


			total_htva = Math.round(parseFloat(total_htva) * exp) / exp;



			if (parseFloat(total_htva) == parseFloat(this.frm.doc.total_base))
			{
				for(var k = 0; k < data_recap.length; k++)
				{
					data_recap_total_base[k] = data_recap_total_htva[k];
				}

			}else{

				let total_base = 0;
				cur_val = 0;
				cur_index = -1;


				for(var k = 0; k < data_recap.length; k++)
				{
					total_base += parseFloat(data_recap_total_base[k])

					if (parseFloat(data_recap_total_base[k]) >= parseFloat(cur_val))
					{
						cur_val = parseFloat(data_recap_total_base[k]);
						cur_index = k;
					}

				}

				total_base = Math.round(parseFloat(total_base) * exp) / exp;


				if (parseFloat(total_base) != parseFloat(this.frm.doc.total_base))
				{
					if (parseFloat(total_base) > parseFloat(this.frm.doc.total_base))
					{	data_recap_total_base[cur_index] = parseFloat(data_recap_total_base[cur_index]) - (parseFloat(total_base) - parseFloat(this.frm.doc.total_base));
					}else{
						data_recap_total_base[cur_index] = parseFloat(data_recap_total_base[cur_index]) + (parseFloat(this.frm.doc.total_base) - parseFloat(total_base));
					}
				}
			}


			let total_tva_real = 0;
			cur_val = 0;
			cur_index = -1;

			for(var k = 0; k < data_recap.length; k++)
			{
				if (data_recap_type[k])
				{
					if ((data_recap_type[k].toUpperCase() == 'N') || (data_recap_type[k].toUpperCase() == 'NAT') || (data_recap_type[k].toUpperCase() == 'X') || (data_recap_type[k].toUpperCase() == 'XXX'))
					{
						total_tva_real += parseFloat(data_recap_total_tva[k])

						if (parseFloat(data_recap_total_tva[k]) >= parseFloat(cur_val))
						{
							cur_val = parseFloat(data_recap_total_tva[k]);
							cur_index = k;
						}

					}

				}
			}


			if (parseFloat(total_tva_real) != parseFloat(this.frm.doc.total_vat))
			{
				if (parseFloat(total_tva_real) > parseFloat(this.frm.doc.total_vat))
				{	data_recap_total_tva[cur_index] = parseFloat(data_recap_total_tva[cur_index]) - (parseFloat(total_tva_real) - parseFloat(this.frm.doc.total_vat));
				}else{
					data_recap_total_tva[cur_index] = parseFloat(data_recap_total_tva[cur_index]) + (parseFloat(this.frm.doc.total_vat) - parseFloat(total_tva_real));
				}

				data_recap_total_tvac[cur_index] = parseFloat(data_recap_total_htva[cur_index]) + parseFloat(data_recap_total_tva[cur_index]);
			}

			for(var k = 0; k < data_recap.length; k++)
			{
				data_recap_total_tvac[k] = (Math.round(parseFloat(data_recap_total_htva[k]) * exp) / exp) + (Math.round(parseFloat(data_recap_total_tva[k]) * exp) / exp);
			}
		}


		let html = '';

		html = '<div style="overflow:none; height:30px;">';

		html += '<table border=1 style="border: 1px solid #E8EAEB" width=950px data-custom-grid="true">';

		html += '<tr style="height:30px">';

		html += '<td width=125px style="vertical-align: middle;"><b>&nbsp;' + __("VAT type") + '</b></td>';
		html += '<td width=125px style="vertical-align: middle;"><b>&nbsp;' + __("VAT rate") + '</b></td>';
		html += '<td width=175px style="vertical-align: middle;"><b>&nbsp;' + __("Amount w/o VAT ( {0} )",this.frm.doc.currency_id) + '</b></td>';
		html += '<td width=175px style="vertical-align: middle;"><b>&nbsp;' + __("Base amount ( {0} )",this.frm.doc.currency_id) + '</b></td>';
		html += '<td width=175px style="vertical-align: middle;"><b>&nbsp;' + __("VAT amount ( {0} )",this.frm.doc.currency_id) + '</b></td>';
		html += '<td width=175px style="vertical-align: middle;"><b>&nbsp;' + __("Amount inc. VAT ( {0} )",this.frm.doc.currency_id) + '</b></td>';
		html += '</tr>';
		html += '</table>';
		html += '</div>';

		let data_in_grid = false;

		if (data_recap.length > 0)
		{
			for(var k = 0; k < data_recap.length; k++)
			{
				if ((data_recap_total_htva[k] != 0) || (data_recap_total_base[k] != 0) || (data_recap_total_tva[k] != 0) || (data_recap_total_tvac[k] != 0))
				{
					data_in_grid = true;
					break;
				}

			}
		}

		if (!data_in_grid)
		{
			html += '<div style="overflow:auto; height:180px;" width=950px>';

			html += '<table border=1 style="border: 1px solid #E8EAEB">';
			html += '<tr style="height:30px">';
			html += '<td width=125px style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=125px style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=175px style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=175px style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=175px style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=175px style="vertical-align: middle;">&nbsp;</td>';
			html += '</tr>';
			html += '</table>';
			html += '</div>';

		}else
		{
			html += '<div style="overflow:auto; height:180px;">';

			html += '<table border=1 style="border: 1px solid #E8EAEB" width=950px>';

			for(var k = 0; k < data_recap.length; k++)
			{


				html += '<tr style="height:30px">';
				html += '<td width=125px style="vertical-align: middle;">&nbsp;' + data_recap_type[k] + '</td>';
				html += '<td width=125px style="vertical-align: middle;" align="right">' + parseFloat(data_recap_rate[k]).toFixed(2) + '&nbsp;%&nbsp;</td>';
				html += '<td width=175px style="vertical-align: middle;" align="right">' + parseFloat(data_recap_total_htva[k]).toFixed(this.frm.doc.currency_digit_rounding) + '&nbsp;</td>';
				html += '<td width=175px style="vertical-align: middle;" align="right">' + parseFloat(data_recap_total_base[k]).toFixed(this.frm.doc.currency_digit_rounding) + '&nbsp;</td>';
				html += '<td width=175px style="vertical-align: middle;" align="right">' + parseFloat(data_recap_total_tva[k]).toFixed(this.frm.doc.currency_digit_rounding) + '&nbsp;</td>';
				html += '<td width=175px style="vertical-align: middle;" align="right">' + parseFloat(data_recap_total_tvac[k]).toFixed(this.frm.doc.currency_digit_rounding) + '&nbsp;</td>';
				html += '</tr>';
			}

			html += '<tr style="height:30px" data-custom-grid-bottom="true">';

			html += '<td width=125px style="vertical-align: middle;">&nbsp;<b>' + __('Total') + '</b></td>';
			html += '<td width=125px style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=175px style="vertical-align: middle;" align="right"><b>' + parseFloat(this.frm.doc.total_htva).toFixed(this.frm.doc.currency_digit_rounding) + '</b>&nbsp;</td>';
			html += '<td width=175px style="vertical-align: middle;" align="right"><b>' + parseFloat(this.frm.doc.total_base).toFixed(this.frm.doc.currency_digit_rounding) + '</b>&nbsp;</td>';
			html += '<td width=175px style="vertical-align: middle;" align="right"><b>' + parseFloat(this.frm.doc.total_vat).toFixed(this.frm.doc.currency_digit_rounding)  + '</b>&nbsp;</td>';
			html += '<td width=175px style="vertical-align: middle;" align="right"><b>' + parseFloat(this.frm.doc.total_tvac).toFixed(this.frm.doc.currency_digit_rounding)  + '</b>&nbsp;</td>';
			html += '</tr>';

			html += '</table>';
			html += '</div>';
		}

		this.frm.fields_dict['html_vat_summary'].$wrapper.empty();
		this.frm.fields_dict['html_vat_summary'].$wrapper.append(html);
	}

	// ***************************************************************************************************************************************
	// Set field's labels with (!) when error found
	// ***************************************************************************************************************************************
	set_label_when_error()
	{
		this.#disable_log_grid_actions();

		this.frm.refresh_field('log_detail');
		this.frm.refresh_field('order_customer_id');
		this.frm.refresh_field('order_customer_contact_id');
		this.frm.refresh_field('delivery_customer_id');
		this.frm.refresh_field('delivery_customer_contact_id');
		this.frm.refresh_field('invoice_customer_id');
		this.frm.refresh_field('invoice_customer_contact_id');
		this.frm.refresh_field('sales_catalog_id');
		this.frm.refresh_field('currency_id');


		this.frm.fields_dict['order_customer_id'].label_area.innerHTML = __(this.frm.fields_dict['order_customer_id']._label);
		this.frm.fields_dict['order_customer_contact_id'].label_area.innerHTML = __(this.frm.fields_dict['order_customer_contact_id']._label);
		this.frm.fields_dict['delivery_customer_id'].label_area.innerHTML = __(this.frm.fields_dict['delivery_customer_id']._label);
		this.frm.fields_dict['delivery_customer_contact_id'].label_area.innerHTML = __(this.frm.fields_dict['delivery_customer_contact_id']._label);
		this.frm.fields_dict['invoice_customer_id'].label_area.innerHTML = __(this.frm.fields_dict['invoice_customer_id']._label);
		this.frm.fields_dict['sales_catalog_id'].label_area.innerHTML = __(this.frm.fields_dict['sales_catalog_id']._label);
		this.frm.fields_dict['currency_id'].label_area.innerHTML = __(this.frm.fields_dict['currency_id']._label);

		if (this.frm.doc.log_detail)
		{
			for (var i = 0; i < this.frm.doc.log_detail.length; i++)
			{
				if ((this.frm.doc.log_detail[i].fieldname.toUpperCase() != 'ITEM_SALES_CONDITION_ID') &&
					(this.frm.doc.log_detail[i].fieldname.toUpperCase() != 'ITEM_ITEM_ID'))
				{
					this.frm.fields_dict[this.frm.doc.log_detail[i].fieldname].label_area.innerHTML = __(this.frm.fields_dict[this.frm.doc.log_detail[i].fieldname]._label) + '&nbsp;<font color = "#FF5338"><b>(!)</b></font>';
				}
			}

			if (this.frm.doc.log_detail.length > 0)
			{
				for (var i = 0; i < this.frm.layout.tabs.length; i++)
				{
					if (this.frm.layout.tabs[i].df.fieldname.toUpperCase() == 'SALES_QUOTE_TAB_WARNINGS')
					{
						document.getElementById('ioi-sales-quote-sales_quote_tab_warnings-tab').innerHTML = '<font color = "#FF5959"><b>' + __(this.frm.layout.tabs[i].label) + ' (!)</b><font>'; // #FF5338
						document.getElementById('ioi-sales-quote-sales_quote_tab_warnings-tab').hidden = false;

					}else if (this.frm.layout.tabs[i].df.fieldname.toUpperCase() == 'SALES_ORDER_TAB_WARNINGS')
					{
						document.getElementById('ioi-sales-order-sales_order_tab_warnings-tab').innerHTML = '<font color = "#FF5959"><b>' + __(this.frm.layout.tabs[i].label) + ' (!)</b><font>'; // #FF5338
						document.getElementById('ioi-sales-order-sales_order_tab_warnings-tab').hidden = false;

					}else if (this.frm.layout.tabs[i].df.fieldname.toUpperCase() == 'SALES_DELIVERY_TAB_WARNINGS')
					{
						document.getElementById('ioi-sales-delivery-sales_delivery_tab_warnings-tab').innerHTML = '<font color = "#FF5959"><b>' + __(this.frm.layout.tabs[i].label) + ' (!)</b><font>'; // #FF5338
						document.getElementById('ioi-sales-delivery-sales_delivery_tab_warnings-tab').hidden = false;

					}else if (this.frm.layout.tabs[i].df.fieldname.toUpperCase() == 'SALES_INVOICE_TAB_WARNINGS')
					{
						document.getElementById('ioi-sales-invoice-sales_invoice_tab_warnings-tab').innerHTML = '<font color = "#FF5959"><b>' + __(this.frm.layout.tabs[i].label) + ' (!)</b><font>'; // #FF5338
						document.getElementById('ioi-sales-invoice-sales_invoice_tab_warnings-tab').hidden = false;

					}
				}
			}else
			{
				for (var i = 0; i < this.frm.layout.tabs.length; i++)
				{
					if (this.frm.layout.tabs[i].df.fieldname.toUpperCase() == 'SALES_QUOTE_TAB_WARNINGS')
					{
						document.getElementById('ioi-sales-quote-sales_quote_tab_warnings-tab').innerHTML = __(this.frm.layout.tabs[i].label);
						document.getElementById('ioi-sales-quote-sales_quote_tab_warnings-tab').hidden = true;
					}else if (this.frm.layout.tabs[i].df.fieldname.toUpperCase() == 'SALES_ORDER_TAB_WARNINGS')
					{
						document.getElementById('ioi-sales-order-sales_order_tab_warnings-tab').innerHTML = __(this.frm.layout.tabs[i].label);
						document.getElementById('ioi-sales-order-sales_order_tab_warnings-tab').hidden = true;
					}else if (this.frm.layout.tabs[i].df.fieldname.toUpperCase() == 'SALES_DELIVERY_TAB_WARNINGS')
					{
						document.getElementById('ioi-sales-delivery-sales_delivery_tab_warnings-tab').innerHTML = __(this.frm.layout.tabs[i].label);
						document.getElementById('ioi-sales-delivery-sales_delivery_tab_warnings-tab').hidden = true;
					}else if (this.frm.layout.tabs[i].df.fieldname.toUpperCase() == 'SALES_INVOICE_TAB_WARNINGS')
					{
						document.getElementById('ioi-sales-invoice-sales_invoice_tab_warnings-tab').innerHTML = __(this.frm.layout.tabs[i].label);
						document.getElementById('ioi-sales-invoice-sales_invoice_tab_warnings-tab').hidden = true;
					}
				}

			}

		}else
		{
			for (var i = 0; i < this.frm.layout.tabs.length; i++)
			{
				if (this.frm.layout.tabs[i].df.fieldname.toUpperCase() == 'SALES_QUOTE_TAB_WARNINGS')
				{
					document.getElementById('ioi-sales-quote-sales_quote_tab_warnings-tab').innerHTML = __(this.frm.layout.tabs[i].label);
					document.getElementById('ioi-sales-quote-sales_quote_tab_warnings-tab').hidden = true;
				}else if (this.frm.layout.tabs[i].df.fieldname.toUpperCase() == 'SALES_ORDER_TAB_WARNINGS')
				{
					document.getElementById('ioi-sales-order-sales_order_tab_warnings-tab').innerHTML = __(this.frm.layout.tabs[i].label);
					document.getElementById('ioi-sales-order-sales_order_tab_warnings-tab').hidden = true;
				}else if (this.frm.layout.tabs[i].df.fieldname.toUpperCase() == 'SALES_DELIVERY_TAB_WARNINGS')
				{
					document.getElementById('ioi-sales-delivery-sales_delivery_tab_warnings-tab').innerHTML = __(this.frm.layout.tabs[i].label);
					document.getElementById('ioi-sales-delivery-sales_delivery_tab_warnings-tab').hidden = true;
				}else if (this.frm.layout.tabs[i].df.fieldname.toUpperCase() == 'SALES_INVOICE_TAB_WARNINGS')
				{
					document.getElementById('ioi-sales-invoice-sales_invoice_tab_warnings-tab').innerHTML = __(this.frm.layout.tabs[i].label);
					document.getElementById('ioi-sales-invoice-sales_invoice_tab_warnings-tab').hidden = true;
				}
			}
		}

		this.frm.refresh_field('order_customer_id');
		this.frm.refresh_field('order_customer_contact_id');
		this.frm.refresh_field('delivery_customer_id');
		this.frm.refresh_field('delivery_customer_contact_id');
		this.frm.refresh_field('invoice_customer_id');
		this.frm.refresh_field('invoice_customer_contact_id');
		this.frm.refresh_field('sales_catalog_id');
		this.frm.refresh_field('currency_id');

		silicon_ioi.doctype.ioiSalesDocumentDocType.set_static_grid_layout(null);

	}

	// ***************************************************************************************************************************************
	// Log grid : disable all actions
	// ***************************************************************************************************************************************
	#disable_log_grid_actions()
	{
		for (var i = 0; i < this.frm.fields_dict['log_detail'].grid.grid_buttons.length; i++)
		{
			this.frm.fields_dict['log_detail'].grid.grid_buttons[i].hidden = true;
		}

		for (var i = 0; i < this.frm.fields_dict['log_detail'].grid.grid_rows.length; i++)
		{
			// this.frm.fields_dict['log_detail'].grid.grid_rows[i].row_check[0].hidden = true;
			this.frm.fields_dict['log_detail'].grid.grid_rows[i].open_form_button[0].hidden = true;
		}

		// this.frm.fields_dict['log_detail'].grid.header_row.row[0].children[0].hidden = true
		// this.frm.fields_dict['log_detail'].grid.header_row.row_check[0].hidden = true;
	}

	check_financial_discount_mode()
	{
		if ((this.frm.doc.financial_discount_mode) && (this.frm.doc.financial_discount_mode == 2)) {

			let vat_reduced_disabled = 0;

			let method = this.path_division + '.ioi_division_is_financial_discount_vat_reduced_disabled';

			frappe.call({  	method: method,
							args: {"division_id" : this.frm.doc.division_id},
							async: false,
							callback:function(r)	{
								vat_reduced_disabled = r.message;
							}
			});

			if (vat_reduced_disabled == 1) {
				this.frm.set_value('financial_discount_mode', 1);
			}
		}
	}

	set_grid_layout() {
		silicon_ioi.doctype.ioiSalesDocumentDocType.set_static_grid_layout(null);
	}

	// ***************************************************************************************************************************************
	// Set static Grid layout : used outside de class without instanciate an object
	// ***************************************************************************************************************************************


	static set_static_grid_layout(dm = null)
	{

		if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') ||
			(cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE')) {

			if (dm == null)
			{
				dm = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;
			}

			if (cur_frm.doc.line_detail)
			{

				for(var i = 0; i < cur_frm.doc.line_detail.length; i++)
				{
					let bgcolor = '';

					for (var k = 0; k < document.getElementById("ioistatus_id").length; k++)
					{
						if (cur_frm.doc.line_detail[i].ioistatus == document.getElementById("ioistatus_id").options[k].value)
						{
							bgcolor = document.getElementById("ioistatus_color").options[k].value;
							break;
						}
					}


					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i])
					{
						cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[1].style.backgroundColor = bgcolor;
						cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[1].style.color = "black";

						for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++)
						{
							if (dm == 0) {
								cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FFFFFF';
							}else{
								cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#1C2126';
							}
						}


						for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++)
						{
							if (cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'INVOICING_MODE') {

								let fn = cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname;

								let gridelement = cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].children[1];

								if (gridelement) {

									let invoicing_mode = gridelement.innerText.trim()

									if ((!invoicing_mode) || ((invoicing_mode) && (invoicing_mode == 0))) {
										gridelement.innerText = __('Default');
									}else if (invoicing_mode == 1) {
										gridelement.innerText = __('Up to ordered');
									}else if (invoicing_mode == 2) {
										gridelement.innerText = __('Fix ordered');
									}else if (invoicing_mode == 3) {
										gridelement.innerText = __('Open order');
									}
								}
							}
						}



						if (cur_frm.doc.line_detail[i].is_title)
						{
							cur_frm.get_field("line_detail").grid.grid_rows[i].wrapper[0].children[0].children[4].children[1].innerHTML = '<b>' + cur_frm.doc.line_detail[i].item_description + '</b>';
						}


						if (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
						{
							for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++)
							{


								if (dm == 0) {
									cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FFFFFF';
								}else{
									cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#1C2126';
								}

							}

						}



						if ((cur_frm.doc.line_detail[i].unit_price_source) && (cur_frm.doc.line_detail[i].unit_price_source.trim() != ''))
						{
							for (var k = 0; k < document.getElementById("select_source_id").length; k++)
							{
								if (document.getElementById("select_source_id").options[k].value == cur_frm.doc.line_detail[i].unit_price_source.toUpperCase())
								{

									for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++)
									{
										if (cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'UNIT_PRICE')
										{
											cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = document.getElementById("select_source_color").options[k].value;
											cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";

										}

									}
									break;

								}
							}
						}

						if ((cur_frm.doc.line_detail[i].base_discount_source) && (cur_frm.doc.line_detail[i].base_discount_source.trim() != ''))
						{
							for (var k = 0; k < document.getElementById("select_source_id").length; k++)
							{
								if (document.getElementById("select_source_id").options[k].value == cur_frm.doc.line_detail[i].base_discount_source.toUpperCase())
								{
									for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++)
									{
										if (cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'BASE_DISCOUNT')
										{
											cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = document.getElementById("select_source_color").options[k].value;
											cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";

											if ((cur_frm.doc.line_detail[i].alert_margin_rate) && (cur_frm.doc.line_detail[i].alert_margin_rate != 0)) {

												if ((cur_frm.doc.line_detail[i].margin_alert) && (cur_frm.doc.line_detail[i].margin_alert != 0)) {

													if (cur_frm.doc.line_detail[i].margin_alert == -1) {
														cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = 'orange';
														cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";

													}else{
														cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = 'red';
														cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";
													}
												}
											}

										}

									}
									break;

								}
							}
						}else{
							for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++)
							{
								if (cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'BASE_DISCOUNT')
								{
									if ((cur_frm.doc.line_detail[i].alert_margin_rate) && (cur_frm.doc.line_detail[i].alert_margin_rate != 0)) {

										if ((cur_frm.doc.line_detail[i].margin_alert) && (cur_frm.doc.line_detail[i].margin_alert != 0)) {

											if (cur_frm.doc.line_detail[i].margin_alert == -1) {
												cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = 'orange';
												cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";

											}else{
												cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = 'red';
												cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";
											}

										}
									}
								}

							}

						}

						if ((cur_frm.doc.line_detail[i].extra_discount_source) && (cur_frm.doc.line_detail[i].extra_discount_source.trim() != ''))
						{
							for (var k = 0; k < document.getElementById("select_source_id").length; k++)
							{
								if (document.getElementById("select_source_id").options[k].value == cur_frm.doc.line_detail[i].extra_discount_source.toUpperCase())
								{
									for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++)
									{
										if (cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'EXTRA_DISCOUNT')
										{
											cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = document.getElementById("select_source_color").options[k].value;
											cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";

											if ((cur_frm.doc.line_detail[i].alert_margin_rate) && (cur_frm.doc.line_detail[i].alert_margin_rate != 0)) {

												if ((cur_frm.doc.line_detail[i].margin_alert) && (cur_frm.doc.line_detail[i].margin_alert != 0)) {

													if (cur_frm.doc.line_detail[i].margin_alert == -1) {
														cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = 'orange';
														cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";

													}else{
														cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = 'red';
														cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";
													}
												}
											}

										}

									}
									break;
								}
							}
						}else{
							for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++)
							{
								if (cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'EXTRA_DISCOUNT')
								{
									if ((cur_frm.doc.line_detail[i].alert_margin_rate) && (cur_frm.doc.line_detail[i].alert_margin_rate != 0)) {

										if ((cur_frm.doc.line_detail[i].margin_alert) && (cur_frm.doc.line_detail[i].margin_alert != 0)) {

											if (cur_frm.doc.line_detail[i].margin_alert == -1) {
												cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = 'orange';
												cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";

											}else{
												cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = 'red';
												cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";
											}
										}
									}
								}

							}

						}



						if ((cur_frm.doc.line_detail[i].free_part_qty_source) && (cur_frm.doc.line_detail[i].free_part_qty_source.trim() != ''))
						{
							for (var k = 0; k < document.getElementById("select_source_id").length; k++)
							{
								if (document.getElementById("select_source_id").options[k].value == cur_frm.doc.line_detail[i].free_part_qty_source.toUpperCase())
								{
									for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++)
									{
										if (cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'FREE_PART_QTY')
										{
											cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = document.getElementById("select_source_color").options[k].value;
											cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";

											if ((cur_frm.doc.line_detail[i].alert_margin_rate) && (cur_frm.doc.line_detail[i].alert_margin_rate != 0)) {

												if ((cur_frm.doc.line_detail[i].margin_alert) && (cur_frm.doc.line_detail[i].margin_alert != 0)) {

													if (cur_frm.doc.line_detail[i].margin_alert == -1) {
														cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = 'orange';
														cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";

													}else{
														cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = 'red';
														cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";
													}
												}
											}

										}

									}
									break;
								}
							}
						}else{
							for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++)
							{
								if (cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'FREE_PART_QTY')
								{
									if ((cur_frm.doc.line_detail[i].alert_margin_rate) && (cur_frm.doc.line_detail[i].alert_margin_rate != 0)) {

										if ((cur_frm.doc.line_detail[i].margin_alert) && (cur_frm.doc.line_detail[i].margin_alert != 0)) {

											if (cur_frm.doc.line_detail[i].margin_alert == -1) {
												cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = 'orange';
												cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";

											}else{
												cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = 'red';
												cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";
											}
										}
									}
								}

							}

						}

						if (cur_frm.doc.line_detail[i].ftg_priority == -1)  {

							for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++) {

								if ((cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'UNIT_PRICE') ||
									(cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'BASE_DISCOUNT') ||
									(cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'EXTRA_DISCOUNT')) {

									cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#ffe499';
									cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";
								}
							}
						}


						if (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')	{

							if (cur_frm.doc.qty_to_prepare_mode == 1) {

								if (cur_frm.doc.line_detail[i].delivered_qty != cur_frm.doc.line_detail[i].qty_to_prepare) {

									for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++) {

										if (cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'DELIVERED_QTY') {

											if (cur_frm.doc.line_detail[i].qty_to_prepare != 0) {

												if (cur_frm.doc.line_detail[i].delivered_qty < cur_frm.doc.line_detail[i].qty_to_prepare) {
													cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FBCEB1';
												}else if (cur_frm.doc.line_detail[i].delivered_qty > cur_frm.doc.line_detail[i].qty_to_prepare) {
													cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FCA3A3';
												}

												cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";
											}
										}
									}
								}
							}

							if ((cur_frm.doc.line_detail[i].item_id) && (cur_frm.doc.line_detail[i].item_id.trim() != '')) {

								let qt = 0;

								if ((cur_frm.doc.line_detail[i].delivered_qty != null) && (cur_frm.doc.line_detail[i].delivered_qty != 0)) {
									qt = cur_frm.doc.line_detail[i].delivered_qty;
								}

								if (qt != 0) {

									let color_cell = false;

									if ((!cur_frm.doc.line_detail[i].warehouse_id) || ((cur_frm.doc.line_detail[i].warehouse_id) && (cur_frm.doc.line_detail[i].warehouse_id.trim() == ''))) {
										color_cell = true;
									}

									if ((!color_cell) && (cur_frm.doc.line_detail[i].location_active == 1) && ((!cur_frm.doc.line_detail[i].warehouse_location_id) || ((cur_frm.doc.line_detail[i].warehouse_location_id) && (cur_frm.doc.line_detail[i].warehouse_location_id.trim() == '')))) {
										color_cell = true;
									}

									if ((!color_cell) && ((cur_frm.doc.line_detail[i].item_mode == 3) || (cur_frm.doc.line_detail[i].item_mode == 4)) && ((!cur_frm.doc.line_detail[i].batch_sn_id) || ((cur_frm.doc.line_detail[i].batch_sn_id) && (cur_frm.doc.line_detail[i].batch_sn_id.trim() == '')))) {
										color_cell = true;
									}



									if (color_cell) {

										for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++) {

											if (cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'DELIVERED_QTY') {

												cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FFE7AB';
												cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";
											}
										}
									}
								}
							}


						}

						if (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER')	{

							for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++) {

								if ((cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'AVAILABLE_QTY') ||
									(cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'AVAILABLE_SCHEDULED_QTY')) {

									if (cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'AVAILABLE_QTY') {
										if ((!cur_frm.doc.line_detail[i].available_status) || ((cur_frm.doc.line_detail[i].available_status) && (cur_frm.doc.line_detail[i].available_status == 0))) {
											cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#DCDCDC';
										}else if (cur_frm.doc.line_detail[i].available_status == 1) {
											cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FA6E6B';
										}else if (cur_frm.doc.line_detail[i].available_status == 2) {
											cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#759CFF';
										}else if (cur_frm.doc.line_detail[i].available_status == 3) {
											cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#5DE861';
										}
									}else{

										if ((!cur_frm.doc.line_detail[i].available_scheduled_status) || ((cur_frm.doc.line_detail[i].available_scheduled_status) && (cur_frm.doc.line_detail[i].available_scheduled_status == 0))) {
											cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#DCDCDC';
										}else if (cur_frm.doc.line_detail[i].available_scheduled_status == 1) {
											cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FA6E6B';
										}else if (cur_frm.doc.line_detail[i].available_scheduled_status == 2) {
											cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#759CFF';
										}else if (cur_frm.doc.line_detail[i].available_scheduled_status == 3) {
											cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#5DE861';
										}
									}

									cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";
								}
							}

						}


						for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++)
						{
							if ((cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'QUOTED_QTY') ||
								(cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'ORDERED_QTY') ||
								(cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'DELIVERED_QTY') ||
								(cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'DELIVERED_QTY') ||
								(cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'INVOICED_QTY') ||
								(cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'FREE_PART_QTY') ||
								(cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'MIN_ORDER_QTY') ||
								(cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'MULTIPLE_ORDER_QTY') ||
								(cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'FREE_PART_NUM') ||
								(cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'FREE_PART_DEN') ||
								(cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'RETURNED_QTY') ||
								(cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'RESERVED_QTY') ||
								(cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'QTY_TO_PREPARE') ||
								(cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'AVAILABLE_QTY') ||
								(cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'AVAILABLE_SCHEDULED_QTY'))
							{
								let fn = cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname;

								let gridelement = cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].children[1].children[0];

								let gridcell = null;

								if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[fn][0].children[0]) {


									if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[fn][0].children[0].children[0]) {

										if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[fn][0].children[0].children[0].children[0]) {
											gridcell = cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[fn][0].children[0].children[0].children[0];
										}
									}
								}

								silicon_ioi.doctype.ioiSalesDocumentDocType.grid_format_float_qty(gridelement, gridcell);

							}

						}

						if ((cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE'))
						{
							if ((cur_frm.doc.line_detail[i].is_warning) && (cur_frm.doc.line_detail[i].is_warning != 0))
							{
								for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++)
								{
									if (cur_frm.doc.line_detail[i].is_warning == 1)
									{	cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FCA874';
									}else
									{	cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FEADAD';
									}

									cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";
								}
							}
						}

						if ((!cur_frm.doc.ioistatus) || ((cur_frm.doc.ioistatus) && (cur_frm.doc.ioistatus == 0))) {

							let unit_price_read_only = 0;

							if ((cur_frm.doc.vat_included_control) && (cur_frm.doc.vat_included_control == 1)) {
								unit_price_read_only = 1;
							}else{
								if ((cur_frm.doc.line_detail[i].vat_included_in_price) && (cur_frm.doc.line_detail[i].vat_included_in_price == 1)) {
									unit_price_read_only = 1;
								}
							}

							let item_description_readonly = false;

							const grid_row = cur_frm.fields_dict["line_detail"].grid.grid_rows_by_docname[cur_frm.doc.line_detail[i].name];

							if (grid_row)
							{
								const unit_price = grid_row.docfields.filter(f => f.fieldname == "unit_price")[0];
								const unit_price_tvac = grid_row.docfields.filter(f => f.fieldname == "unit_price_tvac")[0];
								const item_description = grid_row.docfields.filter(f => f.fieldname == "item_description")[0];

								if (unit_price_read_only == 0) {
									unit_price.read_only = false;
									unit_price_tvac.read_only = true;
								}else{
									unit_price.read_only = true;
									unit_price_tvac.read_only = false;
								}

								if (((cur_frm.doc.line_detail[i].item_id) && (cur_frm.doc.line_detail[i].item_id.trim() != '')) || ((cur_frm.doc.line_detail[i].manufacturer_ref) && (cur_frm.doc.line_detail[i].manufacturer_ref.trim() != '')))
								{	item_description.read_only = true;
									item_description_readonly = true;
								}else
								{	item_description.read_only = false;
									item_description_readonly = false;
								}


							}

							for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++) {

								if (cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'ITEM_DESCRIPTION') {

									if (!item_description_readonly) {
										if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].getElementsByClassName('input-with-feedback form-control input-sm')[0]) {
											cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].getElementsByClassName('input-with-feedback form-control input-sm')[0].disabled = false;
										}
									}else{
										if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].getElementsByClassName('input-with-feedback form-control input-sm')[0]) {
											cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].getElementsByClassName('input-with-feedback form-control input-sm')[0].disabled = true;
										}
									}
								}

								if (cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'UNIT_PRICE') {

									if (unit_price_read_only == 0) {
										if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].getElementsByClassName('input-with-feedback form-control input-sm')[0]) {
											cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].getElementsByClassName('input-with-feedback form-control input-sm')[0].disabled = false;
										}
									}else{
										if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].getElementsByClassName('input-with-feedback form-control input-sm')[0]) {
											cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].getElementsByClassName('input-with-feedback form-control input-sm')[0].disabled = true;
										}
									}
								}

								if (cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'UNIT_PRICE_TVAC') {

									if (unit_price_read_only == 0) {
										if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].getElementsByClassName('input-with-feedback form-control input-sm')[0]) {
											cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].getElementsByClassName('input-with-feedback form-control input-sm')[0].disabled = true;
										}
									}else{
										if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].getElementsByClassName('input-with-feedback form-control input-sm')[0]) {
											cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].getElementsByClassName('input-with-feedback form-control input-sm')[0].disabled = false;
										}
									}
								}

							}

						}
					}
				}
			}

			let fct_grid = function () { silicon_ioi.doctype.ioiSalesDocumentDocType.set_static_grid_layout(dm); };
			setTimeout(fct_grid, 500);
		}

	}

	static set_vt_button_in_grid()
	{
		if (cur_frm.fields_dict["line_detail"].grid) {
			if (cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0]) {

				let from_idx = (parseInt(cur_frm.fields_dict['line_detail'].grid.grid_pagination.page_index) * parseInt(cur_frm.fields_dict['line_detail'].grid.grid_pagination.page_length)) - (parseInt(cur_frm.fields_dict['line_detail'].grid.grid_pagination.page_length))

				for (var i = 0; i < cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].childElementCount; i++) {

					if (document.getElementById('vt_button_' + (i + from_idx).toString())) {
						document.getElementById('vt_button_' + (i + from_idx).toString()).remove();
					}

					let item_id = '';

					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i + from_idx].doc.item_id) {
						item_id = cur_frm.fields_dict['line_detail'].grid.grid_rows[i + from_idx].doc.item_id;
					}

					let to_execute = 0;

					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i + from_idx].doc.to_execute) {
						to_execute = cur_frm.fields_dict['line_detail'].grid.grid_rows[i + from_idx].doc.to_execute;
					}

					if ((item_id != '') && (to_execute == 1)) {
						// Add button VT
						let html = '';
						html += '<button id="vt_button_' + (i + from_idx).toString() + '" title="' + __("Time Vector") + '" style="width:30px; height:25px; border: none;">...</button>';
						cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].insertAdjacentHTML('afterbegin', html);
/*
						// Remove CheckBox
						if (cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].children[1]) {
							cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].children[1].remove();
						}
							*/
					}else {
						/*
						// Remove CheckBox
						if (cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].children[0]) {
							cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].children[0].remove();
						}
							*/
					}
				}


				silicon_ioi.doctype.ioiSalesDocumentDocType.sleep_static(200).then(() => {

					let fct_click = function() {
						let s = this.id;

						while (s.indexOf('_') != -1) {
							s = s.substring(s.indexOf('_')+1, s.length)
						}

						s = s.trim();

						let item_id = '';

						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[s].doc.item_id) {
							item_id = cur_frm.fields_dict['line_detail'].grid.grid_rows[s].doc.item_id;
						}

						let to_execute = 0;

						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[s].doc.to_execute) {
							to_execute = cur_frm.fields_dict['line_detail'].grid.grid_rows[s].doc.to_execute;
						}

						document.getElementById('sb_vt_data_header_title').innerHTML = '<b>' + __("Time Vector") + '</b> ' + item_id;

						silicon_ioi.doctype.ioiTimeVectorFrame.item_id = item_id;
						silicon_ioi.doctype.ioiTimeVectorFrame.doctype = '';
						silicon_ioi.doctype.ioiTimeVectorFrame.origin = 'IOI SALES ORDER';
						silicon_ioi.doctype.ioiTimeVectorFrame.frame_height = 520;
						silicon_ioi.doctype.ioiTimeVectorFrame.pk_reference = cur_frm.doc.name + ' | ' + (parseInt(s)+1).toString();

						silicon_ioi.doctype.ioiTimeVectorFrame.time_vector_refresh();

						if (item_id != '') {
							if (to_execute == 1) {
								document.getElementById('sb_vt_data').style.display = 'block';
							}else{
								document.getElementById('sb_vt_data').style.display = 'none';
							}
						}else{
							document.getElementById('sb_vt_data').style.display = 'none';
						}

						cur_frm.fields_dict['line_detail'].grid.wrapper[0].getElementsByClassName('rows')[0].children[s].children[0].click();
					}

					from_idx = (parseInt(cur_frm.fields_dict['line_detail'].grid.grid_pagination.page_index) * parseInt(cur_frm.fields_dict['line_detail'].grid.grid_pagination.page_length)) - (parseInt(cur_frm.fields_dict['line_detail'].grid.grid_pagination.page_length))

					for (var i = 0; i < cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].childElementCount; i++) {

						if (document.getElementById('vt_button_' + (i + from_idx).toString())) {
							document.getElementById('vt_button_' + (i + from_idx).toString()).onclick = fct_click;
						}
					}
				});
			}
		}
	}


	static build_vt_section()
	{
		if (cur_frm.doc.ioistatus != 0) {

			if (cur_frm.fields_dict["line_detail"]) {

				if (cur_frm.fields_dict["line_detail"].grid) {

					cur_frm.fields_dict["line_detail"].grid.wrapper[0].onchange = silicon_ioi.doctype.ioiSalesDocumentDocType.set_vt_button_in_grid;

					if (document.getElementById('sb_vt_start_move')) {
						document.getElementById('sb_vt_start_move').remove();
					}


					if (document.getElementById('sb_vt_data')) {
						document.getElementById('sb_vt_data').remove();
					}

					if (document.getElementById('sb_vt_data_header')) {
						document.getElementById('sb_vt_data_header').remove();
					}

					if (document.getElementById('sb_vt_data_header_title')) {
						document.getElementById('sb_vt_data_header_title').remove();
					}

					if (document.getElementById('sb_vt_data_header_updown_section')) {
						document.getElementById('sb_vt_data_header_updown_section').remove();
					}

					if (document.getElementById('sb_vt_data_updown')) {
						document.getElementById('sb_vt_data_updown').remove();
					}



					if (document.getElementById('sb_vt_data_header_close_section')) {
						document.getElementById('sb_vt_data_header_close_section').remove();
					}

					if (document.getElementById('sb_vt_data_close')) {
						document.getElementById('sb_vt_data_close').remove();
					}

					if (document.getElementById('sb_vt_data_separator')) {
						document.getElementById('sb_vt_data_separator').remove();
					}


					if (document.getElementById('sb_vt_data_margin')) {
						document.getElementById('sb_vt_data_margin').remove();
					}

					if (document.getElementById('sb_vt_data_content')) {
						document.getElementById('sb_vt_data_content').remove();
					}

					silicon_ioi.doctype.ioiSalesDocumentDocType.vt_data_up = true;



					let content_bgcolor = "#FFFFFF";

					if (silicon_ioi.doctype.ioiSalesDocumentDocType.static_is_dark_mode != 0) {
						content_bgcolor = "#1C2126";
					}

					let title_font_color = '#000000';

					let div_height = 560;
					let content_height = 520;

					if (!silicon_ioi.doctype.ioiSalesDocumentDocType.vt_data_up) {
						div_height = 30;
					}

					if (document.body.clientHeight <= div_height)
					{
						div_height = document.body.clientHeight - 100;
						content_height = div_height - 38;
					}

					let fct_window_resize = function() {

						let div_height = 560;
						let content_height = 520;

						if (!silicon_ioi.doctype.ioiSalesDocumentDocType.vt_data_up) {
							div_height = 30;
						}

						if (document.body.clientHeight <= div_height)
						{
							div_height = document.body.clientHeight - 100;
							content_height = div_height - 38;
						}

						if (document.getElementById('sb_vt_data')) {
							document.getElementById('sb_vt_data').style.height = div_height.toString() + 'px';
							document.getElementById('sb_vt_data_detail').style.height = content_height.toString() + 'px';

							document.getElementById('sb_vt_data').style.top = document.body.clientHeight - div_height + window.scrollY + 'px';
						}

					};

					window.onresize = fct_window_resize;

					let div_top = document.body.clientHeight - div_height + window.scrollY;

					let html = '';
					html += '<input id="sb_vt_start_move" type="hidden" value="0">';
					html += '<div id="sb_vt_data" style="position:absolute;top:' + div_top + 'px; left:0px; width:100%;height:' + div_height.toString() + 'px;background-color:' + content_bgcolor + ';z-index:1000;display:none">';
					html += '	<div id="sb_vt_data_header" style="width:100%;height:30px;background-color:#D0E7FB;">';
					html += '		<label id="sb_vt_data_header_title" style="position:absolute;top:4px; left: 8px; width: 300px; color:' + title_font_color + '"><b>' + __("Time Vector") + '</b></label>';

					html += '		<div id="sb_vt_data_header_close_section" style="float: right; width:30px;height:30px;background-color:#D0E7FB;">';
					html += '			<img id="sb_vt_data_close" src="/assets/silicon_ioi/images/buttons/close.png" width="20px" height="20px" style="position:relative;top:4px; left:4px;"></img>';
					html += '		</div>';

					html += '		<div id="sb_vt_data_header_updown_section" style="float: right; width:30px;height:30px;background-color:#D0E7FB;">';
					html += '			<img id="sb_vt_data_updown" src="/assets/silicon_ioi/images/buttons/arrow_down.png" width="20px" height="20px" style="display:none;position:relative;top:4px; left:4px;"></img>';
					html += '		</div>';

					html += '	</div>';



					html += '	<div id="sb_vt_data_separator" style="overflow: auto; overflow-x: auto; height:8px;">';
					html += '	</div>';


					html += '	<div id="sb_vt_data_detail" style="width:100%;height:' + content_height.toString() + 'px;background-color:' + content_bgcolor + ';overflow-x: auto;">';

					html += '		<div id="sb_vt_data_margin" style="float:left;width:1%;height:100%;background-color:' + content_bgcolor + ';overflow-x: auto;">';
					html += '		</div>';

					html += '		<div id="sb_vt_data_content" style="float:left;width:99%;height:100%;background-color:' + content_bgcolor + ';overflow-x: auto;">';
					html += '		</div>';

					html += '	</div>';

					html += '</div>';

					parent.body.insertAdjacentHTML('beforeend', html);


					for (var i = 0; i < cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].childElementCount; i++) {

						if (document.getElementById('vt_button_' + i.toString())) {
							document.getElementById('vt_button_' + i.toString()).remove();
						}

						let item_id = '';

						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].doc.item_id) {
							item_id = cur_frm.fields_dict['line_detail'].grid.grid_rows[i].doc.item_id;
						}

						let to_execute = 0;

						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].doc.to_execute) {
							to_execute = cur_frm.fields_dict['line_detail'].grid.grid_rows[i].doc.to_execute;
						}

						if ((item_id != '') && (to_execute == 1)) {
							// Add button VT
							html = '';
							html += '<button id="vt_button_' + i.toString() + '" title="' + __("Time Vector") + '" style="width:30px; height:25px; border: none;">...</button>';
							cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].insertAdjacentHTML('afterbegin', html);
/*
							// Remove CheckBox
							if (cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].children[1]) {
								cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].children[1].remove();
							}
								*/
						}else {
							/*
							// Remove CheckBox
							if (cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].children[0]) {
								cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].children[0].remove();
							}
								*/
						}
					}


					silicon_ioi.doctype.ioiSalesDocumentDocType.sleep_static(200).then(() => {

						let fct_header_mousedown = function() {
							document.getElementById('sb_vt_start_move').value = 1;
							this.style.cursor = 'n-resize';
						};

						document.getElementById('sb_vt_data_header').onmousedown = fct_header_mousedown;


						let fct_window_mouseup = function() {
							if (document.getElementById('sb_vt_start_move')) {
								document.getElementById('sb_vt_start_move').value = 0;
							}

							if (document.getElementById('sb_vt_data_header')) {
								document.getElementById('sb_vt_data_header').style.cursor = 'default';
							}
						};

						window.onmouseup = fct_window_mouseup;


						let fct_window_mousemove = function(e) {

							if (document.getElementById('sb_vt_start_move')) {

								if (document.getElementById('sb_vt_start_move').value == 1) {

									let top = e.clientY + window.pageYOffset;

									if (top < (document.body.clientHeight + window.scrollY - 38)) {

										div_height = document.body.clientHeight - top +  window.scrollY;
										document.getElementById('sb_vt_data').style.height = (document.body.clientHeight - top +  window.scrollY).toString() + 'px';
										document.getElementById('sb_vt_data').style.top = top.toString() + 'px';
									}

								}
							}

						};

						window.onmousemove = fct_window_mousemove;

						if (!silicon_ioi.doctype.ioiSalesDocumentDocType.vt_data_up) {
							document.getElementById('sb_vt_data_separator').style.display = 'none';
							document.getElementById('sb_vt_data_detail').style.display = 'none';
						}else{
							document.getElementById('sb_vt_data_separator').style.display = 'block';
							document.getElementById('sb_vt_data_detail').style.display = 'block';
						}

						silicon_ioi.doctype.ioiTimeVectorFrame.frame_height = 520;
						silicon_ioi.doctype.ioiTimeVectorFrame.refresh_time_vector('', 'sb_vt_data_content', '');


						document.onscroll = function() {
							document.getElementById('sb_vt_data').style.top = document.body.clientHeight - div_height + window.scrollY + 'px';
						};

						let fct_hide = function() {
							document.getElementById('sb_vt_data').style.display = 'none';
						};

						let fct_mouseover = function() {
							this.style.cursor = 'pointer';
						}

						let fct_mouseleave = function() {
							this.style.cursor = 'none';
						}

						let fct_updown = function () {

							silicon_ioi.doctype.ioiSalesDocumentDocType.vt_data_up = !silicon_ioi.doctype.ioiSalesDocumentDocType.vt_data_up;

							if (silicon_ioi.doctype.ioiSalesDocumentDocType.vt_data_up) {
								this.src = '/assets/silicon_ioi/images/buttons/arrow_down.png';
							}else{
								this.src = '/assets/silicon_ioi/images/buttons/arrow_up.png';
							}

							if (!silicon_ioi.doctype.ioiSalesDocumentDocType.vt_data_up) {
								document.getElementById('sb_vt_data_separator').style.display = 'none';
								document.getElementById('sb_vt_data_detail').style.display = 'none';
							}else{
								document.getElementById('sb_vt_data_separator').style.display = 'block';
								document.getElementById('sb_vt_data_detail').style.display = 'block';
							}

							let go = false;

							if (!document.getElementById('sb_vt_start_move')) {
								go = true;
							}else{
								if (document.getElementById('sb_vt_start_move').value == 0) {
									go = true;
								}
							}

							if (go) {

								if (!silicon_ioi.doctype.ioiSalesDocumentDocType.vt_data_up) {
									div_height = 30;
								}

								if (document.body.clientHeight <= div_height)
								{
									div_height = document.body.clientHeight - 100;

								}

								content_height = div_height - 38;

								if (document.getElementById('sb_vt_data')) {
									document.getElementById('sb_vt_data').style.height = div_height.toString() + 'px';
									document.getElementById('sb_vt_data_detail').style.height = content_height.toString() + 'px';

									document.getElementById('sb_vt_data').style.top = document.body.clientHeight - div_height + window.scrollY + 'px';
								}
							}

						}

						document.getElementById('sb_vt_data_updown').onclick = fct_updown;
						document.getElementById("sb_vt_data_updown").onmouseover = fct_mouseover;
						document.getElementById("sb_vt_data_updown").onmouseleave = fct_mouseleave;


						document.getElementById('sb_vt_data_close').onclick = fct_hide;
						document.getElementById("sb_vt_data_close").onmouseover = fct_mouseover;
						document.getElementById("sb_vt_data_close").onmouseleave = fct_mouseleave;


						let fct_click = function() {
							let s = this.id;

							while (s.indexOf('_') != -1) {
								s = s.substring(s.indexOf('_')+1, s.length)
							}

							s = s.trim();

							let item_id = '';

							if (cur_frm.fields_dict['line_detail'].grid.grid_rows[s].doc.item_id) {
								item_id = cur_frm.fields_dict['line_detail'].grid.grid_rows[s].doc.item_id;
							}

							let to_execute = 0;

							if (cur_frm.fields_dict['line_detail'].grid.grid_rows[s].doc.to_execute) {
								to_execute = cur_frm.fields_dict['line_detail'].grid.grid_rows[s].doc.to_execute;
							}

							document.getElementById('sb_vt_data_header_title').innerHTML = '<b>' + __("Time Vector") + '</b> ' + item_id;

							silicon_ioi.doctype.ioiTimeVectorFrame.item_id = item_id;
							silicon_ioi.doctype.ioiTimeVectorFrame.doctype = '';
							silicon_ioi.doctype.ioiTimeVectorFrame.origin = 'IOI SALES ORDER';
							silicon_ioi.doctype.ioiTimeVectorFrame.pk_reference = cur_frm.doc.name + ' | ' + (parseInt(s)+1).toString();
							silicon_ioi.doctype.ioiTimeVectorFrame.frame_height = 520;
							silicon_ioi.doctype.ioiTimeVectorFrame.time_vector_refresh();

							if (item_id != '') {
								if (to_execute == 1) {
									document.getElementById('sb_vt_data').style.display = 'block';
								}else{
									document.getElementById('sb_vt_data').style.display = 'none';
								}
							}else{
								document.getElementById('sb_vt_data').style.display = 'none';
							}

							cur_frm.fields_dict['line_detail'].grid.wrapper[0].getElementsByClassName('rows')[0].children[s].children[0].click();
						}

						for (var i = 0; i < cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].childElementCount; i++) {

							if (document.getElementById('vt_button_' + i.toString())) {
								document.getElementById('vt_button_' + i.toString()).onclick = fct_click;
							}
						}
					});
				}
			}
		}
	}

	create_new_contact(fieldname, reference_fieldname)
	{
		if ((!cur_frm.doc.ioistatus) || (cur_frm.doc.ioistatus == 0)) {

			if ((!cur_frm.doc[reference_fieldname]) || (cur_frm.doc[reference_fieldname] == '')) {
				frappe.msgprint({title: __("Message"), message: __('Customer is mandatory'), indicator: "red"});
				raise;
			}

			frappe.new_doc("Contact",{"customer": cur_frm.doc[reference_fieldname]});
		}
	}

	create_new_carrier_contact(fieldname, reference_fieldname)
	{
		if ((!cur_frm.doc.ioistatus) || (cur_frm.doc.ioistatus == 0)) {

			if ((!cur_frm.doc[reference_fieldname]) || (cur_frm.doc[reference_fieldname] == '')) {
				frappe.msgprint({title: __("Message"), message: __('Carrier is mandatory'), indicator: "red"});
				raise;
			}

			frappe.new_doc("Contact",{"supplier": cur_frm.doc[reference_fieldname]});
		}

	}

	// ***************************************************************************************************************************************
	// Sleep
	// ***************************************************************************************************************************************
	#sleep(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	// ***************************************************************************************************************************************
	// Sleep static
	// ***************************************************************************************************************************************
	static sleep_static(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}


	// ***************************************************************************************************************************************
	// Clear all order customer fields
	// ***************************************************************************************************************************************
	#clear_all_order_customer_fields(me)
	{
		me.frm.set_intro('', '');


		me.frm.doc.order_customer_id = '';
		me.frm.doc.order_customer_name = '';
		me.frm.doc.order_customer_contact_id = '';
		me.frm.doc.order_customer_contact_name = '';
		me.frm.doc.end_user_id = '';

		if ((me.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER')) {

			me.frm.doc.intersite_transaction = 0;
			me.frm.doc.intersite_destination_site_id = '';
			me.refresh_intersite_transaction();
		}

		me.frm.doc.sales_catalog_id = me.journal_sales_catalog_id;
		me.frm.doc.sales_condition_id = me.journal_sales_condition_id;
		me.frm.doc.specific_condition_id = '';
		me.frm.doc.delivery_condition_id = '';
		me.frm.doc.currency_id = me.system_currency;
		me.frm.doc.currency_rate = me.system_currency_rate;
		me.frm.doc.currency_rate_inv = me.system_currency_rate_inv;
		me.frm.doc.currency_digit_rounding = me.system_currency_digit_rounding;

		me.frm.doc.base_discount = 0;
		me.frm.doc.extra_discount = 0;

		if ((me.journal_vat_type) && (this.journal_vat_type.trim() != ''))
		{
			me.frm.doc.vat_type = me.journal_vat_type;

			if ((me.frm.doc.vat_type != 'N') && (me.frm.doc.vat_type != 'NAT'))
			{
				me.frm.doc.spec_vat_rate = 0;
			}else
			{
				me.frm.doc.spec_vat_rate = me.journal_spec_vat_rate;
			}
		}else
		{
			me.frm.doc.vat_type = 'NAT';
			me.frm.doc.vat_matching_id = '';
			me.frm.doc.spec_vat_rate = me.journal_spec_vat_rate;
		}


		me.frm.doc.financial_delay = 0;
		me.frm.doc.financial_discount_mode = 0;
		me.frm.doc.payment_term_id = '';
		me.frm.doc.financial_discount = 0;
		me.frm.doc.fast_financial_discount = 0;

		me.frm.doc.document_calc_mode = 0;
		me.frm.doc.sales_agent_id = '';
		me.frm.doc.family_1_id = '';
		me.frm.doc.family_2_id = '';

		me.frm.set_value('activity_detail', '');
		me.frm.set_value('sector_detail', '');


		me.frm.refresh_field('address_detail');


		if (me.frm.doc.address_detail)
		{
			for(var i = me.frm.get_field("address_detail").grid.grid_rows.length-1; i >= 0; i--) {
				if (me.frm.get_field("address_detail").grid.grid_rows[i].doc.detail_mode.toUpperCase() == 'ORDER') {
					me.frm.doc.address_detail[i].detail_mode = '';
					me.frm.get_field("address_detail").grid.grid_rows[i].remove();
					break;
				}
			}

			me.frm.refresh_field('address_detail');

		}

		if (me.frm.doc.log_detail)
		{
			let i = me.frm.get_field("log_detail").grid.grid_rows.length-1;

			while (i >= 0)
			{
				if ((((!me.frm.doc.delivery_customer_id) || ((me.frm.doc.delivery_customer_id) && (me.frm.doc.delivery_customer_id.trim() == ''))) &&
					((!me.frm.doc.invoice_customer_id) || ((me.frm.doc.invoice_customer_id) && (me.frm.doc.invoice_customer_id.trim() == '')))) ||
					(me.frm.doc.log_detail[i].from_fieldname.toUpperCase() == 'ORDER_CUSTOMER_ID'))
				{
					me.frm.get_field("log_detail").grid.grid_rows[i].remove();
				}
				i--;
			}
		}

		if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
			me.frm.set_df_property('payment_term_id', 'read_only', 0);

			if (me.journal_deliveries_lock_payment_terms == 1) {
				me.frm.set_df_property('payment_term_id', 'read_only', 1)
			}
		}



		me.frm.refresh_field('order_customer_id');
		me.frm.refresh_field('order_customer_name');
		me.frm.refresh_field('order_customer_contact_id');
		me.frm.refresh_field('order_customer_contact_name');
		me.frm.refresh_field('end_user_id');
		me.frm.refresh_field('sales_catalog_id');
		me.frm.refresh_field('sales_condition_id');
		me.frm.refresh_field('specific_condition_id');
		me.frm.refresh_field('delivery_condition_id');
		me.frm.refresh_field('currency_id');
		me.frm.refresh_field('currency_rate');
		me.frm.refresh_field('currency_rate_inv');
		me.frm.refresh_field('currency_digit_rounding');
		me.frm.refresh_field('base_discount');
		me.frm.refresh_field('extra_discount');
		me.frm.refresh_field('vat_type');
		me.frm.refresh_field('vat_matching_id');
		me.frm.refresh_field('spec_vat_rate');
		me.frm.refresh_field('financial_delay');
		me.frm.refresh_field('financial_discount_mode');
		me.frm.refresh_field('financial_discount');
		me.frm.refresh_field('fast_financial_discount');
		me.frm.refresh_field('payment_term_id');
		me.frm.refresh_field('document_calc_mode');
		me.frm.refresh_field('sales_agent_id');
		me.frm.refresh_field('family_1_id');
		me.frm.refresh_field('family_2_id');
		me.frm.refresh_field('activity_detail');
		me.frm.refresh_field('sector_detail');
		me.frm.refresh_field('address_detail');
		me.frm.refresh_field('log_detail');
		me.refresh_total_section();
	}

	// ***************************************************************************************************************************************
	// Select order customer
	// ***************************************************************************************************************************************
	select_order_customer_id()
	{

		this.frm.refresh_field('order_customer_id');
		this.frm.refresh_field('delivery_customer_id');
		this.frm.refresh_field('invoice_customer_id');

		if ((this.frm.doc.order_customer_id) && (this.frm.doc.order_customer_id.trim() != ''))
		{
			let me = this;

			let current_delivery_customer_id = '';
			let current_invoice_customer_id = '';

			if ((this.frm.doc.delivery_customer_id) && (this.frm.doc.delivery_customer_id.trim() != ''))
			{
				current_delivery_customer_id = this.frm.doc.delivery_customer_id;
			}

			if ((this.frm.doc.invoice_customer_id) && (this.frm.doc.invoice_customer_id.trim() != ''))
			{
				current_invoice_customer_id = this.frm.doc.invoice_customer_id;
			}

            let dc_type = ''

            if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
            {   dc_type = 'QUOTE';
            }else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
            {	dc_type = 'ORDER';
			}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
            {	dc_type = 'DELIVERY';
			}else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
			{	dc_type = 'INVOICE';
			}

			frappe.call({  	method: this.path_sales_document + '.ioi_sales_document_select_order_customer',
							args: 	{	"doctype": this.frm.doctype,
										"document_type": dc_type,
										"order_customer_id": this.frm.doc.order_customer_id,
										"delivery_customer_id": current_delivery_customer_id,
										"invoice_customer_id": current_invoice_customer_id,
										"document_site_id": this.frm.doc.site_id
							},
							async: false,
			   				callback: function(r)	{
														me.#clear_all_order_customer_fields(me);

								   						if (r.message.error == 1)
														{
															me.#set_trace(me, 'log_detail', r.message.error_from_fieldname, r.message.error_fieldname, r.message.error_message, 'N');
														}

														me.frm.doc.order_customer_id = r.message.order_customer_id;
														me.frm.doc.order_customer_name = r.message.order_customer_name;
														me.frm.doc.order_customer_contact_id = '';
														me.frm.doc.order_customer_contact_name = '';

														if ((me.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER')) {

															me.frm.doc.intersite_transaction = r.message.intersite_transaction;
															me.frm.doc.intersite_destination_site_id = r.message.intersite_destination_site_id;
															me.refresh_intersite_transaction();
														}


														if ((me.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER'))
														{   me.frm.doc.language = r.message.order_customer_language;
														}

														let change_delivery_customer = true;
														let change_invoice_customer = true;

														if ((me.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER')) {
															change_delivery_customer = true;
															change_invoice_customer = true;
														}else if ((me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')) {

															if ((!current_delivery_customer_id) || ((current_delivery_customer_id) && (current_delivery_customer_id.trim() == ''))) {
																change_delivery_customer = true;
															}else{
																change_delivery_customer = false;
															}

															if ((!current_invoice_customer_id) || ((current_invoice_customer_id) && (current_invoice_customer_id.trim() == ''))) {
																change_invoice_customer = true;
															}else{
																change_invoice_customer = false;
															}
														}

														if (change_delivery_customer) {

															me.frm.doc.delivery_customer_id = r.message.delivery_customer_id;
															me.frm.doc.delivery_customer_name = r.message.delivery_customer_name;
															me.frm.doc.delivery_customer_contact_id = '';
															me.frm.doc.delivery_customer_contact_name = '';
														}

														if (change_invoice_customer)
														{
															me.frm.doc.invoice_customer_id = r.message.invoice_customer_id;
															me.frm.doc.invoice_customer_name = r.message.invoice_customer_name;
															me.frm.doc.invoice_customer_contact_id = '';
															me.frm.doc.invoice_customer_contact_name = '';
														}

														if ((r.message.sales_catalog_id) && (r.message.sales_catalog_id.trim() != ''))
														{
															me.frm.doc.sales_catalog_id = r.message.sales_catalog_id;
														}

														if (me.frm.doc.line_detail)
														{
															let found = false;

															for (var i = 0; i < me.frm.doc.line_detail.length; i++)
															{
																if ((me.frm.doc.line_detail[i].item_id) && (me.frm.doc.line_detail[i].item_id.trim() != ''))
																{
																	if ((me.frm.doc.line_detail[i].sales_condition_id) && (me.frm.doc.line_detail[i].sales_condition_id != me.customer_manual_sales_condition_id))
																	{
																		found = true;
																		break;
																	}
																}
															}

															if (found)
															{
																me.frm.set_intro('Customer has been changed, update line prices please (Action menu -> Update line prices)', 'orange');
															}
														}


														if ((r.message.sales_condition_id) && (r.message.sales_condition_id.trim() != ''))
														{
															me.frm.doc.sales_condition_id = r.message.sales_condition_id;
														}

														if ((r.message.specific_condition_id) && (r.message.specific_condition_id.trim() != ''))
														{
															me.frm.doc.specific_condition_id = r.message.specific_condition_id;
														}

														if ((r.message.delivery_condition_id) && (r.message.delivery_condition_id.trim() != ''))
														{
															me.frm.doc.delivery_condition_id = r.message.delivery_condition_id;
														}


														if ((r.message.currency_id) && (r.message.currency_id.trim() != ''))
														{
															me.frm.doc.currency_id = r.message.currency_id;
															me.frm.doc.currency_rate = r.message.currency_rate;
															me.frm.doc.currency_rate_inv = r.message.currency_rate_inv;
															me.frm.doc.currency_digit_rounding = r.message.currency_digit_rounding;
														}

														if ((me.customer_base_discount_in_header) && (me.customer_base_discount_in_header == 1))
														{
															me.frm.doc.base_discount = r.message.base_discount;
														}

														if ((me.customer_extra_discount_in_header) && (me.customer_extra_discount_in_header == 1))
														{
															me.frm.doc.extra_discount = r.message.extra_discount;
														}

														me.frm.doc.def_base_discount = me.frm.doc.base_discount;
														me.frm.doc.def_extra_discount = me.frm.doc.extra_discount;

														me.frm.doc.payment_term_id = r.message.payment_term_id;


														if ((me.frm.doctype.toUpperCase() == 'IOI SALES ORDER') || (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') ||(me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')) {

															if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
																me.frm.doc.cashdesk_pay_on_order = r.message.pay_on_order;
															}

															if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
																me.frm.doc.cashdesk_pay_on_delivery = r.message.pay_on_delivery;
															}

															if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
																document.getElementById('ioi-sales-order-ioi_sales_order_tab_payment-tab').hidden = true;
															}else if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
																document.getElementById('ioi-sales-delivery-ioi_sales_delivery_tab_payment-tab').hidden = true;
															}else{
																document.getElementById('ioi-sales-invoice-ioi_sales_invoice_tab_payment-tab').hidden = true;
															}

															let show_payment_tab = 0;

															if (me.frm.is_new()) {

																if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
																	if (me.journal_orders_cashdesk_active == 1) {
																		show_payment_tab = 1;
																	}
																}else if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
																	if (me.journal_deliveries_cashdesk_active == 1) {
																		show_payment_tab = 1;
																	}
																}else{
																	if (me.frm.doc.document_type.toUpperCase() == 'INVOICE') {
																		if (me.journal_invoices_cashdesk_active == 1) {
																			show_payment_tab = 1;
																		}
																	}else{
																		if (me.journal_cnotes_cashdesk_active == 1) {
																			show_payment_tab = 1;
																		}



																	}
																}

															}else{
																show_payment_tab = 1;
															}

															if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
																if (show_payment_tab == 1) {
																	document.getElementById('ioi-sales-order-ioi_sales_order_tab_payment-tab').hidden = false;
																}
															}else if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {

																if (show_payment_tab == 1) {

																	if (me.frm.doc.cashdesk_pay_on_delivery == 1) {

																		document.getElementById('ioi-sales-delivery-ioi_sales_delivery_tab_payment-tab').hidden = false;
																	}

																}
															}else{
																if (show_payment_tab == 1) {
																	document.getElementById('ioi-sales-invoice-ioi_sales_invoice_tab_payment-tab').hidden = false;
																}

															}

															if ((me.frm.doc.site_id) && (me.frm.doc.site_id.trim() != '')) {

																if ((me.frm.doc.currency_id) && (me.frm.doc.currency_id.trim() != '')) {
																	me.set_query('cashdesk_id', '', me.path_cash_desk + '.ioi_cash_desk_get', '{"site_id":"' + me.frm.doc.site_id + '", "currency_id":"' + me.frm.doc.currency_id + '"}');
																}else {
																	me.set_query('cashdesk_id', '', me.path_cash_desk + '.ioi_cash_desk_get', '{"site_id":"' + me.frm.doc.site_id + '"}');
																}
															}else {
																if ((me.frm.doc.currency_id) && (me.frm.doc.currency_id.trim() != '')) {
																	me.set_query('cashdesk_id', '', me.path_cash_desk + '.ioi_cash_desk_get', '{"currency_id":"' + me.frm.doc.currency_id + '"}');
																}else{
																	me.set_query('cashdesk_id', '', me.path_cash_desk + '.ioi_cash_desk_get', '');
																}
															}


														}


														me.frm.doc.vat_type = r.message.vat_type;

														if ((me.frm.doc.vat_type == 'N') || (me.frm.doc.vat_type == 'NAT'))
														{
															if ((r.message.specific_vat_code_id) && (r.message.specific_vat_code_id != 0))
															{
																me.frm.doc.spec_vat_rate = r.message.specific_vat_code_id;
															}
														}else
														{
															me.frm.doc.spec_vat_rate = 0;
														}

														me.frm.doc.vat_matching_id = r.message.vat_matching_id;

														me.frm.doc.financial_delay = r.message.financial_delay;
														me.frm.doc.financial_discount_mode = r.message.financial_discount_mode;
														me.frm.doc.financial_discount = r.message.financial_discount;
														me.frm.doc.financial_discount_pay_day = r.message.financial_discount_pay_day;
														me.frm.doc.fast_financial_discount = r.message.fast_financial_discount;
														me.frm.doc.fast_financial_discount_pay_day = r.message.fast_financial_discount_pay_day;


														me.frm.doc.document_calc_mode = r.message.document_calc_mode;
														me.frm.doc.sales_agent_id = r.message.sales_agent_id;
														me.frm.doc.customer_family_1_id = r.message.family_1_id;
														me.frm.doc.customer_family_2_id = r.message.family_2_id;

														me.frm.set_value('activity_detail', '');

														if (r.message.activity_detail)
														{
															for(var i = 0; i < r.message.activity_detail.length; i++)
															{
																let row = me.frm.add_child('activity_detail');
																row.activity_id = r.message.activity_detail[i].activity_id;
															}
														}

														me.frm.set_value('sector_detail', '');

														if (r.message.sector_detail)
														{
															for(var i = 0; i < r.message.sector_detail.length; i++)
															{
																let row = me.frm.add_child('sector_detail');
																row.sector_id = r.message.sector_detail[i].sector_id;
															}
														}



														if ((me.frm.doc.order_customer_id) && (me.frm.doc.order_customer_id.trim() != ''))
														{
															if (me.frm.doc.address_detail)
															{
																for(var i = me.frm.get_field("address_detail").grid.grid_rows.length-1; i >= 0; i--) {

																	if (me.frm.get_field("address_detail").grid.grid_rows[i]) {
																		if (me.frm.get_field("address_detail").grid.grid_rows[i].doc.detail_mode.toUpperCase() == 'ORDER') {
																			me.frm.get_field("address_detail").grid.grid_rows[i].remove();
																		}
																	}
																}
															}
														}

														me.#sleep(250).then(() => {

															if (change_delivery_customer) {

																if ((me.frm.doc.delivery_customer_id) && (me.frm.doc.delivery_customer_id.trim() != ''))
																{
																	if (me.frm.doc.address_detail)
																	{
																		for(var i = me.frm.get_field("address_detail").grid.grid_rows.length-1; i >= 0; i--) {
																			if (me.frm.get_field("address_detail").grid.grid_rows[i]) {
																				if (me.frm.get_field("address_detail").grid.grid_rows[i].doc.detail_mode.toUpperCase() == 'DELIVERY') {
																					me.frm.get_field("address_detail").grid.grid_rows[i].remove();
																				}
																			}
																		}

																	}
																}
															}

															me.#sleep(250).then(() => {

																if (change_invoice_customer) {

																	if ((me.frm.doc.invoice_customer_id) && (me.frm.doc.invoice_customer_id.trim() != ''))
																	{
																		if (me.frm.doc.address_detail)
																		{
																			for(var i = me.frm.get_field("address_detail").grid.grid_rows.length-1; i >= 0; i--) {
																				if (me.frm.get_field("address_detail").grid.grid_rows[i]) {
																					if (me.frm.get_field("address_detail").grid.grid_rows[i].doc.detail_mode.toUpperCase() == 'INVOICE') {
																						me.frm.get_field("address_detail").grid.grid_rows[i].remove();

																					}
																				}
																			}
																		}
																	}
																}

																me.#sleep(250).then(() => {

																	if ((me.frm.doc.order_customer_id) && (me.frm.doc.order_customer_id.trim() != ''))
																	{
																		let order_address = me.frm.add_child('address_detail');
																		order_address.customer_id = me.frm.doc.order_customer_id;
																		order_address.detail_mode = 'ORDER';
																		order_address.full_name = r.message.order_customer_name;
																		order_address.detail_address = r.message.order_customer_address;
																		order_address.detail_postal_code_id = r.message.order_customer_postal_code_id;
																		order_address.detail_postal_code = r.message.order_customer_postal_code
																		order_address.detail_city = r.message.order_customer_city
																		order_address.detail_country_id = r.message.order_customer_country_id
																		order_address.detail_phone_1 = r.message.order_customer_phone1
																		order_address.detail_phone_2 = r.message.order_customer_phone2
																		order_address.detail_email = r.message.order_customer_email
																		order_address.latitude = r.message.order_customer_latitude
																		order_address.longitude = r.message.order_customer_longitude
																	}



																	if (change_delivery_customer) {

																		if ((me.frm.doc.delivery_customer_id) && (me.frm.doc.delivery_customer_id.trim() != ''))
																		{
																			let delivery_address = me.frm.add_child('address_detail');
																			delivery_address.customer_id = me.frm.doc.delivery_customer_id;
																			delivery_address.detail_mode = 'DELIVERY';
																			delivery_address.full_name = r.message.delivery_customer_name;
																			delivery_address.detail_address = r.message.delivery_customer_address;
																			delivery_address.detail_postal_code_id = r.message.delivery_customer_postal_code_id;
																			delivery_address.detail_postal_code = r.message.delivery_customer_postal_code
																			delivery_address.detail_city = r.message.delivery_customer_city
																			delivery_address.detail_country_id = r.message.delivery_customer_country_id
																			delivery_address.detail_phone_1 = r.message.delivery_customer_phone1
																			delivery_address.detail_phone_2 = r.message.delivery_customer_phone2
																			delivery_address.detail_email = r.message.delivery_customer_email
																			delivery_address.latitude = r.message.delivery_customer_latitude
																			delivery_address.longitude = r.message.delivery_customer_longitude
																		}
																	}


																	if (change_invoice_customer) {

																		if ((me.frm.doc.invoice_customer_id) && (me.frm.doc.invoice_customer_id.trim() != ''))
																		{
																			let invoice_address = me.frm.add_child('address_detail');
																			invoice_address.customer_id = me.frm.doc.invoice_customer_id;
																			invoice_address.detail_mode = 'INVOICE';
																			invoice_address.full_name = r.message.invoice_customer_name;
																			invoice_address.detail_address = r.message.invoice_customer_address;
																			invoice_address.detail_postal_code_id = r.message.invoice_customer_postal_code_id;
																			invoice_address.detail_postal_code = r.message.invoice_customer_postal_code
																			invoice_address.detail_city = r.message.invoice_customer_city
																			invoice_address.detail_country_id = r.message.invoice_customer_country_id
																			invoice_address.detail_phone_1 = r.message.invoice_customer_phone1
																			invoice_address.detail_phone_2 = r.message.invoice_customer_phone2
																			invoice_address.detail_email = r.message.invoice_customer_email
																			invoice_address.latitude = r.message.invoice_customer_latitude
																			invoice_address.longitude = r.message.invoice_customer_longitude
																		}
																	}



																	me.frm.doc.def_base_discount = me.frm.doc.base_discount;
																	me.frm.doc.def_extra_discount = me.frm.doc.extra_discount;

																	me.frm.refresh_field('language');
																	me.frm.refresh_field('order_customer_id');
																	me.frm.refresh_field('order_customer_name');
																	me.frm.refresh_field('order_customer_contact_id');
																	me.frm.refresh_field('order_customer_contact_name');

																	cur_frm.refresh_field('delivery_customer_id');
																	me.frm.refresh_field('delivery_customer_name');
																	me.frm.refresh_field('delivery_customer_contact_id');
																	me.frm.refresh_field('delivery_customer_contact_name');
																	me.frm.refresh_field('invoice_customer_id');
																	me.frm.refresh_field('invoice_customer_name');
																	me.frm.refresh_field('invoice_customer_contact_id');
																	me.frm.refresh_field('invoice_customer_contact_name');
																	me.frm.refresh_field('sales_catalog_id');
																	me.frm.refresh_field('sales_condition_id');
																	me.frm.refresh_field('specific_condition_id');
																	me.frm.refresh_field('delivery_condition_id');
																	me.frm.refresh_field('currency_id');
																	me.frm.refresh_field('currency_rate');
																	me.frm.refresh_field('currency_rate_inv');
																	me.frm.refresh_field('currency_digit_rounding');
																	me.frm.refresh_field('base_discount');
																	me.frm.refresh_field('extra_discount');
																	me.frm.refresh_field('def_base_discount');
																	me.frm.refresh_field('def_extra_discount');

																	me.frm.refresh_field('vat_type');
																	me.frm.refresh_field('vat_matching_id');
																	me.frm.refresh_field('spec_vat_rate');
																	me.frm.refresh_field('financial_delay');
																	me.frm.refresh_field('financial_discount_mode');
																	me.frm.refresh_field('financial_discount');
																	me.frm.refresh_field('financial_discount_pay_day');
																	me.frm.refresh_field('fast_financial_discount');
																	me.frm.refresh_field('fast_financial_discount_pay_day');

																	me.frm.refresh_field('payment_term_id');
																	me.frm.refresh_field('document_calc_mode');
																	me.frm.refresh_field('sales_agent_id');
																	me.frm.refresh_field('customer_family_1_id');
																	me.frm.refresh_field('customer_family_2_id');
																	me.frm.refresh_field('activity_detail');
																	me.frm.refresh_field('sector_detail');
																	me.frm.refresh_field('address_detail');
																	me.frm.refresh_field('log_detail');
																	me.refresh_total_section();

																	if ((me.frm.doc.order_customer_id) && (me.frm.doc.order_customer_id.trim() != ''))
																	{
																		//me.set_query('order_customer_contact_id', '', me.path_customer + '.ioi_contact_get_all', '{"customer" : "' + me.frm.doc.order_customer_id + '"}');
																		me.set_query('order_customer_contact_id', '', me.path_search_definition + '.set_search_definition_filters', '{"customer" : "' + me.frm.doc.order_customer_id + '"}');
																		me.set_query('end_user_id', '', me.path_customer + '.ioi_customer_end_user_get_all', '{"end_user_id" : "' + me.frm.doc.order_customer_id + '"}');
																	}else
																	{	me.set_query('order_customer_contact_id', '', me.path_customer + '.ioi_contact_get_none');
																		me.set_query('end_user_id', '', me.path_customer + '.ioi_customer_end_user_get_none');
																	}

																	if ((me.frm.doc.delivery_customer_id) && (me.frm.doc.delivery_customer_id.trim() != ''))
																	{
																		//me.set_query('delivery_customer_contact_id', '', me.path_customer + '.ioi_contact_get_all', '{"customer" : "' + me.frm.doc.delivery_customer_id + '"}');
																		me.set_query('delivery_customer_contact_id', '', me.path_search_definition + '.set_search_definition_filters', '{"customer" : "' + me.frm.doc.delivery_customer_id + '"}');
																	}else
																	{	me.set_query('delivery_customer_contact_id', '', me.path_customer + '.ioi_contact_get_none');
																	}

																	if ((me.frm.doc.invoice_customer_id) && (me.frm.doc.invoice_customer_id.trim() != ''))
																	{
																		//me.set_query('invoice_customer_contact_id', '', me.path_customer + '.ioi_contact_get_all', '{"customer" : "' + me.frm.doc.invoice_customer_id + '"}');
																		me.set_query('invoice_customer_contact_id', '', me.path_search_definition + '.set_search_definition_filters', '{"customer" : "' + me.frm.doc.invoice_customer_id + '"}');
																	}else
																	{	me.set_query('invoice_customer_contact_id', '', me.path_customer + '.ioi_contact_get_none');
																	}
																});
															});
														});


													}
			});
		}else
		{	let me = this;
			this.#clear_all_order_customer_fields(me);
			this.set_query('order_customer_contact_id', '', me.path_customer + '.ioi_contact_get_none');
			this.set_query('end_user_id', '', me.path_customer + '.ioi_customer_end_user_get_none');

		}

		if ((this.frm.doc.order_customer_id) && (this.frm.doc.order_customer_id.trim() != '')) {

			this.set_query('park_id', '', this.path_park + '.ioi_park_get_enabled_for_sales', '{"customer_id": "' +  this.frm.doc.order_customer_id + '"}');
		}else{
			this.set_query('park_id', '', this.path_park + '.ioi_park_get_enabled_for_sales', '');
		}


		this.#sleep(1000).then(() => { this.set_label_when_error(); });

	}


	search_park()
	{
		let me = this;

		let title = __("Select a park");
		let form_width_pixel = '1100px';
		let table = "ioi Park";
		let fields = 'name, description, code_ref, external_ref, customer_id, customer_name, customer_contact_id, customer_contact_name, technical_memo';

		let fields_len = '250, 380, 200, 200, 250, 300, 250, 300, 500';
		let fields_desc = __('Identification') + ',' + __('Description') + ',' + __('Serial number') + ',' + __('External ref') + ',' + __('Customer') + ',' + __('Cust. name') + ',' + __('Cust. contact');
		fields_desc += ',' + __('Cust. contact name') + ',' + __('Tech. memo');
		let where = "ioistatus not in (-3, 0, 5, 6) ";


		let order_by = 'name asc';

		let fct_callback = function (return_value) {
			me.frm.set_value('park_id', return_value);
			me.frm.refresh_field('park_id');
		}

		silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

	}

	set_assigned_park()
	{
		if ((this.frm.doc.dossier_id) && (this.frm.doc.dossier_id.trim() != '')) {

			let me = this;

			let method = this.path_dossier + '.ioi_dossier_get_data';

			frappe.call({  	method: method,
							args: {	"name" : this.frm.doc.dossier_id
							},
							async: false,
							callback:function(r)	{

								me.frm.doc.park_id = r.message.park_id;
								me.frm.refresh_field('park_id');
							}
			});
		}
	}


	// ***************************************************************************************************************************************
	// Order Customer : advanced search
	// ***************************************************************************************************************************************
	search_order_customer()
	{
		let me = this;

		let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email, alternate_id';
		let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400, 250';
		let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email, Alternate id';
		let where = 'ioistatus = 2';
		let order_by = 'name asc';

		let fct_callback = function (return_value) {

			me.frm.doc.order_customer_id = return_value;
			me.frm.refresh_field('order_customer_id');
			me.select_order_customer_id();
		}

		silicon_ioi.ioiCommon.select_customer(fields, fields_len, fields_desc, where, order_by, fct_callback);
	}


	// ***************************************************************************************************************************************
	// Select Data from table : advanced search
	// ***************************************************************************************************************************************
	search_select_form(title, form_width_pixel, table,fields, fields_len, fields_desc, where, order_by, fct_callback)
	{
		silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);
	}


	// ***************************************************************************************************************************************
	// Clear all delivery customer fields
	// ***************************************************************************************************************************************
	#clear_all_delivery_customer_fields(me)
	{
		me.frm.set_intro('', 'red');
		me.frm.doc.delivery_customer_id = '';
		me.frm.doc.delivery_customer_name = '';
		me.frm.doc.delivery_customer_contact_id = '';
		me.frm.doc.delivery_customer_contact_name = '';

		if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {

			me.frm.doc.intersite_transaction = 0;
			me.frm.doc.intersite_destination_site_id = '';
			me.refresh_intersite_transaction();
		}


		me.frm.refresh_field('address_detail');

		if (me.frm.doc.address_detail)
		{
			for(var i = me.frm.get_field("address_detail").grid.grid_rows.length-1; i >= 0; i--) {
				if (me.frm.get_field("address_detail").grid.grid_rows[i].doc.detail_mode.toUpperCase() == 'DELIVERY') {
					me.frm.doc.address_detail[i].detail_mode = '';
					me.frm.get_field("address_detail").grid.grid_rows[i].remove();
					break;
				}
			}

			me.frm.refresh_field('address_detail');
		}

		if (me.frm.doc.log_detail)
		{
			let i = me.frm.get_field("log_detail").grid.grid_rows.length-1;

			while (i >= 0)
			{
				if ((((!me.frm.doc.order_customer_id) || ((me.frm.doc.order_customer_id) && (me.frm.doc.order_customer_id.trim() == ''))) &&
					((!me.frm.doc.invoice_customer_id) || ((me.frm.doc.invoice_customer_id) && (me.frm.doc.invoice_customer_id.trim() == '')))) ||
					(me.frm.doc.log_detail[i].from_fieldname.toUpperCase() == 'DELIVERY_CUSTOMER_ID'))
				{
					me.frm.get_field("log_detail").grid.grid_rows[i].remove();
				}
				i--;
			}
		}

		me.frm.refresh_field('delivery_customer_id');
		me.frm.refresh_field('delivery_customer_name');
		me.frm.refresh_field('delivery_customer_contact_id');
		me.frm.refresh_field('delivery_customer_contact_name');
		me.frm.refresh_field('address_detail');
		me.frm.refresh_field('log_detail');
	}


	// ***************************************************************************************************************************************
	// Select delivery customer
	// ***************************************************************************************************************************************
	select_delivery_customer_id()
	{


		this.frm.refresh_field('order_customer_id');
		this.frm.refresh_field('delivery_customer_id');
		this.frm.refresh_field('invoice_customer_id');


		if ((this.frm.doc.delivery_customer_id) && (this.frm.doc.delivery_customer_id.trim() != ''))
		{
			let me = this;

			let current_order_customer_id = '';
			let current_invoice_customer_id = '';

			if ((this.frm.doc.order_customer_id) && (this.frm.doc.order_customer_id.trim() != ''))
			{
				current_order_customer_id = this.frm.doc.order_customer_id;
			}

			if ((this.frm.doc.invoice_customer_id) && (this.frm.doc.invoice_customer_id.trim() != ''))
			{
				current_invoice_customer_id = this.frm.doc.invoice_customer_id;
			}

            let dc_type = ''

            if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
            {	dc_type = 'QUOTE';
            }else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
            {	dc_type = 'ORDER';
            }else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
            {	dc_type = 'DELIVERY';
            }else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
            {	dc_type = 'INVOICE';
            }


			frappe.call({  	method: this.path_sales_document + '.ioi_sales_document_select_delivery_customer',
							args: 	{	"doctype": this.frm.doctype,
										"document_type": dc_type,
										"order_customer_id": current_order_customer_id,
										"delivery_customer_id": this.frm.doc.delivery_customer_id,
										"invoice_customer_id": current_invoice_customer_id,
										"document_site_id" : this.frm.doc.site_id
							},
							async: false,
			   				callback: function(r)	{
														me.#clear_all_delivery_customer_fields(me);

								   						if (r.message.error == 1)
														{
															me.#set_trace(me, 'log_detail', r.message.error_from_fieldname, r.message.error_fieldname, r.message.error_message, 'N');
														}

														if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
														{   me.frm.doc.language = r.message.delivery_customer_language;
														}

														let change_order_customer = true;
														let change_invoice_customer = true;

														if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
															change_order_customer = true;
															change_invoice_customer = true;
														}else {

															if ((!current_order_customer_id) || ((current_order_customer_id) && (current_order_customer_id.trim() == ''))) {
																change_order_customer = true;
															}else{
																change_order_customer = false;
															}

															if ((!current_invoice_customer_id) || ((current_invoice_customer_id) && (current_invoice_customer_id.trim() == ''))) {
																change_invoice_customer = true;
															}else{
																change_invoice_customer = false;
															}
														}

														if (change_order_customer)
														{
															me.frm.doc.order_customer_id = r.message.order_customer_id;
															me.frm.doc.order_customer_name = r.message.order_customer_name;
															me.frm.doc.order_customer_contact_id = '';
															me.frm.doc.order_customer_contact_name = '';
														}

														me.frm.doc.delivery_customer_id = r.message.delivery_customer_id;
														me.frm.doc.delivery_customer_name = r.message.delivery_customer_name;
														me.frm.doc.delivery_customer_contact_id = '';
														me.frm.doc.delivery_customer_contact_name = '';

														if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
															me.frm.doc.intersite_transaction = r.message.intersite_transaction;
															me.frm.doc.intersite_destination_site_id = r.message.intersite_destination_site_id;
															me.refresh_intersite_transaction();
														}


														if (change_invoice_customer)
														{
															me.frm.doc.invoice_customer_id = r.message.invoice_customer_id;
															me.frm.doc.invoice_customer_name = r.message.invoice_customer_name;
															me.frm.doc.invoice_customer_contact_id = '';
															me.frm.doc.invoice_customer_contact_name = '';
														}

														if (change_order_customer)
														{
															if ((r.message.sales_catalog_id) && (r.message.sales_catalog_id.trim() != ''))
															{
																me.frm.doc.sales_catalog_id = r.message.sales_catalog_id;
															}

															if ((r.message.sales_condition_id) && (r.message.sales_condition_id.trim() != ''))
															{
																me.frm.doc.sales_condition_id = r.message.sales_condition_id;
															}

															if ((r.message.specific_condition_id) && (r.message.specific_condition_id.trim() != ''))
															{
																me.frm.doc.specific_condition_id = r.message.specific_condition_id;
															}

															if ((r.message.delivery_condition_id) && (r.message.delivery_condition_id.trim() != ''))
															{
																me.frm.doc.delivery_condition_id = r.message.delivery_condition_id;
															}


															if ((r.message.currency_id) && (r.message.currency_id.trim() != ''))
															{
																me.frm.doc.currency_id = r.message.currency_id;
																me.frm.doc.currency_rate = r.message.currency_rate;
																me.frm.doc.currency_rate_inv = r.message.currency_rate_inv;
																me.frm.doc.currency_digit_rounding = r.message.currency_digit_rounding;
															}

															if ((me.customer_base_discount_in_header) && (me.customer_base_discount_in_header == 1))
															{
																me.frm.doc.base_discount = r.message.base_discount;
															}

															if ((me.customer_extra_discount_in_header) && (me.customer_extra_discount_in_header == 1))
															{
																me.frm.doc.extra_discount = r.message.extra_discount;
															}

															me.frm.doc.def_base_discount = me.frm.doc.base_discount;
															me.frm.doc.def_extra_discount = me.frm.doc.extra_discount;


															me.frm.doc.payment_term_id = r.message.payment_term_id;

															if ((me.frm.doctype.toUpperCase() == 'IOI SALES ORDER') || (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')) {

																if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
																	me.frm.doc.cashdesk_pay_on_order = r.message.pay_on_order;
																}

																if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
																	me.frm.doc.cashdesk_pay_on_delivery = r.message.pay_on_delivery;
																}

																if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
																	document.getElementById('ioi-sales-order-ioi_sales_order_tab_payment-tab').hidden = true;
																}else if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
																	document.getElementById('ioi-sales-delivery-ioi_sales_delivery_tab_payment-tab').hidden = true;
																}else{
																	document.getElementById('ioi-sales-invoice-ioi_sales_invoice_tab_payment-tab').hidden = true;
																}

																let show_payment_tab = 0;

																if (me.frm.is_new()) {
																	if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
																		if (me.journal_orders_cashdesk_active == 1) {
																			show_payment_tab = 1;
																		}
																	}else if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
																		if (me.journal_deliveries_cashdesk_active == 1) {
																			show_payment_tab = 1;
																		}
																	}else{

																		if (me.frm.doc.document_type.toUpperCase() == 'INVOICE') {
																			if (me.journal_invoices_cashdesk_active == 1) {
																				show_payment_tab = 1;
																			}
																		}else{
																			if (me.journal_cnotes_cashdesk_active == 1) {
																				show_payment_tab = 1;
																			}
																		}
																	}

																}else{
																	show_payment_tab = 1;
																}

																if (show_payment_tab == 1) {
																	if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
																		document.getElementById('ioi-sales-order-ioi_sales_order_tab_payment-tab').hidden = false;
																	}else if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
																		if (me.frm.doc.cashdesk_pay_on_delivery == 1) {
																			document.getElementById('ioi-sales-delivery-ioi_sales_delivery_tab_payment-tab').hidden = false;
																		}
																	}else{
																		document.getElementById('ioi-sales-invoice-ioi_sales_invoice_tab_payment-tab').hidden = false;
																	}
																}

																if ((me.frm.doc.site_id) && (me.frm.doc.site_id.trim() != '')) {

																	if ((me.frm.doc.currency_id) && (me.frm.doc.currency_id.trim() != '')) {
																		me.set_query('cashdesk_id', '', me.path_cash_desk + '.ioi_cash_desk_get', '{"site_id":"' + me.frm.doc.site_id + '", "currency_id":"' + me.frm.doc.currency_id + '"}');
																	}else {
																		me.set_query('cashdesk_id', '', me.path_cash_desk + '.ioi_cash_desk_get', '{"site_id":"' + me.frm.doc.site_id + '"}');
																	}
																}else {
																	if ((me.frm.doc.currency_id) && (me.frm.doc.currency_id.trim() != '')) {
																		me.set_query('cashdesk_id', '', me.path_cash_desk + '.ioi_cash_desk_get', '{"currency_id":"' + me.frm.doc.currency_id + '"}');
																	}else{
																		me.set_query('cashdesk_id', '', me.path_cash_desk + '.ioi_cash_desk_get', '');
																	}
																}

															}

															me.frm.doc.vat_type = r.message.vat_type;

															if ((me.frm.doc.vat_type == 'N') || (me.frm.doc.vat_type == 'NAT'))
															{
																if ((r.message.specific_vat_code_id) && (r.message.specific_vat_code_id != 0))
																{
																	me.frm.doc.spec_vat_rate = r.message.specific_vat_code_id;
																}
															}else
															{
																me.frm.doc.spec_vat_rate = 0;
															}

															me.frm.doc.vat_matching_id = r.message.vat_matching_id;


															me.frm.doc.financial_delay = r.message.financial_delay;
															me.frm.doc.financial_discount_mode = r.message.financial_discount_mode;
															me.frm.doc.financial_discount = r.message.financial_discount;
															me.frm.doc.financial_discount_pay_day = r.message.financial_discount_pay_day;
															me.frm.doc.fast_financial_discount = r.message.fast_financial_discount;
															me.frm.doc.fast_financial_discount_pay_day = r.message.fast_financial_discount_pay_day;
															me.frm.doc.document_calc_mode = r.message.document_calc_mode;
															me.frm.doc.sales_agent_id = r.message.sales_agent_id;
															me.frm.doc.customer_family_1_id = r.message.family_1_id;
															me.frm.doc.customer_family_2_id = r.message.family_2_id;

															me.frm.set_value('activity_detail', '');

															if (r.message.activity_detail)
															{
																for(var i = 0; i < r.message.activity_detail.length; i++)
																{
																	let row = me.frm.add_child('activity_detail');
																	row.activity_id = r.message.activity_detail[i].activity_id;
																}
															}

															me.frm.set_value('sector_detail', '');

															if (r.message.sector_detail)
															{
																for(var i = 0; i < r.message.sector_detail.length; i++)
																{
																	let row = me.frm.add_child('sector_detail');
																	row.sector_id = r.message.sector_detail[i].sector_id;
																}
															}
														}



														me.frm.refresh_field('address_detail');

														if ((me.frm.doc.delivery_customer_id) && (me.frm.doc.delivery_customer_id.trim() != ''))
														{
															if (me.frm.doc.address_detail)
															{
																for(var i = me.frm.get_field("address_detail").grid.grid_rows.length-1; i >= 0; i--) {

																	if (me.frm.get_field("address_detail").grid.grid_rows[i]) {
																		if (me.frm.get_field("address_detail").grid.grid_rows[i].doc.detail_mode.toUpperCase() == 'DELIVERY') {
																			me.frm.get_field("address_detail").grid.grid_rows[i].remove();
																		}
																	}
																}
															}
														}


														me.#sleep(250).then(() => {

															if (change_order_customer) {

																if ((me.frm.doc.order_customer_id) && (me.frm.doc.order_customer_id.trim() != ''))
																{
																	if (me.frm.doc.address_detail)
																	{
																		for(var i = me.frm.get_field("address_detail").grid.grid_rows.length-1; i >= 0; i--) {
																			if (me.frm.get_field("address_detail").grid.grid_rows[i]) {
																				if (me.frm.get_field("address_detail").grid.grid_rows[i].doc.detail_mode.toUpperCase() == 'ORDER') {
																					me.frm.get_field("address_detail").grid.grid_rows[i].remove();
																				}
																			}
																		}

																	}
																}
															}

															me.#sleep(250).then(() => {


																if (change_invoice_customer) {

																	if ((me.frm.doc.invoice_customer_id) && (me.frm.doc.invoice_customer_id.trim() != ''))
																	{
																		if (me.frm.doc.address_detail)
																		{
																			for(var i = me.frm.get_field("address_detail").grid.grid_rows.length-1; i >= 0; i--) {
																				if (me.frm.get_field("address_detail").grid.grid_rows[i]) {
																					if (me.frm.get_field("address_detail").grid.grid_rows[i].doc.detail_mode.toUpperCase() == 'INVOICE') {
																						me.frm.get_field("address_detail").grid.grid_rows[i].remove();

																					}
																				}
																			}
																		}
																	}
																}

																me.#sleep(250).then(() => {



																	if (change_order_customer)
																	{
																		if ((me.frm.doc.order_customer_id) && (me.frm.doc.order_customer_id.trim() != ''))
																		{
																			let order_address = me.frm.add_child('address_detail');
																			order_address.customer_id = me.frm.doc.order_customer_id;
																			order_address.detail_mode = 'ORDER';
																			order_address.full_name = r.message.order_customer_name;
																			order_address.detail_address = r.message.order_customer_address;
																			order_address.detail_postal_code_id = r.message.order_customer_postal_code_id;
																			order_address.detail_postal_code = r.message.order_customer_postal_code;
																			order_address.detail_city = r.message.order_customer_city;
																			order_address.detail_country_id = r.message.order_customer_country_id;
																			order_address.detail_phone_1 = r.message.order_customer_phone1;
																			order_address.detail_phone_2 = r.message.order_customer_phone2;
																			order_address.detail_email = r.message.order_customer_email;
																			order_address.latitude = r.message.order_customer_latitude
																			order_address.longitude = r.message.order_customer_longitude

																			me.frm.refresh_field('address_detail');
																		}
																	}

																	if ((me.frm.doc.delivery_customer_id) && (me.frm.doc.delivery_customer_id.trim() != ''))
																	{
																		me.frm.refresh_field('address_detail');
																		let delivery_address = me.frm.add_child('address_detail');
																		delivery_address.customer_id = me.frm.doc.delivery_customer_id;
																		delivery_address.detail_mode = 'DELIVERY';
																		delivery_address.full_name = r.message.delivery_customer_name;
																		delivery_address.detail_address = r.message.delivery_customer_address;
																		delivery_address.detail_postal_code_id = r.message.delivery_customer_postal_code_id;
																		delivery_address.detail_postal_code = r.message.delivery_customer_postal_code;
																		delivery_address.detail_city = r.message.delivery_customer_city;
																		delivery_address.detail_country_id = r.message.delivery_customer_country_id;
																		delivery_address.detail_phone_1 = r.message.delivery_customer_phone1;
																		delivery_address.detail_phone_2 = r.message.delivery_customer_phone2;
																		delivery_address.detail_email = r.message.delivery_customer_email;
																		delivery_address.latitude = r.message.delivery_customer_latitude
																		delivery_address.longitude = r.message.delivery_customer_longitude

																		me.frm.refresh_field('address_detail');
																	}


																	if (change_invoice_customer)
																	{
																		if ((me.frm.doc.invoice_customer_id) && (me.frm.doc.invoice_customer_id.trim() != ''))
																		{
																			me.frm.refresh_field('address_detail');
																			let invoice_address = me.frm.add_child('address_detail');
																			invoice_address.customer_id = me.frm.doc.invoice_customer_id;
																			invoice_address.detail_mode = 'INVOICE';
																			invoice_address.full_name = r.message.invoice_customer_name;
																			invoice_address.detail_address = r.message.invoice_customer_address;
																			invoice_address.detail_postal_code_id = r.message.invoice_customer_postal_code_id;
																			invoice_address.detail_postal_code = r.message.invoice_customer_postal_code;
																			invoice_address.detail_city = r.message.invoice_customer_city;
																			invoice_address.detail_country_id = r.message.invoice_customer_country_id;
																			invoice_address.detail_phone_1 = r.message.invoice_customer_phone1;
																			invoice_address.detail_phone_2 = r.message.invoice_customer_phone2;
																			invoice_address.detail_email = r.message.invoice_customer_email;
																			invoice_address.latitude = r.message.invoice_customer_latitude
																			invoice_address.longitude = r.message.invoice_customer_longitude

																			me.frm.refresh_field('address_detail');
																		}
																	}

																	me.frm.doc.def_base_discount = me.frm.doc.base_discount;
																	me.frm.doc.def_extra_discount = me.frm.doc.extra_discount;


																	me.frm.refresh_field('language');
																	me.frm.refresh_field('order_customer_id');
																	me.frm.refresh_field('order_customer_name');
																	me.frm.refresh_field('order_customer_contact_id');
																	me.frm.refresh_field('order_customer_contact_name');

																	me.frm.refresh_field('delivery_customer_id');
																	me.frm.refresh_field('delivery_customer_name');
																	me.frm.refresh_field('delivery_customer_contact_id');
																	me.frm.refresh_field('delivery_customer_contact_name');
																	me.frm.refresh_field('invoice_customer_id');
																	me.frm.refresh_field('invoice_customer_name');
																	me.frm.refresh_field('invoice_customer_contact_id');
																	me.frm.refresh_field('invoice_customer_contact_name');
																	me.frm.refresh_field('sales_catalog_id');
																	me.frm.refresh_field('sales_condition_id');
																	me.frm.refresh_field('specific_condition_id');
																	me.frm.refresh_field('delivery_condition_id');
																	me.frm.refresh_field('currency_id');
																	me.frm.refresh_field('currency_rate');
																	me.frm.refresh_field('currency_rate_inv');
																	me.frm.refresh_field('currency_digit_rounding');
																	me.frm.refresh_field('base_discount');
																	me.frm.refresh_field('extra_discount');
																	me.frm.refresh_field('def_base_discount');
																	me.frm.refresh_field('def_extra_discount');
																	me.frm.refresh_field('vat_type');
																	me.frm.refresh_field('vat_matching_id');
																	me.frm.refresh_field('spec_vat_rate');
																	me.frm.refresh_field('financial_delay');
																	me.frm.refresh_field('financial_discount_mode');
																	me.frm.refresh_field('financial_discount');
																	me.frm.refresh_field('financial_discount_pay_day');
																	me.frm.refresh_field('fast_financial_discount');
																	me.frm.refresh_field('fast_financial_discount_pay_day');
																	me.frm.refresh_field('payment_term_id');
																	me.frm.refresh_field('document_calc_mode');
																	me.frm.refresh_field('sales_agent_id');
																	me.frm.refresh_field('customer_family_1_id');
																	me.frm.refresh_field('customer_family_2_id');
																	me.frm.refresh_field('activity_detail');
																	me.frm.refresh_field('sector_detail');
																	me.frm.refresh_field('address_detail');
																	me.refresh_total_section();

																	if ((me.frm.doc.order_customer_id) && (me.frm.doc.order_customer_id.trim() != ''))
																	{
																		//me.set_query('order_customer_contact_id', '', me.path_customer + '.ioi_contact_get_all', '{"customer" : "' + me.frm.doc.order_customer_id + '"}');
																		me.set_query('order_customer_contact_id', '', me.path_search_definition + '.set_search_definition_filters', '{"customer" : "' + me.frm.doc.order_customer_id + '"}');
																		me.set_query('end_user_id', '', me.path_customer + '.ioi_customer_end_user_get_all', '{"end_user_id" : "' + me.frm.doc.order_customer_id + '"}');
																	}else
																	{	me.set_query('order_customer_contact_id', '', me.path_customer + '.ioi_contact_get_none');
																		me.set_query('end_user_id', '', me.path_customer + '.ioi_customer_end_user_get_none');
																	}

																	if ((me.frm.doc.delivery_customer_id) && (me.frm.doc.delivery_customer_id.trim() != ''))
																	{
																		//me.set_query('delivery_customer_contact_id', '', me.path_customer + '.ioi_contact_get_all', '{"customer" : "' + me.frm.doc.delivery_customer_id + '"}');
																		me.set_query('delivery_customer_contact_id', '', me.path_search_definition + '.set_search_definition_filters', '{"customer" : "' + me.frm.doc.delivery_customer_id + '"}');
																	}else
																	{	me.set_query('delivery_customer_contact_id', '', me.path_customer + '.ioi_contact_get_none');
																	}

																	if ((me.frm.doc.invoice_customer_id) && (me.frm.doc.invoice_customer_id.trim() != ''))
																	{
																		//me.set_query('invoice_customer_contact_id', '', me.path_customer + '.ioi_contact_get_all', '{"customer" : "' + me.frm.doc.invoice_customer_id + '"}');
																		me.set_query('invoice_customer_contact_id', '', me.path_search_definition + '.set_search_definition_filters', '{"customer" : "' + me.frm.doc.invoice_customer_id + '"}');
																	}else
																	{	me.set_query('invoice_customer_contact_id', '', me.path_customer + '.ioi_contact_get_none');
																	}
																});
															});
														});

													}
			});
		}else
		{	let me = this;
			this.#clear_all_delivery_customer_fields(me);
			this.set_query('delivery_customer_contact_id', '', me.path_customer + '.ioi_contact_get_none');
		}

		this.#sleep(1000).then(() => { this.set_label_when_error(); });
	}

	// ***************************************************************************************************************************************
	// Delivery Customer : advanced search
	// ***************************************************************************************************************************************
	search_delivery_customer()
	{
		let me = this;

		let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email, alternate_id';
		let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400, 250';
		let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email, Alternate id';
		let where = 'ioistatus = 2';
		let order_by = 'name asc';

		let fct_callback = function (return_value) {

			me.frm.doc.delivery_customer_id = return_value;
			me.frm.refresh_field('delivery_customer_id');
			me.select_delivery_customer_id();
		}

		silicon_ioi.ioiCommon.select_customer(fields, fields_len, fields_desc, where, order_by, fct_callback);

	}

	// ***************************************************************************************************************************************
	// Clear all invoice customer fields
	// ***************************************************************************************************************************************
	#clear_all_invoice_customer_fields(me)
	{
		me.frm.set_intro('', 'red');
		me.frm.doc.invoice_customer_id = '';
		me.frm.doc.invoice_customer_name = '';
		me.frm.doc.invoice_customer_contact_id = '';
		me.frm.doc.invoice_customer_contact_name = '';

		if (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
			me.frm.doc.intersite_transaction = 0;
			me.frm.doc.intersite_destination_site_id = '';
			me.refresh_intersite_transaction();
		}


		me.frm.refresh_field('address_detail');


		if (me.frm.doc.address_detail)
		{

			for(var i = me.frm.get_field("address_detail").grid.grid_rows.length-1; i >= 0; i--) {

				if (me.frm.get_field("address_detail").grid.grid_rows[i].doc.detail_mode.toUpperCase() == 'INVOICE') {
					me.frm.doc.address_detail[i].detail_mode = '';
					me.frm.get_field("address_detail").grid.grid_rows[i].remove();
					break;
				}
			}

			me.frm.refresh_field('address_detail');

		}

		if (me.frm.doc.log_detail)
		{
			let i = me.frm.get_field("log_detail").grid.grid_rows.length-1;

			while (i >= 0)
			{
				if ((((!me.frm.doc.order_customer_id) || ((me.frm.doc.order_customer_id) && (me.frm.doc.order_customer_id.trim() == ''))) &&
					((!me.frm.doc.delivery_customer_id) || ((me.frm.doc.delivery_customer_id) && (me.frm.doc.delivery_customer_id.trim() == '')))) ||
					(me.frm.doc.log_detail[i].from_fieldname.toUpperCase() == 'INVOICE_CUSTOMER_ID'))
				{
					me.frm.get_field("log_detail").grid.grid_rows[i].remove();
				}
				i--;
			}
		}

		me.frm.refresh_field('invoice_customer_id');
		me.frm.refresh_field('invoice_customer_name');
		me.frm.refresh_field('invoice_customer_contact_id');
		me.frm.refresh_field('invoice_customer_contact_name');
		me.frm.refresh_field('address_detail');
		me.frm.refresh_field('log_detail');
	}

	// ***************************************************************************************************************************************
	// Select invoice customer
	// ***************************************************************************************************************************************
	select_invoice_customer_id()
	{
		this.frm.refresh_field('order_customer_id');
		this.frm.refresh_field('delivery_customer_id');
		this.frm.refresh_field('invoice_customer_id');


		if ((this.frm.doc.invoice_customer_id) && (this.frm.doc.invoice_customer_id.trim() != ''))
		{
			let me = this;

			let current_order_customer_id = '';
			let current_delivery_customer_id = '';

			if ((this.frm.doc.order_customer_id) && (this.frm.doc.order_customer_id.trim() != ''))
			{
				current_order_customer_id = this.frm.doc.order_customer_id;
			}

			if ((this.frm.doc.delivery_customer_id) && (this.frm.doc.delivery_customer_id.trim() != ''))
			{
				current_delivery_customer_id = this.frm.doc.delivery_customer_id;
			}

            let dc_type = ''

            if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
            {	dc_type = 'QUOTE';
            }else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
            {   dc_type = 'ORDER';
            }else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
            {   dc_type = 'DELIVERY';
            }else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
            {   dc_type = 'INVOICE';
            }


			frappe.call({  	method: this.path_sales_document + '.ioi_sales_document_select_invoice_customer',
							args: 	{	"doctype": this.frm.doctype,
										"document_type": dc_type,
										"order_customer_id": current_order_customer_id,
										"delivery_customer_id": current_delivery_customer_id,
										"invoice_customer_id": this.frm.doc.invoice_customer_id,
										"document_site_id": this.frm.doc.site_id
							},
							async: false,
			   				callback: function(r)	{
														me.#clear_all_invoice_customer_fields(me);

								   						if (r.message.error == 1)
														{
															me.#set_trace(me, 'log_detail', r.message.error_from_fieldname, r.message.error_fieldname, r.message.error_message, 'N');
														}

														if (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
														{   me.frm.doc.language = r.message.invoice_customer_language;
														}

														let change_order_customer = true;
														let change_delivery_customer = true;

														if (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
															change_order_customer = true;
															change_delivery_customer = true;
														}else {

															if ((!current_order_customer_id) || ((current_order_customer_id) && (current_order_customer_id.trim() == ''))) {
																change_order_customer = true;
															}else{
																change_order_customer = false;
															}

															if ((!current_delivery_customer_id) || ((current_delivery_customer_id) && (current_delivery_customer_id.trim() == ''))) {
																change_delivery_customer = true;
															}else{
																change_delivery_customer = false;
															}
														}


														if (change_order_customer)
														{
															me.frm.doc.order_customer_id = r.message.order_customer_id;
															me.frm.doc.order_customer_name = r.message.order_customer_name;
															me.frm.doc.order_customer_contact_id = '';
															me.frm.doc.order_customer_contact_name = '';
														}

														if (change_delivery_customer)
														{
															me.frm.doc.delivery_customer_id = r.message.delivery_customer_id;
															me.frm.doc.delivery_customer_name = r.message.delivery_customer_name;
															me.frm.doc.delivery_customer_contact_id = '';
															me.frm.doc.delivery_customer_contact_name = '';
														}

														me.frm.doc.invoice_customer_id = r.message.invoice_customer_id;
														me.frm.doc.invoice_customer_name = r.message.invoice_customer_name;
														me.frm.doc.invoice_customer_contact_id = '';
														me.frm.doc.invoice_customer_contact_name = '';

														if (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
															me.frm.doc.intersite_transaction = r.message.intersite_transaction;
															me.frm.doc.intersite_destination_site_id = r.message.intersite_destination_site_id;
															me.refresh_intersite_transaction();
														}



														if (change_order_customer)
														{
															if ((r.message.sales_catalog_id) && (r.message.sales_catalog_id.trim() != ''))
															{
																me.frm.doc.sales_catalog_id = r.message.sales_catalog_id;
															}

															if ((r.message.sales_condition_id) && (r.message.sales_condition_id.trim() != ''))
															{
																me.frm.doc.sales_condition_id = r.message.sales_condition_id;
															}

															if ((r.message.specific_condition_id) && (r.message.specific_condition_id.trim() != ''))
															{
																me.frm.doc.specific_condition_id = r.message.specific_condition_id;
															}

															if ((r.message.delivery_condition_id) && (r.message.delivery_condition_id.trim() != ''))
															{
																me.frm.doc.delivery_condition_id = r.message.delivery_condition_id;
															}

															if ((r.message.currency_id) && (r.message.currency_id.trim() != ''))
															{
																me.frm.doc.currency_id = r.message.currency_id;
																me.frm.doc.currency_rate = r.message.currency_rate;
																me.frm.doc.currency_rate_inv = r.message.currency_rate_inv;
																me.frm.doc.currency_digit_rounding = r.message.currency_digit_rounding;
															}

															if ((me.customer_base_discount_in_header) && (me.customer_base_discount_in_header == 1))
															{
																me.frm.doc.base_discount = r.message.base_discount;
															}

															if ((me.customer_extra_discount_in_header) && (me.customer_extra_discount_in_header == 1))
															{
																me.frm.doc.extra_discount = r.message.extra_discount;
															}



															me.frm.doc.payment_term_id = r.message.payment_term_id;


															if ((me.frm.doctype.toUpperCase() == 'IOI SALES ORDER') || (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')) {

																if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
																	me.frm.doc.cashdesk_pay_on_order = r.message.pay_on_order;
																}

																if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
																	me.frm.doc.cashdesk_pay_on_delivery = r.message.pay_on_delivery;
																}

																if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
																	document.getElementById('ioi-sales-order-ioi_sales_order_tab_payment-tab').hidden = true;
																}else if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
																	document.getElementById('ioi-sales-delivery-ioi_sales_delivery_tab_payment-tab').hidden = true;
																}else{
																	document.getElementById('ioi-sales-invoice-ioi_sales_invoice_tab_payment-tab').hidden = true;
																}

																let show_payment_tab = 0;

																if (me.frm.is_new()) {
																	if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
																		if (me.journal_orders_cashdesk_active == 1) {
																			show_payment_tab = 1;
																		}
																	}else if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
																		if (me.journal_deliveries_cashdesk_active == 1) {
																			show_payment_tab = 1;
																		}
																	}else{
																		if (me.frm.doc.document_type.toUpperCase() == 'INVOICE') {
																			if (me.journal_invoices_cashdesk_active == 1) {
																				show_payment_tab = 1;
																			}
																		}else{
																			if (me.journal_cnotes_cashdesk_active == 1) {
																				show_payment_tab = 1;
																			}
																		}
																	}


																}else{
																	show_payment_tab = 1;
																}

																if (show_payment_tab == 1) {
																	if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
																		document.getElementById('ioi-sales-order-ioi_sales_order_tab_payment-tab').hidden = false;
																	}else if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
																		if (me.frm.doc.cashdesk_pay_on_delivery == 1) {
																			document.getElementById('ioi-sales-delivery-ioi_sales_delivery_tab_payment-tab').hidden = false;
																		}
																	}else{
																		document.getElementById('ioi-sales-invoice-ioi_sales_invoice_tab_payment-tab').hidden = false;
																	}
																}

																if ((me.frm.doc.site_id) && (me.frm.doc.site_id.trim() != '')) {

																	if ((me.frm.doc.currency_id) && (me.frm.doc.currency_id.trim() != '')) {
																		me.set_query('cashdesk_id', '', me.path_cash_desk + '.ioi_cash_desk_get', '{"site_id":"' + me.frm.doc.site_id + '", "currency_id":"' + me.frm.doc.currency_id + '"}');
																	}else {
																		me.set_query('cashdesk_id', '', me.path_cash_desk + '.ioi_cash_desk_get', '{"site_id":"' + me.frm.doc.site_id + '"}');
																	}
																}else {
																	if ((me.frm.doc.currency_id) && (me.frm.doc.currency_id.trim() != '')) {
																		me.set_query('cashdesk_id', '', me.path_cash_desk + '.ioi_cash_desk_get', '{"currency_id":"' + me.frm.doc.currency_id + '"}');
																	}else{
																		me.set_query('cashdesk_id', '', me.path_cash_desk + '.ioi_cash_desk_get', '');
																	}
																}


															}

															me.frm.doc.vat_type = r.message.vat_type;

															if ((me.frm.doc.vat_type == 'N') || (me.frm.doc.vat_type == 'NAT'))
															{
																if ((r.message.specific_vat_code_id) && (r.message.specific_vat_code_id != 0))
																{
																	me.frm.doc.spec_vat_rate = r.message.specific_vat_code_id;
																}
															}else
															{
																me.frm.doc.spec_vat_rate = 0;
															}

															me.frm.doc.vat_matching_id = r.message.vat_matching_id;


															me.frm.doc.financial_delay = r.message.financial_delay;
															me.frm.doc.financial_discount_mode = r.message.financial_discount_mode;
															me.frm.doc.financial_discount = r.message.financial_discount;
															me.frm.doc.financial_discount_pay_day = r.message.financial_discount_pay_day;
															me.frm.doc.fast_financial_discount = r.message.fast_financial_discount;
															me.frm.doc.fast_financial_discount_pay_day = r.message.fast_financial_discount_pay_day;
															me.frm.doc.document_calc_mode = r.message.document_calc_mode;
															me.frm.doc.sales_agent_id = r.message.sales_agent_id;
															me.frm.doc.customer_family_1_id = r.message.family_1_id;
															me.frm.doc.customer_family_2_id = r.message.family_2_id;

															me.frm.set_value('activity_detail', '');

															if (r.message.activity_detail)
															{
																for(var i = 0; i < r.message.activity_detail.length; i++)
																{
																	let row = me.frm.add_child('activity_detail');
																	row.activity_id = r.message.activity_detail[i].activity_id;
																}
															}

															me.frm.set_value('sector_detail', '');

															if (r.message.sector_detail)
															{
																for(var i = 0; i < r.message.sector_detail.length; i++)
																{
																	let row = me.frm.add_child('sector_detail');
																	row.sector_id = r.message.sector_detail[i].sector_id;
																}
															}
														}

														me.frm.refresh_field('address_detail');


														if ((me.frm.doc.invoice_customer_id) && (me.frm.doc.invoice_customer_id.trim() != ''))
														{
															if (me.frm.doc.address_detail)
															{
																for(var i = me.frm.get_field("address_detail").grid.grid_rows.length-1; i >= 0; i--) {

																	if (me.frm.get_field("address_detail").grid.grid_rows[i]) {
																		if (me.frm.get_field("address_detail").grid.grid_rows[i].doc.detail_mode.toUpperCase() == 'INVOICE') {
																			me.frm.get_field("address_detail").grid.grid_rows[i].remove();
																		}
																	}
																}
															}
														}
														me.#sleep(250).then(() => {

															if (change_delivery_customer) {

																if ((me.frm.doc.delivery_customer_id) && (me.frm.doc.delivery_customer_id.trim() != ''))
																{
																	if (me.frm.doc.address_detail)
																	{
																		for(var i = me.frm.get_field("address_detail").grid.grid_rows.length-1; i >= 0; i--) {
																			if (me.frm.get_field("address_detail").grid.grid_rows[i]) {
																				if (me.frm.get_field("address_detail").grid.grid_rows[i].doc.detail_mode.toUpperCase() == 'DELIVERY') {
																					me.frm.get_field("address_detail").grid.grid_rows[i].remove();
																				}
																			}
																		}

																	}
																}
															}

															me.#sleep(250).then(() => {

																if (change_order_customer) {

																	if ((me.frm.doc.order_customer_id) && (me.frm.doc.order_customer_id.trim() != ''))
																	{
																		if (me.frm.doc.address_detail)
																		{
																			for(var i = me.frm.get_field("address_detail").grid.grid_rows.length-1; i >= 0; i--) {
																				if (me.frm.get_field("address_detail").grid.grid_rows[i]) {
																					if (me.frm.get_field("address_detail").grid.grid_rows[i].doc.detail_mode.toUpperCase() == 'ORDER') {
																						me.frm.get_field("address_detail").grid.grid_rows[i].remove();

																					}
																				}
																			}
																		}
																	}
																}

																me.#sleep(250).then(() => {

																	if (change_order_customer)
																	{
																		if ((me.frm.doc.order_customer_id) && (me.frm.doc.order_customer_id.trim() != ''))
																		{
																			let order_address = me.frm.add_child('address_detail');
																			order_address.customer_id = me.frm.doc.order_customer_id;
																			order_address.detail_mode = 'ORDER';
																			order_address.full_name = r.message.order_customer_name;
																			order_address.detail_address = r.message.order_customer_address;
																			order_address.detail_postal_code_id = r.message.order_customer_postal_code_id;
																			order_address.detail_postal_code = r.message.order_customer_postal_code;
																			order_address.detail_city = r.message.order_customer_city;
																			order_address.detail_country_id = r.message.order_customer_country_id;
																			order_address.detail_phone_1 = r.message.order_customer_phone1;
																			order_address.detail_phone_2 = r.message.order_customer_phone2;
																			order_address.detail_email = r.message.order_customer_email;
																			order_address.latitude = r.message.order_customer_latitude;
																			order_address.longitude = r.message.order_customer_longitude;

																			me.frm.refresh_field('address_detail');
																		}
																	}

																	if (change_delivery_customer)
																	{
																		if ((me.frm.doc.delivery_customer_id) && (me.frm.doc.delivery_customer_id.trim() != ''))
																		{
																			let delivery_address = me.frm.add_child('address_detail');
																			delivery_address.customer_id = me.frm.doc.delivery_customer_id;
																			delivery_address.detail_mode = 'DELIVERY';
																			delivery_address.full_name = r.message.delivery_customer_name;
																			delivery_address.detail_address = r.message.delivery_customer_address;
																			delivery_address.detail_postal_code_id = r.message.delivery_customer_postal_code_id;
																			delivery_address.detail_postal_code = r.message.delivery_customer_postal_code;
																			delivery_address.detail_city = r.message.delivery_customer_city;
																			delivery_address.detail_country_id = r.message.delivery_customer_country_id;
																			delivery_address.detail_phone_1 = r.message.delivery_customer_phone1;
																			delivery_address.detail_phone_2 = r.message.delivery_customer_phone2;
																			delivery_address.detail_email = r.message.delivery_customer_email;
																			delivery_address.latitude = r.message.delivery_customer_latitude;
																			delivery_address.longitude = r.message.delivery_customer_longitude;

																			me.frm.refresh_field('address_detail');
																		}
																	}

																	if ((me.frm.doc.invoice_customer_id) && (me.frm.doc.invoice_customer_id.trim() != ''))
																	{
																		let invoice_address = me.frm.add_child('address_detail');
																		invoice_address.customer_id = me.frm.doc.invoice_customer_id;
																		invoice_address.detail_mode = 'INVOICE';
																		invoice_address.full_name = r.message.invoice_customer_name;
																		invoice_address.detail_address = r.message.invoice_customer_address;
																		invoice_address.detail_postal_code_id = r.message.invoice_customer_postal_code_id;
																		invoice_address.detail_postal_code = r.message.invoice_customer_postal_code;
																		invoice_address.detail_city = r.message.invoice_customer_city;
																		invoice_address.detail_country_id = r.message.invoice_customer_country_id;
																		invoice_address.detail_phone_1 = r.message.invoice_customer_phone1;
																		invoice_address.detail_phone_2 = r.message.invoice_customer_phone2;
																		invoice_address.detail_email = r.message.invoice_customer_email;
																		invoice_address.latitude = r.message.invoice_customer_latitude;
																		invoice_address.longitude = r.message.invoice_customer_longitude;

																		me.frm.refresh_field('address_detail');
																	}

																	me.frm.doc.def_base_discount = me.frm.doc.base_discount;
																	me.frm.doc.def_extra_discount = me.frm.doc.extra_discount;


																	me.frm.refresh_field('language');
																	me.frm.refresh_field('order_customer_id');
																	me.frm.refresh_field('order_customer_name');
																	me.frm.refresh_field('order_customer_contact_id');
																	me.frm.refresh_field('order_customer_contact_name');
																	me.frm.refresh_field('delivery_customer_id');
																	me.frm.refresh_field('delivery_customer_name');
																	me.frm.refresh_field('delivery_customer_contact_id');
																	me.frm.refresh_field('delivery_customer_contact_name');
																	me.frm.refresh_field('invoice_customer_id');
																	me.frm.refresh_field('invoice_customer_name');
																	me.frm.refresh_field('invoice_customer_contact_id');
																	me.frm.refresh_field('invoice_customer_contact_name');
																	me.frm.refresh_field('sales_catalog_id');
																	me.frm.refresh_field('sales_condition_id');
																	me.frm.refresh_field('specific_condition_id');
																	me.frm.refresh_field('delivery_condition_id');
																	me.frm.refresh_field('currency_id');
																	me.frm.refresh_field('currency_rate');
																	me.frm.refresh_field('currency_rate_inv');
																	me.frm.refresh_field('currency_digit_rounding');
																	me.frm.refresh_field('base_discount');
																	me.frm.refresh_field('extra_discount');
																	me.frm.refresh_field('def_base_discount');
																	me.frm.refresh_field('def_extra_discount');
																	me.frm.refresh_field('vat_type');
																	me.frm.refresh_field('vat_matching_id');
																	me.frm.refresh_field('spec_vat_rate');
																	me.frm.refresh_field('financial_delay');
																	me.frm.refresh_field('financial_discount_mode');
																	me.frm.refresh_field('financial_discount');
																	me.frm.refresh_field('financial_discount_pay_day');
																	me.frm.refresh_field('fast_financial_discount');
																	me.frm.refresh_field('fast_financial_discount_pay_day');
																	me.frm.refresh_field('payment_term_id');
																	me.frm.refresh_field('document_calc_mode');
																	me.frm.refresh_field('sales_agent_id');
																	me.frm.refresh_field('customer_family_1_id');
																	me.frm.refresh_field('customer_family_2_id');
																	me.frm.refresh_field('activity_detail');
																	me.frm.refresh_field('sector_detail');
																	me.frm.refresh_field('address_detail');
																	me.refresh_total_section();

																	if ((me.frm.doc.order_customer_id) && (me.frm.doc.order_customer_id.trim() != ''))
																	{
																		//me.set_query('order_customer_contact_id', '', me.path_customer + '.ioi_contact_get_all', '{"customer" : "' + me.frm.doc.order_customer_id + '"}');
																		me.set_query('order_customer_contact_id', '', me.path_search_definition + '.set_search_definition_filters', '{"customer" : "' + me.frm.doc.order_customer_id + '"}');
																		me.set_query('end_user_id', '', me.path_customer + '.ioi_customer_end_user_get_all', '{"end_user_id" : "' + me.frm.doc.order_customer_id + '"}');
																	}else
																	{	me.set_query('order_customer_contact_id', '', me.path_customer + '.ioi_contact_get_none');
																		me.set_query('end_user_id', '', me.path_customer + '.ioi_customer_end_user_get_none');
																	}

																	if ((me.frm.doc.delivery_customer_id) && (me.frm.doc.delivery_customer_id.trim() != ''))
																	{
																		//me.set_query('delivery_customer_contact_id', '', me.path_customer + '.ioi_contact_get_all', '{"customer" : "' + me.frm.doc.delivery_customer_id + '"}');
																		me.set_query('delivery_customer_contact_id', '', me.path_search_definition + '.set_search_definition_filters', '{"customer" : "' + me.frm.doc.delivery_customer_id + '"}');
																	}else
																	{	me.set_query('delivery_customer_contact_id', '', me.path_customer + '.ioi_contact_get_none');
																	}

																	if ((me.frm.doc.invoice_customer_id) && (me.frm.doc.invoice_customer_id.trim() != ''))
																	{
																		//me.set_query('invoice_customer_contact_id', '', me.path_customer + '.ioi_contact_get_all', '{"customer" : "' + me.frm.doc.invoice_customer_id + '"}');
																		me.set_query('invoice_customer_contact_id', '', me.path_search_definition + '.set_search_definition_filters', '{"customer" : "' + me.frm.doc.invoice_customer_id + '"}');
																	}else
																	{	me.set_query('invoice_customer_contact_id', '', me.path_customer + '.ioi_contact_get_none');
																	}
																});
															});
														});
													}
			});
		}else
		{	let me = this;
			this.#clear_all_invoice_customer_fields(me);
			this.set_query('invoice_customer_contact_id', '', me.path_customer + '.ioi_contact_get_none');
		}

		this.#sleep(1000).then(() => { this.set_label_when_error(); });
	}

	// ***************************************************************************************************************************************
	// Invoice Customer : advanced search
	// ***************************************************************************************************************************************
	search_invoice_customer()
	{
		let me = this;

		let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email, alternate_id';
		let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400, 250';
		let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email, Alternate id';
		let where = 'ioistatus = 2';
		let order_by = 'name asc';

		let fct_callback = function (return_value) {

			me.frm.doc.invoice_customer_id = return_value;
			me.frm.refresh_field('invoice_customer_id');
			me.select_invoice_customer_id();
		}

		silicon_ioi.ioiCommon.select_customer(fields, fields_len, fields_desc, where, order_by, fct_callback);
	}

	// ***************************************************************************************************************************************
	// Search carrier
	// ***************************************************************************************************************************************
	search_carrier()
	{
		let me = this;

		let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email, alternate_id';
		let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400, 250';
		let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email, Alternate id';
		let where = 'ioistatus = 2';
		let order_by = 'name asc';

		let fct_callback = function (return_value) {

			me.frm.set_value('carrier_id', return_value);
			me.frm.refresh_field('carrier_id');
		}

		silicon_ioi.ioiCommon.select_supplier(fields, fields_len, fields_desc, where, order_by, fct_callback);
	}





	// ***************************************************************************************************************************************
	// Select order customer contact
	// ***************************************************************************************************************************************
	select_order_customer_contact_id()
	{
		this.frm.set_intro('', '');

		if ((!this.frm.doc.order_customer_id) || (this.frm.doc.order_customer_id) && (this.frm.doc.order_customer_id.trim() == ''))
		{
			if (this.frm.doc.log_detail)
			{
				let i = this.frm.get_field("log_detail").grid.grid_rows.length-1;

				while (i >= 0)
				{
					if (this.frm.doc.log_detail[i].fieldname.toUpperCase() == 'ORDER_CUSTOMER_CONTACT_ID')
					{
						this.frm.get_field("log_detail").grid.grid_rows[i].remove();
						break;
					}
					i--;
				}
			}

			this.#set_trace(this, 'log_detail', "order_customer_id", "order_customer_contact_id", __('Order customer is mandatory for selecting a contact'), 'N');

			this.frm.doc.order_customer_contact_id = '';
			this.frm.refresh_field('order_customer_contact_id');
		}else
		{
			if ((this.frm.doc.order_customer_contact_id) && (this.frm.doc.order_customer_contact_id.trim() != ''))
			{
				let me = this;

				frappe.call({  	method: this.path_customer + '.ioi_customer_is_contact_for_customer',
								args: 	{	"customer_id": this.frm.doc.order_customer_id,
											"contact_id": this.frm.doc.order_customer_contact_id
										},
								async: false,
			   					callback: function(r)	{
															if (me.frm.doc.log_detail)
															{
																let i = me.frm.get_field("log_detail").grid.grid_rows.length-1;

																while (i >= 0)
																{
																	if (me.frm.doc.log_detail[i].fieldname.toUpperCase() == 'ORDER_CUSTOMER_CONTACT_ID')
																	{
																		me.frm.get_field("log_detail").grid.grid_rows[i].remove();
																		break;
																	}
																	i--;
																}
															}




															if (r.message == 0)
															{
																me.#set_trace(me, 'log_detail', "order_customer_id", "order_customer_contact_id", __('This order contact is not linked to the order customer'), 'N');

																me.frm.doc.order_customer_contact_id = '';
																me.frm.refresh_field('order_customer_contact_id');
															}else
															{
																if ((me.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER'))
																{
																	frappe.call({  	method: me.path_customer + '.ioi_customer_get_contact_language',
																					args: 	{	"customer_id": me.frm.doc.order_customer_id,
																								"contact_id": me.frm.doc.order_customer_contact_id
																							},
																					async: false,
																	   				callback: function(r)	{
																												if (r.message[0].language)
																												{
																													if ((r.message[0].language != null) && (r.message[0].language.trim() != ''))
																													{
																														me.frm.doc.language = r.message[0].language;
																														me.frm.refresh_field('language');
																													}
																												}
																											}
																	});


																}
															}
							   							}
				});
			}
		}

		this.#sleep(1000).then(() => { this.set_label_when_error(); });
	}

	// ***************************************************************************************************************************************
	// Select delivery customer contact
	// ***************************************************************************************************************************************
	select_delivery_customer_contact_id()
	{
		this.frm.set_intro('', 'red');

		if ((!this.frm.doc.delivery_customer_id) || (this.frm.doc.delivery_customer_id) && (this.frm.doc.delivery_customer_id.trim() == ''))
		{
			if (this.frm.doc.log_detail)
			{
				let i = this.frm.get_field("log_detail").grid.grid_rows.length-1;

				while (i >= 0)
				{
					if (this.frm.doc.log_detail[i].fieldname.toUpperCase() == 'DELIVERY_CUSTOMER_CONTACT_ID')
					{
						this.frm.get_field("log_detail").grid.grid_rows[i].remove();
						break;
					}
					i--;
				}
			}

			this.#set_trace(this, 'log_detail', "delivery_customer_id", "delivery_customer_contact_id", __('Delivery customer is mandatory for selecting a contact'), 'N');

			this.frm.doc.delivery_customer_contact_id = '';
			this.frm.refresh_field('delivery_customer_contact_id');

		}else
		{
			if ((this.frm.doc.delivery_customer_contact_id) && (this.frm.doc.delivery_customer_contact_id.trim() != ''))
			{
				let me = this;

				frappe.call({  	method: this.path_customer + '.ioi_customer_is_contact_for_customer',
								args: 	{	"customer_id": this.frm.doc.delivery_customer_id,
											"contact_id": this.frm.doc.delivery_customer_contact_id
										},
								async: false,
			   					callback: function(r)	{
															if (me.frm.doc.log_detail)
															{
																let i = me.frm.get_field("log_detail").grid.grid_rows.length-1;

																while (i >= 0)
																{
																	if (me.frm.doc.log_detail[i].fieldname.toUpperCase() == 'DELIVERY_CUSTOMER_CONTACT_ID')
																	{
																		me.frm.get_field("log_detail").grid.grid_rows[i].remove();
																		break;
																	}
																	i--;
																}
															}

															if (r.message == 0)
															{
																me.#set_trace(me, 'log_detail', "delivery_customer_id", "delivery_customer_contact_id", __('This delivery contact is not linked to the delivery customer'), 'N');

																me.frm.doc.delivery_customer_contact_id = '';
																me.frm.refresh_field('delivery_customer_contact_id');
															}else
															{
																if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
																{
																	frappe.call({  	method: me.path_customer + '.ioi_customer_get_contact_language',
																					args: 	{	"customer_id": me.frm.doc.delivery_customer_id,
																								"contact_id": me.frm.doc.delivery_customer_contact_id
																							},
																					async: false,
																	   				callback: function(r)	{
																												if (r.message[0].language)
																												{
																													if ((r.message[0].language != null) && (r.message[0].language.trim() != ''))
																													{
																														me.frm.doc.language = r.message[0].language;
																														me.frm.refresh_field('language');
																													}
																												}
																											}
																	});


																}
															}
							   							}
				});
			}
		}

		this.#sleep(1000).then(() => { this.set_label_when_error(); });

	}

	// ***************************************************************************************************************************************
	// Select invoice customer contact
	// ***************************************************************************************************************************************
	select_invoice_customer_contact_id()
	{
		this.frm.set_intro('', 'red');

		if ((!this.frm.doc.invoice_customer_id) || (this.frm.doc.invoice_customer_id) && (this.frm.doc.invoice_customer_id.trim() == ''))
		{
			if (this.frm.doc.log_detail)
			{
				let i = this.frm.get_field("log_detail").grid.grid_rows.length-1;

				while (i >= 0)
				{
					if (this.frm.doc.log_detail[i].fieldname.toUpperCase() == 'INVOICE_CUSTOMER_CONTACT_ID')
					{
						this.frm.get_field("log_detail").grid.grid_rows[i].remove();
						break;
					}
					i--;
				}
			}

			this.#set_trace(this, 'log_detail', "invoice_customer_id", "invoice_customer_contact_id", __('Invoice customer is mandatory for selecting a contact'), 'N');

			this.frm.doc.invoice_customer_contact_id = '';
			this.frm.refresh_field('invoice_customer_contact_id');
		}else
		{
			if ((this.frm.doc.invoice_customer_contact_id) && (this.frm.doc.invoice_customer_contact_id.trim() != ''))
			{
				let me = this;

				frappe.call({  	method: this.path_customer + '.ioi_customer_is_contact_for_customer',
								args: 	{	"customer_id": this.frm.doc.invoice_customer_id,
											"contact_id": this.frm.doc.invoice_customer_contact_id
										},
								async: false,
			   					callback: function(r)	{
															if (me.frm.doc.log_detail)
															{
																let i = me.frm.get_field("log_detail").grid.grid_rows.length-1;

																while (i >= 0)
																{
																	if (me.frm.doc.log_detail[i].fieldname.toUpperCase() == 'INVOICE_CUSTOMER_CONTACT_ID')
																	{
																		me.frm.get_field("log_detail").grid.grid_rows[i].remove();
																		break;
																	}
																	i--;
																}
															}

															if (r.message == 0)
															{
																me.#set_trace(me, 'log_detail', "invoice_customer_id", "invoice_customer_contact_id", __('This invoice contact is not linked to the invoice customer'), 'N');

																me.frm.doc.invoice_customer_contact_id = '';
																me.frm.refresh_field('invoice_customer_contact_id');
															}else
															{
																if (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
																{
																	frappe.call({  	method: me.path_customer + '.ioi_customer_get_contact_language',
																					args: 	{	"customer_id": me.frm.doc.invoice_customer_id,
																								"contact_id": me.frm.doc.invoice_customer_contact_id
																							},
																					async: false,
																	   				callback: function(r)	{
																												if (r.message[0].language)
																												{
																													if ((r.message[0].language != null) && (r.message[0].language.trim() != ''))
																													{
																														me.frm.doc.language = r.message[0].language;
																														me.frm.refresh_field('language');
																													}
																												}
																											}
																	});


																}
															}
							   							}
				});
			}

		}

		this.#sleep(1000).then(() => { this.set_label_when_error(); });
	}




	search_order_customer_contact()
	{
		let me = this;

		if ((!me.frm.doc.order_customer_id) || ((me.frm.doc.order_customer_id) && (me.frm.doc.order_customer_id.trim() == ''))) {

			frappe.msgprint({title: __("Message"), message: __('Order customer is mandatory'), indicator: "red"});
			raise;
		}


		let fields = 'name, customer, first_name, last_name, address, function, phone, email_id';
		let fields_len = '200, 200, 300, 300, 350, 250, 150, 400, 200';
		let fields_desc = 'Identification, Customer, First name, Last name, Address, Function, Phone, Email';
		let where = '';
		let order_by = 'name asc';

		let what = 'CUSTOMER';
		let parent = me.frm.doc.order_customer_id;


		let fct_callback = function (parent_value, return_value) {

			me.frm.set_value('order_customer_contact_id', return_value);
		}

		silicon_ioi.ioiCommon.select_contact(fields, fields_len, fields_desc, where, order_by, fct_callback, what, parent);

	}

	search_delivery_customer_contact()
	{
		let me = this;

		if ((!me.frm.doc.delivery_customer_id) || ((me.frm.doc.delivery_customer_id) && (me.frm.doc.delivery_customer_id.trim() == ''))) {

			frappe.msgprint({title: __("Message"), message: __('Delivery customer is mandatory'), indicator: "red"});
			raise;
		}


		let fields = 'name, customer, first_name, last_name, address, function, phone, email_id';
		let fields_len = '200, 200, 300, 300, 350, 250, 150, 400, 200';
		let fields_desc = 'Identification, Customer, First name, Last name, Address, Function, Phone, Email';
		let where = '';
		let order_by = 'name asc';

		let what = 'CUSTOMER';
		let parent = me.frm.doc.delivery_customer_id;


		let fct_callback = function (parent_value, return_value) {

			me.frm.set_value('delivery_customer_contact_id', return_value);
		}

		silicon_ioi.ioiCommon.select_contact(fields, fields_len, fields_desc, where, order_by, fct_callback, what, parent);

	}

	search_invoice_customer_contact()
	{
		let me = this;

		if ((!me.frm.doc.invoice_customer_id) || ((me.frm.doc.invoice_customer_id) && (me.frm.doc.invoice_customer_id.trim() == ''))) {

			frappe.msgprint({title: __("Message"), message: __('Invoice customer is mandatory'), indicator: "red"});
			raise;
		}


		let fields = 'name, customer, first_name, last_name, address, function, phone, email_id';
		let fields_len = '200, 200, 300, 300, 350, 250, 150, 400, 200';
		let fields_desc = 'Identification, Customer, First name, Last name, Address, Function, Phone, Email';
		let where = '';
		let order_by = 'name asc';

		let what = 'CUSTOMER';
		let parent = me.frm.doc.invoice_customer_id;


		let fct_callback = function (parent_value, return_value) {

			me.frm.set_value('invoice_customer_contact_id', return_value);
		}

		silicon_ioi.ioiCommon.select_contact(fields, fields_len, fields_desc, where, order_by, fct_callback, what, parent);

	}


	// ***************************************************************************************************************************************
	// Select carrier
	// ***************************************************************************************************************************************
	select_carrier_id()
	{
		this.frm.refresh_field('carrier_id');

		if ((this.frm.doc.carrier_id) && (this.frm.doc.carrier_id.trim() != ''))
		{
			this.frm.doc.carrier_contact_id = '';
			this.frm.doc.carrier_contact_name = '';
			this.frm.refresh_field('carrier_contact_id');
			this.frm.refresh_field('carrier_contact_name');

			this.set_query('carrier_contact_id', '', this.path_supplier + '.ioi_carrier_contact_get_all', '{"supplier" : "' + this.frm.doc.carrier_id + '"}');

		}else
		{	this.set_query('carrier_contact_id', '', this.path_supplier + '.ioi_carrier_contact_get_none');

			this.frm.doc.carrier_id = '';
			this.frm.doc.carrier_name = '';
			this.frm.doc.carrier_contact_id = '';
			this.frm.doc.carrier_contact_name = '';

			this.frm.refresh_field('carrier_id');
			this.frm.refresh_field('carrier_name');
			this.frm.refresh_field('carrier_contact_id');
			this.frm.refresh_field('carrier_contact_name');
		}
	}


	// ***************************************************************************************************************************************
	// Select document date
	// ***************************************************************************************************************************************
	select_document_date()
	{
		if ((this.frm.doc.document_date) && (this.frm.doc.document_date.trim() != ''))
		{
			this.frm.set_intro('', 'red');

			if (this.frm.doc.log_detail)
			{
				let i = this.frm.get_field("log_detail").grid.grid_rows.length-1;

				while (i >= 0)
				{
					if (this.frm.doc.log_detail[i].fieldname.toUpperCase() == 'DOCUMENT_DATE')
					{
						this.frm.get_field("log_detail").grid.grid_rows[i].remove();
						break;
					}
					i--;
				}
			}

			this.#sleep(1000).then(() => { this.set_label_when_error(); });
		}
	}

	// ***************************************************************************************************************************************
	// Select a catalog id
	// ***************************************************************************************************************************************
	select_sales_catalog()
	{
		if ((this.frm.doc.sales_catalog_id) && (this.frm.doc.sales_catalog_id.trim() != ''))
		{
			this.frm.set_intro('', 'red');

			if (this.frm.doc.log_detail)
			{
				let i = this.frm.get_field("log_detail").grid.grid_rows.length-1;

				while (i >= 0)
				{
					if (this.frm.doc.log_detail[i].fieldname.toUpperCase() == 'SALES_CATALOG_ID')
					{
						this.frm.get_field("log_detail").grid.grid_rows[i].remove();
						break;
					}
					i--;
				}
			}

			if (this.frm.doc.line_detail)
			{
				let found = false;

				for (var i = 0; i < this.frm.doc.line_detail.length; i++)
				{
					if ((this.frm.doc.line_detail[i].item_id) && (this.frm.doc.line_detail[i].item_id.trim() != ''))
					{
						if ((this.frm.doc.line_detail[i].sales_condition_id) && (this.frm.doc.line_detail[i].sales_condition_id != this.customer_manual_sales_condition_id))
						{
							found = true;
							break;
						}
					}
				}

				if (found)
				{
					this.frm.set_intro('Calatog has been changed, update line prices please (Action menu -> Update line prices)', 'orange');
				}
			}


		}
		this.#sleep(1000).then(() => { this.set_label_when_error(); });
	}

	// ***************************************************************************************************************************************
	// Select item sales condition
	// ***************************************************************************************************************************************
	select_item_sales_condition(frm, cdt, cdn)
	{
		this.frm.refresh_field('line_detail');
		let item = locals[cdt][cdn];

		if ((item.sales_condition_id) && (item.sales_condition_id.trim() != ''))
		{
			this.frm.set_intro('', 'red');

			if (this.frm.doc.log_detail)
			{
				let i = this.frm.get_field("log_detail").grid.grid_rows.length-1;

				while (i >= 0)
				{
					if (this.frm.doc.log_detail[i].fieldname.toUpperCase() == 'ITEM_SALES_CONDITION_ID')
					{
						this.frm.get_field("log_detail").grid.grid_rows[i].remove();
						break;
					}
					i--;
				}
			}

			if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
			{
				if (this.frm.doc.document_type.toUpperCase() == 'CONTRACT')
				{
					if (item.sales_condition_id == this.customer_manual_sales_condition_id)
					{
						item.sales_condition_id = 'NORMAL'
						this.frm.refresh_field('line_detail');
					}
				}
			}


			if (item.sales_condition_id != this.customer_manual_sales_condition_id)
			{
				item.manual_price = 0;
			}else
			{
				item.manual_price = 1;
			}

		}
		this.#sleep(1000).then(() => { this.set_label_when_error(); });
		silicon_ioi.doctype.ioiSalesDocumentDocType.set_static_grid_layout(null);


		if ((!item.sales_condition_id) || ((item.sales_condition_id) && (item.sales_condition_id.trim() == '')))
		{
			item.manual_price = 0;
		}
	}


	// ***************************************************************************************************************************************
	// Format Child Address form
	// ***************************************************************************************************************************************
	format_address_form(frm, cdt, cdn)
	{
		this.#fill_address_detail_mode();

		this.#build_banner_detail(frm, cdt, cdn, 'address_detail', 'html_address', 'Address');
		this.#build_banner_detail(frm, cdt, cdn, 'address_detail', 'html_phone', 'Phone & email');
		this.#build_banner_detail(frm, cdt, cdn, 'address_detail', 'html_remark', 'Remark');

		if (!this.detail_mode_readonly)
		{	this.frm.fields_dict['address_detail'].grid.update_docfield_property('detail_mode', 'read_only', 0);
		}else
		{ 	this.frm.fields_dict['address_detail'].grid.update_docfield_property('detail_mode', 'read_only', 1);
		}
		this.detail_mode_readonly = true;

		this.#refresh_spacer(cdt, cdn);

		let item = locals[cdt][cdn];

		if (this.frm.get_field('address_detail').grid.grid_rows[item.idx-1].grid_form)
		{
			for (var i = 0; i < this.frm.get_field('address_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections.length; i++)
			{
				if (this.frm.get_field('address_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head)
				{
					this.frm.get_field('address_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.attr('data-custom-section-head', 'true');
					this.frm.get_field('address_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.css('border-radius', '6px');
					this.frm.get_field('address_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.css('padding', '4px');

					//this.frm.get_field('address_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.click();
				}
			}

		}


		silicon_ioi.doctype.ioiSalesDocumentDocType.refresh_map(item, cdt, cdn, 1000);

	}

	// ***************************************************************************************************************************************
	// Format spacer
	// ***************************************************************************************************************************************
	#refresh_spacer(cdt, cdn)
	{
		let item = locals[cdt][cdn];

		let html = '<div id="spacer" style="width:100%; height:250px"></div>';

		let selected_row = this.frm.get_field('address_detail').grid.grid_rows_by_docname[item.name];
		selected_row.get_field('html_spacer').set_value(html);
	}

	static refresh_map(item, cdt, cdn, timer)
	{
		if (((item.latitude) && (item.latitude != 0)) && ((item.longitude) && (item.longitude != 0)))
		{
			silicon_ioi.ioiCommon.show_map_detail(item, cdt, cdn, 'address_detail', 'html_map', timer, item.latitude, item.longitude);
		}else
		{
			silicon_ioi.ioiCommon.clear_map_detail(item, cdt, cdn, 'address_detail', 'html_map');
		}
	}


	// ***************************************************************************************************************************************
	// Callback : get coordinates from address
	// ***************************************************************************************************************************************
	static get_coordinates_from_address_callback(item, cdt, cdn, latitude, longitude)
	{
		if (!cur_frm.is_dirty())
		{
			cur_frm.dirty();
		}

		item.latitude = latitude;
		item.longitude = longitude;

		cur_frm.refresh_field('address_detail');


		silicon_ioi.doctype.ioiSalesDocumentDocType.refresh_map(item, 500);
	}

	// ***************************************************************************************************************************************
	// Callback : get address from coordinates
	// ***************************************************************************************************************************************
	static get_address_from_coordinates_callback(item, cdt, cdn, address, postalcode, city, countrycode)
	{
		if (!cur_frm.is_dirty())
		{
			cur_frm.dirty();
		}

		item.detail_address = address;
		item.detail_city = city;
		item.detail_postal_code = postalcode;
		item.detail_country_id = countrycode;

		item.detail_postal_code_id = countrycode + ' • ' + postalcode + ' • ' + city;

		cur_frm.refresh_field('address_detail');

		silicon_ioi.doctype.ioiSalesDocumentDocType.refresh_map(item, 500);
	}



	#fill_address_detail_mode()
	{
		this.address_mode = [];
		this.address_mode[0] = ['ORDER'];
		this.address_mode[1] = ['DELIVERY'];
		this.address_mode[2] = ['INVOICE'];
		this.address_mode[3] = ['SITE'];
		this.address_mode[4] = ['POSTAL'];
		this.address_mode[5] = ['INFO'];

		let options = '[';

		for (var i = 0; i < this.address_mode.length; i++)
		{
			options += '{"label": "' + this.address_mode[i] + '", "value": "' + this.address_mode[i] + '"}';

			if (i != this.address_mode.length-1)
			{
				options += ',';
			}
		}

		options += ']';

		this.frm.fields_dict.address_detail.grid.update_docfield_property("detail_mode", "options", JSON.parse(options));
	}

	// ***************************************************************************************************************************************
	// Format banner detail
	// ***************************************************************************************************************************************
	#build_banner_detail(frm, cdt, cdn, childdoc, fieldname, description, br=true)
	{
		let html = '';
		let item = locals[cdt][cdn];
		let selected_row = this.frm.get_field(childdoc).grid.grid_rows_by_docname[item.name];

		html += '<div style="height:30px; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;" data-custom-section-head="true">';
		html += '<b><font style="font-size:14px;color: #1F272E">&nbsp;'+ __(description) +'</b></font>';
		html += '</div>';

		if (br)
		{
			html += '<div style="height:8px;"></div>';
		}

		selected_row.get_field(fieldname).set_value(html);
	}


	// ***************************************************************************************************************************************
	// Addresses : get city and country of detail_postal_code_id to fill detail_city and detail_country_id
	// ***************************************************************************************************************************************
	get_addresses_city_country(frm, cdt, cdn)
	{
		let address_item = locals[cdt][cdn];

		if ((address_item.detail_postal_code_id) && (address_item.detail_postal_code_id.trim() != ''))
		{
			let s = address_item.detail_postal_code_id;
			let country = '';
			let postal_code = '';
			let city = '';

			country = s.substring(0, s.indexOf('•'));

			s = s.substring(s.indexOf('•')+1, s.length);
			postal_code = s.substring(0, s.indexOf('•'));

			s = s.substring(s.indexOf('•')+1, s.length);

			city = s.trim();

			address_item.detail_country_id = country.trim();
			address_item.detail_city = city.trim();
			address_item.detail_postal_code = postal_code.trim();
		}

		this.frm.refresh_field('address_detail');
	}

	// ***************************************************************************************************************************************
	// Addresses : new record
	// ***************************************************************************************************************************************
	addresses_new_record(frm, cdt, cdn)
	{
		let item = locals[cdt][cdn];
		item.detail_mode = 'INFO';
		this.detail_mode_readonly = false;

		if (document.getElementById('hidden_can_edit_document').value == '0')
		{	item.can_remove = 2;
			this.frm.get_field("address_detail").grid.grid_rows[item.idx-1].remove();
		}

	}
	// ***************************************************************************************************************************************
	// Addresses : before delete record
	// ***************************************************************************************************************************************
	addresses_detail_before_remove(frm, cdt, cdn)
	{
		if (document.getElementById('hidden_can_edit_document').value == '0')
		{
			let item = locals[cdt][cdn];

			if (item.can_remove != 2)
			{	raise;
			}
		}
	}

	// ***************************************************************************************************************************************
	// Addresses : get customer address
	// ***************************************************************************************************************************************

	addresses_detail_get_customer_address(frm, cdt, cdn)
	{
		let line = locals[cdt][cdn]

		let method = this.path_sales_document + '.ioi_sales_document_get_customer_address';

		let me = this;

		frappe.call({  	method: method,
						args: {"customer_id": line.customer_id},
						async: false,
						callback:function(r) {

							line.full_name = r.message.full_name;
							line.detail_address = r.message.address;
							line.detail_postal_code_id = r.message.postal_code_id;
							line.detail_postal_code = r.message.postal_code;
							line.detail_city = r.message.city;
							line.detail_country_id = r.message.country_id;
							line.detail_phone_1 = r.message.phone1;
							line.detail_phone_2 = r.message.phone2;
							line.detail_email = r.message.email;
							line.latitude = r.message.latitude;
							line.longitude = r.message.longitude;

							me.frm.refresh_field('address_detail');

						}
		});

	}



	// ***************************************************************************************************************************************
	// Error Log : cancel add row
	// ***************************************************************************************************************************************
	error_log_cancel_add(frm, cdt, cdn)
	{
		let item = locals[cdt][cdn];
		this.frm.get_field("log_detail").grid.grid_rows_by_docname[item.name].remove();
		this.frm.refresh_field('log_detail');
	}

	// ***************************************************************************************************************************************
	// Currency : get rate
	// ***************************************************************************************************************************************
	currency_get_rate()
	{
		if ((!this.frm.doc.currency_id) || ((this.frm.doc.currency_id) && (this.frm.doc.currency_id.trim() == '')))
		{
			this.frm.doc.currency_id = this.currency_id;
			this.frm.doc.currency_rate = this.currency_rate;
			this.frm.doc.currency_rate_inv = this.currency_rate_inv;
			this.frm.doc.currency_digit_rounding = this.currency_digit_rounding;
		}else
		{	let me = this;
			let method = this.path_currency + '.ioi_currency_get_data';

			frappe.call({  	method: method,
							args: {"currency_id": this.frm.doc.currency_id},
							async: false,
							callback:function(r)	{
														me.frm.doc.currency_rate = r.message.sales_rate;
														me.check_currency_rate();
														me.frm.doc.currency_rate_inv = r.message.sales_rate_inv;
														me.check_currency_rate_inv();
														me.frm.doc.currency_digit_rounding = r.message.decimals;
													}
			});
		}

		this.frm.refresh_field('currency_id');
		this.frm.refresh_field('currency_rate');
		this.frm.refresh_field('currency_rate_inv');
		this.frm.refresh_field('currency_digit_number');
		this.refresh_total_section();
	}

	// ***************************************************************************************************************************************
	// Currency : check curency rate
	// ***************************************************************************************************************************************
	check_currency_rate()
	{
		if ((!this.frm.doc.currency_rate) || ((this.frm.doc.currency_rate) && (this.frm.doc.currency_rate == 0)))
		{
			this.frm.doc.currency_rate = 1;
		}
		this.check_currency_rate_inv();
		this.frm.refresh_field('currency_rate');
	}

	// ***************************************************************************************************************************************
	// Currency : check curency rate (inv)
	// ***************************************************************************************************************************************
	check_currency_rate_inv()
	{
		if ((!this.frm.doc.currency_rate) || ((this.frm.doc.currency_rate) && (this.frm.doc.currency_rate == 0)))
		{
			this.frm.doc.currency_rate = 1;
		}

		if ((!this.frm.doc.currency_rate_inv) || ((this.frm.doc.currency_rate_inv) && (this.frm.doc.currency_rate_inv == 0)))
		{
			this.frm.doc.currency_rate_inv = 1;
		}
		this.frm.doc.currency_rate_inv = 1 / this.frm.doc.currency_rate;

		this.frm.refresh_field('currency_rate');
		this.frm.refresh_field('currency_rate_inv');
	}

	// ***************************************************************************************************************************************
	// Populate line
	// ***************************************************************************************************************************************
	populate_line(frm, cdt, cdn, source_type = 'ITEM_ID', unit_id = '')
	{

		this.frm.refresh_field('line_detail');
		let item = locals[cdt][cdn];
		let can_populate_line = false;

		this.frm.set_intro('', 'red');

		if (this.frm.doc.log_detail)
		{
			let i = this.frm.get_field("log_detail").grid.grid_rows.length-1;

			while (i >= 0)
			{
				if ((this.frm.doc.log_detail[i].fieldname.toUpperCase() == 'ORDER_CUSTOMER_ID') ||
					(this.frm.doc.log_detail[i].fieldname.toUpperCase() == 'SALES_CATALOG_ID') ||
					(this.frm.doc.log_detail[i].fieldname.toUpperCase() == 'CURRENCY_ID') ||
					(this.frm.doc.log_detail[i].fieldname.toUpperCase() == 'DOCUMENT_DATE') ||
					(this.frm.doc.log_detail[i].fieldname.toUpperCase() == 'ITEM_SALES_CONDITION_ID'))
				{
					this.frm.get_field("log_detail").grid.grid_rows[i].remove();
				}
				i--;
			}
		}


		let item_id = ''
		let barcode_ref = ''

		if ((source_type.toUpperCase() == 'ITEM_ID') || (source_type.toUpperCase() == 'QTY'))
		{
			if ((item.item_id) && (item.item_id.trim() != ''))
			{	can_populate_line = true;
				item_id = item.item_id;

			}else
			{
				if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
					item.item_mode = 0;
				}
				item.unit_price_source = '';
				item.unit_price_tvac_source = '';
				item.fixed_price_source = '';
				item.base_discount_source = '';
				item.extra_discount_source = '';
				item.discount_value_source = '';
				item.min_order_qty_source = '';
				item.multiple_order_qty_source = '';
				item.free_part_num_source = '';
				item.free_part_den_source = '';

				item.index_id = '';
				item.index_coef = 0;
				item.index_ref_date = '';
				item.index_ref_value = 0
				item.index_cur_value = 0;
				item.decimals_allowed = 1;

				item.supplier_fnd = '';
				item.purch_unit_price_fnd = 0;


				this.format_detail_form(frm, cdt, cdn)


			}

			if (source_type.toUpperCase() == 'QTY') {

				if (item.withme == 1) {
					can_populate_line = false;
				}

			}
		}else
		{
			if ((item.barcode_reference) && (item.barcode_reference.trim() != ''))
			{	can_populate_line = true;
				barcode_ref = item.barcode_reference;
			}

		}

		if (can_populate_line)
		{
			if ((!this.frm.doc.order_customer_id) || ((this.frm.doc.order_customer_id) && (this.frm.doc.order_customer_id.trim() == '')))
			{
				this.#set_trace(this, 'log_detail', "order_customer_id", "order_customer_id", __('Order customer has to be filled'), 'N');
				item.item_id = '';
				this.frm.refresh_field('line_detail');
				can_populate_line = false;
			}

			if ((can_populate_line) && ((!this.frm.doc.sales_catalog_id) || ((this.frm.doc.sales_catalog_id) && (this.frm.doc.sales_catalog_id.trim() == ''))))
			{
				this.#set_trace(this, 'log_detail', "sales_catalog_id", "sales_catalog_id", __('Sales catalog has to be filled'), 'N');
				item.item_id = '';
				this.frm.refresh_field('line_detail');
				can_populate_line = false;
			}

			if ((can_populate_line) && ((!item.sales_condition_id) || ((item.sales_condition_id) && (item.sales_condition_id.trim() == ''))))
			{
				this.#set_trace(this, 'log_detail', "item_sales_condition_id", "item_sales_condition_id", __('Sales condition (item line) has to be filled'), 'N');
				item.item_id = '';
				this.frm.refresh_field('line_detail');
				can_populate_line = false;
			}

			if ((can_populate_line) && ((!this.frm.doc.document_date) || ((this.frm.doc.document_date) && (this.frm.doc.document_date.trim() == ''))))
			{
				this.#set_trace(this, 'log_detail', "document_date", "document_date", __('Document date has to be filled'), 'N');
				item.item_id = '';
				this.frm.refresh_field('line_detail');
				can_populate_line = false;
			}


			if ((can_populate_line) && ((!this.frm.doc.currency_id) || ((this.frm.doc.currency_id) && (this.frm.doc.currency_id.trim() == ''))))
			{
				this.#set_trace(this, 'log_detail', "currency_id", "currency_id", __('Currency has to be filled'), 'N');
				item.item_id = '';
				this.frm.refresh_field('line_detail');
				can_populate_line = false;
			}

            let dc_type = '';
            let qty = 0;

            if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
            {
                dc_type = 'QUOTE';

			    if (!item.quoted_qty)
			    {
    				item.quoted_qty = 0;
                    qty = 0;
	    		}else
                {
                    qty = item.quoted_qty;
                }
            }else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
            {
                dc_type = 'ORDER';

			    if (!item.ordered_qty)
			    {
    				item.ordered_qty = 0;
                    qty = 0;
	    		}else
                {
                    qty = item.ordered_qty;
                }
            }else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
            {
                dc_type = 'DELIVERY';

			    if (!item.delivered_qty)
			    {
    				item.delivered_qty = 0;
                    qty = 0;
	    		}else
                {
                    qty = item.delivered_qty;
                }
            }else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
            {
                dc_type = 'INVOICE';

			    if (!item.invoiced_qty)
			    {
    				item.invoiced_qty = 0;
                    qty = 0;
	    		}else
                {
                    qty = item.invoiced_qty;
                }
            }

			if (can_populate_line)
			{
				let me = this;

				let is_sales_pos = 0;

				if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {

					if ((this.frm.doc.created_from) && (this.frm.doc.created_from.trim() != '')) {
						if (this.frm.doc.created_from.toUpperCase() == 'IOI SALES POS') {
							is_sales_pos = 1;
						}
					}
				}


				frappe.call({  	method: this.path_sales_document + '.ioi_sales_document_populate_line',
								args: 	{	"doctype": this.frm.doctype,
											"document_type": dc_type,
											"source_type": source_type,
											"item_id": item_id,
											"barcode_ref": barcode_ref,
											"customer_id": this.frm.doc.order_customer_id,
											"sales_catalog_id": this.frm.doc.sales_catalog_id,
											"sales_condition_id": item.sales_condition_id,
											"currency_id": this.frm.doc.currency_id,
											"currency_rate_inv": this.frm.doc.currency_rate_inv,
											"currency_rate": this.frm.doc.currency_rate,
											"system_currency_id": this.system_currency,
											"document_date": this.frm.doc.document_date,
											"line_qty": qty,
											"unit_id": unit_id,
											"index_pivot_date": this.frm.doc.index_pivot_date,
											"intersite_transaction": this.frm.doc.intersite_transaction,
											"intersite_origin_site_id": this.frm.doc.site_id,
											"intersite_destination_site_id": this.frm.doc.intersite_destination_site_id,
											"document_language": this.frm.doc.language,
											"journal_id" : this.frm.doc.journal_id,
											"customer_family_1_id": this.frm.doc.customer_family_1_id,
											"is_sales_pos": is_sales_pos


								},
								async: false,
								callback: function(r)	{

															if (source_type.toUpperCase() != 'QTY')
															{
																item.item_id = '';
																item.item_description = '';

																if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
																	item.item_mode = 0;
																}


																item.account_id = '';
																item.analytic1_id = '';
																item.analytic2_id = '';
																item.analytic3_id = '';
																item.analytic4_id = '';

																item.unit_id = '';

																if ((me.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER')) {
																	item.workcenter_id = '';
																}

																item.stock_unit_id = '';
																item.coef_sales_unit = 1;
																item.coef_stock_unit = 1;
															}

															item.unit_price = 0;
															item.unit_price_tvac = 0;
															item.fixed_price = 0;
															item.base_discount = 0;
															item.extra_discount = 0;
															item.discount_in_value = 0;
															item.discount_only = 0;
															item.no_financial_discount = 0;
															item.vat_included_in_price = 0;

															item.index_id = '';
															item.index_coef = 0;
															item.index_ref_date = '';
															item.index_ref_value = 0
															item.index_cur_value = 0;
															item.index_active = 0;
															item.ftg_priority = 0;

															item.supplier_fnd = '';
															item.purch_unit_price_fnd = 0;


															if (source_type.toUpperCase() != 'QTY')
															{
																item.withme_id = '';

																item.vat_rate = me.frm.doc.spec_vat_rate;

																item.vat_matching_id = me.frm.doc.vat_matching_id

																if ((item.vat_matching_id) && (item.vat_matching_id.trim() != '')) {

																	if (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {

																		let method = me.path_sales_document + '.ioi_sales_document_get_vatcode';

																		frappe.call({  	method: method,
																						args: {	"vat_type": item.vat_type,
																								"vat_rate": item.vat_rate,
																								"vat_service": null,
																								"vat_matching": item.vat_matching_id,
																								"account_id": null

																							},
																						async: false,
																						callback:function(r)	{
																							if (r.message.vat_code_id != '') {
																								item.vat_rate = r.message.vat_percentage;
																								item.vatcode_id = r.message.vat_code_id;
																								item.manual_vat = 1;
																							}
																						}
																		});
																	}
																}

															}

															item.free_part_num = 0;
															item.free_part_den = 0;
															item.free_part_qty = 0;

															item.external_ref = '';
															item.vat_service = '';

															item.unit_price_source = '';
															item.unit_price_tvac_source = '';
															item.fixed_price_source = '';
															item.base_discount_source = '';
															item.extra_discount_source = '';
															item.discount_value_source = '';
															item.min_order_qty_source = '';
															item.multiple_order_qty_source = '';
															item.free_part_num_source = '';
															item.free_part_den_source = '';

															item.manual_price = 0;

															item.decimals_allowed = 1;

															item.def_unit_price = 0;
															item.def_fixed_price = 0;
															item.def_base_discount = 0;
															item.def_extra_discount = 0;
															item.def_value_discount = 0;
															item.def_value_line_doc_currency = 0;

															item.alert_margin_rate = me.journal_default_alert_margin_rate
															item.alert_margin_mode = me.journal_margin_mode
															item.cost_margin_rate = 0
															item.sales_margin_rate = 0
															item.margin_alert = 0
															item.item_budget_id = ''
															item.cost_value = 0



															if (me.frm.doc.intersite_transaction == 1) {
																if ((r.message.purchases_sales_condition_id != null) && (r.message.purchases_sales_condition_id != '')) {
																	item.sales_condition_id = r.message.purchases_sales_condition_id;

																}
															}


															if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
																item.price_contract_master_link = '';
																item.price_contract_detail_link = '';
															}




															if (r.message.error == 1)
															{	me.#set_trace(me, 'log_detail', r.message.error_from_fieldname, r.message.error_fieldname, r.message.error_message, 'N');
															}else
															{
																if (source_type.toUpperCase() != 'QTY')
																{
																	item.item_id = r.message.item_id;
																	item.item_description = r.message.item_description;

																	if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
																		item.item_mode = r.message.mode;
																	}

																	if ((r.message.manufacturer_ref != null) && (r.message.manufacturer_ref != '')) {
																		item.manufacturer_ref = r.message.manufacturer_ref;
																	}
																	item.unit_id = r.message.unit_id;
																	item.invoicing_mode = r.message.invoicing_mode;
																	item.stock_unit_id = r.message.stock_unit_id;
																	item.coef_sales_unit = r.message.coef_sales_unit;
																	item.coef_stock_unit = r.message.coef_stock_unit;
																	item.vat_service = r.message.vat_service;


																	item.weight = r.message.weight;
																	item.tare = r.message.tare;
																	item.volume = r.message.volume;

																	if ((me.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER')) {

																		item.workcenter_id = r.message.workcenter_id
																	}


																	item.line_memo = '';
																}


                                                                if (me.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
                                                                {
																	if (source_type.toUpperCase() != 'QTY')
																	{
																		if (me.frm.doc.document_type.toUpperCase() == 'QUOTE')
																		{
																			if (me.journal_quotes_fill_memo == 1)
																			{
																				item.line_memo = r.message.item_memo;
																			}

																			if (me.journal_quotes_fill_thumbnail == 1)
																			{
																				item.thumbnail = r.message.thumbnail;
																			}
																		}else
																		{
																			if (me.journal_contracts_fill_memo == 1)
																			{
																				item.line_memo = r.message.item_memo;
																			}

																			if (me.journal_contracts_fill_thumbnail == 1)
																			{
																				item.thumbnail = r.message.thumbnail;
																			}
																		}

																		item.quoted_qty = r.message.line_qty;
																	}else{
																		if (r.message.line_qty) {
																			if (r.message.line_qty != item.quoted_qty) {
																				item.quoted_qty = r.message.line_qty
																			}
																		}
																	}



																}else if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
                                                                {
																	if (source_type.toUpperCase() != 'QTY')
																	{

																		if (me.journal_orders_fill_memo == 1)
																		{
																			item.line_memo = r.message.item_memo;
																		}

																		if (me.journal_orders_fill_thumbnail == 1)
																		{
																			item.thumbnail = r.message.thumbnail;
																		}

																		item.ordered_qty = r.message.line_qty;
																	}else{
																		if (r.message.line_qty) {
																			if (r.message.line_qty != item.ordered_qty) {
																				item.ordered_qty = r.message.line_qty
																			}
																		}
																	}




																	item.price_contract_master_link = r.message.price_contract_master_link;
																	item.price_contract_detail_link = r.message.price_contract_detail_link;


																}else if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
                                                                {
																	if (source_type.toUpperCase() != 'QTY')
																	{
																		if (me.journal_deliveries_fill_memo == 1)
																		{
																			item.line_memo = r.message.item_memo;
																		}

																		if (me.journal_deliveries_fill_thumbnail == 1)
																		{
																			item.thumbnail = r.message.thumbnail;
																		}

																		item.delivered_qty = r.message.line_qty;
																	}else{
																		if (r.message.line_qty) {
																			if (r.message.line_qty != item.delivered_qty) {
																				item.delivered_qty = r.message.line_qty
																			}
																		}
																	}



																}else if (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
                                                                {
																	if (source_type.toUpperCase() != 'QTY')
																	{
																		if (me.frm.doc.document_type.toUpperCase() == 'INVOICE')
																		{
																			if (me.journal_invoices_fill_memo == 1)
																			{
																				item.line_memo = r.message.item_memo;
																			}

																			if (me.journal_invoices_fill_thumbnail == 1)
																			{
																				item.thumbnail = r.message.thumbnail;
																			}
																		}else
																		{
																			if (me.journal_cnotes_fill_memo == 1)
																			{
																				item.line_memo = r.message.item_memo;
																			}

																			if (me.journal_cnotes_fill_thumbnail == 1)
																			{
																				item.thumbnail = r.message.thumbnail;
																			}
																		}
																		item.invoiced_qty = r.message.line_qty;
																	}else{
																		if (r.message.line_qty) {
																			if (r.message.line_qty != item.invoiced_qty) {
																				item.invoiced_qty = r.message.line_qty
																			}
																		}
																	}


																}

																item.decimals_allowed = r.message.decimals_allowed;

																if ((r.message.min_order_qty) && (r.message.min_order_qty != 0))
																{
																	item.min_order_qty = r.message.min_order_qty;
																	item.min_order_qty_source = r.message.min_order_qty_source;
																}

																if ((r.message.multiple_order_qty) && (r.message.multiple_order_qty != 0))
																{
																	item.multiple_order_qty = r.message.multiple_order_qty;
																	item.multiple_order_qty_source = r.message.multiple_order_qty_source;
																}

																if ((r.message.free_part_num) && (r.message.free_part_num != 0))
																{
																	item.free_part_num = r.message.free_part_num;
																	item.free_part_num_source = r.message.free_part_num_source;
																}

																if ((r.message.free_part_den) && (r.message.free_part_den != 0))
																{
																	item.free_part_den = r.message.free_part_den;
																	item.free_part_den_source = r.message.free_part_den_source;
																}

																if ((r.message.free_part_qty) && (r.message.free_part_qty != 0))
																{
																	item.free_part_qty = r.message.free_part_qty;
																}

																item.ftg_priority = r.message.ftg_priority;

																// Index
																let get_index = true;

																if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
																{	if ((item.quote_master_link) && (item.quote_master_link.trim() != ''))
																	{	get_index = false;
																	}
																}else if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
																{	if ((item.order_master_link) && (item.order_master_link.trim() != ''))
																	{	get_index = false;
																	}
																}else if (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
																{	if ((item.order_master_link) && (item.order_master_link.trim() != ''))
																	{	get_index = false;
																	}

																	if ((get_index) && (item.delivery_master_link) && (item.delivery_master_link.trim() != ''))
																	{	get_index = false;
																	}
																}

																if (get_index)
																{
																	item.index_id = r.message.index_id;
																	item.index_coef = r.message.index_coef;
																	item.index_ref_date = r.message.index_ref_date;
																	item.index_ref_value = r.message.index_ref_value;
																	item.index_cur_value = r.message.index_cur_value;

																	if ((r.message.index_id) && (r.message.index_id.trim() != ''))
																	{
																		item.index_active = 1;
																	}
																}


																if (source_type.toUpperCase() != 'QTY')
																{
																	if ((r.message.specific_vat_rate) && (r.message.specific_vat_rate != 0))
																	{
																		if (me.frm.doctype.toUpperCase() != 'IOI SALES INVOICE') {
																			item.vat_rate = r.message.specific_vat_rate;
																		}else{
																			if ((!item.vat_matching_id) || ((item.vat_matching_id) && (item.vat_matching_id.trim() == ''))) {
																				item.vat_rate = r.message.specific_vat_rate;
																			}else{

																				let method = me.path_sales_document + '.ioi_sales_document_get_vatcode';

																				frappe.call({  	method: method,
																								args: {	"vat_type": item.vat_type,
																										"vat_rate": item.vat_rate,
																										"vat_service": null,
																										"vat_matching": item.vat_matching_id,
																										"account_id": null

																									},
																								async: false,
																								callback:function(r)	{
																									if (r.message.vat_code_id != '') {
																										item.vat_rate = r.message.vat_percentage;
																										item.vatcode_id = r.message.vat_code_id;
																										item.manual_vat = 1;
																									}
																								}
																				});

																			}

																		}
																	}

																	item.line_memo = r.message.item_memo;

																	item.account_id = r.message.account_id;
																	item.analytic1_id = r.message.analytic1_id;
																	item.analytic2_id = r.message.analytic2_id;
																	item.analytic3_id = r.message.analytic3_id;
																	item.analytic4_id = r.message.analytic4_id;
																}


																item.item_cost_std_stamp = r.message.item_cost_std_stamp;
																item.item_cost = r.message.item_cost_calc_stamp;


																item.item_budget_id = r.message.budget_id;

																item.alert_margin_rate = me.journal_default_alert_margin_rate;
																item.alert_margin_mode = me.journal_margin_mode;

																if ((r.message.alert_margin_rate) && (r.message.alert_margin_rate != 0)) {

																	item.alert_margin_rate =r.message.alert_margin_rate;
																	item.alert_margin_mode = r.message.alert_margin_mode;
																}


																item.cost_margin_rate = 0
																item.sales_margin_rate = 0
																item.margin_alert = 0
																item.cost_value = 0


																if (source_type.toUpperCase() != 'QTY')
																{
																	if ((r.message.warehouse_id) && (r.message.warehouse_id.trim() != ''))
																	{
																		item.warehouse_id = r.message.warehouse_id;

																		if ((me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')) {
																			item.warehouse_location_id = r.message.warehouse_location_id;
																		}
																	}
																}


																if (r.message.discount_only == 0)
																{
																	item.unit_price = r.message.unit_price;
																	item.unit_price_tvac = r.message.unit_price_tvac;
																	item.fixed_price = r.message.fixed_price;

																	item.unit_price_source = r.message.unit_price_source;
																	item.unit_price_tvac_source = r.message.unit_price_tvac_source;
																	item.fixed_price_source = r.message.fixed_price_source;
																}

																if (r.message.no_financial_discount == 1)
																{
																	item.no_financial_discount = 1;
																}

																item.base_discount = r.message.base_discount;
																item.extra_discount = r.message.extra_discount;
																item.value_discount = r.message.discount_in_value;
																item.base_discount_source = r.message.base_discount_source;
																item.extra_discount_source = r.message.extra_discount_source;
																item.discount_value_source = r.message.discount_value_source;

																item.vat_included_in_price = r.message.vat_included_in_price;
																item.external_ref = r.message.external_reference;

																if (source_type.toUpperCase() != 'QTY')
																{
																	item.withme_id = r.message.withme_id;
																}


																if (source_type.toUpperCase() != 'QTY')
																{
																	if ((r.message.price_description) && (r.message.price_description.trim() != ''))
																	{
																		if (r.message.price_description[0] == '&')
																		{
																			item.item_description = item.item_description + r.message.price_description.substr(1, r.message.price_description.length);
																		}
																	}
																}

																item.def_unit_price = item.unit_price;
																item.def_fixed_price = item.fixed_price;
																item.def_base_discount = item.base_discount;
																item.def_extra_discount = item.extra_discount;
																item.def_value_discount = item.value_discount;


																item.def_value_line_doc_currency = 0;


																let qt = 0;

																if (me.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') {
																	if ((item.quoted_qty) && (item.quoted_qty.toString() != '')) {
																		qt = item.quoted_qty;
																	}
																}else if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
																	if ((item.ordered_qty) && (item.ordered_qty.toString() != '')) {
																		qt = item.ordered_qty;
																	}
																}else if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')	{

																	if ((item.delivered_qty) && (item.delivered_qty.toString() != '')) {
																		qt = item.delivered_qty;
																	}

																	if ((item.reserved_qty) && (item.reserved_qty.toString() != '')) {
																		qt = qt + item.reserved_qty;
																	}

																	if ((item.allocated_qty) && (item.allocated_qty.toString() != '')) {
																		qt = qt + item.allocated_qty;
																	}


																}else if (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
																	if ((item.invoiced_qty) && (item.invoiced_qty.toString() != '')) {
																		qt = item.invoiced_qty;
																	}
																}

																item.supplier_fnd = r.message.supplier_fnd;
																item.purch_unit_price_fnd = r.message.purch_unit_price_fnd;

															

																if (source_type.toUpperCase() == 'ITEM_ID') {

																	if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {

																		let fct_callback = function(warehouse_id = null, warehouse_location_id = null, batch_sn_id = null, code_ref = null, voucher_value_tvac = null, voucher_value = null, voucher_vat_rate = null, qty = null, voucher = null) {


																			item.warehouse_id = warehouse_id;
																			item.warehouse_location_id = warehouse_location_id;

																			if ((voucher == null) || (voucher == 0)) {
																				item.batch_sn_id = batch_sn_id;
																				item.batch_sn_code_ref = code_ref;
																			}else{
																				if (qty != null) {

																					if (qty <= 0) {
																						item.batch_sn_id = batch_sn_id;
																						item.batch_sn_code_ref = code_ref;
																					}else{
																						item.batch_sn_id = '';
																						item.batch_sn_code_ref = '';
																					}

																				}else{
																					item.batch_sn_id = batch_sn_id;
																					item.batch_sn_code_ref = code_ref;
																				}

																			}

																			
																			

																			if (qty != null) {
																				item.delivered_qty = parseFloat(qty);
																			}																			
	
																			if (batch_sn_id != null) {
	
																				if (batch_sn_id != '') {
	
																					if (voucher_value_tvac != null) {
																						if (parseFloat(voucher_value_tvac) != 0) {
																							item.unit_price_tvac = voucher_value_tvac;
																						}
																					}
	
																					if (voucher_value != null) {
																						if (parseFloat(voucher_value) != 0) {
																							item.unit_price = voucher_value;
																						}
																					}	
	
																					if (voucher_vat_rate != null) {
																						if (parseFloat(voucher_vat_rate) != 0) {
																							item.vat_rate = voucher_vat_rate;
																						}
																					}																					
																				}
																			}	
																			

																			// Compute value line doc currency
																			item.def_value_line_doc_currency = me.#line_compute_value(	me.frm.doc.document_calc_mode, qt, item.free_part_qty,
																																		item.fixed_price, item.unit_price,
																																		item.value_discount, item.base_discount,
																																		item.extra_discount, me.frm.doc.base_discount, me.frm.doc.extra_discount,
																																		me.frm.doc.credit_mode, me.journal_unit_price_decimal_number, me.frm.doc.currency_digit_rounding,
																																		item.is_option);


																			if (me.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
																			{	me.convert_in_stock_unit(me.frm, cdt, cdn, 'quoted_qty', 'stock_quoted_qty');
																				me.convert_in_stock_unit(me.frm, cdt, cdn, 'ordered_qty', 'stock_ordered_qty');
																				me.convert_in_stock_unit(me.frm, cdt, cdn, 'free_part_qty', 'stock_free_part_qty');
																			}else if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
																			{	me.convert_in_stock_unit(me.frm, cdt, cdn, 'ordered_qty', 'stock_ordered_qty');
																				me.convert_in_stock_unit(me.frm, cdt, cdn, 'delivered_qty', 'stock_delivered_qty');
																				me.convert_in_stock_unit(me.frm, cdt, cdn, 'invoiced_qty', 'stock_invoiced_qty');
																				me.convert_in_stock_unit(me.frm, cdt, cdn, 'returned_qty', 'stock_returned_qty');
																				me.convert_in_stock_unit(me.frm, cdt, cdn, 'free_part_qty', 'stock_free_part_qty');
																				me.convert_in_stock_unit(me.frm, cdt, cdn, 'reserved_qty', 'stock_reserved_qty');
																			}else if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
																			{	me.convert_in_stock_unit(me.frm, cdt, cdn, 'delivered_qty', 'stock_delivered_qty');
																				me.convert_in_stock_unit(me.frm, cdt, cdn, 'invoiced_qty', 'stock_invoiced_qty');
																				me.convert_in_stock_unit(me.frm, cdt, cdn, 'ordered_qty', 'stock_ordered_qty');
																				me.convert_in_stock_unit(me.frm, cdt, cdn, 'free_part_qty', 'stock_free_part_qty');
																				me.convert_in_stock_unit(me.frm, cdt, cdn, 'reserved_qty', 'stock_reserved_qty');
																			}else if (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
																			{	me.convert_in_stock_unit(me.frm, cdt, cdn, 'invoiced_qty', 'stock_invoiced_qty');
																				me.convert_in_stock_unit(me.frm, cdt, cdn, 'delivered_qty', 'stock_delivered_qty');
																				me.convert_in_stock_unit(me.frm, cdt, cdn, 'free_part_qty', 'stock_free_part_qty');
																			}

																			me.frm.refresh_field('line_detail');
																					
																			
																		}

																		silicon_ioi.ioiCommon.ioi_sales_document_get_whs_loc_with_stock(me.frm.doctype, me.frm.doc.currency_id, me.frm.doc.currency_rate_inv, me.frm.doc.journal_id, item.item_id, item.delivered_qty, fct_callback);

																	}

																}else{


																	// Compute value line doc currency
																	item.def_value_line_doc_currency = me.#line_compute_value(	me.frm.doc.document_calc_mode, qt, item.free_part_qty,
																																item.fixed_price, item.unit_price,
																																item.value_discount, item.base_discount,
																																item.extra_discount, me.frm.doc.base_discount, me.frm.doc.extra_discount,
																																me.frm.doc.credit_mode, me.journal_unit_price_decimal_number, me.frm.doc.currency_digit_rounding,
																																item.is_option);


																	if (me.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
																	{	me.convert_in_stock_unit(me.frm, cdt, cdn, 'quoted_qty', 'stock_quoted_qty');
																		me.convert_in_stock_unit(me.frm, cdt, cdn, 'ordered_qty', 'stock_ordered_qty');
																		me.convert_in_stock_unit(me.frm, cdt, cdn, 'free_part_qty', 'stock_free_part_qty');
																	}else if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
																	{	me.convert_in_stock_unit(me.frm, cdt, cdn, 'ordered_qty', 'stock_ordered_qty');
																		me.convert_in_stock_unit(me.frm, cdt, cdn, 'delivered_qty', 'stock_delivered_qty');
																		me.convert_in_stock_unit(me.frm, cdt, cdn, 'invoiced_qty', 'stock_invoiced_qty');
																		me.convert_in_stock_unit(me.frm, cdt, cdn, 'returned_qty', 'stock_returned_qty');
																		me.convert_in_stock_unit(me.frm, cdt, cdn, 'free_part_qty', 'stock_free_part_qty');
																		me.convert_in_stock_unit(me.frm, cdt, cdn, 'reserved_qty', 'stock_reserved_qty');
																	}else if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
																	{	me.convert_in_stock_unit(me.frm, cdt, cdn, 'delivered_qty', 'stock_delivered_qty');
																		me.convert_in_stock_unit(me.frm, cdt, cdn, 'invoiced_qty', 'stock_invoiced_qty');
																		me.convert_in_stock_unit(me.frm, cdt, cdn, 'ordered_qty', 'stock_ordered_qty');
																		me.convert_in_stock_unit(me.frm, cdt, cdn, 'free_part_qty', 'stock_free_part_qty');
																		me.convert_in_stock_unit(me.frm, cdt, cdn, 'reserved_qty', 'stock_reserved_qty');
																	}else if (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
																	{	me.convert_in_stock_unit(me.frm, cdt, cdn, 'invoiced_qty', 'stock_invoiced_qty');
																		me.convert_in_stock_unit(me.frm, cdt, cdn, 'delivered_qty', 'stock_delivered_qty');
																		me.convert_in_stock_unit(me.frm, cdt, cdn, 'free_part_qty', 'stock_free_part_qty');
																	}
																}
															}

															me.frm.refresh_field('line_detail');


														}
				});
			}
		}else
		{
			this.frm.refresh_field('line_detail');

			if (((item.item_id) && (item.item_id.trim() != '')) || ((item.manufacturer_ref) && (item.manufacturer_ref.trim() != ''))) {
				item.item_description = '';
			}
			item.decimals_allowed = 1;


		}

		const grid_row = this.frm.fields_dict["line_detail"].grid.grid_rows_by_docname[item.name];
		const item_description = grid_row.docfields.filter(f => f.fieldname == "item_description")[0];
		const item_unit = grid_row.docfields.filter(f => f.fieldname == "unit_id")[0];

		if (((item.item_id) && (item.item_id != '')) || ((item.manufacturer_ref) && (item.manufacturer_ref != '')))
		{
			item_description.read_only = true;
		}else
		{
			item_description.read_only = false;
		}
		this.frm.refresh_field('line_detail');
		silicon_ioi.doctype.ioiSalesDocumentDocType.set_static_grid_layout(null);


		this.#sleep(1000).then(() => { this.set_label_when_error(); });
	}



	// ***************************************************************************************************************************************
	// Manufacturer Catalog Populate line
	// ***************************************************************************************************************************************
	manufacturer_catalog_populate_line(frm, cdt, cdn, source_type = 'MANUFACTURER_REF', unit_id = '')
	{
		this.frm.refresh_field('line_detail');
		let item = locals[cdt][cdn];
		let can_populate_line = false;

		this.frm.set_intro('', 'red');

		if (this.frm.doc.log_detail)
		{
			let i = this.frm.get_field("log_detail").grid.grid_rows.length-1;

			while (i >= 0)
			{
				if ((this.frm.doc.log_detail[i].fieldname.toUpperCase() == 'ORDER_CUSTOMER_ID') ||
					(this.frm.doc.log_detail[i].fieldname.toUpperCase() == 'SALES_CATALOG_ID') ||
					(this.frm.doc.log_detail[i].fieldname.toUpperCase() == 'CURRENCY_ID') ||
					(this.frm.doc.log_detail[i].fieldname.toUpperCase() == 'DOCUMENT_DATE') ||
					(this.frm.doc.log_detail[i].fieldname.toUpperCase() == 'ITEM_SALES_CONDITION_ID'))
				{
					this.frm.get_field("log_detail").grid.grid_rows[i].remove();
				}
				i--;
			}
		}


		let manufacturer_ref = ''
		let barcode_ref = ''

		if ((source_type.toUpperCase() == 'MANUFACTURER_REF') || (source_type.toUpperCase() == 'QTY'))
		{
			if ((item.manufacturer_ref) && (item.manufacturer_ref.trim() != ''))
			{	can_populate_line = true;
				manufacturer_ref = item.manufacturer_ref;

			}else
			{
				item.unit_price_source = '';
				item.unit_price_tvac_source = '';
				item.fixed_price_source = '';
				item.base_discount_source = '';
				item.extra_discount_source = '';
				item.discount_value_source = '';
				item.min_order_qty_source = '';
				item.multiple_order_qty_source = '';
				item.free_part_num_source = '';
				item.free_part_den_source = '';

				item.index_id = '';
				item.index_coef = 0;
				item.index_ref_date = '';
				item.index_ref_value = 0
				item.index_cur_value = 0;
				item.decimals_allowed = 1;


				item.supplier_fnd = '';
				item.purch_unit_price_fnd = 0;

				this.format_detail_form(frm, cdt, cdn)


			}

			if (source_type.toUpperCase() == 'QTY') {

				if (item.withme == 1) {
					can_populate_line = false;
				}

			}
		}else
		{
			if ((item.barcode_reference) && (item.barcode_reference.trim() != ''))
			{	can_populate_line = true;
				barcode_ref = item.barcode_reference;
			}

		}

		if (can_populate_line)
		{

			if ((!this.frm.doc.order_customer_id) || ((this.frm.doc.order_customer_id) && (this.frm.doc.order_customer_id.trim() == '')))
			{
				this.#set_trace(this, 'log_detail', "order_customer_id", "order_customer_id", __('Order customer has to be filled'), 'N');
				item.item_id = '';
				this.frm.refresh_field('line_detail');
				can_populate_line = false;
			}

			if ((can_populate_line) && ((!this.frm.doc.document_date) || ((this.frm.doc.document_date) && (this.frm.doc.document_date.trim() == ''))))
			{
				this.#set_trace(this, 'log_detail', "document_date", "document_date", __('Document date has to be filled'), 'N');
				item.item_id = '';
				this.frm.refresh_field('line_detail');
				can_populate_line = false;
			}


			if ((can_populate_line) && ((!this.frm.doc.currency_id) || ((this.frm.doc.currency_id) && (this.frm.doc.currency_id.trim() == ''))))
			{
				this.#set_trace(this, 'log_detail', "currency_id", "currency_id", __('Currency has to be filled'), 'N');
				item.item_id = '';
				this.frm.refresh_field('line_detail');
				can_populate_line = false;
			}

            let dc_type = '';
            let qty = 0;

            if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
            {
                dc_type = 'QUOTE';

			    if (!item.quoted_qty)
			    {
    				item.quoted_qty = 0;
                    qty = 0;
	    		}else
                {
                    qty = item.quoted_qty;
                }
            }else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
            {
                dc_type = 'ORDER';

			    if (!item.ordered_qty)
			    {
    				item.ordered_qty = 0;
                    qty = 0;
	    		}else
                {
                    qty = item.ordered_qty;
                }
            }else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
            {
                dc_type = 'DELIVERY';

			    if (!item.delivered_qty)
			    {
    				item.delivered_qty = 0;
                    qty = 0;
	    		}else
                {
                    qty = item.delivered_qty;
                }
            }else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
            {
                dc_type = 'INVOICE';

			    if (!item.invoiced_qty)
			    {
    				item.invoiced_qty = 0;
                    qty = 0;
	    		}else
                {
                    qty = item.invoiced_qty;
                }
            }

			if (can_populate_line)
			{
				let me = this;


				frappe.call({  	method: this.path_sales_document + '.ioi_sales_document_manufacturer_catalog_populate_line',
								args: 	{	"doctype": this.frm.doctype,
											"document_type": dc_type,
											"source_type": source_type,
											"manufacturer_ref": manufacturer_ref,
											"barcode_ref": barcode_ref,
											"customer_id": this.frm.doc.order_customer_id,
											"currency_id": this.frm.doc.currency_id,
											"currency_rate_inv": this.frm.doc.currency_rate_inv,
											"currency_rate": this.frm.doc.currency_rate,
											"system_currency_id": this.system_currency,
											"document_date": this.frm.doc.document_date,
											"line_qty": qty,
											"unit_id": unit_id,
											"intersite_transaction": this.frm.doc.intersite_transaction,
											"intersite_origin_site_id": this.frm.doc.site_id,
											"intersite_destination_site_id": this.frm.doc.intersite_destination_site_id,
											"document_language": this.frm.doc.language


								},
								async: false,
								callback: function(r)	{

															if (source_type.toUpperCase() != 'QTY')
															{
																item.manufacturer_ref = '';
																item.item_id = '';
																item.item_description = '';

																item.account_id = '';
																item.analytic1_id = '';
																item.analytic2_id = '';
																item.analytic3_id = '';
																item.analytic4_id = '';

																item.unit_id = '';

																item.stock_unit_id = '';
																item.coef_sales_unit = 1;
																item.coef_stock_unit = 1;
															}

															item.unit_price = 0;
															item.unit_price_tvac = 0;
															item.fixed_price = 0;
															item.base_discount = 0;
															item.extra_discount = 0;
															item.discount_in_value = 0;
															item.discount_only = 0;
															item.no_financial_discount = 0;
															item.vat_included_in_price = 0;

															item.index_id = '';
															item.index_coef = 0;
															item.index_ref_date = '';
															item.index_ref_value = 0
															item.index_cur_value = 0;
															item.index_active = 0;


															item.alert_margin_rate = me.journal_default_alert_margin_rate
															item.alert_margin_mode = me.journal_margin_mode
															item.cost_margin_rate = 0
															item.sales_margin_rate = 0
															item.margin_alert = 0
															item.item_budget_id = ''
															item.cost_value = 0
															item.supplier_fnd = '';
															item.purch_unit_price_fnd = 0;



															if (source_type.toUpperCase() != 'QTY')
															{
																item.withme_id = '';

																item.vat_rate = me.frm.doc.spec_vat_rate;

																item.vat_matching_id = me.frm.doc.vat_matching_id

																if ((item.vat_matching_id) && (item.vat_matching_id.trim() != '')) {

																	if (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {

																		let method = me.path_sales_document + '.ioi_sales_document_get_vatcode';

																		frappe.call({  	method: method,
																						args: {	"vat_type": item.vat_type,
																								"vat_rate": item.vat_rate,
																								"vat_service": null,
																								"vat_matching": item.vat_matching_id,
																								"account_id": null

																							},
																						async: false,
																						callback:function(r)	{
																							if (r.message.vat_code_id != '') {
																								item.vat_rate = r.message.vat_percentage;
																								item.vatcode_id = r.message.vat_code_id;
																								item.manual_vat = 1;
																							}
																						}
																		});
																	}
																}

															}

															item.free_part_num = 0;
															item.free_part_den = 0;
															item.free_part_qty = 0;

															item.external_ref = '';
															item.vat_service = '';

															item.unit_price_source = '';
															item.unit_price_tvac_source = '';
															item.fixed_price_source = '';
															item.base_discount_source = '';
															item.extra_discount_source = '';
															item.discount_value_source = '';
															item.min_order_qty_source = '';
															item.multiple_order_qty_source = '';
															item.free_part_num_source = '';
															item.free_part_den_source = '';

															item.manual_price = 0;

															item.decimals_allowed = 1;


															item.def_unit_price = 0;
															item.def_fixed_price = 0;
															item.def_base_discount = 0;
															item.def_extra_discount = 0;
															item.def_value_discount = 0;

															item.def_value_line_doc_currency = 0;




															if (me.frm.doc.intersite_transaction == 1) {
																if ((r.message.purchases_sales_condition_id != null) && (r.message.purchases_sales_condition_id != '')) {
																	item.sales_condition_id = r.message.purchases_sales_condition_id;

																}
															}


															if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
																item.price_contract_master_link = '';
																item.price_contract_detail_link = '';
															}




															if (r.message.error == 1)
															{	me.#set_trace(me, 'log_detail', r.message.error_from_fieldname, r.message.error_fieldname, r.message.error_message, 'N');
															}else
															{
																if ((r.message.item_id) && (r.message.item_id.trim() != '')) {
																	item.item_id = r.message.item_id;
																	me.frm.refresh_field('line_detail');
																	me.populate_line(frm, cdt, cdn, 'ITEM_ID', '');
																}else{

																	if (source_type.toUpperCase() != 'QTY')
																	{
																		item.item_id = r.message.item_id;
																		item.item_description = r.message.item_description;

																		if ((r.message.manufacturer_ref != null) && (r.message.manufacturer_ref != '')) {
																			item.manufacturer_ref = r.message.manufacturer_ref;
																		}
																		item.unit_id = r.message.unit_id;
																		item.invoicing_mode = r.message.invoicing_mode;
																		item.stock_unit_id = r.message.stock_unit_id;
																		item.coef_sales_unit = r.message.coef_sales_unit;
																		item.coef_stock_unit = r.message.coef_stock_unit;
																		item.vat_service = r.message.vat_service;
																		item.weight = r.message.weight;
																		item.tare = r.message.tare;
																		item.volume = r.message.volume;


																		item.line_memo = '';
																	}


																	if (me.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
																	{
																		if (source_type.toUpperCase() != 'QTY')
																		{
																			if (me.frm.doc.document_type.toUpperCase() == 'QUOTE')
																			{
																				if (me.journal_quotes_fill_memo == 1)
																				{
																					item.line_memo = r.message.item_memo;
																				}

																				if (me.journal_quotes_fill_thumbnail == 1)
																				{
																					item.thumbnail = r.message.thumbnail;
																				}
																			}else
																			{
																				if (me.journal_contracts_fill_memo == 1)
																				{
																					item.line_memo = r.message.item_memo;
																				}

																				if (me.journal_contracts_fill_thumbnail == 1)
																				{
																					item.thumbnail = r.message.thumbnail;
																				}
																			}

																			item.quoted_qty = r.message.line_qty;
																		}else{
																			if (r.message.line_qty) {
																				if (r.message.line_qty != item.quoted_qty) {
																					item.quoted_qty = r.message.line_qty
																				}
																			}
																		}



																	}else if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
																	{
																		if (source_type.toUpperCase() != 'QTY')
																		{

																			if (me.journal_orders_fill_memo == 1)
																			{
																				item.line_memo = r.message.item_memo;
																			}

																			if (me.journal_orders_fill_thumbnail == 1)
																			{
																				item.thumbnail = r.message.thumbnail;
																			}
																			item.ordered_qty = r.message.line_qty;
																		}else{
																			if (r.message.line_qty) {
																				if (r.message.line_qty != item.ordered_qty) {
																					item.ordered_qty = r.message.line_qty
																				}
																			}
																		}



																		item.price_contract_master_link = r.message.price_contract_master_link;
																		item.price_contract_detail_link = r.message.price_contract_detail_link;


																	}else if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
																	{
																		if (source_type.toUpperCase() != 'QTY')
																		{
																			if (me.journal_deliveries_fill_memo == 1)
																			{
																				item.line_memo = r.message.item_memo;
																			}

																			if (me.journal_deliveries_fill_thumbnail == 1)
																			{
																				item.thumbnail = r.message.thumbnail;
																			}

																			item.delivered_qty = r.message.line_qty;
																		}else{
																			if (r.message.line_qty) {
																				if (r.message.line_qty != item.delivered_qty) {
																					item.delivered_qty = r.message.line_qty
																				}
																			}
																		}



																	}else if (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
																	{
																		if (source_type.toUpperCase() != 'QTY')
																		{
																			if (me.frm.doc.document_type.toUpperCase() == 'INVOICE')
																			{
																				if (me.journal_invoices_fill_memo == 1)
																				{
																					item.line_memo = r.message.item_memo;
																				}

																				if (me.journal_invoices_fill_thumbnail == 1)
																				{
																					item.thumbnail = r.message.thumbnail;
																				}
																			}else
																			{
																				if (me.journal_cnotes_fill_memo == 1)
																				{
																					item.line_memo = r.message.item_memo;
																				}

																				if (me.journal_cnotes_fill_thumbnail == 1)
																				{
																					item.thumbnail = r.message.thumbnail;
																				}
																			}

																			item.invoiced_qty = r.message.line_qty;
																		}else{
																			if (r.message.line_qty) {
																				if (r.message.line_qty != item.invoiced_qty) {
																					item.invoiced_qty = r.message.line_qty
																				}
																			}
																		}


																	}

																	item.decimals_allowed = r.message.decimals_allowed;

																	if ((r.message.min_order_qty) && (r.message.min_order_qty != 0))
																	{
																		item.min_order_qty = r.message.min_order_qty;
																		item.min_order_qty_source = r.message.min_order_qty_source;
																	}

																	if ((r.message.multiple_order_qty) && (r.message.multiple_order_qty != 0))
																	{
																		item.multiple_order_qty = r.message.multiple_order_qty;
																		item.multiple_order_qty_source = r.message.multiple_order_qty_source;
																	}

																	if ((r.message.free_part_num) && (r.message.free_part_num != 0))
																	{
																		item.free_part_num = r.message.free_part_num;
																		item.free_part_num_source = r.message.free_part_num_source;
																	}

																	if ((r.message.free_part_den) && (r.message.free_part_den != 0))
																	{
																		item.free_part_den = r.message.free_part_den;
																		item.free_part_den_source = r.message.free_part_den_source;
																	}

																	if ((r.message.free_part_qty) && (r.message.free_part_qty != 0))
																	{
																		item.free_part_qty = r.message.free_part_qty;
																	}

																	// Index
																	let get_index = true;

																	if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
																	{	if ((item.quote_master_link) && (item.quote_master_link.trim() != ''))
																		{	get_index = false;
																		}
																	}else if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
																	{	if ((item.order_master_link) && (item.order_master_link.trim() != ''))
																		{	get_index = false;
																		}
																	}else if (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
																	{	if ((item.order_master_link) && (item.order_master_link.trim() != ''))
																		{	get_index = false;
																		}

																		if ((get_index) && (item.delivery_master_link) && (item.delivery_master_link.trim() != ''))
																		{	get_index = false;
																		}
																	}

																	if (get_index)
																	{
																		item.index_id = r.message.index_id;
																		item.index_coef = r.message.index_coef;
																		item.index_ref_date = r.message.index_ref_date;
																		item.index_ref_value = r.message.index_ref_value;
																		item.index_cur_value = r.message.index_cur_value;

																		if ((r.message.index_id) && (r.message.index_id.trim() != ''))
																		{
																			item.index_active = 1;
																		}
																	}

																	if (source_type.toUpperCase() != 'QTY')
																	{
																		if ((r.message.specific_vat_rate) && (r.message.specific_vat_rate != 0))
																		{

																			if (me.frm.doctype.toUpperCase() != 'IOI SALES INVOICE') {
																				item.vat_rate = r.message.specific_vat_rate;
																			}else{
																				if ((!item.vat_matching_id) || ((item.vat_matching_id) && (item.vat_matching_id.trim() == ''))) {
																					item.vat_rate = r.message.specific_vat_rate;
																				}else{
																					let method = me.path_sales_document + '.ioi_sales_document_get_vatcode';

																					frappe.call({  	method: method,
																									args: {	"vat_type": item.vat_type,
																											"vat_rate": item.vat_rate,
																											"vat_service": null,
																											"vat_matching": item.vat_matching_id,
																											"account_id": null

																										},
																									async: false,
																									callback:function(r)	{
																										if (r.message.vat_code_id != '') {
																											item.vat_rate = r.message.vat_percentage;
																											item.vatcode_id = r.message.vat_code_id;
																											item.manual_vat = 1;
																										}
																									}
																					});

																				}

																			}

																		}

																		item.line_memo = r.message.item_memo;

																		item.account_id = r.message.account_id;
																		item.analytic1_id = r.message.analytic1_id;
																		item.analytic2_id = r.message.analytic2_id;
																		item.analytic3_id = r.message.analytic3_id;
																		item.analytic4_id = r.message.analytic4_id;
																	}


																	item.item_cost_std_stamp = r.message.item_cost_std_stamp;
																	item.item_cost = r.message.item_cost_calc_stamp;



																	item.item_budget_id = r.message.budget_id;

																	item.alert_margin_rate = me.journal_default_alert_margin_rate;
																	item.alert_margin_mode = me.journal_margin_mode;

																	if ((r.message.alert_margin_rate) && (r.message.alert_margin_rate != 0)) {

																		item.alert_margin_rate = r.message.alert_margin_rate;
																		item.alert_margin_mode = r.message.alert_margin_mode;
																	}


																	item.cost_margin_rate = 0
																	item.sales_margin_rate = 0
																	item.margin_alert = 0
																	item.cost_value = 0

																	item.supplier_fnd = r.message.supplier_fnd;
																	item.purch_unit_price_fnd = r.message.purch_unit_price_fnd;


																	if (source_type.toUpperCase() != 'QTY')
																	{
																		if ((r.message.warehouse_id) && (r.message.warehouse_id.trim() != ''))
																		{
																			item.warehouse_id = r.message.warehouse_id;
																		}
																	}


																	if (r.message.discount_only == 0)
																	{
																		item.unit_price = r.message.unit_price;
																		item.unit_price_tvac = r.message.unit_price_tvac;
																		item.fixed_price = r.message.fixed_price;

																		item.unit_price_source = r.message.unit_price_source;
																		item.unit_price_tvac_source = r.message.unit_price_tvac_source;
																		item.fixed_price_source = r.message.fixed_price_source;
																	}

																	if (r.message.no_financial_discount == 1)
																	{
																		item.no_financial_discount = 1;
																	}

																	item.base_discount = r.message.base_discount;
																	item.extra_discount = r.message.extra_discount;
																	item.value_discount = r.message.discount_in_value;
																	item.base_discount_source = r.message.base_discount_source;
																	item.extra_discount_source = r.message.extra_discount_source;
																	item.discount_value_source = r.message.discount_value_source;

																	item.vat_included_in_price = r.message.vat_included_in_price;
																	item.external_ref = r.message.external_reference;

																	if (source_type.toUpperCase() != 'QTY')
																	{
																		item.withme_id = r.message.withme_id;
																	}


																	item.def_unit_price = item.unit_price;
																	item.def_fixed_price = item.fixed_price;
																	item.def_base_discount = item.base_discount;
																	item.def_extra_discount = item.extra_discount;
																	item.def_value_discount = item.value_discount;


																	item.def_value_line_doc_currency = 0;


																	let qt = 0;

																	if (me.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') {
																		if ((item.quoted_qty) && (item.quoted_qty.toString() != '')) {
																			qt = item.quoted_qty;
																		}
																	}else if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
																		if ((item.ordered_qty) && (item.ordered_qty.toString() != '')) {
																			qt = item.ordered_qty;
																		}
																	}else if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')	{

																		if ((item.delivered_qty) && (item.delivered_qty.toString() != '')) {
																			qt = item.delivered_qty;
																		}

																		if ((item.reserved_qty) && (item.reserved_qty.toString() != '')) {
																			qt = qt + item.reserved_qty;
																		}

																		if ((item.allocated_qty) && (item.allocated_qty.toString() != '')) {
																			qt = qt + item.allocated_qty;
																		}


																	}else if (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
																		if ((item.invoiced_qty) && (item.invoiced_qty.toString() != '')) {
																			qt = item.invoiced_qty;
																		}
																	}

																	// Compute value line doc currency
																	item.def_value_line_doc_currency = me.#line_compute_value(	me.frm.doc.document_calc_mode, qt, item.free_part_qty,
																																item.fixed_price, item.unit_price,
																																item.value_discount, item.base_discount,
																																item.extra_discount, me.frm.doc.base_discount, me.frm.doc.extra_discount,
																																me.frm.doc.credit_mode, me.journal_unit_price_decimal_number, me.frm.doc.currency_digit_rounding,
																																item.is_option);




																	if (source_type.toUpperCase() != 'QTY')
																	{
																		if ((r.message.price_description) && (r.message.price_description.trim() != ''))
																		{
																			if (r.message.price_description[0] == '&')
																			{
																				item.item_description = item.item_description + r.message.price_description.substr(1, r.message.price_description.length);
																			}
																		}
																	}

																	if (me.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
																	{	me.convert_in_stock_unit(me.frm, cdt, cdn, 'quoted_qty', 'stock_quoted_qty');
																		me.convert_in_stock_unit(me.frm, cdt, cdn, 'ordered_qty', 'stock_ordered_qty');
																		me.convert_in_stock_unit(me.frm, cdt, cdn, 'free_part_qty', 'stock_free_part_qty');
																	}else if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
																	{	me.convert_in_stock_unit(me.frm, cdt, cdn, 'ordered_qty', 'stock_ordered_qty');
																		me.convert_in_stock_unit(me.frm, cdt, cdn, 'delivered_qty', 'stock_delivered_qty');
																		me.convert_in_stock_unit(me.frm, cdt, cdn, 'invoiced_qty', 'stock_invoiced_qty');
																		me.convert_in_stock_unit(me.frm, cdt, cdn, 'returned_qty', 'stock_returned_qty');
																		me.convert_in_stock_unit(me.frm, cdt, cdn, 'free_part_qty', 'stock_free_part_qty');
																		me.convert_in_stock_unit(me.frm, cdt, cdn, 'reserved_qty', 'stock_reserved_qty');
																	}else if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
																	{	me.convert_in_stock_unit(me.frm, cdt, cdn, 'delivered_qty', 'stock_delivered_qty');
																		me.convert_in_stock_unit(me.frm, cdt, cdn, 'invoiced_qty', 'stock_invoiced_qty');
																		me.convert_in_stock_unit(me.frm, cdt, cdn, 'ordered_qty', 'stock_ordered_qty');
																		me.convert_in_stock_unit(me.frm, cdt, cdn, 'free_part_qty', 'stock_free_part_qty');
																		me.convert_in_stock_unit(me.frm, cdt, cdn, 'reserved_qty', 'stock_reserved_qty');
																	}else if (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
																	{	me.convert_in_stock_unit(me.frm, cdt, cdn, 'invoiced_qty', 'stock_invoiced_qty');
																		me.convert_in_stock_unit(me.frm, cdt, cdn, 'delivered_qty', 'stock_delivered_qty');
																		me.convert_in_stock_unit(me.frm, cdt, cdn, 'free_part_qty', 'stock_free_part_qty');
																	}
																}
															}

															me.frm.refresh_field('line_detail');


															}
				});
			}
		}else
		{
			this.frm.refresh_field('line_detail');
			item.decimals_allowed = 1;
		}

		const grid_row = this.frm.fields_dict["line_detail"].grid.grid_rows_by_docname[item.name];
		const item_description = grid_row.docfields.filter(f => f.fieldname == "item_description")[0];
		const item_unit = grid_row.docfields.filter(f => f.fieldname == "unit_id")[0];


		if (((item.item_id) && (item.item_id != '')) || ((item.manufacturer_ref) && (item.manufacturer_ref != '')))
		{
			item_description.read_only = true;
		}else
		{
			item_description.read_only = false;
		}
		this.frm.refresh_field('line_detail');
		silicon_ioi.doctype.ioiSalesDocumentDocType.set_static_grid_layout(null);


		this.#sleep(1000).then(() => { this.set_label_when_error(); });
	}





	// ***************************************************************************************************************************************
	// Convert in stock unit
	// ***************************************************************************************************************************************
	convert_in_stock_unit(frm, cdt, cdn, fieldname, stock_fieldname)
	{
		frm.refresh_field('line_detail');
		let item = locals[cdt][cdn];
		item[stock_fieldname] = (item[fieldname] * 1.0) * (item.coef_sales_unit * 1.0) / (item.coef_stock_unit * 1.0);

	}

	// ***************************************************************************************************************************************
	// Format qty
	// ***************************************************************************************************************************************
	format_qty(frm, cdt, cdn, fieldname)
	{
		let item = locals[cdt][cdn]

		if ((!item.decimals_allowed) || (item.decimals_allowed == 0))
		{
			if ((item.item_id) && (item.item_id.trim() != ''))
			{
				item[fieldname] = Math.round(item[fieldname]);
				frm.refresh_field('line_detail');
			}
		}
	}

	// ***************************************************************************************************************************************
	// Get price for qty
	// ***************************************************************************************************************************************
	get_price_for_qty(frm, cdt, cdn)
	{
		this.frm.refresh_field('line_detail');
		let item = locals[cdt][cdn];


		let min_order_qty = 0
		let multiple_order_qty = 0
		let qt = 0;

		let do_populate = true

		if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
		{
			if (item.quoted_qty)
			{
				qt = item.quoted_qty;
			}

		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
		{
			if (item.ordered_qty)
			{
				qt = item.ordered_qty;
			}

			if ((item.quote_master_link) && (item.quote_master_link.trim() != ''))
			{
				do_populate = false;
			}
		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
		{
			if (item.delivered_qty)
			{
				qt = item.delivered_qty;
			}

			if ((item.order_master_link) && (item.order_master_link.trim() != ''))
			{
				do_populate = false;
			}

		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
		{
			if (item.invoiced_qty)
			{
				qt = item.invoiced_qty;
			}

			if ((item.delivery_master_link) && (item.delivery_master_link.trim() != ''))
			{
				do_populate = false;
			}

		}

		if ((!item.manual_price) || (item.manual_price == 0))
		{
			if (do_populate)
			{
				if ((!item.manufacturer_ref) || ((item.manufacturer_ref) && (item.manufacturer_ref.trim() == ''))) {
					this.populate_line(frm, cdt, cdn, 'QTY', item.unit_id);
				}else{
					if ((!item.item_id) || ((item.item_id) && (item.item_id.trim() == ''))) {
						this.manufacturer_catalog_populate_line(frm, cdt, cdn, 'QTY', item.unit_id);
					}else{
						this.populate_line(frm, cdt, cdn, 'QTY', item.unit_id);
					}
				}
			}else
			{
				if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
				{	this.convert_in_stock_unit(this.frm, cdt, cdn, 'quoted_qty', 'stock_quoted_qty');
					this.convert_in_stock_unit(this.frm, cdt, cdn, 'ordered_qty', 'stock_ordered_qty');
					this.convert_in_stock_unit(this.frm, cdt, cdn, 'free_part_qty', 'stock_free_part_qty');
				}else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
				{	this.convert_in_stock_unit(this.frm, cdt, cdn, 'ordered_qty', 'stock_ordered_qty');
					this.convert_in_stock_unit(this.frm, cdt, cdn, 'delivered_qty', 'stock_delivered_qty');
					this.convert_in_stock_unit(this.frm, cdt, cdn, 'invoiced_qty', 'stock_invoiced_qty');
					this.convert_in_stock_unit(this.frm, cdt, cdn, 'returned_qty', 'stock_returned_qty');
					this.convert_in_stock_unit(this.frm, cdt, cdn, 'free_part_qty', 'stock_free_part_qty');
					this.convert_in_stock_unit(this.frm, cdt, cdn, 'reserved_qty', 'stock_reserved_qty');
				}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
				{	this.convert_in_stock_unit(this.frm, cdt, cdn, 'delivered_qty', 'stock_delivered_qty');
					this.convert_in_stock_unit(this.frm, cdt, cdn, 'invoiced_qty', 'stock_invoiced_qty');
					this.convert_in_stock_unit(this.frm, cdt, cdn, 'ordered_qty', 'stock_ordered_qty');
					this.convert_in_stock_unit(this.frm, cdt, cdn, 'free_part_qty', 'stock_free_part_qty');
					this.convert_in_stock_unit(this.frm, cdt, cdn, 'reserved_qty', 'stock_reserved_qty');
				}else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
				{	this.convert_in_stock_unit(this.frm, cdt, cdn, 'invoiced_qty', 'stock_invoiced_qty');
					this.convert_in_stock_unit(this.frm, cdt, cdn, 'delivered_qty', 'stock_delivered_qty');
					this.convert_in_stock_unit(this.frm, cdt, cdn, 'free_part_qty', 'stock_free_part_qty');
				}
			}
		}
		else
		{
			if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
			{	this.convert_in_stock_unit(this.frm, cdt, cdn, 'quoted_qty', 'stock_quoted_qty');
				this.convert_in_stock_unit(this.frm, cdt, cdn, 'ordered_qty', 'stock_ordered_qty');
				this.convert_in_stock_unit(this.frm, cdt, cdn, 'free_part_qty', 'stock_free_part_qty');
			}else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
			{	this.convert_in_stock_unit(this.frm, cdt, cdn, 'ordered_qty', 'stock_ordered_qty');
				this.convert_in_stock_unit(this.frm, cdt, cdn, 'delivered_qty', 'stock_delivered_qty');
				this.convert_in_stock_unit(this.frm, cdt, cdn, 'invoiced_qty', 'stock_invoiced_qty');
				this.convert_in_stock_unit(this.frm, cdt, cdn, 'returned_qty', 'stock_returned_qty');
				this.convert_in_stock_unit(this.frm, cdt, cdn, 'free_part_qty', 'stock_free_part_qty');
				this.convert_in_stock_unit(this.frm, cdt, cdn, 'reserved_qty', 'stock_reserved_qty');
			}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
			{	this.convert_in_stock_unit(this.frm, cdt, cdn, 'delivered_qty', 'stock_delivered_qty');
				this.convert_in_stock_unit(this.frm, cdt, cdn, 'invoiced_qty', 'stock_invoiced_qty');
				this.convert_in_stock_unit(this.frm, cdt, cdn, 'ordered_qty', 'stock_ordered_qty');
				this.convert_in_stock_unit(this.frm, cdt, cdn, 'free_part_qty', 'stock_free_part_qty');
				this.convert_in_stock_unit(this.frm, cdt, cdn, 'reserved_qty', 'stock_reserved_qty');
			}else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
			{	this.convert_in_stock_unit(this.frm, cdt, cdn, 'invoiced_qty', 'stock_invoiced_qty');
				this.convert_in_stock_unit(this.frm, cdt, cdn, 'delivered_qty', 'stock_delivered_qty');
				this.convert_in_stock_unit(this.frm, cdt, cdn, 'free_part_qty', 'stock_free_part_qty');
			}

/*			We are on manual mode, the system doesn't adjust qties anymore
			Keep the code below, it could be used

			if (item.min_order_qty)
			{
				min_order_qty = item.min_order_qty;
			}

			if (item.multiple_order_qty)
			{
				multiple_order_qty = item.multiple_order_qty;
			}

			if (min_order_qty == 0)
			{
				if (multiple_order_qty > 1)
				{
					if (qt < multiple_order_qty)
					{
						qt = multiple_order_qty;
					}else
					{
						let n = 1
						let q = 0;

						while (true)
						{
							q = multiple_order_qty * n;

							if (q >= qt)
							{
								qt = q;
								break;
							}

							n++;
						}
					}

					if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
					{
						item.quoted_qty = qt;
					}else
					{
						alert("to be define");
					}
				}
			}else
			{	if (qt < min_order_qty)
				{
					qt = min_order_qty;
				}

				if (multiple_order_qty > 1)
				{
					if (qt < multiple_order_qty)
					{
						qt = multiple_order_qty;
					}else
					{
						let n = 1
						let q = 0;

						while (true)
						{
							q = multiple_order_qty * n;

							if (q >= qt)
							{
								qt = q;
								break;
							}

							n++;
						}
					}
				}

				if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
				{
					item.quoted_qty = qt;
				}else
				{
					alert("to be define");
				}
			}
*/
		}

		if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
		{
			if ((item.delivered_qty) && (item.delivered_qty < 0))
			{
				if (this.journal_deliveries_negative_qty_warehouse)
				{
					if (this.journal_deliveries_negative_qty_warehouse.trim() != '')
					{
						if (item.warehouse_id != this.journal_deliveries_negative_qty_warehouse)
						{
							item.warehouse_id = this.journal_deliveries_negative_qty_warehouse;
							item.warehouse_location_id = '';
							item.batch_sn_id = '';
							item.batch_sn_origin = '';
							item.batch_sn_code_ref = '';

						}
					}
				}
			}else
			{
				if (this.journal_deliveries_negative_qty_warehouse)
				{
					if (this.journal_deliveries_negative_qty_warehouse.trim() != '')
					{
						if (item.warehouse_id == this.journal_deliveries_negative_qty_warehouse)
						{
							item.warehouse_id = this.frm.doc.warehouse_id;
							item.warehouse_location_id = '';
							item.batch_sn_id = '';
							item.batch_sn_origin = '';
							item.batch_sn_code_ref = '';

						}
					}
				}
			}
		}

		if (item.withme == 1)
		{
			item.sales_condition_id = this.customer_manual_sales_condition_id;
		}
	}

	// ***************************************************************************************************************************************
	// Set a trace to the log
	// ***************************************************************************************************************************************
	#set_trace(obj, log_detail, from_fieldname, fieldname, error_message, db_save)
	{
		let error_row = obj.frm.add_child(log_detail);
		error_row.from_fieldname = from_fieldname;
		error_row.fieldname = fieldname;
		error_row.error_message = error_message;
		error_row.db_save = db_save;
		obj.frm.refresh_field(log_detail);

		obj.frm.set_intro(error_message, 'red');
		frappe.show_alert({ message:error_message, indicator:'red'}, 5);
	}

	// ***************************************************************************************************************************************
	// Format Child Detail form
	// ***************************************************************************************************************************************
	format_detail_form(frm, cdt, cdn)
	{
		let item = locals[cdt][cdn];

		if ((this.frm.doctype.toUpperCase() == 'IOI SALES ORDER') || (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE'))
		{
			if ((item.is_warning) && (item.is_warning != 0))
			{
				let msg_bgcolor = '';
				let msg_fontcolor = ''

				if (item.is_warning == 1)
				{	msg_bgcolor = '#FFF5F0';
					msg_fontcolor = '#F57231';
				}else
				{	msg_bgcolor = '#FEADAD';
					msg_fontcolor = 'black';
				}

				if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form)
				{

					if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_warning'])
					{
						this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_warning'].$wrapper.empty();

						let html = '';

						html = '<br><div align="center" style="overflow:auto; vertical-align: middle; height:28px;border-radius:6px;padding:4px;background-color:' + msg_bgcolor + ';">';
						html += '<font color="' + msg_fontcolor +'">' + item.warninfo + '</font>';
						html += '</div>';

						this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_warning'].$wrapper.append(html);
					}
				}
			}else
			{
				if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form)
				{
					if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_warning'])
					{
						this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_warning'].$wrapper.empty();
					}
				}
			}

		}

		let me = this;

		this.format_detail_legend(item);

		this.#refresh_detail_ioistatus(frm, cdt, cdn);

		if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
			this.#refresh_detail_dossier_bom_routing(frm, cdt, cdn);
		}


		this.form_format_all_qties(item.idx);


		this.#detail_set_original_caption_with_currency(item, 'line_detail', 'unit_price');
		this.#detail_set_original_caption_with_currency(item, 'line_detail', 'fixed_price');
		this.#detail_set_original_caption_with_currency(item, 'line_detail', 'value_discount');
		this.#detail_set_original_caption_with_currency(item, 'line_detail', 'unit_price_tvac');
		this.#detail_set_original_caption_with_currency(item, 'line_detail', 'ext_unit_price');

		this.#detail_set_original_caption_with_currency(item, 'line_detail', 'value_line_doc_currency');
		this.#detail_set_original_caption_with_currency(item, 'line_detail', 'value_line_sys_currency');
		this.#detail_set_original_caption_with_currency(item, 'line_detail', 'internal_value_line_option');
		this.#detail_set_original_caption_with_currency(item, 'line_detail', 'internal_line_doc_no_esc');

		this.#detail_set_original_caption_with_currency(item, 'line_detail', 'sub_total');
		this.#detail_set_original_caption_with_currency(item, 'line_detail', 'item_cost_std_stamp');
		this.#detail_set_original_caption_with_currency(item, 'line_detail', 'item_cost');

		this.#detail_set_original_caption_with_currency(item, 'line_detail', 'manual_cost');
		this.#detail_set_original_caption_with_currency(item, 'line_detail', 'cost_value');
		this.#detail_set_original_caption_with_currency(item, 'line_detail', 'purch_unit_price_fnd');
		this.#detail_set_original_caption_with_currency(item, 'line_detail', 'total_supplier_price');


		if (this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form) {

			if (this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['invoicing_mode']) {

				if ((this.frm.doc.ioistatus != 0) || (this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['invoicing_mode'].df.read_only == 1)) {

					let invoicing_mode = cur_frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['invoicing_mode'].value;

					if ((!invoicing_mode) || ((invoicing_mode) && (invoicing_mode == 0))) {
						this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['invoicing_mode'].$input_wrapper[0].children[1].innerText = __('Default');
					}else if (invoicing_mode == 1) {
						this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['invoicing_mode'].$input_wrapper[0].children[1].innerText = __('Up to ordered');
					}else if (invoicing_mode == 2) {
						this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['invoicing_mode'].$input_wrapper[0].children[1].innerText = __('Fix ordered');
					}else if (invoicing_mode == 3) {
						this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['invoicing_mode'].$input_wrapper[0].children[1].innerText = __('Open order');
					}

				}
			}
		}


		let fct_whs = function () {	me.set_query('warehouse_id', 'line_detail', me.path_warehouse + '.ioi_warehouse_get_enabled', '{"site_id":"' + me.frm.doc.site_id + '"}'); };
		setTimeout(fct_whs(), 200)



		if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form)
		{
			if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
			{

				if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['shipping_datetime'].wrapper.children[0].children[1].children[2])
				{
					this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['shipping_datetime'].wrapper.children[0].children[1].children[2].remove();
				}

				if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['shipping_scheduled_datetime'].wrapper.children[0].children[1].children[2])
				{
					this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['shipping_scheduled_datetime'].wrapper.children[0].children[1].children[2].remove();
				}

				if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['expected_arrival_on'].wrapper.children[0].children[1].children[2])
				{
					this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['expected_arrival_on'].wrapper.children[0].children[1].children[2].remove();
				}

				this.#fill_line_available_status(item);
				this.#fill_line_available_scheduled_status(item);
				this.#build_contract_price_detail('price_contract_html', item);
				this.#build_linked_document_detail('quote_master_link', 'quote_detail_link', 'html_quote_detail', item, '')
			}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
			{	this.#build_html_location_buttons('html_location_buttons', cdt, cdn);
				this.#build_html_batch_buttons('html_batch_buttons', cdt, cdn);
				this.#build_linked_document_detail('order_master_link', 'order_detail_link', 'html_order_detail', item, '')
			}else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
			{	this.#build_linked_document_detail('delivery_master_link', 'delivery_detail_link', 'html_delivery_detail', item, 'D')
				this.#build_linked_document_detail('order_master_link', 'order_detail_link', 'html_order_detail', item, 'O')
			}

			const grid_row = this.frm.fields_dict["line_detail"].grid.grid_rows_by_docname[item.idx-1];

			if (grid_row)
			{
				const item_index_ref_date = grid_row.docfields.filter(f => f.fieldname == "index_ref_date")[0];
				const item_index_coef = grid_row.docfields.filter(f => f.fieldname == "index_coef")[0];
				const item_index_ref_value = grid_row.docfields.filter(f => f.fieldname == "index_ref_value")[0];
				const item_index_cur_value = grid_row.docfields.filter(f => f.fieldname == "index_cur_value")[0];
				const item_index_active = grid_row.docfields.filter(f => f.fieldname == "index_active")[0];

				let field_readonly = false;

				if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
				{
					if ((item.quote_master_link) && (item.quote_master_link.trim() != ''))
					{
						field_readonly = true;
					}
				}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
				{
					if ((item.order_master_link) && (item.order_master_link.trim() != ''))
					{
						field_readonly = true;
					}

				}else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
				{
					if (((item.order_master_link) && (item.order_master_link.trim() != '')) ||
						((item.delivery_master_link) && (item.delivery_master_link.trim() != '')))
					{
						field_readonly = true;
					}
				}

				item_index_ref_date.read_only = field_readonly;
				item_index_coef.read_only = field_readonly;
				item_index_ref_value.read_only = field_readonly;
				item_index_cur_value.read_only = field_readonly;
				item_index_active.read_only = field_readonly;

			}




			for (var i = 0; i < this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections.length; i++)
			{
				if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head)
				{
					this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.attr('data-custom-section-head', 'true');
					this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.css('border-radius', '6px');
					this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.css('padding', '4px');

					if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].is_collapsed())
					//if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head[0].className == 'section-head collapsed')
					{	this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.click();
					}
					//this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.click();
				}

			}

			for(var k = 0; k < this.source_color.length; k++)
			{
				if (item.unit_price_source)
				{
					if (this.source_color[k][0] == item.unit_price_source.toUpperCase())
					{
						this.update_source(null, null, null, 'unit_price', item, this.source_color[k][1]);
					}
				}else
				{
					this.update_source(null, null, null, 'unit_price', item, null);
				}

				if (item.unit_price_tvac_source)
				{
					if (this.source_color[k][0] == item.unit_price_tvac_source.toUpperCase())
					{
						this.update_source(null, null, null, 'unit_price_tvac', item, this.source_color[k][1]);
					}
				}else
				{
					this.update_source(null, null, null, 'unit_price_tvac', item, null);
				}

				if (item.fixed_price_source)
				{
					if (this.source_color[k][0] == item.fixed_price_source.toUpperCase())
					{
						this.update_source(null, null, null, 'fixed_price', item, this.source_color[k][1]);
					}
				}else
				{
					this.update_source(null, null, null, 'fixed_price', item, null);
				}

				if (item.base_discount_source)
				{
					if (this.source_color[k][0] == item.base_discount_source.toUpperCase())
					{
						this.update_source(null, null, null, 'base_discount', item, this.source_color[k][1]);
					}
				}else
				{
					this.update_source(null, null, null, 'base_discount', item, null);
				}

				if (item.extra_discount_source)
				{
					if (this.source_color[k][0] == item.extra_discount_source.toUpperCase())
					{
						this.update_source(null, null, null, 'extra_discount', item, this.source_color[k][1]);
					}
				}else
				{
					this.update_source(null, null, null, 'extra_discount', item, null);
				}


				if (item.discount_value_source)
				{
					if (this.source_color[k][0] == item.discount_value_source.toUpperCase())
					{
						this.update_source(null, null, null, 'value_discount', item, this.source_color[k][1]);
					}
				}else
				{
					this.update_source(null, null, null, 'value_discount', item, null);
				}

				if (item.min_order_qty_source)
				{
					if (this.source_color[k][0] == item.min_order_qty_source.toUpperCase())
					{
						this.update_source(null, null, null, 'min_order_qty', item, this.source_color[k][1]);
					}
				}else
				{
					this.update_source(null, null, null, 'min_order_qty', item, null);
				}

				if (item.multiple_order_qty_source)
				{
					if (this.source_color[k][0] == item.multiple_order_qty_source.toUpperCase())
					{
						this.update_source(null, null, null, 'multiple_order_qty', item, this.source_color[k][1]);
					}
				}else
				{
					this.update_source(null, null, null, 'multiple_order_qty', item, null);
				}

				if (item.free_part_num_source)
				{
					if (this.source_color[k][0] == item.free_part_num_source.toUpperCase())
					{
						this.update_source(null, null, null, 'free_part_num', item, this.source_color[k][1]);
					}
				}else
				{
					this.update_source(null, null, null, 'free_part_num', item, null);
				}

				if (item.free_part_den_source)
				{
					if (this.source_color[k][0] == item.free_part_den_source.toUpperCase())
					{
						this.update_source(null, null, null, 'free_part_den', item, this.source_color[k][1]);
					}
				}else
				{
					this.update_source(null, null, null, 'free_part_den', item, null);
				}
			}

			this.update_source(null, null, null, 'free_part_qty', item, null);
			this.update_source(null, null, null, 'margin_alert', item, null);
			this.update_source(null, null, null, 'alert_margin_mode', item, null);
		}
	}

	// ***************************************************************************************************************************************
	// Clear source
	// ***************************************************************************************************************************************
	clear_source(frm, cdt, cdn, fieldname)
	{
		let item = locals[cdt][cdn];

		let fn = fieldname.toUpperCase();

		if (fn == 'UNIT_PRICE')
		{	item.unit_price_source = '';
		}else if (fn == 'BASE_DISCOUNT')
		{	item.base_discount_source = '';
		}else if (fn == 'EXTRA_DISCOUNT')
		{	item.extra_discount_source = '';
		}else if (fn == 'DISCOUNT_VALUE')
		{	item.discount_value_source = '';
		}else if (fn == 'UNIT_PRICE_TAC')
		{	item.unit_price_tvac_source = '';
		}else if (fn == 'FIXED_PRICE')
		{	item.fixed_price_source = '';
		}else if (fn == 'MIN_ORDER_QTY')
		{	item.min_order_qty_source = '';
		}else if (fn == 'MULTIPLE_ORDER_QTY')
		{	item.multiple_order_qty_source = '';
		}else if (fn == 'FREE_PART_NUM')
		{	item.free_part_num_source = '';
		}else if (fn == 'FREE_PART_DEN')
		{	item.free_part_den_source = '';
		}
	}

	// ***************************************************************************************************************************************
	// Update source
	// ***************************************************************************************************************************************
	update_source(frm, cdt, cdn, fieldname, it, color)
	{
		let item = '';
		let color_grid = ''

		if (!it)
		{	item = locals[cdt][cdn];
		}else
		{	item = it;
		}

		if (!color)
		{
			item[fieldname+'_source'] = '';
			color_grid = '#FFFFFF';
		}else
		{
			color_grid = color;
		}


		if (this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form)
		{
			if (!color)
			{ 	color = '#F4F5F6';

				if (this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].df.read_only == 1)
				{
					color = '#F9FAFA';
				}
			}

			if (this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].df.read_only == 0)
			{
				if (this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].wrapper.children[0].children[1].children[0].hasChildNodes())
				{
					this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].wrapper.children[0].children[1].children[0].children[0].style.backgroundColor = color;
				}else
				{
					this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].wrapper.children[0].children[1].children[1].style.backgroundColor = color;
				}
			}else
			{	this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].wrapper.children[0].children[1].children[1].style.backgroundColor = color;
			}
		}

		for (var m = 0; m < this.frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++)
		{
			if (item[fieldname+'_source'] == '')
			{
				if (this.frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == fieldname.toUpperCase())
				{
					this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FFFFFF';
					break;
				}
			}
		}


		if ((fieldname.toUpperCase() == 'BASE_DISCOUNT') || (fieldname.toUpperCase() == 'EXTRA_DISCOUNT') || (fieldname.toUpperCase() == 'FREE_PART_QTY') || (fieldname.toUpperCase() == 'MARGIN_ALERT')) {

			if ((item.margin_alert) && (item.margin_alert != 0)) {

				if (this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form) {

					if (this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].df.read_only == 0)
					{
						if ((item.alert_margin_rate) && (item.alert_margin_rate != 0)) {

							if (item.margin_alert == -1) {

								if (this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].wrapper.children[0].children[1].children[0].hasChildNodes()) {
									this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].wrapper.children[0].children[1].children[0].children[0].style.backgroundColor = 'orange';
								}else{
									this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].wrapper.children[0].children[1].children[1].style.backgroundColor = 'orange';
								}
							}else{
								if (this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].wrapper.children[0].children[1].children[0].hasChildNodes()) {
									this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].wrapper.children[0].children[1].children[0].children[0].style.backgroundColor = 'red';
								}else{
									this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].wrapper.children[0].children[1].children[1].style.backgroundColor = 'red';
								}
							}
						}
					}else{

						if ((item.alert_margin_rate) && (item.alert_margin_rate != 0)) {

							if (item.margin_alert == -1) {
								this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].wrapper.children[0].children[1].children[1].style.backgroundColor = 'orange';
							}else{
								this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].wrapper.children[0].children[1].children[1].style.backgroundColor = 'red';
							}
						}
					}
				}
			}
		}

		if ((fieldname.toUpperCase() == 'MARGIN_ALERT') || (fieldname.toUpperCase() == 'ALERT_MARGIN_MODE')) {

			if (this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form) {

				if (fieldname.toUpperCase() == 'MARGIN_ALERT') {

					if (this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].value == 0) {
						this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].wrapper.children[0].children[1].children[1].innerText = __("Ok");
					}else if (this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].value == -1) {
						this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].wrapper.children[0].children[1].children[1].innerText = __("Margin alert");
					}else if (this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].value == -2) {
						this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].wrapper.children[0].children[1].children[1].innerText = __("Margin error");
					}

				}else{
					if (this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].value == 0) {
						this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].wrapper.children[0].children[1].children[1].innerText = __("Cost margin");
					}else if (this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].value == 1) {
						this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].wrapper.children[0].children[1].children[1].innerText = __("Sales margin");
					}

				}
			}

		}


		if ((fieldname.toUpperCase() == 'UNIT_PRICE') || (fieldname.toUpperCase() == 'BASE_DISCOUNT') || (fieldname.toUpperCase() == 'EXTRA_DISCOUNT')) {

			if (this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form) {

				if (item.ftg_priority == -1) {

					if (this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].df.read_only == 0)
					{
						if (this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].wrapper.children[0].children[1].children[0].hasChildNodes())
						{
							this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].wrapper.children[0].children[1].children[0].children[0].style.backgroundColor = '#ffe499';
						}else
						{
							this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].wrapper.children[0].children[1].children[1].style.backgroundColor = '#ffe499';
						}
					}else
					{	this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].wrapper.children[0].children[1].children[1].style.backgroundColor = '#ffe499';
					}

				}
			}

		}
	}

	// ***************************************************************************************************************************************
	// Format header legend
	// ***************************************************************************************************************************************
	format_header_legend()
	{
		let html = '';

		this.frm.fields_dict['html_header_legend'].$wrapper.empty();

		html += '<div style="overflow:auto; height:32px">';
		html += '<table border=0 style="border: 0px solid #E8EAEB" align="right">';
		html += '<tr>';
		html += '<td width=100px height=30px style="vertical-align: middle;"><b>'+ __('Value from') + '</b></td>';

		for (var i = 0; i < this.source_color.length; i++)
		{
			html += '<td>';
			html += '<div style="height:30px;width:110px;background-color:' + this.source_color[i][1] + ';color:black; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
			html += '<div align="center">' + this.source_color[i][2] + '</div></div>';
			html += '</td>';
			html += '<td width=8px height=30px></td>';
		}
		html += '</tr>';

		html += '</table>';
		html += '</div>';

		this.frm.fields_dict['html_header_legend'].$wrapper.append(html);
	}


	// ***************************************************************************************************************************************
	// Format detail legend
	// ***************************************************************************************************************************************
	format_detail_legend(item)
	{
		if (this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form)
		{
			let html = '';

			this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['html_legend'].$wrapper.empty();

			html += '<div style="overflow:auto; height:32px">';
			html += '<table border=0 style="border: 0px solid #E8EAEB" align="left">';
			html += '<tr>';
			html += '<td width=100px height=30px style="vertical-align: middle;"><b>'+ __('Value from') + '</b></td>';

			for (var i = 0; i < this.source_color.length; i++)
			{
				html += '<td>';
				html += '<div style="height:30px;width:110px;background-color:' + this.source_color[i][1] + '; color:black; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
				html += '<div align="center">' + this.source_color[i][2] + '</div></div>';
				html += '</td>';
				html += '<td width=8px height=30px></td>';
			}
			html += '</tr>';

			html += '</table>';
			html += '</div>';

			this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['html_legend'].$wrapper.append(html);
		}
	}

	static grid_format_float_qty(element, gridcell)
	{

		let original_value = element.innerText.trim()

		if (gridcell != null) {

			let fct_blur = function() {
				silicon_ioi.doctype.ioiSalesDocumentDocType.grid_format_float_qty(element, gridcell);
			}

			gridcell.onblur = fct_blur;
		}


		if ((original_value != '') && (original_value != 0)) {

			original_value = original_value.replaceAll(' ', '');

			let idx_coma = -1;
			let idx_point = -1;

			if (original_value.indexOf(',') != -1) {
				idx_coma = original_value.indexOf(',');
			}

			if (original_value.indexOf('.') != -1) {
				idx_point = original_value.indexOf('.');
			}

			if ((idx_coma != -1) || (idx_point != -1)) {

				let is_point = false;

				if (idx_coma < idx_point) {

					if (idx_coma != -1) {
						original_value = original_value.replaceAll(',', '');
						is_point = true;
					}

				}else if (idx_coma > idx_point)
				{
					if (idx_point != -1) {
						original_value = original_value.replaceAll('.', '');
					}
				}

				let can_continue = false;

				if (is_point)
				{
					if (original_value.indexOf('.') != -1) {
						can_continue = true;
					}

				}else{
					if (original_value.indexOf(',') != -1) {
						can_continue = true;
					}

				}

				if (can_continue) {

					can_continue = true;

					let is_number = true;

					if (isNaN(original_value)) {

						if (original_value.indexOf('.') != -1) {
							original_value = original_value.replaceAll('.', ',');
						}else if (original_value.indexOf(',') != -1) {
							original_value = original_value.replaceAll(',', '.');
						}else{
							can_continue = false;
						}

						is_number = false;
					}

					if (can_continue) {

						let modified_value = parseFloat(original_value);

						if (!is_number) {

							if (modified_value.toString().indexOf('.') != -1) {
								modified_value = modified_value.toString().replaceAll('.', ',');
							}else if (modified_value.toString().indexOf(',') != -1) {
								modified_value = modified_value.toString().replaceAll(',', '.');
							}
						}

						element.innerText = modified_value;
					}
				}
			}
		}

	}


	form_format_qty_float(fieldname, grid_idx)
	{
		if (cur_frm.get_field('line_detail').grid.grid_rows[grid_idx-1].grid_form) {

			let original_value = '';

			if (cur_frm.get_field('line_detail').grid.grid_rows[grid_idx-1].grid_form.fields_dict[fieldname].input) {
				original_value = cur_frm.get_field('line_detail').grid.grid_rows[grid_idx-1].grid_form.fields_dict[fieldname].input.value.trim();

				let me = this;
				let fct_blur = function() {
					me.form_format_qty_float(fieldname, grid_idx);
				}
				cur_frm.get_field('line_detail').grid.grid_rows[grid_idx-1].grid_form.fields_dict[fieldname].input.onblur = fct_blur;

			}else{
				original_value = cur_frm.get_field('line_detail').grid.grid_rows[grid_idx-1].grid_form.fields_dict[fieldname].$input_wrapper[0].children[1].innerText.trim();
			}


			if ((original_value != '') && (original_value != 0)) {

				original_value = original_value.replaceAll(' ', '');

				let idx_coma = -1;
				let idx_point = -1;

				if (original_value.indexOf(',') != -1) {
					idx_coma = original_value.indexOf(',');
				}

				if (original_value.indexOf('.') != -1) {
					idx_point = original_value.indexOf('.');
				}

				if ((idx_coma != -1) || (idx_point != -1)) {

					let is_point = false;

					if (idx_coma < idx_point) {

						if (idx_coma != -1) {
							original_value = original_value.replaceAll(',', '');
							is_point = true;
						}

					}else if (idx_coma > idx_point)
					{
						if (idx_point != -1) {
							original_value = original_value.replaceAll('.', '');
						}
					}

					let can_continue = false;

					if (is_point)
					{
						if (original_value.indexOf('.') != -1) {
							can_continue = true;
						}

					}else{
						if (original_value.indexOf(',') != -1) {
							can_continue = true;
						}

					}

					if (can_continue) {

						can_continue = true;

						let is_number = true;

						if (isNaN(original_value)) {

							if (original_value.indexOf('.') != -1) {
								original_value = original_value.replaceAll('.', ',');
							}else if (original_value.indexOf(',') != -1) {
								original_value = original_value.replaceAll(',', '.');
							}else{
								can_continue = false;
							}

							is_number = false;
						}

						if (can_continue) {

							let modified_value = parseFloat(original_value);

							if (!is_number) {

								if (modified_value.toString().indexOf('.') != -1) {
									modified_value = modified_value.toString().replaceAll('.', ',');
								}else if (modified_value.toString().indexOf(',') != -1) {
									modified_value = modified_value.toString().replaceAll(',', '.');
								}
							}


							if (cur_frm.get_field('line_detail').grid.grid_rows[grid_idx-1].grid_form.fields_dict[fieldname].input) {
								cur_frm.get_field('line_detail').grid.grid_rows[grid_idx-1].grid_form.fields_dict[fieldname].input.value = modified_value;
							}else{
								cur_frm.get_field('line_detail').grid.grid_rows[grid_idx-1].grid_form.fields_dict[fieldname].$input_wrapper[0].children[1].innerText = modified_value;
							}

						}
					}
				}
			}
		}
	}

	form_format_all_qties(grid_idx)
	{
		if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') {

			this.form_format_qty_float('quoted_qty', grid_idx);
			this.form_format_qty_float('ordered_qty', grid_idx);
			this.form_format_qty_float('free_part_qty', grid_idx);
			this.form_format_qty_float('min_order_qty', grid_idx);
			this.form_format_qty_float('multiple_order_qty', grid_idx);
			this.form_format_qty_float('free_part_num', grid_idx);
			this.form_format_qty_float('free_part_den', grid_idx);

		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {

			this.form_format_qty_float('ordered_qty', grid_idx);
			this.form_format_qty_float('delivered_qty', grid_idx);
			this.form_format_qty_float('invoiced_qty', grid_idx);
			this.form_format_qty_float('stock_invoiced_qty', grid_idx);
			this.form_format_qty_float('returned_qty', grid_idx);
			this.form_format_qty_float('free_part_qty', grid_idx);
			this.form_format_qty_float('reserved_qty', grid_idx);
			this.form_format_qty_float('min_order_qty', grid_idx);
			this.form_format_qty_float('multiple_order_qty', grid_idx);
			this.form_format_qty_float('free_part_num', grid_idx);
			this.form_format_qty_float('free_part_den', grid_idx);
			this.form_format_qty_float('available_qty', grid_idx);
			this.form_format_qty_float('available_scheduled_qty', grid_idx);

		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {

			this.form_format_qty_float('ordered_qty', grid_idx);
			this.form_format_qty_float('delivered_qty', grid_idx);
			this.form_format_qty_float('free_part_qty', grid_idx);
			this.form_format_qty_float('reserved_qty', grid_idx);
			this.form_format_qty_float('min_order_qty', grid_idx);
			this.form_format_qty_float('multiple_order_qty', grid_idx);
			this.form_format_qty_float('free_part_num', grid_idx);
			this.form_format_qty_float('free_part_den', grid_idx);
			this.form_format_qty_float('invoiced_qty', grid_idx);
			this.form_format_qty_float('qty_to_prepare', grid_idx);

		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {

			this.form_format_qty_float('invoiced_qty', grid_idx);
			this.form_format_qty_float('delivered_qty', grid_idx);
			this.form_format_qty_float('free_part_qty', grid_idx);
			this.form_format_qty_float('min_order_qty', grid_idx);
			this.form_format_qty_float('multiple_order_qty', grid_idx);
			this.form_format_qty_float('free_part_num', grid_idx);
			this.form_format_qty_float('free_part_den', grid_idx);
		}
	}




	// ***************************************************************************************************************************************
	// Line detail : add new line
	// ***************************************************************************************************************************************
	line_detail_add(frm, cdt, cdn)
	{
		let item = locals[cdt][cdn];

		item.guid = this.#createGUID();
		item.ioistatus = this.frm.doc.ioistatus;
		item.sales_condition_id = this.frm.doc.sales_condition_id;
		item.warehouse_id = this.frm.doc.warehouse_id;
		this.set_query('warehouse_id', 'line_detail', this.path_warehouse + '.ioi_warehouse_get_enabled', '{"site_id":"' + this.frm.doc.site_id + '"}');


		if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {

			item.location_active = 0;

			if ((item.warehouse_id) && (item.warehouse_id.trim() != '')) {

				let me = this;

				let method = this.path_warehouse + '.ioi_warehouse_get_data';

				frappe.call({  	method: method,
								args: {	"warehouse_id" : item.warehouse_id
							},
								async: false,
								callback:function(r)	{

									item.location_active = 0;

									if (r.message[0].location_active) {
										item.location_active = r.message[0].location_active;
									}


								}
				});

			}

		}



		item.dossier_id = this.frm.doc.dossier_id;

		this.#detail_fill_vat_type();
		item.vat_type = this.frm.doc.vat_type;
		item.vat_matching_id = this.frm.doc.vat_matching_id;
		item.vat_rate = this.frm.doc.spec_vat_rate;
		item.invoicing_mode = 0;

		if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {

			if ((item.vat_matching_id) && (item.vat_matching_id.trim() != '')) {

				let method = this.path_sales_document + '.ioi_sales_document_get_vatcode';

				frappe.call({  	method: method,
								args: {	"vat_type": item.vat_type,
										"vat_rate": item.vat_rate,
										"vat_service": null,
										"vat_matching": item.vat_matching_id,
										"account_id": null

									},
								async: false,
								callback:function(r)	{
									if (r.message.vat_code_id != '') {
										item.vat_rate = r.message.vat_percentage;
										item.vatcode_id = r.message.vat_code_id;
										item.manual_vat = 1;
									}
								}
				});

			}
		}



		item.unit_price = 0;
		item.unit_price_tvac = 0;
		item.fixed_price = 0;
		item.base_discount = 0;
		item.extra_discount = 0;
		item.discount_in_value = 0;
		item.discount_only = 0;
		item.vat_included_in_price = 0;
		item.is_option = 0;



		if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
		{
			item.quoted_qty = 0;
			item.ordered_qty = 0;

			item.section =  this.frm.doc.line_detail[item.idx - 2] ? this.frm.doc.line_detail[item.idx - 2].section : null
			item.subsection =  this.frm.doc.line_detail[item.idx - 2] ? this.frm.doc.line_detail[item.idx - 2].subsection : null
			item.tech_responsible_id = this.frm.doc.line_detail[item.idx - 2] ? this.frm.doc.line_detail[item.idx - 2].tech_responsible_id : null

			item.dossier_sequence = 1;

			if (item.idx > 1) {
				item.dossier_sequence = this.frm.doc.line_detail[item.idx - 2].dossier_sequence;
			}

		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
		{
			item.ordered_qty = 0;
			item.delivered_qty = 0;

			item.section =  this.frm.doc.line_detail[item.idx - 2] ? this.frm.doc.line_detail[item.idx - 2].section : null
			item.subsection =  this.frm.doc.line_detail[item.idx - 2] ? this.frm.doc.line_detail[item.idx - 2].subsection : null
			item.tech_responsible_id = this.frm.doc.line_detail[item.idx - 2] ? this.frm.doc.line_detail[item.idx - 2].tech_responsible_id : null

			item.dossier_sequence = 1;

			if (item.idx > 1) {
				item.dossier_sequence = this.frm.doc.line_detail[item.idx - 2].dossier_sequence;
			}


			item.shipping_datetime = this.frm.doc.shipping_datetime;
			item.shipping_scheduled_datetime = this.frm.doc.shipping_datetime;

			if ((item.shipping_datetime) && (item.shipping_datetime.toString().trim() != ''))
			{
				let me = this;

				let method = this.path_sales_order + '.ioi_sales_order_compute_expected_arrival_date';

				frappe.call({  	method: method,
								args: {"prefix_id": this.frm.doc.prefix_id, "customer_id": this.frm.doc.order_customer_id, "shipping_datetime": item.shipping_datetime},
								async: false,
								callback:function(r)	{
															item.expected_arrival_on = r.message;
														}
				});
			}
		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
		{
			item.delivered_qty = 0;
			item.invoiced_qty = 0;
			item.stored_qty_mode = 0;

		}
		if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
		{
			item.invoiced_qty = 0;

		}




		item.free_part_qty = 0;
		item.min_qty_for_price = 0;
		item.value_line_doc_currency = 0;
		item.value_line_sys_currency = 0;
		item.internal_value_line_option = 0;
		item.sub_total = 0;
		item.item_cost_std_stamp = 0;
		item.item_cost = 0;

		item.document_date = this.frm.doc.document_date;
		item.period_id = this.frm.doc.period_id;


		if (document.getElementById('hidden_can_edit_document').value == '0')
		{	item.can_remove = 2;
			this.frm.get_field("line_detail").grid.grid_rows[item.idx-1].remove();
		}


		this.frm.refresh_field('line_detail');
		silicon_ioi.doctype.ioiSalesDocumentDocType.set_static_grid_layout(null);


		silicon_ioi.doctype.ioiSalesDocumentDocType.sleep_static(100).then(() => {
			if (cur_frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form)
			{
				for (var i = 0; i < cur_frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections.length; i++)
				{
					if (cur_frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head)
					{
						cur_frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.click();
					}

				}
			}
		});




	}

	// ***************************************************************************************************************************************
	// Line detail : fill vat_type
	// ***************************************************************************************************************************************
	#detail_fill_vat_type()
	{
		this.frm.fields_dict['line_detail'].grid.update_docfield_property("vat_type", "options", [	{ label: this.vat_type[0][1], value: this.vat_type[0][0]},
																									{ label: this.vat_type[1][1], value: this.vat_type[1][0]},
																									{ label: this.vat_type[2][1], value: this.vat_type[2][0]},
																									{ label: this.vat_type[3][1], value: this.vat_type[3][0]},
																									{ label: this.vat_type[4][1], value: this.vat_type[4][0]}

																								 ]);
	}

	// ***************************************************************************************************************************************
	// Line detail : remove line
	// ***************************************************************************************************************************************
	line_detail_before_remove(frm, cdt, cdn)
	{

		let item = locals[cdt][cdn];

		if (document.getElementById('hidden_can_edit_document').value == '0')
		{
			if (item.can_remove != 2)
			{	raise;
			}
		}

		if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {

			if (parseFloat(item.worked_hours) != 0) {

				frappe.msgprint({title: __("Message"), message: __('Can not delete this line. Worked hours is different from zero'), indicator: "red"});
				raise;

			}
		}

	}

	// ***************************************************************************************************************************************
	// Compute value line
	// ***************************************************************************************************************************************
	#line_compute_value(document_calc_mode, line_qty, line_free_qty, line_fixed_price, line_unit_price, line_discount_in_value, line_base_discount,
						line_extra_discount, base_discount, extra_discount, credit_mode, unit_price_digit_rounding, currency_digit_rounding, is_option)
	{
		let value_line = 0;
		let calc_mode = 0;
		let mode_credit = 1;

		if ((!credit_mode) || (credit_mode.toString() == ''))
		{	mode_credit = 1;
		}else
		{	mode_credit = credit_mode;
		}

		if ((mode_credit != -1 ) && (mode_credit != 1))
		{
			frappe.msgprint({title: __("Message"), message: __('Credit mode has to be -1 or 1'), indicator: "red"});
			raise;
		}

		if ((!document_calc_mode) || ((document_calc_mode) && (document_calc_mode.toString() == '')))
		{	calc_mode = 0;
		}else
		{	calc_mode = document_calc_mode;
		}

		if ((calc_mode != 0) && (calc_mode != 1) && (calc_mode != 2) && (calc_mode != 3))
		{
			frappe.msgprint({title: __("Message"), message: __('Document calc mode has to be 0, 1, 2 or 3'), indicator: "red"});
			raise;
		}

		let unit_price_rounding = 2;

		if ((unit_price_digit_rounding) || (unit_price_digit_rounding >= 0))
		{	unit_price_rounding = unit_price_digit_rounding;
		}

		let currency_rounding = 2;

		if ((currency_digit_rounding) || (currency_digit_rounding >= 0))
		{	currency_rounding = currency_digit_rounding;
		}

		let option = is_option;

		if ((line_qty == 0) && (line_free_qty == 0) && (line_fixed_price == 0))
		{
			value_line = 0;
		}else
		{
			let qty = 0;
			let free_qty = 0;
			let fixed_price = 0;
			let unit_price = 0;
			let line_rval = 0;
			let line_rb = 0;
			let line_re = 0;
			let master_rb = 0;
			let master_re = 0;
			let exp = 0;

			if (line_qty)
			{	qty = line_qty;
			}

			if (line_free_qty)
			{	free_qty = line_free_qty;
			}

			if (line_fixed_price)
			{	fixed_price = line_fixed_price;
			}

			if (line_unit_price)
			{	unit_price = line_unit_price;
			}

			if (line_discount_in_value)
			{	line_rval = line_discount_in_value;
			}

			if (line_base_discount)
			{	line_rb = line_base_discount;
			}

			if (line_extra_discount)
			{	line_re = line_extra_discount;
			}

			if (base_discount)
			{	master_rb = base_discount;
			}

			if (extra_discount)
			{	master_re = extra_discount;
			}


			if ((calc_mode == 0) || (calc_mode == 1))
			{	value_line = parseFloat(( fixed_price + ( ( qty - free_qty  ) * ( unit_price - line_rval ) * ( 1 - ( line_rb / 100.000000)) * ( 1 - ( line_re / 100.000000)) * ( 1 - ( master_rb / 100.000000)) * ( 1 - ( master_re / 100.000000)) ) ) * mode_credit);
			}else if ((calc_mode == 2) || (calc_mode == 3))
			{
				exp = Math.pow(10, unit_price_rounding);
				let price_rounded = unit_price - line_rval;
				price_rounded = Math.round(price_rounded * exp) / exp;
				value_line = parseFloat(( fixed_price + ( ( qty - free_qty  ) * ( parseFloat(price_rounded) ) * ( 1 - ( line_rb / 100.000000)) * ( 1 - ( line_re / 100.000000)) * ( 1 - ( master_rb / 100.000000)) * ( 1 - ( master_re / 100.000000)) ) ) * mode_credit);
			}

			if ((calc_mode == 1) || (calc_mode == 3))
			{
				exp = Math.pow(10, currency_rounding);
				value_line = Math.round(value_line * exp) / exp;
			}

		}

		return value_line;
	}

	// ***************************************************************************************************************************************
	// Select item budget
	// ***************************************************************************************************************************************
	select_item_budget(frm, cdt, cdn)
	{
		let item = locals[cdt][cdn];

		if ((!item.item_budget_id) || ((item.item_budget_id) && (item.item_budget_id.trim() == ''))) {
			item.alert_margin_rate = this.journal_default_alert_margin_rate;
			item.alert_margin_mode = this.journal_margin_mode;

			if (!item.alert_margin_rate) {
				item.alert_margin_rate = 0;
			}

			if (!item.alert_margin_mode) {
				item.alert_margin_mode = 0;
			}

			this.frm.refresh_field('line_detail');

		}else{
			let me = this;

			let method = this.path_item_budget + '.ioi_item_budget_get_data';

			frappe.call({  	method: method,
							args: {	"name" : item.item_budget_id
							},
							async: false,
							callback:function(r)	{

								item.alert_margin_rate = me.journal_default_alert_margin_rate;
								item.alert_margin_mode = me.journal_margin_mode;

								if ((r.message[0].default_alert_margin_rate) && (r.message[0].default_alert_margin_rate != 0)) {
									item.alert_margin_rate = r.message[0].default_alert_margin_rate;
								}

								if ((r.message[0].alert_margin_mode) && (r.message[0].alert_margin_mode != 0)) {
									item.alert_margin_mode = r.message[0].alert_margin_mode;
								}

								if (!item.alert_margin_rate) {
									item.alert_margin_rate = 0;
								}

								if (!item.alert_margin_mode) {
									item.alert_margin_mode = 0;
								}

								me.frm.refresh_field('line_detail');
							}
			});


		}

	}

	// ***************************************************************************************************************************************
	// Compute sales margin
	// ***************************************************************************************************************************************
	compute_sales_margin(row)
	{
		let qty = 0;

		if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') {

			if ((row.quoted_qty) && (row.quoted_qty != 0)) {
				qty = parseFloat(row.quoted_qty);
			}

		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {

			if ((row.ordered_qty) && (row.ordered_qty != 0)) {
				qty = parseFloat(row.ordered_qty);
			}

		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {

			if ((row.delivered_qty) && (row.delivered_qty != 0)) {
				qty += parseFloat(row.delivered_qty);
			}

			if ((row.reserved_qty) && (row.reserved_qty != 0)) {
				qty += parseFloat(row.reserved_qty);
			}

			if ((row.allocated_qty) && (row.allocated_qty != 0)) {
				qty += parseFloat(row.allocated_qty);
			}

		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {

			if ((row.invoiced_qty) && (row.invoiced_qty != 0)) {
				qty = parseFloat(row.invoiced_qty);
			}
		}

		if ((row.free_part_qty) && (row.free_part_qty != 0)) {
			qty = qty - parseFloat(row.free_part_qty);
		}


		if (parseFloat(row.manual_cost) != 0) {
			row.cost_value = parseFloat(row.manual_cost) * parseFloat(qty);
		}else{
			row.cost_value = parseFloat(row.item_cost) * parseFloat(qty);
		}


		let line_rb = 0;

		if ((row.base_discount) && (row.base_discount != 0)) {
			line_rb = parseFloat(row.base_discount);
		}

		let line_re = 0;

		if ((row.extra_discount) && (row.extra_discount != 0)) {
			line_re = parseFloat(row.extra_discount);
		}

		let master_rb = 0;

		if ((this.frm.doc.base_discount) && (this.frm.doc.base_discount != 0)) {
			master_rb = parseFloat(this.frm.doc.base_discount);
		}

		let master_re = 0;

		if ((this.frm.doc.extra_discount) && (this.frm.doc.extra_discount != 0)) {
			master_re = parseFloat(this.frm.doc.extra_discount);
		}

		// row.total_supplier_price = (parseFloat(row.purch_unit_price_fnd) * ( 1 - ( line_rb / 100.000000)) * ( 1 - ( line_re / 100.000000)) * ( 1 - ( master_rb / 100.000000)) * ( 1 - ( master_re / 100.000000))) * parseFloat(qty);

		row.total_supplier_price = parseFloat(row.purch_unit_price_fnd) * parseFloat(qty);

		row.cost_margin_rate = 0;
		row.sales_margin_rate = 0;
		row.margin_alert = 0;

		let iota_value = 0.0001;

		let value_in_scope = parseFloat(row.cost_value)

		if (Math.abs(parseFloat(row.total_supplier_price)) > Math.abs(parseFloat(row.cost_value))) {
			value_in_scope = parseFloat(row.total_supplier_price)
		}


		if (Math.abs(parseFloat(value_in_scope)) >= parseFloat(iota_value)) {
			row.cost_margin_rate = parseFloat(100 * ((parseFloat(row.value_line_sys_currency) /  parseFloat(value_in_scope)) - 1)); //, 2);
		}else{
			if (((row.value_line_sys_currency) && (row.value_line_sys_currency != 0)) || ((value_in_scope) && (value_in_scope != 0))) {
				if (row.alert_margin_mode == 0) {
					row.margin_alert = -2;
				}
			}
		}

		value_in_scope = parseFloat(row.value_line_sys_currency)

		if (Math.abs(parseFloat(row.total_supplier_price)) > Math.abs(parseFloat(row.value_line_sys_currency))) {
			value_in_scope = parseFloat(row.total_supplier_price)
		}


		if (Math.abs(parseFloat(value_in_scope)) >= parseFloat(iota_value)) {
			row.sales_margin_rate = parseFloat(((1 - ((parseFloat(row.cost_value) / parseFloat(value_in_scope)))) * 100)); //, 2);
		}else{
			if (((value_in_scope) && (value_in_scope != 0)) || ((row.cost_value) && (row.cost_value != 0))) {
				if (row.alert_margin_mode == 1) {
					row.margin_alert = -2;
				}
			}
		}

		if (((row.value_line_sys_currency) && (row.value_line_sys_currency != 0)) || ((row.cost_value) && (row.cost_value != 0)) || ((row.total_supplier_price) && (row.total_supplier_price != 0))) {
			if (row.alert_margin_mode == 0) {
				if (row.margin_alert != -2) {
					if (parseFloat(row.cost_margin_rate) < parseFloat(row.alert_margin_rate)) {
						row.margin_alert = -1;
					}else{
						row.margin_alert = 0;
					}
				}
			}

			if (row.alert_margin_mode == 1) {
				if (row.margin_alert != -2) {
					if (parseFloat(row.sales_margin_rate) < parseFloat(row.alert_margin_rate)) {
						row.margin_alert = -1;
					}else{
						row.margin_alert = 0;
					}
				}
			}
		}

	}


	// ***************************************************************************************************************************************
	// Compute document
	// ***************************************************************************************************************************************
	compute_document()
	{
		if ((!this.frm.doc.currency_digit_rounding) || ((this.frm.doc.currency_digit_rounding) && (this.frm.doc.currency_digit_rounding.toString().trim() == '')))
		{
			this.frm.doc.currency_digit_rounding = 0;
			this.frm.refresh_field('currency_digit_rounding');
		}else
		{	if (this.frm.doc.currency_digit_rounding < 0)
			{
				this.frm.doc.currency_digit_rounding = 0;
				this.frm.refresh_field('currency_digit_rounding');
			}
			if (this.frm.doc.currency_digit_rounding > 9)
			{
				this.frm.doc.currency_digit_rounding = 9;
				this.frm.refresh_field('currency_digit_rounding');
			}
		}

		// Compute all document (lines + header)
		this.frm.refresh_field('line_detail');

		let vat_matrix_key = [];
		let vat_matrix_type = [];
		let vat_matrix_rate = [];
		let vat_matrix_wo_vat = [];
		let vat_matrix_base = [];
		let vat_matrix_vat = [];
		let vat_matrix_inc_vat = [];
		let cpt = 0;
		let total_wo_vat_neg_part = 0;
		let tot_internal_value_line_option = 0;
		let tot_sub_total = 0;
		let total_weight = 0;
		let total_volume = 0;

		let fin_disc = 0;

		if ((this.frm.doc.financial_discount) && (this.frm.doc.financial_discount.toString().trim() == ''))
		{	fin_disc = 0;
		}else
		{	fin_disc = this.frm.doc.financial_discount;
		}


		if (this.frm.doc.line_detail)
		{
			let vtype = '';
			let vrate = '';
			let vother = '';
			let n = 0;
			let str_vrate = '';
			let str_vother = '';
			let str_zero = '';
			let idx = 0;


			// Generate VAT Matrix
			for (var i = 0; i < this.frm.doc.line_detail.length; i++)
			{
				if ((!this.frm.doc.line_detail[i].vat_type) || ((this.frm.doc.line_detail[i].vat_type) && (this.frm.doc.line_detail[i].vat_type.trim() == '')))
				{	vtype = '';
				}else
				{	vtype = this.frm.doc.line_detail[i].vat_type.toUpperCase().trim();
				}

				if ((!this.frm.doc.line_detail[i].vat_rate) || ((this.frm.doc.line_detail[i].vat_rate) && (this.frm.doc.line_detail[i].vat_rate == 0)))
				{	vrate = '0';
				}else
				{	vrate = this.frm.doc.line_detail[i].vat_rate.toString().trim();
				}

				if ((vtype != '') || (vrate != ''))
				{
					str_vrate = vrate;

					cpt = 1;
					n = 15 - str_vrate.length;

					str_zero = '';

					while (cpt < n)
					{
						str_zero += '0';
						cpt++;
					}

					str_vrate = str_zero + str_vrate;



					if (vat_matrix_key.indexOf(vtype + "|" + str_vrate) == -1) // + "|" + str_vother) == -1)
					{
						vat_matrix_key.push(vtype + "|" + str_vrate); // + "|" + str_vother);
						vat_matrix_rate.push(parseFloat(0));
						vat_matrix_type.push('');
						vat_matrix_wo_vat.push(parseFloat(0));
						vat_matrix_base.push(parseFloat(0));
						vat_matrix_vat.push(parseFloat(0));
						vat_matrix_inc_vat.push(parseFloat(0));
					}
				}
			}


			vat_matrix_key.sort();

			for(var k = 0; k < vat_matrix_key.length; k++)
			{
				let value = vat_matrix_key[k];

				let f = value.substring(0, value.indexOf('|'));

				let last = value.substring(value.indexOf('|')+1, value.length);

				let s = last;

				/*.substring(0, last.indexOf('|'));

				last = last.substring(last.indexOf('|')+1, last.length);
*/
				if (s.trim() == '')
				{	s = '0';
				}

				s = parseFloat(s).toString().trim();


				cpt = 1;
				n = 15 - s.length;


				str_zero = '';

				while (cpt < n)
				{
					str_zero += '0';
					cpt++;
				}

				s = str_zero + s;



			//	last = parseFloat(last).toString().trim();

				value = f + '|' + s; // + '|' + last;

				vat_matrix_key[k] = value;
			}

			// Item Budget Extra Discount Re-init to zero
			if (this.use_triggered_budget_discount == 1) {

				for (var i = 0; i < this.frm.doc.line_detail.length; i++) {

					if (this.customer_manual_sales_condition_id != this.frm.doc.line_detail[i].sales_condition_id) {

						if ((this.frm.doc.line_detail[i].item_budget_id) && (this.frm.doc.line_detail[i].item_budget_id.trim() != '')) {

							let init_extra_discount = false;

							if ((this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')) {
								init_extra_discount = true;
							}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
								if ((!this.frm.doc.line_detail[i].order_master_link) || ((this.frm.doc.line_detail[i].order_master_link) && (this.frm.doc.line_detail[i].order_master_link.trim() == ''))) {
									init_extra_discount = true;
								}
							}else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
								if (((!this.frm.doc.line_detail[i].order_master_link) || ((this.frm.doc.line_detail[i].order_master_link) && (this.frm.doc.line_detail[i].order_master_link.trim() == ''))) &&
									((!this.frm.doc.line_detail[i].delivery_master_link) || ((this.frm.doc.line_detail[i].delivery_master_link) && (this.frm.doc.line_detail[i].delivery_master_link.trim() == '')))) {
									init_extra_discount = true;
								}
							}

							if (init_extra_discount) {

								let has_detail_discount = 0

								let method = this.path_item_budget + '.ioi_item_budget_has_detail_discounts';

								frappe.call({  	method: method,
												args: {"item_budget_id": this.frm.doc.line_detail[i].item_budget_id, "currency_id": this.frm.doc.currency_id},
												async: false,
												callback:function(r)	{
													has_detail_discount = r.message;
												}
								});

								if (has_detail_discount == 1) {
									this.frm.doc.line_detail[i].extra_discount = 0;
								}
							}
						}
					}
				}
			}



			for (var i = 0; i < this.frm.doc.line_detail.length; i++)
			{
				let qty = 0;

				if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
				{
					if ((this.frm.doc.line_detail[i].quoted_qty) && (this.frm.doc.line_detail[i].quoted_qty.toString() != ''))
					{
						qty = this.frm.doc.line_detail[i].quoted_qty;
					}
				}else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
				{
					if ((this.frm.doc.line_detail[i].ordered_qty) && (this.frm.doc.line_detail[i].ordered_qty.toString() != ''))
					{
						qty = this.frm.doc.line_detail[i].ordered_qty;
					}
				}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
				{

					if ((this.frm.doc.line_detail[i].delivered_qty) && (this.frm.doc.line_detail[i].delivered_qty.toString() != ''))
					{
						qty = this.frm.doc.line_detail[i].delivered_qty;
					}

					if ((this.frm.doc.line_detail[i].reserved_qty) && (this.frm.doc.line_detail[i].reserved_qty.toString() != ''))
					{
						qty = qty + this.frm.doc.line_detail[i].reserved_qty;
					}

					if ((this.frm.doc.line_detail[i].allocated_qty) && (this.frm.doc.line_detail[i].allocated_qty.toString() != ''))
					{
						qty = qty + this.frm.doc.line_detail[i].allocated_qty;
					}


				}else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
				{
					if ((this.frm.doc.line_detail[i].invoiced_qty) && (this.frm.doc.line_detail[i].invoiced_qty.toString() != ''))
					{
						qty = this.frm.doc.line_detail[i].invoiced_qty;
					}
				}

				if (this.frm.doc.line_detail[i].weight)
				{
					total_weight += this.frm.doc.line_detail[i].weight;
				}

				if (this.frm.doc.line_detail[i].volume)
				{
					total_volume += this.frm.doc.line_detail[i].volume;
				}

				// Compute value line doc currency
				this.frm.doc.line_detail[i].value_line_doc_currency = this.#line_compute_value(	this.frm.doc.document_calc_mode, qty, this.frm.doc.line_detail[i].free_part_qty,
																								this.frm.doc.line_detail[i].fixed_price, this.frm.doc.line_detail[i].unit_price,
																								this.frm.doc.line_detail[i].value_discount, this.frm.doc.line_detail[i].base_discount,
																								this.frm.doc.line_detail[i].extra_discount, this.frm.doc.base_discount, this.frm.doc.extra_discount,
																								this.frm.doc.credit_mode, this.journal_unit_price_decimal_number, this.frm.doc.currency_digit_rounding,
																								this.frm.doc.line_detail[i].is_option);

				this.compute_sales_margin(this.frm.doc.line_detail[i]);

				if (this.frm.doc.line_detail[i].is_option == 1)
				{
					this.frm.doc.line_detail[i].internal_value_line_option = parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency);
					tot_internal_value_line_option += parseFloat(this.frm.doc.line_detail[i].internal_value_line_option);
					this.frm.doc.line_detail[i].value_line_doc_currency = 0;
				}

				// Compute value line sys currency
				this.frm.doc.line_detail[i].value_line_sys_currency = parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency * this.frm.doc.currency_rate_inv)

				// Compute total negative part
				if (this.frm.doc.line_detail[i].value_line_doc_currency < 0)
				{	total_wo_vat_neg_part += parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency);
				}


				// Compute internal line doc no esc
				if (this.frm.doc.line_detail[i].no_financial_discount == 1)
				{
					if (this.frm.doc.line_detail[i].value_line_doc_currency == 0)
					{
						this.frm.doc.line_detail[i].internal_line_doc_no_esc = 0;
					}else
					{	this.frm.doc.line_detail[i].internal_line_doc_no_esc = parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency);
					}
				}else
				{	this.frm.doc.line_detail[i].internal_line_doc_no_esc = 0;
				}

				// Sub total
				if (this.frm.doc.line_detail[i].sub_total_break == 0)
				{
					tot_sub_total += parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency);
					this.frm.doc.line_detail[i].sub_total = 0;
				}else
				{
					tot_sub_total += parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency);

					this.frm.doc.line_detail[i].sub_total = parseFloat(tot_sub_total);
					// this.frm.doc.line_detail[i] = 0;
				}

				// Populate VAT matrix
				if ((!this.frm.doc.line_detail[i].vat_type) || ((this.frm.doc.line_detail[i].vat_type) && (this.frm.doc.line_detail[i].vat_type.trim() == '')))
				{	vtype = '';
				}else
				{	vtype = this.frm.doc.line_detail[i].vat_type.toUpperCase().trim();
				}

				if ((!this.frm.doc.line_detail[i].vat_rate) || ((this.frm.doc.line_detail[i].vat_rate) && (this.frm.doc.line_detail[i].vat_rate == 0)))
				{	vrate = '0';
				}else
				{	vrate = this.frm.doc.line_detail[i].vat_rate.toString().trim();
				}

				if ((vtype != '') || (vrate != ''))
				{

					str_vrate = vrate;

					cpt = 1;
					n = 15 - str_vrate.length;

					str_zero = '';

					while (cpt < n)
					{
						str_zero += '0';
						cpt++;
					}

					str_vrate = str_zero + str_vrate;

					idx = vat_matrix_key.indexOf(vtype + "|" + str_vrate); // + "|" + vother);


					vat_matrix_wo_vat[idx] = parseFloat(vat_matrix_wo_vat[idx]) + parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency);

					if ((!this.frm.doc.financial_discount_mode) || ((this.frm.doc.financial_discount_mode) && ((this.frm.doc.financial_discount_mode == 0) || (this.frm.doc.financial_discount_mode == 1))))
					{	vat_matrix_base[idx] = parseFloat(vat_matrix_base[idx]) + parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency);
					}else
					{
						if ((this.frm.doc.line_detail[i].no_financial_discount) && (this.frm.doc.line_detail[i].no_financial_discount == 1))
						{	vat_matrix_base[idx] = parseFloat(vat_matrix_base[idx]) + parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency);
						}else
						{	vat_matrix_base[idx] = parseFloat(vat_matrix_base[idx]) + (parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency) - (parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency) * fin_disc / 100 ))
						}

					}

					vat_matrix_base[idx] = parseFloat(vat_matrix_base[idx]);

					if ((this.frm.doc.line_detail[i].vat_rate) && ((this.frm.doc.line_detail[i].vat_rate.toString().trim() != '')))
					{	vat_matrix_rate[idx] = parseFloat(this.frm.doc.line_detail[i].vat_rate);
					}

					vat_matrix_type[idx] = this.frm.doc.line_detail[i].vat_type;


					if (this.frm.doc.line_detail[i].vat_type)
					{
						let rate = 0;

						if ((this.frm.doc.line_detail[i].vat_rate) && (this.frm.doc.line_detail[i].vat_rate != 0))
						{	rate = this.frm.doc.line_detail[i].vat_rate;
						}

						if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'N') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'NAT') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'X') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'XXX'))
						{	vat_matrix_inc_vat[idx] = parseFloat(vat_matrix_inc_vat[idx]) + parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency + (this.frm.doc.line_detail[i].value_line_doc_currency * ((rate / 100.000000) )));
						}else if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'E') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'INT'))
						{	vat_matrix_inc_vat[idx] = parseFloat(vat_matrix_inc_vat[idx]) + parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency);
						}else if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'I') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'EEC') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'C') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'S') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'NCO'))
						{	vat_matrix_inc_vat[idx] = parseFloat(vat_matrix_inc_vat[idx]) + parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency)
						}

						if ((!this.frm.doc.financial_discount_mode) || ((this.frm.doc.financial_discount_mode) && ((this.frm.doc.financial_discount_mode == 0) || (this.frm.doc.financial_discount_mode == 1))))
						{
							if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'N') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'NAT') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'X') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'XXX'))
							{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + (this.frm.doc.line_detail[i].value_line_doc_currency * ((rate / 100.000000)));
							}else if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'E') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'INT'))
							{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + 0;
							}else if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'I') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'EEC') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'C') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'S') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'NCO'))
							{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + 0;
							}
						}else{
							if ((this.frm.doc.line_detail[i].no_financial_discount) && (this.frm.doc.line_detail[i].no_financial_discount == 1))
							{
								if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'N') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'NAT') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'X') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'XXX'))
								{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + (this.frm.doc.line_detail[i].value_line_doc_currency * ((rate / 100.000000)));
								}else if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'E') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'INT'))
								{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + 0;
								}else if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'I') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'EEC') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'C') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'S') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'NCO'))
								{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + 0;
								}
							}else{
								if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'N') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'NAT') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'X') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'XXX'))
								{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + (this.frm.doc.line_detail[i].value_line_doc_currency - (this.frm.doc.line_detail[i].value_line_doc_currency * fin_disc / 100.00)) * ((rate / 100.000000));
								}else if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'E') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'INT'))
								{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + 0;
								}else if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'I') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'EEC') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'C') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'S') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'NCO'))
								{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + 0;
								}
							}

						}
					}

				}
			}




			if (this.use_triggered_budget_discount == 1) {

				let item_budget = []

				for (var i = 0; i < this.frm.doc.line_detail.length; i++) {

					if (this.customer_manual_sales_condition_id != this.frm.doc.line_detail[i].sales_condition_id) {

						if ((this.frm.doc.line_detail[i].item_budget_id) && (this.frm.doc.line_detail[i].item_budget_id.trim() != '')) {

							let init_extra_discount = false;

							if ((this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')) {

								if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') {

									if ((!this.frm.doc.line_detail[i].option_id) || ((this.frm.doc.line_detail[i].option_id) && (this.frm.doc.line_detail[i].option_id.trim() == ''))) {
										init_extra_discount = true;
									}

								}else{
									init_extra_discount = true;
								}

							}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
								if ((!this.frm.doc.line_detail[i].order_master_link) || ((this.frm.doc.line_detail[i].order_master_link) && (this.frm.doc.line_detail[i].order_master_link.trim() == ''))) {
									init_extra_discount = true;
								}
							}else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
								if (((!this.frm.doc.line_detail[i].order_master_link) || ((this.frm.doc.line_detail[i].order_master_link) && (this.frm.doc.line_detail[i].order_master_link.trim() == ''))) &&
									((!this.frm.doc.line_detail[i].delivery_master_link) || ((this.frm.doc.line_detail[i].delivery_master_link) && (this.frm.doc.line_detail[i].delivery_master_link.trim() == '')))) {
									init_extra_discount = true;
								}
							}

							if (init_extra_discount) {

								let has_detail_discount = 0

								let method = this.path_item_budget + '.ioi_item_budget_has_detail_discounts';

								frappe.call({  	method: method,
												args: {"item_budget_id": this.frm.doc.line_detail[i].item_budget_id, "currency_id": this.frm.doc.currency_id},
												async: false,
												callback:function(r)	{
													has_detail_discount = r.message;
												}
								});

								if (has_detail_discount == 1) {

									let item_budget_found = false;

									for (var j = 0; j < item_budget.length; j++) {

										if ((item_budget[j][0] == this.frm.doc.line_detail[i].item_budget_id) && (item_budget[j][1] == this.frm.doc.currency_id) ) {

											item_budget[j][2] += parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency);
											item_budget_found = true;
											break;
										}
									}

									if (!item_budget_found) {
										item_budget[item_budget.length] = [this.frm.doc.line_detail[i].item_budget_id, this.frm.doc.currency_id, parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency)];
									}
								}
							}
						}
					}
				}

				if (item_budget.length > 0) {

					let me = this;

					let data = []

					let method = this.path_item_budget + '.ioi_item_budget_get_discounts_to_apply';

					frappe.call({  	method: method,
									args: {"item_budget": item_budget},
									async: false,
									callback:function(r)	{
										data = r.message;
									}
					});

					if (data.length > 0) {

						for (var i = 0; i < this.frm.doc.line_detail.length; i++)
						{
							if (this.customer_manual_sales_condition_id != this.frm.doc.line_detail[i].sales_condition_id) {

								let update_extra_discount = false;

								if ((this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')) {

									if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') {

										if ((!this.frm.doc.line_detail[i].option_id) || ((this.frm.doc.line_detail[i].option_id) && (this.frm.doc.line_detail[i].option_id.trim() == ''))) {
											update_extra_discount = true;
										}
									}else{
										update_extra_discount = true;
									}

								}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
									if ((!this.frm.doc.line_detail[i].order_master_link) || ((this.frm.doc.line_detail[i].order_master_link) && (this.frm.doc.line_detail[i].order_master_link.trim() == ''))) {
										update_extra_discount = true;
									}
								}else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
									if (((!this.frm.doc.line_detail[i].order_master_link) || ((this.frm.doc.line_detail[i].order_master_link) && (this.frm.doc.line_detail[i].order_master_link.trim() == ''))) &&
										((!this.frm.doc.line_detail[i].delivery_master_link) || ((this.frm.doc.line_detail[i].delivery_master_link) && (this.frm.doc.line_detail[i].delivery_master_link.trim() == '')))) {
											update_extra_discount = true;
									}
								}

								if (update_extra_discount) {

									for (var j = 0; j < data.length; j++) {

										if (data[j].item_budget_id == this.frm.doc.line_detail[i].item_budget_id) {
											this.frm.doc.line_detail[i].extra_discount = data[j].extra_discount;
											break;
										}

									}
								}
							}
						}


						// Recompute line with Budget Extra discount

						vat_matrix_key = [];
						vat_matrix_type = [];
						vat_matrix_rate = [];
						vat_matrix_wo_vat = [];
						vat_matrix_base = [];
						vat_matrix_vat = [];
						vat_matrix_inc_vat = [];

						// Generate VAT Matrix
						for (var i = 0; i < this.frm.doc.line_detail.length; i++)
						{
							if ((!this.frm.doc.line_detail[i].vat_type) || ((this.frm.doc.line_detail[i].vat_type) && (this.frm.doc.line_detail[i].vat_type.trim() == '')))
							{	vtype = '';
							}else
							{	vtype = this.frm.doc.line_detail[i].vat_type.toUpperCase().trim();
							}

							if ((!this.frm.doc.line_detail[i].vat_rate) || ((this.frm.doc.line_detail[i].vat_rate) && (this.frm.doc.line_detail[i].vat_rate == 0)))
							{	vrate = '0';
							}else
							{	vrate = this.frm.doc.line_detail[i].vat_rate.toString().trim();
							}

							if ((vtype != '') || (vrate != ''))
							{
								str_vrate = vrate;

								cpt = 1;
								n = 15 - str_vrate.length;

								str_zero = '';

								while (cpt < n)
								{
									str_zero += '0';
									cpt++;
								}

								str_vrate = str_zero + str_vrate;



								if (vat_matrix_key.indexOf(vtype + "|" + str_vrate) == -1) // + "|" + str_vother) == -1)
								{
									vat_matrix_key.push(vtype + "|" + str_vrate); // + "|" + str_vother);
									vat_matrix_rate.push(parseFloat(0));
									vat_matrix_type.push('');
									vat_matrix_wo_vat.push(parseFloat(0));
									vat_matrix_base.push(parseFloat(0));
									vat_matrix_vat.push(parseFloat(0));
									vat_matrix_inc_vat.push(parseFloat(0));
								}
							}
						}


						vat_matrix_key.sort();

						for(var k = 0; k < vat_matrix_key.length; k++)
						{
							let value = vat_matrix_key[k];

							let f = value.substring(0, value.indexOf('|'));

							let last = value.substring(value.indexOf('|')+1, value.length);

							let s = last;


							if (s.trim() == '')
							{	s = '0';
							}

							s = parseFloat(s).toString().trim();


							cpt = 1;
							n = 15 - s.length;


							str_zero = '';

							while (cpt < n)
							{
								str_zero += '0';
								cpt++;
							}

							s = str_zero + s;



							//	last = parseFloat(last).toString().trim();

							value = f + '|' + s; // + '|' + last;

							vat_matrix_key[k] = value;
						}

						for (var i = 0; i < this.frm.doc.line_detail.length; i++)
						{
							let qty = 0;

							if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
							{
								if ((this.frm.doc.line_detail[i].quoted_qty) && (this.frm.doc.line_detail[i].quoted_qty.toString() != ''))
								{
									qty = this.frm.doc.line_detail[i].quoted_qty;
								}
							}else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
							{
								if ((this.frm.doc.line_detail[i].ordered_qty) && (this.frm.doc.line_detail[i].ordered_qty.toString() != ''))
								{
									qty = this.frm.doc.line_detail[i].ordered_qty;
								}
							}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
							{

								if ((this.frm.doc.line_detail[i].delivered_qty) && (this.frm.doc.line_detail[i].delivered_qty.toString() != ''))
								{
									qty = this.frm.doc.line_detail[i].delivered_qty;
								}

								if ((this.frm.doc.line_detail[i].reserved_qty) && (this.frm.doc.line_detail[i].reserved_qty.toString() != ''))
								{
									qty = qty + this.frm.doc.line_detail[i].reserved_qty;
								}

								if ((this.frm.doc.line_detail[i].allocated_qty) && (this.frm.doc.line_detail[i].allocated_qty.toString() != ''))
								{
									qty = qty + this.frm.doc.line_detail[i].allocated_qty;
								}


							}else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
							{
								if ((this.frm.doc.line_detail[i].invoiced_qty) && (this.frm.doc.line_detail[i].invoiced_qty.toString() != ''))
								{
									qty = this.frm.doc.line_detail[i].invoiced_qty;
								}
							}

							if (this.frm.doc.line_detail[i].weight)
							{
								total_weight += this.frm.doc.line_detail[i].weight;
							}

							if (this.frm.doc.line_detail[i].volume)
							{
								total_volume += this.frm.doc.line_detail[i].volume;
							}

							// Compute value line doc currency
							this.frm.doc.line_detail[i].value_line_doc_currency = this.#line_compute_value(	this.frm.doc.document_calc_mode, qty, this.frm.doc.line_detail[i].free_part_qty,
																											this.frm.doc.line_detail[i].fixed_price, this.frm.doc.line_detail[i].unit_price,
																											this.frm.doc.line_detail[i].value_discount, this.frm.doc.line_detail[i].base_discount,
																											this.frm.doc.line_detail[i].extra_discount, this.frm.doc.base_discount, this.frm.doc.extra_discount,
																											this.frm.doc.credit_mode, this.journal_unit_price_decimal_number, this.frm.doc.currency_digit_rounding,
																											this.frm.doc.line_detail[i].is_option);

							this.compute_sales_margin(this.frm.doc.line_detail[i]);

							if (this.frm.doc.line_detail[i].is_option == 1)
							{
								this.frm.doc.line_detail[i].internal_value_line_option = parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency);
								tot_internal_value_line_option += parseFloat(this.frm.doc.line_detail[i].internal_value_line_option);
								this.frm.doc.line_detail[i].value_line_doc_currency = 0;
							}

							// Compute value line sys currency
							this.frm.doc.line_detail[i].value_line_sys_currency = parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency * this.frm.doc.currency_rate_inv)

							// Compute total negative part
							if (this.frm.doc.line_detail[i].value_line_doc_currency < 0)
							{	total_wo_vat_neg_part += parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency);
							}


							// Compute internal line doc no esc
							if (this.frm.doc.line_detail[i].no_financial_discount == 1)
							{
								if (this.frm.doc.line_detail[i].value_line_doc_currency == 0)
								{
									this.frm.doc.line_detail[i].internal_line_doc_no_esc = 0;
								}else
								{	this.frm.doc.line_detail[i].internal_line_doc_no_esc = parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency);
								}
							}else
							{	this.frm.doc.line_detail[i].internal_line_doc_no_esc = 0;
							}

							// Sub total
							if (this.frm.doc.line_detail[i].sub_total_break == 0)
							{
								tot_sub_total += parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency);
								this.frm.doc.line_detail[i].sub_total = 0;
							}else
							{
								tot_sub_total += parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency);

								this.frm.doc.line_detail[i].sub_total = parseFloat(tot_sub_total);
								// this.frm.doc.line_detail[i] = 0;
							}

							// Populate VAT matrix
							if ((!this.frm.doc.line_detail[i].vat_type) || ((this.frm.doc.line_detail[i].vat_type) && (this.frm.doc.line_detail[i].vat_type.trim() == '')))
							{	vtype = '';
							}else
							{	vtype = this.frm.doc.line_detail[i].vat_type.toUpperCase().trim();
							}

							if ((!this.frm.doc.line_detail[i].vat_rate) || ((this.frm.doc.line_detail[i].vat_rate) && (this.frm.doc.line_detail[i].vat_rate == 0)))
							{	vrate = '0';
							}else
							{	vrate = this.frm.doc.line_detail[i].vat_rate.toString().trim();
							}

							if ((vtype != '') || (vrate != ''))
							{

								str_vrate = vrate;

								cpt = 1;
								n = 15 - str_vrate.length;

								str_zero = '';

								while (cpt < n)
								{
									str_zero += '0';
									cpt++;
								}

								str_vrate = str_zero + str_vrate;

								idx = vat_matrix_key.indexOf(vtype + "|" + str_vrate); // + "|" + vother);


								vat_matrix_wo_vat[idx] = parseFloat(vat_matrix_wo_vat[idx]) + parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency);

								if ((!this.frm.doc.financial_discount_mode) || ((this.frm.doc.financial_discount_mode) && ((this.frm.doc.financial_discount_mode == 0) || (this.frm.doc.financial_discount_mode == 1))))
								{	vat_matrix_base[idx] = parseFloat(vat_matrix_base[idx]) + parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency);
								}else
								{
									if ((this.frm.doc.line_detail[i].no_financial_discount) && (this.frm.doc.line_detail[i].no_financial_discount == 1))
									{	vat_matrix_base[idx] = parseFloat(vat_matrix_base[idx]) + parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency);
									}else
									{	vat_matrix_base[idx] = parseFloat(vat_matrix_base[idx]) + (parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency) - (parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency) * fin_disc / 100 ))
									}

								}

								vat_matrix_base[idx] = parseFloat(vat_matrix_base[idx]);

								if ((this.frm.doc.line_detail[i].vat_rate) && ((this.frm.doc.line_detail[i].vat_rate.toString().trim() != '')))
								{	vat_matrix_rate[idx] = parseFloat(this.frm.doc.line_detail[i].vat_rate);
								}

								vat_matrix_type[idx] = this.frm.doc.line_detail[i].vat_type;


								if (this.frm.doc.line_detail[i].vat_type)
								{
									let rate = 0;

									if ((this.frm.doc.line_detail[i].vat_rate) && (this.frm.doc.line_detail[i].vat_rate != 0))
									{	rate = this.frm.doc.line_detail[i].vat_rate;
									}

									if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'N') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'NAT') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'X') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'XXX'))
									{	vat_matrix_inc_vat[idx] = parseFloat(vat_matrix_inc_vat[idx]) + parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency + (this.frm.doc.line_detail[i].value_line_doc_currency * ((rate / 100.000000) )));
									}else if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'E') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'INT'))
									{	vat_matrix_inc_vat[idx] = parseFloat(vat_matrix_inc_vat[idx]) + parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency);
									}else if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'I') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'EEC') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'C') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'S') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'NCO'))
									{	vat_matrix_inc_vat[idx] = parseFloat(vat_matrix_inc_vat[idx]) + parseFloat(this.frm.doc.line_detail[i].value_line_doc_currency)
									}

									if ((!this.frm.doc.financial_discount_mode) || ((this.frm.doc.financial_discount_mode) && ((this.frm.doc.financial_discount_mode == 0) || (this.frm.doc.financial_discount_mode == 1))))
									{
										if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'N') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'NAT') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'X') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'XXX'))
										{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + (this.frm.doc.line_detail[i].value_line_doc_currency * ((rate / 100.000000)));
										}else if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'E') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'INT'))
										{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + 0;
										}else if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'I') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'EEC') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'C') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'S') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'NCO'))
										{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + 0;
										}
									}else{
										if ((this.frm.doc.line_detail[i].no_financial_discount) && (this.frm.doc.line_detail[i].no_financial_discount == 1))
										{
											if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'N') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'NAT') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'X') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'XXX'))
											{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + (this.frm.doc.line_detail[i].value_line_doc_currency * ((rate / 100.000000)));
											}else if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'E') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'INT'))
											{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + 0;
											}else if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'I') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'EEC') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'C') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'S') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'NCO'))
											{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + 0;
											}
										}else{
											if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'N') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'NAT') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'X') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'XXX'))
											{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + (this.frm.doc.line_detail[i].value_line_doc_currency - (this.frm.doc.line_detail[i].value_line_doc_currency * fin_disc / 100.00)) * ((rate / 100.000000));
											}else if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'E') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'INT'))
											{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + 0;
											}else if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'I') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'EEC') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'C') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'S') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'NCO'))
											{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + 0;
											}
										}

									}
								}

							}
						}
					}

				}
			}




		}

		// Update wo_vat, base_vat, vat, inc_vat in the header
		this.frm.doc.total_htva = 0;
		this.frm.doc.total_base = 0;
		this.frm.doc.total_tvac = 0;
		this.frm.doc.total_tvac = 0;
		this.frm.doc.total_vat = 0;
		this.frm.doc.total_wo_vat_neg_part = 0;
		this.frm.doc.total_in_system_currency = 0;
		this.frm.doc.total_htva_option_part = 0;
		this.frm.doc.total_fin_discount_base = 0;
		this.frm.doc.total_fin_discount_tva = 0;
		this.frm.doc.total_fin_discount_tvac = 0;
		this.frm.doc.total_fin_discount = 0;
		this.frm.doc.total_fast_fin_discount = 0;

		this.frm.doc.wo_vat1 = 0;
		this.frm.doc.base_vat1 = 0;
		this.frm.doc.vat_type1 = '';
		this.frm.doc.rate_vat1 = 0;
		this.frm.doc.vat1 = 0;
		this.frm.doc.inc_vat1 = 0;

		this.frm.doc.wo_vat2 = 0;
		this.frm.doc.base_vat2 = 0;
		this.frm.doc.vat_type2 = '';
		this.frm.doc.rate_vat2 = 0;
		this.frm.doc.vat2 = 0;
		this.frm.doc.inc_vat2 = 0;

		this.frm.doc.wo_vat3 = 0;
		this.frm.doc.base_vat3 = 0;
		this.frm.doc.vat_type3 = '';
		this.frm.doc.rate_vat3 = 0;
		this.frm.doc.vat3 = 0;
		this.frm.doc.inc_vat3 = 0;

		this.frm.doc.wo_vat4 = 0;
		this.frm.doc.base_vat4 = 0;
		this.frm.doc.vat_type4 = '';
		this.frm.doc.rate_vat4 = 0;
		this.frm.doc.vat4 = 0;
		this.frm.doc.inc_vat4 = 0;

		this.frm.doc.wo_vat5 = 0;
		this.frm.doc.base_vat5 = 0;
		this.frm.doc.vat_type5 = '';
		this.frm.doc.rate_vat5 = 0;
		this.frm.doc.vat5 = 0;
		this.frm.doc.inc_vat5 = 0;

		for (var i = 0; i < vat_matrix_key.length; i++)
		{
			if (i == 0)
			{	this.frm.doc.wo_vat1 = parseFloat(vat_matrix_wo_vat[i]);
				this.frm.doc.base_vat1 = parseFloat(vat_matrix_base[i]);
				this.frm.doc.vat_type1 = vat_matrix_type[i];
				this.frm.doc.rate_vat1 = parseFloat(vat_matrix_rate[i]);
				this.frm.doc.vat1 = parseFloat(vat_matrix_vat[i]);
				this.frm.doc.inc_vat1 = parseFloat(vat_matrix_inc_vat[i]);
			}else if (i == 1)
			{	this.frm.doc.wo_vat2 = parseFloat(vat_matrix_wo_vat[i]);
				this.frm.doc.base_vat2 = parseFloat(vat_matrix_base[i]);
				this.frm.doc.vat_type2 = vat_matrix_type[i];
				this.frm.doc.rate_vat2 = parseFloat(vat_matrix_rate[i]);
				this.frm.doc.vat2 = parseFloat(vat_matrix_vat[i]);
				this.frm.doc.inc_vat2 = parseFloat(vat_matrix_inc_vat[i]);
			}else if (i == 2)
			{	this.frm.doc.wo_vat3 = parseFloat(vat_matrix_wo_vat[i]);
				this.frm.doc.base_vat3 = parseFloat(vat_matrix_base[i]);
				this.frm.doc.vat_type3 = vat_matrix_type[i];
				this.frm.doc.rate_vat3 = parseFloat(vat_matrix_rate[i]);
				this.frm.doc.vat3 = parseFloat(vat_matrix_vat[i]);
				this.frm.doc.inc_vat3 = parseFloat(vat_matrix_inc_vat[i]);
			}else if (i == 3)
			{	this.frm.doc.wo_vat4 = parseFloat(vat_matrix_wo_vat[i]);
				this.frm.doc.base_vat4 = parseFloat(vat_matrix_base[i]);
				this.frm.doc.vat_type4 = vat_matrix_type[i];
				this.frm.doc.rate_vat4 = parseFloat(vat_matrix_rate[i]);
				this.frm.doc.vat4 = parseFloat(vat_matrix_vat[i]);
				this.frm.doc.inc_vat4 = parseFloat(vat_matrix_inc_vat[i]);
			}else if (i == 4)
			{	this.frm.doc.wo_vat5 = parseFloat(vat_matrix_wo_vat[i]);
				this.frm.doc.base_vat5 = parseFloat(vat_matrix_base[i]);
				this.frm.doc.vat_type5 = vat_matrix_type[i];
				this.frm.doc.rate_vat5 = parseFloat(vat_matrix_rate[i]);
				this.frm.doc.vat5 = parseFloat(vat_matrix_vat[i]);
				this.frm.doc.inc_vat5 = parseFloat(vat_matrix_inc_vat[i]);
			}else
			{	break;
			}
		}

		// update amounts in the header
		let exp = Math.pow(10, this.frm.doc.currency_digit_rounding);

		this.frm.doc.total_htva = parseFloat(this.frm.doc.wo_vat1 + this.frm.doc.wo_vat2 + this.frm.doc.wo_vat3 + this.frm.doc.wo_vat4 + this.frm.doc.wo_vat5);
		this.frm.doc.total_htva = Math.round(this.frm.doc.total_htva * exp) / exp;

		this.frm.doc.total_base = parseFloat(this.frm.doc.base_vat1 + this.frm.doc.base_vat2 + this.frm.doc.base_vat3 + this.frm.doc.base_vat4 + this.frm.doc.base_vat5);
		this.frm.doc.total_base = Math.round(this.frm.doc.total_base * exp) / exp;


		if ((!this.frm.doc.vat_type) || ((this.frm.doc.vat_type) && (this.frm.doc.vat_type.trim() == '')))
		{
			this.frm.doc.total_tvac = 0;
		}else
		{
			if (this.frm.doc.financial_discount_mode != 2)
			{
				this.frm.doc.total_vat = parseFloat(this.frm.doc.vat1 + this.frm.doc.vat2 + this.frm.doc.vat3 + this.frm.doc.vat4 + this.frm.doc.vat5);
				this.frm.doc.total_vat = Math.round(this.frm.doc.total_vat * exp) / exp;

				this.frm.doc.total_tvac = this.frm.doc.total_htva + this.frm.doc.total_vat;
				this.frm.doc.total_tvac = Math.round(this.frm.doc.total_tvac * exp) / exp;

			}else
			{	this.frm.doc.total_vat = parseFloat(this.frm.doc.vat1 + this.frm.doc.vat2 + this.frm.doc.vat3 + this.frm.doc.vat4 + this.frm.doc.vat5);
				this.frm.doc.total_vat = Math.round(this.frm.doc.total_vat * exp) / exp;

				this.frm.doc.total_tvac =  this.frm.doc.total_htva + this.frm.doc.total_vat;
				this.frm.doc.total_tvac = Math.round(this.frm.doc.total_tvac * exp) / exp;
			}
		}

		this.frm.doc.total_in_system_currency = (this.frm.doc.total_htva * this.frm.doc.currency_rate_inv) * this.frm.doc.credit_mode;
		this.frm.doc.total_in_system_currency = Math.round(this.frm.doc.total_in_system_currency * exp) / exp;

		this.frm.doc.total_htva_option_part = Math.round(tot_internal_value_line_option * exp) / exp;
		this.frm.doc.total_wo_vat_neg_part = Math.round(total_wo_vat_neg_part * exp) / exp;

		// Total financial discount
		this.frm.doc.total_fin_discount = 0;
		this.frm.doc.total_fast_fin_discount = 0;

		if (this.frm.doc.financial_discount_mode == 1)
		{

			if (this.frm.doc.line_detail)
			{
				let total_fin_discount = 0;

				for (var i = 0; i < this.frm.doc.line_detail.length; i++)
				{
					if (this.frm.doc.line_detail[i].no_financial_discount == 0)
					{

						if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'N') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'NAT') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'X') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'XXX')) {
							total_fin_discount += this.frm.doc.line_detail[i].value_line_doc_currency + (this.frm.doc.line_detail[i].value_line_doc_currency * ((parseFloat(this.frm.doc.line_detail[i].vat_rate) / 100.000000) ));
						}else if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'E') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'INT')) {
							total_fin_discount += 0;
						}else if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'I') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'EEC') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'C') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'NCO') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'S')) {
							total_fin_discount += 0;
						}
					}
				}

				this.frm.doc.total_fin_discount = this.frm.doc.total_tvac - (parseFloat(total_fin_discount) / 100.00 * parseFloat(this.frm.doc.financial_discount));
				this.frm.doc.total_fast_fin_discount = this.frm.doc.total_tvac - (parseFloat(total_fin_discount) / 100.00 * parseFloat(this.frm.doc.fast_financial_discount));
			}
		}else if (this.frm.doc.financial_discount_mode == 2)
		{
			let total_fin_discount = 0;

			for (var i = 0; i < this.frm.doc.line_detail.length; i++)
			{
				if (this.frm.doc.line_detail[i].no_financial_discount == 0)
				{

					if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'N') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'NAT') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'X') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'XXX')) {
						total_fin_discount += this.frm.doc.line_detail[i].value_line_doc_currency + (this.frm.doc.line_detail[i].value_line_doc_currency * ((parseFloat(this.frm.doc.line_detail[i].vat_rate) / 100.000000) ));
					}else if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'E') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'INT')) {
						total_fin_discount += 0;
					}else if ((this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'I') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'EEC') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'C') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'NCO') || (this.frm.doc.line_detail[i].vat_type.toUpperCase() == 'S')) {
						total_fin_discount += 0;
					}
				}
			}

			let tot_tvac = parseFloat(this.frm.doc.inc_vat1 + this.frm.doc.inc_vat2 + this.frm.doc.inc_vat3 + this.frm.doc.inc_vat4 + this.frm.doc.inc_vat5);

			this.frm.doc.total_fin_discount = tot_tvac - (parseFloat(total_fin_discount) / 100.00 * parseFloat(this.frm.doc.financial_discount));
			this.frm.doc.total_fast_fin_discount = tot_tvac - (parseFloat(total_fin_discount) / 100.00 * parseFloat(this.frm.doc.fast_financial_discount));

		}


		// Total weight and volume
		this.frm.doc.total_weight = total_weight;
		this.frm.doc.total_volume = total_volume;

		this.frm.refresh_field('line_detail');
		this.refresh_total_section();


		// Compute payment

		if ((this.frm.doctype.toUpperCase() == 'IOI SALES ORDER') || (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')) {

			if (document.getElementById('payment_movement_total_paid')) {
				document.getElementById('payment_movement_total_paid').innerHTML = '<b>' + parseFloat(this.frm.doc.total_tvac).toFixed(2).toString() + '</b>';
			}

			let total_doc = parseFloat(this.frm.doc.total_tvac);

			let total_already_paid = 0

			if (document.getElementById('payment_movement_total_already_paid')) {
				total_already_paid = parseFloat(document.getElementById('payment_movement_total_already_paid').innerText);
			}

			let total_still_to_pay = total_doc - total_already_paid;


			let color = '#60A367';

			let exp = Math.pow(10, 2);


			if (Math.round(total_still_to_pay * exp) / exp > 0) {
				color = '#ED514C';
			}


			if (document.getElementById('payment_movement_total_already_paid')) {
				document.getElementById('payment_movement_total_already_paid').innerHTML = '<b>' + parseFloat(total_already_paid).toFixed(2).toString() + '</b>';
			}

			let tot = Math.round(total_still_to_pay * exp) / exp;


			if (document.getElementById('payment_movement_still_to_pay')) {
				document.getElementById('payment_movement_still_to_pay').innerHTML = '<b>' + parseFloat(tot).toFixed(2).toString() + '</b>';
				document.getElementById('payment_movement_still_to_pay').style.color = color;

			}

			if (document.getElementById('payment_movement_still_to_pay_curr')) {
				document.getElementById('payment_movement_still_to_pay_curr').style.color = color;
			}


			if (document.getElementById('payment_movement_total_still_to_pay')) {
				document.getElementById('payment_movement_total_still_to_pay').value = total_still_to_pay;
			}

			let change_payment_status = false;

			if (parseFloat(total_doc) != 0) {

				if (parseFloat(total_still_to_pay) <= 0) {
					if (this.frm.doc.cashdesk_payment_status_id != 2) {
						change_payment_status = true;
						this.frm.set_value('cashdesk_payment_status_id', 2);
					}
				}else{
					if (parseFloat(total_still_to_pay) >= parseFloat(this.frm.doc.total_tvac)) {
						if (this.frm.doc.cashdesk_payment_status_id != 0) {
							change_payment_status = true;
							this.frm.set_value('cashdesk_payment_status_id', 0);
						}
					}else{
						if (this.frm.doc.cashdesk_payment_status_id != 1) {
							change_payment_status = true;
							this.frm.set_value('cashdesk_payment_status_id', 1);
						}
					}
				}

				if (change_payment_status) {
					this.refresh_payment_status('cashdesk_html_status');
				};
			}

			this.refresh_payment_pay_on_order();

		}
	}

	// ***************************************************************************************************************************************
	// Set manual price
	// ***************************************************************************************************************************************
	set_manual_price(frm, cdt, cdn, fieldname)
	{
		let can_change = true;

		if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
		{
			if (this.frm.doc.document_type.toUpperCase() == 'CONTRACT')
			{
				can_change = false;
			}
		}

		if (can_change)
		{
			let item = locals[cdt][cdn];

			if (item[fieldname] != 0)
			{
				item.manual_price = 1;
				item.sales_condition_id = this.customer_manual_sales_condition_id;

				if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
					item.price_contract_master_link = '';
					item.price_contract_detail_link = '';
				}
			}
		}
	}

	// ***************************************************************************************************************************************
	// Invoice : Get VAT Code
	// ***************************************************************************************************************************************
	get_vatcode(frm, cdt, cdn)
	{

		let item = locals[cdt][cdn];

		let vat_type = 'N';

		if ((item.vat_type) && (item.vat_type.trim() != ''))
		{
			vat_type = item.vat_type;
		}

		let vat_rate = 0;

		if ((item.vat_rate) && (item.vat_rate != 0))
		{
			vat_rate = item.vat_rate;
		}

		let vat_service = '';

		if ((item.vat_service) && (item.vat_service.trim() != ''))
		{
			vat_service = item.vat_service;
		}

		let vat_matching = '';

		if ((item.vat_matching_id) && (item.vat_matching_id.trim() != ''))
		{
			vat_matching = item.vat_matching_id;
		}

		let account_id = '';

		if ((item.account_id) && (item.account_id.trim() != ''))
		{
			account_id = item.account_id;
		}

		let vat_code_id = '';

		let method = this.path_sales_document + '.ioi_sales_document_get_vatcode'
		frappe.call({  	method: method,
						args: {"vat_type": vat_type, "vat_rate": vat_rate, "vat_service": vat_service, "vat_matching": vat_matching, "account_id": account_id},
						async: false,
						callback:function(r){
							vat_code_id = r.message.vat_code_id;
							vat_rate = r.message.vat_percentage;
						}
		});
		if (vat_code_id != '') {
			item.manual_vat = 1;
			item.vatcode_id = vat_code_id;
			item.vat_rate = vat_rate;
		}
		this.frm.refresh_field('line_detail');

	}

	// ***************************************************************************************************************************************
	// Get analytics
	// ***************************************************************************************************************************************
	get_analytics(frm, cdt, cdn)
	{
		let me = this;

		let item = locals[cdt][cdn];

		let account_id = '';

		if ((item.account_id) && (item.account_id.trim() != '')) {
			account_id = item.account_id;
		}

		if (account_id.trim() != '') {

			let method = this.path_sales_document + '.ioi_sales_document_get_analytics_from_account'
			frappe.call({  	method: method,
							args: {"account_id": account_id},
							async: false,
							callback:function(r){

								item.analytic1_id = r.message.analytic1_id;
								item.analytic2_id = r.message.analytic2_id;
								item.analytic3_id = r.message.analytic3_id;
								item.analytic4_id = r.message.analytic4_id;

								me.frm.refresh_field('line_detail');
							}
			});
		}
	}

	// ***************************************************************************************************************************************
	// Compute unit price tvac
	// ***************************************************************************************************************************************
	compute_unit_price_tvac(frm, cdt, cdn)
	{

		let item = locals[cdt][cdn];

		let unit_price = 0;

		if (item.unit_price)
		{
			unit_price = item.unit_price;
		}

		let rate = 0;

		if ((item.vat_rate) && (item.vat_rate != 0))
		{
			rate = item.vat_rate;
		}

		item.unit_price_tvac = parseFloat(unit_price) + (parseFloat(unit_price) / 100 * parseFloat(rate));

		this.frm.refresh_field('unit_price_tvac');
	}


	// ***************************************************************************************************************************************
	// Compute unit price tvac
	// ***************************************************************************************************************************************
	compute_unit_price(frm, cdt, cdn)
	{

		let item = locals[cdt][cdn];

		let unit_price_tvac = 0;

		if (item.unit_price_tvac)
		{
			unit_price_tvac = item.unit_price_tvac;
		}

		let rate = 0;

		if ((item.vat_rate) && (item.vat_rate != 0))
		{
			rate = item.vat_rate;
		}

		rate += 100;

		item.unit_price = parseFloat(unit_price_tvac) / parseFloat(rate) * 100;

		this.frm.refresh_field('unit_price');
	}

	// ***************************************************************************************************************************************
	// Create GUID
	// ***************************************************************************************************************************************
	#createGUID()
	{
		function random()
		{
		  	return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
		}

		return random() + random() + '-' + random() + '-' + random() + '-' + random() + '-' + random() + random() + random();
	}

	// ***************************************************************************************************************************************
	// Disable frm except fields
	// ***************************************************************************************************************************************
	disable_frm_except_fields()
	{

		if ((document.getElementById('hidden_can_edit_document').value == '0') || (this.frm.doc.frozen_data == 1))
		{
			this.frm.fields.forEach((field) => {
				this.frm.set_df_property(field.df.fieldname, "read_only", "1");
			  });

			this.frm.disable_save();

			let me = this;

			let method = this.path_module_status_accessible_fields + '.ioi_module_status_accessible_fields_get_status_fields';

			frappe.call({  	method: method,
							args: {"doctype":this.frm.doctype, "ioistatus":this.frm.doc.ioistatus},
							async: false,
							callback:function(r)	{

														if (r.message.length > 0)
														{
															let field_child = [];

															for(var i = 0; i < r.message.length; i++)
															{
																// 0 : doctype_id, 1 : field_id, 2 : fieldname, 3 :fieldtype

																if (r.message[i][0] == me.frm.doctype)
																{
																	me.frm.set_df_property(r.message[i][1], 'read_only', 0);
																}else
																{	if (r.message[i][2] != null)
																	{
																		me.frm.set_df_property(r.message[i][2], 'read_only', 0);

																		if (r.message[i][3].toUpperCase() == 'TABLE')
																		{
																			me.frm.fields_dict[r.message[i][2]].grid.docfields.forEach((field) => {
																				if (field_child.indexOf(r.message[i][2] + "|" + field.fieldname) == -1)
																				{
																					me.frm.fields_dict[r.message[i][2]].grid.update_docfield_property(field.fieldname, "read_only", 1);
																				}
																			});

																			if (field_child.indexOf(r.message[i][2] + "|" + r.message[i][1]) == -1)
																			{
																				field_child.push(r.message[i][2] + "|" + r.message[i][1]);
																			}

																			me.frm.fields_dict[r.message[i][2]].grid.update_docfield_property(r.message[i][1], "read_only", 0);
																		}
																	}
																}
															}

															me.frm.enable_save();
														}
													}
			});

		}

		if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {

			if (this.frm.doc.locked_by_credit_control == 1) {

				this.frm.fields.forEach((field) => {
					this.frm.set_df_property(field.df.fieldname, "read_only", "1");
				  });

				this.frm.disable_save();
			}
		}

	}

	// ***************************************************************************************************************************************
	// Display select label when readonly
	// ***************************************************************************************************************************************
	display_select_label()
	{
		for (var i = 0; i < this.frm.fields.length; i++)
		{
			if (this.frm.fields[i].df)
			{
				if (this.frm.fields[i].df.read_only)
				{
					if (this.frm.fields[i].df.read_only == 1)
					{
						let display = true;

						if (this.frm.fields[i].df.hidden)
						{
							if (this.frm.fields[i].df.hidden == 1)
							{
								display = false;
							}
						}

						if (display)
						{
							if (this.frm.fields[i].df.fieldname)
							{
								if (this.frm.fields_dict[this.frm.fields[i].df.fieldname])
								{
									if (this.frm.fields_dict[this.frm.fields[i].df.fieldname].df)
									{
										if (this.frm.fields_dict[this.frm.fields[i].df.fieldname].df.fieldtype.toUpperCase() == 'SELECT')
										{
											if (this.frm.fields[i].last_options)
											{
												let t = JSON.parse(this.frm.fields[i].last_options);

												for (var j = 0; j < t.length; j++)
												{
													if (t[j].value == this.frm.fields[i].value)
													{
														this.frm.fields_dict[this.frm.fields[i].df.fieldname].$wrapper[0].children[0].children[1].children[1].innerText = __(t[j].label);
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// ***************************************************************************************************************************************
	// Select Item : advanced search
	// ***************************************************************************************************************************************
	select_item(frm)
	{
		let me = this;

		let fields = 'a.name, a.description, a.unit_id, b.q_stock, b.q_reserved, a.manufacturer_ref';
		let fields_len = '300, 500, 125, 150, 150, 300';
		let fields_desc = 'Identification, Description, Unit, Stock qty, Reserved qty, Manufacturer Catalog';
		let where = 'ioistatus = 2';
		let order_by = 'a.name asc';
		let parameter_with_stock = true;
		let site_id = this.frm.doc.site_id;

		let fct_callback = function (return_value) {

			let cdt = me.frm.selected_doc.doctype;
			let cdn = me.frm.selected_doc.name;

			let item = locals[cdt][cdn];
			item.item_id = return_value;

			me.frm.refresh_field('line_detail');
			me.populate_line(frm, cdt, cdn, 'ITEM_ID');
			me.compute_document();
			me.format_detail_form(frm, cdt, cdn);
		};


		silicon_ioi.ioiCommon.select_item(fields, fields_len, fields_desc, where, order_by, parameter_with_stock, site_id, fct_callback);

	}

	select_address_customer(frm)
	{
		let me = this;

		let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email, alternate_id';
		let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400, 250';
		let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email, Alternate id';
		let where = 'ioistatus = 2';
		let order_by = 'name asc';

		let fct_callback = function (return_value) {

			let cdt = me.frm.selected_doc.doctype;
			let cdn = me.frm.selected_doc.name;

			let line = locals[cdt][cdn];
			line.customer_id = return_value;

			me.addresses_detail_get_customer_address(frm, cdt, cdn);


			me.frm.refresh_field('address_detail');
		}

		silicon_ioi.ioiCommon.select_customer(fields, fields_len, fields_desc, where, order_by, fct_callback);
	}

	// ***************************************************************************************************************************************
	// Select VAT code : advanced search
	// ***************************************************************************************************************************************
	select_vatcode(frm)
	{
		let me = this;

		if (document.getElementById('hidden_can_edit_document').value == 1)
		{
			let fields = 'name, description, vat_type, vat_kind, vat_percentage, vat_ignore_percentage, intra_goods, intra_services';
			let fields_len = '200, 350, 100, 100, 100, 150, 100, 100';
			let fields_desc = 'Identification, Description, Type, Kind, Rate, Ignore rate, Goods, Services';
			let where = 'sales_purchases = \'S\' and enabled = 1';
			let order_by = 'name asc';


			let fct_callback = function (return_value) {

				let cdt = me.frm.selected_doc.doctype;
				let cdn = me.frm.selected_doc.name;

				let item = locals[cdt][cdn];
				item.vatcode_id = return_value;

				item.manual_vat = 1;

				if ((item.vatcode_id) && (item.vatcode_id != '')) {

					frappe.call({
						method: me.path_sales_document + '.ioi_sales_document_get_vat_percentage',
						args: {"name": item.vatcode_id},
						async: false,
						callback:function(r)	{
							item.vat_rate = r.message.vat_percentage;

						}
					});
				}


				me.frm.refresh_field('line_detail');
				me.compute_document();
				me.format_detail_form(frm, cdt, cdn);
			};


			silicon_ioi.ioiCommon.select_vatcode(fields, fields_len, fields_desc, where, order_by, fct_callback);
		}
	}

	// ***************************************************************************************************************************************
	// Select Manufacturer_cataloge : advanced search
	// ***************************************************************************************************************************************
	select_manufacturer_catalog(frm)
	{

		let me = this;

		let fields = 'a.name, a.item_id, a.description, a.unit_id, a.item_id_live_creation, b.supplier_id, a.barcode_reference, ';
		fields += 'a.family_id, a.type_id, a.group_id, a.subgroup_id, a.shop_category_id, a.classification_id, a.category_id, a.budget_id';
		let fields_len = '300, 200, 350, 100, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200';
		let fields_desc = 'Identification, Item, Description, Unit, Item id live creation, Supplier, Barcode ref, ';
		fields_desc += 'Family, Type, Group, Subgroup, Shop Category, Classification, Category, Budget type';
		let where = 'a.enabled = 1';
		let order_by = 'a.name asc';

		let document_date = me.frm.doc.document_date;



		let fct_callback = function (manufacture_catalog_id, item_id) {

			let cdt = me.frm.selected_doc.doctype;
			let cdn = me.frm.selected_doc.name;

			let item = locals[cdt][cdn];
			item.manufacturer_ref = manufacture_catalog_id;

			if ((item_id != '') && (item_id != null)){
				item.item_id = item_id
			}else{
				item.item_id = '';
			}

			me.frm.refresh_field('line_detail');

			if ((item_id != '') && (item_id != null)){
				me.populate_line(frm, cdt, cdn, 'ITEM_ID');
			}else{
				me.manufacturer_catalog_populate_line(frm, cdt, cdn, 'MANUFACTURER_REF');
			}
			me.compute_document();
			me.format_detail_form(frm, cdt, cdn);
		};


		silicon_ioi.ioiCommon.select_manufacturer_catalog(fields, fields_len, fields_desc, where, order_by, document_date, fct_callback, true);


	}


	// ***************************************************************************************************************************************
	// Update is_option
	// ***************************************************************************************************************************************
	update_is_option(frm, cdt, cdn)
	{
		let item = locals[cdt][cdn];

		if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
		{
			if (item.option_id.trim() == '')
			{
				item.is_option = 0;
			}else
			{
				item.is_option = 1;
			}
		}else
		{
			item.is_option = 0;
		}
	}

	// ***************************************************************************************************************************************
	// Fill available status
	// ***************************************************************************************************************************************
	fill_available_status()
	{
		let available_status_desc = '';
		let available_status_bgcolor = '';

		if ((!this.frm.doc.available_status) || ((this.frm.doc.available_status) && (this.frm.doc.available_status == 0)))
		{
			available_status_desc = __("Uncalculated");
			available_status_bgcolor = '#DCDCDC';

		}else if (this.frm.doc.available_status == 1)
		{
			available_status_desc = __("Nothing");
			available_status_bgcolor = '#FA6E6B';

		}else if (this.frm.doc.available_status == 2)
		{
			available_status_desc = __("Partiel");
			available_status_bgcolor = '#759CFF';

		}else if (this.frm.doc.available_status == 3)
		{
			available_status_desc = __("Full");
			available_status_bgcolor = '#5DE861';

		}

		let html = '';
		this.frm.fields_dict['html_available_status'].$wrapper.empty();

		html = '<div style="overflow: auto; overflow-x: auto; height:100px;">';
		html += '	<label id="ioi_available_status_label" style="position: absolute; top: 0px; left: 2px;">' + __("Available status") + '</label>';
		html += '	<div style="position: absolute; top: 23px; left: 2px;height:32px; width:100%; background-color:' + available_status_bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
		html += '		<label style="position: absolute; top: 7px; left: 10px;"><font color="black">' + available_status_desc + '</font></label>';
		html += '	</div>';


		let available_need_recompute = false;

		if (this.frm.doc.line_detail)
		{
			for(var i = 0; i < this.frm.doc.line_detail.length; i++)
			{
				if (this.frm.doc.line_detail[i].to_execute == 1) {
					available_need_recompute = true;
					break;
				}

			}
		}

		if (available_need_recompute)
		{
			available_need_recompute = false;

			if ((!this.frm.doc.available_calc_datetime) || ((this.frm.doc.available_calc_datetime) && (this.frm.doc.available_calc_datetime == ''))) {
				available_need_recompute = true;
			}else {

				if ((!this.frm.doc.ioistatus_change_dt) || ((this.frm.doc.ioistatus_change_dt) && (this.frm.doc.ioistatus_change_dt == ''))) {
					available_need_recompute = true;
				}

				if (!available_need_recompute) {

					if (this.frm.doc.available_calc_datetime < this.frm.doc.ioistatus_change_dt) {
						available_need_recompute = true;
					}
				}
			}

			if (available_need_recompute) {
				if (this.is_dark_mode == 0) {
					html += '	<label id="ioi_available_need_recompile" style="position: absolute; top: 65px; left: 2px;"><font color="blue">' + __("Availables will be recomputed") + '</font></label>';
				}else{
					html += '	<label id="ioi_available_need_recompile" style="position: absolute; top: 65px; left: 2px;"><font color="#FFFFFF">' + __("Availables will be recomputed") + '</font></label>';

				}
				this.frm.fields_dict['available_calc_datetime'].$wrapper[0].children[0].children[1].children[1].style.backgroundColor = '#FAD487';
			}

		}

		html += '</div>';


		this.frm.fields_dict['html_available_status'].$wrapper.append(html);
	}

	// ***************************************************************************************************************************************
	// Fill available scheduled status
	// ***************************************************************************************************************************************
	fill_available_scheduled_status()
	{
		let available_scheduled_status_desc = '';
		let available_scheduled_status_bgcolor = '';

		if ((!this.frm.doc.available_scheduled_status) || ((this.frm.doc.available_scheduled_status) && (this.frm.doc.available_scheduled_status == 0)))
		{
			available_scheduled_status_desc = __("Uncalculated");
			available_scheduled_status_bgcolor = '#DCDCDC';

		}else if (this.frm.doc.available_scheduled_status == 1)
		{
			available_scheduled_status_desc = __("Nothing");
			available_scheduled_status_bgcolor = '#FA6E6B';

		}else if (this.frm.doc.available_scheduled_status == 2)
		{
			available_scheduled_status_desc = __("Partiel");
			available_scheduled_status_bgcolor = '#759CFF';

		}else if (this.frm.doc.available_scheduled_status == 3)
		{
			available_scheduled_status_desc = __("Full");
			available_scheduled_status_bgcolor = '#5DE861';

		}

		let html = '';
		this.frm.fields_dict['html_available_scheduled_status'].$wrapper.empty();

		html = '<div style="overflow: auto; overflow-x: auto; height:70px;">';
		html += '	<label id="ioi_available_scheduled_status_label" style="position: absolute; top: 0px; left: 2px;">' + __("Available scheduled status") + '</label>';
		html += '	<div style="position: absolute; top: 23px; left: 2px;height:32px; width:100%; background-color:' + available_scheduled_status_bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
		html += '		<label style="position: absolute; top: 7px; left: 10px;"><font color="black">' + available_scheduled_status_desc + '</font></label>';
		html += '	</div>';
		html += '</div>';

		this.frm.fields_dict['html_available_scheduled_status'].$wrapper.append(html);
	}


	// ***************************************************************************************************************************************
	// Fill Line available status
	// ***************************************************************************************************************************************
	#fill_line_available_status(item)
	{
		let available_status_desc = '';
		let available_status_bgcolor = '';

		let available_status_value = this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['available_status'].doc['available_status']

		if ((!available_status_value) || ((available_status_value) && (available_status_value == 0)))
		{
			available_status_desc = __("Uncalculated");
			available_status_bgcolor = '#DCDCDC';

		}else if (available_status_value == 1)
		{
			available_status_desc = __("Nothing");
			available_status_bgcolor = '#FA6E6B';

		}else if (available_status_value == 2)
		{
			available_status_desc = __("Partiel");
			available_status_bgcolor = '#759CFF';

		}else if (available_status_value == 3)
		{
			available_status_desc = __("Full");
			available_status_bgcolor = '#5DE861';

		}

		let html = '';

		this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_available_status'].$wrapper.empty();

		html = '<div style="overflow: auto; overflow-x: auto; height:100px;">';
		html += '	<label id="ioi_available_status_label" style="position: absolute; top: 0px; left: 2px;">' + __("Available status") + '</label>';
		html += '	<div style="position: absolute; top: 23px; left: 2px;height:32px; width:100%; background-color:' + available_status_bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
		html += '		<label style="position: absolute; top: 7px; left: 10px;"><font color="black">' + available_status_desc + '</font></label>';
		html += '	</div>';


		let available_need_recompute = false;

		let to_execute = this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['to_execute'].doc['to_execute'];

		let calc_datetime = this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['available_calc_datetime'].doc['available_calc_datetime'];

		if ((to_execute == null) || (to_execute == ''))	{
			to_execute = 0;
		}

		if (to_execute == 1) {
			available_need_recompute = true;
		}

		if (available_need_recompute)
		{
			available_need_recompute = false;

			if ((!calc_datetime) || ((calc_datetime) && (calc_datetime == ''))) {
				available_need_recompute = true;
			}else {

				if ((!this.frm.doc.ioistatus_change_dt) || ((this.frm.doc.ioistatus_change_dt) && (this.frm.doc.ioistatus_change_dt == ''))) {
					available_need_recompute = true;
				}

				if (!available_need_recompute) {

					if (calc_datetime < this.frm.doc.ioistatus_change_dt) {
						available_need_recompute = true;
					}
				}
			}

			if (available_need_recompute) {

				let line_idx = item.idx-1;

				if (this.is_dark_mode == 0) {
					html += '	<label id="line_ioi_available_need_recompile_' + line_idx.toString() + '" style="position: absolute; top: 65px; left: 2px;"><font color="blue">' + __("Availables will be recomputed") + '</font></label>';
				}else{
					html += '	<label id="line_ioi_available_need_recompile_' + line_idx.toString() + '" style="position: absolute; top: 65px; left: 2px;"><font color="#FFFFFF">' + __("Availables will be recomputed") + '</font></label>';

				}
				this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['available_calc_datetime'].wrapper.children[0].children[1].children[1].style.backgroundColor = '#FAD487';
			}

		}


		html += '</div>';

		this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_available_status'].$wrapper.append(html);

	}

	// ***************************************************************************************************************************************
	// Fill Line available scheduled status
	// ***************************************************************************************************************************************
	#fill_line_available_scheduled_status(item)
	{
		let available_scheduled_status_desc = '';
		let available_scheduled_status_bgcolor = '';

		let available_scheduled_status_value = this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['available_scheduled_status'].doc['available_scheduled_status']

		if ((!available_scheduled_status_value) || ((available_scheduled_status_value) && (available_scheduled_status_value == 0)))
		{
			available_scheduled_status_desc = __("Uncalculated");
			available_scheduled_status_bgcolor = '#DCDCDC';

		}else if (available_scheduled_status_value == 1)
		{
			available_scheduled_status_desc = __("Nothing");
			available_scheduled_status_bgcolor = '#FA6E6B';

		}else if (available_scheduled_status_value == 2)
		{
			available_scheduled_status_desc = __("Partiel");
			available_scheduled_status_bgcolor = '#759CFF';

		}else if (available_scheduled_status_value == 3)
		{
			available_scheduled_status_desc = __("Full");
			available_scheduled_status_bgcolor = '#5DE861';

		}

		let html = '';

		this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_available_scheduled_status'].$wrapper.empty();

		html = '<div style="overflow: auto; overflow-x: auto; height:70px;">';
		html += '	<label id="ioi_available_scheduled_status_label" style="position: absolute; top: 0px; left: 2px;">' + __("Available scheduled status") + '</label>';
		html += '	<div style="position: absolute; top: 23px; left: 2px;height:32px; width:100%; background-color:' + available_scheduled_status_bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
		html += '		<label style="position: absolute; top: 7px; left: 10px;"><font color="black">' + available_scheduled_status_desc + '</font></label>';
		html += '	</div>';
		html += '</div>';

		this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_available_scheduled_status'].$wrapper.append(html);


	}

	// ***************************************************************************************************************************************
	// Build contract price
	// ***************************************************************************************************************************************
	#build_contract_price_detail(html_field, item)
	{
		let html = '';

		this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[html_field].$wrapper.empty();
		this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[html_field].$wrapper.append('&nbsp;');

		if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['price_contract_master_link'].doc['price_contract_master_link'])
		{


			if ((this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['price_contract_master_link'].doc['price_contract_master_link'] != '') &&
				(this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['price_contract_detail_link'].doc['price_contract_detail_link'] != ''))
			{
				this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[html_field].$wrapper.empty();

				let method = this.path_sales_document + '.ioi_sales_document_detail_get_formatted_contract_price_link';

				let master_name = this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['price_contract_master_link'].doc['price_contract_master_link'];
				let detail_name = this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['price_contract_detail_link'].doc['price_contract_detail_link'];

				let id_formatted = '';
				let bgcolor = '';
				let color = '';

				frappe.call({  	method: method,
								args: {"doctype": this.frm.doctype, "master_name": master_name, "detail_name": detail_name},
								async: false,
								callback:function(r)	{
															id_formatted = r.message.formatted_link;
															bgcolor = r.message.bgcolor;
															color = r.message.color;
														}
				});

				if (id_formatted != '')
				{
					let color = '#000000';

					if (bgcolor == '') {
						bgcolor = '#FFFFFF';
					}

					html = '<div style="overflow: auto; overflow-x: auto; height:70px;">';
					html += '	<label style="position: absolute; top: 0px; left: 2px;">';

					html += __("Contract price");

					html += '</label>';
					html += '	<div style="position: absolute; top: 23px; left: 2px;height:32px; width:100%; background-color:' + bgcolor + '; color: black; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
					html += '		<label style="position: absolute; top: 7px; left: 4px;">';

					html += '<a href="#" onclick="window.open(\'/app/ioi-sales-quote/' +  master_name + '\');">';

					html += '<font color="black">' + id_formatted +'</font></a>';
					html += '		</label>';
					html += '	</div>';
					html += '</div>';

					this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[html_field].$wrapper.append(html);
				}
			}
		}

	}



	// ***************************************************************************************************************************************
	// Build linked document detail (linked quote / linked order / linked delivery)
	// ***************************************************************************************************************************************
	#build_linked_document_detail(master_field, detail_field, html_field, item, tp = '')
	{
		let html = '';

		this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[html_field].$wrapper.empty();
		this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[html_field].$wrapper.append('&nbsp;');

		if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[master_field].doc[master_field])
		{
			if ((this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[master_field].doc[master_field] != '') &&
				(this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[detail_field].doc[detail_field] != ''))
			{
				this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[html_field].$wrapper.empty();

				let method = this.path_sales_document + '.ioi_sales_document_detail_get_formatted_link';

				let master_name = this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[master_field].doc[master_field];
				let detail_name = this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[detail_field].doc[detail_field];

				let id_formatted = '';
				let bgcolor = '';
				let color = '';

				frappe.call({  	method: method,
								args: {"doctype": this.frm.doctype, "master_name": master_name, "detail_name": detail_name, "tp": tp},
								async: false,
								callback:function(r)	{
															id_formatted = r.message.formatted_link;
															bgcolor = r.message.bgcolor;
															color = r.message.color;
														}
				});

				if (id_formatted != '')
				{
					let color = '#000000';

					html = '<div style="overflow: auto; overflow-x: auto; height:70px;">';
					html += '	<label style="position: absolute; top: 0px; left: 2px;">';

					if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
					{	html += __("Quote");
					}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
					{	html += __("Order");
					}else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
					{	if (tp == 'D')
						{	html += __("Delivery")
						}else
						{	html += __("Order")
						}

					}

					html += '</label>';
					html += '	<div style="position: absolute; top: 23px; left: 2px;height:32px; width:100%; background-color:' + bgcolor + '; color: black; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
					html += '		<label style="position: absolute; top: 7px; left: 4px;">';

					if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
					{	html += '<a href="#" onclick="window.open(\'/app/ioi-sales-quote/' +  master_name + '\');">';
					}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
					{	html += '<a href="#" onclick="window.open(\'/app/ioi-sales-order/' +  master_name + '\');">';
					}else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
					{
						if (tp == 'D')
						{	html += '<a href="#" onclick="window.open(\'/app/ioi-sales-delivery/' +  master_name + '\');">';
						}else
						{
							html += '<a href="#" onclick="window.open(\'/app/ioi-sales-order/' +  master_name + '\');">';
						}
					}

					html += '<font color="black">' + id_formatted +'</font></a>';
					html += '		</label>';
					html += '	</div>';
					html += '</div>';

					this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[html_field].$wrapper.append(html);
				}
			}
		}
	}


	// ***************************************************************************************************************************************
	// Create a new order from quote
	// **************************************************************************************************************************************
	static order_this_quote(new_order, quote_number = '', order_number = '', bypass = false)
	{
		let n_order = new_order;
		let q_number = quote_number;
		let o_number = order_number;
		let bp = bypass;

		if (cur_frm.is_dirty())
		{
			let fct_callback = function () { silicon_ioi.doctype.ioiSalesDocumentDocType.do_order_this_quote(n_order, q_number, o_number, bp); };
			cur_frm.save('Save', fct_callback);
		}else
		{
			silicon_ioi.doctype.ioiSalesDocumentDocType.do_order_this_quote(n_order, q_number, o_number, bp);
		}
	}

	static do_order_this_quote(new_order, quote_number = '', order_number = '', bypass = false)
	{
		let go = true;

		if (cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
		{
			if ((go) && ((!cur_frm.doc.line_detail) || ((cur_frm.doc.line_detail) && (cur_frm.doc.line_detail.length == 0))))
			{
				frappe.msgprint({title: __("Message"), message: __('Quote lines are mandatory to create the order'), indicator: "red"});
				go = false
			}
		}else
		{
			if (!new_order)
			{
				if ((go) && (cur_frm.doc.ioistatus != 0))
				{
					frappe.msgprint({title: __("Message"), message: __('Document has to be "in preparation"'), indicator: "red"});
					go = false;
				}
			}
		}

		if (go)
		{


			let me = this;

			let title = '';
			let bt_primary_caption = ''


			if (new_order)
			{
				if (cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
				{	title = __('Order this quote / contract : ') + cur_frm.doc.name;
				}else
				{	title = __('Order this quote / contract : ') + quote_number;
				}

				bt_primary_caption = __('Create order');
			}else
			{
				if (cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
				{	title = __('Add line(s) to an existing order from this quote / contract : ') + cur_frm.doc.name;
				}else
				{	title = __('Add line(s) to an existing order from this quote / contract : ') + quote_number;
				}

				bt_primary_caption = __('Add selected line(s) to the order');
			}

			var d = new frappe.ui.Dialog({
				'title': title,
				'fields': [
					{'fieldname': 'html_quotes', 'fieldtype': 'HTML'}

				],
				//size : "extra-large",
				primary_action_label: bt_primary_caption,
				secondary_action_label: __('Cancel'),
				primary_action: function(){

					silicon_ioi.doctype.ioiSalesDocumentDocType.create_order(d, new_order, quote_number);
				},
				secondary_action: function(){
					d.hide();
				}

			});
			let pathsalesquote = 'silicon_ioi.ioi_sales.doctype.ioi_sales_quote.ioi_sales_quote';

			let method = pathsalesquote + '.ioi_sales_quote_get_distinct_line_option_id';

			let options = [];

			let quote_id = '';

			if (cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
			{	quote_id = cur_frm.doc.name;
			}else
			{	quote_id = quote_number;
			}

			frappe.call({  	method: method,
							args: {"name": quote_id},
							async: false,
							callback:function(r)	{
														if (r.message.length > 0)
														{
															for (var i = 0; i < r.message.length; i++)
															{
																options.push(r.message[i].option_id);
															}
														}
													}
			});


			let html = '';

			if (document.getElementById("nb_record"))
			{

				for (var i = 0; i < document.getElementById('nb_record').value; i++)
				{
					if (document.getElementById('checked_id_' + i.toString()))
					{
						document.getElementById('checked_id_' + i.toString()).remove();
					}

					if (document.getElementById('name_id_' + i.toString()))
					{
						document.getElementById('name_id_' + i.toString()).remove();
					}

					if (document.getElementById('option_id_' + i.toString()))
					{
						document.getElementById('option_id_' + i.toString()).remove();
					}


					if (document.getElementById('quoted_qty_' + i.toString())) {
						document.getElementById('quoted_qty_' + i.toString()).remove();
					}


					if (document.getElementById('ordered_qty_' + i.toString())) {
						document.getElementById('ordered_qty_' + i.toString()).remove();
					}



				}

				document.getElementById('nb_record').remove();

			}

			if (document.getElementById('order_nb_record'))
			{
				for (var i = 0; i < document.getElementById('order_nb_record').value; i++)
				{
					if (document.getElementById('order_checked_id_' + i.toString()))
					{
						document.getElementById('order_checked_id_' + i.toString()).remove();
					}

					if (document.getElementById('order_name_id_' + i.toString()))
					{
						document.getElementById('order_name_id_' + i.toString()).remove();
					}

				}

				document.getElementById('order_nb_record').remove();
			}

			if (document.getElementById('check_to_order_button')) {
				document.getElementById('check_to_order_button').remove();
			}

			if (!new_order)
			{
				if (document.getElementById("order_number"))
				{
					document.getElementById("order_number").remove();
				}



				if (document.getElementById("order_number_select"))
				{
					document.getElementById("order_number_select").remove();
				}

				html += '<div style="overflow: auto; overflow-x: auto; height:30px;" data-custom-grid="true">';

				html += '	<label style="position: relative; top: 6px; left: 6px;"><b>' + __("Select the order") + '</b></label>';
				html += '</div>'

				html += '<div style="overflow: auto; overflow-x: auto; height:40px; width: 100%;">';


				html += '	<div style="position: absolute; top: 34px; left: 0px; width:250px;height:28px;">';
				html += '		<input type="text" id="order_number" class="input-with-feedback form-control bold" style="postion:absolute; top: 2px; left: 2px;height:27px" ';
				html += '			   value="' + order_number + '" readonly>';
				html += '	</div>';




				if (order_number.trim() == '')
				{
					html += '	<div style="position: absolute; top: 36px; left: 260px; width:50px;">';
					html += '		<div style="position: absolute; top:0px; left: 2px; height: 25px;">';
					html +='			<button id="order_number_select" title="' + __("...") +'" data-label="..." class="btn btn-default ellipsis" style="height: 25px; width: 50px;" onclick="">' + __("...") + '</button>';
					html += '		</div>';
					html += '	</div>';
				}

				html += '</div>';

			}

			html += '<div style="overflow: auto; overflow-x: auto; height:40px; width: 100%;">';
			html += '	<div style="position: absolute; top: 2px; left: 2px; width:200px;">';
			html += '		<div style="position: absolute; top:0px; left: 2px; height: 35px;">';
			html +='			<button id="check_to_order_button" data-label="..." class="btn btn-default ellipsis" style="height: 35px; width: 250px;" onclick="">' + __("Check still to order") + '</button>';
			html += '		</div>';
			html += '	</div>';
			html += '</div>';

			html += '<div style="overflow: auto; overflow-x: auto; height:8px; width: 100%;">';
			html += '</div>';




			if (options.length > 0)
			{

				if (document.getElementById("nb_options"))
				{
					let n = document.getElementById("nb_options").value;

					for (var i = 0; i < n; i++)
					{
						if (document.getElementById('quote_option_id_' + i.toString()))
						{
							document.getElementById('quote_option_id_' + i.toString()).remove();
						}
					}
				}

				html += '<div style="overflow: auto; overflow-x: auto; height:30px;" data-custom-grid="true">';

				html += '	<label style="position: relative; top: 6px; left: 6px;"><b>' + __("Options") + '</b></label>';
				html += '</div>'

				html += '<div style="overflow: auto; overflow-x: auto; height:30px; width: 100%;">';

				let t = 34;

				if (!new_order)
				{
					t = 104;
				}
				let l = 0;
				let cpt = 0;

				for (var i = 0; i < options.length; i++)
				{

					html += '	<div style="position: absolute; top: ' + t.toString() + 'px; left: ' + l.toString() + 'px; width:30px;height:20px;">';
					html += '		<input type="checkbox" id="quote_option_id_' + cpt.toString() +'" style="postion:absolute; top: 2px; left: 2px;" value="' + options[i] + '" checked>';
					html += '		<label style="position: absolute; top: 0px; left: 22px;">' + options[i] + '</label>';
					html += '	</div>';

					l += 50;
					cpt++;
				}

				html += '</div>';

				html += '<input type="hidden" id="nb_options" value="' + cpt.toString() + '">';

			}

			html += '<div style="overflow: auto; overflow-x: auto; height:600px; width: 100%;">';

			html += '<table border=1 style="border: 1px solid #E8EAEB" width=1605px data-custom-grid="true">';

			html += '<tr style="height:30px">';

			html += '<td width=30px align="center" style="vertical-align: middle;">';
			html += '<input type="checkbox" id="check_all_none" style="postion:absolute; top: 2px; left: 2px;" checked ';
			html += '       onclick=" ';

			html += '					if (document.getElementById(\'nb_options\')) ';
			html += '					{ ';
			html += '						let n = document.getElementById(\'nb_options\').value; ';

			html += '						for (var i = 0; i < n; i++) ';
			html += '						{ ';
			html += '							if (document.getElementById(\'quote_option_id_\' + i.toString())) ';
			html += '							{ ';
			html += '					 			document.getElementById(\'quote_option_id_\' + i.toString()).checked = this.checked; ';
			html += '							} ';
			html += '						} ';
			html += '					} ';

			html += '					for (var i = 0; i < document.getElementById(\'nb_record\').value; i++) ';
			html += '					{	';
			html += '						document.getElementById(\'checked_id_\' + i.toString()).checked = this.checked; ';
			html += '					} ';
			html += '                " ';
			html += '>';
			html += '</td>';
			html += '<td width=50px align="center" style="vertical-align: middle;"><b>' + __("No") + '</b></td>';
			html += '<td width=250px style="vertical-align: middle;">&nbsp;<b>' + __("Item") + '</b></td>';
			html += '<td width=350px style="vertical-align: middle;">&nbsp;<b>' + __("Description") + '</b></td>';
			html += '<td width=250px style="vertical-align: middle;">&nbsp;<b>' + __("Your reference") + '</b></td>';
			html += '<td width=250px style="vertical-align: middle;">&nbsp;<b>' + __("Manufacturer catalog") + '</b></td>';
			html += '<td width=50px align="center" style="vertical-align: middle;"><b>' + __("Opt.") + '</b></td>';
			html += '<td width=125px align="center" style="vertical-align: middle;"><b>' + __("Quoted qty") + '</b></td>';
			html += '<td width=125px align="center" style="vertical-align: middle;"><b>' + __("Ordered qty") + '</b></td>';
			html += '<td width=125px align="center" style="vertical-align: middle;"><b>' + __("Unit price") + '</b></td>';
			html += '</tr>';
			html += '</table>';

			method = pathsalesquote + '.ioi_sales_quote_get_detail';


			frappe.call({  	method: method,
							args: {"name": quote_id},
							async: false,
							callback:function(r)	{
														if (r.message.length > 0)
														{	html += '<input type="hidden" id="nb_record" value="' +  r.message.length + '">';
															html += '<table border=1 style="border: 1px solid #E8EAEB" width=1605px>';

															for (var i = 0; i < r.message.length; i++)
															{
																html += '<tr style="height:30px">';

																html += '<td width=30px align="center" style="vertical-align: middle;">';
																html += '<input type="checkbox" id="checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;" checked>';
																html += '<input type="hidden" id="name_id_' + i.toString() + '" value="' +  r.message[i].name + '">';
																html += '<input type="hidden" id="option_id_' + i.toString() + '" value="' +  r.message[i].option_id + '">';

																html += '<input type="hidden" id="quoted_qty_' + i.toString() + '" value="' +  r.message[i].quoted_qty + '">';
																html += '<input type="hidden" id="ordered_qty_' + i.toString() + '" value="' +  r.message[i].ordered_qty + '">';

																html += '</td>';

																html += '<td bgcolor="' + r.message[i].background_color + '" width=50px align="center" style="vertical-align: middle;"><font color="black">' + r.message[i].idx + '</font></td>';


																html += '<td width=250px style="vertical-align: middle;">&nbsp;'

																if (r.message[i].item_id != null)
																{	html += r.message[i].item_id;
																}
																html += '</td>';

																html += '<td width=350px style="vertical-align: middle;">&nbsp;'

																if (r.message[i].item_description != null)
																{	html += r.message[i].item_description.substr(0,50);
																}

																html += '</td>';

																html += '<td width=250px style="vertical-align: middle;">&nbsp;'

																if (r.message[i].external_ref != null)
																{	html += r.message[i].external_ref;
																}
																html += '</td>';


																html += '<td width=250px style="vertical-align: middle;">&nbsp;'

																if (r.message[i].manufacturer_ref != null)
																{	html += r.message[i].manufacturer_ref;
																}
																html += '</td>';


																html += '<td width=50px align="center" style="vertical-align: middle;">'

																if (r.message[i].option_id != null)
																{
																	html += r.message[i].option_id;

																}

																html += '</td>';


																html += '<td width=125px align="right" style="vertical-align: middle;">'

																if ((r.message[i].quoted_qty != null) && (r.message[i].quoted_qty != 0))
																{
																	html += r.message[i].quoted_qty + '&nbsp;'

																	if (r.message[i].unit_id != null)
																	{	html += r.message[i].unit_id + '&nbsp';
																	}
																}

																html += '</td>';


																html += '<td width=125px align="right" style="vertical-align: middle;">'

																if ((r.message[i].ordered_qty != null) && (r.message[i].ordered_qty != 0))
																{
																	html += r.message[i].ordered_qty + '&nbsp;'

																	if (r.message[i].unit_id != null)
																	{	html += r.message[i].unit_id + '&nbsp';
																	}
																}

																html += '</td>';

																html += '<td width=125px align="right" style="vertical-align: middle;">'

																if ((r.message[i].unit_price != null) && (r.message[i].unit_price != 0))
																{
																	html += r.message[i].unit_price.toFixed(2) + '&nbsp;'

																	if (r.message[i].currency_id != null)
																	{	html += r.message[i].currency_id + '&nbsp';
																	}
																}

																html += '</td>';

																html += '</tr>';
															}
															html += '</table>';
														}else
														{
															html += '<input type="hidden" id="nb_record" value="0">';
														}
													}
			});


			html += '</div>';

			d.fields_dict.html_quotes.$wrapper.html(html);


			d.show();


			silicon_ioi.doctype.ioiSalesDocumentDocType.sleep_static(200).then(() => {

				let fct_still_to_order = function() {

					for (var i = 0; i < document.getElementById('nb_record').value; i++) {

						if (document.getElementById('checked_id_' + i.toString())) {
							document.getElementById('checked_id_' + i.toString()).checked = false;
						}

						let quoted_qty = 0;

						if (document.getElementById('quoted_qty_' + i.toString())) {
							if (document.getElementById('quoted_qty_' + i.toString()).value) {
								quoted_qty = parseFloat(document.getElementById('quoted_qty_' + i.toString()).value)
							}
						}

						let ordered_qty = 0;

						if (document.getElementById('ordered_qty_' + i.toString())) {
							if (document.getElementById('ordered_qty_' + i.toString()).value) {
								ordered_qty = parseFloat(document.getElementById('ordered_qty_' + i.toString()).value)
							}
						}

						if (parseFloat(ordered_qty) < parseFloat(quoted_qty)) {

							if (document.getElementById('checked_id_' + i.toString())) {
								document.getElementById('checked_id_' + i.toString()).checked = true;
							}
						}
					}

				}

				document.getElementById('check_to_order_button').onclick = fct_still_to_order;



				if (bypass)
				{
					document.getElementsByClassName("modal-content")[document.getElementsByClassName("modal-content").length-1].hidden = true;
				}

				document.getElementsByClassName("modal-content")[document.getElementsByClassName("modal-content").length-1].style.position = 'absolute';
				document.getElementsByClassName("modal-content")[document.getElementsByClassName("modal-content").length-1].style.width = '1150px';

				let w = ((1355 / 4)) * -1;

				document.getElementsByClassName("modal-content")[document.getElementsByClassName("modal-content").length-1].style.left = (w).toFixed(0) + 'px';

				if (bypass)
				{ 	d.hide();
				}

			});

			if (bypass)
			{ 	d.hide();
			}

			silicon_ioi.doctype.ioiSalesDocumentDocType.sleep_static(1000).then(() => {

											if (document.getElementById("order_number_select"))
											{
												document.getElementById("order_number_select").onclick = silicon_ioi.doctype.ioiSalesDocumentDocType.quote_select_an_order;
											}

											if (options.length > 0)
											{
												for (var i = 0; i < options.length; i++)
												{
													document.getElementById('quote_option_id_' + i.toString()).onclick = silicon_ioi.doctype.ioiSalesDocumentDocType.quote_order_option_check;
												}

											}

											if (bypass)
											{	d.hide();

												silicon_ioi.doctype.ioiSalesDocumentDocType.create_order(d, new_order, quote_number)
											}



		 	});



		}
	}

	// ***************************************************************************************************************************************
	// Quote Order option check
	// **************************************************************************************************************************************
	static quote_order_option_check(id)
	{
		for (var i = 0; i < document.getElementById('nb_record').value; i++)
		{
			if (document.getElementById('option_id_' + i.toString()).value == this.value)
			{
				document.getElementById('checked_id_' + i.toString()).checked = this.checked;
			}
		}
	}

	// ***************************************************************************************************************************************
	// Create Order
	// **************************************************************************************************************************************
	static create_order(d, new_order, quote_number)
	{
		let go = true;

		if (!new_order)
		{
			if (document.getElementById("order_number").value.trim() == '')
			{
				frappe.msgprint({title: __("Message"), message: __('Order number is mandatory'), indicator: "red"});
				go = false;
			}
		}


		if ((go) && (document.getElementById('nb_record').value == 0))
		{
			if (new_order)
			{	frappe.msgprint({title: __("Message"), message: __('No quote line to create an order'), indicator: "red"});
			}else
			{	frappe.msgprint({title: __("Message"), message: __('No quote line to add to the order'), indicator: "red"});
			}
			go = false;
		}

		if (go)
		{
			let one_selected = false
			let quote_selected_lines = []

			for (var i = 0; i < document.getElementById('nb_record').value; i++)
			{
				if (document.getElementById('checked_id_' + i.toString()).checked)
				{
					one_selected = true;
					quote_selected_lines.push(document.getElementById('name_id_' + i.toString()).value);
				}
			}

			if (!one_selected)
			{
				frappe.msgprint({title: __("Message"), message: __('select at least one quote line'), indicator: "red"});
				go = false;

			}

			if (go)
			{
				let pathsalesquote = 'silicon_ioi.ioi_sales.doctype.ioi_sales_quote.ioi_sales_quote';
				let method = pathsalesquote + '.ioi_sales_quote_create_order';

				let order_number = ''

				if (!new_order)
				{
					if (document.getElementById('order_number'))
					{
						order_number = document.getElementById('order_number').value;
					}
				}

				let quote_id = '';

				if (cur_frm.doc.doctype.toUpperCase() == 'IOI SALES QUOTE')
				{	quote_id = cur_frm.doc.name;
				}else
				{	quote_id = quote_number;
				}

				frappe.call({  	method: method,
								args: {"name": quote_id, "selected_lines":[quote_selected_lines], "new_order" : new_order, "order_id": order_number},
								async: false,
								callback:function(r)	{
															if (r.message.error != 0)
															{
																frappe.msgprint({title: __("Message"), message: r.message.error_message, indicator: "red"});
															}else
															{
																d.hide();

																if (new_order)
																{
																	window.open('/app/ioi-sales-order/' + r.message.order_number);
																}else
																{	if (quote_number.trim() != '')
																	{
																		window.location.reload();
																	}
																}
															}
														}
				});
			}
		}
	}

	// ***************************************************************************************************************************************
	// Quote : select an order
	// **************************************************************************************************************************************
	static quote_select_an_order()
	{
		var z = new frappe.ui.Dialog({
			'title': __("Select an order"),
			'fields': [
				{'fieldname': 'html_select_order', 'fieldtype': 'HTML'}

			],
			primary_action_label: 'Ok',
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

				let go = true;

				if (document.getElementById('order_nb_record').value == 0)
				{	document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
					frappe.msgprint({title: __("Message"), message: __("No order selected"), indicator: "red"});
				}

				if ((go) && (document.getElementById('order_nb_record').value != 0))
				{	go = false
					for (var i = 0; i < document.getElementById('order_nb_record').value; i++)
					{
						if (document.getElementById('order_checked_id_' + i.toString()).checked)
						{
							document.getElementById('order_number').value = document.getElementById('order_name_id_' + i.toString()).value;
							go = true;
							break;
						}
					}

					if (!go)
					{	document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
						frappe.msgprint({title: __("Message"), message: __("Select an order, please"), indicator: "red"});
					}

				}

				if (go)
				{
					z.hide();
				}else{
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
				}
			},
			secondary_action: function(){
				z.hide();
			}

		});

		if (document.getElementById('order_nb_record'))
		{
			for (var i = 0; i < document.getElementById('order_nb_record').value; i++)
			{
				if (document.getElementById('order_checked_id_' + i.toString()))
				{
					document.getElementById('order_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('order_name_id_' + i.toString()))
				{
					document.getElementById('order_name_id_' + i.toString()).remove();
				}

			}

			document.getElementById('order_nb_record').remove();
		}

		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:600px; width: 100%;">';

		html += '<table border=1 style="border: 1px solid #E8EAEB" width=1055px data-custom-grid="true">';

		html += '<tr style="height:30px">';

		html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=200px style="vertical-align: middle;">&nbsp;<b>' + __("Order number") + '</b></td>';
		html += '<td width=100px align="center" style="vertical-align: middle;"><b>' + __("Doc. date") + '</b></td>';
		html += '<td width=250px style="vertical-align: middle;">&nbsp;<b>' + __("Order customer") + '</b></td>';
		html += '<td width=350px style="vertical-align: middle;">&nbsp;<b>' + __("Customer name") + '</b></td>';
		html += '<td width=125px align="center" style="vertical-align: middle;"><b>' + __("Total w/o VAT") + '</b></td>';
		html += '</tr>';
		html += '</table>';

		let pathsalesorder = 'silicon_ioi.ioi_sales.doctype.ioi_sales_order.ioi_sales_order';

		let method = pathsalesorder + '.ioi_sales_order_get_compatible_orders_with_quote';


		frappe.call({  	method: method,
						args: {"quote_id": cur_frm.doc.name},
						async: false,
						callback:function(r)	{
													if (r.message.length > 0)
													{	html += '<input type="hidden" id="order_nb_record" value="' + r.message.length + '">';
														html += '<table border=1 style="border: 1px solid #E8EAEB" width=1055px>';

														for (var i = 0; i < r.message.length; i++)
														{
															html += '<tr style="height:30px">';

															html += '<td width=30px align="center" style="vertical-align: middle;">';
															html += '<input type="checkbox" id="order_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
															html += '       onclick=" ';
															html += '					for (var i = 0; i < document.getElementById(\'order_nb_record\').value; i++) ';
															html += '					{';
															html += '						if (document.getElementById(\'order_checked_id_\' + i.toString()).id != this.id) ';
															html += '						{ ';
															html += '							document.getElementById(\'order_checked_id_\' + i.toString()).checked = false; ';
															html += '						} ';
															html += '					} ';
																												html += '">';
															html += '<input type="hidden" id="order_name_id_' + i.toString() + '" value="' +  r.message[i].name + '">';
															html += '</td>';

															html += '<td width=200px style="vertical-align: middle;">&nbsp;' + r.message[i].name + '</td>'

															html += '<td width=100px style="vertical-align: middle;">&nbsp;' + r.message[i].document_date + '</td>'

															html += '<td width=250px style="vertical-align: middle;">&nbsp;' + r.message[i].order_customer_id + '</td>'

															html += '<td width=350px style="vertical-align: middle;">&nbsp;'

															if (r.message[i].order_customer_name != null)
															{	html += r.message[i].order_customer_name.substr(0,50);
															}

															html += '</td>';


															html += '<td width=125px align="right" style="vertical-align: middle;">'

															if ((r.message[i].total_htva != null))
															{
																html += r.message[i].total_htva.toFixed(2) + '&nbsp;'

																if (r.message[i].currency_id != null)
																{	html += r.message[i].currency_id + '&nbsp';
																}
															}

															html += '</td>';

															html += '</tr>';
														}
														html += '</table>';
													}else
													{
														html += '<input type="hidden" id="order_nb_record" value="0">';
													}
												}
		});

		html += '</div>';


		z.fields_dict.html_select_order.$wrapper.html(html);


		z.show();
	}

	// ***************************************************************************************************************************************
	// Create Order from Quote
	// **************************************************************************************************************************************
	create_order_from_quote(new_order)
	{
		var z = new frappe.ui.Dialog({
			'title': __("Select a quote"),
			'fields': [
				{'fieldname': 'html_select_quote', 'fieldtype': 'HTML'}

			],
			primary_action_label: 'Ok',
			secondary_action_label: __('Cancel'),
			primary_action: function(){


				let go = true;

				if (document.getElementById('quote_nb_record').value == 0)
				{
					frappe.msgprint({title: __("Message"), message: __("No quote selected"), indicator: "red"});
				}

				if ((go) && (document.getElementById('quote_nb_record').value != 0))
				{	go = false
					for (var i = 0; i < document.getElementById('quote_nb_record').value; i++)
					{
						if (document.getElementById('quote_checked_id_' + i.toString()).checked)
						{
							document.getElementById('quote_number').value = document.getElementById('quote_name_id_' + i.toString()).value;
							go = true;
							break;
						}
					}

					if (!go)
					{
						frappe.msgprint({title: __("Message"), message: __("Select a quote, please"), indicator: "red"});
					}

				}

				if (go)
				{
					z.hide();
					if (new_order)
					{
						silicon_ioi.doctype.ioiSalesDocumentDocType.order_this_quote(true, document.getElementById('quote_number').value, '');
					}else
					{
						silicon_ioi.doctype.ioiSalesDocumentDocType.order_this_quote(false, document.getElementById('quote_number').value, cur_frm.doc.name);
					}
				}
			},
			secondary_action: function(){
				z.hide();
			}

		});

		if (document.getElementById('quote_nb_record'))
		{
			for (var i = 0; i < document.getElementById('quote_nb_record').value; i++)
			{
				if (document.getElementById('quote_checked_id_' + i.toString()))
				{
					document.getElementById('quote_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('quote_name_id_' + i.toString()))
				{
					document.getElementById('quote_name_id_' + i.toString()).remove();
				}

			}

			document.getElementById('quote_nb_record').remove();
		}

		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:600px; width: 100%;">';

		html += '<table border=1 style="border: 1px solid #E8EAEB" width=1055px data-custom-grid="true">';

		html += '<tr style="height:30px">';

		html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=200px style="vertical-align: middle;">&nbsp;<b>' + __("Quote number") + '</b></td>';
		html += '<td width=100px align="center" style="vertical-align: middle;"><b>' + __("Doc. date") + '</b></td>';
		html += '<td width=250px style="vertical-align: middle;">&nbsp;<b>' + __("Order customer") + '</b></td>';
		html += '<td width=350px style="vertical-align: middle;">&nbsp;<b>' + __("Customer name") + '</b></td>';
		html += '<td width=125px align="center" style="vertical-align: middle;"><b>' + __("Total w/o VAT") + '</b></td>';
		html += '</tr>';
		html += '</table>';


		let method = this.path_sales_quote + '.ioi_sales_quote_get_quotes_for_order';


		frappe.call({  	method: method,
						args: {"new_order" : new_order, "order_id": this.frm.doc.name },
						async: false,
						callback:function(r)	{
													if (r.message.length > 0)
													{	html += '<input type="hidden" id="quote_nb_record" value="' + r.message.length + '">';
														html += '<table border=1 style="border: 1px solid #E8EAEB" width=1055px>';

														for (var i = 0; i < r.message.length; i++)
														{
															html += '<tr style="height:30px">';

															html += '<td width=30px align="center" style="vertical-align: middle;">';
															html += '<input type="checkbox" id="quote_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
															html += '       onclick=" ';
															html += '					for (var i = 0; i < document.getElementById(\'quote_nb_record\').value; i++) ';
															html += '					{';
															html += '						if (document.getElementById(\'quote_checked_id_\' + i.toString()).id != this.id) ';
															html += '						{ ';
															html += '							document.getElementById(\'quote_checked_id_\' + i.toString()).checked = false; ';
															html += '						} ';
															html += '					} ';
																												html += '">';
															html += '<input type="hidden" id="quote_name_id_' + i.toString() + '" value="' +  r.message[i].name + '">';
															html += '</td>';

															html += '<td width=200px style="vertical-align: middle;">&nbsp;' + r.message[i].name + '</td>'

															html += '<td width=100px style="vertical-align: middle;">&nbsp;' + r.message[i].document_date + '</td>'

															html += '<td width=250px style="vertical-align: middle;">&nbsp;' + r.message[i].order_customer_id + '</td>'

															html += '<td width=350px style="vertical-align: middle;">&nbsp;'

															if (r.message[i].order_customer_name != null)
															{	html += r.message[i].order_customer_name.substr(0,50);
															}

															html += '</td>';


															html += '<td width=125px align="right" style="vertical-align: middle;">'

															if ((r.message[i].total_htva != null))
															{
																html += r.message[i].total_htva.toFixed(2) + '&nbsp;'

																if (r.message[i].currency_id != null)
																{	html += r.message[i].currency_id + '&nbsp';
																}
															}

															html += '</td>';

															html += '</tr>';
														}
														html += '</table>';
													}else
													{
														html += '<input type="hidden" id="quote_nb_record" value="0">';
													}
												}
		});

		html += '<input type="hidden" id="quote_number" value="">';
		html += '</div>';


		z.fields_dict.html_select_quote.$wrapper.html(html);


		z.show();
	}

	// ***************************************************************************************************************************************
	// Change Unit
	// **************************************************************************************************************************************
	change_unit(frm, cdt, cdn)
	{
		let item = locals[cdt][cdn]

		let can_show_unit_frm = true;

		if (item.ioistatus != 0)
		{
			frappe.msgprint({title: __("Message"), message: __("Line status has to be in preparation"), indicator: "red"});
			can_show_unit_frm = false;
		}


		if (((item.item_id) && (item.item_id.trim() != ''))) {

			if ((can_show_unit_frm) && ((!this.frm.doc.order_customer_id) || ((this.frm.doc.order_customer_id) && (this.frm.doc.order_customer_id.trim() == ''))))
			{
				frappe.msgprint({title: __("Message"), message: __("Order customer has to be filled"), indicator: "red"});
				this.frm.refresh_field('line_detail');
				can_show_unit_frm = false;
			}

			if ((can_show_unit_frm) &&  ((can_show_unit_frm) && ((!this.frm.doc.sales_catalog_id) || ((this.frm.doc.sales_catalog_id) && (this.frm.doc.sales_catalog_id.trim() == '')))))
			{
				frappe.msgprint({title: __("Message"), message: __("Sales catalog has to be filled"), indicator: "red"});
				this.frm.refresh_field('line_detail');
				can_show_unit_frm = false;
			}

			if ((can_show_unit_frm) && ((!item.sales_condition_id) || ((item.sales_condition_id) && (item.sales_condition_id.trim() == ''))))
			{
				frappe.msgprint({title: __("Message"), message: __("Sales condition (item line) has to be filled"), indicator: "red"});
				this.frm.refresh_field('line_detail');
				can_show_unit_frm = false;
			}

			if ((can_show_unit_frm) && ((!this.frm.doc.document_date) || ((this.frm.doc.document_date) && (this.frm.doc.document_date.trim() == ''))))
			{
				frappe.msgprint({title: __("Message"), message: __("Document date has to be filled"), indicator: "red"});
				this.frm.refresh_field('line_detail');
				can_show_unit_frm = false;
			}

			if ((can_show_unit_frm) && ((!this.frm.doc.currency_id) || ((this.frm.doc.currency_id) && (this.frm.doc.currency_id.trim() == ''))))
			{
				frappe.msgprint({title: __("Message"), message: __("Currency has to be filled"), indicator: "red"});
				this.frm.refresh_field('line_detail');
				can_show_unit_frm = false;
			}

			if ((can_show_unit_frm) && ((!item.item_id) || ((item.item_id) && (item.item_id.trim() == ''))))
			{
				frappe.msgprint({title: __("Message"), message: __("Item has to be filled"), indicator: "red"});
				this.frm.refresh_field('line_detail');
				can_show_unit_frm = false;
			}

			if (can_show_unit_frm)
			{
				let me = this;
				let ccdt = cdt;
				let ccdn = cdn;

				var z = new frappe.ui.Dialog({
					'title': __("Select an unit"),
					'fields': [
						{'fieldname': 'html_select_unit', 'fieldtype': 'HTML'}

					],
					primary_action_label: 'Ok',
					secondary_action_label: __('Cancel'),
					primary_action: function(){

						let go = true;

						if (document.getElementById('unit_nb_record').value == 0)
						{
							go = false;
						}

						if ((go) && (document.getElementById('unit_nb_record').value != 0))
						{	go = false
							for (var i = 0; i < document.getElementById('unit_nb_record').value; i++)
							{
								if (document.getElementById('unit_checked_id_' + i.toString()).checked)
								{
									document.getElementById('selected_unit_id').value = document.getElementById('unit_name_id_' + i.toString()).value;
									go = true;
									break;
								}
							}
						}

						if (go)
						{
							z.hide();

							let do_populate = true;

							if (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER')
							{
								if ((item.quote_master_link) && (item.quote_master_link.trim() != ''))
								{
									do_populate = false;
								}
							}else if (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
							{
								if ((item.order_master_link) && (item.order_master_link.trim() != ''))
								{
									do_populate = false;
								}
							}else if (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
							{
								if ((item.delivery_master_link) && (item.delivery_master_link.trim() != ''))
								{
									do_populate = false;
								}
							}

							if (do_populate)
							{
								if (!cur_frm.is_dirty()) {
									cur_frm.dirty();
								}
								me.populate_line(me.frm, ccdt, ccdn, 'ITEM_ID', document.getElementById('selected_unit_id').value);
							}
							me.format_detail_form(me.frm, ccdt, ccdn);
						}
					},
					secondary_action: function(){
						z.hide();
					}

				});

				if (document.getElementById('unit_nb_record'))
				{
					for (var i = 0; i < document.getElementById('unit_nb_record').value; i++)
					{
						if (document.getElementById('unit_checked_id_' + i.toString()))
						{
							document.getElementById('unit_checked_id_' + i.toString()).remove();
						}

						if (document.getElementById('unit_name_id_' + i.toString()))
						{
							document.getElementById('unit_name_id_' + i.toString()).remove();
						}

					}

					document.getElementById('unit_nb_record').remove();
				}

				let html = '';

				html += '<div style="overflow: auto; overflow-x: auto; height:300px; width: 100%;">';

				html += '<table border=1 style="border: 1px solid #E8EAEB" width=230px data-custom-grid="true">';

				html += '<tr style="height:30px">';

				html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
				html += '<td width=200px style="vertical-align: middle;">&nbsp;<b>' + __("Unit") + '</b></td>';
				html += '</tr>';
				html += '</table>';


				let method = this.path_sales_price + '.ioi_sales_price_get_unit_for_item_cat_cust_cond_curr';


				frappe.call({  	method: method,
								args: {	"item_id": item.item_id, "catalog_id": this.frm.doc.sales_catalog_id, "customer_id": this.frm.doc.order_customer_id, "condition_id" : item.sales_condition_id,
										"currency_id" : this.frm.doc.currency_id, "document_date" : this.frm.doc.document_date},
								async: false,
								callback:function(r)	{
															if (r.message.length > 0)
															{	html += '<input type="hidden" id="unit_nb_record" value="' + r.message.length + '">';
																html += '<table border=1 style="border: 1px solid #E8EAEB" width=230px>';

																for (var i = 0; i < r.message.length; i++)
																{
																	html += '<tr style="height:30px">';

																	html += '<td width=30px align="center" style="vertical-align: middle;">';
																	html += '<input type="checkbox" id="unit_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
																	html += '       onclick=" ';
																	html += '					for (var i = 0; i < document.getElementById(\'unit_nb_record\').value; i++) ';
																	html += '					{';
																	html += '						if (document.getElementById(\'unit_checked_id_\' + i.toString()).id != this.id) ';
																	html += '						{ ';
																	html += '							document.getElementById(\'unit_checked_id_\' + i.toString()).checked = false; ';
																	html += '						} ';
																	html += '					} ';
																	html += '" ';

																	if (item.unit_id == r.message[i])
																	{
																		html += ' checked '
																	}

																	html += '>';
																	html += '<input type="hidden" id="unit_name_id_' + i.toString() + '" value="' +  r.message[i] + '">';
																	html += '</td>';

																	html += '<td width=200px style="vertical-align: middle;">&nbsp;' + r.message[i] + '</td>'

																	html += '</tr>';
																}
																html += '</table>';
															}else
															{
																html += '<input type="hidden" id="unit_nb_record" value="0">';
															}
														}
				});

				html += '<input type="hidden" id="selected_unit_id" value="">';
				html += '</div>';


				z.fields_dict.html_select_unit.$wrapper.html(html);

				z.show();


				silicon_ioi.doctype.ioiSalesDocumentDocType.sleep_static(250).then(() => {

					document.getElementsByClassName("modal-content")[document.getElementsByClassName("modal-content").length-1].style.position = 'absolute';
					document.getElementsByClassName("modal-content")[document.getElementsByClassName("modal-content").length-1].style.width = '300px';

					let w = ((300 / 4)) * -1;

					document.getElementsByClassName("modal-content")[document.getElementsByClassName("modal-content").length-1].style.left = (w).toFixed(0) + 'px';


				});

			}else
			{
				this.format_detail_form(this.frm, cdt, cdn);
			}
		}else{
			if (((!item.manufacturer_ref) || ((item.manufacturer_ref) && (item.manufacturer_ref.trim() == '')))) {

				let me = this;

				let title = __("Select an unit");
				let form_width_pixel = '600px';
				let table = "ioi Item Unit";
				let fields = 'name, description';
				let fields_len = '200, 300';
				let fields_desc = __('Identification') + ',' + __('Description');
				let where = 'enabled = 1';
				let order_by = 'name asc';

				let fct_callback = function (return_value) {
					item.unit_id = return_value;
					item.stock_unit_id = return_value;
					item.coef_sales_unit = 1;
					item.coef_stock_unit = 1;
					me.frm.refresh_field('line_detail');
					me.format_detail_form(me.frm, cdt, cdn);
				}
				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);


			}else{
				frappe.msgprint({title: __("Message"), message: __("No unit changing for a manufacturer catalog"), indicator: "red"});
			}
		}
	}

	// ***************************************************************************************************************************************
	// Build Location Buttons (select and clear)
	// **************************************************************************************************************************************

	#build_html_location_buttons(fieldname, cdt, cdn)
	{
		let me = this;
		let item = locals[cdt][cdn];

		if (document.getElementById('bt_select_location'))
		{
			document.getElementById('bt_select_location').remove();
		}

		if (document.getElementById('bt_clear_location'))
		{
			document.getElementById('bt_clear_location').remove();
		}

		if (document.getElementById('line_cdt'))
		{
			document.getElementById('line_cdt').remove();
		}

		if (document.getElementById('line_cdn'))
		{
			document.getElementById('line_cdn').remove();
		}


		let html = '';
		html += '<div>';
		html +=	'	<button id="bt_select_location" data-label="Select a location" class="btn btn-default ellipsis" onclick="">' + __("Select a location") + '</button> ';
		html +=	'	<button id="bt_clear_location"  data-label="Clear location" class="btn btn-default ellipsis" onclick="">' + __("Clear location") + '</button> ';
		html += '   <input type="hidden" id="line_cdt" value="' + cdt + '"> ';
		html += '   <input type="hidden" id="line_cdn" value="' + cdn + '"> ';
		html += '</div>'
		this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].$wrapper.empty();
		this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].$wrapper.append(html);

		let fct_select = function () {	me.select_location(true); };
		let fct_clear = function () {	me.clear_location(true); };

		document.getElementById('bt_select_location').onclick = fct_select;
		document.getElementById('bt_clear_location').onclick = fct_clear;
	}

	// ***************************************************************************************************************************************
	// Clear location
	// **************************************************************************************************************************************
	clear_location(form = false, idx = 0)
	{
		let item = null;

		if (form) {
			item = locals[document.getElementById('line_cdt').value][document.getElementById('line_cdn').value];
		}else{
			item = locals[cur_frm.fields_dict['line_detail'].grid.doctype][cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name];
		}

		let can_clear_location = true;

		if (item.ioistatus != 0)
		{
			frappe.msgprint({title: __("Message"), message: __("Line status has to be in preparation"), indicator: "red"});
			can_clear_location = false;
		}

		if (can_clear_location)
		{
			item.warehouse_location_id = '';
			this.frm.refresh_field('line_detail');
			this.frm.dirty();

			if (form) {
				this.format_detail_form(this.frm, document.getElementById('line_cdt').value, document.getElementById('line_cdn').value);
			}else{
				this.format_detail_form(this.frm, cur_frm.fields_dict['line_detail'].grid.doctype, cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name);
			}
		}
	}

	// ***************************************************************************************************************************************
	// Select a location
	// **************************************************************************************************************************************
	select_location(form = false, idx = 0)
	{
		let item = null;

		if (form) {
			item = locals[document.getElementById('line_cdt').value][document.getElementById('line_cdn').value];
		}else{
			item = locals[cur_frm.fields_dict['line_detail'].grid.doctype][cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name];
		}

		let can_show_location_frm = true;

		if (item.ioistatus != 0)
		{
			frappe.msgprint({title: __("Message"), message: __("Line status has to be in preparation"), indicator: "red"});
			return false;
		}

		if ((can_show_location_frm) && ((!item.warehouse_id) || ((item.warehouse_id) && (item.warehouse_id.trim() == ''))))
		{
			frappe.msgprint({title: __("Message"), message: __("Warehouse has to be filled"), indicator: "red"});
			this.frm.refresh_field('line_detail');
			return false;
		}

		if (can_show_location_frm)
		{
			let me = this;

			let ccdt = null;
			let ccdn = null;

			if (form) {
				ccdt = document.getElementById('line_cdt').value;
				ccdn = document.getElementById('line_cdn').value;
			}else{
				ccdt = cur_frm.fields_dict['line_detail'].grid.doctype;
				ccdn = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name;
			}

			let tp = 'OUT';
			let warehouse_id = item.warehouse_id;
			let warehouse_location_id = '';
			let dossier_id = item.dossier_id;
			let dossier_focus = 0;
			let item_id = item.item_id;
			let batch_sn_id = null;
			let stored_qty_mode = 0;


			if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') {

				if ((item.quoted_qty) && (item.quoted_qty < 0)) {
					tp = 'IN';
				}

			}else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {

				if ((item.ordered_qty) && (item.ordered_qty < 0)) {
					tp = 'IN';
				}


				batch_sn_id = item.batch_sn_id;

			}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {

				if ((item.delivered_qty) && (item.delivered_qty < 0)) {
					tp = 'IN';
				}

				warehouse_location_id = item.warehouse_location_id;
				batch_sn_id = item.batch_sn_id;
				stored_qty_mode = item.stored_qty_mode;

			}else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {

				if ((item.invoiced_qty) && (item.invoiced_qty < 0)) {
					tp = 'IN';
				}

				warehouse_location_id = item.warehouse_location_id;
				batch_sn_id = item.batch_sn_id;
				stored_qty_mode = item.stored_qty_mode;
			}


			let callback = function(warehouse_location_id, batch_sn_id, batch_sn_code_ref, batch_sn_origin, batch_sn_cost, dossier_id) {

				item.warehouse_location_id = warehouse_location_id;

				if ((dossier_id != null) && (dossier_id != 'null')) {
					item.dossier_id = dossier_id;
				}else{
					item.dossier_id = '';
				}


				if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {

					if ((batch_sn_id != null) && (batch_sn_id != 'null')) {
						item.batch_sn_id = batch_sn_id;
					}else{
						item.batch_sn_id = '';
					}

				}else if ((me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')) {

					if ((batch_sn_id != null) && (batch_sn_id != 'null')) {
						item.batch_sn_id = batch_sn_id;
					}else{
						item.batch_sn_id = '';
					}

					if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {

						if ((!batch_sn_id) || ((batch_sn_id) && (batch_sn_id.trim() == ''))) {
							item.batch_sn_code_ref = '';
							item.batch_sn_origin = '';
						}else{
							if ((batch_sn_code_ref != null) && (batch_sn_code_ref != 'null')) {
								item.batch_sn_code_ref = batch_sn_code_ref;
							}else{
								item.batch_sn_code_ref = '';
							}

							if ((batch_sn_origin != null) &&(batch_sn_origin != 'null')) {
								item.batch_sn_origin = batch_sn_origin;
							}else{
								item.batch_sn_origin = '';
							}

							if ((batch_sn_cost != null) && (batch_sn_cost != 'null')) {
								item.item_cost = batch_sn_cost;
							}else{
								item.item_cost = 0;
							}
						}

						if (item.batch_sn_id != '') {

							let pathbatchlocation = 'silicon_ioi.ioi_wms.doctype.ioi_batch_sn.ioi_batch_sn';
							let method_bis = pathbatchlocation + '.batch_sn_get_voucher_info';
			
							frappe.call({  
								method: method_bis,
								args: {	
									"batch_sn_id": item.batch_sn_id, 
									"doc_currency_id": cur_frm.doc.currency_id, 
									"doc_rate": cur_frm.doc.currency_inv_rate
								},
								async: false,
								callback:function(r)	{	
									item.vat_rate = r.message.voucher_vat_rate;
									item.unit_price = r.message.voucher_value_htva;
									item.unit_price_tvac = r.message.voucher_value_tvac;
								}
							});						
						}
					}
				}

				me.frm.refresh_field('line_detail');
				me.compute_document();
				me.frm.dirty();
				me.format_detail_form(me.frm, ccdt, ccdn);
			}

			silicon_ioi.ioiCommon.select_location(tp, warehouse_id, warehouse_location_id, dossier_id, dossier_focus, item_id, batch_sn_id, stored_qty_mode, callback);

		}else
		{
			if (form) {
				this.format_detail_form(this.frm, document.getElementById('line_cdt').value, document.getElementById('line_cdn').value);
			}else{
				this.format_detail_form(this.frm, cur_frm.fields_dict['line_detail'].grid.doctype, cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name);
			}

		}

	}

	// ***************************************************************************************************************************************
	// Create a new delivery from order
	// **************************************************************************************************************************************
	static deliver_this_order(new_delivery, order_number = '', order_status = '', delivery_number = '', bypass = false, bypass_mode = 0, bypass_qty_rule = 3)
	{
		let n_delivery = new_delivery;
		let o_number = order_number;
		let o_status = order_status;
		let d_number = delivery_number;
		let bp = bypass;
		let bp_mode = bypass_mode;
		let bp_qty_rule = bypass_qty_rule;

		if (cur_frm.is_dirty())
		{
			let fct_callback = function () { silicon_ioi.doctype.ioiSalesDocumentDocType.do_deliver_this_order(n_delivery, o_number, o_status, d_number, bp, bp_mode, bp_qty_rule); };
			cur_frm.save('Save', fct_callback);
		}else
		{
			silicon_ioi.doctype.ioiSalesDocumentDocType.do_deliver_this_order(n_delivery, o_number, o_status, d_number, bp, bp_mode, bp_qty_rule);
		}

	}

	static do_deliver_this_order(new_delivery, order_number = '', order_status = '', delivery_number = '', bypass = false, bypass_mode = 0, bypass_qty_rule = 3)
	{
		let go = true;

		if (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER')
		{
			if ((go) && ((!cur_frm.doc.line_detail) || ((cur_frm.doc.line_detail) && (cur_frm.doc.line_detail.length == 0))))
			{
				frappe.msgprint({title: __("Message"), message: __('Order lines are mandatory to create the delivery'), indicator: "red"});
				go = false
			}

			if ((go) && ((cur_frm.doc.ioistatus != 1) && (cur_frm.doc.ioistatus != 2) && (cur_frm.doc.ioistatus != 3) && (cur_frm.doc.ioistatus != 4) && (cur_frm.doc.ioistatus != 5)))
			{
				frappe.msgprint({title: __("Message"), message: __('Document has to be "in standby", "confirmed", "back-order"", "delivered" or "BO/reserved"'), indicator: "red"});
				go = false;
			}
		}else
		{
			if (!new_delivery)
			{
				if ((go) && (cur_frm.doc.ioistatus != 0))
				{
					frappe.msgprint({title: __("Message"), message: __('Document has to be "in preparation"'), indicator: "red"});
					go = false;
				}
			}
		}

		if (go)
		{
			let me = this;

			let title = '';
			let bt_primary_caption = ''


			if (new_delivery)
			{
				if (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER')
				{	title = __('Deliver this order : ') + cur_frm.doc.name;
				}else
				{	title = __('Deliver this order : ') + order_number;
				}

				bt_primary_caption = __('Create delivery');
			}else
			{
				if (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER')
				{	title = __('Add line(s) to an existing delivery from this order : ') + cur_frm.doc.name;
				}else
				{	title = __('Add line(s) to an existing delivery from this order : ') + order_number;
				}

				bt_primary_caption = __('Add selected line(s) to the delivery');
			}

			var d = new frappe.ui.Dialog({
				'title': title,
				'fields': [
					{'fieldname': 'html_orders', 'fieldtype': 'HTML'}

				],
				primary_action_label: bt_primary_caption,
				secondary_action_label: __('Cancel'),
				primary_action: function(){
					silicon_ioi.doctype.ioiSalesDocumentDocType.create_delivery(d, new_delivery, order_number, bypass);
				},
				secondary_action: function(){
					d.hide();
				}

			});
			let pathsalesorder = 'silicon_ioi.ioi_sales.doctype.ioi_sales_order.ioi_sales_order';

			let order_id = '';

			if (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER')
			{	order_id = cur_frm.doc.name;
			}else
			{	order_id = order_number;
			}

			let html = '';

			if (document.getElementById("nb_record"))
			{

				for (var i = 0; i < document.getElementById('nb_record').value; i++)
				{
					if (document.getElementById('checked_id_' + i.toString()))
					{
						document.getElementById('checked_id_' + i.toString()).remove();
					}

					if (document.getElementById('name_id_' + i.toString()))
					{
						document.getElementById('name_id_' + i.toString()).remove();
					}

					if (document.getElementById('option_id_' + i.toString()))
					{
						document.getElementById('option_id_' + i.toString()).remove();
					}

					if (document.getElementById('item_id_' + i.toString()))
					{
						document.getElementById('item_id_' + i.toString()).remove();
					}

					if (document.getElementById('warehouse_id_' + i.toString()))
					{
						document.getElementById('warehouse_id_' + i.toString()).remove();
					}

					if (document.getElementById('ordered_qt_' + i.toString()))
					{
						document.getElementById('ordered_qt_' + i.toString()).remove();
					}

					if (document.getElementById('delivered_qt_' + i.toString()))
					{
						document.getElementById('delivered_qt_' + i.toString()).remove();
					}


					if (document.getElementById('reserved_qt_' + i.toString()))
					{
						document.getElementById('reserved_qt_' + i.toString()).remove();
					}


					if (document.getElementById('available_qty_' + i.toString()))
					{
						document.getElementById('available_qty_' + i.toString()).remove();
					}

					if (document.getElementById('available_scheduled_qty_' + i.toString()))
					{
						document.getElementById('available_scheduled_qty_' + i.toString()).remove();
					}

					if (document.getElementById('stock_unit_id_' + i.toString()))
					{
						document.getElementById('stock_unit_id_' + i.toString()).remove();
					}

					if (document.getElementById('stock_ordered_qt_' + i.toString()))
					{
						document.getElementById('stock_ordered_qt_' + i.toString()).remove();
					}

					if (document.getElementById('stock_delivered_qt_' + i.toString()))
					{
						document.getElementById('stock_delivered_qt_' + i.toString()).remove();
					}

					if (document.getElementById('stock_reserved_qt_' + i.toString()))
					{
						document.getElementById('stock_reserved_qt_' + i.toString()).remove();
					}

					if (document.getElementById('withme_' + i.toString()))
					{
						document.getElementById('withme_' + i.toString()).remove();
					}

				}

				document.getElementById('nb_record').remove();

			}

			if (document.getElementById('check_all_none'))
			{
				document.getElementById('check_all_none').remove();
			}


			if (document.getElementById('delivery_nb_record'))
			{
				for (var i = 0; i < document.getElementById('delivery_nb_record').value; i++)
				{
					if (document.getElementById('delivery_checked_id_' + i.toString()))
					{
						document.getElementById('delivery_checked_id_' + i.toString()).remove();
					}

					if (document.getElementById('delivery_name_id_' + i.toString()))
					{
						document.getElementById('delivery_name_id_' + i.toString()).remove();
					}

				}

				document.getElementById('delivery_nb_record').remove();
			}

			if (document.getElementById('check_warehouse_stock'))
			{
				document.getElementById('check_warehouse_stock').remove();
			}

			if (document.getElementById('check_site_stock'))
			{
				document.getElementById('check_site_stock').remove();
			}

			if (document.getElementById('check_no_stock'))
			{
				document.getElementById('check_no_stock').remove();
			}

			if (document.getElementById('check_available'))
			{
				document.getElementById('check_available').remove();
			}

			if (document.getElementById('check_scheduled_available'))
			{
				document.getElementById('check_scheduled_available').remove();
			}

			if (document.getElementById('qty_rule'))
			{
				document.getElementById('qty_rule').remove();
			}

			let current_order_status = ''

			if (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER')
			{	current_order_status = cur_frm.doc.ioistatus;
			}else
			{	current_order_status = order_status;
			}


			if ((current_order_status == '4') || (current_order_status == '5'))
			{
				html += '<div style="overflow: auto; overflow-x: auto; height:30px;" data-custom-grid="true">';

				html += '	<label style="position: relative; top: 6px; left: 6px;"><b>' + __("Information") + '</b></label>';
				html += '</div>'

				html += '<div style="overflow: auto; overflow-x: auto; height:40px; width: 100%;">';

				if (current_order_status == 4)
				{
					html += '	<label id="order_warning_id" style="position: relative; top: 6px; left: 6px;"><b>' + __("Over delivery warning") + '</b></label>';
				}else
				{
					html += '	<label id="order_warning_id" style="position: relative; top: 6px; left: 6px;"><b>' + __("Prefer to use reserved items before see reservation document") + '</b></label>';
				}

				html += '</div>';
			}


			if (!new_delivery)
			{
				if (document.getElementById("delivery_number"))
				{
					document.getElementById("delivery_number").remove();
				}

				if (document.getElementById("delivery_number_select"))
				{
					document.getElementById("delivery_number_select").remove();
				}

				html += '<div style="overflow: auto; overflow-x: auto; height:30px;" data-custom-grid="true">';

				html += '	<label style="position: relative; top: 6px; left: 6px;"><b>' + __("Select the delivery") + '</b></label>';
				html += '</div>'

				html += '<div style="overflow: auto; overflow-x: auto; height:40px; width: 100%;">';


				html += '	<div style="position: relative; top: 4px; left: 0px; width:250px;height:28px;">';
				html += '		<input type="text" id="delivery_number" class="input-with-feedback form-control bold" style="postion:absolute; top: 2px; left: 2px;height:27px" ';
				html += '			   value="' + delivery_number + '" readonly>';
				html += '	</div>';

				if (delivery_number.trim() == '')
				{
					html += '	<div style="position: relative; top: -22px; left: 260px; width:50px;">';
					html += '		<div style="position: absolute; top:0px; left: 2px; height: 25px;">';
					html +='			<button id="delivery_number_select" title="' + __("...") +'" data-label="..." class="btn btn-default ellipsis" style="height: 25px; width: 50px;" onclick="">' + __("...") + '</button>';
					html += '		</div>';
					html += '	</div>';
				}

				html += '</div>';

			}

			html += '<div style="overflow: auto; overflow-x: auto; height:30px;" data-custom-grid="true">';

			html += '	<label style="position: relative; top: 6px; left: 6px;"><b>' + __("Line(s) selection") + '</b></label>';
			html += '</div>'

			html += '<div style="overflow: auto; overflow-x: auto; height:40px; width: 100%;">';

			html += '	<div style="position: relative; top: 4px; left: 0px; width:70px;">';
			html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px;">';
			html +='			<button id="check_warehouse_stock" title="' + __("Warehouse stock") + '" data-label="' + __("Warehouse stock") + '" class="btn btn-default ellipsis" style="height: 30px; width: 250px;" onclick="">' + __("Select if whs stock") + '</button>';
			html += '		</div>';
			html += '	</div>';


			html += '	<div style="position: relative; top: 4px; left: 260px; width:50px;">';
			html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px;">';
			html +='			<button id="check_site_stock" title="' + __("Site stock") + '" data-label="' + __("Site stock") + '" class="btn btn-default ellipsis" style="height: 30px; width: 250px;" onclick="">' + __("Select if site stock") + '</button>';
			html += '		</div>';
			html += '	</div>';


			html += '	<div style="position: relative; top: 4px; left: 520px; width:50px;">';
			html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px;">';
			html +='			<button id="check_no_stock" title="' + __("No check") + '" data-label="' + __("No check") + '" class="btn btn-default ellipsis" style="height: 30px; width: 200px;" onclick="">' + __("Select all items") + '</button>';
			html += '		</div>';
			html += '	</div>';


			html += '	<div style="position: relative; top: 4px; left: 730px; width:50px;">';
			html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px;">';
			html +='			<button id="check_available" title="' + __("Check if available qty") + '" data-label="' + __("Check if available qty") + '" class="btn btn-default ellipsis" style="height: 30px; width: 200px;" onclick="">' + __("Select if available qty") + '</button>';
			html += '		</div>';
			html += '	</div>';

			html += '	<div style="position: relative; top: 4px; left: 940px; width:50px;">';
			html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px;">';
			html +='			<button id="check_scheduled_available" title="' + __("Check if sched. Avail. qty") + '" data-label="' + __("Check if sched. avail. qty") + '" class="btn btn-default ellipsis" style="height: 30px; width: 200px;" onclick="">' + __("Select if sched. avail. qty") + '</button>';
			html += '		</div>';
			html += '	</div>';


			html += '</div>';



			html += '<div style="overflow: auto; overflow-x: auto; height:600px; width: 100%;">';

			html += '<table border=1 style="border: 1px solid #E8EAEB" width=1980px data-custom-grid="true">';

			html += '<tr style="height:30px">';

			html += '<td width=30px align="center" style="vertical-align: middle;">';
			html += '<input type="checkbox" id="check_all_none" style="postion:absolute; top: 2px; left: 2px;" checked ';
			html += '       onclick=" ';
			html += '					for (var i = 0; i < document.getElementById(\'nb_record\').value; i++) ';
			html += '					{	';
			html += '						document.getElementById(\'checked_id_\' + i.toString()).checked = this.checked; ';
			html += '					} ';
			html += '                " ';
			html += '>';
			html += '</td>';
			html += '<td width=50px align="center" style="vertical-align: middle;"><b>' + __("No") + '</b></td>';
			html += '<td width=250px style="vertical-align: middle;">&nbsp;<b>' + __("Item") + '</b></td>';
			html += '<td width=350px style="vertical-align: middle;">&nbsp;<b>' + __("Description") + '</b></td>';
			html += '<td width=250px style="vertical-align: middle;">&nbsp;<b>' + __("Your reference") + '</b></td>';
			html += '<td width=250px style="vertical-align: middle;">&nbsp;<b>' + __("Manufacturer catalog") + '</b></td>';
			html += '<td width=50px align="center" style="vertical-align: middle;"><b>' + __("Opt.") + '</b></td>';
			html += '<td width=125px align="center" style="vertical-align: middle;"><b>' + __("Ordered qty") + '</b></td>';
			html += '<td width=125px align="center" style="vertical-align: middle;"><b>' + __("Delivered qty") + '</b></td>';
			html += '<td width=125px align="center" style="vertical-align: middle;"><b>' + __("Reserved qty") + '</b></td>';
			html += '<td width=125px align="center" style="vertical-align: middle;"><b>' + __("Available qty") + '</b></td>';
			html += '<td width=125px align="center" style="vertical-align: middle;"><b>' + __("Sched. avail. qty") + '</b></td>';
			html += '<td width=125px align="center" style="vertical-align: middle;"><b>' + __("Unit price") + '</b></td>';
			html += '</tr>';
			html += '</table>';

			let method = pathsalesorder + '.ioi_sales_order_get_detail';

			frappe.call({  	method: method,
							args: {"name": order_id},
							async: false,
							callback:function(r)	{
														if (r.message.length > 0)
														{	html += '<input type="hidden" id="nb_record" value="' +  r.message.length + '">';
															html += '<table border=1 style="border: 1px solid #E8EAEB" width=1980px>';

															for (var i = 0; i < r.message.length; i++)
															{
																html += '<tr style="height:30px">';

																html += '<td width=30px align="center" style="vertical-align: middle;">';
																html += '<input type="checkbox" id="checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;" checked>';
																html += '<input type="hidden" id="name_id_' + i.toString() + '" value="' +  r.message[i].name + '">';
																html += '<input type="hidden" id="option_id_' + i.toString() + '" value="' +  r.message[i].option_id + '">';

																if (r.message[i].item_id != null)
																{
																	html += '<input type="hidden" id="item_id_' + i.toString() + '" value="' +  r.message[i].item_id + '">';
																}else
																{
																	html += '<input type="hidden" id="item_id_' + i.toString() + '" value="">';
																}

																if (r.message[i].warehouse_id != null)
																{
																	html += '<input type="hidden" id="warehouse_id_' + i.toString() + '" value="' +  r.message[i].warehouse_id + '">';
																}else
																{
																	html += '<input type="hidden" id="warehouse_id_' + i.toString() + '" value="">';
																}


																html += '<input type="hidden" id="ordered_qt_' + i.toString() + '" value="">';
																html += '<input type="hidden" id="delivered_qt_' + i.toString() + '" value="">';
																html += '<input type="hidden" id="reserved_qt_' + i.toString() + '" value="">';

																if (r.message[i].available_qty == null)
																{
																	html += '<input type="hidden" id="available_qty_' + i.toString() + '" value="0">';
																}else
																{
																	html += '<input type="hidden" id="available_qty_' + i.toString() + '" value="' + r.message[i].available_qty + '">';
																}

																if (r.message[i].available_scheduled_qty == null)
																{
																	html += '<input type="hidden" id="available_scheduled_qty_' + i.toString() + '" value="0">';
																}else
																{
																	html += '<input type="hidden" id="available_scheduled_qty_' + i.toString() + '" value="' + r.message[i].available_scheduled_qty + '">';
																}

																html += '<input type="hidden" id="stock_unit_id_' + i.toString() + '" value="' + r.message[i].stock_unit_id + '">';
																html += '<input type="hidden" id="stock_ordered_qt_' + i.toString() + '" value="' + r.message[i].stock_ordered_qty + '">';
																html += '<input type="hidden" id="stock_delivered_qt_' + i.toString() + '" value="' + r.message[i].stock_delivered_qty + '">';
																html += '<input type="hidden" id="stock_reserved_qt_' + i.toString() + '" value="' + r.message[i].stock_reserved_qty + '">';

																if (r.message[i].withme == null)
																{
																	html += '<input type="hidden" id="withme_' + i.toString() + '" value="0">';
																}else
																{	html += '<input type="hidden" id="withme_' + i.toString() + '" value="' + r.message[i].withme + '">';
																}

																html += '</td>';

																html += '<td bgcolor="' + r.message[i].background_color + '" width=50px align="center" style="vertical-align: middle;"><font color="black">' + r.message[i].idx + '</font></td>';


																html += '<td width=250px style="vertical-align: middle;">&nbsp;'

																if (r.message[i].item_id != null)
																{	html += r.message[i].item_id;
																}
																html += '</td>';

																html += '<td width=350px style="vertical-align: middle;">&nbsp;'

																if (r.message[i].item_description != null)
																{	html += r.message[i].item_description.substr(0,50);
																}

																html += '</td>';

																html += '<td width=250px style="vertical-align: middle;">&nbsp;'

																if (r.message[i].item_id != null)
																{	html += r.message[i].external_ref;
																}
																html += '</td>';

																html += '<td width=250px style="vertical-align: middle;">&nbsp;'

																if (r.message[i].manufacturer_ref != null)
																{	html += r.message[i].manufacturer_ref;
																}
																html += '</td>';


																html += '<td width=50px align="center" style="vertical-align: middle;">'

																if (r.message[i].option_id != null)
																{
																	html += r.message[i].option_id;

																}

																html += '</td>';


																html += '<td width=125px align="right" style="vertical-align: middle;">'

																if ((r.message[i].ordered_qty != null) && (r.message[i].ordered_qty != 0))
																{
																	html += r.message[i].ordered_qty + '&nbsp;'

																	if (r.message[i].unit_id != null)
																	{	html += r.message[i].unit_id + '&nbsp';
																	}
																}

																html += '</td>';


																html += '<td width=125px align="right" style="vertical-align: middle;">'

																if ((r.message[i].delivered_qty != null) && (r.message[i].delivered_qty != 0))
																{
																	html += r.message[i].delivered_qty + '&nbsp;'

																	if (r.message[i].unit_id != null)
																	{	html += r.message[i].unit_id + '&nbsp';
																	}
																}

																html += '</td>';


																html += '<td width=125px align="right" style="vertical-align: middle;">'

																if ((r.message[i].reserved_qty != null) && (r.message[i].reserved_qty != 0))
																{
																	html += r.message[i].reserved_qty + '&nbsp;'

																	if (r.message[i].unit_id != null)
																	{	html += r.message[i].unit_id + '&nbsp';
																	}
																}

																html += '</td>';


																html += '<td width=125px align="right" style="vertical-align: middle;">'

																if ((r.message[i].available_qty != null) && (r.message[i].available_qty != 0))
																{
																	html += r.message[i].available_qty + '&nbsp;'

																	if (r.message[i].unit_id != null)
																	{	html += r.message[i].unit_id + '&nbsp';
																	}
																}

																html += '</td>';


																html += '<td width=125px align="right" style="vertical-align: middle;">'

																if ((r.message[i].available_scheduled_qty != null) && (r.message[i].available_scheduled_qty != 0))
																{
																	html += r.message[i].available_scheduled_qty + '&nbsp;'

																	if (r.message[i].unit_id != null)
																	{	html += r.message[i].unit_id + '&nbsp';
																	}
																}

																html += '</td>';


																html += '<td width=125px align="right" style="vertical-align: middle;">'

																if ((r.message[i].unit_price != null) && (r.message[i].unit_price != 0))
																{
																	html += r.message[i].unit_price.toFixed(2) + '&nbsp;'

																	if (r.message[i].currency_id != null)
																	{	html += r.message[i].currency_id + '&nbsp';
																	}
																}

																html += '</td>';

																html += '</tr>';
															}
															html += '</table>';
														}else
														{
															html += '<input type="hidden" id="nb_record" value="0">';
														}
													}
			});

			html += '<input type="hidden" id="hidden_mode" value="3">';

			html += '<input type="hidden" id="hidden_order_id" value="' + order_id +'">';

			html += '</div>';

			html += '<div style="overflow: auto; overflow-x: auto; height:10px; width: 100%;"></div>';

			html += '<div style="overflow: auto; overflow-x: auto; height:80px; width: 100%;">';

			html += '	<div style="position: relative; top: 0px; left: 0px; width:250px;">';
			html += '		<label id="qty_rule_label" style="position: absolute; top: 0px; left: 2px;">' + __("Qty rule") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 250px; height: 30px;"> ';
			html += '			<select id="qty_rule" class="input-with-feedback form-control bold"> ';
			html += '				<option value="0">' + __('Fill limited to whs stock') + '</option> ';
			html += '				<option value="1">' + __('Fill limited to site stock') + '</option> ';
			html += '				<option value="2">' + __('Fill limited to available') + '</option> ';
			html += '				<option value="3">' + __('Fill limited to scheduled available') + '</option> ';
			html += '				<option value="5" selected>' + __('Fill with line qty') + '</option> ';
			html += '			</select> ';
			html += '		</div>';
			html += '	</div>';

			html += '</div>';

			d.fields_dict.html_orders.$wrapper.html(html);

			d.show();


			silicon_ioi.doctype.ioiSalesDocumentDocType.sleep_static(200).then(() => {

				if (bypass)
				{
					document.getElementsByClassName("modal-content")[document.getElementsByClassName("modal-content").length-1].hidden = true;
				}

				document.getElementsByClassName("modal-content")[document.getElementsByClassName("modal-content").length-1].style.position = 'absolute';
				document.getElementsByClassName("modal-content")[document.getElementsByClassName("modal-content").length-1].style.width = '1275px';

				let w = ((1275 / 4)) * -1;

				document.getElementsByClassName("modal-content")[document.getElementsByClassName("modal-content").length-1].style.left = (w).toFixed(0) + 'px';


				if (bypass)
				{ 	d.hide();
				}


			});

			if (bypass)
			{ 	d.hide();
			}


			silicon_ioi.doctype.ioiSalesDocumentDocType.sleep_static(1000).then(() => {

											if (document.getElementById("delivery_number_select"))
											{
												document.getElementById("delivery_number_select").onclick = silicon_ioi.doctype.ioiSalesDocumentDocType.order_select_a_delivery;
											}

											if (document.getElementById("check_warehouse_stock"))
											{
												document.getElementById("check_warehouse_stock").onclick = silicon_ioi.doctype.ioiSalesDocumentDocType.order_check_warehouse_stock;
											}

											if (document.getElementById("check_site_stock"))
											{
												document.getElementById("check_site_stock").onclick = silicon_ioi.doctype.ioiSalesDocumentDocType.order_check_site_stock;
											}

											if (document.getElementById("check_no_stock"))
											{
												document.getElementById("check_no_stock").onclick = silicon_ioi.doctype.ioiSalesDocumentDocType.order_no_check_stock;
											}

											if (document.getElementById("check_available"))
											{
												document.getElementById("check_available").onclick = silicon_ioi.doctype.ioiSalesDocumentDocType.order_check_available;
											}

											if (document.getElementById("check_scheduled_available"))
											{
												document.getElementById("check_scheduled_available").onclick = silicon_ioi.doctype.ioiSalesDocumentDocType.order_check_scheduled_available;
											}


											if (bypass)
											{	d.hide();

												if (bypass_qty_rule == 0)
												{	document.getElementById("check_warehouse_stock").click();
												}else if (bypass_qty_rule == 1)
												{	document.getElementById("check_site_stock").click();
												}else if (bypass_qty_rule == 5)
												{	document.getElementById("check_no_stock").click();
												}else if (bypass_qty_rule == 2)
												{	document.getElementById("check_available").click();
												}else if (bypass_qty_rule == 3)
												{	document.getElementById("check_scheduled_available").click();
												}

												silicon_ioi.doctype.ioiSalesDocumentDocType.create_delivery(d, new_delivery, order_number, bypass, bypass_qty_rule);
											}


		 	});

		}
	}


	static order_check_warehouse_stock()
	{
		for (var i = 0; i < document.getElementById('nb_record').value; i++)
		{
			document.getElementById('checked_id_' + i.toString()).checked = false;
		}

		let pathsalesorder = 'silicon_ioi.ioi_sales.doctype.ioi_sales_order.ioi_sales_order';
		let method = pathsalesorder + '.ioi_sales_order_check_warehouse_stock_for_order';

		frappe.call({  	method: method,
						args: {"name": document.getElementById('hidden_order_id').value},
						async: false,
						callback:function(r)	{
													if (r.message.length > 0)
													{
														for (var i = 0; i < document.getElementById('nb_record').value; i++)
														{
															if (r.message.indexOf(document.getElementById('name_id_' + i.toString()).value) != -1)
															{
																document.getElementById('checked_id_' + i.toString()).checked = true;
															}
														}
													}
						}
		});

		document.getElementById('hidden_mode').value = 0;
		document.getElementById('qty_rule').value = 0;

	}

	static order_check_site_stock()
	{
		for (var i = 0; i < document.getElementById('nb_record').value; i++)
		{
			document.getElementById('checked_id_' + i.toString()).checked = false;
		}

		let pathsalesorder = 'silicon_ioi.ioi_sales.doctype.ioi_sales_order.ioi_sales_order';
		let method = pathsalesorder + '.ioi_sales_order_check_site_stock_for_order';

		frappe.call({  	method: method,
						args: {"name": document.getElementById('hidden_order_id').value},
						async: false,
						callback:function(r)	{
													if (r.message.length > 0)
													{
														for (var i = 0; i < document.getElementById('nb_record').value; i++)
														{
															if (r.message.indexOf(document.getElementById('name_id_' + i.toString()).value) != -1)
															{
																document.getElementById('checked_id_' + i.toString()).checked = true;
															}
														}
													}
						}
		});

		document.getElementById('hidden_mode').value = 1;
		document.getElementById('qty_rule').value = 1;

	}

	static order_no_check_stock()
	{
		for (var i = 0; i < document.getElementById('nb_record').value; i++)
		{
			document.getElementById('checked_id_' + i.toString()).checked = true;
		}

		document.getElementById('check_all_none').checked = true;

		document.getElementById('hidden_mode').value = 5;
		document.getElementById('qty_rule').value = 5;

	}

	static order_check_available()
	{

		let method = 'silicon_ioi.ioi_system.doctype.ioi_time_vector.ioi_time_vector.ioi_time_vector_compute_available_for_open_sales_orders';

		frappe.call({  	method: method,
						args: {	"order_id": document.getElementById('hidden_order_id').value },
						async: false,
						callback:function(r) { }
		});

		let cpt = 0;
		for (var i = 0; i < document.getElementById('nb_record').value; i++)
		{
			document.getElementById('checked_id_' + i.toString()).checked = false;

			if (document.getElementById('available_qty_' + i.toString()).value > 0)
			{
				document.getElementById('checked_id_' + i.toString()).checked = true;
				cpt++;
			}else
			{
				if (document.getElementById('withme_' + i.toString()).value != 0)
				{
					document.getElementById('checked_id_' + i.toString()).checked = true;
					cpt++;
				}
			}
		}

		document.getElementById('check_all_none').checked = false;

		if (cpt == document.getElementById('nb_record').value)
		{	document.getElementById('check_all_none').checked = true;
		}

		document.getElementById('hidden_mode').value = 2;
		document.getElementById('qty_rule').value = 2;
	}

	static order_check_scheduled_available()
	{

		let method = 'silicon_ioi.ioi_system.doctype.ioi_time_vector.ioi_time_vector.ioi_time_vector_compute_available_for_open_sales_orders';

		frappe.call({  	method: method,
						args: {	"order_id": document.getElementById('hidden_order_id').value },
						async: false,
						callback:function(r) { }
		});

		let cpt = 0;
		for (var i = 0; i < document.getElementById('nb_record').value; i++)
		{
			document.getElementById('checked_id_' + i.toString()).checked = false;

			if (document.getElementById('available_scheduled_qty_' + i.toString()).value > 0)
			{
				document.getElementById('checked_id_' + i.toString()).checked = true;
				cpt++;
			}else
			{
				if (document.getElementById('withme_' + i.toString()).value != 0)
				{
					document.getElementById('checked_id_' + i.toString()).checked = true;
					cpt++;
				}
			}
		}

		document.getElementById('check_all_none').checked = false;

		if (cpt == document.getElementById('nb_record').value)
		{	document.getElementById('check_all_none').checked = true;
		}

		document.getElementById('hidden_mode').value = 3;
		document.getElementById('qty_rule').value = 3;
	}

	// ***************************************************************************************************************************************
	// Create Delivery
	// **************************************************************************************************************************************
	static create_delivery(d, new_delivery, order_number, bypass = false, bypass_qty_rule = 3)
	{
		let go = true;

		if (!new_delivery)
		{
			if (document.getElementById("delivery_number").value.trim() == '')
			{
				frappe.msgprint({title: __("Message"), message: __('Delivery number is mandatory'), indicator: "red"});
				go = false;
			}
		}

		if ((go) && (document.getElementById('nb_record').value == 0))
		{
			if (new_delivery)
			{	frappe.msgprint({title: __("Message"), message: __('No order line to create a delivery'), indicator: "red"});
			}else
			{	frappe.msgprint({title: __("Message"), message: __('No order line to add to the delivery'), indicator: "red"});
			}
			go = false;
		}

		if (go)
		{
			let one_selected = false
			let order_selected_lines = []

			for (var i = 0; i < document.getElementById('nb_record').value; i++)
			{
				if (document.getElementById('checked_id_' + i.toString()).checked)
				{
					one_selected = true;
					order_selected_lines.push(document.getElementById('name_id_' + i.toString()).value);
				}
			}

			if (!one_selected)
			{
				if (!bypass)
				{	frappe.msgprint({title: __("Message"), message: __('select at least one order line'), indicator: "red"});
					go = false;
				}else
				{
					go = true;
				}

			}

			if (go)
			{
				let pathsalesorder = 'silicon_ioi.ioi_sales.doctype.ioi_sales_order.ioi_sales_order';
				let method = pathsalesorder + '.ioi_sales_order_create_delivery';

				let delivery_number = ''

				if (!new_delivery)
				{
					if (document.getElementById('delivery_number'))
					{
						delivery_number = document.getElementById('delivery_number').value;
					}
				}

				let order_id = '';

				if (cur_frm.doc.doctype.toUpperCase() == 'IOI SALES ORDER')
				{	order_id = cur_frm.doc.name;
				}else
				{	order_id = order_number;
				}

				let creation_mode = 'MANUAL';

				let qty_rule = document.getElementById('qty_rule').value;

				if (bypass) {
					creation_mode = 'AUTO';
					qty_rule = document.getElementById('hidden_mode').value;
				}



				frappe.call({  	method: method,
								args: {"name": order_id, "selected_lines":[order_selected_lines], "new_delivery" : new_delivery, "delivery_id": delivery_number, "mode" : creation_mode, "qty_rule": qty_rule},
								async: false,
								callback:function(r)	{
															if (r.message.error != 0)
															{
																frappe.msgprint({title: __("Message"), message: r.message.error_message, indicator: "red"});
															}else
															{
																d.hide();

																if (new_delivery)
																{
																	window.open('/app/ioi-sales-delivery/' + r.message.delivery_number);
																}else
																{
																	if (cur_frm.doc.doctype.toUpperCase() == 'IOI SALES ORDER')
																	{
																		window.open('/app/ioi-sales-delivery/' + r.message.delivery_number);
																	}else
																	{
																		if (order_number.trim() != '')
																		{
																			window.location.reload();
																		}
																	}
																}
															}
														}
				});
			}
		}
	}

	// ***************************************************************************************************************************************
	// Order : select a delivery
	// **************************************************************************************************************************************
	static order_select_a_delivery()
	{
		var z = new frappe.ui.Dialog({
			'title': __("Select a delivery"),
			'fields': [
				{'fieldname': 'html_select_delivery', 'fieldtype': 'HTML'}

			],
			primary_action_label: 'Ok',
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

				let go = true;

				if (document.getElementById('delivery_nb_record').value == 0)
				{	document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
					frappe.msgprint({title: __("Message"), message: __("No delivery selected"), indicator: "red"});
				}

				if ((go) && (document.getElementById('delivery_nb_record').value != 0))
				{	go = false
					for (var i = 0; i < document.getElementById('delivery_nb_record').value; i++)
					{
						if (document.getElementById('delivery_checked_id_' + i.toString()).checked)
						{
							document.getElementById('delivery_number').value = document.getElementById('delivery_name_id_' + i.toString()).value;
							go = true;
							break;
						}
					}

					if (!go)
					{
						frappe.msgprint({title: __("Message"), message: __("Select a delivery, please"), indicator: "red"});
					}else{
						document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
					}

				}

				if (go)
				{
					z.hide();
				}else{
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
				}
			},
			secondary_action: function(){
				z.hide();
			}

		});

		if (document.getElementById('delivery_nb_record'))
		{
			for (var i = 0; i < document.getElementById('delivery_nb_record').value; i++)
			{
				if (document.getElementById('delivery_checked_id_' + i.toString()))
				{
					document.getElementById('delivery_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('delivery_name_id_' + i.toString()))
				{
					document.getElementById('delivery_name_id_' + i.toString()).remove();
				}

			}

			document.getElementById('delivery_nb_record').remove();
		}

		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:600px; width: 100%;">';

		html += '<table border=1 style="border: 1px solid #E8EAEB" width=1055px data-custom-grid="true">';

		html += '<tr style="height:30px">';

		html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=200px style="vertical-align: middle;">&nbsp;<b>' + __("Delivery number") + '</b></td>';
		html += '<td width=100px align="center" style="vertical-align: middle;"><b>' + __("Doc. date") + '</b></td>';
		html += '<td width=250px style="vertical-align: middle;">&nbsp;<b>' + __("Delivery customer") + '</b></td>';
		html += '<td width=350px style="vertical-align: middle;">&nbsp;<b>' + __("Customer name") + '</b></td>';
		html += '<td width=125px align="center" style="vertical-align: middle;"><b>' + __("Total w/o VAT") + '</b></td>';
		html += '</tr>';
		html += '</table>';

		let pathsalesdelivery = 'silicon_ioi.ioi_sales.doctype.ioi_sales_delivery.ioi_sales_delivery';

		let method = pathsalesdelivery + '.ioi_sales_delivery_get_compatible_deliveries_with_order';


		frappe.call({  	method: method,
						args: {"order_id": cur_frm.doc.name},
						async: false,
						callback:function(r)	{
													if (r.message.length > 0)
													{	html += '<input type="hidden" id="delivery_nb_record" value="' + r.message.length + '">';
														html += '<table border=1 style="border: 1px solid #E8EAEB" width=1055px>';

														for (var i = 0; i < r.message.length; i++)
														{
															html += '<tr style="height:30px">';

															html += '<td width=30px align="center" style="vertical-align: middle;">';
															html += '<input type="checkbox" id="delivery_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
															html += '       onclick=" ';
															html += '					for (var i = 0; i < document.getElementById(\'delivery_nb_record\').value; i++) ';
															html += '					{';
															html += '						if (document.getElementById(\'delivery_checked_id_\' + i.toString()).id != this.id) ';
															html += '						{ ';
															html += '							document.getElementById(\'delivery_checked_id_\' + i.toString()).checked = false; ';
															html += '						} ';
															html += '					} ';
															html += '">';

															html += '<input type="hidden" id="delivery_name_id_' + i.toString() + '" value="' +  r.message[i].name + '">';
															html += '</td>';

															html += '<td width=200px style="vertical-align: middle;">&nbsp;' + r.message[i].name + '</td>'

															html += '<td width=100px style="vertical-align: middle;">&nbsp;' + r.message[i].document_date + '</td>'

															html += '<td width=250px style="vertical-align: middle;">&nbsp;' + r.message[i].delivery_customer_id + '</td>'

															html += '<td width=350px style="vertical-align: middle;">&nbsp;'

															if (r.message[i].delivery_customer_name != null)
															{	html += r.message[i].delivery_customer_name.substr(0,50);
															}

															html += '</td>';


															html += '<td width=125px align="right" style="vertical-align: middle;">'

															if ((r.message[i].total_htva != null))
															{
																html += r.message[i].total_htva.toFixed(2) + '&nbsp;'

																if (r.message[i].currency_id != null)
																{	html += r.message[i].currency_id + '&nbsp';
																}
															}

															html += '</td>';

															html += '</tr>';
														}
														html += '</table>';
													}else
													{
														html += '<input type="hidden" id="delivery_nb_record" value="0">';
													}
												}
		});

		html += '</div>';


		z.fields_dict.html_select_delivery.$wrapper.html(html);


		z.show();
	}

	// ***************************************************************************************************************************************
	// Create Delivery from Order
	// **************************************************************************************************************************************
	create_delivery_from_order(new_delivery)
	{
		var z = new frappe.ui.Dialog({
			'title': __("Select an order"),
			'fields': [
				{'fieldname': 'html_select_order', 'fieldtype': 'HTML'}

			],
			primary_action_label: 'Ok',
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				let go = true;

				if (document.getElementById('order_nb_record').value == 0)
				{
					frappe.msgprint({title: __("Message"), message: __("No order selected"), indicator: "red"});
				}

				if ((go) && (document.getElementById('order_nb_record').value != 0))
				{	go = false
					for (var i = 0; i < document.getElementById('order_nb_record').value; i++)
					{
						if (document.getElementById('order_checked_id_' + i.toString()).checked)
						{
							document.getElementById('order_number').value = document.getElementById('order_name_id_' + i.toString()).value;
							document.getElementById('order_ioistatus').value = document.getElementById('order_ioistatus_id_' + i.toString()).value;

							go = true;
							break;
						}
					}

					if (!go)
					{
						frappe.msgprint({title: __("Message"), message: __("Select an order, please"), indicator: "red"});
					}

				}

				if (go)
				{
					z.hide();
					if (new_delivery)
					{
						silicon_ioi.doctype.ioiSalesDocumentDocType.deliver_this_order(true, document.getElementById('order_number').value, document.getElementById('order_ioistatus').value, '');
					}else
					{
						silicon_ioi.doctype.ioiSalesDocumentDocType.deliver_this_order(false, document.getElementById('order_number').value, document.getElementById('order_ioistatus').value, cur_frm.doc.name);
					}
				}
			},
			secondary_action: function(){
				z.hide();
			}

		});

		if (document.getElementById('order_nb_record'))
		{
			for (var i = 0; i < document.getElementById('order_nb_record').value; i++)
			{
				if (document.getElementById('order_checked_id_' + i.toString()))
				{
					document.getElementById('order_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('order_name_id_' + i.toString()))
				{
					document.getElementById('order_name_id_' + i.toString()).remove();
				}

				if (document.getElementById('order_ioistatus_id_' + i.toString()))
				{
					document.getElementById('order_ioistatus_id_' + i.toString()).remove();
				}


			}

			document.getElementById('order_nb_record').remove();
		}

		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:600px; width: 100%;">';

		html += '<table border=1 style="border: 1px solid #E8EAEB" width=1055px data-custom-grid="true">';

		html += '<tr style="height:30px">';

		html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=200px style="vertical-align: middle;">&nbsp;<b>' + __("Order number") + '</b></td>';
		html += '<td width=100px align="center" style="vertical-align: middle;"><b>' + __("Doc. date") + '</b></td>';
		html += '<td width=250px style="vertical-align: middle;">&nbsp;<b>' + __("Order customer") + '</b></td>';
		html += '<td width=350px style="vertical-align: middle;">&nbsp;<b>' + __("Customer name") + '</b></td>';
		html += '<td width=125px align="center" style="vertical-align: middle;"><b>' + __("Total w/o VAT") + '</b></td>';
		html += '</tr>';
		html += '</table>';


		let method = this.path_sales_order + '.ioi_sales_order_get_orders_for_delivery';


		frappe.call({  	method: method,
						args: {"new_delivery" : new_delivery, "delivery_id": this.frm.doc.name },
						async: false,
						callback:function(r)	{
													if (r.message.length > 0)
													{	html += '<input type="hidden" id="order_nb_record" value="' + r.message.length + '">';
														html += '<table border=1 style="border: 1px solid #E8EAEB" width=1055px>';

														for (var i = 0; i < r.message.length; i++)
														{
															html += '<tr style="height:30px">';

															html += '<td width=30px align="center" style="vertical-align: middle;">';
															html += '<input type="checkbox" id="order_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
															html += '       onclick=" ';
															html += '					for (var i = 0; i < document.getElementById(\'order_nb_record\').value; i++) ';
															html += '					{';
															html += '						if (document.getElementById(\'order_checked_id_\' + i.toString()).id != this.id) ';
															html += '						{ ';
															html += '							document.getElementById(\'order_checked_id_\' + i.toString()).checked = false; ';
															html += '						} ';
															html += '					} ';
																												html += '">';
															html += '<input type="hidden" id="order_name_id_' + i.toString() + '" value="' +  r.message[i].name + '">';
															html += '<input type="hidden" id="order_ioistatus_id_' + i.toString() + '" value="' +  r.message[i].ioistatus + '">';
															html += '</td>';

															html += '<td width=200px style="vertical-align: middle;">&nbsp;' + r.message[i].name + '</td>'

															html += '<td width=100px style="vertical-align: middle;">&nbsp;' + r.message[i].document_date + '</td>'

															html += '<td width=250px style="vertical-align: middle;">&nbsp;' + r.message[i].order_customer_id + '</td>'

															html += '<td width=350px style="vertical-align: middle;">&nbsp;'

															if (r.message[i].order_customer_name != null)
															{	html += r.message[i].order_customer_name.substr(0,50);
															}

															html += '</td>';


															html += '<td width=125px align="right" style="vertical-align: middle;">'

															if ((r.message[i].total_htva != null))
															{
																html += r.message[i].total_htva.toFixed(2) + '&nbsp;'

																if (r.message[i].currency_id != null)
																{	html += r.message[i].currency_id + '&nbsp';
																}
															}

															html += '</td>';

															html += '</tr>';
														}
														html += '</table>';
													}else
													{
														html += '<input type="hidden" id="order_nb_record" value="0">';
													}
												}
		});

		html += '<input type="hidden" id="order_number" value="">';
		html += '<input type="hidden" id="order_ioistatus" value="">';
		html += '</div>';


		z.fields_dict.html_select_order.$wrapper.html(html);


		z.show();
	}


	// ***************************************************************************************************************************************
	// Create a new invoice / CN from delivery
	// **************************************************************************************************************************************
	static invoice_this_delivery(new_invoice, delivery_number = '', invoice_status = '', invoice_number = '', bypass = false, bypass_mode = 0)
	{
		let n_invoice = new_invoice;
		let d_number = delivery_number;
		let i_status = invoice_status;
		let i_number = invoice_number;
		let bp = bypass ;
		let bp_mode = bypass_mode;

		if (cur_frm.is_dirty())
		{
			let fct_callback = function () { silicon_ioi.doctype.ioiSalesDocumentDocType.do_invoice_this_delivery(n_invoice, d_number, i_status, i_number, bp, bp_mode); };
			cur_frm.save('Save', fct_callback);
		}else
		{
			silicon_ioi.doctype.ioiSalesDocumentDocType.do_invoice_this_delivery(n_invoice, d_number, i_status, i_number, bp, bp_mode);
		}
	}

	static do_invoice_this_delivery(new_invoice, delivery_number = '', invoice_status = '', invoice_number = '', bypass = false, bypass_mode = 0)
	{
		let go = true;

		if (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
		{
			if ((go) && ((!cur_frm.doc.line_detail) || ((cur_frm.doc.line_detail) && (cur_frm.doc.line_detail.length == 0))))
			{
				frappe.msgprint({title: __("Message"), message: __('Delivery lines are mandatory to create the invoice / CN'), indicator: "red"});
				go = false
			}

			if ((go) && ((cur_frm.doc.ioistatus != 1) && (cur_frm.doc.ioistatus != 4)))
			{
				frappe.msgprint({title: __("Message"), message: __('Document has to be "delivered" or "Inv. part."'), indicator: "red"});
				go = false;
			}
		}else
		{
			if (!new_invoice)
			{
				if ((go) && (cur_frm.doc.ioistatus != 0))
				{
					frappe.msgprint({title: __("Message"), message: __('Document has to be "in preparation"'), indicator: "red"});
					go = false;
				}
			}
		}

		if (go)
		{
			let me = this;

			let title = '';
			let bt_primary_caption = ''


			if (new_invoice)
			{
				if (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
				{	title = __('Invoice or credit this delivery : ') + cur_frm.doc.name;
				}else
				{	title = __('Invoice or credit this delivery : ') + delivery_number;
				}

				bt_primary_caption = __('Create invoice / CN');
			}else
			{
				if (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
				{	title = __('Add line(s) to an existing invoice / CN from this delivery : ') + cur_frm.doc.name;
				}else
				{	title = __('Add line(s) to an existing invoice / CN from this delivery : ') + delivery_number;
				}

				bt_primary_caption = __('Add selected line(s) to the invoice / CN');
			}

			var d = new frappe.ui.Dialog({
				'title': title,
				'fields': [
					{'fieldname': 'html_deliveries', 'fieldtype': 'HTML'}

				],
				primary_action_label: bt_primary_caption,
				secondary_action_label: __('Cancel'),
				primary_action: function(){
					silicon_ioi.doctype.ioiSalesDocumentDocType.create_invoice(d, new_invoice, delivery_number);
				},
				secondary_action: function(){
					d.hide();
				}

			});
			let pathsalesdelivery = 'silicon_ioi.ioi_sales.doctype.ioi_sales_delivery.ioi_sales_delivery';

			let delivery_id = '';

			if (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
			{	delivery_id = cur_frm.doc.name;
			}else
			{	delivery_id = delivery_number;
			}

			let html = '';

			if (document.getElementById("nb_record"))
			{

				for (var i = 0; i < document.getElementById('nb_record').value; i++)
				{
					if (document.getElementById('checked_id_' + i.toString()))
					{
						document.getElementById('checked_id_' + i.toString()).remove();
					}

					if (document.getElementById('name_id_' + i.toString()))
					{
						document.getElementById('name_id_' + i.toString()).remove();
					}

					if (document.getElementById('item_id_' + i.toString()))
					{
						document.getElementById('item_id_' + i.toString()).remove();
					}

					if (document.getElementById('delivered_qt_' + i.toString()))
					{
						document.getElementById('delivered_qt_' + i.toString()).remove();
					}

					if (document.getElementById('invoiced_qt_' + i.toString()))
					{
						document.getElementById('invoiced_qt_' + i.toString()).remove();
					}
				}

				document.getElementById('nb_record').remove();

			}

			if (document.getElementById('check_all_none'))
			{
				document.getElementById('check_all_none').remove();
			}


			if (document.getElementById('invoice_nb_record'))
			{
				for (var i = 0; i < document.getElementById('invoice_nb_record').value; i++)
				{
					if (document.getElementById('invoice_checked_id_' + i.toString()))
					{
						document.getElementById('invoice_checked_id_' + i.toString()).remove();
					}

					if (document.getElementById('invoice_name_id_' + i.toString()))
					{
						document.getElementById('invoice_name_id_' + i.toString()).remove();
					}

				}

				document.getElementById('invoice_nb_record').remove();
			}


			let current_delivery_status = ''

			if (!new_invoice)
			{
				if (document.getElementById("invoice_number"))
				{
					document.getElementById("invoice_number").remove();
				}

				if (document.getElementById("invoice_number_select"))
				{
					document.getElementById("invoice_number_select").remove();
				}

				html += '<div style="overflow: auto; overflow-x: auto; height:30px;" data-custom-grid="true">';

				html += '	<label style="position: relative; top: 6px; left: 6px;"><b>' + __("Select the invoice / CN") + '</b></label>';
				html += '</div>'

				html += '<div style="overflow: auto; overflow-x: auto; height:40px; width: 100%;">';


				html += '	<div style="position: relative; top: 4px; left: 0px; width:250px;height:28px;">';
				html += '		<input type="text" id="invoice_number" class="input-with-feedback form-control bold" style="postion:absolute; top: 2px; left: 2px;height:27px" ';
				html += '			   value="' + invoice_number + '" readonly>';
				html += '	</div>';

				if (invoice_number.trim() == '')
				{
					html += '	<div style="position: relative; top: -22px; left: 260px; width:50px;">';
					html += '		<div style="position: absolute; top:0px; left: 2px; height: 25px;">';
					html +='			<button id="invoice_number_select" title="' + __("...") +'" data-label="..." class="btn btn-default ellipsis" style="height: 25px; width: 50px;" onclick="">' + __("...") + '</button>';
					html += '		</div>';
					html += '	</div>';
				}

				html += '</div>';

			}


			html += '<div style="overflow: auto; overflow-x: auto; height:600px; width: 100%;">';

			html += '<table border=1 style="border: 1px solid #E8EAEB" width=1555px data-custom-grid="true">';

			html += '<tr style="height:30px">';

			html += '<td width=30px align="center" style="vertical-align: middle;">';
			html += '<input type="checkbox" id="check_all_none" style="postion:absolute; top: 2px; left: 2px;" checked ';
			html += '       onclick=" ';
			html += '					for (var i = 0; i < document.getElementById(\'nb_record\').value; i++) ';
			html += '					{	';
			html += '						document.getElementById(\'checked_id_\' + i.toString()).checked = this.checked; ';
			html += '					} ';
			html += '                " ';
			html += '>';
			html += '</td>';
			html += '<td width=50px align="center" style="vertical-align: middle;"><b>' + __("No") + '</b></td>';
			html += '<td width=250px style="vertical-align: middle;">&nbsp;<b>' + __("Item") + '</b></td>';
			html += '<td width=350px style="vertical-align: middle;">&nbsp;<b>' + __("Description") + '</b></td>';
			html += '<td width=250px style="vertical-align: middle;">&nbsp;<b>' + __("Your reference") + '</b></td>';
			html += '<td width=250px style="vertical-align: middle;">&nbsp;<b>' + __("Manufacturer catalog") + '</b></td>';
			html += '<td width=125px align="center" style="vertical-align: middle;"><b>' + __("Delivered qty") + '</b></td>';
			html += '<td width=125px align="center" style="vertical-align: middle;"><b>' + __("Invoiced qty") + '</b></td>';
			html += '<td width=125px align="center" style="vertical-align: middle;"><b>' + __("Unit price") + '</b></td>';
			html += '</tr>';
			html += '</table>';

			let method = pathsalesdelivery + '.ioi_sales_delivery_get_detail';

			frappe.call({  	method: method,
							args: {"name": delivery_id},
							async: false,
							callback:function(r)	{
														if (r.message.length > 0)
														{	html += '<input type="hidden" id="nb_record" value="' +  r.message.length + '">';
															html += '<table border=1 style="border: 1px solid #E8EAEB" width=1555px>';

															for (var i = 0; i < r.message.length; i++)
															{
																html += '<tr style="height:30px">';

																html += '<td width=30px align="center" style="vertical-align: middle;">';
																html += '<input type="checkbox" id="checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;" checked>';
																html += '<input type="hidden" id="name_id_' + i.toString() + '" value="' +  r.message[i].name + '">';

																if (r.message[i].item_id != null)
																{
																	html += '<input type="hidden" id="item_id_' + i.toString() + '" value="' +  r.message[i].item_id + '">';
																}else
																{
																	html += '<input type="hidden" id="item_id_' + i.toString() + '" value="">';
																}

																html += '<input type="hidden" id="delivered_qt_' + i.toString() + '" value="">';
																html += '<input type="hidden" id="invoiced_qt_' + i.toString() + '" value="">';

																html += '</td>';

																html += '<td bgcolor="' + r.message[i].background_color + '" width=50px align="center" style="vertical-align: middle;"><font color="black">' + r.message[i].idx + '</font></td>';


																html += '<td width=250px style="vertical-align: middle;">&nbsp;'

																if (r.message[i].item_id != null)
																{	html += r.message[i].item_id;
																}
																html += '</td>';

																html += '<td width=350px style="vertical-align: middle;">&nbsp;'

																if (r.message[i].item_description != null)
																{	html += r.message[i].item_description.substr(0,50);
																}

																html += '</td>';


																html += '<td width=250px style="vertical-align: middle;">&nbsp;'

																if (r.message[i].item_id != null)
																{	html += r.message[i].external_ref;
																}
																html += '</td>';

																html += '<td width=250px style="vertical-align: middle;">&nbsp;'

																if (r.message[i].manufacturer_ref != null)
																{	html += r.message[i].manufacturer_ref;
																}
																html += '</td>';


																html += '<td width=125px align="right" style="vertical-align: middle;">'

																if ((r.message[i].delivered_qty != null) && (r.message[i].delivered_qty != 0))
																{
																	html += r.message[i].delivered_qty + '&nbsp;'

																	if (r.message[i].unit_id != null)
																	{	html += r.message[i].unit_id + '&nbsp';
																	}
																}

																html += '</td>';


																html += '<td width=125px align="right" style="vertical-align: middle;">'

																if ((r.message[i].invoiced_qty != null) && (r.message[i].invoiced_qty != 0))
																{
																	html += r.message[i].invoiced_qty + '&nbsp;'

																	if (r.message[i].unit_id != null)
																	{	html += r.message[i].unit_id + '&nbsp';
																	}
																}

																html += '</td>';


																html += '<td width=125px align="right" style="vertical-align: middle;">'

																if ((r.message[i].unit_price != null) && (r.message[i].unit_price != 0))
																{
																	html += r.message[i].unit_price.toFixed(2) + '&nbsp;'

																	if (r.message[i].currency_id != null)
																	{	html += r.message[i].currency_id + '&nbsp';
																	}
																}

																html += '</td>';

																html += '</tr>';
															}
															html += '</table>';
														}else
														{
															html += '<input type="hidden" id="nb_record" value="0">';
														}
													}
			});

			html += '<input type="hidden" id="hidden_delivery_id" value="' + delivery_id +'">';

			html += '</div>';

			d.fields_dict.html_deliveries.$wrapper.html(html);

			d.show();

			silicon_ioi.doctype.ioiSalesDocumentDocType.sleep_static(250).then(() => {

				if (bypass)
				{
					document.getElementsByClassName("modal-content")[document.getElementsByClassName("modal-content").length-1].hidden = true;
				}

				document.getElementsByClassName("modal-content")[document.getElementsByClassName("modal-content").length-1].style.position = 'absolute';
				document.getElementsByClassName("modal-content")[document.getElementsByClassName("modal-content").length-1].style.width = '1275px';

				let w = ((1305 / 4)) * -1;

				document.getElementsByClassName("modal-content")[document.getElementsByClassName("modal-content").length-1].style.left = (w).toFixed(0) + 'px';

				if (bypass)
				{ 	d.hide();
				}

			});


			if (bypass)
			{ 	d.hide();
			}



			silicon_ioi.doctype.ioiSalesDocumentDocType.sleep_static(1000).then(() => {

											if (document.getElementById("invoice_number_select"))
											{
												document.getElementById("invoice_number_select").onclick = silicon_ioi.doctype.ioiSalesDocumentDocType.delivery_select_an_invoice_cn;
											}

											if (bypass)
											{	d.hide();

												silicon_ioi.doctype.ioiSalesDocumentDocType.create_invoice(d, new_invoice, delivery_number, bypass);
											}

		 	});
		}
	}


	// ***************************************************************************************************************************************
	// Create Delivery
	// **************************************************************************************************************************************
	static create_invoice(d, new_invoice, delivery_number, bypass)
	{
		let go = true;

		if (!new_invoice)
		{
			if (document.getElementById("invoice_number").value.trim() == '')
			{
				frappe.msgprint({title: __("Message"), message: __('Invoice / CN number is mandatory'), indicator: "red"});
				go = false;
			}
		}

		if ((go) && (document.getElementById('nb_record').value == 0))
		{
			if (new_delivery)
			{	frappe.msgprint({title: __("Message"), message: __('No delivery line to create an invoice / CN'), indicator: "red"});
			}else
			{	frappe.msgprint({title: __("Message"), message: __('No delivery line to add to the invoice / CN'), indicator: "red"});
			}
			go = false;
		}

		if (go)
		{
			let one_selected = false
			let delivery_selected_lines = []

			for (var i = 0; i < document.getElementById('nb_record').value; i++)
			{
				if (document.getElementById('checked_id_' + i.toString()).checked)
				{
					one_selected = true;
					delivery_selected_lines.push(document.getElementById('name_id_' + i.toString()).value);
				}
			}

			if (!one_selected)
			{	if (!bypass)
				{	frappe.msgprint({title: __("Message"), message: __('select at least one delivery line'), indicator: "red"});
				}else
				{	frappe.msgprint({title: __("Message"), message: __('No delivery line to invoice'), indicator: "red"});
				}
				go = false;

			}

			if (go)
			{
				let pathsalesdelivery = 'silicon_ioi.ioi_sales.doctype.ioi_sales_delivery.ioi_sales_delivery';
				let method = pathsalesdelivery + '.ioi_sales_delivery_create_invoice';

				let invoice_number = ''

				if (!new_invoice)
				{
					if (document.getElementById('invoice_number'))
					{
						invoice_number = document.getElementById('invoice_number').value;
					}
				}

				let delivery_id = '';

				if (cur_frm.doc.doctype.toUpperCase() == 'IOI SALES DELIVERY')
				{	delivery_id = cur_frm.doc.name;
				}else
				{	delivery_id = delivery_number;
				}



				frappe.call({  	method: method,
								args: {"name": delivery_id, "selected_lines":[delivery_selected_lines], "new_invoice" : new_invoice, "invoice_id": invoice_number},
								async: false,
								callback:function(r)	{
															if (r.message.error != 0)
															{
																frappe.msgprint({title: __("Message"), message: r.message.error_message, indicator: "red"});
															}else
															{
																d.hide();

																if (new_invoice)
																{
																	window.open('/app/ioi-sales-invoice/' + r.message.invoice_number);
																}else
																{
																	if (cur_frm.doc.doctype.toUpperCase() == 'IOI SALES DELIVERY')
																	{
																		window.open('/app/ioi-sales-invoice/' + r.message.invoice_number);
																	}else
																	{
																		if (delivery_number.trim() != '')
																		{
																			window.location.reload();
																		}
																	}
																}
															}
														}
				});
			}
		}
	}


	// ***************************************************************************************************************************************
	// Delivery : select an invoice / CN
	// **************************************************************************************************************************************
	static delivery_select_an_invoice_cn()
	{
		var z = new frappe.ui.Dialog({
			'title': __("Select an invoice / CN"),
			'fields': [
				{'fieldname': 'html_select_invoice', 'fieldtype': 'HTML'}

			],
			primary_action_label: 'Ok',
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

				let go = true;

				if (document.getElementById('invoice_nb_record').value == 0)
				{
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
					frappe.msgprint({title: __("Message"), message: __("No invoice / CN selected"), indicator: "red"});
				}

				if ((go) && (document.getElementById('invoice_nb_record').value != 0))
				{	go = false
					for (var i = 0; i < document.getElementById('invoice_nb_record').value; i++)
					{
						if (document.getElementById('invoice_checked_id_' + i.toString()).checked)
						{
							document.getElementById('invoice_number').value = document.getElementById('invoice_name_id_' + i.toString()).value;
							go = true;
							break;
						}
					}

					if (!go)
					{	document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
						frappe.msgprint({title: __("Message"), message: __("Select an invoice / CN, please"), indicator: "red"});
					}

				}

				if (go)
				{
					z.hide();
				}else{
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
				}
			},
			secondary_action: function(){
				z.hide();
			}

		});

		if (document.getElementById('invoice_nb_record'))
		{
			for (var i = 0; i < document.getElementById('invoice_nb_record').value; i++)
			{
				if (document.getElementById('invoice_checked_id_' + i.toString()))
				{
					document.getElementById('invoice_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('invoice_name_id_' + i.toString()))
				{
					document.getElementById('invoice_name_id_' + i.toString()).remove();
				}

			}

			document.getElementById('invoice_nb_record').remove();
		}

		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:600px; width: 100%;">';

		html += '<table border=1 style="border: 1px solid #E8EAEB" width=1055px data-custom-grid="true">';

		html += '<tr style="height:30px">';

		html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=200px style="vertical-align: middle;">&nbsp;<b>' + __("Invoice number") + '</b></td>';
		html += '<td width=100px align="center" style="vertical-align: middle;"><b>' + __("Doc. date") + '</b></td>';
		html += '<td width=250px style="vertical-align: middle;">&nbsp;<b>' + __("Invoice customer") + '</b></td>';
		html += '<td width=350px style="vertical-align: middle;">&nbsp;<b>' + __("Customer name") + '</b></td>';
		html += '<td width=125px align="center" style="vertical-align: middle;"><b>' + __("Total w/o VAT") + '</b></td>';
		html += '</tr>';
		html += '</table>';

		let pathsalesinvoice = 'silicon_ioi.ioi_sales.doctype.ioi_sales_invoice.ioi_sales_invoice';

		let method = pathsalesinvoice + '.ioi_sales_invoice_get_compatible_invoices_with_delivery';


		frappe.call({  	method: method,
						args: {"delivery_id": cur_frm.doc.name},
						async: false,
						callback:function(r)	{
													if (r.message.length > 0)
													{	html += '<input type="hidden" id="invoice_nb_record" value="' + r.message.length + '">';
														html += '<table border=1 style="border: 1px solid #E8EAEB" width=1055px>';

														for (var i = 0; i < r.message.length; i++)
														{
															html += '<tr style="height:30px">';

															html += '<td width=30px align="center" style="vertical-align: middle;">';
															html += '<input type="checkbox" id="invoice_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
															html += '       onclick=" ';
															html += '					for (var i = 0; i < document.getElementById(\'invoice_nb_record\').value; i++) ';
															html += '					{';
															html += '						if (document.getElementById(\'invoice_checked_id_\' + i.toString()).id != this.id) ';
															html += '						{ ';
															html += '							document.getElementById(\'invoice_checked_id_\' + i.toString()).checked = false; ';
															html += '						} ';
															html += '					} ';
															html += '">';

															html += '<input type="hidden" id="invoice_name_id_' + i.toString() + '" value="' +  r.message[i].name + '">';
															html += '</td>';

															html += '<td width=200px style="vertical-align: middle;">&nbsp;' + r.message[i].name + '</td>'

															html += '<td width=100px style="vertical-align: middle;">&nbsp;' + r.message[i].document_date + '</td>'

															html += '<td width=250px style="vertical-align: middle;">&nbsp;' + r.message[i].invoice_customer_id + '</td>'

															html += '<td width=350px style="vertical-align: middle;">&nbsp;'

															if (r.message[i].invoice_customer_name != null)
															{	html += r.message[i].invoice_customer_name.substr(0,50);
															}

															html += '</td>';


															html += '<td width=125px align="right" style="vertical-align: middle;">'

															if ((r.message[i].total_htva != null))
															{
																html += r.message[i].total_htva.toFixed(2) + '&nbsp;'

																if (r.message[i].currency_id != null)
																{	html += r.message[i].currency_id + '&nbsp';
																}
															}

															html += '</td>';

															html += '</tr>';
														}
														html += '</table>';
													}else
													{
														html += '<input type="hidden" id="invoice_nb_record" value="0">';
													}
												}
		});

		html += '</div>';


		z.fields_dict.html_select_invoice.$wrapper.html(html);


		z.show();
	}


	// ***************************************************************************************************************************************
	// Create Invoice from Delivery
	// **************************************************************************************************************************************
	create_invoice_from_delivery(new_invoice)
	{
		var z = new frappe.ui.Dialog({
			'title': __("Select a delivery"),
			'fields': [
				{'fieldname': 'html_select_delivery', 'fieldtype': 'HTML'}

			],
			primary_action_label: 'Ok',
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				let go = true;

				if (document.getElementById('delivery_nb_record').value == 0)
				{
					frappe.msgprint({title: __("Message"), message: __("No delivery selected"), indicator: "red"});
				}

				if ((go) && (document.getElementById('delivery_nb_record').value != 0))
				{	go = false
					for (var i = 0; i < document.getElementById('delivery_nb_record').value; i++)
					{
						if (document.getElementById('delivery_checked_id_' + i.toString()).checked)
						{
							document.getElementById('delivery_number').value = document.getElementById('delivery_name_id_' + i.toString()).value;
							document.getElementById('delivery_ioistatus').value = document.getElementById('delivery_ioistatus_id_' + i.toString()).value;

							go = true;
							break;
						}
					}

					if (!go)
					{
						frappe.msgprint({title: __("Message"), message: __("Select a delivery, please"), indicator: "red"});
					}

				}

				if (go)
				{
					z.hide();
					if (new_invoice)
					{
						silicon_ioi.doctype.ioiSalesDocumentDocType.invoice_this_delivery(true, document.getElementById('delivery_number').value, document.getElementById('delivery_ioistatus').value, '');
					}else
					{
						silicon_ioi.doctype.ioiSalesDocumentDocType.invoice_this_delivery(false, document.getElementById('delivery_number').value, document.getElementById('delivery_ioistatus').value, cur_frm.doc.name);
					}
				}
			},
			secondary_action: function(){
				z.hide();
			}

		});

		if (document.getElementById('delivery_nb_record'))
		{
			for (var i = 0; i < document.getElementById('delivery_nb_record').value; i++)
			{
				if (document.getElementById('delivery_checked_id_' + i.toString()))
				{
					document.getElementById('delivery_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('delivery_name_id_' + i.toString()))
				{
					document.getElementById('delivery_name_id_' + i.toString()).remove();
				}

				if (document.getElementById('delivery_ioistatus_id_' + i.toString()))
				{
					document.getElementById('delivery_ioistatus_id_' + i.toString()).remove();
				}


			}

			document.getElementById('delivery_nb_record').remove();
		}

		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:600px; width: 100%;">';

		html += '<table border=1 style="border: 1px solid #E8EAEB" width=1055px data-custom-grid="true">';

		html += '<tr style="height:30px">';

		html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=200px style="vertical-align: middle;">&nbsp;<b>' + __("Delivery number") + '</b></td>';
		html += '<td width=100px align="center" style="vertical-align: middle;"><b>' + __("Doc. date") + '</b></td>';
		html += '<td width=250px style="vertical-align: middle;">&nbsp;<b>' + __("Delivery customer") + '</b></td>';
		html += '<td width=350px style="vertical-align: middle;">&nbsp;<b>' + __("Customer name") + '</b></td>';
		html += '<td width=125px align="center" style="vertical-align: middle;"><b>' + __("Total w/o VAT") + '</b></td>';
		html += '</tr>';
		html += '</table>';


		let method = this.path_sales_delivery + '.ioi_sales_delivery_get_deliveries_for_invoice';


		frappe.call({  	method: method,
						args: {"new_invoice" : new_invoice, "invoice_id": this.frm.doc.name },
						async: false,
						callback:function(r)	{
													if (r.message.length > 0)
													{	html += '<input type="hidden" id="delivery_nb_record" value="' + r.message.length + '">';
														html += '<table border=1 style="border: 1px solid #E8EAEB" width=1055px>';

														for (var i = 0; i < r.message.length; i++)
														{
															html += '<tr style="height:30px">';

															html += '<td width=30px align="center" style="vertical-align: middle;">';
															html += '<input type="checkbox" id="delivery_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
															html += '       onclick=" ';
															html += '					for (var i = 0; i < document.getElementById(\'delivery_nb_record\').value; i++) ';
															html += '					{';
															html += '						if (document.getElementById(\'delivery_checked_id_\' + i.toString()).id != this.id) ';
															html += '						{ ';
															html += '							document.getElementById(\'delivery_checked_id_\' + i.toString()).checked = false; ';
															html += '						} ';
															html += '					} ';
																												html += '">';
															html += '<input type="hidden" id="delivery_name_id_' + i.toString() + '" value="' +  r.message[i].name + '">';
															html += '<input type="hidden" id="delivery_ioistatus_id_' + i.toString() + '" value="' +  r.message[i].ioistatus + '">';
															html += '</td>';

															html += '<td width=200px style="vertical-align: middle;">&nbsp;' + r.message[i].name + '</td>'

															html += '<td width=100px style="vertical-align: middle;">&nbsp;' + r.message[i].document_date + '</td>'

															html += '<td width=250px style="vertical-align: middle;">&nbsp;' + r.message[i].delivery_customer_id + '</td>'

															html += '<td width=350px style="vertical-align: middle;">&nbsp;'

															if (r.message[i].delivery_customer_name != null)
															{	html += r.message[i].delivery_customer_name.substr(0,50);
															}

															html += '</td>';


															html += '<td width=125px align="right" style="vertical-align: middle;">'

															if ((r.message[i].total_htva != null))
															{
																html += r.message[i].total_htva.toFixed(2) + '&nbsp;'

																if (r.message[i].currency_id != null)
																{	html += r.message[i].currency_id + '&nbsp';
																}
															}

															html += '</td>';

															html += '</tr>';
														}
														html += '</table>';
													}else
													{
														html += '<input type="hidden" id="delivery_nb_record" value="0">';
													}
												}
		});

		html += '<input type="hidden" id="delivery_number" value="">';
		html += '<input type="hidden" id="delivery_ioistatus" value="">';
		html += '</div>';


		z.fields_dict.html_select_delivery.$wrapper.html(html);


		z.show();
	}

	// ***************************************************************************************************************************************
	// Build Batch Buttons (select, clear and FIFO proposal)
	// **************************************************************************************************************************************
	#build_html_batch_buttons(fieldname, cdt, cdn)
	{
		let me = this;
		let item = locals[cdt][cdn];

		if (document.getElementById('bt_select_batch'))
		{
			document.getElementById('bt_select_batch').remove();
		}

		if (document.getElementById('bt_clear_batch'))
		{
			document.getElementById('bt_clear_batch').remove();
		}

		if (document.getElementById('bt_batch_fifo'))
		{
			document.getElementById('bt_batch_fifo').remove();
		}


		if (document.getElementById('batch_line_cdt'))
		{
			document.getElementById('batch_line_cdt').remove();
		}

		if (document.getElementById('batch_line_cdn'))
		{
			document.getElementById('batch_line_cdn').remove();
		}


		let html = '';
		html += '<div>';
		html +=	'	<button id="bt_select_batch" data-label="Select a batch/SN" class="btn btn-default ellipsis" onclick="">' + __("Select a batch / SN") + '</button> ';
		html +=	'	<button id="bt_clear_batch"  data-label="Clear batch/SN" class="btn btn-default ellipsis" onclick="">' + __("Clear batch / SN") + '</button> ';
		html +=	'	<button id="bt_batch_fifo"  data-label="FIFO proposal" class="btn btn-default ellipsis" onclick="">' + __("FIFO proposal") + '</button> ';
		html += '   <input type="hidden" id="batch_line_cdt" value="' + cdt + '"> ';
		html += '   <input type="hidden" id="batch_line_cdn" value="' + cdn + '"> ';
		html += '</div>'
		this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].$wrapper.empty();
		this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].$wrapper.append(html);

		let fct_select = function () {	me.select_batch(true); };
		let fct_clear = function () {	me.clear_batch(true); };
		let fct_fifo = function () {	me.batch_fifo_proposal(); };

		document.getElementById('bt_select_batch').onclick = fct_select;
		document.getElementById('bt_clear_batch').onclick = fct_clear;
		document.getElementById('bt_batch_fifo').onclick = fct_fifo;
	}

	// ***************************************************************************************************************************************
	// Clear batch
	// **************************************************************************************************************************************
	clear_batch(form = false, idx = 0)
	{
		let item = null;

		if (form) {
			item = locals[document.getElementById('batch_line_cdt').value][document.getElementById('batch_line_cdn').value];
		}else{
			item = locals[cur_frm.fields_dict['line_detail'].grid.doctype][cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name];
		}


		let can_clear_batch = true;

		if (item.ioistatus != 0)
		{
			frappe.msgprint({title: __("Message"), message: __("Line status has to be in preparation"), indicator: "red"});
			can_clear_batch = false;
		}

		if (can_clear_batch)
		{
			cur_frm.dirty();
			item.batch_sn_id = '';
			item.batch_sn_code_ref = '';
			item.batch_sn_origin = '';

			this.frm.refresh_field('line_detail');

			if (form) {
				this.format_detail_form(this.frm, document.getElementById('batch_line_cdt').value, document.getElementById('batch_line_cdn').value);
			}else{
				this.format_detail_form(this.frm, cur_frm.fields_dict['line_detail'].grid.doctype, cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name);
			}
		}

	}

	// ***************************************************************************************************************************************
	// Select a batch
	// **************************************************************************************************************************************
	select_batch(form = false, idx = 0)
	{
		let item = null;

		if (form) {
			item = locals[document.getElementById('batch_line_cdt').value][document.getElementById('batch_line_cdn').value];
		}else{
			item = locals[cur_frm.fields_dict['line_detail'].grid.doctype][cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name];
		}


		let can_show_batch_frm = true;

		if (item.ioistatus != 0)
		{
			frappe.msgprint({title: __("Message"), message: __("Line status has to be in preparation"), indicator: "red"});
			can_show_batch_frm = false;
		}

		if ((can_show_batch_frm) && ((!item.item_id) || ((item.item_id) && (item.item_id.trim() == ''))))
		{
			frappe.msgprint({title: __("Message"), message: __("item has to be filled"), indicator: "red"});
			this.frm.refresh_field('line_detail');
			can_show_batch_frm = false;
		}


		if ((can_show_batch_frm) && ((!item.warehouse_id) || ((item.warehouse_id) && (item.warehouse_id.trim() == ''))))
		{
			frappe.msgprint({title: __("Message"), message: __("Warehouse has to be filled"), indicator: "red"});
			this.frm.refresh_field('line_detail');
			can_show_batch_frm = false;
		}

		if (can_show_batch_frm)
		{
			let me = this;
			let ccdt = '';
			let ccdn = '';

			if (form) {
				ccdt = document.getElementById('batch_line_cdt').value;
				ccdn = document.getElementById('batch_line_cdn').value;
			}else{
				ccdt = cur_frm.fields_dict['line_detail'].grid.doctype;
				ccdn = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name;
			}

			var z = new frappe.ui.Dialog({
				'title': __("Select a batch / SN in " + item.warehouse_id),
				'fields': [
					{'fieldname': 'html_select_batch', 'fieldtype': 'HTML'}

				],
				primary_action_label: 'Ok',
				secondary_action_label: __('Cancel'),
				primary_action: function(){

					let go = true;

					if (document.getElementById('batch_nb_record').value == 0)
					{
						go = false;
					}

					if ((go) && (document.getElementById('batch_nb_record').value != 0))
					{	go = false
						for (var i = 0; i < document.getElementById('batch_nb_record').value; i++)
						{
							if (document.getElementById('batch_checked_id_' + i.toString()).checked)
							{
								document.getElementById('selected_batch_id').value = document.getElementById('batch_name_id_' + i.toString()).value;
								document.getElementById('selected_batch_location_id').value = document.getElementById('batch_location_id_' + i.toString()).value;

								go = true;
								break;
							}
						}
					}

					if (go)
					{
						z.hide();
						cur_frm.dirty();
						item.batch_sn_id = document.getElementById('selected_batch_id').value;
						item.warehouse_location_id = document.getElementById('selected_batch_location_id').value;
						item.batch_sn_code_ref = '';
						item.batch_sn_origin = '';



						if (silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id)
						{
							for (var i = 0; i < silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id.length; i++)
							{
								if (item.batch_sn_id == silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][0])
								{
									if (silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][3] != null)
									{
										item.batch_sn_code_ref = silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][3];
									}

									if (silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][4] != null)
									{
										item.batch_sn_origin = silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][4];
									}

								}
							}
						}

						if (item.batch_sn_id != '') {

							let pathbatchlocation = 'silicon_ioi.ioi_wms.doctype.ioi_batch_sn.ioi_batch_sn';
							let method_bis = pathbatchlocation + '.batch_sn_get_voucher_info';
			
							frappe.call({  
								method: method_bis,
								args: {	
									"batch_sn_id": item.batch_sn_id, 
									"doc_currency_id": cur_frm.doc.currency_id, 
									"doc_rate": cur_frm.doc.currency_inv_rate
								},
								async: false,
								callback:function(r)	{	
									item.vat_rate = r.message.voucher_vat_rate;
									item.unit_price = r.message.voucher_value_htva;
									item.unit_price_tvac = r.message.voucher_value_tvac;
								}
							});						
						}


						me.frm.refresh_field('line_detail');
						me.compute_document();
						me.format_detail_form(me.frm, ccdt, ccdn);
					}
				},
				secondary_action: function(){
					z.hide();
				}

			});

			if (document.getElementById('batch_nb_record'))
			{
				for (var i = 0; i < document.getElementById('batch_nb_record').value; i++)
				{
					if (document.getElementById('batch_row_' + i.toString()))
					{
						document.getElementById('batch_row_' + i.toString()).remove();
					}

					if (document.getElementById('batch_checked_id_' + i.toString()))
					{
						document.getElementById('batch_checked_id_' + i.toString()).remove();
					}

					if (document.getElementById('batch_name_id_' + i.toString()))
					{
						document.getElementById('batch_name_id_' + i.toString()).remove();
					}

					if (document.getElementById('batch_qt_' + i.toString()))
					{
						document.getElementById('batch_qt_' + i.toString()).remove();
					}

					if (document.getElementById('batch_location_id_' + i.toString()))
					{
						document.getElementById('batch_location_id_' + i.toString()).remove();
					}

				}

				document.getElementById('batch_nb_record').remove();
			}

			if (document.getElementById("table_batch"))
			{
				document.getElementById("table_batch").remove();
			}

			if (document.getElementById('batch_filter'))
			{
				document.getElementById('batch_filter').remove();
			}

			if (document.getElementById('selected_batch_id'))
			{
				document.getElementById('selected_batch_id').remove();
			}



			if (document.getElementById('selected_batch_location_id'))
			{
				document.getElementById('selected_batch_location_id').remove();
			}


			let html = '';

			html += '<div style="overflow: auto; overflow-x: auto; height:70px; width: 100%;">';
			html += '	<div style="position: relative; left: 0px; width:600px;">';
			html += '		<label style="position: absolute; top: 0px; left: 2px;">' + __("Search") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 598px; height: 25px;"> ';
			html += '			<input type="text" id="batch_filter" class="input-with-feedback form-control bold" value="">';
			html += '		</div>';
			html += '	</div>';
			html += '</div>'

			html += '<div style="overflow: auto; overflow-x: auto; height:600px; width: 100%;">';

			html += '<table border=1 style="border: 1px solid #E8EAEB" width=1410px data-custom-grid="true">';

			html += '<tr style="height:30px">';

			html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=270px style="vertical-align: middle;">&nbsp;<b>' + __("Batch") + '</b></td>';
			html += '<td width=270px style="vertical-align: middle;">&nbsp;<b>' + __("Code ref") + '</b></td>';
			html += '<td width=270px style="vertical-align: middle;">&nbsp;<b>' + __("Origin") + '</b></td>';
			html += '<td width=270px style="vertical-align: middle;">&nbsp;<b>' + __("QC ref") + '</b></td>';
			html += '<td width=300px style="vertical-align: middle;">&nbsp;<b>' + __("Qty") + '</b></td>';
			html += '</tr>';
			html += '</table>';


			let item_mode = 0;
			let item_stored_qty_mode = 0;

			frappe.db.get_list('ioi Item', {fields:["mode"], filters:{"name": item.item_id}}).then((r) => {

				for (var i = 0; i < r.length; i++)
				{
					item_mode = r[i].mode;
				}

				if (item.stored_qty_mode)
				{
					item_stored_qty_mode = item.stored_qty_mode;
				}



				let pathbatchlocation = 'silicon_ioi.ioi_wms.doctype.ioi_batch_sn.ioi_batch_sn';
				let method_bis = pathbatchlocation + '.ioi_batch_sn_get_enabled_batch_for_item_warehouse';


				let sens = '+';

				if (item.delivered_qty < 0)
				{
					sens = '-';
				}


				let location_id = '';

				if ((item.warehouse_location_id) && (item.warehouse_location_id.trim() != ''))
				{
					location_id = item.warehouse_location_id;
				}


				frappe.call({  	method: method_bis,
								args: {	"warehouse_id": item.warehouse_id, "item_id": item.item_id, "stored_qty_mode": item_stored_qty_mode, "item_mode": item_mode, "sens" : sens, "location_id": location_id},
								async: false,
								callback:function(r)	{

															silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id = [];

															if (r.message.length > 0)
															{
																html += '<input type="hidden" id="batch_nb_record" value="' + r.message.length + '">';
																html += '<table id="table_batch" border=1 style="border: 1px solid #E8EAEB" width=1410px>';

																for (var i = 0; i < r.message.length; i++)
																{
																	silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i] = [r.message[i].name, r.message[i].qt, r.message[i].location_id, r.message[i].code_ref, r.message[i].origin, r.message[i].qcref];


																	html += '<tr id="batch_row_' + i.toString() + '" style="height:30px">';
																	html += '<td width=30px align="center" style="vertical-align: middle;">';
																	html += '<input type="checkbox" id="batch_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
																	html += '       onclick=" ';
																	html += '					for (var i = 0; i < document.getElementById(\'batch_nb_record\').value; i++) ';
																	html += '					{';
																	html += '						if (document.getElementById(\'batch_checked_id_\' + i.toString()).id != this.id) ';
																	html += '						{ ';
																	html += '							document.getElementById(\'batch_checked_id_\' + i.toString()).checked = false; ';
																	html += '						} ';
																	html += '					} ';
																	html += '" ';

																	if (item.batch_sn_id == silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][0])
																	{
																		html += ' checked ';
																	}
																	html += '>';
																	html += '<input type="hidden" id="batch_name_id_' + i.toString() + '" value="' +  silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][0] + '">';
																	html += '<input type="hidden" id="batch_qt_' + i.toString() + '" value="' +  silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][1] + '">';
																	html += '<input type="hidden" id="batch_location_id_' + i.toString() + '" value="' +  silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][2] + '">';
																	html += '</td>';

																	let v = silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][0];

																	html += '<td width=270px style="vertical-align: middle;">&nbsp;' + v + '</td>';

																	if (silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][3] != null)
																	{
																		v = silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][3];
																		html += '<td width=270px style="vertical-align: middle;">&nbsp;' + v + '</td>';
																	}else
																	{ 	html += '<td width=270px style="vertical-align: middle;">&nbsp;</td>';
																	}

																	if (silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][4] != null)
																	{
																		v = silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][4];
																		html += '<td width=270px style="vertical-align: middle;">&nbsp;' + v + '</td>'
																	}else
																	{ 	html += '<td width=270px style="vertical-align: middle;">&nbsp;</td>';
																	}


																	if (silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][5] != null)
																	{
																		v = silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][5];
																		html += '<td width=270px style="vertical-align: middle;">&nbsp;' + v + '</td>'
																	}else
																	{ 	html += '<td width=270px style="vertical-align: middle;">&nbsp;</td>';
																	}




																	html += '<td width=300px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][1] + '</td>'
																	html += '</tr>';

																}
																html += '</table>';
															}else
															{	html += '<input type="hidden" id="batch_nb_record" value="0">';
															}
														}
				});

				html += '<input type="hidden" id="selected_batch_id" value="">';
				html += '<input type="hidden" id="selected_batch_location_id" value="">';


				html += '</div>';


				z.fields_dict.html_select_batch.$wrapper.html(html);
				z.$wrapper.find('.modal-dialog').css("max-width", "50%").css("width", "50%");
				z.$wrapper.find('.modal-dialog').css("max-height", "50%").css("height", "50%");

				z.show();

				me.#sleep(250).then(() => {
					document.getElementById('batch_filter').onkeyup = this.#batch_filter_keyup;

				});
			});

		}else
		{
			if (form) {
				this.format_detail_form(this.frm, document.getElementById('batch_line_cdt').value, document.getElementById('batch_line_cdn').value);
			}else{
				this.format_detail_form(this.frm, cur_frm.fields_dict['line_detail'].grid.doctype, cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name);
			}
		}

	}

	#batch_filter_keyup(event)
	{
		silicon_ioi.doctype.ioiSalesDocumentDocType.refresh_batches()
	}

	static refresh_batches()
	{
		if (document.getElementById('batch_nb_record'))
		{
			if (document.getElementById('batch_nb_record').value > 0)
			{
				for (var i = 0; i < document.getElementById('batch_nb_record').value; i++)
				{
					if (document.getElementById('batch_row_' + i.toString()))
					{
						document.getElementById('batch_row_' + i.toString()).remove();
					}

					if (document.getElementById('batch_checked_id_' + i.toString()))
					{
						document.getElementById('batch_checked_id_' + i.toString()).remove();
					}

					if (document.getElementById('batch_name_id_' + i.toString()))
					{
						document.getElementById('batch_name_id_' + i.toString()).remove();
					}

					if (document.getElementById('batch_qt_' + i.toString()))
					{
						document.getElementById('batc((can_fifo) &&h_qt_' + i.toString()).remove();
					}

					if (document.getElementById('batch_location_id_' + i.toString()))
					{
						document.getElementById('batch_location_id_' + i.toString()).remove();
					}

				}
			}
		}

		let table = document.getElementById("table_batch");

		let cpt = 0;
		let create_row = false;


		for (var i = 0; i < silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id.length; i++)
		{
			create_row = false;
			if (document.getElementById('batch_filter').value.toString().trim() == '')
			{
				create_row = true;
			}else
			{
				let search = document.getElementById('batch_filter').value.toString().trim().toUpperCase();

				if ((silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][0].toString().toUpperCase().search(search) != -1) ||
					((silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][5] != null) && (silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][5].toString().toUpperCase().search(search) != -1)) ||
					((silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][1] != null) && (silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][1].toString().toUpperCase().search(search) != -1)) ||
					((silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][3] != null) && (silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][3].toString().toUpperCase().search(search) != -1)) ||
					((silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][4] != null) && (silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][4].toString().toUpperCase().search(search) != -1)))
				{	create_row = true;
				}
			}

			if (create_row)
			{
				let row = table.insertRow(cpt);
				row.id = 'batch_row_' + cpt.toString();
				row.style = 'height:30px';

				let cell1 = row.insertCell(0);
				cell1.width = '30px;'
				cell1.align = 'center';
				cell1.style = 'vertical-align: middle';

				let html = '';
				html += '<input type="checkbox" id="batch_checked_id_' + cpt.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
				html += '       onclick=" ';
				html += '					for (var i = 0; i < document.getElementById(\'batch_nb_record\').value; i++) ';
				html += '					{';
				html += '						if (document.getElementById(\'batch_checked_id_\' + i.toString()).id != this.id) ';
				html += '						{ ';
				html += '							document.getElementById(\'batch_checked_id_\' + i.toString()).checked = false; ';
				html += '						} ';
				html += '					} ';
				html += '">';
				html += '<input type="hidden" id="batch_name_id_' + cpt.toString() + '" value="' + silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][0] + '">';
				html += '<input type="hidden" id="batch_qt_' + cpt.toString() + '" value="' + silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][1] + '">';
				html += '<input type="hidden" id="batch_location_id_' + cpt.toString() + '" value="' + silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][2] + '">';

				cell1.innerHTML = html;

				let cell2 = row.insertCell(1);
				cell2.width = '270px;'
				cell2.style = 'vertical-align: middle';

				let v = silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][0];

				let search_term = document.getElementById('batch_filter').value.toString().trim().toUpperCase();

				let formatted_data = '';


				if (search_term != '') {

					search_term = search_term.toUpperCase();

					let data = v.toString();

					if (data != '') {
						data = data.trim().toUpperCase();

						if (data.indexOf(search_term) != -1) {
							let from_idx = data.indexOf(search_term);
							let len_search = search_term.length;

							let begin_data = v.substring(0, from_idx);
							let intermediate_data = v.substring(from_idx,  from_idx + len_search);
							let end_data = v.substring(from_idx+len_search, data.length);

							formatted_data = begin_data;

							formatted_data += '<font style="background-color:#D0E7FB"><b>' + intermediate_data + '</b></font>' + end_data;


						}else {
							formatted_data = v;
						}
					}else{
						formatted_data = v;
					}
				}else {
					formatted_data = v;
				}



				html = '&nbsp;' + formatted_data;


				cell2.innerHTML = html;

				let cell3 = row.insertCell(2);
				cell3.width = '270px;'
				cell3.style = 'vertical-align: middle';

				v = '';
				if (silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][3] != null)
				{
					v = silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][3];
				}

				search_term = document.getElementById('batch_filter').value.toString().trim().toUpperCase();

				formatted_data = '';


				if (search_term != '') {

					search_term = search_term.toUpperCase();

					let data = v.toString();

					if (data != '') {
						data = data.trim().toUpperCase();

						if (data.indexOf(search_term) != -1) {
							let from_idx = data.indexOf(search_term);
							let len_search = search_term.length;

							let begin_data = v.substring(0, from_idx);
							let intermediate_data = v.substring(from_idx,  from_idx + len_search);
							let end_data = v.substring(from_idx+len_search, data.length);

							formatted_data = begin_data;

							formatted_data += '<font style="background-color:#D0E7FB"><b>' + intermediate_data + '</b></font>' + end_data;


						}else {
							formatted_data = v;
						}
					}else{
						formatted_data = v;
					}
				}else {
					formatted_data = v;
				}



				html = '&nbsp;' + formatted_data;



				cell3.innerHTML = html;

				let cell4 = row.insertCell(3);
				cell4.width = '270px;'
				cell4.style = 'vertical-align: middle';

				v = '';
				if (silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][4] != null)
				{
					v = silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][4];
				}

				search_term = document.getElementById('batch_filter').value.toString().trim().toUpperCase();

				formatted_data = '';


				if (search_term != '') {

					search_term = search_term.toUpperCase();

					let data = v.toString();

					if (data != '') {
						data = data.trim().toUpperCase();

						if (data.indexOf(search_term) != -1) {
							let from_idx = data.indexOf(search_term);
							let len_search = search_term.length;

							let begin_data = v.substring(0, from_idx);
							let intermediate_data = v.substring(from_idx,  from_idx + len_search);
							let end_data = v.substring(from_idx+len_search, data.length);

							formatted_data = begin_data;

							formatted_data += '<font style="background-color:#D0E7FB"><b>' + intermediate_data + '</b></font>' + end_data;


						}else {
							formatted_data = v;
						}
					}else{
						formatted_data = v;
					}
				}else {
					formatted_data = v;
				}



				html = '&nbsp;' + formatted_data;
				cell4.innerHTML = html;


				let cell5 = row.insertCell(4);
				cell5.width = '270px;'
				cell5.style = 'vertical-align: middle';

				v = '';
				if (silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][5] != null)
				{
					v = silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][5];
				}

				search_term = document.getElementById('batch_filter').value.toString().trim().toUpperCase();

				formatted_data = '';


				if (search_term != '') {

					search_term = search_term.toUpperCase();

					let data = v.toString();

					if (data != '') {
						data = data.trim().toUpperCase();

						if (data.indexOf(search_term) != -1) {
							let from_idx = data.indexOf(search_term);
							let len_search = search_term.length;

							let begin_data = v.substring(0, from_idx);
							let intermediate_data = v.substring(from_idx,  from_idx + len_search);
							let end_data = v.substring(from_idx+len_search, data.length);

							formatted_data = begin_data;

							formatted_data += '<font style="background-color:#D0E7FB"><b>' + intermediate_data + '</b></font>' + end_data;


						}else {
							formatted_data = v;
						}
					}else{
						formatted_data = v;
					}
				}else {
					formatted_data = v;
				}



				html = '&nbsp;' + formatted_data;
				cell5.innerHTML = html;


				let cell6 = row.insertCell(5);
				cell6.width = '300px;'
				cell6.style = 'vertical-align: middle';

				search_term = document.getElementById('batch_filter').value.toString().trim().toUpperCase();
				v = silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][1].toString();
				formatted_data = '';


				if (search_term != '') {

					search_term = search_term.toUpperCase();

					let data = v.toString();

					if (data != '') {
						data = data.trim().toUpperCase();

						if (data.indexOf(search_term) != -1) {
							let from_idx = data.indexOf(search_term);
							let len_search = search_term.length;

							let begin_data = v.substring(0, from_idx);
							let intermediate_data = v.substring(from_idx,  from_idx + len_search);
							let end_data = v.substring(from_idx+len_search, data.length);

							formatted_data = begin_data;

							formatted_data += '<font style="background-color:#D0E7FB"><b>' + intermediate_data + '</b></font>' + end_data;


						}else {
							formatted_data = v;
						}
					}else{
						formatted_data = v;
					}
				}else {
					formatted_data = v;
				}



				html = '&nbsp;' + formatted_data;

				cell6.innerHTML = html;

				cpt++;


			}
		}
		document.getElementById('batch_nb_record').value = cpt.toString();

	}


	batch_fifo_proposal()
	{
		let me = this;


		let item = locals[document.getElementById('batch_line_cdt').value][document.getElementById('batch_line_cdn').value];

		let can_fifo = true;

		if (item.ioistatus != 0)
		{
			frappe.msgprint({title: __("Message"), message: __("Line status has to be in preparation"), indicator: "red"});
			can_fifo = false;
		}

		if ((can_fifo) && ((!item.item_id) || ((item.item_id) && (item.item_id.trim() == ''))))
		{
			frappe.msgprint({title: __("Message"), message: __("item has to be filled"), indicator: "red"});
			this.frm.refresh_field('line_detail');
			can_fifo = false;
		}


		if ((can_fifo) && ((!item.warehouse_id) || ((item.warehouse_id) && (item.warehouse_id.trim() == ''))))
		{
			frappe.msgprint({title: __("Message"), message: __("Warehouse has to be filled"), indicator: "red"});
			this.frm.refresh_field('line_detail');
			can_fifo = false;
		}

		if ((can_fifo) && ((item.batch_sn_id) && (item.batch_sn_id.trim() != '')))
		{
			can_fifo = false;
		}

		if ((can_fifo) && ((item.delivered_qty) && (item.delivered_qty == 0)))
		{
			can_fifo = false;
		}

		if (can_fifo)
		{
			let item_mode = 0;
			let item_stored_qty_mode = 0;

			frappe.db.get_list('ioi Item', {fields:["mode"], filters:{"name": item.item_id}}).then((r) => {

				for (var i = 0; i < r.length; i++)
				{
					item_mode = r[i].mode;
				}

				if (item.stored_qty_mode)
				{
					item_stored_qty_mode = item.stored_qty_mode;
				}


				let pathbatchlocation = 'silicon_ioi.ioi_wms.doctype.ioi_batch_sn.ioi_batch_sn';
				let method = pathbatchlocation + '.ioi_batch_sn_get_enabled_batch_for_item_warehouse_fifo';

				let sens = '+';

				if (item.delivered_qty < 0)
				{
					sens = '-';
				}


				frappe.call({  	method: method,
								args: {	"warehouse_id": item.warehouse_id, "item_id": item.item_id, "stored_qty_mode": item_stored_qty_mode, "item_mode": item_mode, "sens" : sens},
								async: false,
								callback:function(r)	{
															if (r.message.length > 0)
															{
																if (item_mode == 3)
																{
																	// Batch
																	let current_qty = item.delivered_qty;

																	if (!cur_frm.is_dirty())
																	{
																		cur_frm.is_dirty();
																	}

																	for (var i = 0; i < r.message.length; i++)
																	{
																		if (current_qty <= r.message[i].qt)
																		{
																			item.batch_sn_id = r.message[i].name;
																			item.batch_sn_code_ref = r.message[i].code_ref;
																			item.batch_sn_origin = r.message[i].origin;

																			item.delivered_qty = current_qty;
																			item.stock_delivered_qty = (item.delivered_qty * 1.0) * (item.coef_sales_unit * 1.0) / (item.coef_stock_unit * 1.0);

																			if ((!item.warehouse_location_id) || ((item.warehouse_location_id) && (item.warehouse_location_id.trim() == '')))
																			{
																				if ((r.message[i].location_id != null) && (r.message[i].location_id.trim() != ''))
																				{
																					item.warehouse_location_id = r.message[i].location_id;
																				}
																			}
																			break;
																		}else
																		{
																			item.batch_sn_id = r.message[i].name;
																			item.batch_sn_code_ref = r.message[i].code_ref;
																			item.batch_sn_origin = r.message[i].origin;

																			item.delivered_qty = r.message[i].qt;
																			item.stock_delivered_qty = (item.delivered_qty * 1.0) * (item.coef_sales_unit * 1.0) / (item.coef_stock_unit * 1.0);

																			if ((!item.warehouse_location_id) || ((item.warehouse_location_id) && (item.warehouse_location_id.trim() == '')))
																			{
																				if ((r.message[i].location_id != null) && (r.message[i].location_id.trim() != ''))
																				{
																					item.warehouse_location_id = r.message[i].location_id;
																				}
																			}

																			// duplicate line
																			me.frm.refresh_field('line_detail');
																			let row = me.frm.add_child('line_detail');

																			for (var it in item)
																			{
																				if ((it.toUpperCase() != 'NAME')     && (it.toUpperCase() != 'OWNER')       && (it.toUpperCase() != 'CREATION')  &&
																					(it.toUpperCase() != 'MODIFIED') && (it.toUpperCase() != 'MODIFIED_BY') && (it.toUpperCase() != 'DOCSTATUS') &&
																					(it.toUpperCase() != 'IDX'))
																				{
																					row[it] = item[it];
																				}

																			}

																			item = row;

																			current_qty -= r.message[i].qt;
																		}
																	}
																	if (current_qty != 0)
																	{

																		item.delivered_qty = current_qty;
																		item.stock_delivered_qty = (item.delivered_qty * 1.0) * (item.coef_sales_unit * 1.0) / (item.coef_stock_unit * 1.0);
																		item.batch_sn_id = '';
																		item.batch_sn_code_ref = '';
																		item.batch_sn_origin = '';

																	}


																	cur_frm.save();
																	cur_frm.refresh();

																	cur_frm.refresh_field('line_detail');
																	me.format_detail_form(me.frm, document.getElementById('batch_line_cdt').value, document.getElementById('batch_line_cdn').value);

																}else
																{	// SN
																	let current_qty = item.delivered_qty;

																	if (!cur_frm.is_dirty())
																	{
																		cur_frm.is_dirty();
																	}

																	for (var i = 0; i < r.message.length; i++)
																	{
																		if (current_qty <= r.message[i].qt)
																		{
																			item.batch_sn_id = r.message[i].name;
																			item.batch_sn_code_ref = r.message[i].code_ref;
																			item.batch_sn_origin = r.message[i].origin;


																			item.delivered_qty = current_qty;
																			item.stock_delivered_qty = (item.delivered_qty * 1.0) * (item.coef_sales_unit * 1.0) / (item.coef_stock_unit * 1.0);

																			if ((!item.warehouse_location_id) || ((item.warehouse_location_id) && (item.warehouse_location_id.trim() == '')))
																			{
																				if ((r.message[i].location_id != null) && (r.message[i].location_id.trim() != ''))
																				{
																					item.warehouse_location_id = r.message[i].location_id;
																				}
																			}

																			current_qty -= r.message[i].qt;

																			break;
																		}else
																		{
																			item.batch_sn_id = r.message[i].name;
																			item.batch_sn_code_ref = r.message[i].code_ref;
																			item.batch_sn_origin = r.message[i].origin;


																			item.delivered_qty = r.message[i].qt;
																			item.stock_delivered_qty = (item.delivered_qty * 1.0) * (item.coef_sales_unit * 1.0) / (item.coef_stock_unit * 1.0);

																			if ((!item.warehouse_location_id) || ((item.warehouse_location_id) && (item.warehouse_location_id.trim() == '')))
																			{
																				if ((r.message[i].location_id != null) && (r.message[i].location_id.trim() != ''))
																				{
																					item.warehouse_location_id = r.message[i].location_id;
																				}
																			}

																			// duplicate line
																			me.frm.refresh_field('line_detail');
																			let row = me.frm.add_child('line_detail');

																			for (var it in item)
																			{
																				if ((it.toUpperCase() != 'NAME')     && (it.toUpperCase() != 'OWNER')       && (it.toUpperCase() != 'CREATION')  &&
																					(it.toUpperCase() != 'MODIFIED') && (it.toUpperCase() != 'MODIFIED_BY') && (it.toUpperCase() != 'DOCSTATUS') &&
																					(it.toUpperCase() != 'IDX'))
																				{
																					row[it] = item[it];
																				}

																			}

																			item = row;

																			current_qty -= r.message[i].qt;
																		}
																	}

																	if (current_qty != 0)
																	{

																		item.delivered_qty = current_qty;
																		item.stock_delivered_qty = (item.delivered_qty * 1.0) * (item.coef_sales_unit * 1.0) / (item.coef_stock_unit * 1.0);
																		item.batch_sn_id = '';
																		item.batch_sn_code_ref = '';
																		item.batch_sn_origin = '';

																	}



																	cur_frm.save();
																	cur_frm.refresh();

																	cur_frm.refresh_field('line_detail');
																	me.format_detail_form(me.frm, document.getElementById('batch_line_cdt').value, document.getElementById('batch_line_cdn').value);


																}
															}
								}
				});

			});

		}

	}

	// ***************************************************************************************************************************************
	// Action after change status
	// **************************************************************************************************************************************
	action_after_change_status()
	{
		if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
		{
			this.module_execute_action('ASK_TO_CREATE_LINKED_DOCUMENT');

		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
		{
			if (this.frm.doc.ioistatus == 1)
			{
				this.module_execute_action('ASK_TO_CREATE_LINKED_DOCUMENT');
			}

		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
		{
			if (this.frm.doc.ioistatus == 1)
			{
				this.module_execute_action('INTERSITE_ASK_TO_CREATE_PR');
			}

		}
	}

	// ***************************************************************************************************************************************
	// Module execute action
	// **************************************************************************************************************************************
	module_execute_action(action)
	{
		let me = this;

		let method = this.path_user_change_status_action + '.ioi_user_change_status_action_exists';

		frappe.call({  	method: method,
						args: {"doctype" : this.frm.doctype, "name" : this.frm.doc.name, "to_status" : this.frm.doc.ioistatus, "action" : action},
						async: false,
						callback:function(r)	{
													if (r.message == 1)
													{

														if (action == 'ASK_TO_CREATE_LINKED_DOCUMENT') {

															let msg = '';

															if (me.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
															{	msg = 'Create the order document ?'
															}else if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
															{	msg = 'Create the delivery document ?'
															}else if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
															{
																if (!me.frm.doc.total_htva)
																{	msg = 'Create the invoice document ?'
																}else
																{
																	if (me.frm.doc.total_htva < 0)
																	{	msg = 'Create the credit note document ?'
																	}else
																	{	msg = 'Create the invoice document ?'
																	}
																}
															}


															frappe.confirm(	msg,
																			() => 	{
																						// action to perform if Yes is selected

																						method = me.path_user_change_status_action + '.ioi_user_change_status_action_delete';

																						frappe.call({  	method: method,
																										args: {"doctype" : me.frm.doctype, "name" : me.frm.doc.name, "to_status" : me.frm.doc.ioistatus, "action" : action},
																										async: false,
																										callback:function(r)	{ 	}
																						});

																						if (me.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
																						{
																							let bypass = false;
																							let bypass_mode = 0;

																							if ((!me.journal_quote_to_order_mode) || (me.journal_quote_to_order_mode == 0))
																							{	bypass = false;
																							}else
																							{	bypass = true;
																								bypass_mode = me.journal_quote_to_order_mode;
																							}

																							if (bypass)
																							{
																								if (me.frm.doc.line_detail)
																								{
																									for (var i=0; i < me.frm.doc.line_detail.length; i++)
																									{
																										if ((me.frm.doc.line_detail[i].option_id) && (me.frm.doc.line_detail[i].option_id.trim() != ''))
																										{
																											bypass = false;
																											break;
																										}
																									}
																								}
																							}

																							silicon_ioi.doctype.ioiSalesDocumentDocType.order_this_quote(true, '', '', bypass);

																						}else if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
																						{
																							let bypass = false;
																							let bypass_mode = 0;

																							if ((!me.journal_order_to_delivery_mode) || (me.journal_order_to_delivery_mode == 0))
																							{	bypass = false;
																							}else
																							{	bypass = true;
																								bypass_mode = me.journal_order_to_delivery_mode;
																							}

																							silicon_ioi.doctype.ioiSalesDocumentDocType.deliver_this_order(true, '', '', '', bypass, bypass_mode);

																						}else if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
																						{
																							let bypass = false;
																							let bypass_mode = 0;

																							if ((!me.journal_delivery_to_invoice_mode) || (me.journal_delivery_to_invoice_mode == 0))
																							{	bypass = false;
																							}else
																							{	bypass = true;
																								bypass_mode = me.journal_delivery_to_invoice_mode;

																							}


																							silicon_ioi.doctype.ioiSalesDocumentDocType.invoice_this_delivery(true, '', '', '', bypass, bypass_mode);
																						}




																					},
																			() => 	{
																						// action to perform if No is selected or if click outsite the modal screen
																						method = me.path_user_change_status_action + '.ioi_user_change_status_action_delete';

																						frappe.call({  	method: method,
																										args: {"doctype" : me.frm.doctype, "name" : me.frm.doc.name, "to_status" : me.frm.doc.ioistatus, "action" : action},
																										async: false,
																										callback:function(r)	{ 	}
																						});

																					}
															);
														}else if (action == 'INTERSITE_ASK_TO_CREATE_PR') {

															let msg = __('Do you want to prepare the purchase receipt for the other site ?');

															frappe.confirm(	msg,
																			() => 	{
																						// action to perform if Yes is selected
																						document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

																						let method = me.path_user_change_status_action + '.ioi_user_change_status_action_delete';

																						frappe.call({  	method: method,
																										args: {"doctype" : me.frm.doctype, "name" : me.frm.doc.name, "to_status" : me.frm.doc.ioistatus, "action" : action},
																										async: false,
																										callback:function(r)	{ 	}
																						});



																						method = me.path_sales_delivery + '.ioi_sales_delivery_intersite_prepare_purchases_receipt_for_other_site';

																						frappe.call({  	method: method,
																										args: {	"name" : me.frm.doc.name
																										},
																										async: false,
																										callback:function(r)	{

																											if (r.message.error != 0) {

																												frappe.msgprint({title: __("Message"), message: r.message.error_msg, indicator: "red"});
																												raise;


																											}else{

																												let msg = __("The purchases receipt") + ' ' + r.message.purchases_receipt_no + ' ' + __('has been created in the site of') + ' ' + me.frm.doc.intersite_destination_site_id;

																												frappe.confirm(	msg,
																																() => 	{
																																	window.location.reload();
																																},
																																() => 	{
																																	window.location.reload();
																																});

																											}

																										}
																						});



																					},
																			() => 	{
																						// action to perform if No is selected or if click outsite the modal screen
																						let method = me.path_user_change_status_action + '.ioi_user_change_status_action_delete';

																						frappe.call({  	method: method,
																										args: {"doctype" : me.frm.doctype, "name" : me.frm.doc.name, "to_status" : me.frm.doc.ioistatus, "action" : action},
																										async: false,
																										callback:function(r)	{ 	}
																						});

																					}
															);


														}

													}
						}
		});

	}



	// ***************************************************************************************************************************************
	// Update customer prices
	// **************************************************************************************************************************************
	update_customer_prices()
	{
		if (this.frm.is_dirty())
		{
			let me = this;
			let fct_callback = function () { me.do_update_customer_prices(); };
			this.frm.save('Save', fct_callback);
		}else
		{
			this.do_update_customer_prices();
		}
	}

	do_update_customer_prices()
	{
		let me = this;

		var z = new frappe.ui.Dialog({
			'title': __("Update customer prices"),
			'fields': [
				{'fieldname': 'html_update_customer_prices', 'fieldtype': 'HTML'}

			],
			primary_action_label: __('Update customer prices'),
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

				let go = true;

				if (document.getElementById('document_nb_record').value == 0)
				{
					go = false;
				}

				if ((go) && (document.getElementById('document_nb_record').value != 0))
				{	go = true;

					for (var i = 0; i < document.getElementById('document_nb_record').value; i++)
					{
						if (document.getElementById('condition_' + i.toString()))
						{
							if (document.getElementById('condition_' + i.toString()).value.trim() == '')
							{
								frappe.msgprint({title: __("Message"), message: __("Fill sales condition in all lines please"), indicator: "red"});
								go = false;
								break;
							}
						}
					}
				}

				if (go)
				{

					let method = me.path_sales_document + '.ioi_sales_document_update_customer_prices';

					frappe.call({  	method: method,
									args: {	"doctype": me.frm.doctype,
											"name" : me.frm.doc.name,
											"condition_lines" : document.getElementById('update_price_line_conditions').value
										},
									async: false,
									callback:function(r) {
															if (r.message.trim() != '')
															{	document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
																frappe.msgprint({title: __("Message"), message: r.message, indicator: "red"});
																raise;
															}else
															{
																frappe.msgprint({title: __("Message"), message: __("update customer prices terminated"), indicator: "blue"});
																z.hide();
															}
									 }
								});
				}else{
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
				}
			},
			secondary_action: function(){
				z.hide();
			}

		});

		if (document.getElementById('document_grid_detail'))
		{	document.getElementById('document_grid_detail').remove();
		}

		if (document.getElementById('document_grid_header'))
		{	document.getElementById('document_grid_header').remove();
		}

		if (document.getElementById('document_grid_header'))
		{	document.getElementById('document_grid_header').remove();
		}

		if (document.getElementById('sales_document_order_by'))
		{	document.getElementById('sales_document_order_by').remove();
		}

		if (document.getElementById('sales_document_order'))
		{	document.getElementById('sales_document_order').remove();
		}
		if (document.getElementById('ioi_sales_update_prices_content'))
		{	document.getElementById('ioi_sales_update_prices_content').remove();
		}

		if (document.getElementById('sales_document_data'))
		{	document.getElementById('sales_document_data').remove();
		}

		if (document.getElementById('update_price_condition_label'))
		{	document.getElementById('update_price_condition_label').remove();
		}

		if (document.getElementById('update_price_condition'))
		{	document.getElementById('update_price_condition').remove();
		}

		if (document.getElementById('bt_update_conditions'))
		{	document.getElementById('bt_update_conditions').remove();
		}

		if (document.getElementById('update_prices_param'))
		{	document.getElementById('update_prices_param').remove();
		}

		if (document.getElementById('update_price_line_conditions'))
		{	document.getElementById('update_price_line_conditions').remove();
		}

		let html = '';

		let condition = []

		html += '<select id="document_update_sales_conditions" class="input-with-feedback form-control bold;" style="display: none;"> ';

		let method = this.path_sales_document + '.ioi_sales_document_get_sales_condition'
		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{ 	html += '<option value=""></option> ';

													for (var i = 0; i < r.message.length; i++)
													{
														condition[i] = r.message[i].name;
														html += '<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
													}
												}
		});

		html += '</select> ';
		html += '<input type="hidden" id="update_price_line_conditions" value="">';

		html += '<div id="update_prices_param" style="overflow-x: auto; height:65px; width:100%;">';

		html += '	<div style="position: relative; top: 0px; left: 0px; width:250px;">';
		html += '		<label id="update_price_condition_label" style="position: absolute; top: 0px; left: 2px;">' + __("Sales condition") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 250px; height: 27px;"> ';
		html += '			<select id="update_price_condition" class="input-with-feedback form-control bold"> ';
		html += '				<option value=""></option> ';

		for (var i = 0; i < condition.length; i++)
		{
			if ((me.journal_sales_condition_id) && (me.journal_sales_condition_id.trim() != ''))
			{
				if (me.journal_sales_condition_id == condition[i])
				{		html += '<option value="' + condition[i] + '" selected>' + condition[i] + '</option> ';
				}else
				{		html += '<option value="' + condition[i] + '">' + condition[i] + '</option> ';
				}
			}else
			{			html += '<option value="' + condition[i] + '">' + condition[i] + '</option> ';
			}

		}

		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		// Update button
		html += '	<div style="position: relative; top: 25px; left: 260px; width:200px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="bt_update_conditions" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="">' + __("Fill lines") + '</button>';
		html += '		</div>';
		html += '	</div>';

		html += '</div>';

		html += '<div style="overflow-x: auto; height:10px; width:100%;"></div>';

		html += '<div id="sales_document_data" style="overflow: auto; overflow-x: auto; height:800px">';
		html += '	<input type="hidden" id="sales_document_order_by" value="idx">';
		html += '	<input type="hidden" id="sales_document_order" value="asc">';
		html += '	<table id="document_grid_header" border=1 style="border: 1px solid #E8EAEB" width=1270px" data-custom-grid="true">';

		html += '	<tr style="height:30px">';

		html += '	<td id="col_idx" 				  width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Nr") 				 + '<label id="col_label_idx" style="width:30px; height:8px" align="right">&darr;</label></b></td>';
		html += '	<td id="col_sales_condition_id"   width=250px style="vertical-align: middle;"><b>&nbsp;' + __("Sales condition") 	 + '<label id="col_label_sales_condition_id" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '	<td id="col_item_id" 			  width=250px style="vertical-align: middle;"><b>&nbsp;' + __("Item") 				 + '<label id="col_label_item_id" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '	<td id="col_item_description"	  width=400px style="vertical-align: middle;"><b>&nbsp;' + __("Description") 		 + '<label id="col_label_item_description" style="width:30px; height:8px" align="right"></label></b></td>';

		if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE')
		{	html += '	<td id="col_qt" 		  		  width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Quoted qty") 		 + '<label id="col_label_qt" style="width:30px; height:8px" align="right"></label></b></td>';
		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER')
		{	html += '	<td id="col_qt" 		  		  width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Ordered qty") 		 + '<label id="col_label_qt" style="width:30px; height:8px" align="right"></label></b></td>';
		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
		{	html += '	<td id="col_qt" 		  		  width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Invoice qty") 		 + '<label id="col_label_qt" style="width:30px; height:8px" align="right"></label></b></td>';
		}
		html += '	<td id="col_unit_price" 			  width=150px style="vertical-align: middle;"><b>&nbsp;' + __("Unit price") 				 + '<label id="col_label_unit_price" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '	</tr>';
		html += '	</table>';
		html += '	<div id="ioi_sales_update_prices_content" style="overflow-x: auto; height:771px; width:1290px;"></div>';
		html += '</div>'


		let form_width = '52%';

		z.fields_dict.html_update_customer_prices.$wrapper.html(html);

		z.$wrapper.find('.modal-dialog').css("max-width", form_width).css("width", form_width);
		z.$wrapper.find('.modal-dialog').css("max-height", "50%").css("height", "50%");
		z.show();

		silicon_ioi.doctype.ioiSalesDocumentDocType.sleep_static(200).then(() => {

			document.getElementById('col_idx').onclick = silicon_ioi.doctype.ioiSalesDocumentDocType.update_customer_prices_col_click;
			document.getElementById('col_idx').onmouseover = silicon_ioi.doctype.ioiSalesDocumentDocType.update_customer_prices_col_mouse_over;
			document.getElementById('col_idx').onmouseleave = silicon_ioi.doctype.ioiSalesDocumentDocType.update_customer_prices_col_mouse_leave;

			document.getElementById('col_sales_condition_id').onclick = silicon_ioi.doctype.ioiSalesDocumentDocType.update_customer_prices_col_click;
			document.getElementById('col_sales_condition_id').onmouseover = silicon_ioi.doctype.ioiSalesDocumentDocType.update_customer_prices_col_mouse_over;
			document.getElementById('col_sales_condition_id').onmouseleave = silicon_ioi.doctype.ioiSalesDocumentDocType.update_customer_prices_col_mouse_leave;

			document.getElementById('col_item_id').onclick = silicon_ioi.doctype.ioiSalesDocumentDocType.update_customer_prices_col_click;
			document.getElementById('col_item_id').onmouseover = silicon_ioi.doctype.ioiSalesDocumentDocType.update_customer_prices_col_mouse_over;
			document.getElementById('col_item_id').onmouseleave = silicon_ioi.doctype.ioiSalesDocumentDocType.update_customer_prices_col_mouse_leave;

			document.getElementById('col_item_description').onclick = silicon_ioi.doctype.ioiSalesDocumentDocType.update_customer_prices_col_click;
			document.getElementById('col_item_description').onmouseover = silicon_ioi.doctype.ioiSalesDocumentDocType.update_customer_prices_col_mouse_over;
			document.getElementById('col_item_description').onmouseleave = silicon_ioi.doctype.ioiSalesDocumentDocType.update_customer_prices_col_mouse_leave;

			document.getElementById('col_qt').onclick = silicon_ioi.doctype.ioiSalesDocumentDocType.update_customer_prices_col_click;
			document.getElementById('col_qt').onmouseover = silicon_ioi.doctype.ioiSalesDocumentDocType.update_customer_prices_col_mouse_over;
			document.getElementById('col_qt').onmouseleave = silicon_ioi.doctype.ioiSalesDocumentDocType.update_customer_prices_col_mouse_leave;

			document.getElementById('col_unit_price').onclick = silicon_ioi.doctype.ioiSalesDocumentDocType.update_customer_prices_col_click;
			document.getElementById('col_unit_price').onmouseover = silicon_ioi.doctype.ioiSalesDocumentDocType.update_customer_prices_col_mouse_over;
			document.getElementById('col_unit_price').onmouseleave = silicon_ioi.doctype.ioiSalesDocumentDocType.update_customer_prices_col_mouse_leave;

			document.getElementById('bt_update_conditions').onclick = silicon_ioi.doctype.ioiSalesDocumentDocType.update_customer_prices_update_conditions_line;

			silicon_ioi.doctype.ioiSalesDocumentDocType.update_customer_prices_search();
		});


	}

	// ***************************************************************************************************************************************
	// Empty table row
	// ***************************************************************************************************************************************
	static update_customer_prices_empty_table_row()
	{
		let html = '';
		html += '<table id="document_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=1270px">';
		html += '<tr style="height:30px">';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=400px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
		html += '</tr>';
		html += '</table>';

		return html;
	}

	// ***************************************************************************************************************************************
	// Customer price : Search
	// ***************************************************************************************************************************************
	static update_customer_prices_search()
	{
		if (document.getElementById('document_nb_record'))
		{
			for (var i = 0; i < document.getElementById('document_nb_record').value; i++)
			{
				if (document.getElementById('name_' + i.toString()))
				{	document.getElementById('name_' + i.toString()).remove();
				}

				if (document.getElementById('idx_' + i.toString()))
				{	document.getElementById('idx_' + i.toString()).remove();
				}

				if (document.getElementById('condition_' + i.toString()))
				{	document.getElementById('condition_' + i.toString()).remove();
				}
			}

			document.getElementById('document_nb_record').remove();
		}

		let html = '';

		let manual_found = false

		let path_sales_document = 'silicon_ioi.common.sales_document';

		let method = path_sales_document + '.ioi_sales_document_get_document_detail';

		frappe.call({  	method: method,
						args: {	"doctype": cur_frm.doctype,
								"name" : cur_frm.doc.name,
								"orderby": document.getElementById('sales_document_order_by').value,
								"order": document.getElementById('sales_document_order').value,
							},
						async: false,
						callback:function(r)	{
													if (r.message.length > 0)
													{
														html += '<table id="document_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=1270px">';

														let cpt = 0;

														for (var i = 0; i < r.message.length; i++)
														{
															html += '<tr style="height:30px">';
															html += '<input type="hidden" id="name_' + i.toString() + '" value="' + r.message[i].name + '">';
															html += '<input type="hidden" id="idx_' + i.toString() + '" value="' + r.message[i].idx + '">';

															html += '<td width=100px style="vertical-align: middle;" align="right">' + r.message[i].idx + '&nbsp;</td>'

															if (r.message[i].sales_condition_id.toUpperCase() == silicon_ioi.doctype.ioiSalesDocumentDocType.static_customer_manual_sales_condition_id)
															{	manual_found = true;
																html += '<td width=250px style="vertical-align: middle;">';

																html += '<select id="condition_' + i.toString() + '" class="input-with-feedback form-control bold" style="height:28px"> ';

																for(var j = 0; j < document.getElementById('document_update_sales_conditions').length; j++)
																{
																	if (r.message[i].sales_condition_id == document.getElementById('document_update_sales_conditions')[j].value)
																	{
																		html += '<option value="' + document.getElementById('document_update_sales_conditions')[j].value + '" selected>';
																	}else
																	{	html += '<option value="' + document.getElementById('document_update_sales_conditions')[j].value + '">';

																	}
																	html += document.getElementById('document_update_sales_conditions')[j].value + '</option> ';
																}
																html += '</select> ';

																html += '</td>'
															}else
															{	html += '<td width=250px style="vertical-align: middle;">&nbsp;' + r.message[i].sales_condition_id + '</td>'
															}

															if (r.message[i].item_id != null)
															{	html += '<td width=250px style="vertical-align: middle;">&nbsp;' + r.message[i].item_id + '</td>'
															}else
															{	html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>'
															}

															if (r.message[i].item_description != null)
															{	html += '<td width=400px style="vertical-align: middle;">&nbsp;' + r.message[i].item_description + '</td>'
															}else
															{	html += '<td width=400px style="vertical-align: middle;">&nbsp;</td>'
															}

															if ((r.message[i].qt != null) && (r.message[i].qt != 0))
															{	html += '<td width=120px style="vertical-align: middle;" align="right">' + r.message[i].qt + '&nbsp;</td>'
															}else
															{	html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>'
															}

															if ((r.message[i].unit_price != null) && (r.message[i].unit_price != 0))
															{	html += '<td width=150px style="vertical-align: middle;" align="right">' + r.message[i].unit_price + '&nbsp;' + r.message[i].currency_id + '&nbsp;</td>'
															}else
															{	html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>'
															}


															html += '</tr>';

															cpt++;
														}

														html += '<input type="hidden" id="document_nb_record" value="' + cpt.toString() + '">';
														html += '</table>';

													}else
													{
														html += '<input type="hidden" id="document_nb_record" value="0">';
														html += silicon_ioi.doctype.ioiSalesDocumentDocType.update_customer_prices_empty_table_row();
													}

												}
		});

		document.getElementById('ioi_sales_update_prices_content').innerHTML = html;

		if (!manual_found)
		{
			if (document.getElementById('update_prices_param'))
			{
				document.getElementById('update_prices_param').hidden = true;
			}
		}

		silicon_ioi.doctype.ioiSalesDocumentDocType.sleep_static(200).then(() => {


			for (var i=0; i < document.getElementById('document_nb_record').value; i++)
			{
				if (document.getElementById('condition_' + i.toString()))
				{	document.getElementById('condition_' + i.toString()).onchange = silicon_ioi.doctype.ioiSalesDocumentDocType.update_customer_prices_update_condition_change;

					let s = document.getElementById('update_price_line_conditions').value;

					if (s.indexOf(document.getElementById('name_' + i.toString()).value) != -1)
					{
						s = s.substring(s.indexOf(document.getElementById('name_' + i.toString()).value), s.length);
						s = s.substring(s.indexOf('|')+1, s.length);
						s = s.substring(0, s.length);
						s = s.substring(0, s.indexOf(';'))

						document.getElementById('condition_' + i.toString()).value = s;
					}
				}
			}

		});
	}

	// ***************************************************************************************************************************************
	// Item Search : Column click
	// ***************************************************************************************************************************************
	static update_customer_prices_col_click()
	{
		let s = this.id;

		s = s.substring(4, this.id.length);

		document.getElementById('col_label_idx').innerHTML = '';
		document.getElementById('col_label_sales_condition_id').innerHTML = '';
		document.getElementById('col_label_item_id').innerHTML = '';
		document.getElementById('col_label_item_description').innerHTML = '';
		document.getElementById('col_label_qt').innerHTML = '';
		document.getElementById('col_label_unit_price').innerHTML = '';

		if (document.getElementById('sales_document_order_by').value.toUpperCase() == s.toUpperCase())
		{
			if (document.getElementById('sales_document_order').value == 'desc')
			{	document.getElementById('sales_document_order').value = 'asc';
				document.getElementById('col_label_' + s).innerHTML = '&darr;';
			}else
			{	document.getElementById('sales_document_order').value = 'desc';
				document.getElementById('col_label_' + s).innerHTML = '&uarr;';
			}
		}else
		{	document.getElementById('sales_document_order_by').value = s;
			document.getElementById('sales_document_order').value = 'desc';
			document.getElementById('col_label_' + s).innerHTML = '&uarr;';
		}


		silicon_ioi.doctype.ioiSalesDocumentDocType.update_customer_prices_search();
	}

	// ***************************************************************************************************************************************
	// Column mouse over
	// ***************************************************************************************************************************************
	static update_customer_prices_col_mouse_over()
	{
		this.style.cursor = 'pointer';
	}

	// ***************************************************************************************************************************************
	// Column mouse leave
	// ***************************************************************************************************************************************
	static update_customer_prices_col_mouse_leave()
	{
		this.style.cursor = 'none';
	}

	// ***************************************************************************************************************************************
	// Update condition in line
	// ***************************************************************************************************************************************
	static update_customer_prices_update_conditions_line()
	{
		if (document.getElementById('document_nb_record').value == 0)
		{
			return false;
		}

		let can_update = false;

		for (var i = 0; i < document.getElementById('document_nb_record').value; i++)
		{
			if (document.getElementById('condition_' + i.toString()))
			{
				can_update = true;
				break;
			}
		}

		if (can_update)
		{
			for (var i = 0; i < document.getElementById('document_nb_record').value; i++)
			{
				if (document.getElementById('condition_' + i.toString()))
				{
					document.getElementById('condition_' + i.toString()).value = document.getElementById('update_price_condition').value;

				}

			}

			silicon_ioi.doctype.ioiSalesDocumentDocType.update_customer_prices_update_condition_change();
		}

	}

	// ***************************************************************************************************************************************
	// save all [ name | condtion_id ]
	// ***************************************************************************************************************************************
	static update_customer_prices_update_condition_change()
	{
		document.getElementById('update_price_line_conditions').value = '';

		let s = '';

		for (var i = 0; i < document.getElementById('document_nb_record').value; i++)
		{
			if (document.getElementById('condition_' + i.toString()))
			{
				s += document.getElementById('name_' + i.toString()).value + '|' + document.getElementById('condition_' + i.toString()).value + ';';
			}
		}

		document.getElementById('update_price_line_conditions').value = s;
	}



	// ***************************************************************************************************************************************
	// Change Index pivot date and update lines
	// **************************************************************************************************************************************
	change_index_pivot_date_and_update_lines()
	{
		if (this.frm.is_dirty())
		{
			let me = this;
			let fct_callback = function () { me.do_change_index_pivot_date_and_update_lines(); };
			this.frm.save('Save', fct_callback);
		}else
		{
			this.do_change_index_pivot_date_and_update_lines();
		}
	}

	do_change_index_pivot_date_and_update_lines()
	{
		let me = this;

		var z = new frappe.ui.Dialog({
			'title': __("Change index pivot date and update lines"),
			'fields': [
				{'fieldname': 'html_index_pivot_date', 'fieldtype': 'HTML'}

			],
			primary_action_label: __('Update'),
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

				let go = true;

				if ((!document.getElementById('pivot_date_selected')) || ((document.getElementById('pivot_date_selected')) && (document.getElementById('pivot_date_selected').value == '')))
				{	document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
					frappe.msgprint({title: __("Message"), message: __("Index pivot date is mandatory"), indicator: "red"});
					go = false;
				}

				if (go)
				{

					let method = me.path_sales_document + '.ioi_sales_document_change_index_pivot_date_and_update_lines';

					frappe.call({  	method: method,
									args: {	"doctype": me.frm.doctype,
											"name" : me.frm.doc.name,
											"index_pivot_date" : document.getElementById('pivot_date_selected').value
										},
									async: false,
									callback:function(r) {
															if (r.message.error != 0)
															{	document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
																frappe.msgprint({title: __("Message"), message: r.message.error_msg, indicator: "red"});
																raise;
															}else
															{
																z.hide();
																window.location.reload();
															}
									 }
								});

				}else{
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
				}
			},
			secondary_action: function(){
				z.hide();
			}

		});


		if (document.getElementById('pivot_date_selected_label'))
		{	document.getElementById('pivot_date_selected_label').remove();
		}

		if (document.getElementById('pivot_date_selected'))
		{	document.getElementById('pivot_date_selected').remove();
		}

		let html = '';

		html += '<div style="height:60px;">';
		html += '	<div style="position: relative; top: 0px; left: 0px; width:450px;">';
		html += '		<label id="pivot_date_selected_label" style="position: absolute; top: 8px; left: 2px;">' + __("Index pivot date") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 0px; left: 150px; width: 150px; height: 30px;"> ';
		html += '			<input id="pivot_date_selected" type="date" class="input-with-feedback form-control bold" value="' + this.frm.doc.index_pivot_date + '">';
		html += '		</div>';
		html += '	</div>';
		html += '</div>';


		z.fields_dict.html_index_pivot_date.$wrapper.html(html);
		z.$wrapper.find('.modal-dialog').css("max-width", '450px').css("width", '450px');
		z.show();

	}

	update_line_prices()
	{
		if (!this.frm.doc.line_detail) {
			frappe.msgprint({title: __("Message"), message: __("No line to update"), indicator: "blue"});
			return false;
		}

		this.frm.refresh_field('line_detail');

		let found = false;

		for (var i = 0; i < this.frm.doc.line_detail.length; i++)
		{
			if ((this.frm.doc.line_detail[i].item_id) && (this.frm.doc.line_detail[i].item_id.trim() != ''))
			{
				if ((this.frm.doc.line_detail[i].sales_condition_id) && (this.frm.doc.line_detail[i].sales_condition_id != this.customer_manual_sales_condition_id))
				{
					found = true;
					break;
				}
			}
		}

		if (!found) {
			frappe.msgprint({title: __("Message"), message: __("No line (sales contition not MANUAL) to update"), indicator: "blue"});
			return false;
		}

		if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') {

			if (this.frm.doc.valid_date) {

				let valid_date = this.frm.doc.valid_date;
				valid_date = valid_date.replaceAll('-', '');

				let date = new Date();

				let day = date.getDate();

				if (day < 10) {
					day = '0' + day.toString();
				}

				let month = date.getMonth() + 1;

				if (month < 10) {
					month = '0' + month.toString();
				}

				let year = date.getFullYear();

				let currentDate = `${year}${month}${day}`;

				if (parseInt(valid_date) < parseInt(currentDate)) {

					let me = this;

					let title = 'Validity date';
					let bt_primary_caption = 'Ok'


					var d = new frappe.ui.Dialog({
						'title': title,
						'fields': [
							{'fieldname': 'html_extend_validity_date', 'fieldtype': 'HTML'}
						],
						primary_action_label: bt_primary_caption,
						secondary_action_label: __('Cancel'),
						primary_action: function(){

							if (document.getElementById('html_extend_validity_date_cb').checked) {

								if (document.getElementById('html_extend_validity_date_new_date').value.toString().trim() == '') {
									frappe.msgprint({title: __("Message"), message: __("Date is mandatory"), indicator: "red"});
									return false;
								}

								d.hide();

								me.do_update_line_prices(true, document.getElementById('html_extend_validity_date_new_date').value);
							}else{
								d.hide();
								me.do_update_line_prices(false, null);
							}
						},
						secondary_action: function(){
							d.hide();
						}

					});

					if (document.getElementById('html_extend_validity_date_cb')) {
						document.getElementById('html_extend_validity_date_cb').remove();
					}

					if (document.getElementById('html_extend_validity_date_cb_label')) {
						document.getElementById('html_extend_validity_date_cb_label').remove();
					}

					if (document.getElementById('html_extend_validity_date_new_date_label')) {
						document.getElementById('html_extend_validity_date_new_date_label').remove();
					}

					if (document.getElementById('html_extend_validity_date_new_date')) {
						document.getElementById('html_extend_validity_date_new_date').remove();
					}

					let current_date = new Date();
					current_date.setDate(current_date.getDate() + parseInt(this.journal_quotes_valid_delay));

					let html = '';

					html += '<div style="overflow: auto; overflow-x: hidden; height:90px; width: 300px">';

					html += '	<div style="position: relative; top: 0px; left: 0px; width:300px">';

					html += '		<div class="control-input" style="position: absolute; top: 0px; left: 2px; width: 300px; height: 25px;"> ';
					html += '			<input id="html_extend_validity_date_cb" type="checkbox" class="input-with-feedback form-control bold" checked>';
					html += '		</div>';
					html += '		<label id="html_extend_validity_date_cb_label" style="position: absolute; top: -2px; left: 22px;">' + __("Extend the validity date") + '</label>';
					html += '	</div>';

					html += '	<div style="position: relative; top: 25px; left: 0px; width:300px">';
					html += '		<label id="html_extend_validity_date_new_date_label" style="position: absolute; top: 0px; left: 2px;">' + __("Date") + '</label>';
					html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 250px; height: 25px;"> ';
					html += '			<input id="html_extend_validity_date_new_date" type="date" class="input-with-feedback form-control bold" value="">';
					html += '		</div>';
					html += '	</div>';

					html += '</div>';


					d.fields_dict.html_extend_validity_date.$wrapper.html(html);
					d.$wrapper.find('.modal-dialog').css("max-width", "50%").css("width", "300px");
					d.$wrapper.find('.modal-dialog').css("max-height", "50%").css("height", "50%");

					d.show();

					this.sleep_ms(300).then(() => {


						let date = current_date;

						let day = date.getDate();

						if (day < 10) {
							day = '0' + day.toString();
						}

						let month = date.getMonth() + 1;

						if (month < 10) {
							month = '0' + month.toString();
						}

						let year = date.getFullYear();

						let currentDate = `${year}-${month}-${day}`;

						document.getElementById('html_extend_validity_date_new_date').value = currentDate;
					})


				}else{
					this.do_update_line_prices(false, null);
				}
			}else{
				this.do_update_line_prices(false, null);
			}
		}else{
			this.do_update_line_prices(false, null);
		}
	}

	do_update_line_prices(update_valid_date = false, new_valid_date = null)
	{
		let was_browse = false;

		if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') {
			if (update_valid_date) {

				if (!this.frm.is_dirty())
				{
					was_browse = true;
					this.frm.dirty();
				}

				this.frm.doc.valid_date = new_valid_date;
				this.frm.refresh_field('valid_date');
			}
		}

		for (var i = 0; i < this.frm.doc.line_detail.length; i++)
		{
			if ((this.frm.doc.line_detail[i].item_id) && (this.frm.doc.line_detail[i].item_id.trim() != ''))
			{
				if ((this.frm.doc.line_detail[i].sales_condition_id) && (this.frm.doc.line_detail[i].sales_condition_id != this.customer_manual_sales_condition_id))
				{
					this.frm.set_intro('', '');

					if (!this.frm.is_dirty())
					{
						was_browse = true;
						this.frm.dirty();
					}

					let script_manager = this.frm.fields_dict['line_detail'].grid.grid_rows[i].frm.script_manager;

					script_manager.trigger('item_id', this.frm.fields_dict['line_detail'].grid.doctype, this.frm.fields_dict['line_detail'].grid.grid_rows[i].doc.name);
				}
			}
		}

		this.frm.refresh_field('line_detail');

		if (was_browse)
		{
			this.frm.save();
		}

		frappe.msgprint({title: __("Message"), message: __("Lines are updated"), indicator: "blue"});

	}

	static assign_event_on_grid_idx()
	{

		if (cur_frm.doc.line_detail)
		{
			for (var i = 0; i < cur_frm.doc.line_detail.length; i++)
			{
				if (cur_frm.fields_dict['line_detail'].grid)
				{
					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i])
					{
						let idx = i;

						let fct_enter = function() {
							silicon_ioi.doctype.ioiSalesDocumentDocType.display_histo_documents(idx);
						}

						cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[1].onmouseenter = fct_enter;
					}
				}

			}
		}


		silicon_ioi.doctype.ioiSalesDocumentDocType.sleep_static(500).then(() => {

			silicon_ioi.doctype.ioiSalesDocumentDocType.assign_event_on_grid_idx();
		});


	}

	static display_histo_documents(idx)
	{
		if (cur_frm.fields_dict['line_detail'].grid) {

			if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx])	{

				let order_customer_id = '';

				if ((cur_frm.doc.order_customer_id) && (cur_frm.doc.order_customer_id.trim() != '')) {
					order_customer_id = cur_frm.doc.order_customer_id;
				}

				if (order_customer_id == '') {
					return false;
				}

				let document_date = '';

				if ((cur_frm.doc.document_date) && (cur_frm.doc.document_date.trim() != '')) {
					document_date = cur_frm.doc.document_date;
				}

				if (document_date == '') {
					return false;
				}

				let item_id = ''

				if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['item_id']) {

					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['item_id'].trim() != '') {
						item_id = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['item_id'];
					}
				}

				let manufacturer_ref = ''

				if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['manufacturer_ref']) {

					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['manufacturer_ref'].trim() != '') {
						manufacturer_ref = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['manufacturer_ref'];
					}
				}

				if ((item_id == '') && (manufacturer_ref == '')) {
					return false;
				}


				let method = 'silicon_ioi.common.sales_document.ioi_sales_document_get_histo_quote_order';

				frappe.call({  	method: method,
								args: {	"order_customer_id": order_customer_id,
										"document_date": document_date,
										"item_id": item_id,
										"manufacturer_ref": manufacturer_ref,
										"name" : cur_frm.doc.name
								},
								async: false,
								callback:function(r)	{
															let html = '';

															if (r.message.length > 0) {

																html += '<table width=1105px>';
																html += '<tr height=20px>';
																html += '<td width=95px><u><b>' + __('Doc. date') + '</b></u></td>';
																html += '<td width=130px><u><b>' + __('Sales condition') + '</b></u></td>';
																html += '<td width=250px><u><b>' + __('Item / Manuf. catalog') + '</b></u></td>';
																html += '<td width=100px align="right"><u><b>' + __('Qty') + '</b></u>&nbsp;</td>';
																html += '<td width=120px align="right"><u><b>' + __('Unit price') + '</b></u>&nbsp;</td>';
																html += '<td width=80px align="right"><u><b>' + __('Base disc.') + '</b></u>&nbsp;</td>';
																html += '<td width=80px align="right"><u><b>' + __('Extra disc.') + '</b></u>&nbsp;</td>';
																html += '<td width=250px><u><b>' + __('Document') + '</b></u></td>';
																html += '</tr>';

																for (var i = 0; i < r.message.length; i++) {

																	html += '<tr height=20px>';
																	html += '<td width=95px>' + r.message[i].document_date + '</td>';
																	html += '<td width=130px>' + r.message[i].sales_condition_id + '</td>';

																	if ((r.message[i].item_id) && (r.message[i].item_id.trim() != '')) {
																		html += '<td width=250px>' + r.message[i].item_id + '</td>';
																	}else{
																		html += '<td width=250px>' + r.message[i].manufacturer_ref + '</td>';
																	}

																	if (r.message[i].qty != 0) {
																		html += '<td width=100px align="right">' + r.message[i].qty.toFixed(2) + '&nbsp;</td>';
																	}else{
																		html += '<td width=100px>&nbsp;</td>';
																	}

																	if (r.message[i].unit_price != 0) {
																		html += '<td width=120px align="right">' + r.message[i].unit_price.toFixed(2) + '&nbsp;</td>';
																	}else{
																		html += '<td width=120px>&nbsp;</td>';
																	}

																	if (r.message[i].base_discount != 0) {
																		html += '<td width=80px align="right">' + r.message[i].base_discount.toFixed(2) + '&nbsp;%&nbsp;</td>';
																	}else{
																		html += '<td width=80px>&nbsp;</td>';
																	}

																	if (r.message[i].extra_discount != 0) {
																		html += '<td width=80px align="right">' + r.message[i].extra_discount.toFixed(2) + '&nbsp;%&nbsp;</td>';
																	}else{
																		html += '<td width=80px>&nbsp;</td>';
																	}

																	html += '<td width=250px>' + r.message[i].name + '</td>';
																	html += '</tr>';

																}

																html += '</table>';

																silicon_ioi.ioiCommon.show_grid_info_popup(html)
															}
								}
				});
			}
		}

	}

	static assign_event_on_grid_first_column()
	{
		if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') ||
			(cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE')) {

			if (cur_frm.doc.line_detail)
			{
				for (var i = 0; i < cur_frm.doc.line_detail.length; i++)
				{
					if (cur_frm.fields_dict['line_detail'].grid)
					{
						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i])
						{
							if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[0])
							{
								let idx = i;

								let fct_enter = function() {
									silicon_ioi.doctype.ioiSalesDocumentDocType.display_custom_info(idx);
								}

								cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[0].onmouseenter = fct_enter;
							}
						}
					}

				}
			}


			silicon_ioi.doctype.ioiSalesDocumentDocType.sleep_static(500).then(() => {

				silicon_ioi.doctype.ioiSalesDocumentDocType.assign_event_on_grid_first_column();
			});
		}
	}

	static display_custom_info(idx)
	{
		if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') ||
			(cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE')) {

			if (cur_frm.fields_dict['line_detail'].grid)
			{
				if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx])
				{
					let name = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['name'];

					let method = 'silicon_ioi.common.common.ioi_common_get_tooltip';

					frappe.call({  	method: method,
									args: {"tooltip_id": 1, "doctype": cur_frm.doctype, "parent": cur_frm.doc.name, "name": name},
									async: false,
									callback:function(r)	{
																let html = r.message.content;
																let time_display = r.message.time_display;

																if (html.trim() != '') {
																	silicon_ioi.ioiCommon.show_grid_info_popup(html, time_display);
																}
									}
					});
				}
			}
		}
	}


	static assign_event_on_grid_qty()
	{
		let qty_field = '';

		if (cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') {
			qty_field = 'quoted_qty';
		}else if (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER')
		{	qty_field = 'ordered_qty';
		}else if (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
		{	qty_field = 'delivered_qty';
		}else if (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE')
		{	qty_field = 'invoiced_qty';
		}

		if (qty_field == '') {
			return;
		}

		if (cur_frm.doc.line_detail)
		{
			for (var i = 0; i < cur_frm.doc.line_detail.length; i++)
			{
				if (cur_frm.fields_dict['line_detail'].grid)
				{
					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i])
					{
						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[qty_field])
						{
							let idx = i;

							let fct_enter = function() {
								silicon_ioi.doctype.ioiSalesDocumentDocType.display_stock_info(idx);
							}

							cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[qty_field][0].onmouseenter = fct_enter;
						}
					}
				}

			}
		}


		silicon_ioi.doctype.ioiSalesDocumentDocType.sleep_static(500).then(() => {

			silicon_ioi.doctype.ioiSalesDocumentDocType.assign_event_on_grid_qty();
		});


	}

	static display_stock_info(idx)
	{
		if (cur_frm.fields_dict['line_detail'].grid)
		{
			if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx])
			{
				if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['item_id'])
				{
					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['item_id'].trim() != '')
					{
						let item_id = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['item_id']

						let warehouse_id = ''

						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['warehouse_id'])
						{
							if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['warehouse_id'].trim() != '')
							{
								warehouse_id = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['warehouse_id'];
							}else
							{
								if (cur_frm.doc.warehouse_id)
								{
									if (cur_frm.doc.warehouse_id.trim() != '')
									{
										warehouse_id = cur_frm.doc.warehouse_id;
									}
								}
							}

						}else
						{
							if (cur_frm.doc.warehouse_id)
							{
								if (cur_frm.doc.warehouse_id.trim() != '')
								{
									warehouse_id = cur_frm.doc.warehouse_id;
								}
							}

						}


						let me = this;

						let method = 'silicon_ioi.ioi_wms.doctype.ioi_warehouse_stock.ioi_warehouse_stock.ioi_warehouse_stock_get_site_whs_stock_for_an_item';


						frappe.call({  	method: method,
										args: {"item_id": item_id, "site_id": cur_frm.doc.site_id, "warehouse_id": warehouse_id},
										async: false,
										callback:function(r)	{
																	let html = '';



																	if (r.message.length > 0) {

																		html += '<table width=560px>';

																		html += '<tr height=20px>';
																		html += '<td width=60px><u><b>' + __('Site') + '</b></u></td>';
																		html += '<td width=120px><u><b>' + __('Site qty') + '</b></u></td>';
																		html += '<td width=80px><u><b>' + __('Unit') + '</b></u></td>';
																		html += '<td width=100px><u><b>' + __('Whs') + '</b></u></td>';
																		html += '<td width=120px><u><b>' + __('Whs qty') + '</b></u></td>';
																		html += '<td width=80px><u><b>' + __('Unit') + '</b></u></td>';
																		html += '</tr>';

																		let is_obsolete = 0;

																		for (var i = 0; i < r.message.length; i++) {

																			for(var j = 0; j < r.message[i].length; j++) {

																				if (r.message[i][j].is_obsolete == 1) {
																					is_obsolete = 1;
																				}



																				html += '<tr height=20px>';

																				html += '<td width=60px>';

																				if (r.message[i][j].is_current == '1') {
																					html += '<b>';
																				}

																				html += r.message[i][j].site_id;

																				if (r.message[i][j].is_current == '1') {
																					html += '</b>';
																				}



																				html += '</td>';
																				html += '<td width=130px>';

																				if (r.message[i][j].is_current == '1') {
																					html += '<b>';
																				}

																				html += r.message[i][j].site_qty;

																				if (r.message[i][j].is_current == '1') {
																					html += '</b>';
																				}

																				html += '</td>';

																				html += '<td width=80px>';

																				if (r.message[i][j].is_current == '1') {
																					html += '<b>';
																				}

																				html += r.message[i][j].site_unit;

																				if (r.message[i][j].is_current == '1') {
																					html += '</b>';
																				}

																				html += '</td>';

																				html += '<td width=100px>';

																				if (r.message[i][j].is_current == '1') {
																					html += '<b>';
																				}

																				html +=r.message[i][j].whs_id;

																				if (r.message[i][j].is_current == '1') {
																					html += '</b>';
																				}

																				html += '</td>';

																				html += '<td width=130px>';

																				if (r.message[i][j].is_current == '1') {
																					html += '<b>';
																				}

																				html += r.message[i][j].whs_qty;

																				if (r.message[i][j].is_current == '1') {
																					html += '</b>';
																				}

																				html += '</td>';

																				html += '<td width=80px>';

																				if (r.message[i][j].is_current == '1') {
																					html += '<b>';
																				}

																				html += r.message[i][j].whs_unit;

																				if (r.message[i][j].is_current == '1') {
																					html += '</b>';
																				}

																				html += '</td>';

																				html += '</tr>';

																			}
																		}
																		html += '</table>';

																		if (is_obsolete == 1) {
																			let s = '';

																			s += '<div style="position: relative; top: 0px; left: 0px; width: 100%">';
																			s += '	<label style="position: absolute; top: 0px; left: 0px;"><font color="red">' + __("This item is obsolete") + '</font></label>';
																			s += '</div><br>';

																			html = s + html;
																		}
																	}

																	silicon_ioi.ioiCommon.show_grid_info_popup(html)
										}
						});

					}
				}else if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['manufacturer_ref']) {

					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['manufacturer_ref'].trim() != '') {

						let method = 'silicon_ioi.ioi_items.doctype.ioi_manufacturer_catalog.ioi_manufacturer_catalog.ioi_manufacturer_catalog_is_obsolete';


						frappe.call({  	method: method,
										args: {"name": cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['manufacturer_ref']},
										async: false,
										callback:function(r)	{

											let html = '';

											if (r.message == 1) {

												html += '<div style="position: relative; width: 100%; height:20px">';
												html += '	<label style="position: absolute; top: 0px; left: 0px;"><font color="red">' + __("This manufacturer catalog is obsolete") + '</font></label>';
												html += '</div>';

											}

											silicon_ioi.ioiCommon.show_grid_info_popup(html)
										}
									});
					}

				}

			}
		}


	}

	static assign_event_on_grid_unit_price()
	{
		let unit_field = 'unit_price';

		if (cur_frm.doc.line_detail)
		{
			for (var i = 0; i < cur_frm.doc.line_detail.length; i++)
			{
				if (cur_frm.fields_dict['line_detail'].grid)
				{
					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i])
					{
						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[unit_field])
						{
							let idx = i;

							let fct_enter = function() {
								silicon_ioi.doctype.ioiSalesDocumentDocType.display_margin_info(idx);
							}

							cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[unit_field][0].onmouseenter = fct_enter;
						}
					}
				}

			}
		}


		silicon_ioi.doctype.ioiSalesDocumentDocType.sleep_static(500).then(() => {

			silicon_ioi.doctype.ioiSalesDocumentDocType.assign_event_on_grid_unit_price();
		});


	}

	static assign_event_on_grid_description()
	{
		let description_field = 'item_description';

		if (cur_frm.doc.line_detail)
		{
			for (var i = 0; i < cur_frm.doc.line_detail.length; i++)
			{
				if (cur_frm.fields_dict['line_detail'].grid)
				{
					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i])
					{
						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[description_field])
						{
							let idx = i;

							let fct_enter = function() {
								silicon_ioi.doctype.ioiSalesDocumentDocType.display_description_info(idx);
							}

							cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[description_field][0].onmouseenter = fct_enter;
						}
					}
				}

			}
		}


		silicon_ioi.doctype.ioiSalesDocumentDocType.sleep_static(500).then(() => {

			silicon_ioi.doctype.ioiSalesDocumentDocType.assign_event_on_grid_description();
		});
	}

	static display_margin_info(idx) {
		if (silicon_ioi.doctype.ioiSalesDocumentDocType.show_margin == 1) {

			if (cur_frm.fields_dict['line_detail'].grid)
			{
				if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx])
				{
					let margin_alert = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['margin_alert'];
					let alert_margin_mode = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['alert_margin_mode'];

					if ((!margin_alert) || ((margin_alert) && ((margin_alert == 0) || (margin_alert == -1)))) {

						let v = '';
						let html = '';
						if ((!alert_margin_mode) || ((alert_margin_mode) && (alert_margin_mode == 0))) {
							v = __("Cost margin rate") + ' : ' + cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['cost_margin_rate'];
						}else{
							v = __("Sales margin rate") + ' : ' + cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['sales_margin_rate'];
						}
						if ((!alert_margin_mode) || ((alert_margin_mode) && (margin_alert == 0))) {
							html = '<table width=100%><tr height=20px><td>' + v + '</td></tr></table>';
						}else{
							html = '<table width=100%><tr height=20px><td><font color="orange">' + v + '</font></td></tr></table>';
						}

						silicon_ioi.ioiCommon.show_grid_info_popup(html)

						if (margin_alert == -2) {
							html = '<table width=100%><tr height=20px><td><font color="red">' + __("Margin error !") + '</font></td></tr></table>';

							silicon_ioi.ioiCommon.show_grid_info_popup(html)
						}

					}
				}
			}
		}
	}

	static display_description_info(idx)
	{
		if (cur_frm.fields_dict['line_detail'].grid)
		{
			if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx])
			{
				let item_id = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['item_id'];
				let manufacturer_ref = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['manufacturer_ref'];

				let what = ''
				let identification = ''

				if ((item_id) && (item_id.trim() != '')) {
					what = 'ITEM_ID';
					identification = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['item_id'];
				}else if ((manufacturer_ref) && (manufacturer_ref.trim() != '')) {
					what = 'MANUFACTURER_REF';
					identification = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['manufacturer_ref'];
				}

				if (what != '') {

					let method = 'silicon_ioi.common.sales_document.ioi_sales_document_tooltip_item_description';

					let data = {};

					frappe.call({  	method: method,
									args: {"what": what, "identification": identification},
									async: false,
									callback:function(r)	{
										data = r.message
									}
								});

					let html = '';

					if (data.customized == 0) {

						if (data.content.trim() != '') {

							html = '<table width=700px>';
							html += '<tr height=20px>';
							if (what == 'ITEM_ID')  {
								html += '<td width=250px><b><u>' + __("Item") + '</u></b></td>';
							}else{
								html += '<td width=250px><b><u>' + __("Manufacturer ref") + '</u></b></td>';
							}
							html += '<td width=450px><b><u>' + __("Description") + '</u></b></td>';
							html += '</tr>';
							html += '<tr height=20px>';
							html += '<td width=250px>' + identification + '</td>';
							html += '<td width=450px%>' + data.content + '</td>';
							html += '</tr>';
							html += '</table>';
						}
					}else{
						if (data.content.trim() != '') {
							html = data.content;
						}
					}

					if (html.trim() != '') {
						silicon_ioi.ioiCommon.show_grid_info_popup(html);
					}

				}

			}
		}
	}


	static assign_event_on_grid_base_discount()
	{
		let base_field = 'base_discount';

		if (cur_frm.doc.line_detail)
		{
			for (var i = 0; i < cur_frm.doc.line_detail.length; i++)
			{
				if (cur_frm.fields_dict['line_detail'].grid)
				{
					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i])
					{
						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[base_field])
						{
							let idx = i;

							let fct_enter = function() {
								silicon_ioi.doctype.ioiSalesDocumentDocType.display_margin_info(idx);
							}

							cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[base_field][0].onmouseenter = fct_enter;
						}
					}
				}

			}
		}


		silicon_ioi.doctype.ioiSalesDocumentDocType.sleep_static(500).then(() => {

			silicon_ioi.doctype.ioiSalesDocumentDocType.assign_event_on_grid_base_discount();
		});


	}


	static assign_event_on_grid_extra_discount()
	{

		let extra_field = 'extra_discount';

		if (cur_frm.doc.line_detail)
		{
			for (var i = 0; i < cur_frm.doc.line_detail.length; i++)
			{
				if (cur_frm.fields_dict['line_detail'].grid)
				{
					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i])
					{
						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[extra_field])
						{
							let idx = i;

							let fct_enter = function() {
								silicon_ioi.doctype.ioiSalesDocumentDocType.display_next_item_budget_discounts(idx);
							}

							cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[extra_field][0].onmouseenter = fct_enter;
						}
					}
				}

			}
		}


		silicon_ioi.doctype.ioiSalesDocumentDocType.sleep_static(500).then(() => {

			silicon_ioi.doctype.ioiSalesDocumentDocType.assign_event_on_grid_extra_discount();
		});
	}

	static assign_event_on_grid_free_part_qty()
	{
		let extra_field = 'free_part_qty';

		if (cur_frm.doc.line_detail)
		{
			for (var i = 0; i < cur_frm.doc.line_detail.length; i++)
			{
				if (cur_frm.fields_dict['line_detail'].grid)
				{
					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i])
					{
						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[extra_field])
						{
							let idx = i;

							let fct_enter = function() {
								silicon_ioi.doctype.ioiSalesDocumentDocType.display_margin_info(idx);
							}

							cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[extra_field][0].onmouseenter = fct_enter;
						}
					}
				}

			}
		}


		silicon_ioi.doctype.ioiSalesDocumentDocType.sleep_static(500).then(() => {

			silicon_ioi.doctype.ioiSalesDocumentDocType.assign_event_on_grid_free_part_qty();
		});


	}



	static assign_event_on_grid_item_id()
	{
		let extra_field = 'item_id';

		if (cur_frm.doc.line_detail)
		{
			for (var i = 0; i < cur_frm.doc.line_detail.length; i++)
			{
				if (cur_frm.fields_dict['line_detail'].grid)
				{
					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i])
					{
						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[extra_field])
						{
							let idx = i;

							let fct_enter = function() {
								silicon_ioi.doctype.ioiSalesDocumentDocType.display_item_info(idx);
							}

							cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[extra_field][0].onmouseenter = fct_enter;
						}
					}
				}

			}
		}


		silicon_ioi.doctype.ioiSalesDocumentDocType.sleep_static(500).then(() => {

			silicon_ioi.doctype.ioiSalesDocumentDocType.assign_event_on_grid_item_id();
		});


	}

	static display_item_info(idx)
	{
		if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') ||
			(cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE')) {

			if (cur_frm.fields_dict['line_detail'].grid)
			{
				if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx])
				{
					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['item_id'])
					{
						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['item_id'].trim() != '')
						{
							let item_id = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['item_id']


							let method = 'silicon_ioi.common.sales_document.ioi_sales_document_tooltip_item';


							frappe.call({  	method: method,
											args: {"doctype": cur_frm.doctype, "item_id": item_id, "document_language": cur_frm.doc.language},
											async: false,
											callback:function(r)	{
																		let html = '';

																		if (r.message.customized == 0) {

																			if (r.message.content.length > 0) {

																				if (r.message.content[0].obsolete == 1) {

																					html += '<div style="position: relative; top: 0px; left: 0px; width: 100%">';
																					html += '	<label style="position: absolute; top: 0px; left: 0px;"><font color="red">' + __("This item is obsolete") + '</font></label>';
																					html += '</div><br>';
																				}


																				html += '<table width=560px>';
																				html += '<tr height=20px>';
																				html += '<td width=120px><u>' + __("Item") + '</u></td>';
																				html += '<td width=440px>' + r.message.content[0].name + ' (' + r.message.content[0].item_description + ')</td>';
																				html += '</tr>';

																				html += '<tr height=20px>';
																				html += '<td width=120px><u>' + __("Family") + '</u></td>';
																				html += '<td width=440px>' + r.message.content[0].family_id + ' (' + r.message.content[0].family_description + ')</td>';
																				html += '</tr>';

																				html += '<tr height=20px>';
																				html += '<td width=120px><u>' + __("Type") + '</u></td>';
																				html += '<td width=440px>' + r.message.content[0].type_id + ' (' + r.message.content[0].type_description + ')</td>';
																				html += '</tr>';

																				html += '<tr height=20px>';
																				html += '<td width=120px><u>' + __("Group") + '</u></td>';
																				html += '<td width=440px>' + r.message.content[0].group_id + ' (' + r.message.content[0].group_description + ')</td>';
																				html += '</tr>';

																				html += '<tr height=20px>';
																				html += '<td width=120px><u>' + __("Subgroup") + '</u></td>';
																				html += '<td width=440px>' + r.message.content[0].subgroup_id + ' (' + r.message.content[0].subgroup_description + ')</td>';
																				html += '</tr>';


																				html += '</table>';
																			}
																		}else{
																			html = r.message.content
																		}

																		if (html.trim() != '') {
																			silicon_ioi.ioiCommon.show_grid_info_popup(html)
																		}
											}
							});

						}
					}
				}
			}
		}
	}


	static assign_event_on_grid_manufacturer_ref()
	{
		let extra_field = 'manufacturer_ref';

		if (cur_frm.doc.line_detail)
		{
			for (var i = 0; i < cur_frm.doc.line_detail.length; i++)
			{
				if (cur_frm.fields_dict['line_detail'].grid)
				{
					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i])
					{
						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[extra_field])
						{
							let idx = i;

							let fct_enter = function() {
								silicon_ioi.doctype.ioiSalesDocumentDocType.display_manufacturer_ref_info(idx);
							}

							cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[extra_field][0].onmouseenter = fct_enter;
						}
					}
				}

			}
		}


		silicon_ioi.doctype.ioiSalesDocumentDocType.sleep_static(500).then(() => {

			silicon_ioi.doctype.ioiSalesDocumentDocType.assign_event_on_grid_manufacturer_ref();
		});


	}

	static display_manufacturer_ref_info(idx)
	{
		if (cur_frm.fields_dict['line_detail'].grid)
		{
			if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx])
			{
				if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['manufacturer_ref'])
				{
					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['manufacturer_ref'].trim() != '')
					{
						let manufacturer_ref = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['manufacturer_ref']


						let method = 'silicon_ioi.common.sales_document.ioi_sales_document_tooltip_manufacturer_ref';


						frappe.call({  	method: method,
										args: {"doctype": cur_frm.doctype, "manufacturer_ref": manufacturer_ref, "document_language": cur_frm.doc.language},
										async: false,
										callback:function(r)	{
																	let html = '';

																	if (r.message.customized == 0) {

																		if (r.message.content.length > 0) {

																			if (r.message.content[0].obsolete == 1) {

																				html += '<div style="position: relative; top: 0px; left: 0px; width: 100%">';
																				html += '	<label style="position: absolute; top: 0px; left: 0px;"><font color="red">' + __("This manufacturer catalog is obsolete") + '</font></label>';
																				html += '</div><br>';
																			}


																			html += '<table width=560px>';
																			html += '<tr height=20px>';
																			html += '<td width=120px><u>' + __("Manuf. catalog") + '</u></td>';
																			html += '<td width=440px>' + r.message.content[0].name + ' (' + r.message.content[0].item_description + ')</td>';
																			html += '</tr>';

																			html += '<tr height=20px>';
																			html += '<td width=120px><u>' + __("Family") + '</u></td>';
																			html += '<td width=440px>' + r.message.content[0].family_id + ' (' + r.message.content[0].family_description + ')</td>';
																			html += '</tr>';

																			html += '<tr height=20px>';
																			html += '<td width=120px><u>' + __("Type") + '</u></td>';
																			html += '<td width=440px>' + r.message.content[0].type_id + ' (' + r.message.content[0].type_description + ')</td>';
																			html += '</tr>';

																			html += '<tr height=20px>';
																			html += '<td width=120px><u>' + __("Group") + '</u></td>';
																			html += '<td width=440px>' + r.message.content[0].group_id + ' (' + r.message.content[0].group_description + ')</td>';
																			html += '</tr>';

																			html += '<tr height=20px>';
																			html += '<td width=120px><u>' + __("Subgroup") + '</u></td>';
																			html += '<td width=440px>' + r.message.content[0].subgroup_id + ' (' + r.message.content[0].subgroup_description + ')</td>';
																			html += '</tr>';


																			html += '</table>';
																		}

																	}else{
																		html = r.message.content
																	}

																	if (html.trim() != '') {
																		silicon_ioi.ioiCommon.show_grid_info_popup(html)
																	}
										}
						});

					}
				}
			}
		}
	}

	static assign_event_on_grid_shipping_datetime()
	{
		if (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') {

			let shipping_datetime = 'shipping_datetime';

			if (cur_frm.doc.line_detail)
			{
				for (var i = 0; i < cur_frm.doc.line_detail.length; i++)
				{
					if (cur_frm.fields_dict['line_detail'].grid)
					{
						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i])
						{
							if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[shipping_datetime])
							{
								let idx = i;

								let fct_enter = function() {
									silicon_ioi.doctype.ioiSalesDocumentDocType.display_jit_links(idx);
								}

								cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[shipping_datetime][0].onmouseenter = fct_enter;
							}
						}
					}

				}
			}


			silicon_ioi.doctype.ioiSalesDocumentDocType.sleep_static(500).then(() => {

				silicon_ioi.doctype.ioiSalesDocumentDocType.assign_event_on_grid_shipping_datetime();
			});
		}
	}

	static display_jit_links(idx)
	{
		if (cur_frm.fields_dict['line_detail'].grid)
		{
			if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx])
			{
				let dc = cur_frm.doctype;
				let name = cur_frm.doc.name;
				let detail_name = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['name'];

				let html = silicon_ioi.doctype.ioiJITEngineLinkFrame.get_html_detail_scheduled_operations(name, detail_name);

				if (html.trim() != '') {
					silicon_ioi.ioiCommon.show_grid_info_popup(html);
				}


			}
		}
	}

	static assign_event_on_grid_item_budget_id()
	{
		let item_budget_id = 'item_budget_id';


		if (cur_frm.doc.line_detail)
		{
			for (var i = 0; i < cur_frm.doc.line_detail.length; i++)
			{
				if (cur_frm.fields_dict['line_detail'].grid)
				{
					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i])
					{
						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[item_budget_id])
						{
							let idx = i;

							let fct_enter = function() {
								silicon_ioi.doctype.ioiSalesDocumentDocType.display_next_item_budget_discounts(idx);
							}

							cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[item_budget_id][0].onmouseenter = fct_enter;
						}
					}
				}

			}
		}


		silicon_ioi.doctype.ioiSalesDocumentDocType.sleep_static(500).then(() => {

			silicon_ioi.doctype.ioiSalesDocumentDocType.assign_event_on_grid_item_budget_id();
		});

	}

	static display_next_item_budget_discounts(idx)
	{
		if (cur_frm.fields_dict['line_detail'].grid)
		{
			if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx])
			{
				let current_item_budget_id = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['item_budget_id'];

				if ((current_item_budget_id) && (current_item_budget_id.trim() != '')) {
					silicon_ioi.doctype.ioiSalesDocumentDocType.do_display_next_item_budget_discounts(current_item_budget_id);
				}else{

					let div = $("#grid_info_popup");

					if (div) {
						div.remove();
					}
				}

			}
		}
	}

	static do_display_next_item_budget_discounts(current_item_budget_id)
	{
		if ((!current_item_budget_id) || ((current_item_budget_id) && (current_item_budget_id.trim() == ''))) {
			return false;
		}


		if (silicon_ioi.doctype.ioiSalesDocumentDocType.static_use_triggered_budget_discount == 1) {

			let item_budget = []

			for (var i = 0; i < cur_frm.doc.line_detail.length; i++) {

				if (this.static_customer_manual_sales_condition_id != cur_frm.doc.line_detail[i].sales_condition_id) {

					if ((cur_frm.doc.line_detail[i].item_budget_id) && (cur_frm.doc.line_detail[i].item_budget_id.trim() != '')) {

						let init_extra_discount = false;

						if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER')) {
							init_extra_discount = true;
						}else if (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
							if ((!cur_frm.doc.line_detail[i].order_master_link) || ((cur_frm.doc.line_detail[i].order_master_link) && (cur_frm.doc.line_detail[i].order_master_link.trim() == ''))) {
								init_extra_discount = true;
							}
						}else if (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
							if (((!cur_frm.doc.line_detail[i].order_master_link) || ((cur_frm.doc.line_detail[i].order_master_link) && (cur_frm.doc.line_detail[i].order_master_link.trim() == ''))) &&
								((!cur_frm.doc.line_detail[i].delivery_master_link) || ((cur_frm.doc.line_detail[i].delivery_master_link) && (cur_frm.doc.line_detail[i].delivery_master_link.trim() == '')))) {
								init_extra_discount = true;
							}
						}

						if (init_extra_discount) {

							let has_detail_discount = 0

							let method = 'silicon_ioi.ioi_configuration.doctype.ioi_item_budget.ioi_item_budget.ioi_item_budget_has_detail_discounts';

							frappe.call({  	method: method,
											args: {"item_budget_id": cur_frm.doc.line_detail[i].item_budget_id, "currency_id": cur_frm.doc.currency_id},
											async: false,
											callback:function(r)	{
												has_detail_discount = r.message;
											}
							});

							if (has_detail_discount == 1) {

								let item_budget_found = false;

								for (var j = 0; j < item_budget.length; j++) {

									if ((item_budget[j].name == cur_frm.doc.line_detail[i].item_budget_id) && (item_budget[j].currency_id == cur_frm.doc.currency_id) ) {

										let value = parseFloat(cur_frm.doc.line_detail[i].value_line_doc_currency) * (100.00 / (100 - parseFloat(cur_frm.doc.line_detail[i].extra_discount)));

										item_budget[j].value = parseFloat(item_budget[j].value) + parseFloat(value);
										item_budget_found = true;
										break;
									}
								}

								if (!item_budget_found) {
									let value = parseFloat(cur_frm.doc.line_detail[i].value_line_doc_currency) * (100.00 / (100 - parseFloat(cur_frm.doc.line_detail[i].extra_discount)));
									item_budget[item_budget.length] = {"name": cur_frm.doc.line_detail[i].item_budget_id, "currency_id": cur_frm.doc.currency_id, "value": parseFloat(value)};
								}
							}
						}
					}
				}
			}



			if (item_budget.length > 0) {

				let method = 'silicon_ioi.ioi_configuration.doctype.ioi_item_budget.ioi_item_budget.ioi_item_budget_get_discounts_tooltip';

				let data = [];

				frappe.call({  	method: method,
								args: {"current_item_budget_id": current_item_budget_id, "item_budgets": item_budget},
								async: false,
								callback:function(r)	{
									data = r.message;
								}
				});


				if (data.length == 0) {
					return false;
				}

				if (document.getElementById('item_budget_discounts_table')) {

					for (var i = 0; i < document.getElementById('item_budget_discounts_table').rows.length; i++) {

						if (document.getElementById('item_budget_discounts_item_budget_id_' + i.toString())) {
							document.getElementById('item_budget_discounts_item_budget_id_' + i.toString()).remove();
						}

						if (document.getElementById('item_budget_discounts_currency_id_' + i.toString())) {
							document.getElementById('item_budget_discounts_currency_id_' + i.toString()).remove();
						}

						if (document.getElementById('item_budget_discounts_ordered_value_' + i.toString())) {
							document.getElementById('item_budget_discounts_ordered_value_' + i.toString()).remove();
						}

						if (document.getElementById('item_budget_discounts_next_discount_at_' + i.toString())) {
							document.getElementById('item_budget_discounts_next_discount_at_' + i.toString()).remove();
						}


						if (document.getElementById('item_budget_next_discounts_button_' + i.toString())) {
							document.getElementById('item_budget_next_discounts_button_' + i.toString()).remove();
						}


					}
					document.getElementById('item_budget_discounts_table').remove()

				}


				let html = '';

				html += '<table border="0" width=1020px>';
				html += '<tr height=30px>';
				html += '<td width=200px><p style="font-size: 12px;height:30px"><u>' + __("Discount category") + '</u></p></td>';
				html += '<td width=70px align="center"><p style="font-size: 12px;height:30px"><u>' + __("Currency") + '</u></p></td>';
				html += '<td width=80px align="right"><p style="font-size: 12px;height:30px"><u>' + __("Ordered value") + '</u></p></td>';
				html += '<td width=80px align="right"><p style="font-size: 12px;height:30px"><u>' + __("Discount at") + '</u></p></td>';
				html += '<td width=75px align="right"><p style="font-size: 12px;height:30px"><u>' + __("Granted discount") + '</u></p></td>';
				html += '<td width=70px align="right"><p style="font-size: 12px;height:30px"><u>' + __("You win") + '</u></p></td>';
				html += '<td width=80px align="right"><p style="font-size: 12px;height:30px"><u>' + __("Net value") + '</u></p></td>';
				html += '<td width=80px align="right"><p style="font-size: 12px;height:30px"><u>' + __("Next discount at") + '</u></p></td>';
				html += '<td width=80px align="right"><p style="font-size: 12px;height:30px"><u>' + __("Discount gap") + '</u></p></td>';
				html += '<td width=75px align="right"><p style="font-size: 12px;height:30px"><u>' + __("Next discount") + '</u></p></td>';
				html += '<td width=80px align="right"><p style="font-size: 12px;height:30px"><u>' + __("You can min win") + '</u></p></td>';
				html += '<td width=50px align="center"><p style="font-size: 12px;height:30px"><u>' + __("Next") + '</u></p></td>';
				html += '</tr>';
				html += "</table>"

				html += '<table id="item_budget_discounts_table" border="0" width=1020px>';
				for (var i = 0; i < data.length; i++) {

					html += '<input type="hidden" id="item_budget_discounts_item_budget_id_' + i.toString() + '" value="' + data[i].item_budget_id + '">';
					html += '<input type="hidden" id="item_budget_discounts_currency_id_' + i.toString() + '" value="' + data[i].currency_id  + '">';
					html += '<input type="hidden" id="item_budget_discounts_ordered_value_' + i.toString() + '" value="' + data[i].ordered_value  + '">';
					html += '<input type="hidden" id="item_budget_discounts_next_discount_at_' + i.toString() + '" value="' + data[i].next_discount_at  + '">';

					html += '<tr height=10px>';
					html += '<td width=200px><p style="font-size: 12px;height:10px;"><label style="height:15px">' + data[i].item_budget_id + '</label></p></td>';
					html += '<td width=70px align="center"><p style="font-size: 12px;height:10px;"><label style="height:15px">' + data[i].currency_id + '</label></p></td>';
					html += '<td width=80px align="right"><p style="font-size: 12px;height:10px;"><label style="height:15px">' + data[i].ordered_value.toFixed(2) + '</label></p></td>';


					html += '<td width=80px align="right"><p style="font-size: 12px;height:10px"><label style="height:15px">' + data[i].discount_at.toFixed(2) + '</label></p></td>';

					if (data[i].is_discount_granted == 1) {
						html += '<td width=75px align="right"><p style="font-size: 12px;height:10px"><label style="background:#00ff00;color:#000000;height:15px">' + data[i].granted_discount + ' %</label></p></td>';
					}else{
						html += '<td width=75px align="right"><p style="font-size: 12px;height:10px"><label style="background:#00ffff;color:#000000;height:15px">' + data[i].granted_discount + ' %</label></p></td>';
					}

					if (data[i].is_discount_granted == 1) {
						html += '<td width=70px align="right"><p style="font-size: 12px;height:10px"><label style="background:#00ff00;color:#000000;height:15px">' + data[i].gain.toFixed(2) + '</label></p></td>';
					}else{
						html += '<td width=70px align="right"><p style="font-size: 12px;height:10px"><label style="background:#00ffff;color:#000000;height:15px">' + data[i].gain.toFixed(2) + '</label></p></td>';
					}

					if (data[i].net_value != 0) {
						if (data[i].is_discount_granted == 1) {
							html += '<td width=80px align="right"><p style="font-size: 12px;height:10px"><label style="background:#00ff00;color:#000000;height:15px">' + data[i].net_value.toFixed(2) + '</label></p></td>';
						}else{
							html += '<td width=80px align="right"><p style="font-size: 12px;height:10px"><label style="background:#00ffff;color:#000000;height:15px">' + data[i].net_value.toFixed(2) + '</label></p></td>';
						}
					}else{
						html += '<td width=80px align="right">&nbsp;</td>';
					}

					if (data[i].next_discount_at != 0) {
						html += '<td width=80px align="right"><p style="font-size: 12px;height:10px"><label style="background:#ffff00;color:#000000;height:15px">' + data[i].next_discount_at.toFixed(2) + '</label></p></td>';
					}else{
						html += '<td width=80px align="right">&nbsp;</td>';
					}

					if (data[i].discount_gap != 0) {
						html += '<td width=80px align="right"><p style="font-size: 12px;height:10px"><label style="background:#ffff00;color:#000000;height:15px">' + data[i].discount_gap.toFixed(2) + '</label></p></td>';
					}else{
						html += '<td width=80px align="right">&nbsp;</td>';
					}

					if (data[i].next_discount != 0) {
						html += '<td width=75px align="right"><p style="font-size: 12px;height:10px"><label style="background:#ffff00;color:#000000;height:15px">' + data[i].next_discount + ' %</label></p></td>';
					}else{
						html += '<td width=75px align="right">&nbsp;</td>';
					}
					if (data[i].next_min_gain) {
						html += '<td width=80px align="right"><p style="font-size: 12px;height:10px"><label style="background:#ffff00;color:#000000;height:15px">' + data[i].next_min_gain.toFixed(2) + '</label></p></td>';
					}else{
						html += '<td width=80px align="right">&nbsp;</td>';
					}

					if (data[i].has_next_again == 1) {
						html += '<td width=50px align="center"><button id="item_budget_next_discounts_button_' + i.toString() + '" style="width:30px; height:20px; border: none;">...</button></td>';
					}else{
						html += '<td width=50px align="center">&nbsp;</td>';
					}



					html += '</tr>';

				}

				html += "</table>"

				silicon_ioi.ioiCommon.show_grid_info_popup(html, 120000);

				silicon_ioi.doctype.ioiSalesDocumentDocType.sleep_static(250).then(() => {

					if (document.getElementById('item_budget_discounts_table')) {

						for (var i = 0; i < document.getElementById('item_budget_discounts_table').rows.length; i++) {

							if (document.getElementById('item_budget_next_discounts_button_' + i.toString())) {

								let fct_click = function() {

									let id = this.id;

									while (id.indexOf('_') != -1) {
										id = id.substring(id.indexOf('_')+1, id.length);
									}

									silicon_ioi.doctype.ioiSalesDocumentDocType.show_next_item_budget_discount(id);
								};

								document.getElementById('item_budget_next_discounts_button_' + i.toString()).onclick = fct_click;
							}
						}


					}

				});

			}
		}

	}

	static show_next_item_budget_discount(id)
	{
		let item_budget_id = document.getElementById('item_budget_discounts_item_budget_id_' + id.toString()).value;
		let currency_id = document.getElementById('item_budget_discounts_currency_id_' + id.toString()).value;
		let ordered_value = parseFloat(document.getElementById('item_budget_discounts_ordered_value_' + id.toString()).value);
		let next_discount_at = parseFloat(document.getElementById('item_budget_discounts_next_discount_at_' + id.toString()).value);

		let method = 'silicon_ioi.ioi_configuration.doctype.ioi_item_budget.ioi_item_budget.ioi_item_budget_get_next_discounts';

		let data = [];

		frappe.call({  	method: method,
						args: {"item_budget_id": item_budget_id, "currency_id": currency_id, "ordered_value": ordered_value, "next_discount_at": next_discount_at},
						async: false,
						callback:function(r)	{
							data = r.message;
						}
		});


		if (data.length == 0) {
			return false;
		}

		let title = __("Item budget") + ' ' + item_budget_id + ' : ' + __("next discounts")

		var ItemBudgetNextDiscounts = new frappe.ui.Dialog({
			title: title,
			static: true,
			fields: [	{'fieldname': 'html_item_budget_next_discounts_form', 'fieldtype': 'HTML'}
					],
			primary_action_label: __("Close"),
			primary_action: function(){
				ItemBudgetNextDiscounts.hide();
			}
		});

		let html = ""

		html += '<div style="overflow: auto; overflow-x: auto; height:180px; width: 100%;">';

		html += '<table border="0" width=480px>';
		html += '<tr height=30px>';
		html += '<td width=120px align="right"><u>' + __("Next discount at") + '</u></td>';
		html += '<td width=120px align="right"><u>' + __("Discount gap") + '</u></td>';
		html += '<td width=120px align="right"><u>' + __("Next discount") + '</u></td>';
		html += '<td width=120px align="right"><u>' + __("You can min win") + '</u></td>';
		html += '</tr>';
		html += "</table>"

		html += '<table border="0" width=480px>';


		for (var i = 0; i < data.length; i++) {
			html += '<tr height=30px>';

			if (data[i].trigger_value != 0) {
				html += '<td width=120px align="right">' + data[i].trigger_value.toFixed(2) + ' ' + data[i].currency_id + '</td>';
			}else{
				html += '<td width=120px align="right">&nbsp;</td>';
			}

			if (data[i].discount_gap != 0) {
				html += '<td width=120px align="right">' + data[i].discount_gap.toFixed(2) + ' ' + data[i].currency_id + '</td>';
			}else{
				html += '<td width=120px align="right">&nbsp;</td>';
			}

			if (data[i].next_discount != 0) {
				html += '<td width=120px align="right">' + data[i].extra_discount + ' %</td>';
			}else{
				html += '<td width=120px align="right">&nbsp;</td>';
			}
			if (data[i].next_min_gain) {
				html += '<td width=120px align="right">' + data[i].next_min_gain.toFixed(2) + ' ' + data[i].currency_id + '</td>';
			}else{
				html += '<td width=120px align="right">&nbsp;</td>';
			}

			html += '</tr>';
		}
		html += '</table>';

		html += '</div>';

		ItemBudgetNextDiscounts.fields_dict.html_item_budget_next_discounts_form.$wrapper.html(html);
		ItemBudgetNextDiscounts.$wrapper.find('.modal-dialog').css("max-width", "540px").css("width", "540px");
		ItemBudgetNextDiscounts.show();
	}




	change_payment_term()
	{
		if ((this.frm.doctype.toUpperCase() == 'IOI SALES ORDER') || (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')) {

			if ((this.frm.doc.payment_term_id) && (this.frm.doc.payment_term_id.trim() != '')) {

				let me = this;
				let meth = this.path_payment_terms + '.ioi_payment_terms_get_data';

				frappe.call({  	method: meth,
								args: {"payment_term_id": this.frm.doc.payment_term_id},
								async: false,
								callback:function(r)	{

									if (r.message.length > 0) {

										if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
											me.frm.set_value('cashdesk_pay_on_order', r.message[0].pay_on_order);
										}

										me.frm.set_value('cashdesk_pay_on_delivery', r.message[0].pay_on_delivery);

									}else{

										if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
											me.frm.set_value('cashdesk_pay_on_order', 0);
										}

										me.frm.set_value('cashdesk_pay_on_delivery', 0);
									}

								}
				});


			}else{

				if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
					this.frm.set_value('cashdesk_pay_on_order', 0);
				}

				this.frm.set_value('cashdesk_pay_on_delivery', 0);
			}
		}

		this.refresh_payment_pay_on_order();
	}

	refresh_payment_pay_on_order()
	{
		if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {

			if (document.getElementById('payment_movement_pay_on_order_label')) {

				document.getElementById('payment_movement_pay_on_order_label').innerHTML = '';
				document.getElementById('payment_movement_pay_on_order_value').innerHTML = '';
				document.getElementById('payment_movement_div_pay_on_order').hidden = true;


				if (this.frm.doc.cashdesk_pay_on_order != 0) {

					let ratio = parseFloat(this.frm.doc.total_tvac) * parseFloat(this.frm.doc.cashdesk_pay_on_order) / 100;

					let alreadypaid = 0

					if (document.getElementById('payment_movement_total_already_paid').innerText.trim() != '') {
						alreadypaid = parseFloat(document.getElementById('payment_movement_total_already_paid').innerText);
					}

					let color = '#60A367';

					let s = '';

					if (alreadypaid >= ratio) {
						s += '<b>' + __("Paid on order") + '</b>';
					}else{
						s += '<b>' + __("To pay on order") + '</b>';
						color = '#ED514C';
					}

					let v = '<b><font color="' + color + '">' + this.frm.doc.cashdesk_pay_on_order.toString() + '&nbsp;%</font>&nbsp;(&nbsp;' + ratio.toString() + '&nbsp;' + this.frm.doc.currency_id + '&nbsp;)</b>';

					document.getElementById('payment_movement_div_pay_on_order').hidden = false;
					document.getElementById('payment_movement_pay_on_order_label').innerHTML = s;
					document.getElementById('payment_movement_pay_on_order_value').innerHTML = v;
				}
			}

		}
	}


	load_cash_desk_data()
	{
		this.frm.set_value('cashdesk_payconiq_enabled', 0);
		this.frm.set_value('cashdesk_ccv_enabled', 0);

		this.frm.set_value('cashdesk_cash_value_enabled', 0);
		this.frm.set_value('cashdesk_bank_card_value_enabled', 0);
		this.frm.set_value('cashdesk_credit_card_value_enabled', 0);
		this.frm.set_value('cashdesk_check_value_enabled', 0);
		this.frm.set_value('cashdesk_check_id_required', 0);
		this.frm.set_value('cashdesk_emoney_value_enabled', 0);

		this.frm.set_value('cashdesk_check_spec_1_value_enabled', 0);
		this.frm.set_value('cashdesk_check_spec_1_type', 0);
		this.frm.set_value('cashdesk_check_spec_1_description', '');
		this.frm.set_value('cashdesk_check_spec_1_id_required', 0);

		this.frm.set_value('cashdesk_check_spec_2_value_enabled', 0);
		this.frm.set_value('cashdesk_check_spec_2_type', 0);
		this.frm.set_value('cashdesk_check_spec_2_description', '');
		this.frm.set_value('cashdesk_check_spec_2_id_required', 0);

		this.frm.set_value('cashdesk_check_spec_3_value_enabled', 0);
		this.frm.set_value('cashdesk_check_spec_3_type', 0);
		this.frm.set_value('cashdesk_check_spec_3_description', '');
		this.frm.set_value('cashdesk_check_spec_3_id_required', 0);

		if ((this.frm.doc.cashdesk_id) && (this.frm.doc.cashdesk_id.trim() != '')) {

			let me = this;

			let meth = this.path_cash_desk + '.ioi_cash_desk_get_info';

			frappe.call({  	method: meth,
							args: {"name": this.frm.doc.cashdesk_id},
							async: false,
							callback:function(r)	{

								if (r.message.length != 0) {

									me.frm.set_value('cashdesk_payconiq_enabled', r.message[0].payconiq_enabled);
									me.frm.set_value('cashdesk_ccv_enabled', r.message[0].ccv_enabled);

									me.frm.set_value('cashdesk_cash_value_enabled', r.message[0].cash_value_enabled);
									me.frm.set_value('cashdesk_bank_card_value_enabled', r.message[0].bank_card_value_enabled);
									me.frm.set_value('cashdesk_credit_card_value_enabled', r.message[0].credit_card_value_enabled);
									me.frm.set_value('cashdesk_check_value_enabled', r.message[0].check_value_enabled);
									me.frm.set_value('cashdesk_check_id_required', r.message[0].check_id_required);
									me.frm.set_value('cashdesk_emoney_value_enabled', r.message[0].emoney_value_enabled);

									me.frm.set_value('cashdesk_check_spec_1_value_enabled', r.message[0].check_spec_1_value_enabled);
									me.frm.set_value('cashdesk_check_spec_1_type', r.message[0].check_spec_1_type);
									me.frm.set_value('cashdesk_check_spec_1_description', r.message[0].check_spec_1_description);
									me.frm.set_value('cashdesk_check_spec_1_id_required', r.message[0].check_spec_1_id_required);
									me.frm.set_value('cashdesk_check_spec_1_electronic_transac', r.message[0].check_spec_1_electronic_transac);

									me.frm.set_value('cashdesk_check_spec_2_value_enabled', r.message[0].check_spec_2_value_enabled);
									me.frm.set_value('cashdesk_check_spec_2_type', r.message[0].check_spec_2_type);
									me.frm.set_value('cashdesk_check_spec_2_description', r.message[0].check_spec_2_description);
									me.frm.set_value('cashdesk_check_spec_2_id_required', r.message[0].check_spec_2_id_required);
									me.frm.set_value('cashdesk_check_spec_2_electronic_transac', r.message[0].check_spec_2_electronic_transac);

									me.frm.set_value('cashdesk_check_spec_3_value_enabled', r.message[0].check_spec_3_value_enabled);
									me.frm.set_value('cashdesk_check_spec_3_type', r.message[0].check_spec_3_type);
									me.frm.set_value('cashdesk_check_spec_3_description', r.message[0].check_spec_3_description);
									me.frm.set_value('cashdesk_check_spec_3_id_required', r.message[0].check_spec_3_id_required);
									me.frm.set_value('cashdesk_check_spec_3_electronic_transac', r.message[0].check_spec_3_electronic_transac);

								}
							}
			});
		}
	}

	refresh_credit_control(fieldname)
	{

		let html = '';

		this.frm.fields_dict[fieldname].$wrapper.empty();

		if (!this.frm.is_new()) {

			if (document.getElementById('html_credit_control_summary_unbalanced_amount_label')) { document.getElementById('html_credit_control_summary_unbalanced_amount_label').remove()}
			if (document.getElementById('html_credit_control_summary_unbalanced_amount')) { document.getElementById('html_credit_control_summary_unbalanced_amount').remove()}

			if (document.getElementById('html_credit_control_summary_invoiced_label')) { document.getElementById('html_credit_control_summary_invoiced_label').remove()}
			if (document.getElementById('html_credit_control_summary_invoiced')) { document.getElementById('html_credit_control_summary_invoicd').remove()}

			if (document.getElementById('html_credit_control_summary_delivered_label')) { document.getElementById('html_credit_control_summary_delivered_label').remove()}
			if (document.getElementById('html_credit_control_summary_delivered')) { document.getElementById('html_credit_control_summary_delivered').remove()}


			if (document.getElementById('html_credit_control_summary_reserved_label')) { document.getElementById('html_credit_control_summary_reserved_label').remove()}
			if (document.getElementById('html_credit_control_summary_reserved')) { document.getElementById('html_credit_control_summary_reserved').remove()}

			if (document.getElementById('html_credit_control_summary_this_delivery_label')) { document.getElementById('html_credit_control_summary_this_delivery_label').remove()}
			if (document.getElementById('html_credit_control_summary_this_delivery')) { document.getElementById('html_credit_control_summary_this_delivery').remove()}

			if (document.getElementById('html_credit_control_summary_total_label')) { document.getElementById('html_credit_control_summary_total_label').remove()}
			if (document.getElementById('html_credit_control_summary_total')) { document.getElementById('html_credit_control_summary_total').remove()}

			if (document.getElementById('html_credit_control_summary_cust_credit_label')) { document.getElementById('html_credit_control_summary_cust_credit_label').remove()}
			if (document.getElementById('html_credit_control_summary_cust_credit')) { document.getElementById('html_credit_control_summary_cust_credit').remove()}

			if (document.getElementById('html_credit_control_summary_exceeded_amount_label')) { document.getElementById('html_credit_control_summary_exceeded_amount_label').remove()}
			if (document.getElementById('html_credit_control_summary_exceeded_amount')) { document.getElementById('html_credit_control_summary_exceeded_amount').remove()}

			if (document.getElementById('html_credit_control_summary_unbalanced_days_label')) { document.getElementById('html_credit_control_summary_unbalanced_days_label').remove()}
			if (document.getElementById('html_credit_control_summary_unbalanced_days')) { document.getElementById('html_credit_control_summary_unbalanced_days').remove()}

			if (document.getElementById('html_credit_control_summary_overdue_days_label')) { document.getElementById('html_credit_control_summary_overdue_days_label').remove()}
			if (document.getElementById('html_credit_control_summary_overdue_days')) { document.getElementById('html_credit_control_summary_overdue_days').remove()}

			if (document.getElementById('html_credit_control_summary_exceeded_days_label')) { document.getElementById('html_credit_control_summary_exceeded_days_label').remove()}
			if (document.getElementById('html_credit_control_summary_exceeded_days')) { document.getElementById('html_credit_control_summary_exceeded_days').remove()}

			if (document.getElementById('html_credit_control_summary_locked_label')) { document.getElementById('html_credit_control_summary_locked_label').remove()}
			if (document.getElementById('html_credit_control_summary_locked')) { document.getElementById('html_credit_control_summary_locked').remove()}

			if (document.getElementById('html_credit_control_summary_signed_label')) { document.getElementById('html_credit_control_summary_signed_label').remove()}
			if (document.getElementById('html_credit_control_summary_signed')) { document.getElementById('html_credit_control_summary_signed').remove()}

			if (document.getElementById('html_credit_control_summary_status_label')) { document.getElementById('html_credit_control_summary_status_label').remove()}
			if (document.getElementById('html_credit_control_summary_status')) { document.getElementById('html_credit_control_summary_status').remove()}

			if (document.getElementById('html_credit_control_summary_user_label')) { document.getElementById('html_credit_control_summary_user_label').remove()}
			if (document.getElementById('html_credit_control_summary_user')) { document.getElementById('html_credit_control_summary_user').remove()}

			if (document.getElementById('html_credit_control_summary_date_label')) { document.getElementById('html_credit_control_summary_date_label').remove()}
			if (document.getElementById('html_credit_control_summary_date')) { document.getElementById('html_credit_control_summary_date').remove()}



			html += '<div style="overflow: auto; overflow-x: auto; height:290px; width: 100%;">';

			// Unbalanced accounted amount
			html += '	<div style="position: relative; top: 2px; left: 2px; width:300px;">';
			html += '		<label id="html_credit_control_summary_unbalanced_amount_label" style="position: absolute; top: 0px; left: 2px;">' + __("Unbalanced accounted amount") + '</label>';
			html += '	</div>';

			let v = 0;

			if ((this.frm.doc.credit_control_unbalanced_amount) && (this.frm.doc.credit_control_unbalanced_amount != 0)){
				v = parseFloat(this.frm.doc.credit_control_unbalanced_amount);
			}

			html += '	<div style="position: relative; top: 2px; left: 200px; width:120px;text-align:right;">';
			html += '		<label id="html_credit_control_summary_unbalanced_amount" style="position: absolute; top: 0px;width:120px;">' + v.toFixed(2) + ' ' + this.system_currency + '</label>';
			html += '	</div>';


			// Invoiced not yet accounted
			html += '	<div style="position: relative; top: 32px; left: 2px; width:300px;">';
			html += '		<label id="html_credit_control_summary_invoiced_label" style="position: absolute; top: 0px; left: 2px;">' + __("Invoiced not yet accounted") + '</label>';
			html += '	</div>';

			v = 0;

			if ((this.frm.doc.credit_control_invoiced_not_accounted) && (this.frm.doc.credit_control_invoiced_not_accounted != 0)){
				v = parseFloat(this.frm.doc.credit_control_invoiced_not_accounted);
			}

			html += '	<div style="position: relative; top: 32px; left: 200px; width:120px;text-align:right;">';
			html += '		<label id="html_credit_control_summary_invoiced" style="position: absolute; top: 0px;width:120px;">' + v.toFixed(2) + ' ' + this.system_currency + '</label>';
			html += '	</div>';


			// Delivered not yet invoiced
			html += '	<div style="position: relative; top: 62px; left: 2px; width:300px;">';
			html += '		<label id="html_credit_control_summary_delivered_label" style="position: absolute; top: 0px; left: 2px;">' + __("Delivered not yet invoiced") + '</label>';
			html += '	</div>';

			v = 0;

			if ((this.frm.doc.credit_control_delivered_not_invoiced) && (this.frm.doc.credit_control_delivered_not_invoiced != 0)){
				v = parseFloat(this.frm.doc.credit_control_delivered_not_invoiced);
			}

			html += '	<div style="position: relative; top: 62px; left: 200px; width:120px;text-align:right;">';
			html += '		<label id="html_credit_control_summary_delivered" style="position: absolute; top: 0px;width:120px;">' + v.toFixed(2) + ' ' + this.system_currency + '</label>';
			html += '	</div>';

			// Reserved deliveries
			html += '	<div style="position: relative; top: 92px; left: 2px; width:300px;">';
			html += '		<label id="html_credit_control_summary_reserved_label" style="position: absolute; top: 0px; left: 2px;">' + __("Reserved deliveries") + '</label>';
			html += '	</div>';

			v = 0;

			if ((this.frm.doc.credit_control_reserved) && (this.frm.doc.credit_control_reserved != 0)){
				v = parseFloat(this.frm.doc.credit_control_reserved);
			}

			html += '	<div style="position: relative; top: 92px; left: 200px; width:120px;text-align:right;">';
			html += '		<label id="html_credit_control_summary_reserved" style="position: absolute; top: 0px;width:120px;">' + v.toFixed(2) + ' ' + this.system_currency + '</label>';
			html += '	</div>';

			// This delivery
			html += '	<div style="position: relative; top: 122px; left: 2px; width:300px;">';
			html += '		<label id="html_credit_control_summary_this_delivery_label" style="position: absolute; top: 0px; left: 2px;">' + __("This delivery") + '</label>';
			html += '	</div>';

			v = 0;

			if ((this.frm.doc.credit_control_this_delivery) && (this.frm.doc.credit_control_this_delivery != 0)){
				v = parseFloat(this.frm.doc.credit_control_this_delivery);
			}

			html += '	<div style="position: relative; top: 122px; left: 200px; width:120px;text-align:right;">';
			html += '		<label id="html_credit_control_summary_this_delivery" style="position: absolute; top: 0px;width:120px;">' + v.toFixed(2) + ' ' + this.system_currency + '</label>';
			html += '	</div>';

			// TOTAL
			html += '	<div style="position: relative; top: 152px; left: 2px; width:300px;">';
			html += '		<label id="html_credit_control_summary_total_label" style="position: absolute; top: 0px; left: 2px;"><b>' + __("TOTAL") + '</b></label>';
			html += '	</div>';

			v = 0;

			if ((this.frm.doc.credit_control_total) && (this.frm.doc.credit_control_total != 0)){
				v = parseFloat(this.frm.doc.credit_control_total);
			}

			html += '	<div style="position: relative; top: 152px; left: 200px; width:120px;text-align:right;">';
			html += '		<label id="html_credit_control_summary_total" style="position: absolute; top: 0px;width:120px;"><b>' + v.toFixed(2) + ' ' + this.system_currency + '</b></label>';
			html += '	</div>';

			// Customer credit
			html += '	<div style="position: relative; top: 182px; left: 2px; width:300px;">';
			html += '		<label id="html_credit_control_summary_cust_credit_label" style="position: absolute; top: 0px; left: 2px;">' + __("Customer max credit amount") + '</label>';
			html += '	</div>';

			v = 0;

			if ((this.frm.doc.credit_control_customer_credit) && (this.frm.doc.credit_control_customer_credit != 0)){
				v = parseFloat(this.frm.doc.credit_control_customer_credit);
			}

			html += '	<div style="position: relative; top: 182px; left: 200px; width:120px;text-align:right;">';
			html += '		<label id="html_credit_control_summary_cust_credit" style="position: absolute; top: 0px;width:120px;">' + v.toFixed(2) + ' ' + this.system_currency + '</label>';
			html += '	</div>';


			// Exceeded amount
			html += '	<div style="position: relative; top: 212px; left: 2px; width:300px;">';
			html += '		<label id="html_credit_control_summary_exceeded_amount_label" style="position: absolute; top: 0px; left: 2px;"><b>' + __("Exceeded amount") + '</b></label>';
			html += '	</div>';

			v = 0;

			if ((this.frm.doc.credit_control_exceeded_amount) && (this.frm.doc.credit_control_exceeded_amount != 0)){
				v = parseFloat(this.frm.doc.credit_control_exceeded_amount);
			}

			html += '	<div style="position: relative; top: 212px; left: 200px; width:120px;text-align:right;">';
			html += '		<label id="html_credit_control_summary_exceeded_amount" style="position: absolute; top: 0px;width:120px;"><b>' + v.toFixed(2) + ' ' + this.system_currency + '</b></label>';
			html += '	</div>';


			// Unbalanced accounted days
			html += '	<div style="position: relative; top: 2px; left: 502px; width:300px;">';
			html += '		<label id="html_credit_control_summary_unbalanced_days_label" style="position: absolute; top: 0px; left: 2px;">' + __("Unbalanced accounted days") + '</label>';
			html += '	</div>';

			v = 0;

			if ((this.frm.doc.credit_control_real_days) && (this.frm.doc.credit_control_real_days != 0)){
				v = parseInt(this.frm.doc.credit_control_real_days);
			}

			html += '	<div style="position: relative; top: 2px; left: 600px; width:120px;text-align:right;">';
			html += '		<label id="html_credit_control_summary_unbalanced_days" style="position: absolute; top: 0px;width:120px;">' + v + '</label>';
			html += '	</div>';

			// Customer max overdue days
			html += '	<div style="position: relative; top: 32px; left: 502px; width:300px;">';
			html += '		<label id="html_credit_control_summary_overdue_days_label" style="position: absolute; top: 0px; left: 2px;">' + __("Customer max overdue days") + '</label>';
			html += '	</div>';

			v = 0;

			if ((this.frm.doc.credit_control_customer_overdue_days) && (this.frm.doc.credit_control_customer_overdue_days != 0)){
				v = parseInt(this.frm.doc.credit_control_customer_overdue_days);
			}

			html += '	<div style="position: relative; top: 32px; left: 600px; width:120px;text-align:right;">';
			html += '		<label id="html_credit_control_summary_overdue_days" style="position: absolute; top: 0px;width:120px;">' + v + '</label>';
			html += '	</div>';


			// Exceeded days
			html += '	<div style="position: relative; top: 62px; left: 502px; width:300px;">';
			html += '		<label id="html_credit_control_summary_exceeded_days_label" style="position: absolute; top: 0px; left: 2px;"><b>' + __("Exceeded days") + '</b></label>';
			html += '	</div>';

			v = 0;

			if ((this.frm.doc.credit_control_exceed_days) && (this.frm.doc.credit_control_exceed_days != 0)){
				v = parseInt(this.frm.doc.credit_control_exceed_days);
			}

			html += '	<div style="position: relative; top: 62px; left: 600px; width:120px;text-align:right;">';
			html += '		<label id="html_credit_control_summary_exceeded_days" style="position: absolute; top: 0px;width:120px;"><b>' + v + '</b></label>';
			html += '	</div>';


			// Locked by credit control
			html += '	<div style="position: relative; top: 92px; left: 502px; width:300px;">';
			html += '		<label id="html_credit_control_summary_locked_label" style="position: absolute; top: 0px; left: 2px;">' + __("Locked by the credit control") + '</label>';
			html += '	</div>';

			v = '';

			if ((this.frm.doc.locked_by_credit_control) && (this.frm.doc.locked_by_credit_control != 0)){
				v = 'X'
			}

			html += '	<div style="position: relative; top: 92px; left: 806px; width:40px;text-align:right;" align="right">';
			html += '		<input id="html_credit_control_summary_locked"  type="checkbox"  style="postion:absolute; top: 2px;" '

			if (v == 'X') {
				html += 'checked '
			}
			html += ' disabled>'

			html += '	</div>';


			// Signed
			html += '	<div style="position: relative; top: 99px; left: 502px; width:300px;">';
			html += '		<label id="html_credit_control_summary_signed_label" style="position: absolute; top: 0px; left: 2px;">' + __("Signed") + '</label>';
			html += '	</div>';

			v = '';

			if ((this.frm.doc.signed_to_unlock) && (this.frm.doc.signed_to_unlock != 0)){
				v = 'X'
			}

			html += '	<div style="position: relative; top: 99px; left: 806px; width:40px;text-align:right;" align="right">';
			html += '		<input id="html_credit_control_summary_signed"  type="checkbox"  style="postion:absolute; top: 2px;" '

			if (v == 'X') {
				html += 'checked '
			}
			html += ' disabled>'

			html += '	</div>';


			// Status
			html += '	<div style="position: relative; top: 109px; left: 502px; width:300px;">';
			html += '		<label id="html_credit_control_summary_status_label" style="position: absolute; top: 0px; left: 2px;"><b>' + __("Status") + '</b></label>';
			html += '	</div>';

			v = 0;

			if ((this.frm.doc.credit_control_status) && (this.frm.doc.credit_control_status != 0)){
				v = parseInt(this.frm.doc.credit_control_status);
			}

			if (v == 0) {
				v = __("To approve / refuse")
			}else if (v == 1) {
				v = __("Approved")
			}else{
				v = __("Refused")
			}

			html += '	<div style="position: relative; top: 109px; left: 440px; width:200px;text-align:right;">';
			html += '		<label id="html_credit_control_summary_status" style="position: absolute; top: 0px;width:200px;"><b>' + v + '</b></label>';
			html += '	</div>';


			// User
			html += '	<div style="position: relative; top: 139px; left: 502px; width:300px;">';
			html += '		<label id="html_credit_control_summary_user_label" style="position: absolute; top: 0px; left: 2px;">' + __("User") + '</label>';
			html += '	</div>';

			v = '';

			if ((this.frm.doc.credit_control_user_id) && (this.frm.doc.credit_control_user_id.trim() != '')) {
				v = this.frm.doc.credit_control_user_id
			}
			html += '	<div style="position: relative; top: 139px; left: 440px; width:200px;text-align:right;">';
			html += '		<label id="html_credit_control_summary_user" style="position: absolute; top: 0px;width:200px;">' + v + '</label>';
			html += '	</div>';

			// Date
			html += '	<div style="position: relative; top: 169px; left: 502px; width:300px;">';
			html += '		<label id="html_credit_control_summary_date_label" style="position: absolute; top: 0px; left: 2px;">' + __("Approved/refused on") + '</label>';
			html += '	</div>';

			v = '';

			if ((this.frm.doc.credit_control_action_dt) && (this.frm.doc.credit_control_action_dt.trim() != '')) {
				v = this.frm.doc.credit_control_action_dt
			}
			html += '	<div style="position: relative; top: 169px; left: 440px; width:200px;text-align:right;">';
			html += '		<label id="html_credit_control_summary_date" style="position: absolute; top: 0px;width:200px;">' + v + '</label>';
			html += '	</div>';


			// Remark
			html += '	<div style="position: relative; top: 199px; left: 2px; width:300px;">';
			html += '		<label id="html_credit_control_summary_remark_label" style="position: absolute; top: 0px; left: 2px;">' + __("Remark") + '</label>';
			html += '	</div>';

			v = '';

			if ((this.frm.doc.credit_control_remark) && (this.frm.doc.credit_control_remark.trim() != '')) {
				v = this.frm.doc.credit_control_remark
			}
			html += '	<div style="position: relative; top: 199px; left: 502px; width:350px;">';
			html += '		<label id="html_credit_control_summary_remark" style="position: absolute; top: 0px;width:350px;">' + v + '</label>';
			html += '	</div>';

			html += '</div>'

			this.frm.fields_dict[fieldname].$wrapper.append(html);

		}

	}

	refresh_payment_status(fieldname)
	{

		let html = '';
		this.frm.fields_dict[fieldname].$wrapper.empty();


		let bgcolor = '';
		let color = '#000000';
		let description = '';

		if ((!this.frm.doc.cashdesk_payment_status_id) || ((this.frm.doc.cashdesk_payment_status_id) && ((this.frm.doc.cashdesk_payment_status_id == '') || (this.frm.doc.cashdesk_payment_status_id == 0)))) {
			description = __("Unpaid");
			bgcolor = '#D9D3D2';
		}else if (this.frm.doc.cashdesk_payment_status_id == 1) {
			description = __("Partial");
			bgcolor = '#FFC869';
		}else{
			description = __("Total")
			bgcolor = '#7ECC6E';
		}

		html = '<table width=100%>';
		html += '<tr>';
		html += '<td width=100% height=20px><font style="font-size:12px;">'+ __("Status") +'</font></td>';
		html += '</tr>';

		html += '<tr>';
		html += '<td width=100%>';
		html += '<div style="height:32px; background-color:' + bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
		html += '<b><font style="font-size:14px;color:' + color +'">&nbsp;'+ description +'</b></font>';
		html += '</div></td>';
		html += '</tr>';

		html += '<tr>';
		html += '<td width=100% height=7px>&nbsp;</td>';
		html += '</tr>';

		html += '</table>';

		this.frm.fields_dict[fieldname].$wrapper.append(html);

	}

	refresh_payment_movements(fieldname)
	{
		let html = '';
		this.frm.fields_dict[fieldname].$wrapper.empty();

		let go = true

		if (this.frm.is_new()) {
			go = false;
		}

		if ((go) && ((!this.frm.doc.cashdesk_id) || ((this.frm.doc.cashdesk_id) && (this.frm.doc.cashdesk_id.trim() == '')))) {
			go = false;
		}


		if (document.getElementById('payment_movement_total_paid_label')) {
			document.getElementById('payment_movement_total_paid_label').remove();
		}

		if (document.getElementById('payment_movement_total_paid')) {
			document.getElementById('payment_movement_total_paid').remove();
		}

		if (document.getElementById('payment_movement_total_paid_curr')) {
			document.getElementById('payment_movement_total_paid_curr').remove();
		}


		if (document.getElementById('payment_movement_total_already_paid_label')) {
			document.getElementById('payment_movement_total_already_paid_label').remove();
		}

		if (document.getElementById('payment_movement_total_already_paid')) {
			document.getElementById('payment_movement_total_already_paid').remove();
		}

		if (document.getElementById('payment_movement_total_already_paid_curr')) {
			document.getElementById('payment_movement_total_already_paid_curr').remove();
		}

		if (document.getElementById('payment_movement_still_to_pay_label')) {
			document.getElementById('payment_movement_still_to_pay_label').remove();
		}

		if (document.getElementById('payment_movement_still_to_pay')) {
			document.getElementById('payment_movement_still_to_pay').remove();
		}

		if (document.getElementById('payment_movement_still_to_pay_curr')) {
			document.getElementById('payment_movement_still_to_pay_curr').remove();
		}

		if (document.getElementById('payment_movement_total_still_to_pay')) {
			document.getElementById('payment_movement_total_still_to_pay').remove();
		}

		if (document.getElementById('payment_movement_button_pay')) {
			document.getElementById('payment_movement_button_pay').remove();
		}

		if (document.getElementById('payment_movement_button_get_cash')) {
			document.getElementById('payment_movement_button_get_cash').remove();
		}

		if (document.getElementById('payment_movement_button_put_cash')) {
			document.getElementById('payment_movement_button_put_cash').remove();
		}

		if (document.getElementById('payment_movement_button_cancel')) {
			document.getElementById('payment_movement_button_cancel').remove();
		}

		if (document.getElementById('payment_movement_button_open_cash_desk')) {
			document.getElementById('payment_movement_button_open_cash_desk').remove();
		}

		if (document.getElementById('payment_movement_button_close_cash_desk')) {
			document.getElementById('payment_movement_button_close_cash_desk').remove();
		}

		if (document.getElementById('payment_movement_div_pay_on_order')) {
			document.getElementById('payment_movement_div_pay_on_order').remove();
		}

		if (document.getElementById('payment_movement_pay_on_order_label')) {
			document.getElementById('payment_movement_pay_on_order_label').remove();
		}
		if (document.getElementById('payment_movement_pay_on_order_value')) {
			document.getElementById('payment_movement_pay_on_order_value').remove();
		}

		if (document.getElementById('payment_movement_div_totals')) {
			document.getElementById('payment_movement_div_totals').remove();
		}

		if (document.getElementById('payment_movement_div_buttons')) {
			document.getElementById('payment_movement_div_buttons').remove();
		}


		if (document.getElementById('payment_movement_div_order_already_paid')) {
			document.getElementById('payment_movement_div_order_already_paid').remove();
		}
		if (document.getElementById('payment_movement_order_already_paid_label')) {
			document.getElementById('payment_movement_order_already_paid_label').remove();
		}
		if (document.getElementById('payment_movement_order_already_paid_value')) {
			document.getElementById('payment_movement_order_already_paid_value').remove();
		}


		if (document.getElementById('payment_movement_div_delivery_already_paid')) {
			document.getElementById('payment_movement_div_delivery_already_paid').remove();
		}

		if (document.getElementById('payment_movement_delivery_already_paid_label')) {
			document.getElementById('payment_movement_delivery_already_paid_label').remove();
		}

		if (document.getElementById('payment_movement_delivery_already_paid_value')) {
			document.getElementById('payment_movement_delivery_already_paid_value').remove();
		}


		if (document.getElementById('payment_movement_div_total_already_paid')) {
			document.getElementById('payment_movement_div_total_already_paid').remove();
		}

		if (document.getElementById('payment_movement_total_already_paid_label')) {
			document.getElementById('payment_movement_total_already_paid_label').remove();
		}

		if (document.getElementById('payment_movement_total_already_paid_value')) {
			document.getElementById('payment_movement_total_already_paid_value').remove();
		}



		if (document.getElementById('payment_content')) {
			document.getElementById('payment_content').remove();
		}

		if (document.getElementById('payment_content_grid')) {
			document.getElementById('payment_content_grid').remove();
		}

		if (document.getElementById('payment_movement_grid_header')) {
			document.getElementById('payment_movement_grid_header').remove();
		}

		if (document.getElementById('payment_movement_grid_detail')) {

			for (var i = 0; i < document.getElementById('payment_movement_grid_detail').rows.length; i++) {
				if (document.getElementById('payment_movement_grid_detail_checked_id_' + i.toString())) {
					document.getElementById('payment_movement_grid_detail_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('payment_movement_grid_detail_name_' + i.toString())) {
					document.getElementById('payment_movement_grid_detail_name_' + i.toString()).remove();
				}

				if (document.getElementById('payment_movement_grid_detail_value_mode_' + i.toString())) {
					document.getElementById('payment_movement_grid_detail_value_mode_' + i.toString()).remove();
				}
				if (document.getElementById('payment_movement_grid_detail_record_cancelled_' + i.toString())) {
					document.getElementById('payment_movement_grid_detail_record_cancelled_' + i.toString()).remove();
				}
			}

			document.getElementById('payment_movement_grid_detail').remove()
		}


		let total_paid = 0;

		if ((this.frm.doctype.toUpperCase() == 'IOI SALES ORDER') || (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')) {

			let already_paid = 0;

			if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {

				frappe.call({  	method: this.path_sales_document + '.ioi_sales_document_get_already_paid',
								args: 	{"doctype": this.frm.doctype,	"name": this.frm.doc.name
								},
								async: false,
								callback: function(r)	{
								already_paid = r.message;
							}
				});

			}else{

				if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {

					frappe.call({  	method: this.path_sales_document + '.ioi_sales_document_get_already_paid',
						args: 	{"doctype": this.frm.doctype,	"name": this.frm.doc.name
						},
						async: false,
						callback: function(r)	{
						already_paid = r.message;
						}
					});					

				}else{

					if ((this.frm.doc.name) && (this.frm.doc.name.trim() != '')) {

						frappe.call({  	method: this.path_sales_pos + '.ioi_sales_pos_get_already_paid',
										args: 	{	"name": this.frm.doc.name
										},
										async: false,
										callback: function(r)	{

											already_paid = r.message;

										}
						});
					}
				}

			}


			total_paid = parseFloat(already_paid); // Math.round(already_paid * exp) / exp;

		}


		html = '<div id="payment_content" style="overflow: auto; overflow-x: auto; height:350px;">';

		html += '<div id="payment_content_grid" style="overflow: auto; overflow-x: auto; height:250px;">';
		html += this.#refresh_payment_grid_header();

		if (go) {

			let me = this;
			let method = this.path_cash_desk_movement + '.ioi_cash_desk_movement_get_for_document'

			frappe.call({  	method: method,
							args: 	{	"doctype": this.frm.doctype,
										"name": this.frm.doc.name,
										"cashdesk_id": this.frm.doc.cashdesk_id
							},
							async: false,
							callback: function(r)	{


								if (r.message.length == 0) {
									html += me.#refresh_payment_empty_row();

									me.frm.fields_dict['cashdesk_id'].$input[0].readOnly = false;
								}else{
									html += '<table id="payment_movement_grid_detail" width=2140px border=1 style="border: 1px solid #E8EAEB">';

									for (var i = 0; i < r.message.length; i++) {

										html += '<input id="payment_movement_grid_detail_name_' + i.toString() + '" type="hidden" value="' + r.message[i].name + '">';
										html += '<input id="payment_movement_grid_detail_value_mode_' + i.toString() + '" type="hidden" value="' + r.message[i].value_mode + '">';
										html += '<input id="payment_movement_grid_detail_record_cancelled_' + i.toString() + '" type="hidden" value="' + r.message[i].record_cancelled + '">';

										let bgcolor_is_set = false;

										if (r.message[i].record_cancelled == 'Y') {
											html += '<tr bgcolor="#D7D7D9" style="height:30px">';
											bgcolor_is_set = true;
										}else{
											html += '<tr style="height:30px">';
										}


										html += '<td width=30px style="vertical-align: middle;" align="center">';

										html += '<input type="checkbox" id="payment_movement_grid_detail_checked_id_' + i.toString() + '" style="postion:absolute; top: 2px; left: 2px;" ';
										if (i == 0) {
											html += ' checked ';
										}
										html += '>';


										html += '</td>';

										if (r.message[i].operation_datetime != null) {
											html += '<td width=150px style="vertical-align: middle;';

											if (bgcolor_is_set) {
												html += 'color:#000000;'
											}

											html += '">&nbsp;' + r.message[i].operation_datetime.toString().substring(0, 16) + '</td>';
										}else{
											html += '<td width=150px style="vertical-align: middle;">&nbsp;></td>';
										}


										if ((r.message[i].value != null) && (r.message[i].value != 0)) {
											html += '<td width=120px style="vertical-align: middle;';

											if (bgcolor_is_set) {
												html += 'color:#000000;'
											}

											html += '" align="right">' + r.message[i].value.toFixed(2).toString() + '&nbsp;</td>';
										}else{
											html += '<td width=120px style="vertical-align: middle;" align="right">&nbsp;</td>';
										}

										if ((r.message[i].cash_rounding != null) && (r.message[i].cash_rounding != 0)) {
											html += '<td width=120px style="vertical-align: middle;';

											if (bgcolor_is_set) {
												html += 'color:#000000;'
											}

											html += '" align="right">' + r.message[i].cash_rounding.toFixed(2).toString() + '&nbsp;</td>';
										}else{
											html += '<td width=120px style="vertical-align: middle;" align="right">&nbsp;</td>';
										}


										if ((r.message[i].error_value != null) && (r.message[i].error_value != 0)) {
											html += '<td width=120px style="vertical-align: middle;';

											if (bgcolor_is_set) {
												html += 'color:#000000;'
											}

											html += '" align="right">' + r.message[i].error_value.toFixed(2).toString() + '&nbsp;</td>';
										}else{
											html += '<td width=120px style="vertical-align: middle;" align="right">&nbsp;</td>';
										}



										if (r.message[i].currency_id != null) {
											html += '<td width=60px style="vertical-align: middle;';

											if (bgcolor_is_set) {
												html += 'color:#000000;'
											}

											html += '">&nbsp;' + r.message[i].currency_id + '</td>';
										}else{
											html += '<td width=60px style="vertical-align: middle;">&nbsp;</td>';
										}

										if (r.message[i].value_mode_desc != null) {
											html += '<td width=240px style="vertical-align: middle;';

											if (bgcolor_is_set) {
												html += 'color:#000000;'
											}

											html += '">&nbsp;' + r.message[i].value_mode_desc + '</td>';
										}else{
											html += '<td width=240px style="vertical-align: middle;">&nbsp;</td>';
										}


										if (r.message[i].check_id != null) {
											html += '<td width=250px style="vertical-align: middle;';

											if (bgcolor_is_set) {
												html += 'color:#000000;'
											}

											html += '">&nbsp;' + r.message[i].check_id + '</td>';
										}else{
											html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
										}

										if (r.message[i].transaction_id != null) {
											html += '<td width=500px style="vertical-align: middle;';

											if (bgcolor_is_set) {
												html += 'color:#000000;'
											}

											html += '">&nbsp;<u><a href="#" onclick="window.open(\'/app/ioi-payment-transaction/' + r.message[i].transaction_id + '\');">' + r.message[i].transaction_id + '</a></u></td>';
										}else{
											html += '<td width=500px style="vertical-align: middle;">&nbsp;</td>';
										}


										if (r.message[i].remark != null) {
											html += '<td width=300px style="vertical-align: middle;';

											if (bgcolor_is_set) {
												html += 'color:#000000;'
											}

											html += '">&nbsp;' + r.message[i].remark + '</td>';
										}else{
											html += '<td width=300px style="vertical-align: middle;">&nbsp;</td>';
										}



										if (r.message[i].operator_id != null) {
											html += '<td width=250px style="vertical-align: middle;';

											if (bgcolor_is_set) {
												html += 'color:#000000;'
											}

											html += '">&nbsp;' + r.message[i].operator_id + '</td>';
										}else{
											html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
										}

										html += '</tr>';

									}


									html += '</table>';

									me.frm.fields_dict['cashdesk_id'].$input[0].readOnly = true;
								}

								html += '</div>';
							}
			});
		}else{
			html += this.#refresh_payment_empty_row();
			html += '</div>';

			this.frm.fields_dict['cashdesk_id'].$input[0].readOnly = false;
		}


		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'

		html += '<div id="payment_total" style="overflow: auto; overflow-x: auto; height:90px;overflow-y:hidden;">';

		let total_doc = 0;

		if (this.frm.doc.total_tvac) {
			total_doc = this.frm.doc.total_tvac;
		}

		html += '<div id="payment_movement_div_totals" style="position: relative; top: 2px; left: 0px; height:76px; width:340px; border:1px solid #E8EAEB; border-radius: 6px;">';

		// Total to paid
		html += '	<div style="position: relative; top: 2px; left: 2px; width:240px;">';
		html += '		<label id="payment_movement_total_paid_label" style="position: absolute; top: 0px; left: 2px;"><b>' + __("Total to pay") + '</b></label>';
		html += '		<label id="payment_movement_total_paid" style="position: absolute; top: 0px; left: 100px; width:195px" align="right"><b>' + parseFloat(total_doc).toFixed(2).toString() + '</b></label>';
		html += '		<label id="payment_movement_total_paid_curr" style="position: absolute; top: 0px; left: 300px;"><b>' + this.frm.doc.currency_id + '</b></label>';
		html += '	</div>';


		// Already paid
		html += '	<div style="position: relative; top: 26px; left: 2px; width:240px;">';
		html += '		<label id="payment_movement_total_already_paid_label" style="position: absolute; top: 0px; left: 2px;"><b>' + __("Already paid") + '</b></label>';
		html += '		<label id="payment_movement_total_already_paid" style="position: absolute; top: 0px; left: 100px; width:195px" align="right"><b>' + parseFloat(total_paid).toFixed(2).toString() + '</b></label>';
		html += '		<label id="payment_movement_total_already_paid_curr" style="position: absolute; top: 0px; left: 300px;"><b>' + this.frm.doc.currency_id + '</b></label>';
		html += '	</div>';

		let total_still_to_pay = total_doc - total_paid;

		let color = '#60A367';

		let exp = Math.pow(10, 2);

		if (Math.round(total_still_to_pay * exp) / exp > 0) {
			color = '#ED514C';
		}

		// Still to pay
		html += '	<div style="position: relative; top: 52px; left: 2px; width:240px;">';
		html += '		<label id="payment_movement_still_to_pay_label" style="position: absolute; top: 0px; left: 2px;"><b>' + __("Still to pay") + '</b></label>';

		let total_still_to_paid = Math.round(total_still_to_pay * exp) / exp;

		html += '		<label id="payment_movement_still_to_pay" style="position: absolute; top: 0px; left: 100px; width:195px; color:' + color + ';" align="right"><b>' + parseFloat(total_still_to_paid).toFixed(2).toString() + '</b></label>';
		html += '		<label id="payment_movement_still_to_pay_curr" style="position: absolute; top: 0px; left: 300px;color:' + color + ';"><b>' + this.frm.doc.currency_id + '</b></label>';
		html += '	</div>';

		html += '<input id ="payment_movement_total_still_to_pay"  type="hidden" value="' + total_still_to_pay + '">';
		html += '</div>';

		html += '<div id="payment_movement_div_buttons" style="position: relative; top: -74px; left: 350px; height:74px; width:1055px;">';

		// Pay now
		html += '	<div style="position: absolute; top: 1px; left: 0px; width:120px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 74px">';
		html +='			<button id="payment_movement_button_pay" class="btn btn-default ellipsis" style="height: 74px; width: 120px;" onclick="">' + __("Pay now") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Get cash
		html += '	<div style="position: absolute; top: 1px; left: 130px; width:100px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 32px;">';
		html +='			<button id="payment_movement_button_get_cash" class="btn btn-default ellipsis" style="height: 32px; width: 100px;" onclick="">' + __("Get cash") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Put cash
		html += '	<div style="position: absolute; top: 1px; left: 240px; width:100px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 32px;">';
		html +='			<button id="payment_movement_button_put_cash" class="btn btn-default ellipsis" style="height: 32px; width: 100px;" onclick="">' + __("Put cash") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Cancel selected payment
		html += '	<div style="position: absolute; top: 43px; left: 130px; width:210px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 32px;">';
		html +='			<button id="payment_movement_button_cancel" class="btn btn-default ellipsis" style="height: 32px; width: 210px;" onclick="">' + __("Cancel selected movement") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Open cash desk
		html += '	<div style="position: absolute; top: 1px; left: 350px; width:160px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 32px;">';
		html +='			<button id="payment_movement_button_open_cash_desk" class="btn btn-default ellipsis" style="height: 32px; width: 160px;" onclick="">' + __("Open cash desk") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Close cash desk
		html += '	<div style="position: absolute; top: 43px; left: 350px; width:160px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 32px;">';
		html +='			<button id="payment_movement_button_close_cash_desk" class="btn btn-default ellipsis" style="height: 32px; width: 160px;" onclick="">' + __("Close cash desk") + '</button>';
		html += '		</div>';
		html += '	</div>';


		// To pay on order
		html += '	<div id="payment_movement_div_pay_on_order" style="position: absolute; top: 0px; left: 520px; height:76px; width:220px; border:1px solid #E8EAEB; border-radius: 6px;" hidden="true">';
		html += '		<div style="position: relative; top: 8px; left: 10px; width:300px;">';
		html += '			<label id="payment_movement_pay_on_order_label" style="position: absolute; top: 0px; left: 2px;"></label>';
		html += '		</div>';

		html += '		<div style="position: relative; top: 36px; left: 0px; height:0px; width:218px; border:1px solid #E8EAEB">';
		html += '		</div>';


		html += '		<div style="position: relative; top: 44px; left: 10px; width:300px;">';
		html += '			<label id="payment_movement_pay_on_order_value" style="position: absolute; top: 0px; left: 2px;"></label>';
		html += '		</div>';
		html += '	</div>';


		let order_paid = 0;
		let delivery_paid = 0;

		if ((this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE')) {

			html += '	<div id="payment_movement_div_order_already_paid" style="position: absolute; top: 0px; left: 520px; height:76px; width:170px; border:1px solid #E8EAEB; border-radius: 6px;">';
			html += '		<div style="position: relative; top: 8px; left: 10px; width:300px;">';
			html += '			<label id="payment_movement_order_already_paid_label" style="position: absolute; top: 0px; left: 2px;"><b>' + __("Paid on order") + '</b></label>';
			html += '		</div>';

			html += '		<div style="position: relative; top: 36px; left: 0px; height:0px; width:168px; border:1px solid #E8EAEB">';
			html += '		</div>';


			html += '		<div style="position: relative; top: 44px; left: 10px; width:300px;">';
			if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')	{
				if (this.frm.doc.total_order_paid) {
					html += '		<label id="payment_movement_order_already_paid_value" style="position: absolute; top: 0px; left: 2px;"><b>' + parseFloat(this.frm.doc.total_order_paid).toFixed(2) + '&nbsp;' + this.frm.doc.currency_id + '</b></label>';
				}else{
					html += '		<label id="payment_movement_order_already_paid_value" style="position: absolute; top: 0px; left: 2px;"><b>' + '0.00&nbsp;' + this.frm.doc.currency_id + '</b></label>';
				}
			}else{


				let meth = this.path_sales_invoice + '.ioi_sales_invoice_get_order_paid_and_delivery_paid';

				frappe.call({  	method: meth,
								args: {"name": this.frm.doc.name},
								async: false,
								callback:function(r)	{

									order_paid = r.message.order_paid;
									delivery_paid = r.message.delivery_paid;

								}
				});

				html += '		<label id="payment_movement_order_already_paid_value" style="position: absolute; top: 0px; left: 2px;"><b>' + order_paid.toFixed(2) + ' ' + this.frm.doc.currency_id + '</b></label>';
			}
			html += '		</div>';
			html += '	</div>';

		}

		if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {

			html += '	<div id="payment_movement_div_delivery_already_paid" style="position: absolute; top: 0px; left: 700px; height:76px; width:170px; border:1px solid #E8EAEB; border-radius: 6px;">';
			html += '		<div style="position: relative; top: 8px; left: 10px; width:300px;">';
			html += '			<label id="payment_movement_delivery_already_paid_label" style="position: absolute; top: 0px; left: 2px;"><b>' + __("Paid on delivery") + '</b></label>';
			html += '		</div>';

			html += '		<div style="position: relative; top: 36px; left: 0px; height:0px; width:168px; border:1px solid #E8EAEB">';
			html += '		</div>';


			html += '		<div style="position: relative; top: 44px; left: 10px; width:300px;">';
			html += '			<label id="payment_movement_delivery_already_paid_value" style="position: absolute; top: 0px; left: 2px;"><b>' + delivery_paid.toFixed(2) + ' ' + this.frm.doc.currency_id + '</b></label>';
			html += '		</div>';
			html += '	</div>';

			html += '	<div id="payment_movement_div_total_already_paid" style="position: absolute; top: 0px; left: 880px; height:76px; width:170px; border:1px solid #E8EAEB; border-radius: 6px;">';
			html += '		<div style="position: relative; top: 8px; left: 10px; width:300px;">';
			html += '			<label id="payment_movement_total_already_paid_label" style="position: absolute; top: 0px; left: 2px;"><b>' + __("Total paid") + '</b></label>';
			html += '		</div>';

			html += '		<div style="position: relative; top: 36px; left: 0px; height:0px; width:168px; border:1px solid #E8EAEB">';
			html += '		</div>';

			let total_already_paid = parseFloat(order_paid) + parseFloat(delivery_paid)


			html += '		<div style="position: relative; top: 44px; left: 10px; width:300px;">';
			html += '			<label id="payment_movement_total_already_paid_value" style="position: absolute; top: 0px; left: 2px;"><b>' + total_already_paid.toFixed(2) + ' ' + this.frm.doc.currency_id + '</b></label>';
			html += '		</div>';
			html += '	</div>';

		}


		html += '</div>';

		html += '</div>';



		this.frm.fields_dict[fieldname].$wrapper.append(html);

		let me = this;

		this.#sleep(400).then(() => {

			if (document.getElementById('payment_movement_grid_detail')) {

				let fct_click = function() {

					for (var i = 0; i < document.getElementById('payment_movement_grid_detail').rows.length; i++) {
						if (document.getElementById('payment_movement_grid_detail_checked_id_' + i.toString())) {
							document.getElementById('payment_movement_grid_detail_checked_id_' + i.toString()).checked = false;
						}
					}

					this.checked = true;


				};

				for (var i = 0; i < document.getElementById('payment_movement_grid_detail').rows.length; i++) {
					if (document.getElementById('payment_movement_grid_detail_checked_id_' + i.toString())) {
						document.getElementById('payment_movement_grid_detail_checked_id_' + i.toString()).onclick = fct_click;
					}
				}
			}

			let fct_keydown = function(event) {

				if (event.keyCode == 13) {
					return false;
				}

			};

			let fct_keyup = function(event) {

				if (event.keyCode == 13) {
					return false;
				}

			};



			let fct_button_pay = function () {

				me.pay_now(fieldname);

			};

			document.getElementById('payment_movement_button_pay').onclick = fct_button_pay;
			document.getElementById('payment_movement_button_pay').onkeydown = fct_keydown;
			document.getElementById('payment_movement_button_pay').onkeyup = fct_keyup;


			let fct_button_cancel = function () {

				me.pay_cancel_selected(fieldname);

			};

			document.getElementById('payment_movement_button_cancel').onclick = fct_button_cancel;
			document.getElementById('payment_movement_button_cancel').onkeydown = fct_keydown;
			document.getElementById('payment_movement_button_cancel').onkeyup = fct_keyup;

			let fct_button_get_cash = function () {

				me.pay_get_cash(fieldname);

			};

			document.getElementById('payment_movement_button_get_cash').onclick = fct_button_get_cash;
			document.getElementById('payment_movement_button_get_cash').onkeydown = fct_keydown;
			document.getElementById('payment_movement_button_get_cash').onkeyup = fct_keyup;

			let fct_button_put_cash = function () {

				me.pay_put_cash(fieldname);

			};

			document.getElementById('payment_movement_button_put_cash').onclick = fct_button_put_cash;
			document.getElementById('payment_movement_button_put_cash').onkeydown = fct_keydown;
			document.getElementById('payment_movement_button_put_cash').onkeyup = fct_keyup;


			let fct_button_open_cash_desk = function () {

				silicon_ioi.doctype.ioiCashDesk.open_cash_desk(me.frm.doc.cashdesk_id);

			};

			document.getElementById('payment_movement_button_open_cash_desk').onclick = fct_button_open_cash_desk;
			document.getElementById('payment_movement_button_open_cash_desk').onkeydown = fct_keydown;
			document.getElementById('payment_movement_button_open_cash_desk').onkeyup = fct_keyup;


			let fct_button_close_cash_desk = function () {

				silicon_ioi.doctype.ioiCashDesk.close_cash_desk(me.frm.doc.cashdesk_id);



			};

			document.getElementById('payment_movement_button_close_cash_desk').onclick = fct_button_close_cash_desk;
			document.getElementById('payment_movement_button_close_cash_desk').onkeydown = fct_keydown;
			document.getElementById('payment_movement_button_close_cash_desk').onkeyup = fct_keyup;

			document.getElementById('payment_movement_div_totals').style.height = '74px';
			document.getElementById('payment_movement_div_buttons').style.height = '74px';

			me.refresh_payment_pay_on_order();

		});
	}

	#refresh_payment_grid_header()
	{
		let html = '';

		let bgcolor = '';

		if (this.is_dark_mode == 0) {
			bgcolor = '#D0E7FB';
		}else{
			bgcolor = '#1C2126';
		}

		html += '<table id="payment_movement_grid_header" bgcolor="' + bgcolor + '" width=2140px border=1 style="border: 1px solid #E8EAEB">';
		html += '<tr style="height:30px">';
		html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=150px style="vertical-align: middle;"><b>&nbsp;' + __("Operation date") + '</b></td>';
		html += '<td width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Value") + '</b></td>';
		html += '<td width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Cash rounding") + '</b></td>';
		html += '<td width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Error value") + '</b></td>';
		html += '<td width=60px style="vertical-align: middle;"><b>&nbsp;' + __("Curr.") + '</b></td>';
		html += '<td width=240px style="vertical-align: middle;"><b>&nbsp;' + __("Value mode") + '</b></td>';
		html += '<td width=250px style="vertical-align: middle;"><b>&nbsp;' + __("Check id") + '</b></td>';
		html += '<td width=500px style="vertical-align: middle;"><b>&nbsp;' + __("Transaction id") + '</b></td>';
		html += '<td width=300px style="vertical-align: middle;"><b>&nbsp;' + __("Remark") + '</b></td>';
		html += '<td width=250px style="vertical-align: middle;"><b>&nbsp;' + __("Operator") + '</b></td>';
		html += '</tr>';
		html += '</table>';

		return html;
	}

	#refresh_payment_empty_row()
	{
		let html = '';

		html += '<table id="payment_movement_grid_detail" width=2140px border=1 style="border: 1px solid #E8EAEB">';
		html += '<tr style="height:30px">';
		html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=60px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=240px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=500px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=300px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
		html += '</tr>';
		html += '</table>';

		return html;
	}

	pay_now(fieldname)
	{
		if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
			if ((this.frm.doc.ioistatus != 1) && (this.frm.doc.ioistatus != 2)) {
				frappe.msgprint({title: __("Message"), message: __("The document has to be in standby or confirmed"), indicator: "red"});
				raise;
			}
		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
			if ((this.frm.doc.ioistatus != 0) && (this.frm.doc.ioistatus != 1)) {
				frappe.msgprint({title: __("Message"), message: __("The document has to be in preparation or delivered"), indicator: "red"});
				raise;
			}

		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
			if (this.frm.doc.ioistatus != 1) {
				frappe.msgprint({title: __("Message"), message: __("The document has to be in standby"), indicator: "red"});
				raise;
			}
		}

		if ((!this.frm.doc.delivery_customer_id) || ((this.frm.doc.delivery_customer_id) && (this.frm.doc.delivery_customer_id.trim() == ''))) {
			frappe.msgprint({title: __("Message"), message: __("Delivery customer is mandatory"), indicator: "red"});
			raise;
		}

		if ((!this.frm.doc.order_customer_id) || ((this.frm.doc.order_customer_id) && (this.frm.doc.order_customer_id.trim() == ''))) {
			frappe.msgprint({title: __("Message"), message: __("Order customer is mandatory"), indicator: "red"});
			raise;
		}

		if ((!this.frm.doc.invoice_customer_id) || ((this.frm.doc.invoice_customer_id) && (this.frm.doc.invoice_customer_id.trim() == ''))) {
			frappe.msgprint({title: __("Message"), message: __("Invoice customer is mandatory"), indicator: "red"});
			raise;
		}

		if ((!this.frm.doc.payment_term_id) || ((this.frm.doc.payment_term_id) && (this.frm.doc.payment_term_id.trim() == ''))) {
			frappe.msgprint({title: __("Message"), message: __("Payment term is mandatory"), indicator: "red"});
			raise;
		}

		if (parseFloat(this.frm.doc.total_tvac) <= 0) {
			frappe.msgprint({title: __("Message"), message: __("Total incl. VAT has to be greater than zero"), indicator: "red"});
			raise;
		}

		if (this.frm.doc.cashdesk_payment_status_id == 2) {
			frappe.msgprint({title: __("Message"), message: __("This payment is closed"), indicator: "red"});
			raise;
		}

		if ((!this.frm.doc.cashdesk_id) || ((this.frm.doc.cashdesk_id) && (this.frm.doc.cashdesk_id.trim() == ''))) {
			frappe.msgprint({title: __("Message"), message: __("Cash desk is mandatory before executing this action"), indicator: "red"});
			raise;
		}

		let meth = this.path_cash_desk + '.ioi_cash_desk_get_info';

		let cashdesk_status = 0;

		frappe.call({  	method: meth,
						args: {"name": this.frm.doc.cashdesk_id},
						async: false,
						callback:function(r)	{

							if (r.message.length != 0) {
								cashdesk_status = r.message[0].ioistatus;
							}
						}
		});

		if (cashdesk_status != 1) {
			frappe.msgprint({title: __("Message"), message: __("This cash desk is not open"), indicator: "red"});
			raise;
		}

		let can_pay = true;

		if (((!this.frm.doc.cashdesk_cash_value_enabled) || ((this.frm.doc.cashdesk_cash_value_enabled) && (this.frm.doc.cashdesk_cash_value_enabled != 1))) &&
			((!this.frm.doc.cashdesk_bank_card_value_enabled) || ((this.frm.doc.cashdesk_bank_card_value_enabled) && (this.frm.doc.cashdesk_bank_card_value_enabled != 1))) &&
			((!this.frm.doc.cashdesk_credit_card_value_enabled) || ((this.frm.doc.cashdesk_credit_card_value_enabled) && (this.frm.doc.cashdesk_credit_card_value_enabled != 1))) &&
			((!this.frm.doc.cashdesk_check_value_enabled) || ((this.frm.doc.cashdesk_check_value_enabled) && (this.frm.doc.cashdesk_check_value_enabled != 1))) &&
			((!this.frm.doc.cashdesk_emoney_value_enabled) || ((this.frm.doc.cashdesk_emoney_value_enabled) && (this.frm.doc.cashdesk_emoney_value_enabled != 1))) &&
			((!this.frm.doc.cashdesk_check_spec_1_value_enabled) || ((this.frm.doc.cashdesk_check_spec_1_value_enabled) && (this.frm.doc.cashdesk_check_spec_1_value_enabled != 1))) &&
			((!this.frm.doc.cashdesk_check_spec_2_value_enabled) || ((this.frm.doc.cashdesk_check_spec_2_value_enabled) && (this.frm.doc.cashdesk_check_spec_2_value_enabled != 1))) &&
			((!this.frm.doc.cashdesk_check_spec_3_value_enabled) || ((this.frm.doc.cashdesk_check_spec_3_value_enabled) && (this.frm.doc.cashdesk_check_spec_3_value_enabled != 1)))) {
			can_pay = false;
		}

		if (can_pay) {

			let me = this;

			let fct_callback_action = function() {

				me.refresh_payment_movements(fieldname);

				me.#sleep(1000).then(() => {

					me.frm.set_value('cashdesk_payment_status_id', 0);
					me.frm.save();

					if (parseFloat(document.getElementById('payment_movement_total_still_to_pay').value) <= 0) {
						me.frm.set_value('cashdesk_payment_status_id', 2);
					}else{
						if (parseFloat(document.getElementById('payment_movement_total_still_to_pay').value) >= parseFloat(me.frm.doc.total_tvac)) {
							me.frm.set_value('cashdesk_payment_status_id', 0);
						}else{
							me.frm.set_value('cashdesk_payment_status_id', 1);
						}
					}

					me.frm.save();
					me.refresh_payment_status('cashdesk_html_status');

					me.check_if_payment_movements();
				});

			}

			if ((this.frm.is_dirty()) || (this.frm.is_new())) {
				let me = this;

				let fct_callback = function () {
					me.#sleep(1000).then(() => {
						silicon_ioi.doctype.ioiCashDesk.do_pay_now( me.frm.doc.cashdesk_id, me.frm.doctype, me.frm.doc.name, me.frm.doc.site_id, me.frm.doc.division_id, me.frm.doc.currency_id, me.frm.doc.currency_rate,
																	me.frm.doc.currency_rate_inv, me.frm.doc.total_tvac, document.getElementById('payment_movement_total_still_to_pay').value,
																	me.frm.doc.cashdesk_check_id_required, me.frm.doc.cashdesk_check_spec_1_id_required, me.frm.doc.cashdesk_check_spec_1_description,
																	me.frm.doc.cashdesk_check_spec_2_id_required, me.frm.doc.cashdesk_check_spec_2_description,	me.frm.doc.cashdesk_check_spec_3_id_required,
																	me.frm.doc.cashdesk_check_spec_3_description, me.frm.doc.cashdesk_cash_value_enabled, me.frm.doc.cashdesk_bank_card_value_enabled,
																	me.frm.doc.cashdesk_credit_card_value_enabled, me.frm.doc.cashdesk_check_value_enabled,	me.frm.doc.cashdesk_emoney_value_enabled,
																	me.frm.doc.cashdesk_check_spec_1_value_enabled, me.frm.doc.cashdesk_check_spec_2_value_enabled,	me.frm.doc.cashdesk_check_spec_3_value_enabled,
																	me.frm.doc.cashdesk_check_spec_1_electronic_transac, me.frm.doc.cashdesk_check_spec_2_electronic_transac, me.frm.doc.cashdesk_check_spec_3_electronic_transac,
																	fct_callback_action, frappe.session.user,
																	me.frm.doc.cashdesk_payconiq_enabled, me.frm.doc.cashdesk_ccv_enabled, me.frm.doc.cashdesk_check_spec_1_type,
																	me.frm.doc.cashdesk_check_spec_2_type,	me.frm.doc.cashdesk_check_spec_3_type
																	);
					});
				};

				this.frm.save('Save', fct_callback);
			}else {

				let me = this;
				silicon_ioi.doctype.ioiCashDesk.do_pay_now(	me.frm.doc.cashdesk_id, me.frm.doctype, me.frm.doc.name, me.frm.doc.site_id, me.frm.doc.division_id, me.frm.doc.currency_id, me.frm.doc.currency_rate,
															me.frm.doc.currency_rate_inv, me.frm.doc.total_tvac, document.getElementById('payment_movement_total_still_to_pay').value,
															me.frm.doc.cashdesk_check_id_required, me.frm.doc.cashdesk_check_spec_1_id_required, me.frm.doc.cashdesk_check_spec_1_description,
															me.frm.doc.cashdesk_check_spec_2_id_required, me.frm.doc.cashdesk_check_spec_2_description,	me.frm.doc.cashdesk_check_spec_3_id_required,
															me.frm.doc.cashdesk_check_spec_3_description, me.frm.doc.cashdesk_cash_value_enabled, me.frm.doc.cashdesk_bank_card_value_enabled,
															me.frm.doc.cashdesk_credit_card_value_enabled, me.frm.doc.cashdesk_check_value_enabled, me.frm.doc.cashdesk_emoney_value_enabled,
															me.frm.doc.cashdesk_check_spec_1_value_enabled, me.frm.doc.cashdesk_check_spec_2_value_enabled,	me.frm.doc.cashdesk_check_spec_3_value_enabled,
															me.frm.doc.cashdesk_check_spec_1_electronic_transac, me.frm.doc.cashdesk_check_spec_2_electronic_transac, me.frm.doc.cashdesk_check_spec_3_electronic_transac,
															fct_callback_action, frappe.session.user,
															me.frm.doc.cashdesk_payconiq_enabled, me.frm.doc.cashdesk_ccv_enabled, me.frm.doc.cashdesk_check_spec_1_type,
															me.frm.doc.cashdesk_check_spec_2_type,	me.frm.doc.cashdesk_check_spec_3_type
															);



			}
		}else{
			frappe.msgprint({title: __("Message"), message: __("No payment allowed for this cash desk"), indicator: "red"});
			raise;
		}
	}

	pay_cancel_selected(fieldname)
	{
		if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
			if ((this.frm.doc.ioistatus != 1) && (this.frm.doc.ioistatus != 2)) {
				frappe.msgprint({title: __("Message"), message: __("The document has to be in standby or confirmed"), indicator: "red"});
				raise;
			}
		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
			if ((this.frm.doc.ioistatus != 0) && (this.frm.doc.ioistatus != 1)) {
				frappe.msgprint({title: __("Message"), message: __("The document has to be in preparation or delivered"), indicator: "red"});
				raise;
			}

		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
			if (this.frm.doc.ioistatus != 1) {
				frappe.msgprint({title: __("Message"), message: __("The document has to be in standby"), indicator: "red"});
				raise;
			}
		}

		if ((!this.frm.doc.delivery_customer_id) || ((this.frm.doc.delivery_customer_id) && (this.frm.doc.delivery_customer_id.trim() == ''))) {
			frappe.msgprint({title: __("Message"), message: __("Delivery customer is mandatory"), indicator: "red"});
			raise;
		}

		if ((!this.frm.doc.order_customer_id) || ((this.frm.doc.order_customer_id) && (this.frm.doc.order_customer_id.trim() == ''))) {
			frappe.msgprint({title: __("Message"), message: __("Order customer is mandatory"), indicator: "red"});
			raise;
		}

		if ((!this.frm.doc.invoice_customer_id) || ((this.frm.doc.invoice_customer_id) && (this.frm.doc.invoice_customer_id.trim() == ''))) {
			frappe.msgprint({title: __("Message"), message: __("Invoice customer is mandatory"), indicator: "red"});
			raise;
		}

		if ((!this.frm.doc.payment_term_id) || ((this.frm.doc.payment_term_id) && (this.frm.doc.payment_term_id.trim() == ''))) {
			frappe.msgprint({title: __("Message"), message: __("Payment term is mandatory"), indicator: "red"});
			raise;
		}

		if ((!this.frm.doc.cashdesk_id) || ((this.frm.doc.cashdesk_id) && (this.frm.doc.cashdesk_id.trim() == ''))) {
			frappe.msgprint({title: __("Message"), message: __("Cash desk is mandatory before executing this action"), indicator: "red"});
			raise;
		}


		if (!document.getElementById('payment_movement_grid_detail_checked_id_0')) {
			frappe.msgprint({title: __("Message"), message: __("No payment to cancel"), indicator: "red"});
			raise;
		}

		let payment_name = '';

		for (var i = 0; i < document.getElementById('payment_movement_grid_detail').rows.length; i++) {

			if (document.getElementById('payment_movement_grid_detail_checked_id_' + i.toString())) {

				if (document.getElementById('payment_movement_grid_detail_checked_id_' + i.toString()).checked) {

					payment_name = document.getElementById('payment_movement_grid_detail_name_' + i.toString()).value;
					break;
				}

			}
		}

		if (payment_name == '') {
			frappe.msgprint({title: __("Message"), message: __("No payment to cancel"), indicator: "red"});
			raise;
		}

		let meth = this.path_cash_desk + '.ioi_cash_desk_get_info';

		let cashdesk_status = 0;

		frappe.call({  	method: meth,
						args: {"name": this.frm.doc.cashdesk_id},
						async: false,
						callback:function(r)	{

							if (r.message.length != 0) {
								cashdesk_status = r.message[0].ioistatus;
							}
						}
		});

		if (cashdesk_status != 1) {
			frappe.msgprint({title: __("Message"), message: __("This cash desk is not open"), indicator: "red"});
			raise;
		}



		if ((this.frm.is_dirty()) || (this.frm.is_new())) {
			let me = this;

			let fct_callback = function () {
				me.#sleep(1000).then(() => {
					me.do_pay_cancel_selected(fieldname, payment_name);
				});
			};

			this.frm.save('Save', fct_callback);
		}else {
			this.do_pay_cancel_selected(fieldname, payment_name);
		}
	}

	do_pay_cancel_selected(fieldname, payment_name)
	{
		let top = 10;

		let s =  __('Are you sure to cancel this selected payment ?') + '';
		let msg = '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';

		top += 30;
		msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + __("Remark") + '</label>';


		if (document.getElementById('payment_movement_cancel_remark')) {
			document.getElementById('payment_movement_cancel_remark').remove();
		}

		top += 30;
		msg += '<input id="payment_movement_cancel_remark" type="text" style="position: absolute; top: ' + top.toString() + 'px; left: 20px; width:350px" class="input-with-feedback form-control bold" value="">';


		top += 20;
		msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';

		let me = this;


		var d = frappe.confirm(	msg,
						() => 	{
									if (document.getElementById('payment_movement_cancel_remark').value.trim() == '') {
										frappe.msgprint({title: __("Message"), message: __("Remark is mandatory"), indicator: "red"});
										raise;
									}

									document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

									let method = me.path_cash_desk_movement + '.ioi_cash_desk_movement_cancel_movement';

									frappe.call({  	method: method,
													args: {"name_to_cancel": payment_name, "remark": document.getElementById('payment_movement_cancel_remark').value},
													async: false,
													callback:function(r)	{

														if (r.message.error == 0) {
															me.refresh_payment_movements(fieldname);

															me.#sleep(1000).then(() => {

																me.frm.set_value('cashdesk_payment_status_id', 0);
																me.frm.save();


																if (parseFloat(document.getElementById('payment_movement_total_still_to_pay').value) <= 0) {
																	me.frm.set_value('cashdesk_payment_status_id', 2);
																}else{
																	if (parseFloat(document.getElementById('payment_movement_total_still_to_pay').value) >= parseFloat(me.frm.doc.total_tvac)) {
																		me.frm.set_value('cashdesk_payment_status_id', 0);
																	}else{
																		me.frm.set_value('cashdesk_payment_status_id', 1);
																	}
																}

																me.frm.save();
																me.refresh_payment_status('cashdesk_html_status');

																me.check_if_payment_movements();
															});
														}else
														{	document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
															frappe.msgprint({title: __("Message"), message: r.message.error_message, indicator: "red"});
															raise;
														}
													}
									});


								},
						() => 	{
								}
		);

		d.$wrapper.find('.modal-dialog').css("width", "400px");

		this.#sleep(1000).then(() => {

			document.getElementById('payment_movement_cancel_remark').focus();

		});


	}

	pay_get_cash(fieldname)
	{
		if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
			if ((this.frm.doc.ioistatus != 1) && (this.frm.doc.ioistatus != 2)) {
				frappe.msgprint({title: __("Message"), message: __("The document has to be in standby or confirmed"), indicator: "red"});
				raise;
			}
		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
			if ((this.frm.doc.ioistatus != 0) && (this.frm.doc.ioistatus != 1)) {
				frappe.msgprint({title: __("Message"), message: __("The document has to be in preparation or delivered"), indicator: "red"});
				raise;
			}

		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
			if (this.frm.doc.ioistatus != 1) {
				frappe.msgprint({title: __("Message"), message: __("The document has to be in standby"), indicator: "red"});
				raise;
			}
		}

		if ((!this.frm.doc.delivery_customer_id) || ((this.frm.doc.delivery_customer_id) && (this.frm.doc.delivery_customer_id.trim() == ''))) {
			frappe.msgprint({title: __("Message"), message: __("Delivery customer is mandatory"), indicator: "red"});
			raise;
		}

		if ((!this.frm.doc.order_customer_id) || ((this.frm.doc.order_customer_id) && (this.frm.doc.order_customer_id.trim() == ''))) {
			frappe.msgprint({title: __("Message"), message: __("Order customer is mandatory"), indicator: "red"});
			raise;
		}

		if ((!this.frm.doc.invoice_customer_id) || ((this.frm.doc.invoice_customer_id) && (this.frm.doc.invoice_customer_id.trim() == ''))) {
			frappe.msgprint({title: __("Message"), message: __("Invoice customer is mandatory"), indicator: "red"});
			raise;
		}

		if ((!this.frm.doc.payment_term_id) || ((this.frm.doc.payment_term_id) && (this.frm.doc.payment_term_id.trim() == ''))) {
			frappe.msgprint({title: __("Message"), message: __("Payment term is mandatory"), indicator: "red"});
			raise;
		}

		if (this.frm.doc.cashdesk_cash_value_enabled != 1) {
			frappe.msgprint({title: __("Message"), message: __("Cash value is disabled"), indicator: "red"});
			raise;
		}

		if ((!this.frm.doc.cashdesk_id) || ((this.frm.doc.cashdesk_id) && (this.frm.doc.cashdesk_id.trim() == ''))) {
			frappe.msgprint({title: __("Message"), message: __("Cash desk is mandatory before executing this action"), indicator: "red"});
			raise;
		}

		let meth = this.path_cash_desk + '.ioi_cash_desk_get_info';

		let current_cash_value = 0;
		let role_to_get_cash = '';
		let cashdesk_status = 0;

		frappe.call({  	method: meth,
						args: {"name": this.frm.doc.cashdesk_id},
						async: false,
						callback:function(r)	{

							if (r.message.length != 0) {
								current_cash_value = r.message[0].cash_value;
								role_to_get_cash = r.message[0].role_to_get_cash;
								cashdesk_status = r.message[0].ioistatus;

							}
						}
		});

		if (cashdesk_status != 1) {
			frappe.msgprint({title: __("Message"), message: __("This cash desk is not open"), indicator: "red"});
			raise;
		}

		if (parseFloat(current_cash_value) == 0) {
			frappe.msgprint({title: __("Message"), message: __("No enough cash in this cash desk"), indicator: "red"});
			raise;
		}


		let can_get_cash = false;

		if (frappe.user.name.toUpperCase() == 'ADMINISTRATOR') {
			can_get_cash = true;
		}else {
			if (role_to_get_cash != '') {
				if (frappe.user.has_role(role_to_get_cash)) {
					can_get_cash = true;
				}
			}
		}


		if (can_get_cash) {

			let ioistatus = 0;

			if ((this.frm.doc.ioistatus) && (this.frm.doc.ioistatus != 0)) {
				ioistatus = this.frm.doc.ioistatus;
			}

			let me = this;

			let fct_callback_action = function(cash_value, cb_cashback) {

				me.refresh_payment_movements(fieldname);

				me.#sleep(1000).then(() => {

					me.frm.set_value('cashdesk_payment_status_id', 0);
					me.frm.save();


					if (parseFloat(document.getElementById('payment_movement_total_still_to_pay').value) <= 0) {
						me.frm.set_value('cashdesk_payment_status_id', 2);
					}else{
						if (parseFloat(document.getElementById('payment_movement_total_still_to_pay').value) >= parseFloat(me.frm.doc.total_tvac)) {
							me.frm.set_value('cashdesk_payment_status_id', 0);
						}else{
							me.frm.set_value('cashdesk_payment_status_id', 1);
						}
					}

					me.frm.save();
					me.refresh_payment_status('cashdesk_html_status');

					me.check_if_payment_movements();
				});

			}

			if ((this.frm.is_dirty()) || (this.frm.is_new())) {
				let me = this;

				let fct_callback = function () {

					me.#sleep(1000).then(() => {

						silicon_ioi.doctype.ioiCashDesk.do_pay_get_cash(me.frm.doc.cashdesk_id, me.frm.doctype, me.frm.doc.name, ioistatus, me.frm.doc.total_tvac,	current_cash_value, fct_callback_action);
					});
				};

				this.frm.save('Save', fct_callback);
			}else {
				silicon_ioi.doctype.ioiCashDesk.do_pay_get_cash(this.frm.doc.cashdesk_id, this.frm.doctype, this.frm.doc.name, ioistatus, this.frm.doc.total_tvac, current_cash_value, fct_callback_action);
			}
		}else{
			frappe.msgprint({title: __("Message"), message: __("You're not authorized to get cash from this cash desk"), indicator: "red"});
			raise;
		}
	}


	pay_put_cash(fieldname)
	{
		if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
			if ((this.frm.doc.ioistatus != 1) && (this.frm.doc.ioistatus != 2)) {
				frappe.msgprint({title: __("Message"), message: __("The document has to be in standby or confirmed"), indicator: "red"});
				raise;
			}
		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
			if ((this.frm.doc.ioistatus != 0) && (this.frm.doc.ioistatus != 1)) {
				frappe.msgprint({title: __("Message"), message: __("The document has to be in preparation or delivered"), indicator: "red"});
				raise;
			}

		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
			if (this.frm.doc.ioistatus != 1) {
				frappe.msgprint({title: __("Message"), message: __("The document has to be in standby"), indicator: "red"});
				raise;
			}
		}

		if ((!this.frm.doc.delivery_customer_id) || ((this.frm.doc.delivery_customer_id) && (this.frm.doc.delivery_customer_id.trim() == ''))) {
			frappe.msgprint({title: __("Message"), message: __("Delivery customer is mandatory"), indicator: "red"});
			raise;
		}

		if ((!this.frm.doc.order_customer_id) || ((this.frm.doc.order_customer_id) && (this.frm.doc.order_customer_id.trim() == ''))) {
			frappe.msgprint({title: __("Message"), message: __("Order customer is mandatory"), indicator: "red"});
			raise;
		}

		if ((!this.frm.doc.invoice_customer_id) || ((this.frm.doc.invoice_customer_id) && (this.frm.doc.invoice_customer_id.trim() == ''))) {
			frappe.msgprint({title: __("Message"), message: __("Invoice customer is mandatory"), indicator: "red"});
			raise;
		}

		if ((!this.frm.doc.payment_term_id) || ((this.frm.doc.payment_term_id) && (this.frm.doc.payment_term_id.trim() == ''))) {
			frappe.msgprint({title: __("Message"), message: __("Payment term is mandatory"), indicator: "red"});
			raise;
		}

		if (this.frm.doc.cashdesk_cash_value_enabled != 1) {
			frappe.msgprint({title: __("Message"), message: __("Cash value is disabled"), indicator: "red"});
			raise;
		}


		if ((!this.frm.doc.cashdesk_id) || ((this.frm.doc.cashdesk_id) && (this.frm.doc.cashdesk_id.trim() == ''))) {
			frappe.msgprint({title: __("Message"), message: __("Cash desk is mandatory before executing this action"), indicator: "red"});
			raise;
		}

		let meth = this.path_cash_desk + '.ioi_cash_desk_get_info';


		let role_to_put_cash = '';
		let cashdesk_status = 0;

		frappe.call({  	method: meth,
						args: {"name": this.frm.doc.cashdesk_id},
						async: false,
						callback:function(r)	{

							if (r.message.length != 0) {
								role_to_put_cash = r.message[0].role_to_put_cash;
								cashdesk_status = r.message[0].ioistatus;

							}
						}
		});

		if (cashdesk_status != 1) {
			frappe.msgprint({title: __("Message"), message: __("This cash desk is not open"), indicator: "red"});
			raise;
		}



		let can_put_cash = false;

		if (frappe.user.name.toUpperCase() == 'ADMINISTRATOR') {
			can_put_cash = true;
		}else {
			if (role_to_put_cash != '') {
				if (frappe.user.has_role(role_to_put_cash)) {
					can_put_cash = true;
				}
			}
		}

		if (can_put_cash) {

			let me = this;

			let fct_callback_action = function(cash_value, cb_cashback) {

				me.refresh_payment_movements(fieldname);

				me.#sleep(1000).then(() => {

					me.frm.set_value('cashdesk_payment_status_id', 0);
					me.frm.save();


					if (parseFloat(document.getElementById('payment_movement_total_still_to_pay').value) <= 0) {
						me.frm.set_value('cashdesk_payment_status_id', 2);
					}else{
						if (parseFloat(document.getElementById('payment_movement_total_still_to_pay').value) >= parseFloat(me.frm.doc.total_tvac)) {
							me.frm.set_value('cashdesk_payment_status_id', 0);
						}else{
							me.frm.set_value('cashdesk_payment_status_id', 1);
						}
					}

					me.frm.save();
					me.refresh_payment_status('cashdesk_html_status');

					me.check_if_payment_movements();
				});

			}

			let ioistatus = 0;

			if ((this.frm.doc.ioistatus) && (this.frm.doc.ioistatus != 0)) {
				ioistatus = this.frm.doc.ioistatus;
			}


			if ((this.frm.is_dirty()) || (this.frm.is_new())) {
				let me = this;

				let fct_callback = function () {
					me.#sleep(1000).then(() => {
						silicon_ioi.doctype.ioiCashDesk.do_pay_put_cash(me.frm.doc.cashdesk_id, me.frm.doctype, me.frm.doc.name, ioistatus, me.frm.doc.total_tvac,	fct_callback_action);
					});
				};

				this.frm.save('Save', fct_callback);
			}else {
				silicon_ioi.doctype.ioiCashDesk.do_pay_put_cash(this.frm.doc.cashdesk_id, this.frm.doctype, this.frm.doc.name, ioistatus, me.frm.doc.total_tvac, fct_callback_action);

			}
		}else{
			frappe.msgprint({title: __("Message"), message: __("You're not authorized to put cash in this cash desk"), indicator: "red"});
			raise;
		}
	}


	check_if_payment_movements()
	{
		if (!this.frm.is_new()) {

			let has_payment_transaction = 0

			frappe.call({  	method: this.path_cash_desk_movement + '.ioi_cash_desk_document_has_transaction',
							args: {"doctype": this.frm.doctype, "name": this.frm.doc.name},
							async: false,
							callback:function(r)	{
									has_payment_transaction = r.message;
							}
			});

			if (has_payment_transaction == 1) {

				this.frm.set_df_property('order_customer_id', 'read_only', 1);
				this.frm.set_df_property('delivery_customer_id', 'read_only', 1);
				this.frm.set_df_property('invoice_customer_id', 'read_only', 1);
				this.frm.set_df_property('payment_term_id', 'read_only', 1);
			}

		}
	}


	invoice_order(order_no = null, new_invoice = true, invoice_no = null)
	{
		let me = this;

		let title = __("Direct invoice this order") + ' : ';

		if (order_no == null) {
			title += this.frm.doc.name;
		}else{
			title += order_no;
		}


		var z = new frappe.ui.Dialog({
			'title': title,
			'fields': [
				{'fieldname': 'html_sales_order_invoice_order', 'fieldtype': 'HTML'}
			],
			primary_action_label: __('Ok'),
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				let button_ok = document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1];

				button_ok.disabled = true;


				if (!document.getElementById('html_sales_order_invoice_order_grid_detail')) {
					button_ok.disabled = false;
					frappe.msgprint({ title: __("Message"), message: __("No selected line"), indicator: "red" });
					return false;
				}

				if (document.getElementById('html_sales_order_invoice_order_grid_detail').rows.length == 1) {

					if (!document.getElementById('html_sales_order_invoice_order_grid_detail_checked_id_0')) {
						button_ok.disabled = false;
						frappe.msgprint({ title: __("Message"), message: __("No selected line"), indicator: "red" });
						return false;
					}
				}

				let one_selected = false;

				for (var i = 0; i < document.getElementById('html_sales_order_invoice_order_grid_detail').rows.length; i++) {

					if (document.getElementById('html_sales_order_invoice_order_grid_detail_checked_id_' + i.toString())) {

						if (document.getElementById('html_sales_order_invoice_order_grid_detail_checked_id_' + i.toString()).checked) {
							one_selected = true;
							break;
						}
					}
				}

				if (!one_selected) {
					button_ok.disabled = false;
					frappe.msgprint({ title: __("Message"), message: __("No selected line"), indicator: "red" });
					return false;
				}


				let order_selected_lines = [];
				let order_selected = '[';


				for (var i = 0; i < document.getElementById('html_sales_order_invoice_order_grid_detail').rows.length; i++) {

					if (document.getElementById('html_sales_order_invoice_order_grid_detail_checked_id_' + i.toString())) {

						if (document.getElementById('html_sales_order_invoice_order_grid_detail_checked_id_' + i.toString()).checked) {

							let to_invoice_qty = 0;

							if (document.getElementById('html_sales_order_invoice_order_grid_detail_to_invoice_' + i.toString()).value != '') {

								if (isNaN(document.getElementById('html_sales_order_invoice_order_grid_detail_to_invoice_' + i.toString()).value)) {

									button_ok.disabled = false;
									frappe.msgprint({ title: __("Message"), message: __("Line") + ' ' + (i+1).toString() + ': ' + __("To invoice qty is not a valid number"), indicator: "red" });
									return false;
								}

								if (parseFloat(document.getElementById('html_sales_order_invoice_order_grid_detail_to_invoice_' + i.toString()).value) < 0) {

									button_ok.disabled = false;
									frappe.msgprint({ title: __("Message"), message: __("Line") + ' ' + (i+1).toString() + ': ' + __("To invoice qty has to be greater than or equal to 0"), indicator: "red" });
									return false;

								}

								if (parseFloat(document.getElementById('html_sales_order_invoice_order_grid_detail_to_invoice_' + i.toString()).value) > parseFloat(document.getElementById('html_sales_order_invoice_order_grid_detail_original_to_invoice_qty_' + i.toString()).value)) {

									button_ok.disabled = false;
									frappe.msgprint({ title: __("Message"), message: __("Line") + ' ' + (i+1).toString() + ': ' + __("To invoice qty has to be lower than or equal to") + ' ' + document.getElementById('html_sales_order_invoice_order_grid_detail_original_to_invoice_qty_' + i.toString()).value + ' ' + __("(ordered qty - invoiced qty)"), indicator: "red" });
									return false;
								}

								to_invoice_qty = parseFloat(document.getElementById('html_sales_order_invoice_order_grid_detail_to_invoice_' + i.toString()).value);
							}

							order_selected_lines.push(document.getElementById('html_sales_order_invoice_order_grid_detail_name_' + i.toString()).value);

							order_selected += '{"name":"' + document.getElementById('html_sales_order_invoice_order_grid_detail_name_' + i.toString()).value + '", "qt":' + to_invoice_qty + '},';

						}
					}
				}

				if (order_selected.length > 1) {
					order_selected = order_selected.substring(0, order_selected.length-1)
				}

				order_selected += ']';

				let method = me.path_sales_order + '.ioi_sales_order_create_invoice';

				let name = '';

				if (order_no == null) {
					name = me.frm.doc.name;
				}else{
					name = order_no;
				}

				let is_new = 0;

				if (new_invoice) {
					is_new = 1;
				}

				frappe.call({  	method: method,
								args: {"name": name, "selected_lines": [order_selected_lines], "selected_qties": order_selected, "new_invoice": is_new, "invoice_no": invoice_no},
								async: false,
								callback:function(r)	{

									if (r.message.error != 0)
									{
										button_ok.disabled = false;
										frappe.msgprint({title: __("Message"), message: r.message.error_message, indicator: "red"});
									}else
									{
										z.hide();

										if (is_new == 1) {
											window.open('/app/ioi-sales-invoice/' + r.message.invoice_number);
										}
									}
								}
				});

			},
			secondary_action: function(){

				z.hide();
			}

		});


		if (document.getElementById('html_sales_order_invoice_order_grid_header')) {
			document.getElementById('html_sales_order_invoice_order_grid_header').remove();
		}

		if (document.getElementById('html_sales_order_invoice_order_check_all_none')) {
			document.getElementById('html_sales_order_invoice_order_check_all_none').remove();
		}

		if (document.getElementById('html_sales_order_invoice_order_content')) {
			document.getElementById('html_sales_order_invoice_order_content').remove();
		}


		if (document.getElementById('html_sales_order_invoice_order_percent_label')) {
			document.getElementById('html_sales_order_invoice_order_percent_label').remove();
		}

		if (document.getElementById('html_sales_order_invoice_order_percent')) {
			document.getElementById('html_sales_order_invoice_order_percent').remove();
		}

		if (document.getElementById('html_sales_order_invoice_order_apply_percent_button')) {
			document.getElementById('html_sales_order_invoice_order_apply_percent_button').remove();
		}

		if (document.getElementById('html_sales_order_invoice_order_reset_button')) {
			document.getElementById('html_sales_order_invoice_order_reset_button').remove();
		}


		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:610px; width: 100%;">';

		html += '	<div style="overflow: auto; overflow-x: auto; overflow-y: none; height:30px; width: 100%;">';

		html += '		<table id="html_sales_order_invoice_order_grid_header" border=1 style="border: 1px solid #E8EAEB;" width=1480px data-custom-grid="true">';
		html += '		<tr style="height:29px">';
		html += '		<td width=30px align="center" style="vertical-align: middle;">';
		html += '		<input type="checkbox" id="html_sales_order_invoice_order_check_all_none" style="postion:absolute; top: 2px; left: 2px;" checked> ';
		html += '		</td>';
		html += '		<td width=40px  style="vertical-align: middle;" align="center"><b>' + __("N°") + '</b></td>';
		html += '		<td width=250px style="vertical-align: middle;">&nbsp;<b>' + __("Item") + '</b></td>';
		html += '		<td width=350px style="vertical-align: middle;">&nbsp;<b>' + __("Description") + '</b></td>';
		html += '		<td width=250px style="vertical-align: middle;">&nbsp;<b>' + __("Manufacturer catalog") + '</b></td>';
		html += '		<td width=120px style="vertical-align: middle;">&nbsp;<b>' + __("Ordered qty") + '</b></td>';
		html += '		<td width=120px style="vertical-align: middle;">&nbsp;<b>' + __("Invoiced qty") + '</b></td>';
		html += '		<td width=120px style="vertical-align: middle;">&nbsp;<b>' + __("To invoice qty") + '</b></td>';
		html += '		<td width=80px  style="vertical-align: middle;">&nbsp;<b>' + __("Unit") + '</b></td>';
		html += '		<td width=120px style="vertical-align: middle;">&nbsp;<b>' + __("Unit price") + '</b></td>';
		html += '		</tr>';
		html += '		</table>';

		html += '	</div>';

		html += '	<div id="html_sales_order_invoice_order_content" style="overflow: auto; overflow-x: auto; height:520px; width: 100%;"></div>';

		html += '	<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '	</div>';

		html += '	<div style="overflow: auto; overflow-x: auto; height:50px; width: 100%;">';

		// Percent
		html += '		<div style="position: relative; top: 2px; left: 0px; width:150px;">';
		html += '			<label id="html_sales_order_invoice_order_percent_label" style="position: absolute; top: 6px; left: 0px;z-index:5;">' + __("Invoiceable") + '</label>';
		html += '			<div class="control-input" style="position: absolute; top: 0px; left: 110px; width: 90px; height: 25px;"> ';
		html += '				<input id="html_sales_order_invoice_order_percent" type="number" step="any" class="input-with-feedback form-control bold" style="text-align: right;" value="100">';
		html += '			</div>';
		html += '			<label style="position: absolute; top: 6px; left: 210px;z-index:5;">%</label>';
		html += '		</div>';


		// Percent apply
		html += '		<div style="position: relative; top: 2px; left: 230px; width:120px;">';
		html += '			<div style="position: absolute; top:0px; left: 2px; height: 35px">';
		html +='				<button id="html_sales_order_invoice_order_apply_percent_button" class="btn btn-default ellipsis" style="height: 31px; width: 120px;" onclick="">' + __("Apply") + '</button>';
		html += '			</div>';
		html += '		</div>';

		// Percent apply
		html += '		<div style="position: relative; top: 2px; left: 360px; width:120px;">';
		html += '			<div style="position: absolute; top:0px; left: 2px; height: 35px">';
		html +='				<button id="html_sales_order_invoice_order_reset_button" class="btn btn-default ellipsis" style="height: 31px; width: 120px;" onclick="">' + __("Reset") + '</button>';
		html += '			</div>';
		html += '		</div>';

		html += '	</div>';
		html += '</div>';

		z.fields_dict.html_sales_order_invoice_order.$wrapper.html(html);
		z.$wrapper.find('.modal-dialog').css("max-width", "1550px").css("width", "1550px");
		z.$wrapper.find('.modal-dialog').css("max-height", "450px").css("height", "450px");
		z.show();

		this.#sleep(200).then(() => {

			let fct_click = function() {

				if (document.getElementById('html_sales_order_invoice_order_grid_detail')) {

					for (var i = 0; i < document.getElementById('html_sales_order_invoice_order_grid_detail').rows.length; i++) {

						if (document.getElementById('html_sales_order_invoice_order_grid_detail_checked_id_' + i.toString())) {
							document.getElementById('html_sales_order_invoice_order_grid_detail_checked_id_' + i.toString()).checked = this.checked;
						}

					}
				}

			}

			document.getElementById('html_sales_order_invoice_order_check_all_none').onclick = fct_click;


			let fct_apply_click = function() {

				me.invoice_order_apply_percent();

			}

			document.getElementById('html_sales_order_invoice_order_apply_percent_button').onclick = fct_apply_click;


			let fct_reset_click = function() {

				me.invoice_order_reset();

			}

			document.getElementById('html_sales_order_invoice_order_reset_button').onclick = fct_reset_click;



			me.invoice_order_get_result(order_no, invoice_no);

		});
	}

	invoice_order_get_result(order_no = null)
	{
		let name = '';

		if (order_no == null) {
			name = this.frm.doc.name;
		}else{
			name = order_no;
		}

		let method = this.path_sales_order + '.ioi_sales_order_invoice_order_result'

		let data = [];

		frappe.call({  	method: method,
						args: {"name" : name},
						async: false,
						callback:function(r)	{

							data = r.message;
						}
		});

		if (document.getElementById('html_sales_order_invoice_order_grid_detail')) {

			for (var i = 0; i < document.getElementById('html_sales_order_invoice_order_grid_detail').rows.length; i++) {

				if (document.getElementById('html_sales_order_invoice_order_grid_detail_name_' + i.toString())) {
					document.getElementById('html_sales_order_invoice_order_grid_detail_name_' + i.toString()).remove();
				}

				if (document.getElementById('html_sales_order_invoice_order_grid_detail_original_to_invoice_qty_' + i.toString())) {
					document.getElementById('html_sales_order_invoice_order_grid_detail_original_to_invoice_qty_' + i.toString()).remove();
				}

				if (document.getElementById('html_sales_order_invoice_order_grid_detail_checked_id_' + i.toString())) {
					document.getElementById('html_sales_order_invoice_order_grid_detail_checked_id_' + i.toString()).remove();
				}


				if (document.getElementById('html_sales_order_invoice_order_grid_detail_to_invoice_' + i.toString())) {
					document.getElementById('html_sales_order_invoice_order_grid_detail_to_invoice_' + i.toString()).remove();
				}
			}

			document.getElementById('html_sales_order_invoice_order_grid_detail').remove();
		}

		let html = ''

		if (data.length == 0) {

			html += '<table id="html_sales_order_invoice_order_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=1480px>';
			html += '<tr style="height:30px">';
			html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=40px  style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=350px style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=80px  style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
			html += '</tr>';
			html += '</table>';

		}else{

			html += '<table id="html_sales_order_invoice_order_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=1480px>';

			for (var i = 0; i < data.length; i++) {

				html += '<input id="html_sales_order_invoice_order_grid_detail_name_' + i.toString() + '" type="hidden" value ="' + data[i].name + '">';
				html += '<input id="html_sales_order_invoice_order_grid_detail_original_to_invoice_qty_' + i.toString() + '" type="hidden" value ="' + data[i].to_invoice_qty + '">';

				html += '<tr style="height:30px">';

				html += '<td width=30px align="center" style="vertical-align: middle;">';
				html += '<input type="checkbox" id="html_sales_order_invoice_order_grid_detail_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;" checked>';
				html += '</td>';

				html += '<td bgcolor="' + data[i].ioistatus_bgcolor + '" width=40px  style="vertical-align: middle;" align="center">' + data[i].idx + '</td>';

				if ((data[i].item_id != null) && (data[i].item_id != '')) {
					html += '<td width=250px style="vertical-align: middle;">&nbsp;' + data[i].item_id + '</td>';
				}else{
					html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
				}

				if ((data[i].item_description != null) && (data[i].item_description != '')) {
					html += '<td width=350px style="vertical-align: middle;">&nbsp;' + data[i].item_description + '</td>';
				}else{
					html += '<td width=350px style="vertical-align: middle;">&nbsp;</td>';
				}

				if ((data[i].manufacturer_ref != null) && (data[i].manufacturer_ref != '')) {
					html += '<td width=250px style="vertical-align: middle;">&nbsp;' + data[i].manufacturer_ref + '</td>';
				}else{
					html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
				}


				if ((data[i].ordered_qty != null) && (data[i].ordered_qty != 0)) {
					html += '<td width=120px style="vertical-align: middle;" align="right">' + data[i].ordered_qty + '&nbsp;</td>';
				}else{
					html += '<td width=120px style="vertical-align: middle;" align="right">&nbsp;</td>';
				}

				if ((data[i].invoiced_qty != null) && (data[i].invoiced_qty != 0)) {
					html += '<td width=120px style="vertical-align: middle;" align="right">' + data[i].invoiced_qty + '&nbsp;</td>';
				}else{
					html += '<td width=120px style="vertical-align: middle;" align="right">&nbsp;</td>';
				}

				let to_invoice_qty = 0;

				if ((data[i].to_invoice_qty != null) && (data[i].to_invoice_qty != 0)) {
					to_invoice_qty = data[i].to_invoice_qty;
				}

				html += '<td width=120px style="vertical-align: middle;">';
				html += '<input id="html_sales_order_invoice_order_grid_detail_to_invoice_' + i.toString() + '" type="number" step="any" class="input-with-feedback form-control bold" style="position:relative; top:0px; left:2px; width:112px; height:28px; text-align: right;" value="' + to_invoice_qty + '">';
				html += '</td>';

				if ((data[i].unit_id != null) && (data[i].unit_id != '')) {
					html += '<td width=80px  style="vertical-align: middle;">&nbsp;' + data[i].unit_id + '</td>';
				}else{
					html += '<td width=80px  style="vertical-align: middle;">&nbsp;</td>';
				}

				if ((data[i].unit_price != null) && (data[i].unit_price != '')) {
					html += '<td width=120px style="vertical-align: middle;" align="right">' + data[i].unit_price.toFixed(2) + ' ' + data[i].currency_id + '&nbsp;</td>';
				}else{
					html += '<td width=120px  style="vertical-align: middle;">&nbsp;</td>';
				}

				html += '</tr>';
			}

			html += '</table>';
		}

		document.getElementById('html_sales_order_invoice_order_content').insertAdjacentHTML('beforeend', html);

	}

	invoice_order_apply_percent()
	{
		if (!document.getElementById('html_sales_order_invoice_order_grid_detail')) {
			frappe.msgprint({ title: __("Message"), message: __("No selected line"), indicator: "red" });
			return false;
		}

		if (document.getElementById('html_sales_order_invoice_order_grid_detail').rows.length == 1) {

			if (!document.getElementById('html_sales_order_invoice_order_grid_detail_checked_id_0')) {
				frappe.msgprint({ title: __("Message"), message: __("No selected line"), indicator: "red" });
				return false;
			}
		}

		let one_selected = false;


		for (var i = 0; i < document.getElementById('html_sales_order_invoice_order_grid_detail').rows.length; i++) {

			if (document.getElementById('html_sales_order_invoice_order_grid_detail_checked_id_' + i.toString())) {

				if (document.getElementById('html_sales_order_invoice_order_grid_detail_checked_id_' + i.toString()).checked) {

					one_selected = true;
					break;
				}
			}
		}

		if (!one_selected) {
			frappe.msgprint({ title: __("Message"), message: __("No selected line"), indicator: "red" });
			return false;
		}

		let percent = 0;


		if (document.getElementById('html_sales_order_invoice_order_percent').value != '') {

			if (isNaN(document.getElementById('html_sales_order_invoice_order_percent').value)) {
				frappe.msgprint({ title: __("Message"), message: __("Invoiceable % is not a valid number"), indicator: "red" });
				return false;
			}

			if (parseFloat(document.getElementById('html_sales_order_invoice_order_percent').value) < 0) {
				frappe.msgprint({ title: __("Message"), message: __("Invoiceable % has to be greater than or equal to 0"), indicator: "red" });
				return false;
			}

			if (parseFloat(document.getElementById('html_sales_order_invoice_order_percent').value) > 100) {
				frappe.msgprint({ title: __("Message"), message: __("Invoiceable % has to be lower than or equal to 100"), indicator: "red" });
				return false;
			}

			percent = parseFloat(document.getElementById('html_sales_order_invoice_order_percent').value);
		}


		for (var i = 0; i < document.getElementById('html_sales_order_invoice_order_grid_detail').rows.length; i++) {

			if (document.getElementById('html_sales_order_invoice_order_grid_detail_checked_id_' + i.toString())) {

				if (document.getElementById('html_sales_order_invoice_order_grid_detail_checked_id_' + i.toString()).checked) {

					let original_value = 0;

					if (document.getElementById('html_sales_order_invoice_order_grid_detail_original_to_invoice_qty_' + i.toString()).value != '') {
						original_value = parseFloat(document.getElementById('html_sales_order_invoice_order_grid_detail_original_to_invoice_qty_' + i.toString()).value);
					}

					let new_value = original_value / 100 * percent;

					document.getElementById('html_sales_order_invoice_order_grid_detail_to_invoice_' + i.toString()).value = parseFloat(new_value.toFixed(2));
				}
			}
		}
	}


	invoice_order_reset()
	{
		if (!document.getElementById('html_sales_order_invoice_order_grid_detail')) {
			frappe.msgprint({ title: __("Message"), message: __("No selected line"), indicator: "red" });
			return false;
		}

		if (document.getElementById('html_sales_order_invoice_order_grid_detail').rows.length == 1) {

			if (!document.getElementById('html_sales_order_invoice_order_grid_detail_checked_id_0')) {
				frappe.msgprint({ title: __("Message"), message: __("No selected line"), indicator: "red" });
				return false;
			}
		}

		let one_selected = false;


		for (var i = 0; i < document.getElementById('html_sales_order_invoice_order_grid_detail').rows.length; i++) {

			if (document.getElementById('html_sales_order_invoice_order_grid_detail_checked_id_' + i.toString())) {

				if (document.getElementById('html_sales_order_invoice_order_grid_detail_checked_id_' + i.toString()).checked) {

					one_selected = true;
					break;
				}
			}
		}

		if (!one_selected) {
			frappe.msgprint({ title: __("Message"), message: __("No selected line"), indicator: "red" });
			return false;
		}

		for (var i = 0; i < document.getElementById('html_sales_order_invoice_order_grid_detail').rows.length; i++) {

			if (document.getElementById('html_sales_order_invoice_order_grid_detail_checked_id_' + i.toString())) {

				if (document.getElementById('html_sales_order_invoice_order_grid_detail_checked_id_' + i.toString()).checked) {
					document.getElementById('html_sales_order_invoice_order_grid_detail_to_invoice_' + i.toString()).value = document.getElementById('html_sales_order_invoice_order_grid_detail_original_to_invoice_qty_' + i.toString()).value;
				}
			}
		}
	}


	create_invoice_from_order(new_invoice = true, invoice_no = null)
	{
		let me = this;

		var z = new frappe.ui.Dialog({
			'title': __("Select an order"),
			'fields': [
				{'fieldname': 'html_sales_order_invoice_select_order', 'fieldtype': 'HTML'}
			],
			primary_action_label: __('Ok'),
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				let button_ok = document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1];

				button_ok.disabled = true;


				if (!document.getElementById('html_sales_order_invoice_select_order_grid_detail')) {
					button_ok.disabled = false;
					frappe.msgprint({ title: __("Message"), message: __("No selected line"), indicator: "red" });
					return false;
				}

				if (document.getElementById('html_sales_order_invoice_select_order_grid_detail').rows.length == 1) {

					if (!document.getElementById('html_sales_order_invoice_select_order_grid_detail_checked_id_0')) {
						button_ok.disabled = false;
						frappe.msgprint({ title: __("Message"), message: __("No selected line"), indicator: "red" });
						return false;
					}
				}

				let one_selected = false;
				let order_no = '';

				for (var i = 0; i < document.getElementById('html_sales_order_invoice_select_order_grid_detail').rows.length; i++) {

					if (document.getElementById('html_sales_order_invoice_select_order_grid_detail_checked_id_' + i.toString())) {

						if (document.getElementById('html_sales_order_invoice_select_order_grid_detail_checked_id_' + i.toString()).checked) {
							one_selected = true;
							order_no = document.getElementById('html_sales_order_invoice_select_order_grid_detail_name_' + i.toString()).value;
							break;
						}
					}
				}

				if (!one_selected) {
					button_ok.disabled = false;
					frappe.msgprint({ title: __("Message"), message: __("No selected line"), indicator: "red" });
					return false;
				}

				z.hide();

				me.sleep_ms(200).then(() => {
					me.invoice_order(order_no, new_invoice, invoice_no);

				});
			},
			secondary_action: function(){

				z.hide();
			}

		});

		if (document.getElementById('html_sales_order_invoice_select_order_search_label')) {
			document.getElementById('html_sales_order_invoice_select_order_search_label').remove();
		}

		if (document.getElementById('html_sales_order_invoice_select_order_search')) {
			document.getElementById('html_sales_order_invoice_select_order_search').remove();
		}


		if (document.getElementById('html_sales_order_invoice_select_order_grid_header')) {
			document.getElementById('html_sales_order_invoice_select_order_grid_header').remove();
		}


		if (document.getElementById('html_sales_order_invoice_select_order_content')) {
			document.getElementById('html_sales_order_invoice_select_order_content').remove();
		}


		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:625px; width: 100%;">';

		html += '	<div style="overflow: auto; overflow-x: auto; height:65px; width: 100%;">';

		// Search
		html += '		<div style="position: relative; top: 2px; left: 0px; width:150px;">';
		html += '			<label id="html_sales_order_invoice_select_order_search_label" style="position: absolute; top: 6px; left: 2px;z-index:5;">' + __("Search") + '</label>';
		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 500px; height: 25px;"> ';
		html += '				<input id="html_sales_order_invoice_select_order_search" type="text" class="input-with-feedback form-control bold" value="">';
		html += '			</div>';
		html += '		</div>';

		html += '	</div>';

		html += '	<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '	</div>';

		html += '	<div style="overflow: auto; overflow-x: auto; overflow-y: none; height:30px; width: 100%;">';

		html += '		<table id="html_sales_order_invoice_select_order_grid_header" border=1 style="border: 1px solid #E8EAEB;" width=1170px data-custom-grid="true">';
		html += '		<tr style="height:29px">';
		html += '		<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
		html += '		<td width=190px  style="vertical-align: middle;">&nbsp;<b>' + __("Sales order") + '</b></td>';
		html += '		<td width=150px style="vertical-align: middle;">&nbsp;<b>' + __("Document date") + '</b></td>';
		html += '		<td width=250px style="vertical-align: middle;">&nbsp;<b>' + __("Order customer") + '</b></td>';
		html += '		<td width=350px style="vertical-align: middle;">&nbsp;<b>' + __("Customer name") + '</b></td>';
		html += '		<td width=120px style="vertical-align: middle;">&nbsp;<b>' + __("Total wo vat") + '</b></td>';
		html += '		<td width=80px  style="vertical-align: middle;">&nbsp;<b>' + __("Curr.") + '</b></td>';
		html += '		</tr>';
		html += '		</table>';

		html += '	</div>';



		html += '	<div id="html_sales_order_invoice_select_order_content" style="overflow: auto; overflow-x: auto; height:520px; width: 100%;"></div>';


		html += '</div>';

		z.fields_dict.html_sales_order_invoice_select_order.$wrapper.html(html);
		z.$wrapper.find('.modal-dialog').css("max-width", "1220px").css("width", "1220px");
		z.$wrapper.find('.modal-dialog').css("max-height", "450px").css("height", "450px");
		z.show();

		this.#sleep(200).then(() => {

			let fct_keydown = function(event) {

				if (event.keyCode == 13) {
					me.create_invoice_from_order_get_result(invoice_no);
				}

			}

			document.getElementById('html_sales_order_invoice_select_order_search').onkeydown = fct_keydown;

			me.create_invoice_from_order_get_result(invoice_no);

		});
	}

	create_invoice_from_order_get_result(invoice_no = null)
	{
		let method = this.path_sales_order + '.ioi_sales_order_invoice_select_order'

		let data = [];

		frappe.call({  	method: method,
						args: {"search_term" : document.getElementById('html_sales_order_invoice_select_order_search').value, "invoice_no": invoice_no},
						async: false,
						callback:function(r)	{

							data = r.message;
						}
		});

		if (document.getElementById('html_sales_order_invoice_select_order_grid_detail')) {

			for (var i = 0; i < document.getElementById('html_sales_order_invoice_select_order_grid_detail').rows.length; i++) {

				if (document.getElementById('html_sales_order_invoice_select_order_grid_detail_name_' + i.toString())) {
					document.getElementById('html_sales_order_invoice_select_order_grid_detail_name_' + i.toString()).remove();
				}

				if (document.getElementById('html_sales_order_invoice_select_order_grid_detail_checked_id_' + i.toString())) {
					document.getElementById('html_sales_order_invoice_select_order_grid_detail_checked_id_' + i.toString()).remove();
				}

			}

			document.getElementById('html_sales_order_invoice_select_order_grid_detail').remove();
		}

		let html = ''

		if (data.length == 0) {

			html += '<table id="html_sales_order_invoice_select_order_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=1170px>';
			html += '<tr style="height:30px">';
			html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=190px  style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=350px style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=80px  style="vertical-align: middle;">&nbsp;</td>';
			html += '</tr>';
			html += '</table>';

		}else{

			html += '<table id="html_sales_order_invoice_select_order_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=1170px>';

			for (var i = 0; i < data.length; i++) {

				html += '<input id="html_sales_order_invoice_select_order_grid_detail_name_' + i.toString() + '" type="hidden" value ="' + data[i].name + '">';

				html += '<tr style="height:30px">';

				html += '<td width=30px align="center" style="vertical-align: middle;">';
				html += '<input type="checkbox" id="html_sales_order_invoice_select_order_grid_detail_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;">';
				html += '</td>';

				html += '<td width=190px bgcolor="' + data[i].ioistatus_bgcolor + '" style="vertical-align: middle;">&nbsp;' + data[i].name + '</td>';

				html += '<td width=150px style="vertical-align: middle;">&nbsp;' + data[i].document_date + '</td>';

				html += '<td width=250px style="vertical-align: middle;">&nbsp;' + data[i].order_customer_id + '</td>';

				if ((data[i].order_customer_name != null) && (data[i].order_customer_name != '')) {
					html += '<td width=350px style="vertical-align: middle;">&nbsp;' + data[i].order_customer_name + '</td>';
				}else{
					html += '<td width=350px style="vertical-align: middle;">&nbsp;</td>';
				}

				if ((data[i].total_htva != null) && (data[i].total_htva != 0)) {
					html += '<td width=120px style="vertical-align: middle;" align="right">' + data[i].total_htva + '&nbsp;</td>';
				}else{
					html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
				}

				if ((data[i].currency_id != null) && (data[i].currency_id != '')) {
					html += '<td width=80px  style="vertical-align: middle;">&nbsp;' + data[i].currency_id + '</td>';
				}else{
					html += '<td width=80px  style="vertical-align: middle;">&nbsp;</td>';
				}

				html += '</tr>';
			}

			html += '</table>';
		}

		document.getElementById('html_sales_order_invoice_select_order_content').insertAdjacentHTML('beforeend', html);

		this.#sleep(200).then(() => {

			let fct_click = function() {

				for (var i = 0; i < document.getElementById('html_sales_order_invoice_select_order_grid_detail').rows.length; i++) {

					if (document.getElementById('html_sales_order_invoice_select_order_grid_detail_checked_id_' + i.toString())) {

						if (this.id != document.getElementById('html_sales_order_invoice_select_order_grid_detail_checked_id_' + i.toString()).id) {
							document.getElementById('html_sales_order_invoice_select_order_grid_detail_checked_id_' + i.toString()).checked = false;
						}
					}

				}
			}

			if (document.getElementById('html_sales_order_invoice_select_order_grid_detail')) {

				let one_checked = false;

				for (var i = 0; i < document.getElementById('html_sales_order_invoice_select_order_grid_detail').rows.length; i++) {

					if (document.getElementById('html_sales_order_invoice_select_order_grid_detail_checked_id_' + i.toString())) {

						document.getElementById('html_sales_order_invoice_select_order_grid_detail_checked_id_' + i.toString()).onclick = fct_click;

						if (!one_checked) {
							document.getElementById('html_sales_order_invoice_select_order_grid_detail_checked_id_' + i.toString()).checked = true;
							one_checked = true;
						}
					}
				}
			}

		});
	}

	create_from_template()
	{

		let data = [];

		let method = this.path_sales_document + '.ioi_sales_document_get_template_documents';

		frappe.call({  	method: method,
						args: { "doctype" : this.frm.doctype
						},
						async: false,
						callback:function(r)	{

								data = r.message;

						}
		});


		let title = '';
		if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') {
			title += __("select a sales quote template");
		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
			title += __("select a sales order template");
		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
			title += __("select a sales delivery template");
		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
			title += __("select a sales invoice template");
		}

		let me = this;

		var p = new frappe.ui.Dialog({
			'title': title,
			'fields': [
				{'fieldname': 'html_sales_select_template', 'fieldtype': 'HTML'}

			],
			primary_action_label:  __('Ok'),
			secondary_action_label:  __('Cancel'),
			primary_action: function(){

				if (!document.getElementById('html_sales_select_template_grid_detail')) {
					frappe.msgprint({title: __("Message"), message: __('No document selected'), indicator: "red"});
					raise;
				}

				if (document.getElementById('html_sales_select_template_grid_detail').rows.length == 0) {
					frappe.msgprint({title: __("Message"), message: __('No document selected'), indicator: "red"});
					raise;
				}

				let one_selected = false;
				let document_name = '';

				for (var i = 0; i < document.getElementById('html_sales_select_template_grid_detail').rows.length; i++) {

					if (document.getElementById('html_sales_select_template_grid_detail').rows[i].style.display != 'none') {

						if (document.getElementById('html_sales_select_template_grid_detail_checked_' + i.toString())) {

							if (document.getElementById('html_sales_select_template_grid_detail_checked_' + i.toString()).checked) {
								one_selected = true;
								document_name = document.getElementById('html_sales_select_template_grid_detail_name_' + i.toString()).value;
							}

						}
					}
				}

				if (!one_selected) {
					frappe.msgprint({title: __("Message"), message: __('No document selected'), indicator: "red"});
					raise;
				}

				if (document.getElementById('html_sales_select_template_document_date').value.toString().trim() == '') {
					frappe.msgprint({title: __("Message"), message: __('Document date is mandatory'), indicator: "red"});
					raise;
				}


				if (document.getElementById('html_sales_select_template_customer').value.trim() == '') {
					frappe.msgprint({title: __("Message"), message: __('Customer is mandatory'), indicator: "red"});
					raise;
				}

				method = me.path_customer + '.ioi_customer_exists_and_active';

				let customer_exists = false;

				frappe.call({  	method: method,
								args: { "customer_id" : document.getElementById('html_sales_select_template_customer').value.toUpperCase()
								},
								async: false,
								callback:function(r)	{

									if (r.message == 1) {
										customer_exists = true;
									}

								}
				});

				if (!customer_exists) {
					frappe.msgprint({title: __("Message"), message: __('This customer does not exist or is not active'), indicator: "red"});
					raise;
				}


				if (me.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') {
					method = me.path_sales_quote + '.ioi_sales_quote_create_from_template';
				}else if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
					method = me.path_sales_order + '.ioi_sales_order_create_from_template';
				}else if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
					method = me.path_sales_delivery + '.ioi_sales_delivery_create_from_template';
				}else if (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
					method = me.path_sales_invoice + '.ioi_sales_invoice_create_from_template';
				}

				let discard_prices = 0;

				if (document.getElementById('html_sales_select_template_discard_price').checked) {
					discard_prices = 1;
				}

				frappe.call({  	method: method,
								args: { "template_name" : document_name,
										"journal_id" : document.getElementById('html_sales_select_template_journal').value,
										"document_date": document.getElementById('html_sales_select_template_document_date').value,
										"customer_id" : document.getElementById('html_sales_select_template_customer').value.toUpperCase(),
										"discard_prices": discard_prices
								},
								async: false,
								callback:function(r)	{

									if (r.message.error == 1) {

										frappe.msgprint({title: __("Message"), message: r.message.error_msg, indicator: "red"});
										raise;

									}else{

										p.hide();

										let url = '';

										if (me.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') {
											url = '/app/ioi-sales-quote/' + r.message.quote_number;
										}else if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
											url = '/app/ioi-sales-order/' + r.message.order_number;
										}else if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
											url = '/app/ioi-sales-delivery/' + r.message.delivery_number;
										}else if (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
											url = '/app/ioi-sales-invoice/' + r.message.invoice_number;
										}

										window.location.href = url;

									}


								}
				});

			},
			secondary_action: function(){
				p.hide();
			}

		});

		if (document.getElementById('html_sales_select_template_search_label')) {
			document.getElementById('html_sales_select_template_search_label').remove();
		}

		if (document.getElementById('html_sales_select_template_search')) {
			document.getElementById('html_sales_select_template_search').remove();
		}

		if (document.getElementById('html_sales_select_template_journal_label')) {
			document.getElementById('html_sales_select_template_journal_label').remove();
		}

		if (document.getElementById('html_sales_select_template_journal')) {
			document.getElementById('html_sales_select_template_journal').remove();
		}

		if (document.getElementById('html_sales_select_template_document_date_label')) {
			document.getElementById('html_sales_select_template_document_date_label').remove();
		}

		if (document.getElementById('html_sales_select_template_document_date')) {
			document.getElementById('html_sales_select_template_document_date').remove();
		}


		if (document.getElementById('html_sales_select_template_customer_label')) {
			document.getElementById('html_sales_select_template_customer_label').remove();
		}

		if (document.getElementById('html_sales_select_template_customer')) {
			document.getElementById('html_sales_select_template_customer').remove();
		}

		if (document.getElementById('html_sales_select_template_customer_button')) {
			document.getElementById('html_sales_select_template_customer_button').remove();
		}

		if (document.getElementById('html_sales_select_template_discard_price_label')) {
			document.getElementById('html_sales_select_template_discard_price_label').remove();
		}

		if (document.getElementById('html_sales_select_template_discard_price')) {
			document.getElementById('html_sales_select_template_discard_price').remove();
		}

		if (document.getElementById('html_sales_select_template_warning_label')) {
			document.getElementById('html_sales_select_template_warning_label').remove();
		}

		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:70px; width: 100%;">';

		// Search
		html += '	<div style="position: relative; top: 0px; left: 2px; width:1098px;">';
		html += '		<label id="html_sales_select_template_search_label" style="position: absolute; top: 0px; left: 0px;z-index:5;"><b>' + __("Search") + '</b></label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 1158px; height: 25px;"> ';
		html += '			<input id="html_sales_select_template_search" type="text" class="input-with-feedback form-control bold" value="">';
		html += '		</div>';
		html += '	</div>';

		html += '</div>'

		html += this.get_template_data_list(data);


		html += '<div style="overflow: auto; overflow-x: auto; height:85px; width: 100%;">';

		// Destination Journal
		html += '	<div style="position: relative; top: 0px; left: 2px; width:170px;">';
		html += '		<label id="html_sales_select_template_journal_label" style="position: absolute; top: 0px; left: 0px;z-index:5;"><b>' + __("Sales journal") + '</b></label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 170px; height: 25px;"> ';

		html += '			<select id="html_sales_select_template_journal" class="input-with-feedback form-control bold"> ';

		method = 'silicon_ioi.ioi_sales.doctype.ioi_sales_journal.ioi_sales_journal.ioi_sales_journal_get_journals_current_site'

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{
													for (var i = 0; i < r.message.length; i++)
													{
														html += '		<option value="' + r.message[i].name + '"';

														if (me.frm.doc.journal_id == r.message[i].name) {
															html += ' selected '
														}

														html += '>' + r.message[i].name + '</option> ';
													}
												}
		});
		html += '			</select> ';

		html += '		</div>';
		html += '	</div>';

		const date = new Date();

		let day = date.getDate();
		let month = date.getMonth() + 1;
		let year = date.getFullYear();

		let s = year.toString() + '-';

		if (month < 10) {
			s += '0'
		}

		s += month.toString() + '-';

		if (day < 10) {
			s += '0'
		}

		s += day.toString();


		// Document date
		html += '	<div style="position: relative; top: 0px; left: 182px; width:160px;">';
		html += '		<label id="html_sales_select_template_document_date_label" style="position: absolute; top: 0px; left: 0px;z-index:5;"><b>' + __("Document date") + '</b></label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 160px; height: 25px;"> ';
		html += '			<input id="html_sales_select_template_document_date" type="date" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value="' + s + '">';
		html += '		</div>';
		html += '	</div>';


		// Customer
		html += '	<div style="position: relative; top: 0px; left: 352px; width:250px;">';
		html += '		<label id="html_sales_select_template_customer_label" style="position: absolute; top: 0px; left: 0px;z-index:5;"><b>' + __("Customer") + '</b></label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 250px; height: 25px;"> ';
		html += '			<input id="html_sales_select_template_customer" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value="">';
		html += '		</div>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 260px; width: 50px; height: 25px;"> ';
		html += '			<input id="html_sales_select_template_customer_button" type="button" class="input-with-feedback form-control bold" value="...">';
		html += '		</div>';
		html += '	</div>';


		// Discard prices refresh
		html += '	<div style="position: relative; top: 30px; left: 672px; width:250px;">';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 0px; width: 250px; height: 25px;"> ';
		html += '			<input id="html_sales_select_template_discard_price" type="checkbox" class="input-with-feedback form-control bold">';
		html += '		</div>';

		html += '		<label id="html_sales_select_template_discard_price_label" style="position: absolute; top: 0px; left: 30px;z-index:5;"><b>' + __("Discard prices refresh") + '</b></label>';

		html += '	</div>';

		html += '	<div style="position: relative; top: 40px; left: 2px; width:1098px;">';
		html += '		<label id="html_sales_select_template_warning_label" style="position: absolute; top: 25px; left: 0px;z-index:5;color:blue">' + __("All line prices will be updated except for lines in manual condition") + '</label>';
		html += '	</div>';

		html += '</div>';



		p.fields_dict.html_sales_select_template.$wrapper.html(html);

		let form_width = 1210;

		p.$wrapper.find('.modal-dialog').css("max-width", form_width.toString() + "px").css("width", form_width.toString() + "px");
		p.show();



		this.#sleep(200).then(() => {

			let fct_search = function(event) {

				if (event.keyCode == 13) {

					me.template_search();
					return false;

				}
			}

			document.getElementById('html_sales_select_template_search').onkeydown = fct_search;


			let fct_customer_select = function() {

				me.template_customer_select();
				return false;
			}

			document.getElementById('html_sales_select_template_customer_button').onclick = fct_customer_select;
		});

	}

	get_template_data_list(data)
	{

		if (document.getElementById('html_sales_select_template_content_grid')) {
			document.getElementById('html_sales_select_template_content_grid').remove();
		}

		if (document.getElementById('html_sales_select_template_grid_header')) {
			document.getElementById('html_sales_select_template_grid_header').remove();
		}


		if (document.getElementById('html_sales_select_template_grid_detail')) {

			for (var i = 0; i < document.getElementById('html_sales_select_template_grid_detail').rows.length; i++) {

				if (document.getElementById('html_sales_select_template_grid_detail_checked_' + i.toString())) {
					document.getElementById('html_sales_select_template_grid_detail_checked_' + i.toString()).remove();
				}

				if (document.getElementById('html_sales_select_template_grid_detail_name_' + i.toString())) {
					document.getElementById('html_sales_select_template_grid_detail_name_' + i.toString()).remove();
				}


			}

			document.getElementById('html_sales_select_template_grid_detail').remove()
		}

		let table_width = 1160;

		let html = '';

		html += '<table id="html_sales_select_template_grid_header" width=' + table_width.toString() + 'px border=1 style="border: 1px solid #E8EAEB" data-custom-grid="true">';
		html += '<tr style="height:30px">';
		html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=250px style="vertical-align: middle;"><b>&nbsp;' + __("Document") + '</b></td>';
		html += '<td width=400px style="vertical-align: middle;"><b>&nbsp;' + __("Description") + '</b></td>';
		html += '<td width=250px style="vertical-align: middle;"><b>&nbsp;' + __("Customer") + '</b></td>';
		html += '<td width=150px style="vertical-align: middle;"><b>&nbsp;' + __("Total w/o VAT") + '</b></td>';
		html += '<td width=80px style="vertical-align: middle;"><b>&nbsp;' + __("Currency") + '</b></td>';
		html += '</tr>';
		html += '</table>';


		html += '<div id="html_sales_select_template_content_grid" style="overflow-x: auto;height:400px;">';

		if (data.length == 0) {

			html += '<table id="html_sales_select_template_grid_detail" width=' + table_width.toString() + 'px border=1 style="border: 1px solid #E8EAEB">';
			html += '<tr style="height:30px">';
			html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=400px style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
			html += '</tr>';
			html += '</table>';

		}else{

			html += '<table id="html_sales_select_template_grid_detail" width=' + table_width.toString() + 'px border=1 style="border: 1px solid #E8EAEB">';

			for (var i = 0; i < data.length; i++) {

				html += '<tr style="height:30px">';

				html += '<input id="html_sales_select_template_grid_detail_name_' + i.toString() + '" type="hidden" value="' +  data[i].name + '">';

				html += '<td width=30px style="vertical-align: middle;" align="center">';
				html += '<input type="checkbox" id="html_sales_select_template_grid_detail_checked_' + i.toString() + '" style="postion:absolute; top: 2px; left: 2px;" ';
				html += '       onclick="  ';
				html += '					for (var i = 0; i < document.getElementById(\'html_sales_select_template_grid_detail\').rows.length; i++) ';
				html += '					{	';
				html += '						if (document.getElementById(\'html_sales_select_template_grid_detail_checked_\' + i.toString())) { '
				html += '							document.getElementById(\'html_sales_select_template_grid_detail_checked_\' + i.toString()).checked = false; ';
				html += '						} ';
				html += '					} ';

				html += '					this.checked = true; ';
				html += '                	}" ';
				html += '>';

				html += '</td>';
				html += '<td width=250px style="vertical-align: middle;">&nbsp;' + data[i].name + '</td>';

				if ((data[i].description != null) && (data[i].description != '')) {
					html += '<td width=400px style="vertical-align: middle;">&nbsp;' + data[i].description + '</td>';
				}else{
					html += '<td width=400px style="vertical-align: middle;">&nbsp;</td>';
				}

				if ((data[i].customer_id != null) && (data[i].customer_id != '')) {
					html += '<td width=250px style="vertical-align: middle;">&nbsp;' + data[i].customer_id + '</td>';
				}else{
					html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
				}

				if ((data[i].total_htva != null) && (data[i].total_htva != 0)) {
					html += '<td width=150px style="vertical-align: middle;" align="right">' + data[i].total_htva.toFixed(2)  + '&nbsp;</td>';
				}else{
					html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
				}

				if ((data[i].currency_id != null) && (data[i].currency_id != '')) {
					html += '<td width=80px style="vertical-align: middle;">&nbsp;' + data[i].currency_id + '</td>';
				}else{
					html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
				}

				html += '</tr>';
			}

			html += '</table>';

		}

		html += '</div>';

		return html;
	}

	template_search()
	{
		if (document.getElementById('html_sales_select_template_grid_detail').rows.length > 0) {

			for (var i = 0; i < document.getElementById('html_sales_select_template_grid_detail').rows.length; i++) {

				document.getElementById('html_sales_select_template_grid_detail').rows[i].style.display = 'table-row';

			}
		}

		let search = document.getElementById('html_sales_select_template_search').value;

		if (search.trim() != '') {

			for (var i = 0; i < document.getElementById('html_sales_select_template_grid_detail').rows.length; i++) {

				if ((document.getElementById('html_sales_select_template_grid_detail').rows[i].cells[1].innerText.toUpperCase().indexOf(search.toUpperCase()) == -1) &&
					(document.getElementById('html_sales_select_template_grid_detail').rows[i].cells[2].innerText.toUpperCase().indexOf(search.toUpperCase()) == -1) &&
					(document.getElementById('html_sales_select_template_grid_detail').rows[i].cells[3].innerText.toUpperCase().indexOf(search.toUpperCase()) == -1) &&
					(document.getElementById('html_sales_select_template_grid_detail').rows[i].cells[4].innerText.toUpperCase().indexOf(search.toUpperCase()) == -1) &&
					(document.getElementById('html_sales_select_template_grid_detail').rows[i].cells[5].innerText.toUpperCase().indexOf(search.toUpperCase()) == -1)) {

					document.getElementById('html_sales_select_template_grid_detail').rows[i].style.display = 'none';
				}
			}

		}
	}

	template_customer_select()
	{
		let me = this;

		let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email, alternate_id';
		let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400, 250';
		let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email, Alternate id';
		let where = 'ioistatus = 2';
		let order_by = 'name asc';

		let fct_callback = function (return_value) {

			document.getElementById('html_sales_select_template_customer').value = return_value;
			document.getElementById('html_sales_select_template_customer').focus();
		}

		silicon_ioi.ioiCommon.select_customer(fields, fields_len, fields_desc, where, order_by, fct_callback);

	}

	create_document_missing_number()
	{
		let me = this;

		let can_access = 0;

		let method = me.path_sales_document + '.ioi_sales_document_can_access_to_manual_numbering';

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{
							can_access = parseInt(r.message);
						}
		});

		if (parseInt(can_access) == 0) {
			frappe.msgprint({title: __("Message"), message: __('ioi Sales Master Manager role is required'), indicator: "red"});
			return false;
		}



		let title = __("Create document with manual numbering");


		var createdocDialog = new frappe.ui.Dialog({
			title: title,
			static: true,
			fields: [{	label: '', fieldname: 'html_missing_number', fieldtype: 'HTML'}
					],
			primary_action_label: __("Ok"),
			primary_action: function(){
				document.getElementById('html_missing_number_remark1').innerHTML = '';

				if ((!document.getElementById('html_missing_number_document_type').value) || ((document.getElementById('html_missing_number_document_type').value) && (document.getElementById('html_missing_number_document_type').value.trim() == ''))) {
					document.getElementById('html_missing_number_remark1').innerHTML = '<font color="red">' + __("Document type is mandatory") + '</font>';
					return false;
				}

				if ((!document.getElementById('html_missing_number_prefix_id').value) || ((document.getElementById('html_missing_number_prefix_id').value) && (document.getElementById('html_missing_number_prefix_id').value.trim() == ''))) {
					document.getElementById('html_missing_number_remark1').innerHTML = '<font color="red">' + __("Prefix is mandatory") + '</font>';
					return false;
				}


				let identification = '';

				if ((!document.getElementById('html_missing_number_identification').value) || (document.getElementById('html_missing_number_identification').value.trim() == '')) {
					document.getElementById('html_missing_number_remark1').innerHTML = '<font color="red">' + __("Identification is mandatory") + '</font>';
					document.getElementById('html_missing_number_identification').focus();
					return false;
				}

				identification = document.getElementById('html_missing_number_identification').value;

				let journal_id = ''
				let last_number = 0;
				let nb_digits = 0;

				for (var i = 0; i < me.missing_number_prefixes.length; i++) {
					if (me.missing_number_prefixes[i][1] == document.getElementById('html_missing_number_prefix_id').value) {

						journal_id = me.missing_number_prefixes[i][0];
						last_number = me.missing_number_prefixes[i][2];
						nb_digits = me.missing_number_prefixes[i][3];
						break;

					}
				}

				let method = me.path_sales_document + '.ioi_sales_document_get_subdocument_separator_nb_digits';

				let sub_sperator = '.';
				let sub_nb_digits = 2;

				frappe.call({  	method: method,
								args: {	"document_type": document.getElementById('html_missing_number_document_type').value,
										"prefix_id": document.getElementById('html_missing_number_prefix_id').value
								},
								async: false,
								callback:function(r)	{
									sub_sperator = r.message.sub_separator;
									sub_nb_digits = r.message.sub_nb_digits;
								}
				});



				let authorized_char = '0123456789' + sub_sperator;

				let not_found = false;

				for (var i = 0; i < identification.length; i++) {
					if (authorized_char.indexOf(identification[i]) == -1) {
						not_found = true;
						break;
					}
				}

				if (not_found) {
					document.getElementById('html_missing_number_remark1').innerHTML = '<font color="red">' + __("Identification is not valid") + '</font>';
					document.getElementById('html_missing_number_identification').focus();
					return false;
				}

				let is_subdocument = 0;
				let root_id = -1
				let parent_id = -1;

				if (identification.indexOf(sub_sperator) == -1) {

					if (identification.toString().length != nb_digits) {
						document.getElementById('html_missing_number_remark1').innerHTML = '<font color="red">' + __("Identification has to have") + ' ' + nb_digits.toString() + ' ' + __("digits") + '</font>';
						return false;
					}

					if (parseInt(identification) > (parseInt(last_number)+1)) {
						document.getElementById('html_missing_number_remark1').innerHTML = '<font color="red">' + __("Identification has to be lower than or equal to") + ' ' + (parseInt(last_number)+1).toString() + '</font>';
						return false;
					}
				}else{


					is_subdocument = 1;

					let s = identification;

					root_id = s.substring(0, s.indexOf(sub_sperator))

					let last_index = s.lastIndexOf(sub_sperator);

					parent_id = s.substring(0, last_index);

					s = s.substring(last_index+1, s.length)

					if (s.length != sub_nb_digits) {
						document.getElementById('html_missing_number_remark1').innerHTML = '<font color="red">' + __("Sub level no digits has to have") + ' ' + (parseInt(sub_nb_digits)).toString() + ' ' + __("digits") + '</font>';
						return false;
					}

					let authorized_char = '0123456789';

					let not_found = false;

					for (var i = 0; i < s.length; i++) {
						if (authorized_char.indexOf(s[i]) == -1) {
							not_found = true;
							break;
						}
					}

					if (not_found) {
						document.getElementById('html_missing_number_remark1').innerHTML = '<font color="red">' + __("Sub level is not valid") + '</font>';
						document.getElementById('html_missing_number_identification').focus();
						return false;
					}

					if (parseInt(s) == 0) {
						document.getElementById('html_missing_number_remark1').innerHTML = '<font color="red">' + __("Sub level hase to be greater than 00") + '</font>';
						return false;
					}


					let method = me.path_sales_document + '.ioi_sales_document_check_if_document_root_parent_exists';

					let data = {};

					frappe.call({  	method: method,
									args: {	"doctype": me.frm.doctype,
											"prefix_id": document.getElementById('html_missing_number_prefix_id').value,
											"root_id" : root_id,
											"parent_id": parent_id
									},
									async: false,
									callback:function(r)	{
										data = r.message;
									}
					});

					if (data.error != 0) {
						document.getElementById('html_missing_number_remark1').innerHTML = '<font color="red">' + data.error_msg + '</font>';
						return false;
					}


				}

				let already_exists = true;

				method = me.path_sales_document + '.ioi_sales_document_check_if_document_already_exists';

				frappe.call({  	method: method,
								args: {	"doctype": me.frm.doctype,
										"prefix_id": document.getElementById('html_missing_number_prefix_id').value,
										"identification": document.getElementById('html_missing_number_identification').value
								},
								async: false,
								callback:function(r)	{

									if (r.message == 0) {
										already_exists = false;
									}
								}
				});

				if (already_exists) {
					document.getElementById('html_missing_number_remark1').innerHTML = '<font color="red">' + __("This document already exists") + '</font>';
					return false;
				}

				if ((!document.getElementById('html_missing_number_customer_supplier_id').value) || ((document.getElementById('html_missing_number_customer_supplier_id').value) && (document.getElementById('html_missing_number_customer_supplier_id').value.trim() == ''))) {
					document.getElementById('html_missing_number_remark1').innerHTML = '<font color="red">' + __("The customer is mandatory") + '</font>';
					return false;
				}

				if ((!document.getElementById('html_missing_number_document_date').value) || ((document.getElementById('html_missing_number_document_date').value) && (document.getElementById('html_missing_number_document_date').value.trim() == ''))) {
					document.getElementById('html_missing_number_remark1').innerHTML = '<font color="red">' + __("Document date is mandatory") + '</font>';
					return false;
				}

				if ((!document.getElementById('html_missing_number_period_id').value) || ((document.getElementById('html_missing_number_period_id').value) && (document.getElementById('html_missing_number_period_id').value.trim() == ''))) {
					document.getElementById('html_missing_number_remark1').innerHTML = '<font color="red">' + __("Period is mandatory") + '</font>';
					return false;
				}

				let discard_price = 0;

				if (document.getElementById("html_missing_number_discard_price").checked) {
					discard_price = 1;
				}

				let remove_link = 0;

				if (document.getElementById("html_missing_number_remove_link").checked) {
					remove_link = 1;
				}



				method = me.path_sales_document + '.ioi_sales_document_create_missing_document';

				frappe.call({  	method: method,
								args: {	"doctype": me.frm.doctype,
										"document_type": document.getElementById('html_missing_number_document_type').value,
										"journal_id": journal_id,
										"prefix_id": document.getElementById('html_missing_number_prefix_id').value,
										"identification": document.getElementById('html_missing_number_identification').value,
										"is_subdocument": is_subdocument,
										"root_id": document.getElementById('html_missing_number_prefix_id').value.toUpperCase() + ' • ' + root_id,
										"parent_id": document.getElementById('html_missing_number_prefix_id').value.toUpperCase() + ' • ' + parent_id,
										"customer_id": document.getElementById('html_missing_number_customer_supplier_id').value,
										"document_date": document.getElementById('html_missing_number_document_date').value,
										"period_id": document.getElementById("html_missing_number_period_id").value,
										"from_document": document.getElementById("html_missing_number_from_document").value,
										"discard_price": discard_price,
										"remove_link": remove_link


								},
								async: false,
								callback:function(r)	{

									if (r.message.error != 0) {
										document.getElementById('html_missing_number_remark1').innerHTML = '<font color="red">' + r.message.error_message + '</font>';
									}else{
										createdocDialog.hide();

										let url = '';

										if (me.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') {
											url = '/app/ioi-sales-quote/' + r.message.new_document;
										}else if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
											url = '/app/ioi-sales-order/' + r.message.new_document;
										}else if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
											url = '/app/ioi-sales-delivery/' + r.message.new_document;
										}else if (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
											url = '/app/ioi-sales-invoice/' + r.message.new_document;
										}
										window.open(url);
									}

								}
				});


			},
			secondary_action_label: __("Cancel"),
			secondary_action: function(){

				createdocDialog.hide();
			}

		});

		if (document.getElementById('html_missing_number_document_type_label')) {
			document.getElementById('html_missing_number_document_type_label').remove();
		}

		if (document.getElementById('html_missing_number_document_type')) {
			document.getElementById('html_missing_number_document_type').remove();
		}


		if (document.getElementById('html_missing_number_prefix_id_label')) {
			document.getElementById('html_missing_number_prefix_id_label').remove();
		}

		if (document.getElementById('html_missing_number_prefix_id')) {
			document.getElementById('html_missing_number_prefix_id').remove();
		}


		if (document.getElementById('html_missing_number_identification_label')) {
			document.getElementById('html_missing_number_identification_label').remove();
		}

		if (document.getElementById('html_missing_number_identification')) {
			document.getElementById('html_missing_number_identification').remove();
		}

		if (document.getElementById('html_missing_number_identification_button')) {
			document.getElementById('html_missing_number_identification_button').remove();
		}


		if (document.getElementById('html_missing_number_customer_supplier_label')) {
			document.getElementById('html_missing_number_customer_supplier_label').remove();
		}

		if (document.getElementById('html_missing_number_customer_supplier_id')) {
			document.getElementById('html_missing_number_customer_supplier_id').remove();
		}

		if (document.getElementById('html_missing_number_customer_supplier_button')) {
			document.getElementById('html_missing_number_customer_supplier_button').remove();
		}


		if (document.getElementById('html_missing_number_document_date_label')) {
			document.getElementById('html_missing_number_document_date_label').remove();
		}

		if (document.getElementById('html_missing_number_document_date')) {
			document.getElementById('html_missing_number_document_date').remove();
		}

		if (document.getElementById('html_missing_number_period_label')) {
			document.getElementById('html_missing_number_period_label').remove();
		}

		if (document.getElementById('html_missing_number_period_id')) {
			document.getElementById('html_missing_number_period_id').remove();
		}

		if (document.getElementById('html_missing_number_period_button')) {
			document.getElementById('html_missing_number_period_button').remove();
		}

		if (document.getElementById('html_missing_number_from_document_label')) {
			document.getElementById('html_missing_number_from_document_label').remove();
		}

		if (document.getElementById('html_missing_number_from_document')) {
			document.getElementById('html_missing_number_from_document').remove();
		}

		if (document.getElementById('html_missing_number_from_document_button')) {
			document.getElementById('html_missing_number_from_document_button').remove();
		}


		if (document.getElementById('html_missing_number_discard_price')) {
			document.getElementById('html_missing_number_discard_price').remove();
		}

		if (document.getElementById('html_missing_number_discard_price_label')) {
			document.getElementById('html_missing_number_discard_price_label').remove();
		}


		if (document.getElementById('html_missing_number_remove_link')) {
			document.getElementById('html_missing_number_remove_link').remove();
		}

		if (document.getElementById('html_missing_number_remove_link_label')) {
			document.getElementById('html_missing_number_remove_link_label').remove();
		}


		if (document.getElementById('html_missing_number_remark1')) {
			document.getElementById('html_missing_number_remark1').remove();
		}


		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:235px;">';


		// Document type
		html += '	<div style="position: relative; top: 0px; left: 0px; width:170px;">';
		html += '		<label id="html_missing_number_document_type_label" style="position: absolute; top: 0px; left: 2px;">' + __("Document type") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '			<select id="html_missing_number_document_type" class="input-with-feedback form-control bold"> ';
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		// Prefix
		html += '	<div style="position: relative; top: 0px; left: 180px; width:170px;">';
		html += '		<label id="html_missing_number_prefix_id_label" style="position: absolute; top: 0px; left: 2px;">' + __("Prefix") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '			<select id="html_missing_number_prefix_id" class="input-with-feedback form-control bold"> ';
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		// Identification
		html += '	<div style="position: relative; top: 0px; left: 360px; width:170px;">';
		html += '		<label id="html_missing_number_identification_label" style="position: absolute; top: 0px; left: 2px;">' + __("Identification") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '			<input id="html_missing_number_identification" type="text" class="input-with-feedback form-control bold" value=""> ';
		html += '			</select> ';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 180px; width: 30px; height: 25px;"> ';
		html +='			<button id="html_missing_number_identification_button" style="position:absolute; height: 30px; width: 30px; z-index:10; border:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';

		html += '	</div>';

		// Customer
		html += '	<div style="position: relative; top: 65px; left: 0px; width:170px;">';
		html += '		<label id="html_missing_number_customer_supplier_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Customer") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 170px; height: 25px;"> ';
		html += '			<input id="html_missing_number_customer_supplier_id" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value="">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 180px; width: 30px; height: 25px;"> ';
		html +='			<button id="html_missing_number_customer_supplier_button" style="position:absolute; height: 30px; width: 30px; z-index:10; border:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';


		// Document date
		html += '	<div style="position: relative; top: 65px; left: 220px; width:190px;">';
		html += '		<label id="html_missing_number_document_date_label" style="position: absolute; top: 0px; left: 2px;">' + __("Document date") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 130px; height: 25px;"> ';
		html += '			<input id="html_missing_number_document_date" type="date" class="input-with-feedback form-control bold">';
		html += '		</div>';
		html += '	</div>';


		// Period
		html += '	<div style="position: relative; top: 65px; left: 360px; width:170px;">';
		html += '		<label id="html_missing_number_period_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Period") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 170px; height: 25px;"> ';
		html += '			<input id="html_missing_number_period_id" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value="">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 180px; width: 30px; height: 25px;"> ';
		html +='			<button id="html_missing_number_period_button" style="position:absolute; height: 30px; width: 30px; z-index:10; border:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';


		// Get lines detail from
		html += '	<div style="position: relative; top: 130px; left: 0px; width:170px;">';
		html += '		<label id="html_missing_number_from_document_label" style="position: absolute; top: 0px; left: 2px;">' + __("Get lines detail from") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '			<input id="html_missing_number_from_document" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value=""> ';
		html += '			</select> ';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 180px; width: 30px; height: 25px;"> ';
		html +='			<button id="html_missing_number_from_document_button" style="position:absolute; height: 30px; width: 30px; z-index:10; border:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Discard prices refresh
		html += '	<div style="position: relative; top: 150px; left: 220px; width:250px;">';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 0px; width: 250px; height: 25px;"> ';
		html += '			<input id="html_missing_number_discard_price" type="checkbox" class="input-with-feedback form-control bold">';
		html += '		</div>';

		html += '		<label id="html_missing_number_discard_price_label" style="position: absolute; top: 0px; left: 20px;"><b>' + __("Discard prices refresh") + '</b></label>';
		html += '	</div>';

		// Remove lettering in detail lines
		html += '	<div style="position: relative; top: 170px; left: 220px; width:250px;">';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 0px; width: 250px; height: 25px;"> ';
		html += '			<input id="html_missing_number_remove_link" type="checkbox" class="input-with-feedback form-control bold">';
		html += '		</div>';

		html += '		<label id="html_missing_number_remove_link_label" style="position: absolute; top: 0px; left: 20px;z-index:5;"><b>' + __("Remove lettring in detail lines") + '</b></label>';
		html += '	</div>';


		// Remark1
		html += '	<div style="position: relative; top: 200px; left: 0px; width:500px;" align="center">';
		html += '		<label id="html_missing_number_remark1"></label>';
		html += '	</div>';

		html += '</div>';



		createdocDialog.fields_dict.html_missing_number.$wrapper.html(html);
		createdocDialog.$wrapper.find('.modal-dialog').css("max-width", "615px").css("width", "615px");
		createdocDialog.show();

		this.#sleep(200).then(() => {

			let fct_keydown = function(event) {

				if (event.keyCode == 13)
				{	return false;
				}

			};

			let fct_keyup = function(event) {

				if (event.keyCode == 13)
				{	return false;
				}

			};



			// Fill Document type
			let method = me.path_sales_journal + '.ioi_sales_journal_get_document_types';
			frappe.call({  	method: method,
							args: {"doctype": me.frm.doctype},
							async: false,
							callback:function(r)	{

								for (var i = document.getElementById('html_missing_number_document_type').options.length-1; i >= 0; i--) {
									document.getElementById('html_missing_number_document_type').options.remove(i);
								}

								var x = document.getElementById('html_missing_number_document_type');

								if (r.message.length > 0) {

									for (var i = 0; i < r.message.length; i++) {

										var c = document.createElement("option");
										c.value = r.message[i].document_type;
										c.text = r.message[i].document_type_desc;
										x.options.add(c);
									}

								}

								if (me.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') {

									let found_quote = false;

									for (var i = 0; i < document.getElementById('html_missing_number_document_type').options.length; i++) {
										if (document.getElementById('html_missing_number_document_type').options[i].value == 'QUOTE') {
											found_quote = true;
											document.getElementById('html_missing_number_document_type').selectedIndex = i;
											break;
										}
									}

									if (!found_quote) {
										document.getElementById('html_missing_number_document_type').selectedIndex = 0;
									}

								}else if (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
									let found_quote = false;

									for (var i = 0; i < document.getElementById('html_missing_number_document_type').options.length; i++) {
										if (document.getElementById('html_missing_number_document_type').options[i].value == 'INVOICE') {
											found_quote = true;
											document.getElementById('html_missing_number_document_type').selectedIndex = i;
											break;
										}
									}

									if (!found_quote) {
										document.getElementById('html_missing_number_document_type').selectedIndex = 0;
									}

								}else{
									document.getElementById('html_missing_number_document_type').selectedIndex = 0;
								}

						}
			});

			let fct_change_document_type = function() {
				me.missing_number_fill_prefixes();
				me.missing_number_fill_identification();
			};

			document.getElementById('html_missing_number_document_type').onchange = fct_change_document_type;



			// Fill prefixe
			me.missing_number_fill_prefixes();

			let fct_change_prefix_id = function() {
				me.missing_number_fill_identification();
			};

			document.getElementById('html_missing_number_prefix_id').onchange = fct_change_prefix_id;



			// Fill Identification
			me.missing_number_fill_identification();

			document.getElementById("html_missing_number_identification").onkeydown = fct_keydown;
			document.getElementById("html_missing_number_identification").onkeyup = fct_keyup;


			let fct_identification_button_click = function() {
				me.select_missing_number()
			}


			document.getElementById("html_missing_number_identification_button").onclick = fct_identification_button_click;
			document.getElementById("html_missing_number_identification_button").onkeydown = fct_keydown;


			// Customer
			let fct_customer_button_click = function() {


				let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email, alternate_id';
				let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400, 250';
				let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email, Alternate id';
				let where = 'ioistatus = 2';
				let order_by = 'name asc';

				let fct_callback = function (return_value) {

					document.getElementById("html_missing_number_customer_supplier_id").value = return_value;
					document.getElementById('html_missing_number_customer_supplier_id').focus();
				}

				silicon_ioi.ioiCommon.select_customer(fields, fields_len, fields_desc, where, order_by, fct_callback);
			}

			document.getElementById("html_missing_number_customer_supplier_button").onclick = fct_customer_button_click;
			document.getElementById("html_missing_number_customer_supplier_button").onkeydown = fct_keydown;

			document.getElementById("html_missing_number_customer_supplier_id").onkeydown = fct_keydown;
			document.getElementById("html_missing_number_customer_supplier_id").onkeyup = fct_keyup;


			// Document date

			const date = new Date();
			let day = date.getDate();
			let month = date.getMonth() + 1;
			let year = date.getFullYear();

			let s = year.toString() + '-';

			if (month < 10) {
				s += '0';
			}

			s += month.toString() + '-';

			if (day < 10) {
				s += 0;
			}

			s += day.toString()

			document.getElementById('html_missing_number_document_date').value = s;


			// Period

			method = me.path_sales_document + '.ioi_sales_document_get_period_for_date';
			frappe.call({  	method: method,
							args: {"dt": s},
							async: false,
							callback:function(r)	{

								if (r.message.length > 0) {
									document.getElementById('html_missing_number_period_id').value = r.message[0].name;
								}

						}
			});

			let fct_search_period = function() {

				let title = __("Select a period");
				let form_width_pixel = "600px";
				let table = "ioi Period";
				let fields = "name";
				let fields_len = "500";
				let fields_desc = __("Period");

				let where = "enabled = 1 ";

				for (var i = 0; i < me.missing_number_prefixes.length; i++) {
					if (document.getElementById('html_missing_number_prefix_id').value == me.missing_number_prefixes[i][0]) {
						where += "and division = '"  +  me.missing_number_prefixes[i][6] + "' ";
						break;
					}
				}

				let order_by = "name asc";

				let fct_callback = function(return_value) {
					document.getElementById('html_missing_number_period_id').value = return_value;
				};

				me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);
			};

			document.getElementById("html_missing_number_period_button").onclick = fct_search_period;
			document.getElementById("html_missing_number_period_button").onkeydown = fct_keydown;

			document.getElementById("html_missing_number_period_id").onkeydown = fct_keydown;
			document.getElementById("html_missing_number_period_id").onkeyup = fct_keyup;

			// Get lines detail

			let fct_get_document = function() {

				let title = '';
				let table = '';
				let fields = "";
				let fields_len = "";
				let fields_desc = "";

				let form_width_pixel = "1540px";


				if (me.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') {
					title = __("Select a sales quote / contract");
					table = "ioi Sales Quote";
					fields = "name, journal_id, document_type, document_date, order_customer_id, order_customer_name, external_ref, total_htva, currency_id";
					fields_len = "250, 120, 20, 150, 200, 300, 200, 120, 100";
					fields_desc = __("Identification") + ',' + __("Journal") + ',' + __("Doc. type") + ',' + __("Document date") + ',' + __("Customer") + ',' + __("Customer name") + ',' + __("External ref") + ',' + __("Total w/o VAT") + ',' + __("Currency");
					form_width_pixel = "1660px";
				}else if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
					title = __("Select a sales order");
					table = "ioi Sales Order";
					fields = "name, journal_id, document_date, order_customer_id, order_customer_name, external_ref, total_htva, currency_id";
					fields_len = "250, 120, 150, 200, 300, 200, 120, 100";
					fields_desc = __("Identification") + ',' + __("Journal") + ',' + __("Document date") + ',' + __("Customer") + ',' + __("Customer name") + ',' + __("External ref") + ',' + __("Total w/o VAT") + ',' + __("Currency");
				}else if (me.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
					title = __("Select a sales delivery");
					table = "ioi Sales Delivery";
					fields = "name, journal_id, document_date, delivery_customer_id, delivery_customer_name, external_ref, total_htva, currency_id";
					fields_len = "250, 120, 150, 200, 300, 200, 120, 100";
					fields_desc = __("Identification") + ',' + __("Journal") + ',' + __("Document date") + ',' + __("Customer") + ',' + __("Customer name") + ',' + __("External ref") + ',' + __("Total w/o VAT") + ',' + __("Currency");

				}else if (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
					title = __("Select a sales invoice / credit note");
					table = "ioi Sales Invoice";
					fields = "name, journal_id, document_type, document_date, invoice_customer_id, invoice_customer_name, external_ref, total_htva, currency_id";
					fields_len = "250, 120, 120, 150, 200, 300, 200, 120, 100";
					fields_desc = __("Identification") + ',' + __("Journal") + ',' + __("Doc. type") + ',' + __("Document date") + ',' + __("Customer") + ',' + __("Customer name") + ',' + __("External ref") + ',' + __("Total w/o VAT") + ',' + __("Currency");
					form_width_pixel = "1660px";
				}




				let where = "1 = 1 ";

				let order_by = "document_date desc";

				let fct_callback = function(return_value) {
					document.getElementById('html_missing_number_from_document').value = return_value;
					document.getElementById('html_missing_number_from_document').focus();
				};

				me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);
			};

			document.getElementById("html_missing_number_from_document_button").onclick = fct_get_document;
			document.getElementById("html_missing_number_from_document_button").onkeydown = fct_keydown;

			document.getElementById("html_missing_number_from_document").onkeydown = fct_keydown;
			document.getElementById("html_missing_number_from_document").onkeyup = fct_keyup;

			// Discard price
			document.getElementById("html_missing_number_discard_price").onkeydown = fct_keydown;
			document.getElementById("html_missing_number_discard_price").onkeyup = fct_keyup;

			// Remove link
			document.getElementById("html_missing_number_remove_link").onkeydown = fct_keydown;
			document.getElementById("html_missing_number_remove_link").onkeyup = fct_keyup;

		});

	}

	missing_number_fill_prefixes()
	{
		let me = this;

		if ((document.getElementById('html_missing_number_document_type').value == null) || (document.getElementById('html_missing_number_document_type').value.trim() == '')) {
			return false;
		}

		let amethod = '';
		let tp = document.getElementById('html_missing_number_document_type').value;

		if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') {
			amethod = this.path_sales_journal + '.ioi_sales_journal_get_quotes_contracts_prefixes';
		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
			amethod = this.path_sales_journal + '.ioi_sales_journal_get_orders_prefixes';
		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
			amethod = this.path_sales_journal + '.ioi_sales_journal_get_deliveries_prefixes';
		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
			amethod = this.path_sales_journal + '.ioi_sales_journal_get_invoices_cnotes_prefixes';
		}


		let prefix_enabled = 1;

		frappe.call({  	method: amethod,
					   	args: {"enabled":1, "prefix_enabled" : prefix_enabled, "tp":tp, "current_prefix": ''},
						async: false,
						callback:function(r){
												me.missing_number_prefixes = [];

												for (var i = document.getElementById('html_missing_number_prefix_id').options.length-1; i >= 0; i--) {
													document.getElementById('html_missing_number_prefix_id').options.remove(i);
												}


												var x = document.getElementById('html_missing_number_prefix_id');

												for (var i = 0; i < r.message.length; i++)
												{

													if (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
																			          // name             prefix           last_number      nb_digits		  sublevel_sep     sublvl_nb_digits division_id      dt.site_id       vat_incl_ctrl    vcs_mode,        vcs_param
														me.missing_number_prefixes[i] = [r.message[i][0], r.message[i][1], r.message[i][2], r.message[i][3], r.message[i][4], r.message[i][5], r.message[i][6], r.message[i][7], r.message[i][8], r.message[i][9], r.message[i][10]];
													}else{
																					  // name             prefix           last_number      nb_digits		  sublevel_sep     sublvl_nb_digits division_id      dt.site_id       vat_incl_ctrl
														me.missing_number_prefixes[i] = [r.message[i][0], r.message[i][1], r.message[i][2], r.message[i][3], r.message[i][4], r.message[i][5], r.message[i][6], r.message[i][7], r.message[i][8]];
													}

													var c = document.createElement("option");
													c.value = me.missing_number_prefixes[i][1];
													c.text = me.missing_number_prefixes[i][1];
													x.options.add(c);

												}

												document.getElementById('html_missing_number_prefix_id').selectedIndex = 0;
											}
		});
	}


	missing_number_fill_identification()
	{
		document.getElementById('html_missing_number_remark1').innerHTML = '';

		for (var i = 0; i < this.missing_number_prefixes.length; i++) {
			if (this.missing_number_prefixes[i][1] == document.getElementById('html_missing_number_prefix_id').value) {

				if ((parseInt(this.missing_number_prefixes[i][2])+1).toString().length < this.missing_number_prefixes[i][3])
				{
					let nb = parseInt(this.missing_number_prefixes[i][3]) - (parseInt(this.missing_number_prefixes[i][2])+1).toString().length;

					let s = '';
					for (var j = 1; j <= nb; j++) {
						s += '0'

					}
					s += (parseInt(this.missing_number_prefixes[i][2])+1).toString();

					document.getElementById('html_missing_number_identification').value = s;
				}else{
					document.getElementById('html_missing_number_identification').value = (parseInt(this.missing_number_prefixes[i][2])+1).toString();
				}
			}
		}
	}

	select_missing_number()
	{
		document.getElementById('html_missing_number_remark1').innerHTML = '';

		if ((document.getElementById('html_missing_number_document_type').value == null) || (document.getElementById('html_missing_number_document_type').value.trim() == '')) {
			document.getElementById('html_missing_number_remark1').innerHTML = '<font color="red>' + __("Document type is mandatory") + '</font>';
			return false;
		}

		if ((document.getElementById('html_missing_number_prefix_id').value == null) || (document.getElementById('html_missing_number_prefix_id').value.trim() == '')) {
			document.getElementById('html_missing_number_remark1').innerHTML = '<font color="red>' + __("Prefix is mandatory") + '</font>';
			return false;
		}


		let is_period_break = 0;

		let amethod = this.path_sales_document + '.ioi_sales_document_is_period_break';

		frappe.call({  	method: amethod,
						args: {	"document_type": document.getElementById('html_missing_number_document_type').value,
								"prefix_id": document.getElementById('html_missing_number_prefix_id').value},
						async: false,
						callback:function(r)	{

							is_period_break = r.message;
						}
		});

		if (parseInt(is_period_break) == 1) {
			document.getElementById('html_missing_number_remark1').innerHTML = '<font color="red">' + __("Not allowed for period break numbering") + '</font>';
			return false;
		}


		let me = this;

		let title = __("Sequential numbering error");

		var missingdocDialog = new frappe.ui.Dialog({
			title: title,
			static: true,
			fields: [{	label: '', fieldname: 'html_missing_doc', fieldtype: 'HTML'}
					],
			primary_action_label: __("Ok"),
			primary_action: function(){

				if (!document.getElementById('html_missing_doc_table_detail')) {
					frappe.msgprint({title: __("Message"), message: __('No selected document'), indicator: "red"});
					return false;
				}

				let one_found = false;
				let identification = 0;

				for (var i = 0; i < document.getElementById('html_missing_doc_table_detail').rows.length; i++) {

					if (document.getElementById('html_missing_doc_table_detail_checked_id_' + i.toString())) {
						if (document.getElementById('html_missing_doc_table_detail_checked_id_' + i.toString()).checked) {
							one_found = true;
							identification = document.getElementById('html_missing_doc_table_detail_identification_' + i.toString()).value;
							break;
						}
					}
				}

				if (!one_found) {
					frappe.msgprint({title: __("Message"), message: __('No selected document'), indicator: "red"});
					return false;
				}

				document.getElementById('html_missing_number_document_type').value = document.getElementById('html_missing_doc_document_type').value;

				me.missing_number_fill_prefixes();

				document.getElementById('html_missing_number_prefix_id').value = document.getElementById('html_missing_doc_prefix_id').value;

				document.getElementById('html_missing_number_identification').value = identification;

				missingdocDialog.hide();
			},
			secondary_action_label: __("Cancel"),
			secondary_action: function(){
				missingdocDialog.hide();
			}

		});

		if (document.getElementById('html_missing_doc_document_type_label')) {
			document.getElementById('html_missing_doc_document_type_label').remove();
		}

		if (document.getElementById('html_missing_doc_document_type')) {
			document.getElementById('html_missing_doc_document_type').remove();
		}


		if (document.getElementById('html_missing_doc_prefix_id_label')) {
			document.getElementById('html_missing_doc_prefix_id_label').remove();
		}

		if (document.getElementById('html_missing_doc_prefix_id')) {
			document.getElementById('html_missing_doc_prefix_id').remove();
		}

		if (document.getElementById('html_missing_doc_nb_record_label')) {
			document.getElementById('html_missing_doc_nb_record_label').remove();
		}

		if (document.getElementById('html_missing_doc_nb_record')) {
			document.getElementById('html_missing_doc_nb_record').remove();
		}

		if (document.getElementById('html_missing_doc_content_header')) {
			document.getElementById('html_missing_doc_content_header').remove();
		}

		if (document.getElementById('html_missing_doc_content')) {
			document.getElementById('html_missing_doc_content').remove();
		}

		if (document.getElementById('html_missing_doc_table_header')) {
			document.getElementById('html_missing_doc_table_header').remove();
		}


		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:70px;">';


		// Document type
		html += '	<div style="position: relative; top: 0px; left: 0px; width:150px;">';
		html += '		<label id="html_missing_doc_document_type_label" style="position: absolute; top: 0px; left: 2px;">' + __("Document type") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 150px; height: 30px;"> ';
		html += '			<select id="html_missing_doc_document_type" class="input-with-feedback form-control bold"> ';
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		// Prefix
		html += '	<div style="position: relative; top: 0px; left: 160px; width:120px;">';
		html += '		<label id="html_missing_doc_prefix_id_label" style="position: absolute; top: 0px; left: 2px;">' + __("Prefix") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 120px; height: 30px;"> ';
		html += '			<select id="html_missing_doc_prefix_id" class="input-with-feedback form-control bold"> ';
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		// Nb records
		html += '	<div style="position: relative; top: 0px; left: 290px; width:120px;">';
		html += '		<label id="html_missing_doc_nb_record_label" style="position: absolute; top: 0px; left: 2px;">' + __("last N documents") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 120px; height: 30px;"> ';
		html += '			<input id="html_missing_doc_nb_record" type="number" step="any" class="input-with-feedback form-control bold" value="100"> ';
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';
		html += '</div>';

		html += '<div id="html_missing_doc_content_header" style="overflow: auto; overflow-x: auto; overflow-y: hidden; height:30px">';
		html += '	<table id="html_missing_doc_table_header" border=1 style="border: 1px solid #E8EAEB" width=414px data-custom-grid="true">';
		html += '	<tr style="height:30px">';
		html += '	<td width=30px  style="vertical-align: middle;">&nbsp;</td>';
		html += '	<td width=184px style="vertical-align: middle;">&nbsp;<b>' + __("Prefix") + '</b></td>';
		html += '	<td width=200px style="vertical-align: middle;">&nbsp;<b>' + __("Identification") + '</b></td>';
		html += '	</tr>';
		html += '	</table>';
		html += '</div>';

		html += '<div id="html_missing_doc_content" style="overflow: auto; overflow-x: auto; height:400px"></div>';



		missingdocDialog.fields_dict.html_missing_doc.$wrapper.html(html);
		missingdocDialog.$wrapper.find('.modal-dialog').css("max-width", "465px").css("width", "465px");
		missingdocDialog.show();

		this.#sleep(200).then(() => {

			let fct_keydown = function(event) {

				if (event.keyCode == 13)
				{	return false;
				}

			};

			let fct_keyup = function(event) {

				if (event.keyCode == 13)
				{	return false;
				}

			};

			let fct_keydown_refresh = function(event) {

				if (event.keyCode == 13)
				{
					me.missing_doc_refresh();
					return false;
				}

			};



			// Fill Document type
			let method = me.path_sales_journal + '.ioi_sales_journal_get_document_types';
			frappe.call({  	method: method,
							args: {"doctype": me.frm.doctype},
							async: false,
							callback:function(r)	{

								for (var i = document.getElementById('html_missing_number_document_type').options.length-1; i >= 0; i--) {
									document.getElementById('html_missing_doc_document_type').options.remove(i);
								}

								var x = document.getElementById('html_missing_doc_document_type');

								if (r.message.length > 0) {

									for (var i = 0; i < r.message.length; i++) {

										var c = document.createElement("option");
										c.value = r.message[i].document_type;
										c.text = r.message[i].document_type_desc;
										x.options.add(c);
									}

								}

								document.getElementById('html_missing_doc_document_type').value = document.getElementById('html_missing_number_document_type').value;
								document.getElementById('html_missing_doc_document_type').disabled = true;
						}
			});

			let fct_change_document_type = function() {
				me.missing_doc_fill_prefixes();
				me.missing_doc_refresh();
			};

			document.getElementById('html_missing_doc_document_type').onchange = fct_change_document_type;


			let fct_change_prefix_id = function() {
				me.missing_doc_refresh();
			};

			document.getElementById('html_missing_doc_prefix_id').onchange = fct_change_prefix_id;




			// Fill prefixe
			me.missing_doc_fill_prefixes();
			document.getElementById('html_missing_doc_prefix_id').value = document.getElementById('html_missing_number_prefix_id').value;

			document.getElementById("html_missing_doc_nb_record").onkeydown = fct_keydown_refresh;
			document.getElementById("html_missing_doc_nb_record").onkeyup = fct_keyup;

			me.missing_doc_refresh();

		});
	}

	missing_doc_fill_prefixes()
	{
		let me = this;

		if ((document.getElementById('html_missing_doc_document_type').value == null) || (document.getElementById('html_missing_doc_document_type').value.trim() == '')) {
			return false;
		}

		let amethod = '';
		let tp = document.getElementById('html_missing_doc_document_type').value;

		if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') {
			amethod = this.path_sales_journal + '.ioi_sales_journal_get_quotes_contracts_prefixes';
		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
			amethod = this.path_sales_journal + '.ioi_sales_journal_get_orders_prefixes';
		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
			amethod = this.path_sales_journal + '.ioi_sales_journal_get_deliveries_prefixes';
		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
			amethod = this.path_sales_journal + '.ioi_sales_journal_get_invoices_cnotes_prefixes';
		}


		let prefix_enabled = 1;

		frappe.call({  	method: amethod,
					   	args: {"enabled":1, "prefix_enabled" : prefix_enabled, "tp":tp, "current_prefix": ''},
						async: false,
						callback:function(r){
												me.missing_doc_prefixes = [];

												for (var i = document.getElementById('html_missing_doc_prefix_id').options.length-1; i >= 0; i--) {
													document.getElementById('html_missing_doc_prefix_id').options.remove(i);
												}


												var x = document.getElementById('html_missing_doc_prefix_id');

												for (var i = 0; i < r.message.length; i++)
												{


													if (me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
																				   // name             prefix           last_number      nb_digits		  sublevel_sep     sublvl_nb_digits division_id      dt.site_id       vat_incl_ctrl    vcs_mode,        vcs_param
														me.missing_doc_prefixes[i] = [r.message[i][0], r.message[i][1], r.message[i][2], r.message[i][3], r.message[i][4], r.message[i][5], r.message[i][6], r.message[i][7], r.message[i][8], r.message[i][9], r.message[i][10]];
													}else{
																				   // name             prefix           last_number      nb_digits		  sublevel_sep     sublvl_nb_digits division_id      dt.site_id       vat_incl_ctrl
														me.missing_doc_prefixes[i] = [r.message[i][0], r.message[i][1], r.message[i][2], r.message[i][3], r.message[i][4], r.message[i][5], r.message[i][6], r.message[i][7], r.message[i][8]];
													}

													var c = document.createElement("option");
													c.value = me.missing_doc_prefixes[i][1];
													c.text = me.missing_doc_prefixes[i][1];
													x.options.add(c);

												}

												document.getElementById('html_missing_doc_prefix_id').selectedIndex = 0;
											}
		});
	}

	missing_doc_refresh()
	{
		let dc = this.frm.doctype;
		let document_type = document.getElementById('html_missing_doc_document_type').value;
		let prefix_id = document.getElementById('html_missing_doc_prefix_id').value;

		if ((!document.getElementById('html_missing_doc_nb_record').value) || ((document.getElementById('html_missing_doc_nb_record').value) && (document.getElementById('html_missing_doc_nb_record').value.trim() == ''))) {
			document.getElementById('html_missing_doc_nb_record').value = 100;
		}

		if (parseInt(document.getElementById('html_missing_doc_nb_record').value) <= 0) {
			document.getElementById('html_missing_doc_nb_record').value = 100;
		}

		let nb_record = parseInt(document.getElementById('html_missing_doc_nb_record').value)

		let last_number = 0;
		let nb_digits = 0;

		for (var i = 0; i < this.missing_doc_prefixes.length; i++) {
			if (this.missing_doc_prefixes[i][1] == prefix_id) {
				last_number = this.missing_doc_prefixes[i][2];
				nb_digits = this.missing_doc_prefixes[i][3];
				break;
			}
		}


		if (document.getElementById('html_missing_doc_table_detail')) {

			for (var i = 0; i < document.getElementById('html_missing_doc_table_detail').rows.length; i++) {

				if (document.getElementById('html_missing_doc_table_detail_checked_id_' + i.toString())) {
					document.getElementById('html_missing_doc_table_detail_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('html_missing_doc_table_detail_identification_' + i.toString())) {
					document.getElementById('html_missing_doc_table_detail_identification_' + i.toString()).remove();
				}

			}

			document.getElementById('html_missing_doc_table_detail').remove();
		}

		let me = this;

		let method = this.path_sales_document + '.ioi_sales_document_get_missing_document';

		frappe.call({  	method: method,
						args: {	"doctype": dc,
								"document_type": document_type,
								"prefix_id": prefix_id,
								"last_number": last_number,
								"nb_digits": nb_digits,
								"nb_record": nb_record
						},
		 				async: false,
		 				callback:function(r){

							let html = '';


							if (r.message.length > 0) {

								html += '	<table id = "html_missing_doc_table_detail" border=0 style="border: 1px solid #E8EAEB" width=414px>';

								for (var i = 0; i < r.message.length; i++) {

									html += '	<input id="html_missing_doc_table_detail_identification_' + i.toString() + '" type="hidden" value="' + r.message[i] + '">';
									html += '	<tr style="height:30px">';
									html += '	<td width=30px  style="vertical-align: middle;" align="center">';
									html += '	<input type="checkbox" id="html_missing_doc_table_detail_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;" ';
									html += '       onkeydown="   if (event.keyCode == 13) { return false; } " ';
									html += '       onkeyup="   if (event.keyCode == 13) { return false; } " ';
									html += '>';
									html += '</td>';
									html += '	<td width=184px style="vertical-align: middle;">&nbsp;' + prefix_id + '</td>';
									html += '	<td width=200px style="vertical-align: middle;">&nbsp;' + r.message[i] + '</td>';
									html += '	</tr>';
								}
								html += '	</table>';
							}

							document.getElementById('html_missing_doc_content').insertAdjacentHTML('beforeend', html);

							me.#sleep(400).then(() => {

								if (document.getElementById('html_missing_doc_table_detail')) {

									let fct_click = function() {

										for (var i = 0; i < document.getElementById('html_missing_doc_table_detail').rows.length; i++) {
											if (document.getElementById('html_missing_doc_table_detail_checked_id_' + i.toString())) {
												document.getElementById('html_missing_doc_table_detail_checked_id_' + i.toString()).checked = false;
											}
										}

										this.checked = true;
									};

									let first_row = true;

									for (var i = 0; i < document.getElementById('html_missing_doc_table_detail').rows.length; i++) {

										if (document.getElementById('html_missing_doc_table_detail_checked_id_' + i.toString())) {
											if (first_row) {
												first_row = false;
												document.getElementById('html_missing_doc_table_detail_checked_id_' + i.toString()).checked = true;
											}
											document.getElementById('html_missing_doc_table_detail_checked_id_' + i.toString()).onclick = fct_click;
										}

									}
								}



							});

						}
		});
	}

	change_customer_family1()
	{
		if ((this.frm.is_dirty()) || (this.frm.is_new())) {
			let me = this;
			let fct_callback = function () { me.do_change_customer_family1(); };
			this.frm.save('Save', fct_callback);
		}else {
			this.do_change_customer_family1();
		}
	}

	do_change_customer_family1()
	{
		if (this.frm.doc.ioistatus != 0) {
			frappe.msgprint({title: __("Message"), message: __('Document has to be "in preparation"'), indicator: "red"});
			return false;
		}

		let can_change_family1 = 0;

		let method = this.path_sales_document + '.ioi_sales_document_can_change_customer_family1';

		frappe.call({  	method: method,
						args: {
						},
						async: false,
						callback:function(r)	{
							can_change_family1 = r.message;
						}
		});

		if (can_change_family1 != 1) {
			frappe.msgprint({title: __("Message"), message: __('Only "ioi Sales Manager" and "ioi Sales Master Manager" can change the customer family 1'), indicator: "red"});
			return false;
		}


		let title = __("Change the customer family 1");

		let me = this;

		var changeCustFamily1Dialog = new frappe.ui.Dialog({
			title: title,
			static: true,
			fields: [{	label: '', fieldname: 'html_change_customer_family1', fieldtype: 'HTML'}
					],
			primary_action_label: __("Ok"),
			primary_action: function(){

				document.getElementById('html_change_customer_family1_msg').innerHTML = '';

				let identification = document.getElementById('html_change_customer_family1_identification').value.toUpperCase();

				if ((identification) && (identification.trim() != '')) {

					let method = me.path_sales_document + '.ioi_sales_document_check_customer_family1_exists';

					let customer_family1_exists = 0;

					frappe.call({  	method: method,
									args: {	"customer_family1_id": identification
									},
									async: false,
									callback:function(r)	{
										customer_family1_exists = r.message;
									}
					});

					if (customer_family1_exists != 1) {
						document.getElementById('html_change_customer_family1_msg').innerHTML = '<font color="red">' + __("Customer Family 1 does not exist") + '</font>';
						return false;
					}
				}

				let discard_update_prices = 0;

				if (document.getElementById('html_change_customer_family1_discard_update_prices').checked) {
					discard_update_prices = 1
				}


				let method = me.path_sales_document + '.ioi_sales_document_save_customer_family1_and_prices';

				frappe.call({  	method: method,
								args: {	"doctype": me.frm.doctype,
										"name": me.frm.doc.name,
										"customer_family1_id": identification,
										"discard_update_prices": discard_update_prices
								},
								async: false,
								callback:function(r)	{

									changeCustFamily1Dialog.hide();
									window.location.reload()

								}
				});

			},
			secondary_action_label: __("Cancel"),
			secondary_action: function(){

				changeCustFamily1Dialog.hide();
			}

		});


		if (document.getElementById('html_change_customer_family1_identification_label')) {
			document.getElementById('html_change_customer_family1_identification_label').remove();
		}

		if (document.getElementById('html_change_customer_family1_identification')) {
			document.getElementById('html_change_customer_family1_identification').remove();
		}

		if (document.getElementById('html_change_customer_family1_identification_button')) {
			document.getElementById('html_change_customer_family1_identification_button').remove();
		}

		if (document.getElementById('html_change_customer_family1_discard_update_prices')) {
			document.getElementById('html_change_customer_family1_discard_update_prices').remove();
		}

		if (document.getElementById('html_change_customer_family1_discard_update_prices_label')) {
			document.getElementById('html_change_customer_family1_discard_update_prices_label').remove();
		}


		if (document.getElementById('html_change_customer_family1_msg')) {
			document.getElementById('html_change_customer_family1_msg').remove();
		}


		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:130px;">';

		// Identification
		html += '	<div style="position: relative; top: 0px; left: 0px; width:310px;">';
		html += '		<label id="html_change_customer_family1_identification_label" style="position: absolute; top: 0px; left: 2px;">' + __("Customer Family 1") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 310px; height: 30px;"> ';
		html += '			<input id="html_change_customer_family1_identification" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase" value="' + this.frm.doc.customer_family_1_id + '"> ';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 320px; width: 30px; height: 25px;"> ';
		html +='			<button id="html_change_customer_family1_identification_button" style="position:absolute; height: 30px; width: 30px; z-index:10; border:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';

		html += '	</div>';


		// Discard update prices refresh
		html += '	<div style="position: relative; top: 65px; left: 0px; width:250px;">';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 0px; width: 250px; height: 25px;"> ';
		html += '			<input id="html_change_customer_family1_discard_update_prices" type="checkbox" class="input-with-feedback form-control bold">';
		html += '		</div>';

		html += '		<label id="html_change_customer_family1_discard_update_prices_label" style="position: absolute; top: 0px; left: 20px;">' + __("Discard the update prices in detail") + '</label>';
		html += '	</div>';


		// Message
		html += '	<div style="position: relative; top: 95px; left: 0px; width:350px;" align="center">';
		html += '		<label id="html_change_customer_family1_msg"></label>';
		html += '	</div>';

		html += '</div>';



		changeCustFamily1Dialog.fields_dict.html_change_customer_family1.$wrapper.html(html);
		changeCustFamily1Dialog.$wrapper.find('.modal-dialog').css("max-width", "395px").css("width", "395px");
		changeCustFamily1Dialog.show();

		this.#sleep(200).then(() => {

			let fct_keydown = function(event) {

				if (event.keyCode == 13) {
					return false;
				}

			};

			let fct_keydown_validate = function(event) {

				if (event.keyCode == 13) {
					changeCustFamily1Dialog.primary_action();
					return false;
				}

			};


			let fct_keyup = function(event) {

				if (event.keyCode == 13)
				{	return false;
				}

			};

			document.getElementById("html_change_customer_family1_identification").onkeydown = fct_keydown_validate;
			document.getElementById("html_change_customer_family1_identification").onkeyup = fct_keyup;


			let fct_identification_button_click = function() {
				me.select_customer_family1()
			}


			document.getElementById("html_change_customer_family1_identification_button").onclick = fct_identification_button_click;
			document.getElementById("html_change_customer_family1_identification_button").onkeydown = fct_keydown;


			document.getElementById("html_change_customer_family1_discard_update_prices").onkeydown = fct_keydown;
			document.getElementById("html_change_customer_family1_discard_update_prices").onkeyup = fct_keyup;

		});
	}

	select_customer_family1()
	{
		let title = __("Select a customer family 1");
		let form_width_pixel = '710px';
		let table = "ioi Customer Family 1";
		let fields = 'name, description';
		let fields_len = '250, 380';
		let fields_desc = __('Identification') + ',' + __('Description');
		let where = 'enabled = 1';
		let order_by = 'name asc';

		let fct_callback = function (return_value) {
			document.getElementById("html_change_customer_family1_identification").value = return_value;
			document.getElementById("html_change_customer_family1_identification").focus();
		}

		silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)
	}

	create_from_this_document()
	{
		if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
			if (this.frm.doc.intersite_transaction == 1) {
				frappe.msgprint({title: __("Message"), message: __('Not allowed for intersite document'), indicator: "red"});
				return false;
			}
		}

		let title = '';
		let tp = '';

		if (this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') {
			title += __("Create document from this sales quote");
			tp = 'QUOTE';
		}else if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
			title += __("Create document from this sales order");
			tp = 'ORDER';
		}

		let me = this;

		var p = new frappe.ui.Dialog({
			'title': title,
			'static': true,
			'fields': [
				{'fieldname': 'html_create_from_document', 'fieldtype': 'HTML'}

			],
			primary_action_label:  __('Ok'),
			secondary_action_label:  __('Cancel'),
			primary_action: function(){

				document.getElementById('html_create_from_document_message').innerHTML = '';

				if ((!document.getElementById('html_create_from_document_site_id').value) ||
					((document.getElementById('html_create_from_document_site_id').value) && (document.getElementById('html_create_from_document_site_id').value.trim() == ''))) {

					document.getElementById('html_create_from_document_message').innerHTML = __('Site has to be filled');
					return false;
				}

				if ((!document.getElementById('html_create_from_document_journal_id').value) ||
					((document.getElementById('html_create_from_document_journal_id').value) && (document.getElementById('html_create_from_document_journal_id').value.trim() == ''))) {
					document.getElementById('html_create_from_document_message').innerHTML = __('Journal has to be filled');
					return false;
				}


				let from_document_id = me.frm.doc.name
				let to_site_id = document.getElementById('html_create_from_document_site_id').value;
				let to_journal_id = document.getElementById('html_create_from_document_journal_id').value;
				let discard_price = 0;

				if (document.getElementById('html_create_from_document_discard_price').checked) {
					discard_price = 1;
				}

				let remove_original = 0;

				if (document.getElementById('html_create_from_document_remove_original')) {
					if (document.getElementById('html_create_from_document_remove_original').checked) {
						remove_original = 1;
					}

				}

				let method = me.path_sales_document + '.ioi_sales_document_create_from_document';

				frappe.call({  	method: method,
								args: { "tp": tp,
										"from_document_id": from_document_id,
										"to_site_id": to_site_id,
										"to_journal_id": to_journal_id,
										"discard_refresh_price": discard_price,
										"remove_original": remove_original
								},
								async: false,
								callback:function(r)	{

									if (r.message.error == 1) {
										document.getElementById('html_create_from_document_message').innerHTML = r.message.error_msg;
										raise;

									}else{
										p.hide();


										var p2 = new frappe.ui.Dialog({
											'title': __("Message"),
											'static': true,
											'fields': [
												{'fieldname': 'html_create_from_document_success', 'fieldtype': 'HTML'}

											],
											primary_action_label:  __('Close'),
											primary_action: function(){
												p2.hide();

												if (remove_original == 1) {

													let url = '';

													if (me.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') {
														url = '/app/ioi-sales-quote/';
													}else if (me.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
														url = '/app/ioi-sales-order/';
													}

													window.location.href = url;
												}
											}
										});


										html = '<div style="overflow: auto; overflow-x: none; height:60px; width: 100%;">';
										html += '	<div style="position: relative; top: 0px; left: 2px; width:99%;">';
										html += '		<label style="position: absolute; top: 0px; left: 0px;z-index:5;">' + r.message.error_msg + '</label>';
										html += '	</div>';
										html += '</div>';


										p2.fields_dict.html_create_from_document_success.$wrapper.html(html);

										let form_width = 600;

										p2.$wrapper.find('.modal-dialog').css("max-width", form_width.toString() + "px").css("width", form_width.toString() + "px");
										p2.show();

									}
								}
				});


			},
			secondary_action: function(){
				p.hide();
			}

		});

		if (document.getElementById('html_create_from_document_site_label')) {
			document.getElementById('html_create_from_document_site_label').remove();
		}

		if (document.getElementById('html_create_from_document_site_id')) {
			document.getElementById('html_create_from_document_site_id').remove();
		}

		if (document.getElementById('html_create_from_document_journal_label')) {
			document.getElementById('html_create_from_document_journal_label').remove();
		}

		if (document.getElementById('html_create_from_document_journal_id')) {
			document.getElementById('html_create_from_document_journal_id').remove();
		}


		if (document.getElementById('html_create_from_document_discard_price_label')) {
			document.getElementById('html_create_from_document_discard_price_label').remove();
		}

		if (document.getElementById('html_create_from_document_discard_price')) {
			document.getElementById('html_create_from_document_discard_price').remove();
		}


		if (document.getElementById('html_create_from_document_remove_original_label')) {
			document.getElementById('html_create_from_document_remove_original_label').remove();
		}

		if (document.getElementById('html_create_from_document_remove_original')) {
			document.getElementById('html_create_from_document_remove_original').remove();
		}

		if (document.getElementById('html_create_from_document_message')) {
			document.getElementById('html_create_from_document_message').remove();
		}




		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:150px; width: 100%;">';

		// Destination Site
		html += '	<div style="position: relative; top: 0px; left: 2px; width:220px;">';
		html += '		<label id="html_create_from_document_site_label" style="position: absolute; top: 0px; left: 0px;z-index:5;"><b>' + __("Site") + '</b></label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 220px; height: 25px;"> ';

		html += '			<select id="html_create_from_document_site_id" class="input-with-feedback form-control bold"> ';

		let method = this.path_sales_document + '.ioi_sales_document_create_from_document_get_sites'

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{
													for (var i = 0; i < r.message.length; i++)
													{
														html += '		<option value="' + r.message[i].name + '"';
														html += '>' + r.message[i].name + '</option> ';
													}
												}
		});

		html += '			</select> ';

		html += '		</div>';
		html += '	</div>';

		// Destination Journal
		html += '	<div style="position: relative; top: 0px; left: 232px; width:220px;">';
		html += '		<label id="html_create_from_document_journal_label" style="position: absolute; top: 0px; left: 0px;z-index:5;"><b>' + __("Sales journal") + '</b></label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 220px; height: 25px;"> ';
		html += '			<select id="html_create_from_document_journal_id" class="input-with-feedback form-control bold"> ';
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';


		// Discard prices
		html += '	<div style="position: relative; top: 65px; left: 2px; width:250px;">';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 0px; width: 250px; height: 25px;"> ';
		html += '			<input id="html_create_from_document_discard_price" type="checkbox" class="input-with-feedback form-control bold" checked>';
		html += '		</div>';

		html += '		<label id="html_create_from_document_discard_price_label" style="position: absolute; top: 0px; left: 30px;z-index:5;"><b>' + __("Discard prices refresh") + '</b></label>';

		html += '	</div>';


		// Remove original document
		let show_remove = false;

		method = this.path_sales_document + '.ioi_sales_document_create_from_document_can_remove_orginal_document'

		frappe.call({
			method: method,
			args: {"tp": tp, "from_document_id" : this.frm.doc.name},
			async: false,
			callback:function(r) {

				if (r.message.error == 0) {
					if (r.message.can_remove == 1) {
						show_remove = true;
					}
				}
			}
		});

		let top_remark = 90;

		if (show_remove) {
			html += '	<div style="position: relative; top: 85px; left: 2px; width:250px;">';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 0px; width: 250px; height: 25px;"> ';
			html += '			<input id="html_create_from_document_remove_original" type="checkbox" class="input-with-feedback form-control bold" checked>';
			html += '		</div>';

			html += '		<label id="html_create_from_document_remove_original_label" style="position: absolute; top: 0px; left: 30px;z-index:5;"><b>' + __("Remove the original document") + '</b></label>';
			html += '	</div>';

			top_remark += 30;
		}

		html += '	<div style="position: relative; top: ' + top_remark.toString() + 'px; left: 2px; width:99%;">';
		html += '		<label id="html_create_from_document_message" style="position: absolute; top: 0px; left: 0px;z-index:5;color:red"></label>';
		html += '	</div>';

		html += '</div>';



		p.fields_dict.html_create_from_document.$wrapper.html(html);

		let form_width = 500;

		p.$wrapper.find('.modal-dialog').css("max-width", form_width.toString() + "px").css("width", form_width.toString() + "px");
		p.show();



		this.#sleep(200).then(() => {

			me.create_from_document_refresh_journals();

			let fct_change_site = function() {

				me.create_from_document_refresh_journals();
			}

			document.getElementById('html_create_from_document_site_id').onchange = fct_change_site;

		});


	}

	create_from_document_refresh_journals() {

		if (document.getElementById('html_create_from_document_journal_id').options.length > 0) {

			for (var i = document.getElementById('html_create_from_document_journal_id').options.length-1; i >= 0; i--) {
				document.getElementById('html_create_from_document_journal_id').remove(i);
			}
		}

		if ((document.getElementById('html_create_from_document_site_id').value) && (document.getElementById('html_create_from_document_site_id').value.trim() != '')) {

			let method = this.path_sales_document + '.ioi_sales_document_create_from_document_get_journals';

			let tp = 'QUOTE';

			if (this.frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
				tp = 'ORDER'
			}

			frappe.call({
				method: method,
				args: {"tp": tp, "site_id": document.getElementById('html_create_from_document_site_id').value.toUpperCase()},
				async: false,
				callback:function(r)	{

					for (var i = 0; i < r.message.length; i++) {

						var option = document.createElement("option");
						option.value = r.message[i].name;
						option.text = r.message[i].name;
						document.getElementById("html_create_from_document_journal_id").add(option);
					}
				}
			});
		}
	}
}

silicon_ioi.doctype.ioiSalesDocumentDocType = ioiSalesDocumentDocType;
