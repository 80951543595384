import QueryProfile from  "./QueryProfileBrowser.vue";
import { createApp, h } from 'vue';

frappe.provide("frappe.views");

frappe.views.ExpertView = class ioiExpertView extends frappe.views.Factory {
	constructor(opts) {
		super(opts);

		let me = this
		this.doctype = opts.doctype;
		this.page_name = frappe.get_route_str();
		this.current_select = {}
		this.fieldnames = {}
		this.query_fields = []
		this.saved_query_profile = ""
		this.current_site = ""
		this.global_filtering = []

		this.page = frappe.container.page.page
		this.meta = frappe.get_meta(this.doctype)

		frappe.breadcrumbs.add(
			locals.DocType[me.doctype].module,
			me.doctype
		);

		// Fix awesome bar
		frappe.container.page.list_view = this

		this.show()
	}

	get view_name() {
		return "ioi List Expert";
	}

	show() {
		this.render_page()
	}

	async render_page() {
		await this.check_user_site()

		silicon_ioi.ioiCommon.add_dropdown_prefix('EL', this.doctype);

		this.set_logo()
		this.page.set_title(__(this.doctype));

		if (this.is_multi_site)	await this.add_site_button()

		this.setup_view_menu()
		this.set_primary_action()
		this.set_menu_items()

		this.load_status();

		this.page.main.addClass("frappe-card");

		if (document.getElementById('ioi_expert_view')) {
			document.getElementById('ioi_expert_view').remove()
		}

		$(`<div id="ioi_expert_view" class="ioi_expert_view table table-responsive m-0 rounded" data-custom-grid="true"></div>`).appendTo(this.page.main);

		await this.get_profile_list()
		await this.create_query()
		this.update_query_user_settings(this.saved_query_profile)

		this.render_ioi_expert_grid()
		this.render_profile_selector()
		this.render_global_filter()
		this.render_saved_filters_menu()

		let me = this
		$(document).ready(function () { window.setTimeout(() => me.after_render(), 100) });
	}

	after_render() {
		this.init_list_side_view()
	}

	set_logo() {

		if (this.page.wrapper.find(".title-image")[0]) {
			this.page.wrapper.find(".title-image")[0].remove()
		}

		let html_before = this.page.wrapper.find(".title-area")[0]
		let html = `<div class="title-image mr-2"><img src='/assets/silicon_ioi/images/modules/${this.doctype.replace(/ /g, '_').toLowerCase()}.svg' width="56px" height="56px" style="horizontal-align:left;"></img></div>`

		html_before.insertAdjacentHTML("afterbegin", html)
	}

	async get_profile_list() {
		this.query_profile_list = await frappe.db.get_list('ioi Query Profile', {
			fields: [ 'name', 'is_public', 'is_standard' ],
			filters: { 'doc_type': this.doctype },
			or_filters: [ ['is_public', '=', 1], ['user_name', '=', frappe.session.user] ],
			limit: null
		})
	}

	async render_profile_selector() {

		const side_section = this.page.wrapper.find(".layout-side-section")[0]
		if (side_section.querySelector('.select-profile-section')) side_section.querySelector('.select-profile-section').remove()

		const container_html = `
			<div class="sidebar-section select-profile-section">
				<div class="list-group-by">
					<div id="ev_profile_select_container" class="list-group-by-fields d-flex align-items-center flex-row-reverse">
						<a id="ev_select_profile_settings" class="mx-2 mt-4">
							<svg class="icon" style="filter: opacity(0.5)">
								<use class="" href="#icon-setting-gear"></use>
							</svg>
						</a>
					</div>
					<div class="d-flex flex-wrap justify-content-start align-items-center">
						<li id="ev_save_column_button" class="add-list-group-by sidebar-action list-unstyled mt-2 mr-2">
							${this.saved_query_profile === "Default" ? '' : `
							<button class="btn btn-outline-secondary btn-xs">
								${__('Save Column Display')}
							</button>`}
						</li>
						<li id="ev_reset_column_button" class="add-list-group-by sidebar-action list-unstyled mt-2">
							${this.saved_query_profile === "Default" ? '' : `
							<button class="btn btn-outline-secondary btn-xs">
								${__('Reset Column Display')}
							</button>`}
						</li>
					</div>
				</div>
			</div>`

		side_section.insertAdjacentHTML("afterbegin", container_html)

		const options = this.query_profile_list.map(el => el.name)
		options.unshift('Default')

		const profile_switcher = frappe.ui.form.make_control({
			parent: this.page.wrapper.find("#ev_profile_select_container")[0],
			df: {
				label: __('Select Profile'),
				fieldname: 'switch_profile',
				fieldtype: 'Select',
				options: options,
			},
			render_input: true
		})

		profile_switcher.$wrapper.find('.form-group').css('margin-bottom', '0px')
		profile_switcher.$wrapper.addClass("w-100 mr-1")
		profile_switcher.$input.addClass("pr-5")
		profile_switcher.$input.val(this.saved_query_profile || 'Default')

		profile_switcher.$input.change(async (e) => {
			toggle_columns_button(e.target.value)
			this.saved_query_profile = e.target.value

			if (this.ioi_expert_view_table) this.ioi_expert_view_table.destroy()

			await this.get_query_fields()
			this.update_query_user_settings(e.target.value)
			this.render_ioi_expert_grid()
			this.render_global_filter()
			this.render_saved_filters_menu()
		})

		const settings = this.page.wrapper.find("#ev_select_profile_settings")[0]
		settings.onclick = () => this.show_query_profile_settings()

		const toggle_columns_button = (value) => {
			const save_button = this.page.wrapper.find("#ev_save_column_button")[0];

			if (value === "Default") {
				if (save_button.children.length) save_button.firstElementChild.remove();
			} else {
				if (save_button.children.length) save_button.firstElementChild.remove();
				save_button.insertAdjacentHTML('afterbegin', `<button class="btn btn-outline-secondary btn-xs">${__('Save Column Display')}</button>`);
				save_button.querySelector('button').onclick = () => this.save_table_display(frappe.model.user_settings[this.doctype]["ioi Expert Profile"], value)
			}

			const reset_button = this.page.wrapper.find("#ev_reset_column_button")[0];

			if (value === "Default") {
				if (reset_button.children.length) reset_button.firstElementChild.remove();
			} else {
				if (reset_button.children.length) reset_button.firstElementChild.remove();
				reset_button.insertAdjacentHTML('afterbegin', `<button class="btn btn-outline-secondary btn-xs">${__('Reset Column Display')}</button>`);
				reset_button.querySelector('button').onclick = () => this.reset_table_display(value)
			}
		}

		toggle_columns_button(this.saved_query_profile)
	}

	render_global_filter() {

		const side_section = this.page.wrapper.find(".layout-side-section")[0].querySelector('.select-profile-section')
		if (side_section.querySelector('.global-filter-section')) side_section.querySelector('.global-filter-section').remove()

		const container_html = `
			<div class="sidebar-section global-filter-section mt-4">
				<li class="sidebar-label">${__('Global Filter')}</li>
					<li class="input-area list-unstyled mt-3">
						<div class="frappe-control input-max-width" data-fieldtype="Data">
							<div class="form-group">
								<div class="control-input-wrapper">
									<div class="control-input">
										<input id="expert_view_global_filters_input" style="background-color:var(--control-bg-on-gray);" type="text" autocomplete="off" class="input-with-feedback form-control input-xs" maxlength="140" data-fieldtype="Data" placeholder="${__('Filter All Columns')}">
									</div>
									<div class="control-value like-disabled-input" style="display: none;"></div>
									<p class="help-box small text-muted"></p>
								</div>
							</div>
						</div>
					</li>
				</li>
			</div>`

		side_section.insertAdjacentHTML("beforeend", container_html)

		const input = this.page.wrapper.find("#expert_view_global_filters_input")[0]
		let timeoutId;

		input.oninput = (e) => {
			clearTimeout(timeoutId);

			timeoutId = setTimeout(() => {

				// Reset all exisiting filters
				if (this.filter_selected) {
					this.page.wrapper[0].querySelector(`#expert_view_saved_filters_container .list-link[data-filter-name='${this.filter_selected}']`).classList.replace('btn-primary-light', 'btn-default')
					this.filter_selected = undefined
				}

				// Set global filters
				let value = e.target.value;

				const global_filter = this.ioi_expert_view_table.getColumns().map(col => {
					const field = col.getField()

					if (field !== "responsiveCollapse" && !!value) {
						return { field: field, type: "like", value: value }
					}
				}).filter(el => !!el)

				this.global_filtering = global_filter.reduce((acc, filter) => {
					if (filter.value.includes('|')) {
						const sub_filters = filter.value.split('|').map(value => ({
							"field": filter.field,
							"type": filter.type,
							"value": value.trim(),
						}));

						acc.push(...sub_filters);
					} else {

						acc.push(filter);
					}

					return acc;
				}, []);

				this.ioi_expert_view_table.setFilter(global_filter)

			}, 300);
		}
	}

	async render_saved_filters_menu(new_added_profile=undefined) {
		const side_section = this.page.wrapper.find(".layout-side-section")[0].querySelector('.select-profile-section')
		if (side_section.querySelector('.save-filter-section')) side_section.querySelector('.save-filter-section').remove()

		this.filter_selected = new_added_profile ? new_added_profile : undefined
		const profile = this.saved_query_profile

		const container_html = `
			<div class="sidebar-section save-filter-section mt-4">
				<li class="sidebar-label">${__('Save Filter')}</li>
					<li class="input-area list-unstyled mt-3">
						<div class="frappe-control input-max-width" data-fieldtype="Data">
							<div class="form-group">
								<div class="control-input-wrapper">
									<div class="control-input">
										<input id="expert_view_saved_filters_input" style="background-color:var(--control-bg-on-gray);" type="text" autocomplete="off" class="input-with-feedback form-control input-xs" maxlength="140" data-fieldtype="Data" placeholder="${__('Filter Name')}">
									</div>
									<div class="control-value like-disabled-input" style="display: none;"></div>
									<p class="help-box small text-muted"></p>
								</div>
							</div>
						</div>
						<div id="expert_view_checkboxes" class="form-group frappe-control input-max-width hide-control" data-fieldtype="Check">
							<div class="checkbox">
								<label>
									<span class="input-area">
										<input type="checkbox" class="input-with-feedback" data-fieldtype="Check" checked>
									</span>
									<span class="label-area">${__('Is Public')}</span>
								</label>
							</div>
						</div>
					</li>
					<div id="expert_view_saved_filters_container" class="saved-filters" style=""></div>
				</li>
			</div>`

		side_section.insertAdjacentHTML("beforeend", container_html)

		const input = this.page.wrapper.find("#expert_view_saved_filters_input")[0]
		const label = input.closest(".control-input-wrapper").querySelector("p")
		const checkboxes = this.page.wrapper.find('#expert_view_checkboxes')[0]
		const saved_filters = this.page.wrapper.find('#expert_view_saved_filters_container')[0]

		const profile_saved_filters = await frappe.call('silicon_ioi.utils.lib.system.get_saved_filters', { parent: this.saved_query_profile }).then(r => {
			let user_filters = []

			if (r.message.length) {
				user_filters = r.message.filter(filter => filter.is_public === 1 || (filter.is_public === 0 && filter.owner === frappe.session.user));
			}

			return user_filters
		})
		if (profile_saved_filters.length) profile_saved_filters.forEach(filter => this.add_new_filters(saved_filters, filter.filter_name, profile, filter.filters))

		let timeoutId;
		let hide_show_checkboxes = (opts) => checkboxes.classList.replace(`${opts === "hide" ? "show" : "hide"}-control`, `${opts === "show" ? "show" : "hide"}-control`)

		input.oninput = (e) => {
			clearTimeout(timeoutId);

			timeoutId = setTimeout(() => {
				let value = e.target.value;

				if (!value || value === "") {
					label.textContent = '';
					hide_show_checkboxes("hide")
				} else if (this.saved_query_profile === "Default") {
					label.textContent = __('Filters cannot be saved on a default profile')
					hide_show_checkboxes("hide")
				} else if (saved_filters.children.length) {
					if ([...saved_filters.querySelectorAll('.list-link .filter-name')].find(filter => value == filter.textContent)) {
						label.textContent = __('Duplicate Filter Name')
						hide_show_checkboxes("hide")
					} else {
						label.textContent = __('Press Enter to save');
						hide_show_checkboxes("show")
					}
				} else {
					label.textContent = __('Press Enter to save');
					hide_show_checkboxes("show")
				}
			}, 300);
		}

		input.onkeydown = (e) => {
			if (e.key === "Enter") {
				setTimeout(async () => {
					if (label.textContent === __('Press Enter to save')) {
						const value = input.value
						const is_public = checkboxes.querySelector('input').checked ? 1 : 0

						if (profile && profile !== "Default") await frappe.call('silicon_ioi.utils.lib.system.update_saved_filters', { profile: profile, filter_name: value, is_public: is_public, filters: this.ioi_expert_view_table.getFilters(true), action: "add" })
						return this.render_saved_filters_menu(value)
					}
				}, 250)
			}
		}
	}

	add_new_filters(saved_filters, value, profile, filters) {
		const filter_element_html = `
			<div class="list-link filter-pill list-sidebar-button btn btn-default w-100 mt-3 d-flex justify-content-between align-items-center" style="height: 27.5px; padding: 0 1rem;" data-filter-name="${value}">
				<a class="ellipsis filter-name text-left w-100 h-100 pt-1">${value}</a>
				<a class="remove">
					<svg class="icon  icon-sm" style="">
						<use class="" href="#icon-close"></use>
					</svg>
				</a>
			</div>`

		saved_filters.insertAdjacentHTML("afterbegin", filter_element_html)

		const filter_el = saved_filters.querySelector(`.list-link[data-filter-name='${value}']`)
		const del_arrow = saved_filters.querySelector(`[data-filter-name='${value}'] .remove`)

		if (value === this.filter_selected) filter_el.classList.replace('btn-default', 'btn-primary-light')

		filter_el.firstElementChild.onclick = (e) => {
			this.ioi_expert_view_table.clearFilter(true)

			if (this.filter_selected === value) {
				e.target.parentElement.classList.replace('btn-primary-light', 'btn-default')
				this.filter_selected = undefined
			} else {
				if (this.filter_selected) saved_filters.querySelector(`.list-link[data-filter-name='${this.filter_selected}']`).classList.replace('btn-primary-light', 'btn-default')
				e.target.parentElement.classList.replace('btn-default', 'btn-primary-light')

				this.filter_selected = value
				const format_filters = JSON.parse(filters)

				if (format_filters.length) {
					Array.from(document.querySelectorAll('.tabulator-header-filter input')).forEach(col => col.value = "")

					this.ioi_expert_view_table.getColumns().forEach(col => {
						let field = format_filters.find(filter => filter.field === col.getField())

						if (col.getElement().querySelector('input') && field) {
							col.getElement().querySelector('input').value = field.value
						}
					})
					setTimeout(() => this.ioi_expert_view_table.setFilter(format_filters), 50)
				}
			}
		}

		del_arrow.onclick = () => frappe.confirm(__(`Are you sure you want to remove the <strong>{0}</strong> filter?`,[value]),
			() => {
				if (this.filter_selected === value) {
					this.filter_selected = undefined
					this.ioi_expert_view_table.clearFilter(true)
				}

				if (profile && profile !== "Default") {
					frappe.call('silicon_ioi.utils.lib.system.update_saved_filters', { profile: profile, filter_name: value, action: "remove" }).then(() => filter_el.remove())
				} else {
					filter_el.remove()
				}
			}, () => {})
	}

	async check_user_site() {
		let me = this;

		await frappe.call({
			method: 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user.ioi_user_has_site',
			args: {
				check_if_multi_site: true
			},
			async: false,
			callback: function (r) {
				me.current_site = r.message.site_id;
				me.is_multi_site = r.message.multi_site
			}
		})
	}

	get_search_params() {
		let search_params = new URLSearchParams();

		this.get_filters_for_args().forEach((filter) => {
			if (filter[2] === "=") {
				search_params.append(filter[1], filter[3]);
			} else {
				search_params.append(filter[1], JSON.stringify([filter[2], filter[3]]));
			}
		});
		return search_params;
	}

	get_filters_for_args() {
		// filters might have a fifth param called hidden,
		// we don't want to pass that server side
		return this.filter_area ? this.filter_area.get().map((filter) => filter.slice(0, 4)) : [];
	}

	setup_view_menu() {

		if (this.page.wrapper.find(".custom-btn-group")[0]) {
			this.page.wrapper.find(".custom-btn-group")[0].remove()
		}

		this.list_view = this
		this.list_view.settings = {}

		if (frappe.boot.desk_settings.view_switcher && !this.meta.force_re_route_to_default_view) {
			const icon_map = {
				Image: "image-view",
				List: "list",
				Report: "small-file",
				Calendar: "calendar",
				Gantt: "gantt",
				Kanban: "kanban",
				Dashboard: "dashboard",
				Map: "map",
				'ioi List Expert': "list",
				'ioi Pivot Expert': "list"
			};

			const label_map = {
				List: __("List View"),
				Report: __("Report View"),
				Dashboard: __("Dashboard View"),
				Gantt: __("Gantt View"),
				Kanban: __("Kanban View"),
				Calendar: __("Calendar View"),
				Image: __("Image View"),
				Inbox: __("Inbox View"),
				Tree: __("Tree View"),
				Map: __("Map View"),
				'ioi List Expert': __('ioi List Expert'),
				'ioi Pivot Expert': __('ioi Pivot Expert')
			};

			this.views_menu = this.page.add_custom_button_group(
					label_map[this.view_name] || label_map["List"],
					icon_map[this.view_name] || "list"
			);
			this.views_list = new frappe.views.ListViewSelect({
				doctype: this.doctype,
				parent: this.views_menu,
				page: this.page,
				list_view: this,
				sidebar: this.list_sidebar,
				icon_map: icon_map,
				label_map: label_map,
			});
		}

		if (!frappe.listview_settings[this.doctype] || (frappe.listview_settings[this.doctype] && !frappe.listview_settings[this.doctype].kanban)) {
			this.views_menu.find('[data-view="Kanban"]').hide();
		}

		this.add_refresh_grid_button()
	}

	set_menu_items() {
		this.menu_items = [
			{ label: __('Export Current List'), action: () => this.data_export(), standard: true },
			{ label: __('List Settings'), action: () => this.show_query_profile_settings(), standard: true },
		]

		this.menu_items.map((item) => {
			if (item.condition && item.condition() === false) {
				return;
			}
			const $item = this.page.add_menu_item(
				item.label,
				item.action,
				item.standard,
				item.shortcut
			);
			if (item.class) {
				$item && $item.addClass(item.class);
			}
		});
	}

	data_export () {
		let me = this

		let download = (data=null) => {
			let filename = document.getElementById('export_filename').value;
			let filetype = document.getElementById('export_file_type').value;

			if (filename.trim() == '') {
				filename = this.doctype;
			}

			if (data != null) {
				me.ioi_expert_view_table.replaceData(data)
			}

			if (filetype.toUpperCase() == 'XLSX') {
				me.ioi_expert_view_table.download("xlsx", `${filename}.xlsx`);
			} else {
				me.ioi_expert_view_table.download("csv", `${filename}.csv`, { delimiter: ";" })
			}
		}

		let export_tabulator = () => {
			let config = { method: 'get' }
			let params = {}
			let export_limit = undefined

			if (document.getElementById('export_file_limit').value != this.ioi_expert_view_table.getData().length) {
				export_limit = document.getElementById('export_file_limit').value;
			}

			if (!export_limit) {
				download()
			} else {

				const export_with_limit = () => {
					let url = this.generate_url('/api/method/silicon_ioi.utils.lib.system.get_custom_query_list', config, params, export_limit)
					let current_data = this.ioi_expert_view_table.getData()

					$.ajax({
						url: url,
					}).done(function (r) {
						download(r.message.data)
						me.ioi_expert_view_table.replaceData(current_data)
					})
				}

				if (export_limit == "all") {
					frappe.confirm(__('Accessing all records may cause a temporary delay'),
						() => {
							export_with_limit()
						}, () => {
							// action to perform if No is selected
						})
				} else {
					export_with_limit()
				}
			}
		}

		silicon_ioi.ioiCommon.export_form(export_tabulator, true, true);
	}

	add_refresh_grid_button() {

		if (document.getElementById('expert_view_refresh_button')) {
			document.getElementById('expert_view_refresh_button').remove()
		}

		let html = `
			<button id="expert_view_refresh_button" class="text-muted btn btn-default icon-btn" data-original-title="Refresh">
				<svg class="es-icon es-line  icon-sm" style="" aria-hidden="true">
					<use class="" href="#es-line-reload"></use>
				</svg>
			</button>`

		let action = () => {
			this.render_global_filter()
			this.render_saved_filters_menu()

			if (this.ioi_expert_view_table) {
				this.ioi_expert_view_table.destroy()
			}

			this.render_ioi_expert_grid()
			frappe.show_alert({ message: __('Table display has been redrawn'), indicator:'green' }, 5)
		}
		this.add_custom_action(html, action)
	}

	async add_site_button() {
		await frappe.db.get_value('ioi Module', this.doctype, 'select_site').then(r => {
			if (r.message.select_site == 1) {

				if (document.getElementById('expert_view_site_button')) {
					document.getElementById('expert_view_site_button').remove()
				}

				let html = `<button id="expert_view_site_button" class="btn btn-default btn-sm ellipsis">${__('Site')} : ${this.current_site}</button>`

				let action = () => this.show_select_site(true)
				this.add_custom_action(html, action)
			} else {
				this.current_site = ''
			}
		})
	}

	show_select_site(reload = false) {
		if (!document.getElementById('ioi_site_list')) {

			let html = '';

			let me = this;
			let method = 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user.ioi_user_get_site'

			frappe.call({
				method: method,
				args: {},
				async: false,
				callback: function (r) {
					me.current_site = r.message;
				}
			});

			let division_tab = []
			method = 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user.ioi_user_get_division_with_sites'

			frappe.call({
				method: method,
				args: {},
				async: false,
				callback: function (r) {
					division_tab = r.message;
				}
			});

			let current_division = ''

			if ((me.current_site) && (me.current_site.trim() != '')) {

				method = 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user.ioi_user_get_division_for_site'

				frappe.call({
					method: method,
					args: { "site_id": me.current_site },
					async: false,
					callback: function (r) {
						current_division = r.message;
					}
				});

			} else {
				if (division_tab.length != 0) {
					current_division = division_tab[0].name;
				}
			}

			if (document.getElementById('ioi_site_list_label')) {
				document.getElementById('ioi_site_list_label').remove();
			}
			if (document.getElementById('ioi_site_list')) {
				document.getElementById('ioi_site_list').remove();
			}

			html += '<div style="position: relative; top: -20px; left: 0px; width:300px; height: 25px;">';
			html += '	<label id="ioi_site_list_label" style="position: absolute; top: 0px; left: 2px;">' + __("Select a site") + '</label>';
			html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width:300px; height: 25px;"> ';
			html += '		<select id="ioi_site_list" class="input-with-feedback form-control bold"> ';

			if (current_division != '') {

				method = 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user.ioi_user_get_sites_for_division'

				frappe.call({
					method: method,
					args: { "division_id": current_division },
					async: false,
					callback: function (r) {

						for (var i = 0; i < r.message.length; i++) {
							if (me.current_site == r.message[i].name) {
								html += '		<option value="' + r.message[i].name + '" selected>' + r.message[i].description + '</option> ';
							} else {
								html += '		<option value="' + r.message[i].name + '">' + r.message[i].description + '</option> ';
							}
						}
					}
				});
			}

			html += '		</select> ';
			html += '	</div>';
			html += '</div>';

			if (division_tab.length > 1) {

				html += '<div style="position: relative; top: -45px; left: 310px; width:200px; height: 32px;">';
				html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width:200px; height: 32px;"> ';
				html += '		<input id="ioi_select_division" type="button" class="btn btn-default ellipsis" value="' + __("Change division") + '" style="width:100%; height:100%">';
				html += '	</div>';
				html += '</div>';

				if (document.getElementById('ioi_select_division')) {
					document.getElementById('ioi_select_division').remove();
				}
			}

			frappe.confirm(html,
				() => {
					// action to perform if Yes is selected
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length - 1].disabled = true;

					if ((!document.getElementById('ioi_site_list')) || (document.getElementById('ioi_site_list').value.trim() == '')) {
						document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length - 1].disabled = false;
						frappe.msgprint({ title: __("Message"), message: __('Site is mandatory'), indicator: "red" });
						raise;
					}

					me.current_site = document.getElementById('ioi_site_list').value;

					let method_user = 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user.ioi_user_save_site'
					frappe.call({
						method: method_user,
						args: { "site_id": document.getElementById('ioi_site_list').value },
						async: false,
						callback: function (r) {
							if (reload) {
								window.location.reload();
							}
						}
					});
				},
				() => {
					// action to perform if No is selected or if click outsite the modal screen
					if (reload) {
						window.location.reload();
					}
				}
			);

			this.sleep_ms(300).then(() => {

				let fct_click = function () {

					var changeDivisionDialog = new frappe.ui.Dialog({
						title: __("Change division"),
						static: true,
						fields: [{ 'fieldname': 'html_change_division', 'fieldtype': 'HTML' }
						],
						primary_action_label: __("Ok"),
						primary_action: function () {

							let value = document.getElementById('html_change_division_id').value;

							changeDivisionDialog.hide();

							if (value != current_division) {

								let method = 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user.ioi_user_get_sites_for_division';

								frappe.call({
									method: method,
									args: { "division_id": value },
									async: false,
									callback: function (r) {

										while (document.getElementById('ioi_site_list').options.length > 0) {
											document.getElementById('ioi_site_list').remove(0);
										}

										for (var i = 0; i < r.message.length; i++) {

											var option = document.createElement("option");
											option.value = r.message[i].name;
											option.text = r.message[i].description;
											document.getElementById('ioi_site_list').add(option);
										}
									}
								});

								current_division = value;
							}
						},
						secondary_action_label: __("Cancel"),
						secondary_action: function () {

							changeDivisionDialog.hide();
						}
					});

					if (document.getElementById('html_change_division_label')) {
						document.getElementById('html_change_division_label').remove();
					}

					if (document.getElementById('html_change_division_id')) {
						document.getElementById('html_change_division_id').remove();
					}

					let html = ""
					html += '<div style="height: 70px;">';
					html += '	<div style="position: relative; top: 0px; left: 0px; width:300px; height: 25px;">';
					html += '		<label id="html_change_division_label" style="position: absolute; top: 0px; left: 2px;">' + __("Select a division") + '</label>';
					html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width:300px; height: 25px;"> ';
					html += '			<select id="html_change_division_id" class="input-with-feedback form-control bold"> ';

					for (var i = 0; i < division_tab.length; i++) {
						if (current_division == division_tab[i].name) {
							html += '		<option value="' + division_tab[i].name + '" selected>' + division_tab[i].description + '</option> ';
						} else {
							html += '		<option value="' + division_tab[i].name + '">' + division_tab[i].description + '</option> ';
						}
					}

					html += '			</select> ';
					html += '		</div>';
					html += '	</div>';
					html += '</div>';

					changeDivisionDialog.fields_dict.html_change_division.$wrapper.html(html);
					changeDivisionDialog.$wrapper.find('.modal-dialog').css("max-width", "340px").css("width", "340px");
					changeDivisionDialog.show();
				}

				document.getElementById('ioi_select_division').onclick = fct_click;

			});

			this.sleep_ms(200).then(() => {

				if (division_tab.length <= 1) {
					document.getElementsByClassName('modal-content')[document.getElementsByClassName('modal-content').length - 1].style.width = '340px';
				} else {
					document.getElementsByClassName('modal-content')[document.getElementsByClassName('modal-content').length - 1].style.width = '555px';
				}
			});
		}
	}

	sleep_ms(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}


	add_custom_action(html, action) {
		this.page.wrapper.find(".custom-actions")[0].classList.replace('hide', 'show')

		let action_button =  $(html);
		this.page.custom_actions.append(action_button);

		action && action_button.click(action);
	}

	set_primary_action() {
		const label = `${__("Add", null, "Primary action in list view")} ${__(this.doctype)}`
		this.page.set_primary_action(
			label,
			() => {
				frappe.new_doc(this.doctype)
			},
			"add"
		)
	}

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	/////////////////////////////////////////////////////////////////////                  Profile Query Settings                   /////////////////////////////////////////////////////////////////////
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	setup_query_fields() {

		let fields_html = this.query_profile_settings.get_field("selected_fields");
		fields_html.html(``);

		let fields = ``;

		const arrow_down = `<button class="btn btn-outline-secondary btn-xs insert_query_field" style="font-size: var(--text-xs);" title="${__('Insert below')}">
								<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-arrow-down" viewBox="0 0 16 16">
									<path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1"/>
								</svg>
							</button>`

		const edit_icon = ` <button class="btn btn-outline-secondary btn-xs edit_query_field" style="font-size: var(--text-xs);" title="${__('Edit label')}">
								<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
									<path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
								</svg>
							</button>`

		const del_icon = `  <button class="btn btn-outline-secondary btn-xs remove_query_field" style="font-size: var(--text-xs);" title="${__('Remove field')}">
								<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
									<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
									<path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
								</svg>
							</button>`

		const curr_profile = this.query_profile_list.find(el => el.name == this.saved_query_profile)
		const is_standard = curr_profile?.name !== "Default" && curr_profile?.is_standard === 1;

		for (let idx in this.query_fields) {
			fields += `
				<div class="control-input form-control ${this.query_fields[idx].field === "_assign" ? 'hide' : ''}" style="margin-bottom: 5px; padding-top: 4px; font-size: var(--text-md);" data-fieldname="${this.query_fields[idx].field}" data-idx="${idx}">
					<div class="row d-flex justify-content-between align-items-center mx-1">
						<div class="text-truncate">
							${this.query_fields[idx].path}
						</div>
						<div>
							<a class="text-muted insert-field" data-fieldname="${this.query_fields[idx].path}">
								${	this.saved_query_profile != "Default" &&
									!!this.saved_query_profile &&
									!is_standard ?
									arrow_down : ""
								}
							</a>
							<a class="text-muted edit-field" data-fieldname="${this.query_fields[idx].path}">
								${	this.saved_query_profile != "Default" &&
									!!this.saved_query_profile &&
									!is_standard ?
									edit_icon : ""
								}
							</a>
							<a class="text-muted remove-field" data-fieldname="${this.query_fields[idx].path}">
								${	this.saved_query_profile != "Default" &&
									!!this.saved_query_profile &&
									this.query_fields[idx].path != 'name' &&
									!is_standard ?
									del_icon : ""
								}
							</a>
						</div>
					</div>
				</div>`;
		}

		fields_html.html(`
			<div class="form-group">
				<div class="clearfix">
					<label class="control-label mb-3" style="padding-right: 0px;">${__('Selected fields')}</label>
				</div>
				<div class="control-input-wrapper" style="max-height: 580px; overflow: auto;">
					${fields.length ? fields : `
						<span class="d-flex justify-content-center" style="font-size: var(--text-sm);">${__('No field selected')}</span>
					`}
				</div>
				<div class="help-box small text-muted mt-3">${__('The order of the columns can be adjusted by dragging them within the table. Remember to save your changes to keep these modifications.')}</div>
			</div>
		`);

		fields_html.$wrapper.off("click", ".insert_query_field");
		fields_html.$wrapper.off("click", ".edit_query_field");

		fields_html.$wrapper.on("click", ".insert_query_field", (e) => {
			const $idx = $(e.currentTarget).closest('.control-input').data('idx');
			this.add_field(this.query_profile_settings.fields_dict, Number($idx) + 1);
		});

		fields_html.$wrapper.on("click", ".edit_query_field", (e) => {
			const $fieldname = $(e.currentTarget).closest('.control-input').data('fieldname');
			this.edit_query_field($fieldname);
		});

		this.setup_remove_fields()
	}

	edit_query_field(fieldname) {
		let me  = this

		let d = new frappe.ui.Dialog({
			title: __('Update label'),
			fields: [
				{
					label: __('New label'),
					fieldname: 'new_label',
					fieldtype: 'Data',
					description: __('Current label is "{0}"', [__(me.query_fields.find(el => el.field == fieldname).label)])
				},
			],
			size: 'small',
			primary_action_label: __('Update'),
			primary_action(values) {

				me.query_fields.find(el => el.field == fieldname).label = values.new_label
				frappe.call('silicon_ioi.utils.lib.system.edit_query_field_label', { name: me.query_profile_settings.fields_dict.profile.value, fields: me.query_fields }).then(() => d.hide())

			},
			secondary_action_label: __('Cancel'),
			secondary_action() {
				d.hide();
			}
		});

		d.show();
	}

	async on_query_profile_change(changeValue = null) {
		const dialog = this.query_profile_settings
		const fields_dict = dialog.fields_dict

		const value = changeValue ?? fields_dict.profile.value;
		this.saved_query_profile = value;

		const is_default = value === 'Default';

		const toggle_visibility = (fields, hidden) => {
			fields.forEach(field => {
				fields_dict[field].wrapper.hidden = hidden;
			});
		};

		const custom_actions = dialog.custom_actions[0]

		custom_actions.children[0].classList.toggle('hide', is_default);
		custom_actions.children[1].classList.toggle('hide', is_default);
		fields_dict.add_button.input_area.hidden = is_default;

		toggle_visibility(["is_public", "is_standard", "show_assigned_users"], is_default);

		const curr_profile = this.query_profile_list.find(el => el.name === value);
		this.toggle_dialog_readonly(fields_dict, custom_actions, curr_profile)

		await this.get_query_fields();
		this.setup_query_fields();
	}

	toggle_dialog_readonly(fields_dict, custom_actions, curr_profile) {
		const is_readonly = curr_profile == undefined || curr_profile?.is_standard === 1;

		// Toggle test query/remove button visibility
		custom_actions.children[0].classList.toggle('hide', is_readonly);
		custom_actions.children[1].classList.toggle('hide', is_readonly);

		fields_dict.is_public.$wrapper.find('input').prop('disabled', is_readonly);
		fields_dict.show_assigned_users.$wrapper.find('input').prop('disabled', is_readonly);
		fields_dict.add_button.$wrapper.find('button').prop('hidden', is_readonly);

		const warning_message = is_readonly ? __('This profile cannot be modified') : '';
		fields_dict.profile.$wrapper.find('#warning_default_message').text(warning_message);

		// Set main content to read only
		const pointer_events = is_readonly ? "none" : "";
		const opacity = is_readonly ? "0.65" : "1";

		fields_dict.structure_html.$wrapper.css({"pointer-events": pointer_events, "opacity": opacity});
		fields_dict.selected_fields.$wrapper.css({"pointer-events": pointer_events, "opacity": opacity});
		fields_dict.show_assigned_users.$wrapper.css({"pointer-events": pointer_events, "opacity": opacity});
	}

	async show_query_profile_settings() {
		let me = this
		this.is_fields_changed = false

		await this.get_profile_list()

		const dialog_fields = [
			{
				'label': __('Select a profile'),
				'fieldname': 'profile',
				'fieldtype': 'Select',
				'default': me.saved_query_profile,
				'description':`<p id="warning_default_message" class="text-danger">${ me.saved_query_profile == "Default" || me.saved_query_profile == "" ? __('This profile cannot be modified'): ""}</p>`
			},
			{
				'label': __('New profile'),
				'fieldname': 'new_button',
				'fieldtype': 'Button',
				'btn_size': 'xs',
			},
			{
				'label': __('Is public'),
				'fieldname': 'is_public',
				'fieldtype': 'Check',
			},
			{
				'label': __('Is standard'),
				'fieldname': 'is_standard',
				'fieldtype': 'Check',
				'read_only': true,
			},
			{
				"fieldname": "section",
				"fieldtype": "Section Break",
			},
			{
				'fieldtype': 'HTML',
				'fieldname': 'structure_html'
			},
			{
				'label': __('Add field'),
				'fieldname': 'add_button',
				'fieldtype': 'Button',
				'btn_size': 'xs',
			},
			{
				"fieldname": "section",
				"fieldtype": "Column Break",
			},
			{
				"fieldname": "selected_fields",
				"fieldtype": "HTML",
			},
			{
				'label': __('Show assigned users'),
				'fieldname': 'show_assigned_users',
				'fieldtype': 'Check',
				'default': this.query_fields.some(el => el.field == "_assign"),
			},
		]

		this.query_profile_settings = new frappe.ui.Dialog({
			size: 'extra-large',
			title: __('Query Profile Settings'),
			fields: dialog_fields,
			primary_action_label: __('Save'),
			primary_action(values) {

				const curr_profile = me.query_profile_list.find(el => el.name === values.profile);
				const is_standard = curr_profile?.name !== "Default" && curr_profile?.is_standard === 1;

				if (is_standard != 1) {
					if (me.is_fields_changed && me.saved_query_profile != "Default") {
						frappe.db.set_value('ioi Query Profile', me.query_profile_settings.fields_dict.profile.value, 'display', '')
					} else {
						me.save_table_display(frappe.model.user_settings[me.doctype]["ioi Expert Profile"], me.query_profile_settings.fields_dict.profile.value)
					}
				}

				me.update_query_user_settings(me.query_profile_settings.fields_dict.profile.value)

				me.query_profile_settings.hide();

				if (me.ioi_expert_view_table) {
					me.ioi_expert_view_table.destroy()
				}

				me.render_ioi_expert_grid()
				me.render_profile_selector()
				me.render_global_filter()
				me.render_saved_filters_menu()
			},
			on_page_show: () => {
				me.format_profile_list(fields_dict)
			}
		})

		let fields_dict = this.query_profile_settings.fields_dict

		fields_dict.profile.$input[0].onchange = (e) => this.on_query_profile_change(e.target.value)
		fields_dict.add_button.$input[0].onclick = () => this.add_field(fields_dict)
		fields_dict.is_public.$input[0].onclick = () => frappe.db.set_value('ioi Query Profile', fields_dict.profile.value, 'is_public', fields_dict.is_public.value == 1 ? 0 : 1)
		fields_dict.new_button.$input[0].onclick = () => me.create_new_query_profile()
		fields_dict.show_assigned_users.$input[0].onclick = (e) => me.show_assigned_users(e, fields_dict)

		this.query_profile_settings.add_custom_action(__('Remove'), function () {
			me.remove_query_profile(fields_dict.profile.value)
			me.on_query_profile_change()
		}, 'btn-danger');

		this.query_profile_settings.add_custom_action(__('Reset Column Display'), () => me.reset_table_display(fields_dict.profile.value), 'ml-2');

		this.query_profile_settings.show();
	}

	format_profile_list(fields_dict) {

		const r = this.query_profile_list

		fields_dict.profile.df.options = r.map(el => el.name)
		fields_dict.profile.df.options.unshift('Default')
		let curr_profile = undefined

		if (fields_dict.profile.value == 'Default') {

			this.set_default_profile(fields_dict)

		} else {

			curr_profile = r.find(el => el.name == fields_dict.profile.value)

			if (curr_profile) {

				fields_dict.is_public.value = curr_profile.is_public
				fields_dict.is_standard.value = curr_profile.is_standard
			} else {

				this.saved_query_profile = "Default"
				fields_dict.profile.value == 'Default'
				this.set_default_profile(fields_dict)
			}
		}

		this.query_profile_settings.refresh()
		this.toggle_dialog_readonly(fields_dict, this.query_profile_settings.custom_actions[0], curr_profile)

		this.setup_query_fields()
		this.display_structure()

		fields_dict.add_button.$wrapper[0].classList.add("d-flex", "justify-content-end", 'mt-4')
	}

	set_default_profile(fields_dict) {
		this.query_profile_settings.custom_actions[0].children[0].classList.add('hide')
		this.query_profile_settings.custom_actions[0].children[1].classList.add('hide')

		fields_dict.add_button.input_area.hidden = true
		fields_dict.is_public.wrapper.hidden = true
		fields_dict.is_standard.wrapper.hidden = true
		fields_dict.show_assigned_users.wrapper.hidden = true

		this.update_query_user_settings("Default")
	}

	async reset_table_display(profile) {
		await frappe.db.set_value('ioi Query Profile', profile, 'display', '')

		if (this.query_profile_settings) this.query_profile_settings.hide();
		if (this.ioi_expert_view_table) this.ioi_expert_view_table.destroy();

		this.render_ioi_expert_grid()
	}

	show_assigned_users(e, fields_dict) {
		const show_assign = e.target.checked

		if (show_assign && this.saved_query_profile != "Default") {
			let field_assign = { path: '_assign', field: '_assign', label: __('Assigned To') }

			if (!this.query_fields.some(obj => { return obj.path == "_assign" })) {
				this.query_fields.push(field_assign)
			}
		} else if (!show_assign && this.saved_query_profile != "Default") {

			this.query_fields = this.query_fields.filter(obj => obj.path != "_assign");
		}

		frappe.db.set_value('ioi Query Profile', fields_dict.profile.value, 'fields', this.query_fields)
	}

	add_field(fields_dict, idx=null) {
		if (fields_dict.profile.value != "Default" && fields_dict.profile.value != null) {
			let me = this

			let path = ''
			let node = this.current_select

			if (node.field) {

				if (node.field.parent_node) {
					let parent = this.fieldnames[node.field.parent_node]
					let parents = [parent.name]

					while (parent.parent !== null) {
						parent = this.fieldnames[parent.parent]
						parents.unshift(parent.name)
					}

					for (let i = 0; i < parents.length; i++) {
						path += path != '' ? `.${parents[i]}` : parents[i]
					}
				}

				// Allow to choose site for q_stock
				if (node.field.fieldname === "q_stock") {

					frappe.call('silicon_ioi.utils.lib.system.get_visibles_sites_for_stock', {current_site: this.current_site}).then(r => {

						let d = new frappe.ui.Dialog({
							title: __('Select a site'),
							fields: [
								{
									label: __('Sites'),
									fieldname: 'q_stock_site',
									fieldtype: 'Select',
									options: r.message,
									default: this.current_site || ""
								},
							],
							size: 'small',
							primary_action_label: __('Select'),
							primary_action(values) {

								const site = values.q_stock_site
								const label = __('Site {0} stock qty', [site])
								const fieldname = `${site.toLowerCase()}_${node.field.fieldname}`

								path += path != '' ? `.${fieldname}` : fieldname

								if (idx) {
									me.query_fields.splice(idx, 0, {path: path, field: path, label: label})
								} else {
									me.query_fields.push({path: path, field: path, label: label})
								}

								me.setup_query_fields()
								me.is_fields_changed = true

								frappe.db.set_value('ioi Query Profile', fields_dict.profile.value, 'fields', me.query_fields)

								d.hide();
							}
						});

						d.show();
					})

				} else {

					path += path != '' ? `.${node.field.fieldname}` : node.field.fieldname

					if (idx) {
						this.query_fields.splice(idx, 0, {path: path, field: path.replace('.', '___'), label: this.current_select.label})
					} else {
						this.query_fields.push({path: path, field: path.replace('.', '___'), label: this.current_select.label})
					}

					this.setup_query_fields()
					this.is_fields_changed = true

					frappe.db.set_value('ioi Query Profile', fields_dict.profile.value, 'fields', this.query_fields)
				}

			} else {

				frappe.show_alert({
					message: __('Select a field first'),
					indicator: 'red'
				}, 5);

				return;
			}
		}
	}

	display_structure() {
		createApp({
			render: () => h(QueryProfile, {
				doctype: this.doctype,
				docname: this.docname
			})
		}).mount(this.query_profile_settings.get_field("structure_html").wrapper);
	}

	remove_query_profile(profile) {
		this.query_profile_settings.fields_dict.profile.value = "Default"
		this.saved_query_profile = "Default"

		frappe.db.delete_doc('ioi Query Profile', profile).then(() => {
			this.query_profile_settings.hide();
			this.show_query_profile_settings()
			this.render_profile_selector()
		})
	}

	create_new_query_profile() {
		let me = this
		this.query_profile_settings.hide();

		this.new_query_profile_dialog = new frappe.ui.Dialog({
			title: __('Create a new profile'),
			fields: [
				{
					label: __('Profile name'),
					fieldname: 'new_profile_name',
					fieldtype: 'Data',
				},
			],
			size: 'small',
		});

		this.new_query_profile_dialog.set_primary_action(__("Create"), () => {
			let values = me.new_query_profile_dialog.get_values().new_profile_name;

			frappe.db.insert({ doctype: 'ioi Query Profile', doc_type: me.doctype, user_name: frappe.session.user, profile_name: values }).then(() => {
				let profile_name = `${me.doctype} • ${frappe.session.user} • ${values}`

				me.query_profile_settings.fields_dict.profile.value = profile_name
				me.saved_query_profile = profile_name

				me.new_query_profile_dialog.hide();
				me.show_query_profile_settings()
				me.on_query_profile_change()
			})
		})

		this.new_query_profile_dialog.show()
	}

	setup_remove_fields() {
		let fields_html = this.query_profile_settings.get_field("selected_fields");
		let remove_fields_buttons = fields_html.$wrapper[0].getElementsByClassName("remove-field");

		for (let idx = 0; idx < remove_fields_buttons.length; idx++) {
			remove_fields_buttons.item(idx).onclick = () =>
				this.remove_fields(remove_fields_buttons.item(idx));
		}
	}

	remove_fields(remove_fields_buttons) {
		let me = this;
		let attr = remove_fields_buttons.getAttribute("data-fieldname")

		for (let idx in me.query_fields) {
			let field = me.query_fields[idx];

			if (field.path == attr) {
				me.query_fields.splice(idx, 1);
				me.setup_query_fields()

				frappe.db.set_value('ioi Query Profile', me.query_profile_settings.fields_dict.profile.value, 'fields', me.query_fields)

				break;
			}
		}

		this.is_fields_changed = true
	}

	update_query_user_settings(profile) {
		return frappe.model.user_settings.save(this.doctype, "ioi Expert Profile", !!profile ? profile : "Default")
	}

	async get_query_fields() {
		if (this.saved_query_profile != "Default" && !!this.saved_query_profile) {
			await frappe.db.get_value('ioi Query Profile', { name: this.saved_query_profile }, ['fields', 'is_public', 'is_standard']).then(r => {

				if (this.query_profile_settings) {
					this.query_profile_settings.fields_dict.is_public.input.checked = r.message.is_public == 1 ? true : false

					const input_is_standard = this.query_profile_settings.fields_dict.is_standard.disp_area.querySelector('input')
					input_is_standard.classList = r.message.is_standard == 1 ? 'disabled-selected' : 'disabled-deselected'
				}

				if (r.message.fields == null) {
					this.query_fields = []
					if (this.query_profile_settings) this.query_profile_settings.fields_dict.show_assigned_users.input.checked = false
				} else {

					const parsed_fields = JSON.parse(r.message.fields)
					this.query_fields = parsed_fields

					if (this.query_profile_settings) {
						this.query_profile_settings.fields_dict.show_assigned_users.input.checked = parsed_fields.some(el => el.field == "_assign")
					}
				}
			})
		} else {
			let custom_default_dt = ["ioi Item", "ioi Manufacturer Catalog"]

			if (custom_default_dt.includes(this.doctype)) {
				this.set_custom_default_profile()
			} else {
				let default_fields = this.meta.fields.filter(field => field.in_list_view == 1)

				this.query_fields = await default_fields.map(field => {
					let path = field.fieldname
					return {path: path, field: field.fieldname, label: field.label}
				})
			}
		}

		let field_name = { path: 'name', field: 'name', label: 'ID' }

		if (!this.query_fields.some(obj => { return obj.path == "name" })) {
			this.query_fields.unshift(field_name)
		}
	}

	set_custom_default_profile() {
		if (this.doctype == "ioi Item") {
			// Customized default profile for ioi Item
			return this.query_fields = [
				{path: ('description'), field: ('description'), label: __('Description')},
				{path: (`${this.current_site}_q_stock`), field: (`${this.current_site}_q_stock`), label: __('Site {0} Stock qty', [this.current_site])},
				{path: ('unit_id'), field: ('unit_id'), label: __('Unit')},
				{path: ('mode'), field: ('mode'), label: __('Mode')},
				{path: ('lib_ioistatus'), field: ('lib_ioistatus'), label: __('Status')},
				{path: ('family_id'), field: ('family_id'), label: __('Family')},
			]
		} else if (this.doctype == "ioi Manufacturer Catalog") {
			// Customized default profile for ioi Manufacturer Catalog
			return this.query_fields = [
				{ path: ('description'), field: ('description'), label: __('Description') },
				{ path: ('unit_id'), field: ('unit_id'), label: __('Unit') },
				{ path: ('family_id'), field: ('family_id'), label: __('Family') },
				{ path: ('tags'), field: ('tags'), label: __('Tags') },
			]
		}
	}

	async create_query() {
		let user_settings = frappe.model.user_settings[this.doctype]["ioi Expert Profile"]

		if (!!user_settings && this.query_profile_list.some(el => el.name === user_settings)) {
			this.saved_query_profile = user_settings
		} else {
			this.saved_query_profile = "Default"
			this.update_query_user_settings("Default");
		}

		await this.get_query_fields()
	}

	load_status() {
		let me = this;

		this.status = [];

		frappe.call({
			method: 'silicon_ioi.ioi_configuration.doctype.ioi_module_status.ioi_module_status.ioi_module_status_get_list_for_listview',
			args: { "doctype": this.doctype },
			async: false,
			callback: function (r) {
				if (r.message.length > 0) {
					for (var i = 0; i < r.message.length; i++) {
						//			 dt.ioistatus, dt.background_color, dt.font_color, dt.description, dt.description_lg1, dt.description_lg2, dt.description_lg3, dt.description_lg4, description_language
						me.status[i] = [r.message[i][2], r.message[i][3], r.message[i][4], r.message[i][5], r.message[i][6], r.message[i][7], r.message[i][8], r.message[i][9], r.message[i][10]];
					}
				}
			}
		});

		this.user_status = [];

		frappe.call({
			method: 'silicon_ioi.ioi_configuration.doctype.ioi_module_user_status.ioi_module_user_status.ioi_module_user_status_get_list_for_listview',
			args: { "doctype": this.doctype },
			async: false,
			callback: function (r) {
				if (r.message.length > 0) {
					for (var i = 0; i < r.message.length; i++) {
						//			 dt.ioistatus, dt.background_color, dt.font_color, dt.description, dt.description_lg1, dt.description_lg2, dt.description_lg3, dt.description_lg4, description_language
						me.user_status[i] = [r.message[i][2], r.message[i][3], r.message[i][4], r.message[i][5], r.message[i][6], r.message[i][7], r.message[i][8], r.message[i][9], r.message[i][10]];
					}
				}
			}
		});

		this.clocking_status = [];

		if (this.doctype !== "ioi Period") {
			frappe.call({
				method: 'silicon_ioi.ioi_configuration.doctype.ioi_module_status.ioi_module_status.ioi_module_status_get_list_for_listview',
				args: { "doctype": "ioi Clocking Reg" },
				async: false,
				callback: function (r) {
					if (r.message.length > 0) {
						for (var i = 0; i < r.message.length; i++) {
							me.clocking_status[i] = [r.message[i][2], r.message[i][3], r.message[i][4], r.message[i][5], r.message[i][6], r.message[i][7], r.message[i][8], r.message[i][9], r.message[i][10]];
						}
					}
				}
			});
		}
	}

	save_table_display(old_profile, new_profile) {
		if (this.saved_query_profile != "Default") {
			if (old_profile == new_profile) {
				let columnLayout = this.ioi_expert_view_table.getColumnLayout();
				columnLayout = columnLayout.filter(col => col.field != "responsiveCollapse")

				let total_col_width = 0
				columnLayout.forEach(col => total_col_width += col.width)

				if (total_col_width < this.ioi_expert_view_table.element.clientWidth) {
					frappe.db.set_value('ioi Query Profile', this.saved_query_profile, 'display', columnLayout).then(() => frappe.show_alert({
						message: __('Table display has been saved'),
						indicator:'green'
					}, 5))
				} else {
					frappe.show_alert({
						message: __('The column size cannot exceed the size of the table'),
						indicator:'red'
					}, 5)
				}
			}
		}
	}

	generate_url(url, config, params, export_limit=undefined) {
		const doctype_src = this.doctype
		const fields = this.query_fields.map(field => field.path)
		let sorters = []
		let filters = []
		let path = "name"
		let sort = "asc"
		let page = 1
		let size = 20
		let site_id = (this.meta.fields.filter(field => field.fieldname == "site_id")).length ? this.current_site : ''
		let prefix_id = $('#global_document_prefix_drowpdown_el').val()

		if (this.ioi_expert_view_table && this.ioi_expert_view_table.initialized) {
			sorters = this.ioi_expert_view_table.getSorters()

			if (sorters.length) {
				path = sorters[0].column.getElement().querySelector('.tabulator-col-title')
				path = path.getAttribute('data-field-path')
				sort = sorters[0].dir === 'asc' ? 1 : 0
			}

			let table_filters = this.ioi_expert_view_table.getFilters(true);

			const is_global = (filter, or_filters=false) => {
				const is_global = this.global_filtering.some(
					glob_filter => glob_filter.field === filter.field && glob_filter.value === filter.value
				);

				if (is_global) {
					return {
						...filter,
						"global_filters": 1
					};
				} else if (or_filters) {
					return {
						...filter,
						"or_filters": 1
					};
				}

				return filter;
			};

			filters = table_filters.reduce((acc, filter) => {
				if (filter.value.includes('|')) {

					let sub_filters = filter.value.split('|').map(value => ({
						"field": filter.field,
						"type": filter.type,
						"value": value.trim(),
					}));

					sub_filters = sub_filters.map(sub_filter => is_global(sub_filter, true))

					acc.push(...sub_filters);
				} else {

					acc.push(is_global(filter));
				}

				return acc;
			}, []);

			page = this.ioi_expert_view_table.getPage()

			if (export_limit) {
				size = export_limit
				prefix_id = ""
			} else {
				size = this.ioi_expert_view_table.getPageSize()
			}
		}

		const order_by = [{
			name: path,
			desc: sort
		}]

		params = { doctype_src, fields, order_by, filters, site_id, prefix_id, size, page }
		return url + "?params=" + encodeURI(JSON.stringify(params));
	}

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	/////////////////////////////////////////////////////////////////////                      Table Settings                       /////////////////////////////////////////////////////////////////////
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	async set_columns() {
		let me = this
		let columns = []

		let getPath = (cell, path) => {
			cell.getElement().setAttribute("data-field-path", path)
		}

		let columns_layout = []

		if (me.saved_query_profile != "Default") {
			await frappe.db.get_value('ioi Query Profile', me.saved_query_profile, 'display').then(r => {
				if (r.message.display != "" && r.message.display != null) {
					columns_layout = JSON.parse(r.message.display)
				}
			})
		}

		columns.push({ field: "responsiveCollapse", formatter:"responsiveCollapse", maxWidth: 45, minWidth: 45, hozAlign:"center", resizable:false, headerSort:false })

		if (columns_layout.length) {
			this.query_fields.map(field => {
				let item = columns_layout.find(col => col.field === field.field)
				let index = columns_layout.indexOf(item) + 1

				if (item) {
					columns.push({index: index, title: __(`${field.label}`), field: field.field, headerFilter:"input", headerFilterPlaceholder: __(`${field.label}`), width: item.width, titleFormatter: (cell) => getPath(cell, field.path)})
				} else {
					columns.push({index: columns_layout.length, title: __(`${field.label}`), field: field.field, headerFilter:"input", headerFilterPlaceholder: __(`${field.label}`), titleFormatter: (cell) => getPath(cell, field.path)})
				}
			})
		} else {
			this.query_fields.map(field => columns.push({ title: __(`${field.label}`), field: field.field, headerFilter:"input", headerFilterPlaceholder: __(`${field.label}`), titleFormatter: (cell) => getPath(cell, field.path)}))
		}

		columns.sort(function(a, b){ return a.index-b.index });
		columns.forEach(col => delete col.index)

		return columns
	}

	format_name_style(cell) {
		let value = cell ? cell.getValue() : null

		if (value != null && value != "") {
			cell.getElement().style.fontWeight = 600

			let convert_dt = this.doctype.toLowerCase().replace(/\s+/g, '-')
			let url = `/app/${convert_dt}/${cell.getData().name}`

			const title_field = cell.getData().title_field

			cell.getElement().innerHTML = `<a class="ioi_expert_name_link" href="${url}">${title_field ? title_field : cell.getValue()}</a>`
		}
	}

	get_status_from_dt(cell, row, status, end_path) {
		frappe.call({
			method: 'silicon_ioi.ioi_configuration.doctype' + end_path,
			args: { "doctype": this.doctype, "name": row.getData().name },
			async: false,
			callback: function (r) {
				if (r.message.length) {
					let dt_status = status.filter((el) => el[0] == r.message)
					cell.getElement().style.padding = "0 12px"

					if (dt_status.length) {
						cell.getElement().innerHTML = `<div style="background-color: ${dt_status[0][1]}; color: black; border-radius: 6px; padding: 4px; margin: 8.75px 0 8.75px 0;">${dt_status[0][8]}</div>`
					} else {
						cell.getElement().innerHTML = `<div style="background-color: #C3C2C4; color: black; border-radius: 6px; padding: 4px; margin: 8.75px 0 8.75px 0;">${__('Undefined')}</div>`
					}
				}
			}
		})
	}

	format_status = (row, field) => {
		let status_fields_list = ['ioistatus', 'lib_ioistatus']
		let cell = row.getCell(status_fields_list.find(el => el == field))

		if (cell != "") {
			let cell_value = cell.getValue()
			let status = cell_value != null ? this.status.filter((el) => el.includes(Number(cell_value))) : ""
			if (status != "") {
				cell.getElement().style.padding = "0 12px"
				cell.getElement().innerHTML = `<div style="background-color: ${status[0] ? status[0][1] : ""}; color: black; border-radius: 6px; padding: 4px; margin: 8.75px 0 8.75px 0;">${status[0] ? status[0][8] : ""}</div>`
			} else {
				let me = this
				this.get_status_from_dt(cell, row, me.status, ".ioi_module_status.ioi_module_status.ioi_module_status_get_status_value_from_doctype")
			}
		}
	}

	format_user_status(row, field) {
		let user_status_fields_list = ['ioiuserstatus', 'lib_ioiuserstatus']
		let cell = row.getCell(user_status_fields_list.find(el => el == field))

		if (cell != "") {
			let cell_value = cell.getValue()
			let user_status = cell_value != null ? this.user_status.filter((el) => el.includes(cell.getField() === "ioiuserstatus" ? Number(cell_value) : cell_value)) : ""

			if (user_status != "") {
				cell.getElement().style.padding = "0 12px"
				cell.getElement().innerHTML = `<div style="background-color: ${user_status[0] ? user_status[0][1] : ""}; color: black; border-radius: 6px; padding: 4px; margin: 8.75px 0 8.75px 0;">${user_status[0] ? user_status[0][8] : ""}</div>`
			}
		}
	}

	format_clocking_status = (row) => {
		let cell = row.getCell('clocking_status')

		if (cell != "") {
			let cell_value = cell.getValue()
			let clocking_status = cell_value != null ? this.clocking_status.filter((el) => el.includes(Number(cell_value))) : ""

			if (clocking_status != "") {
				cell.getElement().style.padding = "0 12px"
				cell.getElement().innerHTML = `<div style="background-color: ${clocking_status[0] ? clocking_status[0][1] : ""}; color: black; border-radius: 6px; padding: 4px; margin: 8.75px 0 8.75px 0;">${clocking_status[0] ? clocking_status[0][8] : ""}</div>`
			}
		}
	}

	format_clocking_status_for_period = (row, field) => {
		let cell = row.getCell(field)

		if (cell != "") {
			let cell_value = cell.getValue()
			let clocking_status = cell_value == 0 ? __('Pending') : cell_value == 1 ? __('Open') : cell_value == 2 ? __('Closed') : cell_value

			if (clocking_status != "") {
				cell.getElement().innerHTML = `<div>${clocking_status}</div>`
			}
		}
	}

	format_enabled(row) {
		let cell = row.getCell('enabled')
		let value = cell.getValue()

		if (value == 1) {
			return cell.getElement().innerHTML =
				`<span class="indicator-pill blue filterable ellipsis">
						<span class="ellipsis"> Enabled</span>
					</span>`
		} else if (value == 0) {
			return cell.getElement().innerHTML =
				`<span class="indicator-pill grey filterable ellipsis">
						<span class="ellipsis"> Disabled</span>
					</span>`
		}
	}

	format_to_pill(text) {
		return `<span class="indicator-pill gray filterable ellipsis">
					<span class="ellipsis"> ${text}</span>
				</span>`
	}

	format_mode(row) {
		if (this.doctype == 'ioi Item') {
			let cell = row.getCell('mode')
			let value = cell.getValue()

			if (value != null && value != "") {
				let converted_value = value == 0 ? __('Regular') : value == 1 ? __('Free') : value == 2 ? __('Virtual') : value == 3 ? __('Batch') : value == 4 ? __('Serial') : ''
				cell.getElement().innerHTML = this.format_to_pill(converted_value)
			}
		}
	}

	format_scheduling_mode(row) {
		if (this.doctype == 'ioi Item') {
			let cell = row.getCell('scheduling_mode')
			let value = cell.getValue()

			if (value != null && value != "") {
				let converted_value = value == 0 ? __('Undefined') : value == 1 ? __('Purchased') : value == 2 ? __('Manufactured') : value == 3 ? __('Subcontracted') : ''
				cell.getElement().innerHTML = this.format_to_pill(converted_value)
			}
		}
	}

	format_assign_to(row) {
		let cell = row.getCell('_assign')
		let value = cell.getValue()
		let array = value ? JSON.parse(cell.getValue()) : [];

		if (array.length) {
			cell.getElement().innerHTML = `<div class="list-assignments d-flex align-items-center">
				${frappe.avatar_group(JSON.parse(value), 3, { filterable: true })[0].outerHTML}
			</div>`;
		}
	}

	format_cashdesk_payment_status(row) {
		let cell = row.getCell('cashdesk_payment_status_id')
		let value = cell.getValue()
		let bgcolor = ""
		let description = ""

		if ((!value) || ((value) && ((value == '') || (value == 0)))) {
			description = __("Unpaid");
			bgcolor = '#D9D3D2';
		} else if (value == 1) {
			description = __("Partial");
			bgcolor = '#FFC869';
		} else {
			description = __("Total")
			bgcolor = '#7ECC6E';
		}

		cell.getElement().style.padding = "0 12px"
		cell.getElement().innerHTML = `<div style="background-color: ${bgcolor}; color: black; border-radius: 6px; padding: 4px; margin: 8.75px 0 8.75px 0;">${description}</div>`
	}

	format_pagination_counter(pageSize, currentRow, currentPage, totalRows, totalPages) {
		let currentRowMax = pageSize * currentPage
		if (currentRowMax > totalRows) currentRowMax = totalRows
		if (totalRows == 0) currentRow = 0

		return __("{0} - {1} of {2}",[currentRow,currentRowMax,totalRows]);
	}

	render_placeholder(filters) {
		return `
			<div class="no-result text-muted flex justify-center align-center" style="">
				<div class="no-result text-muted flex justify-center align-center">
					<div class="msg-box no-border flex flex-column justify-center align-center">
					${filters ?
						`<div>
							<img src="/assets/frappe/images/ui-states/list-empty-state.svg" alt="Generic Empty State" class="null-state">
						</div>
						<p style="font-weight: 400;">${__(`No "{0}" found with matching filters. Clear filters to see all "{0}".`, [this.doctype])}</p>`
						:
						`<div>
							<img src="/assets/frappe/images/ui-states/list-empty-state.svg" alt="Generic Empty State" class="null-state">
						</div>
						<p style="font-weight: 400;">${__(`You haven't created a "{0}" yet`), [this.doctype]}</p>`
					}
					</div>
				</div>
			</div>`
	}

	async render_ioi_expert_grid() {
		let me = this

		this.ioi_expert_view_table = new ioi.Tabulator($('#ioi_expert_view')[0], {
			paginationMode: "remote",
			filterMode: "remote",
			sortMode: "remote",
			headerFilterLiveFilterDelay: 500,
			initialSort:[{ column: "name", dir: "desc" }],
			minHeight: "calc(100vh - 165px)",
			maxHeight: "calc(100vh - 165px)",
			responsiveLayout: 'collapse',
			responsiveLayoutCollapseStartOpen: false,
			wrapLines: false,
			pagination: true,
			paginationSize: 20,
			paginationSizeSelector: [20, 100, 500],
			paginationCounter: (pageSize, currentRow, currentPage, totalRows, totalPages) => this.format_pagination_counter(pageSize, currentRow, currentPage, totalRows, totalPages),
			paginationButtonCount: 0,
			ajaxURL: "/api/method/silicon_ioi.utils.lib.system.get_custom_query_list",
			ajaxURLGenerator: (url, config, params) => this.generate_url(url, config, params),
			ajaxResponse: function (url, params, response) {
				return {
					last_page: response.message.last_page,
					last_row: response.message.last_row,
					data: response.message.data
				};
			},
			placeholder: () => this.render_placeholder(this.ioi_expert_view_table.initialized ? this.ioi_expert_view_table.getHeaderFilters().length : false),
			layout: "fitColumns",
			rowHeight: 45,
			movableColumns: true,
			resizableColumns: true,
			selectableRows: 1,
			columns: await this.set_columns(),
			columnDefaults: {
				minWidth: 100,
			},
			resizableColumnFit: true,
			rowFormatter:function(row){
				let data = row.getData()

				if ('name' in data) {
					me.format_name_style(row.getCell("name"))
				}
				if ('enabled' in data) {
					me.format_enabled(row)
				}
				if ('ioistatus' in data) {
					me.format_status(row, 'ioistatus')
				}
				if ('lib_ioistatus' in data) {
					me.format_status(row, 'lib_ioistatus')
				}
				if ('ioiuserstatus' in data) {
					me.format_user_status(row, 'ioiuserstatus')
				}
				if ('lib_ioiuserstatus' in data) {
					me.format_user_status(row, 'lib_ioiuserstatus')
				}
				if ('mode' in data) {
					me.format_mode(row)
				}
				if ('scheduling_mode' in data) {
					me.format_scheduling_mode(row)
				}
				if (me.doctype !== "ioi Period" && 'clocking_status' in data) {
					me.format_clocking_status(row)
				}
				if (me.doctype === "ioi Period") {
					const status_fields = ['clocking_status', 'acc_finances_status', 'acc_miscellaneous_status', 'acc_purchases_status', 'purchases_status', 'acc_sales_status', 'sales_status', 'wms_status'];
					for (let field of status_fields) if (field in data) me.format_clocking_status_for_period(row, field);
				}
				if ('_assign' in data) {
					me.format_assign_to(row)
				}
				if ('cashdesk_payment_status_id' in data) {
					me.format_cashdesk_payment_status(row)
				}
			},
		})

		this.ioi_expert_view_table.on('tableBuilt', () => {
			me.page.wrapper.find('.tabulator-paginator .tabulator-page-size').addClass('btn pb-1 mr-3 border-0')
			me.page.wrapper.find('.tabulator-paginator label').remove()

			me.page.wrapper.find('.tabulator-col .tabulator-header-filter input').attr('title', __("Formulate your search using '>, >=, <=, <' signs and use '|' to indicate 'OR' relations"))
		})

		this.ioi_expert_view_table.on("rowSelected", (row) => {

			// Trick to show selected row, by pass !important scss selector
			const idx = row.getPosition()
			row.getElement().insertAdjacentHTML('afterbegin', `<div id="highlight_selected_row_${idx}" class="h-100 w-100" style="position: absolute; top: 0; left: 0; opacity: 0.1; background-color: #000; z-index: 100;">`)

			if ($(`#${this.formatted_dt_name}_list_highlight_info_content`)[0]) {
				me.get_highlight_list(0, row.getData().name)
			}
		});

		this.ioi_expert_view_table.on("rowDeselected", (row) => {

			const idx = row.getPosition()
			$(`#highlight_selected_row_${idx}`).remove()

			if ($(`#${this.formatted_dt_name}_list_highlight_info_content`)[0]) {
				me.get_highlight_list(0, undefined)
			}
		});
	}

	/////////////////////////
	/////// Side View ///////
	/////////////////////////

	init_list_side_view() {

		this.formatted_dt_name = this.doctype.toLowerCase().replaceAll(' ', '_')
		this.add_menu_side_bar()

		frappe.call('silicon_ioi.utils.lib.system.get_doctype_preview', { doctype: this.doctype, side_view_origin: 'LIST' }).then(r => {
			const res = r.message

			if (res && res.side_view_type) {
				if (res.side_view_type === "LIST_HIGHLIGHT") this.show_highlight_side_view(res)
			}
		})
	}

	add_menu_side_bar() {
		let me = this;

		const buttons = [
			{ label: __("Highlight Info Side View"), type: "LIST_HIGHLIGHT", action: me.show_highlight_side_view },
		];

		const icon = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-layout-sidebar-inset-reverse" viewBox="0 0 16 16">
							<path d="M2 2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zm12-1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2z"/>
							<path d="M13 4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1z"/>
						</svg>`;

		buttons.forEach(({ label, type, action, condition }) => {
			if (condition ? condition : true) {
				me.page.add_inner_button(label, function() {
					frappe.call('silicon_ioi.utils.lib.system.get_doctype_preview', { doctype: me.doctype, side_view_origin: 'LIST' }).then(res => {
						frappe.call('silicon_ioi.utils.lib.system.toggle_doctype_preview', { enabled: 1, doctype: me.doctype, type: type, is_new: res.message ? 0 : 1, side_view_origin: 'LIST' }).then(r => action.call(me, r.message));
					});
				}, icon);
			}
		})
	}

	clear_ioi_list_side_view() {
		let side_view = $(`[data-page-route="List/${this.doctype}/Expert"]`).find(`#${this.formatted_dt_name}_list_side_view`)
		let side_view_resizer = $(`[data-page-route="List/${this.doctype}/Expert"]`).find(`#${this.formatted_dt_name}_list_side_view_resizer`)

		if (side_view) side_view.remove()
		if (side_view_resizer) side_view_resizer.remove()
	}

	check_side_view_res(saved_width) {
		if (saved_width > (window.innerWidth/2.25)) {
			saved_width = 0
			frappe.call('silicon_ioi.utils.lib.system.save_pdf_viewer_width', { doctype: this.doctype, width: 0 });
		}

		return saved_width
	}

	create_resizer(doctype) {
		const file_preview = $(`#${this.formatted_dt_name}_list_side_view`)[0];
		const resizer = $(`#${this.formatted_dt_name}_list_side_view_resizer`)[0];

		if (file_preview) {
			let x = 0;
			let file_preview_width = 0;
			let original_width = 0;
			let timeoutId = null;

			const cursor_style_display = (toggle) => {
				const prevSibling = resizer.previousElementSibling;
				const nextSibling = resizer.nextElementSibling;

				if (toggle === "add") {
					document.body.style.cursor = "ew-resize";
					prevSibling.style.userSelect = 'none';
					prevSibling.style.pointerEvents = 'none';
					nextSibling.style.userSelect = 'none';
					nextSibling.style.pointerEvents = 'none';
				} else {
					document.body.style.removeProperty('cursor');
					prevSibling.style.removeProperty('user-select');
					prevSibling.style.removeProperty('pointer-events');
					nextSibling.style.removeProperty('user-select');
					nextSibling.style.removeProperty('pointer-events');
				}
			}

			const mouse_down_handler = function(e) {
				x = e.clientX;
				const rect = file_preview.getBoundingClientRect();
				file_preview_width = rect.width;
				original_width = file_preview_width;

				cursor_style_display("add")

				document.addEventListener('mousemove', mouse_move_handler);
				document.addEventListener('mouseup', mouse_up_handler);

				clearTimeout(timeoutId);
			};

			const mouse_move_handler = function(e) {
				const dx = e.clientX - x;
				const new_width = file_preview_width - dx;

				if (new_width > 0 && new_width < (window.innerWidth/2.25) ) {
					file_preview.style.width = new_width + 'px';
				}

				clearTimeout(timeoutId);
			};

			const mouse_up_handler = function() {
				cursor_style_display("remove")

				document.removeEventListener('mousemove', mouse_move_handler);
				document.removeEventListener('mouseup', mouse_up_handler);

				clearTimeout(timeoutId);

				timeoutId = setTimeout(() => {
					if (Math.round(parseFloat(file_preview.style.width)) !== original_width) {
						frappe.call('silicon_ioi.utils.lib.system.save_pdf_viewer_width', { doctype: doctype, width: Math.round(parseFloat(file_preview.style.width)) });
					}
				}, 2000);
			};

			resizer.addEventListener('mousedown', mouse_down_handler);
		}
	}

	show_highlight_side_view(curr_doctype_infos) {
		if (frappe.get_route()[0] == 'List') {
			if (curr_doctype_infos && curr_doctype_infos.enabled == 1) {

				const saved_width = this.check_side_view_res(curr_doctype_infos.preview_width)

				const container = `
					<div id="${this.formatted_dt_name}_list_side_view" class="p-4 ml-3 rounded position-relative overflow-auto" style="width: ${saved_width && saved_width != 0 ? `${saved_width}px` : '30%'}; height: calc(-165px + 100vh)">
						<button id="${this.formatted_dt_name}_list_side_view_close" class="btn btn-xs position-absolute shadow-none" style="top: 0px; right: 0px;">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
								<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
							</svg>
						</button>
						<label class="control-label" style="padding-right: 0px; font-size: var(--text-md);">${__('Highlight Info')}</label>
						<div class="mt-3" id="${this.formatted_dt_name}_list_highlight_info_buttons"></div>
						<div class="mt-3" id="${this.formatted_dt_name}_list_highlight_info_content"></div>
					</div>`;

				const resizer_html = `<div id="${this.formatted_dt_name}_list_side_view_resizer" class="resizer rounded" style="height: calc(-165px + 100vh - 50px);"></div>`;
				const main_layout = $(`[data-page-route="List/${this.doctype}/Expert"]`).find(".row.layout-main");

				this.clear_ioi_list_side_view()

				main_layout.append(resizer_html).append(container)
				this.create_resizer(this.doctype);

				this.get_highlight_list(0, undefined)

				$(`#${this.formatted_dt_name}_list_side_view_close`)[0].onclick = () => {
					this.clear_ioi_list_side_view()
					frappe.call('silicon_ioi.utils.lib.system.toggle_doctype_preview', { enabled: 0, doctype: this.doctype, type: curr_doctype_infos.side_view_type, is_new: 0, side_view_origin: 'LIST' })
				}

			} else if (curr_doctype_infos && curr_doctype_infos.enabled == 0) {
				this.clear_ioi_list_side_view()
			}
		} else {
			this.clear_ioi_list_side_view()
		}
	}

	get_highlight_list(view, name_from_data_list) {
		let me = this
		$(`#${this.formatted_dt_name}_list_highlight_info_content`)[0].innerHTML = ''

		if (name_from_data_list) {

			frappe.call({
				method: "silicon_ioi.utils.lib.system.get_highlight_list",
				args: {
					doctype: this.doctype,
					list_mode: 1,
					view: view || 0
				},
				async: true,
				callback: async function (r) {

					if (r.message.length > 0) {
						let result = r.message

						if (result.find(el => el.view_selector !== 0) && !$(`#${me.formatted_dt_name}_side_view_select`)[0]) {

							const unique_view_selectors = new Set();
							const select = `
								<div class="d-flex w-100">
									<div class="frappe-control input-max-width w-25" data-fieldtype="Select">
										<div class="form-group">
											<div class="clearfix">
												<label class="control-label" style="padding-right: 0px; font-size: var(--text-md);">${__('View Selector')}</label>
											</div>
											<div class="control-input-wrapper">
												<div class="control-input flex align-center">
													<select id="${me.formatted_dt_name}_side_view_select" type="text" autocomplete="off" class="input-with-feedback form-control ellipsis">
														${result
															.filter(el => {
																if (el.view_selector !== 0 && !unique_view_selectors.has(el.view_selector)) {
																	unique_view_selectors.add(el.view_selector);
																	return true;
																}
																return false;
															})
															.sort((a, b) => a.view_selector - b.view_selector)
															.map(el => `<option value="${el.view_selector}">${el.view_selector}</option>`)
															.join('')
														}
													</select>
													<div class="select-icon ">
														<svg class="icon  icon-sm" style="">
															<use class="" href="#icon-select"></use>
														</svg>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>`;

							$(`#${me.formatted_dt_name}_list_highlight_info_buttons`).append(select);

							$(`#${me.formatted_dt_name}_side_view_select`)[0].onchange = () => me.get_highlight_list($(`#${me.formatted_dt_name}_side_view_select`).val(), undefined)

							result = result.filter(el => el.view_selector == $(`#${me.formatted_dt_name}_side_view_select`).val())
						}

						for (const item of result) await me.build_highlight_content(item, name_from_data_list);

					} else {
						const div = document.createElement('div')

						div.innerHTML = `
							<div class="w-100 d-flex justify-content-center mt-4" style="text-align: center;">
								${__('Nothing to show')} <br/>
								${__('Define Highlight info in ioi Module')}
							</div>`

						$(`#${me.formatted_dt_name}_list_highlight_info_content`).append(div)
					}
				}
			})
		} else {
			const div = document.createElement('div')

			div.innerHTML = `
				<div class="w-100 d-flex justify-content-center mt-4" style="text-align: center;">
					${__('Please select a row from the list to view the highlighted informations')}
				</div>`

			$(`#${me.formatted_dt_name}_list_highlight_info_content`).append(div)
		}
	}

	async build_highlight_content(item, name_from_data_list) {
		const div = document.createElement('div')
		div.classList.add('card', 'mb-4')
		div.style.height = `${item.card_height}px`

		const limit_text = isNaN(item.limited_to) ? __('No records limit') : __('Limited to {0} records', [item.limited_to])

		div.innerHTML = `
			<div class="card-header border-0" style="font-family: var(--font-stack); font-size: 0.84rem;">
				${item.title}
			</div>
			<div class="card-body ${item.sql_for_query_grid ? 'p-3' : ''} rounded-bottom" style="font-size: var(--text-sm); background-color: var(--card-bg); overflow: auto;">
				${
					item.display_mode === "HTML" ?
						item.fields_to_display ?
							await this.highlight_content_filter_html(item.fields_to_display, name_from_data_list)
						: ''
					: item.display_mode === "Query Grid" ?
						item.sql_for_query_grid ?
							`
							<div id="highlight_query_grid_${item.display_order}" class="table table-bordered my-0" data-custom-grid="true"></div>
							<p class="help-box small text-muted">${limit_text}</p>
							`
						: ''
					: ''
				}
			</div>
		`

		$(`#${this.formatted_dt_name}_list_highlight_info_content`).append(div)

		if (item.sql_for_query_grid) this.build_query_grid(item, name_from_data_list)
	}

	get_params(el) {
		let param = el.match(/\[(.*?)\]/)

		if (param) {
			param = param[0]
			el = el.replace(param, '')
		} else {
			param = null
			el = el
		}

		return [param, el]
	}

	async highlight_content_filter_html(text, name_from_data_list) {
		// Search {}
		const regex = /\{([^}]+)\}/g
		const matches = text.match(regex);

		if (matches) {
			let modified_text = text;

			let simple_fields = []
			let linked_fields = []
			let composite_fields = []

			const set_params_and_format = (el) => {
				let new_value = el.value
				let field_to_replace = `{${el.field}}`

				if (el.param && el.param !== "") {
					if (el.param === "[LINK]") {
						let url = ''

						if (!!el.value && el.value !== "undefined") {

							if (el.field.includes('•')) {
								url = el.doctype && el.name ? `/app/${this.doctype.toLowerCase().replaceAll(' ', '-')}/${name_from_data_list}?id=${el.field_name}` : ''
							} else {
								url = el.doctype && el.name ? `/app/${el.doctype.toLowerCase().replaceAll(' ', '-')}/${el.name}#${el.field.split('.')[1]}` : ''
							}

							new_value = `<span class="text-underline"><a href="${url}" target="_blank">${el.value}</a></span>`
							field_to_replace = `{${el.field}[LINK]}`

						} else {
							field_to_replace = __('undefined')
						}
					}
				}

				return modified_text = modified_text.replace(field_to_replace, new_value);
			}

			const meta_fields = await frappe.call("silicon_ioi.utils.lib.system.get_meta_for_side_view", { doctype: this.doctype }).then(r => r.message.fields)

			// Prepare to format values
			matches.forEach(el => {
				// Remove {}
				el = el.replace(/\{|\}/g, '')

				if (el.includes('.') && !el.includes('•')) {

					const [param, new_el] = this.get_params(el)
					const [link, field] = new_el.split('.')
					const meta_field = meta_fields.find(el => el.fieldname == link)
					const doctype = meta_field.options

					const link_data = undefined

					linked_fields.push({ link_data, doctype, field, link, param })

				} else if (el.includes('•')) {

					let [param, new_el] = this.get_params(el)
					let doctype = new_el.split(', ')[1].trim()

					new_el = new_el.split(', ')[0]
					let [comp, field] = new_el.split('.')

					// curr_field is current doc field value
					let curr_field = comp.split('•')[0].trim()
					const curr_field_name = curr_field

					curr_field = undefined

					comp = comp.split('•')[1].trim()

					composite_fields.push({ comp, field, curr_field, curr_field_name, doctype, param })

				} else {

					const [param, field] = this.get_params(el)
					const meta_field = meta_fields.find(el => el.fieldname == field)
					const doctype = meta_field.options
					const value = __('undefined')
					const name = value

					simple_fields.push({ field, doctype, value, param, name })
				}
			})

			// Get document fields
			const get_document_fields = await frappe.call("silicon_ioi.utils.lib.system.get_highlight_html_document_fields", { simple_fields, linked_fields, composite_fields, 'doctype': this.doctype, 'name': name_from_data_list }).then(r => r.message)

			simple_fields = get_document_fields.simple_fields
			linked_fields = get_document_fields.linked_fields
			composite_fields = get_document_fields.composite_fields

			// Format text for simple fields
			simple_fields.forEach(el => {
				set_params_and_format(el)
			});

			// Format text for linked and composites fields
			if (linked_fields.length || composite_fields.length) {
				const r = await frappe.call("silicon_ioi.utils.lib.system.get_highlight_content_fields", {
					linked_fields: linked_fields,
					composite_fields: composite_fields,
				})

				const result = r.message

				if (result) {
					if (result.linked_fields) result.linked_fields.forEach(el => set_params_and_format(el))
					if (result.composite_fields) result.composite_fields.forEach(el => set_params_and_format(el))
				}
			}

			// Format text for left undefined values
			const last_matches = modified_text.match(regex);
			if (last_matches) last_matches.map(el => modified_text = modified_text.replace(el, __('undefined')))

			return modified_text;
		}

		return text;
	}

	is_read_only_query(query) {
		if (!!query) {
			const forbidden_keywords = ["insert ", "update ", "delete ", "drop ", "create ", "alter "];
			const normalized_query = query.toLowerCase().replace(/\s+/g, ' ').trim();

			if (!normalized_query.startsWith("select")) {
				return false;
			}

			for (const keyword of forbidden_keywords) {
				if (normalized_query.includes(keyword)) {
					return false;
				}
			}

			return true;
		}
	}

	async build_query_grid(item, name_from_data_list) {
		const query = item.sql_for_query_grid
		const regex = /\{([^}]+)\}/g
		const matches = query.match(regex);
		let error = false

		if (matches) {
			let fields = []
			let modified_query = query;

			const set_params_and_format = (el) => {
				let value = el.new_el
				let field_to_replace = `{${value}}`

				if (el.param && el.param !== "") {
					if (el.param === "[COLLAPSED]") {
						field_to_replace = `{${value}[COLLAPSED]}`
					}
				}

				return modified_query = modified_query.replace(field_to_replace, `'${el.value}'`);
			}

			matches.forEach(el => {
				// Remove {}
				el = el.replace(/\{|\}/g, '')

				const [param, new_el] = this.get_params(el)
				fields.push({new_el, param})
			})

			const get_document_fields = await frappe.call("silicon_ioi.utils.lib.system.get_highlight_query_document_fields", { fields, 'doctype': this.doctype, 'name': name_from_data_list }).then(r => r.message)

			fields = get_document_fields.fields

			fields.forEach(el => {
				if (el.value == __('undefined')) {
					error = true
					return $(`#highlight_query_grid_${item.display_order}`).parent().html(`<div class="d-flex justify-content-center align-items-center h-100 text-danger text-center">${__('Error : Field {0} is undefined', [el.new_el])}</div>`)
				} else {
					set_params_and_format(el)
				}
			})

			if (error) {
				return;
			}

			if (this.is_read_only_query(modified_query)) {

				frappe.call("silicon_ioi.utils.lib.system.get_highlight_content_query", {
					query: modified_query,
					limit: item.limited_to
				}).then(r => {
					if (r.message.length) {
						const select = modified_query.match(/SELECT(.*?)FROM/i)
						let columns = []

						const saved_column_sizes = item.query_column_sizes && item.query_column_sizes
							.split(';')
							.filter(el => el)
							.map(el => {
								const array = el.split(':')
								return { [array[0]]: array[1] }
						})

						if (select && select.length > 1) {
							const select_array = select[1].split(',')

							if (item.collapsible_only_for_query_grid) columns.push({ formatter:"responsiveCollapse", headerSort:false })

							select_array.forEach(el => {
								const is_size_saved = saved_column_sizes && saved_column_sizes.find(col => Object.keys(col)[0] == el.trim())
								const saved_value = is_size_saved ? Object.values(is_size_saved)[0] : ""

								const field = el.trim()
								const title = field.charAt(0).toUpperCase() + field.slice(1)

								columns.push({ title: __(title.replaceAll('_', ' ')), field: field, width: saved_value && saved_value != 0 ? `${saved_value}%` : "" })
							})
						}

						let table = new ioi.Tabulator(`#highlight_query_grid_${item.display_order}`, {
							maxHeight: `${item.card_height - 100}px`,
							data: r.message,
							layout: saved_column_sizes ? "fitData" : "fitColumns",
							columns: columns,
							responsiveLayout: item.collapsible_only_for_query_grid ? "collapse" : false,
							columnDefaults: {
								minWidth: item.collapsible_only_for_query_grid ? 100 : '100%',
							},
						});
					}
				})
			} else {

				return $(`#highlight_query_grid_${item.display_order}`).parent().html(`<div class="d-flex justify-content-center align-items-center h-100 text-danger text-center">${__('Error : Only read-only queries are allowed, they must start with SELECT')}</div>`)
			}
		}
	}
};
