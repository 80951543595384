frappe.provide('silicon_ioi.scheduling');


const path_scheduling_production = 'silicon_ioi.ioi_manufacturing.ioi_scheduling.ioi_scheduling_production';
const path_scheduling_dossier = 'silicon_ioi.ioi_manufacturing.ioi_scheduling.ioi_scheduling_dossier';
const path_scheduling_workcenter = 'silicon_ioi.ioi_manufacturing.ioi_scheduling.ioi_scheduling_workcenter';


export class ioiLoadChart {


	items = [];
	settings = null;
	loadChartContainer = null;
	non_working_day = []
	name = ''
	ioiGantt = null
	default_zoom = 10


	constructor(frm, html_field, mode, name = '')
	{
		this.frm = frm
		this.name = (mode == 2 ? name : '')

		this.loadChartContainer = document.createElement('div');
		this.loadChartContainer.id = `${html_field}_loadChartView`;

		this.mode = cint(mode) //mode = 0: production / if mode = 1: dossier / if mode = 2: workcenter

		this.frm.fields_dict[html_field].$wrapper.append(this.loadChartContainer);

		this.load_settings()
	}


	load_settings()
	{
		let me = this
		me.settings = { currentTime: new Date() };
		me.settings.itemHeight = 21 * me.default_zoom;
		me.settings.barMargin = 17;
		me.settings.maxDisplayedUnits = 1.5
		me.settings.workingWeekStart  = 1; // Monday
		me.settings.workingWeekFinish = 0; // Sunday
		me.settings.visibleWeekStart  = 1; // Monday
		me.settings.visibleWeekFinish = 0; // Sunday
		me.settings.visibleDayStart   = 0; // 00:00
		me.settings.visibleDayFinish  = 24 * 60 * 60 * 1000; // 24:00
		me.settings.theme = 'ModernBordered';
		me.settings.weekStartDay = 1; // Monday
		me.settings.isMouseWheelZoomEnabled = false;
		me.settings.isMouseWheelZoomEnabledMinHourWidth = 1;
		me.settings.isMouseWheelZoomEnabledMaxHourWidth = 200;
		me.settings.isReadOnly = false;

		me.settings.useInlineToolTips = true;
		me.settings.itemTemplate = function (item) {
			return me.get_tool_tip(item);
		}

		me.settings.normalAllocationBarStyle = 'fill: #8abbed;'

		//Display others forms on loadchart
		me.settings.extraTaskTemplate = function(item) {
			let loadChartView = item.loadChartView;
			let settings = loadChartView.settings;
			let document = loadChartView.ownerDocument;
			let svgns = 'http://www.w3.org/2000/svg';
			let svgGroup = document.createElementNS(svgns, 'g');

			//Parameters
			let item_left = loadChartView.getChartPosition(item.start, settings);
			let item_right = loadChartView.getChartPosition(item.finish, settings);
			let y_load_limit = 0;
			let y_load = 0;
			let height = 0;
			let units_load = 0
			//Different shift limit
			if(me.is_spec_scale(item))
			{
				y_load_limit = flt(settings.barMargin + settings.barHeight - (item.units_limit / settings.maxDisplayedUnits) * settings.barHeight);
				y_load = flt(settings.barMargin + settings.barHeight - (item.units / settings.maxDisplayedUnits) * settings.barHeight);
				units_load = flt(item.load_hours/ item.load_limit)
				if (units_load > 1) {
					height = flt(settings.barMargin + settings.barHeight - y_load_limit)
				}else{
					height = flt(settings.barMargin + settings.barHeight - y_load)
				}
			//Standard
			}else{
				y_load_limit = flt(settings.barMargin + (settings.barHeight * (1 - 1 / settings.maxDisplayedUnits)));
				y_load = flt(settings.barMargin + settings.barHeight - (item.units / settings.maxDisplayedUnits) * settings.barHeight)
				height = flt(settings.barHeight/ settings.maxDisplayedUnits); //equal to 21 * 2 px
				units_load = item.units_data
			}
			
			//Value by default (loadchartview): style=\"font-family: system-ui, Arial; font-size: 12px; overflow: auto\" 
			let support_text = 7.5;
			let width_text = 12;
			let font_text = 'system-ui, Arial;';
			let match_color = settings.overAllocationBarStyle.match(/fill:\s*([^;]*)/);
			let fill_color = 'Red';
			if (match_color) {
				fill_color = match_color[1];
			}

			//Rectangle below the load limit
			if (item.units_data > 1) {
				var rect = document.createElementNS(svgns, 'rect');
				rect.setAttribute('x', item_left);
				if (units_load > 1) {
					//Standard + Different shift limit
					rect.setAttribute('y', y_load_limit);
				}else{
					//Different shift limit
					rect.setAttribute('y', y_load);
				}
				rect.setAttribute('width', item_right - item_left - 0.78); //0.78 trial-and-error
				rect.setAttribute('height', height);
				rect.setAttribute('style', me.settings.normalAllocationBarStyle);

				//Tool Tip
				let tool_tip = me.get_tool_tip(item)
				rect.appendChild(tool_tip);
				let index = svgGroup.childNodes.length - 1;
				svgGroup.insertBefore(rect, svgGroup.childNodes[index]);

				svgGroup.appendChild(rect);
			}

			//Line load limit
			let limit = document.createElementNS(svgns, 'line');
			limit.setAttribute('x1', item_left);
			limit.setAttribute('y1', y_load_limit);
			limit.setAttribute('x2', item_right - 0.78);
			limit.setAttribute('y2', y_load_limit);
			limit.setAttribute('stroke', fill_color);
			limit.setAttribute('stroke-width', '0.5px');
			svgGroup.appendChild(limit)

			//Text load limit
			let limit_text = document.createElementNS(svgns, 'text');
			limit_text.textContent = item.load_limit;
			limit_text.setAttribute('x', item_left + ((item_right - item_left) / 2) - me.get_text_width_center(limit_text.textContent, width_text));
			limit_text.setAttribute('y', y_load_limit - (support_text / 2));
			limit_text.setAttribute('font-size', `${width_text} px`);
			limit_text.setAttribute('font-family', font_text);
			if ((item.units_data > 1) && (units_load > 1)){
				limit_text.setAttribute('fill', 'white');
			}else{
				limit_text.setAttribute('fill', fill_color);
			}

			//Text load hours
			let load_text = document.createElementNS(svgns, 'text');
			load_text.textContent = item.load_hours;
			if (y_load < settings.barMargin){
				y_load = flt(settings.barMargin)
			}
			load_text.setAttribute('x', item_left + ((item_right - item_left) / 2) - me.get_text_width_center(load_text.textContent, width_text));
			load_text.setAttribute('y', y_load - (support_text / 2));
			load_text.setAttribute('font-size', `${width_text} px`);
			load_text.setAttribute('font-family', font_text);
			load_text.setAttribute('fill', 'black');


			//Display load text (0.65 display factor trial-and-error)
			let can_display_text = true;
			if ((item_right - item_left) < (me.get_length_text(load_text.textContent, width_text) * 0.65)){
				can_display_text = false;
			}
			if (Math.abs(y_load_limit - y_load) < settings.barMargin){
				if ((item.units_data <= 1) || (units_load <= 1)){
					can_display_text = false;
				}
			}
			if (can_display_text){
				svgGroup.appendChild(load_text);
			}

			//Display limit text (0.65 display factor trial-and-error)
			can_display_text = true;
			if ((item_right - item_left) < (me.get_length_text(limit_text.textContent, width_text)  * 0.65)){
				can_display_text = false;
			}
			if (Math.abs(y_load_limit - y_load) < settings.barMargin){
				if ((item.units_data > 1) && (units_load > 1)){
					can_display_text = false;
				}
			}
			if (can_display_text){
				svgGroup.appendChild(limit_text);
			}

			return svgGroup.childNodes.length > 0 ? svgGroup : null;
		}
	}


	get_tool_tip(item){
		const options = {timeZone: 'UTC'};
		let toolTip = document.createElementNS('http://www.w3.org/2000/svg', 'title');
		let toolTipContent = '• ' + item.content;
		if (item.shift){
			toolTipContent += '\n' + `Shift: ${this.get_shift_label(item.shift)}`;
		}
		toolTipContent += '\n' + 'Start: ' + item.start.toLocaleString('fr-FR', options);
		toolTipContent += '\n' + 'Finish: ' + item.finish.toLocaleString('fr-FR', options);
		toolTipContent += '\n' + `Load hours: ${flt(item.load_hours, 2)} h`;
		toolTipContent += '\n' + `Load limit: ${flt(item.load_limit, 2)} h`;
		toolTip.appendChild(document.createTextNode(toolTipContent));
		// toolTip.setAttribute('style', "font-size: 12px; font-family: 'system-ui', Arial;")
		return toolTip;
	}


	get_shift_label(shift){
		switch(cint(shift)){
			case 0:
				return __('Regular open day')
			case 1:
				return __('Morning shift')
			case 2:
				return __('Afternoon shift')
			case 3:
				return __('Night shift')
		}
	}


	get_text_width_center(text_content, width_text){
		return flt(this.get_length_text(text_content, width_text) / 3)
	}

	get_length_text(text_content, width_text){
		if (text_content.includes('.')){
			return flt((text_content.length - 1) * width_text + width_text / 5)
		}else{
			return flt(text_content.length * width_text)
		}
	}

	display_vertical_scaling(item){
		let loadChartView = item.loadChartView;
		let settings = loadChartView.settings;
		let document = loadChartView.ownerDocument;
		let svgns = 'http://www.w3.org/2000/svg';
		let svgGroup = document.createElementNS(svgns, 'g');
		let i = 1
		let height_hour = flt((settings.barHeight - y) / item.load_limit)
		let height_scale = 0

		while (i * height_hour < settings.barHeight){
			height_scale = (settings.barHeight - (i * height_hour))
			//Scale
			let start_line = loadChartView.getChartPosition(settings.displayedTime, settings);
			let scale = document.createElementNS(svgns, 'line');
			scale.setAttribute('x1', start_line);
			scale.setAttribute('y1', height_scale); //by default
			if (i % 10 == 0){
				scale.setAttribute('x2', start_line + 15);
			}else{
				scale.setAttribute('x2', start_line + 10);
			}
			scale.setAttribute('y2', height_scale);
			scale.setAttribute('stroke', 'black');
			scale.setAttribute('stroke-width', '0.5px');
			svgGroup.appendChild(scale)
			i++;
		}
	}


	refresh_load_chart(){
		if (this.loadChartView) {
			this.loadChartView.refresh()
		}
	}


	initialize()
	{
		if(this.ioiGanttControl){
			this.ioiGanttControl.initialize_scales(this.settings);
		}
		this.loadChartView = ioi.DlhSoft.Controls.LoadChartView.initialize(
			this.loadChartContainer,
			this.items,
			this.settings
		);
	}


	initialize_columns(){
		let columns = []
		//Task
		columns.push({ header: __('Workcenter'), width: 200, cellTemplate: function (item) {
			return item.ganttChartView.ownerDocument.createTextNode(item.content); } });
		//Description
		columns.push({ header: __('Description'), width: 200, cellTemplate: function (item) { 
			return item.ganttChartView.ownerDocument.createTextNode(item.description); } });
		//Default load limit
		columns.push({ header: __('Default load limit'), width: 100, cellTemplate: function (item) { 
			return item.ganttChartView.ownerDocument.createTextNode(item.default_load_limit); } });

		this.settings.columns = columns
	}

	initialize_scales(scales){
		if (scales){
			this.settings.scales = scales
		}
	}


	initialize_scales_zoom_level(zoom_level, min, max){
		//Zoom level
		this.settings.updateScale = 15 * 60 * 1000; //15 min
		if (flt(zoom_level) > 0)
			if (min){
				this.settings.isMouseWheelZoomEnabledMinHourWidth = cint(min);
			}
			if (max){
				this.settings.isMouseWheelZoomEnabledMaxHourWidth = cint(max);
			}
			this.settings.hourWidth = flt(zoom_level);
	}


	change_item_height(zoom){
		this.settings.itemHeight = 21 * flt(zoom);
		this.settings.barHeight = 21 * flt(zoom) - 1 * this.settings.barMargin;
		if (zoom > 15){
			this.settings.maxDisplayedUnits = 1.5 + ((zoom - 15) * 0.70) //Zoom: = 20 --> MaxDisplay = 5: (display 5 * load limit)
		}else{
			this.settings.maxDisplayedUnits = 1.5
		}
		//Change units to have log scale (factor 2)
		for (var i = 0; i < this.items.length; i++) {
			for (var j = 0; j < this.items[i].ganttChartItems.length; j++) {
				if(this.is_spec_scale(this.items[i].ganttChartItems[j])){
					let units_limit = flt(this.items[i].ganttChartItems[j].load_limit / this.items[i].default_load_limit);
					this.items[i].ganttChartItems[j].units_limit = this.get_log_scale(units_limit);
					this.items[i].ganttChartItems[j].units_data = flt(this.items[i].ganttChartItems[j].load_hours / this.items[i].default_load_limit);
					this.items[i].ganttChartItems[j].units = this.get_log_scale(this.items[i].ganttChartItems[j].units_data);
				}else{
					this.items[i].ganttChartItems[j].units = this.get_log_scale(this.items[i].ganttChartItems[j].units_data);
				}
			}
		}
	}

	get_log_scale(unit){
		if (unit > 1){
			return (this.settings.maxDisplayedUnits - ((this.settings.maxDisplayedUnits - 1) / unit))
		}else{
			return unit
		}
	}

	is_spec_scale(item){
		if (
			(item.loadChartItem.default_load_limit != item.load_limit) && 
			(item.loadChartItem.default_load_limit != 0)
		){
			return true
		}else{
			return false
		}
	}


	set_units(settings, item){
		return (settings.maxDisplayedUnits - ((settings.maxDisplayedUnits - 1)/ item.units_data)
		)
	}


	set_y_load(settings, item){
		return flt((settings.barMargin) + settings.barHeight - (1 / settings.maxDisplayedUnits) * settings.barHeight * item.units);
	}


	set_y_limit(settings){
		return flt((settings.barMargin) + (settings.barHeight * (1 - 1 / settings.maxDisplayedUnits)));
	}


	set_items(name){
		// Prepare data items
		let scheduled_load = this.get_scheduled_load(name);
		if (scheduled_load && scheduled_load.length){
			this.items = []
			let ganttChartItems = []
			for (var i = 0; i < scheduled_load.length; i++) {
				ganttChartItems = []
				for (var j = 0; j < scheduled_load[i].ganttChartItems.length; j++) {
					let scheduled_load_items = scheduled_load[i].ganttChartItems[j]
					ganttChartItems.push({
						content: scheduled_load_items.content,
						start: new Date(scheduled_load_items.start),
						finish: new Date(scheduled_load_items.finish),
						units_data: scheduled_load_items.units,
						units: scheduled_load_items.units,
						units_limit: scheduled_load_items.units,
						load_limit: scheduled_load_items.load_limit,
						load_hours: this.get_load_hours(scheduled_load_items.units, scheduled_load_items.load_limit),
						shift: scheduled_load_items.shift,
						isRelativeToTimezone: true
					})
				}
				this.items.push({
					content: scheduled_load[i].content,
					description: scheduled_load[i].description,
					default_load_limit: scheduled_load[i].default_load_limit,
					isReadOnly: false,
					ganttChartItems: ganttChartItems
				})
			}
		}
	}


	get_load_hours(units, load_limit){
		return (flt(load_limit * units, 2))
	}


	get_scheduled_load(name){
		let me = this
		let method = ''
		if (me.mode == 0){
			method = path_scheduling_production + '.ioi_scheduling_production_get_load_chart_items';
		}else if(me.mode == 1){
			method = path_scheduling_dossier + '.ioi_scheduling_dossier_get_load_chart_items';
		}else if(me.mode == 2){
			method = path_scheduling_workcenter + '.ioi_scheduling_workcenter_get_load_chart_items';
		}
		let res = null
		frappe.call({
			method: method,
			args: {"name": name},
			async: false,
			callback:function(r)	{
				res = r.message;
			}
		});
		return res
	}

	change_timeline(time_amount_day){
		silicon_ioi.scheduling.ioiGanttControl.change_timeline(this.settings, time_amount_day);
		if(this.items.length){
			let ganttChartItemsLength = this.items[0].ganttChartItems.length;
			if(ganttChartItemsLength > 0){
				let now = new Date();
				let start_date = new Date(this.items[0].ganttChartItems[0].start);
				let ganttChartItemsLength = this.items[0].ganttChartItems.length;
				let end_date = new Date(this.items[this.items.length - 1].ganttChartItems[ganttChartItemsLength - 1].finish);
				//Workcenter mode
				if (this.items.length == 1){
					for (var i = 0; i < ganttChartItemsLength; i++) {
						start_date = new Date(this.items[0].ganttChartItems[i].start);
						if (start_date > now){
							break;
						}
					}
					end_date = new Date(this.items[0].ganttChartItems[ganttChartItemsLength - 1].finish);
				}
				start_date.setDate(start_date.getDate() - (cint(time_amount_day) * 7));
				end_date.setDate(end_date.getDate() + (cint(time_amount_day) * 7));
				this.settings.timelineStart = start_date;
				this.settings.timelineFinish = end_date;
			}
		}
	}

	make_load_chart_control(
		html_timeline_field = ''
	){

		this.ioiGanttControl = new silicon_ioi.scheduling.ioiGanttControl(1);

		if (html_timeline_field){
			this.get_load_curve_timeline(this.ioiGanttControl, html_timeline_field);
		}
	}

	get_load_curve_timeline(ioiGanttControl, html_field){
		let me = this;


		me.frm.fields_dict[html_field].$wrapper.empty();
		ioiGanttControl.make_timeline_html(me.frm.fields_dict[html_field], html_field);
		me.frm.fields_dict[html_field].$wrapper.append(me.loadChartContainer);

		//Change Timeline
		if (ioiGanttControl.gantt_timeline_btn){
			ioiGanttControl.gantt_timeline_btn.onclick = () => {
				me.change_timeline(cint(ioiGanttControl.gantt_timeline_input.value));
				me.initilialize_settings_gantt(me.settings);
				me.refresh_load_chart();
			};
		}

		//Decrease Timeline
		if (ioiGanttControl.gantt_decrease_timeline_btn){
			ioiGanttControl.gantt_decrease_timeline_btn.onclick = () => {
				ioiGanttControl.decrease_timeline(
					me.loadChartView,
					cint(ioiGanttControl.gantt_timeline_input.value)
				)
				me.initilialize_settings_gantt(me.settings);
				me.refresh_load_chart();
			};
		}

		//Increase Timeline
		if (ioiGanttControl.gantt_increase_timeline_btn){
			ioiGanttControl.gantt_increase_timeline_btn.onclick = () => {
				ioiGanttControl.increase_timeline(
					me.loadChartView,
					cint(ioiGanttControl.gantt_timeline_input.value)
				)
				me.initilialize_settings_gantt(me.settings);
				me.refresh_load_chart();
			};
		}

		//Show non-working day
		if (ioiGanttControl.gantt_show_non_working_day){
			ioiGanttControl.gantt_show_non_working_day.onchange = () => {
				ioiGanttControl.initialize_scales(me.settings);
				me.refresh_load_chart();
			}
		}

		//Change Major scale
		if (ioiGanttControl.gantt_major_scale_type_select){
			ioiGanttControl.gantt_major_scale_type_select.onchange = () => {
				ioiGanttControl.update_from_selected_major_scale_type();
				ioiGanttControl.initialize_scales(me.settings);
				me.initilialize_settings_gantt(me.settings);
				me.refresh_load_chart();
			}
		}

		//Change Major format
		if (ioiGanttControl.gantt_major_scale_format_select){
			ioiGanttControl.gantt_major_scale_format_select.onchange = () => {
				ioiGanttControl.initialize_scales(me.settings);
				me.initilialize_settings_gantt(me.settings);
				me.refresh_load_chart();
			}
		}

		//Change Minor scale
		if (ioiGanttControl.gantt_minor_scale_type_select){
			ioiGanttControl.gantt_minor_scale_type_select.onchange = () => {
				ioiGanttControl.update_from_selected_minor_scale_type();
				ioiGanttControl.initialize_scales(me.settings);
				me.initilialize_settings_gantt(me.settings);
				me.refresh_load_chart();
			}
		}

		//Change Minor format
		if (ioiGanttControl.gantt_minor_scale_format_select){
			ioiGanttControl.gantt_minor_scale_format_select.onchange = () => {
				ioiGanttControl.initialize_scales(me.settings);
				me.refresh_load_chart();
			}
		}

		//Change gantt zoom level
		if (ioiGanttControl.gantt_zoom_level){
			ioiGanttControl.gantt_zoom_level.oninput = () => {
				ioiGanttControl.initialize_scales_zoom_level(
					me.settings,
					ioiGanttControl.gantt_zoom_level.value,
					ioiGanttControl.gantt_zoom_level.getAttribute('min'),
					ioiGanttControl.gantt_zoom_level.getAttribute('max')
				);
				me.initilialize_settings_gantt(me.settings);
				me.refresh_load_chart();
			}
		}

		//Change load chart zoom level
		if (ioiGanttControl.loadchart_zoom_level){
			ioiGanttControl.loadchart_zoom_level.oninput = () => {
				me.change_item_height(ioiGanttControl.loadchart_zoom_level.value);
				me.initilialize_settings_gantt(me.settings);
				me.refresh_load_chart();
			}
		}

		//Change Gantt Source
		if (ioiGanttControl.gantt_select_source_items){
			ioiGanttControl.gantt_select_source_items.onchange = () => {

			//Remove if already exist
			if(me.ioiGantt){
				me.ioiGantt.ganttChartView.remove();
			}

			if(ioiGanttControl.gantt_select_source_items.value != -1){
				me.ioiGantt = new silicon_ioi.scheduling.ioiGantt(me.frm, html_field, ioiGanttControl.gantt_select_source_items.value , me.name);
				let res = me.ioiGantt.get_items_by_workcenter(
					cint(ioiGanttControl.gantt_select_source_items.value),
					me.name
				)
				me.ioiGantt.items = me.ioiGantt.set_items(null, res)
				//Init Gantt
				me.ioiGantt.ioiLoadChart = me;
				me.ioiGantt.make_gantt_control('','','');
				me.ioiGantt.get_selected_routing_control(ioiGanttControl);
				me.ioiGantt.display_columns = ['jump', 'name'];
				me.ioiGantt.initialize_columns();
				me.ioiGantt.initialize();
				me.ioiGantt.change_timeline(1);
				me.ioiGantt.scroll_to_first_step();
				me.ioiGantt.settings.isReadOnly = true;
				me.ioiGantt.last_splitter_move = 'GanttChart';
				me.initilialize_settings_gantt(me.settings);
				me.ioiGantt.sync_timeline_load_chart();
				me.ioiGantt.refresh_gantt();

				//Display selected routing tools
				if (ioiGanttControl.group_schedule_selected_routing){
					ioiGanttControl.group_schedule_selected_routing.style.display = 'block';
				}
			}else{
				//Display selected routing tools
				if (ioiGanttControl.group_schedule_selected_routing){
					ioiGanttControl.group_schedule_selected_routing.style.display = 'none';
				}
			}

			me.refresh_load_chart();
			}
		}







	}


	initilialize_settings_gantt(settings){
		if (this.ioiGantt){
			// this.ganttChartView.copyCommonSettings(this.ioiLoadChart.settings);
			this.ioiGantt.settings.hourWidth = settings.hourWidth;
			this.ioiGantt.settings.isMouseWheelZoomEnabledMinHourWidth = settings.isMouseWheelZoomEnabledMinHourWidth,
			this.ioiGantt.settings.isMouseWheelZoomEnabledMaxHourWidth = settings.isMouseWheelZoomEnabledMaxHourWidth
			this.ioiGantt.settings.displayedTime = settings.displayedTime;
			this.ioiGantt.settings.timelineStart = settings.timelineStart;
			this.ioiGantt.settings.timelineFinish = settings.timelineFinish;
			this.ioiGantt.settings.scales = settings.scales;
			this.ioiGantt.update_splitter(this.ioiGantt.last_splitter_move)
			this.ioiGantt.refresh_gantt();
		}
	}



}

silicon_ioi.scheduling.ioiLoadChart = ioiLoadChart;