// *******************************************************************************************************************************************
// ioiReceiptPreview
// *******************************************************************************************************************************************

frappe.provide('silicon_ioi.doctype');

export class ioiReceiptPreview
{
    static path_sales_pos = 'silicon_ioi.ioi_sales.doctype.ioi_sales_pos.ioi_sales_pos';

    static header = null;
    static column_header = null;
    static detail = null;
    static footer = null;


    static nb_field = 0;
    static nb_empty_field = 0;



	static refresh_canvas_part(current_area, area, context, data, data_live, type_live, preview_width, line_spacing, idx, top, id_line = -1, compute_height = false, doctype, canvas_doc, canvas_site, canvas_division, canvas_company, child) 
	{
		if (!data) {
			return false;
		}

		let idx_found = false;

		let type = '';

		let has_multi_line = false;
		let txt_remain = '';

		let txt_x1 = 0;
		let txt_preview_width = 0;
		let txt_bold = 0;
		let txt_italic = 0;
		let txt_font_name = '';
		let txt_font_size = 0;
		let txt_text_align = 'left';

		let txt_nb_char = 0;



		for (var i = 0; i < data.length; i++) {

			type = data[i].type;


			if ((idx == i) && (current_area == area)) {
				idx_found = true;
				type = type_live;
			}

			if ((area != 'DETAIL') || ((area == 'DETAIL') && (type != 'EXTRA_ROW'))) {

				let x1 = 0;
				let y1 = 0;
				let x2 = 0;
				let y2 = 0;
				let line_width = 1;

				let text = '';
				let bold = 0;
				let italic = 0;
				let font_name = '';
				let font_size = 24;
				let text_align = 'left';
				let multi_line = 0;
				let truncated = 0;
				let nb_char_per_line = 0;
				let nb_decimal = 0;
				let is_formula = 0;

				let img_src = '';
				let img_height = 0;
				let img_width = 0;

				let ret_val = [];

				if (type == 'LINE') {

					if ((idx == i) && (current_area == area)) {
						ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_line_value(true, data_live);
					}else{
						ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_line_value(false, data[i]);
					}

					x1 = ret_val[0];
					y1 = ret_val[1];
					x2 = ret_val[2];
					y2 = ret_val[3];
					line_width = ret_val[4];

					let y = 0;
					let yy = 0;

					if (id_line == -1) {
						y = y1;
						yy = y2;
					}else{
						y = top;

						yy = y2 - (y1 - y)
					}

					if (!compute_height) {
						silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_line(context, x1, y, x2, yy, line_width);
					}

					top = y;

				}else if (type == 'RECT') {

					if ((idx == i) && (current_area == area)) {
						ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_rectangle_value(true, data_live);
					}else{
						ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_rectangle_value(false, data[i]);
					}

					x1 = ret_val[0];
					y1 = ret_val[1];
					x2 = ret_val[2];
					y2 = ret_val[3];
					line_width = ret_val[4];

					let y = 0;
					let yy = 0;

					if (id_line == -1) {
						y = y1;
						yy = y2;
					}else{
						y = top;

						yy = y2 - (y1 - y)
					}

					if (!compute_height) {
						silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_rect(context, x1, y, x2, yy, line_width);
					}

					top = y;

				}else if (type == 'TEXT') {


					if ((idx == i) && (current_area == area)) {
						ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_text_value(true, data_live);
					}else{
						ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_text_value(false, data[i]);
					}


					text = ret_val[0];
					bold = ret_val[1];
					italic = ret_val[2];
					font_name = ret_val[3];
					font_size = ret_val[4];
					text_align = ret_val[5];
					x1 = ret_val[6];
					y1 = ret_val[7];
					multi_line = ret_val[8];
					nb_char_per_line = ret_val[9];
					nb_decimal = ret_val[10];
					is_formula = ret_val[11];
					truncated = ret_val[12];

		
					if (text.trim() != '') {

						silicon_ioi.doctype.ioiReceiptPreview.nb_field = 0;
						silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field = 0;
					
						let txt = silicon_ioi.doctype.ioiReceiptPreview.canvas_text_replace(text, id_line, doctype, canvas_doc, canvas_site, canvas_division, canvas_company, child);

						if (silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field != 0) {
							if (silicon_ioi.doctype.ioiReceiptPreview.nb_field == silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field) {
								txt = '';
							}
						}

    					if (txt == 'NaN') {
							txt = '';
						}

						
						if (txt.trim() != '') {

							if (is_formula == 1) {

								try {
									
									txt = parseFloat(window.eval(txt));

									if (isNaN(txt)) {
										txt = '';
									}


								}catch(error) {
									txt = '';
								}
							}

							if (nb_decimal > 0) {


								if (!txt) {
									txt = '';
								}

								if ((txt != '') && (txt != null)) {

									if (!isNaN(txt)) {
										txt = parseFloat(txt).toFixed(nb_decimal);
									}
								}
							}

							if (truncated == 1) {

								let str = txt;

								if (str.trim() != '')
								{
									let t = 0;

									if (id_line == -1) {
										t = y1;
									}else{
										t = top;
									}

								
									let txt_trunc = str.substring(0, nb_char_per_line);

									if (!compute_height) {
										silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, t, preview_width, txt_trunc, bold, italic, font_name, font_size, text_align);
									}

									top = t;

								}

							}else{


								if ((multi_line == 0) || ((multi_line == 1) && (nb_char_per_line == 0))) {

									let y = 0;

									if (id_line == -1) {
										y = y1;
									}else{
										y = top;
									}
									
									if (!compute_height) {

                
										silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, y, preview_width, txt, bold, italic, font_name, font_size, text_align);
									}

									top = y;

								}else{

									let str = txt;

									if (str.trim() != '')
									{
										let t = 0;

										if (id_line == -1) {
											t = y1;
										}else{
											t = top;
										}


										has_multi_line = true;
										
										let txt_trunc = str.substring(0, nb_char_per_line);


										if (!compute_height) {		
											silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, t, preview_width, txt_trunc, bold, italic, font_name, font_size, text_align);
										}

										txt_remain = str.substring(nb_char_per_line, str.length);

										txt_x1 = x1;
										txt_preview_width = preview_width;
										txt_bold = bold;
										txt_italic = italic;
										txt_font_name = font_name;
										txt_font_size = font_size;
										txt_text_align = text_align;
										txt_nb_char = nb_char_per_line;

										top = t;

									}
								}
							}
						}
					}
				}else if (type == 'IMG') {

					if ((idx == i) && (current_area == area)) {
						ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_img_value(true, data_live);
					}else{
						ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_img_value(false, data[i]);
					}

					img_src = ret_val[0];
					img_height = ret_val[1];
					img_width = ret_val[2];
					text_align = ret_val[3];
					x1 = ret_val[4];
					y1 = ret_val[5];

					if (img_src.trim() != '') {

						silicon_ioi.doctype.ioiReceiptPreview.nb_field = 0;
						silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field = 0;

						let src = silicon_ioi.doctype.ioiReceiptPreview.canvas_text_replace(img_src, id_line, doctype, canvas_doc, canvas_site, canvas_division, canvas_company, child);

						if (silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field != 0) {
							if (silicon_ioi.doctype.ioiReceiptPreview.nb_field == silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field) {
								src = '';
							}
						}


						if (src.trim() != '') {

							let y = 0;

							if (id_line == -1) {
								y = y1;
							}else{
								y = top;
							}


							if (!compute_height) {

								silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_img(context, x1, y, preview_width, src, img_height, img_width, text_align);
							}

							top = y;

						}
					}
				}

				if (y1 >= top) {
					top = y1;
				}
			}

		}

		if (idx == -1) {

			if ((!idx_found) && (current_area == area)) {

				if ((document.getElementsByClassName('modal fade show')) && (document.getElementsByClassName('modal fade show').length > 0)) {

					if (document.getElementById('html_receipt_edit_detail_type')) {

						type = document.getElementById('html_receipt_edit_detail_type').value;

						let x1 = 0;
						let y1 = 0;
						let x2 = 0;
						let y2 = 0;
						let line_width = 1;
			
						let text = '';
						let bold = 0;
						let italic = 0;
						let font_name = '';
						let font_size = 24;
						let text_align = 'left';
						let multi_line = 0;
						let truncated = 0;
						let nb_char_per_line = 0;
						let nb_decimal = 0;
						let is_formula = 0;
			
						let img_src = '';
						let img_height = 0;
						let img_width = 0;
			
						let ret_val = [];
			
						if (type == 'LINE') {
			
							ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_line_value(true, data_live);
			
							x1 = ret_val[0];
							y1 = ret_val[1];
							x2 = ret_val[2];
							y2 = ret_val[3];
							line_width = ret_val[4];

							let y = 0;
							let yy = 0;

							if (id_line == -1) {
								y = y1;
								yy = y2;
							}else{
								y = top;

								yy = y2 - (y1 - y)
							}

							if (!compute_height) {
								silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_line(context, x1, y, x2, yy, line_width);
							}

							top = y;

			
						}else if (type == 'RECT') {
			
							ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_rectangle_value(true, data_live);
			
							x1 = ret_val[0];
							y1 = ret_val[1];
							x2 = ret_val[2];
							y2 = ret_val[3];
							line_width = ret_val[4];

							let y = 0;
							let yy = 0;

							if (id_line == -1) {
								y = y1;
								yy = y2;
							}else{
								y = top;

								yy = y2 - (y1 - y)
							}

							if (!compute_height) {
								silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_rect(context, x1, y, x2, yy, line_width);
							}

							top = y;
			
						}else if (type == 'TEXT') {
			
							ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_text_value(true, data_live);
			
							text = ret_val[0];
							bold = ret_val[1];
							italic = ret_val[2];
							font_name = ret_val[3];
							font_size = ret_val[4];
							text_align = ret_val[5];
							x1 = ret_val[6];
							y1 = ret_val[7];
							multi_line = ret_val[8];
							nb_char_per_line = ret_val[9];
							nb_decimal = ret_val[10];
							is_formula = ret_val[11];
							truncated = ret_val[12];
				
							if (text.trim() != '') {
			

								silicon_ioi.doctype.ioiReceiptPreview.nb_field = 0;
								silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field = 0;
			
								let txt = silicon_ioi.doctype.ioiReceiptPreview.canvas_text_replace(text, id_line, doctype, canvas_doc, canvas_site, canvas_division, canvas_company, child);

								if (silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field != 0) {
									if (silicon_ioi.doctype.ioiReceiptPreview.nb_field == silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field) {
										txt = '';
									}
								}


								if (txt.trim() != '') {

									if (is_formula == 1) {

										try {

											txt = parseFloat(window.eval(txt));

											if (isNaN(txt)) {
												txt = '';
											}
			
										}catch(error) {
											txt = '';
										}
			
									}
			

									if (nb_decimal > 0) {

										if (!txt) {
											txt = '';
										}
			
										if ((txt != '') && (txt != null)) {

											if (!isNaN(txt)) {
												txt = parseFloat(txt).toFixed(nb_decimal);
											}
										}
									}


									if (truncated == 1) {

										let str = txt;
			
										if (str.trim() != '')
										{
											let t = 0;
			
											if (id_line == -1) {
												t = y1;
											}else{
												t = top;
											}
			
										
											let txt_trunc = str.substring(0, nb_char_per_line);
			
											if (!compute_height) {
												silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, t, preview_width, txt_trunc, bold, italic, font_name, font_size, text_align);
											}
			
											top = t;
			
										}

									}else{
			
			
										if ((multi_line == 0) || ((multi_line == 1) && (nb_char_per_line == 0))) {

											let y = 0;

											if (id_line == -1) {
												y = y1;
											}else{
												y = top;
											}
					
											if (!compute_height) {
												silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, y, preview_width, txt, bold, italic, font_name, font_size, text_align);
											}

											top = y;

										}else{
				
			
											let str = txt;

											if (str.trim() != '') {

												let t = 0;

												if (id_line == -1) {
													t = y1;
												}else{
													t = top;
												}
						


												has_multi_line = true;
										
												let txt_trunc = str.substring(0, nb_char_per_line);

												if (!compute_height) {
													silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, t, preview_width, txt_trunc, bold, italic, font_name, font_size, text_align);
												}

			
												txt_remain = str.substring(nb_char_per_line, str.length);
				
												txt_x1 = x1;
												txt_preview_width = preview_width;
												txt_bold = bold;
												txt_italic = italic;
												txt_font_name = font_name;
												txt_font_size = font_size;
												txt_text_align = text_align;
												txt_nb_char = nb_char_per_line;
				
												top = t;
				
											}
										}
									}
								}
							}
			
						}else if (type == 'IMG') {
			
							ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_img_value(true, data_live);
			
							img_src = ret_val[0];
							img_height = ret_val[1];
							img_width = ret_val[2];
							text_align = ret_val[3];
							x1 = ret_val[4];
							y1 = ret_val[5];
			
							if (img_src.trim() != '') {
			
								silicon_ioi.doctype.ioiReceiptPreview.nb_field = 0;
								silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field = 0;
			
								let src = silicon_ioi.doctype.ioiReceiptPreview.canvas_text_replace(img_src, id_line, doctype, canvas_doc, canvas_site, canvas_division, canvas_company, child);

								if (silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field != 0) {
									if (silicon_ioi.doctype.ioiReceiptPreview.nb_field == silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field) {
										src = '';
									}
								}
			
			
								if (src.trim() != '') {

									let y = 0;

									if (id_line == -1) {
										y = y1;
									}else{
										y = top;
									}
			
									if (!compute_height) {
										silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_img(context, x1, y, preview_width, src, img_height, img_width, text_align); 
									}

									top = y;
			
								}
							}
						}

						if (y1 >= top) {
							top = y1;
						}
			
					}
				}
			}

		}

		if (has_multi_line) {

			if (txt_remain != '') {

				while (txt_remain.trim() != '') {

					if (line_spacing != 0) {
						top += line_spacing;
					}else{
						top += 25;
					}

		
					let txt_trunc = txt_remain.substring(0, txt_nb_char);

					if (!compute_height) {					
						silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, txt_x1, top, txt_preview_width, txt_trunc, txt_bold, txt_italic, txt_font_name, txt_font_size, txt_text_align);
					}
		
		
					txt_remain = txt_remain.substring(txt_nb_char, txt_remain.length);


				}
		
			}

		}

		if (area == 'DETAIL') {

			for (var i = 0; i < data.length; i++) {

				type = data[i].type;


				if ((idx == i) && (current_area == area)) {
					idx_found = true;
					type = type_live;
				}

				if (type == 'EXTRA_ROW') {

					let x1 = 0;
					let y1 = 0;
					let x2 = 0;
					let y2 = 0;
					let line_width = 1;

					let text = '';
					let bold = 0;
					let italic = 0;
					let font_name = '';
					let font_size = 24;
					let text_align = 'left';
					let multi_line = 0;
					let truncated = 0;
					let nb_char_per_line = 0;
					let nb_decimal = 0;
					let is_formula = 0;

					let img_src = '';
					let img_height = 0;
					let img_width = 0;

					let ret_val = [];




					if ((idx == i) && (current_area == area)) {
						ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_row_value(true, data_live);
					}else{
						ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_row_value(false, data[i]);
					}


					text = ret_val[0];
					bold = ret_val[1];
					italic = ret_val[2];
					font_name = ret_val[3];
					font_size = ret_val[4];
					text_align = ret_val[5];
					x1 = ret_val[6];
					y1 = ret_val[7];
					nb_char_per_line = ret_val[8];
					nb_decimal = ret_val[9];
					is_formula = ret_val[10];
					truncated = ret_val[11];

		
					if (text.trim() != '') {

						silicon_ioi.doctype.ioiReceiptPreview.nb_field = 0;
						silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field = 0;
					
						let txt = silicon_ioi.doctype.ioiReceiptPreview.canvas_text_replace(text, id_line, doctype, canvas_doc, canvas_site, canvas_division, canvas_company, child);

						if (silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field != 0) {
							if (silicon_ioi.doctype.ioiReceiptPreview.nb_field == silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field) {
								txt = '';
							}
						}

						if (txt == 'NaN') {
							txt = '';
						}

						
						if (txt.trim() != '') {

							if (is_formula == 1) {

								try {
									txt = parseFloat(window.eval(txt));

									if (isNaN(txt)) {
										txt = '';
									}


								}catch(error) {
									txt = '';
								}
							}

							if (nb_decimal > 0) {


								if (!txt) {
									txt = '';
								}

								if ((txt != '') && (txt != null)) {

									if (!isNaN(txt)) {
										txt = parseFloat(txt).toFixed(nb_decimal);
									}
								}
							}

							if (truncated == 1) {

								let str = txt;

								if (str.trim() != '')
								{

									if (line_spacing != 0) {
										top += line_spacing;
									}else{
										top += 25;
									}
				
									let t = top;

								
									let txt_trunc = str.substring(0, nb_char_per_line);

									if (!compute_height) {
										silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, t, preview_width, txt_trunc, bold, italic, font_name, font_size, text_align);
									}

									top = t;

								}

							}else{


								if (line_spacing != 0) {
									top += line_spacing;
								}else{
									top += 25;
								}
			
								let y = top;

								
								if (!compute_height) {
									silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, y, preview_width, txt, bold, italic, font_name, font_size, text_align);
								}

								top = y;

							}
						}
					}

					if (y1 >= top) {
						top = y1;
					}
				}

			}
		}




		return top;
	}



	static refresh_canvas_footer(current_area, area, context, data, data_live, type_live, preview_width, line_spacing, idx, top, id_line = -1, compute_height = false, doc, doctype, canvas_doc, canvas_site, canvas_division, canvas_company, child, dc_for_pay) 
	{
		if (!data) {
			return false;
		}

		let idx_found = false;

		let type = '';

		let has_multi_line = false;
		let txt_remain = '';

		let txt_x1 = 0;
		let txt_preview_width = 0;
		let txt_bold = 0;
		let txt_italic = 0;
		let txt_font_name = '';
		let txt_font_size = 0;
		let txt_text_align = 'left';

		let txt_nb_char = 0;

		let after_payment = false;
		let after_id = -1;


		for (var i = 0; i < data.length; i++) {

			type = data[i].type;

			if ((idx == i) && (area == current_area)) {
				idx_found = true;
				type = type_live;
			}

			if ((type == 'PAYMENT_DETAIL') || (type == 'PAYMENT_TOTAL')) {

				if (i < data.length-1) {
					if ((data[i+1].type != 'PAYMENT_DETAIL') && (data[i+1].type != 'PAYMENT_TOTAL')) {
						after_payment = true;
						after_id = i+1;

					}
				}
			}
		}

		let n = data.length;

		if (after_payment) {
			n = after_id;
		}

		for (var i = 0; i < n; i++) {

			type = data[i].type;

			if ((idx == i) && (area == current_area)) {
				idx_found = true;
				type = type_live;
			}

			let x1 = 0;
			let y1 = 0;
			let x2 = 0;
			let y2 = 0;
			let line_width = 1;

			let text = '';
			let bold = 0;
			let italic = 0;
			let font_name = '';
			let font_size = 24;
			let text_align = 'left';
			let multi_line = 0;
			let truncated = 0;
			let nb_char_per_line = 0;
			let nb_decimal = 0;
			let is_formula = 0;

			let img_src = '';
			let img_height = 0;
			let img_width = 0;

			let ret_val = [];

			if (type == 'LINE') {

				if ((idx == i) && (current_area == area)) {
					ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_line_value(true, data_live);
				}else{
					ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_line_value(false, data[i]);
				}

				x1 = ret_val[0];
				y1 = ret_val[1];
				x2 = ret_val[2];
				y2 = ret_val[3];
				line_width = ret_val[4];

				let y = top + y1;
				let yy = top + y2;

				if (!compute_height) {
					silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_line(context, x1, y, x2, yy, line_width);
				}

			}else if (type == 'RECT') {

				if ((idx == i) && (current_area == area)) {
					ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_rectangle_value(true, data_live);
				}else{
					ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_rectangle_value(false, data[i]);
				}

				x1 = ret_val[0];
				y1 = ret_val[1];
				x2 = ret_val[2];
				y2 = ret_val[3];
				line_width = ret_val[4];

				let y = top + y1;
				let yy = top + y2;

				if (!compute_height) {
					silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_rect(context, x1, y, x2, yy, line_width);
				}

			}else if (type == 'TEXT') {

				if ((idx == i) && (current_area == area)) {
					ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_text_value(true, data_live);
				}else{
					ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_text_value(false, data[i]);
				}


				text = ret_val[0];
				bold = ret_val[1];
				italic = ret_val[2];
				font_name = ret_val[3];
				font_size = ret_val[4];
				text_align = ret_val[5];
				x1 = ret_val[6];
				y1 = ret_val[7];
				multi_line = ret_val[8];
				nb_char_per_line = ret_val[9];
				nb_decimal = ret_val[10];
				is_formula = ret_val[11];
				truncated = ret_val[12];
	
				if (text.trim() != '') {

					silicon_ioi.doctype.ioiReceiptPreview.nb_field = 0;
					silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field = 0;
				
					let txt = silicon_ioi.doctype.ioiReceiptPreview.canvas_text_replace(text, id_line, doctype, canvas_doc, canvas_site, canvas_division, canvas_company, child);

					if (silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field != 0) {
						if (silicon_ioi.doctype.ioiReceiptPreview.nb_field == silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field) {
							txt = '';
						}
					}

					
					if (txt.trim() != '') {

						if (is_formula == 1) {

							try {

								txt = parseFloat(window.eval(txt));

								if (isNaN(txt)) {
									txt = '';
								}


							}catch(error) {
								txt = '';

							}
						}


						if (nb_decimal > 0) {

							if (!txt) {
								txt = '';
							}

							if ((txt != '') && (txt != null)) {

								if (!isNaN(txt)) {
									txt = parseFloat(txt).toFixed(nb_decimal);
								}
							}
						}


						if (truncated == 1) {

							let str = txt;

							if (str.trim() != '')
							{
								let t = 0;

								if (id_line == -1) {
									t = y1;
								}else{
									t = top;
								}

							
								let txt_trunc = str.substring(0, nb_char_per_line);

								if (!compute_height) {
									silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, t, preview_width, txt_trunc, bold, italic, font_name, font_size, text_align);
								}

								top = t;

							}
						}else{


							if ((multi_line == 0) || ((multi_line == 1) && (nb_char_per_line == 0))) {

								let y = top + y1;

								if (!compute_height) {
									silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, y, preview_width, txt, bold, italic, font_name, font_size, text_align);
								}

							}else{

								

								let str = txt;

								if (str.trim() != '')
								{

									let t = top + y1;

									has_multi_line = true;
									
									let txt_trunc = str.substring(0, nb_char_per_line);

									if (!compute_height) {
										silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, t, preview_width, txt_trunc, bold, italic, font_name, font_size, text_align);
									}

									txt_remain = str.substring(nb_char_per_line, str.length);

									txt_x1 = x1;
									txt_preview_width = preview_width;
									txt_bold = bold;
									txt_italic = italic;
									txt_font_name = font_name;
									txt_font_size = font_size;
									txt_text_align = text_align;
									txt_nb_char = nb_char_per_line;

								}
							}
						}
					}
				}
			}else if (type == 'IMG') {

				if ((idx == i) && (current_area == area)) {
					ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_img_value(true, data_live);
				}else{
					ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_img_value(false, data[i]);
				}

				img_src = ret_val[0];
				img_height = ret_val[1];
				img_width = ret_val[2];
				text_align = ret_val[3];
				x1 = ret_val[4];
				y1 = ret_val[5];

				if (img_src.trim() != '') {

					silicon_ioi.doctype.ioiReceiptPreview.nb_field = 0;
					silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field = 0;

					let src = silicon_ioi.doctype.ioiReceiptPreview.canvas_text_replace(img_src, id_line, doctype, canvas_doc, canvas_site, canvas_division, canvas_company, child);

					if (silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field != 0) {
						if (silicon_ioi.doctype.ioiReceiptPreview.nb_field == silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field) {
							src = '';
						}
					}


					if (src.trim() != '') {

						let y = top + y1;

						if (!compute_height) {

							silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_img(context, x1, y, preview_width, src, img_height, img_width, text_align);
						}

					}
				}
			}else if (type == 'PAYMENT_TOTAL') {

                if (canvas_doc) {

                    if (((dc_for_pay) && (dc_for_pay != '')) && ((canvas_doc.name) && (canvas_doc.name != '')) && ((canvas_doc.cashdesk_id) && (canvas_doc.cashdesk_id != ''))) {                

                        if ((idx == i) && (current_area == area)) {
                            ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_payment_total_value(true, data_live);
                        }else{
                            ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_payment_total_value(false, data[i]);
                        }

                        x1 = ret_val[0];
                        y1 = ret_val[1];
                        bold = ret_val[2];
                        italic = ret_val[3];
                        font_name = ret_val[4];
                        font_size = ret_val[5];

                        let t = top + y1;



                        let tot_paid = 0;



                        frappe.call({ 	method: silicon_ioi.doctype.ioiReceiptPreview.path_sales_pos + ".ioi_sales_pos_receipt_payment_detail",
                                        args: {	"name" : canvas_doc.name,
                                                "cashdesk_id" : canvas_doc.cashdesk_id,
                                                "doctype": dc_for_pay
                                        },
                                        async : false,
                                        callback:function(r){
            
                                            if (r.message.length > 0) {
            
                                                for (var i = 0; i < r.message.length; i++) {
            
                                                    if ((r.message[i].value != null) && (r.message[i].value != 0)) {
            
                                                        tot_paid += parseFloat(r.message[i].value);
														
                                                    }
                                            
                                                }

                                                t -= 20;


                                                // *********************************************************************************
                                                // Single line
                                                // *********************************************************************************
                                                if (!compute_height) {
                                                    context.moveTo(0, t);
                                                    context.lineWidth = 2;
                                                    context.lineTo(preview_width-10, t);
                                                    context.stroke();
                                                }

                                                t += 30;

                                                // *********************************************************************************
                                                // Total paid 
                                                // *********************************************************************************
                                                if (!compute_height) {
                                                    silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, t, preview_width, __("Total Paid"), bold, italic, font_name, font_size, 'left_x');														
                                                }

                                                let paid = tot_paid;

                                                if (!compute_height) {
                                                    silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, t, preview_width, paid.toFixed(2) + ' ' + canvas_doc.currency_id, bold, italic, font_name, font_size, 'right');														
                                                }



                                                if (parseFloat(canvas_doc.total_tvac) - parseFloat(tot_paid) != 0)
                                                {
                                                    t += 30;

                                                    if (parseFloat(canvas_doc.total_tvac) - parseFloat(tot_paid) > 0) {

                                                        if (!compute_height) {

                                                            silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, t, preview_width, __("Still to pay"), bold, italic, font_name, font_size, 'left_x');														
                                                
                                                            silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, t, preview_width, (parseFloat(canvas_doc.total_tvac) - parseFloat(tot_paid)).toFixed(2) + ' ' + canvas_doc.currency_id, bold, italic, font_name, font_size, 'right');														
                                                        }
                                            
                                                    }else {

                                                        if (!compute_height) {

                                                            silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, t, preview_width, __("To be returned"), bold, italic, font_name, font_size, 'left_x');														

                                                            silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, t, preview_width, (parseFloat(tot_paid) - parseFloat(canvas_doc.total_tvac)).toFixed(2) + ' ' + canvas_doc.currency_id, bold, italic, font_name, font_size, 'right');														
                                                        }

                                                    }
                                                }

                                                t += 10;

                                                if (!compute_height) {
                                                    context.moveTo(0, t);
                                                    context.lineWidth = 2;
                                                    context.lineTo(preview_width-10, t);
                                                    context.stroke();
                                                }


                                            }
                                        }
                        });
                        
            
                        t += 10;

                        top = t;


                    }
                }

			}else if (type == 'PAYMENT_DETAIL') {

                if (canvas_doc) {

                    if (((dc_for_pay) && (dc_for_pay != '')) && ((canvas_doc.name) && (canvas_doc.name != '')) && ((canvas_doc.cashdesk_id) && (canvas_doc.cashdesk_id != ''))) {

                        if ((idx == i) && (current_area == area)) {
                            ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_payment_detail_value(true, data_live);
                        }else{
                            ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_payment_detail_value(false, data[i]);
                        }

                        x1 = ret_val[0];
                        y1 = ret_val[1];
                        bold = ret_val[2];
                        italic = ret_val[3];
                        font_name = ret_val[4];
                        font_size = ret_val[5];


                        let t = top + y1;



                        let tot_paid = 0;
						let tot_cash_rounding = 0;

                        

                        frappe.call({ 	method: silicon_ioi.doctype.ioiReceiptPreview.path_sales_pos + ".ioi_sales_pos_receipt_payment_detail",
                                        args: {	"name" : canvas_doc.name,
                                                "cashdesk_id" : canvas_doc.cashdesk_id,
                                                "doctype": dc_for_pay
                                        },
                                        async : false,
                                        callback:function(r){
            
                                            if (r.message.length > 0) {
            
        
                                                for (var i = 0; i < r.message.length; i++) {
            
                                                    if ((r.message[i].value != null) && (r.message[i].value != 0)) {
            

                                                        if (!compute_height) {

                                                            silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, t, preview_width, r.message[i].value_desc, bold, italic, font_name, font_size, 'left_x');														

                                                            silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, t, preview_width, r.message[i].value.toFixed(2) + ' ' + canvas_doc.currency_id, bold, italic, font_name, font_size, 'right');														
            
                                                        }

                                                        t += 30;
            
            
                                                        tot_paid += parseFloat(r.message[i].value);
														tot_cash_rounding += parseFloat(r.message[i].cash_rounding);

														

            
            
                                                        if ((r.message[i].transaction_id != null) && (r.message[i].transaction_id != '')) {
            
                                                            if (!compute_height) {

                                                                silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1+10, t, preview_width, __('Transaction') + ': ' + r.message[i].transaction_id, bold, italic, font_name, font_size, 'left_x');														

                                                            }

                                                            t += 30;
                
                                                        }
            
                                                        if ((r.message[i].check_id != null) && (r.message[i].check_id != '')) {
            
                                                            
                                                            if (!compute_height) {

                                                                silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1+10, t, preview_width, __('Id') + ': ' + r.message[i].check_id, bold, italic, font_name, font_size, 'left_x');														

                                                            }

                                                            t += 30;
                
                                                        }
                                            
                                                    }
                                            
                                                }

                                                t -= 20;



                                                // *********************************************************************************
                                                // Single line
                                                // *********************************************************************************
                                                if (!compute_height) {
                                                    context.moveTo(0, t);
                                                    context.lineWidth = 2;
                                                    context.lineTo(preview_width-10, t);
                                                    context.stroke();
                                                }

                                                t += 30;

                                                // *********************************************************************************
                                                // Total paid 
                                                // *********************************************************************************
                                                if (!compute_height) {
                                                    silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, t, preview_width, __("Total Paid"), bold, italic, font_name, font_size, 'left_x');														
                                                }


												let paid = parseFloat(tot_paid).toString()

									
												let remain = parseFloat(canvas_doc.total_tvac) - parseFloat(tot_paid) + parseFloat(tot_cash_rounding);
									
									
                                                if (!compute_height) {
                                                    silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, t, preview_width, parseFloat(paid).toFixed(2) + ' ' + canvas_doc.currency_id, bold, italic, font_name, font_size, 'right');														
                                                }


												if (parseFloat(parseFloat(remain).toFixed(2)) != 0)
                                                {
                                                    t += 30;

                                                    if (parseFloat(canvas_doc.total_tvac) - parseFloat(tot_paid) > 0) {

                                                        if (!compute_height) {

                                                            silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, t, preview_width, __("Still to pay"), bold, italic, font_name, font_size, 'left_x');														
                                                
                                                            silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, t, preview_width, (parseFloat(canvas_doc.total_tvac) - parseFloat(tot_paid)).toFixed(2) + ' ' + canvas_doc.currency_id, bold, italic, font_name, font_size, 'right');														
                                                        }
                                            
                                                    }else {

                                                        if (!compute_height) {

                                                            silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, t, preview_width, __("To be returned"), bold, italic, font_name, font_size, 'left_x');														

                                                            silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, t, preview_width, (parseFloat(tot_paid) - parseFloat(canvas_doc.total_tvac)).toFixed(2) + ' ' + canvas_doc.currency_id, bold, italic, font_name, font_size, 'right');														
                                                        }

                                                    }
                                                }

                                                t += 10;

                                                if (!compute_height) {
                                                    context.moveTo(0, t);
                                                    context.lineWidth = 2;
                                                    context.lineTo(preview_width-10, t);
                                                    context.stroke();
                                                }


                                            }
                                        }
                        });
                        
            
                        t += 10;

                        top = t;

                        
                    }
                }
					
			}

		}

		if (after_payment) {

			top += 20;

			for (var i = after_id; i < data.length; i++) {

				type = data[i].type;
	
				if ((idx == i) && (area == current_area)) {
					idx_found = true;
					type = type_live;
				}
	
				let x1 = 0;
				let y1 = 0;
				let x2 = 0;
				let y2 = 0;
				let line_width = 1;
	
				let text = '';
				let bold = 0;
				let italic = 0;
				let font_name = '';
				let font_size = 24;
				let text_align = 'left';
				let multi_line = 0;
				let truncated = 0;
				let nb_char_per_line = 0;
				let nb_decimal = 0;
				let is_formula = 0;
	
				let img_src = '';
				let img_height = 0;
				let img_width = 0;
	
				let ret_val = [];
	
				if (type == 'LINE') {
	
					if ((idx == i) && (current_area == area)) {
						ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_line_value(true, data_live);
					}else{
						ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_line_value(false, data[i]);
					}
	
					x1 = ret_val[0];
					y1 = ret_val[1];
					x2 = ret_val[2];
					y2 = ret_val[3];
					line_width = ret_val[4];
	
					let y = top + y1;
					let yy = top + y2;
	
					if (!compute_height) {
						silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_line(context, x1, y, x2, yy, line_width);
					}

	
				}else if (type == 'RECT') {
	
					if ((idx == i) && (current_area == area)) {
						ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_rectangle_value(true, data_live);
					}else{
						ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_rectangle_value(false, data[i]);
					}
	
					x1 = ret_val[0];
					y1 = ret_val[1];
					x2 = ret_val[2];
					y2 = ret_val[3];
					line_width = ret_val[4];
	
					let y = top + y1;
					let yy = top + y2;
	
					if (!compute_height) {
						silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_rect(context, x1, y, x2, yy, line_width);
					}
	
				}else if (type == 'TEXT') {
	
					if ((idx == i) && (current_area == area)) {
						ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_text_value(true, data_live);
					}else{
						ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_text_value(false, data[i]);
					}
	
	
					text = ret_val[0];
					bold = ret_val[1];
					italic = ret_val[2];
					font_name = ret_val[3];
					font_size = ret_val[4];
					text_align = ret_val[5];
					x1 = ret_val[6];
					y1 = ret_val[7];
					multi_line = ret_val[8];
					nb_char_per_line = ret_val[9];
					nb_decimal = ret_val[10];
					is_formula = ret_val[11];
					truncated = ret_val[12];
		
					if (text.trim() != '') {
	
						silicon_ioi.doctype.ioiReceiptPreview.nb_field = 0;
						silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field = 0;
					
						let txt = silicon_ioi.doctype.ioiReceiptPreview.canvas_text_replace(text, id_line, doctype, canvas_doc, canvas_site, canvas_division, canvas_company, child);
	
						if (silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field != 0) {
							if (silicon_ioi.doctype.ioiReceiptPreview.nb_field == silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field) {
								txt = '';
							}
						}
	
						
						if (txt.trim() != '') {
	
							if (is_formula == 1) {
	
								try {
	
									txt = parseFloat(window.eval(txt));
	
									if (isNaN(txt)) {
										txt = '';
									}
	
	
								}catch(error) {
									txt = '';
	
								}
							}
	
	
							if (nb_decimal > 0) {
	
								if (!txt) {
									txt = '';
								}
	
								if ((txt != '') && (txt != null)) {
	
									if (!isNaN(txt)) {
										txt = parseFloat(txt).toFixed(nb_decimal);
									}
								}
							}
	
	
							if (truncated == 1) {
	
								let str = txt;
	
								if (str.trim() != '')
								{
									let t = 0;
	
									if (id_line == -1) {
										t = y1;
									}else{
										t = top+y1;
									}
	
								
									let txt_trunc = str.substring(0, nb_char_per_line);
	
									if (!compute_height) {
										silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, t, preview_width, txt_trunc, bold, italic, font_name, font_size, text_align);
									}
	
	
								}
							}else{
	
	
								if ((multi_line == 0) || ((multi_line == 1) && (nb_char_per_line == 0))) {
	
									let y = top + y1;
	
									if (!compute_height) {
										silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, y, preview_width, txt, bold, italic, font_name, font_size, text_align);
									}

	
								}else{
	
									
	
									let str = txt;
	
									if (str.trim() != '')
									{
	
										let t = top + y1;
	
										has_multi_line = true;
										
										let txt_trunc = str.substring(0, nb_char_per_line);
	
										if (!compute_height) {
											silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, t, preview_width, txt_trunc, bold, italic, font_name, font_size, text_align);
										}
	
										txt_remain = str.substring(nb_char_per_line, str.length);
	
										txt_x1 = x1;
										txt_preview_width = preview_width;
										txt_bold = bold;
										txt_italic = italic;
										txt_font_name = font_name;
										txt_font_size = font_size;
										txt_text_align = text_align;
										txt_nb_char = nb_char_per_line;
	
									}
								}
							}
						}
					}
				}else if (type == 'IMG') {
	
					if ((idx == i) && (current_area == area)) {
						ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_img_value(true, data_live);
					}else{
						ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_img_value(false, data[i]);
					}
	
					img_src = ret_val[0];
					img_height = ret_val[1];
					img_width = ret_val[2];
					text_align = ret_val[3];
					x1 = ret_val[4];
					y1 = ret_val[5];
	
					if (img_src.trim() != '') {
	
						silicon_ioi.doctype.ioiReceiptPreview.nb_field = 0;
						silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field = 0;
	
						let src = silicon_ioi.doctype.ioiReceiptPreview.canvas_text_replace(img_src, id_line, doctype, canvas_doc, canvas_site, canvas_division, canvas_company, child);
	
						if (silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field != 0) {
							if (silicon_ioi.doctype.ioiReceiptPreview.nb_field == silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field) {
								src = '';
							}
						}
	
	
						if (src.trim() != '') {
	
							let y = top + y1;
	
							if (!compute_height) {
	
								silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_img(context, x1, y, preview_width, src, img_height, img_width, text_align);
							}
	
						}
					}
				}
			}
				
		}
		


		if (idx == -1) {

			if ((!idx_found) && (current_area == area)) {

				if ((document.getElementsByClassName('modal fade show')) && (document.getElementsByClassName('modal fade show').length > 0)) {


					if (document.getElementById('html_receipt_edit_detail_type')) {

						type = document.getElementById('html_receipt_edit_detail_type').value;

						let x1 = 0;
						let y1 = 0;
						let x2 = 0;
						let y2 = 0;
						let line_width = 1;
			
						let text = '';
						let bold = 0;
						let italic = 0;
						let font_name = '';
						let font_size = 24;
						let text_align = 'left';
						let multi_line = 0;
						let truncated = 0;
						let nb_char_per_line = 0;
						let nb_decimal = 0;
						let is_formula = 0;
			
						let img_src = '';
						let img_height = 0;
						let img_width = 0;
			
						let ret_val = [];
			
						if (type == 'LINE') {
			
							ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_line_value(true, data_live);
			
							x1 = ret_val[0];
							y1 = ret_val[1];
							x2 = ret_val[2];
							y2 = ret_val[3];
							line_width = ret_val[4];

							let y = top + y1;
							let yy = top + y2;

							if (!compute_height) {
								silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_line(context, x1, y, x2, yy, line_width);
							}
			
						}else if (type == 'RECT') {
			
							ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_rectangle_value(true, data_live);
			
							x1 = ret_val[0];
							y1 = ret_val[1];
							x2 = ret_val[2];
							y2 = ret_val[3];
							line_width = ret_val[4];

							let y = top + y1;
							let yy = top + y2;
				
							if (!compute_height) {
								silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_rect(context, x1, y, x2, yy, line_width);
							}

			
						}else if (type == 'TEXT') {
			
							ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_text_value(true, data_live);
			
							text = ret_val[0];
							bold = ret_val[1];
							italic = ret_val[2];
							font_name = ret_val[3];
							font_size = ret_val[4];
							text_align = ret_val[5];
							x1 = ret_val[6];
							y1 = ret_val[7];
							multi_line = ret_val[8];
							nb_char_per_line = ret_val[9];
							nb_decimal = ret_val[10];
							is_formula = ret_val[11];
							truncated = ret_val[12];
				
							if (text.trim() != '') {
			

								silicon_ioi.doctype.ioiReceiptPreview.nb_field = 0;
								silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field = 0;
			
								let txt = silicon_ioi.doctype.ioiReceiptPreview.canvas_text_replace(text, id_line, doctype, canvas_doc, canvas_site, canvas_division, canvas_company, child);

								if (silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field != 0) {
									if (silicon_ioi.doctype.ioiReceiptPreview.nb_field == silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field) {
										txt = '';
									}
								}
			
								

								if (txt.trim() != '') {


									if (is_formula == 1) {

										try {

											txt = parseFloat(window.eval(txt));
		
											if (isNaN(txt)) {
												txt = '';
											}

										}catch(error) {
											txt = '';
										}
			
									}
			

									if (nb_decimal > 0) {

										if (!txt) {
											txt = '';
										}

		
										if ((txt != '') && (txt != null)) {

											if (!isNaN(txt)) {
												txt = parseFloat(txt).toFixed(nb_decimal);
											}
										}
									}


									if (truncated == 1) {

										let str = txt;
			
										if (str.trim() != '')
										{
											let t = 0;
			
											if (id_line == -1) {
												t = y1;
											}else{
												t = top;
											}
			
										
											let txt_trunc = str.substring(0, nb_char_per_line);
			
											if (!compute_height) {
												silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, t, preview_width, txt_trunc, bold, italic, font_name, font_size, text_align);
											}
			
											top = t;
			
										}

									}else{

				
				
										if ((multi_line == 0) || ((multi_line == 1) && (nb_char_per_line == 0))) {


											let y = top + y1;

											if (!compute_height) {
												silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, y, preview_width, txt, bold, italic, font_name, font_size, text_align);
											}


										}else{
				
			
											let str = txt;

											if (str.trim() != '') {

												let t = top + y1;

												has_multi_line = true;
										
												let txt_trunc = str.substring(0, nb_char_per_line);

												if (!compute_height) {
													silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, x1, t, preview_width, txt_trunc, bold, italic, font_name, font_size, text_align);
												}

			
												txt_remain = str.substring(nb_char_per_line, str.length);
				
												txt_x1 = x1;
												txt_preview_width = preview_width;
												txt_bold = bold;
												txt_italic = italic;
												txt_font_name = font_name;
												txt_font_size = font_size;
												txt_text_align = text_align;
												txt_nb_char = nb_char_per_line;
				
											}
										}
									}
								}
							}
			
						}else if (type == 'IMG') {
			
							ret_val = silicon_ioi.doctype.ioiReceiptPreview.assign_img_value(true, data_live);
			
							img_src = ret_val[0];
							img_height = ret_val[1];
							img_width = ret_val[2];
							text_align = ret_val[3];
							x1 = ret_val[4];
							y1 = ret_val[5];
			
							if (img_src.trim() != '') {
			
								silicon_ioi.doctype.ioiReceiptPreview.nb_field = 0;
								silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field = 0;
			
								let src = silicon_ioi.doctype.ioiReceiptPreview.canvas_text_replace(img_src, id_line, doctype, canvas_doc, canvas_site, canvas_division, canvas_company, child);

								if (silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field != 0) {
									if (silicon_ioi.doctype.ioiReceiptPreview.nb_field == silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field) {
										src = '';
									}
								}
			
			
								if (src.trim() != '') {

									let y = top + y1;

									if (!compute_height) {

										silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_img(context, x1, y, preview_width, src, img_height, img_width, text_align); 
									}

		
								}
							}
						}
					}

		
				}
			}

		}

		if (has_multi_line) {

			if (txt_remain != '') {

				while (txt_remain.trim() != '') {

					if (line_spacing != 0) {
						top += line_spacing;
					}else{
						top += 25;
					}

		
					let txt_trunc = txt_remain.substring(0, txt_nb_char);

					if (!compute_height) {
						silicon_ioi.doctype.ioiReceiptPreview.canvas_draw_text(context, txt_x1, top, txt_preview_width, txt_trunc, txt_bold, txt_italic, txt_font_name, txt_font_size, txt_text_align);
					}
		
		
					txt_remain = txt_remain.substring(txt_nb_char, txt_remain.length);


				}
		
			}

		}

        top += 25;


		return top;
	}

	static assign_row_value(live = false, data = null)
	{
		let text = '';
		let bold = 0;
		let italic = 0;
		let font_name = '';
		let font_size = 24;
		let text_align = 'left';
		let x1 = 0;
		let y1 = 0;
		let truncated = 0;
		let	nb_char_per_line = 0;
		let nb_decimal = 0;
		let is_formula = 0;
	

		if (live) {

            if ((data) && (data[0])) {

                text = data[0].text;
                bold = data[0].bold;
                italic = data[0].italic;
                font_name = data[0].font_name;
                font_size = data[0].font_size;
                text_align = data[0].text_align;
                x1 = data[0].x1;
                y1 = data[0].y1;
                nb_char_per_line = data[0].nb_char_per_line;
                nb_decimal = data[0].nb_decimal;
                is_formula = data[0].is_formula;
                truncated = data[0].truncated;
            }
			
		}else{

			text = data.text;
			bold = data.bold;
			italic = data.italic;
			font_name = data.font_name;
			font_size = data.font_size;
			text_align = data.text_align;
			x1 = data.x1;
			y1 = data.y1;
			nb_char_per_line = data.nb_char_per_line;
			nb_decimal = data.nb_decimal;
			is_formula = data.is_formula;
			truncated = data.truncated;
	
		}

		return [text, bold, italic, font_name, font_size, text_align, x1, y1, nb_char_per_line, nb_decimal, is_formula, truncated];
	}



	static assign_line_value(live = false, data = null)
	{
		let x1 = 0;
		let y1 = 0;
		let x2 = 0;
		let y2 = 0;
		let line_width = 0;

		if (live) {

            if ((data) && (data[0])) {

                x1 = data[0].x1;
                y1 = data[0].y1;
                x2 = data[0].x2;
                y2 = data[0].y2;
                line_width = data[0].line_width;
            }

		}else{
			x1 = data.x1;
			y1 = data.y1;
			x2 = data.x2;
			y2 = data.y2;
			line_width = data.line_width;
		}

		return [x1, y1, x2, y2, line_width];
	}

	static assign_rectangle_value(live = false, data = null)
	{
		let x1 = 0;
		let y1 = 0;
		let x2 = 0;
		let y2 = 0;
		let line_width = 0;

		if (live) {

            if ((data) && (data[0])) {

                x1 = data[0].x1;
                y1 = data[0].y1;
                x2 = data[0].x2;
                y2 = data[0].y2;
                line_width = data[0].line_width;
            }

		}else{
			x1 = data.x1;
			y1 = data.y1;
			x2 = data.x2;
			y2 = data.y2;
			line_width = data.line_width;
		}

		return [x1, y1, x2, y2, line_width];
	}

	static assign_text_value(live = false, data = null)
	{
		let text = '';
		let bold = 0;
		let italic = 0;
		let font_name = '';
		let font_size = 24;
		let text_align = 'left';
		let x1 = 0;
		let y1 = 0;
		let multi_line = 0;
		let truncated = 0;
		let	nb_char_per_line = 0;
		let nb_decimal = 0;
		let is_formula = 0;
	

		if (live) {

            if ((data) && (data[0])) {

                text = data[0].text;
                bold = data[0].bold;
                italic = data[0].italic;
                font_name = data[0].font_name;
                font_size = data[0].font_size;
                text_align = data[0].text_align;
                x1 = data[0].x1;
                y1 = data[0].y1;
                multi_line = data[0].multi_line;
                nb_char_per_line = data[0].nb_char_per_line;
                nb_decimal = data[0].nb_decimal;
                is_formula = data[0].is_formula;
                truncated = data[0].truncated;
            }
		
		}else{

			text = data.text;
			bold = data.bold;
			italic = data.italic;
			font_name = data.font_name;
			font_size = data.font_size;
			text_align = data.text_align;
			x1 = data.x1;
			y1 = data.y1;
			multi_line = data.multi_line;
			nb_char_per_line = data.nb_char_per_line;
			nb_decimal = data.nb_decimal;
			is_formula = data.is_formula;
			truncated = data.truncated;
	
		}

		return [text, bold, italic, font_name, font_size, text_align, x1, y1, multi_line, nb_char_per_line, nb_decimal, is_formula, truncated];
	}

	static assign_img_value(live = false, data = null)
	{
		let img_src = '';
		let img_height = 0;
		let img_width = 0;
		let text_align = 'left';
		let x1 = 0;
		let y1 = 0;
	
		if (live) {

            if ((data) && (data[0])) {

                img_src = data[0].img_src;
                img_height = data[0].img_height;
                img_width = data[0].img_width;
                text_align = data[0].text_align;
                x1 = data[0].x1;
                y1 = data[0].y1;
            }

		}else{

			img_src = data.img_src;
			img_height = data.img_height;
			img_width = data.img_width;
			text_align = data.text_align;
			x1 = data.x1;
			y1 = data.y1;
		}

		return [img_src, img_height, img_width, text_align, x1, y1];
	}


	static assign_payment_total_value(live = false, data = null)
	{
		let bold = 0;
		let italic = 0;
		let font_name = '';
		let font_size = 24;
		let x1 = 0;
		let y1 = 0;

		if (live) {

            if ((data) && (data[0])) {

                bold = data[0].bold;
                italic = data[0].italic;
                font_name = data[0].font_name;
                font_size = data[0].font_size;
                x1 = data[0].x1;
                y1 = data[0].y1;
            }

		}else{

			bold = data.bold;
			italic = data.italic;
			font_name = data.font_name;
			font_size = data.font_size;
			x1 = data.x1;
			y1 = data.y1;
		}

		return [x1, y1, bold, italic, font_name, font_size];
	}

	static assign_payment_detail_value(live = false, data = null)
	{
		let bold = 0;
		let italic = 0;
		let font_name = '';
		let font_size = 24;
		let x1 = 0;
		let y1 = 0;

		if (live) {

            if ((data) && (data[0])) {

                bold = data[0].bold;
                italic = data[0].italic;
                font_name = data[0].font_name;
                font_size = data[0].font_size;
                x1 = data[0].x1;
                y1 = data[0].y1;

            }

		}else{

			bold = data.bold;
			italic = data.italic;
			font_name = data.font_name;
			font_size = data.font_size;
			x1 = data.x1;
			y1 = data.y1;
		}

		return [x1, y1, bold, italic, font_name, font_size];
	}




	static canvas_draw_line(context, x1, y1, x2, y2, line_width)
	{
		context.moveTo(x1, y1);
		context.lineWidth = line_width;
		context.lineTo(x2, y2);
		context.stroke();
	}

	static canvas_draw_rect(context, x1, y1, x2, y2, line_width)
	{
		context.rect(x1, y1, (x2-x1), (y2-y1));
		context.stroke();
	}

	static canvas_draw_text(context, x1, y1, width, text, bold, italic, font_name, font_size, text_align)
	{

		let f = '';

		if (bold == 1) {
			f += 'bold';
		}

		if (italic == 1) {
			if (f != '') {
				f += ' ';
			}

			f += 'italic';
		}

		if (parseInt(font_size) == 0) {
			if (f != '') {
				f += ' ';
			}

			f += '24px';

		}else{
			if (f != '') {
				f += ' ';
			}

			f += font_size.toString() + 'px';
		}

		if (font_name.trim() == '') {
			if (f != '') {
				f += ' ';
			}

			f += 'helvetica';

		}else{
			if (f != '') {
				f += ' ';
			}

			f += font_name;
		}

		context.font = f;

		let ta = '';
		
		if (text_align == 'right_x') {
			ta = 'right';
		}else if (text_align == 'left_x') {
			ta = 'left';
		}else {
			ta = text_align;
		}

		
		context.textAlign = ta;
		
		let l = 0;

		if (text_align == 'left') {
			l = 1;
		}else if (text_align == 'center') {
			l = width / 2;
		}else if (text_align == 'right') {
			l = width - 10;
		}else if (text_align == 'left_x') {
			l = x1;
		}else if (text_align == 'right_x') {
			l = x1;
		}
		
		context.fillText(text, l, y1);		
		

	}

	static waitForImageToLoad(img)
	{
		return new Promise(resolve=>{img.onload = resolve});
	}

	static canvas_draw_img(context, x1, y1, width, img_src, img_height, img_width, text_align)
	{

		let img = document.createElement('img');


		let img_name = 'receipt_template_img_' + (Date.now()).toString();

		img.setAttribute('id', img_name);
		img.setAttribute('src', img_src);
		img.setAttribute('width', img_width);
		img.setAttribute('height', img_height);
		img.style.width = img_width.toString() + 'px';
		img.style.height = img_height.toString() + 'px';
		img.style.display = 'none';

		document.body.insertAdjacentElement('beforebegin', img);


		silicon_ioi.doctype.ioiReceiptPreview.sleep(150).then(() => {


			img = document.getElementById(img_name);
			img.style.display = 'block';

			let l = 0;

			if (text_align == 'left') {
				l = 1;
			}else if (text_align == 'center') {
				l = (width / 2) - (img_width / 2);
			}else if (text_align == 'right') {
				l = (width - 10) - img_width;
			}else if (text_align == 'left_x') {
				l = x1;
			}else if (text_align == 'right_x') {
				l = x1 - img_width;
			}

			context.drawImage(img, l, y1, img_width, img_height);

			document.getElementById(img_name).remove();

		});

	}

	static canvas_text_replace(txt, idx_line = -1, doctype, canvas_doc, canvas_site, canvas_division, canvas_company, child)
	{
		if (txt == null) {
			txt = '';
		}

		let s = txt;

		if (s.indexOf('{') != -1) {

			silicon_ioi.doctype.ioiReceiptPreview.nb_field++;

			let ch = s.substring(s.indexOf('{')+1, s.length);

			if (ch.indexOf('}') != -1) {
				let el = ch.substring(0, ch.indexOf('}'));


				let dc = '';
				let fn = '';

				if (el.indexOf('.') != -1) {
					dc = el.substring(0, el.indexOf('.'))
					fn = el.substring(el.indexOf('.')+1, el.length)


					let toreplaceby = '';

					if (dc.toUpperCase() == doctype.toUpperCase()) {
						if (canvas_doc) {
							toreplaceby = canvas_doc[fn];
						}
					}else if (dc.toUpperCase() == 'IOI SITE') {
						if (canvas_site) {
							toreplaceby = canvas_site[fn];
						}
					}else if (dc.toUpperCase() == 'IOI DIVISION') {
						if (canvas_division) {
							toreplaceby = canvas_division[fn];
						}
					}else if (dc.toUpperCase() == 'IOI COMPANY') {
						if (canvas_company) {
							toreplaceby = canvas_company[fn];
						}
					}else{
						if ((idx_line != -1) && (idx_line != -2)) {
							let childfn = '';

							for (var i = 0; i < child.length; i++) {
								if (child[i].dc == dc) {
									childfn = child[i].fieldname;
									break;
								}
							}


							if (childfn != '') {
//								if (canvas_doc[childfn][idx_line][fn]) {
									toreplaceby = canvas_doc[childfn][idx_line][fn];
/*								}else{
									toreplaceby = '';
								}
*/								
							}
						}
					}


					if ((toreplaceby == null)) { // || (toreplaceby == '')) { // || ((!isNaN(toreplaceby)) && (parseFloat(toreplaceby) == 0))) {
						toreplaceby = '';
						silicon_ioi.doctype.ioiReceiptPreview.nb_empty_field++;
					}

					txt = txt.replace('{' + el + '}', toreplaceby);

					txt = silicon_ioi.doctype.ioiReceiptPreview.canvas_text_replace(txt, idx_line, doctype, canvas_doc, canvas_site, canvas_division, canvas_company, child);
					s = txt;
				
				}
			}
		}

		if (s.indexOf('[now]') != -1) {

			s = s.replace('[now]', frappe.datetime.now_datetime().toString());
			txt = silicon_ioi.doctype.ioiReceiptPreview.canvas_text_replace(s, idx_line, doctype, canvas_doc, canvas_site, canvas_division, canvas_company, child);

		}

		return txt;
	}



	static canvas_define_height(context, preview_width, line_spacing, idx, area, data_live, type_live, doc, doctype, canvas_doc, canvas_site, canvas_division, canvas_company, child, dc_for_pay)
	{

		let top = 0;

		top = silicon_ioi.doctype.ioiReceiptPreview.refresh_canvas_part(area, 'HEADER', context, silicon_ioi.doctype.ioiReceiptPreview.header, data_live, type_live, preview_width-20, line_spacing, idx, top, -1, true, doctype, canvas_doc, canvas_site, canvas_division, canvas_company, child);
		top = silicon_ioi.doctype.ioiReceiptPreview.refresh_canvas_part(area, 'COLUMN_HEADER', context, silicon_ioi.doctype.ioiReceiptPreview.column_header, data_live, type_live, preview_width-20, line_spacing, idx, top, -1, true, doctype, canvas_doc, canvas_site, canvas_division, canvas_company, child);

		if (canvas_doc) {
			if (canvas_doc.line_detail) {
				for (var j = 0; j < canvas_doc.line_detail.length; j++) {
					top += line_spacing;
					top = silicon_ioi.doctype.ioiReceiptPreview.refresh_canvas_part(area, 'DETAIL', context, silicon_ioi.doctype.ioiReceiptPreview.detail, data_live, type_live, preview_width-20, line_spacing, idx, top, j, true, doctype, canvas_doc, canvas_site, canvas_division, canvas_company, child);

				}
			}
		}

		top += 10;

		top = silicon_ioi.doctype.ioiReceiptPreview.refresh_canvas_footer(area, 'FOOTER', context, silicon_ioi.doctype.ioiReceiptPreview.footer, data_live, type_live, preview_width-20, line_spacing, idx, top, -2, true, doc, doctype, canvas_doc, canvas_site, canvas_division, canvas_company, child, dc_for_pay);


		top += 80;

		return top;
	}

	//this static method is not use anymore
	static text_format(text_data, doc_data, id_line = -1){


		if (!text_data.text){
			return ''
		}

		if (!text_data.text.trim()) {
			return ''
		}

		let text = text_data.text;

		//Text data
		// multi_line = (text_data.multi_line ? cint(text_data.multi_line) : 0);
		let nb_char_per_line = (text_data.nb_char_per_line ? cint(text_data.nb_char_per_line) : 0);
		let nb_decimal = (text_data.nb_decimal ? cint(text_data.nb_decimal) : 0);
		let is_formula = (text_data.is_formula ? cint(text_data.is_formula) : 0);
		let truncated = (text_data.truncated ? cint(text_data.truncated) : 0);

		//Doc data
		if (!doc_data.doctype){
			return ''
		}
		let doctype = doc_data.doctype;
		let canvas_doc = (doc_data.canvas_doc ? doc_data.canvas_doc : {});
		let canvas_site = (doc_data.canvas_site ? doc_data.canvas_site: {});
		let canvas_division = (doc_data.canvas_division ? doc_data.canvas_division : {});
		let canvas_company = (doc_data.canvas_company ? doc_data.canvas_company: {});
		let child = (doc_data.child ? doc_data.child: []);

		//No child for the moment... and probably never 
		let txt = silicon_ioi.doctype.ioiReceiptPreview.canvas_text_replace(text, id_line, doctype, canvas_doc, canvas_site, canvas_division, canvas_company, child);

		if (txt == 'NaN') {
			return 'NaN';
		}

		if (!txt.trim()) {
			return ''
		}

		if (is_formula == 1) {
			txt = silicon_ioi.doctype.ioiReceiptPreview.text_format_formula(txt)
		}

		if (nb_decimal > 0) {
			txt = silicon_ioi.doctype.ioiReceiptPreview.text_format_nb_decimal(txt, nb_decimal)
		}

		if (truncated == 1) {
			txt = silicon_ioi.doctype.ioiReceiptPreview.text_format_truncated(txt, nb_char_per_line)
		}

		return (txt ? txt : '')
	}

	static text_format_formula(txt){
		try {
			txt = parseFloat(window.eval(txt));
			if (isNaN(txt)) {
				return '';
			}
			return txt
		}catch(error) {
			return '';
		}
	}


	static text_format_nb_decimal(txt, nb_decimal){
		if (!txt) {
			return '';
		}
		if ((txt != '') && (txt != null)) {
			if (!isNaN(txt)) {
				return parseFloat(txt).toFixed(nb_decimal);
			}
		}
	}

	static text_format_truncated(txt, nb_char_per_line){
		if (!txt) {
			return '';
		}
		if (txt.trim() != '')
		{
			return txt.substring(0, nb_char_per_line);
		}
	}


	static sleep(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));		
	}



}

silicon_ioi.doctype.ioiReceiptPreview = ioiReceiptPreview;
