frappe.provide("silicon_ioi");


ioi.scanning = (data) => {
	let obj;
	if (data instanceof  Object){
		obj = data;
	}else{
		frappe.throw('Scan error')
	}
	// try {
	// 	obj = JSON.parse(data)

	// }catch{
	// 	frappe.throw('Scan error')
	// }



	if (!frappe.is_mobile()){
		let scanEdit = $("#sb_main_scan_input")
		if (scanEdit.length == 1){
			scanEdit.val(obj['com.symbol.datawedge.data_string']);
			silicon_ioi.ioiScanning.decode_scan(scanEdit[0]);
		}
	}else{
		switch (frappe.get_route_str()){
			case "ioi-scan-stk-out":
				silicon_ioi?.scan_stk_out?.scan_listener(data);
				break;
			case "ioi-scan-home":
				silicon_ioi?.scan_home?.scan_listener(data);
				break;
				case "ioi-scan-delivery":
					silicon_ioi?.scan-scan_delivery?.scan_listener(data);
					break;
		}
	}
}
