import { TabulatorFull as Tabulator, Module as TabulatorModule } from 'tabulator-tables';

ioi.Tabulator = Tabulator

export default class ioiTabulator extends TabulatorModule {
	static moduleName = "custom";

	constructor(table) {
		super(table)
	}

	async create_update_profile() {
		let values = this.grid_settings_dialog.get_values()

		let selected_profile = [...this.grid_settings_dialog.fields_dict['selected_profile'].input.options].find(option => option.value === values.selected_profile);
		let select_profile_value = selected_profile.value;

		let table_id = this.table.element.id
		let user = frappe.session.user
		let doctype = cur_frm.doctype
		let columnLayout = []

		if (select_profile_value !== "DEFAULT") {

			if (select_profile_value === "NEW_PROFILE") {
				if (!values.new_profile_name) {
					frappe.msgprint({ title: __('Notification'), indicator: 'red', message: __('Please add a profile name.') });
					return;
				} else if (values.new_profile_name.toLowerCase() === "default") {
					frappe.msgprint({ title: __('Notification'), indicator: 'red', message: __('Please choose another profile name.') })
					return;
				} else {
					selected_profile = values.new_profile_name
				}
			} else {
				selected_profile = selected_profile.textContent
			}

			this.hide_show_columns(values)

			await frappe.db.exists('ioi GridRow Profile', `${doctype} • ${table_id} • ${user} • ${selected_profile}`).then(exists => {
				// Get table layout to save in DB
				columnLayout = this.table.getColumnLayout();
				columnLayout = columnLayout.filter(col => col.field != "header_menu")

				let content = JSON.stringify(columnLayout)

				if (exists) {
					frappe.db.set_value('ioi GridRow Profile', `${doctype} • ${table_id} • ${user} • ${selected_profile}`, 'content', content)
				} else {
					frappe.db.insert({ doctype: 'ioi GridRow Profile', profile_name: selected_profile, doc_type: doctype, field_name: table_id, user_name: user, content: content })
				}
			})

		} else {
			selected_profile = select_profile_value
		}

		this.update_user_settings_for_grid(selected_profile, columnLayout, table_id, doctype)
		this.grid_settings_dialog.hide();

		location.reload()
	}

	hide_show_columns(values) {
		let removed_from_hidden = this.hidden_columns && values.columns ? this.hidden_columns.filter(obj => !values.columns.includes(obj)) : this.hidden_columns;

		let columns_to_show = removed_from_hidden ? this.all_columns.filter(obj => removed_from_hidden.includes(obj.title)) : [];
		columns_to_show.map(obj => this.table.showColumn(obj.field));

		let columns_to_hide = values.columns ? this.all_columns.filter(obj => values.columns.includes(obj.title)) : [];
		columns_to_hide.map(obj => this.table.hideColumn(obj.field));
	}

	update_user_settings_for_grid(selected_profile, columnLayout, table_id, doctype) {
		let settings = frappe.model.user_settings

		if (selected_profile === "DEFAULT") {
			return settings.save(doctype, table_id, "")
		} else {
			let profiles_list = settings[doctype].GridProfiles ? [...settings[doctype].GridProfiles, table_id] : [table_id]

			let value = {};
			value['profile_name'] = `${doctype} • ${table_id} • ${frappe.session.user} • ${selected_profile}`
			value['columns'] = columnLayout

			return settings.save(doctype, 'GridProfiles', [...new Set(profiles_list)]).then(() => {
				return settings.save(doctype, table_id, value)
			})
		}
	}

	async onProfileChange(selected_profile, table) {
		table.new_profile_name_group.hide();
		this.hidden_columns = table.fields_dict['columns'].value

		if (!selected_profile.value || selected_profile.value == "NEW_PROFILE") {
			table.fields_dict['columns'].value = []
			// table.fields_dict['roles'].value = []
			table.fields_dict['is_public'].value = 1
			table.fields_dict['is_standard'].value = 0
			table.refresh()

			table.$wrapper.find('.btn-modal-primary')[0].innerText = __('Create');
			table.custom_actions[0].firstChild.classList.add('d-none')
			table.new_profile_name_group.show();
			table.columns_group.show()
			// table.roles_group.show()

		} else if (selected_profile.value == "DEFAULT") {
			this.populate_default_profile(table)
		} else {
			await frappe.db.get_doc('ioi GridRow Profile', `${cur_frm.doctype} • ${this.table.element.id} • ${frappe.session.user} • ${selected_profile.textContent}`).then(doc => {
				this.populate_custom_profile({
					columns: JSON.parse(doc.content).map(col => (col.visible === false ? (col.title !== '' ? col.title : col.field) : null)).filter(element => element !== null),
					//roles: (doc.roles).map(role => role.role),
					is_public: doc.is_public,
					is_standard: doc.is_standard,
				}, table)
			})
		}

		this.hide_show_columns(this.grid_settings_dialog.get_values())
	}

	populate_custom_profile(profile, table) {
		table.fields_dict['columns'].value = profile.columns
		// table.fields_dict['roles'].value = profile.roles
		table.fields_dict['is_public'].value = profile.is_public
		table.fields_dict['is_standard'].value = profile.is_standard
		table.refresh()

		table.$wrapper.find('.btn-modal-primary')[0].innerText = __('Update');
		table.custom_actions[0].firstChild.classList.remove('d-none')
		table.columns_group.show()
		// table.roles_group.show()
	}

	populate_default_profile(table) {
		table.fields_dict['is_public'].value = 1
		table.fields_dict['is_standard'].value = 1
		table.refresh()

		table.$wrapper.find('.btn-modal-primary')[0].innerText = __('Update');
		table.custom_actions[0].firstChild.classList.add('d-none')
		table.columns_group.hide()
		// table.roles_group.hide()
	}

	remove_current_profile(profile) {
		frappe.db.delete_doc('ioi GridRow Profile', `${cur_frm.doctype} • ${this.table.element.id} • ${frappe.session.user} • ${profile}`).then(() => {
			frappe.model.user_settings.save(cur_frm.doctype, this.table.element.id, "")
			this.grid_settings_dialog.hide()
			location.reload()
		})
	}

	async configure_dialog_for_columns_selector(gridRow_profiles) {
		let me = this

		// Get all select options
		let selectOptions = [
			{ label: __('Default'), value: 'DEFAULT' },
			...gridRow_profiles.map((profile, key) => ({ label: profile.profile_name, value: key })),
			{ label: __("Create a new profile..."), value: "NEW_PROFILE" }
		];

		// Get all columns titles
		this.all_columns = []

		this.table.getColumns().map(col => {
			let title = col.getDefinition().title
			let field = col.getField()

			if (title && field) {
				this.all_columns.push({title : title, field: field})
			} else if (field !== "header_menu" && title === "") {
				this.all_columns.push({title : field, field: field})
			}
		})

		this.grid_settings_dialog = new frappe.ui.Dialog({
			title: __("Configure Columns"),
			fields: [
				{
					'fieldtype': 'Select',
					'fieldname': 'selected_profile',
					'label': __('Select a profile'),
					'options': selectOptions,
				},
				{
					'fieldtype': 'Data',
					'fieldname': 'new_profile_name',
					'label': __('New profile name'),
				},
				{
					'fieldtype': 'MultiSelectPills',
					'fieldname': 'columns',
					'label': __('Hide Columns'),
					'options': this.all_columns.map(col => col.title),
					'default': ''
				},
				// {
				// 	'fieldtype': 'MultiSelectPills',
				// 	'fieldname': 'roles',
				// 	'label': __('Limit to roles'),
				// 	'depends_on': `eval:frappe.user_roles.includes("System Manager")`,
				// 	get_data: function (txt) {
				// 		return frappe.db.get_link_options("Role", txt, { disabled: 0 });
				// 	},
				// },
				{
					"fieldname": "section",
					"fieldtype": "Section Break",
					"label": ""
				},
				{
					"default": "0",
					"fieldname": "is_public",
					"fieldtype": "Check",
					"label": "Is public"
				},
				{
					"default": "0",
					"fieldname": "is_standard",
					"fieldtype": "Check",
					"label": "Is standard",
					'read_only': "1"
				},
				{
					"fieldname": "column_break",
					"fieldtype": "Column Break"
				}
			],
			primary_action_label: __('Update'),
			primary_action(values) {

				frappe.confirm(__('The page is about to be refreshed, do you want to continue?'),
					() => {
						me.create_update_profile()
					}, () => {
						// action to perform if No is selected
					})

			}
		});

		let table = this.grid_settings_dialog

		table.columns_group = ($(table.fields_dict.columns)[0].$input.parent().parent().parent().parent().parent())
		// table.roles_group = ($(table.fields_dict.roles)[0].$input.parent().parent().parent().parent().parent())
		table.new_profile_name_group = $(me.grid_settings_dialog.fields_dict.new_profile_name)[0].$input.parent().parent().parent();
		table.new_profile_name_group.hide();

		// Get Saved Profile
		let saved_profile = await this.get_saved_profile()

		// Create Remove Button
		table.add_custom_action(__('Remove'), function () {
			let selectedOption = me.grid_settings_dialog.fields_dict['selected_profile'].input.selectedOptions[0];
			if (selectedOption) me.remove_current_profile(selectedOption.textContent);
		}, 'btn-danger');

		// Update profile infos on profile select
		table.fields_dict['selected_profile'].input.addEventListener('change', (e) => {
			let selectedProfile = Array.from(e.target.options).find(option => option.value === e.target.value);
			me.onProfileChange(selectedProfile, table);
		});

		table.show();

		// Set Saved values
		if (saved_profile) {
			this.populate_custom_profile(saved_profile, table)
			let selectedOption = Array.from(table.fields_dict['selected_profile'].input.options).find(option => option.textContent === saved_profile.selected_profile);

			if (selectedOption) {
				table.fields_dict['selected_profile'].input.value = selectedOption.value;
			} else {
				table.fields_dict['selected_profile'].input.value = 'DEFAULT'
			}
		} else {
			this.populate_default_profile(table)
			table.fields_dict['selected_profile'].input.value = 'DEFAULT'
		}

		this.hidden_columns = me.grid_settings_dialog.fields_dict['columns'].value
	}

	async get_saved_profile() {
		return new Promise(async (resolve) => {
			let saved_profile_name = frappe.model.user_settings[cur_frm.doctype][this.table.element.id]?.profile_name || '';

			if (saved_profile_name && (await frappe.db.exists('ioi GridRow Profile', saved_profile_name))) {
				frappe.db.get_doc('ioi GridRow Profile', saved_profile_name).then((doc) => {
					resolve({
						selected_profile: doc.profile_name,
						columns: JSON.parse(doc.content)
							.map((col) => (col.visible === false ? (col.title !== '' ? col.title : col.field) : null))
							.filter((element) => element !== null),
						// roles : (doc.roles).map(role => role.role)
						is_public: doc.is_public_access,
						is_standard: doc.is_standard_access,
					});
				});
			} else {
				frappe.model.user_settings.save(cur_frm.doctype, this.table.element.id, '');

				resolve(false);
			}
		});
	}

	set_columns_layout() {
		let all_saved_profiles = frappe.model.user_settings[cur_frm.doctype].GridProfiles

		if (all_saved_profiles && all_saved_profiles.includes(this.table.element.id)) {
			let current_saved_profile = all_saved_profiles.find(el => el == this.table.element.id);
			let saved_columns = frappe.model.user_settings[cur_frm.doctype][current_saved_profile]?.columns;

			if (saved_columns && saved_columns.length) {
				this.table.setColumnLayout(saved_columns)
				saved_columns.forEach(col => { if (col.visible === false) this.table.hideColumn(col.field) })
			} else {
				// Clear the saved profile if no layout is found
				frappe.model.user_settings.save(cur_frm.doctype, this.table.element.id, "")
			}
		}
	}

	set_header_menu(options) {
		let headerMenu = []

		headerMenu.push({ label: __('Redraw'), action: () => this.table.redraw(true) })

		if (options.exportButton) {
			let export_tabulator = () => {
				let filename = document.getElementById('export_filename').value;
				let filetype = document.getElementById('export_file_type').value;

				if (filename.trim() == '') filename = "export";

				if (filetype.toUpperCase() == 'XLSX') {
					this.table.download("xlsx", `${filename}.xlsx`);
				} else {
					this.table.download("csv", `${filename}.csv`, { delimiter: ";" })
				}
			}

			headerMenu.push({ label: __('Export'), action: () => silicon_ioi.ioiCommon.export_form(export_tabulator, true) })
		}

		if (options.showProfiles == true || options.showProfiles === 'custom') {

			let profiles_settings = () => {
				frappe.db.get_list('ioi GridRow Profile', { 
					fields: ['user_name', 'profile_name', 'is_public_access', 'is_standard_access'], 
					filters: { doc_type: cur_frm.doctype, field_name: this.table.element.id, user_name: frappe.session.user }
				}).then(record => {
					this.configure_dialog_for_columns_selector(record)
				})
			}

			headerMenu.push({ label: __('Profiles settings'), action: profiles_settings })
		}

		this.table.addColumn({ field: 'header_menu', minWidth: 40, maxWidth: 40, headerHozAlign: "center", headerSort: false, resizable: false, headerMenu: headerMenu, download: false, frozen:true }, true)

		// Remove ellipsis from header menu icon
		this.table.element.querySelector('[tabulator-field="header_menu"] .tabulator-col-title').style.textOverflow = "unset"
	}

	set_auto_redraw() {
		const observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					setTimeout(() => {
						let isNotInitialized = (row) => row.initialized == false

						if (this.table.rowManager.activeRows.some(isNotInitialized)) {
							this.table.redraw(true)
						}
					}, 200)
				}
			});
		});

		observer.observe(this.table.element);
	}

	initialize() {
		this.table.on('tableBuilt', () => {
			let options = this.table.options

			if (!options.wrapLines && options.wrapLines != false) {
				this.table.element.classList.add('wrap-lines-table')
			}

			if (cur_frm && cur_frm.doctype) {
				if (options.showProfiles == true) {
					// Update layout from saved profile
					this.set_columns_layout()
				}

				if (options.showMenu || options.showProfiles == true || options.showProfiles === 'custom' || options.exportButton) {
					// Create a heading menu
					this.set_header_menu(options)
				}
			}

			if (options.autoRedraw) {
				// Used to redraw the table if it's created before being displayed
				this.set_auto_redraw()
			}
		})
	}
}

Tabulator.registerModule(ioiTabulator);