frappe.provide('silicon_ioi.doctype');

export class ioiBatchSNFrame
{
	static path_common = 'silicon_ioi.common.common';
	static path_warehouse_stock = 'silicon_ioi.ioi_wms.doctype.ioi_warehouse_stock.ioi_warehouse_stock';

	static is_dark_mode = 0;
	static bgcolor = 0;

    static site_id = '';
    static warehouse_id = '';
    static item_id = '';
	static table_batchsn;


	static load_config()
	{
		silicon_ioi.doctype.ioiBatchSNFrame.is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;
	}

	static load_batchsn_frame(html_field, is_dark_mode = null, bgcolor = null)
	{
		if ((is_dark_mode == null) || (bgcolor == null)) {
			silicon_ioi.doctype.ioiBatchSNFrame.load_config();
		}else{
			silicon_ioi.doctype.ioiBatchSNFrame.is_dark_mode = is_dark_mode;
			silicon_ioi.doctype.ioiBatchSNFrame.bgcolor = bgcolor;
		}

		if (document.getElementById('batchsn_frame_filters_panel_title')) {
			document.getElementById('batchsn_frame_filters_panel_title').remove();
		}

		if (document.getElementById('batchsn_frame_filters_locked_label')) {
			document.getElementById('batchsn_frame_filters_locked_label').remove();
		}

		if (document.getElementById('batchsn_frame_filters_locked')) {
			document.getElementById('batchsn_frame_filters_locked').remove();
		}

		if (document.getElementById('batchsn_frame_filters_archived_label')) {
			document.getElementById('batchsn_frame_filters_archived_label').remove();
		}

		if (document.getElementById('batchsn_frame_filters_archived')) {
			document.getElementById('batchsn_frame_filters_archived').remove();
		}

		if (document.getElementById('batchsn_frame_filters_location_label')) {
			document.getElementById('batchsn_frame_filters_location_label').remove();
		}

		if (document.getElementById('batchsn_frame_filters_location_id')) {
			document.getElementById('batchsn_frame_filters_location_id').remove();
		}

		if (document.getElementById('batchsn_frame_filters_location_button')) {
			document.getElementById('batchsn_frame_filters_location_button').remove();
		}

		if (document.getElementById('batchsn_frame_filters_batch_sn_label')) {
			document.getElementById('batchsn_frame_filters_batch_sn_label').remove();
		}

		if (document.getElementById('batchsn_frame_filters_batch_sn_id')) {
			document.getElementById('batchsn_frame_filters_batch_sn_id').remove();
		}

		if (document.getElementById('batchsn_frame_filters_code_ref_label')) {
			document.getElementById('batchsn_frame_filters_code_ref_label').remove();
		}

		if (document.getElementById('batchsn_frame_filters_code_ref')) {
			document.getElementById('batchsn_frame_filters_code_ref').remove();
		}

		if (document.getElementById('batchsn_frame_filters_origin_label')) {
			document.getElementById('batchsn_frame_filters_origin_label').remove();
		}

		if (document.getElementById('batchsn_frame_filters_origin')) {
			document.getElementById('batchsn_frame_filters_origin').remove();
		}

		if (document.getElementById('batchsn_frame_filters_qcref_label')) {
			document.getElementById('batchsn_frame_filters_qcref_label').remove();
		}

		if (document.getElementById('batchsn_frame_filters_qcref')) {
			document.getElementById('batchsn_frame_filters_qcref').remove();
		}

		if (document.getElementById('batchsn_frame_filters_filter_label')) {
			document.getElementById('batchsn_frame_filters_filter_label').remove();
		}

		if (document.getElementById('batchsn_frame_filters_filter')) {
			document.getElementById('batchsn_frame_filters_filter').remove();
		}

		if (document.getElementById('batchsn_frame_filters_topn_label')) {
			document.getElementById('batchsn_frame_filters_topn_label').remove();
		}

		if (document.getElementById('batchsn_frame_filters_topn')) {
			document.getElementById('batchsn_frame_filters_topn').remove();
		}

		if (document.getElementById('batchsn_frame_filters_button_search')) {
			document.getElementById('batchsn_frame_filters_button_search').remove();
		}

		if (document.getElementById('batchsn_frame_filters_button_clear')) {
			document.getElementById('batchsn_frame_filters_button_clear').remove();
		}

		if (document.getElementById('batchsn_frame_orderby_field')) {
			document.getElementById('batchsn_frame_orderby_field').remove();
		}

		if (document.getElementById('batchsn_frame_orderby_dir')) {
			document.getElementById('batchsn_frame_orderby_dir').remove();
		}

		if (document.getElementById('batchsn_frame_filters')) {
			document.getElementById('batchsn_frame_filters').remove();
		}

		let html = '';

		html += '<div style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '	<label id="batchsn_frame_filters_panel_title" style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Filters") + '</b></label>';
		html += '</div>'

		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'


		html += '<div id="batchsn_frame_filters" style="overflow-x: auto;height:180px;">';

		// Locked / Unlocked
		html += '	<div style="position: relative; top: 0px; left: 0px; width:170px;">';
		html += '		<label id="batchsn_frame_filters_locked_label" style="position: absolute; top: 0px; left: 2px;">' + __("Locked / unlocked") + '</label>';

		html += '		<select id="batchsn_frame_filters_locked" class="input-with-feedback form-control bold" style="position: absolute; top: 25px; left: 2px;"> ';
		html += '			<option value="-1">' + __("All") + '</option> ';
		html += '			<option value="1">' + __("Locked") + '</option> ';
		html += '			<option value="0">' + __("Unlocked") + '</option> ';
		html += '		</select> ';
		html += '	</div>';


		// Archived / Unarchived
		html += '	<div style="position: relative; top: 0px; left: 180px; width:170px;">';
		html += '		<label id="batchsn_frame_filters_archived_label" style="position: absolute; top: 0px; left: 2px;">' + __("Archived / unarchived") + '</label>';

		html += '		<select id="batchsn_frame_filters_archived" class="input-with-feedback form-control bold" style="position: absolute; top: 25px; left: 2px;"> ';
		html += '			<option value="-1">' + __("All") + '</option> ';
		html += '			<option value="1">' + __("Archived") + '</option> ';
		html += '			<option value="0">' + __("Unarchived") + '</option> ';
		html += '		</select> ';
		html += '	</div>';


		// Location
		html += '	<div style="position: relative; top: 0px; left: 360px; width:250px;">';
		html += '		<label id="batchsn_frame_filters_location_label" style="position: absolute; top: 0px; left: 2px;">' + __("Location") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 170px; height: 25px;"> ';
		html += '			<input id="batchsn_frame_filters_location_id" type="text" class="input-with-feedback form-control bold" value="">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 180px; width: 30px; height: 25px;"> ';
		html +='			<button id="batchsn_frame_filters_location_button" style="position:absolute; height: 30px; width: 30px; z-index:10;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Batch / SN
		html += '	<div style="position: relative; top: 0px; left: 580px; width:170px;">';
		html += '		<label id="batchsn_frame_filters_batch_sn_label" style="position: absolute; top: 0px; left: 2px;">' + __("Batch / SN") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 170px; height: 25px;"> ';
		html += '			<input id="batchsn_frame_filters_batch_sn_id" type="text" class="input-with-feedback form-control bold" value="">';
		html += '		</div>';
		html += '	</div>';

		// Code ref
		html += '	<div style="position: relative; top: 65px; left: 0px; width:170px;">';
		html += '		<label id="batchsn_frame_filters_code_ref_label" style="position: absolute; top: 0px; left: 2px;">' + __("Code ref") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 170px; height: 25px;"> ';
		html += '			<input id="batchsn_frame_filters_code_ref" type="text" class="input-with-feedback form-control bold" value="">';
		html += '		</div>';
		html += '	</div>';

		// Origin
		html += '	<div style="position: relative; top: 65px; left: 180px; width:210px;">';
		html += '		<label id="batchsn_frame_filters_origin_label" style="position: absolute; top: 0px; left: 2px;">' + __("Origin") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 170px; height: 25px;"> ';
		html += '			<input id="batchsn_frame_filters_origin" type="text" class="input-with-feedback form-control bold" value="">';
		html += '		</div>';
		html += '	</div>';

		// QCRef
		html += '	<div style="position: relative; top: 65px; left: 360px; width:210px;">';
		html += '		<label id="batchsn_frame_filters_qcref_label" style="position: absolute; top: 0px; left: 2px;">' + __("QCRef") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 210px; height: 25px;"> ';
		html += '			<input id="batchsn_frame_filters_qcref" type="text" class="input-with-feedback form-control bold" value="">';
		html += '		</div>';
		html += '	</div>';


		// Quantities
		html += '	<div style="position: relative; top: 65px; left: 580px; width:170px;">';
		html += '		<label id="batchsn_frame_filters_filter_label" style="position: absolute; top: 0px; left: 2px;">' + __("Quantities") + '</label>';

		html += '		<select id="batchsn_frame_filters_filter" class="input-with-feedback form-control bold" style="position: absolute; top: 25px; left: 2px;"> ';
		html += '			<option value="0">' + __("All") + '</option> ';
		html += '			<option value="1">' + __("In stock") + '</option> ';
		html += '			<option value="2">' + __("In bad stock") + '</option> ';
		html += '			<option value="3">' + __("Not in stock") + '</option> ';
		html += '			<option value="4">' + __("In locked stock") + '</option> ';
		html += '		</select> ';
		html += '	</div>';


		// Topn
		html += '	<div style="position: relative; top: 65px; left: 760px; width:170px;">';
		html += '		<label id="batchsn_frame_filters_topn_label" style="position: absolute; top: 0px; left: 2px;">' + __("No records") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 170px; height: 30px;"> ';
		html += '			<input id="batchsn_frame_filters_topn" type="number" step="any" class="input-with-feedback form-control bold" value="100" min="0">';
		html += '		</div>';
		html += '	</div>';



		// Search
		html += '	<div style="position: relative; top: 135px; left: 0px; width:110px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="batchsn_frame_filters_button_search" class="btn btn-default ellipsis" style="height: 30px; width: 110px;" onclick="">' + __("Search") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Clear
		html += '	<div style="position: relative; top: 135px; left: 120px; width:50px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px;">';
		html +='			<button id="batchsn_frame_filters_button_clear" title="' + __("Clear") +'" data-label="Search" class="btn btn-default ellipsis" style="height: 30px; width: 50px;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';

		html += '	<input id="batchsn_frame_orderby_field" type="hidden" value="warehouse_id">';
		html += '	<input id="batchsn_frame_orderby_dir"   type="hidden" value="asc">';

		html += '</div>';

		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'

		html += '	<div id="batchsn_frame_grid" class="table table-bordered" data-custom-grid="true"></div>';

        document.getElementById(html_field).innerHTML = html;

		silicon_ioi.doctype.ioiBatchSNFrame.batchsn_frame_grid([])

        silicon_ioi.doctype.ioiBatchSNFrame.sleep_static(300).then(() => {



			let fct_keydown = function(event) {

				if (event.keyCode == 13)
				{	return false;
				}

			};

			let fct_keyup = function(event) {

				if (event.keyCode == 13)
				{	return false;
				}

			};


			let fct_keydown_refresh = function(event) {

				if (event.keyCode == 13)
				{
					let site_id = silicon_ioi.doctype.ioiBatchSNFrame.site_id;
					let warehouse_id = silicon_ioi.doctype.ioiBatchSNFrame.warehouse_id;
					let item_id = silicon_ioi.doctype.ioiBatchSNFrame.item_id;

					silicon_ioi.doctype.ioiBatchSNFrame.batchsn_frame_do_refresh(site_id, warehouse_id, item_id);
					return false;
				}

			};

			let fct_change = function() {

				let site_id = silicon_ioi.doctype.ioiBatchSNFrame.site_id;
				let warehouse_id = silicon_ioi.doctype.ioiBatchSNFrame.warehouse_id;
				let item_id = silicon_ioi.doctype.ioiBatchSNFrame.item_id;

				silicon_ioi.doctype.ioiBatchSNFrame.batchsn_frame_do_refresh(site_id, warehouse_id, item_id);
			};

			// Locked / Unlocked
			document.getElementById('batchsn_frame_filters_locked').onchange = fct_change;
			document.getElementById('batchsn_frame_filters_locked').onkeydown = fct_keydown;
			document.getElementById('batchsn_frame_filters_locked').onkeydown = fct_keyup;

			// Archived / Unarchived
			document.getElementById('batchsn_frame_filters_archived').onchange = fct_change;
			document.getElementById('batchsn_frame_filters_archived').onkeydown = fct_keydown;
			document.getElementById('batchsn_frame_filters_archived').onkeydown = fct_keyup;

			// Location
			let fct_location_button_click = function() {

				let title = __("Select a location");
				let form_width_pixel = '710px';
				let table = "ioi Warehouse Location";
				let fields = 'name, description';
				let fields_len = '250, 380';
				let fields_desc = 'Identification, Description';
				let where = "site_id = '" + me.current_site.replaceAll("'", "''") + "' ";


				where += "and warehouse_id = '" + me.frm.doc.warehouse_id.replaceAll("'", "''") + "' "


				let order_by = 'name asc';

				let fct_callback = function (return_value) {

					document.getElementById("batchsn_frame_filters_location_id").value = return_value;
					document.getElementById('batchsn_frame_filters_location_id').focus();
					me.batchsn_frame_do_refresh();

				}

				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);
			}

			document.getElementById("batchsn_frame_filters_location_button").onclick = fct_location_button_click;
			document.getElementById("batchsn_frame_filters_location_button").onkeydown = fct_keydown;
			document.getElementById('batchsn_frame_filters_location_id').onkeydown = fct_keydown_refresh;
			document.getElementById('batchsn_frame_filters_location_id').onkeyup = fct_keyup;

			// Batch SN
			document.getElementById('batchsn_frame_filters_batch_sn_id').onkeydown = fct_keydown_refresh;
			document.getElementById('batchsn_frame_filters_batch_sn_id').onkeyup = fct_keyup;


			// Code Ref
			document.getElementById('batchsn_frame_filters_code_ref').onkeydown = fct_keydown_refresh;
			document.getElementById('batchsn_frame_filters_code_ref').onkeyup = fct_keyup;


			// Origin
			document.getElementById('batchsn_frame_filters_origin').onkeydown = fct_keydown_refresh;
			document.getElementById('batchsn_frame_filters_origin').onkeyup = fct_keyup;

			// QC ref
			document.getElementById('batchsn_frame_filters_qcref').onkeydown = fct_keydown_refresh;
			document.getElementById('batchsn_frame_filters_qcref').onkeyup = fct_keyup;


			// Filter
			document.getElementById('batchsn_frame_filters_filter').onchange = fct_change;
			document.getElementById('batchsn_frame_filters_filter').onkeydown = fct_keydown;
			document.getElementById('batchsn_frame_filters_filter').onkeyup = fct_keyup;


			// Top N
			document.getElementById('batchsn_frame_filters_topn').onkeydown = fct_keydown_refresh;
			document.getElementById('batchsn_frame_filters_topn').onkeyup = fct_keyup;

			let fct_button_search = function () {
                let site_id = silicon_ioi.doctype.ioiBatchSNFrame.site_id;
				let warehouse_id = silicon_ioi.doctype.ioiBatchSNFrame.warehouse_id;
				let item_id = silicon_ioi.doctype.ioiBatchSNFrame.item_id;

                silicon_ioi.doctype.ioiBatchSNFrame.batchsn_frame_do_refresh(site_id, warehouse_id, item_id);
			};

			let fct_button_clear = function () { silicon_ioi.doctype.ioiBatchSNFrame.batchsn_frame_do_clear();	 };

			document.getElementById('batchsn_frame_filters_button_search').onclick = fct_button_search;
			document.getElementById('batchsn_frame_filters_button_clear').onclick = fct_button_clear;

			let site_id = silicon_ioi.doctype.ioiBatchSNFrame.site_id;
			let warehouse_id = silicon_ioi.doctype.ioiBatchSNFrame.warehouse_id;
			let item_id = silicon_ioi.doctype.ioiBatchSNFrame.item_id;

			silicon_ioi.doctype.ioiBatchSNFrame.batchsn_frame_do_refresh(site_id, warehouse_id, item_id)
		});

	}

	static sleep_static(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	static batchsn_frame_grid()
	{
		let let_empty_if_zero = (cell, formatterParams, onRendered) => {
			if (cell.getValue() != 0) {
				return cell.getValue()
			}
		}

		let convert_number_to_checkbox = (cell, formatterParams, onRendered) => {
			return `<div class="d-flex justify-content-center align-items-center" style="height:100%;"><input type="checkbox" ${cell.getValue() == 1 ? "checked" : ""} disabled></div>`;
		}

		let convert_loc = (cell, formatterParams, onRendered) => {
			if (cell.getValue() != null) {
				let s = cell.getValue();
				s = s.substring(s.indexOf('•')+1, s.length);
				s = s.trim();

				return s
			}
		}

		let set_bg_color = (cell, formatterParams, onRendered) => {
			if (cell.getValue() != null) {
				if (cell.getColumn().getField() == "matured") {
					if (cell.getRow().getData().bgcolor_matured != "") {
						cell.getElement().style.backgroundColor = bgcolor_matured
					}
				} else if (cell.getColumn().getField() == "sales_expire") {
					if (cell.getRow().getData().bgcolor_sales_expire != "") {
						cell.getElement().style.backgroundColor = bgcolor_sales_expire
					}
				} else if (cell.getColumn().getField() == "end_of_warranty") {
					if (cell.getRow().getData().bgcolor_end_of_warranty != "") {
						cell.getElement().style.backgroundColor = bgcolor_end_of_warranty
					}
				}
			}
		}

		silicon_ioi.doctype.ioiBatchSNFrame.table_batchsn = new ioi.Tabulator("#batchsn_frame_grid", {
			maxHeight: 200,
			data: [],
			layout: "fitColumns",
			showProfiles: true,
			movableColumns: true,
			resizableColumns: true,
			autoRedraw: true,
			columns: [
				{ title: __("Loc"), field: "location_id", minWidth:80, formatter: convert_loc },
				{ title: __("Batch / SN"), field: "batch_sn_id", minWidth:200, formatter: 'link', formatterParams: { labelField: 'batch_sn_id', urlPrefix: '/app/ioi-batch-sn/', urlField: 'batch_sn_id', target: '_blank'}},
				{ title: __("Stock qty"), field: "q_stock", minWidth:120, bottomCalc:"sum", hozAlign: 'right', formatter: let_empty_if_zero, bottomCalcFormatter: let_empty_if_zero },
				{ title: __("Code ref"), field: "code_ref", minWidth:150 },
				{ title: __("Origin"), field: "origin", minWidth:150 },
				{ title: __("QC ref"), field: "qcref", minWidth:150 },
				{ title: __("Lck."), field: "batch_sn_locked", minWidth:80, formatter: convert_number_to_checkbox },
				{ title: __("Arc."), field: "batch_sn_archived", minWidth:80, formatter: convert_number_to_checkbox },
				{ title: __("Unit"), field: "unit_id", minWidth:100 },
				{ title: __("Reserved qty"), field: "q_reserved", minWidth:150, bottomCalc:"sum", hozAlign: 'right', formatter: let_empty_if_zero, bottomCalcFormatter: let_empty_if_zero },
				{ title: __("To check qty"), field: "q_tocheck", minWidth:150, bottomCalc:"sum", hozAlign: 'right', formatter: let_empty_if_zero, bottomCalcFormatter: let_empty_if_zero },
				{ title: __("Packed qty"), field: "q_packed", minWidth:130, bottomCalc:"sum", hozAlign: 'right', formatter: let_empty_if_zero, bottomCalcFormatter: let_empty_if_zero },
				{ title: __("Bad qty"), field: "q_bad", minWidth:130, bottomCalc:"sum", hozAlign: 'right', formatter: let_empty_if_zero, bottomCalcFormatter: let_empty_if_zero },
				{ title: __("Expiration"), field: "expiration", minWidth:130 },
				{ title: __("Matured"), field: "matured", minWidth:130, formatter: set_bg_color },
				{ title: __("Sales expire"), field: "sales_expire", minWidth:130, formatter: set_bg_color },
				{ title: __("End of warranty"), field: "end_of_warranty", minWidth:150 , formatter: set_bg_color },
			],
		});

		silicon_ioi.doctype.ioiBatchSNFrame.table_batchsn.on("tableBuilt", () => {
			silicon_ioi.doctype.ioiBatchSNFrame.table_batchsn.footerManager.element.setAttribute('data-custom-grid-bottom', 'true')
		})

		silicon_ioi.doctype.ioiBatchSNFrame.table_batchsn.on("headerClick", function (e, column) {
			let ordered_field = document.getElementById('batchsn_frame_orderby_field')
			ordered_field.value = column.getField()

			let ordered_dir = document.getElementById('batchsn_frame_orderby_dir')
			ordered_dir.value = silicon_ioi.doctype.ioiBatchSNFrame.table_batchsn.getSorters()[0].dir

			document.getElementById('batchsn_frame_filters_button_search').click();
		});
	}


	static batchsn_frame_do_refresh(site_id = '', warehouse_id = '', item_id = '')
	{
		silicon_ioi.doctype.ioiBatchSNFrame.site_id = site_id;
		silicon_ioi.doctype.ioiBatchSNFrame.warehouse_id = warehouse_id;
		silicon_ioi.doctype.ioiBatchSNFrame.item_id = item_id;

		if (item_id != '') {

			let method = silicon_ioi.doctype.ioiBatchSNFrame.path_warehouse_stock + '.ioi_warehouse_stock_get_batchsn_result'

			frappe.call({  	method: method,
							args: {	"site_id": silicon_ioi.doctype.ioiBatchSNFrame.site_id,
									"item_id": silicon_ioi.doctype.ioiBatchSNFrame.item_id,
									"warehouse_id": silicon_ioi.doctype.ioiBatchSNFrame.warehouse_id,
									"locked": document.getElementById("batchsn_frame_filters_locked").value,
									"archived": document.getElementById("batchsn_frame_filters_archived").value,
									"location_id": document.getElementById("batchsn_frame_filters_location_id").value,
									"batch_sn_id": document.getElementById("batchsn_frame_filters_batch_sn_id").value,
									"code_ref": document.getElementById("batchsn_frame_filters_code_ref").value,
									"origin": document.getElementById("batchsn_frame_filters_origin").value,
									"qcref": document.getElementById("batchsn_frame_filters_qcref").value,
									"filter": document.getElementById("batchsn_frame_filters_filter").value,
									"topn": document.getElementById("batchsn_frame_filters_topn").value,
									"by_location" : 0,
									"sort_by_clicking": 0,
									"custom_sort" : '',
									"orderby_field": document.getElementById("batchsn_frame_orderby_field").value,
									"orderby_dir": document.getElementById("batchsn_frame_orderby_dir").value
								},
							async: false,
							callback:function(r)	{
								if (r.message.length > 0) {
									if (silicon_ioi.doctype.ioiBatchSNFrame.table_batchsn.initialized) {
										silicon_ioi.doctype.ioiBatchSNFrame.table_batchsn.replaceData(r.message)
									} else {
										silicon_ioi.doctype.ioiBatchSNFrame.table_batchsn.on('tableBuilt', () => {
											silicon_ioi.doctype.ioiBatchSNFrame.table_batchsn.replaceData(r.message)
										})
									}
								} else {
									if (silicon_ioi.doctype.ioiBatchSNFrame.table_batchsn.getData().length) {
										silicon_ioi.doctype.ioiBatchSNFrame.table_batchsn.clearData()
									}
								}
							}
				});
		}
	}

	static batchsn_frame_do_clear()
	{
		if (document.getElementById('batchsn_frame_filters_locked')) {
			document.getElementById('batchsn_frame_filters_locked').selectedIndex = 0;
			document.getElementById('batchsn_frame_filters_archived').selectedIndex = 0;

			document.getElementById('batchsn_frame_filters_location_id').value = '';

			document.getElementById('batchsn_frame_filters_batch_sn_id').value = '';


			document.getElementById('batchsn_frame_filters_code_ref').value = '';

			document.getElementById('batchsn_frame_filters_origin').value = '';
			document.getElementById('batchsn_frame_filters_qcref').value = '';
			document.getElementById('batchsn_frame_filters_filter').selectedIndex = 0;

			document.getElementById('batchsn_frame_filters_topn').value = '100';

			silicon_ioi.doctype.ioiBatchSNFrame.sleep_static(100).then(() => {
				silicon_ioi.doctype.ioiBatchSNFrame.table_batchsn.clearSort()
				silicon_ioi.doctype.ioiBatchSNFrame.table_batchsn.clearData();
			})
		}
	}
}

silicon_ioi.doctype.ioiBatchSNFrame = ioiBatchSNFrame;
