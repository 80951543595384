

class ioiAttachments extends frappe.ui.form.Attachments{
    remove_attachment(fileid, callback) {
        super.remove_attachment(fileid, callback).then((r) => {
            if (document.getElementById('file_bt_search')){
                document.getElementById('file_bt_search').click();
            }
        })
    }
}

$(document).ready(() => {
	frappe.ui.form.Attachments = ioiAttachments;
})