// import { ReportBro } from "reportbro-designer"
import { ReportBro } from "reportbro-designer"

frappe.provide("ioi_report_builder")

const system_date_fmt = 'yyyy-MM-dd'//frappe.datetime.get_user_fmt().replace("mm", "MM").replace("Y", "y")
const system_time_fmt = 'HH:mm:ss'//frappe.datetime.get_user_time_fmt()+
const system_datetime_fmt = system_date_fmt + ' ' + system_time_fmt

function load_reportbro_lib(){
	return new Promise((resolve) => {
		var asset_dir = "assets/silicon_ioi/node_modules/reportbro-designer/src/";
		frappe.require(
			[
				asset_dir + "commands/AddDeleteParameterCmd.js",
			],
			resolve
		);
	});
}

ioi_report_builder.ioiReportBro = class ioiReportBro {
	get_doc_name() {
		routes = frappe.get_route()

		if (routes && routes.length == 2) {
			return routes[1]
		}
		return ''
	}
	constructor(page) {
		this.page = page
		this.reference_document = {}
		this.reference_document_name = frappe.route_options && frappe.route_options.docname;
		this.ioi_report = {}
	}

	refresh() {

		this.reference_doctype = this.ioi_report.doc_type
		this.reference_document_name = this.reference_document_name || this.ioi_report.test_document

		if (Object.keys(this.ioi_report).length) {
			frappe.model.with_doctype(this.reference_doctype, () => {

				if (this.reference_document_name) {

					this.get_reference_document().then(() => {
						this.show();
					})
				} else {
					this.show();
				}
			});
		} else {
			this.make_new_report()
		}

	}

	async get_reference_document(res) {
		await frappe.model.with_doc(this.reference_doctype, this.reference_document_name, () => {
			this.reference_document = frappe.model.get_doc(this.reference_doctype, this.reference_document_name)
		})
	}
	make_search_object_id_control(){
		let f;
		const objects = () => {
			return ioi.report.reportbro?.getDocElements().map((el) => {
				return {label: ioi.report.reportbro.getDocElementById(el.id)?.name, value: el.id}
			}) || []
		}

		let df ={
			label: "Search object",
			fieldtype: "Autocomplete",
			placeholder: __("Object ID"),
			fieldname: "search_object",
			options: objects(),
			onchange: (v) => {
				if (cint(f.value)){
					ioi.report.reportbro.deselectAll()
					ioi.report.reportbro.selectObject(cint(f.value));
					f.set_value("")
				}
			},
		};

		f = frappe.ui.form.make_control({
			df: df,
			parent: $(".custom-actions.hidden-xs.hidden-md"),
			only_input: true,
		});

		let parent = f.$wrapper.parent();
		parent.prepend(f.$wrapper);

		f.$wrapper.width(200);
		f.$wrapper.css("margin","auto");
		f.$wrapper.css("margin-right","15px");

		f.refresh()

		f.$wrapper.find('input').on("focus",() =>{
			ioi.report.reportbro.deselectAll()
		})
	}

	make_language_select_control(){
		let df = {
			fieldname: "preview_language",
			label: __("Language"),
			fieldtype: "Select",
			options: [	{
							label: 'Language: Deutsch', value: 'de'
						},
						{
							label: 'Language: English', value: 'en'
						},
						{
							label: 'Language: Español', value: 'es'
						},
						{
							label: 'Language: Français', value: 'fr'
						},
						{
							label: 'Language: Nederlands', value: 'nl'
						}]
		}

		let f = frappe.ui.form.make_control({
			df: df,
			parent: $(".custom-actions.hidden-xs.hidden-md"),
			only_input: true,
		});
		f.set_value(frappe.boot.lang);
		let parent = f.$wrapper.parent();
		parent.prepend(f.$wrapper);

		f.$wrapper.width(200);
		f.$wrapper.css("margin","auto");
		f.refresh()
	}

	async show() {

		$("[data-page-route='ioi-report-builder'] .layout-main-section").append("<div id='reportbro'></div>")

		// TODO: Add additionnal dates and numbers based on system settings and document specificities
		let me = this;
		const number_fmt = "#,##0.00"
		let currency_symbol = "€";
		if ( this.reference_document?.currency ){
			currency_symbol = (await frappe.db.get_value("Currency","eur","symbol")).message.symbol
		}

		this.currency_fmt = `${number_fmt} ${currency_symbol}`
		this.number_fmt = number_fmt
		this.sidebar_menu_report = 0;
		this.page.add_menu_item(__("Save"), function () {
			if (ioi.report.reportbro.isModified()) {
				ioi.report.reportbro.save();
			}
		}, true, {
			shortcut: "Ctrl+S",
			condition: () => ioi.report.reportbro.isModified()
		});

		frappe.call({
			method: "frappe.client.get_value",
			type: "GET",
			args: {
				doctype: 'ioi User',
				filters: { 'name': frappe.session.user },
				fieldname: 'sidebar_menu_report'
			},
			async: false,
			callback: function (r) {
				me.sidebar_menu_report = r.message.sidebar_menu_report;
			}
		});

		this.reportbro = new ReportBro(document.getElementById("reportbro"), {
			locale: locale_reportBro,
			showPlusFeatures: true,
			showPlusFeaturesInfo: false,
			reportServerHeaders: {
				"X-Frappe-CSRF-Token": frappe.csrf_token,
				"ioiReportName": encodeURIComponent(ioi.report.ioi_report.name),
				"prevLanguage": frappe.boot.lang,
				"previewMode": 1
			},
			reportServerUrl: `/api/method/silicon_ioi.ioi_system.doctype.ioi_report.ioi_report.run`,
			saveCallback: save,
			requestCallback: requestCallback,
			patternAdditionalDates: [
				{
					name: system_date_fmt,
					description: `eg. ${frappe.datetime.str_to_user("2050-12-31")}`
				}
			],
			patternDates: [
				{
					name: system_date_fmt,
					description: `eg. ${frappe.datetime.str_to_user("2050-12-31")}`
				}
			],
			patternAdditionalNumbers: [
				{
					name: this.currency_fmt,
					description: `eg. ${format_currency("1000")}`
				},
				{
					name: this.number_fmt,
					description: `eg. ${format_number("1000", "# ###,##")}`
				},
			],
			allow_local_image: true,
			allow_external_image: true,
			additionalFonts: [
				{ name: 'Archivo', value: 'archivo' },
				{ name: 'Archivo-Thin', value: 'archivo_thin' },
				{ name: 'Arimo', value: 'arimo' },
				{ name: 'Cormorant Garamond', value: 'cormorant_garamond' },
				{ name: 'Courier Prime', value: 'courier_prime' },
				{ name: 'DejaVu', value: 'dejavusans' },
				{ name: 'DejaVu Condensed', value: 'dejavusanscondensed' },
				{ name: 'DejaVu Serif', value: 'dejavuserif' },
				{ name: 'DejaVu Serif Condensed', value: 'dejavuserifcondensed' },
				{ name: 'Inconsolata', value: 'inconsolata' },
				{ name: 'Lato', value: 'lato' },
				{ name: 'Montserrat', value: 'montserrat' },
				{ name: 'Neuto', value: 'neuton' },
				{ name: 'Noto Sans', value: 'noto_sans' },
				{ name: 'Nunito Sans', value: 'nunito_sans' },
				{ name: 'Open Sans', value: 'opensans' },
				{ name: 'Roboto', value: 'roboto' },
				{ name: 'Source Sans Pro', value: 'sourcesanspro' },
				{ name: 'Tinos', value: 'tinos' },
			],
			patternLocale: frappe.boot.lang,
			highlightUnusedParameters: true,
			patternCurrencySymbol: currency_symbol,
			adminMode: 1,// frappe.boot.developer_mode,
			menuShowDebug: frappe.boot.developer_mode,
			menuSidebar: this.sidebar_menu_report
		});

		this.reportbro.getDocument().zoomLevels = [5, 10, 25, 50, 75, 100, 150, 200, 250, 300, 350, 400];

		//$("#reportbro").css("height","940px");
		this.params_to_create = [];
		this.params_state_on_load = []
		if (this.reportbro && this.ioi_report.report) {
			let rpt = JSON.parse(this.ioi_report.report);
			this.params_state_on_load = rpt.parameters;

			rpt.parameters.forEach(elem => {
				if (elem.name.indexOf('__') > 0 || elem.eval || elem.name.startsWith('_') ) {
					let exist = false;

					for (var i = 0; i < this.params_to_create.length; i++) {
						if (this.params_to_create[i]["name"] == elem.name) {
							exist = true;
							break;
						}
					}
					if (!exist) {
						this.params_to_create.push(elem)
					}
				}
			})
			if (this.ioi_report.data_source.toUpperCase() != 'SCRIPT'){
				rpt.parameters = [];
			}
			this.update_report_styles(rpt);

			this.reportbro.load(rpt);


			// let splitter = `<div id="rbro_main_panel_sizer2" style="float:left; overflow-x: auto; width: 5px; left: auto; height:100%; resize:horizontal; background: #C5C5C5; cursor: ew-resize; "></div>`
			// $('#rbro_main_panel_sizer2').remove()
			// $('#rbro_document_panel')[0].insertAdjacentHTML ("beforebegin", splitter);
		}

		this.add_parameters_from_data_source();
		this.check_if_user_exists();

		this.render_datasource_parameters();

		this.make_language_select_control();
		this.make_search_object_id_control();
	}

	render_datasource_parameters(){
		setTimeout(() => {
			let elements = $("#rbro_menu_item_children0_parameters .rbroMenuArrow.rbroIcon-arrow-right").filter(function () {

				var $this = $(this);
				return $this.css("display") != "none"
					;
			});

			for(var idx=0; idx < elements.length; idx++ ){
				elements[idx].parentElement.childNodes[0].style.color = 'lightcoral';
				elements[idx].parentElement.childNodes[0].style.fontWeight = 'bold';
			}
		}, 1000);
	}


	check_if_user_exists() {
		let currentUser = frappe.session.user

		frappe.db.exists("ioi User", currentUser).then(exist => {
			if (exist) {
				this.update_width_settings(currentUser);
			} else {
				frappe.db.insert({
					doctype: 'ioi User',
					user_id: currentUser,
					detail_panel_size: 390
				}).then(this.update_width_settings(currentUser))
			}
		})
	}

	create_parameter(element, parent, key){
		let rbro_key_param = (parent + key).replaceAll('children.','')
		// split rbro_key_param
		if (!ioi.report.reportbro.getParameterByName(rbro_key_param)){
			let param = this.get_field_object({
				fieldtype: element[key].type,
				fieldname: rbro_key_param
			})
			ioi.report.reportbro.createParameter(param)
		}
	}

	refresh_parameters(){
		let parameters = ioi.report.reportbro.getParameters();
		for (let i in parameters){
			if(!["page_count", "page_number"].includes(parameters[i].name)){
				ioi.report.reportbro.deleteParameter(parameters[i])
			}
		}
		let meta = ioi.report.script_meta;
		for (let k in meta){
			meta[k].id = ioi.report.reportbro.getUniqueId();
			ioi.report.reportbro.createParameter(meta[k])
		}
		this.render_datasource_parameters();
		ioi.report.reportbro.setModified(true);
	}

	add_btn_refresh_parameters_from_script(){
		let me = this;
		let divContainer;

		if (document.getElementById("custom_div_container")){
			divContainer = document.getElementById("custom_div_container");
		}else{
			divContainer = document.createElement("div");
			divContainer.id = 'custom_div_container';
			divContainer.style.height = "45px"
			divContainer.style.width = "90px"
			divContainer.style.display = "flex"
		}


		//divContainer.style.left = "45px"
		divContainer.insertAdjacentHTML("afterbegin",
			`<div><button id="rbro_refresh_parameters_script" class="rbroButton rbroMenuButton" title="${__('Refresh parameters')}">
				<span>
				<svg class="icon  icon-md" style="cursor: pointer;">
				<use class="" href="#icon-refresh"></use>
				</svg>
				</span>
			</button></div>`);

			const rbroToolButtonContainer = document.querySelector(".rbroToolButtonContainer")
			rbroToolButtonContainer.before(divContainer);

		const refreshParametersBtn = document.getElementById('rbro_refresh_parameters_script');
		refreshParametersBtn.onclick = function () {
			me.refresh_parameters()
		}
	}

	update_width_settings(currentUser) {

		let detailPanelWidthValue = 0
		let me = this;

		const rbroDetailPanel = document.getElementById('rbro_detail_panel')
		const rbroDocumentPanel = document.getElementById('rbro_document_panel')
		const rbroMainPanel = document.getElementById('rbro_main_panel')

		frappe.db.get_value("ioi User", currentUser, 'detail_panel_size').then(r => {
			if (r.message) {
				detailPanelWidthValue = r.message.detail_panel_size > 0 ? r.message.detail_panel_size : 390
				resizeMainPanel()
			}
		})

		let widthSetting;
		if (document.getElementById("custom_div_container")){
			widthSetting = document.getElementById("custom_div_container");
		}else{
			widthSetting = document.createElement("div");
			widthSetting.id = 'custom_div_container';
			widthSetting.style.height = "45px"
			widthSetting.style.width = "90px"
			widthSetting.style.display = "flex"
		}

		widthSetting.insertAdjacentHTML("afterbegin",
			`<div>
				<button id="rbro_settings" class="rbroButton rbroMenuButton" title="Settings">
					<svg class="icon  icon-md" style="cursor: pointer;">
						<use class="" href="#icon-setting-gear"></use>
					</svg>
					<span class="rbroHidden">SETTINGS</span>
				</button>
			</div>`)

		const rbroToolButtonContainer = document.querySelector(".rbroToolButtonContainer")
		rbroToolButtonContainer.before(widthSetting);

		const settingsButton = document.getElementById('rbro_settings')

		settingsButton.onclick = function () {
			frappe.db.get_doc("ioi User", currentUser).then((r) => {
				let usr_doc = r;

				if (usr_doc) {
					let userDetailPanelSize = usr_doc.detail_panel_size;
					let userSidebarMenu = usr_doc.sidebar_menu_report;
					let d = new frappe.ui.Dialog({
						title: `${__('Panel Settings')}`,
						fields: [
							{
								label: `${__('Detail Panel Size')}`,
								fieldname: 'detail_panel_size',
								fieldtype: 'Int',
								default: userDetailPanelSize
							},
							{
								label: `${__('Sidebar menu')}`,
								fieldname: 'sidebar_menu_report',
								fieldtype: 'Check',
								default: userSidebarMenu
							},
						],
						primary_action_label: `${__('Update')}`,
						primary_action(values) {
							frappe.db.set_value("ioi User", currentUser, 'detail_panel_size', values.detail_panel_size).then(() => {
								frappe.db.set_value("ioi User", currentUser, 'sidebar_menu_report', values.sidebar_menu_report).then(() => {
									detailPanelWidthValue = values.detail_panel_size
									d.hide()
									window.location.reload();
								})
							})


						}
					})
					d.show();

				}

			})



		}

		let resizeMainPanel = () => {
			rbroDetailPanel.style.width = `${detailPanelWidthValue}px`
			rbroDocumentPanel.style.width = `calc(100% - (${detailPanelWidthValue}px + ${rbroMainPanel.style.width} + ${me.sidebar_menu_report ? 92 : 0}px))`
		}

		let rbroContainer = document.getElementById('reportbro')

		let checkWidthTimer = () => {
			if ((rbroDetailPanel.clientWidth + rbroMainPanel.clientWidth + rbroDocumentPanel.clientWidth) > rbroContainer.clientWidth) {
				resizeMainPanel()
			}
		}

		setInterval(checkWidthTimer, 1500);
	}

	update_report_styles(rpt) {
		frappe.call({
			method: "silicon_ioi.ioi_system.doctype.ioi_report.ioi_report.map_ioi_report_styles",
			args: {
				"report_definition": rpt,
			},
			async: false,
			callback: function (r) {
				let result = r.message;
				rpt.styles = result.styles
			}
		});
	}

	async add_parameters_from_data_source() {

		let meta = null;
		if (this.ioi_report.data_source.toUpperCase() == 'SCRIPT') {

			let script_result = this.ioi_report.script_result_dic;
			let script_meta = script_result.meta

			if (script_meta){
				ioi.report.script_meta = script_meta;
				this.add_btn_refresh_parameters_from_script()
				//let f = this.get_field_object(script_meta)
				//this.reportbro.createParameter(f)
			}

			meta = {};
			meta.fields = []

			return
		} else if (this.ioi_report.data_source.toUpperCase() == 'QUERY') {

			let sql_result = this.ioi_report.query_result_dic;

			meta = {};
			meta.fields = [sql_result[0].meta];
		} else {
			meta = frappe.get_meta(this.reference_doctype);
			meta.fields.push({ 'fieldname': 'doctype', 'fieldtype': 'text', 'testData': this.reference_doctype });
		}

		this.children_mode = false;
		this.fields = await Promise.all(meta.fields.map(this.map_fields));

		for (var i = 0; i < this.params_to_create.length; i++) {
			//this.reportbro.createParameter(params_linked_doc[i])
			this.fields.push(this.params_to_create[i])
		}

		if (this.ioi_report.data_source.toUpperCase() == 'QUERY') {
			if ((this.fields.length == 1) && (this.fields[0]["children"])) {
				this.children_mode = true;

				this.fields[0]["children"].map(this.map_fields);

				this.children_mode = false;
			}
		}

		for (var i = 1; i < $(".ql-toolbar").length; i++) {
			$(".ql-toolbar")[i].hidden = true;
		}

		this.add_custom_parameters()
			.then(() => {
				let me = this;
				this.fields = this.fields.filter(f => f != undefined)
				if (this.reportbro) {

					for (var x = 0; x < this.params_state_on_load.length; x++) {
						if (this.params_state_on_load[x].type == 'image') {
							for (var y = 0; y < this.fields.length; y++) {
								if (this.fields[y].name == this.params_state_on_load[x].name) {
									this.fields[y].type = 'image';
									break;
								}
							}
						} else {
							if (this.params_state_on_load[x].type == 'array' || this.params_state_on_load[x].type == 'Table') {
								for (var z = 0; z < this.params_state_on_load[x].children.length; z++) {
									if (this.params_state_on_load[x].children[z].type == 'image') {
										for (var y = 0; y < this.fields.length; y++) {
											if (this.fields[y].name == this.params_state_on_load[x].name &&
												this.fields[y].type == 'array' || this.fields[y].type == 'Table') {
												for (var j = 0; j < this.fields[y].children.length; j++) {
													if (this.fields[y].children[j].name == this.params_state_on_load[x].children[z].name) {
														this.fields[y].children[j].type = 'image';

														break;
													}
												}
											}
										}
									}
									if (this.ioi_report.data_source.toUpperCase() == "DOCTYPE"){
										if (this.params_state_on_load[x].children[z].name.indexOf("__") > 0 ||this.params_state_on_load[x].children[z].name.startsWith('_')) {
											for (var y = 0; y < this.fields.length; y++) {
												if (this.fields[y].name == this.params_state_on_load[x].name &&
													this.fields[y].type == 'array' || this.fields[y].type == 'Table') {

														let param_obj = this.params_state_on_load[x].children[z]
														this.fields[y].children.push(param_obj);
												}
											}
										}
									}
								}
							}
						}
					}

					// add approval fields if applicable
					if (me.ioi_report.data_source.toUpperCase() == 'DOCTYPE') {

						let fieldname_type = typeof (me.reference_document.name) == 'number' ? 'Int' : 'text';
						me.fields.push(me.get_field_object({ 'fieldname': 'name', 'fieldtype': fieldname_type, 'testData': me.reference_document.name }));
						frappe.call({
							method: "silicon_ioi.ioi_system.doctype.ioi_report.ioi_report.get_approval_fields",
							args: {
								"doctype": me.reference_doctype,
								"docname": me.reference_document_name
							},
							async: false,
							callback: function (r) {
								let result = r.message;

								let letters = ['a', 'b', 'c', 'd'];

								for (var i = 0; i < letters.length; i++) {
									if (result['approval_user_' + letters[i]]) {
										me.fields.push(me.get_field_object({ 'fieldname': 'approval_user_' + letters[i], 'fieldtype': 'text', 'testData': result['approval_user_' + letters[i]] }));
										me.fields.push(me.get_field_object({ 'fieldname': 'approval_full_name_' + letters[i], 'fieldtype': 'text', 'testData': result['approval_full_name_' + letters[i]] }));
										me.fields.push(me.get_field_object({ 'fieldname': 'approval_signature_' + letters[i], 'fieldtype': 'Image', 'testData': result['approval_signature_' + letters[i]] }));
									} else {
										if (result.dt_use_approvals) {
											me.fields.push(me.get_field_object({ 'fieldname': 'approval_user_' + letters[i], 'fieldtype': 'text', 'testData': '' }));
											me.fields.push(me.get_field_object({ 'fieldname': 'approval_full_name_' + letters[i], 'fieldtype': 'text', 'testData': '' }));
											me.fields.push(me.get_field_object({ 'fieldname': 'approval_signature_' + letters[i], 'fieldtype': 'Image', 'testData': '' }));
										}
									}
								}
							}
						});
					}

					const existing_parameters = this.reportbro.getReport().parameters
					this.fields.map(f => {
						if (existing_parameters.filter(param => (param.name == f.fieldname)).length) {

							let parameter = this.reportbro.getParameterByName(f.fieldname);
							while (parameter) {
								this.reportbro.deleteParameter(parameter);
								parameter = this.reportbro.getParameterByName(f.fieldname);
							}

						}
					})

					this.fields.push(this.get_field_object({ 'fieldname': 'page_count', 'fieldtype': 'Int', 'testData': '' }));
					this.fields.push(this.get_field_object({ 'fieldname': 'page_number', 'fieldtype': 'Int', 'testData': '' }));



					this.fields.sort((a, b) => {
						return a.name > b.name ? 1 : -1
					});

					this.fields.map(f => {
						this.reportbro.createParameter(f)
					})
				}
			})

	}

	get_field_object(f) {
		let field_type = this.get_fieltype_map(f.fieldtype);
		let newID = this.reportbro.getUniqueId();
		return Object.assign(f, {
			id: newID,
			name: f.fieldname,
			frappe_name: f.name,
			showOnlyNameType: false,
			eval: false,
			type: field_type,
			pattern: this.get_pattern(f.fieldtype),
			children: f.children ? f.children : []
		})
	}

	async map_fields(f) {
		let me = ioi.report;
		if (!["Section Break", "Column Break", "Tab Break", "Fold"].includes(f.fieldtype)) {
			let test_data = "";

			if (me.ioi_report.data_source.toUpperCase() == 'SCRIPT') {
				if (me.ioi_report.script_result_dic) {
					test_data = JSON.stringify(me.ioi_report.script_result_dic);
				}
			} else if (me.ioi_report.data_source.toUpperCase() == 'QUERY') {
				if (me.ioi_report.query_result_dic[1]["query_records"].length > 0) {
					test_data = JSON.stringify(me.ioi_report.query_result_dic[1]["query_records"]);
				}
			} else {
				test_data = me.reference_document[f.fieldname];
			}

			if (f.fieldtype == "Text Editor") {
				const quill = new ioi.reporting.quill($(".layout-footer")[0], { theme: "snow" });
				test_data = {
					"content": quill.clipboard.convert({ html: me.reference_document[f.fieldname], text: "" }),
					"html": me.reference_document[f.fieldname]
				}
			}

			let field_object = me.get_field_object(f)

			if (!me.children_mode) {
				if (field_object.type != 'rich_text'){
					field_object.testData = (typeof test_data === "string") ? test_data : JSON.stringify(test_data)
				}else{
					field_object.testDataRichText = test_data.html
				}

			}

			if (me.ioi_report.data_source.toUpperCase() == 'DOCTYPE') {
				if (field_object.type == 'array') {
					field_object.children = await me.get_field_children(f)
				}
			} else if (me.ioi_report.data_source.toUpperCase() == 'QUERY') {
				field_object.children = f.children
			}else if (me.ioi_report.data_source.toUpperCase() == 'SCRIPT') {
				field_object.children = f.children
			}

			return field_object
		}
	}

	async get_field_children(field) {
		// Children
		return new Promise((resolve) => {
			frappe.model.with_doctype(field.options, () => {
				let child_meta = frappe.get_meta(field.options);
				let children = child_meta.fields.map(f => {
					if (!["Section Break", "Column Break", "Tab Break", "Fold"].includes(f.fieldtype)) {
						const field_obj = this.get_field_object(f)

						field_obj.arrayItemType = 'string'
						field_obj.testData = "" // Defined at parent level
						return field_obj
					}
				}).filter(f => f != undefined)
				resolve(children);
			})
		});
	}

	get_fieltype_map(fieldtype) {

		switch (fieldtype) {
			case 'Table':
			case 'Table MultiSelect':
				return 'array';
			case 'Date':
				return 'date';
			case 'Datetime':
				return 'date';
			case 'Check':
			case 'Boolean':
				return 'boolean';
			case 'Image':
				return 'image';
			case 'Collection':
				return 'map';
			case 'Float':
			case 'Int':
			case 'Currency':
			case 'Percent':
				return 'number';
			case 'Text Editor':
				return 'rich_text'
			default:
				return 'string';
		}
	}

	get_pattern(fieldtype) {
		switch (fieldtype) {
			case 'Date':
				return system_date_fmt;
			case 'Datetime':
				return system_datetime_fmt;
			case 'Currency':
				return this.currency_fmt;
			case 'Int':
			case 'Float':
				return this.number_format;
			default:
				return '';
		}
	}

	print() {
		this.reportbro.previewWithData(this.reference_document)
	}

	make_new_report() {
		frappe.prompt(
			[
				{
					fieldname: "report_name",
					fieldtype: "Data",
					label: __("Name of your report")
				},
				{
					fieldname: "doc_type",
					fieldtype: "Link",
					options: "DocType",
					label: __("Associated DocType")
				}
			],
			(values) => {
				frappe.call("frappe.client.insert", {
					doc: {
						"doctype": "ioi Report",
						"report_name": values.report_name,
						"doc_type": values.doc_type
					}
				}).then(r => {
					if (r.message) {
						frappe.set_route("ioi-report-builder", r.message.name);
					}
				})
			},
			__("Create a new report"),
			__("Create")
		)
	}

	add_custom_parameters() {
		let me = this;

		return frappe.db.get_list("ioi Report Parameter", {
			filters: [
				["ioi Report Parameter Doctype", "authorized_doctype", "=", this.reference_document.doctype]
			],
			fields: ["parameter_name", "name", "test_data"]
		}).then((res) => {
			const fields = res.map((r, i) => {

				return {
					id: me.reportbro.getUniqueId(),
					name: r.parameter_name,
					showOnlyNameType: false,
					eval: false,
					type: "string",
					testData: r.test_data
				}
			})

			this.fields.push(...fields)
		})
	}
}

function sleep_ms(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

function clearReportTestData(report) {

	report.parameters.forEach((param) => {
		if (param.testData) {
			param.testData = ""
		}
		if (param.children) {
			param.children.forEach((child) => {
				if (child.testData) {
					child.testData = ""
				}
			})
		}
	})
	return report
}
const requestCallback = (r) => {

	let select_language = $("select[data-fieldname=preview_language]")
	r.reportServerHeaders.prevLanguage = frappe.boot.lang;

	if (select_language.length > 0){
		r.reportServerHeaders.prevLanguage = select_language[0].value;
	}
	//return r;
}
const save = () => {
	if (ioi.report.ioi_report) {
		frappe.call({method: "frappe.client.get", args: {
			doctype: ioi.report.ioi_report.doctype,
			name: ioi.report.ioi_report.name
		},
		async: false,
		}).then(r => {
			ioi.report.ioi_report = r.message;

			var wait_dialog = new frappe.ui.Dialog({
				title: __("Saving in progress. Please wait..."),
			});
			wait_dialog.show();

			const report = ioi.report.reportbro.getReport()
			if (ioi.report.ioi_report.data_source.toUpperCase() != 'SCRIPT'){
				ioi.report.ioi_report.report = JSON.stringify(clearReportTestData(report))
			}else{
				ioi.report.ioi_report.report = JSON.stringify(report);
			}

			frappe.call("frappe.client.save", {
				doc: ioi.report.ioi_report
			}).then(r => {
				ioi.report.reportbro.setModified(false);
				ioi.report.ioi_report = r.message;

				sleep_ms(1000).then(() => {
					wait_dialog.hide();
				});

			})
		});
	}
}
