
	frappe.templates['ioi_profile_dropdown'] = `{% if (!resources.length) { %}
<li class="resource-no-records text-muted">{{ __("No resource available.") }}</li>
{% } else {
	for (var i=0, l=resources.length; i < l; i++) {
		var resource_label = resources[i].name;
		var resource_value = resources[i].name;
%}
<li>
	<a class="resources-link dropdown-item" data-label="{{ resource_label }}" data-value="{{ resource_value }}" href="#" onclick="return false;">
		<span class="resources-label">{{ __(resource_label) }}</span>
	</a>
</li>
	{% }
} %}
<li class="dropdown-divider" style="display: block;"></li>
<li>
	<a class="text-muted dropdown-item" href="/app/ioi-event-resource-profile/new">{{ __("New Profile") }}</a>
</li>
`;
