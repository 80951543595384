frappe.provide("frappe.ui");

export class ioiThemeSwitcher extends frappe.ui.ThemeSwitcher {
	fetch_themes() {
		return new Promise((resolve) => {
			this.themes = [
				{
					name: "light",
					label: __("Frappe Light"),
					info: __("Light Theme"),
				},
				{
					name: "dark",
					label: __("Timeless Night"),
					info: __("Dark Theme"),
				},
				{
					name: "automatic",
					label: __("Automatic"),
					info: __("Uses system's theme to switch between light and dark mode"),
				},
				{
					name: "light-custom",
					label: __("ioi Launch Edition Light"),
					info: __("Custom Theme"),
				},
				{
					name: "dark-custom",
					label: __("ioi Launch Edition Dark"),
					info: __("Custom Theme"),
				},
			];

			resolve(this.themes);
		});
	}

	toggle_theme(theme) {
		this.current_theme = theme.toLowerCase();
		document.documentElement.setAttribute("data-theme-mode", this.current_theme);
		frappe.show_alert(__("Theme Changed, Please Refresh !"), 4);

		frappe.xcall("silicon_ioi.ioi_system.doctype.ioi_user.ioi_user.switch_theme", {
			theme: toTitle(theme),
		});
	}
}

frappe.ui.set_theme = (theme) => {
	const root = document.documentElement;
	let theme_mode = root.getAttribute("data-theme-mode");
	if (!theme) {
		if (theme_mode === "automatic") {
			theme = frappe.ui.dark_theme_media_query.matches ? "dark" : "light";
		}

		if(theme_mode === "dark-custom") {
			theme = "dark"
		}

		if(theme_mode === "light-custom") {
			theme = "light"
		}
	}
	root.setAttribute("data-theme", theme || theme_mode);
};


frappe.ui.ThemeSwitcher = ioiThemeSwitcher;