frappe.provide('silicon_ioi.doctype');

export class ioiRoutingDefinitionFrame
{
	static path_common = 'silicon_ioi.common.common';
	static path_general_settings = 'silicon_ioi.ioi_configuration.doctype.ioi_general_settings.ioi_general_settings';
	static path_routing_definition = 'silicon_ioi.ioi_configuration.doctype.ioi_routing_definition.ioi_routing_definition';
	static path_item = 'silicon_ioi.ioi_items.doctype.ioi_item.ioi_item';
	static path_workcenter = 'silicon_ioi.ioi_enterprise.doctype.ioi_workcenter.ioi_workcenter';



    static html_field = '';
	static item_id = '';
	static release_id = '';
	static show_data = false;
	static can_read = false;
	static can_modify = false;
	static columns = [];
	static data = [];

	static ioiRoutingDefinitionTable = null;


	static load_routing_definition_frame(html_field, item_id, release_id, show_data = false, check_status = false, status_value = null)
	{
        silicon_ioi.doctype.ioiRoutingDefinitionFrame.html_field = html_field;
		silicon_ioi.doctype.ioiRoutingDefinitionFrame.item_id = item_id;
		silicon_ioi.doctype.ioiRoutingDefinitionFrame.release_id = release_id;
		silicon_ioi.doctype.ioiRoutingDefinitionFrame.show_data = show_data;

		silicon_ioi.doctype.ioiRoutingDefinitionFrame.can_read = false;
		silicon_ioi.doctype.ioiRoutingDefinitionFrame.can_modify = false;

		let method = silicon_ioi.doctype.ioiRoutingDefinitionFrame.path_routing_definition + '.ioi_routing_definition_frame_get_permissions';

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{

							if (r.message.read == 1) {
								silicon_ioi.doctype.ioiRoutingDefinitionFrame.can_read = true;
							}

							if (r.message.write == 1) {
								silicon_ioi.doctype.ioiRoutingDefinitionFrame.can_modify = true;
							}

							if (silicon_ioi.doctype.ioiRoutingDefinitionFrame.can_modify) {
								silicon_ioi.doctype.ioiRoutingDefinitionFrame.can_read = true;
							}
						}
		});

		if (document.getElementById('routing_definition_action')) {
			document.getElementById('routing_definition_action').remove();
		}


		if (document.getElementById('routing_definition_action_button_new')) {
			document.getElementById('routing_definition_action_button_new').remove();
		}

		if (document.getElementById('routing_definition_action_button_duplicate')) {
			document.getElementById('routing_definition_action_button_duplicate').remove();
		}


		if (document.getElementById('routing_definition_action_button_delete')) {
			document.getElementById('routing_definition_action_button_delete').remove();
		}

		if (document.getElementById('routing_definition_action_button_save')) {
			document.getElementById('routing_definition_action_button_save').remove();
		}

		if (document.getElementById('routing_definition_action_button_cancel')) {
			document.getElementById('routing_definition_action_button_cancel').remove();
		}

		if (document.getElementById('routing_definition_action_change_step')) {
			document.getElementById('routing_definition_action_change_step').remove();
		}

		if (document.getElementById('routing_definition_action_jump_to_routing_release')) {
			document.getElementById('routing_definition_action_jump_to_routing_release').remove();
		}

		if (document.getElementById('routing_definition_hidden_routing_release_value')) {
			document.getElementById('routing_definition_hidden_routing_release_value').remove();
		}

		if (document.getElementById('routing_definition_table_container')) {
			document.getElementById('routing_definition_table_container').remove();
		}

		let routing_release_value = silicon_ioi.doctype.ioiRoutingDefinitionFrame.item_id + ' • ' + silicon_ioi.doctype.ioiRoutingDefinitionFrame.release_id;

		let html = '';


		html += '<div id="routing_definition_item_frame" style="overflow: auto; overflow-x: auto; overflow-y: hidden; height:55px; border: 1px solid #E6E9EC;">';

		html += '	<div style="position: relative; top: 2px; left: 2px; width:220px;">';
		html += '		<label id="routing_definition_item_frame_bom_release_label" style="position: absolute; top: 0px; left: 2px;z-index:5;"><b>' + __("Routing Release") + '</b></label>';
		html += '	</div>';

		html += '	<div style="position: relative; top: 27px; left: 2px; width:500px;">';
		html += '		<label id="routing_definition_item_frame_bom_release_description" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + routing_release_value + '</label>';
		html += '	</div>';


		if (cur_frm.doctype.toUpperCase() == 'IOI ITEM MANUFACTURING DEFINITION') {

			html += '	<div style="position: relative; top: 15px; left: 500px; width:250px;">';
			html += '		<input id="routing_definition_hidden_routing_release_value" type="hidden" value="' + routing_release_value +  '">';
			html +='		<button id="routing_definition_action_jump_to_routing_release" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="">' + __("To Routing release") + '</button>';
			html += '	</div>';
		}


		html += '</div>';

		if (check_status) {

			if (status_value != 0) {

				silicon_ioi.doctype.ioiRoutingDefinitionFrame.can_read = true;
				silicon_ioi.doctype.ioiRoutingDefinitionFrame.can_modify = false;
			}
		}


		if ((silicon_ioi.doctype.ioiRoutingDefinitionFrame.can_modify) && (silicon_ioi.doctype.ioiRoutingDefinitionFrame.show_data)) {

			html += '<div style="overflow: auto; overflow-x: auto; overflow-y: hidden; height:8px;">';
			html += '</div>';


			html += '<div id="routing_definition_action" style="overflow: auto; overflow-x: auto; overflow-y: hidden; height:35px;">';

			// New
			html += '	<div style="position: relative; top: 2px; left: 0px; width:150px;">';
			html += '		<div style="position: absolute; top:0px; left: 2px; height: 35px">';
			html +='			<button id="routing_definition_action_button_new" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="">' + __("New") + '</button>';
			html += '		</div>';
			html += '	</div>';

			// Duplicate
			html += '	<div style="position: relative; top: 2px; left: 160px; width:150px;">';
			html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
			html +='			<button id="routing_definition_action_button_duplicate" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="">' + __("Duplicate") + '</button>';
			html += '		</div>';
			html += '	</div>';


			// Delete
			html += '	<div style="position: relative; top: 2px; left: 320px; width:150px;">';
			html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
			html +='			<button id="routing_definition_action_button_delete" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="">' + __("Delete") + '</button>';
			html += '		</div>';
			html += '	</div>';

			// Save
			html += '	<div style="position: relative; top: 2px; left: 480px; width:150px;">';
			html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
			html +='			<button id="routing_definition_action_button_save" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="" disabled>' + __("Save") + '</button>';
			html += '		</div>';
			html += '	</div>';

			// Cancel
			html += '	<div style="position: relative; top: 2px; left: 640px; width:150px;">';
			html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
			html +='			<button id="routing_definition_action_button_cancel" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="" disabled>' + __("Cancel") + '</button>';
			html += '		</div>';
			html += '	</div>';

			// Change position
			html += '	<div style="position: relative; top: 2px; left: 800px; width:150px;">';
			html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
			html +='			<button id="routing_definition_action_change_step" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="" >' + __("Change step") + '</button>';
			html += '		</div>';
			html += '	</div>';


			html += '</div>'

		}

		html += '<div id="routing_definition_table_container" class="table table-bordered" data-custom-grid="true" style="overflow: auto; overflow-x: auto; overflow-y: hidden; height:600px;border: 1px solid #E6E9EC;">';
		html += '</div>';

		cur_frm.fields_dict[html_field].$wrapper.empty();
		cur_frm.fields_dict[html_field].$wrapper.append(html);


		silicon_ioi.doctype.ioiRoutingDefinitionFrame.sleep_static(200).then(() => {

			if (document.getElementById('routing_definition_action_jump_to_routing_release')) {

				let fct_click = function() {

					let url = '/app/ioi-routing-release/' + document.getElementById('routing_definition_hidden_routing_release_value').value;
					window.open(url);
				}

				document.getElementById('routing_definition_action_jump_to_routing_release').onclick = fct_click
			}


			if (document.getElementById('routing_definition_action_button_new')) {

				let fct_click = function() {
					silicon_ioi.doctype.ioiRoutingDefinitionFrame.new();
				}

				document.getElementById('routing_definition_action_button_new').onclick = fct_click
			}

			if (document.getElementById('routing_definition_action_button_duplicate')) {

				let fct_click = function() {
					silicon_ioi.doctype.ioiRoutingDefinitionFrame.duplicate();
				}

				document.getElementById('routing_definition_action_button_duplicate').onclick = fct_click
			}


			if (document.getElementById('routing_definition_action_button_delete')) {

				let fct_click = function() {
					silicon_ioi.doctype.ioiRoutingDefinitionFrame.delete();
				}

				document.getElementById('routing_definition_action_button_delete').onclick = fct_click
			}

			if (document.getElementById('routing_definition_action_button_save')) {

				let fct_click = function() {
					silicon_ioi.doctype.ioiRoutingDefinitionFrame.save();
				}

				document.getElementById('routing_definition_action_button_save').onclick = fct_click
			}

			if (document.getElementById('routing_definition_action_button_cancel')) {

				let fct_click = function() {
					silicon_ioi.doctype.ioiRoutingDefinitionFrame.cancel();
				}

				document.getElementById('routing_definition_action_button_cancel').onclick = fct_click
			}

			if (document.getElementById('routing_definition_action_change_step')) {

				let fct_click = function() {
					silicon_ioi.doctype.ioiRoutingDefinitionFrame.change_step();
				}

				document.getElementById('routing_definition_action_change_step').onclick = fct_click
			}



			// New

			let max_height = 600;
			let row_height = null;
			let layout = null;
			let selectable = 1;
			let show_profiles = true;
			let movable_columns = true;
			let resizable_columns = true;
			let auto_redraw = true;

			let table_parameters = {
				"maxHeight": max_height,
				"rowHeight": row_height,
				//"layout": layout,
				"selectable": selectable,
				"showProfiles": show_profiles,
				"movableColumns": movable_columns,
				"resizableColumns": resizable_columns,
				"autoRedraw": auto_redraw
			};

			let doctype = 'ioi Routing Definition';
			let doctype_reference = 'ioi Routing Definition'

			let display_fields = [];
			let display_custom_fields = true;
			let primary_fields_not_name = ['item_id', 'routing_release_id', 'step_id'];
			let exclude_fields = ['is_warning', 'warninfo'];

			let change_title = [];
			//change_title[0] = ['ioistatus', __("Status")];


			let visible_hidden_columns = ['enabled'];
			let hide_visible_columns = ['item_id', 'routing_release_id', 'step_id_int', 'routing_release_name'];



			let readonly_fields = ['step_id'];

			let display_buttons_when_readonly = [];

			let columns_width = [];

			//columns_width[0] = ['component_id', 500];
			//columns_width[1] = ['test_manuf_ref', 1000];


			let frozen_columns = ['step_id'];
			let frozen_columns_editable = [false];



			let select_values = [];
			select_values[0] = ['workcenter_unit_time_def', '0', __('Duration')];
			select_values[1] = ['workcenter_unit_time_def', '1', __('Rate')];

			select_values[2] = ['worker_unit_time_def', '0', __('Duration')];
			select_values[3] = ['worker_unit_time_def', '1', __('Rate')];

			select_values[4] = ['line_mode', '0', __('Undefined')];
			select_values[5] = ['line_mode', '1', __('Required')];
			select_values[6] = ['line_mode', '2', __('Recommended')];

			select_values[7] = ['before_execution_mode', '0', __('Calendar')];
			select_values[8] = ['before_execution_mode', '1', __('Open hours')];

			select_values[9] = ['after_execution_mode', '0', __('Calendar')];
			select_values[10] = ['after_execution_mode', '1', __('Open hours')];


			select_values[11] = ['scheduling_update', '0', __('Realized time')];
			select_values[12] = ['scheduling_update', '1', __('Realized quantity')];
			select_values[13] = ['scheduling_update', '2', __('Manual Completed')];


			select_values[14] = ['subcontractor_flow', '0', __('No transaction')];
			select_values[15] = ['subcontractor_flow', '1', __('Receive only')];
			select_values[16] = ['subcontractor_flow', '2', __('Send Receive')];
			select_values[17] = ['subcontractor_flow', '3', __('Order/Send/Receive')];



			let default_values = []
			default_values[0] = ['enabled', '1'];
			default_values[1] = ['line_mode', '0'];
			default_values[2] = ['workcenter_unit_time_def', '0'];
			default_values[3] = ['worker_unit_time_def', '0'];
			default_values[4] = ['before_execution_mode', '0'];
			default_values[5] = ['after_execution_mode', '0'];
			default_values[6] = ['scheduling_update', '0'];
			default_values[7] = ['subcontractor_flow', '0'];


			let format_numbers = []
			//format_numbers[0] = ['workcenter_unit_duration', 4, true, false];

			let underline_frozen = true;


			let replace_fields = [];
			let jump_fields = ['workcenter_id', 'line_machine_id', 'staff_skill_id', 'subcontractor_id', 'subcontractor_purchases_condition_id', 'subcontractor_item_id'];

			let show_data = silicon_ioi.doctype.ioiRoutingDefinitionFrame.show_data;
			let can_read = silicon_ioi.doctype.ioiRoutingDefinitionFrame.can_read;
			let can_modify = silicon_ioi.doctype.ioiRoutingDefinitionFrame.can_modify;


			let allow_change_status = false;
			let allow_change_userstatus = false

			let language_columns = [];
			let language_memo_columns = [];

			let show_attach_image_path = [];



			let images_properties = []


			let data = [];

			if (show_data) {

				let method = silicon_ioi.doctype.ioiRoutingDefinitionFrame.path_routing_definition + '.ioi_routing_definition_get_data';

				frappe.call({  	method: method,
								args: {	"item_id": silicon_ioi.doctype.ioiRoutingDefinitionFrame.item_id,
										"release_id": silicon_ioi.doctype.ioiRoutingDefinitionFrame.release_id
								},
								async: false,
								callback:function(r)	{
									data = r.message;
								}
				});
			}

			let Format_Cell = function(col, cell, input) {

				if (silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.can_modify) {

					if (input != null) {

						let cells = cell.getRow().getCells();

						if (cell.getColumn().getField().toUpperCase() == 'WORKCENTER_UNIT_DURATION') {

							for(var i = 0; i < cells.length; i++) {

								if (cells[i].getColumn().getField().toUpperCase() == 'WORKCENTER_UNIT_TIME_DEF') {

									if ((cells[i].getValue() == null) || (cells[i].getValue() == 0)) {
										input.readOnly = false;
									}else{
										input.readOnly = true;
									}
								}
							}
						}



						if (cell.getColumn().getField().toUpperCase() == 'WORKCENTER_UNIT_RATE') {

							for(var i = 0; i < cells.length; i++) {

								if (cells[i].getColumn().getField().toUpperCase() == 'WORKCENTER_UNIT_TIME_DEF') {

									if ((cells[i].getValue() == null) || (cells[i].getValue() == 0)) {
										input.readOnly = true;
									}else{
										input.readOnly = false;
									}
								}
							}
						}

						if (cell.getColumn().getField().toUpperCase() == 'WORKER_UNIT_DURATION') {

							for(var i = 0; i < cells.length; i++) {

								if (cells[i].getColumn().getField().toUpperCase() == 'WORKER_UNIT_TIME_DEF') {

									if ((cells[i].getValue() == null) || (cells[i].getValue() == 0)) {
										input.readOnly = false;
									}else{
										input.readOnly = true;
									}
								}
							}
						}

						if (cell.getColumn().getField().toUpperCase() == 'WORKER_UNIT_RATE') {

							for(var i = 0; i < cells.length; i++) {

								if (cells[i].getColumn().getField().toUpperCase() == 'WORKER_UNIT_TIME_DEF') {

									if ((cells[i].getValue() == null) || (cells[i].getValue() == 0)) {
										input.readOnly = true;
									}else{
										input.readOnly = false;
									}
								}
							}
						}
					}
				}
			}


			let ItemWindowParams = function(bt_fieldname, fieldname, row) {

				let fields = 'a.name, a.description, a.unit_id, b.q_stock, b.q_reserved';
				let fields_len = '300, 500, 125, 150, 150';
				let fields_desc = 'Identification, Description, Unit, Stock qty, Reserved qty';

				let where = 'ioistatus = 2 ';

				if (fieldname.toUpperCase() == 'SUBCONTRACTOR_ITEM_ID') {
					where += ' and mode = 2';
				}

				let order_by = 'a.name asc';

				let parameter_with_stock = false;
				let site_id = '';

				return [fields, fields_len, fields_desc, where, order_by, parameter_with_stock, site_id];


			};



			let CustomerWindowParams = function(bt_fieldname, fieldname, row) {

				let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email';
				let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400';
				let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email';
				let where = 'ioistatus = 2';
				let order_by = 'name asc';

				return [fields, fields_len, fields_desc, where, order_by];

			};


			let SupplierWindowParams = function(bt_fieldname, fieldname, row) {

				let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email';
				let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400';
				let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email';
				let where = 'ioistatus = 2';
				let order_by = 'name asc';

				return [fields, fields_len, fields_desc, where, order_by];

			};

			let ManufacturerCatalogWindowParams = function(bt_fieldname, fieldname, row) {

				let fields = 'a.name, a.item_id, a.description, a.unit_id, a.item_id_live_creation, b.supplier_id, a.barcode_reference, ';
				let fields_len = '300, 200, 350, 100, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200';
				let fields_desc = 'Identification, Item, Description, Unit, Item id live creation, Supplier, Barcode ref, ';
				let where = "a.enabled = 1 ";
				let order_by = 'a.name asc';

				let document_date = null;
				let currency_id = null;

				return [fields, fields_len, fields_desc, where, order_by, document_date, currency_id];

			};


			let DoctypeWindowParams = function(bt_fieldname, fieldname, row, cell) {

				let title = __("Select");
				let form_width_pixel = '710px';
				let fields = 'name, description';
				let fields_len = '250, 380';
				let fields_desc = __('Identification') + ',' + __('Description');
				let where = '';
				let order_by = 'name asc';

				if (fieldname.toUpperCase() == 'LINE_MACHINE_ID') {

					title = __("Select a line machine");

					if ((!row.getData().workcenter_id) || ((row.getData().workcenter_id) && (row.getData().workcenter_id.trim() == ''))) {
						where = "1 = 2 ";
					}else{
						where = "enabled = 1 and workcenter_id = '" +row.getData().workcenter_id.toUpperCase() + "' ";
					}
				}




				return [title, form_width_pixel, fields, fields_len, fields_desc, where, order_by];
			};


			let Cell_Callback = function(col, row, input, cell) {

				if (col.fieldname.toUpperCase() == 'WORKCENTER_ID') {

					if (input != null) {

						if (input.value.trim() != '') {

							let method = silicon_ioi.doctype.ioiRoutingDefinitionFrame.path_workcenter + '.ioi_workcenter_get_data';

							frappe.call({  	method: method,
											args: {"name": input.value.toUpperCase()},
											async: false,
											callback:function(r) {

												if (r.message) {

													row.update({"description": r.message[0].description});
													row.update({"line_machine_id": ''});

												}else{
													row.update({"description": ""});
													row.update({"line_machine_id": ""});
												}
											}
							});

						}else{
							row.update({"description": ""});
							row.update({"line_machine_id": ""});
						}
					}


				}else if (col.fieldname.toUpperCase() == 'SUBCONTRACTOR_ITEM_ID') {

					if (input != null) {

						if (input.value.trim() != '') {

							let method = silicon_ioi.doctype.ioiRoutingDefinitionFrame.path_item + '.ioi_item_get_data';

							frappe.call({  	method: method,
											args: {"item_id": input.value.toUpperCase()},
											async: false,
											callback:function(r) {

												if (r.message) {

													if (r.message.mode != 2) {
														frappe.msgprint({title: __("Message"), message: __("Item has to be virtual"), indicator: "red"});
														row.update({"subcontractor_item_id": ""});
														input.value = '';
													}
												}else{
													frappe.msgprint({title: __("Message"), message: __("Item does not exist or it is inactive"), indicator: "red"});
													row.update({"subcontractor_item_id": ""});
													input.value = '';

												}
											}
							});
						}
					}

				}else if (col.fieldname.toUpperCase() == 'LINE_MACHINE_ID') {

					if (input != null) {

						if (input.value.trim() != '') {

							if ((!row.getData().workcenter_id) || ((row.getData().workcenter_id) && (row.getData().workcenter_id.trim() == ''))) {
								row.update({"line_machine_id": ""});
								input.value = '';
							}
						}
					}
				}else if (col.fieldname.toUpperCase() == 'WORKCENTER_UNIT_TIME_DEF') {

					if (input != null) {

						if (input.value == 0) {

							let cells = row.getCells()

							for (var i = 0; i< cells.length; i++) {

								if (cells[i].getColumn().getField().toUpperCase() == 'WORKCENTER_UNIT_DURATION') {

									if ((cells[i].getValue() != null) && (cells[i].getValue() != 0)) {
										row.update({"workcenter_unit_rate": parseFloat(1/parseFloat(cells[i].getValue()))});
									}else{
										row.update({"workcenter_unit_rate": 0});
									}
								}

							}

						}else{

							let cells = row.getCells()

							for (var i = 0; i< cells.length; i++) {

								if (cells[i].getColumn().getField().toUpperCase() == 'WORKCENTER_UNIT_RATE') {

									if ((cells[i].getValue() != null) && (cells[i].getValue() != 0)) {
										row.update({"workcenter_unit_duration": parseFloat(1/parseFloat(cells[i].getValue()))});
									}else{
										row.update({"workcenter_unit_duration": 0});
									}
								}
							}
						}
					}
				}else if ((col.fieldname.toUpperCase() == 'WORKCENTER_UNIT_DURATION') || (col.fieldname.toUpperCase() == 'WORKCENTER_UNIT_RATE')) {

					let cells = row.getCells();

					let workcenter_unit_time_def = 0;
					let workcenter_unit_duration = 0;
					let workcenter_unit_rate = 0;

					for (var i = 0; i< cells.length; i++) {
						if (cells[i].getColumn().getField().toUpperCase() == 'WORKCENTER_UNIT_TIME_DEF') {
							workcenter_unit_time_def = parseFloat(cells[i].getValue());
							break;
						}
					}

					if (workcenter_unit_time_def == 0) {

						for (var i = 0; i< cells.length; i++) {
							if (cells[i].getColumn().getField().toUpperCase() == 'WORKCENTER_UNIT_DURATION') {
								workcenter_unit_duration = parseFloat(cells[i].getValue());
								break;
							}
						}

						if (workcenter_unit_duration != 0) {
							row.update({"workcenter_unit_rate": parseFloat(1/parseFloat(workcenter_unit_duration))});
						}else{
							row.update({"workcenter_unit_rate": 0});
						}
					}else{

						for (var i = 0; i< cells.length; i++) {
							if (cells[i].getColumn().getField().toUpperCase() == 'WORKCENTER_UNIT_RATE') {
								workcenter_unit_rate = parseFloat(cells[i].getValue());
								break;
							}
						}

						if (workcenter_unit_rate != 0) {
							row.update({"workcenter_unit_duration": parseFloat(1/parseFloat(workcenter_unit_rate))});
						}else{
							row.update({"workcenter_unit_duration": 0});
						}

					}

				}else if (col.fieldname.toUpperCase() == 'WORKER_UNIT_TIME_DEF') {

					if (input != null) {

						if (input.value == 0) {

							let cells = row.getCells()

							for (var i = 0; i< cells.length; i++) {

								if (cells[i].getColumn().getField().toUpperCase() == 'WORKER_UNIT_DURATION') {

									if ((cells[i].getValue() != null) && (cells[i].getValue() != 0)) {
										row.update({"worker_unit_rate": parseFloat(1/parseFloat(cells[i].getValue()))});
									}else{
										row.update({"worker_unit_rate": 0});
									}
								}

							}

						}else{

							let cells = row.getCells()

							for (var i = 0; i< cells.length; i++) {

								if (cells[i].getColumn().getField().toUpperCase() == 'WORKER_UNIT_RATE') {

									if ((cells[i].getValue() != null) && (cells[i].getValue() != 0)) {
										row.update({"worker_unit_duration": parseFloat(1/parseFloat(cells[i].getValue()))});
									}else{
										row.update({"worker_unit_duration": 0});
									}
								}
							}
						}
					}
				}else if ((col.fieldname.toUpperCase() == 'WORKER_UNIT_DURATION') || (col.fieldname.toUpperCase() == 'WORKER_UNIT_RATE')) {

					let cells = row.getCells();

					let worker_unit_time_def = 0;
					let worker_unit_duration = 0;
					let worker_unit_rate = 0;

					for (var i = 0; i< cells.length; i++) {
						if (cells[i].getColumn().getField().toUpperCase() == 'WORKER_UNIT_TIME_DEF') {
							worker_unit_time_def = parseFloat(cells[i].getValue());
							break;
						}
					}

					if (worker_unit_time_def == 0) {

						for (var i = 0; i< cells.length; i++) {
							if (cells[i].getColumn().getField().toUpperCase() == 'WORKER_UNIT_DURATION') {
								worker_unit_duration = parseFloat(cells[i].getValue());
								break;
							}
						}

						if (worker_unit_duration != 0) {
							row.update({"worker_unit_rate": parseFloat(1/parseFloat(worker_unit_duration))});
						}else{
							row.update({"worker_unit_rate": 0});
						}
					}else{

						for (var i = 0; i< cells.length; i++) {
							if (cells[i].getColumn().getField().toUpperCase() == 'WORKER_UNIT_RATE') {
								worker_unit_rate = parseFloat(cells[i].getValue());
								break;
							}
						}

						if (worker_unit_rate != 0) {
							row.update({"worker_unit_duration": parseFloat(1/parseFloat(worker_unit_rate))});
						}else{
							row.update({"worker_unit_duration": 0});
						}

					}

				}else if (col.fieldname.toUpperCase() == 'WORKER_WORKCENTER_RATE') {

					let cells = row.getCells();

					let worker_workcenter_rate = 0
					let workcenter_unit_time_def = 0;
					let workcenter_unit_duration = 0;
					let workcenter_unit_rate = 0;

					for (var i = 0; i< cells.length; i++) {

						if (cells[i].getColumn().getField().toUpperCase() == 'WORKER_WORKCENTER_RATE') {
							worker_workcenter_rate = parseFloat(cells[i].getValue());
						}

						if (cells[i].getColumn().getField().toUpperCase() == 'WORKCENTER_UNIT_TIME_DEF') {
							workcenter_unit_time_def = parseFloat(cells[i].getValue());
						}

						if (cells[i].getColumn().getField().toUpperCase() == 'WORKCENTER_UNIT_DURATION') {
							workcenter_unit_duration = parseFloat(cells[i].getValue());
						}

						if (cells[i].getColumn().getField().toUpperCase() == 'WORKCENTER_UNIT_RATE') {
							workcenter_unit_rate = parseFloat(cells[i].getValue());
						}


					}

					if ((parseFloat(worker_workcenter_rate) >= 0) && (parseFloat(worker_workcenter_rate) <= 100)) {

						row.update({"worker_unit_time_def": workcenter_unit_time_def});
						row.update({"worker_unit_duration": workcenter_unit_duration});
						row.update({"worker_unit_rate": workcenter_unit_rate});


						if (workcenter_unit_time_def == 0) {

							if (parseFloat(workcenter_unit_duration) != 0) {
								row.update({"worker_unit_rate": 1.00 / parseFloat(workcenter_unit_duration)});
							}else{
								row.update({"worker_unit_rate": 0});
							}
						}else{
							if (parseFloat(workcenter_unit_rate) != 0) {
								row.update({"worker_unit_duration": 1.00 / parseFloat(workcenter_unit_rate)});
							}else{
								row.update({"worker_unit_duration": 0});
							}
						}
					}
				}
			}


			let CellButton_Callback = function(fieldname, return_value, row) {

				if (fieldname.toUpperCase() == 'WORKCENTER_ID') {

					let method = silicon_ioi.doctype.ioiRoutingDefinitionFrame.path_workcenter + '.ioi_workcenter_get_data';

					frappe.call({  	method: method,
									args: {"name": return_value.toUpperCase()},
									async: false,
									callback:function(r) {

										if (r.message) {

											row.update({"description": r.message[0].description});
											row.update({"line_machine_id": ''});

										}else{
											row.update({"description": ""});
											row.update({"line_machine_id": ""});
										}
									}
					});
				}

			}

			let Cell_click = function(cell) {

				if (cell.getColumn().getField().toUpperCase() == 'STEP_ID') {
					let row = cell.getRow()

					let url = '/app/ioi-routing-definition/' + row.getData()["routing_release_name"] + ' • ' + row.getData()["step_id"];

					window.open(url);
					return false;
				}
			};



			let Memo_Callback = function(fieldname, output, row) {

			};

			let Duration_Callback = function(fieldname, ret_value, row) {

			};

			let MultiSelect_Callback = function(fieldname, ret_value, row) {

			};



			let Button_Callback = function(bt_fieldname, cell, row, button) {

			};

			let RecordChangeState = function(record_state) {

				if (record_state.toUpperCase() == 'MODIFIED') {

					if (document.getElementById('routing_definition_action_button_new')) {
						document.getElementById('routing_definition_action_button_new').disabled = false;
					}

					if (document.getElementById('routing_definition_action_button_duplicate')) {
						document.getElementById('routing_definition_action_button_duplicate').disabled = false;
					}

					if (document.getElementById('routing_definition_action_button_delete')) {
						document.getElementById('routing_definition_action_button_delete').disabled = false;
					}

					if (document.getElementById('routing_definition_action_button_save')) {
						document.getElementById('routing_definition_action_button_save').disabled = false;
					}

					if (document.getElementById('routing_definition_action_button_cancel')) {
						document.getElementById('routing_definition_action_button_cancel').disabled = false;
					}

					if (document.getElementById('routing_definition_action_change_step')) {
						document.getElementById('routing_definition_action_change_step').disabled = true;
					}


				}else if (record_state.toUpperCase() == 'INSERT') {

					if (document.getElementById('routing_definition_action_button_new')) {
						document.getElementById('routing_definition_action_button_new').disabled = false;
					}

					if (document.getElementById('routing_definition_action_button_duplicate')) {
						document.getElementById('routing_definition_action_button_duplicate').disabled = false;
					}

					if (document.getElementById('routing_definition_action_button_delete')) {
						document.getElementById('routing_definition_action_button_delete').disabled = false;
					}

					if (document.getElementById('routing_definition_action_button_save')) {
						document.getElementById('routing_definition_action_button_save').disabled = false;
					}

					if (document.getElementById('routing_definition_action_button_cancel')) {
						document.getElementById('routing_definition_action_button_cancel').disabled = false;
					}

					if (document.getElementById('routing_definition_action_change_step')) {
						document.getElementById('routing_definition_action_change_step').disabled = true;
					}

				}else if (record_state.toUpperCase() == '') {

					if (document.getElementById('routing_definition_action_button_new')) {
						document.getElementById('routing_definition_action_button_new').disabled = false;
					}

					if (document.getElementById('routing_definition_action_button_duplicate')) {
						document.getElementById('routing_definition_action_button_duplicate').disabled = false;
					}

					if (document.getElementById('routing_definition_action_button_delete')) {
						document.getElementById('routing_definition_action_button_delete').disabled = false;
					}

					if (document.getElementById('routing_definition_action_button_save')) {
						document.getElementById('routing_definition_action_button_save').disabled = true;
					}

					if (document.getElementById('routing_definition_action_button_cancel')) {
						document.getElementById('routing_definition_action_button_cancel').disabled = true;
					}

					if (document.getElementById('routing_definition_action_change_step')) {
						document.getElementById('routing_definition_action_change_step').disabled = false;
					}

				}


			}

			let extended_parameters = {
				"doctype": doctype,
				"doctype_reference": doctype_reference,
				"display_fields": display_fields,
				"display_custom_fields": display_custom_fields,
				"primary_fields_not_name": primary_fields_not_name,
				"exclude_fields": exclude_fields,
				"change_title": change_title,
				"visible_hidden_columns": visible_hidden_columns,
				"hide_visible_columns" : hide_visible_columns,
				"readonly_fields" : readonly_fields,
				"display_buttons_when_readonly" : display_buttons_when_readonly,
				"columns_width": columns_width,
				"frozen_columns": frozen_columns,
				"frozen_columns_editable": frozen_columns_editable,
				"select_values": select_values,
				"default_values": default_values,
				"format_numbers": format_numbers,
				"underline_frozen": underline_frozen,
				"replace_fields": replace_fields,
				"jump_fields": jump_fields,
				"show_data": show_data,
				"can_read": can_read,
				"can_modify": can_modify,
				"allow_change_status": allow_change_status,
				"allow_change_userstatus": allow_change_userstatus,
				"language_columns": language_columns,
				"language_memo_columns": language_memo_columns,
				"show_attach_image_path": show_attach_image_path,
				"images_properties": images_properties
			}


			let methods = {
				format_cell: Format_Cell,
				item_window_params: ItemWindowParams,
				customer_window_params: CustomerWindowParams,
				supplier_window_params: SupplierWindowParams,
				manufacturer_catalog_window_params: ManufacturerCatalogWindowParams,
				doctype_window_params: DoctypeWindowParams,
				cell_callback: Cell_Callback,
				cell_button_callback: CellButton_Callback,
				memo_callback: Memo_Callback,
				duration_callback: Duration_Callback,
				table_multiselect_callback: MultiSelect_Callback,
				button_callback: Button_Callback,
				cell_click: Cell_click,
				record_change_state : RecordChangeState
			}


			silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable = new silicon_ioi.ioiDocTypeGrid('#routing_definition_table_container', table_parameters, extended_parameters, methods, data);


		});


	}

    static load_routing_definition_grid(html_field, item_id, release_id, show_data = false, check_status = false, status_value = null)
    {
        silicon_ioi.doctype.ioiRoutingDefinitionFrame.load_routing_definition_frame(html_field, item_id, release_id, show_data, check_status, status_value);
    }

	static new()
	{

		let step_id_int = 0;

		let rows = silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.ioiTable.getRows();

		if (rows) {

			for (var i = 0; i < rows.length; i++) {

				if (rows[i].getData().step_id_int > step_id_int) {
					step_id_int = rows[i].getData().step_id_int
				}
			}
		}

		step_id_int += 10;

		var insertDialog = new frappe.ui.Dialog({
			title: __("New"),

			fields: [{	label: __("Step"),
						fieldname: 'html_routing_definition_dialog_step',
						fieldtype: 'Int'
					}],
			primary_action_label: __("Ok"),
			primary_action: function(){

				let value = cur_dialog.fields_dict['html_routing_definition_dialog_step'].value

				if ((value == null) || (value == '')) {
					frappe.msgprint({title: __("Message"), message: __("Step is mandatory"), indicator: "red"});
					return false;
				}

				if (parseInt(value) <= 0) {
					frappe.msgprint({title: __("Message"), message: __("Step has to be greater than zero"), indicator: "red"});
					return false;
				}

				let rows = silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.ioiTable.getRows();

				if (rows) {

					for (var i = 0; i < rows.length; i++) {

						if (rows[i].getData().step_id_int == value) {
							frappe.msgprint({title: __("Message"), message: __("This step already exists in the grid"), indicator: "red"});
							return false;
						}
					}
				}

				let nb = 6 - value.toString().length;

				let formatted_step = '';

				for (var i = 1; i <= nb; i++) {
					formatted_step += '0';
				}

				formatted_step += value.toString();

				let s = '{';

				s += '"item_id":"' + silicon_ioi.doctype.ioiRoutingDefinitionFrame.item_id + '", ';
				s += '"routing_release_id":"' + silicon_ioi.doctype.ioiRoutingDefinitionFrame.release_id + '", ';
				s += '"step_id":"' + formatted_step + '", "step_id_int": ' + value.toString() + ', ';
				s += '"routing_release_name":"' + silicon_ioi.doctype.ioiRoutingDefinitionFrame.item_id + ' • ' + silicon_ioi.doctype.ioiRoutingDefinitionFrame.release_id + '", ';
				s += '"name":"' + silicon_ioi.doctype.ioiRoutingDefinitionFrame.item_id + ' • ' + silicon_ioi.doctype.ioiRoutingDefinitionFrame.release_id + ' • ' + formatted_step + '" ';

				s += '}';

				silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.new_row(s, false);

				insertDialog.hide();
			},
			secondary_action_label: __("Cancel"),
			secondary_action: function(){
				insertDialog.hide();
			}

		});


		insertDialog.$wrapper.find('.modal-dialog').css("max-width", "300px").css("width", "300x");

		insertDialog.show();

		silicon_ioi.doctype.ioiRoutingDefinitionFrame.sleep_static(200).then(() => {

			insertDialog.set_value('html_routing_definition_dialog_step', step_id_int);

		});

	}

	static duplicate()
	{

		if (!document.getElementById('routing_definition_action_button_save').disabled) {
			frappe.msgprint({title: __("Message"), message: __("Save the changes before duplicating"), indicator: "red"});
			return false;
		}

		let rows = silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.ioiTable.getRows();

		if (rows.length == 0) {
			return false;
		}

		let top = 10;

		let msg = '';
		let s = __("Duplicate this Routing definition ?");

		msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';

		msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';


		frappe.confirm(	msg,
			() => 	{
						silicon_ioi.doctype.ioiRoutingDefinitionFrame.sleep_static(200).then(() => {

							let step_id_int = 0;

							let rows = silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.ioiTable.getRows();

							if (rows) {

								for (var i = 0; i < rows.length; i++) {

									if (rows[i].getData().step_id_int > step_id_int) {
										step_id_int = rows[i].getData().step_id_int
									}
								}
							}

							step_id_int += 10;

							var insertDialog = new frappe.ui.Dialog({
								title: __("Duplicate"),

								fields: [{	label: __("Position"),
											fieldname: 'html_routing_definition_dialog_step',
											fieldtype: 'Int'
										}],
								primary_action_label: __("Ok"),
								primary_action: function(){

									let value = cur_dialog.fields_dict['html_routing_definition_dialog_step'].value;

									if ((value == null) || (value == '')) {
										frappe.msgprint({title: __("Message"), message: __("Step is mandatory"), indicator: "red"});
										return false;
									}

									if (parseInt(value) <= 0) {
										frappe.msgprint({title: __("Message"), message: __("Step has to be greater than zero"), indicator: "red"});
										return false;
									}

									let rows = silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.ioiTable.getRows();

									if (rows) {

										for (var i = 0; i < rows.length; i++) {

											if (rows[i].getData().step_id_int == value) {
												frappe.msgprint({title: __("Message"), message: __("This step already exists in the grid"), indicator: "red"});
												return false;
											}
										}
									}

									let nb = 6 - value.toString().length;

									let formatted_step = '';

									for (var i = 1; i <= nb; i++) {
										formatted_step += '0';
									}

									formatted_step += value.toString();

									let current_idx = silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.selected_row_index;

									let row = silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.ioiTable.getRow(current_idx);

									if (row.getData().ioistatus) {
										if (row.getData().ioistatus != 0) {
											frappe.msgprint({title: __("Message"), message: __("Status has to be in preparation"), indicator: "red"});
											return false;
										}
									}


									let current_row = silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.ioiTable.getRow(current_idx);

									silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.ioiTable.addData([current_row.getData()], false).then(function(rows){

										let r = rows[0];

										r.update({"item_id": silicon_ioi.doctype.ioiRoutingDefinitionFrame.item_id});
										r.update({"routing_release_id": silicon_ioi.doctype.ioiRoutingDefinitionFrame.release_id});
										r.update({"step_id": formatted_step});
										r.update({"step_id_int": value.toString()});
										r.update({"routing_release_name": silicon_ioi.doctype.ioiRoutingDefinitionFrame.item_id + ' • ' + silicon_ioi.doctype.ioiRoutingDefinitionFrame.release_id});
										r.update({"name": silicon_ioi.doctype.ioiRoutingDefinitionFrame.item_id + ' • ' + silicon_ioi.doctype.ioiRoutingDefinitionFrame.release_id + ' • ' + formatted_step});


										r.update({"tabulator_record_modified":"Y"});

										if (silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.RecordChangeState) {
											silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.RecordChangeState('INSERT');
										}

										silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.record_state = 'INSERT';

										silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.ioiTable.deselectRow();
										r.select();

										let nb = 0;

										let all_datas = silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.ioiTable.getData();

										for (var i = 0; i < all_datas.length; i++) {
											if (all_datas[i].tabulator_row_index > nb) {
												nb = all_datas[i].tabulator_row_index;
											}
										}

										nb++;

										r.update({"tabulator_row_index": nb});
										silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.selected_row_index = nb;

										silicon_ioi.doctype.ioiRoutingDefinitionFrame.save();

									});

									insertDialog.hide();
								},
								secondary_action_label: __("Cancel"),
								secondary_action: function(){
									insertDialog.hide();
								}

							});


							insertDialog.$wrapper.find('.modal-dialog').css("max-width", "300px").css("width", "300x");

							insertDialog.show();

							silicon_ioi.doctype.ioiRoutingDefinitionFrame.sleep_static(200).then(() => {

								insertDialog.set_value('html_routing_definition_dialog_step', step_id_int);

							});



						});
					},
			() => 	{
					}
		);


	}


	static delete()
	{

		let rows = silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.ioiTable.getRows();

		if (rows.length == 0) {
			return false;
		}

		let top = 10;

		let msg = '';
		let s = __("Delete this Routing definition ?");

		msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';

		msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';


		frappe.confirm(	msg,
			() => 	{
						silicon_ioi.doctype.ioiRoutingDefinitionFrame.sleep_static(200).then(() => {
							silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.delete_row();
						});
					},
			() => 	{
					}
		);

	}

	static save()
	{

		let updated_data = [];

		let rows = silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.ioiTable.getRows();

		if (rows) {

			for (var i = 0; i < rows.length; i++) {

				if (rows[i].getData()['tabulator_record_modified'] == 'Y') {

					updated_data[updated_data.length] = rows[i].getData();
				}

			}
		}

		let deleted_data = [];

		deleted_data = silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.deleted_rows;

		let images_uploaded_path = [];
		images_uploaded_path = silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.images_uploaded_path;

		let files_uploaded_path = [];
		files_uploaded_path = silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.files_uploaded_path;


		let allow_change_status = 0;

		if (silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.allow_change_status) {
			allow_change_status = 1;
		}


		let allow_change_user_status = 0;

		if (silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.allow_change_userstatus) {
			allow_change_user_status = 1;
		}

		let method = silicon_ioi.doctype.ioiRoutingDefinitionFrame.path_routing_definition + '.ioi_routing_definition_update_data';

		frappe.call({  	method: method,
						args: {	"data": updated_data,
								"deleted_data": deleted_data,
								"doctype_reference": silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.doctype_reference,
								"current_doctype": silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.current_doctype,
								"current_docname": silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.current_name,
								"images_uploaded_path": images_uploaded_path,
								"files_uploaded_path": files_uploaded_path,
								"allow_change_status": allow_change_status,
								"allow_change_user_status": allow_change_user_status},
						async: false,
						callback:function(r)	{

							if (r.message.error != 0) {

								let msg = '';

								for (var i = 0; i < r.message.error_msg.length; i++) {

									msg += r.message.error_msg[i].action + ' ( ' + r.message.error_msg[i].name + ' ) : ' + r.message.error_msg[i].error_msg;

									if (i < r.message.error_msg.length-1) {
											msg += '<BR>';
									}

								}
								frappe.msgprint({title: __("Message"), message: msg, indicator: "red"});
							}

							silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.data = [];

							let method = silicon_ioi.doctype.ioiRoutingDefinitionFrame.path_routing_definition + '.ioi_routing_definition_get_data';

							frappe.call({  	method: method,
											args: {	"item_id": silicon_ioi.doctype.ioiRoutingDefinitionFrame.item_id,
													"release_id": silicon_ioi.doctype.ioiRoutingDefinitionFrame.release_id
											},
											async: false,
											callback:function(r)	{
												silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.data = r.message;
												silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.set_data();
											}
							});


						}
		});

	}

	static cancel()
	{
		silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.data = [];

		let method = silicon_ioi.doctype.ioiRoutingDefinitionFrame.path_routing_definition + '.ioi_routing_definition_get_data';

		frappe.call({  	method: method,
						args: {	"item_id": silicon_ioi.doctype.ioiRoutingDefinitionFrame.item_id,
								"release_id": silicon_ioi.doctype.ioiRoutingDefinitionFrame.release_id
						},
						async: false,
						callback:function(r)	{
							silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.data = r.message;
							silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.set_data();
						}
		});

	}

	static change_step()
	{
		let rows = silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.ioiTable.getRows();

		if (rows.length == 0) {
			return false;
		}
		let current_idx = silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.selected_row_index;


		let row = silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.ioiTable.getRow(current_idx);

		let current_step_id = row.getData().step_id;


		var UpdateDialog = new frappe.ui.Dialog({
			title: __("Change step"),

			fields: [{	label: __("Current step"),
						fieldname: 'html_routing_definition_dialog_change_step_current',
						fieldtype: 'Data',
						read_only: 1
					},
					{	label: __("New step"),
						fieldname: 'html_routing_definition_dialog_change_step_new',
						fieldtype: 'Data'
					}

				],
			primary_action_label: __("Ok"),
			primary_action: function(){

				let current_value = cur_dialog.fields_dict['html_routing_definition_dialog_change_step_current'].value;

				let value = cur_dialog.fields_dict['html_routing_definition_dialog_change_step_new'].value;

				if ((value == null) || (value == '')) {
					frappe.msgprint({title: __("Message"), message: __("New step is mandatory"), indicator: "red"});
					return false;
				}

				let authorized_char = "0123456789"

				for (var i = 0; i < value.length; i++) {

					if (authorized_char.indexOf(value[i]) == -1) {
						frappe.msgprint({title: __("Message"), message: __("New step has an invalid character") + ' ' + value[i], indicator: "red"});
						return false;
					}

				}

				if (value.length != 6) {
					frappe.msgprint({title: __("Message"), message: __("New step has to have 6 positions length"), indicator: "red"});
					return false;
				}



				let rows = silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.ioiTable.getRows();

				if (rows) {

					for (var i = 0; i < rows.length; i++) {

						if (rows[i].getData().step_id == value) {
							frappe.msgprint({title: __("Message"), message: __("This step already exists in the grid"), indicator: "red"});
							return false;
						}
					}
				}

				let method = silicon_ioi.doctype.ioiRoutingDefinitionFrame.path_routing_definition + '.ioi_routing_definition_rename_step';

				frappe.call({  	method: method,
								args: {	"routing_release_name": silicon_ioi.doctype.ioiRoutingDefinitionFrame.item_id + ' • ' + silicon_ioi.doctype.ioiRoutingDefinitionFrame.release_id,
										"current_step": current_value,
										"new_step": value
								},
								async: false,
								callback:function(r)	{

									UpdateDialog.hide();

									silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.data = [];

									let method = silicon_ioi.doctype.ioiRoutingDefinitionFrame.path_routing_definition + '.ioi_routing_definition_get_data';

									frappe.call({  	method: method,
													args: {	"item_id": silicon_ioi.doctype.ioiRoutingDefinitionFrame.item_id,
															"release_id": silicon_ioi.doctype.ioiRoutingDefinitionFrame.release_id
													},
													async: false,
													callback:function(r)	{
														silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.data = r.message;
														silicon_ioi.doctype.ioiRoutingDefinitionFrame.ioiRoutingDefinitionTable.set_data();
													}
									});
								}
				});

			},
			secondary_action_label: __("Cancel"),
			secondary_action: function(){
				UpdateDialog.hide();
			}

		});


		UpdateDialog.$wrapper.find('.modal-dialog').css("max-width", "300px").css("width", "300x");

		UpdateDialog.show();

		silicon_ioi.doctype.ioiRoutingDefinitionFrame.sleep_static(200).then(() => {

			UpdateDialog.set_value('html_routing_definition_dialog_change_step_current', current_step_id);

		});
	}

	static sleep_static(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}

}

silicon_ioi.doctype.ioiRoutingDefinitionFrame = ioiRoutingDefinitionFrame;
