import { createApp } from "vue";
import FileUploaderComponent from "./FileUploader.vue";
import { watch } from "vue";


export default class ioiFileUploader {
	constructor({
		wrapper,
		method,
		on_success,
		doctype,
		docname,
		fieldname,
		files,
		folder,
		restrictions = {},
		upload_notes,
		allow_multiple,
		as_dataurl,
		disable_file_browser,
		dialog_title,
		attach_doc_image,
		frm,
		make_attachments_public,
		force_private,
		on_success_terminated,
		open_browse_file
		} = {}) {

		this.on_success_terminated = on_success_terminated;
		if (frm && Object.keys(frm.attachments) > 0){
			frm && frm.attachments.max_reached(true);
		}		

		if (!wrapper) {
			this.make_dialog(dialog_title, force_private);
		} else {
			this.wrapper = wrapper.get ? wrapper.get(0) : wrapper;
		}

		if (restrictions && !restrictions.allowed_file_types) {
			// apply global allow list if present
			let allowed_extensions = frappe.sys_defaults?.allowed_file_extensions;
			if (allowed_extensions) {
				restrictions.allowed_file_types = allowed_extensions
					.split("\n")
					.map((ext) => `.${ext}`);
			}
		}

		let app = createApp(FileUploaderComponent, {
			show_upload_button: !Boolean(this.dialog),
			doctype,
			docname,
			fieldname,
			open_browse_file,
			method,
			folder,
			on_success,
			restrictions,
			upload_notes,
			allow_multiple,
			as_dataurl,
			disable_file_browser,
			attach_doc_image,
			make_attachments_public,
			force_private,
		});
		SetVueGlobals(app);
		this.uploader = app.mount(this.wrapper);

		if (!this.dialog) {
			this.uploader.wrapper_ready = true;
		}

		watch(
			() => this.uploader.files,
			(files) => {
				let all_private = files.every((file) => file.private);
				if (this.dialog) {
					this.dialog.set_secondary_action_label(
						all_private ? __("Set all public") : __("Set all private")
					);
				}

				let all_optimize = files.some((file) => file.optimize);
				const optimized_button = $('#file_uploader_unoptimize_all')

				if (optimized_button) {
					optimized_button.text(all_optimize ? __("Unoptimize All") : __("Optimize All"))
				}
			},
			{ deep: true }
		);

		watch(
			() => this.uploader.trigger_upload,
			(trigger_upload) => {
				if (trigger_upload) {
					this.upload_files().then(() => {
						if (this.on_success_terminated){
							this.do_on_success_terminated()
						}
					});
				}
			}
		);

		watch(
			() => this.uploader.close_dialog,
			(close_dialog) => {
				if (close_dialog) {
					this.dialog && this.dialog.hide();
				}
			}
		);

		watch(
			() => this.uploader.hide_dialog_footer,
			(hide_dialog_footer) => {
				if (hide_dialog_footer) {
					this.dialog && this.dialog.footer.addClass("hide");
					this.dialog.$wrapper.data("bs.modal")._config.backdrop = "static";
				} else {
					this.dialog && this.dialog.footer.removeClass("hide");
					this.dialog.$wrapper.data("bs.modal")._config.backdrop = true;
				}
			}
		);

		if (files && files.length) {
			this.uploader.add_files(files);
		}
	}

	upload_files() {

		let result = this.uploader.upload_files().then((r) => {

			if (document.getElementById('file_bt_search')){
				document.getElementById('file_bt_search').click();
				return r;
			}
		});

		return result;
	}

	make_dialog(title, force_private = false) {
		let missing_mandatory_tag = [];
		this.dialog = new frappe.ui.Dialog({
			title: title || __("Upload"),
			primary_action_label: __("Upload"),
			primary_action: () => {

				missing_mandatory_tag = [];
				let uploader = this.uploader;
				for (var file_idx = 0; file_idx < this.uploader.files.length; file_idx++){
					frappe.call({
						method: "silicon_ioi.ioi_system.doctype.ioi_file_management.ioi_file_management.is_madatory_tags_missing",
						args: {
							doctype: frappe.get_route()[0] == 'Form' ? frappe.get_route()[1] : '',
							tags: this.uploader.files[file_idx].tags
						},
						async: false,
						callback(r) {
							missing_mandatory_tag.push(r.message);
							if (r.message){
								$($(".btn.tag .icon")[file_idx]).css('fill','red');
							}else{
								$($(".btn.tag .icon")[file_idx]).css('fill','none');
							}
						},
					});
				}

				for(var idx = 0; idx < missing_mandatory_tag.length;idx++){
					if (missing_mandatory_tag[idx]){
						frappe.msgprint({title: __('Warning'),message: __('Mandatory tags missing')})
						return
					}
				}

				if (missing_mandatory_tag){
					this.upload_files().then(() => {
						if (this.on_success_terminated){
							this.do_on_success_terminated()
						}
					});
				}
			},
			secondary_action_label: __("Set all private"),
			secondary_action: () => {
				this.uploader.toggle_all_private();
			},
			on_page_show: () => {
				this.uploader.wrapper_ready = true;

				if ($('#file_uploader_unoptimize_all')) $('#file_uploader_unoptimize_all').remove()

				this.dialog.standard_actions[0].insertAdjacentHTML('afterbegin', `<button type="button" id="file_uploader_unoptimize_all" class="btn btn-secondary btn-sm">${__('Unoptimize All')}</button>`)

				$('#file_uploader_unoptimize_all').on('click', () => {
					this.uploader.toggle_all_optimize()
				})
			},
		});

		if (force_private){
			this.dialog.get_secondary_btn().hide()
		}

		this.wrapper = this.dialog.body;
		this.dialog.show();
		this.dialog.$wrapper.on("hidden.bs.modal", function () {
			$(this).data("bs.modal", null);
			$(this).remove();
		});
	}

	do_on_success_terminated(){
		let files = [];

		this.uploader.files.forEach(element => {
			files.push({original_name: element.file_obj.name, final_doc_obj : element.doc})
		});
		this.on_success_terminated(files);
	}

	static async show_tags_dialog(doctype, doc_name, filename, tags) {
		return new Promise((resolve) => {
			let groups = []
			let call_result = false;
			let fields = []
			let dialog;
			frappe.call({
				method: "silicon_ioi.ioi_system.doctype.ioi_file_management.ioi_file_management.get_available_tags",
				args: {
					doctype: frappe.get_route()[0] == 'Form' ? frappe.get_route()[1] : '',
				},
				async: false,
				callback(r) {
					r = r.message;
					if (r.result) {
						groups = r.groups;
						call_result = true;

					}
				},
			});

			for (var i = 0; i < groups.length; i++) {
				let grp = groups[i];
				let def_val = "";
				let def_vals = [];

				for (var x = 0; x < tags.length; x++) {
					if (grp.tags.find(element => element == tags[x])) {
						def_val = tags[x];
						def_vals.push(tags[x]);
					}
				}


				let fld =
				{
					'fieldtype': grp.multiselect == 1 ? 'MultiSelectPills' : 'Select',
					'fieldname': 'group_' + i,
					'default': grp.multiselect == 1 ? def_vals : def_val,
					'options': grp.multiselect == 1 ? '' : (grp.mandatory == 0 ? '\n' : '') + grp.tags.join('\n'),
					'label': __(grp.group_name),
					'reqd': grp.mandatory,
					get_data: grp.multiselect == 1 ? function (txt) {
						let result = [];
						let values = [];

						if (dialog) {
							values = dialog.fields_dict[this.fieldname].value;
						}

						for (var idx = 0; idx < grp.tags.length; idx++) {
							if (!values.find(element => element == grp.tags[idx])) {
								result.push({ 'label': grp.tags[idx], 'value': grp.tags[idx] })
							}
						}
						return result;
					} : ''
				};
				fields.push(fld);
			}

			if (call_result) {
				dialog = new frappe.ui.Dialog({
					title: __("Tags") + `: ${filename}`,
					fields: fields,
					primary_action_label: __("Validate"),
					primary_action: () => {
						let selected_tags = [];
						for (var grp = 0; grp < groups.length; grp++) {
							let values = dialog.fields_dict["group_" + grp].value;
							if (typeof values === 'string') {
								if (groups[grp].tags.find(element => element == values)) {
									selected_tags.push(values)
								}
							} else {
								if (values != null) {
									for (var tag_idx = 0; tag_idx < values.length; tag_idx++) {
										if (groups[grp].tags.find(element => element == values[tag_idx])) {
											selected_tags.push(values[tag_idx])
										}
									}
								}
							}


						}
						dialog.hide();
						resolve({
							"success": true,
							"tags": selected_tags
						});
					},
					secondary_action_label: __("Cancel"),
					secondary_action: () => {
						dialog.hide();
						resolve({
							"success": false,
							"tags": []
						});
					},
					on_page_show: () => {
						$('.modal.fade.show').unbind('click');
						$('.modal-actions').hide();
					},
				});

				dialog.show();

			} else {
				frappe.msgprint(__('Please configure this doctype to use the tag management.') + ` => <a href="#" onclick="window.open(\'/app/ioi-file-management/')">${__('Open settings')}</a>`)
			}
		});
	}
}


$(document).ready(() => {
	frappe.provide("frappe.ui");
	frappe.ui.FileUploader = ioiFileUploader;
})
