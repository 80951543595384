frappe.provide('silicon_ioi.doctype');

export class ioiManufacturingDefinitionFrame
{
	static path_common = 'silicon_ioi.common.common';
	static path_general_settings = 'silicon_ioi.ioi_configuration.doctype.ioi_general_settings.ioi_general_settings';
	static path_manufacturing_definition = 'silicon_ioi.ioi_items.doctype.ioi_item_manufacturing_definition.ioi_item_manufacturing_definition';


    static html_field = '';
	static item_id = '';
	static release_id = '';
	static show_data = false;
	static can_read = false;
	static can_modify = false;
	static columns = [];
	static data = [];

	static ioiManufacturingDefinitionTable = null;


	static load_manufacturing_definition_frame(html_field, item_id, show_data = false, current_manufacturing_definition_name = null)
	{
        silicon_ioi.doctype.ioiManufacturingDefinitionFrame.html_field = html_field;
		silicon_ioi.doctype.ioiManufacturingDefinitionFrame.item_id = item_id;
		
		silicon_ioi.doctype.ioiManufacturingDefinitionFrame.show_data = show_data;
		silicon_ioi.doctype.ioiManufacturingDefinitionFrame.can_read = true;
		silicon_ioi.doctype.ioiManufacturingDefinitionFrame.can_modify = false;

		if (document.getElementById('manufacturing_definition_table_container')) {
			document.getElementById('manufacturing_definition_table_container').remove();
		}

		if (document.getElementById('manufacturing_definition_legend')) {
			document.getElementById('manufacturing_definition_legend').remove();
		}

		

		let html = '';

		
	
		html += '<div id="manufacturing_definition_table_container" class="table table-bordered" data-custom-grid="true" style="overflow: auto; overflow-x: auto; overflow-y: hidden; height:300px;border: 1px solid #E6E9EC;">';	
		html += '</div>';

	
		html += '<div id="manufacturing_definition_legend" style="overflow: auto; overflow-x: auto; overflow-y: hidden; height:30px; border: 0px solid #E6E9EC;">';	

		html += '	<div style="position: relative; top: 0px; left:0px; width:200px; height:30px; border: 1px solid #E6E9EC; background:#d0e7fb">';		
		html += '		<div style="position: absolute; top:4px; height: 30px; width:200px;text-align:center" align="center">';
		html +='			<label style=""><b>' + __("Item version") + '</b></label>';
		html += '		</div>';
		html += '	</div>';

		html += '	<div style="position: relative; top: -30px; left:210px; width:200px; height:30px; border: 1px solid #E6E9EC; background:#fcf5bb">';		
		html += '		<div style="position: absolute; top:4px; height: 30px; width:200px;text-align:center" align="center">';
		html +='			<label style=""><b>' + __("Site version") + '</b></label>';
		html += '		</div>';
		html += '	</div>';

		html += '	<div style="position: relative; top: -60px; left:420px; width:200px; height:30px; border: 1px solid #E6E9EC; background:#f7ceb0">';		
		html += '		<div style="position: absolute; top:4px; height: 30px; width:200px;text-align:center" align="center">';
		html +='			<label style=""><b>' + __("Item & Site version") + '</b></label>';
		html += '		</div>';
		html += '	</div>';

		html += '</div>';

		cur_frm.fields_dict[html_field].$wrapper.empty();
		cur_frm.fields_dict[html_field].$wrapper.append(html);


		silicon_ioi.doctype.ioiManufacturingDefinitionFrame.sleep_static(200).then(() => {

			let max_height = 600;
			let row_height = null;
			let layout = null;
			let selectable = 1;
			let show_profiles = false;
			let movable_columns = true;
			let resizable_columns = true;
			let auto_redraw = true;

			let table_parameters = {
				"maxHeight": max_height,
				"rowHeight": row_height,
				//"layout": layout,
				"selectable": selectable,
				"showProfiles": show_profiles,
				"movableColumns": movable_columns,
				"resizableColumns": resizable_columns,
				"autoRedraw": auto_redraw
			};

			let doctype = 'ioi Item Manufacturing Definition';
			let doctype_reference = 'ioi Item Manufacturing Definition'

			let display_fields = ['name', 'description', 'ioistatus', 'bom_release_name', 'routing_release_name'];	

			let display_custom_fields = false;
			let primary_fields_not_name = ['item_id', 'release_id'];
            
			let exclude_fields = ['is_warning', 'warninfo'];

			let change_title = [];
			change_title[0] = ['name', __("Version(s)")];
			change_title[1] = ['ioistatus', __("Status")];
			change_title[2] = ['bom_release_name', __("BOM release")];
			change_title[3] = ['routing_release_name', __("Routing release")];
			

			let visible_hidden_columns = ['name', 'ioistatus'];
			let hide_visible_columns = [];
            
			let readonly_fields = [];

			let display_buttons_when_readonly = []; 

			let columns_width = [];

			columns_width[0] = ['description', 500];
			columns_width[1] = ['ioistatus', 250];
			columns_width[2] = ['bom_release_name', 250];
			columns_width[3] = ['routing_release_name', 250];


			let frozen_columns = ['name'];
			let frozen_columns_editable = [false];

			let select_values = [];
			let default_values = [];

			let format_numbers = []

			let replace_fields = [];
			let jump_fields = ['name', 'bom_release_name', 'routing_release_name']



			let show_data = silicon_ioi.doctype.ioiManufacturingDefinitionFrame.show_data;
			let can_read = silicon_ioi.doctype.ioiManufacturingDefinitionFrame.can_read;
			let can_modify = silicon_ioi.doctype.ioiManufacturingDefinitionFrame.can_modify;


			let allow_change_status = false;
			let allow_change_userstatus = false

			let language_columns = ['description_lg1', 'description_lg2', 'description_lg3', 'description_lg4'];
			let language_memo_columns = ['memo_lg1', 'memo_lg2', 'memo_lg3', 'memo_lg4'];

			let show_attach_image_path = [];
			

			let images_properties = []

		
			let data = [];

			if (show_data) {

				let method = silicon_ioi.doctype.ioiManufacturingDefinitionFrame.path_manufacturing_definition + '.ioi_item_manufacturing_definition_get_data';

                frappe.call({  	method: method, 
								args: {	"item_id": silicon_ioi.doctype.ioiManufacturingDefinitionFrame.item_id,
								},
								async: false,
								callback:function(r)	{ 
									data = r.message;

								}
				});
			}
			let site_manufacturing_definition = ''

			if (data.length > 0) {

				for (var i = 0; i < data.length; i++) {

					site_manufacturing_definition = data[i].site_manufacturing_definition;
					break;
				}
			}

			let item_color = '#d0e7fb';
			let site_color = '#fcf5bb'

			if (current_manufacturing_definition_name == site_manufacturing_definition) {
				item_color = '#f7ceb0';
				site_color = '#f7ceb0';
			}

			let Format_Cell = function(col, cell, input) {

				if (cell.getColumn().getField().toUpperCase() == 'NAME') {

					if (current_manufacturing_definition_name != null) {

						if (current_manufacturing_definition_name.trim() != '') {

							if ((cell.getValue() != null) && (cell.getValue() != '')) {

								if (cell.getValue() == current_manufacturing_definition_name) {

									let cells = cell.getRow().getCells();

									for (var i = 0; i < cells.length; i++) {
										cells[i].getElement().style.backgroundColor = item_color;
									}
								}
							}
						}
					}

					if (site_manufacturing_definition != null) {

						if (site_manufacturing_definition.trim() != '') {

							if ((cell.getValue() != null) && (cell.getValue() != '')) {

								if (cell.getValue() == site_manufacturing_definition) {

									let cells = cell.getRow().getCells();

									for (var i = 0; i < cells.length; i++) {
										cells[i].getElement().style.backgroundColor = site_color;
									}
								}
							}
						}
					}

				}
			}


			let ItemWindowParams = function(bt_fieldname, fieldname, row) {

				let fields = 'a.name, a.description, a.unit_id, b.q_stock, b.q_reserved';
				let fields_len = '300, 500, 125, 150, 150';
				let fields_desc = 'Identification, Description, Unit, Stock qty, Reserved qty';

				let where = 'ioistatus = 2';
				let order_by = 'a.name asc';
			
				let parameter_with_stock = false;
				let site_id = '';

				return [fields, fields_len, fields_desc, where, order_by, parameter_with_stock, site_id];
			

			};


			let CustomerWindowParams = function(bt_fieldname, fieldname, row) {

				let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email';
				let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400';
				let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email';
				let where = 'ioistatus = 2';
				let order_by = 'name asc';

				return [fields, fields_len, fields_desc, where, order_by];
			
			};

			let SupplierWindowParams = function(bt_fieldname, fieldname, row) {

				let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email';
				let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400';
				let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email';
				let where = 'ioistatus = 2';
				let order_by = 'name asc';
	
				return [fields, fields_len, fields_desc, where, order_by];
			
			};

			let ManufacturerCatalogWindowParams = function(bt_fieldname, fieldname, row) {

				let fields = 'a.name, a.item_id, a.description, a.unit_id, a.item_id_live_creation, b.supplier_id, a.barcode_reference, ';
				let fields_len = '300, 200, 350, 100, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200';
				let fields_desc = 'Identification, Item, Description, Unit, Item id live creation, Supplier, Barcode ref, ';
				let where = "a.enabled = 1 ";
				let order_by = 'a.name asc';
											
				let document_date = null;
				let currency_id = null;
			
				return [fields, fields_len, fields_desc, where, order_by, document_date, currency_id];
			
			};


			let DoctypeWindowParams = function(bt_fieldname, fieldname, row, cell) {

				let title = __("Select");
				let form_width_pixel = '710px';
				let fields = 'name, description';
				let fields_len = '250, 380';
				let fields_desc = __('Identification') + ',' + __('Description');
				let where = '';
				let order_by = 'name asc';
	
				return [title, form_width_pixel, fields, fields_len, fields_desc, where, order_by];
			};

		
			let Cell_Callback = function(col, row, input, cell) {

			}


			let CellButton_Callback = function(fieldname, return_value, row) {

			}

			let Memo_Callback = function(fieldname, output, row) {

			};

			let Duration_Callback = function(fieldname, ret_value, row) {
			
			};

			let MultiSelect_Callback = function(fieldname, ret_value, row) {
			
			};


			let Button_Callback = function(bt_fieldname, cell, row, button) {
			};

			let RecordChangeState = function(record_state) {
			}

			let extended_parameters = {
				"doctype": doctype,
				"doctype_reference": doctype_reference,
				"display_fields": display_fields,
				"display_custom_fields": display_custom_fields,
				"primary_fields_not_name": primary_fields_not_name,
				"exclude_fields": exclude_fields,
				"change_title": change_title,
				"visible_hidden_columns": visible_hidden_columns,
				"hide_visible_columns" : hide_visible_columns,
				"readonly_fields" : readonly_fields,
				"display_buttons_when_readonly" : display_buttons_when_readonly,
				"columns_width": columns_width,
				"frozen_columns": frozen_columns,
				"frozen_columns_editable": frozen_columns_editable,
				"select_values": select_values,
				"default_values": default_values,
				"format_numbers": format_numbers,
				"replace_fields": replace_fields,
				"jump_fields": jump_fields,
				"show_data": show_data,
				"can_read": can_read,
				"can_modify": can_modify,
				"allow_change_status": allow_change_status,
				"allow_change_userstatus": allow_change_userstatus,
				"language_columns": language_columns,
				"language_memo_columns": language_memo_columns,
				"show_attach_image_path": show_attach_image_path,
				"images_properties": images_properties
			}


			let methods = {
				format_cell: Format_Cell,
				item_window_params: ItemWindowParams,
				customer_window_params: CustomerWindowParams,
				supplier_window_params: SupplierWindowParams,
				manufacturer_catalog_window_params: ManufacturerCatalogWindowParams,
				doctype_window_params: DoctypeWindowParams,
				cell_callback: Cell_Callback,
				cell_button_callback: CellButton_Callback,
				memo_callback: Memo_Callback,
				duration_callback: Duration_Callback,
				table_multiselect_callback: MultiSelect_Callback,
				button_callback: Button_Callback,
				record_change_state : RecordChangeState
			}


			silicon_ioi.doctype.ioiManufacturingDefinitionFrame.ioiManufacturingDefinitionTable = new silicon_ioi.ioiDocTypeGrid('#manufacturing_definition_table_container', table_parameters, extended_parameters, methods, data);

	
        });


	}

    static load_manufacturing_definition_grid(html_field, item_id, show_data = false, current_manufacturing_definition_name = null)
    {
        silicon_ioi.doctype.ioiManufacturingDefinitionFrame.load_manufacturing_definition_frame(html_field, item_id, show_data, current_manufacturing_definition_name);
    }

	static sleep_static(ms) 
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}

}

silicon_ioi.doctype.ioiManufacturingDefinitionFrame = ioiManufacturingDefinitionFrame;