frappe.provide('silicon_ioi.doctype');

export class ioiGlobalExplorer
{
	static path_jit_engine_link = 'silicon_ioi.ioi_jit.doctype.ioi_jit_engine_link.ioi_jit_engine_link';
	static path_global_explorer_user_list = 'silicon_ioi.ioi_configuration.doctype.ioi_global_explorer_user_list.ioi_global_explorer_user_list';


	static is_dark_mode = 0;
	static bgcolor = 0;

	static ioiTable = null;
	static columns = [];
	static data = [];
	static configuration_doctypes = [];
	static configuration_doctype_fields = [];
	static back_list = [];
	static next_list = [];

	static is_global_explorer = 1;

	static record_name = ''
	static from_doctype = '';
	static from_name = '';
	static from_detail_idx = '';
	static from_detail_name = '';
	static topn = -1;
	static where = '';
	static having = '';
	static sq = 0;
	static so = 0;
	static sd = 0;
	static si = 0;
	static pq = 0;
	static po = 0;
	static pr = 0;
	static pi = 0;
	static ppr = 0;
	static odp = 0;
	static dos = 0;
	static tech_fieldname = 0;
	static default = 0;
	static tab_fields = [];
	static tab_fieldtypes = [];
	static tab_isjumps = [];
	static tab_isformulas = [];
	static tab_formulas_description = [];
	static tab_orderby = [];
	static tab_orderby_sens = [];

	static summary = 0
	static tab_summary_fields = [];
	static tab_summary_action = [];

	static show_global_explorer(doctype, name, container_html)
	{
		if (document.getElementById('html_ioi_explorer_main_parameters')) {
			document.getElementById('html_ioi_explorer_main_parameters').remove();
		}

		if (document.getElementById('html_ioi_explorer_main_page')) {
			document.getElementById('html_ioi_explorer_main_page').remove();
		}

		let html = '';

		html += '<div id="html_ioi_explorer_main_parameters" style="overflow: auto; overflow-x: auto; height:0px;display:none;"></div>';
		html += '<div id="html_ioi_explorer_main_page" style="overflow: auto; overflow-x: auto; height:860px;"></div>';

		container_html.$wrapper.empty();
		container_html.$wrapper.append(html);

		silicon_ioi.doctype.ioiGlobalExplorer.is_global_explorer = 0;

		silicon_ioi.doctype.ioiGlobalExplorer.sleep_static(200).then(() => {

			silicon_ioi.doctype.ioiGlobalExplorer.refresh_main_parameters(null, doctype, name, document.getElementById('html_ioi_explorer_main_parameters'));
			silicon_ioi.doctype.ioiGlobalExplorer.refresh_result(null, doctype, name, document.getElementById('html_ioi_explorer_main_page'));
			silicon_ioi.doctype.ioiGlobalExplorer.sleep_static(2000).then(() => {
				//document.getElementById('ioi_global_explorer_refresh').click();
				silicon_ioi.doctype.ioiGlobalExplorer.do_refresh(false, false, true)
			});

		});
	}


	static refresh_main_parameters(html_obj = null, current_dc = null, current_name = null, html_component = null)
	{
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_from_doctype_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_from_doctype');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_from_name_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_from_name');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_from_idx_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_from_idx');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_topn_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_topn');


		let html = ''

		html += '<div style="overflow: auto; overflow-x: auto; height:65px;">';

		// From Doctype
		html += '	<div style="position: relative; top: 0px; left: 0px; width:220px;">';
		html += '		<label id="ioi_global_explorer_from_doctype_label" style="position: absolute; top: 0px; left: 2px;">' + __("From doctype") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 220px; height: 30px;"> ';
		html += '			<select id="ioi_global_explorer_from_doctype" class="input-with-feedback form-control bold"> ';
		html += "				<option value='ioi Sales Quote'>" 				+ __("Sales Quote") 			+ "</option>"
		html += "				<option value='ioi Sales Order'>" 				+ __("Sales Order") 			+ "</option>"
		html += "				<option value='ioi Sales Delivery'>" 			+ __("Sales Delivery") 			+ "</option>"
		html += "				<option value='ioi Sales Invoice'>" 			+ __("Sales Invoice") 			+ "</option>"
		html += "				<option value='ioi Purchases Price Request'>" 	+ __("Purchases Price Request") + "</option>"
		html += "				<option value='ioi Purchases Order'>" 			+ __("Purchases Order") 		+ "</option>"
		html += "				<option value='ioi Purchases Receipt'>" 		+ __("Purchases Receipt") 		+ "</option>"
		html += "				<option value='ioi Purchases Invoice'>" 		+ __("Purchases Invoice") 		+ "</option>"
		html += "				<option value='ioi Production'>" 				+ __("Production") 				+ "</option>"
		html += "				<option value='ioi Production BOM'>" 			+ __("Production BOM") 			+ "</option>"
		html += "				<option value='ioi Dossier BOM'>" 				+ __("Dossier BOM") 			+ "</option>"
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		// From name
		html += '	<div style="position: relative; top: 0px; left: 230px; width:220px;">';
		html += '		<label id="ioi_global_explorer_from_name_label" style="position: absolute; top: 0px; left: 2px;">' + __("From name") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 220px; height: 25px;"> ';
		html += '			<input id="ioi_global_explorer_from_name" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value="" >';
		html += '		</div>';
		html += '	</div>';

		// From idx
		html += '	<div style="position: relative; top: 0px; left: 460px; width:150px;">';
		html += '		<label id="ioi_global_explorer_from_idx_label" style="position: absolute; top: 0px; left: 2px;">' + __("From nr line") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 150px; height: 25px;"> ';
		html += '			<input id="ioi_global_explorer_from_idx" type="number" step="any" class="input-with-feedback form-control bold" value="" >';
		html += '		</div>';
		html += '	</div>';

		// Topn
		html += '	<div style="position: relative; top: 0px; left: 620px; width:150px;">';
		html += '		<label id="ioi_global_explorer_topn_label" style="position: absolute; top: 0px; left: 2px;">' + __("Nr Records") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 150px; height: 25px;"> ';
		html += '			<input id="ioi_global_explorer_topn" type="number" step="any" class="input-with-feedback form-control bold" value="1000">';
		html += '		</div>';
		html += '	</div>';

		html += '</div>';

		if (html_obj) {
			html_obj.$wrapper.empty();
			html_obj.$wrapper.append(html);
		}else if (html_component) {
			html_component.insertAdjacentHTML('beforeend', html);
		}

		silicon_ioi.doctype.ioiGlobalExplorer.sleep_static(400).then(() => {

			let fct_change = function() {

				if (this.value == 'ioi Sales Quote') {
					document.getElementById('ioi_global_explorer_sq').checked = true;
				}else if (this.value == 'ioi Sales Order') {
					document.getElementById('ioi_global_explorer_so').checked = true;
				}else if (this.value == 'ioi Sales Delivery') {
					document.getElementById('ioi_global_explorer_sd').checked = true;
				}else if (this.value == 'ioi Sales Invoice') {
					document.getElementById('ioi_global_explorer_si').checked = true;
				}else if (this.value == 'ioi Purchases Price Request') {
					document.getElementById('ioi_global_explorer_pq').checked = true;
				}else if (this.value == 'ioi Purchases Order') {
					document.getElementById('ioi_global_explorer_po').checked = true;
				}else if (this.value == 'ioi Purchases Receipt') {
					document.getElementById('ioi_global_explorer_pr').checked = true;
				}else if (this.value == 'ioi Purchases Invoice') {
					document.getElementById('ioi_global_explorer_pi').checked = true;
				}else if (this.value == 'ioi Production') {
					document.getElementById('ioi_global_explorer_odp').checked = true;
				}else if (this.value == 'ioi Production BOM') {
					document.getElementById('ioi_global_explorer_odp').checked = true;
				}else if (this.value == 'ioi Dossier BOM') {
					document.getElementById('ioi_global_explorer_dos').checked = true;
				}

				silicon_ioi.doctype.ioiGlobalExplorer.load_combo_list_name();

				if (document.getElementById('ioi_global_explorer_combo_list_name').options.length > 0) {

					silicon_ioi.doctype.ioiGlobalExplorer.load_report_from_combo(document.getElementById('ioi_global_explorer_combo_list_name').value);
				}


			}

			document.getElementById('ioi_global_explorer_from_doctype').onchange = fct_change;

			if (current_dc) {
				document.getElementById('ioi_global_explorer_from_doctype').value = current_dc;
			}

			if (current_name) {
				document.getElementById('ioi_global_explorer_from_name').value = current_name;
			}


		});

	}

	static load_default_list(current_dc)
	{

		if (current_dc == null) {
			current_dc = 'ioi Sales Quote';
		}

		silicon_ioi.doctype.ioiGlobalExplorer.back_list = [];

		if (document.getElementById('ioi_global_explorer_drill_back_label').style.display == 'block') {
			document.getElementById('ioi_global_explorer_drill_back_label').style.display = 'none';
		}


		silicon_ioi.doctype.ioiGlobalExplorer.next_list = [];

		if (document.getElementById('ioi_global_explorer_drill_next_label').style.display == 'block') {
			document.getElementById('ioi_global_explorer_drill_next_label').style.display = 'none';
		}

		silicon_ioi.doctype.ioiGlobalExplorer.clear_screen();



		let method = silicon_ioi.doctype.ioiGlobalExplorer.path_global_explorer_user_list + '.ioi_global_explorer_user_get_default_list'

		let rec_name = '';

		frappe.call({
			method: method,
			args: {	"from_doctype": current_dc
			},
			async: false,
			callback:function(r)	{
				rec_name = r.message;
			}
		});



		if (rec_name != '') {

			let method = silicon_ioi.doctype.ioiGlobalExplorer.path_global_explorer_user_list + '.ioi_global_explorer_user_list_get'

			let rec = {};

			frappe.call({
				method: method,
				args: {	"name": rec_name
				},
				async: false,
				callback:function(r)	{
					rec = r.message;
				}
			});


			silicon_ioi.doctype.ioiGlobalExplorer.record_name = rec.name;
			silicon_ioi.doctype.ioiGlobalExplorer.list_name = rec.list_name;
			silicon_ioi.doctype.ioiGlobalExplorer.from_doctype = rec.from_doctype;
			silicon_ioi.doctype.ioiGlobalExplorer.from_name = rec.from_name;
			silicon_ioi.doctype.ioiGlobalExplorer.from_detail_idx = rec.from_idx;
			silicon_ioi.doctype.ioiGlobalExplorer.from_detail_name = '';
			silicon_ioi.doctype.ioiGlobalExplorer.topn = rec.topn;
			silicon_ioi.doctype.ioiGlobalExplorer.where = rec.where_clause;
			silicon_ioi.doctype.ioiGlobalExplorer.having = rec.having_clause;
			silicon_ioi.doctype.ioiGlobalExplorer.sq = rec.sq;
			silicon_ioi.doctype.ioiGlobalExplorer.so = rec.so;
			silicon_ioi.doctype.ioiGlobalExplorer.sd = rec.sd;
			silicon_ioi.doctype.ioiGlobalExplorer.si = rec.si;
			silicon_ioi.doctype.ioiGlobalExplorer.pq = rec.pq;
			silicon_ioi.doctype.ioiGlobalExplorer.po = rec.po;
			silicon_ioi.doctype.ioiGlobalExplorer.pr = rec.pr;
			silicon_ioi.doctype.ioiGlobalExplorer.pi = rec.pi;
			silicon_ioi.doctype.ioiGlobalExplorer.ppr = rec.ppr;
			silicon_ioi.doctype.ioiGlobalExplorer.odp = rec.odp;
			silicon_ioi.doctype.ioiGlobalExplorer.dos = rec.dos;
			silicon_ioi.doctype.ioiGlobalExplorer.tech_fieldname = rec.tech_fieldname;
			silicon_ioi.doctype.ioiGlobalExplorer.default = rec.is_default;
			silicon_ioi.doctype.ioiGlobalExplorer.tab_fields = rec.tab_fields;
			silicon_ioi.doctype.ioiGlobalExplorer.tab_isjumps = rec.tab_isjumps;
			silicon_ioi.doctype.ioiGlobalExplorer.tab_isformulas = rec.tab_isformulas;
			silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description = rec.tab_formulas_descripion;
			silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby = rec.tab_orderby;
			silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby_sens = rec.tab_orderby_sens;
			silicon_ioi.doctype.ioiGlobalExplorer.summary = rec.summary
			silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields = rec.tab_summary_fields;
			silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action = rec.tab_summary_action;



			document.getElementById('ioi_global_explorer_from_doctype').value = silicon_ioi.doctype.ioiGlobalExplorer.from_doctype;
			document.getElementById('ioi_global_explorer_from_name').value = silicon_ioi.doctype.ioiGlobalExplorer.from_name;
			document.getElementById('ioi_global_explorer_from_idx').value = silicon_ioi.doctype.ioiGlobalExplorer.from_detail_idx;
			document.getElementById('ioi_global_explorer_topn').value = silicon_ioi.doctype.ioiGlobalExplorer.topn;
			document.getElementById('ioi_global_explorer_where').value = silicon_ioi.doctype.ioiGlobalExplorer.where;

			document.getElementById('ioi_global_explorer_sq').checked = false;

			if (silicon_ioi.doctype.ioiGlobalExplorer.sq == 1) {
				document.getElementById('ioi_global_explorer_sq').checked = true;
			}

			document.getElementById('ioi_global_explorer_so').checked = false;

			if (silicon_ioi.doctype.ioiGlobalExplorer.so == 1) {
				document.getElementById('ioi_global_explorer_so').checked = true;
			}

			document.getElementById('ioi_global_explorer_sd').checked = false;

			if (silicon_ioi.doctype.ioiGlobalExplorer.sd == 1) {
				document.getElementById('ioi_global_explorer_sd').checked = true;
			}

			document.getElementById('ioi_global_explorer_si').checked = false;

			if (silicon_ioi.doctype.ioiGlobalExplorer.si == 1) {
				document.getElementById('ioi_global_explorer_si').checked = true;
			}

			document.getElementById('ioi_global_explorer_pq').checked = false;

			if (silicon_ioi.doctype.ioiGlobalExplorer.pq == 1) {
				document.getElementById('ioi_global_explorer_pq').checked = true;
			}

			document.getElementById('ioi_global_explorer_po').checked = false;

			if (silicon_ioi.doctype.ioiGlobalExplorer.po == 1) {
				document.getElementById('ioi_global_explorer_po').checked = true;
			}

			document.getElementById('ioi_global_explorer_pr').checked = false;

			if (silicon_ioi.doctype.ioiGlobalExplorer.pr == 1) {
				document.getElementById('ioi_global_explorer_pr').checked = true;
			}

			document.getElementById('ioi_global_explorer_pi').checked = false;

			if (silicon_ioi.doctype.ioiGlobalExplorer.pi == 1) {
				document.getElementById('ioi_global_explorer_pi').checked = true;
			}

			document.getElementById('ioi_global_explorer_ppr').checked = false;

			if (silicon_ioi.doctype.ioiGlobalExplorer.ppr == 1) {
				document.getElementById('ioi_global_explorer_ppr').checked = true;
			}

			document.getElementById('ioi_global_explorer_odp').checked = false;

			if (silicon_ioi.doctype.ioiGlobalExplorer.odp == 1) {
				document.getElementById('ioi_global_explorer_odp').checked = true;
			}


			document.getElementById('ioi_global_explorer_dos').checked = false;

			if (silicon_ioi.doctype.ioiGlobalExplorer.dos == 1) {
				document.getElementById('ioi_global_explorer_dos').checked = true;
			}

			document.getElementById('ioi_global_explorer_columns_with_fieldname').checked = false;

			if (silicon_ioi.doctype.ioiGlobalExplorer.tech_fieldname == 1) {
				document.getElementById('ioi_global_explorer_columns_with_fieldname').checked = true;
			}

			document.getElementById('ioi_global_explorer_columns_with_fieldname').checked = false;

			if (silicon_ioi.doctype.ioiGlobalExplorer.default == 1) {
				document.getElementById('ioi_global_explorer_default').checked = true;
			}


			silicon_ioi.doctype.ioiGlobalExplorer.refresh();
		}
	}

	static refresh_result(html_obj = null, current_dc = null, current_name = null, html_component = null)
	{

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_where_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_where');


		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_where_help_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_where_help');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_where_area');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_sq_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_sq');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_so_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_so');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_sd_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_sd');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_si_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_si');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_pq_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_pq');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_po_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_po');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_pr_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_pr');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_pi_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_pi');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_ppr_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_ppr');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_odp_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_odp');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_dos_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_dos');


		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_columns_with_fieldname_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_columns_with_fieldname');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_default_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_default');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_drill_through_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_drill_through');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_drill_back_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_drill_next_label');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_refresh');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_new');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_open');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_delete');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_saveas');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_export');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_getsql');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_updown');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_combo_list_name');


		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_fields');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_table_container');



		let html = ''

		html += '<div id="ioi_global_explorer_where_area" style="overflow: auto; overflow-x: auto; height:0px;">';

		html += '	<div style="position: relative; top: 0px; left: 0px; width:630px;">';
		html += '		<label id="ioi_global_explorer_where_label" style="position: absolute; top: 0px; left: 2px;">' + __("Conditions (SQL without where) ") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 30px; left: 0px; width: 630px; height: 25px;"> ';
		html += '			<textarea id="ioi_global_explorer_where" style="position: absolute; top: 0px; left: 2px; width: 630px; height: 80px; resize: none;" class="input-with-feedback form-control " >';
		html += '			</textarea>';
		html += '		</div>';
		html += '	</div>';

		html += '	<div style="position: relative; top: 0px; left: 640px; width:350px;">';
		html += '		<label id="ioi_global_explorer_where_help_label" style="position: absolute; top: 0px; left: 2px;">' + __("Table alias") + ': <font color="blue">so.order_customer_id</font>' + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 30px; left: 0px; width: 350px; height: 25px;"> ';
		html += '			<textarea id="ioi_global_explorer_where_help" style="position: absolute; top: 0px; left: 2px; width: 350px; height: 80px; resize: none;" class="input-with-feedback form-control" readonly>';
		html += 'sq. / sq_detail. : ' + __("Quote / detail") + '\n';
		html += 'sq_ioicustomer_order. : ' + __("Quote Order Cust.") + '\n';
		html += 'sq_ioicustomer_delivery. : ' + __("Quote Del. Cust.") + '\n';
		html += 'sq_ioicustomer_invoice. : ' + __("Quote Inv. Cust.") + '\n';
		html += 'sq_detail_ioiitem. : ' + __("Quote Item") + '\n';
		html += 'sq_detail_ioimanufacturercatalog. : ' + __("Q. Manuf.") + '\n';

		html += 'so. / so_detail. : ' + __("Order / detail") + '\n';
		html += 'so_ioicustomer_order. : ' + __("Order Order Cust.") + '\n';
		html += 'so_ioicustomer_delivery. : ' + __("Order Del. Cust.") + '\n';
		html += 'so_ioicustomer_invoice. : ' + __("Order Inv. Cust.") + '\n';
		html += 'so_detail_ioiitem. : ' + __("Order Item") + '\n';
		html += 'so_detail_ioimanufacturercatalog. : ' + __("Order Manuf.") + '\n';

		html += 'sd. / sd_detail. : ' + __("Delivery / detail") + '\n'
		html += 'sd_ioicustomer_order. : ' + __("Delivery Order Cust.") + '\n';
		html += 'sd_ioicustomer_delivery. : ' + __("Delivery Del. Cust.") + '\n';
		html += 'sd_ioicustomer_invoice. : ' + __("Delivery Inv. Cust.") + '\n';
		html += 'sd_detail_ioiitem. : ' + __("Delivery Item") + '\n';
		html += 'sd_detail_ioimanufacturercatalog. : ' + __("Del Manuf.") + '\n';

		html += 'si. / si_detail. : ' + __("Invoice / detail") + '\n';
		html += 'si_ioicustomer_order. : ' + __("Invoice Order Cust.") + '\n';
		html += 'si_ioicustomer_delivery. : ' + __("Invoice Del. Cust.") + '\n';
		html += 'si_ioicustomer_invoice. : ' + __("Invoice Inv. Cust.") + '\n';
		html += 'si_detail_ioiitem. : ' + __("Invoice Item") + '\n';
		html += 'si_detail_ioimanufacturercatalog. : ' + __("Inv Manuf.") + '\n';


		html += 'pq. / pq_detail. : ' + __("Price Request / detail") + '\n';
		html += 'pq_ioisupplier_order. : ' + __("P. Req. Order Supplier") + '\n';
		html += 'pq_ioisupplier_delivery. : ' + __("P. Req. Rec. Supplier") + '\n';
		html += 'pq_ioisupplier_invoice. : ' + __("P. Req. Inv. Supplier") + '\n';
		html += 'pq_detail_ioiitem. : ' + __("P. Req. Item") + '\n';
		html += 'pq_detail_ioimanufacturercatalog. : ' + __("PQ. Manuf.") + '\n';

		html += 'po. / po_detail. : ' + __("Purch. Order / detail") + '\n';
		html += 'po_ioisupplier_order. : ' + __("P. Order Order Supplier") + '\n';
		html += 'po_ioisupplier_delivery. : ' + __("P. Order Rec. Supplier") + '\n';
		html += 'po_ioisupplier_invoice. : ' + __("P. Order Inv. Supplier") + '\n';
		html += 'po_detail_ioiitem. : ' + __("P. Order. Item") + '\n';
		html += 'po_detail_ioimanufacturercatalog. : ' + __("PO. Manuf.") + '\n';


		html += 'pr. / pr_detail. : ' + __("Receipt / detail") + '\n';
		html += 'pr_ioisupplier_order. : ' + __("P. Rec. Order Supplier") + '\n';
		html += 'pr_ioisupplier_delivery. : ' + __("P. Rec. Rec. Supplier") + '\n';
		html += 'pr_ioisupplier_invoice. : ' + __("P. Rec. Inv. Supplier") + '\n';
		html += 'pr_detail_ioiitem. : ' + __("P. Rec. Item") + '\n';
		html += 'pr_detail_ioimanufacturercatalog. : ' + __("PR. Manuf.") + '\n';

		html += 'pi. / pi_detail. : ' + __("Purch. Invoice / detail") + '\n';
		html += 'pi_ioisupplier_order. : ' + __("P. Inv. Order Supplier") + '\n';
		html += 'pi_ioisupplier_delivery. : ' + __("P. Inv. Rec. Supplier") + '\n';
		html += 'pi_ioisupplier_invoice. : ' + __("P. Inv. Inv. Supplier") + '\n';
		html += 'pi_detail_ioiitem. : ' + __("P. Inv. Item") + '\n';
		html += 'pi_detail_ioimanufacturercatalog. : ' + __("PI. Manuf.") + '\n';


		html += 'ppr. : ' + __("Purch. Proposal") + '\n';
		html += 'ppr_ioisupplier. : ' + __("Purch. Proposal Supplier") + '\n';
		html += 'ppr_ioiitem. : ' + __("P. Proposal. Item") + '\n';
		html += 'ppr_ioimanufacturercatalog. : ' + __("PP. Manuf.") + '\n';


		html += 'odp. / odp_detail. : ' + __("Production / detail") + '\n';
		html += 'odp_ioiitem. : ' + __("Prod. Item") + '\n';
		html += 'odp_detail_ioiitem. : ' + __("Prod. BOM Item.") + '\n';

		html += 'dos. / dos_detail. : ' + __("Dossier / detail");
		html += 'dos_ioicustomer_order. : ' + __("Dossier Order Cust.") + '\n';
		html += 'dos_ioicustomer_delivery. : ' + __("Dossier Del. Cust.") + '\n';
		html += 'dos_ioicustomer_invoice. : ' + __("Dossier Inv. Cust.") + '\n';

		html += 'dos_ioiitem. : ' + __("Dossier. Item") + '\n';
		html += 'dos_detail_ioiitem. : ' + __("Dos. BOM Item.") + '\n';
		html += 'dos_detail_ioimanufacturercatalog. : ' + __("Dos. Manuf.") + '\n';


		html += '			</textarea>';
		html += '		</div>';
		html += '	</div>';

		html += '</div>';

		html += '<div style="overflow: auto; overflow-x: auto; overflow-y: hidden; height:160px;">';

		// Sales Quote
		html += '	<div style="position: relative; top: 0px; left: 0px; width:300px;">';
		html += '		<input type="checkbox" id="ioi_global_explorer_sq" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="ioi_global_explorer_sq_label" style="position: absolute; top: 6px; left: 30px;">' + __("Quote") + '</label>';
		html += '	</div>';

		// Sales Order
		html += '	<div style="position: relative; top: 0px; left: 150px; width:300px;">';
		html += '		<input type="checkbox" id="ioi_global_explorer_so" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="ioi_global_explorer_so_label" style="position: absolute; top: 6px; left: 30px;">' + __("Order") + '</label>';
		html += '	</div>';

		// Sales Delivery
		html += '	<div style="position: relative; top: 0px; left: 300px; width:300px;">';
		html += '		<input type="checkbox" id="ioi_global_explorer_sd" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="ioi_global_explorer_sd_label" style="position: absolute; top: 6px; left: 30px;">' + __("Delivery") + '</label>';
		html += '	</div>';

		// Sales Invoice
		html += '	<div style="position: relative; top: 0px; left: 450px; width:300px;">';
		html += '		<input type="checkbox" id="ioi_global_explorer_si" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="ioi_global_explorer_si_label" style="position: absolute; top: 6px; left: 30px;">' + __("Invoice") + '</label>';
		html += '	</div>';

		// Purchases Price Request
		html += '	<div style="position: relative; top: 20px; left: 0px; width:300px;">';
		html += '		<input type="checkbox" id="ioi_global_explorer_pq" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="ioi_global_explorer_pq_label" style="position: absolute; top: 6px; left: 30px;">' + __("Price Request") + '</label>';
		html += '	</div>';

		// Purchases Order
		html += '	<div style="position: relative; top: 20px; left: 150px; width:300px;">';
		html += '		<input type="checkbox" id="ioi_global_explorer_po" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="ioi_global_explorer_po_label" style="position: absolute; top: 6px; left: 30px;">' + __("Purch. Order") + '</label>';
		html += '	</div>';

		// Purchases Receipt
		html += '	<div style="position: relative; top: 20px; left: 300px; width:300px;">';
		html += '		<input type="checkbox" id="ioi_global_explorer_pr" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="ioi_global_explorer_pr_label" style="position: absolute; top: 6px; left: 30px;">' + __("Receipt") + '</label>';
		html += '	</div>';

		// Purchases Invoice
		html += '	<div style="position: relative; top: 20px; left: 450px; width:300px;">';
		html += '		<input type="checkbox" id="ioi_global_explorer_pi" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="ioi_global_explorer_pi_label" style="position: absolute; top: 6px; left: 30px;">' + __("Purch. Invoice") + '</label>';
		html += '	</div>';

		// Purchases Proposal
		html += '	<div style="position: relative; top: 40px; left: 0px; width:300px;">';
		html += '		<input type="checkbox" id="ioi_global_explorer_ppr" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="ioi_global_explorer_ppr_label" style="position: absolute; top: 6px; left: 30px;">' + __("Purch. Proposal") + '</label>';
		html += '	</div>';

		// Production
		html += '	<div style="position: relative; top: 40px; left: 150px; width:300px;">';
		html += '		<input type="checkbox" id="ioi_global_explorer_odp" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="ioi_global_explorer_odp_label" style="position: absolute; top: 6px; left: 30px;">' + __("Production") + '</label>';
		html += '	</div>';

		// Dossier
		html += '	<div style="position: relative; top: 40px; left: 300px; width:300px;">';
		html += '		<input type="checkbox" id="ioi_global_explorer_dos" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="ioi_global_explorer_dos_label" style="position: absolute; top: 6px; left: 30px;">' + __("Dossier") + '</label>';
		html += '	</div>';

		// Technical fieldname in grid
		html += '	<div style="position: relative; top: 60px; left: 0px; width:300px;">';
		html += '		<input type="checkbox" id="ioi_global_explorer_columns_with_fieldname" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="ioi_global_explorer_columns_with_fieldname_label" style="position: absolute; top: 6px; left: 30px;">' + __("Display fieldname in column's headers") + '</label>';
		html += '	</div>';

		// Default
		html += '	<div style="position: relative; top: 60px; left: 300px; width:300px;">';
		html += '		<input type="checkbox" id="ioi_global_explorer_default" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="ioi_global_explorer_default_label" style="position: absolute; top: 6px; left: 30px;">' + __("Default") + '</label>';
		html += '	</div>';


		// Drill through
		html += '	<div style="position: relative; top: 90px; left: 0px; width:300px;">';
		html += '		<input type="checkbox" id="ioi_global_explorer_drill_through" style="position: absolute; top: 8px; left: 2px;" checked>';
		html += '		<label id="ioi_global_explorer_drill_through_label" style="position: absolute; top: 6px; left: 30px;">' + __("Drill through (PK)") + '</label>';
		html += '	</div>';

		// Go to back
		html += '	<div style="position: relative; top: 120px; left: 30px; width:30px;height:30px;">';
		html += '		<img id="ioi_global_explorer_drill_back_label" title="' + __("Back") + '" src="/assets/silicon_ioi/images/buttons/prior.svg" width="30px" height="30px" style="position:relative;top:4px; left:4px; display: none;"></img>';
		html += "	</div>";

		// Go to next
		html += '	<div style="position: relative; top: 90px; left: 70px; width:30px; height:30px;">';
		html += '		<img id="ioi_global_explorer_drill_next_label" title="' + __("Next") + '" src="/assets/silicon_ioi/images/buttons/next.svg" width="30px" height="30px" style="position:relative;top:4px; left:4px; display: none;"></img>';
		html += "	</div>";





		// Refresh
		html += '	<div style="position: relative; top: -60px; left: 605px; width:35px;height:35px;">';
		html += '		<img id="ioi_global_explorer_refresh" title="' + __("Refresh") + '" src="/assets/silicon_ioi/images/buttons/refresh.svg" width="35px" height="35px" style="position:relative;top:4px; left:4px;"></img>';
		html += "	</div>";

		// Configure fields
		html += '	<div style="position: relative; top: -95px; left: 650px; width:35px;height:35px;">';
		html += '		<img id="ioi_global_explorer_fields" title="' + __("Configure list") + '" src="/assets/silicon_ioi/images/buttons/grid.svg" width="35px" height="35px" style="position:relative;top:4px; left:4px;"></img>';
		html += "	</div>";


		// Open
		html += '	<div style="position: relative; top: -130px; left: 695px; width:35px;height:35px;">';
		html += '		<img id="ioi_global_explorer_open" title="' + __("Open") + '" src="/assets/silicon_ioi/images/buttons/open.svg" width="35px" height="35px" style="position:relative;top:4px; left:4px;"></img>';
		html += "	</div>";


		// New
		html += '	<div style="position: relative; top: -165px; left: 740px; width:35px;height:35px;">';
		html += '		<img id="ioi_global_explorer_new" title="' + __("New") + '" src="/assets/silicon_ioi/images/buttons/add.svg" width="35px" height="35px" style="position:relative;top:4px; left:4px;"></img>';
		html += "	</div>";


		// Delete
		html += '	<div style="position: relative; top: -200px; left: 785px; width:35px;height:35px;">';
		html += '		<img id="ioi_global_explorer_delete" title="' + __("Delete") + '" src="/assets/silicon_ioi/images/buttons/trash.svg" width="35px" height="35px" style="position:relative;top:4px; left:4px;"></img>';
		html += "	</div>";

		// Save as
		html += '	<div style="position: relative; top: -235px; left: 830px; width:35px;height:35px;">';
		html += '		<img id="ioi_global_explorer_saveas" title="' + __("Save as") + '" src="/assets/silicon_ioi/images/buttons/saveas.svg" width="35px" height="35px" style="position:relative;top:4px; left:4px;"></img>';
		html += "	</div>";

		// Export
		html += '	<div style="position: relative; top: -270px; left: 875px; width:35px;height:35px;">';
		html += '		<img id="ioi_global_explorer_export" title="' + __("Export") + '" src="/assets/silicon_ioi/images/buttons/export.svg" width="35px" height="35px" style="position:relative;top:4px; left:4px;"></img>';
		html += "	</div>";

		// get SQL
		html += '	<div style="position: relative; top: -305px; left: 920px; width:35px;height:35px;">';
		html += '		<img id="ioi_global_explorer_getsql" title="' + __("Get sql") + '" src="/assets/silicon_ioi/images/buttons/sql.svg" width="35px" height="35px" style="position:relative;top:4px; left:4px;"></img>';
		html += "	</div>";

		// Display / Hide conditions
		html += '	<div style="position: relative; top: -340px; left: 960px; width:35px;height:35px;">';
		html += '		<img id="ioi_global_explorer_updown" title="' + __("Display / hide conditions") + '" src="/assets/silicon_ioi/images/buttons/updown.svg" width="35px" height="35px" style="position:relative;top:4px; left:4px;"></img>';
		html += "	</div>";


		// Combo list name
		html += '	<div style="position: relative; top: -330px; left: 605px; width:390px;">';
		html += '		<div class="control-input" style="position: absolute; top: 0px; left:2px; width: 390px; height: 30px;"> ';
		html += '			<select id="ioi_global_explorer_combo_list_name" class="input-with-feedback form-control bold"> ';
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';



		html += '</div>';

		html += '<div id="ioi_global_explorer_table_container" class="table table-bordered" data-custom-grid="true" style="overflow: auto; overflow-x: auto; overflow-y: hidden; height:600px;border: 1px solid #E6E9EC;">';
		html += '</div>';

		if (html_obj) {
			html_obj.$wrapper.empty();
			html_obj.$wrapper.append(html);
		}else if (html_component) {
			html_component.insertAdjacentHTML('beforeend', html);
		}

		silicon_ioi.doctype.ioiGlobalExplorer.sleep_static(400).then(() => {

			var old_select_value = ''

			let fct_focus = function() {
				old_select_value = this.value;

			}

			let fct_list_change = function() {

				if ((document.getElementById('ioi_global_explorer_drill_back_label').style.display) && (document.getElementById('ioi_global_explorer_drill_next_label').style.display)) {

					if ((document.getElementById('ioi_global_explorer_drill_back_label').style.display == 'block') ||
						(document.getElementById('ioi_global_explorer_drill_next_label').style.display == 'block')) {
						this.value = old_select_value;
						frappe.msgprint({title: __("Message"), message: __("Not possible when drill through has been actived"), indicator: "red"});
						return false;
					}else{

						silicon_ioi.doctype.ioiGlobalExplorer.load_report_from_combo(this.value);
					}
				}else{
					silicon_ioi.doctype.ioiGlobalExplorer.load_report_from_combo(this.value);
				}

			}
			document.getElementById('ioi_global_explorer_combo_list_name').onfocus = fct_focus;
			document.getElementById('ioi_global_explorer_combo_list_name').onchange = fct_list_change;







			let fct_label_click = function() {

				let id = this.id;

				id = id.substring(0, id.indexOf('_label'));

				if (document.getElementById(id)) {
					document.getElementById(id).checked = !document.getElementById(id).checked;

					if ((id == 'ioi_global_explorer_columns_with_fieldname') || (id = 'ioi_global_explorer_columns_with_fieldname_label')) {
						document.getElementById('ioi_global_explorer_refresh').click();
					}
				}
			}

			document.getElementById('ioi_global_explorer_sq_label').onclick = fct_label_click;
			document.getElementById('ioi_global_explorer_so_label').onclick = fct_label_click;
			document.getElementById('ioi_global_explorer_sd_label').onclick = fct_label_click;
			document.getElementById('ioi_global_explorer_si_label').onclick = fct_label_click;
			document.getElementById('ioi_global_explorer_pq_label').onclick = fct_label_click;
			document.getElementById('ioi_global_explorer_po_label').onclick = fct_label_click;
			document.getElementById('ioi_global_explorer_pr_label').onclick = fct_label_click;
			document.getElementById('ioi_global_explorer_pi_label').onclick = fct_label_click;
			document.getElementById('ioi_global_explorer_ppr_label').onclick = fct_label_click;
			document.getElementById('ioi_global_explorer_odp_label').onclick = fct_label_click;
			document.getElementById('ioi_global_explorer_dos_label').onclick = fct_label_click;
			document.getElementById('ioi_global_explorer_columns_with_fieldname_label').onclick = fct_label_click;
			document.getElementById('ioi_global_explorer_default_label').onclick = fct_label_click;
			document.getElementById('ioi_global_explorer_drill_through_label').onclick = fct_label_click;


			let fct_fieldname_click = function() {
				document.getElementById('ioi_global_explorer_refresh').click();
			}
			document.getElementById('ioi_global_explorer_columns_with_fieldname').onclick = fct_fieldname_click;

			let fct_mouseenter = function() {
				this.style.cursor = 'pointer';
			}

			let fct_mouseleave = function() {
				this.style.cursor = 'none';
			}

			let fct_gotoback = function() {
				silicon_ioi.doctype.ioiGlobalExplorer.goto_back();
			}

			document.getElementById('ioi_global_explorer_drill_back_label').onclick = fct_gotoback;
			document.getElementById('ioi_global_explorer_drill_back_label').onmouseenter = fct_mouseenter;
			document.getElementById('ioi_global_explorer_drill_back_label').onmouseleave = fct_mouseleave;

			let fct_gotonext = function() {
				silicon_ioi.doctype.ioiGlobalExplorer.goto_next();
			}

			document.getElementById('ioi_global_explorer_drill_next_label').onclick = fct_gotonext;
			document.getElementById('ioi_global_explorer_drill_next_label').onmouseenter = fct_mouseenter;
			document.getElementById('ioi_global_explorer_drill_next_label').onmouseleave = fct_mouseleave;



			let fct_new = function() {
				silicon_ioi.doctype.ioiGlobalExplorer.new();
			}

			document.getElementById('ioi_global_explorer_new').onclick = fct_new;
			document.getElementById('ioi_global_explorer_new').onmouseenter = fct_mouseenter;
			document.getElementById('ioi_global_explorer_new').onmouseleave = fct_mouseleave;

			let fct_open = function() {
				silicon_ioi.doctype.ioiGlobalExplorer.open();
			}

			document.getElementById('ioi_global_explorer_open').onclick = fct_open;
			document.getElementById('ioi_global_explorer_open').onmouseenter = fct_mouseenter;
			document.getElementById('ioi_global_explorer_open').onmouseleave = fct_mouseleave;

			let fct_delete = function() {
				silicon_ioi.doctype.ioiGlobalExplorer.delete();
			}

			document.getElementById('ioi_global_explorer_delete').onclick = fct_delete;
			document.getElementById('ioi_global_explorer_delete').onmouseenter = fct_mouseenter;
			document.getElementById('ioi_global_explorer_delete').onmouseleave = fct_mouseleave;


			let fct_saveas = function() {
				silicon_ioi.doctype.ioiGlobalExplorer.saveas();
			}

			document.getElementById('ioi_global_explorer_saveas').onclick = fct_saveas;
			document.getElementById('ioi_global_explorer_saveas').onmouseenter = fct_mouseenter;
			document.getElementById('ioi_global_explorer_saveas').onmouseleave = fct_mouseleave;

			let fct_refresh = function() {
				silicon_ioi.doctype.ioiGlobalExplorer.refresh();
			}

			document.getElementById('ioi_global_explorer_refresh').onclick = fct_refresh;
			document.getElementById('ioi_global_explorer_refresh').onmouseenter = fct_mouseenter;
			document.getElementById('ioi_global_explorer_refresh').onmouseleave = fct_mouseleave;

			let fct_export = function() {
				silicon_ioi.doctype.ioiGlobalExplorer.export();
			}

			document.getElementById('ioi_global_explorer_export').onclick = fct_export;
			document.getElementById('ioi_global_explorer_export').onmouseenter = fct_mouseenter;
			document.getElementById('ioi_global_explorer_export').onmouseleave = fct_mouseleave;


			let fct_getsql = function() {
				silicon_ioi.doctype.ioiGlobalExplorer.getsql();
			}

			document.getElementById('ioi_global_explorer_getsql').onclick = fct_getsql;
			document.getElementById('ioi_global_explorer_getsql').onmouseenter = fct_mouseenter;
			document.getElementById('ioi_global_explorer_getsql').onmouseleave = fct_mouseleave;


			let fct_updown  = function() {
				silicon_ioi.doctype.ioiGlobalExplorer.updown();
			}

			document.getElementById('ioi_global_explorer_updown').onclick = fct_updown;
			document.getElementById('ioi_global_explorer_updown').onmouseenter = fct_mouseenter;
			document.getElementById('ioi_global_explorer_updown').onmouseleave = fct_mouseleave;



			let fct_fields  = function() {
				silicon_ioi.doctype.ioiGlobalExplorer.configure_columns();
			}

			document.getElementById('ioi_global_explorer_fields').onclick = fct_fields;
			document.getElementById('ioi_global_explorer_fields').onmouseenter = fct_mouseenter;
			document.getElementById('ioi_global_explorer_fields').onmouseleave = fct_mouseleave;

			document.getElementById('ioi_global_explorer_sq').checked = true;

			silicon_ioi.doctype.ioiGlobalExplorer.data = [];

			silicon_ioi.doctype.ioiGlobalExplorer.create_tabulator();

			silicon_ioi.doctype.ioiGlobalExplorer.load_default_list(current_dc, current_name);

			if (current_dc) {
				document.getElementById('ioi_global_explorer_from_doctype').value = current_dc;
			}

			if (current_name) {
				document.getElementById('ioi_global_explorer_from_name').value = current_name;
			}

			silicon_ioi.doctype.ioiGlobalExplorer.load_combo_list_name();



			if (silicon_ioi.doctype.ioiGlobalExplorer.record_name != '') {

				for (var i = 0; i < document.getElementById('ioi_global_explorer_combo_list_name').options.length; i++) {

					if (document.getElementById('ioi_global_explorer_combo_list_name').options[i].value == silicon_ioi.doctype.ioiGlobalExplorer.record_name) {
						document.getElementById('ioi_global_explorer_combo_list_name').selectedIndex = i;
						break;
					}
				}
			}

			silicon_ioi.doctype.ioiGlobalExplorer.load_report_from_combo(document.getElementById('ioi_global_explorer_combo_list_name').value);


		});

	}


	static load_report_from_combo(id, do_not_refresh = false)
	{
		let method = silicon_ioi.doctype.ioiGlobalExplorer.path_global_explorer_user_list + '.ioi_global_explorer_user_list_get'

		let rec = {};

		frappe.call({
			method: method,
			args: {	"name": id
			},
			async: false,
			callback:function(r)	{
				rec = r.message;
			}
		});

		silicon_ioi.doctype.ioiGlobalExplorer.back_list = [];

		if (document.getElementById('ioi_global_explorer_drill_back_label').style.display == 'block') {
			document.getElementById('ioi_global_explorer_drill_back_label').style.display = 'none';
		}

		silicon_ioi.doctype.ioiGlobalExplorer.next_list = [];

		if (document.getElementById('ioi_global_explorer_drill_next_label').style.display == 'block') {
			document.getElementById('ioi_global_explorer_drill_next_label').style.display = 'none';
		}
		silicon_ioi.doctype.ioiGlobalExplorer.record_name = rec.name;
		silicon_ioi.doctype.ioiGlobalExplorer.list_name = rec.list_name;
		silicon_ioi.doctype.ioiGlobalExplorer.from_doctype = rec.from_doctype;
		silicon_ioi.doctype.ioiGlobalExplorer.from_name = rec.from_name;
		silicon_ioi.doctype.ioiGlobalExplorer.from_detail_idx = rec.from_idx;
		silicon_ioi.doctype.ioiGlobalExplorer.from_detail_name = '';
		silicon_ioi.doctype.ioiGlobalExplorer.topn = rec.topn;
		silicon_ioi.doctype.ioiGlobalExplorer.where = rec.where_clause;
		silicon_ioi.doctype.ioiGlobalExplorer.having = rec.having_clause;
		silicon_ioi.doctype.ioiGlobalExplorer.sq = rec.sq;
		silicon_ioi.doctype.ioiGlobalExplorer.so = rec.so;
		silicon_ioi.doctype.ioiGlobalExplorer.sd = rec.sd;
		silicon_ioi.doctype.ioiGlobalExplorer.si = rec.si;
		silicon_ioi.doctype.ioiGlobalExplorer.pq = rec.pq;
		silicon_ioi.doctype.ioiGlobalExplorer.po = rec.po;
		silicon_ioi.doctype.ioiGlobalExplorer.pr = rec.pr;
		silicon_ioi.doctype.ioiGlobalExplorer.pi = rec.pi;
		silicon_ioi.doctype.ioiGlobalExplorer.ppr = rec.ppr;
		silicon_ioi.doctype.ioiGlobalExplorer.odp = rec.odp;
		silicon_ioi.doctype.ioiGlobalExplorer.dos = rec.dos;
		silicon_ioi.doctype.ioiGlobalExplorer.tech_fieldname = rec.tech_fieldname;
		silicon_ioi.doctype.ioiGlobalExplorer.default = rec.is_default;
		silicon_ioi.doctype.ioiGlobalExplorer.tab_fields = rec.tab_fields;
		silicon_ioi.doctype.ioiGlobalExplorer.tab_isjumps = rec.tab_isjumps;
		silicon_ioi.doctype.ioiGlobalExplorer.tab_isformulas = rec.tab_isformulas;
		silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description = rec.tab_formulas_description;
		silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby = rec.tab_orderby;
		silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby_sens = rec.tab_orderby_sens;


		silicon_ioi.doctype.ioiGlobalExplorer.summary = rec.summary;
		silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields = rec.tab_summary_fields;
		silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action = rec.tab_summary_action;



		silicon_ioi.doctype.ioiGlobalExplorer.from_name = rec.from_name;
		silicon_ioi.doctype.ioiGlobalExplorer.from_detail_idx = rec.from_idx;

		if (cur_frm.doctype != 'ioi Global Explorer')
		{
			silicon_ioi.doctype.ioiGlobalExplorer.from_doctype = cur_frm.doctype;
			silicon_ioi.doctype.ioiGlobalExplorer.from_name = cur_frm.doc.name;
			silicon_ioi.doctype.ioiGlobalExplorer.from_detail_idx = '';
			silicon_ioi.doctype.ioiGlobalExplorer.topn = 0;

		}

		document.getElementById('ioi_global_explorer_from_doctype').value = silicon_ioi.doctype.ioiGlobalExplorer.from_doctype;
		document.getElementById('ioi_global_explorer_from_name').value = silicon_ioi.doctype.ioiGlobalExplorer.from_name;
		document.getElementById('ioi_global_explorer_from_idx').value = silicon_ioi.doctype.ioiGlobalExplorer.from_detail_idx;
		document.getElementById('ioi_global_explorer_topn').value = silicon_ioi.doctype.ioiGlobalExplorer.topn;
		document.getElementById('ioi_global_explorer_where').value = silicon_ioi.doctype.ioiGlobalExplorer.where;

		document.getElementById('ioi_global_explorer_sq').checked = false;

		if (silicon_ioi.doctype.ioiGlobalExplorer.sq == 1) {
			document.getElementById('ioi_global_explorer_sq').checked = true;
		}

		document.getElementById('ioi_global_explorer_so').checked = false;

		if (silicon_ioi.doctype.ioiGlobalExplorer.so == 1) {
			document.getElementById('ioi_global_explorer_so').checked = true;
		}

		document.getElementById('ioi_global_explorer_sd').checked = false;

		if (silicon_ioi.doctype.ioiGlobalExplorer.sd == 1) {
			document.getElementById('ioi_global_explorer_sd').checked = true;
		}

		document.getElementById('ioi_global_explorer_si').checked = false;

		if (silicon_ioi.doctype.ioiGlobalExplorer.si == 1) {
			document.getElementById('ioi_global_explorer_si').checked = true;
		}

		document.getElementById('ioi_global_explorer_pq').checked = false;

		if (silicon_ioi.doctype.ioiGlobalExplorer.pq == 1) {
			document.getElementById('ioi_global_explorer_pq').checked = true;
		}

		document.getElementById('ioi_global_explorer_po').checked = false;

		if (silicon_ioi.doctype.ioiGlobalExplorer.po == 1) {
			document.getElementById('ioi_global_explorer_po').checked = true;
		}

		document.getElementById('ioi_global_explorer_pr').checked = false;

		if (silicon_ioi.doctype.ioiGlobalExplorer.pr == 1) {
			document.getElementById('ioi_global_explorer_pr').checked = true;
		}

		document.getElementById('ioi_global_explorer_pi').checked = false;

		if (silicon_ioi.doctype.ioiGlobalExplorer.pi == 1) {
			document.getElementById('ioi_global_explorer_pi').checked = true;
		}

		document.getElementById('ioi_global_explorer_ppr').checked = false;

		if (silicon_ioi.doctype.ioiGlobalExplorer.ppr == 1) {
			document.getElementById('ioi_global_explorer_ppr').checked = true;
		}

		document.getElementById('ioi_global_explorer_odp').checked = false;

		if (silicon_ioi.doctype.ioiGlobalExplorer.odp == 1) {
			document.getElementById('ioi_global_explorer_odp').checked = true;
		}


		document.getElementById('ioi_global_explorer_dos').checked = false;

		if (silicon_ioi.doctype.ioiGlobalExplorer.dos == 1) {
			document.getElementById('ioi_global_explorer_dos').checked = true;
		}

		document.getElementById('ioi_global_explorer_columns_with_fieldname').checked = false;

		if (silicon_ioi.doctype.ioiGlobalExplorer.tech_fieldname == 1) {
			document.getElementById('ioi_global_explorer_columns_with_fieldname').checked = true;
		}

		document.getElementById('ioi_global_explorer_columns_with_fieldname').checked = false;

		if (silicon_ioi.doctype.ioiGlobalExplorer.default == 1) {
			document.getElementById('ioi_global_explorer_default').checked = true;
		}

		if (!do_not_refresh) {

			if (silicon_ioi.doctype.ioiGlobalExplorer.is_global_explorer == 1) {
				silicon_ioi.doctype.ioiGlobalExplorer.sleep_static(250).then(() => {
					silicon_ioi.doctype.ioiGlobalExplorer.refresh(false, true);
				});
			}
		}

	}

	static load_combo_list_name(do_not_refresh = false)
	{

		for (var i = document.getElementById('ioi_global_explorer_combo_list_name').options.length-1; i >= 0; i--) {
			document.getElementById('ioi_global_explorer_combo_list_name').remove(i)
		}


		let method = silicon_ioi.doctype.ioiGlobalExplorer.path_global_explorer_user_list + '.ioi_global_explorer_user_get_all_list'

		let data = [];

		frappe.call({
			method: method,
			args: {	"is_live": 1,
					"from_doctype": document.getElementById('ioi_global_explorer_from_doctype').value
			},
			async: false,
			callback:function(r)	{
				data = r.message;

			}
		});

		if (data.length > 0) {

			for (var i = 0; i < data.length; i++) {

				let option = document.createElement("option");
				option.value = data[i].name;
				option.text = data[i].list_name;

				document.getElementById('ioi_global_explorer_combo_list_name').add(option);
			}



			if (silicon_ioi.doctype.ioiGlobalExplorer.record_name != '') {

				for (var i = 0; i < document.getElementById('ioi_global_explorer_combo_list_name').options.length; i++) {

					if (document.getElementById('ioi_global_explorer_combo_list_name').options[i].value == silicon_ioi.doctype.ioiGlobalExplorer.record_name) {
						document.getElementById('ioi_global_explorer_combo_list_name').selectedIndex = i;
						silicon_ioi.doctype.ioiGlobalExplorer.load_report_from_combo(document.getElementById('ioi_global_explorer_combo_list_name').value, do_not_refresh)
						break;
					}
				}
			}
		}


	}

	static goto_back()
	{
		if (silicon_ioi.doctype.ioiGlobalExplorer.back_list.length > 0) {


			let record_name = '';

			if (silicon_ioi.doctype.ioiGlobalExplorer.back_list[silicon_ioi.doctype.ioiGlobalExplorer.back_list.length-1][0] != '') {
				record_name = silicon_ioi.doctype.ioiGlobalExplorer.back_list[silicon_ioi.doctype.ioiGlobalExplorer.back_list.length-1][0];
			}

			let from_dc = '';

			if (silicon_ioi.doctype.ioiGlobalExplorer.back_list[silicon_ioi.doctype.ioiGlobalExplorer.back_list.length-1][1] != '') {
				from_dc = silicon_ioi.doctype.ioiGlobalExplorer.back_list[silicon_ioi.doctype.ioiGlobalExplorer.back_list.length-1][1];
			}


			let from_name = '';

			if (silicon_ioi.doctype.ioiGlobalExplorer.back_list[silicon_ioi.doctype.ioiGlobalExplorer.back_list.length-1][2] != '') {
				from_name = silicon_ioi.doctype.ioiGlobalExplorer.back_list[silicon_ioi.doctype.ioiGlobalExplorer.back_list.length-1][2];

				if (from_name == '0') {
					from_name = ''
				}
			}

			let from_idx = '';

			if (silicon_ioi.doctype.ioiGlobalExplorer.back_list[silicon_ioi.doctype.ioiGlobalExplorer.back_list.length-1][3] != '') {
				from_idx = silicon_ioi.doctype.ioiGlobalExplorer.back_list[silicon_ioi.doctype.ioiGlobalExplorer.back_list.length-1][3];

				if (from_idx == '0') {
					from_idx = ''
				}
			}

			let topn = 0;

			if ((silicon_ioi.doctype.ioiGlobalExplorer.back_list[silicon_ioi.doctype.ioiGlobalExplorer.back_list.length-1][4] != '') &&
				(silicon_ioi.doctype.ioiGlobalExplorer.back_list[silicon_ioi.doctype.ioiGlobalExplorer.back_list.length-1][4] != '0')) {
				topn = silicon_ioi.doctype.ioiGlobalExplorer.back_list[silicon_ioi.doctype.ioiGlobalExplorer.back_list.length-1][4];
			}

			let back_list = [];

			if (silicon_ioi.doctype.ioiGlobalExplorer.back_list.length > 1) {

				for (var i = 0; i < silicon_ioi.doctype.ioiGlobalExplorer.back_list.length-1; i++) {

					back_list[back_list.length] = [
						silicon_ioi.doctype.ioiGlobalExplorer.back_list[i][0],
						silicon_ioi.doctype.ioiGlobalExplorer.back_list[i][1],
						silicon_ioi.doctype.ioiGlobalExplorer.back_list[i][2],
						silicon_ioi.doctype.ioiGlobalExplorer.back_list[i][3],
						silicon_ioi.doctype.ioiGlobalExplorer.back_list[i][4]
					];

				}

			}


			silicon_ioi.doctype.ioiGlobalExplorer.next_list[silicon_ioi.doctype.ioiGlobalExplorer.next_list.length] = [
				silicon_ioi.doctype.ioiGlobalExplorer.record_name,
				silicon_ioi.doctype.ioiGlobalExplorer.from_doctype,
				silicon_ioi.doctype.ioiGlobalExplorer.from_name,
				silicon_ioi.doctype.ioiGlobalExplorer.from_detail_idx,
				silicon_ioi.doctype.ioiGlobalExplorer.topn
			];



			silicon_ioi.doctype.ioiGlobalExplorer.back_list = [];

			for (var i = 0; i < back_list.length; i++) {

				silicon_ioi.doctype.ioiGlobalExplorer.back_list[silicon_ioi.doctype.ioiGlobalExplorer.back_list.length] = [
					back_list[i][0],
					back_list[i][1],
					back_list[i][2],
					back_list[i][3],
					back_list[i][4]
				];

			}



			if (silicon_ioi.doctype.ioiGlobalExplorer.back_list.length == 0) {

				if (document.getElementById('ioi_global_explorer_drill_back_label').style.display == 'block') {
					document.getElementById('ioi_global_explorer_drill_back_label').style.display = 'none';
				}
			}


			if (document.getElementById('ioi_global_explorer_drill_next_label').style.display == 'none') {
				document.getElementById('ioi_global_explorer_drill_next_label').style.display = 'block';
			}


			silicon_ioi.doctype.ioiGlobalExplorer.clear_screen();

			if (record_name != '') {

				let method = silicon_ioi.doctype.ioiGlobalExplorer.path_global_explorer_user_list + '.ioi_global_explorer_user_list_get'

				let rec = {};

				frappe.call({
					method: method,
					args: {	"name": record_name
					},
					async: false,
					callback:function(r)	{
						rec = r.message;
					}
				});

				silicon_ioi.doctype.ioiGlobalExplorer.record_name = rec.name;
				silicon_ioi.doctype.ioiGlobalExplorer.list_name = rec.list_name;
				silicon_ioi.doctype.ioiGlobalExplorer.from_doctype = from_dc;
				silicon_ioi.doctype.ioiGlobalExplorer.from_name = from_name;
				silicon_ioi.doctype.ioiGlobalExplorer.from_detail_idx = from_idx;
				silicon_ioi.doctype.ioiGlobalExplorer.from_detail_name = '';
				silicon_ioi.doctype.ioiGlobalExplorer.topn = topn;


				silicon_ioi.doctype.ioiGlobalExplorer.where = rec.where_clause;
				silicon_ioi.doctype.ioiGlobalExplorer.having = rec.having_clause;
				silicon_ioi.doctype.ioiGlobalExplorer.sq = rec.sq;
				silicon_ioi.doctype.ioiGlobalExplorer.so = rec.so;
				silicon_ioi.doctype.ioiGlobalExplorer.sd = rec.sd;
				silicon_ioi.doctype.ioiGlobalExplorer.si = rec.si;
				silicon_ioi.doctype.ioiGlobalExplorer.pq = rec.pq;
				silicon_ioi.doctype.ioiGlobalExplorer.po = rec.po;
				silicon_ioi.doctype.ioiGlobalExplorer.pr = rec.pr;
				silicon_ioi.doctype.ioiGlobalExplorer.pi = rec.pi;
				silicon_ioi.doctype.ioiGlobalExplorer.ppr = rec.ppr;
				silicon_ioi.doctype.ioiGlobalExplorer.odp = rec.odp;
				silicon_ioi.doctype.ioiGlobalExplorer.dos = rec.dos;
				silicon_ioi.doctype.ioiGlobalExplorer.tech_fieldname = rec.tech_fieldname;
				silicon_ioi.doctype.ioiGlobalExplorer.default = rec.is_default;
				silicon_ioi.doctype.ioiGlobalExplorer.tab_fields = rec.tab_fields;
				silicon_ioi.doctype.ioiGlobalExplorer.tab_isjumps = rec.tab_isjumps;
				silicon_ioi.doctype.ioiGlobalExplorer.tab_isformulas = rec.tab_isformulas;
				silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description = rec.tab_formulas_descripion;
				silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby = rec.tab_orderby;
				silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby_sens = rec.tab_orderby_sens;

				silicon_ioi.doctype.ioiGlobalExplorer.summary = rec.summary;
				silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields = rec.tab_summary_fields;
				silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action = rec.tab_summary_action;

				document.getElementById('ioi_global_explorer_from_doctype').value = silicon_ioi.doctype.ioiGlobalExplorer.from_doctype;
				document.getElementById('ioi_global_explorer_from_name').value = silicon_ioi.doctype.ioiGlobalExplorer.from_name;
				document.getElementById('ioi_global_explorer_from_idx').value = silicon_ioi.doctype.ioiGlobalExplorer.from_detail_idx;
				document.getElementById('ioi_global_explorer_topn').value = silicon_ioi.doctype.ioiGlobalExplorer.topn;
				document.getElementById('ioi_global_explorer_where').value = silicon_ioi.doctype.ioiGlobalExplorer.where;

				document.getElementById('ioi_global_explorer_sq').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.sq == 1) {
					document.getElementById('ioi_global_explorer_sq').checked = true;
				}

				document.getElementById('ioi_global_explorer_so').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.so == 1) {
					document.getElementById('ioi_global_explorer_so').checked = true;
				}

				document.getElementById('ioi_global_explorer_sd').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.sd == 1) {
					document.getElementById('ioi_global_explorer_sd').checked = true;
				}

				document.getElementById('ioi_global_explorer_si').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.si == 1) {
					document.getElementById('ioi_global_explorer_si').checked = true;
				}

				document.getElementById('ioi_global_explorer_pq').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.pq == 1) {
					document.getElementById('ioi_global_explorer_pq').checked = true;
				}

				document.getElementById('ioi_global_explorer_po').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.po == 1) {
					document.getElementById('ioi_global_explorer_po').checked = true;
				}

				document.getElementById('ioi_global_explorer_pr').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.pr == 1) {
					document.getElementById('ioi_global_explorer_pr').checked = true;
				}

				document.getElementById('ioi_global_explorer_pi').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.pi == 1) {
					document.getElementById('ioi_global_explorer_pi').checked = true;
				}

				document.getElementById('ioi_global_explorer_ppr').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.ppr == 1) {
					document.getElementById('ioi_global_explorer_ppr').checked = true;
				}

				document.getElementById('ioi_global_explorer_odp').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.odp == 1) {
					document.getElementById('ioi_global_explorer_odp').checked = true;
				}


				document.getElementById('ioi_global_explorer_dos').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.dos == 1) {
					document.getElementById('ioi_global_explorer_dos').checked = true;
				}

				document.getElementById('ioi_global_explorer_columns_with_fieldname').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.tech_fieldname == 1) {
					document.getElementById('ioi_global_explorer_columns_with_fieldname').checked = true;
				}

				document.getElementById('ioi_global_explorer_columns_with_fieldname').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.default == 1) {
					document.getElementById('ioi_global_explorer_default').checked = true;
				}

				silicon_ioi.doctype.ioiGlobalExplorer.refresh();



			}else{
				document.getElementById('ioi_global_explorer_from_doctype').value = from_dc;
				document.getElementById('ioi_global_explorer_from_name').value = from_name;
				document.getElementById('ioi_global_explorer_from_idx').value = from_idx;
				document.getElementById('ioi_global_explorer_topn').value = topn;

				if (from_dc == 'ioi Sales Quote') {
					document.getElementById('ioi_global_explorer_sq').checked = true;
				}else if (from_dc == 'ioi Sales Order') {
					document.getElementById('ioi_global_explorer_so').checked = true;
				}else if (from_dc == 'ioi Sales Delivery') {
					document.getElementById('ioi_global_explorer_sd').checked = true;
				}else if (from_dc == 'ioi Sales Invoice') {
					document.getElementById('ioi_global_explorer_si').checked = true;
				}else if (from_dc == 'ioi Purchases Price Request') {
					document.getElementById('ioi_global_explorer_pq').checked = true;
				}else if (from_dc == 'ioi Purchases Order') {
					document.getElementById('ioi_global_explorer_po').checked = true;
				}else if (from_dc == 'ioi Purchases Receipt') {
					document.getElementById('ioi_global_explorer_pr').checked = true;
				}else if (from_dc == 'ioi Purchases Invoice') {
					document.getElementById('ioi_global_explorer_pi').checked = true;
				}else if ((from_dc == 'ioi Production') || (from_dc == 'ioi Production BOM')) {
					document.getElementById('ioi_global_explorer_odp').checked = true;
				}else if ((from_dc == 'ioi Dossier') || (from_dc == 'ioi Dossier BOM')) {
					document.getElementById('ioi_global_explorer_dos').checked = true;
				}

				silicon_ioi.doctype.ioiGlobalExplorer.refresh(false, true);
			}
		}

	}

	static goto_next()
	{
		if (silicon_ioi.doctype.ioiGlobalExplorer.next_list.length > 0) {


			let record_name = '';

			if (silicon_ioi.doctype.ioiGlobalExplorer.next_list[silicon_ioi.doctype.ioiGlobalExplorer.next_list.length-1][0] != '') {
				record_name = silicon_ioi.doctype.ioiGlobalExplorer.next_list[silicon_ioi.doctype.ioiGlobalExplorer.next_list.length-1][0];
			}

			let from_dc = '';

			if (silicon_ioi.doctype.ioiGlobalExplorer.next_list[silicon_ioi.doctype.ioiGlobalExplorer.next_list.length-1][1] != '') {
				from_dc = silicon_ioi.doctype.ioiGlobalExplorer.next_list[silicon_ioi.doctype.ioiGlobalExplorer.next_list.length-1][1];
			}


			let from_name = '';

			if (silicon_ioi.doctype.ioiGlobalExplorer.next_list[silicon_ioi.doctype.ioiGlobalExplorer.next_list.length-1][2] != '') {
				from_name = silicon_ioi.doctype.ioiGlobalExplorer.next_list[silicon_ioi.doctype.ioiGlobalExplorer.next_list.length-1][2];

				if (from_name == '0') {
					from_name = ''
				}
			}


			let from_idx = '';

			if (silicon_ioi.doctype.ioiGlobalExplorer.next_list[silicon_ioi.doctype.ioiGlobalExplorer.next_list.length-1][3] != '') {
				from_idx = silicon_ioi.doctype.ioiGlobalExplorer.next_list[silicon_ioi.doctype.ioiGlobalExplorer.next_list.length-1][3];

				if (from_idx == '0') {
					from_idx = ''
				}
			}

			let topn = 0;

			if ((silicon_ioi.doctype.ioiGlobalExplorer.next_list[silicon_ioi.doctype.ioiGlobalExplorer.next_list.length-1][4] != '') &&
				(silicon_ioi.doctype.ioiGlobalExplorer.next_list[silicon_ioi.doctype.ioiGlobalExplorer.next_list.length-1][4] != '0')) {
				topn = silicon_ioi.doctype.ioiGlobalExplorer.next_list[silicon_ioi.doctype.ioiGlobalExplorer.next_list.length-1][4];
			}

			let next_list = [];

			if (silicon_ioi.doctype.ioiGlobalExplorer.next_list.length > 1) {

				for (var i = 0; i < silicon_ioi.doctype.ioiGlobalExplorer.next_list.length-1; i++) {

					next_list[next_list.length] = [
						silicon_ioi.doctype.ioiGlobalExplorer.next_list[i][0],
						silicon_ioi.doctype.ioiGlobalExplorer.next_list[i][1],
						silicon_ioi.doctype.ioiGlobalExplorer.next_list[i][2],
						silicon_ioi.doctype.ioiGlobalExplorer.next_list[i][3],
						silicon_ioi.doctype.ioiGlobalExplorer.next_list[i][4]
					];

				}


			}


			silicon_ioi.doctype.ioiGlobalExplorer.back_list[silicon_ioi.doctype.ioiGlobalExplorer.back_list.length] = [
				silicon_ioi.doctype.ioiGlobalExplorer.record_name,
				silicon_ioi.doctype.ioiGlobalExplorer.from_doctype,
				silicon_ioi.doctype.ioiGlobalExplorer.from_name,
				silicon_ioi.doctype.ioiGlobalExplorer.from_detail_idx,
				silicon_ioi.doctype.ioiGlobalExplorer.topn
			];



			silicon_ioi.doctype.ioiGlobalExplorer.next_list = [];

			for (var i = 0; i < next_list.length; i++) {

				silicon_ioi.doctype.ioiGlobalExplorer.next_list[silicon_ioi.doctype.ioiGlobalExplorer.next_list.length] = [
					next_list[i][0],
					next_list[i][1],
					next_list[i][2],
					next_list[i][3],
					next_list[i][4]
				];

			}






			if (silicon_ioi.doctype.ioiGlobalExplorer.next_list.length == 0) {

				if (document.getElementById('ioi_global_explorer_drill_next_label').style.display == 'block') {
					document.getElementById('ioi_global_explorer_drill_next_label').style.display = 'none';
				}
			}


			if (document.getElementById('ioi_global_explorer_drill_back_label').style.display == 'none') {
				document.getElementById('ioi_global_explorer_drill_back_label').style.display = 'block';
			}


			silicon_ioi.doctype.ioiGlobalExplorer.clear_screen();

			if (record_name != '') {

				let method = silicon_ioi.doctype.ioiGlobalExplorer.path_global_explorer_user_list + '.ioi_global_explorer_user_list_get'

				let rec = {};

				frappe.call({
					method: method,
					args: {	"name": record_name
					},
					async: false,
					callback:function(r)	{
						rec = r.message;
					}
				});

				silicon_ioi.doctype.ioiGlobalExplorer.record_name = rec.name;
				silicon_ioi.doctype.ioiGlobalExplorer.list_name = rec.list_name;
				silicon_ioi.doctype.ioiGlobalExplorer.from_doctype = from_dc;
				silicon_ioi.doctype.ioiGlobalExplorer.from_name = from_name;
				silicon_ioi.doctype.ioiGlobalExplorer.from_detail_idx = from_idx;
				silicon_ioi.doctype.ioiGlobalExplorer.from_detail_name = '';
				silicon_ioi.doctype.ioiGlobalExplorer.topn = topn;


				silicon_ioi.doctype.ioiGlobalExplorer.where = rec.where_clause;
				silicon_ioi.doctype.ioiGlobalExplorer.having = rec.having_clause;
				silicon_ioi.doctype.ioiGlobalExplorer.sq = rec.sq;
				silicon_ioi.doctype.ioiGlobalExplorer.so = rec.so;
				silicon_ioi.doctype.ioiGlobalExplorer.sd = rec.sd;
				silicon_ioi.doctype.ioiGlobalExplorer.si = rec.si;
				silicon_ioi.doctype.ioiGlobalExplorer.pq = rec.pq;
				silicon_ioi.doctype.ioiGlobalExplorer.po = rec.po;
				silicon_ioi.doctype.ioiGlobalExplorer.pr = rec.pr;
				silicon_ioi.doctype.ioiGlobalExplorer.pi = rec.pi;
				silicon_ioi.doctype.ioiGlobalExplorer.ppr = rec.ppr;
				silicon_ioi.doctype.ioiGlobalExplorer.odp = rec.odp;
				silicon_ioi.doctype.ioiGlobalExplorer.dos = rec.dos;
				silicon_ioi.doctype.ioiGlobalExplorer.tech_fieldname = rec.tech_fieldname;
				silicon_ioi.doctype.ioiGlobalExplorer.default = rec.is_default;
				silicon_ioi.doctype.ioiGlobalExplorer.tab_fields = rec.tab_fields;
				silicon_ioi.doctype.ioiGlobalExplorer.tab_isjumps = rec.tab_isjumps;
				silicon_ioi.doctype.ioiGlobalExplorer.tab_isformulas = rec.tab_isformulas;
				silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description = rec.tab_formulas_descripion;
				silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby = rec.tab_orderby;
				silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby_sens = rec.tab_orderby_sens;

				silicon_ioi.doctype.ioiGlobalExplorer.summary = rec.summary;
				silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields = rec.tab_summary_fields;
				silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action = rec.tab_summary_action;

				document.getElementById('ioi_global_explorer_from_doctype').value = silicon_ioi.doctype.ioiGlobalExplorer.from_doctype;
				document.getElementById('ioi_global_explorer_from_name').value = silicon_ioi.doctype.ioiGlobalExplorer.from_name;
				document.getElementById('ioi_global_explorer_from_idx').value = silicon_ioi.doctype.ioiGlobalExplorer.from_detail_idx;
				document.getElementById('ioi_global_explorer_topn').value = silicon_ioi.doctype.ioiGlobalExplorer.topn;
				document.getElementById('ioi_global_explorer_where').value = silicon_ioi.doctype.ioiGlobalExplorer.where;

				document.getElementById('ioi_global_explorer_sq').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.sq == 1) {
					document.getElementById('ioi_global_explorer_sq').checked = true;
				}

				document.getElementById('ioi_global_explorer_so').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.so == 1) {
					document.getElementById('ioi_global_explorer_so').checked = true;
				}

				document.getElementById('ioi_global_explorer_sd').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.sd == 1) {
					document.getElementById('ioi_global_explorer_sd').checked = true;
				}

				document.getElementById('ioi_global_explorer_si').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.si == 1) {
					document.getElementById('ioi_global_explorer_si').checked = true;
				}

				document.getElementById('ioi_global_explorer_pq').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.pq == 1) {
					document.getElementById('ioi_global_explorer_pq').checked = true;
				}

				document.getElementById('ioi_global_explorer_po').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.po == 1) {
					document.getElementById('ioi_global_explorer_po').checked = true;
				}

				document.getElementById('ioi_global_explorer_pr').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.pr == 1) {
					document.getElementById('ioi_global_explorer_pr').checked = true;
				}

				document.getElementById('ioi_global_explorer_pi').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.pi == 1) {
					document.getElementById('ioi_global_explorer_pi').checked = true;
				}

				document.getElementById('ioi_global_explorer_ppr').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.ppr == 1) {
					document.getElementById('ioi_global_explorer_ppr').checked = true;
				}

				document.getElementById('ioi_global_explorer_odp').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.odp == 1) {
					document.getElementById('ioi_global_explorer_odp').checked = true;
				}


				document.getElementById('ioi_global_explorer_dos').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.dos == 1) {
					document.getElementById('ioi_global_explorer_dos').checked = true;
				}

				document.getElementById('ioi_global_explorer_columns_with_fieldname').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.tech_fieldname == 1) {
					document.getElementById('ioi_global_explorer_columns_with_fieldname').checked = true;
				}

				document.getElementById('ioi_global_explorer_columns_with_fieldname').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.default == 1) {
					document.getElementById('ioi_global_explorer_default').checked = true;
				}

				silicon_ioi.doctype.ioiGlobalExplorer.refresh();



			}else{
				document.getElementById('ioi_global_explorer_from_doctype').value = from_dc;
				document.getElementById('ioi_global_explorer_from_name').value = from_name;
				document.getElementById('ioi_global_explorer_from_idx').value = from_idx;
				document.getElementById('ioi_global_explorer_topn').value = topn;

				if (from_dc == 'ioi Sales Quote') {
					document.getElementById('ioi_global_explorer_sq').checked = true;
				}else if (from_dc == 'ioi Sales Order') {
					document.getElementById('ioi_global_explorer_so').checked = true;
				}else if (from_dc == 'ioi Sales Delivery') {
					document.getElementById('ioi_global_explorer_sd').checked = true;
				}else if (from_dc == 'ioi Sales Invoice') {
					document.getElementById('ioi_global_explorer_si').checked = true;
				}else if (from_dc == 'ioi Purchases Price Request') {
					document.getElementById('ioi_global_explorer_pq').checked = true;
				}else if (from_dc == 'ioi Purchases Order') {
					document.getElementById('ioi_global_explorer_po').checked = true;
				}else if (from_dc == 'ioi Purchases Receipt') {
					document.getElementById('ioi_global_explorer_pr').checked = true;
				}else if (from_dc == 'ioi Purchases Invoice') {
					document.getElementById('ioi_global_explorer_pi').checked = true;
				}else if ((from_dc == 'ioi Production') || (from_dc == 'ioi Production BOM')) {
					document.getElementById('ioi_global_explorer_odp').checked = true;
				}else if ((from_dc == 'ioi Dossier') || (from_dc == 'ioi Dossier BOM')) {
					document.getElementById('ioi_global_explorer_dos').checked = true;
				}

				silicon_ioi.doctype.ioiGlobalExplorer.refresh(false, true);
			}
		}

	}

	static clear_screen()
	{
		silicon_ioi.doctype.ioiGlobalExplorer.record_name = '';
		silicon_ioi.doctype.ioiGlobalExplorer.list_name = '';

		if (document.getElementById('html_global_explorer_new_doctype')) {
			if (document.getElementById('html_global_explorer_new_doctype').value) {
				silicon_ioi.doctype.ioiGlobalExplorer.from_doctype = document.getElementById('html_global_explorer_new_doctype').value;
			}else{
				silicon_ioi.doctype.ioiGlobalExplorer.from_doctype = '';
			}

		}else{
			silicon_ioi.doctype.ioiGlobalExplorer.from_doctype = '';
		}

		silicon_ioi.doctype.ioiGlobalExplorer.from_detail_idx = ''
		silicon_ioi.doctype.ioiGlobalExplorer.from_detail_name = '';
		silicon_ioi.doctype.ioiGlobalExplorer.topn = -1;
		silicon_ioi.doctype.ioiGlobalExplorer.where = '';
		silicon_ioi.doctype.ioiGlobalExplorer.having = '';
		silicon_ioi.doctype.ioiGlobalExplorer.sq = 0;
		silicon_ioi.doctype.ioiGlobalExplorer.so = 0;
		silicon_ioi.doctype.ioiGlobalExplorer.sd = 0;
		silicon_ioi.doctype.ioiGlobalExplorer.si = 0;
		silicon_ioi.doctype.ioiGlobalExplorer.pq = 0;
		silicon_ioi.doctype.ioiGlobalExplorer.po = 0;
		silicon_ioi.doctype.ioiGlobalExplorer.pr = 0;
		silicon_ioi.doctype.ioiGlobalExplorer.pi = 0;
		silicon_ioi.doctype.ioiGlobalExplorer.ppr = 0;
		silicon_ioi.doctype.ioiGlobalExplorer.odp = 0;
		silicon_ioi.doctype.ioiGlobalExplorer.dos = 0;
		silicon_ioi.doctype.ioiGlobalExplorer.tech_fieldname = 0;
		silicon_ioi.doctype.ioiGlobalExplorer.default = 0;
		silicon_ioi.doctype.ioiGlobalExplorer.tab_fields = [];
		silicon_ioi.doctype.ioiGlobalExplorer.tab_fieldtypes = [];
		silicon_ioi.doctype.ioiGlobalExplorer.tab_isjumps = [];
		silicon_ioi.doctype.ioiGlobalExplorer.tab_isformulas = [];
		silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description = [];
		silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby = [];
		silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby_sens = [];

		silicon_ioi.doctype.ioiGlobalExplorer.summary = 0;
		silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields = [];
		silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action = [];

		if (document.getElementById('html_global_explorer_new_doctype')) {
			if (document.getElementById('html_global_explorer_new_doctype').value) {
				document.getElementById('ioi_global_explorer_from_doctype').value = document.getElementById('html_global_explorer_new_doctype').value;
			}else{
				document.getElementById('ioi_global_explorer_from_doctype').value = '';
			}

		}else{
			document.getElementById('ioi_global_explorer_from_doctype').value = '';
		}

		document.getElementById('ioi_global_explorer_from_name').value = silicon_ioi.doctype.ioiGlobalExplorer.from_name;
		document.getElementById('ioi_global_explorer_from_idx').value = '';
		document.getElementById('ioi_global_explorer_topn').value = 0;
		document.getElementById('ioi_global_explorer_where').value = '';

		document.getElementById('ioi_global_explorer_sq').checked = false;
		document.getElementById('ioi_global_explorer_so').checked = false;
		document.getElementById('ioi_global_explorer_sd').checked = false;
		document.getElementById('ioi_global_explorer_si').checked = false;
		document.getElementById('ioi_global_explorer_pq').checked = false;
		document.getElementById('ioi_global_explorer_po').checked = false;
		document.getElementById('ioi_global_explorer_pr').checked = false;
		document.getElementById('ioi_global_explorer_pi').checked = false;
		document.getElementById('ioi_global_explorer_ppr').checked = false;
		document.getElementById('ioi_global_explorer_odp').checked = false;
		document.getElementById('ioi_global_explorer_dos').checked = false;
		document.getElementById('ioi_global_explorer_columns_with_fieldname').checked = false;
		document.getElementById('ioi_global_explorer_default').checked = false;

		if (document.getElementById('ioi_global_explorer_from_doctype').value == 'ioi Sales Quote') {
			document.getElementById('ioi_global_explorer_sq').checked = true;
		}else if (document.getElementById('ioi_global_explorer_from_doctype').value == 'ioi Sales Order') {
			document.getElementById('ioi_global_explorer_so').checked = true;
		}else if (document.getElementById('ioi_global_explorer_from_doctype').value == 'ioi Sales Delivery') {
			document.getElementById('ioi_global_explorer_sd').checked = true;
		}else if (document.getElementById('ioi_global_explorer_from_doctype').value == 'ioi Sales Invoice') {
			document.getElementById('ioi_global_explorer_si').checked = true;
		}else if (document.getElementById('ioi_global_explorer_from_doctype').value == 'ioi Purchases Price Request') {
			document.getElementById('ioi_global_explorer_pq').checked = true;
		}else if (document.getElementById('ioi_global_explorer_from_doctype').value == 'ioi Purchases Order') {
			document.getElementById('ioi_global_explorer_po').checked = true;
		}else if (document.getElementById('ioi_global_explorer_from_doctype').value == 'ioi Purchases Receipt') {
			document.getElementById('ioi_global_explorer_pr').checked = true;
		}else if (document.getElementById('ioi_global_explorer_from_doctype').value == 'ioi Purchases Invoice') {
			document.getElementById('ioi_global_explorer_pi').checked = true;
		}else if (document.getElementById('ioi_global_explorer_from_doctype').value == 'ioi Production') {
			document.getElementById('ioi_global_explorer_odp').checked = true;
		}else if (document.getElementById('ioi_global_explorer_from_doctype').value == 'ioi Production BOM') {
			document.getElementById('ioi_global_explorer_odp').checked = true;
		}else if (document.getElementById('ioi_global_explorer_from_doctype').value == 'ioi Dossier BOM') {
			document.getElementById('ioi_global_explorer_dos').checked = true;
		}

		document.getElementById('ioi_global_explorer_table_container').innerHTML = '';
		silicon_ioi.doctype.ioiGlobalExplorer.ioiTable = null;
	}

	static new()
	{
		if ((document.getElementById('ioi_global_explorer_drill_back_label').style.display) && (document.getElementById('ioi_global_explorer_drill_next_label').style.display)) {

			if ((document.getElementById('ioi_global_explorer_drill_back_label').style.display == 'block') ||
				(document.getElementById('ioi_global_explorer_drill_next_label').style.display == 'block')) {
				frappe.msgprint({title: __("Message"), message: __("Not possible when drill through has been actived"), indicator: "red"});
				return false;
			}
		}

		let title = __("New list")

		var globalExplorerNew =  new frappe.ui.Dialog({
			'title': title,
			'static': true,
			'fields': [
				{'fieldname': 'html_global_explorer_new', 'fieldtype': 'HTML'}
			],
			primary_action_label: __('Ok'),
			secondary_action_label: __('Cancel'),
			primary_action: function() {

				let button_ok = document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1];


				button_ok.disabled = true;

				if ((!document.getElementById('html_global_explorer_new_list_name').value) ||
					((document.getElementById('html_global_explorer_new_list_name').value) && (document.getElementById('html_global_explorer_new_list_name').value.trim() == ''))) {
					button_ok.disabled = false;
					frappe.msgprint({title: __("Message"), message: __("List name is mandatory"), indicator: "red"});
					return false;
				}

				let found = 0;

				let method = silicon_ioi.doctype.ioiGlobalExplorer.path_global_explorer_user_list + '.ioi_global_explorer_user_list_check_list_name'

				frappe.call({
					method: method,
					args: {	"from_doctype": document.getElementById('html_global_explorer_new_doctype').value,
							"list_name": document.getElementById('html_global_explorer_new_list_name').value
					},
					async: false,
					callback:function(r)	{
						found = r.message;
					}
				});

				if (found == 1) {
					button_ok.disabled = false;
					frappe.msgprint({title: __("Message"), message: __("List name already exists"), indicator: "red"});
					return false;
				}

				silicon_ioi.doctype.ioiGlobalExplorer.back_list = [];

				if (document.getElementById('ioi_global_explorer_drill_back_label').style.display == 'block') {
					document.getElementById('ioi_global_explorer_drill_back_label').style.display = 'none';
				}

				silicon_ioi.doctype.ioiGlobalExplorer.next_list = [];

				if (document.getElementById('ioi_global_explorer_drill_next_label').style.display == 'block') {
					document.getElementById('ioi_global_explorer_drill_next_label').style.display = 'none';
				}

				let is_live = 0;

				if (silicon_ioi.doctype.ioiGlobalExplorer.is_global_explorer == 0) {
					is_live = 1;
				}


				globalExplorerNew.hide();

				method = silicon_ioi.doctype.ioiGlobalExplorer.path_global_explorer_user_list + '.ioi_global_explorer_user_list_insert_update'

				frappe.call({
					method: method,
					args: {	"from_doctype": document.getElementById('html_global_explorer_new_doctype').value,
							"is_live": is_live,
							"list_name": document.getElementById('html_global_explorer_new_list_name').value
					},
					async: false,
					callback:function(r)	{
						document.getElementById('ioi_global_explorer_from_doctype').value = document.getElementById('html_global_explorer_new_doctype').value;
						silicon_ioi.doctype.ioiGlobalExplorer.clear_screen();
						silicon_ioi.doctype.ioiGlobalExplorer.record_name = r.message.name;
						silicon_ioi.doctype.ioiGlobalExplorer.list_name = document.getElementById('html_global_explorer_new_list_name').value;
						silicon_ioi.doctype.ioiGlobalExplorer.load_combo_list_name(true);
						silicon_ioi.doctype.ioiGlobalExplorer.do_configure_columns();
					}
				});

			},
			secondary_action: function(){

				globalExplorerNew.hide();
			}

		});

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_new_doctype_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_new_doctype');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_new_list_name_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_new_list_name');

		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:130px; width: 100%;">';

		// Doctype
		html += '	<div style="position: relative; top: 0px; left: 0px; width:300px;">';
		html += '		<label id="html_global_explorer_new_doctype_label" style="position: absolute; top: 0px; left: 2px;">' + __("From doctype") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 300px; height: 30px;"> ';
		html += '			<select id="html_global_explorer_new_doctype" class="input-with-feedback form-control bold"> ';
		html += "				<option value='ioi Sales Quote'>" 				+ __("Sales Quote") 			+ "</option>"
		html += "				<option value='ioi Sales Order'>" 				+ __("Sales Order") 			+ "</option>"
		html += "				<option value='ioi Sales Delivery'>" 			+ __("Sales Delivery") 			+ "</option>"
		html += "				<option value='ioi Sales Invoice'>" 			+ __("Sales Invoice") 			+ "</option>"
		html += "				<option value='ioi Purchases Price Request'>" 	+ __("Purchases Price Request") + "</option>"
		html += "				<option value='ioi Purchases Order'>" 			+ __("Purchases Order") 		+ "</option>"
		html += "				<option value='ioi Purchases Receipt'>" 		+ __("Purchases Receipt") 		+ "</option>"
		html += "				<option value='ioi Purchases Invoice'>" 		+ __("Purchases Invoice") 		+ "</option>"
		html += "				<option value='ioi Production'>" 				+ __("Production") 				+ "</option>"
		html += "				<option value='ioi Production BOM'>" 			+ __("Production BOM") 			+ "</option>"
		html += "				<option value='ioi Dossier BOM'>" 				+ __("Dossier BOM") 			+ "</option>"
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		// List name
		html += '	<div style="position: relative; top: 65px; left: 0px; width:300px;">';
		html += '		<label id="html_global_explorer_new_list_name_label" style="position: absolute; top: 0px; left: 2px;">' + __("List name") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 300px; height: 25px;"> ';
		html += '			<input id="html_global_explorer_new_list_name" type="text" class="input-with-feedback form-control bold" value="" >';
		html += '		</div>';
		html += '	</div>';

		html += '</div>';


		globalExplorerNew.fields_dict.html_global_explorer_new.$wrapper.html(html);
		globalExplorerNew.$wrapper.find('.modal-dialog').css("max-width", "350px").css("width", "350px");
		globalExplorerNew.show();

		silicon_ioi.doctype.ioiGlobalExplorer.sleep_static(1000).then(() => {
			document.getElementById('html_global_explorer_new_doctype').value = document.getElementById('ioi_global_explorer_from_doctype').value;

			if (silicon_ioi.doctype.ioiGlobalExplorer.is_global_explorer == 0) {
				document.getElementById('html_global_explorer_new_doctype').disabled = true;
			}
		});

	}

	static open()
	{

		if ((document.getElementById('ioi_global_explorer_drill_back_label').style.display) && (document.getElementById('ioi_global_explorer_drill_next_label').style.display)) {

			if ((document.getElementById('ioi_global_explorer_drill_back_label').style.display == 'block') ||
				(document.getElementById('ioi_global_explorer_drill_next_label').style.display == 'block')) {
				frappe.msgprint({title: __("Message"), message: __("Not possible when drill through has been actived"), indicator: "red"});
				return false;
			}
		}

		if (silicon_ioi.doctype.ioiGlobalExplorer.record_name != '') {
			silicon_ioi.doctype.ioiGlobalExplorer.save_current_list();
		}



		let is_live = 0;

		if (silicon_ioi.doctype.ioiGlobalExplorer.is_global_explorer == 0) {
			is_live = 1;
		}

		let has_one_list = 0;

		let method = silicon_ioi.doctype.ioiGlobalExplorer.path_global_explorer_user_list + '.ioi_global_explorer_user_has_one_list'

		frappe.call({
			method: method,
			args: {	"is_live": is_live,
				"from_doctype": silicon_ioi.doctype.ioiGlobalExplorer.from_doctype
			},
			async: false,
			callback:function(r)	{
				has_one_list = r.message;
			}
		});

		if (has_one_list == 0) {
			return false;
		}

		let title = __("Open");

		var globalExplorerOpenForm = new frappe.ui.Dialog({
			'title': title,
			'static': true,
			'fields': [
				{'fieldname': 'html_global_explorer_open', 'fieldtype': 'HTML'}
			],
			primary_action_label: __('Ok'),
			secondary_action_label: __('Cancel'),
			primary_action: function() {

				let button_ok = document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1];
				button_ok.disabled = true;

				let method = silicon_ioi.doctype.ioiGlobalExplorer.path_global_explorer_user_list + '.ioi_global_explorer_user_list_get'

				let rec = {};

				frappe.call({
					method: method,
					args: {	"name": document.getElementById('html_global_explorer_open_list_name').value
					},
					async: false,
					callback:function(r)	{
						rec = r.message;
					}
				});

				silicon_ioi.doctype.ioiGlobalExplorer.back_list = [];

				if (document.getElementById('ioi_global_explorer_drill_back_label').style.display == 'block') {
					document.getElementById('ioi_global_explorer_drill_back_label').style.display = 'none';
				}

				silicon_ioi.doctype.ioiGlobalExplorer.next_list = [];

				if (document.getElementById('ioi_global_explorer_drill_next_label').style.display == 'block') {
					document.getElementById('ioi_global_explorer_drill_next_label').style.display = 'none';
				}
				silicon_ioi.doctype.ioiGlobalExplorer.record_name = rec.name;
				silicon_ioi.doctype.ioiGlobalExplorer.list_name = rec.list_name;
				silicon_ioi.doctype.ioiGlobalExplorer.from_doctype = rec.from_doctype;
				silicon_ioi.doctype.ioiGlobalExplorer.from_name = rec.from_name;
				silicon_ioi.doctype.ioiGlobalExplorer.from_detail_idx = rec.from_idx;
				silicon_ioi.doctype.ioiGlobalExplorer.from_detail_name = '';
				silicon_ioi.doctype.ioiGlobalExplorer.topn = rec.topn;
				silicon_ioi.doctype.ioiGlobalExplorer.where = rec.where_clause;
				silicon_ioi.doctype.ioiGlobalExplorer.having = rec.having_clause;
				silicon_ioi.doctype.ioiGlobalExplorer.sq = rec.sq;
				silicon_ioi.doctype.ioiGlobalExplorer.so = rec.so;
				silicon_ioi.doctype.ioiGlobalExplorer.sd = rec.sd;
				silicon_ioi.doctype.ioiGlobalExplorer.si = rec.si;
				silicon_ioi.doctype.ioiGlobalExplorer.pq = rec.pq;
				silicon_ioi.doctype.ioiGlobalExplorer.po = rec.po;
				silicon_ioi.doctype.ioiGlobalExplorer.pr = rec.pr;
				silicon_ioi.doctype.ioiGlobalExplorer.pi = rec.pi;
				silicon_ioi.doctype.ioiGlobalExplorer.ppr = rec.ppr;
				silicon_ioi.doctype.ioiGlobalExplorer.odp = rec.odp;
				silicon_ioi.doctype.ioiGlobalExplorer.dos = rec.dos;
				silicon_ioi.doctype.ioiGlobalExplorer.tech_fieldname = rec.tech_fieldname;
				silicon_ioi.doctype.ioiGlobalExplorer.default = rec.is_default;
				silicon_ioi.doctype.ioiGlobalExplorer.tab_fields = rec.tab_fields;
				silicon_ioi.doctype.ioiGlobalExplorer.tab_isjumps = rec.tab_isjumps;
				silicon_ioi.doctype.ioiGlobalExplorer.tab_isformulas = rec.tab_isformulas;
				silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description = rec.tab_formulas_descripion;
				silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby = rec.tab_orderby;
				silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby_sens = rec.tab_orderby_sens;

				silicon_ioi.doctype.ioiGlobalExplorer.summary = rec.summary
				silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields = rec.tab_summary_fields;
				silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action = rec.tab_summary_action;

				document.getElementById('ioi_global_explorer_from_doctype').value = silicon_ioi.doctype.ioiGlobalExplorer.from_doctype;
				document.getElementById('ioi_global_explorer_from_name').value = silicon_ioi.doctype.ioiGlobalExplorer.from_name;
				document.getElementById('ioi_global_explorer_from_idx').value = silicon_ioi.doctype.ioiGlobalExplorer.from_detail_idx;
				document.getElementById('ioi_global_explorer_topn').value = silicon_ioi.doctype.ioiGlobalExplorer.topn;
				document.getElementById('ioi_global_explorer_where').value = silicon_ioi.doctype.ioiGlobalExplorer.where;

				document.getElementById('ioi_global_explorer_sq').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.sq == 1) {
					document.getElementById('ioi_global_explorer_sq').checked = true;
				}

				document.getElementById('ioi_global_explorer_so').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.so == 1) {
					document.getElementById('ioi_global_explorer_so').checked = true;
				}

				document.getElementById('ioi_global_explorer_sd').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.sd == 1) {
					document.getElementById('ioi_global_explorer_sd').checked = true;
				}

				document.getElementById('ioi_global_explorer_si').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.si == 1) {
					document.getElementById('ioi_global_explorer_si').checked = true;
				}

				document.getElementById('ioi_global_explorer_pq').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.pq == 1) {
					document.getElementById('ioi_global_explorer_pq').checked = true;
				}

				document.getElementById('ioi_global_explorer_po').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.po == 1) {
					document.getElementById('ioi_global_explorer_po').checked = true;
				}

				document.getElementById('ioi_global_explorer_pr').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.pr == 1) {
					document.getElementById('ioi_global_explorer_pr').checked = true;
				}

				document.getElementById('ioi_global_explorer_pi').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.pi == 1) {
					document.getElementById('ioi_global_explorer_pi').checked = true;
				}

				document.getElementById('ioi_global_explorer_ppr').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.ppr == 1) {
					document.getElementById('ioi_global_explorer_ppr').checked = true;
				}

				document.getElementById('ioi_global_explorer_odp').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.odp == 1) {
					document.getElementById('ioi_global_explorer_odp').checked = true;
				}


				document.getElementById('ioi_global_explorer_dos').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.dos == 1) {
					document.getElementById('ioi_global_explorer_dos').checked = true;
				}

				document.getElementById('ioi_global_explorer_columns_with_fieldname').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.tech_fieldname == 1) {
					document.getElementById('ioi_global_explorer_columns_with_fieldname').checked = true;
				}

				document.getElementById('ioi_global_explorer_columns_with_fieldname').checked = false;

				if (silicon_ioi.doctype.ioiGlobalExplorer.default == 1) {
					document.getElementById('ioi_global_explorer_default').checked = true;
				}

				silicon_ioi.doctype.ioiGlobalExplorer.load_combo_list_name();

				silicon_ioi.doctype.ioiGlobalExplorer.refresh();

				globalExplorerOpenForm.hide();
			},
			secondary_action: function(){

				globalExplorerOpenForm.hide();
			}

		});

		silicon_ioi.doctype.ioiGlobalExplorer.tab_open = [];
		silicon_ioi.doctype.ioiGlobalExplorer.tab_open_doctype = [];

		method = silicon_ioi.doctype.ioiGlobalExplorer.path_global_explorer_user_list + '.ioi_global_explorer_user_get_all_list'

		frappe.call({
			method: method,
			args: {	"is_live": is_live,
					"from_doctype": silicon_ioi.doctype.ioiGlobalExplorer.from_doctype
			},
			async: false,
			callback:function(r)	{
				silicon_ioi.doctype.ioiGlobalExplorer.tab_open = r.message;
			}
		});

		for (var i = 0; i < silicon_ioi.doctype.ioiGlobalExplorer.tab_open.length; i++) {

			let found = false;

			for (var k = 0; k  < silicon_ioi.doctype.ioiGlobalExplorer.tab_open_doctype.length; k++) {

				if (silicon_ioi.doctype.ioiGlobalExplorer.tab_open_doctype[k][0] == silicon_ioi.doctype.ioiGlobalExplorer.tab_open[i].from_doctype) {
					found = true;
					break;
				}

			}

			if (!found) {

				silicon_ioi.doctype.ioiGlobalExplorer.tab_open_doctype[silicon_ioi.doctype.ioiGlobalExplorer.tab_open_doctype.length] = silicon_ioi.doctype.ioiGlobalExplorer.tab_open[i].from_doctype;
			}
		}

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_open_doctype_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_open_doctype');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_open_list_name_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_open_list_name');


		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:130px; width: 100%;">';

		// Doctype
		html += '	<div style="position: relative; top: 0px; left: 0px; width:300px;">';
		html += '		<label id="html_global_explorer_open_doctype_label" style="position: absolute; top: 0px; left: 2px;">' + __("From doctype") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 300px; height: 30px;"> ';
		html += '			<select id="html_global_explorer_open_doctype" class="input-with-feedback form-control bold"> ';
		html += "				<option value='ioi Sales Quote'>" 				+ __("Sales Quote") 			+ "</option>"
		html += "				<option value='ioi Sales Order'>" 				+ __("Sales Order") 			+ "</option>"
		html += "				<option value='ioi Sales Delivery'>" 			+ __("Sales Delivery") 			+ "</option>"
		html += "				<option value='ioi Sales Invoice'>" 			+ __("Sales Invoice") 			+ "</option>"
		html += "				<option value='ioi Purchases Price Request'>" 	+ __("Purchases Price Request") + "</option>"
		html += "				<option value='ioi Purchases Order'>" 			+ __("Purchases Order") 		+ "</option>"
		html += "				<option value='ioi Purchases Receipt'>" 		+ __("Purchases Receipt") 		+ "</option>"
		html += "				<option value='ioi Purchases Invoice'>" 		+ __("Purchases Invoice") 		+ "</option>"
		html += "				<option value='ioi Production'>" 				+ __("Production") 				+ "</option>"
		html += "				<option value='ioi Production BOM'>" 			+ __("Production BOM") 			+ "</option>"
		html += "				<option value='ioi Dossier BOM'>" 				+ __("Dossier BOM") 			+ "</option>"
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';


		// List name
		html += '	<div style="position: relative; top: 65px; left: 0px; width:300px;">';
		html += '		<label id="html_global_explorer_open_list_name_label" style="position: absolute; top: 0px; left: 2px;">' + __("From doctype") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 300px; height: 30px;"> ';
		html += '			<select id="html_global_explorer_open_list_name" class="input-with-feedback form-control bold"> ';
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		html += '</div>';


		globalExplorerOpenForm.fields_dict.html_global_explorer_open.$wrapper.html(html);
		globalExplorerOpenForm.$wrapper.find('.modal-dialog').css("max-width", "3500px").css("width", "350px");
		globalExplorerOpenForm.show();

		silicon_ioi.doctype.ioiGlobalExplorer.sleep_static(1000).then(() => {

			for (var k = document.getElementById('html_global_explorer_open_doctype').options.length-1; k >= 0; k--) {

				let found = false;

				for (var i = 0; i < silicon_ioi.doctype.ioiGlobalExplorer.tab_open_doctype.length; i++) {

					if (silicon_ioi.doctype.ioiGlobalExplorer.tab_open_doctype[i] == document.getElementById('html_global_explorer_open_doctype').options[k].value) {
						found = true;
						break;
					}

				}

				if (!found) {
					document.getElementById('html_global_explorer_open_doctype').options[k].remove()
				}
			}

			let fct_change = function() {

				for (var k = document.getElementById('html_global_explorer_open_list_name').options.length-1; k >= 0; k--) {
					document.getElementById('html_global_explorer_open_list_name').options[k].remove();
				}

				let current_doctype = this.value;

				for (var i = 0; i < silicon_ioi.doctype.ioiGlobalExplorer.tab_open.length; i++) {

					if (silicon_ioi.doctype.ioiGlobalExplorer.tab_open[i].from_doctype == current_doctype) {

						let option = document.createElement("option");
						option.value = silicon_ioi.doctype.ioiGlobalExplorer.tab_open[i].name;
						option.text = silicon_ioi.doctype.ioiGlobalExplorer.tab_open[i].list_name;

						document.getElementById('html_global_explorer_open_list_name').add(option);

					}
				}


			}

			document.getElementById('html_global_explorer_open_doctype').onchange = fct_change;

			let current_doctype = document.getElementById('html_global_explorer_open_doctype').value;

			for (var i = 0; i < silicon_ioi.doctype.ioiGlobalExplorer.tab_open.length; i++) {

				if (silicon_ioi.doctype.ioiGlobalExplorer.tab_open[i].from_doctype == current_doctype) {

					let option = document.createElement("option");
					option.value = silicon_ioi.doctype.ioiGlobalExplorer.tab_open[i].name;
					option.text = silicon_ioi.doctype.ioiGlobalExplorer.tab_open[i].list_name;

					document.getElementById('html_global_explorer_open_list_name').add(option);

				}
			}

		});


	}

	static delete()
	{

		if ((document.getElementById('ioi_global_explorer_drill_back_label').style.display) && (document.getElementById('ioi_global_explorer_drill_next_label').style.display)) {

			if ((document.getElementById('ioi_global_explorer_drill_back_label').style.display == 'block') ||
				(document.getElementById('ioi_global_explorer_drill_next_label').style.display == 'block')) {
				frappe.msgprint({title: __("Message"), message: __("Not possible when drill through has been actived"), indicator: "red"});
				return false;
			}
		}

		if (silicon_ioi.doctype.ioiGlobalExplorer.record_name != '') {

			let msg = __("Delete this list ?");

			frappe.confirm(	msg,
				() => {

					let method = silicon_ioi.doctype.ioiGlobalExplorer.path_global_explorer_user_list + '.ioi_global_explorer_user_list_delete'

					frappe.call({
						method: method,
						args: {	"name": silicon_ioi.doctype.ioiGlobalExplorer.record_name
						},
						async: false,
						callback:function(r)	{

							silicon_ioi.doctype.ioiGlobalExplorer.back_list = [];

							if (document.getElementById('ioi_global_explorer_drill_back_label').style.display == 'block') {
								document.getElementById('ioi_global_explorer_drill_back_label').style.display = 'none';
							}

							silicon_ioi.doctype.ioiGlobalExplorer.next_list = [];

							if (document.getElementById('ioi_global_explorer_drill_next_label').style.display == 'block') {
								document.getElementById('ioi_global_explorer_drill_next_label').style.display = 'none';
							}

							silicon_ioi.doctype.ioiGlobalExplorer.clear_screen();
							silicon_ioi.doctype.ioiGlobalExplorer.load_combo_list_name();
						}
					});

				},
				() => {
				}
			);
		}

	}

	static saveas()
	{
		if (silicon_ioi.doctype.ioiGlobalExplorer.record_name == '') {
			return false;
		}

		if ((document.getElementById('ioi_global_explorer_drill_back_label').style.display) && (document.getElementById('ioi_global_explorer_drill_next_label').style.display)) {

			if ((document.getElementById('ioi_global_explorer_drill_back_label').style.display == 'block') ||
				(document.getElementById('ioi_global_explorer_drill_next_label').style.display == 'block')) {
				frappe.msgprint({title: __("Message"), message: __("Not possible when drill through has been actived"), indicator: "red"});
				return false;
			}
		}

		silicon_ioi.doctype.ioiGlobalExplorer.save_current_list();

		let title = __("Save as")

		var globalExplorerNew =  new frappe.ui.Dialog({
			'title': title,
			'static': true,
			'fields': [
				{'fieldname': 'html_global_explorer_new', 'fieldtype': 'HTML'}
			],
			primary_action_label: __('Ok'),
			secondary_action_label: __('Cancel'),
			primary_action: function() {

				let button_ok = document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1];


				button_ok.disabled = true;

				if ((!document.getElementById('html_global_explorer_new_list_name').value) ||
					((document.getElementById('html_global_explorer_new_list_name').value) && (document.getElementById('html_global_explorer_new_list_name').value.trim() == ''))) {
					button_ok.disabled = false;
					frappe.msgprint({title: __("Message"), message: __("List name is mandatory"), indicator: "red"});
					return false;
				}

				let found = 0;

				let method = silicon_ioi.doctype.ioiGlobalExplorer.path_global_explorer_user_list + '.ioi_global_explorer_user_list_check_list_name'

				frappe.call({
					method: method,
					args: {	"from_doctype": document.getElementById('html_global_explorer_new_doctype').value,
							"list_name": document.getElementById('html_global_explorer_new_list_name').value
					},
					async: false,
					callback:function(r)	{
						found = r.message;
					}
				});

				if (found == 1) {
					button_ok.disabled = false;
					frappe.msgprint({title: __("Message"), message: __("List name already exists"), indicator: "red"});
					return false;
				}

				silicon_ioi.doctype.ioiGlobalExplorer.back_list = [];

				if (document.getElementById('ioi_global_explorer_drill_back_label').style.display == 'block') {
					document.getElementById('ioi_global_explorer_drill_back_label').style.display = 'none';
				}

				silicon_ioi.doctype.ioiGlobalExplorer.next_list = [];

				if (document.getElementById('ioi_global_explorer_drill_next_label').style.display == 'block') {
					document.getElementById('ioi_global_explorer_drill_next_label').style.display = 'none';
				}

				let is_live = 0;

				if (silicon_ioi.doctype.ioiGlobalExplorer.is_global_explorer == 0) {
					is_live = 1;
				}

				method = silicon_ioi.doctype.ioiGlobalExplorer.path_global_explorer_user_list + '.ioi_global_explorer_user_list_saveas'

				frappe.call({
					method: method,
					args: {	"current_name": silicon_ioi.doctype.ioiGlobalExplorer.record_name,
							"is_live": is_live,
							"list_name": document.getElementById('html_global_explorer_new_list_name').value
					},
					async: false,
					callback:function(r)	{
						silicon_ioi.doctype.ioiGlobalExplorer.record_name = r.message.name;
						silicon_ioi.doctype.ioiGlobalExplorer.list_name = document.getElementById('html_global_explorer_new_list_name').value;

						silicon_ioi.doctype.ioiGlobalExplorer.load_combo_list_name(true);

					}
				});

				globalExplorerNew.hide();
			},
			secondary_action: function(){

				globalExplorerNew.hide();
			}

		});

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_new_doctype_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_new_doctype');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_new_list_name_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_new_list_name');

		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:130px; width: 100%;">';

		// Doctype
		html += '	<div style="position: relative; top: 0px; left: 0px; width:300px;">';
		html += '		<label id="html_global_explorer_new_doctype_label" style="position: absolute; top: 0px; left: 2px;">' + __("From doctype") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 300px; height: 30px;"> ';
		html += '			<select id="html_global_explorer_new_doctype" class="input-with-feedback form-control bold"> ';
		html += "				<option value='ioi Sales Quote'>" 				+ __("Sales Quote") 			+ "</option>"
		html += "				<option value='ioi Sales Order'>" 				+ __("Sales Order") 			+ "</option>"
		html += "				<option value='ioi Sales Delivery'>" 			+ __("Sales Delivery") 			+ "</option>"
		html += "				<option value='ioi Sales Invoice'>" 			+ __("Sales Invoice") 			+ "</option>"
		html += "				<option value='ioi Purchases Price Request'>" 	+ __("Purchases Price Request") + "</option>"
		html += "				<option value='ioi Purchases Order'>" 			+ __("Purchases Order") 		+ "</option>"
		html += "				<option value='ioi Purchases Receipt'>" 		+ __("Purchases Receipt") 		+ "</option>"
		html += "				<option value='ioi Purchases Invoice'>" 		+ __("Purchases Invoice") 		+ "</option>"
		html += "				<option value='ioi Production'>" 				+ __("Production") 				+ "</option>"
		html += "				<option value='ioi Production BOM'>" 			+ __("Production BOM") 			+ "</option>"
		html += "				<option value='ioi Dossier BOM'>" 				+ __("Dossier BOM") 			+ "</option>"
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		// List name
		html += '	<div style="position: relative; top: 65px; left: 0px; width:300px;">';
		html += '		<label id="html_global_explorer_new_list_name_label" style="position: absolute; top: 0px; left: 2px;">' + __("List name") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 300px; height: 25px;"> ';
		html += '			<input id="html_global_explorer_new_list_name" type="text" class="input-with-feedback form-control bold" value="" >';
		html += '		</div>';
		html += '	</div>';

		html += '</div>';


		globalExplorerNew.fields_dict.html_global_explorer_new.$wrapper.html(html);
		globalExplorerNew.$wrapper.find('.modal-dialog').css("max-width", "350px").css("width", "350px");
		globalExplorerNew.show();

		silicon_ioi.doctype.ioiGlobalExplorer.sleep_static(1000).then(() => {
			document.getElementById('html_global_explorer_new_doctype').value = document.getElementById('ioi_global_explorer_from_doctype').value;
			document.getElementById('html_global_explorer_new_doctype').disabled = true;
		});
	}

	static export()
	{
		if (!silicon_ioi.doctype.ioiGlobalExplorer.ioiTable) {
			frappe.msgprint({title: __("Message"), message: __("No data to export"), indicator: "red"});
			return false;
		}

		let rows = silicon_ioi.doctype.ioiGlobalExplorer.ioiTable.getRows();


		if ((!rows) || (rows.length == 0)) {
			frappe.msgprint({title: __("Message"), message: __("No data to export"), indicator: "red"});
			return false;
		}

		silicon_ioi.doctype.ioiGlobalExplorer.ioiTable.download("xlsx", "global_explorer.xlsx");
	}

	static getsql()
	{
		if (silicon_ioi.doctype.ioiGlobalExplorer.record_name != '') {

			let data = {};

			let method = silicon_ioi.doctype.ioiGlobalExplorer.path_jit_engine_link + '.ioi_jit_engine_link_explorer_can_get_sql'

			frappe.call({
				method: method,
				args: {
				},
				async: false,
				callback:function(r)	{
					data = r.message;
				}
			});

			if (data.error == 1) {
				frappe.msgprint({title: __("Message"), message: data.error_msg, indicator: "red"});
				return false;
			}

			silicon_ioi.doctype.ioiGlobalExplorer.save_current_list();

			silicon_ioi.doctype.ioiGlobalExplorer.refresh(true);

			let title = __("SQL script")

			var globalExplorerSQLForm =  new frappe.ui.Dialog({
				'title': title,
				'static': true,
				'fields': [
					{'fieldname': 'html_global_explorer_sql', 'fieldtype': 'HTML'}
				],
				primary_action_label: __('Ok'),
				primary_action: function() {

					globalExplorerSQLForm.hide();
				}

			});

			silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_sql_script');


			let html = '';

			html += '<div style="overflow: auto; overflow-x: auto; height:600px; width: 100%;">';

			html += '	<div style="position: relative; top: 0px; left: 0px; width:950px;">';
			html += '		<div class="control-input" style="position: absolute; top: 0px; left: 0px; width: 950px; height: 25px;"> ';
			html += '			<textarea id="ioi_global_explorer_sql_script" style="position: absolute; top: 0px; left: 2px; width: 950px; height: 590px; resize: none;" class="input-with-feedback form-control " >';
			html += '			</textarea>';
			html += '		</div>';
			html += '	</div>';

			html += '</div>';


			globalExplorerSQLForm.fields_dict.html_global_explorer_sql.$wrapper.html(html);
			globalExplorerSQLForm.$wrapper.find('.modal-dialog').css("max-width", "1000px").css("width", "1000px");
			globalExplorerSQLForm.show();

			silicon_ioi.doctype.ioiGlobalExplorer.sleep_static(1000).then(() => {

				document.getElementById('ioi_global_explorer_sql_script').value = silicon_ioi.doctype.ioiGlobalExplorer.sql_script;
				document.getElementById('ioi_global_explorer_sql_script').readOnly = true;
			});
		}

	}

	static updown()
	{
		let h = document.getElementById('ioi_global_explorer_where_area').style.height;

		if (h.indexOf('px') != -1) {
			h = h.substring(0, h.indexOf('px'))
		}

		if (parseInt(h) == 0) {
			document.getElementById('ioi_global_explorer_where_area').style.height = '110px';
		}else{
			document.getElementById('ioi_global_explorer_where_area').style.height = '0px';
		}

	}

	static create_tabulator()
	{
		silicon_ioi.doctype.ioiGlobalExplorer.ioiTable = new ioi.Tabulator('#ioi_global_explorer_table_container', {
			data: silicon_ioi.doctype.ioiGlobalExplorer.data,
			maxHeight: 600,
			rowHeight: null,
			selectableRows: 1,
			showProfiles: false,
			movableColumns: true,
			resizableColumns: true,
			autoRedraw: true,
		});
	}

	static refresh(get_sql = false, bypass = false)
	{

		silicon_ioi.doctype.ioiGlobalExplorer.assign_screen_element_to_properties();



		if ((silicon_ioi.doctype.ioiGlobalExplorer.record_name.trim() == '') && (!bypass)) {

			let title = __("New list")

			var globalExplorerNew =  new frappe.ui.Dialog({
				'title': title,
				'static': true,
				'fields': [
					{'fieldname': 'html_global_explorer_new', 'fieldtype': 'HTML'}
				],
				primary_action_label: __('Ok'),
				secondary_action_label: __('Cancel'),
				primary_action: function() {

					let button_ok = document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1];


					button_ok.disabled = true;

					if ((!document.getElementById('html_global_explorer_new_list_name').value) ||
						((document.getElementById('html_global_explorer_new_list_name').value) && (document.getElementById('html_global_explorer_new_list_name').value.trim() == ''))) {
						button_ok.disabled = false;
						frappe.msgprint({title: __("Message"), message: __("List name is mandatory"), indicator: "red"});
						return false;
					}

					let found = 0;

					let method = silicon_ioi.doctype.ioiGlobalExplorer.path_global_explorer_user_list + '.ioi_global_explorer_user_list_check_list_name'

					frappe.call({
						method: method,
						args: {	"from_doctype": document.getElementById('html_global_explorer_new_doctype').value,
								"list_name": document.getElementById('html_global_explorer_new_list_name').value
						},
						async: false,
						callback:function(r)	{
							found = r.message;
						}
					});

					if (found == 1) {
						button_ok.disabled = false;
						frappe.msgprint({title: __("Message"), message: __("List name already exists"), indicator: "red"});
						return false;
					}


					let is_live = 0;

					if (silicon_ioi.doctype.ioiGlobalExplorer.is_global_explorer == 0) {
						is_live = 1;
					}

					method = silicon_ioi.doctype.ioiGlobalExplorer.path_global_explorer_user_list + '.ioi_global_explorer_user_list_insert_update'

					frappe.call({
						method: method,
						args: {	"from_doctype": document.getElementById('html_global_explorer_new_doctype').value,
								"is_live": is_live,
								"list_name": document.getElementById('html_global_explorer_new_list_name').value,
								"is_default": silicon_ioi.doctype.ioiGlobalExplorer.default,
								"from_idx": document.getElementById('ioi_global_explorer_from_idx').value,
								"topn": document.getElementById('ioi_global_explorer_topn').value,
								"sq": silicon_ioi.doctype.ioiGlobalExplorer.sq,
								"so": silicon_ioi.doctype.ioiGlobalExplorer.so,
								"sd": silicon_ioi.doctype.ioiGlobalExplorer.sd,
								"si": silicon_ioi.doctype.ioiGlobalExplorer.si,
								"pq": silicon_ioi.doctype.ioiGlobalExplorer.pq,
								"po": silicon_ioi.doctype.ioiGlobalExplorer.po,
								"pr": silicon_ioi.doctype.ioiGlobalExplorer.pr,
								"pi": silicon_ioi.doctype.ioiGlobalExplorer.pi,
								"ppr": silicon_ioi.doctype.ioiGlobalExplorer.ppr,
								"odp": silicon_ioi.doctype.ioiGlobalExplorer.odp,
								"dos": silicon_ioi.doctype.ioiGlobalExplorer.dos,
								"tech_fieldname": silicon_ioi.doctype.ioiGlobalExplorer.tech_fieldname,
								"where_clause": document.getElementById('ioi_global_explorer_where').value,
								"having_clause": silicon_ioi.doctype.ioiGlobalExplorer.having,
								"tab_fields": silicon_ioi.doctype.ioiGlobalExplorer.tab_fields,
								"tab_isjumps": silicon_ioi.doctype.ioiGlobalExplorer.tab_isjumps,
								"tab_isformulas": silicon_ioi.doctype.ioiGlobalExplorer.tab_isformulas,
								"tab_formulas_description": silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description,
								"tab_orderby": silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby,
								"tab_orderby_sens": silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby_sens,
								"summary": silicon_ioi.doctype.ioiGlobalExplorer.summary,
								"tab_summary_fields": silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields,
								"tab_summary_action": silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action
						},
						async: false,
						callback:function(r)	{

							silicon_ioi.doctype.ioiGlobalExplorer.record_name = r.message.name;
							silicon_ioi.doctype.ioiGlobalExplorer.from_doctype = document.getElementById('html_global_explorer_new_doctype').value;
							silicon_ioi.doctype.ioiGlobalExplorer.list_name = document.getElementById('html_global_explorer_new_list_name').value;

							silicon_ioi.doctype.ioiGlobalExplorer.do_refresh(get_sql, bypass);
						}
					});

					globalExplorerNew.hide();
				},
				secondary_action: function(){

					globalExplorerNew.hide();
				}

			});

			silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_new_doctype_label');
			silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_new_doctype');

			silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_new_list_name_label');
			silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_new_list_name');

			let html = '';

			html += '<div style="overflow: auto; overflow-x: auto; height:130px; width: 100%;">';

			// Doctype
			html += '	<div style="position: relative; top: 0px; left: 0px; width:300px;">';
			html += '		<label id="html_global_explorer_new_doctype_label" style="position: absolute; top: 0px; left: 2px;">' + __("From doctype") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 300px; height: 30px;"> ';
			html += '			<select id="html_global_explorer_new_doctype" class="input-with-feedback form-control bold"> ';
			html += "				<option value='ioi Sales Quote'>" 				+ __("Sales Quote") 			+ "</option>"
			html += "				<option value='ioi Sales Order'>" 				+ __("Sales Order") 			+ "</option>"
			html += "				<option value='ioi Sales Delivery'>" 			+ __("Sales Delivery") 			+ "</option>"
			html += "				<option value='ioi Sales Invoice'>" 			+ __("Sales Invoice") 			+ "</option>"
			html += "				<option value='ioi Purchases Price Request'>" 	+ __("Purchases Price Request") + "</option>"
			html += "				<option value='ioi Purchases Order'>" 			+ __("Purchases Order") 		+ "</option>"
			html += "				<option value='ioi Purchases Receipt'>" 		+ __("Purchases Receipt") 		+ "</option>"
			html += "				<option value='ioi Purchases Invoice'>" 		+ __("Purchases Invoice") 		+ "</option>"
			html += "				<option value='ioi Production'>" 				+ __("Production") 				+ "</option>"
			html += "				<option value='ioi Production BOM'>" 			+ __("Production BOM") 			+ "</option>"
			html += "				<option value='ioi Dossier BOM'>" 				+ __("Dossier BOM") 			+ "</option>"
			html += '			</select> ';
			html += '		</div>';
			html += '	</div>';

			// List name
			html += '	<div style="position: relative; top: 65px; left: 0px; width:300px;">';
			html += '		<label id="html_global_explorer_new_list_name_label" style="position: absolute; top: 0px; left: 2px;">' + __("List name") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 300px; height: 25px;"> ';
			html += '			<input id="html_global_explorer_new_list_name" type="text" class="input-with-feedback form-control bold" value="" >';
			html += '		</div>';
			html += '	</div>';

			html += '</div>';


			globalExplorerNew.fields_dict.html_global_explorer_new.$wrapper.html(html);
			globalExplorerNew.$wrapper.find('.modal-dialog').css("max-width", "350px").css("width", "350px");
			globalExplorerNew.show();

			silicon_ioi.doctype.ioiGlobalExplorer.sleep_static(1000).then(() => {
				document.getElementById('html_global_explorer_new_doctype').value = document.getElementById('ioi_global_explorer_from_doctype').value;

				if (silicon_ioi.doctype.ioiGlobalExplorer.is_global_explorer == 0) {
					document.getElementById('html_global_explorer_new_doctype').disabled = true;
				}
			});



		}else{
			silicon_ioi.doctype.ioiGlobalExplorer.do_refresh(get_sql, bypass);
		}

	}

	static do_refresh(get_sql = false, bypass = false, not_save = false)
	{

		if (document.getElementById('ioi_global_explorer_from_idx').value == '0') {
			document.getElementById('ioi_global_explorer_from_idx').value = '';
		}

		if (!bypass) {

			if (!not_save) {
				silicon_ioi.doctype.ioiGlobalExplorer.save_current_list();
			}
		}

		silicon_ioi.doctype.ioiGlobalExplorer.data = [];

		silicon_ioi.doctype.ioiGlobalExplorer.from_doctype = '';

		if ((document.getElementById('ioi_global_explorer_from_doctype').value) && (document.getElementById('ioi_global_explorer_from_doctype').value.trim() != '')) {
			silicon_ioi.doctype.ioiGlobalExplorer.from_doctype = document.getElementById('ioi_global_explorer_from_doctype').value;
		}

		silicon_ioi.doctype.ioiGlobalExplorer.from_name = '';

		if ((document.getElementById('ioi_global_explorer_from_name').value) && (document.getElementById('ioi_global_explorer_from_name').value.trim() != '')) {
			silicon_ioi.doctype.ioiGlobalExplorer.from_name = document.getElementById('ioi_global_explorer_from_name').value.toUpperCase();
		}

		silicon_ioi.doctype.ioiGlobalExplorer.from_detail_idx = '';

		if ((document.getElementById('ioi_global_explorer_from_idx').value) && (document.getElementById('ioi_global_explorer_from_idx').value.trim() != '')) {
			silicon_ioi.doctype.ioiGlobalExplorer.from_detail_idx = document.getElementById('ioi_global_explorer_from_idx').value;
		}

		silicon_ioi.doctype.ioiGlobalExplorer.from_detail_name = '';

		silicon_ioi.doctype.ioiGlobalExplorer.topn = -1;

		if ((document.getElementById('ioi_global_explorer_topn').value) && (document.getElementById('ioi_global_explorer_topn').value.trim() != '')) {

			if (parseInt(document.getElementById('ioi_global_explorer_topn').value) > 0) {
				silicon_ioi.doctype.ioiGlobalExplorer.topn = parseInt(document.getElementById('ioi_global_explorer_topn').value);
			}else{
				document.getElementById('ioi_global_explorer_topn').value = '0';
			}
		}


		silicon_ioi.doctype.ioiGlobalExplorer.where = '';

		if ((document.getElementById('ioi_global_explorer_where').value) && (document.getElementById('ioi_global_explorer_where').value.trim() != '')) {
			silicon_ioi.doctype.ioiGlobalExplorer.where = document.getElementById('ioi_global_explorer_where').value;
		}

		silicon_ioi.doctype.ioiGlobalExplorer.sq = 0;

		if (document.getElementById('ioi_global_explorer_sq').checked) {
			silicon_ioi.doctype.ioiGlobalExplorer.sq = 1;
		}


		silicon_ioi.doctype.ioiGlobalExplorer.so = 0;

		if (document.getElementById('ioi_global_explorer_so').checked) {
			silicon_ioi.doctype.ioiGlobalExplorer.so = 1;
		}


		silicon_ioi.doctype.ioiGlobalExplorer.sd = 0;

		if (document.getElementById('ioi_global_explorer_sd').checked) {
			silicon_ioi.doctype.ioiGlobalExplorer.sd = 1;
		}


		silicon_ioi.doctype.ioiGlobalExplorer.si = 0;

		if (document.getElementById('ioi_global_explorer_si').checked) {
			silicon_ioi.doctype.ioiGlobalExplorer.si = 1;
		}

		silicon_ioi.doctype.ioiGlobalExplorer.pq = 0;

		if (document.getElementById('ioi_global_explorer_pq').checked) {
			silicon_ioi.doctype.ioiGlobalExplorer.pq = 1;
		}

		silicon_ioi.doctype.ioiGlobalExplorer.po = 0;

		if (document.getElementById('ioi_global_explorer_po').checked) {
			silicon_ioi.doctype.ioiGlobalExplorer.po = 1;
		}

		silicon_ioi.doctype.ioiGlobalExplorer.pr = 0;

		if (document.getElementById('ioi_global_explorer_pr').checked) {
			silicon_ioi.doctype.ioiGlobalExplorer.pr = 1;
		}

		silicon_ioi.doctype.ioiGlobalExplorer.pi = 0;

		if (document.getElementById('ioi_global_explorer_pi').checked) {
			silicon_ioi.doctype.ioiGlobalExplorer.pi = 1;
		}

		silicon_ioi.doctype.ioiGlobalExplorer.ppr = 0;

		if (document.getElementById('ioi_global_explorer_ppr').checked) {
			silicon_ioi.doctype.ioiGlobalExplorer.ppr = 1;
		}

		silicon_ioi.doctype.ioiGlobalExplorer.odp = 0;

		if (document.getElementById('ioi_global_explorer_odp').checked) {
			silicon_ioi.doctype.ioiGlobalExplorer.odp = 1;
		}

		silicon_ioi.doctype.ioiGlobalExplorer.dos = 0;

		if (document.getElementById('ioi_global_explorer_dos').checked) {
			silicon_ioi.doctype.ioiGlobalExplorer.dos = 1;
		}

		silicon_ioi.doctype.ioiGlobalExplorer.tech_fieldname = 0;

		if (document.getElementById('ioi_global_explorer_columns_with_fieldname').checked) {
			silicon_ioi.doctype.ioiGlobalExplorer.tech_fieldname = 1;
		}



		silicon_ioi.doctype.ioiGlobalExplorer.default = 0;

		if (document.getElementById('ioi_global_explorer_default').checked) {
			silicon_ioi.doctype.ioiGlobalExplorer.default = 1;
		}

		let get_script = 0

		if (get_sql) {
			get_script = 1;
		}

		if ((silicon_ioi.doctype.ioiGlobalExplorer.from_doctype) && (silicon_ioi.doctype.ioiGlobalExplorer.from_doctype.trim() != '')) {

			if (silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action.length == 0) {

				if ((silicon_ioi.doctype.ioiGlobalExplorer.record_name) && (silicon_ioi.doctype.ioiGlobalExplorer.record_name != '')) {

					let method = silicon_ioi.doctype.ioiGlobalExplorer.path_global_explorer_user_list + '.ioi_global_explorer_user_get_summary';

					frappe.call({
						method: method,
						args: {	"name": silicon_ioi.doctype.ioiGlobalExplorer.record_name
						},
						async: false,
						callback:function(r)	{
							silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action = r.message

						}
					});
				}

			}

			let method = silicon_ioi.doctype.ioiGlobalExplorer.path_jit_engine_link + '.ioi_jit_engine_link_document_flow';

			silicon_ioi.doctype.ioiGlobalExplorer.columns = [];


			frappe.call({
				method: method,
				args: {	"from_doctype": silicon_ioi.doctype.ioiGlobalExplorer.from_doctype,
						"from_name": silicon_ioi.doctype.ioiGlobalExplorer.from_name,
						"from_detail_name": silicon_ioi.doctype.ioiGlobalExplorer.from_detail_name,
						"from_detail_idx": silicon_ioi.doctype.ioiGlobalExplorer.from_detail_idx,
						"si": silicon_ioi.doctype.ioiGlobalExplorer.si,
						"sd": silicon_ioi.doctype.ioiGlobalExplorer.sd,
						"so": silicon_ioi.doctype.ioiGlobalExplorer.so,
						"sq": silicon_ioi.doctype.ioiGlobalExplorer.sq,
						"pq": silicon_ioi.doctype.ioiGlobalExplorer.pq,
						"po": silicon_ioi.doctype.ioiGlobalExplorer.po,
						"pr": silicon_ioi.doctype.ioiGlobalExplorer.pr,
						"pi": silicon_ioi.doctype.ioiGlobalExplorer.pi,
						"ppr": silicon_ioi.doctype.ioiGlobalExplorer.ppr,
						"odp": silicon_ioi.doctype.ioiGlobalExplorer.odp,
						"dos": silicon_ioi.doctype.ioiGlobalExplorer.dos,
						"tech_fieldname": silicon_ioi.doctype.ioiGlobalExplorer.tech_fieldname,
						"tab_fields": silicon_ioi.doctype.ioiGlobalExplorer.tab_fields,
						"tab_fieldtypes": silicon_ioi.doctype.ioiGlobalExplorer.tab_fieldtypes,
						"tab_isjumps": silicon_ioi.doctype.ioiGlobalExplorer.tab_isjumps,
						"tab_isformulas": silicon_ioi.doctype.ioiGlobalExplorer.tab_isformulas,
						"tab_formulas_description": silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description,
						"tab_orderby" : silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby,
						"tab_orderby_sens" : silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby_sens,
						"conditions": silicon_ioi.doctype.ioiGlobalExplorer.where,
						"having": silicon_ioi.doctype.ioiGlobalExplorer.having,
						"topn": silicon_ioi.doctype.ioiGlobalExplorer.topn,
						"get_sql": get_script,
						"show_general_summary": silicon_ioi.doctype.ioiGlobalExplorer.summary,
						"tab_summary_fields": silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields,
						"tab_summary_action": silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action

				},
				async: false,
				callback:function(r)	{

					silicon_ioi.doctype.ioiGlobalExplorer.sql_script = '';

					if (get_script == 1) {
						silicon_ioi.doctype.ioiGlobalExplorer.sql_script = r.message.sql_script;
					}else{

						silicon_ioi.doctype.ioiGlobalExplorer.columns = r.message.columns;
						silicon_ioi.doctype.ioiGlobalExplorer.data = r.message.data;

						if (r.message.error != 0) {
							frappe.msgprint({title: __("Message"), message: r.message.error_msg, indicator: "red"});
						}
					}
				}
			});


			if (!get_sql) {

				silicon_ioi.doctype.ioiGlobalExplorer.create_tabulator();

				silicon_ioi.doctype.ioiGlobalExplorer.sleep_static(200).then(() => {

					let cols = [];

					if (silicon_ioi.doctype.ioiGlobalExplorer.columns.length > 0) {

						for (var i = 0; i < silicon_ioi.doctype.ioiGlobalExplorer.columns.length; i++) {

							let col = silicon_ioi.doctype.ioiGlobalExplorer.columns[i].name;
							let tp = '';

							if (silicon_ioi.doctype.ioiGlobalExplorer.columns[i].fieldtype) {
								tp = silicon_ioi.doctype.ioiGlobalExplorer.columns[i].fieldtype.toUpperCase();
							}


							let visible = true;

							if ((col == 'si_ioistatus_description') || (col == 'si_ioistatus_color') || (col == 'si_detail_name') || (col == 'si_detail_ioistatus_description') || (col == 'si_detail_ioistatus_color') ||
								(col == 'sd_ioistatus_description') || (col == 'sd_ioistatus_color') || (col == 'sd_detail_name') || (col == 'sd_detail_ioistatus_description') || (col == 'sd_detail_ioistatus_color') ||
								(col == 'so_ioistatus_description') || (col == 'so_ioistatus_color') || (col == 'so_detail_name') || (col == 'so_detail_ioistatus_description') || (col == 'so_detail_ioistatus_color') ||
								(col == 'sq_ioistatus_description') || (col == 'sq_ioistatus_color') || (col == 'sq_detail_name') || (col == 'sq_detail_ioistatus_description') || (col == 'sq_detail_ioistatus_color') ||
								(col == 'pq_ioistatus_description') || (col == 'pq_ioistatus_color') || (col == 'pq_detail_name') || (col == 'pq_detail_ioistatus_description') || (col == 'pq_detail_ioistatus_color') ||
								(col == 'po_ioistatus_description') || (col == 'po_ioistatus_color') || (col == 'po_detail_name') || (col == 'po_detail_ioistatus_description') || (col == 'po_detail_ioistatus_color') ||
								(col == 'pr_ioistatus_description') || (col == 'pr_ioistatus_color') || (col == 'pr_detail_name') || (col == 'pr_detail_ioistatus_description') || (col == 'pr_detail_ioistatus_color') ||
								(col == 'pi_ioistatus_description') || (col == 'pi_ioistatus_color') || (col == 'pi_detail_name') || (col == 'pi_detail_ioistatus_description') || (col == 'pi_detail_ioistatus_color') ||
								(col == 'odp_ioistatus_description') || (col == 'odp_ioistatus_color') || (col == 'odp_detail_name') || (col == 'odp_detail_ioistatus_description') || (col == 'odp_detail_ioistatus_color') ||
								(col == 'dos_ioistatus_description') || (col == 'dos_ioistatus_color') || (col == 'dos_detail_name') || (col == 'dos_detail_ioistatus_description') || (col == 'dos_detail_ioistatus_color') ||
								(col == 'ppr_ioistatus_description') || (col == 'ppr_ioistatus_color') ) {
								visible = false;
							}

							let column_hozAlign = "left";

							if ((tp == 'FLOAT') || (tp == 'INT') || (tp == 'PERCENT')) {
								column_hozAlign = "right";
							}else if (tp == 'CHECK') {
								column_hozAlign = "center";
							}


							let column_formatter = (cell, formatterParams, onRendered) => {

								if ((cell.getColumn().getField() == 'si_name') || (cell.getColumn().getField() == 'si_detail_parent') ||
									(cell.getColumn().getField() == 'sd_name') || (cell.getColumn().getField() == 'sd_detail_parent') ||
									(cell.getColumn().getField() == 'so_name') || (cell.getColumn().getField() == 'so_detail_parent') ||
									(cell.getColumn().getField() == 'sq_name') || (cell.getColumn().getField() == 'sq_detail_parent') ||
									(cell.getColumn().getField() == 'pi_name') || (cell.getColumn().getField() == 'pi_detail_parent') ||
									(cell.getColumn().getField() == 'pr_name') || (cell.getColumn().getField() == 'pr_detail_parent') ||
									(cell.getColumn().getField() == 'po_name') || (cell.getColumn().getField() == 'po_detail_parent') ||
									(cell.getColumn().getField() == 'pq_name') || (cell.getColumn().getField() == 'pq_detail_parent') ||
									(cell.getColumn().getField() == 'ppr_name') ||
									(cell.getColumn().getField() == 'odp_name') || (cell.getColumn().getField() == 'odp_detail_parent') ||
									(cell.getColumn().getField() == 'dos_name') || (cell.getColumn().getField() == 'dos_detail_parent') ||
									(cell.getColumn().getField() == 'si_detail_idx') || (cell.getColumn().getField() == 'sd_detail_idx') || (cell.getColumn().getField() == 'so_detail_idx') || (cell.getColumn().getField() == 'sq_detail_idx') ||
									(cell.getColumn().getField() == 'pi_detail_idx') || (cell.getColumn().getField() == 'pr_detail_idx') || (cell.getColumn().getField() == 'po_detail_idx') || (cell.getColumn().getField() == 'pq_detail_idx') ||
									(cell.getColumn().getField() == 'odp_detail_idx') || (cell.getColumn().getField() == 'dos_detail_idx') ||
									(cell.getColumn().getField() == 'si_ioicustomer_order_name') || (cell.getColumn().getField() == 'si_ioicustomer_delivery_name') || (cell.getColumn().getField() == 'si_ioicustomer_invoice_name') ||
									(cell.getColumn().getField() == 'sd_ioicustomer_order_name') || (cell.getColumn().getField() == 'sd_ioicustomer_delivery_name') || (cell.getColumn().getField() == 'sd_ioicustomer_invoice_name') ||
									(cell.getColumn().getField() == 'so_ioicustomer_order_name') || (cell.getColumn().getField() == 'so_ioicustomer_delivery_name') || (cell.getColumn().getField() == 'so_ioicustomer_invoice_name') ||
									(cell.getColumn().getField() == 'sq_ioicustomer_order_name') || (cell.getColumn().getField() == 'sq_ioicustomer_delivery_name') || (cell.getColumn().getField() == 'sq_ioicustomer_invoice_name') ||
									(cell.getColumn().getField() == 'dos_ioicustomer_order_name') || (cell.getColumn().getField() == 'dos_ioicustomer_delivery_name') || (cell.getColumn().getField() == 'dos_ioicustomer_invoice_name') ||
									(cell.getColumn().getField() == 'pi_ioisupplier_order_name') || (cell.getColumn().getField() == 'pi_ioisupplier_delivery_name') || (cell.getColumn().getField() == 'pi_ioisupplier_invoice_name') ||
									(cell.getColumn().getField() == 'pr_ioisupplier_order_name') || (cell.getColumn().getField() == 'pr_ioisupplier_delivery_name') || (cell.getColumn().getField() == 'pr_ioisupplier_invoice_name') ||
									(cell.getColumn().getField() == 'po_ioisupplier_order_name') || (cell.getColumn().getField() == 'po_ioisupplier_delivery_name') || (cell.getColumn().getField() == 'po_ioisupplier_invoice_name') ||
									(cell.getColumn().getField() == 'pq_ioisupplier_order_name') || (cell.getColumn().getField() == 'pq_ioisupplier_delivery_name') || (cell.getColumn().getField() == 'pq_ioisupplier_invoice_name') ||
									(cell.getColumn().getField() == 'ppr_ioisupplier_name') ||
									(cell.getColumn().getField() == 'si_detail_ioiitem_name') || (cell.getColumn().getField() == 'sd_detail_ioiitem_name') || (cell.getColumn().getField() == 'so_detail_ioiitem_name') ||
									(cell.getColumn().getField() == 'sq_detail_ioiitem_name') || (cell.getColumn().getField() == 'pi_detail_ioiitem_name') || (cell.getColumn().getField() == 'pr_detail_ioiitem_name') ||
									(cell.getColumn().getField() == 'po_detail_ioiitem_name') || (cell.getColumn().getField() == 'pq_detail_ioiitem_name') || (cell.getColumn().getField() == 'ppr_ioiitem_name') ||
									(cell.getColumn().getField() == 'odp_ioiitem_name') || (cell.getColumn().getField() == 'odp_detail_ioiitem_name') || (cell.getColumn().getField() == 'dos_ioiitem_name') ||
									(cell.getColumn().getField() == 'dos_detail_ioiitem_name') ||
									(cell.getColumn().getField() == 'si_detail_ioimanufacturercatalog_name') || (cell.getColumn().getField() == 'sd_detail_ioimanufacturercatalog_name') || (cell.getColumn().getField() == 'so_detail_ioimanufacturercatalog_name') ||
									(cell.getColumn().getField() == 'sq_detail_ioimanufacturercatalog_name') || (cell.getColumn().getField() == 'pi_detail_ioimanufacturercatalog_name') || (cell.getColumn().getField() == 'pr_detail_ioimanufacturercatalog_name') ||
									(cell.getColumn().getField() == 'po_detail_ioimanufacturercatalog_name') || (cell.getColumn().getField() == 'pq_detail_ioimanufacturercatalog_name') || (cell.getColumn().getField() == 'ppr_ioimanufacturercatalog_name') ||
									(cell.getColumn().getField() == 'dos_detail_ioimanufacturercatalog_name')) {

									if ((cell.getValue() != null) && (cell.getValue() != '') && (cell.getValue() != __("SUMMARY"))) {

										if ((cell.getColumn().getField() == 'si_name') || (cell.getColumn().getField() == 'si_detail_parent') ||
											(cell.getColumn().getField() == 'sd_name') || (cell.getColumn().getField() == 'sd_detail_parent') ||
											(cell.getColumn().getField() == 'so_name') || (cell.getColumn().getField() == 'so_detail_parent') ||
											(cell.getColumn().getField() == 'sq_name') || (cell.getColumn().getField() == 'sq_detail_parent') ||
											(cell.getColumn().getField() == 'pi_name') || (cell.getColumn().getField() == 'pi_detail_parent') ||
											(cell.getColumn().getField() == 'pr_name') || (cell.getColumn().getField() == 'pr_detail_parent') ||
											(cell.getColumn().getField() == 'po_name') || (cell.getColumn().getField() == 'po_detail_parent') ||
											(cell.getColumn().getField() == 'pq_name') || (cell.getColumn().getField() == 'pq_detail_parent') ||
											(cell.getColumn().getField() == 'ppr_name') ||
											(cell.getColumn().getField() == 'odp_name') || (cell.getColumn().getField() == 'odp_detail_parent') ||
											(cell.getColumn().getField() == 'dos_name') || (cell.getColumn().getField() == 'dos_detail_parent') ||
											(cell.getColumn().getField() == 'si_ioicustomer_order_name') || (cell.getColumn().getField() == 'si_ioicustomer_delivery_name') || (cell.getColumn().getField() == 'si_ioicustomer_invoice_name') ||
											(cell.getColumn().getField() == 'sd_ioicustomer_order_name') || (cell.getColumn().getField() == 'sd_ioicustomer_delivery_name') || (cell.getColumn().getField() == 'sd_ioicustomer_invoice_name') ||
											(cell.getColumn().getField() == 'so_ioicustomer_order_name') || (cell.getColumn().getField() == 'so_ioicustomer_delivery_name') || (cell.getColumn().getField() == 'so_ioicustomer_invoice_name') ||
											(cell.getColumn().getField() == 'sq_ioicustomer_order_name') || (cell.getColumn().getField() == 'sq_ioicustomer_delivery_name') || (cell.getColumn().getField() == 'sq_ioicustomer_invoice_name') ||
											(cell.getColumn().getField() == 'dos_ioicustomer_order_name') || (cell.getColumn().getField() == 'dos_ioicustomer_delivery_name') || (cell.getColumn().getField() == 'dos_ioicustomer_invoice_name') ||
											(cell.getColumn().getField() == 'pi_ioisupplier_order_name') || (cell.getColumn().getField() == 'pi_ioisupplier_delivery_name') || (cell.getColumn().getField() == 'pi_ioisupplier_invoice_name') ||
											(cell.getColumn().getField() == 'pr_ioisupplier_order_name') || (cell.getColumn().getField() == 'pr_ioisupplier_delivery_name') || (cell.getColumn().getField() == 'pr_ioisupplier_invoice_name') ||
											(cell.getColumn().getField() == 'po_ioisupplier_order_name') || (cell.getColumn().getField() == 'po_ioisupplier_delivery_name') || (cell.getColumn().getField() == 'po_ioisupplier_invoice_name') ||
											(cell.getColumn().getField() == 'pq_ioisupplier_order_name') || (cell.getColumn().getField() == 'pq_ioisupplier_delivery_name') || (cell.getColumn().getField() == 'pq_ioisupplier_invoice_name') ||
											(cell.getColumn().getField() == 'ppr_ioisupplier_name') ||
											(cell.getColumn().getField() == 'si_detail_ioiitem_name') || (cell.getColumn().getField() == 'sd_detail_ioiitem_name') || (cell.getColumn().getField() == 'so_detail_ioiitem_name') ||
											(cell.getColumn().getField() == 'sq_detail_ioiitem_name') || (cell.getColumn().getField() == 'pi_detail_ioiitem_name') || (cell.getColumn().getField() == 'pr_detail_ioiitem_name') ||
											(cell.getColumn().getField() == 'po_detail_ioiitem_name') || (cell.getColumn().getField() == 'pq_detail_ioiitem_name') || (cell.getColumn().getField() == 'ppr_ioiitem_name') ||
											(cell.getColumn().getField() == 'odp_ioiitem_name') || (cell.getColumn().getField() == 'odp_detail_ioiitem_name') || (cell.getColumn().getField() == 'dos_ioiitem_name') ||
											(cell.getColumn().getField() == 'dos_detail_ioiitem_name') ||
											(cell.getColumn().getField() == 'si_detail_ioimanufacturercatalog_name') || (cell.getColumn().getField() == 'sd_detail_ioimanufacturercatalog_name') || (cell.getColumn().getField() == 'so_detail_ioimanufacturercatalog_name') ||
											(cell.getColumn().getField() == 'sq_detail_ioimanufacturercatalog_name') || (cell.getColumn().getField() == 'pi_detail_ioimanufacturercatalog_name') || (cell.getColumn().getField() == 'pr_detail_ioimanufacturercatalog_name') ||
											(cell.getColumn().getField() == 'po_detail_ioimanufacturercatalog_name') || (cell.getColumn().getField() == 'pq_detail_ioimanufacturercatalog_name') || (cell.getColumn().getField() == 'ppr_ioimanufacturercatalog_name') ||
											(cell.getColumn().getField() == 'dos_detail_ioimanufacturercatalog_name') ) {

											if ((cell.getColumn().getField() == 'si_name') || (cell.getColumn().getField() == 'si_detail_parent')) {
												cell.getElement().style.backgroundColor = cell.getRow().getData().si_ioistatus_color;
											}else if ((cell.getColumn().getField() == 'sd_name') || (cell.getColumn().getField() == 'sd_detail_parent')) {
												cell.getElement().style.backgroundColor = cell.getRow().getData().sd_ioistatus_color;
											}else if ((cell.getColumn().getField() == 'so_name') || (cell.getColumn().getField() == 'so_detail_parent')) {
												cell.getElement().style.backgroundColor = cell.getRow().getData().so_ioistatus_color;
											}else if ((cell.getColumn().getField() == 'sq_name') || (cell.getColumn().getField() == 'sq_detail_parent')) {
												cell.getElement().style.backgroundColor = cell.getRow().getData().sq_ioistatus_color;
											}else if ((cell.getColumn().getField() == 'pi_name') || (cell.getColumn().getField() == 'pi_detail_parent')) {
												cell.getElement().style.backgroundColor = cell.getRow().getData().pi_ioistatus_color;
											}else if ((cell.getColumn().getField() == 'pr_name') || (cell.getColumn().getField() == 'pr_detail_parent')) {
												cell.getElement().style.backgroundColor = cell.getRow().getData().pr_ioistatus_color;
											}else if ((cell.getColumn().getField() == 'po_name') || (cell.getColumn().getField() == 'po_detail_parent')) {
												cell.getElement().style.backgroundColor = cell.getRow().getData().po_ioistatus_color;
											}else if ((cell.getColumn().getField() == 'pq_name') || (cell.getColumn().getField() == 'pq_detail_parent')) {
												cell.getElement().style.backgroundColor = cell.getRow().getData().pq_ioistatus_color;
											}else if (cell.getColumn().getField() == 'ppr_name') {
												cell.getElement().style.backgroundColor = cell.getRow().getData().ppr_ioistatus_color;
											}else if ((cell.getColumn().getField() == 'odp_name') || (cell.getColumn().getField() == 'odp_detail_parent')) {
												cell.getElement().style.backgroundColor = cell.getRow().getData().odp_ioistatus_color;
											}else if ((cell.getColumn().getField() == 'dos_name') || (cell.getColumn().getField() == 'dos_detail_parent')) {
												cell.getElement().style.backgroundColor = cell.getRow().getData().dos_ioistatus_color;
											}

											let s = '<u>' + cell.getValue() + '</u>';

											return s;
										}else{

											if (cell.getColumn().getField() == 'si_detail_idx') {
												cell.getElement().style.backgroundColor = cell.getRow().getData().si_detail_ioistatus_color;
											}else if (cell.getColumn().getField() == 'sd_detail_idx') {
												cell.getElement().style.backgroundColor = cell.getRow().getData().sd_detail_ioistatus_color;
											}else if (cell.getColumn().getField() == 'so_detail_idx') {
												cell.getElement().style.backgroundColor = cell.getRow().getData().so_detail_ioistatus_color;
											}else if (cell.getColumn().getField() == 'sq_detail_idx') {
												cell.getElement().style.backgroundColor = cell.getRow().getData().sq_detail_ioistatus_color;
											}else if (cell.getColumn().getField() == 'pi_detail_idx') {
												cell.getElement().style.backgroundColor = cell.getRow().getData().pi_detail_ioistatus_color;
											}else if (cell.getColumn().getField() == 'pr_detail_idx') {
												cell.getElement().style.backgroundColor = cell.getRow().getData().pr_detail_ioistatus_color;
											}else if (cell.getColumn().getField() == 'po_detail_idx') {
												cell.getElement().style.backgroundColor = cell.getRow().getData().po_detail_ioistatus_color;
											}else if (cell.getColumn().getField() == 'pq_detail_idx') {
												cell.getElement().style.backgroundColor = cell.getRow().getData().pq_detail_ioistatus_color;
											}else if (cell.getColumn().getField() == 'odp_detail_idx') {
												cell.getElement().style.backgroundColor = cell.getRow().getData().odp_detail_ioistatus_color;
											}else if (cell.getColumn().getField() == 'dos_detail_idx') {
												cell.getElement().style.backgroundColor = cell.getRow().getData().dos_detail_ioistatus_color;
											}

											return cell.getValue()
										}
									}else{
										return cell.getValue()
									}

								}else{

									if ((cell.getValue() != null) && (cell.getValue() != '') && cell.getValue() != __("SUMMARY")) {

										let s = '';

										if ((cell.getColumn().getField().substring(0, 4) != 'exp_') && (cell.getColumn().getField().substring(0, 4) != 'sub_')) {

											let found = false;

											for (var i = 0; i < silicon_ioi.doctype.ioiGlobalExplorer.columns.length; i++) {

												if (silicon_ioi.doctype.ioiGlobalExplorer.columns[i].name == cell.getColumn().getField()) {

													if (silicon_ioi.doctype.ioiGlobalExplorer.columns[i].url != '') {
														found = true;
														s = '<u>' + cell.getValue() + '</u>';
														break;
													}
												}
											}

											if (!found) {
												s = cell.getValue();
											}
										}else{
											s = cell.getValue();
										}

										return s;
									}else{
										return cell.getValue();
									}
								}
							}

							let cell_onclick = (e, cell) => {

								if ((cell.getValue() != null) && (cell.getValue() != '') && (cell.getValue() != __("SUMMARY"))) {

									if ((cell.getColumn().getField() == 'si_name') || (cell.getColumn().getField() == 'si_detail_parent') ||
										(cell.getColumn().getField() == 'sd_name') || (cell.getColumn().getField() == 'sd_detail_parent') ||
										(cell.getColumn().getField() == 'so_name') || (cell.getColumn().getField() == 'so_detail_parent') ||
										(cell.getColumn().getField() == 'sq_name') || (cell.getColumn().getField() == 'sq_detail_parent') ||
										(cell.getColumn().getField() == 'pi_name') || (cell.getColumn().getField() == 'pi_detail_parent') ||
										(cell.getColumn().getField() == 'pr_name') || (cell.getColumn().getField() == 'pr_detail_parent') ||
										(cell.getColumn().getField() == 'po_name') || (cell.getColumn().getField() == 'po_detail_parent') ||
										(cell.getColumn().getField() == 'pq_name') || (cell.getColumn().getField() == 'pq_detail_parent') ||
										(cell.getColumn().getField() == 'ppr_name') ||
										(cell.getColumn().getField() == 'odp_name') || (cell.getColumn().getField() == 'odp_detail_parent') ||
										(cell.getColumn().getField() == 'dos_name') || (cell.getColumn().getField() == 'dos_detail_parent')) {


										if ((document.getElementById('ioi_global_explorer_drill_through').checked) && (cell.getColumn().getField() != 'ppr_name')) {

											let fn = cell.getColumn().getField().toUpperCase()
											let suffix = fn;
											fn = fn.substring(0, fn.indexOf("_"));
											suffix = suffix.substring(fn.indexOf("_")+1, suffix.length);
											suffix = suffix.substring(0, fn.indexOf("_"));

											let dc = '';

											if (fn == 'SQ') {
												dc = 'ioi Sales Quote';
											}else if (fn == 'SO') {
												dc = 'ioi Sales Order';
											}else if (fn == 'SD') {
												dc = 'ioi Sales Delivery';
											}else if (fn == 'SI') {
												dc = 'ioi Sales Invoice';
											}else if (fn == 'PQ') {
												dc = 'ioi Purchases Price Request';
											}else if (fn == 'PO') {
												dc = 'ioi Purchases Order';
											}else if (fn == 'PR') {
												dc = 'ioi Purchases Receipt';
											}else if (fn == 'PI') {
												dc = 'ioi Purchases Invoice';
											}else if (fn == 'ODP') {
												if (suffix == 'DETAIL') {
													dc = 'ioi Production BOM';
												}else{
													dc = 'ioi Production';
												}
											}else if (fn == 'DOS') {
												if (suffix == 'DETAIL') {
													dc = 'ioi Dossier BOM';
												}else{
													dc = 'ioi Dossier';
												}

											}

											let name = cell.getValue();

											let record_name = '';

											if (silicon_ioi.doctype.ioiGlobalExplorer.record_name != '') {
												record_name = silicon_ioi.doctype.ioiGlobalExplorer.record_name;
											}

											let from_dc = '';

											if (document.getElementById('ioi_global_explorer_from_doctype').value != '') {
												from_dc = document.getElementById('ioi_global_explorer_from_doctype').value;
											}

											let from_name = '';

											if (document.getElementById('ioi_global_explorer_from_name').value != '') {
												from_name = document.getElementById('ioi_global_explorer_from_name').value;
											}

											let from_idx = '';

											if (document.getElementById('ioi_global_explorer_from_idx').value != '') {
												from_name = document.getElementById('ioi_global_explorer_from_idx').value;
											}

											let topn = '';

											if (document.getElementById('ioi_global_explorer_topn').value != '') {
												topn = document.getElementById('ioi_global_explorer_topn').value;
											}

											let do_back = true;

											if (silicon_ioi.doctype.ioiGlobalExplorer.back_list.length > 0) {

												if ((from_dc == dc) && (from_name == cell.getValue())) {
													do_back = false;
												}
											}


											if (do_back) {

												silicon_ioi.doctype.ioiGlobalExplorer.back_list[silicon_ioi.doctype.ioiGlobalExplorer.back_list.length] = [record_name, from_dc, from_name, from_idx, topn];

												if (document.getElementById('ioi_global_explorer_drill_back_label').style.display == 'none') {
													document.getElementById('ioi_global_explorer_drill_back_label').style.display = 'block';
												}





												let method = silicon_ioi.doctype.ioiGlobalExplorer.path_global_explorer_user_list + '.ioi_global_explorer_user_get_default_list'

												let rec_name = '';

												frappe.call({
													method: method,
													args: {	"from_doctype": dc
													},
													async: false,
													callback:function(r)	{
														rec_name = r.message;
													}
												});

												if (rec_name != '') {

													let method = silicon_ioi.doctype.ioiGlobalExplorer.path_global_explorer_user_list + '.ioi_global_explorer_user_list_get'

													let rec = {};

													frappe.call({
														method: method,
														args: {	"name": rec_name
														},
														async: false,
														callback:function(r)	{
															rec = r.message;
														}
													});

													silicon_ioi.doctype.ioiGlobalExplorer.clear_screen();


													silicon_ioi.doctype.ioiGlobalExplorer.record_name = rec.name;
													silicon_ioi.doctype.ioiGlobalExplorer.list_name = rec.list_name;
													silicon_ioi.doctype.ioiGlobalExplorer.from_doctype = rec.from_doctype;
													silicon_ioi.doctype.ioiGlobalExplorer.from_name = rec.from_name;
													silicon_ioi.doctype.ioiGlobalExplorer.from_detail_idx = rec.from_idx;
													silicon_ioi.doctype.ioiGlobalExplorer.from_detail_name = '';
													silicon_ioi.doctype.ioiGlobalExplorer.topn = rec.topn;
													silicon_ioi.doctype.ioiGlobalExplorer.where = rec.where_clause;
													silicon_ioi.doctype.ioiGlobalExplorer.having = rec.having_clause;
													silicon_ioi.doctype.ioiGlobalExplorer.sq = rec.sq;
													silicon_ioi.doctype.ioiGlobalExplorer.so = rec.so;
													silicon_ioi.doctype.ioiGlobalExplorer.sd = rec.sd;
													silicon_ioi.doctype.ioiGlobalExplorer.si = rec.si;
													silicon_ioi.doctype.ioiGlobalExplorer.pq = rec.pq;
													silicon_ioi.doctype.ioiGlobalExplorer.po = rec.po;
													silicon_ioi.doctype.ioiGlobalExplorer.pr = rec.pr;
													silicon_ioi.doctype.ioiGlobalExplorer.pi = rec.pi;
													silicon_ioi.doctype.ioiGlobalExplorer.ppr = rec.ppr;
													silicon_ioi.doctype.ioiGlobalExplorer.odp = rec.odp;
													silicon_ioi.doctype.ioiGlobalExplorer.dos = rec.dos;
													silicon_ioi.doctype.ioiGlobalExplorer.tech_fieldname = rec.tech_fieldname;
													silicon_ioi.doctype.ioiGlobalExplorer.default = rec.is_default;
													silicon_ioi.doctype.ioiGlobalExplorer.tab_fields = rec.tab_fields;
													silicon_ioi.doctype.ioiGlobalExplorer.tab_isjumps = rec.tab_isjumps;
													silicon_ioi.doctype.ioiGlobalExplorer.tab_isformulas = rec.tab_isformulas;
													silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description = rec.tab_formulas_descripion;
													silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby = rec.tab_orderby;
													silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby_sens = rec.tab_orderby_sens;

													silicon_ioi.doctype.ioiGlobalExplorer.summary = rec.summary;
													silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields = rec.tab_summary_fields;
													silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action = rec.tab_summary_action;

													document.getElementById('ioi_global_explorer_from_doctype').value = silicon_ioi.doctype.ioiGlobalExplorer.from_doctype;
													document.getElementById('ioi_global_explorer_from_name').value = silicon_ioi.doctype.ioiGlobalExplorer.from_name;
													document.getElementById('ioi_global_explorer_from_idx').value = silicon_ioi.doctype.ioiGlobalExplorer.from_detail_idx;
													document.getElementById('ioi_global_explorer_topn').value = silicon_ioi.doctype.ioiGlobalExplorer.topn;
													document.getElementById('ioi_global_explorer_where').value = silicon_ioi.doctype.ioiGlobalExplorer.where;

													document.getElementById('ioi_global_explorer_sq').checked = false;

													if (silicon_ioi.doctype.ioiGlobalExplorer.sq == 1) {
														document.getElementById('ioi_global_explorer_sq').checked = true;
													}

													document.getElementById('ioi_global_explorer_so').checked = false;

													if (silicon_ioi.doctype.ioiGlobalExplorer.so == 1) {
														document.getElementById('ioi_global_explorer_so').checked = true;
													}

													document.getElementById('ioi_global_explorer_sd').checked = false;

													if (silicon_ioi.doctype.ioiGlobalExplorer.sd == 1) {
														document.getElementById('ioi_global_explorer_sd').checked = true;
													}

													document.getElementById('ioi_global_explorer_si').checked = false;

													if (silicon_ioi.doctype.ioiGlobalExplorer.si == 1) {
														document.getElementById('ioi_global_explorer_si').checked = true;
													}

													document.getElementById('ioi_global_explorer_pq').checked = false;

													if (silicon_ioi.doctype.ioiGlobalExplorer.pq == 1) {
														document.getElementById('ioi_global_explorer_pq').checked = true;
													}

													document.getElementById('ioi_global_explorer_po').checked = false;

													if (silicon_ioi.doctype.ioiGlobalExplorer.po == 1) {
														document.getElementById('ioi_global_explorer_po').checked = true;
													}

													document.getElementById('ioi_global_explorer_pr').checked = false;

													if (silicon_ioi.doctype.ioiGlobalExplorer.pr == 1) {
														document.getElementById('ioi_global_explorer_pr').checked = true;
													}

													document.getElementById('ioi_global_explorer_pi').checked = false;

													if (silicon_ioi.doctype.ioiGlobalExplorer.pi == 1) {
														document.getElementById('ioi_global_explorer_pi').checked = true;
													}

													document.getElementById('ioi_global_explorer_ppr').checked = false;

													if (silicon_ioi.doctype.ioiGlobalExplorer.ppr == 1) {
														document.getElementById('ioi_global_explorer_ppr').checked = true;
													}

													document.getElementById('ioi_global_explorer_odp').checked = false;

													if (silicon_ioi.doctype.ioiGlobalExplorer.odp == 1) {
														document.getElementById('ioi_global_explorer_odp').checked = true;
													}


													document.getElementById('ioi_global_explorer_dos').checked = false;

													if (silicon_ioi.doctype.ioiGlobalExplorer.dos == 1) {
														document.getElementById('ioi_global_explorer_dos').checked = true;
													}

													document.getElementById('ioi_global_explorer_columns_with_fieldname').checked = false;

													if (silicon_ioi.doctype.ioiGlobalExplorer.tech_fieldname == 1) {
														document.getElementById('ioi_global_explorer_columns_with_fieldname').checked = true;
													}

													document.getElementById('ioi_global_explorer_columns_with_fieldname').checked = false;

													if (silicon_ioi.doctype.ioiGlobalExplorer.default == 1) {
														document.getElementById('ioi_global_explorer_default').checked = true;
													}

													silicon_ioi.doctype.ioiGlobalExplorer.from_doctype = dc;
													silicon_ioi.doctype.ioiGlobalExplorer.from_name = name;
													silicon_ioi.doctype.ioiGlobalExplorer.from_detail_idx = '';
													silicon_ioi.doctype.ioiGlobalExplorer.from_detail_name = '';
													silicon_ioi.doctype.ioiGlobalExplorer.topn = 0;


												}

												document.getElementById('ioi_global_explorer_from_doctype').value = dc;
												document.getElementById('ioi_global_explorer_from_name').value = name;
												document.getElementById('ioi_global_explorer_from_idx').value = '';
												document.getElementById('ioi_global_explorer_topn').value = 0;

												if (fn == 'SQ') {
													document.getElementById('ioi_global_explorer_sq').checked = true;
												}else if (fn == 'SO') {
													document.getElementById('ioi_global_explorer_so').checked = true;
												}else if (fn == 'SD') {
													document.getElementById('ioi_global_explorer_sd').checked = true;
												}else if (fn == 'SI') {
													document.getElementById('ioi_global_explorer_si').checked = true;
												}else if (fn == 'PQ') {
													document.getElementById('ioi_global_explorer_pq').checked = true;
												}else if (fn == 'PO') {
													document.getElementById('ioi_global_explorer_po').checked = true;
												}else if (fn == 'PR') {
													document.getElementById('ioi_global_explorer_pr').checked = true;
												}else if (fn == 'PI') {
													document.getElementById('ioi_global_explorer_pi').checked = true;
												}else if (fn == 'ODP') {
													document.getElementById('ioi_global_explorer_odp').checked = true;
												}else if (fn == 'DOS') {
													document.getElementById('ioi_global_explorer_dos').checked = true;
												}

												silicon_ioi.doctype.ioiGlobalExplorer.refresh(false, true)
											}


										}else{

											let url = '';

											if ((cell.getColumn().getField() == 'si_name') || (cell.getColumn().getField() == 'si_detail_parent')) {
												url = '/app/ioi-sales-invoice/';
											}else if ((cell.getColumn().getField() == 'sd_name') || (cell.getColumn().getField() == 'sd_detail_parent')) {
												url = '/app/ioi-sales-delivery/';
											}else if ((cell.getColumn().getField() == 'so_name') || (cell.getColumn().getField() == 'so_detail_parent')) {
												url = '/app/ioi-sales-order/';
											}else if ((cell.getColumn().getField() == 'sq_name') || (cell.getColumn().getField() == 'sq_detail_parent')) {
												url = '/app/ioi-sales-quote/';
											}else if ((cell.getColumn().getField() == 'pi_name') || (cell.getColumn().getField() == 'pi_detail_parent')) {
												url = '/app/ioi-purchases-invoice/';
											}else if ((cell.getColumn().getField() == 'pr_name') || (cell.getColumn().getField() == 'pr_detail_parent')) {
												url = '/app/ioi-purchases-receipt/';
											}else if ((cell.getColumn().getField() == 'po_name') || (cell.getColumn().getField() == 'po_detail_parent')) {
												url = '/app/ioi-purchases-order/';
											}else if ((cell.getColumn().getField() == 'pq_name') || (cell.getColumn().getField() == 'pq_detail_parent')) {
												url = '/app/ioi-purchases-price-request/';
											}else if (cell.getColumn().getField() == 'ppr_name') {
												url = '/app/ioi-purchases-proposal/';
											}else if ((cell.getColumn().getField() == 'odp_name') || (cell.getColumn().getField() == 'odp_detail_parent')) {
												url = '/app/ioi-production/';
											}else if ((cell.getColumn().getField() == 'dos_name') || (cell.getColumn().getField() == 'dos_detail_parent')) {
												url = '/app/ioi-dossier/';
											}

											url += cell.getValue();
											window.open(url, '_blank');
										}
									}else{

										if ((cell.getColumn().getField() == 'si_ioicustomer_order_name') || (cell.getColumn().getField() == 'si_ioicustomer_delivery_name') || (cell.getColumn().getField() == 'si_ioicustomer_invoice_name') ||
											(cell.getColumn().getField() == 'sd_ioicustomer_order_name') || (cell.getColumn().getField() == 'sd_ioicustomer_delivery_name') || (cell.getColumn().getField() == 'sd_ioicustomer_invoice_name') ||
											(cell.getColumn().getField() == 'so_ioicustomer_order_name') || (cell.getColumn().getField() == 'so_ioicustomer_delivery_name') || (cell.getColumn().getField() == 'so_ioicustomer_invoice_name') ||
											(cell.getColumn().getField() == 'sq_ioicustomer_order_name') || (cell.getColumn().getField() == 'sq_ioicustomer_delivery_name') || (cell.getColumn().getField() == 'sq_ioicustomer_invoice_name') ||
											(cell.getColumn().getField() == 'dos_ioicustomer_order_name') || (cell.getColumn().getField() == 'dos_ioicustomer_delivery_name') || (cell.getColumn().getField() == 'dos_ioicustomer_invoice_name') ||
											(cell.getColumn().getField() == 'pi_ioisupplier_order_name') || (cell.getColumn().getField() == 'pi_ioisupplier_delivery_name') || (cell.getColumn().getField() == 'pi_ioisupplier_invoice_name') ||
											(cell.getColumn().getField() == 'pr_ioisupplier_order_name') || (cell.getColumn().getField() == 'pr_ioisupplier_delivery_name') || (cell.getColumn().getField() == 'pr_ioisupplier_invoice_name') ||
											(cell.getColumn().getField() == 'po_ioisupplier_order_name') || (cell.getColumn().getField() == 'po_ioisupplier_delivery_name') || (cell.getColumn().getField() == 'po_ioisupplier_invoice_name') ||
											(cell.getColumn().getField() == 'pq_ioisupplier_order_name') || (cell.getColumn().getField() == 'pq_ioisupplier_delivery_name') || (cell.getColumn().getField() == 'pq_ioisupplier_invoice_name') ||
											(cell.getColumn().getField() == 'ppr_ioisupplier_name') ||
											(cell.getColumn().getField() == 'si_detail_ioiitem_name') || (cell.getColumn().getField() == 'sd_detail_ioiitem_name') || (cell.getColumn().getField() == 'so_detail_ioiitem_name') ||
											(cell.getColumn().getField() == 'sq_detail_ioiitem_name') || (cell.getColumn().getField() == 'pi_detail_ioiitem_name') || (cell.getColumn().getField() == 'pr_detail_ioiitem_name') ||
											(cell.getColumn().getField() == 'po_detail_ioiitem_name') || (cell.getColumn().getField() == 'pq_detail_ioiitem_name') || (cell.getColumn().getField() == 'ppr_ioiitem_name') ||
											(cell.getColumn().getField() == 'odp_ioiitem_name') || (cell.getColumn().getField() == 'odp_detail_ioiitem_name') || (cell.getColumn().getField() == 'dos_ioiitem_name') ||
											(cell.getColumn().getField() == 'dos_detail_ioiitem_name') ||
											(cell.getColumn().getField() == 'si_detail_ioimanufacturercatalog_name') || (cell.getColumn().getField() == 'sd_detail_ioimanufacturercatalog_name') || (cell.getColumn().getField() == 'so_detail_ioimanufacturercatalog_name') ||
											(cell.getColumn().getField() == 'sq_detail_ioimanufacturercatalog_name') || (cell.getColumn().getField() == 'pi_detail_ioimanufacturercatalog_name') || (cell.getColumn().getField() == 'pr_detail_ioimanufacturercatalog_name') ||
											(cell.getColumn().getField() == 'po_detail_ioimanufacturercatalog_name') || (cell.getColumn().getField() == 'pq_detail_ioimanufacturercatalog_name') || (cell.getColumn().getField() == 'ppr_ioimanufacturercatalog_name') ||
											(cell.getColumn().getField() == 'dos_detail_ioimanufacturercatalog_name'))  {

											let url = '';

											if ((cell.getColumn().getField() == 'si_ioicustomer_order_name') || (cell.getColumn().getField() == 'si_ioicustomer_delivery_name') || (cell.getColumn().getField() == 'si_ioicustomer_invoice_name') ||
												(cell.getColumn().getField() == 'sd_ioicustomer_order_name') || (cell.getColumn().getField() == 'sd_ioicustomer_delivery_name') || (cell.getColumn().getField() == 'sd_ioicustomer_invoice_name') ||
												(cell.getColumn().getField() == 'so_ioicustomer_order_name') || (cell.getColumn().getField() == 'so_ioicustomer_delivery_name') || (cell.getColumn().getField() == 'so_ioicustomer_invoice_name') ||
												(cell.getColumn().getField() == 'sq_ioicustomer_order_name') || (cell.getColumn().getField() == 'sq_ioicustomer_delivery_name') || (cell.getColumn().getField() == 'sq_ioicustomer_invoice_name') ||
												(cell.getColumn().getField() == 'dos_ioicustomer_order_name') || (cell.getColumn().getField() == 'dos_ioicustomer_delivery_name') || (cell.getColumn().getField() == 'dos_ioicustomer_invoice_name')) {
												url = '/app/ioi-customer/';
											}else if ((cell.getColumn().getField() == 'pi_ioisupplier_order_name') || (cell.getColumn().getField() == 'pi_ioisupplier_delivery_name') || (cell.getColumn().getField() == 'pi_ioisupplier_invoice_name') ||
													(cell.getColumn().getField() == 'pr_ioisupplier_order_name') || (cell.getColumn().getField() == 'pr_ioisupplier_delivery_name') || (cell.getColumn().getField() == 'pr_ioisupplier_invoice_name') ||
													(cell.getColumn().getField() == 'po_ioisupplier_order_name') || (cell.getColumn().getField() == 'po_ioisupplier_delivery_name') || (cell.getColumn().getField() == 'po_ioisupplier_invoice_name') ||
													(cell.getColumn().getField() == 'pq_ioisupplier_order_name') || (cell.getColumn().getField() == 'pq_ioisupplier_delivery_name') || (cell.getColumn().getField() == 'pq_ioisupplier_invoice_name') ||
													(cell.getColumn().getField() == 'ppr_ioisupplier_name')) {
												url = '/app/ioi-supplier/';
											}else if ((cell.getColumn().getField() == 'si_detail_ioiitem_name') || (cell.getColumn().getField() == 'sd_detail_ioiitem_name') || (cell.getColumn().getField() == 'so_detail_ioiitem_name') ||
													(cell.getColumn().getField() == 'sq_detail_ioiitem_name') || (cell.getColumn().getField() == 'pi_detail_ioiitem_name') || (cell.getColumn().getField() == 'pr_detail_ioiitem_name') ||
													(cell.getColumn().getField() == 'po_detail_ioiitem_name') || (cell.getColumn().getField() == 'pq_detail_ioiitem_name') || (cell.getColumn().getField() == 'ppr_ioiitem_name') ||
													(cell.getColumn().getField() == 'odp_ioiitem_name') || (cell.getColumn().getField() == 'odp_detail_ioiitem_name') || (cell.getColumn().getField() == 'dos_ioiitem_name') ||
													(cell.getColumn().getField() == 'dos_detail_ioiitem_name')) {
												url = '/app/ioi-item/';
											}else if ((cell.getColumn().getField() == 'si_detail_ioimanufacturercatalog_name') || (cell.getColumn().getField() == 'sd_detail_ioimanufacturercatalog_name') || (cell.getColumn().getField() == 'so_detail_ioimanufacturercatalog_name') ||
													(cell.getColumn().getField() == 'sq_detail_ioimanufacturercatalog_name') || (cell.getColumn().getField() == 'pi_detail_ioimanufacturercatalog_name') || (cell.getColumn().getField() == 'pr_detail_ioimanufacturercatalog_name') ||
													(cell.getColumn().getField() == 'po_detail_ioimanufacturercatalog_name') || (cell.getColumn().getField() == 'pq_detail_ioimanufacturercatalog_name') || (cell.getColumn().getField() == 'ppr_ioimanufacturercatalog_name') ||
													(cell.getColumn().getField() == 'dos_detail_ioimanufacturercatalog_name')) {
												url = '/app/ioi-manufacturer-catalog/';
											}

											url += cell.getValue();

											window.open(url, '_blank');

										}else{

											if ((cell.getColumn().getField().substring(0, 4) != 'exp_') && (cell.getColumn().getField().substring(0, 4) != 'sub_')) {

												for (var i = 0; i < silicon_ioi.doctype.ioiGlobalExplorer.columns.length; i++) {

													if (silicon_ioi.doctype.ioiGlobalExplorer.columns[i].name == cell.getColumn().getField()) {

														if (silicon_ioi.doctype.ioiGlobalExplorer.columns[i].url != '') {

															let url = silicon_ioi.doctype.ioiGlobalExplorer.columns[i].url + cell.getValue();
															window.open(url, '_blank');
															break;
														}
													}
												}
											}
										}
									}
								}
							}


							cols[cols.length] = { 	title: silicon_ioi.doctype.ioiGlobalExplorer.columns[i].description_pref,
								field: silicon_ioi.doctype.ioiGlobalExplorer.columns[i].name,
								visible: visible,
								hozAlign: column_hozAlign,
								formatter: column_formatter,
								cellClick: cell_onclick
							}
						}
					}


					silicon_ioi.doctype.ioiGlobalExplorer.ioiTable = new ioi.Tabulator('#ioi_global_explorer_table_container', {
						columns : cols,
						data: silicon_ioi.doctype.ioiGlobalExplorer.data,
						maxHeight: 600,
						rowHeight: null,
						selectableRows: 1,
						showProfiles: false,
						movableColumns: true,
						resizableColumns: true,
						autoRedraw: false,
					});

		//			silicon_ioi.doctype.ioiGlobalExplorer.ioiTable.addColumn(cols);
/*
						{ 	title: silicon_ioi.doctype.ioiGlobalExplorer.columns[i].description_pref,
							field: silicon_ioi.doctype.ioiGlobalExplorer.columns[i].name,
							visible: visible,
							hozAlign: column_hozAlign,
							//formatter: column_formatter,
							//cellClick: cell_onclick
						}
							*/
					//);

					//silicon_ioi.doctype.ioiGlobalExplorer.ioiTable.setData(silicon_ioi.doctype.ioiGlobalExplorer.data);

				});
			}
		}

	}

	static assign_screen_element_to_properties()
	{
		silicon_ioi.doctype.ioiGlobalExplorer.from_doctype = document.getElementById('ioi_global_explorer_from_doctype').value;

		silicon_ioi.doctype.ioiGlobalExplorer.from_name = '';
		silicon_ioi.doctype.ioiGlobalExplorer.from_idx = 0;

		if (silicon_ioi.doctype.ioiGlobalExplorer.is_global_explorer == 1) {
			silicon_ioi.doctype.ioiGlobalExplorer.from_name = document.getElementById('ioi_global_explorer_from_name').value;
			silicon_ioi.doctype.ioiGlobalExplorer.from_idx = document.getElementById('ioi_global_explorer_from_idx').value;
		}

		silicon_ioi.doctype.ioiGlobalExplorer.topn = document.getElementById('ioi_global_explorer_topn').value;
		silicon_ioi.doctype.ioiGlobalExplorer.where = document.getElementById('ioi_global_explorer_where').value;

		silicon_ioi.doctype.ioiGlobalExplorer.sq = 0;
		silicon_ioi.doctype.ioiGlobalExplorer.so = 0;
		silicon_ioi.doctype.ioiGlobalExplorer.sd = 0;
		silicon_ioi.doctype.ioiGlobalExplorer.si = 0;
		silicon_ioi.doctype.ioiGlobalExplorer.pq = 0;
		silicon_ioi.doctype.ioiGlobalExplorer.po = 0;
		silicon_ioi.doctype.ioiGlobalExplorer.pr = 0;
		silicon_ioi.doctype.ioiGlobalExplorer.pi = 0;
		silicon_ioi.doctype.ioiGlobalExplorer.ppr = 0;
		silicon_ioi.doctype.ioiGlobalExplorer.odp = 0;
		silicon_ioi.doctype.ioiGlobalExplorer.dos = 0;
		silicon_ioi.doctype.ioiGlobalExplorer.tech_fieldname = 0;
		silicon_ioi.doctype.ioiGlobalExplorer.default = 0;


		if (document.getElementById('ioi_global_explorer_sq').checked) {
			silicon_ioi.doctype.ioiGlobalExplorer.sq = 1;
		}

		if (document.getElementById('ioi_global_explorer_so').checked) {
			silicon_ioi.doctype.ioiGlobalExplorer.so = 1;
		}

		if (document.getElementById('ioi_global_explorer_sd').checked) {
			silicon_ioi.doctype.ioiGlobalExplorer.sd = 1;
		}

		if (document.getElementById('ioi_global_explorer_si').checked) {
			silicon_ioi.doctype.ioiGlobalExplorer.si = 1;
		}

		if (document.getElementById('ioi_global_explorer_pq').checked) {
			silicon_ioi.doctype.ioiGlobalExplorer.pq = 1;
		}

		if (document.getElementById('ioi_global_explorer_po').checked) {
			silicon_ioi.doctype.ioiGlobalExplorer.po = 1;
		}

		if (document.getElementById('ioi_global_explorer_pr').checked) {
			silicon_ioi.doctype.ioiGlobalExplorer.pr = 1;
		}

		if (document.getElementById('ioi_global_explorer_pi').checked) {
			silicon_ioi.doctype.ioiGlobalExplorer.pi = 1;
		}

		if (document.getElementById('ioi_global_explorer_ppr').checked) {
			silicon_ioi.doctype.ioiGlobalExplorer.ppr = 1;
		}

		if (document.getElementById('ioi_global_explorer_odp').checked) {
			silicon_ioi.doctype.ioiGlobalExplorer.odp = 1;
		}



		if (document.getElementById('ioi_global_explorer_dos').checked) {
			silicon_ioi.doctype.ioiGlobalExplorer.dos = 1;
		}

		if (document.getElementById('ioi_global_explorer_columns_with_fieldname').checked) {
			silicon_ioi.doctype.ioiGlobalExplorer.tech_fieldname = 1;
		}

		if (document.getElementById('ioi_global_explorer_default').checked) {
			silicon_ioi.doctype.ioiGlobalExplorer.default = 1;
		}

	}

	static save_current_list()
	{

		if ((document.getElementById('ioi_global_explorer_drill_back_label').style.display) && (document.getElementById('ioi_global_explorer_drill_next_label').style.display)) {

			if ((document.getElementById('ioi_global_explorer_drill_back_label').style.display == 'block') ||
				(document.getElementById('ioi_global_explorer_drill_next_label').style.display == 'block')) {

				return false;
			}
		}


		let is_live = 0;

		if (silicon_ioi.doctype.ioiGlobalExplorer.is_global_explorer == 0) {
			is_live = 1;
		}


		silicon_ioi.doctype.ioiGlobalExplorer.assign_screen_element_to_properties();

		if (silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description) {
			if (silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description.length > 0) {

				let method = silicon_ioi.doctype.ioiGlobalExplorer.path_global_explorer_user_list + '.ioi_global_explorer_user_list_insert_update'

				frappe.call({
					method: method,
					args: {	"name": silicon_ioi.doctype.ioiGlobalExplorer.record_name,
							"from_doctype": silicon_ioi.doctype.ioiGlobalExplorer.from_doctype,
							"is_live": is_live,
							"list_name": silicon_ioi.doctype.ioiGlobalExplorer.list_name,
							"is_default": silicon_ioi.doctype.ioiGlobalExplorer.default,
							"from_idx": silicon_ioi.doctype.ioiGlobalExplorer.from_idx,
							"topn": silicon_ioi.doctype.ioiGlobalExplorer.topn,
							"sq": silicon_ioi.doctype.ioiGlobalExplorer.sq,
							"so": silicon_ioi.doctype.ioiGlobalExplorer.so,
							"sd": silicon_ioi.doctype.ioiGlobalExplorer.sd,
							"si": silicon_ioi.doctype.ioiGlobalExplorer.si,
							"pq": silicon_ioi.doctype.ioiGlobalExplorer.pq,
							"po": silicon_ioi.doctype.ioiGlobalExplorer.po,
							"pr": silicon_ioi.doctype.ioiGlobalExplorer.pr,
							"pi": silicon_ioi.doctype.ioiGlobalExplorer.pi,
							"ppr": silicon_ioi.doctype.ioiGlobalExplorer.ppr,
							"odp": silicon_ioi.doctype.ioiGlobalExplorer.odp,
							"dos": silicon_ioi.doctype.ioiGlobalExplorer.dos,
							"tech_fieldname": silicon_ioi.doctype.ioiGlobalExplorer.tech_fieldname,
							"where_clause": silicon_ioi.doctype.ioiGlobalExplorer.where,
							"having_clause": silicon_ioi.doctype.ioiGlobalExplorer.having,
							"tab_fields": silicon_ioi.doctype.ioiGlobalExplorer.tab_fields,
							"tab_isjumps": silicon_ioi.doctype.ioiGlobalExplorer.tab_isjumps,
							"tab_isformulas": silicon_ioi.doctype.ioiGlobalExplorer.tab_isformulas,
							"tab_formulas_description": silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description,
							"tab_orderby": silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby,
							"tab_orderby_sens": silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby_sens,
							"summary": silicon_ioi.doctype.ioiGlobalExplorer.summary,
							"tab_summary_fields": silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields,
							"tab_summary_action": silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action


					},
					async: false,
					callback:function(r)	{
					}
				});
			}else{

				let method = silicon_ioi.doctype.ioiGlobalExplorer.path_global_explorer_user_list + '.ioi_global_explorer_user_list_update'

				frappe.call({
					method: method,
					args: {	"name": silicon_ioi.doctype.ioiGlobalExplorer.record_name,
							"from_doctype": silicon_ioi.doctype.ioiGlobalExplorer.from_doctype,
							"is_live": is_live,
							"list_name": silicon_ioi.doctype.ioiGlobalExplorer.list_name,
							"is_default": silicon_ioi.doctype.ioiGlobalExplorer.default,
							"from_idx": silicon_ioi.doctype.ioiGlobalExplorer.from_idx,
							"topn": silicon_ioi.doctype.ioiGlobalExplorer.topn,
							"sq": silicon_ioi.doctype.ioiGlobalExplorer.sq,
							"so": silicon_ioi.doctype.ioiGlobalExplorer.so,
							"sd": silicon_ioi.doctype.ioiGlobalExplorer.sd,
							"si": silicon_ioi.doctype.ioiGlobalExplorer.si,
							"pq": silicon_ioi.doctype.ioiGlobalExplorer.pq,
							"po": silicon_ioi.doctype.ioiGlobalExplorer.po,
							"pr": silicon_ioi.doctype.ioiGlobalExplorer.pr,
							"pi": silicon_ioi.doctype.ioiGlobalExplorer.pi,
							"ppr": silicon_ioi.doctype.ioiGlobalExplorer.ppr,
							"odp": silicon_ioi.doctype.ioiGlobalExplorer.odp,
							"dos": silicon_ioi.doctype.ioiGlobalExplorer.dos,
							"tech_fieldname": silicon_ioi.doctype.ioiGlobalExplorer.tech_fieldname,
							"where_clause": silicon_ioi.doctype.ioiGlobalExplorer.where,
							"having_clause": silicon_ioi.doctype.ioiGlobalExplorer.having,
							"tab_fields": silicon_ioi.doctype.ioiGlobalExplorer.tab_fields,
							"tab_isjumps": silicon_ioi.doctype.ioiGlobalExplorer.tab_isjumps,
							"tab_isformulas": silicon_ioi.doctype.ioiGlobalExplorer.tab_isformulas,
							"tab_orderby": silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby,
							"tab_orderby_sens": silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby_sens,
							"summary": silicon_ioi.doctype.ioiGlobalExplorer.summary,
							"tab_summary_fields": silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields,
							"tab_summary_action": silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action


					},
					async: false,
					callback:function(r)	{
					}
				});

			}
		}

	}

	static configure_columns()
	{
		if ((document.getElementById('ioi_global_explorer_drill_back_label').style.display) && (document.getElementById('ioi_global_explorer_drill_next_label').style.display)) {

			if ((document.getElementById('ioi_global_explorer_drill_back_label').style.display == 'block') ||
				(document.getElementById('ioi_global_explorer_drill_next_label').style.display == 'block')) {
				frappe.msgprint({title: __("Message"), message: __("Not possible when drill through has been actived"), indicator: "red"});
				return false;
			}
		}

		silicon_ioi.doctype.ioiGlobalExplorer.assign_screen_element_to_properties();

		if (silicon_ioi.doctype.ioiGlobalExplorer.record_name.trim() == '') {

			let title = __("New list")

			var globalExplorerNew =  new frappe.ui.Dialog({
				'title': title,
				'static': true,
				'fields': [
					{'fieldname': 'html_global_explorer_new', 'fieldtype': 'HTML'}
				],
				primary_action_label: __('Ok'),
				secondary_action_label: __('Cancel'),
				primary_action: function() {

					let button_ok = document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1];


					button_ok.disabled = true;

					if ((!document.getElementById('html_global_explorer_new_list_name').value) ||
						((document.getElementById('html_global_explorer_new_list_name').value) && (document.getElementById('html_global_explorer_new_list_name').value.trim() == ''))) {
						button_ok.disabled = false;
						frappe.msgprint({title: __("Message"), message: __("List name is mandatory"), indicator: "red"});
						return false;
					}

					let found = 0;

					let method = silicon_ioi.doctype.ioiGlobalExplorer.path_global_explorer_user_list + '.ioi_global_explorer_user_list_check_list_name'

					frappe.call({
						method: method,
						args: {	"from_doctype": document.getElementById('html_global_explorer_new_doctype').value,
								"list_name": document.getElementById('html_global_explorer_new_list_name').value
						},
						async: false,
						callback:function(r)	{
							found = r.message;
						}
					});

					if (found == 1) {
						button_ok.disabled = false;
						frappe.msgprint({title: __("Message"), message: __("List name already exists"), indicator: "red"});
						return false;
					}


					let is_live = 0;

					if (silicon_ioi.doctype.ioiGlobalExplorer.is_global_explorer == 0) {
						is_live = 1;
					}

					method = silicon_ioi.doctype.ioiGlobalExplorer.path_global_explorer_user_list + '.ioi_global_explorer_user_list_insert_update'

					frappe.call({
						method: method,
						args: {	"from_doctype": document.getElementById('html_global_explorer_new_doctype').value,
								"is_live": is_live,
								"list_name": document.getElementById('html_global_explorer_new_list_name').value,
								"is_default": silicon_ioi.doctype.ioiGlobalExplorer.default,
								"from_idx": document.getElementById('ioi_global_explorer_from_idx').value,
								"topn": document.getElementById('ioi_global_explorer_topn').value,
								"sq": silicon_ioi.doctype.ioiGlobalExplorer.sq,
								"so": silicon_ioi.doctype.ioiGlobalExplorer.so,
								"sd": silicon_ioi.doctype.ioiGlobalExplorer.sd,
								"si": silicon_ioi.doctype.ioiGlobalExplorer.si,
								"pq": silicon_ioi.doctype.ioiGlobalExplorer.pq,
								"po": silicon_ioi.doctype.ioiGlobalExplorer.po,
								"pr": silicon_ioi.doctype.ioiGlobalExplorer.pr,
								"pi": silicon_ioi.doctype.ioiGlobalExplorer.pi,
								"ppr": silicon_ioi.doctype.ioiGlobalExplorer.ppr,
								"odp": silicon_ioi.doctype.ioiGlobalExplorer.odp,
								"dos": silicon_ioi.doctype.ioiGlobalExplorer.dos,
								"tech_fieldname": silicon_ioi.doctype.ioiGlobalExplorer.tech_fieldname,

								"where_clause": document.getElementById('ioi_global_explorer_where').value,
								"having_clause": silicon_ioi.doctype.ioiGlobalExplorer.having,
								"tab_fields": silicon_ioi.doctype.ioiGlobalExplorer.tab_fields,
								"tab_isjumps": silicon_ioi.doctype.ioiGlobalExplorer.tab_isjumps,
								"tab_isformulas": silicon_ioi.doctype.ioiGlobalExplorer.tab_isformulas,
								"tab_formulas_description": silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description,
								"tab_orderby": silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby,
								"tab_orderby_sens": silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby_sens,
								"summary": silicon_ioi.doctype.ioiGlobalExplorer.summary,
								"tab_summary_fields": silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields,
								"tab_summary_action": silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action
						},
						async: false,
						callback:function(r)	{

							silicon_ioi.doctype.ioiGlobalExplorer.record_name = r.message.name;
							silicon_ioi.doctype.ioiGlobalExplorer.from_doctype = document.getElementById('html_global_explorer_new_doctype').value;
							silicon_ioi.doctype.ioiGlobalExplorer.list_name = document.getElementById('html_global_explorer_new_list_name').value;

							silicon_ioi.doctype.ioiGlobalExplorer.do_configure_columns();
						}
					});

					globalExplorerNew.hide();
				},
				secondary_action: function(){

					globalExplorerNew.hide();
				}

			});

			silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_new_doctype_label');
			silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_new_doctype');

			silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_new_list_name_label');
			silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_new_list_name');

			let html = '';

			html += '<div style="overflow: auto; overflow-x: auto; height:130px; width: 100%;">';

			// Doctype
			html += '	<div style="position: relative; top: 0px; left: 0px; width:300px;">';
			html += '		<label id="html_global_explorer_new_doctype_label" style="position: absolute; top: 0px; left: 2px;">' + __("From doctype") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 300px; height: 30px;"> ';
			html += '			<select id="html_global_explorer_new_doctype" class="input-with-feedback form-control bold"> ';
			html += "				<option value='ioi Sales Quote'>" 				+ __("Sales Quote") 			+ "</option>"
			html += "				<option value='ioi Sales Order'>" 				+ __("Sales Order") 			+ "</option>"
			html += "				<option value='ioi Sales Delivery'>" 			+ __("Sales Delivery") 			+ "</option>"
			html += "				<option value='ioi Sales Invoice'>" 			+ __("Sales Invoice") 			+ "</option>"
			html += "				<option value='ioi Purchases Price Request'>" 	+ __("Purchases Price Request") + "</option>"
			html += "				<option value='ioi Purchases Order'>" 			+ __("Purchases Order") 		+ "</option>"
			html += "				<option value='ioi Purchases Receipt'>" 		+ __("Purchases Receipt") 		+ "</option>"
			html += "				<option value='ioi Purchases Invoice'>" 		+ __("Purchases Invoice") 		+ "</option>"
			html += "				<option value='ioi Production'>" 				+ __("Production") 				+ "</option>"
			html += "				<option value='ioi Production BOM'>" 			+ __("Production BOM") 			+ "</option>"
			html += "				<option value='ioi Dossier BOM'>" 				+ __("Dossier BOM") 			+ "</option>"
			html += '			</select> ';
			html += '		</div>';
			html += '	</div>';

			// List name
			html += '	<div style="position: relative; top: 65px; left: 0px; width:300px;">';
			html += '		<label id="html_global_explorer_new_list_name_label" style="position: absolute; top: 0px; left: 2px;">' + __("List name") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 300px; height: 25px;"> ';
			html += '			<input id="html_global_explorer_new_list_name" type="text" class="input-with-feedback form-control bold" value="" >';
			html += '		</div>';
			html += '	</div>';

			html += '</div>';


			globalExplorerNew.fields_dict.html_global_explorer_new.$wrapper.html(html);
			globalExplorerNew.$wrapper.find('.modal-dialog').css("max-width", "350px").css("width", "350px");
			globalExplorerNew.show();

			silicon_ioi.doctype.ioiGlobalExplorer.sleep_static(1000).then(() => {
				document.getElementById('html_global_explorer_new_doctype').value = document.getElementById('ioi_global_explorer_from_doctype').value;

				if (silicon_ioi.doctype.ioiGlobalExplorer.is_global_explorer == 0) {
					document.getElementById('html_global_explorer_new_doctype').disabled = true;
				}
			});



		}else{
			silicon_ioi.doctype.ioiGlobalExplorer.do_configure_columns();
		}
	}

	static do_configure_columns()
	{

		let title = __("Columns configuration")

		var globalExplorerColumnsForm = new frappe.ui.Dialog({
			'title': title,
			'static': true,
			'fields': [
				{'fieldname': 'html_global_explorer_columns_configuration', 'fieldtype': 'HTML'}
			],
			primary_action_label: __('Ok'),
			secondary_action_label: __('Cancel'),
			primary_action: function() {

				let button_ok = document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1];


				button_ok.disabled = true;

				if (document.getElementById('html_global_explorer_columns_configuration_selected_fields').options.length == 0) {

					button_ok.disabled = false;
					frappe.msgprint({ title: __("Message"), message: __("Add a column, please"), indicator: "red" });
					return false;
				}

				silicon_ioi.doctype.ioiGlobalExplorer.tab_fields = [];
				silicon_ioi.doctype.ioiGlobalExplorer.tab_fieldtypes = [];
				silicon_ioi.doctype.ioiGlobalExplorer.tab_isjumps = [];
				silicon_ioi.doctype.ioiGlobalExplorer.tab_isformulas = [];
				silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description = []

				silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby = [];
				silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby_sens = [];


				silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields = [];
				silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action = [];



				for (var i = 0; i < document.getElementById('html_global_explorer_columns_configuration_selected_fields').options.length; i++) {

					let fn = document.getElementById('html_global_explorer_columns_configuration_selected_fields').options[i].value;
					fn = fn.substring(0, fn.indexOf('.')).toUpperCase();

					if ((fn == 'SQ') || (fn == 'SQ_DETAIL') || (fn == 'SQ_IOICUSTOMER_ORDER') || (fn == 'SQ_IOICUSTOMER_DELIVERY') || (fn == 'SQ_IOICUSTOMER_INVOICE') ||
						(fn == 'SI_DETAIL_IOIITEM') || (fn == 'SI_DETAIL_IOIMANUFACTURERCATALOG')) {
						document.getElementById('ioi_global_explorer_sq').checked = true;
					}else if ((fn == 'SO') || (fn == 'SO_DETAIL') || (fn == 'SO_IOICUSTOMER_ORDER') || (fn == 'SO_IOICUSTOMER_DELIVERY') || (fn == 'SO_IOICUSTOMER_INVOICE') ||
							  (fn == 'SO_DETAIL_IOIITEM') || (fn == 'SO_DETAIL_IOIMANUFACTURERCATALOG')) {
						document.getElementById('ioi_global_explorer_so').checked = true;
					}else if ((fn == 'SD') || (fn == 'SD_DETAIL') || (fn == 'SQ_IOICUSTOMER_ORDER') || (fn == 'SD_IOICUSTOMER_DELIVERY') || (fn == 'SD_IOICUSTOMER_INVOICE') ||
							  (fn == 'SD_DETAIL_IOIITEM') || (fn == 'SD_DETAIL_IOIMANUFACTURERCATALOG')) {
						document.getElementById('ioi_global_explorer_sd').checked = true;
					}else if ((fn == 'SI') || (fn == 'SI_DETAIL') || (fn == 'SI_IOICUSTOMER_ORDER') || (fn == 'SI_IOICUSTOMER_DELIVERY') || (fn == 'SI_IOICUSTOMER_INVOICE') ||
							  (fn == 'SI_DETAIL_IOIITEM') || (fn == 'SI_DETAIL_IOIMANUFACTURERCATALOG')) {
						document.getElementById('ioi_global_explorer_si').checked = true;
					}else if ((fn == 'PQ') || (fn == 'PQ_DETAIL') || (fn == 'PQ_IOICUSTOMER_ORDER') || (fn == 'PQ_IOICUSTOMER_DELIVERY') || (fn == 'PQ_IOICUSTOMER_INVOICE') ||
							  (fn == 'PQ_DETAIL_IOIITEM') || (fn == 'PQ_DETAIL_IOIMANUFACTURERCATALOG')) {
						document.getElementById('ioi_global_explorer_pq').checked = true;
					}else if ((fn == 'PO') || (fn == 'PO_DETAIL') || (fn == 'PO_IOISUPPLIER_ORDER') || (fn == 'PO_IOISUPPLIER_DELIVERY') || (fn == 'PO_IOISUPPLIER_INVOICE') ||
							  (fn == 'PO_DETAIL_IOIITEM') || (fn == 'PO_DETAIL_IOIMANUFACTURERCATALOG')) {
						document.getElementById('ioi_global_explorer_po').checked = true;
					}else if ((fn == 'PR') || (fn == 'PR_DETAIL') || (fn == 'PR_IOISUPPLIER_ORDER') || (fn == 'PR_IOISUPPLIER_DELIVERY') || (fn == 'PR_IOISUPPLIER_INVOICE') ||
							  (fn == 'PR_DETAIL_IOIITEM') || (fn == 'PR_DETAIL_IOIMANUFACTURERCATALOG')) {
						document.getElementById('ioi_global_explorer_pr').checked = true;
					}else if ((fn == 'PI') || (fn == 'PI_DETAIL') || (fn == 'PI_IOISUPPLIER_ORDER') || (fn == 'PI_IOISUPPLIER_DELIVERY') || (fn == 'PI_IOISUPPLIER_INVOICE') ||
							  (fn == 'PI_DETAIL_IOIITEM') || (fn == 'PI_DETAIL_IOIMANUFACTURERCATALOG')) {
						document.getElementById('ioi_global_explorer_pi').checked = true;
					}else if ((fn == 'PPR') || (fn == 'PPR_IOISUPPLIER') || (fn == 'PPR_IOIITEM') || (fn == 'PPR_IOIMANUFACTURERCATALOG')) {
						document.getElementById('ioi_global_explorer_ppr').checked = true;
					}else if ((fn == 'ODP') || (fn == 'ODP_DETAIL') || (fn == 'ODP_IOIITEM') || (fn == 'ODP_DETAIL_IOIITEM')) {
						document.getElementById('ioi_global_explorer_odp').checked = true;
					}else if ((fn == 'DOS') || (fn == 'DOS_DETAIL') || (fn == 'DOS_IOICUSTOMER_ORDER') || (fn == 'DOS_IOICUSTOMER_DELIVERY') || (fn == 'DOS_IOICUSTOMER_INVOICE') ||
							  (fn == 'DOS_IOIITEM') || (fn == 'DOS_DETAIL_IOIITEM') || (fn == 'DOS_DETAIL_IOIMANUFACTURERCATALOG') || (fn == 'DOS2')) {
						document.getElementById('ioi_global_explorer_dos').checked = true;
					}


					if (document.getElementById('html_global_explorer_columns_configuration_selected_kinds').options[i].innerText == '0') {
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = document.getElementById('html_global_explorer_columns_configuration_selected_fields').options[i].value;
					}else{
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = document.getElementById('html_global_explorer_columns_configuration_selected_fields').options[i].innerText;
					}
					silicon_ioi.doctype.ioiGlobalExplorer.tab_fieldtypes[silicon_ioi.doctype.ioiGlobalExplorer.tab_fieldtypes.length] = document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').options[i].innerText;
					silicon_ioi.doctype.ioiGlobalExplorer.tab_isjumps[silicon_ioi.doctype.ioiGlobalExplorer.tab_isjumps.length] = document.getElementById('html_global_explorer_columns_configuration_selected_jumps').options[i].innerText;
					silicon_ioi.doctype.ioiGlobalExplorer.tab_isformulas[silicon_ioi.doctype.ioiGlobalExplorer.tab_isformulas.length] = document.getElementById('html_global_explorer_columns_configuration_selected_kinds').options[i].innerText;

					if (document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description').options[i]) {
						silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description[silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description.length] = document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description').options[i].innerText;
					}

				}


				for (var i = 0; i < document.getElementById('html_global_explorer_columns_configuration_summary_fields').options.length; i++) {

					silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields.length] = document.getElementById('html_global_explorer_columns_configuration_summary_fields').options[i].innerText;
					silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action[silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action.length] = document.getElementById('html_global_explorer_columns_configuration_summary_action').options[i].value;
				}

				for (var i = 0; i < document.getElementById('html_global_explorer_columns_configuration_order_fields').options.length; i++) {

					silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby[silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby.length] = document.getElementById('html_global_explorer_columns_configuration_order_fields').options[i].value;
					silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby_sens[silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby_sens.length] = document.getElementById('html_global_explorer_columns_configuration_order_sens').options[i].innerText;
				}

				document.getElementById('ioi_global_explorer_where').value = document.getElementById('html_global_explorer_columns_configuration_where').value;
				silicon_ioi.doctype.ioiGlobalExplorer.where = document.getElementById('html_global_explorer_columns_configuration_where').value;

				silicon_ioi.doctype.ioiGlobalExplorer.having = document.getElementById('html_global_explorer_columns_configuration_having').value;


				globalExplorerColumnsForm.hide();

				silicon_ioi.doctype.ioiGlobalExplorer.refresh();
			},
			secondary_action: function(){

				globalExplorerColumnsForm.hide();
			}

		});

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_doctype_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_doctype');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_search_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_search');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_doctype_fields');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_add_all');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_remove_all');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_add');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_remove');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_add_special_field');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_edit_special_field');





		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_top');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_up');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_down');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_bottom');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_order_top');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_order_up');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_order_down');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_order_bottom');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_selected_fields_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_selected_fields');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_is_jump_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_is_jump');



		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_order_fields_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_order_fields');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_order_sens');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_sort_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_sort');


		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_selected_fieldtypes');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_selected_jumps');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_selected_kinds');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_selected_formulas_description');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_summary_fields');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_summary_action');


		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_where_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_where_switch_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_where');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_where_help');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_where_help');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_where_area');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_summary');



		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_having_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_having_switch_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_having');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_having_area');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_columns_configuration_having_remark_label');


		let html = '';

		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes = [];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[0] = ['ioi Sales Quote', __("Sales Quote"), 'sq'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[1] = ['ioi Sales Quote Detail', __("Sales Quote Detail"), 'sq_detail'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[2] = ['ioi Sales Quote Customer Order', __("Sales Quote (Order Customer)"), 'sq_ioicustomer_order'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[3] = ['ioi Sales Quote Customer Delivery', __("Sales Quote (Delivery Customer)"), 'sq_ioicustomer_delivery'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[4] = ['ioi Sales Quote Customer Invoice', __("Sales Quote (Invoice Customer)"), 'sq_ioicustomer_invoice'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[5] = ['ioi Sales Quote Item', __("Sales Quote Detail (Item)"), 'sq_detail_ioiitem'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[6] = ['ioi Sales Quote Manufacturer Catalog', __("Sales Quote Detail (Manufacturer Cat)"), 'sq_detail_ioimanufacturercatalog'];

		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[7] = ['ioi Sales Order', __("Sales Order"), 'so'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[8] = ['ioi Sales Order Detail', __("Sales Order Detail"), 'so_detail'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[9] = ['ioi Sales Order Customer Order', __("Sales Order (Order Customer)"), 'so_ioicustomer_order'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[10] = ['ioi Sales Order Customer Delivery', __("Sales Order (Delivery Customer)"), 'so_ioicustomer_delivery'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[11] = ['ioi Sales Order Customer Invoice', __("Sales Order (Invoice Customer)"), 'so_ioicustomer_invoice'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[12] = ['ioi Sales Order Item', __("Sales Order Detail (Item)"), 'so_detail_ioiitem'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[13] = ['ioi Sales Order Manufacturer Catalog', __("Sales Order Detail (Manufacturer Cat)"), 'so_detail_ioimanufacturercatalog'];


		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[14] = ['ioi Sales Delivery', __("Sales Delivery"), 'sd'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[15] = ['ioi Sales Delivery Detail', __("Sales Delivery Detail"), 'sd_detail'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[16] = ['ioi Sales Delivery Customer Order', __("Sales Delivery (Order Customer)"), 'sd_ioicustomer_order'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[17] = ['ioi Sales Delivery Customer Delivery', __("Sales Delivery (Delivery Customer)"), 'sd_ioicustomer_delivery'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[18] = ['ioi Sales Delivery Customer Invoice', __("Sales Delivery (Invoice Customer)"), 'sd_ioicustomer_invoice'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[19] = ['ioi Sales Delivery Item', __("Sales Delivery Detail (Item)"), 'sd_detail_ioiitem'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[20] = ['ioi Sales Delivery Manufacturer Catalog', __("Sales Delivery Detail (Manufacturer Cat)"), 'sd_detail_ioimanufacturercatalog'];


		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[21] = ['ioi Sales Invoice', __("Sales Invoice"), 'si'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[22] = ['ioi Sales Invoice Detail', __("Sales Invoice Detail"), 'si_detail'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[23] = ['ioi Sales Invoice Customer Order', __("Sales Invoice (Order Customer)"), 'si_ioicustomer_order'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[24] = ['ioi Sales Invoice Customer Delivery', __("Sales Invoice (Delivery Customer)"), 'si_ioicustomer_delivery'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[25] = ['ioi Sales Invoice Customer Invoice', __("Sales Invoice (Invoice Customer)"), 'si_ioicustomer_invoice'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[26] = ['ioi Sales Invoice Item', __("Sales Invoice Detail (Item)"), 'si_detail_ioiitem'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[27] = ['ioi Sales Invoice Manufacturer Catalog', __("Sales Invoice Detail (Manufacturer Cat)"), 'si_detail_ioimanufacturercatalog'];


		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[28] = ['ioi Purchases Price Request', __("Purchases Price Request"), 'pq'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[29] = ['ioi Purchases Price Request Detail', __("Purchases Price Request Detail"), 'pq_detail'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[30] = ['ioi Purchases Price Request Supplier Order', __("Purchases Price Request (Order Supplier)"), 'pq_ioisupplier_order'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[31] = ['ioi Purchases Price Request Supplier Delivery', __("Purchases Price Request (Delivery Supplier)"), 'pq_ioisupplier_delivery'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[32] = ['ioi Purchases Price Request Supplier Invoice', __("Purchases Price Request (Invoice Supplier)"), 'pq_ioisupplier_invoice'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[33] = ['ioi Purchases Price Request Item', __("Purchases Price Request Detail (Item)"), 'pq_detail_ioiitem'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[34] = ['ioi Purchases Price Request Manufacturer Catalog', __("Purchases Price Request Detail (Manufacturer Cat)"), 'pq_detail_ioimanufacturercatalog'];


		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[35] = ['ioi Purchases Order', __("Purchases Order"), 'po'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[36] = ['ioi Purchases Order Detail', __("Purchases Order Detail"), 'po_detail'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[37] = ['ioi Purchases Order Supplier Order', __("Purchases Order (Order Supplier)"), 'po_ioisupplier_order'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[38] = ['ioi Purchases Order Supplier Delivery', __("Purchases Order (Delivery Supplier)"), 'po_ioisupplier_delivery'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[39] = ['ioi Purchases Order Supplier Invoice', __("Purchases Order (Invoice Supplier)"), 'po_ioisupplier_invoice'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[40] = ['ioi Purchases Order Item', __("Purchases Order Detail (Item)"), 'po_detail_ioiitem'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[41] = ['ioi Purchases Order Manufacturer Catalog', __("Purchases Order Detail (Manufacturer Cat)"), 'po_detail_ioimanufacturercatalog'];


		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[42] = ['ioi Purchases Receipt', __("Purchases Receipt"), 'pr'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[43] = ['ioi Purchases Receipt Detail', __("Purchases Receipt Detail"), 'pr_detail'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[44] = ['ioi Purchases Receipt Supplier Order', __("Purchases Receipt (Order Supplier)"), 'pr_ioisupplier_order'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[45] = ['ioi Purchases Receipt Supplier Delivery', __("Purchases Receipt (Delivery Supplier)"), 'pr_ioisupplier_delivery'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[46] = ['ioi Purchases Receipt Supplier Invoice', __("Purchases Receipt (Invoice Supplier)"), 'pr_ioisupplier_invoice'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[47] = ['ioi Purchases Receipt Item', __("Purchases Receipt Detail (Item)"), 'pr_detail_ioiitem'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[48] = ['ioi Purchases Receipt Manufacturer Catalog', __("Purchases Receipt Detail (Manufacturer Cat)"), 'pr_detail_ioimanufacturercatalog'];


		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[49] = ['ioi Purchases Invoice', __("Purchases Invoice"), 'pi'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[50] = ['ioi Purchases Invoice Detail', __("Purchases Invoice Detail"), 'pi_detail'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[51] = ['ioi Purchases Invoice Supplier Order', __("Purchases Invoice (Order Supplier)"), 'pi_ioisupplier_order'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[52] = ['ioi Purchases Invoice Supplier Delivery', __("Purchases Invoice (Delivery Supplier)"), 'pi_ioisupplier_delivery'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[53] = ['ioi Purchases Invoice Supplier Invoice', __("Purchases Invoice (Invoice Supplier)"), 'pi_ioisupplier_invoice'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[54] = ['ioi Purchases Invoice Item', __("Purchases Invoice Detail (Item)"), 'pi_detail_ioiitem'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[55] = ['ioi Purchases Invoice Manufacturer Catalog', __("Purchases Invoice Detail (Manufacturer Cat)"), 'pi_detail_ioimanufacturercatalog'];


		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[56] = ['ioi Purchases Proposal Internal', __("Purchases Proposal"), 'ppr'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[57] = ['ioi Purchases Proposal Internal Supplier', __("Purchases Proposal (Supplier)"), 'ppr_ioisupplier'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[58] = ['ioi Purchases Proposal Internal Item', __("Purchases Proposal (Item)"), 'ppr_ioiitem'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[59] = ['ioi Purchases Proposal Internal Manufacturer Catalog', __("Purchases Proposal (Manufacturer Cat)"), 'ppr_ioimanufacturercatalog'];


		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[60] = ['ioi Production', __("Production"), 'odp'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[61] = ['ioi Production BOM', __("Production BOM"), 'odp_detail'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[62] = ['ioi Production Item', __("Production (Item)"), 'odp_ioiitem'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[63] = ['ioi Production BOM Item', __("Production BOM (Item)"), 'odp_detail_ioiitem'];

		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[64] = ['ioi Dossier', __("Dossier"), 'dos'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[65] = ['ioi Dossier BOM', __("Dossier BOM"), 'dos_detail'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[66] = ['ioi Dossier Customer Order', __("Dossier (Order Customer)"), 'dos_ioicustomer_order'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[67] = ['ioi Dossier Customer Delivery', __("Dossier (Delivery Customer)"), 'dos_ioicustomer_delivery'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[68] = ['ioi Dossier Customer Invoice', __("Dossier (Invoice Customer)"), 'dos_ioicustomer_invoice'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[69] = ['ioi Dossier Item', __("Dossier (Item)"), 'dos_ioiitem'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[70] = ['ioi Dossier BOM Item', __("Dossier BOM (Item)"), 'dos_detail_ioiitem'];
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[71] = ['ioi Dossier BOM Manufacturer Catalog', __("Dossier BOM (Manufacturer Cat)"), 'dos_detail_ioimanufacturercatalog'];


		html += '<div style="overflow: auto; overflow-x: auto; overflow-y: hidden; height:600px; ">';


		// Doctypes
		html += '	<div style="position: relative; top: 0px; left: 0px; width:280px;">';
		html += '		<label id="html_global_explorer_columns_configuration_doctype_label" style="position: absolute; top: 0px; left: 2px;"><b>' + __("Doctype") + '</b></label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 280px; height: 30px;"> ';
		html += '			<select id="html_global_explorer_columns_configuration_doctype" class="input-with-feedback form-control bold"> ';

		for (var i = 0; i < silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes.length; i++ ) {
			html += "				<option value='" + silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[i][0] + "'>" + silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[i][1] + "</option>"
		}

		html += '			</select>';
		html += '		</div>';
		html += '	</div>';

		// Search
		html += '	<div style="position: relative; top: 0px; left: 290px; width:190px;">';
		html += '		<label id="html_global_explorer_columns_configuration_search_label" style="position: absolute; top: 0px; left: 2px;"><b>' + __("Search") + '</b></label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 190px; height: 25px;"> ';
		html += '			<input id="html_global_explorer_columns_configuration_search" type="text" class="input-with-feedback form-control bold"  value="" >';
		html += '		</div>';
		html += '	</div>';

		// Doctype fields
		html += '<div style="position: relative; top: 65px; left: 0px; width:480px;">';
		html += '	<div class="control-input" style="position: absolute; top: 0px; left:2px; width: 480px; height: 30px;"> ';
		html += '		<select id="html_global_explorer_columns_configuration_doctype_fields" class="input-with-feedback form-control bold" size="21"> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';

		// Add
		html += '<div style="position: relative; top: 25px; left: 490px; width:40px;">';
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='		<button id="html_global_explorer_columns_configuration_add" title="' + __("Add") + '" class="btn btn-default ellipsis" style="height: 30px; width: 40px;" onclick="">&rarr;' + '</button>';
		html += '	</div>';
		html += '</div>';

		// Remove
		html += '<div style="position: relative; top: 65px; left: 490px; width:40px;">';
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='		<button id="html_global_explorer_columns_configuration_remove" title="' + __("Remove") + '" class="btn btn-default ellipsis" style="height: 30px; width: 40px;" onclick="">&larr;' + '</button>';
		html += '	</div>';
		html += '</div>';

		// Add All
		html += '<div style="position: relative; top: 105px; left: 490px; width:40px;">';
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='		<button id="html_global_explorer_columns_configuration_add_all" title="' + __("Add all fields") + '" class="btn btn-default ellipsis" style="height: 30px; width: 40px;" onclick="">&rarrb;' + '</button>';
		html += '	</div>';
		html += '</div>';

		// Remove All
		html += '<div style="position: relative; top: 145px; left: 490px; width:40px;">';
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='		<button id="html_global_explorer_columns_configuration_remove_all" title="' + __("Remove all fields") + '" class="btn btn-default ellipsis" style="height: 30px; width: 40px;" onclick="">&larrb;' + '</button>';
		html += '	</div>';
		html += '</div>';


		// Add Special field
		html += '<div style="position: relative; top: 185px; left: 490px; width:40px;">';
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 30px; display: block;">';
		html +='		<button id="html_global_explorer_columns_configuration_add_special_field" title="' + __("Add expression / subquery") + '" class="btn btn-default ellipsis" style="height: 30px; width: 40px; display: block;" onclick="">&#10148;' + '</button>';
		html += '	</div>';
		html += '</div>';


		// Edit Special field
		html += '<div style="position: relative; top: 225px; left: 490px; width:40px;">';
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 30px; display:block;">';
		html +='		<button id="html_global_explorer_columns_configuration_edit_special_field" title="' + __("Edit expression / subquery") + '" class="btn btn-default ellipsis" style="height: 30px; width: 40px; display: block;" onclick="">...' + '</button>';
		html += '	</div>';
		html += '</div>';


		// Selected Columns
		html += '<div style="position: relative; top: 0px; left: 540px; width:480px;">';
		html += '	<label id="html_global_explorer_columns_configuration_selected_fields_label" style="position: absolute; top: 0px; left: 2px;"><b>' + __("Selected columns") + '</b></label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 480px; height: 30px;"> ';
		html += '		<select id="html_global_explorer_columns_configuration_selected_fields" class="input-with-feedback form-control bold" size="12"> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';

		// Jump
		html += '	<div style="position: relative; top: -5px; left: 940px; width:120px;">';
		html += '		<input type="checkbox" id="html_global_explorer_columns_configuration_is_jump" style="position: absolute; top: 8px; left: 2px; display:none;">';
		html += '		<label id="html_global_explorer_columns_configuration_is_jump_label" style="position: absolute; top: 6px; left: 30px; display:none;">' + __("Jump") + '</label>';
		html += '	</div>';


		// Top
		html += '<div style="position: relative; top: 25px; left: 1030px; width:40px;">';
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='		<button id="html_global_explorer_columns_configuration_top" title="' + __("Top") + '" class="btn btn-default ellipsis" style="height: 30px; width: 40px;" onclick="">&Uarr;' + '</button>';
		html += '	</div>';
		html += '</div>';

		// Up
		html += '<div style="position: relative; top: 65px; left: 1030px; width:40px;">';
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='		<button id="html_global_explorer_columns_configuration_up" title="' + __("Up") + '" class="btn btn-default ellipsis" style="height: 30px; width: 40px;" onclick="">&uarr;' + '</button>';
		html += '	</div>';
		html += '</div>';

		// Down
		html += '<div style="position: relative; top: 105px; left: 1030px; width:40px;">';
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='		<button id="html_global_explorer_columns_configuration_down" title="' + __("Down") + '" class="btn btn-default ellipsis" style="height: 30px; width: 40px;" onclick="">&darr;' + '</button>';
		html += '	</div>';
		html += '</div>';

		// Bottom
		html += '<div style="position: relative; top: 145px; left: 1030px; width:40px;">';
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='		<button id="html_global_explorer_columns_configuration_bottom" title="' + __("Bottom") + '" class="btn btn-default ellipsis" style="height: 30px; width: 40px;" onclick="">&Darr;' + '</button>';
		html += '	</div>';
		html += '</div>';


		// Selected fieldtypes
		html += '<div style="position: relative; top: 130px; left: 1090px; width:480px; ">';
		html += '	<div class="control-input" style="position: absolute; top: 0px; left:2px; width: 480px; height: 30px; display: none;"> ';
		html += '		<select id="html_global_explorer_columns_configuration_selected_fieldtypes" class="input-with-feedback form-control bold" size="20"> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';

		// Selected jumps
		html += '<div style="position: relative; top: 130px; left: 1580px; width:480px;">';
		html += '	<div class="control-input" style="position: absolute; top: 0px; left:2px; width: 480px; height: 30px; display: none;"> ';
		html += '		<select id="html_global_explorer_columns_configuration_selected_jumps" class="input-with-feedback form-control bold" size="20"> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';

		// Selected kinds (0: Field, 1 : Formula)
		html += '<div style="position: relative; top: 130px; left: 2070px; width:480px;">';
		html += '	<div class="control-input" style="position: absolute; top: 0px; left:2px; width: 480px; height: 30px; display: none;"> ';
		html += '		<select id="html_global_explorer_columns_configuration_selected_kinds" class="input-with-feedback form-control bold" size="20"> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';

		// Formulas description
		html += '<div style="position: relative; top: 130px; left: 2070px; width:480px;">';
		html += '	<div class="control-input" style="position: absolute; top: 0px; left:2px; width: 480px; height: 30px; display: none;"> ';
		html += '		<select id="html_global_explorer_columns_configuration_selected_formulas_description" class="input-with-feedback form-control bold" size="20"> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';


		// Summary fields
		html += '<div style="position: relative; top: 130px; left: 1090px; width:480px; ">';
		html += '	<div class="control-input" style="position: absolute; top: 0px; left:2px; width: 480px; height: 30px; display: none;"> ';
		html += '		<select id="html_global_explorer_columns_configuration_summary_fields" class="input-with-feedback form-control bold" size="20"> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';

		// Summary action
		html += '<div style="position: relative; top: 130px; left: 1090px; width:480px; ">';
		html += '	<div class="control-input" style="position: absolute; top: 0px; left:2px; width: 480px; height: 30px; display: none;"> ';
		html += '		<select id="html_global_explorer_columns_configuration_summary_action" class="input-with-feedback form-control bold" size="20"> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';


		// Order by fields
		html += '<div style="position: relative; top: 252px; left: 540px; width:480px;">';
		html += '	<label id="html_global_explorer_columns_configuration_order_fields_label" style="position: absolute; top: 0px; left: 2px;"><b>' + __("Order by") + '</b></label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 480px; height: 30px;"> ';
		html += '		<select id="html_global_explorer_columns_configuration_order_fields" class="input-with-feedback form-control bold" size="9"> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';

		// Order fields sens
		html += '<div style="position: relative; top: 250px; left: 1080px; width:480px;">';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 480px; height: 30px; display: none;"> ';
		html += '		<select id="html_global_explorer_columns_configuration_order_sens" class="input-with-feedback form-control bold" size="9"> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';


		// Descending
		html += '	<div style="position: relative; top: 245px; left: 910px; width:150px;">';
		html += '		<input type="checkbox" id="html_global_explorer_columns_configuration_sort" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="html_global_explorer_columns_configuration_sort_label" style="position: absolute; top: 6px; left: 30px;">' + __("Descending") + '</label>';
		html += '	</div>';


		// Order Top
		html += '<div style="position: relative; top: 277px; left: 1030px; width:40px;">';
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='		<button id="html_global_explorer_columns_configuration_order_top" title="' + __("Top") + '" class="btn btn-default ellipsis" style="height: 30px; width: 40px;" onclick="">&Uarr;' + '</button>';
		html += '	</div>';
		html += '</div>';

		// Order Up
		html += '<div style="position: relative; top: 317px; left: 1030px; width:40px;">';
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='		<button id="html_global_explorer_columns_configuration_order_up" title="' + __("Up") + '" class="btn btn-default ellipsis" style="height: 30px; width: 40px;" onclick="">&uarr;' + '</button>';
		html += '	</div>';
		html += '</div>';

		// Order Down
		html += '<div style="position: relative; top: 357px; left: 1030px; width:40px;">';
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='		<button id="html_global_explorer_columns_configuration_order_down" title="' + __("Down") + '" class="btn btn-default ellipsis" style="height: 30px; width: 40px;" onclick="">&darr;' + '</button>';
		html += '	</div>';
		html += '</div>';

		// Order Bottom
		html += '<div style="position: relative; top: 397px; left: 1030px; width:40px;">';
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='		<button id="html_global_explorer_columns_configuration_order_bottom" title="' + __("Bottom") + '" class="btn btn-default ellipsis" style="height: 30px; width: 40px;" onclick="">&Darr;' + '</button>';
		html += '	</div>';
		html += '</div>';


		// Where
		html += '<div id="html_global_explorer_columns_configuration_where_area" style="position: relative; top: 450px; left: 0px; width:660px;">';
		html += '	<label id="html_global_explorer_columns_configuration_where_label" style="position: absolute; top: 0px; left: 2px;"><b>' + __("Conditions (SQL without where) ") + '</b></label>';
		html += '	<label id="html_global_explorer_columns_configuration_where_switch_label" style="position: absolute; top: 0px; left: 302px;"><font color="blue"><u>' + __("Show Having") + '</u></font></label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 660px; height: 25px;"> ';
		html += '		<textarea id="html_global_explorer_columns_configuration_where" style="position: absolute; top: 0px; left: 2px; width: 660px; height: 80px; resize: none;" class="input-with-feedback form-control " >';
		html += '		</textarea>';
		html += '	</div>';
		html += '</div>';

		// Having
		html += '<div id="html_global_explorer_columns_configuration_having_area" style="position: relative; top: 450px; left: 0px; width:660px; display: none;">';
		html += '	<label id="html_global_explorer_columns_configuration_having_label" style="position: absolute; top: 0px; left: 2px;"><b>' + "Having" + '</b></label>';
		html += '	<label id="html_global_explorer_columns_configuration_having_switch_label" style="position: absolute; top: 0px; left: 302px;"><font color="blue"><u>' + __("Show Conditions") + '</u></font></label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 660px; height: 25px;"> ';
		html += '		<textarea id="html_global_explorer_columns_configuration_having" style="position: absolute; top: 0px; left: 2px; width: 660px; height: 80px; resize: none;" class="input-with-feedback form-control " >';
		html += '		</textarea>';
		html += '	</div>';
		html += '	<label id="html_global_explorer_columns_configuration_having_remark_label" style="position: absolute; top: 115px; left: 0px;"><font color="blue">' + __("Use the fieldname's alias (not the fieldname). For example so_order_customer_id")+ '</font></label>';
		html += '</div>';


		html += '	<div style="position: relative; top: 450px; left: 670px; width:350px;">';
		html += '		<label id="html_global_explorer_columns_configuration_where_help_label" style="position: absolute; top: 0px; left: 2px;">' + __("Table alias") + ': <font color="blue">so.order_customer_id</font>' + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 350px; height: 25px;"> ';
		html += '			<textarea id="html_global_explorer_columns_configuration_where_help" style="position: absolute; top: 0px; left: 2px; width: 350px; height: 80px; resize: none;" class="input-with-feedback form-control" readonly>';
		html += 'sq_ioicustomer_order. : ' + __("Quote Order Cust.") + '\n';
		html += 'sq_ioicustomer_delivery. : ' + __("Quote Del. Cust.") + '\n';
		html += 'sq_ioicustomer_invoice. : ' + __("Quote Inv. Cust.") + '\n';
		html += 'sq_detail_ioiitem. : ' + __("Quote Item") + '\n';
		html += 'sq_detail_ioimanufacturercatalog. : ' + __("Q. Manuf.") + '\n';

		html += 'so. / so_detail. : ' + __("Order / detail") + '\n';
		html += 'so_ioicustomer_order. : ' + __("Order Order Cust.") + '\n';
		html += 'so_ioicustomer_delivery. : ' + __("Order Del. Cust.") + '\n';
		html += 'so_ioicustomer_invoice. : ' + __("Order Inv. Cust.") + '\n';
		html += 'so_detail_ioiitem. : ' + __("Order Item") + '\n';
		html += 'so_detail_ioimanufacturercatalog. : ' + __("Order Manuf.") + '\n';

		html += 'sd. / sd_detail. : ' + __("Delivery / detail") + '\n'
		html += 'sd_ioicustomer_order. : ' + __("Delivery Order Cust.") + '\n';
		html += 'sd_ioicustomer_delivery. : ' + __("Delivery Del. Cust.") + '\n';
		html += 'sd_ioicustomer_invoice. : ' + __("Delivery Inv. Cust.") + '\n';
		html += 'sd_detail_ioiitem. : ' + __("Delivery Item") + '\n';
		html += 'sd_detail_ioimanufacturercatalog. : ' + __("Del Manuf.") + '\n';

		html += 'si. / si_detail. : ' + __("Invoice / detail") + '\n';
		html += 'si_ioicustomer_order. : ' + __("Invoice Order Cust.") + '\n';
		html += 'si_ioicustomer_delivery. : ' + __("Invoice Del. Cust.") + '\n';
		html += 'si_ioicustomer_invoice. : ' + __("Invoice Inv. Cust.") + '\n';
		html += 'si_detail_ioiitem. : ' + __("Invoice Item") + '\n';
		html += 'si_detail_ioimanufacturercatalog. : ' + __("Inv Manuf.") + '\n';


		html += 'pq. / pq_detail. : ' + __("Price Request / detail") + '\n';
		html += 'pq_ioisupplier_order. : ' + __("P. Req. Order Supplier") + '\n';
		html += 'pq_ioisupplier_delivery. : ' + __("P. Req. Rec. Supplier") + '\n';
		html += 'pq_ioisupplier_invoice. : ' + __("P. Req. Inv. Supplier") + '\n';
		html += 'pq_detail_ioiitem. : ' + __("P. Req. Item") + '\n';
		html += 'pq_detail_ioimanufacturercatalog. : ' + __("PQ. Manuf.") + '\n';

		html += 'po. / po_detail. : ' + __("Purch. Order / detail") + '\n';
		html += 'po_ioisupplier_order. : ' + __("P. Order Order Supplier") + '\n';
		html += 'po_ioisupplier_delivery. : ' + __("P. Order Rec. Supplier") + '\n';
		html += 'po_ioisupplier_invoice. : ' + __("P. Order Inv. Supplier") + '\n';
		html += 'po_detail_ioiitem. : ' + __("P. Order. Item") + '\n';
		html += 'po_detail_ioimanufacturercatalog. : ' + __("PO. Manuf.") + '\n';


		html += 'pr. / pr_detail. : ' + __("Receipt / detail") + '\n';
		html += 'pr_ioisupplier_order. : ' + __("P. Rec. Order Supplier") + '\n';
		html += 'pr_ioisupplier_delivery. : ' + __("P. Rec. Rec. Supplier") + '\n';
		html += 'pr_ioisupplier_invoice. : ' + __("P. Rec. Inv. Supplier") + '\n';
		html += 'pr_detail_ioiitem. : ' + __("P. Rec. Item") + '\n';
		html += 'pr_detail_ioimanufacturercatalog. : ' + __("PR. Manuf.") + '\n';

		html += 'pi. / pi_detail. : ' + __("Purch. Invoice / detail") + '\n';
		html += 'pi_ioisupplier_order. : ' + __("P. Inv. Order Supplier") + '\n';
		html += 'pi_ioisupplier_delivery. : ' + __("P. Inv. Rec. Supplier") + '\n';
		html += 'pi_ioisupplier_invoice. : ' + __("P. Inv. Inv. Supplier") + '\n';
		html += 'pi_detail_ioiitem. : ' + __("P. Inv. Item") + '\n';
		html += 'pi_detail_ioimanufacturercatalog. : ' + __("PI. Manuf.") + '\n';


		html += 'ppr. : ' + __("Purch. Proposal") + '\n';
		html += 'ppr_ioisupplier. : ' + __("Purch. Proposal Supplier") + '\n';
		html += 'ppr_ioiitem. : ' + __("P. Proposal. Item") + '\n';
		html += 'ppr_ioimanufacturercatalog. : ' + __("PP. Manuf.") + '\n';


		html += 'odp. / odp_detail. : ' + __("Production / detail") + '\n';
		html += 'odp_ioiitem. : ' + __("Prod. Item") + '\n';
		html += 'odp_detail_ioiitem. : ' + __("Prod. BOM Item.") + '\n';

		html += 'dos. / dos_detail. : ' + __("Dossier / detail");
		html += 'dos_ioicustomer_order. : ' + __("Dossier Order Cust.") + '\n';
		html += 'dos_ioicustomer_delivery. : ' + __("Dossier Del. Cust.") + '\n';
		html += 'dos_ioicustomer_invoice. : ' + __("Dossier Inv. Cust.") + '\n';

		html += 'dos_ioiitem. : ' + __("Dossier. Item") + '\n';
		html += 'dos_detail_ioiitem. : ' + __("Dos. BOM Item.") + '\n';
		html += 'dos_detail_ioimanufacturercatalog. : ' + __("Dos. Manuf.") + '\n';
		html += '			</textarea>';
		html += '		</div>';
		html += '	</div>';

		// Summary
		html += '<div style="position: relative; top: 565px; left: 870px; width:150px;">';
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 30px; display: block;">';
		html +='		<button id="html_global_explorer_columns_configuration_summary" title="' + __("Summary") + '" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="">' + __("Summary") + '</button>';
		html += '	</div>';
		html += '</div>';




		html += '</div>';


		globalExplorerColumnsForm.fields_dict.html_global_explorer_columns_configuration.$wrapper.html(html);
		globalExplorerColumnsForm.$wrapper.find('.modal-dialog').css("max-width", "1120px").css("width", "1120px");
		globalExplorerColumnsForm.show();

		globalExplorerColumnsForm.add_custom_action('Get SQL', silicon_ioi.doctype.ioiGlobalExplorer.get_sql);

		silicon_ioi.doctype.ioiGlobalExplorer.sleep_static(750).then(() => {

			let fct_change = function() {
				document.getElementById('html_global_explorer_columns_configuration_search').value = '';
				silicon_ioi.doctype.ioiGlobalExplorer.load_fields_from_doctype();

			}

			document.getElementById('html_global_explorer_columns_configuration_doctype').onchange = fct_change

			document.getElementById('html_global_explorer_columns_configuration_doctype').value = document.getElementById('ioi_global_explorer_from_doctype').value;




			let fct_search = function() {

				let s = this.value;

				for (var i = 0; i < document.getElementById('html_global_explorer_columns_configuration_doctype_fields').options.length; i++) {
					document.getElementById('html_global_explorer_columns_configuration_doctype_fields').options[i].style.display = 'block';
				}

				for (var i = 0; i < document.getElementById('html_global_explorer_columns_configuration_doctype_fields').options.length; i++) {

					if (document.getElementById('html_global_explorer_columns_configuration_doctype_fields').options[i].text.toUpperCase().indexOf(s.toUpperCase()) == -1) {
						document.getElementById('html_global_explorer_columns_configuration_doctype_fields').options[i].style.display = 'none';
					}
				}
			}

			document.getElementById('html_global_explorer_columns_configuration_search').onkeyup = fct_search;

			silicon_ioi.doctype.ioiGlobalExplorer.load_fields_from_doctype();


			document.getElementById('html_global_explorer_columns_configuration_search').onkeyup = fct_search;

			let configuration_add = function() {
				silicon_ioi.doctype.ioiGlobalExplorer.configuration_add();
			}

			document.getElementById('html_global_explorer_columns_configuration_add').onclick = configuration_add;

			let configuration_remove = function() {
				silicon_ioi.doctype.ioiGlobalExplorer.configuration_remove();
			}

			document.getElementById('html_global_explorer_columns_configuration_remove').onclick = configuration_remove;

			let configuration_add_all = function() {
				silicon_ioi.doctype.ioiGlobalExplorer.configuration_add_all();
			}

			document.getElementById('html_global_explorer_columns_configuration_add_all').onclick = configuration_add_all;

			let configuration_remove_all = function() {
				silicon_ioi.doctype.ioiGlobalExplorer.configuration_remove_all();
			}

			document.getElementById('html_global_explorer_columns_configuration_remove_all').onclick = configuration_remove_all;


			let configuration_add_expression = function() {
				silicon_ioi.doctype.ioiGlobalExplorer.configuration_add_expression();
			}

			document.getElementById('html_global_explorer_columns_configuration_add_special_field').onclick = configuration_add_expression;


			let configuration_edit_expression = function() {
				silicon_ioi.doctype.ioiGlobalExplorer.configuration_edit_expression();
			}

			document.getElementById('html_global_explorer_columns_configuration_edit_special_field').onclick = configuration_edit_expression;


			let configuration_top = function() {
				silicon_ioi.doctype.ioiGlobalExplorer.configuration_top();
			}

			document.getElementById('html_global_explorer_columns_configuration_top').onclick = configuration_top;

			let configuration_up = function() {
				silicon_ioi.doctype.ioiGlobalExplorer.configuration_up();
			}

			document.getElementById('html_global_explorer_columns_configuration_up').onclick = configuration_up;

			let configuration_down = function() {
				silicon_ioi.doctype.ioiGlobalExplorer.configuration_down();
			}

			document.getElementById('html_global_explorer_columns_configuration_down').onclick = configuration_down;

			let configuration_bottom = function() {
				silicon_ioi.doctype.ioiGlobalExplorer.configuration_bottom();
			}

			document.getElementById('html_global_explorer_columns_configuration_bottom').onclick = configuration_bottom;



			let configuration_order_top = function() {
				silicon_ioi.doctype.ioiGlobalExplorer.configuration_order_top();
			}

			document.getElementById('html_global_explorer_columns_configuration_order_top').onclick = configuration_order_top;

			let configuration_order_up = function() {
				silicon_ioi.doctype.ioiGlobalExplorer.configuration_order_up();
			}

			document.getElementById('html_global_explorer_columns_configuration_order_up').onclick = configuration_order_up;

			let configuration_order_down = function() {
				silicon_ioi.doctype.ioiGlobalExplorer.configuration_order_down();
			}

			document.getElementById('html_global_explorer_columns_configuration_order_down').onclick = configuration_order_down;

			let configuration_order_bottom = function() {
				silicon_ioi.doctype.ioiGlobalExplorer.configuration_order_bottom();
			}

			document.getElementById('html_global_explorer_columns_configuration_order_bottom').onclick = configuration_order_bottom;

			let fct_jump_label_click = function() {
				document.getElementById('html_global_explorer_columns_configuration_is_jump').click();
			}

			document.getElementById('html_global_explorer_columns_configuration_is_jump_label').onclick = fct_jump_label_click;


			let fct_jump_sort_click = function() {
				document.getElementById('html_global_explorer_columns_configuration_sort').click();
			}

			document.getElementById('html_global_explorer_columns_configuration_sort_label').onclick = fct_jump_sort_click;

			if (!silicon_ioi.doctype.ioiGlobalExplorer.tab_fields) {
				silicon_ioi.doctype.ioiGlobalExplorer.tab_fields = [];
			}


			if (silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length == 0) {


				// Default
				let field_order = [];

				if (document.getElementById('ioi_global_explorer_from_doctype').value.toUpperCase() == 'IOI SALES INVOICE') {

					field_order[field_order.length] = 'SI';
					field_order[field_order.length] = 'SD';
					field_order[field_order.length] = 'SO';
					field_order[field_order.length] = 'SQ';
					field_order[field_order.length] = 'PO';
					field_order[field_order.length] = 'PQ';
					field_order[field_order.length] = 'PR';
					field_order[field_order.length] = 'PI';
					field_order[field_order.length] = 'PPR';
					field_order[field_order.length] = 'ODP';

				}else if (document.getElementById('ioi_global_explorer_from_doctype').value.toUpperCase() == 'IOI SALES DELIVERY') {

					field_order[field_order.length] = 'SD';
					field_order[field_order.length] = 'SI';
					field_order[field_order.length] = 'SO';
					field_order[field_order.length] = 'SQ';
					field_order[field_order.length] = 'PO';
					field_order[field_order.length] = 'PQ';
					field_order[field_order.length] = 'PR';
					field_order[field_order.length] = 'PI';
					field_order[field_order.length] = 'PPR';
					field_order[field_order.length] = 'ODP';

				}else if (document.getElementById('ioi_global_explorer_from_doctype').value.toUpperCase() == 'IOI SALES ORDER') {

					field_order[field_order.length] = 'SO';
					field_order[field_order.length] = 'SD';
					field_order[field_order.length] = 'SI';
					field_order[field_order.length] = 'SQ';
					field_order[field_order.length] = 'PQ';
					field_order[field_order.length] = 'PO';
					field_order[field_order.length] = 'PR';
					field_order[field_order.length] = 'PI';
					field_order[field_order.length] = 'PPR';
					field_order[field_order.length] = 'ODP';

				}else if (document.getElementById('ioi_global_explorer_from_doctype').value.toUpperCase() == 'IOI SALES QUOTE') {

					field_order[field_order.length] = 'SQ';
					field_order[field_order.length] = 'SO';
					field_order[field_order.length] = 'SD';
					field_order[field_order.length] = 'SI';
					field_order[field_order.length] = 'PO';
					field_order[field_order.length] = 'PQ';
					field_order[field_order.length] = 'PR';
					field_order[field_order.length] = 'PI';
					field_order[field_order.length] = 'PPR';
					field_order[field_order.length] = 'ODP';

				}else if (document.getElementById('ioi_global_explorer_from_doctype').value.toUpperCase() == 'IOI PURCHASES INVOICE') {

					field_order[field_order.length] = 'PI';
					field_order[field_order.length] = 'PR';
					field_order[field_order.length] = 'PO';
					field_order[field_order.length] = 'PQ';
					field_order[field_order.length] = 'SO';
					field_order[field_order.length] = 'SQ';
					field_order[field_order.length] = 'SD';
					field_order[field_order.length] = 'SI';
					field_order[field_order.length] = 'ODP';
					field_order[field_order.length] = 'DOS';

				}else if (document.getElementById('ioi_global_explorer_from_doctype').value.toUpperCase() == 'IOI PURCHASES RECEIPT') {

					field_order[field_order.length] = 'PR';
					field_order[field_order.length] = 'PI';
					field_order[field_order.length] = 'PO';
					field_order[field_order.length] = 'PQ';
					field_order[field_order.length] = 'SO';
					field_order[field_order.length] = 'SQ';
					field_order[field_order.length] = 'SD';
					field_order[field_order.length] = 'SI';
					field_order[field_order.length] = 'ODP';
					field_order[field_order.length] = 'DOS';

				}else if (document.getElementById('ioi_global_explorer_from_doctype').value.toUpperCase() == 'IOI PURCHASES ORDER') {

					field_order[field_order.length] = 'PO';
					field_order[field_order.length] = 'PQ';
					field_order[field_order.length] = 'PR';
					field_order[field_order.length] = 'PI';
					field_order[field_order.length] = 'SO';
					field_order[field_order.length] = 'SQ';
					field_order[field_order.length] = 'SD';
					field_order[field_order.length] = 'SI';
					field_order[field_order.length] = 'ODP';
					field_order[field_order.length] = 'DOS';

				}else if (document.getElementById('ioi_global_explorer_from_doctype').value.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') {

					field_order[field_order.length] = 'PQ';
					field_order[field_order.length] = 'PO';
					field_order[field_order.length] = 'PR';
					field_order[field_order.length] = 'PI';
					field_order[field_order.length] = 'SO';
					field_order[field_order.length] = 'SQ';
					field_order[field_order.length] = 'SD';
					field_order[field_order.length] = 'SI';
					field_order[field_order.length] = 'ODP';
					field_order[field_order.length] = 'DOS';

				}else if (document.getElementById('ioi_global_explorer_from_doctype').value.toUpperCase() == 'IOI PRODUCTION') {

					field_order[field_order.length] = 'ODP';
					field_order[field_order.length] = 'PO';
					field_order[field_order.length] = 'PQ';
					field_order[field_order.length] = 'PR';
					field_order[field_order.length] = 'PI';
					field_order[field_order.length] = 'SO';
					field_order[field_order.length] = 'SQ';
					field_order[field_order.length] = 'SD';
					field_order[field_order.length] = 'SI';
					field_order[field_order.length] = 'PPR';

				}else if (document.getElementById('ioi_global_explorer_from_doctype').value.toUpperCase() == 'IOI DOSSIER') {

					field_order[field_order.length] = 'DOS';
					field_order[field_order.length] = 'PO';
					field_order[field_order.length] = 'PQ';
					field_order[field_order.length] = 'PR';
					field_order[field_order.length] = 'PI';
					field_order[field_order.length] = 'SO';
					field_order[field_order.length] = 'SQ';
					field_order[field_order.length] = 'SD';
					field_order[field_order.length] = 'SI';
					field_order[field_order.length] = 'PPR';

				}

				for (var k = 0; k < field_order.length; k++) {

					if (field_order[k] == 'SI') {

						if (document.getElementById('ioi_global_explorer_si').checked) {

							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si.name';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si_detail.name';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si_detail.idx';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si_detail.item_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si_detail.manufacturer_ref';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si_detail.item_description';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si_detail.invoiced_qty';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si_detail.unit_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si_detail.bi_value_line_sys_currency';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si_detail.item_budget_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si_detail.warehouse_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si_detail.account_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si_detail.analytic1_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si.document_date';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si.period_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si.invoice_customer_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si.bi_total_in_system_currency';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si.total_htva';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si.currency_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si.responsible_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si.customer_family_1_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si.customer_family_2_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si.site_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si.division_id';

						}
					}

					if (field_order[k] == 'SD') {

						if (document.getElementById('ioi_global_explorer_sd').checked) {

							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd.name';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd_detail.name';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd_detail.idx';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd_detail.item_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd_detail.manufacturer_ref';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd_detail.item_description';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd_detail.delivered_qty';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd_detail.reserved_qty';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd_detail.unit_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd_detail.value_line_sys_currency';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd_detail.item_budget_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd_detail.warehouse_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd_detail.account_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd_detail.analytic1_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd.document_date';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd.period_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd.delivery_customer_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd.total_in_system_currency';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd.total_htva';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd.currency_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd.responsible_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd.customer_family_1_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd.customer_family_2_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd.site_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd.division_id';


						}

					}

					if (field_order[k] == 'SO') {

						if (document.getElementById('ioi_global_explorer_so').checked) {

							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so.name';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so_detail.name';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so_detail.idx';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so_detail.item_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so_detail.manufacturer_ref';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so_detail.item_description';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so_detail.ordered_qty';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so_detail.unit_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so_detail.value_line_sys_currency';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so_detail.item_budget_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so_detail.warehouse_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so_detail.account_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so_detail.analytic1_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so.document_date';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so.period_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so.order_customer_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so.total_in_system_currency';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so.total_htva';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so.currency_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so.responsible_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so.customer_family_1_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so.customer_family_2_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so.site_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so.division_id';

						}
					}

					if (field_order[k] == 'SQ') {

						if (document.getElementById('ioi_global_explorer_sq').checked) {

							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq.name';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq_detail.name';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq_detail.idx';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq_detail.item_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq_detail.manufacturer_ref';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq_detail.item_description';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq_detail.ordered_qty';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq_detail.unit_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq_detail.value_line_sys_currency';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq_detail.item_budget_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq_detail.warehouse_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq_detail.account_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq_detail.analytic1_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq.document_date';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq.period_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq.order_customer_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq.total_in_system_currency';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq.total_htva';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq.currency_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq.responsible_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq.customer_family_1_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq.customer_family_2_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq.site_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq.division_id';

						}
					}

					if (field_order[k] == 'PO') {

						if (document.getElementById('ioi_global_explorer_po').checked) {

							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po.name';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po_detail.name';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po_detail.idx';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po_detail.item_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po_detail.manufacturer_ref';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po_detail.item_description';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po_detail.ordered_qty';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po_detail.unit_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po_detail.value_line_sys_currency';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po_detail.budget_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po_detail.warehouse_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po_detail.account_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po_detail.analytic1_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po.document_date';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po.period_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po.order_supplier_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po.total_in_system_currency';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po.total_htva';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po.currency_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po.responsible_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po.supplier_family_1_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po.supplier_family_2_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po.site_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po.division_id';
						}
					}


					if (field_order[k] == 'PQ') {

						if (document.getElementById('ioi_global_explorer_pq').checked) {

							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq.name';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq_detail.name';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq_detail.idx';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq_detail.item_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq_detail.manufacturer_ref';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq_detail.item_description';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq_detail.ordered_qty';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq_detail.unit_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq_detail.value_line_sys_currency';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq_detail.budget_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq_detail.warehouse_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq_detail.account_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq_detail.analytic1_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq.document_date';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq.period_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq.order_supplier_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq.total_in_system_currency';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq.total_htva';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq.currency_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq.responsible_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq.supplier_family_1_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq.supplier_family_2_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq.site_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq.division_id';

						}
					}

					if (field_order[k] == 'PR') {

						if (document.getElementById('ioi_global_explorer_pr').checked) {

							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr.name';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr_detail.name';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr_detail.idx';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr_detail.item_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr_detail.manufacturer_ref';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr_detail.item_description';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr_detail.delivered_qty';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr_detail.unit_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr_detail.value_line_sys_currency';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr_detail.budget_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr_detail.warehouse_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr_detail.account_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr_detail.analytic1_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr.document_date';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr.period_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr.delivery_supplier_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr.total_in_system_currency';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr.total_htva';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr.currency_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr.responsible_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr.supplier_family_1_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr.supplier_family_2_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr.site_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr.division_id';
						}
					}

					if (field_order[k] == 'PI') {

						if (document.getElementById('ioi_global_explorer_pi').checked) {

							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi.name';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi_detail.name';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi_detail.idx';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi_detail.item_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi_detail.manufacturer_ref';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi_detail.item_description';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi_detail.invoiced_qty';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi_detail.unit_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi_detail.bi_value_line_sys_currency';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi_detail.budget_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi_detail.warehouse_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi_detail.account_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi_detail.analytic1_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi.document_date';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi.period_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi.invoice_supplier_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi.bi_total_in_system_currency';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi.total_htva';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi.currency_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi.responsible_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi.supplier_family_1_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi.supplier_family_2_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi.site_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi.division_id';

						}
					}

					if (field_order[k] == 'PPR') {

						if (document.getElementById('ioi_global_explorer_ppr').checked) {

							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'ppr.name';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'ppr.item_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'ppr.manufacturer_ref';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'ppr.item_description';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'ppr.to_order_qty';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'ppr.unit_id';
						}
					}

					if (field_order[k] == 'ODP') {

						if (document.getElementById('ioi_global_explorer_odp').checked) {

							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'odp.name';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'odp_detail.name';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'odp_detail.idx';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'odp_detail.position_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'odp_detail.component_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'odp_detail.description';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'odp_detail.ordered_qty';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'odp_detail.delivered_qty';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'odp_detail.cons_unit_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'odp.item_id';

						}
					}

					if (field_order[k] == 'DOS') {

						if (document.getElementById('ioi_global_explorer_dos').checked) {

							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'dos.name';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'dos_detail.name';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'dos_detail.idx';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'dos_detail.position_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'dos_detail.component_id';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'dos_detail.manufacturer_ref';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'dos_detail.description';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'dos_detail.ordered_qty';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'dos_detail.delivered_qty';
							silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'dos_detail.cons_unit_id';
						}
					}
				}

				if (!silicon_ioi.doctype.ioiGlobalExplorer.tab_fields) {
					silicon_ioi.doctype.ioiGlobalExplorer.tab_fields = [];
				}

				if (silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length == 0) {

					if (document.getElementById('ioi_global_explorer_from_doctype').value.toUpperCase() == 'IOI SALES INVOICE') {

						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si.name';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si_detail.name';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si_detail.idx';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si_detail.item_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si_detail.manufacturer_ref';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si_detail.item_description';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si_detail.invoiced_qty';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si_detail.unit_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si_detail.bi_value_line_sys_currency';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si_detail.item_budget_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si_detail.warehouse_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si_detail.account_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si_detail.analytic1_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si.document_date';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si.period_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si.invoice_customer_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si.bi_total_in_system_currency';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si.total_htva';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si.currency_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si.responsible_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si.customer_family_1_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si.customer_family_2_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si.site_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'si.division_id';



					}else if (document.getElementById('ioi_global_explorer_from_doctype').value.toUpperCase() == 'IOI SALES DELIVERY') {

						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd.name';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd_detail.name';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd_detail.idx';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd_detail.item_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd_detail.manufacturer_ref';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd_detail.item_description';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd_detail.delivered_qty';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd_detail.reserved_qty';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd_detail.unit_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd_detail.value_line_sys_currency';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd_detail.item_budget_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd_detail.warehouse_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd_detail.account_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd_detail.analytic1_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd.document_date';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd.period_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd.delivery_customer_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd.total_in_system_currency';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd.total_htva';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd.currency_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd.responsible_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd.customer_family_1_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd.customer_family_2_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd.site_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sd.division_id';

					}else if (document.getElementById('ioi_global_explorer_from_doctype').value.toUpperCase() == 'IOI SALES ORDER') {

						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so.name';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so_detail.name';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so_detail.idx';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so_detail.item_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so_detail.manufacturer_ref';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so_detail.item_description';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so_detail.ordered_qty';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so_detail.unit_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so_detail.value_line_sys_currency';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so_detail.item_budget_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so_detail.warehouse_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so_detail.account_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so_detail.analytic1_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so.document_date';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so.period_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so.order_customer_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so.total_in_system_currency';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so.total_htva';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so.currency_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so.responsible_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so.customer_family_1_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so.customer_family_2_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so.site_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'so.division_id';



					}else if (document.getElementById('ioi_global_explorer_from_doctype').value.toUpperCase() == 'IOI SALES QUOTE') {

						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq.name';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq_detail.name';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq_detail.idx';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq_detail.item_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq_detail.manufacturer_ref';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq_detail.item_description';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq_detail.ordered_qty';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq_detail.unit_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq_detail.value_line_sys_currency';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq_detail.item_budget_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq_detail.warehouse_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq_detail.account_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq_detail.analytic1_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq.document_date';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq.period_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq.order_customer_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq.total_in_system_currency';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq.total_htva';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq.currency_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq.responsible_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq.customer_family_1_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq.customer_family_2_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq.site_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'sq.division_id';



					}else if (document.getElementById('ioi_global_explorer_from_doctype').value.toUpperCase() == 'IOI PURCHASES INVOICE') {

						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi.name';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi_detail.name';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi_detail.idx';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi_detail.item_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi_detail.manufacturer_ref';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi_detail.item_description';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi_detail.invoiced_qty';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi_detail.unit_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi_detail.bi_value_line_sys_currency';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi_detail.budget_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi_detail.warehouse_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi_detail.account_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi_detail.analytic1_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi.document_date';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi.period_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi.invoice_supplier_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi.bi_total_in_system_currency';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi.total_htva';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi.currency_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi.responsible_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi.supplier_family_1_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi.supplier_family_2_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi.site_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pi.division_id';



					}else if (document.getElementById('ioi_global_explorer_from_doctype').value.toUpperCase() == 'IOI PURCHASES RECEIPT') {

						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr.name';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr_detail.name';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr_detail.idx';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr_detail.item_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr_detail.manufacturer_ref';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr_detail.item_description';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr_detail.delivered_qty';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr_detail.unit_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr_detail.value_line_sys_currency';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr_detail.budget_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr_detail.warehouse_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr_detail.account_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr_detail.analytic1_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr.document_date';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr.period_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr.delivery_supplier_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr.total_in_system_currency';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr.total_htva';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr.currency_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr.responsible_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr.supplier_family_1_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr.supplier_family_2_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr.site_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pr.division_id';



					}else if (document.getElementById('ioi_global_explorer_from_doctype').value.toUpperCase() == 'IOI PURCHASES ORDER') {

						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po.name';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po_detail.name';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po_detail.idx';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po_detail.item_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po_detail.manufacturer_ref';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po_detail.item_description';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po_detail.ordered_qty';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po_detail.unit_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po_detail.value_line_sys_currency';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po_detail.budget_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po_detail.warehouse_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po_detail.account_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po_detail.analytic1_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po.document_date';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po.period_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po.order_supplier_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po.total_in_system_currency';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po.total_htva';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po.currency_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po.responsible_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po.supplier_family_1_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po.supplier_family_2_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po.site_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'po.division_id';



					}else if (document.getElementById('ioi_global_explorer_from_doctype').value.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') {

						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq.name';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq_detail.name';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq_detail.idx';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq_detail.item_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq_detail.manufacturer_ref';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq_detail.item_description';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq_detail.ordered_qty';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq_detail.unit_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq_detail.value_line_sys_currency';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq_detail.budget_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq_detail.warehouse_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq_detail.account_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq_detail.analytic1_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq.document_date';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq.period_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq.order_supplier_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq.total_in_system_currency';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq.total_htva';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq.currency_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq.responsible_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq.supplier_family_1_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq.supplier_family_2_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq.site_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'pq.division_id';


					}else if (document.getElementById('ioi_global_explorer_from_doctype').value.toUpperCase() == 'IOI PURCHASES PROPOSAL INTERNAL') {

						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'ppr.name';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'ppr.item_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'ppr.manufacturer_ref';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'ppr.item_description';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'ppr.to_order_qty';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'ppr.unit_id';

					}else if (document.getElementById('ioi_global_explorer_from_doctype').value.toUpperCase() == 'IOI PRODUCTION') {

						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'odp.name';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'odp_detail.name';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'odp_detail.idx';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'odp_detail.position_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'odp_detail.component_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'odp_detail.description';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'odp_detail.ordered_qty';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'odp_detail.delivered_qty';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'odp_detail.cons_unit_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'odp.item_id';

					}else if (document.getElementById('ioi_global_explorer_from_doctype').value.toUpperCase() == 'IOI DOSSIER') {

						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'dos.name';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'dos_detail.name';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'dos_detail.idx';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'dos_detail.position_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'dos_detail.component_id';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'dos_detail.manufacturer_ref';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'dos_detail.description';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'dos_detail.ordered_qty';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'dos_detail.delivered_qty';
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = 'dos_detail.cons_unit_id';

					}

				}

				if (!silicon_ioi.doctype.ioiGlobalExplorer.tab_fields) {
					silicon_ioi.doctype.ioiGlobalExplorer.tab_fields = [];
				}

				silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby = [];

				for (var z = 0; z < silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length; z++) {
					silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby[silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby.length] = silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[z];


				}



				silicon_ioi.doctype.ioiGlobalExplorer.tab_isformulas = [];

				for (var z = 0; z < silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length; z++) {
					silicon_ioi.doctype.ioiGlobalExplorer.tab_isformulas[silicon_ioi.doctype.ioiGlobalExplorer.tab_isformulas.length] = '0';
				}

				silicon_ioi.doctype.ioiGlobalExplorer.tab_isjumps = [];

				for (var z = 0; z < silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length; z++) {
					silicon_ioi.doctype.ioiGlobalExplorer.tab_isjumps[silicon_ioi.doctype.ioiGlobalExplorer.tab_isjumps.length] = '0';
				}


				silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby_sens = [];

				for (var z = 0; z < silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length; z++) {
					silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby_sens[silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby_sens.length] = '0';
				}

				silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description = [];

				for (var z = 0; z < silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length; z++) {
					silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description[silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description.length] = '';
				}


			}

			silicon_ioi.doctype.ioiGlobalExplorer.tab_fieldtypes = []

			let method = silicon_ioi.doctype.ioiGlobalExplorer.path_jit_engine_link + '.ioi_jit_engine_link_columns_configuration_get_fieldtypes';

			let data = [];

			frappe.call({
				method: method,
				args: {	"tab_fields": silicon_ioi.doctype.ioiGlobalExplorer.tab_fields,
						"tab_isformulas": silicon_ioi.doctype.ioiGlobalExplorer.tab_isformulas
				},
				async: false,
				callback:function(r)	{

					data = r.message;
				}
			});

			for (var z = 0; z < silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length; z++) {

				for (var j = 0; j < data.length; j++) {

					if (data[j].name == silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[z]) {
						silicon_ioi.doctype.ioiGlobalExplorer.tab_fieldtypes[silicon_ioi.doctype.ioiGlobalExplorer.tab_fieldtypes.length] = data[j].fieldtype;
						break;
					}
				}
			}


			if (silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length > 0) {

				if (silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description.length == 0) {

					for (var i = 0; i < silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length; i++) {
						silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description[silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description.length] = '';
					}

				}

				for (var i = 0; i < silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length; i++) {

					let option = document.createElement("option");
					option.value = silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[i];

					if (silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[i] == '0') {
						option.text = silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[i] + ' as ' + silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[i].replaceAll('.', '_');
					}else{
						option.text = silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[i];
					}

					let fct_dbl_click = function() {
						silicon_ioi.doctype.ioiGlobalExplorer.configuration_remove();
					}

					option.ondblclick = fct_dbl_click;


					document.getElementById('html_global_explorer_columns_configuration_selected_fields').add(option);


					let option_fieldtype = document.createElement("option");
					option_fieldtype.value = silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[i];
					option_fieldtype.text = silicon_ioi.doctype.ioiGlobalExplorer.tab_fieldtypes[i];
					document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').add(option_fieldtype);

					let option_jump = document.createElement("option");
					option_jump.value = silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[i];
					option_jump.text = silicon_ioi.doctype.ioiGlobalExplorer.tab_isjumps[i];
					document.getElementById('html_global_explorer_columns_configuration_selected_jumps').add(option_jump);

					let option_kind = document.createElement("option");
					option_kind.value = silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[i];
					option_kind.text = silicon_ioi.doctype.ioiGlobalExplorer.tab_isformulas[i];
					document.getElementById('html_global_explorer_columns_configuration_selected_kinds').add(option_kind);

				}

			}


			if (silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description.length > 0) {

				for (var i = 0; i < silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description.length; i++) {


					let option_f = document.createElement("option");
					option_f.value = silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description[i];
					option_f.text = silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description[i];
					document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description').add(option_f);

				}
			}



			if (silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields.length > 0) {

				for (var i = 0; i < silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields.length; i++) {

					let option_summary_fields = document.createElement("option");
					option_summary_fields.value = silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields[i];
					option_summary_fields.text = silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields[i];
					document.getElementById('html_global_explorer_columns_configuration_summary_fields').add(option_summary_fields);


					let option_summary_action  = document.createElement("option");
					option_summary_action.value = silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action[i];
					option_summary_action.text = silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action[i];
					document.getElementById('html_global_explorer_columns_configuration_summary_action').add(option_summary_action);
				}
			}



			if (silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby.length > 0) {

				for (var i = 0; i < silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby.length; i++) {


					let option_orderby = document.createElement("option");
					option_orderby.value = silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby[i];
					option_orderby.text = silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby[i].replaceAll('.', '_');
					document.getElementById('html_global_explorer_columns_configuration_order_fields').add(option_orderby);

					let option_orderby_sens = document.createElement("option");
					option_orderby_sens.value = silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby[i];
					option_orderby_sens.text = silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby_sens[i];
					document.getElementById('html_global_explorer_columns_configuration_order_sens').add(option_orderby_sens);
				}
			}

			let fct_select = function() {

				document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').selectedIndex = this.selectedIndex;
				document.getElementById('html_global_explorer_columns_configuration_selected_jumps').selectedIndex = this.selectedIndex;
				document.getElementById('html_global_explorer_columns_configuration_selected_kinds').selectedIndex = this.selectedIndex;

				document.getElementById('html_global_explorer_columns_configuration_is_jump').style.display = 'none';
				document.getElementById('html_global_explorer_columns_configuration_is_jump_label').style.display = 'none';
				document.getElementById('html_global_explorer_columns_configuration_is_jump').checked = false;

				document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description').selectedIndex = this.selectedIndex;


				if ((document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').options[document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').selectedIndex].innerText == 'Link') ||
					(document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').options[document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').selectedIndex].innertext == 'Dynamic Link')) {

					document.getElementById('html_global_explorer_columns_configuration_is_jump').style.display = 'block';
					document.getElementById('html_global_explorer_columns_configuration_is_jump_label').style.display = 'block';

					if (document.getElementById('html_global_explorer_columns_configuration_selected_jumps').options[document.getElementById('html_global_explorer_columns_configuration_selected_jumps').selectedIndex].innerText == '1') {
						document.getElementById('html_global_explorer_columns_configuration_is_jump').checked = true;
					}
				}



			}
			document.getElementById('html_global_explorer_columns_configuration_selected_fields').onchange = fct_select;

			document.getElementById('html_global_explorer_columns_configuration_selected_fields').selectedIndex = 0;
			document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').selectedIndex = document.getElementById('html_global_explorer_columns_configuration_selected_fields').selectedIndex;
			document.getElementById('html_global_explorer_columns_configuration_selected_jumps').selectedIndex = document.getElementById('html_global_explorer_columns_configuration_selected_fields').selectedIndex;
			document.getElementById('html_global_explorer_columns_configuration_selected_kinds').selectedIndex = document.getElementById('html_global_explorer_columns_configuration_selected_fields').selectedIndex;
			document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description').selectedIndex = document.getElementById('html_global_explorer_columns_configuration_selected_fields').selectedIndex;

			document.getElementById('html_global_explorer_columns_configuration_is_jump').style.display = 'none';
			document.getElementById('html_global_explorer_columns_configuration_is_jump_label').style.display = 'none';
			document.getElementById('html_global_explorer_columns_configuration_is_jump').checked = false;

			if (document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').options.length > 0) {
				if ((document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').options[document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').selectedIndex].innerText == 'Link') ||
					(document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').options[document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').selectedIndex].innertext == 'Dynamic Link')) {

					document.getElementById('html_global_explorer_columns_configuration_is_jump').style.display = 'block';
					document.getElementById('html_global_explorer_columns_configuration_is_jump_label').style.display = 'block';

					if (document.getElementById('html_global_explorer_columns_configuration_selected_jumps').options[document.getElementById('html_global_explorer_columns_configuration_selected_jumps').selectedIndex].innerText == '1') {
						document.getElementById('html_global_explorer_columns_configuration_is_jump').checked = true;
					}
				}
			}

			let fct_jump_click = function() {

				if (this.checked) {
					document.getElementById('html_global_explorer_columns_configuration_selected_jumps').options[document.getElementById('html_global_explorer_columns_configuration_selected_jumps').selectedIndex].innerText = 1;
				}else{
					document.getElementById('html_global_explorer_columns_configuration_selected_jumps').options[document.getElementById('html_global_explorer_columns_configuration_selected_jumps').selectedIndex].innerText = 0;
				}

			}


			document.getElementById('html_global_explorer_columns_configuration_is_jump').onclick = fct_jump_click;

			let fct_select_order = function() {

				document.getElementById('html_global_explorer_columns_configuration_order_sens').selectedIndex = this.selectedIndex;

				if (document.getElementById('html_global_explorer_columns_configuration_order_sens').options[document.getElementById('html_global_explorer_columns_configuration_order_sens').selectedIndex].innerText == '1') {
					document.getElementById('html_global_explorer_columns_configuration_sort').checked = true;
				}else{
					document.getElementById('html_global_explorer_columns_configuration_sort').checked = false;
				}


			}

			document.getElementById('html_global_explorer_columns_configuration_order_fields').onchange = fct_select_order;

			document.getElementById('html_global_explorer_columns_configuration_order_fields').selectedIndex = 0;
			document.getElementById('html_global_explorer_columns_configuration_order_sens').selectedIndex = document.getElementById('html_global_explorer_columns_configuration_order_fields').selectedIndex;

			if (document.getElementById('html_global_explorer_columns_configuration_order_sens').options.length > 0) {

				if (document.getElementById('html_global_explorer_columns_configuration_order_sens').options[document.getElementById('html_global_explorer_columns_configuration_order_sens').selectedIndex].innerText == '1') {
					document.getElementById('html_global_explorer_columns_configuration_sort').checked = true;
				}else{
					document.getElementById('html_global_explorer_columns_configuration_sort').checked = false;
				}
			}

			let fct_sort_click = function() {

				if (this.checked) {
					document.getElementById('html_global_explorer_columns_configuration_order_sens').options[document.getElementById('html_global_explorer_columns_configuration_order_sens').selectedIndex].innerText = 1;
				}else{
					document.getElementById('html_global_explorer_columns_configuration_order_sens').options[document.getElementById('html_global_explorer_columns_configuration_order_sens').selectedIndex].innerText = 0;
				}

			}


			document.getElementById('html_global_explorer_columns_configuration_sort').onclick = fct_sort_click;

			document.getElementById('html_global_explorer_columns_configuration_where').value = document.getElementById('ioi_global_explorer_where').value;
			document.getElementById('html_global_explorer_columns_configuration_having').value = silicon_ioi.doctype.ioiGlobalExplorer.having;


			let fct_mouseenter = function() {
				this.style.cursor = 'pointer';
			}

			let fct_mouseleave = function() {
				this.style.cursor = 'none';
			}

			let fct_where_switch_click = function() {

				document.getElementById('html_global_explorer_columns_configuration_where_area').style.display = 'none';
				document.getElementById('html_global_explorer_columns_configuration_having_area').style.display = 'block';
			}

			document.getElementById('html_global_explorer_columns_configuration_where_switch_label').onclick = fct_where_switch_click;
			document.getElementById('html_global_explorer_columns_configuration_where_switch_label').onmouseenter = fct_mouseenter;
			document.getElementById('html_global_explorer_columns_configuration_where_switch_label').onmouseleave = fct_mouseleave;

			let fct_having_switch_click = function() {

				document.getElementById('html_global_explorer_columns_configuration_having_area').style.display = 'none';
				document.getElementById('html_global_explorer_columns_configuration_where_area').style.display = 'block';
			}

			document.getElementById('html_global_explorer_columns_configuration_having_switch_label').onclick = fct_having_switch_click;
			document.getElementById('html_global_explorer_columns_configuration_having_switch_label').onmouseenter = fct_mouseenter;
			document.getElementById('html_global_explorer_columns_configuration_having_switch_label').onmouseleave = fct_mouseleave;

			let fct_summary = function() {
				silicon_ioi.doctype.ioiGlobalExplorer.configure_summary()
			}

			document.getElementById('html_global_explorer_columns_configuration_summary').onclick = fct_summary;

		});

	}

	static configure_summary() {

		if (document.getElementById('html_global_explorer_columns_configuration_selected_fields').options.length == 0) {
			frappe.msgprint({title: __("Message"), message: __("Add numeric field or expression before"), indicator: "red"});
			return false;
		}

		let found_one = false;

		for (var i = 0; i <  document.getElementById('html_global_explorer_columns_configuration_selected_fields').options.length; i++) {

			if ((document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').options[i].innerText.toUpperCase() == 'INT') ||
				(document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').options[i].innerText.toUpperCase() == 'FLOAT') ||
				(document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').options[i].innerText.toUpperCase() == 'PERCENT') ||
				(document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').options[i].innerText.toUpperCase() == 'DURATION')) {
				found_one = true;
				break;
			}
		}

		if (!found_one) {
			frappe.msgprint({title: __("Message"), message: __("Add numeric field or expression before"), indicator: "red"});
			return false;
		}

		if (!silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields) {
			silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields = [];
		}

		if (!silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action) {
			silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action = [];
		}

		if (silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields.length == 0) {

			for (var i = 0; i <  document.getElementById('html_global_explorer_columns_configuration_selected_fields').options.length; i++) {

				if ((document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').options[i].innerText.toUpperCase() == 'INT') ||
					(document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').options[i].innerText.toUpperCase() == 'FLOAT') ||
					(document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').options[i].innerText.toUpperCase() == 'PERCENT') ||
					(document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').options[i].innerText.toUpperCase() == 'DURATION')) {

					if (document.getElementById('html_global_explorer_columns_configuration_selected_fields').options[i].value.indexOf(" as ") != -1) {

						let f = document.getElementById('html_global_explorer_columns_configuration_selected_fields').options[i].value.replaceAll(".", "_");
						f = f.substring(f.indexOf(" as ")+4, f.length);
						silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields.length] = f;
					}else{
						silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields.length] = document.getElementById('html_global_explorer_columns_configuration_selected_fields').options[i].value.replaceAll(".", "_");
					}

					silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action[silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action.length] = '0';

				}
			}
		}


		var globalExplorerSummaryForm =  new frappe.ui.Dialog({
			'title': __("Summary"),
			'static': true,
			'fields': [
				{'fieldname': 'html_global_explorer_summary', 'fieldtype': 'HTML'}
			],
			primary_action_label: __('Ok'),
			secondary_action_label: __('Cancel'),
			primary_action: function() {

				silicon_ioi.doctype.ioiGlobalExplorer.summary = 0;

				if (document.getElementById('html_global_explorer_summary_summary').checked) {

					silicon_ioi.doctype.ioiGlobalExplorer.summary = 1;

				}

				if (document.getElementById('html_global_explorer_columns_configuration_summary_fields').options.length > 0) {

					for (var i = document.getElementById('html_global_explorer_columns_configuration_summary_fields').options.length-1; i >= 0; i--) {

						document.getElementById('html_global_explorer_columns_configuration_summary_fields').remove(i);

					}

				}

				if (document.getElementById('html_global_explorer_columns_configuration_summary_action').options.length > 0) {

					for (var i = document.getElementById('html_global_explorer_columns_configuration_summary_action').options.length-1; i >= 0; i--) {

						document.getElementById('html_global_explorer_columns_configuration_summary_action').remove(i);

					}
				}

				silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields = []

				for (var i = 0; i < document.getElementById('html_global_explorer_summary_fields').options.length; i++) {

					let option_summary_fields = document.createElement("option");
					option_summary_fields.value = document.getElementById('html_global_explorer_summary_fields').options[i].value;
					option_summary_fields.text = document.getElementById('html_global_explorer_summary_fields').options[i].innerText;
					document.getElementById('html_global_explorer_columns_configuration_summary_fields').add(option_summary_fields);


					silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields.length] = document.getElementById('html_global_explorer_summary_fields').options[i].value;

					let option_summary_action = document.createElement("option");
					option_summary_action.value = silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action[i];
					option_summary_action.text = silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action[i];
					document.getElementById('html_global_explorer_columns_configuration_summary_action').add(option_summary_action);

				}


				globalExplorerSummaryForm.hide();
			},
			secondary_action: function() {

				globalExplorerSummaryForm.hide();
			}

		});


		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_summary_summary_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_summary_summary');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_summary_fields');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_summary_action_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_summary_action');


		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:400px; width: 100%;">';

		html += '	<div style="position: relative; top: 0px; left: 0px; width:300px;">';
		html += '		<input type="checkbox" id="html_global_explorer_summary_summary" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="html_global_explorer_summary_summary_label" style="position: absolute; top: 6px; left: 30px;">' + __("Show summary") + '</label>';
		html += '	</div>';

		html += '	<div style="position: relative; top: 40px; left: 0px; width:390px;">';
		html += '		<div class="control-input" style="position: absolute; top: 0px; left:2px; width: 390px; height: 30px;" > ';
		html += '			<select id="html_global_explorer_summary_fields" class="input-with-feedback form-control bold" size="15"> ';
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		html += '	<div style="position: relative; top: 310px; left: 0px; width:390px;">';
		html += '		<label id="html_global_explorer_summary_action_label" style="position: absolute; top: 0px; left: 2px;">' + __("Action for the selected field") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 390px; height: 30px;"> ';
		html += '			<select id="html_global_explorer_summary_action" class="input-with-feedback form-control bold"> ';
		html += "				<option value='0'>" + __("Nothing") + "</option>"
		html += "				<option value='1'>" + __("Sum") + "</option>"
		html += "				<option value='2'>" + __("Min") + "</option>"
		html += "				<option value='3'>" + __("Max") + "</option>"
		html += "				<option value='4'>" + __("Avg") + "</option>"
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		html += '</div>';


		globalExplorerSummaryForm.fields_dict.html_global_explorer_summary.$wrapper.html(html);
		globalExplorerSummaryForm.$wrapper.find('.modal-dialog').css("max-width", "440px").css("width", "440px");
		globalExplorerSummaryForm.show();

		silicon_ioi.doctype.ioiGlobalExplorer.sleep_static(400).then(() => {

			document.getElementById('html_global_explorer_summary_summary').checked = false;

			if (silicon_ioi.doctype.ioiGlobalExplorer.summary == 1) {
				document.getElementById('html_global_explorer_summary_summary').checked = true;
			}

			for (var i = 0; i < silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields.length; i++) {

				let option = document.createElement("option");
				option.value = silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields[i]
				option.text = silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields[i];
				document.getElementById('html_global_explorer_summary_fields').add(option);

			}

			document.getElementById('html_global_explorer_summary_fields').selectedIndex = 0;

			document.getElementById('html_global_explorer_summary_action').value = silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action[0];

			let fct_change = function() {

				let idx = document.getElementById('html_global_explorer_summary_fields').selectedIndex;
				silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action[idx] = this.value;

			}

			document.getElementById('html_global_explorer_summary_action').onchange = fct_change;

			let fct_combofield_change = function() {

				document.getElementById('html_global_explorer_summary_action').value = silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action[this.selectedIndex]

			}

			document.getElementById('html_global_explorer_summary_fields').onchange = fct_combofield_change;

		});




	}

	static get_sql() {

		let data = {};

		let method = silicon_ioi.doctype.ioiGlobalExplorer.path_jit_engine_link + '.ioi_jit_engine_link_explorer_can_get_sql'

		frappe.call({
			method: method,
			args: {
			},
			async: false,
			callback:function(r)	{
				data = r.message;
			}
		});

		if (data.error == 1) {
			frappe.msgprint({title: __("Message"), message: data.error_msg, indicator: "red"});
			return false;
		}



		silicon_ioi.doctype.ioiGlobalExplorer.tab_fields = [];
		silicon_ioi.doctype.ioiGlobalExplorer.tab_fieldtypes = [];
		silicon_ioi.doctype.ioiGlobalExplorer.tab_isjumps = [];
		silicon_ioi.doctype.ioiGlobalExplorer.tab_isformulas = [];
		silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description = []

		silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby = [];
		silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby_sens = [];
		silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields = [];
		silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action = [];




		for (var i = 0; i < document.getElementById('html_global_explorer_columns_configuration_selected_fields').options.length; i++) {

			let fn = document.getElementById('html_global_explorer_columns_configuration_selected_fields').options[i].value;
			fn = fn.substring(0, fn.indexOf('.')).toUpperCase();

			if ((fn == 'SQ') || (fn == 'SQ_DETAIL') || (fn == 'SQ_IOICUSTOMER_ORDER') || (fn == 'SQ_IOICUSTOMER_DELIVERY') || (fn == 'SQ_IOICUSTOMER_INVOICE') ||
				(fn == 'SQ_DETAIL_IOIITEM') || (fn == 'SQ_DETAIL_IOIMANUFACTURERCATALOG')) {
				document.getElementById('ioi_global_explorer_sq').checked = true;
			}else if ((fn == 'SO') || (fn == 'SO_DETAIL') || (fn == 'SO_IOICUSTOMER_ORDER') || (fn == 'SO_IOICUSTOMER_DELIVERY') || (fn == 'SO_IOICUSTOMER_INVOICE') ||
					  (fn == 'SO_DETAIL_IOIITEM') || (fn == 'SO_DETAIL_IOIMANUFACTURERCATALOG')) {
				document.getElementById('ioi_global_explorer_so').checked = true;
			}else if ((fn == 'SD') || (fn == 'SD_DETAIL') || (fn == 'DQ_IOICUSTOMER_ORDER') || (fn == 'SD_IOICUSTOMER_DELIVERY') || (fn == 'SD_IOICUSTOMER_INVOICE') ||
					  (fn == 'SD_DETAIL_IOIITEM') || (fn == 'SD_DETAIL_IOIMANUFACTURERCATALOG')) {
				document.getElementById('ioi_global_explorer_sd').checked = true;
			}else if ((fn == 'SI') || (fn == 'SI_DETAIL') || (fn == 'SI_IOICUSTOMER_ORDER') || (fn == 'SI_IOICUSTOMER_DELIVERY') || (fn == 'SI_IOICUSTOMER_INVOICE') ||
					  (fn == 'SI_DETAIL_IOIITEM') || (fn == 'SI_DETAIL_IOIMANUFACTURERCATALOG')) {
				document.getElementById('ioi_global_explorer_si').checked = true;
			}else if ((fn == 'PQ') || (fn == 'PQ_DETAIL') || (fn == 'PQ_IOICUSTOMER_ORDER') || (fn == 'PQ_IOICUSTOMER_DELIVERY') || (fn == 'PQ_IOICUSTOMER_INVOICE') ||
					  (fn == 'PQ_DETAIL_IOIITEM') || (fn == 'PQ_DETAIL_IOIMANUFACTURERCATALOG')) {
				document.getElementById('ioi_global_explorer_pq').checked = true;
			}else if ((fn == 'PO') || (fn == 'PO_DETAIL') || (fn == 'PO_IOISUPPLIER_ORDER') || (fn == 'PO_IOISUPPLIER_DELIVERY') || (fn == 'PO_IOISUPPLIER_INVOICE') ||
					  (fn == 'PO_DETAIL_IOIITEM') || (fn == 'PO_DETAIL_IOIMANUFACTURERCATALOG')) {
				document.getElementById('ioi_global_explorer_po').checked = true;
			}else if ((fn == 'PR') || (fn == 'PR_DETAIL') || (fn == 'PR_IOISUPPLIER_ORDER') || (fn == 'PR_IOISUPPLIER_DELIVERY') || (fn == 'PR_IOISUPPLIER_INVOICE') ||
					  (fn == 'PR_DETAIL_IOIITEM') || (fn == 'PR_DETAIL_IOIMANUFACTURERCATALOG')) {
				document.getElementById('ioi_global_explorer_pr').checked = true;
			}else if ((fn == 'PI') || (fn == 'PI_DETAIL') || (fn == 'PI_IOISUPPLIER_ORDER') || (fn == 'PI_IOISUPPLIER_DELIVERY') || (fn == 'PI_IOISUPPLIER_INVOICE') ||
					  (fn == 'PI_DETAIL_IOIITEM') || (fn == 'PI_DETAIL_IOIMANUFACTURERCATALOG')) {
				document.getElementById('ioi_global_explorer_pi').checked = true;
			}else if ((fn == 'PPR') || (fn == 'PPR_IOISUPPLIER') || (fn == 'PPR_IOIITEM') || (fn == 'PPR_IOIMANUFACTURERCATALOG')) {
				document.getElementById('ioi_global_explorer_ppr').checked = true;
			}else if ((fn == 'ODP') || (fn == 'ODP_DETAIL') || (fn == 'ODP_IOIITEM') || (fn == 'ODP_DETAIL_IOIITEM') || (fn == 'ODP2_IOIITEM')) {
				document.getElementById('ioi_global_explorer_odp').checked = true;
			}else if ((fn == 'DOS') || (fn == 'DOS_DETAIL') || (fn == 'DOS_IOICUSTOMER_ORDER') || (fn == 'DOS_IOICUSTOMER_DELIVERY') || (fn == 'DOS_IOICUSTOMER_INVOICE') ||
					  (fn == 'DOS_IOIITEM') || (fn == 'DOS_DETAIL_IOIITEM') || (fn == 'DOS_DETAIL_IOIMANUFACTURERCATALOG')) {
				document.getElementById('ioi_global_explorer_dos').checked = true;
			}



			silicon_ioi.doctype.ioiGlobalExplorer.tab_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_fields.length] = document.getElementById('html_global_explorer_columns_configuration_selected_fields').options[i].value;
			silicon_ioi.doctype.ioiGlobalExplorer.tab_fieldtypes[silicon_ioi.doctype.ioiGlobalExplorer.tab_fieldtypes.length] = document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').options[i].innerText;
			silicon_ioi.doctype.ioiGlobalExplorer.tab_isjumps[silicon_ioi.doctype.ioiGlobalExplorer.tab_isjumps.length] = document.getElementById('html_global_explorer_columns_configuration_selected_jumps').options[i].innerText;
			silicon_ioi.doctype.ioiGlobalExplorer.tab_isformulas[silicon_ioi.doctype.ioiGlobalExplorer.tab_isformulas.length] = document.getElementById('html_global_explorer_columns_configuration_selected_kinds').options[i].innerText;
			silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description[silicon_ioi.doctype.ioiGlobalExplorer.tab_formulas_description.length] = document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description').options[i].innerText;

			silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields.length] = document.getElementById('html_global_explorer_columns_configuration_summary_fields').options[i].innerText;
			silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action[silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action.length] = document.getElementById('html_global_explorer_columns_configuration_summary_action').options[i].innerText;


		}

		for (var i = 0; i < document.getElementById('html_global_explorer_columns_configuration_order_fields').options.length; i++) {

			silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby[silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby.length] = document.getElementById('html_global_explorer_columns_configuration_order_fields').options[i].value;
			silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby_sens[silicon_ioi.doctype.ioiGlobalExplorer.tab_orderby_sens.length] = document.getElementById('html_global_explorer_columns_configuration_order_sens').options[i].innerText;
		}

		document.getElementById('ioi_global_explorer_where').value = document.getElementById('html_global_explorer_columns_configuration_where').value;
		silicon_ioi.doctype.ioiGlobalExplorer.where = document.getElementById('html_global_explorer_columns_configuration_where').value;

		silicon_ioi.doctype.ioiGlobalExplorer.having = document.getElementById('html_global_explorer_columns_configuration_having').value;



		silicon_ioi.doctype.ioiGlobalExplorer.refresh(true);

		let title = __("SQL script")

		var globalExplorerSQLForm =  new frappe.ui.Dialog({
			'title': title,
			'static': true,
			'fields': [
				{'fieldname': 'html_global_explorer_sql', 'fieldtype': 'HTML'}
			],
			primary_action_label: __('Ok'),
			primary_action: function() {

				globalExplorerSQLForm.hide();
			}

		});

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('ioi_global_explorer_sql_script');


		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:600px; width: 100%;">';

		html += '	<div style="position: relative; top: 0px; left: 0px; width:950px;">';
		html += '		<div class="control-input" style="position: absolute; top: 0px; left: 0px; width: 950px; height: 25px;"> ';
		html += '			<textarea id="ioi_global_explorer_sql_script" style="position: absolute; top: 0px; left: 2px; width: 950px; height: 590px; resize: none;" class="input-with-feedback form-control " >';
		html += '			</textarea>';
		html += '		</div>';
		html += '	</div>';

		html += '</div>';


		globalExplorerSQLForm.fields_dict.html_global_explorer_sql.$wrapper.html(html);
		globalExplorerSQLForm.$wrapper.find('.modal-dialog').css("max-width", "1000px").css("width", "1000px");
		globalExplorerSQLForm.show();

		silicon_ioi.doctype.ioiGlobalExplorer.sleep_static(1000).then(() => {

			document.getElementById('ioi_global_explorer_sql_script').value = silicon_ioi.doctype.ioiGlobalExplorer.sql_script;
			document.getElementById('ioi_global_explorer_sql_script').readOnly = true;
		});
	}


	static load_fields_from_doctype()
	{
		silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctype_fields = [];

		if (document.getElementById('html_global_explorer_columns_configuration_doctype')) {

			if (document.getElementById('html_global_explorer_columns_configuration_doctype').value.trim() != '') {

				let method = silicon_ioi.doctype.ioiGlobalExplorer.path_jit_engine_link + '.ioi_ji_engine_link_columns_configuration_get_doctype_fields';

				frappe.call({
					method: method,
					args: {	"doctype": document.getElementById('html_global_explorer_columns_configuration_doctype').value
					},
					async: false,
					callback:function(r)	{

						silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctype_fields = r.message;
					}
				});

				for (var i = document.getElementById('html_global_explorer_columns_configuration_doctype_fields').options.length-1; i >= 0; i--) {
					document.getElementById('html_global_explorer_columns_configuration_doctype_fields').options[i].remove();
				}

				if (silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctype_fields.length > 0) {

					for (var i = 0; i < silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctype_fields.length; i++) {

						let option = document.createElement("option");
						option.value = silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctype_fields[i].fieldname;
						option.text = silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctype_fields[i].description;

						let fct_dbl_click = function() {
							silicon_ioi.doctype.ioiGlobalExplorer.configuration_add();
						}

						option.ondblclick = fct_dbl_click;

						document.getElementById('html_global_explorer_columns_configuration_doctype_fields').add(option);
					}

				}
			}
		}
	}

	static configuration_add()
	{
		if (document.getElementById('html_global_explorer_columns_configuration_doctype_fields').options.length == 0) {
			return false;
		}

		if (document.getElementById('html_global_explorer_columns_configuration_doctype_fields').selectedIndex == -1) {
			return false;
		}


		let current_field = document.getElementById('html_global_explorer_columns_configuration_doctype_fields').value

		let current_doctype = document.getElementById('html_global_explorer_columns_configuration_doctype').value;


		let current_prefix = '';

		for (var i = 0; i < silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes.length; i++) {

			if (silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[i][0] == current_doctype) {
				current_prefix = silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[i][2];
				break;
			}
		}


		let found = false;

		for (var i = 0; i < document.getElementById('html_global_explorer_columns_configuration_selected_fields').options.length; i++) {
			if ((current_prefix + '.' + current_field) == document.getElementById('html_global_explorer_columns_configuration_selected_fields').options[i].value) {
				found = true;
				break;
			}
		}

		if (!found) {

			let option = document.createElement("option");
			option.value = current_prefix + '.' + current_field;
			option.text = current_prefix + '.' + current_field + ' as ' + current_prefix + '_' + current_field;

			let fct_dbl_click = function() {
				silicon_ioi.doctype.ioiGlobalExplorer.configuration_remove();
			}

			option.ondblclick = fct_dbl_click;

			document.getElementById('html_global_explorer_columns_configuration_selected_fields').add(option);

			for (var i = 0; i < silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctype_fields.length; i++) {

				if (silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctype_fields[i].fieldname == current_field) {

					let option_fieldtype = document.createElement("option");
					option_fieldtype.value = current_prefix + '.' + current_field;
					option_fieldtype.text = silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctype_fields[i].fieldtype;
					document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').add(option_fieldtype);


					if ((silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctype_fields[i].fieldtype.toUpperCase() == 'INT') ||
						(silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctype_fields[i].fieldtype.toUpperCase() == 'FLOAT') ||
						(silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctype_fields[i].fieldtype.toUpperCase() == 'DURATION') ||
						(silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctype_fields[i].fieldtype.toUpperCase() == 'PERCENT')) {

						let option_summary_fields = document.createElement("option");
						option_summary_fields.value = current_prefix + '_' + current_field;
						option_summary_fields.text = current_prefix + '_' + current_field;
						document.getElementById('html_global_explorer_columns_configuration_summary_fields').add(option_summary_fields);

						if (!silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields) {
							silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields = [];
						}

						silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields.length] = current_prefix + '_' + current_field;

						let option_summary_action = document.createElement("option");
						option_summary_action.value = current_prefix + '_' + current_field;
						option_summary_action.text = '0';
						document.getElementById('html_global_explorer_columns_configuration_summary_action').add(option_summary_action);


						if (!silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action) {
							silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action = [];
						}


						silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action[silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action.length] = 0;

					}
					break;


				}
			}

			let option_jump = document.createElement("option");
			option_jump.value = current_prefix + '.' + current_field;
			option_jump.text = '0';
			document.getElementById('html_global_explorer_columns_configuration_selected_jumps').add(option_jump);

			let option_kind = document.createElement("option");
			option_kind.value = current_prefix + '.' + current_field;
			option_kind.text = '0';
			document.getElementById('html_global_explorer_columns_configuration_selected_kinds').add(option_kind);


			let option_fdesc = document.createElement("option");
			option_fdesc.value = current_prefix + '.' + current_field;
			option_fdesc.text = '';
			document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description').add(option_fdesc);

			let option_orderby = document.createElement("option");
			option_orderby.value = current_prefix + '.' + current_field;
			option_orderby.text = current_prefix + '_' + current_field;
			document.getElementById('html_global_explorer_columns_configuration_order_fields').add(option_orderby);

			let option_orderby_sens = document.createElement("option");
			option_orderby_sens.value = current_prefix + '.' + current_field;
			option_orderby_sens.text = '0';
			document.getElementById('html_global_explorer_columns_configuration_order_sens').add(option_orderby_sens);


			let idx = document.getElementById('html_global_explorer_columns_configuration_selected_fields').options.length-1;
			document.getElementById('html_global_explorer_columns_configuration_selected_fields').selectedIndex = idx;
			document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').selectedIndex = idx;
			document.getElementById('html_global_explorer_columns_configuration_selected_jumps').selectedIndex = idx;
			document.getElementById('html_global_explorer_columns_configuration_selected_kinds').selectedIndex = idx;
			document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description').selectedIndex = idx;
			document.getElementById('html_global_explorer_columns_configuration_order_fields').selectedIndex = idx;
			document.getElementById('html_global_explorer_columns_configuration_order_sens').selectedIndex = idx;


			document.getElementById('html_global_explorer_columns_configuration_is_jump').style.display = 'none';
			document.getElementById('html_global_explorer_columns_configuration_is_jump_label').style.display = 'none';
			document.getElementById('html_global_explorer_columns_configuration_is_jump').checked = false;


			if ((document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').options[document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').selectedIndex].innerText == 'Link') ||
				(document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').options[document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').selectedIndex].innertext == 'Dynamic Link')) {

				document.getElementById('html_global_explorer_columns_configuration_is_jump').style.display = 'block';
				document.getElementById('html_global_explorer_columns_configuration_is_jump_label').style.display = 'block';

				if (document.getElementById('html_global_explorer_columns_configuration_selected_jumps').options[document.getElementById('html_global_explorer_columns_configuration_selected_jumps').selectedIndex].innerText == '1') {
					document.getElementById('html_global_explorer_columns_configuration_is_jump').checked = true;
				}
			}


			document.getElementById('html_global_explorer_columns_configuration_sort').checked = false;
		}

	}

	static configuration_remove()
	{
		if (document.getElementById('html_global_explorer_columns_configuration_selected_fields').options.length == 0) {
			return false;
		}

		if (document.getElementById('html_global_explorer_columns_configuration_selected_fields').selectedIndex == -1) {
			return false;
		}



		let current_selected_field = document.getElementById('html_global_explorer_columns_configuration_selected_fields').value;

		let idx = -1;

		for (var i = 0; i < document.getElementById('html_global_explorer_columns_configuration_selected_fields').options.length; i++) {

			if (document.getElementById('html_global_explorer_columns_configuration_selected_fields').options[i].value == current_selected_field) {

				let kind = document.getElementById('html_global_explorer_columns_configuration_selected_kinds').options[i].innerText;

				document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').remove(i);
				document.getElementById('html_global_explorer_columns_configuration_selected_jumps').remove(i);
				document.getElementById('html_global_explorer_columns_configuration_selected_kinds').remove(i);
				document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description').remove(i);

				for (var k=0; k < document.getElementById('html_global_explorer_columns_configuration_order_fields').options.length; k++) {


					if (parseInt(kind) == 0) {

						if (document.getElementById('html_global_explorer_columns_configuration_order_fields').options[k].value == document.getElementById('html_global_explorer_columns_configuration_selected_fields').options[i].value) {

							document.getElementById('html_global_explorer_columns_configuration_order_fields').remove(k);
							document.getElementById('html_global_explorer_columns_configuration_order_sens').remove(k);
							break;
						}
					}else{
						let s = document.getElementById('html_global_explorer_columns_configuration_selected_fields').options[i].value;

						s = s.substring(s.indexOf(' as ')+4, s.length)


						if (document.getElementById('html_global_explorer_columns_configuration_order_fields').options[k].value == s) {

							document.getElementById('html_global_explorer_columns_configuration_order_fields').remove(k);
							document.getElementById('html_global_explorer_columns_configuration_order_sens').remove(k);
							break;
						}

					}
				}


				for (var k = document.getElementById('html_global_explorer_columns_configuration_summary_fields').options.length-1; k >= 0; k--) {

					if (document.getElementById('html_global_explorer_columns_configuration_summary_fields').options[k].value == document.getElementById('html_global_explorer_columns_configuration_selected_fields').options[i].value.replaceAll(".", "_")) {
						document.getElementById('html_global_explorer_columns_configuration_summary_fields').remove(k);
						document.getElementById('html_global_explorer_columns_configuration_summary_action').remove(k);

						silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields.splice(k, 1);
						silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action.splice(k, 1);

						break;
					}
				}

				document.getElementById('html_global_explorer_columns_configuration_selected_fields').remove(i);


				idx = i;
				break;
			}
		}


		document.getElementById('html_global_explorer_columns_configuration_is_jump').style.display = 'none';
		document.getElementById('html_global_explorer_columns_configuration_is_jump_label').style.display = 'none';
		document.getElementById('html_global_explorer_columns_configuration_is_jump').checked = false;
		document.getElementById('html_global_explorer_columns_configuration_sort').checked = false;

		if (document.getElementById('html_global_explorer_columns_configuration_selected_fields').options.length != 0) {

			if (idx != -1) {
				document.getElementById('html_global_explorer_columns_configuration_selected_fields').selectedIndex = idx;
				document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').selectedIndex = idx;
				document.getElementById('html_global_explorer_columns_configuration_selected_jumps').selectedIndex = idx;
				document.getElementById('html_global_explorer_columns_configuration_selected_kinds').selectedIndex = idx;
				document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description').selectedIndex = idx;
				document.getElementById('html_global_explorer_columns_configuration_order_fields').selectedIndex = idx;
				document.getElementById('html_global_explorer_columns_configuration_order_sens').selectedIndex = idx;


				document.getElementById('html_global_explorer_columns_configuration_is_jump').style.display = 'none';
				document.getElementById('html_global_explorer_columns_configuration_is_jump_label').style.display = 'none';
				document.getElementById('html_global_explorer_columns_configuration_is_jump').checked = false;


				if ((document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').options[document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').selectedIndex].innerText == 'Link') ||
					(document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').options[document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').selectedIndex].innertext == 'Dynamic Link')) {

					document.getElementById('html_global_explorer_columns_configuration_is_jump').style.display = 'block';
					document.getElementById('html_global_explorer_columns_configuration_is_jump_label').style.display = 'block';

					if (document.getElementById('html_global_explorer_columns_configuration_selected_jumps').options[document.getElementById('html_global_explorer_columns_configuration_selected_jumps').selectedIndex].innerText == '1') {
						document.getElementById('html_global_explorer_columns_configuration_is_jump').checked = true;
					}
				}

				if (document.getElementById('html_global_explorer_columns_configuration_order_sens').options[document.getElementById('html_global_explorer_columns_configuration_order_sens').selectedIndex].innerText == '1') {
					document.getElementById('html_global_explorer_columns_configuration_sort').checked = true;
				}else{
					document.getElementById('html_global_explorer_columns_configuration_sort').checked = false;
				}

			}
		}

	}

	static configuration_add_all()
	{
		if (document.getElementById('html_global_explorer_columns_configuration_doctype_fields').options.length == 0)	{
			return false;
		}


		for (var k = 0; k < document.getElementById('html_global_explorer_columns_configuration_doctype_fields').options.length; k++) {


			let current_field = document.getElementById('html_global_explorer_columns_configuration_doctype_fields').options[k].value

			let current_doctype = document.getElementById('html_global_explorer_columns_configuration_doctype').value;


			let current_prefix = '';

			for (var i = 0; i < silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes.length; i++) {

				if (silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[i][0] == current_doctype) {
					current_prefix = silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctypes[i][2];
					break;
				}
			}


			let found = false;

			for (var i = 0; i < document.getElementById('html_global_explorer_columns_configuration_selected_fields').options.length; i++) {
				if ((current_prefix + '.' + current_field) == document.getElementById('html_global_explorer_columns_configuration_selected_fields').options[i].value) {
					found = true;
					break;
				}
			}

			if (!found) {

				let option = document.createElement("option");
				option.value = current_prefix + '.' + current_field;
				option.text = current_prefix + '.' + current_field + ' as ' + current_prefix + '_' + current_field;
				document.getElementById('html_global_explorer_columns_configuration_selected_fields').add(option);

				for (var i = 0; i < silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctype_fields.length; i++) {

					if (silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctype_fields[i].fieldname == current_field) {

						let option_fieldtype = document.createElement("option");
						option_fieldtype.value = current_prefix + '.' + current_field;
						option_fieldtype.text = silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctype_fields[i].fieldtype;
						document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').add(option_fieldtype);

						if ((silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctype_fields[i].fieldtype.toUpperCase() == 'INT') ||
							(silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctype_fields[i].fieldtype.toUpperCase() == 'FLOAT') ||
							(silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctype_fields[i].fieldtype.toUpperCase() == 'DURATION') ||
							(silicon_ioi.doctype.ioiGlobalExplorer.configuration_doctype_fields[i].fieldtype.toUpperCase() == 'PERCENT')) {

							let option_summary_fields = document.createElement("option");
							option_summary_fields.value = current_prefix + '_' + current_field;
							option_summary_fields.text = current_prefix + '_' + current_field;
							document.getElementById('html_global_explorer_columns_configuration_summary_fields').add(option_summary_fields);

							if (!silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields) {
								silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields = [];
							}

							silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields.length] = current_prefix + '_' + current_field;

							let option_summary_action = document.createElement("option");
							option_summary_action.value = current_prefix + '_' + current_field;
							option_summary_action.text = '0';
							document.getElementById('html_global_explorer_columns_configuration_summary_action').add(option_summary_action);

							if (!silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action) {
								silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action = [];
							}

							silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action[silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action.length] = '';

						}


						break;

					}
				}

				let option_jump = document.createElement("option");
				option_jump.value = current_prefix + '.' + current_field;
				option_jump.text = '0';
				document.getElementById('html_global_explorer_columns_configuration_selected_jumps').add(option_jump);

				let option_kind = document.createElement("option");
				option_kind.value = current_prefix + '.' + current_field;
				option_kind.text = '0';
				document.getElementById('html_global_explorer_columns_configuration_selected_kinds').add(option_kind);

				let option_fdesc = document.createElement("option");
				option_fdesc.value = current_prefix + '.' + current_field;
				option_fdesc.text = '';
				document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description').add(option_fdesc);

				let option_orderby = document.createElement("option");
				option_orderby.value = current_prefix + '.' + current_field;
				option_orderby.text = current_prefix + '_' + current_field;
				document.getElementById('html_global_explorer_columns_configuration_order_fields').add(option_orderby);

				let option_orderby_sens = document.createElement("option");
				option_orderby_sens.value = current_prefix + '.' + current_field;
				option_orderby_sens.text = '0';
				document.getElementById('html_global_explorer_columns_configuration_order_sens').add(option_orderby_sens);

			}
		}

		let idx = document.getElementById('html_global_explorer_columns_configuration_selected_fields').options.length-1;
		document.getElementById('html_global_explorer_columns_configuration_selected_fields').selectedIndex = idx;
		document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').selectedIndex = idx;
		document.getElementById('html_global_explorer_columns_configuration_selected_jumps').selectedIndex = idx;
		document.getElementById('html_global_explorer_columns_configuration_selected_kinds').selectedIndex = idx;
		document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description').selectedIndex = idx;
		document.getElementById('html_global_explorer_columns_configuration_order_fields').selectedIndex = idx;
		document.getElementById('html_global_explorer_columns_configuration_order_sens').selectedIndex = idx;


		document.getElementById('html_global_explorer_columns_configuration_is_jump').style.display = 'none';
		document.getElementById('html_global_explorer_columns_configuration_is_jump_label').style.display = 'none';
		document.getElementById('html_global_explorer_columns_configuration_is_jump').checked = false;


		if ((document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').options[document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').selectedIndex].innerText == 'Link') ||
			(document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').options[document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').selectedIndex].innertext == 'Dynamic Link')) {

			document.getElementById('html_global_explorer_columns_configuration_is_jump').style.display = 'block';
			document.getElementById('html_global_explorer_columns_configuration_is_jump_label').style.display = 'block';

			if (document.getElementById('html_global_explorer_columns_configuration_selected_jumps').options[document.getElementById('html_global_explorer_columns_configuration_selected_jumps').selectedIndex].innerText == '1') {
				document.getElementById('html_global_explorer_columns_configuration_is_jump').checked = true;
			}
		}

		document.getElementById('html_global_explorer_columns_configuration_sort').checked = false;

	}

	static configuration_remove_all()
	{
		if (document.getElementById('html_global_explorer_columns_configuration_selected_fields').options.length == 0) {
			return false;
		}



		let msg = __("Remove all fields ?");

		frappe.confirm(	msg,
			() => {

				for (var k = document.getElementById('html_global_explorer_columns_configuration_selected_fields').options.length-1; k >=0 ; k--) {

					document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').remove(k);
					document.getElementById('html_global_explorer_columns_configuration_selected_jumps').remove(k);
					document.getElementById('html_global_explorer_columns_configuration_selected_kinds').remove(k);
					document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description').remove(k);
					document.getElementById('html_global_explorer_columns_configuration_selected_fields').remove(k);
					document.getElementById('html_global_explorer_columns_configuration_order_fields').remove(k);
					document.getElementById('html_global_explorer_columns_configuration_order_sens').remove(k);

				}


				for (var k = document.getElementById('html_global_explorer_columns_configuration_summary_fields').options.length-1; k >= 0; k--) {

					document.getElementById('html_global_explorer_columns_configuration_summary_fields').remove(k);
					document.getElementById('html_global_explorer_columns_configuration_summary_action').remove(k);

				}


				document.getElementById('html_global_explorer_columns_configuration_is_jump').style.display = 'none';
				document.getElementById('html_global_explorer_columns_configuration_is_jump_label').style.display = 'none';
				document.getElementById('html_global_explorer_columns_configuration_is_jump').checked = false;
				document.getElementById('html_global_explorer_columns_configuration_sort').checked = false;

			},
			() => {
			}
		);
	}

	static configuration_add_expression()
	{
		let title = __("Add expression / subquery")

		var globalExplorerNewField =  new frappe.ui.Dialog({
			'title': title,
			'static': true,
			'fields': [
				{'fieldname': 'html_global_explorer_newfield', 'fieldtype': 'HTML'}
			],
			primary_action_label: __('Ok'),
			secondary_action_label: __('Cancel'),
			primary_action: function() {

				let button_ok = document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1];


				button_ok.disabled = true;

				if ((!document.getElementById('html_global_explorer_newfield_column_name').value) ||
					((document.getElementById('html_global_explorer_newfield_column_name').value) && (document.getElementById('html_global_explorer_newfield_column_name').value.trim() == ''))) {
					button_ok.disabled = false;
					frappe.msgprint({title: __("Message"), message: __("Column name is mandatory"), indicator: "red"});
					return false;
				}

				document.getElementById('html_global_explorer_newfield_column_name').value = document.getElementById('html_global_explorer_newfield_column_name').value.toLowerCase();
				let v = document.getElementById('html_global_explorer_newfield_column_name').value;

				if (document.getElementById('html_global_explorer_newfield_fieldtype').value == 1) {

					if (v.length <= 4) {
						button_ok.disabled = false;
						frappe.msgprint({title: __("Message"), message: __("Column name has to begin with and greater than 4 positions") + ' exp_', indicator: "red"});
						return false;
					}

					if (v.substring(0, 4) != 'exp_') {
						button_ok.disabled = false;
						frappe.msgprint({title: __("Message"), message: __("Column name has to begin with") + ' exp_', indicator: "red"});
						return false;
					}

				}else{

					if (v.length <= 4) {
						button_ok.disabled = false;
						frappe.msgprint({title: __("Message"), message: __("Column name has to begin with and greater than 4 positions") + ' sub_', indicator: "red"});
						return false;
					}

					if (v.substring(0, 4) != 'sub_') {
						button_ok.disabled = false;
						frappe.msgprint({title: __("Message"), message: __("Column name has to begin with") + ' sub_', indicator: "red"});
						return false;
					}
				}

				// Check if column name is unique

				for (var i = 0; i < document.getElementById('html_global_explorer_columns_configuration_selected_fields').options.length; i++) {

					let fn = document.getElementById('html_global_explorer_columns_configuration_selected_fields').options[i].value.toLowerCase();
					fn = fn.replaceAll('.', '_')

					if (fn == document.getElementById('html_global_explorer_newfield_column_name').value.toLowerCase()) {
						button_ok.disabled = false;
						frappe.msgprint({title: __("Message"), message: __("Column name already exists"), indicator: "red"});
						return false;
					}
				}

				if ((!document.getElementById('html_global_explorer_newfield_column_desc').value) ||
					((document.getElementById('html_global_explorer_newfield_column_desc').value) && (document.getElementById('html_global_explorer_newfield_column_desc').value.trim() == ''))) {
					button_ok.disabled = false;
					frappe.msgprint({title: __("Message"), message: __("Column description is mandatory"), indicator: "red"});
					return false;
				}

				if ((!document.getElementById('html_global_explorer_newfield_content').value) ||
					((document.getElementById('html_global_explorer_newfield_content').value) && (document.getElementById('html_global_explorer_newfield_content').value.trim() == ''))) {
					button_ok.disabled = false;

					if (document.getElementById('html_global_explorer_newfield_fieldtype').value == 1) {
						frappe.msgprint({title: __("Message"), message: __("Expression is mandatory"), indicator: "red"});
					}else{
						frappe.msgprint({title: __("Message"), message: __("Subquery is mandatory"), indicator: "red"});
					}
					return false;
				}


				let current_field = document.getElementById('html_global_explorer_newfield_column_name').value.toLowerCase()

				let option = document.createElement("option");
				option.value = current_field;
				option.text = '(' + document.getElementById('html_global_explorer_newfield_content').value + ' ) as ' + current_field;

				let fct_dbl_click = function() {
					silicon_ioi.doctype.ioiGlobalExplorer.configuration_remove();
				}

				option.ondblclick = fct_dbl_click;

				document.getElementById('html_global_explorer_columns_configuration_selected_fields').add(option);


				let option_fieldtype = document.createElement("option");
				option_fieldtype.value = current_field;
				option_fieldtype.text = 'Float';
				document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').add(option_fieldtype);

				let option_summary_fields = document.createElement("option");
				option_summary_fields.value = current_field;
				option_summary_fields.text = current_field;
				document.getElementById('html_global_explorer_columns_configuration_summary_fields').add(option_summary_fields);

				if (!silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields) {
					silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields = [];
				}

				silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields.length] = current_field;

				let option_summary_action = document.createElement("option");
				option_summary_action.value = current_field;
				option_summary_action.text = '0';
				document.getElementById('html_global_explorer_columns_configuration_summary_action').add(option_summary_action);

				if (!silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields) {
					silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields = [];
				}

				silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields[silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_fields.length] = current_field;


				let option_jump = document.createElement("option");
				option_jump.value =current_field;
				option_jump.text = '0';
				document.getElementById('html_global_explorer_columns_configuration_selected_jumps').add(option_jump);

				if (!silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action) {
					silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action = [];
				}

				silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action[silicon_ioi.doctype.ioiGlobalExplorer.tab_summary_action.length] = current_field;

				let option_kind = document.createElement("option");
				option_kind.value = current_field;
				option_kind.text = document.getElementById('html_global_explorer_newfield_fieldtype').value;
				document.getElementById('html_global_explorer_columns_configuration_selected_kinds').add(option_kind);

				let option_fdesc = document.createElement("option");
				option_fdesc.value = current_field;
				option_fdesc.text = document.getElementById('html_global_explorer_newfield_column_desc').value;
				document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description').add(option_fdesc);

				let option_orderby = document.createElement("option");
				option_orderby.value = current_field;
				option_orderby.text = current_field;
				document.getElementById('html_global_explorer_columns_configuration_order_fields').add(option_orderby);

				let option_orderby_sens = document.createElement("option");
				option_orderby_sens.value = current_field;
				option_orderby_sens.text = '0';
				document.getElementById('html_global_explorer_columns_configuration_order_sens').add(option_orderby_sens);


				let idx = document.getElementById('html_global_explorer_columns_configuration_selected_fields').options.length-1;
				document.getElementById('html_global_explorer_columns_configuration_selected_fields').selectedIndex = idx;
				document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').selectedIndex = idx;
				document.getElementById('html_global_explorer_columns_configuration_selected_jumps').selectedIndex = idx;
				document.getElementById('html_global_explorer_columns_configuration_selected_kinds').selectedIndex = idx;
				document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description').selectedIndex = idx;
				document.getElementById('html_global_explorer_columns_configuration_order_fields').selectedIndex = idx;
				document.getElementById('html_global_explorer_columns_configuration_order_sens').selectedIndex = idx;


				document.getElementById('html_global_explorer_columns_configuration_is_jump').style.display = 'none';
				document.getElementById('html_global_explorer_columns_configuration_is_jump_label').style.display = 'none';
				document.getElementById('html_global_explorer_columns_configuration_is_jump').checked = false;


				if ((document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').options[document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').selectedIndex].innerText == 'Link') ||
					(document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').options[document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').selectedIndex].innertext == 'Dynamic Link')) {

					document.getElementById('html_global_explorer_columns_configuration_is_jump').style.display = 'block';
					document.getElementById('html_global_explorer_columns_configuration_is_jump_label').style.display = 'block';

					if (document.getElementById('html_global_explorer_columns_configuration_selected_jumps').options[document.getElementById('html_global_explorer_columns_configuration_selected_jumps').selectedIndex].innerText == '1') {
						document.getElementById('html_global_explorer_columns_configuration_is_jump').checked = true;
					}
				}

				document.getElementById('html_global_explorer_columns_configuration_sort').checked = false;

				globalExplorerNewField.hide();
			},
			secondary_action: function(){

				globalExplorerNewField.hide();
			}

		});

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_newfield_fieldtype_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_newfield_fieldtype');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_newfield_column_name_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_newfield_column_name');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_newfield_column_desc_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_newfield_column_desc');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_newfield_expression_remark_label');

		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_newfield_content_label');
		silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_newfield_content');

		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:300px; width: 100%;">';

		// Kind of field
		html += '	<div style="position: relative; top: 0px; left: 0px; width:150px;">';
		html += '		<label id="html_global_explorer_newfield_fieldtype_label" style="position: absolute; top: 0px; left: 2px;"><b>' + __("Kind of field") + '</b></label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 150px; height: 30px;"> ';
		html += '			<select id="html_global_explorer_newfield_fieldtype" class="input-with-feedback form-control bold"> ';
		html += "				<option value='1'>" + __("Expression") 			+ "</option>"
		html += "				<option value='2'>" + __("Subquery") 			+ "</option>"
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		// Column Name
		html += '	<div style="position: relative; top: 0px; left: 160px; width:300px;">';
		html += '		<label id="html_global_explorer_newfield_column_name_label" style="position: absolute; top: 0px; left: 2px;"><b>' + __("Column name") + '</b></label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 300px; height: 25px;"> ';
		html += '			<input id="html_global_explorer_newfield_column_name" type="text" class="input-with-feedback form-control bold" value="" >';
		html += '		</div>';
		html += '	</div>';

		// Column Description
		html += '	<div style="position: relative; top: 0px; left: 470px; width:300px;">';
		html += '		<label id="html_global_explorer_newfield_column_desc_label" style="position: absolute; top: 0px; left: 2px;"><b>' + __("Column description") + '</b></label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 300px; height: 25px;"> ';
		html += '			<input id="html_global_explorer_newfield_column_desc" type="text" class="input-with-feedback form-control bold" value="" >';
		html += '		</div>';
		html += '	</div>';

		// Remark
		html += '	<div style="position: relative; top: 65px; left: 160px; width:500px;">';
		html += '		<label id="html_global_explorer_newfield_expression_remark_label" style="position: absolute; top: 0px; left: 2px;"><font color="blue">' + __("Field has to be between []. For example: [si_detail.unit_price]") + '</font></label>';
		html += '	</div>';

		// Content
		html += '	<div style="position: relative; top: 65px; left: 0px; width:770px;">';
		html += '		<label id="html_global_explorer_newfield_content_label" style="position: absolute; top: 0px; left: 2px;"><b>' + __("Expression") + '</b></label>';
		html += '		<div class="control-input" style="position: absolute; top: 30px; left: 0px; width: 770px; height: 25px;"> ';
		html += '			<textarea id="html_global_explorer_newfield_content" style="position: absolute; top: 0px; left: 2px; width: 770px; height: 200px; resize: none;" class="input-with-feedback form-control " >';
		html += '			</textarea>';
		html += '		</div>';
		html += '	</div>';

		html += '</div>';


		globalExplorerNewField.fields_dict.html_global_explorer_newfield.$wrapper.html(html);
		globalExplorerNewField.$wrapper.find('.modal-dialog').css("max-width", "820px").css("width", "820px");
		globalExplorerNewField.show();

		silicon_ioi.doctype.ioiGlobalExplorer.sleep_static(1000).then(() => {

			let fct_change = function() {

				if (this.value == 1) {
					document.getElementById('html_global_explorer_newfield_content_label').innerHTML = '<b>' + __("Expression") + '</b>';
					document.getElementById('html_global_explorer_newfield_expression_remark_label').style.display = 'block';
				}else{
					document.getElementById('html_global_explorer_newfield_content_label').innerHTML = '<b>' + __("Subquery") + '</b>';
					document.getElementById('html_global_explorer_newfield_expression_remark_label').style.display = 'none';
				}

			};

			document.getElementById('html_global_explorer_newfield_fieldtype').onchange = fct_change;

			document.getElementById('html_global_explorer_newfield_fieldtype').value = 1;


		});


	}

	static configuration_edit_expression()
	{
		if (document.getElementById('html_global_explorer_columns_configuration_selected_kinds').length > 0) {

			if (document.getElementById('html_global_explorer_columns_configuration_selected_kinds').options[document.getElementById('html_global_explorer_columns_configuration_selected_fields').selectedIndex].innerText == '0') {
				frappe.msgprint({title: __("Message"), message: __("The field has to be an expression or a subquery"), indicator: "red"});
				return false;
			}

			let title = __("Edit expression / subquery")

			var globalExplorerNewField =  new frappe.ui.Dialog({
				'title': title,
				'static': true,
				'fields': [
					{'fieldname': 'html_global_explorer_newfield', 'fieldtype': 'HTML'}
				],
				primary_action_label: __('Ok'),
				secondary_action_label: __('Cancel'),
				primary_action: function() {

					let button_ok = document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1];


					button_ok.disabled = true;


					if ((!document.getElementById('html_global_explorer_newfield_column_desc').value) ||
						((document.getElementById('html_global_explorer_newfield_column_desc').value) && (document.getElementById('html_global_explorer_newfield_column_desc').value.trim() == ''))) {
						button_ok.disabled = false;
						frappe.msgprint({title: __("Message"), message: __("Column description is mandatory"), indicator: "red"});
						return false;
					}

					if ((!document.getElementById('html_global_explorer_newfield_content').value) ||
						((document.getElementById('html_global_explorer_newfield_content').value) && (document.getElementById('html_global_explorer_newfield_content').value.trim() == ''))) {
						button_ok.disabled = false;

						if (document.getElementById('html_global_explorer_newfield_fieldtype').value == 1) {
							frappe.msgprint({title: __("Message"), message: __("Expression is mandatory"), indicator: "red"});
						}else{
							frappe.msgprint({title: __("Message"), message: __("Subquery is mandatory"), indicator: "red"});
						}
						return false;
					}

					let current_idx = document.getElementById('html_global_explorer_columns_configuration_selected_fields').selectedIndex;


					let current_value =  '(' + document.getElementById('html_global_explorer_newfield_content').value + ') as ' + document.getElementById('html_global_explorer_newfield_column_name').value;

					document.getElementById('html_global_explorer_columns_configuration_selected_fields').options[current_idx].innerText = current_value;


					document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description').options[current_idx].value = document.getElementById('html_global_explorer_newfield_column_name').value;
					document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description').options[current_idx].text = document.getElementById('html_global_explorer_newfield_column_desc').value;

					globalExplorerNewField.hide();
				},
				secondary_action: function(){

					globalExplorerNewField.hide();
				}

			});

			silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_newfield_fieldtype_label');
			silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_newfield_fieldtype');

			silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_newfield_column_name_label');
			silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_newfield_column_name');

			silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_newfield_column_desc_label');
			silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_newfield_column_desc');

			silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_newfield_expression_remark_label');

			silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_newfield_content_label');
			silicon_ioi.doctype.ioiGlobalExplorer.remove_component('html_global_explorer_newfield_content');

			let html = '';

			html += '<div style="overflow: auto; overflow-x: auto; height:300px; width: 100%;">';

			// Kind of field
			html += '	<div style="position: relative; top: 0px; left: 0px; width:150px;">';
			html += '		<label id="html_global_explorer_newfield_fieldtype_label" style="position: absolute; top: 0px; left: 2px;"><b>' + __("Kind of field") + '</b></label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 150px; height: 30px;"> ';
			html += '			<select id="html_global_explorer_newfield_fieldtype" class="input-with-feedback form-control bold"> ';
			html += "				<option value='1'>" + __("Expression") 			+ "</option>"
			html += "				<option value='2'>" + __("Subquery") 			+ "</option>"
			html += '			</select> ';
			html += '		</div>';
			html += '	</div>';

			// Column Name
			html += '	<div style="position: relative; top: 0px; left: 160px; width:300px;">';
			html += '		<label id="html_global_explorer_newfield_column_name_label" style="position: absolute; top: 0px; left: 2px;"><b>' + __("Column name") + '</b></label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 300px; height: 25px;"> ';
			html += '			<input id="html_global_explorer_newfield_column_name" type="text" class="input-with-feedback form-control bold" value="" >';
			html += '		</div>';
			html += '	</div>';

			// Column Description
			html += '	<div style="position: relative; top: 0px; left: 470px; width:300px;">';
			html += '		<label id="html_global_explorer_newfield_column_desc_label" style="position: absolute; top: 0px; left: 2px;"><b>' + __("Column description") + '</b></label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 300px; height: 25px;"> ';
			html += '			<input id="html_global_explorer_newfield_column_desc" type="text" class="input-with-feedback form-control bold" value="" >';
			html += '		</div>';
			html += '	</div>';

			// Remark
			html += '	<div style="position: relative; top: 65px; left: 160px; width:500px;">';
			html += '		<label id="html_global_explorer_newfield_expression_remark_label" style="position: absolute; top: 0px; left: 2px;"><font color="blue">' + __("Field has to be between []. For example: [si_detail.unit_price]") + '</font></label>';
			html += '	</div>';

			// Content
			html += '	<div style="position: relative; top: 65px; left: 0px; width:770px;">';
			html += '		<label id="html_global_explorer_newfield_content_label" style="position: absolute; top: 0px; left: 2px;"><b>' + __("Expression") + '</b></label>';
			html += '		<div class="control-input" style="position: absolute; top: 30px; left: 0px; width: 770px; height: 25px;"> ';
			html += '			<textarea id="html_global_explorer_newfield_content" style="position: absolute; top: 0px; left: 2px; width: 770px; height: 200px; resize: none;" class="input-with-feedback form-control " >';
			html += '			</textarea>';
			html += '		</div>';
			html += '	</div>';

			html += '</div>';


			globalExplorerNewField.fields_dict.html_global_explorer_newfield.$wrapper.html(html);
			globalExplorerNewField.$wrapper.find('.modal-dialog').css("max-width", "820px").css("width", "820px");
			globalExplorerNewField.show();

			silicon_ioi.doctype.ioiGlobalExplorer.sleep_static(1000).then(() => {

				document.getElementById('html_global_explorer_newfield_fieldtype').disabled = true;
				document.getElementById('html_global_explorer_newfield_column_name').disabled = true;

				document.getElementById('html_global_explorer_newfield_fieldtype').value = document.getElementById('html_global_explorer_columns_configuration_selected_kinds').options[document.getElementById('html_global_explorer_columns_configuration_selected_fields').selectedIndex].innerText;

				let s = document.getElementById('html_global_explorer_columns_configuration_selected_fields').options[document.getElementById('html_global_explorer_columns_configuration_selected_fields').selectedIndex].innerText;


				if (s.indexOf(" as ") != -1) {
					s = s.substring(s.indexOf(" as ")+4, s.length);
				}


				document.getElementById('html_global_explorer_newfield_column_name').value = s;

				s = document.getElementById('html_global_explorer_columns_configuration_selected_fields').options[document.getElementById('html_global_explorer_columns_configuration_selected_fields').selectedIndex].innerText;
				s = s.substring(0, s.indexOf(" as "));

				s = s.substring(0, s.length-1)
				s = s.substring(1, s.length);

				document.getElementById('html_global_explorer_newfield_content').value = s;
				document.getElementById('html_global_explorer_newfield_column_desc').value = document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description').options[document.getElementById('html_global_explorer_columns_configuration_selected_fields').selectedIndex].innerText;

				if (document.getElementById('html_global_explorer_newfield_fieldtype').value == 1) {
					document.getElementById('html_global_explorer_newfield_expression_remark_label').style.display = 'block';
				}else{
					document.getElementById('html_global_explorer_newfield_expression_remark_label').style.display = 'none';
				}

			});
		}


	}

	static configuration_top()
	{
		if (document.getElementById('html_global_explorer_columns_configuration_selected_fields').options.length > 0) {

			if (document.getElementById('html_global_explorer_columns_configuration_selected_fields').selectedIndex != 0) {

				let current_idx = document.getElementById('html_global_explorer_columns_configuration_selected_fields').selectedIndex;

				if (current_idx != -1) {

					let selected_fields_current_value = document.getElementById('html_global_explorer_columns_configuration_selected_fields')[current_idx].value;
					let selected_fields_current_label = document.getElementById('html_global_explorer_columns_configuration_selected_fields')[current_idx].innerText;

					let selected_fieldtypes_current_value = document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[current_idx].value;
					let selected_fieldtypes_current_label = document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[current_idx].innerText;

					let selected_jumps_current_value = document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[current_idx].value;
					let selected_jumps_current_label = document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[current_idx].innerText;

					let selected_kinds_current_value = document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[current_idx].value;
					let selected_kinds_current_label = document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[current_idx].innerText;


					let selected_fdesc_current_value = document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[current_idx].value;
					let selected_fdesc_current_label = document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[current_idx].innerText;


					let prior_idx = 0;

					if (document.getElementById('html_global_explorer_columns_configuration_selected_fields')[prior_idx]) {

						let selected_fields_prior_value = document.getElementById('html_global_explorer_columns_configuration_selected_fields')[prior_idx].value;
						let selected_fields_prior_label = document.getElementById('html_global_explorer_columns_configuration_selected_fields')[prior_idx].innerText;

						let selected_fieldtypes_prior_value = document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[prior_idx].value;
						let selected_fieldtypes_prior_label = document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[prior_idx].innerText;

						let selected_jumps_prior_value = document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[prior_idx].value;
						let selected_jumps_prior_label = document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[prior_idx].innerText;

						let selected_kinds_prior_value = document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[prior_idx].value;
						let selected_kinds_prior_label = document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[prior_idx].innerText;

						let selected_fdesc_prior_value = document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[prior_idx].value;
						let selected_fdesc_prior_label = document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[prior_idx].innerText;



						document.getElementById('html_global_explorer_columns_configuration_selected_fields')[current_idx].value = selected_fields_prior_value;
						document.getElementById('html_global_explorer_columns_configuration_selected_fields')[current_idx].innerText = selected_fields_prior_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_fields')[prior_idx].value = selected_fields_current_value;
						document.getElementById('html_global_explorer_columns_configuration_selected_fields')[prior_idx].innerText = selected_fields_current_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_fields').selectedIndex = prior_idx;


						document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[current_idx].value = selected_fieldtypes_prior_value;
						document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[current_idx].innerText = selected_fieldtypes_prior_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[prior_idx].value = selected_fieldtypes_current_value;
						document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[prior_idx].innerText = selected_fieldtypes_current_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').selectedIndex = prior_idx;


						document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[current_idx].value = selected_jumps_prior_value;
						document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[current_idx].innerText = selected_jumps_prior_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[prior_idx].value = selected_jumps_current_value;
						document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[prior_idx].innerText = selected_jumps_current_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_jumps').selectedIndex = prior_idx;

						document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[current_idx].value = selected_kinds_prior_value;
						document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[current_idx].innerText = selected_kinds_prior_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[prior_idx].value = selected_kinds_current_value;
						document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[prior_idx].innerText = selected_kinds_current_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_kinds').selectedIndex = prior_idx;


						document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[current_idx].value = selected_fdesc_prior_value;
						document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[current_idx].innerText = selected_fdesc_prior_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[prior_idx].value = selected_fdesc_current_value;
						document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[prior_idx].innerText = selected_fdesc_current_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description').selectedIndex = prior_idx;


					}
				}
			}
		}

	}

	static configuration_up()
	{

		if (document.getElementById('html_global_explorer_columns_configuration_selected_fields').options.length > 0) {

			if (document.getElementById('html_global_explorer_columns_configuration_selected_fields').selectedIndex != 0) {

				let current_idx = document.getElementById('html_global_explorer_columns_configuration_selected_fields').selectedIndex;

				if (current_idx != -1) {

					let selected_fields_current_value = document.getElementById('html_global_explorer_columns_configuration_selected_fields')[current_idx].value;
					let selected_fields_current_label = document.getElementById('html_global_explorer_columns_configuration_selected_fields')[current_idx].innerText;

					let selected_fieldtypes_current_value = document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[current_idx].value;
					let selected_fieldtypes_current_label = document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[current_idx].innerText;

					let selected_jumps_current_value = document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[current_idx].value;
					let selected_jumps_current_label = document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[current_idx].innerText;

					let selected_kinds_current_value = document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[current_idx].value;
					let selected_kinds_current_label = document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[current_idx].innerText;

					let selected_fdesc_current_value = document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[current_idx].value;
					let selected_fdesc_current_label = document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[current_idx].innerText;


					let prior_idx = current_idx-1;

					if (document.getElementById('html_global_explorer_columns_configuration_selected_fields')[prior_idx]) {

						let selected_fields_prior_value = document.getElementById('html_global_explorer_columns_configuration_selected_fields')[prior_idx].value;
						let selected_fields_prior_label = document.getElementById('html_global_explorer_columns_configuration_selected_fields')[prior_idx].innerText;

						let selected_fieldtypes_prior_value = document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[prior_idx].value;
						let selected_fieldtypes_prior_label = document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[prior_idx].innerText;

						let selected_jumps_prior_value = document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[prior_idx].value;
						let selected_jumps_prior_label = document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[prior_idx].innerText;

						let selected_kinds_prior_value = document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[prior_idx].value;
						let selected_kinds_prior_label = document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[prior_idx].innerText;

						let selected_fdesc_prior_value = document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[prior_idx].value;
						let selected_fdesc_prior_label = document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[prior_idx].innerText;


						document.getElementById('html_global_explorer_columns_configuration_selected_fields')[current_idx].value = selected_fields_prior_value;
						document.getElementById('html_global_explorer_columns_configuration_selected_fields')[current_idx].innerText = selected_fields_prior_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_fields')[prior_idx].value = selected_fields_current_value;
						document.getElementById('html_global_explorer_columns_configuration_selected_fields')[prior_idx].innerText = selected_fields_current_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_fields').selectedIndex = prior_idx;


						document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[current_idx].value = selected_fieldtypes_prior_value;
						document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[current_idx].innerText = selected_fieldtypes_prior_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[prior_idx].value = selected_fieldtypes_current_value;
						document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[prior_idx].innerText = selected_fieldtypes_current_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').selectedIndex = prior_idx;


						document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[current_idx].value = selected_jumps_prior_value;
						document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[current_idx].innerText = selected_jumps_prior_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[prior_idx].value = selected_jumps_current_value;
						document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[prior_idx].innerText = selected_jumps_current_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_jumps').selectedIndex = prior_idx;

						document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[current_idx].value = selected_kinds_prior_value;
						document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[current_idx].innerText = selected_kinds_prior_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[prior_idx].value = selected_kinds_current_value;
						document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[prior_idx].innerText = selected_kinds_current_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_kinds').selectedIndex = prior_idx;


						document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[current_idx].value = selected_fdesc_prior_value;
						document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[current_idx].innerText = selected_fdesc_prior_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[prior_idx].value = selected_fdesc_current_value;
						document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[prior_idx].innerText = selected_fdesc_current_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description').selectedIndex = prior_idx;

					}
				}
			}
		}
	}

	static configuration_bottom()
	{
		if (document.getElementById('html_global_explorer_columns_configuration_selected_fields').options.length > 0) {

			if (document.getElementById('html_global_explorer_columns_configuration_selected_fields').selectedIndex != document.getElementById('html_global_explorer_columns_configuration_selected_fields').options.length) {

				let current_idx = document.getElementById('html_global_explorer_columns_configuration_selected_fields').selectedIndex;

				if (current_idx != -1) {

					let selected_fields_current_value = document.getElementById('html_global_explorer_columns_configuration_selected_fields')[current_idx].value;
					let selected_fields_current_label = document.getElementById('html_global_explorer_columns_configuration_selected_fields')[current_idx].innerText;

					let selected_fieldtypes_current_value = document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[current_idx].value;
					let selected_fieldtypes_current_label = document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[current_idx].innerText;

					let selected_jumps_current_value = document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[current_idx].value;
					let selected_jumps_current_label = document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[current_idx].innerText;

					let selected_kinds_current_value = document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[current_idx].value;
					let selected_kinds_current_label = document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[current_idx].innerText;

					let selected_fdesc_current_value = document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[current_idx].value;
					let selected_fdesc_current_label = document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[current_idx].innerText;


					let next_idx = document.getElementById('html_global_explorer_columns_configuration_selected_fields').options.length-1;

					if (document.getElementById('html_global_explorer_columns_configuration_selected_fields')[next_idx]) {

						let selected_fields_next_value = document.getElementById('html_global_explorer_columns_configuration_selected_fields')[next_idx].value;
						let selected_fields_next_label = document.getElementById('html_global_explorer_columns_configuration_selected_fields')[next_idx].innerText;

						let selected_fieldtypes_next_value = document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[next_idx].value;
						let selected_fieldtypes_next_label = document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[next_idx].innerText;

						let selected_jumps_next_value = document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[next_idx].value;
						let selected_jumps_next_label = document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[next_idx].innerText;

						let selected_kinds_next_value = document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[next_idx].value;
						let selected_kinds_next_label = document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[next_idx].innerText;

						let selected_fdesc_next_value = document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[next_idx].value;
						let selected_fdesc_next_label = document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[next_idx].innerText;



						document.getElementById('html_global_explorer_columns_configuration_selected_fields')[current_idx].value = selected_fields_next_value
						document.getElementById('html_global_explorer_columns_configuration_selected_fields')[current_idx].innerText = selected_fields_next_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_fields')[next_idx].value = selected_fields_current_value
						document.getElementById('html_global_explorer_columns_configuration_selected_fields')[next_idx].innerText = selected_fields_current_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_fields').selectedIndex = next_idx;


						document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[current_idx].value = selected_fieldtypes_next_value
						document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[current_idx].innerText = selected_fieldtypes_next_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[next_idx].value = selected_fieldtypes_current_value
						document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[next_idx].innerText = selected_fieldtypes_current_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').selectedIndex = next_idx;


						document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[current_idx].value = selected_jumps_next_value
						document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[current_idx].innerText = selected_jumps_next_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[next_idx].value = selected_jumps_current_value
						document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[next_idx].innerText = selected_jumps_current_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_jumps').selectedIndex = next_idx;


						document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[current_idx].value = selected_kinds_next_value
						document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[current_idx].innerText = selected_kinds_next_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[next_idx].value = selected_kinds_current_value
						document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[next_idx].innerText = selected_kinds_current_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_kinds').selectedIndex = next_idx;


						document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[current_idx].value = selected_fdesc_next_value
						document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[current_idx].innerText = selected_fdesc_next_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[next_idx].value = selected_fdesc_current_value
						document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[next_idx].innerText = selected_fdesc_current_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description').selectedIndex = next_idx;





					}
				}
			}
		}

	}

	static configuration_down()
	{
		if (document.getElementById('html_global_explorer_columns_configuration_selected_fields').options.length > 0) {

			if (document.getElementById('html_global_explorer_columns_configuration_selected_fields').selectedIndex != document.getElementById('html_global_explorer_columns_configuration_selected_fields').options.length) {

				let current_idx = document.getElementById('html_global_explorer_columns_configuration_selected_fields').selectedIndex;

				if (current_idx != -1) {

					let selected_fields_current_value = document.getElementById('html_global_explorer_columns_configuration_selected_fields')[current_idx].value;
					let selected_fields_current_label = document.getElementById('html_global_explorer_columns_configuration_selected_fields')[current_idx].innerText;

					let selected_fieldtypes_current_value = document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[current_idx].value;
					let selected_fieldtypes_current_label = document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[current_idx].innerText;

					let selected_jumps_current_value = document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[current_idx].value;
					let selected_jumps_current_label = document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[current_idx].innerText;

					let selected_kinds_current_value = document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[current_idx].value;
					let selected_kinds_current_label = document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[current_idx].innerText;

					let selected_fdesc_current_value = document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[current_idx].value;
					let selected_fdesc_current_label = document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[current_idx].innerText;



					let next_idx = current_idx+1;

					if (document.getElementById('html_global_explorer_columns_configuration_selected_fields')[next_idx]) {

						let selected_fields_next_value = document.getElementById('html_global_explorer_columns_configuration_selected_fields')[next_idx].value;
						let selected_fields_next_label = document.getElementById('html_global_explorer_columns_configuration_selected_fields')[next_idx].innerText;

						let selected_fieldtypes_next_value = document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[next_idx].value;
						let selected_fieldtypes_next_label = document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[next_idx].innerText;

						let selected_jumps_next_value = document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[next_idx].value;
						let selected_jumps_next_label = document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[next_idx].innerText;

						let selected_kinds_next_value = document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[next_idx].value;
						let selected_kinds_next_label = document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[next_idx].innerText;

						let selected_fdesc_next_value = document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[next_idx].value;
						let selected_fdesc_next_label = document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[next_idx].innerText;





						document.getElementById('html_global_explorer_columns_configuration_selected_fields')[current_idx].value = selected_fields_next_value
						document.getElementById('html_global_explorer_columns_configuration_selected_fields')[current_idx].innerText = selected_fields_next_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_fields')[next_idx].value = selected_fields_current_value
						document.getElementById('html_global_explorer_columns_configuration_selected_fields')[next_idx].innerText = selected_fields_current_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_fields').selectedIndex = next_idx;


						document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[current_idx].value = selected_fieldtypes_next_value
						document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[current_idx].innerText = selected_fieldtypes_next_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[next_idx].value = selected_fieldtypes_current_value
						document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes')[next_idx].innerText = selected_fieldtypes_current_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_fieldtypes').selectedIndex = next_idx;


						document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[current_idx].value = selected_jumps_next_value
						document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[current_idx].innerText = selected_jumps_next_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[next_idx].value = selected_jumps_current_value
						document.getElementById('html_global_explorer_columns_configuration_selected_jumps')[next_idx].innerText = selected_jumps_current_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_jumps').selectedIndex = next_idx;


						document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[current_idx].value = selected_kinds_next_value
						document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[current_idx].innerText = selected_kinds_next_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[next_idx].value = selected_kinds_current_value
						document.getElementById('html_global_explorer_columns_configuration_selected_kinds')[next_idx].innerText = selected_kinds_current_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_kinds').selectedIndex = next_idx;



						document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[current_idx].value = selected_fdesc_next_value
						document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[current_idx].innerText = selected_fdesc_next_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[next_idx].value = selected_fdesc_current_value
						document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description')[next_idx].innerText = selected_fdesc_current_label;

						document.getElementById('html_global_explorer_columns_configuration_selected_formulas_description').selectedIndex = next_idx;



					}
				}
			}
		}
	}

	static configuration_order_top()
	{
		if (document.getElementById('html_global_explorer_columns_configuration_order_fields').options.length > 0) {

			if (document.getElementById('html_global_explorer_columns_configuration_order_fields').selectedIndex != 0) {

				let current_idx = document.getElementById('html_global_explorer_columns_configuration_order_fields').selectedIndex;

				if (current_idx != -1) {

					let selected_order_fields_current_value = document.getElementById('html_global_explorer_columns_configuration_order_fields')[current_idx].value;
					let selected_order_fields_current_label = document.getElementById('html_global_explorer_columns_configuration_order_fields')[current_idx].innerText;

					let selected_order_sens_current_value = document.getElementById('html_global_explorer_columns_configuration_order_sens')[current_idx].value;
					let selected_order_sens_current_label = document.getElementById('html_global_explorer_columns_configuration_order_sens')[current_idx].innerText;


					let prior_idx = 0;

					if (document.getElementById('html_global_explorer_columns_configuration_order_fields')[prior_idx]) {

						let selected_order_fields_prior_value = document.getElementById('html_global_explorer_columns_configuration_order_fields')[prior_idx].value;
						let selected_order_fields_prior_label = document.getElementById('html_global_explorer_columns_configuration_order_fields')[prior_idx].innerText;

						let selected_order_sens_prior_value = document.getElementById('html_global_explorer_columns_configuration_order_sens')[prior_idx].value;
						let selected_order_sens_prior_label = document.getElementById('html_global_explorer_columns_configuration_order_sens')[prior_idx].innerText;

						document.getElementById('html_global_explorer_columns_configuration_order_fields')[current_idx].value = selected_order_fields_prior_value;
						document.getElementById('html_global_explorer_columns_configuration_order_fields')[current_idx].innerText = selected_order_fields_prior_label;

						document.getElementById('html_global_explorer_columns_configuration_order_fields')[prior_idx].value = selected_order_fields_current_value;
						document.getElementById('html_global_explorer_columns_configuration_order_fields')[prior_idx].innerText = selected_order_fields_current_label;

						document.getElementById('html_global_explorer_columns_configuration_order_fields').selectedIndex = prior_idx;


						document.getElementById('html_global_explorer_columns_configuration_order_sens')[current_idx].value = selected_order_sens_prior_value;
						document.getElementById('html_global_explorer_columns_configuration_order_sens')[current_idx].innerText = selected_order_sens_prior_label;

						document.getElementById('html_global_explorer_columns_configuration_order_sens')[prior_idx].value = selected_order_sens_current_value;
						document.getElementById('html_global_explorer_columns_configuration_order_sens')[prior_idx].innerText = selected_order_sens_current_label;

						document.getElementById('html_global_explorer_columns_configuration_order_sens').selectedIndex = prior_idx;

					}
				}
			}
		}

	}

	static configuration_order_up()
	{

		if (document.getElementById('html_global_explorer_columns_configuration_order_fields').options.length > 0) {

			if (document.getElementById('html_global_explorer_columns_configuration_order_fields').selectedIndex != 0) {

				let current_idx = document.getElementById('html_global_explorer_columns_configuration_order_fields').selectedIndex;

				if (current_idx != -1) {

					let selected_order_fields_current_value = document.getElementById('html_global_explorer_columns_configuration_order_fields')[current_idx].value;
					let selected_order_fields_current_label = document.getElementById('html_global_explorer_columns_configuration_order_fields')[current_idx].innerText;

					let selected_order_sens_current_value = document.getElementById('html_global_explorer_columns_configuration_order_sens')[current_idx].value;
					let selected_order_sens_current_label = document.getElementById('html_global_explorer_columns_configuration_order_sens')[current_idx].innerText;

					let prior_idx = current_idx-1;

					if (document.getElementById('html_global_explorer_columns_configuration_order_fields')[prior_idx]) {

						let selected_order_fields_prior_value = document.getElementById('html_global_explorer_columns_configuration_order_fields')[prior_idx].value;
						let selected_order_fields_prior_label = document.getElementById('html_global_explorer_columns_configuration_order_fields')[prior_idx].innerText;

						let selected_order_sens_prior_value = document.getElementById('html_global_explorer_columns_configuration_order_sens')[prior_idx].value;
						let selected_order_sens_prior_label = document.getElementById('html_global_explorer_columns_configuration_order_sens')[prior_idx].innerText;


						document.getElementById('html_global_explorer_columns_configuration_order_fields')[current_idx].value = selected_order_fields_prior_value;
						document.getElementById('html_global_explorer_columns_configuration_order_fields')[current_idx].innerText = selected_order_fields_prior_label;

						document.getElementById('html_global_explorer_columns_configuration_order_fields')[prior_idx].value = selected_order_fields_current_value;
						document.getElementById('html_global_explorer_columns_configuration_order_fields')[prior_idx].innerText = selected_order_fields_current_label;

						document.getElementById('html_global_explorer_columns_configuration_order_fields').selectedIndex = prior_idx;


						document.getElementById('html_global_explorer_columns_configuration_order_sens')[current_idx].value = selected_order_sens_prior_value;
						document.getElementById('html_global_explorer_columns_configuration_order_sens')[current_idx].innerText = selected_order_sens_prior_label;

						document.getElementById('html_global_explorer_columns_configuration_order_sens')[prior_idx].value = selected_order_sens_current_value;
						document.getElementById('html_global_explorer_columns_configuration_order_sens')[prior_idx].innerText = selected_order_sens_current_label;

						document.getElementById('html_global_explorer_columns_configuration_order_sens').selectedIndex = prior_idx;

					}
				}
			}
		}
	}

	static configuration_order_bottom()
	{
		if (document.getElementById('html_global_explorer_columns_configuration_order_fields').options.length > 0) {

			if (document.getElementById('html_global_explorer_columns_configuration_order_fields').selectedIndex != document.getElementById('html_global_explorer_columns_configuration_order_fields').options.length) {

				let current_idx = document.getElementById('html_global_explorer_columns_configuration_order_fields').selectedIndex;

				if (current_idx != -1) {

					let selected_order_fields_current_value = document.getElementById('html_global_explorer_columns_configuration_order_fields')[current_idx].value;
					let selected_order_fields_current_label = document.getElementById('html_global_explorer_columns_configuration_order_fields')[current_idx].innerText;

					let selected_order_sens_current_value = document.getElementById('html_global_explorer_columns_configuration_order_sens')[current_idx].value;
					let selected_order_sens_current_label = document.getElementById('html_global_explorer_columns_configuration_order_sens')[current_idx].innerText;


					let next_idx = document.getElementById('html_global_explorer_columns_configuration_order_fields').options.length-1;

					if (document.getElementById('html_global_explorer_columns_configuration_order_fields')[next_idx]) {

						let selected_order_fields_next_value = document.getElementById('html_global_explorer_columns_configuration_order_fields')[next_idx].value;
						let selected_order_fields_next_label = document.getElementById('html_global_explorer_columns_configuration_order_fields')[next_idx].innerText;

						let selected_order_sens_next_value = document.getElementById('html_global_explorer_columns_configuration_order_sens')[next_idx].value;
						let selected_order_sens_next_label = document.getElementById('html_global_explorer_columns_configuration_order_sens')[next_idx].innerText;

						document.getElementById('html_global_explorer_columns_configuration_order_fields')[current_idx].value = selected_order_fields_next_value
						document.getElementById('html_global_explorer_columns_configuration_order_fields')[current_idx].innerText = selected_order_fields_next_label;

						document.getElementById('html_global_explorer_columns_configuration_order_fields')[next_idx].value = selected_order_fields_current_value
						document.getElementById('html_global_explorer_columns_configuration_order_fields')[next_idx].innerText = selected_order_fields_current_label;

						document.getElementById('html_global_explorer_columns_configuration_order_fields').selectedIndex = next_idx;


						document.getElementById('html_global_explorer_columns_configuration_order_sens')[current_idx].value = selected_order_sens_next_value
						document.getElementById('html_global_explorer_columns_configuration_order_sens')[current_idx].innerText = selected_order_sens_next_label;

						document.getElementById('html_global_explorer_columns_configuration_order_sens')[next_idx].value = selected_order_sens_current_value
						document.getElementById('html_global_explorer_columns_configuration_order_sens')[next_idx].innerText = selected_order_sens_current_label;

						document.getElementById('html_global_explorer_columns_configuration_order_sens').selectedIndex = next_idx;


					}
				}
			}
		}

	}

	static configuration_order_down()
	{
		if (document.getElementById('html_global_explorer_columns_configuration_order_fields').options.length > 0) {

			if (document.getElementById('html_global_explorer_columns_configuration_order_fields').selectedIndex != document.getElementById('html_global_explorer_columns_configuration_order_fields').options.length) {

				let current_idx = document.getElementById('html_global_explorer_columns_configuration_order_fields').selectedIndex;

				if (current_idx != -1) {

					let selected_order_fields_current_value = document.getElementById('html_global_explorer_columns_configuration_order_fields')[current_idx].value;
					let selected_order_fields_current_label = document.getElementById('html_global_explorer_columns_configuration_order_fields')[current_idx].innerText;

					let selected_order_sens_current_value = document.getElementById('html_global_explorer_columns_configuration_order_sens')[current_idx].value;
					let selected_order_sens_current_label = document.getElementById('html_global_explorer_columns_configuration_order_sens')[current_idx].innerText;

					let next_idx = current_idx+1;

					if (document.getElementById('html_global_explorer_columns_configuration_order_fields')[next_idx]) {

						let selected_order_fields_next_value = document.getElementById('html_global_explorer_columns_configuration_order_fields')[next_idx].value;
						let selected_order_fields_next_label = document.getElementById('html_global_explorer_columns_configuration_order_fields')[next_idx].innerText;

						let selected_order_sens_next_value = document.getElementById('html_global_explorer_columns_configuration_order_sens')[next_idx].value;
						let selected_order_sens_next_label = document.getElementById('html_global_explorer_columns_configuration_order_sens')[next_idx].innerText;


						document.getElementById('html_global_explorer_columns_configuration_order_fields')[current_idx].value = selected_order_fields_next_value
						document.getElementById('html_global_explorer_columns_configuration_order_fields')[current_idx].innerText = selected_order_fields_next_label;

						document.getElementById('html_global_explorer_columns_configuration_order_fields')[next_idx].value = selected_order_fields_current_value
						document.getElementById('html_global_explorer_columns_configuration_order_fields')[next_idx].innerText = selected_order_fields_current_label;

						document.getElementById('html_global_explorer_columns_configuration_order_fields').selectedIndex = next_idx;


						document.getElementById('html_global_explorer_columns_configuration_order_sens')[current_idx].value = selected_order_sens_next_value
						document.getElementById('html_global_explorer_columns_configuration_order_sens')[current_idx].innerText = selected_order_sens_next_label;

						document.getElementById('html_global_explorer_columns_configuration_order_sens')[next_idx].value = selected_order_sens_current_value
						document.getElementById('html_global_explorer_columns_configuration_order_sens')[next_idx].innerText = selected_order_sens_current_label;

						document.getElementById('html_global_explorer_columns_configuration_order_sens').selectedIndex = next_idx;

					}
				}
			}
		}
	}




	static remove_component(fieldname)
	{
		if (document.getElementById(fieldname)) {
			document.getElementById(fieldname).remove();
		}
	}

	static sleep_static(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}

}

silicon_ioi.doctype.ioiGlobalExplorer = ioiGlobalExplorer;
