frappe.provide('silicon_ioi.doctype');
export class ioiItemStockFrame
{
	
	static path_user = 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user';
	static path_common = 'silicon_ioi.common.common';
	static path_site_stock = 'silicon_ioi.ioi_wms.doctype.ioi_site_stock.ioi_site_stock';
	static path_item = 'silicon_ioi.ioi_items.doctype.ioi_item.ioi_item';

	static is_dark_mode = 0;
	static bgcolor = 0
	static selected_site = ''
	static select_item = '';
	static item_mode = -1;
	static table

	static load_config(item_id = '')
	{
		silicon_ioi.doctype.ioiItemStockFrame.selected_site = ''

		let method = silicon_ioi.doctype.ioiItemStockFrame.path_user + '.ioi_user_get_site'

		frappe.call({  	method: method, 
						args: {},
						async: false,
						callback:function(r) { 	
							silicon_ioi.doctype.ioiItemStockFrame.selected_site = r.message
						}
		});

		silicon_ioi.doctype.ioiItemStockFrame.is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

		if (item_id != '') {

			if (silicon_ioi.doctype.ioiItemStockFrame.item_mode == -1) {

				let method = silicon_ioi.doctype.ioiLocationFrame.path_item + '.ioi_item_get_item';
			
				frappe.call({  	method: method, 
								args: {"item_id": item_id },
								async: false,
								callback:function(r)	{ 
									
									silicon_ioi.doctype.ioiItemStockFrame.item_mode = r.message.mode;
								
									if ((silicon_ioi.doctype.ioiItemStockFrame.item_mode != 3) && (silicon_ioi.doctype.ioiItemStockFrame.item_mode != 4)) {

										if (document.getElementById('location_frame_subdetail_detail_panel')) {
											document.getElementById('location_frame_subdetail_detail_panel').style.display = 'none';
										}

										if (document.getElementById('location_frame_subdetail_filters')) {
											document.getElementById('location_frame_subdetail_filters').style.display = 'none';
										}

										if (document.getElementById('location_frame_subdetail_result')) {
											document.getElementById('location_frame_subdetail_result').style.display = 'none';
										}

										if (document.getElementById('item_stock_frame_batches_tab')) {
											document.getElementById('item_stock_frame_batches_tab').style.display = 'none';
										}

										if (document.getElementById('item_stock_frame_batches_content')) {
											document.getElementById('item_stock_frame_batches_content').style.display = 'none';
										}

									}else{

										if (document.getElementById('location_frame_subdetail_detail_panel')) {
											document.getElementById('location_frame_subdetail_detail_panel').style.display = 'block';
										}

										if (document.getElementById('location_frame_subdetail_filters')) {
											document.getElementById('location_frame_subdetail_filters').style.display = 'block';
										}

										if (document.getElementById('location_frame_subdetail_result')) {
											document.getElementById('location_frame_subdetail_result').style.display = 'block';
										}

										if (document.getElementById('item_stock_frame_batches_tab')) {
											document.getElementById('item_stock_frame_batches_tab').style.display = 'block';
										}
									}
								}
				});
			}
		}else{
			if (document.getElementById('location_frame_subdetail_detail_panel')) {
				document.getElementById('location_frame_subdetail_detail_panel').style.display = 'none';
			}

			if (document.getElementById('location_frame_subdetail_filters')) {
				document.getElementById('location_frame_subdetail_filters').style.display = 'none';
			}

			if (document.getElementById('location_frame_subdetail_result')) {
				document.getElementById('location_frame_subdetail_result').style.display = 'none';
			}

			if (document.getElementById('item_stock_frame_batches_tab')) {
				document.getElementById('item_stock_frame_batches_tab').style.display = 'none';
			}

			if (document.getElementById('item_stock_frame_batches_content')) {
				document.getElementById('item_stock_frame_batches_content').style.display = 'none';
			}
		}
	}

    static load_item_stock_frame(html_field, item_id)
    {

		silicon_ioi.doctype.ioiItemStockFrame.select_item = item_id;

		silicon_ioi.doctype.ioiItemStockFrame.load_config(item_id);

		// Tab 1

		if (document.getElementById('item_stock_frame_locations_tab')) {
			document.getElementById('item_stock_frame_locations_tab').remove();
		}

		if (document.getElementById('item_stock_frame_locations_tab_label')) {
			document.getElementById('item_stock_frame_locations_tab_label').remove();
		}		

		if (document.getElementById('item_stock_frame_locations_tab_underline')) {
			document.getElementById('item_stock_frame_locations_tab_underline').remove();
		}

		if (document.getElementById('item_stock_frame_locations_content')) {
			document.getElementById('item_stock_frame_locations_content').remove();
		}

		// Tab 2

		if (document.getElementById('item_stock_frame_batches_tab')) {
			document.getElementById('item_stock_frame_batches_tab').remove();
		}

		if (document.getElementById('item_stock_frame_batches_tab_label')) {
			document.getElementById('item_stock_frame_batches_tab_label').remove();
		}

		if (document.getElementById('item_stock_frame_batches_tab_underline')) {
			document.getElementById('item_stock_frame_batches_tab_underline').remove();
		}

		if (document.getElementById('item_stock_frame_batches_content')) {
			document.getElementById('item_stock_frame_batches_content').remove();
		}

		if (document.getElementById('item_stock_frame_warehouse_stock_tabs_content')) {
			document.getElementById('item_stock_frame_warehouse_stock_tabs_content').remove();
		}

		// Frame Content

		if (document.getElementById('item_stock_frame_content')) {
			document.getElementById('item_stock_frame_content').remove();
		}

        let html = '';

		html = '<div id="item_stock_frame_content" style="height:1110px;">';

		html += '	<div id="item_stock_frame_warehouse_stock_content" style="height:200px;">';

		html += '		<div id="item_stock_frame_warehouse_stock_grid" class="table table-bordered" data-custom-grid="true"></div>'

		html += '	</div>';

		html += '	<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '	</div>'

		html += '	<div id="item_stock_frame_warehouse_stock_tabs_content" style="overflow: auto; overflow-x: auto; height:900px;">';

		html += '		<div style="overflow-x: auto; height:35px;">';				
		
		html += '			<div style="position:relative;"> ';

		// tab 1
		html += '				<div id="item_stock_frame_locations_tab" style="position:relative; top:0px; left:0px; width:100px; height:30px;"> ';
		html += '					<label align="center" id="item_stock_frame_locations_tab_label" style="position:absolute; top:2px;width:100px;"><font size="2">' + __("Locations") + '</font></label>';
		html += '					<div id="item_stock_frame_locations_tab_underline" style="position:absolute; top:25px; left:0px; width:100%; height:1px; background-color:#2490EF;display:none;" data-custom-section-head="true"></div>';		
		html += '				</div>';

		// tab 2
		html += '				<div id="item_stock_frame_batches_tab" style="position:absolute; top:0px; left:130px; width:120px; height:30px;"> ';
		html += '					<label align="center" id="item_stock_frame_batches_tab_label" style="position:absolute; top:2px;width:120px;"><font size="2">' + __("Batches / SN") + '</font></label>';
		html += '					<div id="item_stock_frame_batches_tab_underline" style="position:absolute; top:25px; left:0px; width:100%; height:1px; background-color:#2490EF;display:none;" data-custom-section-head="true"></div>';		
		html += '				</div>';

		html += '			</div>';

		html += '		</div>';

		html += '		<div id="item_stock_frame_locations_content"></div>';
		html += '		<div id="item_stock_frame_batches_content" style="overflow-x: auto; height:850px;"></div>';

		html += '	</div>';

		html += '</div>';


		cur_frm.fields_dict[html_field].$wrapper.empty();
		cur_frm.fields_dict[html_field].$wrapper.append(html);

		silicon_ioi.doctype.ioiItemStockFrame.warehouse_stock_grid([])

		silicon_ioi.doctype.ioiItemStockFrame.sleep_static(200).then(() => {

			let fct_tab_mouseover = function () { silicon_ioi.doctype.ioiItemStockFrame.tab_mouseover(this); };
			let fct_tab_mouseleave = function () { silicon_ioi.doctype.ioiItemStockFrame.tab_mouseleave(this); };
			let fct_tab_click_batches = function() { silicon_ioi.doctype.ioiItemStockFrame.tab_click(this); };
			let fct_tab_click_location = function () { silicon_ioi.doctype.ioiItemStockFrame.tab_click(this); };

			// tab 1
			document.getElementById('item_stock_frame_locations_tab').onmouseover = fct_tab_mouseover;
			document.getElementById('item_stock_frame_locations_tab').onmouseleave = fct_tab_mouseleave;
			document.getElementById('item_stock_frame_locations_tab_label').onmouseover = fct_tab_mouseover;
			document.getElementById('item_stock_frame_locations_tab_label').onmouseleave = fct_tab_mouseleave;
			document.getElementById('item_stock_frame_locations_tab').onclick = fct_tab_click_location;

			// tab 2
			document.getElementById('item_stock_frame_batches_tab').onmouseover = fct_tab_mouseover;
			document.getElementById('item_stock_frame_batches_tab').onmouseleave = fct_tab_mouseleave;
			document.getElementById('item_stock_frame_batches_tab_label').onmouseover = fct_tab_mouseover;
			document.getElementById('item_stock_frame_batches_tab_label').onmouseleave = fct_tab_mouseleave;
			document.getElementById('item_stock_frame_batches_tab').onclick = fct_tab_click_batches;

			if (cur_frm.doctype == "ioi Item") {
				silicon_ioi.doctype.ioiItemStockFrame.warehouse_stock_get_result(item_id);
			}

			document.getElementById('item_stock_frame_locations_tab').click()

			silicon_ioi.doctype.ioiLocationFrame.load_location_frame('item_stock_frame_locations_content', silicon_ioi.doctype.ioiItemStockFrame.is_dark_mode, silicon_ioi.doctype.ioiItemStockFrame.bgcolor);
			silicon_ioi.doctype.ioiBatchSNFrame.load_batchsn_frame('item_stock_frame_batches_content', silicon_ioi.doctype.ioiItemStockFrame.is_dark_mode, silicon_ioi.doctype.ioiItemStockFrame.bgcolor);

			if ((silicon_ioi.doctype.ioiItemStockFrame.item_mode != 3) && (silicon_ioi.doctype.ioiItemStockFrame.item_mode != 4)) {


				if (document.getElementById('location_frame_subdetail_detail_panel')) {
					document.getElementById('location_frame_subdetail_detail_panel').style.display = 'none';
				}

				if (document.getElementById('location_frame_subdetail_filters')) {
					document.getElementById('location_frame_subdetail_filters').style.display = 'none';
				}


				if (document.getElementById('location_frame_subdetail_result')) {
					document.getElementById('location_frame_subdetail_result').style.display = 'none';
				}

				if (document.getElementById('item_stock_frame_batches_tab')) {
					document.getElementById('item_stock_frame_batches_tab').style.display = 'none';
				}

				if (document.getElementById('item_stock_frame_batches_content')) {
					document.getElementById('item_stock_frame_batches_content').style.display = 'none';
				}
			}
		});
	}

	// ***************************************************************************************************************************************************************************
	// Tabs
	// ***************************************************************************************************************************************************************************

	static tab_mouseover(obj)
	{
		obj.style.cursor = 'pointer';
	}

	static tab_mouseleave(obj)
	{
		obj.style.cursor = 'none';
	}

	static tab_click(obj)
	{	
		// tab 1
		document.getElementById('item_stock_frame_locations_tab_underline').style.display = 'none';
		document.getElementById('item_stock_frame_locations_tab_label').style.fontWeight = 'normal';
		document.getElementById('item_stock_frame_locations_content').style.display = 'none';

		// tab 2
		document.getElementById('item_stock_frame_batches_tab_underline').style.display = 'none';
		document.getElementById('item_stock_frame_batches_tab_label').style.fontWeight = 'normal';
		document.getElementById('item_stock_frame_batches_content').style.display = 'none';

		if (obj.id == 'item_stock_frame_locations_tab')
		{
			// tab 1
			document.getElementById('item_stock_frame_locations_tab_underline').style.display = 'block';
			document.getElementById('item_stock_frame_locations_tab_label').style.fontWeight = 'bold';
			document.getElementById('item_stock_frame_locations_content').style.display = 'block';

		}else if (obj.id == 'item_stock_frame_batches_tab')
		{
			// tab 2
			document.getElementById('item_stock_frame_batches_tab_underline').style.display = 'block';
			document.getElementById('item_stock_frame_batches_tab_label').style.fontWeight = 'bold';
			document.getElementById('item_stock_frame_batches_content').style.display = 'block';

		}
	}

	// ***************************************************************************************************************************************************************************
	// Warehouse Stock
	// ***************************************************************************************************************************************************************************

	static warehouse_stock_grid(data)
	{
		let let_empty_if_zero = (cell, formatterParams, onRendered) => {
			if (cell.getValue() != 0) {
				return cell.getValue()
			}
		}

		silicon_ioi.doctype.ioiItemStockFrame.table = new ioi.Tabulator("#item_stock_frame_warehouse_stock_grid", {
			maxHeight: 200,
			data: data,
			layout: "fitColumns",
			selectableRows: 1,
			movableColumns: true,
			resizableColumns: true,
			showProfiles: true,
			autoRedraw: true,
			columns: [
				{ title: __("Site"), field: "site_id", minWidth:100 },
				{ title: __("Whs"), field: "warehouse_id", minWidth:100 },
				{ title: __("Unit"), field: "unit_id", minWidth:100 },
				{ title: __("Stock qty"), field: "q_stock", minWidth:150, bottomCalc:"sum", hozAlign: 'right', formatter: let_empty_if_zero, bottomCalcFormatter: let_empty_if_zero },
				{ title: __("To check qty"), field: "q_tocheck", minWidth:150, bottomCalc:"sum", hozAlign: 'right', formatter: let_empty_if_zero, bottomCalcFormatter: let_empty_if_zero  },
				{ title: __("Bad qty"), field: "q_bad", minWidth:150, bottomCalc:"sum", hozAlign: 'right', formatter: let_empty_if_zero, bottomCalcFormatter: let_empty_if_zero  },
				{ title: __("Locked qty"), field: "q_locked", minWidth:150, bottomCalc:"sum", hozAlign: 'right', formatter: let_empty_if_zero, bottomCalcFormatter: let_empty_if_zero  },
				{ title: __("Reserved qty"), field: "q_reserved", minWidth:150, bottomCalc:"sum", hozAlign: 'right', formatter: let_empty_if_zero, bottomCalcFormatter: let_empty_if_zero  },
				{ title: __("Packed qty"), field: "q_packed", minWidth:150, bottomCalc:"sum", hozAlign: 'right', formatter: let_empty_if_zero, bottomCalcFormatter: let_empty_if_zero  },
				{ title: __("Locked to check qty"), field: "q_lockedtocheck", minWidth:150, bottomCalc:"sum", hozAlign: 'right', formatter: let_empty_if_zero, bottomCalcFormatter: let_empty_if_zero  },
				{ title: __("Locked bad qty"), field: "q_lockedbad", minWidth:150, bottomCalc:"sum", hozAlign: 'right', formatter: let_empty_if_zero, bottomCalcFormatter: let_empty_if_zero  },
			],
		});

		silicon_ioi.doctype.ioiItemStockFrame.table.on("dataProcessed", () => {

			silicon_ioi.doctype.ioiItemStockFrame.sleep_static(200).then(() => {
				if (silicon_ioi.doctype.ioiItemStockFrame.table.getRows().length) {
					silicon_ioi.doctype.ioiItemStockFrame.table.getRows()[0].select()
				}
			})

			if (silicon_ioi.doctype.ioiItemStockFrame.table.getData().length == 0) {
				if (document.getElementById('location_frame_filters_button_clear')) {
					document.getElementById('location_frame_filters_button_clear').click()
				}

				if (document.getElementById('batchsn_frame_filters_button_clear')) {
					document.getElementById('batchsn_frame_filters_button_clear').click()
				}
			}
		})

		silicon_ioi.doctype.ioiItemStockFrame.table.on("rowSelected", (row) => {
			silicon_ioi.doctype.ioiLocationFrame.is_builded_table_filters = false
			silicon_ioi.doctype.ioiItemStockFrame.load_item_stock_locations_batchs(row)
		});

		silicon_ioi.doctype.ioiItemStockFrame.table.on("tableBuilt", () => {
			silicon_ioi.doctype.ioiItemStockFrame.table.footerManager.element.setAttribute('data-custom-grid-bottom', 'true')
		});
	}

	static warehouse_stock_get_result(item_id)
	{
		if (document.getElementById('location_frame_filters_panel_filters_title')) {	
			document.getElementById('location_frame_filters_panel_filters_title').innerHTML = '<b>' + ("Filters") + '</b>';
		}

		if (document.getElementById('batchsn_frame_filters_panel_title')) {	
			document.getElementById('batchsn_frame_filters_panel_title').innerHTML = '<b>' + ("Filters") + '</b>';
		}

		if (document.getElementById('location_frame_subdetail_detail_panel_title')) {	
			document.getElementById('location_frame_subdetail_detail_panel_title').innerHTML = '<b>' + ("Detail") + '</b>';
		}

		if (item_id.trim() != '') {

			silicon_ioi.doctype.ioiItemStockFrame.select_item = item_id;

			frappe.call({
				method: silicon_ioi.doctype.ioiItemStockFrame.path_site_stock + ".ioi_site_stock_get_site_warehouse_stock",
				args: { "item_id": item_id },
				async: false,
				callback: function (r) {
					if (r.message.length > 0) {
						if (silicon_ioi.doctype.ioiItemStockFrame.table.initialized) {
							silicon_ioi.doctype.ioiItemStockFrame.table.replaceData(r.message)
						} else {
							silicon_ioi.doctype.ioiItemStockFrame.table.on('tableBuilt', () => {
								silicon_ioi.doctype.ioiItemStockFrame.table.replaceData(r.message)
							})
						}
					} else {
						silicon_ioi.doctype.ioiItemStockFrame.table.clearData()
					}
				}
			})
		}
	}

	static load_item_stock_locations_batchs(row) 
	{
		let site_id = row.getData().site_id
		let warehouse_id = row.getData().warehouse_id
		let item_id = silicon_ioi.doctype.ioiItemStockFrame.select_item

		silicon_ioi.doctype.ioiLocationFrame.load_location_frame_get_result(site_id, warehouse_id, item_id)
		silicon_ioi.doctype.ioiBatchSNFrame.batchsn_frame_do_refresh(site_id, warehouse_id, item_id);

		if (document.getElementById('location_frame_filters_panel_filters_title')) {
			document.getElementById('location_frame_filters_panel_filters_title').innerHTML = '<b>' + ("Filters, for warehouse") + ' ' + warehouse_id + '</b>';
		}
		if (document.getElementById('batchsn_frame_filters_panel_title')) {
			document.getElementById('batchsn_frame_filters_panel_title').innerHTML = '<b>' + ("Filters, for warehouse") + ' ' + warehouse_id + '</b>';
		}

		if (document.getElementById('location_frame_subdetail_detail_panel_title')) {
			document.getElementById('location_frame_subdetail_detail_panel_title').innerHTML = '<b>' + ("Detail") + '</b>';
		}
	}

	static sleep_static(ms) 
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}

}

silicon_ioi.doctype.ioiItemStockFrame = ioiItemStockFrame;