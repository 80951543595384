// *******************************************************************************************************************************************
// Parent Class of all Root Table Doctypes : for Sales journals, Purchases journals, ...
// *******************************************************************************************************************************************

frappe.provide('silicon_ioi.doctype');

export class ioiRootTableDocType extends silicon_ioi.doctype.ioiTableDocType
{
	constructor(frm) 
	{	
		super(frm);
	}
}

silicon_ioi.doctype.ioiRootTableDocType = ioiRootTableDocType;