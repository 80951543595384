import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ab29fad7"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "tree-label" }
const _hoisted_5 = { class: "tree-children" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TreeNode = _resolveComponent("TreeNode")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tree-node", { opened: $props.node.open }]),
    title: $props.node.field.fieldtype+($props.node.field.length > 0 ?'('+$props.node.field.length+')' : '')
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(["tree-link", { active: $props.node.value === $props.selected_node.value }]),
      onClick: _cache[1] || (_cache[1] = $event => (_ctx.$emit('node-click', $props.node))),
      disabled: $props.node.fetching
    }, [
      _createElementVNode("div", { innerHTML: $options.icon }, null, 8 /* PROPS */, _hoisted_3),
      _createElementVNode("a", _hoisted_4, _toDisplayString($props.node.label), 1 /* TEXT */),
      (!$props.node.parameter_exists && $props.node.field.fieldtype != 'Table' && $props.node.field.fieldtype != 'Link' && $props.node.children.length == 0)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "btn btn-xs btn-load-more",
            onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('create-parameter', $props.node)))
          }, _toDisplayString(_ctx.__("Add as parameter")), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ], 10 /* CLASS, PROPS */, _hoisted_2),
    _withDirectives(_createElementVNode("ul", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.node.children, (n) => {
        return (_openBlock(), _createBlock(_component_TreeNode, {
          key: n.value,
          node: n,
          selected_node: $props.selected_node,
          onNodeClick: n => _ctx.$emit('node-click', n),
          onCreateParameter: n => _ctx.$emit('create-parameter', n)
        }, null, 8 /* PROPS */, ["node", "selected_node", "onNodeClick", "onCreateParameter"]))
      }), 128 /* KEYED_FRAGMENT */))
    ], 512 /* NEED_PATCH */), [
      [_vShow, $props.node.open]
    ])
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}