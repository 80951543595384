frappe.provide('silicon-ioi');

class AControlCode extends frappe.ui.form.ControlCode {
	
	set_language() {
		const language_map = {
			Javascript: "ace/mode/javascript",
			JS: "ace/mode/javascript",
			Python: "ace/mode/python",
			Py: "ace/mode/python",
			HTML: "ace/mode/html",
			CSS: "ace/mode/css",
			Markdown: "ace/mode/markdown",
			SCSS: "ace/mode/scss",
			JSON: "ace/mode/json",
			Golang: "ace/mode/golang",
			Go: "ace/mode/golang",
			Sql: "ace/mode/sql",
		};
		const language = this.df.options;

		const valid_languages = Object.keys(language_map);
		if (language && !valid_languages.includes(language)) {
			// eslint-disable-next-line
			console.warn(
				`Invalid language option provided for field "${
					this.df.label
				}". Valid options are ${valid_languages.join(", ")}.`
			);
		}

		const ace_language_mode = language_map[language] || "";
		this.editor.session.setMode(ace_language_mode);
		this.editor.setKeyboardHandler("ace/keyboard/vscode");
	}

};

// overriding frappe class
frappe.ui.form.ControlCode = AControlCode;
