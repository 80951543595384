frappe.provide("silicon_ioi")

const ioi_kanban_path = 'silicon_ioi.ioi_configuration.kanban.ioi_kanban.';

class ioiKanbanView extends frappe.views.KanbanView {
	get required_libs() {
		return "ioi_kanban_board.bundle.js";
	}

	// Rebuilt the kanban switcher to avoid showing deleted kanbans
	setup_kanban_switcher(kanbans) {
		const old_button = this.page.custom_actions.find(".custom-btn-group button")

		old_button.length && Array.from(old_button).forEach(button => {
			if (button.textContent.trim() == __('Select Kanban')) {
				button.remove()
			}	
		})

		const kanban_switcher = this.page.add_custom_button_group(
			__("Select Kanban"),
			null,
			this.$filter_section
		);

		kanbans.map((k) => {
			const custom_menu = this.page.add_custom_menu_item(
				kanban_switcher,
				k.name,
				() => frappe.set_route("List", this.doctype, "Kanban", k.name),
				false
			);

			custom_menu[0].querySelector('.menu-item-label span').innerHTML = this.format_kanban_name(k.name)
		});

		let perms = this.board_perms;
		let can_create = perms ? perms.create : true;
		let can_delete = perms ? perms.delete : true;

		if (can_create) {
			this.page.add_custom_menu_item(
				kanban_switcher,
				__("Create New Kanban Board"),
				() => frappe.views.KanbanView.show_kanban_dialog(this.doctype),
				true
			);
		}

		if (can_delete) {
			this.page.add_custom_menu_item(
				kanban_switcher,
				__("Delete Kanban Board"),
				() => {
					frappe.confirm(__("Are you sure you want to proceed?"), async () => {
						if (this.current_profile) {
							await frappe.call({ method: "silicon_ioi.ioi_configuration.kanban.ioi_kanban.remove_kanban_definition", args: { card_format: this.current_profile.name, board_name: this.board_name }})
						}

						frappe.db.delete_doc("Kanban Board", this.board_name).then(() => {
							frappe.show_alert(`Kanban Board ${this.board_name} deleted.`);
							frappe.set_route("List", this.doctype, "List");
						});
					});
				},
				true
			);
		}

		this.page.add_custom_menu_item(
			kanban_switcher,
			__("Edit Kanban Card Format"),
			() => window.open(`/app/ioi-module-kanban-definition/${this.current_profile ? this.current_profile.name : 'new-ioi-module-kanban-definition'}`, '_blank'),
			true
		);
	}

	format_kanban_name(name) {
		if (name.includes(`${this.doctype} | `)) name = name.split("|")[1].trim()
		return name
	}

	init() {
		return super.init().then(() => {
			frappe.views.KanbanView.get_kanbans(this.doctype).then((kanbans) =>
				setTimeout(() => this.setup_kanban_switcher(kanbans), 100)
			);

			this.page.menu_btn_group.find(".user-action").css('display', 'none')
			frappe.route_options = {}

			let menu_length = this.page.menu.find(".dropdown-item").length;
			if (menu_length === 1) {
				// Only 'Refresh' (hidden) is present (always), dropdown is visibly empty
				this.page.hide_menu();
			}

			this.page.wrapper[0].querySelector('.title-area .title-text').innerHTML = this.format_kanban_name(this.page.title)
		});
	}

	setup_defaults() {
		return super.setup_defaults().then(() => {
			let get_board_name = () => {
				return this.kanbans.length && this.kanbans[0].name;
			};

			this.board_name = frappe.get_route()[3] || get_board_name() || null;
			this.page_title = __(this.board_name);
			this.card_meta = this.get_card_meta();
			// Set max data
			this.page_length = 100;

			return frappe.run_serially([
				() => this.set_board_perms_and_push_menu_items(),
				() => this.get_board(),
			]);
		});
	}

	get_view_settings() {
		return {
			label: '',
			action: () => {},
			condition: () => false,
		};
	}

	push_menu_items() {
		//
	}

	check_requirements() {
		let me = this;
		let select_site = ''

		return frappe.run_serially([
			() => frappe.db.get_value('ioi Module', this.doctype, 'select_site').then(r => select_site = r.message.select_site),
			() => { if (select_site) {
					frappe.call({
						method: 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user.ioi_user_has_site',
						args: {},
						async: false,
						callback: function (r) {
							me.current_site = r.message.site_id;
						}
					})
				} else { 
					this.current_site = undefined;
				}
			},
			() => frappe.call({ method: "silicon_ioi.ioi_configuration.kanban.ioi_kanban.get_current_profile", args: { board_name: this.board_name, doctype: this.doctype }, callback: (r) => {
				me.current_profile = r.message

				if (me.current_profile) {
					this.page_length = r.message.cards_limit_number
					this.testing_field = r.message.testing_field
				}
			}}),
		]);
	}

	get_board() {
		return frappe.run_serially([
			() => this.check_requirements(),
			() => frappe.db.get_doc("Kanban Board", this.board_name).then((board) => {
				this.board = board;
				this.board.filters_array = JSON.parse(this.board.filters || "[]");
				this.board.fields = JSON.parse(this.board.fields || "[]");
				if (this.current_profile) {
					if (this.current_profile.show_status == 1) this.board.fields += "\nioi Status (ioistatus)"
					if (this.current_profile.show_user_status == 1) this.board.fields += "\nioi User Status (ioiuserstatus)"
					if (this.current_profile.testing_field) this.board.fields += `\n${this.testing_field}`
					this.filters = this.board.filters_array;
				}
				if (this.current_site) {
					this.filters.push([this.doctype,"site_id","=",this.current_site,false])
				}
			}),
		]);
	}

	setup_page() {
		super.setup_page();
		this.hide_sort_selector = false;
	}
}

frappe.ui.form.on("Kanban Board", {
	onload_post_render: function(frm) {
		// set field options
		frm.set_df_property("field_name", "options", Object.keys(frappe.listview_settings[frm.doc.reference_doctype].kanban.fields));
		frm.get_field("field_name").refresh();
	},
	reference_doctype: function (frm) {
		// set field options
		if (!frm.doc.reference_doctype) return;

		setTimeout(() => {
			if (frappe.listview_settings[frm.doc.reference_doctype]) {
				frm.set_df_property("field_name", "options", Object.keys(frappe.listview_settings[frm.doc.reference_doctype].kanban.fields));
				frm.get_field("field_name").refresh();
			}
		}, 100)
	},
});

frappe.views.KanbanView.show_kanban_dialog = function (doctype) {
	cur_list.settings.kanban.card_format_list = []
	let dialog = ''

	return frappe.run_serially([
		() => frappe.db.get_list('ioi Module Kanban Definition', { fields: ['name', 'selected_fields', 'root_filters', 'show_status', 'show_user_status', 'show_colored_field', 'public', 'owner'], filters: { doc_type: doctype } }).then(r => {
			let filtered_def = []

			if (r.length) {
				filtered_def = r.filter(def => def.public === 1 || (def.public === 0 && def.owner === frappe.session.user));
			}

			cur_list.settings.kanban.card_format_list = filtered_def
		}),
		() => {
			dialog = new_kanban_dialog();
			dialog.show()
			
			dialog.$wrapper.find("button[data-fieldname=create_card_format]").on('click', () => {
				frappe.set_route("Form", "ioi Module Kanban Definition", "new-ioi-module-kanban-definition");
			})

			dialog.$wrapper.find("button[data-fieldname=edit_card_format]").on('click', () => {
				window.open(`/app/ioi-module-kanban-definition/${dialog.fields_dict.card_format.value}`, '_blank');
			})
		},
	]);

	function make_kanban_board(board_name, field_name, project, authorized_field_values) {
		return frappe.call({
			method: "frappe.desk.doctype.kanban_board.kanban_board.quick_kanban_board",
			args: {
				doctype,
				board_name,
				field_name,
				project,
				authorized_field_values,
			},
			callback: function (r) {
				var kb = r.message;
				if (kb.filters) {
					frappe.provide("frappe.kanban_filters");
					frappe.kanban_filters[kb.kanban_board_name] = kb.filters;
				}
				frappe.set_route("List", doctype, "Kanban", kb.kanban_board_name);
			},
		});
	}

	function new_kanban_dialog() {
		/* Kanban dialog can show either "Save" or "Customize Form" option depending if any Select fields exist in the DocType for Kanban creation */

		const select_fields = Object.keys(cur_list.settings.kanban.fields);
		const authorized_values_for_fields = cur_list.settings.kanban.fields;
		const dialog_fields = get_fields_for_dialog(select_fields);
		const to_save = select_fields.length > 0;
		const primary_action_label = to_save ? __("Save") : __("Customize Form");
		const dialog_title = to_save ? __("New Kanban Board") : __("No Select Field Found");

		let primary_action = async () => {
			if (to_save) {
				const values = dialog.get_values();
				let boards_list = await frappe.call('silicon_ioi.ioi_configuration.kanban.ioi_kanban.get_kanban_boards_list').then(r => r.message)
				let linked_to_format = boards_list.find(board => board.board_name == values.board_name)
				const board_name = `${doctype} | ${values.board_name}`

				const make_kanban = () => make_kanban_board(board_name, values.field_name, values.project, authorized_values_for_fields[values.field_name]).then(
					() => {
						if (values.card_format != "Default") {
							let saved_card_format = dialog_fields.card_format_list.find(val => val.name === values.card_format)

							let filters = saved_card_format.root_filters
							let fields = saved_card_format.selected_fields

							let values_to_set = {}

							if (filters && filters != '') values_to_set['filters'] = filters
							if (fields) values_to_set['fields'] = JSON.stringify(fields)

							frappe.db.set_value('Kanban Board', board_name, values_to_set)

							frappe.call({
								method: "silicon_ioi.ioi_configuration.kanban.ioi_kanban.populate_kanban_definition",
								args: {
									card_format: values.card_format,
									board_name: board_name,
									field_name: values.field_name
								},
							});
						}

						dialog.hide()
					},
					(err) => frappe.msgprint(err)
				)

				if (linked_to_format) {
					frappe.warn(__('Warning'),
						`Kanban <strong>${linked_to_format.board_name}</strong> ` + __('is already linked to a card format, do you want to overwrite it ?'),
						() => {
							frappe.call({
								method: "silicon_ioi.ioi_configuration.kanban.ioi_kanban.remove_kanban_definition",
								args: {
									card_format: values.card_format,
									board_name: linked_to_format.board_name
								},
								callback: () => make_kanban()
							});
						},
						'Continue',
						true
					)
				} else {
					make_kanban()
				}
			} else {
				frappe.set_route("Form", "Customize Form", { doc_type: doctype });
			}
		};

		return new frappe.ui.Dialog({
			title: dialog_title,
			fields: dialog_fields.fields,
			primary_action_label,
			primary_action,
		});
	}

	function get_fields_for_dialog(select_fields) {
		let card_format_list = []

		if (cur_list.settings.kanban.card_format_list.length) {
			cur_list.settings.kanban.card_format_list.map(el => card_format_list.push(el))
		}

		if (!select_fields.length) {
			return [
				{
					fieldtype: "HTML",
					options: `
					<div>
						<p class="text-medium">
						${__(
							'No fields found that can be used as a Kanban Column. Use the Customize Form to add a Custom Field of type "Select".'
						)}
						</p>
					</div>
				`,
				},
			];
		}

		let fields = [
			{
				fieldtype: "Data",
				fieldname: "board_name",
				label: __("Kanban Board Name"),
				reqd: 1,
				description: ["Note", "ToDo"].includes(doctype)
					? __("This Kanban Board will be private")
					: "",
			},
			{
				fieldtype: "Select",
				fieldname: "field_name",
				label: __("Columns based on"),
				options: select_fields.map((field) => ({ label: field, value: field })),
				default: select_fields[0],
				reqd: 1,
			},
			{
				fieldtype: "Select",
				fieldname: "card_format",
				label: __("Card format"),
				options: card_format_list.map(card_format => ({ label: card_format.name, value: card_format.name })) || [],
				default: card_format_list[0] && card_format_list[0].name || '',
				description: card_format_list.length ? '' : __('Please create at least one card format before creating a kanban'),
				reqd: 1,
			},
			{
				label: __('Create card format'),
				fieldname: 'create_card_format',
				fieldtype: 'Button',
				btn_size: 'xs',
			},
			{
				label: __('Edit card format'),
				fieldname: 'edit_card_format',
				fieldtype: 'Button',
				btn_size: 'xs',
				hidden: card_format_list.length ? false : true,
			},
		];

		if (doctype === "Task") {
			fields.push({
				fieldtype: "Link",
				fieldname: "project",
				label: __("Project"),
				options: "Project",
			});
		}

		return { fields, card_format_list };
	}
};

$(document).ready(() => {
	frappe.views.KanbanView = ioiKanbanView;
})