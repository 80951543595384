<template>
	<div class="file-browser">
		<div class="file-browser-list">
			<div class="file-filter">
				<input
					type="search"
					class="form-control input-xs"
					:placeholder="__('Search by field name or label')"
					v-model="search_text"
					@input="search_by_name"
				/>
			</div>
			<TreeNode
				class="tree with-skeleton"
				:node="node"
				:selected_node="selected_node"
				@node-click="toggle_node"
				@create-parameter="create_parameter"
			/>
		</div>
	</div>
</template>
<script>
import TreeNode from "./DocumentNode.vue";

export default {
	name: "DocumentBrowser",
	components: {
		TreeNode
	},
	props: {
		doctype: {
			default: null
		}
	},
	data() {
		return {
			node: {
				label: __(this.doctype),
				value: this.doctype,
				children: [],
				children_loading: false,
				is_leaf: false,
				fetching: false,
				fetched: false,
				open: false,
				filtered: true,
				parameter_exists: 1,
				field: {fieldname: ''},
				origin_doc_fieldname : null
			},
			selected_node: {},
			search_text: "",
			parameters: ioi.report.fields.map(f => f.frappe_name || f.name),
			fieldnames: {}
		};
	},
	mounted() {
		this.toggle_node(this.node);
	},
	methods: {
		toggle_node(node) {
			if (!node.fetched && (!node.is_leaf || node.field.fieldtype == "Table") ) {
				node.fetching = true;
				node.children_loading = false;
				this.get_fields(node.value, node?.field?.fieldtype == "Table" ? node?.field?.fieldname : node?.origin_doc_fieldname).then(
					(fields) => {
						node.open = true;
						node.children = fields;
						node.fetched = true;
						node.fetching = false;
						node.field = {fieldname: ''};
					}
				);
			} else {
				node.open = !node.open;
				this.select_node(node);
			}
		},
		select_node(node) {
			if (node.is_leaf) {
				this.selected_node = node;
			}
		},
		get_fields(docfield, origin_doc_fieldname) {

			return frappe
				.call("silicon_ioi.ioi_system.page.ioi_report_builder.ioi_report_builder.get_document_structure", {
					doctype: this.doctype,
					docfield: docfield,
					origin_doc_fieldname : origin_doc_fieldname
				})
				.then(r => {
					let fields = r.message;

					fields.sort((a, b) => {
						return a.label.localeCompare(b.label);
					});
					fields.map(f => this.fieldnames[f.name] = {name: f.fieldname, parent: (docfield !== this.doctype) ? docfield : null})
					fields = fields.map(field => this.make_file_node(field));
					return fields;
				});
		},
		search_by_name: frappe.utils.debounce(function() {

			console.dir(this)
			if (this.search_text === "") {
				this.node = this.folder_node;
				return;
			}
			if (this.search_text.length < 3) return;
			frappe
				.call(
					"silicon_ioi.ioi_system.page.ioi_report_builder.ioi_report_builder.get_fields_by_search_text",
					{
						doctype: this.doctype,
						text: this.search_text
					}
				)
				.then(r => {
					
					let fields = r.message || [];
					fields = fields.map(field => this.make_file_node(field));

					if (!this.folder_node) {
						this.folder_node = this.node;
					}
					this.node = {
						label: __("Search Results"),
						value: "",
						children: fields,
						by_search: true,
						open: true,
						filtered: true,
						field: {fieldname: ''},
						parameter_exists: true
					};
				});
		}, 300),
		make_file_node(field) {
			let label = frappe.utils.file_name_ellipsis(field.label ? field.label : field.fieldname, 40);

			return {
				label: label,
				value: field.name,
				is_leaf: field.fieldtype == 'Table' ? 0 : field.is_leaf,
				fetched: false, // fetched if node is leaf
				children: [],
				children_loading: false,
				open: false,
				fetching: false,
				filtered: true,
				parameter_exists:  this.parameters.includes(field.name),
				field: field,
				origin_doc_fieldname: field.origin_doc_fieldname
			};
		},

		async create_parameter(node) {
			const origin_fn = node.origin_doc_fieldname;

			const child_param = ioi.report.get_field_object(node.field)
			if (child_param.type == 'array'){
				child_param.children = await ioi.report.get_field_children(node.field);
			}

			if (node.field.parent_node) {
				let parent = this.fieldnames[node.field.parent_node]
				const parents = [parent.name]
				while (parent.parent !== null) {
					parent = this.fieldnames[parent.parent]
					parents.unshift(parent.name)
				}
				child_param.name = parents.join("__") + `__${child_param.name}`
				child_param.test_data = ""
			}
			debugger;
			if (!origin_fn){
				ioi.report.reportbro.createParameter(child_param)
				this.parameters.push(child_param)

				node.parameter_exists = 1
				ioi.report.reportbro.setModified(true)
				this.select_node(node)
			}else{

				let parameter_parent = ioi.report.reportbro.getParameterByName(origin_fn);
				if (parameter_parent){
					child_param.name = child_param.name.replace(parameter_parent.name+'__','');

					let param = ioi.report.reportbro.createParameter(child_param, parameter_parent)
					this.parameters.push(child_param);

					node.parameter_exists = 1
					ioi.report.reportbro.setModified(true)
					this.select_node(node)
				}
			}


		}
	}
};
</script>

<style>
.file-browser-list {
	overflow: hidden;
	margin-top: 10px;
}

.file-filter {
	padding: 3px;
}

.tree {
	overflow: auto;
	height: 100%;
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 4rem;
}
</style>
