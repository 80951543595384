import {TabulatorFull as Tabulator} from 'tabulator-tables';

frappe.provide("ioi_partners.customers_list");

export default class CustomersList {
	constructor(opts) {
		Object.assign(this, opts);
		this.wrapper = $(".web-list-table");
		this.refresh();
	}

	refresh() {
		this.get_data()
		this.bind_download_button()
	}

	get_columns() {
		return [
			{
				title: __("Customer"),
				field: "enduser",
				cellClick: this.go_to_document.bind(this),
				headerFilter: true
			},
			{
				title: __("Status"),
				field: "ioi_status",
				cellClick: this.go_to_document.bind(this),
				headerFilter: true
			},
			{
				title: __("Subscription"),
				field: "name",
				cellClick: this.go_to_document.bind(this),
				headerFilter: true
			},
			{
				title: __("Total in Basket"),
				field: "total_in_basket",
				cellClick: this.go_to_document.bind(this),
				formatter:"money",
				formatterParams:{
					decimal: ",",
					thousand: " ",
					symbol: "€",
					negativeSign: true,
					precision: 2,
					symbolAfter: true
				}
			},
			{
				title: __("Total Ordered"),
				field: "total_ordered",
				cellClick: this.go_to_document.bind(this),
				formatter:"money",
				formatterParams:{
					decimal: ",",
					thousand: " ",
					symbol: "€",
					negativeSign: true,
					precision: 2,
					symbolAfter: true
				}
			},
			{
				title: __("Total Active"),
				field: "total_active",
				cellClick: this.go_to_document.bind(this),
				formatter:"money",
				formatterParams:{
					decimal: ",",
					thousand: " ",
					symbol: "€",
					negativeSign: true,
					precision: 2,
					symbolAfter: true
				}
			}
		]
	}

	go_to_document(e, cell) {
		const row = cell.getRow()
		const name_cell = row.getCell("name")
		const sub_name = name_cell.getValue()
		
		window.location = this.route_map[sub_name]
	}

	fetch_data() {
		let args = {
			method: "ioi_partners.ioipartners.doctype.ioi_sys_subscription.ioi_sys_subscription.get_subscriptions_for_website",
			args: {}
		};

		if (this.no_change(args)) {
			// console.log('throttled');
			return Promise.resolve();
		}

		return frappe.call(args)
	}

	no_change(args) {
		// returns true if arguments are same for the last 3 seconds
		// this helps in throttling if called from various sources
		if (this.last_args && JSON.stringify(args) === this.last_args) {
			return true;
		}
		this.last_args = JSON.stringify(args);
		setTimeout(() => {
			this.last_args = null;
		}, 3000);
		return false;
	}

	async get_data() {
		let response = await this.fetch_data();
		if (response) {
			const result = await response.message;
			this.data = result.result
			Object.assign(frappe._messages, result.__messages)
			this.build_table()
			this.build_route_map()
		}
	}

	build_table() {
		this.table = new Tabulator(".customers-list", {
			minheight: 800,
			maxHeight: "100%",
			data: this.data, //assign data to table
			layout: "fitColumns",
			columns: this.get_columns(),
		});
	}

	build_route_map() {
		this.route_map = this.data.reduce((acc, d) => (
			{
				...acc,
				[d.name]: d.route
			}
		), {})
	}

	bind_download_button() {
		$(".button-download-customer-statement").click(() => {
			this.table.download("xlsx", "customer_statement.xlsx", {sheetName: "Customer Statement"});
		})
	}
}

frappe.ready(() => {
	ioi_partners.customers_list = new CustomersList();
})
