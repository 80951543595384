frappe.provide('silicon_ioi.scheduling');

const BASIC_ITEM_COLOR = '#8dd2ff' //light blue
const ACTIVE_BTN_COLOR = '#A9AAAD' //dark gray


export class ioiGanttControl {

	constructor(view_type, timeline_control = true){
		//0: Gantt (dossier and production) / 1: LoadChart / 2: scheduleChart (workcenter and line machine)
		this.view_type = cint(view_type)
		this.timeline_control = timeline_control;
		//Scale
		this.gantt_major_scale_type_select = null;
		this.gantt_major_scale_format_select = null;
		this.gantt_minor_scale_type_select = null;
		this.gantt_minor_scale_format_select = null;
		//Timeline
		this.gantt_timeline_input = null;
		this.gantt_timeline_btn = null;
		this.gantt_decrease_timeline_btn = null;
		this.gantt_increase_timeline_btn = null;
		//Zoom
		this.gantt_zoom_level = null;
		this.loadchart_zoom_level = null;
		//Selected item
		this.group_schedule_selected_routing = null;
		this.gantt_show_load_chart = null;
		this.gantt_select_source_items = null;
		this.gantt_show_non_working_day = null;
		this.gantt_schedule_selected_routing = null;
		this.gantt_allow_manual_completion_selected_routing = null
		this.gantt_drag_for_hard_fix_selected_routing = null
		this.gantt_custom_bar_color = null;
		//Warning message
		this.gantt_scheduling_warning = null;

		//Context Menu
		// this.itemContextMenu = null;
		// this.scheduleItemContextMenu = null;
		// this.changeColorContextMenu = null;
		// this.close_context_menu = false;

		//Line machine
		this.schedulechart_move_up = null;
		this.schedulechart_move_down = null;

		//stacking
		this.schedulechart_stacking_scope_duration = null;
		this.schedulechart_forced_stack_btn = null;
		this.schedulechart_contraint_stack_btn = null;
		this.schedulechart_no_contraint_on_step_in_exec = null;
		this.schedulechart_stacking_warning = null;

	}


	static build_gantt_decrease_timeline_btn(id){
		return `
			<button id="${id}_decrease_timeline_btn" title="${__("Decrease timeline")}" data-label="Decrease timeline" class="btn btn-default btn-xs ellipsis mr-2 mb-2">
				<i class="fa fa-caret-left"></i>
			</button>`;
	}


	static build_gantt_increase_timeline_btn(id){
		return `
			<button id="${id}_increase_timeline_btn" title="${__("Increase timeline")}" data-label="Increase timeline" class="btn btn-default btn-xs ellipsis mr-2 mb-2">
				<i class="fa fa-caret-right"></i>
			</button>`;
	}


	static build_gantt_timeline_input(id){
		return `
			<div class="control-input">
				<input id="${id}_timeline_input" title="${__("Weeks amount")}" type="number" data-label="Weeks amount" class="input-with-feedback form-control bold mr-2 mb-2" min="0" max="52" value="1" style="width: 50px; height: 24.8px">
			</div>`;
	}


	static build_gantt_timeline_btn(id){
		return `
			<button id="${id}_timeline_btn" title="${__("Change timeline")}" data-label="Change timeline" class="btn btn-default btn-xs ellipsis mr-2 mb-2">
				<i class="fa fa-arrows-h"></i>
			</button>`;
	}


	static build_gantt_show_non_working_day(id){
		return `
			<button id="${id}_show_non_working_day" title="${__("No-working days")}" data-label="No-working days" class="btn btn-default btn-xs ellipsis mb-2">
				<i class="fa fa-calendar-times-o"></i> 
			</button>`;
	}


	static build_gantt_show_load_chart(id){
		return `
			<button id="${id}_show_load_chart" title="${__("Load chart")}" data-label="Load chart" class="btn btn-default btn-xs ellipsis mr-2 mb-2">
				<i class="fa fa-bar-chart"></i>
			</button>`;
	}


	static build_gantt_select_source_items(id){
		return `<div class="form-group m-0">
					<label class="form-label d-block">${__('Source')}</label> 
					<div class="d-flex flex-row flex-wrap mr-4 my-0">
						<div class="control-input">
							<select title="${__("Gantt source")}" id="${id}_select_source_items" class="input-with-feedback form-control bold mr-2 mb-2" style="height: 24.8px">
								<option value="-1">${__("Nothing")}</option>
								<option value="0">${__("Production")}</option>
								<option value="1">${__("Dossier")}</option>
							</select>
						</div>
					</div>
				</div>`;
	}

	static build_gantt_scheduling_warning(id){
		return `<label id="${id}_scheduling_warning" class="form-label d-block" style="font-size: var(--text-md); color: red;"></label>`;
	}

	static build_scheduling_chart_stacking_warning(id){
		return `<label id="${id}_stacking_warning" class="form-label d-block" style="font-size: var(--text-md); color: red;"></label>`;
	}

	static build_gantt_major_scale_type_select(id){
		return `<div class="control-input mr-2 mb-2" >
					<select title="${__("Major scale")}" id="${id}_major_scale_type_select" class="input-with-feedback form-control bold" style="height: 24.8px">
						<option value="Months">${__("Months")}</option>
						<option value="Weeks">${__("Weeks")}</option>
						<option value="Days">${__("Days")}</option>
					</select>
				</div>`;
	}

	static build_gantt_major_scale_format_select(id){
		return `<div class="control-input mr-2 mb-2">
					<select title="${__("Major scale format")}" id="${id}_major_scale_format_select" class="input-with-feedback form-control bold">
						<option value="Month">${__("Month")}</option>
						<option value="MonthAbbreviation">${__("Month abbr.")}</option>
						<option value="MonthYear">${__("Month year")}</option>
						<option value="DayOfWeek">${__("DayOfWeek")}</option>
						<option value="DayOfWeekAbbreviation">${__("Day of week abbr.")}</option>
						<option value="Day">${__("Day")}</option>	
					</select>
				</div>`;
	}


	static build_gantt_minor_scale_type_select(id){
		return `<div class="control-input mr-2 mb-2">
						<select title="${__("Minor scale")}" id="${id}_minor_scale_type_select" class="input-with-feedback form-control bold" style="height: 24.8px">
							<option value="Days">${__("Days")}</option>
							<option value="Hours">${__("Hours")}</option>
						</select>
				</div>`;
	}


	static build_gantt_minor_scale_format_select(id){
		return `<div class="control-input mr-2 mb-2">
					<select title="${__("Minor scale format")}" id="${id}_minor_scale_format_select" class="input-with-feedback form-control bold">
						<option value="Day">${__("Day")}</option>
						<option value="Hour">${__("Hour")}</option>
						<option value="DateTime">${__("DateTime")}</option>
					</select>
				</div>`;
	}


	static build_gantt_zoom_level(id){
		return `<input title="${__("Zoom time line")}" type="range" class="form-range d-block" min="1" max="200" step="1" id="${id}_gantt_zoom_level">`;
	}


	static build_loadchart_zoom_level(id){
		return `<div id="${id}_group_zoom_load_chart">
					<input title="${__("Zoom load chart")}" type="range" class="form-range d-block" min="0.1" max="20" step="0.1" id="${id}_loadchart_zoom_level">
				</div>`;
	}


	static build_gantt_change_color_selected_routing(id){
		return `<input 
					type="color" 
					id="${id}_custom_bar_color"
					value="${BASIC_ITEM_COLOR}" 
					title="${__("Custom bar color")}"
					class="btn btn-default btn-xs mr-2" 
					style="
						display: inline-block; 
						width: 32px; 
						height: 24.8px; 
						border: none; 
						padding: 0; 
						border-radius: 4px;">`;
	}

	static build_gantt_schedule_selected_routing(id){
		return `
			<button id="${id}_schedule_selected_routing" title="${__("Schedule selected routing")}" data-label="Schedule selected routing" class="btn btn-default btn-xs ellipsis mb-2 mr-2">
				<i class="fa fa-caret-square-o-right"></i> 
			</button>`;
	}

	static build_gantt_allow_manual_completion_selected_routing(id){
		return `
			<button id="${id}_allow_manual_completion_selected_routing" title="${__("Allow manual completion")}" data-label="Allow manual completion" class="btn btn-default btn-xs ellipsis mb-2 mr-2">
				<i class="fa fa-hand-paper-o"></i> 
			</button>`;
	}

	static build_gantt_drag_for_hard_fix_selected_routing(id){
		return `
			<button id="${id}_drag_for_hard_fix_selected_routing" title="${__("Drag for hard fix")}" data-label="Drag for hard fix" class="btn btn-default btn-xs ellipsis mb-2 mr-2">
				<i class="fa fa-anchor"></i> 
			</button>`;
	}


	static build_gantt_context_menu(){
		return `<div id="itemContextMenu" style="display: none; position: absolute; background-color: #FFFFFF; border: 1px solid #ccc; padding: 8px;">
					<div id="changeColorContainer"></div>
					<a id="scheduleItemContextMenu" href="#" style="display: none; font-family: sans-serif; font-size: 12px; text-decoration: none;">${__("Schedule routing")}</a>
				</div>`;
	}

	static build_schedule_chart_line_control(id){
		return `<div class="form-group m-0">
					<label class="form-label d-block">${__('Line')}</label>
						<div class="d-flex flex-row flex-wrap mr-4 my-0">
							<button id="${id}_move_up" title="${__("Move up")}" data-label="Move up" class="btn btn-default btn-xs ellipsis mr-2 mb-2">
								<i class="fa fa-caret-up"></i>
							</button>
							<button id="${id}_move_down" title="${__("Move down")}" data-label="Move down" class="btn btn-default btn-xs ellipsis mb-2">
								<i class="fa fa-caret-down"></i>
							</button>
						</div>
				</div>`;
	}

	static build_gantt_view_control(id){
		return `<div class="form-group m-0">
					<label class="form-label d-block">Views</label>
					<div class="d-flex flex-row flex-wrap mr-4 my-0">
						${silicon_ioi.scheduling.ioiGanttControl.build_gantt_show_load_chart(id)}
						${silicon_ioi.scheduling.ioiGanttControl.build_gantt_show_non_working_day(id)}
					</div>
				</div>`
	}

	static build_vertical_separator() {
		return `
			<div style="
				width: 2px;
				height: 100%;
				background-color: #ccc;
				margin: 0 10px;
				display: inline-block;
			"></div>
		`;
	}


	//Control
	make_timeline_html(container, id){
		let me = this;
		id = id.toLowerCase().replace(/\s+/g, '_');
		const timeline_panel = `
			<div class="d-flex flex-row flex-wrap justify-content-between">
				<div class="d-flex flex-row flex-wrap justify-content-start align-items-start">
					${([1, 2].includes(me.view_type) ? silicon_ioi.scheduling.ioiGanttControl.build_gantt_select_source_items(id) : '')}
					${([2].includes(me.view_type) ? silicon_ioi.scheduling.ioiGanttControl.build_schedule_chart_line_control(id) : '')}
					<div class="form-group m-0">
						<label class="form-label d-block">${__('Timeline')}</label>
						<div class="d-flex flex-row flex-wrap mr-4 my-0">
							${silicon_ioi.scheduling.ioiGanttControl.build_gantt_timeline_input(id)}
							${silicon_ioi.scheduling.ioiGanttControl.build_gantt_timeline_btn(id)}
							${silicon_ioi.scheduling.ioiGanttControl.build_gantt_decrease_timeline_btn(id)}
							${silicon_ioi.scheduling.ioiGanttControl.build_gantt_increase_timeline_btn(id)}
							${silicon_ioi.scheduling.ioiGanttControl.build_gantt_major_scale_type_select(id)}
							${silicon_ioi.scheduling.ioiGanttControl.build_gantt_minor_scale_type_select(id)}
							<div class="form-group m-0">
								${silicon_ioi.scheduling.ioiGanttControl.build_gantt_zoom_level(id)}
								${([0, 1].includes(me.view_type) ? silicon_ioi.scheduling.ioiGanttControl.build_loadchart_zoom_level(id) : '')}
							</div>
						</div>
					</div>
					${([0].includes(me.view_type) ? silicon_ioi.scheduling.ioiGanttControl.build_gantt_view_control(id) : '')}
					<div class="form-group m-0" id="${id}_group_schedule_selected_routing">
						<label class="form-label d-block">${__('Routing selected')}</label> 
						<div class="d-flex flex-row mr-4 my-0">
							${silicon_ioi.scheduling.ioiGanttControl.build_gantt_change_color_selected_routing(id)}
							${([1, 2].includes(me.view_type) ? silicon_ioi.scheduling.ioiGanttControl.build_gantt_schedule_selected_routing(id) : '')}
							${([0].includes(me.view_type) ? silicon_ioi.scheduling.ioiGanttControl.build_gantt_allow_manual_completion_selected_routing(id) : '')}
							${([0].includes(me.view_type) ? silicon_ioi.scheduling.ioiGanttControl.build_gantt_drag_for_hard_fix_selected_routing(id) : '')}
						</div>
					</div>
					<div class="d-flex flex-row align-items-end">
						${silicon_ioi.scheduling.ioiGanttControl.build_gantt_scheduling_warning(id)}
					</div>
				</div>
			</div>
			`;

		container.$wrapper.prepend(timeline_panel);

		//Timeline input
		if (document.getElementById(`${id}_timeline_input`)){
			me.gantt_timeline_input = document.getElementById(`${id}_timeline_input`)
		}

		//Change Timeline
		if ((document.getElementById(`${id}_timeline_input`)) && (document.getElementById(`${id}_timeline_btn`))){
			me.gantt_timeline_btn = document.getElementById(`${id}_timeline_btn`)
		}

		//Decrease Timeline
		if ((document.getElementById(`${id}_timeline_input`)) && (document.getElementById(`${id}_decrease_timeline_btn`))){
			me.gantt_decrease_timeline_btn = document.getElementById(`${id}_decrease_timeline_btn`)
		}

		//Increase Timeline
		if ((document.getElementById(`${id}_timeline_input`)) && (document.getElementById(`${id}_increase_timeline_btn`))){
			me.gantt_increase_timeline_btn = document.getElementById(`${id}_increase_timeline_btn`)
		}

		//Show non-working day
		if (document.getElementById(`${id}_show_non_working_day`)){
			me.gantt_show_non_working_day = document.getElementById(`${id}_show_non_working_day`)
			// me.gantt_show_non_working_day.checked = false;
		}

		//Show load chart
		if (document.getElementById(`${id}_show_load_chart`)){
			me.gantt_show_load_chart = document.getElementById(`${id}_show_load_chart`)
			// me.gantt_show_load_chart.checked = false;
		}

		//Show selected routing control
		if (document.getElementById(`${id}_group_schedule_selected_routing`)){
			me.group_schedule_selected_routing = document.getElementById(`${id}_group_schedule_selected_routing`)
			if(me.view_type == 1){
				me.group_schedule_selected_routing.style.display = 'none'
			}
		}

		//Schedule selected item
		if (document.getElementById(`${id}_schedule_selected_routing`)){
			me.gantt_schedule_selected_routing = document.getElementById(`${id}_schedule_selected_routing`)
		}

		//Style active button
		let toggle_btn = function () {
			if(this.classList.contains('active')) {
				this.classList.remove('active');
				this.style.backgroundColor = '';
				this.style.color = '';
			}else {
				this.classList.add('active');
				this.style.backgroundColor = ACTIVE_BTN_COLOR;
				this.style.color = 'white';
			}
		};

		//Allow manual completion
		if (document.getElementById(`${id}_allow_manual_completion_selected_routing`)){
			me.gantt_allow_manual_completion_selected_routing = document.getElementById(`${id}_allow_manual_completion_selected_routing`)
			me.gantt_allow_manual_completion_selected_routing.onclick = toggle_btn;
		}

		//Drag for hard fix
		if (document.getElementById(`${id}_drag_for_hard_fix_selected_routing`)){
			me.gantt_drag_for_hard_fix_selected_routing = document.getElementById(`${id}_drag_for_hard_fix_selected_routing`)
			me.gantt_drag_for_hard_fix_selected_routing.onclick = toggle_btn;
		}

		//Custom bar color
		if (document.getElementById(`${id}_custom_bar_color`)){
			me.gantt_custom_bar_color = document.getElementById(`${id}_custom_bar_color`)
			me.gantt_custom_bar_color.value = BASIC_ITEM_COLOR
		}

		//Select source gantt
		if (document.getElementById(`${id}_select_source_items`)){
			me.gantt_select_source_items = document.getElementById(`${id}_select_source_items`);
			me.gantt_select_source_items.value = -1;
		}

		//Change Major scale
		if (document.getElementById(`${id}_major_scale_type_select`)){
			me.gantt_major_scale_type_select = document.getElementById(`${id}_major_scale_type_select`);
			me.gantt_major_scale_type_select.value = 'Months';
		}

		//Change Major format
		if (document.getElementById(`${id}_major_scale_format_select`)){
			me.gantt_major_scale_format_select = document.getElementById(`${id}_major_scale_format_select`);
		}

		//Change Minor scale
		if (document.getElementById(`${id}_minor_scale_type_select`)){
			me.gantt_minor_scale_type_select = document.getElementById(`${id}_minor_scale_type_select`);
		}

		//Change Minor format
		if (document.getElementById(`${id}_minor_scale_format_select`)){
			me.gantt_minor_scale_format_select = document.getElementById(`${id}_minor_scale_format_select`);
		}

		//Change zoom level horizontaly
		if (document.getElementById(`${id}_gantt_zoom_level`)){
			me.gantt_zoom_level = document.getElementById(`${id}_gantt_zoom_level`);
		}

		//Change zoom level vertically
		if (document.getElementById(`${id}_loadchart_zoom_level`) && (document.getElementById(`${id}_group_zoom_load_chart`))){
			me.loadchart_zoom_level = document.getElementById(`${id}_loadchart_zoom_level`);
			me.loadchart_zoom_level.setAttribute('min', 3);
			me.loadchart_zoom_level.setAttribute('max', 20);
			me.loadchart_zoom_level.setAttribute('step', 0.5);
			me.loadchart_zoom_level.value = 10
			me.group_zoom_load_chart = document.getElementById(`${id}_group_zoom_load_chart`)
			if(me.view_type == 0){
				me.group_zoom_load_chart.style.display = 'none'
			}
		}

		//Change zoom level horizontaly
		if (document.getElementById(`${id}_move_up`)){
			me.schedulechart_move_up = document.getElementById(`${id}_move_up`);
		}

		//Change zoom level horizontaly
		if (document.getElementById(`${id}_move_down`)){
			me.schedulechart_move_down = document.getElementById(`${id}_move_down`);
		}

		//Display warning message
		if (document.getElementById(`${id}_scheduling_warning`)){
			me.gantt_scheduling_warning = document.getElementById(`${id}_scheduling_warning`);
		}

		//Initialize control
		me.update_from_selected_major_scale_type();
	}


	make_schedule_chart_scheduling_html(container, id){
		let me = this;

		const scheduling_panel = `
			<div class="d-flex flex-column">
				<div class="d-flex flex-row align-items-end mr-4">
					${silicon_ioi.scheduling.ioiGanttControl.build_scheduling_chart_stacking_warning(id)}
				</div>
				<div class="form-group m-1">
					<label class="form-label d-block">Global stacking</label>
					<div class="d-flex flex-row flex-wrap align-items-start justify-content-start">
						<div class="d-flex flex-row flex-wrap align-items-center mr-4">
							<div id="${id}_html_col_1" class="d-flex flex-row mr-2"></div>
							<div id="${id}_html_col_2" class="d-flex flex-row mr-2"></div>
						</div>
					</div>
					<div class="d-flex flex-row flex-wrap align-items-start justify-content-start">
						<div id="${id}_html_col_4" class="d-flex flex-row mr-4"></div>
					</div>
				</div>
			</div>
		`;

		container.$wrapper.prepend(scheduling_panel);

		me.schedulechart_forced_stack_btn = frappe.ui.form.make_control({
			parent: container.$wrapper.find(`#${id}_html_col_1`),
			df: {
				label: `<i class="fa fa-fast-forward" title="${__("Forced stacking for all line")}"></i>`,
				fieldname: 'schedule_chart_stacking_scope_btn',
				fieldtype: 'Button'
			},
			render_input: true
		})

		me.schedulechart_contraint_stack_btn = frappe.ui.form.make_control({
			parent: container.$wrapper.find(`#${id}_html_col_2`),
			df: {
				label: `<i class="fa fa-forward" title="${__("Contraint stacking for all line ")}"></i>`,
				fieldname: 'schedule_chart_remove_lock_scheduled_btn',
				fieldtype: 'Button'
			},
			render_input: true
		})


		me.schedulechart_no_contraint_on_step_in_exec = frappe.ui.form.make_control({
			parent: container.$wrapper.find(`#${id}_html_col_4`),
			df: {
				label: __('No constraint on step in exec.'),
				fieldname: 'schedule_chart_no_contraint_on_step_in_exec',
				fieldtype: 'Check'
			},
			render_input: true
		})

		//Display warning message
		if (document.getElementById(`${id}_stacking_warning`)){
			me.schedulechart_stacking_warning = document.getElementById(`${id}_stacking_warning`);
		}

	}


	make_gantt_context_menu_html(){
		let me = this;

		if (!document.getElementById(`itemContextMenu`)){
			parent.body.insertAdjacentHTML(
				'beforeend', 
				silicon_ioi.scheduling.ioiGanttControl.build_gantt_context_menu()
			);
		}

		//Item context menu
		if (document.getElementById(`itemContextMenu`)){
			me.itemContextMenu = document.getElementById(`itemContextMenu`);
			me.close_context_menu = true;
		}

		//Schedule context menu
		if (document.getElementById(`scheduleItemContextMenu`)){
			me.scheduleItemContextMenu = document.getElementById(`scheduleItemContextMenu`);
		}

		function handleMousedown(e){
			//Color picker
			let click_on_color_picker = false;
			if(me.changeColorContextMenu){
				const colorPicker = document.querySelector('.color-picker');
				if(colorPicker){
					click_on_color_picker = colorPicker.contains(e.target);
				}
			}

			//Don't close context menu if click on context menu or color picker
			if(me.itemContextMenu && !me.itemContextMenu.contains(e.target) && !click_on_color_picker){
				me.hideContextMenus();
				me.close_context_menu = true;
				// e.preventDefault();
			}
		}

		document.removeEventListener("mousedown", handleMousedown);
		document.addEventListener("mousedown", handleMousedown);
	}


	get_make_gantt_change_color_context_menu(){
		$("[data-fieldname=gantt_color]").remove();
		const changeColorContainer = document.getElementById(`changeColorContainer`)
		if(changeColorContainer){
			this.changeColorContextMenu = frappe.ui.form.make_control({
				parent: changeColorContainer,
				df: {
					fieldname: 'gantt_color',
					fieldtype: 'Color',
					hidden: false
				},
				render_input: true
			})

			//Clear elements for context menu
			var clearfixElement = document.querySelector('#changeColorContainer .clearfix');
			if(clearfixElement){
				clearfixElement.remove();
			}
			var formGroupElement = document.querySelector('#changeColorContainer .form-group');
			if(formGroupElement){
				formGroupElement.style.margin = '0';
			}
		}
		
	}


	itemContextMenuHandler(e, options){
		if(this.itemContextMenu){
			let x = e.clientX + window.scrollX;
			let y = e.clientY + window.scrollY;

			this.itemContextMenu.style.display = "block";
			this.itemContextMenu.style.left = x + "px";
			this.itemContextMenu.style.top = y + "px";
			e.preventDefault();

			if(options){
				if(options.includes('schedule_item') && this.scheduleItemContextMenu){
					this.scheduleItemContextMenu.style.display = "block";
				}
				
				if(!options.includes('schedule_item') && this.scheduleItemContextMenu){
					this.scheduleItemContextMenu.style.display = "none";
				}

				if(options.includes('change_color')){
					this.get_make_gantt_change_color_context_menu()
				}

				if(!options.includes('change_color')){
					$("[data-fieldname=gantt_color]").remove();
				}
			}
		}
	}


	hideContextMenus() {
		if(this.itemContextMenu){
			this.itemContextMenu.style.display = "none";
		}
	}


	hide_all_options(select_input){
		var options = select_input.querySelectorAll("option");
		options.forEach(function(option) {
			option.style.display = "none";
		});
	}


	display_options(select_input, options_to_display){
		this.hide_all_options(select_input)
		options_to_display.forEach(function(optionValue) {
			var option = select_input.querySelector("option[value='" + optionValue + "']");
			if (option) {
				option.style.display = "block";
			}
		});
	}


	update_from_selected_major_scale_type()
	{
		let me = this
		switch (me.gantt_major_scale_type_select.value) {
			case 'Months':
				// me.gantt_major_scale_format_select.value = 'Month'
				// var options = [
				// 	['Month'],
				// 	['MonthAbbreviation'],
				// 	['MonthYear']
				// ]
				// me.display_options(me.gantt_major_scale_format_select, options)
				me.gantt_minor_scale_type_select.value = 'Days';
				break;
			case 'Weeks':
				// me.gantt_major_scale_format_select.value = 'DayOfWeekAbbreviation'
				// var options = [
				// 	['DayOfWeek'],
				// 	['DayOfWeekAbbreviation'],
				// 	['Day']
				// ]
				// me.display_options(me.gantt_major_scale_format_select, options)
				me.gantt_minor_scale_type_select.value = 'Days';
				break;
			case 'Days':
				// me.gantt_major_scale_format_select.value = 'Day'
				// var options = [
				// 	['Day']
				// ]
				// me.display_options(me.gantt_major_scale_format_select, options)
				me.gantt_minor_scale_type_select.value = 'Hours';
				break;
		}
		me.update_from_selected_minor_scale_type();
	}


	update_from_selected_minor_scale_type()
	{
		let me = this
		switch (me.gantt_minor_scale_type_select.value) {
			case 'Days':
				me.gantt_zoom_level.setAttribute('min', 1)
				me.gantt_zoom_level.setAttribute('max', 25)
				me.gantt_zoom_level.setAttribute('step', 0.5) // 0.25
				me.gantt_zoom_level.value = 5
				// me.gantt_minor_scale_format_select.value = 'Day'
				// var options = [
				// 	['Day']
				// ]
				// me.display_options(me.gantt_minor_scale_format_select, options)
				break;
			case 'Hours':
				me.gantt_zoom_level.setAttribute('min', 20)//20
				me.gantt_zoom_level.setAttribute('max', 200)//200
				me.gantt_zoom_level.setAttribute('step', 1)//0.5
				me.gantt_zoom_level.value = 25
				// me.gantt_minor_scale_format_select.value = 'Hour';
				// var options = [
				// 	['Hour'],
				// 	['DateTime']
				// ]
				// me.display_options(me.gantt_minor_scale_format_select, options)
				break;
		}
	}


	get_color_from_mode(mode){
		switch(cint(mode)){
			//Info
			case 0:
				return 'blue'
			//Warning
			case 1:
				return 'red'
		}
	}


	display_scheduling_warning_message(mode, msg){
		if (this.gantt_scheduling_warning){
			this.gantt_scheduling_warning.textContent = __(msg);
			this.gantt_scheduling_warning.style.color = this.get_color_from_mode(mode)
		}
	}

	display_stacking_warning_message(mode, msg){
		if (this.schedulechart_stacking_warning){
			this.schedulechart_stacking_warning.textContent = __(msg);
			this.schedulechart_stacking_warning.style.color = this.get_color_from_mode(mode)
		}
	}

	clear_stacking_warning_message(msg){
		if (this.schedulechart_stacking_warning){
			this.schedulechart_stacking_warning.textContent = '';
		}
	}


	initialize_scales(settings){
		let me = this;
		if(me.timeline_control){
			let scales = [];
			//Major scale
			scales.push({
				scaleType: (me.gantt_major_scale_type_select ? me.gantt_major_scale_type_select.value: 'Months'), 
				headerTextFormat: (me.gantt_major_scale_format_select ? me.gantt_major_scale_format_select.value : 'Month'), 
				headerStyle: 'padding: 2.25px; border-right: solid 1px #c8bfe7; border-bottom: solid 1px #c8bfe7', 
				isSeparatorVisible: true,
				separatorStyle: 'stroke: #c8bfe7'
			});
			//Minor scale
			scales.push({
				scaleType: (me.gantt_minor_scale_type_select ? me.gantt_minor_scale_type_select.value : 'Days'), 
				headerTextFormat: (me.gantt_minor_scale_format_select ? me.gantt_minor_scale_format_select.value : 'Day'), 
				headerStyle: 'padding: 2.25px; border-right: solid 1px #c8bfe7; border-bottom: solid 1px #c8bfe7', 
				isSeparatorVisible: false, 
				separatorStyle: 'stroke: #c8bfe7'
			});
			//Current time
			scales.push({
				scaleType: 'CurrentTime', 
				isHeaderVisible: false, 
				isSeparatorVisible: true,
				isHighlightingVisible: true,
				highlightingStyle: 'stroke-width: 0; fill: #f3f2f2', 
				separatorStyle: 'stroke: Red; stroke-width: 1px'
			})
			//Non Working
			scales.push({
				scaleType: 'NonworkingTime',
				isHeaderVisible: false, 
				isHighlightingVisible: true,
				highlightingStyle: 'stroke-width: 0; fill: #f3f2f2;'
			})
	
	
			settings.scales = scales;
	
			//Zoom level
			me.initialize_scales_zoom_level(
				settings,
				(me.gantt_zoom_level ? me.gantt_zoom_level.value : 5),
				(me.gantt_zoom_level ? me.gantt_zoom_level.getAttribute('min') : 1),
				(me.gantt_zoom_level ? me.gantt_zoom_level.getAttribute('max') : 25)
			)
		}
	}


	initialize_scales_zoom_level(settings, zoom_level, min, max){
		//Zoom level
		settings.updateScale = 15 * 60 * 1000; //15 min
		if (flt(zoom_level) > 0)
			if (min){
				settings.isMouseWheelZoomEnabledMinHourWidth = cint(min);
			}
			if (max){
				settings.isMouseWheelZoomEnabledMaxHourWidth = cint(max);
			}
			settings.hourWidth = flt(zoom_level);
	}


	increase_timeline(chart, time_amount_day){
		if (chart) {
			chart.increaseTimelinePage(cint(time_amount_day) * 7 * 24 * 60 * 60 * 1000);
		}
	}


	decrease_timeline(chart, time_amount_day){
		if (chart) {
			chart.decreaseTimelinePage(cint(time_amount_day) * 7 * 24 * 60 * 60 * 1000);
		}
	}


	static change_timeline(settings, time_amount_day, item = null){
		let start_date = ((item && !isNaN(item.start)) ? new Date(item.start) : new Date())
		let end_date = ((item && !isNaN(item.finish)) ? new Date(item.finish) : new Date())
		start_date.setDate(start_date.getDate() - (cint(time_amount_day) * 7));
		end_date.setDate(end_date.getDate() + (cint(time_amount_day) * 7));
		settings.timelineStart = start_date;
		settings.timelineFinish = end_date;
	}

	static darken_color(hex, pourcentage) {
		// Supprimer le "#" au début si présent
		hex = hex.replace(/^#/, '');
	
		// Extraire les valeurs rouges, vertes et bleues
		let r = parseInt(hex.substring(0, 2), 16);
		let g = parseInt(hex.substring(2, 4), 16);
		let b = parseInt(hex.substring(4, 6), 16);
	
		// Appliquer la réduction de luminosité (en fonction du pourcentage)
		r = Math.floor(r * (1 - pourcentage / 100));
		g = Math.floor(g * (1 - pourcentage / 100));
		b = Math.floor(b * (1 - pourcentage / 100));
	
		// Assurer que les valeurs ne dépassent pas 0
		r = Math.max(0, r);
		g = Math.max(0, g);
		b = Math.max(0, b);
	
		// Convertir les valeurs RGB en hexadécimal
		let darken_color = '#' +
			r.toString(16).padStart(2, '0') +
			g.toString(16).padStart(2, '0') +
			b.toString(16).padStart(2, '0');
	
		return darken_color;
	}



}


silicon_ioi.scheduling.ioiGanttControl = ioiGanttControl;