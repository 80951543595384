// *******************************************************************************************************************************************
// Parent Class of all Document (transaction) Doctypes : for quotes, orders, deliveries, invoices ...
// *******************************************************************************************************************************************

frappe.provide('silicon_ioi.doctype');

export class ioiDocumentDocType extends silicon_ioi.doctype.ioiDocType
{
	path_general_settings = 'silicon_ioi.ioi_configuration.doctype.ioi_general_settings.ioi_general_settings';
	path_buttons = '/assets/silicon_ioi/images/buttons/';
	path_module_user_status = 'silicon_ioi.ioi_configuration.doctype.ioi_module_user_status.ioi_module_user_status';


	language_1 = '';
	language_2 = '';
	language_3 = '';
	language_4 = '';

	is_dark_mode = 0;

	ioiuserstatus = [];

	constructor(frm)
	{
		super(frm);

		let me = this;
		this.is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

		this.#load_general_settings();
	}

	// ***************************************************************************************************************************************
	// Loading of General Settings
	// ***************************************************************************************************************************************
	#load_general_settings()
	{
		let me = this;

		let method = this.path_general_settings + '.ioi_general_settings_get_data';

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{
							me.language_1 = r.message.language_1;
							me.language_2 = r.message.language_2;
							me.language_3 = r.message.language_3;
							me.language_4 = r.message.language_4;
						}
		});
	}


	// ***************************************************************************************************************************************
	// Add Lock/Unlock button
	// ***************************************************************************************************************************************
	add_lock_unlock_button()
	{
		let me = this;

		if (!me.frm.is_new())
		{
			if (me.frm.doc.enabled)
			{
				if (me.frm.doc.enabled == 0)
				{
					me.frm.add_custom_button(__('Enable'), function() { me.#enable(); });
				}else
				{
					me.frm.add_custom_button(__('Disable'), function() { me.#disable(); });
				}
			}else
			{
				me.frm.add_custom_button(__('Enable'), function() { me.#enable(); });
			}
		}
	}

	// ***************************************************************************************************************************************
	// Lock
	// ***************************************************************************************************************************************
	#enable()
	{
		this.frm.remove_custom_button('Enable');
		this.frm.set_value('enabled', 1);
		this.frm.save();
	}

	// ***************************************************************************************************************************************
	// UnLock
	// ***************************************************************************************************************************************
	#disable()
	{
		this.frm.remove_custom_button('Disable');
		this.frm.set_value('enabled', 0);
		this.frm.save();
	}

	// ***************************************************************************************************************************************
	// Set a description to a field
	// ***************************************************************************************************************************************
	set_fieldname_description(fieldname, description)
	{
		if (this.frm.fields_dict[fieldname])
		{
			this.frm.fields_dict[fieldname].set_label(description);
		}
	}

	set_descriptions()
	{
		this.set_fieldname_description('description_lg1', __('Description (not specified)'));
		this.set_fieldname_description('description_lg2', __('Description (not specified)'));
		this.set_fieldname_description('description_lg3', __('Description (not specified)'));
		this.set_fieldname_description('description_lg4', __('Description (not specified)'));

		if (this.language_1)
		{
			if(this.language_1.trim() != '')
			{
				this.set_fieldname_description('description_lg1', __('Description') + ' ' + this.language_1.toUpperCase());
			}
		}

		if (this.language_2)
		{
			if(this.language_2.trim() != '')
			{
				this.set_fieldname_description('description_lg2', __('Description') + ' ' + this.language_2.toUpperCase());
			}
		}

		if (this.language_3)
		{
			if(this.language_3.trim() != '')
			{
				this.set_fieldname_description('description_lg3', __('Description') + ' ' + this.language_3.toUpperCase());
			}
		}

		if (this.language_4)
		{
			if(this.language_4.trim() != '')
			{
				this.set_fieldname_description('description_lg4', __('Description') + ' ' + this.language_4.toUpperCase());
			}
		}
	}


	set_ext_descriptions()
	{
		this.set_fieldname_description('ext_description_lg1', __('Extended Description (not specified)'));
		this.set_fieldname_description('ext_description_lg2', __('Extended Description (not specified)'));
		this.set_fieldname_description('ext_description_lg3', __('Extended Description (not specified)'));
		this.set_fieldname_description('ext_description_lg4', __('Extended Description (not specified)'));

		if (this.language_1)
		{
			if(this.language_1.trim() != '')
			{
				this.set_fieldname_description('ext_description_lg1', __('Extended Description') + ' ' + this.language_1.toUpperCase());
			}
		}

		if (this.language_2)
		{
			if(this.language_2.trim() != '')
			{
				this.set_fieldname_description('ext_description_lg2', __('Extended Description') + ' ' + this.language_2.toUpperCase());
			}
		}

		if (this.language_3)
		{
			if(this.language_3.trim() != '')
			{
				this.set_fieldname_description('ext_description_lg3', __('Extended Description') + ' ' + this.language_3.toUpperCase());
			}
		}

		if (this.language_4)
		{
			if(this.language_4.trim() != '')
			{
				this.set_fieldname_description('ext_description_lg4', __('Extended Description') + ' ' + this.language_4.toUpperCase());
			}
		}
	}


	// ***************************************************************************************************************************************
	// Set query
	// ***************************************************************************************************************************************
	set_query(fieldname, childfieldname, method, filter = '')
	{
		if (childfieldname.trim() == '')
		{
			if (filter.trim() != '')
			{
				this.frm.set_query(fieldname, () => {
					return  {   query: method,
								filters : JSON.parse(filter)
							}
				});
			}else
			{
				this.frm.set_query(fieldname, () => {
					return  {   query: method
							}
				});

			}
		}else
		{
			if (filter.trim() != '')
			{
				this.frm.set_query(fieldname, childfieldname, () => {
					return  {   query: method,
								filters: JSON.parse(filter)
							}
				});
			}else
			{
				this.frm.set_query(fieldname, childfieldname, () => {
					return  {   query: method
							}
				});

			}
		}
	}

	// ***************************************************************************************************************************************
	// Load User Status
	// ***************************************************************************************************************************************
	fill_ioiuserstatus()
	{
		this.ioiuserstatus = [];

		let me = this;

		let method = this.path_module_user_status + '.ioi_module_user_status_get_list';

		frappe.call({  	method: method,
						args: {"doctype": this.frm.doctype},
						async: false,
						callback:function(r)	{
													if (r.message.length != 0)
													{
														me.ioiuserstatus = [];

														for (var i = 0; i < r.message.length; i++)
														{	//                    code            description       bgcolor          fontcolor
															me.ioiuserstatus[i] = [r.message[i][2],r.message[i][5], r.message[i][3], r.message[i][4]];

														}
													}

													if (me.ioiuserstatus.length == 0)
													{	me.ioiuserstatus[0] = [0, 'Undefined', '#C3C2C4', '#000000'];
													}else
													{
														let found_zero = false;

														for (var i = 0; i < me.ioiuserstatus.length; i++)
														{
															if (me.ioiuserstatus[i][0] == 0)
															{
																found_zero = true;
																break;
															}
														}

														if (!found_zero)
														{	me.ioiuserstatus[me.ioiuserstatus.length] = [0, 'Undefined', '#C3C2C4', '#000000'];
														}
													}
												}
		});
	}

	// ***************************************************************************************************************************************
	// Change user Status
	// ***************************************************************************************************************************************
	change_user_status()
	{
		if (this.frm.is_dirty())
		{
			let me = this;
			let fct_callback = function () { me.do_change_user_status(); };
			this.frm.save('Save', fct_callback);
		}else
		{
			this.do_change_user_status();
		}
	}

	do_change_user_status()
	{
		let me = this;

		var z = new frappe.ui.Dialog({
			'title': __("User status"),
			'fields': [
				{'fieldname': 'html_select_user_status', 'fieldtype': 'HTML'}

			],
			primary_action_label: 'Ok',
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

				let go = true;

				if (document.getElementById('user_status_nb_record').value == 0)
				{
					go = false;
				}

				if ((go) && (document.getElementById('user_status_nb_record').value != 0))
				{	go = false
					for (var i = 0; i < document.getElementById('user_status_nb_record').value; i++)
					{

						if (document.getElementById('user_status_checked_id_' + i.toString()))
						{
							if (document.getElementById('user_status_checked_id_' + i.toString()).checked)
							{
								document.getElementById('selected_user_status').value = document.getElementById('user_status_id_' + i.toString()).value;
								go = true;
								break;
							}
						}
					}
				}

				if (go)
				{
					z.hide();

					method = me.path_module_user_status + '.ioi_module_user_status_change_status';

					let can_change = false;

					let cur_user_status = 0;

					if (me.frm.doc.ioiuserstatus)
					{
						cur_user_status = me.frm.doc.ioiuserstatus;
					}

					frappe.call({  	method: method,
									args: {"doctype": me.frm.doctype, "name" : me.frm.doc.name, "from_status" : cur_user_status, "to_status" : document.getElementById('selected_user_status').value, "silent_mode" : true },
									async: false,
										callback:function(r)	{
																		can_change = true;
																}
					});

					if (can_change)
					{
						window.location.reload();
					}
				}else{
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
				}
			},
			secondary_action: function(){
				z.hide();
			}

		});

		if (document.getElementById('user_status_nb_record'))
		{
			for (var i = 0; i < document.getElementById('user_status_nb_record').value; i++)
			{
				if (document.getElementById('user_status_checked_id_' + i.toString()))
				{
					document.getElementById('user_status_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('user_status_id_' + i.toString()))
				{
					document.getElementById('user_status_id_' + i.toString()).remove();
				}

			}

			document.getElementById('user_status_nb_record').remove();
		}

		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:300px; width: 100%;">';

		html += '<table border=1 style="border: 1px solid #E8EAEB" width=100% data-custom-grid="true">';

		html += '<tr style="height:30px">';

		html += '<td width=5% align="center" style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=95% style="vertical-align: middle;">&nbsp;<b>' + __("User status") + '</b></td>';
		html += '</tr>';
		html += '</table>';



		let method = me.path_module_user_status + '.ioi_module_user_status_get_list';


		frappe.call({  	method: method,
						args: {	"doctype": this.frm.doctype},
						async: false,
						callback:function(r)	{
													if (r.message.length > 0)
													{
														html += '<table border=1 style="border: 1px solid #E8EAEB" width=100%>';

														let cur_userstatus = 0;

														if (me.frm.doc.ioiuserstatus)
														{
															cur_userstatus = me.frm.doc.ioiuserstatus;
														}

														let cpt = 0


														for (var i = 0; i < r.message.length; i++)
														{
															if (cur_userstatus != r.message[i][2])
															{
																html += '<tr style="height:30px">';

																html += '<td width=30px align="center" style="vertical-align: middle;">';
																html += '<input type="checkbox" id="user_status_checked_id_' + cpt.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
																html += '       onclick=" ';
																html += '					for (var i = 0; i < document.getElementById(\'user_status_nb_record\').value; i++) ';
																html += '					{   if (document.getElementById(\'user_status_checked_id_\' + i.toString())) ';
																html += '						{ '
																html += '							if (document.getElementById(\'user_status_checked_id_\' + i.toString()).id != this.id) ';
																html += '							{ ';
																html += '								document.getElementById(\'user_status_checked_id_\' + i.toString()).checked = false; ';
																html += '							} ';
																html += '						} ';
																html += '					} ';
																html += '" ';

																html += '>';
																html += '<input type="hidden" id="user_status_id_' + cpt.toString() + '" value="' +  r.message[i][2] + '">';
																html += '</td>';

																html += '<td bgcolor="' + r.message[i][3] + '" width=95% style="vertical-align: middle;">&nbsp;<font color="' + r.message[i][4] + '">' + r.message[i][5] + ' ( ' + r.message[i][2] + ' )' + '</font></td>'

																html += '</tr>';
															}

															cpt++;
														}
														html += '<input type="hidden" id="user_status_nb_record" value="' + cpt.toString() + '">';
														html += '</table>';
													}else
													{
														html += '<input type="hidden" id="user_status_nb_record" value="0">';
													}
												}
		});

		html += '<input type="hidden" id="selected_user_status" value="">';
		html += '</div>';


		z.fields_dict.html_select_user_status.$wrapper.html(html);

		z.show();
	}


}

silicon_ioi.doctype.ioiDocumentDocType = ioiDocumentDocType;
