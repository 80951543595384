import GridRow from 'frappe/public/js/frappe/form/grid_row';

export default class ioiGridRow extends GridRow {
	make() {
		this.grid.expand_columns_width = 0
		this.grid.keep_text_on_one_line = 0

		if (this.frm) {
			let saved_grid_data = frappe.model.user_settings[this.frm.doctype]
			&& this.grid.meta.name
			&& frappe.model.user_settings[this.frm.doctype].GridView
			&& frappe.model.user_settings[this.frm.doctype].GridView[this.grid.meta.name]
			&& frappe.model.user_settings[this.frm.doctype].GridView[this.grid.meta.name][0]

			if (saved_grid_data) {
				this.grid.expand_columns_width = saved_grid_data.expand_columns_width
				this.grid.keep_text_on_one_line = saved_grid_data.keep_text_on_one_line
			}
		}

		super.make()
	}

	sleep_static(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	async refresh_current_profile_doc() {
		let me = this;

		const selected_profile = $(this.grid_settings_dialog.fields_dict.selected_profile)[0].$input[0].value.trim();
		const dialog = this.grid_settings_dialog

		let is_standard = (!selected_profile || selected_profile != "NEW_PROFILE") && this.grid.profile_list[selected_profile].is_standard_access == 1 ? true : false;
		const css = { "pointer-events": is_standard ? "none" : "", "opacity": is_standard ? "0.65" : "1" }

		if (!selected_profile || selected_profile != "NEW_PROFILE") {
			dialog.new_profile_name_group.hide();

			await frappe.call({
				method: "silicon_ioi.ioi_system.doctype.ioi_gridrow_profile.ioi_gridrow_profile.get_profile",
				args: {
					profile_name: me.grid.profile_list[selected_profile].profile_name,
					doctype: this.grid.frm.doctype,
					field_name: this.grid.df.fieldname,
					user_owner: me.grid.profile_list[selected_profile].user_name
				},
				callback: function (e) {

					if (is_standard) {

						$(dialog.fields_dict.fields_html.$wrapper).css(css)
						$(dialog.fields_dict.roles.$wrapper).css(css)

						dialog.keep_text_on_one_line.disabled = true;
						dialog.expand_columns_width.disabled = true;
						dialog.is_public.disabled = true;

					} else {

						$(dialog.fields_dict.fields_html.$wrapper).css(css)
						$(dialog.fields_dict.roles.$wrapper).css(css)

						if (frappe.user.name != me.grid.profile_list[selected_profile].user_name && !frappe.user_roles.find(role => role === 'Administrator' || role === 'System Manager')) {
							$(dialog.fields_dict.fields_html)[0].$wrapper.hide();
							$(dialog.fields_dict.roles)[0].$wrapper.hide();

							dialog.is_public.disabled = true;
							dialog.keep_text_on_one_line.disabled = true;
							dialog.expand_columns_width.disabled = true;

						} else {

							dialog.keep_text_on_one_line.disabled = false;
							dialog.expand_columns_width.disabled = false;
							dialog.is_public.disabled = false;

							$(dialog.fields_dict.fields_html)[0].$wrapper.show();
							$(dialog.fields_dict.roles)[0].$wrapper.show();
						}
					}

					dialog.current_profile_doc = e;

					me.grid.expand_columns_width = e.message.expand_columns_width
					me.grid.keep_text_on_one_line = e.message.keep_text_on_one_line

					dialog.expand_columns_width.checked = dialog.current_profile_doc.message.expand_columns_width == '1';
					dialog.keep_text_on_one_line.checked = dialog.current_profile_doc.message.keep_text_on_one_line == '1';
					dialog.is_public.checked = dialog.current_profile_doc.message.is_public_access == '1';
					dialog.is_standard.checked = dialog.current_profile_doc.message.is_standard_access == '1';
				}
			});
		} else {

			$(dialog.fields_dict.fields_html.$wrapper).css(css)
			$(dialog.fields_dict.roles.$wrapper).css(css)
			dialog.keep_text_on_one_line.disabled = false;
			dialog.expand_columns_width.disabled = false;
		}
	}

	render_selected_columns(is_standard) {
		let fields = "";
		if (this.selected_columns_for_grid) {
			this.selected_columns_for_grid.forEach((d) => {
				let docfield = frappe.meta.get_docfield(this.grid.doctype, d.fieldname);

				fields += `
					<div class='control-input flex align-center form-control fields_order sortable-handle sortable'
						style='display: block; margin-bottom: 5px; padding: 0 8px; cursor: pointer; height: 32px;' data-fieldname='${
							docfield.fieldname
						}'
						data-label='${docfield.label}' data-type='${docfield.fieldtype}'>

						<div class='row'>
							<div class='col-1' style='padding-top: 4px'>
								<a style='cursor: grabbing;'>${frappe.utils.icon("drag", "xs")}</a>
							</div>
							<div class='col-6 col-md-8' style='padding-right:0px; padding-top: 5px;'>
								${__(docfield.label)}
							</div>
							<div class='col-3 col-md-2' style='padding-left:0px; padding-top: 2px; margin-top:-2px;' title='${__(
								"Columns"
							)}'>
								<input class='form-control column-width my-1 input-xs text-right'
									style='height: 24px; max-width: 80px; background: var(--bg-color); background-color: var(--modal-bg); display: inline;'
									value='${docfield.columns || cint(d.columns)}'
									data-fieldname='${docfield.fieldname}'
									${ is_standard ? 'disabled' : '' }
								>
							</div>
							<div class='col-1' style='padding-top: 3px;'>
								${ is_standard ? '' : 
									`<a class='text-muted remove-field' data-fieldname='${docfield.fieldname}'>
										<i class='fa fa-trash-o' aria-hidden='true'></i>
									</a>`
								}
							</div>
						</div>
					</div>`;
			});
		}

		$(this.fields_html_wrapper).find(".selected-fields").html(fields);

		this.prepare_handler_for_sort();
		this.select_on_focus();
		this.update_column_width();
		this.remove_selected_column();
	}

	async onProfileChange(gridrow, selected_profile) {
		await gridrow.refresh_current_profile_doc();
		gridrow.refresh_buttons_state();

		if (!selected_profile.value || selected_profile.value == "NEW_PROFILE") {
			gridrow.grid_settings_dialog.new_profile_name_group.show();
			gridrow.grid_settings_dialog.$wrapper.find('.btn-modal-primary')[0].innerText = __('Create');
			gridrow.grid_settings_dialog.is_public.disabled = false;
			$(gridrow.grid_settings_dialog.fields_dict.fields_html)[0].$wrapper.show();
			$(gridrow.grid_settings_dialog.fields_dict.roles)[0].$wrapper.show();
			return
		}
		gridrow.grid_settings_dialog.$wrapper.find('.btn-modal-primary')[0].innerText = __('Update');

		gridrow.fields_html_wrapper && $(gridrow.fields_html_wrapper).empty();

		gridrow.grid.selected_profile = selected_profile.value;

		gridrow.selected_columns_for_grid = gridrow.grid.profile_list[selected_profile.value].content.map(el => ({
			...el,
			expand_columns_width : gridrow.grid_settings_dialog.expand_columns_width,
			keep_text_on_one_line: gridrow.grid_settings_dialog.keep_text_on_one_line
		}))

		gridrow.columns = {};

		gridrow.update_user_settings_for_grid()
			.then(() => {
				gridrow.sleep_static(100).then(() => {
					gridrow.setup_table_editor(gridrow.grid_settings_dialog.is_standard.checked);
					$(gridrow.fields_html_wrapper).find('.add-new-fields').click(() => {
						gridrow.column_selector_for_dialog();
					});
				});
			})

		gridrow.get_profile_roles();
	}

	refresh_buttons_state() {
		if ($(this.grid_settings_dialog.fields_dict.selected_profile)[0].$input[0].value.trim() != 'NEW_PROFILE') {

			const profile = this.grid.profile_list[$(this.grid_settings_dialog.fields_dict.selected_profile)[0].$input[0].value.trim()]
			const user_name = profile.user_name
			const is_standard = profile.is_standard_access == 1 ? true : false;

			if (is_standard) {
				this.remove_profile_btn.attr('hidden', '');
				this.duplicate_profile_btn.attr('hidden', null);
				this.grid_settings_dialog.$wrapper.find('.btn-modal-secondary').attr('disabled', '');
			} else {
				if (frappe.user_roles.find(role => role === 'Administrator' || role === 'System Manager') || frappe.user.name == user_name) {
					this.remove_profile_btn.attr('hidden', null);
					this.duplicate_profile_btn.attr('hidden', '');
					this.grid_settings_dialog.$wrapper.find('.btn-modal-primary').attr('disabled', null);
					this.grid_settings_dialog.$wrapper.find('.btn-modal-secondary').attr('disabled', null);
				} else {
					this.remove_profile_btn.attr('hidden', '');
					this.duplicate_profile_btn.attr('hidden', null);
					this.grid_settings_dialog.$wrapper.find('.btn-modal-primary').attr('disabled', '');
					this.grid_settings_dialog.$wrapper.find('.btn-modal-secondary').attr('disabled', '');
				}
			}

		} else {
			this.remove_profile_btn.attr('hidden', '');
			this.duplicate_profile_btn.attr('hidden', '');
			this.grid_settings_dialog.$wrapper.find('.btn-modal-primary').attr('disabled', null); //btn-modal-secondary
			this.grid_settings_dialog.$wrapper.find('.btn-modal-secondary').attr('disabled', '');
		}

	}

	remove_current_profile() {
		let me = this;
		frappe.confirm(
			__("Are you sure you want to remove the profile «{0}»?", [
				this.grid_settings_dialog.grid.profile_list[$(me.grid_settings_dialog.fields_dict.selected_profile)[0].$input[0].value].profile_name,
			]),
			function () {
				frappe.call({
					method: "silicon_ioi.ioi_system.doctype.ioi_gridrow_profile.ioi_gridrow_profile.removeProfile",
					args: {
						profile_name: me.grid_settings_dialog.grid.profile_list[$(me.grid_settings_dialog.fields_dict.selected_profile)[0].$input[0].value].profile_name.trim(),
						doctype: me.frm.doctype,
						field_name: me.grid.df.fieldname
					},
					callback: function () {
						$(me.grid_settings_dialog.fields_dict.selected_profile)[0].$input[0].remove($(me.grid_settings_dialog.fields_dict.selected_profile)[0].$input[0].selectedIndex);

						if ($(me.grid_settings_dialog.fields_dict.selected_profile)[0].$input[0].value != "NEW_PROFILE") {
							me.onProfileChange(me, $(me.grid_settings_dialog.fields_dict.selected_profile)[0].$input[0]);
						} else {
							$(me.grid_settings_dialog.fields_dict.selected_profile)[0].$input.change();
						}

						me.grid.get_profiles().then(() => {
							me.grid.refresh_profile_selector_btn();
						});

					},
				});
			},
			function () {
				frappe.show_alert({
					message: __("Cancel removed profile"),
					indicator: "info",
				});
			}
		);
	}

	configure_dialog_for_columns_selector() {
		const me = this;
		let selectOptions = [];

		Object.keys(me.grid.profile_list).forEach(function (key, index) {
			selectOptions.push({
				label: me.grid.profile_list[key].profile_name + ((me.grid.profile_list[key].user_name != frappe.session.user) && (me.grid.profile_list[key].is_public_access || me.grid.profile_list[key].is_standard_access) && !frappe.user_roles.find(role => role === 'Administrator' || role === 'System Manager') ? ' &#x1F512' : ''),
				value: key
			});
		});

		selectOptions.push({ label: __("Create a new profile..."), value: "NEW_PROFILE" });

		const is_standard = this.grid.profile_list[this.grid.selected_profile]?.is_standard_access

		this.grid_settings_dialog = new frappe.ui.Dialog({
			title: __("Configure Columns"),
			fields: [
				{
					'fieldtype': 'Select',
					'fieldname': 'selected_profile',
					'label': __('Select a profile'),
					// 'options': [...this.grid.profiles_names.map(p => {
					// 	return {
					// 		label: p.profile,
					// 		value: p.profile
					// 	}
					// }), 
					'options': selectOptions,
					'default': Object.keys(this.grid.profile_list).length == 0 || this.grid.selected_profile == 'DEFAULT' ? 'NEW_PROFILE' : this.grid.selected_profile
				},
				{
					'fieldtype': 'Data',
					'fieldname': 'new_profile_name',
					'label': __('New profile name'),
					// 'depends_on': `eval:doc.selected_profile == "NEW_PROFILE"`
				},
				{
					'fieldtype': 'HTML',
					'fieldname': 'fields_html'
				},
				{
					'fieldtype': 'MultiSelectPills',
					'fieldname': 'roles',
					'label': __('Limit to roles'),
					'depends_on': `eval:frappe.user_roles.includes("System Manager")`,
					get_data: function (txt) {
						return frappe.db.get_link_options("Role", txt, { disabled: 0 });
					}
				},
				{
					"fieldname": "section",
					"fieldtype": "Section Break",
					"label": ""
				},
				{
					"default": "0",
					"fieldname": "expand_columns_width",
					"fieldtype": "Check",
					"label": __("More fields in multi-line")
				},
				{
					"default": "0",
					"fieldname": "keep_text_on_one_line",
					"fieldtype": "Check",
					"label": __("Keep text on one line")
				},
				{
					"default": "0",
					"fieldname": "is_public",
					"fieldtype": "Check",
					"label": __("Is public")
				},
				{
					"default": is_standard || 0,
					"fieldname": "is_standard",
					"fieldtype": "Check",
					"label": __("Is standard")
				},
				{
					"fieldname": "column_break",
					"fieldtype": "Column Break"
				}
			]
		});

		this.setup_table_editor(is_standard == 1 ? true : false);
		this.get_profile_roles();
		$(this.grid_settings_dialog.fields_dict.selected_profile)[0].$wrapper.on('change', function () {
			me.onProfileChange(me, $(me.grid_settings_dialog.fields_dict.selected_profile)[0].$input[0]);
		});
		this.grid_settings_dialog.grid = me.grid;
		this.grid_settings_dialog.gridrow = me;
		this.grid_settings_dialog.expand_columns_width = $(me.grid_settings_dialog.fields_dict.expand_columns_width)[0].$input[0];
		this.grid_settings_dialog.keep_text_on_one_line = $(me.grid_settings_dialog.fields_dict.keep_text_on_one_line)[0].$input[0];
		this.grid_settings_dialog.is_public = $(me.grid_settings_dialog.fields_dict.is_public)[0].$input[0];
		this.grid_settings_dialog.is_standard = $(me.grid_settings_dialog.fields_dict.is_standard)[0].$input[0];
		this.grid_settings_dialog.new_profile_name_group = $(me.grid_settings_dialog.fields_dict.new_profile_name)[0].$input.parent().parent().parent();
		this.grid_settings_dialog.is_standard.disabled = true;

		this.grid_settings_dialog.add_custom_action(__('Remove'), function () {
			me.remove_current_profile();
		}, 'btn-danger');

		this.grid_settings_dialog.add_custom_action(__('Duplicate'), function () {
			
			me.grid_settings_dialog.fields_dict.selected_profile.set_value('NEW_PROFILE').then(() => {
				me.grid_settings_dialog.is_public.disabled = false;
				me.grid_settings_dialog.is_public.checked = false;
				me.onProfileChange(me, 'NEW_PROFILE');
			});

		}, 'btn-info');



		this.remove_profile_btn = $($(this.grid_settings_dialog.custom_actions[0]).children(0)[0]);
		this.duplicate_profile_btn = $($(this.grid_settings_dialog.custom_actions[0]).children(1)[1]);



		me.refresh_buttons_state();


		$(this.fields_html_wrapper).find('.add-new-fields').click(() => {
			me.column_selector_for_dialog();
		});

		this.grid_settings_dialog.set_primary_action(Object.keys(this.grid.profile_list).length == 0 ? __('Create') : __('Update'), () => {
			me.validate_columns_width();
			me.columns = {};
			let is_create = false;
			const values = this.grid_settings_dialog.get_values()

			if (values.selected_profile == 'NEW_PROFILE') {
				if (values.new_profile_name && values.new_profile_name != '') {
					if (me.profile_name_exists(values.new_profile_name, this.frm.doctype, this.grid.df.fieldname)) {
						frappe.throw(__("Profile name already exists! Please specify other name for this new profile"));
					}
				} else {
					frappe.throw(__("Please specify a name for this new profile"));
				}
				is_create = true;
			}

			me.create_update_profile().then(() => {
				me.grid.get_profiles().then(() => {
					me.grid.selected_profile = me.new_id;
					me.grid.refresh_profile_selector_btn();
				});
			});

			me.grid_settings_dialog.hide();

			if (is_create) {
				frappe.show_alert({
					message: __("New profile added successfully"),
					indicator: "info",
				});
			} else {
				frappe.show_alert({
					message: __("Profile updated successfully"),
					indicator: "info",
				});
			}
		});

		this.grid_settings_dialog.set_secondary_action_label(__("Reset to default"));
		this.grid_settings_dialog.set_secondary_action(() => {
			me.reset_user_settings_for_grid();
			me.grid.refresh_profile_selector_btn();
			me.grid_settings_dialog.hide();
		});

		this.refresh_current_profile_doc();
		this.grid_settings_dialog.show();
	}

	setup_table_editor(is_standard) {
		this.grid.setup_visible_columns();
		this.setup_columns_for_dialog();
		this.prepare_wrapper_for_columns();
		this.render_selected_columns(is_standard);
	}

	get_profile_roles() {
		return;
		//utilité ??
		frappe.db.get_list("ioi GridRow Profile Roles", {
			filters: { parent: this.grid.selected_profile },
			fields: ["role"],
			pluck: "role"
		}).then(r => {
			this.grid_settings_dialog.get_field("roles").set_value(r)
		})
	}

	async create_update_profile() {
		const me = this;
		const values = this.grid_settings_dialog.get_values()

		let user
		let admin_update

		if (values.selected_profile !== "NEW_PROFILE") {
			user = values.selected_profile.split('•')[2].trim()
		}

		// Allow to edit for Admin role
		if (user && user !== frappe.session.user && frappe.user_roles.find(role => role === 'Administrator' || role === 'System Manager')) {
			admin_update = (values.selected_profile).trim()
		} else {
			admin_update = "false"
		}

		this.selected_columns_for_grid = this.selected_columns_for_grid.map(el => ({
			...el,
			expand_columns_width : this.grid_settings_dialog.expand_columns_width.checked ? 1 : 0,
			keep_text_on_one_line: this.grid_settings_dialog.keep_text_on_one_line.checked ? 1 : 0
		}))

		await frappe.call({
			method: "silicon_ioi.ioi_system.doctype.ioi_gridrow_profile.ioi_gridrow_profile.create_update_profile",
			args: {
				admin_update: admin_update,
				is_standard: this.grid_settings_dialog.is_standard.checked ? 1 : 0,
				profile_name: values.selected_profile == 'NEW_PROFILE' ? values.new_profile_name.trim() : this.grid_settings_dialog.grid.profile_list[$(this.grid_settings_dialog.fields_dict.selected_profile)[0].$input[0].value].profile_name.trim(),
				doctype: this.frm.doctype,
				field_name: this.grid.df.fieldname,
				columns: this.selected_columns_for_grid,
				expand_columns_width: this.grid_settings_dialog.expand_columns_width.checked ? 1 : 0,
				is_public: this.grid_settings_dialog.is_public.checked ? 1 : 0,
				keep_text_on_one_line: this.grid_settings_dialog.keep_text_on_one_line.checked ? 1 : 0,
				roles: values.roles
			},
			callback: function (e) {
				me.new_id = e.message;
			}
		})

		this.update_user_settings_for_grid()
	}

	profile_name_exists(profile_name, doc_type, field_name) {
		let result = false;
		frappe.call({
			method: "silicon_ioi.ioi_system.doctype.ioi_gridrow_profile.ioi_gridrow_profile.profileNameExists",
			args: {
				profile_name: profile_name.trim(),
				doctype: doc_type,
				field_name: field_name
			},
			async: false,
			callback: function (e) {
				result = e.message;
			}
		});
		return result;
	}

	update_user_settings_for_grid() {

		if (!this.selected_columns_for_grid || !this.frm) {
			return;
		}

		return frappe.model.user_settings.save(this.frm.doctype, 'GridViewProfile', this.grid.selected_profile)
			.then(() => {
				this.save_user_settings_for_gridview()
			})
	}

	save_user_settings_for_gridview() {
		let value = {};
		value[this.grid.doctype] = this.selected_columns_for_grid

		frappe.model.user_settings.save(this.frm.doctype, 'GridView', value)
			.then((r) => {
				frappe.model.user_settings[this.frm.doctype] = r.message || r;
				this.grid.reset_grid()
			});
	}

	validate_columns_width() {
		let total_column_width = 0.0;

		this.selected_columns_for_grid.forEach((row) => {
			if (row.columns && row.columns > 0) {
				total_column_width += cint(row.columns);
			}
		});

		if (this.grid_settings_dialog.expand_columns_width.checked && total_column_width <= 10) {
			this.grid.expand = 0
			frappe.throw(__("'More fields in double line' not needed with fewer than 10 columns"));
		}

		if (!this.grid_settings_dialog.expand_columns_width.checked && total_column_width > 10) {
			frappe.throw(__("The total column width cannot be more than 10 if 'More fields in multi-line' is not selected"));
		}

		if (this.grid && this.grid.expand_columns_width === 1 && total_column_width > 22 && this.grid_settings_dialog.expand_columns_width.checked) {
			frappe.throw(__("The total column width cannot be more than 22"));
		}

		if (this.grid && this.grid.expand_columns_width !== 1 && total_column_width > 10 && !this.grid_settings_dialog.expand_columns_width.checked) {
			frappe.throw(__("The total column width cannot be more than 10 if 'More fields in multi-line' is not selected"));
		}
	}
}