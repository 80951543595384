current_hash = window.location.hash;
call_validation();

//to allow locationchange event
(() => {
    let oldPushState = history.pushState;
    history.pushState = function pushState() {
        let ret = oldPushState.apply(this, arguments);
        window.dispatchEvent(new Event('pushstate'));
        window.dispatchEvent(new Event('locationchange'));
        return ret;
    };

    let oldReplaceState = history.replaceState;
    history.replaceState = function replaceState() {
        let ret = oldReplaceState.apply(this, arguments);
        window.dispatchEvent(new Event('replacestate'));
        window.dispatchEvent(new Event('locationchange'));
        return ret;
    };

    window.addEventListener('popstate', () => {
        window.dispatchEvent(new Event('locationchange'));
    });
})();


// Capture the locationchange event
window.addEventListener('locationchange', function(){
    call_validation();
});

var saved_doctype="";

function call_validation(){
	let doctype = window.location.href.split('/app/').pop().split('/')[0].replaceAll("-"," ");
	//to prevent multiple fire
	if (doctype!=saved_doctype){
		saved_doctype=doctype;
		if (doctype){
			//form
			frappe.call({
				method: "silicon_ioi.ioi_configuration.doctype.ioi_call_validation.ioi_call_validation.server_call",
				args:{
					doctype:doctype
				},
				async:true,
				callback: function (r) {
					if(r["message"]){
						if (r["message"]=="Error"){
							frappe.show_alert(__("An error occurred while contacting the distant server.<br>Please contact your reseller."),60);
						}else{
							if (r["message"]=="Prevented"){
								frappe.show_alert(__("{0} doctype is not enabled with your current licence.<br>Please contact your reseller.",[doctype]),60);
							}
							if (r["message"]=="Soon Expired"){
								frappe.show_alert(__("{0} doctype access will expire in less than two weeks.<br>Please contact your reseller.",[doctype]),60);
							}
							if (r["message"]=="Expired"){
								frappe.show_alert(__("{0} doctype access has expired.<br>Please contact your reseller.",[doctype]),60);
							}
							if (r["message"]=="Locked"){
								frappe.dom.freeze(__("{0} doctype access has expired for more than four weeks.<br>Please contact your reseller.",[doctype]));
								frappe.msgprint(__("{0} doctype access has expired for more than four weeks.<br>Please contact your reseller.",[doctype]));
							}
						}
					}
				},
			});
				
		}
	}
}

