import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "file-browser" }
const _hoisted_2 = { class: "file-browser-list" }
const _hoisted_3 = { class: "file-filter" }
const _hoisted_4 = ["placeholder"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TreeNode = _resolveComponent("TreeNode")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          type: "search",
          class: "form-control input-xs",
          placeholder: _ctx.__('Search by field name or label'),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.search_text) = $event)),
          onInput: _cache[1] || (_cache[1] = (...args) => ($options.search_by_name && $options.search_by_name(...args)))
        }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_4), [
          [_vModelText, $data.search_text]
        ])
      ]),
      _createVNode(_component_TreeNode, {
        class: "tree with-skeleton",
        node: $data.node,
        selected_node: $data.selected_node,
        onNodeClick: $options.toggle_node,
        onCreateParameter: $options.create_parameter
      }, null, 8 /* PROPS */, ["node", "selected_node", "onNodeClick", "onCreateParameter"])
    ])
  ]))
}