import DocumentBrowser from  "./DocumentBrowser.vue";
import { createApp, h } from 'vue';

frappe.provide("ioi.reporting")

ioi.reporting.document_structure = class ioiReportDocumentStructure {
	constructor(opts) {
		Object.assign(this, opts)

		this.show()
	}

	show() {
		this.dialog = new frappe.ui.Dialog({
			size: "large",
			title: __('Browse the structure of your document'),
			fields: [
				{
					fieldtype: 'HTML',
					fieldname: 'structure_html'
				}
			]
		})

		this.display_structure()
		this.dialog.show()
	}

	display_structure() {
		let me = this;
		
		let app = createApp({
			render: () => h(DocumentBrowser, {
							
								doctype: me.doctype,
								docname: me.docname,
						
						})
		})
		SetVueGlobals(app);
		app.mount(this.dialog.get_field("structure_html").wrapper);

		// new Vue({
		// 	el: this.dialog.get_field("structure_html").wrapper,
		// 	render: (h) =>
		// 		h(DocumentBrowser, {
		// 			props: {
		// 				doctype: this.doctype,
		// 				docname: this.docname,
		// 			},
		// 		}),
		// });
	}
}