frappe.provide('silicon_ioi.doctype');

export class ioiTimeVectorFrame
{
	static path_common = 'silicon_ioi.common.common';
	static path_time_vector = 'silicon_ioi.ioi_system.doctype.ioi_time_vector.ioi_time_vector';
	static path_warehouse = 'silicon_ioi.ioi_wms.doctype.ioi_warehouse.ioi_warehouse';

	static is_dark_mode = 0;
	static bgcolor = 0;

	static doctype = '';
	static idx = -1;
	static item_id = '';
	static item_mode = 0;
	static site_id = ''

	static origin = '';
	static pk_reference = '';
	static frame_height = -1;
	static table




	static load_config()
	{
		silicon_ioi.doctype.ioiTimeVectorFrame.is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;
		silicon_ioi.doctype.ioiTimeVectorFrame.bgcolor = '';

		silicon_ioi.doctype.ioiTimeVectorFrame.item_mode = 0;

		if (silicon_ioi.doctype.ioiTimeVectorFrame.item_id != '') {

			let method = silicon_ioi.doctype.ioiTimeVectorFrame.path_time_vector + '.ioi_time_vector_get_item_mode';

			frappe.call({  	method: method,
							args: {"item_id": silicon_ioi.doctype.ioiTimeVectorFrame.item_id },
							async: false,
							callback:function(r)	{

								silicon_ioi.doctype.ioiTimeVectorFrame.item_mode = r.message[0].mode;
							}
			});
		}


	}


	// ***************************************************************************************************************************************
	// Frame Time Vector : Load frame
	// ***************************************************************************************************************************************
	static refresh_time_vector(doctype = '', html_fieldname, item_id)
	{
		silicon_ioi.doctype.ioiTimeVectorFrame.doctype = doctype;
		silicon_ioi.doctype.ioiTimeVectorFrame.item_id = item_id;

		silicon_ioi.doctype.ioiTimeVectorFrame.load_config();

		if (document.getElementById('ioi_time_vector_warehouse_label')) {
			document.getElementById('ioi_time_vector_warehouse_label').remove();
		}

		if (document.getElementById('ioi_time_vector_warehouse')) {
			document.getElementById('ioi_time_vector_warehouse').remove();
		}

		if (document.getElementById('ioi_time_vector_from_label')) {
			document.getElementById('ioi_time_vector_from_label').remove();
		}

		if (document.getElementById('ioi_time_vector_from')) {
			document.getElementById('ioi_time_vector_from').remove();
		}

		if (document.getElementById('ioi_time_vector_to_label')) {
			document.getElementById('ioi_time_vector_to_label').remove();
		}

		if (document.getElementById('ioi_time_vector_to')) {
			document.getElementById('ioi_time_vector_to').remove();
		}

		if (document.getElementById('ioi_time_vector_topn_label')) {
			document.getElementById('ioi_time_vector_topn_label').remove();
		}

		if (document.getElementById('ioi_time_vector_topn')) {
			document.getElementById('ioi_time_vector_topn').remove();
		}

		if (document.getElementById('ioi_time_vector_search')) {
			document.getElementById('ioi_time_vector_search').remove();
		}

		if (document.getElementById('ioi_time_vector_clear')) {
			document.getElementById('ioi_time_vector_clear').remove();
		}

		if (document.getElementById('ioi_time_vector_instack_label')) {
			document.getElementById('ioi_time_vector_instack_label').remove();
		}

		if (document.getElementById('ioi_time_vector_compute_balances')) {
			document.getElementById('ioi_time_vector_compute_balances').remove();
		}

		if (document.getElementById('ioi_time_vector_content')) {
			document.getElementById('ioi_time_vector_content').remove();
		}

		if (document.getElementById('ioi_time_vector_data_tab')) {
			document.getElementById('ioi_time_vector_data_tab').remove();
		}

		if (document.getElementById('ioi_time_vector_data_tab_label')) {
			document.getElementById('ioi_time_vector_data_tab_label').remove();
		}

		if (document.getElementById('ioi_time_vector_data_tab_underline')) {
			document.getElementById('ioi_time_vector_data_tab_underline').remove();
		}

		if (document.getElementById('ioi_time_vector_chart_tab')) {
			document.getElementById('ioi_time_vector_chart_tab').remove();
		}

		if (document.getElementById('ioi_time_vector_chart_tab_label')) {
			document.getElementById('ioi_time_vector_chart_tab_label').remove();
		}

		if (document.getElementById('ioi_time_vector_chart_tab_underline')) {
			document.getElementById('ioi_time_vector_chart_tab_underline').remove();
		}

		if (document.getElementById('ioi_time_vector_chart_content')) {
			document.getElementById('ioi_time_vector_chart_content').remove();
		}

		if (document.getElementById('ioi_time_vector_header_grid')) {
			document.getElementById('ioi_time_vector_header_grid').remove();
		}

		if (document.getElementById('time_vector_detail')) {
			document.getElementById('time_vector_detail').remove();
		}


		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:60px;">';

		// Warehouse
		html += '<div style="position: relative; top: 0px; left: 0px; width:200px;">';
		html += '	<label id="ioi_time_vector_warehouse_label" style="position: absolute; top: 0px; left: 2px;">' + __("Warehouse") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 200px; height: 30px;"> ';
		html += '		<select id="ioi_time_vector_warehouse" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';

		let method = silicon_ioi.doctype.ioiTimeVectorFrame.path_warehouse + '.ioi_warehouse_from_current_site'
		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{
													for (var i = 0; i < r.message.length; i++)
													{
														if (r.message[i].not_in_need_vector != 1)
														{
															html += '		<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
														}
													}
												}
		});

		html += '		</select> ';
		html += '	</div>';
		html += '</div>';


		// From
		html += '<div style="position: relative; top: 0px; left: 210px; width:150px;">';
		html += '	<label id="ioi_time_vector_from_label" style="position: absolute; top: 0px; left: 2px;">' + __("From") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 150px; height: 30px;"> ';
		html += '		<input id="ioi_time_vector_from" type="date" class="input-with-feedback form-control bold">';
		html += '	</div>';
		html += '</div>';

		// To
		html += '<div style="position: relative; top: 0px; left: 370px; width:150px;">';
		html += '	<label id="ioi_time_vector_to_label" style="position: absolute; top: 0px; left: 2px;">' + __("To") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 150px; height: 30px;"> ';
		html += '		<input id="ioi_time_vector_to" type="date" class="input-with-feedback form-control bold" pattern="\y{4}-\m{2}-\d{2}">';
		html += '	</div>';
		html += '</div>';

		// Topn
		html += '<div style="position: relative; top: 0px; left: 530px; width:170px;">';
		html += '	<label id="ioi_time_vector_topn_label" style="position: absolute; top: 0px; left: 2px;">' + __("No records") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 170px; height: 30px;"> ';
		html += '		<input id="ioi_time_vector_topn" type="number" class="input-with-feedback form-control bold" min="1" max="1000" value="100">';
		html += '	</div>';
		html += '</div>';

		// Search
		html += '<div style="position: relative; top: 70px; left: 0px; width:90px;display:none;">';
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='		<button id="ioi_time_vector_search" class="btn btn-default ellipsis" style="height: 30px; width: 90px;" onclick="">' + __("Search") + '</button>';
		html += '	</div>';
		html += '</div>';

		// Clear
		html += '<div style="position: relative; top: 70px; left: 100px; width:50px;display:none;">';
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 30px;">';
		html +='		<button id="ioi_time_vector_clear" title="' + __("Clear") +'" data-label="Search" class="btn btn-default ellipsis" style="height: 30px; width: 70px;" onclick="">' + __("Clear") + '</button>';
		html += '	</div>';
		html += '</div>';


		// Balances compute
		html += '<div style="position: relative; top: 0px; left: 710px; width:90px;">';
		html += '	<label id="ioi_time_vector_instack_label" style="position: absolute; top: 0px; left: 2px;color:red;display:none;">' + __("In stack") + '</label>';
		html += '	<div style="position: absolute; top:25px; left: 2px; height: 30px">';
		html +='		<button id="ioi_time_vector_compute_balances" class="btn btn-default ellipsis" style="height: 30px; width: 200px;" onclick="">' + __("VT process (for this item)") + '</button>';
		html += '	</div>';
		html += '</div>';

		html += '</div>';

		html += '<div style="overflow-x: auto; height:35px;">';

		html += '	<div style="position:relative;"> ';

		// tab 1
		html += '		<div id="ioi_time_vector_data_tab" style="position:relative; top:0px; left:0px; width:100px; height:30px;"> ';
		html += '			<label align="center" id="ioi_time_vector_data_tab_label" style="position:absolute; top:2px;width:100px;"><font size="2">' + __("Result") + '</font></label>';
		html += '			<div id="ioi_time_vector_data_tab_underline" style="position:absolute; top:25px; left:0px; width:100%; height:1px; background-color:#2490EF;display:none;" data-custom-section-head="true"></div>';
		html += '		</div>';


		// tab 2
		html += '		<div id="ioi_time_vector_chart_tab" style="position:absolute; top:0px; left:100px; width:100px; height:30px;"> ';
		html += '			<label align="center" id="ioi_time_vector_chart_tab_label" style="position:absolute; top:2px;width:100px;"><font size="2">' + __("Chart") + '</font></label>';
		html += '			<div id="ioi_time_vector_chart_tab_underline" style="position:absolute; top:25px; left:0px; width:100%; height:1px; background-color:#2490EF;display:none;" data-custom-section-head="true"></div>';
		html += '		</div>';
		html += '	</div>';
		html += '</div>';

		let content_height = 565;

		if (silicon_ioi.doctype.ioiTimeVectorFrame.frame_height != -1) {
			content_height = silicon_ioi.doctype.ioiTimeVectorFrame.frame_height - 95;
		}


		// Grid Header
		html += '<div id="ioi_time_vector_content" style="overflow: auto; overflow-x: auto; height:' + content_height.toString() + 'px;">';

		html += '<div id="ioi_time_vector_grid" class="table table-bordered" data-custom-grid="true"></div>';

		html += '</div>'

		html += '<div id="ioi_time_vector_chart_content" style="overflow: auto; overflow-x: auto; height:' + content_height.toString() + 'px;"></div>';

		if (silicon_ioi.doctype.ioiTimeVectorFrame.doctype != '') {
			cur_frm.fields_dict[html_fieldname].$wrapper.empty();
			cur_frm.fields_dict[html_fieldname].$wrapper.append(html);
		}else{
			if (silicon_ioi.doctype.ioiTimeVectorFrame.idx != -1) {
				cur_frm.fields_dict['line_detail'].grid.grid_rows[silicon_ioi.doctype.ioiTimeVectorFrame.idx].grid_form.fields_dict[html_fieldname].$wrapper.empty();
				cur_frm.fields_dict['line_detail'].grid.grid_rows[silicon_ioi.doctype.ioiTimeVectorFrame.idx].grid_form.fields_dict[html_fieldname].$wrapper.append(html);
			}else{
				document.getElementById(html_fieldname).innerHTML = html;
			}
		}

		silicon_ioi.doctype.ioiTimeVectorFrame.time_vector_grid([])

		silicon_ioi.doctype.ioiTimeVectorFrame.sleep_static(400).then(() => {

			let fct_change = function() {
				silicon_ioi.doctype.ioiTimeVectorFrame.time_vector_search_on_change();
			};

			let fct_key_down = function(event) {

				if (event.keyCode == 13) {
					silicon_ioi.doctype.ioiTimeVectorFrame.time_vector_search_key_down();
				}
			};

			document.getElementById("ioi_time_vector_warehouse").onchange = fct_change;
			document.getElementById('ioi_time_vector_from').onkeydown = fct_key_down;
			document.getElementById('ioi_time_vector_from').onchange = fct_change;
			document.getElementById('ioi_time_vector_to').onkeydown = fct_key_down;
			document.getElementById('ioi_time_vector_to').onchange = fct_change;
			document.getElementById('ioi_time_vector_topn').onkeydown = fct_key_down;
			document.getElementById('ioi_time_vector_topn').onchange = fct_change;

			let fct_search = function () {
				silicon_ioi.doctype.ioiTimeVectorFrame.time_vector_refresh();

			};

			document.getElementById('ioi_time_vector_search').onclick = fct_search;

			let fct_clear = function () {
				silicon_ioi.doctype.ioiTimeVectorFrame.time_vector_clear();

			};

			document.getElementById('ioi_time_vector_clear').onclick = fct_clear;

			let fct_compute = function () {
				silicon_ioi.doctype.ioiTimeVectorFrame.time_vector_compute_balances();
			};

			document.getElementById('ioi_time_vector_compute_balances').onclick = fct_compute;



			let fct_tab_mouseover = function()  { silicon_ioi.doctype.ioiTimeVectorFrame.tab_mouseover(this); };
			let fct_tab_mouseleave = function() { silicon_ioi.doctype.ioiTimeVectorFrame.tab_mouseleave(this); };
			let fct_tab_click = function() { silicon_ioi.doctype.ioiTimeVectorFrame.tab_click(this); };

			// tab 1
			document.getElementById('ioi_time_vector_data_tab_label').onmouseover = fct_tab_mouseover;
			document.getElementById('ioi_time_vector_data_tab_label').onmouseleave = fct_tab_mouseleave;
			document.getElementById('ioi_time_vector_data_tab').onclick = fct_tab_click;

			// tab 2
			document.getElementById('ioi_time_vector_chart_tab_label').onmouseover = fct_tab_mouseover;
			document.getElementById('ioi_time_vector_chart_tab_label').onmouseleave = fct_tab_mouseleave;
			document.getElementById('ioi_time_vector_chart_tab').onclick = fct_tab_click;


			document.getElementById('ioi_time_vector_data_tab').click();

			silicon_ioi.doctype.ioiTimeVectorFrame.time_vector_refresh();

			silicon_ioi.doctype.ioiTimeVectorFrame.in_stack();



		});
	}

	static tab_mouseover(obj)
	{
		obj.style.cursor = 'pointer';
	}

	static tab_mouseleave(obj)
	{
		obj.style.cursor = 'none';
	}

	static tab_click(obj)
	{
		// tab 1
		document.getElementById('ioi_time_vector_data_tab_underline').style.display = 'none';
		document.getElementById('ioi_time_vector_data_tab_label').style.fontWeight = 'normal';
		document.getElementById('ioi_time_vector_content').style.display = 'none';

		// tab 2
		document.getElementById('ioi_time_vector_chart_tab_underline').style.display = 'none';
		document.getElementById('ioi_time_vector_chart_tab_label').style.fontWeight = 'normal';
		document.getElementById('ioi_time_vector_chart_content').style.display = 'none';



		if (obj.id == 'ioi_time_vector_data_tab')
		{
			// tab 1
			document.getElementById('ioi_time_vector_data_tab_underline').style.display = 'block';
			document.getElementById('ioi_time_vector_data_tab_label').style.fontWeight = 'bold';
			document.getElementById('ioi_time_vector_content').style.display = 'block';

		}else if (obj.id == 'ioi_time_vector_chart_tab')
		{
			// tab 2
			document.getElementById('ioi_time_vector_chart_tab_underline').style.display = 'block';
			document.getElementById('ioi_time_vector_chart_tab_label').style.fontWeight = 'bold';
			document.getElementById('ioi_time_vector_chart_content').style.display = 'block';

		}
	}


	static empty_htmlfield(doctype, html_fieldname) {

		let html = '';

		if (doctype != '') {

			cur_frm.fields_dict[html_fieldname].$wrapper.empty();
			cur_frm.fields_dict[html_fieldname].$wrapper.append(html);
		}else{
			document.getElementById(html_fieldname).innerHTML = html;
		}
	}



	// ***************************************************************************************************************************************
	// Frame Time Vector : Clear parameters and result
	// ***************************************************************************************************************************************
	static time_vector_clear()
	{
		document.getElementById("ioi_time_vector_warehouse").selectedIndex = 0;
		document.getElementById('ioi_time_vector_from').value = '';
		document.getElementById('ioi_time_vector_to').value = '';
		document.getElementById('ioi_time_vector_topn').value = '100';

		document.getElementById('ioi_time_vector_chart_content').innerHTML = '';

		if (silicon_ioi.doctype.ioiTimeVectorFrame.table.initialized) {
			silicon_ioi.doctype.ioiTimeVectorFrame.table.clearData()
		}
	}

	// ***************************************************************************************************************************************************************************
	// Time Vector Grid
	// ***************************************************************************************************************************************************************************

	static time_vector_grid(data)
	{

		let let_empty_if_zero = (cell) => {
			if (cell.getValue() != 0) {
				return cell.getValue()
			}
		}

		let format_date = (cell) => {
			if (cell.getValue() != null) {
				return cell.getValue().toString().substring(0, 16)
			}
		}

		let format_link = (cell, formatterParams) => {
			if (cell.getValue() != null && cell.getValue() != "") {
				let html = `<span onmouseover="this.style.textDecoration='underline'" onmouseout="this.style.textDecoration='none'" style="cursor: pointer;">${cell.getValue()}</span>`

				return html
			}
		}

		let link_onclick = (e, cell) => {
			if (cell.getValue() != null && cell.getValue() != "") {
				let doctype = cell.getData().doctype_id.toLowerCase()
				doctype = doctype.replaceAll(' ', '-')
				let doc_name = cell.getData().pk_name
				let child_name = cell.getData().child_pk_name

				let url = `/app/${doctype}/${doc_name}?id=${child_name}`


				if (doctype.toUpperCase() == 'IOI-SALES-QUOTE')	{
					silicon_ioi.doctype.ioiDocType.static_save_current_doc_tab('ioi Sales Quote', 'ioi-sales-quote-sales_quote_tab_detail-tab');
				} else if (doctype.toUpperCase() == 'IOI-SALES-ORDER') {
					silicon_ioi.doctype.ioiDocType.static_save_current_doc_tab('ioi Sales Order', 'ioi-sales-order-sales_order_tab_detail-tab');
				} else if (doctype.toUpperCase() == 'IOI-SALES-DELIVERY') {
					silicon_ioi.doctype.ioiDocType.static_save_current_doc_tab('ioi Sales Delivery', 'ioi-sales-delivery-sales_delivery_tab_detail-tab');
				} else if (doctype.toUpperCase() == 'IOI-SALES-INVOICE') {
					silicon_ioi.doctype.ioiDocType.static_save_current_doc_tab('ioi Sales Invoice', 'ioi-sales-invoice-sales_invoice_tab_detail-tab');
				} else if (doctype.toUpperCase() == 'IOI-PURCHASES-PRICE-REQUEST') {
					silicon_ioi.doctype.ioiDocType.static_save_current_doc_tab('ioi Purchases Price Request', 'ioi-purchases-price-request-purchases_quote_tab_detail-tab');
				} else if (doctype.toUpperCase() == 'IOI-PURCHASES-ORDER') {
					silicon_ioi.doctype.ioiDocType.static_save_current_doc_tab('ioi Purchases Order', 'ioi-purchases-order-purchases_order_tab_detail-tab');
				} else if (doctype.toUpperCase() == 'IOI-PURCHASES-RECEIPT') {
					silicon_ioi.doctype.ioiDocType.static_save_current_doc_tab('ioi Purchases Receipt', 'ioi-purchases-receipt-purchases_delivery_tab_detail-tab');
				} else if (doctype.toUpperCase() == 'IOI-PURCHASES-INVOICE') {
					silicon_ioi.doctype.ioiDocType.static_save_current_doc_tab('ioi Purchases Invoice', 'ioi-purchases-invoice-purchases_invoice_tab_detail-tab');
				} else if (doctype.toUpperCase() == 'IOI-STOCK-TRANSFER') {
					silicon_ioi.doctype.ioiDocType.static_save_current_doc_tab('ioi Stock Transfer', 'ioi-stock-transfer-stock_transfer_tab_detail-tab');
				} else if (doctype.toUpperCase() == 'IOI-PURCHASES-PROPOSAL-INTERNAL') {
					silicon_ioi.doctype.ioiDocType.static_save_current_doc_tab('ioi Purchases Proposals', 'ioi-purchases-proposals-ioi_purchases_proposals_detail_tab-tab');
				} else if (doctype.toUpperCase() == 'IOI-PRODUCTION') {
					silicon_ioi.doctype.ioiDocType.static_save_current_doc_tab('ioi Production', 'ioi-production-production_tab_detail-tab');
				} else if (doctype.toUpperCase() == 'IOI-DOSSIER') {
					silicon_ioi.doctype.ioiDocType.static_save_current_doc_tab('ioi Dossier', 'ioi-dossier-ioi_dossier_tab_detail-tab');
				}

				if (doctype.toUpperCase() != 'IOI-PURCHASES-PROPOSAL-INTERNAL') {
					window.open(url, "_blank")
				}else{
					let dc = 'ioi Purchases Proposals'
					let name = cell.getData().pk_name;

					silicon_ioi.doctype.ioiJITEngineLinkFrame.jump_to_single_module(dc, name);

				}
			}
		}

		let set_rows_cells_bg_colors = (row) => {
			if (silicon_ioi.doctype.ioiTimeVectorFrame.origin != '') {
				if (document.getElementById('ioi_time_vector_grid')) {
					if (silicon_ioi.doctype.ioiTimeVectorFrame.origin.toUpperCase() == 'ITEM') {
						if (row.getData().vt_doc_type == "STK") {
							row.getElement().classList.add('tabulator-selected')
						}
					} else if (silicon_ioi.doctype.ioiTimeVectorFrame.origin.toUpperCase() == 'PURCHASES_PROPOSAL') {
						if (row.getData().vt_doc_type == "PPR" && row.getData().calc_reference == silicon_ioi.doctype.ioiTimeVectorFrame.pk_reference) {
							row.getElement().classList.add('tabulator-selected')
						}
					} else if (silicon_ioi.doctype.ioiTimeVectorFrame.origin.toUpperCase() == 'IOI SALES ORDER') {
						if (row.getData().vt_doc_type == "SOR" && row.getData().calc_reference == silicon_ioi.doctype.ioiTimeVectorFrame.pk_reference) {
							row.getElement().classList.add('tabulator-selected')
						}
					} else if (silicon_ioi.doctype.ioiTimeVectorFrame.origin.toUpperCase() == 'IOI PURCHASES ORDER') {
						if (row.getData().vt_doc_type == "POR" && row.getData().calc_reference == silicon_ioi.doctype.ioiTimeVectorFrame.pk_reference) {
							row.getElement().classList.add('tabulator-selected')
						}
					} else if (silicon_ioi.doctype.ioiTimeVectorFrame.origin.toUpperCase() == 'IOI PRODUCTION') {
						if (row.getData().vt_doc_type == "ODP" && row.getData().calc_reference == silicon_ioi.doctype.ioiTimeVectorFrame.pk_reference) {
							row.getElement().classList.add('tabulator-selected')
						}
					} else if (silicon_ioi.doctype.ioiTimeVectorFrame.origin.toUpperCase() == 'IOI DOSSIER') {
						if (row.getData().vt_doc_type == "DOS" && row.getData().calc_reference == silicon_ioi.doctype.ioiTimeVectorFrame.pk_reference) {
							row.getElement().classList.add('tabulator-selected')
						}
					}
				}
			}

			if (row.getData().site_skip_running_total == 1) {
				row.getCells().map(cell => {
					cell.getElement().style.backgroundColor = "#c3c2c4"
					cell.getElement().style.color = "black"
				});
			}

			if ((row.getData().site_balance != null) && (row.getData().site_balance != 0)) {
				if (row.getData().site_balance < row.getData().site_level_alert) {
					let colored_cells = row.getCells().filter(cell => cell.getColumn().getField() == "site_balance")

					colored_cells.map(cell => {
						if (!row.getElement().classList.contains('tabulator-selected')) {
							cell.getElement().style.backgroundColor = "#FACD7A"
							cell.getElement().style.color = "black"
						}
					});
				}
			}

			if ((row.getData().whs_balance != null) && (row.getData().whs_balance != 0)) {
				if (row.getData().whs_balance < row.getData().whs_level_alert) {
					let colored_cells = row.getCells().filter(cell => cell.getColumn().getField() == "whs_balance")

					colored_cells.map(cell => {
						if (!row.getElement().classList.contains('tabulator-selected')) {
							cell.getElement().style.backgroundColor = "#FACD7A"
							cell.getElement().style.color = "black"
						}
					});
				}
			}

			if ((row.getData().vt_doc_type != 'STK') && (row.getData().vt_doc_type != 'CHK') && (row.getData().vt_doc_type != 'RES')) {
				if (row.getData().calc_reference != null) {
					if (row.getData().calc_reference.trim() != '') {
						if (row.getData().background_color != null) {
							let colored_cells = row.getCells().filter(cell => cell.getColumn().getField() == "calc_reference")

							colored_cells.map(cell => {
								if (!row.getElement().classList.contains('tabulator-selected')) {
									cell.getElement().style.backgroundColor = row.getData().background_color;
									cell.getElement().style.color = "black"
								}
							});
						}
					}
				}
			}

			if (row.getData().ioistatus != null) {
				if (row.getData().background_color != null) {
					let colored_cells = row.getCells().filter(cell => cell.getColumn().getField() == "ioistatus_description")

						colored_cells.map(cell => {
						if (!row.getElement().classList.contains('tabulator-selected')) {
							cell.getElement().style.backgroundColor = row.getData().background_color;
							cell.getElement().style.color = "black"
						}
					});
				}
			}

			if (row.getData().inhibited == 1) {
				row.getCells().map(cell => {
					cell.getElement().style.backgroundColor = "gray"
					cell.getElement().style.color = "black"
				});
			}
		}

		let h = 350;

		if (cur_frm.doctype.toUpperCase() == 'IOI ITEM') {

			h = 495;

			if (silicon_ioi.doctype.ioiTimeVectorFrame.frame_height != -1) {
				h = silicon_ioi.doctype.ioiTimeVectorFrame.frame_height - 95;
			}

		}



		silicon_ioi.doctype.ioiTimeVectorFrame.table = new ioi.Tabulator("#ioi_time_vector_grid", {
			maxHeight: h,
			data: data,
			layout: "fitColumns",
			selectableRows: false,
			movableColumns: true,
			resizableColumns: true,
			showProfiles:true,
			autoRedraw: true,
			rowFormatter: function(row){
				set_rows_cells_bg_colors(row)
			},
			columnDefaults : {
				minWidth: "100px"
			},
			columns: [
				{ title: __("Sheduled"), field: "doc_scheduled_pivot_datetime", formatter: format_date },
				{ title: __("Whs"), field: "warehouse_id" },
				{ title: __("Doc tp"), field: "vt_doc_type" },
				{ title: __("Document"), field: "calc_reference", formatter: format_link, cellClick: link_onclick },
				{ title: __("Qty"), field: "needed_qty", hozAlign: 'right', formatter: let_empty_if_zero },
				{ title: __("Res qty"), field: "reserved_qty", hozAlign: 'right', formatter: let_empty_if_zero },
				{ title: __("Site bal."), field: "site_balance", hozAlign: 'right', formatter: let_empty_if_zero },
				{ title: __("Whs bal."), field: "whs_balance", hozAlign: 'right', formatter: let_empty_if_zero },
				{ title: __("Site alert"), field: "site_level_alert", formatter: let_empty_if_zero },
				{ title: __("Whs alert"), field: "whs_level_alert", formatter: let_empty_if_zero },
				{ title: __("Computed on"), field: "vector_computed_on", formatter: format_date },
				{ title: __("Order"), field: "vt_sort_order" },
				{ title: __("Pivot doc date"), field: "doc_pivot_datetime", formatter: format_date },
				{ title: __("Site bal. W/o to handle"), field: "site_balance_wo_to_handle", hozAlign: 'right', formatter: let_empty_if_zero },
				{ title: __("Site bal. Effective"), field: "site_balance_wo_to_handle_wo_forecasted", hozAlign: 'right', formatter: let_empty_if_zero },
				{ title: __("Site bal, only out"), field: "site_balance_stock_out_only", hozAlign: 'right', formatter: let_empty_if_zero },
				{ title: __("Site bal, only out, W/o to handle"), field: "site_balance_wo_to_handle_stock_out_only", hozAlign: 'right', formatter: let_empty_if_zero },
				{ title: __("Site bal, only out, Effective"), field: "site_balance_wo_to_handle_wo_forecasted_stock_out_only", hozAlign: 'right', formatter: let_empty_if_zero },
				{ title: __("Whs. bal. W/o to handle"), field: "whs_balance_wo_to_handle", hozAlign: 'right', formatter: let_empty_if_zero },
				{ title: __("Whs. bal. Effective"), field: "whs_balance_wo_to_handle_wo_forecasted", hozAlign: 'right', formatter: let_empty_if_zero },
				{ title: __("Whs. bal, only out"), field: "whs_balance_stock_out_only", hozAlign: 'right', formatter: let_empty_if_zero },
				{ title: __("Whs. Bal, only out, W/o to handle"), field: "whs_balance_wo_to_handle_stock_out_only", hozAlign: 'right', formatter: let_empty_if_zero },
				{ title: __("Whs. Bal, only out, Effective"), field: "whs_balance_wo_to_handle_wo_forecasted_stock_out_only", hozAlign: 'right', formatter: let_empty_if_zero },
				{ title: __("Status"), field: "ioistatus_description" },
				{ title: __("Inhibited"), field: "inibited" },
			]
		});
	}

	// ***************************************************************************************************************************************
	// Frame Time Vector : Refresh result
	// ***************************************************************************************************************************************
	static time_vector_refresh()
	{
		let html = '';

		let chartx = [];
		let balance = [];
		let balance_caption = '';
		let alert_data = [];
		let alert_caption = '';
		let effective_data = [];
		let effective_caption = '';

		let item_unit = '';

		if ((silicon_ioi.doctype.ioiTimeVectorFrame.item_id) && (silicon_ioi.doctype.ioiTimeVectorFrame.item_id.trim() != ''))
		{
			if (silicon_ioi.doctype.ioiTimeVectorFrame.item_mode != 2)
			{
				let method = silicon_ioi.doctype.ioiTimeVectorFrame.path_time_vector + '.ioi_time_vector_get_list_from_current_site_item';

				frappe.call({
					method: method,
					args: {
						"item_id": silicon_ioi.doctype.ioiTimeVectorFrame.item_id,
						"warehouse_id": document.getElementById('ioi_time_vector_warehouse').value,
						"dt_from": document.getElementById('ioi_time_vector_from').value,
						"dt_to": document.getElementById('ioi_time_vector_to').value,
						"topn": document.getElementById('ioi_time_vector_topn').value
					},
					async: false,
					callback: function (r) {
						if (r.message.length > 0) {

							if (silicon_ioi.doctype.ioiTimeVectorFrame.table && silicon_ioi.doctype.ioiTimeVectorFrame.table.initialized) {
								silicon_ioi.doctype.ioiTimeVectorFrame.table.replaceData(r.message)
							} else {
								silicon_ioi.doctype.ioiTimeVectorFrame.table.on('tableBuilt', () => silicon_ioi.doctype.ioiTimeVectorFrame.table.replaceData(r.message))
							}

							let table_data = r.message

							for (let i = 0; i < r.message.length; i++) {
								let display_row = true;

								if ((table_data[i].vt_doc_type == 'CHK') || (table_data[i].vt_doc_type == 'RES')) {
									if ((!table_data[i].needed_qty) || (table_data[i].needed_qty == 0)) {
										display_row = false;
									}
								}

								if (display_row) {

									let balance_site = 0;
									let balance_whs = 0;
									let alert_site = 0;
									let alert_whs = 0;
									let effective_site = 0;
									let effective_whs = 0;

									if (table_data[i].item_unit_id != null) {
										item_unit = table_data[i].item_unit_id
									}

									let dt_to_add = '';

									if (table_data[i].doc_scheduled_pivot_datetime != null) {
										dt_to_add = table_data[i].doc_scheduled_pivot_datetime.toString().substring(0, 16)
									}

									chartx[chartx.length] = dt_to_add.toString();

									if ((table_data[i].site_balance != null) && (table_data[i].site_balance != 0)) {
										balance_site = table_data[i].site_balance;
									}

									if ((table_data[i].whs_balance != null) && (table_data[i].whs_balance != 0)) {
										balance_site = table_data[i].whs_balance;
									}

									if (document.getElementById('ioi_time_vector_warehouse').value == '') {
										balance[balance.length] = balance_site;
										balance_caption = __("Site balance");
									} else {
										balance[balance.length] = balance_whs;
										balance_caption = __("Whs balance");
									}

									if ((table_data[i].site_level_alert != null) && (table_data[i].site_level_alert != 0)) {
										alert_site = table_data[i].site_level_alert;
									}

									if ((table_data[i].whs_level_alert != null) && (table_data[i].whs_level_alert != 0)) {
										alert_whs = table_data[i].whs_level_alert;
									}

									if (document.getElementById('ioi_time_vector_warehouse').value == '') {
										alert_data[alert_data.length] = alert_site;
										alert_caption = __("Site alert level");
									} else {
										alert_data[alert_data.length] = alert_whs;
										alert_caption = __("Whs alert level");
									}

									if ((table_data[i].site_balance_wo_to_handle_wo_forecasted_stock_out_only != null) && (table_data[i].site_balance_wo_to_handle_wo_forecasted_stock_out_only != 0)) {
										effective_site = table_data[i].site_balance_wo_to_handle_wo_forecasted_stock_out_only;
									}

									if ((table_data[i].whs_balance_wo_to_handle_wo_forecasted_stock_out_only != null) && (table_data[i].whs_balance_wo_to_handle_wo_forecasted_stock_out_only != 0)) {
										effective_whs = table_data[i].whs_balance_wo_to_handle_wo_forecasted_stock_out_only;
									}

									if (document.getElementById('ioi_time_vector_warehouse').value == '') {
										effective_data[effective_data.length] = effective_site;
										effective_caption = __("Site balance");
										effective_caption = __("Site bal, only out");
									} else {
										effective_data[effective_data.length] = effective_whs;
										effective_caption = __("Whs. bal, only out");
									}
								}
							}
						} else {
							silicon_ioi.doctype.ioiTimeVectorFrame.table.replaceData()
						}
					}
				});
			} else {
				silicon_ioi.doctype.ioiTimeVectorFrame.time_vector_clear();
			}

		} else {
			silicon_ioi.doctype.ioiTimeVectorFrame.time_vector_clear();
		}

		silicon_ioi.doctype.ioiTimeVectorFrame.time_vector_build_chart(chartx, item_unit, balance, balance_caption, alert_data, alert_caption, effective_data, effective_caption);

	}

	static in_stack()
	{
		if ((silicon_ioi.doctype.ioiTimeVectorFrameSub.doctype.toUpperCase() == 'IOI ITEM') ||
			(silicon_ioi.doctype.ioiTimeVectorFrameSub.doctype.toUpperCase() == 'IOI ITEM HISTORY') ||
			(silicon_ioi.doctype.ioiTimeVectorFrameSub.doctype.toUpperCase() == 'IOI OPEN PRODUCTION BOM') ||
			(silicon_ioi.doctype.ioiTimeVectorFrameSub.doctype.toUpperCase() == 'IOI PRODUCTION') ||
			(silicon_ioi.doctype.ioiTimeVectorFrameSub.doctype.toUpperCase() == 'IOI PURCHASES OPEN ORDER') ||
			(silicon_ioi.doctype.ioiTimeVectorFrameSub.doctype.toUpperCase() == 'IOI PURCHASES PROPOSALS') ||
			(silicon_ioi.doctype.ioiTimeVectorFrameSub.doctype.toUpperCase() == 'IOI PURCHASES PROPOSALS INTERNAL') ||
			(silicon_ioi.doctype.ioiTimeVectorFrameSub.doctype.toUpperCase() == 'IOI SALES OPEN ORDER') ||
			(silicon_ioi.doctype.ioiTimeVectorFrameSub.doctype.toUpperCase() == 'IOI DOSSIER') ||
			(silicon_ioi.doctype.ioiTimeVectorFrameSub.doctype.toUpperCase() == 'IOI SITE STOCK'))
		{
			let exexcute_process = false;

			if ((silicon_ioi.doctype.ioiTimeVectorFrameSub.doctype.toUpperCase() == 'IOI ITEM') && (cur_frm.active_tab_map[cur_frm.doc.name]) && (cur_frm.active_tab_map[cur_frm.doc.name].wrapper[0].id == 'ioi-item-ioi_item_time_vector_tab')) {
				exexcute_process = true;
			}else if ((silicon_ioi.doctype.ioiTimeVectorFrameSub.doctype.toUpperCase() == 'IOI ITEM HISTORY') && (cur_frm.active_tab_map[cur_frm.doctype]) && (cur_frm.active_tab_map[cur_frm.doctype].wrapper[0].id == 'ioi-item-history-time_vector_tab')) {
				exexcute_process = true;
			}else if ((silicon_ioi.doctype.ioiTimeVectorFrameSub.doctype.toUpperCase() == 'IOI OPEN PRODUCTION BOM') ||
					(silicon_ioi.doctype.ioiTimeVectorFrameSub.doctype.toUpperCase() == 'IOI PURCHASES OPEN ORDER') ||
					(silicon_ioi.doctype.ioiTimeVectorFrameSub.doctype.toUpperCase() == 'IOI SALES OPEN ORDER') ||
					(silicon_ioi.doctype.ioiTimeVectorFrameSub.doctype.toUpperCase() == 'IOI DOSSIER') ||
					(silicon_ioi.doctype.ioiTimeVectorFrameSub.doctype.toUpperCase() == 'IOI SITE STOCK') ||
					(silicon_ioi.doctype.ioiTimeVectorFrameSub.doctype.toUpperCase() == 'IOI PURCHASES PROPOSALS') ||
					(silicon_ioi.doctype.ioiTimeVectorFrameSub.doctype.toUpperCase() == 'IOI PURCHASES PROPOSALS INTERNAL')) {
				exexcute_process = true;
			}else if ((silicon_ioi.doctype.ioiTimeVectorFrameSub.doctype.toUpperCase() == 'IOI PRODUCTION') && (cur_frm.active_tab_map[cur_frm.doc.name]) && (cur_frm.active_tab_map[cur_frm.doc.name].wrapper[0].id == 'ioi-production-production_tab_subproduct_docflow')) {
				exexcute_process = true;
			}

			if (exexcute_process) {
				let method = silicon_ioi.doctype.ioiTimeVectorFrame.path_time_vector + '.ioi_time_vector_item_is_in_stack';

				frappe.call({  	method: method,
								args: {"item_id": silicon_ioi.doctype.ioiTimeVectorFrame.item_id },
								async: false,
								callback:function(r)	{

									if (r.message == 1) {
										document.getElementById('ioi_time_vector_instack_label').style.display = 'block';
									}else{
										document.getElementById('ioi_time_vector_instack_label').style.display = 'none';
									}
								}
				});
			}

			setTimeout(silicon_ioi.doctype.ioiTimeVectorFrame.in_stack, 1000);
		}

	}

	static time_vector_build_chart(chartx, item_unit, balance, balance_caption, alert_data, alert_caption, effective_data, effective_caption)
	{
		if ((silicon_ioi.doctype.ioiTimeVectorFrame.item_id) && (silicon_ioi.doctype.ioiTimeVectorFrame.item_id.trim() != ''))
		{
			if (silicon_ioi.doctype.ioiTimeVectorFrame.item_mode != 2)
			{
				let chart = new frappe.Chart(

					"#ioi_time_vector_chart_content", {

					data: {
						labels: chartx,

						datasets: [
							{
								name: balance_caption, chartType: 'line',
								values: balance
							},
							{
								name: effective_caption, chartType: 'line',
								values: effective_data
							},
							{
								name: alert_caption, chartType: 'line',
								values: alert_data
							}
						]
					},

					title: "",
					type: 'line', // 'axis-mixed' or 'bar', 'line', 'pie', 'percentage'
					height: 400,
					colors: ['blue', 'green', 'red'],

					tooltipOptions: {
						formatTooltipX: d => (d + '').toUpperCase(),
						formatTooltipY: d => d + ' ' + item_unit,
					}
				});

			}else {
				document.getElementById('ioi_time_vector_chart_content').innerHTML = '';
			}
		}else{
			document.getElementById('ioi_time_vector_chart_content').innerHTML = '';
		}
	}


	static time_vector_search_key_down()
	{
		silicon_ioi.doctype.ioiTimeVectorFrame.table.clearData()

		document.getElementById('ioi_time_vector_search').click();
	}

	static time_vector_search_on_change()
	{
		if (document.getElementById('ioi_time_vector_warehouse')) {
			let hidden_columns_fields = ["site_balance", "site_level_alert", "site_balance_wo_to_handle", "site_balance_wo_to_handle_wo_forecasted", "site_balance_stock_out_only", "site_balance_wo_to_handle_stock_out_only", "site_balance_wo_to_handle_wo_forecasted_stock_out_only"]
			let columns_to_hide = silicon_ioi.doctype.ioiTimeVectorFrame.table.getColumns().filter(col => hidden_columns_fields.includes(col.getField()))

			if (document.getElementById('ioi_time_vector_warehouse').value.trim() != '') {
				columns_to_hide.map(col => col.hide())
			} else {

				// Check if there's a profile and if column is hidden in it

				if (!frappe.model.user_settings[cur_frm.doctype][silicon_ioi.doctype.ioiTimeVectorFrame.table.element.id]) {
					columns_to_hide.map(col => col.show())
				} else {
					frappe.db.get_value('ioi GridRow Profile', { name: frappe.model.user_settings[cur_frm.doctype][silicon_ioi.doctype.ioiTimeVectorFrame.table.element.id].profile_name }, ['content']).then(r => {
						let content = JSON.parse(r.message.content)

						for (let i = 0; i < columns_to_hide.length; i++) {
							for (let j = 0; j < content.length; j++) {
								if (columns_to_hide[i].getField() == content[j].field) {
									if (content[j].visible == true) {
										columns_to_hide[i].show()
									}

									break;
								}
							}
						}
					})
				}
			}
		}

		document.getElementById('ioi_time_vector_search').click();
	}

	static time_vector_compute_balances()
	{
		if ((silicon_ioi.doctype.ioiTimeVectorFrame.item_id) && (silicon_ioi.doctype.ioiTimeVectorFrame.item_id.trim() != ''))
		{
			if (silicon_ioi.doctype.ioiTimeVectorFrame.item_mode != 2)
			{
				// action to perform if Yes is selected

				let me = this;
				let method = silicon_ioi.doctype.ioiTimeVectorFrame.path_time_vector + '.ioi_time_vector_force_for_site_item';

				frappe.call({  	method: method,
								args: {	"item_id": silicon_ioi.doctype.ioiTimeVectorFrame.item_id},
								async: false,
								callback:function(r)	{

									silicon_ioi.doctype.ioiTimeVectorFrame.time_vector_refresh();

									if (silicon_ioi.doctype.ioiTimeVectorFrame.doctype.toUpperCase() == 'IOI ITEM') {
										silicon_ioi.doctype.ioiTimeVectorSummary.refresh_need_flat_balance('html_need_flat_balance', silicon_ioi.doctype.ioiTimeVectorFrame.site_id, silicon_ioi.doctype.ioiTimeVectorFrame.item_id)
										silicon_ioi.doctype.ioiTimeVectorSummary.refresh_time_vector_summary('html_time_vector_summary', silicon_ioi.doctype.ioiTimeVectorFrame.site_id, silicon_ioi.doctype.ioiTimeVectorFrame.item_id);
									}

								}
				});
			}else
			{
				silicon_ioi.doctype.ioiTimeVectorFrame.time_vector_clear();
			}
		}else{
			silicon_ioi.doctype.ioiTimeVectorFrame.time_vector_clear();
		}
	}


	static sleep_static(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}

}

silicon_ioi.doctype.ioiTimeVectorFrame = ioiTimeVectorFrame;
