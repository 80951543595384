frappe.provide('silicon_ioi.doctype');

export class ioiWmsDedicatedLocationFrame {
	static this
	static dossier_id
	static description
	static selected_option

	static build_panel(tab) {
		let panel = '';

		panel += '<div class="d-flex flex-column">';

		panel += '	<div class="d-flex w-100 flex-row flex-wrap mb-2">';

		// Dossier Search
		panel += `	<div class="mr-2" style="width:280px;">
						<label id="${tab}_search_label" style="">${__("Search")}</label>
						<div class="control-input" style="width: 280px;">
							<input id="${tab}_search" type="text" class="input-with-feedback form-control bold">
						</div>
					</div>`

		// Topn
		panel += `	<div class="mr-3" style="width:90px;">
						<label id="${tab}_topn_label" style="">${__("No records")}</label>
						<div class="control-input"> 
							<input id="${tab}_topn" type="number" class="input-with-feedback form-control bold" min="1" max="1000" value="100">
						</div>
					</div>`

		panel += '	</div>'

		panel += `	<div class="d-flex w-100 flex-row mb-2 flex-wrap" style="height: 28px;">
						<button id="${tab}_bt_search" title="${__("Search")}" data-label="Search" class="btn btn-xs btn-default ellipsis mr-2" style="width: 116px;" onclick="">${__("Search")}</button>
						<button id="${tab}_bt_clear" title="${__("...")}" data-label="Search" class="btn btn-xs btn-default ellipsis mr-2" style="width: 50px;" onclick="">${__("...")}</button>
						<button id="${tab}_bt_export" title="${__("Export")}" data-label="Export" class="btn btn-xs btn-default ellipsis mr-2" style="width: 100px;" onclick="">${__("Export")}</button>
					</div>`

		panel += `	<input id="${tab}_orderby" type="hidden" value="name">`;
		panel += `	<input id="${tab}_order" type="hidden" value="asc">`;
		panel += '</div>';

		return panel
	}

	static show_dossier_label(tab_name) {
		if (document.getElementById(`${tab_name}_desc_label`)) {
			document.getElementById(`${tab_name}_desc_label`).remove();
		}

		if (document.getElementById(`${tab_name}_dossier_label`)) {
			document.getElementById(`${tab_name}_dossier_label`).remove();
		}

		let html = '';

		// Item description
		html += '<div class="d-flex flex-column">';
		html += `	<label id="${tab_name}_desc_label">${__("Dossier")}</label>`
		html += `	<label id="${tab_name}_dossier_label">${silicon_ioi.doctype.ioiWmsDedicatedLocationFrame.dossier_id} - ${silicon_ioi.doctype.ioiWmsDedicatedLocationFrame.description}</label>`
		html += '</div>';

		return html
	}

	static build_wms_location() {
		let wms_location_tabs = [
			{ tab_name: 'wms_location', dt: 'ioi Warehouse Location' },
		]

		wms_location_tabs.map(tab => {
			const container = `
				<div id="html_wms_location_content" class="w-100">
					<div class="d-flex flex-row flex-wrap">
						${silicon_ioi.doctype.ioiWmsDedicatedLocationFrame.build_panel(tab.tab_name)}
						${silicon_ioi.doctype.ioiWmsDedicatedLocationFrame.show_dossier_label(tab.tab_name)}
					</div>
					<div id="${tab.tab_name}_grid" class="table table-bordered" data-custom-grid="true"></div>
				</div>`

			const wms_location_content = $('#html_wms_location_content')[0]

			if (wms_location_content) wms_location_content.remove()
			silicon_ioi.doctype.ioiWmsDedicatedLocationFrame.this.frm.fields_dict[`html_${tab.tab_name}`].$wrapper.append(container);

			silicon_ioi.doctype.ioiWmsDedicatedLocationFrame.build_wms_location_grid(tab.tab_name, tab.dt)
		})
	}

	static build_wms_location_grid(tab, dt) {

		let format_link = (cell, formatterParams) => {
			if (cell.getValue() != null && cell.getValue() != "") {
				return `<span style="cursor: pointer; text-decoration: underline;">${cell.getValue()}</span>`
			}
		}

		let link_onclick = (e, cell) => {
			if (cell.getValue() != null && cell.getValue() != "") {
				let url = ""

				if (cell.getField() == "item_id") {
					url = `/app/ioi-item/${cell.getData().item_id}`
				} else if (cell.getField() == "warehouse_location") {
					url = `/app/ioi-warehouse-stock-detail/${cell.getData().warehouse_id} • ${cell.getData().item_id}`
				}

				window.open(url, "_blank")
			}
		}

		let columns = [
			{ title: __("Warehouse"), field: "warehouse_id" },
			{ title: __("Warehouse location"), field: "warehouse_location", formatter: format_link, cellClick: link_onclick },
			{ title: __("Item"), field: "item_id", formatter: format_link, cellClick: link_onclick },
			{ title: __("Stock qty"), field: "q_stock" },
			{ title: __("Unit"), field: "unit_id" },
			{ title: __("Item description"), field: "description" },
			{ title: __("Remark"), field: "remark" },
		]

		let table = new ioi.Tabulator(`#${tab}_grid`, {
			maxHeight: 800,
			data: [],
			layout: "fitColumns",
			movableColumns: true,
			resizableColumns: true,
			showProfiles: true,
			autoRedraw: true,
			initialSort: [
				{ column: "warehouse_id", dir: "asc" },
			],
			columns: columns,
			columnDefaults: {
				minWidth: 100
			}
		});

		table.on("tableBuilt", () => {
			table.footerManager.element.setAttribute('data-custom-grid-bottom', 'true')
		});

		table.on("headerClick", function (e, column) {
			let ordered_field = document.getElementById(`${tab}_orderby`)
			ordered_field.value = column.getField()

			let ordered_dir = document.getElementById(`${tab}_order`)
			ordered_dir.value = table.getSorters()[0].dir

			silicon_ioi.doctype.ioiWmsDedicatedLocationFrame.col_click(tab)
		});

		silicon_ioi.doctype.ioiWmsDedicatedLocationFrame.wms_location_grid_search(table, dt, tab)

		document.getElementById(`${tab}_bt_search`).onclick = () => silicon_ioi.doctype.ioiWmsDedicatedLocationFrame.wms_location_grid_search(table, dt, tab)
		document.getElementById(`${tab}_bt_export`).onclick = () => silicon_ioi.doctype.ioiWmsDedicatedLocationFrame.export_data(table, tab)
		document.getElementById(`${tab}_bt_clear`).onclick = () => {
			document.getElementById(`${tab}_search`).value = ''
			document.getElementById(`${tab}_topn`).value = 100
			table.clearData()
		}

		document.getElementById('wms_location_search').addEventListener("keypress", function(event) {
			if (event.key === "Enter") {
				event.preventDefault();
				silicon_ioi.doctype.ioiWmsDedicatedLocationFrame.wms_location_grid_search(table, dt, tab)
			}
		});

		document.getElementById('wms_location_search').addEventListener("keypress", function(event) {
			if (event.key === "Enter") {
				event.preventDefault();
				silicon_ioi.doctype.ioiWmsDedicatedLocationFrame.wms_location_grid_search(table, dt, tab)
			}
		});
	}

	static col_click(tab) {
		if (document.getElementById(`${tab}_orderby`).value == 'name') {
			document.getElementById(`${tab}_orderby`).value = 'a.name';
		}

		document.getElementById(`${tab}_bt_search`).click();
	}

	static wms_location_grid_search(table, dt, tab) {
		let method = 'silicon_ioi.ioi_trakker.doctype.ioi_dossier_history.ioi_dossier_history.ioi_dossier_wms_location_get_list';

		frappe.call({
			method: method,
			args: {
				doctype: dt,
				dossier_id: silicon_ioi.doctype.ioiWmsDedicatedLocationFrame.dossier_id,
				search: document.getElementById(`${tab}_search`).value,
				topn: document.getElementById(`${tab}_topn`).value,
				orderby: document.getElementById(`${tab}_orderby`).value,
				order: document.getElementById(`${tab}_order`).value,
			},
			async: false,
			callback: function (r) {
				if (r.message.length > 0) {
					if (table.initialized) {
						table.setData(r.message)
					} else {
						table.on('tableBuilt', () => table.setData(r.message))
					}
				} else {
					if (table && table.initialized) table.clearData()
				}
			}
		})
	}

	static export_data(table, default_filename) {
		let export_tabulator = () => {
			let filename = document.getElementById('export_filename').value;
			let filetype = document.getElementById('export_file_type').value;

			if (filename.trim() == '') {
				filename = default_filename;
			}

			if (filetype.toUpperCase() == 'XLSX') {
				table.download("xlsx", `${filename}.xlsx`);
			} else {
				table.download("csv", `${filename}.csv`, { delimiter: ";" })
			}
		}

		silicon_ioi.ioiCommon.export_form(export_tabulator, true);
	}
}

silicon_ioi.doctype.ioiWmsDedicatedLocationFrame = ioiWmsDedicatedLocationFrame;