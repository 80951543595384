frappe.provide('silicon_ioi.doctype');

export class ioiBOMExploreFrame
{
	static path_item_manufacturing_definition = 'silicon_ioi.ioi_items.doctype.ioi_item_manufacturing_definition.ioi_item_manufacturing_definition';

	static tp = ''
	static key_value = ''

	static load_bom_explore_frame(html_field, tp, key_value)
	{
		silicon_ioi.doctype.ioiBOMExploreFrame.tp = tp;
		silicon_ioi.doctype.ioiBOMExploreFrame.key_value = key_value;


		if (document.getElementById('bom_explore_frame')) {
			document.getElementById('bom_explore_frame').remove();
		}


		if (document.getElementById('bom_explore_frame_item_label')) {
			document.getElementById('bom_explore_frame_item_label').remove();
		}

		if (document.getElementById('bom_explore_frame_item_id')) {
			document.getElementById('bom_explore_frame_item_id').remove();
		}

		if (document.getElementById('bom_explore_frame_show_position_label')) {
			document.getElementById('bom_explore_frame_show_position_label').remove();
		}

		if (document.getElementById('bom_explore_frame_show_position')) {
			document.getElementById('bom_explore_frame_show_position').remove();
		}


		if (document.getElementById('bom_explore_frame_show_release_label')) {
			document.getElementById('bom_explore_frame_show_release_label').remove();
		}

		if (document.getElementById('bom_explore_frame_show_release')) {
			document.getElementById('bom_explore_frame_show_release').remove();
		}



		if (document.getElementById('bom_explore_container')) {
			document.getElementById('bom_explore_container').remove();
		}


		let html = '';

		html += '<div style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true"><b>' + __("Parameters")  + '</b></div>';


		html += '<div id="bom_explore_frame" style="overflow: auto; overflow-x: auto; overflow-y: hidden; height:70px; border: 0px solid #E6E9EC;">';

		// Find item
		html += '	<div style="position: relative; top: 0px; left: 2px; width:240px;">';
		html += '		<label id="bom_explore_frame_item_label" style="position: absolute; top: 2px; left: 2px;z-index:5;">' + __("Find Item") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 240px; height: 25px;"> ';
		html += '			<input id="bom_explore_frame_item_id" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;">' ;
		html += '		</div>';

		html += '	</div>';

		// Show position
		html += '	<div style="position: relative; top: 0px; left: 252px; width:240px;">';
		html += '		<div class="control-input" style="position: absolute; top: 12px; left: 2px; width: 240px; height: 25px;"> ';
		html += '			<input id="bom_explore_frame_show_position" type="checkbox" class="input-with-feedback form-control bold" checked>' ;
		html += '		</div>';
		html += '		<label id="bom_explore_frame_show_position_label" style="position: absolute; top: 10px; left: 22px;z-index:5;">' + __("Show position") + '</label>';
		html += '	</div>';

		// Show release
		html += '	<div style="position: relative; top: 25px; left: 252px; width:240px;">';
		html += '		<div class="control-input" style="position: absolute; top: 12px; left: 2px; width: 240px; height: 25px;"> ';
		html += '			<input id="bom_explore_frame_show_release" type="checkbox" class="input-with-feedback form-control bold" checked>' ;
		html += '		</div>';
		html += '		<label id="bom_explore_frame_show_release_label" style="position: absolute; top: 10px; left: 22px;z-index:5;">' + __("Show BOM release") + '</label>';
		html += '	</div>';


		html += '</div>';

		html += '<div style="overflow: auto; overflow-x: auto; overflow-y: hidden; height:8px;">';
		html += '</div>';

		html += '<div style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true"><b>' + __("BOM explorer")  + '</b></div>';


		html += '<div id="bom_explore_container" style="overflow: auto; overflow-x: auto; overflow-y: auto; height:600px;border: 1px solid #E6E9EC;">';
		html += '</div>';

		cur_frm.fields_dict[html_field].$wrapper.empty();
		cur_frm.fields_dict[html_field].$wrapper.append(html);


		silicon_ioi.doctype.ioiBOMExploreFrame.sleep_static(200).then(() => {

			let fct_keyup = function(event) {

				if (event.keyCode == 13) {
					silicon_ioi.doctype.ioiBOMExploreFrame.refresh_bom();
				}
			}

			document.getElementById('bom_explore_frame_item_id').onkeyup = fct_keyup;

			let fct_click = function() {
				silicon_ioi.doctype.ioiBOMExploreFrame.refresh_bom();
			}

			document.getElementById('bom_explore_frame_show_position').onclick = fct_click;
			document.getElementById('bom_explore_frame_show_position').checked = true;

			document.getElementById('bom_explore_frame_show_release').onclick = fct_click;
			document.getElementById('bom_explore_frame_show_release').checked = false;

			silicon_ioi.doctype.ioiBOMExploreFrame.refresh_bom();

        });

	}

	static refresh_bom()
	{
		let cb_show_position = 0;

		if (document.getElementById('bom_explore_frame_show_position').checked) {
			cb_show_position = 1;
		}

		let cb_show_release = 0;

		if (document.getElementById('bom_explore_frame_show_release').checked) {
			cb_show_release = 1;
		}


		let method = silicon_ioi.doctype.ioiBOMExploreFrame.path_item_manufacturing_definition + '.ioi_item_manufacturing_definition_bom_hierarchy_html';

		let parameters = {}

		if (silicon_ioi.doctype.ioiBOMExploreFrame.tp.toUpperCase() == 'MANUFACTURING_DEFINITION') {


			parameters = { 	"ordre" : 1,
							"level" : 0,
							"manufacturing_definition_name": silicon_ioi.doctype.ioiBOMExploreFrame.key_value,
							"bom_release_id": null,
							"show_position": cb_show_position,
							"show_release" : cb_show_release,
							"find_item": document.getElementById('bom_explore_frame_item_id').value

						};
		}else if (silicon_ioi.doctype.ioiBOMExploreFrame.tp.toUpperCase() == 'BOM_RELEASE') {


			parameters = { 	"ordre" : 1,
							"level" : 0,
							"manufacturing_definition_name": null,
							"bom_release_id": silicon_ioi.doctype.ioiBOMExploreFrame.key_value,
							"show_position": cb_show_position,
							"show_release" : cb_show_release,
							"find_item": document.getElementById('bom_explore_frame_item_id').value

						};
		}



		frappe.call({  	method: method,
						args: parameters,
						async: false,
						callback:function(r)	{

							document.getElementById('bom_explore_container').innerHTML = r.message;

							silicon_ioi.doctype.ioiBOMExploreFrame.sleep_static(500).then(() => {


								var toggler = document.getElementsByClassName("caret");


								for (var i = 0; i < toggler.length; i++) {
									toggler[i].addEventListener("click", function() {
										this.parentElement.querySelector(".nested").classList.toggle("active");
										this.classList.toggle("caret-down");
									});
									toggler[i].click();
								}

							});

						}
		});

	}



	static sleep_static(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}

}

silicon_ioi.doctype.ioiBOMExploreFrame = ioiBOMExploreFrame;
