// *******************************************************************************************************************************************
// Parent Class of all Table Doctypes 
// *******************************************************************************************************************************************

frappe.provide('silicon_ioi.doctype'); 

export class ioiTableDocType extends silicon_ioi.doctype.ioiDocType
{
	path_general_settings = 'silicon_ioi.ioi_configuration.doctype.ioi_general_settings.ioi_general_settings';
	path_buttons = '/assets/silicon_ioi/images/buttons/';	

	language_1 = '';
	language_2 = '';
	language_3 = '';
	language_4 = '';

	constructor(frm) 
	{
		super(frm);
		this.#load_general_settings();
	}

	// ***************************************************************************************************************************************
	// Loading of General Settings
	// ***************************************************************************************************************************************
	#load_general_settings()
	{
		let me = this;

		let method = this.path_general_settings + '.ioi_general_settings_get_data';
		
		frappe.call({  	method: method, 
						args: {},
						async: false,
						callback:function(r)	{ 
							me.language_1 = r.message.language_1;
							me.language_2 = r.message.language_2;
							me.language_3 = r.message.language_3;
							me.language_4 = r.message.language_4;
						}
		});
	}


	// ***************************************************************************************************************************************
	// Add Lock/Unlock button
	// ***************************************************************************************************************************************
	add_lock_unlock_button()
	{
		let me = this;

		if (!me.frm.is_new())
		{
			if (me.frm.doc.enabled)
			{	
				if (me.frm.doc.enabled == 0)
				{	
					me.frm.add_custom_button(__('Enable'), function() { me.#enable(); });
				}else
				{	
					me.frm.add_custom_button(__('Disable'), function() { me.#disable(); });
				}
			}else
			{	
				me.frm.add_custom_button(__('Enable'), function() { me.#enable(); });		
			}
		}
	}

	// ***************************************************************************************************************************************
	// Lock
	// ***************************************************************************************************************************************
	#enable()
	{	
		this.frm.remove_custom_button('Enable');
		this.frm.set_value('enabled', 1);
		this.frm.save();
	}

	// ***************************************************************************************************************************************
	// UnLock
	// ***************************************************************************************************************************************
	#disable()
	{
		this.frm.remove_custom_button('Disable');
		this.frm.set_value('enabled', 0);
		this.frm.save();
	}

	// ***************************************************************************************************************************************
	// Set a description to a field
	// ***************************************************************************************************************************************
	set_fieldname_description(fieldname, description)
	{
		if (this.frm.fields_dict[fieldname])
		{
			this.frm.fields_dict[fieldname].set_label(description);
		}
	}

	set_descriptions()
	{	
		this.set_fieldname_description('description_lg1', __('Description (not specified)'));
		this.set_fieldname_description('description_lg2', __('Description (not specified)'));
		this.set_fieldname_description('description_lg3', __('Description (not specified)'));
		this.set_fieldname_description('description_lg4', __('Description (not specified)'));

		if (this.language_1)
		{
			if(this.language_1.trim() != '')
			{
				this.set_fieldname_description('description_lg1', __('Description') + ' ' + this.language_1.toUpperCase());
			}
		}

		if (this.language_2)
		{
			if(this.language_2.trim() != '')
			{
				this.set_fieldname_description('description_lg2', __('Description') + ' ' + this.language_2.toUpperCase());
			}
		}

		if (this.language_3)
		{
			if(this.language_3.trim() != '')
			{
				this.set_fieldname_description('description_lg3', __('Description') + ' ' + this.language_3.toUpperCase());
			}
		}

		if (this.language_4)
		{
			if(this.language_4.trim() != '')
			{
				this.set_fieldname_description('description_lg4', __('Description') + ' ' + this.language_4.toUpperCase());
			}
		}
	}


	set_ext_descriptions()
	{	
		this.set_fieldname_description('ext_description_lg1', __('Extended Description (not specified)'));
		this.set_fieldname_description('ext_description_lg2', __('Extended Description (not specified)'));
		this.set_fieldname_description('ext_description_lg3', __('Extended Description (not specified)'));
		this.set_fieldname_description('ext_description_lg4', __('Extended Description (not specified)'));

		if (this.language_1)
		{
			if(this.language_1.trim() != '')
			{
				this.set_fieldname_description('ext_description_lg1', __('Extended Description') + ' ' + this.language_1.toUpperCase());
			}
		}

		if (this.language_2)
		{
			if(this.language_2.trim() != '')
			{
				this.set_fieldname_description('ext_description_lg2', __('Extended Description') + ' ' + this.language_2.toUpperCase());
			}
		}

		if (this.language_3)
		{
			if(this.language_3.trim() != '')
			{
				this.set_fieldname_description('ext_description_lg3', __('Extended Description') + ' ' + this.language_3.toUpperCase());
			}
		}

		if (this.language_4)
		{
			if(this.language_4.trim() != '')
			{
				this.set_fieldname_description('ext_description_lg4', __('Extended Description') + ' ' + this.language_4.toUpperCase());
			}
		}
	}


	// ***************************************************************************************************************************************
	// Set query
	// ***************************************************************************************************************************************
	set_query(fieldname, childfieldname, method, filter = '')
	{
		if (childfieldname.trim() == '')
		{
			if (filter.trim() != '')
			{
				this.frm.set_query(fieldname, () => {
					return  {   query: method,
								filters : JSON.parse(filter)
							}
				});
			}else
			{
				this.frm.set_query(fieldname, () => {
					return  {   query: method
							}
				});

			}
		}else
		{
			if (filter.trim() != '')
			{
				this.frm.set_query(fieldname, childfieldname, () => {
					return  {   query: method,
								filters: JSON.parse(filter)
							}
				});
			}else
			{
				this.frm.set_query(fieldname, childfieldname, () => {
					return  {   query: method
							}
				});
	
			}
		}
	}



}

silicon_ioi.doctype.ioiTableDocType = ioiTableDocType;