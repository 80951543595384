frappe.provide('silicon_ioi');

const path_customer = 'silicon_ioi.ioi_sales.doctype.ioi_customer.ioi_customer';
const path_supplier = 'silicon_ioi.ioi_purchases.doctype.ioi_supplier.ioi_supplier';
const path_purchases_price = 'silicon_ioi.ioi_items.doctype.ioi_purchases_price.ioi_purchases_price';
const path_item = 'silicon_ioi.ioi_items.doctype.ioi_item.ioi_item';
const path_manufacturer_catalog = 'silicon_ioi.ioi_items.doctype.ioi_manufacturer_catalog.ioi_manufacturer_catalog';
const path_common = 'silicon_ioi.common.common'
const path_user = 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user';
const path_approval_document = 'silicon_ioi.ioi_configuration.doctype.ioi_approval_document.ioi_approval_document';
const path_buttons = '/assets/silicon_ioi/images/buttons/';
const path_advanced_search = 'silicon_ioi.ioi_configuration.doctype.ioi_module_advanced_search_definition.ioi_module_advanced_search_definition'

const leaflet_script = `<head>
						<link rel="stylesheet" href="https://unpkg.com/leaflet@1.9.2/dist/leaflet.css"
	 						integrity="sha256-sA+zWATbFveLLNqWO2gtiw3HL/lh1giY/Inf1BJ0z14="
	  					crossorigin=""/>

						<script src="https://unpkg.com/leaflet@1.9.2/dist/leaflet.js"
 		 					integrity="sha256-o9N1jGDZrf5tS+Ft4gbIK7mYMipq9lqpVJ91xHSyKhg="
 		 					crossorigin="">
						</script>
						</head>`;


export class ioiCommon
{
	// ***************************************************************************************************************************************
	// Customer Search : Select customer
	// ***************************************************************************************************************************************
	static select_customer(fields, fields_len, fields_desc, where, order_by, callback)
	{
		frappe.call(`${path_advanced_search}.ioi_common_advanced_search_definition_fields_get_list`, {
			doctype: 'ioi Customer',
		}).then(r => {
			var z = new frappe.ui.Dialog({
				'title': __("Select a customer"),
				'fields': [
					{'fieldname': 'html_select_customer', 'fieldtype': 'HTML'}

				],
				primary_action_label: 'Ok',
				secondary_action_label: __('Cancel'),
				primary_action: function(){

					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

					let go = true;
					let ret_value = '';


					if (document.getElementById('customer_nb_record').value == 0)
					{
						go = false;
					}

					if ((go) && (document.getElementById('customer_nb_record').value != 0))
					{	go = false;

						for (var i = 0; i < document.getElementById('customer_nb_record').value; i++)
						{

							if (document.getElementById('customer_checked_id_' + i.toString()))
							{
								if (document.getElementById('customer_checked_id_' + i.toString()).checked)
								{
									ret_value = document.getElementById('customer_id_' + i.toString()).value;;
									go = true;
									break;
								}
							}
						}
					}

					if (go)
					{
						z.hide();

						callback(ret_value);
					}else{
						document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
					}
				},
				secondary_action: function(){
					z.hide();
				}

			});

			if (document.getElementById('ioi_customer_search_label'))
			{	document.getElementById('ioi_customer_search_label').remove();
			}

			if (document.getElementById('ioi_customer_search'))
			{	document.getElementById('ioi_customer_search').remove();
			}


			if (document.getElementById('ioi_customer_topn_label'))
			{	document.getElementById('ioi_customer_topn_label').remove();
			}

			if (document.getElementById('ioi_customer_topn'))
			{	document.getElementById('ioi_customer_topn').remove();
			}

			if (document.getElementById('customer_fields'))
			{	document.getElementById('customer_fields').remove();
			}

			if (document.getElementById('customer_fields_len'))
			{	document.getElementById('customer_fields_len').remove();
			}

			if (document.getElementById('customer_fields_desc'))
			{	document.getElementById('customer_fields_desc').remove();
			}

			if (document.getElementById('customer_where'))
			{	document.getElementById('customer_where').remove();
			}

			if (document.getElementById('customer_order_by'))
			{	document.getElementById('customer_order_by').remove();
			}

			if (document.getElementById('ioi_customer_search_content'))
			{	document.getElementById('ioi_customer_search_content').remove();
			}

			if (document.getElementById('customer_col_order_by'))
			{	document.getElementById('customer_col_order_by').remove();
			}

			if (document.getElementById('customer_col_order_order'))
			{	document.getElementById('customer_col_order_order').remove();
			}

			if (r.message.length) {
				fields = "name, full_name"
				fields_desc = `${__('Identification')}, ${__('Name')}`
				fields_len = "200, 300"

				for (let i = 0; i < r.message.length; i++) {
					if (r.message[i].field.startsWith("ioi Customer")) {
						const field = r.message[i].field.replace('ioi Customer-', '')
						fields += `, ${field}`
					} else {
						fields += `, ${r.message[i].field}`
					}
					fields_desc += `, ${__(r.message[i].field_label)}`
					fields_len += `, ${r.message[i].field_width}`
				}
			}

			let html = '';

			html += '<div style="overflow-x: auto; width:100%;">';
			html += '	<input type="hidden" id="customer_fields" value="' + fields + '">';
			html += '	<input type="hidden" id="customer_fields_len" value="' + fields_len + '">';
			html += '	<input type="hidden" id="customer_fields_desc" value="' + fields_desc + '">';
			html += '	<input type="hidden" id="customer_where" value="' + where + '">';
			html += '	<input type="hidden" id="customer_order_by" value="' + order_by + '">';
			html += '	<input type="hidden" id="customer_col_order_by" value="name">';
			html += '	<input type="hidden" id="customer_col_order_order" value="asc">';

			html += '	<div style="overflow-x: auto; height:65px; width:100%;">';

			// Search
			html += '		<div style="position: relative; top: 0px; left: 0px; width:300px;">';
			html += '			<label id="ioi_customer_search_label" style="position: absolute; top: 0px; left: 2px;">' + __("Search") + '</label>';
			html += '			<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 300px; height: 25px;"> ';
			html += '				<input id="ioi_customer_search" type="text" class="input-with-feedback form-control bold">';
			html += '			</div>';
			html += '		</div>';

			// Topn
			html += '		<div style="position: relative; top: 0px; left: 310px; width:170px;">';
			html += '			<label id="ioi_customer_topn_label" style="position: absolute; top: 0px; left: 2px;">' + __("No records") + '</label>';
			html += '			<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 170px; height: 30px;"> ';
			html += '				<input id="ioi_customer_topn" type="number" class="input-with-feedback form-control bold" min="1" max="1000" value="100">';
			html += '			</div>';
			html += '		</div>';

			html += '	</div>';

			html += '	<div style="overflow-x: auto; height:10px; width:100%;"></div>';

			html += '	<div id="ioi_customer_search_content" style="overflow-x: auto; height:500px; width:100%;">';
			html += '	</div>';

			let tot_len = 0;
			let col_count = 0;

			let col_field = [];
			let col_desc = [];
			let col_len = [];

			if ((fields) && (fields.trim() != ''))
			{
				// field names
				let s = fields;

				while (s.indexOf(',') != -1)
				{
					let fn = s.substring(0, s.indexOf(','));
					fn = fn.trim();

					if (fn != '')
					{	col_field[col_count] = fn;
						col_count++;
					}

					s = s.substring(s.indexOf(',')+1, s.length);
				}

				if (s.trim() != '')
				{
					s = s.trim();
					col_field[col_count] = s;
				}

				col_count = 0;

				// field descriptions
				s = fields_desc;

				while (s.indexOf(',') != -1)
				{
					let fn = s.substring(0, s.indexOf(','));
					fn = fn.trim();

					if (fn != '')
					{	col_desc[col_count] = fn;
						col_count++;
					}

					s = s.substring(s.indexOf(',')+1, s.length);
				}

				if (s.trim() != '')
				{
					s = s.trim();
					col_desc[col_count] = s;
				}

				col_count = 0;

				// field lengths
				if ((fields_len) && (fields_len.trim() != ''))
				{
					s = fields_len;

					while (s.indexOf(',') != -1)
					{
						let n = s.substring(0, s.indexOf(','));
						n = n.trim();

						if (n != '')
						{	col_len[col_count] = parseInt(n);
							col_count++;
							tot_len += parseInt(n);
						}

						s = s.substring(s.indexOf(',')+1, s.length);
					}

					if (s.trim() != '')
					{
						s = s.trim();
						tot_len += parseInt(s);
					}
				}
			}

			let form_width = '50%';

			if (tot_len == 0)
			{
				col_field[0] = 'name';
				col_field[1] = 'full_name';

				col_desc[0] = __('Identification');
				col_desc[1] = __('Name');

				col_len[0] = 200;
				col_len[1] = 300;

				tot_len = 530;
				form_width = '30%';
			}else
			{ 	tot_len += 30;
			}

			z.fields_dict.html_select_customer.$wrapper.html(html);
			z.$wrapper.find('.modal-dialog').css("max-width", form_width).css("width", form_width);
			z.$wrapper.find('.modal-dialog').css("max-height", "50%").css("height", "50%");
			z.show();

			silicon_ioi.ioiCommon.select_sleep(250).then(() => {
				let fct_keyup = function(event)
				{
					if (event.keyCode == 13)
					{
						silicon_ioi.ioiCommon.customer_search(z);
					}
				}

				document.getElementById('ioi_customer_search').onkeyup = fct_keyup;
				document.getElementById('ioi_customer_topn').onkeyup = fct_keyup;
				silicon_ioi.ioiCommon.customer_search(z);
			});
		}
	)}

	// ***************************************************************************************************************************************
	// Customer Search : Search Term Key Up
	// ***************************************************************************************************************************************
	static customer_keyup(event)
	{
		if (event.keyCode == 13)
		{
			silicon_ioi.ioiCommon.customer_search();
		}
	}

	// ***************************************************************************************************************************************
	// Customer Search : Search
	// ***************************************************************************************************************************************
	static customer_search(z)
	{
		document.getElementById('ioi_customer_search_content').innerHTML = '';

		if (document.getElementById('customer_nb_record'))
		{
			for (var i = 0; i < document.getElementById('customer_nb_record').value; i++)
			{
				if (document.getElementById('customer_checked_id_' + i.toString()))
				{	document.getElementById('customer_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('customer_id_' + i.toString()))
				{	document.getElementById('customer_id_' + i.toString()).remove();
				}
			}

			document.getElementById('customer_nb_record').remove();
		}

		let tot_len = 0;
		let col_count = 0;

		let col_field = [];
		let col_desc = [];
		let col_len = [];

		if ((document.getElementById('customer_fields').value) && (document.getElementById('customer_fields').value.trim() != ''))
		{
			// field names
			let s = document.getElementById('customer_fields').value;

			while (s.indexOf(',') != -1)
			{
				let fn = s.substring(0, s.indexOf(','));
				fn = fn.trim();

				if (fn != '')
				{	col_field[col_count] = fn;
					col_count++;
				}

				s = s.substring(s.indexOf(',')+1, s.length);
			}

			if (s.trim() != '')
			{
				s = s.trim();
				col_field[col_count] = s;
			}

			col_count = 0;

			// field descriptions
			s = document.getElementById('customer_fields_desc').value;

			while (s.indexOf(',') != -1)
			{
				let fn = s.substring(0, s.indexOf(','));
				fn = fn.trim();

				if (fn != '')
				{	col_desc[col_count] = fn;
					col_count++;
				}

				s = s.substring(s.indexOf(',')+1, s.length);
			}

			if (s.trim() != '')
			{
				s = s.trim();
				col_desc[col_count] = s;
			}

			col_count = 0;

			// field lengths
			if ((document.getElementById('customer_fields_len').value) && (document.getElementById('customer_fields_len').value.trim() != ''))
			{
				s = document.getElementById('customer_fields_len').value;

				while (s.indexOf(',') != -1)
				{
					let n = s.substring(0, s.indexOf(','));
					n = n.trim();

					if (n != '')
					{	col_len[col_count] = parseInt(n);
						col_count++;
						tot_len += parseInt(n);
					}

					s = s.substring(s.indexOf(',')+1, s.length);
				}

				if (s.trim() != '')
				{
					s = s.trim();
					tot_len += parseInt(s);
				}
			}
		}

		if (tot_len == 0)
		{
			col_field[0] = 'name';
			col_field[1] = 'full_name';

			col_desc[0] = __('Identification');
			col_desc[1] = __('Name');

			col_len[0] = 200;
			col_len[1] = 300;

			tot_len = 530;
		}else
		{ 	tot_len += 30;
		}


		for (var i=0; i < col_field.length; i++)
		{
			if (document.getElementById('customer_search_col_label_' + col_field[i]))
			{	document.getElementById('customer_search_col_label_' + col_field[i]).remove();
			}

			if (document.getElementById('customer_search_col_' + col_field[i]))
			{	document.getElementById('customer_search_col_' + col_field[i]).remove();
			}

		}

		let html = '';

		let is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

		html += '<table border=1 style="border: 1px solid #E8EAEB" width=' + tot_len + 'px data-custom-grid="true">';

		html += '<tr style="height:30px">';

		html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';

		for (var i = 0; i < col_field.length; i++)
		{
			let sens = ''

			if (document.getElementById('customer_col_order_by').value.toUpperCase() == col_field[i].toUpperCase())
			{
				if (document.getElementById('customer_col_order_order').value.toUpperCase() == 'ASC')
				{	sens = '&darr;'
				}else
				{	sens = '&uarr;'
				}
			}

			html += '<td id="customer_search_col_' + col_field[i] + '" width=' + col_len[i] + 'px style="vertical-align: middle;">&nbsp;<b>' + col_desc[i] + '<label id="customer_search_col_label_' + col_field[i] + '" style="width:30px; height:8px" align="right">' + sens + '</label></b></td>';

		}

		if (document.getElementById('window_select_customer_detail')) {
			document.getElementById('window_select_customer_detail').remove();
		}

		html += '</tr>';
		html += '</table>';

		let method = path_customer + '.ioi_customer_search_form';

		frappe.call({  	method: method,
						args: {	"fields": document.getElementById('customer_fields').value, "conditions": document.getElementById('customer_where').value,
								"search" : document.getElementById('ioi_customer_search').value,
								"orderby": document.getElementById('customer_col_order_by').value,
								"orderdir": document.getElementById('customer_col_order_order').value,
								"topn": document.getElementById('ioi_customer_topn').value},
						async: false,
						callback:function(r)	{
													if (r.message.length > 0)
													{
														html += '<table id="window_select_customer_detail" border=1 style="border: 1px solid #E8EAEB" width=' + tot_len + 'px">';

														let cpt = 0;


														for (var i = 0; i < r.message.length; i++)
														{
															html += '<tr id="window_select_customer_row_' + i.toString() + '" style="height:30px">';

															html += '<td width=30px align="center" style="vertical-align: middle;">';
															html += '<input type="checkbox" id="customer_checked_id_' + cpt.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
															html += '       onclick=" ';
															html += '					for (var i = 0; i < document.getElementById(\'customer_nb_record\').value; i++) ';
															html += '					{   if (document.getElementById(\'customer_checked_id_\' + i.toString())) ';
															html += '						{ '
															html += '							if (document.getElementById(\'customer_checked_id_\' + i.toString()).id != this.id) ';
															html += '							{ ';
															html += '								document.getElementById(\'customer_checked_id_\' + i.toString()).checked = false; ';
															html += '							} ';
															html += '						} ';
															html += '					} ';
															html += '" ';

															html += '>';
															html += '<input type="hidden" id="customer_id_' + cpt.toString() + '" value="' +  r.message[i][0] + '">';
															html += '</td>';

															for (var k = 0; k < col_field.length; k++)
															{
																if (r.message[i][k] != null)
																{
																	let search_term = document.getElementById('ioi_customer_search').value;
																	search_term = search_term.trim();

																	if (search_term != '') {

																		search_term = search_term.toUpperCase();

																		let data = r.message[i][k].toString();

																		if (data != '') {

																			data = data.trim().toUpperCase();

																			if (data.indexOf(search_term) != -1) {
																				let from_idx = data.indexOf(search_term);
																				let len_search = search_term.length;

																				let begin_data = r.message[i][k].toString().substring(0, from_idx);
																				let intermediate_data = r.message[i][k].toString().substring(from_idx,  from_idx + len_search);
																				let end_data = r.message[i][k].toString().substring(from_idx+len_search, data.length);

																				let formatted_data = begin_data;

																				if (is_dark_mode == 0) {
																					formatted_data += '<font style="background-color:#D0E7FB"><b>' + intermediate_data + '</b></font>' + end_data;
																				}else{
																					formatted_data += '<font style="background-color:gray"><b>' + intermediate_data + '</b></font>' + end_data;
																				}

																				html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;' + formatted_data + '</td>'

																			}else {
																				html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;' + r.message[i][k] + '</td>'
																			}
																		}else{
																			html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;</td>'
																		}

																	}else{
																		html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;' + r.message[i][k] + '</td>'
																	}

																}else
																{	html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;</td>'
																}
															}

															html += '</tr>';

															cpt++;
														}

														html += '<input type="hidden" id="customer_nb_record" value="' + cpt.toString() + '">';
														html += '</table>';

													}else
													{
														html += '<input type="hidden" id="customer_nb_record" value="0">';
													}

												}
		});
		html += '</div>';
		document.getElementById('ioi_customer_search_content').innerHTML = html;

		silicon_ioi.ioiCommon.select_sleep(250).then(() => {

			let is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

			if (document.getElementById('window_select_customer_detail')) {

				let fct_row_dbclick = function() {
					this.click();
					z.primary_action();
				}

				let fct_row_click = function() {
					let s = this.id;

					while (s.indexOf('_') != -1) {
						s = s.substring(s.indexOf('_')+1, s.length)
					}

					s = s.trim();

					for (var i = 0; i < document.getElementById('window_select_customer_detail').rows.length; i++) {

						if (is_dark_mode == 0) {
							document.getElementById('window_select_customer_detail').rows[i].style.backgroundColor = '#FFFFFF';
						}else{
							document.getElementById('window_select_customer_detail').rows[i].style.backgroundColor = '#1C2126';
						}


						if (document.getElementById('customer_checked_id_' + i.toString())) {
							document.getElementById('customer_checked_id_' + i.toString()).checked = false;
						}

					}

					document.getElementById('customer_checked_id_' + s).checked = true;

					if (is_dark_mode == 0) {
						document.getElementById('window_select_customer_detail').rows[parseInt(s)].style.backgroundColor = '#B1FCD9';
					}else{
						document.getElementById('window_select_customer_detail').rows[parseInt(s)].style.backgroundColor = 'green';

					}

				}


				for (var i = 0; i < document.getElementById('window_select_customer_detail').rows.length; i++) {

					if (document.getElementById('window_select_customer_row_' + i.toString())) {
						document.getElementById('window_select_customer_row_' + i.toString()).onclick = fct_row_click;
						document.getElementById('window_select_customer_row_' + i.toString()).ondblclick = fct_row_dbclick;
					}
				}
			}

			let fct_header_col_click = function() {
				silicon_ioi.ioiCommon.customer_col_click(this, z);
			}

			for (var i=0; i < col_field.length; i++)
			{
				if (document.getElementById('customer_search_col_' + col_field[i]))
				{	document.getElementById('customer_search_col_' + col_field[i]).onclick = fct_header_col_click;
					document.getElementById('customer_search_col_' + col_field[i]).onmouseover = silicon_ioi.ioiCommon.col_mouse_over;
					document.getElementById('customer_search_col_' + col_field[i]).onmouseleave = silicon_ioi.ioiCommon.col_mouse_leave;
				}

			}
			document.getElementById('ioi_customer_search').focus();
		});
	}

	// ***************************************************************************************************************************************
	// Customer Search : Column click
	// ***************************************************************************************************************************************
	static customer_col_click(obj, z)
	{
		let s = obj.id;

		s = s.substring(20, s.length);

		if (document.getElementById('customer_col_order_by').value.toUpperCase() == s.toUpperCase())
		{
			if (document.getElementById('customer_col_order_order').value == 'desc')
			{	document.getElementById('customer_col_order_order').value = 'asc';
			}else
			{	document.getElementById('customer_col_order_order').value = 'desc';
			}
		}else
		{	document.getElementById('customer_col_order_by').value = s;
			document.getElementById('customer_col_order_order').value = 'desc';
		}

		silicon_ioi.ioiCommon.customer_search(z);
	}


	// ***************************************************************************************************************************************
	// Contact Search : Select contact
	// ***************************************************************************************************************************************
	static select_contact(fields, fields_len, fields_desc, where, order_by, callback, what = null, parent = null)
	{
		var z = new frappe.ui.Dialog({
			'title': __("Select a contact"),
			'fields': [
				{'fieldname': 'html_select_contact', 'fieldtype': 'HTML'}

			],
			primary_action_label: 'Ok',
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

				let go = true;
				let parent_value = '';
				let ret_value = '';


				if (document.getElementById('contact_nb_record ').value == 0)
				{
					go = false;
				}

				if ((go) && (document.getElementById('contact_nb_record ').value != 0))
				{	go = false;

					for (var i = 0; i < document.getElementById('contact_nb_record ').value; i++)
					{

						if (document.getElementById('contact_checked_id_' + i.toString()))
						{
							if (document.getElementById('contact_checked_id_' + i.toString()).checked)
							{
								ret_value = document.getElementById('contact_id_' + i.toString()).value;

								let w = '';
								if (what != null) {
									w = what.toUpperCase()
								}

								frappe.call({ method: 'silicon_ioi.ioi_sales.doctype.ioi_customer.ioi_customer.ioi_customer_get_contact_parent',
			 								  args: {"name": ret_value, "what": w
											  },
											  async: false,
											  callback: function(r)	{
													parent_value = r.message;
											  }
								});

								go = true;
								break;
							}
						}
					}
				}

				if (go)
				{
					z.hide();

					callback(parent_value, ret_value);
				}else{
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
				}
			},
			secondary_action: function(){
				z.hide();
			}

		});

		if (document.getElementById('ioi_contact_search_label'))
		{	document.getElementById('ioi_contact_search_label').remove();
		}

		if (document.getElementById('ioi_contact_search'))
		{	document.getElementById('ioi_contact_search').remove();
		}


		if (document.getElementById('ioi_contact_topn_label'))
		{	document.getElementById('ioi_contact_topn_label').remove();
		}

		if (document.getElementById('ioi_contact_topn'))
		{	document.getElementById('ioi_contact_topn').remove();
		}

		if (document.getElementById('contact_fields'))
		{	document.getElementById('contact_fields').remove();
		}

		if (document.getElementById('contact_fields_len'))
		{	document.getElementById('contact_fields_len').remove();
		}

		if (document.getElementById('contact_fields_desc'))
		{	document.getElementById('contact_fields_desc').remove();
		}

		if (document.getElementById('contact_where'))
		{	document.getElementById('contact_where').remove();
		}

		if (document.getElementById('contact_order_by'))
		{	document.getElementById('contact_order_by').remove();
		}

		if (document.getElementById('ioi_contact_search_content'))
		{	document.getElementById('ioi_contact_search_content').remove();
		}

		if (document.getElementById('contact_col_order_by'))
		{	document.getElementById('contact_col_order_by').remove();
		}

		if (document.getElementById('contact_col_order_order'))
		{	document.getElementById('contact_col_order_order').remove();
		}

		let html = '';

		let s_what = '';

		if (what != null) {
			s_what = what.toUpperCase();
		}

		let s_parent = '';

		if (parent != null) {
			s_parent = parent.toUpperCase();
		}

		if (document.getElementById('window_contact_what')) {
			document.getElementById('window_contact_what').remove();
		}

		if (document.getElementById('window_contact_parent')) {
			document.getElementById('window_contact_parent').remove();
		}



		html += '<input id="window_contact_what" type="hidden" value="' + s_what + '">';
		html += '<input id="window_contact_parent" type="hidden" value="' + s_parent + '">';



		html += '<div style="overflow-x: auto; width:100%;">';
		html += '	<input type="hidden" id="contact_fields" value="' + fields + '">';
		html += '	<input type="hidden" id="contact_fields_len" value="' + fields_len + '">';
		html += '	<input type="hidden" id="contact_fields_desc" value="' + fields_desc + '">';
		html += '	<input type="hidden" id="contact_where" value="' + where + '">';
		html += '	<input type="hidden" id="contact_order_by" value="' + order_by + '">';
		html += '	<input type="hidden" id="contact_col_order_by" value="name">';
		html += '	<input type="hidden" id="contact_col_order_order" value="asc">';

		html += '	<div style="overflow-x: auto; height:65px; width:100%;">';

		// Search
		html += '		<div style="position: relative; top: 0px; left: 0px; width:300px;">';
		html += '			<label id="ioi_contact_search_label" style="position: absolute; top: 0px; left: 2px;">' + __("Search") + '</label>';
		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 300px; height: 25px;"> ';
		html += '				<input id="ioi_contact_search" type="text" class="input-with-feedback form-control bold">';
		html += '			</div>';
		html += '		</div>';

		// Topn
		html += '		<div style="position: relative; top: 0px; left: 310px; width:170px;">';
		html += '			<label id="ioi_contact_topn_label" style="position: absolute; top: 0px; left: 2px;">' + __("No records") + '</label>';
		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 170px; height: 30px;"> ';
		html += '				<input id="ioi_contact_topn" type="number" class="input-with-feedback form-control bold" min="1" max="1000" value="100">';
		html += '			</div>';
		html += '		</div>';

		html += '	</div>';

		html += '	<div style="overflow-x: auto; height:10px; width:100%;"></div>';

		html += '	<div id="ioi_contact_search_content" style="overflow-x: auto; height:500px; width:100%;">';
		html += '	</div>';

		let tot_len = 0;
		let col_count = 0;

		let col_field = [];
		let col_desc = [];
		let col_len = [];

		if ((fields) && (fields.trim() != ''))
		{
			// field names
			let s = fields;

			while (s.indexOf(',') != -1)
			{
				let fn = s.substring(0, s.indexOf(','));
				fn = fn.trim();

				if (fn != '')
				{	col_field[col_count] = fn;
					col_count++;
				}

				s = s.substring(s.indexOf(',')+1, s.length);
			}

			if (s.trim() != '')
			{
				s = s.trim();
				col_field[col_count] = s;
			}

			col_count = 0;

			// field descriptions
			s = fields_desc;

			while (s.indexOf(',') != -1)
			{
				let fn = s.substring(0, s.indexOf(','));
				fn = fn.trim();

				if (fn != '')
				{	col_desc[col_count] = fn;
					col_count++;
				}

				s = s.substring(s.indexOf(',')+1, s.length);
			}

			if (s.trim() != '')
			{
				s = s.trim();
				col_desc[col_count] = s;
			}

			col_count = 0;

			// field lengths
			if ((fields_len) && (fields_len.trim() != ''))
			{
				s = fields_len;

				while (s.indexOf(',') != -1)
				{
					let n = s.substring(0, s.indexOf(','));
					n = n.trim();

					if (n != '')
					{	col_len[col_count] = parseInt(n);
						col_count++;
						tot_len += parseInt(n);
					}

					s = s.substring(s.indexOf(',')+1, s.length);
				}

				if (s.trim() != '')
				{
					s = s.trim();
					tot_len += parseInt(s);
				}
			}
		}

		let form_width = '50%';

		if (tot_len == 0)
		{
			col_field[0] = 'name';
			col_field[1] = 'first_name';

			col_desc[0] = __('Identification');
			col_desc[1] = __('Name');

			col_len[0] = 200;
			col_len[1] = 300;

			tot_len = 530;
			form_width = '30%';
		}else
		{ 	tot_len += 30;
		}

		z.fields_dict.html_select_contact.$wrapper.html(html);
		z.$wrapper.find('.modal-dialog').css("max-width", form_width).css("width", form_width);
		z.$wrapper.find('.modal-dialog').css("max-height", "50%").css("height", "50%");
		z.show();

		silicon_ioi.ioiCommon.select_sleep(250).then(() => {
			let fct_keyup = function(event)
			{
				if (event.keyCode == 13)
				{
					silicon_ioi.ioiCommon.contact_search(z);
				}
			}

			document.getElementById('ioi_contact_search').onkeyup = fct_keyup;
			document.getElementById('ioi_contact_topn').onkeyup = fct_keyup;
			silicon_ioi.ioiCommon.contact_search(z);
		});
	}

	// ***************************************************************************************************************************************
	// Contact Search : Search Term Key Up
	// ***************************************************************************************************************************************
	static contact_keyup(event)
	{
		if (event.keyCode == 13)
		{
			silicon_ioi.ioiCommon.contact_search();
		}
	}

	// ***************************************************************************************************************************************
	// Contact Search : Search
	// ***************************************************************************************************************************************
	static contact_search(z)
	{
		document.getElementById('ioi_contact_search_content').innerHTML = '';

		if (document.getElementById('contact_nb_record '))
		{
			for (var i = 0; i < document.getElementById('contact_nb_record ').value; i++)
			{
				if (document.getElementById('contact_checked_id_' + i.toString()))
				{	document.getElementById('contact_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('contact_id_' + i.toString()))
				{	document.getElementById('contact_id_' + i.toString()).remove();
				}
			}

			document.getElementById('contact_nb_record ').remove();
		}

		let tot_len = 0;
		let col_count = 0;

		let col_field = [];
		let col_desc = [];
		let col_len = [];

		if ((document.getElementById('contact_fields').value) && (document.getElementById('contact_fields').value.trim() != ''))
		{
			// field names
			let s = document.getElementById('contact_fields').value;

			while (s.indexOf(',') != -1)
			{
				let fn = s.substring(0, s.indexOf(','));
				fn = fn.trim();

				if (fn != '')
				{	col_field[col_count] = fn;
					col_count++;
				}

				s = s.substring(s.indexOf(',')+1, s.length);
			}

			if (s.trim() != '')
			{
				s = s.trim();
				col_field[col_count] = s;
			}

			col_count = 0;

			// field descriptions
			s = document.getElementById('contact_fields_desc').value;

			while (s.indexOf(',') != -1)
			{
				let fn = s.substring(0, s.indexOf(','));
				fn = fn.trim();

				if (fn != '')
				{	col_desc[col_count] = fn;
					col_count++;
				}

				s = s.substring(s.indexOf(',')+1, s.length);
			}

			if (s.trim() != '')
			{
				s = s.trim();
				col_desc[col_count] = s;
			}

			col_count = 0;

			// field lengths
			if ((document.getElementById('contact_fields_len').value) && (document.getElementById('contact_fields_len').value.trim() != ''))
			{
				s = document.getElementById('contact_fields_len').value;

				while (s.indexOf(',') != -1)
				{
					let n = s.substring(0, s.indexOf(','));
					n = n.trim();

					if (n != '')
					{	col_len[col_count] = parseInt(n);
						col_count++;
						tot_len += parseInt(n);
					}

					s = s.substring(s.indexOf(',')+1, s.length);
				}

				if (s.trim() != '')
				{
					s = s.trim();
					tot_len += parseInt(s);
				}
			}
		}

		if (tot_len == 0)
		{
			col_field[0] = 'name';
			col_field[1] = 'first_name';

			col_desc[0] = __('Identification');
			col_desc[1] = __('Name');

			col_len[0] = 200;
			col_len[1] = 300;

			tot_len = 530;
		}else
		{ 	tot_len += 30;
		}


		for (var i=0; i < col_field.length; i++)
		{
			if (document.getElementById('contact_search_col_label_' + col_field[i]))
			{	document.getElementById('contact_search_col_label_' + col_field[i]).remove();
			}

			if (document.getElementById('contact_search_col_' + col_field[i]))
			{	document.getElementById('contact_search_col_' + col_field[i]).remove();
			}

		}

		let html = '';

		let is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

		html += '<table border=1 style="border: 1px solid #E8EAEB" width=' + tot_len + 'px data-custom-grid="true">';

		html += '<tr style="height:30px">';

		html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';

		for (var i = 0; i < col_field.length; i++)
		{
			let sens = ''

			if (document.getElementById('contact_col_order_by').value.toUpperCase() == col_field[i].toUpperCase())
			{
				if (document.getElementById('contact_col_order_order').value.toUpperCase() == 'ASC')
				{	sens = '&darr;'
				}else
				{	sens = '&uarr;'
				}
			}

			html += '<td id="contact_search_col_' + col_field[i] + '" width=' + col_len[i] + 'px style="vertical-align: middle;">&nbsp;<b>' + col_desc[i] + '<label id="contact_search_col_label_' + col_field[i] + '" style="width:30px; height:8px" align="right">' + sens + '</label></b></td>';

		}

		if (document.getElementById('window_select_contact_detail')) {
			document.getElementById('window_select_contact_detail').remove();
		}

		html += '</tr>';
		html += '</table>';

		let method = path_customer + '.ioi_contact_search_form';

		frappe.call({  	method: method,
						args: {	"fields": document.getElementById('contact_fields').value, "conditions": document.getElementById('contact_where').value,
								"search" : document.getElementById('ioi_contact_search').value,
								"orderby": document.getElementById('contact_col_order_by').value,
								"orderdir": document.getElementById('contact_col_order_order').value,
								"topn": document.getElementById('ioi_contact_topn').value,
								"what": document.getElementById('window_contact_what').value,
								"parent": document.getElementById('window_contact_parent').value
						},
						async: false,
						callback:function(r)	{
													if (r.message.length > 0)
													{
														html += '<table id="window_select_contact_detail" border=1 style="border: 1px solid #E8EAEB" width=' + tot_len + 'px">';

														let cpt = 0;


														for (var i = 0; i < r.message.length; i++)
														{
															html += '<tr id="window_select_contact_row_' + i.toString() + '" style="height:30px">';

															html += '<td width=30px align="center" style="vertical-align: middle;">';
															html += '<input type="checkbox" id="contact_checked_id_' + cpt.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
															html += '       onclick=" ';
															html += '					for (var i = 0; i < document.getElementById(\'contact_nb_record \').value; i++) ';
															html += '					{   if (document.getElementById(\'contact_checked_id_\' + i.toString())) ';
															html += '						{ '
															html += '							if (document.getElementById(\'contact_checked_id_\' + i.toString()).id != this.id) ';
															html += '							{ ';
															html += '								document.getElementById(\'contact_checked_id_\' + i.toString()).checked = false; ';
															html += '							} ';
															html += '						} ';
															html += '					} ';
															html += '" ';

															html += '>';
															html += '<input type="hidden" id="contact_id_' + cpt.toString() + '" value="' +  r.message[i][0] + '">';
															html += '</td>';

															for (var k = 0; k < col_field.length; k++)
															{
																if (r.message[i][k] != null)
																{
																	let search_term = document.getElementById('ioi_contact_search').value;
																	search_term = search_term.trim();

																	if (search_term != '') {

																		search_term = search_term.toUpperCase();

																		let data = r.message[i][k].toString();

																		if (data != '') {

																			data = data.trim().toUpperCase();

																			if (data.indexOf(search_term) != -1) {
																				let from_idx = data.indexOf(search_term);
																				let len_search = search_term.length;

																				let begin_data = r.message[i][k].toString().substring(0, from_idx);
																				let intermediate_data = r.message[i][k].toString().substring(from_idx,  from_idx + len_search);
																				let end_data = r.message[i][k].toString().substring(from_idx+len_search, data.length);

																				let formatted_data = begin_data;

																				if (is_dark_mode == 0) {
																					formatted_data += '<font style="background-color:#D0E7FB"><b>' + intermediate_data + '</b></font>' + end_data;
																				}else{
																					formatted_data += '<font style="background-color:gray"><b>' + intermediate_data + '</b></font>' + end_data;
																				}

																				html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;' + formatted_data + '</td>'

																			}else {
																				html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;' + r.message[i][k] + '</td>'
																			}
																		}else{
																			html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;</td>'
																		}

																	}else{
																		html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;' + r.message[i][k] + '</td>'
																	}

																}else
																{	html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;</td>'
																}
															}

															html += '</tr>';

															cpt++;
														}

														html += '<input type="hidden" id="contact_nb_record " value="' + cpt.toString() + '">';
														html += '</table>';

													}else
													{
														html += '<input type="hidden" id="contact_nb_record " value="0">';
													}

												}
		});
		html += '</div>';
		document.getElementById('ioi_contact_search_content').innerHTML = html;

		silicon_ioi.ioiCommon.select_sleep(250).then(() => {

			let is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

			if (document.getElementById('window_select_contact_detail')) {

				let fct_row_dbclick = function() {
					this.click();
					z.primary_action();
				}

				let fct_row_click = function() {
					let s = this.id;

					while (s.indexOf('_') != -1) {
						s = s.substring(s.indexOf('_')+1, s.length)
					}

					s = s.trim();

					for (var i = 0; i < document.getElementById('window_select_contact_detail').rows.length; i++) {

						if (is_dark_mode == 0) {
							document.getElementById('window_select_contact_detail').rows[i].style.backgroundColor = '#FFFFFF';
						}else{
							document.getElementById('window_select_contact_detail').rows[i].style.backgroundColor = '#1C2126';
						}


						if (document.getElementById('contact_checked_id_' + i.toString())) {
							document.getElementById('contact_checked_id_' + i.toString()).checked = false;
						}

					}

					document.getElementById('contact_checked_id_' + s).checked = true;

					if (is_dark_mode == 0) {
						document.getElementById('window_select_contact_detail').rows[parseInt(s)].style.backgroundColor = '#B1FCD9';
					}else{
						document.getElementById('window_select_contact_detail').rows[parseInt(s)].style.backgroundColor = 'green';

					}

				}


				for (var i = 0; i < document.getElementById('window_select_contact_detail').rows.length; i++) {

					if (document.getElementById('window_select_contact_row_' + i.toString())) {
						document.getElementById('window_select_contact_row_' + i.toString()).onclick = fct_row_click;
						document.getElementById('window_select_contact_row_' + i.toString()).ondblclick = fct_row_dbclick;
					}
				}
			}

			let fct_header_col_click = function() {
				silicon_ioi.ioiCommon.contact_col_click(this, z);
			}

			for (var i=0; i < col_field.length; i++)
			{
				if (document.getElementById('contact_search_col_' + col_field[i]))
				{	document.getElementById('contact_search_col_' + col_field[i]).onclick = fct_header_col_click;
					document.getElementById('contact_search_col_' + col_field[i]).onmouseover = silicon_ioi.ioiCommon.col_mouse_over;
					document.getElementById('contact_search_col_' + col_field[i]).onmouseleave = silicon_ioi.ioiCommon.col_mouse_leave;
				}

			}
			document.getElementById('ioi_contact_search').focus();
		});
	}

	// ***************************************************************************************************************************************
	// Contact Search : Column click
	// ***************************************************************************************************************************************
	static contact_col_click(obj, z)
	{
		let s = obj.id;
		s = s.substring(19, s.length);

		if (document.getElementById('contact_col_order_by').value.toUpperCase() == s.toUpperCase())
		{
			if (document.getElementById('contact_col_order_order').value == 'desc')
			{	document.getElementById('contact_col_order_order').value = 'asc';
			}else
			{	document.getElementById('contact_col_order_order').value = 'desc';
			}
		}else
		{	document.getElementById('contact_col_order_by').value = s;
			document.getElementById('contact_col_order_order').value = 'desc';
		}

		silicon_ioi.ioiCommon.contact_search(z);
	}





	// ***************************************************************************************************************************************
	// Supplier Search : Select supplier
	// ***************************************************************************************************************************************
	static select_supplier(fields, fields_len, fields_desc, where, order_by, callback)
	{
		frappe.call(`${path_advanced_search}.ioi_common_advanced_search_definition_fields_get_list`, {
			doctype: 'ioi Supplier',
		}).then(r => {
			var z = new frappe.ui.Dialog({
				'title': __("Select a supplier"),
				'fields': [
					{'fieldname': 'html_select_supplier', 'fieldtype': 'HTML'}

				],
				primary_action_label: 'Ok',
				secondary_action_label: __('Cancel'),
				primary_action: function(){

					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

					let go = true;
					let ret_value = '';

					if (document.getElementById('supplier_nb_record').value == 0)
					{
						go = false;
					}

					if ((go) && (document.getElementById('supplier_nb_record').value != 0))
					{	go = false
						for (var i = 0; i < document.getElementById('supplier_nb_record').value; i++)
						{

							if (document.getElementById('supplier_checked_id_' + i.toString()))
							{
								if (document.getElementById('supplier_checked_id_' + i.toString()).checked)
								{
									ret_value = document.getElementById('supplier_id_' + i.toString()).value;;
									go = true;
									break;
								}
							}
						}
					}

					if (go)
					{
						z.hide();

						callback(ret_value);
					}else{
						document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
					}
				},
				secondary_action: function(){
					z.hide();
				}

			});

			if (document.getElementById('ioi_supplier_search_label'))
			{	document.getElementById('ioi_supplier_search_label').remove();
			}

			if (document.getElementById('ioi_supplier_search'))
			{	document.getElementById('ioi_supplier_search').remove();
			}


			if (document.getElementById('ioi_supplier_topn_label'))
			{	document.getElementById('ioi_supplier_topn_label').remove();
			}

			if (document.getElementById('ioi_supplier_topn'))
			{	document.getElementById('ioi_supplier_topn').remove();
			}

			if (document.getElementById('supplier_fields'))
			{	document.getElementById('supplier_fields').remove();
			}

			if (document.getElementById('supplier_fields_len'))
			{	document.getElementById('supplier_fields_len').remove();
			}

			if (document.getElementById('supplier_fields_desc'))
			{	document.getElementById('supplier_fields_desc').remove();
			}

			if (document.getElementById('supplier_where'))
			{	document.getElementById('supplier_where').remove();
			}

			if (document.getElementById('supplier_order_by'))
			{	document.getElementById('supplier_order_by').remove();
			}

			if (document.getElementById('ioi_supplier_search_content'))
			{	document.getElementById('ioi_supplier_search_content').remove();
			}

			if (document.getElementById('supplier_col_order_by'))
			{	document.getElementById('supplier_col_order_by').remove();
			}

			if (document.getElementById('supplier_col_order_order'))
			{	document.getElementById('supplier_col_order_order').remove();
			}

			if (r.message.length) {
				fields = "name, full_name"
				fields_desc = `${__('Identification')}, ${__('Name')}`
				fields_len = "200, 300"

				for (let i = 0; i < r.message.length; i++) {
					if (r.message[i].field.startsWith("ioi Supplier")) {
						const field = r.message[i].field.replace('ioi Supplier-', '')
						fields += `, ${field}`
					} else {
						fields += `, ${r.message[i].field}`
					}
					fields_desc += `, ${__(r.message[i].field_label)}`
					fields_len += `, ${r.message[i].field_width}`
				}
			}

			let html = '';

			html += '<div style="overflow-x: auto; width:100%;">';
			html += '	<input type="hidden" id="supplier_fields" value="' + fields + '">';
			html += '	<input type="hidden" id="supplier_fields_len" value="' + fields_len + '">';
			html += '	<input type="hidden" id="supplier_fields_desc" value="' + fields_desc + '">';
			html += '	<input type="hidden" id="supplier_where" value="' + where + '">';
			html += '	<input type="hidden" id="supplier_order_by" value="' + order_by + '">';
			html += '	<input type="hidden" id="supplier_col_order_by" value="name">';
			html += '	<input type="hidden" id="supplier_col_order_order" value="asc">';

			html += '	<div style="overflow-x: auto; height:65px; width:100%;">';

			// Search
			html += '		<div style="position: relative; top: 0px; left: 0px; width:300px;">';
			html += '			<label id="ioi_supplier_search_label" style="position: absolute; top: 0px; left: 2px;">' + __("Search") + '</label>';
			html += '			<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 300px; height: 25px;"> ';
			html += '				<input id="ioi_supplier_search" type="text" class="input-with-feedback form-control bold">';
			html += '			</div>';
			html += '		</div>';

			// Topn
			html += '		<div style="position: relative; top: 0px; left: 310px; width:170px;">';
			html += '			<label id="ioi_supplier_topn_label" style="position: absolute; top: 0px; left: 2px;">' + __("No records") + '</label>';
			html += '			<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 170px; height: 30px;"> ';
			html += '				<input id="ioi_supplier_topn" type="number" class="input-with-feedback form-control bold" min="1" max="1000" value="100">';
			html += '			</div>';
			html += '		</div>';

			html += '	</div>';

			html += '	<div style="overflow-x: auto; height:10px; width:100%;"></div>';

			html += '	<div id="ioi_supplier_search_content" style="overflow-x: auto; height:500px; width:100%;">';
			html += '	</div>';

			let tot_len = 0;
			let col_count = 0;

			let col_field = [];
			let col_desc = [];
			let col_len = [];

			if ((fields) && (fields.trim() != ''))
			{
				// field names
				let s = fields;

				while (s.indexOf(',') != -1)
				{
					let fn = s.substring(0, s.indexOf(','));
					fn = fn.trim();

					if (fn != '')
					{	col_field[col_count] = fn;
						col_count++;
					}

					s = s.substring(s.indexOf(',')+1, s.length);
				}

				if (s.trim() != '')
				{
					s = s.trim();
					col_field[col_count] = s;
				}

				col_count = 0;

				// field descriptions
				s = fields_desc;

				while (s.indexOf(',') != -1)
				{
					let fn = s.substring(0, s.indexOf(','));
					fn = fn.trim();

					if (fn != '')
					{	col_desc[col_count] = fn;
						col_count++;
					}

					s = s.substring(s.indexOf(',')+1, s.length);
				}

				if (s.trim() != '')
				{
					s = s.trim();
					col_desc[col_count] = s;
				}

				col_count = 0;

				// field lengths
				if ((fields_len) && (fields_len.trim() != ''))
				{
					s = fields_len;

					while (s.indexOf(',') != -1)
					{
						let n = s.substring(0, s.indexOf(','));
						n = n.trim();

						if (n != '')
						{	col_len[col_count] = parseInt(n);
							col_count++;
							tot_len += parseInt(n);
						}

						s = s.substring(s.indexOf(',')+1, s.length);
					}

					if (s.trim() != '')
					{
						s = s.trim();
						tot_len += parseInt(s);
					}
				}
			}

			let form_width = '50%';

			if (tot_len == 0)
			{
				col_field[0] = 'name';
				col_field[1] = 'full_name';

				col_desc[0] = __('Identification');
				col_desc[1] = __('Name');

				col_len[0] = 200;
				col_len[1] = 300;

				tot_len = 530;
				form_width = '30%';
			}else
			{ 	tot_len += 30;
			}

			z.fields_dict.html_select_supplier.$wrapper.html(html);
			z.$wrapper.find('.modal-dialog').css("max-width", form_width).css("width", form_width);
			z.$wrapper.find('.modal-dialog').css("max-height", "50%").css("height", "50%");
			z.show();

			silicon_ioi.ioiCommon.select_sleep(250).then(() => {

				let fct_keyup = function(event)
				{
					if (event.keyCode == 13)
					{
						silicon_ioi.ioiCommon.supplier_search(z);
					}
				}

				document.getElementById('ioi_supplier_search').onkeyup = fct_keyup;
				document.getElementById('ioi_supplier_topn').onkeyup = fct_keyup;
				silicon_ioi.ioiCommon.supplier_search(z);
			});
		}
	)}

	// ***************************************************************************************************************************************
	// Supplier Search : Search Term Key Up
	// ***************************************************************************************************************************************
	static supplier_keyup(event)
	{
		if (event.keyCode == 13)
		{
			silicon_ioi.ioiCommon.supplier_search();
		}
	}

	// ***************************************************************************************************************************************
	// Supplier Search : Search
	// ***************************************************************************************************************************************
	static supplier_search(z)
	{
		document.getElementById('ioi_supplier_search_content').innerHTML = '';

		if (document.getElementById('supplier_nb_record'))
		{
			for (var i = 0; i < document.getElementById('supplier_nb_record').value; i++)
			{
				if (document.getElementById('supplier_checked_id_' + i.toString()))
				{	document.getElementById('supplier_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('supplier_id_' + i.toString()))
				{	document.getElementById('supplier_id_' + i.toString()).remove();
				}
			}

			document.getElementById('supplier_nb_record').remove();
		}

		let tot_len = 0;
		let col_count = 0;

		let col_field = [];
		let col_desc = [];
		let col_len = [];

		if ((document.getElementById('supplier_fields').value) && (document.getElementById('supplier_fields').value.trim() != ''))
		{
			// field names
			let s = document.getElementById('supplier_fields').value;

			while (s.indexOf(',') != -1)
			{
				let fn = s.substring(0, s.indexOf(','));
				fn = fn.trim();

				if (fn != '')
				{	col_field[col_count] = fn;
					col_count++;
				}

				s = s.substring(s.indexOf(',')+1, s.length);
			}

			if (s.trim() != '')
			{
				s = s.trim();
				col_field[col_count] = s;
			}

			col_count = 0;

			// field descriptions
			s = document.getElementById('supplier_fields_desc').value;

			while (s.indexOf(',') != -1)
			{
				let fn = s.substring(0, s.indexOf(','));
				fn = fn.trim();

				if (fn != '')
				{	col_desc[col_count] = fn;
					col_count++;
				}

				s = s.substring(s.indexOf(',')+1, s.length);
			}

			if (s.trim() != '')
			{
				s = s.trim();
				col_desc[col_count] = s;
			}

			col_count = 0;

			// field lengths
			if ((document.getElementById('supplier_fields_len').value) && (document.getElementById('supplier_fields_len').value.trim() != ''))
			{
				s = document.getElementById('supplier_fields_len').value;

				while (s.indexOf(',') != -1)
				{
					let n = s.substring(0, s.indexOf(','));
					n = n.trim();

					if (n != '')
					{	col_len[col_count] = parseInt(n);
						col_count++;
						tot_len += parseInt(n);
					}

					s = s.substring(s.indexOf(',')+1, s.length);
				}

				if (s.trim() != '')
				{
					s = s.trim();
					tot_len += parseInt(s);
				}
			}
		}

		if (tot_len == 0)
		{
			col_field[0] = 'name';
			col_field[1] = 'full_name';

			col_desc[0] = __('Identification');
			col_desc[1] = __('Name');

			col_len[0] = 200;
			col_len[1] = 300;

			tot_len = 530;
		}else
		{ 	tot_len += 30;
		}


		for (var i=0; i < col_field.length; i++)
		{
			if (document.getElementById('supplier_search_col_label_' + col_field[i]))
			{	document.getElementById('supplier_search_col_label_' + col_field[i]).remove();
			}

			if (document.getElementById('supplier_search_col_' + col_field[i]))
			{	document.getElementById('supplier_search_col_' + col_field[i]).remove();
			}

		}

		let html = '';

		let is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

		html += '<table border=1 style="border: 1px solid #E8EAEB" width=' + tot_len + 'px data-custom-grid="true">';

		html += '<tr style="height:30px">';

		html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';

		for (var i = 0; i < col_field.length; i++)
		{
			let sens = ''

			if (document.getElementById('supplier_col_order_by').value.toUpperCase() == col_field[i].toUpperCase())
			{
				if (document.getElementById('supplier_col_order_order').value.toUpperCase() == 'ASC')
				{	sens = '&darr;'
				}else
				{	sens = '&uarr;'
				}
			}

			html += '<td id="supplier_search_col_' + col_field[i] + '" width=' + col_len[i] + 'px style="vertical-align: middle;">&nbsp;<b>' + col_desc[i] + '<label id="supplier_search_col_label_' + col_field[i] + '" style="width:30px; height:8px" align="right">' + sens + '</label></b></td>';

		}

		html += '</tr>';
		html += '</table>';


		if (document.getElementById('window_select_supplier_detail')) {
			document.getElementById('window_select_supplier_detail').remove();
		}

		let method = path_supplier + '.ioi_supplier_search_form';

		frappe.call({  	method: method,
						args: {	"fields": document.getElementById('supplier_fields').value, "conditions": document.getElementById('supplier_where').value,
								"search" : document.getElementById('ioi_supplier_search').value,
								"orderby": document.getElementById('supplier_col_order_by').value,
								"orderdir": document.getElementById('supplier_col_order_order').value,
								"topn": document.getElementById('ioi_supplier_topn').value},
						async: false,
						callback:function(r)	{
													if (r.message.length > 0)
													{
														html += '<table id="window_select_supplier_detail" border=1 style="border: 1px solid #E8EAEB" width=' + tot_len + 'px">';

														let cpt = 0;


														for (var i = 0; i < r.message.length; i++)
														{
															html += '<tr id="window_select_supplier_row_' + i.toString() + '" style="height:30px">';

															html += '<td width=30px align="center" style="vertical-align: middle;">';
															html += '<input type="checkbox" id="supplier_checked_id_' + cpt.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
															html += '       onclick=" ';
															html += '					for (var i = 0; i < document.getElementById(\'supplier_nb_record\').value; i++) ';
															html += '					{   if (document.getElementById(\'supplier_checked_id_\' + i.toString())) ';
															html += '						{ '
															html += '							if (document.getElementById(\'supplier_checked_id_\' + i.toString()).id != this.id) ';
															html += '							{ ';
															html += '								document.getElementById(\'supplier_checked_id_\' + i.toString()).checked = false; ';
															html += '							} ';
															html += '						} ';
															html += '					} ';
															html += '" ';

															html += '>';
															html += '<input type="hidden" id="supplier_id_' + cpt.toString() + '" value="' +  r.message[i][0] + '">';
															html += '</td>';

															for (var k = 0; k < col_field.length; k++)
															{
																if (r.message[i][k] != null)
																{
																	let search_term = document.getElementById('ioi_supplier_search').value;
																	search_term = search_term.trim();

																	if (search_term != '') {

																		search_term = search_term.toUpperCase();

																		let data = r.message[i][k].toString();

																		if (data != '') {
																			data = data.trim().toUpperCase();

																			if (data.indexOf(search_term) != -1) {
																				let from_idx = data.indexOf(search_term);
																				let len_search = search_term.length;

																				let begin_data = r.message[i][k].toString().substring(0, from_idx);
																				let intermediate_data = r.message[i][k].toString().substring(from_idx,  from_idx + len_search);
																				let end_data = r.message[i][k].toString().substring(from_idx+len_search, data.length);

																				let formatted_data = begin_data;

																				if (is_dark_mode == 0) {
																					formatted_data += '<font style="background-color:#D0E7FB"><b>' + intermediate_data + '</b></font>' + end_data;
																				}else{
																					formatted_data += '<font style="background-color:gray"><b>' + intermediate_data + '</b></font>' + end_data;
																				}

																				html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;' + formatted_data + '</td>'

																			}else {
																				html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;' + r.message[i][k] + '</td>'
																			}
																		}else{
																			html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;</td>'
																		}

																	}else{
																		html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;' + r.message[i][k] + '</td>'
																	}
																}else
																{	html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;</td>'
																}
															}

															html += '</tr>';

															cpt++;
														}

														html += '<input type="hidden" id="supplier_nb_record" value="' + cpt.toString() + '">';
														html += '</table>';

													}else
													{
														html += '<input type="hidden" id="supplier_nb_record" value="0">';
													}

												}
		});
		html += '</div>';
		document.getElementById('ioi_supplier_search_content').innerHTML = html;

		silicon_ioi.ioiCommon.select_sleep(250).then(() => {

			let is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

			if (document.getElementById('window_select_supplier_detail')) {

				let fct_row_dbclick = function() {
					this.click();
					z.primary_action();
				}

				let fct_row_click = function() {
					let s = this.id;

					while (s.indexOf('_') != -1) {
						s = s.substring(s.indexOf('_')+1, s.length)
					}

					s = s.trim();

					for (var i = 0; i < document.getElementById('window_select_supplier_detail').rows.length; i++) {

						if (is_dark_mode == 0) {
							document.getElementById('window_select_supplier_detail').rows[i].style.backgroundColor = '#FFFFFF';
						}else{
							document.getElementById('window_select_supplier_detail').rows[i].style.backgroundColor = '#1C2126';
						}


						if (document.getElementById('supplier_checked_id_' + i.toString())) {
							document.getElementById('supplier_checked_id_' + i.toString()).checked = false;
						}

					}

					document.getElementById('supplier_checked_id_' + s).checked = true;

					if (is_dark_mode == 0) {
						document.getElementById('window_select_supplier_detail').rows[parseInt(s)].style.backgroundColor = '#B1FCD9';
					}else{
						document.getElementById('window_select_supplier_detail').rows[parseInt(s)].style.backgroundColor = 'green';

					}

				}


				for (var i = 0; i < document.getElementById('window_select_supplier_detail').rows.length; i++) {

					if (document.getElementById('window_select_supplier_row_' + i.toString())) {
						document.getElementById('window_select_supplier_row_' + i.toString()).onclick = fct_row_click;
						document.getElementById('window_select_supplier_row_' + i.toString()).ondblclick = fct_row_dbclick;
					}
				}
			}


			let fct_header_col_click = function() {
				silicon_ioi.ioiCommon.supplier_col_click(this, z);
			}

			for (var i=0; i < col_field.length; i++)
			{
				if (document.getElementById('supplier_search_col_' + col_field[i]))
				{	document.getElementById('supplier_search_col_' + col_field[i]).onclick = fct_header_col_click;
					document.getElementById('supplier_search_col_' + col_field[i]).onmouseover = silicon_ioi.ioiCommon.col_mouse_over;
					document.getElementById('supplier_search_col_' + col_field[i]).onmouseleave = silicon_ioi.ioiCommon.col_mouse_leave;
				}
			}

			document.getElementById('ioi_supplier_search').focus();
		});
	}

	// ***************************************************************************************************************************************
	// Supplier Search : Column click
	// ***************************************************************************************************************************************
	static supplier_col_click(obj, z)
	{
		let s = obj.id;

		s = s.substring(20, s.length);

		if (document.getElementById('supplier_col_order_by').value.toUpperCase() == s.toUpperCase())
		{
			if (document.getElementById('supplier_col_order_order').value == 'desc')
			{	document.getElementById('supplier_col_order_order').value = 'asc';
			}else
			{	document.getElementById('supplier_col_order_order').value = 'desc';
			}
		}else
		{	document.getElementById('supplier_col_order_by').value = s;
			document.getElementById('supplier_col_order_order').value = 'desc';
		}

		silicon_ioi.ioiCommon.supplier_search(z);
	}



	// ***************************************************************************************************************************************
	// Item Search : Select item
	// ***************************************************************************************************************************************
	static select_item(fields, fields_len, fields_desc, where, order_by, parameter_with_stock, site_id, callback) {

		frappe.call(`${path_advanced_search}.ioi_common_advanced_search_definition_fields_get_list`, {
			doctype: 'ioi Item',
		}).then(r => {

			let table

			var z = new frappe.ui.Dialog({
				'title': __("Select an item"),
				'fields': [
					{ 'fieldname': 'html_select_item', 'fieldtype': 'HTML' }
				],
				primary_action_label: 'Ok',
				secondary_action_label: __('Cancel'),
				primary_action: function (values, row=null) {

					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length - 1].disabled = true;

					let selected_row
					let selected_row_val

					if (row) {
						// On dblClick, set selected row
						selected_row = [row]
					} else {
						selected_row = table.getSelectedRows();
					}

					selected_row_val = selected_row[0].getData().name

					if (selected_row.length) {
						z.hide();
						callback(selected_row_val);
					}
				},
				secondary_action: function () {
					z.hide();
				},
				on_page_show: () => {
					table = silicon_ioi.ioiCommon.build_ioi_item_grid(fields, fields_desc, fields_len, z)

					let fct_key_up = function (event) {
						if (event.keyCode == 13) silicon_ioi.ioiCommon.item_search(table);
					}

					document.getElementById('ioi_item_search').onkeyup = fct_key_up;
					document.getElementById('ioi_item_topn').onkeyup = fct_key_up;
					document.getElementById('ioi_item_btn_filter').onclick = () => silicon_ioi.ioiCommon.item_search(table);

					if (document.getElementById('ioi_item_with_stock')) {
						document.getElementById('ioi_item_with_stock').onclick = () => silicon_ioi.ioiCommon.item_search(table);
					}

					silicon_ioi.ioiCommon.item_search(table);
				}
			});

			if (document.getElementById('ioi_item_advanced_search_content')) {
				document.getElementById('ioi_item_advanced_search_content').remove();
			}

			let qty_field_found = false;

			// If advanced search is defined, build fields from ioi Advanced Search Definition
			if (r.message.length) {
				fields = "a.name"
				fields_desc = `${__('Identification')}`
				fields_len = "0"

				for (let i = 0; i < r.message.length; i++) {
					if (r.message[i].field.startsWith("ioi Item")) {
						// Format custom fields
						const field = r.message[i].field.replace('ioi Item-', '')
						fields += `, a.${field}`
					} else {
						if (r.message[i].field == "q_stock") {
							fields += `, b.${r.message[i].field}`
							qty_field_found = true

						} else if (r.message[i].field == "q_reserved") {

							fields += `, b.${r.message[i].field}`
						} else {

							fields += `, a.${r.message[i].field}`
						}
					}
					fields_desc += `, ${__(r.message[i].field_label)}`
					fields_len += `, ${r.message[i].field_width}`
				}
			} else {
			// Else build with default fields
				if (fields && fields.trim() !== '') {

					if (fields.split(', ').find(field => field.toUpperCase() === 'B.Q_STOCK')) {
						qty_field_found = true;
					}

					fields += ', a.tags'
				}

				if (fields_desc && fields_desc.trim() !== '') {
					fields_desc += `, ${__('Tags')}`
				}

				if (fields_len && fields_len.trim() !== '') {
					fields_len += ', 500'
				}

				if (!fields.length) {
					fields = 'name, description, tags'
					fields_desc = `${__('Identification')}, ${__('Description')}, ${__('Tags')}`
					fields_len = '200, 300, 500'
				}
			}

			let html = '';

			html += '<div id="ioi_item_advanced_search_content" style="max-height: 75vh; overflow: auto; width:100%;">';
			html += '	<input type="hidden" id="item_fields" value="' + fields + '">';
			html += '	<input type="hidden" id="item_fields_len" value="' + fields_len + '">';
			html += '	<input type="hidden" id="item_fields_desc" value="' + fields_desc + '">';
			html += '	<input type="hidden" id="item_where" value="' + where + '">';
			html += '	<input type="hidden" id="item_order_by" value="' + order_by + '">';
			html += '	<input type="hidden" id="item_col_order_by" value="a.name">';
			html += '	<input type="hidden" id="item_col_order_order" value="asc">';
			html += '	<input type="hidden" id="ioi_item_site_id" value="' + site_id + '">';

			html += '	<div class="d-flex align-items-start flex-wrap pl-1" style="width:100%;">';

			// Search
			html += '		<div class="mr-2 mt-2" style="width:300px;">';
			html += '			<label id="ioi_item_search_label">' + __("Search") + '</label>';
			html += '			<div class="control-input" style="width: 300px; height: 25px;"> ';
			html += '				<input id="ioi_item_search" type="text" class="input-with-feedback form-control bold">';
			html += '			</div>';
			html += '		</div>';

			// Topn
			html += '		<div class="mr-2 mt-2" style="width:170px;">';
			html += '			<label id="ioi_item_topn_label">' + __("No records") + '</label>';
			html += '			<div class="control-input" style="width: 170px; height: 30px;"> ';
			html += '				<input id="ioi_item_topn" type="number" class="input-with-feedback form-control bold" min="1" max="1000" value="100">';
			html += '			</div>';
			html += '		</div>';

			if (parameter_with_stock) {
				// With Stock
				html += '		<div class="mr-2 mt-2">';
				html += '			<label id="ioi_item_with_stock_label">' + __("With stock") + '</label>';
				html += '			<div class="control-input d-flex align-items-center" style="height: 30px;"> ';
				html += '				<input id="ioi_item_with_stock" type="checkbox" class="input-with-feedback form-control bold ml-1">';
				html += '			</div>';
				html += '		</div>';
			}

			// Filter button
			html += '		<div class="ml-2 mt-2 mb-1" style="align-self: end;">';
			html += `				<button id="ioi_item_btn_filter" type="button" class="btn btn-secondary btn-xs align-self-end">${__('Filter')}</button>`
			html += '		</div>';

			html += '	</div>';

			html += '	<div id="ioi_item_search_content" class="table table-bordered" data-custom-grid="true"></div>';

			if (qty_field_found) {
				html += '	<div id="ioi_item_search_stock_content_item" style="width:100%;"></div>';
				html += '	<div id="ioi_item_search_stock_content_header" style="width:100%;"></div>';
				html += '	<div id="ioi_item_search_stock_content" style="width:100%;"></div>';
			}

			z.fields_dict.html_select_item.$wrapper.html(html);
			z.$wrapper.find('.modal-dialog').css({'width': '95%', 'max-width': '2000px'})

			z.show();

		})
	}

	static build_ioi_item_grid(fields, fields_desc, fields_len, z) {

		let columns = []

		let format_tags = (cell) => {
			const value = cell.getValue();

			if (value) {
				return value.replaceAll('\n', ' ')
			}

			return '';
		}

		// Transform "a.field, a.field2" into [field, field2]
		fields = fields.split(', ').map(el => {
			const field = el.split('.')
			return field ? field[1] : el
		})

		fields_desc = fields_desc.split(', ')
		fields_len = fields_len.split(', ')

		columns.push({ field: "checkbox", formatter:"rowSelection", titleFormatter:"rowSelection", hozAlign:"center", headerHozAlign:"center", frozen: true, headerSort: false, download: false, minWidth: 40, maxWidth: 40 })

		for (let i = 0; i < fields.length; i++) {
			if (fields[i] === "name") {
				columns.push({ title: fields_desc[i], field: fields[i], width: !!fields_len[i] && fields_len[i] != "0" ? fields_len[i] : '', frozen: true })
			} else if (fields[i] ==="tags") {
				columns.push({ title: fields_desc[i], field: fields[i], width: !!fields_len[i] && fields_len[i] != "0" ? fields_len[i] : '', formatter: format_tags })
			} else {
				columns.push({ title: fields_desc[i], field: fields[i], width: !!fields_len[i] && fields_len[i] != "0" ? fields_len[i] : '' })
			}
		}

		let table = new ioi.Tabulator(`#ioi_item_search_content`, {
			maxHeight: "50vh",
			data: [],
			layout: "fitColumns",
			selectableRows: 1,
			movableColumns: true,
			resizableColumns: true,
			showProfiles: true,
			columns: columns,
			initialSort: [
				{ column: "name", dir: "asc" },
			],
			columnDefaults: {
				minWidth: 100,
			},
		});

		table.on("rowDblClick", function(e, row){
			z.primary_action({}, row);
		});

		// Toggle confirm button if no selected row
		table.on("rowSelectionChanged", function(data, rows, selected, deselected){
			if (rows.length) {
				document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length - 1].disabled = false;
			} else {
				document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length - 1].disabled = true;
			}
		});

		table.on("cellMouseOver", function(e, cell){
			if (cell.getField() === "q_stock") {
				const value = cell.getRow().getData().name
				silicon_ioi.ioiCommon.display_stock(value);
			}
		});

		// Remove display stock on mouse out
		table.on("cellMouseOut", function(e, cell){
			if (cell.getField() === "q_stock") {
				if (document.getElementById('ioi_item_search_stock_content')) {
					document.getElementById('ioi_item_search_stock_content_item').innerHTML = '';
					document.getElementById('ioi_item_search_stock_content_header').innerHTML = '';
					document.getElementById('ioi_item_search_stock_content').innerHTML = '';

					document.getElementById('ioi_item_search_stock_content_item').style.height = '0px'
					document.getElementById('ioi_item_search_stock_content_header').style.height = '0px'
					document.getElementById('ioi_item_search_stock_content').style.height = '0px'
				}
			}
		});

		table.on("headerClick", function (e, column) {
			document.getElementById('item_col_order_by').value = column.getField()
			document.getElementById('item_col_order_order').value = table.getSorters()[0].dir

			silicon_ioi.ioiCommon.item_search(table)
		});

		return table
	}

	// ***************************************************************************************************************************************
	// Item Search : Search Term Key Up
	// ***************************************************************************************************************************************
	static item_keyup(event)
	{
		if (event.keyCode == 13)
		{
			silicon_ioi.ioiCommon.item_search();
		}
	}

	// ***************************************************************************************************************************************
	// Item Search : Search
	// ***************************************************************************************************************************************
	static item_search(table = null) {

		let with_stock = 0;

		if (document.getElementById('ioi_item_with_stock')) {
			if (document.getElementById('ioi_item_with_stock').checked) {
				with_stock = 1;
			}
		}

		if (document.getElementById('window_select_item_detail')) {
			document.getElementById('window_select_item_detail').remove();
		}

		let method = path_item + '.ioi_item_search_form';

		let html = '';

		frappe.call({
			method: method,
			args: {
				"fields": document.getElementById('item_fields').value,
				"conditions": document.getElementById('item_where').value,
				"search": document.getElementById('ioi_item_search').value,
				"orderby": document.getElementById('item_col_order_by').value,
				"orderdir": document.getElementById('item_col_order_order').value,
				"topn": document.getElementById('ioi_item_topn').value,
				"withstock": with_stock,
				"site_id": document.getElementById('ioi_item_site_id').value
			},
			async: false,
			callback: function (r) {

				if (table) {
					if (r.message.length > 0) {
						if (table.initialized) {
							table.setData(r.message)
						} else {
							table.on('tableBuilt', () => {
								table.setData(r.message)
							})
						}

						html += '<table id="window_select_item_detail" border=1 style="border: 1px solid #E8EAEB" width="100px"></table>';

					} else {
						if (table && table.initialized) table.clearData()
					}
				}
			}
		});

		$('#ioi_item_search_content').append(html);
	}

	static select_bom_search(table, sorters=null) {

		let params = {
			search: document.getElementById('select_bom_search').value,
			topn: document.getElementById('select_bom_topn').value,
		}

		if (sorters) params.sorters =`${sorters[0].field} ${sorters[0].dir}`

		frappe.call('silicon_ioi.ioi_configuration.doctype.ioi_bom_release.ioi_bom_release.ioi_bom_select_get_list', params).then(r => table.replaceData(r.message))
	}

	static display_stock(value)
	{
		if (document.getElementById('ioi_item_search_stock_content')) {


			document.getElementById('ioi_item_search_stock_content_item').innerHTML = '';
			document.getElementById('ioi_item_search_stock_content_header').innerHTML = '';
			document.getElementById('ioi_item_search_stock_content').innerHTML = '';

			document.getElementById('ioi_item_search_stock_content_item').style.height = '0px'
			document.getElementById('ioi_item_search_stock_content_header').style.height = '0px'
			document.getElementById('ioi_item_search_stock_content').style.height = '0px'

			let method = 'silicon_ioi.ioi_wms.doctype.ioi_warehouse_stock.ioi_warehouse_stock.ioi_warehouse_stock_get_site_whs_stock_for_an_item';

			let item_id = value;

			document.getElementById('ioi_item_search_stock_content_item').innerHTML = '<b><u>' + __("Item") + '</u></b>&nbsp;' + item_id;

			document.getElementById('ioi_item_search_stock_content_item').style.height = '20px';


			frappe.call({  	method: method,
							args: {"item_id": item_id, "site_id": '', "warehouse_id": ''},
							async: false,
							callback:function(r)	{
														let html_header = '';
														let html = '';



														if (r.message.length > 0) {

															html_header += '<table width=560px>';
															html_header += '<tr height=20px>';
															html_header += '<td width=60px><u><b>' + __('Site') + '</b></u></td>';
															html_header += '<td width=120px><u><b>' + __('Site qty') + '</b></u></td>';
															html_header += '<td width=80px><u><b>' + __('Unit') + '</b></u></td>';
															html_header += '<td width=100px><u><b>' + __('Whs') + '</b></u></td>';
															html_header += '<td width=120px><u><b>' + __('Whs qty') + '</b></u></td>';
															html_header += '<td width=80px><u><b>' + __('Unit') + '</b></u></td>';
															html_header += '</tr>';
															html_header += '</table>';

															html += '<table width=560px>';

															let nb = 0;

															for (var i = 0; i < r.message.length; i++) {

																for(var j = 0; j < r.message[i].length; j++) {
																	nb++;



																	html += '<tr height=20px>';

																	html += '<td width=60px>';

																	if (r.message[i][j].is_current == '1') {
																		html += '<b>';
																	}

																	html += r.message[i][j].site_id;

																	if (r.message[i][j].is_current == '1') {
																		html += '</b>';
																	}



																	html += '</td>';
																	html += '<td width=130px>';

																	if (r.message[i][j].is_current == '1') {
																		html += '<b>';
																	}

																	html += r.message[i][j].site_qty;

																	if (r.message[i][j].is_current == '1') {
																		html += '</b>';
																	}

																	html += '</td>';

																	html += '<td width=80px>';

																	if (r.message[i][j].is_current == '1') {
																		html += '<b>';
																	}

																	html += r.message[i][j].site_unit;

																	if (r.message[i][j].is_current == '1') {
																		html += '</b>';
																	}

																	html += '</td>';

																	html += '<td width=100px>';

																	if (r.message[i][j].is_current == '1') {
																		html += '<b>';
																	}

																	html +=r.message[i][j].whs_id;

																	if (r.message[i][j].is_current == '1') {
																		html += '</b>';
																	}

																	html += '</td>';

																	html += '<td width=130px>';

																	if (r.message[i][j].is_current == '1') {
																		html += '<b>';
																	}

																	html += r.message[i][j].whs_qty;

																	if (r.message[i][j].is_current == '1') {
																		html += '</b>';
																	}

																	html += '</td>';

																	html += '<td width=80px>';

																	if (r.message[i][j].is_current == '1') {
																		html += '<b>';
																	}

																	html += r.message[i][j].whs_unit;

																	if (r.message[i][j].is_current == '1') {
																		html += '</b>';
																	}

																	html += '</td>';

																	html += '</tr>';

																}
															}
															html += '</table>';

															document.getElementById('ioi_item_search_stock_content_header').innerHTML = html_header;
															document.getElementById('ioi_item_search_stock_content').innerHTML = html;

															nb *= 30;
															document.getElementById('ioi_item_search_stock_content_header').style.height = '20px';

															if (nb > 120) {
																nb = 120;
															}
															document.getElementById('ioi_item_search_stock_content').style.height = nb.toString() + 'px';

														}else{
															document.getElementById('ioi_item_search_stock_content_item').innerHTML = '';
															document.getElementById('ioi_item_search_stock_content_item').style.height = '0px';

															document.getElementById('ioi_item_search_stock_content_header').innerHTML = '';
															document.getElementById('ioi_item_search_stock_content_header').style.height = '0px';
															document.getElementById('ioi_item_search_stock_content').innerHTML = '';
															document.getElementById('ioi_item_search_stock_content').style.height = '0px';

														}


							}
			});

		}

	}

	// ***************************************************************************************************************************************
	// Purchases Price Search : Select purchases prices
	// ***************************************************************************************************************************************
	static select_purchases_prices(fields, fields_len, fields_desc, where, order_by, callback)
	{
		var z = new frappe.ui.Dialog({
			'title': __("Select a purchases price"),
			'fields': [
				{'fieldname': 'html_select_purchases_prices', 'fieldtype': 'HTML'}

			],
			primary_action_label: 'Ok',
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

				let go = true;
				let ret_value = '';


				if (document.getElementById('purchases_prices_nb_record').value == 0)
				{
					go = false;
				}

				if ((go) && (document.getElementById('purchases_prices_nb_record').value != 0))
				{	go = false;

					for (var i = 0; i < document.getElementById('purchases_prices_nb_record').value; i++)
					{

						if (document.getElementById('purchases_prices_checked_id_' + i.toString()))
						{
							if (document.getElementById('purchases_prices_checked_id_' + i.toString()).checked)
							{
								ret_value = document.getElementById('purchases_prices_id_' + i.toString()).value;
								go = true;
								break;
							}
						}
					}
				}

				if (go)
				{
					z.hide();

					callback(ret_value);
				}else{
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
				}
			},
			secondary_action: function(){
				z.hide();
			}

		});

		if (document.getElementById('purchases_prices_search_label'))
		{	document.getElementById('purchases_prices_search_label').remove();
		}

		if (document.getElementById('purchases_prices_search'))
		{	document.getElementById('purchases_prices_search').remove();
		}


		if (document.getElementById('purchases_prices_topn_label'))
		{	document.getElementById('purchases_prices_topn_label').remove();
		}

		if (document.getElementById('purchases_prices_topn'))
		{	document.getElementById('purchases_prices_topn').remove();
		}

		if (document.getElementById('purchases_prices_fields'))
		{	document.getElementById('purchases_prices_fields').remove();
		}

		if (document.getElementById('purchases_prices_fields_len'))
		{	document.getElementById('purchases_prices_fields_len').remove();
		}

		if (document.getElementById('purchases_prices_fields_desc'))
		{	document.getElementById('purchases_prices_fields_desc').remove();
		}

		if (document.getElementById('purchases_prices_where'))
		{	document.getElementById('purchases_prices_where').remove();
		}

		if (document.getElementById('purchases_prices_order_by'))
		{	document.getElementById('purchases_prices_order_by').remove();
		}

		if (document.getElementById('purchases_prices_search_content'))
		{	document.getElementById('purchases_prices_search_content').remove();
		}

		if (document.getElementById('purchases_prices_col_order_by'))
		{	document.getElementById('purchases_prices_col_order_by').remove();
		}

		if (document.getElementById('purchases_prices_col_order_order'))
		{	document.getElementById('purchases_prices_col_order_order').remove();
		}

		let html = '';

		html += '<div style="overflow-x: auto; width:100%;">';
		html += '	<input type="hidden" id="purchases_prices_fields" value="' + fields + '">';
		html += '	<input type="hidden" id="purchases_prices_fields_len" value="' + fields_len + '">';
		html += '	<input type="hidden" id="purchases_prices_fields_desc" value="' + fields_desc + '">';
		html += '	<input type="hidden" id="purchases_prices_where" value="' + where + '">';
		html += '	<input type="hidden" id="purchases_prices_order_by" value="' + order_by + '">';
		html += '	<input type="hidden" id="purchases_prices_col_order_by" value="name">';
		html += '	<input type="hidden" id="purchases_prices_col_order_order" value="asc">';

		html += '	<div style="overflow-x: auto; height:65px; width:100%;">';

		// Search
		html += '		<div style="position: relative; top: 0px; left: 0px; width:300px;">';
		html += '			<label id="purchases_prices_search_label" style="position: absolute; top: 0px; left: 2px;">' + __("Search") + '</label>';
		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 300px; height: 25px;"> ';
		html += '				<input id="purchases_prices_search" type="text" class="input-with-feedback form-control bold">';
		html += '			</div>';
		html += '		</div>';

		// Topn
		html += '		<div style="position: relative; top: 0px; left: 310px; width:170px;">';
		html += '			<label id="purchases_prices_topn_label" style="position: absolute; top: 0px; left: 2px;">' + __("No records") + '</label>';
		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 170px; height: 30px;"> ';
		html += '				<input id="purchases_prices_topn" type="number" class="input-with-feedback form-control bold" min="1" max="1000" value="100">';
		html += '			</div>';
		html += '		</div>';

		html += '	</div>';

		html += '	<div style="overflow-x: auto; height:10px; width:100%;"></div>';

		html += '	<div id="purchases_prices_search_content" style="overflow-x: auto; height:500px; width:100%;">';
		html += '	</div>';

		let tot_len = 0;
		let col_count = 0;

		let col_field = [];
		let col_desc = [];
		let col_len = [];

		if ((fields) && (fields.trim() != ''))
		{
			// field names
			let s = fields;

			while (s.indexOf(',') != -1)
			{
				let fn = s.substring(0, s.indexOf(','));
				fn = fn.trim();

				if (fn != '')
				{	col_field[col_count] = fn;
					col_count++;
				}

				s = s.substring(s.indexOf(',')+1, s.length);
			}

			if (s.trim() != '')
			{
				s = s.trim();
				col_field[col_count] = s;
			}

			col_count = 0;

			// field descriptions
			s = fields_desc;

			while (s.indexOf(',') != -1)
			{
				let fn = s.substring(0, s.indexOf(','));
				fn = fn.trim();

				if (fn != '')
				{	col_desc[col_count] = fn;
					col_count++;
				}

				s = s.substring(s.indexOf(',')+1, s.length);
			}

			if (s.trim() != '')
			{
				s = s.trim();
				col_desc[col_count] = s;
			}

			col_count = 0;

			// field lengths
			if ((fields_len) && (fields_len.trim() != ''))
			{
				s = fields_len;

				while (s.indexOf(',') != -1)
				{
					let n = s.substring(0, s.indexOf(','));
					n = n.trim();

					if (n != '')
					{	col_len[col_count] = parseInt(n);
						col_count++;
						tot_len += parseInt(n);
					}

					s = s.substring(s.indexOf(',')+1, s.length);
				}

				if (s.trim() != '')
				{
					s = s.trim();
					tot_len += parseInt(s);
				}
			}
		}

		let form_width = '50%';

		if (tot_len == 0)
		{
			col_field[0] = 'name';
			col_field[1] = 'unit_price';

			col_desc[0] = __('Name');
			col_desc[1] = __('Unit price');

			col_len[0] = 500;
			col_len[1] = 150;

			tot_len = 680;
			form_width = '30%';
		}else
		{ 	tot_len += 30;
		}

		z.fields_dict.html_select_purchases_prices.$wrapper.html(html);
		z.$wrapper.find('.modal-dialog').css("max-width", form_width).css("width", form_width);
		z.$wrapper.find('.modal-dialog').css("max-height", "50%").css("height", "50%");
		z.show();

		silicon_ioi.ioiCommon.select_sleep(250).then(() => {
			let fct_keyup = function(event)
			{
				if (event.keyCode == 13)
				{
					silicon_ioi.ioiCommon.purchases_prices_search(z);
				}
			}

			document.getElementById('purchases_prices_search').onkeyup = fct_keyup;
			document.getElementById('purchases_prices_topn').onkeyup = fct_keyup;
			silicon_ioi.ioiCommon.purchases_prices_search(z);
		});
	}

	// ***************************************************************************************************************************************
	// Purchases Prices Search : Search Term Key Up
	// ***************************************************************************************************************************************
	static purchases_prices_keyup(event)
	{
		if (event.keyCode == 13)
		{
			silicon_ioi.ioiCommon.purchases_prices_search();
		}
	}

	// ***************************************************************************************************************************************
	// Customer Search : Search
	// ***************************************************************************************************************************************
	static purchases_prices_search(z)
	{
		document.getElementById('purchases_prices_search_content').innerHTML = '';

		if (document.getElementById('purchases_prices_nb_record'))
		{
			for (var i = 0; i < document.getElementById('purchases_prices_nb_record').value; i++)
			{
				if (document.getElementById('purchases_prices_checked_id_' + i.toString()))
				{	document.getElementById('purchases_prices_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('purchases_prices_id_' + i.toString()))
				{	document.getElementById('purchases_prices_id_' + i.toString()).remove();
				}
			}

			document.getElementById('purchases_prices_nb_record').remove();
		}

		let tot_len = 0;
		let col_count = 0;

		let col_field = [];
		let col_desc = [];
		let col_len = [];

		if ((document.getElementById('purchases_prices_fields').value) && (document.getElementById('purchases_prices_fields').value.trim() != ''))
		{
			// field names
			let s = document.getElementById('purchases_prices_fields').value;

			while (s.indexOf(',') != -1)
			{
				let fn = s.substring(0, s.indexOf(','));
				fn = fn.trim();

				if (fn != '')
				{	col_field[col_count] = fn;
					col_count++;
				}

				s = s.substring(s.indexOf(',')+1, s.length);
			}

			if (s.trim() != '')
			{
				s = s.trim();
				col_field[col_count] = s;
			}

			col_count = 0;

			// field descriptions
			s = document.getElementById('purchases_prices_fields_desc').value;

			while (s.indexOf(',') != -1)
			{
				let fn = s.substring(0, s.indexOf(','));
				fn = fn.trim();

				if (fn != '')
				{	col_desc[col_count] = fn;
					col_count++;
				}

				s = s.substring(s.indexOf(',')+1, s.length);
			}

			if (s.trim() != '')
			{
				s = s.trim();
				col_desc[col_count] = s;
			}

			col_count = 0;

			// field lengths
			if ((document.getElementById('purchases_prices_fields_len').value) && (document.getElementById('purchases_prices_fields_len').value.trim() != ''))
			{
				s = document.getElementById('purchases_prices_fields_len').value;

				while (s.indexOf(',') != -1)
				{
					let n = s.substring(0, s.indexOf(','));
					n = n.trim();

					if (n != '')
					{	col_len[col_count] = parseInt(n);
						col_count++;
						tot_len += parseInt(n);
					}

					s = s.substring(s.indexOf(',')+1, s.length);
				}

				if (s.trim() != '')
				{
					s = s.trim();
					tot_len += parseInt(s);
				}
			}
		}

		if (tot_len == 0)
		{
			col_field[0] = 'name';
			col_field[1] = 'unit_price';

			col_desc[0] = __('Name');
			col_desc[1] = __('Unit price');

			col_len[0] = 500;
			col_len[1] = 150;

			tot_len = 680;
		}else
		{ 	tot_len += 30;
		}


		for (var i=0; i < col_field.length; i++)
		{
			if (document.getElementById('purchases_prices_search_col_label_' + col_field[i]))
			{	document.getElementById('purchases_prices_search_col_label_' + col_field[i]).remove();
			}

			if (document.getElementById('purchases_prices_search_col_' + col_field[i]))
			{	document.getElementById('purchases_prices_search_col_' + col_field[i]).remove();
			}

		}

		let html = '';

		let is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

		html += '<table border=1 style="border: 1px solid #E8EAEB" width=' + tot_len + 'px data-custom-grid="true">';

		html += '<tr style="height:30px">';

		html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';

		for (var i = 0; i < col_field.length; i++)
		{
			let sens = ''

			if (document.getElementById('purchases_prices_col_order_by').value.toUpperCase() == col_field[i].toUpperCase())
			{
				if (document.getElementById('purchases_prices_col_order_order').value.toUpperCase() == 'ASC')
				{	sens = '&darr;'
				}else
				{	sens = '&uarr;'
				}
			}

			html += '<td id="purchases_prices_search_col_' + col_field[i] + '" width=' + col_len[i] + 'px style="vertical-align: middle;">&nbsp;<b>' + col_desc[i] + '<label id="purchases_prices_search_col_label_' + col_field[i] + '" style="width:30px; height:8px" align="right">' + sens + '</label></b></td>';

		}

		html += '</tr>';
		html += '</table>';

		if (document.getElementById('window_select_purchases_price_detail')) {
			document.getElementById('window_select_purchases_detail').remove();
		}



		let method = path_purchases_price + '.ioi_purchases_price_search_form';

		frappe.call({  	method: method,
						args: {	"fields": document.getElementById('purchases_prices_fields').value, "conditions": document.getElementById('purchases_prices_where').value,
								"search" : document.getElementById('purchases_prices_search').value,
								"orderby": document.getElementById('purchases_prices_col_order_by').value,
								"orderdir": document.getElementById('purchases_prices_col_order_order').value,
								"topn": document.getElementById('purchases_prices_topn').value},
						async: false,
						callback:function(r)	{
													if (r.message.length > 0)
													{
														html += '<table id="window_select_purchases_price_detail" border=1 style="border: 1px solid #E8EAEB" width=' + tot_len + 'px">';

														let cpt = 0;


														for (var i = 0; i < r.message.length; i++)
														{
															html += '<tr id="window_select_purchases_price_row_' + i.toString() + '" style="height:30px">';

															html += '<td width=30px align="center" style="vertical-align: middle;">';
															html += '<input type="checkbox" id="purchases_prices_checked_id_' + cpt.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
															html += '       onclick=" ';
															html += '					for (var i = 0; i < document.getElementById(\'purchases_prices_nb_record\').value; i++) ';
															html += '					{   if (document.getElementById(\'purchases_prices_checked_id_\' + i.toString())) ';
															html += '						{ '
															html += '							if (document.getElementById(\'purchases_prices_checked_id_\' + i.toString()).id != this.id) ';
															html += '							{ ';
															html += '								document.getElementById(\'purchases_prices_checked_id_\' + i.toString()).checked = false; ';
															html += '							} ';
															html += '						} ';
															html += '					} ';
															html += '" ';

															html += '>';
															html += '<input type="hidden" id="purchases_prices_id_' + cpt.toString() + '" value="' +  r.message[i][0] + '">';
															html += '</td>';

															for (var k = 0; k < col_field.length; k++)
															{
																if (r.message[i][k] != null)
																{
																	let search_term = document.getElementById('purchases_prices_search').value;
																	search_term = search_term.trim();

																	if (search_term != '') {

																		search_term = search_term.toUpperCase();

																		let data = r.message[i][k].toString();

																		if (data != '') {

																			data = data.trim().toUpperCase();

																			if (data.indexOf(search_term) != -1) {
																				let from_idx = data.indexOf(search_term);
																				let len_search = search_term.length;

																				let begin_data = r.message[i][k].toString().substring(0, from_idx);
																				let intermediate_data = r.message[i][k].toString().substring(from_idx,  from_idx + len_search);
																				let end_data = r.message[i][k].toString().substring(from_idx+len_search, data.length);

																				let formatted_data = begin_data;

																				if (is_dark_mode == 0) {
																					formatted_data += '<font style="background-color:#D0E7FB"><b>' + intermediate_data + '</b></font>' + end_data;
																				}else{
																					formatted_data += '<font style="background-color:gray"><b>' + intermediate_data + '</b></font>' + end_data;
																				}

																				html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;' + formatted_data + '</td>'

																			}else {
																				html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;' + r.message[i][k] + '</td>'
																			}
																		}else{
																			html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;</td>'
																		}

																	}else{
																		html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;' + r.message[i][k] + '</td>'
																	}

																}else
																{	html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;</td>'
																}
															}

															html += '</tr>';

															cpt++;
														}

														html += '<input type="hidden" id="purchases_prices_nb_record" value="' + cpt.toString() + '">';
														html += '</table>';

													}else
													{
														html += '<input type="hidden" id="purchases_prices_nb_record" value="0">';
													}

												}
		});
		html += '</div>';
		document.getElementById('purchases_prices_search_content').innerHTML = html;

		silicon_ioi.ioiCommon.select_sleep(250).then(() => {


			let is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

			if (document.getElementById('window_select_purchases_price_detail')) {

				let fct_row_dbclick = function() {
					this.click();
					z.primary_action();
				}

				let fct_row_click = function() {
					let s = this.id;

					while (s.indexOf('_') != -1) {
						s = s.substring(s.indexOf('_')+1, s.length)
					}

					s = s.trim();

					for (var i = 0; i < document.getElementById('window_select_purchases_price_detail').rows.length; i++) {

						if (is_dark_mode == 0) {
							document.getElementById('window_select_purchases_price_detail').rows[i].style.backgroundColor = '#FFFFFF';
						}else{
							document.getElementById('window_select_purchases_price_detail').rows[i].style.backgroundColor = '#1C2126';
						}


						if (document.getElementById('purchases_prices_checked_id_' + i.toString())) {
							document.getElementById('purchases_prices_checked_id_' + i.toString()).checked = false;
						}

					}

					document.getElementById('purchases_prices_checked_id_' + s).checked = true;

					if (is_dark_mode == 0) {
						document.getElementById('window_select_purchases_price_detail').rows[parseInt(s)].style.backgroundColor = '#B1FCD9';
					}else{
						document.getElementById('window_select_purchases_price_detail').rows[parseInt(s)].style.backgroundColor = 'green';

					}

				}


				for (var i = 0; i < document.getElementById('window_select_purchases_price_detail').rows.length; i++) {

					if (document.getElementById('window_select_purchases_price_row_' + i.toString())) {
						document.getElementById('window_select_purchases_price_row_' + i.toString()).onclick = fct_row_click;
						document.getElementById('window_select_purchases_price_row_' + i.toString()).ondblclick = fct_row_dbclick;
					}
				}
			}


			let fct_header_col_click = function() {
				silicon_ioi.ioiCommon.purchases_prices_col_click(this, z);
			}

			for (var i=0; i < col_field.length; i++)
			{
				if (document.getElementById('purchases_prices_search_col_' + col_field[i]))
				{	document.getElementById('purchases_prices_search_col_' + col_field[i]).onclick = fct_header_col_click;
					document.getElementById('purchases_prices_search_col_' + col_field[i]).onmouseover = silicon_ioi.ioiCommon.col_mouse_over;
					document.getElementById('purchases_prices_search_col_' + col_field[i]).onmouseleave = silicon_ioi.ioiCommon.col_mouse_leave;
				}

			}
			document.getElementById('purchases_prices_search').focus();
		});
	}

	// ***************************************************************************************************************************************
	// Purchases Prices Search : Column click
	// ***************************************************************************************************************************************
	static purchases_prices_col_click(obj, z)
	{
		let s = obj.id;

		while (s.indexOf('_') != -1) {
			s = s.substring(s.indexOf('_')+1, s.length);
		}



		if (document.getElementById('purchases_prices_col_order_by').value.toUpperCase() == s.toUpperCase())
		{
			if (document.getElementById('purchases_prices_col_order_order').value == 'desc')
			{	document.getElementById('purchases_prices_col_order_order').value = 'asc';
			}else
			{	document.getElementById('purchases_prices_col_order_order').value = 'desc';
			}
		}else
		{	document.getElementById('purchases_prices_col_order_by').value = s;
			document.getElementById('purchases_prices_col_order_order').value = 'desc';
		}

		silicon_ioi.ioiCommon.purchases_prices_search(z);
	}

	// ***************************************************************************************************************************************
	// Vat code Search : Select vatcode
	// ***************************************************************************************************************************************
	static select_vatcode(fields, fields_len, fields_desc, where, order_by, callback)
	{
		var z = new frappe.ui.Dialog({
			'title': __("Select VAT code"),
			'fields': [
				{'fieldname': 'html_select_vatcode', 'fieldtype': 'HTML'}

			],
			primary_action_label: 'Ok',
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

				let go = true;
				let ret_value = '';


				if (document.getElementById('vatcode_nb_record').value == 0)
				{
					go = false;
				}

				if ((go) && (document.getElementById('vatcode_nb_record').value != 0))
				{	go = false;

					for (var i = 0; i < document.getElementById('vatcode_nb_record').value; i++)
					{

						if (document.getElementById('vatcode_checked_id_' + i.toString()))
						{
							if (document.getElementById('vatcode_checked_id_' + i.toString()).checked)
							{
								ret_value = document.getElementById('vatcode_id_' + i.toString()).value;;
								go = true;
								break;
							}
						}
					}
				}

				if (go)
				{
					z.hide();

					callback(ret_value);
				}else{
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
				}
			},
			secondary_action: function(){
				z.hide();
			}

		});

		if (document.getElementById('ioi_vatcode_search_label'))
		{	document.getElementById('ioi_vatcode_search_label').remove();
		}

		if (document.getElementById('ioi_vatcode_search'))
		{	document.getElementById('ioi_vatcode_search').remove();
		}


		if (document.getElementById('ioi_vatcode_topn_label'))
		{	document.getElementById('ioi_vatcode_topn_label').remove();
		}

		if (document.getElementById('ioi_vatcode_topn'))
		{	document.getElementById('ioi_vatcode_topn').remove();
		}

		if (document.getElementById('vatcode_fields'))
		{	document.getElementById('vatcode_fields').remove();
		}

		if (document.getElementById('vatcode_fields_len'))
		{	document.getElementById('vatcode_fields_len').remove();
		}

		if (document.getElementById('vatcode_fields_desc'))
		{	document.getElementById('vatcode_fields_desc').remove();
		}

		if (document.getElementById('vatcode_where'))
		{	document.getElementById('vatcode_where').remove();
		}

		if (document.getElementById('vatcode_order_by'))
		{	document.getElementById('vatcode_order_by').remove();
		}

		if (document.getElementById('ioi_vatcode_search_content'))
		{	document.getElementById('ioi_vatcode_search_content').remove();
		}

		if (document.getElementById('vatcode_col_order_by'))
		{	document.getElementById('vatcode_col_order_by').remove();
		}

		if (document.getElementById('vatcode_col_order_order'))
		{	document.getElementById('vatcode_col_order_order').remove();
		}

		let html = '';

		html += '<div style="overflow-x: auto; width:100%;">';
		html += '	<input type="hidden" id="vatcode_fields" value="' + fields + '">';
		html += '	<input type="hidden" id="vatcode_fields_len" value="' + fields_len + '">';
		html += '	<input type="hidden" id="vatcode_fields_desc" value="' + fields_desc + '">';
		html += '	<input type="hidden" id="vatcode_where" value="' + where + '">';
		html += '	<input type="hidden" id="vatcode_order_by" value="' + order_by + '">';
		html += '	<input type="hidden" id="vatcode_col_order_by" value="name">';
		html += '	<input type="hidden" id="vatcode_col_order_order" value="asc">';

		html += '	<div style="overflow-x: auto; height:65px; width:100%;">';

		// Search
		html += '		<div style="position: relative; top: 0px; left: 0px; width:300px;">';
		html += '			<label id="ioi_vatcode_search_label" style="position: absolute; top: 0px; left: 2px;">' + __("Search") + '</label>';
		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 300px; height: 25px;"> ';
		html += '				<input id="ioi_vatcode_search" type="text" class="input-with-feedback form-control bold">';
		html += '			</div>';
		html += '		</div>';

		// Topn
		html += '		<div style="position: relative; top: 0px; left: 310px; width:170px;">';
		html += '			<label id="ioi_vatcode_topn_label" style="position: absolute; top: 0px; left: 2px;">' + __("No records") + '</label>';
		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 170px; height: 30px;"> ';
		html += '				<input id="ioi_vatcode_topn" type="number" class="input-with-feedback form-control bold" min="1" max="1000" value="100">';
		html += '			</div>';
		html += '		</div>';

		html += '	</div>';

		html += '	<div style="overflow-x: auto; height:10px; width:100%;"></div>';

		html += '	<div id="ioi_vatcode_search_content" style="overflow-x: auto; height:500px; width:100%;">';
		html += '	</div>';

		let tot_len = 0;
		let col_count = 0;

		let col_field = [];
		let col_desc = [];
		let col_len = [];

		if ((fields) && (fields.trim() != ''))
		{
			// field names
			let s = fields;

			while (s.indexOf(',') != -1)
			{
				let fn = s.substring(0, s.indexOf(','));
				fn = fn.trim();

				if (fn != '')
				{	col_field[col_count] = fn;
					col_count++;
				}

				s = s.substring(s.indexOf(',')+1, s.length);
			}

			if (s.trim() != '')
			{
				s = s.trim();
				col_field[col_count] = s;
			}

			col_count = 0;

			// field descriptions
			s = fields_desc;

			while (s.indexOf(',') != -1)
			{
				let fn = s.substring(0, s.indexOf(','));
				fn = fn.trim();

				if (fn != '')
				{	col_desc[col_count] = fn;
					col_count++;
				}

				s = s.substring(s.indexOf(',')+1, s.length);
			}

			if (s.trim() != '')
			{
				s = s.trim();
				col_desc[col_count] = s;
			}

			col_count = 0;

			// field lengths
			if ((fields_len) && (fields_len.trim() != ''))
			{
				s = fields_len;

				while (s.indexOf(',') != -1)
				{
					let n = s.substring(0, s.indexOf(','));
					n = n.trim();

					if (n != '')
					{	col_len[col_count] = parseInt(n);
						col_count++;
						tot_len += parseInt(n);
					}

					s = s.substring(s.indexOf(',')+1, s.length);
				}

				if (s.trim() != '')
				{
					s = s.trim();
					tot_len += parseInt(s);
				}
			}
		}

		let form_width = '50%';

		if (tot_len == 0)
		{
			col_field[0] = 'name';
			col_field[1] = 'description';

			col_desc[0] = __('Identification');
			col_desc[1] = __('Description');

			col_len[0] = 200;
			col_len[1] = 300;

			tot_len = 530;
			form_width = '30%';
		}else
		{ 	tot_len += 30;
		}

		z.fields_dict.html_select_vatcode.$wrapper.html(html);
		z.$wrapper.find('.modal-dialog').css("max-width", form_width).css("width", form_width);
		z.$wrapper.find('.modal-dialog').css("max-height", "50%").css("height", "50%");
		z.show();

		silicon_ioi.ioiCommon.select_sleep(250).then(() => {
			let fct_keyup = function(event)
			{
				if (event.keyCode == 13)
				{
					silicon_ioi.ioiCommon.vatcode_search(z);
				}
			}

			document.getElementById('ioi_vatcode_search').onkeyup = fct_keyup;
			document.getElementById('ioi_vatcode_topn').onkeyup = fct_keyup;
			silicon_ioi.ioiCommon.vatcode_search(z);
		});
	}

	// ***************************************************************************************************************************************
	// Vat code : Search Term Key Up
	// ***************************************************************************************************************************************
	static vatcode_keyup(event)
	{
		if (event.keyCode == 13)
		{
			silicon_ioi.ioiCommon.vatcode_search();
		}
	}

	// ***************************************************************************************************************************************
	// Vat code : Search
	// ***************************************************************************************************************************************
	static vatcode_search(z)
	{
		document.getElementById('ioi_vatcode_search_content').innerHTML = '';

		if (document.getElementById('vatcode_nb_record'))
		{
			for (var i = 0; i < document.getElementById('vatcode_nb_record').value; i++)
			{
				if (document.getElementById('vatcode_checked_id_' + i.toString()))
				{	document.getElementById('vatcode_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('vatcode_id_' + i.toString()))
				{	document.getElementById('vatcode_id_' + i.toString()).remove();
				}
			}

			document.getElementById('vatcode_nb_record').remove();
		}

		let tot_len = 0;
		let col_count = 0;

		let col_field = [];
		let col_desc = [];
		let col_len = [];

		if ((document.getElementById('vatcode_fields').value) && (document.getElementById('vatcode_fields').value.trim() != ''))
		{
			// field names
			let s = document.getElementById('vatcode_fields').value;

			while (s.indexOf(',') != -1)
			{
				let fn = s.substring(0, s.indexOf(','));
				fn = fn.trim();

				if (fn != '')
				{	col_field[col_count] = fn;
					col_count++;
				}

				s = s.substring(s.indexOf(',')+1, s.length);
			}

			if (s.trim() != '')
			{
				s = s.trim();
				col_field[col_count] = s;
			}

			col_count = 0;

			// field descriptions
			s = document.getElementById('vatcode_fields_desc').value;

			while (s.indexOf(',') != -1)
			{
				let fn = s.substring(0, s.indexOf(','));
				fn = fn.trim();

				if (fn != '')
				{	col_desc[col_count] = fn;
					col_count++;
				}

				s = s.substring(s.indexOf(',')+1, s.length);
			}

			if (s.trim() != '')
			{
				s = s.trim();
				col_desc[col_count] = s;
			}

			col_count = 0;

			// field lengths
			if ((document.getElementById('vatcode_fields_len').value) && (document.getElementById('vatcode_fields_len').value.trim() != ''))
			{
				s = document.getElementById('vatcode_fields_len').value;

				while (s.indexOf(',') != -1)
				{
					let n = s.substring(0, s.indexOf(','));
					n = n.trim();

					if (n != '')
					{	col_len[col_count] = parseInt(n);
						col_count++;
						tot_len += parseInt(n);
					}

					s = s.substring(s.indexOf(',')+1, s.length);
				}

				if (s.trim() != '')
				{
					s = s.trim();
					tot_len += parseInt(s);
				}
			}
		}

		if (tot_len == 0)
		{
			col_field[0] = 'name';
			col_field[1] = 'description';

			col_desc[0] = __('Identification');
			col_desc[1] = __('Description');

			col_len[0] = 200;
			col_len[1] = 300;

			tot_len = 530;
		}else
		{ 	tot_len += 30;
		}


		for (var i=0; i < col_field.length; i++)
		{
			if (document.getElementById('vatcode_search_col_label_' + col_field[i]))
			{	document.getElementById('vatcode_search_col_label_' + col_field[i]).remove();
			}

			if (document.getElementById('vatcode_search_col_' + col_field[i]))
			{	document.getElementById('vatcode_search_col_' + col_field[i]).remove();
			}

		}

		let html = '';


		let is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

		html += '<table border=1 style="border: 1px solid #E8EAEB" width=' + tot_len + 'px data-custom-grid="true">';

		html += '<tr style="height:30px">';

		html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';

		for (var i = 0; i < col_field.length; i++)
		{
			let sens = ''

			if (document.getElementById('vatcode_col_order_by').value.toUpperCase() == col_field[i].toUpperCase())
			{
				if (document.getElementById('vatcode_col_order_order').value.toUpperCase() == 'ASC')
				{	sens = '&darr;'
				}else
				{	sens = '&uarr;'
				}
			}

			html += '<td id="vatcode_search_col_' + col_field[i] + '" width=' + col_len[i] + 'px style="vertical-align: middle;">&nbsp;<b>' + col_desc[i] + '<label id="vatcode_search_col_label_' + col_field[i] + '" style="width:30px; height:8px" align="right">' + sens + '</label></b></td>';

		}

		html += '</tr>';
		html += '</table>';

		if (document.getElementById('window_select_vatcode_detail')) {
			document.getElementById('window_select_vatcode_detail').remove();
		}


		let method = path_common + '.ioi_vatcode_search_form';

		frappe.call({  	method: method,
						args: {	"fields": document.getElementById('vatcode_fields').value, "conditions": document.getElementById('vatcode_where').value,
								"search" : document.getElementById('ioi_vatcode_search').value,
								"orderby": document.getElementById('vatcode_col_order_by').value,
								"orderdir": document.getElementById('vatcode_col_order_order').value,
								"topn": document.getElementById('ioi_vatcode_topn').value},
						async: false,
						callback:function(r)	{
													if (r.message.length > 0)
													{
														html += '<table id="window_select_vatcode_detail" border=1 style="border: 1px solid #E8EAEB" width=' + tot_len + 'px">';

														let cpt = 0;


														for (var i = 0; i < r.message.length; i++)
														{
															html += '<tr id="window_select_vatcode_row_' + i.toString() + '" style="height:30px">';

															html += '<td width=30px align="center" style="vertical-align: middle;">';
															html += '<input type="checkbox" id="vatcode_checked_id_' + cpt.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
															html += '       onclick=" ';
															html += '					for (var i = 0; i < document.getElementById(\'vatcode_nb_record\').value; i++) ';
															html += '					{   if (document.getElementById(\'vatcode_checked_id_\' + i.toString())) ';
															html += '						{ '
															html += '							if (document.getElementById(\'vatcode_checked_id_\' + i.toString()).id != this.id) ';
															html += '							{ ';
															html += '								document.getElementById(\'vatcode_checked_id_\' + i.toString()).checked = false; ';
															html += '							} ';
															html += '						} ';
															html += '					} ';
															html += '" ';

															html += '>';
															html += '<input type="hidden" id="vatcode_id_' + cpt.toString() + '" value="' +  r.message[i][0] + '">';
															html += '</td>';

															for (var k = 0; k < col_field.length; k++)
															{
																if (r.message[i][k] != null)
																{
																	let search_term = document.getElementById('ioi_vatcode_search').value;
																	search_term = search_term.trim();

																	if (search_term != '') {

																		search_term = search_term.toUpperCase();

																		let data = r.message[i][k].toString();

																		if (data != '') {

																			data = data.trim().toUpperCase();

																			if (data.indexOf(search_term) != -1) {
																				let from_idx = data.indexOf(search_term);
																				let len_search = search_term.length;

																				let begin_data = r.message[i][k].toString().substring(0, from_idx);
																				let intermediate_data = r.message[i][k].toString().substring(from_idx,  from_idx + len_search);
																				let end_data = r.message[i][k].toString().substring(from_idx+len_search, data.length);

																				let formatted_data = begin_data;

																				if (is_dark_mode == 0) {
																					formatted_data += '<font style="background-color:#D0E7FB"><b>' + intermediate_data + '</b></font>' + end_data;
																				}else{
																					formatted_data += '<font style="background-color:gray"><b>' + intermediate_data + '</b></font>' + end_data;
																				}

																				html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;' + formatted_data + '</td>'

																			}else {
																				html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;' + r.message[i][k] + '</td>'
																			}
																		}else {
																			html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;</td>'
																		}

																	}else{
																		html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;' + r.message[i][k] + '</td>'
																	}
																}else
																{	html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;</td>'
																}
															}

															html += '</tr>';

															cpt++;
														}

														html += '<input type="hidden" id="vatcode_nb_record" value="' + cpt.toString() + '">';
														html += '</table>';

													}else
													{
														html += '<input type="hidden" id="vatcodenb_record" value="0">';
													}

												}
		});
		html += '</div>';
		document.getElementById('ioi_vatcode_search_content').innerHTML = html;

		silicon_ioi.ioiCommon.select_sleep(250).then(() => {

			let is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

			if (document.getElementById('window_select_vatcode_detail')) {

				let fct_row_dbclick = function() {
					this.click();
					z.primary_action();
				}

				let fct_row_click = function() {
					let s = this.id;

					while (s.indexOf('_') != -1) {
						s = s.substring(s.indexOf('_')+1, s.length)
					}

					s = s.trim();

					for (var i = 0; i < document.getElementById('window_select_vatcode_detail').rows.length; i++) {

						if (is_dark_mode == 0) {
							document.getElementById('window_select_vatcode_detail').rows[i].style.backgroundColor = '#FFFFFF';
						}else{
							document.getElementById('window_select_vatcode_detail').rows[i].style.backgroundColor = '#1C2126';
						}


						if (document.getElementById('vatcode_checked_id_' + i.toString())) {
							document.getElementById('vatcode_checked_id_' + i.toString()).checked = false;
						}

					}

					document.getElementById('vatcode_checked_id_' + s).checked = true;

					if (is_dark_mode == 0) {
						document.getElementById('window_select_vatcode_detail').rows[parseInt(s)].style.backgroundColor = '#B1FCD9';
					}else{
						document.getElementById('window_select_vatcode_detail').rows[parseInt(s)].style.backgroundColor = 'green';

					}

				}


				for (var i = 0; i < document.getElementById('window_select_vatcode_detail').rows.length; i++) {

					if (document.getElementById('window_select_vatcode_row_' + i.toString())) {
						document.getElementById('window_select_vatcode_row_' + i.toString()).onclick = fct_row_click;
						document.getElementById('window_select_vatcode_row_' + i.toString()).ondblclick = fct_row_dbclick;
					}
				}
			}


			let fct_header_col_click = function() {
				silicon_ioi.ioiCommon.vatcode_col_click(this, z);
			}

			for (var i=0; i < col_field.length; i++)
			{
				if (document.getElementById('vatcode_search_col_' + col_field[i]))
				{	document.getElementById('vatcode_search_col_' + col_field[i]).onclick = fct_header_col_click;
					document.getElementById('vatcode_search_col_' + col_field[i]).onmouseover = silicon_ioi.ioiCommon.col_mouse_over;
					document.getElementById('vatcode_search_col_' + col_field[i]).onmouseleave = silicon_ioi.ioiCommon.col_mouse_leave;
				}

			}

			document.getElementById('ioi_vatcode_search').focus();
		});
	}

	// ***************************************************************************************************************************************
	// VAT code : Column click
	// ***************************************************************************************************************************************
	static vatcode_col_click(obj, z)
	{
		let s = obj.id;

		s = s.substring(19, s.length);

		if (document.getElementById('vatcode_col_order_by').value.toUpperCase() == s.toUpperCase())
		{
			if (document.getElementById('vatcode_col_order_order').value == 'desc')
			{	document.getElementById('vatcode_col_order_order').value = 'asc';
			}else
			{	document.getElementById('vatcode_col_order_order').value = 'desc';
			}
		}else
		{	document.getElementById('vatcode_col_order_by').value = s;
			document.getElementById('vatcode_col_order_order').value = 'desc';
		}

		silicon_ioi.ioiCommon.vatcode_search(z);
	}


	// ***************************************************************************************************************************************
	// Manufacturer Catalog Search : Select manufacturer catalog
	// ***************************************************************************************************************************************
	static select_manufacturer_catalog(fields, fields_len, fields_desc, where, order_by, document_date, callback, execute_live_item_creation = true, supplier = null) {
		let title = __("Select a manufacturer catalog");

		if (document_date) {
			title += ' : ';
		}

		if (document_date) {
			title += __("effective date") + ' : ' + document_date.toString();

		}

		frappe.call(`${path_advanced_search}.ioi_common_advanced_search_definition_fields_get_list`, {
			doctype: 'ioi Manufacturer Catalog',
		}).then(r => {

			let table

			var z = new frappe.ui.Dialog({
				'title': title,
				'fields': [
					{ 'fieldname': 'html_select_manufacturer_catalog', 'fieldtype': 'HTML' }

				],
				primary_action_label: 'Ok',
				secondary_action_label: __('Cancel'),
				primary_action: function (values, row=null) {

					z.footer[0].getElementsByClassName('standard-actions')[0].getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[0].disabled = true;

					let selected_row
					let selected_row_manuf_catalog_id
					let selected_row_item_id

					if (row) {
						// On dblClick, set selected row
						selected_row = [row]
					} else {
						selected_row = table.getSelectedRows();
					}

					selected_row_manuf_catalog_id = selected_row[0].getData().name
					selected_row_item_id = selected_row[0].getData().item_id

					if (selected_row.length) {
						z.hide();
						callback(selected_row_manuf_catalog_id, selected_row_item_id);
					}
				},
				secondary_action: function () {
					z.hide();
				},
				on_page_show: () => {
					table = silicon_ioi.ioiCommon.build_ioi_manufacturer_catalog_grid(fields, fields_desc, fields_len, z, document_date)

					let fct_keyup = function (event) {
						if (event.keyCode == 13) {
							silicon_ioi.ioiCommon.manufacturer_catalog_search(document_date, table);
						}
					}

					document.getElementById('ioi_manufacturer_catalog_search').onkeyup = fct_keyup;
					document.getElementById('ioi_manufacturer_catalog_manufacturer').onkeyup = fct_keyup;
					document.getElementById('ioi_manufacturer_catalog_supplier').onkeyup = fct_keyup;
					document.getElementById('ioi_manufacturer_catalog_topn').onkeyup = fct_keyup;
					document.getElementById('ioi_manufacturer_catalog_btn_filter').onclick = () => silicon_ioi.ioiCommon.manufacturer_catalog_search(document_date, table)

					let fct_change = function () {
						silicon_ioi.ioiCommon.manufacturer_catalog_search(document_date, table);
					}

					document.getElementById('ioi_manufacturer_catalog_item_id').onchange = fct_change;
					document.getElementById('ioi_manufacturer_catalog_item_id_live_creation').onchange = fct_change;

					silicon_ioi.ioiCommon.manufacturer_catalog_search(document_date, table);

					if (execute_live_item_creation) {
						z.add_custom_action(__("Create Item and use it"), () => silicon_ioi.ioiCommon.manufacturer_catalog_item_live_creation(z, table));
					}
				}
			});

			if (document.getElementById('ioi_manufacturer_catalog_advanced_search_content')) {
				document.getElementById('ioi_manufacturer_catalog_advanced_search_content').remove();
			}

			// If advanced search is defined, build fields from ioi Advanced Search Definition
			if (r.message.length) {
				fields = "a.name, a.item_id"
				fields_desc = `${__('Identification')}, ${__('Item')}`
				fields_len = "0, 0"

				for (let i = 0; i < r.message.length; i++) {
					if (r.message[i].field.startsWith("ioi Manufacturer Catalog")) {
						// Format custom fields
						const field = r.message[i].field.replace('ioi Manufacturer Catalog-', '')
						fields += `, a.${field}`
					} else {
						fields += `, a.${r.message[i].field}`
					}
					fields_desc += `, ${__(r.message[i].field_label)}`
					fields_len += `, ${r.message[i].field_width}`
				}
			} else {
			// Else build with default fields
				if (fields && fields.trim() !== '') {
					fields += ', a.tags'
				}

				if (fields_desc && fields_desc.trim() !== '') {
					fields_desc += `, ${__('Tags')}`
				}

				if (fields_len && fields_len.trim() !== '') {
					fields_len += ', 500'
				}

				if (!fields.length) {
					fields = 'name, full_name, tags'
					fields_desc = `${__('Identification')}, ${__('Name')}, ${__('Tags')}`
					fields_len = '200, 300, 500'
				}
			}

			let html = '';

			html += '<div id="ioi_manufacturer_catalog_advanced_search_content" style="overflow-x: auto; width:100%;">';
			html += '	<input type="hidden" id="manufacturer_catalog_fields" value="' + fields + '">';
			html += '	<input type="hidden" id="manufacturer_catalog_fields_len" value="' + fields_len + '">';
			html += '	<input type="hidden" id="manufacturer_catalog_fields_desc" value="' + fields_desc + '">';
			html += '	<input type="hidden" id="manufacturer_catalog_where" value="' + where + '">';
			html += '	<input type="hidden" id="manufacturer_catalog_order_by" value="' + order_by + '">';
			html += '	<input type="hidden" id="manufacturer_catalog_col_order_by" value="a.name">';
			html += '	<input type="hidden" id="manufacturer_catalog_col_order_order" value="asc">';

			html += '	<div class="d-flex align-items-start flex-wrap pl-1 mb-3" style="width:100%;">';

			// Search
			html += '		<div class="mr-2 mt-2" style="width:300px;">';
			html += '			<label id="ioi_manufacturer_catalog_search_label">' + __("Search") + '</label>';
			html += '			<div class="control-input" style="width: 300px; height: 25px;"> ';
			html += '				<input id="ioi_manufacturer_catalog_search" type="text" class="input-with-feedback form-control bold">';
			html += '			</div>';
			html += '		</div>';

			// Manufacturer
			html += '		<div class="mr-2 mt-2" style="width:170px;">';
			html += '			<label id="ioi_manufacturer_catalog_manufacturer_label">' + __("Manufacturer") + '</label>';
			html += '			<div class="control-input" style="width: 170px; height: 25px;"> ';
			html += '				<input id="ioi_manufacturer_catalog_manufacturer" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;">';
			html += '			</div>';
			html += '		</div>';

			// Supplier
			html += '		<div class="mr-2 mt-2" style="width:170px;">';
			html += '			<label id="ioi_manufacturer_catalog_supplier_label">' + __("Supplier") + '</label>';
			html += '			<div class="control-input" style="width: 170px; height: 25px;"> ';
			html += '				<input id="ioi_manufacturer_catalog_supplier" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" ';
			if ((supplier) && (supplier.trim() != '')) {
				html += 'value="' + supplier.toUpperCase() + '" '
			}
			html += '				>';
			html += '			</div>';
			html += '		</div>';

			// Item id
			html += '<div class="mr-2 mt-2" style="width:170px;">';
			html += '	<label id="ioi_manufacturer_catalog_item_id_label">' + __("My reference") + '</label>';
			html += '	<div class="control-input" style="width: 170px; height: 30px;"> ';
			html += '		<select id="ioi_manufacturer_catalog_item_id" class="input-with-feedback form-control bold"> ';
			html += '			<option value="-1">' + __("All") + '</option> ';
			html += '			<option value="0">' + __("Empty") + '</option> ';
			html += '			<option value="1">' + __("Filled") + '</option> ';
			html += '		</select> ';
			html += '	</div>';
			html += '</div>';

			// Item id live creation
			html += '<div class="mr-2 mt-2" style="width:170px;">';
			html += '	<label id="ioi_manufacturer_catalog_item_id_live_creation_label">' + __("Item live creation") + '</label>';
			html += '	<div class="control-input" style="width: 170px; height: 30px;"> ';
			html += '		<select id="ioi_manufacturer_catalog_item_id_live_creation" class="input-with-feedback form-control bold"> ';
			html += '			<option value="-1">' + __("All") + '</option> ';
			html += '			<option value="0">' + __("Empty") + '</option> ';
			html += '			<option value="1">' + __("Filled") + '</option> ';
			html += '		</select> ';
			html += '	</div>';
			html += '</div>';

			// Topn
			html += '		<div class="mr-2 mt-2" style="width:170px;">';
			html += '			<label id="ioi_manufacturer_catalog_topn_label">' + __("No records") + '</label>';
			html += '			<div class="control-input" style="width: 170px; height: 30px;"> ';
			html += '				<input id="ioi_manufacturer_catalog_topn" type="number" class="input-with-feedback form-control bold" min="1" max="1000" value="100">';
			html += '			</div>';
			html += '		</div>';

			// Filter button
			html += '		<div class="mt-2 mb-1" style="align-self: end;">';
			html += `				<button id="ioi_manufacturer_catalog_btn_filter" type="button" class="btn btn-secondary btn-xs align-self-end">${__('Filter')}</button>`
			html += '		</div>';

			html += '	</div>';

			html += '	<div id="ioi_manufacturer_catalog_search_content" class="table table-bordered" data-custom-grid="true"></div>';

			z.fields_dict.html_select_manufacturer_catalog.$wrapper.html(html);
			z.$wrapper.find('.modal-dialog').css({'width': '95%', 'max-width': '2000px'})

			z.show();
		})
	}

	static build_ioi_manufacturer_catalog_grid(fields, fields_desc, fields_len, z, document_date) {

		let columns = []

		let format_tags = (cell) => {
			const value = cell.getValue();

			if (value) {
				return value.replaceAll('\n', ' ')
			}

			return '';
		}

		// Transform "a.field, a.field2" into [field, field2]
		fields = fields.split(', ').map(el => {
			const field = el.split('.')
			return field ? field[1] : el
		})

		fields_desc = fields_desc.split(', ')
		fields_len = fields_len.split(', ')

		columns.push({ field: "checkbox", formatter:"rowSelection", titleFormatter:"rowSelection", hozAlign:"center", headerHozAlign:"center", frozen: true, headerSort: false, download: false, minWidth: 40, maxWidth: 40 })

		for (let i = 0; i < fields.length; i++) {
			if (fields[i] === "name") {
				columns.push({ title: fields_desc[i], field: fields[i], width: !!fields_len[i] && fields_len[i] != "0" ? fields_len[i] : '', frozen: true })
			} else if (fields[i] ==="tags") {
				columns.push({ title: fields_desc[i], field: fields[i], width: !!fields_len[i] && fields_len[i] != "0" ? fields_len[i] : '', formatter: format_tags })
			} else {
				columns.push({ title: fields_desc[i], field: fields[i], width: !!fields_len[i] && fields_len[i] != "0" ? fields_len[i] : '' })
			}
		}

		let table = new ioi.Tabulator(`#ioi_manufacturer_catalog_search_content`, {
			maxHeight: "50vh",
			data: [],
			layout: "fitColumns",
			selectableRows: 1,
			movableColumns: true,
			resizableColumns: true,
			showProfiles: true,
			columns: columns,
			initialSort: [
				{ column: "name", dir: "asc" },
			],
			columnDefaults: {
				minWidth: 100,
			},
		});

		table.on("rowDblClick", function(e, row){
			z.primary_action({}, row);
		});

		// Toggle confirm button if no selected row
		table.on("rowSelectionChanged", function(data, rows, selected, deselected){
			if (rows.length) {
				z.footer[0].getElementsByClassName('standard-actions')[0].getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[0].disabled = false;
			} else {
				z.footer[0].getElementsByClassName('standard-actions')[0].getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[0].disabled = true;
			}
		});

		table.on("headerClick", function (e, column) {
			document.getElementById('manufacturer_catalog_col_order_by').value = column.getField()
			document.getElementById('manufacturer_catalog_col_order_order').value = table.getSorters()[0].dir

			silicon_ioi.ioiCommon.manufacturer_catalog_search(document_date, table)
		});

		return table
	}

	// ***************************************************************************************************************************************
	// Manufacturer Catalog Search : Search
	// ***************************************************************************************************************************************
	static manufacturer_catalog_search(document_date, table) {

		let method = path_manufacturer_catalog + '.ioi_manufacturer_catalog_search_form';

		let html = '';

		frappe.call({
			method: method,
			args: {
				"fields": document.getElementById('manufacturer_catalog_fields').value,
				"conditions": document.getElementById('manufacturer_catalog_where').value,
				"search": document.getElementById('ioi_manufacturer_catalog_search').value,
				"orderby": document.getElementById('manufacturer_catalog_col_order_by').value,
				"orderdir": document.getElementById('manufacturer_catalog_col_order_order').value,
				"topn": document.getElementById('ioi_manufacturer_catalog_topn').value,
				"manufacturer_id": document.getElementById('ioi_manufacturer_catalog_manufacturer').value,
				"item_id": document.getElementById('ioi_manufacturer_catalog_item_id').value,
				"item_live_creation": document.getElementById('ioi_manufacturer_catalog_item_id_live_creation').value,
				"document_date": document_date,
				"supplier_id": document.getElementById('ioi_manufacturer_catalog_supplier').value
			},
			async: false,
			callback: function (r) {
				if (table) {
					if (r.message.length > 0) {
						if (table.initialized) {
							table.setData(r.message)
						} else {
							table.on('tableBuilt', () => {
								table.setData(r.message)
							})
						}

						html += html += '<table id="window_select_manufacturer_catalog_detail" border=1 style="border: 1px solid #E8EAEB" width="100px"></table>';

					} else {
						if (table && table.initialized) table.clearData()
					}
				}
			}
		});

		$('#ioi_manufacturer_catalog_search_content').append(html);
	}

	// ***************************************************************************************************************************************
	// Manufacturer catalog : Item live creation
	// ***************************************************************************************************************************************
	static manufacturer_catalog_item_live_creation(z, table) {

		let selected_row = table.getSelectedRows();

		if (!selected_row.length) {

			frappe.msgprint({ title: __("Message"), message: __('No manufacturer catalog selected'), indicator: "red" });
			return false;
		} else {

			frappe.confirm(__('Create/update item, link it to this catalog and use it ?'),
				() => {
					silicon_ioi.ioiCommon.select_sleep(200).then(() => {

						let catalog_name = selected_row[0].getData().name
						let method = path_manufacturer_catalog + '.ioi_manufacturer_catalog_item_live_creation_check';

						frappe.call({
							method: method,
							args: { "name": catalog_name },
							async: false,
							callback: function (r) {

								if (r.message.error == 1) {
									frappe.msgprint({ title: __("Message"), message: r.message.error_msg, indicator: "red" });
									return false;

								} else {
									silicon_ioi.ioiCommon.manufacturer_catalog_do_item_live_creation(catalog_name, r.message.item_id_live_creation, z, table);
								}
							}
						});
					});
				}, () => {}
			)
		}
	}

	static manufacturer_catalog_do_item_live_creation(catalog_name, item_id_live_creation, z, table) {
		let title = __("Item live creation");

		var item_live_dialog = new frappe.ui.Dialog({
			'title': title,
			'static': true,
			'fields': [
				{ 'fieldname': 'html_manufacturer_item_live_creation', 'fieldtype': 'HTML' }
			],
			primary_action_label: 'Ok',
			secondary_action_label: __('Cancel'),
			primary_action: function () {

				document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length - 1].disabled = true;

				if (document.getElementById('html_manufacturer_item_live_creation_live_item_id').value.trim() == '') {
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length - 1].disabled = false;
					frappe.msgprint({ title: __("Message"), message: __("Item reference is mandatory"), indicator: "red" });
					raise;
				}

				let method = path_manufacturer_catalog + '.ioi_manufacturer_catalog_item_live_creation_check_item_exists';

				frappe.call({
					method: method,
					args: {
						"name": document.getElementById('html_manufacturer_item_live_creation_live_item_id').value.toUpperCase()
					},
					async: false,
					callback: function (r) {

						if (r.message == 1) {

							let msg = __('This item already exists, update it and linked it to this catalog ?');

							frappe.confirm(msg,
								() => {
									silicon_ioi.ioiCommon.select_sleep(200).then(() => {
										item_live_dialog.hide();
										silicon_ioi.ioiCommon.select_sleep(200).then(() => {
											silicon_ioi.ioiCommon.manufacturer_catalog_execute_item_live_creation(catalog_name, document.getElementById('html_manufacturer_item_live_creation_live_item_id').value.toUpperCase(), z);
										});
									});
								},
								() => {
									document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length - 2].disabled = false;
								}
							);
						} else {
							silicon_ioi.ioiCommon.select_sleep(200).then(() => {
								item_live_dialog.hide();
								silicon_ioi.ioiCommon.select_sleep(200).then(() => {
									silicon_ioi.ioiCommon.manufacturer_catalog_execute_item_live_creation(catalog_name, document.getElementById('html_manufacturer_item_live_creation_live_item_id').value.toUpperCase(), z);
								});
							});
						}
					}
				});
			},
			secondary_action: function () {
				item_live_dialog.hide();
			}
		});

		if (document.getElementById('html_manufacturer_item_live_creation_live_item_label')) {
			document.getElementById('html_manufacturer_item_live_creation_live_item_label').remove();
		}

		if (document.getElementById('html_manufacturer_item_live_creation_live_item_id')) {
			document.getElementById('html_manufacturer_item_live_creation_live_item_id').remove();
		}

		let html = '';

		html += '<div style="overflow-x: auto; width:100%;">';

		html += '	<div style="overflow-x: auto; height:65px; width:100%;">';

		// Item id live creation
		html += '		<div style="position: relative; top: 0px; left: 0px; width:320px;">';
		html += '			<label id="html_manufacturer_item_live_creation_live_item_label" style="position: absolute; top: 0px; left: 2px;">' + __("Item reference") + '</label>';
		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 320px; height: 25px;"> ';
		html += '				<input id="html_manufacturer_item_live_creation_live_item_id" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value="' + item_id_live_creation + '">';
		html += '			</div>';
		html += '		</div>';

		html += '	</div>';

		item_live_dialog.fields_dict.html_manufacturer_item_live_creation.$wrapper.html(html);
		item_live_dialog.$wrapper.find('.modal-dialog').css({"max-width": "365px", "width": "365px", "max-height": "50%", "height": "50%"});
		item_live_dialog.show();

		silicon_ioi.ioiCommon.select_sleep(300).then(() => {
			document.getElementById('html_manufacturer_item_live_creation_live_item_id').focus();
		});
	}

	static manufacturer_catalog_execute_item_live_creation(catalog_name, item_id_live_creation, z) {
		let method = path_manufacturer_catalog + '.ioi_manufacturer_catalog_update_item_live_create_item_and_prices';

		frappe.call({
			method: method,
			args: { "name": catalog_name, "hostname": window.location.hostname, "item_id_live_creation": item_id_live_creation },
			async: false,
			callback: function (r) {

				if (r.message.error != 0) {
					frappe.msgprint({ title: __("Message"), message: r.message.error_msg, indicator: "red" });
					raise;
				} else {

					z.primary_action();
				}
			}
		})
	}

	// ***************************************************************************************************************************************
	// Form Search : Select form
	// ***************************************************************************************************************************************
	static select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, callback)
	{
		var z = new frappe.ui.Dialog({
			'title': title,
			'fields': [
				{'fieldname': 'html_select_form', 'fieldtype': 'HTML'}

			],
			primary_action_label: 'Ok',
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

				let go = true;
				let ret_value = '';


				if (document.getElementById('form_nb_record').value == 0)
				{
					go = false;
				}

				if ((go) && (document.getElementById('form_nb_record').value != 0))
				{	go = false;

					for (var i = 0; i < document.getElementById('form_nb_record').value; i++)
					{

						if (document.getElementById('form_checked_id_' + i.toString()))
						{
							if (document.getElementById('form_checked_id_' + i.toString()).checked)
							{
								ret_value = document.getElementById('form_id_' + i.toString()).value;;
								go = true;
								break;
							}
						}
					}
				}

				if (go)
				{

					z.hide();

					callback(ret_value);
				}else{
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
				}

			},
			secondary_action: function(){
				z.hide();
			}

		});

		if (document.getElementById('ioi_form_search_label'))
		{	document.getElementById('ioi_form_search_label').remove();
		}

		if (document.getElementById('ioi_form_search'))
		{	document.getElementById('ioi_form_search').remove();
		}


		if (document.getElementById('ioi_form_topn_label'))
		{	document.getElementById('ioi_form_topn_label').remove();
		}

		if (document.getElementById('ioi_form_topn'))
		{	document.getElementById('ioi_form_topn').remove();
		}

		if (document.getElementById('form_table'))
		{	document.getElementById('form_table').remove();
		}

		if (document.getElementById('form_fields'))
		{	document.getElementById('form_fields').remove();
		}

		if (document.getElementById('form_fields_len'))
		{	document.getElementById('form_fields_len').remove();
		}

		if (document.getElementById('form_fields_desc'))
		{	document.getElementById('form_fields_desc').remove();
		}

		if (document.getElementById('form_where'))
		{	document.getElementById('form_where').remove();
		}

		if (document.getElementById('form_order_by'))
		{	document.getElementById('form_order_by').remove();
		}

		if (document.getElementById('ioi_form_search_content'))
		{	document.getElementById('ioi_form_search_content').remove();
		}

		if (document.getElementById('form_col_order_by'))
		{	document.getElementById('form_col_order_by').remove();
		}

		if (document.getElementById('form_col_order_order'))
		{	document.getElementById('form_col_order_order').remove();
		}

		let html = '';


		html += '<div style="overflow-x: auto; width:100%;">';
		html += '	<input type="hidden" id="form_table" value="' + table + '">';
		html += '	<input type="hidden" id="form_fields" value="' + fields + '">';
		html += '	<input type="hidden" id="form_fields_len" value="' + fields_len + '">';
		html += '	<input type="hidden" id="form_fields_desc" value="' + fields_desc + '">';
		html += '	<input type="hidden" id="form_where" value="' + where + '">';
		html += '	<input type="hidden" id="form_order_by" value="' + order_by + '">';

		if ((!order_by) || (order_by.trim() == '')) {
			html += '	<input type="hidden" id="form_col_order_by" value="name">';
			html += '	<input type="hidden" id="form_col_order_order" value="asc">';
		}else{
			let s = '';

			if (order_by.indexOf(',') != -1) {
				// Take the first field, not planned to work with more fields
				s = order_by.substring(0, order_by.indexOf(','));
				s = s.trim();
				html += '	<input type="hidden" id="form_col_order_by" value="' + s + '">';
				html += '	<input type="hidden" id="form_col_order_order" value="asc">';

			}else if (order_by.indexOf(' ') != -1) {
				s = order_by.substring(0, order_by.indexOf(' '));
				s = s.trim();
				html += '	<input type="hidden" id="form_col_order_by" value="' + s + '">';

				s = order_by.substring(order_by.indexOf(' ')+1, order_by.length);

				if (s == null) {
					s = '';
				}
				s = s.trim();
				if (s != '') {
					if ((s.toUpperCase() == 'ASC') || (s.toUpperCase() == 'DESC')) {
						html += '	<input type="hidden" id="form_col_order_order" value="' + s + '">';
					}else{
						html += '	<input type="hidden" id="form_col_order_order" value="asc">';
					}
				}else{
					html += '	<input type="hidden" id="form_col_order_order" value="asc">';
				}
			}else {
				html += '	<input type="hidden" id="form_col_order_by" value="' + order_by + '">';
				html += '	<input type="hidden" id="form_col_order_order" value="asc">';
			}
		}


		html += '	<div style="overflow-x: auto; height:75px; width:100%;">';

		// Search
		html += '		<div style="position: relative; top: 0px; left: 0px; width:300px;">';
		html += '			<label id="ioi_form_search_label" style="position: absolute; top: 0px; left: 2px;">' + __("Search") + '</label>';
		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 300px; height: 25px;"> ';
		html += '				<input id="ioi_form_search" type="text" class="input-with-feedback form-control bold">';
		html += '			</div>';
		html += '		</div>';

		// Topn
		html += '		<div style="position: relative; top: 0px; left: 310px; width:170px;">';
		html += '			<label id="ioi_form_topn_label" style="position: absolute; top: 0px; left: 2px;">' + __("No records") + '</label>';
		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 170px; height: 30px;"> ';
		html += '				<input id="ioi_form_topn" type="number" class="input-with-feedback form-control bold" min="1" max="1000" value="100">';
		html += '			</div>';
		html += '		</div>';

		html += '	</div>';

		html += '	<div style="overflow-x: auto; height:10px; width:100%;"></div>';

		html += '	<div id="ioi_form_search_content" style="overflow-x: auto; height:500px; width:100%;">';
		html += '	</div>';

		let tot_len = 0;
		let col_count = 0;

		let col_field = [];
		let col_desc = [];
		let col_len = [];

		if ((fields) && (fields.trim() != ''))
		{
			// field names
			let s = fields;

			while (s.indexOf(',') != -1)
			{
				let fn = s.substring(0, s.indexOf(','));
				fn = fn.trim();

				if (fn != '')
				{	col_field[col_count] = fn;
					col_count++;
				}

				s = s.substring(s.indexOf(',')+1, s.length);
			}

			if (s.trim() != '')
			{
				s = s.trim();
				col_field[col_count] = s;
			}

			col_count = 0;

			// field descriptions
			s = fields_desc;

			while (s.indexOf(',') != -1)
			{
				let fn = s.substring(0, s.indexOf(','));
				fn = fn.trim();

				if (fn != '')
				{	col_desc[col_count] = fn;
					col_count++;
				}

				s = s.substring(s.indexOf(',')+1, s.length);
			}

			if (s.trim() != '')
			{
				s = s.trim();
				col_desc[col_count] = s;
			}

			col_count = 0;

			// field lengths
			if ((fields_len) && (fields_len.trim() != ''))
			{
				s = fields_len;

				while (s.indexOf(',') != -1)
				{
					let n = s.substring(0, s.indexOf(','));
					n = n.trim();

					if (n != '')
					{	col_len[col_count] = parseInt(n);
						col_count++;
						tot_len += parseInt(n);
					}

					s = s.substring(s.indexOf(',')+1, s.length);
				}

				if (s.trim() != '')
				{
					s = s.trim();
					tot_len += parseInt(s);
				}
			}
		}

		let form_width = form_width_pixel;


		if (tot_len == 0)
		{
			col_field[0] = 'name';


			col_desc[0] = __('Identification');


			col_len[0] = 200;

			tot_len = 530;
			form_width = '300px';
		}else
		{ 	tot_len += 30;
		}

		if (window.innerWidth <= 500) {
			form_width = '100%';
		}

		z.fields_dict.html_select_form.$wrapper.html(html);
		z.$wrapper.find('.modal-dialog').css("max-width", form_width).css("width", form_width);
		z.$wrapper.find('.modal-dialog').css("max-height", "50%").css("height", "50%");
		z.show();

		silicon_ioi.ioiCommon.select_sleep(250).then(() => {

			let fct_keyup = function(event)
			{
				if (event.keyCode == 13)
				{
					silicon_ioi.ioiCommon.form_search(z);
				}
			}


			document.getElementById('ioi_form_search').onkeyup = fct_keyup;
			document.getElementById('ioi_form_topn').onkeyup = fct_keyup;
			silicon_ioi.ioiCommon.form_search(z);

		});
	}

	// ***************************************************************************************************************************************
	// Form Search : Search Term Key Up
	// ***************************************************************************************************************************************
	static form_keyup(event)
	{
		if (event.keyCode == 13)
		{
			silicon_ioi.ioiCommon.form_search();
		}
	}

	// ***************************************************************************************************************************************
	// Form Search : Search
	// ***************************************************************************************************************************************
	static form_search(z)
	{
		document.getElementById('ioi_form_search_content').innerHTML = '';

		if (document.getElementById('form_nb_record'))
		{
			for (var i = 0; i < document.getElementById('form_nb_record').value; i++)
			{
				if (document.getElementById('form_checked_id_' + i.toString()))
				{	document.getElementById('form_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('form_id_' + i.toString()))
				{	document.getElementById('form_id_' + i.toString()).remove();
				}
			}

			document.getElementById('form_nb_record').remove();
		}

		let tot_len = 0;
		let col_count = 0;

		let col_field = [];
		let col_desc = [];
		let col_len = [];

		if ((document.getElementById('form_fields').value) && (document.getElementById('form_fields').value.trim() != ''))
		{
			// field names
			let s = document.getElementById('form_fields').value;

			while (s.indexOf(',') != -1)
			{
				let fn = s.substring(0, s.indexOf(','));
				fn = fn.trim();

				if (fn != '')
				{	col_field[col_count] = fn;
					col_count++;
				}

				s = s.substring(s.indexOf(',')+1, s.length);
			}

			if (s.trim() != '')
			{
				s = s.trim();
				col_field[col_count] = s;
			}

			col_count = 0;

			// field descriptions
			s = document.getElementById('form_fields_desc').value;

			while (s.indexOf(',') != -1)
			{
				let fn = s.substring(0, s.indexOf(','));
				fn = fn.trim();

				if (fn != '')
				{	col_desc[col_count] = fn;
					col_count++;
				}

				s = s.substring(s.indexOf(',')+1, s.length);
			}

			if (s.trim() != '')
			{
				s = s.trim();
				col_desc[col_count] = s;
			}

			col_count = 0;

			// field lengths
			if ((document.getElementById('form_fields_len').value) && (document.getElementById('form_fields_len').value.trim() != ''))
			{
				s = document.getElementById('form_fields_len').value;

				while (s.indexOf(',') != -1)
				{
					let n = s.substring(0, s.indexOf(','));
					n = n.trim();

					if (n != '')
					{	col_len[col_count] = parseInt(n);
						col_count++;
						tot_len += parseInt(n);
					}

					s = s.substring(s.indexOf(',')+1, s.length);
				}

				if (s.trim() != '')
				{
					s = s.trim();
					tot_len += parseInt(s);
				}
			}
		}

		if (tot_len == 0)
		{
			col_field[0] = 'name';

			col_desc[0] = __('Identification');

			col_len[0] = 200;

			tot_len = 200;
		}else
		{ 	tot_len += 30;
		}


		for (var i=0; i < col_field.length; i++)
		{
			if (document.getElementById('form_search_col_label_' + col_field[i]))
			{	document.getElementById('form_search_col_label_' + col_field[i]).remove();
			}

			if (document.getElementById('form_search_col_' + col_field[i]))
			{	document.getElementById('form_search_col_' + col_field[i]).remove();
			}

		}

		let html = '';

		let is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

		html += '<table border=1 style="border: 1px solid #E8EAEB" width=' + tot_len + 'px data-custom-grid="true">';

		html += '<tr style="height:30px">';

		html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';

		for (var i = 0; i < col_field.length; i++)
		{
			let sens = ''

			if (document.getElementById('form_col_order_by').value.toUpperCase() == col_field[i].toUpperCase())
			{
				if (document.getElementById('form_col_order_order').value.toUpperCase() == 'ASC')
				{	sens = '&darr;'
				}else
				{	sens = '&uarr;'
				}
			}

			html += '<td id="form_search_col_' + col_field[i] + '" width=' + col_len[i] + 'px style="vertical-align: middle;">&nbsp;<b>' + col_desc[i] + '<label id="form_search_col_label_' + col_field[i] + '" style="width:30px; height:8px" align="right">' + sens + '</label></b></td>';

		}

		html += '</tr>';
		html += '</table>';

		if (document.getElementById('window_select_form_detail')) {
			document.getElementById('window_select_form_detail').remove();
		}


		let method = path_common + '.ioi_common_search_form';

		frappe.call({  	method: method,
						args: {	"table": document.getElementById('form_table').value,
								"fields": document.getElementById('form_fields').value,
								"conditions": document.getElementById('form_where').value,
								"search" : document.getElementById('ioi_form_search').value,
								"orderby": document.getElementById('form_col_order_by').value,
								"orderdir": document.getElementById('form_col_order_order').value,
								"topn": document.getElementById('ioi_form_topn').value},
						async: false,
						callback:function(r)	{
													if (document.getElementById('form_nb_record')) {

														for (var i = 0; i < document.getElementById('form_nb_record').value; i++) {

															if (document.getElementById('form_checked_id_' + i.toString())) {
																document.getElementById('form_checked_id_' + i.toString()).remove();
															}

															if (document.getElementById('form_id_' + i.toString())) {
																document.getElementById('form_id_' + i.toString()).remove();
															}

														}

														document.getElementById('form_nb_record').remove();
													}

													if (r.message.length > 0)
													{
														html += '<table id="window_select_form_detail" border=1 style="border: 1px solid #E8EAEB" width=' + tot_len + 'px">';

														let cpt = 0;


														for (var i = 0; i < r.message.length; i++)
														{
															html += '<tr id="window_select_form_row_' + i.toString() + '" style="height:30px">';

															html += '<td width=30px align="center" style="vertical-align: middle;">';
															html += '<input type="checkbox" id="form_checked_id_' + cpt.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
															html += '       onclick=" ';
															html += '					for (var i = 0; i < document.getElementById(\'form_nb_record\').value; i++) ';
															html += '					{   if (document.getElementById(\'form_checked_id_\' + i.toString())) ';
															html += '						{ '
															html += '							if (document.getElementById(\'form_checked_id_\' + i.toString()).id != this.id) ';
															html += '							{ ';
															html += '								document.getElementById(\'form_checked_id_\' + i.toString()).checked = false; ';
															html += '							} ';
															html += '						} ';
															html += '					} ';
															html += '" ';

															html += '>';
															html += '<input type="hidden" id="form_id_' + cpt.toString() + '" value="' +  r.message[i][0] + '">';
															html += '</td>';

															for (var k = 0; k < col_field.length; k++)
															{
																if (r.message[i][k] != null)
																{
																	let search_term = document.getElementById('ioi_form_search').value;
																	search_term = search_term.trim();

																	if (search_term != '') {

																		search_term = search_term.toUpperCase();

																		let data = r.message[i][k].toString();

																		if (data != '') {
																			data = data.trim().toUpperCase();

																			if (data.indexOf(search_term) != -1) {
																				let from_idx = data.indexOf(search_term);
																				let len_search = search_term.length;

																				let begin_data = r.message[i][k].toString().substring(0, from_idx);
																				let intermediate_data = r.message[i][k].toString().substring(from_idx,  from_idx + len_search);
																				let end_data = r.message[i][k].toString().substring(from_idx+len_search, data.length);

																				let formatted_data = begin_data;

																				if (is_dark_mode == 0) {
																					formatted_data += '<font style="background-color:#D0E7FB"><b>' + intermediate_data + '</b></font>' + end_data;
																				}else{
																					formatted_data += '<font style="background-color:gray"><b>' + intermediate_data + '</b></font>' + end_data;
																				}

																				html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;' + formatted_data + '</td>'

																			}else {
																				html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;' + r.message[i][k] + '</td>'
																			}
																		}else {
																			html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;</td>'
																		}

																	}else{
																		html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;' + r.message[i][k] + '</td>'
																	}
																}else
																{	html += '<td width=' + col_len[k] + 'px style="vertical-align: middle;">&nbsp;</td>'
																}
															}

															html += '</tr>';

															cpt++;
														}

														html += '<input type="hidden" id="form_nb_record" value="' + cpt.toString() + '">';
														html += '</table>';

													}else
													{
														html += '<input type="hidden" id="form_nb_record" value="0">';
													}

												}
		});
		html += '</div>';
		document.getElementById('ioi_form_search_content').innerHTML = html;

		silicon_ioi.ioiCommon.select_sleep(250).then(() => {

			let is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

			if (document.getElementById('window_select_form_detail')) {

				let fct_row_dbclick = function() {
					this.click();
					z.primary_action();
				}

				let fct_row_click = function() {
					let s = this.id;

					while (s.indexOf('_') != -1) {
						s = s.substring(s.indexOf('_')+1, s.length)
					}

					s = s.trim();

					for (var i = 0; i < document.getElementById('window_select_form_detail').rows.length; i++) {

						if (is_dark_mode == 0) {
							document.getElementById('window_select_form_detail').rows[i].style.backgroundColor = '#FFFFFF';
						}else{
							document.getElementById('window_select_form_detail').rows[i].style.backgroundColor = '#1C2126';
						}


						if (document.getElementById('form_checked_id_' + i.toString())) {
							document.getElementById('form_checked_id_' + i.toString()).checked = false;
						}

					}

					document.getElementById('form_checked_id_' + s).checked = true;

					if (is_dark_mode == 0) {
						document.getElementById('window_select_form_detail').rows[parseInt(s)].style.backgroundColor = '#B1FCD9';
					}else{
						document.getElementById('window_select_form_detail').rows[parseInt(s)].style.backgroundColor = 'green';

					}

				}


				for (var i = 0; i < document.getElementById('window_select_form_detail').rows.length; i++) {

					if (document.getElementById('window_select_form_row_' + i.toString())) {
						document.getElementById('window_select_form_row_' + i.toString()).onclick = fct_row_click;
						document.getElementById('window_select_form_row_' + i.toString()).ondblclick = fct_row_dbclick;
					}
				}
			}


			let fct_header_col_click = function() {
				silicon_ioi.ioiCommon.form_col_click(this, z);
			}

			for (var i=0; i < col_field.length; i++)
			{
				if (document.getElementById('form_search_col_' + col_field[i]))
				{	document.getElementById('form_search_col_' + col_field[i]).onclick = fct_header_col_click;
					document.getElementById('form_search_col_' + col_field[i]).onmouseover = silicon_ioi.ioiCommon.col_mouse_over;
					document.getElementById('form_search_col_' + col_field[i]).onmouseleave = silicon_ioi.ioiCommon.col_mouse_leave;
				}

			}

			document.getElementById('ioi_form_search').focus();
		});


	}

	// ***************************************************************************************************************************************
	// Form Search : Column click
	// ***************************************************************************************************************************************
	static form_col_click(obj, z)
	{
		let s = obj.id;

		s = s.substring(16, s.length);

		if (document.getElementById('form_col_order_by').value.toUpperCase() == s.toUpperCase())
		{
			if (document.getElementById('form_col_order_order').value == 'desc')
			{	document.getElementById('form_col_order_order').value = 'asc';
			}else
			{	document.getElementById('form_col_order_order').value = 'desc';
			}
		}else
		{	document.getElementById('form_col_order_by').value = s;
			document.getElementById('form_col_order_order').value = 'desc';
		}

		silicon_ioi.ioiCommon.form_search(z);
	}



	// ***************************************************************************************************************************************
	// Location Search : Select location
	// ***************************************************************************************************************************************
	static select_location(tp = 'IN', warehouse_id = null, warehouse_location_id = null, dossier_id = null, dossier_focus = 0, item_id = null, batch_sn_id = null, stored_qty_mode = 0, callback)
	{
		if ((!tp) || ((tp) && (tp.toUpperCase() != 'IN') && (tp.toUpperCase() != 'OUT'))) {
			frappe.msgprint({title: __("Message"), message: __('tp parameter has to be IN or OUT'), indicator: "red"});
			return false;
		}

		if (!warehouse_id) {
			frappe.msgprint({title: __("Message"), message: __('Warehouse is mandatory'), indicator: "red"});
			return false;
		}

		let title = '';

		let inventory_doctype = ''

		if (cur_frm.doctype == 'ioi Stock Inventory') {
			title = __("Select a location in ") + ' ' + warehouse_id;
			inventory_doctype = cur_frm.doctype;
		}else{
			title = __("Select a location in ") + ' ' + warehouse_id + ' (' + __("flow") + ' ' + tp + ')';
		}

		var z = new frappe.ui.Dialog({
			'title': title,
			'fields': [
				{'fieldname': 'html_select_location', 'fieldtype': 'HTML'}

			],
			static: true,
			primary_action_label: 'Ok',
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

				let go = true;

				if (!document.getElementById('table_locations')) {
					go = false;
				}

				if ((go) && (document.getElementById('table_locations').rows.length == 0)) {
					go = false;
				}

				let selected_warehouse_location_id = '';
				let selected_batch_sn_id = null;
				let selected_batch_sn_code_ref = null;
				let selected_batch_sn_origin = null;
				let selected_batch_sn_cost = null;
				let selected_dossier_id = null;
				let selected_batch_locked = 0;

				if (go) {

					go = false;

					for (var i = 0; i < document.getElementById('table_locations').rows.length; i++)	{

						if (document.getElementById('location_checked_id_' + i.toString()))	{

							if (document.getElementById('location_checked_id_' + i.toString()).checked) {
								go = true;
								selected_warehouse_location_id = document.getElementById('location_name_id_' + i.toString()).value;
								selected_dossier_id = document.getElementById('location_dossier_id_' + i.toString()).value;

								if (cur_frm.doctype == 'ioi Stock Inventory') {

									selected_batch_sn_id = document.getElementById('location_batch_sn_id_' + i.toString()).value;
									selected_batch_sn_code_ref = document.getElementById('location_batch_code_ref_' + i.toString()).value;
									selected_batch_sn_origin = document.getElementById('location_batch_origin_' + i.toString()).value;
									selected_batch_sn_cost = document.getElementById('location_batch_cost_' + i.toString()).value;
									selected_batch_locked = document.getElementById('location_batch_locked_' + i.toString()).value;

								}else{

									if (document.getElementById('location_get_batchsn_cb')) {
										if (document.getElementById('location_get_batchsn_cb').checked) {

											selected_batch_sn_id = document.getElementById('location_batch_sn_id_' + i.toString()).value;
											selected_batch_sn_code_ref = document.getElementById('location_batch_code_ref_' + i.toString()).value;
											selected_batch_sn_origin = document.getElementById('location_batch_origin_' + i.toString()).value;
											selected_batch_sn_cost = document.getElementById('location_batch_cost_' + i.toString()).value;
											selected_batch_locked = document.getElementById('location_batch_locked_' + i.toString()).value;
										}
									}
								}
								break;
							}
						}
					}

				}

				if (go) {
					z.hide();

					if (callback) {

						callback(selected_warehouse_location_id, selected_batch_sn_id, selected_batch_sn_code_ref, selected_batch_sn_origin, selected_batch_sn_cost, selected_dossier_id, selected_batch_locked);
					}

				}else{
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
				}
			},
			secondary_action: function(){
				z.hide();
			}

		});


		if (document.getElementById('location_parameter_section'))	{
			document.getElementById('location_parameter_section').remove();
		}

		if (document.getElementById('location_dossier_cb'))	{
			document.getElementById('location_dossier_cb').remove();
		}

		if (document.getElementById('location_dossier_cb_label'))	{
			document.getElementById('location_dossier_cb_label').remove();
		}

		if (document.getElementById('location_item_cb'))	{
			document.getElementById('location_item_cb').remove();
		}

		if (document.getElementById('location_item_cb_label'))	{
			document.getElementById('location_item_cb_label').remove();
		}

		if (document.getElementById('location_batchsn_cb'))	{
			document.getElementById('location_batchsn_cb').remove();
		}

		if (document.getElementById('location_batchsn_cb_label'))	{
			document.getElementById('location_batchsn_cb_label').remove();
		}

		if (document.getElementById('location_get_batchsn_cb'))	{
			document.getElementById('location_get_batchsn_cb').remove();
		}

		if (document.getElementById('location_get_batchsn_cb_label'))	{
			document.getElementById('location_get_batchsn_cb_label').remove();
		}

		if (document.getElementById('location_search'))	{
			document.getElementById('location_search').remove();
		}

		if (document.getElementById('location_topn'))	{
			document.getElementById('location_topn').remove();
		}

		if (document.getElementById('table_locations_header_div'))	{
			document.getElementById('table_locations_header_div').remove();
		}

		if (document.getElementById('table_locations_header'))	{
			document.getElementById('table_locations_header').remove();
		}

		if (document.getElementById('table_locations_detail_div'))	{
			document.getElementById('table_locations_detail_div').remove();
		}


		if (document.getElementById('table_locations')) {

			for (var i = 0; i < document.getElementById('table_locations').length; i++)	{
				if (document.getElementById('location_checked_id_' + i.toString()))	{
					document.getElementById('location_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('location_name_id_' + i.toString())) {
					document.getElementById('location_name_id_' + i.toString()).remove();
				}

				if (document.getElementById('location_dossier_id_' + i.toString())) {
					document.getElementById('location_dossier_id_' + i.toString()).remove();
				}


				if (document.getElementById('location_batch_sn_id_' + i.toString())) {
					document.getElementById('location_batch_sn_id_' + i.toString()).remove();
				}

				if (document.getElementById('location_batch_code_ref_' + i.toString())) {
					document.getElementById('location_batch_code_ref_' + i.toString()).remove();
				}

				if (document.getElementById('location_batch_origin_' + i.toString())) {
					document.getElementById('location_batch_origin_' + i.toString()).remove();
				}

				if (document.getElementById('location_batch_cost_' + i.toString())) {
					document.getElementById('location_batch_cost_' + i.toString()).remove();
				}

			}

			document.getElementById('table_locations').remove();
		}




		let html = '';

		html += '<div id="location_parameter_section" style="overflow: auto; overflow-x: auto; height:300px; width: 100%;">';

		let top = 0;


		// Item
		if ((item_id) && (item_id.trim() != '')) {

			html += '	<div style="position: relative; top: ' + top.toString() + 'px; left: 0px; width:600px;">';

			html += '		<div class="control-input" style="position: absolute; top: 2px; left:2px; width: 598px; height: 25px;"> ';
			if (tp == 'OUT') {
				html += '			<input id="location_item_cb" type="checkbox" class="input-with-feedback form-control bold" checked disabled>';
			}else{
				html += '			<input id="location_item_cb" type="checkbox" class="input-with-feedback form-control bold">';
			}
			html += '		</div>';

			html += '		<label id="location_item_cb_label" style="position: absolute; top: 0px; left: 24px;">' + __("For this item") + ' ' + item_id +  '</label>';

			html += '	</div>';

			top += 25;
		}


		// Batch/SN
		if ((batch_sn_id) && (batch_sn_id.trim() != '')) {

			html += '	<div style="position: relative; top: ' + top.toString() + 'px; left: 0px; width:600px;">';

			html += '		<div class="control-input" style="position: absolute; top: 2px; left:2px; width: 598px; height: 25px;"> ';
			if (tp == 'OUT') {
				html += '			<input id="location_batchsn_cb" type="checkbox" class="input-with-feedback form-control bold" checked disabled>';
			}else{
				html += '			<input id="location_batchsn_cb" type="checkbox" class="input-with-feedback form-control bold">';
			}
			html += '		</div>';

			html += '		<label id="location_batchsn_cb_label" style="position: absolute; top: 0px; left: 24px;">' + __("For this batch/sn") + ' ' + batch_sn_id +  '</label>';

			html += '	</div>';

			top += 25;
		}

		// Get Batchsn
		if ((item_id) && (item_id.trim() != '')) {

			if ((!batch_sn_id) || ((batch_sn_id) && (batch_sn_id.trim() == ''))) {

				let method = path_item + '.ioi_item_get_data';

				let item_mode = 0

				frappe.call({  	method: method,
								args: {	"item_id" : item_id
								},
								async: false,
								callback:function(r)	{
									item_mode = r.message.mode;
								}
				});

				if ((item_mode == 3) || (item_mode == 4)) {

					html += '	<div style="position: relative; top: ' + top.toString() + 'px; left: 0px; width:600px;">';

					html += '		<div class="control-input" style="position: absolute; top: 2px; left:2px; width: 598px; height: 25px;"> ';
					if (tp == 'OUT') {
						html += '			<input id="location_get_batchsn_cb" type="checkbox" class="input-with-feedback form-control bold" checked disabled>';
					}else{
						html += '			<input id="location_get_batchsn_cb" type="checkbox" class="input-with-feedback form-control bold">';
					}
					html += '		</div>';

					html += '		<label id="location_get_batchsn_cb_label" style="position: absolute; top: 0px; left: 24px;">' + __("Get batch/SN") +  '</label>';

					html += '	</div>';

					top += 25;

				}

			}

		}


		// Search
		html += '	<div style="position: relative; top: ' + top.toString() + 'px; left: 0px; width:600px;">';
		html += '		<label style="position: absolute; top: 0px; left: 2px;">' + __("Search") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 598px; height: 25px;"> ';
		html += '			<input type="text" id="location_search" class="input-with-feedback form-control bold" value="">';
		html += '		</div>';
		html += '	</div>';


		// Top n
		html += '	<div style="position: relative; top: ' + top.toString() + 'px; left: 610px; width:180px;">';
		html += '		<label style="position: absolute; top: 0px; left: 2px;">' + __("No record(s)") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 100px; height: 25px;"> ';
		html += '			<input type="number" id="location_topn" step="any" class="input-with-feedback form-control bold" value="100">';
		html += '		</div>';
		html += '	</div>';

		top += 75;

		html += '</div>'

		html += '<div id="table_locations_header_div" style="overflow: hidden; overflow-x: auto; height:31px; width: 100%;">';
		html += '</div>';

		html += '<div id="table_locations_detail_div" style="overflow: hidden; overflow-x: auto; height:600px; width: 100%;">';
		html += '</div>';


		z.fields_dict.html_select_location.$wrapper.html(html);
		z.$wrapper.find('.modal-dialog').css("max-width", '2080px').css("width", '2000px');

		z.show();

		silicon_ioi.ioiCommon.sleep_static(200).then(() => {
			if ((batch_sn_id) && (batch_sn_id.trim() != '')) {
				document.getElementById('location_get_batchsn_cb').disabled = true;
			}

			document.getElementById('location_parameter_section').style.height = top.toString() + 'px';

			if (document.getElementById('location_dossier_cb')) {

				let fct_click = function() {
					silicon_ioi.ioiCommon.location_refresh_grid_header();
					silicon_ioi.ioiCommon.location_refresh_grid_detail(z, tp, warehouse_id, warehouse_location_id, dossier_id, dossier_focus, item_id, batch_sn_id, stored_qty_mode, inventory_doctype);
				}

				document.getElementById('location_dossier_cb').onclick = fct_click;
			}


			if (document.getElementById('location_item_cb')) {

				let fct_click = function() {
					if ((batch_sn_id) && (batch_sn_id.trim() != '')) {

						if (tp == 'OUT') {
							document.getElementById('location_get_batchsn_cb').disabled = true;
							
						}else{

							if (this.checked) {
								document.getElementById('location_get_batchsn_cb').disabled = false;
							}else{
								document.getElementById('location_get_batchsn_cb').disabled = true;
							}
						}
					}
		
					silicon_ioi.ioiCommon.location_refresh_grid_header();
					silicon_ioi.ioiCommon.location_refresh_grid_detail(z, tp, warehouse_id, warehouse_location_id, dossier_id, dossier_focus, item_id, batch_sn_id, stored_qty_mode, inventory_doctype);
				}

				document.getElementById('location_item_cb').onclick = fct_click;
			}

			if (document.getElementById('location_batchsn_cb')) {

				let fct_click = function() {
					silicon_ioi.ioiCommon.location_refresh_grid_header();
					silicon_ioi.ioiCommon.location_refresh_grid_detail(z, tp, warehouse_id, warehouse_location_id, dossier_id, dossier_focus, item_id, batch_sn_id, stored_qty_mode, inventory_doctype);
				}

				document.getElementById('location_batchsn_cb').onclick = fct_click;
			}

			let fct_key_up = function(event) {

				if (event.keyCode == 13) {
					silicon_ioi.ioiCommon.location_refresh_grid_header();
					silicon_ioi.ioiCommon.location_refresh_grid_detail(z, tp, warehouse_id, warehouse_location_id, dossier_id, dossier_focus, item_id, batch_sn_id, stored_qty_mode, inventory_doctype);
					return false;
				}
			}

			document.getElementById('location_search').onkeyup = fct_key_up;


			let fct_change = function() {

				silicon_ioi.ioiCommon.location_refresh_grid_header();
				silicon_ioi.ioiCommon.location_refresh_grid_detail(z, tp, warehouse_id, warehouse_location_id, dossier_id, dossier_focus, item_id, batch_sn_id, stored_qty_mode, inventory_doctype);
			}

			document.getElementById('location_topn').onkeyup = fct_key_up;
			document.getElementById('location_topn').onchange = fct_change;


			silicon_ioi.ioiCommon.location_refresh_grid_header();
			silicon_ioi.ioiCommon.location_refresh_grid_detail(z, tp, warehouse_id, warehouse_location_id, dossier_id, dossier_focus, item_id, batch_sn_id, stored_qty_mode, inventory_doctype);



		});

	}

	static location_refresh_grid_header()
	{
		let standard_grid = 1

		if (document.getElementById('location_item_cb')) {
			if (document.getElementById('location_item_cb').checked) {
				standard_grid = 0;
			}
		}

		if (document.getElementById('location_batchsn_cb')) {

			if (document.getElementById('location_batchsn_cb').checked) {
				standard_grid = 0;
			}
		}

		silicon_ioi.ioiCommon.location_build_grid_header(standard_grid);

	}

	static location_build_grid_header(standard_grid)
	{
		let html = '';

		if (document.getElementById('table_locations_header')) {
			document.getElementById('table_locations_header').remove();
		}

		if (standard_grid == 1) {

			html += '	<table id="table_locations_header" border=1 style="border: 1px solid #E8EAEB" width=850px data-custom-grid="true">';
			html += '	<tr style="height:30px">';
			html += '	<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
			html += '	<td width=270px style="vertical-align: middle;">&nbsp;<b>' + __("Location") + '</b></td>';
			html += '	<td width=300px style="vertical-align: middle;">&nbsp;<b>' + __("Description") + '</b></td>';
			html += '	<td width=250px style="vertical-align: middle;">&nbsp;<b>' + __("Dedicated dossier") + '</b></td>';
			html += '	</tr>';
			html += '	</table>';

			document.getElementsByClassName('modal-dialog')[document.getElementsByClassName('modal-dialog').length-1].style.width = '900px';

		}else{
			html += '	<table id="table_locations_header" border=1 style="border: 1px solid #E8EAEB" width=1380px data-custom-grid="true">';
			html += '	<tr style="height:30px">';
			html += '	<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
			html += '	<td width=270px style="vertical-align: middle;">&nbsp;<b>' + __("Location") + '</b></td>';
			html += '	<td width=300px style="vertical-align: middle;">&nbsp;<b>' + __("Description") + '</b></td>';
			html += '	<td width=120px style="vertical-align: middle;">&nbsp;<b>' + __("Stock qty") + '</b></td>';
			html += '	<td width=80px style="vertical-align: middle;">&nbsp;<b>' + __("Unit") + '</b></td>';
			html += '	<td width=250px style="vertical-align: middle;">&nbsp;<b>' + __("Batch/SN") + '</b></td>';
			html += '	<td width=80px style="vertical-align: middle;">&nbsp;<b>' + __("Locked") + '</b></td>';
			html += '	<td width=250px style="vertical-align: middle;">&nbsp;<b>' + __("Dedicated dossier") + '</b></td>';
			html += '	</tr>';
			html += '	</table>';

			document.getElementsByClassName('modal-dialog')[document.getElementsByClassName('modal-dialog').length-1].style.width = '1430px';
		}

		document.getElementById('table_locations_header_div').innerHTML = html;
	}

	static location_refresh_grid_detail(z, tp, warehouse_id, warehouse_location_id = '', dossier_id = '', dossier_focus = 0, item_id = '', batch_sn_id = '', stored_qty_mode = 0, inventory_doctype = '')
	{
		let standard_grid = 1

		if (document.getElementById('location_item_cb')) {
			if (document.getElementById('location_item_cb').checked) {
				standard_grid = 0;
			}
		}

		if (document.getElementById('location_batchsn_cb')) {

			if (document.getElementById('location_batchsn_cb').checked) {
				standard_grid = 0;
			}
		}


		if (document.getElementById('table_locations')) {

			for (var i = 0; i < document.getElementById('table_locations').length; i++)	{

				if (document.getElementById('location_checked_id_' + i.toString()))	{
					document.getElementById('location_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('location_name_id_' + i.toString())) {
					document.getElementById('location_name_id_' + i.toString()).remove();
				}

				if (document.getElementById('location_dossier_id_' + i.toString())) {
					document.getElementById('location_dossier_id_' + i.toString()).remove();
				}

				if (document.getElementById('location_batch_sn_id_' + i.toString())) {
					document.getElementById('location_batch_sn_id_' + i.toString()).remove();
				}

				if (document.getElementById('location_batch_code_ref_' + i.toString())) {
					document.getElementById('location_batch_code_ref_' + i.toString()).remove();
				}

				if (document.getElementById('location_batch_origin_' + i.toString())) {
					document.getElementById('location_batch_origin_' + i.toString()).remove();
				}

				if (document.getElementById('location_batch_cost_' + i.toString())) {
					document.getElementById('location_batch_cost_' + i.toString()).remove();
				}

				if (document.getElementById('location_batch_locked_' + i.toString())) {
					document.getElementById('location_batch_locked_' + i.toString()).remove();
				}

			}

			document.getElementById('table_locations').remove();
		}


		let param_dossier_id = '';
		let param_dossier_focus = 0;

		if (document.getElementById('location_dossier_cb')) {

			if (document.getElementById('location_dossier_cb').checked) {
				param_dossier_id = dossier_id;
				param_dossier_focus = dossier_focus;
			}
		}

		let param_item_id = '';

		if (document.getElementById('location_item_cb')) {

			if (document.getElementById('location_item_cb').checked) {
				param_item_id = item_id;
			}
		}

		let param_batch_sn_id = '';

		if (document.getElementById('location_batchsn_cb')) {

			if (document.getElementById('location_batchsn_cb').checked) {
				param_batch_sn_id = batch_sn_id;
			}
		}

		if ((!document.getElementById('location_topn').value) || ((document.getElementById('location_topn').value) && (document.getElementById('location_topn').value == ''))) {
			document.getElementById('location_topn').value = 0;
		}

		if (parseInt(document.getElementById('location_topn').value) < 0) {
			document.getElementById('location_topn').value = 0;
		}


		let pathwarehouselocation = 'silicon_ioi.ioi_wms.doctype.ioi_warehouse_location.ioi_warehouse_location';
		let method = pathwarehouselocation + '.ioi_warehouse_location_search_form';

		let data = [];

		frappe.call({  	method: method,
						args: {	"tp": tp,
								"warehouse_id": warehouse_id,
								"dossier_id": param_dossier_id,
								"dossier_focus": param_dossier_focus,
								"item_id": param_item_id,
								"batch_sn_id": param_batch_sn_id,
								"stored_qty_mode": stored_qty_mode,
								"search": document.getElementById('location_search').value,
								"topn": document.getElementById('location_topn').value,
								"inventory_doctype": inventory_doctype
						},
						async: false,
						callback:function(r)	{
							data = r.message;
						}
		});

		let html = '';

		if (data.length == 0) {

			if (standard_grid == 1) {

				html += '	<table id="table_locations" border=1 style="border: 1px solid #E8EAEB" width=850px>';
				html += '	<tr style="height:30px">';
				html += '	<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
				html += '	<td width=270px style="vertical-align: middle;">&nbsp;</td>';
				html += '	<td width=300px style="vertical-align: middle;">&nbsp;</td>';
				html += '	<td width=250px style="vertical-align: middle;">&nbsp;</td>';
				html += '	</tr>';
				html += '	</table>';

			}else{
				html += '	<table id="table_locations" border=1 style="border: 1px solid #E8EAEB" width=1380px>';
				html += '	<tr style="height:30px">';
				html += '	<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
				html += '	<td width=270px style="vertical-align: middle;">&nbsp;</td>';
				html += '	<td width=300px style="vertical-align: middle;">&nbsp;</td>';
				html += '	<td width=120px style="vertical-align: middle;">&nbsp;</td>';
				html += '	<td width=80px style="vertical-align: middle;">&nbsp;</td>';
				html += '	<td width=250px style="vertical-align: middle;">&nbsp;</td>';
				html += '	<td width=80px style="vertical-align: middle;">&nbsp;</td>';
				html += '	<td width=250px style="vertical-align: middle;">&nbsp;</td>';
				html += '	</tr>';
				html += '	</table>';

			}
		}else{

			if (standard_grid == 1) {
				html += '	<table id="table_locations" border=1 style="border: 1px solid #E8EAEB" width=850px>';
			}else{
				html += '	<table id="table_locations" border=1 style="border: 1px solid #E8EAEB" width=1380px>';
			}

			let first = true;


			for (var i = 0; i < data.length; i++ ) {

				html += '<tr id="location_row_' + i.toString() + '" style="height:30px">';

				html += '<td width=30px align="center" style="vertical-align: middle;">';
				html += '<input type="checkbox" id="location_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
				html += '       onclick=" ';
				html += '					for (var i = 0; i < document.getElementById(\'table_locations\').rows.length; i++) ';
				html += '					{';
				html += '						if (document.getElementById(\'location_checked_id_\' + i.toString()).id != this.id) ';
				html += '						{ ';
				html += '							document.getElementById(\'location_checked_id_\' + i.toString()).checked = false; ';
				html += '						} ';
				html += '					} ';
				html += '" ';

				if (warehouse_location_id == data[i].name)
				{
					if (first) {
						html += ' checked ';
						first = false;
					}
				}
				html += '>';

				html += '<input type="hidden" id="location_name_id_' + i.toString() + '" value="' +  data[i].name + '">';
				html += '<input type="hidden" id="location_dossier_id_' + i.toString() + '" value="' +  data[i].dossier_id + '">';
				html += '<input type="hidden" id="location_batch_sn_id_' + i.toString() + '" value="' +  data[i].batch_sn_id + '">';
				html += '<input type="hidden" id="location_batch_code_ref_' + i.toString() + '" value="' +  data[i].code_ref + '">';
				html += '<input type="hidden" id="location_batch_origin_' + i.toString() + '" value="' +  data[i].origin + '">';
				html += '<input type="hidden" id="location_batch_cost_' + i.toString() + '" value="' +  data[i].cost + '">';
				html += '<input type="hidden" id="location_batch_locked_' + i.toString() + '" value="' +  data[i].batch_sn_locked + '">';

				html += '</td>';


				let v = data[i].name;
				let idx = v.indexOf('•');
				v = v.substring(idx+1, v.length);
				v = v.trim();


				html += '<td width=270px style="vertical-align: middle;">&nbsp;' + v + '</td>';

				if ((!data[i].lib) || ((data[i].lib) && (data[i].lib.trim() == ''))) {
					html += '	<td width=300px style="vertical-align: middle;">&nbsp;</td>';
				}else{
					html += '	<td width=300px style="vertical-align: middle;">&nbsp;' + data[i].lib + '</td>';
				}


				if (standard_grid == 1) {

					if ((!data[i].dossier_id) || ((data[i].dossier_id) && (data[i].dossier_id.trim() == ''))) {
						html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
					}else{
						html += '<td width=250px style="vertical-align: middle;">&nbsp;' + data[i].dossier_id + '</td>';
					}
				}else{
					html += '<td width=120px style="vertical-align: middle;" align="right">' + data[i].q_stock + '&nbsp;</td>';
					html += '<td width=80px style="vertical-align: middle;">&nbsp;' + data[i].unit_id + '</td>';
					html += '<td width=250px style="vertical-align: middle;">&nbsp;' + data[i].batch_sn_id + '</td>';


					if (data[i].batch_sn_locked == 1) {
						html += '<td width=80px style="vertical-align: middle;" align="center">V</td>';
					}else{
						html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
					}

					if ((!data[i].dossier_id) || ((data[i].dossier_id) && (data[i].dossier_id.trim() == ''))) {
						html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
					}else{
						html += '<td width=250px style="vertical-align: middle;">&nbsp;' + data[i].dossier_id + '</td>';
					}

				}

				html += '	</tr>';
			}

			html += '</table>';

		}

		document.getElementById('table_locations_detail_div').innerHTML = html;

		silicon_ioi.ioiCommon.sleep_static(350).then(() => {

			let is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

			if (document.getElementById('table_locations')) {

				let fct_row_dbclick = function() {
					this.click();
					z.primary_action();
				}

				let fct_row_click = function() {
					let s = this.id;

					while (s.indexOf('_') != -1) {
						s = s.substring(s.indexOf('_')+1, s.length)
					}

					s = s.trim();

					for (var i = 0; i < document.getElementById('table_locations').rows.length; i++) {

						if (is_dark_mode == 0) {
							document.getElementById('table_locations').rows[i].style.backgroundColor = '#FFFFFF';
						}else{
							document.getElementById('table_locations').rows[i].style.backgroundColor = '#1C2126';
						}


						if (document.getElementById('location_checked_id_' + i.toString())) {
							document.getElementById('location_checked_id_' + i.toString()).checked = false;
						}

					}

					document.getElementById('location_checked_id_' + s).checked = true;

					if (is_dark_mode == 0) {
						document.getElementById('table_locations').rows[parseInt(s)].style.backgroundColor = '#B1FCD9';
					}else{
						document.getElementById('table_locations').rows[parseInt(s)].style.backgroundColor = 'green';
					}
				}

				let already_checked = false;

				for (var i = 0; i < document.getElementById('table_locations').rows.length; i++) {

					if (document.getElementById('location_row_' + i.toString())) {
						document.getElementById('location_row_' + i.toString()).onclick = fct_row_click;
						document.getElementById('location_row_' + i.toString()).ondblclick = fct_row_dbclick;

						if (document.getElementById('location_checked_id_' + i.toString())) {

							if (document.getElementById('location_checked_id_' + i.toString()).checked) {

								if (is_dark_mode == 0) {
									document.getElementById('table_locations').rows[i].style.backgroundColor = '#B1FCD9';
								}else{
									document.getElementById('table_locations').rows[i].style.backgroundColor = 'green';
								}

								already_checked = true;
							}
						}

					}
				}

				if (!already_checked) {

					if (document.getElementById('location_checked_id_0')) {

						document.getElementById('location_checked_id_0').checked = true;

						if (is_dark_mode == 0) {
							document.getElementById('table_locations').rows[0].style.backgroundColor = '#B1FCD9';
						}else{
							document.getElementById('table_locations').rows[0].style.backgroundColor = 'green';
						}
					}
				}
			}
		});
	}

	static ioi_sales_document_get_whs_loc_with_stock(doctype, currency_id, currency_rate_inv, journal_id, item_id, delivered_qty, fct_callback = null)
	{

		if (!doctype) {
			return false;
		}

		if (!currency_id) {
			return false;
		}		

		if (!journal_id) {
			return false;
		}

		if (!item_id) {
			return false;
		}

		if (doctype.toUpperCase() != 'IOI SALES DELIVERY') {
			return false;
		}

		let qty = 1;

		if ((!delivered_qty)|| ((delivered_qty) && (delivered_qty == 0))) {
			qty = 1;
		}else{
			qty = parseFloat(delivered_qty)
		}

		let data = {};

		let method = 'silicon_ioi.common.sales_document.ioi_sales_document_get_whs_loc_with_stock';

		frappe.call({
			method: method,
			args: {"currency_id": currency_id, "currency_rate_inv": currency_rate_inv, "journal_id": journal_id, "item_id": item_id },
			async: false,
			callback: function (r) {
				data = r.message
			}
		});

		let show_window = false;

		if (data.nb_record == 0) {
			return false;
		}else if (data.nb_record == 1) {
			if (fct_callback) {
				fct_callback(data.data[0].warehouse_id, data.data[0].location_id, data.data[0].batch_sn_id, data.data[0].code_ref, data.data[0].voucher_value_tvac, data.data[0].voucher_value, data.data[0].voucher_vat_rate);
			}
		}else{
			show_window = true;
		}

		if (show_window) {

			var whsLocForm = new frappe.ui.Dialog({
				'title': __("Select the warehouse / location / batch SN"),
				'static' : true,
				'fields': [
					{'fieldname': 'html_select_whs_loc_batch', 'fieldtype': 'HTML'}
	
				],
				primary_action_label: 'Ok',
				secondary_action_label: __('Cancel'),
				primary_action: function(){

					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;


					let rows = silicon_ioi.ioiCommon.whslocTable.getRows();

					if (!rows) {
						frappe.msgprint({title: __("Message"), message: __("No row selected"), indicator: "red"});
						document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
						return false;
					}
	
					if (rows.length == 0) {
						frappe.msgprint({title: __("Message"), message: __("No row selected"), indicator: "red"});
						document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
						return false;
					}
	
					let one_selected = false;

					let warehouse_id = '';
					let location_id = '';
					let batch_sn_id = '';
					let code_ref = '';
					let voucher_value_tvac = 0;
					let voucher_vat_rate = 0;
					let voucher_value = 0;
					let voucher = 0

					let selected_row = silicon_ioi.ioiCommon.whslocTable.getSelectedRows();

					if (selected_row.length ==  0) {
						frappe.msgprint({title: __("Message"), message: __("No row selected"), indicator: "red"});
						document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
						return false;
					}

					warehouse_id = selected_row[0].getData()['warehouse_id'];
					location_id = selected_row[0].getData()['location_id'];
					batch_sn_id = selected_row[0].getData()['batch_sn_id'];
					code_ref = selected_row[0].getData()['code_ref'];
					voucher = selected_row[0].getData()['voucher'];

					if ((selected_row[0].getData()['voucher_value_tvac'] != null) && (parseFloat(selected_row[0].getData()['voucher_value_tvac']) != 0)) {
						voucher_value_tvac = parseFloat(selected_row[0].getData()['voucher_value_tvac']);
					}

					if ((selected_row[0].getData()['voucher_vat_rate'] != null) && (parseFloat(selected_row[0].getData()['voucher_vat_rate']) != 0)) {
						voucher_vat_rate = parseFloat(selected_row[0].getData()['voucher_vat_rate']);
					}

					if ((selected_row[0].getData()['voucher_value'] != null) && (parseFloat(selected_row[0].getData()['voucher_value']) != 0)) {
						voucher_value = parseFloat(selected_row[0].getData()['voucher_value']);		
					}
					
					let qty = 0;

					if ((document.getElementById('html_select_whs_loc_batch_delivered_qty').value) && (document.getElementById('html_select_whs_loc_batch_delivered_qty').value != 0)) {
						qty = parseFloat(document.getElementById('html_select_whs_loc_batch_delivered_qty').value)
					}
						 

					if (fct_callback) {
						fct_callback(warehouse_id, location_id, batch_sn_id, code_ref, voucher_value_tvac, voucher_value, voucher_vat_rate, qty, voucher);
					}

					whsLocForm.hide();

				},
				secondary_action: function(){
					whsLocForm.hide();
				}
	
			});

			silicon_ioi.ioiCommon.whslocTable = null;

			if (document.getElementById('sales_document_whs_loc_result_content')) {
				document.getElementById('sales_document_whs_loc_result_content').remove();
			}

			if (document.getElementById('html_select_whs_loc_batch_warehouse_label')) {
				document.getElementById('html_select_whs_loc_batch_warehouse_label').remove();
			}

			if (document.getElementById('html_select_whs_loc_batch_warehouse_id')) {
				document.getElementById('html_select_whs_loc_batch_warehouse_id').remove();
			}

			if (document.getElementById('html_select_whs_loc_batch_location_label')) {
				document.getElementById('html_select_whs_loc_batch_location_label').remove();
			}

			if (document.getElementById('html_select_whs_loc_batch_location_id')) {
				document.getElementById('html_select_whs_loc_batch_location_id').remove();
			}		

			if (document.getElementById('html_select_whs_loc_batch_batchsncoderef_label')) {
				document.getElementById('html_select_whs_loc_batch_batchsncoderef_label').remove();
			}

			if (document.getElementById('html_select_whs_loc_batch_batchsncoderef_id')) {
				document.getElementById('html_select_whs_loc_batch_batchsncoderef_id').remove();
			}		

			if (document.getElementById('html_select_whs_loc_batch_topn_label')) {
				document.getElementById('html_select_whs_loc_batch_topn_label').remove();
			}

			if (document.getElementById('html_select_whs_loc_batch_topn')) {
				document.getElementById('html_select_whs_loc_batch_topn').remove();
			}		


			if (document.getElementById('html_select_whs_loc_batch_search')) {
				document.getElementById('html_select_whs_loc_batch_search').remove();
			}	

			if (document.getElementById('html_select_whs_loc_batch_delivered_qty_label')) {
				document.getElementById('html_select_whs_loc_batch_delivered_qty_label').remove();
			}

			if (document.getElementById('html_select_whs_loc_batch_delivered_qty')) {
				document.getElementById('html_select_whs_loc_batch_delivered_qty').remove();
			}				
			

			

			let html = '';
			
			html += '<div style="overflow: auto; overflow-x: auto; height:65px; width: 100%;">';

			// Warehouse
			html += '	<div style="position: relative; top: 0px; left: 0px; width:160px;">';
			html += '		<label id="html_select_whs_loc_batch_warehouse_label" style="position: absolute; top: 0px; left: 2px;">' + __("Warehouse") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 160px; height: 25px;"> ';
			html += '			<input id="html_select_whs_loc_batch_warehouse_id" type="text" class="input-with-feedback form-control bold" style="text-transform:uppercase;">';
			html += '		</div>';
			html += '	</div>';		
			
			// Location
			html += '	<div style="position: relative; top: 0px; left: 170px; width:180px;">';
			html += '		<label id="html_select_whs_loc_batch_location_label" style="position: absolute; top: 0px; left: 2px;">' + __("Location") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 180px; height: 25px;"> ';
			html += '			<input id="html_select_whs_loc_batch_location_id" type="text" class="input-with-feedback form-control bold" style="text-transform:uppercase;">';
			html += '		</div>';
			html += '	</div>';			
			
			// Batch/SN or code ref
			html += '	<div style="position: relative; top: 0px; left: 360px; width:180px;">';
			html += '		<label id="html_select_whs_loc_batch_batchsncoderef_label" style="position: absolute; top: 0px; left: 2px;">' + __("Batch/SN or Code ref") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 180px; height: 25px;"> ';
			html += '			<input id="html_select_whs_loc_batch_batchsncoderef_id" type="text" class="input-with-feedback form-control bold" style="text-transform:uppercase;">';
			html += '		</div>';
			html += '	</div>';	

			// Topn
			html += '	<div style="position: relative; top: 0px; left: 550px; width:140px;">';
			html += '		<label id="html_select_whs_loc_batch_topn_label" style="position: absolute; top: 0px; left: 2px;">' + __("Nr of records") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 140px; height: 25px;"> ';
			html += '			<input id="html_select_whs_loc_batch_topn" type="number" step="any" class="input-with-feedback form-control bold" value="50">';
			html += '		</div>';
			html += '	</div>';				

			// Search
			html += '	<div style="position: relative; top: 22px; left: 700px; width:110px;">';
			html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
			html +='			<button id="html_select_whs_loc_batch_search" class="btn btn-default ellipsis" style="height: 30px; width: 110px;" onclick="">' + __("Search") + '</button>';
			html += '		</div>';
			html += '	</div>';			

			html += '</div>';

			html += '<div id="sales_document_whs_loc_result_content" class="table table-bordered" data-custom-grid="true" style="height:400px; border-radius:6px; padding: 4px;">';
			html += '</div>';	
			
			
			html += '<div style="overflow: auto; overflow-x: auto; height:65px; width: 100%;">';

			// Delivered qty
			html += '	<div style="position: relative; top: 0px; left: 865px; width:140px;">';
			html += '		<label id="html_select_whs_loc_batch_delivered_qty_label" style="position: absolute; top: 0px; left: 2px;">' + __("Delivered qty") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 140px; height: 25px;"> ';
			html += '			<input id="html_select_whs_loc_batch_delivered_qty" type="number" step="any" class="input-with-feedback form-control bold" value="' + qty + '">';
			html += '		</div>';
			html += '	</div>';				

			html += '</div>';
			
	
			whsLocForm.fields_dict.html_select_whs_loc_batch.$wrapper.html(html);
			whsLocForm.$wrapper.find('.modal-dialog').css("max-width", '1050px').css("width", '1050px');
	
			whsLocForm.show();		
			
			silicon_ioi.ioiCommon.sleep_static(200).then(() => {

				let fct_click = function() {
					silicon_ioi.ioiCommon.ioi_sales_document_get_whs_loc_with_stock_refresh(currency_id, currency_rate_inv, journal_id, item_id);
				}

				document.getElementById('html_select_whs_loc_batch_search').onclick = fct_click;

				let fct_keydown = function(event) {

					if (event.keyCode == 13) {
						silicon_ioi.ioiCommon.ioi_sales_document_get_whs_loc_with_stock_refresh(currency_id, currency_rate_inv, journal_id, item_id);
						return false;
					}
				}				

				document.getElementById('html_select_whs_loc_batch_warehouse_id').onkeydown = fct_keydown;
				document.getElementById('html_select_whs_loc_batch_location_id').onkeydown = fct_keydown;
				document.getElementById('html_select_whs_loc_batch_batchsncoderef_id').onkeydown = fct_keydown;

				silicon_ioi.ioiCommon.ioi_sales_document_get_whs_loc_with_stock_refresh(currency_id, currency_rate_inv, journal_id, item_id);

			});
		}
	}

	static ioi_sales_document_get_whs_loc_with_stock_refresh(currency_id, currency_rate_inv, journal_id, item_id)
	{

		let search_whs = '';

		if ((document.getElementById('html_select_whs_loc_batch_warehouse_id').value) && (document.getElementById('html_select_whs_loc_batch_warehouse_id').value.trim() != '')) {
			search_whs = document.getElementById('html_select_whs_loc_batch_warehouse_id').value.toUpperCase();
		}

		let search_loc = '';

		if ((document.getElementById('html_select_whs_loc_batch_location_id').value) && (document.getElementById('html_select_whs_loc_batch_location_id').value.trim() != '')) {
			search_loc = document.getElementById('html_select_whs_loc_batch_location_id').value.toUpperCase();
		}

		let search_batchsncoderef = '';

		if ((document.getElementById('html_select_whs_loc_batch_batchsncoderef_id').value) && (document.getElementById('html_select_whs_loc_batch_batchsncoderef_id').value.trim() != '')) {
			search_batchsncoderef = document.getElementById('html_select_whs_loc_batch_batchsncoderef_id').value.toUpperCase();
		}

		let topn = 0;

		if ((document.getElementById('html_select_whs_loc_batch_topn').value) && (document.getElementById('html_select_whs_loc_batch_topn').value != 0)) {
			topn = parseInt(document.getElementById('html_select_whs_loc_batch_topn').value);
		}


		let data = {};

		let method = 'silicon_ioi.common.sales_document.ioi_sales_document_get_whs_loc_with_stock';

		frappe.call({
			method: method,
			args: {
				"currency_id": currency_id, 
				"currency_rate_inv": currency_rate_inv, 
				"journal_id": journal_id, 
				"item_id": item_id,
				"search_whs": search_whs,
				"search_loc" : search_loc,
				"search_batchsncoderef": search_batchsncoderef,
				"topn": topn
			},
			async: false,
			callback: function (r) {
				data = r.message
			}
		});

		silicon_ioi.ioiCommon.whslocTable = new ioi.Tabulator('#sales_document_whs_loc_result_content', {
			data:data.data,
			maxHeight: 600,
			rowHeight: null,
			selectableRows: 1,
			showProfiles: false,
			movableColumns: true,
			resizableColumns: true,
			autoRedraw: true,

			columns: [
				{field: 'checkbox', width: 50, hozAlign: "center", formatter:"rowSelection", hozAlign:"center", headerHozAlign:"center", frozen: true, headerSort: false, download: false, minWidth: 40, maxWidth: 40},
				{title: __('Warehouse'), field: 'warehouse_id', width: 125},
				{title: __('Location'), field: 'location_id', width: 200},
				{title: __('Stock qty'), field: 'q_stock', width: 100, hozAlign: "right"},			
				{title: __('Batch SN'), field: 'batch_sn_id', width: 175},
				{title: __('Code ref'), field: 'code_ref', width: 175},
				{title: __('Voucher value (inc VAT)'), field: 'voucher_value_tvac', width: 160, hozAlign: "right"},
				{title: __('Voucher value'), field: 'voucher_value', width: 160, visible: false},
				{title: __('Voucher Currency'), field: 'voucher_currency_id', width: 250, visible: false},
				{title: __('Voucher VAT rate'), field: 'voucher_vat_rate', width: 250, visible: false},
				{title: __('Voucher'), field: 'voucher', width: 250, visible: false}
			]
		});

	}




	// ***************************************************************************************************************************************
	// Can Approval Document
	// ***************************************************************************************************************************************
	static can_approve_document(credit_control = false) {

		let ret_value = false;

		if (!credit_control) {

			let amethod = 'silicon_ioi.ioi_configuration.doctype.ioi_approval_scheme.ioi_approval_scheme.ioi_approval_scheme_can_approve';

			frappe.call({
				method: amethod,
				args: { "doctype": cur_frm.doctype, "name": cur_frm.doc.name },
				async: false,
				callback: function (r) {

					if (r.message == 1) {
						ret_value = true;
					}
				}
			});
		}else{


			let amethod = 'silicon_ioi.common.sales_document.ioi_sales_document_can_approve_credit_control';

			frappe.call({
				method: amethod,
				args: { "doctype": cur_frm.doctype},
				async: false,
				callback: function (r) {

					if (r.message == 1) {
						ret_value = true;
					}
				}
			});

		}

		return ret_value;
	}

	// ***************************************************************************************************************************************
	// Approval Document form
	// ***************************************************************************************************************************************
	static approval_document_form(fct_callback_process, approve_only = false)
	{
		var z = new frappe.ui.Dialog({
			'title': __("Approve / refuse document"),
			'fields': [
				{'fieldname': 'html_approval_document', 'fieldtype': 'HTML'}

			],
			primary_action_label: __('Close'),
			primary_action: function(){

				z.hide();
			}
		});


		if (document.getElementById('approve_refuse_document_code_label'))
		{	document.getElementById('approve_refuse_document_code_label').remove();
		}

		if (document.getElementById('approve_refuse_document_code_1'))
		{	document.getElementById('approve_refuse_document_code_1').remove();
		}

		if (document.getElementById('approve_refuse_document_code_2'))
		{	document.getElementById('approve_refuse_document_code_2').remove();
		}

		if (document.getElementById('approve_refuse_document_code_3'))
		{	document.getElementById('approve_refuse_document_code_3').remove();
		}

		if (document.getElementById('approve_refuse_document_code_4'))
		{	document.getElementById('approve_refuse_document_code_4').remove();
		}

		if (document.getElementById('approve_refuse_document_code_5'))
		{	document.getElementById('approve_refuse_document_code_5').remove();
		}

		if (document.getElementById('approve_refuse_document_code_6'))
		{	document.getElementById('approve_refuse_document_code_6').remove();
		}

		if (document.getElementById('approve_refuse_bt_view'))
		{	document.getElementById('approve_refuse_bt_view').remove();
		}

		if (document.getElementById('approve_refuse_view'))
		{	document.getElementById('approve_refuse_view').remove();
		}


		if (document.getElementById('approve_refuse_forgot_code'))
		{	document.getElementById('approve_refuse_forgot_code').remove();
		}


		if (document.getElementById('approve_refuse_document_comment_label'))
		{	document.getElementById('approve_refuse_document_comment_label').remove();
		}

		if (document.getElementById('approve_refuse_document_comment'))
		{	document.getElementById('approve_refuse_document_comment').remove();
		}


		if (document.getElementById('bt_approve'))
		{	document.getElementById('bt_approve').remove();
		}


		if (document.getElementById('bt_refuse'))
		{	document.getElementById('bt_refuse').remove();
		}

		let html = '';

		html += '<div style="overflow-x: auto; width:100%;">';

		html += '	<div style="overflow-x: auto; height:170px; width:100%;">';

		// Code
		html += '		<div style="position: relative; top: 0px; left: 0px; width:350px;">';
		html += '			<label id="approve_refuse_document_code_label" style="position: absolute; top: 0px; left: 2px;">' + __("Enter your code") + '</label>';

		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 40px; height: 25px;"> ';
		html += '				<input id="approve_refuse_document_code_1" type="password" maxlength="1" class="input-with-feedback form-control bold">';
		html += '			</div>';

		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 52px; width: 40px; height: 25px;"> ';
		html += '				<input id="approve_refuse_document_code_2" type="password" maxlength="1" class="input-with-feedback form-control bold">';
		html += '			</div>';

		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 102px; width: 40px; height: 25px;"> ';
		html += '				<input id="approve_refuse_document_code_3" type="password" maxlength="1" class="input-with-feedback form-control bold">';
		html += '			</div>';

		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 152px; width: 40px; height: 25px;"> ';
		html += '				<input id="approve_refuse_document_code_4" type="password" maxlength="1" class="input-with-feedback form-control bold">';
		html += '			</div>';

		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 202px; width: 40px; height: 25px;"> ';
		html += '				<input id="approve_refuse_document_code_5" type="password" maxlength="1" class="input-with-feedback form-control bold">';
		html += '			</div>';

		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 252px; width: 40px; height: 25px;"> ';
		html += '				<input id="approve_refuse_document_code_6" type="password" maxlength="1" class="input-with-feedback form-control bold">';
		html += '			</div>';

		html += '			<img id="approve_refuse_bt_view" src="' + path_buttons + 'view.png" style="position: absolute; top: 23px; left: 297px; width: 50px; height: 38px; border-radius:6px; padding: 4px;"></img>';

		html += '			<input id="approve_refuse_view" type="hidden" value="0">';


		html += '			<label id="approve_refuse_document_comment" style="position: absolute; top: 65px; left: 2px;">' + __("Comment") + '</label>';
		html += '			<div class="control-input" style="position: absolute; top: 90px; left: 2px; width: 342px; height: 25px;"> ';
		html += '				<input id="approve_refuse_comment" type="text" class="input-with-feedback form-control bold">';
		html += '			</div>';


		html += '		</div>';

		// I forgot my code
		html += '		<div style="position: relative; top: 0px; left: 0px; width:340px;" align="right">';
		html += '			<label id="approve_refuse_forgot_code" align="right"><u>' + __("I forgot my code") + '</u></label>';
		html += '		</div>';




		// Approve
		html += '	<div style="position: relative; top: 110px; left: 0px; width:160px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px;">';
		html += '			<button id="bt_approve" title="' + __("Approve") +'" data-label="Search" class="btn btn-default ellipsis" style="height: 30px; width: 160px;" onclick="">' + __("Approve") + '</button>';
		html += '		</div>';
		html += '	</div>';

		if (!approve_only) {

			// Refuse
			html += '	<div style="position: relative; top: 110px; left: 182px; width:160px;">';
			html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px;">';
			html +='			<button id="bt_refuse" title="' + __("Refuse") +'" data-label="Search" class="btn btn-default ellipsis" style="height: 30px; width: 160px;" onclick="">' + __("Refuse") + '</button>';
			html += '		</div>';
			html += '	</div>';
		}

		html += '</div>';

		z.fields_dict.html_approval_document.$wrapper.html(html);
		z.$wrapper.find('.modal-dialog').css("max-width", "393px").css("width", "393px");
		z.$wrapper.find('.modal-dialog').css("max-height", "50%").css("height", "50%");
		z.show();

		silicon_ioi.ioiCommon.select_sleep(250).then(() => {

			let fct_code_1_keyup = function(event) {
				if ((event.keyCode != 8) && (event.keyCode != 9) && (event.keyCode != 16) && (event.keyCode != 37) && (event.keyCode != 38) && (event.keyCode != 39) && (event.keyCode != 40) && (event.keyCode != 46)) {
					document.getElementById('approve_refuse_document_code_2').focus();
					return false;
				}
			};


			document.getElementById('approve_refuse_document_code_1').onkeyup = fct_code_1_keyup;


			let fct_code_2_keyup = function(event) {
				if ((event.keyCode != 8) && (event.keyCode != 9) && (event.keyCode != 16) && (event.keyCode != 37) && (event.keyCode != 38) && (event.keyCode != 39) && (event.keyCode != 40) && (event.keyCode != 46)) {
					document.getElementById('approve_refuse_document_code_3').focus();
					return false;
				}
			};

			document.getElementById('approve_refuse_document_code_2').onkeyup = fct_code_2_keyup;


			let fct_code_3_keyup = function(event) {
				if ((event.keyCode != 8) && (event.keyCode != 9) && (event.keyCode != 16) && (event.keyCode != 37) && (event.keyCode != 38) && (event.keyCode != 39) && (event.keyCode != 40) && (event.keyCode != 46)) {
					document.getElementById('approve_refuse_document_code_4').focus();
					return false;
				}
			};

			document.getElementById('approve_refuse_document_code_3').onkeyup = fct_code_3_keyup;


			let fct_code_4_keyup = function(event) {
				if ((event.keyCode != 8) && (event.keyCode != 9) && (event.keyCode != 16) && (event.keyCode != 37) && (event.keyCode != 38) && (event.keyCode != 39) && (event.keyCode != 40) && (event.keyCode != 46)) {
					document.getElementById('approve_refuse_document_code_5').focus();
				}
			};

			document.getElementById('approve_refuse_document_code_4').onkeyup = fct_code_4_keyup;


			let fct_code_5_keyup = function(event) {
				if ((event.keyCode != 8) && (event.keyCode != 9) && (event.keyCode != 16) && (event.keyCode != 37) && (event.keyCode != 38) && (event.keyCode != 39) && (event.keyCode != 40) && (event.keyCode != 46)) {
					document.getElementById('approve_refuse_document_code_6').focus();
				}
			};

			document.getElementById('approve_refuse_document_code_5').onkeyup = fct_code_5_keyup;


			let fct_over = function() {
				this.style.cursor = 'pointer';
			};

			document.getElementById('approve_refuse_forgot_code').onmouseover = fct_over;


			let fct_leave = function() {
				this.style.cursor = 'none';
			};

			document.getElementById('approve_refuse_forgot_code').onmouseleave = fct_leave;


			let fct_forgot_click = function() {

				silicon_ioi.ioiCommon.forgot_code(z);
			};

			document.getElementById('approve_refuse_forgot_code').onclick = fct_forgot_click;


			document.getElementById('approve_refuse_bt_view').onmouseover = fct_over;
			document.getElementById('approve_refuse_bt_view').onmouseleave = fct_leave;


			let fct_bt_view_click = function() {

				if (document.getElementById('approve_refuse_view').value == '0') {

					document.getElementById('approve_refuse_document_code_1').type = 'text';
					document.getElementById('approve_refuse_document_code_2').type = 'text';
					document.getElementById('approve_refuse_document_code_3').type = 'text';
					document.getElementById('approve_refuse_document_code_4').type = 'text';
					document.getElementById('approve_refuse_document_code_5').type = 'text';
					document.getElementById('approve_refuse_document_code_6').type = 'text';

					document.getElementById('approve_refuse_view').value = '1';

				}else{

					document.getElementById('approve_refuse_document_code_1').type = 'password';
					document.getElementById('approve_refuse_document_code_2').type = 'password';
					document.getElementById('approve_refuse_document_code_3').type = 'password';
					document.getElementById('approve_refuse_document_code_4').type = 'password';
					document.getElementById('approve_refuse_document_code_5').type = 'password';
					document.getElementById('approve_refuse_document_code_6').type = 'password';

					document.getElementById('approve_refuse_view').value = '0';
				}

				if (document.getElementById('approve_refuse_document_code_1').value.trim() == '') {
					document.getElementById('approve_refuse_document_code_1').focus();
				}else if (document.getElementById('approve_refuse_document_code_2').value.trim() == '') {
					document.getElementById('approve_refuse_document_code_2').focus();
				}else if (document.getElementById('approve_refuse_document_code_3').value.trim() == '') {
					document.getElementById('approve_refuse_document_code_3').focus();
				}else if (document.getElementById('approve_refuse_document_code_4').value.trim() == '') {
					document.getElementById('approve_refuse_document_code_4').focus();
				}else if (document.getElementById('approve_refuse_document_code_5').value.trim() == '') {
					document.getElementById('approve_refuse_document_code_5').focus();
				}else if (document.getElementById('approve_refuse_document_code_6').value.trim() == '') {
					document.getElementById('approve_refuse_document_code_6').focus();
				}


			};

			document.getElementById('approve_refuse_bt_view').onclick = fct_bt_view_click;




			let fct_approve_click = function() {

				silicon_ioi.ioiCommon.approve_refuse('APPROVE', fct_callback_process, z);
			};

			document.getElementById('bt_approve').onclick = fct_approve_click;

			if (!approve_only) {

				let fct_refuse_click = function() {

					silicon_ioi.ioiCommon.approve_refuse('REFUSE', fct_callback_process, z);
				};

				document.getElementById('bt_refuse').onclick = fct_refuse_click;
			}


			silicon_ioi.ioiCommon.select_sleep(500).then(() => {
				document.getElementById('approve_refuse_document_code_1').focus();
			});


		});
	}

	static forgot_code(z)
	{
		let callback = function () {

			if (z) {
				z.hide();
			}

			silicon_ioi.ioiCommon.change_code(z);
		};

		frappe.verify_password(callback);
	}

	static change_code(a) {

		var z = new frappe.ui.Dialog({
			'title': __("Define a new approval code"),
			'fields': [
				{'fieldname': 'html_new_approval_code', 'fieldtype': 'HTML'}

			],
			primary_action_label: __('Ok'),
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				if (document.getElementById('approve_refuse_new_code_1').value.trim() == '') {
					document.getElementById('approve_refuse_new_code_1').focus();
				}else if (document.getElementById('approve_refuse_new_code_2').value.trim() == '') {
					document.getElementById('approve_refuse_new_code_2').focus();
				}else if (document.getElementById('approve_refuse_new_code_3').value.trim() == '') {
					document.getElementById('approve_refuse_new_code_3').focus();
				}else if (document.getElementById('approve_refuse_new_code_4').value.trim() == '') {
					document.getElementById('approve_refuse_new_code_4').focus();
				}else if (document.getElementById('approve_refuse_new_code_5').value.trim() == '') {
					document.getElementById('approve_refuse_new_code_5').focus();
				}else if (document.getElementById('approve_refuse_new_code_6').value.trim() == '') {
					document.getElementById('approve_refuse_new_code_6').focus();
				}

				if (((!document.getElementById('approve_refuse_new_code_1')) || ((document.getElementById('approve_refuse_new_code_1')) && (document.getElementById('approve_refuse_new_code_1').value.trim() == ''))) ||
					((!document.getElementById('approve_refuse_new_code_2')) || ((document.getElementById('approve_refuse_new_code_2')) && (document.getElementById('approve_refuse_new_code_2').value.trim() == ''))) ||
					((!document.getElementById('approve_refuse_new_code_3')) || ((document.getElementById('approve_refuse_new_code_3')) && (document.getElementById('approve_refuse_new_code_3').value.trim() == ''))) ||
					((!document.getElementById('approve_refuse_new_code_4')) || ((document.getElementById('approve_refuse_new_code_4')) && (document.getElementById('approve_refuse_new_code_4').value.trim() == ''))) ||
					((!document.getElementById('approve_refuse_new_code_5')) || ((document.getElementById('approve_refuse_new_code_5')) && (document.getElementById('approve_refuse_new_code_5').value.trim() == ''))) ||
					((!document.getElementById('approve_refuse_new_code_6')) || ((document.getElementById('approve_refuse_new_code_6')) && (document.getElementById('approve_refuse_new_code_6').value.trim() == ''))))
				{

					frappe.msgprint({title: __("Message"), message: __('Fill in the code, please'), indicator: "red"});
					raise;
				}

				let code = document.getElementById('approve_refuse_new_code_1').value.toString() + document.getElementById('approve_refuse_new_code_2').value.toString() + document.getElementById('approve_refuse_new_code_3').value.toString();
				code += document.getElementById('approve_refuse_new_code_4').value.toString() + document.getElementById('approve_refuse_new_code_5').value.toString() + document.getElementById('approve_refuse_new_code_6').value.toString();

				let method = path_user + '.ioi_user_change_approval_code';

				let ok = false;

				frappe.call({  	method: method,
								args: { "code": code.toString() },
								async: false,
								callback:function(r) {

									ok = true;
							}
				});


				if (ok) {
					z.hide();

					if (a) {
						a.show();
					}else{
						window.location.reload();
					}

				}
			},

			secondary_action: function(){

				z.hide();

				if (a) {
					a.show();
				}
			}

		});


		if (document.getElementById('approve_refuse_new_code_label'))
		{	document.getElementById('approve_refuse_new_code_label').remove();
		}

		if (document.getElementById('approve_refuse_new_code_1'))
		{	document.getElementById('approve_refuse_new_code_1').remove();
		}

		if (document.getElementById('approve_refuse_new_code_2'))
		{	document.getElementById('approve_refuse_new_code_2').remove();
		}

		if (document.getElementById('approve_refuse_new_code_3'))
		{	document.getElementById('approve_refuse_new_code_3').remove();
		}

		if (document.getElementById('approve_refuse_new_code_4'))
		{	document.getElementById('approve_refuse_new_code_4').remove();
		}

		if (document.getElementById('approve_refuse_new_code_5'))
		{	document.getElementById('approve_refuse_new_code_5').remove();
		}

		if (document.getElementById('approve_refuse_new_code_6'))
		{	document.getElementById('approve_refuse_new_code_6').remove();
		}

		if (document.getElementById('approve_refuse_new_bt_view'))
		{	document.getElementById('approve_refuse_new_bt_view').remove();
		}

		if (document.getElementById('approve_refuse_new_view'))
		{	document.getElementById('approve_refuse_new_view').remove();
		}


		let html = '';

		html += '<div style="overflow-x: auto; width:100%;">';

		html += '	<div style="overflow-x: auto; height:70px; width:100%;">';

		// Code
		html += '		<div style="position: relative; top: 0px; left: 0px; width:350px;">';
		html += '			<label id="approve_refuse_new_code_label" style="position: absolute; top: 0px; left: 2px;">' + __("Enter the new code") + '</label>';

		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 40px; height: 25px;"> ';
		html += '				<input id="approve_refuse_new_code_1" type="password" maxlength="1" class="input-with-feedback form-control bold">';
		html += '			</div>';

		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 52px; width: 40px; height: 25px;"> ';
		html += '				<input id="approve_refuse_new_code_2" type="password" maxlength="1" class="input-with-feedback form-control bold">';
		html += '			</div>';

		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 102px; width: 40px; height: 25px;"> ';
		html += '				<input id="approve_refuse_new_code_3" type="password" maxlength="1" class="input-with-feedback form-control bold">';
		html += '			</div>';

		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 152px; width: 40px; height: 25px;"> ';
		html += '				<input id="approve_refuse_new_code_4" type="password" maxlength="1" class="input-with-feedback form-control bold">';
		html += '			</div>';

		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 202px; width: 40px; height: 25px;"> ';
		html += '				<input id="approve_refuse_new_code_5" type="password" maxlength="1" class="input-with-feedback form-control bold">';
		html += '			</div>';

		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 252px; width: 40px; height: 25px;"> ';
		html += '				<input id="approve_refuse_new_code_6" type="password" maxlength="1" class="input-with-feedback form-control bold">';
		html += '			</div>';

		html += '			<img id="approve_refuse_new_bt_view" src="' + path_buttons + 'view.png" style="position: absolute; top: 23px; left: 297px; width: 50px; height: 38px; border-radius:6px; padding: 4px;"></img>';

		html += '			<input id="approve_refuse_new_view" type="hidden" value="0">';

		html += '		</div>';

		html += '</div>';

		z.fields_dict.html_new_approval_code.$wrapper.html(html);
		z.$wrapper.find('.modal-dialog').css("max-width", "393px").css("width", "393px");
		z.$wrapper.find('.modal-dialog').css("max-height", "50%").css("height", "50%");
		z.show();

		silicon_ioi.ioiCommon.select_sleep(250).then(() => {

			let fct_code_1_keyup = function(event) {
				if ((event.keyCode != 8) && (event.keyCode != 9) && (event.keyCode != 16) && (event.keyCode != 37) && (event.keyCode != 38) && (event.keyCode != 39) && (event.keyCode != 40) && (event.keyCode != 46)) {
					document.getElementById('approve_refuse_new_code_2').focus();
					return false;
				}
			};


			document.getElementById('approve_refuse_new_code_1').onkeyup = fct_code_1_keyup;


			let fct_code_2_keyup = function(event) {
				if ((event.keyCode != 8) && (event.keyCode != 9) && (event.keyCode != 16) && (event.keyCode != 37) && (event.keyCode != 38) && (event.keyCode != 39) && (event.keyCode != 40) && (event.keyCode != 46)) {
					document.getElementById('approve_refuse_new_code_3').focus();
					return false;
				}
			};

			document.getElementById('approve_refuse_new_code_2').onkeyup = fct_code_2_keyup;


			let fct_code_3_keyup = function(event) {
				if ((event.keyCode != 8) && (event.keyCode != 9) && (event.keyCode != 16) && (event.keyCode != 37) && (event.keyCode != 38) && (event.keyCode != 39) && (event.keyCode != 40) && (event.keyCode != 46)) {
					document.getElementById('approve_refuse_new_code_4').focus();
					return false;
				}
			};

			document.getElementById('approve_refuse_new_code_3').onkeyup = fct_code_3_keyup;


			let fct_code_4_keyup = function(event) {
				if ((event.keyCode != 8) && (event.keyCode != 9) && (event.keyCode != 16) && (event.keyCode != 37) && (event.keyCode != 38) && (event.keyCode != 39) && (event.keyCode != 40) && (event.keyCode != 46)) {
					document.getElementById('approve_refuse_new_code_5').focus();
				}
			};

			document.getElementById('approve_refuse_new_code_4').onkeyup = fct_code_4_keyup;


			let fct_code_5_keyup = function(event) {
				if ((event.keyCode != 8) && (event.keyCode != 9) && (event.keyCode != 16) && (event.keyCode != 37) && (event.keyCode != 38) && (event.keyCode != 39) && (event.keyCode != 40) && (event.keyCode != 46)) {
					document.getElementById('approve_refuse_new_code_6').focus();
				}
			};

			document.getElementById('approve_refuse_new_code_5').onkeyup = fct_code_5_keyup;


			let fct_over = function() {
				this.style.cursor = 'pointer';
			};


			let fct_leave = function() {
				this.style.cursor = 'none';
			};


			document.getElementById('approve_refuse_new_bt_view').onmouseover = fct_over;
			document.getElementById('approve_refuse_new_bt_view').onmouseleave = fct_leave;


			let fct_bt_view_click = function() {

				if (document.getElementById('approve_refuse_new_view').value == '0') {

					document.getElementById('approve_refuse_new_code_1').type = 'text';
					document.getElementById('approve_refuse_new_code_2').type = 'text';
					document.getElementById('approve_refuse_new_code_3').type = 'text';
					document.getElementById('approve_refuse_new_code_4').type = 'text';
					document.getElementById('approve_refuse_new_code_5').type = 'text';
					document.getElementById('approve_refuse_new_code_6').type = 'text';

					document.getElementById('approve_refuse_new_view').value = '1';

				}else{

					document.getElementById('approve_refuse_new_code_1').type = 'password';
					document.getElementById('approve_refuse_new_code_2').type = 'password';
					document.getElementById('approve_refuse_new_code_3').type = 'password';
					document.getElementById('approve_refuse_new_code_4').type = 'password';
					document.getElementById('approve_refuse_new_code_5').type = 'password';
					document.getElementById('approve_refuse_new_code_6').type = 'password';

					document.getElementById('approve_refuse_new_view').value = '0';
				}

				if (document.getElementById('approve_refuse_new_code_1').value.trim() == '') {
					document.getElementById('approve_refuse_new_code_1').focus();
				}else if (document.getElementById('approve_refuse_new_code_2').value.trim() == '') {
					document.getElementById('approve_refuse_new_code_2').focus();
				}else if (document.getElementById('approve_refuse_new_code_3').value.trim() == '') {
					document.getElementById('approve_refuse_new_code_3').focus();
				}else if (document.getElementById('approve_refuse_new_code_4').value.trim() == '') {
					document.getElementById('approve_refuse_new_code_4').focus();
				}else if (document.getElementById('approve_refuse_new_code_5').value.trim() == '') {
					document.getElementById('approve_refuse_new_code_5').focus();
				}else if (document.getElementById('approve_refuse_new_code_6').value.trim() == '') {
					document.getElementById('approve_refuse_new_code_6').focus();
				}


			};

			document.getElementById('approve_refuse_new_bt_view').onclick = fct_bt_view_click;


			silicon_ioi.ioiCommon.select_sleep(500).then(() => {
				document.getElementById('approve_refuse_new_code_1').focus();
			});


		});


	}

	static approve_refuse(kind, fct_callback_process, z)
	{

		if (((!document.getElementById('approve_refuse_document_code_1')) || ((document.getElementById('approve_refuse_document_code_1')) && (document.getElementById('approve_refuse_document_code_1').value.trim() == ''))) ||
			((!document.getElementById('approve_refuse_document_code_2')) || ((document.getElementById('approve_refuse_document_code_2')) && (document.getElementById('approve_refuse_document_code_2').value.trim() == ''))) ||
			((!document.getElementById('approve_refuse_document_code_3')) || ((document.getElementById('approve_refuse_document_code_3')) && (document.getElementById('approve_refuse_document_code_3').value.trim() == ''))) ||
			((!document.getElementById('approve_refuse_document_code_4')) || ((document.getElementById('approve_refuse_document_code_4')) && (document.getElementById('approve_refuse_document_code_4').value.trim() == ''))) ||
			((!document.getElementById('approve_refuse_document_code_5')) || ((document.getElementById('approve_refuse_document_code_5')) && (document.getElementById('approve_refuse_document_code_5').value.trim() == ''))) ||
			((!document.getElementById('approve_refuse_document_code_6')) || ((document.getElementById('approve_refuse_document_code_6')) && (document.getElementById('approve_refuse_document_code_6').value.trim() == ''))))
		{

			frappe.msgprint({title: __("Message"), message: __('Fill in the code, please'), indicator: "red"});
			raise;
		}

		let code = document.getElementById('approve_refuse_document_code_1').value + document.getElementById('approve_refuse_document_code_2').value + document.getElementById('approve_refuse_document_code_3').value;
		code += document.getElementById('approve_refuse_document_code_4').value + document.getElementById('approve_refuse_document_code_5').value + document.getElementById('approve_refuse_document_code_6').value;

		let method = path_user + '.ioi_user_does_approval_code_valid';

		let ok = false;

		frappe.call({  	method: method,
						args: { "code": code.toString() },
						async: false,
						callback:function(r) {

							if (r.message == 1) {
								ok = true;
							}
						}
		});



		if (!ok) {

			frappe.msgprint({title: __("Message"), message: __('The code is not valid'), indicator: "red"});
			raise;
		}

		if (kind == 'REFUSE') {
			if (document.getElementById('approve_refuse_comment').value.trim() == '') {

				frappe.msgprint({title: __("Message"), message: __('Fill in the comment, please'), indicator: "red"});
				raise;

			}
		}


		if (!fct_callback_process) {

			let msg = '';
			let s = '';

			let top = 10;



			msg = '<div style="overflow: auto; overflow-x: auto;" align="center">';

			if (kind == 'APPROVE') {
				s = '<font color="blue">' + __('Are you sure to approve this document ?') + '</font>';
			}else{
				s = '<font color="blue">' + __('Are you sure to refuse this document ?') + '</font>';
			}



			msg += '<label> ' + s + '</label>';

			msg += '</div>';

			top += 20;
			msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';


			frappe.confirm(	msg,
				() => 	{
					silicon_ioi.ioiCommon.do_approve_refuse(kind, fct_callback_process, z);
						},
				() => 	{
						}
			);
		}else{
			silicon_ioi.ioiCommon.do_approve_refuse(kind, fct_callback_process, z);
		}

	}

	static do_approve_refuse(kind, fct_callback_process, z)
	{
		if (!fct_callback_process) {
			let method = path_approval_document + '.ioi_approval_document_approve_refuse';

			frappe.call({  	method: method,
							args: { "kind": kind, "doctype": cur_frm.doctype, "name": cur_frm.doc.name, "refused_remark" : document.getElementById('approve_refuse_comment').value},
							async: false,
							callback:function(r) {

								window.location.reload()

							}
			});
		}else{
			if (z) {
				z.hide();
			}

			fct_callback_process(kind, document.getElementById('approve_refuse_comment').value);
		}

	}


	static approval_detail(html_fieldname)
	{

		if (document.getElementById('ioi_approval_grid')) {
			document.getElementById('ioi_approval_grid').remove();
		}


		if (document.getElementById('ioi_approval_detail')) {
			document.getElementById('ioi_approval_detail').remove();
		}

		if (document.getElementById('ioi_approval_content')) {
			document.getElementById('ioi_approval_content').remove();
		}


		if (document.getElementById('button_approve_document')) {
			document.getElementById('button_approve_document').remove();
		}


		let html = '';

		// Grid Header
		html += '<div id="ioi_approval_content" style="overflow: auto; overflow-x: auto; height:200px;">';

		html += '<table id="ioi_approval_grid" border=1 style="border: 1px solid #E8EAEB" width=1070px data-custom-grid="true">';

		html += '<tr style="height:30px">';

		html += '<td width=150px style="vertical-align: middle;"><b>&nbsp;' + __("Date / time") 	+ '</b></td>';
		html += '<td width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Status") 			+ '</b></td>';
		html += '<td width=400px style="vertical-align: middle;"><b>&nbsp;' + __("User / Role") 	+ '</b></td>';
		html += '<td width=400px style="vertical-align: middle;"><b>&nbsp;' + __("Comment") 		+ '</b></td>';
		html += '</tr>';
		html += '</table>';


		// Result
		html += '<table id="ioi_approval_detail" border=1 style="border: 1px solid #E8EAEB" width=1070px>';
		html += '<tr style="height:30px">';
		html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=400px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=400px style="vertical-align: middle;">&nbsp;</td>';
		html += '</tr>';
		html += '</table>';

		html += '</div>'

		if (((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') && ((cur_frm.doc.ioistatus == -1) || (cur_frm.doc.ioistatus == 1))) ||
			((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') && ((cur_frm.doc.ioistatus == -1) || (cur_frm.doc.ioistatus == 1))) ||
			((cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') && (cur_frm.doc.ioistatus == 1)) ||
			((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') && (cur_frm.doc.ioistatus == 1)) ||
			((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') && (cur_frm.doc.ioistatus == 1)) ||
			((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') && (cur_frm.doc.ioistatus == 1)) ||
			((cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE') && (cur_frm.doc.ioistatus == 1)) ||
			((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE') && (cur_frm.doc.ioistatus == 1))) {


			if ((!cur_frm.doc.approval_status) || ((cur_frm.doc.approval_status) && (cur_frm.doc.approval_status == 0))) {

				let method = path_approval_document + '.ioi_approval_document_approve_only_already_done';

				let already_approve = 0;

				frappe.call({  	method: method,
								args: { "doctype": cur_frm.doctype, "name": cur_frm.doc.name},
								async: false,
								callback:function(r) {

									already_approve = r.message;

								}
				});

				if (already_approve == 0) {

					html += '<div style="overflow: auto; overflow-x: auto; height:10px;">';
					html += '</div>';

					html += '<div style="overflow: auto; overflow-x: auto; height:40px;">';


					html += '	<div style="position: relative; top: 0px; left: 0px; width:150px;">';
					html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px;">';
					html +='			<button id="button_approve_document" title="' + __("Approve") +'" data-label="Search" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="">' + __("Approve") + '</button>';
					html += '		</div>';
					html += '	</div>';

					html += '</div>';
				}
			}
		}



		cur_frm.fields_dict[html_fieldname].$wrapper.empty();
		cur_frm.fields_dict[html_fieldname].$wrapper.append(html);

		silicon_ioi.ioiCommon.select_sleep(150).then(() => {

			if (document.getElementById('button_approve_document')) {

				let fct_click = function() {

					let fct_callback = function (kind, remark) {

						let method = path_approval_document + '.ioi_approval_document_approve_only_without_check';

						frappe.call({  	method: method,
										args: { "doctype": cur_frm.doctype, "name": cur_frm.doc.name, "remark" : remark},
										async: false,
										callback:function(r) {

											window.location.reload();

										}
						});
					}

					silicon_ioi.ioiCommon.approval_document_form(fct_callback, true);
				};

				document.getElementById('button_approve_document').onclick = fct_click;
			}

			silicon_ioi.ioiCommon.approval_detail_refresh();

		});
	}


	static approval_detail_refresh()
	{
		if (!cur_frm.is_new()) {

			let html = '';

			let method = path_approval_document + '.ioi_approval_document_get_approvals_detail';

			frappe.call({  	method: method,
							args: {	"doctype": cur_frm.doctype,
									"name": cur_frm.doc.name
									},
							async: true,
							callback:function(r)	{
														if (document.getElementById('ioi_approval_detail'))
														{
															document.getElementById('ioi_approval_detail').remove();
														}


														if (r.message.length > 0)
														{
															html += '<table id="ioi_approval_detail" border=1 style="border: 1px solid #E8EAEB" width=1070px>';

															for (var i = 0; i < r.message.length; i++)
															{

																html += '<tr style="height:30px">';

																if (r.message[i].approval_datetime != null) {
																	html += '<td width=150px style="vertical-align: middle;">&nbsp;' + r.message[i].approval_datetime.toString().substring(0, 16) + '</td>';
																}else{
																	html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
																}

																if (r.message[i].approval_status == 1) {
																	html += '<td width=120px bgcolor="#97F779" style="vertical-align: middle;">&nbsp;' + __('Approved') + '</td>';
																}else {
																	html += '<td width=120px bgcolor="#FA6557" style="vertical-align: middle;">&nbsp;' + __('Refused') + '</td>';
																}

																if ((r.message[i].approval_role == null) || (r.message[i].approval_role.trim() == '')) {
																	html += '<td width=400px style="vertical-align: middle;">&nbsp;' + r.message[i].approval_user + '</td>';
																}else{
																	html += '<td width=400px style="vertical-align: middle;">&nbsp;' + r.message[i].approval_role + ' ( ' + r.message[i].approval_user + ' ) ' + '</td>';
																}

																if (r.message[i].approval_remark != null) {
																	html += '<td width=400px style="vertical-align: middle;">&nbsp;' + r.message[i].approval_remark	+ '</td>';
																}else{
																	html += '<td width=400px style="vertical-align: middle;">&nbsp;</td>';
																}

																html += '</tr>';
															}
															html += '</table>';
														}else
														{
															html += '<table id="ioi_approval_detail" border=1 style="border: 1px solid #E8EAEB" width=1070px>';
															html += '<tr style="height:30px">';
															html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
															html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
															html += '<td width=400px style="vertical-align: middle;">&nbsp;</td>';
															html += '<td width=400px style="vertical-align: middle;">&nbsp;</td>';
															html += '</tr>';
															html += '</table>';
														}

														document.getElementById('ioi_approval_content').insertAdjacentHTML('beforeend', html);
													}
			});
		}
	}


	// ***************************************************************************************************************************************
	// Sleep
	// ***************************************************************************************************************************************
	static select_sleep(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	// ***************************************************************************************************************************************
	// Column mouse over
	// ***************************************************************************************************************************************
	static col_mouse_over()
	{
		this.style.cursor = 'pointer';
	}

	// ***************************************************************************************************************************************
	// Column mouse leave
	// ***************************************************************************************************************************************
	static col_mouse_leave()
	{
		this.style.cursor = 'none';
	}

	// ***************************************************************************************************************************************
	// Export window options
	// ***************************************************************************************************************************************
	static export_form(callback, tabulator=false, expert_view=false)
	{
		if (document.getElementById('export_filename_label'))
		{	document.getElementById('export_filename_label').remove();
		}

		if (document.getElementById('export_filename'))
		{	document.getElementById('export_filename').remove();
		}

		if (document.getElementById('export_file_type_label'))
		{	document.getElementById('export_file_type_label').remove();
		}

		if (document.getElementById('export_file_type'))
		{	document.getElementById('export_file_type').remove();
		}

		if (document.getElementById('export_file_limit_label'))
		{	document.getElementById('export_file_limit_label').remove();
		}

		if (document.getElementById('export_file_limit'))
		{	document.getElementById('export_file_limit').remove();
		}

		if (document.getElementById('export_inhibit_topn'))
		{	document.getElementById('export_inhibit_topn').remove();
		}


		var d = new frappe.ui.Dialog({
			'title': __("Export"),
			'fields': [
				{'fieldname': 'html_export', 'fieldtype': 'HTML'}
			],
			primary_action_label: __("Ok"),
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				d.hide();
				callback();
			},
			secondary_action: function() {
				d.hide();
			}
		});

		let html = '';

		html += '<div style="height:200px;">';

		html += '	<div style="position: relative; top: 0px; left: 0px; width:150px;">';
		html += '		<label id="export_filename_label" style="position: absolute; top: 0px; left: 2px;">' + __("File name (not for csv)") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 350px; height: 25px;"> ';
		html += '			<input id="export_filename" type="text" class="input-with-feedback form-control bold">';
		html += '		</div>';
		html += '	</div>';

		html += '	<div style="position: relative; top: 65px; left: 0px; width:170px;">';
		html += '		<label id="export_file_type_label" style="position: absolute; top: 0px; left: 2px;">' + __("File type") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '			<select id="export_file_type" class="input-with-feedback form-control bold"> ';
		html += '				<option value="CSV">'  + __("CSV")  + '</option> ';
		html += '				<option value="XLSX">' + __("XLSX") + '</option> ';
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		if (!tabulator) {
			html += '	<div style="position: relative; top: 100px; left: 180px; width:350px;">';
			html += '		<div class="control-input" style="position: absolute; top: 0px; left: 2px; width: 350px; height: 25px;"> ';
			html += '		<input type="checkbox" id="export_inhibit_topn" style="postion:absolute; top: 2px; left: 2px;">' + __("Inhibit no record limit");
			html += '		</div>';
			html += '	</div>';
		}

		if (expert_view) {
			html += '	<div style="position: relative; top: 65px; left: 180px; width:170px;">';
			html += '		<label id="export_file_limit_label" style="position: absolute; top: 0px; left: 2px;">' + __("Limit") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
			html += '			<select id="export_file_limit" class="input-with-feedback form-control bold"> ';
			html += '				<option value="20">20</option> ';
			html += '				<option value="100">100</option> ';
			html += '				<option value="500">500</option> ';
			html += '				<option value="1000">1000</option> ';
			html += '				<option value="2000">2000</option> ';
			html += '				<option value="all">' + __("All") + '</option> ';
			html += '			</select> ';
			html += '		</div>';
			html += '	</div>';
		}

		html += '</div>';

		d.fields_dict.html_export.$wrapper.html(html);
		d.$wrapper.find('.modal-dialog').css("max-width", "400px").css("width", "400px");
		d.$wrapper.find('.modal-dialog').css("max-height", "150px").css("height", "250px");
		d.show();

		silicon_ioi.ioiCommon.sleep_static(250).then(() => {

			document.getElementById('export_file_type').selectedIndex = 1;
		});


	}

	// ***************************************************************************************************************************************
	// Sleep
	// ***************************************************************************************************************************************
	static sleep_static(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	// ***************************************************************************************************************************************
	// Get data from tabulator table
	// ***************************************************************************************************************************************
	static get_tabulator_data(t, from_col=0)
	{
		let content = '';

		let replaceAllCaracters = (s) => {
			s.replaceAll('•', '-');
			s.replaceAll('\\', ' ');
			s.replaceAll(',', ' ');
			s.replaceAll(';', ' ');
			s.replaceAll('↑', '');
			s.replaceAll('↓', '');
			s.replaceAll('\n', ' ');

			return s
		}

		if (t[0].classList.contains('tabulator-col')) {
			for (let i = 0; i < t.length; i++) {
				let s = t[i].firstElementChild.firstElementChild.firstElementChild.innerHTML

				if (s != "&nbsp;" && !s.startsWith('<')) {

					replaceAllCaracters(s)

					content += s;
					content += ';'
				}
			}

			content += '\n';

		} else if (t[0].classList.contains('tabulator-row')) {
			for (let i = 0; i < t.length; i++) {
				for (let j = from_col; j < t[i].children.length; j++) {
					if (t[i].children[j] && t[i].children[j].classList.contains('tabulator-cell')) {
						let s = t[i].children[j].innerHTML

						if (s != "&nbsp;" && !s.startsWith('<')) {
							replaceAllCaracters(s)

							content += s;

							if (j < t[i].children.length) {
								content += ';'
							}
						}
					}
				}
				content += '\n';
			}
		}
		return content;
	}

	// ***************************************************************************************************************************************
	// Get data from html table
	// ***************************************************************************************************************************************
	static get_table_data(t, from_col=0)
	{
		let content = '';

		for (var i = 0; i < t.rows.length; i++)
		{
			 for (var j = from_col; j < t.rows[i].cells.length; j++)
			 {
				let s = t.rows[i].cells[j].outerText.trim();

				s = s.replaceAll('•', '-');
				s = s.replaceAll('\\', ' ');
				s = s.replaceAll(',', ' ');
				s = s.replaceAll(';', ' ');
				s = s.replaceAll('↑', '');
				s = s.replaceAll('↓', '');
				s = s.replaceAll('\n', ' ');

				content += s;

				if (j < t.rows[i].cells.length)
				{
					content += ';'
				}
			 }

			 content += '\n';
		}

		return content;
	}

	// ***************************************************************************************************************************************
	// Export to csv from html table header and table detail
	// ***************************************************************************************************************************************
	static export_to_csv(header, detail, from_col=0, tabulator=false)
	{
		let csv = '';

		if (tabulator) {
			csv = silicon_ioi.ioiCommon.get_tabulator_data(header, from_col);
			csv += silicon_ioi.ioiCommon.get_tabulator_data(detail, from_col);
		} else {
			csv = silicon_ioi.ioiCommon.get_table_data(header, from_col);
			csv += silicon_ioi.ioiCommon.get_table_data(detail, from_col);
		}

	   	csv = csv.replaceAll(' ', "%20")

		window.open('data:text/csv,' + csv);
	}

	// ***************************************************************************************************************************************
	// Export to csv from html table header and table detail
	// ***************************************************************************************************************************************
	static export_to_csv_raw(csv)
	{
		window.open('data:text/csv,' + csv);
	}

	// ***************************************************************************************************************************************
	// Show map
	// ***************************************************************************************************************************************
	static show_map(frm, html_field, timer, latitude, longitude)
	{
		if (document.getElementById('map'))
		{	document.getElementById('map').remove();
		}

		if (document.getElementById('map_spacer'))
		{	document.getElementById('map_spacer').remove();
		}

		if (document.getElementById('map_bt_div'))
		{	document.getElementById('map_bt_div').remove();
		}

		if (document.getElementById('bt_map_refresh'))
		{	document.getElementById('bt_map_refresh').remove();
		}

		if (document.getElementsByClassName('leaflet-control-container')[0])
		{	document.getElementsByClassName('leaflet-control-container')[0].remove();
		}

		let html = '';
		frm.fields_dict[html_field].$wrapper.empty();

		html += leaflet_script;

		html += '<div id="map" style="width:100%; height:300px; resize:vertical; z-index: 0"></div>';
		html += '<div id="map_spacer" style="width:100%; height:8px"></div>';

		html += '<div id="map_bt_div" style="width:110px;">';
		html +='	<button id="bt_map_refresh" class="btn btn-default ellipsis" style="height: 30px; width: 110px;" onclick="">' + __("Refresh") + '</button>';
		html += '</div>';


		frm.fields_dict[html_field].$wrapper.append(html);

		silicon_ioi.ioiCommon.sleep_static(timer).then(() => {
			if (window.L.map)
			{
				let map = window.L.map('map').setView([latitude, longitude], 13);

				L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
					maxZoom: 19,
					attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
				}).addTo(map);

				let marker = window.L.marker([latitude, longitude]).addTo(map);

				if (document.getElementById('bt_map_refresh'))
				{

					let fct_click = function () { silicon_ioi.ioiCommon.show_map(frm, html_field, 500, latitude, longitude); };
					document.getElementById('bt_map_refresh').onclick = fct_click;
				}
			}
		});
	}

	// ***************************************************************************************************************************************
	// Clear map
	// ***************************************************************************************************************************************
	static clear_map(frm, html_field)
	{
		frm.fields_dict[html_field].$wrapper.empty();
	}

	// ***************************************************************************************************************************************
	// Show map
	// ***************************************************************************************************************************************
	static show_map_detail(item, cdt, cdn, detail, html_field, timer, latitude, longitude)
	{
		if (cur_frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form)
		{
			if (cur_frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form.fields_dict[html_field])
			{
				cur_frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form.fields_dict[html_field].$wrapper.empty();

				if (document.getElementById('map'))
				{	document.getElementById('map').remove();
				}

				if (document.getElementById('map_spacer'))
				{	document.getElementById('map_spacer').remove();
				}

				if (document.getElementById('map_bt_div'))
				{	document.getElementById('map_bt_div').remove();
				}

				if (document.getElementById('bt_map_refresh'))
				{	document.getElementById('bt_map_refresh').remove();
				}

				if (document.getElementsByClassName('leaflet-control-container')[0])
				{	document.getElementsByClassName('leaflet-control-container')[0].remove();
				}

				let html = '';

				html += leaflet_script;

				html += '<div id="map" style="width:100%; height:300px; resize:vertical;"></div>';

				html += '<div id="map_spacer" style="width:100%; height:8px"></div>';

				html += '<div id="map_bt_div" style="width:110px;">';
				html +='	<button id="bt_map_refresh" class="btn btn-default ellipsis" style="height: 30px; width: 110px;" onclick="">' + __("Refresh") + '</button>';
				html += '</div>';


				cur_frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form.fields_dict[html_field].$wrapper.append(html);


				silicon_ioi.ioiCommon.sleep_static(timer).then(() => {

					let map = window.L.map('map').setView([latitude, longitude], 13);

					L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
						maxZoom: 19,
						attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
					}).addTo(map);

					let marker = window.L.marker([latitude, longitude]).addTo(map);

					let fct_click = function () { silicon_ioi.ioiCommon.show_map_detail(item, cdt, cdn, detail, html_field, 500, latitude, longitude); };

					document.getElementById('bt_map_refresh').onclick = fct_click;

				});

			}
		}
	}

	// ***************************************************************************************************************************************
	// Clear map
	// ***************************************************************************************************************************************
	static clear_map_detail(item, cdt, cdn, detail, html_field)
	{
		if (cur_frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form)
		{
			if (cur_frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form.fields_dict[html_field])
			{
				cur_frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form.fields_dict[html_field].$wrapper.empty();
			}

		}
	}



	// ***************************************************************************************************************************************
	// Get coodinates from address
	// ***************************************************************************************************************************************
	static get_coordinates_from_address(address, postal_code, city, country_id, callback)
	{
		if (cur_frm.doc.ioistatus)
		{
			if (cur_frm.doc.ioistatus != 0)
			{	return false;
			}
		}

		let format_address = '';

		if ((address) && (address.trim() != ''))
		{	format_address += address + ',';
		}

		if((postal_code) && (postal_code.trim() != ''))
		{	format_address += postal_code + ',';
		}

		if((city) && (city.trim() != ''))
		{	format_address += city + ',';
		}

		if((country_id) && (country_id.trim() != ''))
		{	format_address += country_id + ',';
		}

		if (format_address.trim() == '')
		{
			frappe.msgprint({title: __("Message"), message: __('Fill address, please'), indicator: "red"});
			raise;
		}

		let url = 'https://nominatim.openstreetmap.org/search?q=' + format_address + '&format=json&polygon=1&addressdetails=1';


		fetch(url)
			.then((response) => {

		  		if (!response.ok) {
					throw new Error(`HTTP error: ${response.status}`);
		  		}

			  	return response.text();
			})
			.then((text) => {
								let res = JSON.parse(text);

								if (res)
								{	if (res.length != 0)
									{
										if (res[0].lat)
										{
											let latitude = res[0].lat;
											let longitude = res[0].lon;

											callback(latitude, longitude);
										}
									}
								}

			})
			.catch((error) => {alert(error)});

	}

	static get_coordinates_from_address_detail(item, cdt, cdn, address, postal_code, city, country_id, callback)
	{
		if (item.ioistatus)
		{
			if (item.ioistatus != 0)
			{	return false;
			}
		}

		let format_address = '';

		if ((address) && (address.trim() != ''))
		{	format_address += address + ',';
		}

		if((postal_code) && (postal_code.trim() != ''))
		{	format_address += postal_code + ',';
		}

		if((city) && (city.trim() != ''))
		{	format_address += city + ',';
		}

		if((country_id) && (country_id.trim() != ''))
		{	format_address += country_id + ',';
		}

		if (format_address.trim() == '')
		{
			frappe.msgprint({title: __("Message"), message: __('Fill address, please'), indicator: "red"});
			raise;
		}


		let url = 'https://nominatim.openstreetmap.org/search?q=' + format_address + '&format=json&polygon=1&addressdetails=1';


		fetch(url)
			.then((response) => {

		  		if (!response.ok) {
					throw new Error(`HTTP error: ${response.status}`);
		  		}

			  	return response.text();
			})
			.then((text) => {
								let res = JSON.parse(text);

								if (res)
								{	if (res.length != 0)
									{
										if (res[0].lat)
										{
											let latitude = res[0].lat;
											let longitude = res[0].lon;

											callback(item, cdt, cdn, latitude, longitude);
										}
									}
								}
			})
			.catch((error) => {alert(error)});

	}


	// ***************************************************************************************************************************************
	// Get Address from coordinates
	// ***************************************************************************************************************************************
	static get_address_from_coordinates(latitude, longitude, callback)
	{
		if (cur_frm.doc.ioistatus)
		{
			if (cur_frm.doc.ioistatus != 0)
			{	return false;
			}
		}

		if (((!latitude) || ((latitude) && (latitude.toString().trim() == ''))) ||
			((!longitude) || ((longitude) && (longitude.toString().trim() == ''))))
		{
			frappe.msgprint({title: __("Message"), message: __('Fill latitude and longitude, please'), indicator: "red"});
			raise;
		}

		let url = 'https://nominatim.openstreetmap.org/reverse?lat=' + latitude.toString() + '&lon=' + longitude.toString() + '&format=json&polygon=1&addressdetails=1';

		fetch(url)
			.then((response) => {

		  		if (!response.ok) {
					throw new Error(`HTTP error: ${response.status}`);
		  		}

			  	return response.text();
			})
			.then((text) => {  	let res = JSON.parse(text);

								if (res)
								{	if (res.length != 0)
									{
										if (res.address.road)
										{
											let address = res.address.road;
											let city = res.address.city_district;
											let postcode = res.address.postcode;
											let countrycode = res.address.country_code;
											countrycode = countrycode.toUpperCase();

											callback(address, postcode, city, countrycode);
										}
									}
								}


			})
			.catch((error) => {alert(error)});
	}

	static get_address_from_coordinates_detail(item, cdt, cdn, latitude, longitude, callback)
	{
		if (item.ioistatus)
		{
			if (item.ioistatus != 0)
			{	return false;
			}
		}

		if (((!latitude) || ((latitude) && (latitude.toString().trim() == ''))) ||
			((!longitude) || ((longitude) && (longitude.toString().trim() == ''))))
		{
			frappe.msgprint({title: __("Message"), message: __('Fill latitude and longitude, please'), indicator: "red"});
			raise;
		}

		let url = 'https://nominatim.openstreetmap.org/reverse?lat=' + latitude.toString() + '&lon=' + longitude.toString() + '&format=json&polygon=1&addressdetails=1';

		fetch(url)
			.then((response) => {

		  		if (!response.ok) {
					throw new Error(`HTTP error: ${response.status}`);
		  		}

			  	return response.text();
			})
			.then((text) => {  	let res = JSON.parse(text);

							if (res)
							{	if (res.length != 0)
								{
									if (res.address.road)
									{
										let address = res.address.road;
										let city = res.address.city_district;
										let postcode = res.address.postcode;
										let countrycode = res.address.country_code;
										countrycode = countrycode.toUpperCase();

										callback(item, cdt, cdn, address, postcode, city, countrycode);
									}
								}
							}

			})
			.catch((error) => {alert(error)});
	}

	static async blink_error_field(field){
		let fld;

		if (typeof(field) == 'string'){
			fld = cur_frm.fields_dict[field]
		}else{
			fld = field
		}

		if (fld && fld.$wrapper){
			let idx = 0;
			const max_blink = 5;
			while (idx < (max_blink*2)){
				if (idx % 2 == 0){
					fld.$wrapper.addClass('has-error');
				}else{
					fld.$wrapper.removeClass('has-error');
				}
				idx++;
				await silicon_ioi.ioiCommon.select_sleep(400);
			}
		}
	}

	static is_accept_manual_labelling(doctype){
		return (frappe.db.get_value('ioi Module',doctype, 'manual_labelling')).then((r) => {return r.message?.manual_labelling == 1});
	}

	static add_custom_action_in_page(page, icon, click, css_class = "", tooltip_label) {
		const button = $(`
			<button class="text-muted btn btn-default ${css_class} icon-btn">
				<i class="fa ${icon}"></i>
			</button>
		`);

		button.appendTo(page.icon_group.removeClass("hide"));
		button.click(click);
		button
			.attr("title", __(tooltip_label || frappe.unscrub(icon)))
			.tooltip({ delay: { show: 600, hide: 100 }, trigger: "hover" });

		return button;

	}

	static show_grid_info_popup(html, close_after = 5800) {
		let div = $("#grid_info_popup");

		if (!div.length) {
			if (!$("#dialog-container").length) {
				$('<div id="dialog-container"><div id="alert-container"></div></div>').appendTo("body");
			}

			div = $(`
					<div class="alert desk-alert border" id="grid_info_popup" role="alert" style="width:100%;">
						<div class="alert-message-container">
							<div class="alert-title-container">
								<div class="alert-message m-1">${html}</div>
							</div>
							<div class="alert-subtitle"></div>
						</div>
						<div class="alert-body" style="display: none"></div>
						<a class="close">${frappe.utils.icon("close-alt")}</a>
					</div>
				`);

			div.hide().appendTo("#alert-container").show();

			div.find(".close, button").click(function () {
				div.addClass("out");
				setTimeout(() => div.remove(), 800);
				return false;
			});

			div.on("mouseenter", function() {
				clearTimeout(cur_frm.timeout_out);
				clearTimeout(cur_frm.timeout_remove_el);
			});

			div.on("mouseleave", function() {
				cur_frm.timeout_out = setTimeout(() => {
					div.addClass("out");
					return false;
				}, 5000);

				cur_frm.timeout_remove_el = setTimeout(() => div.remove(), close_after);
			});

		} else {
			div.find('.alert-message').html(html);
		}

		if (cur_frm.timeout_out) clearTimeout(cur_frm.timeout_out);
		if (cur_frm.timeout_remove_el) clearTimeout(cur_frm.timeout_remove_el);

		cur_frm.timeout_out = setTimeout(() => {
			div.addClass("out");
			return false;
		}, close_after);

		cur_frm.timeout_remove_el = setTimeout(() => div.remove(), close_after);

		return div
	}

	static onclick_analyse_quote_order() {
		const child_table_name = cur_frm.doctype === "ioi Sales Quote" ? "ioi_sales_quote_analysis" : "ioi_sales_order_analysis"

		cur_frm.set_value(child_table_name, [])

		const quotes = cur_frm.doc.line_detail.filter(el => el.quoted_qty !== 0)

		const sort_and_group = (quotes) => {
			const new_array = [];

			const discard_section = cur_frm.doc.discard_section;
			const discard_subsection = cur_frm.doc.discard_subsection;
			const discard_option = child_table_name === "ioi_sales_quote_analysis" ? cur_frm.doc.discard_option : 1

			quotes.forEach(object => {
				let grouping_fields = { section: object.section, subsection: object.subsection, option_id: object.option_id, item_budget_id: object.item_budget_id };

				if (discard_section === 1) delete grouping_fields.section;
				if (discard_subsection === 1) delete grouping_fields.subsection;
				if (discard_option === 1) delete grouping_fields.option_id;

				const group_key = JSON.stringify(grouping_fields);

				let group = new_array.find(group => JSON.stringify({ section: group.section, subsection: group.subsection, option_id: group.option_id, item_budget_id: group.item_budget_id }) === group_key);

				if (!group) {
					group = { ...grouping_fields, data: [] };
					new_array.push(group);
				}

				group.data.push(object);
			});

			return new_array;
		};

		const group_quotes = sort_and_group(quotes);

		group_quotes.forEach(group => {
			let child = cur_frm.add_child(child_table_name)

			// Populate childs

			child.section = group.section
			child.subsection = group.subsection
			child.item_budget_id = group.item_budget_id

			if (child_table_name === "ioi_sales_quote_analysis") child.option_id = group.option_id

			let total_cost = 0;
			let total_std_cost = 0;
			let net_total_sale_price = 0;
			let exist_null_cost = 0;
			let exist_manual_cost = 0;
			let exist_null_std_cost = 0;
			let exist_null_price = 0;

			const get_total_cost = (manual_cost, cost_value, total_supplier_price, quoted_qty, ordered_qty) => {
				manual_cost = parseFloat(manual_cost)
				cost_value = parseFloat(cost_value)
				total_supplier_price = parseFloat(total_supplier_price)
				quoted_qty = parseFloat(quoted_qty)
				ordered_qty = parseFloat(ordered_qty)

				if (manual_cost != 0) {
					return manual_cost * (child_table_name === "ioi_sales_quote_analysis" ? quoted_qty : ordered_qty)
				} else if (manual_cost == 0) {
					if (cost_value > total_supplier_price) {
						return cost_value
					} else if (total_supplier_price > cost_value) {
						return total_supplier_price
					}
				}
			}

			group.data.forEach(el => {
				total_cost += get_total_cost(el.manual_cost, el.cost_value, el.total_supplier_price, el.quoted_qty, el.ordered_qty)
				total_std_cost += (el.item_cost_std_stamp ? parseFloat(el.item_cost_std_stamp) : 0);
				net_total_sale_price += (el.value_line_doc_currency ? parseFloat(el.value_line_doc_currency) : 0);

				exist_null_cost = el.cost_value === 0 ? 1 : exist_null_cost;
				exist_manual_cost = el.manual_cost !== 0 ? 1 : exist_manual_cost;
				exist_null_std_cost = el.item_cost_std_stamp === 0 ? 1 : exist_null_std_cost;
				exist_null_price = el.value_line_doc_currency === 0 ? 1 : exist_null_price;
			});

			child.total_cost = total_cost;
			child.total_std_cost = total_std_cost;
			child.net_total_sale_price = net_total_sale_price;

			child.exist_null_cost = exist_null_cost;
			child.exist_manual_cost = exist_manual_cost;
			child.exist_null_std_cost = exist_null_std_cost;
			child.exist_null_price = exist_null_price;

			child.margin_alert = group.data.find(el => el.margin_alert && el.margin_alert !== "");

			if (child.total_cost === 0) {
				child.cost_margin_error = 1
			} else {
				child.cost_margin = (child.net_total_sale_price / child.total_cost - 1) * 100
			}

			if (child.net_total_sale_price === 0) {
				child.sales_margin_error = 1
			} else {
				child.sales_margin = (1 - child.total_cost / child.net_total_sale_price) * 100
			}
		})

		cur_frm.refresh_field(child_table_name)
	}

	static add_dropdown_prefix(tp, doctype) {

		if ((doctype.toUpperCase() == 'IOI SALES QUOTE') || (doctype.toUpperCase() == 'IOI SALES ORDER') || (doctype.toUpperCase() == 'IOI SALES DELIVERY') || (doctype.toUpperCase() == 'IOI SALES INVOICE') ||
			(doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') || (doctype.toUpperCase() == 'IOI PURCHASES ORDER') || (doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (doctype.toUpperCase() == 'IOI PURCHASES INVOICE') ||
			(doctype.toUpperCase() == 'IOI STOCK ENTRY') || (doctype.toUpperCase() == 'IOI STOCK OUTPUT') || (doctype.toUpperCase() == 'IOI STOCK TRANSFER') || (doctype.toUpperCase() == 'IOI STOCK QUALIFICATION') || (doctype.toUpperCase() == 'IOI STOCK INVENTORY') ||
			(doctype.toUpperCase() == 'IOI DOSSIER') || (doctype.toUpperCase() == 'IOI PRODUCTION') || (doctype.toUpperCase() == 'IOI SUBSCRIPTION')) {

			if (document.getElementById('global_document_prefix_drowpdown_' + tp.toLowerCase())) {
				document.getElementById('global_document_prefix_drowpdown_' + tp.toLowerCase()).remove()
			}

			let method = '';

			if (doctype.toUpperCase() == 'IOI SALES QUOTE') {
				method = 'silicon_ioi.ioi_sales.doctype.ioi_sales_quote.ioi_sales_quote.ioi_sales_quote_get_prefixes_for_module_list';
			}else if (doctype.toUpperCase() == 'IOI SALES ORDER') {
				method = 'silicon_ioi.ioi_sales.doctype.ioi_sales_order.ioi_sales_order.ioi_sales_order_get_prefixes_for_module_list';
			}else if (doctype.toUpperCase() == 'IOI SALES DELIVERY') {
				method = 'silicon_ioi.ioi_sales.doctype.ioi_sales_delivery.ioi_sales_delivery.ioi_sales_delivery_get_prefixes_for_module_list';
			}else if (doctype.toUpperCase() == 'IOI SALES INVOICE') {
				method = 'silicon_ioi.ioi_sales.doctype.ioi_sales_invoice.ioi_sales_invoice.ioi_sales_invoice_get_prefixes_for_module_list';
			}else if (doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') {
				method = 'silicon_ioi.ioi_purchases.doctype.ioi_purchases_price_request.ioi_purchases_price_request.ioi_purchases_price_request_get_prefixes_for_module_list';
			}else if (doctype.toUpperCase() == 'IOI PURCHASES ORDER') {
				method = 'silicon_ioi.ioi_purchases.doctype.ioi_purchases_order.ioi_purchases_order.ioi_purchases_order_get_prefixes_for_module_list';
			}else if (doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') {
				method = 'silicon_ioi.ioi_purchases.doctype.ioi_purchases_receipt.ioi_purchases_receipt.ioi_purchases_receipt_get_prefixes_for_module_list';
			}else if (doctype.toUpperCase() == 'IOI PURCHASES INVOICE') {
				method = 'silicon_ioi.ioi_purchases.doctype.ioi_purchases_invoice.ioi_purchases_invoice.ioi_purchases_invoice_get_prefixes_for_module_list';
			}else if (doctype.toUpperCase() == 'IOI STOCK ENTRY') {
				method = 'silicon_ioi.ioi_wms.doctype.ioi_stock_entry.ioi_stock_entry.ioi_stock_entry_get_prefixes_for_module_list';
			}else if (doctype.toUpperCase() == 'IOI STOCK OUTPUT') {
				method = 'silicon_ioi.ioi_wms.doctype.ioi_stock_output.ioi_stock_output.ioi_stock_output_get_prefixes_for_module_list';
			}else if (doctype.toUpperCase() == 'IOI STOCK TRANSFER') {
				method = 'silicon_ioi.ioi_wms.doctype.ioi_stock_transfer.ioi_stock_transfer.ioi_stock_transfer_get_prefixes_for_module_list';
			}else if (doctype.toUpperCase() == 'IOI STOCK QUALIFICATION') {
				method = 'silicon_ioi.ioi_wms.doctype.ioi_stock_qualification.ioi_stock_qualification.ioi_stock_qualification_get_prefixes_for_module_list';
			}else if (doctype.toUpperCase() == 'IOI STOCK INVENTORY') {
				method = 'silicon_ioi.ioi_wms.doctype.ioi_stock_inventory.ioi_stock_inventory.ioi_stock_inventory_get_prefixes_for_module_list';
			}else if (doctype.toUpperCase() == 'IOI DOSSIER') {
				method = 'silicon_ioi.ioi_trakker.doctype.ioi_dossier.ioi_dossier.ioi_dossier_get_prefixes_for_module_list';
			}else if (doctype.toUpperCase() == 'IOI PRODUCTION') {
				method = 'silicon_ioi.ioi_production.doctype.ioi_production.ioi_production.ioi_production_get_prefixes_for_module_list';
			}else if (doctype.toUpperCase() == 'IOI SUBSCRIPTION') {
				method = 'silicon_ioi.ioi_park_manager.doctype.ioi_subscription.ioi_subscription.ioi_subscription_get_prefixes_for_module_list';
			}

			let html = '';

			html += '<select id="global_document_prefix_drowpdown_' + tp.toLowerCase() + '" class="input-with-feedback form-control bold;" style="width:180px; height: 27px"> ';

			let is_multi_prefix = false

			frappe.call({  	method: method,
							args: {},
							async: false,
							callback:function(r)	{
								if (r.message.length > 1)
									is_multi_prefix = true

									html += '<option value="">' + __("All") + '</option> ';

									for (var i = 0; i < r.message.length; i++) {
												html += '<option value="' + r.message[i].prefix + '">' + r.message[i].prefix + '</option> ';
									}
								}
			});
			html += '</select> ';

			// If more than one prefix, build select
			if (is_multi_prefix) {

				cur_page.page.page.custom_actions[0].insertAdjacentHTML('afterbegin', html);

				silicon_ioi.ioiCommon.sleep_static(200).then(() => {

					let fct_change = function ()
					{
						let method = 'silicon_ioi.ioi_system.doctype.ioi_user_module_params.ioi_user_module_params.ioi_user_module_params_update'

						frappe.call({
							method: method,
							args: { "doctype": doctype,
									"prefix_id": document.getElementById('global_document_prefix_drowpdown_' + tp.toLowerCase()).value
							},
							async: false,
							callback:function(r)	{

								if (tp != 'F') {
									window.location.reload();
								}
							}
						});

					}

					document.getElementById('global_document_prefix_drowpdown_' + tp.toLowerCase()).onchange = fct_change;


					if (document.getElementById('global_document_prefix_drowpdown_' + tp.toLowerCase()).options.length > 0) {

						let prefix_id = ''

						let method = 'silicon_ioi.ioi_system.doctype.ioi_user_module_params.ioi_user_module_params.ioi_user_module_params_get_prefix'

						frappe.call({
							method: method,
							args: { "doctype": doctype

							},
							async: false,
							callback:function(r)	{

								prefix_id = r.message;
							}
						});

						for (var i = 0; i < document.getElementById('global_document_prefix_drowpdown_' + tp.toLowerCase()).options.length; i++) {
							if (document.getElementById('global_document_prefix_drowpdown_' + tp.toLowerCase()).options[i].value == prefix_id) {
								document.getElementById('global_document_prefix_drowpdown_' + tp.toLowerCase()).options[i].selected = true;
								break;
							}
						}
					}
				});
			}
		}
	}

	static import_xlsx_in_child_detail(childname)
	{
		if ((cur_frm.is_new()) || (cur_frm.is_dirty())) {
			let fct_callback = function () { silicon_ioi.ioiCommon.execute_import_xlsx_in_child_detail(childname); };
			cur_frm.save('Save', fct_callback);
		}else {
			silicon_ioi.ioiCommon.execute_import_xlsx_in_child_detail(childname);
		}
	}

	static execute_import_xlsx_in_child_detail(childname)
	{
		if ((cur_frm.doc.ioistatus) && (cur_frm.doc.ioistatus != 0)) {
			frappe.msgprint({ title: __("Message"), message: __('The document has to be in Preparation'), indicator: "red" });
			return false;
		}

		if ((!childname) || ((childname) && (childname.trim() == ''))) {
			frappe.msgprint({ title: __("Message"), message: __('childname is mandatory'), indicator: "red" });
			return false;
		}

		let method = 'silicon_ioi.common.common.ioi_check_if_field_exists_in_doctype';

		let data = {};

		frappe.call({
			method: method,
			args: {
				"doctype": cur_frm.doctype,
				"fieldname": childname
			},
			async: false,
			callback:function(r)	{
				data = r.message;
			}
		});

		if (data.error == 1) {
			frappe.msgprint({ title: __("Message"), message: __('This childname does not exist in this doctype'), indicator: "red" });
			return false;
		}

		childname = data.fieldname;

		let childdoctype = data.childdoctype;

		let title = __("Import xlsx in the detail")

		if ((childname.toUpperCase() == 'BOM_DETAIL') || (childname.toUpperCase() == 'ROUTING_DETAIL')) {

			if (childname.toUpperCase() == 'BOM_DETAIL') {
				title = __("Import xlsx in BOM detail");
			}else{
				title = __("Import xlsx in Routing detail");
			}

		}


		var importXlsxFrm = new frappe.ui.Dialog({
			'title': title,
			'static': true,
			'fields': [
				{'fieldname': 'html_common_import_xlsx', 'fieldtype': 'HTML'}
			],
			primary_action_label: __('Import now'),
			secondary_action_label: __('Cancel'),
			primary_action: function() {

				document.getElementById('html_common_import_xlsx_error_label').innerText = '';

				let button_ok = cur_dialog.footer[0].getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[0];

				button_ok.disabled = true;

				if (document.getElementById('html_common_import_xlsx_file').value.trim() == '') {

					button_ok.disabled = false;
					document.getElementById('html_common_import_xlsx_error_label').style.color = 'red';
					document.getElementById('html_common_import_xlsx_error_label').innerText = __("Select the xls file, please");
					return false;
				}

				if ((!document.getElementById('html_common_import_xlsx_max_lines').value) || ((document.getElementById('html_common_import_xlsx_max_lines').value) && (parseInt(document.getElementById('html_common_import_xlsx_max_lines').value) <= 0))) {
					button_ok.disabled = false;
					document.getElementById('html_common_import_xlsx_error_label').style.color = 'red';
					document.getElementById('html_common_import_xlsx_error_label').innerText = __("Max rows allowed has to be greater than zero");
					return false;
				}

				let top = 10;

				let msg = '';

				let s = __('The import could take a long time. Are you sure to import now ?');

				msg += '<label style="position: absolute; top: ' + (top+10).toString() + 'px; left: 80px;"> ' + s + '</label>';

				top += 20;
				msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;" align="center">' + msg + '</div>';


				frappe.confirm(	msg,
								() => 	{	silicon_ioi.ioiCommon.sleep_static(200).then(() => {
												silicon_ioi.ioiCommon.do_import_xlsx_in_child_detail(importXlsxFrm, childname, childdoctype);
											});
										},
								() => 	{
											button_ok.disabled = false;
										}
				);


			},
			secondary_action: function(){

				importXlsxFrm.hide();
			}

		});

		if (document.getElementById('html_common_import_xlsx_file_label')) {
			document.getElementById('html_common_import_xlsx_file_label').remove();
		}

		if (document.getElementById('html_common_import_xlsx_file')) {
			document.getElementById('html_common_import_xlsx_file').remove();
		}

		if (document.getElementById('html_common_import_xlsx_file_button_select')) {
			document.getElementById('html_common_import_xlsx_file_button_select').remove();
		}

		if (document.getElementById('html_common_import_xlsx_file_button_clear')) {
			document.getElementById('html_common_import_xlsx_file_button_clear').remove();
		}

		if (document.getElementById('html_common_import_xlsx_delete_child_label')) {
			document.getElementById('html_common_import_xlsx_delete_child_label').remove();
		}

		if (document.getElementById('html_common_import_xlsx_delete_child')) {
			document.getElementById('html_common_import_xlsx_delete_child').remove();
		}

		if (document.getElementById('html_common_import_xlsx_only_field_to_field_label')) {
			document.getElementById('html_common_import_xlsx_only_field_to_field_label').remove();
		}

		if (document.getElementById('html_common_import_xlsx_only_field_to_field')) {
			document.getElementById('html_common_import_xlsx_only_field_to_field').remove();
		}

		if (document.getElementById('html_common_import_xlsx_replace_label')) {
			document.getElementById('html_common_import_xlsx_replace_label').remove();
		}
		
		if (document.getElementById('html_common_import_xlsx_replace')) {
			document.getElementById('html_common_import_xlsx_replace').remove();
		}		

		if (document.getElementById('html_common_import_xlsx_no_replace_for_label')) {
			document.getElementById('html_common_import_xlsx_no_replace_for_label').remove();
		}
		
		if (document.getElementById('html_common_import_xlsx_no_replace_for')) {
			document.getElementById('html_common_import_xlsx_no_replace_for').remove();
		}		


		if (document.getElementById('html_common_import_xlsx_max_lines_label')) {
			document.getElementById('html_common_import_xlsx_max_lines_label').remove();
		}

		if (document.getElementById('html_common_import_xlsx_max_lines')) {
			document.getElementById('html_common_import_xlsx_max_lines').remove();
		}

		if (document.getElementById('html_common_import_xlsx_remarks_label')) {
			document.getElementById('html_common_import_xlsx_remarks_label').remove();
		}

		if (document.getElementById('html_common_import_xlsx_remark0_label')) {
			document.getElementById('html_common_import_xlsx_remark0_label').remove();
		}

		if (document.getElementById('html_common_import_xlsx_remark1_label')) {
			document.getElementById('html_common_import_xlsx_remark1_label').remove();
		}

		if (document.getElementById('html_common_import_xlsx_remark2_label')) {
			document.getElementById('html_common_import_xlsx_remark2_label').remove();
		}

		if (document.getElementById('html_common_import_xlsx_remark3_label')) {
			document.getElementById('html_common_import_xlsx_remark3_label').remove();
		}

		if (document.getElementById('html_common_import_xlsx_error_label')) {
			document.getElementById('html_common_import_xlsx_error_label').remove();
		}


		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:375px; width: 100%;">';


		// XLSX File
		html += '	<div style="position: relative; top: 0px; left: 2px; width:428px;">';
		html += '		<b><label id="html_common_import_xlsx_file_label" style="position: absolute; top: 0px; left: 0px;z-index:5;">' + __("File") + ' (xlsx)' + '</label></b>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 600px; height: 25px;"> ';
		html += '			<input id="html_common_import_xlsx_file" type="text" class="input-with-feedback form-control bold" value="" disabled>';
		html += '		</div>';
		html += '		<div style="position: absolute; top:25px; left: 610px; height: 35px">';
		html +='			<button id="html_common_import_xlsx_file_button_select" class="btn btn-default ellipsis" style="height: 30px; width: 50px;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '		<div style="position: absolute; top:25px; left: 670px; height: 35px">';
		html +='			<button id="html_common_import_xlsx_file_button_clear" class="btn btn-default ellipsis" style="height: 30px; width: 100px;" onclick="">' + __("Clear") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Empty child detail before insert
		html += '	<div style="position: relative; top: 65px; left: 2px; width:428px;">';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 0px; width: 600px; height: 25px;"> ';
		html += '			<input id="html_common_import_xlsx_delete_child" type="checkbox" class="input-with-feedback form-control bold">';
		html += '		</div>';
		html += '		<label id="html_common_import_xlsx_delete_child_label" style="position: absolute; top: 0px; left: 20px;z-index:5;">' + __("Empty the detail before insert") + '</label>';
		html += '	</div>';

		// Only field to field
		html += '	<div style="position: relative; top: 95px; left: 2px; width:428px;">';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 0px; width: 600px; height: 25px;"> ';
		html += '			<input id="html_common_import_xlsx_only_field_to_field" type="checkbox" class="input-with-feedback form-control bold">';
		html += '		</div>';
		html += '		<label id="html_common_import_xlsx_only_field_to_field_label" style="position: absolute; top: 0px; left: 20px;z-index:5;">' + __("Import field to field only (no populate)") + '</label>';
		html += '	</div>';

		// Replace '/' by ' • '
		html += '	<div style="position: relative; top: 125px; left: 2px; width:428px;">';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 0px; width: 600px; height: 25px;"> ';
		html += '			<input id="html_common_import_xlsx_replace" type="checkbox" class="input-with-feedback form-control bold">';
		html += '		</div>';
		html += '		<label id="html_common_import_xlsx_replace_label" style="position: absolute; top: 0px; left: 20px;z-index:5;">' + __("Replace '/' by ' • ' in key/link fields") + '</label>';
		html += '	</div>';	
		
		// No replace for
		html += '	<div style="position: relative; top: 150px; left: 22px; width:500px;">';
		html += '		<label id="html_common_import_xlsx_no_replace_for_label" style="position: absolute; top: 0px; left: 0px;z-index:5;"><u>' + __("Not replace for") + '</u> ' + __("(fields separated by ; )") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 750px; height: 25px;"> ';
		html += '			<input id="html_common_import_xlsx_no_replace_for" type="text"class="input-with-feedback form-control bold" value="">';
		html += '		</div>';
		html += '	</div>';		

		// Max rows allowed
		html += '	<div style="position: relative; top: 65px; left: 477px; width:150px;">';
		html += '		<label id="html_common_import_xlsx_max_lines_label" style="position: absolute; top: 0px; left: 0px;z-index:5;">' + __("Max rows allowed") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 125px; height: 25px;"> ';
		html += '			<input id="html_common_import_xlsx_max_lines" type="number" step="any" class="input-with-feedback form-control bold" value="">';
		html += '		</div>';
		html += '	</div>';

		// Remarks
		html += '	<div style="position: relative; top: 210px; left: 2px; width:428px;">';
		html += '		<label id="html_common_import_xlsx_remarks_label" style="position: absolute; top: 0px; left: 0px;z-index:5;"><u><b>' + __("Remarks") + '</b></u></label>';
		html += '	</div>';

		// Remark 0
		html += '	<div style="position: relative; top: 235px; left: 2px; width:650px;">';
		html += '		<label id="html_common_import_xlsx_remark0_label" style="position: absolute; top: 0px; left: 0px;z-index:5;color:blue">' + __("First xlsx row must contain internal fieldname. Use ALT+click on the doc field to see fieldname") + '</label>';
		html += '	</div>';		

		// Remark 1
		html += '	<div style="position: relative; top: 260px; left: 2px; width:600px;">';
		html += '		<label id="html_common_import_xlsx_remark1_label" style="position: absolute; top: 0px; left: 0px;z-index:5;color:blue">' + __("Put the fields (in xlsx file) in the correct order as if you were encoding manually") + '</label>';
		html += '	</div>';

		// Remark 2
		html += '	<div style="position: relative; top: 285px; left: 2px; width:600px;">';
		html += '		<label id="html_common_import_xlsx_remark2_label" style="position: absolute; top: 0px; left: 0px;z-index:5;color:blue">' + __("The import could take a long time regarding the number of rows in the xlsx file") + '</label>';
		html += '	</div>';

		// Remark 3
		html += '	<div style="position: relative; top: 310px; left: 2px; width:600px;">';
		html += '		<label id="html_common_import_xlsx_remark3_label" style="position: absolute; top: 0px; left: 0px;z-index:5;color:blue">' + __("if the max rows allowed is reached, a new document will be created, and so on ...") + '</label>';
		html += '	</div>';

		// Error
		html += '	<div style="position: relative; top: 335px; left: 2px; width:760px;" align="center">';
		html += '		<label id="html_common_import_xlsx_error_label" style="color:red"></label>';
		html += '	</div>';

		html += '</div>';

		let action_get_xlsx_template = function() {
			silicon_ioi.ioiCommon.import_get_xlsx_template(childname);
		}

		importXlsxFrm.add_custom_action(__("Get xlsx template"), action_get_xlsx_template);


		importXlsxFrm.fields_dict.html_common_import_xlsx.$wrapper.html(html);
		importXlsxFrm.$wrapper.find('.modal-dialog').css("max-width", "820px").css("width", "820px");
		importXlsxFrm.$wrapper.find('.modal-dialog').css("max-height", "450px").css("height", "450px");
		importXlsxFrm.show();


		silicon_ioi.ioiCommon.sleep_static(200).then(() => {

			let max_rows_allowed = 250

			let method = 'silicon_ioi.common.common.ioi_import_get_max_rows_allowed';

			let data = {}

			frappe.call({
				method: method,
				args: {
					"doctype": cur_frm.doctype,
					"name": cur_frm.doc.name,

				},
				async: false,
				callback:function(r)	{
					max_rows_allowed = r.message;

				}
			});

			document.getElementById('html_common_import_xlsx_max_lines').value = max_rows_allowed;




			let fct_select_file = function () {
				silicon_ioi.ioiCommon.import_xlsx_select_file();
			}

			document.getElementById('html_common_import_xlsx_file_button_select').onclick = fct_select_file;

			let fct_clear_file = function () {
				silicon_ioi.ioiCommon.import_xlsx_clear_file();
			}

			document.getElementById('html_common_import_xlsx_file_button_clear').onclick = fct_clear_file;

			let fct_click_empty = function() {
				document.getElementById('html_common_import_xlsx_delete_child').checked = !document.getElementById('html_common_import_xlsx_delete_child').checked;
			}

			document.getElementById('html_common_import_xlsx_delete_child_label').onclick = fct_click_empty;


			let fct_click_field_to_field = function() {
				document.getElementById('html_common_import_xlsx_only_field_to_field').checked = !document.getElementById('html_common_import_xlsx_only_field_to_field').checked;
			}

			document.getElementById('html_common_import_xlsx_only_field_to_field_label').onclick = fct_click_field_to_field;

			let fct_click_replace = function() {
				document.getElementById('html_common_import_xlsx_replace').checked = !document.getElementById('html_common_import_xlsx_replace').checked;
			}

			document.getElementById('html_common_import_xlsx_replace_label').onclick = fct_click_replace;			

			let fct_keyup_no_replace = function() {

				if ((document.getElementById('html_common_import_xlsx_no_replace_for').value) && (document.getElementById('html_common_import_xlsx_no_replace_for').value.trim() != '')) {
					document.getElementById('html_common_import_xlsx_replace').checked = true;
				}else{
					document.getElementById('html_common_import_xlsx_replace').checked = false;
				}
			}

			document.getElementById('html_common_import_xlsx_no_replace_for').onkeyup = fct_keyup_no_replace;
		});
	}

	static do_import_xlsx_in_child_detail(importXlsxFrm, childname, childdoctype)
	{
		let button_ok = cur_dialog.footer[0].getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[0];
		button_ok.disabled = false;

		document.getElementById('html_common_import_xlsx_error_label').style.color = 'green';
		document.getElementById('html_common_import_xlsx_error_label').innerText = __("Import in progress, please wait ...");

		silicon_ioi.ioiCommon.sleep_static(200).then(() => {

			let cb_delete = 0

			if (document.getElementById('html_common_import_xlsx_delete_child').checked) {
				cb_delete = 1
			}

			let fieldtofield_only = 0

			if (document.getElementById('html_common_import_xlsx_only_field_to_field').checked) {
				fieldtofield_only = 1
			}

			let cb_replace = 0;

			if (document.getElementById('html_common_import_xlsx_replace').checked) {
				cb_replace = 1
			}

			let no_replace_for = []

			if ((document.getElementById('html_common_import_xlsx_no_replace_for').value) && (document.getElementById('html_common_import_xlsx_no_replace_for').value.trim() != '')) {

				let s = document.getElementById('html_common_import_xlsx_no_replace_for').value;

				while (s.trim() != '') {
					if (s.indexOf(';') != -1) {
						
						let element = s.substring(0, s.indexOf(';'));

						if (element.trim() != '') {
							no_replace_for[no_replace_for.length] = element.trim().toLowerCase()
						}

						s = s.substring(s.indexOf(';')+1, s.length)

					}else{
						if (s.trim() != '') {
							no_replace_for[no_replace_for.length] = s.trim().toLowerCase()
							s = '';
						}
					}
				}
			}

			let method = 'silicon_ioi.common.common.ioi_import_xlsx_in_detail';

			let data = {}

			frappe.call({
				method: method,
				args: {
					"doctype": cur_frm.doctype,
					"name": cur_frm.doc.name,
					"childname": childname,
					"data_file" : document.getElementById('html_common_import_xlsx_file').value,
					"delete_before": cb_delete,
					"fieldtofield_only": fieldtofield_only,
					"replace_slash": cb_replace,
					"no_replace_for": no_replace_for,
					"max_lines": document.getElementById('html_common_import_xlsx_max_lines').value
				},
				async: false,
				callback:function(r)	{
					data = r.message;

				}
			});

			if (data.error == 1) {

				button_ok.disabled = false;
				document.getElementById('html_common_import_xlsx_error_label').style.color = 'red';
				document.getElementById('html_common_import_xlsx_error_label').innerText = data.error_msg;
				return false;

			}else{
				importXlsxFrm.hide();

				if (data.documents.length > 0) {

					let msg = __("Others documents created") + ': ';

					for (var i = 0; i < data.documents.length; i++) {
						msg += data.documents[i];

						if (i <  data.documents.length-1) {
							msg += ', '
						}
					}

					frappe.msgprint({title: __("Message"), message: msg, indicator: "blue"});
				}
			}
		});

	}

	static import_xlsx_select_file()
	{

		let format1 = '.xlsx';

		new frappe.ui.FileUploader({
									folder: "Home/Attachments",
									doctype: cur_frm.doc.doctype,
									docname: cur_frm.doc.name,
									force_private: false,
									allow_multiple: false,
									restrictions: {
										allowed_file_types: [format1],
									},

									on_success_terminated: (attachments) => {

										let destination_files = []

										for (var i = 0; i < attachments.length; i++) {

											if (attachments[i].final_doc_obj.file_name.toUpperCase().indexOf('.XLS') == -1) {
												frappe.msgprint({title: __("Message"), message: __('File format has to be') + ' XLSX', indicator: "red"});
												return false;
											}

											destination_files[destination_files.length] = attachments[i].final_doc_obj.file_url;

										}

										let button_ok = cur_dialog.footer[0].getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[0];

										if (destination_files.length == 0) {
											button_ok.disabled = false;

											frappe.msgprint({title: __("Message"), message: __('Please select a file'), indicator: "red"});
											return false;

										}

										document.getElementById('html_common_import_xlsx_file').value = destination_files[0];
									},
									make_attachments_public: cur_frm.meta.make_attachments_public

		});
	}

	static import_xlsx_clear_file()
	{
		document.getElementById('html_common_import_xlsx_file').value = '';
	}

	static import_get_xlsx_template(childname)
	{
		let title = __("Get xlsx template");

		var templateXlsxFrm = new frappe.ui.Dialog({
			'title': title,
			'static': true,
			'fields': [
				{'fieldname': 'html_common_template_xlsx', 'fieldtype': 'HTML'}
			],
			primary_action_label: __('Ok'),
			secondary_action_label: __('Cancel'),
			primary_action: function() {

				let button_ok = cur_dialog.footer[0].getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[0];

				button_ok.disabled = true;

				let fields = []

				if (document.getElementById('html_common_template_xlsx_fields_option').value == 2) {

					if (document.getElementById('html_common_template_xlsx_container_selected_fields_list').options.length == 0) {

						button_ok.disabled = false;
						frappe.msgprint({ title: __("Message"), message: __('No selected fields'), indicator: "red" });
						return false;
					}

					for (var i = 0; i < document.getElementById('html_common_template_xlsx_container_selected_fields_list').options.length; i++) {
						fields[fields.length] = document.getElementById('html_common_template_xlsx_container_selected_fields_list').options[i].value;
					}

				}else if (document.getElementById('html_common_template_xlsx_fields_option').value == 1) {

					for (var i = 0; i < cur_frm.fields_dict[childname].grid.visible_columns.length; i++) {
						if (cur_frm.fields_dict[childname].grid.visible_columns[i][0]) {
							if (cur_frm.fields_dict[childname].grid.visible_columns[i][0].fieldname) {
								if (cur_frm.fields_dict[childname].grid.visible_columns[i][0].fieldname.trim() != '') {
									fields[fields.length] = cur_frm.fields_dict[childname].grid.visible_columns[i][0].fieldname;
								}
							}
						}

					}

				}

				let url = "/api/method/" + path_common + ".ioi_import_xlsx_get_template" + frappe.utils.make_query_string({"doctype": cur_frm.doctype, "childname": childname, "mode":document.getElementById('html_common_template_xlsx_fields_option').value, "orderby": document.getElementById('html_common_template_xlsx_fields_sort').value, "fields":fields}, false);
				window.open(url);		
				templateXlsxFrm.hide()		
			

			},
			secondary_action: function(){

				templateXlsxFrm.hide();
			}

		});

		if (document.getElementById('html_common_template_xlsx_container')) {
			document.getElementById('html_common_template_xlsx_container').remove();
		}

		if (document.getElementById('html_common_template_xlsx_fields_option_label')) {
			document.getElementById('html_common_template_xlsx_fields_option_label').remove();
		}
		
		if (document.getElementById('html_common_template_xlsx_fields_option')) {
			document.getElementById('html_common_template_xlsx_fields_option').remove();
		}	

		if (document.getElementById('html_common_template_xlsx_fields_sort_label')) {
			document.getElementById('html_common_template_xlsx_fields_sort_label').remove();
		}
		
		if (document.getElementById('html_common_template_xlsx_fields_sort')) {
			document.getElementById('html_common_template_xlsx_fields_sort').remove();
		}	
	
		if (document.getElementById('html_common_template_xlsx_container_search_fields')) {
			document.getElementById('html_common_template_xlsx_container_search_fields').remove();
		}	

		if (document.getElementById('html_common_template_xlsx_search_field_label')) {
			document.getElementById('html_common_template_xlsx_search_field_label').remove();
		}	

		if (document.getElementById('html_common_template_xlsx_search_field')) {
			document.getElementById('html_common_template_xlsx_search_field').remove();
		}	

		if (document.getElementById('html_common_template_xlsx_container_childdoctype_button_add_all')) {
			document.getElementById('html_common_template_xlsx_container_childdoctype_button_add_all').remove();
		}	
		
		if (document.getElementById('html_common_template_xlsx_container_childdoctype_button_add')) {
			document.getElementById('html_common_template_xlsx_container_childdoctype_button_add').remove();
		}	
		
		if (document.getElementById('html_common_template_xlsx_container_childdoctype_button_remove')) {
			document.getElementById('html_common_template_xlsx_container_childdoctype_button_remove').remove();
		}	
		
		if (document.getElementById('html_common_template_xlsx_container_childdoctype_button_remove_all')) {
			document.getElementById('html_common_template_xlsx_container_childdoctype_button_remove_all').remove();
		}	

		if (document.getElementById('html_common_template_xlsx_container_childdoctype_button_up')) {
			document.getElementById('html_common_template_xlsx_container_childdoctype_button_up').remove();
		}	
		
		if (document.getElementById('html_common_template_xlsx_container_childdoctype_button_down')) {
			document.getElementById('html_common_template_xlsx_container_childdoctype_button_down').remove();
		}	
		

	

		if (document.getElementById('html_common_template_xlsx_container_childdoctype_fields')) {
			document.getElementById('html_common_template_xlsx_container_childdoctype_fields').remove();
		}	


		if (document.getElementById('html_common_template_xlsx_container_childdoctype_fields_list')) {
			document.getElementById('html_common_template_xlsx_container_childdoctype_fields_list').remove();
		}	


		if (document.getElementById('html_common_template_xlsx_container_selected_fields')) {
			document.getElementById('html_common_template_xlsx_container_selected_fields').remove();
		}	

		if (document.getElementById('html_common_template_xlsx_container_selected_fields_list')) {
			document.getElementById('html_common_template_xlsx_container_selected_fields_list').remove();
		}	

		let html = '';

		html += '<div id="html_common_template_xlsx_container" style="overflow: auto; overflow-x: hidden; overflow-y: hidden; height:65px; width: 100%;">';

		// Fields option
		html += '	<div style="position: relative; top: 0px; left: 2px; width:440px;">';
		html += '		<label id="html_common_template_xlsx_fields_option_label" style="position: absolute; top: 0px; left: 2px;">' + __("Fields") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 400px; height: 30px;"> ';
		html += '		<select id="html_common_template_xlsx_fields_option" class="input-with-feedback form-control bold" style="width:460px"> ';
		html += '			<option value="0" selected>' + __("All fields") + '</option> ';
		html += '			<option value="1">' + __("Existing fields in the current detail grid") + '</option> ';
		html += '			<option value="2">' + __("Choose fields") + '</option> ';
		html += '		</select> ';
		html += '		</div>';
		html += '	</div>';		

		// Fields sort
		html += '	<div style="position: relative; top: 0px; left: 472px; width:400px;">';
		html += '		<label id="html_common_template_xlsx_fields_sort_label" style="position: absolute; top: 0px; left: 2px;">' + __("Sorted by") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 400px; height: 30px;"> ';
		html += '		<select id="html_common_template_xlsx_fields_sort" class="input-with-feedback form-control bold" style="width:400px"> ';
		html += '			<option value="0" selected>' + __("Field order") + '</option> ';
		html += '			<option value="1">' + __("Fieldname") + '</option> ';
		html += '		</select> ';
		html += '		</div>';
		html += '	</div>';	

		// html_common_template_xlsx_selected_label
		
		// Selected field label
		html += '	<div style="position: relative; top: 105px; left: 472px; width:300px;">';
		html += '		<label id="html_common_template_xlsx_selected_label" style="position: absolute; top: 0px; left: 0px;z-index:5;">' + __("Selected fields") + '</label>';
		html += '	</div>';				


		// Search fields
		html += '	<div id="html_common_template_xlsx_container_search_fields" style="position: relative; top: 65px; left: 2px; width:300px;display:none">';
		html += '		<label id="html_common_template_xlsx_search_field_label" style="position: absolute; top: 0px; left: 0px;z-index:5;">' + __("Search") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 400px; height: 25px;"> ';
		html += '			<input id="html_common_template_xlsx_search_field" type="text"class="input-with-feedback form-control bold" value="">';
		html += '		</div>';
		html += '	</div>';		




		html += '	<div style="position: relative; top:130px; left: 412px; height: 35px">';
		html +='		<button id="html_common_template_xlsx_container_childdoctype_button_add_all" class="btn btn-default ellipsis" style="height: 30px; width: 50px;" onclick="">&#8677;</button>';
		html += '	</div>';		

		html += '	<div style="position: relative; top:130px; left: 412px; height: 35px">';
		html +='		<button id="html_common_template_xlsx_container_childdoctype_button_add" class="btn btn-default ellipsis" style="height: 30px; width: 50px;" onclick="">&#8594;</button>';
		html += '	</div>';		

		html += '	<div style="position: relative; top:130px; left: 412px; height: 35px">';
		html +='		<button id="html_common_template_xlsx_container_childdoctype_button_remove" class="btn btn-default ellipsis" style="height: 30px; width: 50px;" onclick="">&#8592;</button>';
		html += '	</div>';	
		

		html += '	<div style="position: relative; top:130px; left: 412px; height: 35px">';
		html +='		<button id="html_common_template_xlsx_container_childdoctype_button_remove_all" class="btn btn-default ellipsis" style="height: 30px; width: 50px;" onclick="">&#8676;</button>';
		html += '	</div>';		
		
		// Up
		html += '	<div style="position: relative; top: -10px; left: 882px; height: 35px">';
		html +='		<button id="html_common_template_xlsx_container_childdoctype_button_up" title="' + __("Up") + '" class="btn btn-default ellipsis" style="height: 30px; width: 50px;" onclick="">&uarr;' + '</button>';
		html += '	</div>';

		// Down
		html += '	<div style="position: relative; top: -10px; left: 882px; height: 35px">';
		html +='		<button id="html_common_template_xlsx_container_childdoctype_button_down" title="' + __("Down") + '" class="btn btn-default ellipsis" style="height: 30px; width: 50px;" onclick="">&darr;' + '</button>';
		html += '	</div>';
		



		// Childdoctype fields
		html += '	<div id="html_common_template_xlsx_container_childdoctype_fields" style="position: relative; top: -80px; left: 2px; width:400px;height:345px;display:none;">';
		html += '		<select id="html_common_template_xlsx_container_childdoctype_fields_list" class="input-with-feedback form-control bold" style="width:400px" size=19> ';
		html += '		</select> ';		
		html += '	</div>';	
		

		// Childdoctype fields
		html += '	<div id="html_common_template_xlsx_container_selected_fields" style="position: relative; top: -425px; left: 472px; width:400px;height:345px;display:none;">';
		html += '		<select id="html_common_template_xlsx_container_selected_fields_list" class="input-with-feedback form-control bold" style="width:400px" size=19> ';
		html += '		</select> ';				
		html += '	</div>';					
		html += '</div>';


		templateXlsxFrm.fields_dict.html_common_template_xlsx.$wrapper.html(html);
		templateXlsxFrm.$wrapper.find('.modal-dialog').css("max-width", "980px").css("width", "980px");
		
		templateXlsxFrm.show();


		silicon_ioi.ioiCommon.sleep_static(200).then(() => {

			let fct_field_options_change = function() {

				if (document.getElementById('html_common_template_xlsx_fields_option').value == 2) {
					document.getElementById('html_common_template_xlsx_container').style.height = '470px';
					document.getElementById('html_common_template_xlsx_container_search_fields').style.display = 'block';
					document.getElementById('html_common_template_xlsx_container_childdoctype_fields').style.display = 'block';
					document.getElementById('html_common_template_xlsx_container_selected_fields').style.display = 'block';		

					document.getElementById('html_common_template_xlsx_fields_sort_label').style.display = 'block';
					document.getElementById('html_common_template_xlsx_fields_sort').style.display = 'block';
					
					silicon_ioi.ioiCommon.import_get_xlsx_template_get_childfields(childname);

					document.getElementById('html_common_template_xlsx_search_field').focus();
				}else{
					document.getElementById('html_common_template_xlsx_container').style.height = '65px';
					document.getElementById('html_common_template_xlsx_container_search_fields').style.display = 'none';
					document.getElementById('html_common_template_xlsx_container_childdoctype_fields').style.display = 'none';
					document.getElementById('html_common_template_xlsx_container_selected_fields').style.display = 'none';

					if (document.getElementById('html_common_template_xlsx_fields_option').value == 1) {
						document.getElementById('html_common_template_xlsx_fields_sort_label').style.display = 'none';
						document.getElementById('html_common_template_xlsx_fields_sort').style.display = 'none';
					}else{
						document.getElementById('html_common_template_xlsx_fields_sort_label').style.display = 'block';
						document.getElementById('html_common_template_xlsx_fields_sort').style.display = 'block';						
					}
				}
	
			}

			document.getElementById('html_common_template_xlsx_fields_option').onchange = fct_field_options_change;

			let fct_search_keydown = function(event) {
				if (event.keyCode == 13) {
					silicon_ioi.ioiCommon.import_get_xlsx_template_get_childfields(childname);
					return false;
				}else if (event.keyCode == 40) {
					if (document.getElementById('html_common_template_xlsx_container_childdoctype_fields_list').options.length > 0) {
						document.getElementById('html_common_template_xlsx_container_childdoctype_fields_list').focus();
					}
				}
			}


			document.getElementById('html_common_template_xlsx_search_field').onkeydown = fct_search_keydown;

			let fct_field_sort_change = function() {
				silicon_ioi.ioiCommon.import_get_xlsx_template_get_childfields(childname);
			}

			document.getElementById('html_common_template_xlsx_fields_sort').onchange = fct_field_sort_change;

			let fct_add_all = function() {
				silicon_ioi.ioiCommon.import_get_xlsx_template_add_all();

			}

			document.getElementById('html_common_template_xlsx_container_childdoctype_button_add_all').onclick = fct_add_all;

			let fct_add = function() {
				silicon_ioi.ioiCommon.import_get_xlsx_template_add();

			}

			document.getElementById('html_common_template_xlsx_container_childdoctype_button_add').onclick = fct_add;			

			let fct_remove = function() {
				silicon_ioi.ioiCommon.import_get_xlsx_template_remove_all();
			}

			document.getElementById('html_common_template_xlsx_container_childdoctype_button_remove_all').onclick = fct_remove;			



			let fct_remove_all = function() {
				silicon_ioi.ioiCommon.import_get_xlsx_template_remove();
			}

			document.getElementById('html_common_template_xlsx_container_childdoctype_button_remove').onclick = fct_remove_all;			


			let fct_move_up = function() {
				silicon_ioi.ioiCommon.import_get_xlsx_template_move_up();
			}

			document.getElementById('html_common_template_xlsx_container_childdoctype_button_up').onclick = fct_move_up;			

			let fct_move_down = function() {
				silicon_ioi.ioiCommon.import_get_xlsx_template_move_down();
			}

			document.getElementById('html_common_template_xlsx_container_childdoctype_button_down').onclick = fct_move_down;			
			
			let fct_fields_list_keydown = function(event) {

				if (event.keyCode == 13) {
					silicon_ioi.ioiCommon.import_get_xlsx_template_add();
					return false
				}else if (event.keyCode == 38) {
					if (document.getElementById('html_common_template_xlsx_container_childdoctype_fields_list').selectedIndex == 0) {
						document.getElementById('html_common_template_xlsx_search_field').focus();
					}
				}
			}

			document.getElementById('html_common_template_xlsx_container_childdoctype_fields_list').onkeydown = fct_fields_list_keydown;

			let fct_selected_fields_list_keydown = function(event) {

				if (event.keyCode == 13) {
					silicon_ioi.ioiCommon.import_get_xlsx_template_remove();
					return false
				}

			}

			document.getElementById('html_common_template_xlsx_container_selected_fields_list').onkeydown = fct_selected_fields_list_keydown;


		});

	}

	static import_get_xlsx_template_get_childfields(childname)
	{
		let method = "silicon_ioi.common.common.ioi_import_xlsx_get_template_get_doctypefields"
		let data = {}

		frappe.call({
			method: method,
			args: {
				"doctype": cur_frm.doctype,
				"childname": childname,
				"search_term": document.getElementById('html_common_template_xlsx_search_field').value, 
				"orderby" : document.getElementById('html_common_template_xlsx_fields_sort').value
			},
			async: false,
			callback:function(r)	{
				data = r.message;

			}
		});	

		for (var i = document.getElementById('html_common_template_xlsx_container_childdoctype_fields_list').options.length-1; i >= 0; i--) {
			document.getElementById('html_common_template_xlsx_container_childdoctype_fields_list').options.remove(i);
		}

		if (data.length > 0) {

			for (var i = 0; i < data.length; i++) {

				let option = document.createElement("option");
				option.value = data[i].fieldname;
				option.text = data[i].fieldname + ' ( ' + __(data[i].label) + ' ) ';

				let fct_dblclick = function () {
					silicon_ioi.ioiCommon.import_get_xlsx_template_add();
				}

				option.ondblclick = fct_dblclick;
				document.getElementById('html_common_template_xlsx_container_childdoctype_fields_list').add(option);

			}
		}

		if (document.getElementById('html_common_template_xlsx_container_childdoctype_fields_list').options.length > 0) {
			document.getElementById('html_common_template_xlsx_container_childdoctype_fields_list').selectedIndex = 0;
			document.getElementById('html_common_template_xlsx_container_childdoctype_fields_list').focus();
		}
	

	}


	static import_get_xlsx_template_add_all()
	{
		if (document.getElementById('html_common_template_xlsx_container_childdoctype_fields_list').options.length == 0)	{
			return false;
		}

		for (var k = 0; k < document.getElementById('html_common_template_xlsx_container_childdoctype_fields_list').options.length; k++) {

			let found = false;

			for (var i = 0; i < document.getElementById('html_common_template_xlsx_container_selected_fields_list').options.length; i++) {

				if (document.getElementById('html_common_template_xlsx_container_childdoctype_fields_list').options[k].value == document.getElementById('html_common_template_xlsx_container_selected_fields_list').options[i].value) {
					found = true;
					break;
				}
			}

			if (!found) {

				let option = document.createElement("option");
				option.value = document.getElementById('html_common_template_xlsx_container_childdoctype_fields_list').options[k].value;
				option.text = document.getElementById('html_common_template_xlsx_container_childdoctype_fields_list').options[k].text;

				let fct_dblclick = function () {
					silicon_ioi.ioiCommon.import_get_xlsx_template_remove()
				}

				option.ondblclick = fct_dblclick;				
				document.getElementById('html_common_template_xlsx_container_selected_fields_list').add(option);

			}
		}

		document.getElementById('html_common_template_xlsx_container_selected_fields_list').selectedIndex = 0;

	}

	static import_get_xlsx_template_add()
	{
		if (document.getElementById('html_common_template_xlsx_container_childdoctype_fields_list').options.length == 0)	{
			return false;
		}

		let current_value = document.getElementById('html_common_template_xlsx_container_childdoctype_fields_list').options[document.getElementById('html_common_template_xlsx_container_childdoctype_fields_list').selectedIndex].value;

		let found = false;

		for (var i = 0; i < document.getElementById('html_common_template_xlsx_container_selected_fields_list').options.length; i++) {

			if (current_value == document.getElementById('html_common_template_xlsx_container_selected_fields_list').options[i].value) {
				found = true;
				break;
			}
		}

		if (!found) {

			let option = document.createElement("option");
			option.value = document.getElementById('html_common_template_xlsx_container_childdoctype_fields_list').options[document.getElementById('html_common_template_xlsx_container_childdoctype_fields_list').selectedIndex].value;
			option.text = document.getElementById('html_common_template_xlsx_container_childdoctype_fields_list').options[document.getElementById('html_common_template_xlsx_container_childdoctype_fields_list').selectedIndex].innerText;

			let fct_dblclick = function () {
				silicon_ioi.ioiCommon.import_get_xlsx_template_remove()
			}

			option.ondblclick = fct_dblclick;	

			document.getElementById('html_common_template_xlsx_container_selected_fields_list').add(option);

			document.getElementById('html_common_template_xlsx_container_selected_fields_list').selectedIndex = document.getElementById('html_common_template_xlsx_container_selected_fields_list').options.length-1;
		}

	}

	static import_get_xlsx_template_remove_all()
	{
		if (document.getElementById('html_common_template_xlsx_container_selected_fields_list').options.length == 0)	{
			return false;
		}

		for (var k = document.getElementById('html_common_template_xlsx_container_selected_fields_list').options.length-1; k >= 0; k--) {
			document.getElementById('html_common_template_xlsx_container_selected_fields_list').options.remove(k);
		}
	}

	static import_get_xlsx_template_remove()
	{
		if (document.getElementById('html_common_template_xlsx_container_selected_fields_list').options.length == 0)	{
			return false;
		}

		let current_value = document.getElementById('html_common_template_xlsx_container_selected_fields_list').options[document.getElementById('html_common_template_xlsx_container_selected_fields_list').selectedIndex].value;

		let current_idx = -1;

		for (var k = document.getElementById('html_common_template_xlsx_container_selected_fields_list').options.length-1; k >= 0; k--) {
			if (current_value == document.getElementById('html_common_template_xlsx_container_selected_fields_list').options[k].value) {
				current_idx = k;
				document.getElementById('html_common_template_xlsx_container_selected_fields_list').options.remove(k);
				break;
			}
		}

		if (current_idx > 0) {
			document.getElementById('html_common_template_xlsx_container_selected_fields_list').selectedIndex = current_idx-1;
		}
	}

	static import_get_xlsx_template_move_up()
	{

		if (document.getElementById('html_common_template_xlsx_container_selected_fields_list').options.length > 0) {

			if (document.getElementById('html_common_template_xlsx_container_selected_fields_list').selectedIndex != 0) {

				let current_idx = document.getElementById('html_common_template_xlsx_container_selected_fields_list').selectedIndex;

				if (current_idx != -1) {

					let selected_fields_current_value = document.getElementById('html_common_template_xlsx_container_selected_fields_list')[current_idx].value;
					let selected_fields_current_label = document.getElementById('html_common_template_xlsx_container_selected_fields_list')[current_idx].innerText;

					let prior_idx = current_idx-1;

					if (document.getElementById('html_common_template_xlsx_container_selected_fields_list')[prior_idx]) {

						let selected_fields_prior_value = document.getElementById('html_common_template_xlsx_container_selected_fields_list')[prior_idx].value;
						let selected_fields_prior_label = document.getElementById('html_common_template_xlsx_container_selected_fields_list')[prior_idx].innerText;


						document.getElementById('html_common_template_xlsx_container_selected_fields_list')[current_idx].value = selected_fields_prior_value;
						document.getElementById('html_common_template_xlsx_container_selected_fields_list')[current_idx].innerText = selected_fields_prior_label;

						document.getElementById('html_common_template_xlsx_container_selected_fields_list')[prior_idx].value = selected_fields_current_value;
						document.getElementById('html_common_template_xlsx_container_selected_fields_list')[prior_idx].innerText = selected_fields_current_label;

						document.getElementById('html_common_template_xlsx_container_selected_fields_list').selectedIndex = prior_idx;

					}
				}
			}
		}

	}

	static import_get_xlsx_template_move_down()
	{
		if (document.getElementById('html_common_template_xlsx_container_selected_fields_list').options.length > 0) {

			if (document.getElementById('html_common_template_xlsx_container_selected_fields_list').selectedIndex != document.getElementById('html_common_template_xlsx_container_selected_fields_list').options.length) {

				let current_idx = document.getElementById('html_common_template_xlsx_container_selected_fields_list').selectedIndex;

				if (current_idx != -1) {

					let selected_fields_current_value = document.getElementById('html_common_template_xlsx_container_selected_fields_list')[current_idx].value;
					let selected_fields_current_label = document.getElementById('html_common_template_xlsx_container_selected_fields_list')[current_idx].innerText;

					let next_idx = current_idx+1;

					if (document.getElementById('html_common_template_xlsx_container_selected_fields_list')[next_idx]) {

						let selected_fields_next_value = document.getElementById('html_common_template_xlsx_container_selected_fields_list')[next_idx].value;
						let selected_fields_next_label = document.getElementById('html_common_template_xlsx_container_selected_fields_list')[next_idx].innerText;


						document.getElementById('html_common_template_xlsx_container_selected_fields_list')[current_idx].value = selected_fields_next_value
						document.getElementById('html_common_template_xlsx_container_selected_fields_list')[current_idx].innerText = selected_fields_next_label;

						document.getElementById('html_common_template_xlsx_container_selected_fields_list')[next_idx].value = selected_fields_current_value
						document.getElementById('html_common_template_xlsx_container_selected_fields_list')[next_idx].innerText = selected_fields_current_label;

						document.getElementById('html_common_template_xlsx_container_selected_fields_list').selectedIndex = next_idx;

					}
				}
			}
		}

	}
}

silicon_ioi.ioiCommon = ioiCommon;
