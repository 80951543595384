frappe.provide('silicon-ioi');


class AFilter extends frappe.ui.Filter {

    path_module_status = 'silicon_ioi.ioi_configuration.doctype.ioi_module_status.ioi_module_status';

    fill_status_filter(doctype)
    {
        let me = this;

        $(this.field.input).empty();
        $(this.field.input).add_options([{ label: __("All"), value: null }]);

        let method = 'silicon_ioi.ioi_configuration.doctype.ioi_module_status.ioi_module_status.ioi_module_status_get_list';

        frappe.call({  	method: method,
                        args: {"doctype": doctype},
                        async: false,
                        callback:function(r)	{
                                                    if (r.message.length != 0)
                                                    {
                                                        for (var i = 0; i < r.message.length; i++)
                                                        {
                                                            $(me.field.input).add_options([{ label: r.message[i][5], value: r.message[i][2] }]);
                                                        }
                                                    }
                                                }
        });
        this.field.refresh();
    }

    fill_document_prefixes(doctype)
    {

        let me = this;

        $(this.field.input).empty();
        $(this.field.input).add_options([{ label: __("All"), value: null }]);

        let method = '';

        if (doctype.toUpperCase() == 'IOI OPPORTUNITY') {
            method = 'silicon_ioi.ioi_crm.doctype.ioi_opportunity.ioi_opportunity.ioi_opportunity_get_prefixes_for_module_list';
        }else if (doctype.toUpperCase() == 'IOI PRODUCTION') {
            method = 'silicon_ioi.ioi_production.doctype.ioi_production.ioi_production.ioi_production_get_prefixes_for_module_list';
        }else if (doctype.toUpperCase() == 'IOI PURCHASES INVOICE') {
            method = 'silicon_ioi.ioi_purchases.doctype.ioi_purchases_invoice.ioi_purchases_invoice.ioi_purchases_invoice_get_prefixes_for_module_list';
        }else if (doctype.toUpperCase() == 'IOI PURCHASES ORDER') {
            method = 'silicon_ioi.ioi_purchases.doctype.ioi_purchases_order.ioi_purchases_order.ioi_purchases_order_get_prefixes_for_module_list';
        }else if (doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') {
            method = 'silicon_ioi.ioi_purchases.doctype.ioi_purchases_price_request.ioi_purchases_price_request.ioi_purchases_price_request_get_prefixes_for_module_list';
        }else if (doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') {
            method = 'silicon_ioi.ioi_purchases.doctype.ioi_purchases_receipt.ioi_purchases_receipt.ioi_purchases_receipt_get_prefixes_for_module_list';
        }else if (doctype.toUpperCase() == 'IOI SALES DELIVERY') {
            method = 'silicon_ioi.ioi_sales.doctype.ioi_sales_delivery.ioi_sales_delivery.ioi_sales_delivery_get_prefixes_for_module_list';
        }else if (doctype.toUpperCase() == 'IOI SALES INVOICE') {
            method = 'silicon_ioi.ioi_sales.doctype.ioi_sales_invoice.ioi_sales_invoice.ioi_sales_invoice_get_prefixes_for_module_list';
        }else if (doctype.toUpperCase() == 'IOI SALES ORDER') {
            method = 'silicon_ioi.ioi_sales.doctype.ioi_sales_order.ioi_sales_order.ioi_sales_order_get_prefixes_for_module_list';
        }else if (doctype.toUpperCase() == 'IOI SALES QUOTE') {
            method = 'silicon_ioi.ioi_sales.doctype.ioi_sales_quote.ioi_sales_quote.ioi_sales_quote_get_prefixes_for_module_list';
        }else if (doctype.toUpperCase() == 'IOI DOSSIER') {
            method = 'silicon_ioi.ioi_trakker.doctype.ioi_dossier.ioi_dossier.ioi_dossier_get_prefixes_for_module_list';
        }else if (doctype.toUpperCase() == 'IOI STOCK ENTRY') {
            method = 'silicon_ioi.ioi_wms.doctype.ioi_stock_entry.ioi_stock_entry.ioi_stock_entry_get_prefixes_for_module_list';
        }else if (doctype.toUpperCase() == 'IOI STOCK OUTPUT') {
            method = 'silicon_ioi.ioi_wms.doctype.ioi_stock_output.ioi_stock_output.ioi_stock_output_get_prefixes_for_module_list';
        }else if (doctype.toUpperCase() == 'IOI STOCK TRANSFER') {
            method = 'silicon_ioi.ioi_wms.doctype.ioi_stock_transfer.ioi_stock_transfer.ioi_stock_transfer_get_prefixes_for_module_list';
        }else if (doctype.toUpperCase() == 'IOI STOCK QUALIFICATION') {
            method = 'silicon_ioi.ioi_wms.doctype.ioi_stock_qualification.ioi_stock_qualification.ioi_stock_qualification_get_prefixes_for_module_list';
        }else if (doctype.toUpperCase() == 'IOI STOCK INVENTORY') {
            method = 'silicon_ioi.ioi_wms.doctype.ioi_stock_inventory.ioi_stock_inventory.ioi_stock_inventory_get_prefixes_for_module_list';
        }

        if (method.trim() != '') {

            frappe.call({  	method: method,
                            args: {},
                            async: false,
                            callback:function(r)	{
                                                        if (r.message.length != 0)
                                                        {
                                                            for (var i = 0; i < r.message.length; i++)
                                                            {
                                                                $(me.field.input).add_options([{ label: r.message[i].prefix, value: r.message[i].prefix }]);
                                                            }
                                                        }
                                                    }
            });
            this.field.refresh();
        }

    }



    make_field (df, oldfieldtype) {
        super.make_field(df, oldfieldtype);

		if (df.parent == "ioi Supplier")
        {
            if (df.fieldname == "ioistatus") {
                this.fill_status_filter(df.parent);
            }else if (df.fieldname == "mode") {
                $(this.field.input).empty();
                $(this.field.input).add_options([{ label: __("All"),            value: null }]);
                $(this.field.input).add_options([{ label: __("Regular"),        value: 0}]);
                $(this.field.input).add_options([{ label: __("Prospect"),       value: 1}]);
                $(this.field.input).add_options([{ label: __("Lead"),           value: 2}]);
                $(this.field.input).add_options([{ label: __("Inter company"),  value: 4}]);

                this.field.refresh();
			}

		}else if (df.parent == "ioi Customer")
        {
            if (df.fieldname == "ioistatus") {
            	this.fill_status_filter(df.parent);
            }else if (df.fieldname == "mode") {

                $(this.field.input).empty();
                $(this.field.input).add_options([{ label: __("All"),            value: null }]);
                $(this.field.input).add_options([{ label: __("Regular"),        value: 0}]);
                $(this.field.input).add_options([{ label: __("Prospect"),       value: 1}]);
                $(this.field.input).add_options([{ label: __("Lead"),           value: 2}]);
                $(this.field.input).add_options([{ label: __("End user"),       value: 3}]);
                $(this.field.input).add_options([{ label: __("Inter company"),  value: 4}]);

                this.field.refresh();
			}
		}else if (df.parent == "ioi Other Address")
        {
            if (df.fieldname == "ioistatus") {
            	this.fill_status_filter(df.parent);
            }else if (df.fieldname == "mode") {

                $(this.field.input).empty();
                $(this.field.input).add_options([{ label: __("All"),      value: null }]);
                $(this.field.input).add_options([{ label: __("Regular"),  value: 0}]);
                $(this.field.input).add_options([{ label: __("Prospect"), value: 1}]);
                $(this.field.input).add_options([{ label: __("Lead"),     value: 2}]);
                this.field.refresh();
			}
		}else if (df.parent == "ioi Item")
        {
            if (df.fieldname == "ioistatus") {
            	this.fill_status_filter(df.parent);
            }else if (df.fieldname == "mode") {

                $(this.field.input).empty();
                $(this.field.input).add_options([{ label: __("All"),     value: null }]);
                $(this.field.input).add_options([{ label: __("Regular"), value: 0}]);
                $(this.field.input).add_options([{ label: __("Free"),    value: 1}]);
                $(this.field.input).add_options([{ label: __("Virtual"), value: 2}]);
                $(this.field.input).add_options([{ label: __("Batch"),   value: 3}]);
                $(this.field.input).add_options([{ label: __("Serial"),  value: 4}]);
                this.field.refresh();
            }
        }else if (df.parent == "ioi Staff")
        {
            if (df.fieldname == "ioistatus") {
                this.fill_status_filter(df.parent);
            }
        }else if (df.parent == "ioi Dossier")
        {
            if (df.fieldname == "ioistatus") {
                this.fill_status_filter(df.parent);
            }else if (df.fieldname == "prefix_id") {
                this.fill_document_prefixes(df.parent);
            }
        }else if (df.parent == "ioi Sales FTG Discount")
        {
            if (df.fieldname == "ioistatus") {
                this.fill_status_filter(df.parent);
            }
        }else if (df.parent == "ioi Sales Quote")
        {
            if (df.fieldname == "ioistatus") {
				this.fill_status_filter(df.parent);
            }else if (df.fieldname == "prefix_id") {
                this.fill_document_prefixes(df.parent);
            }
        }else if (df.parent == "ioi Sales Order")
        {
            if (df.fieldname == "ioistatus") {
				this.fill_status_filter(df.parent);
            }else if (df.fieldname == "prefix_id") {
                this.fill_document_prefixes(df.parent);
            }
        }else if (df.parent == "ioi Sales Delivery")
        {
            if (df.fieldname == "ioistatus") {
                this.fill_status_filter(df.parent);
            }else if (df.fieldname == "prefix_id") {
                this.fill_document_prefixes(df.parent);
            }
        }else if (df.parent == "ioi Sales Invoice")
        {
            if (df.fieldname == "ioistatus") {
                this.fill_status_filter(df.parent);
            }else if (df.fieldname == "prefix_id") {
                this.fill_document_prefixes(df.parent);
            }
        }else if (df.parent == "ioi Purchases Price Request")
        {
            if (df.fieldname == "ioistatus") {
                this.fill_status_filter(df.parent);
            }else if (df.fieldname == "prefix_id") {
                this.fill_document_prefixes(df.parent);
            }
        }else if (df.parent == "ioi Purchases Order")
        {
            if (df.fieldname == "ioistatus") {
                this.fill_status_filter(df.parent);
            }else if (df.fieldname == "prefix_id") {
                this.fill_document_prefixes(df.parent);
            }
        }else if (df.parent == "ioi Purchases Receipt")
        {
            if (df.fieldname == "ioistatus") {
                this.fill_status_filter(df.parent);
            }else if (df.fieldname == "prefix_id") {
                this.fill_document_prefixes(df.parent);
            }
        }else if (df.parent == "ioi Purchases Invoice")
        {
            if (df.fieldname == "ioistatus") {
                this.fill_status_filter(df.parent);
            }else if (df.fieldname == "prefix_id") {
                this.fill_document_prefixes(df.parent);
            }
        }else if (df.parent == "ioi Opportunity")
        {
            if (df.fieldname == "ioistatus") {
                this.fill_status_filter(df.parent);
            }else if (df.fieldname == "prefix_id") {
                this.fill_document_prefixes(df.parent);
            }
        }else if (df.parent == "ioi Subscription")
        {
            if (df.fieldname == "ioistatus") {
                this.fill_status_filter(df.parent);
            }
        }else if (df.parent == "ioi Stock Transfer")
        {
            if (df.fieldname == "ioistatus") {
                this.fill_status_filter(df.parent);
            }else if (df.fieldname == "prefix_id") {
                this.fill_document_prefixes(df.parent);
            }
        }else if (df.parent == "ioi Stock Qualification")
        {
            if (df.fieldname == "ioistatus") {
                this.fill_status_filter(df.parent);
            }else if (df.fieldname == "prefix_id") {
                this.fill_document_prefixes(df.parent);
            }
        }else if (df.parent == "ioi Stock Entry")
        {
            if (df.fieldname == "ioistatus") {
                this.fill_status_filter(df.parent);
            }else if (df.fieldname == "prefix_id") {
                this.fill_document_prefixes(df.parent);
            }
        }else if (df.parent == "ioi Production")
        {
            if (df.fieldname == "ioistatus") {
                this.fill_status_filter(df.parent);
            }else if (df.fieldname == "prefix_id") {
                this.fill_document_prefixes(df.parent);
            }
        }else if (df.parent == "ioi Stock Output")
        {
            if (df.fieldname == "ioistatus") {
                this.fill_status_filter(df.parent);
            }else if (df.fieldname == "prefix_id") {
                this.fill_document_prefixes(df.parent);
            }
        }else if (df.parent == "ioi Stock Inventory")
        {
            if (df.fieldname == "ioistatus") {
                this.fill_status_filter(df.parent);
            }else if (df.fieldname == "prefix_id") {
                this.fill_document_prefixes(df.parent);
            }
        }
}
}

$(document).ready(() => {
	frappe.ui.Filter = AFilter
});
