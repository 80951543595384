frappe.provide('silicon_ioi.doctype');

export class ioiTimeVectorSummary
{
	static path_common = 'silicon_ioi.common.common';
	static path_time_vector = 'silicon_ioi.ioi_system.doctype.ioi_time_vector.ioi_time_vector';
	static path_item_site = 'silicon_ioi.ioi_items.doctype.ioi_item_site.ioi_item_site';

	static is_dark_mode = 0;
	static bgcolor = 0;

	static idx = -1;
    static site_id = '';
	static item_id = '';
	static item_mode = 0;

	static frame_height = -1;
	static table

	
	static load_config()
	{
		silicon_ioi.doctype.ioiTimeVectorSummary.is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;
		silicon_ioi.doctype.ioiTimeVectorSummary.bgcolor = '';

		silicon_ioi.doctype.ioiTimeVectorSummary.item_mode = 0;

		if (silicon_ioi.doctype.ioiTimeVectorSummary.item_id != '') {

			let method = silicon_ioi.doctype.ioiTimeVectorFrame.path_time_vector + '.ioi_time_vector_get_item_mode';
		
			frappe.call({  	method: method, 
							args: {"item_id": silicon_ioi.doctype.ioiTimeVectorSummary.item_id },
							async: false,
							callback:function(r)	{ 

								silicon_ioi.doctype.ioiTimeVectorSummary.item_mode = r.message[0].mode;
							}
			});		
		}
	}


	// ***************************************************************************************************************************************
	// Frame Time Vector : Load frame
	// ***************************************************************************************************************************************
	static refresh_time_vector_summary(html_fieldname, site_id = '', item_id)
	{

		silicon_ioi.doctype.ioiTimeVectorSummary.site_id = site_id;
        silicon_ioi.doctype.ioiTimeVectorSummary.item_id = item_id;

		silicon_ioi.doctype.ioiTimeVectorSummary.load_config();

		if (document.getElementById('html_time_vector_summary_hide_dossier')) {
			document.getElementById('html_time_vector_summary_hide_dossier').remove();
		}

		if (document.getElementById('html_time_vector_summary_hide_dossier_label')) {
			document.getElementById('html_time_vector_summary_hide_dossier_label').remove();
		}


		if (document.getElementById('html_time_vector_summary_hide_production')) {
			document.getElementById('html_time_vector_summary_hide_production').remove();
		}

		if (document.getElementById('html_time_vector_summary_hide_production_label')) {
			document.getElementById('html_time_vector_summary_hide_production_label').remove();
		}

		if (document.getElementById('html_time_vector_summary_hide_forecasted')) {
			document.getElementById('html_time_vector_summary_hide_forecasted').remove();
		}

		if (document.getElementById('html_time_vector_summary_hide_forecasted_label')) {
			document.getElementById('html_time_vector_summary_hide_forecasted_label').remove();
		}

		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:25px;">';

		html += '	<div style="position: relative; top: 0px; left: 0px; width:300px;">';
		html += '		<input type="checkbox" id="html_time_vector_summary_hide_dossier" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="html_time_vector_summary_hide_dossier_label" style="position: absolute; top: 6px; left: 30px;">' + __("Hide Dossier") + '</label>';
		html += '	</div>';

		html += '	<div style="position: relative; top: 0px; left: 310px; width:300px;">';
		html += '		<input type="checkbox" id="html_time_vector_summary_hide_production" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="html_time_vector_summary_hide_production_label" style="position: absolute; top: 6px; left: 30px;">' + __("Hide Production") + '</label>';
		html += '	</div>';

		html += '	<div style="position: relative; top: 0px; left: 620px; width:300px;">';
		html += '		<input type="checkbox" id="html_time_vector_summary_hide_forecasted" style="position: absolute; top: 8px; left: 2px;">';
		html += '		<label id="html_time_vector_summary_hide_forecasted_label" style="position: absolute; top: 6px; left: 30px;">' + __("Hide Forecasted") + '</label>';
		html += '	</div>';


		html += '</div>';

		let content_height = 750;

		// Grid Header
		html += '<div id="ioi_time_vector_summary_content" style="overflow: auto; overflow-x: auto; height:' + content_height.toString() + 'px;">';
		html += '	<div id="ioi_time_vector_summary_grid" class="table table-bordered" data-custom-grid="true"></div>';
		html += '</div>'


		cur_frm.fields_dict[html_fieldname].$wrapper.empty();
		cur_frm.fields_dict[html_fieldname].$wrapper.append(html);



		silicon_ioi.doctype.ioiTimeVectorSummary.time_vector_summary_grid([])

		silicon_ioi.doctype.ioiTimeVectorSummary.sleep_static(400).then(() => {

			let fct_click = function() {
				silicon_ioi.doctype.ioiTimeVectorSummary.time_vector_summary_refresh();
			};


			document.getElementById('html_time_vector_summary_hide_dossier').onclick = fct_click;
			document.getElementById('html_time_vector_summary_hide_production').onclick = fct_click;
			document.getElementById('html_time_vector_summary_hide_forecasted').onclick = fct_click;

			silicon_ioi.doctype.ioiTimeVectorSummary.time_vector_summary_refresh();
		
		});
	}

	static empty_htmlfield(html_fieldname) {

		let html = '';

		cur_frm.fields_dict[html_fieldname].$wrapper.empty();
		cur_frm.fields_dict[html_fieldname].$wrapper.append(html);
	}


	// ***************************************************************************************************************************************************************************
	// Time Vector Grid
	// ***************************************************************************************************************************************************************************

	static time_vector_summary_grid(data)
	{
		let let_empty_if_zero = (cell) => {

			let row = cell.getRow();

			if ((row.getData()["what"] == 'TOTAL_ALL_QTY') || (row.getData()["what"] == 'TOTAL_TOHANDLE_QTY') || (row.getData()["what"] == 'TOTAL_FORECASTED_QTY')) {
				cell.getElement().style.backgroundColor = '#f4f5f6';
			}

			if (cell.getValue() != 0) {
				return cell.getValue()
			}
		}

		let format_cell = (cell) => {
			

			if (cell.getColumn().getField().toUpperCase() == 'DESCRIPTION') {

				let row = cell.getRow();

				if ((row.getData()["what"] == 'PART_TO_HANDLE') || (row.getData()["what"] == 'PART_FORCASTED')) {
					return '&nbsp;&nbsp;&nbsp;&nbsp;' + cell.getValue()
				}else{
					if ((row.getData()["what"] == 'TOTAL_ALL_QTY') || (row.getData()["what"] == 'TOTAL_TOHANDLE_QTY') || (row.getData()["what"] == 'TOTAL_FORECASTED_QTY')) {
						cell.getElement().style.backgroundColor = '#f4f5f6';
					}

					return cell.getValue()
				}

			}
		}

		silicon_ioi.doctype.ioiTimeVectorSummary.table = new ioi.Tabulator("#ioi_time_vector_summary_grid", {
			maxHeight: 750,
			data: data,
			//layout: "fitColumns",
			selectableRows: false,
			movableColumns: false,
			resizableColumns: false,
			showProfiles: false,
			autoRedraw: false,
			columns: [
				{ title: __("Description"), field: "description", minWidth: 350, formatter: format_cell, headerSort: false },
				{ title: __("Qty (in)"), field: "qt_in", minWidth: 120, hozAlign: 'right', formatter: let_empty_if_zero, headerSort: false },
				{ title: __("Qty (out)"), field: "qt_out", minWidth: 120, hozAlign: 'right', formatter: let_empty_if_zero, headerSort: false },
				{ title: __("Total"), field: "total", minWidth: 120, hozAlign: 'right', formatter: let_empty_if_zero, headerSort: false },
				{ title: __("What"), field: "what", visible: false }
			]
		});
	}

	// ***************************************************************************************************************************************
	// Frame Time Vector : Refresh result
	// ***************************************************************************************************************************************
	static time_vector_summary_refresh()
	{
		if ((silicon_ioi.doctype.ioiTimeVectorSummary.item_id) && (silicon_ioi.doctype.ioiTimeVectorSummary.item_id.trim() != ''))
		{
			if (silicon_ioi.doctype.ioiTimeVectorSummary.item_mode != 2)
			{

				let cb_hide_dossier = 0;

				if (document.getElementById('html_time_vector_summary_hide_dossier').checked) {
					cb_hide_dossier = 1
				}
				
				let cb_hide_production = 0;

				if (document.getElementById('html_time_vector_summary_hide_production').checked) {
					cb_hide_production = 1;
				}

				let cb_hide_forecasted = 0;

				if (document.getElementById('html_time_vector_summary_hide_forecasted').checked) {
					cb_hide_forecasted = 1;
				}
	
				let method = silicon_ioi.doctype.ioiTimeVectorSummary.path_time_vector + '.ioi_time_vector_get_summary_list_with_total';

				frappe.call({
					method: method,
					args: {
						"site_id": silicon_ioi.doctype.ioiTimeVectorSummary.site_id,
						"item_id": silicon_ioi.doctype.ioiTimeVectorSummary.item_id,
						"hide_dossier": cb_hide_dossier, 
						"hide_production": cb_hide_production, 
						"hide_forecasted": cb_hide_forecasted
					},
					async: false,
					callback: function (r) {
						if (r.message.length > 0) {
							
							if (silicon_ioi.doctype.ioiTimeVectorSummary.table && silicon_ioi.doctype.ioiTimeVectorSummary.table.initialized) {
								silicon_ioi.doctype.ioiTimeVectorSummary.table.replaceData(r.message)
							} else {
								silicon_ioi.doctype.ioiTimeVectorSummary.table.on('tableBuilt', () => silicon_ioi.doctype.ioiTimeVectorSummary.table.replaceData(r.message))
							}

						} else {
							silicon_ioi.doctype.ioiTimeVectorSummary.table.replaceData()
						}
					}
				});
			} else {
			//	silicon_ioi.doctype.ioiTimeVectorSummary.time_vector_clear();
			}

		} else {
		//	silicon_ioi.doctype.ioiTimeVectorSummary.time_vector_clear();
		}
	}



	// ***************************************************************************************************************************************
	// Frame Time Vector : Load frame
	// ***************************************************************************************************************************************
	static refresh_need_flat_balance(html_fieldname, site_id = '', item_id)
	{
		let method = silicon_ioi.doctype.ioiTimeVectorFrame.path_time_vector + '.ioi_time_vector_get_need_flat_balance';

		let data = [];
		
		frappe.call({  	method: method, 
						args: {"site_id": site_id, "item_id": item_id},
						async: false,
						callback:function(r)	{ 
							data = r.message;
						}
		});	

		let in_stock_qty = 0;
		let need_flat_balance_qty = 0;
		let need_report_on = '';
		let need_flat_balance_qty_wo_to_handle = 0;
		let need_flat_balance_qty_wo_to_handle_and_previsional = 0;
		let survey_mode = '';
		let warning_mode = '';
		let warning_forward_datetime = '';
		let min_alert_level = 0;
		let warning_flat_mode = '';
		let max_alert_level = 0;

		if (data.length > 0) {
			in_stock_qty = data[0].in_stock_qty;
			need_flat_balance_qty = data[0].need_flat_balance_qty;

			if (data[0].need_report_on) {
				need_report_on = data[0].need_report_on.toString().substring(0, 19);
			}

			need_flat_balance_qty_wo_to_handle = data[0].need_flat_balance_qty_wo_to_handle;
			need_flat_balance_qty_wo_to_handle_and_previsional = data[0].need_flat_balance_qty_wo_to_handle_and_previsional;
			survey_mode = data[0].survey_mode;
			warning_mode = data[0].warning_mode;
			warning_flat_mode = data[0].warning_flat_mode;

			if (data[0].warning_forward_datetime) {
				warning_forward_datetime = data[0].warning_forward_datetime.toString().substring(0, 19);
			}

			min_alert_level = data[0].min_alert_level;
			max_alert_level = data[0].max_alert_level;
		}


		if (document.getElementById('html_need_flat_balance_in_stock_qty_label')) {
			document.getElementById('html_need_flat_balance_in_stock_qty_label').remove();
		}

		if (document.getElementById('html_need_flat_balance_in_stock_qty')) {
			document.getElementById('html_need_flat_balance_in_stock_qty').remove();
		}


		if (document.getElementById('html_need_flat_balance_need_flat_balance_qty_label')) {
			document.getElementById('html_need_flat_balance_need_flat_balance_qty_label').remove();
		}
		
		if (document.getElementById('html_need_flat_balance_need_flat_balance_qty')) {
			document.getElementById('html_need_flat_balance_need_flat_balance_qty').remove();
		}

		if (document.getElementById('html_need_flat_balance_need_report_on_label')) {
			document.getElementById('html_need_flat_balance_need_report_on_label').remove();
		}

		if (document.getElementById('html_need_flat_balance_need_report_on')) {
			document.getElementById('html_need_flat_balance_need_report_on').remove();
		}



		if (document.getElementById('html_need_flat_balance_need_flat_balance_qty_wo_to_handle_label')) {
			document.getElementById('html_need_flat_balance_need_flat_balance_qty_wo_to_handle_label').remove();
		}

		if (document.getElementById('html_need_flat_balance_need_flat_balance_qty_wo_to_handle')) {
			document.getElementById('html_need_flat_balance_need_flat_balance_qty_wo_to_handle').remove();
		}


		if (document.getElementById('html_need_flat_balance_need_flat_balance_qty_wo_to_handle_and_previsional_label')) {
			document.getElementById('html_need_flat_balance_need_flat_balance_qty_wo_to_handle_and_previsional_label').remove();
		}
		
		if (document.getElementById('html_need_flat_balance_need_flat_balance_qty_wo_to_handle_and_previsional')) {
			document.getElementById('html_need_flat_balance_need_flat_balance_qty_wo_to_handle_and_previsional').remove();
		}

		if (document.getElementById('html_need_flat_balance_survey_mode_label')) {
			document.getElementById('html_need_flat_balance_survey_mode_label').remove();
		}

		if (document.getElementById('html_need_flat_balance_survey_mode')) {
			document.getElementById('html_need_flat_balance_survey_mode').remove();
		}


		if (document.getElementById('html_need_flat_balance_warning_mode_label')) {
			document.getElementById('html_need_flat_balance_warning_mode_label').remove();
		}
		
		if (document.getElementById('html_need_flat_balance_warning_mode')) {
			document.getElementById('html_need_flat_balance_warning_mode').remove();
		}

		if (document.getElementById('html_need_flat_balance_warning_forward_datetime_label')) {
			document.getElementById('html_need_flat_balance_warning_forward_datetime_label').remove();
		}

		if (document.getElementById('html_need_flat_balance_warning_forward_datetime')) {
			document.getElementById('html_need_flat_balance_warning_forward_datetime').remove();
		}


		if (document.getElementById('html_need_flat_balance_min_alert_level_label')) {
			document.getElementById('html_need_flat_balance_min_alert_level_label').remove();
		}

		if (document.getElementById('html_need_flat_balance_min_alert_level')) {
			document.getElementById('html_need_flat_balance_min_alert_level').remove();
		}

		if (document.getElementById('html_need_flat_balance_min_alert_level_button')) {
			document.getElementById('html_need_flat_balance_min_alert_level_button').remove();
		}

		if (document.getElementById('html_need_flat_balance_max_alert_level_label')) {
			document.getElementById('html_need_flat_balance_max_alert_level_label').remove();
		}


		if (document.getElementById('html_need_flat_balance_max_alert_level')) {
			document.getElementById('html_need_flat_balance_max_alert_level').remove();
		}

		if (document.getElementById('html_need_flat_balance_max_alert_level_button')) {
			document.getElementById('html_need_flat_balance_max_alert_level_button').remove();
		}


		if (document.getElementById('html_need_flat_balance_warning_flat_mode_label')) {
			document.getElementById('html_need_flat_balance_warning_flat_mode_label').remove();
		}
		
		if (document.getElementById('html_need_flat_balance_warning_flat_mode')) {
			document.getElementById('html_need_flat_balance_warning_flat_mode').remove();
		}


		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:270px;">';

		html += '	<div style="position: relative; top: 0px; left: 0px; width:300px;">';
		html += '		<label id="html_need_flat_balance_in_stock_qty_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("In stock") + '</label>';		
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 300px; height: 25px;"> ';
		html += '			<input id="html_need_flat_balance_in_stock_qty" type="number" step="any" style="text-align: left;" class="input-with-feedback form-control bold" value="' + in_stock_qty + '" disabled>';
		html += '		</div>';
		html += '	</div>';

		html += '	<div style="position: relative; top: 0px; left: 320px; width:300px;">';
		html += '		<label id="html_need_flat_balance_need_flat_balance_qty_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Need flat balance") + '</label>';		
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 300px; height: 25px;"> ';
		html += '			<input id="html_need_flat_balance_need_flat_balance_qty" type="number" step="any" style="text-align: left;" class="input-with-feedback form-control bold" value="' + need_flat_balance_qty + '" disabled>';
		html += '		</div>';
		html += '	</div>';

		html += '	<div style="position: relative; top: 0px; left: 640px; width:300px;">';
		html += '		<label id="html_need_flat_balance_need_report_on_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Need report on") + '</label>';		
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 300px; height: 25px;"> ';
		html += '			<input id="html_need_flat_balance_need_report_on" type="text" style="text-align: left;" class="input-with-feedback form-control bold" value="' + need_report_on + '" disabled>';
		html += '		</div>';
		html += '	</div>';


		html += '	<div style="position: relative; top: 65px; left: 0px; width:300px;">';
		html += '		<label id="html_need_flat_balance_need_flat_balance_qty_wo_to_handle_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Need flat balance w/o to handle") + '</label>';		
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 300px; height: 25px;"> ';
		html += '			<input id="html_need_flat_balance_need_flat_balance_qty_wo_to_handle" type="number" step="any" style="text-align: left;" class="input-with-feedback form-control bold" value="' + need_flat_balance_qty_wo_to_handle + '" disabled>';
		html += '		</div>';
		html += '	</div>';

		html += '	<div style="position: relative; top: 65px; left: 320px; width:300px;">';
		html += '		<label id="html_need_flat_balance_need_flat_balance_qty_wo_to_handle_and_previsional_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Need flat balance w/o to handle and previsional") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 300px; height: 25px;"> ';
		html += '			<input id="html_need_flat_balance_need_flat_balance_qty_wo_to_handle_and_previsional" type="number" step="any" style="text-align: left;" class="input-with-feedback form-control bold" value="' + need_flat_balance_qty_wo_to_handle_and_previsional + '" disabled>';
		html += '		</div>';
		html += '	</div>';

		html += '	<div style="position: relative; top: 65px; left: 640px; width:300px;">';
		html += '		<label id="html_need_flat_balance_survey_mode_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Survey mode") + '</label>';		
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 300px; height: 25px;"> ';
		html += '			<input id="html_need_flat_balance_survey_mode" type="text" style="text-align: left;" class="input-with-feedback form-control bold" value="' + survey_mode + '" disabled>';
		html += '		</div>';
		html += '	</div>';


		html += '	<div style="position: relative; top: 130px; left: 0px; width:300px;">';
		html += '		<label id="html_need_flat_balance_warning_mode_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Warning mode") + '</label>';		
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 300px; height: 25px;"> ';
		html += '			<input id="html_need_flat_balance_warning_mode" type="text" style="text-align: left;" class="input-with-feedback form-control bold" value="' + warning_mode + '" disabled>';
		html += '		</div>';
		html += '	</div>';

		html += '	<div style="position: relative; top: 130px; left: 320px; width:300px;">';
		html += '		<label id="html_need_flat_balance_warning_forward_datetime_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Warning forward datetime") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 300px; height: 25px;"> ';
		html += '			<input id="html_need_flat_balance_warning_forward_datetime" type="text" style="text-align: left;" class="input-with-feedback form-control bold" value="' + warning_forward_datetime + '" disabled>';
		html += '		</div>';
		html += '	</div>';

		html += '	<div style="position: relative; top: 130px; left: 640px; width:300px;">';
		html += '		<label id="html_need_flat_balance_min_alert_level_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Min alert level") + '</label>';		
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 150px; height: 25px;"> ';
		html += '			<input id="html_need_flat_balance_min_alert_level" type="text" style="text-align: left;" class="input-with-feedback form-control bold" value="' + min_alert_level + '" disabled>';
		html += '		</div>';
		html += '		<div style="position: absolute; top:25px; left: 162px; height: 30px">';
		html +='			<button id="html_need_flat_balance_min_alert_level_button" class="btn btn-default ellipsis" style="height: 30px; width: 140px;" onclick="">' + __("Change") + '</button>';
		html += '		</div>';
		html += '	</div>';

		html += '	<div style="position: relative; top: 195px; left: 0px; width:300px;">';
		html += '		<label id="html_need_flat_balance_warning_flat_mode_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Warning flat mode") + '</label>';		
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 300px; height: 25px;"> ';
		html += '			<input id="html_need_flat_balance_warning_flat_mode" type="text" style="text-align: left;" class="input-with-feedback form-control bold" value="' + warning_flat_mode + '" disabled>';
		html += '		</div>';
		html += '	</div>';

		html += '	<div style="position: relative; top: 195px; left: 640px; width:300px;">';
		html += '		<label id="html_need_flat_balance_max_alert_level_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Max alert level") + '</label>';		
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 150px; height: 25px;"> ';
		html += '			<input id="html_need_flat_balance_max_alert_level" type="text" style="text-align: left;" class="input-with-feedback form-control bold" value="' + max_alert_level + '" disabled>';
		html += '		</div>';
		html += '		<div style="position: absolute; top:25px; left: 162px; height: 30px">';
		html +='			<button id="html_need_flat_balance_max_alert_level_button" class="btn btn-default ellipsis" style="height: 30px; width: 140px;" onclick="">' + __("Change") + '</button>';
		html += '		</div>';
		html += '	</div>';


		html += '</div>';

		cur_frm.fields_dict[html_fieldname].$wrapper.empty();
		cur_frm.fields_dict[html_fieldname].$wrapper.append(html);

		silicon_ioi.doctype.ioiTimeVectorSummary.sleep_static(400).then(() => {

			let fct_change_min_alert_level = function() {

				silicon_ioi.doctype.ioiTimeVectorSummary.change_min_alert_level();

			}

			document.getElementById('html_need_flat_balance_min_alert_level_button').onclick = fct_change_min_alert_level;


			let fct_change_max_alert_level = function() {

				silicon_ioi.doctype.ioiTimeVectorSummary.change_max_alert_level();

			}

			document.getElementById('html_need_flat_balance_max_alert_level_button').onclick = fct_change_max_alert_level;			

		});
	}

	static change_min_alert_level()
	{

		let min_alert_level_value = 0;

		if ((document.getElementById('html_need_flat_balance_min_alert_level').value) && (document.getElementById('html_need_flat_balance_min_alert_level').value != 0)) {
			min_alert_level_value = document.getElementById('html_need_flat_balance_min_alert_level').value;
		}

		var minAlertLeveltDialog = new frappe.ui.Dialog({
			title: __("Change min alert level"),
			static: true,
			fields: [	{'fieldname': 'html_need_flat_balance_change_min_alert_level', 'fieldtype': 'HTML'}
					],
			primary_action_label: __("Ok"),
			primary_action: function(){
				
				let value = 0;

				if ((document.getElementById('html_need_flat_balance_change_min_alert_level_value').value) && (document.getElementById('html_need_flat_balance_change_min_alert_level_value').value != 0)) {
					value = parseFloat(document.getElementById('html_need_flat_balance_change_min_alert_level_value').value);
				}


				minAlertLeveltDialog.hide();

				if (parseFloat(min_alert_level_value) != parseFloat(value)) {

					let method = silicon_ioi.doctype.ioiTimeVectorSummary.path_item_site + '.ioi_item_site_update_min_alert_level';
			
					frappe.call({  	method: method,
									args: {	"site_id": silicon_ioi.doctype.ioiTimeVectorSummary.site_id,
											"item_id": silicon_ioi.doctype.ioiTimeVectorSummary.item_id,
											"min_alert_level": value
									},
									async: false,
									callback:function(r)	{

										if (r.message.error != 0) {
											frappe.msgprint({title: __("Message"), message: r.message.error_msg, indicator: "red"});
										}else{
											document.getElementById('html_need_flat_balance_min_alert_level').value = value;
										}
									}
					});
				}

			},
			secondary_action_label: __("Cancel"),
			secondary_action: function(){

				minAlertLeveltDialog.hide();
			}

		});

		if (document.getElementById('html_need_flat_balance_change_min_alert_level_value_label')) {
			document.getElementById('html_need_flat_balance_change_min_alert_level_value_label').remove();
		}

		if (document.getElementById('html_need_flat_balance_change_min_alert_level_value')) {
			document.getElementById('html_need_flat_balance_change_min_alert_level_value').remove();
		}

		let html = ""
		html += '<div style="overflow: auto; overflow-x: auto; height:70px; width: 100%;">';
		html += '	<div style="position: relative; top: 0px; left: 2px; width:290px;">';		
		html += '		<label id="html_need_flat_balance_change_min_alert_level_value_label" style="position: absolute; top: 0px; left: 0px;z-index:5;">' + __("Min alert level") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 290px; height: 25px;"> ';
		html += '			<input id="html_need_flat_balance_change_min_alert_level_value" type="number" step="any" class="input-with-feedback form-control bold" value="' + min_alert_level_value.toString() + '">';
		html += '		</div>';
		html += '	</div>';
		html += "</div>"

		minAlertLeveltDialog.fields_dict.html_need_flat_balance_change_min_alert_level.$wrapper.html(html);		
		minAlertLeveltDialog.$wrapper.find('.modal-dialog').css("max-width", "335px").css("width", "335px");
		minAlertLeveltDialog.show();


		silicon_ioi.doctype.ioiTimeVectorSummary.sleep_static(500).then(() => {

			let fct_keyup = function(event) {

				if (event.keyCode == 13) {
					minAlertLeveltDialog.primary_action()
					return false;
				}
			}
			document.getElementById('html_need_flat_balance_change_min_alert_level_value').onkeyup = fct_keyup;
			document.getElementById('html_need_flat_balance_change_min_alert_level_value').focus();

		});

	}



	static change_max_alert_level()
	{

		let max_alert_level_value = 0;

		if ((document.getElementById('html_need_flat_balance_max_alert_level').value) && (document.getElementById('html_need_flat_balance_max_alert_level').value != 0)) {
			max_alert_level_value = document.getElementById('html_need_flat_balance_max_alert_level').value;
		}

		var maxAlertLeveltDialog = new frappe.ui.Dialog({
			title: __("Change max alert level"),
			static: true,
			fields: [	{'fieldname': 'html_need_flat_balance_change_max_alert_level', 'fieldtype': 'HTML'}
					],
			primary_action_label: __("Ok"),
			primary_action: function(){
				
				let value = 0;

				if ((document.getElementById('html_need_flat_balance_change_max_alert_level_value').value) && (document.getElementById('html_need_flat_balance_change_max_alert_level_value').value != 0)) {
					value = parseFloat(document.getElementById('html_need_flat_balance_change_max_alert_level_value').value);
				}


				maxAlertLeveltDialog.hide();

				if (parseFloat(max_alert_level_value) != parseFloat(value)) {

					let method = silicon_ioi.doctype.ioiTimeVectorSummary.path_item_site + '.ioi_item_site_update_max_alert_level';
			
					frappe.call({  	method: method,
									args: {	"site_id": silicon_ioi.doctype.ioiTimeVectorSummary.site_id,
											"item_id": silicon_ioi.doctype.ioiTimeVectorSummary.item_id,
											"max_alert_level": value
									},
									async: false,
									callback:function(r)	{

										if (r.message.error != 0) {
											frappe.msgprint({title: __("Message"), message: r.message.error_msg, indicator: "red"});
										}else{
											document.getElementById('html_need_flat_balance_max_alert_level').value = value;
										}
									}
					});
				}

			},
			secondary_action_label: __("Cancel"),
			secondary_action: function(){

				maxAlertLeveltDialog.hide();
			}

		});

		if (document.getElementById('html_need_flat_balance_change_max_alert_level_value_label')) {
			document.getElementById('html_need_flat_balance_change_max_alert_level_value_label').remove();
		}

		if (document.getElementById('html_need_flat_balance_change_max_alert_level_value')) {
			document.getElementById('html_need_flat_balance_change_max_alert_level_value').remove();
		}

		let html = ""
		html += '<div style="overflow: auto; overflow-x: auto; height:70px; width: 100%;">';
		html += '	<div style="position: relative; top: 0px; left: 2px; width:290px;">';		
		html += '		<label id="html_need_flat_balance_change_max_alert_level_value_label" style="position: absolute; top: 0px; left: 0px;z-index:5;">' + __("Max alert level") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 290px; height: 25px;"> ';
		html += '			<input id="html_need_flat_balance_change_max_alert_level_value" type="number" step="any" class="input-with-feedback form-control bold" value="' + max_alert_level_value.toString() + '">';
		html += '		</div>';
		html += '	</div>';
		html += "</div>"

		maxAlertLeveltDialog.fields_dict.html_need_flat_balance_change_max_alert_level.$wrapper.html(html);		
		maxAlertLeveltDialog.$wrapper.find('.modal-dialog').css("max-width", "335px").css("width", "335px");
		maxAlertLeveltDialog.show();


		silicon_ioi.doctype.ioiTimeVectorSummary.sleep_static(500).then(() => {

			let fct_keyup = function(event) {

				if (event.keyCode == 13) {
					maxAlertLeveltDialog.primary_action()
					return false;
				}
			}
			document.getElementById('html_need_flat_balance_change_max_alert_level_value').onkeyup = fct_keyup;
			document.getElementById('html_need_flat_balance_change_max_alert_level_value').focus();

		});

	}


	static sleep_static(ms) 
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}

}

silicon_ioi.doctype.ioiTimeVectorSummary = ioiTimeVectorSummary;