frappe.provide('silicon_ioi');

const dialog = new frappe.ui.Dialog({
  'title': __("Select an item"),
  'fields': [
      {'fieldname': 'html_select_item_shop', 'fieldtype': 'HTML'},
      {
        "fieldtype": "Link",
        "label": __("Item name"),
        "fieldname": "item_name",
        "options": "ioi Item",
        "hidden":1,
      }

  ],
  primary_action_label: 'Ok',
  secondary_action_label: __('Cancel'),
  secondary_action: function(){
      dialog.hide();
      wipe_all();
  }


});

var language = "" ;

//pagination
var current_page = 1;

var records_per_page = 24;

var used_page_items=[];

var mobilemode=1;

var items_lock=[]
var nbr_items=0;

const item_list=[[],0];


export class ioiEShop
{
	static select_item_from_shop(window_width, division_id, catalog_id, condition_id, customer_id, warehouse_id, callback = null)
  {

      let html = '<link rel="stylesheet" href="/assets/silicon_ioi/css/eshop.bundle.css">';

      html += '<div style="overflow-x: auto;overflow-y: auto; width:100%;height:600px;">';

      html += `<div id="item-ensemble" class="item-ensemble">
      <nav class="categories-nav nav-all mb-only border-left hidden" id="categories_nav">



          <div id="mb-categorytree" ></div>





      </nav>

      <nav class="filter-nav nav-all mb-only border-left hidden" id="filter_nav">



          <div id="mb-filtertree" class="tag_list a-section a-spacing-none box-ensemble" style="margin-left:10px;"></div>



      </nav>

      <div class="all_filter">

          <div class="box-ensemble dskt-only">



              <div class="tree" id="tree-parent-list">





              </div>

          </div>



          <div id="tag-parent-list" class="tag_list a-section a-spacing-none box-ensemble dskt-only">



          </div>

      </div>

      <div class="item-vente-ensemble" id="itemventeensemble">

      </div>
  
      </div>
      </div>
      `
      html += '</div>';

      dialog.fields_dict.html_select_item_shop.$wrapper.html(html);
      dialog.$wrapper.find('.modal-dialog').css("max-width", window_width).css("width", window_width);
      dialog.$wrapper.find('.modal-dialog').css("max-height", "100%").css("height", "100%").addClass("visual-search");
      dialog.$wrapper.find('.modal-body').css("padding","unset");
      dialog.disable_primary_action();
      dialog.show();

    categoriescreation("All Categories","")


    dialog.set_primary_action(__("Ok"), function () {
      var values = dialog.get_values();
      if (values) {

        //dialog.disable_primary_action()

        dialog.hide();

        // Item_id selected and qty
        let ret_value=values["item_name"];
            let ret_qty = 1;

          if(callback && ret_value){
            callback(ret_value, ret_qty)
          }
      }

    });

  }


  //return item_name
  static returnval(item_name){

    dialog.set_value("item_name",item_name);

    dialog.enable_primary_action()

    setTimeout(() => {
      dialog.get_primary_btn().click();
    }, "200");

  }

  static prevPage() {

    if (current_page > 1) {

      current_page--;

      changePage(current_page);

    }

  }

  static nextPage() {
      
    if (current_page < numPages()) {
    
      current_page++;
    
      changePage(current_page);
    
    }
    
  }

  static treeclick(source) {

    wipe_all();

    categoriescreation(source.dataset.name,"");

    }

  static filter_tag(source){
    
    create_key();

  //console.log(source)

  let input = source.querySelector('input[type=checkbox]')

  let filter_tag = input.value;
  
  let filter_tag_subject = input.name;
  

  //console.log(filter_tag)

  let maincategorie="";

  let maincat = document.getElementById("maincategorie")

  if (maincat != null){

      maincategorie = maincat.dataset.name;

  }

  var checkboxs = document.querySelectorAll('.a-checkbox input');  

  var all_tags=[];

  for (let i = 0; i < checkboxs.length; i++) {   

      if (checkboxs[i].checked == true){

          if(checkboxs[i].value != input.value){

              all_tags.push([checkboxs[i].name,checkboxs[i].value]);

          }

      }

  }

  if (!input.checked){

  all_tags.push([filter_tag_subject,filter_tag]);

  }else{

      input.checked = false;

  }

  

  //alert(filter_tag)

  if(!all_tags.length){

      //to prevent reload of category tree

      all_tags="defilter";

  }

  // Clean items

  var tagparentlist = document.getElementById("tag-parent-list");

  var itemventeensemble = document.getElementById("itemventeensemble");

  tagparentlist.innerHTML="";

  itemventeensemble.innerHTML="";
  
  //document.getElementById("loading").classList.remove("hide");
  
  //console.log(all_tags)

  categoriescreation(maincategorie,all_tags);
  

}

}

silicon_ioi.ioiEShop = ioiEShop;

var lock_key = ""

function categoriescreation(mastercategory,tags) {

  var no_items=0;
  
  create_key();

  let renew_cat=1;

  

  if (tags=="defilter"){

      renew_cat=0;

      tags="";

  }
  
  tags_json=JSON.stringify(tags);
  

frappe.call({

  method:

    "silicon_ioi.ioi_e_commerce.doctype.ioi_shop_category.ioi_shop_category.get_categories",

  args: {

    mastercategory: mastercategory,

    tags: tags_json,
    
    language: language

  },
  
  // freeze the screen until the request is completed
  freeze: true,


  // disable the button until the request is completed

  btn: $(".primary-action"),

  callback: (r) => {

    if (r.message) {

      //console.log(r.message);

      if(tags == "" && renew_cat){
          
          tags_json="";

          // Category Tree

          // Parents

          var treeparentlist = document.getElementById("tree-parent-list");

          var parentelement = document.createElement("div");

          
            treeparentlist.appendChild(parentelement);

          

          for (let i = r.message[1].length - 1; i >= 0; i--) {

              var parentelement = document.createElement("div");

              parentelement.className = "category_list";

              if(i==r.message[1].length - 1){
                  
                  //console.log(r.message[1][i])

                  parentelement.innerHTML = `<b><a data-name="${r.message[1][i][0]}" onclick='silicon_ioi.ioiEShop.treeclick(this)'>${r.message[1][i][1]}</a></b>`;

                  for (let j = 0; j <r.message[5].length; j++){

                      parentelement.innerHTML += `<div></div><a onclick="silicon_ioi.ioiEShop.treeclick(this)" data-name="${r.message[5][j][0]}">${r.message[5][j][1]}</a></div>`;

                  }

              }else{
                   //console.log(r.message[1][i])

              parentelement.innerHTML =`<div></div>‹&nbsp;<a onclick="silicon_ioi.ioiEShop.treeclick(this)" data-name="${r.message[1][i][0]}">${r.message[1][i][1]}</a></div>`;

              }

            treeparentlist.appendChild(parentelement);

          }

          var is_group=0;
          if (r.message[2].length) {
            if (r.message[2][0]=="no_items"){
              is_group=1;
              no_items=1;
            
            }else{

              //brothers

              r.message[2].sort(sortcatbytext);

              for (let i =0; i < r.message[2].length; i++) {

                var brothercategorie = document.createElement("div");

                brothercategorie.className = "category_list";

                if(r.message[2][i][0] == r.message[4][0][0]){

                    //main

                    brothercategorie.innerHTML = "<b>" + r.message[2][i][1] + "</b>";

                    brothercategorie.setAttribute('id','maincategorie');

                    brothercategorie.setAttribute('data-name',r.message[2][i][0]);

                }else{

                    brothercategorie.innerHTML = `<div></div><a onclick="silicon_ioi.ioiEShop.treeclick(this)" data-name="${r.message[2][i][0]}">${r.message[2][i][1]}</a></div>`;

                }

                parentelement.appendChild(brothercategorie);

              }
            }

          } else {
            is_group=1;
        }
        
        if (is_group){

              if(r.message[4][0].length>0 ){
                  if(r.message[4][0][0]){

                      //main
  
                      var maincategorie = document.createElement("div");
  
                      maincategorie.className = "category_list";
  
                      maincategorie.innerHTML = "<b>" + r.message[4][0][1] + "</b>";
  
                      maincategorie.setAttribute('id','maincategorie');
  
                      maincategorie.setAttribute('data-name',r.message[4][0][0]);
  
                      parentelement.appendChild(maincategorie);
                  }

              }

            // child

            r.message[0].sort(sortcatbytext);

            var childelement = document.createElement("div");

            childelement.className = "category_list";

            var childhtml = '<ul class="ul-box" style="padding-left: 0;">';

            for (let i = 0; i < r.message[0].length; i++) {

              childhtml +=`<li class="li-box-2"><a onclick="silicon_ioi.ioiEShop.treeclick(this)" data-name="${r.message[0][i][0]}">${r.message[0][i][1]}</a></li>`;

            }

            childhtml += "</ul>";

            childelement.innerHTML = childhtml;

            if(r.message[4][0].length>0 ){
                if(r.message[4][0][0]){

                  maincategorie.appendChild(childelement);
                }else{
                    parentelement.appendChild(childelement);
                }

            }else{

                parentelement.appendChild(childelement);

            }

          }

          //mbcategorie

          document.getElementById("mb-categorytree").innerHTML=treeparentlist.innerHTML;

      }  

      if(no_items){
            
        display_no_items(r.message[0],r.message[2][1])
      //tags
      }else{


        var tagparentlist = document.getElementById("tag-parent-list");

        for (let i = 0; i < r.message[8].length; i++) {

          var tagparentelement = document.createElement("div");

          tagparentelement.className = "a-section a-spacing-small";

          tagparentelement.innerHTML =

            '<span class="a-size-base a-color-base a-text-bold" dir="auto">' +

            decode_string(r.message[8][i][1]) +

            "</span>";

          var tagchildelement = document.createElement("ul");

          tagchildelement.className =

            "a-unordered-list a-nostyle a-vertical a-spacing-medium";

          for (let j = 0; j < r.message[9].length; j++) {

            if (r.message[9][j][0] == r.message[8][i][0]) {

              var tagvalueelement = document.createElement("li");

              tagvalueelement.className = "a-spacing-micro";

              tagvalueelement.innerHTML =

                `<span class="a-list-item"><a class="a-link-normal c_pointer" tabindex="-1" onclick="silicon_ioi.ioiEShop.filter_tag(this)"><div class="a-checkbox a-checkbox-fancy aok-float-left apb-browse-refinements-checkbox"><label class="c_pointer"><input type="checkbox" name='${r.message[9][j][0]}' value='${r.message[9][j][1][0]}' disabled><i class="a-icon a-icon-checkbox"></i><span class="a-label a-checkbox-label"></span></label></div><span class="a-size-base a-color-base" dir="auto">${decode_string(r.message[9][j][1][1])}</span><span class="a-size-base a-color-base">&nbsp;(${r.message[10][j][0]})</span></a></span>`;

              tagchildelement.appendChild(tagvalueelement);

            }

          }

  

          tagparentelement.appendChild(tagchildelement);

          tagparentlist.appendChild(tagparentelement);

          

          }

          

          mbtagparentlist=document.getElementById("mb-filtertree");

          mbtagparentlist.innerHTML=tagparentlist.innerHTML;

    

           if (tags!=""){

            for (let i = 0; i < tags.length; i++) {

                

                var checked_tag = tagparentlist.querySelector(`input[name='${tags[i][0]}'][value='${tags[i][1]}']`);

                if (checked_tag){

                  checked_tag.checked = true;

                }

                

                var checked_tag = mbtagparentlist.querySelector(`input[name='${tags[i][0]}'][value='${tags[i][1]}']`);

                if (checked_tag){

                  checked_tag.checked = true;

                }

              }

          

          }
        
        //items
        r.message[11].sort()
        items_lock=[];
        items_lock.push(lock_key)
        nbr_items=r.message[11].length;
        if (nbr_items>records_per_page){
          firstpageitems=r.message[11].slice(0,records_per_page);
          items_lock.push([firstpageitems]);
          let restofitems=r.message[11].slice(records_per_page);
          let restofitemslist=[]
            while(1) {
                if (restofitems.length > records_per_page){
                    restofitemslist.push(restofitems.slice(0,records_per_page));
                    restofitems=restofitems.slice(records_per_page);
                }else{
                    restofitemslist.push(restofitems);
                    break;
                }
            }
            for(let i = 0; i < restofitemslist.length; i++) {
              items_lock[1].push(restofitemslist[i]);
            }
        }else{
            items_lock.push([r.message[11]])
        }

        changePage(current_page)
      }

    }

  },

  error: (r) => {

    alert("Error : " + r.message);

  },

});

}

function sortcatbytext(a,b){
  if (a[1]< b[1]) {
  return -1;
} else if (a[1] > b[1]) {
  return 1;
}
// a must be equal to b
return 0;
}

function create_key() {
  let new_key = lock_key;
  while(lock_key == new_key){
      new_key = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < 7) {
        new_key += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
  }
  lock_key = new_key;
  
  return new_key
}



function decode_string(string){

  return decodeURIComponent(string)

}


function itemscreation(items,freezevar,call_key) {
    
    //if (!mobilemode){
    if(!mobilemode || current_page==1){
        itemventeensemble.innerHTML="";
    }

  frappe.call({

    method:

      "silicon_ioi.ioi_e_commerce.doctype.ioi_shop_category.ioi_shop_category.get_item_detail",

    args: {

     itemlist:JSON.stringify(items),
     language:language

    },
    
    // freeze the screen until the request is completed
    freeze: freezevar,

    // disable the button until the request is completed

    btn: $(".primary-action"),

    callback: (r) => {

      if (r.message){
        //console.log(r.message)
        if (call_key == lock_key){
           displaycreation(r.message,freezevar);
        }
        
      }

    },

    error: (r) => {

      alert("Error : " + r.message);

    },

  });

}



function display_no_items(dsp_chld_cats_names,dspl_chld_pcts){
  let mockup_el = document.querySelector(".navbar-brand img");
  mockup="/assets/silicon_ioi/images/hero-apps.png";
  if (mockup_el){
    mockup=mockup_el.src;
  }
  var itemventeensemble = document.getElementById("itemventeensemble");
  for (let j=0; j<dsp_chld_cats_names.length ; j++){
      let childcat = document.createElement("div");
      childcat.className = "item-vente";
      childcat.innerHTML = `<div class="item-vente-content"><a class="flex-vertical no_items_a" data-name='${dsp_chld_cats_names[j][0].replaceAll("'","%27").replaceAll('"','&quot;')}' onclick='silicon_ioi.ioiEShop.treeclick(this)'><div class="img-w100 img-spacing-small"> <div class="img-container align-center-horizontally"><img src="${dspl_chld_pcts[dsp_chld_cats_names[j][0]] ? dspl_chld_pcts[dsp_chld_cats_names[j][0]] : mockup}"></div><span class="text-bike">${dsp_chld_cats_names[j][1]}</span></div></a></div>`
      itemventeensemble.appendChild(childcat);
  }
  
}


function displaycreation(items,changepage){
/*
  item_list[0].sort(function(a, b) {
if (a[1]<b[1]) return -1;
if (a[1] > b[1]) return 1;
return 0;
});
*/
var itemventeensemble = document.getElementById("itemventeensemble");

  for (let i = 0; i < items.length; i++) {

    var onclickfct=""

    
    // to have two decimals if decimals exist 

    var price=items[i][1][1];

    var full_price=items[i][1][0]

    let displayed_currency_symbol="";

    if ( typeof price == 'number' ) {

      if(price.countDecimals() == 1){

          price=price+"0";

      }
      displayed_currency_symbol=items[i][1][4];
    }
    let textpriceclass="";
    if ( typeof full_price == 'number' ) {
        if(full_price.countDecimals() == 1){

            full_price=full_price+"0";

        }
    }else{
        textpriceclass="textprice"
    }

    var itemvente = document.createElement("div");

    let stocktxt="";

    if(items[i][4][0]){
      if (items[i][4][1]){
          stocktxt=items[i][4][0] +" " + items[i][4][1];
      }else{
          stocktxt=items[i][4][0] +" days";
      }
    }

    if(!items[i][3]){
      onclickfct=`onclick="silicon_ioi.ioiEShop.returnval('${items[i][0].name}')"`
      itemvente.className = "item-vente";
    }else{
      itemvente.className = "item-vente mc_disable";
    }

    itemvente.innerHTML = `<div class="item-vente-content"><a class="a_eshop a-color-base a-text-normal a-size-text a-bottom flex-vertical" ${onclickfct}> <div class="img-w100 img-spacing-small"> <div class="img-container align-center-horizontally"> <img alt="${items[i][0].structured_id}" src="

    ${items[i][0].attach_thumbnail1}"> </div> <div class="dskt-only"> <div class="text-bike lg1">

    ${items[i][2]}</div> </div></div>  <div class="items-description">  <span class="price-design"> <span class="price ${textpriceclass}"><span>

    ${items[i][1][2] && full_price != price ? "<span style='color:red'>"+price+"</span>&nbsp;<strike style='font-size: large;'>"+full_price+"</strike>": price}</span><span class="symbol-design">${displayed_currency_symbol}</span> </span> </span><span class="stock">${stocktxt} </span> <div class="mb-only"> <div class="text-bike lg1">${items[i][2]}</div> </div> </div></a> </div>`;

    itemventeensemble.appendChild(itemvente);
    
    //to trigger loading of next "page" on mobile
    if(i==items.length-6 && mobilemode){
        respondToVisibility(itemvente, visible => {
            if(visible){
                if(!used_page_items.includes(items[i][0].structured_id)){
                    used_page_items.push(items[i][0].structured_id)
                    silicon_ioi.ioiEShop.nextPage()
                }
                
            }
        });
    }
        
  }

}


function wipe_all(){

  var treeparentlist = document.getElementById("tree-parent-list");

  var itemventeensemble = document.getElementById("itemventeensemble");

  var tagparentlist = document.getElementById("tag-parent-list");

  treeparentlist.innerHTML="";

  itemventeensemble.innerHTML="";

  tagparentlist.innerHTML="";

}


    //pagination


function changePage(page) {
  
  // Validate page
  
  if (page < 1) page = 1;
  
  if (page > numPages()) page = numPages();
  
  itemscreation(items_lock[1][current_page-1],0, items_lock[0]);
  
  
  var item_vente_ensemble = document.getElementsByClassName(
  
    "item-vente-ensemble"
  
  );
  
  var item_ensemble = document.getElementById("item-ensemble");
  
  if (item_vente_ensemble[0].innerHTML.trim() === "") {
  
    item_ensemble.classList.add("border-bottom-items");
  
  }
  
}
  
function numPages() {

  return Math.ceil(nbr_items/ records_per_page);

}

function respondToVisibility(element, callback) {
  var options = {
    root: null,
    threshold: .5
  };

  var observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      callback(entry.intersectionRatio > 0);
    });
  }, options);

  observer.observe(element);
}


Number.prototype.countDecimals = function () {


  if (Math.floor(this.valueOf()) === this.valueOf()) return 0;


  var str = this.toString();

  if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {

      return str.split("-")[1] || 0;

  } else if (str.indexOf(".") !== -1) {

      return str.split(".")[1].length || 0;

  }

  return str.split("-")[1] || 0;

}
