frappe.provide('silicon_ioi');


export class ioiLabelling
{
	// ***************************************************************************************************************************************
	// init_print_labels
	// ***************************************************************************************************************************************

	static init_print_labels(frm, dt, dn)
	{
		let labelling = new ioiLabelling(frm, dt, dn);

		if(labelling.#check_permissions_to_print()){
			if (labelling.#show_dialog()){

			}
		}
	}

	constructor(frm, dt, dn)
	{
		this.frm = frm;
		this.dt = dt;
		this.dn = dn;
	}

	#check_permissions_to_print(){
		return true;
	}

	static change_printer_paper_format(printer_id, label_paper_format_id){
		frappe.db.set_value("ioi Printer", printer_id, "label_paper_format_id", label_paper_format_id)
	}

	static async get_user_current_site(){
		let current_site = (await frappe.db.get_value('ioi User', { "user_id": frappe.session.user }, "site_id")).message.site_id;
		return current_site;
	}

	static async get_user_current_work_area(){
		let current_work_area = (await frappe.db.get_value('ioi User', { "user_id": frappe.session.user }, "work_area_id")).message.work_area_id;
		return current_work_area;
	}

	static async get_label_formats(src_doctype, src_name){
		let label_formats;

		frappe.call({
			method: "silicon_ioi.ioi_configuration.doctype.ioi_label_format.ioi_label_format.get_label_formats",
			args: {
				src_doctype : src_doctype,
				src_name : src_name
			},
			async: false,
			callback: (r) => {
				if (r.message.success){
					label_formats = r.message.output;
				}else{
					label_formats = []
				}
			}
		})
		return label_formats;
	}

	static async get_work_areas(){

		let current_site = await ioiLabelling.get_user_current_site();
		let work_areas = await frappe.db.get_list("ioi Work Area",
												{
													fields:["name as value", "description as label"],
													filters: {"site_id": current_site},
													order_by:"description"
												});

		return work_areas;
	}

	#refresh_printer_list_dialog(label_dlg, work_area_id){
		let printer_list;
		frappe.call({
			method: "silicon_ioi.ioi_configuration.doctype.ioi_printer.ioi_printer.ioi_printer_for_labelling",
			args: {
				work_area_id: work_area_id
			},
			async: false,
			callback: (r) => {
				printer_list = r.message;
			}
		})
		let current_printer_value = label_dlg.fields_dict.printer_id.value;
		label_dlg.set_df_property('printer_id', 'options', printer_list);
		if (!current_printer_value && printer_list.length > 0){
			current_printer_value = printer_list[0].value;
		}
		label_dlg.fields_dict.printer_id.set_value(current_printer_value);
	}

	async #is_printer_compatible(label_format_id, printer_id){
		let lf  = (await frappe.db.get_value("ioi Label Format", label_format_id, "label_paper_format_id")).message.label_paper_format_id;
		let prn = (await frappe.db.get_value("ioi Printer", printer_id, "label_paper_format_id")).message.label_paper_format_id;

		return (lf == prn && lf && prn)|| !lf || !prn
	}

	async #refresh_html_error(label_dlg, label_formats){

		let html = "";
		let label_idx = label_formats.findIndex((lab) => {return lab.value == label_dlg.fields_dict.label_format_id.value});
		if (label_idx > -1){
			let is_prn_compatible = await this.#is_printer_compatible(label_formats[label_idx].parent_label_id, label_dlg.fields_dict.printer_id.value);

			if (is_prn_compatible){

			}else{
				html = `
				<div style="color:red">
					${__("Paper format of this 'Label Format' does not compatible with the selected printer")}
				</div>
				`
			}
			label_dlg.fields_dict.html_error.$wrapper.empty();
			label_dlg.fields_dict.html_error.$wrapper.append(html);
		}

	}

	#refresh_default_printer(label_dlg, label_formats){
		//set default printer for this label
		let selected_lab = label_formats.filter((lab) => {
			if (lab.value == label_dlg.fields_dict.label_format_id.value){
				return lab;
			}
		});

		if (selected_lab.length == 1){
			if (selected_lab[0].default_printer_id && selected_lab[0].default_printer_id != label_dlg.fields_dict.printer_id.value){
				let printers = JSON.parse(label_dlg.fields_dict.printer_id.last_options);
				if (printers.findIndex((prn) => { return prn.value == selected_lab[0].default_printer_id})> -1){
					label_dlg.fields_dict.printer_id.set_value(selected_lab[0].default_printer_id);
				}
			}
		}
	}

	#print_label_format(label_caller_name, label_format_id, printer_id){

		if (label_caller_name && label_format_id && printer_id){
			frappe.call({
				method: "silicon_ioi.ioi_configuration.doctype.ioi_label_format.ioi_label_format.print_label_format",
				args: {
					label_caller_name : label_caller_name,
					label_format_name : label_format_id,
					src_dt : this.frm.doc.doctype,
					src_name : this.frm.doc.name,
					printer_id : printer_id
				},
				async: false,
				callback: (r) => {
					console.log(r);
					if(r.message.success){
						if(r.message.pdf){
							let downloadLink      = document.createElement('a');
							downloadLink.target   = '_blank';
							downloadLink.download = 'label.pdf';

							let uint8Array = new Uint8Array(r.message.pdf);
							// convert downloaded data to a Blob
							let blob = new Blob([uint8Array], { type: 'application/pdf' });

							// create an object URL from the Blob
							let URL = window.URL || window.webkitURL;
							let downloadUrl = URL.createObjectURL(blob);

							// set object URL as the anchor's href
							downloadLink.href = downloadUrl;

							// append the anchor to document body
							document.body.append(downloadLink);

							// fire a click event on the anchor
							// downloadLink.click();
							window.open(downloadUrl,'_blank');
							// cleanup: remove element and revoke object URL
							document.body.removeChild(downloadLink);
							URL.revokeObjectURL(downloadUrl);
						}
					}else{
						frappe.throw(r.message.description)
					}
				}
			})
		}
	}

	async #show_dialog(){
		let me = this;
		let label_formats = await ioiLabelling.get_label_formats(me.dt, me.dn);
		let work_areas = await ioiLabelling.get_work_areas();
		let usr_work_area = await ioiLabelling.get_user_current_work_area();
		let dlg_created = false;

		let label_dlg = new frappe.ui.Dialog({
			title: __("Label print"),
			fields: [
				{
					fieldtype: "Select",
					options: label_formats.map((lab) => {
						let lab_val = {label: lab.label, value: lab.value};
						return lab_val;
					}),
					default: label_formats.length > 0 ? label_formats[0].value : '',
					label: __("Label Format"),
					fieldname: "label_format_id",
					reqd: 1,
					onchange: () => {
						me.#refresh_default_printer(label_dlg, label_formats);
						me.#refresh_html_error(label_dlg, label_formats);
					}
				},
				{
					fieldtype: "Check",
					label: __("Ignore Work Area"),
					fieldname: "ignore_work_area",
					default: 0,
					onchange: () => {
						if (label_dlg.fields_dict.ignore_work_area.value == 1){
							me.#refresh_printer_list_dialog(label_dlg, "")
						}else{
							me.#refresh_printer_list_dialog(label_dlg, label_dlg.fields_dict.user_work_area_id.value)
						}
						me.#refresh_default_printer(label_dlg, label_formats);
						me.#refresh_html_error(label_dlg, label_formats);
					}
				},
				{
					fieldtype: "Select",
					options: work_areas,
					default: usr_work_area ? usr_work_area :  (work_areas.length > 0 ? work_areas[0].value : ''),
					label: __("Current work area"),
					fieldname: "user_work_area_id",
					onchange: () => {
						me.#refresh_printer_list_dialog(label_dlg, label_dlg.fields_dict.user_work_area_id.value)
					},
					depends_on:"eval: doc.ignore_work_area == 0"
				},
				{
					fieldtype: "Select",
					options: [],
					label: __("Printer"),
					fieldname: "printer_id",
					onchange: () => {
						me.#refresh_html_error(label_dlg, label_formats);
					},
					reqd: 1
				},
				{
					fieldtype: "HTML",
					fieldname: "html_error",
				}
			],
			primary_action_label: __("Print"),
			primary_action: async function (values) {
				if (values.user_work_area_id){
					frappe.call({
						method: "silicon_ioi.ioi_configuration.doctype.ioi_work_area.ioi_work_area.set_current_user_work_area",
						args: {
							work_area_id: values.user_work_area_id
						},
						callback: (r) => {

						}
					});
				}

				let printer_id = label_dlg.fields_dict.printer_id.value;
				let label_format_idx = label_formats.findIndex((lab) => {return lab.value == label_dlg.fields_dict.label_format_id.value});
				let label_format_id = label_formats[label_format_idx].parent_label_id;
				let label_caller_name = label_dlg.fields_dict.label_format_id.value;

				let is_prn_compatible = await me.#is_printer_compatible(label_format_id, printer_id);
				if (!is_prn_compatible){
					let printer_paper_format_id = (await frappe.db.get_value("ioi Printer", printer_id, "label_paper_format_id")).message.label_paper_format_id;
					let label_paper_format_id = (await frappe.db.get_value("ioi Label Format", label_format_id, "label_paper_format_id")).message.label_paper_format_id;
					label_dlg.hide();
					frappe.confirm(
						__("The selected printer does not have the same paper format as the label format.<br>"+
						   "Please change the label paper on the printer if you want to continue.<br><br>"+
						   "If you continue, the paper format will be changed on the selected printer.<br><br>"+
						   "<b>Old format:</b> {0}<br>"+
						   "<b>New format:</b> {1}<br>", [
							printer_paper_format_id, label_paper_format_id
						]),
						function () {
							ioiLabelling.change_printer_paper_format(printer_id, label_paper_format_id);
							me.#print_label_format(label_caller_name, label_format_id, printer_id);
						},
						function () {
							//not ok
							label_dlg.show();
						}
					);
				}else{
					me.#print_label_format(label_caller_name, label_format_id, printer_id);
					label_dlg.hide();
				}
			},
			secondary_action_label: __("Cancel"),
			secondary_action : function () {
				label_dlg.hide();
				return
			},
			on_page_show: async () => {
				if (!dlg_created){
					me.#refresh_printer_list_dialog(label_dlg, label_dlg.fields_dict.user_work_area_id.value);
					me.#refresh_default_printer(label_dlg, label_formats);
					dlg_created = true;
				}
			}
		});
		label_dlg.show();

		return true;
	}
}

silicon_ioi.ioiLabelling = ioiLabelling;
